import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {ApiDelete, ApiGet, ApiPost, ApiPut, ApiUpload} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import TitleHead from './comman/TitleHead'
import SubHead from './comman/SubHead'
import LabelTitle from './comman/LabelTitle'
import AddUnits from './AddUnits'
import townhouse from '../../../img/TownHouse.svg'
import villa from '../../../img/Villa.svg'
import common_area from '../../../img/common_area.svg'
import other from '../../../img/other.svg'
import rightarrow from '../../../img/rightArrow.svg'

const UnitComForm = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
  } = useForm<any>()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [tableData, setTableData] = useState([])
  const [updateDataId, setUpdateDataId] = useState('')
  const [propertiType, setPropertiType] = useState('Townhouse')
  const [create, setCreate] = useState<any>(false)
  const [imgUrl, setImgUrl] = useState([])
  const [header, setHeader] = useState<any>({})
  const [headerB, setHeaderB] = useState<any>([])
  console.log('🚀 ~ file: Building.tsx ~ line 10 ~ Building ~ updateDataId', updateDataId)
  const [isEdit, setIsEdit] = useState(false)
  console.log('🚀 ~ file: Building.tsx ~ line 12 ~ Building ~ isEdit', isEdit)
  const [formData, setFormData] = useState<any>({
    propertyId: window.location.pathname?.split('/')[2],
    propertyAreaId: window.location.pathname?.split('/')[3],
  })
  console.log('formData', formData)

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }

  const building = async () => {
    const body = {
      page: 1,
      limit: 1000,
      propertyAreaId: window.location.pathname?.split('/')[3],
    }
    await ApiPost(`corporate/unit/get`, body)
      .then((res) => {
        setTableData(res?.data?.data?.property_area_unit_data)
        console.log('res', res)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const imageChange = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/town_house', formData)
      .then((res) => setImgUrl(res?.data?.data?.image))
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const imageChangePen = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/villa', formData)
      .then((res) => setImgUrl(res?.data?.data?.image))
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const imageChangeCom = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/common_area', formData)
      .then((res) => setImgUrl(res?.data?.data?.image))
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const imageChangeOth = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/other', formData)
      .then((res) => setImgUrl(res?.data?.data?.image))
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const onSubmit = (data: any, event: any) => {
    event.preventDefault()
    if (propertiType === 'Townhouse') {
      if (isEdit) {
        delete formData._id
        delete formData.updatedBy
        delete formData.status
        const body = {...formData, managerId: '6329ee43396e812bcc0964e5', id: updateDataId}
        ApiPut('corporate/properties_area_unit', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building()
            setIsEdit(false)
            console.log('addProperty', res)
            setShowCreateAppModal(false)
            setFormData({propertyId: window.location.pathname?.split('/')[2]})
          })
          .catch((err) => ErrorToast(err.message))
      } else {
        const body = {
          unitNo: formData?.unitNo,
          bedrooms: formData?.bedrooms,
          size: formData?.size,
          sizeType: formData?.sizeType,
          occupy: create ? 1 : 0,
          images: [imgUrl],
          unitType: 'town_house',
          premiseNo: formData?.premiseNo,
          managerId: formData?.propertyManager,
          // propertyId: formData?.propertyId,
          floorId: formData?.propertyAreaId,
          clusterId: window.location.pathname.split('/')[3],
        }
        console.log('body', body)
        ApiPost('corporate/unit', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building()
            console.log('addProperty', res)
            setShowCreateAppModal(false)
            setFormData({propertyId: window.location.pathname?.split('/')[2]})
            navigate(
              `/unitscom/${window.location.pathname?.split('/')[2]}/${
                window.location.pathname?.split('/')[3]
              }`
            )
          })
          .catch((err) => ErrorToast(err.message))
      }
    } else if (propertiType === 'villa') {
      if (isEdit) {
        delete formData._id
        delete formData.updatedBy
        delete formData.status
        const body = {...formData, managerId: '6329ee43396e812bcc0964e5', id: updateDataId}
        ApiPut('corporate/unit', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building()
            setIsEdit(false)
            console.log('addProperty', res)
            setShowCreateAppModal(false)
            setFormData({propertyId: window.location.pathname?.split('/')[2]})
          })
          .catch((err) => ErrorToast(err.message))
      } else {
        // const body = {...formData, managerId: '6329ee43396e812bcc0964e5'}
        const body = {
          unitNo: formData?.unitNo,
          bedrooms: formData?.bedrooms,
          size: formData?.size,
          sizeType: formData?.sizeType,
          occupy: create ? 1 : 0,
          unitType: 'villa',
          images: [imgUrl],
          premiseNo: formData?.premiseNo,
          managerId: formData?.propertyManager,
          // propertyId: formData?.propertyId,
          floorId: formData?.propertyAreaId,
          clusterId: window.location.pathname.split('/')[3],
          communityId: window.location.pathname.split('/')[2],
        }
        ApiPost('corporate/unit', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building()
            console.log('addProperty', res)
            setShowCreateAppModal(false)
            setFormData({propertyId: window.location.pathname?.split('/')[2]})
            navigate(
              `/unitscom/${window.location.pathname?.split('/')[2]}/${
                window.location.pathname?.split('/')[3]
              }`
            )
          })
          .catch((err) => ErrorToast(err.message))
      }
    } else if (propertiType === 'CommonArea') {
      if (isEdit) {
        delete formData._id
        delete formData.updatedBy
        delete formData.status
        const body = {...formData, managerId: '6329ee43396e812bcc0964e5', id: updateDataId}
        ApiPut('corporate/properties_area_unit', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building()
            setIsEdit(false)
            console.log('addProperty', res)
            setShowCreateAppModal(false)
            setFormData({propertyId: window.location.pathname?.split('/')[2]})
          })
          .catch((err) => ErrorToast(err.message))
      } else {
        // const body = {...formData, managerId: '6329ee43396e812bcc0964e5'}
        const body = {
          unitNo: formData?.commonAreaName,
          occupy: create ? 1 : 0,
          unitType: 'common_area',
          images: [imgUrl],
          premiseNo: formData?.premiseNo,
          managerId: formData?.propertyManager,
          // propertyId: formData?.propertyId,
          // floorId: formData?.propertyAreaId,
          clusterId: window.location.pathname.split('/')[3],
          communityId: window.location.pathname.split('/')[2],
        }
        ApiPost('corporate/unit', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building()
            console.log('addProperty', res)
            setShowCreateAppModal(false)
            setFormData({propertyId: window.location.pathname?.split('/')[2]})
            navigate(
              `/unitscom/${window.location.pathname?.split('/')[2]}/${
                window.location.pathname?.split('/')[3]
              }`
            )
          })
          .catch((err) => ErrorToast(err.message))
      }
    } else if (propertiType === 'Other') {
      if (isEdit) {
        delete formData._id
        delete formData.updatedBy
        delete formData.status
        const body = {...formData, managerId: '6329ee43396e812bcc0964e5', id: updateDataId}
        ApiPut('corporate/properties_area_unit', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building()
            setIsEdit(false)
            console.log('addProperty', res)
            setShowCreateAppModal(false)
            setFormData({propertyId: window.location.pathname?.split('/')[2]})
          })
          .catch((err) => ErrorToast(err.message))
      } else {
        // const body = {...formData, managerId: '6329ee43396e812bcc0964e5'}
        const body = {
          unitNo: formData?.unitNo,
          bedrooms: formData?.bedrooms,
          size: formData?.size,
          sizeType: formData?.sizeType,
          occupy: create ? 1 : 0,
          images: [imgUrl],
          unitType: 'other',
          otherName: formData?.unitType,
          premiseNo: formData?.premiseNo,
          managerId: formData?.propertyManager,
          // propertyId: formData?.propertyId,
          floorId: formData?.propertyAreaId,
          clusterId: window.location.pathname.split('/')[3],
          communityId: window.location.pathname.split('/')[2],
        }
        ApiPost('corporate/unit', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building()
            console.log('addProperty', res)
            setShowCreateAppModal(false)
            setFormData({propertyId: window.location.pathname?.split('/')[2]})
            navigate(
              `/unitscom/${window.location.pathname?.split('/')[2]}/${
                window.location.pathname?.split('/')[3]
              }`
            )
          })
          .catch((err) => ErrorToast(err.message))
      }
    }
  }
  const getHeaderfData = () => {
    ApiGet(`corporate/cluster/${formData?.propertyAreaId}`)
      .then((res) => {
        console.log('res', res?.data?.data)
        setHeader(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }
  const getHeaderbData = () => {
    ApiGet(`corporate/communities/${formData?.propertyId}`)
      .then((res) => {
        console.log('res', res?.data?.data)
        setHeaderB(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    building()
    getHeaderfData()
    getHeaderbData()

    if (isEdit) {
      ApiGet(`corporate/properties_area_unit/${updateDataId}`)
        .then((response) => {
          console.log('response', response)
          setFormData(response?.data?.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [isEdit])

  const tabUpdate = (flg: any) => {
    setPropertiType(flg)
    setCreate(false)
  }
  return (
    <>
      <form onSubmit={handleSubmit((data: any, e: any) => onSubmit(formData, e))}>
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
          <div id='' className='app-container container-xxl flex-stack pt-0 mt-0'>
            <div className=' container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap mt-5'>
              <div className='d-flex align-items-center flex-wrap mr-1'>
                <div className='d-flex align-items-baseline flex-wrap mb-5'>
                  <ul className='d-flex m-0 p-0 gap-2 lists align-items-center'>
                    <li className=''>
                      <a
                        className='gray_color px-2 cursor-pointer '
                        onClick={() => navigate('/dashboard')}
                      >
                        Manage Properties
                      </a>
                    </li>
                    <li className='my-auto'>
                      <img src={rightarrow} />
                    </li>
                    <li className=''>
                      <a
                        className='gray_color px-2 cursor-pointer'
                        onClick={() => navigate('/community')}
                      >
                        Communities
                      </a>
                    </li>
                    <li className='my-auto'>
                      <img src={rightarrow} />
                    </li>
                    <li className=''>
                      <a className='gray_color px-2 cursor-pointer' onClick={() => navigate(-2)}>
                        Clusters
                      </a>
                    </li>
                    <li className='my-auto'>
                      <img src={rightarrow} />
                    </li>
                    <li className=''>
                      <a className='gray_color px-2 cursor-pointer' onClick={() => navigate(-1)}>
                        Unit
                      </a>
                    </li>
                    <li className='my-auto'>
                      <img src={rightarrow} />
                    </li>
                    <li className='active'>
                      <a className='green_color' style={{borderBottom: '1px solid #007a59'}}>
                        Add Unit
                      </a>
                    </li>
                  </ul>
                  {/* <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
                    <li className='breadcrumb-item '>
                      <a
                        className='text-muted px-2 cursor-pointer'
                        onClick={() => navigate('/dashboard')}
                      >
                        Manage Properties
                      </a>
                    </li>
                    <li className='breadcrumb-item '>
                      <a
                        className='text-muted px-2 cursor-pointer'
                        onClick={() => navigate('/building')}
                      >
                        Communities
                      </a>
                    </li>
                    <li className='breadcrumb-item '>
                      <a
                        className='text-muted px-2 cursor-pointer'
                        onClick={() =>
                          navigate(`/areaCom/${window.location.pathname.split('/')[2]}`)
                        }
                      >
                        Clusters
                      </a>
                    </li>
                    <li className='breadcrumb-item '>
                      <a
                        className='text-muted px-2 cursor-pointer'
                        // onClick={() => navigate('/building')}
                        onClick={() =>
                          navigate(
                            `/unitscom/${window.location.pathname.split('/')[2]}/${
                              window.location.pathname.split('/')[3]
                            }`
                          )
                        }
                      >
                        Unit
                      </a>
                    </li>
                    <li className='breadcrumb-item  active'>
                      <a className='px-2'>Add</a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
            <TitleHead title='Add Units' btnTitle='Add' />
            <SubHead
              one={{title: 'Community', data: headerB[0]?.name}}
              two={{title: 'Cluster Type', data: 'Units Cluster'}}
              three={{title: 'Cluster Name', data: header[0]?.name}}
            />

            <div
              className='d-flex align-items-center gap-2 gap-lg-3 mt-8 justfy-content-center'
              style={{justifyContent: 'center'}}
            >
              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                <li className='nav-item cursor-pointer' onClick={() => tabUpdate('Townhouse')}>
                  <a
                    className='nav-link text-active-primary pb-4 active text-center mx-10'
                    data-bs-toggle='tab'
                  >
                    <div className='mb-2'>
                      <img src={townhouse} alt='' width='30px' />
                    </div>
                    Townhouse
                  </a>
                </li>
                <li className='nav-item cursor-pointer' onClick={() => tabUpdate('villa')}>
                  <a
                    className='nav-link text-active-primary pb-4 text-center mx-10'
                    data-bs-toggle='tab'
                  >
                    <div className='svg-icon svg-icon-md svg-icon-primary mb-2'>
                      <img src={villa} alt='' width='30px' />
                    </div>
                    Villa
                  </a>
                </li>
                <li className='nav-item cursor-pointer' onClick={() => tabUpdate('CommonArea')}>
                  <a
                    className='nav-link text-active-primary pb-4 text-center mx-10'
                    data-bs-toggle='tab'
                  >
                    <div className='svg-icon svg-icon-md svg-icon-primary mb-2'>
                      <img src={common_area} alt='' width='30px' />
                    </div>
                    Common Area
                  </a>
                </li>
                <li className='nav-item cursor-pointer' onClick={() => tabUpdate('Other')}>
                  <a
                    className='nav-link text-active-primary pb-4 text-center mx-10'
                    data-bs-toggle='tab'
                  >
                    <div className='svg-icon svg-icon-md svg-icon-primary mb-2'>
                      <img src={other} alt='' width='30px' />
                    </div>
                    Other
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div id='kt_app_content_container' className='app-container container-xxl mt-10'>
            <div className='card p-5 grid-out-diff-gap0'>
              {propertiType === 'Townhouse' && (
                <>
                  <div className=' p-5 grid-out-diff-gap'>
                    <div className='tops text-muted'>
                      <div className=' text-muted mx-0 align-items-center '>
                        <LabelTitle title='Upload Image' htmlFor='unitNo' />
                        <div className='mx-1 value_black'>
                          <input
                            type='file'
                            className='form-control form-control-solid'
                            placeholder='e.g.-JAS001'
                            {...register('unitImage')}
                            name='unitImage'
                            onChange={imageChange}
                          />
                          {errors.unitImage && (
                            <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='side'>
                      <h3 className='green_color'> Unit Details </h3>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle title='Unit No' htmlFor='unitNo' />
                        <div className='mx-1 value_black'>
                          <input
                            type='text'
                            id='unitNo'
                            {...register('unitNo', {required: true})}
                            className='form-control form-control-solid'
                            placeholder='e.g.-JAS001'
                            name='unitNo'
                            value={formData?.unitNo}
                            onChange={handleChnage}
                          />
                          {errors.unitNo && (
                            <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                          )}
                        </div>
                      </div>

                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle
                          title='Bedrooms'
                          htmlFor='bedrooms1'
                          className='required form-label'
                        />
                        <select
                          name='bedrooms'
                          id='bedrooms1'
                          className='form-select form-select-solid'
                          value={formData?.bedrooms}
                          onChange={handleChnage}
                        >
                          <option disabled selected>
                            Select
                          </option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                          <option value={10}>10</option>
                        </select>
                      </div>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='size1' title='Size' />
                        <div className='d-flex'>
                          <input
                            type='number'
                            className='form-control form-control-solid mx-1'
                            placeholder='e.g. - 1000'
                            name='size'
                            id='size1'
                            value={formData?.size}
                            onChange={handleChnage}
                          />
                          <select
                            name='sizeType'
                            className='form-select form-select-solid'
                            value={formData?.sizeType}
                            onChange={handleChnage}
                          >
                            <option className='fst-italic' disabled selected>
                              Select
                            </option>
                            <option className='fst-italic' value={0}>
                              Sq ft
                            </option>
                            <option className='fst-italic' value={1}>
                              Sq m
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='exampleFormControlInput1' title=' Premise No' />
                        <input
                          type='number'
                          className='form-control form-control-solid'
                          placeholder='e.g.-52525252'
                          name='premiseNo'
                          value={formData?.premiseNo}
                          onChange={handleChnage}
                        />
                      </div>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='managerId1' title='Client Unit ID' />
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='e.g.-159753'
                          name='managerId'
                          id='managerId1'
                          value={formData?.managerId}
                          onChange={handleChnage}
                        />
                      </div>
                    </div>
                    <div className='side'>
                      <h3 className='green_color'>Tenancy</h3>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle className='vacant' title='Occupancy' />
                        <div className='d-flex'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm'>
                            <input
                              className='form-check-input'
                              type='radio'
                              value={0}
                              id='vacant'
                              name='occupancyType'
                              checked={formData?.OccupancyType}
                              onChange={handleChnage}
                              onClick={() => setCreate(false)}
                            />
                            <label className='form-check-label' htmlFor='vacant'>
                              Vacant
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm ms-3'>
                            <input
                              className='form-check-input'
                              type='radio'
                              value={1}
                              id='occupied'
                              name='occupancyType'
                              checked={formData?.OccupancyType}
                              onChange={handleChnage}
                              onClick={() => setCreate(true)}
                            />
                            <label className='form-check-label' htmlFor='occupied'>
                              occupied
                            </label>
                          </div>
                        </div>
                      </div>
                      <h3 className='green_color'>Manager</h3>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='propertyManager1' title='Property Manager' />

                        <select
                          name='propertyManager'
                          id='propertyManager1'
                          className='form-select form-select-solid'
                          value={formData?.propertyManager}
                          onChange={handleChnage}
                        >
                          <option disabled selected>
                            Select
                          </option>
                          <option value='John'>John</option>
                          <option value='Max'>Max</option>
                          <option value='Root'>Root</option>
                          {/* <option value='Al Ain'>Al Ain</option> */}
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {propertiType === 'villa' && (
                <>
                  <div className=' p-5 grid-out-diff-gap'>
                    <div className='tops text-muted'>
                      <div className=' text-muted mx-0 align-items-center '>
                        <LabelTitle title='Upload Image' htmlFor='unitNo' />
                        <div className='mx-1 value_black'>
                          <input
                            type='file'
                            className='form-control form-control-solid'
                            placeholder='e.g.-JAS001'
                            {...register('unitImage')}
                            name='unitImage'
                            onChange={imageChangePen}
                          />
                          {errors.unitImage && (
                            <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='side'>
                      <h3 className='green_color'> Unit Details </h3>

                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='unitNo1' title='Unit No' />
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='e.g.-JAS001'
                          name='unitNo'
                          id='unitNo1'
                          value={formData?.unitNo}
                          onChange={handleChnage}
                        />
                      </div>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='bedrooms1' title='Bedrooms' />
                        <select
                          name='bedrooms'
                          id='bedrooms1'
                          className='form-select form-select-solid'
                          value={formData?.bedrooms}
                          onChange={handleChnage}
                        >
                          <option disabled selected>
                            Select
                          </option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                          <option value={10}>10</option>
                        </select>
                      </div>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='size1' title='Size' />
                        <div className='d-flex'>
                          <input
                            type='number'
                            className='form-control form-control-solid mx-1'
                            placeholder='e.g. - 1000'
                            name='size'
                            id='size1'
                            value={formData?.size}
                            onChange={handleChnage}
                          />
                          <select
                            name='sizeType'
                            className='form-select form-select-solid'
                            value={formData?.sizeType}
                            onChange={handleChnage}
                          >
                            <option className='fst-italic' disabled selected>
                              Select
                            </option>
                            <option className='fst-italic' value={0}>
                              Sq ft
                            </option>
                            <option className='fst-italic' value={1}>
                              Sq m
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle title='Premise No' htmlFor='premiseNo1' />

                        <input
                          type='number'
                          className='form-control form-control-solid'
                          placeholder='Ex-52525252'
                          name='premiseNo'
                          id='premiseNo1'
                          value={formData?.premiseNo}
                          onChange={handleChnage}
                        />
                      </div>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='managerId1' title='Client Unit ID' />
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='e.g.-159753'
                          name='managerId'
                          id='managerId1'
                          value={formData?.managerId}
                          onChange={handleChnage}
                        />
                      </div>
                    </div>
                    <div className='side'>
                      <h3 className='green_color'>Tenancy</h3>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle title='Occupancy' htmlFor='Occupancy1' />
                        <div className='d-flex'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm'>
                            <input
                              className='form-check-input'
                              type='radio'
                              value={0}
                              id='vacant'
                              name='occupancyType'
                              // checked={formData?.OccupancyType}
                              checked={formData?.occupy === 0 ? true : false}
                              onChange={handleChnage}
                              onClick={() => setCreate(false)}
                            />
                            <label className='form-check-label' htmlFor='vacant'>
                              Vacant
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm ms-3'>
                            <input
                              className='form-check-input'
                              type='radio'
                              value={1}
                              id='occupied'
                              name='occupancyType'
                              // checked={formData?.OccupancyType}
                              checked={formData?.occupy === 1 ? true : false}
                              onChange={handleChnage}
                              onClick={() => setCreate(true)}
                            />
                            <label className='form-check-label' htmlFor='occupied'>
                              occupied
                            </label>
                          </div>
                        </div>
                      </div>
                      <h3 className='green_color'>Manager</h3>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='propertyManager1' title='Property Manager' />
                        <select
                          name='propertyManager'
                          id='propertyManager1'
                          className='form-select form-select-solid'
                          value={formData?.propertyManager}
                          onChange={handleChnage}
                        >
                          <option disabled selected>
                            Select
                          </option>
                          <option value='John'>John</option>
                          <option value='Max'>Max</option>
                          <option value='Root'>Root</option>
                          {/* <option value='Al Ain'>Al Ain</option> */}
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {propertiType === 'CommonArea' && (
                <>
                  <div className=' p-5 grid-out-diff-gap'>
                    <div className='tops text-muted'>
                      <div className=' text-muted mx-0 align-items-center '>
                        <LabelTitle title='Upload Image' htmlFor='unitNo' />
                        <div className='mx-1 value_black'>
                          <input
                            type='file'
                            className='form-control form-control-solid'
                            placeholder='e.g.-JAS001'
                            {...register('unitImage')}
                            name='unitImage'
                            onChange={imageChangeCom}
                          />
                          {errors.unitImage && (
                            <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='side'>
                      <h3 className='green_color'> Unit Details </h3>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='commonAreaName1' title='Common Area Name' />
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='e.g.-Gym'
                          name='commonAreaName'
                          id='commonAreaName1'
                          value={formData?.commonAreaName}
                          onChange={handleChnage}
                        />
                      </div>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='managerId1' title='Client Property Id' />
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='e.g.-159753'
                          name='managerId'
                          id='managerId1'
                          value={formData?.managerId}
                          onChange={handleChnage}
                        />
                      </div>
                    </div>
                    <div className='side'>
                      <h3 className='green_color'>Manager</h3>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='propertyManager1' title='Property Manager' />
                        <select
                          name='propertyManager'
                          id='propertyManager1'
                          className='form-select form-select-solid'
                          value={formData?.propertyManager}
                          onChange={handleChnage}
                        >
                          <option disabled selected>
                            Select
                          </option>
                          <option value='John'>John</option>
                          <option value='Max'>Max</option>
                          <option value='Root'>Root</option>
                          {/* <option value='Al Ain'>Al Ain</option> */}
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {propertiType === 'Other' && (
                <>
                  <div className=' p-5 grid-out-diff-gap'>
                    <div className='tops text-muted'>
                      <div className=' text-muted mx-0 align-items-center '>
                        <LabelTitle title='Upload Image' htmlFor='unitNo' />
                        <div className='mx-1 value_black'>
                          <input
                            type='file'
                            className='form-control form-control-solid'
                            placeholder='e.g.-JAS001'
                            {...register('unitImage')}
                            name='unitImage'
                            onChange={imageChangeOth}
                          />
                          {errors.unitImage && (
                            <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className='side'>
                      <h3 className='green_color'> Unit Details </h3>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='unitNo1' title='Unit No' />
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='e.g.-JAS001'
                          name='unitNo'
                          id='unitNo1'
                          value={formData?.unitNo}
                          onChange={handleChnage}
                        />
                      </div>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='bedrooms1' title='Bedrooms' />
                        <select
                          name='bedrooms'
                          id='bedrooms1'
                          className='form-select form-select-solid'
                          value={formData?.bedrooms}
                          onChange={handleChnage}
                        >
                          <option disabled selected>
                            Select
                          </option>
                          <option value={1}>1</option>
                          <option value={2}>2</option>
                          <option value={3}>3</option>
                          <option value={4}>4</option>
                          <option value={5}>5</option>
                          <option value={6}>6</option>
                          <option value={7}>7</option>
                          <option value={8}>8</option>
                          <option value={9}>9</option>
                          <option value={10}>10</option>
                        </select>
                      </div>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle title='Unit Type' htmlFor='unitType1' />
                        <div>
                          <input
                            className='form-control form-control-solid'
                            type='text'
                            name='unitType'
                            id='unitType1'
                            placeholder='e.g.-34'
                            value={formData?.unitType}
                            onChange={handleChnage}
                          />
                        </div>
                      </div>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='exampleFormControlInput1' title='Size' />
                        <div className='d-flex'>
                          <input
                            type='number'
                            className='form-control form-control-solid mx-1'
                            placeholder='e.g. - 1000'
                            name='size'
                            value={formData?.size}
                            onChange={handleChnage}
                          />
                          <select
                            name='sizeType'
                            className='form-select form-select-solid'
                            value={formData?.sizeType}
                            onChange={handleChnage}
                          >
                            <option className='fst-italic' disabled selected>
                              Select
                            </option>
                            <option className='fst-italic' value={0}>
                              Sq ft
                            </option>
                            <option className='fst-italic' value={1}>
                              Sq m
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle htmlFor='exampleFormControlInput1' title='Premise No' />
                        <input
                          type='number'
                          className='form-control form-control-solid'
                          placeholder='e.g.-52525252'
                          name='premiseNo'
                          value={formData?.premiseNo}
                          onChange={handleChnage}
                        />
                      </div>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle title='Client Property Id' htmlFor='managerId1' />
                        <input
                          type='text'
                          className='form-control form-control-solid'
                          placeholder='e.g.-159753'
                          name='managerId'
                          id='managerId1'
                          value={formData?.managerId}
                          onChange={handleChnage}
                        />
                      </div>
                    </div>
                    <div className='side'>
                      <h3 className='green_color'>Tenancy</h3>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle title='Occupancy' htmlFor='occupancy' />
                        <div className='d-flex'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm'>
                            <input
                              className='form-check-input'
                              type='radio'
                              value={0}
                              id='vacant'
                              name='occupancyType'
                              checked={formData?.OccupancyType}
                              onChange={handleChnage}
                              onClick={() => setCreate(false)}
                            />
                            <label className='form-check-label' htmlFor='vacant'>
                              Vacant
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm ms-3'>
                            <input
                              className='form-check-input'
                              type='radio'
                              value={1}
                              id='occupied'
                              name='occupancyType'
                              checked={formData?.OccupancyType}
                              onChange={handleChnage}
                              onClick={() => setCreate(true)}
                            />
                            <label className='form-check-label' htmlFor='occupied'>
                              occupied
                            </label>
                          </div>
                        </div>
                      </div>
                      <h3 className='green_color pt-6'>Manager</h3>
                      <div className='text-muted mx-0 align-items-center '>
                        <LabelTitle title='Property Manager' htmlFor='exampleFormControlInput1' />
                        <select
                          name='propertyManager'
                          className='form-select form-select-solid'
                          value={formData?.propertyManager}
                          onChange={handleChnage}
                        >
                          <option disabled selected>
                            Select
                          </option>
                          <option value='John'>John</option>
                          <option value='Max'>Max</option>
                          <option value='Root'>Root</option>
                          {/* <option value='Al Ain'>Al Ain</option> */}
                        </select>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default UnitComForm
