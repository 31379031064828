import React from 'react'
import dragImg from '../../../../../img/drag-img.png'
import addImg from '../../../../../img/add-green-filled.png'
import removeImg from '../../../../../img/remove-filled.png'
import dynamicTextImg from '../../../../../img/dynamic-text.png'
import dynamicDocumentImg from '../../../../../img/dynamic-document.png'

interface SectionControlsProps {
  sectionId: string
  onDuplicate: (id: string) => void
  onDelete: (id: string) => void
  dragHandleProps: any
  selectedSection: any
  onShowingDynamicText: () => void
  onShowingDynamicDocument: () => void
  disableDynamicTextIcon: boolean
  disableDynamicDocumentIcon: boolean
  selectedSectionStyling: any
  disableDTextBCZofDocument:any
}

const SectionControls: React.FC<SectionControlsProps> = ({
  sectionId,
  onDuplicate,
  onDelete,
  dragHandleProps,
  selectedSection,
  onShowingDynamicText,
  onShowingDynamicDocument,
  disableDynamicTextIcon,
  disableDynamicDocumentIcon,
  selectedSectionStyling,
  disableDTextBCZofDocument
}) => {
  
  return (
    <div className={`section-controls-style ${selectedSection == sectionId ? '' : 'd-none'}`}>
      <img
        src={dragImg}
        height={16}
        width={16}
        className='mb-2 cursor-pointer'
        {...dragHandleProps}
      />
      <img
        src={addImg}
        height={16}
        width={16}
        className='mb-2 cursor-pointer'
        onClick={() => onDuplicate(sectionId)}
      />
      <img
        src={removeImg}
        height={16}
        width={16}
        className=' mb-2 cursor-pointer'
        onClick={() => onDelete(sectionId)}
      />
      <div className='position-relative'>
        {(disableDynamicTextIcon || disableDTextBCZofDocument) && (
          <div
            className='requestBoxOpacity z-index-2 border-radius-5px d-flex align-items-center justify-content-center'
            style={{
              cursor:'not-allowed',
              boxShadow: '0px 0px 20px 0px rgba(10, 24, 64, -0.8)',
            }}
          ></div>
        )}
        <img
          src={dynamicTextImg}
          height={16}
          width={16}
          className='mb-2 cursor-pointer'
          onClick={() => onShowingDynamicText()}
        />
      </div>
      <div className='position-relative'>
        {(disableDynamicTextIcon || disableDynamicDocumentIcon || selectedSectionStyling?.content.staticLink != null) && (
          <div
            className='requestBoxOpacity z-index-2 border-radius-5px d-flex align-items-center justify-content-center'
            style={{
              cursor:'not-allowed',
              boxShadow: '0px 0px 20px 0px rgba(10, 24, 64, -0.8)',
            }}
          ></div>
        )}
      <img
        src={dynamicDocumentImg}
        height={16}
        width={16}
        className='cursor-pointer'
        onClick={() => onShowingDynamicDocument()}
      />
       </div>
    </div>
  )
}

export default SectionControls
