import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Box, Grid} from '@mui/material'

import AddDeposit from './AddDeposit'
import DepositCardItem from './components/DepositCardItem'
import Filters from './components/Filters'
import CustomButton from '../../../../../../../components/Button/CustomButton'

import addImg from '../../../../../../../../img/add.png'

const Deposits = () => {
  const navigate = useNavigate()
  const handleAddClick = () => {
    // navigate(`/settings/add-late-payment-fee/${leasingType}/${leasingSubType}/1`)
  }

  return (
    <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3 mt-10 mx-2' style={{minHeight: '75vh'}}>
      <div className='card-body pt-0 mt-5 py-0 px-3'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className='labl-gry fs-4 mb-0 light-dark-theme-color'>
              Configure and customize the deposits on your leases. All deposits configured will automatically be entered
              into the lease you are creating and charged directly to your tenant once a booking or a lease is created.
            </p>
          </Grid>
        </Grid>

        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 3}}>
          <label className='head-text me-5 pe-3 fnt-600'>Search </label>
          <Filters />

          <Box sx={{marginLeft: 'auto'}}>
            <CustomButton
              text='Add Deposit'
              imgSrc={addImg}
              className='green-hollow-btn px-3 status-w-150'
              onClick={handleAddClick}
              imgSize={{height: 16, width: 16}}
            />
          </Box>
        </Box>

        {/*  Different Type of Deposits */}
        <Grid item xs={12}>
          <DepositCardItem
            name='Security Deposit'
            description='Allows you to set the security deposit amount on properties that are being booked for a lease and charge them directly to the prospect tenant.'
            path='/'
            configureCount={0}
          />
        </Grid>
      </div>
    </div>
  )
}

export default Deposits
