import React, {useCallback, useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import {Controller, useFormContext} from 'react-hook-form'
import PercentageInput from '../../../../../../components/PercentageInput'
import MultiSelectInput from '../../../../../../components/MultiSelectInput'
import {ApiGet} from '../../../../../../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../../../../../../../apiCommon/helpers/Toast'
import InputNotRequired from '../../../../../../../General/InputNotRequired'
import {
  InvoicingOptions,
  StartApplyingOptions,
  DueDateOptions,
  InstallmentOptions,
  CustomFrequencyOptions,
} from './Options'
import {formatAmount} from '../../../../../../../../../Utilities/utils'
import CustomFrequencyInput from '../../../../../../components/CustomFrequencyInput'

type ParentData = {
  chargeData: any
  onUpdateChargeData: any
}

const SingleCharge = ({chargeData, onUpdateChargeData}: ParentData) => {
  const {
    control,
    register,
    setValue,
    formState: {errors},
    trigger,
    reset,
  } = useFormContext()
  const [selectedCharge, setSelectedCharge] = useState<any>([])
  const [selectedInstallmentType, setSelectedInstallmentType] = useState<Array<InstallmentOptions | {}>>([])
  const [selectedDueDateType, setSelectedDueDateType] = useState<any>([])
  const [selectedInvoicingType, setSelectedInvoicingType] = useState<Array<InvoicingOptions>>([])
  const [selectedCustomFrequencyType, setSelectedCustomFrequencyType] = useState<Array<CustomFrequencyOptions>>([])
  const [selectedStartApplyingType, setSelectedStartApplyingType] = useState<Array<CustomFrequencyOptions>>([])

  const [chargeOptions, setChargeOptions] = useState<any>([
    {label: 'Total Rent Value', value: 0},
    {label: 'Single Rent Installment', value: 1},
  ])
  const [customFrequencyOptions, setCustomFrequencyOptions] = useState<Array<CustomFrequencyOptions>>([
    {
      label: 'Every Day',
      value: 0,
    },
    {
      label: 'Every Week',
      value: 1,
    },
    {
      label: 'Every Month',
      value: 2,
    },
  ])
  const invoicingOptions: Array<InvoicingOptions> = [
    {
      label: 'Enabled',
      value: true,
    },
    {
      label: 'Disabled',
      value: false,
    },
  ]
  const installmentOptions: Array<InstallmentOptions> = [
    {
      label: 'As Per Rent Installments',
      value: 0,
    },
    {
      label: 'Custom Split',
      value: 1,
    },
    {
      label: 'No Split',
      value: 2,
    },
  ]
  const startApplyingOptions: Array<StartApplyingOptions> = [
    {
      label: 'Start Date Of The Lease',
      value: 0,
    },
    {
      label: 'When A % Of The Payment In A Lease Are Received',
      value: 1,
    },
    {
      label: 'Manually Set The Start Date At The Time Of Creating The Booking Or Lease',
      value: 2,
    },
  ]
  const dueDateOptions: Array<DueDateOptions> = [
    {
      label: 'Time Charge Is Applied',
      value: 0,
    },
    {
      label: 'Days After Charge Is Applied',
      value: 1,
    },
    {
      label: 'Same Day Rent Installment Is Due',
      value: 2,
    },
  ]

  const subLabels = {
    minimumAmount:
      'Set a minimum amount in case the total amount of the fee calculated is less than the intended amount to charge.',
    VATPercentage: 'Set the amount of tax you want to apply to the charge once the fee is calculated.',
    feePercentage: 'Set the fee %',
    chargeLabel: 'Select the charge you want this fee to be calculated from:',
    splitInstallmentLabel: 'Select how you would like to split this charge:',
    dueDateLable: 'Set the due date for when you want your tenant to settle the payment for this charge:',
    invoicingLabel: 'Auto-generate invoices for the charge whenever it is applied and charged to the tenant.',
    customSplitLabel: 'How many installments do you want to split the charge?',
    startApplyingLable: 'When would you like to start applying this charge?',
    startApplyPercentagePaymentReceivedLabel:
      'The charge will first apply when the set % of payments for selected charge are received',
  }

  // const getFeeListTypes = async () => {
  //   await ApiGet(`corporate/lease_fee_list`)
  //     .then((res) => {
  //       // console.log('fee list',res?.data?.data)
  //       const feeList = res?.data?.data?.map((feeListItem: any) => ({
  //         value: feeListItem?._id,
  //         label: feeListItem?.name,
  //         type:
  //           feeListItem?.name?.toLowerCase() === 'total rent value'
  //             ? 0
  //             : feeListItem?.name?.toLowerCase() === 'single rent installment'
  //             ? 1
  //             : 2,
  //       }))
  //       setChargeOptions(feeList)
  //     })
  //     .catch((err) => {
  //       ErrorToast(err.message)
  //       if (err?.message === 'Your account has been suspended!') {
  //         window.location.pathname = '/auth'
  //         localStorage.clear()
  //       }
  //     })
  // }

  // useEffect(() => {
  //   getFeeListTypes()
  // }, [])

  // useEffect(() => {
  //   const updatedChargeData = {...chargeData}
  //   updatedChargeData.startApplyPercentagePaymentReceived = null
  //   updatedChargeData.startApplySelectChargeLeaseFeeListType = []
  //   onUpdateChargeData(updatedChargeData)
  // }, [chargeData?.startApplying])

  // useEffect(() => {
  //   setSelectedInvoicingType([
  //     chargeData?.autoInvoicingEnabled === true ? {label: 'Enabled', value: true} : {label: 'Disabled', value: false},
  //   ])
  // }, [chargeData])

  const calculateMaxValue = useCallback(() => {
    if (
      chargeData?.splitIntoInstallments?.length > 0 &&
      chargeData?.splitIntoInstallments?.[0]?.value === 1 &&
      chargeData?.customTimesApplied !== null &&
      chargeData?.customFrequencyType?.length > 0
    ) {
      // const currentYear = new Date().getFullYear()

      // Check if the current year is a leap year
      // const isLeapYear = (year: number) => {
      //   return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
      // }

      // Calculate max value based on frequency type
      if (chargeData?.customFrequencyType?.[0]?.value === 0) {
        // Daily option
        return 365
      } else if (chargeData?.customFrequencyType?.[0]?.value === 1) {
        // Weekly option - Max weeks in a year
        return 52
      } else if (chargeData?.customFrequencyType?.[0]?.value === 2) {
        // Monthly option
        return 12 // Months in a year are always 12
      }
    }

    return 365 // Default max value
  }, [chargeData?.customFrequencyType, chargeData?.customTimesApplied])

  return (
    <Row>
      <Col sm={12}>
        <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3 pb-10' style={{minHeight: '300px'}}>
          <div className='card-body pt-0 mt-5 py-0 px-3'>
            <h3 className='page-heading m-0 white-dark-theme-color fnt-700'>Fee Settings</h3>
            <p className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>
              Set the fee structure of this type.
            </p>

            {chargeData?.type != null && chargeData?.amountCalculationType != null && (
              <>
                {chargeData?.amountCalculationType == 1 && (
                  <div
                    className='row my-10'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className='col-6 col-md-5'>
                      <PercentageInput
                        isPercent={true}
                        className='width-300'
                        isDisabled={false}
                        iconSize={30}
                        required={true}
                        setRightPosition='10px'
                        type='number'
                        name='feePercentage'
                        value={chargeData?.feePercentage !== null ? chargeData?.feePercentage : ''}
                        label='Set Fee'
                        firstPlaceholder='Enter Percentage'
                        subLabel='Set the fee %'
                        secondPlaceHolder='%'
                        onChange={(newValue: any) => {
                          const updateLatePaymentFields = {...chargeData}
                          if (newValue) {
                            updateLatePaymentFields.feePercentage = newValue
                          } else {
                            updateLatePaymentFields.feePercentage = null
                          }
                          onUpdateChargeData(updateLatePaymentFields)
                        }}
                      />
                    </div>
                    <div className='col-6 col-md-7 ps-12 multi-select-wrapper'>
                      {/* <MultiSelectInput
                        wrapperStyle='width-300'
                        label='Select Charge'
                        name='selectCharge'
                        subLabel={subLabels.chargeLabel}
                        chargeOptions={chargeOptions}
                        selectedCharge={chargeData?.selectLeaseChargeType}
                        setSelectedCharge={(newVal: any) => {
                          const updateLatePaymentFields = {...chargeData}
                          updateLatePaymentFields.selectChargeLeaseFeeListId = newVal
                          updateLatePaymentFields.selectLeaseChargeType = newVal
                          onUpdateChargeData(updateLatePaymentFields)
                        }}
                        required={true}
                        disableSearch={true}
                      /> */}
                      <MultiSelectInput
                        wrapperStyle='width-300'
                        name='selectCharge'
                        label='Select Charge'
                        subLabel={subLabels.chargeLabel}
                        chargeOptions={chargeOptions}
                        selectedCharge={chargeData?.selectLeaseChargeType}
                        setSelectedCharge={(newVal: any) => {
                          const updateLatePaymentFields = {...chargeData}
                          updateLatePaymentFields.selectChargeLeaseFeeListId = newVal
                          updateLatePaymentFields.selectLeaseChargeType = newVal
                          onUpdateChargeData(updateLatePaymentFields)
                        }}
                        required={true}
                        disableSearch={true}
                      />
                      <p
                        className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1 width-300'
                        style={{color: '#a4a4a4'}}
                      >
                        <i>
                          You will only be able to select a charge with a fixed fee or defined at the time of creating
                          the lease.
                        </i>
                      </p>
                    </div>
                  </div>
                )}

                {chargeData?.amountCalculationType == 0 && (
                  <div
                    className='row my-10'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className='col-6 col-md-5'>
                      <PercentageInput
                        isPercent={false}
                        className='width-300'
                        isDisabled={false}
                        iconSize={30}
                        required={true}
                        setRightPosition='10px'
                        type='number'
                        name='fixedAmount'
                        value={chargeData?.fixedAmount !== null ? chargeData?.fixedAmount : ''}
                        label='Set Fee'
                        firstPlaceholder='Enter Amount'
                        subLabel='Set the fee amount'
                        secondPlaceHolder='AED'
                        onChange={(newValue: any) => {
                          const updateLatePaymentFields = {...chargeData}
                          if (newValue) {
                            updateLatePaymentFields.fixedAmount = newValue
                          } else {
                            updateLatePaymentFields.fixedAmount = null
                          }
                          onUpdateChargeData(updateLatePaymentFields)
                        }}
                      />
                    </div>
                    <div className='col-6 col-md-7 ps-12'>
                      <PercentageInput
                        isPercent={true}
                        className='width-300'
                        isDisabled={false}
                        iconSize={30}
                        required={false}
                        setRightPosition='10px'
                        type='number'
                        name='VATPercentage'
                        value={chargeData?.VATPercentage !== null ? chargeData?.VATPercentage : ''}
                        label='VAT'
                        firstPlaceholder='Enter Percentage'
                        subLabel={subLabels.VATPercentage}
                        secondPlaceHolder='%'
                        onChange={(newValue: any) => {
                          const updateLatePaymentFields = {...chargeData}
                          if (newValue) {
                            updateLatePaymentFields.VATPercentage = newValue
                          } else {
                            updateLatePaymentFields.VATPercentage = null
                          }
                          onUpdateChargeData(updateLatePaymentFields)

                          // setTimeout(() => {
                          //   setIsChangeHappened(Date.now())
                          // }, 500)
                        }}
                      />
                      <div className='d-flex align-items-center '>
                        <label className={`percentage-input-label white-dark-theme-color status-w-150`}>VAT Rate</label>
                        <p
                          className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1'
                          style={{color: '#a4a4a4'}}
                        >
                          {chargeData?.previewData.VATRate != null
                            ? `AED ${formatAmount(chargeData?.previewData.VATRate)}`
                            : 'AED -'}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

                {chargeData?.amountCalculationType == 1 && (
                  <div
                    className='row my-10'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      // marginTop: '20px',
                    }}
                  >
                    <div className='col-6 col-md-5'>
                      <PercentageInput
                        isPercent={true}
                        className='width-300'
                        isDisabled={false}
                        iconSize={30}
                        required={false}
                        setRightPosition='10px'
                        type='number'
                        name='VATPercentage'
                        value={chargeData?.VATPercentage !== null ? chargeData?.VATPercentage : ''}
                        label='VAT'
                        firstPlaceholder='Enter Percentage'
                        subLabel={subLabels.VATPercentage}
                        secondPlaceHolder='%'
                        onChange={(newValue: any) => {
                          const updateLatePaymentFields = {...chargeData}
                          if (newValue) {
                            updateLatePaymentFields.VATPercentage = newValue
                          } else {
                            updateLatePaymentFields.VATPercentage = null
                          }
                          onUpdateChargeData(updateLatePaymentFields)

                          // setTimeout(() => {
                          //   setIsChangeHappened(Date.now())
                          // }, 500)
                        }}
                      />
                      <div className='d-flex align-items-center '>
                        <label className={`percentage-input-label white-dark-theme-color status-w-150`}>VAT Rate</label>
                        <p
                          className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1'
                          style={{color: '#a4a4a4'}}
                        >
                          AED -
                        </p>
                      </div>
                      {chargeData?.VATPercentage != null && (
                        <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1'>
                          Will be calculated at the time of defining the charge value in the lease.
                        </p>
                      )}
                    </div>
                    <div className='col-6 col-md-7 ps-12'>
                      <PercentageInput
                        className='width-300'
                        isDisabled={false}
                        iconSize={30}
                        required={true}
                        setRightPosition='10px'
                        type='number'
                        name='minimumAmount'
                        value={chargeData?.minimumAmount !== null ? chargeData?.minimumAmount : ''}
                        label='Minimum Amount'
                        firstPlaceholder='Enter Amount'
                        subLabel={subLabels.minimumAmount}
                        secondPlaceHolder='AED'
                        onChange={(newValue: any) => {
                          const updateLatePaymentFields = {...chargeData}
                          if (newValue) {
                            updateLatePaymentFields.minimumAmount = newValue
                          } else {
                            updateLatePaymentFields.minimumAmount = null
                          }
                          onUpdateChargeData(updateLatePaymentFields)
                        }}
                      />
                    </div>
                  </div>
                )}

                <div
                  className='row my-10'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    // marginTop: '20px',
                  }}
                >
                  <div className='col-6 col-md-5 multi-select-wrapper'>
                    <MultiSelectInput
                      disableSearch={true}
                      name='splitIntoInstallments'
                      labelContainerHeight='70px'
                      wrapperStyle='width-300'
                      label='Split into Installments'
                      subLabel={subLabels.splitInstallmentLabel}
                      chargeOptions={installmentOptions}
                      selectedCharge={chargeData?.splitIntoInstallments}
                      // setSelectedCharge={setSelectedInstallmentType}
                      setSelectedCharge={(newVal: any) => {
                        const updateLatePaymentFields = {...chargeData}
                        updateLatePaymentFields.splitIntoInstallments = newVal

                        updateLatePaymentFields.dueDateType = []
                        updateLatePaymentFields.daysAfterChargeIsApplied = null
                        updateLatePaymentFields.customTimesApplied = null
                        updateLatePaymentFields.startApplyPercentagePaymentReceived = null
                        updateLatePaymentFields.customFrequencyType = []
                        updateLatePaymentFields.startApplying = []
                        updateLatePaymentFields.startApplySelectChargeLeaseFeeListType = []

                        if (newVal?.[0]?.value === 0) {
                          const dueDateDefaultOpt = dueDateOptions?.filter((dateItem: DueDateOptions) => {
                            return dateItem.value === 2
                          })
                          setValue("dueDate",dueDateDefaultOpt,{shouldValidate:true})
                          updateLatePaymentFields.dueDateType = dueDateDefaultOpt
                          // reset({dueDate: dueDateDefaultOpt})
                        }
                        onUpdateChargeData(updateLatePaymentFields)
                      }}
                      required={true}
                    />
                    <div className='d-flex align-items-center mt-3'>
                      <label className={`percentage-input-label white-dark-theme-color status-w-150`}>
                        Daily Fee Rate
                      </label>
                      <p
                        className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1'
                        style={{color: '#a4a4a4'}}
                      >
                        AED -
                      </p>
                    </div>

                    <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1'>
                      Will be calculated at the time of defining the charge value in the lease.
                    </p>
                  </div>
                  <div className={`col-6 col-md-7 ps-12 multi-select-wrapper ${chargeData?.splitIntoInstallments?.[0]?.value == 0 ? 'd-none-dropdown-arrow' : ''}`}>
                    <MultiSelectInput
                      showRemove={false}
                      name='dueDate'
                      disableSearch={true}
                      required={true}
                      labelContainerHeight='70px'
                      wrapperStyle='width-300'
                      label='Due Date'
                      subLabel={subLabels.dueDateLable}
                      chargeOptions={dueDateOptions?.filter((dateItem: DueDateOptions) => {
                        if (chargeData?.splitIntoInstallments?.[0]?.value !== 0) {
                          return dateItem?.value !== 2
                        } else if (chargeData?.splitIntoInstallments?.[0]?.value === 0) {
                          return dateItem?.value === 2
                        }
                      })}
                      selectedCharge={chargeData?.dueDateType}
                      setSelectedCharge={(newVal: any) => {
                        const updateLatePaymentFields = {...chargeData}
                        updateLatePaymentFields.dueDateType = newVal
                        onUpdateChargeData(updateLatePaymentFields)
                      }}
                      disableInput={chargeData?.splitIntoInstallments?.[0]?.value == 0}
                    />

                    {chargeData?.dueDateType?.length > 0 && chargeData?.dueDateType?.[0].value === 1 && (
                      <div className='width-300 mt-3'>
                        <PercentageInput
                          className='days-after-payment text-end'
                          isDisabled={false}
                          iconSize={30}
                          required={true}
                          setRightPosition='10px'
                          type='number'
                          name='daysAfterChargeIsApplied'
                          value={
                            chargeData?.daysAfterChargeIsApplied !== null ? chargeData?.daysAfterChargeIsApplied : ''
                          }
                          firstPlaceholder='-'
                          secondPlaceHolder='Days'
                          onChange={(newValue: any) => {
                            const updateLatePaymentFields = {...chargeData}
                            if (newValue) {
                              updateLatePaymentFields.daysAfterChargeIsApplied = newValue
                            } else {
                              updateLatePaymentFields.daysAfterChargeIsApplied = null
                            }
                            onUpdateChargeData(updateLatePaymentFields)
                          }}
                          minValue={1}
                          maxValue={365}
                          errorClasees='status-w-300 text-end'
                        />
                      </div>
                    )}
                  </div>
                </div>

                {chargeData?.splitIntoInstallments?.length > 0 && chargeData?.splitIntoInstallments?.[0]?.value === 2 && (
                  <div
                    className='row my-10'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      // marginTop: '20px',
                    }}
                  >
                    <div className='col-6 col-md-7 multi-select-wrapper'>
                      <MultiSelectInput
                        name='startApplying'
                        disableSearch={true}
                        required={true}
                        wrapperStyle='width-300'
                        label='Start Applying'
                        subLabel={subLabels.startApplyingLable}
                        chargeOptions={startApplyingOptions}
                        selectedCharge={chargeData?.startApplying}
                        setSelectedCharge={(newVal: any) => {
                          const updateLatePaymentFields = {...chargeData}
                          updateLatePaymentFields.startApplyPercentagePaymentReceived = null
                          updateLatePaymentFields.startApplySelectChargeLeaseFeeListType = []
                          updateLatePaymentFields.startApplying = newVal
                          onUpdateChargeData(updateLatePaymentFields)
                        }}
                      />
                    </div>
                  </div>
                )}

                {chargeData?.startApplying?.length > 0 &&
                  chargeData?.splitIntoInstallments?.[0]?.value === 2 &&
                  chargeData?.startApplying?.[0]?.value === 1 && (
                    <div
                      className='row my-10'
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        // alignItems:'start'
                        // marginTop: '20px',
                      }}
                    >
                      {chargeData?.startApplying?.[0]?.value === 1 && (
                        <div className='col-6 col-md-5'>
                          <PercentageInput
                            isPercent={true}
                            key={'startApplyPercentagePaymentReceived'}
                            className='width-300'
                            label='Set % of Payments Received'
                            subLabel={subLabels?.startApplyPercentagePaymentReceivedLabel}
                            isDisabled={false}
                            iconSize={30}
                            required={true}
                            setRightPosition='10px'
                            type='number'
                            name='startApplyPercentagePaymentReceived'
                            value={
                              chargeData?.startApplyPercentagePaymentReceived != null
                                ? chargeData?.startApplyPercentagePaymentReceived
                                : ''
                            }
                            firstPlaceholder='-'
                            secondPlaceHolder='%'
                            onChange={(newValue: any) => {
                              const updateLatePaymentFields = {...chargeData}
                              if (newValue) {
                                updateLatePaymentFields.startApplyPercentagePaymentReceived = newValue
                              } else {
                                updateLatePaymentFields.startApplyPercentagePaymentReceived = null
                              }
                              onUpdateChargeData(updateLatePaymentFields)
                            }}
                          />
                        </div>
                      )}

                      {/* Select Specific charge */}
                      {chargeData?.startApplying?.[0]?.value === 1 && (
                        <div className='col-6 col-md-7 ps-12'>
                          <MultiSelectInput
                            name='startApplyingSelectCharge'
                            label='Select Charge'
                            subLabel={subLabels?.chargeLabel}
                            isMultiSelect={false}
                            required={true}
                            disableSearch={true}
                            wrapperStyle='width-300'
                            chargeOptions={chargeOptions}
                            selectedCharge={chargeData?.startApplySelectChargeLeaseFeeListType}
                            setSelectedCharge={(newVal: any) => {
                              const totalRentValue = 'Total Rent Value'
                              const singleRentInstallment = 'Single Rent Installment'

                              const updateLatePaymentFields = {...chargeData}

                              // if (newVal.some((item: any) => item.label === totalRentValue)) {
                              //   // Remove Single Rent Installment if Total Rent Value is selected
                              //   newVal = newVal.filter((item: any) => item.label !== singleRentInstallment)
                              // } else if (newVal.some((item: any) => item.label === singleRentInstallment)) {
                              //   // Remove Total Rent Value if Single Rent Installment is selected
                              //   newVal = newVal.filter((item: any) => item.label !== totalRentValue)
                              // }

                              updateLatePaymentFields.startApplySelectChargeLeaseFeeListId = newVal
                              updateLatePaymentFields.startApplySelectChargeLeaseFeeListType = newVal
                              onUpdateChargeData(updateLatePaymentFields)
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}

                {/* custom split */}
                {chargeData?.splitIntoInstallments?.length > 0 && chargeData?.splitIntoInstallments?.[0]?.value === 1 && (
                  <div
                    className='row my-10'
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className='col-6 col-md-5 multi-select-wrapper'>
                      <div className='percentage-label-container'>
                        <label className={`percentage-input-label white-dark-theme-color required`}>Custom Split</label>
                        <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2'>
                          {subLabels.customSplitLabel}
                        </p>
                      </div>
                      <div
                        // className='row'
                        style={{
                          display: 'flex',
                        }}
                      >
                        <div className=''>
                          {/* <PercentageInput */}
                          <CustomFrequencyInput
                           chrgData={chargeData}
                           setChrgData={onUpdateChargeData}
                            minValue={1}
                            maxValue={calculateMaxValue()}
                            className='width-140'
                            isDisabled={false}
                            iconSize={30}
                            required={true}
                            setRightPosition='10px'
                            type='number'
                            name='installmentFrequency'
                            value={chargeData?.customTimesApplied !== null ? chargeData?.customTimesApplied : ''}
                            firstPlaceholder='-'
                            secondPlaceHolder='Installments'
                            onChange={(newValue: any) => {
                              const updateLatePaymentFields = {...chargeData}
                              console.log('newValue', typeof newValue)
                              if (newValue) {
                                updateLatePaymentFields.customTimesApplied = newValue
                              } else {
                                updateLatePaymentFields.customTimesApplied = null
                              }
                              onUpdateChargeData(updateLatePaymentFields)
                            }}
                          />
                        </div>
                        <div className='ms-5'>
                          <MultiSelectInput
                            name='customFrequencyType'
                            required={true}
                            disableSearch={true}
                            wrapperStyle='width-140'
                            chargeOptions={customFrequencyOptions}
                            selectedCharge={chargeData?.customFrequencyType}
                            setSelectedCharge={(newVal: any) => {
                              const updateLatePaymentFields = {...chargeData}
                              updateLatePaymentFields.customFrequencyType = newVal
                              onUpdateChargeData(updateLatePaymentFields)
                            }}
                          />
                        </div>
                      </div>
                      {chargeData?.customTimesApplied && chargeData?.customFrequencyType?.length > 0 && (
                        <p
                          className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2'
                          style={{color: '#a4a4a4'}}
                        >
                          {`Charge will be Applied ${
                            chargeData?.customFrequencyType?.[0]?.value === 0
                              ? 'daily'
                              : chargeData?.customFrequencyType?.[0]?.value === 1
                              ? 'Weekly'
                              : 'Monthly'
                          } until the number of times are completed.`}
                        </p>
                      )}
                    </div>
                    <div className='col-6 col-md-7 ps-12 multi-select-wrapper'>
                      <MultiSelectInput
                        name='startApplying'
                        disableSearch={true}
                        required={true}
                        wrapperStyle='width-300'
                        label='Start Applying'
                        subLabel={subLabels.startApplyingLable}
                        chargeOptions={startApplyingOptions}
                        selectedCharge={chargeData?.startApplying}
                        setSelectedCharge={(newVal: any) => {
                          const updateLatePaymentFields = {...chargeData}
                          updateLatePaymentFields.startApplyPercentagePaymentReceived = null
                          updateLatePaymentFields.startApplySelectChargeLeaseFeeListType = []
                          updateLatePaymentFields.startApplying = newVal
                          onUpdateChargeData(updateLatePaymentFields)
                        }}
                      />

                      {chargeData?.startApplying?.[0]?.value === 1 ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '20px',
                          }}
                        >
                          {/* <label className={`percentage-input-label  white-dark-theme-color required`}>
                            {'Set % of Payments Received'}
                          </label>
                          <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2'>
                            {subLabels?.startApplyPercentagePaymentReceivedLabel}
                          </p> */}
                          <PercentageInput
                            isPercent={true}
                            key={'startApplyPercentagePaymentReceived'}
                            className='width-300'
                            label='Set % of Payments Received'
                            subLabel={subLabels?.startApplyPercentagePaymentReceivedLabel}
                            isDisabled={false}
                            iconSize={30}
                            required={true}
                            setRightPosition='10px'
                            type='number'
                            name='startApplyPercentagePaymentReceived'
                            value={
                              chargeData?.startApplyPercentagePaymentReceived != null
                                ? chargeData?.startApplyPercentagePaymentReceived
                                : ''
                            }
                            firstPlaceholder='-'
                            secondPlaceHolder='%'
                            onChange={(newValue: any) => {
                              const updateLatePaymentFields = {...chargeData}
                              if (newValue) {
                                updateLatePaymentFields.startApplyPercentagePaymentReceived = newValue
                              } else {
                                updateLatePaymentFields.startApplyPercentagePaymentReceived = null
                              }
                              onUpdateChargeData(updateLatePaymentFields)
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}

                      {/* Select Specific charge */}
                      {chargeData?.startApplying?.[0]?.value === 1 ? (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '20px',
                          }}
                          // className='xyz tst'
                        >
                          {/* <label className={`percentage-input-label  white-dark-theme-color required `}>
                            {'Select Charges'}
                          </label>
                          <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2'>
                            {subLabels?.chargeLabel}
                          </p> */}
                          <MultiSelectInput
                            name='startApplyingSelectCharge'
                            label='Select Charge'
                            subLabel={subLabels?.chargeLabel}
                            isMultiSelect={false}
                            required={true}
                            disableSearch={true}
                            wrapperStyle='width-300'
                            chargeOptions={chargeOptions}
                            selectedCharge={chargeData?.startApplySelectChargeLeaseFeeListType}
                            setSelectedCharge={(newVal: any) => {
                              const totalRentValue = 'Total Rent Value'
                              const singleRentInstallment = 'Single Rent Installment'

                              const updateLatePaymentFields = {...chargeData}

                              // if (newVal.some((item: any) => item.label === totalRentValue)) {
                              //   // Remove Single Rent Installment if Total Rent Value is selected
                              //   newVal = newVal.filter((item: any) => item.label !== singleRentInstallment)
                              // } else if (newVal.some((item: any) => item.label === singleRentInstallment)) {
                              //   // Remove Total Rent Value if Single Rent Installment is selected
                              //   newVal = newVal.filter((item: any) => item.label !== totalRentValue)
                              // }

                              updateLatePaymentFields.startApplySelectChargeLeaseFeeListId = newVal
                              updateLatePaymentFields.startApplySelectChargeLeaseFeeListType = newVal
                              onUpdateChargeData(updateLatePaymentFields)
                            }}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}

                {/* Invoicing */}
                <div className='row my-10'>
                  <div className='col-6 col-md-5'>
                    <MultiSelectInput
                      showRemove={false}
                      name='invoicingType'
                      required={false}
                      disableSearch={true}
                      wrapperStyle='width-300'
                      label='Invoicing'
                      subLabel={subLabels.invoicingLabel}
                      chargeOptions={invoicingOptions}
                      selectedCharge={chargeData?.autoInvoicingEnabled}
                      setSelectedCharge={(newVal: any) => {
                        const updateLatePaymentFields = {...chargeData}
                        updateLatePaymentFields.autoInvoicingEnabled = newVal
                        onUpdateChargeData(updateLatePaymentFields)
                      }}
                    />
                  </div>

                  <div className='col-6 col-md-7 ps-12 position-relative'>
                    {chargeData?.autoInvoicingEnabled?.[0]?.value == false && (
                      <div className='tenantOpacityBox' style={{opacity: '0.7', cursor: 'not-allowed'}}></div>
                    )}
                    <div>
                      {/* consolidate */}
                      <div className='d-flex align-items-start tst mb-4 pt-7'>
                        <div style={{width: '30px', height: '30px', marginTop: '3px'}}>
                          <input
                            type='radio'
                            className='me-3 cursor-pointer form-check-input'
                            {...register('invoicingType', {
                              required: true,
                            })}
                            id='consolidate'
                            value='consolidate'
                            checked={chargeData?.invoicingType === 0}
                            onChange={(e: any) => {
                              const updateChargeData = {...chargeData}
                              updateChargeData.invoicingType = 0
                              onUpdateChargeData(updateChargeData)
                            }}
                          />
                        </div>
                        <div>
                          <p className='pe-13 fs-5 fnt-500 labl-gry light-dark-theme-color m-0'>
                            <label htmlFor='consolidate' className='cursor-pointer'>
                              Consolidate this charge with other charges that share the same due date under one invoice.
                            </label>
                          </p>
                        </div>
                      </div>

                      {/* separate */}
                      <div className='d-flex align-items-start tst'>
                        <div style={{width: '30px', height: '30px', marginTop: '3px'}}>
                          <input
                            type='radio'
                            className='me-3 cursor-pointer form-check-input'
                            {...register('invoicingType', {
                              required: true,
                            })}
                            id='separate'
                            value='separate'
                            checked={chargeData?.invoicingType === 1}
                            onChange={(e: any) => {
                              const updateChargeData = {...chargeData}
                              updateChargeData.invoicingType = 1
                              onUpdateChargeData(updateChargeData)
                            }}
                          />
                        </div>
                        <div>
                          <p className='pe-13 fs-5 fnt-500 labl-gry light-dark-theme-color m-0'>
                            <label htmlFor='separate' className='cursor-pointer'>
                              Generate a separate invoice for this charge.
                            </label>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default SingleCharge

// {chargeData?.type != null &&
//   chargeData?.amountCalculationType != null &&
//   chargeData?.amountCalculationType == 0 && (
//     <>

//     </>
//   )}
