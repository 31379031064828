import React, {useEffect, useState, useCallback} from 'react'
import PercentageInput from '../../../../../../components/PercentageInput'
import MultiSelectInput from '../../../../../../components/MultiSelectInput'
import CustomFrequencyInput from '../../../../../../components/CustomFrequencyInput'
import {useFormContext} from 'react-hook-form'
import {ApiGet} from '../../../../../../../../../apiCommon/helpers/API/ApiData'
import {dueDateRentOptions} from './Options'
import { formatAmount } from '../../../../../../../../../Utilities/utils'

type ParentData = {
  chrgData: any
  setChrgData: any
}

const RecurringCharge = ({chrgData, setChrgData}: ParentData) => {
  const {
    control,
    register,
    setValue,
    formState: {errors},
  } = useFormContext()

  const [chargeOptions, setChargeOptions] = useState<any>([
    {label: 'Total Rent Value', value: 0},
    {label: 'Single Rent Installment', value: 1},
  ])

  const [frequencyOptions, setFrequencyOptions] = useState<any>([
    {label: 'As Per Rent Installments', value: 0},
    {label: 'Daily', value: 1},
    {label: 'Weekly', value: 2},
    {label: 'Monthly', value: 3},
    {label: 'Custom', value: 4},
  ])

  const [dueDateOptions, setDueDateOptions] = useState<any>([
    {label: 'Time Charge is Applied', value: 0},
    {label: '‘Days’ After Charge Is Applied', value: 1},
  ])

  const [applicableOptions, setApplicableOptions] = useState<any>([
    {label: 'Start Date Of The Lease', value: 0},
    {label: 'When A % Of The Payments In A Lease Are Received', value: 1},
    {label: 'Manually Set The Start Date At The Time Of Creating The Booking Or Lease', value: 2},
  ])

  const [applicableEndOptions, setApplicableEndOptions] = useState<any>([
    {label: 'End Date Of The Lease', value: 0},
    {label: 'Until A % Of The Payments In A Lease Are Received', value: 1},
    {label: 'Manually Set The End Date At The Time Of Creating The Booking Or Lease', value: 2},
  ])

  const [invoicingOptions, setInvoicingOptions] = useState<any>([
    {label: 'Enabled', value: true},
    {label: 'Disabled', value: false},
  ])

  const subLabels = {
    minimumAmount:
      'Set a minimum amount in case the total amount of the fee calculated is less than the intended amount to charge.',
    VATPercentage:
      'Set the amount of tax you want to apply to the charge once the fee is calculated.',
    feePercentage: 'Set the fee %',
    chargeLabel: 'Select the charge you want this fee to be calculated from:',
    frequencyLabel: 'Select how frequent you want to apply this charge for:',
    dueDateLable:
      'Set the due date for when you want your tenant to settle the payment for this charge:',
    invoicingLabel:
      'Auto-generate invoices for the charge whenever it is applied and charged to the tenant.',
    startApplyingLabel: 'When would you like to start applying this charge?',
    endApplyingLabel: 'When would you like to stop applying this charge?',
    customFrequencyLabel: 'How many times do you want to apply this charge?',
  }

  const [customFrequencyOptions, setCustomFrequencyOptions] = useState<any>([
    {
      label: 'Every Day',
      value: 0,
    },
    {
      label: 'Every Week',
      value: 1,
    },
    {
      label: 'Every Month',
      value: 2,
    },
  ])

  const getChargesList = async () => {
    try {
      const response = await ApiGet('corporate/lease_fee_list')
      const options =
        response?.data?.data?.map((item: any) => ({
          label: item?.name,
          value: item?._id,
        })) || []
      setChargeOptions(options)
    } catch (error) {
      console.error('Failed to fetch charges list:', error)
    }
  }

  useEffect(() => {
    // getChargesList()
  }, [])

  const calculateMaxValue = useCallback(() => {
    if (
      chrgData?.frequency?.length > 0 &&
      chrgData?.frequency?.[0]?.value == 4 &&
      chrgData?.customTimesApplied !== null &&
      chrgData?.customFrequencyType?.length > 0
    ) {
      const currentYear = new Date().getFullYear()

      // Check if the current year is a leap year
      // const isLeapYear = (year: number) => {
      //   return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
      // }

      // Calculate max value based on frequency type
      if (chrgData?.customFrequencyType?.[0]?.value === 0) {
        // Daily option
        return 365
        // return isLeapYear(currentYear) ? 366 : 365
      } else if (chrgData?.customFrequencyType?.[0]?.value === 1) {
        // Weekly option - Max weeks in a year
        return 52
        // return isLeapYear(currentYear) ? 53 : 52
      } else if (chrgData?.customFrequencyType?.[0]?.value === 2) {
        // Monthly option
        return 12 // Months in a year are always 12
      }
    }

    return 365 // Default max value
  }, [chrgData?.customFrequencyType, chrgData?.customTimesApplied])

  // 
  const calculateDailyRate = () => {
    const updateLatePaymentFields = {...chrgData}
    if(chrgData?.type == 1 && chrgData?.amountCalculationType == 0 && chrgData?.frequency?.[0]?.value != 0){
      if (chrgData?.frequency?.[0]?.value == 1)
        //Daily
        updateLatePaymentFields.previewData.dailyRate =
          updateLatePaymentFields.previewData.feeAmount
      else if (chrgData?.frequency?.[0]?.value == 2)
        //Weekly
        updateLatePaymentFields.previewData.dailyRate =
          Number(updateLatePaymentFields.previewData.feeAmount) / 7
      else if (chrgData?.frequency?.[0]?.value == 3)
        //Monthly
        updateLatePaymentFields.previewData.dailyRate =
          Number(updateLatePaymentFields.previewData.feeAmount) / 30.4
      else if (chrgData?.frequency?.[0]?.value == 4) {
        updateLatePaymentFields.previewData.dailyRate = null
        //Custom 
        if (chrgData?.customFrequencyType?.[0]?.value == 0)
          //Custom Daily
          updateLatePaymentFields.previewData.dailyRate = Number(updateLatePaymentFields.previewData.feeAmount)
        else if (chrgData?.customFrequencyType?.[0]?.value == 1)
          //Custom Weekly
          updateLatePaymentFields.previewData.dailyRate = (Number(updateLatePaymentFields.previewData.feeAmount) / 7)
        else if (chrgData?.customFrequencyType?.[0]?.value == 2)
          //Custom Monthly
          updateLatePaymentFields.previewData.dailyRate = (Number(updateLatePaymentFields.previewData.feeAmount) / 30.4)

        setChrgData(updateLatePaymentFields)
      }
    }
    else{
      if (chrgData?.frequency?.[0]?.value == 0)
        //Daily
        updateLatePaymentFields.previewData.dailyRate = null
        setChrgData(updateLatePaymentFields)
    }
  }

  // 
  useEffect(()=>{
    calculateDailyRate()
  },[chrgData?.customFrequencyType, chrgData?.customTimesApplied, chrgData?.frequency])

  return (
    <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-7 px-3' style={{minHeight: '300px'}}>
      <div className='card-body pt-0 mt-5 py-0 px-3'>
        <h3 className='page-heading m-0 white-dark-theme-color fnt-700'>Fee Settings</h3>
        <p className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>
          Set the fee structure for this charge.
        </p>

        {chrgData?.type != null && chrgData?.amountCalculationType != null && (
          <>
            <div
              className='row xyz tst recurring'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '20px',
              }}
            >
              <div className='col-6 col-md-5'>
                {chrgData?.amountCalculationType == 1 ? (
                  // Fee Percentage
                  <PercentageInput
                    isDisabled={false}
                    iconSize={30}
                    required={true}
                    setRightPosition='10px'
                    type='number'
                    name='feePercentage'
                    value={chrgData?.feePercentage != null ? chrgData?.feePercentage : ''}
                    label='Set Fee'
                    firstPlaceholder='Enter Percentage'
                    subLabel='Set the fee %'
                    secondPlaceHolder='%'
                    onChange={(newValue: any) => {
                      const updateLatePaymentFields = {...chrgData}
                      updateLatePaymentFields.feePercentage = newValue != '' ? newValue : null
                      setChrgData(updateLatePaymentFields)

                      // setTimeout(() => {
                      //   setIsChangeHappened(Date.now())
                      // }, 500)
                    }}
                    isPercent={true}
                  />
                ) : (
                  // Fixed Fee
                  <PercentageInput
                    isDisabled={false}
                    iconSize={30}
                    required={true}
                    setRightPosition='10px'
                    type='number'
                    name='fixedAmount'
                    value={chrgData?.fixedAmount != null ? chrgData?.fixedAmount : ''}
                    label='Set Fee'
                    firstPlaceholder='Enter Amount'
                    subLabel='Set the fee amount'
                    secondPlaceHolder='AED'
                    onChange={(newValue: any) => {
                      const updateLatePaymentFields = {...chrgData}
                      updateLatePaymentFields.fixedAmount  = newValue != '' ? newValue : null
                      
                      if(updateLatePaymentFields.VATPercentage != null)
                        updateLatePaymentFields.previewData.VATAmountForFixes = (updateLatePaymentFields.VATPercentage/100)*updateLatePaymentFields.fixedAmount
                      else
                       updateLatePaymentFields.previewData.VATAmountForFixes = null


                      setChrgData(updateLatePaymentFields)

                      // setTimeout(() => {
                      //   setIsChangeHappened(Date.now())
                      // }, 500)
                    }}
                    isPercent={false}
                  />
                )}
              </div>

              {/* Select Charge */}
              {chrgData?.amountCalculationType == 1 && (
                <div className='col-6 col-md-7 ps-12 multi-select-wrapper'>
                  <MultiSelectInput
                    name='selectCharge'
                    label='Select Charge'
                    subLabel={subLabels.chargeLabel}
                    chargeOptions={chargeOptions}
                    selectedCharge={chrgData?.selectLeaseChargeType}
                    setSelectedCharge={(newVal: any) => {
                      const updateLatePaymentFields = {...chrgData}
                      updateLatePaymentFields.selectChargeLeaseFeeListId = newVal
                      updateLatePaymentFields.selectLeaseChargeType = newVal
                      setChrgData(updateLatePaymentFields)
                    }}
                    required={true}
                    disableSearch={true}
                  />
                  <p
                    className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1'
                    style={{color: '#a4a4a4'}}
                  >
                    <i>
                      You will only be able to select a charge with a fixed fee or defined at the
                      time of creating the lease.
                    </i>
                  </p>
                </div>
              )}

              {/* VAT */}
              <div
                className={`col-6  ${
                  chrgData?.amountCalculationType == 1 ? 'col-md-5 mt-10' : 'col-md-7 ps-12'
                }`}
              >
                <PercentageInput
                  isDisabled={false}
                  iconSize={30}
                  required={false}
                  setRightPosition='10px'
                  type='number'
                  name='VATPercentage'
                  value={chrgData?.VATPercentage != null ? chrgData?.VATPercentage : ''}
                  label='VAT'
                  firstPlaceholder='Enter Percentage'
                  subLabel={subLabels.VATPercentage}
                  secondPlaceHolder='%'
                  onChange={(newValue: any) => {
                    const updateLatePaymentFields = {...chrgData}
                    updateLatePaymentFields.VATPercentage = newValue != '' ? newValue : null
                   

                    if(updateLatePaymentFields.fixedAmount != null)
                      updateLatePaymentFields.previewData.VATAmountForFixes = (updateLatePaymentFields.VATPercentage/100)*updateLatePaymentFields.fixedAmount
                    else
                     updateLatePaymentFields.previewData.VATAmountForFixes = null

                     setChrgData(updateLatePaymentFields)

                    // setTimeout(() => {
                    //   setIsChangeHappened(Date.now())
                    // }, 500)
                  }}
                  isPercent={true}
                />
                <div className='d-flex align-items-center '>
                  <label className={`percentage-input-label white-dark-theme-color status-w-150`}>
                    VAT Rate
                  </label>
                  <p
                    className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1'
                    style={{color: '#a4a4a4'}}
                  >
                    AED {chrgData?.amountCalculationType == 0 ? formatAmount(chrgData?.previewData.VATAmountForFixes) : '-'}
                  </p>
                </div>
                {chrgData?.VATPercentage != null && chrgData?.amountCalculationType == 1 && (
                  <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1'>
                    Will be calculated at the time of defining the charge value in the lease.
                  </p>
                )}
              </div>

              {/* Minimum Amount */}
              {chrgData?.amountCalculationType == 1 && (
                <div className='col-6 col-md-7 ps-12 mt-10'>
                  <PercentageInput
                    isDisabled={false}
                    iconSize={30}
                    required={true}
                    setRightPosition='10px'
                    type='number'
                    name='minimumAmount'
                    value={chrgData?.minimumAmount != null ? chrgData?.minimumAmount : ''}
                    label='Minimum Amount'
                    firstPlaceholder='Enter Amount'
                    subLabel={subLabels.minimumAmount}
                    secondPlaceHolder='AED'
                    onChange={(newValue: any) => {
                      const updateLatePaymentFields = {...chrgData}
                      updateLatePaymentFields.minimumAmount = newValue != '' ? newValue : null
                      setChrgData(updateLatePaymentFields)

                      // setTimeout(() => {
                      //   setIsChangeHappened(Date.now())
                      // }, 500)
                    }}
                  />
                </div>
              )}

              {/* Frequency */}
              <div className='col-6 col-md-5 multi-select-wrapper mt-10'>
                <MultiSelectInput
                  label='Frequency'
                  name='frequency'
                  subLabel={subLabels.frequencyLabel}
                  chargeOptions={frequencyOptions}
                  selectedCharge={chrgData?.frequency}
                  setSelectedCharge={(newVal: any) => {
                    const updateLatePaymentFields = {...chrgData}
                    console.log(updateLatePaymentFields.frequency)
                    console.log(newVal?.[0]?.value)
                    if (
                      updateLatePaymentFields.frequency?.[0]?.value == 4 &&
                      newVal?.[0]?.value != 4
                    )
                      updateLatePaymentFields.startApplying = []

                    updateLatePaymentFields.frequency = newVal

                    if (newVal?.[0]?.value == 0) {
                      updateLatePaymentFields.dueDateType = [
                        {
                          label: 'Same Day Rent Installment Is Due',
                          value: 2,
                        },
                      ]

                      setDueDateOptions([
                        {
                          label: 'Same Day Rent Installment Is Due',
                          value: 2,
                        },
                      ])
                      setValue(`dueDate`, updateLatePaymentFields.dueDateType, {
                        shouldValidate: true,
                      })
                    } else {
                      if (
                        updateLatePaymentFields.dueDateType?.length > 0 &&
                        updateLatePaymentFields.dueDateType?.[0]?.value == 2
                      )
                        updateLatePaymentFields.dueDateType = []
                      setDueDateOptions([
                        {label: 'Time Charge is Applied', value: 0},
                        {label: '‘Days’ After Charge Is Applied', value: 1},
                      ])
                    }

                    setChrgData(updateLatePaymentFields)
                  }}
                  required={true}
                  disableSearch={true}
                />
                <div className='d-flex align-items-center mt-3'>
                  <label className={`percentage-input-label white-dark-theme-color status-w-150`}>
                    Daily Fee Rate
                  </label>
                  <p
                    className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1'
                    style={{color: '#a4a4a4'}}
                  >
                    AED {chrgData?.amountCalculationType == 0 && chrgData?.frequency?.[0]?.value != 0 && chrgData?.frequency?.[0]?.value != 4 && chrgData?.previewData?.dailyRate != null ? formatAmount(chrgData?.previewData?.dailyRate) : '-'}
                  </p>
                </div>

                <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1'>
                  Will be calculated at the time of defining the charge value in the lease.
                </p>
              </div>

              {/* Due Date Type */}
              <div className={`col-6 col-md-7 ps-12 multi-select-wrapper mt-10 ${chrgData?.frequency?.[0]?.value == 0 ? 'd-none-dropdown-arrow' : ''}`}>
                <MultiSelectInput
                  name='dueDate'
                  label='Due Date'
                  subLabel={subLabels.dueDateLable}
                  chargeOptions={
                    chrgData?.frequency?.[0]?.value == 0 ? dueDateRentOptions : dueDateOptions
                  }
                  selectedCharge={chrgData?.dueDateType}
                  setSelectedCharge={(newVal: any) => {
                    const updateLatePaymentFields = {...chrgData}
                    updateLatePaymentFields.dueDateType = newVal
                    setChrgData(updateLatePaymentFields)
                  }}
                  required={true}
                  disableSearch={true}
                  disableInput={chrgData?.frequency?.[0]?.value == 0}
                  showRemove={chrgData?.frequency?.[0]?.value == 0 ? false : true}
                />

                {chrgData?.dueDateType?.[0]?.value == 1 && (
                  <PercentageInput
                    isDisabled={false}
                    iconSize={30}
                    required={true}
                    setRightPosition='10px'
                    type='number'
                    name='days-after-due-date'
                    value={chrgData?.daysAfterChargeIsApplied != null ? chrgData?.daysAfterChargeIsApplied : ''}
                    label=''
                    firstPlaceholder='-'
                    subLabel={''}
                    secondPlaceHolder='Days'
                    onChange={(newValue: any) => {
                      console.log(newValue)
                      const updateLatePaymentFields = {...chrgData}
                      updateLatePaymentFields.daysAfterChargeIsApplied =
                        newValue != '' ? newValue : null
                      setChrgData(updateLatePaymentFields)

                      // setTimeout(() => {
                      //   setIsChangeHappened(Date.now())
                      // }, 500)
                    }}
                    className='days-after-payment mt-5'
                    minValue={1}
                    maxValue={365}
                    errorClasees='status-w-300 text-end'
                  />
                )}
              </div>

              {(chrgData?.frequency?.[0]?.value == 1 ||
                chrgData?.frequency?.[0]?.value == 2 ||
                chrgData?.frequency?.[0]?.value == 3) && (
                <>
                  {/* Start Applying */}
                  <div className='col-6 col-md-5 multi-select-wrapper mt-10'>
                    <MultiSelectInput
                      name='startApplying'
                      label='Start Applying'
                      subLabel={subLabels.startApplyingLabel}
                      chargeOptions={applicableOptions}
                      selectedCharge={chrgData?.startApplying}
                      setSelectedCharge={(newVal: any) => {
                        const updateLatePaymentFields = {...chrgData}
                        updateLatePaymentFields.startApplying = newVal
                        setChrgData(updateLatePaymentFields)
                      }}
                      required={true}
                      disableSearch={true}
                    />
                  </div>

                  {/* Stop Applying */}
                  <div className='col-6 col-md-7 ps-12 multi-select-wrapper mt-10'>
                    <MultiSelectInput
                      name='stopApplying'
                      label='Stop Applying'
                      subLabel={subLabels.endApplyingLabel}
                      chargeOptions={applicableEndOptions}
                      selectedCharge={chrgData?.endApplying}
                      setSelectedCharge={(newVal: any) => {
                        const updateLatePaymentFields = {...chrgData}
                        updateLatePaymentFields.endApplying = newVal
                        setChrgData(updateLatePaymentFields)
                      }}
                      required={true}
                      disableSearch={true}
                    />
                  </div>
                </>
              )}

              {/* Custom Frequency --- Times Applied */}
              {chrgData?.frequency?.[0]?.value == 4 && (
                <div
                  className='row my-10 mx-0 px-0'
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className='col-6 col-md-5 multi-select-wrapper'>
                    <div className='percentage-label-container'>
                      <label className={`percentage-input-label white-dark-theme-color required`}>
                        Times Applied
                      </label>
                      <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2'>
                        {subLabels.customFrequencyLabel}
                      </p>
                    </div>
                    <div
                      // className='row'
                      style={{
                        display: 'flex',
                      }}
                    >
                      <div className='custom-frequency'>
                        {/* <PercentageInput
                          minValue={2}
                          className='width-140'
                          isDisabled={false}
                          iconSize={30}
                          required={true}
                          setRightPosition='10px'
                          type='number'
                          name='installmentFrequency'
                          value={
                            chrgData?.customTimesApplied !== null
                              ? chrgData?.customTimesApplied
                              : ''
                          }
                          firstPlaceholder='-'
                          secondPlaceHolder='Times'
                          onChange={(newValue: any) => {
                            const updateLatePaymentFields = {...chrgData}
                            updateLatePaymentFields.customTimesApplied = newValue
                            setChrgData(updateLatePaymentFields)
                          }}
                        /> */}
                        <CustomFrequencyInput
                            minValue={1}
                            maxValue={calculateMaxValue()}
                            className='width-140'
                            isDisabled={false}
                            iconSize={30}
                            required={true}
                            setRightPosition='10px'
                            type='number'
                            name='installmentFrequency'
                            value={chrgData?.customTimesApplied !== null ? chrgData?.customTimesApplied : ''}
                            firstPlaceholder='-'
                            secondPlaceHolder='Times'
                            onChange={(newValue: any) => {
                              const updateLatePaymentFields = {...chrgData}
                              console.log('newValue', typeof newValue)
                              if (newValue) {
                                updateLatePaymentFields.customTimesApplied = newValue
                              } else {
                                updateLatePaymentFields.customTimesApplied = null
                              }
                              setChrgData(updateLatePaymentFields)
                            }}
                            isFrequency={true}
                            chrgData={chrgData}
                            setChrgData={setChrgData}
                          />

                      </div>
                      <div className='ms-5'>
                        <MultiSelectInput
                          name='customFrequencyType'
                          required={true}
                          disableSearch={true}
                          wrapperStyle='width-140'
                          chargeOptions={customFrequencyOptions}
                          selectedCharge={chrgData?.customFrequencyType}
                          setSelectedCharge={(newVal: any) => {
                            const updateLatePaymentFields = {...chrgData}
                            updateLatePaymentFields.customFrequencyType = newVal
                            setChrgData(updateLatePaymentFields)
                          }}
                        />
                      </div>
                    </div>
                    {chrgData?.customTimesApplied && chrgData?.customFrequencyType?.length > 0 && (
                      <p
                        className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2'
                        style={{color: '#a4a4a4'}}
                      >
                        Charge will be Applied once a
                        <u className='px-1'>
                          {chrgData?.customFrequencyType?.[0]?.value === 0
                            ? 'day'
                            : chrgData?.customFrequencyType?.[0]?.value === 1
                            ? 'week'
                            : 'month'}
                        </u>
                        until the number of times are completed.
                      </p>
                    )}
                  </div>

                  {/* Custom --- Start Applying */}
                  <div className='col-6 col-md-7 multi-select-wrapper ps-12'>
                    <MultiSelectInput
                      name='startApplying'
                      label='Start Applying'
                      subLabel={subLabels.startApplyingLabel}
                      chargeOptions={applicableOptions}
                      selectedCharge={chrgData?.startApplying}
                      setSelectedCharge={(newVal: any) => {
                        const updateLatePaymentFields = {...chrgData}
                        updateLatePaymentFields.startApplying = newVal
                        setChrgData(updateLatePaymentFields)
                      }}
                      required={true}
                      disableSearch={true}
                    />

                    {/* When % of Payments Is Received */}
                    {chrgData?.startApplying?.[0]?.value == 1 && (
                      <>
                        {/* Custom --- Start Applying --- Set % of Payments Received */}
                        <div className='mt-10'>
                          <PercentageInput
                            isDisabled={false}
                            iconSize={30}
                            required={true}
                            setRightPosition='10px'
                            type='number'
                            name='startApplyPercentagePaymentReceived'
                            value={chrgData?.startApplyPercentagePaymentReceived != null ? chrgData?.startApplyPercentagePaymentReceived : ''}
                            label='Set % of Payments Received'
                            firstPlaceholder='Enter Percentage'
                            subLabel='The charge will first apply when the set % of payments for selected charges are received'
                            secondPlaceHolder='%'
                            onChange={(newValue: any) => {
                              const updateLatePaymentFields = {...chrgData}
                              updateLatePaymentFields.startApplyPercentagePaymentReceived =
                                newValue != '' ? newValue : null

                              if (
                                updateLatePaymentFields?.endApplying?.[0]?.value == 1 &&
                                updateLatePaymentFields?.startApplyPercentagePaymentReceived != null
                              ) {
                                if (
                                  Number(
                                    updateLatePaymentFields?.startApplyPercentagePaymentReceived
                                  ) >=
                                  Number(updateLatePaymentFields?.endApplyPercentagePaymentReceived)
                                ) {
                                  updateLatePaymentFields.previewData.percentError =
                                    '% of Payments Received for Start Applying can not be greater than or equal to % of Payments Received for Stop Applying'
                                } else {
                                  updateLatePaymentFields.previewData.percentError = ''
                                }
                              } else {
                                updateLatePaymentFields.previewData.percentError = ''
                              }
                              setChrgData(updateLatePaymentFields)

                              // setTimeout(() => {
                              //   setIsChangeHappened(Date.now())
                              // }, 500)
                            }}
                            isPercent={true}
                            minValue={1}
                          />
                        </div>

                        {/* Custom --- Start Applying --- Select Charge */}
                        <div className='multi-select-wrapper mt-10'>
                          <MultiSelectInput
                            name='startApplyingSelectCharge'
                            label='Select Charges'
                            subLabel={
                              'The charge will first apply when the set % of payments for selected charges are received'
                            }
                            chargeOptions={chargeOptions}
                            selectedCharge={chrgData?.startApplySelectChargeLeaseFeeListType}
                            setSelectedCharge={(newVal: any) => {
                              console.log(newVal)
                              const totalRentValue = 'Total Rent Value'
                              const singleRentInstallment = 'Single Rent Installment'

                              const updateLatePaymentFields = {...chrgData}

                              // if (newVal.some((item: any) => item.label === totalRentValue)) {
                              //   // Remove Single Rent Installment if Total Rent Value is selected
                              //   newVal = newVal.filter(
                              //     (item: any) => item.label !== singleRentInstallment
                              //   )
                              // } else if (
                              //   newVal.some((item: any) => item.label === singleRentInstallment)
                              // ) {
                              //   // Remove Total Rent Value if Single Rent Installment is selected
                              //   newVal = newVal.filter((item: any) => item.label !== totalRentValue)
                              // }

                              updateLatePaymentFields.startApplySelectChargeLeaseFeeListId = newVal
                              updateLatePaymentFields.startApplySelectChargeLeaseFeeListType =
                                newVal
                              setChrgData(updateLatePaymentFields)
                            }}
                            required={true}
                            isMultiSelect={false}
                            disableSearch={true}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              {/* When % of Payments Is Received */}
              {(chrgData?.frequency?.[0]?.value == 1 ||
                chrgData?.frequency?.[0]?.value == 2 ||
                chrgData?.frequency?.[0]?.value == 3) &&
                chrgData?.startApplying?.[0]?.value == 1 && (
                  <div className='col-6 col-md-5 mt-10'>
                    <PercentageInput
                      isDisabled={false}
                      iconSize={30}
                      required={true}
                      setRightPosition='10px'
                      type='number'
                      name='startApplyPercentagePaymentReceived'
                      value={chrgData?.startApplyPercentagePaymentReceived != null ? chrgData?.startApplyPercentagePaymentReceived : ''}
                      label='Set % of Payments Received'
                      firstPlaceholder='Enter Percentage'
                      subLabel='The charge will first apply when the set % of payments for selected charges are received'
                      secondPlaceHolder='%'
                      onChange={(newValue: any) => {
                        const updateLatePaymentFields = {...chrgData}
                        updateLatePaymentFields.startApplyPercentagePaymentReceived =
                          newValue != '' ? newValue : null

                        if (
                          updateLatePaymentFields?.endApplying?.[0]?.value == 1 &&
                          updateLatePaymentFields?.startApplyPercentagePaymentReceived != null
                        ) {
                          if (
                            Number(updateLatePaymentFields?.startApplyPercentagePaymentReceived) >=
                            Number(updateLatePaymentFields?.endApplyPercentagePaymentReceived)
                          ) {
                            updateLatePaymentFields.previewData.percentError =
                              '% of Payments Received for Start Applying can not be greater than or equal to % of Payments Received for Stop Applying'
                          } else {
                            updateLatePaymentFields.previewData.percentError = ''
                          }
                        } else {
                          updateLatePaymentFields.previewData.percentError = ''
                        }
                        setChrgData(updateLatePaymentFields)

                        // setTimeout(() => {
                        //   setIsChangeHappened(Date.now())
                        // }, 500)
                      }}
                      isPercent={true}
                      minValue={1}
                    />

                    {chrgData?.previewData.percentError ==
                      '% of Payments Received for Start Applying can not be greater than or equal to % of Payments Received for Stop Applying' &&
                      chrgData?.endApplying?.[0]?.value == 1 && (
                        <p
                          style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                          className='m-0 mt-1 fnt-400'
                        >
                          {chrgData?.previewData.percentError}
                        </p>
                      )}

                    <div className='multi-select-wrapper mt-10'>
                      <MultiSelectInput
                        name='startApplyingSelectCharge'
                        label='Select Charges'
                        subLabel={
                          'The charge will first apply when the set % of payments for selected charges are received'
                        }
                        chargeOptions={chargeOptions}
                        selectedCharge={chrgData?.startApplySelectChargeLeaseFeeListType}
                        setSelectedCharge={(newVal: any) => {
                          console.log(newVal)
                          const totalRentValue = 'Total Rent Value'
                          const singleRentInstallment = 'Single Rent Installment'

                          const updateLatePaymentFields = {...chrgData}

                          // if (newVal.some((item: any) => item.label === totalRentValue)) {
                          //   // Remove Single Rent Installment if Total Rent Value is selected
                          //   newVal = newVal.filter(
                          //     (item: any) => item.label !== singleRentInstallment
                          //   )
                          // } else if (
                          //   newVal.some((item: any) => item.label === singleRentInstallment)
                          // ) {
                          //   // Remove Total Rent Value if Single Rent Installment is selected
                          //   newVal = newVal.filter((item: any) => item.label !== totalRentValue)
                          // }

                          updateLatePaymentFields.startApplySelectChargeLeaseFeeListId = newVal
                          updateLatePaymentFields.startApplySelectChargeLeaseFeeListType = newVal
                          if(newVal?.length > 0)
                          updateLatePaymentFields.endApplySelectChargeLeaseFeeListType = newVal
                          setChrgData(updateLatePaymentFields)
                        }}
                        required={true}
                        isMultiSelect={false}
                        disableSearch={true}
                      />
                    </div>
                  </div>
                )}

              {/* Until % of Payments Is Received */}
              {(chrgData?.frequency?.[0]?.value == 1 ||
                chrgData?.frequency?.[0]?.value == 2 ||
                chrgData?.frequency?.[0]?.value == 3) &&
                chrgData?.endApplying?.[0]?.value == 1 && (
                  <div className='col-6 col-md-7 ps-10 mt-10 ms-auto'>
                    <PercentageInput
                      isDisabled={false}
                      iconSize={30}
                      required={true}
                      setRightPosition='10px'
                      type='number'
                      name='endApplyPercentagePaymentReceived'
                      value={chrgData?.endApplyPercentagePaymentReceived != null ? chrgData?.endApplyPercentagePaymentReceived : ''}
                      label='Set % of Payments Received'
                      firstPlaceholder='Enter Percentage'
                      subLabel='The last charge will apply when the set % of payments for selected charges are received'
                      secondPlaceHolder='%'
                      onChange={(newValue: any) => {
                        const updateLatePaymentFields = {...chrgData}
                        updateLatePaymentFields.endApplyPercentagePaymentReceived =
                          newValue != '' ? newValue : null

                        if (
                          updateLatePaymentFields?.startApplying?.[0]?.value == 1 &&
                          updateLatePaymentFields?.endApplyPercentagePaymentReceived != null
                        ) {
                          if (
                            Number(updateLatePaymentFields?.endApplyPercentagePaymentReceived) <=
                            Number(updateLatePaymentFields?.startApplyPercentagePaymentReceived)
                          ) {
                            updateLatePaymentFields.previewData.percentError =
                              '% of Payments Received for Stop Applying can not be less than or equal to % of Payments Received for Start Applying'
                          } else {
                            updateLatePaymentFields.previewData.percentError = ''
                          }
                        } else {
                          updateLatePaymentFields.previewData.percentError = ''
                        }

                        setChrgData(updateLatePaymentFields)

                        // setTimeout(() => {
                        //   setIsChangeHappened(Date.now())
                        // }, 500)
                      }}
                      isPercent={true}
                      minValue={1}
                    />
                    {chrgData?.previewData.percentError ==
                      '% of Payments Received for Stop Applying can not be less than or equal to % of Payments Received for Start Applying' &&
                      chrgData?.startApplying?.[0]?.value == 1 && (
                        <p
                          style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                          className='m-0 mt-1 fnt-400'
                        >
                          {chrgData?.previewData.percentError}
                        </p>
                      )}

                    <div className='multi-select-wrapper mt-10 multi-select-container'>
                      <MultiSelectInput
                        name='endApplyingSelectCharge'
                        label='Select Charges'
                        subLabel={
                          'The last charge will apply when the set % of payments for selected charges are received'
                        }
                        chargeOptions={chargeOptions}
                        selectedCharge={chrgData?.endApplySelectChargeLeaseFeeListType}
                        setSelectedCharge={(newVal: any) => {
                          const totalRentValue = 'Total Rent Value'
                          const singleRentInstallment = 'Single Rent Installment'

                          const updateLatePaymentFields = {...chrgData}

                          // if (newVal.some((item: any) => item.label === totalRentValue)) {
                          //   // Remove Single Rent Installment if Total Rent Value is selected
                          //   newVal = newVal.filter(
                          //     (item: any) => item.label !== singleRentInstallment
                          //   )
                          // } else if (
                          //   newVal.some((item: any) => item.label === singleRentInstallment)
                          // ) {
                          //   // Remove Total Rent Value if Single Rent Installment is selected
                          //   newVal = newVal.filter((item: any) => item.label !== totalRentValue)
                          // }
                          updateLatePaymentFields.endApplySelectChargeLeaseFeeListId = newVal
                          updateLatePaymentFields.endApplySelectChargeLeaseFeeListType = newVal

                          if(newVal?.length > 0)
                          updateLatePaymentFields.startApplySelectChargeLeaseFeeListType = newVal
                          setChrgData(updateLatePaymentFields)
                        }}
                        required={true}
                        isMultiSelect={false}
                        disableSearch={true}
                      />
                    </div>
                  </div>
                )}
            </div>

            {/* Invoicing */}
            <div className='row my-10 recurring invoicing-section'>
              <div className='col-6 col-md-5'>
                <MultiSelectInput
                  name='invoicingType'
                  label='Invoicing'
                  subLabel={subLabels.invoicingLabel}
                  chargeOptions={invoicingOptions}
                  selectedCharge={chrgData?.autoInvoicingEnabled}
                  setSelectedCharge={(newVal: any) => {
                    const updateLatePaymentFields = {...chrgData}
                    updateLatePaymentFields.autoInvoicingEnabled = newVal
                    setChrgData(updateLatePaymentFields)
                  }}
                  required={false}
                  disableSearch={true}
                />
              </div>

              <div className='col-6 col-md-7 ps-12 position-relative'>
                {chrgData?.autoInvoicingEnabled?.[0]?.value == false && (
                  <div
                    className='tenantOpacityBox'
                    style={{opacity: '0.7', cursor: 'not-allowed'}}
                  ></div>
                )}
                <div>
                  {/* consolidate */}
                  <div className='d-flex align-items-start tst mb-4 pt-7'>
                    <div style={{width: '30px', height: '30px', marginTop: '3px'}}>
                      <input
                        type='radio'
                        className='me-3 cursor-pointer form-check-input'
                        {...register('invoicingType', {
                          required: true,
                        })}
                        id='consolidate'
                        value='consolidate'
                        checked={chrgData?.invoicingType == 0}
                        onChange={(e: any) => {
                          const updateChargeData = {...chrgData}
                          updateChargeData.invoicingType = 0
                          setChrgData(updateChargeData)
                        }}
                      />
                    </div>
                    <div>
                      <p className='pe-13 fs-5 fnt-500 labl-gry light-dark-theme-color m-0'>
                        <label htmlFor='consolidate' className='cursor-pointer'>
                          Consolidate this charge with other charges that share the same due date
                          under one invoice.
                        </label>
                      </p>
                    </div>
                  </div>

                  {/* separate */}
                  <div className='d-flex align-items-start tst'>
                    <div style={{width: '30px', height: '30px', marginTop: '3px'}}>
                      <input
                        type='radio'
                        className='me-3 cursor-pointer form-check-input'
                        {...register('invoicingType', {
                          required: true,
                        })}
                        id='separate'
                        value='separate'
                        checked={chrgData?.invoicingType == 1}
                        onChange={(e: any) => {
                          const updateChargeData = {...chrgData}
                          updateChargeData.invoicingType = 1
                          setChrgData(updateChargeData)
                        }}
                      />
                    </div>
                    <div>
                      <p className='pe-13 fs-5 fnt-500 labl-gry light-dark-theme-color m-0'>
                        <label htmlFor='separate' className='cursor-pointer'>
                          Generate a separate invoice for this charge.
                        </label>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default RecurringCharge
