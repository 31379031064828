import React, {useState, useEffect} from 'react'
import {Button, ButtonGroup, Col, Dropdown, Form, Row, Collapse, Card, Modal} from 'react-bootstrap'
import close from '../../../img/close.png'
import {ApiGet, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import invoiceWhite from '../../../img/invoice-white.png'
import send from '../../../img/sendwhite.svg'
import share from '../../../img/email.png'
import print from '../../../img/printer.png'
import download from '../../../img/download-white.png'
import chequeImg from '../../../img/cheque.png'
import moment from 'moment'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import plusBlue from '../../../img/add-blue.png'
import recipient from '../../../img/recipient.png'
import receiptWhite from '../../../img/receipt-white.png'
import file from '../../../img/file.png'
import refundWhite from '../../../img/refund-white.png'
import ViewRefund from './ViewRefund'
import ViewAllRefundsOfPayment from './ViewAllRefundsOfPayment'

import './style.scss'

interface ButtonProps {
  show: any
  handleClose: any
  receipt: any
  paymentId: any
  selectedAccountReceipt: any
  tenantDetails: any
  from: any
}

const ViewReceipt = ({
  handleClose,
  show,
  receipt,
  paymentId,
  selectedAccountReceipt,
  tenantDetails,
  from,
}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  var totalAED: any = 0
  const [viewRefund, setViewRefund] = useState<any>(false)
  const [viewAllRefunds, setViewAllRefunds] = useState<any>()
  const [refundToView, setRefundToView] = useState<any>()

  const getNegative = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number > 0 ? number * -1 : number
  }

  const getPositive = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const getRefundById = async (id: any) => {
    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_refund/${id}?isCloneRecord=true`
    else url = `corporate/payment_refund/${id}`
    await ApiGet(`${url}`)
      .then((res) => {
        setRefundToView(res?.data?.data)
        setViewRefund(true)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  return (
    <div>
      {/*  Receipt Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto no-print'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 receipt-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Receipt</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => handleClose()}
              >
                <img className='svg-icon-1 text-black' src={close} width={14} height={14} />
              </div>
            </Col>
          </Row>
        </div>
        <div className='receipt-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '15px'}}>
              <img src={receiptWhite} height={80} width={85} className='me-3 ms-5' />
            </Col>
          </Row>
        </div>

        <div
          className='modal-body px-lg-10'
          style={{
            height: '600px',
            transform: 'translate(0px, -94px)',
            maxHeight: '600px',
            overflow: 'scroll',
            marginBottom: '-94px',
          }}
        >
          <div className='d-flex align-items-center justify-content-center mb-2'>
            <div className='d-flex'>
              <a
                className='btn btn-sm fw-bold ps-2 pe-4 me-3 text-white d-block mt-2 receipt-color'
                style={{width: '140px'}}
                href={`${Bucket}${receipt?.pdfURL}`}
                target='_blank'
              >
                <img
                  src={download}
                  height={18}
                  width={18}
                  style={{marginRight: '3px', marginTop: '-2px'}}
                />{' '}
                Download
              </a>

              <button
                className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-2 me-3 text-white'
                style={{
                  width: '140px',
                  backgroundColor: '#8a8aa3',
                  fontSize: '13px',
                }}
                onClick={() => {
                  window.print()
                }}
              >
                <img
                  src={print}
                  height={18}
                  width={18}
                  style={{
                    marginRight: '12px',
                    marginTop: '-2px',
                  }}
                />{' '}
                {'  '}
                Print
              </button>
              {receipt?.refund?.length > 0 && (
                <button
                  className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-2 refund-color text-white '
                  style={{
                    width: '140px',
                    fontSize: '13px',
                  }}
                  onClick={() => {
                    if (receipt?.refund?.length > 1) setViewAllRefunds(true)
                    else getRefundById(receipt?.refund?.[0]?._id)
                  }}
                >
                  <img
                    src={refundWhite}
                    height={20}
                    width={20}
                    style={{
                      marginRight: '12px',
                      marginTop: '-2px',
                    }}
                  />{' '}
                  {'  '}
                  Refund
                </button>
              )}
            </div>
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-2 mt-4'
            style={{fontWeight: '800'}}
          >
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px', fontWeight: '500'}}>
              Invoice No.
            </label>
            <u>{receipt?.invoiceNo}</u>

            {/* <a style={{textDecoration: 'underline', color: 'dodgerblue'}}>{receipt?.invoiceNo}</a> */}
          </div>

          <div className='d-flex align-items-center justify-content-center mt-2 '>
            <h1 className=''>RECEIPT</h1>
          </div>

          <div
            className='d-flex align-items-center justify-content-center mb-2'
            style={{fontWeight: '800'}}
          >
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px', fontWeight: '500'}}>
              Receipt No.
            </label>

            <u>{receipt?.receiptNo}</u>
          </div>

          <div
            className='d-flex align-items-center justify-content-center my-2 mt-4 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>{receipt?.receiptCompany?.name}</p>
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-2 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>{receipt?.receiptCompany?.address} </p>
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-2 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>
              {receipt?.receiptCompany?.countryCode + ' ' + receipt?.receiptCompany?.phoneNumber}
            </p>
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-2 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>{receipt?.receiptCompany?.email}</p>
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-5 fs-3'
            style={{fontWeight: '600'}}
          >
            <label className='me-5 pe-5 ' style={{minWidth: '50px'}}>
              Total
            </label>

            <b style={{fontWeight: '700'}} className='fs-3'>
              {/* AED {parseFloat(totalAED) - parseFloat(detailsToEdit?.amountPaid)} */}
              AED{' '}
              {(receipt?.amountPaid + receipt?.outstandingAmount)?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })}
            </b>
          </div>

          <div className='row mt-3'>
            <div className='col-md-3'></div>
            <div
              className='col-md-6 ps-5 fs-5 d-flex align-items-center head-text'
              style={{fontWeight: '700'}}
            >
              <label className='mb-1 me-5 head-text' style={{minWidth: '110px', fontWeight: '500'}}>
                Amount Paid
              </label>
              {receipt?.amountPaid?.toLocaleString('en-US', {minimumFractionDigits: 2})}
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-3'></div>
            <div
              className='col-md-6 ps-5 fs-5 d-flex align-items-center head-text'
              style={{fontWeight: '700'}}
            >
              <label className='mb-1 me-5 head-text' style={{minWidth: '110px', fontWeight: '500'}}>
                Date Paid
              </label>
              <div style={{width: '-webkit-fill-available'}}>
                {moment(receipt?.datePaid).format('DD.MM.YYYY')}
              </div>
              {/* {datePaid} */}
            </div>
          </div>

          {/* <div className='d-flex align-items-center justify-content-center my-5'>
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
              Date Paid
            </label>
            {datePaid}
          </div> */}

          <div className='row mt-3'>
            <div className='col-md-3'></div>
            <div
              className='col-md-6 ps-5 fs-5 d-flex align-items-center head-text'
              style={{fontWeight: '700'}}
            >
              <label className='mb-1 me-5 head-text' style={{minWidth: '110px', fontWeight: '500'}}>
                Payment Method
              </label>
              <div style={{width: '-webkit-fill-available'}}>
                {receipt?.paymentMethod == 0
                  ? 'Card'
                  : receipt?.paymentMethod == 1
                  ? 'Cheque'
                  : receipt?.paymentMethod == 2
                  ? 'Bank'
                  : 'Cash'}
              </div>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5'>
              {/* Payment Method: Bank */}
              {receipt?.paymentMethod == 2 ? (
                <>
                  <div
                    className='d-flex align-items-center mb-3 head-text'
                    style={{fontWeight: '700'}}
                  >
                    <label
                      className='mb-1 me-1 pe-5'
                      style={{minWidth: '110px', fontWeight: '500'}}
                    >
                      Account Name
                    </label>
                    {selectedAccountReceipt?.accountHolderName}
                  </div>
                  <div
                    className='d-flex align-items-center my-2 head-text'
                    style={{fontWeight: '700'}}
                  >
                    <label
                      className='mb-1 me-1 pe-5'
                      style={{minWidth: '110px', fontWeight: '500'}}
                    >
                      IBAN
                    </label>
                    {selectedAccountReceipt?.IBAN}
                  </div>
                  <div
                    className='d-flex align-items-center mb-3 head-text'
                    style={{fontWeight: '700'}}
                  >
                    <label
                      className='mb-1 me-1 pe-5'
                      style={{minWidth: '110px', fontWeight: '500'}}
                    >
                      Account No.
                    </label>
                    {selectedAccountReceipt?.accountNumber}
                  </div>
                  <div
                    className='d-flex align-items-center mb-3 head-text'
                    style={{fontWeight: '700'}}
                  >
                    <label
                      className='mb-1 me-1 pe-5'
                      style={{minWidth: '110px', fontWeight: '500'}}
                    >
                      Bank Name
                    </label>
                    {selectedAccountReceipt?.bankName}
                  </div>
                </>
              ) : receipt?.paymentMethod == 1 ? (
                <>
                  <div
                    className='d-flex align-items-center head-text mb-2'
                    style={{fontWeight: '700'}}
                  >
                    <label
                      className='mb-1 me-5 pe-5  blue-label'
                      style={{minWidth: '110px', fontWeight: '500'}}
                    >
                      Bank Name
                    </label>

                    {receipt?.chequeBankName}
                  </div>

                  {receipt?.chequeImages.length > 0 && (
                    <div
                      className='row align-items-center my-2'
                      style={{overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}}
                    >
                      {receipt?.chequeImages.map((chq: any, ind: any) => {
                        return (
                          <>
                            <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                              <div className='d-flex'>
                                <a
                                  href={`${Bucket}${chq}`}
                                  target='_blank'
                                  style={{color: '#384a62'}}
                                  className='d-flex'
                                >
                                  <img
                                    src={chequeImg}
                                    width='80px'
                                    height='40px'
                                    className='main_img cursor-pointer'
                                    alt='image'
                                    style={{objectFit: 'cover'}}
                                  />
                                </a>
                              </div>
                              <p className='blue-label fs-7 text-center mb-1'>
                                {' '}
                                {chq?.split('/')[2]}
                              </p>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className='row '>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5 head-text' style={{fontWeight: '700'}}>
              <label
                className='mb-1 me-5 pe-2  head-text'
                style={{minWidth: '150px', fontWeight: '500'}}
              >
                Outstanding Amount
              </label>
              {receipt?.outstandingAmount > 0
                ? getNegative(receipt?.outstandingAmount)?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })
                : getPositive(receipt?.outstandingAmount)?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
            </div>
          </div>

          {/* <div className='d-flex align-items-center justify-content-center my-5'>
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
              Outstanding Amount
            </label>

            <b> {totalAED - paidAmount}</b>
          </div> */}

          <div className='align-items-center justify-content-center my-2 text-center'>
            <p
              className='grey-label mx-auto mt-3'
              style={{borderBottom: '2px dashed lightgray', width: '60%'}}
            ></p>
          </div>

          <div className='d-flex align-items-center justify-content-center my-5'>
            <label className='head-text fs-4' style={{fontWeight: '700'}}>
              Attachments
            </label>
          </div>

          <div className='row'>
            <div className='col-3'></div>
            <div className='col-6'>
              {receipt?.attachments.length > 0 && (
                <div className='row align-items-center my-2'>
                  {receipt?.attachments.map((chq: any, ind: any) => {
                    let fileName: any
                    let temp = chq?.split('.')[1]
                    let x = chq?.split('/')[2]
                    fileName = x + '.' + temp
                    return (
                      <>
                        <div className='img-sets mw-350px col-md-4 px-2 py-3'>
                          <div className='d-flex'>
                            <a
                              href={`${Bucket}${chq}`}
                              target='_blank'
                              style={{color: '#384a62'}}
                              className='d-flex'
                            >
                              <img
                                src={file}
                                width='42px'
                                height='55px'
                                className='main_img cursor-pointer'
                                alt='image'
                                style={{marginLeft: '26px'}}
                              />
                            </a>
                          </div>
                          <p
                            style={{fontSize: '12px', wordBreak: 'break-all', padding: '0px 5px'}}
                            className='mt-1'
                          >
                            {fileName}{' '}
                          </p>
                        </div>
                      </>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/* Screen for Print */}
      <div className='app-main print-invoice' id='kt_app_main'>
        <div id='' className=''>
          <div
            className='d-flex align-items-center justify-content-center my-2'
            style={{fontWeight: '800'}}
          >
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '90px', fontWeight: '500'}}>
              Invoice No.
            </label>
            <u>{receipt?.invoiceNo}</u>

            {/* <a style={{textDecoration: 'underline', color: 'dodgerblue'}}>{receipt?.invoiceNo}</a> */}
          </div>

          <div className='d-flex align-items-center justify-content-center mt-2 '>
            <h1 className=''>RECEIPT</h1>
          </div>

          <div
            className='d-flex align-items-center justify-content-center mb-2'
            style={{fontWeight: '800'}}
          >
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '90px', fontWeight: '500'}}>
              Receipt No.
            </label>

            <u>{receipt?.receiptNo}</u>
          </div>

          <div
            className='d-flex align-items-center justify-content-center my-2 mt-4 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}> {receipt?.receiptCompany?.name}</p>
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-2 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>{receipt?.receiptCompany?.address}</p>{' '}
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-2 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>
              {' '}
              {receipt?.receiptCompany?.countryCode + ' ' + receipt?.receiptCompany?.phoneNumber}
            </p>{' '}
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-2 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>{receipt?.receiptCompany?.email}</p>{' '}
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-5 fs-3'
            style={{fontWeight: '600'}}
          >
            <label className='me-5 pe-5 ' style={{minWidth: '50px'}}>
              Total
            </label>

            <b style={{fontWeight: '700'}} className='fs-3'>
              {/* AED {parseFloat(totalAED) - parseFloat(detailsToEdit?.amountPaid)} */}
              AED{' '}
              {(receipt?.amountPaid + receipt?.outstandingAmount)?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })}
            </b>
          </div>

          <div className='d-flex mt-3'>
            <div className='' style={{width: '28%'}}></div>
            <div
              className='ps-5 fs-5 d-flex align-items-center head-text'
              style={{fontWeight: '700', width: '60%'}}
            >
              <label className='mb-1 me-5 head-text' style={{minWidth: '110px', fontWeight: '500'}}>
                Amount Paid
              </label>
              {receipt?.amountPaid?.toLocaleString('en-US', {minimumFractionDigits: 2})}
            </div>
          </div>

          <div className='d-flex mt-3'>
            <div className='' style={{width: '28%'}}></div>
            <div
              className='ps-5 fs-5 d-flex align-items-center head-text'
              style={{fontWeight: '700', width: '60%'}}
            >
              <label className='mb-1 me-5 head-text' style={{minWidth: '110px', fontWeight: '500'}}>
                Date Paid
              </label>
              <div style={{width: '-webkit-fill-available'}}>
                {moment(receipt?.datePaid).format('DD.MM.YYYY')}
              </div>
              {/* {datePaid} */}
            </div>
          </div>

          {/* <div className='d-flex align-items-center justify-content-center my-5'>
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
              Date Paid
            </label>
            {datePaid}
          </div> */}

          <div className='d-flex mt-3'>
            <div className='' style={{width: '28%'}}></div>
            <div
              className='ps-5 fs-5 d-flex align-items-center head-text'
              style={{fontWeight: '700', width: '60%'}}
            >
              <label
                className='mb-1 me-5 head-text'
                style={{minWidth: 'fit-content', fontWeight: '500'}}
              >
                Payment Method
              </label>
              <div style={{width: '-webkit-fill-available'}}>
                {receipt?.paymentMethod == 0
                  ? 'Card'
                  : receipt?.paymentMethod == 1
                  ? 'Cheque'
                  : receipt?.paymentMethod == 2
                  ? 'Bank'
                  : 'Cash'}
              </div>
            </div>
          </div>

          <div className='row '>
            <div className='col-md-3'></div>
            <div className='col-md-6 fs-5'>
              {/* Payment Method: Bank */}
              {receipt?.paymentMethod == 2 ? (
                <>
                  <div className='d-flex mt-3'>
                    <div className='' style={{width: '28%'}}></div>
                    <div
                      className='ps-5 fs-5 d-flex align-items-center head-text'
                      style={{fontWeight: '700', width: '60%'}}
                    >
                      <label
                        className='mb-1 me-5 head-text'
                        style={{minWidth: '110px', fontWeight: '500'}}
                      >
                        Account Name
                      </label>
                      {selectedAccountReceipt?.accountHolderName}
                    </div>
                  </div>
                  {/* <div
                    className='d-flex align-items-center mb-3 head-text'
                    style={{fontWeight: '700'}}
                  >
                    <label
                      className='mb-1 me-1 pe-5'
                      style={{minWidth: '110px', fontWeight: '500'}}
                    >
                      Account Name
                    </label>
                    {selectedAccountReceipt?.accountHolderName}
                  </div> */}

                  <div className='d-flex mt-3'>
                    <div className='' style={{width: '28%'}}></div>
                    <div
                      className='ps-5 fs-5 d-flex align-items-center head-text'
                      style={{fontWeight: '700', width: '60%'}}
                    >
                      <label
                        className='mb-1 me-5 head-text'
                        style={{minWidth: '110px', fontWeight: '500'}}
                      >
                        IBAN
                      </label>
                      {selectedAccountReceipt?.IBAN}
                    </div>
                  </div>
                  {/* <div
                    className='d-flex align-items-center my-2 head-text'
                    style={{fontWeight: '700'}}
                  >
                    <label
                      className='mb-1 me-1 pe-5'
                      style={{minWidth: '110px', fontWeight: '500'}}
                    >
                      IBAN
                    </label>
                    {selectedAccountReceipt?.IBAN}
                  </div> */}

                  <div className='d-flex mt-3'>
                    <div className='' style={{width: '28%'}}></div>
                    <div
                      className='ps-5 fs-5 d-flex align-items-center head-text'
                      style={{fontWeight: '700', width: '60%'}}
                    >
                      <label
                        className='mb-1 me-5 head-text'
                        style={{minWidth: '110px', fontWeight: '500'}}
                      >
                        Account No.
                      </label>
                      {selectedAccountReceipt?.accountNumber}
                    </div>
                  </div>
                  {/* <div
                    className='d-flex align-items-center mb-3 head-text'
                    style={{fontWeight: '700'}}
                  >
                    <label
                      className='mb-1 me-1 pe-5'
                      style={{minWidth: '110px', fontWeight: '500'}}
                    >
                      Account No.
                    </label>
                    {selectedAccountReceipt?.accountNumber}
                  </div> */}

                  <div className='d-flex mt-3'>
                    <div className='' style={{width: '28%'}}></div>
                    <div
                      className='ps-5 fs-5 d-flex align-items-center head-text'
                      style={{fontWeight: '700', width: '60%'}}
                    >
                      <label
                        className='mb-1 me-5 head-text'
                        style={{minWidth: '110px', fontWeight: '500'}}
                      >
                        Bank Name
                      </label>
                      {selectedAccountReceipt?.bankName}
                    </div>
                  </div>
                  {/* <div
                    className='d-flex align-items-center mb-3 head-text'
                    style={{fontWeight: '700'}}
                  >
                    <label
                      className='mb-1 me-1 pe-5'
                      style={{minWidth: '110px', fontWeight: '500'}}
                    >
                      Bank Name
                    </label>
                    {selectedAccountReceipt?.bankName}
                  </div> */}
                </>
              ) : receipt?.paymentMethod == 1 ? (
                <>
                  <div className='d-flex mt-3'>
                    <div className='' style={{width: '28%'}}></div>
                    <div
                      className='ps-5 fs-5 d-flex align-items-center head-text'
                      style={{fontWeight: '700', width: '60%'}}
                    >
                      <label
                        className='mb-1 me-5 head-text'
                        style={{minWidth: '110px', fontWeight: '500'}}
                      >
                        Bank Name
                      </label>
                      {receipt?.chequeBankName}
                    </div>
                  </div>
                  {/* <div
                    className='d-flex align-items-center head-text mb-2'
                    style={{fontWeight: '700'}}
                  >
                    <label
                      className='mb-1 me-5 pe-5  blue-label'
                      style={{minWidth: '110px', fontWeight: '500'}}
                    >
                      Bank Name
                    </label>

                    {receipt?.chequeBankName}
                  </div> */}

                  {receipt?.chequeImages.length > 0 && (
                    <div
                      className='row align-items-center my-2 no-print'
                      style={{overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}}
                    >
                      {receipt?.chequeImages.map((chq: any, ind: any) => {
                        return (
                          <>
                            <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                              <div className='d-flex'>
                                <a
                                  href={`${Bucket}${chq}`}
                                  target='_blank'
                                  style={{color: '#384a62'}}
                                  className='d-flex'
                                >
                                  <img
                                    src={chequeImg}
                                    width='80px'
                                    height='40px'
                                    className='main_img cursor-pointer'
                                    alt='image'
                                    style={{objectFit: 'cover'}}
                                  />
                                </a>
                              </div>
                              <p className='blue-label fs-7 text-center mb-1'>
                                {' '}
                                {chq?.split('/')[2]}
                              </p>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          {/* <div className='row'>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5 head-text' style={{fontWeight: '700'}}>
              <label
                className='mb-1 me-5 pe-2  head-text'
                style={{minWidth: '150px', fontWeight: '500'}}
              >
                Outstanding Amount
              </label>
              {receipt?.outstandingAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
            </div>
          </div> */}

          <div className='d-flex mt-3'>
            <div className='' style={{width: '28%'}}></div>
            <div
              className='ps-5 fs-5 d-flex align-items-center head-text'
              style={{fontWeight: '700', width: '60%'}}
            >
              <label className='mb-1 me-5 head-text' style={{minWidth: '110px', fontWeight: '500'}}>
                Outstanding Amount
              </label>
              {receipt?.outstandingAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
            </div>
          </div>

          {/* <div className='d-flex align-items-center justify-content-center my-5'>
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
              Outstanding Amount
            </label>

            <b> {totalAED - paidAmount}</b>
          </div> */}

          <div className='align-items-center justify-content-center my-2 text-center'>
            <p
              className='grey-label mx-auto mt-3'
              style={{borderBottom: '2px dashed lightgray', width: '60%'}}
            ></p>
          </div>
        </div>
      </div>

      {/* View Single Refund */}
      {viewRefund && (
        <ViewRefund
          show={viewRefund}
          handleClose={() => {
            setViewRefund(false)
          }}
          refund={refundToView}
          paymentId={paymentId}
          tenantDetails={tenantDetails}
          dueDate={''}
          bankAccountItems={''}
          from={from}
        />
      )}

      {/* View All Debit Notes of Payment */}
      {viewAllRefunds && (
        <ViewAllRefundsOfPayment
          show={viewAllRefunds}
          handleClose={() => {
            setViewAllRefunds(false)
          }}
          refunds={receipt?.refund}
          paymentId={paymentId}
          tenantDetails={tenantDetails}
          getPaymentRefunds={''}
          bankAccountItems={''}
          from={from}
        />
      )}
    </div>
  )
}

export default ViewReceipt
