import React from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  show: any
  handleClose: any
  labelDelete: any
}
function UpdateService({show, handleClose, labelDelete}: ButtonProps) {
  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div className='modal-header'>
          {labelDelete === 'Delete' ? <h2>Delete Service</h2> : <h2>Update Service</h2>}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG
              className='svg-icon-1 text-black'
              path='/media/icons/duotune/arrows/arr061.svg'
            />
          </div>
        </div>
        <div className='modal-body py-lg-10 px-lg-10'>
          <div
            className='stepper stepper-pills stepper-column flex-column flex-xl-row flex-row-fluid'
            id='kt_modal_create_app_stepper'
          >
            {labelDelete === 'Delete' ? (
              <p className='text-center' style={{width: '420px', margin: '0 auto 0', fontSize: 16}}>
                Are you sure you want to permanenetly delete this service? The service will still be
                retained for past Service Requests submitted under this service.{' '}
              </p>
            ) : (
              <p className='text-center' style={{width: '420px', margin: '0 auto 0', fontSize: 16}}>
                Are you sure you want to update the details of this service? Updating the details
                will reflect on all past Service Requests submitted under this service.{' '}
              </p>
            )}
          </div>
        </div>
        <div className='modal-footer justify-content-center'>
          <div className='d-flex w-100 justify-content-evenly'>
            <button
              type='submit'
              className='btn btn-lg btn-primary btn-green me-5'
              onClick={handleClose}
            >
              Yes
            </button>
            <button
              type='submit'
              className='btn btn-lg btn-primary btn-green'
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UpdateService
