import React, {useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {AiFillDelete} from 'react-icons/ai'
import ReactPaginate from 'react-paginate'
import {useNavigate} from 'react-router-dom'
import {ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../_metronic/helpers'
import ServiceProviderFilter from './ServiceProviderFilter'
import back from '../../../img/left-arrow.png'
import plus from '../../../img/plus.svg'
import approve from '../../../img/approve.png'
import sort from '../../../img/sort.png'

const ServiceData = [
  {name: 'Façade Cleaners', value: '1'},
  {name: 'Housekeeping', value: '10'},
  {name: 'Electricians', value: '5'},
  {name: 'Interior Works', value: '5'},
  {name: 'Construction', value: '5'},
  {name: 'Landscaping', value: '5'},
  {name: 'General Maintenance', value: '5'},
]
function ServiceProviderList() {
  const navigate = useNavigate()
  const [providerFilter, setProviderFilter] = useState<any>(false)
  const [providerFilterType, setProviderFilterType] = useState<any>('')
  const [vendor, setVendor] = useState<any>()
  const [pageLimit, setPageLimit] = useState<any>()
  const [vendorCount, setVendorCount] = useState<any>()
  const [page, setPage] = useState<any>(1)
  const [vendorList, setVendorList] = useState<any>()
  const [vendorListOverview, setVendorListOverview] = useState<any>([])
  const [checked, setChecked] = React.useState<any>()
  const [selectedItem, setSelectedItem] = React.useState<any>()
  const currentTab: any = localStorage.getItem('currentTab')
  // const requestServiceId: any = localStorage.getItem(currentTab)
  // const requestServiceId: any = localStorage.getItem('serviceRequestId')

  const activeTab: any = localStorage.getItem('activeTab')
  const requestServiceId: any = localStorage.getItem('serviceRequestId'+activeTab)

  const serviceId = window.location.pathname?.split('/')[4]
  const vendorServiceId: any  = localStorage.getItem('serviceProvider_' + serviceId)

  const getVendorOverView = (vendorListId: any = null) => {
    setChecked('')
    const body = {
      page: page,
      limit: 10,
      vendorListId: vendorListId,
    }
    ApiPost('corporate/vendor/overview', body)
      .then((res) => {
        setVendor(res?.data?.data?.vendor_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
        setVendorCount(res?.data?.data?.state?.data_count)

        let temp = res?.data?.data?.vendor_data
        for(let i=0; i< temp.length; i++){
          if(temp[i]._id === vendorServiceId){
            setSelectedItem(temp[i])
            setChecked(i)
          }

        }
      })
      .catch((err) => ErrorToast(err.message))
  }

  const getvendorList = () => {
    ApiGet('corporate/vendor_list')
      .then((res) => {
        setVendorList(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const getvendorListOverview = () => {
    const body = {
      page: 1,
      limit: 10,
      startingWitAlphabet: '',
    }
    ApiPost('corporate/vendor_list/get', body)
      .then((res) => {
        setVendorListOverview(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  React.useEffect(() => {
    getvendorListOverview()
    getVendorOverView()
    getvendorList()
  }, [page])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const handleChnage = () => {}

  const removeServices = () => {
    setSelectedItem('')
  }

  const handleSelectService = (item: any, index: any) => {
    setChecked(index)
    setSelectedItem(item)
  }

  const addServiceRequest = async () => {
    const body = {
      requestType: 1,
    }
    await ApiPost(`corporate/service_request`, body)
      .then((res) => {
        localStorage.setItem(currentTab, res?.data?.data?._id)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getRequestDataById = async () => {
    console.log(requestServiceId)
    // let temp = localStorage.getItem('serviceRequestId' + requestServiceId)

    // console.log(temp)
   let mainService:any
   let subService : any
   let staff : any
   let vendor: any
    await ApiGet(`corporate/service_request/`+serviceId)
      .then((res) => {
        console.log("In property list service by ID ")
        console.log(res?.data?.data)

        // main service 
        if(res?.data?.data[0].main_service.length === 0){
          mainService = null
        }
        else{
          mainService = res?.data?.data[0].main_service[0]?._id
        }

        // sub service 
        if(res?.data?.data[0].sub_service.length === 0){
          subService = null
        }
        else{
          subService = res?.data?.data[0]?.sub_service[0]?._id
        }
 
        // staff
        if(res?.data?.data[0].staff.length === 0){
          staff = null
        }
        else{
          staff = res?.data?.data[0]?.staff[0]?._id
        }

         // vendor
        if(res?.data?.data[0].vendor.length === 0){
          vendor = null
        }
        else{
          vendor = res?.data?.data[0]?.vendor[0]?._id
        }

        const body = {
          serviceRequests: [
            {
              _id: serviceId,
              mainServiceId: mainService,
              subServiceId: subService,
              vendorId: vendor,
              staffId: staff,
              requestUrgency: res.data.data[0].requestUrgency,
              requestType: res.data.data[0].requestType,
              saveStatus: 0,
              comment: res.data.data[0].comment,
              vendorComment: res.data.data[0].vendorComment

            },
          ],
          isSave: true,
          isDelete: false,
        }

        ApiPost(`corporate/service_request/multiple/draft_delete`, body)
        .then((res) => {
            // localStorage.setItem('savedOnce'+requestServiceId, '1')
            localStorage.setItem('serviceProvider_'+serviceId,vendor)
           
        })
        .catch()

        let tnt = localStorage.getItem('tenantRequestId')
        let tntType = localStorage.getItem('requestType')
      
      let idToSend : any
      let typeToSend : any
      if(tntType !== null){
        typeToSend = JSON.parse(tntType)
        
      }
        if(tnt !== null)
        idToSend = tnt
  
      const data ={
        id : idToSend,
        serviceRequestType : typeToSend,
      }
  
      ApiPut(`corporate/tenant_request/`,data)
      .then((res) => {})
      .catch()

      navigate(-1)


        
          
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    // if (!requestServiceId) {
    //   addServiceRequest()
    // }
  }, [requestServiceId])

  const addServiceProvider = async () => {
    let temp = localStorage.getItem('requestType')
    let vendorID : any
    let staffID : any

    if(temp !== null){
      if(temp === '1'){

    // If a user first selects the internal and then vendor team for that internal
    let internalSelected = localStorage.getItem('internalForVendor'+serviceId)
    if(internalSelected !== null || internalSelected !== undefined){
      staffID = internalSelected   
      // console.log("internalSelected testing"+internalSelected)
    }
    else{
      staffID = null
    }
  }
}

    const body = {
      id: serviceId,
      vendorId: selectedItem?._id,
      requestType: 1,
      staffId: staffID,
    }
    await ApiPut(`corporate/service_request`, body)
      .then((res) => {
        localStorage.setItem('vendor'+serviceId,selectedItem?._id)
        getRequestDataById()
        localStorage.setItem('requestType', ''+temp+'')

        // navigate(-1)
      })
      .catch((e) => {
        console.log(e)
      })
     
    // moveVendor()
  }

  const moveVendor = () => {
    navigate(`/request-management/service/vendor-list`)
    localStorage.setItem('inter', 'false')
  }

  return (
    <>
      <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
        <div id='' className='app-container container-xxl pt-0 mt-0'>
          {/* <div className='d-flex align-items-baseline flex-wrap mr-5'>
            <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
              <li className='breadcrumb-item '>
                <a
                  className='text-muted px-2 cursor-pointer'
                  onClick={() => navigate(`/request-management/service`)}
                >
                  Request
                </a>
              </li>
              <li className='breadcrumb-item '>
                <a className='text-muted px-2 cursor-pointer' onClick={() => moveVendor()}>
                  External Request
                </a>
              </li>
              <li className='breadcrumb-item active'>
                <a className='px-2'> select Service Provider</a>
              </li>
            </ul>
          </div>
          <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/request-management/service/create-request-service`)
                }}
              >
                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                  Internal
                </a>
              </li>
              <li
                className='nav-item'
                // onClick={() => {
                //     navigate(`/request-management/quotation`)
                // }}
              >
                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                  Marketplace
                </a>
              </li>
              <li className='nav-item' onClick={() => moveVendor()}>
                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                  VendorList
                </a>
              </li>
            </ul>
          </div> */}
          <div className='card card-flush app-container py-5'>
            <div className='d-flex justify-content-between py-5'>
              <div className=''>
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green '
                  onClick={() =>   navigate(-1)}
                >
                   <img
                    src={back}
                    style={{
                      height: '16px',
                      width: '16px',
                      marginRight: '7px',
                      marginLeft: '-10px',
                      marginTop: '-2px',
                    }}
                  />
                  Back
                </a>
              </div>
              <div className=''>
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green '
                  onClick={() => addServiceProvider()}
                >
                  <img
                    src={approve}
                    style={{height: '18px', width: '18px', marginRight: '7px', marginLeft: '-10px'}}
                  />
                  Assign
                </a>
              </div>
            </div>
            <Row>
              <Col lg={6}>
                <h3>Service Details</h3>
                <div className='d-flex align-items-center mb-3'>
                  <input
                    type='text'
                    placeholder='Search Service'
                    className='form-control form-control-solid w-300px'
                    // onChange={(e) => setMainService({ ...mainService, 'name': e.target.value })}
                    name='name'
                    // value={mainService?.name}
                  />
                  <button
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => setProviderFilter(true)}
                    style={{height: '34px', width: '80px'}}
                  >
                    <svg
                     style={{height: '18px', width: '18px', marginRight: '7px', marginLeft: '-10px', marginTop: '-2px'}}
                      viewBox='0 0 1024 1024'
                      xmlns='http://www.w3.org/2000/svg'
                      className='me-1'
                    >
                      <path
                        fill='#ffff'
                        d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                      />
                    </svg>
                    Filter
                  </button>
                  <button
                    className='btn btn-sm fw-bold btn-primary btn-green'
                    onClick={() => {
                      setProviderFilter(true)
                      setProviderFilterType('sort')
                    }}
                    style={{height: '34px', width: '80px'}}
                  >
                <img src={sort} style={{height: '18px', width: '18px', marginRight: '7px', marginLeft: '-10px', marginTop: '-2px'}} />

                    Sort
                  </button>
                </div>
              </Col>
              <Col lg={6} md={6} >
                <div className='select-box p-3'>
                <h3 className='page-heading mt-2 text-dark fw-bold fs-3 mb-5'>
                  Service Provider Selected
                </h3>
                <table
                  className='table align-middle table-row-dashed fs-6 gy-5'
                  id='kt_ecommerce_sales_table'
                >
                  <thead>
                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                      <th className='text-center min-w-100px'>Service Provider Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className='fw-semibold text-gray-600'>
                    {selectedItem?.firstName && (
                      <tr>
                        <td className='text-center min-w-100px'>
                          {selectedItem?.firstName}
                          {selectedItem?.lastName}
                        </td>
                        <td className='text-center min-w-100px'></td>
                        <td>
                          <AiFillDelete
                            style={{cursor: 'pointer'}}
                            onClick={() => removeServices()}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
                </div>
              </Col>
            </Row>
            <div className='overflow-scroll my-10'>
            <div className='w-100 d-flex row'>
                <Col className='px-5' style={{cursor: 'pointer'}} xs={4}>
                  <h4 onClick={() => getVendorOverView(null)}>All</h4>
                  <span onClick={() => getVendorOverView(null)}>
                    {vendorListOverview?.state?.data_count}
                  </span>
                </Col>
                {/* <Col className='min-w-150px px-5'>
                            <h4>Not Listed</h4>
                            <span>##</span>
                        </Col> */}
                {vendorListOverview?.vendor_data?.map((i: any, index: number) => {
                  return (
                    <Col key={index} className='px-5' style={{cursor: 'pointer'}} xs={4}>
                      <h4 onClick={() => getVendorOverView(i._id)}>{i?.name}</h4>
                      <span onClick={() => getVendorOverView(i._id)}>{i.totalVendor}</span>
                    </Col>
                  )
                })}
              </div>
            </div>
            <div className='pt-0 table-responsive mt-5 px-3'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='text-center min-w-50px'></th>
                    <th className='text-center min-w-100px'>Company</th>
                    <th className='text-center min-w-100px'>TRN</th>
                    <th className='text-center min-w-150px'>Trade License</th>
                    <th className='text-center min-w-100px'>City</th>
                    <th className='text-center min-w-100px'>List</th>
                    <th className='text-center min-w-100px'>Phone No.</th>
                    <th className='text-center min-w-100px'>Main Contact</th>
                    <th className='text-center min-w-100px'>Rating</th>
                    <th className='text-center min-w-100px'>Date Added</th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {vendor?.map((f: any, i: any) => {
                    return (
                      <tr>
                       <th className='text-center min-w-50px'>
                          <div className='form-check form-check-sm form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='radio'
                              checked={checked === i}
                              style={{cursor: 'pointer'}}
                              onChange={() => handleSelectService(f, i)}
                            />
                          </div>
                        </th>
                        <th className='text-center min-w-100px'>{f?.companyName}</th>
                        <th className='text-center min-w-100px'>{f?.TRN}</th>
                        <th className='text-center min-w-100px'>{f?.tradeLicenseNo}</th>
                        <th className='text-center min-w-100px'>{f?.city}</th>
                        <td className='text-left'>
                          {f?.vendor_list?.map((i: any) => {
                            return <li>{i?.name}</li>
                          })}
                        </td>
                        <th className='text-center min-w-100px'>{f?.phoneNumber}</th>
                        <th className='text-center min-w-100px'>{f?.email}</th>
                        <td className='text-center'>{}</td>
                        <td className='text-center'>
                          <KTSVG
                            path='/media/icons/duotune/general/gen007.svg'
                            className='svg-icon-3 text-primary me-1 ms-2 me-0'
                          />
                          5
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_ecommerce_sales_table_paginate'
                >
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous disabled'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ServiceProviderFilter
        show={providerFilter}
        handleClose={() => {
          setProviderFilter(false)
        }}
        handleChnage={handleChnage}
        providerFilterType={providerFilterType}
      />
    </>
  )
}

export default ServiceProviderList
