import React, {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {ApiGet, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../_metronic/helpers'
import {setCreateNewService} from '../../../redux/counterSlice'
import {useDispatch, useSelector} from 'react-redux'
import leftArrow from '../../../img/arrowleft.svg'
import { Tabs, Tab, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Content from './CreateService';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children} </div>
      )}
    </div>
  );
}

interface TabData {
  label: string;
  component: React.FC<any>;
  props?: any;
}

function CreateServiceMain() {

    const navigate = useNavigate()
    const [tabValue, setTabValue] = useState(0);
    const[tabsLength, setTabsLength] = useState(1)
    const[bg,setBG] = useState<any>('')

    const[tabFinal, setTabFinal] = useState(1)

    const dispatch = useDispatch()
    const createNewService = useSelector((state: any) => state.counterReducer.createNewService)
    const [tabData, setTabData] = useState<TabData[]>([
      { label: 'Tab 1', component: () => <Content index={0} updateParent={updateParent} updateSubmitAll={updateSubmitAll}/> },
    ]);
    // const [tabData, setTabData] = useState<TabData[]>([
    // ]);
  
    // Tabs Switching
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      let temp = ''+newValue+'';
      console.log("Is active " + newValue)
      setTabValue(newValue);
      dispatch(setCreateNewService(false))

      localStorage.setItem('activeTab',temp)
    };
  
    // Adding New Tabs
    const handleAddTab = (len: number) => {
      let isSave = localStorage.getItem('isSave')
      // if(isSave === '1'){
      dispatch(setCreateNewService(true))
      localStorage.setItem('isFirstTime', 'true')

      localStorage.setItem('activeTab',''+tabData.length+'')
      const newTabIndex = tabData.length; 
      const temp = 1;
      const rec = newTabIndex + temp
   
      const newTabData = [
        ...tabData,
        {
          label: `Tab ${newTabIndex + 1}`,
          // component: () => <div>Content for Tab {newTabIndex + 1}</div>,
          component: () => <Content index={len} updateParent={updateParent} updateSubmitAll={updateSubmitAll}/>,
        },
      ];
      setTabData(newTabData)
      setTabValue(newTabIndex)
      setTabsLength(newTabData.length)
      localStorage.setItem('totalTab', ''+rec+'')
     
      var tabsss  = localStorage.getItem('Tabsss')
      if(tabsss != null){
      var x = parseInt(tabsss)
      var z = ++x;
      localStorage.setItem('Tabsss',''+z+'')
      }
      else
      localStorage.setItem('Tabsss','2')
      localStorage.setItem('isSave','0')
    // }
    // else{
    //   ErrorToast("Please First Save this Record to Continue!!!")

    // }
      // newServiceRequest(newTabIndex);
      console.log(tabData.length)
    };

  //  Delete Tab /
    const handleDeleteTab = (index: number) => {
      const totalTabs = localStorage.getItem('totalTab')

      if(totalTabs !== null){
      let tb = parseInt(totalTabs)
      setTabFinal(tb)
      }
      
      if(totalTabs != null && totalTabs === ''+1+''){
      localStorage.setItem('activeTab',''+0+'')
      }

      let x = [{label:'Tab 0', component: () => <Content index={0} updateParent={updateParent} updateSubmitAll={updateSubmitAll}/>}]
      if(totalTabs != null) {
        for(let i=1; i < (parseInt(totalTabs)-1); i++){
        const newTabData = [
        ...x,
          {
          label: `Tab ${i}`,
          // component: () => <div>Content for Tab {newTabIndex + 1}</div>,
          component: () => <Content index={i} updateParent={updateParent} updateSubmitAll={updateSubmitAll}/>,
          }
        ]
          x = newTabData
          
        }
        console.log("xxxx "+x)
          setTabData(x)
          console.log(tabData)
          setTabsLength(parseInt(totalTabs))

        const y = localStorage.getItem('activeTab')
      
        if(y != null){
        let z = parseInt(y)
        const tabFin = localStorage.getItem('totalTab')
        
        if(tabFin !== null){
          let tbF = parseInt(tabFin)
        if (z === 0 && tbF === 1){
          // console.log("condition 1")
          // console.log("z = "+z +" tabFinal "+tbF)
          navigate('/request-management/service')
        }
        else if( z === 0 && tbF !== 0){
          // console.log("condition 2")
          // console.log("z = "+z +" tabFinal "+tbF)
          setTabValue(z)
          localStorage.setItem('activeTab',''+z+'')

        }
        else if(z == (tbF-1)){
          // console.log("condition 3")
          // console.log("z = "+z +" tabFinal "+tbF)
          setTabValue(z-1)
          localStorage.setItem('activeTab',''+(z-1)+'')

        }
        else{
          // console.log("condition 4")
          // console.log("z = "+z +" tabFinal "+tbF)
          setTabValue(z)
          localStorage.setItem('activeTab',''+z+'')

        }
      }
        }
    }

    // // removing from localStorage + swapping values
      let temp = 'serviceRequestId'+index
      localStorage.removeItem(temp)

      console.log("temp" + temp)

      console.log("tabLENGTH"+tabsLength)

      const total = localStorage.getItem('totalTab')
      if(total != null){
        let j = parseInt(total) + 1

      for(let i=index; i < (j); i++){
        let next = localStorage.getItem('serviceRequestId'+(i+1))
        if(next !== null){
          console.log("next " + next)
          let temp = 'serviceRequestId'+i
          localStorage.setItem(temp,next);
        }
       
      }
      localStorage.removeItem('serviceRequestId'+total)
    }
       
      // event.stopPropagation();
      // const newTabData = tabData.filter((tab, i) => i !== index);
      // console.log(newTabData)

    //  
      // setTabData(newTabData);
      // if (tabValue === index && newTabData.length > 0) {
      //   setTabValue(0);
      // }
      // setTabsLength(newTabData.length)

      // // removing from localStorage + swapping values
      // localStorage.setItem('totalTab',''+newTabData.length+'')
      // let temp = 'serviceRequestId'+index
      // localStorage.removeItem(temp)

      // for(let i=index; i<tabsLength; i++){
      //   let next = localStorage.getItem('serviceRequestId'+(i+1))
      //   if(next != null){
      //     let temp = 'serviceRequestId'+i
      //     localStorage.setItem(temp,next);
      //   }
      //   localStorage.removeItem('serviceRequestId'+(i+1))
      // }
    };

    const updateParent = (newValue: number) => {
      // console.log(newValue)
      handleDeleteTab(newValue)
    };

    const updateSubmitAll = (newValue: number) => {
      let x = [{label:'Tab 0', component: () => <Content index={0} updateParent={updateParent} updateSubmitAll={updateSubmitAll}/>}]
     
        for(let i=1; i < newValue; i++){
        const newTabData = [
        ...x,
          {
          label: `Tab ${i}`,
          // component: () => <div>Content for Tab {newTabIndex + 1}</div>,
          component: () => <Content index={i} updateParent={updateParent} updateSubmitAll={updateSubmitAll}/>,
          }
        ]
          x = newTabData
        }
          setTabData(x)
          setTabValue(0)
          localStorage.setItem('activeTab',''+0+'')
          setBG('mistyrose')
          setTimeout(()=>{
            setBG('')
          },5000)

    }

    const goBack = () =>{
        //  navigate(-1)
        navigate('/request-management/service')
          // Clearing All isSave Statuses
        var x  =localStorage.getItem('Tabsss')
        if(x !== null)
        {
          var y = parseInt(x)
          for(let i=0; i<=y; i++){
            var z = localStorage.getItem('isSave'+i)
            if(z !== null){
              localStorage.removeItem('isSave'+i)
            }
          }
        }

        // Clearing All serviceRequestIds
        x  =localStorage.getItem('Tabsss')
        if(x !== null)
        {
          var y = parseInt(x)
          for(let i=0; i<=y; i++){
            var z = localStorage.getItem('serviceRequestId'+i)
            if(z !== null){
              localStorage.removeItem('serviceRequestId'+i)
            }
          }
        }

        localStorage.removeItem('submitAll')
        localStorage.removeItem('saveAll')
    }

    useEffect(()=>{
      const totalTabs = localStorage.getItem('totalTab')
      if(totalTabs != null && totalTabs === ''+1+''){
      localStorage.setItem('activeTab',''+0+'')
      }

      // Retain all Tabs Record
      let x = [{label:'Tab 0', component: () => <Content index={0} updateParent={updateParent} updateSubmitAll={updateSubmitAll}/>}]
      if(totalTabs != null) {
        for(let i=1; ''+i+'' < totalTabs; i++){
        const newTabData = [
        ...x,
          {
          label: `Tab ${i}`,
          // component: () => <div>Content for Tab {newTabIndex + 1}</div>,
          component: () => <Content index={i} updateParent={updateParent} updateSubmitAll={updateSubmitAll}/>,
          }
        ]
          x = newTabData
          
     }
          setTabData(x);
          console.log(tabData)

      const y = localStorage.getItem('activeTab')
      
     
      if(y != null){
        let z = parseInt(y)
      setTabValue(z)
      }
     
      localStorage.setItem('requestType','2')
     

    }
    },[])

return (
    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
        <div id='' className='container-xxl pt-0 mt-0 px-1'>
            <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3 px-5'>
                <div className='d-flex align-items-center gap-5' >
                <span
                    className='rounded-circle'
                    style={{cursor: 'pointer'}}
                    onClick={()=> goBack()}
                >
                    <img src={leftArrow} style={{stroke: 'red'}} />
                </span>
                <h1 className='page-heading green_color m-0'>New Service Request</h1>
                </div>
            </div>

            {/* <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3 justify-content-center'>
              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                <li
                  className='nav-item'
                  onClick={() => {
                    navigate(`/request-management/service/create-request-service-internal/0`)
                  }}
                >
                  <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                    Internal
                  </a>
                </li>
                <li
                  className='nav-item'
                  onClick={() => {
                    navigate(`/request-management/service/create-request-service-external`)
                  }}
                >
                  <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                    External
                  </a>
                </li>
                </ul>
            </div> */}

        <div className='card-flush gap-5 p-5'>

        <div>
      <Tabs value={tabValue} onChange={handleTabChange} className="tab-width">
        {tabData.map((tab, index) => (
          
          <Tab className="d-tabs text-capitalize" key={index} label={'Request '+(++index)} id={`tab-${index}`} aria-controls={`tabpanel-${index}`} style={{backgroundColor: bg, fontSize:'13px'}} />
            
           
          
        ))}
        <IconButton onClick={()=>handleAddTab(tabsLength)}>
          <AddIcon />
        </IconButton>
      </Tabs>
      {tabData.map((tab, index) => (
        <div key={index}>
          <TabPanel value={tabValue} index={index}>
          {/* <IconButton onClick={(event) => handleDeleteTab(event, index)}> */}
            {/* No X btn for for one tab only */}
            {/* {tabData.length != 1 && 
            <CloseIcon /> 
             } 
          </IconButton> */}
          
          <tab.component  index={0} updateParent={updateParent} updateSubmitAll={updateSubmitAll}/>
          </TabPanel>
        </div>
      ))}
    </div>
        </div>
        </div>
    </div>
    )
}
export default CreateServiceMain