import React, {useState} from 'react'
import {Col, Row, Modal} from 'react-bootstrap'
import close from '../../../img/close.png'
import refundBlue from '../../../img/refund-blue.png'
import {ApiGet} from '../../../apiCommon/helpers/API/ApiData'
import refundWhite from '../../../img/refund-white.png'
import ViewRefund from './ViewRefund'
import { ErrorToast } from '../../../apiCommon/helpers/Toast'

interface ButtonProps {
  show: any
  handleClose: any
  refunds: any
  paymentId: any
  tenantDetails: any
  getPaymentRefunds: any
  bankAccountItems: any
  from:any
}

const ViewAllRefundsOfPayment = ({
  handleClose,
  show,
  refunds,
  paymentId, 
  tenantDetails,
  getPaymentRefunds,
  bankAccountItems,
  from
}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [viewRefund, setViewRefund] = useState<any>(false)
  const [refundToView, setRefundToView] = useState<any>()

  const getRefundById = async (id: any) => {
    let url: any
    if (from == 'terminate-tenancy')
      url = `corporate/payment_refund/${id}?isCloneRecord=true`
    else url = `corporate/payment_refund/${id}`

    await ApiGet(`${url}`) 
      .then((res) => {
        setRefundToView(res?.data?.data)
        setViewRefund(true)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto no-print'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 refund-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white' style={{fontWeight: '700'}}>
                Refunds
              </h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={handleClose}
              >
                <img className='svg-icon-1 text-black' src={close} width={14} height={14} />
              </div>
            </Col>
          </Row>
        </div>

        <div className='refund-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '65px'}}>
              <img src={refundWhite} height={80} width={80} className='me-3 ' />
            </Col>
          </Row>
        </div>

        <div
          className='modal-body pt-0 pe-lg-3 pb-0 ps-3'
          style={{
            transform: 'translate(0px, -80px)',
            height: '400px',
            maxHeight: '500px',
            overflowX: 'scroll',
            marginBottom: '-86px',
          }}
        >
          <div className='row px-3 pt-2'>
            {refunds?.length > 0 &&
              refunds.map((rf: any, index: any) => (
                <div className='img-sets mw-350px col-2 mt-2 ps-2 text-center'>
                  <div className='text-center'>
                    <a
                      onClick={() => {
                        if (((from == 'tenancy' || from == 'terminate-tenancy') && ((user?.isSubUser && role?.leasing_residential?.lease_financial?.refunds?.view_details) ||
                        !user?.isSubUser)) || 
                        (from == 'propertyProfile') || 
                        (from == 'tenant') || 
                        (from == 'financials'))
                        getRefundById(rf?._id)
                        else
                        ErrorToast(`You don't have the permissions to view Refund Details...`)
                      }}
                      style={{color: '#384a62'}}
                      className='d-flex justify-content-center'
                    >
                      <img
                        src={refundBlue}
                        width='50'
                        height='50'
                        className='main_img cursor-pointer ms-2'
                      />
                    </a>
                    <p
                      style={{
                        height: '15px',
                        fontSize: '0.9rem',
                        wordBreak: 'break-all',
                      }}
                      className='mt-1 text-center'
                    >
                      {rf?.refundNo}{' '}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>

      {viewRefund && (
        <ViewRefund
          show={viewRefund}
          handleClose={() => {
            setViewRefund(false)
          }}
          refund={refundToView}
          paymentId={paymentId}
          tenantDetails={tenantDetails}
          dueDate={''}
          bankAccountItems={bankAccountItems}
          from={from}
        />
      )}
    </div>
  )
}

export default ViewAllRefundsOfPayment
