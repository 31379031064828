import React, {useEffect, useState, useCallback} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../img/back-arrow.png'
import {ApiPost, ApiPut} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import noData from '../../../../img/NoData1.svg'
import ReactPaginate from 'react-paginate'
import CircularProgress from '@mui/material/CircularProgress'
import {capitalizeFirstLetter, formatAmount} from '../../../../Utilities/utils'
import moment from 'moment'
import Stepper from './Stepper'

interface EmailTemplate {
  id: string
  email: string
  isEnable?: boolean
}

const TypeListing: React.FC = () => {
  const navigate = useNavigate()
  const {type} = useParams<{type: string}>()

  const [requestTableData, setRequestTableData] = useState<EmailTemplate[]>([])
  const [page, setPage] = useState<number>(1)
  const [limit] = useState<number>(25)
  const [pageLimit, setPageLimit] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [templateTypes, setTemplateTypes] = useState<any>([
    `${
      type == 'invoices'
        ? 'invoice_share_via_email'
        : type == 'receipts'
        ? 'receipt_share_via_email'
        : type == 'refunds'
        ? 'refund_share_via_email'
        : type == 'credit notes'
        ? 'credit_note_share_via_email'
         : type == 'debit notes'
        ? 'debit_note_share_via_email'
        : ''
    }`,
  ])

  const handlePageClick = (event: {selected: number}) => {
    setPage(event.selected + 1)
  }

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const body = {page, limit, templateTypes}

      const res = await ApiPost('corporate/email_template/get', body)
      setRequestTableData(res?.data?.data?.email_template || [])
      setPageLimit(res?.data?.data?.state?.page_limit || 1)
    } catch (err: any) {
      ErrorToast(err.message)
      if (err?.message === 'Your account has been suspended!') {
        localStorage.clear()
        window.location.pathname = '/auth'
      }
    } finally {
      setIsLoading(false)
    }
  }, [page, limit])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  // 
  function capitalizeWords(sentence: string): string {
    return sentence.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  return (
    <div id='' className='pt-0 mt-0 px-2 ps-1'>
      <div className='d-flex align-items-start'>
        <div className='d-flex align-items-start'>
          <span
            className='rounded-circle cursor-pointer'
            onClick={() => navigate(`/settings/communications`)}
          >
            <img src={backArrow} height={14} width={14} className='me-4' alt='Back' />
          </span>
          <div>
            <h2 className='page-heading m-0 head-text'>
              {`Email Builder - ${capitalizeWords(type || '')}`}
            </h2>
          </div>
        </div>
        <button
          type='button'
          className='btn btn-sm fw-bold green-submit-btn px-7 justify-content-center btn-fit-content ms-auto'
          onClick={() => navigate(`/settings/communications/${type}/email-templates/select`)}
        >
          Create Email
        </button>
      </div>
      <div className='d-flex mt-5 d-flex justify-content-center'>
        <Stepper />
      </div>

      <div className='card card-flush gap-5 p-5 pt-1 pb-0 tst mt-5'>
        <div className='card-body pt-0 table-responsive mt-5 py-2 px-1'>
          <table className='table align-middle fs-6 gy-5'>
            <thead>
              <tr className='text-start text-gray-400 fw-bold text-uppercase'>
                <th>Template Name</th>
                <th>Sent From</th>
                <th>Status</th>
                <th>Developments</th>
                <th>Total Sent</th>
                <th>Delivered</th>
                <th>Undelivered</th>
                <th>Created By</th>
                <th>Last Updated</th>
              </tr>
            </thead>
            <tbody className='fw-semibold head-text'>
              {isLoading ? (
                <tr>
                  <td colSpan={9} className='text-center'>
                    <div
                      className='d-flex justify-content-center align-items-center w-100'
                      style={{height: '25vh'}}
                    >
                      <CircularProgress style={{color: '#007a59'}} />
                    </div>
                  </td>
                </tr>
              ) : requestTableData.length > 0 ? (
                requestTableData.map((itm: any, index: number) => (
                  <tr key={index}>
                    {/* Render your row data here */}
                    <td>{itm?.name || '-'}</td>
                    <td>{itm?.from || '-'}</td>
                    <td style={{color: itm?.isActive ? '#0d9e91' : '#a1a5b7'}}>
                      {itm?.isActive ? 'Active' : 'Inactive'}
                    </td>
                    <td>
                      {' '}
                      {formatAmount(
                        (itm?.buildingIds?.length || 0) + (itm?.communityIds?.length || 0)
                      )}
                    </td>
                    <td>{itm?.totalSent > 0 ? formatAmount(itm?.totalSent) : 0}</td>

                    <td>{itm?.totalDelivered > 0 ? formatAmount(itm?.totalDelivered) : 0}</td>
                    <td>{itm?.totalUnDelivered > 0 ? formatAmount(itm?.totalUnDelivered) : 0}</td>
                    <td>
                      <p className='my-0'>
                        {itm?.createdByUser?.[0] &&
                          `${itm?.createdByUser?.[0]?.firstName} ${itm?.createdByUser?.[0]?.lastName} - `}
                      </p>
                      <p className='my-0'>
                        {itm?.createdAt
                          ? `${moment(itm?.createdAt).format('DD.MM.YYYY - hh:mm  a')}`
                          : '-'}
                      </p>
                    </td>
                    <td>
                      {itm?.updatedByUser?.length > 0 ? (
                        <>
                          <p className='my-0'>
                            {itm?.updatedByUser?.[0] &&
                              `${itm?.updatedByUser?.[0]?.firstName} ${itm?.updatedByUser?.[0]?.lastName} - `}
                          </p>
                          <p className='my-0'>
                            {itm?.updatedAt
                              ? ` ${moment(itm?.updatedAt).format('DD.MM.YYYY - hh:mm a')}`
                              : '- -'}
                          </p>
                        </>
                      ) : (
                        <>{'-'}</>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={9} className='text-center'>
                    <img src={noData} alt='No Data' width={350} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className='row mb-5'>
          <div className='col-sm-12 col-md-12 d-flex justify-content-center justify-content-md-end'>
            <div
              className='dataTables_paginate paging_simple_numbers'
              id='kt_ecommerce_sales_table_paginate'
            >
              <ReactPaginate
                breakLabel='...'
                nextLabel='>'
                pageClassName='paginate_button page-item +'
                pageLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
                previousClassName='paginate_button page-item previous'
                nextClassName='paginate_button page-item next'
                previousLinkClassName='page-link'
                nextLinkClassName='page-link'
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageLimit}
                forcePage={page - 1}
                previousLabel='<'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TypeListing
