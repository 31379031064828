import React, {useState, useEffect} from 'react'
import {Col, Row, Modal} from 'react-bootstrap'
import close from '../../../img/close.png'
import redCross from '../../../img/x-mark.png'
import {ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import invoiceWhite from '../../../img/invoice-white.png'
import chequeImg from '../../../img/cheque.png'
import moment from 'moment'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import './style.scss'
import ViewAllCreditNotes from './ViewAllCreditNotes'
import ViewCreditNote from './ViewCreditNote'
import saveImg from '../../../img/Save-White.png'
import Select from 'react-select'
import Switch from 'react-switch'

interface ButtonProps {
  show: any
  handleClose: any
  invoice: any
  dueDate: any
  paymentId: any
  tenantDetails: any
  paymentMethod: any
  getPaymentInvoices: any
  invRecDetails: any
  itemToAdd: any
  detailsToEdit: any
  updateRecord: any
  disableAction: any 
  from:any
}

const ViewDraftInvoice = ({
  handleClose,
  show,
  invoice,
  dueDate,
  paymentId,
  tenantDetails,
  paymentMethod,
  getPaymentInvoices,
  invRecDetails,
  itemToAdd,
  detailsToEdit,
  updateRecord,
  disableAction,
  from
}: ButtonProps) => {
  const [invoiceNoError, setInvoiceNoError] = useState<any>(false)
  const [invoiceNo, setInvoiceNo] = useState<any>('')
  var nonVat: any = 0
  var vatAmount: any = 0
  const [invoiceNotes, setInvoiceNotes] = useState<any>(invoice?.note)
  const [viewAllCreditNotes, setViewAllCreditNotes] = useState<any>(false)
  const [invoiceChequeMedia, setInvoiceChequeMedia] = useState<any>([])
  const [selectedAccountInvoice, setSelectedAccountInvoice] = useState<any>()
  const [showAccInvoice, setShowAccInvoice] = useState<any>(false)
  const [invoiceChequeBankName, setInvoiceChequeBankName] = useState<any>()
  const [invoicePaymentMethod, setInvoicePaymentMethod] = useState<any>(
    invoice?.payment?.[0]?.paymentMethod
  )

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [checkedRec, setCheckedRec] = useState<any>(invoice?.isShareToTenant)

  const [updateSubItem, setUpdateSubItem] = useState<any>([])

  const [cardItems, setCardItems] = useState<any>([
    {value: '0', label: 'Card'},
    {value: '1', label: 'Cheque'},
    {value: '2', label: 'Bank'},
    {value: '3', label: 'Cash'},
  ])

  const [bankAccountItems, setBankAccountItems] = useState<any>([
    {value: '0', label: 'Account 1'},
    {value: '1', label: 'Account 2'},
    {value: '2', label: 'Account 3'},
    {value: '3', label: 'Account 4'},
  ])

  var accountInd: any

  const customStylesInputFilled2 = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #35475e',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '600'}),
  }

  const customStyles2 = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '700'}),
  }

  // getting list of all bank accounts
  const getBankAccounts = () => {
    const body = {
      limit: 25,
      page: 1,
    }
    ApiPost(`corporate/payment_account/get`, body)
      .then((res: any) => {
        setBankAccountItems(res?.data?.data?.payment_account_data)
      })
      .catch((err: any) => console.log('err', err))
  }

  //
  const updatePaymentInvoice = (id: any, invoice: any, val: any) => {
    // chqImages
    let chqImages: any = []
    if (invoicePaymentMethod == 1) {
      for (let i = 0; i < invoiceChequeMedia?.length; i++) {
        chqImages[i] = invoiceChequeMedia[i]?.image
      }
    }

    //  account selected is Cheque
    let chqBankName: any
    if (invoicePaymentMethod == 1) chqBankName = invoiceChequeBankName
    else chqBankName = null

    //  account selected is Bank
    let paymentAccountId: any
    if (invoicePaymentMethod == 2) paymentAccountId = selectedAccountInvoice?._id
    else paymentAccountId = null

    const body = {
      id: id,
      invoiceNo: invoice?.invoiceNo,
      saveStatus: val,
      paymentMethod: parseInt(invoicePaymentMethod),
      paymentAccountId: paymentAccountId, 
      note: invoiceNotes,
      chequeImages: chqImages,
      chequeBankName: chqBankName,
      isShareToTenant: checkedRec
    }

    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_invoice?isCloneRecord=true`
    else url = `corporate/payment_invoice`

    ApiPut(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        handleClose()
        getPaymentInvoices(paymentId)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  //
  const [dragActive, setDragActive] = React.useState(false)
  const inputRef = React.useRef(null)
  var fileToRename: any = ''

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  }

  const handleInvoiceChequeChange = async (e: any) => {
    e.preventDefault()
    const values = [...invoiceChequeMedia]
    let newVal = values?.length - 1
    
    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let x = values[values.length]?.src?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any = Date.now()

        await ApiPost(
          `upload/compress_image/params?fileType=cheque&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            newVal = newVal + 1
            values[newVal] = {
              src: fileURL,
              name: '',
              fileType: 'cheque',
              databaseId: data?._id,
              image: res?.data?.data?.image,
            }
          })
          .catch((err) => ErrorToast(err?.message))
      }
      setInvoiceChequeMedia(values)
    }
  }

  // set cheque number
  const renameCheque = async (chq: any, type: any) => {
    let chequeImage: any = chq?.image

    let x = chq?.src?.name?.split('.')[1]

    const body = {
      image: chq?.image,
      newFileName: chq?.name + '.' + x,
    }

    await ApiPost(`upload/rename_file`, body)
      .then((res: any) => {
        const values = [...invoiceChequeMedia]
        for (let i = 0; i < values.length; i++) {
          if (values[i].name === chq?.name) values[i].image = res?.data?.data?.image
        }
        setInvoiceChequeMedia(values)
      })
      .catch((err: any) => {
        // ErrorToast(err.message)
        ErrorToast('Name Already Exists.. Please add another name..')
      })
  }

  // delete cheque
  const deleteCheque = (chq: any) => {
    const body = {
      url: chq?.image,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res: any) => {
        const values = [...invoiceChequeMedia]
        let newData: any = []
        let j = 0
        for (let i = 0; i < values.length; i++) {
          if (values[i].image !== chq?.image) {
            newData.push(values[i])
            j++
          }
        }
        setInvoiceChequeMedia(newData)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
      })
  }

  const [creditNoteToView, setCreditNoteToView] = useState<any>()
  const [viewCreditNote, setViewCreditNote] = useState<any>(false)

  useEffect(() => {
    let temp = [...invoice?.paymentSubItems]
    let newObj: any
    if (itemToAdd?.item != undefined) {
      let x = itemToAdd?.lateDetails
      console.log(x)

      if(x?.late_payment_item_charge){
      let xId: any = x?.late_payment_item_charge[0]?._id
      newObj = {
        _id: xId,
        dayAfterPaymentDue: x.late_payment_item_charge[0]?.dayAfterPaymentDue,
        frequency: x.late_payment_item_charge[0]?.frequency,
        feeType: x.late_payment_item_charge[0]?.feeType,
        percentageAmount: x.late_payment_item_charge[0]?.percentageAmount,
        fixedAmount: x.late_payment_item_charge[0]?.fixedAmount,
        VATPercentage: x.late_payment_item_charge[0]?.VATPercentage,
        VATAmount: x.late_payment_item_charge[0]?.VATAmount,
      }
    }
    else{
      let xId: any = x?._id
      newObj = {
        _id: xId,
        dayAfterPaymentDue: x?.dayAfterPaymentDue,
        frequency: x?.frequency,
        feeType: x?.feeType,
        percentageAmount: x?.percentageAmount,
        fixedAmount: x?.fixedAmount,
        VATPercentage: x?.VATPercentage,
        VATAmount: x?.VATAmount,
      }
    }

      temp[invoice?.paymentSubItems?.length] = {
        _id: itemToAdd?._id,
        id: itemToAdd?.id,
        name: itemToAdd?.item,
        paymentItemListId: itemToAdd?.paymentItemListId,
        amount: parseFloat(itemToAdd?.amount),
        quantity: parseFloat(itemToAdd?.qty),
        totalAmount: itemToAdd?.total,
        // VATAmount: parseFloat(invoiceFields[i].vatAmount),
        VATPercentage: parseFloat(itemToAdd?.vat),
        latePaymentFees: itemToAdd?.lateFeeTotal,
        latePaymentSelectionStatus: itemToAdd?.latePaymentSelectionStatus,
        paymentId: paymentId,
        latePaymentObject: newObj,
        amountPaid: 0,
        outstandingAmount: parseFloat(itemToAdd?.total),
        paymentSubItemStatus: 1,
        isLatePaymentFeesRecord: itemToAdd?.isLatePaymentFeesRecord,
      }
    }

    let newVal: any = []
    newVal[0] = temp[invoice?.paymentSubItems?.length]
    setUpdateSubItem(newVal)

    invoice.paymentSubItems = temp

    setSelectedAccountInvoice('')
    setShowAccInvoice(false)
    setInvoicePaymentMethod(invoice?.payment?.[0]?.paymentMethod)
    setInvoiceChequeBankName(invoice?.payment?.[0]?.chequeBankName)

    // Payment Method is Cheque
    if (invoice?.payment?.[0]?.paymentMethod == 1) {
      setInvoiceChequeMedia([])
      if (invoice?.payment?.[0]?.chequeImages?.length > 0) {
        let values: any = []
        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)
        for (let i = 0; i < invoice?.payment?.[0]?.chequeImages?.length; i++) {
          // console.log(invoice?.payment?.[0]?.chequeImages[i])
          if (invoice?.payment?.[0]?.chequeImages[i] != null) {
            let x: any = invoice?.payment?.[0]?.chequeImages[i]?.split('/')[2]
            let y: any = x.split('.')[0]
            values[i] = {
              src: {
                name: invoice?.payment?.[0]?.chequeImages[i],
              },
              name: y,
              fileType: 'cheque',
              databaseId: data?._id,
              image: invoice?.payment?.[0]?.chequeImages[i],
            }
          }
        }
        setInvoiceChequeMedia(values)
      }
    }

    // Payment Method is Bank
    if (invoice?.payment?.[0]?.paymentMethod == 2) {
      setShowAccInvoice(true)
      setSelectedAccountInvoice(invoice?.payment?.[0]?.payment_account[0])
    }

    // get bank Accounts
    getBankAccounts()
  }, [])

  const validateNewInvoice = (val: any) => {
    let vatAmount: any = 0
    let nonVat: any = 0

    if (val == 0) updateSubItem.paymentSubItemStatus = 1
    else updateSubItem.paymentSubItemStatus = 2

    invoice?.paymentSubItems.map((f: any, ind: any) => {
      if (val == 0) f.paymentSubItemStatus = 1
      else f.paymentSubItemStatus = 2

      if (f?.isLatePaymentFeesRecord) {
        vatAmount = vatAmount + (f.amount / 100) * f.VATPercentage
        nonVat = nonVat + f.amount
      } else {
        vatAmount = vatAmount + ((f.amount * f.quantity) / 100) * f.VATPercentage
        nonVat = nonVat + f.amount * f.quantity
      }
    })
    // chqImages
    let chqImages: any = []
    if (invoicePaymentMethod == 1) {
      for (let i = 0; i < invoiceChequeMedia?.length; i++) {
        chqImages[i] = invoiceChequeMedia[i]?.image
      }
    }

    //  account selected is Cheque
    let chqBankName: any
    if (invoicePaymentMethod == 1) chqBankName = invoiceChequeBankName
    else chqBankName = null

    //  account selected is Bank
    let paymentAccountId: any
    if (invoicePaymentMethod == 2) paymentAccountId = selectedAccountInvoice?._id
    else paymentAccountId = null

    let updInv: any = [
      {
        _id: invoice?._id,
        isMainPDF: false,
        invoiceNo: invoice?.invoiceNo,
        name: detailsToEdit?.name,
        currency: detailsToEdit?.currency,
        date: detailsToEdit?.date,
        dueDate: moment(new Date(detailsToEdit?.dueDate)).format('YYYY-MM-DD'),
        note: invoiceNotes,
        // attachments: invoiceAttachedMedia,
        paymentSubItems: invoice?.paymentSubItems,
        paymentMethod: parseInt(invoicePaymentMethod),
        subTotalAmount: parseFloat(nonVat),
        totalVAT: parseFloat(vatAmount),
        totalAmount: parseFloat(nonVat) + parseFloat(vatAmount),
        amountPaid: detailsToEdit?.amountPaid,
        isManualPDF: false,
        saveStatus: val,
        isShareToTenant: checkedRec
      },
    ]

    const body = {
      payment: {
        _id: detailsToEdit?._id,
        name: detailsToEdit?.name,
        note: detailsToEdit?.note,
        currency: detailsToEdit?.currency,
        paymentStatus: detailsToEdit?.paymentStatus,
        categories: detailsToEdit?.categories,
        amountPaid: detailsToEdit?.amountPaid,
        outstandingAmount: detailsToEdit?.outstandingAmount,
        subTotalAmount: detailsToEdit?.subTotalAmount,
        totalVAT: detailsToEdit?.totalVAT,
        totalAmount: detailsToEdit?.totalAmount,
        paymentMethod: parseInt(invoicePaymentMethod),
        paymentAccountId: paymentAccountId,
        chequeBankName: chqBankName,
        dueDate: moment(new Date(detailsToEdit?.dueDate)).format('YYYY-MM-DD'),
        attachments: detailsToEdit?.attachments,
        chequeImages: chqImages,
        tenancyId: detailsToEdit?.tenancyId,
        unitId: detailsToEdit?.unitId,
        clusterId: detailsToEdit?.clusterId,
        unitGroupId: detailsToEdit?.unitGroupId,
        communityId: detailsToEdit?.communityId,
        floorId: detailsToEdit?.floorId,
        buildingId: detailsToEdit?.buildingId,
        serviceRequestId: detailsToEdit?.serviceRequestId,
        serviceJobIds: [],
        reminderAfterPayment: detailsToEdit?.reminderAfterPayment,
      },
      update_payment_invoice: updInv,
      update_payment_sub_item: updateSubItem,
    }

    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment/update_all_in_one?isCloneRecord=true`
    else url = `corporate/payment/update_all_in_one`

    ApiPost(`${url}`, body)
      .then(async (res: any) => {
        if (val == 0) SuccessToast('New Payment Invoice has been saved successfully !!!')
        else SuccessToast('New Payment Invoice has been generated successfully !!!')
        handleClose()
        updateRecord()
        getPaymentInvoices(paymentId)
      })
      .catch((err: any) => ErrorToast(err?.message))
  }

  const isDraftDisabled =  (((from == 'tenancy' || from == 'terminate-tenancy') && ((user?.isSubUser && role?.leasing_residential?.lease_financial?.invoicing?.update) ||
  !user?.isSubUser)) || 
  (from == 'propertyProfile') ||
  (from == 'tenant') || 
  (from == 'financials')) 

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto no-print'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 invoice-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Invoice</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => handleClose()}
              >
                <img className='svg-icon-1 text-black' src={close} width={14} height={14} />
              </div>
            </Col>
          </Row>
        </div>

        <div className='invoice-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '80px'}}>
              <img src={invoiceWhite} height={80} width={65} className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body px-lg-5 pt-lg-2'
          style={{
            maxHeight: '470px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
            marginBottom: '-90px',
          }}
        >
          <Row className='mt-2'>
            <Col md={8}>
              <button
                className='btn btn-sm fw-bold ps-2 pe-4 text-white ms-5'
                style={{
                  width: '120px',
                  backgroundColor: '#008081',
                  fontSize: '13px',
                }}
                onClick={() => {
                  if (itemToAdd?.item != undefined) validateNewInvoice(1)
                  else updatePaymentInvoice(invoice?._id, invoice, 1)
                }}
                disabled={disableAction || !isDraftDisabled ? true : false}
              >
                Generate
              </button>

              <button
                className='btn btn-sm fw-bold ps-1 pe-4 ms-3'
                style={{
                  width: '120px',
                  backgroundColor: '#8a8aa3',
                  color: 'white',
                  fontSize: '13px',
                }}
                onClick={() => {
                  if (itemToAdd?.item != undefined) validateNewInvoice(0)
                  else updatePaymentInvoice(invoice?._id, invoice, 0)
                }}
                disabled={disableAction || !isDraftDisabled ? true : false}
              >
                <img
                  src={saveImg}
                  height={18}
                  width={18}
                  style={{
                    marginRight: '12px',
                    marginTop: '-2px',
                  }}
                />{' '}
                {'  '}
                Save
              </button>
            </Col>
            <Col md={4} className='py-3 '>
              <div className='d-flex align-items-center mt-1 justify-content-end'>
                <Switch
                  onChange={(e) => {
                    setCheckedRec(e)
                  }}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  checked={checkedRec}
                  onColor='#008081'
                  offColor='#b0bec5'
                  disabled={!isDraftDisabled ? true : false} 
                />{' '}
                <i className='ms-3 me-1 text-dark'>Share copy with recipient</i>
              </div>
            </Col>
          </Row>

          <div
            className='card px-6 mt-5 py-3'
            style={{boxShadow: '0px 0px 20px 0px darkgray !important;'}}
          >
            {invoice?.isVoid && (
              <Row>
                <Col md={4} className='pe-4'>
                  <div className='d-flex align-items-center mt-4 '>
                    <h1 className=' text-danger mb-0'>VOID</h1>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={6} className='pe-4'>
                <div className='d-flex align-items-center my-2 mt-4'>
                  <h1 className=''>INVOICE</h1>
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2 fs-7'>
                  <label className='mb-1 pe-5 blue-label fs-7' style={{minWidth: '100px'}}>
                    Invoice No.
                  </label>

                  {invoice?.invoiceNo == null || invoice?.invoiceNo == '' ? (
                    <>
                      {invRecDetails?.invoiceSequence?.activityStatus == 1 ? (
                        <>
                          {invRecDetails?.invoiceSequence?.prefix != null && (
                            <>
                              <input
                                type='text'
                                className={
                                  invoiceNoError
                                    ? 'form-control form-control-solid-bg input-style border-danger '
                                    : 'form-control form-control-solid-bg input-style mytest'
                                }
                                placeholder='Enter Invoice No'
                                name='item_id'
                                value={invRecDetails?.invoiceSequence?.prefix}
                                disabled
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <input
                            type='text'
                            className={
                              invoiceNoError
                                ? 'form-control form-control-solid-bg input-style border-danger '
                                : 'form-control form-control-solid-bg input-style mytest'
                            }
                            placeholder='Enter Invoice No'
                            name='item_id'
                            value={invoiceNo}
                            disabled={!isDraftDisabled ? true : false}
                            onChange={(event) => {
                              setInvoiceNo(event.target.value)
                              setInvoiceNoError(false)
                            }}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <div style={{fontWeight: '500'}}> {invoice?.invoiceNo}</div>
                  )}
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2 fs-7'>
                  <label className='mb-1 pe-5  blue-label fs-7' style={{minWidth: '100px'}}>
                    Invoice Date
                  </label>
                  <div style={{fontWeight: '500'}}> {moment(new Date()).format('DD-MM-YYYY')}</div>
                </div>

                <div className='d-flex align-items-center justify-content-start my-2 fs-7'>
                  <label className='mb-1 pe-5  blue-label fs-7' style={{minWidth: '100px'}}>
                    Due Date
                  </label>
                  <div style={{fontWeight: '500'}}>{moment(dueDate).format('DD-MM-YYYY')}</div>
                </div>
              </Col>
              <Col md={6}>
                {invoice?.invoiceLogo != null && (
                  <div className='d-flex mb-3  '>
                    <img
                      src={`${Bucket}${invoice?.invoiceLogo}`}
                      height='140'
                      width='140'
                      style={{borderRadius: '7px'}}
                      className='mt-2 ms-auto'
                    />
                  </div>
                )}
              </Col>
            </Row>

            <Row className='mt-5 pb-2'>
              <Col md={6} className='text-start pe-3' style={{fontWeight: '600'}}>
                <p style={{marginBottom: '0.1rem'}}>
                  {invoice?.invoiceCompany?.name != null && invoice?.invoiceCompany?.name}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {invoice?.invoiceCompany?.address != null && invoice?.invoiceCompany?.address}{' '}
                </p>

                <p style={{marginBottom: '0.1rem'}}>
                  {invoice?.invoiceCompany?.phoneNumber &&
                    invoice?.invoiceCompany?.countryCode +
                      ' ' +
                      invoice?.invoiceCompany?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {invoice?.invoiceCompany?.email && invoice?.invoiceCompany?.email}
                </p>
              </Col>
              <Col md={6} style={{fontWeight: '600'}} className='text-end'>
                <h4>
                  <b>Recipient</b>
                </h4>

                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.firstName} {tenantDetails?.lastName}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>{tenantDetails?.email}</p>
              </Col>
            </Row>
            <Row className='pb-5' style={{borderBottom: '2px dashed lightgray'}}>
              <Col md={12} className='pe-4'>
                <div className='d-flex align-items-center my-2'>
                  <label
                    className='mb-1 me-5 pe-5 head-text'
                    style={{minWidth: '50px', maxWidth: '50px'}}
                  >
                    <b>TRN</b>
                  </label>
                  <b>{invoice?.invoiceCompany?.trn ? invoice?.invoiceCompany?.trn : '-'}</b>
                </div>
              </Col>
            </Row>

            <table
              className='table align-middle fs-6 gy-5 mt-3 pb-5'
              id='kt_ecommerce_sales_table'
              style={{borderBottom: '1.5px dashed lightgray'}}
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-50px'>#</th>
                  <th className='min-w-100px'>Item</th>
                  <th className='min-w-100px'>Qty</th>
                  <th className='min-w-100px'>Rate</th>
                  <th className='min-w-100px'>Vat</th>
                  <th className='min-w-100px'>Amount</th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}}>
                {invoice?.paymentSubItems.map((field: any, index: any) => {
                  let y: any = ((field.amount * field.quantity) / 100) * field.VATPercentage
                  let total: number = parseFloat(y) + field.amount * field.quantity
                  return (
                    <tr style={{borderBottom: '1px solid lightgray', height: '45px'}}>
                      <td className='py-2'>{index + 1}</td>
                      <td className='py-2'>{field.name}</td>
                      <td className='py-2'>{field.quantity}</td>
                      <td className='py-2'>
                        {field?.isLatePaymentFeesRecord
                          ? (field.amount / field?.quantity)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })
                          : field.amount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </td>
                      <td className='py-2'>
                        {field?.isLatePaymentFeesRecord
                          ? ((field.amount / 100) * field.VATPercentage)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })
                          : (
                              ((field.amount * field.quantity) / 100) *
                              field.VATPercentage
                            )?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </td>
                      <td className='py-2'>
                        {field?.totalAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </td>
                    </tr>
                  )
                })}

                <>
                  {invoice?.paymentSubItems.map((f: any, ind: any) => {
                    vatAmount = 0
                    nonVat = 0
                    return <></>
                  })}
                </>
                {invoice?.paymentSubItems?.length > 0 && (
                  <>
                    {invoice?.paymentSubItems.map((f: any, ind: any) => {
                      if (f?.isLatePaymentFeesRecord) {
                        vatAmount = vatAmount + (f.amount / 100) * f.VATPercentage
                        nonVat = nonVat + f.amount
                      } else {
                        vatAmount = vatAmount + ((f.amount * f.quantity) / 100) * f.VATPercentage
                        nonVat = nonVat + f.amount * f.quantity
                      }
                      return <></>
                    })}
                  </>
                )}

                <tr style={{height: '55px'}}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5 fs-4' style={{color: '#B5B5C3'}}>
                    SUB TOTAL
                  </td>
                  <td className='py-2 fs-4'>
                    {nonVat?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>

                <tr style={{height: '30px'}}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5 fs-4' style={{color: '#B5B5C3'}}>
                    VAT
                  </td>
                  <td className='py-2 fs-4'>
                    {vatAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>
                <tr style={{height: '55px'}} className='pb-4'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5 fs-4'>Total</td>
                  <td className='py-2 fs-3 fs-4' style={{fontWeight: '800px'}}>
                    {' '}
                    <b>
                      AED{' '}
                      {(nonVat + vatAmount)?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>

            <Row>
              <Col md={7}>
                <div className='row mt-3'>
                  <div className='col-md-9 ps-5 fs-5 d-flex align-items-center head-text'>
                    <label
                      className='mb-1 me-5 head-text'
                      style={{minWidth: '120px', fontWeight: '800'}}
                    >
                      Payment Method
                    </label>
                    <div style={{width: '-webkit-fill-available'}}>
                      <Select
                        options={cardItems}
                        styles={customStylesInputFilled2}
                        isSearchable={false}
                        defaultValue={
                          invoicePaymentMethod == 0
                            ? cardItems[0]
                            : invoicePaymentMethod == 1
                            ? cardItems[1]
                            : invoicePaymentMethod == 2
                            ? cardItems[2]
                            : cardItems[3]
                        }
                        onChange={(event: any) => {
                          setInvoicePaymentMethod(event.value)
                          if (event.value == 2) getBankAccounts()
                          else {
                            setShowAccInvoice(false)
                            setSelectedAccountInvoice(null)
                          }
                          // updatePayment(invoiceUrl, event.value)
                        }}
                        isDisabled={!isDraftDisabled ? true : false}
                      />
                    </div>
                  </div>
                </div>

                <div className='row mt-3'>
                  <div className='col-md-9 ps-5 fs-5'>
                    {/* Payment Method: Bank */}
                    {invoicePaymentMethod == 2 ? (
                      <>
                        <div className='align-items-center d-flex mb-3'>
                          <label
                            className='mb-1 me-5 pe-5 d-flex head-text'
                            style={{minWidth: '120px', fontWeight: '800'}}
                          >
                            Bank Account
                          </label>

                          {bankAccountItems &&
                            bankAccountItems.map((bA: any, index: any) => {
                              if (invoice?.payment?.payment_account?.[0]?._id == bA._id)
                                accountInd = index
                              if (selectedAccountInvoice?._id == bA._id) accountInd = index

                              return <></>
                            })}

                          <div style={{width: '-webkit-fill-available'}}>
                            {accountInd != null ? (
                              <Select
                                defaultValue={{
                                  label: bankAccountItems[accountInd]?.accountHolderName,
                                  value: bankAccountItems[accountInd]?._id,
                                  id: bankAccountItems[accountInd],
                                }}
                                // bankAccountItems[accountInd]}
                                options={
                                  bankAccountItems &&
                                  bankAccountItems.map((bA: any, index: any) => {
                                    // console.log(pItem)
                                    return {
                                      label: `${bA?.accountHolderName}`,
                                      value: `${bA?._id}`,
                                      id: bA,
                                    }
                                  })
                                }
                                styles={customStylesInputFilled2}
                                isSearchable={false}
                                onChange={(event: any) => {
                                  setShowAccInvoice(true)
                                  setSelectedAccountInvoice(event.id)
                                }}
                                isDisabled={!isDraftDisabled ? true : false}
                              />
                            ) : (
                              <Select
                                options={
                                  bankAccountItems &&
                                  bankAccountItems.map((bA: any, index: any) => {
                                    // console.log(pItem)
                                    return {
                                      label: `${bA?.accountHolderName}`,
                                      value: `${bA?._id}`,
                                      id: bA,
                                    }
                                  })
                                }
                                styles={customStyles2}
                                isSearchable={false}
                                onChange={(event: any) => {
                                  setShowAccInvoice(true)
                                  setSelectedAccountInvoice(event.id)
                                }}
                                isDisabled={!isDraftDisabled ? true : false}
                              />
                            )}
                          </div>
                        </div>
                        {showAccInvoice && (
                          <>
                            <div
                              className='d-flex align-items-center mb-3 head-text'
                              style={{fontWeight: '800'}}
                            >
                              <label
                                className='mb-1 me-5 pe-5'
                                style={{minWidth: '120px', fontWeight: '600'}}
                              >
                                Account Name
                              </label>
                              {selectedAccountInvoice?.accountHolderName}
                            </div>
                            <div
                              className='d-flex align-items-center my-2 head-text'
                              style={{fontWeight: '800'}}
                            >
                              <label
                                className='mb-1 me-5 pe-5'
                                style={{minWidth: '120px', fontWeight: '600'}}
                              >
                                IBAN
                              </label>
                              {selectedAccountInvoice?.IBAN}
                            </div>
                            <div
                              className='d-flex align-items-center mb-3 head-text'
                              style={{fontWeight: '800'}}
                            >
                              <label
                                className='mb-1 me-5 pe-5'
                                style={{minWidth: '120px', fontWeight: '600'}}
                              >
                                Account No.
                              </label>
                              {selectedAccountInvoice?.accountNumber}
                            </div>
                            <div
                              className='d-flex align-items-center mb-3 head-text'
                              style={{fontWeight: '800'}}
                            >
                              <label
                                className='mb-1 me-5 pe-5'
                                style={{minWidth: '120px', fontWeight: '600'}}
                              >
                                Bank Name
                              </label>
                              {selectedAccountInvoice?.bankName}
                            </div>
                          </>
                        )}
                      </>
                    ) : invoicePaymentMethod == 1 ? (
                      <>
                        <div className='d-flex align-items-center justify-content-center mb-2'>
                          <label
                            className='mb-1 me-5 pe-5  blue-label'
                            style={{minWidth: '120px', fontWeight: '800'}}
                          >
                            Bank Name
                          </label>

                          <input
                            type='text'
                            className='form-control form-control-solid-bg input-style mytest'
                            placeholder='Enter Name'
                            name='item_id'
                            value={invoiceChequeBankName}
                            onChange={(event) => {
                              setInvoiceChequeBankName(event?.target.value)
                            }}
                            disabled={!isDraftDisabled ? true : false}
                          />
                        </div>

                        <div className='d-flex justify-content-center my-4 '>
                          <form
                            id='form-file-upload'
                            onDragEnter={handleDrag}
                            onSubmit={(e) => e.preventDefault()}
                            style={{height: '90px'}}
                          >
                            <label
                              id='label-file-upload'
                              htmlFor='input-file-upload'
                              className={
                                dragActive
                                  ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                  : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                              }
                              style={{
                                fontSize: '16px',
                                color: 'white',
                              }}
                              ref={inputRef}
                            >
                              <div
                                style={{
                                  height: '90px',
                                  width: '280px',
                                  border: '2px dashed #384a62',
                                  backgroundColor: '#f5f8fa',
                                }}
                                className=''
                              >
                                <div
                                  className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                                  style={{minWidth: '90px'}}
                                >
                                  Drag & drop your document
                                </div>
                                <div
                                  className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                                  style={{minWidth: '90px'}}
                                >
                                  <div
                                    className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                                    style={{
                                      fontSize: '1rem',
                                      paddingLeft: '15px',
                                      borderRadius: '7px',
                                      backgroundColor: '#35475e',
                                      boxShadow: '0px 0px 20px 0px #516176',
                                    }}
                                  >
                                    Browse Files
                                  </div>
                                </div>
                              </div>
                            </label>
                            <input
                              type='file'
                              multiple={true}
                              onChange={handleInvoiceChequeChange}
                              id='input-file-upload'
                              ref={inputRef}
                              style={{
                                height: '90px',
                                opacity: '-0.9',
                                transform: 'translate(-2px, -90px)',
                              }}
                              disabled={!isDraftDisabled ? true : false}
                            />
                            {dragActive && (
                              <div
                                id='drag-file-element'
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              ></div>
                            )}
                          </form>
                        </div>
                        {invoiceChequeMedia.length > 0 && (
                          <div
                            className='row align-items-center my-2'
                            style={
                              invoicePaymentMethod == 1
                                ? {
                                    overflowY: 'scroll',
                                    minHeight: '96px',
                                    maxHeight: '200px',
                                    // transform: 'translate(-2px, -90px)',
                                  }
                                : {overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}
                            }
                          >
                            {invoiceChequeMedia.map((chq: any, ind: any) => (
                              <>
                                <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                                  <div className='d-flex'>
                                    <a
                                      href={`${Bucket}${chq?.image}`}
                                      target='_blank'
                                      style={{color: '#384a62'}}
                                      className='d-flex'
                                    >
                                      <img
                                        src={chequeImg}
                                        width='80px'
                                        height='40px'
                                        className='main_img cursor-pointer'
                                        alt='image'
                                        style={{objectFit: 'cover'}}
                                      />

                                      
                                    </a>
                                    <div className='h-0'>
                                    <img
                                        src={redCross}
                                        width='12px'
                                        height='12px'
                                        className='main_img cursor-pointer'
                                        style={{marginTop: '-25px', marginLeft: '5px'}}
                                        onClick={() => deleteCheque(chq)}
                                      />
                                    </div>
                                  </div>
                                  <input
                                    type='text'
                                    className='form-control form-control-solid-bg input-style mt-1 mytest'
                                    placeholder='Cheque No'
                                    name='item_id'
                                    value={chq.name}
                                    disabled={!isDraftDisabled ? true : false}
                                    onChange={(event) => {
                                      fileToRename = chq
                                      // console.log(event)
                                      const values = [...invoiceChequeMedia]
                                      // let x = values[ind]?.src?.name?.split('.')[1]
                                      values[ind].name = event.target.value

                                      setInvoiceChequeMedia(values)
                                    }}
                                    onBlur={() => {
                                      let fileToRenam = chq

                                      const values = [...invoiceChequeMedia]
                                      let x = values[ind]?.src?.name?.split('.')[1]

                                      renameCheque(fileToRenam, x)
                                    }}
                                    style={{height: '18px', fontSize: '8px', width: '80px'}}
                                  />
                                </div>
                              </>
                            ))}
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Col>
              <Col md={5}>
                {/* Notes */}
                <div className='my-2 mb-5 h-10 pt-3'>
                  <label className='mb-4 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Notes
                  </label>

                  <textarea
                    className='form-control form-control-solid-bg input-style mytest'
                    name='comment'
                    placeholder='Enter any additional notes to the recipient'
                    style={{height: '130px', borderColor: 'lightgray'}}
                    value={invoiceNotes}
                    onChange={(e) => setInvoiceNotes(e.target.value)}
                    maxLength={1000}
                    disabled={!isDraftDisabled ? true : false}
                  ></textarea>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>

      {/* View All Credit Notes */}
      <ViewAllCreditNotes
        show={viewAllCreditNotes}
        handleClose={() => {
          setViewAllCreditNotes(false)
        }}
        creditNotes={invoice?.payment_credit_note}
        paymentId={paymentId}
        tenantDetails={tenantDetails}
        from={from}
      />

      {/* View Single Credit Note */}
      {viewCreditNote && (
        <ViewCreditNote
          show={viewCreditNote}
          handleClose={() => {
            setViewCreditNote(false)
          }}
          creditNote={creditNoteToView}
          paymentId={paymentId}
          tenantDetails={tenantDetails}
          dueDate={dueDate}
          from={from}
        />
      )}
    </div>
  )
}

export default ViewDraftInvoice
