import React, {useEffect, useState} from 'react'
import daysDueIcon from '../../../../img/daysDue.png'
import {useFormContext} from 'react-hook-form'
import {preventExpAndSign, removeLeadingZeros} from '../../../../Utilities/utils'

interface LatePaymentInputI {
  isDisabled: boolean
  iconSize: number
  icon?: string
  type?: string
  label: string
  name: string
  value: string
  onChange: any
  required?: boolean
  firstPlaceholder?: string
  secondPlaceHolder?: string
  className?: string
  setRightPosition?: string
  subLabel?: string
  minValue?: number
  maxValue?: number
}

const FlexedInputFrequency = ({
  type = 'text',
  label,
  name,
  value = '',
  onChange,
  required = false,
  firstPlaceholder = '-',
  secondPlaceHolder = 'Days',
  icon,
  iconSize,
  className = '',
  setRightPosition = '',
  isDisabled,
  subLabel,
  minValue = 0,
  maxValue,
}: // capitalizeFirst = false
LatePaymentInputI) => {
  const {
    register,
    setValue,
    formState: {errors},
  } = useFormContext()

  const [errorMessage, setErrorMessage] = useState<any>('')

  const handleChange = (e: any) => {
    let newValue = e.target.value;
  
    // Remove leading zeros
    newValue = removeLeadingZeros(newValue);
  
    // Prevent entering values out of bounds
    if (type === 'number') {
      const numericValue = parseFloat(newValue);
  
      // Check for min value
      if (minValue !== undefined && numericValue < minValue) {
        setErrorMessage(`Installment must be greater than ${minValue}.`);
        clearErrorMessage();
        return;
      }
  
      // Check for max value
      if (maxValue !== undefined && numericValue > maxValue) {
        setErrorMessage(`Installment must be less than or equal to ${maxValue}.`);
        clearErrorMessage();
        return;
      }
  
      // Clear error message if within bounds
      setErrorMessage('');
    }
  
    onChange(newValue);
  
    // Update the value in form context
    setValue(name, newValue, {
      shouldValidate: true,
    });
  };
  
  // Utility to clear error messages after a delay
  const clearErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage('');
    }, 7000);
  };  

  useEffect(() => {
    if (value.trim().length > 0) {
      setValue(name, value, {
        shouldValidate: true,
      })
    }
  }, [maxValue])

  return (
    <>
      <div className={`${className} d-flex align-items-center`}>
        {label != '' && (
          <div className='percentage-label-container'>
            <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>{label}</label>
          </div>
        )}

        <div className='percentage-input-wrapper mt-2'>
          <input
            disabled={isDisabled}
            {...register(name, {required: false, min: Number(minValue), max: Number(maxValue)})}
            type={type}
            name={name}
            value={value}
            onKeyDown={type === 'number' ? preventExpAndSign : () => {}}
            onChange={handleChange}
            className={`form-control form-control-solid  latePaymentInput ${
              type === 'number' ? 'hide-number-input-arrows' : ''
            } ${value ? 'latePaymentInputFilled' : ''} percentage-input`}
            autoComplete='off'
            onWheel={(e) => e.currentTarget.blur()}
          />
          <span
            className={`percentage-placeholder-left ${
              value ? 'percentage-placeholder percentage-placeholder-filled' : 'percentage-placeholder'
            } ps-3`}
          >
            {value === '' || value === null ? `${firstPlaceholder}` : ''}
          </span>
          <span
            className={`${value ? 'percentage-placeholder percentage-placeholder-filled' : 'percentage-placeholder'}`}
            style={{right: `${setRightPosition}`}}
          >
            {`${secondPlaceHolder}`}
          </span>
        </div>
      </div>
      <div className={`${className} d-flex align-items-center`}>
        <div className='percentage-label-container'>
          <label className='status-w-130'></label>
        </div>
        <div className='percentage-input-wrapper ps-1'>
        {errors[name]?.type === 'required' ? (
          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 fnt-400'>
            This field is required
          </p>
        ) : errors[name]?.type === 'min' ? (
          <p
            style={{color: 'var(--kt-danger)', margin: '0.5rem 0'}}
            className='m-0 mt-1 fnt-400'
          >{`Installment must be greater than ${minValue}.`}</p>
        ) : errors[name]?.type === 'max' ? (
          <p
            style={{color: 'var(--kt-danger)', margin: '0.5rem 0'}}
            className='m-0 mt-1 fnt-400'
          >{`Installment must be less than or equal to ${maxValue}.`}</p>
        ) : (
          ''
        )}

        {errorMessage?.trim()?.length > 0 && (
          <div className={``}>
            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 fnt-400'>
              {errorMessage}
            </p>
          </div>
        )}
      </div>

      </div>
      
    </>
  )
}

export default FlexedInputFrequency
