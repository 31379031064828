import React, {useState, useEffect} from 'react'
import {Col, Row, Modal} from 'react-bootstrap'
import close from '../../../img/close.png'
import redCross from '../../../img/x-mark.png'
import {ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import refundWhite from '../../../img/refund-white.png'
import share from '../../../img/email.png'
import print from '../../../img/printer.png'
import moment from 'moment'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import plusBlue from '../../../img/add-blue.png'
import recipient from '../../../img/recipient.png'
import './style.scss'
import Switch from 'react-switch'
import ViewReceipt from './ViewReceipt'
import chequeImg from '../../../img/cheque.png'
import download from '../../../img/download-white.png'

interface ButtonProps {
  show: any
  handleClose: any
  paymentId: any
  tenantDetails: any
  refund: any
  dueDate: any
  bankAccountItems: any
  from: any
}

const ViewRefund = ({
  handleClose,
  show,
  refund,
  paymentId,
  tenantDetails,
  dueDate,
  bankAccountItems,
  from,
}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [recipientsModal, setRecipientsModal] = useState(false)
  const [checkedRec, setCheckedRec] = useState<any>(refund?.isShareToTenant)
  const [viewReceipt, setViewReceipt] = useState<any>(false)
  const [receiptToView, setReceiptToView] = useState<any>()
  const [chequeMedia, setChequeMedia] = useState<any>([])
  const [selectedAccount, setSelectedAccount] = useState<any>()

  const [recipientsEmails, setRecipientsEmails] = useState<any>([
    {
      email: '',
    },
  ])

  //
  const handleEmailsAddFields = () => {
    const values = [...recipientsEmails]
    values.push({
      email: '',
    })
    setRecipientsEmails(values)
  }

  //
  const handleEmailRemoveFields = (index: any) => {
    const values = [...recipientsEmails]
    values.splice(index, 1)
    setRecipientsEmails(values)
  }

  //
  const shareWithRecipients = async () => {
    let emailsList: any = []
    let j = 0
    for (let i = 0; i < recipientsEmails?.length; i++) {
      if (recipientsEmails[i]?.email?.length != 0) {
        emailsList[j] = recipientsEmails[i]?.email
        j++
      }
    }

    const body = {
      paymentId: paymentId,
      emails: emailsList,
      paymentRefundId: refund?._id,
    }

    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_refund/sent/other?isCloneRecord=true`
    else url = `corporate/payment_refund/sent/other`

    await ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setRecipientsModal(false)
        setRecipientsEmails([
          {
            email: '',
          },
        ])
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const sentCreditNoteToTenant = () => {
    const body = {
      paymentId: paymentId,
      paymentCreditNoteId: refund?._id,
    }

    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment/credit_note/sent?isCloneRecord=true`
    else url = `corporate/payment/credit_note/sent`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        handleClose()
      })
      .catch((err: any) => {
        ErrorToast(err.message)
      })
  }

  const getNegative = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number > 0 ? number * -1 : number
  }

  const getPositive = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const getSum = (numb1: any, numb2: any) => {
    return numb1 + numb2
  }

  const getPaymentReceiptById = async (id: any) => {
    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_receipt/${id}?isCloneRecord=true`
    else url = `corporate/payment_receipt/${id}`
    await ApiGet(`${url}`)
      .then((res) => {
        setReceiptToView(res?.data?.data)
        setViewReceipt(true)

        for (let i = 0; i < bankAccountItems?.length; i++) {
          if (bankAccountItems[i]?._id == res?.data?.data?.paymentAccountId) {
            setSelectedAccount(bankAccountItems[i])
          }
        }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  const updateShareStatus = (val: any) => {
    const body = {
      id: refund?._id,
      isShareToTenant: val,
    }

    let url: any
    if (from == 'terminate-tenancy') url = `corporate/payment_refund?isCloneRecord=true`
    else url = `corporate/payment_refund`

    ApiPut(`${url}`, body)
      .then((res) => {})
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    // Payment Method is Cheque
    if (refund?.paymentMethod == 1) {
      setChequeMedia([])
      if (refund?.chequeImages?.length > 0) {
        let values: any = []
        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)
        for (let i = 0; i < refund?.chequeImages?.length; i++) {
          // console.log(refund?.payment_account?.[0]?.chequeImages[i])
          if (refund?.chequeImages[i] != null) {
            let x: any = refund?.chequeImages[i]?.split('/')[2]
            let y: any = x.split('.')[0]
            values[i] = {
              src: {
                name: refund?.chequeImages[i],
              },
              name: y,
              fileType: 'cheque',
              databaseId: data?._id,
              image: refund?.chequeImages[i],
            }
          }
        }
        setChequeMedia(values)
      }
    }
  }, [])

  const isDraftDisabled =
    ((from == 'tenancy' || from == 'terminate-tenancy') &&
      ((user?.isSubUser && role?.leasing_residential?.lease_financial?.refunds?.update) ||
        !user?.isSubUser)) ||
    from == 'propertyProfile' ||
    from == 'tenant' ||
    from == 'financials'

  return (
    <div>
      {/* REFUND Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto custom-modal no-print'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 refund-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Refund</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => {
                  handleClose()
                }}
              >
                <img className='svg-icon-1 text-black' src={close} width={14} height={14} />
              </div>
            </Col>
          </Row>
        </div>

        <div className='refund-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '65px'}}>
              <img src={refundWhite} height={80} width={80} className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body px-lg-4'
          style={{
            maxHeight: '600px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
            marginBottom: '-100px',
          }}
        >
          <Row>
            <Col md={8}>
              <div className='d-flex'>
                {(((from == 'tenancy' || from == 'terminate-tenancy') &&
                  ((user?.isSubUser &&
                    role?.leasing_residential?.lease_financial?.refunds?.update) ||
                    !user?.isSubUser)) ||
                  from == 'propertyProfile' ||
                  from == 'tenant' ||
                  from == 'financials') && (
                  <button
                    className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-3 text-white status-w-110'
                    style={{
                      backgroundColor: '#384a62',
                      fontSize: '13px',
                    }}
                    onClick={() => {
                      setRecipientsModal(true)
                    }}
                    disabled={!isDraftDisabled ? true : false}
                  >
                    <img
                      src={share}
                      height={18}
                      width={18}
                      style={{
                        marginRight: '12px',
                        marginTop: '-2px',
                      }}
                    />{' '}
                    {'  '}
                    Share
                  </button>
                )}

                <a
                  className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-3 refund-color text-white status-w-110'
                  style={{
                    fontSize: '13px',
                  }}
                  href={`${Bucket}${refund?.pdfURL}`}
                  target='_blank'
                >
                  <img
                    src={download}
                    height={18}
                    width={18}
                    style={{
                      marginRight: '3px',
                      marginTop: '-2px',
                    }}
                  />{' '}
                  Download
                </a>

                <button
                  className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-3 text-white status-w-110'
                  style={{
                    backgroundColor: '#8a8aa3',
                    fontSize: '13px',
                  }}
                  onClick={() => {
                    window.print()
                  }}
                >
                  <img
                    src={print}
                    height={18}
                    width={18}
                    style={{
                      marginRight: '12px',
                      marginTop: '-2px',
                    }}
                  />{' '}
                  {'  '}
                  Print
                </button>

                {(((from == 'tenancy' || from == 'terminate-tenancy') &&
                  ((user?.isSubUser &&
                    role?.leasing_residential?.lease_financial?.receipts?.view_list) ||
                    !user?.isSubUser)) ||
                  from == 'propertyProfile' ||
                  from == 'tenant' ||
                  from == 'financials') && (
                  <button
                    className='btn btn-sm fw-bold ps-1 pe-4 mt-2 ms-3 receipt-color text-white text-white status-w-110'
                    style={{
                      fontSize: '13px',
                    }}
                    onClick={() => {
                      if (
                        ((from == 'tenancy' || from == 'terminate-tenancy') &&
                          ((user?.isSubUser &&
                            role?.leasing_residential?.lease_financial?.receipts?.view_details) ||
                            !user?.isSubUser)) ||
                        from == 'propertyProfile' ||
                        from == 'tenant' ||
                        from == 'financials'
                      )
                        getPaymentReceiptById(refund?.paymentReceiptId)
                      else ErrorToast("You don't have the permissions to view Receipt details...")
                    }}
                  >
                    {'  '}
                    Receipt
                  </button>
                )}
              </div>
            </Col>
            <Col md={4} className='py-3 '>
              {(((from == 'tenancy' || from == 'terminate-tenancy') &&
                ((user?.isSubUser && role?.leasing_residential?.lease_financial?.refunds?.update) ||
                  !user?.isSubUser)) ||
                from == 'propertyProfile' ||
                from == 'tenant' ||
                from == 'financials') && (
                <div className='d-flex align-items-center mt-1 justify-content-end'>
                  <Switch
                    onChange={(e) => {
                      setCheckedRec(e)
                      updateShareStatus(e)
                    }}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    checked={checkedRec}
                    onColor='#ffb100'
                    offColor='#b0bec5'
                    disabled={!isDraftDisabled ? true : false}
                  />{' '}
                  <i className='ms-3 me-1 text-dark'>Share copy with recipient</i>
                </div>
              )}
            </Col>
          </Row>
          <div
            className='card px-6 mt-5'
            style={{boxShadow: '0px 0px 20px 0px darkgray !important;'}}
          >
            <Row className='mt-2'>
              <Col md={8} className='pe-4'>
                <div className='d-flex align-items-center my-2 '>
                  <h1 className=''>REFUND RECIPIENT</h1>
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                    Refund No.
                  </label>
                  <div>
                    <p style={{fontWeight: '800'}} className='mb-1 fs-5'>
                      {refund?.refundNo}
                    </p>
                  </div>
                </div>
                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                    Issue Date
                  </label>
                  <div>
                    <p style={{fontWeight: '800'}} className='mb-1 fs-5'>
                      {moment(refund?.date).format('DD-MM-YYYY')}
                    </p>
                  </div>
                </div>

                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 blue-label fs-5' style={{minWidth: '100px'}}>
                    Receipt No.
                  </label>
                  <div>
                    <p style={{fontWeight: '800'}} className='mb-1 fs-5'>
                      {refund?.receiptNo}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <div className='d-flex my-4 ms-auto'>
                  {refund?.refundLogo && (
                    <img
                      src={`${Bucket}${refund?.refundLogo}`}
                      height='140'
                      width='140'
                      style={{borderRadius: '7px'}}
                      className='ms-auto'
                    />
                  )}
                </div>
                {/* )} */}
              </Col>
            </Row>

            <Row className='mt-5 pb-2'>
              <Col md={6} className='text-start' style={{fontWeight: '600'}}>
                <p style={{marginBottom: '0.1rem'}}>{refund?.refundCompany?.name}</p>
                <p style={{marginBottom: '0.1rem'}}>{refund?.refundCompany?.address}</p>

                <p style={{marginBottom: '0.1rem'}}>
                  {refund?.refundCompany?.countryCode} {refund?.refundCompany?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>{refund?.refundCompany?.email}</p>
              </Col>
              <Col md={6} style={{fontWeight: '600'}} className='text-end'>
                <h5 style={{fontWeight: '700'}}>Recipient</h5>

                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.firstName} {tenantDetails?.lastName}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>{tenantDetails?.email}</p>
              </Col>
            </Row>

            <Row style={{borderBottom: '2px dashed lightgray'}}>
              <Col md={4} className='pe-4 pb-5'>
                <div className='d-flex align-items-center my-2'>
                  <label
                    className='mb-1 me-5 pe-5 head-text'
                    style={{minWidth: '50px', maxWidth: '50px'}}
                  >
                    <b>TRN</b>
                  </label>
                  <b>{refund?.refundCompany?.trn ? refund?.refundCompany?.trn : '-'}</b>
                </div>
              </Col>
            </Row>

            <table
              className='table align-middle fs-6 gy-5 mt-3 pb-5'
              id='kt_ecommerce_sales_table'
              style={{borderBottom: '1.5px dashed lightgray'}}
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold text-uppercase gs-0'>
                  <th className='min-w-50px'>#</th>
                  <th className='min-w-100px'>Item</th>
                  <th className='min-w-100px'>Qty</th>
                  <th className='min-w-100px'>Rate</th>
                  <th className='min-w-100px'>Vat</th>
                  <th className='min-w-100px'>Amount Paid</th>
                  <th className='min-w-100px'>Refund Amount</th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}}>
                {refund?.paymentSubItems?.map((field: any, index: any) => {
                  return (
                    <tr style={{borderBottom: '1px solid lightgray', height: '45px'}}>
                      <td className='py-2'>{index + 1}</td>
                      <td className='py-2'>{field.name}</td>
                      <td className='py-2'>{field.quantity}</td>
                      <td className='py-2'>
                        {field?.isLatePaymentFeesRecord
                          ? getPositive(field?.amount / field?.quantity)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })
                          : getPositive(field?.amount)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                      </td>
                      <td className='py-2'>
                        {getPositive(field?.VATAmount)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}
                      </td>
                      <td className='py-2'>
                        {getPositive(field?.amountPaid)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}
                      </td>
                      <td className='py-2'>
                        {getPositive(
                          getSum(
                            getPositive(parseFloat(field?.refundAmount)),
                            getPositive(parseFloat(field?.refundVATAmount))
                          )
                        )?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}
                      </td>
                    </tr>
                  )
                })}

                <tr style={{height: '55px'}} className='fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                    SUB TOTAL
                  </td>
                  <td className='py-2'>
                    {getPositive(refund?.subTotalRefundAmount)?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                </tr>

                <tr style={{height: '30px'}} className='fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                    VAT
                  </td>
                  <td className='py-2'>
                    {getPositive(refund?.totalRefundVAT)?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                </tr>
                <tr style={{height: '55px'}} className='pb-4 fs-5'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-3'>Total</td>
                  <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                    {' '}
                    <b>
                      AED{' '}
                      {getPositive(refund?.totalRefundAmount)?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>

            <Row>
              <Col md={6}>
                <div className='d-flex my-2 mb-5'>
                  <div className=''>
                    <label
                      className='mb-1 me-1 fs-4'
                      style={{minWidth: '160px', fontWeight: '700'}}
                    >
                      Payment Method
                    </label>
                  </div>
                  <div className='text-start'>
                    <p className='mb-1 me-1 pt-1' style={{minWidth: '160px', fontWeight: '500'}}>
                      {refund?.paymentMethod == 0
                        ? 'Card'
                        : refund?.paymentMethod == 1
                        ? 'Cheque'
                        : refund?.paymentMethod == 2
                        ? 'Bank'
                        : 'Cash'}
                    </p>
                  </div>
                </div>
                {refund?.paymentMethod == 2 && (
                  <>
                    <div className='d-flex align-items-center mb-3'>
                      <label
                        className='mb-1 me-1'
                        style={{minWidth: '110px', maxWidth: '100px', fontWeight: '500'}}
                      >
                        Account Name
                      </label>
                      <b> {refund?.payment_account?.[0]?.accountHolderName}</b>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                      <label
                        className='mb-1 me-1'
                        style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                      >
                        IBAN
                      </label>
                      <b> {refund?.payment_account?.[0]?.IBAN}</b>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                      <label
                        className='mb-1 me-1'
                        style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                      >
                        Account No.
                      </label>
                      <b> {refund?.payment_account?.[0]?.accountNumber}</b>
                    </div>
                    <div className='d-flex align-items-center mb-3'>
                      <label
                        className='mb-1 me-1'
                        style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                      >
                        Bank Name
                      </label>
                      <b> {refund?.payment_account?.[0]?.bankName}</b>
                    </div>
                  </>
                )}

                {refund?.paymentMethod == 1 && (
                  <>
                    <div className='d-flex'>
                      <label className='mb-1 me-5 pe-3  blue-label' style={{minWidth: '100px'}}>
                        Bank Name
                      </label>
                      <p style={{fontWeight: '700'}} className='mb-1'>
                        {refund?.chequeBankName}
                      </p>
                    </div>
                    {chequeMedia.length > 0 && (
                      <div
                        className='row align-items-center my-2'
                        style={{overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}}
                      >
                        {chequeMedia.map((chq: any, ind: any) => (
                          <>
                            <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                              <div className='d-flex'>
                                <a
                                  href={`${Bucket}${chq?.image}`}
                                  target='_blank'
                                  style={{color: '#384a62'}}
                                  className='d-flex'
                                >
                                  <img
                                    src={chequeImg}
                                    width='80px'
                                    height='40px'
                                    className='main_img cursor-pointer'
                                    alt='image'
                                    style={{objectFit: 'cover'}}
                                  />
                                </a>
                              </div>
                              <p className='fs-7 ps-1'>{chq.name}</p>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </Col>
              <Col md={6}>
                {/* Notes */}
                {refund?.note && (
                  <div className='my-2 mb-5 h-10'>
                    <label
                      className='mb-4 me-1 fs-4'
                      style={{minWidth: '160px', fontWeight: '700'}}
                    >
                      Notes
                    </label>
                    <p>{refund?.note}</p>
                  </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Modal>

      {/* Share with recipients */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={recipientsModal}
        onHide={() => {
          setRecipientsModal(false)
          setRecipientsEmails([
            {
              email: '',
            },
          ])
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 '
          style={{
            zIndex: '1',
            opacity: '0.85',
            borderBottom: '0px',
            minHeight: '95px',
            backgroundColor: '#384a62 ',
          }}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Recipients</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross me-1'
                onClick={() => {
                  setRecipientsModal(false)
                  setRecipientsEmails([
                    {
                      email: '',
                    },
                  ])
                }}
              >
                <img className='svg-icon-1 text-black' src={close} width={14} height={14} />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg-edit' style={{backgroundColor: '#384a62'}}>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2'>
              <img
                src={recipient}
                height={85}
                width={85}
                className='me-3 ms-5'
                style={{transform: 'translate(10px, -2px)'}}
              />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body pt-0 pe-lg-3 pb-0 ps-3'
          style={{
            marginBottom: '-93px',
            maxHeight: '500px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
          }}
        >
          <div className='d-flex mt-5 mx-3'>
            <h3 className='head-text mt-5' style={{fontWeight: '700'}}>
              Recipients Emails
            </h3>
            <button
              className='btn btn-sm fw-bold ps-1 pe-4 mt-3 ms-auto text-white status-w-150'
              style={{
                backgroundColor: '#384a62',
                fontSize: '13px',
              }}
              onClick={() => shareWithRecipients()}
            >
              <img
                src={share}
                height={18}
                width={18}
                style={{marginRight: '12px', marginTop: '-2px'}}
              />{' '}
              {'  '}
              Share
            </button>
          </div>

          {recipientsEmails.map((rec: any, index: any) => {
            return (
              <Row className='mx-1 mt-4'>
                <Col lg={4} md={4} className='align-items-center pe-5'>
                  {/* <label className='required mb-2 label-color'>Days after payment is due</label> */}
                  <input
                    type='text'
                    className='form-control form-control-solid-bg mytest'
                    placeholder='Enter Recepient Email..'
                    name='item_id'
                    value={rec.email}
                    onChange={(e) => {
                      const values = [...recipientsEmails]
                      values[index].email = e.target.value
                      setRecipientsEmails(values)
                    }}
                    style={{
                      border: '0.1rem solid #bec3cb',
                      borderRadius: '2px',
                      padding: '0.4rem 1rem',
                    }}
                  />
                </Col>
                <Col lg={4} className='align-items-center mb-0  m-2'>
                  <div className='d-flex align-items-center mt-0'>
                    <img
                      src={redCross}
                      height={18}
                      width={18}
                      className='main_img cursor-pointer '
                      onClick={() => handleEmailRemoveFields(index)}
                    />
                  </div>
                </Col>
                <Col lg={4} md={4} className='align-items-center text-end'></Col>
              </Row>
            )
          })}

          <div
            className='d-flex align-items-center cursor-pointer m-5 ms-0'
            onClick={() => handleEmailsAddFields()}
            style={{marginTop: '30px'}}
          >
            <img src={plusBlue} className='ms-4' height={16} width={16} />
            <p className='head-text ms-3 mb-0'>
              {' '}
              <i>Add another Email</i>
            </p>
          </div>
        </div>
      </Modal>

      {/* View Single Invoice */}
      {viewReceipt && (
        <ViewReceipt
          show={viewReceipt}
          handleClose={() => {
            setViewReceipt(false)
          }}
          receipt={receiptToView}
          paymentId={paymentId}
          selectedAccountReceipt={selectedAccount}
          tenantDetails={tenantDetails}
          from={from}
        />
      )}

      {/* Screen for Print */}
      <div className='app-main print-invoice' id='kt_app_main'>
        <div id='' className=''>
          <div className='d-flex mt-3'>
            <div className=''>
              <div className='d-flex align-items-center my-2 '>
                <h1 className=''>REFUND RECEIPT</h1>
              </div>

              {/*  */}
              <div className='d-flex align-items-center justify-content-start my-2'>
                <label
                  className='mb-1 me-5 pe-5 label-color'
                  style={{minWidth: '105px', fontSize: '1rem'}}
                >
                  Refund No.
                </label>

                <div style={{fontWeight: '500'}}> {refund?.refundNo}</div>
              </div>

              {/*  */}
              <div className='d-flex align-items-center justify-content-start my-2'>
                <label
                  className='mb-1 me-5 pe-5  label-color'
                  style={{minWidth: '105px', fontSize: '1rem'}}
                >
                  Issue Date
                </label>
                <div style={{fontWeight: '500'}}> {moment(refund?.date).format('DD-MM-YYYY')}</div>
              </div>

              <div className='d-flex align-items-center justify-content-start my-2'>
                <label
                  className='mb-1 me-5 pe-5  label-color'
                  style={{minWidth: '105px', fontSize: '1rem'}}
                >
                  Receipt No.
                </label>
                <div style={{fontWeight: '500'}}>{refund?.receiptNo}</div>
              </div>
            </div>
            <div className='text-end ms-auto'>
              {refund?.refundLogo && (
                <img
                  src={`${Bucket}${refund?.refundLogo}`}
                  height='140'
                  width='140'
                  style={{borderRadius: '7px'}}
                  className='ms-auto'
                />
              )}
            </div>
          </div>

          <div className='mt-5 pb-5 d-flex'>
            <div className='text-start pe-3' style={{fontWeight: '600'}}>
              <p style={{marginBottom: '0.1rem'}}>{refund?.refundCompany?.name}</p>
              <p style={{marginBottom: '0.1rem'}}>{refund?.refundCompany?.address}</p>

              <p style={{marginBottom: '0.1rem'}}>
                {refund?.refundCompany?.countryCode} {refund?.refundCompany?.phoneNumber}
              </p>
              <p style={{marginBottom: '0.1rem'}}>{refund?.refundCompany?.email}</p>
            </div>
            <div style={{fontWeight: '600'}} className='text-end ms-auto'>
              <h5>Recipient</h5>

              <p style={{marginBottom: '0.1rem'}}>
                {tenantDetails?.lastName} {tenantDetails?.firstName}
              </p>
              <p style={{marginBottom: '0.1rem'}}>
                {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
              </p>
              <p style={{marginBottom: '0.1rem'}}>{tenantDetails?.email}</p>
            </div>
          </div>

          <div
            className='d-flex align-items-center mb-5 pb-5'
            style={{borderBottom: '2px dashed lightgray'}}
          >
            <label
              className='mb-1 me-5 pe-5 head-text'
              style={{minWidth: '50px', maxWidth: '50px'}}
            >
              <b>TRN</b>
            </label>
            <b>{refund?.refundCompany?.trn ? refund?.refundCompany?.trn : '-'}</b>
          </div>

          <table
            className='table align-middle fs-6 gy-5 mt-3 pb-5'
            id='kt_ecommerce_sales_table'
            style={{borderBottom: '1.5px dashed lightgray'}}
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-50px'>#</th>
                <th className='min-w-100px'>Item</th>
                <th className='min-w-100px'>Qty</th>
                <th className='min-w-100px'>Rate</th>
                <th className='min-w-100px'>Vat</th>
                <th className='min-w-100px'>Amount Paid</th>
                <th className='min-w-100px'> Refund Amount</th>
              </tr>
            </thead>
            <tbody style={{fontWeight: '500'}}>
              {refund?.paymentSubItems?.map((field: any, index: any) => {
                return (
                  <tr style={{borderBottom: '1px solid lightgray', height: '45px'}}>
                    <td className='py-2'>{index + 1}</td>
                    <td className='py-2'>{field.name}</td>
                    <td className='py-2'>{field.quantity}</td>
                    <td className='py-2'>
                      {field?.isLatePaymentFeesRecord
                        ? getPositive(field?.amount / field?.quantity)?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })
                        : getPositive(field?.amount)?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}
                    </td>
                    <td className='py-2'>
                      {getPositive(field?.VATAmount)?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td className='py-2'>
                      {getPositive(field?.amountPaid)?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </td>
                    <td className='py-2'>
                      {getPositive(
                        getSum(
                          getPositive(parseFloat(field?.refundAmount)),
                          getPositive(parseFloat(field?.refundVATAmount))
                        )
                      )?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </td>
                  </tr>
                )
              })}

              <tr style={{height: '55px'}} className='fs-5'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                  SUB TOTAL
                </td>
                <td className='py-2'>
                  {getPositive(refund?.subTotalRefundAmount)?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
                </td>
              </tr>

              <tr style={{height: '30px'}} className='fs-5'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-3' style={{color: '#B5B5C3'}}>
                  VAT
                </td>
                <td className='py-2'>
                  {getPositive(refund?.totalRefundVAT)?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
                </td>
              </tr>
              <tr style={{height: '55px'}} className='pb-4 fs-5'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-3'>Total</td>
                <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                  {' '}
                  <b>
                    AED{' '}
                    {getPositive(refund?.totalRefundAmount)?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>

          <div className='d-flex'>
            <div style={{width: '50%'}}>
              <div className='d-flex my-2 mb-5'>
                <div className=''>
                  <label className='mb-1 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Payment Method
                  </label>
                </div>
                <div className='text-start'>
                  <label className='mb-1 me-1 pt-1' style={{minWidth: '160px', fontWeight: '500'}}>
                    {refund?.paymentMethod == 0
                      ? 'Card'
                      : refund?.paymentMethod == 1
                      ? 'Cheque'
                      : refund?.paymentMethod == 2
                      ? 'Bank'
                      : 'Cash'}
                  </label>
                </div>
              </div>
              {refund?.paymentMethod == 2 && (
                <>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '100px', fontWeight: '500'}}
                    >
                      Account Name
                    </label>
                    <b> {refund?.payment_account?.[0]?.accountHolderName}</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      IBAN
                    </label>
                    <b> {refund?.payment_account?.[0]?.IBAN}</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      Account No.
                    </label>
                    <b> {refund?.payment_account?.[0]?.accountNumber}</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      Bank Name
                    </label>
                    <b> {refund?.payment_account?.[0]?.bankName}</b>
                  </div>
                </>
              )}

              {refund?.paymentMethod == 1 && (
                <>
                  <label className='mb-1 me-5 pe-3  blue-label' style={{minWidth: '100px'}}>
                    Bank Name
                  </label>
                  <p style={{fontWeight: '700'}} className='mb-1'>
                    {refund?.chequeBankName}
                  </p>

                  {chequeMedia.length > 0 && (
                    <div
                      className='row align-items-center my-2'
                      style={{overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}}
                    >
                      {chequeMedia.map((chq: any, ind: any) => (
                        <>
                          <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                            <div className='d-flex'>
                              <a
                                href={`${Bucket}${chq?.image}`}
                                target='_blank'
                                style={{color: '#384a62'}}
                                className='d-flex'
                              >
                                <img
                                  src={chequeImg}
                                  width='80px'
                                  height='40px'
                                  className='main_img cursor-pointer'
                                  alt='image'
                                  style={{objectFit: 'cover'}}
                                />
                              </a>
                            </div>
                            <p className='fs-7 ps-1'>{chq.name}</p>
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
            <div style={{width: '50%'}}>
              {/* Notes */}
              {refund?.note && (
                <div className='my-2 mb-5 h-10'>
                  <label className='mb-4 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Notes
                  </label>

                  <p style={{marginBottom: '0.1rem'}}> {refund?.note}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewRefund
