import React, {useEffect, useState} from 'react'

const TitleHead = (props: any) => {
  return (
    <>
      <div className='card p-5 flex-row justify-content-between'>
        <h1 className='m-0 green_color'>{props.title}</h1>
        <button
          className='btn btn-sm fw-bold btn-primary btn-green'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_create_app'
          type='submit'
        >
          {props.btnTitle}
        </button>
      </div>
    </>
  )
}

export default TitleHead
