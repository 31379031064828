import React, { useState, useEffect, useRef } from 'react'
import "./styles.scss"
import allProperties from '../../../img/allProperties.png'
import allDevelopment from '../../../img/allDevelopment.png'
import allBuilding from '../../../img/small-building.png'
import allCommunities from '../../../img/allCommunities.png'
import bulkImportWhite from '../../../img/bulkImportWhite.png'
import unitsBlue from '../../../img/allPropertiesBlue.png'
import tenantBlue from '../../../img/tenantBlue.png'
import leasesBlue from '../../../img/leasesBlue.png'
import yellowStar from '../../../img/yellow-star.png'
import occupancyBlue from '../../../img/occupancyBlue.png'
import addWhiteIcon from '../../../img/add-white.png'
import CustomDateRangePicker from './CustomDateRangePicker'


import moment from 'moment'
import { useNavigate, useLocation } from 'react-router-dom'
import { ApiDelete, ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData'
import { SuccessToast, ErrorToast } from '../../../apiCommon/helpers/Toast'
import closes from '../../../img/close.svg'
import windowImg from '../../../img/window.png'
import sync from '../../../img/sync.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import { DatePicker, Space } from 'antd'
import Select from 'react-select'
import downGreen from '../../../img/down-arrow-green.png'
import upGreen from '../../../img/up-arrow-green.png'
import bedroomIcon from '../../../img/bedroomsIcon.png'
import bathroomIcon from '../../../img/bathroomsIcon.png'
import sizeIcon from '../../../img/sizeIcon.png'

import PropertiesList from './components/PropertiesList'
import DevelopmentList from './components/DevelopmentList'
import BuildingList from './components/BuildingList'
import CommunityList from './components/CommunityList'
import { Rating } from '@mui/material'
import grayStar from '../../../img/grey-star.png'
import { label } from 'yet-another-react-lightbox/*'
import { MultiSelect } from 'react-multi-select-component'
import cluster from 'cluster'
import allBuildingWhite from '../../../img/small-building.png'
import communityWhite from '../../../img/allCommunities.png'

interface SquareHandleProps {
  value: number
}

const Properties = () => {
  const getALlPropertiesFilter: any = localStorage.getItem('allPropertiesFilter')
  const propertiesFilter = JSON.parse(getALlPropertiesFilter)

  // const getAllPropertiesActiveTab: any = localStorage.getItem('allPropertiesActiveTab')
  // const tab = JSON.parse(getAllPropertiesActiveTab)
  const getAllPropertiesActiveTab: any = localStorage.getItem('allPropertiesActiveTab')
  const tab = JSON.parse(getAllPropertiesActiveTab)

  const getAllPropertiesSubTab: any = localStorage.getItem('allPropertiesSubTabListItem')
  const subTab = JSON.parse(getAllPropertiesSubTab)

  const getAllPropertiesResidentialDate: any = localStorage.getItem('allPropertiesResidentialDateFilter')
  const allPropertiesResidentialDate = JSON.parse(getAllPropertiesResidentialDate) ?? {}

  const getAllPropertiesAllUnitsDate: any = localStorage.getItem('allPropertiesAllUnitsDateFilter')
  const allPropertiesAllUnitsDate = JSON.parse(getAllPropertiesAllUnitsDate) ?? {}

  const getAllPropertiesCommercialDate: any = localStorage.getItem('allPropertiesCommercialDateFilter')
  const allPropertiesCommercialDate = JSON.parse(getAllPropertiesCommercialDate) ?? {}

  const getAllPropertiesCommonAreaDate: any = localStorage.getItem('allPropertiesCommonAreaDateFilter')
  const allPropertiesCommonAreaDate = JSON.parse(getAllPropertiesCommonAreaDate) ?? {}

  const getAllPropertiesDevelopmentDate: any = localStorage.getItem('allPropertiesDevelopmentDateFilter')
  const allPropertiesDevelopmentDate = JSON.parse(getAllPropertiesDevelopmentDate) ?? {}

  const getAllPropertiesBuildingDate: any = localStorage.getItem('allPropertiesBuildingDateFilter')
  const allPropertiesBuildingDate = JSON.parse(getAllPropertiesBuildingDate) ?? {}

  const getAllPropertiesCommunitiesDate: any = localStorage.getItem('allPropertiesCommunitiesDateFilter')
  const allPropertiesCommunitiesDate = JSON.parse(getAllPropertiesCommunitiesDate) ?? {}

  


  const { pathname } = useLocation()
  const [activeTab, setActiveTab] = useState<any>(tab ? tab : 'all-properties')
  const [propertiesCount, setPropertiesCount] = useState<any>()
  const [propertiesSubCount, setSubPropertiesCount] = useState<any>()
  const [subTabListItem, setSubTabListItem] = useState<any>(subTab ? subTab : 'allUnits')

  const [searchUnit, setSearchUnit] = useState<any>(propertiesFilter?.searchUnit ?? '')
  const [searchValue, setSearchValue] = useState<any>(propertiesFilter?.searchUnit ?? '')
  const [searchResidentialUnit, setSearcResidentialhUnit] = useState<any>(propertiesFilter?.searchResidentialUnit ?? '')
  const [searchValueResidential, setSearchValueResidential] = useState<any>(propertiesFilter?.searchResidentialUnit  ?? '')
  const [searchCommercialUnit, setSearchCommercialUnit] = useState<any>(propertiesFilter?.searchCommercialUnit ?? '')
  const [searchValueCommercial, setSearchValueCommercial] = useState<any>(propertiesFilter?.searchCommercialUnit  ?? '')
  const [searchCommonAreaUnit, setSearchCommonAreaUnit] = useState<any>(propertiesFilter?.searchCommonAreaUnit ?? '')
  const [searchValueCommonArea, setSearchValueCommonArea] = useState<any>(propertiesFilter?.searchCommonAreaUnit  ?? '')
  const [searchBuilding, setSearchBuilding] = useState<any>(propertiesFilter?.searchBuilding ?? '')
  const [searchDevelopment, setSearchDevelopment] = useState<any>(propertiesFilter?.searchDevelopment ?? '')
  const [searchCommunity, setSearchCommunity] = useState<any>(propertiesFilter?.searchCommunity ?? '')
  const [allUnitsOptions, setAllUnitsOptions] = useState<any>([])
  const [allUnitDropDownStatus, setAllUnitDropdownStatus] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)

  const [allPropertiesTableData, setAllPropertiesTableData] = useState<any>([])
  const [allDevelopmentTableData, setAllDevelopmentTableData] = useState<any>([])
  const [allBuildingTableData, setAllBuildingTableData] = useState<any>([])
  const [allCommunitiesTableData, setAllCommunitiesTableData] = useState<any>([])
  const [requestTabItem] = useState<any>([])

  const navigate = useNavigate()

  const goToMiantenance = (listItem: any) => {
    if (activeTab === 'all-building') {
      navigate(`/properties/building-details/${listItem?._id}`, 
      { state: { key: 'buildings',
       buildingDetails: listItem, 
       area:listItem?.area,
         city:listItem?.city,
      //  area:listItem?.community?.length === 0 ? listItem?.building[0]?.area : listItem?.community[0]?.area ,
      //  city:listItem?.community?.length === 0 ? listItem?.building[0]?.city : listItem?.community[0]?.city 
      } })
    }
    else if (activeTab === 'communities') {
      navigate(`/properties/community-details/${listItem?._id}`, 
      { state: { 
        key: 'community', type: listItem?.type,
         communityName: listItem?.name ,
         area:listItem?.area,
         city:listItem?.city,
         
        } })
    }
    else if (activeTab === 'all-development') {
      if (listItem?.isBuilding === 'true') {
        navigate(`/properties/building-details/${listItem?._id}`, 
        { state: { key: 'buildings',
         buildingDetails: listItem ,
          buildingName: listItem?.name,
          buildingId:listItem?._id,
          area:listItem?.area,
          city:listItem?.city
        } })
      } else if (listItem?.isCommunity === 'true') {
        navigate(`/properties/community-details/${listItem?._id}`, { state: { key: 'community', communityDetails: listItem ,communityName: listItem?.name,communityId:listItem?._id,area:listItem?.area,city:listItem?.city} })
      }
    }
    else{
      navigate(`/property-profile/${listItem?._id}`,{state:{
        from:'globalProperty',
        developmentName: listItem?.community?.length === 0 ? listItem?.building[0]?.name : listItem?.community[0]?.name,
         area:listItem?.community?.length === 0 ? listItem?.building[0]?.area : listItem?.community[0]?.area ,
       city:listItem?.community?.length === 0 ? listItem?.building[0]?.city : listItem?.community[0]?.city 
      }})
    }

  }
 

  // date range
  // const setStartDateFromLocal = () ? allPropertiesUnitsStartDate : null
  // const setEndDateFromLocal = (activeTab === 'all-properties' && subTabListItem === 'allUnits') ? allPropertiesUnitsEndDate : null
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)
  const [start_date_residential, setStartResidential] = useState<any>(null)
  const [end_date_residential, setEndResidential] = useState<any>(null)
  const [start_date_commercial, setStartCommercial] = useState<any>(null)
  const [end_date_commercial, setEndCommercial] = useState<any>(null)
  const [start_date_commonArea, setStartCommonArea] = useState<any>(null)
  const [end_date_commonArea, setEndCommonArea] = useState<any>(null)


  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)
  const [startDatePayloadResidential, setStartDatePayloadResidential] = useState<any>(null)
  const [endDatePayloadResidential, setEndDatePayloadResidential] = useState<any>(null)
  const [startDatePayloadCommercial, setStartDatePayloadCommercial] = useState<any>(null)
  const [endDatePayloadCommercial, setEndDatePayloadCommercial] = useState<any>(null)
  const [startDatePayloadCommonArea, setStartDatePayloadCommonArea] = useState<any>(null)
  const [endDatePayloadCommonArea, setEndDatePayloadCommonArea] = useState<any>(null)

  const [start_date_development, setStartDate_Development] = useState<any>(null)
  const [end_date_development, setEndDate_Development] = useState<any>(null)
  const [startDateDevelopmentPayload, setStartDateDevelopmentPayload] = useState<any>(null)
  const [endDateDevelopmentPayload, setEndDateDevelopmentPayload] = useState<any>(null)

  const [start_date_building, setStartBuilding] = useState<any>(null)
  const [end_date_building, setEndBuilding] = useState<any>(null)
  const [startDateBuildingPayload, setStartDateBuildingPayload] = useState<any>(null)
  const [endDateBuildingPayload, setEndDateBuildingPayload] = useState<any>(null)
  const [isFormDirty, setIsFormDirty] = useState(false)


  function formatDate(date: any) {
    const day = ('0' + date.getDate()).slice(-2); // Get day with leading zero if needed
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Get month with leading zero if needed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function generateDates() {
    // Create start date for the year (1st January)
    const currentDate = new Date();
    const year: any = currentDate.getFullYear();
    const startDate: any = new Date(year, 0, 1);

    // Create end date for the year (31st December)
    const endDate = new Date(year, 11, 31);

    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    if (activeTab === 'all-properties' && subTabListItem === 'residentialUnits' && Object.keys(allPropertiesResidentialDate).length > 0) {
      if (Object?.values(allPropertiesResidentialDate).every(x => x !== null || x !== '')) {

        setStartResidential(moment(allPropertiesResidentialDate?.startDate));
        setEndResidential(moment(allPropertiesResidentialDate?.endDate));
        setStartDatePayloadResidential(moment(allPropertiesResidentialDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayloadResidential(moment(allPropertiesResidentialDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (activeTab === 'all-properties' && subTabListItem === 'allUnits' && Object.keys(allPropertiesAllUnitsDate).length > 0) {
      if (Object?.values(allPropertiesAllUnitsDate).every(x => x !== null || x !== '')) {

        setStartResidential(moment(allPropertiesAllUnitsDate?.startDate));
        setEndResidential(moment(allPropertiesAllUnitsDate?.endDate));
        setStartDatePayloadResidential(moment(allPropertiesAllUnitsDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayloadResidential(moment(allPropertiesAllUnitsDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (activeTab === 'all-properties' && subTabListItem === 'commercialUnits' && Object.keys(allPropertiesCommercialDate).length > 0) {
      if (Object?.values(allPropertiesCommercialDate).every(x => x !== null || x !== '')) {

        setStartResidential(moment(allPropertiesCommercialDate?.startDate));
        setEndResidential(moment(allPropertiesCommercialDate?.endDate));
        setStartDatePayloadResidential(moment(allPropertiesCommercialDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayloadResidential(moment(allPropertiesCommercialDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (activeTab === 'all-properties' && subTabListItem === 'commonAreas' && Object.keys(allPropertiesCommonAreaDate).length > 0) {
      if (Object?.values(allPropertiesCommonAreaDate).every(x => x !== null || x !== '')) {

        setStartResidential(moment(allPropertiesCommonAreaDate?.startDate));
        setEndResidential(moment(allPropertiesCommonAreaDate?.endDate));
        setStartDatePayloadResidential(moment(allPropertiesCommonAreaDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayloadResidential(moment(allPropertiesCommonAreaDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (activeTab === 'all-development' && Object.keys(allPropertiesDevelopmentDate).length > 0) {
      if (Object?.values(allPropertiesDevelopmentDate).every(x => x !== null || x !== '')) {

        setStartResidential(moment(allPropertiesDevelopmentDate?.startDate));
        setEndResidential(moment(allPropertiesDevelopmentDate?.endDate));
        setStartDatePayloadResidential(moment(allPropertiesDevelopmentDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayloadResidential(moment(allPropertiesDevelopmentDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (activeTab === 'all-building' && Object.keys(allPropertiesBuildingDate).length > 0) {
      if (Object?.values(allPropertiesBuildingDate).every(x => x !== null || x !== '')) {

        setStartResidential(moment(allPropertiesBuildingDate?.startDate));
        setEndResidential(moment(allPropertiesBuildingDate?.endDate));
        setStartDatePayloadResidential(moment(allPropertiesBuildingDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayloadResidential(moment(allPropertiesBuildingDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else if (activeTab === 'communities' && Object.keys(allPropertiesCommunitiesDate).length > 0) {
      if (Object?.values(allPropertiesCommunitiesDate).every(x => x !== null || x !== '')) {

        setStartResidential(moment(allPropertiesCommunitiesDate?.startDate));
        setEndResidential(moment(allPropertiesCommunitiesDate?.endDate));
        setStartDatePayloadResidential(moment(allPropertiesCommunitiesDate?.startDate)?.format('YYYY-MM-DD'))
        setEndDatePayloadResidential(moment(allPropertiesCommunitiesDate?.endDate)?.format('YYYY-MM-DD'))
      }
    } else {

      setStartResidential(moment(formattedStartDate, 'DD/MM/YYYY'));
      setEndResidential(moment(formattedEndDate, 'DD/MM/YYYY'));
      setStartDatePayloadResidential(moment(formattedStartDate)?.format('YYYY-MM-DD'))
      setEndDatePayloadResidential(moment(formattedEndDate)?.format('YYYY-MM-DD'))
    }
  }


  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) => {
    if (startDate == null && endDate == null) {
      // setStart(null)
      // setStartDatePayload(null)
      // setEnd(null)
      // setEndDatePayload(null)

      setStartResidential(null)
      setStartDatePayloadResidential(null)
      setEndResidential(null)
      setEndDatePayloadResidential(null)
    } else {
      setStartResidential(startDate)
      setStartDatePayloadResidential(startDate?.format('YYYY-MM-DD'))
      setEndResidential(endDate)
      setEndDatePayloadResidential(endDate?.format('YYYY-MM-DD'))


      setIsFormDirty(true)
      if (startDatePayload && endDatePayload) {
      }
    }
  }

  // filter
  const [hideContract, setHideContract] = React.useState<any>(true)
  const [hideStatus, setHideStatus] = React.useState<any>(true)
  const [hideTenant, setHideTenant] = React.useState<any>(true)
  const [hideProperty, setHideProperty] = React.useState<any>(true)
  const [hideDevelopment, setHideDevelopment] = React.useState<any>(true)
  const [hideFinancials, setHideFinancials] = React.useState<any>(true)
  const [hideRating, setHideRating] = React.useState<any>(true)
  const [hideAssignedUser, setHideAssignedUser] = React.useState<any>(true)

  const [contractNo, setContractNo] = useState<any>('')
  const [bedrooms, setBedrooms] = useState<any>([])
  const [bathrooms, setBathrooms] = useState<any>([])
  const [size, setSize] = useState<any>([])
  const [unitType, setUnitType] = useState<any>([])
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [rating, setRating] = useState<any>(0)


  const [renewalStatus, setRenewalStatus] = useState<any>([0, 2, 7])
  const [formStatus, setFormStatus] = useState<any>(['draft', 'created', 'cancelled'])
  const [tenancyStatus, setTenancyStatus] = useState<any>([])
  const [tenantSource, setTenantSource] = useState<any>([0, 1])
  const [developmentSource, setDevelopmentSource] = useState<any>(["building", "community"])
  const [propertyCategorySource, setPropertyCategorySource] = useState<any>([0, 1])
  const [leaseSource, setLeaseSource] = useState<any>([])
  const [propertyTypeSource, setPropertyTypeSource] = useState<any>([0, 1, 3, 4, 5])
  const [others, setOthers] = useState<any>([])
  const [floors, setFloor] = useState<any>([])
  const [groups, setGroup] = useState<any>([])
  const [clusters, setCluster] = useState<any>([])
  const [units, setUnits] = useState<any>([])
  const [users, setUser] = useState<any>([])

  const [financial, setFinancial] = useState<any>({
    incomeMin: null,
    incomeMax: null,
    expenseMin: null,
    expenseMax: null,
    upcomingMin: null,
    upcomingMax: null,
  })

  const [propertySize, setPropertySize] = useState<any>({ sizeFrom: null, sizeTo: null })
  const [occupancyRate, setOccupancyRate] = useState<any>({ occupancyFrom: null, occupancyTo: null })
  const [paidPercentage, setPaidPercentage] = useState<any>(null)
  const [paid, setPaid] = useState<any>(null)
  const [notPaidPercentage, setNotPaidPercentage] = useState<any>(null)
  const [notPaid, setNotPaid] = useState<any>(null)

  // const unitOptions = [
  //   { value: 'apartment', label: 'Apartment' },
  //   { value: 'pent_house', label: 'Penthouse' },
  //   { value: 'town_house', label: 'Townhouse' },
  //   { value: 'villa', label: 'Villa' },
  //   { value: 'common_area', label: 'Common Area' },
  //   { value: 'other', label: 'Other' },
  // ]

  const bathroomOptions = [
    {
      value: 1,
      label: '1 Bath',
    },
    { value: 2, label: '2 Bath' },
    { value: 3, label: '3 Bath' },
    { value: 4, label: '4 Bath' },
    { value: 5, label: '5 Bath' },
    { value: 6, label: '6 Bath' },
    { value: 7, label: '7 Bath' },
    { value: 8, label: '8 Bath' },
    { value: 9, label: '9 Bath' },
    { value: 10, label: '10 Bath' },
  ]
  const bedroomsOptions = [
    {
      value: 1,
      label: '1 BR',
    },
    { value: 2, label: '2 BR' },
    { value: 3, label: '3 BR' },
    { value: 4, label: '4 BR' },
    { value: 5, label: '5 BR' },
    { value: 6, label: '6 BR' },
    { value: 7, label: '7 BR' },
    { value: 8, label: '8 BR' },
    { value: 9, label: '9 BR' },
    { value: 10, label: '10 BR' },
  ]

  const [floorOptions, setFloorOptions] = useState<any>([])
  const [clusterOptions, setClusterOptions] = useState<any>([])
  const [groupOptions, setGroupOptions] = useState<any>([])
  const [userOptions, setUserOptions] = useState<any>([])
  const [othersOptions, setOthersOptions] = useState<any>([])
  const [unitOptions, setUnitOptions] = useState<any>([])
  const [commonAreaOptions, setCommonAreaOptions] = useState<any>([])

  const resetFilters = () => {
    setFinancial({
      incomeMin: null,
      incomeMax: null,
      expenseMin: null,
      expenseMax: null,
      upcomingMin: null,
      upcomingMax: null,
    })
    setPropertySize({ sizeFrom: null, sizeTo: null })
    setOccupancyRate({ occupancyFrom: null, occupancyTo: null })

    setBedrooms([])
    setUser([])
    setUnits([])
    setCluster([])
    setGroup([])
    setFloor([])
    setOthers([])
    setBedrooms([])
    setBathrooms([])
    setTenantSource([0, 1])
    setPropertyCategorySource([0, 1])
    setDevelopmentSource(["building", "community"])
    setLeaseSource([])
    setPropertyTypeSource([0, 1, 3, 4, 5])
    setRating(0)
  }

  // pagination
  const [page, setPage] = useState<any>(1)
  const [pageTemp, setPageTemp] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const [unitCountSummery, setUnitCountSummary] = useState<any>()
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    // setPageTemp(event.selected + 1)
    // getAllPropertiesTableData(event.selected + 1)
    if ((activeTab === 'all-properties' && subTabListItem == 'allUnits') 
      || (activeTab === 'all-properties' && subTabListItem == 'residentialUnits') 
    || (activeTab === 'all-properties' && subTabListItem == 'commonAreas') ||
     (activeTab === 'all-properties' && subTabListItem === 'commercialUnits')) {
        getAllPropertiesTableData(event.selected + 1)
    }
    else if (activeTab === 'all-development') {
      getAllDevelopmentTableData(event.selected + 1)
    }
    else if (activeTab === 'all-building') {
      getAllBuildingTableData(event.selected + 1)
    }
    else if (activeTab === 'communities') {
      getAllCommunitiesTableData(event.selected + 1)
    }
  }


  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '50%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#000;',
      backgroundColor: state.isSelected ? '#007a59;' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '1000',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
      minWidth: '55px',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      // display: 'flex',
      flexWrap: 'nowrap',
      maxWidth: '100%',
      overflowX: 'scroll',
    }),
    multiValue: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      whiteSpace: 'nowrap',
      marginRight: '4px',
      minWidth: '55px',
    }),
  }

  const [isLoading, setIsLoading] = useState<any>(false)
  const [propertiesOverAllCount, setPropertiesOverAllCount] = useState<any>()

  const countSummary = () => {
    ApiGet('corporate/development/properties/count')
      .then((res) => {
        setPropertiesOverAllCount(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const getClusterOptions = () => {
    ApiPost('corporate/development/cluster/get', {})
      .then((res) => {
        let clusters = res?.data?.data.map((clusterItem: any) => ({ value: clusterItem?._id, label: clusterItem?.name }))
        setClusterOptions(clusters)
      })
      .catch((err) => ErrorToast(err.message))
  }
  const getFloorsOptions = () => {
    ApiPost('corporate/development/floor/get', {})
      .then((res) => {
        let floors = res?.data?.data.map((floorItem: any) => ({ value: floorItem?._id, label: floorItem?.name }))
        setFloorOptions(floors)
      })
      .catch((err) => ErrorToast(err.message))
  }
  const getGroupOptions = () => {
    ApiPost('corporate/development/unit_group/get', {})
      .then((res) => {
        let groups = res?.data?.data.map((groupItem: any) => ({ value: groupItem?._id, label: groupItem?.name }))
        setGroupOptions(groups)
      })
      .catch((err) => ErrorToast(err.message))
  }
  const getUnitOptions = () => {
    ApiPost('corporate/development/unit/get', {})
      .then((res) => {
        let units = res?.data?.data.map((unitItem: any) => ({ value: unitItem?._id, label: unitItem?.unitNo }))
        setUnitOptions(units)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const getCommonTypes = async () => {
    await ApiGet(`test/common/type/`)
        .then((res:any) => {
            const commonAreas = res?.data?.data?.databaseTypes?.commonAreasList?.filter((commonAreaItem: any, i: any) =>  commonAreaItem !== 'Other').map((item:any) => ( { value: item, label: item }))
            setCommonAreaOptions(commonAreas)
        })
        .catch((err) => {
            ErrorToast(err.message)
            if (err?.message === 'Your account has been suspended!') {
                window.location.pathname = '/auth'
                localStorage.clear()
            }
        })
}

  const unitCountSummary = async () => {
    let body :any = {
      isAllPropertise: (activeTab === 'all-properties' || activeTab === 'all-development') ? true : false,
      isForBuildingDevelopment: activeTab === 'all-building' ? true : false,
      isForCommunityDevelopment: activeTab === 'communities' ? true : false,
      unit: {
        propertyType:
        activeTab === 'all-properties' ? (subTabListItem === 'allUnits'
            ? propertyCategorySource
            : subTabListItem === 'residentialUnits'
            ? [0]
            : subTabListItem === 'commercialUnits'
            ? [1]
            : subTabListItem === 'commonAreas'
            ? [0, 1]
            : []) : [],
        unitType:
        activeTab === 'all-properties' ?  (subTabListItem === 'allUnits'
            ? propertyTypeSource
            : subTabListItem === 'residentialUnits'
            ? [0, 1, 3, 4, 5]
            : subTabListItem === 'commercialUnits'
            ? [0, 1, 3, 4, 5]
            : subTabListItem == 'commonAreas'
            ? [2]
            : []) : [],
            
      },
      startDate: startDatePayloadResidential ? startDatePayloadResidential : null,
      endDate: endDatePayloadResidential ? endDatePayloadResidential : null,
      // searchBuildingName:activeTab === 'all-building' ? searchBuilding :'',
      // searchDevelopmentName: activeTab === 'all-development' ? searchDevelopment : '',
      // searchCommunityName: activeTab === 'communities' ? searchCommunity : '',
    
    }
    if(activeTab === 'all-properties'){
      body.unit.searchUnitNo = subTabListItem === 'allUnits' ? searchUnit : subTabListItem === 'residentialUnits' ? searchResidentialUnit : subTabListItem === 'commercialUnits' ? searchCommercialUnit : subTabListItem == 'commonAreas' ? searchCommonAreaUnit : ''
    }else if(activeTab === 'all-building' ){
      body.searchBuildingName = activeTab === 'all-building' ? searchBuilding :''
    }else if(activeTab === 'all-development'){
      body.searchDevelopmentName = activeTab === 'all-development' ? searchDevelopment : ''
      // body.isAllPropertise =  false
      // body.isForBuildingDevelopment =  true
      // body.isForCommunityDevelopment =  true
    }else if(activeTab === 'communities'){
      body.searchCommunityName = activeTab === 'communities' ? searchCommunity : ''

    }
    await ApiPost('corporate/development/properties/unit_count', body).then((res) => {
      setUnitCountSummary(res?.data?.data)
    })
      .catch((err) => ErrorToast(err.message))
  }

  // get Maintenance Request Table Data
  const getAllPropertiesTableData = async (pg = 1) => {
    setIsLoading(true)
    setAllPropertiesTableData([])

    let selectedFloors: any = []
    floors?.map((v: any, ind: any) => {
      selectedFloors[ind] = v?.value
    })
    let selectedClusters : any = []
    clusters?.map((v: any, ind: any) => {
      selectedClusters[ind] = v?.value
    })
    let selectedGroups : any = []
    groups?.map((v: any, ind: any) => {
      selectedGroups[ind] = v?.value
    })
    let selectedBedrooms : any = []
    bedrooms?.map((v: any, ind: any) => {
      selectedBedrooms[ind] = v?.value
    })
    let selectedBathrooms : any = []
    bathrooms?.map((v: any, ind: any) => {
      selectedBathrooms[ind] = v?.value
    })
    let selectedUsers : any = []
    users?.map((v: any, ind: any) => {
      selectedUsers[ind] = v?.value
    })
    let selectedUnits: any = []
    units?.map((v: any, ind: any) => {
      selectedUnits[ind] = v?.value
    })

    let body : any = {
      page: pg,
      limit: limit,
      startDate: startDatePayloadResidential ? startDatePayloadResidential : null,
      // startDate: subTabListItem === 'allUnits' ? startDatePayload : subTabListItem === 'residentialUnits' ? startDatePayloadResidential : subTabListItem === 'commercialUnits' ? startDatePayloadCommercial : subTabListItem === 'commonAreas' ? startDatePayloadCommonArea : null,
      endDate: endDatePayloadResidential ? endDatePayloadResidential : null,
      // endDate: subTabListItem === 'allUnits' ? endDatePayload : subTabListItem === 'residentialUnits' ? endDatePayloadResidential : subTabListItem === 'commercialUnits' ? endDatePayloadCommercial : subTabListItem === 'commonAreas' ? endDatePayloadCommonArea : null,
      searchUnitNo: subTabListItem === 'allUnits' ? searchUnit : subTabListItem === 'residentialUnits' ? searchResidentialUnit : subTabListItem === 'commercialUnits' ? searchCommercialUnit : subTabListItem == 'commonAreas' ? searchCommonAreaUnit : '',
      propertyTypes: subTabListItem === 'allUnits' ? propertyCategorySource : subTabListItem === 'residentialUnits' ? [0] : subTabListItem === 'commercialUnits' ? [1] : [0, 1],
      unitTypes: subTabListItem === 'allUnits' ? propertyTypeSource : subTabListItem === 'residentialUnits' ? [0, 1, 3, 4, 5] : subTabListItem === 'commercialUnits' ? [0, 1, 3, 4, 5] : subTabListItem == 'commonAreas' ? [2] : [],
      buildingIds: [],
      communityIds: [],
      floorIds: selectedFloors,
      clusterIds: selectedClusters,
      unitGroupIds: selectedGroups,
      bedrooms: selectedBedrooms,
      bathrooms:selectedBathrooms ,
      watchTypes: developmentSource,
      occupancies: tenantSource,
      tenancyStatus: leaseSource,
      assignUserIds:selectedUsers,
      sizeFilter: {
        start: propertySize.sizeFrom,
        end: propertySize.sizeTo
      },
      rating: {
        start: 0,
        end: rating
      },
      financial: {
        income: {
          start: financial.incomeMin,
          end: financial.incomeMax
        },
        expense: {
          start: financial.expenseMin,
          end: financial.expenseMax
        },
        upcoming: {
          start: financial.upcomingMin,
          end: financial.upcomingMax
        }
      }
    }

    await ApiPost('corporate/unit/get_properties', body)
      .then((res) => {
        setAllPropertiesTableData(res?.data?.data?.unit_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        setTimeout(() => {
            setIsLoading(false)
        }, 1000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }
  const getAllDevelopmentTableData = async (pg = 1) => {
    setIsLoading(true)
    setAllDevelopmentTableData([])
    let body = {
      page: pg,
      limit: limit,
      startDate: startDatePayloadResidential ? startDatePayloadResidential : null,
      endDate: endDatePayloadResidential ? endDatePayloadResidential : null,
      search: searchDevelopment,
    }

    await ApiPost('corporate/development/property/get_all', body)
      .then((res) => {
        setAllDevelopmentTableData(res?.data?.data?.development_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const getAllBuildingTableData = async (pg = 1) => {
    setIsLoading(true)
    setAllBuildingTableData([])
    let body = {
      page: pg,
      limit: limit,
      startDate: startDatePayloadResidential ? startDatePayloadResidential : null,
      endDate: endDatePayloadResidential ? endDatePayloadResidential : null,
      search: searchBuilding,
      buildingIds: [],
      communityIds: []
    }

    await ApiPost('corporate/building/get_properties', body)
      .then((res) => {
        setAllBuildingTableData(res?.data?.data?.building_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const getAllCommunitiesTableData = async (pg = 1) => {
    setIsLoading(true)
    setAllCommunitiesTableData([])

    let body = {
      page: pg,
      limit: limit,
      startDate: startDatePayloadResidential ? startDatePayloadResidential : null,
      endDate: endDatePayloadResidential ? endDatePayloadResidential : null,
      search: searchCommunity,
      communityIds: [],
    }

    await ApiPost('corporate/communities/get_properties', body)
      .then((res) => {
        setAllCommunitiesTableData(res?.data?.data?.community_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    unitCountSummary()
  },[
    startDatePayloadResidential,
    endDatePayloadResidential,
    activeTab, 
    subTabListItem,
    searchDevelopment,
    searchBuilding,
    searchCommunity,
    searchUnit,
    searchCommercialUnit,
    searchResidentialUnit,
    searchCommonAreaUnit,
    propertyCategorySource,
    propertyTypeSource

  ])

  useEffect(() => {
    countSummary()
    generateDates()
    setPage(1)
    // setPageTemp(1)
  }, [activeTab, subTabListItem])

  useEffect(() => {
    getClusterOptions()
    getFloorsOptions()
    getGroupOptions()
    getUnitOptions()
    getCommonTypes()
  }, [])

useEffect(() => {
  const handler = setTimeout(() => {
    if(activeTab === 'all-properties' && subTabListItem == 'allUnits') {
      setSearchUnit(searchValue);
    }
    if(activeTab === 'all-properties' && subTabListItem == 'residentialUnits'){
      setSearcResidentialhUnit(searchValueResidential)
    }
    if(activeTab === 'all-properties' && subTabListItem === 'commercialUnits'){
      setSearchCommercialUnit(searchValueCommercial)
    }
    if(activeTab === 'all-properties' && subTabListItem == 'commonAreas'){
      setSearchCommonAreaUnit(searchValueCommonArea)
    }
  }, 500); // delay time in milliseconds

  return () => {
    clearTimeout(handler);
  };
}, [searchValue,searchValueResidential,searchValueCommercial,searchValueCommonArea]);

  useEffect(() => {
    if ((activeTab === 'all-properties' && subTabListItem == 'allUnits') 
      || (activeTab === 'all-properties' && subTabListItem == 'residentialUnits') 
    || (activeTab === 'all-properties' && subTabListItem == 'commonAreas') ||
     (activeTab === 'all-properties' && subTabListItem === 'commercialUnits')) {
        setPage(1)
        getAllPropertiesTableData(1)

    }
    else if (activeTab === 'all-development') {
      setPage(1)
      getAllDevelopmentTableData(1)
    }
    else if (activeTab === 'all-building') {
      setPage(1)
      getAllBuildingTableData(1)
    }
    else if (activeTab === 'communities') {
      setPage(1)
      getAllCommunitiesTableData(1)
    }
  }, [
    searchUnit,
    startDatePayload,
    endDatePayload,
    start_date_residential,
    end_date_residential,
    startDateBuildingPayload,
    endDateBuildingPayload,
    startDateDevelopmentPayload,
    endDateDevelopmentPayload,
    searchDevelopment,
    activeTab,
    searchBuilding,
    subTabListItem,
    floors,
    clusters,
    units,
    others,
    groups,
    bedrooms,
    bathrooms,
    developmentSource,
    tenantSource,
    leaseSource,
    propertySize,
    propertyCategorySource,
    propertyTypeSource,
    financial,
    rating,
    users,
    searchCommercialUnit,
    searchResidentialUnit,
    searchCommonAreaUnit,
    searchCommunity
  ])

  useEffect(() => {
      const filters = {
        searchUnit: searchUnit,
        searchResidentialUnit: searchResidentialUnit,
        searchCommercialUnit: searchCommercialUnit,
        searchCommonAreaUnit: searchCommonAreaUnit,
        searchDevelopment: searchDevelopment,
        searchCommunity: searchCommunity,
        searchBuilding: searchBuilding,
      }

      if (activeTab === 'all-properties' && subTabListItem === 'residentialUnits' && start_date_residential !== null && end_date_residential !== null) {
        const dateRange = {
          startDate: start_date_residential,
          endDate: end_date_residential,
        }
        localStorage.setItem('allPropertiesResidentialDateFilter', JSON.stringify(dateRange))
      }
      if (activeTab === 'all-properties' && subTabListItem === 'allUnits' && start_date_residential !== null && end_date_residential !== null) {
        const dateRange = {
          startDate: start_date_residential,
          endDate: end_date_residential,
        }
        localStorage.setItem('allPropertiesAllUnitsDateFilter', JSON.stringify(dateRange))
      }
      if (activeTab === 'all-properties' && subTabListItem === 'commercialUnits' && start_date_residential !== null && end_date_residential !== null) {
        const dateRange = {
          startDate: start_date_residential,
          endDate: end_date_residential,
        }
        localStorage.setItem('allPropertiesCommercialDateFilter', JSON.stringify(dateRange))
      }
      if (activeTab === 'all-properties' && subTabListItem === 'commonAreas' && start_date_residential !== null && end_date_residential !== null) {
        const dateRange = {
          startDate: start_date_residential,
          endDate: end_date_residential,
        }
        localStorage.setItem('allPropertiesCommonAreaDateFilter', JSON.stringify(dateRange))
      }
      if (activeTab === 'all-development' && start_date_residential !== null && end_date_residential !== null) {
        const dateRange = {
          startDate: start_date_residential,
          endDate: end_date_residential,
        }
        localStorage.setItem('allPropertiesDevelopmentDateFilter', JSON.stringify(dateRange))
      }
      if (activeTab === 'all-building' && start_date_residential !== null && end_date_residential !== null) {
        const dateRange = {
          startDate: start_date_residential,
          endDate: end_date_residential,
        }
        localStorage.setItem('allPropertiesBuildingDateFilter', JSON.stringify(dateRange))
      }
      if (activeTab === 'communities' && start_date_residential !== null && end_date_residential !== null) {
        const dateRange = {
          startDate: start_date_residential,
          endDate: end_date_residential,
        }
        localStorage.setItem('allPropertiesCommunitiesDateFilter', JSON.stringify(dateRange))
      }

      localStorage.setItem('allPropertiesFilter', JSON.stringify(filters))
      localStorage.setItem('allPropertiesActiveTab', JSON.stringify(activeTab))
      localStorage.setItem('allPropertiesSubTabListItem', JSON.stringify(subTabListItem))
  }, [
    activeTab,
    subTabListItem,
    searchCommunity,
    searchBuilding,
    searchDevelopment,
    searchCommercialUnit,
    searchCommonAreaUnit,
    searchResidentialUnit,
    searchUnit,
    start_date,
    end_date,
    start_date_residential,
    end_date_residential
  ])


  const goToEdit = (listItem:any) => {
    navigate(`/properties/edit-property/${listItem._id}`,{
      state:{
        developmentName: listItem?.community?.length === 0 ? listItem?.building[0]?.name : listItem?.community[0]?.name,
         area:listItem?.community?.length === 0 ? listItem?.building[0]?.area : listItem?.community[0]?.area ,
       city:listItem?.community?.length === 0 ? listItem?.building[0]?.city : listItem?.community[0]?.city,
       isCommonArea:listItem?.unitType == 2 ? true : false
      }
    })
  }

  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected.length > 0 ? `${selected.length} Selected` : 'Select'
  }

  

  return (
    <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
      <div className='row'>
        <div className='col-lg-10'>
          <div className="row">
            <div className='col-12 mb-7'>
              <h1 className='page-heading  m-0'>
                <b>Properties</b>
              </h1>
            </div>

            {/* tabs start */}
            <div className='col-lg-12 d-flex' style={{ gap: '20px' }}>
              {/* All Properties tab */}
              <div className=''>
                <div
                  className={
                    activeTab == 'all-properties'
                      ? 'tenancy-active-card d-flex align-items-center'
                      : 'tenancy-green-card d-flex align-items-center'
                  }
                  onClick={() => {
                    setActiveTab('all-properties')
                    // setPage(1)

                  }}
                >
                  <div
                    className=''
                    style={{
                      fontWeight: '700',
                      width: 'fit-content',
                    }}
                  >
                    <img
                      src={allProperties}
                      height={22}
                      width={22}
                      className='mx-3'
                    />
                  </div>
                  <div>
                    <h2 className='mb-0 text-white'>
                      {propertiesOverAllCount?.unitCount
                        ? propertiesOverAllCount?.unitCount?.toLocaleString('en-US', {})
                        : 0}
                    </h2>
                    <h5 className='mb-0 text-white'>All Properties</h5>
                  </div>
                </div>
                {activeTab == 'all-properties' && <div className='active-filter'></div>}
              </div>
              {/* All Devlopment Tab */}
              <div className=''>
                <div
                  className={
                    activeTab == 'all-development'
                      ? 'tenancy-active-card d-flex align-items-center'
                      : 'tenancy-green-card d-flex align-items-center'
                  }
                  onClick={() => {
                    setActiveTab('all-development')
                    // setPage(1)

                  }}
                >
                  <div
                    className=''
                    style={{
                      fontWeight: '700',
                      width: 'fit-content',
                    }}
                  >
                    <img
                      src={allDevelopment}
                      height={21}
                      width={21}
                      style={{
                        marginRight: '7px',
                      }}
                      className='mx-3'
                    />
                  </div>
                  <div>
                    <h2 className='mb-0 text-white'>
                        {
                          (propertiesOverAllCount?.buildingDevelopmentCount ?? 0) + (propertiesOverAllCount?.communityDevelopmentCount ?? 0)
                        }
                    </h2>

                    <h5 className='mb-0 text-white'>All Developments</h5>
                  </div>
                </div>
                {activeTab == 'all-development' && <div className='active-filter'></div>}
              </div>
              {/* All Buildings Tab */}
              <div className=''>
                <div
                  className={
                    activeTab == 'all-building'
                      ? 'tenancy-active-card d-flex align-items-center'
                      : 'tenancy-green-card d-flex align-items-center'
                  }
                  onClick={() => {
                    setActiveTab('all-building')
                    // setPage(1)

                  }}
                >
                  <div
                    className=''
                    style={{
                      fontWeight: '700',
                      width: 'fit-content',
                    }}
                  >
                    <img
                      src={allBuilding}
                      height={26}
                      width={26}
                      style={{
                        marginRight: '7px',
                      }}
                      className='mx-3'
                    />
                  </div>
                  <div>
                    <h2 className='mb-0 text-white'>
                      {propertiesOverAllCount?.buildingCount
                        ? propertiesOverAllCount?.buildingCount?.toLocaleString('en-US', {})
                        : 0}
                    </h2>

                    <h5 className='mb-0 text-white'>Buildings</h5>
                  </div>
                </div>
                {activeTab == 'all-building' && <div className='active-filter'></div>}
              </div>
              {/* All Communities Tab */}
              <div className=''>
                <div
                  className={
                    activeTab == 'communities'
                      ? 'tenancy-active-card d-flex align-items-center '
                      : 'tenancy-green-card d-flex align-items-center'
                  }
                  onClick={() => {
                    // setPage(1)
                    setActiveTab('communities')
                  }}
                >
                  <div
                    className=''
                    style={{
                      fontWeight: '700',
                      width: 'fit-content',
                    }}
                  >
                    <img
                      src={allCommunities}
                      height={22}
                      width={22}
                      style={{
                        marginRight: '7px',
                      }}
                      className='mx-3'
                    />
                  </div>
                  <div>
                    <h2 className='mb-0 text-white'>
                      {propertiesOverAllCount?.communityDevelopmentCount
                        ? propertiesOverAllCount?.communityDevelopmentCount?.toLocaleString('en-US', {})
                        : 0}
                    </h2>

                    <h5 className='mb-0 text-white'>Communities</h5>
                  </div>
                </div>
                {activeTab == 'communities' && <div className='active-filter'></div>}
              </div>
            </div>
            {/* tabs end */}
          </div>

        </div>
        <div className='col-lg-2 d-flex justify-content-end' style={{ columnGap: '10px' }}>
         <div
            className='d-flex flex-column justify-content-center align-items-center'
            onClick={() => {
              if(activeTab === 'all-properties' || activeTab === 'all-development'){
                navigate('/properties/add-development',{state:{from:'global'}})
              }else if(activeTab === 'all-building'){
                navigate('/properties/add-development',{state:{isBuilding:true,from:'global'}})
              }else if(activeTab === 'communities'){
                navigate('/properties/add-development', {state:{isCommunity:true,from:'global'}})
              }
            }}
          >
            <div className='bulkBtn'>
              <img src={(activeTab === 'all-properties' || activeTab === 'all-development') ? allDevelopment : activeTab === 'all-building' ? allBuildingWhite : activeTab === 'communities' ? communityWhite : ''} height={30} width={30} />
            </div>
            <span className='mt-1 white-dark-theme-color'>Add</span>
            <span className='white-dark-theme-color'>{`${(activeTab === 'all-properties' || activeTab === 'all-development') ? 'Development' : activeTab === 'all-building' ? 'Building' : activeTab === 'communities' ? 'Community' : ''}`}</span>
          </div>

          <div
            className='d-flex flex-column justify-content-center align-items-center'
            onClick={() => {
              // navigate('/create-announcement/1')
            }}
          >
            <div className='bulkBtn'>
              <img src={bulkImportWhite} height={30} width={30} />
            </div>
            <span className='mt-1 white-dark-theme-color'>Bulk</span>
            <span className='white-dark-theme-color'>Import</span>
          </div>

        </div>
      </div>



      <div
        className='d-flex align-items-center mt-3 pb-5 mb-5  mx-3'
        style={{ borderBottom: '0.1rem solid #bec3cb' }}
      ></div>

      {/* sub tabs start */}
      {(activeTab === 'all-properties') &&
        <div className=' ps-0 row mt-2'>
          <div className='col-10'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz'>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'allUnits' && 'active'
                    }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('allUnits')
                    // localStorage.setItem('vendorActiveTab', 'info')
                  }}
                >
                  All Units
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'residentialUnits' && 'active'
                    }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('residentialUnits')
                    // localStorage.setItem('vendorActiveTab', 'requests')
                  }}
                >
                  Residential Units
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'commercialUnits' && 'active'
                    }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('commercialUnits')
                    // localStorage.setItem('vendorActiveTab', 'requests')
                  }}
                >
                  Commercial Units
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${subTabListItem === 'commonAreas' && 'active'
                    }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setSubTabListItem('commonAreas')
                    // localStorage.setItem('vendorActiveTab', 'requests')
                  }}
                >
                  Common Areas
                </a>
              </li>
            </ul>
          </div>
          <div className="col-2 d-flex align-items-center justify-content-end">

            <button
              type='button'
              className='btn btn-sm fw-bold px-2 green-submit-btn'
              onClick={() => {
                navigate('/properties/add-property', { state: { key: 'global', fromBuilding: false, fromGlobal: true } })

              }}
            >
              <img src={addWhiteIcon} height={18} width={18} className='me-2' /> Add Property
            </button>
          </div>
        </div>}
      {/* sub tabs end */}

      {/* ingights start and filter */}
      <div className='row mt-7'>
        <div className='col-12'>
          <div className='d-flex flex-lg-wrap flex-xl-nowrap' style={{ gap: '20px' }}>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500 '>Units</h4>
              <div className='d-flex justify-content-start align-items-center'>
                <img
                  src={unitsBlue}
                  height={50}
                  width={50}
                  className='me-3'
                />
                <h4 className='fw-bold'>{(unitCountSummery?.unitCount ? unitCountSummery?.unitCount : 0)}</h4>
              </div>

            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500'>Tenants</h4>
              <div className='d-flex justify-content-start align-items-center'>
                <img
                  src={tenantBlue}
                  height={50}
                  width={50}
                  className='me-3'
                />
                <h4 className='fw-bold'>{unitCountSummery?.tenantCount ?? 0}</h4>
              </div>

            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500 '>Leases</h4>
              <div className='d-flex justify-content-start align-items-center'>
                <img
                  src={leasesBlue}
                  height={50}
                  width={50}
                  className='me-3'
                />
                <h4 className='fw-bold'>{unitCountSummery?.tenancyCount ?? 0}</h4>
              </div>
            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500'>Occupancy</h4>
              <div className='d-flex justify-content-start align-items-top'>
                <img
                  src={occupancyBlue}
                  height={45}
                  width={45}
                  className='me-3'
                />
                <h4 className='fw-bold ms-2' style={{ marginTop: '15px' }}>{unitCountSummery?.occupancyRate >= 0 ? `${Math.round(unitCountSummery?.occupancyRate)}%` : 0}</h4>
              </div>
            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500'>Income</h4>
              <div style={{marginTop:'18px'}}>
                <h4 className='fw-bold' style={{ color: '#00b8a9' }}>AED</h4>
                <h4 className='fw-bold'>
                {`${parseFloat(unitCountSummery?.incomePaymentCount ?? 0)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`}
                </h4>
                
              </div>

            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px' }}>
              <h4 className='text-gray-500'>Expense</h4>
              <div style={{marginTop:'18px'}}>
                <h4 className='fw-bold' style={{ color: '#e46464 ' }}>AED</h4>
                <h4 className='fw-bold'>
                  {/* {unitCountSummery?.expensePaymentCount ?? 0} */}
                  {`${parseFloat(unitCountSummery?.expensePaymentCount ?? 0)?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}`}

                  </h4>
              </div>

            </div>
            <div className='customCard py-4 px-7 shadow' style={{ width: '12rem', height: '90px'}}>
              <h4 className='text-gray-500'>Rating</h4>
              <div className='d-flex justify-content-between align-items-end'>
                <img
                  src={yellowStar}
                  height={35}
                  width={35}
                  className='me-3 mt-3'
                />
                <h4 className='fw-bold'>{unitCountSummery?.ratingCount ?? 0}</h4>
              </div>

            </div>
          </div>
        </div>
        <div className='row d-flex justify-content-between align-items-center py-5 gy-3 mx-0 mt-5' style={{ gap: '0px' }}>
          <div className={`d-flex align-items-center  px-0 col-10`} >
            <div className='xyz me-5 pe-3'>
              <label className='head-text' style={{ fontWeight: '600' }}>
                {' '}
                Search{' '}
              </label>
            </div>

            {(activeTab === 'all-properties' && subTabListItem === 'allUnits') && <div className='xyz me-4'>

              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Unit'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Unit' }}
                  value={searchValue}
                  onChange={(e: any) => {
                    setSearchValue(e.target.value)
                    // setSearchUnit(e.target.value)
                  }}
                />
                <IconButton style={searchIconStyle} aria-label='Unit'>
                  <SearchIcon />
                </IconButton>
              </Paper>

            </div>}
            {(activeTab === 'all-properties' && subTabListItem === 'residentialUnits') && <div className='xyz me-4'>

              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Unit'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Unit' }}
                  value={searchValueResidential}
                  onChange={(e: any) => setSearchValueResidential(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='Unit'>
                  <SearchIcon />
                </IconButton>
              </Paper>

            </div>}
            {(activeTab === 'all-properties' && subTabListItem === 'commercialUnits') && <div className='xyz me-4'>

              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Unit'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Unit' }}
                  value={searchValueCommercial}
                  onChange={(e: any) => setSearchValueCommercial(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='Unit'>
                  <SearchIcon />
                </IconButton>
              </Paper>

            </div>}
            {(activeTab === 'all-properties' && subTabListItem === 'commonAreas') && <div className='xyz me-4'>

              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Unit'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Unit' }}
                  value={searchValueCommonArea}
                  onChange={(e: any) => setSearchValueCommonArea(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='Unit'>
                  <SearchIcon />
                </IconButton>
              </Paper>

            </div>}
            {(activeTab === 'communities') && <div className='xyz me-4'>

              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Community'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Community' }}
                  value={searchCommunity}
                  onChange={(e: any) => setSearchCommunity(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='Community'>
                  <SearchIcon />
                </IconButton>
              </Paper>

            </div>}
            {(activeTab === 'all-building') && <div className='xyz me-4'>

              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Building'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Building' }}
                  value={searchBuilding}
                  onChange={(e: any) => setSearchBuilding(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='Building'>
                  <SearchIcon />
                </IconButton>
              </Paper>

            </div>}
            {(activeTab === 'all-development') && <div className='xyz me-4'>

              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Development'
                  style={inputBaseStyle}
                  inputProps={{ 'aria-label': 'Development' }}
                  value={searchDevelopment}
                  onChange={(e: any) => setSearchDevelopment(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='Development'>
                  <SearchIcon />
                </IconButton>
              </Paper>

            </div>}
            <div className='test property me-4'>
              <CustomDateRangePicker
                startDate={start_date_residential}
                endDate={end_date_residential}
                dateStatuses={[]}
                onDatesChange={handleDatesChange}
                autoSave={() => { }}
                from={'create'}
                disabled={false}
              />
              
            </div>
            {/* filters icon */}
            {(activeTab === 'all-properties' && subTabListItem === 'allUnits') && <div className='mb-2 col-md-1 pe-5 xyz'>
              <div
                className='ok-div d-flex align-items-center justify-content-center cursor-pointer'
                onClick={() => setShowFilter(true)}
              >
                <svg
                  style={{
                    height: '23px',
                    width: '23px',
                    marginLeft: '3px',
                  }}
                  viewBox='0 0 1024 1024'
                  xmlns='http://www.w3.org/2000/svg'
                  className='me-1'
                >
                  <path
                    fill='#ffff'
                    d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                  />
                </svg>
              </div>
            </div>}
          </div>

        </div>
      </div>
      {/* ingights end and fitlers*/}

      {(subTabListItem == 'allUnits' && activeTab === 'all-properties') && (
        <PropertiesList goToEdit={goToEdit} isFloorCol={true} isEditBtn={true} listData={allPropertiesTableData} goToMiantenance={goToMiantenance} handlePageClick={handlePageClick} page={page} pageLimit={pageLimit} />
      )}
      {(subTabListItem == 'residentialUnits' && activeTab === 'all-properties') && (
        <PropertiesList goToEdit={goToEdit} isFloorCol={true} isEditBtn={true} listData={allPropertiesTableData} goToMiantenance={goToMiantenance} handlePageClick={handlePageClick} page={page} pageLimit={pageLimit} />
      )}
      {(subTabListItem === 'commercialUnits' && activeTab === 'all-properties') && (
        <PropertiesList goToEdit={goToEdit} isFloorCol={true} isEditBtn={true} listData={allPropertiesTableData} goToMiantenance={goToMiantenance} handlePageClick={handlePageClick} page={page} pageLimit={pageLimit} />
      )}
      {(subTabListItem === 'commonAreas' && activeTab === 'all-properties') && (
        <PropertiesList goToEdit={goToEdit} isFloorCol={true} isEditBtn={true} listData={allPropertiesTableData} goToMiantenance={goToMiantenance} handlePageClick={handlePageClick} page={page} pageLimit={pageLimit} />
      )}
      {(activeTab === 'all-development') && (
        <DevelopmentList listData={allDevelopmentTableData} goToMiantenance={goToMiantenance} handlePageClick={handlePageClick} page={page} pageLimit={pageLimit} />
      )}
      {(activeTab === 'all-building') && (
        <BuildingList listData={allBuildingTableData} goToMiantenance={goToMiantenance} handlePageClick={handlePageClick} page={page} pageLimit={pageLimit} />
      )}
      {(activeTab === 'communities') && (
        <CommunityList listData={allCommunitiesTableData} goToMiantenance={goToMiantenance} handlePageClick={handlePageClick} page={page} pageLimit={pageLimit} />
      )}


      {/* Filters */}
      <div
        className={showFilter == true ? 'filter-payments test ' : 'd-none'}
        style={{
          width: '420px',
          backgroundColor: 'white',
          zIndex: '999',
          boxShadow: '0px 0px 20px 0px rgba(18, 32, 78, 0.2)',
          paddingBottom: '50px',
        }}
      >
        <div
          className='card m-0 p-0 '
          style={{
            overflow: 'hidden',
            borderRadius: '0px',
            boxShadow: 'none',
          }}
        >
          {/* header */}
          <h3
            className='m-0 d-flex align-items-center msg_title'
            style={{
              justifyContent: 'space-between',
              borderRadius: '0px',
              zIndex: '1',
              opacity: '0.85',
            }}
          >
            Filter
            <span className='mx-0' style={{ cursor: 'pointer', marginTop: '-20px' }}>
              <img src={closes} alt='' onClick={() => setShowFilter(false)} />
            </span>
          </h3>

          <h3
            className='m-0 d-flex align-items-center '
            style={{
              justifyContent: 'flex-end',
              borderRadius: '0px',
              transform: 'translate(0px, -50px)',
              color: 'white',
              backgroundColor: '#146C6A',
              paddingBottom: '5px',
              background:
                'linear-gradient(41deg, rgba(20,108,106,1) 15%, rgba(20,108,106,1) 15%, rgba(20,108,106,1) 0%)',
            }}
          >
            <span className='mx-1' style={{ cursor: 'pointer' }}>
              <svg
                style={{
                  height: '40px',
                  width: '38px',
                  marginRight: '7px',
                  marginLeft: '-10px',
                  marginTop: '5px',
                  transform: 'translate(-30px, 0px)',
                }}
                viewBox='0 0 1024 1024'
                xmlns='http://www.w3.org/2000/svg'
                className='me-1'
              >
                <path
                  fill='#ffff'
                  d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                />
              </svg>
            </span>
          </h3>
        </div>

        <div
          style={{
            height: '100%',
            overflow: 'scroll',
            transform: 'translate(0px, -50px)',
            overflowX: 'hidden',
          }}
        >

          {/* Property */}
          <div className='min-w-200px ps-3  pt-1  mt-1 card' style={{ borderRadius: '0px' }}>
            <div className='ms-auto mt-3 me-5'>
              <div
                className='ok-div d-flex align-items-center justify-content-center cursor-pointer'
                onClick={() => resetFilters()}
              >
                <img src={sync} height={22} width={24} className='cursor-pointer' />
              </div>
            </div>
            <label className='mb-2 ms-2 mt-3' style={{ fontWeight: '700', fontSize: '14px' }}>
              {' '}
              Property
            </label>
            {!hideProperty && (
              <>
                <div className='d-flex align-items-baseline ms-2 '>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{ fontWeight: '600' }}
                  >
                    Category
                  </label>
                </div>
                <div className='row mb-2 mt-2' style={{ paddingLeft: '27px' }}>
                  <div className='col-3 d-flex align-items-center xyz'>
                    <input
                      className='form-check-input me-3 cursor-pointer'
                      type='radio'
                      checked={propertyCategorySource.length == 2 || propertyCategorySource.length == 0 ? true : false}
                      onChange={(e: any) => {
                        if (e.target.checked == true) {
                          let values = [0, 1]
                          setPropertyCategorySource(values)
                        } else {
                          setPropertyCategorySource([])
                        }
                      }}
                    />{' '}
                    All
                  </div>
                </div>

                <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                  <div className='col-4 d-flex align-items-center test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (propertyCategorySource[0] == 0 || propertyCategorySource[1] == 0) && propertyCategorySource.length != 2
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            if (propertyCategorySource.length == 2) {
                              // setTenantSource([])
                              let values = [0]
                              setPropertyCategorySource(values)
                            } else {
                              let values = [...propertyCategorySource]
                              values[values.length] = 0
                              setPropertyCategorySource(values)
                            }
                          } else {
                            let values = [...propertyCategorySource]
                            if (values[0] == 0) values.splice(0, 1)
                            else values.splice(1, 1)
                            setPropertyCategorySource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                        {' '}
                        Residential
                      </p>
                    </div>
                  </div>

                  <div className='col-4 d-flex align-items-center'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (propertyCategorySource[1] == 1 || propertyCategorySource[0] == 1) && propertyCategorySource.length != 2
                            ? true
                            : false
                        }
                        onClick={(e: any) => {
                          if (e.target.checked) {
                            if (propertyCategorySource.length == 2) {
                              // setTenantSource([])
                              let values = [1]
                              setPropertyCategorySource(values)
                            } else {
                              let values = [...propertyCategorySource]
                              values[values.length] = 1
                              setPropertyCategorySource(values)
                            }
                          } else {
                            let values = [...propertyCategorySource]
                            if (values[0] == 1) values.splice(0, 1)
                            else values.splice(1, 1)
                            setPropertyCategorySource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                        {' '}
                        Commercial
                      </p>
                    </div>
                  </div>
                </div>

                <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-2 mx-4'></div>
                <div className='d-flex align-items-baseline ms-2 mt-3'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{ fontWeight: '600' }}
                  >
                    Type
                  </label>
                </div>
                <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                  <div className='col-3 d-flex align-items-center xyz'>
                    <input
                      className='form-check-input me-3 cursor-pointer'
                      type='radio'
                      checked={propertyTypeSource.length == 5 || propertyTypeSource.length == 0 ? true : false}
                      onChange={(e: any) => {
                        if (e.target.checked == true) {
                          let values = [0, 1, 3, 4, 5]
                          setPropertyTypeSource(values)
                        } else {
                          setPropertyTypeSource([])
                        }
                      }}
                    />{' '}
                    All
                  </div>
                </div>
                <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                  <div className='col-3 d-flex align-items-center test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (propertyTypeSource[0] == 0 ||
                            propertyTypeSource[1] == 0 ||
                            propertyTypeSource[2] == 0 ||
                            propertyTypeSource[3] == 0 ||
                            propertyTypeSource[4] == 0) &&
                            propertyTypeSource.length != 5
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            if (propertyTypeSource.length == 5) {
                              let values = [0]
                              setPropertyTypeSource(values)
                            } else {
                              let values = [...propertyTypeSource]
                              values[values.length] = 0
                              setPropertyTypeSource(values)
                            }
                          } else {
                            let values = [...propertyTypeSource]
                            if (values[0] == 0) values.splice(0, 1)
                            else if (values[1] == 0) values.splice(1, 1)
                            else if (values[2] == 0) values.splice(2, 1)
                            else if (values[3] == 0) values.splice(3, 1)
                            else values.splice(4, 1)

                            setPropertyTypeSource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                        {' '}
                        Apartment
                      </p>
                    </div>
                  </div>

                  <div className='col-3 d-flex align-items-center'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (propertyTypeSource[0] == 1 ||
                            propertyTypeSource[1] == 1 ||
                            propertyTypeSource[2] == 1 ||
                            propertyTypeSource[3] == 1 ||
                            propertyTypeSource[4] == 1) &&
                            propertyTypeSource.length != 5
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            if (propertyTypeSource.length == 5) {
                              let values = [1]
                              setPropertyTypeSource(values)
                            } else {
                              let values = [...propertyTypeSource]
                              values[values.length] = 1
                              setPropertyTypeSource(values)
                            }
                          } else {
                            let values = [...propertyTypeSource]
                            if (values[0] == 1) values.splice(0, 1)
                            else if (values[1] == 1) values.splice(1, 1)
                            else if (values[2] == 1) values.splice(2, 1)
                            else if (values[3] == 1) values.splice(3, 1)
                            else values.splice(4, 1)

                            setPropertyTypeSource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                        {' '}
                        Penthouse
                      </p>
                    </div>
                  </div>
                  <div className='col-3 d-flex align-items-center test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (propertyTypeSource[0] == 3 ||
                            propertyTypeSource[1] == 3 ||
                            propertyTypeSource[2] == 3 ||
                            propertyTypeSource[3] == 3 ||
                            propertyTypeSource[4] == 3) &&
                            propertyTypeSource.length != 5
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            if (propertyTypeSource.length == 5) {
                              let values = [3]
                              setPropertyTypeSource(values)
                            } else {
                              let values = [...propertyTypeSource]
                              values[values.length] = 3
                              setPropertyTypeSource(values)
                            }
                          } else {
                            let values = [...propertyTypeSource]
                            if (values[0] == 3) values.splice(0, 1)
                            else if (values[1] == 3) values.splice(1, 1)
                            else if (values[2] == 3) values.splice(2, 1)
                            else if (values[3] == 3) values.splice(3, 1)
                            else values.splice(4, 1)

                            setPropertyTypeSource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                        {' '}
                        Townhouse
                      </p>
                    </div>
                  </div>
                  <div className='col-3 d-flex align-items-center test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (propertyTypeSource[0] == 4 ||
                            propertyTypeSource[1] == 4 ||
                            propertyTypeSource[2] == 4 ||
                            propertyTypeSource[3] == 4 ||
                            propertyTypeSource[4] == 4) &&
                            propertyTypeSource.length != 5
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            if (tenancyStatus.length == 5) {
                              let values = [4]
                              setPropertyTypeSource(values)
                            } else {
                              const values = [...propertyTypeSource]

                              values[values.length] = 4
                              setPropertyTypeSource(values)
                            }
                          } else {
                            const values = [...propertyTypeSource]
                            if (values[0] == 4) values.splice(0, 1)
                            else if (values[1] == 4) values.splice(1, 1)
                            else if (values[2] == 4) values.splice(2, 1)
                            else if (values[3] == 4) values.splice(3, 1)
                            else values.splice(4, 1)

                            setPropertyTypeSource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                        {' '}
                        Villa
                      </p>
                    </div>
                  </div>
                  <div className="col-12" style={{ marginTop: '15px' }}>
                    <div className="row d-flex align-items-center">
                      <div className='col-2 d-flex align-items-center test'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              (propertyTypeSource[0] == 5 ||
                                propertyTypeSource[1] == 5 ||
                                propertyTypeSource[2] == 5 ||
                                propertyTypeSource[3] == 5 ||
                                propertyTypeSource[4] == 5) &&
                                propertyTypeSource.length != 5
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                if (tenancyStatus.length == 5) {
                                  // alert('run length 5')
                                  let values = [5]
                                  setPropertyTypeSource(values)
                                } else {
                                  // alert('run length not 5')
                                  const values = [...propertyTypeSource]

                                  values[values.length] = 5
                                  setPropertyTypeSource(values)
                                }
                              } else {
                                // alert('run')
                                const values = [...propertyTypeSource]
                                if (values[0] == 5) values.splice(0, 1)
                                else if (values[1] == 5) values.splice(1, 1)
                                else if (values[2] == 5) values.splice(2, 1)
                                else if (values[3] == 5) values.splice(3, 1)
                                else values.splice(4, 1)

                                setPropertyTypeSource(values)
                              }
                            }}
                          />
                          <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                            {' '}
                            Other
                          </p>
                        </div>
                      </div>
                      <div className='me-4 col-7'>
                        <div
                          className={`multi-select-container ${others.length === 0 ? 'no-value other' : 'has-value'
                            }`}
                        >
                          <MultiSelect
                            options={commonAreaOptions}
                            value={others}
                            onChange={setOthers}
                            labelledBy='Select'
                            valueRenderer={CustomValueRenderer}
                            overrideStrings={{
                              allItemsAreSelected: `${others?.length} Selected`,
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className='col-7 ms-2'>
                        <Select
                          styles={{
                            ...customStyles, container: (defaultStyles: any) => ({
                              ...defaultStyles,
                              width: '80%',
                            })
                          }}
                          isSearchable={true}
                          options={othersOptions}
                          onChange={(e: any) => {
                            let values: any = []
                            for (let i = 0; i < e?.length; i++) {
                              values[i] = Number(e[i]?.value)
                            }
                            setOthers(values)
                          }}
                          placeholder={'Search..'}
                          isMulti
                        />
                      </div> */}
                    </div>
                  </div>
                </div>

                <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-2 mx-4'></div>

                <div className='d-flex align-items-baseline ms-2 mt-2'>
                  <img src={bedroomIcon} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{ fontWeight: '600' }}
                  >
                    Bedrooms
                  </label>
                </div>
                <div className='row mb-5 ' style={{ paddingLeft: '27px' }}>
                  <div className='col-12'>
                    <div
                      className={`multi-select-container ${bedrooms.length === 0 ? 'no-value other' : 'has-value'
                        }`}
                    >
                      <MultiSelect
                        options={bedroomsOptions}
                        value={bedrooms}
                        onChange={setBedrooms}
                        labelledBy='Select'
                        valueRenderer={CustomValueRenderer}
                            overrideStrings={{
                              allItemsAreSelected: `${bedrooms?.length} Selected`,
                            }}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-2 mx-4'></div>
                <div className='d-flex align-items-baseline ms-2 mt-2'>
                  <img src={bathroomIcon} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{ fontWeight: '600' }}
                  >
                    Bathrooms
                  </label>
                </div>
                <div className='row mb-5 ' style={{ paddingLeft: '27px' }}>
                  <div className='col-12'>
                    <div
                      className={`multi-select-container ${bathrooms.length === 0 ? 'no-value other' : 'has-value'
                        }`}
                    >
                      <MultiSelect
                        options={bathroomOptions}
                        value={bathrooms}
                        onChange={setBathrooms}
                        labelledBy='Select'
                        valueRenderer={CustomValueRenderer}
                            overrideStrings={{
                              allItemsAreSelected: `${bathrooms?.length} Selected`,
                          }}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-2 mx-4'></div>

                <div className='d-flex align-items-baseline ms-2 mt-2'>
                  <img src={sizeIcon} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{ fontWeight: '600' }}
                  >
                    Size
                  </label>
                </div>

                <div className='d-flex mb-2 ms-2 xyz' style={{ paddingLeft: '27px' }}>
                  <Paper component='form' style={searchContainerStyle} className='me-5'>
                    <InputBase
                      type='number'
                      placeholder='From'
                      style={inputBaseStyle}
                      inputProps={{ 'aria-label': 'search' }}
                      // value={financial.min == null ? 0 : financial.min}
                      value={propertySize.sizeFrom}
                      onChange={(e: any) => {
                        const values = { ...propertySize }
                        if (e.target.value.trim()?.length > 0) {
                          values.sizeFrom = parseInt(e.target.value)
                          setPropertySize(values)
                        } else {
                          values.sizeFrom = null
                          setPropertySize(values)
                        }
                      }}
                    />
                  </Paper>

                  <Paper component='form' style={searchContainerStyle} className='me-5'>
                    <InputBase
                      type='number'
                      placeholder='To'
                      style={inputBaseStyle}
                      inputProps={{ 'aria-label': 'search' }}
                      value={propertySize.sizeTo}
                      onChange={(e: any) => {
                        const values = { ...size }
                        if (e.target.value.trim()?.length > 0) {
                          values.sizeTo = parseInt(e.target.value)
                          setPropertySize(values)
                        } else {
                          values.sizeTo = null
                          setPropertySize(values)
                        }
                      }}
                    />
                  </Paper>
                </div>

              </>
            )}
            <div className='ms-auto mt-3 mb-5 me-5'>
              <div className='br-arr d-flex align-items-center justify-content-center'>
                {hideProperty == false ? (
                  <img
                    src={upGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideProperty(true)}
                  />
                ) : (
                  <img
                    src={downGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideProperty(false)}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Development */}
          <div className='min-w-200px ps-3  pt-1  mt-1 card' style={{ borderRadius: '0px' }}>
            <label className='mb-2 ms-2 mt-3' style={{ fontWeight: '700', fontSize: '14px' }}>
              {' '}
              Development
            </label>
            {!hideDevelopment && (
              <>
                <div className='d-flex align-items-baseline ms-2 '>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{ fontWeight: '600' }}
                  >
                    Type
                  </label>
                </div>
                <div className='row mb-2 mt-2' style={{ paddingLeft: '27px' }}>
                  <div className='col-3 d-flex align-items-center xyz'>
                    <input
                      className='form-check-input me-3 cursor-pointer'
                      type='radio'
                      checked={developmentSource.length == 2 || developmentSource.length == 0 ? true : false}
                      onChange={(e: any) => {
                        if (e.target.checked == true) {
                          let values = ["building", "community"]
                          setDevelopmentSource(values)
                        } else {
                          setDevelopmentSource([])
                        }
                      }}
                    />{' '}
                    All
                  </div>
                </div>

                <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                  <div className='col-4 d-flex align-items-center test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (developmentSource[0] == "building" || developmentSource[1] == "building") && developmentSource.length != 2
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            if (developmentSource.length == 2) {
                              // setTenantSource([])
                              let values = ["building"]
                              setDevelopmentSource(values)
                            } else {
                              let values = [...developmentSource]
                              values[values.length] = "building"
                              setDevelopmentSource(values)
                            }
                          } else {
                            let values = [...developmentSource]
                            if (values[0] == "building") values.splice(0, 1)
                            else values.splice(1, 1)
                            setDevelopmentSource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                        {' '}
                        Building
                      </p>
                    </div>
                  </div>

                  <div className='col-4 d-flex align-items-center'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (developmentSource[0] == "community" || developmentSource[1] == "community") && developmentSource.length != 2
                            ? true
                            : false
                        }
                        onClick={(e: any) => {
                          if (e.target.checked) {
                            if (developmentSource.length == 2) {
                              // setTenantSource([])
                              let values = ["community"]
                              setDevelopmentSource(values)
                            } else {
                              let values = [...developmentSource]
                              values[values.length] = "community"
                              setDevelopmentSource(values)
                            }
                          } else {
                            let values = [...developmentSource]
                            if (values[0] == "community") values.splice(0, 1)
                            else values.splice(1, 1)
                            setDevelopmentSource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                        {' '}
                        Community
                      </p>
                    </div>
                  </div>
                </div>

                <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-2 mx-4'></div>

                <div className='d-flex align-items-baseline ms-2 mt-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{ fontWeight: '600' }}
                  >
                    Cluster
                  </label>
                </div>
                <div className='row mb-5 ' style={{ paddingLeft: '27px' }}>
                  <div className='col-12'>
                    <div
                      className={`multi-select-container ${clusters.length === 0 ? 'no-value other' : 'has-value'
                        }`}
                    >
                      <MultiSelect
                        options={clusterOptions}
                        value={clusters}
                        onChange={setCluster}
                        labelledBy='Select'
                        valueRenderer={CustomValueRenderer}
                        overrideStrings={{
                          allItemsAreSelected: `${clusters?.length} Selected`,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-2 mx-4'></div>
                <div className='d-flex align-items-baseline ms-2 mt-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{ fontWeight: '600' }}
                  >
                    Group
                  </label>
                </div>
                <div className='row mb-5 ' style={{ paddingLeft: '27px' }}>
                  <div className='col-12'>
                    <div
                      className={`multi-select-container ${groups.length === 0 ? 'no-value other' : 'has-value'
                        }`}
                    >
                      <MultiSelect
                        options={groupOptions}
                        value={groups}
                        onChange={setGroup}
                        labelledBy='Select'
                        valueRenderer={CustomValueRenderer}
                            overrideStrings={{
                              allItemsAreSelected: `${groups?.length} Selected`,
                            }}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-2 mx-4'></div>

                <div className='d-flex align-items-baseline ms-2 mt-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{ fontWeight: '600' }}
                  >
                    Floor
                  </label>
                </div>
                <div className='row mb-5 ' style={{ paddingLeft: '27px' }}>
                  <div className='col-12'>
                    <div
                      className={`multi-select-container ${floors.length === 0 ? 'no-value other' : 'has-value'
                        }`}
                    >
                      <MultiSelect
                        options={floorOptions}
                        value={floors}
                        onChange={setFloor}
                        labelledBy='Select'
                        valueRenderer={CustomValueRenderer}
                        overrideStrings={{
                          allItemsAreSelected: `${floors?.length} Selected`,
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-2 mx-4'></div>

                <div className='d-flex align-items-baseline ms-2 mt-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{ fontWeight: '600' }}
                  >
                    Unit
                  </label>
                </div>
                <div className='row mb-5 ' style={{ paddingLeft: '27px' }}>
                  <div className='col-12'>
                    <div
                      className={`multi-select-container ${units.length === 0 ? 'no-value other' : 'has-value'
                        }`}
                    >
                      <MultiSelect
                        options={unitOptions}
                        value={units}
                        onChange={setUnits}
                        labelledBy='Select'
                        valueRenderer={CustomValueRenderer}
                        overrideStrings={{
                          allItemsAreSelected: `${units?.length} Selected`,
                        }}
                      />
                    </div>
                  </div>
                </div>


              </>
            )}
            <div className='ms-auto mt-3 mb-5 me-5'>
              <div className='br-arr d-flex align-items-center justify-content-center'>
                {hideDevelopment == false ? (
                  <img
                    src={upGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideDevelopment(true)}
                  />
                ) : (
                  <img
                    src={downGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideDevelopment(false)}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Occupancy & Leases */}
          <div className='min-w-200px card ps-3  pt-1  mt-1' style={{ borderRadius: '0px' }}>
            <label className='mb-2 ms-2 mt-3' style={{ fontWeight: '700', fontSize: '14px' }}>
              {' '}
              Occupancy & Leases
            </label>
            {!hideTenant && (
              <>
                <div className='d-flex align-items-baseline ms-2 '>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{ fontWeight: '600' }}
                  >
                    Occupancy
                  </label>
                </div>
                <div className='row mb-2 mt-2' style={{ paddingLeft: '27px' }}>
                  <div className='col-3 d-flex align-items-center xyz'>
                    <input
                      className='form-check-input me-3 cursor-pointer'
                      type='radio'
                      checked={tenantSource.length == 2 || tenantSource.length == 0 ? true : false}
                      onChange={(e: any) => {
                        if (e.target.checked == true) {
                          let values = [0, 1]
                          setTenantSource(values)
                        } else {
                          setTenantSource([])
                        }
                      }}
                    />{' '}
                    All
                  </div>
                </div>

                <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                  <div className='col-4 d-flex align-items-center test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (tenantSource[0] == 0 || tenantSource[1] == 0) && tenantSource.length != 2
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            if (tenantSource.length == 2) {
                              // setTenantSource([])
                              let values = [0]
                              setTenantSource(values)
                            } else {
                              let values = [...tenantSource]
                              values[values.length] = 0
                              setTenantSource(values)
                            }
                          } else {
                            let values = [...tenantSource]
                            if (values[0] == 0) values.splice(0, 1)
                            else values.splice(1, 1)
                            setTenantSource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                        {' '}
                        Occupied
                      </p>
                    </div>
                  </div>

                  <div className='col-4 d-flex align-items-center'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (tenantSource[1] == 1 || tenantSource[0] == 1) && tenantSource.length != 2
                            ? true
                            : false
                        }
                        onClick={(e: any) => {
                          if (e.target.checked) {
                            if (tenantSource.length == 2) {
                              // setTenantSource([])
                              let values = [1]
                              setTenantSource(values)
                            } else {
                              let values = [...tenantSource]
                              values[values.length] = 1
                              setTenantSource(values)
                            }
                          } else {
                            let values = [...tenantSource]
                            if (values[0] == 1) values.splice(0, 1)
                            else values.splice(1, 1)
                            setTenantSource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                        {' '}
                        Vacant
                      </p>
                    </div>
                  </div>
                </div>

                <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-2 mx-4'></div>
                <div className='d-flex align-items-baseline ms-2 mt-4'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{ fontWeight: '600' }}
                  >
                    Lease
                  </label>
                </div>
                <div className='row mb-2 mt-2' style={{ paddingLeft: '27px' }}>
                  <div className='col-3 d-flex align-items-center xyz'>
                    <input
                      className='form-check-input me-3 cursor-pointer'
                      type='radio'
                      checked={leaseSource.length == 3 || leaseSource.length == 0 ? true : false}
                      onChange={(e: any) => {
                        if (e.target.checked == true) {
                          let values = [1,2,3]
                          setLeaseSource(values)
                        } else {
                          setLeaseSource([])
                        }
                      }}
                    />{' '}
                    All
                  </div>
                </div>

                <div className='row mb-2 ' style={{ paddingLeft: '27px' }}>
                  <div className='col-4 d-flex align-items-center test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (leaseSource[0] == 1 || leaseSource[1] == 1 || leaseSource[2] == 1) && leaseSource.length != 3
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          if (e.target.checked) {
                            if (leaseSource.length == 3) {
                              // setTenantSource([])
                              let values = [1]
                              setLeaseSource(values)
                            } else {
                              let values = [...leaseSource]
                              values[values.length] = 1
                              setLeaseSource(values)
                            }
                          } else {
                            let values = [...leaseSource]
                            if (values[0] == 1) values.splice(0, 1)
                            else if (values[1] == 1) values.splice(1, 1)
                            else values.splice(2, 1)
                            setLeaseSource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                        {' '}
                        Active
                      </p>
                    </div>
                  </div>

                  <div className='col-4 d-flex align-items-center'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (leaseSource[1] == 2 || leaseSource[0] == 2 || leaseSource[2] == 2) && leaseSource.length != 3
                            ? true
                            : false
                        }
                        onClick={(e: any) => {
                          if (e.target.checked) {
                            if (leaseSource.length == 3) {
                              let values = [2]
                              setLeaseSource(values)
                            } else {
                              let values = [...leaseSource]
                              values[values.length] = 2
                              setLeaseSource(values)
                            }
                          } else {
                            let values = [...leaseSource]
                            if (values[0] == 2) values.splice(0, 1)
                            else if (values[1] == 2) values.splice(1, 1)
                            else values.splice(2, 1)
                            setLeaseSource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                        {' '}
                        Expiring
                      </p>
                    </div>
                  </div>

                  <div className='col-4 d-flex align-items-center'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          (leaseSource[0] == 3 || leaseSource[1] == 3 || leaseSource[2] == 3) && leaseSource.length != 3
                            ? true
                            : false
                        }
                        onClick={(e: any) => {
                          if (e.target.checked) {
                            if (leaseSource.length == 3) {
                              let values = [3]
                              setLeaseSource(values)
                            } else {
                              let values = [...leaseSource]
                              values[values.length] = 3
                              setLeaseSource(values)
                            }
                          } else {
                            let values = [...leaseSource]
                            if (values[0] == 3) values.splice(0, 1)
                            else if (values[1] == 3) values.splice(1, 1)
                            else values.splice(2, 1)
                            setLeaseSource(values)
                          }
                        }}
                      />
                      <p className='head-text ms-3 mb-0' style={{ fontWeight: '600' }}>
                        {' '}
                        Expired
                      </p>
                    </div>
                  </div>
                </div>

                <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-2 mx-4'></div>

                <div className='d-flex align-items-baseline ms-2 mt-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                    style={{ fontWeight: '600' }}
                  >
                    Rate of Occupancy
                  </label>
                </div>

                <div className='d-flex mb-2 ms-2 xyz' style={{ paddingLeft: '27px' }}>
                  <Paper component='form' style={searchContainerStyle} className='me-5'>
                    <InputBase
                      type='number'
                      placeholder='From'
                      style={inputBaseStyle}
                      inputProps={{ 'aria-label': 'search' }}
                      // value={financial.min == null ? 0 : financial.min}
                      value={occupancyRate.occupancyFrom}
                      onChange={(e: any) => {
                        const values = { ...occupancyRate }
                        if (e.target.value.trim()?.length > 0) {
                          values.occupancyFrom = parseInt(e.target.value)
                          setPropertySize(values)
                        } else {
                          values.occupancyFrom = null
                          setOccupancyRate(values)
                        }
                      }}
                    />
                  </Paper>

                  <Paper component='form' style={searchContainerStyle} className='me-5'>
                    <InputBase
                      type='number'
                      placeholder='To'
                      style={inputBaseStyle}
                      inputProps={{ 'aria-label': 'search' }}
                      value={occupancyRate.occupancyTo}
                      onChange={(e: any) => {
                        const values = { ...occupancyRate }
                        if (e.target.value.trim()?.length > 0) {
                          values.occupancyTo = parseInt(e.target.value)
                          setOccupancyRate(values)
                        } else {
                          values.occupancyTo = null
                          setOccupancyRate(values)
                        }
                      }}
                    />
                  </Paper>
                </div>

              </>
            )}

            <div className='ms-auto mt-3 mb-3 me-5'>
              <div className='br-arr d-flex align-items-center justify-content-center'>
                {hideTenant == false ? (
                  <img
                    src={upGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideTenant(true)}
                  />
                ) : (
                  <img
                    src={downGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideTenant(false)}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Financials */}
          <div className='min-w-200px  ps-3 pt-1 card mt-1' style={{ borderRadius: '0px' }}>
            <label className='mb-2 ms-2 mt-3' style={{ fontWeight: '700', fontSize: '14px' }}>
              {' '}
              Financials
            </label>

            {!hideFinancials && (
              <>
                {/* Rent Value */}
                <div className='d-flex align-items-center ms-2 mt-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                    style={{ fontWeight: '600' }}
                  >
                    Income
                  </label>
                </div>
                <div className='d-flex mb-2 ms-2 xyz' style={{ paddingLeft: '27px' }}>
                  <Paper component='form' style={searchContainerStyle} className='me-5'>
                    <InputBase
                      type='number'
                      placeholder='From (AED)'
                      style={inputBaseStyle}
                      inputProps={{ 'aria-label': 'search' }}
                      // value={financial.min == null ? 0 : financial.min}
                      value={financial.incomeMin}
                      onChange={(e: any) => {
                        const values = { ...financial }
                        if (e.target.value.trim()?.length > 0) {
                          values.incomeMin = parseInt(e.target.value)
                          setFinancial(values)
                        } else {
                          values.incomeMin = null
                          setFinancial(values)
                        }
                      }}
                    />
                    {/* <IconButton style={searchIconStyle} aria-label='search'>
                      <SearchIcon />
                    </IconButton> */}
                  </Paper>

                  <Paper component='form' style={searchContainerStyle} className='me-5'>
                    <InputBase
                      type='number'
                      placeholder='To (AED)'
                      style={inputBaseStyle}
                      inputProps={{ 'aria-label': 'search' }}
                      value={financial.incomeMax}
                      onChange={(e: any) => {
                        const values = { ...financial }
                        if (e.target.value.trim()?.length > 0) {
                          values.incomeMax = parseInt(e.target.value)
                          setFinancial(values)
                        } else {
                          values.incomeMax = null
                          setFinancial(values)
                        }
                      }}
                    />
                    {/* <IconButton style={searchIconStyle} aria-label='search'>
                      <SearchIcon />
                    </IconButton> */}
                  </Paper>
                </div>

                <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-3 mx-4'></div>

                <div className='d-flex align-items-center ms-2 mt-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                    style={{ fontWeight: '600' }}
                  >
                    Expense
                  </label>
                </div>
                <div className='d-flex mb-2 ms-2 xyz' style={{ paddingLeft: '27px' }}>
                  <Paper component='form' style={searchContainerStyle} className='me-5'>
                    <InputBase
                      type='number'
                      placeholder='From (AED)'
                      style={inputBaseStyle}
                      inputProps={{ 'aria-label': 'search' }}
                      // value={financial.min == null ? 0 : financial.min}
                      value={financial.expenseMin}
                      onChange={(e: any) => {
                        const values = { ...financial }
                        if (e.target.value.trim()?.length > 0) {
                          values.expenseMin = parseInt(e.target.value)
                          setFinancial(values)
                        } else {
                          values.expenseMin = null
                          setFinancial(values)
                        }
                      }}
                    />
                  </Paper>

                  <Paper component='form' style={searchContainerStyle} className='me-5'>
                    <InputBase
                      type='number'
                      placeholder='To (AED)'
                      style={inputBaseStyle}
                      inputProps={{ 'aria-label': 'search' }}
                      value={financial.expenseMax}
                      onChange={(e: any) => {
                        const values = { ...financial }
                        if (e.target.value.trim()?.length > 0) {
                          values.expenseMax = parseInt(e.target.value)
                          setFinancial(values)
                        } else {
                          values.expenseMax = null
                          setFinancial(values)
                        }
                      }}
                    />
                  </Paper>
                </div>


                <div style={{ borderTop: '1.5px solid #b5b5c3' }} className='mt-3 mx-4'></div>

                <div className='d-flex align-items-center ms-2 mt-2'>
                  <img src={windowImg} height='14' width='14' className='me-4' />

                  <label
                    className='mb-1 me-5 pe-5 d-flex grey-label fs-4  '
                    style={{ fontWeight: '600' }}
                  >
                    Upcoming
                  </label>
                </div>
                <div className='d-flex mb-2 ms-2 xyz' style={{ paddingLeft: '27px' }}>
                  <Paper component='form' style={searchContainerStyle} className='me-5'>
                    <InputBase
                      type='number'
                      placeholder='From (AED)'
                      style={inputBaseStyle}
                      inputProps={{ 'aria-label': 'search' }}
                      // value={financial.min == null ? 0 : financial.min}
                      value={financial.upcomingMin}
                      onChange={(e: any) => {
                        const values = { ...financial }
                        if (e.target.value.trim()?.length > 0) {
                          values.upcomingMin = parseInt(e.target.value)
                          setFinancial(values)
                        } else {
                          values.upcomingMin = null
                          setFinancial(values)
                        }
                      }}
                    />
                  </Paper>

                  <Paper component='form' style={searchContainerStyle} className='me-5'>
                    <InputBase
                      type='number'
                      placeholder='To (AED)'
                      style={inputBaseStyle}
                      inputProps={{ 'aria-label': 'search' }}
                      value={financial.upcomingMax}
                      onChange={(e: any) => {
                        const values = { ...financial }
                        if (e.target.value.trim()?.length > 0) {
                          values.upcomingMax = parseInt(e.target.value)
                          setFinancial(values)
                        } else {
                          values.upcomingMax = null
                          setFinancial(values)
                        }
                      }}
                    />
                  </Paper>
                </div>

                <div className='d-flex mb-2 ms-2 xyz' style={{ paddingLeft: '27px' }}></div>
              </>
            )}

            <div className='ms-auto mt-3 mb-5 me-5'>
              <div className='br-arr d-flex align-items-center justify-content-center'>
                {hideFinancials == false ? (
                  <img
                    src={upGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideFinancials(true)}
                  />
                ) : (
                  <img
                    src={downGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideFinancials(false)}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Rating */}
          <div className='min-w-200px  ps-3 pt-1 card mt-1' style={{ borderRadius: '0px' }}>
            <label className='mb-2 ms-2 mt-3' style={{ fontWeight: '700', fontSize: '14px' }}>
              {' '}
              Rating
            </label>

            {!hideRating && (
              <>
                {/* Rating */}
                <div className="row">
                  <div className="col-12">
                    <div className='d-flex mt-1' style={{ columnGap: '10px' }}>
                      <img
                        src={rating >= 1 ? yellowStar : grayStar}
                        height={35}
                        width={35}
                        className='cursor-pointer'
                        onClick={() => setRating(1)}
                      />
                      <img
                        src={rating >= 2 ? yellowStar : grayStar}
                        height={35}
                        width={35}
                        className='cursor-pointer'
                        onClick={() => setRating(2)}
                      />
                      <img
                        src={rating >= 3 ? yellowStar : grayStar}
                        height={35}
                        width={35}
                        className='cursor-pointer'
                        onClick={() => setRating(3)}
                      />
                      <img
                        src={rating >= 4 ? yellowStar : grayStar}
                        height={35}
                        width={35}
                        className='cursor-pointer'
                        onClick={() => setRating(4)}
                      />
                      <img
                        src={rating >= 5 ? yellowStar : grayStar}
                        height={35}
                        width={35}
                        className='cursor-pointer'
                        onClick={() => setRating(5)}
                      />
                    </div>
                  </div>
                </div>



                <div className='d-flex mb-2 ms-2 xyz' style={{ paddingLeft: '27px' }}></div>
              </>
            )}

            <div className='ms-auto mt-3 mb-5 me-5'>
              <div className='br-arr d-flex align-items-center justify-content-center'>
                {hideRating == false ? (
                  <img
                    src={upGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideRating(true)}
                  />
                ) : (
                  <img
                    src={downGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideRating(false)}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Assigned User */}
          <div className='min-w-200px  ps-3 pt-1 card mt-1' style={{ borderRadius: '0px' }}>
            <label className='mb-2 ms-2 mt-3' style={{ fontWeight: '700', fontSize: '14px' }}>
              {' '}
              Assigned User
            </label>

            {!hideAssignedUser && (
              <>
                {/* Rating */}
                <div className="row">
                  <div className='d-flex align-items-baseline ms-2 mt-2'>
                    <img src={windowImg} height='14' width='14' className='me-4' />

                    <label
                      className='mb-1 me-5 pe-5 d-flex grey-label fs-4 '
                      style={{ fontWeight: '600' }}
                    >
                      User
                    </label>
                  </div>
                  <div className='row mb-5 ' style={{ paddingLeft: '27px' }}>
                    <div className='col-12'>
                      <div
                        className={`multi-select-container ${users.length === 0 ? 'no-value other' : 'has-value'
                          }`}
                      >
                        <MultiSelect
                          options={userOptions}
                          value={users}
                          onChange={setUser}
                          labelledBy='Select'
                          valueRenderer={CustomValueRenderer}
                            overrideStrings={{
                              allItemsAreSelected: `${users?.length} Selected`,
                            }}
                        />
                      </div>
                    </div>
                  </div>
                </div>



                <div className='d-flex mb-2 ms-2 xyz' style={{ paddingLeft: '27px' }}></div>
              </>
            )}

            <div className='ms-auto mt-3 mb-5 me-5'>
              <div className='br-arr d-flex align-items-center justify-content-center'>
                {hideAssignedUser == false ? (
                  <img
                    src={upGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideAssignedUser(true)}
                  />
                ) : (
                  <img
                    src={downGreen}
                    height={20}
                    width={20}
                    className='cursor-pointer'
                    onClick={() => setHideAssignedUser(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Properties
