import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../../img/back-arrow.png'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../../apiCommon/helpers/Toast'
import noData from '../../../../../img/NoData1.svg'
import ReactPaginate from 'react-paginate'
import CircularProgress from '@mui/material/CircularProgress'

const ListingEmailTemplates = () => {
  const navigate = useNavigate()

  const [requestTableData, setRequestTableData] = useState<any>([
    {email: 'maintenance@corporate.com'},
    {email: 'email1@corporate.com'},
  ])

  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)
  const [pageLimit, setPageLimit] = useState<any>(1)

  const [isLoading, setIsLoading] = useState<any>(false)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const getRequestTableData = async () => {
    setIsLoading(true)
    setRequestTableData([])

    let body = {
      page: page,
      limit: limit,
    }

    await ApiPost('corporate/email_template/get', body)
      .then((res) => {
        setRequestTableData(res?.data?.data?.email_template)
        setPageLimit(res?.data?.data?.state?.page_limit)
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const updateReqestData = async (id: any, toggleValue: any, index: any) => {
    let body = {
      id: id,
      isEnable: toggleValue,
    }

    await ApiPut('', body)
      .then((res) => {
        const updateRequestData = [...requestTableData]
        updateRequestData[index].isEnable = res?.data?.data?.isEnable
        // setRequestTableData(updateRequestData)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    getRequestTableData()
  }, [])

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-start'>
          <div className='d-flex align-items-start'>
            <span className='rounded-circle cursor-pointer' onClick={() => navigate(`/settings`)}>
              <img src={backArrow} height={14} width={14} className='me-4' />
            </span>
            <div>
              <h2 className='page-heading m-0 head-text'>{'Email Builder'}</h2>
              <p className='labl-gry fs-4 mb-0'></p>
            </div>
          </div>
          <button
            type='button'
            className='btn btn-sm fw-bold green-submit-btn px-7 justify-content-center btn-fit-content ms-auto'
            onClick={() => {
              navigate('/settings/crm-residents/email-templates/select')
            }}
          >
            Create Email
          </button>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pb-0 tst mt-5'>
          <div className='card-body pt-0 table-responsive mt-5 py-2 px-1'>
            <table className='table align-middle fs-6 gy-5' id='kt_ecommerce_sales_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold text-uppercase gs-0'>
                  <th className='min-w-200px'>Email Address</th>
                  <th className='min-w-150px'>Template</th>
                </tr>
              </thead>
              <tbody className='fw-semibold head-text'>
                {' '}
                {/* className='fw-semibold text-gray-600'*/}
                {requestTableData?.length > 0 ? (
                  requestTableData?.map((itm: any, ind: any) => {
                    return (
                      <tr>
                        <td>{itm?.email ? itm?.email : '-'}</td>
                        <td className='cursor-pointer'>
                          {itm?.templateName?.trim()?.length == 0 ||
                          itm?.templateName == undefined ? (
                            <button
                              type='button'
                              className='btn btn-sm fw-bold green-submit-btn justify-content-center btn-fit-content px-7'
                              onClick={() => {
                                navigate(
                                  `/settings/crm-residents/email-templates/select/${itm?._id}`,
                                  {
                                    state: {
                                      email: itm?.email,
                                    },
                                  }
                                )
                              }}
                            >
                              Set Template
                            </button>
                          ) : (
                            <>{itm?.templateName}</>
                          )}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    {!isLoading ? (
                      <td colSpan={2} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    ) : (
                      <td colSpan={2} className='text-center'>
                        <div
                          className='d-flex justify-content-center align-items-center w-100'
                          style={{height: '25vh'}}
                        >
                          <CircularProgress style={{color: '#007a59'}} />
                        </div>
                      </td>
                    )}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* pagination start*/}
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='row mt-5'>
              <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_ecommerce_sales_table_paginate'
                >
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    forcePage={page - 1}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </div>
          {/* pagination end*/}
        </div>
      </div>
    </>
  )
}

export default ListingEmailTemplates
