import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  ApiDelete,
} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {Controller, useForm} from 'react-hook-form'
import swal from 'sweetalert2'
import moment from 'moment'
import PhoneInput from 'react-phone-input-2'
import backArrow from '../../../img/back-arrow.png'
import submitImg from '../../../img/Submit-White.png'
import trashImg from '../../../img/trash.png'
import saveGreenImg from '../../../img/save-dark-green.png'
import Select from 'react-select'
import addWhite from '../../../img/add-white.png'
import redCross from '../../../img/remove.png'
import homeImg from '../../../img/home.png'
import SelectPropertyModal from './SelectProperty'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import downGreen from '../../../img/down-arrow-green.png'
import upGreen from '../../../img/up-arrow-green.png'
import selectListImg from '../../../img/select-list.png'
import SelectListModal from './SelectListModal'
import upload from '../../../img/upload-blue.png'
import pencil from '../../../img/edit-pen.png'
import crossBtn from '../../../img/cross-button.png'
import pdfDocument from '../../../img/pdf.svg'
import docCocument from '../../../img/doc.svg'
import fileIcon from '../../../img/file.svg'

import ModalLoader from '../General/ModalLoader'
import { capitalizeFirstLetter, getFileIcon } from '../../../Utilities/utils'
import { handleImageClick, useFancybox } from '../General/FilePreviewFunctions'

const ViewEditVendor = () => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const {flg, id} = useParams()

  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<any>('Company')
  const [vendorData, setVendorData] = useState<any>({})

  const [isEdit, setIsEdit] = useState<any>(false)

  const [contactInformation, setContactInformation] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>(false)

  const [vendorTypeOptions, setVendorTypeOptions] = useState<any>([
    {value: 0, label: 'Service Provider'},
    {value: 1, label: 'Supplier'},
  ])

  const [autoSaveData, setAutoSaveData] = useState<any>(false)
  const [open, setOpen] = React.useState(false)

  const [country, setCountry] = useState<any>([])
  const [countryOptions, setCountryOptions] = useState<any>([])

  const [city, setCity] = useState<any>([])
  const [cityOptions, setCityOptions] = useState<any>([
    {
      label: 'Dubai',
      value: 'Dubai',
    },
    {
      label: 'Abu Dhabi',
      value: 'Abu Dhabi',
    },
    {
      label: 'Sharjah',
      value: 'Sharjah',
    },
    {
      label: 'Ajman',
      value: 'Ajman',
    },
    {
      label: 'Umm Al Quwain',
      value: 'Umm Al Quwain',
    },
    {
      label: 'Ras Al Khaimah',
      value: 'Ras Al Khaimah',
    },
    {
      label: 'Fujairah',
      value: 'Fujairah',
    },
  ])

  useEffect(() => {
    let values: any = []
    if (city == 'Dubai') {
      values = [
        {
          label: 'Al Jaddaf',
          value: 'Al Jaddaf',
        },
        {
          label: 'Barsha',
          value: 'Barsha',
        },
        {
          label: 'Culture Village',
          value: 'Culture Village',
        },
        {
          label: 'Jumeriah Lake Towers',
          value: 'Jumeriah Lake Towers',
        },
      ]
    } else if (city == 'Abu Dhabi') {
      values = [
        {
          label: 'Al Bateen',
          value: 'Al Bateen',
        },
        {
          label: 'Al Saadah',
          value: 'Al Saadah',
        },
        {
          label: 'Corniche',
          value: 'Corniche',
        },
        {
          label: 'Yas Island',
          value: 'Yas Island',
        },
      ]
    } else if (city == 'Sharjah') {
      values = [
        {
          label: 'Al Qasimia',
          value: 'Al Qasimia',
        },
        {
          label: 'Halwan',
          value: 'Halwan',
        },
        {
          label: 'Industrial Area',
          value: 'Industrial Area',
        },
        {
          label: 'Muwaileh',
          value: 'Muwaileh',
        },
      ]
    } else if (city == 'Ajman') {
      values = [
        {
          label: 'Al Bustan',
          value: 'Al Bustan',
        },
        {
          label: 'Al Hamidiya',
          value: 'Al Hamidiya',
        },
        {
          label: 'Al Jerf 1',
          value: 'Al Jerf 1',
        },
        {
          label: 'Al Jerf 2',
          value: 'Al Jerf 2',
        },
        {
          label: 'Al Rawda',
          value: 'Al Rawda',
        },
      ]
    } else if (city == 'Umm Al Quwain') {
      values = [
        {
          label: 'Al Butain',
          value: 'Al Butain',
        },
        {
          label: 'Al Neefah',
          value: 'Al Neefah',
        },
        {
          label: 'Al Raas',
          value: 'Al Raas',
        },
        {
          label: 'Al Raudah',
          value: 'Al Raudah',
        },
        {
          label: 'Al Salamah',
          value: 'Al Salamah',
        },
      ]
    } else if (city == 'Ras Al Khaimah') {
      values = [
        {
          label: 'Al Dhait South',
          value: 'Al Dhait South',
        },
        {
          label: 'Al Kharran',
          value: 'Al Kharran',
        },
        {
          label: 'Al Sewaan',
          value: 'Al Sewaan',
        },
        {
          label: 'Al Digdaga',
          value: 'Al Digdaga',
        },
        {
          label: 'Dafn Al Khor',
          value: 'Dafn Al Khor',
        },
      ]
    } else if (city == 'Fujairah') {
      values = [
        {
          label: 'Al Faseel',
          value: 'Al Faseel',
        },
        {
          label: 'Al Gurfah',
          value: 'Al Gurfah',
        },
        {
          label: 'Al Sharyah',
          value: 'Al Sharyah',
        },
        {
          label: 'Al Righailat',
          value: 'Al Righailat',
        },
        {
          label: 'Safad',
          value: 'Safad',
        },
      ]
    }
    setAreaOptions(values)
  }, [city])

  const [area, setArea] = useState<any>([])
  const [areaOptions, setAreaOptions] = useState<any>([])

  const [hide, setHide] = React.useState<any>(false)
  const [hideList, setHideList] = React.useState<any>(false)

  const [selectModal, setSelectModal] = useState<any>(false)
  const [selectListModal, setSelectListModal] = useState<any>(false)

  const [properties, setProperties] = useState<any>([])
  const [addedProperties, setAddedProperties] = useState<any>([])

  const [added, setAdded] = useState<any>(false)

  const [lists, setLists] = useState<any>([])

  let countryInd: any
  let cityInd: any
  let areaInd: any

  let propertiesSelected: any = 0
  let listsSelected: any = 0

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(res?.data?.data)
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((cn: any, ind: any) => {
            values[ind] = {
              label: `${cn?.country}`,
              value: `${cn?.country}`,
            }
          })

        setCountryOptions(values)
      })
      .catch((e: any) => {
        console.log('e', e)
      })
  }

  const handleAddContactInformation = () => {
    let values = [...contactInformation]
    values.push({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    })
    setContactInformation(values)
  }

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '50%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  useEffect(() => {
    getCountry()
  }, [])

  //
  const deleteVendor = () => {
    setIsLoading(true)
    let vId: any = []
    vId[0] = id

    const body = {
      vendorIds: vId,
    }

    ApiPost(`corporate/vendor/delete`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)
        navigate('/vendor-management')
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const handlePropertySelection = (data: any) => {
    console.log(data)

    let values: any = []
    let i: any = 0
    data?.map((dt: any, ind: any) => {
      if (dt?.check) {
        values[i] = {
          name: dt?.name,
          assignedProperties: {
            developmentId: dt?._id,
            buildingId: dt?.building?._id ? dt?.building?._id : undefined,
            clusterId: dt?.cluster?._id ? dt?.cluster?._id : undefined,
            unitGroupId: dt?.unitGroup?._id ? dt?.unitGroup?._id : undefined,
          },
          check: false,
          clusterName: dt?.cluster?.name,
          UnitGroupName: dt?.unitGroup?.name,
        }
        i++
      }
    })

    setProperties(values)

    let propertiesToSend: any = {
      developmentIds: [],
      buildingIds: [],
      unitGroupsIds: [],
      clusterIds: [],
    }

    values?.map((property: any) => {
      if (property?.assignedProperties?.developmentId != undefined)
        propertiesToSend.developmentIds[propertiesToSend.developmentIds?.length] =
          property?.assignedProperties?.developmentId

      if (property?.assignedProperties?.buildingId != undefined)
        propertiesToSend.buildingIds[propertiesToSend.buildingIds?.length] =
          property?.assignedProperties?.buildingId

      if (property?.assignedProperties?.clusterId != undefined)
        propertiesToSend.clusterIds[propertiesToSend.clusterIds?.length] =
          property?.assignedProperties?.clusterId

      if (property?.assignedProperties?.unitGroupId != undefined)
        propertiesToSend.unitGroupIds[propertiesToSend.unitGroupIds?.length] =
          property?.assignedProperties?.unitGroupId
    })

    console.log(propertiesToSend)

    setAddedProperties(propertiesToSend)
  }

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)
  const [attachments, setAttachments] = useState<any>([])

  const handleChange = async (e: any) => {
    e.preventDefault()

    const values = [...attachments]
    let newVal = values?.length - 1

    if (e.target.files && e.target.files[0]) {
      setShowLoader(true)
      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let x = values[values.length]?.src?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any = e.target.files[i]?.name

        await ApiPost(
          `upload/image/params?fileType=vendor_trade_license&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            newVal = newVal + 1
            values[newVal] = {
              src: fileURL,
              name: '',
              fileType: 'cheque',
              databaseId: data?._id,
              image: res?.data?.data?.image,
            }
            setUploadProgress(i + 1)
          })
          .catch((err) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }
      setAttachments(values)
      setShowLoader(false)
      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      e.target.value = null

      setTimeout(() => {
        setAutoSaveData(Date.now())
      }, 700)
    }
  }

  //
  const updateVendor = async (e: any, isSave: any) => {
    setOpen(true)
    if (e != '') e?.preventDefault()

    let attachedMedia: any = []
    attachments?.map((attach: any, ind: any) => {
      attachedMedia[ind] = attach?.image
    })

    let vdIds: any = []
    lists?.map((list: any, ind: any) => {
      vdIds[ind] = list?._id
    })

    let propertiesToSend: any = {
      developmentIds: [],
      buildingIds: [],
      unitGroupsIds: [],
      clusterIds: [],
    }

    properties?.map((property: any) => {
      if (property?.assignedProperties?.developmentId != undefined)
        propertiesToSend.developmentIds[propertiesToSend.developmentIds?.length] =
          property?.assignedProperties?.developmentId

      if (property?.assignedProperties?.buildingId != undefined)
        propertiesToSend.buildingIds[propertiesToSend.buildingIds?.length] =
          property?.assignedProperties?.buildingId

      if (property?.assignedProperties?.clusterId != undefined)
        propertiesToSend.clusterIds[propertiesToSend.clusterIds?.length] =
          property?.assignedProperties?.clusterId

      if (property?.assignedProperties?.unitGroupId != undefined)
        propertiesToSend.unitGroupIds[propertiesToSend.unitGroupIds?.length] =
          property?.assignedProperties?.unitGroupId
    })

    let newDev = propertiesToSend?.developmentIds?.filter((value: any, index: any, self: any) => {
      return self.indexOf(value) === index
    })

    let newClust = propertiesToSend?.clusterIds?.filter((value: any, index: any, self: any) => {
      return self.indexOf(value) === index
    })

    let newUnitGroup = propertiesToSend?.unitGroupsIds?.filter(
      (value: any, index: any, self: any) => {
        return self.indexOf(value) === index
      }
    )

    let newArr: any = {
      developmentIds: newDev,
      buildingIds: [],
      unitGroupsIds: newUnitGroup,
      clusterIds: newClust,
    }

    const body = {
      id: vendorData?._id,
      companyName: vendorData?.companyName,
      tradeLicenseNo: vendorData?.tradeLicenseNo,
      TRN: vendorData?.TRN,
      country: vendorData?.country,
      city: vendorData?.city,
      ratting: vendorData?.ratting,
      email: vendorData?.email,
      countryCode: vendorData?.countryCode,
      phoneNumber: vendorData?.phoneNumber,
      vendorType: vendorData?.vendorType,
      vendorListIds: vdIds,
      tradeLicenseImages: attachedMedia,
      contactInformation: contactInformation,
      area: vendorData?.area,
      address1: vendorData?.address1,
      address2: vendorData?.address2,
      landlineNumber: vendorData?.landlineNumber,
      assignPropertise: newArr,
      saveStatus: isSave ? 0 : 1,
    }

    await ApiPut(`corporate/vendor`, body)
      .then((res: any) => {
        setOpen(false)
        if (!isSave) {
          SuccessToast(res?.data?.message)
          getVendorById()
        }
      })
      .catch((err: any) => {
        setOpen(false)
        ErrorToast(err?.message)
      })
  }

  const getVendorById = () => {
    ApiGet(`corporate/vendor/${id}`)
      .then((res: any) => {
        setVendorData(res?.data?.data)

        setValue('companyName', res?.data?.data?.companyName)
        setValue('vendorType', res?.data?.data?.vendorType)
        setValue('tradeLicenseNo', res?.data?.data?.tradeLicenseNo)
        setValue('TRN', res?.data?.data?.TRN)
        setValue('country', res?.data?.data?.country)
        setValue('city', res?.data?.data?.city)
        setCity(res?.data?.data?.city)
        setValue('area', res?.data?.data?.area)
        setValue('address1', res?.data?.data?.address1)
        setValue('address2', res?.data?.data?.address2)
        setValue('email', res?.data?.data?.email)
        setValue('phoneNumber', res?.data?.data?.phoneNumber)
        setValue('landlineNumber', res?.data?.data?.landlineNumber)

        let values: any = []
        res?.data?.data?.tradeLicenseImages?.map((img: any, ind: any) => {
          values[ind] = {
            image: img,
          }
        })

        setAttachments(values)

        setAddedProperties(res?.data?.data?.assignPropertise)

        setAdded(true)

        let contact: any = []
        res?.data?.data?.contactInformation?.map((ct: any, ind: any) => {
          contact[ind] = {
            firstName: ct?.firstName,
            lastName: ct?.lastName,
            email: ct?.email,
            phoneNumber: ct?.phoneNumber,
          }
        })

        setContactInformation(contact)

        setValue('firstName', contact?.[0]?.firstName)
        setValue('lastName', contact?.[0]?.lastName)
        setValue('contactEmail', contact?.[0]?.email)
        setValue('contactPhone', contact?.[0]?.phoneNumber)

        let vendorValue: any = res?.data?.data?.vendorListIds

        let ind: any = 0

        ApiGet(`corporate/vendor_list?searchName=&sortType=A-Z`)
          .then((res: any) => {
            let values: any = []
            for (let i = 0; i < res?.data?.data?.length; i++) {
              for (let j = 0; j < vendorValue?.length; j++) {
                if (res?.data?.data[i]?._id == vendorValue[j]) {
                  values[ind] = {
                    name: res?.data?.data?.[i]?.name,
                    _id: res?.data?.data?.[i]?._id,
                    colorCode: res?.data?.data?.[i]?.colorCode,
                    check: false,
                  }
                  ind++
                }
              }
            }

            setLists(values)

            setIsEdit(false)
          })
          .catch(() => {})
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getVendorById()
  }, [])

  //
  const updatePropertyList = async () => {
    const body = {
      searchDevelopment: '',
      developmentIds: [],
      buildingIds: [],
      clusterIds: [],
      unitGroupIds: [],
    }
    await ApiPost(`corporate/vendor/get/development`, body)
      .then((res) => {
        let values = [...res?.data?.data]
        res?.data?.data?.map((res: any, ind: any) => {
          values[ind].check = false
        })

        for (let j = 0; j < res?.data?.data?.length; j++) {
          let isCheckForDev = false
          let isCheckForAll = false
          let isChckforDevClust = false
          let isCheckForDevUnitGrp = false

          if (
            values[j]?._id != undefined &&
            values[j]?.cluster?._id == undefined &&
            values[j]?.unitGroup?._id == undefined
          )
            isCheckForDev = true
          else if (
            values[j]?._id != undefined &&
            values[j]?.cluster?._id != undefined &&
            values[j]?.unitGroup?._id != undefined
          )
            isCheckForAll = true
          else if (
            values[j]?._id != undefined &&
            values[j]?.cluster?._id != undefined &&
            values[j]?.unitGroup?._id == undefined
          )
            isChckforDevClust = true
          else if (
            values[j]?._id != undefined &&
            values[j]?.cluster?._id == undefined &&
            values[j]?.unitGroup?._id != undefined
          )
            isCheckForDevUnitGrp = true

          // Dev only
          if (isCheckForDev) {
            let x = addedProperties?.developmentIds?.includes(values[j]?._id)

            if (x) values[j].check = true
          }
          // Dev, Clust and Unit Grouo
          else if (isCheckForAll) {
            let x = addedProperties?.developmentIds?.includes(values[j]?._id)

            let y = addedProperties?.clusterIds?.includes(values[j]?.cluster?._id)

            let z = addedProperties?.unitGroupIds?.includes(values[j]?.unitGroup?._id)

            if (x && y && z) values[j].check = true
          }
          // Dev and Clust
          else if (isChckforDevClust) {
            let x = addedProperties?.developmentIds?.includes(values[j]?._id)

            let y = addedProperties?.clusterIds?.includes(values[j]?.cluster?._id)

            if (x && y) values[j].check = true
          }

          // Dev and Unit Group
          else if (isCheckForDevUnitGrp) {
            let x = addedProperties?.developmentIds?.includes(values[j]?._id)

            let z = addedProperties?.unitGroupIds?.includes(values[j]?.unitGroup?._id)
            if (x && z) values[j].check = true
          }
        }

        // console.log(values)

        handlePropertySelection(values)
      })
      .catch((e) => {
        ErrorToast(e?.message)
      })
  }

  useEffect(() => {
    if (added) updatePropertyList()
  }, [added])


  useFancybox({
    dependencies: [] // Add any dependencies that should trigger a re-initialization
  })

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form onSubmit={handleSubmit((data: any, e: any) => updateVendor(e, false))}>
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              <div className=' d-flex flex-stack pt-1 mt-0 px-0'>
                {' '}
                {/* container-xxl */}
                <div className=''>
                  <div className='d-flex align-items-center mb-1'>
                    <span
                      className='rounded-circle cursor-pointer'
                      onClick={() => {
                        navigate('/vendor-management')
                      }}
                    >
                      <img src={backArrow} height={14} width={14} />
                    </span>
                    <h2 className='m-0 head-text ms-2'>{!isEdit ? 'Vendor' : 'Edit Vendor'}</h2>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  {!isEdit ? (
                    <>
                      <img
                        src={pencil}
                        alt=''
                        height={19}
                        width={19}
                        className='ms-4 me-1 cursor-pointer'
                        onClick={() => {
                          setIsEdit(true)
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold  mx-3 red-hollow-btn'
                          onClick={() => {
                            setAdded(false)
                            getVendorById()
                            // setIsEdit(false)
                          }}
                          disabled={isLoading}
                        >
                          <img src={redCross} height={18} width={18} className='me-3' /> Cancel
                        </button>

                        <button
                          type='submit'
                          className='btn btn-sm fw-bold mx-3 green-hollow-btn'
                          disabled={isLoading}
                        >
                          <img src={saveGreenImg} height={18} width={18} className='me-3' /> {'  '}
                          Save
                        </button>
                      </>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='card card-flush py-5 mt-5'>
              <div className='row mx-1'>
                {/* Left column - Tabs */}
                <div className='col-7 px-3'>
                  {/* Left Section */}
                  <>
                    <div className='row mx-4 mt-4'>
                      <h2 className='top ps-0 white-dark-theme-color'>
                        <b>Company Details</b>
                      </h2>
                      <div className='col-12 px-5  ps-0 my-5 mt-6'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              {' '}
                              Type
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>
                                  {vendorData?.vendorType == 0
                                    ? 'Service Provider'
                                    : vendorData?.vendorType == 1
                                    ? 'Supplier'
                                    : '-'}
                                </b>
                              </span>
                            )}
                          </h4>
                          {isEdit && (
                            <h4 style={{width: '-webkit-fill-available'}}>
                              <Controller
                                name='vendorType'
                                control={control}
                                rules={{
                                  required: 'This field is required',
                                }}
                                render={({field}) => (
                                  <Select
                                    defaultValue={
                                      vendorData?.vendorType == 0
                                        ? vendorTypeOptions[0]
                                        : vendorData?.vendorType == 1
                                        ? vendorTypeOptions[1]
                                        : []
                                    }
                                    value={
                                      vendorData?.vendorType == 0
                                        ? vendorTypeOptions[0]
                                        : vendorData?.vendorType == 1
                                        ? vendorTypeOptions[1]
                                        : []
                                    }
                                    styles={customStyles}
                                    isSearchable={false}
                                    options={vendorTypeOptions}
                                    name='vendorType'
                                    onChange={(e: any) => {
                                      setVendorData({
                                        ...vendorData,
                                        ['vendorType']: e.value,
                                      })

                                      setValue('vendorType', e.value)

                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 700)
                                    }}
                                    placeholder={'Select'}
                                  />
                                )}
                              />
                              {errors.vendorType && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </h4>
                          )}
                        </div>
                      </div>

                      {/* Name */}
                      <div className='col-12 px-5  ps-0 my-5'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              {' '}
                              Name
                            </label>

                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>{vendorData?.companyName ? vendorData?.companyName : '-'}</b>
                              </span>
                            )}
                          </h4>
                          {isEdit && (
                            <h4 style={{width: '-webkit-fill-available'}}>
                              <input
                                {...register('companyName', {required: true})}
                                type='text'
                                className='form-control form-control-solid mytest w-50'
                                placeholder='Enter Company Name'
                                 autoComplete="off"
                                name='companyName'
                                value={vendorData?.companyName}
                                onChange={(e) => {
                                  setVendorData({
                                    ...vendorData,
                                    [e.target.name]: capitalizeFirstLetter(e.target.value),
                                  })
                                  setValue('companyName', capitalizeFirstLetter(e.target.value))
                                }}
                                onBlur={() =>
                                  setTimeout(() => {
                                    setAutoSaveData(Date.now())
                                  }, 500)
                                }
                              />

                              {errors.companyName && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </h4>
                          )}
                        </div>
                      </div>

                      {/* Trade License */}
                      <div className='col-12 px-5  ps-0 my-5'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              {' '}
                              Trade License
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>
                                  {vendorData?.tradeLicenseNo ? vendorData?.tradeLicenseNo : '-'}
                                </b>
                              </span>
                            )}
                          </h4>
                          {isEdit && (
                            <h4 style={{width: '-webkit-fill-available'}}>
                              <input
                                {...register('tradeLicenseNo', {required: true})}
                                type='text'
                                className='form-control form-control-solid mytest w-50'
                                placeholder='Enter No.'
                                name='tradeLicenseNo'
                                value={vendorData?.tradeLicenseNo}
                                onChange={(e) => {
                                  setVendorData({
                                    ...vendorData,
                                    [e.target.name]: e.target.value,
                                  })

                                  setValue('tradeLicenseNo', e.target.value)
                                }}
                                onBlur={() =>
                                  setTimeout(() => {
                                    setAutoSaveData(Date.now())
                                  }, 500)
                                }
                              />

                              {errors.tradeLicenseNo && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </h4>
                          )}
                        </div>
                      </div>

                      <div className='col-12 px-5 ps-0 my-5'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label className='labl-gry status-w-130'>
                              {' '}
                            </label>
                          </h4>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label className='labl-gry ms-1 status-w-130'>
                              {' '}
                              Attachments
                            </label>
                          </h4>
                          {isEdit && (
                            <>
                              <label
                                title='Add New File'
                                htmlFor='uploadNewTenant'
                                className='btn btn-sm fw-bold upload-btn ms-auto'
                              >
                                <img src={upload} height={18} width={18} className='me-3' />
                                Upload File{' '}
                              </label>
                              <input
                                type='file'
                                hidden
                                id='uploadNewTenant'
                                className='form-control form-control-solid'
                                placeholder='upload'
                                name='newTenant'
                                onChange={(e: any) => {
                                  handleChange(e)
                                }}
                                multiple
                              />
                            </>
                          )}
                        </div>
                      </div>

                      <div className='col-12 px-5  ps-0 my-5'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label className='labl-gry status-w-130'>
                              {' '}
                            </label>
                          </h4>
                          {attachments?.length > 0 ? (
                            <div
                              className='tops d-flex gap-5 align-items-start mx-5 flex-wrap'
                              style={{maxHeight: '350px', overflowY: 'auto', overflowX: 'hidden'}}
                            >
                              {attachments?.map((file: any, ind: any) => {
                                file.URL = file?.image
                                return (
                                  <>
                                    <div className='imgs-set'>
                                      <div className='d-flex mw-350px me-3 '>
                                        {/* <a target='_blank' href={`${Bucket}${file?.image}`}> */}
                                          <img
                                            // src={
                                            //   file?.image?.split('.')[1] === 'pdf'
                                            //     ? pdfDocument
                                            //     : file?.image?.split('.')[1] === 'doc' ||
                                            //       file?.image?.split('.')[1] === 'docx'
                                            //     ? docCocument
                                            //     : file?.image?.split('.')[1] === 'jpeg' ||
                                            //       file?.image?.split('.')[1] === 'jpg' ||
                                            //       file?.image?.split('.')[1] === 'png' ||
                                            //       file?.image?.split('.')[1] === 'svg' ||
                                            //       file?.image?.split('.')[1] === 'gif'
                                            //     ? `${Bucket}${file?.image}`
                                            //     : fileIcon
                                            // }
                                            src={getFileIcon(file?.URL)}
                                            width='100'
                                            height='100'
                                            className='main_img mb-5 cursor-pointer'
                                            // data-fancybox
                                            id={`clickedAttachments${ind}`}
                                            key={`clickedAttachments${ind}`}
                                            onClick={(e: any) => {
                                              handleImageClick(attachments?.map((tempFile:any) => ({URL:tempFile?.image,...tempFile})),'group_attachments_others','clickedAttachments')
                                            }}
                                            data-src={`${Bucket}${file?.URL}`}
                                          />
                                        {/* </a> */}
                                        {isEdit && (
                                          <img
                                            src={redCross}
                                            height={15}
                                            width={15}
                                            className='cursor-pointer ms-2 mt-1'
                                            onClick={() => {
                                              const updateAttachment = [...attachments]
                                              updateAttachment?.splice(ind,1)
                                              setAttachments(updateAttachment)
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )
                              })}
                            </div>
                          )
                        :
                        <i className='i_grey ms-1'>No attachments available</i>}

                        </div>
                      </div>

                      {/* TRN */}
                      <div className='col-12 px-5  ps-0 my-5'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              {' '}
                              TRN
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>{vendorData?.TRN ? vendorData?.TRN : '-'}</b>
                              </span>
                            )}
                          </h4>
                          {isEdit && (
                            <h4 style={{width: '-webkit-fill-available'}}>
                              <input
                                {...register('TRN', {required: true})}
                                type='number'
                                className='form-control form-control-solid mytest w-50'
                                placeholder='Enter Tax Registration No.'
                                name='TRN'
                                value={vendorData?.TRN}
                                onChange={(e) => {
                                  setVendorData({
                                    ...vendorData,
                                    [e.target.name]: e.target.value,
                                  })

                                  setValue('TRN', e.target.value)
                                }}
                                onBlur={() =>
                                  setTimeout(() => {
                                    setAutoSaveData(Date.now())
                                  }, 500)
                                }
                              />

                              {errors.TRN && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </h4>
                          )}
                        </div>
                      </div>

                      <>
                        {country?.map((con: any, ind: any) => {
                          if (con?.country == vendorData?.country) {
                            countryInd = ind
                            console.log(countryInd)
                          }
                          return <></>
                        })}
                      </>

                      {/* Country  */}
                      <div className='col-12 px-5  ps-0 my-5'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              {' '}
                              Country
                            </label>

                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>{vendorData?.country ? vendorData?.country : '-'}</b>
                              </span>
                            )}
                          </h4>
                          {isEdit && (
                            <h4 style={{width: '-webkit-fill-available'}}>
                              <Controller
                                name='country'
                                control={control}
                                rules={{
                                  required: 'This field is required',
                                }}
                                render={({field}) => (
                                  <Select
                                    defaultValue={
                                      vendorData?.country != null &&
                                      vendorData?.country != undefined
                                        ? countryOptions[countryInd]
                                        : []
                                    }
                                    value={
                                      vendorData?.country != null &&
                                      vendorData?.country != undefined
                                        ? countryOptions[countryInd]
                                        : []
                                    }
                                    styles={customStyles}
                                    isSearchable={false}
                                    options={countryOptions}
                                    name='country'
                                    onChange={(e: any) => {
                                      setVendorData({
                                        ...vendorData,
                                        ['country']: e.value,
                                      })

                                      setValue('country', e.value)

                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 700)
                                    }}
                                  />
                                )}
                              />

                              {errors.country && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </h4>
                          )}
                        </div>
                      </div>

                      <>
                        {cityOptions?.map((con: any, ind: any) => {
                          if (con?.value == vendorData?.city) {
                            cityInd = ind
                          }
                          return <></>
                        })}
                      </>

                      {/* City  */}
                      <div className='col-12 px-5  ps-0 my-5'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              {' '}
                              City
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>{vendorData?.city ? vendorData?.city : '-'}</b>
                              </span>
                            )}
                          </h4>
                          {isEdit && (
                            <h4 style={{width: '-webkit-fill-available'}}>
                              <Controller
                                name='city'
                                control={control}
                                rules={{
                                  required: 'This field is required',
                                }}
                                render={({field}) => (
                                  <Select
                                    defaultValue={
                                      vendorData?.city != null && vendorData?.city != undefined
                                        ? cityOptions[countryInd]
                                        : []
                                    }
                                    value={
                                      vendorData?.city != null && vendorData?.city != undefined
                                        ? cityOptions[cityInd]
                                        : []
                                    }
                                    styles={customStyles}
                                    isSearchable={false}
                                    options={cityOptions}
                                    name='city'
                                    onChange={(e: any) => {
                                      setVendorData({
                                        ...vendorData,
                                        ['city']: e.value,
                                      })
                                      setCity(e.value)

                                      setValue('city', e.value)

                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 700)
                                    }}
                                  />
                                )}
                              />

                              {errors.city && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </h4>
                          )}
                        </div>
                      </div>

                      <>
                        {areaOptions?.map((con: any, ind: any) => {
                          if (con?.value == vendorData?.area) {
                            areaInd = ind
                          }
                          return <></>
                        })}
                      </>

                      {/* Area  */}
                      <div className='col-12 px-5  ps-0 my-5'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              {' '}
                              Area
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>{vendorData?.area ? vendorData?.area : '-'}</b>
                              </span>
                            )}
                          </h4>
                          {isEdit && (
                            <h4 style={{width: '-webkit-fill-available'}}>
                              <Controller
                                name='area'
                                control={control}
                                rules={{
                                  required: 'This field is required',
                                }}
                                render={({field}) => (
                                  <Select
                                    defaultValue={
                                      vendorData?.area != null && vendorData?.area != undefined
                                        ? areaOptions[areaInd]
                                        : []
                                    }
                                    value={
                                      vendorData?.area != null && vendorData?.area != undefined
                                        ? areaOptions[areaInd]
                                        : []
                                    }
                                    styles={customStyles}
                                    isSearchable={false}
                                    options={areaOptions}
                                    name='area'
                                    onChange={(e: any) => {
                                      setVendorData({
                                        ...vendorData,
                                        ['area']: e.value,
                                      })

                                      setValue('area', e.value)

                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 700)
                                    }}
                                  />
                                )}
                              />

                              {errors.area && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </h4>
                          )}
                        </div>
                      </div>

                      {/* Address 01 */}
                      <div className='col-12 px-5  ps-0 my-5'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              {' '}
                              Address 1
                            </label>

                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>{vendorData?.address1 ? vendorData?.address1 : '-'}</b>
                              </span>
                            )}
                          </h4>
                          {isEdit && (
                            <h4 style={{width: '-webkit-fill-available'}}>
                              <input
                                {...register('address1', {required: true})}
                                type='text'
                                className='form-control form-control-solid mytest w-50'
                                placeholder=''
                                name='address1'
                                 autoComplete="off"
                                value={vendorData?.address1}
                                onChange={(e) => {
                                  setVendorData({
                                    ...vendorData,
                                    [e.target.name]: capitalizeFirstLetter(e.target.value),
                                  })
                                  setValue('address1', capitalizeFirstLetter(e.target.value))
                                }}
                                onBlur={() =>
                                  setTimeout(() => {
                                    setAutoSaveData(Date.now())
                                  }, 500)
                                }
                              />

                              {errors.address1 && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </h4>
                          )}
                        </div>
                      </div>

                      {/* Address 02 */}
                      <div className='col-12 px-5  ps-0 my-5'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              {' '}
                              Address 2
                            </label>

                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>{vendorData?.address2 ? vendorData?.address2 : '-'}</b>
                              </span>
                            )}
                          </h4>
                          {isEdit && (
                            <h4 style={{width: '-webkit-fill-available'}}>
                              <input
                                {...register('address2', {required: true})}
                                type='text'
                                className='form-control form-control-solid mytest w-50'
                                placeholder=''
                                name='address2'
                                 autoComplete="off"
                                value={vendorData?.address2}
                                onChange={(e) => {
                                  setVendorData({
                                    ...vendorData,
                                    [e.target.name]: capitalizeFirstLetter(e.target.value),
                                  })
                                  setValue('address2', capitalizeFirstLetter(e.target.value))
                                }}
                                onBlur={() =>
                                  setTimeout(() => {
                                    setAutoSaveData(Date.now())
                                  }, 500)
                                }
                              />

                              {errors.address2 && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </h4>
                          )}
                        </div>
                      </div>

                      {/* Email */}
                      <div className='col-12 px-5  ps-0 my-5'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              {' '}
                              Email
                            </label>

                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>{vendorData?.email ? vendorData?.email : '-'}</b>
                              </span>
                            )}
                          </h4>
                          {isEdit && (
                            <h4 style={{width: '-webkit-fill-available'}}>
                              <Controller
                                name='email'
                                control={control}
                                rules={{
                                  required: 'Email is required',
                                  pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Invalid email address',
                                  },
                                }}
                                render={({field}) => (
                                  <input
                                    {...register('email', {required: true})}
                                    type='text'
                                    className='form-control form-control-solid mytest w-50'
                                    placeholder=''
                                    name='email'
                                     autoComplete="off"
                                    value={vendorData?.email}
                                    onChange={(e) => {
                                      setVendorData({
                                        ...vendorData,
                                        [e.target.name]: e.target.value,
                                      })
                                      setValue('email', e.target.value)
                                    }}
                                    onBlur={() =>
                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 500)
                                    }
                                  />
                                )}
                              />

                              {errors.email && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  Please enter a valid email address
                                </p>
                              )}
                            </h4>
                          )}
                        </div>
                      </div>

                      {/* Mobile No. */}
                      <div className='col-12  px-5  ps-0 my-5 '>
                        <div className='d-flex  vendor'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              {' '}
                              Mobile No.
                            </label>

                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>
                                  {vendorData?.phoneNumber
                                    ? `+${vendorData?.countryCode} ${vendorData?.phoneNumber}`
                                    : '-'}
                                </b>
                              </span>
                            )}
                          </h4>
                          {isEdit && (
                            <h4 style={{width: '-webkit-fill-available'}}>
                              <Controller
                                name='phoneNumber'
                                control={control}
                                rules={{
                                  required: 'This field is required',
                                }}
                                render={({field}) => (
                                  <PhoneInput
                                    placeholder='Enter phone number'
                                    value={`${vendorData?.countryCode} ${vendorData?.phoneNumber}`}
                                    onChange={(phone: any, e: any) => {
                                      let CountryCode: any = e?.dialCode
                                      let PhoneNumber: any = phone.split(CountryCode)[1]
                                        ? phone.split(CountryCode)[1]
                                        : ''

                                      setVendorData({
                                        ...vendorData,
                                        ['countryCode']:
                                          phone?.trim()?.length > 0 ? CountryCode : '',
                                        ['phoneNumber']: PhoneNumber ? PhoneNumber : '',
                                      })

                                      setValue(
                                        'phoneNumber',
                                        `${
                                          phone?.trim()?.length > 0 ? CountryCode : ''
                                        }${PhoneNumber}`
                                      )
                                    }}
                                    onBlur={() => {
                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 700)
                                    }}
                                  />
                                )}
                              />

                              {errors.phoneNumber && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                  {/* {errors.email.message} */}
                                </p>
                              )}
                            </h4>
                          )}
                        </div>
                      </div>

                      {/* Landline No */}
                      <div className='col-12 px-5  ps-0 my-5'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              {' '}
                              Landline No
                            </label>

                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>
                                  {vendorData?.landlineNumber ? vendorData?.landlineNumber : '-'}
                                </b>
                              </span>
                            )}
                          </h4>
                          {isEdit && (
                            <h4 style={{width: '-webkit-fill-available'}}>
                              <input
                                {...register('landlineNumber', {required: true})}
                                type='number'
                                className='form-control form-control-solid mytest w-50'
                                placeholder=''
                                name='landlineNumber'
                                value={vendorData?.landlineNumber}
                                onChange={(e) => {
                                  setVendorData({
                                    ...vendorData,
                                    [e.target.name]: e.target.value,
                                  })

                                  setValue('landlineNumber', e.target.value)
                                }}
                              />
                              {errors.landlineNumber && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </h4>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                </div>

                {/* Right Column */}

                <div className='col-5 px-5'>
                  {/* Assign Prperties Modal */}
                  <div
                    className={
                      hide
                        ? 'card card-flush py-5 min-property mt-2 min-res pe-5 mb-5 px-3'
                        : 'card card-flush py-5 max-property mt-2 min-card pe-5 mb-5 px-3'
                    }
                    style={{position: 'relative', zIndex: '10'}}
                  >
                    <div className='d-flex  align-items-center'>
                      <h2 className='ms-3 white-dark-theme-color fnt-700'>
                        {!isEdit ? 'Properties' : 'Assign Properties'}
                      </h2>
                      <h3 className='mb-2 ms-auto fnt-500 light-dark-theme-color'>
                        <i>
                          <b>{properties?.length}</b>
                        </i>
                      </h3>
                    </div>

                    {properties?.map((user: any, ind: any) => {
                      if (user?.check == true) propertiesSelected = propertiesSelected + 1
                      return <></>
                    })}

                    {isEdit && (
                      <div className='d-flex mt-3 align-items-center'>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold ms-3 ps-4 text-white select-btn'
                          onClick={(event) => {
                            setSelectModal(true)
                          }}
                        >
                          <img src={homeImg} height={19} width={19} className='me-2' />{' '}
                          {'  '}
                          Select
                        </button>

                        <div className='ms-auto'>
                          <h4 className='mb-2 white-dark-theme-color fnt-500'>
                            <i>
                              Total Selected <b>{propertiesSelected}</b>
                            </i>
                          </h4>
                        </div>
                      </div>
                    )}
                    {properties?.length > 0 && (
                      <div className={`'table-responsive ms-3 ${isEdit ? 'mt-5' : 'mt-2'} ${hide ? 'max-h-270' : 'max-h-400'}`}>
                        <table
                          className='table align-middle fs-8 gy-5 '
                          id='kt_ecommerce_sales_table'
                        >
                          {isEdit && (
                            <thead>
                              <th className='text-start min-w-25px test'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                  <input
                                    className='form-check-input cursor-pointer'
                                    type='checkbox'
                                    id='vacant0'
                                    name='none'
                                    checked={
                                      propertiesSelected == properties?.length &&
                                      properties?.length != 0
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => {
                                      let values: any = [...properties]
                                      if (e.target.checked) {
                                        values?.map((user: any) => {
                                          user.check = true
                                        })
                                      } else {
                                        values?.map((user: any) => {
                                          user.check = false
                                        })
                                      }
                                      setProperties(values)
                                    }}
                                  />
                                </div>
                              </th>

                              <th className='text-start min-w-150px'>Properties Selected</th>
                              <th className='text-end min-w-75px'>
                                {propertiesSelected > 0 && (
                                  <a
                                    className='btn btn-sm fw-bold  ms-3 remove-btn ps-2 ms-auto'
                                    onClick={() => {
                                      let values = [...properties]
                                      // Filter out the Properties with checkboxes checked
                                      const filteredProperties = values.filter(
                                        (user) => !user.check
                                      )
                                      setProperties(filteredProperties)
                                    }}
                                  >
                                    <img src={crossBtn} height={18} width={18} className='me-4' />{' '}
                                    Remove
                                  </a>
                                )}
                              </th>
                            </thead>
                          )}
                          <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                            {properties?.length > 0 &&
                              properties?.map((prop: any, ind: any) => {
                                return (
                                  <>
                                    <tr key={ind}>
                                      {isEdit && (
                                        <td className='text-start ps-0 test'>
                                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                            <input
                                              className='form-check-input cursor-pointer'
                                              type='checkbox'
                                              id='vacant0'
                                              name='none'
                                              checked={properties[ind]?.check}
                                              onChange={(e: any) => {
                                                const values = [...properties]
                                                values[ind].check = e.target.checked
                                                setProperties(values)
                                              }}
                                            />
                                          </div>
                                        </td>
                                      )}
                                      <td className='text-start ps-0'>
                                        <div className='d-flex head-text align-items-center'>
                                          {!isEdit && (
                                            <div
                                              className='bullet-div me-2'
                                              style={{
                                                backgroundColor: `#146c6a`,
                                              }}
                                            ></div>
                                          )}
                                          <p className='user-head pe-2 mb-0'>
                                            {' '}
                                            {prop?.name}{' '}
                                            {prop?.clusterName != undefined &&
                                              prop?.clusterName != undefined &&
                                              ` - ${prop?.clusterName}`}{' '}
                                            {prop?.unitGroupName != undefined &&
                                              prop?.unitGroupName != 'undefined' &&
                                              ` - ${prop?.unitGroupName}`}
                                          </p>
                                        </div>
                                      </td>
                                      {isEdit && (
                                        <td className='text-end ps-0'>
                                          <img
                                            src={redCross}
                                            height={16}
                                            width={16}
                                            className='me-4 cursor-pointer'
                                            onClick={() => {
                                              let values = [...properties]
                                              values.splice(ind, 1)
                                              setProperties(values)
                                            }}
                                          />
                                        </td>
                                      )}
                                    </tr>
                                  </>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <div className='ms-auto mt-auto' style={{height: '30px'}}>
                      <div
                        className='br-arr d-flex align-items-center justify-content-center mt-3'
                        // style={{transform: 'translate(0px, -25px)'}}
                      >
                        {hide === false ? (
                          <img
                            src={upGreen}
                            height={20}
                            width={20}
                            className='cursor-pointer'
                            onClick={() => {
                              setHide(true)
                            }}
                          />
                        ) : (
                          <img
                            src={downGreen}
                            height={20}
                            width={20}
                            className='cursor-pointer'
                            onClick={() => {
                              setHide(false)
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Lists */}
                  <div
                    className={
                      hideList
                        ? 'card card-flush py-5 min-property mt-2 min-res pe-5 mt-10 px-3'
                        : 'card card-flush py-5 max-property mt-2 min-card pe-5 mt-10 px-3'
                    }
                    style={{position: 'relative', zIndex: '10'}}
                  >
                    <div className='d-flex align-items-center'>
                      <h2 className='ms-3 white-dark-theme-color fnt-700'>
                        {'Lists'}
                      </h2>
                      <h3 className='mb-2 ms-auto fnt-500 light-dark-theme-color'>
                        <i>
                          <b>{lists?.length}</b>
                        </i>
                      </h3>
                    </div>

                    {lists?.map((list: any, ind: any) => {
                      if (list?.check == true) listsSelected = listsSelected + 1
                      return <></>
                    })}

                    {isEdit && (
                      <div className='d-flex mt-3 align-items-center'>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold ms-3 ps-4 text-white select-btn status-w-120 ps-3'
                          onClick={(event) => {
                            setSelectListModal(true)
                          }}
                        >
                          <img
                            src={selectListImg}
                            height={19}
                            width={19}
                            className='me-2'
                          />{' '}
                          {'  '}
                          Select Lists
                        </button>

                        <div className='ms-auto'>
                          <h4 className='mb-2 white-dark-theme-color fnt-500'>
                            <i>
                              Total Selected <b>{listsSelected}</b>
                            </i>
                          </h4>
                        </div>
                      </div>
                    )}
                    {lists?.length > 0 && (
                      <div className={`'table-responsive ${isEdit ? 'mt-5' : 'mt-2'} ${hideList ? 'max-h-270' : 'max-h-400'}`}>
                        <table
                          className='table align-middle  fs-8 gy-5 '
                          id='kt_ecommerce_sales_table'
                        >
                          {isEdit && (
                            <thead>
                              <th className='text-start min-w-25px test'>
                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                                  <input
                                    className='form-check-input cursor-pointer'
                                    type='checkbox'
                                    id='vacant0'
                                    name='none'
                                    checked={
                                      listsSelected == lists?.length && lists?.length != 0
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) => {
                                      let values: any = [...lists]
                                      if (e.target.checked) {
                                        values?.map((user: any) => {
                                          user.check = true
                                        })
                                      } else {
                                        values?.map((user: any) => {
                                          user.check = false
                                        })
                                      }
                                      setLists(values)
                                    }}
                                  />
                                </div>
                              </th>

                              <th className='text-start min-w-150px ps-3'>Lists Selected</th>
                              <th className='text-end min-w-75px'>
                                {listsSelected > 0 && (
                                  <a
                                    className='btn btn-sm fw-bold  ms-3 remove-btn ps-2 ms-auto'
                                    onClick={() => {
                                      let values = [...lists]
                                      // Filter out the Properties with checkboxes checked
                                      const filteredLists = values.filter((list) => !list.check)
                                      setLists(filteredLists)
                                    }}
                                  >
                                    <img src={crossBtn} height={18} width={18} className='me-4' />{' '}
                                    Remove
                                  </a>
                                )}
                              </th>
                            </thead>
                          )}
                          <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                            {lists?.length > 0 &&
                              lists?.map((list: any, ind: any) => {
                                return (
                                  <>
                                    <tr key={ind}>
                                      {isEdit && (
                                        <td className='text-start ps-0 test'>
                                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-3 pe-5'>
                                            <input
                                              className='form-check-input cursor-pointer'
                                              type='checkbox'
                                              id='vacant0'
                                              name='none'
                                              checked={lists[ind]?.check}
                                              onChange={(e: any) => {
                                                const values = [...lists]
                                                values[ind].check = e.target.checked
                                                setLists(values)
                                              }}
                                            />
                                          </div>
                                        </td>
                                      )}
                                      <td className='text-start'>
                                        <div className='d-flex head-text align-items-center ms-0'>
                                          <div
                                            className='bullet-div'
                                            style={{backgroundColor: `${list?.colorCode}`}}
                                          ></div>
                                          <p className='user-head pe-2 mb-0 ps-3'>{list.name}</p>
                                        </div>
                                      </td>
                                      {isEdit && (
                                        <td className='text-end ps-0'>
                                          <img
                                            src={redCross}
                                            height={16}
                                            width={16}
                                            className='me-4 cursor-pointer'
                                            onClick={() => {
                                              let values = [...lists]
                                              values.splice(ind, 1)
                                              setLists(values)
                                            }}
                                          />
                                        </td>
                                      )}
                                    </tr>
                                  </>
                                )
                              })}
                          </tbody>
                        </table>
                      </div>
                    )}

                    <div className='ms-auto mt-auto' style={{height: '30px'}}>
                      <div
                        className='br-arr d-flex align-items-center justify-content-center mt-3'
                        // style={{transform: 'translate(0px, -25px)'}}
                      >
                        {hideList === false ? (
                          <img
                            src={upGreen}
                            height={20}
                            width={20}
                            className='cursor-pointer'
                            onClick={() => {
                              setHideList(true)
                            }}
                          />
                        ) : (
                          <img
                            src={downGreen}
                            height={20}
                            width={20}
                            className='cursor-pointer'
                            onClick={() => {
                              setHideList(false)
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mx-2'>
                {(contactInformation?.length > 0 || isEdit) && (
                  <div className='d-flex mx-3 mt-10 align-items-center mb-4'>
                    <h2 className='top ps-0 white-dark-theme-color'>
                      <b>Contact Details </b>
                    </h2>
                    {/* {isEdit && (
                    <button
                      type='button'
                      className='btn btn-sm fw-bold  ms-auto select-btn'
                      onClick={(e: any) => {
                        handleAddContactInformation()
                      }}
                    >
                      <img src={addWhite} height={18} width={18} className='me-2' /> Add
                    </button>
                  )} */}
                  </div>
                )}

                {contactInformation?.length > 0 && (
                  <div className='table-responsive vendor ps-5' style={{overflow: 'unset'}}>
                    <table className='table align-start  fs-4 gy-5' id='kt_ecommerce_sales_table'>
                      <thead>
                        <tr className='text-start text-gray-400 fw-bold fs-4 text-uppercase gs-0'>
                          <th className='text-start min-w-100px py-2'>First Name</th>
                          <th className='text-start min-w-100px py-2'>Last Name</th>
                          <th className='text-start min-w-150px py-2'>Email </th>
                          <th className='text-start min-w-100px py-2'>Mobile No.</th>
                          {/* {isEdit && <th className='text-end min-w-25px'></th>} */}
                        </tr>
                      </thead>

                      <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                        {contactInformation.map((sb: any, i: any) => {
                          return (
                            <>
                              <tr className='mt-2'>
                                <td className='text-start '>
                                  {!isEdit ? (
                                    <>{sb?.firstName}</>
                                  ) : (
                                    <>
                                      <input
                                        {...register('firstName', {required: true})}
                                        type='text'
                                        className='form-control form-control-solid mytest '
                                        placeholder=''
                                        name='firstName'
                                         autoComplete="off"
                                        value={sb?.firstName}
                                        onChange={(e) => {
                                          let values = [...contactInformation]
                                          values[i].firstName = capitalizeFirstLetter(e.target.value)
                                          setContactInformation(values)

                                          setValue('firstName', capitalizeFirstLetter(e.target.value))
                                        }}
                                      />

                                      {errors.firstName && (
                                        <p
                                          style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                          className='m-0 mt-1'
                                        >
                                          This field is required
                                        </p>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td className='text-start '>
                                  {!isEdit ? (
                                    <>{sb?.lastName}</>
                                  ) : (
                                    <>
                                      <input
                                        {...register('lastName', {required: true})}
                                        type='text'
                                        className='form-control form-control-solid mytest '
                                        placeholder=''
                                        name='lastName'
                                         autoComplete="off"
                                        value={sb?.lastName}
                                        onChange={(e) => {
                                          let values = [...contactInformation]
                                          values[i].lastName = capitalizeFirstLetter(e.target.value)
                                          setContactInformation(values)

                                          setValue('lastName', capitalizeFirstLetter(e.target.value))
                                        }}
                                      />

                                      {errors.lastName && (
                                        <p
                                          style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                          className='m-0 mt-1'
                                        >
                                          This field is required
                                        </p>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td className='text-start'>
                                  {!isEdit ? (
                                    <>{sb?.email}</>
                                  ) : (
                                    <>
                                      <Controller
                                        name='contactEmail'
                                        control={control}
                                        rules={{
                                          required: 'Email is required',
                                          pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email address',
                                          },
                                        }}
                                        render={({field}) => (
                                          <input
                                            {...register('contactEmail', {required: true})}
                                            type='text'
                                            className='form-control form-control-solid mytest '
                                            placeholder=''
                                            name='contactEmail'
                                             autoComplete="off"
                                            value={sb?.email}
                                            onChange={(e) => {
                                              let values = [...contactInformation]
                                              values[i].email = e.target.value
                                              setContactInformation(values)

                                              setValue('contactEmail', e.target.value)
                                            }}
                                            disabled
                                          />
                                        )}
                                      />

                                      {errors.contactEmail && (
                                        <p
                                          style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                          className='m-0 mt-1'
                                        >
                                          Please enter a valid email address
                                        </p>
                                      )}
                                    </>
                                  )}{' '}
                                </td>
                                <td className='text-start vendor2 '>
                                  {!isEdit ? (
                                    <>
                                      {`+${sb?.phoneNumber}`}
                                    </>
                                  ) : (
                                    <>
                                      <Controller
                                        name='contactPhone'
                                        control={control}
                                        rules={{
                                          required: 'This field is required',
                                        }}
                                        render={({field}) => (
                                          <PhoneInput
                                            placeholder='Enter phone number'
                                            value={`${sb?.phoneNumber}`}
                                            onChange={(phone: any, e: any) => {
                                              let CountryCode: any = e?.dialCode
                                              let PhoneNumber: any = phone.split(CountryCode)[1]
                                                ? phone.split(CountryCode)[1]
                                                : ''

                                              let values = [...contactInformation]
                                              values[i].phoneNumber = `${
                                                phone?.trim()?.length > 0 ? CountryCode : ''
                                              }${PhoneNumber}`
                                              setContactInformation(values)

                                              setValue('contactPhone', values[i].phoneNumber)
                                            }}
                                            onBlur={() => {
                                              setTimeout(() => {
                                                setAutoSaveData(Date.now())
                                              }, 700)
                                            }}
                                          />
                                        )}
                                      />

                                      {errors.contactPhone && (
                                        <p
                                          style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                          className='m-0 mt-1'
                                        >
                                          This field is required
                                          {/* {errors.email.message} */}
                                        </p>
                                      )}
                                    </>
                                  )}{' '}
                                </td>
                                {/* {isEdit && (
                                  <td className='text-end '>
                                    <img
                                      src={redCross}
                                      height={16}
                                      width={16}
                                      style={{cursor: 'pointer'}}
                                      onClick={() => {
                                        swal
                                          .fire({
                                            text: 'Are you sure you want to remove this contact from the contacts List ?',
                                            icon: 'warning',
                                            showConfirmButton: true,
                                            confirmButtonColor: '#D72852',
                                            confirmButtonText: 'Yes',
                                            showCancelButton: true,
                                            // cancelButtonColor: "transparent",
                                            cancelButtonText: 'Cancel',
                                          })
                                          .then((res) => {
                                            if (res.isConfirmed) {
                                              // deleteTenant(v?._id, 1)
                                              let temp = [...contactInformation]
                                              temp.splice(i, 1)
                                              setContactInformation(temp)
                                            }
                                          })
                                      }}
                                    />{' '}
                                  </td>
                                )} */}
                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* Select Properties Modal */}
      {selectModal && (
        <SelectPropertyModal
          show={selectModal}
          handleClose={() => {
            setSelectModal(false)
          }}
          handleSubmitId={(data: any) => {
            handlePropertySelection(data)
          }}
          addedProperties={properties}
        />
      )}

      {/* Select LISTS MODAL */}
      {selectListModal && (
        <SelectListModal
          show={selectListModal}
          handleClose={() => {
            setSelectListModal(false)
          }}
          addedLists={lists}
          updateLists={(data: any) => {
            console.log(data)
            const values: any = []
            let j = 0
            for (let i = 0; i < data?.length; i++) {
              if (data[i]?.check) {
                values[j] = data[i]
                values[j].check = false
                j++
              }
            }
            setLists(values)
            setSelectListModal(false)
          }}
        />
      )}

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
        {/* <div style={{color: '#007a59', fontWeight: '700',
      }}>LOADING..</div> */}
      </Backdrop>

      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </>
  )
}

export default ViewEditVendor
