import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {
  ApiGet,
  ApiPost,
  ApiUpload,
  ApiPut,
  ApiDelete,
  ApiGetNoAuth,
} from '../../../apiCommon/helpers/API/ApiData'
import img from '../../../img/300-1.jpg'
import folderDocument from '../../../img/folder-document.svg'
import pdfDocument from '../../../img/pdf.svg'
import docCocument from '../../../img/doc.svg'
import {Bucket} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import backArrow from '../../../img/back-arrow.png'
import {Modal, Button} from 'react-bootstrap'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Swal from 'sweetalert2'
import {AiFillDelete} from 'react-icons/ai'
import lock from '../../../img/padlock.png'
import pencil from '../../../img/pencil-blue.png'
import eye from '../../../img/eye-blue.png'
import trash from '../../../img/trash-blue.png'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import check from '../../../img/check-white.png'
import upload from '../../../img/upload-blue.png'
import bin from '../../../img/bin.png'
import PhoneInput from 'react-phone-input-2'
import {Controller, useForm} from 'react-hook-form'
import plusImg from '../../../img/add.png'
import plusBlue from '../../../img/add-blue.png'
import crossbtn from '../../../img/cross-button.png'
import './style.scss'
import redCross from '../../../img/remove.png'
import trashImg from '../../../img/trash.png'
import correct from '../../../img/correct.png'
import Box from '@mui/material/Box'
import editpen from '../../../img/pen.png'
import deleteImg from '../../../img/delete.png'
import Select from 'react-select'
import downloadImg from '../../../img/download-img.png'
import axios from 'axios';

interface ButtonProps {
  show: any
  handleClose: any
  refetchTenants: any
  tenant: any
  onSuccess: any
}

const EditTenant = ({show, handleClose, refetchTenants, tenant, onSuccess}: ButtonProps) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [tenantData, setTenantData] = React.useState<any>([])
  const [propertiType, setPropertiType] = React.useState('Documents')
  const [messageList, setMessageList] = React.useState<any>(false)
  const [open, setOpen] = React.useState(false)
  const [genderOptions, setGenderOptions] = useState<any>([
    {value: '0', label: 'Female'},
    {value: '1', label: 'Male'},
    {value: '2', label: 'Other'},
  ])
  const [viewFolder, setViewFolder] = useState<any>(false)

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const [country, setCountry] = useState<any>([])
  const [profileImg, setProfileImg] = useState<any>(null)

  const [index, setIndex] = React.useState(-1)
  const [slides, setSlides] = React.useState<any>([])
  const [totalImages, setTotalImages] = React.useState<any>(0)

  const today = new Date().toISOString().split('T')[0]

  const [folders, setFolders] = useState<any>([
    // {isSystemGenerated: true, name: 'Contracts', fileList: []},
    // {isSystemGenerated: true, name: 'IDs', fileList: []},
    // {isSystemGenerated: true, name: 'Passports', fileList: []},
    // {isSystemGenerated: true, name: 'Residencies', fileList: []},
  ])

  /************************* Documents **********************/

  const [viewFolderTenant, setViewFolderTenant] = useState<any>(false)
  const [folderFiles, setFolderFiles] = useState<any>()
  const [selectedFolder, setSelectedFolder] = useState<any>()
  const [folderName, setFolderName] = useState<any>('')
  const [folderNameModal, setFolderNameModal] = useState<any>(false)

  const getTenantFolders = () => {
    ApiGet(`corporate/tenant_folder?tenantId=${tenant}`)
      .then((res: any) => {
        setFolders(res?.data?.data)
      })
      .catch((err: any) => {})
  }

  // const getFolderFiles = (folder: any) => {
  //   // ApiGet(`corporate/tenant_folder/${folder?._id}`)
  //   //   .then((res: any) => {
  //   setSelectedFolder(folder)
  //   setViewFolderTenant(true)
  //   setFolderFiles(folder?.fileList)

  //   let y: any = []
  //   for (let i = 0; i < folder?.fileList?.length; i++) {
  //     y[y.length] = {
  //       src: Bucket + folder?.fileList?.[i]?.fileURL,
  //     }
  //   }
  //   setSlides(y)
  //   setTotalImages(folder?.fileList?.length)
  //   //   })
  //   //   .catch((err: any) => {})
  // }

  const getFolderFiles = (folder: any) => {
    ApiGet(`corporate/tenant_folder/${folder?._id}`)
      .then((res: any) => {
        setSelectedFolder(folder)
        setViewFolder(true)
        setFolderFiles(res?.data?.data?.fileList)

        let y: any = []
        for (let i = 0; i < res?.data?.data?.fileList?.length; i++) {
          y[y.length] = {
            src: Bucket + res?.data?.data?.fileList[i]?.fileURL,
          }
        }
        console.log(y)
        console.log(res?.data?.data?.fileList?.length)
        setSlides(y)
        setTotalImages(res?.data?.data?.fileList?.length)


        setEditFolderName(-1)
      })
      .catch((err: any) => {})
  }

  /**********************************/
  const [editFile, setEditFile] = useState<any>(-1)
  const [editFolderName, setEditFolderName] = useState<any>(-1)
  const [fileFound, setFileFound] = useState<any>(false)
  let fileLists_single: any = []

  const [fileToReplace, setFileToReplace] = useState<any>()
  var replaceFile: any

  const confirmAction = () => {
    // return new Promise((resolve) => {
    return Swal.fire({
      text: `${replaceFile?.name} exists already. Do you wish to REPLACE or KEEP BOTH ? `,
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#007a59',
      confirmButtonText: 'Keep Both',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      showDenyButton: true,
      denyButtonText: 'Replace',
      denyButtonColor: '#D72852',
    }).then((res: any) => {
      if (res.isConfirmed) {
        return 'keep'
      } else if (res.isDenied) {
        return 'replace'
      } else {
      }
    })
    // });
  }

  // const onFilesAddedTenant = async (event: any, val: any, id: any) => {
  //   console.log(event.target.files)
  //   let temp = [...folderFiles]

  //   if (event.target.files) {
  //     for (let m = 0; m < event.target.files.length; m++) {
  //       // setTimeout(async () => {
  //       try {
  //         // Add File
  //         if (val == 0) {
  //           const files = event.target.files
  //           fileLists_single = Array.from(files)

  //           setFileToReplace(event.target.files[m])
  //           replaceFile = event.target.files[m]

  //           console.log('File to replace ' + event.target.files)

  //           let isFound: any = false
  //           for (let i = 0; i < folderFiles?.length; i++) {
  //             if (event.target.files?.[m]?.name == folderFiles[i]?.fileName) {
  //               isFound = true
  //               i = folderFiles?.length
  //             }
  //           }

  //           if (!isFound) {
  //             setOpen(true)

  //             // for (let i = 0; i < fileLists_single.length; i++) {
  //             let formData: any = new FormData()
  //             let data: any = fileLists_single[m]
  //             let fileURL: any = URL.createObjectURL(data)
  //             let filetypes: any = fileLists_single[m].type
  //             data.fileURL = fileURL
  //             formData.append('image', data)

  //             let url = 'upload/image/tenant_folder'

  //             await ApiUpload(`${url}`, formData)
  //               .then((res) => {
  //                 temp[temp?.length] = {
  //                   fileName: event?.target?.files[m]?.name,
  //                   fileURL: res?.data?.data?.image,
  //                   fileSize: event?.target?.files[m]?.size,
  //                   createdAt: moment(new Date()).format('YYYY-MM-DD'),
  //                 }

  //                 let values = [...folders]

  //                 for (let i = 0; i < folders?.length; i++) {
  //                   if (folders[i]?.name == selectedFolder?.name) {
  //                     folders[i].fileList = temp
  //                   }
  //                 }

  //                 setFolders(values)
  //                 getFolderFiles(selectedFolder)
  //               })
  //               .catch((err) => {
  //                 console.log('res_blob', err)
  //                 ErrorToast(err?.message)
  //               })
  //             // }
  //           } else {
  //             const userChoice = await confirmAction()
  //             console.log(userChoice)
  //             if (userChoice == 'keep') {
  //               setOpen(true)

  //               // for (let i = 0; i < fileLists_single.length; i++) {
  //               let formData: any = new FormData()
  //               let data: any = replaceFile
  //               console.log(replaceFile)
  //               let fileURL: any = URL.createObjectURL(data)
  //               let filetypes: any = replaceFile.type
  //               let filename: any = replaceFile.name
  //               data.fileURL = fileURL
  //               formData.append('image', data)
  //               console.log('\ntype', filetypes)

  //               let url = 'upload/image/tenant_folder'

  //               await ApiUpload(`${url}`, formData)
  //                 .then((res) => {
  //                   // let temp = [...folderFiles]

  //                   var name = Date.now()
  //                   temp[temp?.length] = {
  //                     fileName: `${getFilename(filename, false)}-${name}.${getFilename(
  //                       filename,
  //                       true
  //                     )}`,
  //                     fileURL: res?.data?.data?.image,
  //                     fileSize: replaceFile.size,
  //                     createdAt: moment(new Date()).format('YYYY-MM-DD'),
  //                   }

  //                   let values = [...folders]

  //                   for (let i = 0; i < folders?.length; i++) {
  //                     if (folders[i]?.name == selectedFolder?.name) {
  //                       folders[i].fileList = temp
  //                     }
  //                   }

  //                   setFolders(values)
  //                   getFolderFiles(selectedFolder)
  //                   setOpen(false)
  //                 })
  //                 .catch((err) => {
  //                   console.log('res_blob', err)
  //                   ErrorToast(err?.message)
  //                 })
  //             }
  //             // replaceKeepFile('keep')
  //             else {
  //               setOpen(true)

  //               // for (let i = 0; i < fileLists_single.length; i++) {
  //               let formData: any = new FormData()
  //               let data: any = replaceFile
  //               console.log(replaceFile)
  //               let fileURL: any = URL.createObjectURL(data)
  //               let filetypes: any = replaceFile.type
  //               let filename: any = replaceFile.name
  //               data.fileURL = fileURL
  //               formData.append('image', data)
  //               console.log('\ntype', filetypes)

  //               let url = 'upload/image/tenant_folder'

  //               await ApiUpload(`${url}`, formData)
  //                 .then((res) => {
  //                   // let temp = [...folderFiles]

  //                   for (let j = 0; j < folderFiles?.length; j++) {
  //                     if (filename == folderFiles[j]?.fileName) {
  //                       temp[j] = {
  //                         fileName: filename,
  //                         fileURL: res?.data?.data?.image,
  //                         fileSize: replaceFile.size,
  //                         createdAt: moment(new Date()).format('YYYY-MM-DD'),
  //                       }
  //                     }
  //                   }

  //                   let values = [...folders]

  //                   for (let i = 0; i < folders?.length; i++) {
  //                     if (folders[i]?.name == selectedFolder?.name) {
  //                       folders[i].fileList = temp
  //                     }
  //                   }

  //                   setFolders(values)
  //                   getFolderFiles(selectedFolder)
  //                   setOpen(false)
  //                 })
  //                 .catch((err) => {
  //                   console.log('res_blob', err)
  //                   ErrorToast(err?.message)
  //                 })
  //             }
  //             // replaceKeepFile('replace')
  //           }
  //           setOpen(false)
  //         }
  //         // }, 1000)
  //       } catch (error) {
  //         console.error(error)
  //         // Handle errors
  //       }
  //     }
  //   }

  //   // delete file
  //   else {
  //     let temp = [...folderFiles]
  //     temp.splice(id, 1)
  //     setFolderFiles(temp)

  //     let folds = [...folders]
  //     for (let i = 0; i < folds?.length; i++) {
  //       if (folds?.[i]?.name == selectedFolder?.name) folds[i].fileList = temp
  //     }

  //     setFolders(folds)

  //     // for (let i = 0; i < temp?.length; i++) {
  //     //   if (temp[i]?._id == id) temp.splice(i, 1)
  //     // }

  //     // const body = {
  //     //   id: selectedFolder?._id,
  //     //   name: selectedFolder?.name,
  //     //   fileList: temp,
  //     // }
  //     // ApiPut(`corporate/tenant_folder`, body)
  //     //   .then((res: any) => {
  //     //     getFolderFiles(selectedFolder)
  //     //     setOpen(false)
  //     //   })
  //     //   .catch((err: any) => {})
  //   }
  // }

  const getFilename = (file: any, type: any) => {
    if (type) return file.split('.')[1]
    else return file.split('.')[0]
  }

  // Add Folder on update btn click -> previous approach
  // const addFolder = () => {
  //   let isFound: any = false
  //   for (let i = 0; i < folders?.length; i++) {
  //     if (folderName == folders[i]?.name) {
  //       isFound = true
  //       setFileFound(true)
  //       i = folders?.length
  //     }
  //   }

  //   if (isFound == false) {
  //     const values = [...folders]
  //     values[folders?.length] = {
  //       name: folderName,
  //       isSystemGenerated: false,
  //       fileList: [],
  //     }
  //     setFolders(values)
  //     SuccessToast('New Folder has been added successfully!')

  //     setFolderNameModal(false)

  //     //   const body = {
  //     //     name: folderName,
  //     //     tenantId: `${tenant}`,
  //     //     fileList: [],
  //     //   }
  //     //   ApiPost(`corporate/tenant_folder`, body)
  //     //     .then((res: any) => {
  //     //       SuccessToast('New Folder has been added successfully!')
  //     //       getTenantFolders()
  //     //       setFolderNameModal(false)
  //     //     })
  //     //     .catch((err: any) => {})
  //   } else {
  //   }
  // }

  const addFolder = () => {
    let isFound: any = false
    for (let i = 0; i < folders?.length; i++) {
      if (folderName == folders[i]?.name) {
        isFound = true
        setFileFound(true)
        i = folders?.length
      }
    }

    if (isFound == false) {
      const body = {
        name: folderName,
        tenantId: `${tenant}`,
        fileList: [],
      }
      ApiPost(`corporate/tenant_folder`, body)
        .then((res: any) => {
          SuccessToast('New Folder has been added successfully!')
          getTenantFolders()
          setFolderNameModal(false)
        })
        .catch((err: any) => {})
    } else {
    }
  }

  const updateName = () => {
    const body = {
      id: selectedFolder?._id,
      name: selectedFolder?.name,
      fileList: folderFiles,
    }
    ApiPut(`corporate/tenant_folder`, body)
      .then((res: any) => {
        getFolderFiles(selectedFolder)
        setEditFile(-1)
      })
      .catch((err: any) => {})
  }

  const updateFolderName = () => {
    const body = {
      id: selectedFolder?._id,
      name: newFolderName,
      fileList: selectedFolder?.fileList,
    }
    ApiPut(`corporate/tenant_folder`, body)
      .then((res: any) => {
        setEditFolderName(-1)
        getTenantFolders()
      })
      .catch((err: any) => {})
  }

  const deleteFolder = (id: any) => {
    ApiDelete(`corporate/tenant_folder/${id}`)
      .then((res: any) => {
        SuccessToast('Folder has been deleted successfully!')
        getTenantFolders()
      })
      .catch((err: any) => {})
  }

  // Replace OR Keep File
  const replaceKeepFile = async (val: any) => {
    const files = replaceFile
    fileLists_single = Array.from(files)

    setOpen(true)

    for (let i = 0; i < fileLists_single.length; i++) {
      let formData: any = new FormData()
      let data: any = fileLists_single[i]
      console.log(fileLists_single[i])
      let fileURL: any = URL.createObjectURL(data)
      let filetypes: any = fileLists_single[i].type
      let filename: any = fileLists_single[i].name
      data.fileURL = fileURL
      formData.append('image', data)
      console.log('\ntype', filetypes)

      let url = 'upload/image/tenant_folder'

      await ApiUpload(`${url}`, formData)
        .then((res) => {
          let temp = [...folderFiles]
          if (val == 'replace') {
            for (let j = 0; j < folderFiles?.length; j++) {
              if (filename == folderFiles[j]?.fileName) {
                temp[j] = {
                  fileName: filename,
                  fileURL: res?.data?.data?.image,
                  fileSize: fileLists_single[i].size,
                  createdAt: moment(new Date()).format('YYYY-MM-DD'),
                }
              }
            }
          } else {
            temp[folderFiles?.length] = {
              fileName: `${getFilename(filename, false)}-copy.${getFilename(filename, true)}`,
              fileURL: res?.data?.data?.image,
              fileSize: fileLists_single[i].size,
              createdAt: moment(new Date()).format('YYYY-MM-DD'),
            }
          }

          let values = [...folders]

          for (let i = 0; i < folders?.length; i++) {
            if (folders[i]?.name == selectedFolder?.name) {
              folders[i].fileList = temp
            }
          }

          setFolders(values)
          getFolderFiles(selectedFolder)
          setOpen(false)
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    }
  }

  //
  const getPositive = (number: any) => {
    // if number is less than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const [newName, setNewName] = useState<any>()
  const [newFolderName, setNewFolderName] = useState<any>()
  const [isEditTenant, setIsEditTenant] = useState<any>()

  //
  const renameFile = async (file: any, type: any) => {
    const body = {
      newFileName: newName + '.' + type,
      image: file,
    }

    await ApiPost('upload/rename_file', body)
      .then((res) => {
        setTimeout(() => {
          setEditFile(-1)
          getFolderFiles(selectedFolder)
        }, 1500)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(res?.data?.data)
      })
      .catch((e: any) => {
        console.log('e', e)
      })
  }

  useEffect(() => {
    getCountry()
  }, [])

  const updateTenant = async (data: any, e: any) => {
    e.preventDefault()
    const body = {
      _id: tenantData?._id,
      firstName: tenantData?.firstName,
      lastName: tenantData?.lastName,
      phoneNumber: tenantData?.phoneNumber,
      countryCode: tenantData?.countryCode,
      email: tenantData?.email,
      nationality: tenantData?.nationality,
      // tenantType: tenantData?.tenantType,
      DOB: tenantData?.DOB,
      passportNo: tenantData?.passportNo,
      otherNo: tenantData?.otherNo,
      idNo: tenantData?.idNo,
      folder_list: folders,
      gender: Number(tenantData?.gender),
      profileImage: profileImg,
    }
    console.log(body)
    await ApiPut(`corporate/tenant`, body)
      .then((response) => {
        SuccessToast(response?.data?.message)
        refetchTenants()
        // handleClose()
        setIsEditTenant(false)
        onSuccess(response?.data?.data)
      })
      .catch((error) => {
        console.log(error)
        ErrorToast(error?.message)
      })
  }

  const getTenantData = async () => {
    await ApiGet(`corporate/tenant/${tenant}`)
      .then((res) => {
        setTenantData(res?.data?.data)
        setFolders(res?.data?.data?.tenant_folder)
        setValue('email', res?.data?.data?.email)
        setValue(
          'phoneNumber',
          `${parseInt(res?.data?.data?.countryCode)}${res?.data?.data?.phoneNumber}`
        )
        console.log('\nres?.data?.data', res?.data?.data)
        setProfileImg(res?.data?.data?.profileImage)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    getTenantData()
    getTenantFolders()
  }, [])

  const onSelectFile = async (e: any) => {
    e.preventDefault()
    let fileURL = e.target.files[0]
    var formdata = new FormData()
    formdata.append('image', fileURL)

    await ApiPost(`upload/compress_image/tenant_profile`, formdata)
      .then((res) => {
        e.preventDefault()
        const body = {
          _id: tenantData?._id,
          firstName: tenantData?.firstName,
          lastName: tenantData?.lastName,
          phoneNumber: tenantData?.phoneNumber,
          countryCode: tenantData?.countryCode,
          email: tenantData?.email,
          nationality: tenantData?.nationality,
          // tenantType: tenantData?.tenantType,
          DOB: tenantData?.DOB,
          passportNo: tenantData?.passportNo,
          otherNo: tenantData?.otherNo,
          idNo: tenantData?.idNo,
          // folder_list: folders,
          gender: Number(tenantData?.gender),
          profileImage: res?.data?.data?.image,
        }

        ApiPut(`corporate/tenant`, body)
          .then((response) => {
            SuccessToast('Profile picture has been updated successfully!!!')
            getTenantData()
            setIsEditTenant(false)
          })
          .catch((error) => {
            console.log(error)
            ErrorToast(error?.message)
          })
      })
      .catch(() => {})
  }

  // delete logo
  const deleteProfileImg = () => {
    const body = {
      url: profileImg,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res) => {
        setProfileImg(null)
        SuccessToast('Profile picture has been removed successfully!!!')
        // const body = {
        //   _id: tenantData?._id,
        //   profileImage: '',
        // }
        // console.log(body)
        // ApiPut(`corporate/tenant`, body)
        //   .then((response) => {

        //   })
        //   .catch((error) => {
        //     console.log(error)
        //     ErrorToast(error?.message)
        //   })
      })
      .catch((err) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  const deleteTenant = () => {
    ApiDelete(`corporate/tenant/${window.location.pathname.split('/')[2]}`)
      .then(() => {
        SuccessToast('Tenant has been deleted successfully..')
        // navigate(-1)
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  //
  const onFilesAddedSingle = async (event: any, val: any, id: any) => {
    let temp = [...folderFiles]

    if (event.target.files) {
      for (let m = 0; m < event.target.files.length; m++) {
        try {
          // Add File
          if (val == 0) {
            const files = event.target.files
            fileLists_single = Array.from(files)

            setFileToReplace(event.target.files[m])
            replaceFile = event.target.files[m]

            let isFound: any = false
            for (let i = 0; i < folderFiles?.length; i++) {
              if (event.target.files?.[m]?.name == folderFiles[i]?.fileName) {
                isFound = true
                i = folderFiles?.length
              }
            }

            if (!isFound) {
              setOpen(true)

              // for (let i = 0; i < fileLists_single.length; i++) {
              let formData: any = new FormData()
              let data: any = fileLists_single[m]
              let fileURL: any = URL.createObjectURL(data)
              let filetypes: any = fileLists_single[m].type
              data.fileURL = fileURL
              formData.append('image', data)

              let url = 'upload/image/tenant_folder'

              await ApiUpload(`${url}`, formData)
                .then(async (res) => {
                  // let temp = [...folderFiles]
                  temp[temp?.length] = {
                    fileName: event?.target?.files[m]?.name,
                    fileURL: res?.data?.data?.image,
                    fileSize: event?.target?.files[m]?.size,
                    createdAt: moment(new Date()).format('YYYY-MM-DD'),
                  }

                  const body = {
                    id: selectedFolder?._id,
                    name: selectedFolder?.name,
                    fileList: temp,
                  }
                  await ApiPut(`corporate/tenant_folder`, body)
                    .then((res: any) => {
                      getFolderFiles(selectedFolder)
                      setOpen(false)
                    })
                    .catch((err: any) => {})
                })
                .catch((err) => {
                  console.log('res_blob', err)
                  ErrorToast(err?.message)
                })
              // }
            } else {
              const userChoice = await confirmAction()
              console.log(userChoice)
              if (userChoice == 'keep') {
                setOpen(true)

                let formData: any = new FormData()
                let data: any = replaceFile
                console.log(replaceFile)
                let fileURL: any = URL.createObjectURL(data)
                let filetypes: any = replaceFile.type
                let filename: any = replaceFile.name
                data.fileURL = fileURL
                formData.append('image', data)
                console.log('\ntype', filetypes)

                let url = 'upload/image/tenant_folder'

                await ApiUpload(`${url}`, formData)
                  .then(async (res) => {
                    // let temp = [...folderFiles]

                    var name = Date.now()
                    temp[temp?.length] = {
                      fileName: `${getFilename(filename, false)}-${name}.${getFilename(
                        filename,
                        true
                      )}`,
                      fileURL: res?.data?.data?.image,
                      fileSize: replaceFile.size,
                      createdAt: moment(new Date()).format('YYYY-MM-DD'),
                    }

                    const body = {
                      id: selectedFolder?._id,
                      name: selectedFolder?.name,
                      fileList: temp,
                    }
                    await ApiPut(`corporate/tenant_folder`, body)
                      .then((res: any) => {
                        getFolderFiles(selectedFolder) /********************** */
                        // setOpen(false)
                      })
                      .catch((err: any) => {})
                  })
                  .catch((err) => {
                    console.log('res_blob', err)
                    ErrorToast(err?.message)
                  })
                  .catch((err) => {
                    console.log('res_blob', err)
                    ErrorToast(err?.message)
                  })
              }
              // replaceKeepFile('keep')
              else {
                setOpen(true)

                let formData: any = new FormData()
                let data: any = replaceFile
                console.log(replaceFile)
                let fileURL: any = URL.createObjectURL(data)
                let filetypes: any = replaceFile.type
                let filename: any = replaceFile.name
                data.fileURL = fileURL
                formData.append('image', data)
                console.log('\ntype', filetypes)

                let url = 'upload/image/tenant_folder'

                await ApiUpload(`${url}`, formData)
                  .then((res) => {
                    // let temp = [...folderFiles]

                    for (let j = 0; j < folderFiles?.length; j++) {
                      if (filename == folderFiles[j]?.fileName) {
                        temp[j] = {
                          fileName: filename,
                          fileURL: res?.data?.data?.image,
                          fileSize: replaceFile.size,
                          createdAt: moment(new Date()).format('YYYY-MM-DD'),
                        }
                      }
                    }

                    const body = {
                      id: selectedFolder?._id,
                      name: selectedFolder?.name,
                      fileList: temp,
                    }
                    ApiPut(`corporate/tenant_folder`, body)
                      .then((res: any) => {
                        getFolderFiles(selectedFolder) /********************* */
                        // setOpen(false)
                      })
                      .catch((err: any) => {})
                  })
                  .catch((err) => {
                    console.log('res_blob', err)
                    ErrorToast(err?.message)
                  })
              }
              // replaceKeepFile('replace')
            }
            setOpen(false)
          }
          // }, 1000)
        } catch (error) {
          console.error(error)
          // Handle errors
        }
      }
    }

    // delete file
    if (val == 1) {
      let temp = [...folderFiles]

      for (let i = 0; i < temp?.length; i++) {
        if (temp[i]?._id == id) temp.splice(i, 1)
      }

      const body = {
        id: selectedFolder?._id,
        name: selectedFolder?.name,
        fileList: temp,
      }
      ApiPut(`corporate/tenant_folder`, body)
        .then((res: any) => {
          let values = {...selectedFolder}
          values.fileList = temp
          setSelectedFolder(values)

          getFolderFiles(values)
          setOpen(false)
        })
        .catch((err: any) => {})
    }
  }

  const [isDisable, setIsDisable] = useState<any>(false)

  const handleDownload = async (imageUrl:any) => {
    setIsDisable(true)
    try {
      const response = await axios.get(imageUrl, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: response.headers['content-type'] });

      // Create a link element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'downloaded_image.png';

      // Append the link to the body
      document.body.appendChild(link);

      // Trigger the click event to start the download
      link.click();

      // Remove the link from the body
      document.body.removeChild(link);
      setIsDisable(false)
    } catch (error) {
      setIsDisable(false)
      ErrorToast('Error downloading image')
    }
  };

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-1200px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        // style={{minWidth: '1150px'}}
      >
        <div
          className='modal-body py-lg-3 px-lg-6 '
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '600px',
            maxHeight: '670px',
            overflow: 'auto',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-4 cursor-pointer'
                style={{fontWeight: '800'}}
                onClick={() => {
                  handleClose()
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='d-flex flex-column flex-column-fluid mt-4'>
            <form onSubmit={handleSubmit((data: any, e: any) => updateTenant(tenantData, e))}>
              <div id='kt_app_content' className='app-content flex-column-fluid pt-0 pb-2'>
                <div className='px-2 pt-0 mt-0'>
                  <div className='d-flex align-items-center'>
                    <h1 className='m-0 head-text ms-2'>
                      {tenantData?.firstName || tenantData?.lastName
                        ? `${tenantData?.firstName} ${tenantData?.lastName}`
                        : 'Tenant Profile'}
                    </h1>
                  </div>
                </div>
                <div id='kt_app_content_container' className='px-2 mt-8'>
                  <div className='d-flex flex-column flex-lg-row'>
                    <div className='flex-column flex-lg-row-auto w-lg-300px w-xl-400px mb-0'>
                      <div className='card mb-5 mb-xl-8'>
                        <div className='card-body py-3 px-5'>
                          <div id='kt_user_view_details' className='collapse show'>
                            <div className='pb-5 fs-6'>
                              {isEditTenant && (
                                <>
                                  <div className='d-flex align-items-center justify-content-center mb-5 mt-2'>
                                    <button
                                      type='button'
                                      className='btn btn-sm fw-bold red-hollow-btn'
                                      onClick={() => {
                                        getTenantData()
                                        getTenantFolders()
                                        setTimeout(() => {
                                          setIsEditTenant(false)
                                        }, 500)
                                      }}
                                    >
                                      <img
                                        src={redCross}
                                        height={18}
                                        width={18}
                                        style={{marginRight: '7px'}}
                                      />{' '}
                                      Cancel
                                    </button>
                                    <button
                                      className='btn btn-sm fw-bold select-btn ms-3'
                                      type='submit'
                                    >
                                      <img src={check} height={18} width={18} className='me-5' />
                                      Update
                                    </button>
                                  </div>
                                </>
                              )}
                              <div className='row mt-4'>
                                {!isEditTenant && (
                                  <div className='col-lg-8 col-md-8 symbol symbol-100px symbol d-flex align-items-center'>
                                    {/* Logo */}
                                    <div className='d-flex mb-3 '>
                                      {profileImg == null ? (
                                        <>
                                          <label
                                            htmlFor='media'
                                            className='btn btn-sm fw-bold form-label m-0 upload text-center pb-5'
                                            style={{
                                              height: '160px',
                                              width: '160px',
                                              backgroundColor: '#8a8aa3',
                                              fontSize: '20px',
                                              // paddingLeft: '40px',
                                              color: 'white',
                                            }}
                                          >
                                            <img />
                                            <b
                                              style={{
                                                fontSize: '22px',
                                                display: 'block',
                                                marginTop: '-25px',
                                                fontWeight: '700',
                                                marginBottom: '5px',
                                              }}
                                            >
                                              PROFILE PHOTO
                                            </b>
                                            Click to upload
                                          </label>

                                          <input
                                            type='file'
                                            hidden
                                            id='media'
                                            className='form-control form-control-solid'
                                            name='passport'
                                            onChange={onSelectFile}
                                          />
                                        </>
                                      ) : (
                                        <div className='d-flex'>
                                          <img
                                            src={`${Bucket}${profileImg}`}
                                            height='160'
                                            width='160'
                                            style={{
                                              borderRadius: '7px',
                                              border: '2px solid black',
                                              objectFit: 'contain',
                                            }}
                                          />

                                          {/* <div> */}
                                          <label
                                            htmlFor='media'
                                            className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                                            style={{
                                              height: '25px',
                                              width: '25px',
                                              border: '1px solid #48a794 ',
                                              borderRadius: '4px',
                                              transform: 'translate(-45px,-10px)',
                                            }}
                                          >
                                            <img
                                              src={editpen}
                                              alt=''
                                              className=''
                                              style={{
                                                cursor: 'pointer',
                                                height: '18px',
                                                width: '18px',
                                              }}
                                            />
                                          </label>

                                          <input
                                            type='file'
                                            hidden
                                            id='media'
                                            className='form-control form-control-solid'
                                            name='passport'
                                            onChange={onSelectFile}
                                          />
                                          {/* </div> */}
                                          {/* <div> */}
                                          <div
                                            className='fw-bold form-label m-0 upload text-center pb-5 bg-white'
                                            style={{
                                              height: '25px',
                                              width: '25px',
                                              border: '1px solid crimson',
                                              borderRadius: '4px',
                                              transform: 'translate(-38px,-10px)',
                                            }}
                                            onClick={() => deleteProfileImg()}
                                          >
                                            <img
                                              src={deleteImg}
                                              alt=''
                                              className=''
                                              style={{
                                                cursor: 'pointer',
                                                height: '18px',
                                                width: '18px',
                                                marginLeft: '2px',
                                              }}
                                            />
                                          </div>
                                          {/* </div> */}
                                        </div>
                                      )}
                                    </div>
                                    {/* <img src={img} alt='image' /> */}
                                  </div>
                                )}
                                <div
                                  className='col-lg-4 col-md-4 pe-5'
                                  style={{textAlign: 'right'}}
                                >
                                  {!isEditTenant && (
                                    <div className='d-flex align-items-center justify-content-end'>
                                      <img
                                        src={bin}
                                        alt=''
                                        height='24px'
                                        width='24px'
                                        className='ms-4'
                                        style={{cursor: 'pointer'}}
                                        onClick={() =>
                                          Swal.fire({
                                            html: '<p class="text-center m-0" style="font-size: 16px"> You are about to delete the tenant, do you wish to proceed?</p>',
                                            showConfirmButton: true,
                                            confirmButtonColor: '#007a59',
                                            confirmButtonText: 'Confirm',
                                            showCancelButton: true,
                                            cancelButtonText: 'Cancel',
                                          }).then((res: any) => {
                                            if (res.isConfirmed) {
                                              deleteTenant()
                                            }
                                          })
                                        }
                                      />
                                      <img
                                        src={pencil}
                                        alt=''
                                        height='22px'
                                        width='22px'
                                        className='ms-4'
                                        style={{cursor: 'pointer'}}
                                        // onClick={() => {
                                        //   navigate(`/edit-tenant/${window.location.pathname.split('/')[2]}`)
                                        // }}
                                        onClick={() => setIsEditTenant(true)}
                                      />

                                      {/* <a
                               
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                
                                onClick={() => {
                                  navigate(`/edit-tenant/${window.location.pathname.split('/')[2]}`)
                                }}
                              >
                                Edit
                              </a> */}
                                    </div>
                                  )}
                                </div>
                                {!isEditTenant && (
                                  <div className='col-12 mt-3 '>
                                    <span
                                      className='value_black'
                                      style={{
                                        fontSize: '16px',
                                        textTransform: 'capitalize',
                                        fontWeight: 600,
                                        color: 'black',
                                      }}
                                    >
                                      {tenantData?.firstName} {tenantData?.lastName}
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className=''>
                                {isEditTenant && (
                                  <div className='d-flex my-2 mb-4 align-items-center'>
                                    <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                      <label
                                        className={isEditTenant ? 'required labl-gry' : 'labl-gry'}
                                      >
                                        {' '}
                                        First Name{' '}
                                      </label>
                                    </h4>
                                    <h4 style={{width: '65%'}} className='my-2'>
                                      <input
                                        {...register('firstName', {required: true})}
                                        type='text'
                                        className='form-control form-control-solid mytest'
                                        placeholder=''
                                        name='firstName'
                                        value={tenantData?.firstName}
                                        onChange={(e) =>
                                          setTenantData({
                                            ...tenantData,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />

                                      {errors.firstName && isEditTenant && (
                                        <p
                                          style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                          className='m-0 mt-1'
                                        >
                                          This field is required
                                        </p>
                                      )}
                                    </h4>
                                  </div>
                                )}

                                {isEditTenant && (
                                  <div className='d-flex my-2 mb-4 align-items-center'>
                                    <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                      <label
                                        className={isEditTenant ? 'required labl-gry' : 'labl-gry'}
                                      >
                                        {' '}
                                        Last Name{' '}
                                      </label>
                                    </h4>
                                    <h4 style={{width: '65%'}} className='my-2'>
                                      <input
                                        type='text'
                                        {...register('lastName', {required: true})}
                                        className='form-control form-control-solid mytest'
                                        placeholder=''
                                        name='lastName'
                                        value={tenantData?.lastName}
                                        onChange={(e) =>
                                          setTenantData({
                                            ...tenantData,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />

                                      {errors.lastName && isEditTenant && (
                                        <p
                                          style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                          className='m-0 mt-1'
                                        >
                                          This field is required
                                        </p>
                                      )}
                                    </h4>
                                  </div>
                                )}

                                {!isEditTenant && (
                                  <div className='d-flex my-2 mb-4 align-items-center'>
                                    <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                      <label
                                        className={isEditTenant ? 'required labl-gry' : 'labl-gry'}
                                      >
                                        {' '}
                                        ID
                                      </label>
                                    </h4>
                                    <h4 style={{width: '65%'}} className='my-2'>
                                      <span className='ps-1'>
                                        <b>{tenantData?.id}</b>
                                      </span>
                                    </h4>
                                  </div>
                                )}

                                {!isEditTenant && (
                                  <div className='d-flex my-2 mb-4 align-items-center'>
                                    <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                      <label
                                        className={isEditTenant ? 'required labl-gry' : 'labl-gry'}
                                      >
                                        {' '}
                                        Type
                                      </label>
                                    </h4>
                                    <h4 style={{width: '65%'}} className='my-2'>
                                      <span className='ps-1'>
                                        <b>
                                          {tenantData?.tenantType === 0
                                            ? 'Main'
                                            : tenantData?.tenantType === 1
                                            ? 'Sub'
                                            : ''}
                                        </b>
                                      </span>
                                    </h4>
                                  </div>
                                )}

                                <div className='d-flex my-2 mb-4 align-items-center'>
                                  <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                    <label
                                      className={isEditTenant ? 'required labl-gry' : 'labl-gry'}
                                    >
                                      {' '}
                                      Gender{' '}
                                    </label>
                                  </h4>
                                  <h4 style={{width: '65%'}} className='my-2'>
                                    {isEditTenant ? (
                                      <Select
                                        defaultValue={
                                          tenantData?.gender == 0
                                            ? genderOptions[0]
                                            : tenantData?.gender == 1
                                            ? genderOptions[1]
                                            : genderOptions[2]
                                        }
                                        // {...register('gender', {required: true})}
                                        styles={customStyles}
                                        isSearchable={false}
                                        options={genderOptions}
                                        name='gender'
                                        onChange={(e) =>
                                          setTenantData({
                                            ...tenantData,
                                            ['gender']: e.value,
                                          })
                                        }
                                      />
                                    ) : (
                                      <span className='ps-1'>
                                        <b>
                                          {tenantData?.gender == 0
                                            ? 'Female'
                                            : tenantData?.gender == 1
                                            ? 'Male'
                                            : 'Other'}
                                        </b>
                                      </span>
                                    )}
                                  </h4>
                                </div>

                                <div className='d-flex my-2 mb-4 align-items-center'>
                                  <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                    <label
                                      className={isEditTenant ? 'required labl-gry' : 'labl-gry'}
                                    >
                                      {' '}
                                      DOB{' '}
                                    </label>
                                  </h4>
                                  <h4 style={{width: '65%'}} className='my-2'>
                                    {isEditTenant ? (
                                      <input
                                        type='date'
                                        {...register('DOB', {required: true})}
                                        className='form-control form-control-solid mytest'
                                        placeholder='Select date'
                                        max={new Date().toISOString().split('T')[0]}
                                        name='DOB'
                                        value={moment(
                                          tenantData?.DOB && tenantData?.DOB.split('T')[0]
                                        ).format('YYYY-MM-DD')}
                                        onChange={(e) =>
                                          setTenantData({
                                            ...tenantData,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                    ) : (
                                      <span className='ps-1'>
                                        <b>
                                          {moment(
                                            tenantData?.DOB && tenantData?.DOB.split('T')[0]
                                          ).format('DD/MM/YYYY')}
                                        </b>
                                      </span>
                                    )}
                                    {errors.DOB && isEditTenant && (
                                      <p
                                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                        className='m-0 mt-1'
                                      >
                                        This field is required
                                      </p>
                                    )}
                                  </h4>
                                </div>

                                <div className='d-flex my-2 mb-4 align-items-center'>
                                  <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                    <label
                                      className={isEditTenant ? 'required labl-gry' : 'labl-gry'}
                                    >
                                      {' '}
                                      Nationality{' '}
                                    </label>
                                  </h4>
                                  <h4 style={{width: '65%'}} className='my-2'>
                                    {isEditTenant ? (
                                      <select
                                        {...register('nationality', {required: true})}
                                        className='form-select form-select-solid mytest'
                                        name='nationality'
                                        value={tenantData?.nationality}
                                        // style={{width: '150px'}}
                                        onChange={(e) =>
                                          setTenantData({
                                            ...tenantData,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      >
                                        <option value='' disabled selected>
                                          Select country
                                        </option>
                                        {country?.map((v: any) => (
                                          <option value={v.country}>{v.country}</option>
                                        ))}
                                      </select>
                                    ) : (
                                      <span className='ps-1'>
                                        <b>
                                          {tenantData?.nationality ? tenantData?.nationality : '-'}
                                        </b>
                                      </span>
                                    )}
                                    {errors.nationality && isEditTenant && (
                                      <p
                                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                        className='m-0 mt-1'
                                      >
                                        This field is required
                                      </p>
                                    )}
                                  </h4>
                                </div>

                                <div className='d-flex my-2 mb-4 align-items-center'>
                                  <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                    <label
                                      className={isEditTenant ? 'required labl-gry' : 'labl-gry'}
                                    >
                                      {' '}
                                      Email{' '}
                                    </label>
                                  </h4>
                                  <h4 style={{width: '65%'}} className='my-2 test'>
                                    {isEditTenant ? (
                                      <Controller
                                        name='email'
                                        control={control}
                                        rules={{
                                          required: 'Email is required',
                                          pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                            message: 'Invalid email address',
                                          },
                                        }}
                                        render={({field}) => (
                                          <input
                                            //   {...register('email', {required: true})}
                                            type='email'
                                            className='form-control form-control-solid mytest'
                                            placeholder=''
                                            name='email'
                                            value={tenantData?.email}
                                            onChange={(e) => {
                                              setValue('email', e.target.value) // Update the form value
                                              setTenantData({
                                                ...tenantData,
                                                [e.target.name]: e.target.value,
                                              })
                                            }}
                                          />
                                        )}
                                      />
                                    ) : (
                                      <span className='ps-1'>
                                        <b>{tenantData?.email ? tenantData?.email : '-'}</b>
                                      </span>
                                    )}
                                    {errors.email && isEditTenant && (
                                      <p
                                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                        className='m-0 mt-1'
                                      >
                                        Please enter a valid Email Address
                                        {/* {errors.email.message} */}
                                      </p>
                                    )}
                                  </h4>
                                </div>

                                <div className='d-flex my-2 mb-4 align-items-center'>
                                  <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                    <label
                                      className={isEditTenant ? 'required labl-gry' : 'labl-gry'}
                                    >
                                      {' '}
                                      Mobile No.{' '}
                                    </label>
                                  </h4>

                                  <h4
                                    style={{width: '65%', borderRadius: '0.475rem'}}
                                    className='my-2 test'
                                  >
                                    {isEditTenant ? (
                                      <Controller
                                        name='phoneNumber'
                                        control={control}
                                        rules={{
                                          required: 'This field is required',
                                        }}
                                        render={({field}) => (
                                          <PhoneInput
                                            //   {...register('phoneNumber', {required: true})}
                                            placeholder='Enter phone number'
                                            value={`${tenantData?.countryCode} ${tenantData?.phoneNumber}`}
                                            onChange={(phone: any, e: any) => {
                                              let CountryCode: any = e?.dialCode
                                              let PhoneNumber: any = phone.split(CountryCode)[1]
                                              setTenantData({
                                                ...tenantData,
                                                ['countryCode']: parseInt(CountryCode),
                                                ['phoneNumber']: PhoneNumber,
                                              })

                                              setValue(
                                                'phoneNumber',
                                                `${parseInt(CountryCode)}${PhoneNumber}`
                                              )
                                            }}
                                          />
                                        )}
                                      />
                                    ) : (
                                      <span className='ps-1'>
                                        <b>
                                          {tenantData?.phoneNumber
                                            ? `${tenantData?.countryCode} ${tenantData?.phoneNumber}`
                                            : '-'}
                                        </b>
                                      </span>
                                    )}

                                    {errors.phoneNumber && isEditTenant && (
                                      <p
                                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                        className='m-0 mt-1'
                                      >
                                        This field is required
                                        {/* {errors.email.message} */}
                                      </p>
                                    )}
                                  </h4>
                                </div>

                                <div className='d-flex my-2 mb-4 align-items-center'>
                                  <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                    <label className='labl-gry'> ID No. </label>
                                  </h4>
                                  <h4 style={{width: '65%'}} className='my-2'>
                                    {isEditTenant ? (
                                      <input
                                        type='text'
                                        className='form-control form-control-solid mytest '
                                        placeholder=''
                                        name='idNo'
                                        value={tenantData?.idNo}
                                        onChange={(e) =>
                                          setTenantData({
                                            ...tenantData,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                    ) : (
                                      <span className='ps-1'>
                                        <b>{tenantData?.idNo ? `${tenantData?.idNo}` : '-'}</b>
                                      </span>
                                    )}
                                  </h4>
                                </div>

                                <div className='d-flex my-2 mb-4 align-items-center'>
                                  <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                    <label className='labl-gry'> Passport No. </label>
                                  </h4>
                                  <h4 style={{width: '65%'}} className='my-2'>
                                    {isEditTenant ? (
                                      <input
                                        type='text'
                                        className='form-control form-control-solid mytest'
                                        placeholder=''
                                        name='passportNo'
                                        value={tenantData?.passportNo}
                                        onChange={(e) =>
                                          setTenantData({
                                            ...tenantData,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                    ) : (
                                      <span className='ps-1'>
                                        <b>
                                          {tenantData?.passportNo
                                            ? `${tenantData?.passportNo}`
                                            : '-'}
                                        </b>
                                      </span>
                                    )}
                                  </h4>
                                </div>
                                <div className='d-flex my-2 mb-4 align-items-center'>
                                  <h4 className='my-2' style={{color: 'black', width: '35%'}}>
                                    <label className='labl-gry'> Other No. </label>
                                  </h4>
                                  <h4 style={{width: '65%'}} className='my-2'>
                                    {isEditTenant ? (
                                      <input
                                        type='text'
                                        className='form-control form-control-solid mytest'
                                        placeholder=''
                                        name='otherNo'
                                        value={tenantData?.otherNo}
                                        onChange={(e) =>
                                          setTenantData({
                                            ...tenantData,
                                            [e.target.name]: e.target.value,
                                          })
                                        }
                                      />
                                    ) : (
                                      <span className='ps-1'>
                                        <b>
                                          {tenantData?.otherNo ? `${tenantData?.otherNo}` : '-'}
                                        </b>
                                      </span>
                                    )}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <>
                      {!messageList && (
                        <div className='flex-lg-row-fluid ms-lg-10'>
                          <ul
                            className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-8'
                            role='tablist'
                          >
                            <li className='nav-item' role='presentation'>
                              <a
                                className={
                                  propertiType === 'Documents'
                                    ? 'nav-link text-active-primary pb-4 active head-text'
                                    : 'nav-link text-active-primary pb-4 head-text'
                                }
                                data-bs-toggle='tab'
                                aria-selected='false'
                                role='tab'
                                tabIndex={-1}
                                onClick={() => {
                                  setPropertiType('Documents')
                                }}
                              >
                                Documents
                              </a>
                            </li>
                          </ul>
                          <div>
                            {/* Documents Tab */}
                            {propertiType === 'Documents' && (
                              <>
                                <div className='row g-6 g-xl-9 mb-6 mb-xl-9 '>
                                  {!viewFolder && (
                                    <div
                                      className='col-lg-12 col-md-12 text-end mt-3'
                                      style={{textAlign: 'right'}}
                                    >
                                      <button
                                        type='button'
                                        className='btn btn-sm fw-bold blue-hollow-btn ms-auto'
                                        style={{width: '130px'}}
                                        onClick={() => {
                                          setFileFound(false)
                                          setFolderNameModal(true)
                                        }}
                                      >
                                        <img
                                          src={plusBlue}
                                          height={18}
                                          width={18}
                                          style={{
                                            marginRight: '7px',
                                          }}
                                        />
                                        New Folder
                                      </button>
                                    </div>
                                  )}

                                  {/* Add New Folder Card */}
                                  {folderNameModal && (
                                    <div className='col-md-6 col-lg-4 col-xl-4'>
                                      <div className='card h-100'>
                                        <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4 test'>
                                          <div className='d-flex'>
                                            <img
                                              src={trashImg}
                                              height={18}
                                              width={18}
                                              className='cursor-pointer'
                                              onClick={() => setFolderNameModal(false)}
                                            />

                                            <img
                                              style={
                                                folderName?.trim()?.length > 0
                                                  ? {opacity: '1', cursor: 'pointer'}
                                                  : {opacity: '0.7', cursor: 'not-allowed'}
                                              }
                                              src={correct}
                                              height={30}
                                              width={30}
                                              className='ms-auto'
                                              onClick={() => {
                                                if (folderName?.trim()?.length > 0) addFolder()
                                              }}
                                            />
                                          </div>

                                          <a className='text-gray-800 text-hover-primary d-flex flex-column'>
                                            <div className='symbol symbol-60px mb-5'>
                                              <img
                                                src={
                                                  tenantData?.document?.contract?.split('.')[1] ===
                                                  'pdf'
                                                    ? pdfDocument
                                                    : tenantData?.other?.split('.')[1] === 'doc'
                                                    ? docCocument
                                                    : folderDocument
                                                }
                                                className='theme-light-show'
                                                alt=''
                                              />
                                              <img
                                                src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                                className='theme-dark-show'
                                                alt=''
                                              />
                                            </div>
                                            <div className='fs-5 fw-bold mb-2 '>
                                              <input
                                                type='text'
                                                className='form-control form-control-solid mytest w-75 m-auto'
                                                placeholder='Enter Folder Name'
                                                onChange={(e: any) => setFolderName(e.target.value)}
                                              >
                                                {fileFound ? (
                                                  <p className='mt-2' style={{color: 'red'}}>
                                                    Folder name already exists
                                                  </p>
                                                ) : null}
                                              </input>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  )}

                                  {folders?.length > 0 && (
                                    <>
                                      {viewFolder ? (
                                        <>
                                          <div className='d-flex align-items-center'>
                                            <span
                                              className='rounded-circle'
                                              style={{cursor: 'pointer'}}
                                              onClick={() => {
                                                setViewFolder(false)
                                                setEditFile(-1)
                                              }}
                                            >
                                              <img
                                                src={backArrow}
                                                style={{stroke: 'red'}}
                                                height='14'
                                                width='14'
                                              />
                                            </span>
                                            <h2 className='m-0 head-text ms-2'>
                                              {selectedFolder?.name}
                                            </h2>

                                            <label
                                              title='Add New File'
                                              htmlFor='uploadPassport'
                                              className='btn btn-sm fw-bold upload-btn ms-auto'
                                            >
                                              <img
                                                src={upload}
                                                height={18}
                                                width={18}
                                                className='me-3'
                                              />
                                              Upload File{' '}
                                            </label>
                                            <input
                                              type='file'
                                              hidden
                                              id='uploadPassport'
                                              className='form-control form-control-solid'
                                              placeholder='upload'
                                              name='passport'
                                              onChange={(e: any) => {
                                                onFilesAddedSingle(e, 0, '')
                                              }}
                                              multiple
                                            />
                                          </div>
                                          <div className='col-md-12 col-lg-12 col-xl-12'>
                                            <div className='card h-100'>
                                              <div className='card-body d-flex justify-content-center flex-column p-8'>
                                                {folderFiles?.length > 0 ? (
                                                  <>
                                                    <div
                                                      className='row py-3'
                                                      style={{color: '#a1a5b7'}}
                                                    >
                                                      <div className='col-md-6 '>File</div>
                                                      <div className='col-md-2 '>Date Added</div>
                                                      <div className='col-md-4'></div>
                                                    </div>

                                                    {folderFiles?.map((file: any, ind: any) => {
                                                      return (
                                                        <div
                                                          className='row py-3'
                                                          style={{
                                                            borderBottom: '1px solid lightgrey',
                                                          }}
                                                        >
                                                          {editFile == ind ? (
                                                            <>
                                                              <div className='col-md-6 head-text pe-5'>
                                                                <div className='d-flex'>
                                                                  <input
                                                                    type='text'
                                                                    value={getFilename(
                                                                      file?.fileName,
                                                                      false
                                                                    )}
                                                                    className='form-control form-control-solid p-2'
                                                                    onChange={(e: any) => {
                                                                      let temp = getFilename(
                                                                        file?.fileName,
                                                                        true
                                                                      )
                                                                      const values = [
                                                                        ...folderFiles,
                                                                      ]
                                                                      values[ind].fileName =
                                                                        e.target.value + '.' + temp
                                                                      setFolderFiles(values)
                                                                    }}
                                                                  />
                                                                  <a
                                                                    className='btn btn-sm fw-bold btn-primary btn-green'
                                                                    onClick={() => updateName()}
                                                                  >
                                                                    <img
                                                                      src={check}
                                                                      height={18}
                                                                      width={18}
                                                                    />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                            </>
                                                          ) : (
                                                            <div className='col-md-6 head-text'>
                                                              {getFilename(file?.fileName, false)
                                                                ?.length > 30
                                                                ? `${file?.fileName?.slice(
                                                                    0,
                                                                    30
                                                                  )}...${getFilename(
                                                                    file?.fileName,
                                                                    true
                                                                  )}`
                                                                : file?.fileName}
                                                            </div>
                                                          )}
                                                          <div className='col-md-2 head-text'>
                                                            {moment(file?.createdAt).format(
                                                              'DD.MM.YYYY'
                                                            )}
                                                          </div>
                                                          <div className='col-md-4 head-text text-end'>
                                                            <div className='d-flex justify-content-end'>
                                                              <img
                                                                src={eye}
                                                                height={20}
                                                                width={20}
                                                                className='me-3 cursor-pointer'
                                                                
                                                                onClick={() => {
                                                                  setIndex(ind)
                                                                }}
                                                              />
                                                               <img
                                                            src={downloadImg}
                                                            height={22}
                                                            width={22}
                                                            className='me-10 cursor-pointer'
                                                           
                                                            onClick={() => {
                                                              if(!isDisable)
                                                              handleDownload(Bucket + file?.fileURL)
                                                            }}
                                                            style={isDisable ? {opacity: '0.6'}: {opacity: '1'}}
                                                            
                                                          />
                                                              <img
                                                                src={pencil}
                                                                height={17}
                                                                width={17}
                                                                style={{cursor: 'pointer'}}
                                                                className='me-3'
                                                                onClick={() => {
                                                                  setNewName(
                                                                    getFilename(
                                                                      file?.fileName,
                                                                      false
                                                                    )
                                                                  )
                                                                  setEditFile(ind)
                                                                }}
                                                              />
                                                              <img
                                                                src={trash}
                                                                height={18}
                                                                width={18}
                                                                className='me-5 cursor-pointer'
                                                                
                                                                onClick={(e) => {
                                                                  Swal.fire({
                                                                    text: 'Are you sure you want to permanently delete this file?',
                                                                    icon: 'warning',
                                                                    showConfirmButton: true,
                                                                    confirmButtonColor: '#D72852',
                                                                    confirmButtonText: 'Yes',
                                                                    showCancelButton: true,
                                                                    // cancelButtonColor: "transparent",
                                                                    cancelButtonText: 'Cancel',
                                                                  }).then((res: any) => {
                                                                    if (res.isConfirmed) {
                                                                      onFilesAddedSingle(
                                                                        e,
                                                                        1,
                                                                        file?._id
                                                                      )
                                                                    }
                                                                  })
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      )
                                                    })}

                                                    {/* <div className='tops mw-350px d-flex gap-5 align-items-start'>
                                                {folderFiles?.map((file: any, ind: any) => {
                                                  return (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='80'
                                                          height='80'
                                                          className='main_img'
                                                        />

                                                        <img
                                                          style={{
                                                            marginLeft: '-13px',
                                                            marginTop: '-10px',
                                                            cursor: 'pointer',
                                                          }}
                                                          src={closered}
                                                       
                                                          width='25'
                                                          height='25'
                                                          onClick={(e) => {
                                                            onFilesAddedSingle(e, 1, file?._id)
                                                          }}
                                                        />
                                                      </div>
                                                      <p className=''>
                                                        {getFilename(file?.fileName, false)
                                                          ?.length > 6
                                                          ? `${file?.fileName?.slice(
                                                              0,
                                                              6
                                                            )}...${getFilename(
                                                              file?.fileName,
                                                              true
                                                            )}`
                                                          : file?.fileName}
                                                      </p>
                                                    </div>
                                                  )
                                                })}
                                              </div> */}
                                                  </>
                                                ) : (
                                                  <div className='text-center'> No Files</div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          {folders?.map((fl: any, index: any) => {
                                            return (
                                              <div className='col-md-6 col-lg-4 col-xl-4'>
                                                <div className='card h-100'>
                                                  <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4'>
                                                    {!fl?.isSystemGenerated ? (
                                                      <div className='d-flex'>
                                                        {editFolderName != index ? (
                                                          <>
                                                            <img
                                                              src={trashImg}
                                                              height={18}
                                                              width={18}
                                                              className='cursor-pointer ms-auto'
                                                              onClick={() => {
                                                                Swal.fire({
                                                                  text: 'Are you sure you want to permanently delete this folder?',
                                                                  icon: 'warning',
                                                                  showConfirmButton: true,
                                                                  confirmButtonColor: '#D72852',
                                                                  confirmButtonText: 'Yes',
                                                                  showCancelButton: true,
                                                                  // cancelButtonColor: "transparent",
                                                                  cancelButtonText: 'Cancel',
                                                                }).then((res: any) => {
                                                                  if (res.isConfirmed) {
                                                                    deleteFolder(fl?._id)
                                                                  }
                                                                })
                                                              }}
                                                            />
                                                            <img
                                                              src={pencil}
                                                              alt=''
                                                              height={18}
                                                              width={18}
                                                              className='ms-4'
                                                              style={{cursor: 'pointer'}}
                                                              // onClick={() => {
                                                              //   navigate(`/edit-tenant/${window.location.pathname.split('/')[2]}`)
                                                              // }}
                                                              onClick={() => {
                                                                setNewFolderName(fl?.name)
                                                                setEditFolderName(index)
                                                                setSelectedFolder(fl)
                                                              }}
                                                            />
                                                          </>
                                                        ) : (
                                                          <>
                                                            {/* <div className='d-flex'> */}
                                                              <img
                                                                src={trashImg}
                                                                height={18}
                                                                width={18}
                                                                className='cursor-pointer'
                                                                onClick={() =>
                                                                  setEditFolderName(-1)
                                                                }
                                                              />

                                                              <img
                                                                style={
                                                                  newFolderName?.trim()?.length > 0
                                                                    ? {
                                                                        opacity: '1',
                                                                        cursor: 'pointer',
                                                                      }
                                                                    : {
                                                                        opacity: '0.7',
                                                                        cursor: 'not-allowed',
                                                                      }
                                                                }
                                                                src={correct}
                                                                height={30}
                                                                width={30}
                                                                className='ms-auto'
                                                                onClick={() => {
                                                                  if (
                                                                    newFolderName?.trim()?.length > 0
                                                                  )
                                                                    updateFolderName()
                                                                }}
                                                              />
                                                            {/* </div> */}
                                                          </>
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <img
                                                        src={lock}
                                                        height='20px'
                                                        width='20px'
                                                        className='ms-auto'
                                                      />
                                                    )}
                                                    <a
                                                      // href={`${Bucket}${tenantData?.document?.contract}`}
                                                      // target='_blank'

                                                      className='text-gray-800 text-hover-primary d-flex flex-column'
                                                    >
                                                      <div
                                                        className='symbol symbol-60px mb-5'
                                                        onClick={() => {
                                                          getFolderFiles(fl)
                                                         
                                                        }}
                                                      >
                                                        <img
                                                          src={
                                                            tenantData?.document?.contract?.split(
                                                              '.'
                                                            )[1] === 'pdf'
                                                              ? pdfDocument
                                                              : tenantData?.other?.split('.')[1] ===
                                                                'doc'
                                                              ? docCocument
                                                              : folderDocument
                                                          }
                                                          className='theme-light-show'
                                                          alt=''
                                                        />
                                                        <img
                                                          src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                                          className='theme-dark-show'
                                                          alt=''
                                                        />
                                                      </div>
                                                      <div className='fs-5 fw-bold mb-2'>
                                                        {editFolderName == index ? (
                                                          <>
                                                            <input
                                                              type='text'
                                                              className='form-control form-control-solid mytest w-75 m-auto'
                                                              placeholder='Enter Folder Name'
                                                              value={newFolderName}
                                                              onChange={(e: any) =>
                                                                setNewFolderName(e.target.value)
                                                              }
                                                            >
                                                              {fileFound ? (
                                                                <p
                                                                  className='mt-2'
                                                                  style={{color: 'red'}}
                                                                >
                                                                  Folder name already exists
                                                                </p>
                                                              ) : null}
                                                            </input>
                                                          </>
                                                        ) : (
                                                          <h4>{fl?.name}</h4>
                                                        )}
                                                      </div>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          })}
                                        </>
                                      )}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={slides}
        carousel={{finite: slides.length <= 1}}
        render={{
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default EditTenant
