import { TimePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData';
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast';
import { KTSVG } from '../../../_metronic/helpers';
import MediaComments from './MediaComments';

const ReviewRequest = () => {

    const navigate = useNavigate()
    const [formData, setFormData] = useState<any>({})
    const [mainServiceData, setMainServiceData] = useState<any>()
    const [subServiceData, setSubServiceData] = useState<any>()
    const [requestTab, setRequestTab] = useState<any>([1])
    const [activeTab, setActiveTab] = useState<any>(1)
    const [showMedia, setShowMedia] = useState<any>(false)

    const format = 'HH:mm';

    const getmainServiceList = () => {
        ApiGet(`corporate/main_service`)
            .then((res) => {
                setMainServiceData(res?.data?.data)
            })
            .catch((err) => ErrorToast(err.message))
    }

    const getSubServiceData = () => {
        ApiGet(`corporate/sub_service`)
            .then((res) => {
                setSubServiceData(res?.data?.data)
            })
            .catch((err) => ErrorToast(err.message))
    }

    React.useEffect(() => {
        getmainServiceList()
        getSubServiceData()
    }, [])

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleAddServices = () => {
        if (formData?.mainServiceList !== undefined) {
            const data = {
                name: formData?.subServiceList ? formData?.subServiceList : formData?.subNewServiceList,
                note: formData?.note,
                serviceIds: [formData?.mainServiceList]
            }
            ApiPost(`corporate/sub_service`, data)
                .then((res) => {
                    SuccessToast(res?.data.message)
                    navigate('/facility')
                })
                .catch((err) => ErrorToast(err.message))
        } else {
            const body = {
                name: formData?.mainServiceList ? formData?.mainServiceList : formData?.mainNewServiceList ? formData?.mainNewServiceList : '',
                note: formData?.note,
                type: 0
            }
            ApiPost(`corporate/main_service`, body)
                .then((res) => {

                    if (res?.data?.data?._id && formData?.subServiceList !== undefined || formData?.subNewServiceList !== undefined) {
                        const data = {
                            name: formData?.subServiceList ? formData?.subServiceList : formData?.subNewServiceList,
                            note: formData?.note,
                            serviceIds: [res?.data?.data?._id]
                        }
                        ApiPost(`corporate/sub_service`, data)
                            .then((res) => {
                                SuccessToast(res?.data.message)
                                navigate('/facility')
                            })
                            .catch((err) => ErrorToast(err.message))
                    } else {
                        SuccessToast(res?.data.message)
                        navigate('/facility')
                    }
                })
                .catch((err) => ErrorToast(err.message))
        }
    }

    const handleChangeService = (e: any) => {
        if (e.target.value === 'propertlist') {
            navigate('/request-management/service/create-request-service/select-list')
        } else {
            navigate('/request-management/service/create-request-service/custom-list')
        }
    }
    const removeTab = (item: any) => {
        const secondIndex = requestTab.findIndex((data: any) => data === item);
        requestTab.splice(secondIndex, 1);
        if (requestTab.length === 0) {
            setRequestTab([1])
            setActiveTab(1)
        } else {
            setActiveTab(requestTab[0])
        }
    }

    return (
        <>
            <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>
                    <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-5'>
                        Service Request
                    </h1>
                    <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/request-management/service/create-request-service`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                                    Internal
                                </a>
                            </li>
                            <li
                                className='nav-item'
                            // onClick={() => {
                            //     navigate(`/request-management/quotation`)
                            // }}
                            >
                                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                                    Marketplace
                                </a>
                            </li>
                            <li
                                className='nav-item'
                            // onClick={() => {
                            //     navigate(`/request-management/service/create-vendor-service`)
                            // }}
                            >
                                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                                    VendorList
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className='d-flex flex-stack pt-3 mt-0 mb-10'>
                        <div className=''>
                            <a
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_create_app'
                                onClick={() => navigate('/request-management/service')}
                            >
                                Save All
                            </a>
                        </div>
                    </div>
                    <div className='d-flex pt-3 mt-0 mb-10'>
                        <div className=''>
                            <a
                                // onClick={() => setShowCreateAppModal(true)}
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_create_app'
                                onClick={() => { setRequestTab([...requestTab, requestTab[requestTab.length - 1] + 1]); setActiveTab(requestTab[requestTab.length - 1] + 1) }}
                            >
                                +
                            </a>
                        </div>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            {requestTab.map((item: any) => {
                                return (<li
                                    className='nav-item'
                                    onClick={() => setActiveTab(item)}
                                // onClick={() => {
                                //     navigate(`/request-management/service/create-request-service`)
                                // }}
                                >
                                    <a className={`${activeTab === item ? 'active' : ''} nav-link text-active-primary pb-4`} data-bs-toggle='tab'>
                                        Request {item}
                                    </a>
                                </li>
                                )
                            })}
                        </ul>
                    </div>
                    {requestTab.map((item: any) => {
                        return (<div className={`${activeTab === item ? 'd-show' : 'd-none'} card card-flush app-container py-5`}>
                            <Row >
                                <div className='col-md-3'>
                                    <a
                                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                    >
                                        Save
                                    </a>
                                    <a
                                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                        onClick={() => removeTab(item)}
                                    >
                                        Delete
                                    </a>
                                </div>
                                <div className='col-md-3'>
                                </div>
                                <div className='col-md-3'></div>
                                <div className='col-md-3' style={{ textAlign: 'end' }}>
                                    <a
                                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                        onClick={() => navigate('/request-management/service/create-request-service/approve-request')}
                                    >
                                        Submit
                                    </a>
                                </div>
                                <div className='col-md-3'>
                                    <div className='card-header align-items-center py-5 mt-5 gap-md-2 d-flex'>
                                        <h5 className='gray-500'>
                                            Request ID:{' '}
                                            <span className='' style={{ color: 'black' }}>
                                                ###
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='card-header align-items-center py-5 mt-5 gap-md-2 d-flex'>
                                        <h5 className='gray-500'>
                                            Requestor:{' '}
                                            <span className='' style={{ color: 'black' }}>
                                                USERNAME - Tenant
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <div className='col-md-3'></div>
                                <div className='col-md-3'>
                                </div>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <h3 className='mx-10 mb-10 mt-10'> Service Details</h3>
                                    <div className='min-w-200px mx-10'>
                                        <div className='mb-10 min-w-200px d-flex align-items-center'>
                                            <div className="d-flex align-items-center me-10 mb-3">
                                                <label htmlFor='exampleFormControlInput1' className='required min-w-100px form-label m-0'>
                                                    Main Service
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-solid'
                                                    onChange={handleChnage}
                                                    name='subNewServiceList'
                                                    disabled={formData?.subServiceList ? true : false}
                                                />
                                            </div>
                                            <div className="d-flex align-items-center me-10 mb-3">
                                                <label htmlFor='exampleFormControlInput1' className='required min-w-100px form-label m-0'>
                                                    Sub Service
                                                </label>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-solid'
                                                    onChange={handleChnage}
                                                    name='subNewServiceList'
                                                    disabled={formData?.subServiceList ? true : false}
                                                />
                                                <a
                                                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                    data-bs-toggle='modal'
                                                    data-bs-target='#kt_modal_create_app'
                                                    onClick={() => navigate('/request-management/service/create-request-service/select-list')}
                                                >
                                                    Select
                                                </a>
                                            </div>
                                        </div>
                                        <div className="d-flex me-10 mb-3">
                                            <div className='mb-10 service_list min-w-200px '>
                                                <h3 className='mb-5 mt-5'> Request Urgency </h3>
                                                <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                                                    <input
                                                        className='form-check-input'
                                                        type='radio'
                                                        value="Internally"
                                                        id="Internally"
                                                        // {...register("clusterunitNo", { required: true })}
                                                        name='servicehandle'
                                                    // checked={formData1?.clusterunitNo == data._id ? true : false}
                                                    // onChange={handleChnageunit}
                                                    />
                                                    <label className='form-check-label required' htmlFor='Internally' style={{ marginRight: '6px' }}>
                                                        Standard
                                                    </label>
                                                </div>
                                                <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                                    <input
                                                        className='form-check-input'
                                                        type='radio'
                                                        value="Internally"
                                                        id="Internally"
                                                        // {...register("clusterunitNo", { required: true })}
                                                        name='servicehandle'
                                                    // checked={formData1?.clusterunitNo == data._id ? true : false}
                                                    // onChange={handleChnageunit}
                                                    />
                                                    <label className='form-check-label required' htmlFor='Internally' style={{ marginRight: '6px' }}>
                                                        Emergency
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='mb-10 service_list min-w-200px '>
                                                <h3 className='mb-5 mt-5'>  Preferred Schedules For Service</h3>
                                                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                    <label className='text-muted mt-4 mr-2 required'>
                                                        Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className='mx-1 form-control form-control-solid'
                                                        name="from"
                                                    />
                                                    <label className='text-muted mt-4 mr-2 required'>
                                                        Time
                                                    </label>
                                                    <TimePicker className='form-control form-control-solid' size="large" defaultOpenValue={moment('00:00', 'HH:mm')} format={format} />
                                                </div>
                                                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                                    <label className='text-muted mt-4 mr-2 '>
                                                        Date
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className='mx-1 form-control form-control-solid'
                                                        name="from"
                                                    />
                                                    <label className='text-muted mt-4 mr-2 '>
                                                        Time
                                                    </label>
                                                    <TimePicker className='form-control form-control-solid' size="large" defaultOpenValue={moment('00:00', 'HH:mm')} format={format} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-10 '>
                                            <div>
                                                <h6 className='text-muted m-0 mr-2 min-w-100px mb-2'>
                                                    Comments (For Internal Reference)
                                                </h6>
                                                <textarea
                                                    className='form-control form-control-solid mb-8'
                                                    rows={3}
                                                    name='comment'
                                                    onChange={handleChnage}
                                                    placeholder='Type or paste emails here'
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className='mb-10 service_list min-w-200px '>
                                        <h3 className='mb-10 mt-10'> Facility Management Team </h3>
                                        <div className="d-flex align-items-center me-10 mb-3">
                                            <label htmlFor='exampleFormControlInput1' className='min-w-100px form-label m-0 required'>
                                                Assigned To
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control form-control-solid'
                                                onChange={handleChnage}
                                                name='subNewServiceList'
                                                disabled={formData?.subServiceList ? true : false}
                                            />
                                            <a
                                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                data-bs-toggle='modal'
                                                data-bs-target='#kt_modal_create_app'
                                                onClick={() => navigate('/request-management/service/create-request-service/internal-team')}
                                            >
                                                Select
                                            </a>
                                        </div>
                                        <div className='d-flex align-items-center mb-3'>

                                        </div>
                                        <div className='d-flex align-items-center mb-3'>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='align-items-start'>
                                <Col md={8}>
                                    <div className='mb-10 service_list  mx-10'>
                                        <h3 className='mb-5 mt-5'>  Properties </h3>
                                        <div className='d-flex align-items-center mb-3'>
                                            <a
                                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                data-bs-toggle='modal'
                                                data-bs-target='#kt_modal_create_app'
                                                onClick={() => navigate('/request-management/service/create-request-service')}

                                            >
                                                Select Properties
                                            </a>
                                        </div>
                                        <table
                                            className='table align-middle table-row-dashed fs-6 gy-5'
                                            id='kt_ecommerce_sales_table'
                                        >
                                            <thead>
                                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                                    <th className=' min-w-100px'>Job ID</th>
                                                    <th className=' min-w-100px'>Development</th>
                                                    <th className=' min-w-100px'>Floor#/Cluster</th>
                                                    <th className=' min-w-100px'>Unit #</th>
                                                    <th className=' min-w-100px'>Unit Type</th>
                                                    <th className=' min-w-100px'># Bedrooms</th>
                                                    <th className=' min-w-100px'></th>

                                                </tr>
                                            </thead>
                                            <tbody className='fw-semibold text-gray-600'>
                                                <tr>
                                                    <td>Job ID</td>
                                                    <td>Development</td>
                                                    <td>Floor#/Cluster</td>
                                                    <td>Unit #</td>
                                                    <td>Unit Type</td>
                                                    <td># Bedrooms</td>
                                                    <td>
                                                        <a className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                            data-bs-toggle='modal'
                                                            data-bs-target='#kt_modal_create_app'
                                                            onClick={() => setShowMedia(true)}>
                                                            Media & Comments
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                                <Col md={4} className='select-box'>
                                    <div className='mb-10 service_list'>
                                        <Row>
                                            <Col md={5}>
                                                <h5 className='mb-5 mt-5' style={{ color: '#007a59' }}>  Properties Selected</h5>
                                            </Col>
                                            <Col md={7}>
                                                <h5 className='mb-2 mt-5'>  Total Buildings/Communities :</h5>
                                                <h5 className='mb-2 '>  Total Units :</h5>
                                                <h5 className='mb-5 '>  Total Jobs :</h5>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        )
                    })}
                </div>
            </div>

            {/* <MediaComments
                show={showMedia}
                handleClose={() => {
                    setShowMedia(false)
                }}
                handleChnage={handleChnage}
            /> */}
        </>
    )
}

export default ReviewRequest

