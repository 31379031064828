import {useState, useEffect} from 'react'
import {MultiSelect} from 'react-multi-select-component'
import noData from '../../../img/NoData1.svg'
import backArrow from '../../../img/back-arrow.png'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import starYellow from '../../../img/yellow-star.png'
import pdfDocument from '../../../img/pdf.svg'
import docCocument from '../../../img/doc.svg'
import fileIcon from '../../../img/file.svg'
import videoImg from '../../../img/video.png'
import {Bucket} from '../../../apiCommon/helpers/API/ApiData'
import tenantUserFilledIconBlue from '../../../img/tenant-user-filled-blue.png'
import BusinessComments from './BusinessComments'
import TenantCommentsCRM from './TenantsComments'
import terminate from '../../../img/expired-tenc.png'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import {ApiGet, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import moment from 'moment'
import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import Swal from 'sweetalert2'

function ViewResidentRequest() {
  const navigate = useNavigate()
  const [requestData, setRequestData] = useState<any>()
  const {id} = useParams()

  const [priorityLists, setPriorityLists] = useState<any>([
    {label: 'Low', value: 0},
    {label: 'Medium', value: 1},
    {label: 'High', value: 2},
  ])
  const [selectedPriority, setSelectedPriority] = useState<any>([])

  const [requestTypesLists, setRequestTypesLists] = useState<any>([
    {label: 'Complaint', value: 1},
    {label: 'Maintenance', value: 0},
    {label: 'General Request', value: 2},
  ])
  const [selectedRequestTypes, setSelectedRequestTypes] = useState<any>([])

  const [assignRoleTypesLists, setAssignRoleTypesLists] = useState<any>([
    {label: 'Customer Support', value: 0},
    {label: 'Finance', value: 2},
    {label: 'Leasing', value: 3},
    {label: 'Maintenance', value: 1},
  ])
  const [selectedRoleType, setSelectedRoleType] = useState<any>([])

  const [updateData, setUpdateData] = useState<any>(false)

  //
  const getTicketById = () => {
    ApiGet(`corporate/crm_ticket/${id}`)
      .then((res: any) => {
        setRequestData(res?.data?.data)

        // Priority
        if (res?.data?.data?.priority == 0) setSelectedPriority([{label: 'Low', value: 0}])
        else if (res?.data?.data?.priority == 1) setSelectedPriority([{label: 'Medium', value: 1}])
        else if (res?.data?.data?.priority == 2) setSelectedPriority([{label: 'High', value: 2}])

        //  Type
        if (res?.data?.data?.type == 0) setSelectedRequestTypes([{label: 'Maintenance', value: 0}])
        else if (res?.data?.data?.type == 1)
          setSelectedRequestTypes([{label: 'Complaint', value: 1}])
        else if (res?.data?.data?.type == 2)
          setSelectedRequestTypes([{label: 'General Request', value: 2}])

        // Role Type
        if (res?.data?.data?.assignRoleType == 0)
          setSelectedRoleType([{label: 'Customer Support', value: 0}])
        else if (res?.data?.data?.assignRoleType == 1)
          setSelectedRoleType([{label: 'Maintenance', value: 1}])
        else if (res?.data?.data?.assignRoleType == 2)
          setSelectedRoleType([{label: 'Finance', value: 2}])
        else if (res?.data?.data?.assignRoleType == 3)
          setSelectedRoleType([{label: 'Leasing', value: 3}])
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  //
  const updateCRM = () => {
    const body = {
      id: id,
      priority: selectedPriority?.[0]?.value,
      type: selectedRequestTypes?.[0]?.value,
      assignRoleType: selectedRoleType?.[0]?.value,
    }

    ApiPut(`corporate/crm_ticket`, body)
      .then((res: any) => {
        getTicketById()
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  //
  useEffect(() => {
    getTicketById()
  }, [])

  //
  useEffect(() => {
    if (updateData != false) updateCRM()
  }, [updateData])

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, [])

  //
  const closeTicket = () => {
    const body = {
      id: id,
      status: 1,
    }

    ApiPut(`corporate/crm_ticket`, body)
      .then((res: any) => {
        getTicketById()
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  //
  const getDuration = (val: any) => {
    let duration = moment.duration(val, 'minutes')
    let displayValue = ''

    if (duration.asMinutes() < 60) {
      displayValue = `${duration.asMinutes().toFixed(0)} min`
    } else if (duration.asHours() < 24) {
      displayValue = `${duration.asHours().toFixed(0)} hrs`
    } else if (duration.asDays() < 7) {
      displayValue = `${duration.asDays().toFixed(0)} Days`
    } else if (duration.asWeeks() < 4) {
      displayValue = `${duration.asWeeks().toFixed(0)} Weeks`
    } else {
      displayValue = `${duration.asMonths().toFixed(0)} Months`
    }

    return displayValue
  }

  //
  const handleImageClick = (val: any, valSrc: any) => {
    const groupName = `group_attachments`

    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(val)
    if (image) {
      image.setAttribute('data-fancybox', groupName)
      image.setAttribute('data-src', valSrc)
    }

    // Add data-fancybox attribute to the related receipts

    requestData?.attachments?.map((attach: any, idx: any) => {
      const imgeNext = document.getElementById(`clickedAttachments${idx}`)
      if (imgeNext) {
        imgeNext.setAttribute('data-fancybox', groupName)
        imgeNext.setAttribute('data-src', `${Bucket}${attach?.fileURL}`)
        // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
      }
    })

    // Trigger the fancybox on the clicked image
    if (image) {
      image.click()
    }
  }

  return (
    <>
      <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
        {' '}
        {/* Header */}
        <div className='page-title d-flex flex-wrap'>
          <div className=' d-flex flex-stack pt-1 mt-0 px-0'>
            <div className='d-flex align-items-center mb-1'>
              <span
                className='rounded-circle cursor-pointer'
                onClick={() => navigate('/crm-residents')}
              >
                <img src={backArrow} height='14' width='14' />
              </span>
              <h2 className='m-0 head-text ms-2 text-capitalize'>
                <b>Resident Request</b>
              </h2>
            </div>
          </div>
          {requestData?.status == 0 && (
            <button
              className='btn btn-sm fw-bold term-btn ps-2 ms-auto btn-fit-content pe-5'
              onClick={() => {
                Swal.fire({
                  html: ` 
                  <div class='fs-3'> <b>Close Ticket</></div>
                  <div class='fs-5 mt-4' style="line-height: 1.5">You are about to close this ticket.Please ensure that this case has been resolved.Once closed, you can not reopen it. </div>
                  <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                  // icon: 'warning',
                  showConfirmButton: true,
                  confirmButtonColor: '#35475e',
                  confirmButtonText: 'Yes',
                  showCancelButton: true,
                  cancelButtonText: 'No',
                  cancelButtonColor: '#fff',
                  customClass: {
                    confirmButton: 'custom-confirm-button',
                    cancelButton: 'custom-cancel-button',
                    popup: 'custom-popup',
                  },
                  reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                }).then((res: any) => {
                  if (res.isConfirmed) {
                    closeTicket()
                  }
                })
              }}
            >
              <img src={terminate} height={18} width={18} className='cursor-pointer me-4' />
              Close Ticket
            </button>
          )}
        </div>
        {/* Details */}
        <div className='card card-flush py-5 mt-1' style={{minHeight: '75vh'}}>
          <div className='row mx-5'>
            {/* Request Details */}
            <div className='col-7'>
              <h2 className='top ps-0 white-dark-theme-color'>
                <b>Request Details</b>
              </h2>

              {/* Ticket  */}
              <div className='d-flex align-items-center mt-7'>
                <h4 className='my-2' style={{color: 'black'}}>
                  <label className='labl-gry light-dark-theme-color status-w-120'>
                    {' '}
                    Ticket #
                  </label>
                </h4>

                <span className='ps-2 my-2 white-dark-theme-color'>
                  <b>{requestData?.id ? requestData?.id : '-'}</b>
                </span>
              </div>

              {/* Created  */}
              <div className='d-flex align-items-center mt-4'>
                <h4 className='my-2' style={{color: 'black'}}>
                  <label className='labl-gry light-dark-theme-color status-w-120'>
                    {' '}
                    Created
                  </label>
                </h4>

                <span className='ps-2 my-2 white-dark-theme-color'>
                  <b>
                    {requestData?.createdAt
                      ? `${moment
                          ?.utc(requestData?.createdAt)
                          ?.local()
                          ?.format('DD.MM.YYYY - hh:mm A')}`
                      : '-'}
                  </b>
                </span>
              </div>

              {/* Status  */}
              <div className='d-flex align-items-center mt-4'>
                <h4 className='my-2' style={{color: 'black'}}>
                  <label className='labl-gry light-dark-theme-color status-w-120'>
                    {' '}
                    Status
                  </label>
                </h4>

                <span className='ps-2 my-2 white-dark-theme-color'>
                  <b>
                    <div
                      className={`status ${
                        requestData?.status == 0
                          ? 'status active-bar'
                          : requestData?.status == 1
                          ? 'status expired-bar'
                          : 'status no-st-bar'
                      } mx-auto status-w-110`}
                    >
                      {requestData?.status == 0
                        ? 'Open'
                        : requestData?.status == 1
                        ? 'Closed'
                        : '-'}
                    </div>
                  </b>
                </span>
              </div>

              {/* Priority  */}
              <div className='d-flex align-items-center mt-3'>
                <h4 className='my-2' style={{color: 'black'}}>
                  <label className='labl-gry light-dark-theme-color status-w-120'>
                    {' '}
                    Priority
                  </label>
                </h4>

                <span className='ps-2 my-2 test jobs'>
                  <div className='property-test d-none-multi-checbox'>
                    <div
                      className={`multi-select-container jobs-property ms-0 me-5   ${
                        selectedPriority.length === 0 ? 'no-value select ' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={priorityLists}
                        value={selectedPriority}
                        onChange={(e: any) => {
                          let newVal: any = e
                          if (e?.length > 1) newVal = e.slice(1)
                          setSelectedPriority(newVal)
                          setTimeout(() => {
                            setUpdateData(new Date())
                          }, 200)
                        }}
                        labelledBy=''
                        overrideStrings={{
                          allItemsAreSelected: '',
                        }}
                        hasSelectAll={false}
                        closeOnChangedValue={true}
                        disabled={requestData?.status == 1}
                      />
                    </div>
                  </div>
                </span>
              </div>

              {/* Type  */}
              <div className='d-flex align-items-center mt-1'>
                <h4 className='my-2' style={{color: 'black'}}>
                  <label className='labl-gry light-dark-theme-color status-w-120'>
                    {' '}
                    Type
                  </label>
                </h4>

                <span className='ps-2 my-2'>
                  <div className='property-test d-none-multi-checbox'>
                    <div
                      className={`multi-select-container jobs-property ms-0 me-5  ${
                        selectedRequestTypes.length === 0 ? 'no-value unassigned ' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={requestTypesLists}
                        value={selectedRequestTypes}
                        onChange={(e: any) => {
                          let newVal: any = e
                          if (e?.length > 1) newVal = e.slice(1)
                          setSelectedRequestTypes(newVal)
                          setTimeout(() => {
                            setUpdateData(new Date())
                          }, 200)
                        }}
                        labelledBy=''
                        overrideStrings={{
                          allItemsAreSelected: 'All',
                        }}
                        hasSelectAll={false}
                        closeOnChangedValue={true}
                        disabled={requestData?.status == 1}
                      />
                    </div>
                  </div>
                </span>
              </div>

              {/* Assigned To  */}
              <div className='d-flex align-items-center mt-1'>
                <h4 className='my-2' style={{color: 'black'}}>
                  <label className='labl-gry light-dark-theme-color status-w-120'>
                    {' '}
                    Assigned To
                  </label>
                </h4>

                <span className='ps-2 my-2'>
                  <div className='property-test d-none-multi-checbox'>
                    <div
                      className={`multi-select-container jobs-property ms-0 me-5  ${
                        selectedRoleType?.length === 0 ? 'no-value unassigned ' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={assignRoleTypesLists}
                        value={selectedRoleType}
                        onChange={(e: any) => {
                          let newVal: any = e
                          if (e?.length > 1) newVal = e.slice(1)
                          setSelectedRoleType(newVal)
                          setTimeout(() => {
                            setUpdateData(new Date())
                          }, 200)
                        }}
                        labelledBy=''
                        overrideStrings={{
                          allItemsAreSelected: 'All',
                        }}
                        hasSelectAll={false}
                        closeOnChangedValue={true}
                        disabled={requestData?.status == 1}
                      />
                    </div>
                  </div>
                </span>
              </div>

              {/* Duration  */}
              <div className='d-flex align-items-center mt-4'>
                <h4 className='my-2' style={{color: 'black'}}>
                  <label className='labl-gry light-dark-theme-color status-w-120'>
                    {' '}
                    Duration
                  </label>
                </h4>

                <span className='ps-2 my-2 white-dark-theme-color'>
                  <b>{requestData?.duration != null ? getDuration(requestData?.duration) : '-'}</b>
                </span>
              </div>

              {/* Request Rating */}
              <div className='d-flex my-3'>
                <h4 className='my-2' style={{color: 'black'}}>
                  <label className='labl-gry light-dark-theme-color status-w-120'>
                    {' '}
                    Request Rating
                  </label>
                  <span className='ms-0 white-dark-theme-color'>
                    <b>
                      {' '}
                      <img src={starYellow} height={18} width={18} className='me-1' />
                      {requestData?.rate != undefined ? requestData?.rate : '-'}
                    </b>
                  </span>
                </h4>
              </div>
            </div>

            {/* Requestor Card */}
            <div className='col-5'>
              <div className='card card-flush py-5 mt-2  min-res px-5'>
                <div className='d-flex  align-items-center'>
                  <h2 className='white-dark-theme-color' style={{fontWeight: '700'}}>
                    Requestor
                  </h2>
                </div>
                <div className='d-flex  align-items-center mt-3'>
                  <img src={tenantUserFilledIconBlue} height={45} width={45} className='m-0 p-0' />
                  <div className='d-block align-items-center ms-5'>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label style={{minWidth: '90px'}} className='labl-gry light-dark-theme-color'>
                        {' '}
                        Tenant Name
                      </label>
                    </h4>

                    <span className='my-2 ps-1 white-dark-theme-color'>
                      <b>
                        {requestData?.tenant?.length > 0
                          ? `${requestData?.tenant?.[0]?.firstName} ${requestData?.tenant?.[0]?.lastName}`
                          : `-`}
                      </b>
                    </span>
                  </div>
                </div>

                {/* Unit No.  */}
                <div className='d-flex align-items-center  mt-4'>
                  <h4 className='my-2' style={{color: 'black'}}>
                    <label style={{minWidth: '90px'}} className='labl-gry light-dark-theme-color'>
                      {' '}
                      Unit No.
                    </label>
                  </h4>

                  <span className='ms-5 ps-1 my-2 white-dark-theme-color'>
                    <b> {requestData?.unit?.length > 0 ? requestData?.unit?.[0]?.unitNo : '-'}</b>
                  </span>
                </div>

                {/* Type  */}
                <div className='d-flex align-items-center  mt-3'>
                  <h4 className='my-2' style={{color: 'black'}}>
                    <label style={{minWidth: '90px'}} className='labl-gry light-dark-theme-color'>
                      {' '}
                      Type
                    </label>
                  </h4>

                  <span className='ms-5 ps-1 my-2 white-dark-theme-color'>
                    <b>
                      {requestData?.unit?.[0]?.unitType != 2 ? (
                        <>
                          {requestData?.unit?.[0]?.unitType === 0
                            ? 'Apartment'
                            : requestData?.unit?.[0]?.unitType === 1
                            ? 'Penthouse'
                            : requestData?.unit?.[0]?.unitType === 2
                            ? `${requestData?.unit?.[0]?.commonAreaName}`
                            : requestData?.unit?.[0]?.unitType === 3
                            ? 'Townhouse'
                            : requestData?.unit?.[0]?.unitType === 4
                            ? 'Villa'
                            : requestData?.unit?.[0]?.unitType === 5
                            ? `Other - ${requestData?.unit?.[0]?.otherName}`
                            : requestData?.unit?.[0]?.unitType === 6
                            ? 'Office'
                            : requestData?.unit?.[0]?.unitType === 7
                            ? 'Restaurant'
                            : requestData?.unit?.[0]?.unitType === 8
                            ? 'Cafe'
                            : requestData?.unit?.[0]?.unitType === 9
                            ? 'Retail'
                            : '-'}
                        </>
                      ) : (
                        <>
                          {requestData?.unit?.[0]?.commonAreaName
                            ? requestData?.unit?.[0]?.commonAreaName
                            : '-'}
                        </>
                      )}
                    </b>
                  </span>
                </div>

                {/* Bedrooms  */}
                {requestData?.unit?.[0]?.unitType != 2 && (
                  <div className='d-flex align-items-center  mt-3'>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label style={{minWidth: '90px'}} className='labl-gry light-dark-theme-color'>
                        {' '}
                        Bedrooms
                      </label>
                    </h4>

                    <span className='ms-5 ps-1 my-2 white-dark-theme-color'>
                      <b>
                        {requestData?.unit?.[0]?.bedrooms ? requestData?.unit?.[0]?.bedrooms : '-'}
                      </b>
                    </span>
                  </div>
                )}

                {/* Floor  */}
                {requestData?.floorId && (
                  <div className='d-flex align-items-center  mt-3'>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label style={{minWidth: '90px'}} className='labl-gry light-dark-theme-color'>
                        {' '}
                        Floor
                      </label>
                    </h4>

                    <span className='ms-5 ps-1 my-2 white-dark-theme-color'>
                      <b> {requestData?.floor?.length > 0 ? requestData?.floor?.[0]?.name : '-'}</b>
                    </span>
                  </div>
                )}

                {/* Group  */}
                {requestData?.cluster?.[0]?.type == 2 && (
                  <div className='d-flex align-items-center  mt-3'>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label style={{minWidth: '90px'}} className='labl-gry light-dark-theme-color'>
                        {' '}
                        Group
                      </label>
                    </h4>

                    <span className='ms-5 ps-1 my-2 white-dark-theme-color'>
                      <b>
                        {' '}
                        {requestData?.unit_group?.length > 0
                          ? requestData?.unit_group?.[0]?.name
                          : '-'}
                      </b>
                    </span>
                  </div>
                )}

                {/* Cluster  */}
                {requestData?.clusterId && (
                  <div className='d-flex align-items-center  mt-3'>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label style={{minWidth: '90px'}} className='labl-gry light-dark-theme-color'>
                        {' '}
                        Cluster
                      </label>
                    </h4>

                    <span className='ms-5 ps-1 my-2 white-dark-theme-color'>
                      <b>
                        {requestData?.cluster?.length > 0 ? requestData?.cluster?.[0]?.name : '-'}
                      </b>
                    </span>
                  </div>
                )}

                {/* Development  */}
                <div className='d-flex align-items-center mt-3'>
                  <h4 className='my-2' style={{color: 'black'}}>
                    <label style={{minWidth: '90px'}} className='labl-gry light-dark-theme-color'>
                      {' '}
                      Development
                    </label>
                  </h4>

                  <span className='ms-5 ps-1 my-2 white-dark-theme-color'>
                    <b>
                      {requestData?.community?.length > 0
                        ? requestData?.community?.[0]?.name
                        : requestData?.building?.length > 0
                        ? requestData?.building?.[0]?.name
                        : '-'}
                    </b>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className='row mx-5 mt-3 d-flex'>
            {/* Request Attachments */}
            <div className='col-6 ps-2' style={{display: 'flex', flexDirection: 'column'}}>
              <div className='card card-flush py-5 container-xxl mt-5  min-res pe-5 me-5 h-100'>
                <h4 className='ms-1 labl-gry' style={{fontWeight: '700'}}>
                  Request Attachments
                </h4>

                {requestData?.attachments?.length > 0 ? (
                  <div
                    className='tops d-flex gap-5 align-items-start mx-2 flex-wrap mt-4'
                    style={{maxHeight: '200px', overflow: 'auto'}}
                  >
                    {requestData?.attachments?.map((file: any, ind: any) => {
                      const videoUrl = file?.fileURL
                      const filename = videoUrl.substring(videoUrl.lastIndexOf('/') + 1)
                      const filenameWithoutExtension = filename.substring(
                        0,
                        filename.lastIndexOf('.')
                      )
                      const thumbnailUrl = videoUrl.replace(
                        filename,
                        `thumbnail_${filenameWithoutExtension}.png`
                      )

                      return (
                        <>
                          <div className='imgs-set'>
                            <div className='d-flex mw-350px me-3 '>
                              {/* <a target='_blank' href={`${Bucket}${file}`}> */}
                              <img
                                src={
                                  file?.fileURL?.split('.')[1] === 'pdf'
                                    ? pdfDocument
                                    : file?.fileURL?.split('.').pop() === 'doc' ||
                                      file?.fileURL?.split('.').pop() === 'docx'
                                    ? docCocument
                                    : file?.fileURL?.split('.').pop() === 'jpeg' ||
                                      file?.fileURL?.split('.').pop() === 'jpg' ||
                                      file?.fileURL?.split('.').pop() === 'png' ||
                                      file?.fileURL?.split('.').pop() === 'svg' ||
                                      file?.fileURL?.split('.').pop() === 'gif'
                                    ? `${Bucket}${file?.fileURL}`
                                    : file?.fileURL?.split('.').pop()?.toLowerCase() === 'mp4' ||
                                      file?.fileURL?.split('.').pop()?.toLowerCase() === 'webm' ||
                                      file?.fileURL?.split('.').pop()?.toLowerCase() === 'avi' ||
                                      file?.fileURL?.split('.').pop()?.toLowerCase() === 'mkv' ||
                                      file?.fileURL?.split('.').pop()?.toLowerCase() === 'mov' ||
                                      file?.fileURL?.split('.').pop()?.toLowerCase() === 'flv' ||
                                      file?.fileURL?.split('.').pop()?.toLowerCase() === 'wmv'
                                    ? `${Bucket}${thumbnailUrl}`
                                    : fileIcon
                                }
                                // src={Bucket + file}
                                width={100}
                                height={100}
                                className='main_img mb-5 cursor-pointer'
                                id={`clickedAttachments${ind}`}
                                // data-fancybox
                                // data-src={`${Bucket}${file?.fileURL}`}
                                onClick={() =>
                                  handleImageClick(
                                    `clickedAttachments${ind}`,
                                    `${Bucket}${file?.fileURL}`
                                  )
                                }
                              />
                              {/* </a> */}
                            </div>
                          </div>
                        </>
                      )
                    })}
                  </div>
                ) : (
                  <div className='ms-1'>
                    <i className='gry-dark-text labl-gry i_grey'> No attachments available.</i>
                  </div>
                )}
              </div>
            </div>

            {/* Request Description */}
            <div className='col-6 pe-5' style={{display: 'flex', flexDirection: 'column'}}>
              <div className='card card-flush py-5 container-xxl mt-5  pe-5 me-5 ms-3 h-100'>
                <h4 className='ms-1 labl-gry white-dark-theme-color' style={{fontWeight: '700'}}>
                  Request Description
                </h4>

                <div style={{maxHeight: '200px', overflow: 'auto'}}>
                  <p className='ms-1 labl-gry mb-0 light-dark-theme-color' style={{fontWeight: '500'}}>
                    {requestData?.description ? requestData?.description : '-'}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* Comments */}
          <div className='row mx-1 d-flex mt-3'>
            <div
              className='col-12 col-md-6 position-relative'
              style={{display: 'flex', flexDirection: 'column'}}
            >
              {requestData?.status == 1 && (
                <div
                  className='tenantOpacityBox'
                  style={{zIndex: '1000', opacity: '0.7', cursor: 'not-allowed'}}
                ></div>
              )}

              {requestData && (
                <BusinessComments
                  modalData={requestData}
                  coments={requestData?.businessComments}
                  updateParent={() => {
                    getTicketById()
                  }}
                />
              )}
            </div>
            <div
              className='col-12 col-md-6 position-relative'
              style={{display: 'flex', flexDirection: 'column'}}
            >
              {requestData?.status == 1 && (
                <div
                  className='tenantOpacityBox'
                  style={{zIndex: '1000', opacity: '0.7', cursor: 'not-allowed'}}
                ></div>
              )}
              {requestData && (
                <TenantCommentsCRM
                  modalData={requestData}
                  coments={requestData?.tenantComments}
                  updateParent={() => {
                    getTicketById()
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewResidentRequest
