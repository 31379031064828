import React, {useState, useEffect, useRef} from 'react'
import backArrow from '../../../../img/back-arrow.png'
import {useNavigate, useParams} from 'react-router-dom'
import redCross from '../../../../img/remove.png'
import redCrossFilled from '../../../../img/remove-filled.png'
import {Row, Col, OverlayTrigger, Tooltip, Modal} from 'react-bootstrap'
import addWhite from '../../../../img/add-white.png'
import moment, {Moment} from 'moment'
import TextEditor from '../../General/TextEditor'
import {ApiGet, Bucket, ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import {Backdrop, CircularProgress} from '@mui/material'
import moveUpImg from '../../../../img/scroll-up.png'
import movedownImg from '../../../../img/scroll-down.png'
import {useForm} from 'react-hook-form'
import {useLocation} from 'react-router-dom'
import pngIcon from '../../../../img/png.png'
import jpgIcon from '../../../../img/jpg-file.png'
import jpegIcon from '../../../../img/jpeg.png'
import pdfIcon from '../../../../img/pdf.svg'
import docIcon from '../../../../img/doc.svg'
import pptIcon from '../../../../img/ppt.png'
import downloadIcon from '../../../../img/download.png'
import axios from 'axios'
import InputNotRequired from '../../General/InputNotRequired'

const GenerateInvoice = () => {
  const {flg, id} = useParams()
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const navigate = useNavigate()
  const {state} = useLocation()
  const [isLoading, setIsLoading] = useState<any>()

  const [showAccountDetails, setShowAccountDetails] = useState<any>(true)
  const [showNotes, setShowNotes] = useState<any>(true)

  const [invoiceData, setInvoiceData] = useState<any>({
    _id: '',
    name: 'INVOICE',
    invoiceNo: '',
    date: '',
    paymentSubItems: [],
    paymentMethod: 0,
    totalAmount: 0,
    saveStatus: 0,
    totalVAT: 0,
    subTotalAmount: 0,
    paymentAccountId: null,
    paymentId: null,
    status: 0,
    outstandingAmount: 0,
    itemDescriptions: [
      //   {
      //     title: 'Payment Terms',
      //     text: 'Payment Terms Defaullttt',
      //     html: '',
      //   },
    ],
    note: '',
    frontendStorage: {
      showAccountDetails: showAccountDetails,
      showNotes: showNotes
    }
  })

  var totalPrice: any = 0

  const [invoiceCompany, setInvoiceCompany] = useState<any>({
    name: '',
    address: '',
    phoneNumber: '',
    email: '',
    countryCode: ''
  })

  const [logo, setLogo] = useState<any>(null)
  const [open, setOpen] = useState<any>(false)

  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const [invRecDetails, setInvRecDetails] = useState<any>(null)

  // Sections
  const handleAddNewSections = () => {
    let values = {...invoiceData}
    let valueItems: any = [...invoiceData?.itemDescriptions]
    valueItems.push({
      name: '',
      content: '',
    })
    values.itemDescriptions = valueItems
    setInvoiceData(values)
  }

  //
  const handleRemoveSections = (index: any) => {
    let values = {...invoiceData}
    let valueItems: any = [...invoiceData?.itemDescriptions]
    valueItems.splice(index, 1)
    values.itemDescriptions = valueItems
    setInvoiceData(values)

    setTimeout(() => {
      setAutoSaveData(Date.now())
    }, 700)
  }

  //
  const getInvoiceDetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setInvRecDetails(res?.data?.data)
        // setInvoiceCompany(res?.data?.data?.invoiceCompany)
        // setLogo(res?.data?.data?.invoiceLogo)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const [paymentData, setPaymentData] = useState<any>({})

  //
  const getPaymentById = () => {
    ApiGet(`corporate/payment/${id}`)
      .then((res: any) => {
        setPaymentData(res?.data?.data)
        setIsLoading(false)
      })
      .catch((err: any) => {
        setOpen(false)
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getInvoiceDetails()
    getPaymentById()
  }, [])

  //
  const updateInvoice = (isBack: boolean) => {
    if(isBack)
      setOpen(true)

    const body = {
      payment: {
        _id: id,
      },
      update_payment_invoice: [invoiceData],
    }

    ApiPost(`corporate/payment/v1/add_update`, body)
      .then((res: any) => {
        if(isBack)
          navigate(`/financials/add-income-payment/2/${id}`)
        // else
        //   getInvoiceById(paymentData?.payment_invoice?.[0]?._id)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  useEffect(() => {
    if (autoSaveData != false) updateInvoice(false)
  }, [autoSaveData])

  //
  const swapItems = (prevInd: any, nextInd: any) => {
    const values = {...invoiceData}
    const valuesSections = [...invoiceData?.itemDescriptions]

    // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    setInvoiceData({...values, itemDescriptions: valuesSections})

    setTimeout(() => {
      let temp = valuesSections[prevInd]
      valuesSections[prevInd] = valuesSections[nextInd]
      valuesSections[nextInd] = temp
      valuesSections[prevInd].animationClass = ''
      valuesSections[nextInd].animationClass = ''

      values.itemDescriptions = valuesSections
      setInvoiceData(values)
    }, 300)

    setTimeout(() => {
      setAutoSaveData(Date.now())
    }, 700)

    // setTimeout(() => {
    //   valuesSections[prevInd].animationClass = ''
    //   valuesSections[nextInd].animationClass = ''

    //   values.itemDescriptions = valuesSections
    //   setInvoiceData(values)
    // }, 200)
  }

  const firstErrorInputRef = useRef<any>(null)

  var nonVat: any = 0
  var vatAmount: any = 0

  //
  const handleImageClickCheques = () => {
    const groupName = `group_attachments_others`

    paymentData?.chequeImages?.map((receipt: any, idx: any) => {
      const imgeNext = document.getElementById(`clickedAttachments${idx}`)
      if (imgeNext) {
        imgeNext.setAttribute('data-src', `${Bucket}${receipt?.URL}`)
        imgeNext.setAttribute('data-fancybox', groupName)

        // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
      }

      // Trigger the fancybox on the clicked image
      // if (imgeNext) {
      //   imgeNext.click()
      // }
    })
  }

  //
  const handleDownload = async (fileUrl: any) => {
    // setIsDisable(true)
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      })

      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: contentType})

      // Determine file extension based on content type
      const extensionMap: {[key: string]: string} = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        // Add more mappings as needed
      }

      const extension = extensionMap[contentType] || 'file'

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `downloaded_file.${extension}`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      // setIsDisable(false)
    } catch (error) {
      // setIsDisable(false)
      ErrorToast('Error downloading file')
    }
  }

  useEffect(() => {
    if (paymentData?.payment_invoice?.[0]?._id)
      getInvoiceById(paymentData?.payment_invoice?.[0]?._id)
  }, [paymentData])

  //
  const getInvoiceById = async (id: any) => {
    let url = `corporate/payment_invoice/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        let values: any = {
          _id: res?.data?.data?._id,
          name: res?.data?.data?.name,
          invoiceNo: res?.data?.data?.invoiceNo,
          date: res?.data?.data?.date,
          paymentSubItems: res?.data?.data?.paymentSubItems,
          paymentMethod: res?.data?.data?.paymentMethod,
          totalAmount: res?.data?.data?.totalAmount,
          saveStatus: res?.data?.data?.saveStatus,
          totalVAT: res?.data?.data?.totalVAT,
          subTotalAmount: res?.data?.data?.subTotalAmount,
          paymentAccountId: res?.data?.data?.paymentAccountId,
          paymentId: res?.data?.data?.payment?.[0]?._id,
          status: 0,
          outstandingAmount: res?.data?.data?.outstandingAmount,
          itemDescriptions: res?.data?.data?.itemDescriptions,
          note: res?.data?.data?.note,
          frontendStorage: res?.data?.data?.frontendStorage,
          invoiceCompany: res?.data?.data?.invoiceCompany,
          invoiceLogo: res?.data?.data?.invoiceLogo

        }
        setInvoiceData(values)

        // Show OR Hide Payments Card
        setInvoiceCompany(res?.data?.data?.invoiceCompany)
        setLogo(res?.data?.data?.invoiceLogo)

        setShowAccountDetails(res?.data?.data?.frontendStorage?.showAccountDetails)

        // Show OR Hide Notes
        setShowNotes(res?.data?.data?.frontendStorage?.showNotes)

      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        {/* <form onSubmit={handleSubmit((data: any, e: any) => {})}> */}
        <div id='' className='pt-0 mt-0'>
          {' '}
          {/* Header */}
          <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
            <div className=' d-flex flex-stack pt-1 mt-0 px-0'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <span
                  className='rounded-circle cursor-pointer'
                  onClick={() => {

                    updateInvoice(true)
                  }}
                >
                  <img src={backArrow} height='14' width='14' />
                </span>
                <h2 className='m-0 head-text ms-2 text-capitalize'>{`Invoice - Draft`}</h2>
              </div>
            </div>
          </div>
          <div className='card mt-5 px-6 py-5' style={{minHeight: '75vh'}}>
            <Row className=''>
              <Col xs={6} sm={6} md={9} lg={9}>
                <div className='d-flex mb-3 mt-2 align-items-center'>
                  {(logo == null || logo?.trim()?.length == 0) ? (
                    <></>
                  ) : (
                    <img
                      // src={`${Bucket}${logo}`}
                      src={`${Bucket}${logo}`}
                      height='110'
                      width='110'
                      style={{borderRadius: '7px'}}
                      className='me-4'
                    />
                  )}
                </div>
              </Col>
              <Col xs={6} sm={6} md={3} lg={3} className='pe-4'>
                <div className='d-flex align-items-center my-2'>
                  <label
                    className='mb-1 me-5 pe-5 ms-auto head-text'
                    style={{minWidth: '50px', maxWidth: '50px'}}
                  >
                    <b>TRN</b>
                  </label>
                  <b>{invoiceCompany?.trn ? invoiceCompany?.trn : '-'}</b>
                </div>
                <div className='d-flex align-items-center my-2 '>
                  <h1 className='ms-auto mb-0'>
                    {/* <input
                      {...register('documentName', {required: true})}
                      type='text'
                      className='form-control form-control-solid mytest tenancy-control mb-3 bg-white text-end'
                      name='documentName'
                      value={invoiceData?.name}
                      style={{height: '35px', width: '300px'}}
                      placeholder={'Enter Document Name'}
                      onChange={(e: any) => {
                        let values = {...invoiceData}
                        let newVal: any =
                          e.target.value != ''
                            ? e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                            : e.target.value
                        values.name = newVal
                        setInvoiceData(values)
                        setValue('documentName', e.target.value)
                      }}
                      onBlur={() => {
                        setTimeout(() => {
                          setAutoSaveData(Date.now())
                        }, 700)
                      }}
                    /> */}

                    <InputNotRequired
                      register={register}
                      errors={errors}
                      value={invoiceData.name}
                      setValue={setValue}
                      trigger={trigger}
                      onBlur={() => {
                        setTimeout(() => {
                          setAutoSaveData(Date.now())
                        }, 700)
                      }}
                      onChange={(val: any) => {
                        setInvoiceData({ ...invoiceData,
                          ['name']: val})
                      }} // Pass the callback function to handle input changes
                      placeholder='Enter Document Name'
                      name='documentName'
                      pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                      maxLength={30} // Maximum length of 50 characters
                      // style={{height: '35px', width: '300px'}}
                      className='text-end document-name-input-field'
                    />

                    
                  </h1>
                </div>

                {/*  */}
                <div className='d-flex align-items-start justify-content-start my-2'>
                  <label
                    className='mb-1 me-3 ms-15 pt-2'
                    style={{
                      minWidth: '70px',
                      fontSize: '1rem',
                      color: '#516176',
                      fontWeight: '600',
                    }}
                  >
                    Invoice No.
                  </label>
                  {invRecDetails?.invoiceSequence?.activityStatus == 1 ? (
                    <>
                      {invRecDetails?.invoiceSequence?.prefix != null && (
                        <p className="pt-2 mb-0 ms-auto">{invoiceData.invoiceNo ? invoiceData.invoiceNo : '-'}</p>
                      )}
                    </>
                  ) : (
                    <div className='ms-auto' style={{fontWeight: '500'}}>

                      {/* <input
                        type='text'
                        className={'form-control form-control-solid-bg input-style mytest bg-white'}
                        // className='form-control form-control-solid-bg input-style'
                        placeholder='Enter Invoice No'
                        name='item_id'
                        value={invoiceData?.invoiceNo}
                        onChange={(e: any) => {
                          let values = {...invoiceData}
                          let newVal: any =
                            e.target.value != ''
                              ? e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                              : e.target.value
                          values.invoiceNo = newVal
                          setInvoiceData(values)
                        }}
                        onBlur={() => {
                          setTimeout(() => {
                            setAutoSaveData(Date.now())
                          }, 700)
                        }}
                      /> */}

                    <InputNotRequired
                      register={register}
                      errors={errors}
                      value={invoiceData.invoiceNo}
                      setValue={setValue}
                      trigger={trigger}
                      onBlur={() => {
                        setTimeout(() => {
                          setAutoSaveData(Date.now())
                        }, 700)
                      }}
                      onChange={(val: any) => {
                        
                        setInvoiceData({ ...invoiceData,
                          ['invoiceNo']: val})
                      }} // Pass the callback function to handle input changes
                      placeholder='Invoice No.'
                      name='documentInvoiceNo'
                      pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                      maxLength={15} // Maximum length of 50 characters
                      style={{height: '35px'}}
                      className='your-custom-class'
                    />

                    </div>
                  )}
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2'>
                  <label
                    className='mb-1 me-3 ms-15'
                    style={{
                      minWidth: '70px',
                      fontSize: '1rem',
                      color: '#516176',
                      fontWeight: '600',
                    }}
                  >
                    Invoice Date
                  </label>
                  <div className='ms-auto' style={{fontWeight: '500'}}>
                    {' '}
                    {flg == '2' ? moment(invoiceData?.date)?.format('DD.MM.YYYY') : '-'}
                  </div>
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2'>
                  <label
                    className='mb-1 me-3 ms-15'
                    style={{
                      minWidth: '70px',
                      fontSize: '1rem',
                      color: '#516176',
                      fontWeight: '600',
                    }}
                  >
                    Due Date
                  </label>
                  <div className='ms-auto' style={{fontWeight: '500'}}>
                    {' '}
                    {paymentData?.dueDate
                      ? moment(paymentData?.dueDate)?.format('DD.MM.YYYY')
                      : '-'}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className='mt-5'>
              {/* Vendor Details */}
              <Col md={6}>
                <div style={{fontWeight: '600'}} className='w-75'>
                  <div className='d-flex align-items-center'>
                    <h3 className='mb-3 fs-3'>
                      <b>Recipient</b>
                    </h3>
                  </div>

                  <p style={{marginBottom: '0.1rem'}}>
                    {paymentData?.tenant?.firstName} {paymentData?.tenant?.lastName}
                  </p>
                  <p style={{marginBottom: '0.1rem'}}>
                    {paymentData?.tenant?.countryCode} {paymentData?.tenant?.phoneNumber}
                  </p>
                  <p style={{marginBottom: '0.1rem'}}>{paymentData?.tenant?.email}</p>
                </div>
              </Col>
              {/* Delivery Details */}

              <Col md={6} className=''>
                <div className='text-end' style={{fontWeight: '600'}}>
                  <p style={{marginBottom: '0.1rem'}}>
                    {' '}
                    <b>{invoiceCompany?.name != null && invoiceCompany?.name}</b>
                  </p>
                  <p style={{marginBottom: '0.1rem'}} className='fs-7'>
                    {' '}
                    {invoiceCompany?.address != null && invoiceCompany?.address}
                  </p>

                  <p style={{marginBottom: '0.1rem'}} className='fs-7'>
                    {invoiceCompany?.phoneNumber != null &&
                      invoiceCompany?.countryCode + invoiceCompany?.phoneNumber}
                  </p>
                  <p style={{marginBottom: '0.1rem'}} className='fs-7'>
                    {' '}
                    {invoiceCompany?.email != null && invoiceCompany?.email}
                  </p>
                </div>
              </Col>
            </Row>
            <Row className='mt-7 separator mx-0'></Row>

            <div
              className='table-responsive mt-4 mb-10'
            >
              <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
                <thead>
                  <th className='text-start min-w-25px ps-3'>#</th>
                  <th className='text-start min-w-225px ps-3'>Description</th>
                  <th className='text-start min-w-100px ps-3'>Rate</th>
                  <th className='text-start min-w-100px ps-3'>QTY</th>
                  <th className='text-start min-w-100px ps-3'>VAT</th>
                  <th className='text-start min-w-100px ps-3'>Amount</th>
                </thead>

                <>
                  {paymentData?.payment_sub_item?.map((itm: any) => {
                    totalPrice = totalPrice + parseFloat(itm?.quantity) * parseFloat(itm?.amount)
                    return <></>
                  })}
                </>

                <tbody className='fw-semibold'>
                  {paymentData?.payment_sub_item?.map((item: any, index: any) => {
                    return (
                      <>
                        <tr key={index}>
                          <td className='text-start min-w-25px ps-3'>
                            {' '}
                            <p className='pt-2' style={{fontWeight: '700'}}>
                              {index < 9 ? `0${index + 1}` : index + 1}
                            </p>
                          </td>
                          <td className='text-start min-w-225px ps-3'>
                            {item?.name ? item?.name : '-'}
                          </td>
                          <td className='text-start min-w-100px ps-3'>
                            {item?.amount ? item?.amount : '0'}
                          </td>
                          <td className='text-start min-w-100px ps-3'>
                            {item?.quantity ? item?.quantity : '1'}
                          </td>
                          <td className='text-start min-w-100px ps-3'>
                            {item?.frontendStorage?.isLatePaymentItem ? (
                              <>{(item.amount / 100) * item.VATPercentage}</>
                            ) : (
                              <>{((item.amount * item.quantity) / 100) * item.VATPercentage}</>
                            )}
                          </td>
                          <td className='text-start min-w-100px ps-3'>
                            {item?.totalAmount ? item?.totalAmount : '0'}
                          </td>
                        </tr>
                      </>
                    )
                  })}

                  <>
                    {paymentData?.payment_sub_item?.map((f: any, ind: any) => {
                      vatAmount = 0
                      nonVat = 0
                      return <></>
                    })}
                  </>
                  {paymentData?.payment_sub_item?.length > 0 && (
                    <>
                      {paymentData?.payment_sub_item?.map((f: any, ind: any) => {
                        if (f?.frontendStorage?.isLatePaymentItem) {
                          vatAmount = vatAmount + (f.amount / 100) * f.VATPercentage
                          nonVat = nonVat + f.amount
                        } else {
                          vatAmount = vatAmount + ((f.amount * f.quantity) / 100) * f.VATPercentage
                          nonVat = nonVat + f.amount * f.quantity
                        }
                        return <></>
                      })}
                    </>
                  )}

                  <tr style={{height: '40px'}}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='py-2 ps-5 fs-4' style={{color: '#B5B5C3'}}>
                      SUB TOTAL
                    </td>
                    <td className='py-2 fs-4'>
                      {nonVat.toLocaleString('en-US', {minimumFractionDigits: 2})}
                    </td>
                  </tr>

                  <tr style={{height: '40px'}}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='py-2 ps-5 fs-4' style={{color: '#B5B5C3'}}>
                      VAT
                    </td>
                    <td className='py-2 fs-4'>
                      {vatAmount.toLocaleString('en-US', {minimumFractionDigits: 2})}
                    </td>
                  </tr>
                  <tr style={{height: '40px'}} className='pb-4'>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className='py-2 ps-5 fs-4'>Total</td>
                    <td className='py-2 fs-3 fs-4' style={{fontWeight: '800px'}}>
                      {' '}
                      <b>
                        AED{' '}
                        {(nonVat + vatAmount).toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <Row className='mt-5 separator mb-10 mx-0'></Row>

            <Row>
              {showAccountDetails && (
                <Col sm={12} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                  <div className={'card card-flush py-5 container-xxl mt-2  pe-5 mb-5 px-6 h-100'}>
                    <div className='d-flex'>
                      <h2 className='top ps-0'>
                        <b>Payment Method</b>
                      </h2>

                      <h3 className='head-text ms-auto min-w-200px'>
                        {Number(paymentData?.paymentMethod) == 0
                          ? 'Card'
                          : Number(paymentData?.paymentMethod) == 1
                          ? 'Cheque'
                          : Number(paymentData?.paymentMethod) == 2
                          ? 'Bank'
                          : 'Cash'}
                      </h3>

                      <img
                        src={redCrossFilled}
                        height={18}
                        width={18}
                        className='cursor-pointer'
                        onClick={() => {
                          setShowAccountDetails(false)

                          let values = {...invoiceData}
                          values.frontendStorage.showAccountDetails = false
                          setInvoiceData(values)

                          setTimeout(() => {
                            setAutoSaveData(Date.now())
                          }, 700)
                        }}
                      />
                    </div>
                    <div className='d-flex mt-3 mb-0 align-items-center'>
                      <h4 className='my-2' style={{color: 'black'}}>
                        <label style={{width: '130px'}} className='labl-gry '>
                          {' '}
                          Account Name
                        </label>
                      </h4>

                      <span className='ms-1'>
                        <b>
                          {paymentData?.payment_account?.accountHolderName
                            ? paymentData?.payment_account?.accountHolderName
                            : '-'}
                        </b>
                      </span>
                    </div>
                    <div className='d-flex my-1 mb-0 align-items-center'>
                      <h4 className='my-2' style={{color: 'black'}}>
                        <label style={{width: '130px'}} className='labl-gry '>
                          {' '}
                          IBAN
                        </label>
                      </h4>

                      <span className='ms-1'>
                        <b>
                          {paymentData?.payment_account?.IBAN
                            ? paymentData?.payment_account?.IBAN
                            : '-'}
                        </b>
                      </span>
                    </div>

                    <div className='d-flex my-1 mb-0 align-items-center'>
                      <h4 className='my-2' style={{color: 'black'}}>
                        <label style={{width: '130px'}} className='labl-gry '>
                          {' '}
                          SWIFT
                        </label>
                      </h4>

                      <span className='ms-1'>
                        <b>
                          {paymentData?.payment_account?.swiftCode
                            ? paymentData?.payment_account?.swiftCode
                            : '-'}
                        </b>
                      </span>
                    </div>

                    <div className='d-flex my-1 mb-0 align-items-center'>
                      <h4 className='my-2' style={{color: 'black'}}>
                        <label style={{width: '130px'}} className='labl-gry '>
                          {' '}
                          Account No.
                        </label>
                      </h4>

                      <span className='ms-1'>
                        <b>
                          {paymentData?.payment_account?.accountNumber
                            ? paymentData?.payment_account?.accountNumber
                            : '-'}
                        </b>
                      </span>
                    </div>
                    <div className='d-flex my-1 mb-0 align-items-center'>
                      <h4 className='my-2' style={{color: 'black'}}>
                        <label style={{width: '130px'}} className='labl-gry '>
                          {' '}
                          Bank Name
                        </label>
                      </h4>

                      <span className='ms-1'>
                        <b>
                          {paymentData?.payment_account?.bankName
                            ? paymentData?.payment_account?.bankName
                            : '-'}
                        </b>
                      </span>
                    </div>

                    {Number(paymentData?.paymentMethod) == 1 && (
                      <>
                        <div
                          className='d-flex align-items-center mt-2 pb-5 mb-4 mx-3'
                          style={{borderBottom: '0.1rem solid #bec3cb'}}
                        ></div>
                        <div
                          className='table-responsive mt-4'
                          style={{maxHeight: '300px', overflow: 'auto'}}
                        >
                          <table
                            className='table align-middle  fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                          >
                            {/* <thead>
                                        <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                                        <th className='text-start min-w-100px'></th>
                                        <th className='text-start min-w-150px'></th>
                                        </tr>
                                     </thead> */}

                            <tbody className='fw-semibold text-gray-600'>
                              {paymentData?.paymentData?.chequeImages?.map(
                                (chq: any, index: any) => {
                                  return (
                                    <tr className='cursor-pointer'>
                                      <td className='text-start ps-0'>
                                        <div className='d-flex align-items-center'>
                                          <div className='bullet-item me-3 mb-0 ms-1'></div>
                                          <p className='mb-0'>{chq?.name}</p>
                                        </div>
                                      </td>
                                      <td className='text-end'>
                                        <div
                                          className='d-flex align-items-center ms-auto'
                                          style={{width: 'max-content'}}
                                        >
                                          <img
                                            src={
                                              chq?.URL?.split('.')?.pop() === 'png'
                                                ? pngIcon
                                                : chq?.URL?.split('.')?.pop() === 'jpg'
                                                ? jpgIcon
                                                : chq?.URL?.split('.')?.pop() === 'pdf'
                                                ? pdfIcon
                                                : chq?.URL?.split('.')?.pop() === 'jpeg'
                                                ? jpegIcon
                                                : chq?.URL?.split('.')?.pop() === 'docx'
                                                ? docIcon
                                                : chq?.URL?.split('.')?.pop() === 'doc'
                                                ? docIcon
                                                : chq?.URL?.split('.')?.pop() === 'pptx'
                                                ? pptIcon
                                                : chq?.URL?.split('.')?.pop() === 'ppt'
                                                ? pptIcon
                                                : pdfIcon
                                            }
                                            height={30}
                                            width={30}
                                            className='ms-3'
                                            id={`clickedAttachments${index}`}
                                            onClick={() => {
                                              handleImageClickCheques()
                                            }}
                                            // data-fancybox
                                            data-src={`${Bucket}${chq?.URL}`}
                                          />
                                          {/* <a href={`${Bucket}${chq?.URL}`} target='_blank'> */}
                                          <img
                                            src={downloadIcon}
                                            height={18}
                                            width={18}
                                            className='ms-5 cursor-pointer'
                                            onClick={() => {
                                              handleDownload(`${Bucket}${chq?.URL}`)
                                            }}
                                          />
                                          {/* </a> */}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </Col>
              )}
              {showNotes && (
                <Col sm={12} md={6} style={{display: 'flex', flexDirection: 'column'}}>
                  <div className={'card card-flush py-5 container-xxl mt-2  pe-5 mb-5 px-6 h-100'}>
                    <div className='mb-5 h-10'>
                      <div className='d-flex align-items-start mb-4'>
                        <h2 className='me-1' style={{minWidth: '160px', fontWeight: '700'}}>
                          Notes
                        </h2>
                        <img
                          src={redCrossFilled}
                          height={18}
                          width={18}
                          className='cursor-pointer ms-auto'
                          onClick={() => {
                            setShowNotes(false)

                            let values = {...invoiceData}
                            values.frontendStorage.showNotes = false
                            setInvoiceData(values)

                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }}
                        />
                      </div>

                      <textarea
                        className='form-control form-control-solid-bg input-style mytest bg-white'
                        name='comment'
                        placeholder='Enter any additional notes to the recipient'
                        style={{height: '150px', borderColor: 'lightgray'}}
                        value={invoiceData?.note}
                        onChange={(e) => {
                          const values = {...invoiceData}
                          values.note = e.target.value
                          setInvoiceData(values)
                        }}
                        maxLength={1000}

                        onBlur={(()=>{
                          setTimeout(() => {
                            setAutoSaveData(Date.now())
                          }, 700)
                        })}
                      ></textarea>
                    </div>
                  </div>
                </Col>
              )}
            </Row>

            {invoiceData?.itemDescriptions?.map((pr: any, ind: any) => {
              return (
                <div className={`${pr.animationClass}`}>
                  <div className={`d-flex mb-5 mt-10 align-items-center`}>
                    {pr?.text == 'Payment Terms Defaullttt' ? (
                      <h3 className='m-0 head-text text-capitalize'>
                        <b>{`Payment Terms`}</b>
                      </h3>
                    ) : (
                      <h3 className='m-0 head-text text-capitalize'>
                        <input
                          type='text'
                          className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                          name='mainService'
                          value={pr?.title ? pr?.title : ''}
                          style={{height: '35px', width: '300px'}}
                          placeholder={'Add Section Title'}
                          onChange={(e: any) => {
                            let values = {...invoiceData}
                            let valuesSections = [...invoiceData?.itemDescriptions]
                            valuesSections[ind].title = e.target.value
                            values.itemDescriptions = valuesSections
                            setInvoiceData(values)
                          }}
                          onBlur={() => {
                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }}
                        />
                      </h3>
                    )}
                    <div className='d-flex ms-auto align-items-center'>
                      {invoiceData?.itemDescriptions?.length > 1 && (
                        <>
                          {ind != invoiceData?.itemDescriptions?.length - 1 && (
                            <OverlayTrigger
                              placement='top'
                              delay={{show: 250, hide: 400}}
                              overlay={
                                <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                  {`Move this section one step down`}
                                </Tooltip>
                              }
                            >
                              <span>
                                {' '}
                                <img
                                  src={movedownImg}
                                  height={20}
                                  width={20}
                                  className='cursor-pointer'
                                  onClick={() => {
                                    swapItems(ind, ind + 1)
                                  }}
                                />
                              </span>
                            </OverlayTrigger>
                          )}

                          {ind != 0 && (
                            <OverlayTrigger
                              placement='top'
                              delay={{show: 250, hide: 400}}
                              overlay={
                                <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                  {`Move this section one step up`}
                                </Tooltip>
                              }
                            >
                              <span>
                                {' '}
                                <img
                                  src={moveUpImg}
                                  height={20}
                                  width={20}
                                  className='ms-5 cursor-pointer'
                                  onClick={() => {
                                    swapItems(ind - 1, ind)
                                  }}
                                />
                              </span>
                            </OverlayTrigger>
                          )}
                        </>
                      )}

                      <img
                        src={redCrossFilled}
                        height={18}
                        width={18}
                        className='ms-5 cursor-pointer'
                        onClick={() => {
                          handleRemoveSections(ind)
                        }}
                      />
                    </div>
                  </div>

                  <TextEditor
                    value={pr?.html}
                    onChange={(val: any) => {
                      let values = {...invoiceData}
                      let valuesSections = [...invoiceData?.itemDescriptions]
                      valuesSections[ind].html = val
                      values.itemDescriptions = valuesSections
                      setInvoiceData(values)
                    }}
                    autoSaveText={() => {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }}
                    refresh={invoiceData}
                    placeholder={'Add section details here...'}
                  />
                </div>
              )
            })}

            <div className='d-flex justif-content-center mt-5 mb-10'>
              <button
                type='button'
                className='btn btn-sm fw-bold select-btn btn-fit-content ps-2 mx-auto pe-5 mb-5'
                onClick={() => {
                  handleAddNewSections()
                }}
              >
                <img src={addWhite} height={18} width={18} className='me-5' /> Add Section
              </button>
            </div>
          </div>
        </div>
        {/* </form> */}
      </div>

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <div className="">
          <CircularProgress style={{color: '#007a59'}} />
          <p style={{color: '#007a59'}} className='mt-2'><b>Saving...</b></p>
        </div>
      </Backdrop>
    </>
  )
}

export default GenerateInvoice
