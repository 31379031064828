export default {
  latePaymentFeeGlobalTable: [
    {label: 'Name', key: 'name', labelClasses: '', keyClasses: ''},
    {label: 'Fee', key: 'fee', labelClasses: '', keyClasses: ''},
    {label: 'Fee Type', key: 'feeType', labelClasses: '', keyClasses: ''},
    {label: 'Calculation', key: 'calculation', labelClasses: '', keyClasses: ''},
    {label: 'Frequency', key: 'frequency', labelClasses: '', keyClasses: ''},
    {label: 'Times Applied', key: 'timesApplied', labelClasses: '', keyClasses: ''},
    {label: 'Starts Applying', key: 'startsApplying', labelClasses: '', keyClasses: ''},
    {label: 'Status', key: 'status', labelClasses: '', keyClasses: ''},
  ],
}
