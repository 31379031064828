import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Row, Col} from 'react-bootstrap'
import plus from '../../../../img/plus.svg'
import close from '../../../../img/close.png'
import submitImg from '../../../../img/Submit-White.png'
import Select from 'react-select'
import './../style.scss'
import latePayment from '../../../../img/overdue-white.png'
import profileImg from '../../../../img/user.jpeg'
import profileWhite from '../../../../img/profile-white.png'
import PhoneInput from 'react-phone-input-2'
import addWhite from '../../../../img/add-white.png'

interface ButtonProps {
  show: any
  handleClose: any
  //   propertyList: any
  //   id: any
  //   unitNo: any
  //   mediaPop?: any
}

const AddUser = ({handleClose, show}: ButtonProps) => {
  const [userData, setUserData] = React.useState<any>([])

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-750px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-header text-white ps-5 pe-1 user-header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Add User</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={handleClose}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='user-header-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '2.8rem'}}>
              <img src={profileWhite} height='70px' width='70px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body py-lg-10 px-lg-10 pt-lg-4'
          style={{transform: 'translate(0px, -80px)', maxHeight: '620px', overflowY: 'scroll', marginBottom: '-80px'}}
        >
          <Row className='align-items-center'>
            <Col lg={6}>
              <div className='d-flex align-items-center'>
                <div className='symbol-group symbol-hover m-0'>
                  <div className='symbol symbol-70px symbol-circle '>
                    <img alt='' src={profileImg}></img>
                  </div>
                </div>

                <h2 className='head-text ms-4 mb-0' style={{fontWeight: '700'}}>
                  User Details
                </h2>
              </div>
            </Col>
            <Col lg={6} md={6} className='align-items-center text-end'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                style={{
                  fontSize: '1.2rem',
                  paddingRight: '35px',
                  borderRadius: '5px',
                  backgroundColor: '#146c6a',
                  boxShadow: '0px 0px 20px 0px #146c6a',
                }}
              >
                <img
                  src={submitImg}
                  style={{
                    height: '20px',
                    width: '20px',
                    marginRight: '15px',
                    marginLeft: '4px',
                    marginTop: '-3px',
                  }}
                />{' '}
                {'  '}
                Submit
              </button>
            </Col>
          </Row>
          <Row className='align-items-end mt-5'>
            <Col lg={4} md={4} className='align-items-center pe-5 mb-5'>
              <label className='required mb-2 label-color'>First Name</label>
              <input
                type='text'
                className='form-control form-control-solid-bg'
                placeholder='Enter first name..'
                name='item_id'
                style={{
                  border: '0.1rem solid #bec3cb',
                  borderRadius: '2px',
                  padding: '0.7rem 1rem',
                }}
              />
            </Col>
            <Col lg={4} md={4} className='align-items-center ps-5 mb-5'>
              <label className='required mb-2 label-color'>Last Name</label>
              <input
                type='text'
                className='form-control form-control-solid-bg'
                placeholder='Enter last name..'
                name='item_id'
                style={{
                  border: '0.1rem solid #bec3cb',
                  borderRadius: '2px',
                  padding: '0.7rem 1rem',
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={4} md={4} className='align-items-center pe-5 mb-5'>
              <label className='required mb-2 label-color'>Email</label>

              <input
                type='text'
                className='form-control form-control-solid-bg'
                placeholder='Enter email..'
                name='item_id'
                style={{
                  border: '0.1rem solid #bec3cb',
                  borderRadius: '2px',
                  padding: '0.7rem 1rem',
                }}
              />
            </Col>
            <Col lg={4} md={4} className='align-items-center ps-5 mb-5'>
              <label className='required mb-2 label-color'>Mobile Number</label>
              <PhoneInput
                //   {...register('phoneNumber', {required: true})}
                placeholder='Enter phone number'
                value={`${userData?.countryCode} ${userData?.phoneNumber}`}
                onChange={(phone: any, e: any) => {
                  let CountryCode: any = e?.dialCode
                  let PhoneNumber: any = phone.split(CountryCode)[1]
                  setUserData({
                    ...userData,
                    ['countryCode']: parseInt(CountryCode),
                    ['phoneNumber']: PhoneNumber,
                  })

                  //   setValue(
                  //     'phoneNumber',
                  //     `${parseInt(CountryCode)}${PhoneNumber}`
                  //   )
                }}
              />
            </Col>
          </Row>
          <Row className='mt-5'>
            <Col>
              <h2 className='head-text mb-0' style={{fontWeight: '700'}}>
                Assign Roles
              </h2>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={12}>
              <div className='d-flex align-items-center'>
                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 pe-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='vacant0'
                    name='none'
                    checked={true}
                    onChange={(e: any) => {}}
                  />
                </div>
                <h3 className='head-text ms-1 mb-0' style={{fontWeight: '600'}}>
                  Administrator
                </h3>
              </div>
            </Col>
            <Col md={12}>
              <p className='user-sub-head mb-2 ms-7 ps-5'>
                Allows full access to the system… For users who are account administrators,
                installing, configuring, and maintaining all software applications and
                infrastructure in a SaaS environment.
              </p>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={12}>
              <div className='d-flex align-items-center'>
                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 pe-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='vacant0'
                    name='none'
                    checked={true}
                    onChange={(e: any) => {}}
                  />
                </div>
                <h3 className='head-text ms-1 mb-0' style={{fontWeight: '600'}}>
                  Leasing Manager
                </h3>
              </div>
            </Col>
            <Col md={12}>
              <p className='user-sub-head mb-2 ms-7 ps-5'>
                For users who are looking after property rentals, payments, etc.
              </p>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={12}>
              <div className='d-flex align-items-center'>
                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 pe-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='vacant0'
                    name='none'
                    checked={true}
                    onChange={(e: any) => {}}
                  />
                </div>
                <h3 className='head-text ms-1 mb-0' style={{fontWeight: '600'}}>
                  Role Name
                </h3>
              </div>
            </Col>
            <Col md={12}>
              <p className='user-sub-head mb-2 ms-7 ps-5'>Role Description..</p>
            </Col>
          </Row>

          <Row className='mt-5'>
            <Col>
              <h2 className='head-text mb-0' style={{fontWeight: '700'}}>
                Property Portfolio
              </h2>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col md={4}>
              <div className='d-flex align-items-center'>
                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 pe-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='vacant0'
                    name='none'
                    checked={true}
                    onChange={(e: any) => {}}
                  />
                </div>
                <h3 className='head-text ms-1 mb-0' style={{fontWeight: '500'}}>
                  Select Properties
                </h3>
              </div>
            </Col>
            <Col md={4}>
              <div className='d-flex align-items-center'>
                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 pe-5'>
                  <input
                    className='form-check-input'
                    type='radio'
                    id='vacant0'
                    name='none'
                    checked={true}
                    onChange={(e: any) => {}}
                  />
                </div>
                <h3 className='head-text ms-1 mb-0' style={{fontWeight: '500'}}>
                  All Properties
                </h3>
              </div>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
            <button
                      type='button'
                      className='btn btn-sm fw-bold  me-0 select-btn ps-3 pe-10'
                      onClick={() => {
                        
                      }}
                      style={{width: 'fit-content'}}
                    >
                      <img src={addWhite} height={20} width={20} style={{marginRight: '10px'}} />{' '}
                      Add
                    </button></Col>
          </Row>
        </div>
      </Modal>
    </div>
  )
}

export default AddUser
