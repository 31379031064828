import React from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  show?: any
  handleClose?: any
  propertyList?: any
  id?: any
  mediaPop?: any
  errorType?: any
}

const PaymentErrorModal = ({show, handleClose, errorType}: ButtonProps) => {
  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px'
        show={show}
        onHide={() => {
          handleClose()
        }}
        size='sm'
      >
        <div className='modal-body py-lg-10 px-lg-10'>
          <div className='d-flex justify-content-between'>
            <h2 className='mx-0 green_color'></h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG
                className='svg-icon-1 text-black'
                path='/media/icons/duotune/arrows/arr061.svg'
              />
            </div>
          </div>
          <h6 className='d-flex flex-column justify-content-center p-5 br-5 shadow my-5'>
            {errorType}
          </h6>
        </div>
      </Modal>
    </div>
  )
}

export default PaymentErrorModal
