import {Modal} from 'react-bootstrap'

interface UnitsListProps {
  show: boolean
  onHide: any
  unitsDetails: any
  unitsCount: any
}

const UnitsList = ({show, onHide, unitsDetails, unitsCount}: UnitsListProps) => {
  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-850px mx-auto'
        show={show}
        onHide={() => {
          onHide()
        }}
        backdrop={true}
      >
        <div className='modal-body p-0 modal-body-without-header px-7'>
          <div
            className='row px-0 py-5 m-0 pb-1'
            style={{position: 'sticky', top: 0, background: 'transparent', zIndex: 2}}
          >
            <div className='d-flex justify-content-end mb-3 px-0'>
              <label
                className='head-text fs-4 cursor-pointer blue-dark-text fnt-700'
                onClick={() => {
                  onHide()
                }}
              >
                X
              </label>
            </div>

            <div className='d-flex align-items-center px-0'>
              <h3 className='text-dark white-dark-theme-color mb-4'>
                <b>Overlapping Units</b>
              </h3>

              <h3 className='text-dark white-dark-theme-color mb-4 ms-auto me-7'>
                <b>Total: {unitsCount} Units</b>
              </h3>
            </div>
          </div>

          {/*  */}
          {unitsDetails?.length > 0 && (
            <div
              className='table-responsive px-2'
              style={{minHeight: '300px', maxHeight: '450px', overflow: 'auto'}}
            >
              <table className='table align-middle gy-5 ' id='kt_ecommerce_sales_table'>
                <thead className='fs-5 text-gray-400 fnt-500'>
                  <th className='text-start min-w-300px ps-0 pe-10'>Development</th>
                  <th className='text-start min-w-150px ps-0'>Unit </th>
                  <th className='text-start min-w-125px ps-0'>Floor </th>
                  <th className='text-start min-w-100px pe-0'> Furnishing</th>
                  <th className='text-start min-w-125px ps-0'>Unit Type</th>
                  <th className='text-start min-w-75px ps-0'>Bedrooms</th>
                </thead>
                <tbody className='fw-semibold text-dark fs-6'>
                  {unitsDetails?.map((tb: any, ind: any) => {
                    return (
                      <>
                        {tb?.units?.map((tbUnit: any) => {
                          return (
                            <tr key={ind}>
                              <td className='text-start ps-0 pe-10 py-5'>
                                <div className='mb-3'>
                                  {tbUnit?.community?.length > 0 ? (
                                    <>
                                      {tbUnit?.community?.[0]?.name}
                                      {' - '} {tbUnit?.cluster?.[0]?.name}
                                      {tbUnit?.cluster?.[0]?.type == 1
                                        ? ` - ${tbUnit?.building?.[0]?.name}`
                                        : tbUnit?.cluster?.[0]?.type == 2
                                        ? ` - ${
                                            tbUnit?.unit_group?.[0]?.name ||
                                            tbUnit?.building?.[0]?.name
                                          }`
                                        : ''}
                                    </>
                                  ) : (
                                    <>{tbUnit?.building?.[0]?.name}</>
                                  )}
                                </div>
                              </td>
                              <td className='text-start ps-0 py-5'>
                                {' '}
                                <div className='mb-3'>{tbUnit?.unitNo || '-'}</div>
                              </td>
                              <td className='text-start ps-0 py-5'>
                                <div className='mb-3'>
                                  {tbUnit?.floor?.length > 0 ? tbUnit?.floor?.[0]?.name : '-'}
                                </div>
                              </td>
                              <td className='text-start ps-0 py-5'>
                                <div className='mb-3'>
                                  {tbUnit?.furnishingType === 0
                                    ? 'Unfurnished'
                                    : tbUnit?.furnishingType === 1
                                    ? 'Furnished'
                                    : '-'}
                                </div>
                              </td>
                              <td className='text-start ps-0 py-5'>
                                {' '}
                                <div className='mb-3'>{tbUnit?.unitTypeString}</div>
                              </td>
                              <td className='text-start ps-0 py-5'>
                                {' '}
                                <div className='mb-3'>{tbUnit?.bedrooms} BR</div>
                              </td>
                            </tr>
                          )
                        })}
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default UnitsList