import React from 'react'
import {Box} from '@mui/material'
import { useNavigate } from 'react-router-dom'

const DepositCardItem: React.FC<{
  name: string
  description: string
  path: string
  configureCount: number
}> = ({name, description, path, configureCount}) => {
    const navigate = useNavigate()
  return (
    <Box  onClick={() => {
        // if (path) navigate(path)
      }}>
      <div className='card card-flush gap-5 p-5 mt-5 px-3 mt-10'>
        <div className='card-body pt-0 py-0 px-3'>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4 mb-1'>{name}</p>
            <p className='fs-4 fnt-500 labl-gry mb-1 light-dark-theme-color ms-auto'>
              Configured{' '}
              <span className='ms-3'>
                <b>{configureCount}</b>
              </span>
            </p>
          </Box>
          {description && <p className='fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>{description}</p>}
        </div>
      </div>
    </Box>
  )
}

export default DepositCardItem
