import {Modal} from 'react-bootstrap'
import switchOffIcon from '../../../../../../../../../img/switch-off.png'
import switchOnIcon from '../../../../../../../../../img/switch-on.png'

interface ActivityStatusModalI {
  show: boolean
  onHide: any
  onAdd: any
  selectedConfig: any
}

const ActivityStatusModal = ({show, onHide, onAdd, selectedConfig}: ActivityStatusModalI) => {
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px mx-auto'
      show={show}
      onHide={() => {
        onHide()
      }}
      backdrop={true}
    >
      <div className='modal-body p-0 modal-body-without-header px-5 pb-6'>
        <div
          className='row px-1 py-5 m-0 pb-3'
          style={{position: 'sticky', top: 0, background: 'transparent', zIndex: 2}}
        >
          <div className={`col-12 px-0`}>
            <div className='d-flex justify-content-end'>
              <label
                className='head-text fs-4 cursor-pointer blue-dark-text fnt-700'
                onClick={() => {
                  onHide()
                }}
              >
                X
              </label>
            </div>
          </div>

          <div className='d-flex justify-content-center'>
            <h2 className='text-dark white-dark-theme-color mb-3'>
              <b>{`${selectedConfig?.activityStatus ? 'Deactivate' : 'Activate'} Charge`}</b>
            </h2>
          </div>
        </div>

        <div className='d-flex justify-content-center'>
          <div className='d-flex align-items-center justify-content-center'>
            <img
              src={!selectedConfig?.activityStatus ? switchOnIcon : switchOffIcon}
              height={25}
              width={25}
            />
            <p className='m-0 ms-3'>{selectedConfig?.activityStatus ? 'Deactive' : 'Active'}</p>
          </div>
        </div>

        <p className='my-3 text-center text-dark white-dark-theme-color fs-4 fnt-400 mb-4'>
          You are about to {selectedConfig?.activityStatus ? 'deactivate' : 'activate'} this charge.
        </p>

        <p className='my-3 text-center text-dark white-dark-theme-color fs-4 fnt-400 mb-4'>
          Once {selectedConfig?.activityStatus ? 'deactivated' : 'activated'}, the system will{' '}
          {selectedConfig?.activityStatus ? 'stop' : 'start'} auto-generating this charge on
          bookings and leases.
        </p>

        <p className='my-3 text-center text-dark white-dark-theme-color fs-4 fnt-400 mb-4'>
          All draft, booked and ongoing leases will not be affected.
        </p>

        <p className='my-3 text-center text-dark white-dark-theme-color fs-4 fnt-400 mb-5'>
          Are you sure you want to continue?
        </p>

        <div className='col-12 my-4'>
          {!selectedConfig?.activityStatus ? (
            <button
              type='button'
              className='btn btn-sm fw-bold select-btn px-2 mx-auto justify-content-center status-w-175'
              onClick={() => {
                onAdd()
              }}
            >
              Enable
            </button>
          ) : (
            <button
              type='button'
              className='btn btn-sm fw-bold px-2 mx-auto justify-content-center status-w-175 d-flex align-items-center justify-content-center'
              style={{
                backgroundColor: '#a6a6a6',
                border: 'none',
                color: 'white',
                fontSize: '11px',
                height: '31px',
              }}
              onClick={() => {
                onAdd()
              }}
            >
              Disable
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default ActivityStatusModal