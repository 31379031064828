import moment from 'moment'
import React, {useRef, useState} from 'react'
import {Modal, Col, Row} from 'react-bootstrap'
import ReactToPrint from 'react-to-print'
import {ApiGet, ApiPost, ApiUpload, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  show: any
  handleClose: any
  showModal: any
  receiptId: any
  tenancy: any

  // 👇️ turn off type checking
  // sethandleSubmitIdv:any;
}
const ViewReceipt = ({show, handleClose, tenancy, showModal, receiptId}: ButtonProps) => {
  const [tenancyPayment, setTenancyPayment] = React.useState<any>([])
  const [tenancyData, setTenancyData] = React.useState<any>([])
  const [electronicSignature, setElectronicSignature] = React.useState<boolean>(true)
  const [date, setDate] = useState<any>('')
  const [uploadedFile, setUploadedFile] = useState<any>('')
  const [uploadedSign, setUploadedSign] = useState<any>('')
  const [receiptData, setReceiptData] = useState<any>('')

  const getPaymentReceiptById = async () => {
    await ApiGet(`corporate/payment_receipt/${receiptId}`)
      .then((res) => {
        setReceiptData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const getTenancy = async () => {
    await ApiGet(`corporate/tenancy/${window.location.pathname.split('/')[2]}`)
      .then((res) => {
        // setTableData(res?.data?.data)
        setTenancyData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  React.useEffect(() => {
    if (receiptId) {
      getPaymentReceiptById()
    }
    getTenancy()
  }, [receiptId])

  const uploadFilelHandler = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/document', formData)
      .then((res: any) => setUploadedFile(res?.data?.data?.image))
      .catch((err: any) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }

  const uploadSignHandler = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/document', formData)
      .then((res: any) => setUploadedSign(res?.data?.data?.image))
      .catch((err: any) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const ref = useRef()

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={() => {
        handleClose()
      }}
      backdrop={true}
    >
      <div className='modal-header'>
        <h1>View Receipt</h1>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1 text-black' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div
        className='d-flex align-tems-center mt-5'
        style={{
          height: '33px',
          paddingLeft: '23px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div className='checkbtn-wrapper d-flex'>
          {receiptData[0]?.signatureType === 1 ? (
            <div className='form-check form-check-custom form-check-solid form-check-sm me-3'>
              {/* <input
                            className='form-check-input'
                            type='checkbox'
                            id='occupied'
                            name='electronicSignature'
                            checked={receiptData[0]?.signatureType === 1 ? true : false}
                        /> */}
              <label className='form-check-label' htmlFor='occupied'>
                Electronic Signature
              </label>
            </div>
          ) : (
            ''
          )}

          {receiptData[0]?.signatureType === 0 ? (
            <div className='form-check form-check-custom form-check-solid form-check-sm me-3'>
              <label className='form-check-label' htmlFor='occupied1'>
                Manual Signature
              </label>
            </div>
          ) : (
            ''
          )}

          {/* {JSON.stringify(receiptData)} */}
          {receiptData[0]?.signatureType === 0 ? (
            <>
              {/* <ReactToPrint
                            trigger={() => {
                                return <a href="#">Print & Sign</a>;
                            },
                            content={() => ref}
                        }
                        /> */}
              <ReactToPrint
                trigger={() => (
                  <button className='btn btn-sm fw-bold btn-primary btn-green mx-3 position-relative'>
                    Print & Sign
                  </button>
                )}
                content={(): any => ref.current}
              />
              <a className='btn btn-sm fw-bold btn-primary btn-green mx-3 position-relative'>
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                  href={`${Bucket}${receiptData[0]?.uploadSignPdf}`}
                  target='_blank'
                >
                  Signature
                </a>
              </a>
            </>
          ) : (
            ''
          )}
        </div>
        {/* <div className='savebtn-wrapper'>
                    <button className='btn btn-sm fw-bold btn-primary btn-green' style={{ margin: "23px" }}>Save</button>
                </div> */}
      </div>
      <Col ref={ref}>
        <div className='modal-body' style={{padding: '23px'}}>
          <Row>
            <Col>
              <Row>
                <Col md={8} className=''>
                  <div className='payment-receipt-item'>
                    <h6 className='text-muted'>
                      Received From.
                      <span className='mx-1' style={{color: 'black'}}>
                        {tenancyData[0]?.tenant[0]?.firstName}
                      </span>
                    </h6>
                  </div>
                  <div className='payment-receipt-item d-flex align-items-center mb-2'>
                    <h6 className='text-muted m-0'>Total Amount Received AED </h6>
                  </div>
                  <div className='payment-receipt-item'>
                    <h6 className='text-muted'>
                      Against Contract No.
                      <span className='mx-1' style={{color: 'black'}}>
                        {tenancyData[0]?.contractNo}
                      </span>
                    </h6>
                  </div>
                  <div className='payment-receipt-item'>
                    <h6 className='text-muted'>
                      Unit
                      <span className='mx-1' style={{color: 'black'}}>
                        {' '}
                        {tenancyData[0]?.unit[0]?.unitNo}
                      </span>
                    </h6>
                  </div>
                  <div className='payment-receipt-item'>
                    <h6 className='text-muted'>
                      Tenancy Period
                      <span className='mx-1' style={{color: 'black'}}>
                        {' '}
                        {moment(tenancyData[0]?.duration?.start_date).format('DD/MM/YYYY')} -{' '}
                        {moment(tenancyData[0]?.duration?.end_date).format('DD/MM/YYYY')}
                      </span>
                    </h6>
                  </div>
                </Col>
                <Col md={4} className=''>
                  <div className='payment-receipt-item d-flex align-items-center'>
                    <h6 className='text-muted'>Receipt Generate Date</h6>
                    <span>{moment(receiptData[0]?.receiptGenerateDate).format('DD/MM/YYYY')}</span>
                  </div>
                  <div className='payment-receipt-item'>
                    <h6 className='text-muted'>Receipt No :</h6>
                    <span>{receiptData[0]?.receiptNo}</span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <div className='pt-0 table-responsive mt-5'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className=' min-w-100px'>Payment</th>
                    <th className=' min-w-100px'>Type</th>
                    <th className=' min-w-100px'>Payment Method</th>
                    <th className=' min-w-100px'>Payment Ref No.</th>
                    <th className=' min-w-100px'>Payment Date</th>
                    <th className=' min-w-100px'>Amount Received</th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {receiptData[0]?.tenant_payment?.map((v: any, i: any) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          {v?.rentType === 0
                            ? 'Rent'
                            : v?.rentType === 1
                            ? 'Security Deposite'
                            : v?.rentType === 2
                            ? 'Booking'
                            : v?.rentType === 3
                            ? 'Others'
                            : ''}
                        </td>
                        <td>
                          {v?.paymentMethod === 0
                            ? 'Card (Recurring Payments)'
                            : v?.paymentMethod === 1
                            ? 'Card (Individual Payments)'
                            : v?.paymentMethod === 2
                            ? 'Cheque'
                            : v?.paymentMethod === 3
                            ? 'Bank'
                            : 'Cash'}
                        </td>
                        <td>{v?.paymentNo === null ? '-' : v?.paymentNo}</td>

                        <td>
                          {v?.status === 0 ? 'Paid' : v?.status === 1 ? 'Upcoming' : 'Overdue'}
                        </td>

                        <td>{v?.amountReceived} AED</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Row>

          <Row>
            <Col md={9} className=''>
              <div className='payment-receipt-item'>
                <h6 className='text-muted'>Received By</h6>
                {/* {tenancyData[0]?.receivedDate} */}
                <span>{receiptData[0]?.receivedBy}</span>
                {/* <input
                                        type='text'
                                        className='form-control d-inline-block form-control-solid'
                                        placeholder='Enter'
                                        name='Total Amount Received AED'
                                        style={{ width: '200px' }}
                                        value={}
                                        onChange={(e) => { setReceivedBy(e.target.value) }}
                                    /> */}
              </div>
              {electronicSignature && (
                <div className='payment-receipt-item d-flex align-items-center mt-2'>
                  <h6 className='text-muted'>Receipt Submission Date</h6>
                  <span>{moment(receiptData[0]?.submissionDate).format('DD/MM/YYYY')}</span>
                </div>
              )}
            </Col>
            <Col md={3} className=''>
              <div className='payment-receipt-item d-flex align-items-center'>
                <h6 className='text-muted'>Signature</h6>
                {receiptData[0]?.signatureType === 1 ? (
                  <div className='btn btn-sm ms-1 fw-bold btn-primary btn-green position-relative'>
                    <a
                      className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                      href={`${Bucket}${receiptData[0]?.uploadSign}`}
                      target='_blank'
                    >
                      Signature
                    </a>
                  </div>
                ) : (
                  <div
                    className='border-bottom border-dark'
                    style={{height: '15px', width: '150px'}}
                  ></div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      </Col>
      {/* <Col className='align-self-end'>
                <button onClick={() => handleSubmitReciept(1)} className='btn btn-sm fw-bold btn-primary btn-green mt-0' style={{ margin: "23px" }}>
                    Submit Receipt
                </button>
            </Col> */}
    </Modal>
  )
}

export default ViewReceipt
