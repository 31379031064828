import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import ApexCharts from 'react-apexcharts'
import arrowUp from '../../../../img/caret-arrow-up-green.png'
import arrowDown from '../../../../img/caret-arrow-down-green.png'
import CircularProgress from '@mui/material/CircularProgress'

interface TenantChartMonthlyProps {
  chartData: DataPoint[]
}

interface DataPoint {
  time: string
  tenants: number
  percentage: number
  increase: boolean
  decrease: boolean
}

const TenantChartMonthly: React.FC<TenantChartMonthlyProps> = ({ chartData }) => {
  const [series, setSeries] = useState([
    {
      name: 'Tenants',
      data: [] as number[],
      color: '#188383',
    },
  ])

  const [categories, setCategories] = useState<string[]>([])
  const [isSkeleton, setIsSkeleton] = useState<any>(true)

  const [maxValue, setMaxValue] = useState<any>(100)

  // console.log(maxValue)

  useEffect(() => {
    const tenants = chartData?.map((dataPoint) => dataPoint?.tenants)
    const times = chartData?.map((dataPoint) => dataPoint?.time)

    setSeries([
      {
        name: 'Tenants',
        data: tenants,
        color: '#188383',
      },
    ])

    setCategories(times)

    const temp = Math?.max(...tenants)
    const roundedMaxValue = Math?.ceil(temp / 5) * 5 // Round up to the nearest multiple of 5
    setMaxValue(roundedMaxValue)

    setTimeout(() => {
      setIsSkeleton(false)
    }, 1000)
  }, [chartData])

//   console.log('/***************** Tenant Chart *******************/')
//   console.log(chartData)

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      height: 350,
      // width: chartData?.length * 20,
      type: 'bar',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
      },
      selection: {
        enabled: true
      },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        // columnWidth: '45px', // Adjust column width as needed
        distributed: true, // Ensures bars are evenly distributed
        // borderRadius: 5, // Adjust border radius for rounded bars
        dataLabels: {
          position: 'top',
        
        },
      },
    },
    dataLabels: {
    //   enabled: false,
      style: {
        colors: ['#666'],
        fontSize: chartData?.length > 10 ? '9px' : '10px',
      },
      formatter: function (val, { series, seriesIndex, dataPointIndex, w }) {
        if(chartData?.length > 0){
        const percentage = Number?.isInteger(chartData?.[dataPointIndex]?.percentage) ? chartData?.[dataPointIndex]?.percentage : chartData?.[dataPointIndex]?.percentage?.toFixed(1)
        const increase = chartData?.[dataPointIndex]?.increase
        const decrease = chartData?.[dataPointIndex]?.decrease

        const tenantsDataAvailable = chartData?.[dataPointIndex]?.tenants > 0

        const arrowHTML = increase
          ? `<img src="${arrowUp}" width="14" height="14" alt="Increase" />`
          : decrease
          ? `<img src="${arrowDown}" width="14" height="14" alt="Decrease" />`
          : ''

        return `${tenantsDataAvailable && increase ? `${percentage}%▲` : tenantsDataAvailable && decrease ? `${percentage}%🔻` : chartData?.[dataPointIndex]?.tenants > 0 ? `${percentage}%` : ''}`
        }
        else
        return ''
      },
      offsetY: -15,
      offsetX: 0,
    },
    colors: ['#188383'],
    legend: {
      show: false,
    },
    xaxis: {
      categories: categories,
      type: 'datetime',
      labels: {
        format: 'MMM-yy',
        rotate: 0,
        rotateAlways: false,
        style: {
          fontSize: '12px',
        },
      },
      // tickAmount: Math.min(categories?.length, 10),
      // range: 30 * 24 * 60 * 60 * 1000, // Set range to 30 days (adjust as needed)
    },
    yaxis: {
      min: 0,
      max: Number?.isInteger(maxValue) ? maxValue : maxValue?.toFixed(0) ,
      tickAmount: 5,
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: '#666',
        },
      },
      axisBorder: {
        show: false,
      },
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const dataPoint = chartData?.[dataPointIndex]
        const date = new Date(categories?.[dataPointIndex])?.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
        //   day: 'numeric',
        })
        return (
          `<div class="apexcharts-tooltip-custom">` +
          `<div style="text-align:center;" class="text-decoration-underline">${date}</div>` +
          `<div>` +
          `<div style="display: flex; justify-content: center;">` +
          `<div style="font-weight:bold">${chartData?.[dataPointIndex]?.tenants}</div>` +
          `<div>Tenants</div>` +
          `<div>(${
            chartData?.[dataPointIndex]?.increase || chartData?.[dataPointIndex]?.decrease
              ? `${Number?.isInteger(chartData?.[dataPointIndex]?.percentage) ? chartData?.[dataPointIndex]?.percentage : chartData?.[dataPointIndex]?.percentage?.toFixed(1)}`
              : '0'
          }% ${
            chartData?.[dataPointIndex]?.increase || chartData?.[dataPointIndex]?.decrease
              ? `<img src="${
                  chartData?.[dataPointIndex]?.increase ? arrowUp : arrowDown
                }" width="14" height="14" />`
              : ''
          })</div>` +
          `</div>` +
          `</div>` +
          `</div>`
        )
      },
    },
    title: {
      text: 'Tenants',
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000',
      },
    },
    grid: {
      show: false,
    },
    states: {},
    annotations: {},
  }

  const styles = `
    .apexcharts-tooltip-custom {
      padding: 10px;
      background: #fff;
      border: 1px solid #ccc;
    }
    .apexcharts-tooltip-custom div {
      margin: 5px 3px;
    }
    .apexcharts-tooltip-custom .xyz span {
    }
  `
  const styleSheet = document.createElement('style')
  styleSheet.innerText = styles
  document.head.appendChild(styleSheet)

  return (
    <div className='card card-flush py-4 px-2' style={{ width: '750px', overflowX: 'auto' }}>
      {!isSkeleton ? (
        // <div style={{ maxWidth: '730px', overflowX: 'auto' }}>
          <div id='chart'>
            {series && (
              <ApexCharts
                options={chartOptions}
                series={series ? series : []}
                type='bar'
                height={300}
              />
            )}
          </div>
        // </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{ height: '75vh' }}
        >
          <CircularProgress style={{ color: '#007a59' }} />
        </div>
      )}
    </div>
  )
}

export default TenantChartMonthly
