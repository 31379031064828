/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {ApiGet, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import noData from '../../../img/NoData1.svg'
import ReactPaginate from 'react-paginate'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import {IFIlterProps} from './DashboardWrapper'
import FilterSection from './Components/FilterSection'
import ListOfCommunities from './Components/ListOfCommunities'

const CommunityDashboard: FC = () => {
  const [count, setCount] = useState<any>([])
  const [tableData, setTableData] = useState<any>([])
  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [propertyType, setPropertyType] = useState<any>()
  const [unitType, setUnitType] = useState<any>()
  const [unitNo, setUnitNo] = useState<any>()
  const [watchType, setWatchtype] = useState<any>()
  const [rating, setRating] = useState<any>()
  const [occupy, setOccupy] = useState<any>()
  const [bedrooms, setBedrooms] = useState<any>()
  const [buildingManager, setBuildingManager] = useState<any>()
  const [propertyManager, setPropertyManager] = useState<any>()
  const permission: any = localStorage.getItem('permission')
  const permissionType = JSON.parse(permission)

  const [state, setState] = useState<IFIlterProps>({
    loading: false,
    page: 1,
    pageLimit: 10,
    pageCount: 0,
    unitNo: '',
    developmentType: '',
    unitId: '',
    unitType: null,
    bedrooms: null,
    rating: null,
    occupy: null,
    data: [],
    count: {},
    devList: []
  })

  const navigate = useNavigate()

  const handleChange = (name: string, value: any) => {
    updateData(name, value)
  }

  const handleRoutes = (routes: string) => {
    navigate(routes)
    updateData('page', 1)
  }

  const handlePageClick = (event: any) => {
    updateData('page', event.selected + 1)
  }

  const updateData = (key: string, value: any) => setState((prev) => ({...prev, [key]: value}))

  const getCount = () => {
    ApiGet('corporate/dashboard')
      .then((res) => {
        updateData('count', res?.data?.data)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }
  const getData = () => {
    let body = {
      page: state.page,
      limit: state.pageLimit,
      unitType: state.unitType === '0' ? null : state.unitType,
      rating: state.rating === '' ? null : state.rating,
      occupy: state.occupy === '' ? null : state.occupy,
      bedrooms: state.bedrooms === '' ? null : state.bedrooms,
      searchUnitNo: state.unitNo,
      premiseNo: "",
      watchType: 'community',
      communityManager: state.buildingManager,
      propertyManager: state.propertyManager,
      communityId: state.developmentType,
      searchId: state.unitId
    }
    ApiPost('corporate/unit/home/get', body)
      .then((res) => {
        updateData('data', res?.data?.data?.unit_data)
        updateData('pageCount', res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        console.log('err', err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const getCommunities = () =>  {
    ApiGet('corporate/communities')
    .then((res) => {
      updateData('devList', res?.data?.data)
    })
    .catch((err) => {
      console.log('err', err.message)
    }) 
  }

  useEffect(() => {
    updateData('loading', true)

    const getListAll = setTimeout(() => {
      getCount()
      getData()
      getCommunities()
    }, 1000)
    return () => clearTimeout(getListAll)
  }, [state.page, state.unitType, state.unitNo, state.unitId, state.developmentType])

  const onReset = () => {
    updateData('rating', '')
    updateData('occupy', '')
    updateData('bedrooms', '')
    updateData('buildingManager', '')
    updateData('propertyManager', '')
    getData()
  }

  return (
    <>
      <div
        className='px-2 mx-auto d-flex gap-5 flex-column p-0 m-0'
        id='kt_app_main'
      >
        <FilterSection
          onManageProperties={() => {
            permissionType?.property === true
              ? navigate('/community')
              : ErrorToast('youhave not permission to add property')
          }}
          onApply={getData}
          onReset={onReset}
          handleChange={handleChange}
          handleRoutes={handleRoutes}
          state={state}
        />
        <div className='card p-5'>
          <ListOfCommunities data={state} handlePageClick={handlePageClick} />
        </div>
      </div>
    </>
  )
}

export {CommunityDashboard}
