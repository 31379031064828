import {createSlice} from '@reduxjs/toolkit'

export const chatPopUpSlice = createSlice({
  name: 'counter',
  initialState: {
    requestorId: false,
  },
  reducers: {
    setRequestorId: (state, action) => {
      state.requestorId = action.payload
    },
  },
})

export const {setRequestorId} = chatPopUpSlice.actions

export default chatPopUpSlice.reducer
