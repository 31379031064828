import React, { useState } from 'react'
import { Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { array } from 'yup'
import { ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData'
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast'
import AddVendorListPopup from './AddVendorListPopup'

let vendorListArray: any = []
let removeVendorList: any = []

const EditVendorList = () => {

    const navigate = useNavigate()

    const [formData, setFormData] = useState<any>({})
    const [listFormData, setListFormData] = useState<any>({})
    const [showAddVendorModal, setShowAppVendorModal] = React.useState<boolean>(false)
    const [vendorList, setVendorList] = React.useState<any>()
    const [removeList, setRemoveList] = React.useState<any>()
    const [checkedState, setCheckedState] = useState<any>()

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleListChnage = (e: any) => {
        const { name, value } = e.target
        setListFormData({ ...listFormData, [name]: value })
    }

    const getvendorList = () => {
        ApiGet(`corporate/vendor?search=${listFormData?.searchVendor ? listFormData?.searchVendor : ''}`)
            .then((res) => {
                setVendorList(res?.data?.data)
            })
            .catch((err) => ErrorToast(err.message))
    }

    const getVendorListById=()=>{
        ApiGet(`corporate/vendor_list/${window.location.pathname?.split('/')[4]}`)
        .then((res) => {
            setFormData(res?.data?.data[0])
            setRemoveList(res?.data?.data[0]?.vendorIds)
            setCheckedState(new Array(res?.data?.data[0]?.vendorIds).fill(false))
        })
        .catch((err) => ErrorToast(err.message))
    }

    React.useEffect(() => {
        getvendorList()
        getVendorListById()
    }, [])

    const onSubmit = async (data: any) => {
        const body = {
            name: formData?.listName,
            totalVendor: vendorListArray.length,
            comment: formData?.note,
            vendorIds: vendorListArray
        }
        await ApiPost(`corporate/vendor_list`, body)
            .then((res) => {
                SuccessToast(res?.data?.message)
                navigate('/vendor-management/management-list')
            })
            .catch((err) => ErrorToast(err.message))

    }

    const handleRemoveVendorList = () => {
        vendorListArray = vendorListArray.filter((val: any) => !removeVendorList.includes(val));
        setRemoveList(vendorListArray)
        setCheckedState(new Array(removeVendorList?.length).fill(false))

    }

    const removeVendor = (i: any, index: any) => {
        const updatedCheckedState = checkedState.map((item: any, i: any) =>
            i === index ? !item : item
        );
        setCheckedState(updatedCheckedState);

        if (removeVendorList.includes(i?._id)) {
            removeVendorList = removeVendorList.filter((item: any) => item !== i?._id)
        } else {
            removeVendorList.push(i?._id)
        }
    }

    return (
        <>
            <div>
                <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                    <div id='' className='app-container container-xxl pt-0 mt-0'>
                        <div className='d-flex justify-content-between mb-5'>
                            <div
                                className='btn btn-sm fw-bold me-5 btn-primary btn-green hover-none'
                                onClick={() => navigate(`/vendor-management/management-list`)}>
                                Back
                            </div>
                            <button
                                className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                type='submit'

                            >
                                Create
                            </button>
                        </div>
                        <div className='d-flex align-items-center mb-5'>
                            <div>
                                <label htmlFor='exampleFormControlInput1' className='min-w-100px form-label m-0'>List Name</label>
                                <input
                                    type='text'
                                    className='form-control w-auto'
                                    placeholder='List Name'
                                    name='name'
                                    value={formData?.name}                                   
                                    onChange={handleChnage}
                                />
                            </div>

                            <div className='menu-item px-3'>
                                <div
                                    // onClick={() => navigate(`/tenancy-details/${v?._id}/payment-rent`)}
                                    className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                    style={{ marginTop: '15px' }}
                                >
                                    Create
                                </div>
                            </div>
                        </div>
                        <div className='card card-flush app-container py-5'>
                            <Col lg={6} className="mb-5">
                                <label htmlFor='exampleFormControlInput1' className='min-w-100px form-label m-0'>
                                    Comments
                                </label>
                                <textarea
                                    className='form-control form-control-solid'
                                    placeholder='Type Your Comments....'
                                    name='comment'
                                    value={formData?.comment}
                                    onChange={handleChnage}
                                />
                            </Col>
                            <div className='d-flex mb-5 align-items-center justify-content-between'>
                                <div className=''>
                                    <a
                                        // onClick={() => setShowCreateAppModal(true)}
                                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                        onClick={() => setShowAppVendorModal(true)}
                                    >
                                        + Add Vendors
                                    </a>
                                    <a
                                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_create_app'
                                        onClick={handleRemoveVendorList}
                                    >
                                        Remove
                                    </a>
                                </div>
                                <h5 className='gray-500 me-10 mb-0 w-200px'>
                                    Total Vendors Added:{' '}
                                    <span className='' style={{ color: 'black' }}>
                                        {vendorListArray.length}
                                    </span>
                                </h5>
                            </div>
                            <Col lg={6}>
                            {
                                vendorList?.filter((i: any) => removeList?.includes(i._id)).map((item: any, index: any) => {
                                    return (
                                        <div className="form-check form-check-sm form-check-custom form-check-solid mb-3">
                                            <input className="form-check-input me-5" type="checkbox" value="" checked={checkedState[index]} id="flexCheckDefault" style={{ cursor: 'pointer' }} onChange={() => removeVendor(item, index)} />
                                           <h6>{item?.firstName} {item?.lastName}</h6>
                                        </div>
                                    )
                                })
                            }
                        </Col>
                        </div>
                    </div>
                </div>
            </div>
            <AddVendorListPopup
                show={showAddVendorModal}
                handleClose={() => {
                    setShowAppVendorModal(false)
                }}
                vendorList={vendorList}
                vendorListArray={vendorListArray}
                handleListChnage={handleListChnage}
                getvendorList={getvendorList}
                setRemoveList={setRemoveList}
                setCheckedState={setCheckedState}
            />
        </>
    )
}

export default EditVendorList
