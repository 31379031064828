import React from 'react';

interface AmountTypeSelectorProps { 
}

const AmountTypeSelector: React.FC<AmountTypeSelectorProps> = ({ }) => {
    return (
        <div>
            
        </div>
    );
};

export default AmountTypeSelector;
