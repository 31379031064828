import React, {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {ApiPost, Bucket, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import {useParams} from 'react-router-dom'
import ModalLoader from '../../General/ModalLoader'
import {CircularProgress} from '@mui/material'
import noData from '../../../../img/NoData1.svg'
import {MultiSelect} from 'react-multi-select-component'
import addWhite from '../../../../img/add-white.png'

interface ButtonProps {
  show: any
  handleClose: any
  updateDetails?: any
  tenantData: any
}

const SelectList = ({show, handleClose, updateDetails, tenantData}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [isLoading, setIsLoading] = useState<any>(false)

  const [itemsList, setItemsList] = useState<any>([])

  const [radioChecked, setRadioChecked] = useState<any>(-1)
  const [selectedListForItem, setSelectedListForItem] = useState<any>()

  const [listOptions, setListOptions] = useState<any>([])
  const [selectedList, setSelectedList] = useState<any>([])

  const [itemsOptions, setItemsOptions] = useState<any>([])
  const [selectedItems, setSelectedItems] = useState<any>([])

  //
  const getListForDropdown = async () => {
    await ApiGet(`corporate/payment_main_item_list`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setListOptions(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getListForDropdown()
  }, [])

  //
  const getItemsForDropdown = async () => {
    await ApiGet(`corporate/payment_sub_item_list?activityStatus=1`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setItemsOptions(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getItemsForDropdown()
  }, [])

  //
  const getResultForTable = async () => {
    let list:any = []
    selectedList?.map((sl:any, ind:any)=>{
      list[ind] = sl?.value
    })

    let itms:any = []
    selectedItems?.map((sl:any, ind:any)=>{
      itms[ind] = sl?.value
    })

    const body = {
      paymentMainItemListIds: list,
      ids: itms,
      activityStatus: [1]
    }

    ApiPost(`corporate/payment_sub_item_list/get`, body)
      .then((res: any) => {
        setItemsList(res?.data?.data?.payment_sub_item_list_data)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getResultForTable()
  }, [selectedItems, selectedList])

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            // minHeight: '500px',
            maxHeight: '500px',
            overflow: 'auto',
          }}
        >
          <div className='d-flex align-items-center'>
            <h2 className=' pt-5 mb-0'>
              <b>Select Item</b>
            </h2>

            <label
              className='head-text fs-2 cursor-pointer ms-auto'
              style={{fontWeight: '700'}}
              onClick={() => {
                handleClose()
              }}
            >
              X
            </label>
          </div>
          <div className='d-flex align-items-center '>
            <p className=' pt-0 mb-0 pe-13'>Select the item you wish to add to this expense.</p>
            <button
              type='button'
              className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3 d-flex justify-content-center status-w-175 ms-auto me-4'
              onClick={(e: any) => {updateDetails(selectedListForItem)}}
              disabled={radioChecked == -1}
            >
              <img src={addWhite} height={18} width={18} className='me-7' /> {'  '}
              Add
            </button>
          </div>

          <div>
            <>
              <div className='d-flex align-items-center px-0 mt-5'>
                <label className='head-text status-w-100' style={{fontWeight: '600'}}>
                  {' '}
                  Filter{' '}
                </label>

                {/* Items */}
                <div className='test property me-4 '>
                  <div
                    className={`multi-select-container ${
                      selectedList.length === 0 ? 'no-value expense-items-name' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={itemsOptions}
                      value={selectedItems}
                      onChange={setSelectedItems}
                      labelledBy='List'
                      overrideStrings={{
                        allItemsAreSelected: 'All Items', // Custom text for when all items are selected
                      }}
                    />
                  </div>
                </div>

                {/* Item LIST */}
                <div className='test property me-4 '>
                  <div
                    className={`multi-select-container ${
                      selectedList.length === 0 ? 'no-value expense-items-list' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={listOptions}
                      value={selectedList}
                      onChange={setSelectedList}
                      labelledBy='List'
                      overrideStrings={{
                        allItemsAreSelected: 'All Lists', // Custom text for when all items are selected
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className='separator my-3 mt-5'></div>
              <div className='row mt-3'>
                <div className='col-12 pe-0'>
                  <div className=''>
                    <div
                      className='table-responsive'
                      style={{maxHeight: '380px', overflow: 'auto'}}
                    >
                      <table
                        className='table align-middle  fs-6 gy-5'
                        id='kt_ecommerce_sales_table'
                      >
                        <thead>
                          <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                            <th className='text-start w-25px test'></th>
                            <th className='text-start min-w-100px'>ID</th>
                            <th className='text-start min-w-100px'>Item Name</th>
                            <th className='text-start min-w-100px'>Item List</th>
                          </tr>
                        </thead>

                        <tbody className='fw-semibold text-gray-600'>
                          {itemsList?.length > 0 ? (
                            <>
                              {itemsList?.map((rs: any, index: any) => {
                                return (
                                  <tr>
                                    <td className='text-start w-25px  test'>
                                      {radioChecked === rs?._id ? (
                                        <div
                                          style={{
                                            height: '16px',
                                            width: '16px',
                                            backgroundColor: '#146c6a',
                                            borderRadius: '50%',
                                          }}
                                          className='cursor-pointer'
                                        ></div>
                                      ) : (
                                        <div
                                          style={{
                                            height: '16px',
                                            width: '16px',
                                            backgroundColor: 'transparent',
                                            borderRadius: '50%',
                                            border: '1px solid #146c6a',
                                          }}
                                          onClick={() => {
                                            setRadioChecked(rs?._id)
                                            setSelectedListForItem(rs)
                                            // setSelectedName(rs?.name)
                                          }}
                                          className='cursor-pointer'
                                        ></div>
                                      )}
                                    </td>
                                    <td className='text-start min-w-100px'>
                                      {rs?.id ? rs?.id : '-'}
                                    </td>
                                    <td className='text-start min-w-100px'>
                                      {rs?.name ? rs?.name : '-'}
                                    </td>
                                    <td className='text-start min-w-100px'>
                                      {rs?.payment_main_item_list?.[0]?.name
                                        ? rs?.payment_main_item_list?.[0]?.name
                                        : '-'}
                                    </td>
                                  </tr>
                                )
                              })}
                            </>
                          ) : (
                            <tr>
                              {!isLoading ? (
                                <td colSpan={4} className='text-center'>
                                  <img src={noData} alt='' width={350} />
                                </td>
                              ) : (
                                <td colSpan={4} className='text-center'>
                                  <div
                                    className='d-flex justify-content-center align-items-center w-100'
                                    style={{height: '25vh'}}
                                  >
                                    <CircularProgress style={{color: '#007a59'}} />
                                  </div>
                                </td>
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SelectList
