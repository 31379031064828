import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  ApiDelete,
} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {Col, Row, Tabs, Tab, Modal} from 'react-bootstrap'
import TenanciesFilter from './TenanciesFilter'
import {Controller, useForm} from 'react-hook-form'
import {KTSVG} from '../../../_metronic/helpers'
import {AiFillDelete} from 'react-icons/ai'
import swal from 'sweetalert2'
import {DatePicker} from 'antd'
import 'antd/dist/antd.css'
import moment from 'moment'
import townhouse from '../../../img/TownHouse.svg'
import appartment from '../../../img/Apartment.svg'
import villa from '../../../img/Villa.svg'
import other from '../../../img/other.svg'
import buildings from '../../../img/buildings.svg'
import common_area from '../../../img/common_area.svg'
import penthouse from '../../../img/PentHouse.svg'
import plus from '../../../img/plus.svg'
import noData from '../../../img/NoData1.svg'
import pencil from '../../../img/edit-pen.png'
import IncomingPaymentModal from './IncomingPaymentModal'
import ReactPaginate from 'react-paginate'
import EditPaymentModal from '../Financials/EditPaymentModal'
import backArrow from '../../../img/back-arrow.png'
import folderDocument from '../../../img/folder-document.svg'
import EditPaymentModalDraft from './EditPaymentModal'
import AddNewTenant from './AddNewTenantModal'
import profileImg from '../../../img/user.jpeg'
import closes from '../../../img/close.svg'
import filter from '../../../img/filter.svg'
import send from '../../../img/sendwhite.svg'
import saveGreenImg from '../../../img/save-dark-green.png'
import profileWhite from '../../../img/profile-white.png'
import addFolder from '../../../img/add-fold-ehite.png'
import invoiceAdd from '../../../img/invoice-new.png'
import addWhite from '../../../img/add-white.png'
import profileGreen from '../../../img/profile-green.png'
import folderGreen from '../../../img/folder-green.png'
import chatGreen from '../../../img/chat-green.png'
import redCross from '../../../img/remove.png'
import redCrossFilled from '../../../img/remove-filled.png'
import {BsArrowRightSquareFill} from 'react-icons/bs'
import SelectPropertyModal from './SelectPropertyModal'
import EditTenant from './EditTenantModal'
import Select from 'react-select'
import TenantFolders from './TenantFolderModal'
import './style.scss'
import SingleFileModal from './SingleFileModal'
import TerminateTenancy from '../propertyProfile/PropertyProfileTenancy/TerminateTenancyModal'
import sync from '../../../img/sync.png'
import terminate from '../../../img/expired-tenc.png'
import {onMessageListener} from '../../../apiCommon/helpers/firebase/firebase'
import io from 'socket.io-client'
import leftarrow from '../../../img/arrowleft.svg'
import {OverlayTrigger, Tooltip, Card, Form, InputGroup, Button} from 'react-bootstrap'
import {setComefrom, setUnitIDfromProperties} from '../../../redux/counterSlice'
import download from '../../../img/upload-file-green.png'
import axios from 'axios'
import tick from '../../../img/tick-white.png'
import { Fancybox as fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css'

const userData: any = localStorage.getItem('userData')
const user = JSON.parse(userData)

const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
const role = JSON.parse(roleAndPermissions)

const socket = io('https://dev.api.propertise.com/room')

let arry: any = []

const ViewEditTenancy = () => {
  const {flg, id, tenancyId} = useParams()
  const [showTerm, setShowTerm] = useState<any>(false)

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [isFormDirty, setIsFormDirty] = useState(false)

  const [selectModal, setSelectModal] = useState<any>(false)

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',

      zIndex: '1',
    }),
  }

  const [hide, setHide] = React.useState<any>(false)

  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const {state} = useLocation()
  const [showCheange, setShowCheange] = useState<boolean>(false)
  const [showPaid, setShowPaid] = useState<boolean>(false)
  const [showImageName, setShowImageName] = useState<boolean>(false)
  const [country, setCountry] = useState([])
  const [tableData, setTableData] = useState([])
  const [card, setCard] = useState('')
  const [cardA, setCardA] = useState<any>([])
  const [showModal, setShowModal] = useState<any>('Filter')
  const [type, setType] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)
  const [totalDays, setTotalDays] = useState('0 Days')
  const [remainingDays, setRemainingDays] = useState('')

  const [propertiType, setPropertiType] = useState<any>('Tenants')
  const [payment, setPayment] = useState<number>(0)
  const [option, setOption] = useState(1)
  const [formData, setFormData] = useState<any>({
    propertyId: window.location.pathname?.split('/')[2],
    propertyAreaId: window.location.pathname?.split('/')[3],
  })
  const [create, setCreate] = useState<any>(false)
  const [status, setStatus] = useState<any>(false)
  const [phone, setPhone] = useState('')
  const [header, setHeader] = useState<any>({})
  const [unitData, setUnitData] = useState<any>([])
  const [optionArry, setOptionArry] = useState<any>({
    none: false,
    maintenanceFree: false,
    other: [],
    chillerFree: false,
  })
  const [headerB, setHeaderB] = useState<any>([])
  const [tenancyData, setTenancyData] = useState<any>({})
  const [paymentData, setPaymentData] = useState<any>({})
  const [createpaymentData, setCreatePaymentData] = useState<any>(0)
  const [subTenant, setSubTenant] = useState<any>({})
  const [otherOptions, setOtherOptions] = useState<any>([
    // {
    //   name: '',
    // },
  ])

  const [subTenantData, setSubTenantData] = useState<any>([
    // {
    //   id: '',
    //   firstName: '',
    //   lastName: '',
    //   email: '',
    //   mobileNo: '',
    // },
  ])

  // Handle Add Sub Tenants
  const handleAddFields = () => {
    const values = [...subTenantData]
    values.push({
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      mobileNo: '',
      _id: '',
    })
    setSubTenantData(values)
  }

  // Handle Remove Sub Tenants
  const handleRemoveFields = (index: any, item: any) => {
    const values = [...subTenantData]
    values.splice(index, 1)
    setSubTenantData(values)
  }

  // Handle Add Other Options
  const handleAddOther = () => {
    const values = [...otherOptions]
    values.push({
      value: '',
    })
    setOtherOptions(values)
  }

  // Handle Remove Other Options
  const handleRemoveOther = (index: any) => {
    const values = [...otherOptions]
    values.splice(index, 1)
    setOtherOptions(values)

    setIsFormDirty(true)
    localStorage.setItem('isFormDirty', 'true')
  }

  const [count, setcount] = useState<any>(0)
  const [totalamount, settotalamount] = useState<any>(0)
  const [totalpayment, settotalpayment] = useState<any>(0)
  const [createtotalpayment, setCreatetotalpayment] = useState<any>(0)
  const [modeldata, setmodeldata] = useState<any>()
  const [modelindex, setmodelindex] = useState<any>()
  const [modelData, setmodelData] = useState<any>({})
  const [popUpData, setpopUpData] = useState<any>({})
  const [modelchequeData, setmodelchequeData] = useState<any>()
  const [popUpReceiptData, setpopUpReceiptData] = useState<any>()
  const [popupindex, setpopupindex] = useState<any>()
  const [tenancyPayment, setTenancyPayment] = useState<any>([])
  const [createtenancyPayment, setCreateTenancyPayment] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>()
  const [updateIds, setUpdateIds] = useState<any>([])
  const [deletePayment, setDeletePayment] = useState<any>([])
  const [updatePayment, setUpdatePayment] = useState<any>([])
  const [newPayment, setNewPayment] = useState<any>([])
  const [tenanatData, setTenantData] = useState<any>([])

  const [subTenantIds, setSubTenantIds] = useState<any>([])

  const [imgerrors, setImgError] = useState<any>({})
  const [modalBtnIsLoading, setModalBtnIsLoading] = useState<any>(false)
  const [deleteSubtenantArr, setDeleteSubtenantArr] = useState<any>([])
  const [sendError, setSendError] = useState<any>(true)
  const [finalPaid, setFinalPaid] = useState<any>()

  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)

  var optionArryPayload: any

  const unitId = localStorage.getItem('UnitId')
  const navigatePage = localStorage.getItem('page')

  const unitIDfromProperties = useSelector(
    (state: any) => state.counterReducer.unitIDfromProperties
  )
  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)
  const [tenancyStatus, setTenancyStatus] = useState<any>(null)

  const {RangePicker} = DatePicker
  const [isReqCheck, setisReqCheck] = useState<any>(false)
  let status_isDraft: any = localStorage.getItem('isDraft')

  useEffect(() => {
    if (flg == '1') {
      setisReqCheck(true)
    } else {
      setisReqCheck(false)
    }
  }, [])

  const requestorId = useSelector((state: any) => state.counterReducer.requestorId)
  const comefromtenant = useSelector((state: any) => state.counterReducer.comefrom)

  const dateFormat = 'DD/MM/YYYY'

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }
  const popUpchange = (name: string, value: any) => {
    // let {name, value} = e.target
    setpopUpData({...popUpData, [name]: value})
    // setpopUpData({...popUpData, [name]: isNaN(value) ? value : +value})
  }
  const modelchange = (e: any) => {
    let {name, value} = e.target
    setmodelData({...modelData, [name]: value})
  }
  const modelSubmit = () => {
    let data: any
    if (modeldata == '1') {
      data = {
        cheque: modelData,
      }
      data.cheque.image = modelchequeData
    } else if (modeldata == '2') {
      data = {
        bank: modelData,
      }
    } else if (modeldata == '0') {
      data = {
        card: {},
      }
    }
    // else if (modeldata == '1') {
    //   data = {
    //     card: {},
    //   }
    // }
    arry = arry.map((q: any, i: any) => {
      if (i === modelindex) {
        // 👇️ change value of name property
        return {...q, ['paymentValue']: data}
      }
      return q
    })

    setcount(count + 1)
    setShowCheange(false)
  }
  const popUpSubmit = (event: any, index: any) => {
    event.preventDefault()
    let data: any
    setmodelindex(index)
    if (paymentMethod === '0') {
      data = {
        card: {},
      }
      // data.card.image = popUpReceiptData
    } else if (paymentMethod === '1') {
      data = {
        cheque: {
          chequeNo: popUpData?.chequeNo,
          bankName: popUpData?.bankName,
          image: modelchequeData,
        },
      }
      // data.cheque.receiptImage = popUpReceiptData
      // data.cheque.chequeImage = modelchequeData
    } else if (paymentMethod === '2') {
      data = {
        bank: {
          IBAN: popUpData?.IBAN,
          accountNo: popUpData?.accountNo,
          bankName: popUpData?.bankName,
        },
      }
      // data.bank.image = popUpReceiptData
    } else {
      data = {
        cash: {},
      }
      // data.cash.image = popUpReceiptData
    }
    let receiptd: any = {
      referenceNo: popUpData?.referenceNo,
      receiptDate: popUpData?.receiptDate,
      image: popUpReceiptData,
    }

    let temp: any = {...popUpData, paymentValue: data, paymentMethod}
    setFinalPaid([temp])
    console.log('\ntemp', temp)
    const new_tenancyPayment = tenancyPayment.map((el: any, i: any) => {
      if (i == popupindex) {
        return {
          ...el,
          amount: temp.amountPaid,
          amountPaid: temp.amountPaid,
          amountReceived: temp.amountReceived,
          outstandingBalance: temp.outstandingBalance,
          serviceFee: temp.serviceFee,
          paymentValue: temp.paymentValue,
          paymentMethod: temp.paymentMethod,
          receiptDate: temp.receiptDate,
          paymentDate: temp.paymentDate,
          receiptURL: popUpReceiptData,
        }
      } else {
        return el
      }
    })
    const new_createtenancyPayment = createtenancyPayment.map((el: any, i: any) => {
      if (i == popupindex) {
        return {
          ...el,
          amount: temp.amountPaid,
          amountPaid: temp.amountPaid,
          amountReceived: temp.amountReceived,
          outstandingBalance: temp.outstandingBalance,
          serviceFee: temp.serviceFee,
          paymentValue: temp.paymentValue,
          paymentMethod: temp.paymentMethod,
          receiptDate: temp.receiptDate,
          paymentDate: temp.paymentDate,
          receiptURL: popUpReceiptData,
        }
      } else {
        return el
      }
    })
    setTenancyPayment(new_tenancyPayment)
    setCreateTenancyPayment(new_createtenancyPayment)

    setpopUpData({})
    setmodelchequeData('')
    setpopUpReceiptData('')

    setcount(count + 1)
    setShowPaid(false)
  }

  const handleChangeContractNo = (e: any) => {
    const {name, value} = e.target
    setTenancyData({...tenancyData, [name]: value})
    setIsFormDirty(true)
    localStorage.setItem('isFormDirty', 'true')
  }

  const handleChnageTenancy = (e: any) => {
    console.log(e.target.value)
    const {name, value} = e.target
    setTenancyData({...tenancyData, [name]: value})

    setTimeout(() => {
      autoSaveTenancy()
    }, 2500)
  }

  const handleChnagePayment = (e: any, i: any, v: any) => {
    let {name, value} = e.target
    if (name == 'amount' || name == 'paymentMethod' || name == 'rentType' || name == 'status') {
      value = parseInt(value)
    }
    if (flg == '1') {
      if (name == 'amount') {
        let dat = createtenancyPayment[i].amount
        if (!value) {
          value = 0
        }

        if (dat) {
          settotalamount(totalamount - dat + value)
        } else {
          settotalamount(totalamount + value)
        }
      }
      let updateTenancyPayment = createtenancyPayment.map((q: any, k: any) => {
        if (k === i) {
          if (name == 'paymentSchedule' || name == 'paymentRemainder') {
            let d = new Date(value)
            return {...q, [name]: moment(d).format('YYYY-MM-DD')}
          } else {
            return {...q, [name]: value}
          }
        }
        return q
      })
      setCreateTenancyPayment(updateTenancyPayment)
      setcount(count + 1)
      setPaymentData({...paymentData, [name]: value})
    } else {
      if (name == 'amount') {
        let dat = tenancyPayment[i].amount
        if (!value) {
          value = 0
        }

        if (dat) {
          settotalamount(totalamount - dat + value)
        } else {
          settotalamount(totalamount + value)
        }
      }
      let updateTenancyPayment = tenancyPayment.map((q: any, k: any) => {
        if (k === i) {
          if (name == 'paymentSchedule' || name == 'paymentRemainder') {
            let d = new Date(value)
            return {...q, [name]: moment(d).format('YYYY-MM-DD')}
          } else {
            return {...q, [name]: value}
          }
        }
        return q
      })
      setTenancyPayment(updateTenancyPayment)
      setcount(count + 1)
      setPaymentData({...paymentData, [name]: value})
      if (!updateIds.includes(v._id)) {
        setUpdateIds([...updateIds, v._id])
      }
    }
  }

  const callmodel = (data: any, index: any) => {
    setmodeldata(data)
    setmodelindex(index)
    setcount(count + 1)
    setShowCheange(true)
  }
  const callpopUp = (data: any, index: any) => {
    setmodelindex(index)
    setcount(count + 1)
    setShowPaid(true)
  }

  // work in progress

  const handleChnageOption = (e: any) => {
    let {name, checked} = e.target
    console.log('e.target.value', e.target.value)
    if (name == 'other') {
      setOptionArry({...optionArry, [name]: [e.target.value]})
      optionArryPayload = {...optionArry, [name]: [e.target.value]}
    } else {
      if (name == 'none' && checked == true) {
        setOption(0)
        setOptionArry({
          ...optionArry,
          ['other']: [],
          ['chillerFree']: false,
          ['maintenanceFree']: false,
          [name]: checked,
        })
        optionArryPayload = {
          ...optionArry,
          ['other']: [],
          ['chillerFree']: false,
          ['maintenanceFree']: false,
          [name]: checked,
        }
      } else {
        setOptionArry({...optionArry, [name]: checked})
        optionArryPayload = {...optionArry, [name]: checked}
      }

      localStorage.setItem('isFormDirty', 'true')
    }

    setcount(count + 1)
    setIsFormDirty(true)
  }

  const getUbitDataById = () => {
    if (comefrom == 'properties') {
      ApiGet(`corporate/unit/${unitIDfromProperties}`)
        .then((res) => {
          setUnitData(res?.data?.data)
          setSendError(false)
          setDisabledBtn(false)
        })
        .catch((err) => console.log('err', err))
    } else if (comefrom == 'tenancy' && unitId != null) {
      ApiGet(`corporate/unit/${unitId}`)
        .then((res) => {
          setUnitData(res?.data?.data)
          setSendError(false)
          setDisabledBtn(false)
        })
        .catch((err) => console.log('err', err))
    } else {
      if (unitId != null) {
        ApiGet(`corporate/unit/${unitId}`)
          .then((res) => {
            setUnitData(res?.data?.data)
            setSendError(false)
            setDisabledBtn(false)
          })
          .catch((err) => console.log('err', err))
      }
      // else {
      //   ApiGet(`corporate/unit/${id}`)
      //     .then((res) => {
      //       setUnitData(res?.data?.data)
      //       setSendError(false)
      //       setDisabledBtn(false)
      //     })
      //     .catch((err) => console.log('err', err))
      // }
    }
  }

  const getUnitById = (value: any) => {
    ApiGet(`corporate/unit/${value}`)
      .then((res) => {
        setUnitData(res?.data?.data)
        setSendError(false)
        setDisabledBtn(false)
      })
      .catch((err) => console.log('err', err))
  }

  const handleSubmitId = (value: any) => {
    setIsFormDirty(true)
    getUnitById(value)
  }
  const handleChnageSubTenant = (e: any) => {
    const {name, value} = e.target
    setSubTenant({...subTenant, [name]: value})
  }

  const handleChnageTenancyDate = (dates: any) => {
    let body: any
    if (flg == '1') {
      body = {
        startDate: dates?.[0].format('YYYY-MM-DD'),
        endDate: dates?.[1].format('YYYY-MM-DD'),
        unitId: unitData?._id,
      }
    } else {
      body = {
        startDate: dates?.[0].format('YYYY-MM-DD'),
        endDate: dates?.[1].format('YYYY-MM-DD'),
        unitId: unitData?._id,
        tenancyIId: tenancyId,
      }
    }

    ApiPost(`corporate/tenancy/term/validator`, body)
      .then((res) => {
        if (res?.data?.data?.isAlreadyExist)
          ErrorToast(
            'This unit is currently occupied during the selected term, please select another term date'
          )
        else {
          setStart(dates?.[0].format('DD/MM/YYYY'))
          setStartDatePayload(dates?.[0].format('YYYY-MM-DD'))

          setEnd(dates?.[1].format('DD/MM/YYYY'))
          setEndDatePayload(dates?.[1].format('YYYY-MM-DD'))

          var start = moment(dates?.[0])
          var end = moment(dates?.[1])
          setTotalDays(end.diff(start, 'days', true)?.toFixed(2) + ' days')

          setIsFormDirty(true)
        }
      })
      .catch((err) => console.log('err', err))

    // console.log(dates)
    // setStart(moment(e[0]?._d).format('YYYY-MM-DD'))
    // setEnd(moment(e[1]?._d).format('YYYY-MM-DD'))
  }

  const handleChnage12 = (e: any) => {
    const {name, value} = e.target
    setPaymentMethod(e.target.value)
  }

  const deleteSubtenant = (j: any) => {
    setDeleteSubtenantArr((prev: any) => [
      ...prev,
      subTenantData.filter((el: any, i: any) => i == j),
    ])
    setSubTenantData(subTenantData.filter((el: any, i: any) => i !== j))
  }
  const addSubTenant = () => {
    setSubTenantIds([...subTenantIds, subTenantId])
    setSubTenantData([...subTenantData, subTenant])
    setCreate(false)

    let values = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      countryCode: '',
      email: '',
    }
    setSubTenant(values)
    setSubTenantId('')
  }

  const deleteRow = (i: any, amount: any, deleteData: any) => {
    if (flg == '1') {
      setCreateTenancyPayment(createtenancyPayment.filter((v: any, index: any) => index !== i))
    } else if (flg == '2' || flg == '3') {
      setTenancyPayment(tenancyPayment.filter((v: any, index: any) => index !== i))
      setDeletePayment([...deletePayment, deleteData])
      if (amount == undefined) {
        amount = 0
      }
      settotalamount(totalamount - amount)
      settotalpayment(totalpayment - 1)
      setcount(count + 1)
      if (tenancyPayment.length === 0) {
        setCard('')
      }
      let a: any = []
      tenancyPayment.map((v: any) => {
        if (v?.paymentMethod === 0) {
          a.push(v?.paymentMethod)
        }
        if (v?.paymentMethod === 1) {
          a.push(v?.paymentMethod)
        }
      })
      const ages = a
      const uniqueAges = ages.filter((x: any, i: any, a: any) => a.indexOf(x) == i)

      if (uniqueAges.length === 2) {
        setCard('2')
      }
      if (uniqueAges.length === 1) {
        if (uniqueAges[0] === 0) {
          setCard('0')
        } else {
          setCard('1')
        }
      }
      if (uniqueAges.length === 0) {
        if (a.length === 0) {
          setCard('')
        }
      }
    }
  }

  const imageCheque = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/cheque', formData)
      .then(async (res) => {
        setmodelchequeData(res?.data?.data?.image)
      })
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const imagerecipt = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/cheque', formData)
      .then(async (res) => {
        setpopUpReceiptData(res?.data?.data?.image)
      })
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }

  const [uploadOtherImgTenancy, setuploadOtherImgTenancy] = useState<any>([])
  const [uploadContractTenancy, setuploadContractTenancy] = useState<any>([])

  const [uploadOtherImgTenant, setuploadOtherImgTenant] = useState<any>([])
  const [uploadIdTenant, setuploadIdTenant] = useState<any>([])
  const [uploadresidencyTenant, setuploadresidencyTenant] = useState<any>([])
  const [uploadPassportTenant, setuploadPassportTenant] = useState<any>([])
  const [selectedUploadFile, setSelectedUploadFile] = useState<any>()
  const [finalFileUpload, setFinalFileUpload] = useState<any>([])
  const [fileFound, setFileFound] = useState<any>(false)
  const [newTenancyID, setNewTenancyID] = useState<any>(null)
  const [newTenancyData, setNewTenancyData] = useState<any>(state?.tenancyData)
  const [newTenantId, setNewTenantID] = useState<any>('')
  const [tenantDocModal, setTenantDocModal] = useState<any>(false)

  const [editName, setEditName] = useState(false)
  // const dropArea: any = document.querySelector('.drag-area'),
  //   dragText = dropArea.querySelector('header'),
  //   button = dropArea.querySelector('button'),
  //   input = dropArea.querySelector('input')
  // let file: any = ''
  // button.onclick = () => {
  //   input.click() //if user click on the button then the input also clicked
  // }
  // input.addEventListener('change', function (e: any) {
  //   //getting user select file and [0] this means if user select multiple files then we'll select only the first one
  //   console.log('this.files[0]', e)
  //   // file = this.files[0]
  // })

  const [payments, setPayments] = useState<any>()
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)
  const [unitDetails, setUnitDetails] = useState<any>()
  const [editIncomeModal, setEditIncomeModal] = useState<any>(false)
  const [editIncomeModalDraft, setEditIncomeModalDraft] = useState<any>(false)
  const [dataToEdit, setDataToEdit] = useState<any>()
  const [indexToEdit, setIndexToEdit] = useState<any>()

  const [addNewTenant, setAddNewTenant] = useState<any>(false)
  const [editTenantModal, setEditTenantModal] = useState<any>(false)
  const [tenantIdToView, setTenantIdToView] = useState<any>()
  const [tenantIdForFolder, setTenantIdForFolder] = useState<any>()
  const [incomingPaymentId, setIncomingPaymentId] = useState<any>()
  const [paymentId, setPaymentId] = useState<any>()

  const [tenantFolderModal, setTenantFolderModal] = useState<any>(false)
  const [tenantFileModal, setTenantFileModal] = useState<any>(false)
  const [targetFolder, setTargetFolder] = useState<any>()

  const getAllPayments = () => {
    const body = {
      limit: 25,
      page: page,
      tenancyId :tenancyId
    }
      ApiPost(`corporate/payment/get/tenancy`, body)
        .then((res) => {
          setIncomePaymentPayload(res?.data?.data?.payment_data)
          setPageLimit(res?.data?.data?.state?.page_limit)
          for (let i = 0; i < res?.data?.data?.payment_data.length; i++) {
            let values = [...show]
            values[i] = false
            setShow(values)
          }
        })
        .catch((err) => console.log('err', err))
      }
  
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const getPaymentById = async (id: any) => {
    ApiGet(`corporate/payment/${id}`)
      .then(async (res) => {
        setDataToEdit(res?.data?.data)
        setIncomingPaymentId(res?.data?.data?._id)
        setPaymentId(res?.data?.data?.id)

        await ApiGet(`corporate/unit/${res?.data?.data?.unitId}`)
          .then((res) => {
            setUnitDetails(res?.data?.data)
            setEditIncomeModal(true)
          })
          .catch((err) => console.log('err', err))
      })
      .catch((err) => console.log('err', err))
  }

  const [randomDatabaseId, setRandomDatabaseId] = useState<any>()
  useEffect(() => {
    setRandomDatabaseId(new Date().getTime())
  }, [])

  let fileLists: any = []

  const onFilesAdded = async (event: any, type: any) => {
    setFileFound(false)
    const files = event.target.files
    fileLists = Array.from(files)
    setSelectedUploadFile({files: fileLists, type: type})
    fileLists.forEach((element: any, i: any) => {
      setFinalFileUpload((prev: any) => {
        if (prev[i]) {
          prev[i].index = i
          prev[i].fileName = type == 'tenancy' || type == 'tenant' ? '' : element.name
          prev[i].selctedfile = element
          prev[i].type = type
          return [...prev]
        } else {
          return [...prev, {index: i, fileName: '', selctedfile: element, type: type}]
        }
      })
    })
    if (type == 'tenancy' || type == 'tenant') {
      handleShow()
    } else {
      saveImageFileName()
    }
  }
  const uploadImageName = (e: any, selctedfile: any, i: any) => {
    setFinalFileUpload((prev: any) => {
      prev[i].fileName = e.target.value
      return [...prev]
    })
  }
  const getFilename = (file: any) => {
    return file.split('/').pop()
  }

  const getFilenameToSelect = (file: any, type: any) => {
    if (type) return file.split('.')[1]
    else return file.split('.')[0]
  }
  const saveImageFileName = async () => {
    setModalBtnIsLoading(true)
    setFileFound(false)
    for (let i = 0; i < finalFileUpload.length; i++) {
      let formData: any = new FormData()
      let data: any = finalFileUpload[i]
      let fileURL: any = URL.createObjectURL(data.selctedfile)
      let extensionType = data?.selctedfile?.name?.split('.')[1]
      let type: any = finalFileUpload[i].type

      data.fileURL = fileURL
      formData.append('image', data?.selctedfile)
      let fileType: any = ''

      switch (type) {
        case 'tenancy':
          fileType = 'tenancy_other'
          break
        case 'tenant':
          fileType = 'tenant_other'
          break
        default:
          break
      }

      // if (type == 'tenancy' || type == 'tenant') {
      await ApiPost(
        `upload/file_checker?fileType=${fileType}&databaseId=${randomDatabaseId}&fileName=${finalFileUpload[i]?.fileName}.${extensionType}`,
        formData
      )
        .then(async (res) => {
          if (res?.data?.data?.isFileExist) {
            setFileFound(true)
          } else {
            await ApiUpload(
              `upload/image/params?fileType=${fileType}&databaseId=${randomDatabaseId}&fileName=${finalFileUpload[i]?.fileName}.${extensionType}`,
              formData
            )
              .then((res) => {
                if (type == 'tenant') {
                  setuploadOtherImgTenant([...uploadOtherImgTenant, res?.data?.data?.image])
                } else if (type == 'tenancy') {
                  setuploadOtherImgTenancy([...uploadOtherImgTenancy, res?.data?.data?.image])
                }
                setIsFormDirty(true)
                handleClose()
              })
              .catch((err) => {
                console.log('res_blob', err)
                ErrorToast(err?.message)
              })

            if (type == 'tenancy') {
              setTimeout(() => {
                autoSaveTenancy()
              }, 2000)
            } else {
            }
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
          setModalBtnIsLoading(false)
        })
    }
  }

  let fileLists_single: any = []

  const onFilesAddedSingle = async (event: any, type: any, isTenancy: any) => {
    const files = event.target.files
    fileLists_single = Array.from(files)

    for (let i = 0; i < fileLists_single.length; i++) {
      let formData: any = new FormData()
      let data: any = fileLists_single[i]
      let fileURL: any = URL.createObjectURL(data)
      let filetypes: any = fileLists_single[i].type
      data.fileURL = fileURL
      formData.append('image', data)
      console.log('\ntype', filetypes)

      let url = ''
      if (type == 'tenant') {
        url = 'upload/image/tenant_other'
      } else if (type == 'tenancy') {
        url = 'upload/image/tenancy_other'
      } else if (type == 'uploadId') {
        url = 'upload/image/tenant_id'
      } else if (type == 'uploadResidency') {
        url = 'upload/image/tenant_residency'
      } else if (type == 'uploadPassport') {
        url = 'upload/image/tenant_passport'
      } else if (type == 'uploadContract') {
        url = 'upload/image/tenancy_contract'
      }

      await ApiUpload(`${url}`, formData)
        .then((res) => {
          if (type == 'uploadId') {
            setuploadIdTenant([res?.data?.data?.image])
          } else if (type == 'uploadResidency') {
            setuploadresidencyTenant([res?.data?.data?.image])
          } else if (type == 'uploadPassport') {
            setuploadPassportTenant([res?.data?.data?.image])
          } else if (type == 'uploadContract') {
            setuploadContractTenancy([res?.data?.data?.image])
          }

          if (isTenancy) {
            setTimeout(() => {
              autoSaveTenancy()
            }, 2500)
          } else {
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    }
  }

  const handleClose = () => {
    setModalBtnIsLoading(false)
    setShowImageName(false)
  }
  const handleShow = () => setShowImageName(true)

  const deleteUploadImg = async (e: any, type: any, ele: any) => {
    const url = ele.replace(`${Bucket}`, '')
    await ApiPost('upload/delete_file', {url})
      .then(() => {
        if (type == 'tenant') {
          setuploadOtherImgTenant(uploadOtherImgTenant.filter((flg: any, j: any) => j !== e))
        } else if (type == 'tenancy') {
          setuploadOtherImgTenancy(uploadOtherImgTenancy.filter((flg: any, j: any) => j !== e))
          setTimeout(() => {
            autoSaveTenancy()
          }, 2500)
        } else if (type == 'uploadId') {
          setuploadIdTenant(uploadIdTenant.filter((flg: any, j: any) => j !== e))
        } else if (type == 'uploadResidency') {
          setuploadresidencyTenant(uploadresidencyTenant.filter((flg: any, j: any) => j !== e))
        } else if (type == 'uploadPassport') {
          setuploadPassportTenant(uploadPassportTenant.filter((flg: any, j: any) => j !== e))
        } else if (type == 'uploadContract') {
          setuploadContractTenancy(uploadContractTenancy.filter((flg: any, j: any) => j !== e))
        }
      })

      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const [newName, setnewName] = useState<any>()
  const [isEditImgName, setIsEditImgName] = useState<any>(false)
  const [isEditTenant, setIsEditTenant] = useState<any>(false)

  const changeFileNameOther = async (type: any, index: any) => {
    let body: any
    if (isEditImgName) {
      if (type == 'tenancy') {
        body = {
          newFileName: newName + '.' + uploadOtherImgTenancy[index].split('.').pop(),
          image: uploadOtherImgTenancy[index],
        }
      } else if (type == 'tenant') {
        body = {
          newFileName: newName + '.' + uploadOtherImgTenant[index].split('.').pop(),
          image: uploadOtherImgTenant[index],
        }
      }
      console.log('\nbody', body)

      await ApiPost('upload/rename_file', body)
        .then((res) => {
          if (type == 'tenancy') {
            setEditName(false)
            // setuploadOtherImgTenancy([...uploadOtherImgTenancy, res?.data?.data?.image])
            const remove_index = uploadOtherImgTenancy.filter((el: any, i: any) => i !== index)
            setuploadOtherImgTenancy([...remove_index, res?.data?.data?.image])
          } else if (type == 'tenant') {
            setEditName(false)
            const remove_index = uploadOtherImgTenant.filter((el: any, i: any) => i !== index)
            setuploadOtherImgTenant([...remove_index, res?.data?.data?.image])
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    } else {
      setEditName(false)
    }
  }

  const handleNumber = (Phone: any, e: any) => {
    let CountryCode: any = e?.dialCode
    let PhoneNumber: any = Phone.split(CountryCode)[1]
    setFormData({
      ...formData,
      ['countryCode']: parseInt(CountryCode),
      ['phoneNumber']: parseInt(PhoneNumber),
    })
    setPhone(Phone)
  }

  const handleNumber1 = (Phone: any, e: any) => {
    let CountryCode: any = e.dialCode
    let PhoneNumber: any = Phone.split(CountryCode)[1]

    setSubTenant({
      ...subTenant,
      ['countryCode']: parseInt(CountryCode),
      ['phoneNumber']: parseInt(PhoneNumber),
    })
  }
  setTimeout(() => {
    setIsLoading(false)
  }, 5000)

  const varificationCodeValidation = () => {
    let errorMsg: any = {
      error: {},
      isValid: true,
    }

    // tenant
    // if (!uploadPassportTenant.length) {
    //   errorMsg.isValid = false
    //   errorMsg.error['passport'] = 'this field is Required'
    // }
    // if (!uploadresidencyTenant.length) {
    //   errorMsg.isValid = false
    //   errorMsg.error['residancy'] = 'this field is Required'
    // }
    // if (!uploadIdTenant.length) {
    //   errorMsg.isValid = false
    //   errorMsg.error['id'] = 'this field is Required'
    // }
    // if (!uploadOtherImgTenant.length) {
    //   errorMsg.isValid = false
    //   errorMsg.error['otherimgtenant'] = 'this field is Required'
    // }

    // tenancy
    // if (!uploadContractTenancy.length) {
    //   errorMsg.isValid = false
    //   errorMsg.error['contract'] = 'this field is Required'
    // }
    // if (!uploadOtherImgTenancy.length) {
    //   errorMsg.isValid = false
    //   errorMsg.error['otherimgtenancy'] = 'this field is Required'
    // }
    if (!unitData?._id) {
      errorMsg.isValid = false
      errorMsg.error['unitdata'] = 'This field is Required'
    }
    return errorMsg
  }

  const checkIsImage = (fileUrl: any) => {
    if (fileUrl?.includes('blob')) {
      return true
    } else if (fileUrl?.match(/\.(jpg|jpeg|png|gif)$/i)) {
      return true
    }
    return false
  }

  const removeBucket = (url: any) => {
    if (typeof url === 'string') {
      return url.replaceAll(Bucket, '')
    } else {
      let urlData: any = []
      url?.forEach((element: any) => {
        urlData?.push(element?.replaceAll(Bucket, ''))
      })
      return urlData
    }
  }

  const [tenantDocuments, setTenantDocuments] = useState<any>([])
  const [tenantDeletedDocuments, setTenantDeletedDocuments] = useState<any>([])

  const [contractDocuments, setContractDocuments] = useState<any>([])
  const [contractDeletedDocuments, setContractDeletedDocuments] = useState<any>([])

  const onSubmitNewFlow = (data: any, event: any, isDraft: any) => {
    // event.preventDefault()
    setIsLoading(true)

    //  tenant Documents
    let tentDoc: any = []
    let tentDocInd: any = 0

    for (let i = 0; i < tenantDocuments?.length; i++) {
      if (tenantDocuments[i]?.isCheck) {
        let files: any = []
        if (tenantDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < tenantDocuments[i]?.fileList?.length; j++) {
            if (tenantDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: tenantDocuments[i]?.fileList[j]?.fileName,
                fileURL: tenantDocuments[i]?.fileList[j]?.fileURL,
                _id: tenantDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        tentDoc[tentDocInd] = {
          _id: tenantDocuments[i]?._id,
          name: tenantDocuments[i]?.name,
          isSystemGenerated: tenantDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        tentDocInd = tentDocInd + 1
      }
    }

    tentDocInd = tentDoc.length

    for (let i = 0; i < tenantDeletedDocuments?.length; i++) {
      if (tenantDeletedDocuments[i]?.isCheck) {
        let files: any = []
        if (tenantDeletedDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < tenantDeletedDocuments[i]?.fileList?.length; j++) {
            if (tenantDeletedDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: tenantDeletedDocuments[i]?.fileList[j]?.fileName,
                fileURL: tenantDeletedDocuments[i]?.fileList[j]?.fileURL,
                _id: tenantDeletedDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        tentDoc[tentDocInd] = {
          _id: tenantDeletedDocuments[i]?._id,
          name: tenantDeletedDocuments[i]?.name,
          isSystemGenerated: tenantDeletedDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        tentDocInd = tentDocInd + 1
      }
    }

    // contract Documents
    let contractDoc: any = []
    let contractDocInd: any = 0

    for (let i = 0; i < contractDocuments?.length; i++) {
      if (contractDocuments[i]?.isCheck) {
        let files: any = []
        if (contractDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < contractDocuments[i]?.fileList?.length; j++) {
            if (contractDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: contractDocuments[i]?.fileList[j]?.fileName,
                fileURL: contractDocuments[i]?.fileList[j]?.fileURL,
                _id: contractDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        contractDoc[contractDocInd] = {
          _id: contractDocuments[i]?._id,
          name: contractDocuments[i]?.name,
          isSystemGenerated: contractDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        contractDocInd = contractDocInd + 1
      }
    }

    contractDocInd = contractDoc.length

    for (let i = 0; i < contractDeletedDocuments?.length; i++) {
      if (contractDeletedDocuments[i]?.isCheck) {
        let files: any = []
        if (contractDeletedDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < contractDeletedDocuments[i]?.fileList?.length; j++) {
            if (contractDeletedDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: contractDeletedDocuments[i]?.fileList[j]?.fileName,
                fileURL: contractDeletedDocuments[i]?.fileList[j]?.fileURL,
                _id: contractDeletedDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        contractDoc[contractDocInd] = {
          _id: contractDeletedDocuments[i]?._id,
          name: contractDeletedDocuments[i]?.name,
          isSystemGenerated: contractDeletedDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        contractDocInd = contractDocInd + 1
      }
    }

    let temp: any = []
    let otherInd: any = 0
    for (let i = 0; i < otherOptions?.length; i++) {
      if (otherOptions[i]?.value?.trim().length > 0) {
        temp[otherInd] = otherOptions[i]?.value
        otherInd++
      }
    }

    let valuesBenefit = {...optionArry}
    if (optionArry?.none == false) valuesBenefit.other = temp

    setOptionArry(valuesBenefit)

    let subIds: any = []
    let j: any = 0
    for (let i = 0; i < subTenantData?.length; i++) {
      if (subTenantData[i]?.id != '') {
        subIds[j] = subTenantData[i]?.id
        j++
      }
    }

    let validationCall: any = varificationCodeValidation()
    setImgError(validationCall.error)

    let benifit_optionArray: any = optionArry

    if (flg == '2' || flg == '3') {
      delete valuesBenefit._id
      benifit_optionArray = optionArry
    }

    if (validationCall.isValid) {
      const body = {
        unitId: unitData?._id ? unitData?._id : null,
        mainTenantId: newTenantId,
        subTenantIds: subIds,
        tenancy: {
          _id: tenancyId,
          isDraft: false,
          contractNo: tenancyData?.contractNo,
          duration: {
            start_date: startDatePayload,
            end_date: endDatePayload,
            days: totalDays.split(' ')[0],
          },
          benefits: valuesBenefit,
          renewalDays: Number(tenancyData?.renewalDays),
          // tenantDocument: tentDoc,
          contractDocument: contractDoc,
        },
        // paymentList: incomePaymentPayload,
      }
      ApiPost('corporate/tenancy/form/handler', body)
        .then((res) => {
          setIsFormDirty(false)
          // SuccessToast(res?.data?.message)
          SuccessToast('Tenancy data has been updated successfully..')
          if (event == 'exit') {
            navigate('/tenancies')
            localStorage.removeItem('isFormDirty')
          } else {
            setTimeout(() => {
              setIsEditTenant(false)
              getTenancyData(tenancyId)
              setIsEdit(false)
              localStorage.removeItem('isFormDirty')
            }, 800)
          }
          setIsLoading(false)
        })
        .catch((err) => {
          setIsLoading(false)
          ErrorToast(err.message)
        })
    }
  }

  const submitTenantDocuments = (data: any, event: any, isDraft: any) => {
    event.preventDefault()

    //  tenant Dpcuments
    let tentDoc: any = []
    let tentDocInd: any = 0

    for (let i = 0; i < tenantDocuments?.length; i++) {
      if (tenantDocuments[i]?.isCheck) {
        let files: any = []
        if (tenantDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < tenantDocuments[i]?.fileList?.length; j++) {
            if (tenantDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: tenantDocuments[i]?.fileList[j]?.fileName,
                fileURL: tenantDocuments[i]?.fileList[j]?.fileURL,
                _id: tenantDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        tentDoc[tentDocInd] = {
          _id: tenantDocuments[i]?._id,
          name: tenantDocuments[i]?.name,
          isSystemGenerated: tenantDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        tentDocInd = tentDocInd + 1
      }
    }

    // contract Documents
    let contractDoc: any = []
    let contractDocInd: any = 0

    for (let i = 0; i < contractDocuments?.length; i++) {
      if (contractDocuments[i]?.isCheck) {
        let files: any = []
        if (contractDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < contractDocuments[i]?.fileList?.length; j++) {
            if (contractDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: contractDocuments[i]?.fileList[j]?.fileName,
                fileURL: contractDocuments[i]?.fileList[j]?.fileURL,
                _id: contractDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        contractDoc[contractDocInd] = {
          _id: contractDocuments[i]?._id,
          name: contractDocuments[i]?.name,
          isSystemGenerated: contractDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        contractDocInd = contractDocInd + 1
      }
    }

    let temp: any = []
    for (let i = 0; i < otherOptions?.length; i++) {
      temp[i] = otherOptions[i]?.value
    }

    let valuesBenefit = {...optionArry}
    valuesBenefit.other = temp

    setOptionArry(valuesBenefit)

    setIsLoading(true)

    let subIds: any = []
    let j: any = 0
    for (let i = 0; i < subTenantData?.length; i++) {
      if (subTenantData[i]?.id != '') {
        subIds[j] = subTenantData[i]?.id
        j++
      }
    }

    let validationCall: any = varificationCodeValidation()
    setImgError(validationCall.error)

    let benifit_optionArray: any = optionArry

    if (flg == '2' || flg == '3') {
      delete valuesBenefit._id
      benifit_optionArray = optionArry
    }

    if (validationCall.isValid) {
      const body = {
        unitId: unitData?._id ? unitData?._id : null,
        mainTenantId: newTenantId,
        subTenantIds: subIds,
        tenancy: {
          _id: tenancyId,
          isDraft: false,
          contractNo: tenancyData?.contractNo,
          duration: {
            start_date: startDatePayload,
            end_date: endDatePayload,
            days: totalDays.split(' ')[0],
          },
          benefits: valuesBenefit,
          renewalDays: Number(tenancyData?.renewalDays),
          tenantDocument: tentDoc,
          // contractDocument: contractDoc,
        },
        // paymentList: incomePaymentPayload,
      }
      ApiPost('corporate/tenancy/form/handler', body)
        .then((res) => {
          setIsFormDirty(false)
          setIsLoading(false)

          SuccessToast(res?.data?.message)
          setTimeout(() => {
            setIsEditTenant(false)
            getTenancyData(tenancyId)
            setIsEdit(false)
          }, 1000)
        })
        .catch((err) => ErrorToast(err.message))
    }
  }

  const onSubmit = (data: any, event: any) => {
    event.preventDefault()
    setIsLoading(true)
    let validationCall: any = varificationCodeValidation()
    setImgError(validationCall.error)

    if (validationCall.isValid) {
      let benifit_optionArray: any = optionArry

      if (flg == '2' || flg == '3') {
        delete optionArry._id
        benifit_optionArray = optionArry
      }

      let body: any = {
        unitId: unitData?._id ? unitData?._id : null,

        isDraft: status_isDraft === '1',
        tenant: {
          isUpdate: isUpdate,
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          phoneNumber: (formData?.phoneNumber).toString(),
          countryCode: (formData?.countryCode).toString(),
          email: formData?.email,
          nationality: formData?.nationality,
          tenantSource: formData?.tenantSource,
          DOB: formData?.DOB,
          document: {
            passport: uploadPassportTenant.length
              ? removeBucket(uploadPassportTenant[0])
              : removeBucket(formData?.document?.passport),
            residency: uploadresidencyTenant.length
              ? removeBucket(uploadresidencyTenant[0])
              : removeBucket(formData?.document?.residency),
            id: uploadIdTenant.length
              ? removeBucket(uploadIdTenant[0])
              : removeBucket(formData?.document?.id),
            other: uploadOtherImgTenant.length
              ? removeBucket(uploadOtherImgTenant)
              : removeBucket(formData?.document?.other),
          },
        },
        tenancy: {
          contractNo: tenancyData?.contractNo,
          duration: {
            start_date: start_date,
            end_date: end_date,
            days: totalDays.split(' ')[0],
          },
          benefits: benifit_optionArray,
          tenancyStatus: Number(tenancyData?.tenancyStatus),
          totalAmounts: totalamount,
          totalPayments: totalpayment,
          document: {
            contract: uploadContractTenancy.length
              ? removeBucket(uploadContractTenancy[0])
              : removeBucket(formData?.tenancy?.document?.contract),
            other: uploadOtherImgTenancy.length
              ? removeBucket(uploadOtherImgTenancy)
              : removeBucket(formData?.tenancy?.document?.other),
          },
        },
      }

      if (flg == '1') {
        // create tenancy tab save button and create button
        body.incomePayment = incomePaymentPayload
        body.communityId = unitData?.communityId || null
        body.buildingId = unitData?.buildingId || null
        body['subTenant'] = subTenantData.map((val: any) => ({
          ...val,
          phoneNumber: val.phoneNumber.toString(),
        }))
        body['subTenant'].map((el: any) => {
          delete el['registrationStatus']
          delete el['tenantSource']
          delete el['_id']
          return el
        })

        delete body['tenancy']['tenancyStatus']
        let new_createtenancyPayment = createtenancyPayment?.map((el: any) => {
          if (el.receiptDate == null) {
            delete el.receiptDate
          }
          if (el.paymentDate == null) {
            delete el.paymentDate
          }
          if (el.receiptURL == null) {
            delete el.receiptURL
          }
          return el
        })
        // body['payment'] = new_createtenancyPayment
        if (isUpdate) {
          body['tenant']['_id'] = changeid
        }
        console.log('\nbody', body)
        ApiPost('corporate/tenancy/form', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            // building()
            setTimeout(() => {
              navigate(-1)
            }, 1000)
            setIsLoading(false)
          })
          .catch((err) => ErrorToast(err.message))
      } else if (flg == '3') {
        // edit tenancy tab save button
        // delete body['tenancy']?.['benefits']?.['_id']
        delete body['unitId']
        delete body['isDraft']
        delete body['tenancy']['tenancyStatus']
        if (isUpdate) {
          body['tenant']['_id'] = changeid
        } else {
          body['tenant']['_id'] = id
        }
        body['tenancy']['_id'] = tenancyId
        body['tenancy']['isDraft'] = status_isDraft == '1'
        // subtenantdata
        let update_subTenant = subTenantData
          .filter((el: any) => el._id)
          .map((val: any) => ({
            ...val,
            phoneNumber: val.phoneNumber.toString(),
          }))
        body['updateSubTenant'] = update_subTenant
        body['deleteSubTenant'] = deleteSubtenantArr

        let new_subtenant: any = subTenantData
          .filter((el: any) => !el._id)
          .map((val: any) => ({
            ...val,
            phoneNumber: val.phoneNumber.toString(),
          }))
        body['newSubTenant'] = new_subtenant
        body['newSubTenant'].map((el: any) => {
          delete el['registrationStatus']
          delete el['tenantSource']
          delete el['_id']
          return el
        })
        body['deleteSubTenant'].map((el: any) => {
          delete el['registrationStatus']
          delete el['tenantSource']
          delete el['_id']
          return el
        })
        body['updateSubTenant'].map((el: any) => {
          delete el['registrationStatus']
          delete el['tenantSource']
          delete el['_id']
          return el
        })
        // paymentdata
        body['deletePayment'] = deletePayment?.map((el: any) => {
          delete el.workSpaceId
          delete el.paymentDate
          delete el.tenantId
          delete el.tenancyId
          delete el.comment
          delete el.createdAt
          if (el.receiptDate == null) {
            delete el.receiptDate
          }
          if (el.paymentDate == null) {
            delete el.paymentDate
          }
          if (el.receiptURL == null) {
            delete el.receiptURL
          }
          return el
        })

        let new_defaultTenanvyPayment: any = []
        let defaultTenanvyPayment_data = tenancyPayment.filter(
          (u: any) => !tenancyPayment.some((n: any) => n._id === u._id)
        )
        let new_defaultTenanvy: any = [
          ...defaultTenanvyPayment_data,
          ...deletePayment,
          ...tenancyPayment,
        ]

        let updatedIdPayments: any = []
        new_defaultTenanvy.map((el: any) => {
          if (!el?._id) {
            if (el.receiptDate == null) {
              delete el.receiptDate
            }
            delete el.workSpaceId
            delete el.paymentDate
            delete el._id
            delete el.tenantId
            delete el.tenancyId
            delete el.comment
            delete el.createdAt
            if (el.receiptDate == null) {
              delete el.receiptDate
            }
            if (el.paymentDate == null) {
              delete el.paymentDate
            }
            if (el.receiptURL == null) {
              delete el.receiptURL
            }
            new_defaultTenanvyPayment.push(el)
          }
          if (updateIds.includes(el._id)) {
            updatedIdPayments.push(el)
          }
        })
        body['newPayment'] = new_defaultTenanvyPayment
        updatedIdPayments = updatedIdPayments.filter(
          (u: any) => !new_defaultTenanvyPayment.some((n: any) => n.paymentNo === u.paymentNo)
        )
        updatedIdPayments = [...updatedIdPayments, ...tenancyPayment]
        updatedIdPayments = updatedIdPayments.filter(
          (value: any, index: any, self: any) =>
            index === self.findIndex((t: any) => t._id === value._id)
        )
        body['updatePayment'] = updatedIdPayments
          ?.filter((item: any) => item._id)
          .map((el: any) => {
            delete el.workSpaceId
            delete el.paymentDate
            delete el.tenantId
            delete el.tenancyId
            delete el.comment
            delete el.createdAt
            if (el.receiptDate == null) {
              delete el.receiptDate
            }
            if (el.receiptURL == null) {
              delete el.receiptURL
            }
            if (el.paymentDate == null) {
              delete el.paymentDate
            }
            return el
            // if (el.paymentNo) {
            //   //   new_defaultTenanvyPayment.find((newel: any) => newel.paymentNo !== el.paymentNo)
            //   //   return el
            //   // }
            //   new_defaultTenanvyPayment = new_defaultTenanvyPayment.filter((item: any) => !item._id)
            // }
            // if (new_defaultTenanvyPayment.filter((el:any)=>el.paymentNo) {
            //   updatedIdPayments.push(el)
            // }
          })
        // body['subTenant']['_id'] = tenancyId
        // console.log('\nbody', body)
        ApiPut('corporate/tenancy/form', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            // building()
            setIsLoading(false)

            setTimeout(() => {
              // navigate(`/tenancies`)
              navigate(`/${navigatePage}`)
            }, 1000)
          })
          .catch((err) => ErrorToast(err.message))
      } else if (flg == '2') {
        if (comefromtenant == 'tenant') {
          body['tenantRequestId'] = requestorId
        } else {
          delete body['tenantRequestId']
        }
        // renew tenancy tab save button and renew button
        if (status_isDraft == '2') {
          delete body['tenancy']['benefits']['_id']
          delete body['tenancy']['tenancyStatus']
          body.communityId = unitData?.communityId || null
          body.buildingId = unitData?.buildingId || null
          delete body['tenantId']
          body['isDraft'] = status_isDraft == '1'
          // subtenantdata
          body['deleteSubTenant'] = deleteSubtenantArr
          body['newSubTenant'] = subTenantData
            .filter((el: any) => !el._id)
            .map((val: any) => ({
              ...val,
              phoneNumber: val.phoneNumber.toString(),
            }))
          let update_subTenant = subTenantData
            .filter((el: any) => el._id)
            .map((val: any) => ({
              ...val,
              phoneNumber: val.phoneNumber.toString(),
            }))
          body['updateSubTenant'] = update_subTenant

          body['newSubTenant'].map((el: any) => {
            delete el['registrationStatus']
            delete el['tenantSource']
            delete el['_id']
            return el
          })
          body['deleteSubTenant'].map((el: any) => {
            delete el['registrationStatus']
            delete el['tenantSource']
            delete el['_id']
            return el
          })
          body['updateSubTenant'].map((el: any) => {
            delete el['registrationStatus']
            delete el['tenantSource']
            delete el['_id']
            return el
          })
          // paymentdata
          let new_tenancyPayment = tenancyPayment?.map((el: any) => {
            if (el.receiptDate == null) {
              delete el.receiptDate
            }
            if (el.paymentDate == null) {
              delete el.paymentDate
            }
            if (el.receiptURL == null) {
              delete el.receiptURL
            }
            return el
          })
          if (isUpdate) {
            body['tenant']['_id'] = changeid
          } else {
            body['tenant']['_id'] = id
          }
          body['payment'] = new_tenancyPayment
          // console.log('\nbody post', body)
          ApiPost('corporate/tenancy/renew', body)
            .then((res) => {
              SuccessToast(res?.data?.message)
              setIsLoading(false)
              localStorage.setItem('isDraft', '1')
              setTimeout(() => {
                // navigate(`/tenancies`)
                navigate(`/${navigatePage}`)
              }, 1000)
            })
            .catch((err) => ErrorToast(err.message))
        } else {
          delete body['communityId']
          delete body['unitId']
          delete body['isDraft']
          delete body['buildingId']
          delete body['tenantId']
          delete body['subTenant']
          delete body['payment']

          if (isUpdate) {
            body['tenant']['_id'] = changeid
          } else {
            body['tenant']['_id'] = id
          }
          body['tenancy']['_id'] = tenancyId
          body['tenancy']['isDraft'] = status_isDraft == '1'
          // subtenantdata

          let update_subTenant = subTenantData
            .filter((el: any) => el._id)
            .map((val: any) => ({
              ...val,
              phoneNumber: val.phoneNumber.toString(),
            }))
          body['updateSubTenant'] = update_subTenant
          body['deleteSubTenant'] = deleteSubtenantArr

          let new_subtenant: any = subTenantData
            .filter((el: any) => !el._id)
            .map((val: any) => ({
              ...val,
              phoneNumber: val.phoneNumber.toString(),
            }))
          body['newSubTenant'] = new_subtenant
          body['newSubTenant'].map((el: any) => {
            delete el['registrationStatus']
            delete el['tenantSource']
            delete el['_id']
            return el
          })
          body['deleteSubTenant'].map((el: any) => {
            delete el['registrationStatus']
            delete el['tenantSource']
            delete el['_id']
            return el
          })
          body['updateSubTenant'].map((el: any) => {
            delete el['registrationStatus']
            delete el['tenantSource']
            delete el['_id']
            return el
          })
          // paymentdata
          body['deletePayment'] = deletePayment?.map((el: any) => {
            delete el.workSpaceId
            delete el.paymentDate
            delete el.tenantId
            delete el.tenancyId
            delete el.comment
            delete el.createdAt
            if (el.receiptDate == null) {
              delete el.receiptDate
            }
            if (el.receiptURL == null) {
              delete el.receiptURL
            }
            if (el.paymentDate == null) {
              delete el.paymentDate
            }
            return el
          })

          let new_defaultTenanvyPayment: any = []
          let defaultTenanvyPayment_data = tenancyPayment.filter(
            (u: any) => !tenancyPayment.some((n: any) => n._id === u._id)
          )
          let new_defaultTenanvy: any = [
            ...defaultTenanvyPayment_data,
            ...deletePayment,
            ...tenancyPayment,
          ]

          let updatedIdPayments: any = []
          new_defaultTenanvy.map((el: any) => {
            if (!el?._id) {
              delete el.workSpaceId
              delete el.paymentDate
              delete el._id
              delete el.tenantId
              delete el.tenancyId
              delete el.comment
              delete el.createdAt
              if (el.receiptDate == null) {
                delete el.receiptDate
              }
              if (el.paymentDate == null) {
                delete el.paymentDate
              }
              if (el.receiptURL == null) {
                delete el.receiptURL
              }
              new_defaultTenanvyPayment.push(el)
            }
            if (updateIds.includes(el._id)) {
              updatedIdPayments.push(el)
            }
          })
          body['newPayment'] = new_defaultTenanvyPayment
          updatedIdPayments = updatedIdPayments.filter(
            (u: any) => !new_defaultTenanvyPayment.some((n: any) => n.paymentNo === u.paymentNo)
          )
          body['updatePayment'] = updatedIdPayments
            ?.filter((item: any) => item._id)
            .map((el: any) => {
              delete el.workSpaceId
              delete el.paymentDate
              delete el.tenantId
              delete el.tenancyId
              delete el.comment
              delete el.createdAt
              if (el.receiptDate == null) {
                delete el.receiptDate
              }
              if (el.receiptURL == null) {
                delete el.receiptURL
              }
              if (el.paymentDate == null) {
                delete el.paymentDate
              }
              return el
            })

          // console.log('\nbody put', body)
          ApiPut('corporate/tenancy/form', body)
            .then((res) => {
              SuccessToast(res?.data?.message)
              setIsLoading(false)

              setTimeout(() => {
                // navigate(-1)
                navigate(`/${navigatePage}`)
              }, 1000)
            })
            .catch((err) => ErrorToast(err.message))
        }
      }
      // localStorage.setItem('isDraft', '1')
    }
  }

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(res?.data?.data)
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  const navigate = useNavigate()
  // useEffect(() => {
  //   arry.map((a: any, i: any) => {
  //     if (i === modelindex) {
  //       const payedAmount = +a.amount - +popUpData?.amountPaid;
  //       setoutstandingBalance(payedAmount)
  //     }
  //   })
  // }, [popUpData])
  // useEffect(() => {
  //   setpopUpData({ ...popUpData, outstandingBalance: outstandingBalance })
  // }, [outstandingBalance])

  const getTenantData = () => {
    let id: any = localStorage.getItem('newTenancyID')
    if (flg === '2' || flg === '3') id = tenancyId
    if (flg == '3')
      ApiGet(`corporate/tenant?isNullFilter=true&isCloneRecord=true`)
        // ApiGet(`corporate/tenant/tenancy_record?tenancyId=${id}&isCloneRecord=true`)
        .then((res) => {
          setTenantData(res?.data?.data)
        })
        .catch((err) => console.log('err', err))
    // ApiGet(`corporate/tenant/tenancy_record?tenancyId=${id}`)
    else
      ApiGet(`corporate/tenant?isNullFilter=true`)
        .then((res) => {
          setTenantData(res?.data?.data)
        })
        .catch((err) => console.log('err', err))
  }
  const [changeid, setchangeid] = useState<any>(null)
  const [isUpdate, setIsUpdate] = useState<any>(false)
  const [subTenantId, setSubTenantId] = useState<any>('')
  const [editTenant, setEditTenant] = useState<any>(-1)
  const [tenantFolders, setTenantFolders] = useState<any>([])
  const [residencyFolder, setResidencyFolder] = useState<any>()
  const [passportFolder, setPassportFolder] = useState<any>()
  const [iDsFolder, setIDsFolder] = useState<any>()
  const [contractsFolder, setContractsFolder] = useState<any>()
  const [dataToSelectFrom, setDataToSelectFrom] = useState<any>()
  const [folderFileModal, setFolderFileModal] = useState<any>(false)
  const [fileType, setFileType] = useState<any>()
  const [showBck, setShowBck] = useState<any>()
  const [selectedFolder, setSelectedFolder] = useState<any>()
  const [selectedTenantName, setSelectedTenantName] = useState<any>()

  var tenantName: any

  const handleChnageTenant = async (e: any) => {
    let isSel: any = false

    console.log(subTenantIds)

    for (let i = 0; i < subTenantIds?.length; i++) {
      if (e.target.value == subTenantIds?.[i]) {
        isSel = true
        i = subTenantIds?.length
      }
    }

    if (isSel) {
      ErrorToast('Tenant already Select as Sub-Tenant')
    } else {
      if (e.target.value == 'new') {
        // let newTenantId = localStorage.getItem('newTenantID')
        // setNewTenantID(newTenantId)
        setFormData([])
        setuploadresidencyTenant([])
        setuploadPassportTenant([])
        setuploadIdTenant([])
        setuploadOtherImgTenant([])
        setchangeid(null)

        // setTimeout(() => {
        //   getSubTenants(newTenantId)
        // }, 1500)
      } else {
        setIsUpdate(true)
        setchangeid(e.target.value)
        setNewTenantID(e.target.value)
        if (e.target.value) {
          await ApiGet(`corporate/tenant/${e.target.value}`)
            .then((res) => {
              setTenantIdForFolder(res?.data?.data?._id)
              setIsFormDirty(true)
              let temp = res?.data?.data?.firstName + res?.data?.data?.lastName
              setSelectedTenantName(temp)
              tenantName = temp

              setFormData(res?.data?.data)

              setuploadContractTenancy([])
              setuploadresidencyTenant([])
              setuploadPassportTenant([])
              setuploadIdTenant([])
              setuploadOtherImgTenant([])

              let tentFold: any = []
              let t = 0

              let contFold: any = []
              let c = 0

              for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
                if (res?.data?.data?.tenant_folder?.[i].name == 'Passports') {
                  setPassportFolder(res?.data?.data?.tenant_folder?.[i])
                } else if (res?.data?.data?.tenant_folder?.[i].name == 'Contracts') {
                  // contFold[c] = {
                  //   data: res?.data?.data?.tenant_folder?.[i],
                  //   isChecked: true,
                  // }
                  // c++
                  setContractsFolder(res?.data?.data?.tenant_folder?.[i])
                } else if (res?.data?.data?.tenant_folder?.[i].name == 'IDs') {
                  setIDsFolder(res?.data?.data?.tenant_folder?.[i])
                } else if (res?.data?.data?.tenant_folder?.[i].name == 'Residencies') {
                  setResidencyFolder(res?.data?.data?.tenant_folder?.[i])
                }

                //  Documents Section
                //  Tenant Documents
                if (res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
                  if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                    tentFold[t] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?._id,
                      name: res?.data?.data?.tenant_folder?.[i]?.name,
                      isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                      isCheck: true,
                      fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                    }
                  } else {
                    tentFold[t] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?._id,
                      name: res?.data?.data?.tenant_folder?.[i]?.name,
                      isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                      isCheck: false,
                      fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                    }
                  }
                  console.log(res?.data?.data?.tenant_folder?.[i]?.fileList?.length)

                  for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                    if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                      // tentFold[t].selected = true,
                      tentFold[t].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: true,
                      }
                    } else {
                      // tentFold[t].isCheck = false,
                      tentFold[t].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: true,
                      }
                    }
                  }
                  t++
                }
                // Contract/Tenancy Documents
                if (
                  res?.data?.data?.tenant_folder?.[i].name != 'Passports' &&
                  res?.data?.data?.tenant_folder?.[i].name != 'Residencies' &&
                  res?.data?.data?.tenant_folder?.[i].name != 'IDs'
                ) {
                  if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                    contFold[c] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?._id,
                      name: res?.data?.data?.tenant_folder?.[i]?.name,
                      isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                      isCheck: true,
                      fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                    }
                  } else {
                    contFold[c] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?._id,
                      name: res?.data?.data?.tenant_folder?.[i]?.name,
                      isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                      isCheck: false,
                      fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                    }
                  }
                  console.log(res?.data?.data?.tenant_folder?.[i]?.fileList?.length)

                  for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                    if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                      // tentFold[t].selected = true,
                      contFold[c].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: true,
                      }
                    } else {
                      // tentFold[t].isCheck = false,
                      contFold[c].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: true,
                      }
                    }
                  }
                  c++
                }
              }

              console.log('Tenant Documents')
              console.log(tentFold)
              console.log('Contract Documents')
              console.log(contFold)

              setTenantDocuments(tentFold)

              setContractDocuments(contFold)

              // setStart(res?.data?.data?.tenancy?.duration?.start_date)
              // setEnd(res?.data?.data?.tenancy?.duration?.end_date)

              // setuploadresidencyTenant(
              //   res?.data?.data?.document?.residency ? [res?.data?.data?.document?.residency] : []
              // )
              // setuploadPassportTenant(
              //   res?.data?.data?.document?.passport ? [res?.data?.data?.document?.passport] : []
              // )
              // setuploadIdTenant(res?.data?.data?.document?.id ? [res?.data?.data?.document?.id] : [])
              // setuploadOtherImgTenant(
              //   res?.data?.data?.document?.other ? res?.data?.data?.document?.other : ''
              // )
              // console.log('\n res?.data?.data?.document?.other 1', res?.data?.data?.document?.other)
              // setTimeout(() => {
              //   getSubTenants(e.target.value)
              // }, 1500)
            })
            .catch((err) => console.log('err', err))
        }
      }
    }
  }

  const handleSelectSubTenant = async (e: any, ind: any) => {
    if (e.value == newTenantId) {
      ErrorToast('Tenant already Selected as Main Tenant')
    } else {
      const temp = [...subTenantData]
      temp[ind].id = e.value
      // setSubTenantId(e.target.value)
      if (e.value == 'new') {
        // let id = localStorage.getItem('newSubId')
        // setSubTenantId(id)
        // let values = {
        //   firstName: '',
        //   lastName: '',
        //   phoneNumber: '',
        //   countryCode: '',
        //   email: '',
        // }
        // setSubTenant(values)
      } else {
        if (e.value) {
          await ApiGet(`corporate/tenant/${e.value}`)
            .then((res) => {
              setIsFormDirty(true)
              temp[ind] = {
                firstName: res?.data?.data?.firstName,
                lastName: res?.data?.data?.lastName,
                phoneNumber: res?.data?.data?.phoneNumber,
                countryCode: res?.data?.data?.countryCode,
                email: res?.data?.data?.email,
                passportNo: res?.data?.data?.passportNo,
                idNo: res?.data?.data?.idNo,
                id: res?.data?.data?._id,
                _id: res?.data?.data?._id,
              }
              setSubTenantData(temp)

              setSubTenantIds([...subTenantIds, res?.data?.data?._id])

              setIsFormDirty(true)
              localStorage.setItem('isFormDirty', 'true')
            })
            .catch((err) => console.log('err', err))
        }
      }
    }
  }

  const createTenant = () => {
    ApiPost('corporate/tenant')
      .then((res) => {
        localStorage.setItem('newTenantID', res?.data?.data?._id)
        setNewTenantID(res?.data?.data?._id)
      })
      .catch((err: any) => ErrorToast(err.message))
  }

  const getTenantDocuments = async (id: any, dataTenant: any, dataContract: any) => {
    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        setTenantIdForFolder(res?.data?.data?._id)

        let tentFold: any = []
        let t = 0

        let contFold: any = []
        let c = 0

        let deletedFolder: any = []
        let d: any = 0

        //  Documents Section
        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          let isInc: any = false
          let incTenant: any = false
          let ind: any = 0
          let indTen: any = 0
          let isDeleted = true

          for (let x = 0; x < dataTenant?.length; x++) {
            if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
              isDeleted = false
              x = dataTenant?.length
            }
          }

          if (!isDeleted) {
            //  Tenant Documents
            if (res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
              for (let x = 0; x < dataTenant?.length; x++) {
                if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
                  incTenant = true
                  ind = x
                  x = dataTenant?.length
                } else {
                  incTenant = false
                }
              }

              if (incTenant) {
                tentFold[t] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: true,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  let isAvailable: any = false
                  let isAvailableTenant: any = false

                  for (let x = 0; x < dataTenant[ind]?.fileList?.length; x++) {
                    if (
                      res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                      dataTenant[ind]?.fileList?.[x]?._id
                    ) {
                      // console.log(res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id + ' ' + dataTenant[ind]?.fileList?.[x]?._id)
                      isAvailableTenant = true
                      x = dataTenant[ind]?.fileList?.length
                    } else {
                      isAvailableTenant = false
                    }
                  }

                  // console.log(isAvailableTenant)

                  if (isAvailableTenant) {
                    tentFold[t].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: true,
                    }
                  } else {
                    tentFold[t].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                }
                t++
              } else {
                // console.log(res?.data?.data?.tenant_folder?.[i]?.name)
                tentFold[t] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: false,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  // tentFold[t].isCheck = false,
                  tentFold[t].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: false,
                  }
                }
                t++
              }
            }
          }
        }

        let temp: any = false
        let tempInd: any = 0
        for (let x = 0; x < dataTenant?.length; x++) {
          for (let y = 0; y < tentFold?.length; y++) {
            if (tentFold[y]._id == dataTenant[x]?._id) {
              console.log(tentFold[y]._id + ' ' + dataTenant[x]?._id)
              temp = true
              // tempInd = x
              y = tentFold?.length
            } else {
              temp = false
              tempInd = x
            }
          }
          if (temp == false) {
            if (dataTenant?.[tempInd]?.name != 'Contracts') {
              deletedFolder[d] = {
                _id: dataTenant?.[tempInd]?._id,
                name: dataTenant?.[tempInd]?.name,
                isSystemGenerated: dataTenant?.[tempInd]?.isSystemGenerated,
                isCheck: true,
                fileList: dataTenant?.[tempInd]?.fileList,
              }

              for (let j = 0; j < dataTenant?.[tempInd]?.fileList?.length; j++) {
                deletedFolder[d].fileList[j] = {
                  _id: dataTenant?.[tempInd]?.fileList?.[j]?._id,
                  fileName: dataTenant?.[tempInd]?.fileList?.[j]?.fileName,
                  fileURL: dataTenant?.[tempInd]?.fileList?.[j]?.fileURL,
                  isChecked: true,
                }
              }
            }
            d++
          }
        }

        console.log(' Tenant Deleted Folder')
        console.log(deletedFolder)

        console.log('Tenant Documents')
        console.log(tentFold)

        setTenantDocuments(tentFold)
        setTenantDeletedDocuments(deletedFolder)
      })
      .catch((err) => console.log('err', err))
  }

  const getContractDocuments = async (id: any, dataTenant: any, dataContract: any) => {
    // setContractDocuments([])

    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        setTenantIdForFolder(res?.data?.data?._id)

        let tentFold: any = []
        let t = 0

        let contFold: any = []
        let c = 0

        let deletedFolder: any = []
        let d: any = 0

        //  Documents Section
        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          let isInc: any = false
          let incTenant: any = false
          let ind: any = 0
          let indTen: any = 0
          let isDeleted = true

          // Contract/Tenancy Documents
          // console.log(dataContract)
          // console.log(contractDocuments)
          for (let x = 0; x < dataContract?.length; x++) {
            if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
              isDeleted = false
              x = dataTenant?.length
            }
          }

          if (!isDeleted) {
            if (
              res?.data?.data?.tenant_folder?.[i].name != 'Passports' &&
              res?.data?.data?.tenant_folder?.[i].name != 'Residencies' &&
              res?.data?.data?.tenant_folder?.[i].name != 'IDs'
            ) {
              for (let x = 0; x < dataContract?.length; x++) {
                if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
                  isInc = true
                  indTen = x
                  x = dataContract?.length
                } else {
                  isInc = false
                }
              }

              if (isInc) {
                contFold[c] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: true,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  let isAvailable: any = false

                  for (let x = 0; x < dataContract[indTen]?.fileList?.length; x++) {
                    if (
                      res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                      dataContract[indTen]?.fileList?.[x]?._id
                    ) {
                      isAvailable = true
                      x = dataContract[indTen]?.fileList?.length
                    } else {
                      isAvailable = false
                    }
                  }

                  if (isAvailable) {
                    contFold[c].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: true,
                    }
                  } else {
                    contFold[c].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                }
                c++
              } else {
                contFold[c] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: false,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  // tentFold[t].isCheck = false,
                  contFold[c].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: false,
                  }
                }
                c++
              }
            }
          }
        }

        let temp: any = false
        let tempInd: any = 0
        for (let x = 0; x < dataContract?.length; x++) {
          for (let y = 0; y < contFold?.length; y++) {
            if (contFold[y]._id == dataContract[x]?._id) {
              console.log(contFold[y]._id + ' ' + dataContract[x]?._id)
              temp = true
              // tempInd = x
              y = contFold?.length
            } else {
              temp = false
              tempInd = x
            }
          }
          if (temp == false) {
            if (
              dataContract?.[tempInd]?.name != 'Passports' &&
              dataContract?.[tempInd]?.name != 'Residencies' &&
              dataContract?.[tempInd]?.name != 'IDs'
            ) {
              deletedFolder[d] = {
                _id: dataContract?.[tempInd]?._id,
                name: dataContract?.[tempInd]?.name,
                isSystemGenerated: dataContract?.[tempInd]?.isSystemGenerated,
                isCheck: true,
                fileList: dataContract?.[tempInd]?.fileList,
              }

              for (let j = 0; j < dataContract?.[tempInd]?.fileList?.length; j++) {
                deletedFolder[d].fileList[j] = {
                  _id: dataContract?.[tempInd]?.fileList?.[j]?._id,
                  fileName: dataContract?.[tempInd]?.fileList?.[j]?.fileName,
                  fileURL: dataContract?.[tempInd]?.fileList?.[j]?.fileURL,
                  isChecked: true,
                }
              }
            }
            d++
          }
        }

        console.log('Contract  Deleted Folder')
        console.log(deletedFolder)

        console.log('Contract Documents')
        console.log(contFold)

        setContractDocuments(contFold)
        setContractDeletedDocuments(deletedFolder)
      })
      .catch((err) => console.log('err', err))
  }

  const getTenantById = async (id: any, dataTenant: any, dataContract: any) => {
    setTenantDocuments([])
    setContractDocuments([])

    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        setTenantIdForFolder(res?.data?.data?._id)

        let tentFold: any = []
        let t = 0

        let contFold: any = []
        let c = 0

        //  Documents Section
        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          let isInc: any = false
          let incTenant: any = false
          let ind: any = 0
          let indTen: any = 0

          // Contract/Tenancy Documents
          console.log(dataContract)
          console.log(contractDocuments)
          if (
            res?.data?.data?.tenant_folder?.[i].name != 'Passports' &&
            res?.data?.data?.tenant_folder?.[i].name != 'Residencies' &&
            res?.data?.data?.tenant_folder?.[i].name != 'IDs'
          ) {
            for (let x = 0; x < dataContract?.length; x++) {
              if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
                isInc = true
                indTen = x
                x = dataContract?.length
              } else {
                isInc = false
              }
            }

            if (isInc) {
              contFold[c] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: true,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }

              for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                let isAvailable: any = false

                for (let x = 0; x < dataContract[indTen]?.fileList?.length; x++) {
                  if (
                    res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                    dataContract[indTen]?.fileList?.[x]?._id
                  ) {
                    isAvailable = true
                    x = dataContract[indTen]?.fileList?.length
                  } else {
                    isAvailable = false
                  }
                }

                if (isAvailable) {
                  contFold[c].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: true,
                  }
                } else {
                  contFold[c].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: false,
                  }
                }
              }
              c++
            } else {
              contFold[c] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: false,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }

              for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                // tentFold[t].isCheck = false,
                contFold[c].fileList[j] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                  fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                  fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                  isChecked: false,
                }
              }
              c++
            }
          }
        }

        //  Documents Section
        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          let isInc: any = false
          let incTenant: any = false
          let ind: any = 0
          let indTen: any = 0

          //  Tenant Documents
          if (res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
            for (let x = 0; x < dataTenant?.length; x++) {
              if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
                incTenant = true
                ind = x
                x = dataTenant?.length
              } else {
                incTenant = false
              }
            }

            if (incTenant) {
              tentFold[t] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: true,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }

              for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                let isAvailable: any = false
                let isAvailableTenant: any = false

                for (let x = 0; x < dataTenant[ind]?.fileList?.length; x++) {
                  if (
                    res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                    dataTenant[ind]?.fileList?.[x]?._id
                  ) {
                    console.log(
                      res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id +
                        ' ' +
                        dataTenant[ind]?.fileList?.[x]?._id
                    )
                    isAvailableTenant = true
                    x = dataTenant[ind]?.fileList?.length
                  } else {
                    isAvailableTenant = false
                  }
                }

                console.log(isAvailableTenant)

                if (isAvailableTenant) {
                  console.log('I am in true')
                  tentFold[t].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: true,
                  }
                } else {
                  console.log('I am in false')
                  tentFold[t].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: false,
                  }
                }
              }
              t++
            } else {
              console.log(res?.data?.data?.tenant_folder?.[i]?.name)
              tentFold[t] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: false,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }

              for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                // tentFold[t].isCheck = false,
                tentFold[t].fileList[j] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                  fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                  fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                  isChecked: false,
                }
              }
              t++
            }
          }
        }

        console.log('Tenant Documents')
        console.log(tentFold)
        console.log('Contract Documents')
        console.log(contFold)

        setTenantDocuments(tentFold)

        setContractDocuments(contFold)
      })
      .catch((err) => console.log('err', err))
  }

  const getTenancyData = (id: any) => {
      ApiGet(`corporate/tenancy/full_detail/${id}`)
        .then((res) => {
          // Financial Summary
          let finance: any = res?.data?.data?.[0]?.financialSummery
          let otherInd: any = 0
          let otherValues: any = []
          for (let i = 0; i < finance?.length; i++) {
            if (finance[i]?.name == 'Rent') setRentFin(finance[i])
            else if (finance[i]?.name == 'Security Deposit') setSecurityFin(finance[i])
            else {
              otherValues[otherInd] = finance[i]
              otherInd++
            }
          }
          setOtherFinancials(otherValues)

          if (res?.data?.data?.[0]?.tenant?.length > 0) {
            setNewTenantID(res?.data?.data?.[0]?.tenant?.[0]?._id)
            setFormData(res?.data?.data?.[0]?.tenant?.[0])
            setTenantIdForFolder(res?.data?.data?.[0]?.tenant?.[0]?._id)
          }

          setNewTenancyID(res.data.data?.[0]?._id)
          setTenancyData(res?.data?.data?.[0])
          setTenancyPayment(res?.data?.data[0].tenancy_payment)

          // SubTenant Data
          let values: any = []
          for (let i = 0; i < res?.data?.data?.[0].sub_tenant?.length; i++) {
            values[i] = {
              id: res?.data?.data?.[0].sub_tenant?.[i]?._id,
              firstName: res?.data?.data?.[0].sub_tenant?.[i]?.firstName,
              lastName: res?.data?.data?.[0].sub_tenant?.[i]?.lastName,
              email: res?.data?.data?.[0].sub_tenant?.[i]?.email,
              countryCode: `${res?.data?.data?.[0].sub_tenant?.[i]?.countryCode}`,
              phoneNumber: `${res?.data?.data?.[0].sub_tenant?.[i]?.phoneNumber}`,
              passportNo: `${res?.data?.data?.[0].sub_tenant?.[i]?.passportNo}`,
              idNo: `${res?.data?.data?.[0].sub_tenant?.[i]?.idNo}`,
              _id: res?.data?.data?.[0].sub_tenant?.[i]?._id,
            }
          }
          setSubTenantData(values)

          // Start Date
          if (res?.data?.data[0]?.duration?.start_date != null) {
            setStart(moment(res?.data?.data?.[0]?.duration?.start_date).format('DD.MM.YYYY'))
            setStartDatePayload(
              moment(res?.data?.data?.[0]?.duration?.start_date).format('YYYY-MM-DD')
            )
          }

          // End Date
          if (res?.data?.data[0]?.duration?.end_date != null) {
            if (state?.terminationDate) {
              setEnd(moment(new Date(state?.terminationDate)).format('DD.MM.YYYY'))
              setEndDatePayload(moment(new Date(state?.terminationDate)).format('YYYY-MM-DD'))
            } else {
              setEnd(moment(res?.data?.data[0]?.duration?.end_date).format('DD.MM.YYYY'))
              setEndDatePayload(moment(res?.data?.data[0]?.duration?.end_date).format('YYYY-MM-DD'))
            }
          }

          setTotalDays(res?.data?.data[0]?.duration?.days?.toFixed(2) + ' Days')
          setRemainingDays(res?.data?.data[0]?.remainingDays?.toFixed(2) + ' Days')

          // Benefits
          let final_obj: any = {
            none: false,
            maintenanceFree: false,
            chillerFree: false,
            other: [],
            ...res?.data?.data[0]?.benefits,
          }
          setOptionArry(final_obj)

          let temp: any = []
          for (let i = 0; i < res?.data?.data[0]?.benefits?.other?.length; i++) {
            temp[i] = {
              value: res?.data?.data[0]?.benefits?.other?.[i],
            }
          }
          setOtherOptions(temp)

          getTenantDocuments(
            res?.data?.data?.[0]?.tenant?.[0]?._id,
            res?.data?.data?.[0]?.tenantDocument,
            res?.data?.data?.[0]?.contractDocument
          )

          getContractDocuments(
            res?.data?.data?.[0]?.tenant?.[0]?._id,
            res?.data?.data?.[0]?.tenantDocument,
            res?.data?.data?.[0]?.contractDocument
          )

          // getTenantById(
          //   res?.data?.data?.[0]?.tenant?.[0]?._id,
          //   res?.data?.data?.[0]?.tenantDocument,
          //   res?.data?.data?.[0]?.contractDocument
          // )

        })

        .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    getPaymentItemsList()
  }, [unitData])

  useEffect(() => {
    getUnitById(id)
    if (flg == '2' || flg == '3') {
      setSendError(false)
      getTenancyData(tenancyId)
      getAllPayments()
    }
    getTenantData()
    // getCountry()
    getApprovalDetails()
  }, [])

  useEffect(() => {
    reset(formData)
  }, [formData])

  const getImage: any = () => {
    if (unitData?.images?.length > 0 && unitData?.images[0].length > 0) {
      return <img src={`${Bucket}${unitData?.images[0]}`} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'town_house') {
      return <img src={townhouse} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'other') {
      return <img src={other} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'common_area') {
      return <img src={common_area} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'villa') {
      return <img src={villa} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'apartment') {
      return <img src={appartment} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'penthouse') {
      return <img src={penthouse} alt='' width={170} height={150} />
    }
  }

  const total: any = tenancyPayment?.reduce((acc: any, obj: any) => {
    return acc + obj.amount
  }, 0)

  const [from, setFrom] = useState<any>()
  const [subInd, setSubInd] = useState<any>()

  const [disabledBtn, setDisabledBtn] = useState<any>(true)
  const [incomeModal, setIncomeModal] = useState<any>(false)
  const [tenantDetails, setTenantDetails] = useState<any>()
  const [incomePaymentPayload, setIncomePaymentPayload] = useState<any>([])

  const [filteredPaymentItems, setFilteredPaymentItems] = useState<any>([
    {value: '0', label: 'Booking'},
    {value: '1', label: 'Maintenance'},
    {value: '2', label: 'Rent'},
  ])

  const [show, setShow] = useState<any>([])

  const getPaymentItemsList = () => {
    // console.log(unitData)
    if (unitData?.communityId !== undefined && unitData?.communityId !== null) {
      ApiGet(`corporate/payment_item_list/payment?communityId=` + unitData.communityId)
        .then((res) => {
          setFilteredPaymentItems(res?.data?.data)
        })
        .catch((err) => console.log('err', err))
    } else if (unitData?.buildingId !== undefined && unitData?.buildingId !== null) {
      ApiGet(`corporate/payment_item_list/payment?buildingId=` + unitData.buildingId)
        .then((res) => {
          setFilteredPaymentItems(res?.data?.data)
        })
        .catch((err) => console.log('err', err))
    }
  }

  const updateIncomePaymentPayload = (val: any, from: any, ind: any) => {
    const values = [...incomePaymentPayload]
    if (from == 'add') values[values.length] = val
    else values[ind] = val

    setIncomePaymentPayload(values)

    setIsFormDirty(true)

    for (let i = 0; i < values.length; i++) {
      let values = [...show]
      values[i] = false
      setShow(values)
    }
  }

  const tableRef = useRef<HTMLTableElement | null>(null)

  const autoSaveTenancy = () => {
    let benifit_optionArray: any = optionArry

    if (flg == '2' || flg == '3') {
      delete optionArry._id
      benifit_optionArray = optionArry
      optionArryPayload = optionArry
    }

    const body = {
      id: flg == '1' ? newTenancyID : tenancyId,
      contractNo: tenancyData?.contractNo,
      duration: {
        start_date: start_date,
        end_date: end_date,
        days: totalDays?.split(' ')[0],
        _id: newTenancyData?.duration?._id,
      },
      document: {
        contract:
          uploadContractTenancy?.[0] === null
            ? null
            : uploadContractTenancy?.length > 0
            ? removeBucket(uploadContractTenancy[0])
            : uploadContractTenancy?.length === 0
            ? null
            : null,
        other: uploadOtherImgTenancy?.length > 0 ? removeBucket(uploadOtherImgTenancy) : [],
        tenantPassport:
          uploadPassportTenant?.length > 0 ? removeBucket(uploadPassportTenant[0]) : null,
        tenantResidency:
          uploadresidencyTenant?.length > 0 ? removeBucket(uploadresidencyTenant[0]) : null,
        tenantId: uploadIdTenant?.length > 0 ? removeBucket(uploadIdTenant[0]) : null,
        tenantOther: uploadOtherImgTenant?.length > 0 ? removeBucket(uploadOtherImgTenant) : null,
        _id: newTenancyData?.document?._id,
      },
      benefits: optionArryPayload,
      isDraft: true,
      tenancyStatus: tenancyData?.tenancyStatus != null ? parseInt(tenancyData?.tenancyStatus) : 0,
      unitId: unitData?._id,
      communityId: unitData?.communityId,
      tenantId: flg == '3' ? tenancyData?.tenant?.[0]?._id : newTenantId,
      buildingId: unitData?.buildingId,
      tenantSource: formData?.tenantSource != null ? parseInt(formData?.tenantSource) : 0,
      subTenantIds: subTenantIds,
    }

    // if (flg == '3')
    //   ApiPut('corporate/tenancy?isCloneRecord=true', body)
    //     .then((res) => {})
    //     .catch((err) => ErrorToast(err.message))
    // else
    //   ApiPut('corporate/tenancy', body)
    //     .then((res) => {})
    //     .catch((err) => ErrorToast(err.message))
  }

  //
  const updateSubTenant = (subTen: any) => {
    setNewTenantID(subTen?.tenantId)
    const body = {
      id: subTen?._id,
      firstName: subTen?.firstName ? subTen?.firstName : null,
      lastName: subTen?.lastName ? subTen?.lastName : null,
      phoneNumber: subTen?.phoneNumber?.toString() ? subTen?.phoneNumber?.toString() : null,
      countryCode: subTen?.countryCode?.toString() ? subTen?.countryCode?.toString() : null,
      email: subTen?.email ? subTen?.email : null,
      tenantType: subTen?.tenantType,
      tenantId: subTen?.tenantId,
    }

    if (flg == '3')
      ApiPut('corporate/tenant?isCloneRecord=true', body)
        .then((res) => {
          setTimeout(() => {
            getSubTenants(subTen?.tenantId)
            setEditTenant(-1)
          }, 1000)
        })
        .catch((err) => ErrorToast(err.message))
    else
      ApiPut('corporate/tenant', body)
        .then((res) => {
          setTimeout(() => {
            getSubTenants(subTen?.tenantId)
            setEditTenant(-1)
          }, 1000)
        })
        .catch((err) => ErrorToast(err.message))
  }

  //
  const getSubTenants = (id: any) => {
    let body: any

    if (flg == '1') {
      let newId = localStorage.getItem('newTenancyID')
      body = {
        page: page,
        limit: 10,
        mainTenantId: '',
        tenancyId: newId,
      }
    } else {
      body = {
        page: page,
        limit: 10,
        mainTenantId: '',
        tenancyId: tenancyId,
      }
    }

    if (flg == '3')
      ApiPost('corporate/tenant/sub_tenant/get?isCloneRecord=true', body)
        .then((res) => {
          let values: any = []
          for (let i = 0; i < subTenantData?.length; i++) {
            values[i] = {
              id: res?.data?.data?.tenant_data?.[i]?._id,
              firstName: res?.data?.data?.tenant_data?.[i]?.firstName,
              lastName: res?.data?.data?.tenant_data?.[i]?.lastName,
              email: res?.data?.data?.tenant_data?.[i]?.email,
              mobileNo: res?.data?.data?.tenant_data?.[i]?.mobileNo,
              _id: res?.data?.data?.tenant_data?.[i]?._id,
            }
          }
          setSubTenantData(values)
        })
        .catch((err) => ErrorToast(err.message))
    else
      ApiPost('corporate/tenant/sub_tenant/get', body)
        .then((res) => {
          let values: any = []
          for (let i = 0; i < subTenantData?.length; i++) {
            values[i] = {
              id: res?.data?.data?.tenant_data?.[i]?._id,
              firstName: res?.data?.data?.tenant_data?.[i]?.firstName,
              lastName: res?.data?.data?.tenant_data?.[i]?.lastName,
              email: res?.data?.data?.tenant_data?.[i]?.email,
              mobileNo: res?.data?.data?.tenant_data?.[i]?.mobileNo,
              _id: res?.data?.data?.tenant_data?.[i]?._id,
            }
          }
          setSubTenantData(values)
        })
        .catch((err) => ErrorToast(err.message))
  }

  const cancelTenancy = () => {
    ApiGet(`corporate/tenancy/cancel/${tenancyId}`)
      .then((response: any) => {
        SuccessToast(response?.data?.message)
        navigate(-1)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  // useEffect(() => {
  //   if(flg != '1')
  //   getAllPayments()
  // }, [page])

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)

  useEffect(() => {
    if (isSaveFirst == true) {
      // if (flg == '2' || flg == '3') {
      setSendError(false)
      getTenancyData(tenancyId)
      // }
      getTenantData()
      // getSubTenants(tenancyId)
      getAllPayments()
      ApiGet(`corporate/unit/${unitData?._id}`)
        .then((res) => {
          setUnitData(res?.data?.data)
          setSendError(false)
          setDisabledBtn(false)
        })
        .catch((err) => console.log('err', err))
    }
  }, [isSaveFirst])

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (isFormDirty) {
        e.preventDefault()
        e.returnValue = 'You have unsaved changes. Are you sure you want to leave this page'
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isFormDirty])

  const [isEdit, setIsEdit] = useState<any>(false)
  var itemInd: any

  /* ---------------- Message ------------------*/
  const myDivRef = useRef(null)
  const searchRef = useRef(null)
  const bottomRef = useRef<any>(null)
  const [chatServiceJobId, setChatServiceJobId] = useState<any>()
  const [tenanttData, setTenanttData] = useState<any>()
  const [serviceJobId, setServiceJobId] = useState<any>()

  const [chatData, setChatData] = useState<any>([])
  const chatScreen = useSelector((state: any) => state.counterReducer.chatScreen)
  const chatSelectedDataList = useSelector(
    (state: any) => state.counterReducer.chatSelectedDataList
  )
  const dispatch = useDispatch()
  const [messages, setMessages] = useState<any>([])
  const [author, setAuthor] = useState<any>('')
  const [message, setMessage] = useState<any>('')
  const [activeChat, setactiveChat] = useState<any>(-1)
  const [showFilterCard, setShowFilterCard] = useState<any>(false)
  const [chatTab, setchatTab] = useState<any>('All')
  const [totalArr, settotalArr] = useState<any>([])
  const [room_id, setRoom_id] = useState<any>()
  const [modalopen, setmodalopen] = useState<any>(false)
  const [name, setName] = useState<any>('')
  const [bodyWithSubject, SetBodyWithSubject] = useState<any>({})
  const [finalIdData, setFinalIdData] = useState<any>({})
  const [defaultRoomData, setDefaultRoomData] = useState<any>(false)
  let user: any = localStorage.getItem('userData')
  user = JSON.parse(user)
  const [chatId, setChatId] = useState<any>('')
  const [msgPage, setMsgPage] = useState<any>(1)
  const [msgPageLimit, setMsgPageLimit] = useState<any>(false)

  const [showNotification, setShowNotification] = useState<any>(1)
  const [showChat, setShowChat] = useState<any>('message')
  const [showMessageCard, setShowMessageCard] = useState<any>(false)
  const [selectedDataListTenant, setSelectedDataListTenant] = useState<any>([])
  const [selectedDataListStaff, setSelectedDataListStaff] = useState<any>([])
  const [allDataPopup, setAllDataPopup] = useState<any>([])
  const [internalDataPopup, setInternalDataPopup] = useState<any>([])
  const [tenantDataPopup, setTenantDataPopup] = useState<any>([])
  const [bodyData, setBodyData] = useState<any>({})

  const [payloadData, setPayloadData] = useState<any>(false)
  const [notificationRed, setNotificationRed] = useState<any>(false)

  const [serviceJob, setServiceJob] = useState<any>('')

  const [noShow, setNoShow] = useState<any>(0)
  const [chatSubject, setChatSubject] = useState<any>()

  const [chatRoomId, setChatRoomId] = useState<any>(null)
  const [roomData, setRoomData] = useState<any>(null)

  // const [pageLimit, setPageLimit] = useState<any>(false)
  // const [page, setPage] = useState<any>(1)

  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n)
    let newVal: any = temp.toLocaleTimeString()
    // console.log(newVal);

    let h1: any = newVal.slice(0, 2) * 60
    let newValHours = parseInt(h1)
    //  console.log( newValHours)

    let newValMins: any = parseInt(newVal.slice(3, 5))
    //  console.log(newValMins)

    var num: number = newValHours + newValMins
    //  console.log(num)
    var hours = num / 60
    var rhours: any = Math.floor(hours)
    var minutes = (hours - rhours) * 60
    var rminutes: any = Math.round(minutes)
    var rh, rm

    if (rhours < 10) rh = '0' + rhours
    else rh = rhours

    if (rminutes < 10) rm = '0' + rminutes
    else rm = rminutes

    return rh + ':' + rm
  }

  useEffect(() => {
    if (bodyData?.staffIds?.length || bodyData?.tenantIds?.length) {
      settotalArr([...bodyData?.staffIds, ...bodyData?.tenantIds])
    }
  }, [bodyData])

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current?.scrollHeight
    }
  }, [messages])

  // useEffect(() => {
  //   socket.once('online', handleOnline)
  //   socket.emit('online', {userId: user._id}, (res: any) => {
  //     console.log('socket res', res)
  //   })
  //   return () => {
  //     socket.off('online', handleOnline)
  //     socket.close()
  //   }
  // }, [])
  // const handleOnline = (data: any) => {
  //   console.log('socket Online event received:', data)
  //   // Update state to reflect online status
  // }
  useEffect(() => {
    socket.on('receive_message', (newMessage: any) => {
      console.log('socket newMessage', newMessage)
      console.log('messages', messages)
      setMessages([
        ...messages,
        {author: newMessage?.author || '', message: newMessage?.message, createdAt: new Date()},
      ])
    })
    socket.on('new_user', (new_user) => {
      console.log('socket new_user', new_user)
    })
  }, [])

  useEffect(() => {
    socket.off('receive_message')
    socket.on('receive_message', (message: any) => {
      setMessages((prevMessages: any) => [...prevMessages, message])
    })
  }, [])
  const handleJoinRoom = (room: any) => {
    socket.emit('join_room', room)
  }
  const handleSendMessage = (message: any) => {
    socket.off('send_message')
    socket.emit('send_message', message)
  }
  useEffect(() => {
    socket.off('online')
    socket.emit('online', {userId: user._id}, (res: any) => {
      console.log('socket res', res)
    })
  }, [])
  // useEffect(() => {
  //   console.log('messages', messages)
  //   socket.on('receive_message', (newMessage: any) => {
  //     console.log('socket newMessage', newMessage)
  //     console.log('socket messages', messages)
  //     setMessages([
  //       ...messages,
  //       {author: newMessage?.author, message: newMessage?.message, createdAt: new Date()},
  //     ])
  //   })
  //   socket.on('new_user', (new_user) => {
  //     console.log('socket new_user', new_user)
  //   })
  //   // Send a message to the server
  //   // Receive messages from the server
  //   socket.emit('online', {userId: user._id}, (res: any) => {
  //     console.log('socket res', res)
  //   })
  //   socket.on('online', (data) => {
  //     console.log('socket data', data)
  //     // io.emit('post', {userId: user._id})
  //   })
  // }, [])

  const openMessage1 = async (e: any, el: any, chat: any) => {
    console.log('chat', chat)
    setRoom_id(chat)

    await ApiGet(`corporate/chat_room/${chat._id}`)
      .then((res) => {
        setDefaultRoomData(res.data.data)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })

    await ApiPost(`corporate/chat_message/get`, {
      limit: 10,
      page: 1,
      chatRoomId: chat._id,
    })
      .then((res) => {
        console.log('\nres.data.data', res.data.data)
        setMessages(res?.data?.data?.chat_message_data)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })

    handleJoinRoom({
      roomId: chat._id,
      userId: user._id,
    })
    setShowChat('chat')
  }

  // const [chatId, setChatId] = useState<any>('')
  // const [msgPage, setMsgPage] = useState<any>(1)
  // const [msgPageLimit, setMsgPageLimit] = useState<any>(false)

  const openMessage = async (e: any, el: any, chat: any) => {
    setChatId(chat._id)
    setRoom_id(chat)
    setMessages([])
    setShowChat('chat')

    // await ApiGet(`corporate/chat_room/${chat._id}`)
    //   .then((res) => {
    setDefaultRoomData(roomData)
    fetchMessage(chat._id, 1, [])
    // })
    // .catch((err) => {
    //   console.log('res_blob', err)
    // })
    handleJoinRoom({
      roomId: chat._id,
      userId: user._id,
    })
  }

  const fetchMessage = async (chatId: any, searchPage: any, messages: any) => {
    await ApiPost(`corporate/chat_message/get`, {
      limit: 10,
      page: searchPage,
      chatRoomId: chatId,
    })
      .then((res) => {
        setMessages(res?.data?.data?.chat_message_data)
        setMsgPage(msgPage + 1)
        setMsgPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }

  const closeMessageCard = () => {
    setShowMessageCard(false)
    setShowChat(false)
    setShowChat('message')
    settotalArr([])
  }
  const viewall = () => {
    setShowMessageCard(false)
    setShowChat(false)
    setShowChat('message')
    navigate(`chat`)
  }

  const popupEvent = async (flg: any) => {
    setShowMessageCard(false)
    setShowNotification(flg)
    if (flg == 1) {
      setNotificationRed(false)
    }
  }
  const notificationEvent = async () => {
    await onMessageListener()
      .then((payload: any) => {
        setPayloadData(payload)
        setNotificationRed(true)
        console.log('\npayload notification', payload)
      })
      .catch((err) => console.log('failed: ', err))
  }
  notificationEvent()

  // const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault()
  //   await ApiPost(`corporate/chat_message/get`, {
  //   limit: 10,
  //   page: 1,
  //   chatRoomId: chatRoomId,
  // })
  //   .then((res) => {
  //     setMessages(res?.data?.data?.chat_message_data)
  //     setMsgPage(msgPage + 1)
  //     setMsgPageLimit(res?.data?.data?.state?.page_limit)
  //   })
  //   .catch((err) => {
  //     console.log('res_blob', err)
  //   })
  // }

  const handleSubmitt = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    let idsToSend: any = []
    for (let i = 0; i < tenanttData?.length; i++) {
      idsToSend[i] = tenanttData[i]._id
    }

    // const body = {
    //   subjectTitle: bodyWithSubject.subjectTitle,
    //   type: 0,
    //   serviceJobId: chatServiceJobId,
    //   tenantIds: idsToSend,
    // }

    // console.log('\nbodyWithSubject', bodyWithSubject)
    if (defaultRoomData) {
      handleSendMessage({
        roomId: defaultRoomData?._id,
        message: message,
        senderId: user._id,
        receiverId: tenanttData._id,
        senderType: 0,
        receiverType: 1,
      })
      // setMessages([...messages, {author, message, createdAt: new Date()}])
      setAuthor('')
      setMessage('')
    } else {
      // await ApiPost(`corporate/chat_room`, body)
      //   .then((res) => {
      //     console.log('\nres.data.data', res.data.data)
      handleSendMessage({
        roomId: chatRoomId,
        message: message,
        senderId: user._id,
        receiverId: tenanttData._id,
        senderType: 0,
        receiverType: 1,
      })
      openMessage(e, 0, {_id: chatRoomId})
      // setMessages([...messages, {author, message, createdAt: new Date()}])
      setAuthor('')
      setMessage('')
      // })
      // .catch((err) => {
      //   console.log('res_blob', err)
      // })
    }
  }

  useEffect(() => {
    getChatList()
  }, [])

  const dateTotime = (dd: any) => {
    // let m = new Date(dd).toLocaleString()
    // let x = moment(m.format("DD-MM-YYYY HH:mm"))
    // return x;
    return new Date(dd).toLocaleString('ro-RO', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  const getChatList = async () => {
    // await ApiPost(`corporate/chat_room/get`, {
    //   limit: 10,
    //   page: page,
    // })
    //   .then((res) => {
    //     setChatData([...chatData, ...res?.data?.data?.chat_room_data])
    //     setPage(page + 1)
    //     setPageLimit(res?.data?.data?.state?.page_limit)
    //   })
    //   .catch((err) => {
    //     console.log('res_blob', err)
    //   })
  }

  const [searchPage, setSearchPage] = useState<any>(1)
  const [searchPageLimit, setSearchPageLimit] = useState<any>(false)
  const searchFromAll = async (name: any, tab: any, searchPage: any) => {
    setName(name)
    let tab_data: any = {
      isAll: true,
      isInternal: false,
      isTenant: false,
    }
    if (tab == 'All') {
      tab_data = {
        isAll: true,
        isInternal: false,
        isTenant: false,
      }
    } else if (tab == 'Internal') {
      tab_data = {
        isAll: false,
        isInternal: true,
        isTenant: false,
      }
    } else if (tab == 'Tenants') {
      tab_data = {
        isAll: false,
        isInternal: false,
        isTenant: true,
      }
    }
    let body = {
      limit: 10,
      page: searchPage,
      searchName: name,
      ...tab_data,
    }
    await ApiPost(`corporate/chat_room/get_user`, body)
      .then((res) => {
        // setSearchPage(searchPage + 1)
        setSearchPageLimit(res?.data?.data?.state?.page_limit)
        if (chatTab == 'All') {
          setAllDataPopup(res?.data?.data?.user_data || [])
        } else if (chatTab == 'Internal') {
          setInternalDataPopup(res?.data?.data?.user_data || [])
        } else if (chatTab == 'Tenants') {
          setTenantDataPopup(res?.data?.data?.user_data || [])
        }
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }
  useEffect(() => {
    searchFromAll('', 'All', searchPage)
  }, [])

  const onChangeTab = (tab: any) => {
    setchatTab(tab)
    setSearchPage(1)
    searchFromAll('', tab, 1)
  }

  const showMessagePopUp = () => {
    setShowMessageCard(!showMessageCard)
    getChatList()
  }

  const userScroll = (e: any) => {
    if (myDivRef.current) {
      const divElement: any = myDivRef.current // Access the div element using the ref
      if (divElement.scrollTop === divElement.scrollHeight - divElement.clientHeight) {
        if (page <= pageLimit) {
          getChatList()
        }
      }
    }

    if (bottomRef.current) {
      const divElement: any = bottomRef.current // Access the div element using the ref
      if (divElement.scrollTop === 0) {
        if (msgPage <= msgPageLimit) {
          fetchMessage(chatId, msgPage, messages)
        }
      }
    }

    if (searchRef.current) {
      const serarchElement: any = searchRef.current // Access the div element using the ref
      if (serarchElement.scrollTop === serarchElement.scrollHeight - serarchElement.clientHeight) {
        if (searchPage < searchPageLimit) {
          setSearchPage(searchPage + 1)
          searchFromAll(name, chatTab, searchPage + 1)
        }
      }
    }
  }

  const makeGroup = (e: any, i: any) => {
    let id: any = allDataPopup[i]
    if (allDataPopup[i].role == 'Tenant') {
      if (e.target.checked) {
        if (selectedDataListTenant.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListTenant((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListTenant(selectedDataListTenant.filter((e: any) => e !== id))
      }
    } else {
      if (e.target.checked) {
        if (selectedDataListStaff.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListStaff((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListStaff(selectedDataListStaff.filter((e: any) => e !== id))
      }
    }
  }

  const goToNewMessage = (from: any) => {
    setShowChat('newMessage')
    let body: any = {}
    if (from == 'all') {
      body = {
        tenantIds: selectedDataListTenant,
        staffIds: selectedDataListStaff,
      }
      if (selectedDataListStaff.length + selectedDataListTenant.length == 1) {
        body['type'] = 0
      } else if (selectedDataListStaff.length + selectedDataListTenant.length > 1) {
        body['type'] = 1
      }
    } else if (from == 'internal') {
      body = {
        tenantIds: selectedDataListTenant,
        staffIds: selectedDataListStaff,
      }
      if (selectedDataListStaff.length + selectedDataListTenant.length == 1) {
        body['type'] = 0
      } else if (selectedDataListStaff.length + selectedDataListTenant.length > 1) {
        body['type'] = 1
      }
    }
    setBodyData(body)
  }

  const changeSubject = (e: any) => {
    let temp = {
      ...bodyData,
      subjectTitle: e.target.value,
    }
    SetBodyWithSubject(temp)
  }

  const gotoUserList = () => {
    getChatList()
    setShowChat('message')
  }

  const gotoChatList = async () => {
    const body = {
      limit: 10,
      page: 1,
      serviceJobId: serviceJob,
    }

    await ApiPost(`corporate/chat_room/service_job`, body)
      .then((res) => {
        setTenanttData(res.data.data.tenant_data)
        setChatData(res?.data?.data?.chat_room_data)
        setShowChat('message')
      })
      .catch()
  }

  // function called on clicking Message tenant Button
  const getChatRoomForTenant = async (val: any) => {
    let tenant: any = []
    tenant[0] = val
    setServiceJob(val)
    await ApiGet(`corporate/chat_room/personal_chat?tenantId=${val?._id}`)
      .then((res) => {
        SetBodyWithSubject({subjectTitle: res?.data?.data?.subjectTitle})
        showMessagePopUp()

        setTimeout(() => {
          fetchMessage(res?.data?.data?._id, 1, [])
        }, 300)

        // if (res.data.data.isNewChat === true)
        setShowChat('newMessage')

        setTenanttData(tenant)

        setChatRoomId(res.data.data._id)
        setRoomData(res?.data?.data)

        setDefaultRoomData(res?.data?.data)

        handleJoinRoom({
          roomId: res?.data?.data?._id,
          userId: user._id,
        })

        // setChatData(res?.data?.data?.chat_room_data)
      })
      .catch()
  }

  //
  const generateTicketForRenew = () => {
    const body = {
      unitId: unitData?._id,
      previousTenancyId: tenancyData?._id,
      type: 2,
    }
    ApiPost(`corporate/tenant_request`, body)
      .then((res) => {
        navigate(
          `/lease-request/${res?.data?.data?._id}/${tenancyData?.unitId}/${tenancyData?._id}/2`
        )

        // navigate(`/tenants`, {
        //   state: {
        //     requestId: res?.data?.data?._id,
        //   },
        // })

         // navigate(`/view-request/${res?.data?.data?._id}/2`)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  //
  const generateTicketForTerminate = () => {
    const body = {
      unitId: unitData?._id,
      tenancyId: tenancyData?._id,
      type: 3,
    }
    ApiPost(`corporate/tenant_request`, body)
      .then((res) => {

        navigate(
          `/lease-request/${res?.data?.data?._id}/${tenancyData?.unitId}/${tenancyData?._id}/3`
        )

        // navigate(`/view-request/${res?.data?.data?._id}/2`)
        // navigate(`/tenants`, {
        //   state: {
        //     requestId: res?.data?.data?._id,
        //   },
        // })
      })
      .catch((err: any) => {
        ErrorToast(err?.data?.message)
      })
  }

  /************************ */
  const handleBeforeUnload = (event: BeforeUnloadEvent) => {
    const message = 'Are you sure you want to leave?'

    event.returnValue = message
  }

  // const handlePopstate = (event: PopStateEvent) => {
  //   if(isFormDirty){
  //   const userChoice = window.confirm(
  //     'You are about to leave this page. Do you wish to save your updates?'
  //   )

  //   if (userChoice) {
  //     console.log('User chose to save updates')
  //     onSubmitNewFlow(formData, 'exit', true)
  //     window.removeEventListener('popstate', handlePopstate)
  //   } else {
  //     console.log('User chose not to save updates')
  //     window.removeEventListener('popstate', handlePopstate)

  //   }
  //   setIsFormDirty(false)
  // }
  // }

  // if (isFormDirty ) {
  // window.addEventListener('popstate', handlePopstate)
  // }
  // else{
  //   // return () => {
  //     window.removeEventListener('popstate', handlePopstate)
  //   // }
  // }

  // useEffect(() => {
  //   if (isFormDirty && isEdit) {
  //     // window.addEventListener('popstate', handlePopstate)
  //     window.addEventListener('beforeunload', handleBeforeUnload)

  //     return () => {
  //       // window.removeEventListener('popstate', handlePopstate)
  //       window.removeEventListener('beforeunload', handleBeforeUnload)
  //     }
  //   }
  // }, [isFormDirty])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isFormDirty) {
        const message = 'You have unsaved changes. Do you really want to leave?'
        event.returnValue = message // Standard for most browsers
        return message // For some older browsers
      }
    }

    const handlePopstate = (event: PopStateEvent) => {
      if (isFormDirty) {
        const userChoice = window.confirm(
          'You are about to leave this page. Do you wish to save your updates?'
        )

        if (userChoice) {
          console.log('User chose to save updates')
          onSubmitNewFlow(formData, 'exit', true)
        } else {
          console.log('User chose not to save updates')
        }

        setIsFormDirty(false)
      }
    }

    const handleHashChange = () => {
      if (isFormDirty) {
        const userChoice = window.confirm(
          'You are about to leave this page. Do you wish to save your updates?'
        )

        if (userChoice) {
          console.log('User chose to save updates')
          onSubmitNewFlow(formData, 'exit', true)
        } else {
          console.log('User chose not to save updates')
        }

        setIsFormDirty(false)
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)
    window.addEventListener('popstate', handlePopstate)
    window.addEventListener('hashchange', handleHashChange)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      window.removeEventListener('popstate', handlePopstate)
      window.removeEventListener('hashchange', handleHashChange)
    }
  }, [isFormDirty])

  const [rentFin, setRentFin] = useState<any>({
    name: 'Rent',
    totalAmount: '',
    noOfPayment: '',
    paymentItemListId: '',
  })

  const [securityFin, setSecurityFin] = useState<any>({
    name: 'Security Deposit',
    totalAmount: '',
    noOfPayment: '',
    paymentItemListId: '',
  })

  const [otherFinancials, setOtherFinancials] = useState<any>([
    // {
    //   name: '',
    //   amount: 0,
    //   noOfPayments: 0
    // }
  ])

  const [isDisable, setIsDisable] = useState<any>(false)

  const handleDownload = async (imageUrl: any) => {
    setIsDisable(true)
    try {
      const response = await axios.get(imageUrl, {
        responseType: 'blob',
      })

      const blob = new Blob([response.data], {type: response.headers['content-type']})

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'downloaded_image.png'

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      setIsDisable(false)
    } catch (error) {
      setIsDisable(false)
      ErrorToast('Error downloading image')
    }
  }

  const [approvalUsers, setApprovalUsers] = useState<any>([])
  const [approvalWorkflowCard, setApprovalWorkflowCard] = useState<any>(false)

  const getApprovalDetails = () => {
    const body = {
      tenancyId: tenancyId,
    }
    ApiPost(`corporate/approval_workflow/tenancy/get`, body)
      .then((res: any) => {
        setApprovalUsers(res?.data?.data)
      })
      .catch((err: any) => {})
  }


  const handleImageClick = (val: any, valSrc: any, type: any,docs:any,docType:any) => {
    const groupName = `group_${type}`

    if(docType === 'TenantDocuments' || docType ===  'ContactDocs' || docType === 'ContactDeletedDocs' || docType === 'TenantDeletedDocs'){
      docs?.map((doc: any, index: any) => {
        const imgeNext = document.getElementById(`clicked${type}${index}`)
        if (imgeNext) {
          imgeNext.setAttribute('data-fancybox', groupName)
          imgeNext.setAttribute('data-src', `${Bucket}${doc?.fileURL}`)
          // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
        }
      })

    }
   

    
  }

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })

    // return () => {
    //   fancybox.destroy();
    // };
  }, []);


  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form onSubmit={handleSubmit((data: any, e: any) => onSubmitNewFlow(formData, e, false))}>
          <div id='' className=' pt-0 mt-0 '>
            {' '}
            {/*    container-xxl */}
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              {isEdit && isFormDirty && (
                <>
                  <div className='d-flex ms-auto mask-toolbar'></div>
                </>
              )}
              <div
                className=' d-flex flex-stack pt-1 mt-0 px-0'
                style={
                  isEdit && isFormDirty
                    ? {transform: 'translate(0px, -50px)'}
                    : {transform: 'translate(0px, 0px)'}
                }
              >
                {' '}
                {/*    container-xxl */}
                <div className=''>
                  <div className='d-flex align-items-center mb-1'>
                    <span
                      className='rounded-circle'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        if (isFormDirty) {
                          swal
                            .fire({
                              text: 'You are about to leave this page. Do you wish to save your updates?',
                              icon: 'warning',
                              showConfirmButton: true,
                              confirmButtonColor: '#146c6a',
                              confirmButtonText: 'Yes',
                              showCancelButton: false,
                              cancelButtonText: 'Cancel',
                              showDenyButton: true,
                              denyButtonText: 'No',
                              denyButtonColor: '#d546452',
                            })
                            .then((res: any) => {
                              if (res.isConfirmed) {
                                onSubmitNewFlow(formData, 'exit', true)
                              } else if (res.isDenied) {
                                let temp = localStorage.getItem('tenancyFlow')
                                if (temp != null) {
                                  if (temp == 'tenancies') navigate('/tenancies')
                                  else navigate(-1)
                                }
                              } else {
                              }
                            })
                        } else {
                          let temp = localStorage.getItem('tenancyFlow')
                          if (temp != null) {
                            if (temp == 'tenancies') navigate('/tenancies')
                            else navigate(-1)
                          }
                        }
                      }}
                    >
                      <img src={backArrow} height='16' width='16' />
                    </span>
                    <h1 className='m-0 head-text ms-2'>{isEdit ? 'Edit Lease' : 'Lease'}</h1>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  {!isEdit ? (
                    <>
                      {approvalUsers?.length > 0 && (
                        <button
                          type='button'
                          className='btn btn-sm fw-bold  ms-3 blue-btn'
                          style={{width: 'fit-content'}}
                          onClick={() => setApprovalWorkflowCard(true)}
                        >
                          <img src={tick} height={18} width={18} style={{marginRight: '7px'}} />{' '}
                          Approval Workflow
                        </button>
                      )}

                      {((user?.isSubUser && role?.leasing_residential?.lease_agreements?.update) ||
                        !user?.isSubUser) && (
                        <img
                          src={pencil}
                          alt=''
                          height={19}
                          width={19}
                          className='ms-4 me-1 cursor-pointer'
                          onClick={() => {
                            setIsEdit(true)
                          }}
                        />
                      )}
                       {((user?.isSubUser && role?.leasing_residential?.cancellation?.allow) ||
                        !user?.isSubUser) && (
                      <>
                      {tenancyData?.tenancyStatus == 0 && tenancyData?.isCancel == false && (
                        <button
                          type='button'
                          className='btn btn-sm fw-bold  ms-6 red-hollow-btn term-width'
                          onClick={() => cancelTenancy()}
                        >
                          <img src={redCross} height={18} width={18} style={{marginRight: '7px'}} />{' '}
                          Cancel Lease
                        </button>
                      )}
                      </>
                        )}
                    </>
                  ) : (
                    <>
                      <button
                        type='button'
                        className='btn btn-sm fw-bold  mx-3 red-hollow-btn'
                        onClick={() => {
                          getTenancyData(tenancyId)
                          localStorage.removeItem('isFormDirty')
                          setIsEdit(false)
                          setIsFormDirty(false)
                        }}
                        disabled={isLoading}
                      >
                        <img src={redCross} height={20} width={20} style={{marginRight: '7px'}} />{' '}
                        Cancel
                      </button>

                      <button
                        type='button'
                        className='btn btn-sm fw-bold mx-3 green-hollow-btn'
                        onClick={(e) => {
                          onSubmitNewFlow(formData, e, true)
                        }}
                        disabled={isLoading}
                      >
                        <img
                          src={saveGreenImg}
                          height={20}
                          width={20}
                          style={{marginRight: '13px'}}
                        />{' '}
                        {'  '}Save
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className='card card-flush py-5 mt-5 px-1'
              style={
                isEdit && isFormDirty
                  ? {transform: 'translate(0px, -50px)'}
                  : {transform: 'translate(0px, 0px)'}
              }
            >
              {' '}
              {/*    container-xxl */}
              <div className='row mx-1'>
                <div className='col-12 pe-5'>
                  <div className='row'>
                  {((user?.isSubUser && role?.leasing_residential?.lease_agreements?.contract_detail?.view_details) ||
                        !user?.isSubUser) && (
                    <div className='col-7'>
                      <div className='d-flex row align-items-center'>
                        <div className='col-7 px-5'>
                          <h2 className='top ps-0'>
                            <b>Contract Details</b>
                          </h2>
                        </div>
                        <div className='col-4 ms-auto'>
                          <div className='d-flex'>
                            <div className='' style={{width: '180px'}}>
                              <div
                                className={
                                  tenancyData?.tenancyStatus == 0
                                    ? 'status-view booked-bar'
                                    : tenancyData?.tenancyStatus == 1
                                    ? 'status-view active-bar '
                                    : tenancyData?.tenancyStatus == 2
                                    ? 'status-view expiring-bar '
                                    : tenancyData?.tenancyStatus == 3
                                    ? 'status-view expired-bar '
                                    : 'status-view  no-st-bar '
                                }
                              >
                                {tenancyData?.tenancyStatus == 0
                                  ? 'Booked'
                                  : tenancyData?.tenancyStatus == 1
                                  ? 'Active'
                                  : tenancyData?.tenancyStatus == 2
                                  ? 'Expiring'
                                  : tenancyData?.tenancyStatus == 3
                                  ? 'Expired'
                                  : '-'}
                              </div>
                              <div>
                                <i>
                                  {' '}
                                  {tenancyData?.tenancyStatusDateTime
                                    ? `${moment(tenancyData?.tenancyStatusDateTime).format(
                                        'DD.MM.YYYY - HH:mm A'
                                      )} `
                                    : ''}
                                </i>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className='col-7 px-5 mt-4'>
                          <div className='d-flex align-items-center'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{minWidth: '90px'}} className='labl-gry'>
                                {' '}
                                Contract No{' '}
                              </label>
                            </h4>
                            {isEdit ? (
                              <>
                                <input
                                  type='number'
                                  {...register('contractNo', {required: true})}
                                  className='form-control form-control-solid mytest tenancy-control ms-5 ps-1 my-2'
                                  placeholder=''
                                  name='contractNo'
                                  value={tenancyData?.contractNo}
                                  onChange={handleChangeContractNo}
                                  disabled={sendError || (user?.isSubUser &&
                                    !role?.leasing_residential?.lease_agreements?.contract_detail?.update)}
                                  style={{height: '35px', width: '50%'}}
                                />
                                {errors.contractNo && (
                                  <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                                )}
                              </>
                            ) : (
                              <span className='ms-5 ps-1 my-2'>
                                <b>{tenancyData?.contractNo ? tenancyData?.contractNo : '-'}</b>
                              </span>
                            )}
                          </div>
                        </div>
                        <div className='col-4 ms-auto '>
                          <div className='d-flex  mt-5 '>
                            <div className='' style={{width: '180px'}}>
                              {tenancyData?.isCancel ? (
                                <div className='status-view cancelled-st me-5'>Cancelled</div>
                              ) : (
                                <div
                                  className={
                                    tenancyData?.isDraft
                                      ? 'status-view draft-st '
                                      : 'status-view create-st'
                                  }
                                >
                                  {tenancyData?.isDraft ? 'Draft' : 'Created'}
                                </div>
                              )}

                              <i>
                                {' '}
                                {tenancyData?.draftStatusDateTime
                                  ? `${moment(tenancyData?.draftStatusDateTime).format(
                                      'DD.MM.YYYY - HH:mm A'
                                    )} `
                                  : ''}
                              </i>
                            </div>
                          </div>
                        </div>

                        <div className='col-7 px-5 mt-2 mb-1'>
                          <div className='d-flex align-items-center'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{minWidth: '90px'}} className='labl-gry'>
                                {' '}
                                Term{' '}
                              </label>
                            </h4>
                            {isEdit ? (
                              <>
                                {start_date != null || end_date != null ? (
                                  // <Controller
                                  //   control={control}
                                  //   name='start'
                                  //   render={({
                                  //     field: {onChange, onBlur, value, name, ref},
                                  //     fieldState: {invalid, isTouched, isDirty, error},
                                  //     formState,
                                  //   }) => (
                                  <RangePicker
                                    defaultValue={[
                                      moment(start_date ? start_date : new Date(), dateFormat),
                                      moment(end_date ? end_date : new Date(), dateFormat),
                                    ]}
                                    // {...register('start', {required: true})}
                                    format={dateFormat}
                                    onChange={(e: any) => handleChnageTenancyDate(e)}
                                    className='form-control form-control-solid mytest tenancy-control ms-5 ps-1 my-2'
                                    disabled
                                    // style={{width: '50%'}}
                                  />
                                ) : (
                                  // )}
                                  // />
                                  // <Controller
                                  //   control={control}
                                  //   name='start'
                                  //   render={({
                                  //     field: {onChange, onBlur, value, name, ref},
                                  //     fieldState: {invalid, isTouched, isDirty, error},
                                  //     formState,
                                  //   }) => (
                                  <RangePicker
                                    {...register('start', {required: true})}
                                    format={dateFormat}
                                    onChange={(e: any) => handleChnageTenancyDate(e)}
                                    className='form-control form-control-solid tenancy-control ms-5 ps-1 my-2'
                                    disabled
                                    // style={{width: '50%'}}
                                  />
                                  // )}
                                  // />
                                )}
                                {/* {errors.start && (
                                  <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                                )} */}
                              </>
                            ) : (
                              <>
                                <span className='ms-5 ps-1 my-2'>
                                  <b>
                                    {start_date != null || end_date != null
                                      ? `${start_date} - ${end_date} `
                                      : '-'}
                                  </b>
                                </span>
                              </>
                            )}
                          </div>
                        </div>

                        <div className='col-7 px-5 mt-3 mb-1'>
                          <div className='d-flex'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{minWidth: '90px'}} className='labl-gry'>
                                {' '}
                                Duration
                              </label>
                            </h4>
                            <span className='ms-5 ps-1 my-2 '>
                              <b>{totalDays}</b>
                            </span>
                          </div>
                        </div>

                        <div className='col-7 px-5 mt-3 mb-1'>
                          <div className='d-flex'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{minWidth: '90px'}} className='labl-gry'>
                                {' '}
                                Remaining
                              </label>{' '}
                            </h4>
                            <span className='ms-5 ps-1 my-2'>
                              <b>{remainingDays}</b>
                            </span>
                          </div>
                        </div>

                        <div className='col-8 px-5 mt-3 mb-1'>
                          <div className='d-flex align-items-center'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{minWidth: '90px'}} className='labl-gry'>
                                {' '}
                                Renewal{' '}
                              </label>
                            </h4>
                            {isEdit ? (
                              <>
                                <input
                                  type='text'
                                  className='form-control form-control-solid mytest tenancy-control ms-5 ps-1 my-2'
                                  placeholder=''
                                  name='renewalDays'
                                  value={tenancyData?.renewalDays}
                                  onChange={(e: any) => {
                                    const {name, value} = e.target
                                    setTenancyData({...tenancyData, [name]: value})
                                    setIsFormDirty(true)

                                    localStorage.setItem('isFormDirty', 'true')
                                  }}
                                  style={{height: '35px', width: '20%'}}
                                  disabled
                                />{' '}
                                <span>
                                  {' '}
                                  <i className='mt-2 ms-3'>Days before term end</i>
                                </span>
                              </>
                            ) : (
                              <span className='ms-5 ps-1 my-2'>
                                <b>{tenancyData?.renewalDays ? tenancyData?.renewalDays : '-'}</b>{' '}
                                <i className='mt-2 ms-3'>Days before term end</i>
                              </span>
                            )}
                          </div>
                        </div>

                        <div className='col-4'>
                          <div className='d-flex mt-5'>
                            <div className='' style={{width: '180px'}}>
                              <div
                                className={
                                  tenancyData?.renewalStatus == 0
                                    ? 'status-view create-st'
                                    : tenancyData?.renewalStatus == 1
                                    ? 'status-view create-st'
                                    : tenancyData?.renewalStatus == 2
                                    ? 'status-view renewed-st'
                                    : tenancyData?.renewalStatus == 7
                                    ? 'status-view cancelled-st '
                                    : 'status-view not-saved '
                                }
                              >
                                {tenancyData?.renewalStatus == 0
                                  ? 'Not Renewed'
                                  : tenancyData?.renewalStatus == 1
                                  ? 'Under Renewal'
                                  : tenancyData?.renewalStatus == 2
                                  ? 'Renewed'
                                  : tenancyData?.renewalStatus == 7
                                  ? 'Terminated'
                                  : '-'}
                              </div>
                              <i>
                                {' '}
                                {tenancyData?.renewalStatusDateTime
                                  ? `${moment(tenancyData?.renewalStatusDateTime).format(
                                      'DD.MM.YYYY - HH:mm A'
                                    )} `
                                  : ''}
                              </i>
                            </div>
                          </div>
                        </div>

                        {isEdit ? (
                          <div className='col-12 ps-5 mt-3 mb-2'>
                            <div className='d-flex'>
                              <div className='d-flex '>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label
                                    style={{minWidth: '90px'}}
                                    className='labl-gry'
                                    htmlFor='exampleFormControlInput1'
                                  >
                                    Benefits
                                  </label>
                                </h4>
                                <div className='test ms-5 ps-1 my-2'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      id='vacant0'
                                      name='none'
                                      // defaultChecked
                                      checked={optionArry?.none === true ? true : false}
                                      onChange={handleChnageOption}
                                      disabled={sendError || (user?.isSubUser &&
                                        !role?.leasing_residential?.lease_agreements?.contract_detail?.update)}
                                    />
                                    <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                                      {' '}
                                      None
                                    </p>
                                  </div>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      id='occupied1'
                                      name='chillerFree'
                                      checked={optionArry?.chillerFree === true ? true : false}
                                      disabled={
                                        (optionArry?.none === true || sendError == true || (user?.isSubUser &&
                                          !role?.leasing_residential?.lease_agreements?.contract_detail?.update))
                                          ? true
                                          : false
                                      }
                                      onChange={handleChnageOption}
                                    />
                                    <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                                      {' '}
                                      Chiller Free
                                    </p>
                                  </div>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      id='occupied2'
                                      name='maintenanceFree'
                                      checked={optionArry?.maintenanceFree === true ? true : false}
                                      disabled={
                                        (optionArry?.none === true || sendError == true || (user?.isSubUser &&
                                          !role?.leasing_residential?.lease_agreements?.contract_detail?.update))
                                          ? true
                                          : false
                                      }
                                      onChange={handleChnageOption}
                                    />
                                    <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                                      {' '}
                                      Maintenance Free
                                    </p>
                                  </div>
                                  {otherOptions?.length > 0 &&
                                    otherOptions?.map((opt: any, ind: any) => (
                                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          id='occupied2'
                                          name='maintenanceFree'
                                          checked={optionArry?.none == true ? false : true}
                                        />
                                        <input
                                          className='form-control form-control-solid mytest w-75 ms-3 tenancy-control'
                                          placeholder='Type benefit'
                                          type='text'
                                          value={opt.value}
                                          onChange={(e: any) => {
                                            const values = [...otherOptions]
                                            values[ind] = {
                                              value: e.target.value,
                                            }
                                            setOtherOptions(values)

                                            setIsFormDirty(true)

                                            localStorage.setItem('isFormDirty', 'true')
                                          }}
                                          style={{height: '35px'}}
                                          disabled={sendError || (user?.isSubUser &&
                                            !role?.leasing_residential?.lease_agreements?.contract_detail?.update)}
                                        />

                                        <p
                                          className='head-text ms-3 mb-0'
                                          style={{fontWeight: '600'}}
                                        >
                                          <img
                                            src={redCross}
                                            width='16px'
                                            height='16px'
                                            className='main_img cursor-pointer ms-auto'
                                            onClick={() => {
                                              if((user?.isSubUser &&
                                                role?.leasing_residential?.lease_agreements?.contract_detail?.update))
                                              handleRemoveOther(ind)}
                                            }
                                          />
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              </div>

                              <div className='ms-auto'>
                                <button
                                  type='button'
                                  className='btn btn-sm fw-bold blue-btn'
                                  onClick={() => {
                                    if (optionArry?.none === true || sendError == true) {
                                    } else handleAddOther()
                                  }}
                                  disabled={
                                    (optionArry?.none == true || sendError == true || (user?.isSubUser &&
                                      !role?.leasing_residential?.lease_agreements?.contract_detail?.update)) ? true : false
                                  }
                                >
                                  <img
                                    src={addWhite}
                                    height={16}
                                    width={16}
                                    style={{marginRight: '10px'}}
                                  />{' '}
                                  Other
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className='col-7 ps-5 mt-3 mb-2'>
                              <div className='d-flex '>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label
                                    style={{minWidth: '90px'}}
                                    className='labl-gry '
                                    htmlFor='exampleFormControlInput1'
                                  >
                                    Benefits
                                  </label>
                                </h4>
                                <div className='test ms-5 ps-1 my-2'>
                                  {optionArry?.none && (
                                    <div className='d-flex align-items-center'>
                                      <p className='bullet-ic mb-0'></p>
                                      <p className=' ms-3 mb-0' style={{fontWeight: '600'}}>
                                        {' '}
                                        None
                                      </p>
                                    </div>
                                  )}

                                  {optionArry?.chillerFree === true && (
                                    <div className='d-flex align-items-center'>
                                      <p className='bullet-ic mb-0'></p>
                                      <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                        {' '}
                                        Chiller Free
                                      </p>
                                    </div>
                                  )}

                                  {optionArry?.maintenanceFree === true && (
                                    <div className='d-flex align-items-center'>
                                      <p className='bullet-ic mb-0'></p>
                                      <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                        {' '}
                                        Maintenance Free
                                      </p>
                                    </div>
                                  )}
                                  {otherOptions?.length > 0 &&
                                    otherOptions?.map((opt: any, ind: any) => (
                                      <div className='d-flex align-items-center'>
                                        <p className='bullet-ic mb-0'></p>
                                        <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                          {' '}
                                          {opt?.value}
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                            <div className='col-4 ms-auto'>
                              {tenancyData?.remainingDays <= tenancyData?.renewalDays && (
                                <>
                                  {/* Tenancy Renewal Button */}
                                  {tenancyData?.renewal_request != null ? (
                                    <>
                                      <a
                                        className='btn btn-sm fw-bold renew-btn ps-2 pe-0 ps-2 mt-4'
                                        onClick={() => {
                                          dispatch(setComefrom('edit-view'))
                                          navigate(
                                            `/lease-request/${tenancyData?.renewal_request?._id}/${tenancyData?.unitId}/${tenancyData?._id}/2`
                                          )

                                          // navigate(`/tenants`, {
                                          //   state: {
                                          //     requestId: tenancyData?.renewal_request?._id,
                                          //   },
                                          // })

                                          // navigate(
                                          //   // `/view-request/${tenancyData?.renewal_request?._id}/${tenancyData?.renewal_request?.type}`

                                          // )

                                          /* navigate(
                                          `/overViewData/${unitId}/view/${tenancyData?.renewal_request?._id}/${tenancyData?.renewal_request?.type}`,
                                          {
                                            state: {
                                              details: state.details,
                                              id: unitId,
                                          },
                                            })*/
                                        }}
                                      >
                                        <img
                                          src={sync}
                                          height={20}
                                          width={20}
                                          className='cursor-pointer me-4'
                                        />
                                        Renewal Request
                                      </a>
                                    </>
                                  ) : (
                                    <>
                                      {/* {tenancyData?.draft_renewal_tenancy?.isDraft == true ? (
                                    <>
                                      <button
                                        type='button'
                                        className='btn btn-sm fw-bold renew-btn me-0 mt-2 ps-2 pe-0 mt-4'
                                        onClick={() => {
                                          navigate(
                                            `/tenancy/draft/2/${tenancyData?.draft_renewal_tenancy?.unitId}/${tenancyData?.draft_renewal_tenancy?._id}`
                                          )
                                        }}
                                        disabled={
                                          tenancyData?.terminate_request != null ? true : false
                                        }
                                      >
                                        <img
                                          src={sync}
                                          height={20}
                                          width={20}
                                          className='cursor-pointer me-4'
                                        />
                                        Continue Renewal
                                      </button>
                                    </>
                                  ) : ( */}
                                      <>
                                        {(tenancyData?.tenancyStatus === 1 ||
                                          tenancyData?.tenancyStatus === 2 ||
                                          tenancyData?.tenancyStatus === 3) && (
                                          <button
                                            type='button'
                                            className='btn btn-sm fw-bold renew-btn me-0 mt-2 ps-2  pe-0 mt-4'
                                            onClick={() => {
                                              dispatch(setComefrom('edit-view')) 
                                              generateTicketForRenew()
                                              // navigate(`/renew-tenancy/${unitId}/${tenancyData?._id}`)

                                              // navigate(
                                              //   `/tenancy/draft/4/${tenancyData?.unitId}/${tenancyData?._id}`
                                              // )
                                            }}
                                            // disabled={
                                            //   tenancyData?.terminate_request != null ? true : false
                                            // }

                                            disabled={
                                              tenancyData?.renewalStatus == 7
                                                ? false
                                                : tenancyData?.terminate_request != null
                                                ? true
                                                : false
                                            }
                                          >
                                            <img
                                              src={sync}
                                              height={20}
                                              width={20}
                                              className='cursor-pointer me-4'
                                            />
                                            Renew Lease
                                          </button>
                                        )}
                                      </>
                                      {/* )} */}
                                    </>
                                  )}
                                </>
                              )}

                              {/* Tenancy Termination Button */}
                              {tenancyData?.terminate_request != null ? (
                                <>
                                  <a
                                    className='btn btn-sm fw-bold term-btn me-0 mt-2 ps-2  pe-0 mt-4'
                                    onClick={() => {
                                      dispatch(setComefrom('edit-view'))
                                          navigate(
                                            `/lease-request/${tenancyData?.terminate_request?._id}/${tenancyData?.unitId}/${tenancyData?._id}/3`
                                      )

                                      // localStorage.setItem('fromList', 'true')
                                      // dispatch(setComefrom('edit-view-terminate'))
                                      // navigate(`/tenants`, {
                                      //   state: {
                                      //     requestId: tenancyData?.terminate_request?._id,
                                      //   },
                                      // })

                                      // navigate(
                                      //   `/view-request/${tenancyData?.terminate_request?._id}/${tenancyData?.terminate_request?.type}`
                                      // )
                                    }}
                                  >
                                    <img
                                      src={terminate}
                                      height={18}
                                      width={18}
                                      className='cursor-pointer me-4'
                                    />
                                    Termination Request
                                  </a>
                                </>
                              ) : (
                                <>
                                  {(tenancyData?.tenancyStatus === 1 ||
                                    tenancyData?.tenancyStatus === 2) && (
                                    <button
                                      type='button'
                                      className='btn btn-sm fw-bold term-btn me-0 mt-2 ps-2  pe-0 mt-4'
                                      onClick={() => {
                                        dispatch(setComefrom('edit-view'))

                                        // localStorage.setItem('fromList', 'true')
                                        // dispatch(setComefrom('edit-view-terminate'))
                                        
                                        generateTicketForTerminate()
                                      }}
                                      // onClick={() => setShowTerm(true)}
                                      // onClick={() => handleSubmit('terminate_tenancy')}
                                      disabled={
                                        tenancyData?.renewalStatus == 2
                                          ? false
                                          : tenancyData?.renewal_request != null
                                          ? true
                                          : false
                                      }
                                    >
                                      <img
                                        src={terminate}
                                        height={18}
                                        width={18}
                                        className='cursor-pointer me-4'
                                      />
                                      Terminate Lease
                                    </button>
                                  )}
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>

                      <div className='row mx-1 mt-5 pt-4 ms-2'>
                        <div className='col-12 ps-0'>
                          <div className='d-flex  align-items-center'>
                            <h3 className='ms-0' style={{fontWeight: '700'}}>
                              Financial Summary
                            </h3>
                          </div>
                        </div>
                        <div className='col-12 ps-0 ms-2'>
                          <div className='d-flex'>
                            <div className='test mt-2' style={{width: '-webkit-fill-available'}}>
                              <div className='row mb-3'>
                                <div className='d-flex align-items-center col-3 ps-1'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    {' '}
                                    Rent
                                  </h4>
                                </div>
                                <div className='col-4'>
                                  <div className='d-flex align-items-center'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '90px'}} className='labl-gry'>
                                        {' '}
                                        Total Amount{' '}
                                      </label>
                                    </h4>

                                    <span className='ms-5 ps-1 my-2'>
                                      <b>
                                        {rentFin?.totalAmount?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </b>
                                    </span>
                                  </div>
                                </div>

                                <div className='col-4'>
                                  {' '}
                                  <div className='d-flex align-items-center'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '90px'}} className='labl-gry'>
                                        {' '}
                                        No. of Payment{' '}
                                      </label>
                                    </h4>

                                    <span className='ms-5 ps-1 my-2'>
                                      <b>{rentFin?.noOfPayment}</b>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <div className='d-flex align-items-center col-3 ps-1'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    {' '}
                                    Security Deposit
                                  </h4>
                                </div>
                                <div className='col-4'>
                                  <div className='d-flex align-items-center'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '90px'}} className='labl-gry'>
                                        {' '}
                                        Total Amount{' '}
                                      </label>
                                    </h4>

                                    <span className='ms-5 ps-1 my-2'>
                                      <b>
                                        {securityFin?.totalAmount?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </b>
                                    </span>
                                  </div>
                                </div>

                                <div className='col-4'>
                                  {' '}
                                  <div className='d-flex align-items-center'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '90px'}} className='labl-gry'>
                                        {' '}
                                        No. of Payment{' '}
                                      </label>
                                    </h4>

                                    <span className='ms-5 ps-1 my-2'>
                                      <b>{securityFin?.noOfPayment}</b>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              {otherFinancials?.length > 0 &&
                                otherFinancials?.map((fn: any, ind: any) => (
                                  <>
                                    <div className='row mb-3'>
                                      <div className='d-flex align-items-center col-3 ps-1'>
                                        <h4 className='my-2' style={{color: 'black'}}>
                                          {' '}
                                          {fn?.name}
                                        </h4>
                                      </div>
                                      <div className='col-4'>
                                        <div className='d-flex align-items-center'>
                                          <h4 className='my-2' style={{color: 'black'}}>
                                            <label style={{minWidth: '90px'}} className='labl-gry'>
                                              {' '}
                                              Total Amount{' '}
                                            </label>
                                          </h4>

                                          <span className='ms-5 ps-1 my-2'>
                                            <b>
                                              {fn?.totalAmount?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </b>
                                          </span>
                                        </div>
                                      </div>

                                      <div className='col-4'>
                                        {' '}
                                        <div className='d-flex align-items-center'>
                                          <h4 className='my-2' style={{color: 'black'}}>
                                            <label style={{minWidth: '90px'}} className='labl-gry'>
                                              {' '}
                                              No. of Payment{' '}
                                            </label>
                                          </h4>

                                          <span className='ms-5 ps-1 my-2'>
                                            <b>{fn?.noOfPayment}</b>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                        )}
                    {/* Property header */}
                    <div className='col-5'>
                      <div
                        className='card card-flush py-5 container-xxl mt-2  min-res pe-5'
                        style={
                          isEdit
                            ? {position: 'relative', zIndex: '10', opacity: '0.4'}
                            : {position: 'relative', zIndex: '10', opacity: '1'}
                        }
                      >
                        <div className='d-flex  align-items-center'>
                          <h2 className='ms-3' style={{fontWeight: '700'}}>
                            Property
                          </h2>
                          <div className='ms-auto'>
                            <img
                              src={
                                unitData?.unitType === 'town_house'
                                  ? townhouse
                                  : unitData?.unitType === 'other'
                                  ? other
                                  : unitData?.unitType === 'common_area'
                                  ? common_area
                                  : unitData?.unitType === 'villa'
                                  ? villa
                                  : unitData?.unitType === 'apartment'
                                  ? appartment
                                  : unitData?.unitType === 'penthouse'
                                  ? penthouse
                                  : ''
                              }
                              height={40}
                              className='mb-2'
                            />
                          </div>
                        </div>

                        {unitData && (
                          <>
                            <div className='row d-flex me-5 mb-5 mt-0 px-0 ms-0'>
                              {unitData?.communityId && (
                                <div className='col-12 px-4'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                      {' '}
                                      Development{' '}
                                    </label>
                                    <span className='ms-5 ps-1'>
                                      <b>
                                        {unitData?.communityId
                                          ? unitData?.community?.[0]?.name
                                          : '-'}
                                      </b>
                                    </span>
                                  </h4>
                                </div>
                              )}
                              {unitData?.communityId == null && unitData?.buildingId && (
                                <div className='col-12 px-4'>
                                  <h4 className='my-2' style={{color: 'black'}}>
                                    <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                      {' '}
                                      Development{' '}
                                    </label>
                                    <span className='ms-5 ps-1'>
                                      <b>
                                        {unitData?.buildingId ? unitData?.building?.[0]?.name : '-'}
                                      </b>
                                    </span>
                                  </h4>
                                </div>
                              )}

                              <div className='col-12 px-4'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                    {' '}
                                    Unit No{' '}
                                  </label>
                                  <span className='ms-5 ps-1'>
                                    <b>{unitData?.unitNo ? unitData?.unitNo : '-'}</b>
                                  </span>
                                </h4>
                              </div>

                              {hide !== true && (
                                <>
                                  {unitData?.clusterId && (
                                    <div className='col-12 px-4'>
                                      <h4 className='my-2' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Cluster{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.clusterId
                                              ? unitData?.cluster?.[0]?.name
                                              : unitData?.building?.[0]?.name}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  {unitData?.unitGroupId && (
                                    <div className='col-12 px-4'>
                                      <h4 className='my-2' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Unit No{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.unitGroupId
                                              ? unitData?.unit_group?.[0]?.name
                                              : ''}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  {unitData?.floorId && (
                                    <div className='col-12 px-4'>
                                      <h4 className='my-2' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Floor{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.floorId ? unitData?.floor?.[0]?.name : ''}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  <div className='col-12 px-4'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                        {' '}
                                        Type{' '}
                                      </label>
                                      <span className='ms-5 ps-0'>
                                        <b>
                                          {' '}
                                          {unitData?.unitType === 'town_house'
                                            ? 'TownHouse'
                                            : unitData?.unitType === 'other'
                                            ? 'Other'
                                            : unitData?.unitType === 'common_area'
                                            ? 'Common Area'
                                            : unitData?.unitType === 'villa'
                                            ? 'Villa'
                                            : unitData?.unitType === 'apartment'
                                            ? 'Apartment'
                                            : unitData?.unitType === 'penthouse'
                                            ? 'Penthouse'
                                            : '-'}
                                        </b>
                                      </span>
                                    </h4>
                                  </div>

                                  <div className='col-12 px-4'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                        {' '}
                                        Bedroom{' '}
                                      </label>
                                      <span className='ms-5 ps-1'>
                                        <b>{unitData?.bedrooms ? unitData?.bedrooms : '-'}</b>
                                      </span>
                                    </h4>
                                  </div>

                                  <div className='col-12 px-4'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                        {' '}
                                        Size{' '}
                                      </label>
                                      <span className='ms-5 ps-1'>
                                        <b>{unitData?.size ? `${unitData?.size} SqFt` : '-'}</b>
                                      </span>
                                    </h4>
                                  </div>

                                  <div className='col-12 px-4'>
                                    <h4 className='my-2 mb-0' style={{color: 'black'}}>
                                      <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                        {' '}
                                        Property Manager{' '}
                                      </label>
                                      <span className='ms-5 ps-1'>
                                        <b>{unitData?.managerId ? unitData?.managerId : '-'}</b>
                                      </span>
                                    </h4>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Tenancy Documents */}
                  {((user?.isSubUser && role?.leasing_residential?.lease_agreements?.contract_detail?.view_details) ||
                        !user?.isSubUser) && (
                  <div className='row mt-5 ms-2 mx-3'>
                    <div className='col-7 ps-0 pe-1 mt-5'>
                      <div className='d-flex  align-items-center'>
                        <h3 className='ms-0' style={{fontWeight: '700'}}>
                          Documents
                        </h3>
                        {isEdit && (
                          <div className='ms-auto'>
                            <button
                              type='button'
                              className='btn btn-sm fw-bold  ms-3  blue-btn'
                              disabled={sendError || (user?.isSubUser &&
                                !role?.leasing_residential?.lease_agreements?.contract_detail?.update)}
                              onClick={() => {
                                if (newTenantId) {
                                  setFrom('contract')

                                  let temp = [...contractDocuments]
                                  localStorage.setItem('contractCopy', JSON.stringify(temp))

                                  setTenantFolderModal(true)
                                } else {
                                  ErrorToast('Please select Main Tenant..')
                                }
                              }}
                            >
                              <img
                                src={addFolder}
                                height={20}
                                width={20}
                                style={{marginRight: '7px'}}
                              />{' '}
                              Folder
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    {contractDocuments?.map((doc: any, index: any) => {
                      return (
                        <>
                          {doc.isCheck && (
                            <>
                              <div className='col-7 ps-0 pe-1 mt-4'>
                                <div className='d-flex  align-items-center'>
                                  <h4 className='' style={{fontWeight: '700'}}>
                                    {doc?.name}
                                  </h4>
                                  {isEdit && (
                                    <div className='ms-auto'>
                                      <button
                                        type='button'
                                        className='btn btn-sm fw-bold  ms-3  blue-hollow-btn'
                                        disabled={sendError || (user?.isSubUser &&
                                          !role?.leasing_residential?.lease_agreements?.contract_detail?.update)}
                                        onClick={() => {
                                          setFrom('contract')
                                          setTargetFolder(doc)
                                          setTimeout(() => {
                                            setTenantFileModal(true)
                                          }, 1000)
                                        }}
                                      >
                                        <img
                                          src={invoiceAdd}
                                          height={20}
                                          width={16}
                                          style={{marginRight: '15px'}}
                                        />{' '}
                                        File
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className='col-5 mt-4 ps-1'>
                                {isEdit && (
                                  <img
                                    src={redCrossFilled}
                                    height={15}
                                    width={15}
                                    className='cursor-pointer ms-0 mt-3'
                                    onClick={() => {
                                      const values = [...contractDocuments]
                                      values[index].isCheck = false
                                      setContractDocuments(values)

                                      setIsFormDirty(true)
                                      localStorage.setItem('isFormDirty', 'true')
                                    }}
                                  />
                                )}
                              </div>
                              <div className='col-12 px-0 mt-5 pt-4'>
                                {doc?.fileList?.length > 0 ? (
                                  <div
                                    className='tops d-flex gap-5 align-items-start mx-5'
                                    style={{overflow: 'auto', overflowY: 'hidden'}}
                                  >
                                    {doc?.fileList?.map((file: any, ind: any) => {
                                      return (
                                        <>
                                          {file.isChecked && (
                                            <div className='imgs-set'>
                                              <div className='d-flex mw-350px me-3'>
                                                <img
                                                  src={Bucket + file?.fileURL}
                                                  width='100'
                                                  height='100'
                                                  className='main_img mb-5 cursor-pointer'
                                                  id={`clicked${doc?.name}${ind}`}
                                                  onClick={() =>
                                                    handleImageClick(
                                                      `clicked${doc?.name}${ind}`,
                                                      `${Bucket}${file?.fileURL}`,
                                                      `${doc?.name}`,
                                                      doc?.fileList,
                                                      'ContactDocs'
                                                    )
                                                  }
                                                />
                                                {isEdit ? (
                                                  <img
                                                    src={redCross}
                                                    height={15}
                                                    width={15}
                                                    className='cursor-pointer ms-2 mt-1'
                                                    onClick={() => {
                                                      const values = [...contractDocuments]
                                                      console.log(values)
                                                      values[index].fileList[ind].isChecked = false
                                                      setContractDocuments(values)

                                                      setIsFormDirty(true)
                                                      localStorage.setItem('isFormDirty', 'true')
                                                    }}
                                                  />
                                                ) : (
                                                  <>
                                                    <img
                                                      src={download}
                                                      height={18}
                                                      width={16}
                                                      className='cursor-pointer ms-2 mt-0'
                                                      onClick={() => {
                                                        if (!isDisable)
                                                          handleDownload(Bucket + file?.fileURL)
                                                      }}
                                                      style={
                                                        isDisable
                                                          ? {opacity: '0.6'}
                                                          : {opacity: '1'}
                                                      }
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )
                                    })}
                                  </div>
                                ) : (
                                  <div className='d-flex justify-content-center'>
                                    {' '}
                                    <div className=''>
                                      {' '}
                                      {/* <h2 className='head-text'>No Files...</h2> */}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )
                    })}

                    {contractDeletedDocuments?.map((doc: any, index: any) => {
                      return (
                        <>
                          {doc.isCheck && (
                            <>
                              <div className='col-7 ps-0 pe-1 mt-4'>
                                <div className='d-flex  align-items-center'>
                                  <h4 className='' style={{fontWeight: '700'}}>
                                    {doc?.name}
                                  </h4>
                                  {isEdit && (
                                    <div className='ms-auto'>
                                      {/* <button
                                        type='button'
                                        className='btn btn-sm fw-bold  ms-3  blue-hollow-btn'
                                        disabled={sendError}
                                        onClick={() => {
                                          setFrom('contract')
                                          setTargetFolder(doc)
                                          setTimeout(() => {
                                            setTenantFileModal(true)
                                          }, 1000)
                                        }}
                                      >
                                        <img
                                          src={invoiceAdd}
                                          height={20}
                                          width={16}
                                          style={{marginRight: '15px'}}
                                        />{' '}
                                        File
                                      </button> */}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className='col-5 mt-4 ps-1'>
                                {isEdit && (
                                  <img
                                    src={redCrossFilled}
                                    height={15}
                                    width={15}
                                    className='cursor-pointer ms-0 mt-3'
                                    onClick={() => {
                                      const values = [...contractDeletedDocuments]
                                      values[index].isCheck = false
                                      setContractDeletedDocuments(values)

                                      setIsFormDirty(true)
                                      localStorage.setItem('isFormDirty', 'true')
                                    }}
                                  />
                                )}
                              </div>
                              <div className='col-12 px-0 mt-5 pt-4'>
                                {doc?.fileList?.length > 0 ? (
                                  <div
                                    className='tops d-flex gap-5 align-items-start mx-5'
                                    style={{overflow: 'auto', overflowY: 'hidden'}}
                                  >
                                    {doc?.fileList?.map((file: any, ind: any) => {
                                      return (
                                        <>
                                          {file.isChecked && (
                                            <div className='imgs-set'>
                                              <div className='d-flex mw-350px me-3'>
                                                <img
                                                  src={Bucket + file?.fileURL}
                                                  width='100'
                                                  height='100'
                                                  className='main_img mb-5 cursor-pointer'
                                                  id={`clicked${doc?.name}${ind}`}
                                                  onClick={() =>
                                                    handleImageClick(
                                                      `clicked${doc?.name}${ind}`,
                                                      `${Bucket}${file?.fileURL}`,
                                                      `${doc?.name}`,
                                                      doc?.fileList,
                                                      'ContactDeletedDocs'
                                                    )
                                                  }
                                                />
                                                {isEdit ? (
                                                  <img
                                                    src={redCross}
                                                    height={15}
                                                    width={15}
                                                    className='cursor-pointer ms-2 mt-1'
                                                    onClick={() => {
                                                      const values = [...contractDeletedDocuments]
                                                      console.log(values)
                                                      values[index].fileList[ind].isChecked = false
                                                      setContractDeletedDocuments(values)

                                                      setIsFormDirty(true)
                                                      localStorage.setItem('isFormDirty', 'true')
                                                    }}
                                                  />
                                                ) : (
                                                  <>
                                                    <img
                                                      src={download}
                                                      height={18}
                                                      width={16}
                                                      className='cursor-pointer ms-2 mt-0'
                                                      onClick={() => {
                                                        if (!isDisable)
                                                          handleDownload(Bucket + file?.fileURL)
                                                      }}
                                                      style={
                                                        isDisable
                                                          ? {opacity: '0.6'}
                                                          : {opacity: '1'}
                                                      }
                                                    />
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )
                                    })}
                                  </div>
                                ) : (
                                  <div className='d-flex justify-content-center'>
                                    {' '}
                                    <div className=''>
                                      {' '}
                                      {/* <h2 className='head-text'>No Files...</h2> */}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )
                    })}
                  </div>
                  )}

                </div>
              </div>
              {((user?.isSubUser &&
                role?.leasing_residential?.lease_agreements?.tenant_detail?.view_details) ||
                !user?.isSubUser) && (
                <>
                  <div className='row mx-1 mt-5' style={isEdit ? {opacity: '0.4'} : {opacity: '1'}}>
                    <div className='col-7 ps-5 mt-5'>
                      <h2 className='top ps-0 mt-3'>
                        <b>Main Tenant</b>
                      </h2>
                    </div>
                  </div>
                  <div className='row mx-0' style={isEdit ? {opacity: '0.4'} : {opacity: '1'}}>
                    <div className='col-12 ps-5 pe-0'>
                      <div className='table-responsive'>
                        <table
                          className='table align-middle  fs-6 gy-5'
                          id='kt_ecommerce_sales_table'
                        >
                          <thead>
                            <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                              <th className='text-start min-w-100px'>First Name</th>
                              <th className='text-start min-w-100px'>Last Name</th>
                              <th className='text-start min-w-100px'>Passport No.</th>
                              <th className='text-start min-w-100px'>ID No.</th>
                              <th className='text-start min-w-150px'>Email </th>
                              <th className='text-start min-w-100px'>Mobile No.</th>
                              <th className='text-start min-w-100px'></th>
                            </tr>
                          </thead>

                          <tbody className='fw-semibold text-gray-600'>
                            <tr className='mt-2 head-text'>
                              <td className='text-start min-w-100px'>
                                {`${formData?.firstName}` ? `${formData?.firstName}` : '-'}
                              </td>
                              <td className='text-start min-w-100px'>
                                {`${formData?.lastName}` ? `${formData?.lastName}` : '-'}
                              </td>
                              <td className='text-start min-w-100px'>
                                {`${formData?.passportNo}` ? `${formData?.passportNo}` : '-'}
                              </td>
                              <td className='text-start min-w-100px'>
                                {`${formData?.idNo}` ? `${formData?.idNo}` : '-'}
                              </td>

                              <td className='text-start min-w-150px'>
                                {`${formData?.email}` ? `${formData?.email}` : '-'}{' '}
                              </td>
                              <td className='text-start min-w-100px'>
                                {`${formData?.phoneNumber}}`
                                  ? `${formData?.countryCode} ${formData?.phoneNumber}`
                                  : '-'}
                              </td>
                              <td>
                                <div className='d-flex'>
                                  <img
                                    src={profileGreen}
                                    height={18}
                                    width={18}
                                    className='me-3 cursor-pointer'
                                    onClick={() => {
                                      if (!isEdit) {
                                        setFrom('main')
                                        setTenantIdToView(newTenantId)
                                        setEditTenantModal(true)
                                      }
                                    }}
                                  />
                                  <img
                                    src={folderGreen}
                                    height={18}
                                    width={18}
                                    className='me-3 cursor-pointer'
                                    onClick={() => {
                                      if (!isEdit) {
                                        setFrom('tenant')
                                        setIsEditTenant(false)
                                        setTimeout(() => {
                                          setTenantDocModal(true)
                                        }, 200)
                                      }
                                    }}
                                  />
                                  <img
                                    src={chatGreen}
                                    height={17}
                                    width={17}
                                    className='cursor-pointer'
                                    onClick={() => {
                                      // setNewMessageModal(true)
                                      if (!isEdit) {
                                        SetBodyWithSubject({subjectTitle: newTenantId})
                                        setChatSubject(newTenantId)
                                        setChatServiceJobId(newTenantId)
                                        getChatRoomForTenant(formData)
                                      }
                                    }}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className='row mx-1 mt-5'>
                    <div className='col-12 ps-5 mt-3'>
                      <div className='d-flex ms-auto'>
                        <h2 className='top ps-0'>
                          <b>Sub Tenants</b>
                        </h2>
                        {((user?.isSubUser &&
                          role?.leasing_residential?.lease_agreements?.tenant_detail?.update) ||
                          !user?.isSubUser) && (
                          <>
                            {isEdit && (
                              <div className='d-flex ms-auto'>
                                <button
                                  type='button'
                                  className='btn btn-sm fw-bold  me-3  select-btn'
                                  onClick={() => {
                                    let values = [...subTenantData]
                                    let temp = subTenantData?.length

                                    if (values?.length == 0) handleAddFields()
                                    else {
                                      console.log(values[temp])
                                      if (values[values?.length - 1]?.id != '') handleAddFields()
                                      else
                                        ErrorToast(
                                          'The subtenant selection dropdown is already available, please select the previous dropdown to add more subtenants first.'
                                        )
                                    }
                                  }}
                                  disabled={sendError}
                                >
                                  <img
                                    src={addWhite}
                                    height={18}
                                    width={18}
                                    style={{marginRight: '10px'}}
                                  />{' '}
                                  Add
                                </button>

                                <button
                                  type='button'
                                  className='btn btn-sm fw-bold green-submit-btn'
                                  onClick={() => {
                                    setFrom('sub')
                                    setAddNewTenant(true)
                                  }}
                                  style={{width: '160px'}}
                                  disabled={sendError}
                                >
                                  <img
                                    src={profileWhite}
                                    height={18}
                                    width={18}
                                    style={{margin: '5px'}}
                                  />{' '}
                                  Add New Tenant
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row mx-0'>
                    <div className='col-12 ps-5 pe-0'>
                      <div className='table-responsive' style={{overflow: 'unset'}}>
                        <table
                          className='table align-middle  fs-6 gy-5'
                          id='kt_ecommerce_sales_table'
                        >
                          <thead>
                            <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                              <th className='text-start min-w-100px'>First Name</th>
                              <th className='text-start min-w-100px'>Last Name</th>
                              <th className='text-start min-w-100px'>Passport No.</th>
                              <th className='text-start min-w-100px'>ID No.</th>
                              <th className='text-start min-w-150px'>Email </th>
                              <th className='text-start min-w-100px'>Mobile No.</th>
                              <th className='text-start min-w-100px'></th>
                              {isEdit && <th className='text-start min-w-25px'></th>}
                            </tr>
                          </thead>

                          <tbody className='fw-semibold text-gray-600'>
                            {subTenantData?.length ? (
                              subTenantData.map((sb: any, i: any) => {
                                return (
                                  <>
                                    {tenanatData &&
                                      tenanatData.map((pI: any, index: any) => {
                                        if (sb?.id == pI._id) itemInd = index
                                        return <></>
                                      })}

                                    {isEdit && (
                                      <tr className=''>
                                        <div className='' style={{color: 'black', width: '200px'}}>
                                          {sb?.id != '' ? (
                                            <Select
                                              defaultValue={{
                                                label: `${tenanatData[itemInd]?.firstName} ${tenanatData[itemInd]?.lastName}`,
                                                value: tenanatData[itemInd]?._id,
                                              }}
                                              value={{
                                                label: `${tenanatData[itemInd]?.firstName} ${tenanatData[itemInd]?.lastName}`,
                                                value: tenanatData[itemInd]?._id,
                                              }}
                                              styles={customStyles}
                                              isSearchable={true}
                                              options={
                                                tenanatData &&
                                                tenanatData?.map((i: any, index: any) => {
                                                  return {
                                                    label: `${i?.firstName} ${i?.lastName}`,
                                                    value: `${i?._id}`,
                                                  }
                                                })
                                              }
                                              onChange={(e: any) => handleSelectSubTenant(e, i)}
                                              isDisabled={sendError ? true : false}
                                            />
                                          ) : (
                                            <Select
                                              value={{
                                                label: `Search Tenant..`,
                                                value: '',
                                              }}
                                              styles={customStyles}
                                              isSearchable={true}
                                              options={
                                                tenanatData &&
                                                tenanatData?.map((i: any, index: any) => {
                                                  return {
                                                    label: `${i?.firstName} ${i?.lastName}`,
                                                    value: `${i?._id}`,
                                                  }
                                                })
                                              }
                                              name='tenanttSource'
                                              onChange={(e: any) => handleSelectSubTenant(e, i)}
                                              isDisabled={sendError ? true : false}
                                              placeholder={'Search Tenant..'}
                                            />
                                          )}
                                          {/* <select
                                       className='form-select form-select-solid my-2'
                                       value={`${sb?.id}`}
                                       onChange={(e: any) => handleSelectSubTenant(e, i)}
                                       style={{width: '100%'}}
                                     >
                                       <option value='' disabled selected>
                                         Select Tenant
                                       </option>

                                       {tenanatData?.map((i: any) => {
                                         return (
                                           <option value={i?._id}>
                                             {`${i?.firstName} ${i?.lastName}`}
                                           </option>
                                         )
                                       })}
                                     </select> */}
                                        </div>
                                      </tr>
                                    )}
                                    {sb?.id != '' && (
                                      <tr className='mt-3 head-text'>
                                        <td className='text-start min-w-100px'>
                                          {`${sb?.firstName}` ? `${sb?.firstName}` : '-'}
                                        </td>
                                        <td className='text-start min-w-100px'>
                                          {`${sb?.lastName}` ? `${sb?.lastName}` : '-'}
                                        </td>
                                        <td className='text-start min-w-100px'>
                                        {(sb?.passportNo != 'undefined' && sb?.passportNo != undefined &&  sb?.passportNo != null && sb?.passportNo != 'null') ? sb?.passportNo : '-'} 
                                        </td>
                                        <td className='text-start min-w-100px'>
                                          {(sb?.idNo != 'undefined' && sb?.idNo != undefined &&  sb?.idNo != null) ? sb?.idNo : '-'}
                                        </td>
                                        <td className='text-start min-w-150px'>
                                          {`${sb?.email}` ? `${sb?.email}` : '-'}{' '}
                                        </td>
                                        <td className='text-start min-w-100px'>
                                          {`${sb?.phoneNumber}}`
                                            ? `${sb?.countryCode} ${sb?.phoneNumber}`
                                            : '-'}
                                        </td>
                                        <td
                                          className='text-start min-w-100px'
                                          style={isEdit ? {opacity: '0.4'} : {opacity: '1'}}
                                        >
                                          <div className='d-flex'>
                                            <img
                                              src={profileGreen}
                                              height={18}
                                              width={18}
                                              className='me-3 cursor-pointer'
                                              onClick={() => {
                                                if (!isEdit) {
                                                  setFrom('sub')
                                                  setSubInd(i)
                                                  setTenantIdToView(sb?.id)
                                                  setEditTenantModal(true)
                                                }
                                              }}
                                            />
                                            {/* <img
                                          src={folderGreen}
                                          height={18}
                                          width={18}
                                          className='me-3'
                                          onClick={() => {
                                            if (!isEdit) {
                                              setFrom('sub')
                                              setSubInd(i)
                                              setTenantIdToView(sb?.id)
                                              setEditTenantModal(true)
                                            }
                                          }}
                                        /> */}
                                            <img
                                              src={chatGreen}
                                              height={17}
                                              width={17}
                                              className='cursor-pointer'
                                              onClick={() => {
                                                if (!isEdit) {
                                                  SetBodyWithSubject({subjectTitle: sb?.id})
                                                  setChatSubject(sb?.id)
                                                  setChatServiceJobId(sb?.id)
                                                  getChatRoomForTenant(sb)
                                                }
                                              }}
                                            />
                                          </div>
                                        </td>
                                        {isEdit && (
                                          <td>
                                            {' '}
                                            <img
                                              src={redCross}
                                              height={16}
                                              width={16}
                                              style={{cursor: 'pointer'}}
                                              className='me-5'
                                              onClick={() => {
                                                swal
                                                  .fire({
                                                    text: 'Are you sure you want to remove this tenant from Sub Tenants List ?',
                                                    icon: 'warning',
                                                    showConfirmButton: true,
                                                    confirmButtonColor: '#D72852',
                                                    confirmButtonText: 'Yes',
                                                    showCancelButton: true,
                                                    // cancelButtonColor: "transparent",
                                                    cancelButtonText: 'Cancel',
                                                  })
                                                  .then((res) => {
                                                    if (res.isConfirmed) {
                                                      let temp = [...subTenantIds]
                                                      temp.splice(i, 1)
                                                      setSubTenantIds(temp)

                                                      let values = [...subTenantData]
                                                      values.splice(i, 1)
                                                      setSubTenantData(values)

                                                      setIsFormDirty(true)
                                                    }
                                                  })
                                              }}
                                            />{' '}
                                          </td>
                                        )}
                                      </tr>
                                    )}
                                  </>
                                )
                              })
                            ) : (
                              <>
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={350} />
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* Financials */}
              
              {((user?.isSubUser && role?.leasing_residential?.lease_financial?.view_list) ||
              !user?.isSubUser) &&
                <>
                <div className='row mx-4 mt-5' style={isEdit ? {opacity: '0.4'} : {opacity: '1'}}>
                  <div className='col-12 px-4 mt-5'>
                    <div className='d-flex align-items-center'>
                      <h2 className='top ps-0'>
                        <b>Financials</b>
                      </h2>
                      { ((user?.isSubUser && role?.leasing_residential?.lease_financial?.create) ||
                                     !user?.isSubUser) && 
                      <button
                        type='button'
                        className='btn btn-sm fw-bold green-submit-btn ms-auto'
                        onClick={() => {
                          let values = {
                            firstName: formData?.firstName,
                            lastName: formData?.lastName,
                            email: formData?.email,
                            countryCode: formData?.countryCode,
                            phoneNumber: formData?.phoneNumber,
                          }
                          setTenantDetails(values)
                          setIncomeModal(true)
                        }}
                        style={{width: '200px'}}
                        disabled={isEdit ? true : false}
                      >
                        <img src={addWhite} height={16} width={16} style={{marginRight: '10px'}} />{' '}
                        Add Incoming Payment
                      </button>
                   }
                    </div>
                  </div>
                </div>

                <div
                  className='row mx-4 mt-5 mb-5'
                  style={isEdit ? {opacity: '0.4'} : {opacity: '1'}}
                >
                  <div className='col-12 px-3 mt-3 mb-3'>
                    {incomePaymentPayload?.length > 0 ? (
                      <>
                        <div className='card px-3' style={{boxShadow: 'lightgrey 0px 4px 6px 5px'}}>
                          <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                            <table
                              className='table align-start table-row-dashed fs-6 gy-5'
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                  <th className='min-w-100px'>Payment</th>
                                  <th className='min-w-100px'>Amount</th>
                                  <th className='min-w-100px'>Status</th>
                                  <th className='min-w-100px'>Due</th>
                                  <th className='min-w-100px'>Created At</th>
                                  <th className='min-w-150px'>Amount Paid</th>
                                  <th className='min-w-150px'>Date Paid</th>
                                  { ((user?.isSubUser && role?.leasing_residential?.lease_financial?.view_details) ||
                                     !user?.isSubUser) && 
                                  <th className='min-w-100px'></th>
}
                                </tr>
                              </thead>
                              <tbody className='fw-semibold text-gray-600'>
                                {incomePaymentPayload?.map((pm: any, ind: any) => {
                                  return (
                                    <>
                                      <tr
                                        style={
                                          show[ind] == true
                                            ? {borderBottomStyle: 'none', height: '30px'}
                                            : show[ind] == false
                                            ? {borderBottomStyle: 'dashed', height: '30px'}
                                            : pm?.payment_sub_item?.length == 0
                                            ? {borderBottomStyle: 'dashed', height: '30px'}
                                            : {height: '30px'}
                                        }
                                      >
                                        <td>
                                          <div className='d-flex align-items-center'>
                                            {pm?.name}
                                          </div>
                                        </td>

                                        <td>
                                          {parseFloat(pm?.totalAmount)?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })}
                                        </td>

                                        <td>
                                          {pm?.paymentStatus === 0
                                            ? 'Unpaid'
                                            : pm?.paymentStatus === 1
                                            ? 'Paid'
                                            : pm?.paymentStatus === 2
                                            ? 'Partially paid'
                                            : pm?.paymentStatus === 3
                                            ? 'Void'
                                            : 'Draft'}
                                        </td>

                                        <td>
                                          {pm?.dueDate
                                            ? moment(pm?.dueDate).format('DD.MM.YYYY')
                                            : '-'}
                                        </td>
                                        <td>
                                          {pm?.createdAt
                                            ? moment(pm?.createdAt).format('DD.MM.YYYY - hh:mm A')
                                            : '-'}
                                        </td>
                                        <td>
                                          {pm?.amountPaid?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })}
                                        </td>
                                        <td>
                                          {pm?.datePaid
                                            ? moment(pm?.datePaid).format('DD.MM.YYYY - hh:mm A')
                                            : '-'}
                                        </td>
                                        { ((user?.isSubUser && role?.leasing_residential?.lease_financial?.view_details) ||
                                     !user?.isSubUser) && 
                                        <td>
                                          <BsArrowRightSquareFill
                                            color='#35475E '
                                            fontSize={18}
                                            style={
                                              !isEdit
                                                ? {cursor: 'pointer'}
                                                : {cursor: 'not-allowed'}
                                            }
                                            className='mx-3 me-4'
                                            onClick={() => {
                                              if (!isEdit) getPaymentById(pm?._id)
                                            }}

                                            // onClick={() => {
                                            //   let values = {
                                            //     firstName: formData?.firstName,
                                            //     lastName: formData?.lastName,
                                            //     email: formData?.email,
                                            //     countryCode: formData?.countryCode,
                                            //     phoneNumber: formData?.phoneNumber,
                                            //   }
                                            //   setTenantDetails(values)

                                            //   console.log(pm?.payment?._id)
                                            //   if (pm?.payment?._id) {
                                            //     ApiGet(`corporate/payment/${pm?.payment?._id}`)
                                            //       .then(async (res) => {
                                            //         setDataToEdit(res?.data?.data)
                                            //         setIndexToEdit(ind)
                                            //         setEditIncomeModalDraft(true)
                                            //       })
                                            //       .catch((err) => console.log('err', err))
                                            //   } else {
                                            //     console.log(pm)
                                            //     setDataToEdit(pm)
                                            //     setIndexToEdit(ind)
                                            //     setEditIncomeModalDraft(true)
                                            //   }
                                            // }}
                                          />
                                        </td>
                                }
                                      </tr>
                                    </>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                          {/* Pagination */}
                          <div className='row mb-5'>
                            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end mt-2'>
                              <div
                                className='dataTables_paginate paging_simple_numbers test'
                                id='kt_ecommerce_sales_table_paginate'
                              >
                                <ReactPaginate
                                  breakLabel='...'
                                  nextLabel='>'
                                  pageClassName='paginate_button page-item +'
                                  pageLinkClassName='page-link'
                                  containerClassName='pagination'
                                  activeClassName='active'
                                  previousClassName='paginate_button page-item previous'
                                  nextClassName='paginate_button page-item next'
                                  previousLinkClassName='page-link'
                                  nextLinkClassName='page-link'
                                  onPageChange={handlePageClick}
                                  pageRangeDisplayed={5}
                                  pageCount={pageLimit}
                                  previousLabel='<'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='card'>
                          <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                            <table
                              className='table align-start table-row-dashed fs-6 gy-5'
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                  <th className='min-w-100px'>Payment</th>
                                  <th className='min-w-100px'>Amount</th>
                                  <th className='min-w-100px'>Status</th>
                                  <th className='min-w-100px'>Due</th>
                                  <th className='min-w-100px'>Created At</th>
                                  <th className='min-w-150px'>Amount Paid</th>
                                  <th className='min-w-150px'>Date Paid</th>
                                  <th className='min-w-100px'></th>
                                </tr>
                              </thead>
                              <tbody className='fw-semibold text-gray-600'>
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={350} />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
              }
            </div>
          </div>
        </form>
      </div>

      <Modal show={showImageName} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUploadFile?.files.map((el: any, i: any) => (
            <>
              <label htmlFor='exampleFormControlInput1' className='required form-label m-0'>
                Input File Name
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                onChange={(e: any) => uploadImageName(e, el, i)}
              />
              {fileFound ? (
                <p className='mt-2' style={{color: 'red'}}>
                  Filename Exist already
                </p>
              ) : null}
            </>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={saveImageFileName}
            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
            disabled={modalBtnIsLoading}
          >
            Save {''}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Property Management '})}</PageTitle>
      <DashboardPage /> */}
      <TenanciesFilter
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false)
        }}
        // type={type}
        showModal={showModal}
        // building={building}
        // updateDataId={updateDataId}
        // isEdit={isEdit}
        // formData={formData}
        // propertiType={propertiType}
        // sethandleSubmitIdv={sethandleSubmitIdv}
        handleSubmitId={handleSubmitId}
        // handleChnage={handleChnage}
        // imageChange={imageChange}
        unitData={unitData._id}
      />

      {incomeModal && (
        <IncomingPaymentModal
          show={incomeModal}
          handleClose={() => setIncomeModal(false)}
          paymentItems={filteredPaymentItems}
          tenantDetails={tenantDetails}
          handlePayload={updateIncomePaymentPayload}
          allPayments={() => getAllPayments()}
          unitDetails={unitData}
          tenancyId={flg == '1' ? newTenancyID : tenancyId}
          from={'view'}
          otherFinancials={''}
        />
      )}

      {/* Edit Payment Modal */}
      {editIncomeModal && (
        <EditPaymentModal
          show={editIncomeModal}
          handleClose={() => {
            setEditIncomeModal(false)
          }}
          incomingPaymentId={incomingPaymentId}
          id={paymentId}
          subItemId={''}
          subId={''}
          getAllPayments={getAllPayments}
          unitDetails={unitDetails}
          detailsToEdit={dataToEdit}
          type={'tenancy'}
        />
      )}

      {/* {editIncomeModalDraft && filteredPaymentItems && (
        <EditPaymentModalDraft
          show={editIncomeModalDraft}
          handleClose={() => {
            setEditIncomeModalDraft(false)
          }}
          paymentItems={filteredPaymentItems}
          tenantDetails={tenantDetails}
          handlePayload={updateIncomePaymentPayload}
          allPayments={() => getAllPayments()}
          unitDetails={unitData}
          tenancyId={flg == '1' ? newTenancyID : tenancyId}
          detailsToEdit={dataToEdit}
          indexToUpdate={indexToEdit}
        />
      )} */}

      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={folderFileModal}
        onHide={() => {
          setFolderFileModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            // minHeight: '150px',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setFolderFileModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>

          {showBck && (
            <div className='d-flex align-items-center mb-3'>
              <span
                className='rounded-circle'
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setFileType('other')
                  setShowBck(false)
                }}
              >
                <img src={backArrow} style={{stroke: 'red'}} height='14' width='14' />
              </span>
              <h2 className='m-0 head-text ms-2'>{selectedFolder?.name}</h2>
            </div>
          )}

          {fileType == 'other' ? (
            <>
              {tenantFolders?.length > 0 ? (
                <>
                  <div className='d-flex'>
                    {tenantFolders?.map((folder: any) => {
                      return (
                        <a
                          onClick={() => {}}
                          className='text-gray-800 text-hover-primary d-flex flex-column mx-5 text-center'
                        >
                          <div
                            className='symbol symbol-60px mb-5'
                            onClick={() => {
                              if (folder?.name == 'Passports') {
                                setDataToSelectFrom(passportFolder)
                                setFileType('passport')
                              } else if (folder?.name == 'Contracts') {
                                setDataToSelectFrom(contractsFolder)
                                setFileType('contract')
                              } else if (folder?.name == 'IDs') {
                                setDataToSelectFrom(iDsFolder)
                                setFileType('ids')
                              } else if (folder?.name == 'Residencies') {
                                setDataToSelectFrom(residencyFolder)
                                setFileType('residency')
                              }
                              setSelectedFolder(folder)
                              setShowBck(true)
                            }}
                          >
                            <img src={folderDocument} className='theme-light-show' alt='' />
                            <img
                              src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                              className='theme-dark-show'
                              alt=''
                            />
                          </div>
                          <div className='fs-5 fw-bold mb-2'>{folder?.name}</div>
                        </a>
                      )
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div className='d-flex justify-content-center'>
                    {' '}
                    <div className=''>
                      {' '}
                      <h2 className='head-text'>No Files...</h2>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {dataToSelectFrom?.fileList?.length > 0 ? (
                <div
                  className='tops d-flex gap-5 align-items-start mx-5'
                  style={{overflow: 'scroll', overflowY: 'hidden'}}
                >
                  {dataToSelectFrom?.fileList?.map((file: any, ind: any) => {
                    return (
                      <div className='imgs-set'>
                        <div className='d-flex mw-350px me-3'>
                          <img
                            src={Bucket + file?.fileURL}
                            width='100'
                            height='100'
                            className='main_img mb-5'
                            onClick={() => {
                              if (showBck) {
                                setuploadOtherImgTenant([...uploadOtherImgTenant, file?.fileURL])
                              } else {
                                if (fileType == 'passport') setuploadPassportTenant([file?.fileURL])
                                else if (fileType == 'contract')
                                  setuploadContractTenancy([file?.fileURL])
                                else if (fileType == 'residency')
                                  setuploadresidencyTenant([file?.fileURL])
                                else if (fileType == 'ids') setuploadIdTenant([file?.fileURL])
                              }

                              setIsFormDirty(true)

                              setFolderFileModal(false)

                              // setTimeout(() => {
                              //   autoSaveTenancy()
                              // }, 2500)
                            }}
                          />
                        </div>
                        <p className=''>
                          {getFilenameToSelect(file?.fileName, false)?.length > 6
                            ? `${file?.fileName?.slice(0, 6)}...${getFilenameToSelect(
                                file?.fileName,
                                true
                              )}`
                            : file?.fileName}
                        </p>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className='d-flex justify-content-center'>
                  {' '}
                  <div className=''>
                    {' '}
                    <h2 className='head-text'>No Files...</h2>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Modal>

      {/* Add New tenant Modal */}
      {addNewTenant && (
        <AddNewTenant
          show={AddNewTenant}
          handleClose={() => setAddNewTenant(false)}
          refetchTenants={() => getTenantData()}
          onSuccess={(val: any) => {
            if (from == 'main') {
              setNewTenantID(val?._id)
              setTenantIdForFolder(val?._id)
              setIsFormDirty(true)
              let temp = val?.firstName + val?.lastName
              setSelectedTenantName(temp)
              tenantName = temp

              setFormData(val)

              setuploadContractTenancy([])
              setuploadresidencyTenant([])
              setuploadPassportTenant([])
              setuploadIdTenant([])
              setuploadOtherImgTenant([])

              let tentFold: any = []
              let t = 0

              let contFold: any = []
              let c = 0

              for (let i = 0; i < val?.tenant_folder?.length; i++) {
                if (val?.tenant_folder?.[i].name == 'Passports') {
                  setPassportFolder(val?.tenant_folder?.[i])
                  tentFold[t] = val?.tenant_folder?.[i]
                  t++
                } else if (val?.tenant_folder?.[i].name == 'Contracts') {
                  contFold[c] = val?.tenant_folder?.[i]
                  c++
                  setContractsFolder(val?.tenant_folder?.[i])
                } else if (val?.tenant_folder?.[i].name == 'IDs') {
                  setIDsFolder(val?.tenant_folder?.[i])
                  tentFold[t] = val?.tenant_folder?.[i]
                  t++
                } else if (val?.tenant_folder?.[i].name == 'Residencies') {
                  tentFold[t] = val?.tenant_folder?.[i]
                  t++
                  setResidencyFolder(val?.tenant_folder?.[i])
                }
              }
              setTenantDocuments(tentFold)
              setContractDocuments(contFold)
            } else {
              let temp = [...subTenantData]
              setIsFormDirty(true)
              temp[subTenantData?.length] = {
                firstName: val?.firstName,
                lastName: val?.lastName,
                phoneNumber: val?.phoneNumber,
                countryCode: val?.countryCode,
                email: val?.email,
                passportNo: val?.passportNo,
                idNo: val?.idNo,
                id: val?._id,
                _id: val?._id,
              }
              setSubTenantData(temp)
              setSubTenantIds([...subTenantIds, val?._id])

              setIsFormDirty(true)
              localStorage.setItem('isFormDirty', 'true')
            }
          }}
        />
      )}

      {/* Add New tenant Modal */}
      {editTenantModal && tenantIdToView && (
        <EditTenant
          show={AddNewTenant}
          handleClose={() => setEditTenantModal(false)}
          refetchTenants={() => getTenantData()}
          tenant={tenantIdToView}
          onSuccess={(val: any) => {
            if (from == 'main') {
              setNewTenantID(val?._id)
              setTenantIdForFolder(val?._id)
              setIsFormDirty(true)
              let temp = val?.firstName + val?.lastName
              setSelectedTenantName(temp)
              tenantName = temp

              setFormData(val)

              setuploadContractTenancy([])
              setuploadresidencyTenant([])
              setuploadPassportTenant([])
              setuploadIdTenant([])
              setuploadOtherImgTenant([])

              let tentFold: any = []
              let t = 0

              let contFold: any = []
              let c = 0
            } else {
              let temp = [...subTenantData]
              setIsFormDirty(true)
              temp[subInd] = {
                firstName: val?.firstName,
                lastName: val?.lastName,
                phoneNumber: val?.phoneNumber,
                countryCode: val?.countryCode,
                email: val?.email,
                passportNo: val?.passportNo,
                idNo: val?.idNo,
                id: val?._id,
                _id: val?._id,
              }
              setSubTenantData(temp)
              setSubTenantIds([...subTenantIds, val?._id])
            }
          }}
        />
      )}

      {/* Add Folder Modal */}
      {tenantFolderModal && (
        <TenantFolders
          show={tenantFolderModal}
          handleClose={(val: any, st: any) => {
            setTenantFolderModal(false)
            if (st) {
              if (from == 'tenant') setTenantDocuments(val)
              else {
                setContractDocuments(val)
                setIsFormDirty(true)
                localStorage.setItem('isFormDirty', 'true')
              }
            } else {
              let temp = localStorage.getItem('contractCopy')
              if (temp != null) {
                let copy = JSON.parse(temp)
                setContractDocuments(copy)
              }

              let temp2 = localStorage.getItem('tenantCopy')
              if (temp2 != null) {
                let copy2 = JSON.parse(temp2)
                setTenantDocuments(copy2)
              }
            }
            // getTenancyData(tenancyId)
          }}
          refetchTenants={() => getTenantData()}
          tenant={tenantIdForFolder}
          tentFolder={from == 'tenant' ? tenantDocuments : contractDocuments}
          // tentFolder={tenantDocuments}
          contFolder={contractDocuments}
          from={from}
          onSuccess={(val: any) => {
            setTenantFolderModal(false)
            console.log(from)
            console.log(val)
            if (from == 'tenant') setTenantDocuments(val)
            else {
              setContractDocuments(val)
              setIsFormDirty(true)
              localStorage.setItem('isFormDirty', 'true')
            }
          }}
        />
      )}

      {/* Add File Modal */}
      {tenantFileModal && (
        <SingleFileModal
          show={tenantFileModal}
          handleClose={(val: any, st: any) => {
            setTenantFileModal(false)
            if (st) {
              if (from == 'tenant') setTenantDocuments(val)
              else {
                setContractDocuments(val)
                setIsFormDirty(true)
                localStorage.setItem('isFormDirty', 'true')
              }
            }
          }}
          refetchTenants={() => getTenantData()}
          tenant={tenantIdForFolder}
          tentFolder={from == 'tenant' ? tenantDocuments : contractDocuments}
          targetFolder={targetFolder}
          contFolder={contractDocuments}
          from={from}
          onSuccess={(val: any) => {
            if (from == 'tenant') setTenantDocuments(val)
            else {
              setContractDocuments(val)
              setIsFormDirty(true)
              localStorage.setItem('isFormDirty', 'true')
            }
          }}
        />
      )}

      {/* Add Payment Modal */}
      {selectModal && (
        <SelectPropertyModal
          show={selectModal}
          handleClose={() => {
            setSelectModal(false)
          }}
          handleSubmitId={handleSubmitId}
        />
      )}

      {/* Tenant Document Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={tenantDocModal}
        onHide={() => {
          setTenantDocModal(false)
        }}
        backdrop={true}
        size='sm'
        // style={{minWidth: '1150px'}}
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            maxHeight: '620px',
            overflow: 'scroll',
          }}
        >
          <div className='row mx-1 mt-5'>
            <div className='col-12'>
              <div
                className='d-flex  align-items-center '
                style={{justifyContent: 'space-between'}}
              >
                <div className='d-flex'>
                  <h3 className='ms-0 me-2' style={{fontWeight: '700'}}>
                    Tenant Documents
                  </h3>
                  {((user?.isSubUser &&
                    role?.leasing_residential?.lease_agreements?.tenant_detail?.update) ||
                    !user?.isSubUser) && (
                    <>
                      {!isEditTenant && (
                        <img
                          src={pencil}
                          alt=''
                          height={16}
                          width={16}
                          className='ms-5 me-1 cursor-pointer'
                          onClick={() => {
                            setIsEditTenant(true)
                          }}
                        />
                      )}
                    </>
                  )}
                </div>

                <div className=' d-flex '>
                  {isEditTenant ? (
                    <>
                      <div className=' d-flex ms-auto'>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold  mx-3 red-hollow-btn ms-auto'
                          onClick={() => {
                            getTenancyData(tenancyId)
                            setIsEditTenant(false)
                          }}
                        >
                          <img src={redCross} height={20} width={20} style={{marginRight: '7px'}} />{' '}
                          Cancel
                        </button>

                        <button
                          type='submit'
                          className='btn btn-sm fw-bold ms-3 green-hollow-btn'
                          onClick={(e) => {
                            submitTenantDocuments(formData, e, true)
                          }}
                        >
                          <img
                            src={saveGreenImg}
                            height={20}
                            width={20}
                            style={{marginRight: '13px'}}
                          />{' '}
                          {'  '}Save
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className='d-flex ms-auto'>
                      <label
                        className='head-text fs-3 cursor-pointer ms-auto'
                        style={{fontWeight: '800', transform: 'translate(0px, -10px)'}}
                        onClick={() => {
                          setTenantDocModal(false)
                        }}
                      >
                        X
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isEditTenant && (
              <div className='col-12'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold  blue-btn'
                  disabled={sendError}
                  onClick={() => {
                    if (newTenantId) {
                      setFrom('tenant')
                      let temp = [...tenantDocuments]
                      localStorage.setItem('tenantCopy', JSON.stringify(temp))
                      setTenantFolderModal(true)
                    } else {
                      ErrorToast('Please select Main Tenant..')
                    }
                  }}
                >
                  <img src={addFolder} height={20} width={20} style={{marginRight: '7px'}} /> Folder
                </button>
              </div>
            )}

            {tenantDocuments?.map((doc: any, index: any) => {
              return (
                <>
                  {doc.isCheck && (
                    <>
                      <div className='col-12 ps-4 pe-3 mt-4'>
                        <div className='d-flex  align-items-center'>
                          <h4 className='' style={{fontWeight: '700'}}>
                            {doc?.name}
                          </h4>
                          {isEditTenant && (
                            <div className='ms-auto d-flex align-items-center'>
                              <button
                                type='button'
                                className='btn btn-sm fw-bold  ms-3  blue-hollow-btn'
                                disabled={sendError}
                                onClick={() => {
                                  setFrom('tenant')
                                  setTargetFolder(doc)
                                  setTenantFileModal(true)
                                }}
                              >
                                <img
                                  src={invoiceAdd}
                                  height={20}
                                  width={16}
                                  style={{marginRight: '15px'}}
                                />{' '}
                                File
                              </button>
                              {/* {!doc?.isSystemGenerated && ( */}
                              <img
                                src={redCrossFilled}
                                height={15}
                                width={15}
                                className='cursor-pointer ms-2 '
                                onClick={() => {
                                  const values = [...tenantDocuments]
                                  values[index].isCheck = false
                                  setTenantDocuments(values)
                                }}
                              />
                              {/* )} */}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='col-12 px-4 mt-4'>
                        {doc?.fileList?.length > 0 ? (
                          <div
                            className='tops d-flex gap-5 align-items-start mx-5'
                            style={{overflow: 'auto', overflowY: 'hidden'}}
                          >
                            {doc?.fileList?.map((file: any, ind: any) => {
                              return (
                                <>
                                  {file.isChecked && (
                                    <div className='imgs-set'>
                                      <div className='d-flex mw-350px me-3 cursor-pointer'>
                                        <img
                                          src={Bucket + file?.fileURL}
                                          width='100'
                                          height='100'
                                          className='main_img mb-5'
                                          id={`clicked${doc?.name}${ind}`}
                                          onClick={() =>
                                            handleImageClick(
                                              `clicked${doc?.name}${ind}`,
                                              `${Bucket}${file?.fileURL}`,
                                              `${doc?.name}`,
                                              doc?.fileList,
                                              'TenantDocuments'
                                            )
                                          }
                                        />
                                        {!isEditTenant && (
                                          <img
                                            src={download}
                                            height={18}
                                            width={16}
                                            className='cursor-pointer ms-2 mt-0'
                                            onClick={() => {
                                              if (!isDisable) handleDownload(Bucket + file?.fileURL)
                                            }}
                                            style={isDisable ? {opacity: '0.6'} : {opacity: '1'}}
                                          />
                                        )}

                                        {isEditTenant && (
                                          <img
                                            src={redCross}
                                            height={15}
                                            width={15}
                                            className='cursor-pointer ms-2 mt-1'
                                            onClick={() => {
                                              const values = [...tenantDocuments]
                                              console.log(values)
                                              values[index].fileList[ind].isChecked = false
                                              setTenantDocuments(values)
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </>
                              )
                            })}
                          </div>
                        ) : (
                          <div className='d-flex justify-content-center'>
                            {' '}
                            <div className=''>
                              {' '}
                              {/* <h2 className='head-text'>No Files...</h2> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )
            })}

            {tenantDeletedDocuments?.map((doc: any, index: any) => {
              return (
                <>
                  {doc.isCheck && (
                    <>
                      <div className='col-12 ps-4 pe-3 mt-4'>
                        <div className='d-flex  align-items-center'>
                          <h4 className='' style={{fontWeight: '700'}}>
                            {doc?.name}
                          </h4>
                          {isEditTenant && (
                            <div className='ms-auto d-flex align-items-center'>
                              {/* <button
                                type='button'
                                className='btn btn-sm fw-bold  ms-3  blue-hollow-btn'
                                disabled={sendError}
                                onClick={() => {
                                  setFrom('tenant')
                                  setTargetFolder(doc)
                                  setTenantFileModal(true)
                                }}
                              >
                                <img
                                  src={invoiceAdd}
                                  height={20}
                                  width={16}
                                  style={{marginRight: '15px'}}
                                />{' '}
                                File
                              </button> */}
                              {/* {!doc?.isSystemGenerated && ( */}
                              <img
                                src={redCrossFilled}
                                height={15}
                                width={15}
                                className='cursor-pointer ms-2 '
                                onClick={() => {
                                  const values = [...tenantDeletedDocuments]
                                  values[index].isCheck = false
                                  setTenantDeletedDocuments(values)
                                }}
                              />
                              {/* )} */}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='col-12 px-4 mt-4'>
                        {doc?.fileList?.length > 0 ? (
                          <div
                            className='tops d-flex gap-5 align-items-start mx-5'
                            style={{overflow: 'scroll', overflowY: 'hidden'}}
                          >
                            {doc?.fileList?.map((file: any, ind: any) => {
                              return (
                                <>
                                  {file.isChecked && (
                                    <div className='imgs-set'>
                                      <div className='d-flex mw-350px me-3'>
                                        <img
                                          src={Bucket + file?.fileURL}
                                          width='100'
                                          height='100'
                                          className='main_img mb-5 cursor-pointer'
                                           id={`clicked${doc?.name}${ind}`}
                                           onClick={() =>
                                           handleImageClick(
                                           `clicked${doc?.name}${ind}`,
                                            `${Bucket}${file?.fileURL}`,
                                              `${doc?.name}`,
                                               doc?.fileList,
                                             'TenantDeletedDocs'
                                            )}
                                        />
                                        {!isEditTenant && (
                                          <img
                                            src={download}
                                            height={18}
                                            width={16}
                                            className='cursor-pointer ms-2 mt-0'
                                            onClick={() => {
                                              if (!isDisable) handleDownload(Bucket + file?.fileURL)
                                            }}
                                            style={isDisable ? {opacity: '0.6'} : {opacity: '1'}}
                                          />
                                        )}

                                        {isEditTenant && (
                                          <img
                                            src={redCross}
                                            height={15}
                                            width={15}
                                            className='cursor-pointer ms-2 mt-1'
                                            onClick={() => {
                                              const values = [...tenantDeletedDocuments]
                                              console.log(values)
                                              values[index].fileList[ind].isChecked = false
                                              setTenantDeletedDocuments(values)
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </>
                              )
                            })}
                          </div>
                        ) : (
                          <div className='d-flex justify-content-center'>
                            {' '}
                            <div className=''>
                              {' '}
                              {/* <h2 className='head-text'>No Files...</h2> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </>
              )
            })}
          </div>
        </div>
      </Modal>

      {showTerm && (
        <TerminateTenancy
          show={showTerm}
          handleClose={() => setShowTerm(false)}
          id={tenancyData?._id}
          startDate={tenancyData?.duration?.start_date}
          endDate={tenancyData?.duration?.end_date}
          fetchData={() => getTenancyData(tenancyId)}
          unitId={tenancyData?.unitId}
        />
      )}

      {/* Message modal */}
      <div
        className={showMessageCard ? 'message' : 'd-none'}
        style={{width: '400px', height: '600px', zIndex: '20', right: '0px'}}
      >
        <div
          className='card m-0 p-0 br-10'
          style={{
            overflow: 'hidden',
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
          }}
        >
          <h3
            className='m-0 d-flex align-items-center msg_title'
            style={{justifyContent: 'space-between'}}
          >
            Message
            <span className='mx-1' style={{cursor: 'pointer'}}>
              <img src={closes} alt='' onClick={closeMessageCard} />
            </span>
          </h3>
          <div className='p-5'>
            {showChat == 'chat' ? (
              <>
                <div
                  className='symbol-group symbol-hover py-5 m-0'
                  style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
                >
                  <div className='symbol-group symbol-hover mx-5'>
                    {/* <img
                      src={leftarrow}
                      width='18px'
                      onClick={() => gotoChatList()}
                      style={{cursor: 'pointer'}}
                    /> */}
                  </div>

                  {defaultRoomData?.userList?.slice(0, 4).map((el: any, i: any) => (
                    <div className='symbol-group symbol-hover m-0'>
                      <div className='symbol symbol-40px symbol-circle '>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              {el.firstName} {el.lastName}
                            </Tooltip>
                          }
                          placement='bottom'
                        >
                          {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                          <img alt='' src={profileImg}></img>
                        </OverlayTrigger>
                      </div>
                    </div>
                  ))}
                  {defaultRoomData?.userList?.length > 4 ? (
                    <a
                      href='#'
                      className='symbol symbol-45px symbol-circle'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_view_users'
                    >
                      <span
                        className='symbol-label fs-8 fw-bold green_color'
                        data-bs-toggle='tooltip'
                        data-bs-trigger='hover'
                        data-bs-original-title='View more users'
                        data-kt-initialized='1'
                        onClick={() => {
                          setShowChat('ChatRoomUserList')
                          // setmodalopen(true)
                          // console.log('\nsetmodalopen(true)', modalopen)
                        }}
                      >
                        +{defaultRoomData?.userList?.length - 4}
                      </span>
                    </a>
                  ) : null}
                  {room_id?.userList?.length == 1 ? (
                    <h4 className='m-5'>
                      {room_id?.userList[0]?.firstName} {room_id?.userList[0]?.lastName}
                    </h4>
                  ) : (
                    <>
                      {tenanttData &&
                        tenanttData?.map((tnt: any) => (
                          <h4 className='m-5 '>
                            {tnt.firstName} {tnt.lastName}
                          </h4>
                        ))}
                    </>
                  )}
                </div>
                <h6
                  className='d-flex gap-5 mx-5 align-items-center pb-5'
                  style={{borderBottom: '1px solid #aaa'}}
                >
                  <label htmlFor='subject'>Subject</label>
                  {defaultRoomData?.subjectTitle}
                  {/* <input
                    type='text'
                    className='form-control form-control-solid mr-5'
                    name='subject'
                    id='subject'
                    style={{backgroundColor: 'white'}}
                    value={defaultRoomData?.subjectTitle || ''}
                    disabled={defaultRoomData?.subjectTitle ? true : false}
                    onChange={(e: any) => changeSubject(e)}
                  /> */}
                </h6>
                <div
                  style={{marginBottom: '5rem', flex: 1, overflowY: 'auto', height: '350px'}}
                  ref={bottomRef}
                  onScroll={(e: any) => userScroll(e)}
                >
                  {messages
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                    )
                    ?.map((msg: any) => (
                      <div
                        className={
                          `${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'
                        }
                      >
                        <Card
                          style={{
                            maxWidth: '70%',
                            margin: '10px',
                            alignSelf:
                              `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                          }}
                        >
                          <Card.Body
                            className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'rec_msg br-5 p-0 m-0'
                                : 'send_msg br-5 p-0 m-0'
                            }
                          >
                            <p
                              className='p-0 m-0 small_font'
                              style={{paddingRight: '20px !important'}}
                            >
                              {/* {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName} */}
                            </p>
                            <div className='px-3 pt-0'>
                              <h4 className='m-0 p-0 py-2'>{msg.message}</h4>
                            </div>
                          </Card.Body>
                          <Card.Footer
                            className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'p-0 text-start'
                                : 'p-0 text-end'
                            }
                            style={{backgroundColor: 'transparent', borderTop: '0px'}}
                          >
                            {' '}
                            <span className='m-0 small_font' style={{fontWeight: '400 !important'}}>
                              {dateTotime(msg?.createdAt)}
                            </span>
                          </Card.Footer>
                        </Card>
                      </div>
                    ))}
                </div>
                <div style={{position: 'absolute', bottom: 25, width: '90%'}} className='px-0'>
                  <Form onSubmit={handleSubmitt}>
                    <InputGroup>
                      <Form.Control
                        type='text'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Button
                        variant='primary'
                        type='submit'
                        className='btn-green'
                        disabled={!message}
                      >
                        <img src={send} width='24px' />
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </>
            ) : showChat == 'ChatRoomUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('chat')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {defaultRoomData?.userList?.length > 0
                      ? defaultRoomData?.userList?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImg}></img>
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'message' ? (
              <>
                <input
                  type='text'
                  placeholder='Search'
                  className='form form-control m-0 msg_search'
                />
                <img
                  src={filter}
                  alt=''
                  width='24px'
                  className='filter'
                  onClick={() => setShowFilterCard(!showFilterCard)}
                />
                <ul className={showFilterCard ? 'text-center m-0 p-0 card filter_drop' : 'd-none'}>
                  {/* <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    All
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Unread
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Tenants
                  </li>
                  <li className='p-1' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Internal
                  </li> */}
                </ul>
                <div
                  className='d-flex align-items-center my-5'
                  style={{justifyContent: 'space-between'}}
                >
                  <p
                    className='btn btn-sm fw-bold btn-primary btn-green m-0 align-items-center d-flex gap-2'
                    onClick={() => {
                      // setNewMessageModal(true)
                      setDefaultRoomData(false)
                      setShowChat('newMessage')
                      setMessages([])
                    }}
                  >
                    <img src={plus} /> New Message
                  </p>
                  {/* <div className='d-flex gap-3'>
                    <p className='m-0 cus-line' onClick={viewall}>
                      View All
                    </p>
                  </div> */}
                </div>
                <ul
                  className='notification min_height p-0 m-0'
                  ref={myDivRef}
                  onScroll={(e: any) => userScroll(e)}
                >
                  <>
                    {chatData.map((chat: any, index: any) => (
                      <li
                        className='d-flex align-items-center gap-5 p-5 lis'
                        style={{borderBottom: '1px solid #aaaaaa50'}}
                        onClick={(e: any) => openMessage(e, index, chat)}
                      >
                        {chat?.type == 1 ? (
                          <div className='symbol-group symbol-hover flex-nowrap'>
                            {chat?.userList?.slice(0, 2).map((el: any, i: any) => (
                              <div className='symbol-group symbol-hover m-0'>
                                <div className='symbol symbol-45px symbol-circle '>
                                  {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                  <img alt='' src={profileImg}></img>
                                </div>
                              </div>
                            ))}

                            {chat?.userList?.length > 2 ? (
                              <a
                                href='#'
                                className='symbol symbol-45px symbol-circle'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_view_users'
                              >
                                <span
                                  className='symbol-label fs-8 fw-bold green_color'
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  data-bs-original-title='View more users'
                                  data-kt-initialized='1'
                                  onClick={() => {
                                    setShowChat('messageUserList')
                                  }}
                                >
                                  +{chat?.userList?.length - 2}
                                </span>
                              </a>
                            ) : null}
                          </div>
                        ) : (
                          <div className='symbol symbol-45px symbol-circle '>
                            {/* <img alt='Pic' src='media/avatars/300-23.jpg' /> */}
                            <img alt='' src={profileImg}></img>
                          </div>
                        )}
                        <div className='grid-auto-new'>
                          <div className='d-flex flex-column'>
                            {chat?.type !== 1 ? (
                              <h6 className='m-0'>
                                {chat?.userList[0]?.firstName} {chat?.userList[0]?.lastName}
                              </h6>
                            ) : null}
                            <h6 className='m-0'>{chat.subjectTitle}</h6>
                          </div>
                          <div className='d-flex flex-column'>
                            <p className='m-0 small_grey'>
                              {/* {moment(chat.createdAt).format('HH:MM')} */}
                              {timeConvert(chat?.createdAt)}
                            </p>
                            <p className='m-0 small_grey'></p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </>
                </ul>
              </>
            ) : showChat == 'messageUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {chatData?.userList?.length > 0
                      ? chatData?.userList?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImg}></img>
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'newMessage' ? (
              <>
                <div
                  className='symbol-group symbol-hover p-5 m-0'
                  style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
                >
                  {totalArr?.length > 0 ? (
                    <>
                      <div className='symbol-group symbol-hover mx-5'>
                        {/* <img
                          src={leftarrow}
                          width='14px'
                          onClick={() => {
                            setShowChat('message')
                            getChatList()
                            settotalArr([])
                          }}
                          style={{cursor: 'pointer'}}
                        /> */}
                      </div>

                      {totalArr?.slice(0, 4).map((el: any, i: any) => (
                        <div className='symbol-group symbol-hover m-0'>
                          <div className='symbol symbol-40px symbol-circle '>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  {el.firstName} {el.lastName}
                                </Tooltip>
                              }
                              placement='bottom'
                            >
                              {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                              <img alt='' src={profileImg} />
                            </OverlayTrigger>
                          </div>
                        </div>
                      ))}

                      {totalArr?.length > 4 ? (
                        <a
                          href='#'
                          className='symbol symbol-40px symbol-circle'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_view_users'
                        >
                          <span
                            className='symbol-label fs-8 fw-bold green_color'
                            data-bs-toggle='tooltip'
                            data-bs-trigger='hover'
                            data-bs-original-title='View more users'
                            data-kt-initialized='1'
                            onClick={() => {
                              setShowChat('newMessageUserList')
                            }}
                          >
                            +{totalArr?.length - 4}
                          </span>
                        </a>
                      ) : null}
                    </>
                  ) : (
                    <div className='d-flex align-items-center '>
                      <div
                        className='symbol-group symbol-hover p-5 px-0 m-0'
                        style={{background: '#fff', borderRadius: '10px 10px 0 0'}}
                      >
                        <div className='symbol-group symbol-hover me-5 ms-0'>
                          {/* <img
                        src={leftarrow}
                        width='20px'
                        onClick={() => setShowChat('message')}
                        style={{cursor: 'pointer'}}
                      /> */}
                        </div>
                        {tenanttData &&
                          tenanttData?.map((tnt: any) => (
                            <div className='symbol-group symbol-hover m-0'>
                              <div className='symbol symbol-40px symbol-circle '>
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {tnt.firstName} {tnt.lastName}
                                    </Tooltip>
                                  }
                                  placement='bottom'
                                >
                                  {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                  <img alt='' src={profileImg} />
                                </OverlayTrigger>
                              </div>
                            </div>
                          ))}
                      </div>

                      <h6 className='m-0 p-0'>
                        {/* <a
                          className='btn btn-sm fw-bold btn-primary btn-green m-0 align-items-center d-flex gap-2'
                          onClick={() => {
                            setShowChat('addNew')
                          }}
                        >
                          Select Recipient
                        </a> */}
                        {tenanttData &&
                          tenanttData?.map((tnt: any) => (
                            <h4 className='m-5 '>
                              {tnt.firstName} {tnt.lastName}
                            </h4>
                          ))}
                      </h6>
                    </div>
                  )}
                </div>
                <h6
                  className='d-flex gap-5 mx-5 align-items-center pb-5'
                  style={{borderBottom: '1px solid #aaa'}}
                >
                  <label htmlFor='subject'>Subject</label>
                  {bodyWithSubject.subjectTitle}
                  {/* <input
                    type='text'
                    className='form-control form-control-solid mr-5'
                    name='subject'
                    id='subject'
                    value={bodyWithSubject.subjectTitle}
                    onChange={(e: any) => changeSubject(e)}
                  /> */}
                </h6>
                <div
                  style={{flex: 1, overflowY: 'auto', height: '350px', marginBottom: '50px'}}
                  ref={bottomRef}
                >
                  {messages
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                    )
                    ?.map((msg: any, index: any) => (
                      <div
                        className={
                          `${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'
                        }
                      >
                        <Card
                          style={{
                            maxWidth: '70%',
                            margin: '10px',
                            alignSelf:
                              `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                          }}
                        >
                          <Card.Body
                            className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'rec_msg br-5 p-0 m-0'
                                : 'send_msg br-5 p-0 m-0'
                            }
                          >
                            <p
                              className='p-0 m-0 small_font'
                              style={{paddingRight: '20px !important'}}
                            >
                              {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName}
                            </p>
                            <div className='px-5 pb-5 pt-0'>
                              <h4 className='m-0 p-0 pt-5'>{msg.message}</h4>
                            </div>
                          </Card.Body>
                          <span className='m-0 small_font'>{dateTotime(msg?.createdAt)}</span>
                        </Card>
                      </div>
                    ))}
                </div>
                <div
                  style={{position: 'absolute', bottom: 25, width: '90%'}}
                  className='px-0 mx-auto'
                >
                  <Form onSubmit={handleSubmitt}>
                    <InputGroup>
                      <Form.Control
                        type='text'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Button
                        variant='primary'
                        type='submit'
                        className='btn-green'
                        disabled={!message}
                      >
                        <img src={send} width='24px' />
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </>
            ) : showChat == 'newMessageUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    {/* <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                      style={{cursor: 'pointer'}}
                    /> */}
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {totalArr?.length > 0
                      ? totalArr?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem', width: '100%'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                {/* <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} /> */}
                                <img alt='' src={profileImg}></img>
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'addNew' ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  className='p-0'
                >
                  <div className='new_add'>
                    <span
                      className=''
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                    >
                      <img src={leftarrow} />
                    </span>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 mx-auto fs-4 fw-semibold mb-n2 scr '>
                      <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'All' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('All')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'All' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          All
                        </a>
                      </li>
                      <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'Internal' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('Internal')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'Internal' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          Internal
                        </a>
                      </li>
                      <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'Tenants' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('Tenants')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'Tenants' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          Tenants
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='internal' style={{overflowY: 'scroll', width: '90%'}}>
                    <input
                      type='text'
                      placeholder='Search'
                      className='form form-control m-0 msg_search_chat '
                      value={name}
                      onChange={(e: any) => searchFromAll(e.target.value, chatTab, searchPage)}
                    />
                    {chatTab == 'All' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {allDataPopup?.length ? (
                                allDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('all')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : chatTab == 'Internal' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {internalDataPopup?.length ? (
                                internalDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('internal')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : chatTab == 'Tenants' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {tenantDataPopup?.length ? (
                                tenantDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('internal')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={approvalWorkflowCard}
        onHide={() => {
          setApprovalWorkflowCard(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-0 px-lg-0'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            // minHeight: '150px',
          }}
        >
          <div className='my-1 pb-1 row mx-3 mt-3'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setApprovalWorkflowCard(false)
                }}
              >
                X
              </label>
            </div>
          </div>

          <div className='py-5 mt-0 pt-1 px-7'>
            <div className='d-flex'>
              <h2 className='top ps-0'>
                <b>Approval Workflow</b>
              </h2>
              <h3 className='ms-auto head-text pe-0' style={{fontWeight: '600'}}>
                <b>
                  {' '}
                  <span
                    className={
                      approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 3
                        ? 'select-color ms-5'
                        : 'labl-gry ms-5'
                    }
                    style={{fontWeight: '700'}}
                  >
                    {approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 0
                      ? 'Pending Submission'
                      : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 1
                      ? 'Submitted'
                      : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2
                      ? 'Pending Approval'
                      : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 3
                      ? 'Approved'
                      : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 4
                      ? 'Sent Back'
                      : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 5
                      ? 'Reassigned'
                      : ''}
                  </span>
                </b>
              </h3>
            </div>

            {approvalUsers?.length > 0 && (
              <div className='table-responsive' style={{maxHeight: '500px'}}>
                <table className='table align-start fs-6 gy-5 ' id='kt_ecommerce_sales_table'>
                  <thead>
                    <th className='text-start min-w-150px test'>User</th>
                    <th className='text-start min-w-100px'>Role</th>
                    <th className='text-start min-w-150px'>Status</th>
                    <th className='text-start min-w-150px'>Comments</th>
                  </thead>
                  <tbody className='fw-semibold text-gray-600'>
                    {approvalUsers?.length > 0 &&
                      approvalUsers?.map((app: any, ind: any) => {
                        return (
                          <>
                            <tr>
                              <td className='text-start ps-0'>
                                <div className='d-flex'>
                                  <div className='head-text'>
                                    <p
                                      className='user-head head-text pe-2 mb-0'
                                      style={{fontWeight: '600'}}
                                    >
                                      {app?.createdBy?.[0]?.firstName}{' '}
                                      {app?.createdBy?.[0]?.lastName}
                                    </p>
                                    <p className='labl-gry mb-2'>({app?.createdBy?.[0]?.email})</p>
                                  </div>
                                </div>
                              </td>
                                <td className='text-start ps-0'>
                                            <div className='head-text'>
                                              <p
                                                className='user-head pe-2 mb-0 ps-1'
                                                style={{fontWeight: '600'}}
                                              >
                                                {app?.isRootUser &&
                                                !app?.isApprovalRequest &&
                                                !app?.isSubmitFinance  &&
                                                !app?.isFinanceApprovalRequest
                                                  ? 'Root (Leasing Officer)'
                                                  : !app?.isRootUser &&
                                                    !app?.isApprovalRequest &&
                                                    !app?.isSubmitFinance  &&
                                                    !app?.isFinanceApprovalRequest
                                                  ? 'Leasing Officer'
                                                  : app?.isRootUser && app?.isApprovalRequest
                                                  ? 'Root (Leasing Manager)'
                                                  : app?.isRootUser && app?.isSubmitFinance    
                                                  ? 'Root (Finance Officer)'
                                                  : app?.isRootUser &&
                                                    app?.isFinanceApprovalRequest
                                                  ? 'Root (Finance Manager)'
                                                  : !app?.isRootUser && app?.isApprovalRequest
                                                  ? 'Leasing Manager'
                                                  : !app?.isRootUser && app?.isSubmitFinance 
                                                  ? 'Finance Officer'
                                                  : !app?.isRootUser &&
                                                    app?.isFinanceApprovalRequest
                                                  ? 'Finance Manager'
                                                  : app?.isRootUser
                                                  ? 'Root User'
                                                  : ''}
                                              </p>
                                            </div>
                                          </td>

                              <td className='text-start ps-0'>
                                <div className='head-text'>
                                  <p className='user-head pe-2 mb-0' style={{fontWeight: '600'}}>
                                    {app?.approvalStatus === 0
                                      ? 'Pending Submission'
                                      : app?.approvalStatus === 1
                                      ? 'Submitted'
                                      : app?.approvalStatus === 2 
                                      ? 'Pending Approval'
                                      : app?.approvalStatus === 3
                                      ? 'Approved'
                                      : app?.approvalStatus === 4
                                      ? 'Sent Back'
                                      : app?.approvalStatus === 5
                                      ? 'Reassigned'
                                      : ''}
                                  </p>
                                  <p className='mb-2'>
                                    <i>
                                      {app?.approvalStatusAt
                                        ? `${moment(app?.approvalStatusAt).format(
                                            'DD.MM.YYYY - HH:mm A'
                                          )} `
                                        : ''}
                                    </i>
                                  </p>
                                </div>
                              </td>
                              <td className='text-start ps-0 pe-3'>
                                <textarea
                                  className='form-control form-control-solid mytest'
                                  placeholder='---'
                                  name='description'
                                  value={app?.comment}
                                  onBlur={() => {}}
                                  disabled
                                />{' '}
                              </td>
                            </tr>
                          </>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ViewEditTenancy
