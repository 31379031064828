import React, { createContext, useReducer, ReactNode, useContext, Dispatch } from 'react';

// Define State Type
interface State {
  annualLeaseActiveTab: string;
  searchName: string;
  searchStatus: string[];
  searchType: string[];
}

// Initial State
const initialState: State = {
  annualLeaseActiveTab: '',
  searchName: '',
  searchStatus: [],
  searchType: [],
};

// Define Action Types
type Action =
  | { type: 'SET_ANNUAL_LEASE_ACTIVE_TAB'; payload: string}
  | { type: 'SET_NAME'; payload: string }
  | { type: 'SET_STATUS'; payload: string[] }
  | { type: 'SET_TYPE'; payload: string[] }

  | { type: 'RESET' };

// Reducer Function
const appReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_ANNUAL_LEASE_ACTIVE_TAB':
      return { ...state, annualLeaseActiveTab: action.payload };
    case 'SET_NAME':
      return { ...state, searchName: action.payload };
    case 'SET_STATUS':
      return { ...state, searchStatus: action.payload };
    case 'SET_TYPE':
      return { ...state, searchType: action.payload };
    case 'RESET':
      return initialState;
    default:
      return state;
  }
};

// Context and Provider
interface AppContextProps {
  state: State;
  dispatch: Dispatch<Action>;
}

const AppContext = createContext<AppContextProps | null>(null);

export const AppProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

// Custom hook for accessing state globally
export const useAppState = (): AppContextProps => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppState must be used within an AppProvider');
  }
  return context;
};
