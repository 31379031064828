import {createSlice} from '@reduxjs/toolkit'

export const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    requestorId: false,
    comefrom: false,
    selectedUnit: false,
    unitIDfromProperties: false,
    chatScreen: false,
    chatDataSearch: [],
    chatComeFrom: false,
    chatSelectedDataList: [],

    createNewService: false,
  },
  reducers: {
    setRequestorId: (state, action) => {
      state.requestorId = action.payload
    },
    setComefrom: (state, action) => {
      state.comefrom = action.payload
    },
    setSelectedUnit: (state, action) => {
      state.selectedUnit = action.payload
    },
    setUnitIDfromProperties: (state, action) => {
      state.unitIDfromProperties = action.payload
    },
    setChatScreen: (state, action) => {
      state.chatScreen = action.payload
    },
    setChatDataSearch: (state, action) => {
      state.chatDataSearch = action.payload
    },
    setChatComeFrom: (state, action) => {
      state.chatComeFrom = action.payload
    },
    setChatSelectedDataList: (state, action) => {
      state.chatSelectedDataList = action.payload
    },

    setCreateNewService: (state, action) => {
      state.createNewService = action.payload
    },
  },
})

export const {
  setRequestorId,
  setComefrom,
  setSelectedUnit,
  setUnitIDfromProperties,
  setChatScreen,
  setChatDataSearch,
  setChatComeFrom,
  setChatSelectedDataList,
  setCreateNewService,
} = counterSlice.actions

export default counterSlice.reducer
