import React from 'react'
import {Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'
import {ApiPut, ApiUpload} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  show: any
  handleClose: any
  setFormData: any
  formData: any
  getApprovalServiceRequest: any
  listData: any
  updateSignature: (newValue: string, checkType: number) => void
  closeBoth: () => void
}

const SignaturePopup = ({
  show,
  handleClose,
  setFormData,
  getApprovalServiceRequest,
  formData,
  listData,
  updateSignature,
  closeBoth
}: ButtonProps) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
  } = useForm<any>()
  const [uploadedFile, setUploadedFile] = React.useState<any>('')
  const navigate = useNavigate()

  const [uploadImg, setUploadImage] = React.useState<any>('')
  const [signToSend, setSignToSend] = React.useState<any>()

  const [isShow, setIsShow] = React.useState<any>(false)

  const uploadFilelHandler = async (e: any) => {
    setIsShow(true)
    setUploadImage(URL.createObjectURL(e.target.files[0]))
    setSignToSend(e.target.files[0])

  }

  const onSubmit = async () => {
  }

  const onApprove = async () => {
    let file = signToSend
    // let fileURL = URL.createObjectURL(file)
    // file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/building', formData)
      .then((res: any) => {setUploadedFile(res?.data?.data?.image)
      updateSignature(res?.data?.data?.image, 2)
      handleClose()}
      )
      .catch((err: any) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
    // const body = {
    //   id: listData?.serviceApprovalRequest[0]?._id,
    //   approvalStatus: 2,
    //   comment: 'TEST',
    //   eSignature: uploadedFile,
    //   writtenSignature: '',
    // }
    // console.log('\nlistData', listData)
    // await ApiPut('corporate/approval_request', body)
    //   .then((res) => {
    //     SuccessToast(res?.data?.message)
    //     getApprovalServiceRequest()
    //     handleClose()
    //     navigate(`/request-management/service`)
    //   })
    //   .catch((err) => ErrorToast(err.message))
  }
  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px written-sign'
        show={show}
        onHide={() => {
         closeBoth()
        }}
        backdrop={true}
        size='sm'
       style={{left: '50%', width:'600px'}}
      >
        <div className='modal-header'>
            <h2>Written Signature</h2>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG
                className='svg-icon-1 text-black'
                path='/media/icons/duotune/arrows/arr061.svg'
              />
            </div>
           </div>
         <div className='modal-body py-lg-7 px-lg-10 '>
         {/* <div className='d-flex justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
              <KTSVG
                className='svg-icon-1 text-black'
                path='/media/icons/duotune/arrows/arr061.svg'
              />
            </div>
          </div> */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='d-flex justify-content-center mb-5'>
              <button
                className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                onClick={() => {
                  onApprove()
                    // handleClose()
                  }}
                  disabled={uploadImg === '' ? true : false}
              >
                Approve & Submit
              </button>
            </div>
            <p className='mb-5'><span className="text-danger">*</span> Your written signature is required to complete this step.</p>
            {
                  isShow === true &&
                  <div className='d-flex justify-content-center align-items-center mb-5' >
                  <img src={uploadImg} style={{width: '50px', height: '50px'}} className='mx-4' />
                  </div>
                }
            {/* <p><b>Written-Signature</b></p> */}
            <div className='d-flex justify-content-center align-items-center'>
            <div>
                <button className='btn btn-sm fw-bold ms-1 btn-primary btn-green hover-none position-relative' onClick={()=>{
                    window.print()
                }}>
                  Print Form
                </button>

              </div>
              <div>
                <button className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none position-relative'>
                  Upload
                  <input
                    type='file'
                    {...register('esignature', {required: true})}
                    className='form-control form-control-solid position-absolute '
                    placeholder=''
                    name='esignature'
                    style={{top: 0, left: 0, width: '100%', height: '100%', opacity: 0}}
                    onChange={(e) => uploadFilelHandler(e)}
                  />
                </button>
                {/* {
                  isShow === true &&
                  <img src={uploadImg} style={{width: '50px', height: '50px'}} className='mx-4' />
                } */}
               
                {/* {errors.esignature && (
                  <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                )} */}
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  )
}

export default SignaturePopup
