import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiGet,
  ApiPost,
  ApiPut,
  Bucket,
} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import swal from 'sweetalert2'
import 'antd/dist/antd.css'
import moment from 'moment'
import backArrow from '../../../../img/back-arrow.png'
import redCross from '../../../../img/remove.png'
import trashImg from '../../../../img/trash.png'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import saveGreenImg from '../../../../img/save-dark-green.png'
import {DatePicker} from 'antd'
import {MultiSelect} from 'react-multi-select-component'
import {Moment} from 'moment'
import './style.scss'
import addGreen from '../../../../img/add.png'
import ExpenseItems from './ExpenseItems'
import PaymentRecords from './PaymentRecords'
import OtherAttachments from './OtherAttachments'
import PaymentReminders from './PaymentReminders'
import SelectPayeeModal from './SelectPayeeModal'
import AddInvoice from './AddInvoice'
import downloadIcon from '../../../../img/download.png'
import greenPen from '../../../../img/pen.png'
import AttachmentPreview from '../../General/AttachmentsPreview'
import axios from 'axios'
import {Controller, useForm} from 'react-hook-form'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import fileIcon from '../../../../img/file.svg'
import videoImg from '../../../../img/video.png'
import pngImg from '../../../../img/png.png'

const ViewEditExpense = () => {
  const {flg, type, id, tenancyId, unitId} = useParams()
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()

  const [isSkeleton, setIsSkeleton] = useState<any>(false)
  const [isLoading, setIsLoading] = useState<any>(false)

  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const [showSelectPayee, setShowSelectPayee] = useState<any>(false)
  const [showInvoiceAdd, setShowInvoiceAdd] = useState<any>(false)

  const [expenseAPIDetails, setExpenseAPIDetails] = useState<any>()

  const [isUpdateGlobal, setIsUpdateGlobal] = useState<any>(false)

  const [expenseData, setExpenseData] = useState<any>({
    payeeData: {
      type: 3,
      details: '',
    },
    dueDate: null,
    paymentMethod: '',
    paymentAccountId: '',
    notes: '',
    development: '',
    invoices: [],
    items: [],
    attachments: [],
    reminders: [],
    paymentRecord: [],
  })

  const [selectedAccount, setSelectedAccount] = useState<any>([])
  const [accountOptions, setAccountOptions] = useState<any>([])

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<any>([])
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<any>([
    {
      label: 'Bank Transfer',
      value: '2',
    },
    {
      label: 'Cash',
      value: '3',
    },
    {
      label: 'Cheque',
      value: '1',
    },
  ])

  // 
  const getStatuss = async (val: any) => {
    if (!val) {
      return null // No status when no due date is selected
    }

    const currentDate = new Date()
    const selectedDate = val.toDate()

    let x = currentDate.toLocaleDateString('uk-UA')
    let y = selectedDate.toLocaleDateString('uk-UA')

    if (x === y) {
      return 'Upcoming'
    } else if (selectedDate < currentDate) {
      return 'Overdue'
    } else if (selectedDate > currentDate) {
      return 'Upcoming'
    }

    return null // No status when the due date is today
  }

  // 
  const handleDateChange = async (date: any) => {
    let temp = await getStatuss(date)
    // console.log(temp)
    const values = {...expenseData}

    if (temp == 'Upcoming') {
      values.category = 0
      values.categoryDateTime = new Date().toISOString()

      // console.log(values)
      // setExpenseData(values)
    } else {
      values.category = 1
      values.categoryDateTime = new Date().toISOString()
      // setExpenseData(values)
    }

    if (date != null) {
      // let values = {...expenseData}
      values.dueDate = date.format('YYYY-MM-DD')
      setValue('dueDate', date.format('YYYY-MM-DD'), { shouldValidate: true })
    
      setExpenseData(values)
    } else {
      // let values = {...expenseData}
      values.dueDate = null
      setValue('dueDate', '' , { shouldValidate: true })
      setExpenseData(values)
    }

    if (flg == '2') {
      setTimeout(() => {
        setAutoSaveData(Date.now())
      }, 700)
    }

    setRefreshChild(Date.now())
  }

  // 
  const getAccounts = () => {
    const body = {
      limit: 50,
      page: 1,
    }
    ApiPost(`corporate/payment_account/get`, body)
      .then((res) => {
        let values: any = []

        res?.data?.data?.payment_account_data?.map((acc: any, ind: any) => {
          values[ind] = {
            label: `${acc?.accountNumber} - ${acc?.bankName}`,
            value: acc?._id,
          }

          if (expenseData?.paymentAccountId == acc?._id) setSelectedAccount([values[ind]])
        })

        setAccountOptions(values)
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    getAccounts()
  }, [])

  const [mode, setMode] = useState<any>('add')
  const [selectedInd, setSelectedInd] = useState<any>(-1)
  const [selectedRecord, setSelectedRecord] = useState<any>({})
  const [isViewOnly, setIsViewOnly] = useState<any>(false)

  const [tenantData, setTenantData] = useState<any>(false)

  useEffect(() => {
    setIsSkeleton(true)
    getExpenseById()
  }, [])

  // 
  const getExpenseById = () => {
    ApiGet(`corporate/expense/${id}`)
      .then((res: any) => {
        setExpenseAPIDetails(res?.data?.data)

        setValue('expenseName',res?.data?.data?.name)
        setValue('dueDate',res?.data?.data?.dueDate)
        setValue('accountNumber',res?.data?.data?.paymentAccountId ? res?.data?.data?.paymentAccountId : '')
        setValue('paymentMethod',res?.data?.data?.expenseMethod ? res?.data?.data?.expenseMethod : '')

        let values = {
          name: res?.data?.data?.name,
          payeeData: {
            type: res?.data?.data?.payeeType,
            details: {
              tenant: res?.data?.data?.payeeType == 0 ? res?.data?.data?.tenant : undefined,
              vendor:
                res?.data?.data?.payeeType == 1
                  ? {
                      companyName: res?.data?.data?.vendor?.[0]?.companyName,
                    }
                  : undefined,
              tenancyId: res?.data?.data?.payeeType == 0 ? res?.data?.data?.tenancyId : undefined,
              unit: '',
              developmentId:  res?.data?.data?.communityId != null ? res?.data?.data?.communityId : res?.data?.data?.buildingId
            },
          },
          dueDate: res?.data?.data?.dueDate,
          category: res?.data?.data?.category,
          paymentMethod: res?.data?.data?.expenseMethod,
          paymentAccountId: res?.data?.data?.paymentAccountId,
          notes: res?.data?.data?.note,
          development:
            res?.data?.data?.communityId == null && res?.data?.data?.buildingId == null
              ? undefined
              : [
                  {
                    devType: res?.data?.data?.communityId != null ? 'comm' : 'build',
                    value:
                      res?.data?.data?.communityId != null
                        ? res?.data?.data?.communityId
                        : res?.data?.data?.buildingId,
                  },
                ],
          invoices: res?.data?.data?.invoice,
          items: res?.data?.data?.expense_payment_item,
          attachments: res?.data?.data?.attachments,
          reminders: res?.data?.data?.payment_reminder,
          paymentRecords: [],
          requestDetail: res?.data?.data?.maintenanceServiceRequestId != null ? {} : null,
        }

        // console.log(res?.data?.data?.expense_payment_item)
        // setReminderWithID(res?.data?.data?.payment_reminder)

        res?.data?.data?.expense_payment_item?.map((vl: any, ind: any) => {
           values.items[ind].expenseItemId = vl?.frontendStorage ? vl?.frontendStorage?.expenseItemId : ''
          if (vl?.frontendStorage != undefined){
            console.log( ((Number(vl?.frontendStorage?.level == 2) || Number(vl?.frontendStorage?.level == 3)) &&
            vl?.unitId != null))
            values.items[ind].property = {
              level: vl?.frontendStorage?.level 
                     ? vl?.frontendStorage?.level 
                     : (vl?.unitId != null && vl?.unit?.[0]?.unitType == 2) 
                     ? 3 
                     : (vl?.unitId != null && vl?.unit?.[0]?.unitType != 2) 
                     ? 2 
                     : 1,
              details:
                (((Number(vl?.frontendStorage?.level) == 2) || (Number(vl?.frontendStorage?.level) == 3)) &&
                vl?.unitId != null)
                  ? vl?.unit?.[0]
                  : Number(vl?.frontendStorage?.level) == 1
                  ? vl?.frontendStorage?.details
                  : vl?.unitId != null
                  ? vl?.unit?.[0]
                  : res?.data?.data?.community?.length > 0
                  ? res?.data?.data?.community?.[0]?.name
                  : res?.data?.data?.building?.[0]?.name
                
                  
            }
            // console.log( values.items[ind].property)
          }
          else values.items[ind].property = ''
        })

        let pR: any = []
        res?.data?.data?.expense_payment_record?.map((record: any, ind: any) => {
          pR[ind] = {
            id: record?.id,
            _id: record?._id,
            items: record?.paymentItemList,
            datePaid: record?.datePaid,
            totalAmountPaid: record?.amountPaid,
            receipts: record?.receipt,
            attachments: record?.other,
            notes: record?.note,
            paymentItemListId: record?.paymentItemListId,
            createdAt: record?.createdAt,
            updatedAt: record?.updatedAt,
            amountPaid: record?.amountPaid,
          }

          record?.paymentItemList?.map((itm: any, itmInd: any) => {
            pR[ind].items[itmInd].property = {
              level: itm?.frontendStorage?.level,
              details: itm?.frontendStorage?.details,
            }
          })
        })

        values.paymentRecords = pR

        values.requestDetail =
          res?.data?.data?.maintenanceServiceRequestId != null
            ? {
                _id: res?.data?.data?.maintenance_service_request?.[0]?._id,
                id: res?.data?.data?.maintenance_service_request?.[0]?.id,
              }
            : null

        if (res?.data?.data?.paymentAccountId != null)
          setSelectedAccount([
            {
              value: res?.data?.data?.payment_account?.[0]?._id,
              label: `${res?.data?.data?.payment_account?.[0]?.accountNumber} - ${res?.data?.data?.payment_account?.[0]?.bankName} `,
            },
          ])

        // console.log(values)

        if(values.payeeData.type == 0)
          setTenantData(true)

        if (res?.data?.data?.expenseMethod == 1)
          setSelectedPaymentMethod([
            {
              value: '1',
              label: 'cheque',
            },
          ])
        else if (res?.data?.data?.expenseMethod == 2)
          setSelectedPaymentMethod([
            {
              value: '2',
              label: 'Bank Transfer',
            },
          ])
        else if (res?.data?.data?.expenseMethod == 3)
          setSelectedPaymentMethod([
            {
              value: '3',
              label: 'Cash',
            },
          ])

        setExpenseData(values)

        if(isUpdateGlobal)
        updateGlobal(1, values)

        setIDToDelete([])

        setRefreshChild(Date.now())

        setIsEdit(false)
        setOpen(false)

        // setTimeout(() => {
          setIsSkeleton(false)
        // }, 2000)
      })
      .catch((err:any) => {
        ErrorToast(err?.message)
        setIsSkeleton(false)
      })
  }

  const [idToDelete, setIDToDelete] =  useState<any>([])

  // 
  const addExpenseData = async (saveStatus: any) => {
    // console.log("View Edit Expense")
    // console.log(expenseData)

    let isValid: any = true
    if (saveStatus == 1) {
      isValid = await trigger()
    }
    
    if(isValid){

    let errors: any = false
    let payeeSelectionErr:any = false
    let devSelectionErr:any = false
    let amountLessErr:any = false

    if (saveStatus == 1) {
      expenseData?.items?.map((itm: any, ind: any) => {
        // console.log("/*************** ITEM VALIDATION ******************/")
        let totalAmountOfItem:any=0
        let vatAmnt:any = ((Number(itm?.amount)*Number(itm?.quantity))/100 ) * Number(itm?.VATPercentage)
        totalAmountOfItem = (Number(itm?.amount)*Number(itm?.quantity)) + vatAmnt

        if (
          itm?.name?.trim()?.length === 0 ||
          itm?.amount === '' || itm?.amount === undefined || Number(itm?.amount) == 0 ||
          itm?.quantity === '' || itm?.quantity === undefined ||  Number(itm?.quantity) == 0 ||
          itm?.VATPercentage === '' || itm?.VATPercentage === undefined ||
          itm?.property == '' || itm?.property == undefined || itm?.property === 'undefined' ||  itm?.property?.level == undefined
        ) {
          errors = true
        }

        if(totalAmountOfItem < itm?.amountPaid)
          amountLessErr = true
      })
    }

    if(saveStatus == 1 && expenseData?.payeeData?.type != 1 && expenseData?.payeeData?.type != 0)
      payeeSelectionErr = true

    if(saveStatus == 1 && (expenseData?.development == undefined || expenseData?.development?.length == 0))
      devSelectionErr = true

    if (!errors && !payeeSelectionErr && !devSelectionErr && !amountLessErr) {

    setIsLoading(true)
    setOpen(true)

    let newItems: any = []
    let newItemInd: any = 0
    let updateItems: any = []
    let updateItemInd: any = 0

    expenseData?.items?.map((itm: any, ind: any) => {
      let totalAmountOfItem:any=0
      let vatAmnt:any = ((Number(itm?.amount)*Number(itm?.quantity))/100 ) * Number(itm?.VATPercentage)
      totalAmountOfItem = (Number(itm?.amount)*Number(itm?.quantity)) + vatAmnt

      let totalAmountPaidOfItem:any = 0

        expenseData?.paymentRecords?.map((prRec:any, prInd:any)=>{
          prRec?.items?.map((prItm:any, prItmInd:any)=>{
            if(prItm?.paymentItemListId == itm?.paymentItemListId && prItm?.frontendStorage?.itemUniqueId ==  itm?.frontendStorage?.itemUniqueId)
              totalAmountPaidOfItem =  totalAmountPaidOfItem + prItm?.amountPaid
          })
        })

      if (itm?.id == '') {
        newItems[newItemInd] = {
          paymentItemListId: itm?.paymentItemListId ? itm?.paymentItemListId : null,
          name: itm?.name ? itm?.name : '',
          amount: isNaN(itm?.amount) ? undefined : Number(itm?.amount),
          quantity: isNaN(itm?.quantity) ? undefined : Number(itm?.quantity),
          totalAmount: isNaN(itm?.totalAmount) ? undefined : Number(totalAmountOfItem),
          amountPaid: isNaN(totalAmountPaidOfItem) ? undefined : Number(totalAmountPaidOfItem),
          outstandingAmount: isNaN(itm?.outstandingAmount)
            ? undefined
            : Number(itm?.outstandingAmount),
          VATAmount: Number(vatAmnt),
          VATPercentage: isNaN(itm?.VATPercentage) ? undefined : Number(itm?.VATPercentage),
          // maintenanceServiceJobId: "",
          unitId: Number(itm?.property?.level) != 1 ? itm?.property?.details?._id : undefined,
          clusterId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.cluster?.[0]?._id
              : undefined,
          unitGroupId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.unit_group?.[0]?._id
              : undefined,
          communityId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.community?.[0]?._id
              : expenseData?.development?.[0]?.devType == 'comm'
              ? expenseData?.development?.[0]?.value
              : undefined,
          floorId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.floor?.[0]?._id
              : undefined,
          buildingId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.building?.[0]?._id
              : expenseData?.development?.[0]?.devType == 'build'
              ? expenseData?.development?.[0]?.value
              : undefined,
          frontendStorage: {
            level: itm?.property?.level,
            details: expenseData?.development?.[0]?.label,
            expenseItemId: itm?.expenseItemId,
              itemUniqueId: itm?.frontendStorage?.itemUniqueId
          },
        }
        newItemInd++
      } else {
        updateItems[updateItemInd] = {
          _id: itm?._id,
          name: itm?.name ? itm?.name : '',
          paymentItemListId: itm?.paymentItemListId ? itm?.paymentItemListId : null,
          amount: isNaN(itm?.amount) ? undefined : Number(itm?.amount),
          quantity: isNaN(itm?.quantity) ? undefined : Number(itm?.quantity),
          totalAmount: isNaN(itm?.totalAmount) ? undefined : Number(totalAmountOfItem),
          amountPaid: isNaN(totalAmountPaidOfItem) ? undefined : Number(totalAmountPaidOfItem),
          outstandingAmount: isNaN(itm?.outstandingAmount)
            ? undefined
            : Number(itm?.outstandingAmount),
            VATAmount: Number(vatAmnt),
          VATPercentage: isNaN(itm?.VATPercentage) ? undefined : Number(itm?.VATPercentage),
          // maintenanceServiceJobId: "",
          unitId: Number(itm?.property?.level) != 1 ? itm?.property?.details?._id : undefined,
          clusterId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.cluster?.[0]?._id
              : undefined,
          unitGroupId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.unit_group?.[0]?._id
              : undefined,
          communityId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.community?.[0]?._id
              : expenseData?.development?.[0]?.devType == 'comm'
              ? expenseData?.development?.[0]?.value
              : undefined,
          floorId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.floor?.[0]?._id
              : undefined,
          buildingId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.building?.[0]?._id
              : expenseData?.development?.[0]?.devType == 'build'
              ? expenseData?.development?.[0]?.value
              : undefined,
          frontendStorage: {
            level: itm?.property?.level,
            details: expenseData?.development?.[0]?.label,
            expenseItemId: itm?.expenseItemId,
            itemUniqueId: itm?.frontendStorage?.itemUniqueId
          },
        }
        updateItemInd++
      }
    })

    let newRemind: any = []
    let newRemindInd: any = 0
    let updateRemind: any = []
    let updateRemindInd: any = 0

    // console.log(expenseData?.reminders)

    expenseData?.reminders?.map((rm: any, ind: any) => {
      if (rm?._id) {
        updateRemind[updateRemindInd] = rm
        updateRemindInd++
      } else {
        newRemind[newRemindInd] = rm
        newRemindInd++
      }
    })

    let temp = expenseData?.reminders
    temp?.map((tmp: any, ind: any) => {
      if (temp[ind]?._id) {
        temp[ind]._id = undefined
        temp[ind].id = undefined
      }
    })
    const body = {
      expense: {
        _id: id,
        currency: 'AED',
        name: expenseData?.name ? expenseData?.name : '',
        note: expenseData?.notes ? expenseData?.notes : '',
        // expenseStatus: 0,
        category: expenseData?.category,
        amountPaid: Number(grandPaidAmount),
        outstandingAmount: Number(grandTotalAmount) - Number(grandPaidAmount),
        totalAmount: Number(grandTotalAmount),
        // VATAmount: 0,
        payeeType: Number(expenseData?.payeeData?.type),
        saveStatus: saveStatus,
        saveStatusDateTime: expenseAPIDetails?.saveStatus != saveStatus ? new Date() : undefined,
        // expenseStatusDateTime: new Date(),
        expenseMethod:
          selectedPaymentMethod?.length > 0 ? Number(selectedPaymentMethod?.[0]?.value) : undefined,
        dueDate: expenseData?.dueDate,
        attachments: expenseData?.attachments,
        invoice: expenseData?.invoices,
        tenancyId:
          expenseData?.payeeData?.type == 0 ? expenseData?.payeeData?.details?.tenancyId : null,
        tenantId:
          expenseData?.payeeData?.type == 0
            ? expenseData?.payeeData?.details?.tenant?.[0]?._id
            : null,
        vendorId:
          expenseData?.payeeData?.type == 1 ? expenseData?.payeeData?.details?.vendor?._id : null,
        paymentAccountId: selectedAccount?.length > 0 ? selectedAccount?.[0]?.value : null,
        communityId:
          expenseData?.development?.[0]?.devType == 'comm'
            ? expenseData?.development?.[0]?.value
            : null,
        buildingId:
          expenseData?.development?.[0]?.devType == 'build'
            ? expenseData?.development?.[0]?.value
            : null,
        maintenanceServiceRequestId: expenseData?.requestDetail?._id ? expenseData?.requestDetail?._id : null,
      },
      new_payment_reminder: newRemind,
      update_payment_reminder: [],
      new_expense_payment_item: newItems,
      update_expense_payment_item: updateItems,
      delete_expense_payment_item: idToDelete,
    }
    ApiPost(`corporate/expense/add_all_in_one`, body)
      .then((res) => {

        for (let i = 0; i < expenseData?.paymentRecords?.length; i++) {
          if(expenseData?.paymentRecords[i]?._id)
            updatePaymentRecord(res?.data?.data?._id, expenseData?.paymentRecords[i])
          else
            addPaymentRecord(res?.data?.data?._id, expenseData?.paymentRecords[i])
        }
       
        getExpenseById()
        setIsEdit(false)

        setTimeout(() => {
          setIsLoading(false)
          setOpen(false)
        }, 1500)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setTimeout(() => {
          setIsLoading(false)
          setOpen(false)
        }, 1500)
      })
    }

      else {
        if(payeeSelectionErr)
        ErrorToast("Please select a PAYEE before creating this expense")
        else if(devSelectionErr)
          ErrorToast("Please select a development before creating this expense")
  
        else if (errors)
        ErrorToast("Complete all expense items before creating this expense")
        // ErrorToast('Please select a property for each expense item before creating an expense...')
        else if(amountLessErr){
          swal
          .fire({
            html: `<div class='fnt-700'>Cannot Update Expense </div>
            <div class='mt-4'> The amount paid recorded in this expense exceeds the total amount of the expense items. Edit the total amount 	of       
            your expense item or update your payment record to be able to create this expense..</div>`,
            showConfirmButton: true,
            confirmButtonText: 'Okay',
            showCancelButton: false,
            cancelButtonText: 'No',
            customClass: {
              confirmButton: 'custom-confirm-button',
              cancelButton: 'custom-cancel-button',
              popup: 'custom-popup',
            },
            reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
          })
          .then((res: any) => {
                                                   
          })
  
        }
      }
  }
}

  useEffect(() => {
    if (autoSaveData != false) {
      addExpenseData(1)
    }
  }, [autoSaveData])

  //
  const addPaymentRecord = (expId: any, data: any) => {
    if (!isEdit) setOpen(true)

    let items: any = []
    data?.items?.map((itm: any, ind: any) => {
      items[ind] = {
        paymentItemListId: itm?.paymentItemListId,
        name: itm?.name,
        amount: Number(itm?.amount),
        quantity: Number(itm?.quantity),
        totalAmount: Number(itm?.totalAmount),
        amountPaid: itm?.totalAmountPaid ? Number(itm?.totalAmountPaid) : Number(itm?.amountPaid),
        VATPercentage: Number(itm?.VATPercentage),
        VATAmount: Number(itm?.VATAmount),
        outstandingAmount: Number(itm?.outstandingAmount),
        unitId: Number(itm?.property?.level) != 1 ? itm?.property?.details?._id : undefined,
        clusterId:
          Number(itm?.property?.level) != 1 ? itm?.property?.details?.cluster?.[0]?._id : undefined,
        unitGroupId:
          Number(itm?.property?.level) != 1
            ? itm?.property?.details?.unit_group?.[0]?._id
            : undefined,
        communityId:
          Number(itm?.property?.level) != 1
            ? itm?.property?.details?.community?.[0]?._id
            : expenseData?.development?.[0]?.devType == 'comm'
            ? expenseData?.development?.[0]?.value
            : undefined,
        floorId:
          Number(itm?.property?.level) != 1 ? itm?.property?.details?.floor?.[0]?._id : undefined,
        buildingId:
          Number(itm?.property?.level) != 1
            ? itm?.property?.details?.building?.[0]?._id
            : expenseData?.development?.[0]?.devType == 'build'
            ? expenseData?.development?.[0]?.value
            : undefined,
        frontendStorage: {
          level: itm?.property?.level,
          details:
            Number(itm?.property?.level) == 1
              ? selectedDevelopment?.[0]?.label
              : itm?.property?.details,
          itemUniqueId: itm?.frontendStorage?.itemUniqueId
        },
      }
    })

    const body = {
      _id: data?._id ? data?._id : undefined,
      currency: 'AED',
      note: data?.notes,
      amountPaid: Number(data?.totalAmountPaid),
      datePaid: data?.datePaid,
      // isActive: true,
      // isBlock: false,
      paymentItemList: items,
      receipt: data?.receipts,
      other: data?.attachments,
      expenseId: expId,
    }

    ApiPost(`corporate/expense_payment_record`, body)
      .then((res) => {
        setRefreshChild(Date.now())
        setRefreshForGlobal(true)
        getExpenseById()

      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  // 
  const updatePaymentRecord= (expId: any, data: any) => {
    if (!isEdit) setOpen(true)
    let items: any = []
    data?.items?.map((itm: any, ind: any) => {
      items[ind] = {
        paymentItemListId: itm?.paymentItemListId,
        name: itm?.name,
        amount: Number(itm?.amount),
        quantity: Number(itm?.quantity),
        totalAmount: Number(itm?.totalAmount),
        amountPaid: itm?.totalAmountPaid ? Number(itm?.totalAmountPaid) : Number(itm?.amountPaid),
        VATPercentage: Number(itm?.VATPercentage),
        VATAmount: Number(itm?.VATAmount),
        outstandingAmount: Number(itm?.outstandingAmount),
        unitId: Number(itm?.property?.level) != 1 ? itm?.property?.details?._id : undefined,
        clusterId:
          Number(itm?.property?.level) != 1 ? itm?.property?.details?.cluster?.[0]?._id : undefined,
        unitGroupId:
          Number(itm?.property?.level) != 1
            ? itm?.property?.details?.unit_group?.[0]?._id
            : undefined,
        communityId:
          Number(itm?.property?.level) != 1
            ? itm?.property?.details?.community?.[0]?._id
            : expenseData?.development?.[0]?.devType == 'comm'
            ? expenseData?.development?.[0]?.value
            : undefined,
        floorId:
          Number(itm?.property?.level) != 1 ? itm?.property?.details?.floor?.[0]?._id : undefined,
        buildingId:
          Number(itm?.property?.level) != 1
            ? itm?.property?.details?.building?.[0]?._id
            : expenseData?.development?.[0]?.devType == 'build'
            ? expenseData?.development?.[0]?.value
            : undefined,
        frontendStorage: {
          level: itm?.property?.level,
          details:
            Number(itm?.property?.level) == 1
              ? selectedDevelopment?.[0]?.label
              : itm?.property?.details,
          itemUniqueId: itm?.frontendStorage?.itemUniqueId
        },
      }
    })

    const body = {
      id: data?._id ? data?._id : undefined,
      currency: 'AED',
      note: data?.notes,
      amountPaid: Number(data?.totalAmountPaid),
      datePaid: data?.datePaid,
      // isActive: true,
      // isBlock: false,
      paymentItemList: items,
      receipt: data?.receipts,
      other: data?.attachments,
      expenseId: expId,
    }

    ApiPut(`corporate/expense_payment_record`, body)
      .then((res) => {
        setRefreshForGlobal(true)
        
        setRefreshChild(Date.now())
        getExpenseById()

      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  const [refreshChild, setRefreshChild] = useState<any>(true)
  const [refreshForGlobal, setRefreshForGlobal] = useState<any>(true)

  // 
  const deleteExpense = () => {
    setIsLoading(true)
    const body = {
      ids: [id],
    }
    ApiPost(`corporate/expense/delete`, body)
      .then((res: any) => {
        SuccessToast('Expense has been deleted successfully')
        navigate('/financials-expenses')
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  const [isEdit, setIsEdit] = useState<any>(false)

  let grandTotalAmount: any = 0
  let grandPaidAmount: any = 0

  const [attachmentsForPreview, setAttachmentsForPreview] = useState<any>([])

  useEffect(() => {
    let values: any = []
    {
      expenseData?.invoices?.map((inv: any, index: any) => {
        values[index] = {
          type: '',
          url: `${Bucket}${inv?.URL}`,
          thumbnail: '',
        }

        if (inv?.URL?.split('.').pop() === 'pdf') values[index].type = 'pdf'
        else if (inv?.URL?.split('.').pop() === 'doc' || inv?.URL?.split('.').pop() === 'docx')
          values[index].type = 'doc'
        else if (
          inv?.URL?.split('.').pop() === 'jpeg' ||
          inv?.URL?.split('.').pop() === 'jpg' ||
          inv?.URL?.split('.').pop() === 'png' ||
          inv?.URL?.split('.').pop() === 'svg' ||
          inv?.URL?.split('.').pop() === 'gif'
        )
          values[index].type = 'image'
        else if (
          inv?.URL?.split('.').pop()?.toLowerCase() === 'mp4' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'webm' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'avi' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'mkv' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'mov' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'flv' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'wmv'
        )
          values[index].type = 'video'
        else values[index].type = 'file'
      })
    }

    setAttachmentsForPreview(values)
  }, [expenseData])

  const handleDownload = async (fileUrl: any) => {
    // setIsDisable(true)
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      })

      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: contentType})

      // Determine file extension based on content type
      const extensionMap: {[key: string]: string} = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        // Add more mappings as needed
      }

      const extension = extensionMap[contentType] || 'file'

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `downloaded_file.${extension}`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      // setIsDisable(false)
    } catch (error) {
      // setIsDisable(false)
      ErrorToast('Error downloading file')
    }
  }

  const [selectedDevelopment, setSelectedDevelopment] = useState<any>()

  // Development
  const getDevelopment = () => {
    const body = {
      search: '',
      developmentIds: [],
    }
    ApiPost(`corporate/development/get`, body)
      .then((res: any) => {
        let values: any = []
        let valuesInd: any = 0
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values = {
              label: `${val?.name}`,
              value: `${val?._id}`,
              devType:
                val?.isCommunity == 'true' ? 'comm' : val?.isBuilding == 'true' ? 'build' : '',
            }

            if (expenseData?.development?.[0]?.value == val?._id) {
              // console.log('SELECTED DEVELOPMENT')
              // console.log(values)
              setSelectedDevelopment([values])
            }
            setTimeout(() => {
              setRefreshChild(Date.now())
            }, 300)
          })
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    getDevelopment()
  }, [expenseData])


  const getTenantList = () => {
    const body = {
      search: '',
    }
    ApiPost('corporate/expense_development/get/tenancy', body)
      .then((res: any) => {
        let values: any = {...expenseData}
        res?.data?.data?.map((data:any, ind:any)=>{
          if(expenseData?.payeeData?.type == 0 && expenseData?.payeeData?.details?.tenancyId == res?.data?.data?.[ind]?._id){
            values.payeeData.type = 0
            values.payeeData.details = {
              tenant: data?.tenant,
              tenancyId: data?._id,
              vendor: data,
              developmentId:
                data?.community?.length > 0 ? data?.community?.[0]?._id : data?.building?.[0]?._id,
              unit: data?.unit,
            }

              // values.development = [
              //   {
              //     value:
              //       data?.community?.length > 0
              //         ? data?.community?.[0]?._id
              //         : data?.building?.[0]?._id,
              //     label:
              //       data?.community?.length > 0
              //         ? data?.community?.[0]?.name
              //         : data?.building?.[0]?.name,
              //     devType: data?.community?.length > 0 ? 'comm' : 'build',
              //   },
              // ]

            setExpenseData(values)

          }

        })
      })
      .catch((err: any) => {})
    }


  useEffect(()=>{
    if(tenantData)
    getTenantList()

  }, [tenantData])

  // 
  const updateGlobal = async (saveStatus: any, expenseData:any) => {
    setIsLoading(true)
    setOpen(true)

    let newItems: any = []
    let newItemInd: any = 0
    let updateItems: any = []
    let updateItemInd: any = 0

    expenseData?.items?.map((itm: any, ind: any) => {
      if (itm?.id == '') {
        newItems[newItemInd] = {
          paymentItemListId: itm?.paymentItemListId ? itm?.paymentItemListId : null,
          name: itm?.name ? itm?.name : '',
          amount: isNaN(itm?.amount) ? undefined : Number(itm?.amount),
          quantity: isNaN(itm?.quantity) ? undefined : Number(itm?.quantity),
          totalAmount: isNaN(itm?.totalAmount) ? undefined : Number(itm?.totalAmount),
          amountPaid: isNaN(itm?.totalAmountPaid) ? undefined : Number(itm?.totalAmountPaid),
          outstandingAmount: isNaN(itm?.outstandingAmount)
            ? undefined
            : Number(itm?.outstandingAmount),
          VATAmount: isNaN(itm?.VATAmount) ? undefined : Number(itm?.VATAmount),
          VATPercentage: isNaN(itm?.VATPercentage) ? undefined : Number(itm?.VATPercentage),
          // maintenanceServiceJobId: "",
          unitId: Number(itm?.property?.level) != 1 ? itm?.property?.details?._id : undefined,
          clusterId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.cluster?.[0]?._id
              : undefined,
          unitGroupId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.unit_group?.[0]?._id
              : undefined,
          communityId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.community?.[0]?._id
              : expenseData?.development?.[0]?.devType == 'comm'
              ? expenseData?.development?.[0]?.value
              : undefined,
          floorId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.floor?.[0]?._id
              : undefined,
          buildingId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.building?.[0]?._id
              : expenseData?.development?.[0]?.devType == 'build'
              ? expenseData?.development?.[0]?.value
              : undefined,
          frontendStorage: {
            level: itm?.property?.level,
            details: expenseData?.development?.[0]?.label,
            expenseItemId: itm?.expenseItemId
          },
        }
        newItemInd++
      } else {
        updateItems[updateItemInd] = {
          _id: itm?._id,
          name: itm?.name ? itm?.name : '',
          paymentItemListId: itm?.paymentItemListId ? itm?.paymentItemListId : null,
          amount: isNaN(itm?.amount) ? undefined : Number(itm?.amount),
          quantity: isNaN(itm?.quantity) ? undefined : Number(itm?.quantity),
          totalAmount: isNaN(itm?.totalAmount) ? undefined : Number(itm?.totalAmount),
          amountPaid: isNaN(itm?.totalAmountPaid) ? undefined : Number(itm?.totalAmountPaid),
          outstandingAmount: isNaN(itm?.outstandingAmount)
            ? undefined
            : Number(itm?.outstandingAmount),
          VATAmount: isNaN(itm?.VATAmount) ? undefined : Number(itm?.VATAmount),
          VATPercentage: isNaN(itm?.VATPercentage) ? undefined : Number(itm?.VATPercentage),
          // maintenanceServiceJobId: "",
          unitId: Number(itm?.property?.level) != 1 ? itm?.property?.details?._id : undefined,
          clusterId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.cluster?.[0]?._id
              : undefined,
          unitGroupId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.unit_group?.[0]?._id
              : undefined,
          communityId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.community?.[0]?._id
              : expenseData?.development?.[0]?.devType == 'comm'
              ? expenseData?.development?.[0]?.value
              : undefined,
          floorId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.floor?.[0]?._id
              : undefined,
          buildingId:
            Number(itm?.property?.level) != 1
              ? itm?.property?.details?.building?.[0]?._id
              : expenseData?.development?.[0]?.devType == 'build'
              ? expenseData?.development?.[0]?.value
              : undefined,
          frontendStorage: {
            level: itm?.property?.level,
            details: expenseData?.development?.[0]?.label,
            expenseItemId: itm?.expenseItemId
          },
        }
        updateItemInd++
      }
    })

    let newRemind: any = []
    let newRemindInd: any = 0
    let updateRemind: any = []
    let updateRemindInd: any = 0

    // console.log(expenseData?.reminders)

    expenseData?.reminders?.map((rm: any, ind: any) => {
      if (rm?._id) {
        updateRemind[updateRemindInd] = rm
        updateRemindInd++
      } else {
        newRemind[newRemindInd] = rm
        newRemindInd++
      }
    })

    let temp = expenseData?.reminders
    temp?.map((tmp: any, ind: any) => {
      if (temp[ind]?._id) {
        temp[ind]._id = undefined
        temp[ind].id = undefined
      }
    })

    let grandTotal : any = 0
    let grandPaid: any = 0

    {expenseData?.items?.map((itm: any, ind: any) => {
      grandTotal = Number(grandTotal) + Number(itm?.totalAmount)
    })}

    {/* PAID AND OUTSTANDING AMOUNT */}
    {expenseData?.paymentRecords?.map((rc: any, ind: any) => {
      grandPaid = Number(grandPaid) + Number(rc?.totalAmountPaid)
    })}

    const body = {
      expense: {
        _id: id,
        currency: 'AED',
        name: expenseData?.name ? expenseData?.name : '',
        note: expenseData?.notes ? expenseData?.notes : '',
        // expenseStatus: 0,
        category: expenseData?.category,
        amountPaid: Number(grandPaid),
        outstandingAmount: Number(grandTotal) - Number(grandPaid),
        totalAmount: Number(grandTotal),
        // VATAmount: 0,
        payeeType: Number(expenseData?.payeeData?.type),
        saveStatus: saveStatus,
        saveStatusDateTime: expenseAPIDetails?.saveStatus != saveStatus ? new Date() : undefined,
        // expenseStatusDateTime: new Date(),
        expenseMethod:
          selectedPaymentMethod?.length > 0 ? Number(selectedPaymentMethod?.[0]?.value) : undefined,
        dueDate: expenseData?.dueDate,
        attachments: expenseData?.attachments,
        invoice: expenseData?.invoices,
        tenancyId:
          expenseData?.payeeData?.type == 0 ? expenseData?.payeeData?.details?.tenancyId : null,
        tenantId:
          expenseData?.payeeData?.type == 0
            ? expenseData?.payeeData?.details?.tenant?.[0]?._id
            : null,
        vendorId:
          expenseData?.payeeData?.type == 1 ? expenseData?.payeeData?.details?.vendor?._id : null,
        paymentAccountId: selectedAccount?.length > 0 ? selectedAccount?.[0]?.value : null,
        communityId:
          expenseData?.development?.[0]?.devType == 'comm'
            ? expenseData?.development?.[0]?.value
            : null,
        buildingId:
          expenseData?.development?.[0]?.devType == 'build'
            ? expenseData?.development?.[0]?.value
            : null,
        maintenanceServiceRequestId: expenseData?.requestDetail?._id ? expenseData?.requestDetail?._id : null,
      },
      new_payment_reminder: newRemind,
      update_payment_reminder: [],
      new_expense_payment_item: newItems,
      update_expense_payment_item: updateItems,
      delete_expense_payment_item: idToDelete,
    }
    ApiPost(`corporate/expense/add_all_in_one`, body)
      .then((res) => {

        setIsEdit(false)

        setRefreshForGlobal(false)

        setTimeout(() => {
          setIsLoading(false)
          setOpen(false)
        }, 1500)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setTimeout(() => {
          setIsLoading(false)
          setOpen(false)
        }, 1500)
      })
}

const intervalRef = useRef<NodeJS.Timer | null>(null); 

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const checkboxes = document.querySelectorAll('.property-test .multi-select-container .rmsc .item-renderer input[type=checkbox]');
      // console.log(checkboxes)
      checkboxes.forEach((checkbox) => {
        checkbox.setAttribute('type', 'radio');
      });
    }, 100);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  // 
  const handleImageClick = (val:any, valSrc:any) => {
    const groupName = `group_invoices`

    const image = document.getElementById(val)
    if (image) {
      image.setAttribute('data-src', valSrc)
      image.setAttribute('data-fancybox', groupName) 
    }

      expenseData?.invoices?.map((receipt: any, idx: any) => {
        const imgeNext = document.getElementById(`clickedInvoice${idx}`)
        if (imgeNext) {
          imgeNext.setAttribute('data-src', `${Bucket}${receipt?.URL}`)
          imgeNext.setAttribute('data-fancybox', groupName)
         
          // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
        }

         // Trigger the fancybox on the clicked image
        // if (imgeNext) {
        //   imgeNext.click()
        // }
      })
  }

  return (
    <>
      {!isSkeleton ? (
        <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
           <form onSubmit={handleSubmit((data: any, e: any) =>  {
            // addExpenseData(1)
            })}>
          <div id='' className='pt-0 mt-0'>
            {' '}
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <div className='d-flex align-items-center'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => navigate('/financials-expenses')}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`${
                    isEdit ? 'Edit Expense' : 'Expense'
                  }`}</h2>
                </div>

                {!isEdit ? (
                  <div className='d-flex ms-auto'>
                    <img
                      src={trashImg}
                      height={18}
                      width={18}
                      className='cursor-pointer'
                      onClick={() => {
                        swal
                          .fire({
                            html: ` 
                            <div class='fs-3'> <b>Delete Expense</></div>
                             <div class='fs-5 mt-4'>You are about to delete the expense.</div>
                            <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                            // icon: 'warning',
                            showConfirmButton: true,
                            confirmButtonColor: '#35475e',
                            confirmButtonText: 'Yes',
                            showCancelButton: true,
                            cancelButtonText: 'No',
                            cancelButtonColor: '#fff',
                            customClass: {
                              confirmButton: 'custom-confirm-button',
                              cancelButton: 'custom-cancel-button',
                              popup: 'custom-popup',
                            },
                            reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                          })
                          .then((res: any) => {
                            if (res.isConfirmed) {
                              deleteExpense()
                            }
                          })
                      }}
                    />
                    <img
                      src={greenPen}
                      height={18}
                      width={18}
                      className='ms-5 cursor-pointer'
                      onClick={() => setIsEdit(true)}
                    />
                  </div>
                ) : (
                  <div className='d-flex ms-auto'>
                    <button
                      type='button'
                      className='btn btn-sm fw-bold  ms-3 red-hollow-btn ps-3'
                      disabled={isLoading || isViewOnly}
                      onClick={() => {
                        getExpenseById()
                      }}
                    >
                      <img src={redCross} height={18} width={18} className='me-4' /> Cancel
                    </button>

                    <button
                      type='button'
                      className='btn btn-sm fw-bold ms-3 green-hollow-btn ps-3'
                      onClick={() => {
                        addExpenseData(1)
                      }}
                      disabled={isLoading}
                    >
                      <img src={saveGreenImg} height={18} width={18} className='me-4' /> {'  '}Save
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* GRAND TOTAL AMOUNT */}
            {expenseData?.items?.map((itm: any, ind: any) => {
              grandTotalAmount = Number(grandTotalAmount) + Number(itm?.totalAmount)
            })}
            {/* PAID AND OUTSTANDING AMOUNT */}
            {expenseData?.paymentRecords?.map((rc: any, ind: any) => {
              grandPaidAmount = Number(grandPaidAmount) + Number(rc?.totalAmountPaid)
            })}
            {/* Details Card */}
            <div className='card card-flush py-5 mt-5'>
              <div className='row mx-1'>
                {/* left section */}
                <div className='col-6 px-5'>
                  <div className='row'>
                    {/* Expense Details */}
                    <div className='col-12 pe-5 ps-0 mt-3'>
                      <div className='row me-4 ms-0 mt-0'>
                        <div className='col-8 px-5'>
                          <h2 className='top ps-0'>
                            <b>Expense Details</b>
                          </h2>
                        </div>
                        <div className='col-4'>
                          {expenseAPIDetails?.saveStatusDateTime ? (
                            <div className=' me-2'>
                              <div
                                className={
                                  expenseAPIDetails?.saveStatus == 0
                                    ? 'status draft-st ms-5  status-w-120'
                                    : 'status create-st ms-5 status-w-120'
                                }
                              >
                                {expenseAPIDetails?.saveStatus == 0 ? 'Draft' : 'Created'}
                              </div>
                              <i style={{paddingRight: '3px', paddingLeft: '18px'}}>
                                {' '}
                                {expenseAPIDetails?.saveStatusDateTime
                                  ? `${moment
                                      .utc(expenseAPIDetails?.saveStatusDateTime)
                                      .local()
                                      .format('DD.MM.YYYY - hh:mm A')}`
                                  : ''}
                              </i>
                            </div>
                          ) : (
                            <div className='status not-saved ms-5 status-w-120'>-</div>
                          )}
                        </div>
                      </div>

                      <div className='row me-4 ms-0 mt-4'>
                        <div className='col-12 px-5 d-flex'>
                          {isEdit ? (
                            <>
                              <input
                                {...register('expenseName', {required: true})}
                                type='text'
                                className='form-control form-control-solid mytest tenancy-control bg-white'
                                name='expenseName'
                                value={expenseData?.name}
                                style={{height: '35px', width: '300px'}}
                                placeholder={'Enter Expense Name'}
                                onChange={(e: any) => {
                                  let values = {...expenseData}
                                  values.name = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
    
                                  setExpenseData(values)
                                  if(e.target.value?.trim()?.length != 0)
                                  setValue('expenseName', e.target.value, { shouldValidate: true })
                                }}
                                onBlur={() => {
                                  if (flg == '2') {
                                    setTimeout(() => {
                                      setAutoSaveData(Date.now())
                                    }, 700)
                                  }
                                }}
                                 autoComplete="off"
                              />
                              <h3 className='text-danger mb-0 ms-2'>
                                <b>*</b>
                              </h3>
                            </>
                          ) : (
                            <span className='ms-3'>
                              <b>
                                {expenseData?.name?.trim()?.length > 0 ? expenseData?.name : '-'}
                              </b>
                            </span>
                          )}
                        </div>
                        {isEdit && errors.expenseName && (
                           <div className='col-12 px-5 d-flex'>
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                          </p>
                          </div>
                        )}
                      </div>
                      <div className='row me-4 ms-0 mt-0'>
                        {/* Select Payee */}
                        <div className='col-12 px-5 mt-4'>
                          <div className='d-flex my-4 align-items-center'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '130px'}} className='labl-gry '>
                                {' '}
                                Payee
                              </label>
                            </h4>
                            {isEdit ? (
                              <>
                                {expenseData?.payeeData?.type != 0 &&
                                expenseData?.payeeData?.type != 1 ? (
                                  <div className='expense-date d-flex justify-content-center'>
                                    <button
                                      type='button'
                                      className='btn btn-sm fw-bold  green-submit-btn ps-3 d-flex justify-content-center status-w-200'
                                      onClick={(e: any) => {
                                        setShowSelectPayee(true)
                                      }}
                                      disabled={isLoading || isViewOnly}
                                    >
                                      {/* <img src={submitImg} height={19} width={19} className='me-4' /> {'  '} */}
                                      Select Payee
                                    </button>
                                    <h3 className='text-danger mb-0 ms-2'>
                                      <b>*</b>
                                    </h3>
                                  </div>
                                ) : (
                                  <div className='d-flex expense-date'>
                                    <span className='ms-1'>
                                      <b>
                                        {expenseData?.payeeData?.type == 0 ||
                                        expenseData?.payeeData?.type == '0'
                                          ? `${expenseData?.payeeData?.details?.tenant?.[0]?.firstName} ${expenseData?.payeeData?.details?.tenant?.[0]?.lastName}`
                                          : `${expenseData?.payeeData?.details?.vendor?.companyName}`}
                                      </b>
                                    </span>
                                    <img
                                      src={redCross}
                                      height={16}
                                      width={16}
                                      className='ms-auto cursor-pointer'
                                      onClick={() => {
                                        let values = {...expenseData}
                                        if (Number(values?.payeeData?.type) == 0) {
                                          expenseData?.items?.map((itm: any, ind: any) => {
                                            values.items[ind].property = {
                                              level: undefined,
                                              details: '',
                                            }
                                          })
                                        }
                                        values.payeeData = {
                                          type: undefined,
                                          details: '',
                                        }

                                        setExpenseData(values)

                                        if (flg == '2') {
                                          setTimeout(() => {
                                            setAutoSaveData(Date.now())
                                          }, 700)
                                        }
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            ) : (
                              <span className='ms-1'>
                                <b>
                                  { Number(expenseData?.payeeData?.type) == 0 
                                    ? `${expenseData?.payeeData?.details?.tenant?.[0]?.firstName} ${expenseData?.payeeData?.details?.tenant?.[0]?.lastName}`
                                    : Number(expenseData?.payeeData?.type) == 1
                                    ? `${expenseData?.payeeData?.details?.vendor?.companyName}`
                                    : '-'}
                                </b>
                              </span>
                            )}
                          </div>
                        </div>
                        {/* Due date */}
                        <div className='col-11 col-md-9 px-5 mt-2'>
                          <div className='d-flex my-4 align-items-center'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '130px'}} className='labl-gry '>
                                {' '}
                                Due Date
                              </label>
                            </h4>
                            {isEdit ? (
                              <>
                               <div style={{width: '-webkit-fill-available'}}>
                              <div>
                                <Controller
                                name='dueDate'
                                control={control}
                                rules={{
                                  required: 'this is required',
                                  
                                }}
                                render={({field}) => (
                                <div className='expense-date'>
                                  {expenseData?.dueDate != null ? (
                                    <DatePicker
                                      name='dueDate'
                                      value={moment(new Date(expenseData?.dueDate), 'DD.MM.YYYY')}
                                      format={'DD.MM.YYYY'}
                                      onChange={handleDateChange}
                                      placeholder='Select Due Date'
                                    />
                                  ) : (
                                    <DatePicker
                                      name='dueDate'
                                      onChange={handleDateChange}
                                      placeholder='Select Due Date'
                                    />
                                  )}
                                </div>
                                 )}
                              /> 
                             
                              </div>
                              {isEdit && errors.dueDate && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )} 
                            </div>
                            <h3 className='text-danger mb-0 ms-2'>
                              <b>*</b>
                            </h3>
                                
                              </>
                            ) : (
                              <span className='ms-1'>
                                <b>
                                  {expenseData?.dueDate
                                    ? `${moment
                                        ?.utc(expenseData?.dueDate)
                                        ?.local()
                                        ?.format('DD.MM.YYYY')}`
                                    : `-`}
                                </b>
                              </span>
                            )}
                          </div>
                        </div>
                        <div className='col-11 col-md-9 px-5 mt-2'>
                          <div className='d-flex my-4 align-items-center'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '130px'}} className='labl-gry '>
                                {' '}
                                Account
                              </label>
                            </h4>

                            {isEdit ? (
                              <>
                                  <div className='test expense property-test'>
                              <div
                                className={`multi-select-container ${
                                  selectedAccount?.length === 0
                                    ? 'no-value expense-account'
                                    : 'has-value'
                                }`}
                              >
                                 <Controller
                                name='accountNumber'
                                control={control}
                                rules={{
                                  required: 'This field is required',
                                }}
                                render={({field}) => (
                                <MultiSelect
                                  {...field}
                                  options={accountOptions}
                                  value={selectedAccount}
                                  onChange={(e: any) => {
                                    let newVal: any = e
                                    if (e?.length > 1) newVal = e.slice(1)

                                      setValue('accountNumber', newVal, { shouldValidate: true }); // Trigger validation

                                    setSelectedAccount(newVal)

                                    let values = {...expenseData}
                                    values.paymentAccountId = e.value

                                    

                                    setExpenseData(values)

                                    if (flg == '2') {
                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 700)
                                    }
                                  }}
                                  labelledBy='Type'
                                  hasSelectAll={false}
                                  overrideStrings={{
                                    allItemsAreSelected: `${selectedAccount?.[0]?.label}`,
                                  }}
                                  closeOnChangedValue={true}
                                />)}
                                />
                                 {errors.accountNumber && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )} 
                              </div>
                            </div>
                            <h3 className='text-danger mb-0 ms-2'>
                              <b>*</b>
                            </h3>
                              </>
                            ) : (
                              <span className='ms-1'>
                                <b>{selectedAccount ? selectedAccount?.[0]?.label : `-`}</b>
                              </span>
                            )}
                          </div>
                        </div>

                        <div className='col-11 col-md-9 px-5 mt-2'>
                          <div className='d-flex my-4 align-items-center'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '130px'}} className='labl-gry '>
                                {' '}
                                Payment Method
                              </label>
                            </h4>

                            {isEdit ? (
                              <>
                                 <div className='test expense  property-test'>
                              <div
                                className={`multi-select-container ${
                                  selectedPaymentMethod?.length === 0
                                    ? 'no-value expense-payment-method'
                                    : 'has-value'
                                }`}
                              >
                                 <Controller
                                name='paymentMethod'
                                control={control}
                                rules={{
                                  required: 'This field is required',
                                }}
                                render={({field}) => (
                                <MultiSelect
                                {...field}
                                  options={paymentMethodOptions}
                                  value={selectedPaymentMethod}
                                  onChange={(e: any) => {
                                    let newVal: any = e
                                    if (e?.length > 1) newVal = e.slice(1)
                                    setSelectedPaymentMethod(newVal)

                                    let values = {...expenseData}
                                    values.paymentAccountId = e.value

                                    setValue('paymentMethod', newVal, { shouldValidate: true });

                                    setExpenseData(values)

                                    if (flg == '2') {
                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 700)
                                    }
                                  }}
                                  labelledBy='Type'
                                  hasSelectAll={false}
                                  overrideStrings={{
                                    allItemsAreSelected: `${selectedPaymentMethod?.[0]?.label}`,
                                  }}
                                  closeOnChangedValue={true}
                                />)}
                                />
                                {errors.paymentMethod && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )} 
                              </div>
                            </div>
                            <h3 className='text-danger mb-0 ms-2'>
                              <b>*</b>
                            </h3>
                              </>
                            ) : (
                              <span className='ms-1'>
                                <b>
                                  {selectedPaymentMethod ? selectedPaymentMethod?.[0]?.label : `-`}
                                </b>
                              </span>
                            )}
                          </div>
                        </div>

                        <div className='col-11 col-md-9 px-5 mt-4'>
                          <h4 className='my-2 mb-3' style={{color: 'black'}}>
                            <label className={`labl-gry`}> Notes</label>
                          </h4>

                          <h4>
                            {isEdit ? (
                              <textarea
                                className='form-control form-control-solid mytest bg-white'
                                placeholder='Enter expense notes'
                                name='message'
                                value={expenseData?.notes}
                                style={{minHeight: '150px'}}
                                onChange={(e: any) => {
                                  let values = {...expenseData}
                                  let newValue:any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
                                  values.notes = newValue
                                  setExpenseData(values)
                                }}
                                onBlur={() => {
                                  if (flg == '2') {
                                    setTimeout(() => {
                                      setAutoSaveData(Date.now())
                                    }, 700)
                                  }
                                }}
                                 autoComplete="off"
                              />
                            ) : (
                              <span className='ms-3'>
                                {expenseData?.notes ? expenseData?.notes : `-`}
                              </span>
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6 px-5 text end'>
                  <div className='row'>
                    {/* Requesy Details */}
                    <div className='col-12 px-5 mt-3'>
                      <div className={'card card-flush py-5 container-xxl mt-2 mb-5 px-6'}>
                        <div className='d-flex align-items-center my-2'>
                          <h2 className='top ps-0'>
                            <b>
                              Total Expenses {'   '}{' '}
                              <span className='ms-5'>
                                {grandTotalAmount
                                  ? `AED ${grandTotalAmount?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}`
                                  : 'AED -'}
                              </span>
                            </b>
                          </h2>

                          <h3 className='ms-auto' style={{color: 'black'}}>
                            <label style={{width: '130px'}} className='labl-gry '>
                              {' '}
                              Expense ID
                            </label>
                            <span className='ms-1'>
                              <b>{expenseAPIDetails?.id ? expenseAPIDetails?.id : '-'}</b>
                            </span>
                          </h3>
                        </div>

                        <div className='d-flex'>
                          <div className='d-block px-0 py-2'>
                            <div className='d-flex my-4 mt-6'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry '>
                                  Paid
                                </label>
                                <span className='ms-1'>
                                  {/* <b> */}
                                  {grandPaidAmount
                                    ? `AED ${grandPaidAmount?.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}`
                                    : 'AED 0'}
                                  {/* </b> */}
                                </span>
                              </h4>
                            </div>

                            <div className='d-flex my-4'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '130px'}} className='labl-gry '>
                                  Outstanding
                                </label>
                                <span className='ms-1'>
                                  {/* <b> */}
                                  {grandTotalAmount
                                    ? `AED ${(grandTotalAmount - grandPaidAmount)?.toLocaleString(
                                        'en-US',
                                        {
                                          minimumFractionDigits: 2,
                                        }
                                      )}`
                                    : 'AED 0'}
                                  {/* </b> */}
                                </span>
                              </h4>
                            </div>
                          </div>

                          <div className='ms-auto me-4'>
                            {expenseAPIDetails?.expenseStatus != null &&
                            expenseAPIDetails?.expenseStatus != undefined ? (
                              <div className='my-6 me-2'>
                                <div
                                  className={
                                    expenseAPIDetails?.expenseStatus === 2
                                      ? 'status pending-approval-st status-w-120'
                                      : expenseAPIDetails?.expenseStatus === 1
                                      ? 'status approve-st status-w-120'
                                      : expenseAPIDetails?.expenseStatus === 0
                                      ? 'status cancelled-st status-w-120'
                                      : 'status pend-st status-w-120'
                                  }
                                >
                                  <>
                                    {expenseAPIDetails?.expenseStatus === 2
                                      ? 'Partially Paid'
                                      : expenseAPIDetails?.expenseStatus === 1
                                      ? 'Paid'
                                      : expenseAPIDetails?.expenseStatus === 0
                                      ? 'Unpaid'
                                      : '-'}
                                  </>
                                </div>
                                <i style={{paddingRight: '3px'}}>
                                  {expenseAPIDetails?.expenseStatusDateTime
                                    ? `${moment
                                        .utc(expenseAPIDetails?.expenseStatusDateTime)
                                        .local()
                                        .format('DD.MM.YYYY - hh:mm A')}`
                                    : '-'}
                                </i>
                              </div>
                            ) : (
                              <div className='mb-7 text-end me-2'>
                                <div className='status not-saved ms-auto mt-10 status-w-120'>-</div>
                                <i style={{paddingRight: '3px'}}>
                                  {' '}
                                  {expenseAPIDetails?.expenseStatusDateTime
                                    ? `${moment
                                        .utc(expenseAPIDetails?.expenseStatusDateTime)
                                        .local()
                                        .format('DD.MM.YYYY - hh:mm A')}`
                                    : '-'}
                                </i>
                              </div>
                            )}

                            {expenseAPIDetails?.categoryDateTime ? (
                              <div className='me-2'>
                                <div
                                  className={
                                    expenseAPIDetails?.category == 0
                                      ? 'status active-bar  status-w-120'
                                      : expenseAPIDetails?.category == 1
                                      ? 'status expired-bar  status-w-120'
                                      : expenseAPIDetails?.category == 2
                                      ? 'status complete-bar  status-w-120'
                                      : 'status  req-bar status-w-120'
                                  }
                                >
                                  {expenseAPIDetails?.category == 0
                                    ? 'Upcoming'
                                    : expenseAPIDetails?.category == 1
                                    ? 'Overdue'
                                    : expenseAPIDetails?.category == 2
                                    ? 'Complete'
                                    : ''}
                                </div>
                                <i style={{paddingRight: '3px'}}>
                                  {' '}
                                  {expenseAPIDetails?.categoryDateTime
                                    ? `${moment
                                        .utc(expenseAPIDetails?.categoryDateTime)
                                        .local()
                                        .format('DD.MM.YYYY - hh:mm A')}`
                                    : ''}
                                </i>
                              </div>
                            ) : (
                              <>
                              <div className='status req-bar  mt-10 status-w-120'>-</div>
                              <div className='mt-2'>-</div>

                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Invoices Card*/}
                    <div className='col-12 px-5 mt-3'>
                      <div
                        className={
                          'card card-flush py-5 container-xxl mt-2  pe-5 mb-5 px-6 min-hg-250'
                        }
                      >
                        <div className='d-flex'>
                          <h2 className='top ps-0'>
                            <b>Invoices</b>
                          </h2>

                          <button
                            type='button'
                            className='btn btn-sm fw-bold  mx-3 px-2 green-hollow-btn ms-auto'
                            onClick={() => {
                              setMode('add')
                              setSelectedRecord({
                                name: '',
                                URL: '',
                              })
                              setShowInvoiceAdd(true)
                            }}
                          >
                            <img src={addGreen} height={18} width={18} className='me-4' /> Add
                            Invoice
                          </button>
                        </div>

                        <div
                          className='table-responsive mt-4'
                          style={{maxHeight: '300px', overflow: 'auto'}}
                        >
                          <table
                            className='table align-middle  fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                                <th className='text-start min-w-100px'></th>
                                <th className='text-start min-w-150px'></th>
                              </tr>
                            </thead>

                            <tbody className='fw-semibold text-gray-600'>
                              {expenseData?.invoices?.map((inv: any, index: any) => {
                                return (
                                  <tr className='cursor-pointer'>
                                    <td className='text-start ps-0'>
                                      <div className='d-flex align-items-center'>
                                        <div className='bullet-item me-3 mb-0'></div>
                                        <p className='mb-0'>{inv?.name}</p>
                                      </div>
                                    </td>
                                    <td className='text-start min-w-100px'>
                                      <div className='d-flex align-items-center'>
                                        <img
                                          data-src={`${Bucket}${inv?.URL}`}
                                          src={
                                            inv?.URL?.split('.')[1] === 'pdf'
                                              ? pdfDocument
                                              : inv?.URL?.split('.').pop() === 'doc' ||
                                                inv?.URL?.split('.').pop() === 'docx'
                                              ? docCocument
                                              : inv?.URL?.split('.').pop() === 'jpeg' ||
                                                inv?.URL?.split('.').pop() === 'jpg' ||
                                                inv?.URL?.split('.').pop() === 'png' ||
                                                inv?.URL?.split('.').pop() === 'svg' ||
                                                inv?.URL?.split('.').pop() === 'gif'
                                              ? pngImg
                                              : inv?.URL?.split('.').pop()?.toLowerCase() === 'mp4' ||
                                                inv?.URL?.split('.').pop()?.toLowerCase() === 'webm' ||
                                                inv?.URL?.split('.').pop()?.toLowerCase() === 'avi' ||
                                                inv?.URL?.split('.').pop()?.toLowerCase() === 'mkv' ||
                                                inv?.URL?.split('.').pop()?.toLowerCase() === 'mov' ||
                                                inv?.URL?.split('.').pop()?.toLowerCase() === 'flv' ||
                                                inv?.URL?.split('.').pop()?.toLowerCase() === 'wmv'
                                              ? ``
                                              : fileIcon
                                          }
                                          height={30}
                                          width={30}
                                          className='ms-3'
                                          id={`clickedInvoice${index}`}
                                          onClick={()=>{handleImageClick(`clickedInvoice${index}`, `${Bucket}${inv?.URL}`)}}
                                          // data-fancybox
                                        />
                                        {/* <a href={`${Bucket}${inv?.URL}`} target='_blank'> */}
                                        <img
                                          src={downloadIcon}
                                          height={18}
                                          width={18}
                                          className='ms-5 cursor-pointer'
                                          onClick={() => {
                                            handleDownload(`${Bucket}${inv?.URL}`)
                                          }}
                                        />
                                        {/* </a> */}
                                        <img
                                          src={greenPen}
                                          height={18}
                                          width={18}
                                          className='ms-5 cursor-pointer'
                                          onClick={() => {
                                            setMode('edit')
                                            setSelectedInd(index)
                                            setSelectedRecord(inv)
                                            setShowInvoiceAdd(true)
                                          }}
                                        />
                                        <img
                                          src={trashImg}
                                          height={18}
                                          width={18}
                                          className='mx-5 cursor-pointer'
                                          onClick={() => {
                                            let values = {...expenseData}
                                            values.invoices.splice(index, 1)

                                            setExpenseData(values)

                                            if (!isEdit) {
                                              setTimeout(() => {
                                                setAutoSaveData(Date.now())
                                              }, 700)
                                            }
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>

                        {attachmentsForPreview && (
                          <AttachmentPreview attachments={attachmentsForPreview} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {expenseData && refreshChild && (
                <ExpenseItems
                  addDevelopment={(val: any) => {
                    // console.log(val)
                    let values: any = {...expenseData}
                    values.development = val
                    setExpenseData(values)

                    setSelectedDevelopment(val)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  expenseData={expenseData}
                  updateParent={(data: any) => {
                    let values: any = {...expenseData}
                    values.items = data
                    setExpenseData(values)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  updateParentAfterDelete={(data: any, idToDlt:any) => {
                    // console.log(idToDlt)
                    let values: any = {...expenseData}
                    values.items = data
                    setExpenseData(values)


                    let deleteIDs:any = [...idToDelete]
                   
                    deleteIDs.push({_id: idToDlt})
                    // console.log( deleteIDs)
                    setIDToDelete(deleteIDs)
                    // if (flg == '2') {
                    //   setTimeout(() => {
                    //     setAutoSaveData(Date.now())
                    //   }, 700)
                    // }
                  }}
                  updateRequest={(data: any, itmVals:any, dev:any) => {
                    let values: any = {...expenseData}
                    values.requestDetail = data
                    values.items = itmVals
                    values.development = dev
                    setExpenseData(values)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  isEdit={isEdit}
                  from={'view-edit'}
                />
              )}

              {expenseData && refreshChild && (
                <PaymentRecords
                  expenseData={expenseData}
                  updateParent={(val: any) => {
                    let values = {...expenseData}
                    values.paymentRecords = val
                    setExpenseData(values)

                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  autoSaveParent={(val: any) => {
                    if (!isEdit) {
                      addPaymentRecord(id, val)
                    }
                  }}
                  autoUpdateParent={(val: any) => {
                    if (!isEdit) {
                      updatePaymentRecord(id, val)
                    }
                  }}
                  selectedDev={selectedDevelopment}
                  updateParentAfterDelete={()=>{
                    setIsUpdateGlobal(true)
                    setTimeout(()=>{
                      getExpenseById()
                    }, 300)
                   
                  }}
                />
              )}

              <div className='row mx-1 d-flex'>
                <div className='col-12 col-md-6' style={{display: 'flex', flexDirection: 'column'}}>
                  {expenseData && refreshChild && (
                    <PaymentReminders
                      expenseData={expenseData}
                      updateParent={(val: any) => {
                        let values = {...expenseData}
                        values.reminders = val
                        setExpenseData(values)

                        if (!isEdit) {
                          setTimeout(() => {
                            setAutoSaveData(Date.now())
                          }, 700)
                        }
                      }}
                    />
                  )}
                </div>
                <div className='col-12 col-md-6' style={{display: 'flex', flexDirection: 'column'}}>
                  {expenseData && (
                    <OtherAttachments
                      expenseData={expenseData}
                      updateParent={(val: any) => {
                        let values = {...expenseData}
                        values.attachments = val
                        setExpenseData(values)

                        if (!isEdit) {
                          setTimeout(() => {
                            setAutoSaveData(Date.now())
                          }, 700)
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          </form>
        </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}

      {/* Add Report Modal */}
      {showSelectPayee && expenseData && (
        <SelectPayeeModal
          show={showSelectPayee}
          handleClose={() => {
            setShowSelectPayee(false)
          }}
          updateDetails={(type: any, data: any) => {
            // console.log(data)
            let values = {...expenseData}
            values.payeeData.type = type
            values.payeeData.details = {
              tenant: data?.tenant,
              tenancyId: data?._id,
              vendor: data,
            }

            if (type == 0)
              values.development = {
                value:
                  data?.community?.length > 0
                    ? data?.community?.[0]?._id
                    : data?.building?.[0]?._id,
                label:
                  data?.community?.length > 0
                    ? data?.community?.[0]?.name
                    : data?.building?.[0]?.name,
                devType: data?.community?.length > 0 ? 'comm' : 'build',
              }

            setExpenseData(values)

            setRefreshChild(Date.now())

            if (flg == '2') {
              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)
            }
          }}
          expData={expenseData}
        />
      )}

      {showInvoiceAdd && mode && (
        <AddInvoice
          show={showInvoiceAdd}
          handleClose={() => setShowInvoiceAdd(false)}
          mode={mode}
          updateData={(data: any) => {
            let values = {...expenseData}
            if (mode == 'add') values.invoices.push(data)
            else values.invoices[selectedInd] = data
            setExpenseData(values)
            setShowInvoiceAdd(false)

            if (!isEdit) {
              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)
            }
          }}
          selectedItem={selectedRecord}
        />
      )}

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default ViewEditExpense
