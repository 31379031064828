import React, {useState} from 'react'
import Chart from 'react-apexcharts'
import {ApexOptions} from 'apexcharts'
import CircularProgress from '@mui/material/CircularProgress'

interface OccupancyChartProps {
  chartData: DataPoint[]
}

interface DataPoint {
  time: string
  overall: {
    percent: number
    value: number
  }
  residential: {
    percent: number
    value: number
  }
  commercial: {
    percent: number
    value: number
  }
}

const OccupancyChartMonthly: React.FC<OccupancyChartProps> = ({chartData}) => {
  const [visibleSeries, setVisibleSeries] = useState({
    overall: true,
    residential: true,
    commercial: true,
  })

  const [isSkeleton, setIsSkeleton] = useState<any>(false)

  const handleCheckboxChange = (seriesName: keyof typeof visibleSeries) => {
    setIsSkeleton(true)

    setVisibleSeries((prevState) => ({
      ...prevState,
      [seriesName]: !prevState[seriesName],
    }))

    setTimeout(() => {
      setIsSkeleton(false)
    }, 300)
  }

  const seriesColors: Record<string, string> = {
    overall: '#39394c',
    residential: '#00b9a9',
    commercial: '#0b6c6a',
  }

  const seriesStrokes = {
    overall: 4,
    residential: 0,
    commercial: 0,
  }

  const seriesData = {
    overall: [] as number[],
    residential: [] as number[],
    commercial: [] as number[],
  }

  const categories: string[] = chartData?.map((dataPoint) => dataPoint?.time)

  chartData?.forEach((dataPoint) => {
    seriesData?.overall?.push(dataPoint?.overall?.percent)
    seriesData?.residential?.push(dataPoint?.residential?.percent)
    seriesData?.commercial?.push(dataPoint?.commercial?.percent)
  })

  const series = [
    {
      name: 'Overall',
      data: seriesData?.overall,
      color: seriesColors?.overall,
      stroke: {
        dashArray: seriesStrokes?.overall,
      },
    },
    {
      name: 'Residential',
      data: seriesData?.residential,
      color: seriesColors?.residential,
      stroke: {
        dashArray: seriesStrokes?.residential,
      },
    },
    {
      name: 'Commercial',
      data: seriesData?.commercial,
      color: seriesColors?.commercial,
      stroke: {
        dashArray: seriesStrokes?.commercial,
      },
    },
  ].filter((s) => visibleSeries[s.name.toLowerCase() as keyof typeof visibleSeries])

  // Compute dashArray based on visibleSeries
  const dashArray = [
    visibleSeries.overall ? 4 : 0,
    visibleSeries.residential ? 0 : 0,
    visibleSeries.commercial ? 0 : 0
  ].filter((_, index) => visibleSeries[Object.keys(visibleSeries)[index] as keyof typeof visibleSeries]);


  const maxPercentage = Math?.max(
    ...chartData?.map((dataPoint) =>
      Math?.max(
        dataPoint?.overall?.percent,
        dataPoint?.residential?.percent,
        dataPoint?.commercial?.percent
      )
    )
  )

  const yAxisMax = Math?.ceil(maxPercentage / 10) * 10

  const options: ApexOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
      },
      selection: {
        enabled: true,
      },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
    },
    stroke: {
      curve: 'smooth',
      dashArray: dashArray,
    },
    title: {
      text: 'Occupancy (%)',
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: '800',
      },
    },
    grid: {
      show: false,
    },
    xaxis: {
      categories: categories,
      type: 'datetime',
      labels: {
        format: 'MMM-yy',
      },
    },
    yaxis: {
      title: {
        style: {
          fontSize: '14px',
          fontWeight: 'bold',
          color: '#666',
        },
        offsetY: -20,
      },
      labels: {
        formatter: (value: number) => `${Number?.isInteger(value) ? value : value?.toFixed(0)}%`,
      },
      min: 0,
      max: yAxisMax == 0 ? 25 : yAxisMax,
      tickAmount: yAxisMax == 0 ? 5 : yAxisMax >= 50 ? yAxisMax/10 : yAxisMax / 5,
    },
    tooltip: {
      x: {
        format: 'MMM yyyy',
      },
      custom: function ({seriesIndex, dataPointIndex, w}) {
        const dataPoint: any = chartData[dataPointIndex]
        const date = new Date(categories[dataPointIndex])?.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
        })

        const visibleSeriesKeys = Object.keys(visibleSeries).filter(
          (key) => visibleSeries[key as keyof typeof visibleSeries]
        )
        let tooltipHTML = `<div class="apexcharts-tooltip-custom "><div style="text-align:center;" class="text-decoration-underline">${date}</div>`

        visibleSeriesKeys.forEach((seriesName) => {
          const series: any = seriesName as keyof DataPoint
          const percent: any = dataPoint[series]?.percent
          const value: any = dataPoint[series]?.value

          tooltipHTML += `<div style="display: flex; ;">
                            <div style="font-weight:bold"><span style="color: ${
                              seriesColors[series]
                            }">&#9679;</span> ${
            Number?.isInteger(percent) ? percent : percent?.toFixed(1)
          }%</div>
                            <div>(${value} Units)</div>
                          </div>`
        })

        tooltipHTML += `</div>`
        return tooltipHTML
      },

      //   y: {
      //     formatter: (value: number, { seriesIndex, dataPointIndex, w }) => {
      //         const dataPoint: any = chartData[dataPointIndex];
      //         const actualValue: any = dataPoint[w.globals.seriesNames[seriesIndex].toLowerCase() as keyof DataPoint]?.value;
      //         return `${value ? value.toFixed(1) : 0}% (${actualValue} Units)`;
      //     },
      //   },
    },
    legend: {
      show: false,
    },
  }

  return (
    <div className='chart-container'>
      <div className='legend-container d-flex w-100'>
        <div className=' d-flex align-items ms-auto'>
          {Object?.keys(visibleSeries)?.map((seriesName) => (
            <label
              key={seriesName}
              style={{marginRight: '15px', display: 'flex', alignItems: 'center'}}
            >
              <input
                type='checkbox'
                className='form-check-input me-2'
                checked={visibleSeries[seriesName as keyof typeof visibleSeries]}
                onChange={() => handleCheckboxChange(seriesName as keyof typeof visibleSeries)}
              />
              <span
                style={{
                  // display: 'inline-block',
                  width: '12px',
                  height: '12px',
                  borderRadius: '50%',
                  backgroundColor: seriesColors[seriesName as keyof typeof seriesColors],
                  marginRight: '5px',
                }}
              ></span>
              {seriesName?.charAt(0)?.toUpperCase() + seriesName?.slice(1)}
            </label>
          ))}
        </div>
      </div>
      {!isSkeleton ? (
        <Chart options={options} series={series ? series : []} type='line' height={350} />
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '350px'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}
    </div>
  )
}

export default OccupancyChartMonthly
