import React, {useCallback, useEffect, useState} from 'react'
import backArrow from '../../../../../../../../../img/back-arrow.png'
import addWhiteIcon from '../../../../../../../../../img/add-white.png'
import noData from '../../../../../../../../../img/NoData1.svg'
import removeIcon from '../../../../../../../../../img/remove.png'
import tickIcon from '../../../../../../../../../img/onboard-tick.png'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {Row, Col} from 'react-bootstrap'
import {ApiPost} from '../../../../../../../../../apiCommon/helpers/API/ApiData'
import RentDetailsLayout from './RentDetailLayout'
import PercentageInput from '../../../../../../components/PercentageInput'
import {FormProvider, useForm} from 'react-hook-form'
import {formatAmount, preventExpAndSign} from '../../../../../../../../../Utilities/utils'

const UnitCard: React.FC<{
  icon: any
  name: string
  stateObj: any
  navigate: any
  unitCount: number
  configurationCount: number
}> = ({name, unitCount, icon, configurationCount, stateObj, navigate}) => (
  <Col md={12}>
    <div
      className='card card-flush gap-5 p-3 ms-3 cursor-pointerlight-gry-dark-shadow cursor-pointer lease-setting-table-card mt-4 mb-10'
      onClick={() => {
        // if (path) navigate(path)
      }}
    >
      <div className='card-body p-1'>
        <div className='d-flex align-items-center mb-2'>
          <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4 ps-4'>{name}</p>
          {icon && <img className='ms-auto' src={icon} alt='Icon' width='30px' />}
        </div>

        {/* Units */}
        <div className='d-flex align-items-center mb-1'>
          <p className='page-heading m-0 labl-gry light-dark-theme-color fnt-600 fs-4 ps-4 status-w-130'>Units</p>
          <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4 ps-4'>{unitCount}</p>
        </div>
        {/* configured units */}
        <div className='d-flex align-items-center'>
          <p className='page-heading m-0 labl-gry light-dark-theme-color fnt-600 fs-4 ps-4 status-w-130'>Configured</p>
          <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4 ps-4'>{configurationCount}</p>
        </div>
      </div>
    </div>
  </Col>
)

const RentUnitList = () => {
  const navigate = useNavigate()
  const {leaseType, id, developmentId, furnitureType, unitType} = useParams()
  const location = useLocation()
  const passedState = location.state
  const [unitList, setUnitList] = useState<any>([])
  const [configType, setConfigType] = useState<any>('')
  const [totalRent, setTotalRent] = useState<any>(null)

  const [checkedValues, setCheckedValues] = useState<any>([])
  let totalSelected: any = 0

  const [unitCountObj, setUnitCountObj] = useState<any>({
    totalUnit: 0,
    configurationCount: 0,
  })

  const [currentUnitCountObj, setCurrentUnitCountObj] = useState<any>({
    totalUnit: 0,
    configurationCount: 0,
  })
  // const [checkFilled, setCheckFilled] = useState<any>(false)
  // const methods = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  // console.log(passedState)

  const getDevelopmentList = async () => {
    let body: any = {
      showFurnishingTypes: false,
      showUnitTypes: false,
      showBedroomTypes: false,
      showUnitList: true,
      leaseType: 0,
      // buildingDevelopmentIds: [developmentId],
      furnishingTypes: [Number(furnitureType)],
      unitTypes: [Number(unitType)],
      bedrooms: [Number(passedState?.bedrooms)],
      propertyTypes:[0],
      leaseChargeListIds:[id]
    }

    if (passedState?.isBuilding) {
      body = {
        ...body,
        buildingDevelopmentIds: [developmentId],
      }
    } else if(passedState?.isCommunity || (passedState?.isCommunity && passedState?.isUnitGroup)) {
      body = {
        ...body,
        communityIds: [developmentId],
        clusterIds: [passedState?.ids?.clusterId],
      }

      if (passedState?.isUnitGroupInMixedCluster) {
        body = {
          ...body,
          unitGroupIds: [passedState?.ids?.unitGroupId],
        }
      } else if (passedState?.isCommunityBuilding || passedState?.isBuildingCluster)  {
        body = {
          ...body,
          buildingIds: [passedState?.ids?.buildingId],
        }
      }
    }

    try {
      const response = await ApiPost('corporate/lease_fee_list/rent/unit_list', body)
      // console.log(response?.data?.data)
      const updatedUnitTypes = response?.data?.data.map((item: any) => {
        return {
          ...item,
          rentFeesConfiguration: {
            total: {
              annual:
                Number(item?.rentFeesConfiguration?.total?.annual) > 0
                  ? item?.rentFeesConfiguration?.total?.annual
                  : null,
              monthly: 0,
              weekly: 0,
              daily: 0,
            },
            daily: {
              annual:
                Number(item?.rentFeesConfiguration?.daily?.annual) > 0
                  ? item?.rentFeesConfiguration?.daily?.annual
                  : null,
              monthly: 0,
              weekly: 0,
              daily: 0,
            },
          },
        }
      })

      let values: any = []
      for (let i = 0; i < updatedUnitTypes?.length; i++) {
        values[i] = false
      }
      setCheckedValues(values)

      setUnitList(updatedUnitTypes)
    } catch (error: any) {
      console.error('Failed to fetch lease types:', error)
      if (error?.message === 'Your account has been suspended!') {
        window.location.pathname = '/auth'
        localStorage.clear()
      }
    }
  }

  const getDevelopmentCount = async () => {
    let body: any = {
      showFurnishingTypes: false,
      showUnitTypes: false,
      showBedroomTypes: false,
      showUnitList: false,
      leaseType: 0,
      onlyConfigurationCount: true,
      // furnishingTypes: [Number(furnitureType)],
      // unitTypes: [Number(unitType)],
      // bedrooms: [Number(passedState?.bedrooms)],
      propertyTypes:[0],
      leaseChargeListIds:[id]
    }
    if (passedState?.isBuilding) {
      body = {
        ...body,
        buildingDevelopmentIds: [developmentId],
      }
    } else if (passedState?.isCommunity || (passedState?.isCommunity && passedState?.isUnitGroup)) {
      body = {
        ...body,
        communityIds: [developmentId],
        clusterIds: [passedState?.ids?.clusterId],
      }

      if (passedState?.isUnitGroupInMixedCluster) {
        body = {
          ...body,
          unitGroupIds: [passedState?.ids?.unitGroupId],
        }
      } else if (passedState?.isCommunityBuilding || passedState?.isBuildingCluster) {
        body = {
          ...body,
          buildingIds: [passedState?.ids?.buildingId],
        }
      }
    }
    try {
      const response = await ApiPost('corporate/lease_fee_list/rent/unit_list', body)
      // console.log(response?.data?.data)

      setUnitCountObj({
        configurationCount: response?.data?.data?.[0]?.configurationCount,
        totalUnit: response?.data?.data?.[0]?.totalUnit,
      })
    } catch (error: any) {
      console.error('Failed to fetch lease types:', error)
      if (error?.message === 'Your account has been suspended!') {
        window.location.pathname = '/auth'
        localStorage.clear()
      }
    }
  }

  const updateConfigration = async () => {
    const bulkUpdate = unitList.map((item: any) => {
      return {
        _id: item?._id,
        rentFeesConfiguration: {
          total: {
            annual: Number(item?.rentFeesConfiguration?.total?.annual),
            monthly: 0,
            weekly: 0,
            daily: 0,
          },
          daily: {
            annual: Number(item?.rentFeesConfiguration?.daily?.annual),
            monthly: 0,
            weekly: 0,
            daily: 0,
          },
        },
      }
    })
    // console.log(unitList)
    const body = {
      bulkUpdate,
    }
    try {
      const response = await ApiPost('corporate/lease_fee_list/rent/bulk_update', body)

      setConfigType('')
      setIsDisabled(true)
      getDevelopmentList()
      getDevelopmentCount()
      getCurrentCount()
      setTotalRent(null)
    } catch (error: any) {
      console.error('Failed to fetch lease types:', error)
      if (error?.message === 'Your account has been suspended!') {
        window.location.pathname = '/auth'
        localStorage.clear()
      }
    }
  }

  const getCurrentCount = async () => {
    let body: any = {
      showFurnishingTypes: false,
      showUnitTypes: false,
      showBedroomTypes: false,
      showUnitList: false,
      leaseType: 0,
      onlyConfigurationCount: true,
      furnishingTypes: [Number(furnitureType)],
      unitTypes: [Number(unitType)],
      bedrooms: [Number(passedState?.bedrooms)],
      propertyTypes:[0],
      leaseChargeListIds:[id]
    }
    if (passedState?.isBuilding) {
      body = {
        ...body,
        buildingDevelopmentIds: [developmentId],
      }
    } else if (passedState?.isCommunity || (passedState?.isCommunity && passedState?.isUnitGroup)){
      body = {
        ...body,
        communityIds: [developmentId],
        clusterIds: [passedState?.ids?.clusterId],
      }

      if (passedState?.isUnitGroupInMixedCluster) {
        body = {
          ...body,
          unitGroupIds: [passedState?.ids?.unitGroupId],
        }
      } else if (passedState?.isCommunityBuilding || passedState?.isBuildingCluster)  {
        body = {
          ...body,
          buildingIds: [passedState?.ids?.buildingId],
        }
      }
    }
    try {
      const response = await ApiPost('corporate/lease_fee_list/rent/unit_list', body)
      // console.log(response?.data?.data)

      setCurrentUnitCountObj({
        configurationCount: response?.data?.data?.[0]?.configurationCount,
        totalUnit: response?.data?.data?.[0]?.totalUnit,
      })
    } catch (error: any) {
      console.error('Failed to fetch lease types:', error)
      if (error?.message === 'Your account has been suspended!') {
        window.location.pathname = '/auth'
        localStorage.clear()
      }
    }
  }

  // const checkIsFilled = useCallback(() => {
  //   return unitList?.some((listItem: any) => !!listItem?.rentFeesConfiguration?.total?.annual)
  // }, [unitList])
  // const isDisabled = !checkIsFilled()
  const [isDisabled, setIsDisabled] = useState<any>(true)

  useEffect(() => {
    // if (configType === 'individualConfigration') {
      // const checkIsFilled = unitList?.some((listItem: any) => !!listItem?.rentFeesConfiguration?.total?.annual)
      // setIsDisabled(!checkIsFilled)
      
    // } else 
    if (configType === 'bulkConfigration') {
      const selectedItem = checkedValues.some((listItem:any) => listItem === true)
      if (totalRent && selectedItem) {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    }
  }, [unitList, configType, totalRent,checkedValues])

  useEffect(() => {
    getDevelopmentList()
    getDevelopmentCount()
    getCurrentCount()
  }, [])

  useEffect(() => {
    let values: any = [...unitList]
    if (!totalRent) return
    for (let i = 0; i < checkedValues?.length; i++) {
      if (checkedValues[i] == true) {
        values[i].rentFeesConfiguration.total.annual = totalRent
      } else {
        // values[i].rentFeesConfiguration.total.annual = totalRent
      }
    }
    setUnitList(values)
    // }
  }, [totalRent, checkedValues])

  console.log('passedState',passedState)

  return (
    <>
      <div className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-4 gap-lg-3'>
          <div className='d-flex align-items-start gap-3'>
            <span
              className='rounded-circle cursor-pointer'
              onClick={() =>
                navigate(
                  `/settings/residential-leasing/annual-leases/rent-fee/${id}/rent-details/${developmentId}/${furnitureType}/${unitType}/bedrooms`,
                  {
                    state: {...passedState, bedrooms: null},
                  }
                )
              }
            >
              <img src={backArrow} height={14} width={14} alt='Back' />
            </span>
            <div>
              <h2 className='page-heading m-0 head-text mb-1'>Rent Configurations</h2>
              <p className='labl-gry fs-4 mb-0 light-dark-theme-color'>
                Setup you rental rates across your property portfolio. Rent configrations will apply to new leases
                created.
              </p>
            </div>
          </div>
        </div>

        {checkedValues?.length > 0 && (
          <>
            {checkedValues?.map((ch: any, ind: any) => {
              if (ch == true) totalSelected = totalSelected + 1
              return <></>
            })}
          </>
        )}

        {/* card */}
        {/* <FormProvider {...methods}> */}
        <div className='card card-flush gap-5 p-5 pt-0 pb-0 mt-5 px-3' style={{minHeight: '75vh'}}>
          <div className='card-body pt-0 py-0 px-3'>
            <Row>
              <Col className='pt-5 d-flex flex-column' style={{position: 'relative'}} md={3}>
                {configType && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                      right: 0,
                      left: 0,
                      backgroundColor: 'rgba(255,255,255,0.5)',
                    }}
                  ></div>
                )}
                <div style={{margin: '12px 0px'}}>
                  <p className='page-heading m-0 white-dark-theme-color fnt-400 fs-4 mb-1'>Development</p>
                  <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4'>
                    {passedState?.name ? passedState?.name : '-'}
                  </p>
                </div>
                <div style={{margin: '12px 0px'}}>
                  <p className='page-heading m-0 white-dark-theme-color fnt-400 fs-4 mb-1'>Furnishing Type</p>
                  <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4'>
                    {passedState?.furnishingType == 0
                      ? 'Unfurnished Properties'
                      : passedState?.furnishingType == 1
                      ? 'Furnished Properties'
                      : '-'}
                  </p>
                </div>
                <div style={{margin: '12px 0px'}}>
                  <p className='page-heading m-0 white-dark-theme-color fnt-400 fs-4 mb-1'>Unit Type</p>
                  <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4'>
                    {passedState?.unitType == 0
                      ? 'Apartments'
                      : passedState?.unitType == 1
                      ? 'Penthouses'
                      : passedState?.unitType == 3
                      ? 'Townhouses'
                      : passedState?.unitType == 4
                      ? 'Villas'
                      : passedState?.unitType == 5
                      ? 'Other - Duplex'
                      : '-'}
                  </p>
                </div>
                <div style={{margin: '12px 0px'}}>
                  <p className='page-heading m-0 white-dark-theme-color fnt-400 fs-4 mb-1'>Bedrooms</p>
                  <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4'>
                    {passedState?.bedrooms ? `${passedState?.bedrooms} Bedrooms` : '-'}
                  </p>
                </div>
                {/* individual && bulk configration */}
                <div
                  className='d-flex flex-column justify-content-center align-items-center'
                  style={{padding: '12px 0px'}}
                >
                  <button
                    type='button'
                    className='btn btn-sm fw-bold blue-btn px-4 justify-content-start status-w-175'
                    style={{marginBottom: '15px'}}
                    onClick={() => {
                      setConfigType('individualConfigration')
                    }}
                  >
                    <img src={addWhiteIcon} height={16} width={16} className='me-2' />
                    Individual Configuration
                  </button>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold lightGray-btn px-4 justify-content-start status-w-175'
                    onClick={() => {
                      setConfigType('bulkConfigration')
                      // unitList?.map((listItem:any) => {})

                      let values: any = []
                      for (let i = 0; i < unitList?.length; i++) {
                        if (unitList[i]?.rentFeesConfiguration?.total?.annual) {
                          values[i] = false
                        } else {
                          values[i] = true
                        }
                      }
                      setCheckedValues(values)
                    }}
                  >
                    <img src={addWhiteIcon} height={16} width={16} className='me-2' />
                    Bulk Configuration
                  </button>
                </div>

                {/* count box */}
                <div
                  className='status-w-170 p-3 mt-10'
                  style={{
                    border: '2px solid lightgrey',
                    borderRadius: '5px',
                    margin: '12px 0px',
                    backgroundColor: '#ffffff',
                  }}
                >
                  <div className='d-flex align-items-center mb-1'>
                    <p className='page-heading m-0 labl-gry light-dark-theme-color fnt-600 fs-4 status-w-110'>Units</p>
                    <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4 text-start'>
                      {currentUnitCountObj?.totalUnit}
                    </p>
                  </div>
                  {/* configured units */}
                  <div className='d-flex align-items-center'>
                    <p className='page-heading m-0 labl-gry light-dark-theme-color fnt-600 fs-4 status-w-110'>
                      Configured
                    </p>
                    <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4'>
                      {currentUnitCountObj?.configurationCount}
                    </p>
                  </div>
                </div>
                <div
                  className='status-w-170 p-3 mb-15'
                  style={{
                    border: '2px solid lightgrey',
                    borderRadius: '5px',
                    margin: '12px 0px',
                    backgroundColor: 'lightgrey',
                  }}
                >
                  <div className='d-flex align-items-center mb-1'>
                    <p className='page-heading m-0 labl-gry light-dark-theme-color fnt-600 fs-4 status-w-110'>Units</p>
                    <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4 text-start'>
                      {unitCountObj?.totalUnit}
                    </p>
                  </div>
                  {/* configured units */}
                  <div className='d-flex align-items-center'>
                    <p className='page-heading m-0 labl-gry light-dark-theme-color fnt-600 fs-4 status-w-110'>
                      Configured
                    </p>
                    <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4'>
                      {unitCountObj?.configurationCount}
                    </p>
                  </div>
                </div>
              </Col>

              <Col className='pt-5' md={9} style={{borderLeft: '2px solid lightgrey', minHeight: '75vh'}}>
                {/* configure and cancle cta */}
                {configType && (
                  <div className='d-flex align-items-center'>
                    {configType === 'bulkConfigration' && (
                      <div className='d-flex align-items-center ms-2'>
                        <label className={`percentage-input-label white-dark-theme-color fnt-500`}>
                          Total Annual Rent
                        </label>
                        <div style={{width: '200px'}} className='ms-6 percentage-input-wrapper'>
                          <input
                            type='number'
                            name={'annualRent'}
                            value={totalRent !== null ? totalRent : ''}
                            onKeyDown={preventExpAndSign}
                            onChange={(e: any) => {
                              const newValue = e?.target?.value
                              if (newValue) {
                                setTotalRent(newValue)
                              } else {
                                setTotalRent(null)
                              }
                              // setIsDisabled(Date?.now())
                            }}
                            className={`form-control form-control-solid  latePaymentInput hide-number-input-arrows ${
                              totalRent ? 'latePaymentInputFilled' : ''
                            } percentage-input`}
                            autoComplete='off'
                            onWheel={(e) => e.currentTarget.blur()}
                          />
                          <span
                            className={`percentage-placeholder-left ${
                              totalRent
                                ? 'percentage-placeholder percentage-placeholder-filled'
                                : 'percentage-placeholder'
                            } ps-3`}
                          >
                            {!totalRent ? `Annual Rent` : ''}
                          </span>
                          <span
                            className={`${
                              totalRent
                                ? 'percentage-placeholder percentage-placeholder-filled'
                                : 'percentage-placeholder'
                            }`}
                            style={{right: '10px'}}
                          >
                            {`AED`}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className='d-flex align-items-center ms-auto'>
                      {/* count */}
                      {configType === 'bulkConfigration' && (
                        <div className='d-flex align-items-center pe-8'>
                          <h3 className='my-2' style={{color: 'black'}}>
                            <label> Selected:</label>
                          </h3>
                          <span className='ms-1 ps-1 my-2 me-4'>
                            <b>{totalSelected}</b>
                          </span>
                        </div>
                      )}
                      {/* buttons */}
                      <button
                        disabled={isDisabled === true}
                        type='button'
                        className='btn btn-sm fw-bold green-submit-btn px-4 justify-content-start  status-w-110'
                        onClick={updateConfigration}
                      >
                        <img src={tickIcon} height={16} width={16} className='me-3' />
                        Configure
                      </button>
                      <button
                        type='button'
                        className='ms-5 btn btn-sm fw-bold red-hollow-btn px-4 justify-content-start status-w-110'
                        onClick={() => {
                          setConfigType('')
                          setIsDisabled(true)
                          getDevelopmentList()
                          getCurrentCount()
                          setTotalRent(null)
                        }}
                      >
                        <img src={removeIcon} height={16} width={16} className='me-3' />
                        Cancel
                      </button>
                    </div>
                  </div>
                )}

                {configType === 'bulkConfigration' && <Row className='separator mx-2 my-3 mt-10'></Row>}
            
                  <table
                    className='table align-middle fs-6 gy-5 table-responsive'
                    id='kt_ecommerce_sales_table'
                    style={{borderCollapse: 'separate', borderSpacing: '0 15px',}}
                  >
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold text-uppercase gs-0'>
                        {configType === 'bulkConfigration' && (
                          <th className='min-w-50px ps-5 test'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='vacant0'
                                name='none'
                                checked={totalSelected == unitList?.length && unitList?.length != 0 ? true : false}
                                onChange={(e: any) => {
                                  let values = [...checkedValues]
                                  // console.log(e.target.checked)
                                  if (e.target.checked) {
                                    for (let i = 0; i < unitList?.length; i++) {
                                      values[i] = true
                                    }
                                  } else {
                                    for (let i = 0; i < unitList?.length; i++) {
                                      values[i] = false
                                      unitList[i].rentFeesConfiguration.daily.annual = null
                                      unitList[i].rentFeesConfiguration.total.annual = null

                                    }
                                  }
                                  setCheckedValues(values)
                                }}
                              />
                            </div>
                          </th>
                        )}
                        <th className='min-w-100px ps-5'>Unit</th>
                        {( passedState?.isBuilding || passedState?.isCommunityBuilding || passedState?.isBuildingCluster) && <th className='min-w-100px'>Floor</th>}
                        <th className='min-w-100px max-w-100px'>Daily Rent</th>
                        <th className='min-w-100px max-w-100px'>Total Annual Rent</th>
                      </tr>
                    </thead>
                    <tbody style={{fontWeight: '500',}} className='table-body'>
                      {unitList?.length > 0 ? (
                        unitList?.map((listItem: any, index: number) => {
                          if (listItem?.rentFeesConfiguration?.total?.annual) {
                            listItem.rentFeesConfiguration.daily.annual =
                              listItem?.rentFeesConfiguration?.total?.annual / 365
                          } else {
                            listItem.rentFeesConfiguration.daily.annual = null
                          }
                          return (
                            <tr
                              key={listItem?._id}
                              style={{
                                background: '#fff',
                                boxShadow: '0px 0px 10px 0px rgba(18, 32, 78, 0.2)',
                                height: '40px',
                              }}
                            >
                              {configType === 'bulkConfigration' && (
                                <td className={`cursor-pointer test  ps-5`}>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      id='vacant0'
                                      name='none'
                                      checked={checkedValues[index] == true ? true : false}
                                      onChange={(e: any) => {
                                        const values = [...checkedValues]
                                        values[index] = !values[index]
                                        if(!e.target.checked){
                                          listItem.rentFeesConfiguration.daily.annual = null
                                          listItem.rentFeesConfiguration.total.annual = null
                                        }
                                        setCheckedValues(values)
                                      }}
                                    />
                                  </div>
                                </td>
                              )}
                              <td className={`cursor-pointer max-w-100px ps-5`}>
                                {listItem?.unitNo ? listItem?.unitNo : '-'}
                              </td>
                              {(passedState?.isBuilding || passedState?.isCommunityBuilding || passedState?.isBuildingCluster) && (
                                <td className='cursor-pointer max-w-100px'>
                                  {listItem?.floor?.[0]?.name ? listItem?.floor?.[0]?.name : '-'}
                                </td>
                              )}
                              <td className='cursor-pointer max-w-100px pe-12'>
                                <span style={{wordBreak:'break-all'}}>
                                  {listItem?.rentFeesConfiguration?.daily?.annual
                                    ? `AED ${formatAmount(listItem?.rentFeesConfiguration?.daily?.annual)}`
                                    : 'AED -'}
                                </span>
                                {/* <span style={{display:'inline-block', marginLeft:'20px'}}>AED</span> */}
                              
                                
                              </td>
                              <td className='cursor-pointer max-w-100px pe-12'>
                                {configType === 'individualConfigration' ? (
                                  <h4 className='m-0 py-2'>
                                    <div className='percentage-input-wrapper'>
                                      <input
                                        type='number'
                                        name={'annualRent'}
                                        value={
                                          listItem?.rentFeesConfiguration?.total?.annual != null ? listItem?.rentFeesConfiguration?.total?.annual
                                            : ''
                                        }
                                        onKeyDown={preventExpAndSign}
                                        onChange={(e: any) => {
                                          const updateUnitList = [...unitList]
                                          const newValue = e?.target?.value
                                          if (newValue) {
                                          //  const greaterValue =  String(newValue).length >= 10 ? `${String(newValue).substring(0,10)}...` : newValue
                                            updateUnitList[index].rentFeesConfiguration.total.annual = newValue
                                          } else {
                                            updateUnitList[index].rentFeesConfiguration.total.annual = null
                                          }
                                          setIsDisabled(Date?.now())
                                          setUnitList(updateUnitList)
                                        }}
                                        className={`form-control form-control-solid  latePaymentInput hide-number-input-arrows  ${
                                          listItem?.rentFeesConfiguration?.total?.annual ? 'latePaymentInputFilled' : ''
                                        } percentage-input rent-input`}
                                        autoComplete='off'
                                        onWheel={(e) => e.currentTarget.blur()}
                                      />
                                      <span
                                        className={`percentage-placeholder-left ${
                                          listItem?.rentFeesConfiguration?.total?.annual
                                            ? 'percentage-placeholder percentage-placeholder-filled'
                                            : 'percentage-placeholder'
                                        } ps-3`}
                                      >
                                        {!listItem?.rentFeesConfiguration?.total?.annual ? `Annual Rent` : ''}
                                      </span>
                                      <span
                                        className={`${
                                          listItem?.rentFeesConfiguration?.total?.annual
                                            ? 'percentage-placeholder percentage-placeholder-filled'
                                            : 'percentage-placeholder'
                                        }`}
                                        style={{right: '10px'}}
                                      >
                                        {`AED`}
                                      </span>
                                    </div>
                                  </h4>
                                ) : (
                                  <div className='d-flex align-items-center'>
                                  
                                     <span style={{wordBreak:'break-all'}}>
                                     {listItem?.rentFeesConfiguration?.total?.annual
                                        ? `AED ${formatAmount(listItem?.rentFeesConfiguration?.total?.annual)}`
                                        : 'AED -'}
                                     </span>
                                     {/* <span style={{display:'inline-block', marginLeft:'20px'}}></span> */}
                                 
                                  </div>
                                )}
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <>
                          <tr>
                            <td colSpan={10} className='text-center'>
                              <img className='text-center' src={noData} alt='' width={400} />
                            </td>
                          </tr>
                        </>
                      )}

                    </tbody>
                  </table>
              </Col>
            </Row>
          </div>
        </div>
        {/* </FormProvider> */}
      </div>
    </>
  )
}

export default RentUnitList
