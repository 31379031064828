import React, {useState, useEffect, useCallback} from 'react'
import {Row, Col} from 'react-bootstrap'
import FlexedInput from '../../../../../../components/FlexedInput'
import FlexedInputFrequency from '../../../../../../components/FlexedInputFrequency'
import DatePicker from 'antd/es/date-picker'
import moment, {Moment} from 'moment'
import {formatAmount} from '../../../../../../../../../Utilities/utils'
import sync from '../../../../../../../../../img/refreshIcon.png'

interface ParentData {
  chrgData: any
  setChrgData: any
  isChrgDataChanged?: any
}

const RecurringPreview = ({chrgData, setChrgData, isChrgDataChanged}: ParentData) => {
  const [previewCalculations, setPreviewCalculations] = useState<any>(false)
  const [previewChargesData, setPreviewChargesData] = useState<any>([])
  const [pickerKey, setPickerKey] = useState(Date.now())

  //  PreviewValidation when amountCalculationType == 1
  const showInputPreview1 =
    chrgData?.type == 1 &&
    chrgData?.amountCalculationType == 1 &&
    chrgData?.feePercentage != null &&
    chrgData?.selectLeaseChargeType?.length > 0 &&
    // chrgData?.VATPercentage != null &&
    chrgData?.minimumAmount != null &&
    chrgData?.frequency?.length > 0 &&
    chrgData?.dueDateType?.length > 0 &&
    chrgData?.autoInvoicingEnabled?.length > 0 &&
    chrgData?.invoicingType != null

  // Frequency ---- Daily/Weekly/Monthly
  const showInputPreview2 =
    showInputPreview1 &&
    (((chrgData?.frequency?.[0]?.value == 1 ||
      chrgData?.frequency?.[0]?.value == 2 ||
      chrgData?.frequency?.[0]?.value == 3) &&
      chrgData?.startApplying?.length > 0 &&
      chrgData?.endApplying?.length > 0) ||
      chrgData?.frequency?.[0]?.value == 0 ||
      chrgData?.frequency?.[0]?.value == 4)

  // based on due date
  const showInputPreview3 =
    (showInputPreview2 &&
      chrgData?.dueDateType?.[0]?.value == 1 &&
      chrgData?.daysAfterChargeIsApplied != null) ||
    (showInputPreview2 &&
      (chrgData?.dueDateType?.[0]?.value == 0 || chrgData?.dueDateType?.[0]?.value == 2))

  // based on Start Appyling When % Received
  const showInputPreview4 =
    (showInputPreview3 &&
      chrgData?.startApplying?.[0]?.value == 1 &&
      chrgData?.startApplyPercentagePaymentReceived != null &&
      chrgData?.startApplySelectChargeLeaseFeeListType?.length > 0) ||
    (showInputPreview3 && chrgData?.startApplying?.[0]?.value == 0) ||
    (showInputPreview3 && chrgData?.startApplying?.[0]?.value == 2) ||
    (showInputPreview3 && chrgData?.frequency?.[0]?.value == 0)

  // based on Stop Appyling until % Received
  const showInputPreviewSections =
    (showInputPreview4 &&
      chrgData?.endApplying?.[0]?.value == 1 &&
      chrgData?.endApplyPercentagePaymentReceived != null &&
      chrgData?.endApplySelectChargeLeaseFeeListType?.length > 0) ||
    (showInputPreview4 && chrgData?.endApplying?.[0]?.value == 0) ||
    (showInputPreview4 && chrgData?.endApplying?.[0]?.value == 2) ||
    (showInputPreview4 && chrgData?.frequency?.[0]?.value == 0) ||
    (showInputPreview4 &&
      chrgData?.frequency?.[0]?.value == 4 &&
      chrgData?.customTimesApplied != null &&
      chrgData?.customFrequencyType?.length > 0)

  {
    /***************** Preview Validation when amountCalculationType == 0 *****************/
  }
  const showInputPreviewFixed1 =
    chrgData?.type == 1 &&
    chrgData?.amountCalculationType == 0 &&
    chrgData?.fixedAmount != null &&
    // chrgData?.VATPercentage != null &&
    chrgData?.frequency?.length > 0 &&
    chrgData?.dueDateType?.length > 0 &&
    chrgData?.autoInvoicingEnabled?.length > 0 &&
    chrgData?.invoicingType != null

  // Frequency ---- Daily/Weekly/Monthly
  const showInputPreviewFixed2 =
    showInputPreviewFixed1 &&
    (((chrgData?.frequency?.[0]?.value == 1 ||
      chrgData?.frequency?.[0]?.value == 2 ||
      chrgData?.frequency?.[0]?.value == 3) &&
      chrgData?.startApplying?.length > 0 &&
      chrgData?.endApplying?.length > 0) ||
      chrgData?.frequency?.[0]?.value == 0 ||
      chrgData?.frequency?.[0]?.value == 4)

  // based on due date
  const showInputPreviewFixed3 =
    (showInputPreviewFixed2 &&
      chrgData?.dueDateType?.[0]?.value == 1 &&
      chrgData?.daysAfterChargeIsApplied != null) ||
    (showInputPreviewFixed2 &&
      (chrgData?.dueDateType?.[0]?.value == 0 || chrgData?.dueDateType?.[0]?.value == 2))

  // based on Start Appyling When % Received
  const showInputPreviewFixed4 =
    (showInputPreviewFixed3 &&
      chrgData?.startApplying?.[0]?.value == 1 &&
      chrgData?.startApplyPercentagePaymentReceived != null &&
      chrgData?.startApplySelectChargeLeaseFeeListType?.length > 0) ||
    (showInputPreviewFixed3 && chrgData?.startApplying?.[0]?.value == 0) ||
    (showInputPreviewFixed3 && chrgData?.startApplying?.[0]?.value == 2) ||
    (showInputPreviewFixed3 && chrgData?.frequency?.[0]?.value == 0)

  // based on Stop Appyling until % Received
  const showInputPreviewFixedSection =
    (showInputPreviewFixed4 &&
      chrgData?.endApplying?.[0]?.value == 1 &&
      chrgData?.endApplyPercentagePaymentReceived != null &&
      chrgData?.endApplySelectChargeLeaseFeeListType?.length > 0) ||
    (showInputPreviewFixed4 && chrgData?.endApplying?.[0]?.value == 0) ||
    (showInputPreviewFixed4 && chrgData?.endApplying?.[0]?.value == 2) ||
    (showInputPreviewFixed4 && chrgData?.frequency?.[0]?.value == 0) ||
    (showInputPreviewFixed4 &&
      chrgData?.frequency?.[0]?.value == 4 &&
      chrgData?.customTimesApplied != null &&
      chrgData?.customFrequencyType?.length > 0)

  console.log(chrgData?.frequency?.[0]?.value)
  console.log(chrgData?.customTimesApplied)
  console.log(chrgData?.customFrequencyType)
  //
  const disablePreviewCTA =
    chrgData.previewData.feeAmount == null ||
    chrgData.previewData.startDate == null ||
    (chrgData?.selectLeaseChargeType?.[0]?.label == 'Single Rent Installment' &&
      (chrgData.previewData.noOfInstallments == null || Number(chrgData.previewData.noOfInstallments) > 12 || Number(chrgData.previewData.noOfInstallments) < 1)) ||
    (chrgData?.endApplying?.[0]?.value == 1 &&
      (chrgData?.frequency?.[0]?.value == 1 ||
        chrgData?.frequency?.[0]?.value == 2 ||
        chrgData?.frequency?.[0]?.value == 3) &&
      chrgData?.previewData?.endDateReceived == null) ||
    (chrgData?.startApplying?.[0]?.value == 1 &&
      (chrgData?.frequency?.[0]?.value == 1 ||
        chrgData?.frequency?.[0]?.value == 2 ||
        chrgData?.frequency?.[0]?.value == 3) &&
      chrgData?.previewData?.startDateReceived == null) ||
    (chrgData?.endApplying?.[0]?.value == 2 &&
      (chrgData?.frequency?.[0]?.value == 1 ||
        chrgData?.frequency?.[0]?.value == 2 ||
        chrgData?.frequency?.[0]?.value == 3) &&
      chrgData?.previewData?.dateStopped == null) ||
    (chrgData?.startApplying?.[0]?.value == 2 &&
      (chrgData?.frequency?.[0]?.value == 1 ||
        chrgData?.frequency?.[0]?.value == 2 ||
        chrgData?.frequency?.[0]?.value == 3) &&
      chrgData?.previewData?.dateApplied == null) ||
    (chrgData?.frequency?.[0]?.value == 0 && chrgData.previewData.noOfInstallments == null) ||
    (chrgData?.startApplying?.[0]?.value == 1 &&
      chrgData?.frequency?.[0]?.value == 4 &&
      chrgData?.previewData?.startDateReceived == null)

  const [showPreviewCharges, setShowPreviewCharges] = useState<any>(false)

  // get Preview Charges Data
  const getPreviewCharges = () => {
    // User wants to configure a Recurring Charge with a %-based Fee that follows their Rent Installments for their Annual Residential Leases.
    if (
      chrgData?.type == 1 &&
      // chrgData?.amountCalculationType == 1 &&
      (chrgData?.frequency?.[0]?.value == 1 ||
        chrgData?.frequency?.[0]?.value == 2 ||
        chrgData?.frequency?.[0]?.value == 3)
    ) {
      const start: any =
        chrgData?.startApplying?.[0]?.value == 0
          ? chrgData?.previewData?.startDate
          : chrgData?.startApplying?.[0]?.value == 1
          ? chrgData?.previewData?.startDateReceived
          : chrgData?.startApplying?.[0]?.value == 2
          ? chrgData?.previewData?.dateApplied
          : null

      const end: any =
        chrgData?.endApplying?.[0]?.value == 0
          ? chrgData?.previewData?.endDate
          : chrgData?.endApplying?.[0]?.value == 1
          ? chrgData?.previewData?.endDateReceived
          : chrgData?.endApplying?.[0]?.value == 2
          ? chrgData?.previewData?.dateStopped
          : null

      const startDate = new Date(start)
      const endDate = new Date(end)

      console.log(startDate)
      console.log(endDate)

      let charges: any = []

      if (startDate > endDate) {
        console.error('Start date cannot be after the end date.')
        return []
      }

      let currentDate = new Date(startDate)

      if (chrgData?.dueDateType?.[0]?.value == 0) {
        while (currentDate <= endDate) {
          switch (chrgData?.frequency?.[0]?.value) {
            case 1: {
              // Daily
              charges.push({
                appliedDate: moment(currentDate).format('YYYY-MM-DD'),
                dueDate: moment(currentDate).format('YYYY-MM-DD'),
                subTotal: Number(chrgData.previewData.feeAmount),
                vatAmount: Number(chrgData.previewData.VATRate),
                totalAmount:
                  Number(chrgData.previewData.feeAmount) + Number(chrgData.previewData.VATRate),
              })

              currentDate.setDate(currentDate.getDate() + 1)
              break
            }
            case 2: {
              // Weekly
              charges.push({
                appliedDate: moment(currentDate).format('YYYY-MM-DD'),
                dueDate: moment(currentDate).format('YYYY-MM-DD'),
                subTotal: Number(chrgData.previewData.feeAmount),
                vatAmount: Number(chrgData.previewData.VATRate),
                totalAmount:
                  Number(chrgData.previewData.feeAmount) + Number(chrgData.previewData.VATRate),
              })

              currentDate.setDate(currentDate.getDate() + 7)
              break
            }
            case 3: {
              // Monthly
              charges.push({
                appliedDate: moment(currentDate).format('YYYY-MM-DD'),
                dueDate: moment(currentDate).format('YYYY-MM-DD'),
                subTotal: Number(chrgData.previewData.feeAmount),
                vatAmount: Number(chrgData.previewData.VATRate),
                totalAmount:
                  Number(chrgData.previewData.feeAmount) + Number(chrgData.previewData.VATRate),
              })
              currentDate.setMonth(currentDate.getMonth() + 1)
              break
            }
            default:
              throw new Error(
                "Invalid frequency. Allowed values are 'daily', 'weekly', or 'monthly'."
              )
          }
        }
      } else if (chrgData?.dueDateType?.[0]?.value == 1) {
        while (currentDate <= endDate) {
          switch (chrgData?.frequency?.[0]?.value) {
            case 1: {
              // Daily
              const dueDate = moment(currentDate)
                .add(chrgData?.daysAfterChargeIsApplied || 0, 'days')
                .format('YYYY-MM-DD')

              charges.push({
                appliedDate: moment(currentDate).format('YYYY-MM-DD'),
                dueDate: dueDate,
                subTotal: Number(chrgData.previewData.feeAmount),
                vatAmount: Number(chrgData.previewData.VATRate),
                totalAmount:
                  Number(chrgData.previewData.feeAmount) + Number(chrgData.previewData.VATRate),
              })

              currentDate.setDate(currentDate.getDate() + 1)
              break
            }
            case 2: {
              // Weekly
              const dueDate = moment(currentDate)
                .add(chrgData?.daysAfterChargeIsApplied || 0, 'days')
                .format('YYYY-MM-DD')

              charges.push({
                appliedDate: moment(currentDate).format('YYYY-MM-DD'),
                dueDate: dueDate,
                subTotal: Number(chrgData.previewData.feeAmount),
                vatAmount: Number(chrgData.previewData.VATRate),
                totalAmount:
                  Number(chrgData.previewData.feeAmount) + Number(chrgData.previewData.VATRate),
              })

              currentDate.setDate(currentDate.getDate() + 7)
              break
            }
            case 3: {
              // Monthly
              const dueDate = moment(currentDate)
                .add(chrgData?.daysAfterChargeIsApplied || 0, 'days')
                .format('YYYY-MM-DD')

              charges.push({
                appliedDate: moment(currentDate).format('YYYY-MM-DD'),
                dueDate: dueDate,
                subTotal: Number(chrgData.previewData.feeAmount),
                vatAmount: Number(chrgData.previewData.VATRate),
                totalAmount:
                  Number(chrgData.previewData.feeAmount) + Number(chrgData.previewData.VATRate),
              })

              currentDate.setMonth(currentDate.getMonth() + 1)
              break
            }
            default:
              throw new Error(
                "Invalid frequency. Allowed values are 'daily', 'weekly', or 'monthly'."
              )
          }
        }
      }

      console.log(charges)
      setPreviewChargesData(charges)
      setShowPreviewCharges(true)
    }

    // User wants to configure a Recurring Charge with a %-based fee that is applied on a Daily / Weekly / Monthly basis for their Annual Residential Leases
    else if (
      chrgData?.type == 1 &&
      // chrgData?.amountCalculationType == 1 &&
      chrgData?.frequency?.[0]?.value == 0
    ) {
      const start: any = chrgData?.previewData?.startDate
      const end: any = chrgData?.previewData?.endDate

      const startDate = new Date(start)
      const endDate = new Date(end)

      const paymentDates = calculateInstallmentDates(
        startDate,
        endDate,
        chrgData?.previewData?.noOfInstallments
      )
      console.log(paymentDates)

      let charges: any = []

      paymentDates?.map((pmDate: any) => {
        charges.push({
          appliedDate: moment(pmDate).format('YYYY-MM-DD'),
          dueDate: moment(pmDate).format('YYYY-MM-DD'),
          subTotal: Number(chrgData.previewData.feeAmount),
          vatAmount: Number(chrgData.previewData.VATRate),
          totalAmount:
            Number(chrgData.previewData.feeAmount) + Number(chrgData.previewData.VATRate),
        })
      })

      setPreviewChargesData(charges)
      setShowPreviewCharges(true)
    }

    // User wants to configure a Recurring Charge with a %-based fee that is applied on a Custom basis for their Annual Residential Leases.
    else if (
      chrgData?.type == 1 &&
      // chrgData?.amountCalculationType == 1 &&
      chrgData?.frequency?.[0]?.value == 4
    ) {
      const start: any =
        chrgData?.startApplying?.[0]?.value == 0
          ? chrgData?.previewData?.startDate
          : chrgData?.startApplying?.[0]?.value == 1
          ? chrgData?.previewData?.startDateReceived
          : null

      const end: any = chrgData?.previewData?.endDate

      const startDate = new Date(start)
      const endDate = new Date(end)

      console.log('Start Date:', startDate)
      console.log('End Date:', endDate)

      console.log(chrgData?.customFrequencyType)

      // Frequency interval in days
      const intervalDays =
        chrgData?.customFrequencyType?.[0]?.value === 0
          ? 1 // Daily
          : chrgData?.customFrequencyType?.[0]?.value === 1
          ? 7 // Weekly
          : chrgData?.customFrequencyType?.[0]?.value === 2
          ? 30 // Monthly (approximation)
          : 0 // Default/Unknown

      let charges: any = []
      let currentDate = new Date(startDate)

      // Condition for due date type
      if (chrgData?.dueDateType?.[0]?.value == 0) {
        // Case: Applied date is the same as due date
        for (let i = 0; i < chrgData?.customTimesApplied; i++) {
          charges.push({
            appliedDate: moment(currentDate).format('YYYY-MM-DD'),
            dueDate: moment(currentDate).format('YYYY-MM-DD'), // Same as applied date
            subTotal: Number(chrgData.previewData.feeAmount),
            vatAmount: Number(chrgData.previewData.VATRate),
            totalAmount:
              Number(chrgData.previewData.feeAmount) + Number(chrgData.previewData.VATRate),
          })
          currentDate.setDate(currentDate.getDate() + intervalDays)
        }
      } else if (chrgData?.dueDateType?.[0]?.value == 1) {
        // Case: Due date is after the applied date
        for (let i = 0; i < chrgData?.customTimesApplied; i++) {
          const dueDate = moment(currentDate)
            .add(chrgData?.daysAfterChargeIsApplied || 0, 'days') // Add days after applied
            .format('YYYY-MM-DD')

          charges.push({
            appliedDate: moment(currentDate).format('YYYY-MM-DD'),
            dueDate: dueDate, // Calculated due date
            subTotal: Number(chrgData.previewData.feeAmount),
            vatAmount: Number(chrgData.previewData.VATRate),
            totalAmount:
              Number(chrgData.previewData.feeAmount) + Number(chrgData.previewData.VATRate),
          })

          currentDate.setDate(currentDate.getDate() + intervalDays)
        }
      }

      console.log('Charges:', charges)

      setPreviewChargesData(charges)
      setShowPreviewCharges(true)
    }

    // User wants to configure a Recurring Charge with a Fixed Fee that follows their Rent Installments for their Annual Residential Leases.
    // else if (
    //   chrgData?.type == 1 &&
    //   chrgData?.amountCalculationType == 0 &&
    //   chrgData?.frequency?.[0]?.value == 0
    // ){
    //   const start: any = chrgData?.previewData?.startDate
    //   const end: any = chrgData?.previewData?.endDate

    //   const startDate = new Date(start)
    //   const endDate = new Date(end)

    //   const paymentDates = calculateInstallmentDates(
    //     startDate,
    //     endDate,
    //     chrgData?.previewData?.noOfInstallments
    //   )
    //   console.log(paymentDates)

    //   let charges: any = []

    //   paymentDates?.map((pmDate: any) => {
    //     charges.push({
    //       appliedDate: moment(pmDate).format('YYYY-MM-DD'),
    //       dueDate: moment(pmDate).format('YYYY-MM-DD'),
    //       subTotal: Number(chrgData.previewData.feeAmount),
    //       vatAmount: Number(chrgData.previewData.VATRate),
    //       totalAmount:
    //         Number(chrgData.previewData.feeAmount) + Number(chrgData.previewData.VATRate),
    //     })
    //   })

    //   setPreviewChargesData(charges)
    //   setShowPreviewCharges(true)
    // }

    setDisablePC(true)
  }

  //
  function calculateInstallmentDates(
    startDate: Date,
    endDate: Date,
    noOfInstallments: number
  ): Date[] {
    const installmentDates: Date[] = []
    const timeDiff = endDate.getTime() - startDate.getTime()
    const interval = timeDiff / noOfInstallments

    console.log(interval)

    for (let i = 0; i < noOfInstallments; i++) {
      const installmentDate = new Date(startDate.getTime() + interval * i)
      installmentDates.push(installmentDate)
    }

    console.log(installmentDates)

    return installmentDates
  }

  //
  const calculateChargeAmount = () => {
    const updateLatePaymentFields = {...chrgData}

    // User wants to configure a Recurring Charge with a %-based Fee that follows their Rent Installments for their Annual Residential Leases.
    if (
      chrgData?.type == 1 &&
      chrgData?.amountCalculationType == 1 &&
      chrgData?.frequency?.length > 0
    ) {
      // Selected Charge - Single Rent Installment
      console.log(chrgData?.selectLeaseChargeType)
      if (chrgData?.selectLeaseChargeType?.[0]?.label == 'Single Rent Installment') {
        if (updateLatePaymentFields.previewData.noOfInstallments != null) {
          updateLatePaymentFields.previewData.singleRentInstallment =
            Number(updateLatePaymentFields.previewData.totalValue) /
            Number(updateLatePaymentFields.previewData.noOfInstallments)

            let newFeeAmount: any = ((updateLatePaymentFields.previewData.singleRentInstallment / 100) *
            updateLatePaymentFields.feePercentage)

          updateLatePaymentFields.previewData.feeAmount =
            (Number(newFeeAmount) >= Number(updateLatePaymentFields.minimumAmount)) 
            ? Number(newFeeAmount)
            : Number(updateLatePaymentFields.minimumAmount)
            
          updateLatePaymentFields.previewData.VATRate =
            (updateLatePaymentFields.previewData.feeAmount / 100) *
            updateLatePaymentFields.VATPercentage
        } else {
          updateLatePaymentFields.previewData.singleRentInstallment = null
          updateLatePaymentFields.previewData.feeAmount = null
          updateLatePaymentFields.previewData.VATRate = null
        }
      }
      // Selected Charge - Other than Single Rent Installment
      else {
        updateLatePaymentFields.previewData.singleRentInstallment =
          Number(updateLatePaymentFields.previewData.totalValue) /
          Number(
            updateLatePaymentFields.previewData.noOfInstallments != null
              ? updateLatePaymentFields.previewData.noOfInstallments
              : 1
          )

          let newFeeAmount: any = ((updateLatePaymentFields.previewData.totalValue/ 100) *
          updateLatePaymentFields.feePercentage)

        updateLatePaymentFields.previewData.feeAmount =
          (Number(newFeeAmount) >= Number(updateLatePaymentFields.minimumAmount)) 
          ? Number(newFeeAmount)
          : Number(updateLatePaymentFields.minimumAmount)

        updateLatePaymentFields.previewData.VATRate =
          (updateLatePaymentFields.previewData.feeAmount / 100) *
          updateLatePaymentFields.VATPercentage
      }

      updateLatePaymentFields.previewData.totalAmountWithVAT =
        Number(updateLatePaymentFields.previewData.feeAmount) +
        Number(updateLatePaymentFields.previewData.VATRate)

      if (chrgData?.frequency?.[0]?.value == 0)
        //As per Rent installments
        updateLatePaymentFields.previewData.dailyRate =
          (Number(updateLatePaymentFields.previewData.feeAmount) *
            Number(updateLatePaymentFields.previewData.noOfInstallments)) /
          365
      else if (chrgData?.frequency?.[0]?.value == 1)
        //Daily
        updateLatePaymentFields.previewData.dailyRate =
          updateLatePaymentFields.previewData.feeAmount
      else if (chrgData?.frequency?.[0]?.value == 2)
        //Weekly
        updateLatePaymentFields.previewData.dailyRate =
          Number(updateLatePaymentFields.previewData.feeAmount) / 7
      else if (chrgData?.frequency?.[0]?.value == 3)
        //Monthly
        updateLatePaymentFields.previewData.dailyRate =
          Number(updateLatePaymentFields.previewData.feeAmount) / 30.4
      else if (chrgData?.frequency?.[0]?.value == 4) {
        //Custom ---- Same like as per Rent installments
        if (chrgData?.customFrequencyType?.[0]?.value == 0)
          //Custom Daily
          updateLatePaymentFields.previewData.dailyRate = Number(updateLatePaymentFields.previewData.feeAmount) 
        else if (chrgData?.customFrequencyType?.[0]?.value == 1)
          //Custom Weekly
          updateLatePaymentFields.previewData.dailyRate = (Number(updateLatePaymentFields.previewData.feeAmount) / 7)
        else if (chrgData?.customFrequencyType?.[0]?.value == 2)
          //Custom Monthly
          updateLatePaymentFields.previewData.dailyRate = (Number(updateLatePaymentFields.previewData.feeAmount) / 30.4)
      }

      setChrgData(updateLatePaymentFields) // Update state
    }

   
    setPreviewChargesData([])
    setShowPreviewCharges(false)

    setDisablePC(false)
  }

  useEffect(() => {
    if (previewCalculations != false) {
      // % Based
      if (chrgData?.amountCalculationType == 1) {
        if (chrgData?.previewData?.totalValue != null) {
          if (
            chrgData?.selectLeaseChargeType?.[0]?.label === 'Single Rent Installment' ||
            chrgData?.frequency?.[0]?.value === 0
          ) {
            // Check noOfInstallments when condition is satisfied
            if (chrgData?.previewData?.noOfInstallments != null) {
              calculateChargeAmount()
            } else {
              const updatedFields = {...chrgData}

              updatedFields.previewData.singleRentInstallment = null
              updatedFields.previewData.noOfInstallments = null
              updatedFields.previewData.feeAmount = null
              updatedFields.previewData.VATRate = null
              updatedFields.previewData.dailyRate = null

              setChrgData(updatedFields) // Update state only when necessary
              setPreviewChargesData([])
              setShowPreviewCharges(false)
            }
          } else calculateChargeAmount()
        } else {
          const updatedFields = {...chrgData}

          updatedFields.previewData.singleRentInstallment = null
          // updatedFields.previewData.noOfInstallments = null
          updatedFields.previewData.feeAmount = null
          updatedFields.previewData.VATRate = null

          setChrgData(updatedFields) // Update state only when necessary
          setPreviewChargesData([])
          setShowPreviewCharges(false)
        }
      }

      // Fixed Amount
      else {
        console.log('previewCalculations ' + 'FixesAmount')
        const updateLatePaymentFields = {...chrgData}

        if (chrgData?.frequency?.[0]?.value == 0)
          //As per Rent installments
          updateLatePaymentFields.previewData.dailyRate =
            (Number(updateLatePaymentFields.previewData.feeAmount) *
              Number(updateLatePaymentFields.previewData.noOfInstallments)) /
            365
        else if (chrgData?.frequency?.[0]?.value == 1)
          //Daily
          updateLatePaymentFields.previewData.dailyRate =
            updateLatePaymentFields.previewData.feeAmount
        else if (chrgData?.frequency?.[0]?.value == 2)
          //Weekly
          updateLatePaymentFields.previewData.dailyRate =
            Number(updateLatePaymentFields.previewData.feeAmount) / 7
        else if (chrgData?.frequency?.[0]?.value == 3)
          //Monthly
          updateLatePaymentFields.previewData.dailyRate =
            Number(updateLatePaymentFields.previewData.feeAmount) / 30.4
        else if (chrgData?.frequency?.[0]?.value == 4) {
          //Custom ---- Same like as per Rent installments
          if (chrgData?.customFrequencyType?.[0]?.value == 0)
            //Custom Daily
            updateLatePaymentFields.previewData.dailyRate = Number(updateLatePaymentFields.previewData.feeAmount) 
          else if (chrgData?.customFrequencyType?.[0]?.value == 1)
            //Custom Weekly
            updateLatePaymentFields.previewData.dailyRate = (Number(updateLatePaymentFields.previewData.feeAmount) / 7)
          else if (chrgData?.customFrequencyType?.[0]?.value == 2)
            //Custom Monthly
            updateLatePaymentFields.previewData.dailyRate = (Number(updateLatePaymentFields.previewData.feeAmount) / 30.4)
        }

        setChrgData(updateLatePaymentFields) // Update state
        setPreviewChargesData([])
        setShowPreviewCharges(false)
      }

      setDisablePC(false)
    }
  }, [previewCalculations])

  let subTotalAmount: any = 0
  let vatAmount: any = 0
  let totalAmount: any = 0

  useEffect(() => {
    const updateLatePaymentFields = {...chrgData}
    if (updateLatePaymentFields?.amountCalculationType == 1) {
      // console.log("?************ UseEffect ***********?")
      updateLatePaymentFields.previewData.totalValue = null
      updateLatePaymentFields.previewData.noOfInstallments = null
      updateLatePaymentFields.previewData.startDate = null
      updateLatePaymentFields.previewData.startDateReceived = null
      updateLatePaymentFields.previewData.endDateReceived = null
      updateLatePaymentFields.previewData.dateApplied = null
      updateLatePaymentFields.previewData.dateStopped = null
      updateLatePaymentFields.previewData.feeAmount = null
      updateLatePaymentFields.previewData.VATRate = null
      updateLatePaymentFields.previewData.totalAmountWithVAT = null
    } else {
      updateLatePaymentFields.previewData.feeAmount = updateLatePaymentFields.fixedAmount
      updateLatePaymentFields.previewData.VATRate = updateLatePaymentFields.previewData.VATAmountForFixes
      updateLatePaymentFields.previewData.startDate = null
      updateLatePaymentFields.previewData.startDateReceived = null
      updateLatePaymentFields.previewData.endDateReceived = null
      updateLatePaymentFields.previewData.dateApplied = null
      updateLatePaymentFields.previewData.dateStopped = null
      updateLatePaymentFields.previewData.noOfInstallments = null
    }

    setChrgData(updateLatePaymentFields)

    calculateChargeAmount()

    setPreviewChargesData([])
    setShowPreviewCharges(false)
  }, [isChrgDataChanged])

  //
  // const disabledDate = (current: any) => {
  //   const startDate = chrgData?.previewData?.startDate
  //     ? moment(chrgData.previewData.startDate, 'YYYY-MM-DD')
  //     : null;
  //   const endDate = chrgData?.previewData?.endDate
  //     ? moment(chrgData.previewData.endDate, 'YYYY-MM-DD')
  //     : null;

  //   // Disable all dates before startDate or after endDate
  //   return (
  //     (startDate && current.isBefore(startDate, 'day')) ||
  //     (endDate && current.isAfter(endDate, 'day'))
  //   );
  // };

  // chrgData?.previewData?.dateStopped
  // chrgData?.previewData?.endDateReceived
  // chrgData?.previewData?.dateApplied
  // chrgData?.previewData?.startDateReceived

  const disabledLessStartDate = (current: any) => {
    const startDate = chrgData?.previewData?.startDate
      ? moment(chrgData.previewData.startDate, 'YYYY-MM-DD')
      : null
    const endDate = chrgData?.previewData?.endDate
      ? moment(chrgData.previewData.endDate, 'YYYY-MM-DD')
      : null
    const dateApplied = chrgData?.previewData?.dateApplied
      ? moment(chrgData.previewData.dateApplied, 'YYYY-MM-DD')
      : null
    const receivedApplied = chrgData?.previewData?.startDateReceived
      ? moment(chrgData?.previewData?.startDateReceived, 'YYYY-MM-DD')
      : null

    // Disable all dates before startDate or after endDate
    // Also disable dates that violate dateApplied <= endDateReceived
    return (
      (startDate && current.isBefore(startDate, 'day')) ||
      (endDate && current.isAfter(endDate, 'day')) ||
      (dateApplied && current.isSameOrBefore(dateApplied, 'day')) ||
      (receivedApplied && current.isSameOrBefore(receivedApplied, 'day'))
    )
  }

  const disabledGreaterEndDate = (current: any) => {
    const startDate = chrgData?.previewData?.startDate
      ? moment(chrgData.previewData.startDate, 'YYYY-MM-DD')
      : null
    const endDate = chrgData?.previewData?.endDate
      ? moment(chrgData.previewData.endDate, 'YYYY-MM-DD')
      : null
    const endDateReceived = chrgData?.previewData?.endDateReceived
      ? moment(chrgData.previewData.endDateReceived, 'YYYY-MM-DD')
      : null
    const stopDate = chrgData?.previewData?.dateStopped
      ? moment(chrgData.previewData.dateStopped, 'YYYY-MM-DD')
      : null

    // Disable all dates before startDate or after endDate
    // Also disable dates that violate dateApplied <= endDateReceived
    return (
      (startDate && current.isBefore(startDate, 'day')) ||
      (endDate && current.isAfter(endDate, 'day')) ||
      (endDateReceived && current.isSameOrAfter(endDateReceived, 'day')) ||
      (stopDate && current.isSameOrAfter(stopDate, 'day'))
    )
  }

  const [disablePC, setDisablePC] = useState<boolean>(false)

  const resetPreview = () => {
    const updateLatePaymentFields = {...chrgData}
    updateLatePaymentFields.previewData.totalValue = null
      updateLatePaymentFields.previewData.noOfInstallments = null
      updateLatePaymentFields.previewData.startDate = null
      updateLatePaymentFields.previewData.startDateReceived = null
      updateLatePaymentFields.previewData.endDateReceived = null
      updateLatePaymentFields.previewData.dateApplied = null
      updateLatePaymentFields.previewData.dateStopped = null
      if(updateLatePaymentFields?.amountCalculationType == 1){
      updateLatePaymentFields.previewData.feeAmount = null
      updateLatePaymentFields.previewData.VATRate = null
      updateLatePaymentFields.previewData.totalAmountWithVAT = null
      }
      setChrgData(updateLatePaymentFields)

  }

  return (
    <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3 card-grey h-100'>
      <div className='card-body pt-0 mt-5 py-0 px-3'>
      <div className='d-flex align-items-center justify-content-between'>
          <h3 className='page-heading m-0 white-dark-theme-color fnt-700'>Preview Charges</h3>
          <div
            className='filter-div d-flex align-items-center justify-content-center cursor-pointer ms-auto'
            onClick={()=>{
              resetPreview()
              setPreviewChargesData([])
              setShowPreviewCharges(false)}}
            // style={{zIndex: '20'}}
          >
            <img src={sync} height={18} width={18} />
          </div>
        </div>
         
        <p className='me-5 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color mb-10'
          style={{color: '#a4a4a4'}}>
          Fill out the settings below and click preview to view how the charges will apply
        </p>
            

        {(showInputPreviewSections || showInputPreviewFixedSection) && (
          <>
            <Row className='d-block charge-preview'>
              {/* Total Amount */}
              {chrgData?.amountCalculationType == 1 && chrgData?.selectLeaseChargeType?.length > 0 && (
                <Col className='' sm={12}>
                  <FlexedInput
                    isDisabled={false}
                    iconSize={30}
                    required={false}
                    setRightPosition='10px'
                    type='number'
                    name='amountPreview'
                    value={
                      chrgData?.previewData?.totalValue != null
                        ? chrgData?.previewData?.totalValue
                        : ''
                    }
                    label={`${
                      chrgData?.selectLeaseChargeType?.[0]?.label == 'Total Rent Value' ||
                      chrgData?.selectLeaseChargeType?.[0]?.label == 'Single Rent Installment'
                        ? 'Total Rent Value'
                        : `${chrgData?.selectLeaseChargeType?.[0]?.label}`
                    }`}
                    firstPlaceholder='Enter Amount'
                    secondPlaceHolder='AED'
                    onChange={(newValue: any) => {
                      const updateLatePaymentFields = {...chrgData}
                      updateLatePaymentFields.previewData.totalValue = newValue != '' ? newValue : null
                      setChrgData(updateLatePaymentFields)

                      setTimeout(() => {
                        setPreviewCalculations(Date.now())
                      }, 200)
                    }}
                  />
                </Col>
              )}

              {(chrgData?.selectLeaseChargeType?.[0]?.label == 'Single Rent Installment' ||
                chrgData?.frequency?.[0]?.value == 0) && (
                <Col className='mt-5' sm={12}>
                  <FlexedInputFrequency
                    isDisabled={false}
                    iconSize={30}
                    required={false}
                    setRightPosition='10px'
                    type='number'
                    name='noOfInstallments'
                    value={
                      chrgData?.previewData?.noOfInstallments != null
                        ? chrgData?.previewData?.noOfInstallments
                        : ''
                    }
                    label={`No. of Installments`}
                    firstPlaceholder='Enter Installments'
                    secondPlaceHolder=''
                    onChange={(newValue: any) => {
                      const updateLatePaymentFields = {...chrgData}
                      updateLatePaymentFields.previewData.noOfInstallments =
                        newValue != '' ? newValue : null
                      setChrgData(updateLatePaymentFields)

                      setTimeout(() => {
                        setPreviewCalculations(Date.now())
                      }, 500)
                    }}
                    minValue={1}
                    maxValue={12}
                  />
                </Col>
              )}

              {/* Lease Start Date  */}
              {/* {chrgData?.startApplying?.[0]?.value == 0 && ( */}
              <Col className='mt-5 lease-charges' sm={12}>
                <div className={`d-flex align-items-center`}>
                  <div className='percentage-label-container'>
                    <label
                      className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}
                    >
                      Lease Start Date
                    </label>
                  </div>
                  <DatePicker
                    key={pickerKey}
                    className={`w-100 ${
                      chrgData?.previewData?.startDate ? 'isFilled bg-white' : ''
                    }`}
                    value={
                      chrgData?.previewData?.startDate
                        ? moment(chrgData.previewData.startDate, 'YYYY-MM-DD')
                        : undefined
                    }
                    format='DD.MM.YYYY'
                    // disabledDate={disabledDate}
                    onChange={(date: any) => {
                      if (date) {
                        const startDate = date.format('YYYY-MM-DD')
                        const endDate = moment(date)
                          .add(1, 'year')
                          .subtract(1, 'day')
                          .format('YYYY-MM-DD')

                        const isAppliedStartDateInRange = moment(chrgData.previewData.dateApplied).isBetween(startDate, endDate, 'day', '[]'); 
                        const isAppliedEndDateInRange = moment(chrgData.previewData.dateStopped).isBetween(startDate, endDate, 'day', '[]');
                        const isReceivedStartDateInRange = moment(chrgData.previewData.startDateReceived).isBetween(startDate, endDate, 'day', '[]');
                        const isReceivedEndDateInRange = moment(chrgData.previewData.endDateReceived).isBetween(startDate, endDate, 'day', '[]');


                        const updatedChrgData = {
                          ...chrgData,
                          previewData: {
                            ...chrgData.previewData,
                            startDate: startDate,
                            endDate: endDate, // Set the correctly calculated endDate
                            dateApplied: isAppliedStartDateInRange ? chrgData.previewData.dateApplied : null, 
                            dateStopped: isAppliedEndDateInRange ? chrgData.previewData.dateStopped : null, 
                            startDateReceived: isReceivedStartDateInRange ? chrgData.previewData.startDateReceived : null, 
                            endDateReceived: isReceivedEndDateInRange ? chrgData.previewData.endDateReceived : null, 
                          },
                        }

                        setChrgData(updatedChrgData)
                      } else {
                        const updatedChrgData = {
                          ...chrgData,
                          previewData: {
                            ...chrgData.previewData,
                            startDate: null,
                            endDate: null, // Reset endDate when startDate is cleared
                          },
                        }

                        setChrgData(updatedChrgData)

                        setPickerKey(Date.now())
                      }

                      setTimeout(() => {
                        setPreviewCalculations(Date.now())
                      }, 200)
                    }}
                    placeholder='Select Date'
                    defaultPickerValue={chrgData?.previewData?.startDate == null ? moment() : undefined}
                    inputReadOnly={true}
                  />
                </div>
              </Col>
              {/* )} */}

              {/* Lease End Date  */}
              {/* {chrgData?.endApplying?.[0]?.value == 0 && ( */}
              <Col className='mt-7' sm={12}>
                <div className={`d-flex align-items-center`}>
                  <div className='percentage-label-container'>
                    <label
                      className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}
                    >
                      Lease End Date
                    </label>
                  </div>
                  <label className={`light-dark-theme-color fnt-400 ps-3`}>
                    {chrgData?.previewData?.startDate != null
                      ? moment(chrgData?.previewData?.endDate).format('DD.MM.YYYY')
                      : '-'}
                  </label>
                </div>
              </Col>
              {/* )} */}

              {/* Times Applied */}
              {chrgData?.frequency?.[0]?.value == 4 && (
                <Col className='mt-7 pe-6' sm={12}>
                  <div className={`d-flex align-items-center`}>
                    <div className='percentage-label-container'>
                      <label
                        className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}
                      >
                        Times Applied
                      </label>
                    </div>
                    <label className={`light-dark-theme-color fnt-400 ps-3`}>
                      {chrgData?.customTimesApplied != null ? chrgData?.customTimesApplied : '-'}{' '}
                      Times
                    </label>
                  </div>
                </Col>
              )}

              {/*  Rent Intsallment */}
              {chrgData?.selectLeaseChargeType?.[0]?.label == 'Single Rent Installment' &&
                chrgData?.amountCalculationType == 1 && (
                  <Col className='mt-7 pe-6' sm={12}>
                    <div className={`d-flex align-items-center`}>
                      <div className='percentage-label-container'>
                        <label
                          className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}
                        >
                          Rent Intsallment
                        </label>
                      </div>
                      <label className={`fnt-500 light-dark-theme-color text-end ms-auto`}>
                        {chrgData?.previewData?.singleRentInstallment != null
                          ? formatAmount(chrgData?.previewData?.singleRentInstallment)
                          : '-'}{' '}
                        {' AED'}
                      </label>
                    </div>
                  </Col>
                )}

              {/* Charge Details */}
              {/* Fee Amount */}
              <Col className='mt-15 pt-10 pe-6' sm={12}>
                <div className={`d-flex align-items-center`}>
                  <div className='percentage-label-container '>
                    <label
                      className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}
                    >
                      Fee Amount
                    </label>
                  </div>
                  <label className={`fnt-500 light-dark-theme-color text-end ms-auto`}>
                    {(chrgData?.previewData?.feeAmount != null && chrgData?.previewData.totalValue != null && chrgData?.amountCalculationType == 1) || 
                    (chrgData?.previewData?.feeAmount != null && chrgData?.amountCalculationType == 0)
                      ? formatAmount(chrgData?.previewData?.feeAmount)
                      : '-'}{' '}
                    {' AED'}
                  </label>
                </div>
              </Col>

              {/* VAT Rate */}
              <Col className='mt-5 pe-6' sm={12}>
                <div className={`d-flex align-items-center`}>
                  <div className='percentage-label-container'>
                    <label
                      className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}
                    >
                      VAT Rate
                    </label>
                  </div>
                  <label className={`fnt-500 light-dark-theme-color text-end ms-auto`}>
                    {(chrgData?.previewData?.VATRate != null && chrgData?.previewData.totalValue != null && chrgData?.amountCalculationType == 1)
                    ? formatAmount(chrgData?.previewData?.VATRate)
                    : (chrgData?.previewData?.VATRate != null && chrgData?.amountCalculationType == 0)
                    ? formatAmount(chrgData?.previewData.VATAmountForFixes)
                    : '-'}
                    {' AED'}
                  </label>
                </div>
              </Col>

              {/* Daily Rate */}
              <Col className='mt-5 pe-6' sm={12}>
                <div className={`d-flex align-items-center`}>
                  <div className='percentage-label-container'>
                    <label
                      className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}
                    >
                      Daily Rate
                    </label>
                  </div>
                  <label className={`fnt-500 light-dark-theme-color text-end ms-auto`}>
                    {chrgData?.previewData?.dailyRate != null &&
                    chrgData?.previewData?.startDate != null
                      ? formatAmount(chrgData?.previewData?.dailyRate)
                      : '-'}{' '}
                    {' AED'}
                  </label>
                </div>
                
              </Col>
            </Row>

            <Row className='separator mx-0 my-3'></Row>

            <Row className='charge-preview lease-charges'>
              {/* Start Appyling % */}
              {chrgData?.frequency?.[0]?.value != 0 && chrgData?.startApplying?.[0]?.value == 1 && (
                <Col sm={12}>
                  <h3 className='page-heading m-0 white-dark-theme-color fnt-700 mt-2'>
                    Charge Starts Applying
                  </h3>

                  <div className={`d-flex align-items-center w-100 mt-3`}>
                    <div className='percentage-label-container' style={{width: '55%'}}>
                      <label className={`percentage-input-label white-dark-theme-color fnt-500`}>
                        % of Payments Received
                      </label>
                    </div>
                    <label
                      className={`fnt-500 light-dark-theme-color text-end`}
                      style={{width: '45%'}}
                    >
                      {chrgData?.startApplyPercentagePaymentReceived != null
                        ? chrgData?.startApplyPercentagePaymentReceived
                        : '-'}
                      %
                    </label>
                  </div>

                  <div className={`d-flex align-items-center mt-5`}>
                    <div className='percentage-label-container'>
                      <label
                        className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}
                      >
                        Date Received
                      </label>
                    </div>
                    <DatePicker
                      key={pickerKey}
                      className={`w-100 ${
                        chrgData?.previewData?.startDateReceived ? 'isFilled' : ''
                      }`}
                      value={
                        chrgData?.previewData?.startDateReceived
                          ? moment(chrgData.previewData.startDateReceived, 'YYYY-MM-DD')
                          : undefined
                      }
                      format='DD.MM.YYYY'
                      disabledDate={disabledGreaterEndDate}
                      onChange={(date: any) => {
                        const updatedChrgData = {
                          ...chrgData,
                          previewData: {
                            ...chrgData.previewData,
                            startDateReceived: date ? date.format('YYYY-MM-DD') : null,
                          },
                        }
                        setChrgData(updatedChrgData)

                        setTimeout(() => {
                          setPreviewCalculations(Date.now())
                        }, 200)

                        setPickerKey(Date.now())
                      }}
                      placeholder='Select Date'
                      disabled={chrgData?.previewData?.startDate == null}
                      defaultPickerValue={chrgData?.previewData?.startDateReceived == null ? moment() : undefined}
                      inputReadOnly={true}
                    />
                  </div>
                </Col>
              )} 

              {/* Custom Start Appyling % */}
              {chrgData?.startApplying?.[0]?.value == 2 && (
                <Col sm={12} className="lease-charges">
                  <h3 className='page-heading m-0 white-dark-theme-color fnt-700 mt-2'>
                    Charge Starts Applying
                  </h3>

                  <div className={`d-flex align-items-center mt-5`}>
                    <div className='percentage-label-container'>
                      <label
                        className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}
                      >
                        Date Applied
                      </label>
                    </div>
                    <DatePicker
                      key={pickerKey}
                      className={`w-100 ${chrgData?.previewData?.dateApplied ? 'isFilled' : ''}`}
                      value={
                        chrgData?.previewData?.dateApplied
                          ? moment(chrgData.previewData.dateApplied, 'YYYY-MM-DD')
                          : undefined
                      }
                      format='DD.MM.YYYY'
                      disabledDate={disabledGreaterEndDate}
                      onChange={(date: any) => {
                        const updatedChrgData = {
                          ...chrgData,
                          previewData: {
                            ...chrgData.previewData,
                            dateApplied: date ? date.format('YYYY-MM-DD') : null,
                          },
                        }
                        setChrgData(updatedChrgData)

                        setTimeout(() => {
                          setPreviewCalculations(Date.now())
                        }, 200)

                        setPickerKey(Date.now())
                      }}
                      placeholder='Select Date'
                      disabled={chrgData?.previewData?.startDate == null}
                      defaultPickerValue={chrgData?.previewData?.dateApplied == null ? moment() : undefined}
                      inputReadOnly={true}
                    />
                  </div>
                </Col>
              )}

              {/* Stop Appyling % */}
              {(chrgData?.frequency?.[0]?.value == 1 ||
                chrgData?.frequency?.[0]?.value == 2 ||
                chrgData?.frequency?.[0]?.value == 3) &&
                chrgData?.endApplying?.[0]?.value == 1 && (
                  <Col sm={12} className='lease-charges'>
                    <h3 className='page-heading m-0 white-dark-theme-color fnt-700 mt-5'>
                      Charge Stops Applying
                    </h3>

                    <div className={`d-flex align-items-center w-100 mt-3`}>
                      <div className='percentage-label-container' style={{width: '55%'}}>
                        <label className={`percentage-input-label white-dark-theme-color fnt-500`}>
                          % of Payments Received
                        </label>
                      </div>
                      <label
                        className={`fnt-500 light-dark-theme-color text-end`}
                        style={{width: '45%'}}
                      >
                        {chrgData?.endApplyPercentagePaymentReceived != null
                          ? chrgData?.endApplyPercentagePaymentReceived
                          : '-'}
                        %
                      </label>
                    </div>

                    <div className={`d-flex align-items-center mt-5`}>
                      <div className='percentage-label-container'>
                        <label
                          className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}
                        >
                          Date Received
                        </label>
                      </div>
                      <DatePicker
                        key={pickerKey}
                        className={`w-100 ${
                          chrgData?.previewData?.endDateReceived ? 'isFilled' : ''
                        }`}
                        value={
                          chrgData?.previewData?.endDateReceived
                            ? moment(chrgData.previewData.endDateReceived, 'YYYY-MM-DD')
                            : undefined
                        }
                        format='DD.MM.YYYY'
                        disabledDate={disabledLessStartDate}
                        onChange={(date: any) => {
                          const updatedChrgData = {
                            ...chrgData,
                            previewData: {
                              ...chrgData.previewData,
                              endDateReceived: date ? date.format('YYYY-MM-DD') : null,
                            },
                          }
                          setChrgData(updatedChrgData)

                          setTimeout(() => {
                            setPreviewCalculations(Date.now())
                          }, 200)

                          setPickerKey(Date.now())
                        }}
                        placeholder='Select Date'
                        disabled={chrgData?.previewData?.startDate == null}
                        defaultPickerValue={chrgData?.previewData?.endDateReceived == null ? moment() : undefined}
                        inputReadOnly={true}
                      />
                    </div>
                  </Col>
                )}

              {/* Custom Stop  Appyling % */}
              {(chrgData?.frequency?.[0]?.value == 1 ||
                chrgData?.frequency?.[0]?.value == 2 ||
                chrgData?.frequency?.[0]?.value == 3) && 
                chrgData?.endApplying?.[0]?.value == 2 && (
                <Col sm={12} className="lease-charges">
                  <h3 className='page-heading m-0 white-dark-theme-color fnt-700 mt-5'>
                    Charge Stops Applying
                  </h3>

                  <div className={`d-flex align-items-center mt-5`}>
                    <div className='percentage-label-container'>
                      <label
                        className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}
                      >
                        Date Stopped
                      </label>
                    </div>
                    <DatePicker
                      key={pickerKey}
                      className={`w-100 ${chrgData?.previewData?.dateStopped ? 'isFilled' : ''}`}
                      value={
                        chrgData?.previewData?.dateStopped
                          ? moment(chrgData.previewData.dateStopped, 'YYYY-MM-DD')
                          : undefined
                      }
                      format='DD.MM.YYYY'
                      disabledDate={disabledLessStartDate}
                      onChange={(date: any) => {
                        const updatedChrgData = {
                          ...chrgData,
                          previewData: {
                            ...chrgData.previewData,
                            dateStopped: date ? date.format('YYYY-MM-DD') : null,
                          },
                        }
                        setChrgData(updatedChrgData)
                        setTimeout(() => {
                          setPreviewCalculations(Date.now())
                        }, 200)

                        setPickerKey(Date.now())
                      }}
                      placeholder='Select Date'
                      disabled={chrgData?.previewData?.startDate == null}
                      defaultPickerValue={chrgData?.previewData?.dateStopped == null ? moment() : undefined}
                      inputReadOnly={true}
                    />
                  </div>
                </Col>
              )}
            </Row>
          </>
        )}

        {/* Preview CTA */}
        <button
          type='button'
          className='btn btn-sm fw-bold px-2 mx-auto justify-content-center blue-btn status-w-200 mt-10'
          onClick={() => {
            getPreviewCharges()
          }}
          disabled={disablePreviewCTA || disablePC}
        >
          {!showInputPreviewSections && !showInputPreviewFixedSection
            ? 'Preview'
            : 'Preview Charges'}
        </button>

        {!showInputPreviewSections && !showInputPreviewFixedSection && (
          <p
            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
            className='m-0 mt-1 text-center px-5'
          >
            Please fill out all required field settings and then click ‘Preview’ to view the
            charges.
          </p>
        )}

        {/* Charges */}
        {showPreviewCharges && (
          <>
            <Row className='mt-8'>
              {previewChargesData?.length <= 4
                ? previewChargesData.map((prChg: any, ind: any) => (
                    <React.Fragment key={ind}>
                      <Col className='mb-1 pe-6' sm={12}>
                        <label className='percentage-input-label white-dark-theme-color fnt-600 w-50'>
                          Charge {ind+1}
                        </label>
                        <label className='percentage-input-label white-dark-theme-color fnt-600 w-50 text-end'>
                          {formatAmount(prChg?.totalAmount)} {' AED'}
                        </label>
                      </Col>
                      <Col className='mb-5 pe-6' sm={12}>
                        <label className='percentage-input-label white-dark-theme-color fnt-400 w-50'>
                          Applied: {moment(new Date(prChg?.appliedDate)).format('DD.MM.YYYY')}
                        </label>

                        <label className='percentage-input-label white-dark-theme-color fnt-400 w-50 text-end'>
                          Due: {moment(new Date(prChg?.dueDate)).format('DD.MM.YYYY')}
                        </label>
                      </Col>
                    </React.Fragment>
                  ))
                : previewChargesData
                  .map((prChg: any, originalInd: any) => ({ ...prChg, originalInd }))
                    .filter(
                      (_: any, ind: any) =>
                        // Filter first, second, second-last, and last records
                        ind === 0 ||
                        ind === 1 ||
                        ind === previewChargesData.length - 2 ||
                        ind === previewChargesData.length - 1
                    )
                    .map((prChg: any, ind: any) => (
                      <React.Fragment key={ind}>
                        <Col className='mb-1 pe-6' sm={12}>
                          <label className='percentage-input-label white-dark-theme-color fnt-600 w-50'>
                            Charge {prChg.originalInd + 1}
                          </label>
                          <label className='percentage-input-label white-dark-theme-color fnt-600 w-50 text-end'>
                            {formatAmount(prChg?.totalAmount)} {' AED'}
                          </label>
                        </Col>
                        <Col className='mb-5 pe-6' sm={12}>
                          <label className='percentage-input-label white-dark-theme-color fnt-400 w-50'>
                            Applied: {moment(new Date(prChg?.appliedDate)).format('DD.MM.YYYY')}
                          </label>

                          <label className='percentage-input-label white-dark-theme-color fnt-400 w-50 text-end'>
                            Due: {moment(new Date(prChg?.dueDate)).format('DD.MM.YYYY')}
                          </label>
                        </Col>
                      </React.Fragment>
                    ))}
            </Row>

            <Row className='separator mx-0 my-3 mb-7'></Row>

            {previewChargesData.map((prChg: any, ind: any) => {
              subTotalAmount = subTotalAmount + prChg?.subTotal
              vatAmount = vatAmount + prChg?.vatAmount
              totalAmount = Number(subTotalAmount) + Number(vatAmount)

              return
              ;<></>
            })}
            <Row className='mb-10'>
              {/*  Total Applied */}
              <Col className='pe-6' sm={12}>
                <div className={`d-flex align-items-center w-100`}>
                  <div className='percentage-label-container w-50'>
                    <label
                      className={`percentage-input-label white-dark-theme-color fnt-400 status-w-130`}
                    >
                      Total Applied
                    </label>
                  </div>
                  <label className={`light-dark-theme-color w-50 text-end`}>
                    {previewChargesData?.length > 0
                      ? `${formatAmount(previewChargesData?.length)} Charges`
                      : '- Charges'}
                  </label>
                </div>
              </Col>

              {/* Sub Total */}
              <Col className='mt-5 pe-6' sm={12}>
                <div className={`d-flex align-items-center w-100`}>
                  <div className='percentage-label-container w-50'>
                    <label
                      className={`percentage-input-label white-dark-theme-color fnt-400 status-w-130`}
                    >
                      Sub Total
                    </label>
                  </div>
                  <label className={`light-dark-theme-color w-50 text-end`}>
                    {subTotalAmount ? formatAmount(subTotalAmount) : '-'}
                    {' AED'}
                  </label>
                </div>
              </Col>

              {/* VAT */}
              <Col className='mt-5 pe-6' sm={12}>
                <div className={`d-flex align-items-center w-100`}>
                  <div className='percentage-label-container w-50'>
                    <label
                      className={`percentage-input-label white-dark-theme-color fnt-400 status-w-130`}
                    >
                      VAT
                    </label>
                  </div>
                  <label className={`light-dark-theme-color w-50 text-end`}>
                    {vatAmount ? formatAmount(vatAmount) : '-'}
                    {' AED'}
                  </label>
                </div>
              </Col>

              {/* Total Amount */}
              <Col className='mt-5 pe-6' sm={12}>
                <div className={`d-flex align-items-center w-100`}>
                  <div className='percentage-label-container w-50'>
                    <label
                      className={`percentage-input-label white-dark-theme-color fnt-400 status-w-130`}
                    >
                      Total Amount
                    </label>
                  </div>
                  <label className={`light-dark-theme-color w-50 text-end`}>
                    {totalAmount ? formatAmount(totalAmount) : '-'}
                    {' AED'}
                  </label>
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  )
}

export default RecurringPreview
