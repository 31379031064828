import React, {useEffect, useState, useRef} from 'react'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import IconButton from '@mui/material/IconButton'
import {MultiSelect} from 'react-multi-select-component'
import {useLocation, useNavigate} from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import swal from 'sweetalert2'
import moment from 'moment'
import RequestModel from './RequestModel'
import Select from 'react-select'
import './styles.scss'
import {ApiPost, ApiGet} from '../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import maintenance from '../../../img/maintenanceWhite.png'
import maintenanceJob from '../../../img/maintenanceJobs.png'
import maintenanceService from '../../../img/maintenanceService.png'
import maintenanceReactive from '../../../img/maintenanceReactive.png'
import trashImg from '../../../img/trash.png'
import wrenchImg from '../../../img/wrench.png'
import noData from '../../../img/NoData1.svg'
import starYellow from '../../../img/yellow-star.png'
import CRMResidents from './CRM/CRMResidents'

function Maintenance() {
  const {state, pathname} = useLocation()

  const getReqMaintenanceFilter: any = localStorage.getItem('maintenanceRequestFilters')
  const reqFilters = JSON.parse(getReqMaintenanceFilter)

  const getJobMaintenanceFilter: any = localStorage.getItem('maintenanceJobFilters')
  const jobFilters = JSON.parse(getJobMaintenanceFilter)

  const getServiceMaintenanceFilter: any = localStorage.getItem('maintenanceServiceFilters')
  const serviceFilters = JSON.parse(getServiceMaintenanceFilter)

  const getReactiveMaintenanceFilter: any = localStorage.getItem('maintenanceReactiveFilters')
  const reactiveFilters = JSON.parse(getReactiveMaintenanceFilter)

  const getActiveTab: any = localStorage.getItem('maintenanceActiveTab')
  const tab = JSON.parse(getActiveTab)

  const getMaintenanceTablePage: any = localStorage.getItem('maintenanceTablePage')
  const maintenanceTablePage = JSON.parse(getMaintenanceTablePage)

  const [pageLimit, setPageLimit] = useState<any>(1)
  const navigate = useNavigate()
  // const [activeTab, setActiveTab] = useState<any>(state?.activeTab ? state?.activeTab : 'requests')
  const [activeTab, setActiveTab] = useState<any>(tab ? tab : 'requests')
  const [page, setPage] = useState<any>(maintenanceTablePage ? maintenanceTablePage : 1)
  const [limit, setLimit] = useState<any>(25)
  const [requestModal, setRequestModal] = useState<any>(false)
  const [maintenanceCount, setMaintenanceCount] = useState<any>()
  const [totalMaintenanceRequests, setTotalMaintenanceRequests] = useState<any>()
  const [serviceJobCount, setServiceJobCount] = useState<any>()
  const [totalServiceJobs, setTotalServiceJobs] = useState<any>()

  const [requestDevelopment, setRequestDevelopment] = useState<any>(
    reqFilters?.development ? reqFilters?.development : ''
  )

  const [jobDevelopment, setJobDevelopment] = useState<any>(
    jobFilters?.development ? jobFilters?.development : ''
  )
  const [reactiveDevelopment, setReactiveDevelopment] = useState<any>(
    reactiveFilters?.development ? reactiveFilters?.development : ''
  )

  const [sortType, setSortType] = useState<any>(
    state?.sortType ? state?.sortType : [{value: 'Request High', label: 'Requests (High - Low)'}]
  )
  const [sortOptions, setSortOptions] = useState<any>([
    {value: 'A-Z', label: 'Alphabetic (A-Z)'},
    {value: 'Z-A', label: 'Alphabetic (Z-A)'},
    {value: 'Main Service A-Z', label: 'Main Service (A-Z)'},
    {value: 'Main Service Z-A', label: 'Main Service (Z-A)'},
    {value: 'Sub Service A-Z', label: 'Sub Service (A-Z)'},
    {value: 'Sub Service Z-A', label: 'Sub Service (Z-A)'},
    {value: 'Request High', label: 'Requests (High - Low)'},
    {value: 'Request Low', label: 'Requests (Low - High)'},
    {value: 'Duration High', label: 'Duration (High - Low)'},
    {value: 'Duration Low', label: 'Duration (Low - High)'},
    {value: 'Rating High', label: 'Rating (High - Low)'},
    {value: 'Rating Low', label: 'Rating (Low - High)'},
    // {value: 'latest', label: '(Latest - Oldest)'},
    // {value: 'oldest', label: '(Oldest - Latest)'},
  ])

  const [mainServiceOptions, setMainServiceOptions] = useState<any>([])
  const [mainServiceOptionsForServices, setMainServiceOptionsForServices] = useState<any>([])

  const [requestMainServiceDropdownStatus, setRequestMainServiceDropdownStatus] = useState<any>(
    reqFilters?.mainServiceDropdownStatus ? reqFilters?.mainServiceDropdownStatus : []
  )
  const [jobMainServiceDropdownStatus, setJobMainServiceDropdownStatus] = useState<any>(
    jobFilters?.mainServiceDropdownStatus ? jobFilters?.mainServiceDropdownStatus : []
  )
  const [serviceMainServiceDropdownStatus, setServiceMainServiceDropdownStatus] = useState<any>(
    serviceFilters?.mainServiceDropdownStatus ? serviceFilters?.mainServiceDropdownStatus : []
  )
  const [reactiveMainServiceDropdownStatus, setReactiveMainServiceDropdownStatus] = useState<any>(
    reactiveFilters?.mainServiceDropdownStatus ? reactiveFilters?.mainServiceDropdownStatus : []
  )

  const [subServiceOptions, setSubServiceOptions] = useState<any>([])
  const [subServiceOptionsForServices, setSubServiceOptionsForServices] = useState<any>([])

  const [requestSubServiceDropdownStatus, setRequestSubServiceDropdownStatus] = useState<any>(
    reqFilters?.subServiceDropdownStatus ? reqFilters?.subServiceDropdownStatus : []
  )
  const [jobSubServiceDropdownStatus, setJobSubServiceDropdownStatus] = useState<any>(
    jobFilters?.subServiceDropdownStatus ? jobFilters?.subServiceDropdownStatus : []
  )
  const [serviceSubServiceDropdownStatus, setServiceJobSubServiceDropdownStatus] = useState<any>(
    serviceFilters?.subServiceDropdownStatus ? serviceFilters?.subServiceDropdownStatus : []
  )
  const [reactiveSubServiceDropdownStatus, setReactiveSubServiceDropdownStatus] = useState<any>(
    reactiveFilters?.subServiceDropdownStatus ? reactiveFilters?.subServiceDropdownStatus : []
  )

  const [handleByOptions, setHandleByOptions] = useState<any>([
    {
      label: 'Internal Team',
      value: 0,
    },
    {
      label: 'External Team',
      value: 1,
    },
  ])
  const [requestHandleByDropdownStatus, setRequestHandleByDropdownStatus] = useState<any>(
    reqFilters?.handleByDropdownStatus ? reqFilters?.handleByDropdownStatus : []
  )
  const [jobHandleByDropdownStatus, setJobHandleByDropdownStatus] = useState<any>(
    jobFilters?.handleByDropdownStatus ? jobFilters?.handleByDropdownStatus : []
  )
  const [reactiveHandleByDropdownStatus, setReactiveHandleByDropdownStatus] = useState<any>(
    reactiveFilters?.handleByDropdownStatus ? reactiveFilters?.handleByDropdownStatus : []
  )

  const [listOptions, setListOptions] = useState<any>([])
  const [listDropdownStatus, setListDropdownStatus] = useState<any>(
    serviceFilters?.list ? serviceFilters?.list : []
  )

  const [isLoading, setIsLoading] = useState<any>(false)
  const [requestTabTableData, setRequestTabTableData] = useState<any>([])
  const [jobTabTableData, setJobTabTableData] = useState<any>([])
  const [serviceTabTableData, setServiceTabTableData] = useState<any>([])
  const [checkedValues, setCheckedValues] = useState<any>([])
  let totalSelected: any = 0

  const [maintenanceJobType, setMaintenanceJobType] = useState<any>(2)

  const [refreshData, setRefreshData] = useState<any>(false)

  const goToMiantenance = (v: any) => {
    if (activeTab == 'requests' || activeTab == 'maintenance-reactive') {
      let type: any
      if (v?.maintenanceRequestType == 0) type = 'reactive'
      else if (v?.maintenanceRequestType == 1) type = 'planned'
      else if (v?.maintenanceRequestType == 2) type = 'preventative'

      let jobType: any
      if (v?.maintenanceJobType == 0) jobType = 'dev-clust'
      else if (v?.maintenanceJobType == 1) jobType = 'common-area'
      else if (v?.maintenanceJobType == 2) jobType = 'unit'

      if (v?.saveStatus == 0) navigate(`/maintenance/create-request/${type}/2/${jobType}/${v?._id}`)
      else
        navigate(`/maintenance/request/${type}/${v?._id}`, {
          state: {
            activeTab: activeTab,
          },
        })
    } else if (activeTab == 'maintenance-jobs') {
      navigate(`/maintenance/request/${v?.maintenance_service_requests?.[0]?._id}/job/${v?._id}`)
    }
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    if (activeTab == 'requests' || activeTab == 'maintenance-reactive') {
      getMaintenanceRequestServices(event.selected + 1)
    }
    if (activeTab === 'maintenance-jobs' && refreshData != false) {
      getMaintenanceJobServices(event.selected + 1)
    }
    if (activeTab === 'maintenance-services') {
      getMaintenanceSubServices(event.selected + 1)
    }
  }

  const getAllCount = () => {
    let buildIds: any = []
    let buildIdsInd: any = 0
    let commIds: any = []
    let commIdsInd: any = 0

    let ids: any = []
    let idsInd: any = 0

    let isInternal: any = false

    requestHandleByDropdownStatus?.length > 0 &&
      requestHandleByDropdownStatus?.map((req: any, ind: any) => {
        if (req?.type == 1) {
          ids[idsInd] = req?.value
          idsInd++
        }
        if (req?.type == 0) {
          isInternal = true
        }
      })

    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        if (dev?.devType == 'build') {
          buildIds[buildIdsInd] = dev?.value
          buildIdsInd++
        } else if (dev?.devType == 'comm') {
          commIds[commIdsInd] = dev?.value
          commIdsInd++
        }
      })

    let mainIds: any = []
    requestMainServiceDropdownStatus?.length > 0 &&
      requestMainServiceDropdownStatus?.map((req: any, ind: any) => {
        mainIds[ind] = req?.value
      })

    let subIds: any = []
    requestSubServiceDropdownStatus?.length > 0 &&
      requestSubServiceDropdownStatus?.map((req: any, ind: any) => {
        subIds[ind] = req?.value
      })

    const body = {
      buildingIds: buildIds,
      communityIds: commIds,
      maintenanceMainServiceIds: mainIds,
      maintenanceSubServiceIds: subIds,
      handlerTypes:
        isInternal && ids?.length > 0
          ? [0, 1]
          : isInternal && ids?.length == 0
          ? [0]
          : !isInternal && ids?.length > 0
          ? [1]
          : [],
      vendorIds: ids,
    }
    ApiPost('corporate/maintenance_service_request/get_all_count', body)
      .then((res) => {
        setMaintenanceCount(res?.data?.data)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  const getAllJobsCount = () => {
    let buildIds: any = []
    let buildIdsInd: any = 0
    let commIds: any = []
    let commIdsInd: any = 0

    let ids: any = []
    let idsInd: any = 0

    let isInternal: any = false

    jobHandleByDropdownStatus?.length > 0 &&
      jobHandleByDropdownStatus?.map((req: any, ind: any) => {
        if (req?.type == 1) {
          ids[idsInd] = req?.value
          idsInd++
        }
        if (req?.type == 0) {
          isInternal = true
        }
      })

    selectedDevelopmentsForJobs?.length > 0 &&
      selectedDevelopmentsForJobs?.map((dev: any, ind: any) => {
        if (dev?.devType == 'build') {
          buildIds[buildIdsInd] = dev?.value
          buildIdsInd++
        } else if (dev?.devType == 'comm') {
          commIds[commIdsInd] = dev?.value
          commIdsInd++
        }
      })

    let mainIds: any = []
    jobMainServiceDropdownStatus?.length > 0 &&
      jobMainServiceDropdownStatus?.map((req: any, ind: any) => {
        mainIds[ind] = req?.value
      })

    let subIds: any = []
    jobSubServiceDropdownStatus?.length > 0 &&
      jobSubServiceDropdownStatus?.map((req: any, ind: any) => {
        subIds[ind] = req?.value
      })

    const body = {
      buildingIds: buildIds,
      communityIds: commIds,
      maintenanceMainServiceIds: mainIds,
      maintenanceSubServiceIds: subIds,
      handlerTypes:
        isInternal && ids?.length > 0
          ? [0, 1]
          : isInternal && ids?.length == 0
          ? [0]
          : !isInternal && ids?.length > 0
          ? [1]
          : [],
      vendorIds: ids,
      maintenanceJobType: maintenanceJobType
    }
    ApiPost('corporate/maintenance_service_job/get_all_count', body)
      .then((res) => {
        setServiceJobCount(res?.data?.data)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  const getMainServicesForDropdown = async () => {
    await ApiGet(`corporate/maintenance_main_service`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setMainServiceOptionsForServices(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  const getSubServicesForDropdown = async () => {
    await ApiGet(`corporate/maintenance_sub_service`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setSubServiceOptionsForServices(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  // get Maintenance Request Table Data
  const getMaintenanceRequestServices = async (pageVal:any) => {
    setIsLoading(true)
    setRequestTabTableData([])

    let main: any = []
    let sub: any = []
    let handleType: any = []
    let buildIds: any = []
    let buildIdsInd: any = 0
    let commIds: any = []
    let commIdsInd: any = 0

    let isInternal: any = false

    let vendorInd: any = 0

    if (activeTab === 'requests') {
      requestSubServiceDropdownStatus?.map((v: any, ind: any) => {
        sub[ind] = v?.value
      })
      requestMainServiceDropdownStatus?.map((v: any, ind: any) => {
        main[ind] = v?.value
      })
      requestHandleByDropdownStatus?.map((v: any, ind: any) => {
        if (v?.type == 1) {
          handleType[vendorInd] = v?.value
          vendorInd++
        }
        if (v?.type == 0) isInternal = true
      })

      selectedDevelopments?.map((v: any, ind: any) => {
        if (v?.devType == 'build') {
          buildIds[buildIdsInd] = v?.value
          buildIdsInd++
        } else {
          commIds[commIdsInd] = v?.value
          commIdsInd++
        }
      })
    }

    // if (activeTab === 'maintenance-reactive') {
    //   reactiveSubServiceDropdownStatus?.map((v: any, ind: any) => {
    //     sub[ind] = v?.value
    //   })
    //   reactiveMainServiceDropdownStatus?.map((v: any, ind: any) => {
    //     main[ind] = v?.value
    //   })
    //   reactiveHandleByDropdownStatus?.map((v: any, ind: any) => {
    //     handleType[ind] = v?.value
    //   })
    // }

    let body = {
      page: pageVal,
      limit: limit,
      maintenanceSubServiceIds: sub,
      maintenanceMainServiceIds: main,
      handlerTypes:
        isInternal && handleType?.length > 0
          ? [0, 1]
          : isInternal && handleType?.length == 0
          ? [0]
          : !isInternal && handleType?.length > 0
          ? [1]
          : [],
      // searchDevelopment: activeTab === 'maintenance-reactive' ? reactiveDevelopment : requestDevelopment,
      buildingIds: buildIds,
      communityIds: commIds,
      maintenanceRequestType: activeTab === 'maintenance-reactive' ? [0] : [],
      vendorIds: handleType,
    }

    await ApiPost('corporate/maintenance_service_request/get', body)
      .then((res) => {
        // console.log(res.data.data.maintenance_service_request_data)
        let values: any = []
        for (let i = 0; i < res?.data?.data?.maintenance_service_request_data?.length; i++) {
          values[i] = false
        }
        setCheckedValues(values)
        setRequestTabTableData(res?.data?.data?.maintenance_service_request_data)

        setPageLimit(res?.data?.data?.state?.page_limit)
        setTimeout(() => {
          setIsLoading(false)
        }, 2000)

        // setTotalMaintenanceRequests(res?.data?.data?.state?.data_count)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const deleteRequests = () => {
    setIsLoading(true)
    let ids: any = []

    let i: any = 0

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        ids[i] = requestTabTableData[ind]?._id
        i++
      }
    })

    const body = {
      ids: ids,
    }

    let url: any = `corporate/maintenance_service_request/delete`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        if (activeTab == 'requests' || activeTab == 'maintenance-reactive') {
          getMaintenanceRequestServices(page)
          getAllCount()
          getOverAllCountForTopTabs()
        }
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  const getServicesListForDropdown = async () => {
    await ApiGet(`corporate/maintenance_service_list`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setListOptions(values)
        // setListDropdownStatus([])

        let temp = [...values]
        // Filter out the Properties with checkboxes checked
        // const filteredList = temp.filter((list) => list?.value !== activeTabId)
        // setServicesListOptions(filteredList)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }
  // get Maintenance Sub Service Table data
  const getMaintenanceSubServices = async (pageVal:any) => {
    setIsLoading(true)
    setServiceTabTableData([])

    let main: any = []
    serviceMainServiceDropdownStatus?.map((v: any, ind: any) => {
      main[ind] = v?.value
    })

    let sub: any = []
    serviceSubServiceDropdownStatus?.map((v: any, ind: any) => {
      sub[ind] = v?.value
    })

    let lst: any = []
    listDropdownStatus?.map((v: any, ind: any) => {
      lst[ind] = v?.value
    })

    let body = {
      page: pageVal,
      limit: limit,
      maintenanceSubServiceIds: sub,
      maintenanceMainServiceIds: main,
      maintenanceServiceListIds: lst,
      sortType: sortType?.length > 0 ? sortType?.[0]?.value : 'Request High',
    }

    await ApiPost('corporate/maintenance_sub_service/get', body)
      .then((res) => {
        let values: any = []
        for (let i = 0; i < res?.data?.data?.maintenance_sub_service_data?.length; i++) {
          values[i] = false
        }
        setCheckedValues(values)
        setServiceTabTableData(res?.data?.data?.maintenance_sub_service_data)

        setPageLimit(res?.data?.data?.state?.page_limit)
        setTimeout(() => {
          setIsLoading(false)
        }, 2000)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  // get Maintenance Job Table Data
  const getMaintenanceJobServices = async (pageVal:any) => {
    setIsLoading(true)
    setJobTabTableData([])

    let handleType: any = []

    let buildIds: any = []
    let buildIdsInd: any = 0
    let commIds: any = []
    let commIdsInd: any = 0

    let isInternal: any = false

    let vendorInd: any = 0

    let main: any = []
    jobMainServiceDropdownStatus?.map((v: any, ind: any) => {
      main[ind] = v?.value
    })

    let sub: any = []
    jobSubServiceDropdownStatus?.map((v: any, ind: any) => {
      sub[ind] = v?.value
    })

    jobHandleByDropdownStatus?.map((v: any, ind: any) => {
      if (v?.type == 1) {
        handleType[vendorInd] = v?.value
        vendorInd++
      }
      if (v?.type == 0) isInternal = true
    })

    selectedDevelopmentsForJobs?.map((v: any, ind: any) => {
      if (v?.devType == 'build') {
        buildIds[buildIdsInd] = v?.value
        buildIdsInd++
      } else {
        commIds[commIdsInd] = v?.value
        commIdsInd++
      }
    })

    let body = {
      page: pageVal,
      limit: limit,
      maintenanceSubServiceIds: sub,
      maintenanceMainServiceIds: main,
      handlerTypes:
        isInternal && handleType?.length > 0
          ? [0, 1]
          : isInternal && handleType?.length == 0
          ? [0]
          : !isInternal && handleType?.length > 0
          ? [1]
          : [],
      // searchDevelopment: jobDevelopment,
      maintenanceJobType: maintenanceJobType,
      buildingIds: buildIds,
      communityIds: commIds,
      vendorIds: handleType,
    }

    await ApiPost('corporate/maintenance_service_job/get', body)
      .then((res) => {
        // console.log(res.data.data.maintenance_service_request_data)
        let values: any = []
        for (let i = 0; i < res?.data?.data?.maintenance_service_job_data?.length; i++) {
          values[i] = false
        }
        setCheckedValues(values)
        setJobTabTableData(res?.data?.data?.maintenance_service_job_data)

        setPageLimit(res?.data?.data?.state?.page_limit)
        setTimeout(() => {
          setIsLoading(false)
        }, 2000)

        setTotalServiceJobs(res?.data?.data?.state?.data_count)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const deleteJobs = () => {
    setIsLoading(true)
    let ids: any = []

    let i: any = 0

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        ids[i] = requestTabTableData[ind]?._id
        i++
      }
    })

    const body = {
      ids: ids,
    }

    let url: any = `corporate/maintenance_service_job/delete`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        if (activeTab == 'maintenance-jobs') {
          getMaintenanceJobServices(page)
          getAllJobsCount()
        }
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    getMainServicesForDropdown()
    getSubServicesForDropdown()
    getServicesListForDropdown()
    getAllCount()
    getOverAllCountForTopTabs()
  }, [])

  useEffect(() => {
    localStorage.setItem('maintenanceActiveTab', JSON.stringify(activeTab))
    localStorage.setItem('maintenanceTablePage', JSON.stringify(page))
  }, [activeTab, page])

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '180px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #b0b7c2',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
      backgroundColor: '#f5f8fa',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
      color: '#fff',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#b0b7c2',
      color: '#fff',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#b0b7c2',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #b0b7c2',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#b0b7c2',
        backgroundColor: '#b0b7c2',
      },
      ':focus': {
        borderColor: '#b0b7c2 !important',
        backgroundColor: '#b0b7c2',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#fff',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
      color: '#fff',
    }),
  }

  const [developmentLists, setDevelopmentLists] = useState<any>([])
  const [developmentListsForJobs, setDevelopmentListsForJobs] = useState<any>([])

  const [selectedDevelopments, setSelectedDevelopments] = useState<any>(
    reqFilters?.developmentListsStatus ? reqFilters?.developmentListsStatus : []
  )
  const [selectedDevelopmentsForJobs, setSelectedDevelopmentsForJobs] = useState<any>(
    jobFilters?.developmentListsStatus ? jobFilters?.developmentListsStatus : []
  )

  const [refreshSelectedDevs, setRefreshSelectedDevs] = useState<any>()
  const [refreshSelectedMain, setRefreshSelectedMain] = useState<any>(false)
  const [refreshSelectedSub, setRefreshSelectedSub] = useState<any>(false)
  const [refreshSelectedHandlerType, setRefreshSelectedHandlerType] = useState<any>(false)

  // Maintenance Requests - Filters Dropdown - Developments
  const getFiltersForDevelopment = () => {
    let buildIds: any = []
    let buildIdsInd: any = 0
    let commIds: any = []
    let commIdsInd: any = 0

    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        if (dev?.devType == 'build') {
          buildIds[buildIdsInd] = dev?.value
          buildIdsInd++
        } else if (dev?.devType == 'comm') {
          commIds[commIdsInd] = dev?.value
          commIdsInd++
        }
      })

    const body = {
      buildingIdFilter: buildIds,
      communityIdFilter: commIds,
      unitTypes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      // isAllowEmpty: selectedDevelopments?.length == 0 ? true : false
      isAllowEmpty: false,
      maintenanceJobType: activeTab === 'maintenance-jobs' ? maintenanceJobType : undefined,
    }

    ApiPost(`corporate/maintenance_service_request/filter/development`, body)
      .then((res: any) => {
        updateFilters(res)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  // Service Jobs - Filters Dropdown - Developments
  const getFiltersForDevelopmentForServiceJobs = () => {
    let buildIds: any = []
    let buildIdsInd: any = 0
    let commIds: any = []
    let commIdsInd: any = 0

    selectedDevelopmentsForJobs?.length > 0 &&
      selectedDevelopmentsForJobs?.map((dev: any, ind: any) => {
        if (dev?.devType == 'build') {
          buildIds[buildIdsInd] = dev?.value
          buildIdsInd++
        } else if (dev?.devType == 'comm') {
          commIds[commIdsInd] = dev?.value
          commIdsInd++
        }
      })

    const body = {
      buildingIdFilter: buildIds,
      communityIdFilter: commIds,
      unitTypes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      // isAllowEmpty: selectedDevelopments?.length == 0 ? true : false
      isAllowEmpty: false,
      maintenanceJobType: activeTab === 'maintenance-jobs' ? maintenanceJobType : undefined,
    }

    ApiPost(`corporate/maintenance_service_request/filter/development`, body)
      .then((res: any) => {
        updateFiltersForJobs(res)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (activeTab === 'maintenance-jobs') getFiltersForDevelopmentForServiceJobs()
    else if (activeTab === 'requests') getFiltersForDevelopment()
  }, [refreshSelectedDevs, activeTab, maintenanceJobType])

  // Maintenance Requests - Filters Dropdown - Main Services
  const getFiltersForMainServices = () => {
    let ids: any = []
    requestMainServiceDropdownStatus?.length > 0 &&
      requestMainServiceDropdownStatus?.map((req: any, ind: any) => {
        ids[ind] = req?.value
      })

    const body = {
      maintenanceMainServiceIdFilter: ids,
      unitTypes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      // isAllowEmpty: ids?.length == 0 ? true : false
      isAllowEmpty: false,
    }

    ApiPost(`corporate/maintenance_service_request/filter/main_service`, body)
      .then((res: any) => {
        updateFilters(res)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  // Service Requests -  Filters Dropdown - Main Services
  const getFiltersForMainServicesForServiceJobs = () => {
    let ids: any = []
    jobMainServiceDropdownStatus?.length > 0 &&
      jobMainServiceDropdownStatus?.map((req: any, ind: any) => {
        ids[ind] = req?.value
      })

    const body = {
      maintenanceMainServiceIdFilter: ids,
      unitTypes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      // isAllowEmpty: ids?.length == 0 ? true : false
      isAllowEmpty: false,
      maintenanceJobType: maintenanceJobType,
    }

    ApiPost(`corporate/maintenance_service_request/filter/main_service`, body)
      .then((res: any) => {
        updateFiltersForJobs(res)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (activeTab === 'maintenance-jobs' && refreshSelectedMain != false)
      getFiltersForMainServicesForServiceJobs()
    else if (activeTab === 'requests' && refreshSelectedMain != false) getFiltersForMainServices()
  }, [refreshSelectedMain])

  // Maintenance Requests - Filters Dropdown - Sub Service
  const getFiltersForSubServices = () => {
    let ids: any = []
    requestSubServiceDropdownStatus?.length > 0 &&
      requestSubServiceDropdownStatus?.map((req: any, ind: any) => {
        ids[ind] = req?.value
      })

    const body = {
      maintenanceSubServiceIdFilter: ids,
      unitTypes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      // isAllowEmpty: ids?.length == 0 ? true : false
      isAllowEmpty: false,
    }

    ApiPost(`corporate/maintenance_service_request/filter/sub_service`, body)
      .then((res: any) => {
        updateFilters(res)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  // Service Job - Filters Dropdown - Sub Service
  const getFiltersForSubServicesForServiceJobs = () => {
    let ids: any = []
    jobSubServiceDropdownStatus?.length > 0 &&
      jobSubServiceDropdownStatus?.map((req: any, ind: any) => {
        ids[ind] = req?.value
      })

    const body = {
      maintenanceSubServiceIdFilter: ids,
      unitTypes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      // isAllowEmpty: ids?.length == 0 ? true : false
      isAllowEmpty: false,
      maintenanceJobType: maintenanceJobType,
    }

    ApiPost(`corporate/maintenance_service_request/filter/sub_service`, body)
      .then((res: any) => {
        updateFiltersForJobs(res)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (activeTab === 'maintenance-jobs' && refreshSelectedSub != false)
      getFiltersForSubServicesForServiceJobs()
    else if (activeTab === 'requests' && refreshSelectedSub != false) {
      getFiltersForSubServices()
    }
  }, [refreshSelectedSub])

  // Maintenance Requests - Filters Dropdown - HandlerType
  const getFiltersForHandlerTypes = () => {
    let ids: any = []
    let idsInd: any = 0

    let isInternal: any = false

    requestHandleByDropdownStatus?.length > 0 &&
      requestHandleByDropdownStatus?.map((req: any, ind: any) => {
        if (req?.type == 1) {
          ids[idsInd] = req?.value
          idsInd++
        }
        if (req?.type == 0) {
          isInternal = true
        }
      })

    const body = {
      vendorIdFilter: ids,
      unitTypes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      // isAllowEmpty: ids?.length == 0 ? true : false
      isAllowEmpty: false,
      handlerTypes:
        isInternal && ids?.length > 0
          ? [0, 1]
          : isInternal && ids?.length == 0
          ? [0]
          : !isInternal && ids?.length > 0
          ? [1]
          : [],
    }

    ApiPost(`corporate/maintenance_service_request/filter/vendor`, body)
      .then((res: any) => {
        updateFilters(res)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  // Service Jobs - Filters Dropdown - HandlerType
  const getFiltersForHandlerTypesForServiceJobs = () => {
    let ids: any = []
    let idsInd: any = 0

    let isInternal: any = false

    jobHandleByDropdownStatus?.length > 0 &&
      jobHandleByDropdownStatus?.map((req: any, ind: any) => {
        if (req?.type == 1) {
          ids[idsInd] = req?.value
          idsInd++
        }
        if (req?.type == 0) {
          isInternal = true
        }
      })

    const body = {
      vendorIdFilter: ids,
      unitTypes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      // isAllowEmpty: ids?.length == 0 ? true : false
      isAllowEmpty: false,
      handlerTypes:
        isInternal && ids?.length > 0
          ? [0, 1]
          : isInternal && ids?.length == 0
          ? [0]
          : !isInternal && ids?.length > 0
          ? [1]
          : [],
      maintenanceJobType: maintenanceJobType,
    }

    ApiPost(`corporate/maintenance_service_request/filter/vendor`, body)
      .then((res: any) => {
        updateFiltersForJobs(res)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (activeTab === 'maintenance-jobs' && refreshSelectedHandlerType != false)
      getFiltersForHandlerTypesForServiceJobs()
    else if (activeTab === 'requests' && refreshSelectedHandlerType != false) {
      getFiltersForHandlerTypes()
    }
  }, [refreshSelectedHandlerType])

  const updateFilters = (res: any) => {
    //  Development
    let devValues: any = []
    let selectedDev: any = []
    let selectedDevInd: any = 0

    res?.data?.data?.developmentList?.map((val: any, ind: any) => {
      devValues[ind] = {
        label: `${val?.name}`,
        value: `${val?._id}`,
        devType: val?.isCommunity ? 'comm' : val?.isBuilding ? 'build' : '',
      }
      if (val?.isCheck) {
        selectedDev[selectedDevInd] = devValues[ind]
        selectedDevInd++
      }
    })

    setDevelopmentLists(devValues)
    setSelectedDevelopments(selectedDev)

    // Main Service

    let mainValues: any = []
    let selectedMain: any = []
    let selectedMainInd: any = 0
    res?.data?.data?.maintenanceMainServiceList?.map((val: any, ind: any) => {
      mainValues[ind] = {
        label: `${val?.name}`,
        value: `${val?._id}`,
      }
      if (val?.isCheck) {
        selectedMain[selectedMainInd] = mainValues[ind]
        selectedMainInd++
      }
    })

    setMainServiceOptions(mainValues)
    setRequestMainServiceDropdownStatus(selectedMain)

    // Sub Services
    let subValues: any = []
    let selectedSub: any = []
    let selectedSubInd: any = 0
    res?.data?.data?.maintenanceSubServiceList?.map((val: any, ind: any) => {
      subValues[ind] = {
        label: `${val?.name}`,
        value: `${val?._id}`,
      }
      if (val?.isCheck) {
        selectedSub[selectedSubInd] = subValues[ind]
        selectedSubInd++
      }
    })

    setSubServiceOptions(subValues)
    setRequestSubServiceDropdownStatus(selectedSub)

    //  Handled By
    let handleByValues: any = [
      {
        type: 0,
        value: 0,
        label: 'Internal Team',
      },
    ]

    let selectedHandleBy: any = []
    let selectedHandleByInd: any = 0

    if (
      res?.data?.data?.handlerTypes?.length > 0 &&
      (res?.data?.data?.handlerTypes?.[0] == 0 || res?.data?.data?.handlerTypes?.[1] == 0)
    ) {
      selectedHandleBy = [
        {
          type: 0,
          value: 0,
          label: 'Internal Team',
        },
      ]
      selectedHandleByInd = 1
    }

    res?.data?.data?.vendorList?.map((val: any, ind: any) => {
      handleByValues[ind + 1] = {
        type: 1,
        label: ` ${val?.contactInformation?.[0]?.firstName} ${val?.contactInformation?.[0]?.lastName}`,
        value: `${val?._id}`,
      }
      if (val?.isCheck) {
        selectedHandleBy[selectedHandleByInd] = handleByValues[ind + 1]
        selectedHandleByInd++
      }
    })

    setHandleByOptions(handleByValues)
    setRequestHandleByDropdownStatus(selectedHandleBy)

    setTimeout(()=>{
      setRefreshData(new Date())
    }, 200)

    setIsLoading(false)
  }

  const updateFiltersForJobs = (res: any) => {
    //  Development
    let devValues: any = []
    let selectedDev: any = []
    let selectedDevInd: any = 0

    res?.data?.data?.developmentList?.map((val: any, ind: any) => {
      devValues[ind] = {
        label: `${val?.name}`,
        value: `${val?._id}`,
        devType: val?.isCommunity ? 'comm' : val?.isBuilding ? 'build' : '',
      }
      if (val?.isCheck) {
        selectedDev[selectedDevInd] = devValues[ind]
        selectedDevInd++
      }
    })

    setDevelopmentListsForJobs(devValues)
    setSelectedDevelopmentsForJobs(selectedDev)

    // Main Service

    let mainValues: any = []
    let selectedMain: any = []
    let selectedMainInd: any = 0
    res?.data?.data?.maintenanceMainServiceList?.map((val: any, ind: any) => {
      mainValues[ind] = {
        label: `${val?.name}`,
        value: `${val?._id}`,
      }
      if (val?.isCheck) {
        selectedMain[selectedMainInd] = mainValues[ind]
        selectedMainInd++
      }
    })

    setMainServiceOptions(mainValues)
    setJobMainServiceDropdownStatus(selectedMain)

    // Sub Services
    let subValues: any = []
    let selectedSub: any = []
    let selectedSubInd: any = 0
    res?.data?.data?.maintenanceSubServiceList?.map((val: any, ind: any) => {
      subValues[ind] = {
        label: `${val?.name}`,
        value: `${val?._id}`,
      }
      if (val?.isCheck) {
        selectedSub[selectedSubInd] = subValues[ind]
        selectedSubInd++
      }
    })

    setSubServiceOptions(subValues)
    setJobSubServiceDropdownStatus(selectedSub)

    //  Handled By
    let handleByValues: any = [
      {
        type: 0,
        value: 0,
        label: 'Internal Team',
      },
    ]

    let selectedHandleBy: any = []
    let selectedHandleByInd: any = 0

    if (
      res?.data?.data?.handlerTypes?.length > 0 &&
      (res?.data?.data?.handlerTypes?.[0] == 0 || res?.data?.data?.handlerTypes?.[1] == 0 || res?.data?.data?.handlerTypes?.[2] == 0 || res?.data?.data?.handlerTypes?.[3] == 0)
    ) {
      selectedHandleBy = [
        {
          type: 0,
          value: 0,
          label: 'Internal Team',
        },
      ]
      selectedHandleByInd = 1
    }

    res?.data?.data?.vendorList?.map((val: any, ind: any) => {
      handleByValues[ind + 1] = {
        type: 1,
        label: ` ${val?.contactInformation?.[0]?.firstName} ${val?.contactInformation?.[0]?.lastName}`,
        value: `${val?._id}`,
      }
      if (val?.isCheck) {
        selectedHandleBy[selectedHandleByInd] = handleByValues[ind + 1]
        selectedHandleByInd++
      }
    })

    setHandleByOptions(handleByValues)
    setJobHandleByDropdownStatus(selectedHandleBy)

    setIsLoading(false)

    setTimeout(()=>{
      setRefreshData(new Date())
    }, 200)
  }

  //
  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected.length > 0 ? `${selected.length} Selected` : 'Select Cluster'
  }

  const [overallCount, setOverallCount] = useState<any>()

  const getOverAllCountForTopTabs = () => {
    ApiGet('corporate/maintenance_service_request/overall_count')
      .then((res) => {
        setOverallCount(res?.data?.data)
      })
      .catch((err) => {
        ErrorToast(err.message)
      })
  }

  useEffect(() => {
    if (pathname === '/maintenance' && activeTab === 'requests') {
      const filters = {
        development: requestDevelopment,
        developmentListsStatus: selectedDevelopments,
        handleByDropdownStatus: requestHandleByDropdownStatus,
        subServiceDropdownStatus: requestSubServiceDropdownStatus,
        mainServiceDropdownStatus: requestMainServiceDropdownStatus,
      }
      localStorage.setItem('maintenanceRequestFilters', JSON.stringify(filters))
    } else if (pathname === '/maintenance' && activeTab === 'maintenance-jobs') {
      const filters = {
        development: jobDevelopment,
        developmentListsStatus: selectedDevelopmentsForJobs,
        handleByDropdownStatus: jobHandleByDropdownStatus,
        subServiceDropdownStatus: jobSubServiceDropdownStatus,
        mainServiceDropdownStatus: jobMainServiceDropdownStatus,
      }
      localStorage.setItem('maintenanceJobFilters', JSON.stringify(filters))
    } else if (pathname === '/maintenance' && activeTab === 'maintenance-services') {
      const filters = {
        sort: sortType,
        list: listDropdownStatus,
        subServiceDropdownStatus: serviceSubServiceDropdownStatus,
        mainServiceDropdownStatus: serviceMainServiceDropdownStatus,
      }
      localStorage.setItem('maintenanceServiceFilters', JSON.stringify(filters))
    } else if (pathname === '/maintenance' && activeTab === 'maintenance-reactive') {
      const filters = {
        development: reactiveDevelopment,
        handleByDropdownStatus: reactiveHandleByDropdownStatus,
        subServiceDropdownStatus: reactiveSubServiceDropdownStatus,
        mainServiceDropdownStatus: reactiveMainServiceDropdownStatus,
      }
      localStorage.setItem('maintenanceReactiveFilters', JSON.stringify(filters))
    }
  }, [
    activeTab,
    requestDevelopment,
    jobDevelopment,
    requestHandleByDropdownStatus,
    jobHandleByDropdownStatus,
    requestSubServiceDropdownStatus,
    jobSubServiceDropdownStatus,
    requestMainServiceDropdownStatus,
    jobMainServiceDropdownStatus,
    serviceSubServiceDropdownStatus,
    serviceMainServiceDropdownStatus,
    reactiveDevelopment,
    reactiveHandleByDropdownStatus,
    reactiveMainServiceDropdownStatus,
    reactiveSubServiceDropdownStatus,
    sortType,
    selectedDevelopments,
    selectedDevelopmentsForJobs,
  ])

  useEffect(() => {
    if ((activeTab === 'requests' || activeTab === 'maintenance-reactive') && refreshData != false) {
      setPage(1)
      getMaintenanceRequestServices(1)
      getAllCount()
    }
    if (activeTab === 'maintenance-services' ) {
      setPage(1)
      getMaintenanceSubServices(1)
      getAllCount()
    }
    if (activeTab === 'maintenance-jobs' && refreshData != false) {
      setPage(1)
      getMaintenanceJobServices(1)
      getAllJobsCount()
    }
   
  }, [
    // page,
    // requestSubServiceDropdownStatus,
    // jobSubServiceDropdownStatus,
    // requestMainServiceDropdownStatus,
    // jobMainServiceDropdownStatus,
    // jobDevelopment,
    // requestDevelopment,
    // requestHandleByDropdownStatus,
    // jobHandleByDropdownStatus,
    listDropdownStatus,
    serviceMainServiceDropdownStatus,
    serviceSubServiceDropdownStatus,
    // reactiveDevelopment,
    // reactiveHandleByDropdownStatus,
    // reactiveMainServiceDropdownStatus,
    // reactiveSubServiceDropdownStatus,
    sortType,
    activeTab,

    // maintenanceJobType,

    // selectedDevelopments,
    // selectedDevelopmentsForJobs
    refreshData
  ])

  useEffect(()=>{
    getOverAllCountForTopTabs()

  },[activeTab]) 

  const [tenantReqCount, setTenantReqCount] = useState<any>()

  const updateTenantReqCount = (cnt:any) => {
    setTenantReqCount(cnt)
  }

   //
   const getCRMTickets = () => {
    const body = {
      limit: 25,
      page: 1,
      buildingIds: [],
      communityIds: [],
      unitIds: [],
      tenantIds: [],
      crmTicketTypes: [],
      crmTicketStatus: [],
      crmTicketPriorities: [],
      crmAssignRoleTypes:[1]
    }
    ApiPost(`corporate/crm_ticket/get`, body)
      .then((res: any) => {
       
        setTenantReqCount(res?.data?.data?.state?.data_count)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(()=>{
    getCRMTickets()
  }, [])

  return (
    <>
      <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
        {' '}
        {/* container-xxl */}
        <div className='row'>
          <div className='col-12'>
            <h1 className='page-heading  m-0'>
              <b>Maintenance</b>
            </h1>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 d-flex maintenance' style={{gap: '20px'}}>
            {/* Request tab */}
            <div>
              <div
                className={
                  activeTab == 'requests'
                    ? 'tenancy-active-blue-card d-flex align-items-center'
                    : 'tenancy-green-card d-flex align-items-center'
                }
                onClick={() => {
                  setActiveTab('requests')
                  setPage(1)
                  // setAnnouncementStatus(null)
                }}
              >
                <div
                  className=''
                  style={{
                    fontWeight: '700',
                    width: 'fit-content',
                  }}
                >
                  <img src={maintenance} height={22} width={22} className='mx-3' />
                </div>
                <div>
                  <h2 className='mb-0 text-white'>
                    {activeTab != "requests" ?
                    <>
                    {overallCount
                      ? overallCount?.totalMaintenanceServiceRequest.toLocaleString('en-US', {})
                      : 0}
                      </>
                      :
                      <>
                       {maintenanceCount?.totalNotStarted
                        ? Number(maintenanceCount?.totalNotStarted) +
                          Number(maintenanceCount?.totalInProgress) +
                          Number(maintenanceCount?.totalComplete) +
                          Number(maintenanceCount?.totalCancel)
                        : 0}
                      </>
                      }
                  </h2>
                  <h5 className='mb-0 text-white'>Requests</h5>
                </div>
              </div>
              {activeTab == 'requests' && <div className='blue-active-filter '></div>}
            </div>

            {/* Maintenance Job Tab */}
            <div>
              <div
                className={
                  activeTab == 'maintenance-jobs'
                    ? 'tenancy-active-blue-card d-flex align-items-center'
                    : 'tenancy-green-card d-flex align-items-center'
                }
                onClick={() => {
                  setActiveTab('maintenance-jobs')
                  setPage(1)
                  // setAnnouncementStatus([0])
                }}
              >
                <div
                  className=''
                  style={{
                    fontWeight: '700',
                    width: 'fit-content',
                  }}
                >
                  <img
                    src={maintenanceJob}
                    height={26}
                    width={26}
                    style={{
                      marginRight: '7px',
                    }}
                    className='mx-3'
                  />
                </div>
                <div>
                  <h2 className='mb-0 text-white'>
                  {activeTab != "maintenance-jobs" ?
                    <>
                    {overallCount
                      ? overallCount?.totalMaintenanceServiceJob.toLocaleString('en-US', {})
                      : 0}
                      </>
                      :
                      <>
                       {serviceJobCount?.totalNotStarted
                        ? Number(serviceJobCount?.totalNotStarted) +
                          Number(serviceJobCount?.totalInProgress) +
                          Number(serviceJobCount?.totalComplete) +
                          Number(serviceJobCount?.totalCancel)
                        : 0}
                      </>
                      }
                  </h2>

                  <h5 className='mb-0 text-white'>Jobs</h5>
                </div>
              </div>
              {activeTab == 'maintenance-jobs' && <div className='blue-active-filter '></div>}
            </div>
            {/* Maintenance Services*/}
            <div>
              <div
                className={
                  activeTab == 'maintenance-services'
                    ? 'tenancy-active-blue-card d-flex align-items-center'
                    : 'tenancy-green-card d-flex align-items-center'
                }
                onClick={() => {
                  setActiveTab('maintenance-services')
                  setPage(1)
                  // setAnnouncementStatus([0])
                }}
              >
                <div
                  className=''
                  style={{
                    fontWeight: '700',
                    width: 'fit-content',
                  }}
                >
                  <img
                    src={maintenanceService}
                    height={26}
                    width={26}
                    style={{
                      marginRight: '7px',
                    }}
                    className='mx-3'
                  />
                </div>
                <div>
                  <h2 className='mb-0 text-white'>
                    {overallCount ? overallCount?.totalSubService?.toLocaleString('en-US', {}) : 0}
                  </h2>

                  <h5 className='mb-0 text-white'>Services</h5>
                </div>
              </div>
              {activeTab == 'maintenance-services' && <div className='blue-active-filter '></div>}
            </div>
            {/* Maintenance Reactive*/}
            {/* <div >
              <div
                className={
                  activeTab == 'maintenance-reactive'
                    ? 'tenancy-active-blue-card d-flex align-items-center'
                    : 'tenancy-green-card d-flex align-items-center'
                }
                onClick={() => {
                  setActiveTab('maintenance-reactive')
                  setPage(1)
                  // setAnnouncementStatus([0])
                }}
              >
                <div
                  className=''
                  style={{
                    fontWeight: '700',
                    width: 'fit-content',
                  }}
                >
                  <img
                    src={maintenanceReactive}
                    height={26}
                    width={26}
                    style={{
                      marginRight: '7px',
                    }}
                    className='mx-3'
                  />
                </div>
                <div>
                  <h2 className='mb-0 text-white'>
                    {maintenanceCount?.maintenanceReactiveServiceRequestCount
                      ? maintenanceCount?.maintenanceReactiveServiceRequestCount?.toLocaleString('en-US', {})
                      : 0}
                  </h2>

                  <h5 className='mb-0 text-white'>Reactive</h5>
                </div>
              </div>
              {activeTab == 'maintenance-reactive' && <div className='blue-active-filter '></div>}
            </div> */}
            {/* Maintenance Planned*/}
            {/* <div >
              <div
                className={
                  activeTab == 'maintenance-planned'
                    ? 'tenancy-active-blue-card d-flex align-items-center'
                    : 'tenancy-green-card d-flex align-items-center'
                }
                onClick={() => {
                  setActiveTab('maintenance-planned')
                  setPage(1)
                  // setAnnouncementStatus([0])
                }}
              >
                <div
                  className=''
                  style={{
                    fontWeight: '700',
                    width: 'fit-content',
                  }}
                >
                  <img
                    src={maintenanceReactive}
                    height={26}
                    width={26}
                    style={{
                      marginRight: '7px',
                    }}
                    className='mx-3'
                  />
                </div>
                <div>
                  <h2 className='mb-0 text-white'>
                    {maintenanceCount?.maintenancePlannedServiceRequestCount
                      ? maintenanceCount?.maintenancePlannedServiceRequestCount?.toLocaleString('en-US', {})
                      : 0}
                  </h2>

                  <h5 className='mb-0 text-white'>Planned</h5>
                </div>
              </div>
              {activeTab == 'maintenance-planned' && <div className='blue-active-filter '></div>}
            </div> */}
            {/* Maintenance Preventative*/}
            {/* <div>
              <div
                className={
                  activeTab == 'maintenance-preventative'
                    ? 'tenancy-active-blue-card d-flex align-items-center'
                    : 'tenancy-green-card d-flex align-items-center'
                }
                onClick={() => {
                  setActiveTab('maintenance-preventative')
                  setPage(1)
                  // setAnnouncementStatus([0])
                }}
              >
                <div
                  className=''
                  style={{
                    fontWeight: '700',
                    width: 'fit-content',
                  }}
                >
                  <img
                    src={maintenanceReactive}
                    height={26}
                    width={26}
                    style={{
                      marginRight: '7px',
                    }}
                    className='mx-3'
                  />
                </div>
                <div>
                  <h2 className='mb-0 text-white'>
                    {maintenanceCount?.maintenancePreventativeServiceRequestCount
                      ? maintenanceCount?.maintenancePreventativeServiceRequestCount?.toLocaleString('en-US', {})
                      : 0}
                  </h2>

                  <h5 className='mb-0 text-white'>Preventative</h5>
                </div>
              </div>
              {activeTab == 'maintenance-preventative' && <div className='blue-active-filter '></div>}
            </div> */}

             {/* Tenant Requests*/}
             <div className='ms-auto'>
              <div
                className={
                  activeTab == 'tenant-requests'
                    ? 'tenancy-active-blue-card d-flex align-items-center gray-card'
                    : 'tenancy-green-card d-flex align-items-center gray-card'
                }
                onClick={() => {
                  setActiveTab('tenant-requests')
                  setPage(1)
                  // setAnnouncementStatus([0])
                }}
              >
                <div
                  className=''
                  style={{
                    fontWeight: '700',
                    width: 'fit-content',
                  }}
                >
                  <img
                   src={maintenance}
                    height={26}
                    width={26}
                    style={{
                      marginRight: '7px',
                    }}
                    className='mx-3'
                  />
                </div>
                <div>
                  <h2 className='mb-0 text-white'>
                    {tenantReqCount ? tenantReqCount?.toLocaleString('en-US', {}) : 0}
                  </h2>

                  <h5 className='mb-0 text-white'>Tenant Requests</h5>
                </div>
              </div>
              {activeTab == 'tenant-requests' && <div className='blue-active-filter  gray-card'></div>}
            </div>
          </div>

          {/* Maintenance Requests - Insights */}
          {activeTab == 'requests' && (
            <div className='d-flex mt-10 flex-wrap align-items-stretch'>
              {/* Not Started */}
              <div className='d-flex align-items-center insights-white-card px-3 booked-border me-5 py-2'>
                <div className='h-100 d-flex align-items-center justify-content-between w-100'>
                  <p className='fs-5 mb-0 labl-gry fnt-500 me-2 gry-dark-text'>Not Started </p>
                  <p className='mb-0 fs-3 word-break'>
                    <b>
                      {maintenanceCount?.totalNotStarted ? maintenanceCount?.totalNotStarted : 0}
                    </b>
                  </p>
                </div>
              </div>

              {/*  In Progress*/}
              <div className='d-flex align-items-center insights-white-card px-3 danger-border  me-5 py-2'>
                <div className='h-100 d-flex align-items-center justify-content-between w-100'>
                  <p className='fs-5 mb-0 labl-gry fnt-500 me-2 gry-dark-text'>In-Progress </p>
                  <p className='mb-0 fs-3 word-break'>
                    <b>
                      {maintenanceCount?.totalInProgress ? maintenanceCount?.totalInProgress : 0}
                    </b>
                  </p>
                </div>
              </div>

              {/* Complete */}
              <div className='d-flex align-items-center insights-white-card px-3 active-border me-5 py-2'>
                <div className='h-100 d-flex align-items-center justify-content-between w-100'>
                  <p className='fs-5 mb-0 labl-gry fnt-500 me-2 gry-dark-text'>Complete</p>
                  <p className='mb-0 fs-3 word-break'>
                    <b>{maintenanceCount?.totalComplete ? maintenanceCount?.totalComplete : 0}</b>
                  </p>
                </div>
              </div>

              {/* Cancelled */}
              <div className='d-flex align-items-center insights-white-card px-3 danger-border py-2'>
                <div className='h-100 d-flex align-items-center justify-content-between w-100'>
                  <p className='fs-5 mb-0 labl-gry fnt-500 me-2 gry-dark-text'>Cancelled </p>
                  <p className='mb-0 fs-3 word-break'>
                    <b>{maintenanceCount?.totalCancel ? maintenanceCount?.totalCancel : 0}</b>
                  </p>
                </div>
              </div>
            </div>
          )}

          {activeTab == 'maintenance-jobs' && (
            <div className='row mt-5'>
              <div className='col-12'>
                <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz'>
                  <li className='nav-item'>
                    <a
                      className={`nav-link text-active-primary pb-0 me-5 fs-4 ${
                        maintenanceJobType == 2 ? 'active ' : 'fnt-400'
                      }`}
                      data-bs-toggle='tab'
                      onClick={() => {
                        setMaintenanceJobType(2)
                      }}
                    >
                      Units
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className={`nav-link text-active-primary pb-0 me-5 fs-4 ${
                        maintenanceJobType == 1 ? 'active ' : 'fnt-400'
                      }`}
                      data-bs-toggle='tab'
                      onClick={() => {
                        setMaintenanceJobType(1)
                      }}
                    >
                      Common Areas
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className={`nav-link text-active-primary pb-0 me-5 fs-4 ${
                        maintenanceJobType == 0 ? 'active ' : 'fnt-400'
                      }`}
                      data-bs-toggle='tab'
                      onClick={() => {
                        setMaintenanceJobType(0)
                      }}
                    >
                      Development & Clusters
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}

          {/* Service Jobs - Insights */}
          {activeTab == 'maintenance-jobs' && (
            <div className='d-flex mt-10 flex-wrap align-items-stretch'>
              {/* All Jobs */}
              <div className='d-flex align-items-center insights-white-card px-3 dark-border me-5 py-2'>
                <div className='h-100 d-flex align-items-center justify-content-between w-100'>
                  <p className='fs-5 mb-0 labl-gry fnt-500 me-2 gry-dark-text'>All Jobs</p>
                  <p className='mb-0 fs-3 word-break'>
                    <b>
                      {serviceJobCount?.totalNotStarted
                        ? Number(serviceJobCount?.totalNotStarted) +
                          Number(serviceJobCount?.totalInProgress) +
                          Number(serviceJobCount?.totalComplete) +
                          Number(serviceJobCount?.totalCancel)
                        : 0}
                    </b>
                  </p>
                </div>
              </div>
              {/* Not Started */}
              <div className='d-flex align-items-center insights-white-card px-3 booked-border me-5 py-2'>
                <div className='h-100 d-flex align-items-center justify-content-between w-100'>
                  <p className='fs-5 mb-0 labl-gry fnt-500 me-2 gry-dark-text'>Not Started </p>
                  <p className='mb-0 fs-3 word-break'>
                    <b>{serviceJobCount?.totalNotStarted ? serviceJobCount?.totalNotStarted : 0}</b>
                  </p>
                </div>
              </div>

              {/*  In Progress*/}
              <div className='d-flex align-items-center insights-white-card px-3 warning-border me-5 py-2'>
                <div className='h-100 d-flex align-items-center justify-content-between w-100'>
                  <p className='fs-5 mb-0 labl-gry fnt-500 me-2 gry-dark-text'>In-Progress </p>
                  <p className='mb-0 fs-3 word-break'>
                    <b>{serviceJobCount?.totalInProgress ? serviceJobCount?.totalInProgress : 0}</b>
                  </p>
                </div>
              </div>

              {/* Complete */}
              <div className='d-flex align-items-center insights-white-card px-3 active-border me-5 py-2'>
                <div className='h-100 d-flex align-items-center justify-content-between w-100'>
                  <p className='fs-5 mb-0 labl-gry fnt-500 me-2 gry-dark-text'>Complete</p>
                  <p className='mb-0 fs-3 word-break'>
                    <b>{serviceJobCount?.totalComplete ? serviceJobCount?.totalComplete : 0}</b>
                  </p>
                </div>
              </div>

              {/* Cancelled */}
              <div className='d-flex align-items-center insights-white-card px-3 danger-border py-2'>
                <div className='h-100 d-flex align-items-center justify-content-between w-100'>
                  <p className='fs-5 mb-0 labl-gry fnt-500 me-2 gry-dark-text'>Cancelled </p>
                  <p className='mb-0 fs-3 word-break'>
                    <b>{serviceJobCount?.totalCancel ? serviceJobCount?.totalCancel : 0}</b>
                  </p>
                </div>
              </div>
            </div>
          )}

          {(activeTab == 'maintenance-services' || activeTab == 'requests' || activeTab === 'maintenance-jobs') && (
            <div className='row py-5 gy-3 mx-0 mt-5' style={{gap: '0px'}}>
              <div className='col-md-8 d-flex align-items-center  px-0 '>
                <div className='xyz me-5 pe-3'>
                  <label className='head-text' style={{fontWeight: '600'}}>
                    {' '}
                    Search{' '}
                  </label>
                </div>

                {activeTab === 'maintenance-services' && (
                  <>
                    <div className='test property me-4 '>
                      <div
                        className={`multi-select-container jobs-property ${
                          listDropdownStatus.length === 0 ? 'no-value list' : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={listOptions}
                          value={listDropdownStatus}
                          onChange={setListDropdownStatus}
                          labelledBy='List'
                          overrideStrings={{
                            allItemsAreSelected: 'All ListS', // Custom text for when all items are selected
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>

                    <div className='test property me-4 '>
                      <div
                        className={`multi-select-container jobs-property ${
                          serviceMainServiceDropdownStatus.length === 0
                            ? 'no-value mainService'
                            : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={mainServiceOptionsForServices}
                          value={serviceMainServiceDropdownStatus}
                          onChange={setServiceMainServiceDropdownStatus}
                          labelledBy='Main Service'
                          overrideStrings={{
                            allItemsAreSelected: 'All Main Services', // Custom text for when all items are selected
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>

                    <div className='test property me-4'>
                      <div
                        className={`multi-select-container jobs-property ${
                          serviceSubServiceDropdownStatus.length === 0
                            ? 'no-value subService'
                            : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={subServiceOptionsForServices}
                          value={serviceSubServiceDropdownStatus}
                          onChange={setServiceJobSubServiceDropdownStatus}
                          labelledBy='Sub Service'
                          overrideStrings={{
                            allItemsAreSelected: 'All Sub Services', // Custom text for when all items are selected
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>
                  </>
                )}

                {activeTab === 'requests' && (
                  <>
                    <div className='test property me-4 '>
                      <div
                        className={`multi-select-container jobs-property dev ${
                          selectedDevelopments?.length === 0 ? 'no-value' : 'has-value '
                        }`}
                      >
                        <MultiSelect
                          options={developmentLists}
                          value={selectedDevelopments}
                          onChange={(e: any) => {
                            setSelectedDevelopments(e)
                            setRefreshSelectedDevs(new Date())
                          }}
                          labelledBy='Developments'
                          valueRenderer={CustomValueRenderer}
                          overrideStrings={{
                            allItemsAreSelected: `${selectedDevelopments?.length} Selected`,
                          }}
                        />
                      </div>
                    </div>

                    <div className='test property me-4 '>
                      <div
                        className={`multi-select-container jobs-property ${
                          requestMainServiceDropdownStatus.length === 0
                            ? 'no-value mainService'
                            : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={mainServiceOptions}
                          value={requestMainServiceDropdownStatus}
                          onChange={(e: any) => {
                            setRequestMainServiceDropdownStatus(e)
                            setRefreshSelectedMain(new Date())
                          }}
                          labelledBy='Main Service'
                          overrideStrings={{
                            allItemsAreSelected: 'All Main Services', // Custom text for when all items are selected
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>

                    <div className='test property me-4'>
                      <div
                        className={`multi-select-container jobs-property ${
                          requestSubServiceDropdownStatus.length === 0
                            ? 'no-value subService'
                            : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={subServiceOptions}
                          value={requestSubServiceDropdownStatus}
                          onChange={(e: any) => {
                            setRequestSubServiceDropdownStatus(e)
                            setRefreshSelectedSub(new Date())
                          }}
                          labelledBy='Sub Service'
                          overrideStrings={{
                            allItemsAreSelected: 'Sub Services', // Custom text for when all items are selected
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>

                    <div className='test property me-4 '>
                      <div
                        className={`multi-select-container jobs-property ${
                          requestHandleByDropdownStatus.length === 0
                            ? 'no-value handledBy'
                            : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={handleByOptions}
                          value={requestHandleByDropdownStatus}
                          onChange={(e: any) => {
                            setRequestHandleByDropdownStatus(e)
                            setRefreshSelectedHandlerType(new Date())
                          }}
                          labelledBy='Handle By'
                          overrideStrings={{
                            allItemsAreSelected: 'Handle By', // Custom text for when all items are selected
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>
                  </>
                )}

                {activeTab === 'maintenance-jobs' && (
                  <>
                    <div className='test property me-4 '>
                      <div
                        className={`multi-select-container jobs-property dev ${
                          selectedDevelopmentsForJobs?.length === 0 ? 'no-value' : 'has-value '
                        }`}
                      >
                        <MultiSelect
                          options={developmentListsForJobs}
                          value={selectedDevelopmentsForJobs}
                          onChange={(e: any) => {
                            setSelectedDevelopmentsForJobs(e)
                            setRefreshSelectedDevs(new Date())
                          }}
                          labelledBy='Developments'
                          valueRenderer={CustomValueRenderer}
                          overrideStrings={{
                            allItemsAreSelected: `${selectedDevelopmentsForJobs?.length} Selected`,
                          }}
                        />
                      </div>
                    </div>

                    <div className='test property me-4 '>
                      <div
                        className={`multi-select-container jobs-property ${
                          jobMainServiceDropdownStatus.length === 0
                            ? 'no-value mainService'
                            : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={mainServiceOptions}
                          value={jobMainServiceDropdownStatus}
                          onChange={(e: any) => {
                            setJobMainServiceDropdownStatus(e)
                            setRefreshSelectedMain(new Date())
                          }}
                          labelledBy='Main Service'
                          overrideStrings={{
                            allItemsAreSelected: 'All Main Services', // Custom text for when all items are selected
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>

                    <div className='test property me-4'>
                      <div
                        className={`multi-select-container jobs-property ${
                          jobSubServiceDropdownStatus.length === 0
                            ? 'no-value subService'
                            : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={subServiceOptions}
                          value={jobSubServiceDropdownStatus}
                          onChange={(e: any) => {
                            setJobSubServiceDropdownStatus(e)
                            setRefreshSelectedSub(new Date())
                          }}
                          labelledBy='Sub Service'
                          overrideStrings={{
                            allItemsAreSelected: 'Sub Services', // Custom text for when all items are selected
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>

                    <div className='test property me-4 '>
                      <div
                        className={`multi-select-container jobs-property ${
                          jobHandleByDropdownStatus.length === 0
                            ? 'no-value handledBy'
                            : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={handleByOptions}
                          value={jobHandleByDropdownStatus}
                          onChange={(e: any) => {
                            setJobHandleByDropdownStatus(e)
                            setRefreshSelectedHandlerType(new Date())
                          }}
                          labelledBy='Handle By'
                          overrideStrings={{
                            allItemsAreSelected: 'Handle By', // Custom text for when all items are selected
                          }}
                          valueRenderer={CustomValueRenderer}
                        />
                      </div>
                    </div>
                  </>
                )}

                {activeTab === 'maintenance-reactive' && (
                  <>
                    <div className='xyz me-4'>
                      <Paper component='form' style={searchContainerStyle}>
                        <InputBase
                          placeholder='Development'
                          style={inputBaseStyle}
                          inputProps={{'aria-label': 'Development'}}
                          value={reactiveDevelopment}
                          onChange={(e: any) => setReactiveDevelopment(e.target.value)}
                        />
                        <IconButton style={searchIconStyle} aria-label='Announcement'>
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </div>

                    <div className='test property me-4 '>
                      <div
                        className={`multi-select-container ${
                          reactiveMainServiceDropdownStatus.length === 0
                            ? 'no-value mainService'
                            : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={mainServiceOptions}
                          value={reactiveMainServiceDropdownStatus}
                          onChange={setReactiveMainServiceDropdownStatus}
                          labelledBy='Main Service'
                          overrideStrings={{
                            allItemsAreSelected: 'All Main Services', // Custom text for when all items are selected
                          }}
                        />
                      </div>
                    </div>

                    <div className='test property me-4'>
                      <div
                        className={`multi-select-container ${
                          reactiveSubServiceDropdownStatus.length === 0
                            ? 'no-value subService'
                            : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={subServiceOptions}
                          value={reactiveSubServiceDropdownStatus}
                          onChange={setReactiveSubServiceDropdownStatus}
                          labelledBy='Sub Service'
                          overrideStrings={{
                            allItemsAreSelected: 'Sub Services', // Custom text for when all items are selected
                          }}
                        />
                      </div>
                    </div>

                    <div className='test property me-4 '>
                      <div
                        className={`multi-select-container ${
                          reactiveHandleByDropdownStatus.length === 0
                            ? 'no-value handledBy'
                            : 'has-value'
                        }`}
                      >
                        <MultiSelect
                          options={handleByOptions}
                          value={reactiveHandleByDropdownStatus}
                          onChange={setReactiveHandleByDropdownStatus}
                          labelledBy='Handle By'
                          overrideStrings={{
                            allItemsAreSelected: 'Handled By', // Custom text for when all items are selected
                          }}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div
                className='col-md-4 mx-0 d-flex  align-items-center justify-content-end  pe-0'
                style={{gap: '10px'}}
              >
                {checkedValues?.length > 0 && (
                  <>
                    {checkedValues?.map((ch: any, ind: any) => {
                      if (ch == true) totalSelected = totalSelected + 1
                      return <></>
                    })}
                  </>
                )}

                {totalSelected > 0 && (
                  <div className='d-flex align-items-center'>
                    <div className='d-flex align-items-center '>
                      <h4 className='my-2' style={{color: 'black'}}>
                        <label className='labl-gry'>
                          {' '}
                          <i>Total Selected </i>
                        </label>
                      </h4>
                      <span className='ms-5 ps-1 my-2 me-4 white-dark-theme-color'>
                        <b>{totalSelected}</b>
                      </span>
                    </div>
                    <div className=''>
                      <button
                        type='button'
                        className='btn btn-sm fw-bold red-hollow-btn m-0 ps-2'
                        onClick={() => {
                          swal
                            .fire({
                              html: ` 
                                        <div class='fs-3'> <b>Delete ${
                                          activeTab === 'requests' ||
                                          activeTab === 'maintenance-reactive'
                                            ? 'Requests'
                                            : activeTab === 'maintenance-jobs'
                                            ? 'Jobs'
                                            : '-'
                                        }</></div>
                                                <div class='fs-5 mt-4'> Are you sure you want to delete the selected ${
                                                  activeTab === 'requests' ||
                                                  activeTab === 'maintenance-reactive'
                                                    ? 'requests'
                                                    : activeTab === 'maintenance-jobs'
                                                    ? 'jobs'
                                                    : '-'
                                                }? </div>`,
                              // icon: 'warning',
                              showConfirmButton: true,
                              confirmButtonColor: '#35475e',
                              confirmButtonText: 'Yes',
                              showCancelButton: true,
                              cancelButtonText: 'No',
                              cancelButtonColor: '#fff',
                              customClass: {
                                confirmButton: 'custom-confirm-button',
                                cancelButton: 'custom-cancel-button',
                                popup: 'custom-popup',
                              },
                              reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                            })
                            .then((res: any) => {
                              if (res.isConfirmed) {
                                if (activeTab === 'requests') {
                                  deleteRequests()
                                }
                                if (activeTab === 'maintenance-jobs') {
                                  deleteJobs()
                                }
                              }
                            })
                        }}
                        disabled={isLoading}
                      >
                        <img src={trashImg} height={18} width={18} className='me-4' /> Delete
                      </button>
                    </div>
                  </div>
                )}
                {activeTab === 'requests' && (
                  <div className=''>
                    <button
                      type='button'
                      className='btn btn-sm fw-bold px-2 green-submit-btn w-100'
                      onClick={() => {
                        // navigate('/create-announcement/1')
                        setRequestModal(true)
                      }}
                    >
                      <img src={wrenchImg} height={20} width={20} className='me-3' /> Request
                      Maintenance
                    </button>
                  </div>
                )}

                {activeTab === 'maintenance-services' && (
                  <div className='me-4'>
                    <Select
                      styles={customStyles}
                      isSearchable={false}
                      options={sortOptions}
                      name='sortType'
                      onChange={(e: any) => {
                        console.log(e)
                        setSortType([e])
                      }}
                      placeholder={'Sort'}
                      value={sortType ? sortType : []}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className='row'>
          <div className='col-12'>
            {/* request tab table */}
            {(activeTab == 'requests' || activeTab === 'maintenance-reactive') && (
              <div className='card card-flush py-5  px-7'>
                <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
                  <div className='table-responsive'>
                    <table
                      className='table align-start table-row-dashed fs-8 gy-5 '
                      id='kt_ecommerce_sales_table'
                    >
                      <thead className='fs-7'>
                        <th className='text-start min-w-25px test'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                            <input
                              className='form-check-input cursor-pointer'
                              type='checkbox'
                              id='vacant0'
                              name='none'
                              checked={
                                totalSelected == requestTabTableData?.length &&
                                requestTabTableData?.length != 0
                                  ? true
                                  : false
                              }
                              onChange={(e: any) => {
                                let values = [...checkedValues]

                                if (e.target.checked) {
                                  for (let i = 0; i < requestTabTableData?.length; i++) {
                                    values[i] = true
                                  }
                                } else {
                                  for (let i = 0; i < requestTabTableData?.length; i++) {
                                    values[i] = false
                                  }
                                }
                                setCheckedValues(values)
                              }}
                            />
                          </div>
                        </th>
                        <th className='text-start min-w-100px ps-0'>Request ID</th>
                        {activeTab !== 'maintenance-reactive' && (
                          <th className='text-start min-w-100px ps-0'>Type</th>
                        )}
                        <th className='text-start min-w-125px ps-0'>Main Service</th>
                        <th className='text-start min-w-125px ps-0'>Sub Service</th>
                        <th className='text-start min-w-125px ps-0'>Development</th>
                        <th className='text-center min-w-100px '>Jobs</th>
                        <th className='text-center min-w-125px'>Request Status</th>
                        <th className='text-center min-w-150px ps-0'>Duration (Days)</th>
                        <th className='text-start min-w-100px ps-0'>Handled By</th>
                        <th className='text-start min-w-125px ps-0 '>Vendor Status</th>
                        <th className='text-start min-w-125px ps-0'>Request Cost</th>
                        <th className='text-start min-w-75px ps-0 '>Rating</th>
                        <th className='text-center min-w-150px ps-0'>Action Required</th>
                        <th className='text-start min-w-100px ps-5'>Requestor</th>
                        <th className='text-center min-w-125px ps-0'>Form Status</th>
                        <th className='text-start min-w-150px ps-5'>Created By</th>
                        <th className='text-start min-w-150px ps-0'>Last Updated</th>
                      </thead>
                      <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                        {requestTabTableData?.length > 0 ? (
                          requestTabTableData?.map((requestTabItem: any, i: any) => {
                            return (
                              <tr>
                                <td className='text-start ps-0 test'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                    <input
                                      className='form-check-input cursor-pointer'
                                      type='checkbox'
                                      id='vacant0'
                                      name='none'
                                      checked={checkedValues[i] == true ? true : false}
                                      onChange={(e: any) => {
                                        const values = [...checkedValues]
                                        values[i] = !values[i]
                                        setCheckedValues(values)
                                      }}
                                    />
                                  </div>
                                </td>
                                <td
                                  data-kt-ecommerce-order-filter='order_id'
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {requestTabItem?.id != undefined ? requestTabItem?.id : '-'}
                                </td>
                                {activeTab != 'maintenance-reactive' && (
                                  <td
                                    className='text-start cursor-pointer  ps-0'
                                    onClick={() => goToMiantenance(requestTabItem)}
                                  >
                                    {requestTabItem?.maintenanceRequestType == 0
                                      ? 'Reactive'
                                      : requestTabItem?.maintenanceRequestType == 1
                                      ? 'Planned'
                                      : requestTabItem?.maintenanceRequestType == 2
                                      ? 'Preventative'
                                      : '-'}
                                  </td>
                                )}
                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {requestTabItem?.maintenance_main_service.length > 0
                                    ? requestTabItem?.maintenance_main_service[0]?.name
                                    : '-'}
                                </td>
                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.maintenance_sub_service.length > 0
                                    ? requestTabItem?.maintenance_sub_service[0]?.name
                                    : '-'}
                                </td>

                                <td
                                  className='text-start cursor-pointer ps-0 min-w-125'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.community?.[0]?.name
                                    ? requestTabItem?.community?.[0]?.name
                                    : requestTabItem?.building?.[0]?.name
                                    ? requestTabItem?.building?.[0]?.name
                                    : '-'}
                                </td>
                                <td
                                  className='text-center cursor-pointer '
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div className='status create-st mx-auto'>
                                    {requestTabItem?.totalJobs != undefined
                                      ? requestTabItem?.totalJobs
                                      : '-'}
                                  </div>
                                </td>
                                <td
                                  className='text-center cursor-pointer ps-0 '
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div
                                    className={
                                      requestTabItem?.maintenanceRequestStatus == 0
                                        ? 'status booked-bar ms-5 status-w-120 mx-auto'
                                        : requestTabItem?.maintenanceRequestStatus == 1
                                        ? 'status expiring-bar ms-5 status-w-120 mx-auto'
                                        : requestTabItem?.maintenanceRequestStatus == 2
                                        ? 'status active-bar ms-5 status-w-120 mx-auto'
                                        : requestTabItem?.maintenanceRequestStatus == 3
                                        ? 'status expired-bar ms-5 status-w-120 mx-auto'
                                        : requestTabItem?.maintenanceRequestStatus == 4
                                        ? 'status req-bar ms-5 status-w-120 mx-auto'
                                        : 'status  no-st-bar ms-5 status-w-120 mx-auto'
                                    }
                                  >
                                    {requestTabItem?.maintenanceRequestStatus == 0
                                      ? 'Not Started'
                                      : requestTabItem?.maintenanceRequestStatus == 1
                                      ? 'In-Progress'
                                      : requestTabItem?.maintenanceRequestStatus == 2
                                      ? 'Completed'
                                      : requestTabItem?.maintenanceRequestStatus == 3
                                      ? 'Cancelled'
                                      : requestTabItem?.maintenanceRequestStatus == 4
                                      ? 'Requested'
                                      : '-'}
                                  </div>
                                </td>
                                <td
                                  className='text-center cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.duration != undefined
                                    ? requestTabItem?.duration
                                    : '-'}
                                </td>
                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.handlerType == 0
                                    ? 'Internal Team'
                                    : requestTabItem?.handlerType == 1
                                    ? 'External Team'
                                    : '-'}
                                </td>
                                <td
                                  className='text-start cursor-pointer ps-0 '
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.vendorStatus == 0
                                    ? 'Not Submitted'
                                    : requestTabItem?.vendorStatus == 1
                                    ? 'Submitted'
                                    : '-'}
                                </td>
                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.totalAmount != undefined
                                    ? `AED ${requestTabItem?.totalAmount}`
                                    : ' AED-'}
                                </td>
                                <td
                                  className='text-start cursor-pointer ps-0 min-w-75px'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div className='d-flex align-items-center '>
                                    <img src={starYellow} height={18} width={18} className='me-1' />
                                    {requestTabItem?.rating != undefined
                                      ? requestTabItem?.rating
                                      : '-'}
                                  </div>
                                </td>
                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div
                                    className={
                                      requestTabItem?.approval_workflow?.[0]?.approvalStatus === 0
                                        ? 'status pend-st status-w-140 px-0'
                                        : requestTabItem?.approval_workflow?.[0]?.approvalStatus ===
                                          1
                                        ? 'status create-st status-w-140 px-0'
                                        : requestTabItem?.approval_workflow?.[0]?.approvalStatus ===
                                          2
                                        ? 'status pending-approval-st status-w-140 px-0'
                                        : requestTabItem?.approval_workflow?.[0]?.approvalStatus ===
                                          3
                                        ? 'status approve-st status-w-140 px-0'
                                        : requestTabItem?.approval_workflow?.[0]?.approvalStatus ===
                                          4
                                        ? 'status sent-back-st status-w-140 px-0'
                                        : requestTabItem?.approval_workflow?.[0]?.approvalStatus ===
                                          5
                                        ? 'status approve-st status-w-140 px-0'
                                        : requestTabItem?.approval_workflow?.[0]?.approvalStatus ===
                                          6
                                        ? 'status cancelled-st status-w-140 px-0'
                                        : ''
                                    }
                                  >
                                    {requestTabItem?.approval_workflow?.length > 0 ? (
                                      <>
                                        {requestTabItem?.approval_workflow?.[0]?.approvalStatus ===
                                        0
                                          ? 'Pending Submission'
                                          : requestTabItem?.approval_workflow?.[0]
                                              ?.approvalStatus === 1
                                          ? 'Submitted'
                                          : requestTabItem?.approval_workflow?.[0]
                                              ?.approvalStatus === 2
                                          ? 'Pending Approval'
                                          : requestTabItem?.approval_workflow?.[0]
                                              ?.approvalStatus === 3
                                          ? 'Approved'
                                          : requestTabItem?.approval_workflow?.[0]
                                              ?.approvalStatus === 4
                                          ? 'Sent Back'
                                          : requestTabItem?.approval_workflow?.[0]
                                              ?.approvalStatus === 5
                                          ? 'Approved'
                                          : requestTabItem?.approval_workflow?.[0]
                                              ?.approvalStatus === 6
                                          ? 'Locked'
                                          : ''}
                                      </>
                                    ) : (
                                      <div className='status not-saved status-w-140 px-0'>-</div>
                                    )}
                                  </div>
                                </td>
                                <td
                                  className='text-start cursor-pointer ps-5'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.requestorType == 0
                                    ? 'Staff'
                                    : requestTabItem?.requestorType == 1
                                    ? 'Tenant'
                                    : '-'}
                                </td>
                                <td
                                  className='text-center cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div
                                    className={
                                      requestTabItem?.saveStatus == 0
                                        ? 'status draft-st status-w-120'
                                        : requestTabItem?.saveStatus == 1
                                        ? 'status create-st status-w-120'
                                        : requestTabItem?.saveStatus == 2
                                        ? 'status cancelled-st status-w-120'
                                        : 'status not-saved status-w-120'
                                    }
                                  >
                                    {requestTabItem?.saveStatus == 0
                                      ? 'Draft'
                                      : requestTabItem?.saveStatus == 1
                                      ? 'Created'
                                      : requestTabItem?.saveStatus == 2
                                      ? 'Cancelled'
                                      : '-'}
                                  </div>
                                </td>

                                <td
                                  className='text-start  cursor-pointer ps-5'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                  data-order='2022-09-11'
                                >
                                  <p className='my-0'>
                                    {requestTabItem?.createdBy != undefined &&
                                      `${requestTabItem?.createdBy?.firstName} ${requestTabItem?.createdBy?.lastName} - `}
                                  </p>
                                  <p className='my-0'>
                                    {requestTabItem?.createdAt
                                      ? `${moment
                                          ?.utc(requestTabItem?.createdAt)
                                          ?.local()
                                          ?.format('DD.MM.YYYY - hh:mm A')}`
                                      : '-'}
                                  </p>
                                </td>
                                <td
                                  className='py-3 ps-0 cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {requestTabItem?.updatedBy?.length > 0 ? (
                                    <>
                                      <p className='my-0'>
                                        {requestTabItem?.updatedBy?.[0] &&
                                          `${requestTabItem?.updatedBy[0]?.firstName} ${requestTabItem?.updatedBy[0]?.lastName} - `}
                                      </p>
                                      <p className='my-0'>
                                        {requestTabItem?.updatedAt
                                          ? `${moment
                                              ?.utc(requestTabItem?.updatedAt)
                                              ?.local()
                                              ?.format('DD.MM.YYYY - hh:mm A')}`
                                          : '-'}
                                      </p>
                                    </>
                                  ) : (
                                    <>{'-'}</>
                                  )}
                                </td>
                                {/* <td
                                className='py-3 ps-0 cursor-pointer ps-0'
                                onClick={() => goToMiantenance(requestTabItem)}
                              >
                                <p className='my-0'>
                                  {requestTabItem?.updatedBy?.[0] &&
                                   `${requestTabItem?.updatedBy?.[0]?.firstName} ${requestTabItem?.updatedBy?.[0]?.lastName} - `}
                                </p>
                                <p className='my-0'>
                                  {requestTabItem?.updatedAt
                                   ? ` ${moment(requestTabItem?.updatedAt).format(
                                     'DD.MM.YYYY hh:mm A'
                                   )}`
                                   : '- -'}
                                </p>
                              </td> */}
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td colSpan={10} className='text-center'>
                              <img src={noData} alt='' width={350} />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* pagination start*/}
                  <div className='row mb-5'>
                    <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                    <div className='row mt-5'>
                      <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='kt_ecommerce_sales_table_paginate'
                        >
                          <ReactPaginate
                            breakLabel='...'
                            nextLabel='>'
                            pageClassName='paginate_button page-item +'
                            pageLinkClassName='page-link'
                            containerClassName='pagination'
                            activeClassName='active'
                            previousClassName='paginate_button page-item previous'
                            nextClassName='paginate_button page-item next'
                            previousLinkClassName='page-link'
                            nextLinkClassName='page-link'
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageLimit}
                            forcePage={page - 1}
                            previousLabel='<'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* pagination end*/}
                </div>
              </div>
            )}

            {/* job tab table data */}
            {activeTab === 'maintenance-jobs' && (
              <div className='card card-flush py-5  px-7'>
                <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
                  <div className='table-responsive '>
                    <table
                      className='table align-start table-row-dashed fs-8 gy-5 '
                      id='kt_ecommerce_sales_table'
                    >
                      <thead className='fs-7'>
                        <th className='text-start min-w-25px test'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                            <input
                              className='form-check-input cursor-pointer'
                              type='checkbox'
                              id='vacant0'
                              name='none'
                              checked={
                                totalSelected == jobTabTableData?.length &&
                                jobTabTableData?.length != 0
                                  ? true
                                  : false
                              }
                              onChange={(e: any) => {
                                let values = [...checkedValues]

                                if (e.target.checked) {
                                  for (let i = 0; i < jobTabTableData?.length; i++) {
                                    values[i] = true
                                  }
                                } else {
                                  for (let i = 0; i < jobTabTableData?.length; i++) {
                                    values[i] = false
                                  }
                                }
                                setCheckedValues(values)
                              }}
                            />
                          </div>
                        </th>
                        <th className='text-start min-w-100px ps-0'>Request ID</th>
                        <th className='text-start min-w-100px ps-0'>Job ID</th>
                        {activeTab !== 'maintenance-reactive' && (
                          <th className='text-start min-w-100px ps-0'>Type</th>
                        )}
                        <th className='text-start min-w-125px ps-0'>Main Service</th>
                        <th className='text-start min-w-125px ps-0'>Sub Service</th>
                        {maintenanceJobType != 0 && (
                          <th
                            className={`text-start ps-0 ${
                              maintenanceJobType == 1 ? 'min-w-175px' : 'min-w-125px'
                            }`}
                          >
                            {maintenanceJobType == 2 ? 'Unit No' : 'Common Area'}
                          </th>
                        )}
                        {maintenanceJobType != 0 && (
                          <th className='text-start  min-w-125px ps-0'>Floor</th>
                        )}
                        <th className='text-start min-w-125px ps-0'>Group</th>
                        <th className='text-start  min-w-125px ps-0'>Cluster</th>
                        <th className='text-start  min-w-125px ps-0'>Development</th>
                        <th className='text-center min-w-125px'>Urgency</th>
                        <th className='text-center min-w-125px'>Job Status</th>
                        <th className='text-center min-w-175px ps-0 '>Schedule</th>
                        <th className='text-center min-w-150px ps-0 ms-3'>Duration (Days)</th>
                        <th className='text-start min-w-100px ps-0'>Handled By</th>
                        <th className='text-start min-w-100px ps-0'>Job Cost</th>
                        <th className='text-start min-w-75px ps-0 '>Rating</th>
                        <th className='text-center min-w-150px ps-0'>Action Required</th>
                      </thead>
                      <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                        {jobTabTableData?.length ? (
                          jobTabTableData?.map((requestTabItem: any, i: any) => {
                            return (
                              <tr>
                                <td className='text-start ps-0 test'>
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                    <input
                                      className='form-check-input cursor-pointer'
                                      type='checkbox'
                                      id='vacant0'
                                      name='none'
                                      checked={checkedValues[i] == true ? true : false}
                                      onChange={(e: any) => {
                                        const values = [...checkedValues]
                                        values[i] = !values[i]
                                        setCheckedValues(values)
                                      }}
                                    />
                                  </div>
                                </td>
                                <td
                                  data-kt-ecommerce-order-filter='order_id'
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {requestTabItem?.maintenance_service_requests[0]?.id != undefined
                                    ? requestTabItem?.maintenance_service_requests[0]?.id
                                    : '-'}
                                </td>
                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.id != undefined ? requestTabItem?.id : '-'}
                                </td>

                                <td
                                  className='text-start cursor-pointer  ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {requestTabItem?.maintenance_service_requests[0]
                                    ?.maintenanceRequestType == 0
                                    ? 'Reactive'
                                    : requestTabItem?.maintenance_service_requests[0]
                                        ?.maintenanceRequestType == 1
                                    ? 'Planned'
                                    : requestTabItem?.maintenance_service_requests[0]
                                        ?.maintenanceRequestType == 2
                                    ? 'Preventative'
                                    : '-'}
                                </td>

                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {requestTabItem?.maintenance_service_requests[0]
                                    ?.maintenance_main_service.length > 0
                                    ? requestTabItem?.maintenance_service_requests[0]
                                        ?.maintenance_main_service[0]?.name
                                    : '-'}
                                </td>
                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.maintenance_service_requests[0]
                                    ?.maintenance_sub_service.length > 0
                                    ? requestTabItem?.maintenance_service_requests[0]
                                        ?.maintenance_sub_service[0]?.name
                                    : '-'}
                                </td>
                                {maintenanceJobType != 0 && (
                                  <td
                                    className='text-start cursor-pointer ps-0 '
                                    onClick={() => goToMiantenance(requestTabItem)}
                                  >
                                    {' '}
                                    {maintenanceJobType == 2 ? (
                                      <>
                                        {requestTabItem?.unit?.length > 0
                                          ? requestTabItem?.unit[0]?.unitNo
                                          : '-'}
                                      </>
                                    ) : (
                                      <>
                                        {requestTabItem?.unit?.length > 0
                                          ? `${requestTabItem?.unit[0]?.unitNo}-${requestTabItem?.unit[0]?.commonAreaName}`
                                          : '-'}
                                      </>
                                    )}
                                  </td>
                                )}

                                {maintenanceJobType != 0 && (
                                  <td
                                    className='text-start cursor-pointer ps-0'
                                    onClick={() => goToMiantenance(requestTabItem)}
                                  >
                                    {' '}
                                    {requestTabItem?.floor?.length > 0
                                      ? requestTabItem?.floor?.[0]?.name
                                      : '-'}
                                  </td>
                                )}
                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.unit_group?.length > 0
                                    ? requestTabItem?.unit_group?.[0]?.name
                                    : '-'}
                                </td>

                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.cluster?.length > 0
                                    ? requestTabItem?.cluster?.[0]?.name
                                    : '-'}
                                </td>

                                <td
                                  className='text-start cursor-pointer ps-0 min-w-125'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.community?.length > 0
                                    ? requestTabItem?.community[0]?.name
                                    : requestTabItem?.building?.length
                                    ? requestTabItem?.building?.[0]?.name
                                    : '-'}
                                </td>

                                <td
                                  className='text-center cursor-pointer '
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div
                                    className={
                                      requestTabItem?.jobUrgency == 0
                                        ? 'status no-st-bar mx-auto status-w-120'
                                        : requestTabItem?.jobUrgency == 1
                                        ? 'status expired-bar mx-auto status-w-120'
                                        : 'status no-st-bar mx-auto status-w-120'
                                    }
                                  >
                                    {requestTabItem?.jobUrgency == 0
                                      ? 'Standard'
                                      : requestTabItem?.jobUrgency == 1
                                      ? 'Emergency'
                                      : '-'}
                                  </div>
                                </td>
                                <td
                                  className='text-center cursor-pointer'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {/* {requestTabItem?.requestStatus ?? '-'} */}
                                  <div
                                    className={
                                      requestTabItem?.maintenanceJobStatus == 0
                                        ? 'status booked-bar mx-5 status-w-120'
                                        : requestTabItem?.maintenanceJobStatus == 1
                                        ? 'status expiring-bar mx-5 status-w-120'
                                        : requestTabItem?.maintenanceJobStatus == 2
                                        ? 'status expired-bar mx-5 status-w-120'
                                        : requestTabItem?.maintenanceJobStatus == 3
                                        ? 'status active-bar mx-5 status-w-120'
                                        : 'status  no-st-bar mx-5 status-w-120'
                                    }
                                  >
                                    {requestTabItem?.maintenanceJobStatus == 0
                                      ? 'Not Started'
                                      : requestTabItem?.maintenanceJobStatus == 1
                                      ? 'In-Progress'
                                      : requestTabItem?.maintenanceJobStatus == 2
                                      ? 'Canceled'
                                      : requestTabItem?.maintenanceJobStatus == 3
                                      ? 'Complete'
                                      : '-'}
                                  </div>
                                </td>
                                <td
                                  className='text-center cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {/* {requestTabItem?.schedule ?? '-'} */}
                                  <tr
                                    className='d-flex align-items-center'
                                    style={{columnGap: '2px'}}
                                  >
                                    {requestTabItem?.maintenance_service_job_schedule?.length >
                                    0 ? (
                                      <>
                                        <td className='text-start cursor-pointer ps-0 flex-grow-1 min-w-100px'>
                                          <p className='p-0 m-0'>
                                            {requestTabItem?.maintenance_service_job_schedule?.[0]
                                              ?.date
                                              ? `${moment
                                                  ?.utc(
                                                    requestTabItem
                                                      ?.maintenance_service_job_schedule?.[0]?.date
                                                  )
                                                  ?.local()
                                                  ?.format('DD.MM.YYYY')}`
                                              : '-'}
                                          </p>
                                          {requestTabItem?.maintenance_service_job_schedule?.[0]
                                            ?.startTime ? (
                                            <p className='p-0 m-0'>
                                              {requestTabItem?.maintenance_service_job_schedule?.[0]
                                                ?.startTime
                                                ? `${moment
                                                    ?.utc(
                                                      requestTabItem
                                                        ?.maintenance_service_job_schedule?.[0]
                                                        ?.startTime
                                                    )
                                                    ?.local()
                                                    ?.format('HH:mm')}`
                                                : '-'}{' '}
                                              -{' '}
                                              {requestTabItem?.maintenance_service_job_schedule?.[0]
                                                ?.endTime
                                                ? `${moment
                                                    ?.utc(
                                                      requestTabItem
                                                        ?.maintenance_service_job_schedule?.[0]
                                                        ?.endTime
                                                    )
                                                    ?.local()
                                                    ?.format('HH:mm')}`
                                                : '-'}
                                            </p>
                                          ) : (
                                            '-'
                                          )}
                                        </td>
                                        <td className='text-center cursor-pointer ps-0 flex-grow-1 min-w-100px'>
                                          {requestTabItem?.maintenance_service_job_schedule?.[0]
                                            ?.visitStatus === 0 ? (
                                            <span>Not Assign</span>
                                          ) : requestTabItem?.maintenance_service_job_schedule?.[0]
                                              ?.visitStatus === 1 ? (
                                            <span style={{color: '#0d9e91'}}>On Time</span>
                                          ) : requestTabItem?.maintenance_service_job_schedule?.[0]
                                              ?.visitStatus === 2 ? (
                                            <span style={{color: '#ff9a1e'}}>Late</span>
                                          ) : requestTabItem?.maintenance_service_job_schedule[0]
                                              ?.visitStatus === 3 ? (
                                            <span style={{color: '#0d9e91'}}>No Show</span>
                                          ) : (
                                            '-'
                                          )}
                                        </td>
                                      </>
                                    ) : (
                                      <>
                                        <td className='text-start cursor-pointer ps-0 flex-grow-1 min-w-100px'>
                                          -
                                        </td>
                                        <td className='text-end cursor-pointer ps-0 flex-grow-1 min-w-100px'>
                                          -
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                </td>
                                <td
                                  className='text-center cursor-pointer ps-0 ms-5'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.duration != undefined
                                    ? requestTabItem?.duration
                                    : '-'}
                                </td>
                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.maintenance_service_requests[0]?.handlerType == 0
                                    ? 'Internal Team'
                                    : requestTabItem?.maintenance_service_requests[0]
                                        ?.handlerType == 1
                                    ? 'External Team'
                                    : '-'}
                                </td>

                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.totalAmount != undefined
                                    ? `AED ${requestTabItem?.totalAmount}`
                                    : ' AED-'}
                                </td>
                                <td
                                  className='text-start cursor-pointer ps-0 min-w-75px'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div className='d-flex align-items-center '>
                                    <img src={starYellow} height={18} width={18} className='me-1' />
                                    {requestTabItem?.rating != undefined
                                      ? requestTabItem?.rating
                                      : '-'}
                                  </div>
                                </td>
                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {/* <div
                                    className={
                                      requestTabItem?.maintenance_service_requests[0]?.approval_workflow?.[0]?.approvalStatus === 0
                                        ? 'tenancy-stats pend-st'
                                        : requestTabItem?.maintenance_service_requests[0]?.approval_workflow?.[0]?.approvalStatus === 2
                                          ? 'tenancy-stats pending-approval-st'
                                          : requestTabItem?.maintenance_service_requests[0]?.approval_workflow?.[0]?.approvalStatus === 5
                                            ? 'tenancy-stats approve-st'
                                            : requestTabItem?.maintenance_service_requests[0]?.approval_workflow?.[0]?.approvalStatus === 10
                                              ? 'tenancy-stats cancelled-st'
                                              : ''
                                    }
                                  >
                                    {requestTabItem?.maintenance_service_requests[0]?.approval_workflow?.length > 0 ? (
                                      <>
                                        {requestTabItem?.maintenance_service_requests[0]?.approval_workflow?.[0]?.approvalStatus === 0
                                          ? 'Pending Submission'
                                          : requestTabItem?.maintenance_service_requests[0]?.approval_workflow?.[0]?.approvalStatus === 2
                                            ? 'Pending Approval'
                                            : requestTabItem?.maintenance_service_requests[0]?.approval_workflow?.[0]?.approvalStatus === 5
                                              ? 'Approved'
                                              : requestTabItem?.maintenance_service_requests[0]?.approval_workflow?.[0]?.approvalStatus === 6
                                                ? 'Canceled'
                                                : ''}
                                      </>
                                    ) : (
                                      <div className='tenancy-stats not-saved'>-</div>
                                    )}
                                  </div> */}
                                  <div
                                    className={
                                      requestTabItem?.maintenance_service_requests[0]
                                        ?.approval_workflow[0]?.approvalStatus === 0
                                        ? 'status pend-st status-w-140 px-0'
                                        : requestTabItem?.maintenance_service_requests[0]
                                            ?.approval_workflow[0]?.approvalStatus === 1
                                        ? 'status create-st status-w-140 px-0'
                                        : requestTabItem?.maintenance_service_requests[0]
                                            ?.approval_workflow[0]?.approvalStatus === 2
                                        ? 'status pending-approval-st status-w-140 px-0'
                                        : requestTabItem?.maintenance_service_requests[0]
                                            ?.approval_workflow[0]?.approvalStatus === 3
                                        ? 'status approve-st status-w-140 px-0'
                                        : requestTabItem?.maintenance_service_requests[0]
                                            ?.approval_workflow[0]?.approvalStatus === 4
                                        ? 'status sent-back-st status-w-140 px-0'
                                        : requestTabItem?.maintenance_service_requests[0]
                                            ?.approval_workflow[0]?.approvalStatus === 5
                                        ? 'status approve-st status-w-140 px-0'
                                        : requestTabItem?.maintenance_service_requests[0]
                                            ?.approval_workflow[0]?.approvalStatus === 6
                                        ? 'status cancelled-st status-w-140 px-0'
                                        : ''
                                    }
                                  >
                                    {requestTabItem?.maintenance_service_requests[0]
                                      ?.approval_workflow?.length > 0 ? (
                                      <>
                                        {requestTabItem?.maintenance_service_requests[0]
                                          ?.approval_workflow[0]?.approvalStatus === 0
                                          ? 'Pending Submission'
                                          : requestTabItem?.maintenance_service_requests[0]
                                              ?.approval_workflow[0]?.approvalStatus === 1
                                          ? 'Submitted'
                                          : requestTabItem?.maintenance_service_requests[0]
                                              ?.approval_workflow[0]?.approvalStatus === 2
                                          ? 'Pending Approval'
                                          : requestTabItem?.maintenance_service_requests[0]
                                              ?.approval_workflow[0]?.approvalStatus === 3
                                          ? 'Approved'
                                          : requestTabItem?.maintenance_service_requests[0]
                                              ?.approval_workflow[0]?.approvalStatus === 4
                                          ? 'Sent Back'
                                          : requestTabItem?.maintenance_service_requests[0]
                                              ?.approval_workflow[0]?.approvalStatus === 5
                                          ? 'Approved'
                                          : requestTabItem?.maintenance_service_requests[0]
                                              ?.approval_workflow[0]?.approvalStatus === 6
                                          ? 'Locked'
                                          : ''}
                                      </>
                                    ) : (
                                      <div className='status not-saved status-w-140 px-0'>-</div>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td colSpan={17} className='text-center'>
                              <img src={noData} alt='' width={350} />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* pagination start*/}
                  <div className='row mb-5'>
                    <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                    <div className='row mt-5'>
                      <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='kt_ecommerce_sales_table_paginate'
                        >
                          <ReactPaginate
                            breakLabel='...'
                            nextLabel='>'
                            pageClassName='paginate_button page-item +'
                            pageLinkClassName='page-link'
                            containerClassName='pagination'
                            activeClassName='active'
                            previousClassName='paginate_button page-item previous'
                            nextClassName='paginate_button page-item next'
                            previousLinkClassName='page-link'
                            nextLinkClassName='page-link'
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageLimit}
                            forcePage={page - 1}
                            previousLabel='<'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* pagination end*/}
                </div>
              </div>
            )}

            {activeTab === 'maintenance-services' && (
              <div className='card card-flush py-5  px-7'>
                <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
                  <div className='table-responsive '>
                    <table
                      className='table align-middle table-row-dashed fs-8 gy-5 '
                      id='kt_ecommerce_sales_table'
                    >
                      <thead className='fs-7'>
                        <th className='text-start min-w-25px ps-0'></th>
                        <th className='text-start min-w-100px ps-0'>List</th>
                        <th className='text-start min-w-100px ps-0'>Main Service</th>
                        <th className='text-start min-w-150px ps-0'>Sub Service</th>
                        <th className='text-start min-w-75px ps-0'>Request</th>
                        <th className='text-center min-w-100px px-0'>Complete</th>
                        <th className='text-center min-w-100px px-0'>Not Started</th>
                        <th className='text-center min-w-100px px-0'>In-Progress</th>
                        <th className='text-center min-w-100px px-0'>Cancelled</th>
                        <th className='text-center min-w-125px px-0'>Average Duration</th>
                        <th className='text-start min-w-100px ps-0'>Rating</th>
                      </thead>
                      <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                        {serviceTabTableData?.length > 0 ? (
                          serviceTabTableData?.map((requestTabItem: any, i: any) => {
                            return (
                              <tr>
                                <td>{i + 1}.</td>
                                <td
                                  data-kt-ecommerce-order-filter='order_id'
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {requestTabItem?.maintenance_service_list != undefined
                                    ? requestTabItem?.maintenance_service_list?.name
                                    : '-'}
                                </td>

                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {requestTabItem?.maintenance_main_service != undefined
                                    ? requestTabItem?.maintenance_main_service?.name
                                    : '-'}
                                </td>
                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.name != undefined ? requestTabItem?.name : '-'}
                                </td>

                                <td
                                  className='text-start cursor-pointer ps-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.totalMaintenanceRequests != undefined
                                    ? requestTabItem?.totalMaintenanceRequests
                                    : '-'}
                                </td>
                                <td
                                  className='text-center  cursor-pointer px-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div className='status tenant-approve-st mx-auto'>
                                    {requestTabItem?.totalMaintenanceCompleteRequests != undefined
                                      ? requestTabItem?.totalMaintenanceCompleteRequests
                                      : '-'}
                                  </div>
                                </td>
                                <td
                                  className='text-center  cursor-pointer px-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div className='status draft-st mx-auto'>
                                    {requestTabItem?.totalMaintenanceNotStartedRequests != undefined
                                      ? requestTabItem?.totalMaintenanceNotStartedRequests
                                      : '-'}
                                  </div>
                                </td>
                                <td
                                  className='text-center cursor-pointer px-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div className='status pending-approval-st mx-auto'>
                                    {requestTabItem?.totalMaintenanceInProgressRequests != undefined
                                      ? requestTabItem?.totalMaintenanceInProgressRequests
                                      : '-'}
                                  </div>
                                </td>
                                <td
                                  className='text-center cursor-pointer px-0 '
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div className='status cancelled-st mx-auto'>
                                    {requestTabItem?.totalMaintenanceCancelledRequests != undefined
                                      ? requestTabItem?.totalMaintenanceCancelledRequests
                                      : '-'}
                                  </div>
                                </td>

                                <td
                                  className='text-center cursor-pointer px-0'
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  {requestTabItem?.averageDuration != undefined
                                    ? `${requestTabItem?.averageDuration} Days`
                                    : '- Days'}
                                </td>

                                <td
                                  className='text-start cursor-pointer ps-0 '
                                  onClick={() => goToMiantenance(requestTabItem)}
                                >
                                  {' '}
                                  <div className='d-flex align-items-center '>
                                    <img src={starYellow} height={18} width={18} className='me-1' />
                                    {requestTabItem?.rating != undefined
                                      ? requestTabItem?.rating
                                      : '-'}
                                  </div>
                                </td>
                              </tr>
                            )
                          })
                        ) : (
                          <tr>
                            <td colSpan={10} className='text-center'>
                              <img src={noData} alt='' width={350} />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* pagination start*/}
                  <div className='row mb-5'>
                    <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                    <div className='row mt-5'>
                      <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                        <div
                          className='dataTables_paginate paging_simple_numbers'
                          id='kt_ecommerce_sales_table_paginate'
                        >
                          <ReactPaginate
                            breakLabel='...'
                            nextLabel='>'
                            pageClassName='paginate_button page-item +'
                            pageLinkClassName='page-link'
                            containerClassName='pagination'
                            activeClassName='active'
                            previousClassName='paginate_button page-item previous'
                            nextClassName='paginate_button page-item next'
                            previousLinkClassName='page-link'
                            nextLinkClassName='page-link'
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageLimit}
                            forcePage={page - 1}
                            previousLabel='<'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* pagination end*/}
                </div>
              </div>
            )}

            {activeTab == 'tenant-requests' && (
             <CRMResidents updateCount={(val:any)=>{updateTenantReqCount(val)}} />
            )}
          </div>
        </div>
      </div>

      {requestModal && (
        <RequestModel
          show={requestModal}
          handleClose={() => {
            setRequestModal(false)
          }}
        />
      )}
    </>
  )
}

export default Maintenance
