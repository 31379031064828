import React from 'react'
import {Box, IconButton, Typography} from '@mui/material'
import {IoMdInformationCircleOutline} from 'react-icons/io'
import Switch from 'react-switch'
import CustomTooltip from '../../../../../../../../components/Tooltip/CustomTooltip'
import {booleanToInt} from '../../../../../../../../../Utilities/utils'
import DocumentName from './components/DocumentName'
import {useFormContext} from 'react-hook-form'
import LatePaymentTooltipContent from '../../LatePaymentFees/LatePaymentTooltipContent'
import ChargeSelector from './components/ChargeSelector'
import AmountTypeSelector from './components/AmountTypeSelector'

interface ChargeSettingsProps {
  cardHeading?: string
  cardSubHeading?: string
  type: string
  itemData: any
  setItemData: (value: any) => void
  namePlaceholderText: string
}

const ChargeSettings: React.FC<ChargeSettingsProps> = ({
  cardHeading,
  cardSubHeading,
  type,
  itemData,
  setItemData,
  namePlaceholderText,
}) => {
  const {
    control,
    register,
    setValue,
    formState: {errors},
    trigger,
    reset,
  } = useFormContext()
  return (
    <div>
      <Box
        className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3 pb-10 h-100'
        sx={{display: 'flex', flexDirection: 'column'}}
      >
        <Box className='card-body pt-0 mt-5 py-0 px-3'>
          <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'start'}}>
            {/* Heading */}
            <Box sx={{display: 'block'}}>
              <Typography variant='h6' className='white-dark-theme-color fnt-700'>
                {cardHeading}
              </Typography>
              <Typography className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>
                {cardSubHeading}
              </Typography>
            </Box>

            {/* Info & Switch Control */}
            <Box sx={{display: 'flex', alignItems: 'center', marginLeft: 'auto'}}>
              <CustomTooltip title={<LatePaymentTooltipContent />} placement='top'>
                <IconButton>
                  <IoMdInformationCircleOutline
                    className='cursor-pointer head-text'
                    style={{height: '16px', width: '16px'}}
                  />
                </IconButton>
              </CustomTooltip>

              <Switch
                onChange={(val: any) => {
                  setItemData({
                    ...itemData,
                    ['activityStatus']: booleanToInt(val),
                  })
                }}
                checked={itemData?.activityStatus === 1}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor='#00cf99'
                offColor='#b0bec5'
                className='ms-3 me-5'
                height={23}
                width={42}
              />
              <Typography className='fs-5 fnt-500 pt-2 head-text mb-1 light-dark-theme-color'>
                <i>{itemData?.activityStatus === 1 ? 'Active' : 'Inactive'}</i>
              </Typography>
            </Box>
          </Box>

          {/* Documement name */}
          <Box>
            <DocumentName
              register={register}
              errors={errors}
              itemData={itemData}
              setItemData={setItemData}
              setValue={setValue}
              trigger={trigger}
              type={type}
              placeholderText={namePlaceholderText}
            />

            {/* Charge Type */}
            <Box>
              <ChargeSelector itemData={itemData} setItemData={setItemData} />
            </Box>

            {/* Amount Calculation Type */}
            <Box>
              <AmountTypeSelector />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  )
}

export default ChargeSettings
