import React, {useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import MediaComments from './MediaComments'
import MediaPopUp from './MediaPopUp'
import SignaturePopup from './SignaturePopup'
import {ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {useNavigate} from 'react-router-dom'
import ReactPaginate from 'react-paginate'
import moment from 'moment'

function ExternalService() {
  const navigate = useNavigate()
  const [signatureModal, setSignatureModal] = React.useState<boolean>(false)
  const [formData, setFormData] = React.useState<any>({})
  const [showMedia, setShowMedia] = useState<any>(false)
  const [approvalRequest, setApprovalRequest] = React.useState<any>()
  const [propertyList, setPropertyList] = React.useState<any>()
  const [requestData, setRequestData] = React.useState<any>()
  const [serviceData, setServicedata] = React.useState<any>()
  const [pageLimit, setPageLimit] = React.useState<any>()
  const [listData, setListData] = React.useState<any>()
  const [page, setPage] = React.useState<any>(1)
  const serviceId = window.location.pathname?.split('/')[4]
  const [submitButton, setSubmitButton] = React.useState<any>()
  const [jobId, setJobId] = React.useState<any>()
  const[unitNo, setUnitNo] =  useState<any>()

  const handleChnage = () => {}

  const ExternalData = new Array(2).fill(0)
  const Properties = new Array(2).fill(0)

  const getServiceData = async () => {
    await ApiGet(`corporate/service_request/${serviceId}`)
      .then((res) => {
        setRequestData(res?.data?.data[0])
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getServiceJob = async () => {
    const body = {
      serviceRequestId: window.location.pathname?.split('/')[4],
    }
    await ApiPost(`corporate/service_job/job_list`, body)
      .then((res) => {
        setServicedata(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const serviceRequestData = async () => {
    const body = {
      page: page,
      limit: 2,
      serviceRequestId: window.location.pathname?.split('/')[4],
    }
    await ApiPost(`corporate/service_job/get`, body)
      .then((res) => {
        setPropertyList(res?.data?.data?.service_job_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getApprovalServiceRequest = async () => {
    const body = {
      serviceRequestId: window.location.pathname?.split('/')[4],
    }
    await ApiPost(`corporate/approval_request/get`, body)
      .then((res) => {
        console.log('res?.data?.data++', res?.data?.data)
        setApprovalRequest(res?.data?.data?.serviceApprovalRequest)
        setSubmitButton(res?.data?.data?.viewSubmitVendorButton)
        setListData(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    getServiceData()
    getServiceJob()
    getApprovalServiceRequest()
    serviceRequestData()
  }, [window.location.pathname?.split('/')[4]])

  const sendBackApproval = () => {
    const body = {
      id: approvalRequest[0]?._id,
      approvalStatus: 4,
    }

    ApiPut('corporate/approval_request', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        // navigate(`/request-management/service`)
      })
      .catch((err) => ErrorToast(err.message))
  }
  const rejectApproval = () => {
    const body = {
      id: approvalRequest[0]?._id,
      approvalStatus: 3,
    }

    ApiPut('corporate/approval_request', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        navigate(`/request-management/service`)
      })
      .catch((err) => ErrorToast(err.message))
  }
  const submitApproval = () => {
    const body = {
      id: serviceId,
      requestStatus: 5,
    }

    ApiPut('corporate/service_request', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
      })
      .catch((err) => ErrorToast(err.message))
  }
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }
  const openModal = (id: any) => {
    setShowMedia(true)
    setJobId(id)
  }

  function timeConvert(n: any) {
    var num = n
    var hours = num / 60
    var rhours = Math.floor(hours)
    var minutes = (hours - rhours) * 60
    var rminutes = Math.round(minutes)
    return rhours + ':' + rminutes
  }
  const moveVendor = () => {
    navigate(`/request-management/service/vendor-list`)
    localStorage.setItem('inter', 'false')
  }

  const updateSignature = (newValue: string) => {
  }

  const closeBoth = () => {

  }
  return (
    <>
      <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
        <div id='' className='app-container container-xxl pt-0 mt-0'>
          <div className='d-flex align-items-baseline flex-wrap mr-5'>
            <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
              <li className='breadcrumb-item '>
                <a
                  className='text-muted px-2 cursor-pointer'
                  onClick={() => navigate(`/request-management/service`)}
                >
                  Request
                </a>
              </li>
              <li className='breadcrumb-item '>
                <a className='text-muted px-2 cursor-pointer' onClick={() => moveVendor()}>
                  {' '}
                  External Request
                </a>
              </li>
              <li className='breadcrumb-item active'>
                <a className='px-2'> Approved Request</a>
              </li>
            </ul>
          </div>
          <div className='mb-5'>
            <a
              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_create_app'
              onClick={() => moveVendor()}
            >
              Back
            </a>
          </div>
          <div className='card card-flush app-container py-5'>
            <h4 className='fw-bold mb-10'>Approved Workflow</h4>
            <Row>
              <div className='col-md-3'>
                <div className='d-flex'>
                  <div>Request Status</div>:{' '}
                  <div className='d-flex'>
                    {' '}
                    {listData?.isUnderApproval === true ? (
                      <div
                        style={{
                          height: '10px',
                          width: '10px',
                          borderRadius: '50%',
                          background: '#007a59',
                          marginTop: '5px',
                          marginRight: '3px',
                        }}
                      ></div>
                    ) : (
                      <div
                        style={{
                          height: '10px',
                          width: '10px',
                          borderRadius: '50%',
                          border: '2px solid black',
                          marginTop: '5px',
                          marginRight: '3px',
                        }}
                      ></div>
                    )}
                    Under Approval{' '}
                  </div>{' '}
                </div>
              </div>
              <div className='col-md-3'>
                Pending With: {listData?.pendingWith[0]?.corporate[0]?.firstName}
                {listData?.pendingWith[0]?.corporate[0]?.lastName}-
                {moment(listData?.pendingWith[0]?.corporate[0]?.updatedAt).format(
                  'DD/MM/YYYY  HH:mm:ss'
                )}
              </div>
              <div className='col-md-6 d-flex justify-content-end align-items-center'>
                <button
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                  onClick={() => setSignatureModal(true)}
                >
                  Approve
                </button>
                <button
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                  onClick={() => sendBackApproval()}
                >
                  Send Back
                </button>
                <button
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                  onClick={() => rejectApproval()}
                >
                  Reject
                </button>
                {submitButton === true && (
                  <button
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => submitApproval()}
                  >
                    Submit To Vendor
                  </button>
                )}
              </div>
            </Row>
            <div className='pt-0 table-responsive mt-5'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-100px'>User</th>
                    <th className='min-w-100px'>Updated</th>
                    <th className='min-w-100px'>Status</th>
                    <th className='min-w-100px'>Signature</th>
                    <th className='min-w-100px'></th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {approvalRequest?.map((f: any, i: any) => {
                    return (
                      <tr>
                        <td>
                          {f?.corporate[0]?.firstName}
                          {f?.corporate[0]?.lastName}
                        </td>
                        <td>{moment(f?.updatedAt).format('DD/MM/YYYY')}</td>
                        <td>
                          {f?.approvalStatus === 2
                            ? 'Approved'
                            : f?.approvalStatus === 3
                            ? 'Rejected'
                            : f?.approvalStatus === 1
                            ? 'Requested'
                            : f?.approvalStatus === 0
                            ? 'pending Approval'
                            : 'Send Back'}
                        </td>
                        <td>
                          {f?.eSignature && (
                            <a
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                              href={`${Bucket}${f?.eSignature}`}
                              target='_blank'
                            >
                              e signature
                            </a>
                          )}
                          {f?.writtenSignature && (
                            <a
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                              href={`${Bucket}${f?.writtenSignature}`}
                              target='_blank'
                            >
                              written signature
                            </a>
                          )}
                        </td>
                        <td>{f.comment}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <Row className='my-5'>
              <div className='col-md-3'>
                <h5 className='gray-500'>
                  Request ID:{' '}
                  <span className='' style={{color: 'black'}}>
                    {requestData?.id}
                  </span>
                </h5>
              </div>
              <div className='col-md-3'>
                <h5 className='gray-500'>
                  Request Source:{' '}
                  <span className='' style={{color: 'black'}}>
                    External - Vendor List
                  </span>
                </h5>
              </div>
            </Row>
            <Row>
              <Col>
                <h3 className='page-heading d-flex text-darkfs-3 flex-column justify-content-center mb-0 mb-5'>
                  Request Summary
                </h3>
                <div className='d-flex select-box p-5'>
                  <div className='me-10'>
                    <p className='m-0'>
                      Service: <b>{requestData?.main_service[0]?.name}</b>
                    </p>
                    <p className='m-0'>
                      Assigned To: <b>{requestData?.staff[0]?.name}</b>
                    </p>
                    <p className='m-0'>
                      Total Buildings/Communities: <b>{serviceData?.total_building}</b>
                    </p>
                    <p className='m-0'>
                      Total Units: <b>{serviceData?.total_units}</b>
                    </p>
                    <p className='m-0'>Total Jobs:{serviceData?.total_units} </p>
                  </div>
                  <div className='me-10'>
                    <p className='m-0 d-flex align-items-center'>
                      Request Urgency:{' '}
                      <b>
                        <span
                          style={{
                            marginLeft: '5px',
                            width: '10px',
                            height: '10px',
                            borderRadius: '50%',
                            background: 'green',
                            display: 'inline-block',
                          }}
                        ></span>{' '}
                        <b>{requestData?.requestUrgency === 0 ? 'Standard' : 'Emergency'}</b>
                      </b>
                    </p>
                    <p className='m-0'>Preferred Schedules For Service </p>
                    <p className='m-0'>
                      <b>
                        {moment(requestData?.schedule[0]?.preferScheduleDate).format('DD/MM/YYYY')}{' '}
                        - {timeConvert(requestData?.schedule[0]?.preferScheduleTime)}
                      </b>
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className='mb-3'>
                  <h6 className='text-muted m-0 mr-2 min-w-100px mb-2'>
                    Request Details (Shared with Selected Vendor)
                  </h6>
                  <textarea
                    className='form-control form-control-solid mb-8'
                    rows={3}
                    name='comment'
                    placeholder='Type or paste emails here'
                  ></textarea>
                </div>
              </Col>
            </Row>
            <h4 className='fw-bold my-10'>Properties</h4>
            <div className='pt-0 table-responsive mt-5'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-100px'>Job ID</th>
                    <th className='min-w-100px'>Development Name</th>
                    <th className='min-w-100px'>Floor#/Cluster</th>
                    <th className='min-w-100px'>Unit ###</th>
                    <th className='min-w-100px'>Unit Type</th>
                    <th className='min-w-100px'># Bedrooms</th>
                    <th className='min-w-100px'></th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {propertyList?.map((f: any, i: any) => {
                    return (
                      <tr>
                        <td>{f?._id}</td>
                        <td>{f?.communityId ? 'Community' : 'Building'}</td>
                        <td>{f?.communityId ? 'Cluster' : 'Floor'}</td>
                        <td>{f?.unit[0]?.unitNo}</td>
                        <td>{f?.unit[0]?.unitType}</td>
                        <td>{f?.unit[0]?.bedrooms}</td>
                        <td>
                          <button
                            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                            onClick={() => openModal(f)}
                          >
                            Media & Comments
                          </button>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_ecommerce_sales_table_paginate'
                >
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous disabled'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SignaturePopup
        show={signatureModal}
        handleClose={() => {
          setSignatureModal(false)
        }}
        setFormData={setFormData}
        formData={approvalRequest?.map((f: any) => f)}
        getApprovalServiceRequest={getApprovalServiceRequest}
        listData={listData}
        updateSignature={updateSignature}
        closeBoth={closeBoth}
      />
      <MediaPopUp
        unitNo={unitNo}
        show={showMedia}
        handleClose={() => {
          setShowMedia(false)
        }}
        propertyList={propertyList}
        id={jobId}
      />
    </>
  )
}

export default ExternalService
