import React, {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {ApiGet, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../_metronic/helpers'
import {useDispatch} from 'react-redux'
import {setCreateNewService} from '../../../redux/counterSlice'

function Service() {
  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [propertiType, setPropertiType] = useState<any>('Service Providers')
  const [ServiceListCount, setServiceListCount] = React.useState<any>()
  const [requestId, setRequestId] = React.useState<any>()
  const [requestSource, setRequestSource] = React.useState<any>()
  const [requestStatus, setRequestStatus] = React.useState<any>()
  const [serviceList, setServiceList] = React.useState<any>()
  const navigate = useNavigate()
  const pathName = window.location.pathname

  const ListNameItem = new Array(10).fill(0)
  const Vendors = new Array(5).fill(0)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const getServiceListCount = async () => {
    await ApiGet(`corporate/service_request/overview/count`)
      .then((res) => {
        setServiceListCount(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getServiceList = async () => {
    const body = {
      page: page,
      limit: 10,
      serviceRequestId: requestId ? requestId : '',
      requestType: requestSource ? Number(requestSource) : '',
      requestStatus: requestStatus ? Number(requestStatus) : '',
    }
    await ApiPost(`corporate/service_request/overview`, body)
      .then((res) => {
        setServiceList(res?.data?.data?.services_request)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message) {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  React.useEffect(() => {
    getServiceListCount()
    getServiceList()
  }, [requestId, requestSource, requestStatus, page])

  const goToRequest = (el: any) => {
    console.log(el._id)
    if (el.requestType === 0 && el.requestStatus === 0)
      navigate(`/request-management/service/create-request-service/${el?.tenantRequestIds[0]}`)
    else if (
      (el.requestType === 1 || el.requestType === 2) &&
      (el.requestStatus === 1 || el.requestStatus === 2)
    )
      navigate('/request-management/service/create-request-service/approve-request/' + el._id)
    else if (el.requestStatus === 5)
      navigate(`/request-management/service/create-request-service/service-detail/${el?._id}`)
    // scope page
    else if ((el.requestType === 1 || el.requestType === 2) && el.requestStatus === 0) {
      localStorage.setItem('serviceRequestId' + el?._id, el?._id)
      navigate(`/request-management/service/edit-request/${el?._id}`)
    } else console.log()

    // console.log('\nel', el)
    // if (el?.requestorType === 1) {
    //   navigate(`/request-management/service/create-request-service/${el?.tenantRequestId}`)
    //   console.log('tenant')
    // } else if (el?.requestType === 1 && el.requestStatus == 0) {
    //   navigate(`/request-management/service/vendor-list`) //external page from notification
    //   // console.log('external draft')
    // } else if (el?.requestType === 2 && el.requestStatus == 0) {
    //   navigate(`/request-management/service/create-request-service-internal/1`) // internal page from notification
    //   // console.log('internal draft')
    // } else if (el.requestStatus === 4) {
    //   navigate(`/request-management/service/create-request-service/service-detail/${el?._id}`) // scope page
    //   // console.log('approve')
    // } else {
    //   console.log(123)
    // }

    localStorage.setItem('requestorType', el?.requestorType)
    localStorage.setItem('serviceRequestId', el?._id)
  }
  // navigate(`/request-management/service/external-detail/${el?._id}`)
  const dispatch = useDispatch()
  const createNewService = () => {
    // navigate(`/request-management/service/create-request-service-internal/0`)
    // when clicked on REQUEST SERVICE BUTTON
    localStorage.setItem('activeTab', '' + 0 + '')
    localStorage.setItem('totalTab', '' + 1 + '')
    localStorage.setItem('isSave', '0')

    // Clearing All isSave Statuses
    var x = localStorage.getItem('Tabsss')
    if (x !== null) {
      var y = parseInt(x)
      for (let i = 0; i <= y; i++) {
        var z = localStorage.getItem('isSave' + i)
        if (z !== null) {
          localStorage.removeItem('isSave' + i)
        }
      }

      for (let i = 0; i <= y; i++) {
        var z = localStorage.getItem('isSubmit' + i)
        if (z !== null) {
          localStorage.removeItem('isSubmit' + i)
        }
      }

      localStorage.removeItem('fromSubmitAll')
    }

    // Clearing All serviceRequestIds
    x = localStorage.getItem('Tabsss')
    if (x !== null) {
      var y = parseInt(x)
      for (let i = 0; i <= y; i++) {
        var z = localStorage.getItem('serviceRequestId' + i)
        if (z !== null) {
          localStorage.removeItem('serviceRequestId' + i)
        }
      }
    }

    localStorage.removeItem('submitAll')
    localStorage.removeItem('saveAll')
    navigate(`/request-management/service/create-request-service-main`)
    localStorage.setItem('requestorType', '2')
    localStorage.setItem('isFirstTime', 'true')
    dispatch(setCreateNewService(true))
  }

  return (
    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
      <div id='' className='app-container container-xxl pt-0 mt-0'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-5 min-w-200px'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            Manage Requests
          </h1>
        </div>
        <div className='d-flex align-items-center gap-2 mb-6 gap-lg-3 justify-content-center'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li
              className='nav-item'
              onClick={() => {
                navigate(`/vendor-management/serviceproviders`)
              }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                All
              </a>
            </li>
            <li
              className='nav-item'
              onClick={() => {
                navigate(`/request-management/quotation`)
              }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Quotations
              </a>
            </li>
            <li
              className='nav-item'
              onClick={() => {
                navigate(`/request-management/service`)
              }}
            >
              <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                Maintenance & Service
              </a>
            </li>
            <li
              className='nav-item'
              // onClick={() => {
              //   navigate(`/vendor-management/suppliers`)
              // }}
            >
              <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                Products & Goods
              </a>
            </li>
            <li
              className='nav-item'
              // onClick={() => {
              //   navigate(`/vendor-management/suppliers`)
              // }}
            >
              <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                Payments
              </a>
            </li>
          </ul>
        </div>
        <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li
              className='nav-item'
              onClick={() => {
                navigate(`/request-management/service`)
              }}
            >
              <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                Requests
              </a>
            </li>
            <li
              className='nav-item'
              onClick={() => {
                navigate(`/request-management/jobs`)
              }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Jobs
              </a>
            </li>
          </ul>
        </div>
        <div className='card card-flush gap-5 p-5 pt-1'>
          <Row
            className='align-items-center mt-3'
            style={{borderBottom: '1px solid var(--kt-card-border-color)'}}
          >
            <Col  xs ={12} md={12} lg={9} className='order-2 order-sm-2 order-lg-1'>
              <div
                className='card-header align-items-center gap-md-2 d-flex ps-0'
                style={{
                  justifyContent: 'space-between',
                  borderBottom: '0px solid var(--kt-card-border-color)',
                }}
              >
                <div className='mb-10  min-w-200px' style={{display: 'contents'}}>
                  <h5 className='gray-500'>
                    Overall Requests:{' '}
                    <span className='' style={{color: 'black'}}>
                      {ServiceListCount?.overAllCount}
                    </span>
                  </h5>
                </div>
                <div className='mb-10  min-w-200px' style={{display: 'contents'}}>
                  <h5 className='gray-500'>
                    Pending Requests:{' '}
                    <span className='' style={{color: 'black'}}>
                      {ServiceListCount?.pendingRequestCount}
                    </span>
                  </h5>
                </div>
                <div className='mb-10  min-w-200px' style={{display: 'contents'}}>
                  <h5 className='gray-500'>
                    Requests Cancelled:{' '}
                    <span className='' style={{color: 'black'}}>
                      {ServiceListCount?.cancelledRequest}
                    </span>
                  </h5>
                </div>
                <div className='mb-10  min-w-200px' style={{display: 'contents'}}>
                  <h5 className='gray-500'>
                    Pending Vendor Submission:{' '}
                    <span className='' style={{color: 'black'}}>
                      {ServiceListCount?.pendingVendorSubmissionCount}
                    </span>
                  </h5>
                </div>
              </div>
            </Col>
            <Col  xs ={12} md={12} lg={3} className=' order-1 order-sm-1 order-lg-2'>
              <div className='d-flex gap-4 w-auto justify-content-end'>
                <div
                  className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                  onClick={() => createNewService()}
                  style={{transform: 'translate(0px, -3px)'}}
                >
                  Request Service
                </div>
              </div>
            </Col>
          </Row>
          <div className='mb-1'>
            <Row>
              <Col xs={12} sm={6} lg={4} md={4}  className='d-flex align-items-center mb-3 order-3 order-md-1'>
                <input
                  type='text'
                  className='form-control form-control-solid solid'
                  placeholder='Request ID'
                  name='request_id'
                  style={{backgroundColor: '#fff'}}
                  onChange={(e) => setRequestId(e.target.value)}
                />
              </Col>
              <Col xs={12} sm={6} lg={4} md={4} className='d-flex align-items-center ms-auto mb-3  order-1 order-md-2'>
                <label htmlFor='exampleFormControlInput1' className='w-200px form-label m-0'>
                  Request Source
                </label>
                <select
                  name='request_source'
                  className='form-select'
                  onChange={(e) => setRequestSource(e.target.value)}
                >
                  <option selected value=''>
                    Select
                  </option>
                  <option value={2}>Internal</option>
                  <option value={1}>External</option>
                  <option value={3}>Marketplace</option>
                  <option value={0}>Tenant</option>
                </select>
              </Col>
              <Col xs={12} sm={6} lg={4} md={4} className='d-flex align-items-center mb-3  order-2 order-md-3'>
                <label htmlFor='exampleFormControlInput1' className='w-200px form-label m-0'>
                  Request Status
                </label>
                <select
                  name='request_status '
                  className='form-select'
                  onChange={(e) => setRequestStatus(e.target.value)}
                >
                  <option selected value='' disabled>
                    Select
                  </option>
                  <option value={0}>Draft</option>
                  <option value={1}>Under Approval</option>
                  <option value={2}>Pending Approval</option>
                  <option value={3}>Recalled</option>
                  <option value={4}>Rejected</option>
                  <option value={5}>Approved</option>
                  {/* <option value={6}>Awaiting Vendor Confirmation</option>
                  <option value={7}>Accepted</option> */}
                  <option value={8}>Booked</option>
                  <option value={9}>Variation Request</option>
                  <option value={10}>Cancelled</option>
                  <option value={11}>Tenant Pending Approval</option>
                  <option value={12}>Incomplete</option>
                  <option value={13}>Complete</option>
                </select>
              </Col>
            </Row>
          </div>
          <div className='card-body pt-0 table-responsive mt-5 px-3'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='text-center min-w-100px'>Request ID</th>
                  <th className='text-center min-w-150px'>Service Name</th>
                  <th className='text-center min-w-150px'>Request Source </th>
                  <th className='text-center min-w-150px'>Request Status</th>
                  <th className='text-center min-w-150px'>Assigned To</th>
                  <th className='text-center min-w-150px'>Handled By</th>
                  <th className='text-center min-w-150px'>Vendor Status</th>
                  <th className='text-center min-w-200px'>Total Developments</th>
                  <th className='text-center min-w-150px'>Total Units</th>
                  <th className='text-center min-w-100px'>Total Jobs</th>
                  <th className='text-center min-w-150px'>Request Amount</th>
                  <th className='text-center min-w-150px'>Request Urgency</th>
                  <th className='text-center min-w-150px'>Requestor Type</th>
                  <th className='text-center min-w-100px'>Requestor</th>
                  <th className='text-center min-w-150px'>Completed By</th>
                  <th className='text-center min-w-150px'>Pending With</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {serviceList?.length ? (
                  serviceList?.map((f: any, i: any) => {
                    return (
                      <tr style={{height: '40px'}}>
                        <td className='text-center' style={{cursor: 'pointer'}}>
                          <span onClick={() => goToRequest(f)}>
                            <a style={{textDecoration: 'underline', color: '#007a59'}}>
                              {f?.id === null ? '-------' : f?.id}
                            </a>
                          </span>{' '}
                          <br />
                        </td>
                        <td className='text-center'>
                          {f?.main_service?.map((i: any) => i?.name)} {' '} - {' '}
                          {f?.sub_service?.map((i: any) => i?.name)}
                        </td>
                        <td className='text-center'>
                          {f?.requestType === 0
                            ? 'Tenant'
                            : f?.requestType === 1
                            ? 'External'
                            : f?.requestType === 2
                            ? 'Internal'
                            : ''}
                        </td>
                        <td className='text-center'>
                          {f?.requestStatus === 0
                            ? 'Draft'
                            : f?.requestStatus === 1
                            ? 'Under Approval'
                            : f?.requestStatus === 2
                            ? 'Pending Approval'
                            : f?.requestStatus === 3
                            ? 'Recalled'
                            : f?.requestStatus === 4
                            ? 'Rejected'
                            : f?.requestStatus === 5
                            ? 'Approved'
                            : f?.requestStatus === 6
                            ? 'Approved'
                            : f?.requestStatus === 7
                            ? 'Approved'
                            : f?.requestStatus === 8
                            ? 'Booked'
                            : f?.requestStatus === 9
                            ? 'Variation Request'
                            : f?.requestStatus === 10
                            ? 'Cancelled'
                            : f?.requestStatus === 11
                            ? 'Tenant Pending Approval'
                            : f?.requestStatus === 12
                            ? 'Incomplete'
                            : f?.requestStatus === 13
                            ? 'Complete'
                            : ''}
                        </td>
                        <td className='text-center'>{f?.staff[0]?.name} </td>
                        <td className='text-center'>
                          {f?.requestType === 2 ? 'Internal Team' : f?.vendor[0]?.companyName}
                        </td>
                        <td className='text-center'>
                          {f?.vendorStatus === 0
                            ? 'Not Submitted'
                            : f?.vendorStatus === 1
                            ? 'Awaiting Vendor Confirmation'
                            : f?.vendorStatus === 2
                            ? 'Accepted'
                            : 'Rejected'}
                        </td>
                        <td className='text-center'>{f?.totalDevelopment}</td>
                        <td className='text-center'>{f?.totalProperties}</td>
                        <td className='text-center'>{f?.totalProperties}</td>
                        <td className='text-center'>0</td>
                        <td className='text-center'>
                          {f?.requestUrgency === 0 ? 'Standard' : 'Emergency'}
                        </td>
                        <td className='text-center'>
                          {f?.requestorType === 0 ? 'Staff' : 'Tenant'}
                        </td>
                        <td className='text-center'>
                          {f?.requestor[0]?.firstName} {f?.requestor[0]?.lastName || '-'}
                        </td>
                        <td className='text-center'>
                          {f?.approval_request[0]?.complete_with[0]?.user[0]?.firstName}{' '}
                          {f?.approval_request[0]?.complete_with[0]?.user[0]?.lastName || '-'}
                        </td>
                        <td className='text-center'>
                          {f?.approval_request[0]?.pending_with[0]?.user[0]?.firstName}{' '}
                          {f?.approval_request[0]?.pending_with[0]?.user[0]?.lastName || '-'}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td className='100'> No Data Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous disabled'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Service
