import React, {useEffect} from 'react'
import {Fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import './style.scss'
import pdfDocument from '../../../img/pdf.svg'
import docCocument from '../../../img/doc.svg'
import fileIcon from '../../../img/file.svg'
import videoImg from '../../../img/video.png'

interface Attachment {
  type: 'image' | 'video' | 'pdf' | 'iframe'
  url: string
  thumbnail: string
}

interface AttachmentPreviewProps {
  attachments: Attachment[]
}

const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({attachments}) => {
  const options = {
    Toolbar: {
      display: {
        left: ['infobar'],
        middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
        right: ['slideshow', 'thumbs', 'close'],
      },
    },
  }

  useEffect(() => {
    Fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })
  }, [])
  const renderAttachment = (attachment: Attachment) => {
    const {type, url, thumbnail} = attachment

    if (type == 'image') {
      return (
        <>
        {/* <p>111111</p> */}
        <a data-fancybox='gallery' href={url} key={url}>
          {/* <img src={url} alt='attachment' /> */}
        </a>
        </>
      )
    } else if (type == 'video') {
      return (
        <a data-fancybox='gallery' href={url} data-type='video' key={url}>
          {/* <img src={thumbnail} alt='attachment' /> */}
        </a>
      )
    } else if (type == 'pdf') {
      return (
        <a data-fancybox='gallery' href={url} data-type='iframe' key={url}>
          {/* <img src={pdfDocument} alt='attachment' /> */}
        </a>
      )
    } else {
      return (
        <a data-fancybox='gallery' href={url} data-type='iframe' key={url}>
          {/* <img src={thumbnail} alt='attachment' /> */}
        </a>
      )
    }
  }

  return <div className='attachment-preview'>{attachments.map(renderAttachment)}</div>
}

export default AttachmentPreview
