import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, Modal, FormCheck} from 'react-bootstrap'
import {useNavigate, useLocation, useParams} from 'react-router-dom'
import lock from '../../../img/padlock.png'
import bin from '../../../img/bin.png'
import Swal from 'sweetalert2'
import plus from '../../../img/add-white.png'
import AddItem from './AddItem'
import close from '../../../img/close.png'
import Switch from 'react-switch'
import payIncome from '../../../img/download.png'
import backArrow from '../../../img/back-arrow.png'
import greenPlus from '../../../img/add.png'
import pen from '../../../img/pen.png'
import './../settings/style.scss'
import moment from 'moment'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import download from '../../../img/download-white.png'
import {SuccessToast} from '../../../apiCommon/helpers/Toast'
import noData from '../../../img/NoData1.svg'
import {Form} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'

const PropertyIncomeItems = () => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const {development, devName} = state

  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)

  const params = useParams()

  const [paymentItems, setPaymentItems] = useState<any>([
  
  ])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const [addItemModal, setAddItemModal] = useState<any>(false)
  const [editModal, setEditModal] = useState<any>(false)

  const [confirmationModal, setConfirmationModal] = useState<any>(false)
  const [selectedPaymentsModal, setSelectedPaymentsModal] = useState<any>(false)

  const [checked, setChecked] = useState(false)

  const [itemToEdit, setItemToEdit] = useState<any>()
  const [itemToUpdate, setItemToUpdate] = useState<any>()

  const [name, setName] = useState<any>('')
  const [nameError, setNameError] = useState<any>(false)

  const handleChange = (e: any) => {
    setChecked(e.target.checked)
  }

  // list of payment items
  const getPaymentItems = () => {
    let body: any
    if (state.development == 'community') {
      body = {
        limit: 50,
        page: page,
        communityId: params?.id,
      }
    } else {
      body = {
        limit: 50,
        page: page,
        buildingId: params?.id,
      }
    }

    ApiPost(`corporate/payment_item_list/get`, body)
      .then((res) => {
        setPaymentItems(res?.data?.data?.payment_item_list_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
        // console.log(res?.data?.data?.payment_item_list_data)
      })
      .catch((err) => console.log('err', err))
  }

  const editName = (p: any) => {
    setItemToEdit(p)
    setName(p?.name)
    setEditModal(true)
  }

  // update payment items
  const updateItem = () => {
    if (name === '') setNameError(true)

    if (name !== '') {
      let body: any
      if (state.development == 'community') {
        body = {
          id: itemToEdit._id,
          name: name,
          communityId: params?.id,
        }
      } else {
        body = {
          id: itemToEdit._id,
          name: name,
          buildingId: params?.id,
        }
      }

      ApiPut(`corporate/payment_item_list`, body)
        .then((res) => {
          getPaymentItems()
          setEditModal(false)
          SuccessToast('Item has been updated successfully!!')
        })
        .catch((err) => console.log('err', err))
    }
  }

  // update item status
  const updateStatus = (p: any) => {
    let st: any
    if (p.activityStatus === 0) st = 1
    else st = 0
    let body: any
    if (state.development == 'community') {
      body = {
        id: p._id,
        activityStatus: st,
        communityId: params?.id,
      }
    } else {
      body = {
        id: p._id,
        activityStatus: st,
        buildingId: params?.id,
      }
    }

    ApiPut(`corporate/payment_item_list`, body)
      .then((res) => {
        getPaymentItems()
      })
      .catch((err) => console.log('err', err))
  }

  // delete Item
  const deleteItem = (p: any) => {
    ApiDelete(`corporate/payment_item_list/${p._id}`)
      .then((res) => {
        getPaymentItems()
        SuccessToast('Item has been deleted successfully!!')
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    getPaymentItems()
  }, [page])

  const confirmPop = (p: any) => {
    ApiGet(`corporate/payment_item_list/payment/popup/checker/${p?._id}`)
      .then((res) => {
        if (res?.data?.data?.isOpenPopup == true) setConfirmationModal(true)
        else {
          updateStatus(p)
        }
      })
      .catch((err) => console.log('err', err))
  }

  return (
    <>
      {/* <div className='app-main flex-column flex-row-fluid' id='kt_app_main'> */}
      <div id='' className='container-xxl pt-0 mt-0 px-4' style={{padding: '0px 14px'}}>
        <div className='align-items-center row mb-4'>
          <div className='d-flex align-items-center col-12 col-sm-8 col-md-6 mt-2'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => navigate(-1)}
            >
              <img src={backArrow} style={{stroke: 'red'}} height='18px' width='18px' />
            </span>
            <h1 className='page-heading m-0 head-text ps-2'>Payment Items (Income) - {state.devName}</h1>
          </div>
          <div className='col-4 col-sm-3 col-md-2 ms-auto text-end mt-2'>
            <a
              style={{
                textDecoration: 'none',
                color: '#007a59',
                fontWeight: '600',
                fontSize: '1.15rem ',
              }}
              className='me-5 pe-4'
              onClick={() => setAddItemModal(true)}
            >
              <img
                src={greenPlus}
                style={{
                  height: '18px',
                  width: '18px',
                  marginRight: '10px',
                  marginLeft: '-10px',
                  marginTop: '-3px',
                }}
              />
              Add Item
            </a>
          </div>
          <div className='col-1 col-md-1 d-none d-sm-block text-end mt-2'>
            <img src={payIncome} height='35px' width='30px' />
          </div>
        </div>
        {/*  */}
        <div className='card card-flush gap-5 p-5 pt-1 pb-0 px-3'>
          <div className='card-body pt-0 table-responsive mt-5 py-3 px-3'>
            <table className='table align-middle fs-6 gy-5' id='kt_ecommerce_sales_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold text-uppercase gs-0 fs-5'>
                  <th className='min-w-150px' style={{paddingLeft: '42px'}}>
                    Item Name
                  </th>
                  <th className='min-w-100px'>Date Added</th>
                  <th className='min-w-100px'>Status</th>
                  <th className='min-w-100px'>Actions</th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}} className='table-body'>
               
                {paymentItems.length > 0 ? (
                  paymentItems.map((pItem: any) => {
                    return (
                      <tr>
                        {pItem.isSystemGenerated === true ? (
                          <td className='d-flex align-items-center'>
                            {' '}
                            <img src={lock} height='20px' width='20px' className='me-4' style={{transform: 'translate(0px, -5px)'}}/>
                            <p className=''>{pItem?.name}</p>
                          </td>
                        ) : (
                          <td style={{paddingLeft: '42px'}} className=''>
                            <p className='pt-4'> {pItem?.name}</p>
                          </td>
                        )}
                        <td className=''>{moment(pItem?.createdAt).format('DD.MM.YYYY')}</td>
                        <td className='d-flex align-items-center'>
                          <Switch
                            onChange={(e) => {
                              setChecked(e)
                              setItemToUpdate(pItem)
                              // updateStatus(pItem)

                              if (pItem?.activityStatus === 0) updateStatus(pItem)
                              else {
                                // setStatusUpd(true)
                                confirmPop(pItem)

                                // setConfirmationModal(true)
                              }
                            }}
                            defaultChecked={checked}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            checked={pItem.activityStatus === 1 ? true : false}
                            onColor='#00cf99'
                            offColor='#b0bec5'
                          />
                          <p className='ms-4 pt-4'>
                            <i>{pItem.activityStatus === 0 ? 'Inactive' : 'Active'}</i>
                          </p>
                        </td>
                        <td>
                          {pItem.isSystemGenerated === false ? (
                            <div className='d-flex align-items-center'>
                              <img
                                src={pen}
                                height='20px'
                                width='20px'
                                alt=''
                                className='me-4'
                                style={{cursor: 'pointer'}}
                                onClick={() => editName(pItem)}
                              />
                              <img
                                src={bin}
                                alt=''
                                height='20px'
                                width='20px'
                                className='me-4'
                                style={{cursor: 'pointer'}}
                                onClick={() =>
                                  Swal.fire({
                                    html: '<p class="text-center m-0" style="font-size: 16px"> You are about to delete the item, do you wish to proceed?</p>',
                                    showConfirmButton: true,
                                    confirmButtonColor: '#007a59',
                                    confirmButtonText: 'Confirm',
                                    showCancelButton: true,
                                    cancelButtonText: 'Cancel',
                                  }).then((res) => {
                                    if (res.isConfirmed) {
                                      deleteItem(pItem)
                                    }
                                  })
                                }
                              />
                            </div>
                          ) : (
                            <p className='pt-4'>Not Available</p>
                          )}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={10} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='row'>
              <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_ecommerce_sales_table_paginate'
                >
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    forcePage={page - 1}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddItem
        show={addItemModal}
        handleClose={() => {
          setAddItemModal(false)
        }}
        paymentItemsList={getPaymentItems}
      />

        {/* Edit Item Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px'
        show={editModal}
        onHide={() => setEditModal(false)}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col xs={8} sm={8} md={8} lg={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Edit Item</h2>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => setEditModal(false)}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>
        <div className='header-bg'>
          <Row className='mx-2'>
            <Col xs={9} sm={9} md={9} lg={9}></Col>
            <Col xs={3} sm={3} md={3} lg={3} className='pt-5'>
              <img src={download} height='65px' width='50px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>

        <div
          className='modal-body py-lg-10 px-lg-10 pt-lg-7'
          style={{transform: 'translate(0px, -80px)', height: '53px'}}
        >
          <Row className='align-items-end'>
            <Col xs={6} sm={6} md={6} lg={6} className='align-items-center mt-3'>
              <label className='required mb-1'>Item Name</label>
              <input
                type='text'
                placeholder='Enter Name'
                name='item_id'
                value={name}
                onChange={(e: any) => {
                  setName(e.target.value)
                  // const values = itemToEdit
                  // values.name = e.target.value
                  // setItemToEdit(values)
                  setNameError(false)
                }}
                className={
                  nameError
                    ? 'form-control form-control-solid-bg border-danger'
                    : 'form-control form-control-solid-bg'
                }

                style={
                  name == ''
                    ? {
                        border: '0.1rem solid #bec3cb',
                        borderRadius: '2px',
                        padding: '0.7rem 1rem',
                      }
                    : {
                        border: '0.1rem solid #384a62 ',
                        borderRadius: '2px',
                        padding: '0.7rem 1rem',
                      }
                }
              />
            </Col>
            <Col xs={6} sm={6} md={6} lg={6} className='align-items-center text-end mt-3'>
              <button
                className='btn btn-sm fw-bold text-white'
                style={{
                  fontSize: '1.2rem',
                  paddingRight: '55px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={() => updateItem()}
              >
                <img
                  src={plus}
                  style={{
                    height: '18px',
                    width: '18px',
                    marginRight: '20px',
                    marginLeft: '-7px',
                    marginTop: '-3px',
                  }}
                />
                Update
              </button>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* Confirmation modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-550px mx-auto'
        show={confirmationModal}
        onHide={() => {
          setConfirmationModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', minHeight: '65px', boxShadow: 'none', borderBottom: '0px'}}
        >
          <Row className='w-100'>
            <Col xs={8} sm={8} md={8} lg={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Apply Changes</h2>
            </Col>
            <Col xs={4} sm={4} md={4} lg={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                style={{marginTop: '-10px'}}
                onClick={() => setConfirmationModal(false)}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div
          className='modal-body py-lg-10 px-lg-5  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '2px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <Row className='my-5 mt-2'>
            <Col>
              <label className='head-text fs-4' style={{fontWeight: '600'}}>
                Do you wish to apply this change on existing payments?
              </label>
            </Col>
          </Row>

          <Row className='align-items-center justify-content-center mb-5 mt-2'>
            <Col lg={12} md={12} className='text-center'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                style={{
                  fontSize: '1.2rem',
                  width: '250px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={() => {
                  setConfirmationModal(false)
                  updateStatus(itemToUpdate)
                }}
              >
                {'  '}
                Do Not Apply
              </button>
            </Col>
          </Row>
          <Row
            className='align-items-center justify-content-center mb-5'
            style={{marginTop: '40px'}}
          >
            <Col lg={12} md={12} className='text-center'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                style={{
                  fontSize: '1.2rem',
                  width: '250px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={() => {
                  setConfirmationModal(false)
                  updateStatus(itemToUpdate)
                }}
              >
                Apply to All Payments
              </button>
            </Col>
          </Row>
          <Row className='align-items-center justify-content-center mb-3'>
            <Col lg={12} md={12} className='text-center'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                style={{
                  fontSize: '1.2rem',
                  width: '250px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                // onClick={() => updateLatePaymentFee()}
                onClick={() => {
                  setConfirmationModal(false)
                  setSelectedPaymentsModal(true)
                }}
              >
                Apply to Selected Payments
              </button>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* Selected Payments */}

      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-1000px mx-auto'
        show={selectedPaymentsModal}
        onHide={() => {
          setSelectedPaymentsModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-header text-white ps-5 pe-1 header-style'
          style={{zIndex: '1', minHeight: '65px', boxShadow: 'none', borderBottom: '0px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Payments</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                style={{marginTop: '-10px'}}
                onClick={() =>setSelectedPaymentsModal(false)}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div
          className='modal-body py-lg-10 px-lg-5  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176 ',
          }}
        >
          <Row className='my-5'>
            <Col md={8}>
              <label className='head-text fs-3 ' style={{fontWeight: '600'}}>
                Which payments do you wish to apply this change to?
              </label>
            </Col>
            <Col lg={4} md={4} className='align-items-center text-end'>
              <button
                className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                style={{
                  fontSize: '1.2rem',
                  width: '110px',
                  //   paddingRight: '55px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={() => {
                  setSelectedPaymentsModal(false)
                }}
              >
                Apply
              </button>
            </Col>
          </Row>

          <table className='table align-middle fs-6 gy-5 mt-3' id='kt_ecommerce_sales_table'>
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                <th className=''>
                  {' '}
                  <td>
                    <FormCheck />
                  </td>
                </th>
                <th className='min-w-100px'>#</th>
                <th className='min-w-100px'>Unit No.</th>
                <th className='min-w-100px'>Payment</th>
                <th className='min-w-100px'>Amount</th>
                <th className='min-w-100px'>Status</th>
                <th className='min-w-100px'>Category</th>
                <th className='min-w-100px'>Due</th>
                <th className='min-w-100px'>Previous Fee</th>
                <th className='min-w-100px'>Previous Frequency</th>
              </tr>
            </thead>
            <tbody style={{fontWeight: '500'}} className='table-body'>
              <tr>
                <td>
                  <FormCheck />
                </td>
                <td className=''>12345_111</td>
                <td className=''>101</td>
                <td className=''>Rent</td>
                <td className=''> 5000</td>
                <td className=''> Unpaid</td>
                <td className=''> Upcoming</td>
                <td className=''> 30.06.2023</td>
                <td className=''> 250</td>
                <td className=''> Weekly</td>
              </tr>

              <tr>
                <td>
                  <FormCheck />
                </td>
                <td className=''>12345_111</td>
                <td className=''>101</td>
                <td className=''>Rent</td>
                <td className=''> 5000</td>
                <td className=''> Unpaid</td>
                <td className=''> Upcoming</td>
                <td className=''> 30.06.2023</td>
                <td className=''> 250</td>
                <td className=''> Weekly</td>
              </tr>

              <tr>
                <td>
                  <FormCheck />
                </td>
                <td className=''>12345_111</td>
                <td className=''>101</td>
                <td className=''>Rent</td>
                <td className=''> 5000</td>
                <td className=''> Unpaid</td>
                <td className=''> Upcoming</td>
                <td className=''> 30.06.2023</td>
                <td className=''> 250</td>
                <td className=''> Weekly</td>
              </tr>
              <tr>
                <td>
                  <FormCheck />
                </td>
                <td className=''>12345_111</td>
                <td className=''>101</td>
                <td className=''>Rent</td>
                <td className=''> 5000</td>
                <td className=''> Unpaid</td>
                <td className=''> Upcoming</td>
                <td className=''> 30.06.2023</td>
                <td className=''> 250</td>
                <td className=''> Weekly</td>
              </tr>

              <tr>
                <td>
                  <FormCheck />
                </td>
                <td className=''>12345_111</td>
                <td className=''>101</td>
                <td className=''>Rent</td>
                <td className=''> 5000</td>
                <td className=''> Unpaid</td>
                <td className=''> Upcoming</td>
                <td className=''> 30.06.2023</td>
                <td className=''> 250</td>
                <td className=''> Weekly</td>
              </tr>

              <tr>
                <td>
                  <FormCheck />
                </td>
                <td className=''>12345_111</td>
                <td className=''>101</td>
                <td className=''>Rent</td>
                <td className=''> 5000</td>
                <td className=''> Unpaid</td>
                <td className=''> Upcoming</td>
                <td className=''> 30.06.2023</td>
                <td className=''> 250</td>
                <td className=''> Weekly</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  )
}

export default PropertyIncomeItems
