import React, {useEffect, useState, useRef} from 'react'
import {ApiGet, ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import addGreen from '../../../../img/add.png'
import {MultiSelect} from 'react-multi-select-component'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import { IoMdInformationCircleOutline } from "react-icons/io";
import LinkToARequestModal from './LinkToARequestModal'
import homeImg from '../../../../img/home.png'
import plusBlue from '../../../../img/add-blue.png'
import trashImg from '../../../../img/trash.png'
import SelectList from './SelectItem'
import SelectProperty from './PropertySelectionModal'
import redCross from '../../../../img/remove.png'
import greenPen from '../../../../img/pen.png'
import {useParams} from 'react-router-dom'
import swal from 'sweetalert2'

interface ExpenseItemsProps {
  addDevelopment?: any
  expenseData?: any
  updateParent?: any
  updateRequest?: any
  isEdit?: any
  from?: any
  updateParentAfterDelete?: any
}

const ExpenseItems: React.FC<ExpenseItemsProps> = ({
  addDevelopment,
  expenseData,
  updateParent,
  updateParentAfterDelete,
  updateRequest,
  isEdit,
  from,
}) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const {flg} = useParams()

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [selectModal, setSelectModal] = useState<any>(false)

  const [selectPropertyModal, setSelectPropertyModal] = useState<any>(false)

  const [expenseItems, setExpenseItems] = useState<any>(
    expenseData?.items?.length > 0
      ? expenseData?.items
      : [
          {
            id: '',
            name: '',
            paymentItemListId: '',
            amount: '',
            quantity: '',
            VATAmount: '',
            VATPercentage: '',
            property: undefined,
            totalAmount: '',
            outstandingAmount: '',
            amountPaid: 0,
            totalAmountPaid: 0,
          },
        ]
  )

  const [devOptions, setDevOptions] = useState<any>([])
  // console.log('********* Expense Items ************')
  // console.log(expenseData)
  const [selectedDevelopment, setSelectedDevelopment] = useState<any>(
    expenseData?.development ? expenseData?.development : []
  )
  const [showLinkToARequest, setShowLinkToARequest] = useState<any>(false)

  //
  const handleAddExpenseItems = () => {
    const values = [...expenseItems]
    values.push({
      id: '',
      name: '',
      paymentItemListId: '',
      amount: '',
      quantity: '',
      VATAmount: '',
      VATPercentage: '',
      property:
        expenseData?.payeeData?.type == 0
          ? {
              level: '2',
              details: expenseData?.payeeData?.details?.unit,
            }
          : '',
      totalAmount: '',
      outstandingAmount: '',
      amountPaid: 0,
      totalAmountPaid: 0,
      frontendStorage: {
        itemUniqueId: new Date().getTime()
      }
    })
    setExpenseItems(values)
    updateParent(values)
  }

  //
  const handleRemoveExpenseItems = (ind: any) => {
    let id: any = expenseItems[ind]?._id
    const values = [...expenseItems]
    values.splice(ind, 1)
    setExpenseItems(values)
    if (flg == '1') updateParent(values)
    else updateParentAfterDelete(values, id)
  }

  // Development
  const getDevelopment = () => {
    const body = {
      search: '',
      developmentIds: [],
    }
    ApiPost(`corporate/development/get`, body)
      .then((res: any) => {
        let values: any = []
        let valuesInd: any = 0
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
              devType:
                val?.isCommunity == 'true' ? 'comm' : val?.isBuilding == 'true' ? 'build' : '',
            }

            if (val?.name?.trim()?.length != 0) {
              values[valuesInd] = {
                label: `${val?.name}`,
                value: `${val?._id}`,
                devType:
                  val?.isCommunity == 'true' ? 'comm' : val?.isBuilding == 'true' ? 'build' : '',
              }
              valuesInd = valuesInd + 1
            }

            if (
              expenseData?.payeeData?.type == 0 &&
              expenseData?.payeeData?.details?.developmentId == val?._id
            ) {
              let valuesSelected: any = []
              valuesSelected[0] = {
                label: `${val?.name}`,
                value: `${val?._id}`,
                devType:
                  val?.isCommunity == 'true' ? 'comm' : val?.isBuilding == 'true' ? 'build' : '',
              }

              setDevOptions(valuesSelected)
              if (expenseData?.development?.length != 0 && expenseData?.development != undefined)
                setSelectedDevelopment([valuesSelected[0]])
            }

            if (expenseData?.payeeData?.type != 0) setDevOptions(values)

            if (
              expenseData?.payeeData?.type != 0 &&
              expenseData?.development?.[0]?.value == val?._id
            )
              setSelectedDevelopment([values[ind]])

            if (
              expenseData?.payeeData?.type == 0 &&
              expenseData?.payeeData?.details?.developmentId == val?._id
            ) {
              let expItemsValues: any = [...expenseItems]
              expenseItems?.map((exIT: any, ind: any) => {
                expItemsValues[ind].property = {
                  level: '2',
                  details: expenseData?.payeeData?.details?.unit?.[0],
                }
              })

              if (expenseData?.development?.length != 0 && expenseData?.development != undefined) {
                setExpenseItems(expItemsValues)
              } else {
                expenseItems?.map((exIT: any, ind: any) => {
                  expItemsValues[ind].property = {
                    level: undefined,
                    details: '',
                  }
                })
                setExpenseItems(expItemsValues)
              }
            }
          })

        // if (isFirst) setSelectedDevelopments(values)
      })
      .catch((err: any) => {})
  }

  let [expData, setExpData] = useState<any>(expenseData)
  const [isMainRequest, setIsMainRequest] = useState<any>(new Date())
  const [requestData, setRequestData] = useState<any>([])
  const [selectedIndex, setSelectedIndex] = useState<any>()
  let isDuplicated:any = []

  React.useEffect(() => {
    getDevelopment()
    setExpenseItems(expenseData?.items)

    let duplicates:any = []

    expenseData?.items?.map((itm:any, ind:any)=>{
      duplicates[ind] = false
    })
    isDuplicated = duplicates

    // console.log(expenseData)
    // setSelectedDevelopment(expenseData?.development)
  }, [expenseData])

  const getRequestById = () => {
    if(expData?.requestDetail?._id){
    ApiGet(`corporate/maintenance_service_request/${expData?.requestDetail?._id}`)
      .then((res: any) => {
        setRequestData(res?.data?.data?.maintenance_service_job)

        setIsMainRequest(false)
      })
      .catch((err: any) => {
        // ErrorToast(err?.message)
      })
    }
  }

  useEffect(() => {
    if (isMainRequest != false) getRequestById()
  }, [isMainRequest, expData])

  // 
  const getServiceJob = (val: any) => {
    let jobId: any = null;

    requestData?.forEach((rq: any) => {
        if (rq?.unit?.[0]?._id === val) {
            jobId = rq?.id;
        }
    });

    return jobId;
  }

  const intervalRef = useRef<NodeJS.Timer | null>(null); 

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const checkboxes = document.querySelectorAll('.property-test .multi-select-container .rmsc .item-renderer input[type=checkbox]');
      checkboxes.forEach((checkbox) => {
        checkbox.setAttribute('type', 'radio');
      });
    }, 100);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  return (
    <>
      <div className={'card card-flush py-5 mt-7  px-5 mb-5 mx-7 min-hg-300'}>
        <div className='d-flex  align-items-center'>
          <h2 className='' style={{fontWeight: '700'}}>
            {'Expense Items'}
          </h2>

          {isEdit && (
            <button
              type='button'
              className='btn btn-sm fw-bold  px-2 green-hollow-btn ms-auto btn-fit-content'
              onClick={() => {
                handleAddExpenseItems()
              }}
            >
              <img src={addGreen} height={18} width={18} className='me-4' /> Add Expense Item
            </button>
          )}
        </div>

        {isEdit ? (
          <>
            <div className='d-flex align-items-center'>
              <div className='d-flex align-items-center' style={{minWidth: '370px'}}>
                <p className=' pt-2 mb-0 me-5'>
                  Which development are you recording this expense for?
                </p>
                <p className=' pt-2 mb-0 me-5 ms-auto'>
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                          {
                            'You can record your expense for a single development at a time. You will be able to include multiple expense items on different properties within the same development only.'
                          }
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                      </span>
                    </OverlayTrigger>
                  </span>
                </p>
              </div>

              <div className='test expense-items me-4 d-flex property-test'>
                <div
                  className={`multi-select-container ${
                    selectedDevelopment?.length === 0 ? 'no-value expense-development' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={devOptions}
                    value={selectedDevelopment}
                    onChange={(e: any) => {
                      let newVal: any = e
                      if (e?.length > 1) newVal = e.slice(1)

                      if (selectedDevelopment?.[0]?.value != newVal?.[0]?.value) {
                        // console.log(selectedDevelopment?.[0]?.value != newVal?.[0]?.value)
                        let values: any = [...expenseItems]

                        expenseItems?.map((expItm: any, ind: any) => {
                          values[ind].property = {
                            level: undefined,
                            details: '',
                          }
                        })

                        let valuesExp = {...expData}
                        valuesExp.requestDetail = undefined

                        setExpData(valuesExp)

                        setExpenseItems(values)

                        updateRequest(undefined, values, newVal)
                        // updateParent(values)
                        // updateRequest(undefined, values)

                        setSelectedDevelopment(newVal)

                        // addDevelopment(newVal)
                       
                      }

                      // setSelectedDevelopment(newVal)

                    }}
                    labelledBy='Type'
                    overrideStrings={{
                      allItemsAreSelected: `${selectedDevelopment?.[0]?.label}`,
                    }}
                    closeOnChangedValue={true}
                    hasSelectAll={false}
                  />
                </div>
                <h3 className='text-danger mb-0 ms-2'>
                  <b>*</b>
                </h3>
              </div>
            </div>
            <div className='d-flex mt-5'>
              <div className='d-flex align-items-center' style={{minWidth: '370px'}}>
                <p className=' pt-2 mb-0 me-5'>Link the below expense items to a request</p>
                <p className=' pt-2 mb-0 me-5 ms-auto'>
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                          {
                            'You can link the expense to any past or ongoing request to ensure you keep track of the costs of your requests. You will be able to select one request that falls under your selected development. Once the request is selected and you select the property for each expense item, the expense will be recorded and become available on the request page for each property.'
                          }
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                      </span>
                    </OverlayTrigger>
                  </span>
                </p>
              </div>
              <div className='d-flex align-items-center justify-content-center'>
                {expData?.requestDetail == undefined ? (
                  // expData?.requestDetail?.trim()?.length == 0
                  <div className='d-flex align-items-center'>
                    <button
                      type='button'
                      className='btn btn-sm fw-bold  green-submit-btn ps-3 d-flex justify-content-center status-w-200 ms-7'
                      onClick={(e: any) => {
                        setShowLinkToARequest(true)
                      }}
                      disabled={selectedDevelopment?.length == 0}
                    >
                      Link to A Request
                    </button>
                    {selectedDevelopment?.length == 0 && (
                      <p className='text-danger mb-0 ms-5'> Please select the development first.</p>
                    )}
                  </div>
                ) : (
                  <>
                  <div className='d-flex expense-date ms-1'>
                    <span
                      className='ms-1 text-decoration-underline cursor-pointer'
                      onClick={() => {
                        setShowLinkToARequest(true)
                      }}
                    >
                      <b>{expData?.requestDetail?.id ? expData?.requestDetail?.id : '-'}</b>
                    </span>
                    <img
                      src={redCross}
                      height={16}
                      width={16}
                      className='ms-7 cursor-pointer'
                      onClick={() => {
                        let values = {...expData}
                        values.requestDetail = undefined

                        setExpData(values)
                        let itemValues: any = [...expenseItems]

                        expenseItems?.map((expItm: any, ind: any) => {
                          itemValues[ind].property = {
                            level: undefined,
                            details: '',
                          }
                        })

                        updateRequest(undefined, itemValues, selectedDevelopment)

                        // setTimeout(()=>{
                        //   updateParent(itemValues)
                        // }, 400)
                      }}
                    />
                  </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className='d-flex my-4'>
              <h4 className='my-2' style={{color: 'black'}}>
                <label style={{width: '150px'}} className='labl-gry '>
                  {' '}
                  Development
                </label>
                <span className='ms-3'>
                  <b>{selectedDevelopment?.length > 0 ? selectedDevelopment?.[0]?.label : '-'}</b>
                </span>
              </h4>
            </div>

            <div className='d-flex my-4'>
              <h4 className='my-2' style={{color: 'black'}}>
                <label style={{width: '150px'}} className='labl-gry '>
                  {' '}
                  Request Linked
                </label>
                <span className='ms-3 text-decoration-underline'>
                  <b>{expData?.requestDetail?.id ? expData?.requestDetail?.id : '-'}</b>
                </span>
              </h4>
            </div>
          </>
        )}

        <div className='table-responsive mt-3' style={{maxHeight: '700px'}}>
          <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                <th className='text-start min-w-50px'>ID</th>
                <th className='text-start min-w-150px'>Item</th>
                <th className='text-start min-w-100px'>Amount</th>
                <th className='text-start min-w-75px'>QTY</th>
                <th className='text-start min-w-100px'>VAT</th>
                <th className='text-start min-w-150px'>
                  Property{' '}
                  <span>
                    <OverlayTrigger
                      placement='top'
                      delay={{show: 250, hide: 400}}
                      overlay={
                        <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                          {
                            'Includes the Unit Number of the property selected for the expense item. You can record one expense item for one property or common area under the development that was selected in your overall expense.'
                          }
                        </Tooltip>
                      }
                    >
                      <span>
                        {' '}
                        <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                      </span>
                    </OverlayTrigger>
                  </span>
                </th>
                {/* from == 'view-edit' && */}
                {expenseData?.requestDetail?._id != null &&
                  expenseData?.requestDetail?._id != undefined && (
                    <th className='text-start min-w-150px'>
                      Job ID{' '}
                      <span>
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                              {'Includes the Job ID assigned to the selected property under the linked request.'}
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                          </span>
                        </OverlayTrigger>
                      </span>
                    </th>
                  )}
                <th className='text-start min-w-150px'>Total Amount</th>
                {isEdit && expenseItems?.length > 1 && <th className='text-start min-w-75px'></th>}
              </tr>
            </thead>

            {expenseItems?.map((rsOut: any, indOut: any) => {
              {expenseItems?.map((rsIn: any, indIn: any) => {
                 if((rsOut?.expenseItemId == rsIn?.expenseItemId) && 
                (((Number(rsOut?.property?.level) == 2  || Number(rsOut?.property?.level) == 3) && (rsOut?.property?.details?.unitNo == rsIn?.property?.details?.unitNo)) || ((Number(rsOut?.property?.level) == 1 && Number(rsIn?.property?.level) == 1))) &&
                 indOut != indIn
                 ){
                  isDuplicated[indOut] = true
                  }
               })}
             })}

            <tbody className='fw-semibold text-gray-600'>
              {expenseItems?.map((rs: any, index: any) => {
                let totalAmountOfItem:any=0
                let vatAmnt:any = ((Number(rs?.amount)*Number(rs?.quantity))/100 ) * Number(rs?.VATPercentage)
                totalAmountOfItem = (Number(rs?.amount)*Number(rs?.quantity)) + vatAmnt
                return (
                  <tr className=''>
                    <td className='text-start'>{rs?.expenseItemId ? rs?.expenseItemId : '-'}</td>
                    <td className='text-start min-w-100px'>
                      {isEdit ? (
                        <div className='d-flex align-items-center'>
                          <div
                            className='form-control form-control-solid tenancy-control status-w-200 cursor-pointer'
                            style={{
                              height: '35px',
                              width: '300px',
                              display: 'flex',
                              alignItems: 'center',
                              border:
                                rs?.name?.trim()?.length === 0
                                  ? '0.1rem solid #bec3cb '
                                  : '0.1rem solid #384a62',
                              color: rs?.name?.trim()?.length === 0 ? '#a1a5b7' : '#384a62',
                              fontWeight: rs?.name?.trim()?.length === 0 ? '500' : '',
                            }}
                            onClick={(e) => {
                              if (rs?.name?.trim()?.length === 0) {
                                setSelectedIndex(index)
                                setSelectModal(true)
                              }
                            }}
                          >
                            {' '}
                            {rs?.name?.trim()?.length === 0 ? (
                              <i>{'Select Item'}</i>
                            ) : (
                              `${rs?.name}`
                            )}
                          </div>
                          {/* <input
                            //   {...register('documentName', {required: true})}
                            type='text'
                            className='form-control form-control-solid mytest tenancy-control status-w-200 cursor-pointer'
                            name='documentName'
                            value={rs?.name}
                            style={{height: '35px', width: '300px'}}
                            placeholder={'Select Item'}
                            onChange={(e: any) => {
                              let values = {...expenseItems}
                              values.name = e.target.value
                              setExpenseItems(values)
                              updateParent(values)
                            }}
                            onClick={(e: any) => {
                              if (rs?.name?.trim()?.length == 0) {
                                setSelectedIndex(index)
                                setSelectModal(true)
                              }
                            }}
                            // disabled
                          /> */}
                          {rs?.name?.trim()?.length == 0 ? (
                            <img
                              src={plusBlue}
                              height={16}
                              width={16}
                              style={{marginLeft: '-30px'}}
                              onClick={() => {
                                setSelectedIndex(index)
                                setSelectModal(true)
                              }}
                              className='cursor-pointer'
                            />
                          ) : (
                            <img
                              src={redCross}
                              height={16}
                              width={16}
                              style={{marginLeft: '-30px'}}
                              onClick={() => {
                                let values = [...expenseItems]
                                values[index].name = ''
                                setExpenseItems(values)
                                updateParent(values)
                              }}
                              className='cursor-pointer'
                            />
                          )}
                        </div>
                      ) : (
                        <>{rs?.name ? rs?.name : '-'}</>
                      )}
                    </td>
                    <td className='text-start'>
                      {isEdit ? (
                        <>
                        <input
                          //   {...register('documentName', {required: true})}
                          type='number'
                          className='form-control form-control-solid mytest tenancy-control status-w-170'
                          name='documentName'
                          value={rs?.amount}
                          style={{height: '35px', width: '300px'}}
                          placeholder={'Enter Amount'}
                          onChange={(e: any) => {
                            let newValue = e.target.value

                            if (newValue.length > 1 && newValue.startsWith('0')) {
                              newValue = newValue.replace(/^0+/, '')
                            }

                            let values = [...expenseItems]
                            values[index].amount = Number(newValue)
                            let vatAmount: any =
                              ((Number(newValue) * Number(values[index].quantity)) / 100) *
                              values[index].VATPercentage
                              
                            values[index].totalAmount =
                              (Number(newValue) * Number(values[index].quantity)) + Number(vatAmount)
                            values[index].outstandingAmount =
                              Number(values[index].totalAmount) - Number(values[index].amountPaid)

                            setExpenseItems(values)
                            // updateParent(values)
                          }}
                          onBlur={() => {
                            if(totalAmountOfItem >= rs?.amountPaid)
                            updateParent(expenseItems)
                          }}
                          autoComplete="off"
                        />
                          {totalAmountOfItem < rs?.amountPaid && (
                          <p className='text-danger mb-0 status-w-170'> The total amount of the expense item is less than the payment amount recorded for it.</p>
                           )}
                        </>
                      ) : (
                        <>
                          {rs?.amount
                            ? `AED ${rs?.amount?.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                              })}`
                            : 'AED -'}
                        </>
                      )}
                    </td>

                    <td className='text-start'>
                      {isEdit ? (
                        <input
                          type='number'
                          className='form-control form-control-solid mytest tenancy-control  status-w-100'
                          name='documentName'
                          value={rs?.quantity}
                          style={{height: '35px', width: '300px'}}
                          placeholder={'QTY'}
                          onChange={(e: any) => {
                            let values = [...expenseItems]
                            let newValue = e.target.value

                            if (newValue.length > 1 && newValue.startsWith('0')) {
                              newValue = newValue.replace(/^0+/, '')
                            }

                            values[index].quantity = newValue != '' ? Number(newValue) : newValue
                            let vatAmount: any =
                              ((Number(newValue) * Number(values[index].amount)) / 100) *
                              values[index].VATPercentage
                            values[index].totalAmount =
                             (Number(newValue) * Number(values[index].amount)) + Number(vatAmount)
                            values[index].outstandingAmount =
                              (Number(values[index].totalAmount) - Number(values[index].amountPaid))

                            setExpenseItems(values)
                            // updateParent(values)
                          }}
                          onBlur={() => {
                            if(totalAmountOfItem >= rs?.amountPaid)
                            updateParent(expenseItems)
                          }}
                           autoComplete="off"
                        />
                      ) : (
                        <>{rs?.quantity ? `${rs?.quantity?.toLocaleString('en-US', {})}` : '-'}</>
                      )}
                    </td>
                    <td className='text-start'>
                      {isEdit ? (
                        <input
                          //   {...register('documentName', {required: true})}
                          type='number'
                          className='form-control form-control-solid mytest tenancy-control   status-w-100'
                          name='documentName'
                          value={rs?.VATPercentage}
                          style={{height: '35px', width: '300px'}}
                          placeholder={'%'}
                          onChange={(e: any) => {
                            let values = [...expenseItems]
                            let newValue = e.target.value

                            if (newValue.length > 1 && newValue.startsWith('0')) {
                              newValue = newValue.replace(/^0+/, '')
                            }

                            values[index].VATPercentage = newValue
                            let vatAmount: any =
                              ((Number(values[index].amount) * Number(values[index].quantity)) /
                                100) *
                              Number(newValue)
                            values[index].VATAmount = vatAmount
                            values[index].totalAmount =
                              (Number(values[index].amount) * Number(values[index].quantity)) +
                              Number(vatAmount)
                            values[index].outstandingAmount =
                              Number(values[index].totalAmount) - Number(values[index].amountPaid)
                            setExpenseItems(values)
                            // updateParent(values)
                          }}
                          onBlur={() => {
                            if(totalAmountOfItem >= rs?.amountPaid)
                            updateParent(expenseItems)
                          }}
                           autoComplete="off"
                        />
                      ) : (
                        <>{rs?.VATPercentage ? `${rs?.VATPercentage}%` : '-'}</>
                      )}
                    </td>
                    <td className='text-start'>
                      {isEdit ? (
                        <>
                          {rs?.property?.level != undefined ? (
                            <>
                              <div className='d-flex mt-2'>
                                {isDuplicated[index] &&
                                  <span>
                                    <OverlayTrigger
                                      placement='top'
                                      delay={{show: 250, hide: 400}}
                                      overlay={
                                        <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                         {`This expense item already exsits for this ${Number(rs?.property?.level) == 1 ? 'property' : 'unit'}`}
                                        </Tooltip>
                                     }
                                   >
                                   <span>
                                    {' '}
                                    <div className='yellow-exclamation-mark me-3'>!</div>
                                     </span>
                                     </OverlayTrigger>
                                     </span>
                                    }
                                    <span className=''>
                                       {/* <b> */}
                                       {/* {flg == '1' ? ( */}
                                     <>
                                        {Number(rs?.property?.level) == 1
                                        ? selectedDevelopment?.[0]?.label
                                        : rs?.property?.details?.unitNo}
                                      {/* </b> */}
                                      </>
                                     {/* ) : (
                                       <>
                                         {rs?.unit?.length > 0 ? rs?.unit?.[0]?.unitNo : '-'}
                                        </>
                                       )} */}
                                   </span>
                                {(expenseData?.payeeData?.type == 1 ||
                                  expenseData?.payeeData?.type == undefined) && (
                                  <img
                                    src={greenPen}
                                    width={16}
                                    height={16}
                                    className='cursor-pointer ms-5'
                                    onClick={(event) => {
                                      setSelectedIndex(index)
                                      setSelectPropertyModal(true)
                                    }}
                                  />
                                )}
                              </div>
                            </>
                          ) : (
                            <button
                              type='button'
                              className='btn btn-sm fw-bold  ps-4 text-white select-btn status-w-150 btn-fit-content'
                              onClick={(event) => {
                                setSelectedIndex(index)
                                setSelectPropertyModal(true)
                              }}
                              disabled={selectedDevelopment?.length == 0}
                            >
                              Select Property
                              <img src={homeImg} height={19} width={19} className='ms-8' /> {'  '}
                            </button>
                          )}
                        </>
                      ) : (
                        <div className='d-flex mt-2'>
                          {' '}
                          {Number(rs?.property?.level) == 1
                            ? selectedDevelopment?.[0]?.label
                            : rs?.property?.details?.unitNo}
                        </div>
                      )}
                    </td>

                    {expenseData?.requestDetail?._id != null &&
                      expenseData?.requestDetail?._id != undefined && (
                        <td className='text-start'>
                          {rs?.property?.level != undefined ?
                             <div className='d-flex mt-2'>
                              {/* {(flg == '2' && rs?.maintenanceServiceJobId) ? rs?.maintenance_service_job?.[0]?.id :  */}
                                {Number(rs?.property?.level) != 1 ? getServiceJob(rs?.property?.details?._id) ?? '-' : '-'}   {/*Using nullish coalescing operator to handle null values*/}
                              {/* : '-'} */}
                            </div>
                            :
                            <div className='d-flex mt-2'>{'-'}</div>
                          }
                        </td>
                      )}

                    <td className='text-start '>
                      <div className='d-flex align-items-center mt-2'>
                        <b>
                          {' '}
                          {totalAmountOfItem
                            ? `AED ${totalAmountOfItem?.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                              })}`
                            : 'AED -'}
                        </b>
                      </div>
                    </td>
                    {isEdit && expenseItems?.length > 1 && (
                      <td className='text-start'>
                        <img
                          src={trashImg}
                          height={16}
                          width={16}
                          onClick={() => {
                            if (flg == '1') handleRemoveExpenseItems(index)
                            else {
                              swal
                                .fire({
                                  html: ` 
                                <div class='fs-3'> <b>Delete Expense Item</></div>
                                 <div class='fs-5 mt-4'>You are about to delete an expense item.</div>
                                <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                  // icon: 'warning',
                                  showConfirmButton: true,
                                  confirmButtonColor: '#35475e',
                                  confirmButtonText: 'Yes',
                                  showCancelButton: true,
                                  cancelButtonText: 'No',
                                  cancelButtonColor: '#fff',
                                  customClass: {
                                    confirmButton: 'custom-confirm-button',
                                    cancelButton: 'custom-cancel-button',
                                    popup: 'custom-popup',
                                  },
                                  reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                })
                                .then((res: any) => {
                                  if (res.isConfirmed) {
                                    handleRemoveExpenseItems(index)
                                  }
                                })
                            }
                          }}
                          className='cursor-pointer'
                        />{' '}
                      </td>
                    )}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      {/*  */}
      {showLinkToARequest && selectedDevelopment && (
        <LinkToARequestModal
          show={showLinkToARequest}
          handleClose={() => {
            setShowLinkToARequest(false)
          }}
          updateDetails={(val: any) => {
            let values = {...expData}

            // console.log(values)
            // console.log(val)

            values.requestDetail = val

            let itemValues: any = [...expenseItems]

            expenseItems?.map((expItm: any, ind: any) => {
              itemValues[ind].property = {
                level: undefined,
                details: '',
              }
            })

            // updateParent(itemValues)
            setExpData(values)

            setIsMainRequest(new Date())

            updateRequest(val, itemValues, selectedDevelopment)

            setShowLinkToARequest(false)
          }}
          requestData={selectedDevelopment}
          requestDetails={expData?.requestDetail}
        />
      )}

      {/*  */}
      {selectModal && (
        <SelectList
          show={selectModal}
          handleClose={() => {
            setSelectModal(false)
          }}
          updateDetails={(val: any) => {
            // console.log(val)
            let values = [...expenseItems]

            // console.log(values[selectedIndex])

            values[selectedIndex].name = val?.name
            values[selectedIndex].paymentItemListId = val?._id
            values[selectedIndex].expenseItemId = val?.id

            setExpenseItems(values)

            updateParent(values)
            setSelectModal(false)
          }}
          tenantData={''}
        />
      )}

      {/*  */}
      {selectPropertyModal && selectedDevelopment && (
        <SelectProperty
          show={selectPropertyModal}
          handleClose={() => {
            setSelectPropertyModal(false)
          }}
          updateDetails={(level: any, data: any) => {
            let values = [...expenseItems]

            values[selectedIndex].property = {
              level: level,
              details: Number(level) != 1 ? data : selectedDevelopment?.[0]?.label,
            }
           
            setExpenseItems(values)
            updateParent(values)

            setSelectPropertyModal(false)
          }}
          expData={expenseData}
          selectedDev={selectedDevelopment}
        />
      )}
    </>
  )
}

export default ExpenseItems
