import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../img/back-arrow.png'
import './style.scss'
import {ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import noData from '../../../img/NoData1.svg'
import resImg from '../../../img/residential.png'
import commImg from '../../../img/commercial.png'
import settingsIcon from '../../../img/settings-black.png'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import Switch from 'react-switch'
import swal from 'sweetalert2'
import { IoMdInformationCircleOutline } from "react-icons/io";

const TypeLeasing = () => {
  const navigate = useNavigate()
  
  const {flg, mainId, id} = useParams()

  const [newLeaseApproval, setNewLeaseApproval] = useState<any>(false)
  const [newLeaseFinancials, setNewLeaseFinancials] = useState<any>(false)
  const [renewalLeaseApproval, setRenewalLeaseApproval] = useState<any>(false)
  const [renewalLeaseFinancials, setRenewalLeaseFinancials] = useState<any>(false)
  const [terminateLeaseApproval, setTerminateLeaseApproval] = useState<any>(false)
  const [terminateLeaseFinancials, setTerminateLeaseFinancials] = useState<any>(false)

  const [subWorkflowList, setSubWorkflowList] = useState<any>()
  const [selected, setSelected] = useState<any>()

  const [isFirst, setIsFirst] = useState<any>(true)

  //
  const getSubWorkFlowsList = () => {
    const body = {
      subWorkflowId: id,
    }
    ApiPost('corporate/sub_workflow_list/get', body)
      .then((res: any) => {
        setSubWorkflowList(res?.data?.data)
        res?.data?.data?.map((flow: any, index: any) => {
          if (flow?.name == 'New Lease') {
            setNewLeaseApproval(flow?.isApprovalEnabled)
            setNewLeaseFinancials(flow?.isFinancialIncluded)
          } else if (flow?.name == 'Lease Renewals') {
            setRenewalLeaseApproval(flow?.isApprovalEnabled)
            setRenewalLeaseFinancials(flow?.isFinancialIncluded)
          } else if (flow?.name == 'Lease Termination') {
            setTerminateLeaseApproval(flow?.isApprovalEnabled)
            setTerminateLeaseFinancials(flow?.isFinancialIncluded)
          }

          setIsFirst(false)
          return <></>
        })
      })
      .catch(() => {})
  }

  //
  const updateSubWorkFlowsList = () => {
    const body = {
      id: selected?._id,
      isApprovalEnabled:
        selected?.name == 'New Lease'
          ? newLeaseApproval
          : selected?.name == 'Lease Termination'
          ? terminateLeaseApproval
          : selected?.name == 'Lease Renewals'
          ? renewalLeaseApproval
          : '',
      isFinancialIncluded:
        selected?.name == 'New Lease'
          ? newLeaseFinancials
          : selected?.name == 'Lease Termination'
          ? terminateLeaseFinancials
          : selected?.name == 'Lease Renewals'
          ? renewalLeaseFinancials
          : '',
    }
    ApiPut('corporate/sub_workflow_list', body)
      .then((res: any) => {
        getSubWorkFlowsList()
      })
      .catch(() => {})
  }
  //
  useEffect(() => {
    getSubWorkFlowsList()
  }, [])

  useEffect(() => {
    if(!isFirst)
    updateSubWorkFlowsList()
  }, [
    newLeaseApproval,
    newLeaseFinancials,
    renewalLeaseApproval,
    renewalLeaseFinancials,
    terminateLeaseApproval,
    terminateLeaseFinancials,
  ])

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <div className='d-flex align-items-center gap-3'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => navigate(`/settings/workflows/leasing/${mainId}`)}
            >
              <img src={backArrow} style={{stroke: 'red'}} height='14px' width='14px' />
            </span>
            <h2 className='page-heading m-0 head-text'>
              {flg == '1' ? 'Residential Leasing Workflows' : 'Commercial Leasing Workflows'}
            </h2>
          </div>
          <div className='ms-auto'>
            <img src={flg == '1' ? resImg : commImg} height='30px' width='30px' />
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3' style={{minHeight: '75vh'}}>
          <div className='card-body pt-0 mt-5 py-0 px-3'>
            <Row className='my-5'>
              {subWorkflowList?.length > 0 ? (
                <>
                  {subWorkflowList?.map((workflow: any, index: any) => {
                    return (
                      <Col md={12} className='mb-5'>
                        <div className='card card-flush gap-5 p-3 cursor-pointer'>
                          <div className='card-body p-3 pb-0'>
                            <div className='table-responsive add-user-height'>
                              <table
                                className='table align-middle fs-6 gy-5 '
                                id='kt_ecommerce_sales_table'
                              >
                                <tbody className='fw-semibold text-gray-600'>
                                  <tr>
                                    <td className='text-start ps-0 min-w-150px w-lg-250px'>
                                      <div className='d-flex align-items-center'>
                                        <img
                                          src={settingsIcon}
                                          height={28}
                                          width={28}
                                          className='me-5 pe-2 object-fit-contain'
                                        />
                                        <h4 className=' m-0 head-text my-3'>
                                          <b>{workflow?.name}</b>
                                        </h4>
                                      </div>
                                    </td>

                                    <td
                                      className={
                                        workflow?.name == 'New Lease'
                                          ? 'text-start ps-0 min-w-175px w-lg-250px'
                                          : 'text-start ps-0 min-w-200px w-lg-250px'
                                      }
                                    >
                                      <div className='text-center'>
                                        <h5 className='m-0 lease-grey-label mb-3 light-dark-theme-color'>
                                          Workflow Category
                                        </h5>
                                        <h5 className=' m-0 head-text mb-3'>
                                          {workflow?.main_workflow?.[0]?.name} -{' '}
                                          {workflow?.sub_workflow?.[0]?.name}
                                        </h5>
                                      </div>
                                    </td>
                                    <td className='text-start ps-0 min-w-150px'>
                                      <div className='lease ms-10 ps-10'>
                                        <h5 className='m-0 lease-grey-label mb-3 light-dark-theme-color'>
                                          Approvals
                                          <span>
                                            <OverlayTrigger
                                              placement='top'
                                              delay={{show: 250, hide: 400}}
                                              overlay={
                                                <Tooltip
                                                  id='button-tooltip-3'
                                                  className='custom-tooltip'
                                                >
                                                  {' '}
                                                  {workflow?.name == 'New Lease' ? (
                                                    <> {' New Lease Approvals..'}</>
                                                  ) : workflow?.name == 'Lease Renewals' ? (
                                                    <> {' Lease Renewals Approvals..'}</>
                                                  ) : workflow?.name == 'Lease Termination' ? (
                                                    <> {'Lease Terminations Approvals..'}</>
                                                  ) : (
                                                    ''
                                                  )}
                                                </Tooltip>
                                              }
                                            >
                                              <span>
                                                {' '}
                                                <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                                              </span>
                                            </OverlayTrigger>
                                          </span>
                                        </h5>
                                        <div className='d-flex  align-items-center'>
                                          <Switch
                                            onChange={(e) => {
                                              setSelected(workflow)

                                              swal
                                                .fire({
                                                  html: `<div>You are about to switch ${
                                                    e ? 'ON' : 'OFF'
                                                  }  the approval process for the '${
                                                    workflow?.name
                                                  }' flow. </div>
                                                  <div class='mt-4'>The approval process will be applied on new ${
                                                  workflow?.name == 'New Lease'
                                                    ? 'leases'
                                                    : workflow?.name == 'Lease Renewals'
                                                    ? 'renewals'
                                                    : 'terminations'
                                                } generated.
                                                This update will not be applied to existing incomplete/ draft ${
                                                  workflow?.name == 'New Lease'
                                                    ? 'leases'
                                                    : workflow?.name == 'Lease Renewals'
                                                    ? 'renewals'
                                                    : 'terminations'
                                                }.</div>
                                                <div class='mt-4'> Are you sure you want to continue? </div>`,
                                                  // icon: 'warning',
                                                  showConfirmButton: true,
                                                  confirmButtonColor: '#35475e',
                                                  confirmButtonText: 'Yes',
                                                  showCancelButton: true,
                                                  cancelButtonText: 'No',
                                                  cancelButtonColor: '#fff',
                                                  customClass: {
                                                    confirmButton: 'custom-confirm-button',
                                                    cancelButton: 'custom-cancel-button',
                                                    popup: 'custom-popup',
                                                  },
                                                  reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                                })
                                                .then((res: any) => {
                                                  if (res.isConfirmed) {
                                                    if (workflow?.name == 'New Lease')
                                                      setNewLeaseApproval(e)
                                                    else if (workflow?.name == 'Lease Renewals')
                                                      setRenewalLeaseApproval(e)
                                                    else if (workflow?.name == 'Lease Termination')
                                                      setTerminateLeaseApproval(e)
                                                  } else if (res.isDenied) {
                                                  } else {
                                                  }
                                                })
                                            }}
                                            defaultChecked={
                                              workflow?.name == 'New Lease'
                                                ? newLeaseApproval
                                                : workflow?.name == 'Lease Renewals'
                                                ? renewalLeaseApproval
                                                : workflow?.name == 'Lease Termination'
                                                ? terminateLeaseApproval
                                                : ''
                                            }
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            checked={
                                              workflow?.name == 'New Lease'
                                                ? newLeaseApproval
                                                : workflow?.name == 'Lease Renewals'
                                                ? renewalLeaseApproval
                                                : workflow?.name == 'Lease Termination'
                                                ? terminateLeaseApproval
                                                : ''
                                            }
                                            onColor='#00cf99'
                                            offColor='#b0bec5'
                                          />
                                          <h5 className=' m-0 head-text ms-3'>
                                            {workflow?.name == 'New Lease' ? (
                                              <> {newLeaseApproval ? 'On' : 'Off'}</>
                                            ) : workflow?.name == 'Lease Renewals' ? (
                                              <> {renewalLeaseApproval ? 'On' : 'Off'}</>
                                            ) : workflow?.name == 'Lease Termination' ? (
                                              <> {terminateLeaseApproval ? 'On' : 'Off'}</>
                                            ) : (
                                              ''
                                            )}
                                          </h5>
                                        </div>
                                      </div>
                                    </td>

                                    <td className='text-start ps-0 min-w-200px'>
                                      <div className='text-center lease'>
                                        <h5 className=' m-0 lease-grey-label mb-3 light-dark-theme-color'>
                                          {workflow?.name == 'New Lease' ? (
                                            <> {'Include Financials in tenancy creation?'}</>
                                          ) : workflow?.name == 'Lease Renewals' ? (
                                            <> {'Include Financials in tenancy renewals?'}</>
                                          ) : workflow?.name == 'Lease Termination' ? (
                                            <> {'Include Financials in tenancy terminations?'}</>
                                          ) : (
                                            ''
                                          )}

                                          <span>
                                            <OverlayTrigger
                                              placement='top'
                                              delay={{show: 250, hide: 400}}
                                              overlay={
                                                <Tooltip
                                                  id='button-tooltip-3'
                                                  className='custom-tooltip'
                                                >
                                                  {' '}
                                                  {workflow?.name == 'New Lease' ? (
                                                    <> {'Financials in tenancy creation..'}</>
                                                  ) : workflow?.name == 'Lease Renewals' ? (
                                                    <> {'Financials in tenancy renewals..'}</>
                                                  ) : workflow?.name == 'Lease Termination' ? (
                                                    <> {'Financials in tenancy termination..'}</>
                                                  ) : (
                                                    ''
                                                  )}
                                                </Tooltip>
                                              }
                                            >
                                              <span>
                                                {' '}
                                                <IoMdInformationCircleOutline className='ms-0 cursor-pointer exclam-circle' />
                                              </span>
                                            </OverlayTrigger>
                                          </span>
                                        </h5>

                                        <div className='d-flex justify-content-center align-items-center'>
                                          <Switch
                                            onChange={(e) => {
                                              setSelected(workflow)

                                              swal
                                                .fire({
                                                  html: `<div>You are about to  ${
                                                    e ? 'include' : 'remove'
                                                  }  Financials as part of the '${
                                                    workflow?.name
                                                  }' flow.
                                                  </div>
                                                  <div class='mt-4'> Financials will be ${
                                                    e ? 'included' : 'removed'
                                                  }  in new ${
                                                    workflow?.name == 'New Lease'
                                                      ? 'leases'
                                                      : workflow?.name == 'Lease Renewals'
                                                      ? 'renewals'
                                                      : 'terminations'
                                                  } generated.
                                                This update will not be applied to existing incomplete/ draft ${
                                                  workflow?.name == 'New Lease'
                                                    ? 'leases'
                                                    : workflow?.name == 'Lease Renewals'
                                                    ? 'renewals'
                                                    : 'terminations'
                                                }.</div>
                                               <div class='mt-4'> Are you sure you want to continue? </div>`,
                                                  // icon: 'warning',
                                                  showConfirmButton: true,
                                                  confirmButtonText: 'Yes',
                                                  showCancelButton: true,
                                                  cancelButtonText: 'No',
                                                  customClass: {
                                                    confirmButton: 'custom-confirm-button',
                                                    cancelButton: 'custom-cancel-button',
                                                    popup: 'custom-popup',
                                                  },
                                                  reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                                })
                                                .then((res: any) => {
                                                  if (res.isConfirmed) {
                                                    if (workflow?.name == 'New Lease')
                                                      setNewLeaseFinancials(e)
                                                    else if (workflow?.name == 'Lease Renewals')
                                                      setRenewalLeaseFinancials(e)
                                                    else if (workflow?.name == 'Lease Termination')
                                                      setTerminateLeaseFinancials(e)
                                                  } else if (res.isDenied) {
                                                  } else {
                                                  }
                                                })
                                            }}
                                            defaultChecked={
                                              workflow?.name == 'New Lease'
                                                ? newLeaseFinancials
                                                : workflow?.name == 'Lease Renewals'
                                                ? renewalLeaseFinancials
                                                : workflow?.name == 'Lease Termination'
                                                ? terminateLeaseFinancials
                                                : ''
                                            }
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            checked={
                                              workflow?.name == 'New Lease'
                                                ? newLeaseFinancials
                                                : workflow?.name == 'Lease Renewals'
                                                ? renewalLeaseFinancials
                                                : workflow?.name == 'Lease Termination'
                                                ? terminateLeaseFinancials
                                                : ''
                                            }
                                            onColor='#00cf99'
                                            offColor='#b0bec5'
                                          />
                                          <h5 className=' m-0 head-text ms-3'>
                                            {workflow?.name == 'New Lease' ? (
                                              <> {newLeaseFinancials ? 'Yes' : 'No'}</>
                                            ) : workflow?.name == 'Lease Renewals' ? (
                                              <> {renewalLeaseFinancials ? 'Yes' : 'No'}</>
                                            ) : workflow?.name == 'Lease Termination' ? (
                                              <> {terminateLeaseFinancials ? 'Yes' : 'No'}</>
                                            ) : (
                                              ''
                                            )}
                                          </h5>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                </>
              ) : (
                <>
                  <Row>
                    <Col md={12} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </Col>
                  </Row>
                </>
              )}
              {/* <Col md={12} className='mb-5'>
                <div className='card card-flush gap-5 p-3 cursor-pointer'>
                  <div className='card-body p-3 pb-0'>
                    <div className='table-responsive add-user-height'>
                      <table
                        className='table align-middle fs-6 gy-5 '
                        id='kt_ecommerce_sales_table'
                      >
                        <tbody className='fw-semibold text-gray-600'>
                          <tr>
                            <td className='text-start ps-0 min-w-50px'>
                              <img src={settingsIcon} height={25} width={25} />
                            </td>

                            <td className='text-start ps-0 min-w-150px'>
                              <div className='d-flex align-items-center'>
                                <h4 className=' m-0 head-text my-3'>
                                  <b>Lease Renewals</b>
                                </h4>
                              </div>
                            </td>

                            <td className='text-start ps-0 min-w-150px'>
                              <div className='ms-auto text-center'>
                                <h5 className=' m-0 lease-grey-label mb-3'>Workflow Category</h5>
                                <h5 className=' m-0 head-text mb-3'>
                                  Leasing - Residential Leasing
                                </h5>
                              </div>
                            </td>
                            <td className='text-start ps-0 min-w-150px'>
                              <div className='ms-auto text-center lease'>
                                <h5 className=' m-0 lease-grey-label mb-3'>
                                  Approvals
                                  <span>
                                    <OverlayTrigger
                                      placement='top'
                                      delay={{show: 250, hide: 400}}
                                      overlay={
                                        <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                          {' '}
                                          Lease Renewals Approvals..
                                        </Tooltip>
                                      }
                                    >
                                      <span>
                                        {' '}
                                        <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                                      </span>
                                    </OverlayTrigger>
                                  </span>
                                </h5>
                                <div className='d-flex justify-content-center align-items-center '>
                                  <Switch
                                    onChange={(e) => {
                                      setRenewalLeaseApproval(e)
                                    }}
                                    defaultChecked={renewalLeaseApproval}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    checked={renewalLeaseApproval}
                                    onColor='#00cf99'
                                    offColor='#b0bec5'
                                  />
                                  <h5 className=' m-0 head-text ms-3'>
                                    {renewalLeaseApproval ? 'On' : 'Off'}
                                  </h5>
                                </div>
                              </div>
                            </td>

                            <td className='text-start ps-0 min-w-200px'>
                              <div className='text-center lease'>
                                <h5 className=' m-0 lease-grey-label mb-3'>
                                  Include Financials in tenancy renewals?
                                  <span>
                                    <OverlayTrigger
                                      placement='top'
                                      delay={{show: 250, hide: 400}}
                                      overlay={
                                        <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                          {' '}
                                          Financials in tenancy renewals..
                                        </Tooltip>
                                      }
                                    >
                                      <span>
                                        {' '}
                                        <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                                      </span>
                                    </OverlayTrigger>
                                  </span>
                                </h5>

                                <div className='d-flex justify-content-center align-items-center'>
                                  <Switch
                                    onChange={(e) => {
                                      setRenewalLeaseFinancials(e)
                                    }}
                                    defaultChecked={renewalLeaseFinancials}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    checked={renewalLeaseFinancials}
                                    onColor='#00cf99'
                                    offColor='#b0bec5'
                                  />
                                  <h5 className=' m-0 head-text ms-3'>
                                    {renewalLeaseFinancials ? 'Yes' : 'No'}
                                  </h5>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12} className='mb-5'>
                <div className='card card-flush gap-5 p-3 cursor-pointer'>
                  <div className='card-body p-3 pb-0'>
                    <div className='table-responsive add-user-height'>
                      <table
                        className='table align-middle fs-6 gy-5 '
                        id='kt_ecommerce_sales_table'
                      >
                        <tbody className='fw-semibold text-gray-600'>
                          <tr>
                            <td className='text-start ps-0 min-w-50px'>
                              <img src={settingsIcon} height={25} width={25} />
                            </td>

                            <td className='text-start ps-0 min-w-150px'>
                              <div className='d-flex align-items-center'>
                                <h4 className=' m-0 head-text my-3'>
                                  <b>Lease Terminations </b>
                                </h4>
                              </div>
                            </td>

                            <td className='text-start ps-0 min-w-200px'>
                              <div className='ms-auto text-center'>
                                <h5 className=' m-0 lease-grey-label mb-3'>Workflow Category</h5>
                                <h5 className=' m-0 head-text mb-3'>
                                  Leasing - Residential Leasing
                                </h5>
                              </div>
                            </td>
                            <td className='text-start ps-0 min-w-150px'>
                              <div className='ms-auto text-center lease'>
                                <h5 className=' m-0 lease-grey-label mb-3'>
                                  Approvals
                                  <span>
                                    <OverlayTrigger
                                      placement='top'
                                      delay={{show: 250, hide: 400}}
                                      overlay={
                                        <Tooltip id='button-tooltip-3' className='custom-tooltip lease'>
                                          {' '}
                                          Lease Terminations Approvals..
                                        </Tooltip>
                                      }
                                    >
                                      <span>
                                        {' '}
                                        <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                                      </span>
                                    </OverlayTrigger>
                                  </span>
                                </h5>
                                <div className='d-flex justify-content-center align-items-center'>
                                  <Switch
                                    onChange={(e) => {
                                      setTerminateLeaseApproval(e)
                                    }}
                                    defaultChecked={terminateLeaseApproval}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    checked={terminateLeaseApproval}
                                    onColor='#00cf99'
                                    offColor='#b0bec5'
                                  />
                                  <h5 className=' m-0 head-text ms-3'>
                                    {terminateLeaseApproval ? 'On' : 'Off'}
                                  </h5>
                                </div>
                              </div>
                            </td>

                            <td className='text-start ps-0 min-w-200px'>
                              <div className='text-center lease'>
                                <h5 className=' m-0 lease-grey-label mb-3'>
                                  Include Financials in tenancy terminations?
                                  <span>
                                    <OverlayTrigger
                                      placement='top'
                                      delay={{show: 250, hide: 400}}
                                      overlay={
                                        <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                          {' '}
                                          Financials in tenancy terminations..
                                        </Tooltip>
                                      }
                                    >
                                      <span>
                                        {' '}
                                        <AiOutlineExclamationCircle className='ms-0 cursor-pointer exclam-circle' />
                                      </span>
                                    </OverlayTrigger>
                                  </span>
                                </h5>
                                <div className='d-flex justify-content-center align-items-center'>
                                  <Switch
                                    onChange={(e) => {
                                      setTerminateLeaseFinancials(e)
                                    }}
                                    defaultChecked={terminateLeaseFinancials}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    checked={terminateLeaseFinancials}
                                    onColor='#00cf99'
                                    offColor='#b0bec5'
                                  />
                                  <h5 className=' m-0 head-text ms-3'>
                                    {terminateLeaseFinancials ? 'Yes' : 'No'}
                                  </h5>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default TypeLeasing
