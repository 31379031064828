import React from 'react'

interface CustomButtonProps {
  text: string
  imgSrc?: string
  imgAlt?: string
  imgSize?: { height: number; width: number };
  className?: string
  onClick?: () => void
}

const CustomButton: React.FC<CustomButtonProps> = ({
  text,
  imgSrc,
  imgAlt = 'icon',
  imgSize = {height:16, width:16},
  className = '',
  onClick,
}) => {
  return (
    <button
      type='button'
      className={`btn btn-sm fw-bold ${className}`}
      onClick={onClick}
    >
      {imgSrc && (
        <img src={imgSrc} height={imgSize?.height} width={imgSize?.width} className='me-4' alt={imgAlt} />
      )}
      {text}
    </button>
  )
}

export default CustomButton
