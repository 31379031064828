import { Carousel } from 'antd';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData';
import { ErrorToast } from '../../../apiCommon/helpers/Toast';
import { KTSVG } from '../../../_metronic/helpers';

function VendorManagement() {
    const [vendor, setVendor] = useState<any>()
    const [pageLimit, setPageLimit] = useState<any>()
    const [vendorCount, setVendorCount] = useState<any>()
    const [page, setPage] = useState<any>(1)
    const [vendorList, setVendorList] = useState<any>()
    const [vendorListOverview, setVendorListOverview] = useState<any>([])

    const navigate = useNavigate()

    const getVendorOverView = (vendorListId: any = null) => {
        const body = {
            page: page,
            limit: 10,
            vendorListId: vendorListId
        }
        ApiPost('corporate/vendor/overview', body)
            .then((res) => {
                setVendor(res?.data?.data?.vendor_data)
                setPageLimit(res?.data?.data?.state?.page_limit)
                setVendorCount(res?.data?.data?.state?.data_count)

            })
            .catch((err) => ErrorToast(err.message))
    }

    const getvendorList = () => {
        ApiGet('corporate/vendor_list')
            .then((res) => {
                setVendorList(res?.data?.data)
            })
            .catch((err) => {
                ErrorToast(err.message)
                if (err?.message) {
                    window.location.pathname = '/auth'
                    localStorage.clear()
                }
            })
    }

    const getvendorListOverview = () => {
        const body = {
            "page": 1,
            "limit": 10,
            "startingWitAlphabet": ""
        }
        ApiPost('corporate/vendor_list/get', body)
            .then((res) => {
                setVendorListOverview(res?.data?.data)
            })
            .catch((err) => ErrorToast(err.message))
    }

    useEffect(() => {
        getvendorListOverview()
        getVendorOverView()
        getvendorList()
    }, [page])

    const handlePageClick = (event: any) => {
        setPage(event.selected + 1)
    }
    const contentStyle: React.CSSProperties = {
        margin: 0,
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    const onChange = (currentSlide: number) => {
        console.log(currentSlide);
    };

    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div id='' className='container-xxl pt-0 mt-0 px-4'>
                <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-5 min-w-200px'>
                    <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                        Vendor Management
                    </h1>
                </div>
                <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/vendor-management/serviceproviders`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                                Service Providers
                            </a>
                        </li>
                        <li
                            className='nav-item'
                        /*   onClick={() => {
                              navigate(`/vendor-management/suppliers`)
                          }} */
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                                Suppliers
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='mb-8 min-w-375px d-flex w-auto'>
                    <div
                        className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                        onClick={() => navigate(`/vendor-management/management-list`)}
                    >
                        Manage Vendors
                    </div>
                </div>
                <div className='overflow-scroll'>
                    <div className='w-100 row'>

                        <Col className='px-5'  xs={4} sm={4} md={4} lg={4}>
                            <h4 onClick={() => getVendorOverView(null)}>All</h4>
                            <span onClick={() => getVendorOverView(null)}>{vendorListOverview?.state?.data_count}</span>
                        </Col>

                        {
                            vendorListOverview?.vendor_data?.map((i: any, index: number) => {
                                return (

                                    <Col className='px-5' xs={4} sm={4} md={4} lg={4}>
                                        <h4 onClick={() => getVendorOverView(i._id)}>{i?.name}</h4>
                                        <span onClick={() => getVendorOverView(i._id)}>{i.totalVendor}</span>
                                    </Col>

                                )
                            })
                        }


                    </div>
                </div>
                <div className='card-header align-items-center py-5 mt-5 gap-md-2 d-flex'>
                    <h4 className='gray-500'>
                        Total Service Providers:{' '}
                        <span className='' style={{ color: 'black' }}>
                            {vendorCount}
                        </span>
                    </h4>
                </div>
                <div className='card card-flush px-2'>
                    <div className='card-body pt-0 table-responsive px-3'>
                        <table
                            className='table align-middle table-row-dashed fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                        >
                            <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                    <th className='text-center min-w-100px'>Vendor Id</th>
                                    <th className='text-center min-w-150px'>Vendor Name</th>
                                    <th className='text-center min-w-100px'>Type</th>
                                    <th className='text-center min-w-70px'>TRN</th>
                                    <th className='text-center min-w-150px'>Trade License</th>
                                    <th className='text-center min-w-100px'>City</th>
                                    <th className='text-center min-w-150px'>Overall Requests</th>
                                    <th className='text-center min-w-150px'>List</th>
                                    <th className='text-center min-w-200px'>Main Contact (Mobile No.)</th>
                                    <th className='text-center min-w-150px'>Main Contact (Email)</th>
                                    <th className='text-center min-w-100px'>Rating</th>
                                    <th className='text-center min-w-100px'>Notifications</th>
                                </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                                {vendor?.map((f: any, i: any) => {
                                    return (
                                        <tr>
                                            <td className='text-center'>{f?.id}</td>
                                            <td className='text-center'>{`${f?.firstName}` + ' ' + `${f?.firstName}`}</td>
                                            <td className='text-center'>{f?.vendorType === 0 ? 'Service provide' : ''}</td>
                                            <td className='text-center'>{f?.TRN}</td>
                                            <td className='text-center'>{f?.tradeLicenseNo}</td>
                                            <td className='text-center'>{f?.city}</td>
                                            <td className='text-center'>{ }</td>
                                            <td className='text-left'>{f?.vendor_list?.map((i: any) => {
                                                return (<li>{i?.name}</li>)
                                            })
                                            }</td>
                                            <td className='text-center'>{f?.phoneNumber}</td>
                                            <td className='text-center'>{f?.email}</td>
                                            <td className='text-center'>{ }</td>
                                            <td className='text-center'><KTSVG
                                                path='/media/icons/duotune/general/gen007.svg'
                                                className='svg-icon-3 text-primary me-1 ms-2 me-0' />5</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                        </div>
                        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                            <div
                                className='dataTables_paginate paging_simple_numbers'
                                id='kt_ecommerce_sales_table_paginate'
                            >
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    pageClassName="paginate_button page-item +"
                                    pageLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    previousClassName="paginate_button page-item previous disabled"
                                    nextClassName="paginate_button page-item next"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageLimit}
                                    previousLabel="<"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VendorManagement;