import React, {useState, useEffect} from 'react'
import CustomCard from '../../../General/CustomCard'
import receiptsImg from '../../../../../img/downDoubleArrow.png'
import totalReceiptsImg from '../../../../../img/totalInvoice.png'
import upcomingImg from '../../../../../img/upcoming-expense.png'
import overdueImg from '../../../../../img/overdue-expense.png'
import paidImg from '../../../../../img/paid-expense.png'
import vatImg from '../../../../../img/vatIcon.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {useNavigate} from 'react-router-dom'
import receiptWhite from '../../../../../img/receipt-white.png'
import noData from '../../../../../img/NoData1.svg'
import moment from 'moment'
import {ApiPost} from '../../../../../apiCommon/helpers/API/ApiData'
import openImg from '../../../../../img/black-collap-down.png'
import closeImg from '../../../../../img/black-collap-up.png'
import ReactPaginate from 'react-paginate'
import InvoicesPopup from './Receipts/InvoicesPopup'
import {formatAmount} from '../../../../../Utilities/utils'

const ReceiptsTab = () => {
  const [tabsInsights, setTabsInsights] = useState<any>([
    {
      title: 'Receipts',
      icon: receiptsImg,
      value: 0,
      currency: 'AED',
      isAmount: false,
    },
    // {
    //   title: 'Total',
    //   icon: totalReceiptsImg,
    //   value: 0,
    //   currency: 'AED',
    //   isAmount: true,
    // },
    {
      title: 'Paid',
      icon: paidImg,
      value: 0,
      currency: 'AED',
      isAmount: true,
    },
    // {
    //   title: 'Upcoming',
    //   icon: upcomingImg,
    //   value: 0,
    //   currency: 'AED',
    //   isAmount: true,
    // },
    // {
    //   title: 'Overdue',
    //   icon: overdueImg,
    //   value: 0,
    //   currency: 'AED',
    //   isAmount: true,
    // },
    // {
    //   title: 'VAT Returns',
    //   icon: vatImg,
    //   value: 0,
    //   currency: 'AED',
    //   isAmount: true,
    // },
  ])

  const navigate = useNavigate()
  const [receipts, setReceipts] = useState<any>([])
  const [show, setShow] = useState<any>([])

  const [searchReceipt, setSearchReceipt] = useState<any>('')
  const [searchPayor, setSearchPayor] = useState<any>('')
  const [showFilter, setShowFilter] = useState<any>(false)

  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)

  const [showInvoices, setShowInvoices] = useState<any>(false)
  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    getAllReceipts(null, event.selected + 1)
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  //
  const goToReceipt = (data: any) => {
    if (data?.formStatus == 0) navigate(`/finance-income/add-receipt/2/${data?._id}`)
    else navigate(`/finance-income/view-receipt/${data?._id}`)
  }

  //
  const getAllReceipts = (data: any, pageVal: any) => {
    let body: any
    if (data == null) {
      body = {
        page: pageVal,
        limit: limit,
        searchReceipt: searchReceipt,
        searchTenant: searchPayor,
      }
    } else {
      let values: any = {...data}
      values.page = pageVal
      values.limit = limit
      values.searchPayment = searchReceipt
      values.searchTenant = searchPayor
      body = values
    }

    ApiPost(`corporate/payment_receipt/get`, body)
      .then((res) => {
        setReceipts(res?.data?.data?.payment_receipt_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        let showValues = [...show]
        for (let i = 0; i < res?.data?.data?.payment_receipt_data?.length; i++) {
          showValues[i] = false
        }

        setShow(showValues)
      })
      .catch((err) => console.log('err', err))
  }

  const [receiptsCountData, setReceiptsCountData] = useState<any>()

  //
  useEffect(() => {
    setPage(1)
    getAllReceipts(null, 1)
    getReceiptsCount()
  }, [searchReceipt, searchPayor])

  //
  const getReceiptsCount = () => {
    let body = {
      searchReceipt: searchReceipt,
      searchTenant: searchPayor,
    }
    ApiPost(`corporate/payment_receipt/get_count`, body)
      .then((res) => {
        let values = []

        values?.push({
          title: 'Receipts',
          icon: receiptsImg,
          value: res?.data?.data?.totalReceipts,
          currency: 'AED',
          isAmount: false,
        })

        values?.push({
          title: 'Paid',
          icon: paidImg,
          value: res?.data?.data?.totalPaidAmount,
          currency: 'AED',
          isAmount: true,
        })
        setTabsInsights(values)
      })
      .catch((err) => console.log('err', err))
  }

  return (
    <div id='' className=' pt-0 mt-0 no-print'>
      {/* Insights */}
      <div className='d-flex flex-lg-wrap flex-xl-nowrap mt-5' style={{gap: '20px'}}>
        {tabsInsights?.map((tb: any, ind: number) => {
          return (
            <CustomCard
              title={tb?.title}
              currency={tb?.currency}
              imageUrl={tb?.icon}
              totalAmount={tb?.value}
              isAmount={tb?.isAmount}
            />
          )
        })}
      </div>

      {/* Search Filters */}
      <div className='d-flex align-items-center py-5 pt-2 gy-3 row mx-0 mt-4'>
        <div className='mb-2 col-md-1 pe-5 xyz'>
          <label className='head-text' style={{fontWeight: '600'}}>
            {' '}
            Search{' '}
          </label>
        </div>
        <div className='mb-2 col-md-2 pe-5 property xyz tst'>
          <Paper component='form' style={searchContainerStyle} onSubmit={(e) => e.preventDefault()}>
            <InputBase
              placeholder='Receipt'
              style={inputBaseStyle}
              inputProps={{'aria-label': 'search'}}
              value={searchReceipt}
              onChange={(e: any) => setSearchReceipt(e.target.value)}
            />
            <IconButton style={searchIconStyle} aria-label='search'>
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>

        <div className='mb-2 col-md-2 ps-5 pe-5 property xyz tst'>
          <Paper component='form' style={searchContainerStyle} onSubmit={(e) => e.preventDefault()}>
            <InputBase
              placeholder='Payor'
              style={inputBaseStyle}
              inputProps={{'aria-label': 'search'}}
              value={searchPayor}
              onChange={(e: any) => setSearchPayor(e.target.value)}
            />
            <IconButton style={searchIconStyle} aria-label='search'>
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>

        <div className='mb-2 col-md-5 xyz ms-auto pe-0 text-end'>
          <div className='d-flex justify-content-end align-items-center'>
            <button
              type='button'
              className='btn btn-sm fw-bold green-submit-btn btn-fit-content ps-2'
              onClick={() => {
                navigate('/finance-income/add-receipt/1') // 1 for no status
              }}
            >
              <img src={receiptWhite} height={18} width={18} className='me-3' /> Raise Receipt
            </button>

            <div className='mb-2 col-md-1 pe-5 xyz pt-1'>
              <div
                className='ok-div d-flex align-items-center justify-content-center cursor-pointer mx-3'
                onClick={() => setShowFilter(true)}
              >
                <svg
                  style={{
                    height: '20px',
                    width: '28px',
                    marginLeft: '3px',
                  }}
                  viewBox='0 0 1024 1024'
                  xmlns='http://www.w3.org/2000/svg'
                  className='me-1'
                >
                  <path
                    fill='#ffff'
                    d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Table Listing */}
      <div className='card card-flush gap-5 p-5 pt-1 pe-2'>
        <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5 ps-0' id='global-table'>
          <table
            className='table align-start table-row-dashed fs-6 gy-5'
            id='kt_ecommerce_sales_table'
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-125px'>Receipt</th>
                <th className='min-w-150px '>Payor</th>
                <th className='min-w-125px'>Amount</th>
                <th className='min-w-125px'>Payment Date</th>
                <th className='min-w-125px'>Invoices</th>
                <th className='text-center min-w-125px'>Form Status</th>
                <th className='min-w-150px'>Created By</th>
                <th className='min-w-150px'>Last Updated</th>
              </tr>
            </thead>
            <tbody className='fw-semibold head-text'>
              {receipts?.length > 0 ? (
                <>
                  {receipts?.map((rcp: any, ind: number) => {
                    return (
                      <>
                        <tr
                          style={{
                            borderBottomStyle:
                              show[ind] === true
                                ? 'none'
                                : show[ind] === false || rcp?.paymentSubItems?.length === 0
                                ? 'dashed'
                                : undefined,
                          }}
                          className=''
                        >
                          <td className=''>
                            <div className='d-flex text-decoration-underline'>
                              {rcp?.paymentSubItems?.length > 0 && (
                                <img
                                  src={show[ind] ? closeImg : openImg}
                                  height='15'
                                  width='15'
                                  className='cursor-pointer me-3'
                                  // style={{marginLeft: '-30px', marginRight: '15px'}}
                                  onClick={() => {
                                    if (show[ind] == true) {
                                      let values = [...show]
                                      values[ind] = false
                                      setShow(values)
                                    } else {
                                      let values = [...show]

                                      values[ind] = true

                                      setShow(values)
                                    }
                                  }}
                                />
                              )}
                              <b
                                onClick={() => {
                                  goToReceipt(rcp)
                                }}
                                className='cursor-pointer'
                              >
                                {rcp?.receiptNo ? rcp?.receiptNo : '-'}
                              </b>
                            </div>
                          </td>
                          <td
                            className='cursor-pointer'
                            onClick={() => {
                              goToReceipt(rcp)
                            }}
                          >
                            {rcp?.tenant?.length > 0
                              ? `${rcp?.tenant?.[0]?.firstName} ${rcp?.tenant?.[0]?.lastName}`
                              : '-'}
                          </td>
                          <td
                            className='cursor-pointer'
                            onClick={() => {
                              goToReceipt(rcp)
                            }}
                          >
                            {rcp?.amountPaid > 0 ? (
                              <>AED {formatAmount(rcp?.amountPaid)}</>
                            ) : (
                              <>AED 0</>
                            )}
                          </td>

                          <td
                            className='cursor-pointer'
                            onClick={() => {
                              goToReceipt(rcp)
                            }}
                          >
                            {rcp?.datePaid ? moment(rcp?.datePaid).format('DD.MM.YYYY') : '-'}
                          </td>

                          <td
                            className={` ${
                              rcp?.invoiceRecords?.length > 0 ? 'cursor-pointer' : ''
                            }`}
                            onClick={() => {
                              setSelectedInvoices(rcp?.invoiceRecords)
                              setShowInvoices(true)
                            }}
                          >
                            <div className='d-flex text-decoration-underline'>
                              <b>
                                {rcp?.invoiceRecords?.length > 0 ? rcp?.invoiceRecords?.length : 0}
                              </b>
                            </div>
                          </td>

                          <td
                            className='cursor-pointer'
                            onClick={() => {
                              goToReceipt(rcp)
                            }}
                          >
                            <div
                              className={`status status-w-100 mx-auto
                                ${
                                  rcp?.formStatus == 0
                                    ? 'draft-st '
                                    : rcp?.formStatus == 1
                                    ? 'create-st'
                                    : 'not-saved'
                                }
                              `}
                            >
                              {rcp?.formStatus == 0
                                ? 'Draft'
                                : rcp?.formStatus == 1
                                ? 'Created'
                                : '-'}
                            </div>
                          </td>

                          <td
                            className='cursor-pointer'
                            onClick={() => {
                              goToReceipt(rcp)
                            }}
                          >
                            <p className='my-0'>
                              {rcp?.createdByUser?.[0] &&
                                `${rcp?.createdByUser?.[0]?.firstName} ${rcp?.createdByUser?.[0]?.lastName} - `}
                            </p>
                            <p className='my-0'>
                              {rcp?.createdAt
                                ? `${moment(rcp?.createdAt).format('DD.MM.YYYY - hh:mm  a')}`
                                : '-'}
                            </p>
                          </td>

                          <td
                            className='cursor-pointer'
                            onClick={() => {
                              goToReceipt(rcp)
                            }}
                          >
                            {rcp?.updatedByUser?.length > 0 ? (
                              <>
                                <p className='my-0'>
                                  {rcp?.updatedByUser?.[0] &&
                                    `${rcp?.updatedByUser?.[0]?.firstName} ${rcp?.updatedByUser?.[0]?.lastName} - `}
                                </p>
                                <p className='my-0'>
                                  {rcp?.updatedAt
                                    ? ` ${moment(rcp?.updatedAt).format('DD.MM.YYYY - hh:mm a')}`
                                    : '- -'}
                                </p>
                              </>
                            ) : (
                              <>{'-'}</>
                            )}
                          </td>
                        </tr>

                        {rcp?.paymentSubItems?.length > 0 && show[ind] == true ? (
                          rcp?.paymentSubItems.map((rcpSub: any, index: any) => {
                            return (
                              <>
                                <tr
                                  style={{
                                    borderBottomStyle:
                                      rcp?.paymentSubItems?.length === index - 1
                                        ? 'none'
                                        : 'dashed',
                                  }}
                                >
                                  <td>
                                    <div style={{marginLeft: '27px'}} className='i_grey'>{rcpSub?.name}</div>
                                  </td>
                                  <td></td>
                                  <td className='i_grey'>
                                    {rcpSub?.amountPaid > 0 ? (
                                      <>
                                        AED{' '}
                                        {parseFloat(rcpSub?.amountPaid)?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </>
                                    ) : (
                                      <>AED 0</>
                                    )}
                                  </td>
                                </tr>
                              </>
                            )
                          })
                        ) : (
                          <></>
                        )}
                      </>
                    )
                  })}
                </>
              ) : (
                <tr>
                  <td colSpan={10} className='text-center'>
                    <img src={noData} alt='' width={350} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous disabled'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <InvoicesPopup
        show={showInvoices}
        handleClose={() => {
          setShowInvoices(false)
        }}
        invoicesData={selectedInvoices}
      />
    </div>
  )
}

export default ReceiptsTab
