/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {ApiGet, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import noData from '../../../img/NoData1.svg'
import {
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
  CardsWidget7,
  CardsWidget17,
  CardsWidget20,
  ListsWidget26,
  EngageWidget10,
} from '../../../_metronic/partials/widgets'
import ReactPaginate from 'react-paginate'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import {IFIlterProps} from './DashboardWrapper'
import FilterSection from './Components/FilterSection'
import ListOfBuilding from './Components/ListOfBuilding'

const tabsname = ['Overview', 'Properties', 'Tenants', 'Announcements', 'Payments & Rent']

const DashboardPage: FC = () => (
  <>
    <div className='row gy-5 gx-xl-8'>
      <div className='col-xl-12'>
        <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
      </div>
    </div>
  </>
)

const BuildingDashboard: FC = () => {
  const [state, setState] = useState<IFIlterProps>({
    loading: false,
    page: 1,
    pageLimit: 10,
    pageCount: 0,
    unitNo: '',
    developmentType: '',
    unitId: '',
    unitType: null,
    bedrooms: null,
    rating: null,
    occupy: null,
    data: [],
    count: {},
    devList: []
  })
  const permission: any = localStorage.getItem('permission')
  const permissionType = JSON.parse(permission)

  const [page, setPage] = useState<any>(1)
  const intl = useIntl()
  const navigate = useNavigate()

  const handleChange = (name: string, value: any) => {
    updateData(name, value)
  }

  const handleRoutes = (routes: string) => {
    navigate(routes)
    updateData('page', 1)
  }

  const getCount = () => {
    ApiGet('corporate/dashboard')
      .then((res) => {
        updateData('count', res?.data?.data)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  const updateData = (key: string, value: any) => setState((prev) => ({...prev, [key]: value}))
  const getData = () => {
    let body = {
      page: state.page,
      limit: state.pageLimit,
      watchType: 'building',
      searchUnitNo: state.unitNo,
      unitType: state.unitType === '0' ? null : state.unitType,
      rating: state.rating === '' ? null : state.rating,
      occupy: state.occupy === '' ? null : state.occupy,
      bedrooms: state.bedrooms === '' ? null : state.bedrooms,
      premiseNo: '',
      buildingManager: state.buildingManager,
      propertyManager: state.propertyManager,
      buildingId: state.developmentType,
      searchId: state.unitId
    }
    ApiPost('corporate/unit/home/get', body)
      .then((res) => {
        updateData('data', res?.data?.data?.unit_data)
        updateData('pageCount', res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        console.log('err', err)
        ErrorToast(err?.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  const getBuildings = () =>  {
    ApiGet('corporate/building')
    .then((res) => {
      updateData('devList', res?.data?.data)
    })
    .catch((err) => {
      console.log('err', err.message)
    }) 
  }

  useEffect(() => {
    updateData('loading', true)

    const getListAll = setTimeout(() => {
      getCount()
      getData()
      getBuildings()
    }, 1000)
    return () => clearTimeout(getListAll)
  }, [state.page, state.unitType, state.unitNo, state.unitId, state.developmentType])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }
  const onReset = () => {
    updateData('rating', '')
    updateData('occupy', '')
    updateData('bedrooms', '')
    updateData('buildingManager', '')
    updateData('propertyManager', '')
    getData()
  }
  return (
    <>
      <div
        className='px-2 mx-auto d-flex gap-5 flex-column p-0 m-0'
        id='kt_app_main'
      >
        <FilterSection
          onManageProperties={() => {
            permissionType?.property === true
              ? navigate('/community')
              : ErrorToast('youhave not permission to add property')
          }}
          onApply={getData}
          onReset={onReset}
          handleChange={handleChange}
          handleRoutes={handleRoutes}
          state={state}
        />
        <div className='card p-5'>
          <ListOfBuilding data={state} handlePageClick={handlePageClick} />
        </div>
      </div>
    </>
  )
}

export {BuildingDashboard}
