import React, {useState, useEffect, useRef} from 'react'
import clsx from 'clsx'
import noData from '../../../../img/NoData1.svg'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'
import bell from '../../../../img/bell.svg'
import chat from '../../../../img/chat.svg'
import send from '../../../../img/sendwhite.svg'
import plus from '../../../../img/plus.svg'
import filter from '../../../../img/filter.svg'
import closes from '../../../../img/close.svg'
import leftarrow from '../../../../img/arrowleft.svg'
import HeaderUserMenu from '../../../partials/layout/header-menus/HeaderUserMenu'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {onMessageListener} from '../../../../apiCommon/helpers/firebase/firebase'
import {Form, InputGroup, Button, Card} from 'react-bootstrap'
import {ApiGet, ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import moment from 'moment'

// import socket from '../../../../app/modules/General/Socket'
import io from 'socket.io-client'

let user: any = localStorage.getItem('userData')
user = JSON.parse(user)

const socket = io('https://dev.api.propertise.com/room')

const userAvatarClass = 'symbol-35px symbol-md-40px'
const btnIconClass = 'svg-icon-1'
interface ChatMessage {
  author: string
  message: string
  createdAt: any
}
const Navbar = () => {
  const myDivRef = useRef(null)
  const searchRef = useRef(null)
  const bottomRef = useRef<any>(null)

  const {config} = useLayout()
  const navigate = useNavigate()
  const [showNotification, setShowNotification] = useState<any>(1)
  const [showChat, setShowChat] = useState<any>('message')
  const [showMessageCard, setShowMessageCard] = useState<any>(false)
  const [showFilterCard, setShowFilterCard] = useState<any>(false)
  const [messages, setMessages] = useState<any>([])
  const [author, setAuthor] = useState('')
  const [message, setMessage] = useState('')
  const [chatTab, setchatTab] = useState<any>('All')
  const [room_id, setRoom_id] = useState<any>()
  const [name, setName] = useState<any>('')
  const [chatData, setChatData] = useState<any>([])
  const [totalArr, settotalArr] = useState<any>([])
  const [selectedDataListTenant, setSelectedDataListTenant] = useState<any>([])
  const [selectedDataListStaff, setSelectedDataListStaff] = useState<any>([])
  const [allDataPopup, setAllDataPopup] = useState<any>([])
  const [internalDataPopup, setInternalDataPopup] = useState<any>([])
  const [tenantDataPopup, setTenantDataPopup] = useState<any>([])
  const [bodyData, setBodyData] = useState<any>({})

  const [payloadData, setPayloadData] = useState<any>(false)
  const [notificationRed, setNotificationRed] = useState<any>(false)
  const [bodyWithSubject, SetBodyWithSubject] = useState<any>({})
  const [defaultRoomData, setDefaultRoomData] = useState<any>(false)

  const [pageLimit, setPageLimit] = useState<any>(false)
  const [page, setPage] = useState<any>(1)

  useEffect(() => {
    console.log(bodyData)
    if (bodyData?.staffIds?.length || bodyData?.tenantIds?.length) {
      settotalArr([...bodyData?.staffIds, ...bodyData?.tenantIds])
    }

    // console.log([...bodyData?.staffIds, ...bodyData?.tenantIds])
  }, [bodyData])

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollTop = bottomRef.current?.scrollHeight
    }
  }, [messages])

  // useEffect(() => {
  //   socket.once('online', handleOnline)
  //   socket.emit('online', {userId: user._id}, (res: any) => {
  //     console.log('socket res', res)
  //   })
  //   return () => {
  //     socket.off('online', handleOnline)
  //     socket.close()
  //   }
  // }, [])
  // const handleOnline = (data: any) => {
  //   console.log('socket Online event received:', data)
  //   // Update state to reflect online status
  // }
  useEffect(() => {
    socket.on('receive_message', (newMessage: any) => {
      console.log('socket newMessage', newMessage)
      console.log('messages', messages)
      setMessages([
        ...messages,
        {author: newMessage?.author || '', message: newMessage?.message, createdAt: new Date()},
      ])
    })
    socket.on('new_user', (new_user) => {
      console.log('socket new_user', new_user)
    })
  }, [])

  useEffect(() => {
    socket.off('receive_message')
    socket.on('receive_message', (message: any) => {
      setMessages((prevMessages: any) => [...prevMessages, message])
    })
  }, [])
  const handleJoinRoom = (room: any) => {
    socket.emit('join_room', room)
  }
  const handleSendMessage = (message: any) => {
    socket.off('send_message')
    socket.emit('send_message', message)
  }
  useEffect(() => {
    socket.off('online')
    socket.emit('online', {userId: user._id}, (res: any) => {
      console.log('socket res', res)
    })
  }, [])
  // useEffect(() => {
  //   console.log('messages', messages)
  //   socket.on('receive_message', (newMessage: any) => {
  //     console.log('socket newMessage', newMessage)
  //     console.log('socket messages', messages)
  //     setMessages([
  //       ...messages,
  //       {author: newMessage?.author, message: newMessage?.message, createdAt: new Date()},
  //     ])
  //   })
  //   socket.on('new_user', (new_user) => {
  //     console.log('socket new_user', new_user)
  //   })
  //   // Send a message to the server
  //   // Receive messages from the server
  //   socket.emit('online', {userId: user._id}, (res: any) => {
  //     console.log('socket res', res)
  //   })
  //   socket.on('online', (data) => {
  //     console.log('socket data', data)
  //     // io.emit('post', {userId: user._id})
  //   })
  // }, [])

  const openMessage1 = async (e: any, el: any, chat: any) => {
    console.log('chat', chat)
    setRoom_id(chat)

    await ApiGet(`corporate/chat_room/${chat._id}`)
      .then((res) => {
        setDefaultRoomData(res.data.data)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })

    await ApiPost(`corporate/chat_message/get`, {
      limit: 25,
      page: 1,
      chatRoomId: chat._id,
    })
      .then((res) => {
        console.log('\nres.data.data', res.data.data)
        setMessages(res?.data?.data?.chat_message_data)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })

    handleJoinRoom({
      roomId: chat._id,
      userId: user._id,
    })
    setShowChat('chat')
  }

  const [chatId, setChatId] = useState<any>('')
  const [msgPage, setMsgPage] = useState<any>(1)
  const [msgPageLimit, setMsgPageLimit] = useState<any>(false)

  const openMessage = async (e: any, el: any, chat: any) => {
    setChatId(chat._id)
    setRoom_id(chat)
    setMessages([])
    setShowChat('chat')

    await ApiGet(`corporate/chat_room/${chat._id}`)
      .then((res) => {
        setDefaultRoomData(res.data.data)
        fetchMessage(chat._id, 1, [])
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
    handleJoinRoom({
      roomId: chat._id,
      userId: user._id,
    }) 
  }

  const fetchMessage = async (chatId: any, searchPage: any, messages: any) => {
    await ApiPost(`corporate/chat_message/get`, {
      limit: 10,
      page: searchPage,
      chatRoomId: chatId,
    })
      .then((res) => {
        setMessages([...messages, ...res?.data?.data?.chat_message_data])
        setMsgPage(msgPage + 1)
        setMsgPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }

  const closeMessageCard = () => {
    setShowMessageCard(false)
    setShowChat(false)
    setShowChat('message')
    settotalArr([])
  }
  const viewall = () => {
    setShowMessageCard(false)
    setShowChat(false)
    setShowChat('message')
    navigate(`chat`)
  }

  const popupEvent = async (flg: any) => {
    setShowMessageCard(false)
    setShowNotification(flg)
    if (flg == 1) {
      setNotificationRed(false)
    }
  }
  const notificationEvent = async () => {
    await onMessageListener()
      .then((payload: any) => {
        setPayloadData(payload)
        setNotificationRed(true)
        console.log('\npayload notification', payload)
      })
      .catch((err) => console.log('failed: ', err))
  }
  notificationEvent()

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    console.log(bodyWithSubject)

    let tIds : any = []
    let tncIds: any = []

    bodyWithSubject?.tenantIds?.map((tn:any, ind:any

    )=>{
      tIds[ind] = tn?.tenant?.[0]?._id
      


    })
    // console.log(defaultRoomData)

    let body = {
      tenantIds: tIds,
      tenancyId:  bodyWithSubject?.tenantIds?.[0]?._id,
      subjectTitle: bodyWithSubject?.subjectTitle,
      type: 0

    }

    // console.log('\nbodyWithSubject', bodyWithSubject)

    
    if (defaultRoomData) {
      handleSendMessage({
        roomId: defaultRoomData?._id,
        message: message,
        senderId: user._id,
        receiverId: bodyData?.staffIds?.[0],
        senderType: 0,
        receiverType: 1,
      })
      // setMessages([...messages, {author, message, createdAt: new Date()}])
      setAuthor('')
      setMessage('')
    } else {
      await ApiPost(`corporate/chat_room`, body)
        .then((res) => {
          console.log('\nres.data.data', res.data.data)
          handleSendMessage({
            roomId: res.data.data?._id,
            message: message,
            senderId: user._id,
            receiverId: bodyData?.staffIds?.[0],
            senderType: 0,
            receiverType: 1,
          })
          openMessage(e, 0, {_id: res.data.data?._id})
          // setMessages([...messages, {author, message, createdAt: new Date()}])
          setAuthor('')
          setMessage('')
        })
        .catch((err) => {
          console.log('res_blob', err)
        })
    }
  }

  useEffect(() => {
    getChatList()
  }, [])

  const dateTotime = (dd: any) => {
    return new Date(dd).toLocaleString()
  }

  const getChatList = async () => {
    await ApiPost(`corporate/chat_room/get`, {
      limit: 10,
      page: page,
    })
      .then((res) => {
        setChatData([...res?.data?.data?.chat_room_data])

        // setChatData([...chatData, ...res?.data?.data?.chat_room_data])
        // setPage(page + 1)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }

  const [searchPage, setSearchPage] = useState<any>(1)
  const [searchPageLimit, setSearchPageLimit] = useState<any>(false)
  const searchFromAll = async (name: any, tab: any, searchPage: any) => {
    setName(name)
    let tab_data: any = {
      isAll: true,
      isInternal: false,
      isTenant: false,
    }
    if (tab == 'All') {
      tab_data = {
        isAll: true,
        isInternal: false,
        isTenant: false,
      }
    } else if (tab == 'Internal') {
      tab_data = {
        isAll: false,
        isInternal: true,
        isTenant: false,
      }
    } else if (tab == 'Tenants') {
      tab_data = {
        isAll: false,
        isInternal: false,
        isTenant: true,
      }
    }
    let body = {
      limit: 25,
      page: searchPage,
      searchName: name,
      ...tab_data,
    }
    await ApiPost(`corporate/chat_room/get_user`, body)
      .then((res) => {
        // setSearchPage(searchPage + 1)
        setSearchPageLimit(res?.data?.data?.state?.page_limit)
        if (chatTab == 'All') {
          setAllDataPopup(res?.data?.data?.user_data || [])
        } else if (chatTab == 'Internal') {
          setInternalDataPopup(res?.data?.data?.user_data || [])
        } else if (chatTab == 'Tenants') {
          setTenantDataPopup(res?.data?.data?.user_data || [])
        }
      })
      .catch((err) => {
        console.log('res_blob', err)
      })
  }
  useEffect(() => {
    searchFromAll('', 'All', searchPage)
  }, [])

  const onChangeTab = (tab: any) => {
    setchatTab(tab)
    setSearchPage(1)
    searchFromAll('', tab, 1)
  }

  const showMessagePopUp = () => {
    setShowMessageCard(!showMessageCard)
    getChatList()
  }

  const userScroll = (e: any) => {
    if (myDivRef.current) {
      const divElement: any = myDivRef.current // Access the div element using the ref
      if (divElement.scrollTop === divElement.scrollHeight - divElement.clientHeight) {
        if (page <= pageLimit) {
          getChatList()
        }
      }
    }

    if (bottomRef.current) {
      const divElement: any = bottomRef.current // Access the div element using the ref
      if (divElement.scrollTop === 0) {
        if (msgPage <= msgPageLimit) {
          fetchMessage(chatId, msgPage, messages)
        }
      }
    }

    if (searchRef.current) {
      const serarchElement: any = searchRef.current // Access the div element using the ref
      if (serarchElement.scrollTop === serarchElement.scrollHeight - serarchElement.clientHeight) {
        if (searchPage < searchPageLimit) {
          setSearchPage(searchPage + 1)
          searchFromAll(name, chatTab, searchPage + 1)
        }
      }
    }
  }

  const makeGroupOfTenants = (e:any, i:any, chck:any) => {
    if (chck) {
            if (selectedDataListTenant.find((e: any) => e._id === i.tenant?.[0]?._id) == undefined) {
              setSelectedDataListTenant([i])
            }
          } else {
            setSelectedDataListTenant(selectedDataListTenant.filter((e: any) => e !== i))
          }

  }

  const makeGroup = (e: any, i: any) => {
    let id: any = allDataPopup[i]
    if (allDataPopup[i].role == 'Tenant') {
      if (e.target.checked) {
        if (selectedDataListTenant.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListTenant((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListTenant(selectedDataListTenant.filter((e: any) => e !== id))
      }
    } else {
      if (e.target.checked) {
        if (selectedDataListStaff.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListStaff((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListStaff(selectedDataListStaff.filter((e: any) => e !== id))
      }
    }
  }

  const goToNewMessage = (from: any) => {
    setShowChat('newMessage')
    let body: any = {}
    if (from == 'all') {
      body = {
        tenantIds: selectedDataListTenant,
        staffIds: selectedDataListStaff,
      }
      if (selectedDataListStaff.length + selectedDataListTenant.length == 1) {
        body['type'] = 0
      } else if (selectedDataListStaff.length + selectedDataListTenant.length > 1) {
        body['type'] = 1
      }
    } else if (from == 'internal') {
      body = {
        tenantIds: selectedDataListTenant,
        staffIds: selectedDataListStaff,
      }
      if (selectedDataListStaff.length + selectedDataListTenant.length == 1) {
        body['type'] = 0
      } else if (selectedDataListStaff.length + selectedDataListTenant.length > 1) {
        body['type'] = 1
      }
    }
    setBodyData(body)
  }

  const changeSubject = (e: any) => {
    let temp = {
      ...bodyData,
      subjectTitle: e.target.value,
    }
    SetBodyWithSubject(temp)
  }

  const gotoUserList = () => {
    getChatList()
    setShowChat('message')
  }

  const [radioChecked, setRadioChecked] = useState<any>(-1)

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item ms-1 ms-lg-3')}>
        <div className='msg_icon' onClick={() => showMessagePopUp()}>
          <img src={chat} alt='chat' style={{width: '26px', height: '24px'}} />
        </div>
      </div>
      <div className={clsx('app-navbar-item ms-1 ms-lg-3')}>
        <div
          className={clsx(
            `cursor-pointer symbol ${notificationRed ? 'msg_icon' : ''}`,
            userAvatarClass
          )}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          onClick={() => {
            popupEvent(1)
          }}
        >
          <img src={bell} alt='bell' style={{width: '35px', height: '24px'}} />
        </div>
        <HeaderUserMenu showNotification={showNotification} payloadData={payloadData} />
      </div>
      <div className={clsx('app-navbar-item ms-1 ms-lg-3')}>
        <div
          className={clsx('cursor-pointer symbol ', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
          onClick={() => {
            popupEvent(2)
          }}
        >
          <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
        </div>
        <HeaderUserMenu showNotification={showNotification} payloadData={payloadData} />
      </div>

      <div
        className={showMessageCard ? 'message' : 'd-none'}
        style={{width: '400px', height: '600px '}}
      >
        <div
          className='card m-0 p-0 br-10 light-gry-dark-shadow'
          style={{
            overflow: 'hidden',
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
          }}
        >
          <h3
            className='m-0 d-flex align-items-center msg_title'
            style={{justifyContent: 'space-between'}}
          >
            Message
            <span className='mx-1' style={{cursor: 'pointer'}}>
              <img src={closes} alt='' onClick={closeMessageCard} />
            </span>
          </h3>
          <div className='p-5 px-2'>
            {showChat == 'chat' ? (
              <>
                <div
                  className='symbol-group symbol-hover py-5 m-0'
                  style={{background: 'transparent', borderRadius: '10px 10px 0 0'}}
                >
                  <div className='symbol-group symbol-hover mx-5'>
                    <img
                      src={leftarrow}
                      width='18px'
                      onClick={() => gotoUserList()}
                      style={{cursor: 'pointer'}}
                    />
                  </div>

                  {defaultRoomData?.userList?.slice(0, 4).map((el: any, i: any) => (
                    <div className='symbol-group symbol-hover m-0 ms-3'>
                      <div className='symbol symbol-40px symbol-circle '>
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              {el.firstName} {el.lastName}
                            </Tooltip>
                          }
                          placement='bottom'
                        >
                          <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} />
                        </OverlayTrigger>
                      </div>
                    </div>
                  ))}
                  {defaultRoomData?.userList?.length > 4 ? (
                    <a
                      href='#'
                      className='symbol symbol-45px symbol-circle'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_view_users'
                    >
                      <span
                        className='symbol-label fs-8 fw-bold green_color'
                        data-bs-toggle='tooltip'
                        data-bs-trigger='hover'
                        data-bs-original-title='View more users'
                        data-kt-initialized='1'
                        onClick={() => {
                          setShowChat('ChatRoomUserList')
                          // setmodalopen(true)
                          // console.log('\nsetmodalopen(true)', modalopen)
                        }}
                      >
                        +{defaultRoomData?.userList?.length - 4}
                      </span>
                    </a>
                  ) : null}
                  {room_id?.userList?.length == 1 ? (
                    <h4 className='m-5 white-dark-theme-color'>
                      {room_id?.userList[0]?.firstName} {room_id?.userList[0]?.lastName}
                    </h4>
                  ) : null}
                </div>
                <h6
                  className='d-flex gap-5 mx-5 align-items-center pb-5'
                  style={{borderBottom: '1px solid #aaa'}}
                >
                  <label htmlFor='subject' className='white-dark-theme-color'>Subject</label>
                  <input
                    type='text'
                    className='form-control form-control-solid mr-5'
                    name='subject'
                    id='subject'
                    value={defaultRoomData?.subjectTitle || ''}
                    disabled={defaultRoomData?.subjectTitle ? true : false}
                    onChange={(e: any) => changeSubject(e)}
                  />
                </h6>
                <div
                  style={{marginBottom: '5rem', flex: 1, overflowY: 'auto', height: '350px'}}
                  ref={bottomRef}
                  onScroll={(e: any) => userScroll(e)}
                >
                  {messages
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                    )
                    ?.map((msg: any) => (
                      <div
                        className={
                          `${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'
                        }
                      >
                        <Card
                          style={{
                            maxWidth: '70%',
                            margin: '10px',
                            alignSelf:
                              `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                          }}
                        >
                          <Card.Body
                            className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'rec_msg br-5 p-0 m-0'
                                : 'send_msg br-5 p-0 m-0'
                            }
                          >
                            <p className='p-0 m-0 small_font white-dark-theme-color pt-2 fnt-600'>
                              {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName}
                            </p>
                            <div className='px-5 pb-5 pt-0 ps-3'>
                              <h4 className='m-0 p-0 pt-5 light-dark-theme-color'>{msg.message}</h4>
                            </div>
                          </Card.Body>
                          <span className='m-0 small_font white-dark-theme-color'>{dateTotime(msg?.createdAt)}</span>
                        </Card>
                      </div>
                    ))}
                </div>
                <div style={{position: 'absolute', bottom: 25, width: '90%'}} className='px-0 ms-3'>
                  <Form onSubmit={handleSubmit}>
                    <InputGroup>
                      <Form.Control
                        type='text'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      <Button
                        variant='primary'
                        type='submit'
                        className='btn-green'
                        disabled={!message}
                      >
                        <img src={send} width='24px' />
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </>
            ) : showChat == 'ChatRoomUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('chat')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {defaultRoomData?.userList?.length > 0
                      ? defaultRoomData?.userList?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} />
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'message' ? (
              <>
                <input
                  type='text'
                  placeholder='Search'
                  className='form form-control m-0 msg_search mx-3'
                  style={{width: '95%'}}
                />
                <img
                  src={filter}
                  alt=''
                  width='24px'
                  className='filter'
                  onClick={() => setShowFilterCard(!showFilterCard)}
                />
                <ul className={showFilterCard ? 'text-center m-0 p-0 card filter_drop' : 'd-none'}>
                  <li className='p-1 white-dark-theme-color' onClick={() => setShowFilterCard(!showFilterCard)}>
                    All
                  </li>
                  <li className='p-1 white-dark-theme-color' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Unread
                  </li>
                  <li className='p-1 white-dark-theme-color' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Tenants
                  </li>
                  <li className='p-1 white-dark-theme-color' onClick={() => setShowFilterCard(!showFilterCard)}>
                    Internal
                  </li>
                </ul>
                <div
                  className='d-flex align-items-center my-5 ms-3'
                  style={{justifyContent: 'space-between'}}
                >
                  <p
                    className='btn btn-sm fw-bold btn-primary btn-green m-0 align-items-center d-flex gap-2'
                    onClick={() => {
                      // setNewMessageModal(true)
                      setDefaultRoomData(false)
                      setShowChat('newMessage')
                      setMessages([])
                    }}
                  >
                    <img src={plus} /> New Message
                  </p>
                  <div className='d-flex gap-3 me-3'>
                    <p className='m-0 cus-line white-dark-theme-color' onClick={viewall}>
                      View All
                    </p>
                  </div>
                </div>
                <ul
                  className='notification min_height p-0 m-0' 
                  ref={myDivRef}
                  onScroll={(e: any) => userScroll(e)}
                >
                  <>
                    {chatData.map((chat: any, index: any) => (
                      <li
                        className='d-flex align-items-center gap-5 p-5 lis'
                        style={{borderBottom: '1px solid #aaaaaa50'}}
                        onClick={(e: any) => openMessage(e, index, chat)}
                        key={`chat${index}`}
                      >
                        {chat?.type == 1 ? (
                          <div className='symbol-group symbol-hover flex-nowrap'>
                            {chat?.userList?.slice(0, 2).map((el: any, i: any) => (
                              <div className='symbol-group symbol-hover m-0'>
                                <div className='symbol symbol-45px symbol-circle '>
                                  <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} />
                                </div>
                              </div>
                            ))}

                            {chat?.userList?.length > 2 ? (
                              <a
                                href='#'
                                className='symbol symbol-45px symbol-circle'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_view_users'
                              >
                                <span
                                  className='symbol-label fs-8 fw-bold green_color'
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  data-bs-original-title='View more users'
                                  data-kt-initialized='1'
                                  onClick={() => {
                                    setShowChat('messageUserList')
                                  }}
                                >
                                  +{chat?.userList?.length - 2}
                                </span>
                              </a>
                            ) : null}
                          </div>
                        ) : (
                          <div className='symbol symbol-45px symbol-circle '>
                            <img alt='Pic' src='media/avatars/300-23.jpg' />
                          </div>
                        )}
                        <div className='grid-auto-new'>
                          <div className='d-flex flex-column'>
                            {chat?.type !== 1 ? (
                              <h6 className='m-0 white-dark-theme-color'>
                                {chat?.userList[0]?.firstName} {chat?.userList[0]?.lastName}
                              </h6>
                            ) : null}
                            <h6 className='m-0 light-dark-theme-color'>{chat.subjectTitle}</h6>
                          </div>
                          <div className='d-flex flex-column'>
                            <p className='m-0 small_grey light-dark-theme-color fs-5'>
                              {moment(chat.createdAt).format('HH:MM')}
                            </p>
                            <p className='m-0 small_grey'></p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </>
                </ul>
              </>
            ) : showChat == 'messageUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {chatData?.userList?.length > 0
                      ? chatData?.userList?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} />
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'newMessage' ? (
              <>
                <div
                  className='symbol-group symbol-hover p-5 m-0 '
                  style={{background: 'transparent', borderRadius: '10px 10px 0 0'}}
                >
                  {totalArr?.length > 0 ? (
                    <>
                      <div className='symbol-group symbol-hover me-5 ms-0'>
                        <img
                          src={leftarrow}
                          width='14px'
                          onClick={() => {
                            setShowChat('message')
                            getChatList()
                            settotalArr([])
                          }}
                          style={{cursor: 'pointer'}}
                        />
                      </div>

                      {totalArr?.slice(0, 4).map((el: any, i: any) => (
                        <>
                        <div className='symbol-group symbol-hover m-0 ms-3'>
                          <div className='symbol symbol-40px symbol-circle '>
                            <OverlayTrigger
                              overlay={
                                <Tooltip>
                                  {el?.tenant?.[0]?.firstName} {el?.tenant?.[0]?.lastName}
                                </Tooltip>
                              }
                              placement='bottom'
                            >
                              <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} />
                            </OverlayTrigger>
                          </div>
                        </div>
                        <div className="ps-3 white-dark-theme-color"> {el?.tenant?.[0]?.firstName} {el?.tenant?.[0]?.lastName}</div>
                        </>
                      ))}

                      {totalArr?.length > 4 ? (
                        <a
                          href='#'
                          className='symbol symbol-40px symbol-circle'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_view_users'
                        >
                          <span
                            className='symbol-label fs-8 fw-bold green_color'
                            data-bs-toggle='tooltip'
                            data-bs-trigger='hover'
                            data-bs-original-title='View more users'
                            data-kt-initialized='1'
                            onClick={() => {
                              setShowChat('newMessageUserList')
                            }}
                          >
                            +{totalArr?.length - 4}
                          </span>
                        </a>
                      ) : null}
                    </>
                  ) : (
                    <div className='d-flex align-items-center ' style={{gap: '3.9rem'}}>
                      <img
                        src={leftarrow}
                        width='14px'
                        onClick={() => setShowChat('message')}
                        style={{cursor: 'pointer'}}
                      />
                      <h6 className='m-0 p-0'>
                        <a
                          className='btn btn-sm fw-bold btn-primary btn-green m-0 align-items-center d-flex gap-2'
                          onClick={() => {
                            setRadioChecked(-1)
                            onChangeTab('Tenants')
                            setchatTab('Tenants')

                            setTimeout(()=>{
                              setShowChat('addNew')

                            }, 400)
                           
                            
                          }}
                        >
                          Select Recipient
                        </a>
                      </h6>
                    </div>
                  )}
                </div>
                <h6
                  className='d-flex gap-5 mx-5 align-items-center pb-5'
                  style={{borderBottom: '1px solid #aaa'}}
                >
                  <label htmlFor='subject' className='white-dark-theme-color'>Subject</label>
                  <input
                    type='text'
                    className='form-control form-control-solid mr-5'
                    name='subject'
                    id='subject'
                    onChange={(e: any) => changeSubject(e)}
                  />
                </h6>
                <div
                  style={{flex: 1, overflowY: 'auto', height: '350px', marginBottom: '50px'}}
                  ref={bottomRef}
                >
                  {messages
                    ?.sort(
                      (a: any, b: any) =>
                        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
                    )
                    ?.map((msg: any, index: any) => (
                      <div
                        className={
                          `${msg.senderId}` !== `${user._id}` ? 'flex-starts' : 'flex-ends'
                        }
                      >
                        <Card
                          style={{
                            maxWidth: '70%',
                            margin: '10px',
                            alignSelf:
                              `${msg.senderId}` !== `${user._id}` ? 'flex-end' : 'flex-start',
                          }}
                        >
                          <Card.Body
                            className={
                              `${msg.senderId}` !== `${user._id}`
                                ? 'rec_msg br-5 p-0 m-0'
                                : 'send_msg br-5 p-0 m-0'
                            }
                          >
                            <p className='p-0 m-0 small_font white-dark-theme-color pt-2 fnt-600'>
                              {msg?.senderUser?.firstName + ' ' + msg?.senderUser?.lastName}
                            </p>
                            <div className='px-5 pb-5 pt-0 ps-3'>
                              <h4 className='m-0 p-0 pt-5 light-dark-theme-color'>{msg.message}</h4>
                            </div>
                          </Card.Body>
                          <span className='m-0 small_font white-dark-theme-color'>{dateTotime(msg?.createdAt)}</span>
                        </Card>
                      </div>
                    ))}
                </div>
                <div
                  style={{position: 'absolute', bottom: 25, width: '90%'}}
                  className='px-0 mx-auto ms-3'
                >
                  <Form onSubmit={handleSubmit}>
                    <InputGroup>
                      <Form.Control
                        type='text'
                        placeholder='Type a message'
                        value={message}
                        onChange={(e) => {
                          console.log(selectedDataListTenant)
                          setMessage(e.target.value)
                        }}
                      />
                      <Button
                        variant='primary'
                        type='submit'
                        className='btn-green'
                        disabled={!message || totalArr?.length == 0}
                      >
                        <img src={send} width='24px' />
                      </Button>
                    </InputGroup>
                  </Form>
                </div>
              </>
            ) : showChat == 'newMessageUserList' ? (
              <>
                <div className='scroll-y d-flex flex-column gap-5'>
                  <div className='align-items-center new_add'>
                    <img
                      src={leftarrow}
                      width='14px'
                      onClick={() => {
                        setShowChat('newMessage')
                      }}
                      style={{cursor: 'pointer'}}
                    />
                    <h1 className='m-0 text-center green_color'>Users List</h1>
                  </div>

                  <div style={{height: '470px', overflowY: 'scroll'}}>
                    {totalArr?.length > 0
                      ? totalArr?.map((el: any, i: any) => (
                          <div className='d-flex flex-stack p-5 border-bottom border-gray-300 border-bottom-dashed'>
                            <div className='new_add' style={{gap: '1rem', width: '100%'}}>
                              <div className='symbol symbol-40px symbol-circle'>
                                <img alt='Pic' src={`media/avatars/300-${i + 1}.jpg`} />
                              </div>

                              <div
                                className='d-flex align-items-center '
                                style={{justifyContent: 'space-between'}}
                              >
                                <h6 className='m-0'>
                                  {el?.firstName} {el.lastName}
                                </h6>
                                <span className='badge badge-light fs-8 fw-semibold ms-2'>
                                  {el.role}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </>
            ) : showChat == 'addNew' ? (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                  className='p-0'
                >
                  <div className='new_add'>
                    <span
                      className=''
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setSelectedDataListTenant([])
                        setShowChat('newMessage')
                      }}
                    >
                      <img src={leftarrow} />
                    </span>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 mx-auto fs-4 fw-semibold mb-n2 scr '>
                      {/* <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'All' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('All')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'All' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          All
                        </a>
                      </li> */}
                      {/* <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'Internal' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('Internal')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-4 text-center mx-2 ` +
                            (chatTab === 'Internal' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          Internal
                        </a>
                      </li> */}
                      <li
                        className={
                          `nav-item cursor-pointer text-center min-w-75px ` +
                          (chatTab === 'Tenants' ? 'active' : '')
                        }
                        onClick={() => {
                          onChangeTab('Tenants')
                        }}
                      >
                        <a
                          className={
                            `nav-link text-active-primary pb-1 text-center mx-2 ` +
                            (chatTab === 'Tenants' ? 'active' : '')
                          }
                          data-bs-toggle='tab'
                        >
                          Tenants
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='internal' style={{overflowY: 'scroll', width: '90%'}}>
                    <input
                      type='text'
                      placeholder='Search'
                      className='form form-control m-0 msg_search_chat '
                      value={name}
                      onChange={(e: any) => searchFromAll(e.target.value, chatTab, searchPage)}
                    />
                    {chatTab == 'All' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {allDataPopup?.length ? (
                                allDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('all')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : chatTab == 'Internal' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-30px'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-100px'>Role</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {internalDataPopup?.length ? (
                                internalDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id'
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroup(e, i)}
                                        />
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.firstName} {v.lastName}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v.role}
                                      </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('internal')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : chatTab == 'Tenants' ? (
                      <>
                        <div
                          className='my-5 table-responsive '
                          style={{overflowY: 'scroll', height: '250px'}}
                          ref={searchRef}
                          onScroll={(e: any) => userScroll(e)}
                        >
                          <table
                            className='table align-middle table-row-dashed fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className=' min-w-25px ps-0'>#</th>
                                <th className=' min-w-100px'>Name</th>
                                <th className=' min-w-75px'>Role</th>
                                <th className=' min-w-75px'>Unit No.</th>
                                <th className=' min-w-100px'>Lease Status</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold head-text'>
                              {tenantDataPopup?.length ? (
                                tenantDataPopup?.map((v: any, i: any) => (
                                  <tr>
                                    <>
                                      <td
                                        data-kt-ecommerce-order-filter='order_id '
                                        className='test ps-0'
                                        >
                                           {radioChecked === i ? (
                                <div className='me-5 green-radio-filled cursor-pointer'></div>
                              ) : (
                                <div
                                  className='me-5 green-radio-hollow cursor-pointer'
                                  onClick={(e:any) => {
                                    setRadioChecked(i)
                                    makeGroupOfTenants(e, v, true)
                                  }}
                                ></div>
                              )}

                                          {/* <div
                                        className='form-check form-check-custom form-check-solid form-check-sm'
                                        style={{border: 'none'}}
                                      >
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          onChange={(e: any) => makeGroupOfTenants(e, v)}
                                        />
                                        </div> */}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                         {(v?.tenant?.[0]?.firstName || v?.tenant?.[0]?.lastName) ? 
                                          <>{v?.tenant?.[0]?.firstName} {v?.tenant?.[0]?.lastName} </>
                                          : '-' }
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v?.tenant?.[0]?.role ? v?.tenant?.[0]?.role : '-'}
                                      </td>
                                      <td className='' data-order='2022-09-11'>
                                        {v?.unit?.length > 0 ? v?.unit?.[0]?.unitNo : '-'}
                                      </td>
                                      <td>
                                      <div
                              className={
                                v?.tenancyStatus == 0
                                  ? 'status booked-bar me-2 my-2'
                                  : v?.tenancyStatus == 1
                                  ? 'status active-bar me-2 my-2'
                                  : v?.tenancyStatus == 2
                                  ? 'status expiring-bar me-2 my-2'
                                  : v?.tenancyStatus == 3
                                  ? 'status expired-bar me-2 my-2'
                                  : 'status  no-st-bar me-2 my-2'
                              }
                            >
                              {v?.tenancyStatus == 0
                                ? 'Booked'
                                : v?.tenancyStatus == 1
                                ? 'Active'
                                : v?.tenancyStatus == 2
                                ? 'Expiring'
                                : v?.tenancyStatus == 3
                                ? 'Expired'
                                : '-'}
                            </div>
                            </td>
                                    </>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={150} />
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>

                        <div className='d-flex align-items-center justify-content-end mb-5'>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green m-0'
                            onClick={() => goToNewMessage('internal')}
                          >
                            Done
                          </a>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>

      {config.app?.header?.default?.menu?.display && (
        <div className='app-navbar-item d-lg-none ms-2 me-n3' title='Show header menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-35px h-35px'
            id='kt_app_header_menu_toggle'
          >
            <KTSVG path='/media/icons/duotune/text/txt001.svg' className={btnIconClass} />
          </div>
        </div>
      )}
      {/* <NewMessage
        show={newMessageModal}
        tenantdata={' '}
        getMessageList={''}
        handleClose={() => {
          setNewMessageModal(false)
        }}
      /> */}
    </div>
  )
}

export {Navbar}
