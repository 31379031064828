import React from 'react'
import {useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import ApexCharts from 'react-apexcharts'
import appartment from '../../../img/Apartment.svg'
import penthouse from '../../../img/PentHouse.svg'

interface UnitTypeProps {
    chartData: any;
  }

  const UnitTypeOccupancy: React.FC<UnitTypeProps> = ({chartData}) => {
    // console.log(chartData)
  const seriesData = {
    apartment: [100, 0, 0, 0],
    penthouse: [0, 82, 0, 0],
    otherDuplex: [0, 0, 40, 0],
    otherSemiDetached: [0, 0, 0, 20],
  }

  // const chartData: any = [
  //   {
  //     name: 'Apartment',
  //     data: [100, 0, 0, 0],
  //     units: 20,
  //     unitType: 0,
  //     color: '#188383',
  //   },
  //   {
  //     name: 'Penthouse',
  //     data: [0, 82, 0, 0],
  //     units: 25,
  //     unitType: 1,
  //     color: '#188383',
  //   },
  //   {
  //     name: 'Other - Duplex',
  //     data: [0, 0, 40, 0],
  //     units: 30,
  //     unitType: 5,
  //     color: '#188383',
  //   },
  //   {
  //     name: 'Other - Semi Detached',
  //     data: [0, 0, 0, 20],
  //     units: 35,
  //     unitType: 5,
  //     color: '#188383',
  //   },
  // ]

  const categories: string[] = chartData?.map((dataPoint: any) => dataPoint?.name)

  // Find the maximum percentage value in the data
  const maxPercentage = Math.max(
    ...chartData?.flatMap((dataPoint: any) => dataPoint?.data)
);

const yAxisMax = Math?.ceil(maxPercentage / 10) * 10

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      height: 300,
      type: 'bar',
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
        },
      },
      selection: {
        enabled: true,
      },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '45px',
        // borderRadius: 10,
      },
    },
    colors: ['#188383'],
    legend: {
      show: false,
    },
    xaxis: {
      categories: categories,
      labels: {
        rotate: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      min: 0,
      max: yAxisMax == 0 ? 25 : yAxisMax,
      tickAmount: yAxisMax == 0 ? 5 : yAxisMax >= 50 ? yAxisMax/10 : yAxisMax / 5,
      axisTicks: {
        show: false,
      },
      labels: {
        formatter: function (value: number) {
          return `${Number?.isInteger(value) ? value : value?.toFixed(0)}%`;
        },
        style: {
          colors: '#000000',
        },
      },
    },
    tooltip: {
      y: {
        formatter: (value: number, { seriesIndex, dataPointIndex, w }: any) => {
          const dataPoint: any = chartData?.[dataPointIndex];
          return `${value ? value?.toFixed(1) : 0}% (Units: ${dataPoint?.units})`;
        },
      },
    },
    title: {
      text: 'Occupancy by Unit Type (Residential)',
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000000',
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
  };
   
  return (
    <div id='chart'>
      <ApexCharts options={chartOptions} series={chartData ? chartData : []} type='bar' height={300}  />
    </div>
  )
}

export default UnitTypeOccupancy
