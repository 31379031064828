import React, {useEffect, useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../../../../../../img/back-arrow.png'
import addWhite from '../../../../../../../../../img/add-white.png'
import redCross from '../../../../../../../../../img/remove.png'
import trashImg from '../../../../../../../../../img/trash.png'
import InputNotRequired from '../../../../../../../General/InputNotRequired'
import {Controller, useForm, FormProvider} from 'react-hook-form'
import Switch from 'react-switch'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {IoMdInformationCircleOutline} from 'react-icons/io'
import SingleCharge from '../components/SingleCharge'
import RecurringCharge from '../components/RecurringCharge'
import SinglePreview from '../components/SinglePreview'
import RecurringPreview from '../components/RecurringPreview'
import PropertySettings from '../components/PropertySettings'
import {
  ApiDelete,
  ApiGet,
  ApiPost,
  ApiPut,
} from '../../../../../../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../../../../../../apiCommon/helpers/Toast'
import ConfirmationModal from '../components/ConfirmationModal'
import {
  customFrequencyOptions,
  dueDateOptions,
  installmentOptions,
  invoicingOptions,
  startApplyingOptions,
  frequencyOptions,
  applicableEndOptions,
  dueDateRentOptions,
  chargeOptions,
} from '../components/Options'
import {CircularProgress} from '@mui/material'
import switchOffIcon from '../../../../../../../../../img/switch-off.png'
import switchOnIcon from '../../../../../../../../../img/switch-on.png'
import ViewConfiguration from './ViewConfiguration'
import AssignedProperties from '../components/AssignedProperties'
import OverlappingModal from '../components/OverlappingModal'
import UnitsList from '../components/UnitsList'

const AddConfiguration = () => {
  const navigate = useNavigate()
  const {type, id, flg, configId, mode} = useParams()
  const methods = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  // const {
  //   register,
  //   control,
  //   handleSubmit,
  //   reset,
  //   setValue,
  //   formState: {errors},
  //   trigger,
  // } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = methods

  const [chargeData, setChargeData] = useState<any>({
    name: '',
    type: null,
    amountCalculationType: null,
    activityStatus: 1,
    appliesToAllUnits: true,
    feePercentage: null,
    VATPercentage: null,
    minimumAmount: null,
    invoicingType: 0,
    leaseFeeListId: id,
    // selectChargeLeaseFeeListId: [],
    selectLeaseChargeType: [],
    frequency: [],
    dueDateType: [],
    autoInvoicingEnabled: [{label: 'Enabled', value: true}],
    startApplying: [],
    startApplyPercentagePaymentReceived: null,
    startApplySelectChargeLeaseFeeListId: [],
    startApplySelectChargeLeaseFeeListType: [],
    endApplying: [],
    endApplyPercentagePaymentReceived: null,
    endApplySelectChargeLeaseFeeListId: [],
    endApplySelectChargeLeaseFeeListType: [],
    daysAfterChargeIsApplied: null,
    splitIntoInstallments: [],
    customTimesApplied: null,
    customFrequencyType: [],
    developmentConfiguration: [],
    fixedAmount: null,
    previewData: {
      // s
      chargeSeries: [],
      totalRentValue: null,
      startApplingReceivedDate: null,

      //c
      totalValue: null,
      startDate: null,
      endDate: null,
      feeAmount: null,
      VATRate: null,
      dailyRate: null,
      noOfInstallments: null,
      singleRentInstallment: null,

      //w
      totalAmountWithVAT: null,
      totalApplied: null,
      subTotal: null,
      totalVAT: null,
      totalAmount: null,
      startDateReceived: null,
      endDateReceived: null,
      dateApplied: null,
      dateStopped: null,
      developmentConfiguration: [],
      VATAmountForFixes: null,
    },
  })

  const [isEdit, setIsEdit] = useState<any>(false)
  const [action, setAction] = useState<any>('')

  useEffect(() => {
    if (flg == '1') {
      setChargeData((prevCharge: any) => {
        return {
          ...prevCharge,
          feePercentage: null,
          VATPercentage: null,
          minimumAmount: null,
          invoicingType: 0,
          leaseFeeListId: id,
          // selectChargeLeaseFeeListId: [],
          selectLeaseChargeType: [],
          frequency: [],
          dueDateType: [],
          autoInvoicingEnabled: [{label: 'Enabled', value: true}],
          startApplying: [],
          startApplyPercentagePaymentReceived: null,
          startApplySelectChargeLeaseFeeListType: [],
          endApplying: [],
          endApplyPercentagePaymentReceived: null,
          endApplySelectChargeLeaseFeeListId: [],
          endApplySelectChargeLeaseFeeListType: [],
          daysAfterChargeIsApplied: null,
          splitIntoInstallments: [],
          customTimesApplied: null,
          customFrequencyType: [],
          developmentConfiguration: [],
          previewData: {
            // s
            chargeSeries: [],
            totalRentValue: null,
            startApplingReceivedDate: null,

            //c
            totalValue: null,
            startDate: null,
            endDate: null,
            feeAmount: null,
            VATRate: null,
            dailyRate: null,
            noOfInstallments: null,
            singleRentInstallment: null,

            //w
            totalAmountWithVAT: null,
            totalApplied: null,
            subTotal: null,
            totalVAT: null,
            totalAmount: null,
            startDateReceived: null,
            endDateReceived: null,
            dateApplied: null,
            dateStopped: null,
            developmentConfiguration: [],
          },

          // fixed amount
          fixedAmount: null,
        }
      })
    }
  }, [chargeData?.amountCalculationType])

  function booleanToInt(value: any) {
    return value ? 1 : 0
  }

  const [isChargeDataChanged, setIsChargeDataChanged] = useState<any>(false)
  const [disableAddCTA, setDisableAddCTA] = useState<any>(true)
  const [askForConfirmation, setAskForConfirmation] = useState<any>(false)
  const [isSkeleton, setIsSkeleton] = useState<any>(false)

  const [showStatusModal, setShowStatusModal] = useState<any>(false)
  const [selectedConfig, setSelectedConfig] = useState<any>()

  const [showOverlappingModal, setShowOverlappingModal] = useState<any>(false)
  const [overlappingUnits, setOverlappingUnits] = useState<any>([])
  const [overlappingUnitsCount, setOverlappingUnitsCount] = useState<any>(0)
  const [showOverlappingUnitListModal, setShowOverlappingUnitListModal] = useState<any>(false)

  const addConfiguration = () => {
    setDisableAddCTA(true)
    let startApplyCharge: any = []
    let endApplyCharge: any = []

    // chargeData?.startApplySelectChargeLeaseFeeListId?.map((chg:any)=>{
    //   startApplyCharge?.push(chg?.value)
    // })

    chargeData?.startApplySelectChargeLeaseFeeListType?.map((chg: any) => {
      startApplyCharge?.push(chg?.value)
    })

    // chargeData?.endApplySelectChargeLeaseFeeListId?.map((chg:any)=>{
    //   endApplyCharge?.push(chg?.value)
    // })

    chargeData?.endApplySelectChargeLeaseFeeListType?.map((chg: any) => {
      endApplyCharge?.push(chg?.value)
    })

    let devConfigs: any = []

    console.log(chargeData?.developmentConfiguration)

    let unitIdsForValidation: any = []

    if (!chargeData?.appliesToAllUnits) {
      chargeData?.developmentConfiguration?.map((dev: any) => {
        devConfigs?.push({
          buildingId: dev?.isBuilding
            ? dev?.id
            : dev?.isCommunity && dev?.buildingIds != null
            ? dev?.buildingIds?.[0]
            : null,
          communityId: dev?.isCommunity ? dev?.id : null,
          isBuilding: dev?.isBuilding,
          isCommunity: dev?.isCommunity,
          unitIds: dev?.selectedUnits,
          furnishingTypes: dev?.furnishingTypes.map((fr: any) => fr?.value),
          unitTypeStrings: dev?.unitTypes.map((unt: any) => unt?.value),
          bedrooms: dev?.bedrooms.map((bd: any) => bd?.value),
          clusterId: dev?.clusterIds != null ? dev?.clusterIds?.[0] : undefined,
          unitGroupId: dev?.unitGroupIds != null ? dev?.unitGroupIds?.[0] : undefined,
        })

        dev?.selectedUnits?.map((slUnit: any) => {
          unitIdsForValidation?.push(slUnit)
        })
      })
    }

    let body: any = {
      id: flg == '2' ? configId : undefined,
      name: chargeData?.name,
      type: chargeData?.type,
      amountCalculationType: chargeData?.amountCalculationType,
      feePercentage:
        chargeData?.amountCalculationType == 1 ? Number(chargeData?.feePercentage) : null,
      VATPercentage: Number(chargeData?.VATPercentage),
      minimumAmount:
        chargeData?.amountCalculationType == 1 ? Number(chargeData?.minimumAmount) : null,
      dueDateType: chargeData?.dueDateType?.length > 0 ? chargeData?.dueDateType?.[0]?.value : null,
      daysAfterChargeIsApplied:
        chargeData?.dueDateType?.[0]?.value == 1
          ? Number(chargeData?.daysAfterChargeIsApplied)
          : null,
      autoInvoicingEnabled: chargeData?.autoInvoicingEnabled?.[0]?.value,
      invoicingType: chargeData?.invoicingType,
      appliesToAllUnits: chargeData?.appliesToAllUnits,
      activityStatus: chargeData?.activityStatus,
      leaseFeeListId: id, // ObjectId of related lease_fee_list
      // selectChargeLeaseFeeListId:
      //   chargeData?.selectChargeLeaseFeeListId?.length > 0
      //     ? chargeData?.selectChargeLeaseFeeListId?.[0]?.value
      //     : null,
      selectLeaseChargeType:
        chargeData?.selectLeaseChargeType?.length > 0
          ? chargeData?.selectLeaseChargeType?.[0]?.value
          : null,
      startApplying:
        chargeData?.startApplying?.length > 0 ? chargeData?.startApplying?.[0]?.value : null,
      // startApplySelectChargeLeaseFeeListId: startApplyCharge,
      startApplySelectChargeLeaseFeeListType:
        chargeData?.startApplying?.[0]?.value == 1 &&
        chargeData?.startApplySelectChargeLeaseFeeListType?.length > 0
          ? chargeData?.startApplySelectChargeLeaseFeeListType?.[0]?.value
          : null,
      startApplyPercentagePaymentReceived:
        chargeData?.startApplying?.[0]?.value == 1
          ? Number(chargeData?.startApplyPercentagePaymentReceived)
          : null,

      endApplying: chargeData?.endApplying?.length > 0 ? chargeData?.endApplying?.[0]?.value : null,
      // endApplySelectChargeLeaseFeeListId: endApplyCharge,
      endApplySelectChargeLeaseFeeListType:
        chargeData?.endApplying?.[0]?.value == 1 &&
        chargeData?.endApplySelectChargeLeaseFeeListType?.length > 0
          ? chargeData?.endApplySelectChargeLeaseFeeListType?.[0]?.value
          : null,
      endApplyPercentagePaymentReceived:
        chargeData?.endApplying?.[0]?.value == 1
          ? Number(chargeData?.endApplyPercentagePaymentReceived)
          : null,

      frontendStorage: chargeData?.previewData,
      developmentConfigurations: devConfigs,
      fixedAmount: chargeData?.amountCalculationType == 0 ? Number(chargeData?.fixedAmount) : null,
    }

    if (chargeData?.type == 0) {
      body = {
        ...body,
        splitIntoInstallments:
          chargeData?.splitIntoInstallments?.length > 0
            ? chargeData?.splitIntoInstallments?.[0]?.value
            : null,
        customTimesApplied: chargeData?.customTimesApplied
          ? Number(chargeData?.customTimesApplied)
          : null,
        customFrequencyType: chargeData?.customFrequencyType
          ? chargeData?.customFrequencyType?.[0]?.value
          : null,
        frequency: null,
      }
    } else {
      body = {
        // fixedAmount : 50.0,
        ...body,
        splitIntoInstallments: null,
        frequency: chargeData?.frequency?.length > 0 ? chargeData?.frequency?.[0]?.value : null,
        customFrequencyType:
          chargeData?.frequency?.[0]?.value == 4 && chargeData?.customFrequencyType?.length > 0
            ? chargeData?.customFrequencyType?.[0]?.value
            : null,
        customTimesApplied:
          chargeData?.frequency?.[0]?.value == 4 && chargeData?.customTimesApplied
            ? Number(chargeData?.customTimesApplied)
            : null,
      }
    }

    // console.log(body)

    const bodyForStatus = {
      leaseFeeListId: configId ? undefined : id,
      id: configId,
      activityStatus: chargeData?.activityStatus ? 1 : 0,
      unitIds: unitIdsForValidation,
    }
    ApiPost(`corporate/lease_fee_configuration/activity_update`, bodyForStatus)
      .then(() => {
        if (configId) {
          ApiPut(`corporate/lease_fee_configuration`, body)
            .then(() => {
              SuccessToast(
                'Charge configuration was updated successfully! Now you can apply this charge to all new leases.'
              )
              if (flg == '1' || mode == 'edit')
                navigate(`/settings/residential-leasing/annual-leases/${type}/${id}`)
              else getConfigurationById()
            })
            .catch((err) => {
              ErrorToast(err.message)
              if (err?.message === 'Your account has been suspended!') {
                window.location.pathname = '/auth'
                localStorage.clear()
              }
              setDisableAddCTA(false)
            })
        } else {
          ApiPost(`corporate/lease_fee_configuration`, body)
            .then(() => {
              SuccessToast('Charge has been added successfully.')
              navigate(`/settings/residential-leasing/annual-leases/${type}/${id}`)
            })
            .catch((err) => {
              // ErrorToast(err.message)
              if (err?.message === 'Your account has been suspended!') {
                window.location.pathname = '/auth'
                localStorage.clear()
              }

              if (err?.status == 400) {
                setAskForConfirmation(false)
                setShowStatusModal(false)
                setShowOverlappingModal(true)
                setOverlappingUnits(err?.data)
                let count: any = 0
                err?.data?.map((dt: any) => {
                  count = count + dt?.units?.length
                })
                setOverlappingUnitsCount(count)
                console.log(err?.data)
      
                setDisableAddCTA(false)
              } else {
                ErrorToast(err.message)
                setDisableAddCTA(false)
              }
              
              setDisableAddCTA(false)
            })
        }
      })
      .catch((err) => {
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }

        if (err?.status == 400) {
          setAskForConfirmation(false)
          setShowStatusModal(false)
          setShowOverlappingModal(true)
          setOverlappingUnits(err?.data)
          let count: any = 0
          err?.data?.map((dt: any) => {
            count = count + dt?.units?.length
          })
          setOverlappingUnitsCount(count)
          console.log(err?.data)

          setDisableAddCTA(false)
        } else {
          ErrorToast(err.message)
          setDisableAddCTA(false)
        }
      })
  }

  //
  const calculateVATRate = () => {
    const updatedChargeData = {...chargeData}
    if (chargeData?.fixedAmount && chargeData?.VATPercentage) {
      const vatRate = (Number(chargeData?.fixedAmount) * Number(chargeData?.VATPercentage)) / 100
      // Update chrgData
      updatedChargeData.previewData.VATRate = vatRate
    } else {
      updatedChargeData.previewData.VATRate = null
    }
    setChargeData(updatedChargeData)
  }

  useEffect(() => {
    if (chargeData?.type == 0) calculateVATRate()
  }, [chargeData?.fixedAmount, chargeData?.VATPercentage])

  useEffect(() => {
    // Single Charge
    if (chargeData?.type == 0) {
      const showInputPreview1 =
        chargeData?.amountCalculationType === 1
          ? chargeData?.name?.trim()?.length > 0 &&
            chargeData?.type != null &&
            chargeData?.amountCalculationType != null &&
            chargeData?.feePercentage &&
            chargeData?.selectLeaseChargeType?.length > 0 &&
            // chargeData?.VATPercentage &&
            chargeData?.minimumAmount &&
            chargeData?.splitIntoInstallments?.length > 0 &&
            chargeData?.dueDateType?.length > 0 &&
            chargeData?.autoInvoicingEnabled?.length > 0 &&
            chargeData?.invoicingType != null
          : chargeData?.name?.trim()?.length > 0 &&
            chargeData?.type != null &&
            chargeData?.amountCalculationType != null &&
            chargeData?.fixedAmount &&
            // chargeData?.VATPercentage &&
            chargeData?.splitIntoInstallments?.length > 0 &&
            chargeData?.dueDateType?.length > 0 &&
            chargeData?.autoInvoicingEnabled?.length > 0 &&
            chargeData?.invoicingType != null

      // Custom Split ---- Daily/Weekly/Monthly
      const showInputPreview2 =
        (showInputPreview1 &&
          chargeData?.splitIntoInstallments?.[0]?.value == 1 &&
          chargeData?.customTimesApplied &&
          chargeData?.customFrequencyType?.length > 0) ||
        (showInputPreview1 && chargeData?.splitIntoInstallments?.[0]?.value == 0) ||
        (showInputPreview1 && chargeData?.splitIntoInstallments?.[0]?.value == 2)

      // based on due date
      const showInputPreview3 =
        (showInputPreview2 &&
          chargeData?.dueDateType?.[0]?.value == 1 &&
          chargeData?.daysAfterChargeIsApplied) ||
        (showInputPreview2 && chargeData?.dueDateType?.[0]?.value == 0) ||
        (showInputPreview2 && chargeData?.dueDateType?.[0]?.value == 2)

      // based on Start Appyling When % Received
      const showInputPreview4 =
        (showInputPreview3 &&
          chargeData?.startApplying?.[0]?.value == 1 &&
          chargeData?.startApplyPercentagePaymentReceived !== null &&
          chargeData?.startApplySelectChargeLeaseFeeListType?.length > 0) ||
        (showInputPreview3 && chargeData?.startApplying?.[0]?.value == 0) ||
        (showInputPreview3 && chargeData?.startApplying?.[0]?.value == 2) ||
        (showInputPreview3 && chargeData?.splitIntoInstallments?.[0]?.value == 0) ||
        (showInputPreview3 &&
          chargeData?.splitIntoInstallments?.[0]?.value == 2 &&
          chargeData?.startApplying?.[0]?.value == 1 &&
          chargeData?.startApplyPercentagePaymentReceived !== null &&
          chargeData?.startApplySelectChargeLeaseFeeListType?.length > 0) ||
        (showInputPreview3 &&
          chargeData?.splitIntoInstallments?.[0]?.value == 2 &&
          chargeData?.startApplying?.[0]?.value == 0) ||
        (showInputPreview3 &&
          chargeData?.splitIntoInstallments?.[0]?.value == 2 &&
          chargeData?.startApplying?.[0]?.value == 2)

      // console.log('showInputPreview1,',showInputPreview1)
      // console.log('showInputPreview2,',showInputPreview2)
      // console.log('showInputPreview3,',showInputPreview3)

      setDisableAddCTA(!showInputPreview4)
    }

    // Recurring Charge
    else if (chargeData?.type == 1) {
      if (chargeData?.amountCalculationType == 1) {
        const showInputPreview1 =
          chargeData?.name?.trim()?.length != 0 &&
          chargeData?.amountCalculationType == 1 &&
          chargeData?.feePercentage != null &&
          chargeData?.selectLeaseChargeType?.length > 0 &&
          // chargeData?.VATPercentage != null &&
          chargeData?.minimumAmount != null &&
          chargeData?.frequency?.length > 0 &&
          chargeData?.dueDateType?.length > 0 &&
          chargeData?.autoInvoicingEnabled?.length > 0 &&
          chargeData?.invoicingType != null

        // Frequency ---- Daily/Weekly/Monthly
        const showInputPreview2 =
          showInputPreview1 &&
          (((chargeData?.frequency?.[0]?.value == 1 ||
            chargeData?.frequency?.[0]?.value == 2 ||
            chargeData?.frequency?.[0]?.value == 3) &&
            chargeData?.startApplying?.length > 0 &&
            chargeData?.endApplying?.length > 0) ||
            chargeData?.frequency?.[0]?.value == 0 ||
            chargeData?.frequency?.[0]?.value == 4)

        // based on due date
        const showInputPreview3 =
          (showInputPreview2 &&
            chargeData?.dueDateType?.[0]?.value == 1 &&
            chargeData?.daysAfterChargeIsApplied != null) ||
          (showInputPreview2 &&
            (chargeData?.dueDateType?.[0]?.value == 0 || chargeData?.dueDateType?.[0]?.value == 2))

        // based on Start Appyling When % Received
        const showInputPreview4 =
          (showInputPreview3 &&
            chargeData?.startApplying?.[0]?.value == 1 &&
            chargeData?.startApplyPercentagePaymentReceived != null &&
            chargeData?.startApplySelectChargeLeaseFeeListType?.length > 0) ||
          (showInputPreview3 && chargeData?.startApplying?.[0]?.value == 0) ||
          (showInputPreview3 && chargeData?.startApplying?.[0]?.value == 2) ||
          (showInputPreview3 && chargeData?.frequency?.[0]?.value == 0)

        // based on Stop Appyling until % Received
        const showInputPreviewSections =
          (showInputPreview4 &&
            chargeData?.endApplying?.[0]?.value == 1 &&
            chargeData?.endApplyPercentagePaymentReceived != null &&
            chargeData?.endApplySelectChargeLeaseFeeListType?.length > 0) ||
          (showInputPreview4 && chargeData?.endApplying?.[0]?.value == 0) ||
          (showInputPreview4 && chargeData?.endApplying?.[0]?.value == 2) ||
          (showInputPreview4 && chargeData?.frequency?.[0]?.value == 0) ||
          (showInputPreview4 &&
            chargeData?.frequency?.[0]?.value == 4 &&
            chargeData?.customTimesApplied != null &&
            chargeData?.customFrequencyType?.length > 0)

        setDisableAddCTA(!showInputPreviewSections)
      } else if (chargeData?.amountCalculationType == 0) {
        const showInputPreviewFixed1 =
          chargeData?.type == 1 &&
          chargeData?.amountCalculationType == 0 &&
          chargeData?.fixedAmount != null &&
          // chargeData?.VATPercentage != null &&
          chargeData?.frequency?.length > 0 &&
          chargeData?.dueDateType?.length > 0 &&
          chargeData?.autoInvoicingEnabled?.length > 0 &&
          chargeData?.invoicingType != null

        // Frequency ---- Daily/Weekly/Monthly
        const showInputPreviewFixed2 =
          showInputPreviewFixed1 &&
          (((chargeData?.frequency?.[0]?.value == 1 ||
            chargeData?.frequency?.[0]?.value == 2 ||
            chargeData?.frequency?.[0]?.value == 3) &&
            chargeData?.startApplying?.length > 0 &&
            chargeData?.endApplying?.length > 0) ||
            chargeData?.frequency?.[0]?.value == 0 ||
            chargeData?.frequency?.[0]?.value == 4)

        // based on due date
        const showInputPreviewFixed3 =
          (showInputPreviewFixed2 &&
            chargeData?.dueDateType?.[0]?.value == 1 &&
            chargeData?.daysAfterChargeIsApplied != null) ||
          (showInputPreviewFixed2 &&
            (chargeData?.dueDateType?.[0]?.value == 0 || chargeData?.dueDateType?.[0]?.value == 2))

        // based on Start Appyling When % Received
        const showInputPreviewFixed4 =
          (showInputPreviewFixed3 &&
            chargeData?.startApplying?.[0]?.value == 1 &&
            chargeData?.startApplyPercentagePaymentReceived != null &&
            chargeData?.startApplySelectChargeLeaseFeeListType?.length > 0) ||
          (showInputPreviewFixed3 && chargeData?.startApplying?.[0]?.value == 0) ||
          (showInputPreviewFixed3 && chargeData?.startApplying?.[0]?.value == 2) ||
          (showInputPreviewFixed3 && chargeData?.frequency?.[0]?.value == 0)

        // based on Stop Appyling until % Received
        const showInputPreviewFixedSection =
          (showInputPreviewFixed4 &&
            chargeData?.endApplying?.[0]?.value == 1 &&
            chargeData?.endApplyPercentagePaymentReceived != null &&
            chargeData?.endApplySelectChargeLeaseFeeListType?.length > 0) ||
          (showInputPreviewFixed4 && chargeData?.endApplying?.[0]?.value == 0) ||
          (showInputPreviewFixed4 && chargeData?.endApplying?.[0]?.value == 2) ||
          (showInputPreviewFixed4 && chargeData?.frequency?.[0]?.value == 0) ||
          (showInputPreviewFixed4 &&
            chargeData?.frequency?.[0]?.value == 4 &&
            chargeData?.customTimesApplied != null &&
            chargeData?.customFrequencyType?.length > 0)

        setDisableAddCTA(!showInputPreviewFixedSection)
      }
    }
  }, [chargeData])

  const getConfigurationById = () => {
    ApiGet(`corporate/lease_fee_configuration/${configId}`)
      .then((res: any) => {
        // const selectChargeLeaseFeeListData = res?.data?.data?.selectChargeLeaseFeeListData?.map(
        //   (feeListItem: any) => ({
        //     value: feeListItem?._id,
        //     label: feeListItem?.name,
        //     type:
        //       feeListItem?.name?.toLowerCase() === 'total rent value'
        //         ? 0
        //         : feeListItem?.name?.toLowerCase() === 'single rent installment'
        //         ? 1
        //         : 2,
        //   })
        // )

        const dueDateData =
          res?.data?.data?.dueDateType == 2
            ? dueDateRentOptions?.filter(
                (option: any) => option.value == res?.data?.data?.dueDateType
              )
            : dueDateOptions?.filter((option: any) => option.value == res?.data?.data?.dueDateType)
        const installmentData = installmentOptions?.filter(
          (option: any) => option.value == res?.data?.data?.splitIntoInstallments
        )
        const customFrequencyData = customFrequencyOptions?.filter(
          (option: any) => option.value == res?.data?.data?.customFrequencyType
        )
        const startApplyingData = startApplyingOptions?.filter(
          (option: any) => option.value == res?.data?.data?.startApplying
        )
        const stopApplyingData = applicableEndOptions?.filter(
          (option: any) => option.value == res?.data?.data?.startApplying
        )
        const startApplySelectChargeLeaseFeeListData =
          res?.data?.data?.startApplySelectChargeLeaseFeeListData.map((feeListItem: any) => ({
            value: feeListItem?._id,
            label: feeListItem?.name,
          }))
        const frequencyData = frequencyOptions?.filter(
          (option: any) => option.value == res?.data?.data?.frequency
        )

        console.log('?**************** TST ******************?')
        console.log(res?.data?.data?.selectLeaseChargeType)
        setValue('templateName', res?.data?.data?.name)
        setValue('feePercentage', res?.data?.data?.feePercentage)
        setValue(
          'selectCharge',
          res?.data?.data?.selectLeaseChargeType != null
            ? res?.data?.data?.selectLeaseChargeType + 'tst'
            : ''
        )
        setValue('VATPercentage', res?.data?.data?.VATPercentage)
        setValue('minimumAmount', res?.data?.data?.minimumAmount)
        setValue('splitIntoInstallments', installmentData)
        setValue('dueDate', dueDateData)
        setValue('daysAfterChargeIsApplied', res?.data?.data?.daysAfterChargeIsApplied)
        setValue('installmentFrequency', res?.data?.data?.customTimesApplied)
        setValue('customFrequencyType', customFrequencyData)
        setValue('startApplying', startApplyingData)
        setValue(
          'startApplyPercentagePaymentReceived',
          res?.data?.data?.startApplyPercentagePaymentReceived
        )
        console.log(res?.data?.data?.startApplySelectChargeLeaseFeeType)
        setValue(
          'startApplyingSelectCharge',
          res?.data?.data?.startApplySelectChargeLeaseFeeListType != null
            ? res?.data?.data?.startApplySelectChargeLeaseFeeListType + 'tst'
            : ''
        )
        setValue('endApplying', stopApplyingData)
        setValue(
          'endApplyPercentagePaymentReceived',
          res?.data?.data?.endApplyPercentagePaymentReceived
        )
        console.log(res?.data?.data?.endApplySelectChargeLeaseFeeType)
        setValue(
          'endApplyingSelectCharge',
          res?.data?.data?.endApplySelectChargeLeaseFeeListType != null
            ? res?.data?.data?.endApplySelectChargeLeaseFeeListType + 'tst'
            : ''
        )
        setValue('invoicingType', res?.data?.data?.invoicingType)
        setValue('frequency', frequencyData)
        setValue('stopApplying', stopApplyingData)
        setValue('fixedAmount', res?.data?.data?.fixedAmount)

        let chrgDataTemp: any = {}
        chrgDataTemp = {
          name: res?.data?.data?.name,
          type: res?.data?.data?.type,
          amountCalculationType: res?.data?.data?.amountCalculationType,
          activityStatus: res?.data?.data?.activityStatus,
          appliesToAllUnits: res?.data?.data?.appliesToAllUnits,
          feePercentage: res?.data?.data?.feePercentage,
          VATPercentage: res?.data?.data?.VATPercentage,
          minimumAmount: res?.data?.data?.minimumAmount,
          invoicingType: res?.data?.data?.invoicingType,
          leaseFeeListId: res?.data?.data?.leaseFeeListId,
          // selectChargeLeaseFeeListId: res?.data?.data?.selectChargeLeaseFeeListData?.map(
          //   (feeListItem: any) => ({
          //     value: feeListItem?._id,
          //     label: feeListItem?.name,
          //     type:
          //       feeListItem?.name?.toLowerCase() === 'total rent value'
          //         ? 0
          //         : feeListItem?.name?.toLowerCase() === 'single rent installment'
          //         ? 1
          //         : 2,
          //   })
          // ),
          selectLeaseChargeType:
            res?.data?.data?.selectLeaseChargeType == 0
              ? [{label: 'Total Rent Value', value: 0}]
              : res?.data?.data?.selectLeaseChargeType == 1
              ? [{label: 'Single Rent Installment', value: 1}]
              : [],
          frequency: frequencyOptions?.filter(
            (option: any) => option.value == res?.data?.data?.frequency
          ),
          dueDateType:
            res?.data?.data?.dueDateType == 2
              ? dueDateRentOptions?.filter(
                  (option: any) => option.value == res?.data?.data?.dueDateType
                )
              : dueDateOptions?.filter(
                  (option: any) => option.value == res?.data?.data?.dueDateType
                ),
          autoInvoicingEnabled:
            res?.data?.data?.autoInvoicingEnabled === true
              ? [{label: 'Enabled', value: true}]
              : [{label: 'Disabled', value: false}],
          startApplying: startApplyingOptions?.filter(
            (option: any) => option.value == res?.data?.data?.startApplying
          ),
          startApplyPercentagePaymentReceived: res?.data?.data?.startApplyPercentagePaymentReceived,
          startApplySelectChargeLeaseFeeListId:
            res?.data?.data?.startApplySelectChargeLeaseFeeListData.map((feeListItem: any) => ({
              value: feeListItem?._id,
              label: feeListItem?.name,
            })),
          startApplySelectChargeLeaseFeeListType:
            res?.data?.data?.startApplySelectChargeLeaseFeeListType == 0
              ? [{label: 'Total Rent Value', value: 0}]
              : res?.data?.data?.startApplySelectChargeLeaseFeeListType == 1
              ? [{label: 'Single Rent Installment', value: 1}]
              : [],
          endApplying: applicableEndOptions?.filter(
            (option: any) => option.value == res?.data?.data?.endApplying
          ),
          endApplyPercentagePaymentReceived: res?.data?.data?.endApplyPercentagePaymentReceived,
          endApplySelectChargeLeaseFeeListId:
            res?.data?.data?.endApplySelectChargeLeaseFeeListData.map((feeListItem: any) => ({
              value: feeListItem?._id,
              label: feeListItem?.name,
            })),
          endApplySelectChargeLeaseFeeListType:
            res?.data?.data?.endApplySelectChargeLeaseFeeListType == 0
              ? [{label: 'Total Rent Value', value: 0}]
              : res?.data?.data?.endApplySelectChargeLeaseFeeListType == 1
              ? [{label: 'Single Rent Installment', value: 1}]
              : [],
          daysAfterChargeIsApplied: res?.data?.data?.daysAfterChargeIsApplied,
          splitIntoInstallments: installmentOptions?.filter(
            (option: any) => option.value == res?.data?.data?.splitIntoInstallments
          ),
          customTimesApplied: res?.data?.data?.customTimesApplied,
          customFrequencyType: customFrequencyOptions?.filter(
            (option: any) => option.value == res?.data?.data?.customFrequencyType
          ),
          developmentConfiguration: res?.data?.data?.frontendStorage?.developmentConfiguration,
          previewData: res?.data?.data?.frontendStorage,
          fixedAmount: res?.data?.data?.fixedAmount ? res?.data?.data?.fixedAmount : null,
        }

        chrgDataTemp.previewData.totalValue = null
        chrgDataTemp.previewData.totalRentValue = null
        chrgDataTemp.previewData.noOfInstallments = null
        chrgDataTemp.previewData.singleRentInstallment = null

        console.log(chrgDataTemp)

        // setChargeData((previousState:any) => ({
        //   ...previousState,
        //   ...chrgDataTemp,
        //   // previewData:{
        //   //   ...previousState?.previewData
        //   // }

        // }))
        setChargeData(chrgDataTemp)
        setDisableAddCTA(false)
        setAskForConfirmation(false)
        setIsSkeleton(false)
        setIsEdit(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsSkeleton(false)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    if (flg == '2') {
      setIsSkeleton(true)
      getConfigurationById()
    }
  }, [])

  const deleteConfiguration = () => {
    setDisableAddCTA(true)
    ApiDelete(`corporate/lease_fee_configuration/${configId}`)
      .then(() => {
        SuccessToast('The charge configuration has been permanently deleted.')
        navigate(`/settings/residential-leasing/annual-leases/${type}/${id}`)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
        setDisableAddCTA(false)
      })
  }

  return (
    <>
      {!isSkeleton ? (
        <div className='pt-0 mt-0 px-2 ps-1'>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit((data: any, e: any) => {
                addConfiguration()
              })}
            >
              <div className='d-flex align-items-end gap-2 mb-3 gap-lg-3'>
                <div className='d-flex align-items-start gap-3'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() =>
                      navigate(`/settings/residential-leasing/annual-leases/${type}/${id}`)
                    }
                  >
                    <img src={backArrow} height={14} width={14} alt='Back' />
                  </span>
                  <div>
                    <h2 className='page-heading m-0 head-text mb-1'>
                      {mode == 'edit' || isEdit || flg == '1'
                        ? 'Charge Configuration Settings'
                        : 'View Configuration'}
                    </h2>
                    {flg == '1' && (
                      <p className='labl-gry fs-4 mb-0 light-dark-theme-color'>
                        Configure your charge settings
                      </p>
                    )}
                  </div>
                </div>

                {(mode == 'edit' || isEdit || flg == '1') && (
                  <>
                    <button
                      type='button'
                      className='btn btn-sm fw-bold me-3 red-hollow-btn ms-auto me-3'
                      onClick={() => {
                        if (flg == '1' || mode == 'edit')
                          navigate(`/settings/residential-leasing/annual-leases/${type}/${id}`)
                        else getConfigurationById()
                      }}
                    >
                      <img src={redCross} height={16} width={16} style={{marginRight: '10px'}} />{' '}
                      Cancel
                    </button>

                    <button
                      type='button'
                      className='btn btn-sm fw-bold green-submit-btn px-7 justify-content-center btn-fit-content'
                      disabled={disableAddCTA}
                      onClick={async () => {
                        let isValid: any = true
                        isValid = await trigger()
                        if (isValid) {
                          //
                          if (
                            chargeData?.startApplying?.[0]?.value == 1 &&
                            chargeData?.endApplying?.[0]?.value == 1 &&
                            (chargeData?.endApplyPercentagePaymentReceived <=
                              chargeData?.startApplyPercentagePaymentReceived ||
                              chargeData?.startApplyPercentagePaymentReceived >=
                                chargeData?.endApplyPercentagePaymentReceived)
                          )
                            ErrorToast(chargeData?.previewData.percentError)
                          //
                          else if (
                            chargeData?.appliesToAllUnits == false &&
                            chargeData?.developmentConfiguration?.length == 0
                          )
                            ErrorToast(
                              "Since you have chosen to apply this configuration to 'Selected Properties', please select at least one property."
                            )
                          // Custom - Every Day
                          else if (
                            chargeData?.type?.[0]?.value == 1 &&
                            chargeData?.frequency?.[0]?.value == 4 &&
                            chargeData?.customFrequencyType?.[0]?.value == 0 &&
                            (Number(chargeData?.customTimesApplied) > 365 ||
                              Number(chargeData?.customTimesApplied) < 1)
                          )
                            ErrorToast(
                              'Times applied cannot less than 1 or greater than 365 since there are 365 days in a year.'
                            )
                          // Custom - Every Week
                          else if (
                            chargeData?.type?.[0]?.value == 1 &&
                            chargeData?.frequency?.[0]?.value == 4 &&
                            chargeData?.customFrequencyType?.[0]?.value == 1 &&
                            (Number(chargeData?.customTimesApplied) > 52 ||
                              Number(chargeData?.customTimesApplied) < 1)
                          )
                            ErrorToast(
                              'Times applied cannot be less than 1 or greater than 52 since there are 52 weeks in a year.'
                            )
                          // Custom - Every Week
                          else if (
                            chargeData?.type?.[0]?.value == 1 &&
                            chargeData?.frequency?.[0]?.value == 4 &&
                            chargeData?.customFrequencyType?.[0]?.value == 2 &&
                            (Number(chargeData?.customTimesApplied) > 12 ||
                              Number(chargeData?.customTimesApplied) < 1)
                          )
                            ErrorToast(
                              'Times applied cannot be less than 1 or greater than 12 since there are 12 months in a year.'
                            )
                          else setAskForConfirmation(true)
                        }
                      }}
                    >
                      {flg == '1' ? 'Add ' : 'Update '} Configuration
                    </button>
                  </>
                )}
              </div>

              {mode == 'edit' || flg == '1' || isEdit ? (
                <Row className='mb-4 d-flex'>
                  <Col md={9} style={{display: 'flex', flexDirection: 'column'}}>
                    <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3 pb-10'>
                      <div className='card-body pt-0 mt-5 py-0 px-3'>
                        <div className='d-flex'>
                          <div>
                            <h3 className='page-heading m-0 white-dark-theme-color fnt-700'>
                              Charge Settings
                            </h3>
                            <p className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>
                              Set your charge structure.
                            </p>
                          </div>

                          <div className='d-flex ms-auto align-items-center'>
                            <p className='mb-0 me-5'>
                              <span>
                                <OverlayTrigger
                                  placement='top'
                                  delay={{show: 250, hide: 400}}
                                  flip
                                  overlay={
                                    <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                      <p className='m-0 fnt-700 fs-5 mb-1 text-start'>
                                        Charge Activation
                                      </p>
                                      <p className='mb-2 text-start'>
                                        You are able to activate and de-activate the charge on your
                                        leases by switching between the ‘Active’ and ‘Inactive’
                                        statuses:
                                      </p>
                                      <div className='d-flex align-items-center'>
                                        <img src={switchOnIcon} height={25} width={25} />
                                        <p className='m-0 ms-3 fnt-700'>Active</p>
                                      </div>

                                      <p className='mb-2 text-start'>
                                        Will automatically add the charge into the list of lease
                                        charges in the lease you are creating.
                                      </p>
                                      <div className='d-flex align-items-center'>
                                        <img src={switchOffIcon} height={25} width={25} />
                                        <p className='m-0 ms-3 fnt-700'>Inactive</p>
                                      </div>
                                      <p className='mb-2 text-start'>
                                        Will not automatically add the charge into the list of lease
                                        charges in the lease being created, however, the charge will
                                        be available and can be added manually into the lease.
                                      </p>
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    {' '}
                                    <IoMdInformationCircleOutline
                                      className='ms-0 cursor-pointer'
                                      style={{height: '18px', width: '18px'}}
                                    />
                                  </span>
                                </OverlayTrigger>
                              </span>
                            </p>

                            <Switch
                              onChange={(val: any) => {
                                console.log(val)
                                setChargeData({
                                  ...chargeData,
                                  ['activityStatus']: booleanToInt(val),
                                })
                              }}
                              checked={chargeData?.activityStatus === 1}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              onColor='#00cf99'
                              offColor='#b0bec5'
                              className='ms-auto me-5'
                              height={23}
                              width={42}
                            />
                            <p className='fs-5 fnt-500 pt-2 head-text mb-1 light-dark-theme-color'>
                              <i>{chargeData?.activityStatus === 1 ? 'Active' : 'Inactive'}</i>
                            </p>
                          </div>
                        </div>

                        <div className='row me-4 ms-0 mt-5'>
                          <div className='col-10 ps-0'>
                            <p className='fs-4 fnt-700 required'> Name</p>
                            <div className='d-flex'>
                              <p className='fs-4 fnt-500 mb-0 mt-2'>
                                {' '}
                                {type == 'contract-fee'
                                  ? 'Contract Fee - '
                                  : type == 'admin-fee'
                                  ? 'Admin Fee - '
                                  : type == 'management-fee'
                                  ? 'Management Fee - '
                                  : type == 'maintenance-fee'
                                  ? 'Maintenance Fee - '
                                  : type == 'housekeeping-fee'
                                  ? 'Housekeeping Fee - '
                                  : `${type} - `}{' '}
                              </p>
                              <div className='ms-3' style={{width: '60%'}}>
                                <InputNotRequired
                                  required={true}
                                  register={register}
                                  errors={errors}
                                  value={chargeData?.name}
                                  setValue={setValue}
                                  trigger={trigger}
                                  onBlur={() => {}}
                                  onChange={(val: any) => {
                                    setChargeData({...chargeData, ['name']: val})
                                  }} // Pass the callback function to handle input changes
                                  placeholder='Enter a sub-name for this charge…'
                                  name='templateName'
                                  pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                                  maxLength={70} // Maximum length of 70 characters
                                  className=''
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <>
                          <div className='position-relative'>
                            {flg == '2' && (
                              <div
                                className='tenantOpacityBox'
                                style={{opacity: '0.7', cursor: 'not-allowed'}}
                              ></div>
                            )}
                            <div className='row mt-7'>
                              <div className='col-12'>
                                <h4 className='page-heading m-0 white-dark-theme-color fnt-700 required'>
                                  Type
                                </h4>
                                <p className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>
                                  Select a charge type:
                                </p>
                              </div>
                            </div>

                            <div className='row mt-5'>
                              <div className='col-6'>
                                <div className='d-flex align-items-start tst mb-4'>
                                  <div style={{width: '30px', height: '30px'}}>
                                    <input
                                      type='radio'
                                      className='me-3 cursor-pointer form-check-input'
                                      {...register('type', {
                                        required: 'Please select an option to proceed',
                                      })}
                                      id='singleCharge'
                                      value='singleCharge'
                                      checked={chargeData?.type == 0}
                                      onChange={(e: any) => {
                                        setChargeData({...chargeData, ['type']: 0})

                                        setIsChargeDataChanged(new Date())
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <h5 className='m-0'>
                                      <label htmlFor='singleCharge' className='fs-5 cursor-pointer'>
                                        Single Charge
                                      </label>
                                    </h5>
                                    <p
                                      className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'
                                      style={{color: '#a4a4a4'}}
                                    >
                                      The charge will be generated once and can be applied once or
                                      split into installments.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-6'>
                                <div className='d-flex align-items-start tst mb-4'>
                                  <div style={{width: '30px', height: '30px'}}>
                                    <input
                                      type='radio'
                                      className='me-3 cursor-pointer form-check-input'
                                      {...register('type', {
                                        required: 'Please select an option to proceed',
                                      })}
                                      id='recurringCharge'
                                      value='recurringCharge'
                                      checked={chargeData?.type == 1}
                                      onChange={(e: any) => {
                                        setChargeData({...chargeData, ['type']: 1})

                                        setIsChargeDataChanged(new Date())
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <h5 className='m-0'>
                                      <label
                                        htmlFor='recurringCharge'
                                        className='fs-5 cursor-pointer'
                                      >
                                        Recurring Charge
                                      </label>
                                    </h5>
                                    <p
                                      className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'
                                      style={{color: '#a4a4a4'}}
                                    >
                                      The charge will be generated with the same fee amount every
                                      time for the entire frequency set.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='row mt-7'>
                            <div className='col-12'>
                              <h4 className='page-heading m-0 white-dark-theme-color fnt-700 required'>
                                Amount Calculation
                              </h4>
                              <p className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>
                                Select the relevant calculation method that will be used to
                                calculate the charge amount:
                              </p>
                            </div>
                          </div>

                          <div className='row mt-5'>
                            <div className='col-6'>
                              <div className='d-flex align-items-start tst mb-4'>
                                <div style={{width: '30px', height: '30px'}}>
                                  <input
                                    type='radio'
                                    className='me-3 cursor-pointer form-check-input'
                                    {...register('amountCalculationType', {
                                      required: 'Please select an option to proceed',
                                    })}
                                    id='fixedAmount'
                                    value='fixedAmount'
                                    checked={chargeData?.amountCalculationType == 0}
                                    onChange={(e: any) => {
                                      setChargeData({
                                        ...chargeData,
                                        ['amountCalculationType']: 0,
                                      })

                                      setIsChargeDataChanged(new Date())
                                    }}
                                  />
                                </div>
                                <div>
                                  <h5 className='m-0'>
                                    <label htmlFor='fixedAmount' className='fs-5 cursor-pointer'>
                                      Fixed Fee
                                    </label>
                                  </h5>
                                  <p
                                    className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'
                                    style={{color: '#a4a4a4'}}
                                  >
                                    This charge will be generated and calculated based on a defined
                                    fixed fee.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className='col-6'>
                              <div className='d-flex align-items-start tst mb-4'>
                                <div style={{width: '30px', height: '30px'}}>
                                  <input
                                    type='radio'
                                    className='me-3 cursor-pointer form-check-input'
                                    {...register('amountCalculationType', {
                                      required: 'Please select an option to proceed',
                                    })}
                                    id='percentAmount'
                                    value='percentAmount'
                                    checked={chargeData?.amountCalculationType == 1}
                                    onChange={(e: any) => {
                                      setChargeData({
                                        ...chargeData,
                                        ['amountCalculationType']: 1,
                                      })

                                      setIsChargeDataChanged(new Date())
                                    }}
                                  />
                                </div>
                                <div>
                                  <h5 className='m-0'>
                                    <label htmlFor='percentAmount' className='fs-5 cursor-pointer'>
                                      % from the charges applied in a lease
                                    </label>
                                  </h5>
                                  <p
                                    className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'
                                    style={{color: '#a4a4a4'}}
                                  >
                                    The charge will be generated and calculated based on selected
                                    charges that are included in a lease. When applying this method,
                                    you must also select the charges it will be calculated from.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      </div>
                    </div>

                    {/* Fee Setting */}
                    {chargeData?.type == 0 ? (
                      <SingleCharge
                        chargeData={chargeData}
                        onUpdateChargeData={(updatedCharge: any) => {
                          // console.log(updateCharge)
                          setChargeData(updatedCharge)
                          setIsChargeDataChanged(new Date())
                        }}
                      />
                    ) : (
                      <RecurringCharge
                        chrgData={chargeData}
                        setChrgData={(updatedCharge: any) => {
                          // console.log(updateCharge)
                          setChargeData(updatedCharge)
                          setIsChargeDataChanged(new Date())
                        }}
                      />
                    )}
                  </Col>

                  {/* Charges Preview */}
                  <Col md={3} style={{display: 'flex', flexDirection: 'column'}}>
                    {chargeData?.type == 0 ? (
                      <SinglePreview
                        chrgData={chargeData}
                        setChrgData={(updatedCharge: any) => {
                          // console.log(updateCharge)
                          setChargeData(updatedCharge)
                        }}
                        isChrgDataChanged={isChargeDataChanged}
                      />
                    ) : (
                      <RecurringPreview
                        chrgData={chargeData}
                        setChrgData={(updatedCharge: any) => {
                          // console.log(updateCharge)
                          setChargeData(updatedCharge)
                        }}
                        isChrgDataChanged={isChargeDataChanged}
                      />
                    )}
                  </Col>
                </Row>
              ) : (
                <ViewConfiguration
                  chrgData={chargeData}
                  setIsEdit={() => {
                    setIsEdit(true)
                  }}
                  setChrgData={(updatedCharge) => {
                    setChargeData(updatedCharge)
                  }}
                  getById={getConfigurationById}
                />
              )}
            </form>
          </FormProvider>

          {/* Property Settings */}
          {mode == 'edit' || flg == '1' || isEdit ? (
            <PropertySettings
              chrgData={chargeData}
              setChrgData={(val: any) => {
                setChargeData({...chargeData, ['appliesToAllUnits']: val})
              }}
              setDevelopmentConfiguration={(val: any) => {
                let values = {...chargeData}
                values.developmentConfiguration = val
                values.previewData.developmentConfiguration = val
                setChargeData(values)
              }}
            />
          ) : (
            <AssignedProperties chrgData={chargeData} />
          )}

          {mode == 'view' && !isEdit && (
            <button
              type='button'
              className='btn btn-sm fw-bold status-w-600 mx-3 red-hollow-btn d-flex mx-auto justify-content-center bg-white mt-14 mb-20'
              onClick={() => {
                setAction('delete')
                setAskForConfirmation(true)
              }}
            >
              <img src={trashImg} height={20} width={20} className='me-3' /> Delete Configuration
            </button>
          )}

          {/* Confirmation Modal */}
          <ConfirmationModal
            show={askForConfirmation}
            onHide={() => {
              setAskForConfirmation(false)
            }}
            status={chargeData?.activityStatus}
            onAdd={() => {
              if (action == 'delete') deleteConfiguration()
              else addConfiguration()
            }}
            isDisabled={disableAddCTA}
            action={action}
          />
        </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}

      {/* Overlapping Modal */}
      <OverlappingModal
        show={showOverlappingModal}
        onHide={() => {
          setShowOverlappingModal(false)
        }}
        showDetails={() => {
          setShowOverlappingUnitListModal(true)
          setShowOverlappingModal(false)
        }}
        unitsCount={overlappingUnitsCount}
      />

      {/* Units List */}
      <UnitsList
        show={showOverlappingUnitListModal}
        onHide={() => {
          setShowOverlappingUnitListModal(false)
        }}
        unitsDetails={overlappingUnits}
        unitsCount={overlappingUnitsCount}
      />
    </>
  )
}

export default AddConfiguration
