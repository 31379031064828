import React from 'react'
import {useState, useRef, useEffect} from 'react'
import {Row, Col, Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import backArrow from '../../../img/back-arrow.png'
import addWhite from '../../../img/add-white.png'
import './style.scss'
import {Paper} from '@mui/material'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import Select from 'react-select'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'

interface ButtonProps {
  show: any
  handleClose: any
  updateUsers: any
  addedUsers: any
  from: any
  previousIds: any
}

const AddUsersModal = ({
  show,
  handleClose,
  updateUsers,
  addedUsers,
  from,
  previousIds,
}: ButtonProps) => {
  const navigate = useNavigate()
  const [searchUser, setSearchUser] = useState<any>('')
  const [sortOptions, setSortOptions] = useState<any>([
    {value: '1', label: 'Alphabetic (A-Z)'},
    {value: '-1', label: 'Alphabetic (Z-A)'},
  ])
  const [sortValue, setSortValue] = useState<any>()
  const [usersInRole, setUsersInRole] = useState<any>([])

  let usersInRoleSelected: any = 0

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      minHeight: '35px',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',

    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const getUsers = () => {
    const body = {
      search: searchUser,
      nameSort: parseInt(sortValue),
    }
    ApiPost('corporate/user/get/dropdown', body)
      .then((res: any) => {
        let values: any = []
        for (let i = 0; i < res?.data?.data?.length; i++) {
          values[i] = {
            email: res?.data?.data?.[i]?.email,
            firstName: res?.data?.data?.[i]?.firstName,
            lastName: res?.data?.data?.[i]?.lastName,
            _id: res?.data?.data?.[i]?._id,
            check: false,
          }
          for (let j = 0; j < addedUsers?.length; j++) {
            if (values[i]?._id == addedUsers[j]?._id) values[i].check = true

            if (from == 'view-role') {
              previousIds?.map((prev: any) => {
                if (prev === values[i]?._id) values[i].isDefault = true
              })
            }
          }
        }
        setUsersInRole(values)
      })
      .catch(() => {})
  }

  useEffect(() => {
    getUsers()
  }, [searchUser, sortValue])

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '550px',
            maxHeight: '550px',
            overflow: 'hidden',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  handleClose()
                }}
              >
                X
              </label>
            </div>
          </div>

          <div className='d-flex align-items-center'>
            <h2 className='head-text pt-5 mb-0'>
              <b>Add Users</b>
            </h2>
            <div className='d-flex ms-auto'>
              <button
                type='button'
                className='btn btn-sm fw-bold  me-0 select-btn ps-3 pe-10'
                onClick={() => {
                  let values = [...usersInRole]
                  console.log(values)
                  updateUsers(values)
                  
                }}
                style={{width: 'fit-content'}}
              >
                <img src={addWhite} height={17} width={17} style={{marginRight: '10px'}} /> Add
              </button>
            </div>
          </div>

          {usersInRole?.map((user: any, ind: any) => {
            if (user?.check == true) usersInRoleSelected = usersInRoleSelected + 1
            return <></>
          })}

          <div
            className='d-flex align-items-center mt-3 pb-5 '
            style={{borderBottom: '0.1rem solid #bec3cb'}}
          >
            <div className='ms-auto'>
              <p className='user-head mb-2 light-dark-theme-color' style={{fontWeight: '500', fontSize: '14px'}}>
                Users Selected <b>{usersInRoleSelected}</b>
              </p>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='mb-2 col-md-5 pe-5 xyz'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Search User...'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchUser}
                  onChange={(e: any) => setSearchUser(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
            <div className='mb-2 col-md-4 pe-5 xyz'>
              <Select
                placeholder={'Sort'}
                styles={customStyles}
                isSearchable={false}
                options={sortOptions}
                name='sort'
                onChange={(e: any) => {
                 
                  setSortValue(e.value)
                }}
              />
            </div>
          </div>

          <div className='table-responsive add-user-height'>
            <table className='table align-middle fs-6 gy-5 ' id='kt_ecommerce_sales_table'>
              <thead>
                <th className='text-start min-w-25px test'>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='vacant0'
                      name='none'
                      checked={usersInRoleSelected == usersInRole?.length && usersInRole?.length != 0 ? true : false}
                      onChange={(e: any) => {
                        let values: any = [...usersInRole]
                        if (e.target.checked) {
                          values?.map((user: any) => {
                            user.check = true
                          })
                        } else {
                          values?.map((user: any) => {
                            user.check = false
                          })
                        }
                        setUsersInRole(values)
                      }}
                    />
                  </div>
                </th>

                <th className='text-start min-w-150px'>Users</th>
                <th className='text-start min-w-75px'></th>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {usersInRole?.length > 0 &&
                  usersInRole?.map((user: any, ind: any) => {
                    return (
                      <>
                        <tr>
                          <td className='text-start ps-0 test'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                              {from == 'view-role' && user?.isDefault ? (
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={false}
                                  onChange={(e: any) => {}}
                                />
                              ) : (
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={usersInRole[ind]?.check}
                                  onChange={(e: any) => {
                                   
                                    const values = [...usersInRole]
                                    values[ind].check = e.target.checked
                                    setUsersInRole(values)
                                    
                                  }}
                                />
                              )}
                            </div>
                          </td>
                          <td className='text-start ps-0'>
                            <div className='d-flex head-text'>
                              <p className='user-head pe-2 mb-2'>
                                {user?.firstName} {user?.lastName}
                              </p>
                              <p className='role-text mb-2'>
                                <i> ({user?.email})</i>
                              </p>
                            </div>
                          </td>
                          <td className='text-start ps-0'>
                            {from == 'view-role' && user?.isDefault && (
                              <div className='ms-5 '>
                                {' '}
                                <p className='select-color mb-2'><b>Added</b></p>
                              </div>
                            )}
                          </td>
                        </tr>
                      </>
                    )
                  })}
                {/* <tr>
                  <td className='text-start ps-0 test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={true}
                        onChange={(e: any) => {}}
                      />
                    </div>
                  </td>
                  <td className='text-start ps-0'>
                    <div className='d-flex head-text'>
                      <p className='user-head pe-2 mb-2'> Waqar Khan </p>
                      <p className='role-text mb-2'>
                        <i>(Waqar.khan@email.com)</i>
                      </p>
                    </div>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddUsersModal
