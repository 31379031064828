import {setPriority} from 'os'
import React, {useEffect, useState} from 'react'
import {Button, ButtonGroup, Col, Dropdown, Form, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {MultiSelect} from 'react-multi-select-component'
import {useNavigate} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import PropertyFilter from './PropertyFilter'
import {AnyAaaaRecord} from 'dns'
import {profileDetailsInitValues} from '../accounts/components/settings/SettingsModel'
import {AiFillDelete, AiFillEdit} from 'react-icons/ai'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import back from '../../../img/left-arrow.png'
import plus from '../../../img/plus.svg'
import approve from '../../../img/approve.png'
import ServiceList from './ServiceListTenant'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

let selectedProperty: any = []
const SelectProperty = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
    reset,
  } = useForm<any>()

  const [open, setOpen] = React.useState(false)
  const [type, setType] = useState('')
  const [devlopment, setdevlopment] = useState<any>([])
  const [formData1, setFormData1] = useState<any>({})

  const [date, setdate] = useState()
  const activeTab: any = localStorage.getItem('activeTab')
  const requestServiceId: any = localStorage.getItem('serviceRequestId' + activeTab)

  const [cluster, setcluster] = useState([])
  const [flour, setflour] = useState<any>([])
  const [clusterflour, setclusterflour] = useState([])
  const [unit, setunit] = useState([])
  const [clusterunit, setclusterunit] = useState([])
  const [updateclusterunit, setupdateclusterunit] = useState([])

  const [clusterunitgroup, setclusterunitgroup] = useState([])
  const [clustertype, setclustertype] = useState<any>()
  const [clusterbuilding, setclusterbuilding] = useState([])
  const [ChnageUGB, setChnageUGB] = useState()

  const [selected, setSelected] = useState<any>([])
  const [communityId, setCommunityId] = useState<any>()
  const [mixedClusterData, setMixedClusterData] = useState<any>()
  const [unitGroupData, setUnitGroupData] = useState<any>()
  const [floorName, setFloorName] = useState<any>()
  const [developmentName, setDevelopmentName] = useState<any>()
  const [buildingId, setBuildingId] = useState<any>()
  const [selectedUnitData, setSelectedUnitData] = useState<any>([])
  const currentTab: any = localStorage.getItem('currentTab')
  const [propertySummary, setPropertySummary] = useState<any>()
  const [propertyDevelopmentSummary, setPropertyDevelopmentSummary] = useState<any>()
  const [isEdit, setIsEdit] = useState<any>(false)
  const [datatoUpdate, setDatatoUpdate] = useState<any>()
  const [commId, setCommId] = useState<any>()
  const [clusterId, setClusterId] = useState<any>()
  const [unitGrp, setUnitGrp] = useState<any>()
  const [buildingName, setBuildingName] = useState<any>('')

  const [developType, setDevelopType] = useState<any>()
  const [clusterName, setClusterName] = useState<any>('')
  var clust: any
  var build: any

  const [isComm, setIsComm] = useState<boolean>(false)
  const [isBuild, setIsBuild] = useState<boolean>(false)

  const [commDatatoUpdate, setCommDatatoUpdate] = useState<any>()
  const [buildingDataToUpdate, setBuildingDataToUpdate] = useState<any>()

  const serviceId = window.location.pathname?.split('/')[4]
  const unitsData: any = localStorage.getItem('isUnit_' + serviceId)

  // const requestServiceId: any = localStorage.getItem('serviceRequestId')

  const addedUnitData = JSON.parse(unitsData)
  const [previousUnitData, setPreviousUnitData] = useState<any>(JSON.parse(unitsData))

  const pDetail: any = localStorage.getItem('propertyDetail_' + serviceId)
  // const properties: any = JSON.parse(pDetail)
  const [peopertyUnit, setPropertyUnit] = useState<any>(JSON.parse(pDetail))

  var existed: any

  var unitsToUpdate: any = []

  var [isCommunity, setIsCommunity] = useState<any>(3)
  var [buildingIdToUpdate, setBuildingIdToUpdate] = useState<any>()
  var [communityIdToUpdate, setCommunityIdToUpdate] = useState<any>()

  //  select clusture
  const handleChnageCluster = (e: any) => {
    setBuildingName('')
    // setType(value.split('/')[1].split('-')[0])
    const {name, value} = e.target
    clust = value.split('/')[1]
    setClusterName(value.split('/')[1])
    setFormData1({...formData1, [name]: value.split('/')[0]})
    let dte: any = cluster?.filter((v: any, index: any) => v._id == value.split('/')[0])
    // setcluster([])
    setclustertype(dte[0].type)
    if (dte[0].type == 0) {
      setDevelopType('isCommClust')
      callunitbyclusterid(value.split('/')[0])
    } else if (dte[0].type == 1) {
      setDevelopType('isCommClustBuild')
      callclusterbuilding(value.split('/')[0])
    } else if (dte[0].type == 2) {
      setDevelopType('isCommClustMixed')
      callclusterunitgroup(value.split('/')[0])
    }
    setclusterunit([])
    setclusterflour([])
    setclusterbuilding([])
    setclusterunitgroup([])
  }
  const handleChnageclusterbuilding = (e: any) => {
    const {name, value} = e.target
    setBuildingName(value.split('/')[1])
    build = value.split('/')[1]
    setclusterunit([])
    setclusterflour([])
    // setclusterbuilding([])
    setBuildingId(value.split('/')[0])
    callflour(value.split('/')[0])
    setFormData1({...formData1, [name]: value.split('/')[0]})
  }
  const handleChnageclusterunit = (e: any) => {
    const {name, value} = e.target
    setclusterunit([])
    callclusterunitbyclusterflour(value)
    setFormData1({...formData1, [name]: value})
  }
  const handleChnageUGB = (e: any) => {
    const {name, value} = e.target

    setBuildingName(value.split('/')[1])
    build = value.split('/')[1]
    setclusterunit([])
    setclusterflour([])
    setclusterbuilding([])
    setclusterunitgroup([])
    setChnageUGB(value.split('/')[1]?.split(' ')[0])
    if (value.split('/')[1]?.split(' ')[0] == 'Building') {
      callclusterflour(value.split('/')[0])
      setFormData1({...formData1, ['Building']: value.split('/')[0]})
    } else if (value.split('/')[1]?.split(' ')[0] == 'Unit') {
      callunitbyunitgroupid(value.split('/')[0])
      setFormData1({...formData1, ['Unit']: value.split('/')[0]})
    }
  }

  const callunitbyunitgroupid = (value: any) => {
    ApiGet(`corporate/unit?unitGroupId=${value}`)
      .then((res) => {
        setclusterunit(res?.data?.data)
        setupdateclusterunit([])
        setDatatoUpdate([])
        setIsEdit(false)
      })
      .catch((err) => console.log('err', err))
  }

  const callclusterunitgroup = (value: any) => {
    const body = {
      communityId: communityId,
      clusterId: value,
    }
    ApiPost(`corporate/service_request/get_unit_list`, body)
      .then((res) => {
        setMixedClusterData(res?.data?.data?.communityData[0]?.cluster[0]?.building)
        setUnitGroupData(res?.data?.data?.communityData[0]?.cluster[0]?.unit_group)
        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callunitbyclusterid = async (value: any) => {
    // ApiGet(`corporate/unit?clusterId=${value}`)
    //   .then((res) => {
    //     setclusterunit(res?.data?.data)
    //     setupdateclusterunit([])
    //     setDatatoUpdate([])
    //     setIsEdit(false)
    //     // setHeaderB(res)
    //   })
    //   .catch((err) => console.log('err', err))
    const body = {
      communityId: communityId ? communityId : null,
      clusterId: value,
      serviceRequestId: requestServiceId,
    }

    await ApiPost(`corporate/service_request/get_unit_list`, body)
        .then((res) => {
          setclusterunit(res?.data?.data?.communityData[0]?.cluster[0]?.unit)
              setupdateclusterunit([])
              setDatatoUpdate([])
              setIsEdit(false)
              // setHeaderB(res)
        })
        .catch((err) => console.log('err', err))

  }
  const callclusterunitbyclusterflour = (value: any) => {
    ApiGet(`corporate/unit?floorId=${value}`)
      .then((res) => {
        console.log('res', res)

        setclusterunit(res?.data?.data)
        setupdateclusterunit([])
        setDatatoUpdate([])
        setIsEdit(false)
        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callclusterflour = (value: any) => {
    console.log('\nvalue', value)
    ApiGet(`corporate/floor?buildingId=${value}`)
      .then((res) => {
        setclusterflour(res?.data?.data?.map((i: any) => ({label: i?.name, value: i?._id})))
        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callclusterbuilding = (value: any) => {
    ApiGet(`corporate/building?clusterId=${value}`)
      .then((res) => {
        setclusterbuilding(res?.data?.data)

        // setHeaderB(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  const handleChnageflour = (e: any) => {
    const {name, value} = e.target
    setFloorName(value.split('/')[1])
    // callunitbyflour(value.split('/')[0])
    setFormData1({...formData1, [name]: value.split('/')[0]})
  }

  // 
  const callunitbyflour = () => {
    setSelectedUnitData([])
    selected?.map((i: any, index: any) => {
      const body = {
        buildingId: buildingId,
        floorId: i?._id,
        communityId: communityId ? communityId : null,
        clusterId: formData1?.Cluster ? formData1?.Cluster : null,
        serviceRequestId: requestServiceId,
      }
      bodyFunction(body, index)
    })
  }

  const bodyFunction = async (body: any, index: any) => {
    if (type === 'C') {
      await ApiPost(`corporate/service_request/get_unit_list`, body)
        .then((res) => {
          const newData = res?.data?.data?.communityData[0]?.cluster[0]?.building[0]?.floor
          setSelectedUnitData((prev: any) => [...prev, newData?.[0]])
        })
        .catch((err) => console.log('err', err))
    } else {
      await ApiPost(`corporate/service_request/get_unit_list`, body)
        .then((res) => {
          console.log('res?.data?.data++', res?.data)
          const newData = res?.data?.data?.buildingData[0]?.floor
          setSelectedUnitData((prev: any) => [...prev, newData[0]])
        })
        .catch((err) => console.log('err', err))
    }
  }

  useEffect(() => {
    callunitbyflour()
  }, [selected])

  useEffect(() => {
    setSelected([])
    setclusterunit([])
  }, [developmentName])

  const callcluster = (value: any) => {
    ApiGet(`corporate/cluster?communityId=${value}`)
      .then((res) => {
        setcluster(res?.data?.data)
        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callflour = (value: any) => {
    console.log('\nvalue', value)
    ApiGet(`corporate/floor?buildingId=${value}`)
      .then((res) => {
        // setflour(res?.data?.data)
        // console.log('\nres.data.data', res.data.data)
        if (res?.data?.data?.length) {
          setflour(
            res?.data?.data?.map((i: any) => ({...i, label: i?.name, value: i?._id, checked: true}))
          )
        }

        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }

  const addServiceRequest = async () => {
    const body = {
      requestType: 2,
    }
    await ApiPost(`corporate/service_request`, body)
      .then((res) => {
        localStorage.setItem(currentTab, res?.data?.data?._id)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    getSummary()
    // if (!requestServiceId) {
    //   addServiceRequest()
    // }

    selectedProperty = []

    let temp = localStorage.getItem('isUnit_' + serviceId)
    if (temp !== null) {
      // selectedProperty = temp
      // const parsedData = JSON.parse(temp)
      // addedUnitData = parsedData
      // console.log(parsedData)
      // setPreviousUnitData(parsedData)
      // addedUnitData.map((a:any)=> {
      //   console.log(a.unitId)
      // })
      // console.log(previousUnitData)
      // let z : any = []
      // for (let i=0; i<addedUnitData.length; i++){
      //  z[i] = addedUnitData[i]
      // }
    } else selectedProperty = []
  }, [])

  const addProperty = async () => {
    console.log(selectedProperty)

    let temp = localStorage.getItem('isUnit_' + serviceId)

    if (temp !== null) {
      let x: any = JSON.parse(temp)
      for (let i = 0; i < selectedProperty.length; i++) {
        x.push(selectedProperty[i])
      }
      console.log(x)
      // localStorage.setItem('isUnit_' + requestServiceId, JSON.stringify(x))
    } else {
      // localStorage.setItem('isUnit_' + requestServiceId, JSON.stringify(selectedProperty))
    }

    // let isUnits = localStorage.getItem('isUnitAdded'+requestServiceId)
    const body = {
      serviceRequestId: serviceId,
      service_job: selectedProperty,
    }
    // if(isUnits !== null){
    //   await ApiPut(`corporate/service_job/multiple_add`, body)
    //   .then((res) => {
    //     localStorage.setItem('isUnitAdded'+requestServiceId, '1')
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //   })
    // }
    // else{
    await ApiPost(`corporate/service_job/multiple_add`, body)
      .then((res) => {
        localStorage.setItem('isUnitAdded' + serviceId, '1')
        localStorage.setItem('isPropertySelected_' + serviceId, '1')

        if (peopertyUnit !== null) {
          localStorage.setItem('propertyDetail_' + serviceId, JSON.stringify(peopertyUnit))
        } else {
          // setPropertyUnit([{developmentName: developmentName, totalunit:  selectedProperty.length}])
          localStorage.setItem(
            'propertyDetail_' + serviceId,
            JSON.stringify([{developmentName: developmentName, totalunit: selectedProperty.length}])
          )
        }

        getSummary()
        // navigate(-1)
      })
      .catch((e) => {
        console.log(e)
      })
    // }
    // navigate(`/request-management/service/create-request-service`)
  }
  // floors unit data
  const selectedUnits = async (id: any, floorId: any, e: any) => {
    selectedProperty = []
    if (selectedProperty.includes(id)) {
      selectedProperty = selectedProperty?.filter((item: any) => item !== id)
    } else {
      selectedProperty.push({
        communityId: communityId ? communityId : null,
        clusterId: formData1?.Cluster ? formData1?.Cluster : null,
        buildingId: buildingId ? buildingId : null,
        floorId: floorId ? floorId : null,
        unitGroupId: formData1?.Unit ? formData1?.Unit : null,
        unitId: id,
      })
    }

    const body = {
      serviceRequestId: serviceId,
      service_job: selectedProperty,
    }
    setOpen(true)

    const bodyAllunits = {
      serviceRequestId: serviceId,
      communityId: communityId ? communityId : null,
      clusterId: formData1?.Cluster ? formData1?.Cluster : null,
      buildingId: buildingId ? buildingId : null,
      floorId: floorId ? floorId : null,
      unitGroupId: formData1?.Unit ? formData1?.Unit : null,
      unitId: id,
    }

    if (e.target.checked === true) {
      await ApiPost(`corporate/service_job/multiple_add`, body)
        .then((res) => {
          setOpen(false)
          getSummary()
          // navigate(-1)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      await ApiPost(`corporate/service_request/select_propertise/all_unit`, bodyAllunits)
        .then((res) => {
          // if simple cluster
          if (clustertype === 0) {
            console.log(res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit)
            let newData = res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit
            for (let i = 0; i < newData.length; i++) {
              if (id === newData[i]._id) newData[i].isCheck = false
            }

            let newVal: any = []
            let x = newData
            for (let i = 0; i < x.length; i++) {
              if (x[i].isCheck === true) {
                let m = x[i]._id
                newVal[newVal.length] = m
              }
            }

            const data = {
              serviceRequestId: serviceId,
              communityId: communityId ? communityId : null,
              clusterId: formData1?.Cluster ? formData1?.Cluster : null,
              buildingId: buildingId ? buildingId : null,
              floorId: null,
              unitGroupId: formData1?.Unit ? formData1?.Unit : null,
              unitIds: newVal,
            }

            console.log(data)
            ApiPost(`corporate/service_request/select_propertise/update`, data)
              .then((res) => {
                setOpen(false)

                getSummary()
              })
              .catch((e) => {
                ErrorToast(e.message)
                console.log(e)
              })
          }
          // cluster-> building
          else if (clustertype === 1) {
            let newData: any
            setOpen(false)

            if (res?.data?.data?.developmentList[0]?.community[0]?.cluster.length !== 0) {
              res?.data?.data?.developmentList[0]?.community[0]?.cluster.map((cl: any) => {
                if (cl?.building.length !== 0) {
                  cl?.building.map((bld: any) => {
                    if (bld?.floor?.length !== 0) {
                      bld?.floor.map((fl: any) => {
                        if (fl?.unit.length !== 0) {
                          console.log()
                          newData = fl?.unit
                        }
                      })
                    }
                  })
                }
              })
            }

            for (let i = 0; i < newData.length; i++) {
              if (id === newData[i]._id) newData[i].isCheck = false
            }

            let newVal: any = []
            let x = newData
            for (let i = 0; i < x.length; i++) {
              if (x[i].isCheck === true) {
                let m = x[i]._id
                newVal[newVal.length] = m
              }
            }

            const data = {
              serviceRequestId: serviceId,
              communityId: communityId ? communityId : null,
              clusterId: formData1?.Cluster ? formData1?.Cluster : null,
              buildingId: buildingId ? buildingId : null,
              floorId: null,
              unitGroupId: formData1?.Unit ? formData1?.Unit : null,
              unitIds: newVal,
            }

            console.log(data)
            ApiPost(`corporate/service_request/select_propertise/update`, data)
              .then((res) => {
                setOpen(false)

                getSummary()
              })
              .catch((e) => {
                ErrorToast(e.message)
                console.log(e)
              })
          } else {
            let newData = res?.data?.data?.developmentList[0]?.building[0]?.floor[0]?.unit

            for (let i = 0; i < newData.length; i++) {
              if (id === newData[i]._id) newData[i].isCheck = false
            }

            let newVal: any = []
            let x = newData
            for (let i = 0; i < x.length; i++) {
              if (x[i].isCheck === true) {
                let m = x[i]._id
                newVal[newVal.length] = m
              }
            }

            const data = {
              serviceRequestId: serviceId,
              communityId: communityId ? communityId : null,
              clusterId: formData1?.Cluster ? formData1?.Cluster : null,
              buildingId: buildingId ? buildingId : null,
              floorId: null,
              unitGroupId: formData1?.Unit ? formData1?.Unit : null,
              unitIds: newVal,
            }

            console.log(data)
            ApiPost(`corporate/service_request/select_propertise/update`, data)
              .then((res) => {
                setOpen(false)

                getSummary()
                getSummary()
                // navigate(-1)
                getSummary()
                // navigate(-1)
              })
              .catch((e) => {
                ErrorToast(e.message)
                console.log(e)
                console.log(e)
                console.log(e)
              })
          }
        })
        .catch()
    }
  }

  // clusters unit data
  const unitData = async (id: any, e: any) => {
    console.log(e.target.checked)
    selectedProperty = []
    if (selectedProperty.includes(id) === true) {
      selectedProperty = selectedProperty?.filter((item: any) => item !== id)
    } else {
      selectedProperty.push({
        communityId: communityId ? communityId : null,
        clusterId: formData1?.Cluster ? formData1?.Cluster : null,
        buildingId: buildingId ? buildingId : null,
        floorId: null,
        unitGroupId: formData1?.Unit ? formData1?.Unit : null,
        unitId: id,
      })
    }

    const body = {
      serviceRequestId: serviceId,
      service_job: selectedProperty,
    }

    const bodyAllUnits = {
      serviceRequestId: serviceId,
      communityId: communityId ? communityId : null,
      clusterId: formData1?.Cluster ? formData1?.Cluster : null,
      buildingId: buildingId ? buildingId : null,
      floorId: null,
      unitGroupId: formData1?.Unit ? formData1?.Unit : null,
      unitId: id,
    }

    setOpen(true)
    if (e.target.checked === true) {
      await ApiPost(`corporate/service_job/multiple_add`, body)
        .then((res) => {
          setOpen(false)
          getSummary()
          // navigate(-1)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      await ApiPost(`corporate/service_request/select_propertise/all_unit`, bodyAllUnits)
        .then((res) => {
          console.log(res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit)
          let newData = res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit
          for (let i = 0; i < newData.length; i++) {
            if (id === newData[i]._id) newData[i].isCheck = false
          }

          let newVal: any = []
          let x = newData
          for (let i = 0; i < x.length; i++) {
            if (x[i].isCheck === true) {
              let m = x[i]._id
              newVal[newVal.length] = m
            }
          }

          const data = {
            serviceRequestId: serviceId,
            communityId: communityId ? communityId : null,
            clusterId: formData1?.Cluster ? formData1?.Cluster : null,
            buildingId: buildingId ? buildingId : null,
            floorId: null,
            unitGroupId: formData1?.Unit ? formData1?.Unit : null,
            unitIds: newVal,
          }

          console.log(data)
          ApiPost(`corporate/service_request/select_propertise/update`, data)
            .then((res) => {
              setOpen(false)

              getSummary()
            })
            .catch((e) => {
              ErrorToast(e.message)
              console.log(e)
            })
        })
        .catch()
    }
  }
  const removeTeam = () => {
    setPropertyUnit('')
  }

  // Select the units
  const setPropertyUnitData = () => {
    if (peopertyUnit !== null) {
      for (let i = 0; i < peopertyUnit.length; i++) {
        if (peopertyUnit[i].developmentName === developmentName) {
          console.log(peopertyUnit[i].developmentName + '  ' + developmentName)
          peopertyUnit[i].totalunit = peopertyUnit[i].totalunit + selectedProperty.length
          existed = 3
        }

        var x = peopertyUnit
      
        setPropertyUnit([])
      
        setPropertyUnit(x)
      }

      if (existed !== 3) {
        var newData: any = peopertyUnit
        newData[newData.length] = {
          developmentName: developmentName,
          totalunit: selectedProperty.length,
        }
        setPropertyUnit(newData)
      }

      // localStorage.setItem('propertyDetail_'+requestServiceId,JSON.stringify(peopertyUnit))
    } else {
      console.log('First record ')
      setPropertyUnit([{developmentName: developmentName, totalunit: selectedProperty.length}])

      // localStorage.setItem('propertyDetail_'+requestServiceId,JSON.stringify( [{developmentName: developmentName, totalunit:  selectedProperty.length}]))
    }
  }

  const moveVendor = () => {
    navigate(`/request-management/service/vendor-list`)
    localStorage.setItem('inter', 'false')
  }

  const getSummary = async () => {
    const body = {
      serviceRequestId: serviceId,
    }
    await ApiPost(`corporate/service_request/select_propertise/summery`, body)
      .then((res) => {
        setPropertySummary(res?.data?.data)
        setPropertyDevelopmentSummary(res?.data?.data?.developmentList)
      })
      .catch()
  }

  //  get community records to update
  // const getAllUnits = async (commName: string, commId: any, clusId: any, units: any) => {
  const getAllUnits = async (val: any, val2: string) => {
    // console.log(val)
    setCommDatatoUpdate('')
    setBuildingDataToUpdate('')

    let devType: any = val2

    let commId: any = ''
    let clusId: any = ''
    let buildingId: any = ''
    let floorId: any = ''
    // for community
    if (val.community) {
      commId = val?.community?.[0]?._id
      if (val?.community?.[0]?.cluster.length !== 0) {
        clusId = val?.community?.[0]?.cluster?.[0]?._id
        devType = 'isCommClust'
        if (val?.community?.[0]?.cluster?.[0]?.building.length !== 0) {
          buildingId = val?.community?.[0]?.cluster?.[0]?.building?.[0]._id
          devType = 'isCommClustBuild'
        }
      }
    } else {
      buildingId = val?.building?.[0]?._id
    }
    // for building

    // console.log(commName)
    // setDevelopmentName(commName)
    // setFormData1({...formData1, 'name': ''+commName+''})
    setIsEdit(true)
    let body: any
    if (val2 === 'isComm') {
      setIsCommunity(1)
      body = {
        serviceRequestId: serviceId,
        communityId: commId,
        clusterId: '',
        buildingId: '',
      }
      setCommunityIdToUpdate(commId)
    } else {
      setIsCommunity(2)
      body = {
        serviceRequestId: serviceId,
        communityId: '',
        clusterId: '',
        buildingId: buildingId,
      }
      setBuildingIdToUpdate(buildingId)
    }

    await ApiPost(`corporate/service_request/select_propertise/all_unit`, body)
      .then((res) => {
        console.log(res?.data?.data)
        if (val2 === 'isComm') {
          setCommDatatoUpdate(res?.data?.data?.developmentList)
          setBuildingDataToUpdate('')
          setDevelopmentName('')
          setClusterName('')
          setType('')
          setclustertype('')
        } else if (val2 === 'isBuild') {
          setBuildingDataToUpdate(res?.data?.data?.developmentList)

          setCommDatatoUpdate('')
          setDevelopmentName('')
          setClusterName('')
          setType('')
          setclustertype('')
        }

        // if (devType === 'isCommClust')
        //   setDatatoUpdate(res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit)
        // else if (devType === 'isCommClustBuild')
        //   setDatatoUpdate(
        //     res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.building?.[0]?.floor?.[0]
        //       .unit
        //   )

        // setupdateclusterunit(res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit)
        // setDatatoUpdate(res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit)
        // setPropertySummary(res?.data?.data)
        // setPropertyDevelopmentSummary(res?.data?.data?.developmentList)

        // setclusterunit([])
        // setCommId(commId)
        // setClusterId(clusId)
        // setUnitGrp(res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit_group)
      })
      .catch()
  }

  //  get records to update
  const getAllUnitsForBuilding = async (buildingName: string, buildId: any, floorId: any) => {
    setDevelopmentName(buildingName)
    // setFormData1({...formData1, 'name': ''+commName+''})
    setIsEdit(true)
    const body = {
      serviceRequestId: serviceId,
      buildingId: buildId,
      floorId: floorId,
    }
    await ApiPost(`corporate/service_request/select_propertise/all_unit`, body)
      .then((res) => {
        setupdateclusterunit(res?.data?.data?.developmentList[0]?.building[0]?.floor[0]?.unit)
        setDatatoUpdate(res?.data?.data?.developmentList[0]?.building[0]?.floor[0]?.unit)
        // setPropertySummary(res?.data?.data)
        // setPropertyDevelopmentSummary(res?.data?.data?.developmentList)
        setclusterunit([])
        // setCommId(commId)
        // setClusterId(clusId)
        // setUnitGrp(res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit_group)
      })
      .catch()
  }

  const deletePropertise = async (commName: string, commId: any, clusId: any, units: any) => {
    const body = {
      serviceRequestId: serviceId,
      communityId: commId,
      clusterId: '',
    }
    await ApiPost(`corporate/service_request/select_propertise/delete`, body)
      .then((res) => {
        SuccessToast('Record has been deleted successfully!!')
        setDevelopmentName('')
        setupdateclusterunit([])
        setDatatoUpdate([])
        setIsEdit(false)
        setCommDatatoUpdate('')
        setBuildingDataToUpdate('')
        // setPropertySummary(res?.data?.data)
        // setPropertyDevelopmentSummary(res?.data?.data?.developmentList)
        getSummary()
      })
      .catch((e) => {
        ErrorToast(e.message)
      })
  }

  const deleteBuilding = async (buildId: any) => {
    const body = {
      serviceRequestId: serviceId,
      buildingId: buildId,
      floorId: '',
    }
    await ApiPost(`corporate/service_request/select_propertise/delete`, body)
      .then((res) => {
        SuccessToast('Record has been deleted successfully!!')
        setDevelopmentName('')
        setupdateclusterunit([])
        setDatatoUpdate([])
        setIsEdit(false)
        setCommDatatoUpdate('')
        setBuildingDataToUpdate('')
        // setPropertySummary(res?.data?.data)
        // setPropertyDevelopmentSummary(res?.data?.data?.developmentList)
        getSummary()
      })
      .catch((e) => {
        ErrorToast(e.message)
      })
  }

  // update record
  const updateDataToSend = async (
    commId: any,
    clusId: any,
    buildId: any,
    floorId: any,
    val: any,
    e: any,
    tp: any
  ) => {
    setOpen(true)
    const bodyAllunits = {
      serviceRequestId: serviceId,
      communityId: commId,
      clusterId: clusId,
      buildingId: buildId,
      floorId: floorId,
      unitGroupId: '',
      unitId: val,
    }

    await ApiPost(`corporate/service_request/select_propertise/all_unit`, bodyAllunits)
      .then((res) => {
        let newData: any

        if (tp === 1) {
          if (res?.data?.data?.developmentList[0]?.community[0]?.cluster.length !== 0) {
            res?.data?.data?.developmentList[0]?.community[0]?.cluster.map((cl: any) => {
              if (cl?.building.length !== 0) {
                cl?.building.map((bld: any) => {
                  if (bld?.floor?.length !== 0) {
                    bld?.floor.map((fl: any) => {
                      if (fl?.unit.length !== 0) {
                        newData = fl?.unit
                      }
                    })
                  }
                })
              }
            })
          }
        } else if (tp === 2) {
          newData = res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit
        } else if (tp === 3) {
          newData = res?.data?.data?.developmentList[0]?.building[0]?.floor[0]?.unit
        }

        for (let i = 0; i < newData.length; i++) {
          if (val === newData[i]._id) newData[i].isCheck = !newData[i].isCheck
        }

        let newVal: any = []
        let x = newData
        for (let i = 0; i < x.length; i++) {
          if (x[i].isCheck === true) {
            let m = x[i]._id
            newVal[newVal.length] = m
          }
        }

        const data = {
          serviceRequestId: serviceId,
          communityId: commId,
          clusterId: clusId,
          buildingId: buildId,
          floorId: floorId,
          unitGroupId: '',
          unitIds: newVal,
        }

        console.log(data)
        ApiPost(`corporate/service_request/select_propertise/update`, data)
          .then((res) => {
            setOpen(false)
            if (newVal.length === 0) {
              setCommDatatoUpdate('')
              setBuildingDataToUpdate('')
            }

            getSummary()
          })
          .catch((e) => {
            ErrorToast(e.message)
            console.log(e)
          })
      })
      .catch()

    // let x = datatoUpdate
    let x = unitsToUpdate

    for (let i = 0; i < x.length; i++) {
      if (x[i]._id === val) {
        x[i].isCheck = !x[i].isCheck
      }
    }
    unitsToUpdate = x
    // setDatatoUpdate(x)
    // console.log(datatoUpdate)
  }

  // update peopertise
  const updatePropertise = async () => {
    // let x = datatoUpdate
    let x = unitsToUpdate
    let data = []
    for (let i = 0; i < x.length; i++) {
      if (x[i].isCheck === true) {
        let newData = x[i]._id
        data[data.length] = newData
      }
    }
    // console.log(data)

    let body: any
    console.log(isCommunity + 'isCommunity')
    if (isCommunity === 1) {
      body = {
        serviceRequestId: serviceId,
        communityId: communityIdToUpdate,
        clusterId: '',
        buildingId: '',
        floorId: '',
        // unitGroupId: unitGrp,
        unitIds: data,
      }
    } else if (isCommunity === 2) {
      body = {
        serviceRequestId: serviceId,
        communityId: '',
        clusterId: '',
        buildingId: buildingIdToUpdate,
        floorId: '',
        // unitGroupId: unitGrp,
        unitIds: data,
      }
    }

    await ApiPost(`corporate/service_request/select_propertise/update`, body)
      .then((res) => {
        SuccessToast('Record has been updated successfully!!')
        setDatatoUpdate([])
        setDevelopmentName('')
        setIsEdit(false)
        setCommDatatoUpdate('')
        setBuildingDataToUpdate('')
        getSummary()
      })
      .catch((e) => {
        ErrorToast(e.message)
        console.log(e)
      })
  }

  // development/first-drop-down selection
  const handleChnage12 = (e: any) => {
    setBuildingName('')
    setCommDatatoUpdate('')
    setBuildingDataToUpdate('')
    setIsEdit(false)
    setDevelopmentName('')
    setClusterName('')
    setType('')
    setclustertype(10)
    setType('')
    console.log(e.target)
    const {name, value} = e.target
    console.log('\nname 49', name, value)
    setFormData1({...formData1, [name]: value.split('/')[0]})
    setType(value.split('/')[1].split('-')[0])
    setDevelopmentName(value.split('/')[2])

    // if community is selected
    if (value.split('/')[1].split('-')[0] == 'C') {
      setclusterunit([])
      setclusterflour([])
      setclusterbuilding([])
      setclusterunitgroup([])
      callcluster(value.split('/')[0])
      setCommunityId(value.split('/')[0])
      setBuildingId('')
      console.log('\n check 61', value.split('/')[1].split('-')[0])
      setIsComm(true)
      setIsBuild(false)
    }
    // if building is selected
    else if (value.split('/')[1].split('-')[0] == 'B') {
      callflour(value.split('/')[0])
      setunit([])
      setflour([])
      setBuildingId(value.split('/')[0])
      setCommunityId('')
      setIsComm(false)
      setIsBuild(true)
    }
  }

  // get All the development list either community or building i.e. first dropdown-list
  const callDevelopment = () => {
    ApiGet(`corporate/communities/get_development`)
      .then((res) => {
        setdevlopment(res?.data?.data)
        // setHeaderB(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  React.useEffect(() => {
    callDevelopment()
  }, [])

  // console.log('\nflour', flour)
  return (
    <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
      <div id='' className='app-container container-xxl pt-0 mt-0'>
        {/* <div className='d-flex align-items-baseline flex-wrap mr-5'>
          <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate(`/request-management/service`)}
              >
                Request
              </a>
            </li>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate(`/request-management/service/create-request-service`)}
              >
                {' '}
                Internal Request
              </a>
            </li>
            <li className='breadcrumb-item active'>
              <a className='px-2'> Select Property</a>
            </li>
          </ul>
        </div>
        <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li
              className='nav-item'
              // onClick={() => {
              //   navigate(`/request-management/service/create-request-service`)
              // }}
            >
              <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                Internal
              </a>
            </li>
            <li
              className='nav-item'
              // onClick={() => {
              //   // navigate(`/request-management/quotation`)
              // }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Marketplace
              </a>
            </li>
            <li
              className='nav-item'
              // onClick={() =>moveVendor()}
            >
              <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                VendorList
              </a>
            </li>
          </ul>
        </div> */}

        <Row className='mb-5'>
          <div className='col-md-7 px-0'>
            <a
              className='btn btn-md fw-bold btn-primary btn-green me-3'
              // onClick={() => navigate(`/request-management/service/create-request-service`)}
              onClick={() => navigate(-1)}
            >
              <img
                src={back}
                style={{
                  height: '17px',
                  width: '17px',
                  marginRight: '7px',
                  marginLeft: '-10px',
                  marginTop: '-2px',
                }}
              />
              Back
            </a>
          </div>

          {/* <div className='col-md-5 d-flex'>
            <button
              className='btn btn-md w-100 fw-bold btn-primary btn-green '
              onClick={() => addProperty()}
            >
              Add
            </button>
          </div> */}
        </Row>

        <Row>
          <Col md={7}>
            <Row>
              <div className='card p-5'>
                <h3 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-5 mt-5'>
                  Select Properties
                </h3>
                <form>
                  <div className='mb-5'>
                    <div
                      className='stepper stepper-pills stepper-column flex-column flex-xl-row flex-row-fluid'
                      id='kt_modal_create_app_stepper'
                    >
                      <div
                        className='card-header align-items-center gap-md-2'
                        style={{
                          justifyContent: 'flex-start',
                          paddingLeft: '0px',
                          borderBottom: '0px',
                        }}
                      >
                        <div className='mb-10 min-w-200px '>
                          <label htmlFor='exampleFormControlInput1' className='required form-label'>
                            Development
                          </label>
                          <select
                            {...register('unitType', {required: true})}
                            name='unitType'
                            className='form-select form-select-solid'
                            onChange={handleChnage12}
                          >
                            <option value=''>Select</option>
                            {devlopment.length > 0 &&
                              devlopment.map((data: any, i: any) => {
                                var isInc: any
                                return (
                                  <>
                                    {/* {propertyDevelopmentSummary &&
                                      propertyDevelopmentSummary.map((dev: any) => {
                                        if (dev.community[0]._id === data._id) isInc = 3
                                        return <></>
                                      })} */}
                                    {/* {isInc !== 3 && ( */}
                                    <option value={`${data._id}/${data?.id}/${data.name}`}>
                                      {data.name}
                                    </option>
                                    {/* )} */}
                                  </>
                                )
                              })}
                          </select>
                          {errors.unitType && (
                            <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                          )}
                        </div>
                        {type === 'C' && (
                          <>
                            <div className='mb-10 min-w-200px '>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label'
                              >
                                Cluster
                              </label>
                              <select
                                {...register('Cluster', {required: true})}
                                name='Cluster'
                                className='form-select form-select-solid'
                                value={clust}
                                onChange={handleChnageCluster}
                              >
                                <option selected value=''>
                                  Select
                                </option>
                                {cluster.length > 0 &&
                                  cluster.map((data: any, i) => {
                                    var isInc: any
                                    return (
                                      <>
                                        {/* {propertyDevelopmentSummary &&
                                      propertyDevelopmentSummary.map((dev: any) => {
                                        if (dev.community[0].cluster[0]._id === data._id) isInc = 3
                                        return <></>
                                      })}
                                    {isInc !== 3 && ( */}
                                        <option value={`${data._id}/${data.name}`}>
                                          {data.name}{' '}
                                        </option>
                                        {/* )} */}
                                      </>
                                    )
                                  })}
                              </select>
                              {errors.Cluster && (
                                <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                              )}
                            </div>
                            {clustertype == 2 && mixedClusterData ? (
                              <div className='mb-10 min-w-200px '>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label'
                                >
                                  Select UG/B
                                </label>
                                <select
                                  {...register('clusterType', {required: true})}
                                  className='form-select form-select-solid'
                                  onChange={handleChnageUGB}
                                >
                                  <option selected value=''>
                                    Select
                                  </option>
                                  {[...mixedClusterData, ...unitGroupData]?.map(
                                    (data: any, i: any) => {
                                      return (
                                        <option value={`${data._id}/${data?.name}`}>
                                          {data.name}
                                        </option>
                                      )
                                    }
                                  )}
                                </select>
                                {errors.clusterType && (
                                  <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                                )}
                              </div>
                            ) : null}
                            {clustertype == 1 ? (
                              <div className='mb-10 min-w-200px '>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label'
                                >
                                  Building
                                </label>
                                <select
                                  {...register('building', {required: true})}
                                  name='building'
                                  placeholder='Select Building'
                                  className='form-select form-select-solid'
                                  value={build}
                                  onChange={handleChnageclusterbuilding}
                                >
                                  <option selected value=''>
                                    Select
                                  </option>
                                  {clusterbuilding.length > 0 &&
                                    clusterbuilding.map((data: any, i) => (
                                      <option value={`${data._id}/${data?.name}`}>
                                        {data.name}
                                      </option>
                                    ))}
                                  {errors.building && (
                                    <p style={{color: 'var(--kt-danger)'}}>
                                      This field is required
                                    </p>
                                  )}
                                </select>
                              </div>
                            ) : null}
                            {formData1?.building ||
                            (clustertype == 2 && ChnageUGB == 'Building') ? (
                              <div className='mb-10 min-w-200px w-200px'>
                                <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label'
                                >
                                  Floor
                                </label>
                                <MultiSelect
                                  options={flour}
                                  value={selected}
                                  onChange={setSelected}
                                  labelledBy='Select'
                                />
                                {errors.floor && (
                                  <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                                )}
                              </div>
                            ) : null}
                            {/* <div className='row gy-2'></div> */}
                          </>
                        )}
                        {type === 'B' && (
                          <>
                            <div className='mb-10 min-w-200px w-200px'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label'
                              >
                                Floor
                              </label>
                              <MultiSelect
                                options={flour}
                                value={selected}
                                onChange={setSelected}
                                labelledBy='Select'
                              />

                              {errors.flour && (
                                <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </form>
                {/* <div style={{display: 'flex', justifyContent: 'left'}}>
                  <a
                    className='btn btn-md w-25 fw-bold btn-primary btn-green me-3'
                    onClick={() => {
                      setPropertyUnitData()
                      addProperty()
                    }}
                  >
                    <img
                      src={plus}
                      style={{
                        height: '17px',
                        width: '17px',
                        marginRight: '7px',
                        marginLeft: '-10px',
                        marginTop: '-3px',
                      }}
                    />
                    Add
                  </a>
                </div> */}
              </div>
            </Row>
            <Row className='mt-4'>
              <div className='card card-flush p-5'>
                <Row>
                  <Col md={4}>
                    {/* {isComm === true ? (
                      <h3 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-5 mt-5'>
                        {developmentName}
                      </h3>
                    ) : isBuild === true ? (
                      <h3 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-5 mt-5'>
                        {developmentName}
                      </h3>
                    ) : (
                      ''
                    )} */}
                  </Col>
                  <Col md={4}>
                    <div className='d-flex align-items-center mb-5 mt-5'>
                      <div className='d-flex align-items-center me-7'>
                        <div className='green-dot'></div>{' '}
                        <h5 style={{marginBottom: '0px'}}>Vacant</h5>
                      </div>
                      <div className='d-flex align-items-center'>
                        {' '}
                        <div className='gray-dot'></div>{' '}
                        <h5 style={{marginBottom: '0px'}}>Occupied</h5>
                      </div>
                    </div>
                  </Col>

                  <Col md={4} className='text-end'>
                    {/* {isEdit === true ? (
                      <a
                        className='btn btn-sm fw-bold btn-primary btn-green mt-2'
                        onClick={() => {
                          updatePropertise()
                        }}
                      >
                        <img
                          src={approve}
                          style={{
                            height: '16px',
                            width: '16px',
                            marginRight: '7px',
                            marginLeft: '-10px',
                          }}
                        />
                        Update
                      </a>
                    ) : (
                      <></>
                      
                    )} */}
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    {isComm === true ? (
                      <p className='page-heading d-flex text-dark flex-column justify-content-center mt-0 main-heading mb-0'>
                        {developmentName}
                      </p>
                    ) : isBuild === true ? (
                      <p className='page-heading d-flex text-dark flex-column justify-content-center mt-0 main-heading mb-0'>
                        {developmentName}
                      </p>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
                <Row className='Data'>
                  {clusterName !== '' && (
                    <p
                      className='page-heading d-flex text-dark flex-column justify-content-center my-1 clusterCheck clust-heading'
                      style={{marginLeft: '25px'}}
                    >
                      {clusterName}
                    </p>
                  )}
                </Row>

                {buildingName !== '' && (
                  <Row className='Data'>
                    <p
                      className='page-heading d-flex text-dark fw-bold flex-column justify-content-center my-1 buildingCheck clust-build-heading'
                      style={{marginLeft: '25px'}}
                    >
                      {buildingName}
                    </p>
                  </Row>
                )}
                {/* Units from Cluster */}
                <Row className='mt-1'>
                  {clusterunit &&
                    clusterunit.map((f: any, i) => {
                      var isTrue: any
                      return (
                        <Col md={4}>
                          <div className='d-flex align-items-center'>
                            <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='flexCheckDefault'
                                style={{cursor: 'pointer'}}
                                defaultChecked={f?.isCheck === true ? true : false}
                                onChange={(e) => unitData(f?._id, e)}
                              />
                            </div>
                            <div className='d-flex' style={{paddingTop: '8px'}}>
                              <h6 style={{marginRight: '7px'}}>{f?.unitNo} </h6>{' '}
                              <div className={f?.occupy === 0 ? 'green-dot' : 'gray-dot'}></div>
                            </div>
                            {/* <div style={{ marginBottom: '7px' }} className='green-dot'></div> */}
                          </div>
                        </Col>
                      )
                    })}
                </Row>

                {/* Selected Units from Floors */}
                {selectedUnitData?.map((f: any, i: any) => {
                  return (
                    <>
                      {clusterName !== '' ? (
                        <Row>
                          <p
                            className='page-heading d-flex text-dark flex-column justify-content-center my-1 floorCheck floor-head'
                            style={{marginLeft: '25px'}}
                          >
                            <b>{f?.name}</b>
                          </p>
                        </Row>
                      ) : (
                        <Row>
                          {i === 0 ? (
                            <p
                              className='page-heading d-flex text-dark flex-column justify-content-center floorCheck floor-head2'
                              style={{marginLeft: '25px', fontSize: '16px !important'}}
                            >
                              {f?.name}
                            </p>
                          ) : (
                            <p
                              className='page-heading d-flex text-dark flex-column justify-content-center my-2 floorCheck floor-head2'
                              style={{marginLeft: '25px', fontSize: '16px !important'}}
                            >
                              {f?.name}
                            </p>
                          )}
                        </Row>
                      )}
                      <Row className=''>
                        {f?.unit?.length > 0 &&
                          f?.unit?.map((i: any) => (
                            <Col md={4}>
                              <div className='d-flex align-items-center'>
                                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    value=''
                                    id='flexCheckDefault'
                                    style={{cursor: 'pointer'}}
                                    defaultChecked={i?.isCheck === true ? true : false}
                                    onChange={(e) => selectedUnits(i?._id, f?._id, e)}
                                  />
                                </div>
                                {/* <h6 style={{marginRight: '2px'}}>Unit :{i?.unitNo}</h6>
                        {f?.occupy === 1 ? (
                          <div className='green-dot' style={{marginBottom: '7px'}}></div>
                        ) : (
                          <div className='gray-dot' style={{marginBottom: '7px'}}></div>
                        )} */}
                                <div className='d-flex' style={{paddingTop: '8px'}}>
                                  <h6 style={{marginRight: '7px'}}>{i?.unitNo}  </h6>{' '}
                                  <div className={i?.occupy === 0 ? 'green-dot' : 'gray-dot'}></div>
                                </div>
                              </div>
                            </Col>
                          ))}
                      </Row>
                    </>
                  )
                })}

                {/* Update Community Data*/}
                {commDatatoUpdate &&
                  commDatatoUpdate.map((dev: any, index: any) => {
                    let totalUnitss: any = 0
                    return (
                      <div className='row mt-3'>
                        {/* Community */}
                        {dev.community ? (
                          <>
                            <div className='col-md-12'>
                              <p className='main-heading'>{dev.community[0].name}</p>
                              <>
                                {dev?.community[0]?.cluster.length !== 0 &&
                                  dev?.community[0]?.cluster.map((cl: any) => {
                                    totalUnitss = totalUnitss + cl?.unit?.length
                                    return (
                                      <>
                                        <p
                                          className='clusterCheck my-2 clust-heading'
                                          style={{marginLeft: '25px'}}
                                        >
                                          <span>{cl.name} </span>
                                        </p>
                                        <>
                                          {cl?.building.length !== 0 ? (
                                            cl?.building.map((bld: any) => (
                                              <>
                                                <p
                                                  className='buildingCheck my-2 clust-build-heading'
                                                  style={{marginLeft: '25px'}}
                                                >
                                                  <b> {bld.name}</b>
                                                </p>
                                                <>
                                                  {bld?.floor.length !== 0 &&
                                                    bld?.floor.map((fl: any) => {
                                                      return (
                                                        <>
                                                          <p
                                                            className='floorCheck my-1 floor-heading'
                                                            style={{marginLeft: '25px'}}
                                                          >
                                                            <b>{fl.name}</b>
                                                          </p>
                                                          <Row>
                                                            {fl?.unit?.length !== 0 &&
                                                              fl?.unit.map((i: any) => {
                                                                unitsToUpdate[
                                                                  unitsToUpdate.length
                                                                ] = i
                                                                return (
                                                                  <Col md={4}>
                                                                    <div className='d-flex align-items-center'>
                                                                      <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                                                        <input
                                                                          className='form-check-input'
                                                                          type='checkbox'
                                                                          value=''
                                                                          id='flexCheckDefault'
                                                                          style={{
                                                                            cursor: 'pointer',
                                                                          }}
                                                                          defaultChecked={
                                                                            i?.isCheck === true
                                                                              ? true
                                                                              : false
                                                                          }
                                                                          onChange={(e) =>
                                                                            updateDataToSend(
                                                                              dev?.community[0]._id,
                                                                              cl._id,
                                                                              bld._id,
                                                                              fl._id,
                                                                              i?._id,
                                                                              e,
                                                                              1
                                                                            )
                                                                          }
                                                                        />
                                                                      </div>

                                                                      <div
                                                                        className='d-flex'
                                                                        style={{paddingTop: '8px'}}
                                                                      >
                                                                        <h6
                                                                          style={{
                                                                            marginRight: '7px',
                                                                          }}
                                                                        >
                                                                          {i?.unitNo}{' '}
                                                                        </h6>{' '}
                                                                        <div
                                                                          className={
                                                                            i?.occupy === 0
                                                                              ? 'green-dot'
                                                                              : 'gray-dot'
                                                                          }
                                                                        ></div>
                                                                      </div>
                                                                    </div>
                                                                  </Col>
                                                                )
                                                              })}
                                                          </Row>
                                                        </>
                                                      )
                                                    })}
                                                </>
                                              </>
                                            ))
                                          ) : (
                                            <>
                                              <Row>
                                                {cl?.unit?.length !== 0 &&
                                                  cl?.unit.map((i: any) => {
                                                    unitsToUpdate[unitsToUpdate.length] = i
                                                    return (
                                                      <Col md={4}>
                                                        <div className='d-flex align-items-center'>
                                                          <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                                            <input
                                                              className='form-check-input'
                                                              type='checkbox'
                                                              value=''
                                                              id='flexCheckDefault'
                                                              style={{cursor: 'pointer'}}
                                                              defaultChecked={
                                                                i?.isCheck === true ? true : false
                                                              }
                                                              onClick={(e) =>
                                                                updateDataToSend(
                                                                  dev?.community[0]._id,
                                                                  cl._id,
                                                                  '',
                                                                  '',
                                                                  i?._id,
                                                                  e,
                                                                  2
                                                                )
                                                              }
                                                            />
                                                          </div>

                                                          <div
                                                            className='d-flex'
                                                            style={{paddingTop: '8px'}}
                                                          >
                                                            <h6 style={{marginRight: '7px'}}>
                                                              {i?.unitNo}{' '}
                                                            </h6>{' '}
                                                            <div
                                                              className={
                                                                i?.occupy === 0
                                                                  ? 'green-dot'
                                                                  : 'gray-dot'
                                                              }
                                                            ></div>
                                                          </div>
                                                        </div>
                                                      </Col>
                                                    )
                                                  })}
                                              </Row>
                                            </>
                                          )}
                                        </>
                                      </>
                                    )
                                  })}
                              </>
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    )
                  })}

                {/* Update buildings Data */}
                <Row className='Data'>
                  {buildingDataToUpdate &&
                    buildingDataToUpdate.map((bld: any) => {
                      unitsToUpdate = []
                      // var isTrue: any
                      return (
                        <div>
                          <p className='main-heading'>
                            <b>{bld.building[0].name}</b>
                          </p>
                          <>
                            {bld?.building[0].floor.length !== 0 &&
                              bld?.building[0].floor.map((fl: any) => {
                                return (
                                  <>
                                    <p
                                      className='floorCheck mt-2 mb-1 clust-heading'
                                      style={{marginLeft: '25px'}}
                                    >
                                      {fl.name}
                                    </p>
                                    <Row>
                                      {fl?.unit?.length !== 0 &&
                                        fl?.unit.map((i: any) => {
                                          unitsToUpdate[unitsToUpdate.length] = i
                                          return (
                                            <Col md={4}>
                                              <div className='d-flex align-items-center'>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                                                  <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    value=''
                                                    id='flexCheckDefault'
                                                    style={{
                                                      cursor: 'pointer',
                                                    }}
                                                    defaultChecked={
                                                      i?.isCheck === true ? true : false
                                                    }
                                                    onClick={(e) =>
                                                      updateDataToSend(
                                                        '',
                                                        '',
                                                        bld.building[0]._id,
                                                        fl._id,
                                                        i?._id,
                                                        e,
                                                        3
                                                      )
                                                    }
                                                  />
                                                </div>

                                                <div className='d-flex' style={{paddingTop: '8px'}}>
                                                  <h6
                                                    style={{
                                                      marginRight: '7px',
                                                    }}
                                                  >
                                                    {i?.unitNo}{' '}
                                                  </h6>{' '}
                                                  <div
                                                    className={
                                                      i?.occupy === 0 ? 'green-dot' : 'gray-dot'
                                                    }
                                                  ></div>
                                                </div>
                                              </div>
                                            </Col>
                                          )
                                        })}
                                    </Row>
                                  </>
                                )
                              })}
                          </>
                        </div>
                      )
                    })}
                </Row>
              </div>
            </Row>
          </Col>

          {/* Green Card */}
          <Col md={5}>
            <div className='select-box p-5 h-100'>
              <div className='mb-10 service_list'>
                <Row className='pb-2' style={{borderBottom: '1px dashed gray'}}>
                  {/* <Col md={6}>
                    <h5 className='mb-5 mt-5' style={{color: '#007a59'}}>
                      {' '}
                      Properties Selected
                    </h5>
                  </Col>
                  <Col md={6}>
                    { peopertyUnit !== null ?
                    <h5 className='mb-2 mt-5'> Developments:  {peopertyUnit.length}</h5>
                    : 
                    <h5 className='mb-2 mt-5'> Developments:  0</h5>
                    }
                    
                    <h5 className='mb-5 '> Total Units : {peopertyUnit?.totalunit}</h5>
                  </Col> */}

                  <Col md={5}>
                    <h4 className='' style={{color: 'black'}}>
                      {' '}
                      <b>Properties Selected</b>
                    </h4>
                  </Col>
                  {propertySummary && (
                    <Col md={7} className='text-end pr-1'>
                      <h5 className='mb-1'>
                        Developments{' '}
                        <b className='ms-3'>
                          {propertySummary.totalBuilding + propertySummary.totalCommunity}
                        </b>
                      </h5>
                      <h5 className='mb-1'>
                        Total Units <b className='ms-3'>{propertySummary.totalUnits}</b>
                      </h5>
                    </Col>
                  )}
                </Row>

                {/* Property Summary Green Card */}
                {propertyDevelopmentSummary &&
                  propertyDevelopmentSummary.map((dev: any, index: any) => {
                    let totalUnitss: any = 0
                    return (
                      <div className='row mt-3'>
                        {/* Community */}
                        {dev.community ? (
                          <>
                            <div className='col-md-7'>
                              <p style={{paddingLeft: '1px'}} className='main-heading mb-0'>
                                {index + 1}
                                {'. '}
                                {dev.community[0].name}
                              </p>
                              <>
                                {dev?.community[0]?.cluster.length !== 0 &&
                                  dev?.community[0]?.cluster.map((cl: any) => {
                                    totalUnitss = totalUnitss + cl?.unit?.length
                                    return (
                                      <>
                                        <p
                                          className='clusterCheck clust-heading'
                                          style={{marginLeft: '27px'}}
                                        >
                                          {cl.name}
                                        </p>
                                        <>
                                          {cl?.building.length !== 0 ? (
                                            cl?.building.map((bld: any) => (
                                              <>
                                                <p
                                                  className='buildingCheck mt-1 clust-build-heading'
                                                  style={{marginLeft: '27px'}}
                                                >
                                                  <b>{bld.name}</b>
                                                </p>
                                                <>
                                                  {bld?.floor.length !== 0 &&
                                                    bld?.floor.map((fl: any) => {
                                                      if (fl?.units?.length !== 0)
                                                        totalUnitss = totalUnitss + fl?.unit?.length
                                                      return (
                                                        <p
                                                          className='floorCheck mt-1 floor-heading'
                                                          style={{marginLeft: '27px'}}
                                                        >
                                                          <b>{fl.name} </b>
                                                        </p>
                                                      )
                                                    })}
                                                </>
                                              </>
                                            ))
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      </>
                                    )
                                  })}
                              </>
                            </div>
                            <h6 className='col-md-3'>
                              Total Units <b className='ms-3'>{totalUnitss}</b>
                            </h6>
                            <h6 className='col-md-2 pe-0'>
                              {' '}
                              <AiFillEdit
                                color='green'
                                fontSize={20}
                                className='mx-3'
                                style={{cursor: 'pointer'}}
                                onClick={
                                  () => getAllUnits(dev, 'isComm')
                                  // getAllUnits(
                                  //   dev.community[0].name,
                                  //   dev.community[0]._id,
                                  //   dev.community[0].cluster[0]._id,
                                  //   dev.community[0].cluster[0].unit
                                  // )
                                }
                              />
                              <AiFillDelete
                                color='#D54645'
                                fontSize={20}
                                className='ms-1'
                                style={{cursor: 'pointer'}}
                                onClick={() =>
                                  deletePropertise(
                                    dev.community[0].name,
                                    dev.community[0]._id,
                                    dev.community[0].cluster[0]._id,
                                    dev.community[0].cluster[0].unit
                                  )
                                }
                              />
                            </h6>
                          </>
                        ) : (
                          // Building
                          <>
                            <div className='col-md-7'>
                              <p className='main-heading mb-0' style={{paddingLeft: '1px'}}>
                                {index + 1}
                                {'. '}
                                {dev.building[0].name}
                              </p>
                              <>
                                {dev.building[0]?.floor?.length !== 0 &&
                                  dev.building[0]?.floor.map((fl: any) => {
                                    if (fl?.unit?.length !== 0)
                                      totalUnitss = totalUnitss + fl?.unit?.length
                                    return (
                                      <p
                                        className='floorCheck my-1 clust-heading'
                                        style={{marginLeft: '27px'}}
                                      >
                                        {fl?.name}{' '}
                                      </p>
                                    )
                                  })}
                              </>
                            </div>
                            <h6 className='col-md-3'>
                              Total Units <b className='ms-3'>{totalUnitss}</b>
                            </h6>
                            <h6 className='col-md-2 pe-0'>
                              {' '}
                              <AiFillEdit
                                color='green'
                                fontSize={20}
                                className='mx-3'
                                style={{cursor: 'pointer'}}
                                onClick={
                                  () => getAllUnits(dev, 'isBuild')
                                  // getAllUnitsForBuilding(
                                  //   dev.building[0].name,
                                  //   dev.building[0]._id,
                                  //   dev.building[0].floor[0]._id
                                  // )
                                }
                              />
                              <AiFillDelete
                                color='#D54645'
                                fontSize={20}
                                className='ms-1'
                                style={{cursor: 'pointer'}}
                                onClick={() => deleteBuilding(dev.building[0]._id)}
                              />
                            </h6>
                          </>
                        )}
                        {/* {dev.community ? <></> : <></>} */}

                        {/* {dev.community ? (
                         
                        ) : (
                          
                        )} */}
                        {/* <div className='d-flex mx-5'>
                          <p className='pb-0'>
                            {dev.community[0].cluster[0].unit &&
                              dev.community[0].cluster[0].unit.map((unitt: any, index: any) => {
                                return (
                                  <>
                                    {unitt.unitNo} {' , '}
                                  </>
                                )
                              })}
                          </p>
                        </div> */}
                      </div>
                    )
                  })}
                {/* <div className='d-flex mx-5'>
                  <p className="pb-3" style={{borderBottom: '1px dashed darkgray'}}>
                    unit-001, unit-107, unit-999,unit-001, unit-107, unit-999, unit-107, unit-999,
                    unit-107, unit-999,{' '}
                  </p>
                </div> */}

                {/* <div className='d-flex mt-2'>
                  <h6 className='me-5'><b>2. Development Name</b></h6>
                  <h6 className='mx-auto'>Total Units   <b className='ms-3'>###</b></h6>

                  <h6 className='d-flex text-end'>
                    {' '}
                    <AiFillEdit
                      color='green'
                      fontSize={20}
                      className='mx-3'
                      style={{cursor: 'pointer'}}
                    />
                    <AiFillDelete
                      color='#D54645'
                      fontSize={20}
                      className='ms-3'
                      style={{cursor: 'pointer'}}
                    />
                  </h6>
                </div> */}
                {/* <div className='d-flex  mx-5'>
                  <p style={{borderBottom: '1px dashed darkgray'}}>
                    unit-001, unit-107, unit-999,unit-001, unit-107, unit-999, unit-107, unit-999,
                    unit-107, unit-999,{' '}
                  </p>
                </div> */}

                {/* <div className='d-flex mt-2'>
                  <h6 className='me-5'><b>3. Development Name</b></h6>
                  <h6 className='mx-auto'>Total Units   <b className='ms-3'>###</b></h6>

                  <h6 className='d-flex text-end'>
                    {' '}
                    <AiFillEdit
                      color='green'
                      fontSize={20}
                      className='mx-3'
                      style={{cursor: 'pointer'}}
                    />
                    <AiFillDelete
                      color='#D54645'
                      fontSize={20}
                      className='ms-3'
                      style={{cursor: 'pointer'}}
                    />
                  </h6>
                </div>

                <div className='d-flex  mx-5'>
                  <p style={{borderBottom: '1px dashed darkgray'}}>
                    unit-001, unit-107, unit-999,unit-001, unit-107, unit-999, unit-107, unit-999,
                    unit-107, unit-999,{' '}
                  </p>
                </div> */}

                {/* <table
                    className='table align-middle table-row-dashed fs-6 gy-5'
                    id='kt_ecommerce_sales_table'
                  > */}
                {/* <thead> */}
                {/* <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                        <th className='text-center min-w-100px'>Development name</th>
                        <th className='text-center min-w-100px'>Total Units</th>


                        <th className='d-flex'>  <AiFillEdit
                                color='green'
                                fontSize={20}
                               
                                style={{cursor: 'pointer'}}
                              />
                               <AiFillDelete
                                color='#D54645'
                                fontSize={20}
                               
                                style={{cursor: 'pointer'}}
                              /></th>
                      </tr> */}
                {/* </thead> */}
                {/* <tbody className='fw-semibold text-gray-600'>
                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                        <td className='text-center min-w-100px'>Development Name</td>
                        <td className='text-center min-w-100px'>Total Units: ###</td>


                        <td className='d-flex'>  <AiFillEdit
                                color='green'
                                fontSize={20}
                                className='mx-3'
                               
                                style={{cursor: 'pointer'}}
                              />
                               <AiFillDelete
                                color='#D54645'
                                fontSize={20}
                                className='mx-3'
                               
                                style={{cursor: 'pointer'}}
                              /></td>
                      </tr>
                      <tr>
                        <td><p>unit-001, unit-107, unit-999,unit-001, unit-107, unit-999,unit-001, unit-107, unit-999</p></td>
                      </tr>

                    </tbody> */}

                {/* <tbody className='fw-semibold text-gray-600'>
                      {peopertyUnit !== null  &&
                        peopertyUnit.map((pUnit: any) => {
                          return (
                            <tr>
                              <td className='text-center min-w-100px'>{pUnit?.developmentName}</td>
                              <td className='text-center min-w-100px'>{pUnit?.totalunit}</td>
                              {/* <td>
                            <AiFillDelete
                              style={{cursor: 'pointer'}}
                              onClick={() => removeTeam()}
                            />
                          </td> */}
                {/* </tr>
                          )
                        })}
                    </tbody> */}
                {/* </table> */}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </div>
  )
}

export default SelectProperty
