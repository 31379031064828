import React, {useEffect, useRef} from 'react'
import {useState} from 'react'
import EmailBuilder from '../EmailBuilder'
import {Bucket} from '../../../../../../apiCommon/helpers/API/ApiData'

const Template002 = () => {
  const imageUploaderSmall = `${Bucket}6582bac50d9a049c6882be03/email_template_63ff37b0031cb72db844e843/uploader-small`
  const imageUploader = `${Bucket}6582bac50d9a049c6882be03/email_template_63ff37b0031cb72db844e843/uploader-large`
  const imageUploaderSquare = `${Bucket}6582bac50d9a049c6882be03/email_template_63ff37b0031cb72db844e843/uploader-square`

  const [sections, setSections] = useState<any>([
    {
      id: `${Date.now()}1`,
      type: 'image',
      content: {
        display: 'flex',
        justifyContent: 'left',
        textAlign: 'left',
        backgroundColor: '#6254FF',
        height: 60,
        width: 180,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '10',
        paddingRight: '10',
        paddingBottom: '10',
        paddingLeft: '10',
        imageUrl: '',
        sizeType: 'px',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        imgSrc:'headerImg',
        html: `<div style="background-color: transparent; text-align: left; border-radius: 8px 8px 0 0;">
                <a href= "" target="_blank">
                  <img src="${imageUploaderSmall}" alt="Header Image" style="display: block; width: 180px; height: 60px; border-radius: 5px; max-width: 100%; margin: 0px 10px" id="img-in-svg" />
                </a>
              </div>`,
        image: [
          {
            display: 1,
          },
        ],
        link: [
          {
            display: 1,
          },
        ],
      },
    },
    {
      id: `${Date.now()}2`,
      type: 'image',
      content: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#fff',
        height: 220,
        width: 350,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '20',
        paddingRight: '0',
        paddingBottom: '20',
        paddingLeft: '0',
        imageUrl: '',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        html: `<div style="background-color: transparent; text-align: center;">
                <a href= "" target="_blank">
                  <img src="${imageUploader}" alt="Main Content Image" style="width: 350px; height: 220px; border-radius: 5px; max-width: 100%;  padding: 0px 10px" />
                </a>
              </div>`,
        image: [
          {
            display: 1,
          },
        ],
        link: [
          {
            display: 1,
          },
        ],
      },
    },
    {
      id: `${Date.now()}3`,
      type: 'text',
      content: {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: '#fff',
        height: undefined,
        width: 100,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '25',
        paddingRight: '20',
        paddingBottom: '25',
        paddingLeft: '20',
        imageUrl: '',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        html: `<div style="text-align: center; border-radius: 8px; margin-top: 10px;">
                <h1 style="font-size: 24px; margin-bottom: 20px; color: #000;">Header Title</h1>
              </div>`,
      },
    },
    {
      id: `${Date.now()}4`,
      type: 'text',
      content: {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: '#fff',
        height: undefined,
        width: 100,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '10',
        paddingRight: '20',
        paddingBottom: '10',
        paddingLeft: '20',
        imageUrl: '',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        html: `<p style="font-size: 12px; line-height: 1.6; color: #000;">Here’s what you need to do:</p>
              <ol style="font-size: 12px; line-height: 1.6; color: #000;">
                <li>Click ‘Get Started’ to download Propertise and complete your account.</li>
                <li>Complete and submit your landlord’s registration form.</li>
              </ol>`,
      },
    },
    {
      id: `${Date.now()}5`,
      type: 'text',
      content: {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: '#fff',
        height: undefined,
        width: 100,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '10',
        paddingRight: '20',
        paddingBottom: '10',
        paddingLeft: '20',
        imageUrl: '',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        html: `<p style="font-size: 12px; line-height: 1.6; color: #000;">
                  Connect instantly with your landlord and start managing your lease, rental payments, requests, and more.
              </p>`,
      },
    },
    {
      id: `${Date.now()}6`,
      type: 'text',
      content: {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: '#fff',
        height: undefined,
        width: 100,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '5',
        paddingRight: '20',
        paddingBottom: '50',
        paddingLeft: '20',
        imageUrl: '',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        html: ` <p style="font-size: 12px; line-height: 1.6; color: #000;">
                  If you have any questions or need assistance, our support team is here to help. Feel free to reach out to us at 
                  <a href="mailto:support@propertise.com" style="color: #4A90E2;">support@propertise.com</a>.
                </p>`,
      },
    },
    {
      id: `${Date.now()}7`,
      type: 'image',
      content: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#fff',
        height: 120,
        width: 120,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '20',
        paddingRight: '20',
        paddingBottom: '50',
        paddingLeft: '20',
        imageUrl: '',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        html: `<div style="text-align: center;">
                <a href="" target="_blank"><img src="${imageUploaderSquare}" alt="Img 1" style="width:120px; height: 120px; margin-right: 5px; border-radius: 5px; max-width: 100%; padding: 0px 10px 10px 10px" /></a>
                <a href="" target="_blank"><img src="${imageUploaderSquare}" alt="Img 2" style="width:120px; height: 120px; margin-right: 5px; border-radius: 5px; max-width: 100%; padding: 0px 10px 10px 10px" /></a>
                <a href="" target="_blank"><img src="${imageUploaderSquare}" alt="Img 3" style="width:120px; height: 120px; border-radius: 5px; max-width: 100%; padding: 0px 10px 10px 10px" /></a>
              </div>`,
        image: [
          {
            display: 1,
            linkDisplay: 1,
            link: '',
          },
          {
            display: 1,
            linkDisplay: 1,
            link: '',
          },
          {
            display: 1,
            linkDisplay: 1,
            link: '',
          },
        ],
        link: [
          {
            display: 1,
          },
          {
            display: 1,
          },
          {
            display: 1,
          },
        ],
      },
    },
    {
      id: `${Date.now()}8`,
      type: 'text',
      content: {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: '#6254FF',
        // height: '40',
        width: 100,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '15',
        paddingRight: '20',
        paddingBottom: '15',
        paddingLeft: '20',
        imageUrl: '',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        html: `<div style="background-color: transparent; padding: 20px; text-align: left; border-radius: 8px 8px 0 0;">
               </div>`,
      },
    },
    {
      id: `${Date.now()}9`,
      type: 'text',
      content: {
        display: 'flex',
        justifyContent: 'left',
        backgroundColor: 'transparent',
        height: undefined,
        width: 100,
        // color: '#fff',
        marginTop: '0',
        marginRight: '0',
        marginBottom: '0',
        marginLeft: '0',
        paddingTop: '30',
        paddingRight: '20',
        paddingBottom: '40',
        paddingLeft: '20',
        imageUrl: '',
        title: 'Title One',
        borderThickness: '1',
        borderLine: 'solid',
        borderColor: 'transparent',
        borderInnerTagRadius: '5',
        html: `<div style="text-align: center;">
                <p style="font-size: 12px; color: #000;">This is a system generated email, please do not respond to it.</p>
                <p style="font-size: 12px; color: #000; margin-top: 5px;">
                  <a href="https://www.propertise.com/" style="color: #000; text-decoration: none; margin-right: 15px;">About Us</a> | 
                  <a href="mailto:info@propertise.com" style="color: #000; text-decoration: none; margin-left: 15px;">Contact Us</a>
                </p>
                <p style="font-size: 12px; color: #000; margin-top: 10px;">Copyright@2024, Propertise FZCO, All rights reserved</p>
              </div>`,
      },
    },
  ])
  return (
    <>
      <EmailBuilder template={sections} />
    </>
  )
}

export default Template002
