import React from 'react'
import {Col, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {AiFillDelete} from 'react-icons/ai'
import {ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'

const AssignRequest = () => {
  const navigate = useNavigate()
  const [internalStaff, setInternalStaff] = React.useState<any>()
  const [searchStaff, setSearchStaff] = React.useState<any>()
  const [searchDepartMentName, setSearchDepartMent] = React.useState<any>()
  const [searchTitle, setSearchTitle] = React.useState<any>()
  const [pageLimit, setPageLimit] = React.useState<any>()
  const [page, setPage] = React.useState<any>(1)
  const [checked, setChecked] = React.useState<any>()
  const [selectedItem, setSelectedItem] = React.useState<any>()
  const currentTab: any = localStorage.getItem('currentTab')
  const requestServiceId: any = localStorage.getItem(currentTab)

  const getInternalStaff = async () => {
    const body = {
      page: page,
      limit: 10,
      searchName: searchStaff ? searchStaff : '',
      searchDepartment: searchDepartMentName ? searchDepartMentName : '',
      searchTitle: searchTitle ? searchTitle : '',
    }
    await ApiPost(`corporate/staff/get`, body)
      .then((res) => {
        // setTableData(res?.data?.data)
        setInternalStaff(res?.data?.data?.staff_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const addServiceRequest = async () => {
    const body = {
      requestType: 1,
    }
    await ApiPost(`corporate/service_request`, body)
      .then((res) => {
        localStorage.setItem(currentTab, res?.data?.data?._id)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    // if (!requestServiceId) {
    //   addServiceRequest()
    // }
    getInternalStaff()
  }, [searchDepartMentName, searchTitle])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const handleSelectTeam = (item: any, index: any) => {
    setChecked(index)
    setSelectedItem(item)
  }

  const removeTeam = () => {
    setChecked('')
    setSelectedItem('')
  }

  const addStaff = async () => {
    const body = {
      id: requestServiceId,
      staffId: selectedItem?._id,
      requestType: 1,
    }
    await ApiPut(`corporate/service_request`, body)
      .then((res) => {})
      .catch((e) => {
        console.log(e)
      })
    moveVendor()
  }
  const moveVendor = () => {
    navigate(`/request-management/service/vendor-list`)
    localStorage.setItem('inter', 'false')
  }

  return (
    <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
      <div id='' className='app-container container-xxl pt-0 mt-0'>
        <div className='d-flex align-items-baseline flex-wrap mr-5'>
          <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate(`/request-management/service`)}
              >
                Request
              </a>
            </li>
            <li className='breadcrumb-item '>
              <a className='text-muted px-2 cursor-pointer' onClick={() => moveVendor()}>
                External Request
              </a>
            </li>
            <li className='breadcrumb-item active'>
              <a className='px-2'> select Staff</a>
            </li>
          </ul>
        </div>
        <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li
              className='nav-item'
              onClick={() => {
                navigate(`/request-management/service/create-request-service`)
              }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Internal
              </a>
            </li>
            <li
              className='nav-item'
              // onClick={() => {
              //     navigate(`/request-management/quotation`)
              // }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Marketplace
              </a>
            </li>
            <li className='nav-item' onClick={() => moveVendor()}>
              <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                VendorList
              </a>
            </li>
          </ul>
        </div>

        <div className='card card-flush app-container py-5'>
          <Row>
            <div className='col-md-3'>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                onClick={() => moveVendor()}
              >
                Back
              </a>
            </div>
            <div className='col-md-3'></div>
            <div className='col-md-3'></div>
            <div className='col-md-3' style={{textAlign: 'end'}}>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                onClick={() => addStaff()}
              >
                Assign
              </a>
            </div>
          </Row>

          <Row>
            <div className='col-md-6'></div>
            <div className='col-md-6 mt-2 select-box'>
              <h3 className='page-heading d-flex text-dark fw-bold fs-3 mt-2 flex-column justify-content-center mb-5'>
                Selected Staff
              </h3>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='text-center min-w-100px'>Username</th>
                    <th className='text-center min-w-100px'>Department</th>
                    <th className='text-center min-w-100px'>Title</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {selectedItem && (
                    <tr>
                      <td className='text-center min-w-100px'>{selectedItem?.name}</td>
                      <td className='text-center min-w-100px'>
                        {selectedItem?.department[0]?.name}
                      </td>
                      <td className='text-center min-w-100px'>{selectedItem?.title}</td>
                      <td>
                        <AiFillDelete style={{cursor: 'pointer'}} onClick={() => removeTeam()} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Row>
          <h3 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-5'>
            Assign Request
          </h3>
          <div className='mb-10'>
            <Row>
              <Col lg={4} md={4} className='d-flex align-items-center'>
                <label htmlFor='exampleFormControlInput1' className='w-200px form-label m-0'>
                  Search Staff
                </label>
                <input
                  type='text'
                  name='sub_service'
                  className='form-control form-control-solid solid'
                  onChange={(e) => setSearchStaff(e.target.value)}
                />
                <button
                  className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                  onClick={() => getInternalStaff()}
                  style={{marginLeft: '10px'}}
                >
                  Search
                </button>
              </Col>
              <Col lg={3} md={3} className='d-flex align-items-center'>
                <label htmlFor='exampleFormControlInput1' className='w-200px form-label m-0'>
                  Department
                </label>
                <select
                  name='request_source'
                  className='form-select form-select-solid'
                  onChange={(e) => setSearchDepartMent(e.target.value)}
                >
                  <option selected value=''>
                    Select
                  </option>
                  {internalStaff?.map((item: any) => {
                    return <option>{item?.department[0]?.name}</option>
                  })}
                </select>
              </Col>
              <Col lg={3} md={3} className='d-flex align-items-center'>
                <label htmlFor='exampleFormControlInput1' className='w-200px form-label m-0'>
                  Title
                </label>
                <select
                  name='request_status '
                  className='form-select form-select-solid'
                  onChange={(e) => setSearchTitle(e.target.value)}
                >
                  <option selected value=''>
                    Select
                  </option>
                  {internalStaff?.map((item: any) => {
                    return <option>{item?.title}</option>
                  })}
                </select>
              </Col>
            </Row>
          </div>
          <div className='card-body pt-0 table-responsive mt-5'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='text-center min-w-10px'></th>
                  <th className='min-w-100px'>Staff Name</th>
                  <th className='min-w-100px'>Department</th>
                  <th className='min-w-100px'>Title </th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {internalStaff?.map((item: any, index: any) => {
                  return (
                    <tr>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='radio'
                            checked={checked === index}
                            style={{cursor: 'pointer'}}
                            onChange={() => handleSelectTeam(item, index)}
                          />
                        </div>
                      </td>
                      <td>{item?.name}</td>
                      <td>{item?.department[0]?.name}</td>
                      <td>{item?.title}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous disabled'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssignRequest
