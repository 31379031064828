import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { ApiPost } from '../../../../apiCommon/helpers/API/ApiData'
import { ErrorToast, SuccessToast } from '../../../../apiCommon/helpers/Toast'
import { BsFillEyeSlashFill } from 'react-icons/bs'
import { BsFillEyeFill } from 'react-icons/bs'
import queryString from 'query-string';
import { Button } from 'react-bootstrap'

const initialValues = {
  password: '',
  repeatPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  otp: Yup.string()
    .min(6, 'Minimum 3 symbols')
    .max(6, 'Maximum 50 symbols')
    .required('OTP is required'),
})

const ResetPassword = () => {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const params = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [showCon, setShowCon] = useState(false)
  const [isAgree, setIsAgree] = useState<any>(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [flg, setflg] = useState(0)

  const urlData: any = queryString.parse(location.search)
  const validEmail = new RegExp('^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$');
  const poorRegExp = /[a-z]/;
    const weakRegExp = /(?=.*?[0-9])/;;
    const strongRegExp = /(?=.*?[#?!@$%^&*-])/;
  const handleChange = (e: any) => {
    console.log(e,"dfsdfsd",e.target.value.length)
    const poorPassword= poorRegExp.test(e.target.value);
    const weakPassword= weakRegExp.test(e.target.value);
    const strongPassword= strongRegExp.test(e.target.value);
    setPassword(e.target.value)
    if ((poorPassword && weakPassword) && strongPassword && e.target.value.length >= 8) {
      console.log("dsfsdfsdfsdfsd")
      setflg(3);
   }
   else if (poorPassword && (weakPassword || strongPassword) && e.target.value.length >= 6) {
    setflg(2);
 }
 else if((poorPassword || weakPassword || strongPassword) && e.target.value.length >= 4) {
  setflg(1);
}else{
  setflg(0);
}
  }
  const handleChangeCon = (e: any) => {
    setConfirmPassword(e.target.value)
  }
  const callsubmit = () => {
    let body = {
      id: params?.id,
      password: password,
      otp: params?.otp,
    }

    setLoading(true)
    if(!validEmail.test(password) || password.length<8){
       return ErrorToast('Use 8 or more characters with a mix of letters,Uppercase, numbers & symbols.')
    }
    if(password.length>15){
      return ErrorToast('Password length not valid more than 15')
   }
    if (password === confirmPassword ) {
     
        
        
          ApiPost('corporate/reset_password', body)
            .then((res) => {
              SuccessToast(res?.data?.message)
              setLoading(false)
              navigate('/auth/login')
            })
            .catch((e) => {
              console.log('e', e)
              ErrorToast(e.message)
              setLoading(false)
            })
       
      
      
    } else {
      return ErrorToast('Password and ConfirmPassword are not match')
    }
  }
  // const formik = useFormik({
  //   initialValues,
  //   // validationSchema: forgotPasswordSchema,
  //   onSubmit: (values, { setStatus ,setSubmitting }) => {

  //     let body = {
  //       id: params?.id,
  //       password: password,
  //       otp: params?.otp,
  //     }

  //     setLoading(true)
  //     // if(!validEmail.test(password)){
  //     //    ErrorToast('Password and ConfirmPassword are not match')
  //     // }
  //     if (password === confirmPassword ) {
       
  //         try {
          
  //           ApiPost('corporate/reset_password', body)
  //             .then((res) => {
  //               SuccessToast(res?.data?.message)
  //               setLoading(false)
  //               navigate('/auth/login')
  //             })
  //             .catch((e) => {
  //               console.log('e', e)
  //               ErrorToast(e.message)
  //               setLoading(false)
  //             })
  //         } catch (error) {
  //           setLoading(false)
  //         }
        
        
  //     } else {
  //       ErrorToast('Password and ConfirmPassword are not match')
  //     }
  //   },
  // })


  return (
    <>
      <div>
            <div className='text-center mb-10'>
            <h1 className='text-dark fw-bolder mb-3'>Setup New Password</h1>
          </div>
        {/* <form className='form w-100'  > */}

          <div className='fv-row mb-8' >
            <div className='mb-1'>
              <div className='position-relative mb-3'>
                <input
                  className='form-control bg-transparent'
                  // pattern='^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$'
                  type={show ? 'text ' : 'password'}
                  placeholder='Password'
                  name='password'
                  onChange={handleChange}
                  autoComplete='off'
                />
                <span
                  className='btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
                  
                >
                  {show === false && (
                    // <i className='bi bi-eye-slash fs-2' onClick={() => setShow(true)}></i>
                    <BsFillEyeSlashFill onClick={() => setShow(true)} />
                  )}
                  {show === true && (
                    <BsFillEyeFill onClick={() => setShow(false)} />
                    // <i className='bi bi-eye fs-2 d-none' onClick={() => setShow(false)}></i>
                  )}
                  {/* <i className='bi bi-eye-slash fs-2'></i>
              <i className='bi bi-eye fs-2 d-none'></i> */}
                </span>
              </div>
              <div
                className='d-flex align-items-center mb-3'
                data-kt-password-meter-control='highlight'
              >
                <div className={`flex-grow-1 ${flg==1?'bg-danger':flg==2?'bg-warning':flg==3?'bg-success':'bg-secondary'} bg-active-success rounded h-5px me-2`}></div>
                <div className={`flex-grow-1 ${flg==1?'bg-secondary':flg==2?'bg-warning':flg==3?'bg-success':'bg-secondary'} bg-active-success rounded h-5px me-2`}></div>
                {/* <div className='flex-grow-1 bg-warning bg-active-success rounded h-5px me-2'></div> */}
                <div className={`flex-grow-1 ${flg==1?'bg-secondary':flg==2?'bg-secondary':flg==3?'bg-success':'bg-secondary'} bg-active-success rounded h-5px`}></div>
              </div>
            </div>
            <div className='text-muted'>
              Use 8 or more characters with a mix of letters, numbers & symbols. Ex:- Emad@123$
            </div>
          </div>
          <div className='fv-row mb-8'>
            {/* <input
          type='password'
          placeholder='Repeat Password'
          name='confirm-password'
          autoComplete='off'
          className='form-control bg-transparent'
          onChange={handleChangeCon}
        /> */}
            <div className='mb-1'>
              <div className='position-relative mb-3'>
                <input
                  type={showCon ? 'text ' : 'password'}
                  placeholder='Confirm Password'
                  name='confirm-password'
                  autoComplete='off'
                  className='form-control bg-transparent'
                  onChange={handleChangeCon}
                />
                <span
                  className='btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
                  
                >
                  {showCon === false && (
                    // <i className='bi bi-eye-slash fs-2' onClick={() => setShow(true)}></i>
                    <BsFillEyeSlashFill onClick={() => setShowCon(true)} />
                  )}
                  {showCon === true && (
                    <BsFillEyeFill onClick={() => setShowCon(false)} />
                    // <i className='bi bi-eye fs-2 d-none' onClick={() => setShow(false)}></i>
                  )}
                  {/* <i className='bi bi-eye-slash fs-2'></i>
              <i className='bi bi-eye fs-2 d-none'></i> */}
                </span>
              </div>
            </div>
          </div>
          <div className='fv-row mb-8'>
            <label className='form-check form-check-inline'>
              <input className='form-check-input' type='checkbox' name='toc' value='1' checked={isAgree} onChange={(e) => setIsAgree(e.target.checked)} />
              <span className='form-check-label fw-semibold text-gray-700 fs-6 ms-1'>
                I Agree & Terms and conditions .
              </span>
            </label>
          </div>
          <div className='d-grid mb-10'>
            <button disabled={!isAgree}   className='btn btn-primary' onClick={callsubmit}>
              <span className='indicator-label'>Submit</span>
              <span className='indicator-progress'>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </button>
          </div>
        {/* </form> */}
      </div>
    </>
  )
}

export default ResetPassword
