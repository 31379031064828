import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ApiPost, Bucket, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import submitWhite from '../../../../img/Submit-White.png'
import {useParams} from 'react-router-dom'
import ModalLoader from '../../General/ModalLoader'
import tenantGreen from '../../../../img/tenant.png'
import tenantWhite from '../../../../img/tenant-white.png'
import vendorGreen from '../../../../img/external-team-green.png'
import vendorWhite from '../../../../img/external-team-white.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {CircularProgress} from '@mui/material'
import noData from '../../../../img/NoData1.svg'
import moment from 'moment'

interface ButtonProps {
  show: any
  handleClose: any
  updateDetails?: any
  expData: any
}

const SelectPayeeModal = ({show, handleClose, updateDetails, expData}: ButtonProps) => {
  const {id} = useParams()
  const [selectedType, setSelectedType] = useState<any>()
  // console.log(selectedType)

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [isLoading, setIsLoading] = useState<any>(false)

  const [goToNext, setGoToNext] = useState<any>(false)
  const [searchTenant, setSearchTenant] = useState<any>('')
  const [searchVendor, setSearchVendor] = useState<any>('')

  const [tenantList, setTenantList] = useState<any>([])
  const [vendorList, setVendorList] = useState<any>([])

  const [radioChecked, setRadioChecked] = useState<any>(-1)
  const [selectedUser, setSelectedUser] = useState<any>()

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  // console.log(expData)

  const getTenantList = () => {
    const body = {
      search: searchTenant,
      // page: 1,
      // limit: 10,
      buildingId:
        expData?.development?.[0]?.devType == 'build'
          ? expData?.development?.[0]?.value
          : undefined,
      communityId:
        expData?.development?.[0]?.devType == 'comm' ? expData?.development?.[0]?.value : undefined,
    }
    ApiPost('corporate/expense_development/get/tenancy', body)
      .then((res: any) => {
        setTenantList(res?.data?.data)
      })
      .catch((err: any) => {})
  }

  const getVendorList = () => {
    const body = {
      search: searchVendor,
      // page: 1,
      // limit: 10,
    }
    ApiPost('corporate/expense_development/get/vendor', body)
      .then((res: any) => {
        setVendorList(res?.data?.data)
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    if (goToNext == true) {
      if (selectedType == 1) getVendorList()
      else getTenantList()
    }
  }, [goToNext, searchTenant, searchVendor])

  const [isCommonArea, setIsCommonArea] = useState<any>(false)

  useEffect(() => {
    let isCmnArea: any = false
    expData?.items?.map((itm: any, ind: any) => {
      if (Number(itm?.property?.level) == 3) isCmnArea = true
    })

    console.log(isCmnArea)

    setIsCommonArea(isCmnArea)
  }, [expData])

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body px-lg-7 py-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '500px',
            // maxHeight: '500px',
            // overflow: 'auto',
          }}
        >
          <div className='d-flex align-items-top'>
            <h2 className=' pt-5 mb-0'>
              <b>Select Payee</b>
            </h2>

            <label
              className='head-text fs-2 cursor-pointer ms-auto'
              style={{fontWeight: '700'}}
              onClick={() => {
                handleClose()
              }}
            >
              X
            </label>
          </div>
          <div className='d-flex align-items-center'>
            <h4 className=' pt-2 mb-0'>
              Select the payee which you are creating this expense for:
            </h4>

            {goToNext && (
              <button
                type='button'
                className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3 d-flex justify-content-center status-w-175 ms-auto'
                onClick={(e: any) => {
                  updateDetails(selectedType, selectedUser)
                  handleClose()
                }}
                disabled={radioChecked == -1}
              >
                {/* <img src={submitImg} height={19} width={19} className='me-4' /> {'  '} */}
                Select
              </button>
            )}
          </div>

          {!goToNext ? (
            <div className='d-flex justify-content-evenly mt-10  ' >
              {/* Tenant*/}
              <div>
              <div className='position-relative'>
              {isCommonArea && (
                          <div className='tenantOpacityBox' ></div>
                )}
                <div
                  className={`${selectedType == 0 ? 'mainRequestBoxColor' : 'mainRequestBox'} mt-3`}
                  onClick={() => {
                    let values: any = {...expData}
                    let isCommonArea: any = false
                    expData?.items?.map((itm: any, ind: any) => {
                      if (Number(itm?.property?.level) == 3) isCommonArea = true
                    })
                    if (isCommonArea)
                      ErrorToast(
                        'This expense includes a common area and cannot be expensed to a tenant.'
                      )
                    else setSelectedType(0)
                  }}
                  style={{height: '130px', width: '130px', borderRadius: '5px', padding: '10px'}}
                >
                  <h2 className={`head-text mb-3 ${selectedType == 0 ? 'headTextWhite' : ''}`}>
                    Tenant
                  </h2>
                  <img
                    src={selectedType == 0 ? tenantWhite : tenantGreen}
                    height={75}
                    width={75}
                    className='d-block'
                  />
                </div>
                </div>
                {isCommonArea && (
                  <div className="d-flex justify-content-center text-center">
                      <p
                        style={{color: 'var(--kt-danger)', gridColumn: '2/2', width: '130px'}}
                        className='m-0 mt-1'
                      >
                      This expense includes a common area and cannot be expensed to a tenant.
                      </p>
                      </div>
                    )}

                {selectedType == 0 && (
                  <button
                    type='button'
                    className=' mt-4 btn btn-sm fw-bold green-submit-btn w-100 d-flex justify-content-center status-w-130'
                    onClick={() => {
                      setGoToNext(true)
                    }}
                  >
                    Next
                  </button>
                )}
              </div>

              {/* Vendor*/}
              <div>
                <div
                  className={` ${
                    selectedType == 1 ? 'mainRequestBoxColor' : 'mainRequestBox'
                  } mt-3`}
                  onClick={() => {
                    setSelectedType(1)
                  }}
                  style={{height: '130px', width: '130px', borderRadius: '5px', padding: '10px'}}
                >
                  <h2 className={`head-text mb-3 ${selectedType == 1 ? 'headTextWhite' : ''}`}>
                    Vendor
                  </h2>
                  <img
                    src={selectedType == 1 ? vendorWhite : vendorGreen}
                    height={75}
                    width={75}
                    className='d-block'
                  />
                </div>

                {selectedType == 1 && (
                  <button
                    type='button'
                    className=' mt-4 btn btn-sm fw-bold green-submit-btn w-100 d-flex justify-content-center status-w-130'
                    onClick={() => {
                      setGoToNext(true)
                    }}
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div>
              <div className='d-flex align-items-center mt-3 mb-2'>
                <div className='d-block'>
                  <h3
                    className='pt-5 mb-0 cursor-pointer'
                    onClick={() => {
                      setGoToNext(false)
                    }}
                  >
                    <b>{'<'} Back</b>
                  </h3>
                </div>
              </div>

              {selectedType == 0 ? (
                <>
                  <div className='d-flex align-items-center px-0'>
                    <h3 className=' mb-0'>
                      <b>Tenants</b>
                    </h3>
                    <label className='head-text ms-auto status-w-100' style={{fontWeight: '600'}}>
                      {' '}
                      Search{' '}
                    </label>

                    <div className='mb-2 xyz '>
                      <Paper component='form' style={searchContainerStyle}>
                        <InputBase
                          placeholder='Tenant'
                          style={inputBaseStyle}
                          inputProps={{'aria-label': 'search'}}
                          value={searchTenant}
                          onChange={(e: any) => setSearchTenant(e.target.value)}
                        />
                        <IconButton style={searchIconStyle} aria-label='search'>
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </div>
                  </div>
                  <div className='row mt-3'>
                    <div className='col-12 pe-0'>
                      <div className=''>
                        <div
                          className='table-responsive'
                          style={{maxHeight: '400px', overflow: 'auto'}}
                        >
                          <table
                            className='table align-start fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                                <th className='text-start w-25px test'></th>
                                <th className='text-start min-w-125px'>Tenant</th>
                                <th className='text-start min-w-150px'>Development</th>
                                <th className='text-start min-w-125px'>Unit</th>
                                <th className='text-start min-w-150px'>Type</th>
                                <th className='text-center min-w-125px'>Lease Status</th>
                                <th className='text-start min-w-175px'>Lease Term</th>
                                <th className='text-start min-w-100px'>Contract No.</th>
                              </tr>
                            </thead>

                            <tbody className='fw-semibold text-gray-600'>
                              {tenantList?.length > 0 ? (
                                <>
                                  {tenantList?.map((rs: any, index: any) => {
                                    return (
                                      <tr>
                                        <td className='text-start w-25px  test ps-0'>
                                          {radioChecked === rs?._id ? (
                                            <div
                                              style={{
                                                height: '16px',
                                                width: '16px',
                                                backgroundColor: '#146c6a',
                                                borderRadius: '50%',
                                              }}
                                              className='cursor-pointer'
                                            ></div>
                                          ) : (
                                            <div
                                              style={{
                                                height: '16px',
                                                width: '16px',
                                                backgroundColor: 'transparent',
                                                borderRadius: '50%',
                                                border: '1px solid #146c6a',
                                              }}
                                              onClick={() => {
                                                setRadioChecked(rs?._id)
                                                setSelectedUser(rs)
                                                // setSelectedName(rs?.name)
                                              }}
                                              className='cursor-pointer'
                                            ></div>
                                          )}
                                        </td>
                                        <td className='text-start min-w-100px'>
                                          {rs?.tenant?.length > 0
                                            ? `${rs?.tenant?.[0]?.firstName} ${rs?.tenant?.[0]?.lastName}`
                                            : '-'}
                                        </td>
                                        <td className='text-start min-w-100px'>
                                          {rs?.community?.length > 0
                                            ? `${rs?.community?.[0]?.name}`
                                            : `${rs?.building?.[0]?.name}`}
                                        </td>

                                        <td className='text-start'>
                                          {rs?.unit?.length > 0 ? `${rs?.unit?.[0]?.unitNo}` : '-'}
                                        </td>
                                        <td className='text-start '>
                                          {rs?.unit?.[0]?.unitType === 0
                                            ? 'Apartment'
                                            : rs?.unit?.[0]?.unitType === 1
                                            ? 'Penthouse'
                                            : rs?.unit?.[0]?.unitType === 2
                                            ? 'Common Area'
                                            : rs?.unit?.[0]?.unitType === 3
                                            ? 'Townhouse'
                                            : rs?.unit?.[0]?.unitType === 4
                                            ? 'Villa'
                                            : rs?.unit?.[0]?.unitType === 5
                                            ? `Other - ${rs?.unit?.[0]?.otherName}`
                                            : rs?.unit?.[0]?.unitType === 6
                                            ? 'Office'
                                            : rs?.unit?.[0]?.unitType === 7
                                            ? 'Restaurant'
                                            : rs?.unit?.[0]?.unitType === 8
                                            ? 'Cafe'
                                            : rs?.unit?.[0]?.unitType === 9
                                            ? 'Retail'
                                            : '-'}
                                        </td>
                                        <td className='text-center '>
                                          <div className='d-flex justify-content-center'>
                                            <div
                                              className={
                                                rs?.tenancyStatus == 0
                                                  ? 'status booked-bar'
                                                  : rs?.tenancyStatus == 1
                                                  ? 'status active-bar  '
                                                  : rs?.tenancyStatus == 2
                                                  ? 'status expiring-bar'
                                                  : rs?.tenancyStatus == 3
                                                  ? 'status expired-bar'
                                                  : 'status  no-st-bar'
                                              }
                                            >
                                              {rs?.tenancyStatus == 0
                                                ? 'Booked'
                                                : rs?.tenancyStatus == 1
                                                ? 'Active'
                                                : rs?.tenancyStatus == 2
                                                ? 'Expiring'
                                                : rs?.tenancyStatus == 3
                                                ? 'Expired'
                                                : '-'}
                                            </div>
                                          </div>
                                        </td>
                                        <td className='text-start '>
                                          {rs?.duration?.start_date
                                            ? `${moment
                                                .utc(rs?.duration?.start_date)
                                                .local()
                                                .format('DD.MM.YYYY')} `
                                            : '-'}
                                          {'-'}
                                          {rs?.duration?.end_date
                                            ? `${moment
                                                .utc(rs?.duration?.end_date)
                                                .local()
                                                .format('DD.MM.YYYY')} `
                                            : '-'}
                                        </td>
                                        <td className='text-start '>
                                          {rs?.contractNo ? `${rs?.contractNo}` : '-'}
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </>
                              ) : (
                                <tr>
                                  {!isLoading ? (
                                    <td colSpan={7} className='text-center'>
                                      <img src={noData} alt='' width={350} />
                                    </td>
                                  ) : (
                                    <td colSpan={7} className='text-center'>
                                      <div
                                        className='d-flex justify-content-center align-items-center w-100'
                                        style={{height: '25vh'}}
                                      >
                                        <CircularProgress style={{color: '#007a59'}} />
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='d-flex align-items-center px-0'>
                    <h3 className=' mb-0'>
                      <b>Vendors</b>
                    </h3>
                    <label className='head-text ms-auto status-w-100' style={{fontWeight: '600'}}>
                      {' '}
                      Search{' '}
                    </label>

                    <div className='mb-2 xyz '>
                      <Paper component='form' style={searchContainerStyle}>
                        <InputBase
                          placeholder='Vendor'
                          style={inputBaseStyle}
                          inputProps={{'aria-label': 'search'}}
                          value={searchVendor}
                          onChange={(e: any) => setSearchVendor(e.target.value)}
                        />
                        <IconButton style={searchIconStyle} aria-label='search'>
                          <SearchIcon />
                        </IconButton>
                      </Paper>
                    </div>
                  </div>

                  <div className='row mt-3'>
                    <div className='col-12 pe-0'>
                      <div className=''>
                        <div
                          className='table-responsive'
                          style={{maxHeight: '380px', overflow: 'auto'}}
                        >
                          <table
                            className='table align-middle  fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className='text-start w-25px test'></th>
                                <th className='text-start min-w-100px'>Company</th>
                                <th className='text-start min-w-100px'>Type</th>
                                <th className='text-start min-w-100px'>TRN</th>
                                <th className='text-start min-w-100px'>Trade License</th>
                              </tr>
                            </thead>

                            <tbody className='fw-semibold text-gray-600'>
                              {vendorList?.length > 0 ? (
                                <>
                                  {vendorList?.map((rs: any, index: any) => {
                                    return (
                                      <tr>
                                        <td className='text-start w-25px  test ps-0'>
                                          {radioChecked === rs?._id ? (
                                            <div
                                              style={{
                                                height: '16px',
                                                width: '16px',
                                                backgroundColor: '#146c6a',
                                                borderRadius: '50%',
                                              }}
                                              className='cursor-pointer'
                                            ></div>
                                          ) : (
                                            <div
                                              style={{
                                                height: '16px',
                                                width: '16px',
                                                backgroundColor: 'transparent',
                                                borderRadius: '50%',
                                                border: '1px solid #146c6a',
                                              }}
                                              onClick={() => {
                                                setRadioChecked(rs?._id)
                                                setSelectedUser(rs)
                                                // setSelectedName(rs?.name)
                                              }}
                                              className='cursor-pointer'
                                            ></div>
                                          )}
                                        </td>
                                        <td className='text-start min-w-100px'>
                                          {rs?.companyName ? rs?.companyName : '-'}
                                        </td>
                                        <td className='text-start min-w-100px'>
                                          <div className='d-flex align-items-center'>
                                            {rs?.vendorType == 0 ? 'Service Provider' : 'Supplier'}
                                          </div>
                                        </td>
                                        <td className='text-start min-w-100px'>
                                          {rs?.TRN ? rs?.TRN : '-'}
                                        </td>
                                        <td className='text-start min-w-100px'>
                                          {rs?.tradeLicenseNo ? rs?.tradeLicenseNo : '-'}
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </>
                              ) : (
                                <tr>
                                  {!isLoading ? (
                                    <td colSpan={4} className='text-center'>
                                      <img src={noData} alt='' width={350} />
                                    </td>
                                  ) : (
                                    <td colSpan={4} className='text-center'>
                                      <div
                                        className='d-flex justify-content-center align-items-center w-100'
                                        style={{height: '25vh'}}
                                      >
                                        <CircularProgress style={{color: '#007a59'}} />
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default SelectPayeeModal
