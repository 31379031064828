import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {CreateAppModal} from '../../../_metronic/partials'
import swal from 'sweetalert2'
import AddBuilding from './AddBuilding'
import {TbChevronDown} from 'react-icons/tb'
import {KTSVG} from '../../../_metronic/helpers'
import noData from '../../../img/NoData1.svg'
import {Dropdown} from 'react-bootstrap'

const Community = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [tableData, setTableData] = useState([])
  const [updateDataId, setUpdateDataId] = useState('')
  const [propertiType, setPropertiType] = useState('Communities')
  const [show, setShow] = useState(false)
  const [showCom, setShowCom] = useState(false)
  const [page, setPage] = useState(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [limit, setLimit] = useState(10)
  const [isEdit, setIsEdit] = useState(false)
  const [formData, setFormData] = useState<any>({
    // portfolioType: 'residential',
    // portfolio: 'building',
  })
  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }

  const building = async (a: any, b: any) => {
    if (propertiType === 'Buildings') {
      const body = {
        page: page,
        limit: 100,
        type: 0,
      }
      await ApiPost('corporate/building/get', body)
        .then((res) => {
          setTableData(res?.data?.data?.building_data)
          setPageLimit(res?.data?.data?.state?.page_limit)
          console.log('res', res)
          // setFormData({
          //   portfolioType: a,
          //   portfolio: b,
          // })
        })
        .catch((e) => {
          console.log(e)
        })
    }
    if (propertiType === 'Communities') {
      const body = {
        page: page,
        limit: 100,
      }
      await ApiPost('corporate/communities/get', body)
        .then((res) => {
          setTableData(res?.data?.data?.communities_data)
          console.log('res', res)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const deleteProperty = (id: any) => {
    if (propertiType === 'Buildings') {
      ApiDelete(`corporate/building/${id}`)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          building(formData?.portfolioType, formData?.portfolio)
        })

        .catch((err) => ErrorToast(err.message))
    } else {
      ApiDelete(`corporate/communities/${id}`)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          building(formData?.portfolioType, formData?.portfolio)
        })

        .catch((err) => ErrorToast(err.message))
    }
  }

  const editBtn = (id: any) => {
    setUpdateDataId(id)
    setIsEdit(true)
    setShowCreateAppModal(true)
    ApiGet(`corporate/communities/${id}`)
      .then((res) => {
        console.log('res', res?.data?.data[0])
        setFormData(res?.data?.data[0])
      })
      .catch((err) => console.log('err', err))
  }

  const handleSubmit = () => {
    if (isEdit) {
      delete formData._id
      delete formData.updatedBy
      delete formData.status
      if (propertiType === 'Buildings') {
        const body = {...formData, managerId: formData?.managerId, id: updateDataId}
        ApiPut('corporate/properties', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building(formData?.portfolioType, formData?.portfolio)
            setIsEdit(false)
            console.log('addProperty', res)
            setShowCreateAppModal(false)
            setFormData({
              portfolioType: formData?.portfolioType,
              portfolio: formData.portfolio,
            })
          })
          .catch((err) => ErrorToast(err.message))
      } else {
        const body = {
          name: formData?.name,
          city: formData?.city,
          street: formData?.street,
          area: formData?.area,
          totalFloors: 0,
          totalClusters: 0,
          unitClusters: formData?.totalZones,
          buildingClusters: formData?.totalBuildings,
          mixedClusters: 0,
          managerId: formData?.managerId,
          id: updateDataId,
        }
        ApiPut('corporate/communities', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building(formData?.portfolioType, formData?.portfolio)
            setIsEdit(false)
            console.log('addProperty', res)
            setShowCreateAppModal(false)
            setFormData({
              portfolioType: formData?.portfolioType,
              portfolio: formData.portfolio,
            })
          })
          .catch((err) => ErrorToast(err.message))
      }
    } else {
      if (propertiType === 'Buildings') {
        // const body = {...formData, managerId: formData?.managerId}
        const body = {
          name: formData?.name,
          managerId: formData?.managerId,
          type: 0,
          totalFloors: formData?.totalFloors,
          city: formData?.city,
          street: formData?.street,
          area: formData?.area,
        }
        ApiPost('corporate/building', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building(formData?.portfolioType, formData?.portfolio)
            console.log('addProperty', res)
            setShowCreateAppModal(false)
            setFormData({
              portfolioType: formData?.portfolioType,
              portfolio: formData.portfolio,
            })
          })
          .catch((err) => ErrorToast(err.message))
      } else {
        // const body = {...formData, managerId: formData?.managerId}
        const body = {
          name: formData?.name,
          city: formData?.city,
          street: formData?.street,
          area: formData?.area,
          totalFloors: 0,
          totalClusters: 0,
          unitClusters: formData?.totalZones,
          buildingClusters: formData?.totalBuildings,
          mixedClusters: 0,
          managerId: formData?.managerId,
        }
        console.log('body', body)
        ApiPost('corporate/communities', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building(formData?.portfolioType, formData?.portfolio)
            console.log('addProperty', res)
            setShowCreateAppModal(false)
            setFormData({
              portfolioType: formData?.portfolioType,
              portfolio: formData.portfolio,
            })
          })
          .catch((err) => ErrorToast(err.message))
      }
    }
  }
  const handleSubmitCom = () => {
    if (isEdit) {
      delete formData._id
      delete formData.updatedBy
      delete formData.status
      const body = {...formData, managerId: '6329ee43396e812bcc0964e5', id: updateDataId}
      ApiPut('corporate/properties', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building(formData?.portfolioType, formData?.portfolio)
          setIsEdit(false)
          console.log('addProperty', res)
          setShowCreateAppModal(false)
          setFormData({
            portfolioType: formData?.portfolioType,
            portfolio: formData.portfolio,
          })
        })
        .catch((err) => ErrorToast(err.message))
    } else {
      // const body = {...formData, managerId: '6329ee43396e812bcc0964e5'}
      const body = {
        portfolioType: formData?.portfolioType,
        portfolio: formData?.portfolio,
        name: formData?.name,
        city: formData?.city,
        location: formData?.location,
        street: formData?.street,
        totalZones: formData?.totalZones,
        totalBuildings: formData?.totalBuildings,
        managerId: '632aa52cacdb90269c4655d5',
      }
      console.log('formData', formData)
      ApiPost('corporate/properties', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building(formData?.portfolioType, formData?.portfolio)
          console.log('addProperty', res)
          setShowCreateAppModal(false)
          setFormData({
            portfolioType: formData?.portfolioType,
            portfolio: formData.portfolio,
          })
        })
        .catch((err) => ErrorToast(err.message))
    }
  }

  useEffect(() => {
    building(page, limit)
  }, [propertiType, page])

  useEffect(() => {
    building(page, limit)

    if (isEdit) {
      ApiGet(`corporate/properties/${updateDataId}`)
        .then((response) => {
          console.log('response', response)
          setFormData(response?.data?.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [propertiType, page])

  const navigate = useNavigate()
  return (
    <>
      <div
        className='app-container container-xxl mx-auto d-flex gap-5 flex-column p-0 m-0'
        id='kt_app_main'
      >
        <div className='d-flex align-items-center gap-5 justify-content-center mb-5'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li
              className='nav-item'
              style={{fontSize: '15px'}}
              onClick={() => {
                setFormData({...formData, portfolio: 'building'})
                building(formData?.portfolioType, 'building')
                navigate('/building')
              }}
            >
              <a className='nav-link text-active-primary pb-4  cursor-pointer' data-bs-toggle='tab'>
                Buildings
              </a>
            </li>
            <li
              className='nav-item '
              style={{fontSize: '15px'}}
              onClick={() => {
                setFormData({...formData, portfolio: 'communities'})
                building(formData?.portfolioType, 'communities')
                navigate('/Community')
              }}
            >
              <a
                className='nav-link text-active-primary pb-4 cursor-pointer active'
                data-bs-toggle='tab'
              >
                Communities
              </a>
            </li>
          </ul>
        </div>
        <div className='card p-5 text-muted gap-5'>
          <h1 className='m-0 green_color'>Developments</h1>
          <div
            className='d-flex align-items-center gap-5 my-auto'
            style={{justifyContent: 'space-between'}}
          >
            <div className='d-flex gap-5 align-items-center '>
              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                <input
                  className='form-check-input'
                  type='radio'
                  value='residential'
                  id='residential'
                  name='portfolioType'
                  checked={formData?.portfolioType == 'residential'}
                  onChange={() => {
                    setFormData({...formData, portfolioType: 'residential'})
                    building('residential', formData?.portfolio)
                  }}
                />
                <label className='form-check-label' htmlFor='residential'>
                  Residential
                </label>
              </div>
              <div className='form-check form-check-custom form-check-solid form-check-sm'>
                <input
                  className='form-check-input'
                  type='radio'
                  value='commercial'
                  id='commercial'
                  name='portfolioType'
                  checked={formData?.portfolioType == 'commercial'}
                  onChange={() => {
                    setFormData({...formData, portfolioType: 'commercial'})
                    building('commercial', formData?.portfolio)
                  }}
                />
                <label className='form-check-label' htmlFor='commercial'>
                  Commercial
                </label>
              </div>
            </div>
            <div>
              {propertiType === 'Buildings' && (
                <div className=''>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_app'
                    onClick={() => {
                      setShowCreateAppModal(true)
                    }}
                  >
                    Add Building
                  </a>
                </div>
              )}
              {propertiType === 'Communities' && (
                <div className=''>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green'
                    data-bs-toggle='modal'
                    data-bs-target='#kt_modal_create_app'
                    onClick={() => {
                      setShowCreateAppModal(true)
                    }}
                  >
                    Add Community
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        {show && propertiType === 'Buildings' && (
          <>
            <div className='card grid-out-diff-gap p-5'>
              <h3 className='green_color tops'>ADD NEW BUILDINGS</h3>
              <div className='side py-3'>
                <h6 className='text-muted mx-0 align-items-center'>
                  Building Name
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='name'
                      value={formData?.name}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Total Floor
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='name'
                      value={formData?.name}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Building Manager
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='managerId'
                      value={formData?.managerId}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
              </div>
              <div className='side py-3 '>
                <h6 className='text-muted mx-0 align-items-center'>
                  Location
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='location'
                      value={formData?.location}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Street
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='street'
                      value={formData?.street}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  City
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='city'
                      value={formData?.city}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
              </div>
              <div className='d-flex justify-content-end mb-4 px-4 tops'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={() => setShow(false)}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1 '
                    />{' '}
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary btn-green'
                    data-kt-stepper-action='submit'
                    onClick={handleSubmit}
                  >
                    Submit{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {showCom && propertiType === 'Communities' && (
          <>
            <div className='card p-5 text-muted'>
              <h3 className='text-green tops'>ADD NEW COMMUNITIES</h3>
              <div className='side py-3 px-5'>
                <h6 className='text-muted mx-0 align-items-center'>
                  Communities Name
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='name'
                      value={formData?.name}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  City
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='city'
                      value={formData?.city}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Location
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='location'
                      value={formData?.location}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Street
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='street'
                      value={formData?.street}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
              </div>
              <div className='side py-3 px-5'>
                <h6 className='text-muted mx-0 align-items-center'>
                  Community Manager
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='managerId'
                      value={formData?.managerId}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Total Zones
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='totalZones'
                      value={formData?.totalZones}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
                <h6 className='text-muted mx-0 align-items-center'>
                  Total Buildings
                  <div className='mx-1 value_black'>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='totalBuildings'
                      value={formData?.totalBuildings}
                      onChange={handleChnage}
                    />
                  </div>
                </h6>
              </div>

              <div className='d-flex justify-content-end mb-4 px-4 tops'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={() => setShowCom(false)}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1 '
                    />
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary btn-green'
                    data-kt-stepper-action='submit'
                    onClick={handleSubmitCom}
                  >
                    Submit
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        <div className='card p-5'>
          <div className='table-responsive mb-5'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5 '
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  {/* <th className='w-10px pe-2'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            data-kt-check='true'
                            data-kt-check-target='#kt_ecommerce_sales_table .form-check-input'
                            value='1'
                          />
                        </div>
                      </th> */}
                  <th className='text-center min-w-100px'>#</th>
                  <th className='text-center min-w-150px'>
                    {propertiType === 'Buildings' ? 'BUILDING NAME' : ' COMMUNITY NAME'}
                  </th>
                  <th className='text-center min-w-150px'>
                    {' '}
                    {propertiType === 'Buildings' ? 'TOTAL FLOORS' : ' TOTAL CLUSTERS'}
                  </th>
                  <th className='text-center min-w-150px'>
                    {propertiType === 'Buildings' ? 'TOTAL PROPERTIES' : ' UNIT CLUSTERS'}
                  </th>
                  {propertiType === 'Buildings' ? (
                    ''
                  ) : (
                    <>
                      <th className='text-center min-w-150px'>BUILDING CLUSTERS</th>
                      <th className='text-center min-w-150px'>MIXED CLUSTERS</th>
                      <th className='text-center min-w-150px'>TOTAL PROPERTIES</th>
                    </>
                  )}
                  <th className='text-center min-w-200px'>
                    {propertiType === 'Buildings' ? 'BUILDING MANAGER' : ' COMMUNITY MANAGER'}
                  </th>
                  <th className='text-center min-w-100px'></th>

                  <th className='text-end min-w-100px'></th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-800'>
                {tableData?.length ? (
                  tableData?.map((v: any, i: any) => {
                    return (
                      <tr>
                        {/* <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input className='form-check-input' type='checkbox' value='1' />
                        </div>
                      </td> */}
                        {propertiType === 'Buildings' ? (
                          <>
                            {' '}
                            <td className='text-center'>{i + 1}</td>
                            <td className='text-center' data-kt-ecommerce-order-filter='order_id'>
                              {v?.name}
                            </td>
                            <td className='text-center'>{v?.totalFloors}</td>
                            <td className='text-center'>{v?.totalProperties}</td>
                            <td className='text-center'>{v?.managerId}</td>
                          </>
                        ) : (
                          <>
                            {' '}
                            <td className='text-center'>{i + 1}</td>
                            <td className='text-center' data-kt-ecommerce-order-filter='order_id'>
                              {v?.name}
                            </td>
                            <td className='text-center'>{v?.totalClusters}</td>
                            <td className='text-center'>{v?.unitClusters}</td>
                            <td className='text-center'>{v?.buildingClusters}</td>
                            <td className='text-center'>{v?.mixedClusters}</td>
                            <td className='text-center'>{v?.totalProperties}</td>
                            <td className='text-center'>{v?.managerId}</td>
                          </>
                        )}
                        <td className='text-center'>
                          <div className='menu-item p-0'>
                            {/* <a
                                onClick={() => navigate(`/area/${v?._id}`)}
                                className='btn btn-sm fw-bold btn-primary btn-green'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_create_app'
                              >
                                Area
                              </a> */}
                            <div
                              onClick={() => {
                                propertiType === 'Communities'
                                  ? navigate(`/areaCom/${v?._id}`)
                                  : navigate(`/area/${v?._id}`)
                              }}
                              className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                            >
                              {propertiType === 'Communities' ? 'Clusters' : 'Floors'}
                            </div>
                          </div>
                        </td>

                        <td className='text-end' style={{justifyContent: 'end', border: 'none'}}>
                          <Dropdown className='' style={{width: 'fit-content'}}>
                            <Dropdown.Toggle
                              variant='success'
                              id='dropdown-basic'
                              className='btn btn-sm btn-light btn-active-light-primary text-hover-green'
                            >
                              Actions <TbChevronDown />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                href='#/action-1'
                                // onClick={() => {
                                //   console.log('clicked')

                                //   setUpdateDataId(v?._id)
                                //   setIsEdit(true)
                                //   setShowCreateAppModal(true)
                                // }}
                                onClick={() => editBtn(v?._id)}
                                className='menu-link px-3'
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                href='#/action-2'
                                onClick={() => {
                                  swal
                                    .fire({
                                      text: 'Are you sure you want to permanently delete this community? Deleting this community will delete all the data registered under the community.',
                                      icon: 'warning',
                                      showConfirmButton: true,
                                      confirmButtonColor: '#D72852',
                                      confirmButtonText: 'Yes',
                                      showCancelButton: true,
                                      // cancelButtonColor: "transparent",
                                      cancelButtonText: 'Cancel',
                                    })
                                    .then((res) => {
                                      if (res.isConfirmed) {
                                        deleteProperty(v?._id)
                                      }
                                    })
                                }}
                                className='menu-link px-3'
                                data-kt-ecommerce-order-filter='delete_row'
                              >
                                Delete{' '}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={propertiType === 'Buildings' ? 7 : 10} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className='row mb-6'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
              {/* <div className='dataTables_length' id='kt_ecommerce_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_sales_table_length'
                    aria-controls='kt_ecommerce_sales_table'
                    className='form-select form-select-sm form-select-solid'
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div> */}
            </div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ul className='pagination'>
                  <li
                    className='paginate_button page-item previous disabled'
                    id='kt_ecommerce_sales_table_previous'
                  >
                    <a
                      href='#'
                      aria-controls='kt_ecommerce_sales_table'
                      data-dt-idx='0'
                      tabIndex={0}
                      className='page-link'
                    >
                      <i className='previous'></i>
                    </a>
                  </li>
                  <li className={`paginate_button page-item + ${page === 1 ? 'active' : ''}`}>
                    <a
                      href='#'
                      aria-controls='kt_ecommerce_sales_table'
                      data-dt-idx='1'
                      tabIndex={0}
                      className='page-link'
                      onClick={() => setPage(1)}
                    >
                      1
                    </a>
                  </li>
                  {pageLimit >= 2 && (
                    <li className={`paginate_button page-item + ${page === 2 ? 'active' : ''}`}>
                      <a
                        href='#'
                        aria-controls='kt_ecommerce_sales_table'
                        data-dt-idx='2'
                        tabIndex={0}
                        className='page-link'
                        onClick={() => setPage(2)}
                      >
                        2
                      </a>
                    </li>
                  )}
                  {pageLimit >= 3 && (
                    <li className={`paginate_button page-item + ${page === 3 ? 'active' : ''}`}>
                      <a
                        href='#'
                        aria-controls='kt_ecommerce_sales_table'
                        data-dt-idx='3'
                        tabIndex={0}
                        className='page-link'
                        onClick={() => setPage(3)}
                      >
                        3
                      </a>
                    </li>
                  )}
                  {pageLimit >= 4 && (
                    <li className={`paginate_button page-item + ${page === 4 ? 'active' : ''}`}>
                      <a
                        href='#'
                        aria-controls='kt_ecommerce_sales_table'
                        data-dt-idx='4'
                        tabIndex={0}
                        className='page-link'
                        onClick={() => setPage(4)}
                      >
                        4
                      </a>
                    </li>
                  )}
                  {pageLimit >= 5 && (
                    <li className={`paginate_button page-item + ${page === 5 ? 'active' : ''}`}>
                      <a
                        href='#'
                        aria-controls='kt_ecommerce_sales_table'
                        data-dt-idx='5'
                        tabIndex={0}
                        className='page-link'
                        onClick={() => setPage(5)}
                      >
                        5
                      </a>
                    </li>
                  )}
                  <li className='paginate_button page-item next' id='kt_ecommerce_sales_table_next'>
                    <a
                      href='#'
                      aria-controls='kt_ecommerce_sales_table'
                      data-dt-idx='6'
                      tabIndex={0}
                      className='page-link'
                    >
                      <i className='next'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {showCreateAppModal && (
        <AddBuilding
          show={showCreateAppModal}
          handleClose={() => {
            setShowCreateAppModal(false)
            setIsEdit(false)
          }}
          building={building}
          handleChnage={handleChnage}
          updateDataId={updateDataId}
          isEdit={isEdit}
          propertiType={propertiType}
          formData={formData}
          onSubmit={handleSubmit}
        />
      )}
    </>
  )
}

export default Community
