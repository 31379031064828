import React from 'react'
import {formatAmount} from '../../../Utilities/utils'

interface CustomCardProps {
  title?: string
  currency?: string
  imageUrl?: string
  totalAmount: number
  isAmount: boolean
}

const CustomCard: React.FC<CustomCardProps> = ({
  title,
  currency,
  imageUrl,
  totalAmount,
  isAmount,
}) => {
  return (
    <div
      className='customCard py-4 px-6 shadow  property-insight-shadow'
      style={{width: '16rem', height: '110px'}}
    >
      <h4 className='text-dark-500 mb-3'>{title}</h4>
      <div className={`d-flex justify-content-start align-items-center ${!isAmount ? 'mt-5' : ''}`}>
        <img
          src={imageUrl}
          height={!isAmount ? 40 : 35}
          width={!isAmount ? 40 : 35}
          className='me-3'
          alt='total invoice'
        />
        {isAmount ? (
          <h3 className='fw-bold ms-auto mt-5'>{currency}</h3>
        ) : (
          <h2 className='fw-bold ms-auto mt-5'>{formatAmount(totalAmount)}</h2>
        )}
      </div>
      <div className='d-flex justify-content-start align-items-center'>
        {isAmount && (
          <h3 className='fnt-500 ms-auto mt-1 d-flex'>
            {totalAmount !== 0 ? <>{`${formatAmount(totalAmount)}`}</> : <>0</>}
          </h3>
        )}
      </div>
    </div>
  )
}

export default CustomCard
