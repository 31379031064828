import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import noData from '../../../../../img/NoData1.svg'
import {ApiGet, ApiPost, Bucket} from '../../../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import plus from '../../../../../img/add-white.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import trashImg from '../../../../../img/trash.png'
import InvoiceImg from '../../../../../img/downDoubleArrow.png'
import totalInvoicesImg from '../../../../../img/totalInvoice.png'
import upcomingImg from '../../../../../img/upcoming-expense.png'
import overdueImg from '../../../../../img/overdue-expense.png'
import paidImg from '../../../../../img/paid-expense.png'
import vatImg from '../../../../../img/vatIcon.png'
import Filters from '../Filters'
import {SuccessToast, ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import swal from 'sweetalert2'
import openImg from '../../../../../img/black-collap-down.png'
import closeImg from '../../../../../img/black-collap-up.png'
import CustomCard from '../../../General/CustomCard'
import {formatAmount, invertOutstandingAmount} from '../../../../../Utilities/utils'
import ReceiptsPopup from './Invoices/ReceiptsPopup'

function InvoicesTab() {
  const [searchInvoice, setSearchInvoice] = useState<any>('')
  const [searchTenant, setSearchTenant] = useState<any>('')
  const [searchAccount, setSearchAccount] = useState<any>('')
  const [isLoading, setIsLoading] = useState<any>(false)

  const navigate = useNavigate()

  const tableRef = useRef<HTMLTableElement | null>(null)

  const [invoices, setInvoices] = useState<any>()
  const [show, setShow] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)

  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)
  const [limit, setLimit] = useState<any>(25)

  const [startDate, setStartDate] = useState<any>(new Date())
  const [endDate, setEndDate] = useState<any>(new Date())

  const [checked, setChecked] = useState<any>(null)

  const [incomesCardCount, setIncomesCardCount] = useState<any>()

  const getPositive = (number: any) => {
    // if number is less than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [checkedValues, setCheckedValues] = useState<any>([])
  let totalSelected: any = 0

  //
  const goToInvoice = (data: any) => {
    if (data?.formStatus == 0) navigate(`/finance-income/add-invoice/2/${data?._id}`)
    else navigate(`/finance-income/view-invoice/${data?._id}`)
  }

  //
  const getAllInvoices = (data: any, pageVal: any) => {
    let body: any
    if (data == null) {
      body = {
        page: pageVal,
        limit: limit,
        searchInvoice: searchInvoice,
        searchTenant: searchTenant,
        searchPaymentAccount: searchAccount,
      }
    } else {
      let values: any = {...data}
      values.page = pageVal
      values.limit = limit
      values.searchInvoice = searchInvoice
      values.searchTenant = searchTenant
      values.searchPaymentAccount = searchAccount
      body = values
    }

    ApiPost(`corporate/payment_invoice/get`, body)
      .then((res) => {
        setInvoices(res?.data?.data?.payment_invoice_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        let values: any = []
        let showValues = [...show]
        for (let i = 0; i < res?.data?.data?.payment_invoice_data?.length; i++) {
          values[i] = false
          showValues[i] = false
        }

        setCheckedValues(values)
        setShow(showValues)
      })
      .catch((err) => console.log('err', err))
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    getAllInvoices(null, event.selected + 1)
  }

  //
  const [tabsInsights, setTabsInsights] = useState<any>([
    {
      title: 'Invoices',
      icon: InvoiceImg,
      value: 0,
      currency: 'AED',
      isAmount: false,
    },
    {
      title: 'Total',
      icon: totalInvoicesImg,
      value: 0,
      currency: 'AED',
      isAmount: true,
    },
    {
      title: 'Paid',
      icon: paidImg,
      value: 0,
      currency: 'AED',
      isAmount: true,
    },
    {
      title: 'Upcoming',
      icon: upcomingImg,
      value: 0,
      currency: 'AED',
      isAmount: true,
    },
    {
      title: 'Overdue',
      icon: overdueImg,
      value: 0,
      currency: 'AED',
      isAmount: true,
    },
    {
      title: 'VAT Returns',
      icon: vatImg,
      value: 0,
      currency: 'AED',
      isAmount: true,
    },
  ])

  //
  const getInvoicesCount = async () => {
    const body = {
      searchInvoice: '',
      searchTenant: '',
    }

    try {
      const res = await ApiPost(`corporate/payment_invoice/get_count`, body)

      const {
        totalInvoices,
        totalAmount,
        totalPaidAmount,
        totalUpcomingPayment,
        totalOverdueAmount,
        totalVATAmount,
      } = res?.data?.data || {}

      const createInsight = (title: string, icon: string, value: number, isAmount: boolean) => ({
        title,
        icon,
        value,
        currency: 'AED',
        isAmount,
      })

      const values = [
        createInsight('Invoices', InvoiceImg, totalInvoices, false),
        createInsight('Total', totalInvoicesImg, totalAmount, true),
        createInsight('Paid', paidImg, totalPaidAmount, true),
        createInsight('Upcoming', upcomingImg, totalUpcomingPayment, true),
        createInsight('Overdue', overdueImg, totalOverdueAmount, true),
        createInsight('VAT Returns', vatImg, totalVATAmount, true),
      ]

      setTabsInsights(values)
    } catch (err) {
      console.error('Failed to fetch invoice counts', err)
    }
  }

  //
  useEffect(() => {
    setPage(1)
    getAllInvoices(null, 1)
    getInvoicesCount()
  }, [searchInvoice, searchTenant, searchAccount])

  const [showReceipts, setShowReceipts] = useState<any>(false)
  const [selectedReceiptss, setSelectedReceipts] = useState<any>([])

  return (
    <>
      <div id='' className=' pt-0 mt-0 no-print'>
        <>
          {/* Insights */}
          <div className='d-flex flex-lg-wrap flex-xl-nowrap mt-5' style={{gap: '20px'}}>
            {tabsInsights?.map((tb: any, ind: number) => {
              return (
                <CustomCard
                  title={tb?.title}
                  currency={tb?.currency}
                  imageUrl={tb?.icon}
                  totalAmount={tb?.value}
                  isAmount={tb?.isAmount}
                />
              )
            })}
          </div>

          <div className='d-flex align-items-center py-5 pt-2 gy-3 row mx-0 mt-4'>
            <div className='mb-2 col-md-1 pe-5 xyz'>
              <label className='head-text' style={{fontWeight: '600'}}>
                {' '}
                Search{' '}
              </label>
            </div>
            <div className='mb-2 col-md-2 pe-5 property xyz tst'>
              <Paper component='form' style={searchContainerStyle} onSubmit={(e) => e.preventDefault()}>
                <InputBase
                  placeholder='Invoice'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchInvoice}
                  onChange={(e: any) => setSearchInvoice(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

            <div className='mb-2 col-md-2 ps-5 pe-5 property xyz tst'>
              <Paper component='form' style={searchContainerStyle} onSubmit={(e) => e.preventDefault()}>
                <InputBase
                  placeholder='Payor'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchTenant}
                  onChange={(e: any) => setSearchTenant(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
            <div className='mb-2 col-md-2 ps-5 pe-5 property xyz tst'>
              <Paper component='form' style={searchContainerStyle} onSubmit={(e) => e.preventDefault()}>
                <InputBase
                  placeholder='Account'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchAccount}
                  onChange={(e: any) => setSearchAccount(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

            {checkedValues?.length > 0 && (
              <>
                {checkedValues?.map((ch: any, ind: any) => {
                  if (ch == true) totalSelected = totalSelected + 1
                  return <></>
                })}
              </>
            )}

            <div className='mb-2 col-md-5 xyz ms-auto pe-0 text-end'>
              <div className='d-flex justify-content-end align-items-center'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold green-submit-btn btn-fit-content ps-2'
                  onClick={() => {
                    navigate('/finance-income/add-invoice/1') // 1 for no status
                  }}
                >
                  <img src={plus} height={18} width={18} className='me-3' /> Raise Invoice
                </button>

                <div className='mb-2 col-md-1 pe-5 xyz pt-1'>
                  <div
                    className='ok-div d-flex align-items-center justify-content-center cursor-pointer mx-3'
                    // onClick={() => setShowFilter(true)}
                  >
                    <svg
                      style={{
                        height: '20px',
                        width: '28px',
                        marginLeft: '3px',
                      }}
                      viewBox='0 0 1024 1024'
                      xmlns='http://www.w3.org/2000/svg'
                      className='me-1'
                    >
                      <path
                        fill='#ffff'
                        d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card card-flush gap-5 p-5 pt-1 pe-2'>
            <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5 ps-0' id='global-table'>
              <table
                className='table align-start table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='w-25px ps-0'></th>
                    <th className='min-w-100px ps-0'>Invoice</th>
                    <th className='min-w-150px '>Payor</th>
                    <th className='min-w-125px'>Amount</th>
                    <th className='min-w-125px text-center'>Category</th>
                    <th className='min-w-100px'>Due Date</th>
                    <th className='min-w-125px text-center'>Status</th>
                    <th className='min-w-125px '>Receipt</th>
                    <th className='min-w-150px'>Paid Amount</th>
                    <th className='min-w-150px'>Last Paid Date</th>
                    <th className='min-w-150px'>Outstanding</th>
                    <th className='min-w-150px'>Development</th>
                    <th className='min-w-100px'>Unit No.</th>
                    <th className='min-w-150px'>Unit Category</th>
                    <th className='min-w-125px'>Contract No.</th>
                    <th className='min-w-150px'>Lease Value</th>
                    <th className='min-w-150px'>Payment Account</th>
                    <th className='text-center min-w-150px px-3'>Form Status</th>
                    <th className='text-start min-w-150px px-3'>Created By</th>
                    <th className='text-start min-w-150px px-3'>Last Updated</th>
                  </tr>
                </thead>
                <tbody className='fw-semibold head-text'>
                  {' '}
                  {/* className='fw-semibold text-gray-600'*/}
                  {invoices?.length > 0 ? (
                    <>
                      {invoices.map((inv: any, ind: any) => {
                        return (
                          <>
                            <tr
                              style={{
                                borderBottomStyle:
                                  show[ind] === true
                                    ? 'none'
                                    : show[ind] === false || inv?.paymentSubItems?.length === 0
                                    ? 'dashed'
                                    : undefined,
                              }}
                              className='cursor-pointer'
                            >
                              <td className='ps-0'>
                                <div className='d-flex text-decoration-underline'>
                                  {inv?.paymentSubItems?.length > 0 && (
                                    <img
                                      src={show[ind] ? closeImg : openImg}
                                      height='15'
                                      width='15'
                                      className='cursor-pointer me-3'
                                      // style={{marginLeft: '-30px', marginRight: '15px'}}
                                      onClick={() => {
                                        if (show[ind] == true) {
                                          let values = [...show]
                                          values[ind] = false
                                          setShow(values)
                                        } else {
                                          let values = [...show]

                                          values[ind] = true

                                          setShow(values)
                                        }
                                      }}
                                    />
                                  )}
                                </div>
                              </td>
                              <td
                                className='ps-0 text-decoration-underline cursor-pointer'
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                <b>{inv?.invoiceNo ? inv?.invoiceNo : '-'}</b>
                              </td>
                              <td
                                className='cursor-pointer'
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                {inv?.tenant?.length > 0
                                  ? `${inv?.tenant?.[0]?.firstName} ${inv?.tenant?.[0]?.lastName}`
                                  : '-'}
                              </td>
                              <td
                                className='cursor-pointer'
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                {inv?.totalAmount > 0 ? (
                                  <>AED {formatAmount(inv?.totalAmount)}</>
                                ) : (
                                  <>AED 0</>
                                )}
                              </td>
                              <td
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                <div
                                  className={`status status-w-100
                                   ${inv?.dueDate == null
                                    ? 'no-st-bar'
                                    : (inv?.totalAmount == 0 && inv?.outstandingAmount == 0 && inv?.totalPaidAmount == 0 && inv?.dueDate != null && inv?.dueDate != undefined) 
                                    ? (new Date(inv?.dueDate)?.toISOString()?.split('T')[0] < (new Date())?.toISOString()?.split('T')[0] ? 'expired-bar' : 'active-bar')
                                    : inv?.categories == 0
                                    ? 'active-bar '
                                    : inv?.categories == 1
                                    ? 'expired-bar'
                                    : inv?.categories == 3
                                    ? 'complete-bar'
                                    : inv?.categories == 2
                                    ? 'req-bar'
                                    : 'no-st-bar'
                                }
                              `}
                                >
                                  { inv?.dueDate == null
                                    ? '-'
                                    : (inv?.totalAmount == 0 && inv?.outstandingAmount == 0 && inv?.totalPaidAmount == 0 && inv?.dueDate != null && inv?.dueDate != undefined) 
                                    ? (new Date(inv?.dueDate)?.toISOString()?.split('T')[0] < (new Date())?.toISOString()?.split('T')[0] ? 'Overdue' : 'Upcoming')
                                    : inv?.categories == 0
                                    ? 'Upcoming'
                                    : inv?.categories == 1
                                    ? 'Overdue'
                                    : inv?.categories == 3
                                    ? 'Complete'
                                    : inv?.categories == 2
                                    ? 'Deferred'
                                    : '-'}
                                </div>
                              </td>
                              <td
                                className=''
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                {inv?.dueDate ? moment(inv?.dueDate).format('DD.MM.YYYY') : '-'}
                              </td>
                              <td
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                <div
                                  className={`status-w-100 status
                                ${
                                  inv?.status === 2
                                    ? 'pending-approval-st '
                                    : inv?.status === 1
                                    ? 'approve-st'
                                    : inv?.status === 0
                                    ? 'cancelled-st'
                                    : inv?.status === 3 || inv?.status === 4
                                    ? 'pend-st'
                                    : 'not-saved'
                                }`}
                                >
                                  {inv?.status === 0
                                    ? 'Unpaid'
                                    : inv?.status === 1
                                    ? 'Paid'
                                    : inv?.status === 2
                                    ? 'Partially paid'
                                    : inv?.status === 3
                                    ? 'Void'
                                    : inv?.status === 4
                                    ? 'Draft'
                                    : '-'}
                                </div>
                              </td>

                              <td
                                className={`${
                                  (inv?.isReceiptGenerated &&
                                    inv?.payment_receipt?.length == 1 &&
                                    inv?.payment_receipt?.[0]?.formStatus == 0) ||
                                  (inv?.isReceiptGenerated && inv?.payment_receipt?.length > 0)
                                    ? 'text-decoration-underline cursor-pointer fnt-700'
                                    : ''
                                }`}
                                onClick={() => {
                                  if (inv?.isReceiptGenerated) {
                                    setSelectedReceipts(inv)
                                    setShowReceipts(true)
                                  } else {
                                    goToInvoice(inv)
                                  }
                                }}
                              >
                                {inv?.isReceiptGenerated &&
                                inv?.payment_receipt?.length == 1 &&
                                inv?.payment_receipt?.[0]?.formStatus == 0
                                  ? 'Draft'
                                  : inv?.isReceiptGenerated && inv?.payment_receipt?.length > 0
                                  ? inv?.payment_receipt?.length
                                  : 'Not Raised'}
                              </td>

                              <td
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                {inv?.totalPaidAmount > 0 ? (
                                  <>AED {formatAmount(inv?.totalPaidAmount)}</>
                                ) : (
                                  <>AED 0</>
                                )}
                              </td>

                              <td
                                className='max-w-150px'
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                {inv?.lastPaidDate
                                  ? moment(inv?.lastPaidDate).format('DD.MM.YYYY')
                                  : '-'}
                              </td>
                              <td
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                {inv?.outstandingAmount != undefined ? (
                                  <>{`AED  ${formatAmount(invertOutstandingAmount(inv?.outstandingAmount))}`}</>
                                ) : (
                                  <>AED 0</>
                                )}
                              </td>

                              <td
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                {inv?.community?.length > 0
                                  ? inv?.community?.[0]?.name
                                  : inv?.building?.[0]?.name || '-'}
                              </td>
                              <td
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                {inv?.unit?.length > 0 ? inv?.unit?.[0]?.unitNo : '-'}
                              </td>
                              <td
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                {' '}
                                {inv?.unit?.[0]?.propertyType === 0
                                  ? 'Residential'
                                  : inv?.unit?.[0]?.propertyType === 1
                                  ? 'Commercial'
                                  : '-'}
                              </td>
                              <td>
                                {inv?.tenancy?.[0]?.contractNo
                                  ? inv?.tenancy?.[0]?.contractNo
                                  : '-'}
                              </td>

                              <td
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                {inv?.tenancy?.[0]?.totalAmount > 0 ? (
                                  <>AED {formatAmount(inv?.tenancy?.[0]?.totalAmount)}</>
                                ) : (
                                  <>AED 0</>
                                )}
                              </td>

                              <td
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                {inv?.payment_account?.[0]?.accountNumber || '-'}
                              </td>
                              <td
                                className='text-center  cursor-pointer px-3'
                                data-order='2022-09-11'
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                <div
                                  className={`status status-w-100 mx-auto
                                ${
                                  inv?.formStatus == 0
                                    ? 'draft-st '
                                    : inv?.formStatus == 1
                                    ? 'create-st'
                                    : 'not-saved'
                                }
                              `}
                                >
                                  {inv?.formStatus == 0
                                    ? 'Draft'
                                    : inv?.formStatus == 1
                                    ? 'Created'
                                    : '-'}
                                </div>
                              </td>

                              <td
                                className='py-3 cursor-pointer px-3'
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                <p className='my-0'>
                                  {inv?.createdByUser?.[0] &&
                                    `${inv?.createdByUser?.[0]?.firstName} ${inv?.createdByUser?.[0]?.lastName} - `}
                                </p>
                                <p className='my-0'>
                                  {inv?.createdAt
                                    ? `${moment(inv?.createdAt).format('DD.MM.YYYY - hh:mm  a')}`
                                    : '-'}
                                </p>
                              </td>

                              <td
                                className='py-3 cursor-pointer pe-5'
                                onClick={() => {
                                  goToInvoice(inv)
                                }}
                              >
                                {inv?.updatedByUser?.length > 0 ? (
                                  <>
                                    <p className='my-0'>
                                      {inv?.updatedByUser?.[0] &&
                                        `${inv?.updatedByUser?.[0]?.firstName} ${inv?.updatedByUser?.[0]?.lastName} - `}
                                    </p>
                                    <p className='my-0'>
                                      {inv?.updatedAt
                                        ? ` ${moment(inv?.updatedAt).format(
                                            'DD.MM.YYYY - hh:mm a'
                                          )}`
                                        : '- -'}
                                    </p>
                                  </>
                                ) : (
                                  <>{'-'}</>
                                )}
                              </td>
                            </tr>

                            {inv?.paymentSubItems?.length > 0 && show[ind] == true ? (
                              inv?.paymentSubItems?.map((invSub: any, invSubIndex: any) => {
                                
                                return (
                                  <>
                                   <tr
                                      key={invSubIndex}
                                      style={{
                                        borderBottomStyle:
                                        invSubIndex === inv?.paymentSubItems?.length - 1 ? 'dashed' : 'none', // Apply 'dashed' for all except the last item
                                      }}
                                    >
                                      <td></td>
                                      <td className='ps-0 i_grey'>{invSub?.name}</td>
                                      <td></td>
                                      <td className='i_grey'>
                                        {invSub?.totalAmount != undefined ? (
                                          <>AED {formatAmount(invSub?.totalAmount)}</>
                                        ) : (
                                          <>AED -</>
                                        )}
                                      </td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td className='i_grey'>
                                        {invSub?.amountPaid != undefined ? (
                                          <>AED {formatAmount(invSub?.amountPaid)}</>
                                        ) : (
                                          <>AED -</>
                                        )}
                                      </td>
                                      <td></td>
                                      <td className='i_grey'>
                                        {invSub?.outstandingAmount != undefined ? (
                                          <>AED {formatAmount(invertOutstandingAmount(invSub?.outstandingAmount))}</>
                                        ) : (
                                          <>AED -</>
                                        )}
                                      </td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </>
                                )
                              })
                            ) : (
                              <></>
                            )}
                          </>
                          //   )}
                          // </>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={10} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_ecommerce_sales_table_paginate'
                >
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous disabled'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </div>
        </>

        {/* Filters */}
        {showFilter && (
          <Filters
            showw={showFilter}
            handleClose={() => setShowFilter(false)}
            updateParent={(data: any, pageVal: any) => {
              getAllInvoices(data, 1)
            }}
          />
        )}

        {/*  */}
        <ReceiptsPopup
          show={showReceipts}
          handleClose={() => {
            setShowReceipts(false)
          }}
          receiptData={selectedReceiptss}
        />
      </div>
    </>
  )
}

export default InvoicesTab
