import React from 'react'
import {useState, useRef, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../img/back-arrow.png'
import './style.scss'
import redCross from '../../../img/remove.png'
import {Tabs, Tab, Paper} from '@mui/material'
import LeasingCommercial from './LeasingCommercial'
import LeasingResidential from './LeasingResedential'
import Tenants from './TenantsTab'
import AddFeature from './AddFeatures'
import PropertyPortfolio from './PropertyPortfolio'
import Maintenance from './Maintenance'
import Communications from './Communications'
import AddUsersModal from './AddUsersModal'
import saveGreenImg from '../../../img/save-dark-green.png'
import greenPlus from '../../../img/add.png'
import {ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import HelpAndSupport from './HelpAndSupport'
import AccountManagement from './AccountManagement'
import UserGuide from './UserGuide'
import sync from '../../../img/sync.png'

const EditRole = () => {
  const navigate = useNavigate()

  const [roleData, setRoleData] = useState<any>({
    name: '',
    description: '',
    featureList: {
      leasing_residential: {
        lease_agreements: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          tenant_detail: {
            view_details: false,
            create: false,
            update: false,
          },
          contract_detail: {
            view_details: false,
            create: false,
            update: false,
          },
          isRemoved: false,
        },
        renewals: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          isRemoved: false,
        },
        terminations: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          isRemoved: false,
        },
        cancellation: {
          allow: false,
          isRemoved: false,
        },
        lease_deletion: {
          allow: false,
          isRemoved: false,
        },
        lease_financial: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          isRemoved: false,
          invoicing: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          receipts: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          refunds: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          credit_notes: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          debit_notes: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          items: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          vat: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          late_payment_fees: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          payment_method: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          payment_reminders: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          attachments: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          notes: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
        },
      },
      user_guide: {
        user_guide: true,
      },
      help_support: {
        support_ticket: true,
      },
      account_management: {
        two_step_verification: true,
        reset_password: true,
        reset_email_id: true,
        themSelection: false,
        editPersonalDetails: false,
      },
    },
    userIds: [],
  })

  const [roleName, setRoleName] = useState<any>('')
  const {id} = useParams()

  const [tabs, setTabs] = useState<any>([
    {
      name: 'Account Management',
      value: 0,
    },
    {
      name: 'Help & Support',
      value: 1,
    },
    {
      name: 'User Guide',
      value: 2,
    },
  ])

  const [backup, setBackup] = useState<any>({
    name: '',
    description: '',
    featureList: {
      leasing_residential: {
        lease_agreements: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          tenant_detail: {
            view_details: false,
            create: false,
            update: false,
          },
          contract_detail: {
            view_details: false,
            create: false,
            update: false,
          },
          isRemoved: false,
        },
        renewals: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          isRemoved: false,
        },
        terminations: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          isRemoved: false,
        },
        cancellation: {
          allow: false,
          isRemoved: false,
        },
        lease_deletion: {
          allow: false,
          isRemoved: false,
        },
        lease_financial: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          isRemoved: false,
          invoicing: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          receipts: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          refunds: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          credit_notes: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          debit_notes: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          items: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          vat: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          late_payment_fees: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          payment_method: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          payment_reminders: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          attachments: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          notes: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
        },
      },
      user_guide: {
        user_guide: true,
      },
      help_support: {
        support_ticket: true,
      },
      account_management: {
        two_step_verification: true,
        reset_password: true,
        reset_email_id: true,
        themSelection: false,
        editPersonalDetails: false,
      },
    },
    userIds: [],
  })

  localStorage.setItem('roleDataBackup', JSON.stringify(backup))

  const [sortedTabs, setSortedTabs] = useState<any>([])

  const [tabName, setTabName] = useState('Account Management')
  const [tabValue, setTabValue] = useState('Account Management')
  const [addFeatureModal, setAddFeatureModal] = useState<any>(false)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [addUser, setAddUser] = useState<any>(false)

  const [nameError, setNameError] = useState<boolean>(false)
  const [descriptionCount, setDescriptionCount] = useState<any>(`0/50`)
  const [descriptionError, setDescriptionError] = useState<boolean>(false)
  const [wordCountError, setWordCountError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  //
  const handleChange = (event: any, newValue: any) => {
    setTabValue(newValue)
    setTabName(event.target.innerText)
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }

  //
  const getRoleById = async () => {
    await ApiGet(`corporate/role_permission/${id}`)
      .then((res: any) => {
        let values: any = res?.data?.data

        if (
          values?.featureList?.leasing_residential?.lease_agreements?.view_list ||
          values?.featureList?.leasing_residential?.lease_agreements?.view_details ||
          values?.featureList?.leasing_residential?.lease_agreements?.create ||
          values?.featureList?.leasing_residential?.lease_agreements?.update ||
          values?.featureList?.leasing_residential?.lease_agreements?.delete ||
          values?.featureList?.leasing_residential?.lease_agreements?.approve
        )
          values.featureList.leasing_residential.lease_agreements.isRemoved = undefined

        if (
          values?.featureList?.leasing_residential?.renewals?.view_list ||
          values?.featureList?.leasing_residential?.renewals?.view_details ||
          values?.featureList?.leasing_residential?.renewals?.create ||
          values?.featureList?.leasing_residential?.renewals?.update ||
          values?.featureList?.leasing_residential?.renewals?.delete ||
          values?.featureList?.leasing_residential?.renewals?.approve
        )
          values.featureList.leasing_residential.renewals.isRemoved = undefined

        if (
          values?.featureList?.leasing_residential?.terminations?.view_list ||
          values?.featureList?.leasing_residential?.terminations?.view_details ||
          values?.featureList?.leasing_residential?.terminations?.create ||
          values?.featureList?.leasing_residential?.terminations?.update ||
          values?.featureList?.leasing_residential?.terminations?.delete ||
          values?.featureList?.leasing_residential?.terminations?.approve
        )
          values.featureList.leasing_residential.terminations.isRemoved = undefined

        if (values?.featureList?.leasing_residential?.cancellation?.allow)
          values.featureList.leasing_residential.cancellation.isRemoved = undefined

        if (values?.featureList?.leasing_residential?.lease_deletion?.allow)
          values.featureList.leasing_residential.lease_deletion.isRemoved = undefined

        if (
          values?.featureList?.leasing_residential?.lease_financial?.view_list ||
          values?.featureList?.leasing_residential?.lease_financial?.view_details ||
          values?.featureList?.leasing_residential?.lease_financial?.create ||
          values?.featureList?.leasing_residential?.lease_financial?.update ||
          values?.featureList?.leasing_residential?.lease_financial?.delete ||
          values?.featureList?.leasing_residential?.lease_financial?.approve
        )
          values.featureList.leasing_residential.lease_financial.isRemoved = undefined

        setRoleData(values)
        setRoleName(res?.data?.data?.name)
        setDescriptionCount(`${res?.data?.data?.description?.length}/50`)
        let temp = [...tabs]
        if (res?.data?.data?.featureList?.leasing_residential != undefined) {
          let newTab = {
            name: 'Leasing (Residential)',
            value: temp?.length,
          }
          temp[temp?.length] = newTab
        }
        setTabs(temp)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  //
  useEffect(() => {
    getRoleById()
  }, [])

  //
  const updateRole = () => {
    let nameErr: any = roleData?.name?.trim()?.length == 0
    // let descErr: any = roleData?.description?.trim()?.length == 0
    // let countErr: any = roleData?.description?.length < 500

    if (!nameErr) {
      setIsLoading(true)

      roleData.features = undefined
      if (roleData?.featureList?.leasing_residential?.obj != undefined)
        roleData.featureList.leasing_residential.obj = undefined

      const body = {
        id: roleData?._id,
        name: roleData?.name,
        description: roleData?.description,
        featureList: roleData?.featureList,
      }
      ApiPut('corporate/role_permission', body)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          localStorage.removeItem('roleData')
          localStorage.removeItem('roleDataBackup')
          setIsLoading(false)

          navigate(`/settings/view-role/${id}`)
        })
        .catch((err: any) => {
          setIsLoading(false)
          ErrorToast(err?.message)
        })
    } else {
      setIsLoading(false)
      if (nameErr) setNameError(true)
      // if (descErr) setDescriptionError(true)
      // else if (countErr) setWordCountError(true)
    }
  }

  useEffect(() => {
    let values = tabs.slice().sort((a: any, b: any) => a.name.localeCompare(b.name))
    setSortedTabs(values)

    console.log(values)

    setTabValue(values[0]?.name)
    setTabName(values[0]?.name)
  }, [tabs])

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <div className='d-flex align-items-center gap-2'>
            <span
              className='rounded-circle cursor-pointer'
              onClick={() => navigate(`/settings/view-role/${id}`)}
            >
              <img src={backArrow} height={14} width={14} />
            </span>
            <h2 className='page-heading m-0 head-text'>Edit Role - {roleName}</h2>
          </div>

          <div className='d-flex ms-auto'>
            <button
              type='button'
              className='btn btn-sm fw-bold  mx-3 red-hollow-btn'
              onClick={() => navigate(`/settings/view-role/${id}`)}
            >
              <img src={redCross} height={18} width={18} style={{marginRight: '7px'}} /> Cancel
            </button>

            <button
              type='button'
              className='btn btn-sm fw-bold ms-3 green-hollow-btn'
              onClick={(e) => updateRole()}
            >
              <img src={saveGreenImg} height={18} width={18} style={{marginRight: '13px'}} /> {'  '}
              Save
            </button>
          </div>
        </div>

        {roleData?.isGeneratedByPropertise && (
          <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
            <button
              type='button'
              className='btn btn-sm fw-bold blue-btn mx-1 ps-3'
              style={{width: 'fit-content'}}
              onClick={() => {}}
            >
              <img src={sync} height={18} width={18} style={{marginRight: '7px'}} /> Switch to
              Default
            </button>
          </div>
        )}

        {!roleData?.isGeneratedByPropertise ? (
          <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-0 px-3'>
            <div className='card-body pt-0 mt-5 py-3 px-3'>
              <Row className='mt-5'>
                <Col md={7} lg={7} className='px-7 mb-12'>
                  <div className='d-flex'>
                    <h5 className='my-2' style={{color: 'black'}}>
                      <label style={{width: '100px'}} className='labl-gry required'>
                        {' '}
                        Role Name
                      </label>
                    </h5>
                    <input
                     
                      type='text'
                      className='form-control form-control-solid mytest tenancy-control'
                      placeholder='Enter a role name...'
                      name='name'
                      value={roleData?.name}
                      onChange={(e: any) => {
                        const values = {...roleData}
                        values.name = e.target.value
                        setRoleData(values)
                        setNameError(false)
                      }}
                      style={{height: '35px', width: '50%'}}
                    />{' '}
                  </div>
                  {nameError && (
                    <div className='d-flex mb-0'>
                      {' '}
                      <label style={{width: '100px'}} className='labl-gry'>
                        {' '}
                      </label>
                      <p
                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                        className='m-0 mt-1'
                      >
                        This field is required
                      </p>
                    </div>
                  )}
                  <h5 className='my-2 mt-10' style={{color: 'black'}}>
                    <label className='labl-gry'> Role Description</label>
                  </h5>
                  <textarea
                    className='form-control form-control-solid mytest'
                    placeholder='Give the role a description...'
                    name='description'
                    value={roleData?.description}
                    onChange={(e: any) => {
                      const values = {...roleData}
                      values.description = e.target.value
                      setRoleData(values)
                      setDescriptionError(false)

                      const target = e.target
                      // Get the `maxlength` attribute
                      const maxLength = target.getAttribute('maxlength')
                      // Count the current number of characters
                      const currentLength = target.value.length

                      setDescriptionCount(`${currentLength}/${maxLength}`)

                      // if (currentLength >= 500) setWordCountError(false)
                    }}
                    maxLength={50}
                    style={{height: '210px'}}
                  />{' '}
                  <div className=' d-flex'>
                   
                   
                    <p className='ms-auto'>
                      
                      {descriptionCount}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row className='mb-5'>
                <Col md={12} lg={12} className='px-7'>
                  <div className='d-flex mb-5 align-items-center'>
                    <h2 className='page-heading m-0 head-text me-5'>Features</h2>
                    <a
                      style={{
                        color: '#007a59',
                        fontWeight: '600',
                        fontSize: '1.15rem ',
                        borderBottom: '0.12rem #007a59 solid',
                      }}
                      className='ms-5 ps-4 text-decoration-none'
                      onClick={() => {
                        localStorage.setItem('roleData', JSON.stringify(roleData))
                        localStorage.setItem('actveTab', tabValue)
                        setAddFeatureModal(true)
                      }}
                    >
                      <img
                        src={greenPlus}
                        height={16}
                        width={16}
                        style={{
                          marginRight: '10px',
                          marginLeft: '-10px',
                          marginTop: '-3px',
                        }}
                      />
                      Add
                    </a>
                  </div>
                </Col>
              </Row>

              <div className='card card-flush gap-5 p-5 pt-0 pb-0 mt-5 px-0 card-onboard-shad mx-3'>
                <div className='card-body pt-0 mt-0 py-3 px-0 pb-0'>
                  <Row>
                    <Col md={2} className='test'>
                      <Paper style={{display: 'flex', height: '100%'}}>
                        <Tabs
                          orientation='vertical'
                          // variant='scrollable'
                          value={tabValue}
                          onChange={handleChange}
                          TabIndicatorProps={{
                            style: {
                              display: 'none', // This will hide the indicator line
                            },
                          }}
                          sx={{
                            borderRight: `1px solid divider`,
                            paddingTop: '15px',
                            paddingBottom: '20px',

                            '& .Mui-selected': {
                              fontWeight: 'bold',
                              textDecoration: 'underline',
                              border: 'none',
                              color: '#000',
                            },
                          }}
                        >
                          {sortedTabs.map((tab: any, index: any) => {
                            return (
                              <Tab
                                label={tab?.name}
                                value={tab?.name}
                                sx={{
                                  fontWeight: tabValue === index ? 'bold' : 'normal',
                                  textDecoration: tabValue === index ? 'underline' : 'none',
                                  border: 'none',
                                  color: '#000',
                                }}
                              />
                            )
                          })}

                         
                        </Tabs>
                        {/* Content for each tab */}
                      </Paper>
                    </Col>
                    <Col md={10} className='px-0'>
                      <div
                        style={{flex: 1, padding: '10px 0px'}}
                        className='feature-tab-details'
                        ref={contentRef}
                      >
                        {(tabName === 'Leasing (Residential)' && roleData) && (
                          <LeasingResidential
                            roleData={roleData}
                            from={'add-view'}
                            updateRoleDate={(val: any) => {}}
                          />
                        )}
                        {(tabName === 'Leasing (Commercial)' && roleData) && <LeasingCommercial />}
                        {tabName === 'Tenants' && <Tenants />}
                        {tabName === 'PropertyPortfolio' && <PropertyPortfolio />}
                        {tabName === 'Maintenance' && <Maintenance />}
                        {tabName === 'Communications' && <Communications />}
                        {(tabName === 'Help & Support' && roleData) && (
                          <HelpAndSupport
                            roleData={roleData}
                            from={'edit'}
                            updateRoleDate={(val: any) => {}}
                          />
                        )}
                        {(tabName === 'Account Management' && roleData && tabValue) && (
                          <AccountManagement
                            roleData={roleData}
                            from={'edit'}
                            updateRoleDate={(val: any) => {}}
                          />
                        )}
                        {tabName === 'User Guide' && (
                          <UserGuide
                            roleData={roleData}
                            from={'edit'}
                            updateRoleDate={(val: any) => {}}
                          />
                        )}
                        {tabName === 'Data Export' && <div>{'Data Export'}</div>}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className='card card-flush gap-5 p-5 pt-0 pb-0 mt-7 px-0  mx-1'>
              <div className='card-body pt-0 mt-0 py-3 px-0 pb-0'>
                <Row>
                  <Col md={2} className='test ps-0'>
                    <Paper style={{height: '670px', width: 'max-content'}} className='pe-2'>
                      <div className='d-flex align-items-baseline'>
                        <h3 className='head-text ps-2 ms-3 pt-5 mb-0'>
                          <b>Features</b>
                        </h3>
                        <a
                          style={{
                            color: '#007a59',
                            fontWeight: '600',
                            fontSize: '1rem ',
                            borderBottom: '0.12rem #007a59 solid',
                          }}
                          className='ms-5 ps-4 text-decoration-none'
                          onClick={() => {
                            localStorage.setItem('roleData', JSON.stringify(roleData))
                            localStorage.setItem('actveTab', tabValue)
                            setAddFeatureModal(true)
                          }}
                        >
                          <img
                            src={greenPlus}
                            height={14}
                            width={14}
                            style={{
                              marginRight: '10px',
                              marginLeft: '-10px',
                              marginTop: '-3px',
                            }}
                          />
                          Add
                        </a>
                      </div>
                      <Tabs
                        orientation='vertical'
                        // variant='scrollable'
                        value={tabValue}
                        onChange={handleChange}
                        TabIndicatorProps={{
                          style: {
                            display: 'none', // This will hide the indicator line
                          },
                        }}
                        sx={{
                          borderRight: `1px solid divider`,
                          paddingTop: '15px',
                          paddingBottom: '20px',

                          '& .Mui-selected': {
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                            border: 'none',
                            color: '#000',
                          },
                        }}
                      >
                        {tabs?.map((tab: any, index: any) => {
                          return (
                            <Tab
                              label={tab?.name}
                              sx={{
                                fontWeight: tabValue === index ? 'bold' : 'normal',
                                textDecoration: tabValue === index ? 'underline' : 'none',
                                border: 'none',
                                color: '#000',
                              }}
                            />
                          )
                        })}
                      </Tabs>
                      {/* Content for each tab */}
                    </Paper>
                  </Col>
                  <Col md={10} className='px-0'>
                    <div
                      style={{flex: 1, padding: '10px 0px'}}
                      className='feature-tab-details'
                      ref={contentRef}
                    >
                      {tabName === 'Leasing (Residential)' && roleData && (
                        <LeasingResidential
                          roleData={roleData}
                          from={'add-view'}
                          updateRoleDate={(val: any) => {}}
                        />
                      )}
                      {tabName === 'Leasing (Commercial)' && roleData && <LeasingCommercial />}
                      {tabName === 'Tenants' && <Tenants />}
                      {tabName === 'PropertyPortfolio' && <PropertyPortfolio />}
                      {tabName === 'Maintenance' && <Maintenance />}
                      {tabName === 'Communications' && <Communications />}
                      {tabName === 'Help & Support' && roleData && (
                        <HelpAndSupport
                          roleData={roleData}
                          from={'add-view'}
                          updateRoleDate={(val: any) => {}}
                        />
                      )}
                      {tabName === 'Account Management' && roleData && (
                        <AccountManagement
                          roleData={roleData}
                          from={'edit'}
                          updateRoleDate={(val: any) => {}}
                        />
                      )}
                      {tabName === 'User Guide' && (
                        <UserGuide
                          roleData={roleData}
                          from={'edit'}
                          updateRoleDate={(val: any) => {}}
                        />
                      )}
                      {tabName === 'Data Export' && <div>{'Data Export'}</div>}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </div>

      {/* Add Feature Modal */}
      {addFeatureModal && roleData && (
        <AddFeature
          show={addFeatureModal}
          handleClose={(val: any) => {
            let temp = localStorage.getItem('roleData')
            if (temp != null) {
              let copy = JSON.parse(temp)
              setRoleData(copy)
            }

            const values = [...tabs]

            setTabValue(values[0]?.name)
            setTabName(values[0]?.name)

            setTimeout(() => {
              let active: any = localStorage?.getItem('actveTab')
              if (active != null) {
                setTabValue(active)
                setTabName(active)
              }
              setAddFeatureModal(false)
            }, 300)

            // if (val) {
            //   setTimeout(() => {
            //     setTabName('Leasing (Residential)')
            //     setAddFeatureModal(false)
            //   }, 300)
            // } else {
            //   setTabName('Help & Support')
            //   setAddFeatureModal(false)
            // }
          }}
          role={roleData}
          from={'add'}
          updateTabData={(val: any, data: any) => {
            const isInc = tabs.some((tab: any) => tab.name === 'Leasing (Residential)')
            setRoleData(data)

            const values = [...tabs]

            if (val == 'residential' && !isInc) {
              values[values.length] = {
                name: 'Leasing (Residential)',
                value: values.length,
              }
              setTabs(values)
            } else if (val == 'residential' && isInc) {
            } else if (val == '' && isInc) {
              values.splice(values.length - 1, 1)
              setTabs(values)
            }

            setTimeout(() => {
              let active: any = localStorage?.getItem('actveTab')
              if (active != null) {
                const inclTab = values.some((tab: any) => tab.name === active)
                if (inclTab) {
                  setTabValue(active)
                  setTabName(active)
                } else {
                  setTabValue(values[0]?.name)
                  setTabName(values[0]?.name)
                }
              }
              setAddFeatureModal(false)
            }, 300)
          }}
        />
      )}

      {/* Add Feature Modal */}
      {addUser && (
        <AddUsersModal
          show={addUser}
          handleClose={() => setAddUser(false)}
          updateUsers={(data: any) => {}}
          addedUsers={''}
          from={'edit-role'}
          previousIds={''}
        />
      )}
    </>
  )
}

export default EditRole
