export default {
  //
  latePaymentFeeStatusOptions: [
    {label: 'Enabled', value: 1},
    {label: 'Disabled', value: 0},
  ],

  //
  amountCalculationTypeOptions: [
    {label: 'Fixed Amount', value: 0},
    {label: 'Percentage', value: 1},
  ],
}
