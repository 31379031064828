import React, {useRef, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import noData from '../../../../img/NoData1.svg'
import ReactPaginate from 'react-paginate'
import {useDispatch} from 'react-redux'
import {setComefrom, setUnitIDfromProperties} from '../../../../redux/counterSlice'

const ListOfBuilding = ({data, handlePageClick}: any) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const tableRef = useRef<HTMLTableElement | null>(null)

  const handleMouseWheel = (e: React.WheelEvent) => {
    if (tableRef.current) {
      if (isMouseOverTable(e, tableRef.current)) {
        e.preventDefault()
        tableRef.current.scrollLeft += e.deltaY
      }
    }
  }

  const isMouseOverTable = (e: React.WheelEvent, element: HTMLElement) => {
    // document.body.style.overflow = 'hidden';
    const rect = element.getBoundingClientRect()
    const mouseX = e.clientX - rect.left
    const mouseY = e.clientY - rect.top
    return mouseX >= 0 && mouseX <= rect.width && mouseY >= 0 && mouseY <= rect.height
  }

  const disableScrolling = () => {
    document.body.style.overflow = 'hidden'
  }

  const enableScrolling = () => {
    document.body.style.overflow = '' // Reset to default
  }

  const handleMouseEnter = () => {
    disableScrolling()
  }

  const handleMouseLeave = () => {
    enableScrolling()
  }

  useEffect(() => {
    enableScrolling()
  }, [])

  return (
    <div>
      <>
        {data.data?.length ? (
          <div
            className='table-responsive mb-5'
            // onWheel={handleMouseWheel}
            // ref={tableRef}
            // onMouseEnter={handleMouseEnter}
            // onMouseLeave={handleMouseLeave}
          >
            <table
              className='table align-start table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='text-start min-w-50px'>#</th>
                  <th className='text-start min-w-150px'>Development Name</th>
                  <th className='text-start min-w-100px'>Floor</th>
                  <th className='text-start min-w-100px'>Property ID</th>
                  <th className='text-start min-w-100px'>Unit Type</th>
                  <th className='text-start min-w-100px'>Unit No.</th>
                  <th className='text-start min-w-100px'>Bedrooms</th>
                  <th className='text-start min-w-100px'>Tenancy Status</th>
                  <th className='text-start min-w-100px'>Occupancy</th>
                  <th className='text-start min-w-150px'>Building Manager</th>
                  <th className='text-start min-w-150px'>Property Manager</th>
                  <th className='text-start min-w-100px'>Rating</th>
                  {/* <th className='text-end min-w-100px'></th> */}
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {data.data.map((v: any, i: any) => {
                  return (
                    <>
                      <tr
                        onClick={() => {
                          dispatch(setComefrom('build'))

                          navigate(`/overViewData/${v?._id}`, {
                            state: {development: 'building', details: v, unitId: `${v?._id}`},
                          })
                          localStorage.setItem('page', 'building-dashboard')
                        }}
                        className='cursor-pointer'
                      >
                        <td className='text-start'>{(data.page - 1) * 10 + (i + 1)}</td>
                        <td data-kt-ecommerce-order-filter='order_id' className='start'>
                          {v?.building[0]?.name}
                        </td>
                        <td className='text-start'>{v?.floor[0]?.name}</td>
                        <td className='text-start' data-order='Denied'>
                          {v?.id}
                        </td>
                        <td className='text-start'>
                          {v?.unitType === 'town_house'
                            ? 'TownHouse'
                            : v?.unitType === 'other'
                            ? 'Other'
                            : v?.unitType === 'common_area'
                            ? 'Common Area'
                            : v?.unitType === 'villa'
                            ? 'Villa'
                            : v?.unitType === 'apartment'
                            ? 'Apartment'
                            : v?.unitType === 'penthouse'
                            ? 'Penthouse'
                            : ''}
                        </td>
                        <td className='text-start' data-order='2022-09-09'>
                          {v?.unitNo}
                        </td>
                        <td className='text-start' data-order='2022-09-11'>
                          {v?.bedrooms}
                        </td>
                        <td className='text-start'>
                          <div
                            className={
                              v?.tenancyStatus == 0
                                ? 'status booked-bar me-5'
                                : v?.tenancyStatus == 1
                                ? 'status active-bar me-5'
                                : v?.tenancyStatus == 2
                                ? 'status expiring-bar me-5'
                                : v?.tenancyStatus == 3
                                ? 'status expired-bar me-5'
                                : 'status  no-st-bar me-5'
                            }
                          >
                            {v?.tenancyStatus == 0
                              ? 'Booked'
                              : v?.tenancyStatus == 1
                              ? 'Active'
                              : v?.tenancyStatus == 2
                              ? 'Expiring'
                              : v?.tenancyStatus == 3
                              ? 'Expired'
                              : '-'}
                          </div>
                        </td>
                        <td className='text-start' data-order='2022-09-11'>
                          {v?.occupy === 0 ? 'Vacant ' : 'Occupied'}
                        </td>
                        <td className='text-start' data-order='2022-09-11'>
                          {v?.building[0]?.managerId}
                        </td>
                        <td className='text-start' data-order='2022-09-11'>
                          {v?.managerId}
                        </td>
                        <td className='text-start' data-order='2022-09-11'>
                          <span className='svg-icon svg-icon-2' style={{color: '#ffad0f'}}>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M11.1359 4.48359C11.5216 3.82132 12.4784 3.82132 12.8641 4.48359L15.011 8.16962C15.1523 8.41222 15.3891 8.58425 15.6635 8.64367L19.8326 9.54646C20.5816 9.70867 20.8773 10.6186 20.3666 11.1901L17.5244 14.371C17.3374 14.5803 17.2469 14.8587 17.2752 15.138L17.7049 19.382C17.7821 20.1445 17.0081 20.7069 16.3067 20.3978L12.4032 18.6777C12.1463 18.5645 11.8537 18.5645 11.5968 18.6777L7.69326 20.3978C6.99192 20.7069 6.21789 20.1445 6.2951 19.382L6.7248 15.138C6.75308 14.8587 6.66264 14.5803 6.47558 14.371L3.63339 11.1901C3.12273 10.6186 3.41838 9.70867 4.16744 9.54646L8.3365 8.64367C8.61089 8.58425 8.84767 8.41222 8.98897 8.16962L11.1359 4.48359Z'
                                fill='currentColor'
                              ></path>
                            </svg>
                          </span>{' '}
                          {v?.rating}
                        </td>
                        {/* <td className='text-start'>
                        <div className='menu-item px-3'>
                          <div
                            onClick={() => {
                              dispatch(setComefrom('build'))

                              navigate(`/overViewData/${v?._id}`, {
                                state: {development: 'building', details: v, unitId: `${v?._id}`},
                              })
                              localStorage.setItem('page', 'building-dashboard')
                            }}
                            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                          >
                            View
                          </div>
                        </div>
                      </td> */}
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className=' table-responsive mb-5'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='text-start min-w-100px'>#</th>
                  <th className='text-start min-w-150px'>Development Name</th>
                  <th className='text-start min-w-100px'>Floor</th>
                  <th className='text-start min-w-100px'>Property ID</th>
                  <th className='text-start min-w-100px'>Unit Type</th>
                  <th className='text-start min-w-100px'>Unit No.</th>
                  <th className='text-start min-w-100px'>Bedrooms</th>
                  <th className='text-start min-w-100px'>Occupancy</th>
                  <th className='text-start min-w-150px'>Building Manager</th>
                  <th className='text-start min-w-150px'>Property Manager</th>
                  <th className='text-start min-w-100px'>Rating</th>
                  <th className='text-end min-w-100px'></th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                <tr>
                  <td colSpan={12} className='text-start'>
                    <img src={noData} alt='' width={350} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </>
      <div className='row my-5'>
        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
          <div
            className='dataTables_paginate paging_simple_numbers'
            id='kt_ecommerce_sales_table_paginate'
          >
            <ReactPaginate
              breakLabel='...'
              nextLabel='>'
              pageClassName='paginate_button page-item +'
              pageLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              previousClassName='paginate_button page-item previous disabled'
              nextClassName='paginate_button page-item next'
              previousLinkClassName='page-link'
              nextLinkClassName='page-link'
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={data.pageCount}
              previousLabel='<'
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListOfBuilding
