import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import backArrow from '../../../img/back-arrow.png'
import addWhite from '../../../img/add-white.png'
// import './style.scss'
import {ApiGet, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import noData from '../../../img/NoData1.svg'
import leasingImg from '../../../img/leasing.png'
import financeImg from '../../../img/financials-payments.png'
import mainImg from '../../../img/Maintenance.png'
import annouceImg from '../../../img/annoucements.png'
import tenantImg from '../../../img/tenants.png'
import vendorImg from '../../../img/vendors.png'
import workFlowsImg from '../../../img/workflows.png'

const Workflows = () => {
  const navigate = useNavigate()

  const [mainWorkflows, setMainWorkflows] = useState<any>()

  const getMainWorkFlows = () => {
    ApiPost('corporate/main_workflow/get')
      .then((res: any) => {
        setMainWorkflows(res?.data?.data)
      })
      .catch(() => {})
  }
  useEffect(() => {
    getMainWorkFlows()
  }, [])

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <div className='d-flex align-items-center gap-3'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => navigate('/settings')}
            >
              <img src={backArrow} height='14px' width='14px' />
            </span>
            <h2 className='page-heading m-0 head-text'>Workflows</h2>
          </div>
          <div className='ms-auto'>
            <img src={workFlowsImg} height='30px' width='30px' />
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3' style={{minHeight: '75vh'}}>
          <div className='card-body pt-0 mt-5 py-0 px-3'>
            <Row className='my-5'>
              {mainWorkflows?.length > 0 ? (
                <>
                  {mainWorkflows?.map((workflow: any, index: any) => {
                    return (
                      <Col md={6} className='mb-5' key={index}>
                        <div
                          className='card card-flush gap-5 p-3 cursor-pointer'
                          onClick={() => {
                            if (workflow?.name == 'Leasing') navigate(`/settings/workflows/leasing/${workflow?._id}`)
                            else if (workflow?.name == 'Maintenance') navigate(`/settings/workflows/maintenance/${workflow?._id}`)
                          }}
                        >
                          <div className='card-body p-3'>
                            <div className='d-flex align-items-center'>
                              <img
                                src={
                                  workflow?.name == 'Leasing'
                                    ? leasingImg
                                    : workflow?.name == 'Financial & Payments'
                                    ? financeImg
                                    : workflow?.name == 'Maintenance'
                                    ? mainImg
                                    : workflow?.name == 'Announcements'
                                    ? annouceImg
                                    : workflow?.name == 'Tenants'
                                    ? tenantImg
                                    : workflow?.name == 'Vendor Management'
                                    ? vendorImg
                                    : ''
                                }
                                height={35}
                                width={35}
                              />
                              <h4 className='page-heading m-0 head-text ps-4'>
                                <b>{workflow?.name}</b>
                              </h4>
                              <div className='ms-auto text-center'>
                                <h5 className=' m-0 labl-gry mb-3 light-dark-theme-color'>Workflows</h5>
                                <h4 className=' m-0 head-text'>
                                  <b>{workflow?.totalWorkflows}</b>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                </>
              ) : (
                <>
                <Row>
                    <Col md={12} className='text-center'>
                      <img src={noData} alt='' width={350}  />
                    </Col>
                  </Row></>
              )}

              {/* <Col md={6} className='mb-5'>
                //{' '}
                <div className='card card-flush gap-5 p-3'>
                  //{' '}
                  <div className='card-body p-3'>
                    //{' '}
                    <div className='d-flex align-items-center'>
                      // <img src={financeImg} height={35} width={35} />
                      //{' '}
                      <h4 className='page-heading m-0 head-text ps-4'>
                        <b>Financials & Payments</b>
                      </h4>
                      //{' '}
                      <div className='ms-auto text-center'>
                        // <h5 className=' m-0 labl-gry mb-3'>Workflows</h5>
                        //{' '}
                        <h4 className=' m-0 head-text'>
                          <b>13</b>
                        </h4>
                        //{' '}
                      </div>
                      //{' '}
                    </div>
                    //{' '}
                  </div>
                  //{' '}
                </div>
                //{' '}
              </Col> */}
              {/* <Col md={6} className="mb-5">
                <div className='card card-flush gap-5 p-3'>
                  <div className='card-body p-3'>
                    <div className='d-flex align-items-center'>
                      <img src={mainImg} height={35} width={35} />
                      <h4 className='page-heading m-0 head-text ps-4'><b>Maintenance</b></h4>
                      <div className='ms-auto text-center'>
                      <h5 className=' m-0 labl-gry mb-3'>Workflows</h5>
                      <h4 className=' m-0 head-text'><b>2</b></h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Col> */}

              {/* <Col md={6} className='mb-5'>
                //{' '}
                <div className='card card-flush gap-5 p-3'>
                  //{' '}
                  <div className='card-body p-3'>
                    //{' '}
                    <div className='d-flex align-items-center'>
                      // <img src={annouceImg} height={35} width={35} />
                      //{' '}
                      <h4 className='page-heading m-0 head-text ps-4'>
                        <b>Annoucements</b>
                      </h4>
                      //{' '}
                      <div className='ms-auto text-center'>
                        // <h5 className=' m-0 labl-gry mb-3'>Workflows</h5>
                        //{' '}
                        <h4 className=' m-0 head-text'>
                          <b>1</b>
                        </h4>
                        //{' '}
                      </div>
                      //{' '}
                    </div>
                    //{' '}
                  </div>
                  //{' '}
                </div>
                //{' '}
              </Col> */}

              {/* <Col md={6} className='mb-5'>
                //{' '}
                <div className='card card-flush gap-5 p-3'>
                  //{' '}
                  <div className='card-body p-3'>
                    //{' '}
                    <div className='d-flex align-items-center'>
                      // <img src={tenantImg} height={35} width={35} />
                      //{' '}
                      <h4 className='page-heading m-0 head-text ps-4'>
                        <b>Tenants</b>
                      </h4>
                      //{' '}
                      <div className='ms-auto text-center'>
                        // <h5 className=' m-0 labl-gry mb-3'>Workflows</h5>
                        //{' '}
                        <h4 className=' m-0 head-text'>
                          <b>1</b>
                        </h4>
                        //{' '}
                      </div>
                      //{' '}
                    </div>
                    //{' '}
                  </div>
                  //{' '}
                </div>
                //{' '}
              </Col> */}

              {/* <Col md={6} className='mb-5'>
                //{' '}
                <div className='card card-flush gap-5 p-3'>
                  //{' '}
                  <div className='card-body p-3'>
                    //{' '}
                    <div className='d-flex align-items-center'>
                      // <img src={vendorImg} height={35} width={35} />
                      //{' '}
                      <h4 className='page-heading m-0 head-text ps-4'>
                        <b>Vendor Management</b>
                      </h4>
                      //{' '}
                      <div className='ms-auto text-center'>
                        // <h5 className=' m-0 labl-gry mb-3'>Workflows</h5>
                        //{' '}
                        <h4 className=' m-0 head-text'>
                          <b>1</b>
                        </h4>
                        //{' '}
                      </div>
                      //{' '}
                    </div>
                    //{' '}
                  </div>
                  //{' '}
                </div>
                //{' '}
              </Col> */}
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default Workflows
