import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import whiteTick from '../../../../../img/onboard-tick.png'
import ColorPicker from 'react-pick-color'
import colorPickImg from '../../../../../img/colr-picker.png'
import {MultiSelect} from 'react-multi-select-component'
import alignLeftImg from '../../../../../img/align-left.png'
import alignCenterImg from '../../../../../img/align-center.png'
import alignRightImg from '../../../../../img/align-right.png'
import justifyContentImg from '../../../../../img/justify-content.png'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import {styled} from '@mui/material/styles'
import './../styles.scss'
import SingleFileLoader from '../../../General/SingleFileLoader'
import {ApiPost, Bucket} from '../../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import {convertSvgToPng} from '../../../../../Utilities/utils'

interface SectionEditorProps {
  selectedSectionStyling: any
  setSelectedSectionStyling: (values: any) => void
  selectedSectInd?: any
  selectedText: any
  onFieldSelect: (field: string) => void
  onDocumentSelect: (field: string) => void
  showMergeStyle: boolean
  selectedDynamicText: any
  selectedDynamicDocument: any
  isSquareBracket: any
}

const SectionEditor: React.FC<SectionEditorProps> = ({
  selectedSectionStyling,
  setSelectedSectionStyling,
  selectedSectInd,
  selectedText,
  onFieldSelect,
  onDocumentSelect,
  showMergeStyle,
  selectedDynamicText,
  selectedDynamicDocument,
  isSquareBracket,
}) => {
  const PrettoSlider = styled(Slider)({
    color: '#146c6a',
    height: 8,
    '& .MuiSlider-root': {
      padding: '5px 0px',
    },
    '& .MuiSlider-rail': {
      height: '5px',
    },
    '& .MuiSlider-track': {
      border: 'none',
      height: '5px',
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&::before': {
        display: 'none',
      },
    },
    '& .MuiSlider-valueLabel': {
      lineHeight: 1.2,
      fontSize: 12,
      background: 'unset',
      padding: 0,
      width: 32,
      height: 32,
      borderRadius: '50% 50% 50% 0',
      backgroundColor: '#146c6a',
      transformOrigin: 'bottom left',
      transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
      '&::before': {display: 'none'},
      '&.MuiSlider-valueLabelOpen': {
        transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
      },
      '& > *': {
        transform: 'rotate(45deg)',
      },
    },
  })

  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false)
  const [isColorPickerForButtonVisible, setIsColorPickerForButtonVisible] = useState(false)
  const [isColorPickerForButtonTextColorVisible, setIsColorPickerForButtonTextColorVisible] =
    useState(false)
  const [isColorPickerForBorder, setIsColorPickerForBorder] = useState(false)
  const [isColorPickerForShape, setIsColorPickerForShape] = useState(false)

  const [linkLists, setLinkLists] = useState<any>([])
  const [borderTypeLists, setBorderTypeLists] = useState<any>([
    {
      label: 'solid',
      value: 'solid',
    },
    {
      label: 'dotted',
      value: 'dotted',
    },
    {
      label: 'dashed',
      value: 'dashed',
    },
  ])

  const [showLoader, setShowLoader] = useState<boolean>(false) // State to control the visibility of the modal loader

  //
  const handleChange = async (e: any, ind: any) => {
    e.preventDefault()
    setShowLoader(true) // Show the modal loader when file uploading starts

    const values: any = {...selectedSectionStyling}

    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        // file upload logic here

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', e.target.files[i])

        var videoFormdata = new FormData()
        videoFormdata.append('video', e.target.files[i])

        let name: any = e.target.files[i]?.name

        try {
          const res = await ApiPost(
            `upload/image/params?fileType=maintenance_service_job&databaseId=${data._id}&fileName=${name}`,
            formdata
          )

          const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

          if (sectionElement) {
            // Select the image element inside the section
            const img: NodeListOf<HTMLImageElement> =
              sectionElement.querySelectorAll('img:not(.svg-img)')

            if (img) {
              //
              if (values?.content?.image?.[ind]?.display == 1) {
                Array.from(img)[ind].src = Bucket + res?.data?.data?.image
                Array.from(img)[ind].style.height = selectedSectionStyling?.content?.height
                Array.from(img)[ind].style.width = selectedSectionStyling?.content?.width
              }

              // Update the content HTML with the modified outerHTML of the section
              values.content.html = sectionElement.innerHTML

              // Update the selected section styling state
              setSelectedSectionStyling(values)
            }
          }
        } catch (error: any) {
          console.error('Error while uploading image:', error)
          ErrorToast(error?.message)
        }
      }

      e.target.value = null
    }

    setShowLoader(false) // Hide the modal loader when file uploading finishes
  }

  const [selectedSectionImages, setSelectedSectionImages] = useState<any>([])

  //
  useEffect(() => {
    const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

    if (sectionElement) {
      // Select the image element inside the section
      const imgs: any = sectionElement.querySelectorAll('img:not(.svg-img)')
      setSelectedSectionImages(imgs)
    }
  }, [selectedSectionStyling])

  //
  const uploadPNG = async (
    imgSrc: any,
    section: any,
    pngImg: any,
    color: any,
    sectionElement: any
  ) => {
    var formdata = new FormData()
    formdata.append('image', imgSrc)

    let userData: any = localStorage.getItem('userData')
    const data = JSON.parse(userData)

    let name: any = Date.now()

    await ApiPost(
      `upload/compress_image/params?fileType=email_template&databaseId=${data._id}&fileName=${name}`,
      formdata
    )
      .then((res) => {
        section.content.shapeColor = color.hex
        pngImg.src = `${Bucket}${res?.data?.data?.image}`

        section.content.html = sectionElement?.innerHTML

        section.content.shapeColor = color.hex
        setSelectedSectionStyling(section)

        return res?.data?.data?.image
      })

      .catch((err) => {})
  }

  const [selectedMergeField, setSelectedMergeField] = useState<any>([])
  const [selectedMergeDocument, setSelectedMergeDocument] = useState<any>([])
  const [toggleMergeField, setToggleMergeField] = useState<boolean>(showMergeStyle)

  //
  const options = [
    {
      label: 'Tenant ID',
      value: 'tenantId',
      description:
        'Will include the individual unique ID of the tenant selected as the ‘payor’ and ‘recipient’.',
    },
    {
      label: 'Tenant First Name',
      value: 'firstName',
      description:
        'Will include the first name of the tenant selected as the ‘payor’ and ‘recipient’.',
    },
    {
      label: 'Tenant Last Name',
      value: 'lastName',
      description:
        'Will include the last name of the tenant selected as the ‘payor’ and ‘recipient’.',
    },
    {
      label: 'Tenant Email',
      value: 'email',
      description:
        'Will include the email address registered for the tenant selected as the ‘payor’ and ‘recipient’.',
    },
    {
      label: 'Tenant Phone Number',
      value: 'phoneNumber',
      description:
        'Will include the mobile number registered for the tenant selected as the ‘payor’ and ‘recipient’.',
    },
    {
      label: 'Tenant Country Code (Phone Number)',
      value: 'countryCode',
      description:
        'Will include the country code of the mobile number registered for the tenant selected as the ‘payor’ and ‘recipient’.',
    },
    {
      label: 'Tenant Gender',
      value: 'gender',
      description: 'Will include the gender of the tenant selected as the ‘payor’ and ‘recipient’.',
    },
    {
      label: 'Tenant Date of Birth',
      value: 'DOB',
      description:
        'Will include the date of birth (dd/mm/yyyy) of the tenant selected as the ‘payor’ and ‘recipient’.',
    },
    {
      label: 'Invoice ID',
      value: 'Id',
      description: 'Will include the unique ID of the invoice that is generated.',
    },
    {
      label: 'Invoice Number',
      value: 'invoiceNo',
      description:
        'Will include the invoice number that was assigned to or entered on the invoice that was generated.',
    },
    {
      label: 'Payment Name',
      value: 'paymentName',
      description:
        'Will include the name of the payment the invoice is created under. Payments can include multiple invoices.',
    },
    {
      label: 'Invoice Due Date',
      value: 'dueDate',
      description:
        'Will include the due date of the invoice that was selected when creating the invoice.',
    },
    {
      label: 'Invoice Date of Issue',
      value: 'date',
      description: 'Will include the date the invoice was generated.',
    },
    {
      label: 'Outstanding Amount',
      value: 'outstandingAmount',
      description: 'Will include the outstanding amount of the selected tenant.',
    },
    {
      label: 'Total Paid Amount',
      value: 'totalPaidAmount',
      description: 'Will include the total paid amount.',
    },
    {
      label: 'Total Refund Amount',
      value: 'totalRefundAmount',
      description: 'Will include the total refund amount.',
    },
    {
      label: 'Total Amount',
      value: 'totalAmount',
      description: 'Will include the total amount.',
    },
    {
      label: 'Total Credit Amount',
      value: 'totalCreditAmount',
      description: 'Will include the total credit amount.',
    },
    {
      label: 'Total Debit Amount',
      value: 'totalDebitAmount',
      description: 'Will include the total debit amount.',
    },
    {
      label: 'Invoice Total VAT Amount',
      value: 'totalVAT',
      description: 'Will include the total VAT amount that was added in the invoice generated.',
    },
    {
      label: 'Invoice Sub-Total Amount',
      value: 'subTotalAmount',
      description:
        'Will include the sub-total amount of the invoice (total of all items added to the invoice excluding VAT).',
    },
    {
      label: 'Invoice Payment Method',
      value: 'paymentMethod',
      description:
        'Will include the payment method selected in the invoice for the recipient to settle their payment through.',
    },
  ]

  //
  const handleMergeFieldChange = (newVal: any) => {
    const selectedField = newVal?.[0]?.value
    setSelectedMergeField(newVal)
    onFieldSelect(selectedField) // Pass the selected field to the parent
  }

  //
  useEffect(() => {
    console.log(showMergeStyle)
    setToggleMergeField(showMergeStyle)
  }, [showMergeStyle])

  //
  useEffect(() => {
    console.log(selectedDynamicText)
    let newVal: any = selectedDynamicText
    if (selectedDynamicText?.length > 0) newVal = selectedDynamicText?.trimEnd()
    const selectedInd = options?.findIndex((option: any) => option?.value === newVal)

    if (selectedInd !== -1) {
      setSelectedMergeField([options[selectedInd]])
    } else {
      setSelectedMergeField([])
    }
  }, [selectedDynamicText]) // Add options to the dependency array

  //
  useEffect(() => {
    console.log('{************ Highlighted Text **************}')
    console.log(selectedText)
  }, [selectedText])

  //
  const documentOptions = [
    {
      label: 'PDF Web Preview',
      value: 'pdfURL',
      description:
        'Will include the URL generated for the PDF preview of the invoice. It will allow the email recipient to click on the field and preview the invoice that was generated.',
    },
    {
      label: 'Invoice Payment Method',
      value: 'paymentMethod',
      description:
        'Will include the payment method selected in the invoice for the recipient to settle their payment through.',
    },
  ]

  //
  const handleMergeDocumentChange = (newVal: any) => {
    const selectedField = newVal?.[0]?.value
    setSelectedMergeDocument(newVal)
    onDocumentSelect(selectedField) // Pass the selected field to the parent
  }

  //
  useEffect(() => {
    console.log(selectedDynamicDocument)
    let newVal: any = selectedDynamicDocument
    if (selectedDynamicDocument != null && selectedDynamicDocument?.length > 0) {
      if (selectedDynamicDocument?.trim()?.length > 0) newVal = selectedDynamicDocument?.trimEnd()
    }
    const selectedInd = documentOptions?.findIndex((option: any) => option?.value === newVal)

    if (selectedInd !== -1) {
      setSelectedMergeDocument([documentOptions[selectedInd]])
    } else {
      setSelectedMergeDocument([])
      // handleMergeDocumentChange([])
    }
  }, [selectedDynamicDocument]) // Add options to the dependency array

  //
  const customOptionRenderer = (option: any) => {
    // console.log(option)
    return (
      <div
        style={{padding: '4px 8px'}}
        className='d-flex'
        onClick={() => {
          console.log(option?.option)
          if (toggleMergeField) {
            if (selectedText?.trim()?.length > 0) {
              if (selectedMergeDocument?.[0]?.label != option?.option?.label) {
                setSelectedMergeDocument([option?.option])

                handleMergeDocumentChange([option?.option])
                handleMergeFieldChange([])
              }
            } else {
              setSelectedMergeField([option?.option])
              handleMergeFieldChange([option?.option])
            }
          } else {
            const values = {...selectedSectionStyling}
            const sectionElement: any = document.getElementById(selectedSectionStyling?.id)

            if (sectionElement) {
              const tag: NodeListOf<HTMLAnchorElement> = sectionElement.querySelectorAll('a')
              console.log(tag)

              const btn: NodeListOf<HTMLButtonElement> = sectionElement.querySelectorAll('button')
              console.log(btn)
              if (tag.length > 0 && btn.length > 0) {
                //
                // tag.forEach((itm, ind) => {
                tag[0].href = `{{${option?.option?.label}}}`
                // tag[0].innerHTML = btn
                // })

                values.content.dynamicDocument = [option?.option]

                values.content.html = sectionElement.innerHTML

                const dynamicTag: NodeListOf<HTMLElement> =
                  sectionElement.querySelectorAll('#borderOfDynamicButton') // Using the class selector

                // Apply styles to each element in the NodeList
                dynamicTag.forEach((dtag) => {
                  dtag.style.border = '2px solid #0065bd'
                  dtag.style.padding = '5px'
                })

                values.content.dynamicStyle.border = '2px solid #0065bd'
                values.content.dynamicStyle.padding = '5px'

                values.content.html = sectionElement.innerHTML
              }
            }
            setSelectedSectionStyling(values)
          }
        }}
      >
        <div style={{height: '30px', width: '30px'}}>
          <input
            type='radio'
            className='me-3 cursor-pointer form-check-input mt-1'
            id='staticLink'
            value='staticLink'
            checked={option?.checked}
            onChange={() => {}}
          />
        </div>
        <div>
          <strong>{option?.option.label}</strong>
          <p style={{margin: '0', color: '#6c757d'}}>{option?.option.description}</p>
        </div>
      </div>
    )
  }

  // InfoCard Component
  const InfoCard = (selectedItem: any) => {
    console.log(selectedItem)
    return (
      <div className='card card-flush p-5 py-5 tst mt-10 mx-5 me-10'>
        <h3 className='my-2 '>
          <label className='fnt-600'> {selectedItem?.selectedItem?.label}</label>
        </h3>
        <p style={{color: '#6c757d'}} className='mb-3'>
          {selectedItem?.selectedItem?.description}
        </p>
      </div>
    )
  }

  const [squareBracketsFound, setSquareBracketsFound] = useState<any>(false)

  useEffect(() => {
    if (selectedSectionStyling?.type == 'button') {
      if (
        (selectedSectionStyling?.content?.html?.includes('[') &&
          selectedSectionStyling?.content?.html.includes(']')) ||
        (selectedSectionStyling?.content?.html?.includes('{{') &&
          selectedSectionStyling?.content?.html.includes('}}'))
      ) {
        // Set your other variable based on the existence of square brackets
        setSquareBracketsFound(true)

        console.log('Square brackets found in the HTML')
      } else {
        setSquareBracketsFound(false)
        console.log('No square brackets found in the HTML')
      }
    }
  }, [isSquareBracket, selectedSectionStyling])

  return (
    <>
      {!toggleMergeField ? (
        <div className='section-editor tst text-editor-tst'>
          <h2 className='my-3 mx-5'>
            <b>Section {selectedSectInd ? Number(selectedSectInd) + 1 : ''} </b>
          </h2>
          <div className='mx-10 me-12'>
            {/* Section Background Color */}
            <h3 className='my-2 mt-4' style={{color: 'black'}}>
              <label className='fnt-600'> Background Color</label>
            </h3>

            <div className='color-picker-box d-flex align-items-center'>
              <div
                className='color-picker-selected-circle'
                style={{
                  backgroundColor: `${
                    selectedSectionStyling?.content?.backgroundColor
                      ? selectedSectionStyling?.content?.backgroundColor
                      : 'black'
                  }`,
                }}
              ></div>
              <p className='fs-4 ms-2 mb-0'>
                {selectedSectionStyling?.content?.backgroundColor
                  ? selectedSectionStyling?.content?.backgroundColor
                  : '#0000'}
              </p>

              <img
                src={colorPickImg}
                height={25}
                width={25}
                className='ms-auto cursor-pointer'
                onClick={() => {
                  setIsColorPickerVisible(!isColorPickerVisible)
                  setIsColorPickerForButtonTextColorVisible(false)
                  setIsColorPickerForButtonVisible(false)
                  setIsColorPickerForBorder(false)
                  setIsColorPickerForShape(false)
                }}
              />
            </div>

            <div style={{height: '0px'}}>
              <div className='color-picker-div'>
                {isColorPickerVisible && (
                  <ColorPicker
                    color={selectedSectionStyling?.content?.backgroundColor}
                    onChange={(color: any) => {
                      let values = {...selectedSectionStyling}
                      if (selectedSectionStyling != null) values.content.backgroundColor = color.hex
                      setSelectedSectionStyling(values)
                    }}
                    presets={['#6254ff', '#146c6a', '#00b9a9', '#D7197D', '#3573CB', '#E4E4E4']}
                  />
                )}
              </div>
            </div>

            {selectedSectionStyling?.content?.imgSrc == 'svg' && (
              <>
                {/* Section Background Color */}
                <h3 className='my-2 mt-8' style={{color: 'black'}}>
                  <label className='fnt-600'> Shape Color</label>
                </h3>

                <div className='color-picker-box d-flex align-items-center'>
                  <div
                    className='color-picker-selected-circle'
                    style={{
                      backgroundColor: `${
                        selectedSectionStyling?.content?.shapeColor
                          ? selectedSectionStyling?.content?.shapeColor
                          : 'black'
                      }`,
                    }}
                  ></div>
                  <p className='fs-4 ms-2 mb-0'>
                    {selectedSectionStyling?.content?.shapeColor
                      ? selectedSectionStyling?.content?.shapeColor
                      : '#0000'}
                  </p>

                  <img
                    src={colorPickImg}
                    height={25}
                    width={25}
                    className='ms-auto cursor-pointer'
                    onClick={() => {
                      setIsColorPickerForShape(!isColorPickerForShape)
                      setIsColorPickerVisible(false)
                      setIsColorPickerForButtonTextColorVisible(false)
                      setIsColorPickerForButtonVisible(false)
                      setIsColorPickerForBorder(false)
                    }}
                  />
                </div>

                <div style={{height: '0px'}}>
                  <div className='color-picker-div'>
                    {isColorPickerForShape && (
                      <ColorPicker
                        color={selectedSectionStyling?.content?.shapeColor}
                        onChange={async (color: any) => {
                          let values = {...selectedSectionStyling}
                          const sectionElement: any = document.getElementById(
                            selectedSectionStyling?.id
                          )

                          const parser = new DOMParser()
                          const svgDocument = parser.parseFromString(
                            selectedSectionStyling?.content?.svgSrc,
                            'image/svg+xml'
                          )
                          const svgImg: any = sectionElement.querySelector('svg')

                          let pngImg: any = sectionElement.querySelector('img')

                          if (svgImg) {
                            let pathElement = svgImg.querySelector('path')
                            if (pathElement) {
                              pathElement.setAttribute('fill', color?.hex)
                            }

                            const htmlContent = svgImg
                            // let pngReturned = convertSvgToPng(htmlContent)
                            // console.log(pngReturned)

                            try {
                              const pngBlob = await convertSvgToPng(htmlContent)
                              // console.log(pngBlob)

                              // Now upload the PNG if the conversion was successful
                              if (pngBlob) {
                                const pngUploaded = await uploadPNG(
                                  pngBlob,
                                  values,
                                  pngImg,
                                  color,
                                  sectionElement
                                )
                                // console.log(pngUploaded)
                              }
                            } catch (error) {
                              console.error('Error during PNG conversion or upload:', error)
                            }

                            // let pngUploaded = uploadPNG(pngReturned)
                            // console.log(pngUploaded)
                            // values.content.shapeColor = color.hex
                          }

                          // values.content.html = sectionElement?.outerHTML

                          // if (selectedSectionStyling != null) values.content.shapeColor = color.hex
                          // setSelectedSectionStyling(values)
                        }}
                        presets={['#6254ff', '#146c6a', '#00b9a9', '#D7197D', '#3573CB', '#E4E4E4']}
                      />
                    )}
                  </div>
                </div>

                {/* Section Background Color */}
                <h3 className='my-2 mt-8' style={{color: 'black'}}>
                  <label className='fnt-600'> Shape Height</label>
                </h3>

                <div className='d-flex align-items-center'>
                  <div>
                    <input
                      type='number'
                      className='form-control form-control-solid input-style tenancy-control bg-white'
                      name='expenseName'
                      value={Number(selectedSectionStyling?.content?.shapeHeight)}
                      style={{height: '35px', width: '100px'}}
                      onChange={(e: any) => {
                        let values = {...selectedSectionStyling}

                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          let tag:
                            | NodeListOf<HTMLImageElement>
                            | NodeListOf<HTMLButtonElement>
                            | null = null

                          if (
                            selectedSectionStyling?.type == 'image' ||
                            selectedSectionStyling?.type == 'textImage'
                          ) {
                            tag = sectionElement.querySelectorAll('img.svg-img')
                          } else if (selectedSectionStyling?.type == 'button') {
                            tag = sectionElement.querySelectorAll('button')
                          }

                          if (tag) {
                            //
                            tag.forEach((itm, ind) => {
                              itm.style.height = e.target.value + 'px'
                              values.content.shapeHeight = e.target.value
                            })
                          }

                          // Update the content HTML with the modified outerHTML of the section
                          values.content.html = sectionElement.innerHTML
                          setSelectedSectionStyling(values)
                        }

                        //  if (selectedSectionStyling != null){
                        //    values.content.height = e.target.value
                        //    values.content.sizeType = 'px'
                        //  }
                        // setSelectedSectionStyling(values)
                      }}
                      autoComplete='off'
                      placeholder='Height...'
                    />
                  </div>
                  <h3 className='my-2 mx-3 mt-0' style={{color: 'black'}}>
                    <label className='labl-gry fnt-500'> px</label>
                  </h3>
                  {/* <h4 className='my-2 mx-3 mt-0' style={{color: 'black'}}>
                <label className='labl-gry fnt-500'> H</label>
              </h4> */}
                </div>
              </>
            )}

            {selectedSectionStyling?.type == 'button' && (
              <>
                {/* Button Color */}
                <div className='d-flex align-items-color'>
                  <div className='w-50'>
                    <h3 className='my-2 mt-8' style={{color: 'black'}}>
                      <label className='fnt-600'> Button Color</label>
                    </h3>
                    <div className='color-picker-box d-flex align-items-center'>
                      <div
                        className='color-picker-selected-circle'
                        style={{
                          backgroundColor: `${
                            selectedSectionStyling?.content.buttonColor
                              ? selectedSectionStyling?.content.buttonColor
                              : 'black'
                          }`,
                        }}
                      ></div>
                      <p className='fs-4 ms-2 mb-0'>
                        {selectedSectionStyling?.content.buttonColor
                          ? selectedSectionStyling?.content.buttonColor
                          : '#0000'}
                      </p>

                      <img
                        src={colorPickImg}
                        height={25}
                        width={25}
                        className='ms-auto cursor-pointer'
                        onClick={() => {
                          setIsColorPickerForButtonVisible(!isColorPickerForButtonVisible)
                          setIsColorPickerVisible(false)
                          setIsColorPickerForButtonTextColorVisible(false)
                          setIsColorPickerForBorder(false)
                          setIsColorPickerForShape(false)
                        }}
                      />
                    </div>

                    <div style={{height: '0px'}}>
                      <div className='color-picker-div'>
                        {isColorPickerForButtonVisible && (
                          <ColorPicker
                            color={selectedSectionStyling?.content?.buttonColor}
                            onChange={(color: any) => {
                              const values = {...selectedSectionStyling}
                              const sectionElement: any = document.getElementById(
                                selectedSectionStyling?.id
                              )

                              if (sectionElement) {
                                const btn: NodeListOf<HTMLButtonElement> =
                                  sectionElement.querySelectorAll('button')
                                if (btn) {
                                  //
                                  btn?.forEach((itm, ind) => {
                                    itm.style.backgroundColor = color.hex
                                    values.content.buttonColor = color.hex
                                  })
                                }
                              }
                              setSelectedSectionStyling(values)
                            }}
                            presets={[
                              '#6254ff',
                              '#146c6a',
                              '#00b9a9',
                              '#D7197D',
                              '#3573CB',
                              '#E4E4E4',
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-color'>
                  {/* <div className='w-50'> */}
                  {/* Button Text */}
                  {/* <h3 className='my-2 mt-8' style={{color: 'black'}}>
                      <label className='fnt-600'> Button Text</label>
                    </h3> */}
                  {/* <div>
                      <input
                      
                        type='text'
                        className='form-control form-control-solid  bg-white input-style btn-clr-input'
                        name='expenseName'
                        value={selectedSectionStyling?.content?.buttonName}
                        style={{height: '35px'}}
                        placeholder={'Button Text..'}
                        onChange={(e: any) => {
                          const values = {...selectedSectionStyling}
                          const sectionElement: any = document.getElementById(
                            selectedSectionStyling?.id
                          )

                          if (sectionElement) {
                            const btn: NodeListOf<HTMLButtonElement> =
                              sectionElement.querySelectorAll('button')
                            if (btn) {
                              //
                              btn?.forEach((itm, ind) => {
                                itm.innerHTML = e.target.value
                                values.content.buttonName = e.target.value
                              })
                            }
                          }
                          setSelectedSectionStyling(values)
                        }}
                        autoComplete='off'
                      />
                    </div> */}
                  {/* </div> */}
                  <div className=''>
                    {/* Button Text Color */}
                    <h3 className='my-2 mt-8' style={{color: 'black'}}>
                      <label className='fnt-600'> Button Text Color</label>
                    </h3>
                    <div className='color-picker-box d-flex align-items-center '>
                      <div
                        className='color-picker-selected-circle'
                        style={{
                          backgroundColor: `${
                            selectedSectionStyling?.content?.buttonTextColor
                              ? selectedSectionStyling?.content?.buttonTextColor
                              : 'black'
                          }`,
                        }}
                      ></div>
                      <p className='fs-4 ms-2 mb-0'>
                        {selectedSectionStyling?.content?.buttonTextColor
                          ? selectedSectionStyling?.content?.buttonTextColor
                          : '#0000'}
                      </p>

                      <img
                        src={colorPickImg}
                        height={25}
                        width={25}
                        className='ms-auto cursor-pointer'
                        onClick={() => {
                          setIsColorPickerForButtonTextColorVisible(
                            !isColorPickerForButtonTextColorVisible
                          )
                          setIsColorPickerForButtonVisible(false)
                          setIsColorPickerVisible(false)
                          setIsColorPickerForBorder(false)
                          setIsColorPickerForShape(false)
                        }}
                      />
                    </div>

                    <div style={{height: '0px'}}>
                      <div className='color-picker-div'>
                        {isColorPickerForButtonTextColorVisible && (
                          <ColorPicker
                            color={selectedSectionStyling?.content?.buttonTextColor}
                            onChange={(color: any) => {
                              const values = {...selectedSectionStyling}
                              const sectionElement: any = document.getElementById(
                                selectedSectionStyling?.id
                              )

                              if (sectionElement) {
                                const btn: NodeListOf<HTMLButtonElement> =
                                  sectionElement.querySelectorAll('button')
                                if (btn) {
                                  //
                                  btn?.forEach((itm, ind) => {
                                    itm.style.color = color.hex
                                    values.content.buttonTextColor = color.hex
                                  })
                                }
                              }
                              setSelectedSectionStyling(values)
                            }}
                            presets={[
                              '#6254ff',
                              '#146c6a',
                              '#00b9a9',
                              '#D7197D',
                              '#3573CB',
                              '#E4E4E4',
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Padding */}
            {/* {(selectedSectionStyling?.type == 'text' ||
          selectedSectionStyling?.type == 'textImage') && ( */}
            <>
              <h3 className='my-2 mt-8'>
                <label className='fnt-600'>
                  {' '}
                  {selectedSectionStyling?.type == 'image' ||
                  selectedSectionStyling?.type == 'button'
                    ? 'Margin'
                    : 'Padding'}
                </label>
              </h3>
              <div className='d-flex alig-items-center'>
                {/* Top */}
                <div>
                  <h4 className='my-2 mt-3'>
                    <label className='fnt-500'> Top </label>
                  </h4>
                  <div className='d-flex disable-input-stepper'>
                    <button
                      onClick={() => {
                        let values = {...selectedSectionStyling}
                        if (selectedSectionStyling != null)
                          values.content.paddingTop = Number(values.content.paddingTop) - 1
                        setSelectedSectionStyling(values)
                      }}
                      className='stepper-left'
                    >
                      -
                    </button>
                    <input
                      type='number'
                      className='form-control form-control-solid stepper-input-style bg-white'
                      name='expenseName'
                      value={selectedSectionStyling?.content?.paddingTop}
                      style={{
                        height: '35px',
                        width: '50px',
                        borderRadius: '0px',
                        textAlign: 'center', // Center-align text
                        MozAppearance: 'textfield', // For Firefox
                      }}
                      onChange={(e: any) => {
                        let values = {...selectedSectionStyling}
                        if (selectedSectionStyling != null)
                          values.content.paddingTop = e.target.value
                        setSelectedSectionStyling(values)
                      }}
                      autoComplete='off'
                    />
                    <button
                      className='stepper-right'
                      onClick={() => {
                        let values = {...selectedSectionStyling}
                        if (selectedSectionStyling != null)
                          values.content.paddingTop = Number(values.content.paddingTop) + 1
                        setSelectedSectionStyling(values)
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>

                {/* Right */}
                <div className='ms-auto'>
                  <h4 className='my-2 mt-3'>
                    <label className='fnt-500'> Right </label>
                  </h4>
                  <div className='d-flex disable-input-stepper'>
                    <button
                      onClick={() => {
                        let values = {...selectedSectionStyling}
                        if (selectedSectionStyling != null)
                          values.content.paddingRight = Number(values.content.paddingRight) - 1
                        setSelectedSectionStyling(values)
                      }}
                      className='stepper-left'
                    >
                      -
                    </button>
                    <input
                      type='number'
                      className='form-control form-control-solid stepper-input-style bg-white'
                      name='expenseName'
                      value={selectedSectionStyling?.content?.paddingRight}
                      style={{
                        height: '35px',
                        width: '50px',
                        borderRadius: '0px',
                        textAlign: 'center', // Center-align text
                        MozAppearance: 'textfield', // For Firefox
                      }}
                      onChange={(e: any) => {
                        let values = {...selectedSectionStyling}
                        if (selectedSectionStyling != null)
                          values.content.paddingRight = e.target.value
                        setSelectedSectionStyling(values)
                      }}
                      autoComplete='off'
                    />
                    <button
                      className='stepper-right'
                      onClick={() => {
                        let values = {...selectedSectionStyling}
                        if (selectedSectionStyling != null)
                          values.content.paddingRight = Number(values.content.paddingRight) + 1
                        setSelectedSectionStyling(values)
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <div className='d-flex alig-items-center'>
                {/* Bottom */}
                <div>
                  <h4 className='my-2 mt-3'>
                    <label className='fnt-500'> Bottom </label>
                  </h4>
                  <div className='d-flex disable-input-stepper'>
                    <button
                      onClick={() => {
                        let values = {...selectedSectionStyling}
                        if (selectedSectionStyling != null)
                          values.content.paddingBottom = Number(values.content.paddingBottom) - 1
                        setSelectedSectionStyling(values)
                      }}
                      className='stepper-left'
                    >
                      -
                    </button>
                    <input
                      type='number'
                      className='form-control form-control-solid stepper-input-style bg-white'
                      name='expenseName'
                      value={selectedSectionStyling?.content?.paddingBottom}
                      style={{
                        height: '35px',
                        width: '50px',
                        borderRadius: '0px',
                        textAlign: 'center', // Center-align text
                        MozAppearance: 'textfield', // For Firefox
                      }}
                      onChange={(e: any) => {
                        let values = {...selectedSectionStyling}
                        if (selectedSectionStyling != null)
                          values.content.paddingBottom = e.target.value
                        setSelectedSectionStyling(values)
                      }}
                      autoComplete='off'
                    />
                    <button
                      className='stepper-right'
                      onClick={() => {
                        let values = {...selectedSectionStyling}
                        if (selectedSectionStyling != null)
                          values.content.paddingBottom = Number(values.content.paddingBottom) + 1
                        setSelectedSectionStyling(values)
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>

                {/* Left */}
                <div className='ms-auto'>
                  <h4 className='my-2 mt-3'>
                    <label className='fnt-500'> Left </label>
                  </h4>
                  <div className='d-flex disable-input-stepper'>
                    <button
                      onClick={() => {
                        let values = {...selectedSectionStyling}
                        if (selectedSectionStyling != null)
                          values.content.paddingLeft = Number(values.content.paddingLeft) - 1
                        setSelectedSectionStyling(values)
                      }}
                      className='stepper-left'
                    >
                      -
                    </button>
                    <input
                      type='number'
                      className='form-control form-control-solid stepper-input-style bg-white'
                      name='expenseName'
                      value={selectedSectionStyling?.content?.paddingLeft}
                      style={{
                        height: '35px',
                        width: '50px',
                        borderRadius: '0px',
                        textAlign: 'center', // Center-align text
                        MozAppearance: 'textfield', // For Firefox
                      }}
                      onChange={(e: any) => {
                        let values = {...selectedSectionStyling}
                        if (selectedSectionStyling != null)
                          values.content.paddingLeft = e.target.value
                        setSelectedSectionStyling(values)
                      }}
                      autoComplete='off'
                    />
                    <button
                      className='stepper-right'
                      onClick={() => {
                        let values = {...selectedSectionStyling}
                        if (selectedSectionStyling != null)
                          values.content.paddingLeft = Number(values.content.paddingLeft) + 1
                        setSelectedSectionStyling(values)
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </>
            {/* )} */}

            {/* Margin */}
            {selectedSectionStyling?.type != 'image' && selectedSectionStyling?.type != 'button' && (
              <>
                <h3 className='my-2 mt-8'>
                  <label className='fnt-600'> Margin</label>
                </h3>
                <div className='d-flex alig-items-center'>
                  {/* Top */}
                  <div>
                    <h4 className='my-2 mt-3'>
                      <label className='fnt-500'> Top </label>
                    </h4>
                    <div className='d-flex disable-input-stepper'>
                      <button
                        onClick={() => {
                          let values = {...selectedSectionStyling}
                          if (selectedSectionStyling != null)
                            values.content.marginTop = Number(values.content.marginTop) - 1
                          setSelectedSectionStyling(values)
                        }}
                        className='stepper-left'
                      >
                        -
                      </button>
                      <input
                        type='number'
                        className='form-control form-control-solid stepper-input-style bg-white'
                        name='expenseName'
                        value={selectedSectionStyling?.content?.marginTop}
                        style={{
                          height: '35px',
                          width: '50px',
                          borderRadius: '0px',
                          textAlign: 'center', // Center-align text
                          MozAppearance: 'textfield', // For Firefox
                        }}
                        onChange={(e: any) => {
                          let values = {...selectedSectionStyling}
                          if (selectedSectionStyling != null)
                            values.content.marginTop = e.target.value
                          setSelectedSectionStyling(values)
                        }}
                        autoComplete='off'
                      />
                      <button
                        className='stepper-right'
                        onClick={() => {
                          let values = {...selectedSectionStyling}
                          if (selectedSectionStyling != null)
                            values.content.marginTop = Number(values.content.marginTop) + 1
                          setSelectedSectionStyling(values)
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  {/* Right */}
                  <div className='ms-auto'>
                    <h4 className='my-2 mt-3'>
                      <label className='fnt-500'> Right </label>
                    </h4>
                    <div className='d-flex disable-input-stepper'>
                      <button
                        onClick={() => {
                          let values = {...selectedSectionStyling}
                          if (selectedSectionStyling != null)
                            values.content.marginRight = Number(values.content.marginRight) - 1
                          setSelectedSectionStyling(values)
                        }}
                        className='stepper-left'
                      >
                        -
                      </button>
                      <input
                        type='number'
                        className='form-control form-control-solid stepper-input-style bg-white'
                        name='expenseName'
                        value={selectedSectionStyling?.content?.marginRight}
                        style={{
                          height: '35px',
                          width: '50px',
                          borderRadius: '0px',
                          textAlign: 'center', // Center-align text
                          MozAppearance: 'textfield', // For Firefox
                        }}
                        onChange={(e: any) => {
                          let values = {...selectedSectionStyling}
                          if (selectedSectionStyling != null)
                            values.content.marginRight = e.target.value
                          setSelectedSectionStyling(values)
                        }}
                        autoComplete='off'
                      />
                      <button
                        className='stepper-right'
                        onClick={() => {
                          let values = {...selectedSectionStyling}
                          if (selectedSectionStyling != null)
                            values.content.marginRight = Number(values.content.marginRight) + 1
                          setSelectedSectionStyling(values)
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>

                <div className='d-flex alig-items-center'>
                  {/* Bottom */}
                  <div>
                    <h4 className='my-2 mt-3'>
                      <label className='fnt-500'> Bottom </label>
                    </h4>
                    <div className='d-flex disable-input-stepper'>
                      <button
                        onClick={() => {
                          let values = {...selectedSectionStyling}
                          if (selectedSectionStyling != null)
                            values.content.marginBottom = Number(values.content.marginBottom) - 1
                          setSelectedSectionStyling(values)
                        }}
                        className='stepper-left'
                      >
                        -
                      </button>
                      <input
                        type='number'
                        className='form-control form-control-solid stepper-input-style bg-white'
                        name='expenseName'
                        value={selectedSectionStyling?.content?.marginBottom}
                        style={{
                          height: '35px',
                          width: '50px',
                          borderRadius: '0px',
                          textAlign: 'center', // Center-align text
                          MozAppearance: 'textfield', // For Firefox
                        }}
                        onChange={(e: any) => {
                          let values = {...selectedSectionStyling}
                          if (selectedSectionStyling != null)
                            values.content.marginBottom = e.target.value
                          setSelectedSectionStyling(values)
                        }}
                        autoComplete='off'
                      />
                      <button
                        className='stepper-right'
                        onClick={() => {
                          let values = {...selectedSectionStyling}
                          if (selectedSectionStyling != null)
                            values.content.marginBottom = Number(values.content.marginBottom) + 1
                          setSelectedSectionStyling(values)
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  {/* Left */}
                  <div className='ms-auto'>
                    <h4 className='my-2 mt-3'>
                      <label className='fnt-500'> Left </label>
                    </h4>
                    <div className='d-flex disable-input-stepper'>
                      <button
                        onClick={() => {
                          let values = {...selectedSectionStyling}
                          if (selectedSectionStyling != null)
                            values.content.marginLeft = Number(values.content.marginLeft) - 1
                          setSelectedSectionStyling(values)
                        }}
                        className='stepper-left'
                      >
                        -
                      </button>
                      <input
                        type='number'
                        className='form-control form-control-solid stepper-input-style bg-white'
                        name='expenseName'
                        value={selectedSectionStyling?.content?.marginLeft}
                        style={{
                          height: '35px',
                          width: '50px',
                          borderRadius: '0px',
                          textAlign: 'center', // Center-align text
                          MozAppearance: 'textfield', // For Firefox
                        }}
                        onChange={(e: any) => {
                          let values = {...selectedSectionStyling}
                          if (selectedSectionStyling != null)
                            values.content.marginLeft = e.target.value
                          setSelectedSectionStyling(values)
                        }}
                        autoComplete='off'
                      />
                      <button
                        className='stepper-right'
                        onClick={() => {
                          let values = {...selectedSectionStyling}
                          if (selectedSectionStyling != null)
                            values.content.marginLeft = Number(values.content.marginLeft) + 1
                          setSelectedSectionStyling(values)
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Destination Link */}
            {selectedSectionStyling?.type == 'button' && (
              <>
                <div className='position-relative'>
                  {squareBracketsFound && (
                    <div
                      className='requestBoxOpacity z-index-2 border-radius-5px d-flex align-items-center justify-content-center'
                      style={{
                        boxShadow: '0px 0px 20px 0px rgba(10, 24, 64, -0.8)',
                      }}
                    ></div>
                  )}
                  <div className='d-flex align-items-center tst mb-4 mt-8 pt-2'>
                    <div style={{width: '25px'}}>
                      <input
                        type='radio'
                        className='me-3 cursor-pointer form-check-input'
                        id='staticLink'
                        value='staticLink'
                        checked={selectedSectionStyling?.content?.staticLink == 1}
                        onChange={(e: any) => {
                          const values = {...selectedSectionStyling}

                          const sectionElement: any = document.getElementById(
                            selectedSectionStyling?.id
                          )

                          const tags: NodeListOf<HTMLElement> =
                            sectionElement.querySelectorAll('#borderOfDynamicButton') // Using the class selector

                          console.log(tags)

                          // Apply styles to each element in the NodeList
                          tags.forEach((tag) => {
                            tag.style.border = '2px dashed #f5c71a'
                            tag.style.padding = '5px'
                          })

                          const anchortag: NodeListOf<HTMLAnchorElement> =
                            sectionElement.querySelectorAll('a')
                          console.log(anchortag)

                          const btn: NodeListOf<HTMLButtonElement> =
                            sectionElement.querySelectorAll('button')
                          console.log(btn)
                          if (anchortag.length > 0 && btn.length > 0) {
                            //
                            // tag.forEach((itm, ind) => {
                            anchortag[0].href = ''
                            // tag[0].innerHTML = btn
                            // })
                          }

                          values.content.dynamicStyle.border = '2px dashed #f5c71a'
                          values.content.dynamicStyle.padding = '5px'

                          values.content.dynamicDocument = []

                          values.content.staticLink = 1

                          values.content.html = sectionElement.innerHTML

                          setSelectedSectionStyling(values)
                        }}
                      />
                    </div>
                    <div className='position-relative'>
                      {selectedSectionStyling?.content?.staticLink == 0 && (
                        <div
                          className='requestBoxOpacity z-index-2 border-radius-5px d-flex align-items-center justify-content-center'
                          style={{
                            boxShadow: '0px 0px 20px 0px rgba(10, 24, 64, -0.8)',
                          }}
                        ></div>
                      )}
                      <h3 className='mb-1 '>
                        <label
                          htmlFor='staticLink'
                          className='cursor-pointer'
                          style={{minWidth: '320px'}}
                        >
                          Static Destination Link
                        </label>
                      </h3>
                    </div>
                    <div style={{width: '25px'}}>
                      <input
                        type='radio'
                        className='me-3 cursor-pointer form-check-input ms-auto'
                        id='dynamicLink'
                        value='dynamicLink'
                        checked={selectedSectionStyling?.content?.staticLink == 0}
                        onChange={(e: any) => {
                          const values = {...selectedSectionStyling}
                          const sectionElement: any = document.getElementById(
                            selectedSectionStyling?.id
                          )

                          const tags: NodeListOf<HTMLElement> =
                            sectionElement.querySelectorAll('#borderOfDynamicButton') // Using the class selector

                          console.log(tags)

                          // Apply styles to each element in the NodeList
                          tags.forEach((tag) => {
                            tag.style.border = '2px dashed #0065bd'
                            tag.style.padding = '5px'
                          })

                          const anchortag: NodeListOf<HTMLAnchorElement> =
                            sectionElement.querySelectorAll('a')
                          console.log(anchortag)

                          const btn: NodeListOf<HTMLButtonElement> =
                            sectionElement.querySelectorAll('button')
                          console.log(btn)
                          if (anchortag.length > 0 && btn.length > 0) {
                            //
                            // tag.forEach((itm, ind) => {
                            anchortag[0].href = ''
                            // tag[0].innerHTML = btn
                            // })
                          }

                          values.content.staticLink = 0
                          values.content.buttonLink = ''
                          values.content.dynamicStyle.border = '2px dashed #0065bd'
                          values.content.dynamicStyle.padding = '5px'

                          values.content.html = sectionElement.innerHTML
                          setSelectedSectionStyling(values)
                        }}
                      />
                    </div>
                    <div className='position-relative'>
                      {selectedSectionStyling?.content?.staticLink == 1 && (
                        <div
                          className='requestBoxOpacity z-index-2 border-radius-5px d-flex align-items-center justify-content-center'
                          style={{
                            boxShadow: '0px 0px 20px 0px rgba(10, 24, 64, -0.8)',
                          }}
                        ></div>
                      )}
                      <h3 className='mb-1'>
                        <label
                          htmlFor='dynamicLink '
                          className='cursor-pointer'
                          style={{minWidth: '320px'}}
                        >
                          Dynamic Destination Link
                        </label>
                      </h3>
                    </div>
                  </div>
                </div>

                {selectedSectionStyling?.content?.staticLink == 1 ? (
                  <>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label className='labl-gry '>
                        {' '}
                        If user click here they will be directed to the destination set:
                      </label>
                    </h4>

                    <div className='d-flex my-2 mt-4'>
                      <div className='property-test d-none-multi-checbox w-50'>
                        <div
                          className={`multi-select-container jobs-property ms-0 me-5   ${
                            selectedSectionStyling?.link?.[0]?.value?.trim()?.length === 0
                              ? 'no-value select '
                              : 'has-value'
                          }`}
                        >
                          <input
                            //   {...register('expenseName', {required: true})}
                            type='text'
                            className='form-control form-control-solid  bg-white input-style btn-clr-input'
                            name='expenseName'
                            value={selectedSectionStyling?.content?.buttonLink}
                            style={{height: '35px'}}
                            placeholder={'Destination Link..'}
                            onChange={(e: any) => {
                              const values = {...selectedSectionStyling}
                              const sectionElement: any = document.getElementById(
                                selectedSectionStyling?.id
                              )

                              if (sectionElement) {
                                const tags: NodeListOf<HTMLElement> =
                                  sectionElement.querySelectorAll('#borderOfDynamicButton') // Using the class selector

                                console.log(tags)

                                // Apply styles to each element in the NodeList

                                const anchorTag: NodeListOf<HTMLAnchorElement> =
                                  sectionElement.querySelectorAll('a')
                                console.log(anchorTag)

                                const btn: NodeListOf<HTMLButtonElement> =
                                  sectionElement.querySelectorAll('button')
                                console.log(btn)
                                if (anchorTag.length > 0 && btn.length > 0) {
                                  //
                                  // tag.forEach((itm, ind) => {
                                  anchorTag[0].href = e.target.value
                                  // tag[0].innerHTML = btn
                                  // })

                                  if (e?.target?.value?.trim()?.length > 0) {
                                    tags.forEach((tag) => {
                                      tag.style.border = '2px solid #f5c71a'
                                      tag.style.padding = '5px'
                                    })
                                    values.content.dynamicStyle.border = '2px solid #f5c71a'
                                    values.content.dynamicStyle.padding = '5px'
                                  } else {
                                    tags.forEach((tag) => {
                                      tag.style.border = '2px dashed #f5c71a'
                                      tag.style.padding = '5px'
                                    })

                                    values.content.dynamicStyle.border = '2px dashed #f5c71a'
                                    values.content.dynamicStyle.padding = '5px'
                                  }

                                  values.content.buttonLink = e.target.value
                                  values.content.html = sectionElement.innerHTML
                                }
                              }
                              setSelectedSectionStyling(values)
                            }}
                            autoComplete='off'
                          />
                          {/* <MultiSelect
                options={linkLists}
                value={
                  selectedSectionStyling?.link?.[0]?.value?.trim()?.length > 0
                    ? selectedSectionStyling?.link?.[0]?.value
                    : []
                }
                onChange={(e: any) => {
                  let newVal: any = e
                  if (e?.length > 1) newVal = e.slice(1)

                  let values = {...selectedSectionStyling}
                  values.height = newVal
                  setSelectedSectionStyling(values)
                }}
                labelledBy=''
                overrideStrings={{
                  allItemsAreSelected: '',
                }}
                hasSelectAll={false}
                closeOnChangedValue={true}
              /> */}
                        </div>
                      </div>
                      {/* <div className='d-flex align-items-center ms-5 w-50'>
                        <div
                          className={`d-flex align-items-center justify-content-center ${
                            selectedSectionStyling?.content?.link?.[0]?.display == 0
                              ? 'green-radio-filled'
                              : ' green-radio-hollow'
                          } h-18px w-18px me-3 cursor-pointer`}
                          onClick={() => {
                            let values = {...selectedSectionStyling}
                            if (Number(values.content.link[0].display) == 0)
                              values.content.link[0].display = 1
                            else values.content.link[0].display = 0
                            setSelectedSectionStyling(values)
                          }}
                        >
                          <img src={whiteTick} height={10} width={10} />
                        </div>
                        <h4 className='mb-0'>No Link</h4>
                      </div> */}
                    </div>
                  </>
                ) : selectedSectionStyling?.content?.staticLink == 0 ? (
                  <>
                    <h3 className='my-2 mt-8 me-5 d-flex align-items-center'>
                      <label className='fnt-600'> Dynamic File/Document </label>
                      <label
                        className='ms-auto text-decoration-underline cursor-pointer'
                        style={{color: 'var(--kt-danger)'}}
                        onClick={() => {
                          const values = {...selectedSectionStyling}

                          const sectionElement: any = document.getElementById(
                            selectedSectionStyling?.id
                          )

                          const tags: NodeListOf<HTMLElement> =
                            sectionElement.querySelectorAll('#borderOfDynamicButton') // Using the class selector

                          console.log(tags)

                          // Apply styles to each element in the NodeList
                          tags.forEach((tag) => {
                            tag.style.border = '2px dashed #0065bd'
                            tag.style.padding = '5px'
                          })

                          const anchortag: NodeListOf<HTMLAnchorElement> =
                            sectionElement.querySelectorAll('a')
                          console.log(anchortag)

                          const btn: NodeListOf<HTMLButtonElement> =
                            sectionElement.querySelectorAll('button')
                          console.log(btn)
                          if (anchortag.length > 0 && btn.length > 0) {
                            //
                            // tag.forEach((itm, ind) => {
                            anchortag[0].href = ''
                            // tag[0].innerHTML = btn
                            // })
                          }

                          values.content.dynamicStyle.border = '2px dashed #0065bd'
                          values.content.dynamicStyle.padding = '5px'
                          values.content.dynamicDocument = []

                          values.content.html = sectionElement.innerHTML

                          setSelectedSectionStyling(values)
                        }}
                      >
                        Remove
                      </label>
                    </h3>
                    <p className='labl-gry fs-4 mb-4 me-5'>
                      Select a system generated file that you would like to embed within the
                      selected text. Recipient will be able to click on the text and preview the
                      file generated:
                    </p>
                    <div className='tst'>
                      <div className='property-test d-none-multi-checbox me-5 text-editor mb-3'>
                        <div
                          className={`multi-select-container jobs-property ms-0 me-5    ${
                            selectedSectionStyling?.content?.dynamicDocument?.length === 0
                              ? 'no-value select '
                              : 'has-value'
                          }`}
                        >
                          <MultiSelect
                            options={documentOptions}
                            value={selectedSectionStyling?.content?.dynamicDocument}
                            ItemRenderer={customOptionRenderer}
                            onChange={(e: any) => {
                              let newVal: any = e
                              console.log(newVal)
                              if (e?.length > 1) newVal = e.slice(1)
                              setSelectedMergeDocument(newVal)

                              handleMergeDocumentChange(newVal)
                            }}
                            labelledBy='' // Empty as we are overriding the label below
                            overrideStrings={{
                              allItemsAreSelected:
                                selectedMergeDocument?.[0]?.label || 'Item selected', // Fallback to 'Item selected' if label is undefined
                            }}
                            hasSelectAll={false}
                            closeOnChangedValue={true}
                          />
                        </div>
                      </div>
                    </div>

                    {selectedMergeDocument?.length > 0 && (
                      <InfoCard selectedItem={selectedMergeDocument[0]} />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}

            {/* Image */}
            {(selectedSectionStyling?.type == 'image' ||
              selectedSectionStyling?.type == 'textImage') && (
              <>
                {Array.from(selectedSectionImages)?.length > 0 && (
                  <h3 className='my-2 mt-8'>
                    <label className='fnt-600'> Edit Images</label>
                  </h3>
                )}

                {Array.from(selectedSectionImages)?.map((image: any, imgInd: any) => (
                  <div key={imgInd}>
                    <h3 className='my-2 mt-8'>
                      <label className='fnt-600'>Image {imgInd + 1}</label>
                    </h3>
                    <div className='d-flex my-2 mt-4'>
                      <div className='w-50'>
                        {/* <h4 className=''>Upload</h4>
            <button
              type='button'
              className='btn btn-sm fw-bold green-submit-btn px-2 justify-content-center status-w-120 mt-3'
              onClick={() => {}}
            >
              Upload
            </button> */}
                        <div className='position-relative'>
                          {selectedSectionStyling?.content?.image?.[imgInd]?.display == 0 && (
                            <div
                              className='requestBoxOpacity z-index-2 border-radius-5px d-flex align-items-center justify-content-center'
                              style={{
                                boxShadow: '0px 0px 20px 0px rgba(10, 24, 64, -0.8)',
                              }}
                            ></div>
                          )}
                          <label
                            className='btn btn-sm fw-bold  me-0 green-submit-btn btn-fit-content px-7'
                            onClick={() => {}}
                            style={{width: 'fit-content'}}
                            htmlFor={`uploadNew${imgInd}`}
                          >
                            {/* <img src={uploadWhite} height={18} width={18} className='me-3' /> */}
                            Upload
                          </label>
                        </div>
                        <input
                          type='file'
                          hidden
                          id={`uploadNew${imgInd}`}
                          className='form-control form-control-solid'
                          placeholder='upload'
                          name={`newImg${imgInd}`}
                          accept='image/png, image/jpeg, image/gif, image/webp'
                          onChange={(e: any) => {
                            handleChange(e, imgInd)
                          }}
                          disabled={selectedSectionStyling?.content?.image?.[imgInd]?.display == 0}
                        />
                      </div>

                      <div className='d-flex align-items-center ms-5 w-50'>
                        <div
                          className={`d-flex align-items-center justify-content-center ${
                            selectedSectionStyling?.content?.image?.[imgInd]?.display == 0
                              ? 'green-radio-filled'
                              : ' green-radio-hollow'
                          } h-18px w-18px me-3 cursor-pointer`}
                          onClick={() => {
                            let values = {...selectedSectionStyling}
                            if (Number(values.content.image[imgInd].display) == 0)
                              values.content.image[imgInd].display = 1
                            else values.content.image[imgInd].display = 0

                            const sectionElement: any = document.getElementById(
                              selectedSectionStyling?.id
                            )

                            if (sectionElement) {
                              // Select the image element inside the section
                              const img: NodeListOf<HTMLImageElement> =
                                sectionElement.querySelectorAll('img:not(.svg-img)')
                              if (img) {
                                //
                                Array.from(img)[imgInd].src = ''
                                Array.from(img)[imgInd].style.height = 0 + 'px'
                                Array.from(img)[imgInd].style.width = 0 + 'px'

                                // Update the content HTML with the modified outerHTML of the section
                                values.content.html = sectionElement.innerHTML

                                // Update the selected section styling state
                                setSelectedSectionStyling(values)
                              }
                            }

                            setSelectedSectionStyling(values)
                          }}
                        >
                          <img src={whiteTick} height={10} width={10} />
                        </div>
                        <h4 className='mb-0'>Remove Image</h4>
                      </div>
                    </div>

                    {selectedSectionStyling?.type == 'image' && (
                      <>
                        <div className='d-flex my-2 mt-4'>
                          <div className='property-test d-none-multi-checbox w-50'>
                            <div
                              className={`multi-select-container jobs-property ms-0 me-5   ${
                                selectedSectionStyling?.link?.[0]?.value?.trim()?.length === 0
                                  ? 'no-value select '
                                  : 'has-value'
                              }`}
                            >
                              <input
                                //   {...register('expenseName', {required: true})}
                                type='text'
                                className='form-control form-control-solid  bg-white input-style btn-clr-input'
                                name='expenseName'
                                value={selectedSectionStyling?.content?.image?.[imgInd]?.link}
                                style={{height: '35px'}}
                                placeholder={'Destination Link..'}
                                onChange={(e: any) => {
                                  const values = {...selectedSectionStyling}
                                  const sectionElement: any = document.getElementById(
                                    selectedSectionStyling?.id
                                  )

                                  if (sectionElement) {
                                    const tag: NodeListOf<HTMLAnchorElement> =
                                      sectionElement.querySelectorAll('a')
                                    if (tag) {
                                      //
                                      tag.forEach((itm, ind) => {
                                        if (ind == imgInd) itm.href = e.target.value
                                      })
                                      values.content.image[imgInd].link = e.target.value
                                    }
                                  }
                                  setSelectedSectionStyling(values)
                                }}
                                autoComplete='off'
                                disabled={
                                  selectedSectionStyling?.content?.image?.[imgInd]?.linkDisplay == 0
                                }
                              />
                            </div>
                          </div>
                          <div className='d-flex align-items-center ms-5 w-50'>
                            <div
                              className={`d-flex align-items-center justify-content-center ${
                                selectedSectionStyling?.content?.image?.[imgInd]?.linkDisplay == 0
                                  ? 'green-radio-filled'
                                  : ' green-radio-hollow'
                              } h-18px w-18px me-3 cursor-pointer`}
                              onClick={() => {
                                let values = {...selectedSectionStyling}
                                const sectionElement: any = document.getElementById(
                                  selectedSectionStyling?.id
                                )

                                if (Number(values?.content?.image?.[imgInd]?.linkDisplay) === 0) {
                                  values.content.image[imgInd].linkDisplay = 1

                                  // Re-wrap the image with <a> tag
                                  if (sectionElement) {
                                    const imgTag: NodeListOf<HTMLImageElement> =
                                      sectionElement.querySelectorAll('img:not(.svg-img)')
                                    if (imgTag) {
                                      imgTag.forEach((img, ind) => {
                                        if (ind === imgInd) {
                                          const newAnchor = document.createElement('a')
                                          newAnchor.href = values.content.image[imgInd].link
                                          img.parentNode?.insertBefore(newAnchor, img)
                                          newAnchor.appendChild(img)
                                        }
                                      })
                                    }
                                  }
                                } else {
                                  values.content.image[imgInd].linkDisplay = 0

                                  // Remove the <a> tag
                                  if (sectionElement) {
                                    const tag: NodeListOf<HTMLAnchorElement> =
                                      sectionElement.querySelectorAll('a')
                                    if (tag) {
                                      tag.forEach((itm, ind) => {
                                        if (ind === imgInd) {
                                          const parent = itm.parentNode
                                          if (parent) {
                                            // Replace <a> with its child nodes (removing <a>)
                                            while (itm.firstChild) {
                                              parent.insertBefore(itm.firstChild, itm)
                                            }
                                            parent.removeChild(itm)
                                          }
                                        }
                                      })
                                    }
                                  }
                                  values.content.image[imgInd].link = ''
                                }
                                setSelectedSectionStyling(values)
                              }}
                            >
                              <img src={whiteTick} height={10} width={10} />
                            </div>
                            <h4 className='mb-0'>No Link</h4>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </>
            )}

            {/* Alignment */}
            {(selectedSectionStyling?.type == 'image' ||
              selectedSectionStyling?.type == 'button') && (
              <>
                <h3 className='my-2 mt-8'>
                  <label className='fnt-600'>Alignment</label>
                </h3>
                <div
                  className='d-flex align-items-center mt-3'
                  style={{backgroundColor: '#f0f1f4', width: 'fit-content'}}
                >
                  <img
                    src={alignLeftImg}
                    height={50}
                    width={50}
                    className={`p-2 cursor-pointer ${
                      selectedSectionStyling?.content?.justifyContent == 'left'
                        ? 'selected-alignment'
                        : ''
                    }`}
                    onClick={(e: any) => {
                      let values = {...selectedSectionStyling}
                      values.content.justifyContent = 'left'

                      if (
                        values?.content?.imgSrc == 'svg' ||
                        values?.content?.imgSrc == 'headerImg'
                      ) {
                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          const sectionElementImg: any = document.getElementById('img-in-svg')
                          sectionElementImg.style.marginLeft = '0px'
                          sectionElementImg.style.marginRight = 'auto'
                        }

                        values.content.html = sectionElement.innerHTML
                      }

                      setSelectedSectionStyling(values)
                    }}
                  />
                  <img
                    src={alignCenterImg}
                    height={50}
                    width={50}
                    className={`p-2 cursor-pointer ${
                      selectedSectionStyling?.content?.justifyContent == 'center'
                        ? 'selected-alignment'
                        : ''
                    }`}
                    onClick={(e: any) => {
                      let values = {...selectedSectionStyling}
                      values.content.justifyContent = 'center'

                      if (
                        values?.content?.imgSrc == 'svg' ||
                        values?.content?.imgSrc == 'headerImg'
                      ) {
                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          const sectionElementImg: any = document.getElementById('img-in-svg')
                          sectionElementImg.style.marginLeft = 'auto'
                          sectionElementImg.style.marginRight = 'auto'
                        }

                        values.content.html = sectionElement.innerHTML
                      }

                      setSelectedSectionStyling(values)
                    }}
                  />
                  <img
                    src={alignRightImg}
                    height={50}
                    width={50}
                    className={`p-2 cursor-pointer ${
                      selectedSectionStyling?.content?.justifyContent == 'right'
                        ? 'selected-alignment'
                        : ''
                    }`}
                    onClick={(e: any) => {
                      let values = {...selectedSectionStyling}
                      values.content.justifyContent = 'right'

                      if (
                        values?.content?.imgSrc == 'svg' ||
                        values?.content?.imgSrc == 'headerImg'
                      ) {
                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          const sectionElementImg: any = document.getElementById('img-in-svg')
                          sectionElementImg.style.marginLeft = 'auto'
                          sectionElementImg.style.marginRight = '0px'
                        }

                        values.content.html = sectionElement.innerHTML
                      }

                      setSelectedSectionStyling(values)
                    }}
                  />
                  <img
                    src={justifyContentImg}
                    height={50}
                    width={50}
                    className={`p-2 cursor-pointer ${
                      selectedSectionStyling?.content?.justifyContent == 'space-between'
                        ? 'selected-alignment'
                        : ''
                    }`}
                    onClick={(e: any) => {
                      let values = {...selectedSectionStyling}
                      values.content.justifyContent = 'space-between'

                      if (
                        values?.content?.imgSrc == 'svg' ||
                        values?.content?.imgSrc == 'headerImg'
                      ) {
                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          const sectionElementImg: any = document.getElementById('img-in-svg')
                          sectionElementImg.style.marginLeft = 'auto'
                          sectionElementImg.style.marginRight = 'auto'
                        }

                        values.content.html = sectionElement.innerHTML
                      }

                      setSelectedSectionStyling(values)
                    }}
                  />
                </div>
              </>
            )}

            {/* Size */}
            {selectedSectionStyling?.type != 'text' && selectedSectionStyling?.type != 'button' && (
              <>
                <h3 className='my-2 mt-8'>
                  <label className='fnt-600'>
                    {' '}
                    {selectedSectionStyling?.type == 'textImage' ? 'Image Size' : 'Size'}
                  </label>
                </h3>

                <div className='d-flex'>
                  <div>
                    <input
                      type='number'
                      className='form-control form-control-solid input-style tenancy-control bg-white'
                      name='expenseName'
                      value={Number(selectedSectionStyling?.content?.width)}
                      style={{height: '35px', width: '100px', border: '2px solid #2f2d2d8c'}}
                      onChange={(e: any) => {
                        let values = {...selectedSectionStyling}

                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          // Select the image element inside the section
                          // console.log(img)
                          // Array.from(img).map((itm, ind) => {
                          //   console.log(itm)
                          // })

                          let tag:
                            | NodeListOf<HTMLImageElement>
                            | NodeListOf<HTMLButtonElement>
                            | null = null

                          if (
                            selectedSectionStyling?.type == 'image' ||
                            selectedSectionStyling?.type == 'textImage'
                          ) {
                            tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                          } else if (selectedSectionStyling?.type == 'button') {
                            tag = sectionElement.querySelectorAll('button')
                          }

                          if (tag) {
                            //
                            tag.forEach((itm, ind) => {
                              itm.style.width = e.target.value + 'px'
                              values.content.width = e.target.value
                            })
                          }

                          // Update the content HTML with the modified outerHTML of the section
                          values.content.html = sectionElement.innerHTML
                          setSelectedSectionStyling(values)
                        }

                        // const doc :any= document.getElementById(selectedSectionStyling?.id)
                        // console.log(doc)
                        // const img:any = doc.querySelector('img');
                        // console.log(img)
                        // img.style.width = e.target.value+'px'

                        // const div = document.getElementById(selectedSectionStyling?.id)
                        // console.log(div)
                        // values.content.html = `${div}`
                        // console.log(values.content.html)

                        // if (selectedSectionStyling != null) {
                        //   values.content.width = e.target.value
                        //   values.content.sizeType = 'px'
                        // }
                        // setSelectedSectionStyling(values)
                      }}
                      autoComplete='off'
                      placeholder='Width...'
                      // disabled={selectedSectionStyling?.content?.imgSrc != undefined}
                    />
                    <h3 className='my-2 mx-3' style={{color: 'black'}}>
                      <label className='labl-gry fnt-500'> px</label>
                    </h3>
                  </div>
                  <h4 className='my-2 mx-3' style={{color: 'black'}}>
                    <label className='labl-gry fnt-500'> W</label>
                  </h4>

                  <div>
                    <input
                      type='number'
                      className='form-control form-control-solid input-style tenancy-control bg-white'
                      name='expenseName'
                      value={Number(selectedSectionStyling?.content?.height)}
                      style={{height: '35px', width: '100px'}}
                      onChange={(e: any) => {
                        let values = {...selectedSectionStyling}

                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          let tag:
                            | NodeListOf<HTMLImageElement>
                            | NodeListOf<HTMLButtonElement>
                            | null = null

                          if (
                            selectedSectionStyling?.type == 'image' ||
                            selectedSectionStyling?.type == 'textImage'
                          ) {
                            tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                          } else if (selectedSectionStyling?.type == 'button') {
                            tag = sectionElement.querySelectorAll('button')
                          }

                          if (tag) {
                            //
                            tag.forEach((itm, ind) => {
                              itm.style.height = e.target.value + 'px'
                              values.content.height = e.target.value
                            })
                          }

                          // Update the content HTML with the modified outerHTML of the section
                          values.content.html = sectionElement.innerHTML
                          setSelectedSectionStyling(values)
                        }

                        //  if (selectedSectionStyling != null){
                        //    values.content.height = e.target.value
                        //    values.content.sizeType = 'px'
                        //  }
                        // setSelectedSectionStyling(values)
                      }}
                      autoComplete='off'
                      placeholder='Height...'
                    />
                    <h3 className='my-2 mx-3' style={{color: 'black'}}>
                      <label className='labl-gry fnt-500'> px</label>
                    </h3>
                  </div>
                  <h4 className='my-2 mx-3' style={{color: 'black'}}>
                    <label className='labl-gry fnt-500'> H</label>
                  </h4>
                </div>

                {/* {selectedSectionStyling && ( */}
                <>
                  {(selectedSectionStyling?.type == 'image' ||
                    selectedSectionStyling?.type == 'button') && (
                    <Box className='mt-4'>
                      <PrettoSlider
                        valueLabelDisplay='auto'
                        aria-label='pretto slider'
                        defaultValue={selectedSectionStyling?.content?.height}
                        value={selectedSectionStyling?.content?.height}
                        onChange={(e: Event, newValue: number | number[]) => {
                          let values = {...selectedSectionStyling}
                          const sectionElement = document.getElementById(selectedSectionStyling?.id)

                          if (sectionElement) {
                            if (selectedSectionStyling?.type === 'image') {
                              const img: NodeListOf<HTMLImageElement> =
                                sectionElement.querySelectorAll('img:not(.svg-img)')
                              img.forEach((itm) => {
                                itm.style.height = newValue + '%'
                                itm.style.width = newValue + '%'

                                values.content.height = newValue
                                values.content.width = newValue
                              })
                            } else if (selectedSectionStyling?.type === 'button') {
                              const btn: NodeListOf<HTMLButtonElement> =
                                sectionElement.querySelectorAll('button')
                              btn.forEach((itm) => {
                                itm.style.height = newValue + '%'
                                itm.style.width = newValue + '%'

                                values.content.height = newValue
                                values.content.width = newValue
                              })
                            }

                            values.content.html = sectionElement.innerHTML
                            setSelectedSectionStyling(values)
                          }
                        }}
                        onChangeCommitted={(event: any, newValue: number | number[]) => {
                          let values = {...selectedSectionStyling}
                          const sectionElement = document.getElementById(selectedSectionStyling?.id)

                          if (sectionElement) {
                            if (selectedSectionStyling?.type === 'image') {
                              const img: NodeListOf<HTMLImageElement> =
                                sectionElement.querySelectorAll('img:not(.svg-img)')
                              img.forEach((itm) => {
                                itm.style.height = newValue + '%'
                                itm.style.width = newValue + '%'

                                values.content.height = newValue
                                values.content.width = newValue
                              })
                            } else if (selectedSectionStyling?.type === 'button') {
                              const btn: NodeListOf<HTMLButtonElement> =
                                sectionElement.querySelectorAll('button')
                              btn.forEach((itm) => {
                                itm.style.height = newValue + '%'
                                itm.style.width = newValue + '%'

                                values.content.height = newValue
                                values.content.width = newValue
                              })
                            }

                            values.content.html = sectionElement.innerHTML
                            setSelectedSectionStyling(values)
                          }
                        }}
                      />

                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          transform: 'translate(0px, -7px)',
                        }}
                      >
                        <Typography>
                          <h4>0%</h4>
                        </Typography>
                        <Typography>
                          <h4>100%</h4>
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </>
                {/* // )} */}
              </>
            )}

            {selectedSectionStyling?.type == 'button' && (
              <>
                <h3 className='my-2 mt-8'>
                  <label className='fnt-600'>{'Width '}</label>
                </h3>
                <Box className='mt-4'>
                  <PrettoSlider
                    valueLabelDisplay='auto'
                    aria-label='pretto slider'
                    defaultValue={selectedSectionStyling?.content?.width}
                    value={selectedSectionStyling?.content?.width}
                    onChange={(e: Event, newValue: number | number[]) => {
                      let values = {...selectedSectionStyling}
                      const sectionElement: any = document.getElementById(
                        selectedSectionStyling?.id
                      )

                      if (sectionElement) {
                        const btn: NodeListOf<HTMLButtonElement> =
                          sectionElement.querySelectorAll('button')
                        btn.forEach((itm) => {
                          // itm.style.height = newValue + '%'
                          itm.style.width = newValue + '%'

                          // values.content.height = newValue
                          values.content.width = newValue
                        })
                        values.content.html = sectionElement.innerHTML
                      }

                      setSelectedSectionStyling(values)
                    }}
                    onChangeCommitted={(event: any, newValue: number | number[]) => {
                      let values = {...selectedSectionStyling}
                      const sectionElement = document.getElementById(selectedSectionStyling?.id)

                      if (sectionElement) {
                        const btn: NodeListOf<HTMLButtonElement> =
                          sectionElement.querySelectorAll('button')
                        btn.forEach((itm) => {
                          // itm.style.height = newValue + '%'
                          itm.style.width = newValue + '%'

                          // values.content.height = newValue
                          values.content.width = newValue
                        })
                        values.content.html = sectionElement.innerHTML
                      }

                      setSelectedSectionStyling(values)
                    }}
                  />

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      transform: 'translate(0px, -7px)',
                    }}
                  >
                    <Typography>
                      <h4>0%</h4>
                    </Typography>
                    <Typography>
                      <h4>100%</h4>
                    </Typography>
                  </Box>
                </Box>
              </>
            )}

            {/* Border */}
            {selectedSectionStyling?.type != 'text' && (
              <>
                <h3 className='my-2 mt-8'>
                  <label className='fnt-600'> Border</label>
                </h3>

                <div className='d-flex align-items-center'>
                  <div className='d-flex disable-input-stepper'>
                    <button
                      onClick={() => {
                        let values = {...selectedSectionStyling}

                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          let tag:
                            | NodeListOf<HTMLImageElement>
                            | NodeListOf<HTMLButtonElement>
                            | null = null

                          if (
                            selectedSectionStyling?.type == 'image' ||
                            selectedSectionStyling?.type == 'textImage'
                          ) {
                            tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                          } else if (selectedSectionStyling?.type == 'button') {
                            tag = sectionElement.querySelectorAll('button')
                          }

                          if (tag) {
                            //
                            tag.forEach((itm, ind) => {
                              itm.style.border = `${values.content.borderThickness - 1}px ${
                                values.content.borderLine
                              } ${values.content.borderColor}`
                            })
                          }

                          if (selectedSectionStyling != null)
                            values.content.borderThickness =
                              Number(values.content.borderThickness) - 1
                        }

                        // Update the content HTML with the modified outerHTML of the section
                        values.content.html = sectionElement.innerHTML

                        setSelectedSectionStyling(values)
                      }}
                      className='stepper-left'
                    >
                      -
                    </button>
                    <input
                      type='number'
                      className='form-control form-control-solid stepper-input-style bg-white'
                      name='expenseName'
                      value={selectedSectionStyling?.content?.borderThickness}
                      style={{
                        height: '35px',
                        width: '50px',
                        borderRadius: '0px',
                        textAlign: 'center', // Center-align text
                        MozAppearance: 'textfield', // For Firefox
                      }}
                      onChange={(e: any) => {
                        let values = {...selectedSectionStyling}
                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          let tag:
                            | NodeListOf<HTMLImageElement>
                            | NodeListOf<HTMLButtonElement>
                            | null = null

                          if (
                            selectedSectionStyling?.type == 'image' ||
                            selectedSectionStyling?.type == 'textImage'
                          ) {
                            tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                          } else if (selectedSectionStyling?.type == 'button') {
                            tag = sectionElement.querySelectorAll('button')
                          }

                          if (tag) {
                            //
                            tag.forEach((itm, ind) => {
                              itm.style.border = `${e.target.value}px ${values.content.borderLine} ${values.content.borderColor}`
                            })
                          }

                          if (selectedSectionStyling != null)
                            values.content.borderThickness = Number(e.target.value)
                        }

                        // Update the content HTML with the modified outerHTML of the section
                        values.content.html = sectionElement.innerHTML
                        setSelectedSectionStyling(values)
                      }}
                      autoComplete='off'
                    />
                    <button
                      className='stepper-right-no-border'
                      onClick={() => {
                        let values = {...selectedSectionStyling}
                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          let tag:
                            | NodeListOf<HTMLImageElement>
                            | NodeListOf<HTMLButtonElement>
                            | null = null

                          if (
                            selectedSectionStyling?.type == 'image' ||
                            selectedSectionStyling?.type == 'textImage'
                          ) {
                            tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                          } else if (selectedSectionStyling?.type == 'button') {
                            tag = sectionElement.querySelectorAll('button')
                          }

                          if (tag) {
                            //
                            tag.forEach((itm, ind) => {
                              itm.style.border = `${values.content.borderThickness + 1}px ${
                                values.content.borderLine
                              } ${values.content.borderColor}`
                            })
                          }

                          if (selectedSectionStyling != null)
                            values.content.borderThickness =
                              Number(values.content.borderThickness) + 1
                        }

                        // Update the content HTML with the modified outerHTML of the section
                        values.content.html = sectionElement.innerHTML
                        setSelectedSectionStyling(values)
                      }}
                    >
                      +
                    </button>
                  </div>
                  <div className='property-test d-none-multi-checbox'>
                    <div
                      className={`multi-select-container jobs-property template-border-select ms-0 ${
                        selectedSectionStyling?.link?.[0]?.value?.trim()?.length === 0
                          ? 'no-value select '
                          : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={borderTypeLists}
                        value={
                          selectedSectionStyling?.content?.borderLine == 'solid'
                            ? [
                                {
                                  label: 'solid',
                                  value: 'solid',
                                },
                              ]
                            : selectedSectionStyling?.content?.borderLine == 'dotted'
                            ? [
                                {
                                  label: 'dotted',
                                  value: 'dotted',
                                },
                              ]
                            : selectedSectionStyling?.content?.borderLine == 'dashed'
                            ? [
                                {
                                  label: 'dashed',
                                  value: 'dashed',
                                },
                              ]
                            : []
                        }
                        onChange={(e: any) => {
                          let newVal: any = e
                          if (e?.length > 1) newVal = e.slice(1)

                          let values = {...selectedSectionStyling}

                          const sectionElement: any = document.getElementById(
                            selectedSectionStyling?.id
                          )

                          if (sectionElement) {
                            let tag:
                              | NodeListOf<HTMLImageElement>
                              | NodeListOf<HTMLButtonElement>
                              | null = null

                            if (
                              selectedSectionStyling?.type == 'image' ||
                              selectedSectionStyling?.type == 'textImage'
                            ) {
                              tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                            } else if (selectedSectionStyling?.type == 'button') {
                              tag = sectionElement.querySelectorAll('button')
                            }

                            if (tag) {
                              //
                              tag.forEach((itm, ind) => {
                                itm.style.border = `${values.content.borderThickness}px ${newVal?.[0]?.value} ${values.content.borderColor}`
                              })
                            }
                          }

                          values.content.borderLine = newVal?.[0]?.value
                          values.content.html = sectionElement.innerHTML
                          setSelectedSectionStyling(values)
                        }}
                        labelledBy=''
                        overrideStrings={{
                          allItemsAreSelected: '',
                        }}
                        hasSelectAll={false}
                        closeOnChangedValue={true}
                      />
                    </div>
                  </div>
                  <div>
                    <div className='color-picker-box-border d-flex align-items-center'>
                      <div
                        className='color-picker-selected-circle-border'
                        style={{
                          backgroundColor: `${
                            selectedSectionStyling?.content?.borderColor
                              ? selectedSectionStyling?.content?.borderColor
                              : 'black'
                          }`,
                        }}
                      ></div>
                      <p className='fs-5 ms-2 mb-0'>
                        {selectedSectionStyling?.content?.borderColor
                          ? selectedSectionStyling?.content?.borderColor
                          : '#0000'}
                      </p>

                      <img
                        src={colorPickImg}
                        height={23}
                        width={23}
                        className='ms-auto cursor-pointer'
                        onClick={() => {
                          setIsColorPickerVisible(false)
                          setIsColorPickerForButtonTextColorVisible(false)
                          setIsColorPickerForButtonVisible(false)
                          setIsColorPickerForBorder(!isColorPickerForBorder)
                          setIsColorPickerForShape(false)
                        }}
                      />
                    </div>

                    <div style={{height: '0px'}}>
                      <div className='color-picker-div'>
                        {isColorPickerForBorder && (
                          <ColorPicker
                            color={selectedSectionStyling?.content?.borderColor}
                            onChange={(color: any) => {
                              let values = {...selectedSectionStyling}

                              const sectionElement: any = document.getElementById(
                                selectedSectionStyling?.id
                              )

                              if (sectionElement) {
                                let tag:
                                  | NodeListOf<HTMLImageElement>
                                  | NodeListOf<HTMLButtonElement>
                                  | null = null

                                if (
                                  selectedSectionStyling?.type == 'image' ||
                                  selectedSectionStyling?.type == 'textImage'
                                ) {
                                  tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                                } else if (selectedSectionStyling?.type == 'button') {
                                  tag = sectionElement.querySelectorAll('button')
                                }

                                if (tag) {
                                  //
                                  tag.forEach((itm, ind) => {
                                    itm.style.border = `${values.content.borderThickness}px ${values.content.borderLine} ${color.hex}`
                                  })
                                }
                              }

                              values.content.borderColor = color?.hex
                              values.content.html = sectionElement.innerHTML

                              setSelectedSectionStyling(values)
                            }}
                            presets={[
                              '#6254ff',
                              '#146c6a',
                              '#00b9a9',
                              '#D7197D',
                              '#3573CB',
                              '#E4E4E4',
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <h3 className='my-2 mt-8'>
                  <label className='fnt-600'> Border Radius</label>
                </h3>

                <div className='d-flex align-items-center'>
                  <div className='d-flex disable-input-stepper'>
                    <button
                      onClick={() => {
                        let values = {...selectedSectionStyling}

                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          let tag:
                            | NodeListOf<HTMLImageElement>
                            | NodeListOf<HTMLButtonElement>
                            | null = null

                          if (
                            selectedSectionStyling?.type == 'image' ||
                            selectedSectionStyling?.type == 'textImage'
                          ) {
                            tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                          } else if (selectedSectionStyling?.type == 'button') {
                            tag = sectionElement.querySelectorAll('button')
                          }

                          if (tag) {
                            //
                            tag.forEach((itm, ind) => {
                              itm.style.borderRadius = `${
                                values.content.borderInnerTagRadius - 1
                              }px`
                            })
                          }

                          if (selectedSectionStyling != null)
                            values.content.borderInnerTagRadius =
                              Number(values.content.borderInnerTagRadius) - 1
                        }

                        // Update the content HTML with the modified outerHTML of the section
                        values.content.html = sectionElement.innerHTML

                        setSelectedSectionStyling(values)
                      }}
                      className='stepper-left'
                    >
                      -
                    </button>
                    <input
                      type='number'
                      className='form-control form-control-solid stepper-input-style bg-white'
                      name='expenseName'
                      value={selectedSectionStyling?.content?.borderInnerTagRadius}
                      style={{
                        height: '35px',
                        width: '50px',
                        borderRadius: '0px',
                        textAlign: 'center', // Center-align text
                        MozAppearance: 'textfield', // For Firefox
                      }}
                      onChange={(e: any) => {
                        let values = {...selectedSectionStyling}
                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          let tag:
                            | NodeListOf<HTMLImageElement>
                            | NodeListOf<HTMLButtonElement>
                            | null = null

                          if (
                            selectedSectionStyling?.type == 'image' ||
                            selectedSectionStyling?.type == 'textImage'
                          ) {
                            tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                          } else if (selectedSectionStyling?.type == 'button') {
                            tag = sectionElement.querySelectorAll('button')
                          }

                          if (tag) {
                            //
                            tag.forEach((itm, ind) => {
                              itm.style.borderRadius = `${e.target.value + 1}px`
                            })
                          }

                          if (selectedSectionStyling != null)
                            values.content.borderInnerTagRadius = Number(e.target.value)
                        }

                        // Update the content HTML with the modified outerHTML of the section
                        values.content.html = sectionElement.innerHTML
                        setSelectedSectionStyling(values)
                      }}
                      autoComplete='off'
                    />
                    <button
                      className='stepper-right'
                      onClick={() => {
                        let values = {...selectedSectionStyling}
                        const sectionElement: any = document.getElementById(
                          selectedSectionStyling?.id
                        )

                        if (sectionElement) {
                          let tag:
                            | NodeListOf<HTMLImageElement>
                            | NodeListOf<HTMLButtonElement>
                            | null = null

                          if (
                            selectedSectionStyling?.type == 'image' ||
                            selectedSectionStyling?.type == 'textImage'
                          ) {
                            tag = sectionElement.querySelectorAll('img:not(.svg-img)')
                          } else if (selectedSectionStyling?.type == 'button') {
                            tag = sectionElement.querySelectorAll('button')
                          }

                          if (tag) {
                            //
                            tag.forEach((itm, ind) => {
                              itm.style.borderRadius = `${
                                values.content.borderInnerTagRadius + 1
                              }px`
                            })
                          }

                          if (selectedSectionStyling != null)
                            values.content.borderInnerTagRadius =
                              Number(values.content.borderInnerTagRadius) + 1
                        }

                        // Update the content HTML with the modified outerHTML of the section
                        values.content.html = sectionElement.innerHTML
                        setSelectedSectionStyling(values)
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>

          <SingleFileLoader isVisible={showLoader} />
        </div>
      ) : (
        <div className='section-editor tst text-editor-tst'>
          <h2 className='my-3 mx-5'>
            <b>Section {selectedSectInd ? Number(selectedSectInd) + 1 : ''} </b>
          </h2>
          {/* Merge Tags */}
          {selectedText?.trim()?.length > 0 ? (
            <>
              <h3 className='my-2 mt-8 mx-5'>
                <label className='fnt-600'> Dynamic File/Document </label>
              </h3>
              <p className='labl-gry fs-4 mb-4 mx-5'>
                Select a system generated file that you would like to embed within the selected
                text. Recipient will be able to click on the text and preview the file generated:
              </p>
              <div className='property-test d-none-multi-checbox mx-5 text-editor mb-3'>
                <div
                  className={`multi-select-container jobs-property ms-0 me-5    ${
                    selectedMergeDocument.length === 0 ? 'no-value select ' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={documentOptions}
                    value={selectedMergeDocument}
                    ItemRenderer={customOptionRenderer}
                    onChange={(e: any) => {
                      let newVal: any = e
                      console.log(newVal)
                      if (e?.length > 1) newVal = e.slice(1)
                      setSelectedMergeDocument(newVal)

                      handleMergeDocumentChange(newVal)
                    }}
                    labelledBy='' // Empty as we are overriding the label below
                    overrideStrings={{
                      allItemsAreSelected: selectedMergeDocument?.[0]?.label || 'Item selected', // Fallback to 'Item selected' if label is undefined
                    }}
                    hasSelectAll={false}
                    closeOnChangedValue={true}
                  />
                </div>
              </div>

              {selectedMergeDocument?.length > 0 && (
                <InfoCard selectedItem={selectedMergeDocument[0]} />
              )}
            </>
          ) : (
            <>
              <h3 className='my-2 mt-8 mx-5'>
                <label className='fnt-600'> Dynamic Text</label>
              </h3>
              <p className='labl-gry fs-4 mb-4 mx-5'>Select the text you would like to include:</p>
              <div className='property-test d-none-multi-checbox mx-5 text-editor mb-3'>
                <div
                  className={`multi-select-container jobs-property ms-0 me-5    ${
                    selectedMergeField.length === 0 ? 'no-value select ' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={options}
                    value={selectedMergeField}
                    ItemRenderer={customOptionRenderer}
                    onChange={(e: any) => {
                      let newVal: any = e
                      console.log(newVal)
                      if (e?.length > 1) newVal = e.slice(1)
                      setSelectedMergeField(newVal)

                      handleMergeFieldChange(newVal)
                    }}
                    labelledBy=''
                    overrideStrings={{
                      allItemsAreSelected: selectedMergeField?.[0]?.label || 'Item selected', // Fallback to 'Item selected' if label is undefined
                    }}
                    hasSelectAll={false}
                    closeOnChangedValue={true}
                  />
                </div>
              </div>

              {selectedMergeField?.length > 0 && <InfoCard selectedItem={selectedMergeField[0]} />}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default SectionEditor
