import React, { useState } from 'react'
import PercentageInput from '../../../../../../components/PercentageInput'
import MultiSelectInput from '../../../../../../components/MultiSelectInput'

type ParentData = {
  chrgData: any
  setChrgData: any
}

const PercentageCharge = ({ chrgData, setChrgData }: ParentData) => {

  const [chargeOptions, setChargeOptions] = useState<any>([
    { label: 'Total Rent Value', value: 0 },
    { label: 'Security Deposit', value: 2 },
  ])

  const [invoicingOptions, setInvoicingOptions] = useState<any>([
    { label: 'Enabled', value: true },
    { label: 'Disabled', value: false },
  ])

  const [creditOptions, setCreditOptions] = useState<any>([
    { label: 'Rent Charges', value: 1 },
    { label: 'Security Deposit', value: 0 },
  ])

  const subLabels = {
    minimumAmount:
      'Set a minimum amount in case the total amount of the fee calculated is less than the intended amount to charge.',
    VATPercentage:
      'Set the amount of tax you want to apply to the charge once the fee is calculated.',
    feePercentage: 'Set the fee %',
    chargeLabel: 'Select the charge you want this fee to be calculated from:',
    frequencyLabel: 'Select how frequent you want to apply this charge for:',
    dueDateLable:
      'Set the due date for when you want your tenant to settle the payment for this charge:',
    invoicingLabel:
      'Auto-generate invoices for the charge whenever it is applied and charged to the tenant.',
    startApplyingLabel: 'When would you like to start applying this charge?',
    endApplyingLabel: 'When would you like to stop applying this charge?',
    customFrequencyLabel: 'How many times do you want to apply this charge?',
    creditLabel: 'Select the charge you want to credit the booking fee from once it has been paid.',
    creditSubLabel: 'Once the booking fee is paid, the security deposit will be credited with the booking fee amount and reduce the outstanding balance of the security deposit'
  }

  const renderCreditText = () => {
    if (chrgData?.creditOptionType?.[0]?.value === 0) {
      return (
        <p className='mt-3 fs-6 fnt-500 light-dark-theme-color'>
          Once the booking fee is paid, the <u>security deposit</u> will be credited with the booking fee amount and reduce the outstanding balance of the <u>security deposit</u>.
        </p>
      );
    } else if (chrgData?.creditOptionType?.[0]?.value === 1) {
      return (
        <p className='mt-3 fs-6 fnt-500 light-dark-theme-color'>
          Once the booking fee is paid, the <u>rent charge</u> will be credited with the booking fee amount and reduce the outstanding balance of the <u>rent installment</u>. The system will auto-credit the first available rent installment.
        </p>
      );
    }
    return null;
  };

  return (
    <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-7 px-3' style={{ minHeight: '300px' }}>
      <div className='card-body pt-0 mt-5 py-0 px-3'>
        <h3 className='page-heading m-0 white-dark-theme-color fnt-700'>Fee Settings</h3>
        <p className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>
          Set the fee structure for this charge.
        </p>

        {chrgData?.amountCalculationType != null && (
          <>
            <div
              className='row xyz tst recurring'
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '20px',
              }}
            >
              <div className='col-6 col-md-5'>
                {chrgData?.amountCalculationType == 1 ? (
                  // Fee Percentage
                  <PercentageInput
                    isDisabled={false}
                    iconSize={30}
                    required={true}
                    setRightPosition='10px'
                    type='number'
                    name='feePercentage'
                    value={chrgData?.feePercentage != null ? chrgData?.feePercentage : ''}
                    label='Set Fee'
                    firstPlaceholder='Enter Percentage'
                    subLabel='Set the fee %'
                    secondPlaceHolder='%'
                    onChange={(newValue: any) => {
                      const updateLatePaymentFields = { ...chrgData }
                      updateLatePaymentFields.feePercentage = newValue != '' ? newValue : null
                      setChrgData(updateLatePaymentFields)
                    }}
                    isPercent={true}
                  />
                ) : (
                  // Fixed Fee
                  <PercentageInput
                    isDisabled={false}
                    iconSize={30}
                    required={true}
                    setRightPosition='10px'
                    type='number'
                    name='fixedAmount'
                    value={chrgData?.fixedAmount != null ? chrgData?.fixedAmount : ''}
                    label='Set Fee'
                    firstPlaceholder='Enter Amount'
                    subLabel='Set the fee amount'
                    secondPlaceHolder='AED'
                    onChange={(newValue: any) => {
                      const updateLatePaymentFields = { ...chrgData }
                      updateLatePaymentFields.fixedAmount = newValue != '' ? newValue : null
                      if (updateLatePaymentFields.VATPercentage != null)
                        updateLatePaymentFields.previewData.VATAmountForFixes = (updateLatePaymentFields.VATPercentage / 100) * updateLatePaymentFields.fixedAmount
                      else
                        updateLatePaymentFields.previewData.VATAmountForFixes = null

                      setChrgData(updateLatePaymentFields)
                    }}
                    isPercent={false}
                  />
                )}
              </div>

              {/* Select Charge */}
              {chrgData?.amountCalculationType === 1 && (
                <div className='col-6 col-md-7 ps-12 multi-select-wrapper'>
                  <MultiSelectInput
                    name='selectCharge'
                    label='Select Charge'
                    subLabel={subLabels.chargeLabel}
                    chargeOptions={chargeOptions}
                    selectedCharge={chrgData?.selectLeaseChargeType}
                    setSelectedCharge={(newVal: any) => {
                      const updateLatePaymentFields = { ...chrgData }
                      updateLatePaymentFields.selectChargeLeaseFeeListId = newVal
                      updateLatePaymentFields.selectLeaseChargeType = newVal
                      setChrgData(updateLatePaymentFields)
                    }}
                    required={true}
                    disableSearch={true}
                  />
                  {/* <p
                    className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1'
                    style={{ color: '#a4a4a4' }}
                  >
                    <i>
                      You will only be able to select a charge with a fixed fee or defined at the
                      time of creating the lease.
                    </i>
                  </p> */}
                </div>
              )}

              {/* VAT */}
              <div
                className={`col-6  ${chrgData?.amountCalculationType == 1 ? 'col-md-5 mt-10' : 'col-md-7 ps-12'
                  }`}
              >
                <PercentageInput
                  isDisabled={false}
                  iconSize={30}
                  required={true}
                  setRightPosition='10px'
                  type='number'
                  name='VATPercentage'
                  value={chrgData?.VATPercentage}
                  label='VAT'
                  firstPlaceholder='Enter Percentage'
                  subLabel={subLabels.VATPercentage}
                  secondPlaceHolder='%'
                  onChange={(newValue: any) => {
                    const updateLatePaymentFields = { ...chrgData }
                    updateLatePaymentFields.VATPercentage = newValue != '' ? newValue : null


                    if (updateLatePaymentFields.fixedAmount != null)
                      updateLatePaymentFields.previewData.VATAmountForFixes = (updateLatePaymentFields.VATPercentage / 100) * updateLatePaymentFields.fixedAmount
                    else
                      updateLatePaymentFields.previewData.VATAmountForFixes = null

                    setChrgData(updateLatePaymentFields)

                    // setTimeout(() => {
                    //   setIsChangeHappened(Date.now())
                    // }, 500)
                  }}
                  isPercent={true}
                />
                <div className='d-flex align-items-center '>
                  <label className={`percentage-input-label white-dark-theme-color status-w-150`}>
                    VAT Rate
                  </label>
                  <p
                    className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1'
                    style={{ color: '#a4a4a4' }}
                  >
                    AED {chrgData?.amountCalculationType == 0 ? chrgData?.previewData.VATAmountForFixes : '-'}
                  </p>
                </div>
                {chrgData?.VATPercentage != null && chrgData?.amountCalculationType == 1 && (
                  <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2 mb-0 pb-1'>
                    Will be calculated at the time of defining the charge value in the lease.
                  </p>
                )}
              </div>

              {/* Minimum Amount */}
              {chrgData?.amountCalculationType === 1 && (
                <div className='col-6 col-md-7 ps-12 mt-10'>
                  <PercentageInput
                    isDisabled={false}
                    iconSize={30}
                    required={true}
                    setRightPosition='10px'
                    type='number'
                    name='minimumAmount'
                    value={chrgData?.minimumAmount}
                    label='Minimum Amount'
                    firstPlaceholder='Enter Amount'
                    subLabel={subLabels.minimumAmount}
                    secondPlaceHolder='AED'
                    onChange={(newValue: any) => {
                      const updateLatePaymentFields = { ...chrgData }
                      updateLatePaymentFields.minimumAmount = newValue != '' ? newValue : null
                      setChrgData(updateLatePaymentFields)

                      // setTimeout(() => {
                      //   setIsChangeHappened(Date.now())
                      // }, 500)
                    }}
                  />
                </div>
              )}

              {/* Invoicing and Due Date */}
              <div className='row my-10 recurring invoicing-section'>
                <div className='col-6 col-md-5'>
                  <MultiSelectInput
                    name='invoicingType'
                    label='Invoicing'
                    subLabel={subLabels.invoicingLabel}
                    chargeOptions={invoicingOptions}
                    selectedCharge={chrgData?.autoInvoicingEnabled}
                    setSelectedCharge={(newVal: any) => {
                      const updateLatePaymentFields = { ...chrgData }
                      updateLatePaymentFields.autoInvoicingEnabled = newVal
                      setChrgData(updateLatePaymentFields)
                    }}
                    required={false}
                    disableSearch={true}
                  />
                </div>

                {/* Due Date Type */}
                <div className='col-6 col-md-5 ps-15 multi-select-wrapper'>
                  <div className='percentage-label-container'>
                    <label className='percentage-input-label white-dark-theme-color'>
                      Due Date
                    </label>
                    <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2'>
                      {subLabels.dueDateLable}
                    </p>
                  </div>
                  <div className='form-control bg-secondary bg-opacity-15 p-3'>
                    Time Charge is Applied
                  </div>
                </div>
              </div>

              {/* Credit */}
              <div className='col-6 my-5 row col-md-5 multi-select-wrapper'>
                <MultiSelectInput
                  name='credit'
                  label='Credit'
                  subLabel={subLabels.creditLabel}
                  chargeOptions={
                    creditOptions
                  }
                  selectedCharge={chrgData?.creditOptionType}
                  setSelectedCharge={(newVal: any) => {
                    const updateLatePaymentFields = { ...chrgData }
                    updateLatePaymentFields.creditOptionType = newVal
                    setChrgData(updateLatePaymentFields)
                  }}
                  required={true}
                  disableSearch={true}
                  disableInput={chrgData?.frequency?.[0]?.value == 0}
                  showRemove={chrgData?.frequency?.[0]?.value == 0 ? false : true}
                />
                {renderCreditText()}
              </div>
            </div>
          </>
        )}
      </div>
    </div >
  )
}

export default PercentageCharge;
