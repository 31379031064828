import React from 'react'
import {useState, useRef, useEffect} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../img/back-arrow.png'
import './style.scss'
import crossBtn from '../../../img/cross-button.png'
import {Paper} from '@mui/material'
import AddUsersModal from './AddUsersModal'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import profileWhite from '../../../img/profile-white.png'
import {ApiGet, ApiPost, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import noData from '../../../img/NoData1.svg'
import moment from 'moment'
import Swal from 'sweetalert2'
import profileImg from '../../../img/user.jpeg'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'

const UsersInRole = () => {
  const navigate = useNavigate()
  const {id} = useParams()

  const [users, setUsers] = useState<any>([])
  const [roleData, setRoleData] = useState<any>([])
  const [addUser, setAddUser] = useState<any>(false)
  const [searchUser, setSearchUser] = useState<any>()
  const [checkedValues, setCheckedValues] = useState<any>([])
  let totalSelected: any = 0

  const [roleUsersIds, setRoleUsersIds] = useState<any>([])

  // 
  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  //
  const getRoleById = async () => {
    await ApiGet(`corporate/role_permission/${id}`)
      .then((res: any) => {
        setRoleData(res?.data?.data)
        setRoleUsersIds(res?.data?.data?.userIds)
      })
      .catch((err: any) => {
        // console.log('err', err)
        ErrorToast(err?.message)
      })
  }

  //
  const getUsersByRoleId = async () => {
    const body = {
      search: searchUser,
      roleAndPermissionId: id,
    }
    await ApiPost(`corporate/user/get/role_and_permission`, body)
      .then((res: any) => {
        setUsers(res?.data?.data)
        let values: any = []
        for (let i = 0; i < res?.data?.data?.length; i++) {
          values[i] = false
        }
        setCheckedValues(values)
      })
      .catch((err: any) => {
        // console.log('err', err)
        ErrorToast(err?.message)
      })
  }

  //
  const addRemoveUsers = async (val: any, usersData: any) => {
    let newUsers: any = []
    let ind: any = 0
    if (val == 'add') {
      usersData?.map((us: any) => {
        let isInc: any = false
        for (let i = 0; i < roleUsersIds?.length; i++) {
          if (roleUsersIds[i] === us?._id) {
            isInc = true
            break
          }
        }

        if (!isInc) {
          newUsers[ind] = us?._id
          ind++
        }
      })
    }
    let deleteData: any = []
    let j: any = 0
    for (let i = 0; i < users?.length; i++) {
      if (checkedValues[i]) {
        deleteData[j] = users[i]?._id
        j++
      }
    }

    const body = {
      addUserIds: newUsers,
      deleteUserIds: deleteData,
      roleAndPermissionId: id,
    }
    await ApiPost(`corporate/role_permission/user/add_remove`, body)
      .then((res: any) => {
        getUsersByRoleId()
        getRoleById()
        setAddUser(false)
        SuccessToast(res?.data?.message)
      })
      .catch((err: any) => {
        // console.log('err', err)
        ErrorToast(err?.message)
      })
  }

  // 
  useEffect(() => {
    getRoleById()
  }, [])

  // 
  useEffect(() => {
    getUsersByRoleId()
  }, [searchUser])
  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <div className='d-flex align-items-center gap-3'>
            <span
              className='rounded-circle cursor-pointer'
              onClick={() => navigate(`/settings/view-role/${id}`)}
            >
              <img src={backArrow} height={14} width={14} />
            </span>
            <h2 className='page-heading m-0 head-text'>{roleData?.name} - Users</h2>
          </div>
          <div className='d-flex ms-auto'>
            <a className='text-decoration-none cursor-auto' style={{color: '#000'}}>
              <h3 className='page-heading m-0 head-text'>
                <b>Total Users {users?.length} </b>
              </h3>
            </a>
          </div>
        </div>

        <div className='d-flex align-items-center pt-2 pb-0 gy-3 row mx-0 mt-4'>
          <div className='mb-2 col-md-1 pe-5 xyz'>
            <label className='head-text' style={{fontWeight: '600'}}>
              {' '}
              Search{' '}
            </label>
          </div>
          <div className='mb-2 col-md-2 pe-5 xyz'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='User...'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchUser}
                onChange={(e: any) => setSearchUser(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>

          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1
                return <></>
              })}
            </>
          )}

          <div className='mb-2 col-md-4 xyz ms-auto pe-0 text-end'>
            <div className='d-flex justify-content-end'>
              {totalSelected > 0 && (
                <>
                  <div className='d-flex align-items-center'>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label className='labl-gry '>
                        {' '}
                        <i>Total Selected </i>
                      </label>

                      <span className='ms-5 ps-1 my-2 me-4'>
                        <b>{totalSelected}</b>
                      </span>
                    </h4>
                  </div>
                  <a
                    className='btn btn-sm fw-bold  ms-3 remove-btn ps-2 me-5'
                    onClick={() =>
                      Swal.fire({
                        html: '<p class="text-center m-0" style="font-size: 16px"> You are about to remove the selected users from this role, Do you wish to continue ?</p>',
                        showConfirmButton: true,
                        confirmButtonColor: '#007a59',
                        confirmButtonText: 'Yes',
                        showCancelButton: true,
                        cancelButtonText: 'No',
                      }).then((res) => {
                        if (res.isConfirmed) {
                          addRemoveUsers('delete', users)
                        }
                      })
                    }
                  >
                    <img src={crossBtn} height={18} width={18} className='me-4' /> Remove
                  </a>
                </>
              )}
              <button
                type='button'
                className='btn btn-sm fw-bold  me-0 select-btn ps-3 pe-10'
                onClick={() => {
                  setAddUser(true)
                }}
                style={{width: 'fit-content'}}
              >
                <img
                  src={profileWhite}
                  height={18}
                  width={18}
                  style={{marginLeft: '3px'}}
                  className='me-4'
                />
                Add
              </button>
            </div>
          </div>
        </div>

        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'></div>
        <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3'>
          <div className='card-body pt-0 mt-5 py-3 px-3'>
            <div className='table-responsive '>
              <table className='table align-middle  fs-6 gy-5 ' id='kt_ecommerce_sales_table'>
                <thead>
                  <th className='text-start min-w-25px test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          totalSelected == users?.length && users?.length != 0 ? true : false
                        }
                        onChange={(e: any) => {
                          let values = [...checkedValues]
                          if (e.target.checked) {
                            for (let i = 0; i < users?.length; i++) {
                              values[i] = true
                            }
                          } else {
                            for (let i = 0; i < users?.length; i++) {
                              values[i] = false
                            }
                          }
                          setCheckedValues(values)
                        }}
                      />
                    </div>
                  </th>
                  <th className='text-start min-w-200px'>Users</th>

                  <th className='text-start min-w-150px'>Role Assigned</th>
                  <th className='text-start min-w-150px'>Activity</th>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {users?.length > 0 ? (
                    <>
                      {users?.map((user: any, ind: any) => {
                        return (
                          <tr>
                            <td className='text-start ps-0 test'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={checkedValues[ind] == true ? true : false}
                                  onChange={(e: any) => {
                                    const values = [...checkedValues]
                                    values[ind] = !values[ind]
                                    setCheckedValues(values)
                                  }}
                                />
                              </div>
                            </td>
                            <td className='text-start ps-0'>
                              <div className='d-flex align-items-center mb-2'>
                                <div className='symbol-group symbol-hover m-0'>
                                  <div className='symbol symbol-35px symbol-circle '>
                                    <img alt='' src={user?.image ? Bucket+user?.image : profileImg}></img>
                                  </div>
                                </div>
                                <div className='head-text ms-3'>
                                  <p className='user-head pe-2 mb-0'>
                                    {user?.firstName} {user?.lastName}
                                  </p>
                                  <p className='user-sub-head mb-0'>{user?.email}</p>
                                </div>
                              </div>
                            </td>
                            <td className='text-start ps-0 '>
                              <p className='head-text user-head pe-2 mb-1'>
                                {user?.roleAssignAt
                                  ? `${moment(user?.roleAssignAt).format('DD.MM.YYYY - HH:mm A')} `
                                  : '-'}
                              </p>
                            </td>
                            <td className='text-start ps-0'>
                              <div className='d-flex align-items-center'>
                                {' '}
                                <div
                                  className={
                                    user?.activityStatus === 0
                                      ? 'select-dot me-2'
                                      : user?.activityStatus === 1
                                      ? 'orange-dot me-2'
                                      : user?.activityStatus === 2
                                      ? 'red-dot me-2'
                                      : '-'
                                  }
                                ></div>
                                <p className='head-text user-head pe-2 mb-0'>
                                  {user?.activityStatus === 0
                                    ? 'Online'
                                    : user?.activityStatus === 1
                                    ? 'Inactive'
                                    : user?.activityStatus === 2
                                    ? 'Offline'
                                    : '-'}
                                </p>
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={10} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    </tr>
                  )}

                  {/* <tr>
                    <td className='text-start ps-0 test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={true}
                          onChange={(e: any) => {}}
                        />
                      </div>
                    </td>
                    <td className='text-start ps-0'>
                      <div className='head-text'>
                        <p className='user-head pe-2 mb-1'> Waqar Khan </p>
                        <p className='user-sub-head mb-2'>Waqar.khan@email.com</p>
                      </div>
                    </td>

                    <td className='text-start ps-0 '>
                      <p className='head-text user-head pe-2 mb-1'>24.12.2023 – 12:30pm</p>
                    </td>
                    <td className='text-start ps-0'>
                      <div className='d-flex align-items-center'>
                        {' '}
                        <div className='orange-dot me-2'></div>
                        <p className='head-text user-head pe-2 mb-0'>Inactive 20 min ago</p>
                      </div>
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td className='text-start ps-0 test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={true}
                          onChange={(e: any) => {}}
                        />
                      </div>
                    </td>
                    <td className='text-start ps-0'>
                      <div className='head-text'>
                        <p className='user-head pe-2 mb-1'> Ketan Vagh </p>
                        <p className='user-sub-head mb-2'>Ketan.vagh@email.com</p>
                      </div>
                    </td>
                    <td className='text-start ps-0 '>
                      <p className='head-text user-head pe-2 mb-1'>24.12.2023 – 12:30pm</p>
                    </td>
                    <td className='text-start ps-0'>
                      <div className='d-flex align-items-center'>
                        {' '}
                        <div className='red-dot me-2'></div>
                        <p className='head-text user-head pe-2 mb-0'>Offline 3 days ago </p>
                      </div>
                      <p className='user-sub-head mb-2'>13.12.2023 – 5:06pm</p>
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Add User Modal */}
      {addUser && (
        <AddUsersModal
          show={addUser}
          handleClose={() => setAddUser(false)}
          updateUsers={(data: any) => {
            const values: any = []
            let j = 0
            for (let i = 0; i < data?.length; i++) {
              if (data[i]?.check) {
                values[j] = data[i]
                // values[j].check = false
                j++
              }
            }
            setUsers(values)

            addRemoveUsers('add', values)
          }}
          addedUsers={users}
          from={'view-role'}
          previousIds={roleUsersIds}
        />
      )}
    </>
  )
}

export default UsersInRole
