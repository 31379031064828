import React from 'react';

interface FiltersProps { 
}

const Filters: React.FC<FiltersProps> = ({ }) => {
    return (
        <div>
            
        </div>
    );
};

export default Filters;
