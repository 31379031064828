import React, {useState, useEffect, useRef} from 'react'
import {Modal} from 'react-bootstrap'
import './styles.scss'
import {Button, ButtonGroup, Col, Dropdown, Form, Row, Collapse, Card} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import redCross from '../../../img/remove.png'
import addWhite from '../../../img/add-white.png'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import sync from '../../../img/sync.png'
import noData from '../../../img/NoData1.svg'
import {CircularProgress} from '@mui/material'
import swal from 'sweetalert2'
import {useParams} from 'react-router-dom'
import {AntAnchor} from 'antd/lib/anchor/Anchor'

interface ButtonProps {
  show: any
  handleSubmitId: (message: string, deleteMessage: any) => void
  handleClose: any
  addedJobs: any
  development: any
}

const DevClustAddJobsModal = ({
  handleClose,
  show,
  addedJobs,
  handleSubmitId,
  development,
}: ButtonProps) => {
  const {jobType} = useParams()

  const [searchDevelopment, setSearchDevelopment] = useState<any>('')
  const [result, setResult] = useState<any>()
  const [searchUnitNo, setSearchUnitNo] = useState<any>('')
  const [isFirst, setIsFirst] = useState<any>(true)

  const [developmentLists, setDevelopmentLists] = useState<any>([])
  // console.log(development)
  // console.log(addedJobs)
  const [selectedDevelopments, setSelectedDevelopments] = useState<any>(
    development?.value != undefined ? [development] : []
  )

  const [clustersLists, setClustersLists] = useState<any>([])
  const [selectedClusters, setSelectedClusters] = useState<any>(
    addedJobs?.length > 0 && addedJobs?.[0]?.community?.length > 0
      ? [
          {
            label: addedJobs?.[0]?.cluster?.[0]?.name,
            value: addedJobs?.[0]?.cluster?.[0]?._id,
            type: addedJobs?.[0]?.cluster?.[0]?.type,
          },
        ]
      : []
  )

  const [refreshForClust, setRefreshForClust] = useState<any>(
    addedJobs?.length > 0 && addedJobs?.[0]?.community?.length > 0 ? new Date() : false
  )

  const [building, setBuilding] = useState<any>(false)
  const [community, setCommunity] = useState<any>(
    addedJobs?.length > 0 && addedJobs?.[0]?.community?.length > 0 ? true : false
  )

  const [isResidential, setIsResidential] = useState<any>(true)
  const [isCommercial, setIsCommercial] = useState<any>(true)

  const [buildingLists, setBuildingLists] = useState<any>([])
  const [selectedBuildings, setSelectedBuildings] = useState<any>([])

  const [addedJobDetails, setAddedJobDetails] = useState<any>(addedJobs)

  const [unitGrpsLists, setUnitGrpsLists] = useState<any>([])
  const [selectedUnitGrps, setSelectedUnitGrps] = useState<any>([])

  const [selectedFloorGrps, setSelectedFloorGrps] = useState<any>([])
  const [floorGrpsLists, setFloorGrpsLists] = useState<any>([])

  const [selectedUnits, setSelectedUnits] = useState<any>([])
  const [unitsLists, setUnitsLists] = useState<any>([])

  const [showBuildingCluster, setShowBuildingCluster] = useState<any>(
    addedJobs?.length > 0 &&
      addedJobs?.[0]?.community?.length > 0 &&
      addedJobs?.[0]?.cluster?.[0]?.type == 1
      ? true
      : false
  )
  const [showUnitsForCluster, setShowUnitsForCluster] = useState<any>(false)
  const [showIsUnitGroup, setShowIsUnitGroup] = useState<any>(false)
  const [showBuildingUnitGroup, setShowBuildingUnitGroup] = useState<any>(false)
  const [showMixedCluster, setShowMixedCluster] = useState<any>(
    addedJobs?.length > 0 &&
      addedJobs?.[0]?.community?.length > 0 &&
      addedJobs?.[0]?.cluster?.[0]?.type == 2
      ? true
      : false
  )

  const [showFloorsForBuildingCluster, setShowFloorsForBuildingCluster] = useState<any>(false)

  const [isLoading, setIsLoading] = useState<any>(true)

  const [isBuildingChanged, setIsBuildingChanged] = useState<any>(true)

  let totalSelected: any = 0
  let totalNotSelectedInCurrentBatch: any = 0

  // Development
  const getDevelopment = () => {
    const body = {
      search: searchDevelopment,
      developmentIds: [],
    }
    ApiPost(`corporate/development/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
              devType:
                val?.isCommunity == 'true' ? 'comm' : val?.isBuilding == 'true' ? 'build' : '',
            }
          })

        setDevelopmentLists(values)

        setIsLoading(false)

        // if (isFirst) setSelectedDevelopments(values)
      })
      .catch((err: any) => {})
  }

  React.useEffect(() => {
    getDevelopment()
  }, [])

  React.useEffect(() => {
    if (selectedDevelopments?.length > 0) {
      setIsFirst(false)
    } else {
      setSelectedUnits([])
      setSelectedFloorGrps([])
      setSelectedUnitGrps([])
      setSelectedClusters([])
    }
  }, [selectedDevelopments])

  // Clusters
  const getClusters = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    const body = {
      search: searchDevelopment,
      developmentIds: devIds,
      clusterIds: [],
    }
    ApiPost(`corporate/development/cluster/get`, body)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            values[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
              type: `${val?.type}`,
            }
          })

        setClustersLists(values)

        let isBuildingCluster: any = false
        let isUnitCluster: any = false
        let isMixedCluster: any = false
        // console.log('/************Get Clusters***********/')
        // console.log(selectedClusters)

        if (selectedClusters?.length > 0) {
          // e?.map((el:any, ind:any)=>{
          // console.log(el)
          if (Number(selectedClusters?.[0]?.type) == 1) isBuildingCluster = true

          if (Number(selectedClusters?.[0]?.type) == 0) isUnitCluster = true

          if (Number(selectedClusters?.[0]?.type) == 2) isMixedCluster = true

          // })
        }
        setShowBuildingCluster(isBuildingCluster)
        setShowUnitsForCluster(isUnitCluster)
        setShowMixedCluster(isMixedCluster)

        // setShowFloorsForBuildingCluster(false)
        // setSelectedFloorGrps([])
        // setSelectedUnitGrps([])
        // setSelectedUnits([])
        // setSelectedBuildings([])

        // let isUnitGrp:any = false
        //   let isUnitCluster:any = false
        //   let isMixedCluster: any = false
        //   values?.map((el:any, ind:any)=>{
        //     console.log(el)
        //     if(Number(el?.type) == 1 )  // building cluster
        //       isUnitGrp = true

        //     if(Number(el?.type) == 0)   //unit cluster
        //       isUnitCluster = true

        //     if( Number(el?.type) == 2)   // Mixed Cluster
        //     isMixedCluster = true

        //   })
        //   setShowBuildingCluster(isUnitGrp)
        //   setShowUnitsForCluster(isUnitCluster)
        //   setShowMixedCluster(isMixedCluster)

        if (
          (selectedClusters?.length == 0 && selectedDevelopments?.length != 0) ||
          isBuildingChanged
        ) {
          // setSelectedClusters(values)
        } else {
          const filteredValues = selectedClusters.filter((cluster: any) =>
            values.some((value: any) => value.value === cluster.value)
          )
          if (selectedDevelopments?.length == 0) {
            setClustersLists([])
            setSelectedClusters([])
          } else setSelectedClusters(filteredValues)
        }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (!isFirst && selectedDevelopments?.length > 0) getClusters()
  }, [selectedDevelopments, isFirst, refreshForClust])

  //  Buildings
  const getBuildings = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    const body = {
      search: searchDevelopment,
      buildingIds:
        selectedDevelopments?.[0]?.devType == 'build' ? [selectedDevelopments?.[0]?.value] : [],
      communityIds:
        selectedDevelopments?.[0]?.devType == 'comm' ? [selectedDevelopments?.[0]?.value] : [],
      clusterIds: clustIds,
    }

    ApiPost(`corporate/development/building/get`, body)
      .then((res: any) => {
        let valuesForBuildings: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            valuesForBuildings[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
              // type: val?.isUnitGroup ? 0 : 1, // 0 for unitGroup , 1 for building
            }
          })
        // console.log('{************ Get Buildings *****************}')
        // console.log(valuesForBuildings)
        setBuildingLists(valuesForBuildings)

        let values: any = [...valuesForBuildings]

        if (Number(selectedClusters?.[0]?.type) == 1) {
          valuesForBuildings?.map((vl: any, j: any) => {
            values[j] = {
              comments: [],
              schedule: {
                date: null,
                startTime: null,
                endTime: null,
                status: 0,
              },
              jobUrgency: 0,
              maintenanceJobStatus: 0,
              attachments: [],
              jobId: null,
              jobIdNo: null,
              check: false,
              isDisable: false,
              building: [
                {
                  name: values[j]?.label,
                  _id: values[j]?.value,
                },
              ],
              community: [
                {
                  name: selectedDevelopments?.[0]?.label,
                  _id: selectedDevelopments?.[0]?.value,
                },
              ],
              cluster: [
                {
                  name: selectedClusters?.[0]?.label,
                  _id: selectedClusters?.[0]?.value,
                  type: selectedClusters?.[0]?.type,
                },
              ],
            }

            let isExist = false

            addedJobs?.map((job: any, ind: any) => {
              if (vl?.value == job?.building?.[0]?._id) {
                isExist = true
                values[j].comments = job?.comments
                values[j].schedule = job?.schedule
                values[j].jobUrgency = job?.jobUrgency
                values[j].maintenanceJobStatus = job?.maintenanceJobStatus
                values[j].attachments = job?.attachments
                values[j].jobId = job?.jobId
                values[j].jobIdNo = job?.jobIdNo
                values[j].building = job?.building
                values[j].community = job?.community
                values[j].cluster = job?.cluster
              }
            })

            if (isExist) {
              values[j].check = true
              values[j].isDisable = true
            }
          })

          setResult(values)
        }

        if (Number(selectedClusters?.[0]?.type) == 1) {
          if (
            (selectedBuildings?.length == 0 && selectedDevelopments?.length != 0) ||
            isBuildingChanged
          ) {
            setSelectedBuildings(valuesForBuildings)

            if (values?.length > 0) setShowFloorsForBuildingCluster(true)
            else setShowFloorsForBuildingCluster(false)
          } else {
            const filteredValues = selectedBuildings.filter((grp: any) =>
              valuesForBuildings.some((value: any) => value.value === grp.value)
            )

            if (selectedDevelopments?.length == 0) {
              setBuildingLists([])
              setSelectedBuildings([])
            } else {
              setSelectedBuildings(filteredValues)
              if (filteredValues?.length > 0) setShowFloorsForBuildingCluster(true)
              else setShowFloorsForBuildingCluster(false)
            }
          }
        }

        if (clustIds?.length == 0) {
          setBuildingLists([])
          setSelectedBuildings([])
        }

        let isUnitGrp: any = false
        let isUnitCluster: any = false
        let isMixedCluster: any = false

        if (Number(selectedClusters?.[0]?.type) == 1)
          // building cluster
          isUnitGrp = true

        if (Number(selectedClusters?.[0]?.type) == 0)
          //unit cluster
          isUnitCluster = true

        if (Number(selectedClusters?.[0]?.type) == 2)
          // Mixed Cluster
          isMixedCluster = true

        setShowBuildingCluster(isUnitGrp)
        setShowUnitsForCluster(isUnitCluster)
        setShowMixedCluster(isMixedCluster)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (selectedClusters?.length > 0) getBuildings()
  }, [selectedClusters])

  //  Unit Groups
  const getUnitGroups = () => {
    let devIds: any = []
    selectedDevelopments?.length > 0 &&
      selectedDevelopments?.map((dev: any, ind: any) => {
        devIds[ind] = dev?.value
      })

    let clustIds: any = []
    selectedClusters?.length > 0 &&
      selectedClusters?.map((dev: any, ind: any) => {
        clustIds[ind] = dev?.value
      })

    const body = {
      search: searchDevelopment,
      developmentIds: devIds,
      unitGroupIds: [],
      clusterIds: clustIds,
    }

    ApiPost(`corporate/development/unit_group/get`, body)
      .then((res: any) => {
        let valuesForGroup: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((val: any, ind: any) => {
            valuesForGroup[ind] = {
              label: `${val?.name}`,
              value: `${val?._id}`,
              type: val?.isUnitGroup ? 0 : 1, // 0 for unitGroup , 1 for building
            }
          })

        setUnitGrpsLists(valuesForGroup)

        // console.log('{************ Get Unit Groups *****************}')
        // console.log(valuesForGroup)

        let values: any = [...valuesForGroup]

        if (Number(selectedClusters?.[0]?.type) == 2) {
          valuesForGroup?.map((vl: any, j: any) => {
            values[j] = {
              comments: [],
              schedule: {
                date: null,
                startTime: null,
                endTime: null,
                status: 0,
              },
              jobUrgency: 0,
              maintenanceJobStatus: 0,
              attachments: [],
              jobId: null,
              jobIdNo: null,
              check: false,
              isDisable: false,
              building: [],
              unit_group: [
                {
                  name: values[j]?.label,
                  _id: values[j]?.value,
                },
              ],
              community: [
                {
                  name: selectedDevelopments?.[0]?.label,
                  _id: selectedDevelopments?.[0]?.value,
                },
              ],
              cluster: [
                {
                  name: selectedClusters?.[0]?.label,
                  _id: selectedClusters?.[0]?.value,
                  type: selectedClusters?.[0]?.type,
                },
              ],
            }

            let isExist = false

            addedJobs?.map((job: any, ind: any) => {
              if (vl?.value == job?.unit_group?.[0]?._id) {
                isExist = true
                values[j].comments = job?.comments
                values[j].schedule = job?.schedule
                values[j].jobUrgency = job?.jobUrgency
                values[j].maintenanceJobStatus = job?.maintenanceJobStatus
                values[j].attachments = job?.attachments
                values[j].jobId = job?.jobId
                values[j].jobIdNo = job?.jobIdNo
                values[j].building = job?.building
                values[j].community = job?.community
                values[j].cluster = job?.cluster
              }
            })

            if (isExist) {
              values[j].check = true
              values[j].isDisable = true
            }
          })

          setResult(values)
        }

        let isUnitGrp: any = false
        let isBuildGroup: any = false

        values?.map((el: any, ind: any) => {
          // console.log(el)
          if (Number(el?.type) == 0)
            // building cluster
            isUnitGrp = true

          if (Number(el?.type) == 1)
            //unit cluster
            isBuildGroup = true
        })
        setShowIsUnitGroup(isUnitGrp)
        setShowBuildingUnitGroup(isBuildGroup)

        if (
          (selectedUnitGrps?.length == 0 && selectedDevelopments?.length != 0) ||
          isBuildingChanged
        )
          setSelectedUnitGrps(valuesForGroup)
        else {
          const filteredValues = selectedUnitGrps.filter((grp: any) =>
            valuesForGroup.some((value: any) => value.value === grp.value)
          )

          if (selectedDevelopments?.length == 0) {
            setUnitGrpsLists([])
            setSelectedUnitGrps([])
          } else setSelectedUnitGrps(filteredValues)
        }

        if (clustIds?.length == 0) {
          setUnitGrpsLists([])
          setSelectedUnitGrps([])
        }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  React.useEffect(() => {
    if (selectedClusters?.length > 0) getUnitGroups()
  }, [selectedClusters])

  useEffect(() => {
    let isBuild: any = false
    let isComm: any = false

    if (selectedDevelopments?.length == 0) {
      setCommunity(true)
      setBuilding(true)
    } else {
      selectedDevelopments?.map((sl: any) => {
        if (sl?.devType == 'comm') isComm = true

        if (sl?.devType == 'build') isBuild = true
      })

      setCommunity(isComm)
      setBuilding(isBuild)
    }
  }, [selectedDevelopments])

  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected.length > 0 ? `${selected.length} Selected` : 'Select Cluster'
  }

  useEffect(() => {}, [selectedDevelopments])

  // setTimeout(()=>{

  //   const checkboxes = document.querySelectorAll('.property-test .multi-select-container .rmsc .item-renderer input[type=checkbox]');
  //     console.log(checkboxes)
  //   checkboxes.forEach(function(checkbox) {
  //       checkbox.setAttribute('type', 'radio');
  //     });

  // }, 200)

  const intervalRef = useRef<NodeJS.Timer | null>(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const checkboxes = document.querySelectorAll(
        '.property-test .multi-select-container .rmsc .item-renderer input[type=checkbox]'
      )
      // console.log(checkboxes)
      checkboxes.forEach((checkbox) => {
        checkbox.setAttribute('type', 'radio')
      })
    }, 100)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  const [radioChecked, setRadioChecked] = useState<any>(
    development?.value != undefined && development?.devType == 'build' ? development?.value : -1
  )

  const addJobs = (newVal: any, newClust: any, tableData: any) => {
    let jobsToUpdate: any = []
    if (newVal?.[0]?.devType == 'build') {
      jobsToUpdate?.push({
        comments: [],
        schedule: {
          date: null,
          startTime: null,
          endTime: null,
          status: 0,
        },
        jobUrgency: 0,
        maintenanceJobStatus: 0,
        attachments: [],
        jobId: null,
        jobIdNo: null,
        isDisable: false,
        building: [
          {
            name: newVal?.[0]?.label,
            _id: newVal?.[0]?.value,
          },
        ],
        community: null,
        check: true,
      })

      if (newVal?.[0]?.value == addedJobs?.[0]?.building?.[0]?._id) {
        jobsToUpdate[jobsToUpdate?.length - 1].isDisable = true
      }
    } else if (newVal?.[0]?.devType == 'comm' && newClust?.[0]?.type == 0) {
      jobsToUpdate?.push({
        comments: [],
        schedule: {
          date: null,
          startTime: null,
          endTime: null,
          status: 0,
        },
        jobUrgency: 0,
        maintenanceJobStatus: 0,
        attachments: [],
        jobId: null,
        jobIdNo: null,
        isDisable: false,
        building: null,
        community: [
          {
            name: selectedDevelopments?.[0]?.label,
            _id: selectedDevelopments?.[0]?.value,
          },
        ],
        check: true,
        cluster: [
          {
            name: newClust?.[0]?.label,
            _id: newClust?.[0]?.value,
            type: newClust?.[0]?.type,
          },
        ],
      })

      if (newClust?.[0]?.value == addedJobs?.[0]?.cluster?.[0]?._id) {
        jobsToUpdate[jobsToUpdate?.length - 1].isDisable = true
      }
    }
    //  building cluster
    else if (newVal?.[0]?.devType == 'comm' && newClust?.[0]?.type == 1) {
      tableData?.map((tb: any, ind: any) => {
        jobsToUpdate[ind] = {
          comments: [],
          schedule: {
            date: null,
            startTime: null,
            endTime: null,
            status: 0,
          },
          jobUrgency: 0,
          maintenanceJobStatus: 0,
          attachments: [],
          jobId: null,
          jobIdNo: null,
          isDisable: false,
          community: [
            {
              name: newVal?.[0]?.label,
              _id: newVal?.[0]?.value,
            },
          ],
          check: false,
          cluster: [
            {
              name: newClust?.[0]?.label,
              _id: newClust?.[0]?.value,
              type: newClust?.[0]?.type,
            },
          ],
          building: [
            {
              name: tb?.label,
              _id: tb?.value,
            },
          ],
        }

        let isExist: any = false
        addedJobs?.map((job: any, ind2: any) => {
          

          if (tb?.value == job?.building?.[0]?._id) {
            isExist = true
            jobsToUpdate[ind].comments = job?.comments
            jobsToUpdate[ind].schedule = job?.schedule
            jobsToUpdate[ind].jobUrgency = job?.jobUrgency
            jobsToUpdate[ind].maintenanceJobStatus = job?.maintenanceJobStatus
            jobsToUpdate[ind].attachments = job?.attachments
            jobsToUpdate[ind].jobId = job?.jobId
            jobsToUpdate[ind].jobIdNo = job?.jobIdNo
            jobsToUpdate[ind].building = job?.building
            jobsToUpdate[ind].community = job?.community
            jobsToUpdate[ind].cluster = job?.cluster
          }
        })

        if (isExist) {
          jobsToUpdate[ind].check = true
          jobsToUpdate[ind].isDisable = true
        }
      })
    }
    // mixed cluster
    else if (newVal?.[0]?.devType == 'comm' && newClust?.[0]?.type == 2) {
      tableData?.map((tb: any, ind: any) => {
        jobsToUpdate[ind] = {
          comments: [],
          schedule: {
            date: null,
            startTime: null,
            endTime: null,
            status: 0,
          },
          jobUrgency: 0,
          maintenanceJobStatus: 0,
          attachments: [],
          jobId: null,
          jobIdNo: null,
          isDisable: false,
          community: [
            {
              name: newVal?.[0]?.label,
              _id: newVal?.[0]?.value,
            },
          ],
          check: false,
          cluster: [
            {
              name: newClust?.[0]?.label,
              _id: newClust?.[0]?.value,
              type: newClust?.[0]?.type,
            },
          ],
          building: [],
          unit_group: [
            {
              name: tb?.label,
              _id: tb?.value,
            },
          ],
        }

        let isExist: any = false
        addedJobs?.map((job: any, ind2: any) => {
          

          if (tb?.value == job?.unit_group?.[0]?._id) {
            isExist = true
            jobsToUpdate[ind].comments = job?.comments
            jobsToUpdate[ind].schedule = job?.schedule
            jobsToUpdate[ind].jobUrgency = job?.jobUrgency
            jobsToUpdate[ind].maintenanceJobStatus = job?.maintenanceJobStatus
            jobsToUpdate[ind].attachments = job?.attachments
            jobsToUpdate[ind].jobId = job?.jobId
            jobsToUpdate[ind].jobIdNo = job?.jobIdNo
            jobsToUpdate[ind].building = job?.building
            jobsToUpdate[ind].community = job?.community
            jobsToUpdate[ind].cluster = job?.cluster
          }
        })

        if (isExist) {
          jobsToUpdate[ind].check = true
          jobsToUpdate[ind].isDisable = true
        }
      })
    }
    // console.log(jobsToUpdate)
    setResult(jobsToUpdate)
  }

  const updateJobs = () => {
    let values: any = []
    // console.log('/****************Update Jobs************* */')
    // console.log(addedJobs)

    addedJobs?.map((res: any, ind: any) => {
      // values[ind].check = false
    })

    let isExist: any = false

    addedJobs?.map((job: any, ind: any) => {
      isExist = true
      values[ind] = {
        comments: job?.comments,
        schedule: job?.schedule,
        jobUrgency: job?.jobUrgency,
        maintenanceJobStatus: job?.maintenanceJobStatus,
        attachments: job?.attachments,
        jobId: job?.jobId,
        jobIdNo: job?.jobIdNo,
        check: false,
        isDisable: true,
        building: job?.building,
        community: job?.community,
      }
    })

    setResult(values)
  }

  useEffect(() => {
    // console.log(addedJobDetails)
    updateJobs()
  }, [addedJobDetails])

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <Row>
          <div
            className='card p-5 px-7'
            style={{
              boxShadow: '0px 0px 20px 0px #516176',
              zIndex: '1',
              borderRadius: '0px',
              minHeight: `${
                selectedDevelopments?.length == 0 ||
                result?.length == 0 ||
                (selectedDevelopments?.[0]?.devType == 'comm' && selectedClusters?.length == 0)
                  ? '600px'
                  : '600px'
              }`,
            }}
          >
            {result?.map((res: any, ind: any) => {
              if (res?.check == true && res?.isDisable == false) totalSelected = totalSelected + 1
              return <></>
            })}

            {result?.map((res: any, ind: any) => {
              if (res?.isDisable == false)
                totalNotSelectedInCurrentBatch = totalNotSelectedInCurrentBatch + 1
              return <></>
            })}

            <div className='d-flex align-items-center'>
              <h2 className='page-heading d-flex text-dark flex-column justify-content-center ps-1'>
                <b>Add Jobs</b>
              </h2>

              <div className='d-flex ms-auto align-items-center '>
                {/* <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3 red-hollow-btn ps-4'
                  onClick={() => {
                    handleClose()
                  }}
                >
                  <img src={redCross} height={18} width={18} className='me-4'/> Cancel
                </button> */}

                <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3  select-btn ps-3  status-w-100'
                  onClick={() => {
                    let noJobAdded:any = true
                    result?.map((rs:any, ind:any)=>{
                      if(rs?.check)
                        noJobAdded = false
                      
                    })

                    if (
                      selectedDevelopments?.[0]?.value != development?.value &&
                      development?.value != undefined && noJobAdded
                    ) {
                      swal
                        .fire({
                          html: `<div class='fs-3'> <b>Apply Changes</b></div>
                            <div class='fs-4 mt-4'>You selected a different development, but no job has been added. It will discard all existing jobs.</div>
                            <div class='fs-4 mt-4'> Do you wish to continue ? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                            let values: any = [...result]

                            values?.map((rs: any, ind: any) => {
                              if (rs?.check) {
                                if (
                                  development?._id != rs?.community?.[0]?._id &&
                                  development?._id != rs?.building?.[0]?._id
                                ) {
                                  values[ind].check = false
                                }
                              }
                            })

                            let jobsToDelete: any = []
                            // console.log(addedJobs)
                            addedJobs?.map((jb: any, ind: any) => {
                              if (jb?.jobId != null) {
                                jobsToDelete?.push({
                                  _id: jb?.jobId,
                                })
                              }
                            })

                            handleSubmitId(values, jobsToDelete)
                            handleClose()
                          }
                        })
                    }
                    else if (
                      selectedDevelopments?.[0]?.value == development?.value &&
                      development?.value != undefined &&
                      selectedDevelopments?.[0]?.devType == 'comm' &&
                      selectedClusters?.[0]?.value != development?.clusterId && noJobAdded
                    ) {
                      swal
                        .fire({
                          html: `<div class='fs-3'> <b>Apply Changes</b></div>
                            <div class='fs-4 mt-4'>You selected a different cluster, but no job has been added. It will discard all existing jobs.</div>
                            <div class='fs-4 mt-4'> Do you wish to continue ? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                            let values: any = [...result]

                            values?.map((rs: any, ind: any) => {
                              // console.log(development)
                              // console.log(rs)
                              if (rs?.check) {
                                if (selectedClusters?.[0]?.value != rs?.cluster?.[0]?._id) {
                                  values[ind].check = false
                                }
                              }
                            })

                            let jobsToDelete: any = []
                            addedJobs?.map((jb: any, ind: any) => {
                              if (jb?.jobId != null) {
                                jobsToDelete?.push({
                                  _id: jb?.jobId,
                                })
                              }
                            })

                            handleSubmitId(values, jobsToDelete)

                            handleClose()
                          }
                        })
                    } 
                    else if (
                      selectedDevelopments?.[0]?.value != development?.value &&
                      development?.value != undefined
                    ) {
                      swal
                        .fire({
                          html: `<div class='fs-3'> <b>Apply Changes</b></div>
                            <div class='fs-4 mt-4'>Your selection falls under a different development. By adding it you will replace all existing jobs.</div>
                            <div class='fs-4 mt-4'> Do you wish to continue ? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                            let values: any = [...result]

                            values?.map((rs: any, ind: any) => {
                              if (rs?.check) {
                                if (
                                  development?._id != rs?.community?.[0]?._id &&
                                  development?._id != rs?.building?.[0]?._id
                                ) {
                                  values[ind].check = false
                                }
                              }
                            })

                            let jobsToDelete: any = []
                            // console.log(addedJobs)
                            addedJobs?.map((jb: any, ind: any) => {
                              if (jb?.jobId != null) {
                                jobsToDelete?.push({
                                  _id: jb?.jobId,
                                })
                              }
                            })

                            handleSubmitId(values, jobsToDelete)
                            handleClose()
                          }
                        })
                    } else if (
                      selectedDevelopments?.[0]?.value == development?.value &&
                      development?.value != undefined &&
                      selectedDevelopments?.[0]?.devType == 'comm' &&
                      selectedClusters?.[0]?.value != development?.clusterId
                    ) {
                      swal
                        .fire({
                          html: `<div class='fs-3'> <b>Apply Changes</b></div>
                            <div class='fs-4 mt-4'>Your selection falls under a different cluster. By adding it you will replace all existing jobs.</div>
                            <div class='fs-4 mt-4'> Do you wish to continue ? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                            let values: any = [...result]

                            values?.map((rs: any, ind: any) => {
                              // console.log(development)
                              // console.log(rs)
                              if (rs?.check) {
                                if (selectedClusters?.[0]?.value != rs?.cluster?.[0]?._id) {
                                  values[ind].check = false
                                }
                              }
                            })

                            let jobsToDelete: any = []
                            addedJobs?.map((jb: any, ind: any) => {
                              if (jb?.jobId != null) {
                                jobsToDelete?.push({
                                  _id: jb?.jobId,
                                })
                              }
                            })

                            handleSubmitId(values, jobsToDelete)

                            handleClose()
                          }
                        })
                    } else {
                      handleSubmitId(result, [])
                      handleClose()
                    }
                  }}
                >
                  <img src={addWhite} height={18} width={18} className='me-4' /> Add
                </button>

                <label
                  className='head-text fs-2 cursor-pointer ms-3'
                  style={{fontWeight: '700'}}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>

            <div className='d-flex align-items-center mt-2'>
              <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-700 ps-1'>
                Select Development
              </h4>
            </div>

            {/* <div className='row mt-4'>
              <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='labl-gry fs-5 mt-3'>
                  {' '}
                  Search
                </label>
              </div>
              <div className='col-11 ps-0'>
                <div
                  className='d-flex flex-wrap '
                  style={{
                   
                    borderBottom: '0px',
                  }}
                >
                  <div className='input-container mb-5 property xyz tst ps-0 me-3 job-modl'>
               
                    <Paper component='form' style={searchContainerStyle} className='input-bg-grey'>
                      <InputBase
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={searchDevelopment}
                        onChange={(e: any) => {
                          setSearchDevelopment(e.target.value)
                        }}
                        placeholder={'Development...'}
                        disabled={
                          selectedDevelopments?.length > 0 ||
                          selectedClusters?.length > 0 ||
                          selectedUnitGrps?.length > 0 ||
                          selectedFloorGrps?.length > 0 ||
                          selectedUnits?.length > 0
                        }
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>
                  <div className='input-container mb-5 property xyz tst ms-2 job-modl'>
                    <Paper component='form' style={searchContainerStyle} className='input-bg-grey'>
                      <InputBase
                        placeholder={'Unit...'}
                        style={inputBaseStyle}
                        inputProps={{'aria-label': 'search'}}
                        value={searchUnitNo}
                        onChange={(e: any) => {
                          setSearchUnitNo(e.target.value)
                        }}
                        disabled={
                          selectedDevelopments?.length > 0 ||
                          selectedClusters?.length > 0 ||
                          selectedUnitGrps?.length > 0 ||
                          selectedFloorGrps?.length > 0 ||
                          selectedUnits?.length > 0
                        }
                      />
                      <IconButton style={searchIconStyle} aria-label='search'>
                        <SearchIcon />
                      </IconButton>
                    </Paper>
                  </div>

                  <div className='mb-5 property xyz tst ps-0 pe-3 ms-auto'>
                    <div
                      className='filter-div d-flex align-items-center justify-content-center cursor-pointer mt-1 ms-auto'
                      onClick={() => {
                        setSearchDevelopment('')
                        setSearchUnitNo('')
                        setSelectedDevelopments([])
                        setSelectedClusters([])
                        setSelectedUnitGrps([])
                        setSelectedFloorGrps([])
                        setSelectedUnits([])
                      }}
                    >
                      <img src={sync} height={20} width={22} className='cursor-pointer' />
                    </div>
                  </div >
                </div>
              </div>
            </div> */}

            <div className='row mt-3 mx-1'>
              {/* <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='labl-gry fs-5 mt-3'>
                  {' '}
                  Filter
                </label>
              </div> */}
              <div className='col-12 px-0'>
                <>
                  <div className='d-flex test flex-wrap jobs'>
                    {/* Development */}
                    <div className='property-test'>
                      <div
                        className={`multi-select-container jobs-property  ms-0 me-5 mb-5  ${
                          selectedDevelopments.length === 0 ? 'no-value dev ' : 'has-value'
                        }`}
                      >
                        <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-500 mb-3'>
                          Development
                        </h4>

                        <MultiSelect
                          options={developmentLists}
                          value={selectedDevelopments}
                          onChange={(e: any) => {
                            // console.log(e)
                            let newVal: any = e
                            setIsBuildingChanged(true)

                            if (e?.length > 1) newVal = e.slice(1)
                            setSelectedDevelopments(newVal)

                            if (newVal?.[0]?.devType == 'build') {
                              setRadioChecked(newVal?.[0]?.value)
                              addJobs(newVal, [], [])
                            }

                            setSelectedClusters([])
                            setSelectedFloorGrps([])
                          }}
                          labelledBy='Development'
                          overrideStrings={{
                            allItemsAreSelected: `${selectedDevelopments?.[0]?.label}`,
                          }}
                          disabled={
                            searchDevelopment?.trim()?.length > 0 ||
                            searchUnitNo?.trim()?.length > 0
                          }
                          hasSelectAll={false}
                        />
                      </div>
                    </div>

                    {selectedDevelopments?.length > 0 && (
                      <>
                        {community && (
                          <>
                            {/* Cluster */}
                            <div className='property-test'>
                              <div
                                className={`multi-select-container jobs-property  me-5 mb-5  ${
                                  selectedClusters.length === 0 ? 'no-value clust ' : 'has-value '
                                }`}
                              >
                                <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-500 mb-3'>
                                  Cluster
                                </h4>
                                <MultiSelect
                                  options={clustersLists}
                                  value={selectedClusters}
                                  onChange={(e: any) => {
                                    let newVal: any = e
                                    if (e?.length > 1) newVal = e.slice(1)
                                    setSelectedClusters(newVal)

                                    if (e?.length == 0) {
                                      setUnitGrpsLists([])
                                      setSelectedUnitGrps([])
                                      setResult([])
                                    }

                                    // console.log(newVal)

                                    addJobs(selectedDevelopments, newVal, [])

                                    let isBuildingCluster: any = false
                                    let isUnitCluster: any = false
                                    let isMixedCluster: any = false
                                    // e?.map((el:any, ind:any)=>{
                                    // console.log(el)
                                    if (Number(newVal?.[0]?.type) == 1) isBuildingCluster = true

                                    if (Number(newVal?.[0]?.type) == 0) isUnitCluster = true

                                    if (Number(newVal?.[0]?.type) == 2) isMixedCluster = true

                                    // })

                                    setShowBuildingCluster(isBuildingCluster)
                                    setShowUnitsForCluster(isUnitCluster)
                                    setShowMixedCluster(isMixedCluster)

                                    if (isUnitCluster)
                                      setRadioChecked(selectedDevelopments?.[0]?.value)

                                    setShowFloorsForBuildingCluster(false)
                                    setSelectedFloorGrps([])
                                    setSelectedUnitGrps([])
                                    setSelectedUnits([])
                                    setSelectedBuildings([])
                                  }}
                                  labelledBy='Cluster'
                                  overrideStrings={{
                                    allItemsAreSelected: `${selectedClusters?.[0]?.label}`,
                                  }}
                                  disabled={
                                    searchDevelopment?.trim()?.length > 0 ||
                                    searchUnitNo?.trim()?.length > 0
                                  }
                                  // valueRenderer={CustomValueRenderer}
                                  hasSelectAll={false}
                                />
                              </div>
                            </div>

                            {/* Building Cluster --- Building */}
                            {showBuildingCluster && selectedClusters?.length > 0 && (
                              <div
                                className={`multi-select-container jobs-property  me-5 mb-5  ${
                                  selectedBuildings.length === 0
                                    ? 'no-value building-clust'
                                    : 'has-value'
                                }`}
                              >
                                <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-500 mb-3'>
                                  Buildings
                                </h4>
                                <MultiSelect
                                  options={buildingLists}
                                  value={selectedBuildings}
                                  onChange={(e: any) => {
                                    setSelectedBuildings(e)
                                    if (e?.length > 0) setShowFloorsForBuildingCluster(true)
                                    else setShowFloorsForBuildingCluster(false)

                                    let isUnitGrp: any = false
                                    let isBuildGroup: any = false

                                    addJobs(selectedDevelopments, selectedClusters, e)

                                    // e?.map((el: any, ind: any) => {
                                    //   console.log(el)
                                    //   if (Number(el?.type) == 0)
                                    //     // building cluster
                                    //     isUnitGrp = true

                                    //   if (Number(el?.type) == 1)
                                    //     //unit cluster
                                    //     isBuildGroup = true
                                    // })
                                    // setShowIsUnitGroup(isUnitGrp)
                                    // setShowBuildingUnitGroup(isBuildGroup)
                                  }}
                                  labelledBy='Buildings'
                                  overrideStrings={{
                                    allItemsAreSelected: `${selectedBuildings?.length} Selected`,
                                  }}
                                  disabled={
                                    searchDevelopment?.trim()?.length > 0 ||
                                    searchUnitNo?.trim()?.length > 0
                                  }
                                  valueRenderer={CustomValueRenderer}
                                />
                              </div>
                            )}

                            {/* Unit Groups */}
                            {showMixedCluster && selectedClusters?.length > 0 && (
                              <div
                                className={`multi-select-container jobs-property  me-5 mb-5  ${
                                  selectedUnitGrps.length === 0 ? 'no-value grpunit ' : 'has-value'
                                }`}
                              >
                                <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-500 mb-3'>
                                  Group
                                </h4>
                                <MultiSelect
                                  options={unitGrpsLists}
                                  value={selectedUnitGrps}
                                  onChange={(e: any) => {
                                    setSelectedUnitGrps(e)

                                    let isUnitGrp: any = false
                                    let isBuildGroup: any = false

                                    addJobs(selectedDevelopments, selectedClusters, e)

                                    e?.map((el: any, ind: any) => {
                                      // console.log(el)
                                      if (Number(el?.type) == 0)
                                        // building cluster
                                        isUnitGrp = true

                                      if (Number(el?.type) == 1)
                                        //unit cluster
                                        isBuildGroup = true
                                    })
                                    setShowIsUnitGroup(isUnitGrp)
                                    setShowBuildingUnitGroup(isBuildGroup)
                                  }}
                                  labelledBy='Unit Groups'
                                  overrideStrings={{
                                    allItemsAreSelected: `${selectedUnitGrps?.length} Selected`,
                                  }}
                                  disabled={
                                    searchDevelopment?.trim()?.length > 0 ||
                                    searchUnitNo?.trim()?.length > 0
                                  }
                                  valueRenderer={CustomValueRenderer}
                                />
                              </div>
                            )}
                          </>
                        )}

                        {showBuildingUnitGroup && (
                          <>
                            {/* Floor Status */}
                            <div
                              className={`multi-select-container jobs-property  me-5 mb-5  ${
                                selectedFloorGrps.length === 0 ? 'no-value floor' : 'has-value'
                              }`}
                            >
                              <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-500 mb-3'>
                                Floor
                              </h4>
                              <MultiSelect
                                options={floorGrpsLists}
                                value={selectedFloorGrps}
                                onChange={(e: any) => {
                                  setSelectedFloorGrps(e)
                                }}
                                labelledBy='Floor '
                                overrideStrings={{
                                  // selectSomeItems: selectedFloorGrps.length > 0 ? `${selectedFloorGrps.length} Selected` : '',
                                  allItemsAreSelected: `${selectedFloorGrps?.length} Selected`,
                                }}
                                disabled={
                                  searchDevelopment?.trim()?.length > 0 ||
                                  searchUnitNo?.trim()?.length > 0
                                }
                                valueRenderer={CustomValueRenderer}
                              />
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              </div>
            </div>

            <div className='row mt-3 mx-0' style={{borderTop: '2px solid lightgrey'}}></div>

            <div className='d-flex align-items-center mt-4'>
              <h4 className='page-heading d-flex text-dark flex-column justify-content-center fnt-700 pt-2'>
                Select Jobs
              </h4>

              <div className='d-flex ms-auto align-items-center '>
                <h4 className='user-head ms-auto me-5 mb-0 white-dark-theme-color fnt-500'>
                  <i>
                    Total Selected <b>{totalSelected > 0 ? totalSelected : '-'} </b>
                  </i>
                </h4>
              </div>
            </div>

            {selectedDevelopments?.length > 0 &&
              (selectedDevelopments?.[0]?.devType == 'build' ||
                (selectedDevelopments?.[0]?.devType == 'comm' && selectedClusters?.length > 0)) && (
                <div className='row mt-3'>
                  <div className='col-12 px-0'>
                    <div className='table-responsive ' style={{maxHeight: '400px'}}>
                      <table
                        className='table align-middle  fs-6 gy-5'
                        id='kt_ecommerce_sales_table'
                      >
                        <thead>
                          <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                            <th className='text-start w-25px  test  pt-2'>
                              {result?.length > 0 &&
                                (selectedClusters?.[0]?.type == 1 ||
                                  selectedClusters?.[0]?.type == 2) && (
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      id='vacant0'
                                      name='none'
                                      checked={
                                        totalSelected == totalNotSelectedInCurrentBatch &&
                                        totalSelected != 0 &&
                                        result?.length != 0
                                          ? true
                                          : false
                                      }
                                      onChange={(e: any) => {
                                        let values: any = [...result]
                                        if (e.target.checked) {
                                          values?.map((list: any) => {
                                            list.check = true
                                          })
                                        } else {
                                          values?.map((list: any) => {
                                            if (!list?.isDisable) list.check = false
                                          })
                                        }
                                        setResult(values)
                                      }}
                                    />
                                  </div>
                                )}
                            </th>

                            {community && showBuildingCluster && (
                              <th className='text-start min-w-100px  pt-2 w-200px'>Building</th>
                            )}

                            {community && (showBuildingUnitGroup || showMixedCluster) && (
                              <th className='text-start min-w-100px  pt-2 w-200px'>Group</th>
                            )}
                            {community && (
                              <th className='text-start min-w-100px  pt-2  w-200px'>Cluster</th>
                            )}

                            <th className='text-start min-w-100px  pt-2'>Development</th>
                          </tr>
                        </thead>

                        <tbody className='fw-semibold head-text'> {/* className='fw-semibold text-gray-600'*/ }
                          {selectedDevelopments?.length > 0 ? (
                            <>
                              {selectedDevelopments?.[0]?.devType == 'build' ||
                              showUnitsForCluster ? (
                                <>
                                  {result?.map((rs: any, index: any) => {
                                    return (
                                      <tr>
                                        <td className='text-start test'>
                                          {radioChecked === selectedDevelopments?.[0]?.value ||
                                          (development?.value == selectedDevelopments?.[0]?.value &&
                                            development?.devType == 'build') ||
                                          (development?.value == selectedDevelopments?.[0]?.value &&
                                            development?.devType == 'comm' &&
                                            selectedClusters?.[0]?.value ==
                                              development?.clusterId) ? (
                                            <div
                                              className={`me-5 green-radio-filled ${
                                                rs?.isDisable ? 'disable-opacity' : ''
                                              }`}
                                            ></div>
                                          ) : (
                                            <div
                                              onClick={() => {
                                                setRadioChecked(selectedDevelopments?.[0]?.value)
                                              }}
                                              className={`me-5 green-radio-hollow crsor-pointer ${
                                                rs?.isDisable ? 'disable-opacity' : ''
                                              }`}
                                            ></div>
                                          )}
                                        </td>
                                        {showUnitsForCluster && (
                                          <td
                                            className={`text-start test ${
                                              rs?.isDisable ? 'disable-opacity' : ''
                                            }`}
                                          >
                                            {selectedClusters?.[0]?.label}
                                          </td>
                                        )}
                                        <td
                                          className={`text-start test ${
                                            rs?.isDisable ? 'disable-opacity' : ''
                                          }`}
                                        >
                                          {selectedDevelopments?.[0]?.label}
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </>
                              ) : (
                                <>
                                  {result?.map((rs: any, index: any) => {
                                    return (
                                      <>
                                        {(selectedClusters?.[0]?.type == 1 ||
                                          selectedClusters?.[0]?.type == 2) && (
                                          <tr>
                                            <td className='text-start test'>
                                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                                                <input
                                                  className='form-check-input cursor-pointer'
                                                  type='checkbox'
                                                  id='vacant0'
                                                  name='none'
                                                  checked={rs?.check}
                                                  onChange={(e: any) => {
                                                    const values = [...result]
                                                    values[index].check = e.target.checked
                                                    setResult(values)
                                                  }}
                                                  disabled={rs?.isDisable}
                                                />
                                              </div>
                                            </td>

                                            {community && showBuildingCluster && (
                                              <td
                                                className={`text-start ${
                                                  rs?.isDisable ? 'disable-opacity' : ''
                                                }`}
                                              >
                                                {rs?.building?.length > 0
                                                  ? `${rs?.building?.[0]?.name}`
                                                  : `-`}
                                              </td>
                                            )}

                                            {community &&
                                              (showMixedCluster || showBuildingUnitGroup) && (
                                                <td
                                                  className={`text-start ${
                                                    rs?.isDisable ? 'disable-opacity' : ''
                                                  }`}
                                                >
                                                  {rs?.unit_group?.length > 0
                                                    ? `${rs?.unit_group?.[0]?.name}`
                                                    : `-`}
                                                </td>
                                              )}

                                            {community && (
                                              <td
                                                className={`text-start ${
                                                  rs?.isDisable ? 'disable-opacity' : ''
                                                }`}
                                              >
                                                {rs?.cluster?.length > 0
                                                  ? `${rs?.cluster?.[0]?.name}`
                                                  : `-`}
                                              </td>
                                            )}

                                            <td
                                              className={`text-start ${
                                                rs?.isDisable ? 'disable-opacity' : ''
                                              }`}
                                            >
                                              {rs?.community?.length > 0
                                                ? `${rs?.community?.[0]?.name}`
                                                : rs?.building.length > 0
                                                ? `${rs?.building?.[0]?.name}`
                                                : `-`}
                                            </td>
                                          </tr>
                                         )} 
                                      </>
                                    )
                                  })}
                                </>
                              )}
                            </>
                          ) : (
                            <tr>
                              {!isLoading ? (
                                <td colSpan={7} className='text-center'>
                                  <img src={noData} alt='' width={350} />
                                </td>
                              ) : (
                                <td colSpan={7} className='text-center'>
                                  <div
                                    className='d-flex justify-content-center align-items-center w-100'
                                    style={{height: '25vh'}}
                                  >
                                    <CircularProgress style={{color: '#007a59'}} />
                                  </div>
                                </td>
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </Row>
      </Modal>
    </div>
  )
}

export default DevClustAddJobsModal
