import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {CircularProgress} from '@mui/material'
import noData from '../../../../img/NoData1.svg'
import moment from 'moment'

interface ButtonProps {
  show: any
  handleClose: any
  updateDetails?: any
  expData: any
}

const SelectPayorModal = ({show, handleClose, updateDetails, expData}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [isLoading, setIsLoading] = useState<any>(false)

  const [searchTenant, setSearchTenant] = useState<any>('')
  const [searchContractNo, setSearchContractNo] = useState<any>('')
  const [searchDevelopment, setSearchDevelopment] = useState<any>('')
  const [searchUnit, setSearchUnit] = useState<any>('')

  const [tenantList, setTenantList] = useState<any>([])

  const [radioChecked, setRadioChecked] = useState<any>(-1)
  const [selectedPayor, setSelectedPayor] = useState<any>()

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const getPayorList = () => {
    const body = {
      page: 1,
      limit: 10000,
      searchTenant: searchTenant,
      searchDevelopment: searchDevelopment,
      searchContractNo: searchContractNo,
      searchUnit: searchUnit,
    }

    ApiPost('corporate/tenancy/overview', body)
      .then((res: any) => {
        setTenantList(res?.data?.data?.tenancy_data)
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    getPayorList()
  }, [searchTenant, searchDevelopment, searchContractNo, searchUnit])

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body px-lg-7 py-lg-3 modal-body-without-header'
          style={{minHeight: '500px'}}
        >
          <div className='d-flex align-items-top'>
            <h2 className=' pt-5 mb-0 white-dark-theme-color'>
              <b>Select Payor</b>
            </h2>

            <label
              className='head-text fs-2 cursor-pointer ms-auto'
              style={{fontWeight: '700'}}
              onClick={() => {
                handleClose()
              }}
            >
              X
            </label>
          </div>
          <div className='d-flex align-items-center '>
            <h4 className='pt-2 mb-0 white-dark-theme-color'>Select the payor which you are charging this payment to:</h4>

            <button
              type='button'
              className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3 d-flex justify-content-center status-w-175 ms-auto'
              onClick={(e: any) => {
                updateDetails(selectedPayor)
                handleClose()
              }}
              disabled={radioChecked == -1}
            >
              Select
            </button>
          </div>

          <>
            <div className='d-flex align-items-center px-0 mt-8'>
              <label className='head-text status-w-80' style={{fontWeight: '600'}}>
                {' '}
                Search{' '}
              </label>

              <div className='mb-2 xyz grey-search-bar'>
                <Paper
                  component='form'
                  style={searchContainerStyle}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <InputBase
                    placeholder='Tenant'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'search'}}
                    value={searchTenant}
                    onChange={(e: any) => setSearchTenant(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>

              <div className='mb-2 xyz ms-3 grey-search-bar'>
                <Paper
                  component='form'
                  style={searchContainerStyle}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <InputBase
                    placeholder='Contract No.'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'search'}}
                    value={searchContractNo}
                    onChange={(e: any) => setSearchContractNo(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>

              <div className='mb-2 xyz ms-3 grey-search-bar'>
                <Paper
                  component='form'
                  style={searchContainerStyle}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <InputBase
                    placeholder='Development'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'search'}}
                    value={searchDevelopment}
                    onChange={(e: any) => setSearchDevelopment(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>

              <div className='mb-2 xyz ms-3 grey-search-bar'>
                <Paper
                  component='form'
                  style={searchContainerStyle}
                  onSubmit={(e) => e.preventDefault()}
                >
                  <InputBase
                    placeholder='Unit'
                    style={inputBaseStyle}
                    inputProps={{'aria-label': 'search'}}
                    value={searchUnit}
                    onChange={(e: any) => setSearchUnit(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-12 pe-0'>
                <div className=''>
                  <div className='table-responsive' style={{maxHeight: '400px', overflow: 'auto'}}>
                    <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
                      <thead>
                        <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                          <th className='text-start w-25px test'></th>
                          <th className='text-start min-w-125px'>Tenant</th>
                          <th className='text-start min-w-150px'>Development</th>
                          <th className='text-start min-w-125px'>Unit</th>
                          <th className='text-start min-w-150px'>Type</th>
                          <th className='text-center min-w-125px'>Lease Status</th>
                          <th className='text-start min-w-175px'>Lease Term</th>
                          <th className='text-start min-w-100px'>Contract No.</th>
                        </tr>
                      </thead>

                      <tbody className='fw-semibold head-text'>
                        {tenantList?.length > 0 ? (
                          <>
                            {tenantList?.map((rs: any, index: any) => {
                              return (
                                <tr>
                                  <td className='text-start w-25px  test ps-0'>
                                    {radioChecked === rs?._id ? (
                                      <div
                                        style={{
                                          height: '16px',
                                          width: '16px',
                                          // backgroundColor: '#146c6a',
                                          // borderRadius: '50%',
                                        }}
                                        className='cursor-pointer green-radio-filled'
                                      ></div>
                                    ) : (
                                      <div
                                        style={{
                                          height: '16px',
                                          width: '16px',
                                          // backgroundColor: 'transparent',
                                          // borderRadius: '50%',
                                          // border: '1px solid #146c6a',
                                        }}
                                        onClick={() => {
                                          setRadioChecked(rs?._id)
                                          setSelectedPayor(rs)
                                        }}
                                        className='cursor-pointer green-radio-hollow'
                                      ></div>
                                    )}
                                  </td>
                                  <td className='text-start min-w-100px'>
                                    {rs?.tenant?.length > 0
                                      ? `${rs?.tenant?.[0]?.firstName} ${rs?.tenant?.[0]?.lastName}`
                                      : '-'}
                                  </td>
                                  <td className='text-start min-w-100px'>
                                    {rs?.community?.length > 0
                                      ? `${rs?.community?.[0]?.name}`
                                      : `${rs?.building?.[0]?.name}`}
                                  </td>

                                  <td className='text-start'>
                                    {rs?.unit?.[0]?.unitNo ? `${rs?.unit?.[0]?.unitNo}` : '-'}
                                  </td>
                                  <td className='text-start '>
                                    {rs?.unit?.[0]?.unitType === 0
                                      ? 'Apartment'
                                      : rs?.unit?.[0]?.unitType === 1
                                      ? 'Penthouse'
                                      : rs?.unit?.[0]?.unitType === 2
                                      ? 'Common Area'
                                      : rs?.unit?.[0]?.unitType === 3
                                      ? 'Townhouse'
                                      : rs?.unit?.[0]?.unitType === 4
                                      ? 'Villa'
                                      : rs?.unit?.[0]?.unitType === 5
                                      ? `Other ${rs?.otherName ? `- ${rs?.otherName}` : ''}`
                                      : rs?.unit?.[0]?.unitType === 6
                                      ? 'Office'
                                      : rs?.unit?.[0]?.unitType === 7
                                      ? 'Restaurant'
                                      : rs?.unit?.[0]?.unitType === 8
                                      ? 'Cafe'
                                      : rs?.unit?.[0]?.unitType === 9
                                      ? 'Retail'
                                      : '-'}
                                  </td>
                                  <td className='text-center '>
                                    <div className='d-flex justify-content-center'>
                                      <div
                                        className={
                                          rs?.tenancyStatus == 0
                                            ? 'status booked-bar'
                                            : rs?.tenancyStatus == 1
                                            ? 'status active-bar  '
                                            : rs?.tenancyStatus == 2
                                            ? 'status expiring-bar'
                                            : rs?.tenancyStatus == 3
                                            ? 'status expired-bar'
                                            : 'status  no-st-bar'
                                        }
                                      >
                                        {rs?.tenancyStatus == 0
                                          ? 'Booked'
                                          : rs?.tenancyStatus == 1
                                          ? 'Active'
                                          : rs?.tenancyStatus == 2
                                          ? 'Expiring'
                                          : rs?.tenancyStatus == 3
                                          ? 'Expired'
                                          : '-'}
                                      </div>
                                    </div>
                                  </td>
                                  <td className='text-start '>
                                    {rs?.duration?.start_date
                                      ? `${moment
                                          .utc(rs?.duration?.start_date)
                                          .local()
                                          .format('DD.MM.YYYY')} `
                                      : '-'}
                                    {'-'}
                                    {rs?.duration?.end_date
                                      ? `${moment
                                          .utc(rs?.duration?.end_date)
                                          .local()
                                          .format('DD.MM.YYYY')} `
                                      : '-'}
                                  </td>
                                  <td className='text-start '>
                                    {rs?.contractNo ? `${rs?.contractNo}` : '-'}
                                  </td>
                                </tr>
                              )
                            })}
                          </>
                        ) : (
                          <tr>
                            {!isLoading ? (
                              <td colSpan={7} className='text-center'>
                                <img src={noData} alt='' width={350} />
                              </td>
                            ) : (
                              <td colSpan={7} className='text-center'>
                                <div
                                  className='d-flex justify-content-center align-items-center w-100'
                                  style={{height: '25vh'}}
                                >
                                  <CircularProgress style={{color: '#007a59'}} />
                                </div>
                              </td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </Modal>
    </div>
  )
}

export default SelectPayorModal
