import React from 'react';

interface PercentBasedFeeProps { 
}

const PercentBasedFee: React.FC<PercentBasedFeeProps> = ({ }) => {
    return (
        <div>
            
        </div>
    );
};

export default PercentBasedFee;
