import React, {useState, useEffect} from 'react'
import {Box, Grid} from '@mui/material'
import Filters from './components/Filters'
import addImg from '../../../../../../../../img/add.png'
import trashImg from '../../../../../../../../img/trash.png'
import CustomButton from '../../../../../../../components/Button/CustomButton'
import {useNavigate, useParams} from 'react-router-dom'
import TableCellActionWithCheck from '../../../../../../../components/Table/TableCellActionWithCheck'
import {getLatePaymentFeeList} from '../../../../../../../services/api/late-payment-fee.api'
import {ErrorToast} from '../../../../../../../../apiCommon/helpers/Toast'
import {useAppState} from '../../../../../../../../context/AppContext'
import tablesConstants from '../../../../../../../constants/tables.constants'
import {formatAmount} from '../../../../../../../../Utilities/utils'

const LatePaymentFee = () => {
  const navigate = useNavigate()
  const {leasingType, leasingSubType} = useParams()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [selectedItems, setSelectedItems] = useState<any>([])

  const handleAddClick = () => {
    navigate(`/settings/add-late-payment-fee/${leasingType}/${leasingSubType}/1`)
  }

  const [tableBodyData, setTableBodyData] = useState<any>([])

  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [limit, setLimit] = useState<any>(10)
  const [dataCount, setDataCount] = useState<any>(0)

  const {state, dispatch} = useAppState()

  useEffect(() => {
    const fetchLatePaymentFees = async () => {
      setIsLoading(true)

      const status: any = state?.searchStatus?.map((st: any) => st?.value) || []
      const types: any = state?.searchType?.map((st: any) => st?.value) || []

      const body = {
        limit,
        page,
        leaseFeeListTypes: [2],
        leaseFeeListIds: [],
        sortType: 'A-Z',
        searchName: state.searchName,
        types,
        activityStatus: status,
      }

      const {result, error} = await getLatePaymentFeeList(body)
      if (result) {
        let temp: any = result?.data
        let values: any = [...temp]

        let checkedValues:any =[]

        temp?.map((tempInstance: any, ind: any) => {
          values[ind].name = `Late Payment Fee - ${tempInstance?.name}`
          values[ind].fee =
            tempInstance?.amountCalculationType == 0
              ? `${formatAmount(tempInstance?.fixedAmount)}`
              : `${tempInstance?.feePercentage ? tempInstance?.feePercentage : '-'}%`
          values[ind].feeType = tempInstance?.type == 0 ? 'Single' : 'Recurring'
          values[ind].calculation =
            tempInstance?.amountCalculationType == 0 ? `Fixed` : `Percentage`
          values[ind].frequency =
            tempInstance?.frequency == 1
              ? 'Daily'
              : tempInstance?.frequency == 2
              ? 'Weekly'
              : tempInstance?.frequency == 3
              ? 'Monthly'
              : '-'


            checkedValues?.push(false)
        })

        setSelectedItems(checkedValues)

        setTableBodyData(values)
        setPageLimit(result?.state?.page_limit)
        setDataCount(result?.state?.data_count)
      }
      if (error) ErrorToast(error)
      setIsLoading(false)
    }

    fetchLatePaymentFees()
  }, [state])

  return (
    <div
      className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3 mt-10 mx-2'
      style={{minHeight: '75vh'}}
    >
      <div className='card-body pt-0 mt-5 py-0 px-3'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <p className='labl-gry fs-4 mb-0 light-dark-theme-color'>
              Manage the late payment fees that would apply to the charges added to your leases.
            </p>
          </Grid>
        </Grid>

        <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 3}}>
          <label className='head-text me-5 pe-3 fnt-600'>Search </label>
          {/* Filters */}
          <Filters
            name={state.searchName}
            status={state.searchStatus}
            type={state.searchType}
            onNameChange={(value: any) => dispatch({type: 'SET_NAME', payload: value})}
            onStatusChange={(value: any) => dispatch({type: 'SET_STATUS', payload: value})}
            onTypeChange={(value: any) => dispatch({type: 'SET_TYPE', payload: value})}
          />
          {/* CTAs */}
          <Box
            sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 'AUTO'}}
          >
            {/* Delete CTA */}
            {selectedItems?.length > 0 && (
              <CustomButton
                text='Delete'
                imgSrc={trashImg}
                className='red-hollow-btn px-3 status-w-100 me-3'
                onClick={handleAddClick}
                imgSize={{height: 16, width: 16}}
              />
            )}

            {/* Add CTA */}
            <CustomButton
              text='Add'
              imgSrc={addImg}
              className='green-hollow-btn px-3 status-w-100'
              onClick={handleAddClick}
              imgSize={{height: 16, width: 16}}
            />
          </Box>
        </Box>

        {/*  */}
        <TableCellActionWithCheck
          fields={tablesConstants?.latePaymentFeeGlobalTable}
          items={tableBodyData}
          checkedValues={selectedItems}
          setCheckedValues={(values) => {setSelectedItems(values)}}
          handlePageClick={(e) => console.log(e.selected)}
          pageLimit={pageLimit}
          page={page}
          onCellClick={(item, columnKey, rowIndex) => {
            if (columnKey === 'Name') {
            }
          }}
        />
      </div>
    </div>
  )
}

export default LatePaymentFee
