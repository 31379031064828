import React from 'react'
import {Typography, Box} from '@mui/material'
import switchOffIcon from '../../../../../../../../img/switch-off.png'
import switchOnIcon from '../../../../../../../../img/switch-on.png'

const LatePaymentTooltipContent = () => {
  return (
    <>
      <Typography variant='body1' fontWeight={700} mb={1}>
        Late Payment Fee Activation
      </Typography>
      <Typography variant='body2' mb={2}>
        You are able to activate and deactivate the late payment fee on your leases by switching
        between the ‘Active’ and ‘Inactive’ statuses:
      </Typography>
      <Box display='flex' alignItems='center' mb={1}>
        <img src={switchOnIcon} height={25} width={25} alt='Active' />
        <Typography variant='body2' ml={2} fontWeight={700}>
          Active
        </Typography>
      </Box>
      <Typography variant='body2' mb={2}>
        Will automatically apply the late payment fee on the assigned charges in the lease you are
        creating. For this to work, you must ensure you assign a charge to the late payment fee in
        the late payment fee settings.
      </Typography>
      <Box display='flex' alignItems='center' mb={1}>
        <img src={switchOffIcon} height={25} width={25} alt='Inactive' />
        <Typography variant='body2' ml={2} fontWeight={700}>
          Inactive
        </Typography>
      </Box>
      <Typography variant='body2'>
        Will not automatically apply the late payment fee on the assigned charges in the lease being
        created, however, the late payment fee will be available and can be applied manually on any
        charges included in the lease.
      </Typography>
    </>
  )
}

export default LatePaymentTooltipContent
