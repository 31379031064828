import React from 'react'
import {useState, useRef, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import addWhite from '../../../img/add-white.png'
import './style.scss'
import {Paper} from '@mui/material'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import Select from 'react-select'
import {ApiGet} from '../../../apiCommon/helpers/API/ApiData'

interface ButtonProps {
  show: any
  handleClose: any
  updateLists: any
  addedLists: any
  addedVendors: any
}

const AddToLists = ({show, handleClose, updateLists, addedLists, addedVendors}: ButtonProps) => {
  const [searchName, setSearchName] = useState<any>('')
  const [sortOptions, setSortOptions] = useState<any>([
    {value: 'A-Z', label: 'Alphabetic (A-Z)'},
    {value: 'Z-A', label: 'Alphabetic (Z-A)'},
  ])

  const [sortValue, setSortValue] = useState<any>('A-Z')
  const [listsInVendors, setListsInVendors] = useState<any>([])

  let listsInVendorsSelected: any = 0

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      minHeight: '35px',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',

    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const getVendorsLists = () => {
    ApiGet(`corporate/vendor_list?searchName=${searchName}&sortType=${sortValue}`)
      .then((res: any) => {
        let values: any = []
        for (let i = 0; i < res?.data?.data?.length; i++) {
          values[i] = {
            name: res?.data?.data?.[i]?.name,
            _id: res?.data?.data?.[i]?._id,
            colorCode: res?.data?.data?.[i]?.colorCode,
            check: false,
          }

          for (let j = 0; j < addedLists?.length; j++) {
            if (values[i]?._id == addedLists[j]?._id) values[i].check = true
          }
        }
        setListsInVendors(values)
      })
      .catch(() => {})
  }

  useEffect(() => {
    getVendorsLists()
  }, [searchName, sortValue])

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3 modal-body-without-header'
          style={{
            minHeight: '550px',
            maxHeight: '550px',
            overflow: 'hidden',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer fnt-700'
                onClick={() => {
                  handleClose()
                }}
              >
                X
              </label>
            </div>
          </div>

          <div className='d-flex align-items-center'>
            <h2 className='head-text pt-5 mb-0'>
              <b>Add to Lists</b>
            </h2>
            <div className='d-flex ms-auto'>
              <button
                type='button'
                className='btn btn-sm fw-bold  me-0 select-btn ps-3 pe-10 btn-fit-content'
                onClick={() => {
                  let values = [...listsInVendors]
                  updateLists(values)
                }}
              >
                <img src={addWhite} height={16} width={16} className='me-3' /> Add
              </button>
            </div>
          </div>

          {listsInVendors?.map((user: any, ind: any) => {
            if (user?.check == true) listsInVendorsSelected = listsInVendorsSelected + 1
            return <></>
          })}

          <div
            className='d-flex align-items-center mt-4 pb-5 '
            style={{borderBottom: '0.1rem solid #bec3cb'}}
          >
            <div className=''>
              <h6 className='mb-2 white-dark-theme-color fnt-500 fs-4'>
                Vendors Selected <b>{addedVendors}</b>
              </h6>
            </div>

            <div className='ms-auto'>
              <h6 className='mb-2 white-dark-theme-color fnt-500 fs-4'>
                Lists Selected <b>{listsInVendorsSelected}</b>
              </h6>
            </div>
          </div>
          <div className='row mt-5'>
            <div className='mb-2 col-md-5 pe-5 xyz'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='Search Name...'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchName}
                  onChange={(e: any) => setSearchName(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
            <div className='mb-2 col-md-4 pe-5 xyz'>
              <Select
                placeholder={'Sort'}
                styles={customStyles}
                isSearchable={false}
                options={sortOptions}
                name='sort'
                onChange={(e: any) => {
                  setSortValue(e.value)
                }}
              />
            </div>
          </div>

          <div className='' style={{maxHeight: '380px', overflow: 'auto'}}>
            <div className='table-responsive '>
              <table className='table align-middle fs-6 gy-5 ' id='kt_ecommerce_sales_table'>
                <thead>
                  <th className='text-start min-w-25px test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                      <input
                        className='form-check-input cursor-pointer'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={
                          listsInVendorsSelected == listsInVendors?.length &&
                          listsInVendors?.length != 0
                            ? true
                            : false
                        }
                        onChange={(e: any) => {
                          let values: any = [...listsInVendors]
                          if (e.target.checked) {
                            values?.map((list: any) => {
                              list.check = true
                            })
                          } else {
                            values?.map((list: any) => {
                              list.check = false
                            })
                          }
                          setListsInVendors(values)
                        }}
                      />
                    </div>
                  </th>

                  <th className='text-start min-w-150px'>List Name</th>
                  <th className='text-start min-w-75px'></th>
                </thead>
                <tbody className='fw-semibold head-text'>
                  {listsInVendors?.length > 0 &&
                    listsInVendors?.map((list: any, ind: any) => {
                      return (
                        <>
                          <tr>
                            <td className='text-start ps-0 test'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 px-0'>
                                <input
                                  className='form-check-input cursor-pointer'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={listsInVendors[ind]?.check}
                                  onChange={(e: any) => {
                                    const values = [...listsInVendors]
                                    values[ind].check = e.target.checked
                                    setListsInVendors(values)
                                  }}
                                />
                              </div>
                            </td>
                            <td className='text-start ps-0'>
                              <div className='d-flex head-text align-items-center'>
                                <div
                                  className='bullet-div'
                                  style={{backgroundColor: `${list?.colorCode}`}}
                                ></div>
                                <p className='user-head pe-2 mb-0 ps-3'>{list.name}</p>
                              </div>
                            </td>
                          </tr>
                        </>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddToLists
