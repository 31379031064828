import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  ApiDelete,
} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import swal from 'sweetalert2'
import 'antd/dist/antd.css'
import moment from 'moment'
import noData from '../../../img/NoData1.svg'
import backArrow from '../../../img/back-arrow.png'
import submitImg from '../../../img/Submit-White.png'
import redCross from '../../../img/remove.png'
import downGreen from '../../../img/down-arrow-green.png'
import upGreen from '../../../img/up-arrow-green.png'
import Select from 'react-select'
import './style.scss'
import sync from '../../../img/sync.png'
import terminate from '../../../img/expired-tenc.png'
import {OverlayTrigger, Tooltip, Card, Form, InputGroup, Button} from 'react-bootstrap'
import allTen from '../../../img/all-tenc.png'
import sendBackImg from '../../../img/share.png'
import swapImg from '../../../img/swap.png'
import lock from '../../../img/padlock.png'
import greenSubmit from '../../../img/green-submit-btn.png'
import approvedIcon from '../../../img/approved.png'
import leasingImg from '../../../img/all-tenc.png'
import financialsIcon from '../../../img/financials.png'

const RenewalTerminationRequest = () => {
  const {id, unitId, tenancyId, type} = useParams()
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const navigate = useNavigate()

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      width: '180px',
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',

      zIndex: '1',
    }),
  }

  // React Select
  const customStylesApprove = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
      width: '200px',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      width: '200px',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '31px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '31px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const {state} = useLocation()
  const [formData, setFormData] = useState<any>({
    // propertyId: window.location.pathname?.split('/')[2],
  })
  const dispatch = useDispatch()
  const [hideApproval, setHideApproval] = useState<any>(false)
  const [approvalUsers, setApprovalUsers] = useState<any>([])
  const [selectedApprover, setSelectedApprover] = useState<any>(null)
  const [approvalDropdownUsers, setApprovalDropdownUsers] = useState<any>([])
  const [approvalComments, setApprovalComments] = useState<any>('')
  const [isViewMode, setIsViewMode] = useState<any>(false)
  const [financeDropdownUsers, setFinanceDropdownUsers] = useState<any>([])
  const [isSubmitToApprovar, setIsSubmitToApprovar] = useState<any>(false)
  const [isSubmitToFinance, setIsSubmitToFinance] = useState<any>(false)
  const [showApprovarDropdown, setShowApprovarDropdown] = useState<any>(false)
  const [showFinanceDropdown, setShowFinanceDropdown] = useState<any>(false)
  const [leasingDropdownUsers, setLeasingDropdownUsers] = useState<any>([])

  const [isLoading, setIsLoading] = useState<any>()
  const [showLock, setShowLock] = useState<any>(false)
  const [isAssign, setIsAssign] = useState<any>(false)
  const [assignData, setAssignData] = useState<any>()

  const [byiddata, setbyiddata] = useState<any>(false)
  const [tenancyData, setTenancyData] = useState<any>({
    renewalDays: 90,
  })
  const [isOriginal, setIsOriginal] = useState<any>(false)
  const [incomePaymentPayload, setIncomePaymentPayload] = useState<any>([])
  const [newTenantId, setNewTenantID] = useState<any>('')
  const [tenancyPayment, setTenancyPayment] = useState<any>([])
  const [subTenantData, setSubTenantData] = useState<any>([])
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)
  const [totalDays, setTotalDays] = useState('0 Days')
  const [ttlDays, setTtlDays] = useState<any>('0')
  const [remainingDays, setRemainingDays] = useState('0 Days')
  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)
  const [tenantDocuments, setTenantDocuments] = useState<any>([])
  const [tenantDeletedDocuments, setTenantDeletedDocuments] = useState<any>([])
  const [contractDocuments, setContractDocuments] = useState<any>([])
  const [contractDeletedDocuments, setContractDeletedDocuments] = useState<any>([])
  const [optionArry, setOptionArry] = useState<any>({
    none: false,
    maintenanceFree: false,
    other: [],
    chillerFree: false,
  })
  const [otherOptions, setOtherOptions] = useState<any>([
    // {
    //   name: '',
    // },
  ])

  const [rentFin, setRentFin] = useState<any>({
    name: 'Rent',
    totalAmount: '',
    noOfPayment: '',
    paymentItemListId: '',
  })

  const [rentAmountError, setRentAmountError] = useState<any>(true)
  const [rentNoOfPaymentsError, setRentNoOfPaymentsError] = useState<any>(true)

  const [securityFin, setSecurityFin] = useState<any>({
    name: 'Security Deposit',
    totalAmount: '',
    noOfPayment: '',
    paymentItemListId: '',
  })

  const [securityAmountError, setSecurityAmountError] = useState<any>(true)
  const [securityNoOfPaymentsError, setSecurityNoOfPaymentsError] = useState<any>(true)

  const [otherFinancials, setOtherFinancials] = useState<any>([
    // {
    //   name: '',
    //   amount: 0,
    //   noOfPayments: 0
    // }
  ])

  const [otherAmountError, setOtherAmountError] = useState<any>(false)
  const [otherNoOfPaymentsError, setOtherNoOfPaymentsError] = useState<any>(false)
  const [otherItem, setOtherItem] = useState<any>({})

  const [pageLimit, setPageLimit] = useState<any>(25)
  const [page, setPage] = useState<any>(1)

  const [leaseWithApproval, setLeaseWithApproval] = useState<any>()
  const [isFinance, setIsFinance] = useState<any>(false)

  const getWorkFlowListByName = () => {
    if (type === '2') {
      ApiGet(`corporate/sub_workflow_list/get?name=Lease Renewals`)
        .then((res: any) => {
          setLeaseWithApproval(res?.data?.data?.isApprovalEnabled)
          setIsFinance(res?.data?.data?.isFinancialIncluded)
          localStorage.setItem('leaseRenewalsWithFinancials', res?.data?.data?.isFinancialIncluded)

          if (
            (byiddata?.subWorkFlowListValue?.isApprovalEnabled &&
              !res?.data?.data?.isApprovalEnabled &&
              approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 0) ||
            approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 6
          ) {
            setShowLock(true)
            setIsViewMode(true)
          }
        })
        .catch((err: any) => {})
    } else {
      ApiGet(`corporate/sub_workflow_list/get?name=Lease Termination`)
        .then((res: any) => {
          setLeaseWithApproval(res?.data?.data?.isApprovalEnabled)
          setIsFinance(res?.data?.data?.isFinancialIncluded)
          localStorage.setItem(
            'leaseTerminationWithFinancials',
            res?.data?.data?.isFinancialIncluded
          )

          if (
            (byiddata?.subWorkFlowListValue?.isApprovalEnabled &&
              !res?.data?.data?.isApprovalEnabled &&
              approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 0) ||
            approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 6
          ) {
            setShowLock(true)
            setIsViewMode(true)
          }
        })
        .catch((err: any) => {})
    }
  }

  const getApprovalDropdown = () => {
    let body: any
    if (type === '2')
      body = {
        subWorkflowListName: 'Lease Renewals',
      }
    else
      body = {
        subWorkflowListName: 'Lease Termination',
      }

    ApiPost(`corporate/user/get/dropdown/tenancy/approval_workflow`, body)
      .then((res: any) => {
        setApprovalDropdownUsers(res?.data?.data)
      })
      .catch((err: any) => {})
  }

  //
  const getFinanceDropdown = () => {
    const body = {
      subWorkflowListName: 'New Lease',
    }

    ApiPost(`corporate/user/get/dropdown/tenancy/finance_user`, body)
      .then((res: any) => {
        setFinanceDropdownUsers(res?.data?.data)
      })
      .catch((err: any) => {})
  }

  //
  const getLeasingDropdown = () => {
    const body = {
      isNewLease: true,
      isRenewalLease: false,
      isTerminationLease: false,
    }

    ApiPost(`corporate/user/get/dropdown/tenancy/submit_lease`, body)
      .then((res: any) => {
        setLeasingDropdownUsers(res?.data?.data)
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    if (
      approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance ||
      (!approvalUsers[approvalUsers?.length - 1]?.isApprovalRequest &&
        !approvalUsers[approvalUsers?.length - 1]?.isFinanceApprovalRequest &&
        !approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance)
    )
      getLeasingDropdown()
  }, [approvalUsers])

  const [lastApproverId, setLastApproverId] = useState<any>()

  const getApprovalDetails = () => {
    const body = {
      tenantRequestId: id,
    }
    ApiPost(`corporate/approval_workflow/tenancy/get`, body)
      .then((res: any) => {
        setApprovalUsers(res?.data?.data)
        for (let i = res?.data?.data?.length - 2; i >= 0; i--) {
          if (res?.data?.data?.[i]?.approvalStatus !== 5) {
            setLastApproverId(res?.data?.data?.[i]?.createdBy?.[0]?._id)
            //  console.log(res?.data?.data?.[i]?.createdBy?.[0]?._id)
            break
          }
        }
      })
      .catch((err: any) => {})
  }

  //
  useEffect(() => {
    callRequestDataById()
  }, [])

  //
  useEffect(() => {
    if (leaseWithApproval) {
      getApprovalDropdown()
      getFinanceDropdown()
      getApprovalDetails()
    }
  }, [leaseWithApproval])

  const callRequestDataById = async () => {
    await ApiGet(`corporate/tenant_request?tenantRequestId=${id}&type=${type}`)
      .then((res) => {
        setLeaseWithApproval(res?.data?.data?.[0]?.subWorkFlowListValue?.isApprovalEnabled)
        setIsFinance(res?.data?.data?.[0]?.subWorkFlowListValue?.isFinancialIncluded)
        if (type == '2')
          localStorage.setItem(
            'leaseRenewalsWithFinancials',
            res?.data?.data?.[0]?.subWorkFlowListValue?.isFinancialIncluded
          )
        else
          localStorage.setItem(
            'leaseTerminationWithFinancials',
            res?.data?.data?.[0]?.subWorkFlowListValue?.isFinancialIncluded
          )

        setbyiddata(res?.data?.data?.[0])
        if (
          type == '2' &&
          res?.data?.data?.[0]?.tenancyId != null
          // && res?.data?.data?.[0]?.clone_tenancy?.[0]?.isDraft == false
        ) {
          getTenancyData(res?.data?.data?.[0]?.tenancyId)
          getAllPayments(res?.data?.data?.[0]?.tenancyId)
        }

        if (type == '3') {
          let temp = localStorage.getItem('DiscardSession')
          // console.log(temp)
          if (temp != null) {
            let val = JSON.parse(temp)
            // console.log(val)
            // console.log(" discard session "+res?.data?.data?.[0]?._id)
            if (val?.requestId == res?.data?.data?.[0]?._id) {
              // discardSession()
              const body = {
                tenancyId: res?.data?.data?.[0]?.tenancyId,
              }

              ApiPost(`corporate/tenancy/apply/session?isCloneRecord=true`, body)
                .then(() => {
                  localStorage.removeItem('DiscardSession')
                })
                .catch((err: any) => {
                  ErrorToast(err?.message)
                })
            }
          }

          let temp2 = localStorage.getItem('DeleteClone')
          if (temp2 != null) {
            let val = JSON.parse(temp2)
            if (val?.requestId == res?.data?.data?.[0]?._id) {
              // deleteClone()
              const body = {
                tenancyId: res?.data?.data?.[0]?.tenancyId,
              }

              ApiPost(`corporate/tenancy/terminate/delete_clone`, body)
                .then(() => {
                  localStorage.removeItem('DeleteClone')
                })
                .catch((err: any) => {
                  ErrorToast(err?.message)
                })
            }
          }

          if (
            res?.data?.data?.[0]?.terminationOffer == undefined ||
            res?.data?.data?.[0]?.terminationOffer == null
          )
            setTenancyData(res?.data?.data?.[0]?.clone_tenancy?.[0])
          else getTerminationOffer(res?.data?.data?.[0]?.terminationOffer)
        }

        //  setUnitDataApi(res?.data?.data)
        // dispatch(setRequestorId(id))
        // dispatch(setComefrom('tenant'))
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const getTenancyData = (id: any) => {
    ApiGet(`corporate/tenancy/full_detail/${id}?isCloneRecord=true`)
      .then((res) => {
        // console.log('\nres.data.data', res.data.data)

        if (res?.data?.data?.[0]?.tenant?.length > 0) {
          setNewTenantID(res?.data?.data?.[0]?.tenant?.[0]?._id)
          setFormData(res?.data?.data?.[0]?.tenant?.[0])
        }

        setTenancyData(res?.data?.data[0])
        setTenancyPayment(res?.data?.data[0].tenancy_payment)

        // SubTenant Data
        let values: any = []
        for (let i = 0; i < res?.data?.data?.[0].sub_tenant?.length; i++) {
          values[i] = {
            id: res?.data?.data?.[0].sub_tenant?.[i]?._id,
            firstName: res?.data?.data?.[0].sub_tenant?.[i]?.firstName,
            lastName: res?.data?.data?.[0].sub_tenant?.[i]?.lastName,
            email: res?.data?.data?.[0].sub_tenant?.[i]?.email,
            countryCode: `${res?.data?.data?.[0].sub_tenant?.[i]?.countryCode}`,
            phoneNumber: `${res?.data?.data?.[0].sub_tenant?.[i]?.phoneNumber}`,
          }
        }
        setSubTenantData(values)

        if (res?.data?.data[0]?.duration?.start_date != null) {
          setStart(moment(res?.data?.data[0]?.duration?.start_date).format('DD/MM/YYYY'))
          setStartDatePayload(moment(res?.data?.data[0]?.duration?.start_date).format('YYYY-MM-DD'))
        }

        if (res?.data?.data[0]?.duration?.end_date != null) {
          if (state?.terminationDate) {
            setEnd(moment(new Date(state?.terminationDate)).format('DD/MM/YYYY'))
            setEndDatePayload(moment(new Date(state?.terminationDate)).format('YYYY-MM-DD'))
          } else {
            setEnd(moment(res?.data?.data[0]?.duration?.end_date).format('DD/MM/YYYY'))
            setEndDatePayload(moment(res?.data?.data[0]?.duration?.end_date).format('YYYY-MM-DD'))
          }
        }

        setTotalDays(res?.data?.data[0]?.duration?.days + ' days')
        setRemainingDays(res?.data?.data[0]?.remainingDays + ' days')
        setNewTenantID(res?.data?.data?.[0]?.tenantId)

        let final_obj: any = {
          none: false,
          maintenanceFree: false,
          chillerFree: false,
          other: [],
          ...res?.data?.data[0]?.benefits,
        }
        setOptionArry(final_obj)

        let temp: any = []
        for (let i = 0; i < res?.data?.data[0]?.benefits?.other?.length; i++) {
          temp[i] = {
            value: res?.data?.data[0]?.benefits?.other?.[i],
          }
        }
        setOtherOptions(temp)

        if (res?.data?.data?.[0]?.tenant?.[0]?._id != undefined) {
          getTenantDocuments(
            res?.data?.data?.[0]?.tenant?.[0]?._id,
            res?.data?.data?.[0]?.tenantDocument,
            res?.data?.data?.[0]?.contractDocument
          )

          getContractDocuments(
            res?.data?.data?.[0]?.tenant?.[0]?._id,
            res?.data?.data?.[0]?.tenantDocument,
            res?.data?.data?.[0]?.contractDocument
          )
        }

        let finance: any = res?.data?.data?.[0]?.financialSummery
        let otherInd: any = 0
        let otherValues: any = []
        for (let i = 0; i < finance?.length; i++) {
          if (finance[i]?.name == 'Rent') setRentFin(finance[i])
          else if (finance[i]?.name == 'Security Deposit') setSecurityFin(finance[i])
          else {
            otherValues[otherInd] = finance[i]
            otherInd++
          }
        }
        setOtherFinancials(otherValues)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const getTerminationOffer = (data: any) => {
    /********************** Tenancy Data **********************/
    if (data?.tenancyData?.[0]?.tenant?.length > 0) {
      setNewTenantID(data?.tenancyData?.[0]?.tenant?.[0]?._id)
      setFormData(data?.tenancyData?.[0]?.tenant?.[0])
    }

    setTenancyData(data?.tenancyData?.[0])
    // SubTenant Data
    let values: any = []
    for (let i = 0; i < data?.tenancyData?.[0]?.sub_tenant?.length; i++) {
      values[i] = {
        id: data?.tenancyData?.[0]?.sub_tenant?.[i]?._id,
        firstName: data?.tenancyData?.[0]?.sub_tenant?.[i]?.firstName,
        lastName: data?.tenancyData?.[0]?.sub_tenant?.[i]?.lastName,
        email: data?.tenancyData?.[0]?.sub_tenant?.[i]?.email,
        countryCode: `${data?.tenancyData?.[0]?.sub_tenant?.[i]?.countryCode}`,
        phoneNumber: `${data?.tenancyData?.[0]?.sub_tenant?.[i]?.phoneNumber}`,
      }
    }
    setSubTenantData(values)

    if (data?.tenancyData?.[0]?.duration?.start_date != null) {
      setStart(moment(data?.tenancyData?.[0]?.duration?.start_date).format('DD/MM/YYYY'))
      setStartDatePayload(moment(data?.tenancyData?.[0]?.duration?.start_date).format('YYYY-MM-DD'))
    }

    if (data?.tenancyData?.[0]?.duration?.end_date != null) {
      if (state?.terminationDate) {
        setEnd(moment(new Date(state?.terminationDate)).format('DD/MM/YYYY'))
        setEndDatePayload(moment(new Date(state?.terminationDate)).format('YYYY-MM-DD'))
      } else {
        setEnd(moment(data?.tenancyData?.[0]?.duration?.end_date).format('DD/MM/YYYY'))
        setEndDatePayload(moment(data?.tenancyData?.[0]?.duration?.end_date).format('YYYY-MM-DD'))
      }
    }

    setTotalDays(data?.tenancyData?.[0]?.duration?.days + ' days')
    setRemainingDays(data?.tenancyData?.[0]?.remainingDays + ' days')
    setNewTenantID(data?.tenancyData?.[0]?.tenantId)

    let final_obj: any = {
      none: false,
      maintenanceFree: false,
      chillerFree: false,
      other: [],
      ...data?.tenancyData?.[0]?.benefits,
    }
    setOptionArry(final_obj)

    let temp: any = []
    for (let i = 0; i < data?.tenancyData?.[0]?.benefits?.other?.length; i++) {
      temp[i] = {
        value: data?.tenancyData?.[0]?.benefits?.other?.[i],
      }
    }
    setOtherOptions(temp)

    getTenantDocuments(
      data?.tenancyData?.[0]?.tenant?.[0]?._id,
      data?.tenancyData?.[0]?.tenantDocument,
      data?.tenancyData?.[0]?.contractDocument
    )

    getContractDocuments(
      data?.tenancyData?.[0]?.tenant?.[0]?._id,
      data?.tenancyData?.[0]?.tenantDocument,
      data?.tenancyData?.[0]?.contractDocument
    )

    // payments

    let values1: any = []

    let temp1: any = []

    for (let i = 0; i < data?.paymentData?.length; i++) {
      values1[i] = {
        payment: {
          _id: data?.paymentData?.[i]?._id,
          name: data?.paymentData?.[i]?.name,
          note: data?.paymentData?.[i]?.note,
          currency: 'AED',
          paymentStatus: data?.paymentData?.[i]?.paymentStatus,
          categories: data?.paymentData?.[i]?.categories,
          amountPaid: parseFloat(data?.paymentData?.[i]?.amountPaid),
          outstandingAmount: data?.paymentData?.[i]?.outstandingAmount,
          subTotalAmount: data?.paymentData?.[i]?.subTotalAmount,
          totalVAT: data?.paymentData?.[i]?.totalVAT,
          totalAmount: data?.paymentData?.[i]?.totalAmount,
          // paymentType: 0,
          paymentMethod: data?.paymentData?.[i]?.paymentMethod,
          paymentAccountId: data?.paymentData?.[i]?.paymentAccountId,
          chequeBankName: data?.paymentData?.[i]?.chequeBankName,
          isCardVerificationEnable: data?.paymentData?.[i]?.isCardVerificationEnable,
          dueDate: data?.paymentData?.[i]?.dueDate,
          datePaid: data?.paymentData?.[i]?.datePaid,
          createdAt: data?.paymentData?.[i]?.createdAt,
          attachments: data?.paymentData?.[i]?.attachments,
          chequeImages: data?.paymentData?.[i]?.chequeImages,
          tenancyId: data?.tenancyData?.[0]?._id,

          // unitId: unitDetails?._id,
          // clusterId: unitDetails?.clusterId,
          // unitGroupId: unitDetails?.unitGroupId,
          // communityId: unitDetails?.communityId,
          // floorId: unitDetails?.floorId,
          // buildingId: unitDetails?.buildingId,
        },
        // new_payment_sub_item: payment,
        // new_payment_invoice: payInv,
        // new_payment_receipt: prec,
        // new_payment_reminder: remindersSched,
      }
      for (let j = 0; j < data?.paymentData?.[i]?.payment_sub_item?.length; j++) {
        temp1[j] = {
          _id: data?.paymentData?.[i]?.payment_sub_item?.[j]?._id,
          name: data?.paymentData?.[i]?.payment_sub_item?.[j]?.name,
          amount: data?.paymentData?.[i]?.payment_sub_item?.[j]?.amount,
          quantity: data?.paymentData?.[i]?.payment_sub_item?.[j]?.quantity,
          isLateItem: data?.paymentData?.[i]?.payment_sub_item?.[j]?.isLatePaymentFeesRecord
            ? true
            : false,
        }
      }
      values1[i].new_payment_sub_item = temp1
    }

    setIncomePaymentPayload(values1)
  }

  const getTenantDocuments = async (id: any, dataTenant: any, dataContract: any) => {
    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        let tentFold: any = []
        let t = 0

        let deletedFolder: any = []
        let d: any = 0

        //  Documents Section
        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          let isInc: any = false
          let incTenant: any = false
          let ind: any = 0
          let indTen: any = 0
          let isDeleted = true

          for (let x = 0; x < dataTenant?.length; x++) {
            if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
              isDeleted = false
              x = dataTenant?.length
            }
          }

          if (!isDeleted) {
            //  Tenant Documents
            if (res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
              for (let x = 0; x < dataTenant?.length; x++) {
                if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
                  incTenant = true
                  ind = x
                  x = dataTenant?.length
                } else {
                  incTenant = false
                }
              }

              if (incTenant) {
                tentFold[t] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: true,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  let isAvailable: any = false
                  let isAvailableTenant: any = false

                  for (let x = 0; x < dataTenant[ind]?.fileList?.length; x++) {
                    if (
                      res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                      dataTenant[ind]?.fileList?.[x]?._id
                    ) {
                      // console.log(res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id + ' ' + dataTenant[ind]?.fileList?.[x]?._id)
                      isAvailableTenant = true
                      x = dataTenant[ind]?.fileList?.length
                    } else {
                      isAvailableTenant = false
                    }
                  }

                  // console.log(isAvailableTenant)

                  if (isAvailableTenant) {
                    tentFold[t].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: true,
                    }
                  } else {
                    tentFold[t].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                }
                t++
              } else {
                // console.log(res?.data?.data?.tenant_folder?.[i]?.name)
                tentFold[t] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: false,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  // tentFold[t].isCheck = false,
                  tentFold[t].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: false,
                  }
                }
                t++
              }
            }
          }
        }

        let temp: any = false
        let tempInd: any = 0
        for (let x = 0; x < dataTenant?.length; x++) {
          for (let y = 0; y < tentFold?.length; y++) {
            if (tentFold[y]._id == dataTenant[x]?._id) {
              // console.log(tentFold[y]._id + ' ' + dataTenant[x]?._id)
              temp = true
              // tempInd = x
              y = tentFold?.length
            } else {
              temp = false
              tempInd = x
            }
          }
          if (temp == false) {
            if (dataTenant?.[tempInd]?.name != 'Contracts') {
              deletedFolder[d] = {
                _id: dataTenant?.[tempInd]?._id,
                name: dataTenant?.[tempInd]?.name,
                isSystemGenerated: dataTenant?.[tempInd]?.isSystemGenerated,
                isCheck: true,
                fileList: dataTenant?.[tempInd]?.fileList,
              }

              for (let j = 0; j < dataTenant?.[tempInd]?.fileList?.length; j++) {
                deletedFolder[d].fileList[j] = {
                  _id: dataTenant?.[tempInd]?.fileList?.[j]?._id,
                  fileName: dataTenant?.[tempInd]?.fileList?.[j]?.fileName,
                  fileURL: dataTenant?.[tempInd]?.fileList?.[j]?.fileURL,
                  isChecked: true,
                }
              }
            }
            d++
          }
        }

        setTenantDocuments(tentFold)
        setTenantDeletedDocuments(deletedFolder)
      })
      .catch((err) => console.log('err', err))
  }

  const getContractDocuments = async (id: any, dataTenant: any, dataContract: any) => {
    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        let tentFold: any = []
        let t = 0

        let contFold: any = []
        let c = 0

        let deletedFolder: any = []
        let d: any = 0

        //  Documents Section
        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          let isInc: any = false
          let incTenant: any = false
          let ind: any = 0
          let indTen: any = 0
          let isDeleted = true

          // Contract/Tenancy Documents
          // console.log(dataContract)
          // console.log(contractDocuments)
          for (let x = 0; x < dataContract?.length; x++) {
            if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
              isDeleted = false
              x = dataTenant?.length
            }
          }

          if (!isDeleted) {
            if (
              res?.data?.data?.tenant_folder?.[i].name != 'Passports' &&
              res?.data?.data?.tenant_folder?.[i].name != 'Residencies' &&
              res?.data?.data?.tenant_folder?.[i].name != 'IDs'
            ) {
              for (let x = 0; x < dataContract?.length; x++) {
                if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
                  isInc = true
                  indTen = x
                  x = dataContract?.length
                } else {
                  isInc = false
                }
              }

              if (isInc) {
                contFold[c] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: true,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  let isAvailable: any = false

                  for (let x = 0; x < dataContract[indTen]?.fileList?.length; x++) {
                    if (
                      res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                      dataContract[indTen]?.fileList?.[x]?._id
                    ) {
                      isAvailable = true
                      x = dataContract[indTen]?.fileList?.length
                    } else {
                      isAvailable = false
                    }
                  }

                  if (isAvailable) {
                    contFold[c].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: true,
                    }
                  } else {
                    contFold[c].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                }
                c++
              } else {
                contFold[c] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: false,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  // tentFold[t].isCheck = false,
                  contFold[c].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: false,
                  }
                }
                c++
              }
            }
          }
        }

        let temp: any = false
        let tempInd: any = 0
        for (let x = 0; x < dataContract?.length; x++) {
          for (let y = 0; y < contFold?.length; y++) {
            if (contFold[y]._id == dataContract[x]?._id) {
              console.log(contFold[y]._id + ' ' + dataContract[x]?._id)
              temp = true
              // tempInd = x
              y = contFold?.length
            } else {
              temp = false
              tempInd = x
            }
          }
          if (temp == false) {
            if (
              dataContract?.[tempInd]?.name != 'Passports' &&
              dataContract?.[tempInd]?.name != 'Residencies' &&
              dataContract?.[tempInd]?.name != 'IDs'
            ) {
              deletedFolder[d] = {
                _id: dataContract?.[tempInd]?._id,
                name: dataContract?.[tempInd]?.name,
                isSystemGenerated: dataContract?.[tempInd]?.isSystemGenerated,
                isCheck: true,
                fileList: dataContract?.[tempInd]?.fileList,
              }

              for (let j = 0; j < dataContract?.[tempInd]?.fileList?.length; j++) {
                deletedFolder[d].fileList[j] = {
                  _id: dataContract?.[tempInd]?.fileList?.[j]?._id,
                  fileName: dataContract?.[tempInd]?.fileList?.[j]?.fileName,
                  fileURL: dataContract?.[tempInd]?.fileList?.[j]?.fileURL,
                  isChecked: true,
                }
              }
            }
            d++
          }
        }

        setContractDocuments(contFold)
        setContractDeletedDocuments(deletedFolder)
      })
      .catch((err) => console.log('err', err))
  }

  const getAllPayments = (id:any) => {
    const body = {
      limit: pageLimit,
      page: page,
      tenancyId: id,
      paymentStatus: 4,
      includePaymentSubItem: true,
    }
    ApiPost(`corporate/payment/get/tenancy?isCloneRecord=true`, body)
      .then((res) => {
        let values: any = []

        for (let i = 0; i < res?.data?.data?.payment_data?.length; i++) {
          let temp: any = []
          values[i] = {
            payment: {
              _id: res?.data?.data?.payment_data?.[i]?._id,
              name: res?.data?.data?.payment_data?.[i]?.name,
              note: res?.data?.data?.payment_data?.[i]?.note,
              currency: 'AED',
              paymentStatus: res?.data?.data?.payment_data?.[i]?.paymentStatus,
              categories: res?.data?.data?.payment_data?.[i]?.categories,
              amountPaid: parseFloat(res?.data?.data?.payment_data?.[i]?.amountPaid),
              outstandingAmount: res?.data?.data?.payment_data?.[i]?.outstandingAmount,
              subTotalAmount: res?.data?.data?.payment_data?.[i]?.subTotalAmount,
              totalVAT: res?.data?.data?.payment_data?.[i]?.totalVAT,
              totalAmount: res?.data?.data?.payment_data?.[i]?.totalAmount,
              // paymentType: 0,
              paymentMethod: res?.data?.data?.payment_data?.[i]?.paymentMethod,
              paymentAccountId: res?.data?.data?.payment_data?.[i]?.paymentAccountId,
              chequeBankName: res?.data?.data?.payment_data?.[i]?.chequeBankName,
              isCardVerificationEnable:
                res?.data?.data?.payment_data?.[i]?.isCardVerificationEnable,
              dueDate: res?.data?.data?.payment_data?.[i]?.dueDate,
              attachments: res?.data?.data?.payment_data?.[i]?.attachments,
              chequeImages: res?.data?.data?.payment_data?.[i]?.chequeImages,
              tenancyId: tenancyId,
              // unitId: unitDetails?._id,
              // clusterId: unitDetails?.clusterId,
              // unitGroupId: unitDetails?.unitGroupId,
              // communityId: unitDetails?.communityId,
              // floorId: unitDetails?.floorId,
              // buildingId: unitDetails?.buildingId,
              datePaid: res?.data?.data?.payment_data?.[i]?.datePaid,
            },
            new_payment_sub_item: [],
            // new_payment_invoice: payInv,
            // new_payment_receipt: prec,
            // new_payment_reminder: remindersSched,
          }
          for (let j = 0; j < res?.data?.data?.payment_data?.[i]?.payment_sub_item?.length; j++) {
            temp[j] = {
              _id: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?._id,
              name: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?.name,
              amount: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?.amount,
              quantity: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?.quantity,
              isLateItem: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]
                ?.isLatePaymentFeesRecord
                ? true
                : false,
            }
          }
          values[i].new_payment_sub_item = temp
        }

        setIncomePaymentPayload(values)
        setPageLimit(res?.data?.data?.state?.page_limit)
        
      })
      .catch((err) => console.log('err', err))
  }

  const requeststatuschange = async (id: any, type: any) => {
    let body = {
      id: id,
      status: type,
    }
    ApiPut(`corporate/tenant_request/status`, body)
      .then((res) => {
        SuccessToast(res.data.message)
        if (comefrom == 'propertyProfile') {
          navigate(`/overViewData/${unitId}/requests`, {
            state: {
              details: state,
              id: unitId,
            },
          })
        } else if (comefrom == 'edit-view') {
          navigate(`/tenancy-view/2/${unitId}/${tenancyId}`)
        } else {
          navigate(`/tenancies`, {
            state: {
              from: 'requests',
            },
          })
        }
      })
      .catch((err) => ErrorToast(err.message))
  }

  //
  const addTerminateTenancy = (uId: any, tenId: any, reqId: any, reqDate: any) => {
    const body = {
      tenancyId: tenId,
      tenantRequestId: reqId,
    }

    ApiPost(`corporate/tenancy/terminate`, body)
      .then((res) => {
        navigate(`/tenancy-termination/3/${byiddata?.unit?.[0]?._id}/${byiddata?.tenancyId}`, {
          state: {
            tenantReqId: byiddata?._id,
            terminationDate: byiddata?.terminationDate,
          },
        })
      })
      .catch(() => {})
  }

  const approvalFlowSubmission = (event: any) => {
    const body = {
      id: approvalUsers[approvalUsers?.length - 1]?._id,
      type: 0,
      tenancyWorkFlowType: type === '2' ? 1 : 2,
      approvalStatus: 1,
      comment: approvalComments,
      approvalUserId:
        isSubmitToApprovar && !approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
          ? selectedApprover
          : undefined,
      financeApprovalUserId:
        isSubmitToApprovar && approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
          ? selectedApprover
          : undefined,
      financeSubmitterId:
        isSubmitToFinance && !approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
          ? selectedApprover
          : undefined,
      leaseSubmitterId:
        isSubmitToFinance && approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
          ? selectedApprover
          : undefined,
    }
    ApiPost(`corporate/approval_workflow/tenancy/submission`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        if (comefrom == 'propertyProfile') {
          navigate(`/overViewData/${unitId}/requests`, {
            state: {
              details: state,
              id: unitId,
            },
          })
        } else if (comefrom == 'edit-view') {
          navigate(`/tenancy-view/2/${unitId}/${tenancyId}`)
        } else {
          navigate(`/tenancies`, {
            state: {
              from: 'requests',
            },
          })
        }
      })
      .catch((err: any) => {
        ErrorToast(err.message)
      })

    setIsLoading(false)
  }

  const reAssign = () => {
    const body = {
      approvalWorkflowId: assignData?._id,
      // approvalUserId: selectedApprover,
      approvalUserId: approvalUsers[approvalUsers?.length - 1]?.isApprovalRequest
        ? selectedApprover
        : undefined,
      financeApprovalUserId: approvalUsers[approvalUsers?.length - 1]?.isFinanceApprovalRequest
        ? selectedApprover
        : undefined,
      financeSubmitterId: approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
        ? selectedApprover
        : undefined,
      leaseSubmitterId:
        !approvalUsers[approvalUsers?.length - 1]?.isApprovalRequest &&
        !approvalUsers[approvalUsers?.length - 1]?.isFinanceApprovalRequest &&
        !approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
          ? selectedApprover
          : undefined,
    }
    ApiPost(`corporate/approval_workflow/tenancy/reassign`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        if (comefrom == 'propertyProfile') {
          navigate(`/overViewData/${unitId}/requests`, {
            state: {
              details: state,
              id: unitId,
            },
          })
        } else if (comefrom == 'edit-view') {
          navigate(`/tenancy-view/2/${unitId}/${tenancyId}`)
        } else {
          navigate(`/tenancies`, {
            state: {
              from: 'requests',
            },
          })
        }
      })
      .catch((err: any) => {
        ErrorToast(err.message)
      })
  }

  const sendBack = () => {
    const body = {
      approvalWorkflowId: approvalUsers[approvalUsers?.length - 1]?._id,
      comment: approvalComments,
    }
    ApiPost(`corporate/approval_workflow/tenancy/sent_back`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        if (comefrom == 'propertyProfile') {
          navigate(`/overViewData/${unitId}/requests`, {
            state: {
              details: state,
              id: unitId,
            },
          })
        } else if (comefrom == 'edit-view') {
          navigate(`/tenancy-view/2/${unitId}/${tenancyId}`)
        } else {
          navigate(`/tenancies`, {
            state: {
              from: 'requests',
            },
          })
        }
      })
      .catch((err: any) => {
        ErrorToast(err.message)
      })
  }

  const getViewMode = () => {
    if (user?._id === byiddata?.requestor?._id) {
      setIsViewMode(false)
    } else {
      if (approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id !== user?._id) {
        setIsViewMode(true)
      } else setIsViewMode(false)
    }
  }

  const onSubmitRenewal = (val: any) => {
    setIsLoading(true)
    if (
      tenancyData?.contractNo?.trim()?.length == 0 ||
      tenancyData?.duration?.start_date == null ||
      tenancyData?.duration?.end_date == null ||
      tenancyData?.duration?.days <= tenancyData?.renewalDays ||
      ((rentAmountError ||
        securityAmountError ||
        securityNoOfPaymentsError ||
        rentNoOfPaymentsError ||
        otherAmountError ||
        otherNoOfPaymentsError) &&
        isFinance)
    ) {
      ErrorToast(
        'Some mandatory fields are not filled yet.. Please review the Renewal Offer and submit again..'
      )
      setIsLoading(false)
    } else {
      let payment: any = [...incomePaymentPayload]

      let deletedPayments: any = []
      let deleteInd: any = 0

      let discardedPayments: any = [...deletedPayments]

      if (isFinance) {
        for (let i = 0; i < incomePaymentPayload?.length; i++) {
          let newItems: any = []
          let newItemsInd: any = 0
          let updateItems: any = []
          let updateItemsInd: any = 0

          for (let j = 0; j < incomePaymentPayload[i]?.new_payment_sub_item?.length; j++) {
            payment[i].new_payment_sub_item[j] = {
              _id:
                payment[i]?.new_payment_sub_item[j]?._id?.trim()?.length == 0
                  ? undefined
                  : payment[i]?.new_payment_sub_item[j]?._id,
              VATAmount: payment[i]?.new_payment_sub_item[j].VATAmount,
              VATPercentage: payment[i]?.new_payment_sub_item[j].VATPercentage,
              amount: payment[i]?.new_payment_sub_item[j].amount,
              amountPaid: payment[i]?.new_payment_sub_item[j].amountPaid,
              isLatePaymentFeesRecord: payment[i]?.new_payment_sub_item[j].isLatePaymentFeesRecord,
              latePaymentFees: payment[i]?.new_payment_sub_item[j].latePaymentFees,
              latePaymentObject: payment[i]?.new_payment_sub_item[j].latePaymentObject,
              latePaymentSelectionStatus:
                payment[i]?.new_payment_sub_item[j].latePaymentSelectionStatus,
              name: payment[i]?.new_payment_sub_item[j].name,
              nextLatePaymentCycle: payment[i]?.new_payment_sub_item[j].nextLatePaymentCycle,
              oldLatePaymentCycleList: payment[i]?.new_payment_sub_item[j].oldLatePaymentCycleList,
              outstandingAmount: payment[i]?.new_payment_sub_item[j].outstandingAmount,
              paymentItemListId: payment[i]?.new_payment_sub_item[j].paymentItemListId,
              paymentSubItemStatus: payment[i]?.new_payment_sub_item[j].paymentSubItemStatus,
              quantity: payment[i]?.new_payment_sub_item[j].quantity,
              serviceJobId: payment[i]?.new_payment_sub_item[j].serviceJobId,
              serviceRequestId: payment[i]?.new_payment_sub_item[j].serviceRequestId,
              totalAmount: payment[i]?.new_payment_sub_item[j].totalAmount,
            }

            if (payment[i]?.new_payment_sub_item[j]?._id?.trim()?.length == undefined) {
              newItems[newItemsInd] = payment[i].new_payment_sub_item[j]
              newItemsInd++
            } else {
              updateItems[updateItemsInd] = payment[i].new_payment_sub_item[j]
              updateItemsInd++
            }
          }

          // Payment Invoice
          if (payment[i]?.payment?.invoiceId != undefined) {
            payment[i].update_payment_invoice = payment[i].new_payment_invoice
            // payment[i].update_payment_invoice[0]._id = payment[i]?.payment?.invoiceId

            payment[i].update_payment_invoice[0] = {
              _id: payment[i]?.payment?.invoiceId,
              // "id": 51,
              // "workspaceId": "ae7ef7b8-7fa6-4375-9f97-3705a14186a8",
              note: payment[i].new_payment_invoice?.[0]?.note,
              invoiceNo: payment[i].new_payment_invoice?.[0]?.invoiceNo,
              date: moment(new Date()).format('YYYY-MM-DD'),
              pdfURL: payment[i].new_payment_invoice?.[0]?.pdfURL,
              // invoiceLogo: "6582bac50d9a049c6882be03/logo/1703154826946.jpg",
              // "invoiceCompany": {
              //   "trn": "147852369",
              //   "name": "Muhammad",
              //   "address": "9XRH+3J7, Lund Khwar, Mardan, Khyber Pakhtunkhwa, Pakistan",
              //   "countryCode": "+92",
              //   "phoneNumber": "341-0521297",
              //   "email": "test@mailinator.com",
              //   "_id": "6584148bfe71fa5a07a69c8c"
              // },
              attachments: payment[i].new_payment_invoice?.[0]?.attachments,
              paymentSubItems: payment[i].new_payment_sub_item,
              paymentMethod: payment[i]?.payment?.paymentMethod,
              receiptAmount:
                payment[i].new_payment_receipt?.length > 0
                  ? payment[i].new_payment_receipt?.[0]?.amountPaid
                  : 0,
              totalRefundAmount: 0,
              refundVATAmount: 0,
              refundAmount: 0,
              totalAmount: payment[i]?.payment?.totalAmount,
              saveStatus: 1,
              creditAmount: 0,
              creditVATAmount: 0,
              totalCreditAmount: 0,
              debitAmount: 0,
              debitVATAmount: 0,
              totalDebitAmount: 0,
              totalVAT: payment[i]?.payment?.totalVAT,
              subTotalAmount: payment[i]?.payment?.subTotalAmount,
              isReceiptGenerated: payment[i].new_payment_receipt?.length > 0 ? true : false,
              isShareToTenant: payment[i].new_payment_invoice?.[0]?.isShareToTenant,
              isManualPDF: payment[i].new_payment_invoice?.[0]?.isManualPDF,
              // "isMainPDF": true,
              isActive: true,
              isLock: false,
              isBlock: false,
              isVoid: false,
              paymentAccountId: payment[i].new_payment_invoice?.[0]?.paymentAccountId,
              paymentId: payment[i]?.payment?._id,
              // "createdBy": "6582bac50d9a049c6882be03"
            }
            payment[i].new_payment_invoice = []
          }

          // Payment Receipt
          if (payment[i]?.payment?.receiptId != undefined) {
            if (payment[i].new_payment_receipt?.length == 0) {
              payment[i].delete_payment_receipt = [{_id: payment[i]?.payment?.receiptId}]
            } else {
              payment[i].update_payment_receipt = payment[i].new_payment_receipt
              payment[i].update_payment_receipt[0]._id = payment[i]?.payment?.receiptId
              payment[i].new_payment_receipt = []
            }
          }

          // Payment reminder
          let newReminder: any = []
          let updateReminder: any = []
          let newReminderInd: any = 0
          let updateReminderInd: any = 0

          if (payment[i]?.new_payment_reminder?.length > 0) {
            for (let j = 0; j < payment[i]?.new_payment_reminder?.length; j++) {
              if (
                payment[i]?.new_payment_reminder?.[j]?._id != undefined &&
                payment[i]?.new_payment_reminder?.[j]?._id?.trim()?.length > 0
              ) {
                updateReminder[updateReminderInd] = payment[i]?.new_payment_reminder?.[j]
                updateReminderInd++
              } else {
                newReminder[newReminderInd] = payment[i]?.new_payment_reminder?.[j]
                newReminder[newReminderInd]._id = undefined
                newReminderInd++
              }
            }
            payment[i].new_payment_reminder = newReminder
            payment[i].update_payment_reminder = updateReminder
          }

          payment[i].new_payment_sub_item = newItems
          payment[i].update_payment_sub_item = updateItems
          payment[i].payment.datePaid = undefined
          payment[i].isManipulated = undefined
          payment[i].payment.receiptId = undefined
          payment[i].payment.invoiceId = undefined
        }
      } else {
        discardedPayments = []
        for (let i = 0; i < incomePaymentPayload?.length; i++) {
          discardedPayments[deleteInd] = incomePaymentPayload[i]?.payment?._id
        }
      }

      const body = {
        unitId: unitId,
        mainTenantId: byiddata?.tenant?.[0]?._id,
        tenancy: {
          _id: byiddata?.tenancyId,
          isDraft: false,
          tenantRequestId: byiddata?._id,
          previousTenancyId: byiddata?.previousTenancyId,
        },
        paymentList: isFinance ? payment : [],
        deletePaymentList: discardedPayments,
      }
      ApiPost('corporate/tenancy/renewal_form/handler', body)
        .then((res) => {
          if (val) {
            const body = {
              approvalWorkflowId: approvalUsers[approvalUsers?.length - 1]?._id,
              comment: approvalComments,
            }
            ApiPost(`corporate/approval_workflow/tenancy/approve`, body)
              .then((res: any) => {
                setIsLoading(false)
                SuccessToast(res?.data?.message)
                setTimeout(() => {
                  if (comefrom == 'propertyProfile') {
                    navigate(`/overViewData/${unitId}/requests`, {
                      state: {
                        details: state,
                        id: unitId,
                      },
                    })
                  } else if (comefrom == 'edit-view') {
                    navigate(`/tenancy-view/2/${unitId}/${tenancyId}`)
                  } else {
                    navigate(`/tenancies`, {
                      state: {
                        from: 'requests',
                      },
                    })
                  }
                }, 200)
              })
              .catch((err: any) => {
                setIsLoading(false)
                ErrorToast(err?.message)
              })
          } else {
            setIsLoading(false)
            SuccessToast(res?.data?.message)
            setTimeout(() => {
              if (comefrom == 'propertyProfile') {
                navigate(`/overViewData/${unitId}/requests`, {
                  state: {
                    details: state,
                    id: unitId,
                  },
                })
              } else if (comefrom == 'edit-view') {
                navigate(`/tenancy-view/2/${unitId}/${tenancyId}`)
              } else {
                navigate(`/tenancies`, {
                  state: {
                    from: 'requests',
                  },
                })
              }
            }, 200)
          }
        })
        .catch((err) => {
          setIsLoading(false)
          ErrorToast(err.message)
        })
    }
  }

  const onSubmitTermination = (val: any) => {
    setIsLoading(true)
    if (
      tenancyData?.duration?.end_date == null ||
      tenancyData?.duration?.days <= tenancyData?.renewalDays
    ) {
      ErrorToast(
        'Some mandatory fields are not filled yet.. Please review Termination and submit again..'
      )
      setIsLoading(false)
    } else {
      const body = {
        unitId: unitId,
        mainTenantId: byiddata?.tenant?.[0]?._id,
        tenancy: {
          _id: byiddata?.tenancyId,
          isDraft: false,
          tenantRequestId: byiddata?._id,
        },
      }
      ApiPost('corporate/tenancy/terminate_form/handler', body)
        .then((res) => {
          localStorage.removeItem('isFormDirty')
          const body = {
            tenancyId: tenancyId,
          }

          ApiPost(`corporate/tenancy/save/session?isCloneRecord=true`, body)
            .then(() => {
              setIsLoading(false)
            })
            .catch((err: any) => {
              ErrorToast(err?.message)
              setIsLoading(false)
            })

          if (val) {
            const body1 = {
              approvalWorkflowId: approvalUsers[approvalUsers?.length - 1]?._id,
              comment: approvalComments,
            }
            ApiPost(`corporate/approval_workflow/tenancy/approve`, body1)
              .then((res: any) => {
                SuccessToast(res?.data?.message)

                setTimeout(() => {
                  if (comefrom == 'propertyProfile') {
                    navigate(`/overViewData/${unitId}/requests`, {
                      state: {
                        details: state,
                        id: unitId,
                      },
                    })
                  } else if (comefrom == 'edit-view') {
                    navigate(`/tenancy-view/2/${unitId}/${tenancyId}`)
                  } else {
                    navigate(`/tenancies`, {
                      state: {
                        from: 'requests',
                      },
                    })
                  }
                }, 200)
                setIsLoading(false)
              })
              .catch((err: any) => {
                setIsLoading(false)
              })
          } else {
            SuccessToast(res?.data?.message)
            setTimeout(() => {
              if (comefrom == 'propertyProfile') {
                navigate(`/overViewData/${unitId}/requests`, {
                  state: {
                    details: state,
                    id: unitId,
                  },
                })
              } else if (comefrom == 'edit-view') {
                navigate(`/tenancy-view/2/${unitId}/${tenancyId}`)
              } else {
                navigate(`/tenancies`, {
                  state: {
                    from: 'requests',
                  },
                })
              }
            }, 200)
            setIsLoading(false)
          }
          setIsLoading(false)
        })
        .catch((err) => {
          ErrorToast(err.message)
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    getViewMode()
    // getWorkFlowListByName()
  }, [approvalUsers, byiddata])

  // Financial Summary linking Errors
  useEffect(() => {
    let income: any = [...incomePaymentPayload]
    // console.log(income)
    let totalAmountFinanceRent = 0

    let rentError: any = false
    let securityError: any = false
    let otherError: any = false

    /*************************** Total Amount Error ****************/
    //  Rent Amount
    for (let i = 0; i < income?.length; i++) {
      for (let j = 0; j < income[i]?.new_payment_sub_item?.length; j++) {
        // console.log(income[i]?.new_payment_sub_item[j]?.name)
        if (income[i]?.new_payment_sub_item[j]?.name == 'Rent') {
          totalAmountFinanceRent =
            totalAmountFinanceRent +
            income[i]?.new_payment_sub_item[j]?.amount *
              income[i]?.new_payment_sub_item[j]?.quantity
          if (totalAmountFinanceRent != rentFin?.totalAmount) {
            rentError = true
            setRentAmountError(true)
          } else {
            rentError = false
            setRentAmountError(false)
          }
        }
      }
    }
    // console.log('totalAmountFinanceRent ' + totalAmountFinanceRent)

    //  Security Deposit Amount
    let totalAmountFinanceSecurity = 0
    for (let i = 0; i < income?.length; i++) {
      for (let j = 0; j < income[i]?.new_payment_sub_item?.length; j++) {
        if (income[i]?.new_payment_sub_item[j]?.name == 'Security Deposit') {
          totalAmountFinanceSecurity =
            totalAmountFinanceSecurity +
            income[i]?.new_payment_sub_item[j]?.amount *
              income[i]?.new_payment_sub_item[j]?.quantity
          if (totalAmountFinanceSecurity != securityFin?.totalAmount) {
            securityError = true
            setSecurityAmountError(true)
          } else {
            securityError = false
            setSecurityAmountError(false)
          }
        }
      }
    }
    // console.log('totalAmountFinanceSecurity ' + totalAmountFinanceSecurity)

    // Other Financials Amount
    let totalAmountFinanceOther = 0
    {
      otherFinancials?.length > 0 &&
        otherFinancials?.map((othFin: any, index: any) => {
          return (
            <>
              {incomePaymentPayload?.length > 0 &&
                incomePaymentPayload?.map((pm: any, index: any) => {
                  return (
                    <>
                      {pm?.new_payment_sub_item?.map((pmIt: any, ind: any) => {
                        if (pmIt?.name == othFin?.name) {
                          totalAmountFinanceOther =
                            totalAmountFinanceOther + pmIt?.amount * pmIt?.quantity
                          if (totalAmountFinanceOther != othFin?.totalAmount) {
                            setOtherItem({
                              name: othFin?.name,
                            })
                            otherError = true
                            setOtherAmountError(true)
                          } else {
                            otherError = false
                            setOtherAmountError(false)
                          }
                        }
                        return <></>
                      })}
                    </>
                  )
                })}
            </>
          )
        })
    }

    /************************* No of Payments  *********************/
    let rentCount: any = 0
    let securityCount: any = 0
    let otherCount: any = 0
    // let income: any = [...incomePaymentPayload]

    //  rent
    for (let i = 0; i < income?.length; i++) {
      for (let j = 0; j < income[i]?.new_payment_sub_item?.length; j++) {
        if (income[i]?.new_payment_sub_item[j]?.name == 'Rent') {
          rentCount = rentCount + 1
          j = income[i]?.new_payment_sub_item?.length
        }
      }
    }

    if (rentCount != rentFin?.noOfPayment) setRentNoOfPaymentsError(true)
    else setRentNoOfPaymentsError(false)

    // security deposits
    for (let i = 0; i < income?.length; i++) {
      for (let j = 0; j < income[i]?.new_payment_sub_item?.length; j++) {
        if (income[i]?.new_payment_sub_item[j]?.name == 'Security Deposit') {
          securityCount = securityCount + 1
          j = income[i]?.new_payment_sub_item?.length
        }
      }
    }

    if (securityCount != securityFin?.noOfPayment) setSecurityNoOfPaymentsError(true)
    else setSecurityNoOfPaymentsError(false)

    // Other Financials
    for (let i = 0; i < otherFinancials?.length; i++) {
      for (let j = 0; j < income?.length; j++) {
        for (let k = 0; k < income[j]?.new_payment_sub_item?.length; k++) {
          if (income[j]?.new_payment_sub_item[k]?.name == otherFinancials[i]?.name) {
            otherCount = otherCount + 1
            k = income[j]?.new_payment_sub_item?.length
          }
        }
      }
      if (otherCount != otherFinancials[i]?.noOfPayment) {
        setOtherNoOfPaymentsError(true)
        setOtherItem({
          name: otherFinancials[i]?.name,
        })
        i = otherFinancials?.length
      } else setOtherNoOfPaymentsError(false)
    }

    if (otherFinancials?.length == 0) {
      setOtherAmountError(false)
      setOtherNoOfPaymentsError(false)
    }
    // }
  }, [rentFin, securityFin, otherFinancials, incomePaymentPayload])

  const isViewOnly: any =
    // user?._id !== byiddata?.requestor?._id &&
    (byiddata?.subWorkFlowListValue?.isApprovalEnabled && approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id !== user?._id) || (!byiddata?.subWorkFlowListValue?.isApprovalEnabled && user?._id !== byiddata?.requestor?._id)
  // || (approvalUsers?.length !== 0 && approvalUsers?.length !== 1 && user?._id !== byiddata?.requestor?._id)

  const isFinanceSubmitted =
    approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance &&
    approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id === user?._id

  localStorage.setItem('isFinanceSubmitted',isFinanceSubmitted == undefined ? false : isFinanceSubmitted)

  const isInitiator =
    user?._id === byiddata?.requestor?._id &&
    user?._id !== approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id &&
    approvalUsers?.length > 1

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form>
          <div id='' className=' pt-0 mt-0 '>
            {' '}
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              <div className=' d-flex flex-stack pt-1 mt-0 px-0 mb-5'>
                <div className='d-flex w-100'>
                  <div className='d-flex align-items-center mb-1'>
                    <span
                      className='rounded-circle'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        if (comefrom == 'propertyProfile') {
                          navigate(`/overViewData/${unitId}/requests`, {
                            state: {
                              details: state,
                              id: unitId,
                            },
                          })
                        } else if (comefrom == 'edit-view') {
                          navigate(`/tenancy-view/2/${unitId}/${tenancyId}`)
                        } else {
                          navigate(`/tenancies`, {
                            state: {
                              from: 'requests',
                            },
                          })
                        }
                      }}
                    >
                      <img src={backArrow} height='14' width='14' />
                    </span>
                    <h2 className='m-0 head-text ms-2'>
                      Lease {type === '2' ? ' Renewal ' : ' Termination '} Request {showLock}
                    </h2>
                    {showLock && (
                      <span>
                        <OverlayTrigger
                          placement='right'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {' '}
                              Approval for this request has been disabled. To continue you can
                              cancel this request and create a new request.
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <img
                              src={lock}
                              height='18'
                              width='18'
                              className='cursor-pointer ms-2'
                            />
                          </span>
                        </OverlayTrigger>
                      </span>
                    )}
                  </div>
                  <div className='d-flex ms-auto'>
                    {byiddata?.status === 4 ||
                    byiddata?.status === 6 ||
                    byiddata?.status === 5 ||
                    byiddata?.status === 2 ? (
                      <></>
                    ) : (
                      <button
                        type='button'
                        className='btn btn-sm fw-bold  ms-auto red-hollow-btn status-w-140 px-2'
                        onClick={() => requeststatuschange(byiddata?._id, 6)}
                        disabled={isLoading || (isViewOnly && !showLock)}
                      >
                        <img src={redCross} height={16} width={16} className='me-3' /> Cancel
                        Request
                      </button>
                    )}

                    {!isViewOnly &&
                      user?._id !== byiddata?.requestor?._id &&
                      (byiddata?.status == 0 ||
                        byiddata?.status == 7 ||
                        byiddata?.status == 10) && (
                        <button
                          type='button'
                          className='btn btn-sm fw-bold ms-3 send-back-btn pe-2 ms-4'
                          onClick={(e) => {
                            sendBack()
                          }}
                          disabled={isLoading || isViewOnly}
                        >
                          <img
                            src={sendBackImg}
                            height={21}
                            width={21}
                            style={{marginRight: '8px'}}
                          />{' '}
                          {'  '}Send Back
                        </button>
                      )}

                    {leaseWithApproval &&
                    (byiddata?.status == 0 || byiddata?.status == 7 || byiddata?.status == 10) ? (
                      <>
                        <button
                          type='button'
                          className={
                            approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2 &&
                            approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id ==
                              user?._id
                              ? 'btn btn-sm fw-bold ms-3 green-hollow-btn'
                              : 'btn btn-sm fw-bold ms-3 green-submit-btn'
                          }
                          onClick={(e: any) => {
                            if (isAssign) {
                              reAssign()
                            } else {
                              approvalFlowSubmission(e)
                            }
                          }}
                          disabled={
                            isLoading || isViewOnly || selectedApprover == null
                            // (selectedApprover == null &&
                            //   user?._id === byiddata?.requestor?._id &&
                            //   !showLock)
                          }
                        >
                          <img
                            src={
                              approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2 &&
                              approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id ==
                                user?._id
                                ? greenSubmit
                                : submitImg
                            }
                            height={19}
                            width={19}
                            style={{marginRight: '7px'}}
                          />{' '}
                          Submit
                        </button>

                        {approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2 &&
                          approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id ==
                            user?._id && (
                            <button
                              type='button'
                              className='btn btn-sm fw-bold ms-3 green-submit-btn'
                              onClick={(e: any) => {
                                if (type === '2') onSubmitRenewal(true)
                                else onSubmitTermination(true)
                              }}
                              disabled={
                                isLoading || isViewOnly
                                // ||
                                // (selectedApprover == null &&
                                //   user?._id === byiddata?.requestor?._id &&
                                //   !showLock)
                              }
                            >
                              <img
                                src={submitImg}
                                height={19}
                                width={19}
                                style={{marginRight: '7px'}}
                              />{' '}
                              Create
                            </button>
                          )}
                      </>
                    ) : (
                      <>
                        {!showLock && (
                          <>
                            {type === '3' ? (
                              <>
                                {byiddata?.status === 4 ||
                                byiddata?.status === 6 ||
                                byiddata?.status === 5 ||
                                byiddata?.status === 2 ? (
                                  <></>
                                ) : (
                                  <div className='d-flex ms-auto'>
                                    {byiddata?.status === 10 ||
                                    byiddata?.status === 0 ||
                                    byiddata?.status === 7 ? (
                                      <>
                                        <button
                                          type='button'
                                          className={
                                            byiddata?.clone_tenancy?.length > 0
                                              ? 'btn btn-sm fw-bold term-btn mx-3 pe-0 ps-2 ten-renew-term  mb-1 me-0 status-w-180'
                                              : 'btn btn-sm fw-bold term-btn mx-3 pe-0 ps-2 ten-renew-term  mb-1 me-0 status-w-160 '
                                          }
                                          onClick={() => {
                                            if (byiddata?.clone_tenancy?.length > 0) {
                                              navigate(
                                                `/tenancy-termination/2/${byiddata?.unit?.[0]?._id}/${byiddata?.tenancyId}`,
                                                {
                                                  state: {
                                                    tenantReqId: byiddata?._id,
                                                    terminationDate: byiddata?.terminationDate,
                                                  },
                                                }
                                              )
                                            } else {
                                              addTerminateTenancy(
                                                byiddata?.unit?.[0]?._id,
                                                byiddata?.tenancyId,
                                                byiddata?._id,
                                                byiddata?.terminationDate
                                              )
                                            }

                                            let start = byiddata?.tenancy?.[0]?.duration?.start_date
                                            let end = byiddata?.tenancy?.[0]?.duration?.end_date

                                            let duration = {
                                              start: start,
                                              end: end,
                                            }

                                            localStorage.setItem(
                                              'OriginalTerm',
                                              JSON.stringify(duration)
                                            )
                                            localStorage.setItem('termTenantReqId', byiddata?._id)
                                          }}
                                          disabled={isLoading || isViewOnly || showLock}
                                        >
                                          <img
                                            src={terminate}
                                            height={18}
                                            width={18}
                                            className='cursor-pointer me-4'
                                          />
                                          {byiddata?.clone_tenancy?.length > 0
                                            ? 'Continue Termination'
                                            : 'Terminate Lease'}
                                        </button>
                                        <button
                                          type='button'
                                          className='btn btn-sm fw-bold ms-3 grey-submit-btn'
                                          onClick={(e: any) => {
                                            onSubmitTermination(false)
                                          }}
                                          disabled={isLoading || isViewOnly || showLock}
                                        >
                                          <img
                                            src={submitImg}
                                            height={19}
                                            width={19}
                                            style={{marginRight: '7px'}}
                                          />{' '}
                                          Submit
                                        </button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {byiddata?.status === 4 ||
                                byiddata?.status === 6 ||
                                byiddata?.status === 5 ||
                                byiddata?.status === 2 ? (
                                  <></>
                                ) : (
                                  <div className='d-flex  ms-auto'>
                                    {byiddata?.status === 10 ||
                                    byiddata?.status === 0 ||
                                    byiddata?.status === 7 ? (
                                      <>
                                        <button
                                          className={
                                            byiddata?.tenancyId != null
                                              ? 'btn btn-sm fw-bold renew-btn ten-renew-term mx-3 pe-0 ps-2 mb-1 me-0'
                                              : 'btn btn-sm fw-bold renew-btn ten-renew-term mx-3 pe-0 ps-2 mb-1 me-0 status-w-140 '
                                          }
                                          onClick={() => {
                                            localStorage.setItem('renewRequestId', byiddata?._id)
                                            if (byiddata?.tenancyId != null) {
                                              navigate(
                                                `/renew-tenancy/2/${byiddata?.unit?.[0]?._id}/${byiddata?.tenancyId}`,
                                                {
                                                  state: {
                                                    tenantReqId: byiddata?._id,
                                                  },
                                                }
                                              )
                                            } else {
                                              navigate(
                                                `/renew-tenancy/4/${byiddata?.unit?.[0]?._id}/${byiddata?.previousTenancyId}`,
                                                {
                                                  state: {
                                                    tenantReqId: byiddata?._id,
                                                  },
                                                }
                                              )
                                            }
                                          }}
                                          disabled={isLoading || isViewOnly || showLock}
                                        >
                                          <img
                                            src={sync}
                                            height={20}
                                            width={20}
                                            className='cursor-pointer me-4'
                                          />

                                          {byiddata?.tenancyId != null
                                            ? 'Continue Renewal'
                                            : 'Renew Lease'}
                                        </button>
                                        <button
                                          type='button'
                                          className='btn btn-sm fw-bold ms-3 grey-submit-btn'
                                          onClick={(e: any) => {
                                            onSubmitRenewal(false)
                                          }}
                                          disabled={isLoading || isViewOnly || showLock}
                                        >
                                          <img
                                            src={submitImg}
                                            height={19}
                                            width={19}
                                            style={{marginRight: '7px'}}
                                          />{' '}
                                          Submit
                                        </button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Approval Workflow Card*/}
            {(leaseWithApproval ||
              showLock ||
              byiddata?.subWorkFlowListValue?.isApprovalEnabled) && (
              <div className='card card-flush py-5 px-7 mb-7'>
                <div className='d-flex'>
                  <h2 className='top ps-0'>
                    <b>Approval Workflow</b>
                  </h2>
                  <p className='ms-auto head-text pe-0' style={{fontWeight: '600'}}>
                    Action Required{' '}
                    <span
                      className={
                        approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2
                          ? 'send-back-color ms-5'
                          : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 3
                          ? 'select-color ms-5'
                          : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 6
                          ? 'red-color ms-5'
                          : 'labl-gry ms-5'
                      }
                      style={{fontWeight: '700'}}
                    >
                      {approvalUsers?.length == 0
                        ? 'Pending Submission'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 0
                        ? 'Pending Submission'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 1
                        ? 'Submitted'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 2
                        ? 'Pending Approval'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 3
                        ? 'Approved'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 4
                        ? 'Sent Back'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 5
                        ? 'Reassigned'
                        : approvalUsers[approvalUsers?.length - 1]?.approvalStatus === 6
                        ? 'Locked'
                        : ''}
                    </span>
                  </p>
                </div>

                {hideApproval && (
                  <>
                    <>
                      {approvalUsers?.length > 0 ? (
                        <div className='table-responsive' style={{maxHeight: '400px'}}>
                          <table
                            className='table align-start fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <th className='text-start min-w-150px test'>User</th>

                              <th className='text-start min-w-150px'>Status</th>
                              <th className='text-start min-w-150px'>Comments</th>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {approvalUsers?.length > 0 &&
                                approvalUsers?.map((app: any, ind: any) => {
                                  return (
                                    <>
                                      <tr>
                                        <td className='text-start ps-0'>
                                          <div className='d-flex'>
                                            <div className='head-text'>
                                              <p
                                                className='user-head head-text pe-2 mb-0'
                                                style={{fontWeight: '600'}}
                                              >
                                                {app?.createdBy?.[0]?.firstName}{' '}
                                                {app?.createdBy?.[0]?.lastName}
                                              </p>
                                              <p className='labl-gry mb-2'>
                                                ({app?.createdBy?.[0]?.email})
                                              </p>
                                            </div>
                                            {/* {ind == approvalUsers?.length - 1 &&
                                              app?.createdBy?.[0]?._id !== user?._id &&
                                              user?._id === byiddata?.requestor?._id &&
                                              (byiddata?.status == 0 ||
                                                byiddata?.status == 7 ||
                                                byiddata?.status == 10) && ( */}
                                            {ind == approvalUsers?.length - 1 &&
                                              lastApproverId === user?._id && (
                                                <div className='ms-5'>
                                                  <img
                                                    src={swapImg}
                                                    height={20}
                                                    width={20}
                                                    className='cursor-pointer'
                                                    onClick={() => {
                                                      setIsAssign(true)
                                                      setAssignData(app)
                                                    }}
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        </td>

                                        <td className='text-start ps-0'>
                                          <div className='head-text'>
                                            <p
                                              className='user-head pe-2 mb-0 ps-1'
                                              style={{fontWeight: '600'}}
                                            >
                                              {app?.approvalStatus === 0
                                                ? 'Pending Submission'
                                                : app?.approvalStatus === 1
                                                ? 'Submitted'
                                                : app?.approvalStatus === 2
                                                ? 'Pending Approval'
                                                : app?.approvalStatus === 3
                                                ? 'Approved'
                                                : app?.approvalStatus === 4
                                                ? 'Sent Back'
                                                : app?.approvalStatus === 5
                                                ? 'Reassigned'
                                                : app?.approvalStatus === 6
                                                ? 'Locked'
                                                : ''}
                                            </p>
                                            <p className='mb-2 ps-1'>
                                              <i>
                                                {app?.approvalStatusAt
                                                  ? `${moment(app?.approvalStatusAt).format(
                                                      'DD.MM.YYYY - HH:mm A'
                                                    )} `
                                                  : ''}
                                              </i>
                                            </p>
                                          </div>
                                        </td>
                                        <td className='text-start ps-0 pe-3'>
                                          <textarea
                                            className='form-control form-control-solid mytest'
                                            placeholder='Enter comments...'
                                            name='description'
                                            value={app?.comment}
                                            onChange={(e: any) => {
                                              let values = [...approvalUsers]
                                              values[ind].comment = e.target.value
                                              setApprovalComments(e.target.value)
                                            }}
                                            onBlur={() => {}}
                                            disabled={
                                              app?.createdBy?.[0]?._id != user?._id ||
                                              app?.approvalStatus === 1 ||
                                              app?.approvalStatus === 5 ||
                                              showLock ||
                                              byiddata?.status === 1 ||
                                              byiddata?.status === 2 ||
                                              byiddata?.status === 4 ||
                                              byiddata?.status === 6 ||
                                              byiddata?.status === 5
                                              //  || (app?.createdBy?.[0]?._id == user?._id &&
                                              //     ind != app?.length - 1)
                                            }
                                          />{' '}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className='table-responsive' style={{maxHeight: '400px'}}>
                          <table
                            className='table align-start fs-6 gy-5 '
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <th className='text-start min-w-150px test'>User</th>
                              <th className='text-start min-w-150px'>Status</th>
                              <th className='text-start min-w-150px'>Comments</th>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              <tr>
                                <td className='text-start ps-0'>
                                  <div className='head-text'>
                                    <p
                                      className='user-head head-text pe-2 mb-0'
                                      style={{fontWeight: '600'}}
                                    >
                                      {byiddata?.requestor?.firstName}{' '}
                                      {byiddata?.requestor?.lastName}
                                    </p>
                                    <p className='labl-gry mb-2'>({byiddata?.requestor?.email})</p>
                                  </div>
                                </td>
                                <td className='text-start ps-0'>
                                  <div className='head-text'>
                                    <p
                                      className='user-head pe-2 mb-0 ps-1'
                                      style={{fontWeight: '600'}}
                                    >
                                      Pending Submission
                                    </p>
                                  </div>
                                </td>
                                <td className='text-start ps-0'>
                                  <textarea
                                    className='form-control form-control-solid mytest'
                                    placeholder='Enter comments...'
                                    name='description'
                                    value={approvalComments}
                                    onChange={(e: any) => {
                                      setApprovalComments(e.target.value)
                                    }}
                                    onBlur={() => {}}
                                    disabled={
                                      showLock ||
                                      byiddata?.status === 1 ||
                                      byiddata?.status === 2 ||
                                      byiddata?.status === 4 ||
                                      byiddata?.status === 6 ||
                                      byiddata?.status === 5 ||
                                      byiddata?.requestor?._id != user?._id
                                      //  || (app?.createdBy?.[0]?._id == user?._id &&
                                      //     ind != app?.length - 1)
                                    }
                                  />{' '}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>

                    {/* Reassign User */}
                    {isAssign && (
                      <div className='row tst'>
                        <div className='col-4 col-md-2'>
                          <Select
                            styles={customStylesApprove}
                            isSearchable={false}
                            options={
                              approvalUsers[approvalUsers?.length - 1]?.isApprovalRequest ||
                              approvalUsers[approvalUsers?.length - 1]?.isFinanceApprovalRequest
                                ? approvalDropdownUsers &&
                                  approvalDropdownUsers?.map((i: any, index: any) => {
                                    return {
                                      label: `${i?.firstName} ${i?.lastName}`,
                                      value: `${i?._id}`,
                                    }
                                  })
                                : approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
                                ? financeDropdownUsers &&
                                  financeDropdownUsers?.map((i: any, index: any) => {
                                    return {
                                      label: `${i?.firstName} ${i?.lastName}`,
                                      value: `${i?._id}`,
                                    }
                                  })
                                : leasingDropdownUsers &&
                                  leasingDropdownUsers?.map((i: any, index: any) => {
                                    return {
                                      label: `${i?.firstName} ${i?.lastName}`,
                                      value: `${i?._id}`,
                                    }
                                  })
                            }
                            onChange={(e: any) => {
                              setSelectedApprover(e.value)
                            }}
                            placeholder={
                              approvalUsers[approvalUsers?.length - 1]?.isApprovalRequest ||
                              approvalUsers[approvalUsers?.length - 1]?.isFinanceApprovalRequest
                                ? ' Reassign Approver'
                                : approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
                                ? 'Reassign Finance User'
                                : 'Reassign Leasing User'
                            }
                          />
                        </div>
                      </div>
                    )}

                    {/*Submit for Approval / Submit to Finance/Leasing  */}
                    {isViewOnly || isInitiator || isAssign ? (
                      <></>
                    ) : (
                      <div className='row mb-5 mt-3 tst'>
                        <div className='col-4 col-md-2'>
                          <button
                            type='button'
                            className='btn btn-sm fw-bold blue-btn status-w-200 me-4 mb-5'
                            onClick={() => {
                              setIsSubmitToApprovar(true)
                                    setShowApprovarDropdown(true)
                                    setIsSubmitToFinance(false)
                                    setShowFinanceDropdown(false)
                            }}
                            disabled={isSubmitToFinance || isViewOnly || isInitiator}
                          >
                            <img
                              src={approvedIcon}
                              height={17}
                              width={17}
                              style={{marginRight: '10px'}}
                            />{' '}
                            Submit for Approval
                          </button>
                          {showApprovarDropdown &&
                          <Select
                            styles={customStylesApprove}
                            isSearchable={false}
                            options={
                              approvalDropdownUsers &&
                              approvalDropdownUsers?.map((i: any, index: any) => {
                                return {
                                  label: `${i?.firstName} ${i?.lastName}`,
                                  value: `${i?._id}`,
                                }
                              })
                            }
                            onChange={(e: any) => {
                              setIsSubmitToApprovar(true)
                              setIsSubmitToFinance(false)
                              setSelectedApprover(e.value)
                            }}
                            placeholder={'Select Approver'}
                            isDisabled={isSubmitToFinance || isViewOnly || isInitiator}
                          />
}
                        </div>
                        {isFinance && (
                          <div className='col-4 col-md-2'>
                            <button
                              type='button'
                              className='btn btn-sm fw-bold blue-btn status-w-200 mb-5'
                              onClick={() => {
                                setIsSubmitToApprovar(false)
                                setShowApprovarDropdown(false)
                                setIsSubmitToFinance(true)
                                setShowFinanceDropdown(true)
                              }}
                              disabled={isSubmitToApprovar || isViewOnly || isInitiator}
                            >
                              <img
                                src={
                                  approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
                                    ? leasingImg
                                    : financialsIcon
                                }
                                height={
                                  approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
                                    ? 17
                                    : 20
                                }
                                width={
                                  approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
                                    ? 17
                                    : 19
                                }
                                style={{marginRight: '10px'}}
                              />{' '}
                              {approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
                                ? 'Submit to Leasing'
                                : 'Submit to Finance'}
                            </button>
                            {showFinanceDropdown && 
                            <Select
                              styles={customStylesApprove}
                              isSearchable={false}
                              options={
                                !approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
                                  ? financeDropdownUsers &&
                                    financeDropdownUsers?.map((i: any, index: any) => {
                                      return {
                                        label: `${i?.firstName} ${i?.lastName}`,
                                        value: `${i?._id}`,
                                      }
                                    })
                                  : leasingDropdownUsers &&
                                    leasingDropdownUsers?.map((i: any, index: any) => {
                                      return {
                                        label: `${i?.firstName} ${i?.lastName}`,
                                        value: `${i?._id}`,
                                      }
                                    })
                              }
                              onChange={(e: any) => {
                                setIsSubmitToFinance(true)
                                setIsSubmitToApprovar(false)
                                setSelectedApprover(e.value)
                              }}
                              placeholder={
                                approvalUsers[approvalUsers?.length - 1]?.isSubmitFinance
                                  ? 'Select Leasing User'
                                  : 'Select Finance User'
                              }
                              isDisabled={isSubmitToApprovar || isViewOnly || isInitiator}
                            />
}
                          </div>
                        )}
                      </div>
                    )}

                    {/* Select Approvar Dropdown */}
                    {/* {((user?._id === byiddata?.requestor?._id &&
                      (approvalUsers[approvalUsers?.length - 1]?.createdBy?.[0]?._id == user?._id ||
                        approvalUsers?.length == 0)) ||
                      isAssign) &&
                      !showLock &&
                      (byiddata?.status == 10 ||
                        byiddata?.status == 0 ||
                        byiddata?.status == 7) && (
                        <div className='row'>
                          <div className='col-md-3'>
                            <Select
                              styles={customStyles}
                              isSearchable={false}
                              options={
                                approvalDropdownUsers &&
                                approvalDropdownUsers?.map((i: any, index: any) => {
                                  return {
                                    label: `${i?.firstName} ${i?.lastName}`,
                                    value: `${i?._id}`,
                                  }
                                })
                              }
                              onChange={(e: any) => {
                                setSelectedApprover(e.value)
                              }}
                              placeholder={'Select Approver'}
                            />
                          </div>
                        </div>
                      )} */}
                  </>
                )}

                <div className='ms-auto mt-5'>
                  <div className='br-arr d-flex align-items-center justify-content-center'>
                    {hideApproval ? (
                      <img
                        src={upGreen}
                        height={20}
                        width={20}
                        className='cursor-pointer'
                        onClick={() => {
                          setHideApproval(false)
                        }}
                      />
                    ) : (
                      <img
                        src={downGreen}
                        height={20}
                        width={20}
                        className='cursor-pointer'
                        onClick={() => {
                          setHideApproval(true)
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
            {/* Renew/ Terminate Button */}
            {(leaseWithApproval || showLock) && (
              <div className='d-flex mt-0'>
                {type === '3' ? (
                  // <button
                  //   type='button'
                  //   className='btn btn-sm fw-bold term-btn me-0 mt-2 ps-2  pe-0 mt-4  ten-renew-term ms-auto'
                  //   style={{width: ''}}
                  //   onClick={() => {}}
                  // >
                  //   <img src={terminTen} height={18} width={18} className='cursor-pointer me-4' />
                  //   Terminate Lease
                  // </button>
                  <>
                    {byiddata?.status === 4 ||
                    byiddata?.status === 6 ||
                    byiddata?.status === 5 ||
                    byiddata?.status === 2 ? (
                      <></>
                    ) : (
                      <div className='d-flex ms-auto'>
                        {byiddata?.status === 10 ||
                        byiddata?.status === 0 ||
                        byiddata?.status === 7 ? (
                          <button
                            type='button'
                            className={
                              byiddata?.clone_tenancy?.length > 0
                                ? 'btn btn-sm fw-bold term-btn mx-3 pe-0 ps-2 ten-renew-term  mt-0 mb-1 me-0 status-w-180 mb-6'
                                : 'btn btn-sm fw-bold term-btn mx-3 pe-0 ps-2 ten-renew-term  mt-0 mb-1 me-0 status-w-160 mb-6'
                            }
                            disabled={showLock || isLoading || isViewOnly}
                            onClick={() => {
                              localStorage.setItem('isViewMode', isViewOnly)
                              if (byiddata?.clone_tenancy?.length > 0) {
                                navigate(
                                  `/tenancy-termination/2/${byiddata?.unit?.[0]?._id}/${byiddata?.tenancyId}`,
                                  {
                                    state: {
                                      tenantReqId: byiddata?._id,
                                      terminationDate: byiddata?.terminationDate,
                                    },
                                  }
                                )
                              } else {
                                addTerminateTenancy(
                                  byiddata?.unit?.[0]?._id,
                                  byiddata?.tenancyId,
                                  byiddata?._id,
                                  byiddata?.terminationDate
                                )
                              }

                              let start = byiddata?.tenancy?.[0]?.duration?.start_date
                              let end = byiddata?.tenancy?.[0]?.duration?.end_date

                              let duration = {
                                start: start,
                                end: end,
                              }

                              localStorage.setItem('OriginalTerm', JSON.stringify(duration))
                              localStorage.setItem('termTenantReqId', byiddata?._id)
                            }}
                          >
                            <img
                              src={terminate}
                              height={18}
                              width={18}
                              className='cursor-pointer me-4'
                            />
                            {user?._id !== byiddata?.requestor?._id ? (
                              <>{'Termination'}</>
                            ) : (
                              <>
                                {byiddata?.clone_tenancy?.length > 0
                                  ? 'Continue Termination'
                                  : 'Terminate Lease'}
                              </>
                            )}
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  // <button
                  //   type='button'
                  //   className='btn btn-sm fw-bold renew-btn me-0 mt-2 ps-2  pe-0 mt-4 ten-renew-term ms-auto'
                  //   onClick={() => {}}
                  // >
                  //   <img src={sync} height={20} width={20} className='cursor-pointer me-4' />
                  //   Renew Lease
                  // </button>
                  <>
                    {byiddata?.status === 4 ||
                    byiddata?.status === 6 ||
                    byiddata?.status === 5 ||
                    byiddata?.status === 2 ? (
                      <></>
                    ) : (
                      <div className='d-flex  ms-auto'>
                        {byiddata?.status === 10 ||
                        byiddata?.status === 0 ||
                        byiddata?.status === 7 ? (
                          <button
                            type='button'
                            className={
                              byiddata?.tenancyId != null
                                ? 'btn btn-sm fw-bold renew-btn ten-renew-term mx-3 pe-0 ps-2 mt-0 mb-1 me-0 mb-6'
                                : 'btn btn-sm fw-bold renew-btn ten-renew-term mx-3 pe-0 ps-2 mt-0 mb-1 me-0 status-w-140 mb-6'
                            }
                            disabled={showLock || isLoading || isViewOnly}
                            onClick={() => {
                              localStorage.setItem('renewRequestId', byiddata?._id)
                              localStorage.setItem('isViewMode', isViewOnly)
                              if (byiddata?.tenancyId != null) {
                                navigate(
                                  `/renew-tenancy/2/${byiddata?.unit?.[0]?._id}/${byiddata?.tenancyId}`,
                                  {
                                    state: {
                                      tenantReqId: byiddata?._id,
                                    },
                                  }
                                )
                              } else {
                                navigate(
                                  `/renew-tenancy/4/${byiddata?.unit?.[0]?._id}/${byiddata?.previousTenancyId}`,
                                  {
                                    state: {
                                      tenantReqId: byiddata?._id,
                                    },
                                  }
                                )
                              }
                            }}
                          >
                            <img
                              src={sync}
                              height={20}
                              width={20}
                              className='cursor-pointer me-4'
                            />
                            {user?._id !== byiddata?.requestor?._id ? (
                              <>{'Renewal Offer'}</>
                            ) : (
                              <>
                                {byiddata?.tenancyId != null ? 'Continue Renewal' : 'Renew Lease'}
                              </>
                            )}
                          </button>
                        ) : (
                          <></>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            {/* Request Summary */}
            <div className='grid-out p-5 pt-0 px-0'>
              <div className='card grid px-7 py-5 side'>
                <h2 className='top ps-0'>
                  <b>Request Summary</b>
                </h2>

                <div className='mt-4 mb-0'>
                  <h4 className='my-1 d-flex' style={{color: 'black'}}>
                    <label style={{minWidth: '100px'}} className='labl-gry'>
                      {' '}
                      Status{' '}
                    </label>
                    <div className='ms-5 ps-1 d-flex'>
                      <div
                        className={
                          byiddata?.status === 0
                            ? 'tenancy-stats create-st status-w-280'
                            : byiddata?.status === 1
                            ? 'tenancy-stats pending-approval-st status-w-280'
                            : byiddata?.status === 2
                            ? 'tenancy-stats tenant-approve-st  status-w-280'
                            : byiddata?.status === 4 ||
                              byiddata?.status === 6 ||
                              byiddata?.status === 5
                            ? 'tenancy-stats cancelled-st status-w-280'
                            : byiddata?.status === 10
                            ? 'tenancy-stats pend-st status-w-280'
                            : byiddata?.status === 7
                            ? 'tenancy-stats draft-st status-w-280'
                            : ''
                        }
                      >
                        {byiddata?.status === 0
                          ? 'Requested'
                          : byiddata?.status === 1
                          ? 'Submitted - Pending Tenant Approval'
                          : byiddata?.status === 2
                          ? 'Tenant Approved'
                          : byiddata?.status === 4 || byiddata?.status === 6
                          ? 'Cancelled'
                          : byiddata?.status === 5
                          ? 'Tenant Rejected'
                          : byiddata?.status === 10
                          ? 'Draft'
                          : byiddata?.status === 7
                          ? 'Under Approval'
                          : ''}
                      </div>
                    </div>
                  </h4>
                </div>

                <div className='mb-3'>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                  </label>
                  <span className='ms-5 ps-1'>
                    <i>
                      {byiddata?.updatedBy?.[0] &&
                        `${byiddata?.updatedBy?.[0]?.firstName} ${byiddata?.updatedBy?.[0]?.lastName} - `}

                      {byiddata?.statusDateTime
                        ? `${moment(byiddata?.statusDateTime).format('DD.MM.YYYY - HH:mm A')}`
                        : '-'}
                    </i>
                  </span>
                </div>

                <div className='mt-4 mb-2'>
                  <h4 className='my-1' style={{color: 'black'}}>
                    <label style={{minWidth: '100px'}} className='labl-gry'>
                      {' '}
                      Request ID
                    </label>
                    <span className='ms-5 ps-1'>
                      <b>{byiddata?.id}</b>
                    </span>
                  </h4>
                </div>

                <div className='mt-4 mb-2'>
                  <h4 className='my-1' style={{color: 'black'}}>
                    <label style={{minWidth: '100px'}} className='labl-gry'>
                      {' '}
                      Request Date
                    </label>
                    <span className='ms-6'>
                      <b>
                        {byiddata?.status !== 0 &&
                        byiddata?.status !== 7 &&
                        byiddata?.status !== 10 ? (
                          <>
                            {byiddata?.requestDate
                              ? moment(byiddata?.requestDate).format('DD.MM.YYYY')
                              : '-'}
                          </>
                        ) : (
                          <span className='ms-1'>{'-'}</span>
                        )}
                      </b>
                    </span>
                  </h4>
                </div>

                <div className='mt-4 mb-2'>
                  <h4 className='my-1' style={{color: 'black'}}>
                    <label style={{minWidth: '100px'}} className='labl-gry'>
                      {' '}
                      Requestor
                    </label>
                    <span className='ms-6'>
                      <b>
                        {byiddata?.status !== 0 &&
                        byiddata?.status !== 7 &&
                        byiddata?.status !== 10 ? (
                          <>
                            {byiddata?.requestor?.firstName || byiddata?.requestor?.lastName
                              ? byiddata?.requestor?.firstName + ' ' + byiddata?.requestor?.lastName
                              : '-'}
                          </>
                        ) : (
                          <span className='ms-1'>{'-'}</span>
                        )}
                      </b>
                    </span>
                  </h4>
                </div>

                {byiddata?.requestorType == 0 && type === '2' && (
                  <>
                    <div className='mt-4 mb-2'>
                      <h4 className='my-1' style={{color: 'black'}}>
                        <label style={{minWidth: '100px'}} className='labl-gry'>
                          {' '}
                          Term{' '}
                        </label>
                        <span className='ms-5 ps-2'>
                          <b>
                            {byiddata?.unit?.[0]?.tenancy?.[0]?.duration?.start_date
                              ? moment(
                                  byiddata?.unit?.[0]?.tenancy?.[0]?.duration?.start_date
                                ).format('DD.MM.YYYY')
                              : '-'}{' '}
                            {' - '}
                            {byiddata?.unit?.[0]?.tenancy?.[0]?.duration?.end_date
                              ? moment(
                                  byiddata?.unit?.[0]?.tenancy?.[0]?.duration?.end_date
                                ).format('DD.MM.YYYY')
                              : '-'}
                          </b>
                        </span>
                      </h4>
                    </div>

                    <div className='mt-4 mb-2'>
                      <h4 className='my-1' style={{color: 'black'}}>
                        <label style={{minWidth: '100px'}} className='labl-gry'>
                          {' '}
                          Duration
                        </label>
                        <span className='ms-5 ps-1'>
                          <b>
                            {' '}
                            {(new Date(byiddata?.endDate).getTime() -
                              new Date(byiddata?.startDate).getTime()) /
                              (1000 * 3600 * 24)}
                          </b>
                        </span>
                      </h4>
                    </div>
                  </>
                )}

                {byiddata?.requestorType == 0 && type === '3' && (
                  <>
                    <div className='mt-4 mb-2'>
                      <h4 className='my-1' style={{color: 'black'}}>
                        <label style={{minWidth: '100px'}} className='labl-gry'>
                          {' '}
                          Termination Date{' '}
                        </label>
                        <span className='ms-5 ps-2'>
                          <b>{moment(byiddata?.terminationDate).format('DD-MM-YYYY')}</b>
                        </span>
                      </h4>
                    </div>

                    <div className='mt-4 mb-2'>
                      <h4 className='my-1' style={{color: 'black'}}>
                        <label style={{minWidth: '100px'}} className='labl-gry'>
                          {' '}
                          Remaining Days
                        </label>
                        <span className='ms-5 ps-1'>
                          <b>
                            {' '}
                            {byiddata?.unit?.length > 0 &&
                            byiddata?.unit?.[0]?.tenancy?.[0]?.remainingDays > 0
                              ? byiddata?.unit?.[0]?.tenancy?.[0]?.remainingDays
                              : '0 '}{' '}
                            Days
                          </b>
                        </span>
                      </h4>
                    </div>
                  </>
                )}
              </div>
              <div className='card grid px-7 py-5 side'>
                <h2 className='top ps-0'>
                  <b>Lease Details</b>
                </h2>

                <div className='mt-4 mb-2'>
                  <h4 className='my-1' style={{color: 'black'}}>
                    <label style={{minWidth: '100px'}} className='labl-gry'>
                      {' '}
                      Contract No.
                    </label>
                    <span className='ms-5 ps-1'>
                      {type === '3' ? (
                        <b>
                          {' '}
                          {byiddata?.tenancy?.[0]?.contractNo
                            ? byiddata?.tenancy?.[0]?.contractNo
                            : 'N/A'}
                        </b>
                      ) : (
                        <b>
                          {' '}
                          {byiddata?.previous_tenancy?.[0]?.contractNo
                            ? byiddata?.previous_tenancy?.[0]?.contractNo
                            : 'N/A'}
                        </b>
                      )}
                    </span>
                  </h4>
                </div>

                <div className='mt-4 mb-2'>
                  <h4 className='my-1' style={{color: 'black'}}>
                    <label style={{minWidth: '100px'}} className='labl-gry'>
                      {' '}
                      Term
                    </label>
                    <span className='ms-5 ps-2'>
                      {type === '3' ? (
                        <b>
                          {byiddata?.tenancy?.[0]?.duration?.start_date
                            ? moment(byiddata?.tenancy?.[0]?.duration?.start_date).format(
                                'DD.MM.YYYY'
                              )
                            : '-'}{' '}
                          {' - '}
                          {byiddata?.tenancy?.[0]?.duration?.end_date
                            ? moment(byiddata?.tenancy?.[0]?.duration?.end_date).format(
                                'DD.MM.YYYY'
                              )
                            : '-'}
                        </b>
                      ) : (
                        <>
                          <b>
                            {byiddata?.previous_tenancy?.[0]?.duration?.start_date
                              ? moment(
                                  byiddata?.previous_tenancy?.[0]?.duration?.start_date
                                ).format('DD/MM/YYYY')
                              : '-'}{' '}
                            {' - '}
                            {byiddata?.previous_tenancy?.[0]?.duration?.end_date
                              ? moment(byiddata?.previous_tenancy?.[0]?.duration?.end_date).format(
                                  'DD/MM/YYYY'
                                )
                              : '-'}
                          </b>
                        </>
                      )}
                    </span>
                  </h4>
                </div>

                <div className='mt-4 mb-2 d-flex'>
                  <h4 className='my-1 d-flex align-items-center' style={{color: 'black'}}>
                    <label style={{minWidth: '100px'}} className='labl-gry'>
                      {' '}
                      Lease Status
                    </label>
                    <span className='ms-5 ps-1'>
                      {type === '3' ? (
                        <div
                          className={
                            byiddata?.tenancy?.[0]?.tenancyStatus == 0
                              ? 'tenancy-stats booked-bar me-5'
                              : byiddata?.tenancy?.[0]?.tenancyStatus == 1
                              ? 'tenancy-stats active-bar me-5'
                              : byiddata?.tenancy?.[0]?.tenancyStatus == 2
                              ? 'tenancy-stats expiring-bar me-5'
                              : byiddata?.tenancy?.[0]?.tenancyStatus == 3
                              ? 'tenancy-stats expired-bar me-5'
                              : 'tenancy-stats  no-st-bar me-5'
                          }
                        >
                          {byiddata?.tenancy?.[0]?.tenancyStatus == 0
                            ? 'Booked'
                            : byiddata?.tenancy?.[0]?.tenancyStatus == 1
                            ? 'Active'
                            : byiddata?.tenancy?.[0]?.tenancyStatus == 2
                            ? 'Expiring'
                            : byiddata?.tenancy?.[0]?.tenancyStatus == 3
                            ? 'Expired'
                            : '-'}
                        </div>
                      ) : (
                        <div
                          className={
                            byiddata?.previous_tenancy?.[0]?.tenancyStatus == 0
                              ? 'tenancy-stats booked-bar me-5'
                              : byiddata?.previous_tenancy?.[0]?.tenancyStatus == 1
                              ? 'tenancy-stats active-bar me-5'
                              : byiddata?.previous_tenancy?.[0]?.tenancyStatus == 2
                              ? 'tenancy-stats expiring-bar me-5'
                              : byiddata?.previous_tenancy?.[0]?.tenancyStatus == 3
                              ? 'tenancy-stats expired-bar me-5'
                              : 'tenancy-stats  no-st-bar me-5'
                          }
                        >
                          {byiddata?.previous_tenancy?.[0]?.tenancyStatus == 0
                            ? 'Booked'
                            : byiddata?.previous_tenancy?.[0]?.tenancyStatus == 1
                            ? 'Active'
                            : byiddata?.previous_tenancy?.[0]?.tenancyStatus == 2
                            ? 'Expiring'
                            : byiddata?.previous_tenancy?.[0]?.tenancyStatus == 3
                            ? 'Expired'
                            : '-'}
                        </div>
                      )}
                    </span>
                  </h4>
                </div>

                <div className='mt-4 mb-2'>
                  <h4 className='my-1' style={{color: 'black'}}>
                    <label style={{minWidth: '100px'}} className='labl-gry'>
                      {' '}
                      Tenant
                    </label>
                    <span className='ms-5 ps-1'>
                      <b>
                        {' '}
                        {byiddata?.tenant?.length > 0
                          ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                          : 'N/A'}
                      </b>
                    </span>
                  </h4>
                </div>

                <div className='mt-4 mb-2'>
                  <h4 className='my-1' style={{color: 'black'}}>
                    <label style={{minWidth: '100px'}} className='labl-gry'>
                      {' '}
                      Development
                    </label>
                    <span className='ms-5 ps-2'>
                      <b>
                        {byiddata?.unit?.length > 0 ? (
                          <>
                            {byiddata?.unit?.[0]?.community?.length > 0
                              ? byiddata?.unit?.[0]?.community[0]?.name
                              : byiddata?.unit?.[0]?.building[0]?.name}
                          </>
                        ) : (
                          'N/A'
                        )}
                      </b>
                    </span>
                  </h4>
                </div>

                <div className='mt-4 mb-2'>
                  <h4 className='my-1' style={{color: 'black'}}>
                    <label style={{minWidth: '100px'}} className='labl-gry'>
                      {' '}
                      Cluster
                    </label>
                    <span className='ms-5 ps-2'>
                      <b>
                        {byiddata?.unit?.length > 0 ? (
                          <>
                            {byiddata?.unit?.[0]?.cluster?.length > 0
                              ? byiddata?.unit?.[0]?.cluster[0]?.name
                              : '-'}
                          </>
                        ) : (
                          'N/A'
                        )}
                      </b>
                    </span>
                  </h4>
                </div>

                <div className='mt-4 mb-2'>
                  <h4 className='my-1' style={{color: 'black'}}>
                    <label style={{minWidth: '100px'}} className='labl-gry'>
                      {' '}
                      Group
                    </label>
                    <span className='ms-7'>
                      <b>
                        {byiddata?.unit?.length > 0 ? (
                          <>
                            {byiddata?.unit?.[0]?.unit_group?.length > 0
                              ? byiddata?.unit?.[0]?.unit_group[0]?.name
                              : '-'}
                          </>
                        ) : (
                          'N/A'
                        )}
                      </b>
                    </span>
                  </h4>
                </div>

                <div className='mt-4 mb-2'>
                  <h4 className='my-1' style={{color: 'black'}}>
                    <label style={{minWidth: '100px'}} className='labl-gry'>
                      {' '}
                      Unit
                    </label>
                    <span className='ms-5 ps-2'>
                      <b>{byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.unitNo : 'N/A'}</b>
                    </span>
                  </h4>
                </div>
              </div>
            </div>
            {type === '2' ? (
              <>
                {byiddata?.renewalOffer != undefined && (
                  <div className='p-5 pt-3 px-0'>
                    <div
                      className='card card-flush py-5 px-0  m-0'
                      // style={{boxShadow: 'lightgrey 0px 1px 4px 4px'}}
                    >
                      <div className='row mx-1'>
                        <div className='col-12 px-5'>
                          <div className='d-flex'>
                            <h2 className='top ps-2'>
                              <b>Renewal Offer</b>
                            </h2>
                            <div className=' d-flex ms-auto'>
                              {byiddata?.status === 2 && (
                                <a
                                  className='btn btn-sm fw-bold view-btn me-3 '
                                  onClick={() => {
                                    localStorage.setItem('tenancyFlow', 'renewalTicket')
                                    navigate(
                                      `/tenancy-view/2/${byiddata?.unit?.[0]?._id}/${byiddata?.tenancyId}`
                                    )
                                  }}
                                >
                                  <img
                                    src={allTen}
                                    height={20}
                                    width={20}
                                    style={{marginRight: '10px'}}
                                  />
                                  View Tenancy
                                </a>
                              )}
                              {/* <div className='me-5'>
                              <div
                                className={
                                  flg == '4'
                                    ? 'status  no-st-bar ms-5'
                                    : tenancyData?.tenancyStatus == 0
                                    ? 'status booked-bar ms-5'
                                    : tenancyData?.tenancyStatus == 1
                                    ? 'status active-bar ms-5'
                                    : tenancyData?.tenancyStatus == 2
                                    ? 'status expiring-bar ms-5'
                                    : tenancyData?.tenancyStatus == 3
                                    ? 'status expired-bar ms-5'
                                    : 'status  no-st-bar ms-5'
                                }
                              >
                                {flg == '4'
                                  ? '-'
                                  : tenancyData?.tenancyStatus == 0
                                  ? 'Booked'
                                  : tenancyData?.tenancyStatus == 1
                                  ? 'Active'
                                  : tenancyData?.tenancyStatus == 2
                                  ? 'Expiring'
                                  : tenancyData?.tenancyStatus == 3
                                  ? 'Expired'
                                  : '-'}
                              </div>
                              <i>
                                {' '}
                                {tenancyData?.tenancyStatusDateTime
                                  ? `${moment(tenancyData?.tenancyStatusDateTime).format(
                                      'DD.MM.YYYY - HH:mm A'
                                    )} `
                                  : ''}
                              </i>
                            </div> */}
                              {/* <div>
                        {tenancyData?.isDraft ? (
                          <>
                            <div
                              className={
                                tenancyData?.isDraft
                                  ? 'status draft-st ms-5'
                                  : 'status create-st ms-5'
                              }
                            >
                              {tenancyData?.isDraft ? 'Draft' : 'Created'}
                            </div>
                            <i>
                              {' '}
                              {tenancyData?.draftStatusDateTime
                                ? `${moment(tenancyData?.draftStatusDateTime).format(
                                    'DD.MM.YYYY - HH:mm A'
                                  )} `
                                : ''}
                            </i>
                          </>
                        ) : (
                          <div className='status not-saved'>-</div>
                        )}
                      </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Contract Details */}
                      <>
                        <div className='row mx-4 mt-5 '>
                          <div className='col-12 px-4 '>
                            <h2 className='top ps-0'>
                              <b>Contract Details</b>
                            </h2>
                          </div>

                          <div className='col-12 px-4 mt-4 mb-3'>
                            <h4 className='my-1' style={{color: 'black'}}>
                              <label style={{minWidth: '100px'}} className='labl-gry'>
                                {' '}
                                Contract No.{' '}
                              </label>
                              <span className='ms-5 ps-1'>
                                <b>{tenancyData?.contractNo ? tenancyData?.contractNo : ''}</b>
                              </span>
                            </h4>
                          </div>

                          <div className='col-12 px-4 mt-4 mb-3'>
                            <h4 className='my-1' style={{color: 'black'}}>
                              <label style={{minWidth: '100px'}} className='labl-gry'>
                                {' '}
                                Term{' '}
                              </label>
                              <span className='ms-5 ps-0'>
                                <b>
                                  {start_date != null || end_date != null ? (
                                    <>
                                      {' '}
                                      {start_date != null || end_date != null
                                        ? `${start_date} - ${end_date} `
                                        : '-'}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </b>
                              </span>
                            </h4>
                          </div>

                          <div className='col-12 px-4 mt-3 mb-3'>
                            <h4 className='my-1' style={{color: 'black'}}>
                              <label style={{minWidth: '100px'}} className='labl-gry'>
                                {' '}
                                Duration
                              </label>
                              <span className='ms-5 ps-1'>
                                <b>{totalDays}</b>
                              </span>
                            </h4>
                          </div>

                          <div className='col-12 px-4 my-3 mb-3'>
                            <h4 className='my-1' style={{color: 'black'}}>
                              <label style={{minWidth: '100px'}} className='labl-gry'>
                                {' '}
                                Remaining
                              </label>
                              <span className='ms-5 ps-1'>
                                <b>{remainingDays}</b>
                              </span>
                            </h4>
                          </div>

                          <div className='col-12 px-4 my-3 mb-3'>
                            <div className='d-flex align-items-center'>
                              <h4 className='my-1' style={{color: 'black'}}>
                                <label style={{minWidth: '100px'}} className='labl-gry'>
                                  {' '}
                                  Renewal{' '}
                                </label>
                              </h4>
                              <span className='ms-5 ps-1'>
                                <b>{tenancyData?.renewalDays ? tenancyData?.renewalDays : '-'}</b>
                              </span>

                              <span>
                                {' '}
                                <i className='mt-2 ms-2'> Days before term end</i>
                              </span>
                            </div>
                          </div>

                          <div className='col-12 ps-4 my-3 mb-3'>
                            <div className='d-flex'>
                              <div className='d-flex'>
                                <h4 className='my-1' style={{color: 'black'}}>
                                  <label style={{minWidth: '100px'}} className='labl-gry'>
                                    {' '}
                                    Benefits{' '}
                                  </label>
                                </h4>
                                <div className='test ms-5 ps-1'>
                                  {optionArry?.none && (
                                    <div className='d-flex align-items-center'>
                                      <p className='bullet-ic mb-0'></p>
                                      <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                        {' '}
                                        None
                                      </p>
                                    </div>
                                  )}

                                  {optionArry?.chillerFree === true && (
                                    <div className='d-flex align-items-center'>
                                      <p className='bullet-ic mb-0'></p>
                                      <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                        {' '}
                                        Chiller Free
                                      </p>
                                    </div>
                                  )}

                                  {optionArry?.maintenanceFree === true && (
                                    <div className='d-flex align-items-center'>
                                      <p className='bullet-ic mb-0'></p>
                                      <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                        {' '}
                                        Maintenance Free
                                      </p>
                                    </div>
                                  )}
                                  {otherOptions?.length > 0 &&
                                    otherOptions?.map((opt: any, ind: any) => (
                                      <div className='d-flex align-items-center'>
                                        <p className='bullet-ic mb-0'></p>
                                        <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                          {' '}
                                          {opt?.value}
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='row mx-1 mt-5'>
                            <div className='col-12 ps-0 mt-5'>
                              <div className='d-flex  align-items-center'>
                                <h3 className='ms-0' style={{fontWeight: '700'}}>
                                  Documents
                                </h3>
                              </div>
                            </div>

                            {contractDocuments?.map((doc: any, index: any) => {
                              return (
                                <>
                                  {doc.isCheck && (
                                    <>
                                      <div className='col-12 ps-0 pe-3 mt-5 pt-2'>
                                        <div className='d-flex  align-items-center'>
                                          <h4 className='' style={{fontWeight: '700'}}>
                                            {doc?.name}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className='col-12 px-2 mt-4'>
                                        {doc?.fileList?.length > 0 ? (
                                          <div
                                            className='tops d-flex gap-5 align-items-start mx-5'
                                            style={{overflow: 'scroll', overflowY: 'hidden'}}
                                          >
                                            {doc?.fileList?.map((file: any, ind: any) => {
                                              return (
                                                <>
                                                  {file.isChecked && (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='100'
                                                          height='100'
                                                          className='main_img mb-5'
                                                          onClick={() => {}}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            })}
                                          </div>
                                        ) : (
                                          <div className='d-flex justify-content-center'>
                                            {' '}
                                            <div className=''>
                                              {' '}
                                              {/* <h2 className='head-text'>No Files...</h2> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            })}
                            {contractDeletedDocuments?.map((doc: any, index: any) => {
                              return (
                                <>
                                  {doc.isCheck && (
                                    <>
                                      <div className='col-7 ps-0 pe-1 mt-4'>
                                        <div className='d-flex  align-items-center'>
                                          <h4 className='' style={{fontWeight: '700'}}>
                                            {doc?.name}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className='col-5 mt-4 ps-1'></div>
                                      <div className='col-12 px-0 mt-5 pt-4'>
                                        {doc?.fileList?.length > 0 ? (
                                          <div
                                            className='tops d-flex gap-5 align-items-start mx-5'
                                            style={{overflow: 'scroll', overflowY: 'hidden'}}
                                          >
                                            {doc?.fileList?.map((file: any, ind: any) => {
                                              return (
                                                <>
                                                  {file.isChecked && (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='100'
                                                          height='100'
                                                          className='main_img mb-5'
                                                          onClick={() => {}}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            })}
                                          </div>
                                        ) : (
                                          <div className='d-flex justify-content-center'>
                                            {' '}
                                            <div className=''>
                                              {' '}
                                              {/* <h2 className='head-text'>No Files...</h2> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            })}
                          </div>
                        </div>
                      </>

                      {/* Main Tenant Details */}
                      <>
                        <div className='row mx-1 mt-5 pt-3'>
                          <div className='col-7 ps-5'>
                            <h2 className='top ps-2'>
                              <b>Main Tenant Details</b>
                            </h2>
                          </div>

                          <div className='row mx-0 mt-3'>
                            <div className='col-12 px-4'>
                              <div className='d-flex'>
                                <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                  <label style={{width: '100px'}} className='labl-gry'>
                                    {' '}
                                    First Name{' '}
                                  </label>
                                  <span className='ms-5 ps-1'>
                                    <b>{formData?.firstName ? formData?.firstName : ''}</b>
                                  </span>
                                </h4>
                              </div>
                            </div>
                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  Last Name{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.lastName ? formData?.lastName : ''}</b>
                                </span>
                              </h4>
                            </div>
                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  Passport No.{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.passportNo ? formData?.passportNo : '-'}</b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  ID No.{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.idNo ? formData?.idNo : '-'}</b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  DOB{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>
                                    {formData?.DOB
                                      ? moment(formData?.DOB && formData?.DOB.split('T')[0]).format(
                                          'DD/MM/YYYY'
                                        )
                                      : ''}
                                  </b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  Nationality{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.nationality ? formData?.nationality : ''}</b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  Email{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.email ? formData?.email : ''}</b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  Mobile No.{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>
                                    {formData?.phoneNumber
                                      ? `${formData?.countryCode} ${formData?.phoneNumber}`
                                      : ''}
                                  </b>
                                </span>
                              </h4>
                            </div>
                          </div>

                          <div className='row mx-1 mt-5'>
                            <div className='col-7 mt-5'>
                              <div className='d-flex  align-items-center'>
                                <h3 className='ms-0' style={{fontWeight: '700'}}>
                                  Documents
                                </h3>
                              </div>
                            </div>

                            {tenantDocuments?.map((doc: any, index: any) => {
                              return (
                                <>
                                  {doc.isCheck && (
                                    <>
                                      <div className='col-7 ps-3 pe-3 mt-5 pt-3'>
                                        <div className='d-flex  align-items-center'>
                                          <h4 className='' style={{fontWeight: '700'}}>
                                            {doc?.name}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className='col-12 px-4 mt-5'>
                                        {doc?.fileList?.length > 0 ? (
                                          <div
                                            className='tops d-flex gap-5 align-items-start mx-5'
                                            style={{overflow: 'scroll', overflowY: 'hidden'}}
                                          >
                                            {doc?.fileList?.map((file: any, ind: any) => {
                                              return (
                                                <>
                                                  {file.isChecked && (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='100'
                                                          height='100'
                                                          className='main_img mb-5'
                                                          onClick={() => {}}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            })}
                                          </div>
                                        ) : (
                                          <div className='d-flex justify-content-center'>
                                            {' '}
                                            <div className=''>
                                              {' '}
                                              {/* <h2 className='head-text'>No Files...</h2> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            })}

                            {tenantDeletedDocuments?.map((doc: any, index: any) => {
                              return (
                                <>
                                  {doc.isCheck && (
                                    <>
                                      <div className='col-12 ps-4 pe-3 mt-4'>
                                        <div className='d-flex  align-items-center'>
                                          <h4 className='' style={{fontWeight: '700'}}>
                                            {doc?.name}
                                          </h4>
                                        </div>
                                      </div>

                                      <div className='col-12 px-4 mt-4'>
                                        {doc?.fileList?.length > 0 ? (
                                          <div
                                            className='tops d-flex gap-5 align-items-start mx-5'
                                            style={{overflow: 'scroll', overflowY: 'hidden'}}
                                          >
                                            {doc?.fileList?.map((file: any, ind: any) => {
                                              return (
                                                <>
                                                  {file.isChecked && (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='100'
                                                          height='100'
                                                          className='main_img mb-5'
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            })}
                                          </div>
                                        ) : (
                                          <div className='d-flex justify-content-center'>
                                            {' '}
                                            <div className=''>
                                              {' '}
                                              {/* <h2 className='head-text'>No Files...</h2> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            })}
                          </div>

                          {/* Sub Tenants */}
                          {subTenantData?.length > 0 && (
                            <div className='row mx-0 mt-5 pt-4'>
                              <div className='col-12 ps-4 pe-0 mt-3'>
                                <div className='d-flex  align-items-center'>
                                  <h2 className='ms-0' style={{fontWeight: '700'}}>
                                    Sub Tenants
                                  </h2>
                                </div>
                              </div>
                            </div>
                          )}

                          {subTenantData?.length > 0 && (
                            <div className='row mx-0'>
                              <div className='col-12 ps-5 pe-0 '>
                                <div className='table-responsive '>
                                  <table
                                    className='table align-middle  fs-6 gy-5'
                                    id='kt_ecommerce_sales_table'
                                  >
                                    <thead>
                                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className='text-start min-w-100px'>First Name</th>
                                        <th className='text-start min-w-100px'>Last Name</th>
                                        <th className='text-start min-w-100px'>Passport No.</th>
                                        <th className='text-start min-w-100px'>ID No.</th>
                                        <th className='text-start min-w-150px'>Email </th>
                                        <th className='text-start min-w-100px'>Mobile No.</th>
                                      </tr>
                                    </thead>

                                    <tbody className='fw-semibold text-gray-600'>
                                      {subTenantData?.length ? (
                                        subTenantData.map((sb: any, i: any) => {
                                          return (
                                            <>
                                              {sb?.id != '' && (
                                                <tr className='mt-2'>
                                                  <td className='text-start min-w-100px'>
                                                    {`${sb?.firstName}` ? `${sb?.firstName}` : '-'}
                                                  </td>
                                                  <td className='text-start min-w-100px'>
                                                    {`${sb?.lastName}` ? `${sb?.lastName}` : '-'}
                                                  </td>
                                                  <td className='text-start min-w-100px'>
                                                    {`${
                                                      sb?.passportNo != undefined &&
                                                      sb?.passportNo != null
                                                    }`
                                                      ? `${sb?.passportNo}`
                                                      : '-'}
                                                  </td>
                                                  <td className='text-start min-w-100px'>
                                                    {`${sb?.idNo != undefined && sb?.idNo != null}`
                                                      ? `${sb?.idNo}`
                                                      : '-'}
                                                  </td>
                                                  <td className='text-start min-w-150px'>
                                                    {`${sb?.email}` ? `${sb?.email}` : '-'}{' '}
                                                  </td>
                                                  <td className='text-start min-w-100px'>
                                                    {`${sb?.phoneNumber}}`
                                                      ? `${sb?.countryCode} ${sb?.phoneNumber}`
                                                      : '-'}
                                                  </td>
                                                </tr>
                                              )}
                                            </>
                                          )
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>

                      {/* Financials Details */}
                      <>
                        {incomePaymentPayload?.length > 0 && (
                          <div className='row mx-3 mt-5'>
                            <div className='col-7 px-5 mt-5 pt-5'>
                              <h2 className='top'>
                                <b>Financials</b>
                              </h2>
                            </div>
                          </div>
                        )}
                        {incomePaymentPayload?.length > 0 && (
                          <div className='row mx-4 mt-0'>
                            <div className='col-12 px-3'>
                              {incomePaymentPayload?.length > 0 ? (
                                <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0 test'>
                                  <table
                                    className='table align-start table-row-dashed fs-6 gy-5 '
                                    id='kt_ecommerce_sales_table'
                                  >
                                    <thead>
                                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className='min-w-100px'>Payment</th>
                                        <th className='min-w-100px'>Amount</th>
                                        <th className='min-w-100px'>Status</th>
                                        <th className='min-w-100px'>Due</th>
                                        <th className='min-w-100px'>Created At</th>
                                        <th className='min-w-150px'>Amount Paid</th>
                                        <th className='min-w-150px'>Date Paid</th>
                                        <th className='min-w-100px'></th>
                                      </tr>
                                    </thead>
                                    <tbody className='fw-semibold text-gray-600'>
                                      {incomePaymentPayload?.map((pm: any, ind: any) => {
                                        return (
                                          <>
                                            <tr
                                            // style={
                                            //   show[ind] == true
                                            //     ? {borderBottomStyle: 'none', height: '30px'}
                                            //     : show[ind] == false
                                            //     ? {borderBottomStyle: 'dashed', height: '30px'}
                                            //     : pm?.payment_sub_item?.length == 0
                                            //     ? {borderBottomStyle: 'dashed', height: '30px'}
                                            //     : {height: '30px'}
                                            // }
                                            >
                                              <td>
                                                <div className='d-flex align-items-center'>
                                                  {pm?.payment?.name}
                                                </div>
                                              </td>

                                              <td>
                                                {parseFloat(
                                                  pm?.payment?.totalAmount
                                                )?.toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                })}
                                              </td>

                                              <td>
                                                {pm?.payment?.paymentStatus === 0
                                                  ? 'Unpaid'
                                                  : pm?.payment?.paymentStatus === 1
                                                  ? 'Paid'
                                                  : pm?.payment?.paymentStatus === 2
                                                  ? 'Partially paid'
                                                  : pm?.payment?.paymentStatus === 3
                                                  ? 'Void'
                                                  : 'Draft'}
                                              </td>

                                              <td>
                                                {pm?.payment?.dueDate
                                                  ? moment(pm?.payment?.dueDate).format(
                                                      'DD.MM.YYYY - hh:mm A'
                                                    )
                                                  : '-'}
                                              </td>
                                              <td>
                                                {pm?.payment?.dueDate
                                                  ? moment(pm?.payment?.createdAt).format(
                                                      'DD.MM.YYYY - hh:mm A'
                                                    )
                                                  : '-'}
                                              </td>
                                              <td>
                                                {pm?.payment?.amountPaid?.toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                })}
                                              </td>
                                              <td>
                                                {pm?.payment?.new_payment_receipt?.[0]?.datePaid
                                                  ? moment(
                                                      pm?.payment?.new_payment_receipt?.[0]
                                                        ?.datePaid
                                                    ).format('DD.MM.YYYY - hh:mm A')
                                                  : '-'}
                                              </td>
                                              <td>
                                                {/* <BsArrowRightSquareFill
                                                color='#35475E '
                                                fontSize={18}
                                                style={{cursor: 'pointer'}}
                                                className='mx-3 me-4'
                                                onClick={() => {
                                                  let values = {
                                                    firstName: formData?.firstName,
                                                    lastName: formData?.lastName,
                                                    email: formData?.email,
                                                    countryCode: formData?.countryCode,
                                                    phoneNumber: formData?.phoneNumber,
                                                  }
                                                  setTenantDetails(values)

                                                  console.log(pm?.payment?._id)
                                                  if (pm?.payment?._id) {
                                                    ApiGet(`corporate/payment/${pm?.payment?._id}`)
                                                      .then(async (res) => {
                                                        setDataToEdit(res?.data?.data)
                                                        setIndexToEdit(ind)
                                                        setEditIncomeModalDraft(true)
                                                      })
                                                      .catch((err) => console.log('err', err))
                                                  } else {
                                                    console.log(pm)
                                                    setDataToEdit(pm)
                                                    setIndexToEdit(ind)
                                                    setEditIncomeModalDraft(true)
                                                  }
                                                }}
                                              /> */}
                                              </td>
                                            </tr>
                                          </>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                                  <table
                                    className='table align-start table-row-dashed fs-6 gy-5'
                                    id='kt_ecommerce_sales_table'
                                  >
                                    <thead>
                                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className='min-w-100px'>Payment</th>
                                        <th className='min-w-100px'>Amount</th>
                                        <th className='min-w-100px'>Status</th>
                                        <th className='min-w-100px'>Due</th>
                                        <th className='min-w-100px'>Created At</th>
                                        <th className='min-w-150px'>Amount Paid</th>
                                        <th className='min-w-150px'>Date Paid</th>
                                        <th className='min-w-100px'></th>
                                      </tr>
                                    </thead>
                                    <tbody className='fw-semibold text-gray-600'>
                                      <tr>
                                        <td colSpan={10} className='text-center'>
                                          <img src={noData} alt='' width={350} />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {byiddata?.terminationOffer != undefined && (
                  <div className='p-5 pt-3 px-0'>
                    <div
                      className='card card-flush py-5 px-0  m-0'
                      // style={{boxShadow: 'lightgrey 0px 1px 4px 4px'}}
                    >
                      <div className='row mx-1'>
                        <div className='col-12 px-5'>
                          <div className='d-flex'>
                            <h2 className='top ps-2'>
                              <b>
                                {!isOriginal ? 'Termination Details' : 'Original Tenancy Details'}
                              </b>
                            </h2>
                            <div className=' d-flex ms-auto'>
                              {byiddata?.status === 2 && (
                                <>
                                  {!isOriginal ? (
                                    <a
                                      className='btn btn-sm fw-bold view-btn me-3 '
                                      onClick={() => {
                                        setIsOriginal(true)
                                        getTerminationOffer(byiddata?.beforeTerminationTenancyData)
                                      }}
                                    >
                                      Original Tenancy
                                    </a>
                                  ) : (
                                    <a
                                      className='btn btn-sm fw-bold view-btn me-3 '
                                      onClick={() => {
                                        setIsOriginal(false)
                                        getTerminationOffer(byiddata?.terminationOffer)
                                      }}
                                    >
                                      Termination Details
                                    </a>
                                  )}
                                </>
                              )}

                              {/* <div className='me-5'>
                              <div
                                className={
                                  flg == '4'
                                    ? 'status  no-st-bar ms-5'
                                    : tenancyData?.tenancyStatus == 0
                                    ? 'status booked-bar ms-5'
                                    : tenancyData?.tenancyStatus == 1
                                    ? 'status active-bar ms-5'
                                    : tenancyData?.tenancyStatus == 2
                                    ? 'status expiring-bar ms-5'
                                    : tenancyData?.tenancyStatus == 3
                                    ? 'status expired-bar ms-5'
                                    : 'status  no-st-bar ms-5'
                                }
                              >
                                {flg == '4'
                                  ? '-'
                                  : tenancyData?.tenancyStatus == 0
                                  ? 'Booked'
                                  : tenancyData?.tenancyStatus == 1
                                  ? 'Active'
                                  : tenancyData?.tenancyStatus == 2
                                  ? 'Expiring'
                                  : tenancyData?.tenancyStatus == 3
                                  ? 'Expired'
                                  : '-'}
                              </div>
                              <i>
                                {' '}
                                {tenancyData?.tenancyStatusDateTime
                                  ? `${moment(tenancyData?.tenancyStatusDateTime).format(
                                      'DD.MM.YYYY - HH:mm A'
                                    )} `
                                  : ''}
                              </i>
                            </div> */}
                              {/* <div>
                        {tenancyData?.isDraft ? (
                          <>
                            <div
                              className={
                                tenancyData?.isDraft
                                  ? 'status draft-st ms-5'
                                  : 'status create-st ms-5'
                              }
                            >
                              {tenancyData?.isDraft ? 'Draft' : 'Created'}
                            </div>
                            <i>
                              {' '}
                              {tenancyData?.draftStatusDateTime
                                ? `${moment(tenancyData?.draftStatusDateTime).format(
                                    'DD.MM.YYYY - HH:mm A'
                                  )} `
                                : ''}
                            </i>
                          </>
                        ) : (
                          <div className='status not-saved'>-</div>
                        )}
                      </div> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Contract Details */}
                      <>
                        <div className='row mx-4 mt-5 '>
                          <div className='col-12 px-4 '>
                            <h2 className='top ps-0'>
                              <b>Contract Details</b>
                            </h2>
                          </div>

                          <div className='col-12 px-4 mt-4 mb-3'>
                            <h4 className='my-1' style={{color: 'black'}}>
                              <label style={{minWidth: '100px'}} className='labl-gry'>
                                {' '}
                                Contract No.{' '}
                              </label>
                              <span className='ms-5 ps-1'>
                                <b>{tenancyData?.contractNo ? tenancyData?.contractNo : ''}</b>
                              </span>
                            </h4>
                          </div>

                          <div className='col-12 px-4 mt-4 mb-3'>
                            <h4 className='my-1' style={{color: 'black'}}>
                              <label style={{minWidth: '100px'}} className='labl-gry'>
                                {' '}
                                Term{' '}
                              </label>
                              <span className='ms-5 ps-0'>
                                <b>
                                  {start_date != null || end_date != null ? (
                                    <>
                                      {' '}
                                      {start_date != null || end_date != null
                                        ? `${start_date} - ${end_date} `
                                        : '-'}
                                    </>
                                  ) : (
                                    ''
                                  )}
                                </b>
                              </span>
                            </h4>
                          </div>

                          <div className='col-12 px-4 mt-3 mb-3'>
                            <h4 className='my-1' style={{color: 'black'}}>
                              <label style={{minWidth: '100px'}} className='labl-gry'>
                                {' '}
                                Duration
                              </label>
                              <span className='ms-5 ps-1'>
                                <b>{totalDays}</b>
                              </span>
                            </h4>
                          </div>

                          <div className='col-12 px-4 my-3 mb-3'>
                            <h4 className='my-1' style={{color: 'black'}}>
                              <label style={{minWidth: '100px'}} className='labl-gry'>
                                {' '}
                                Remaining
                              </label>
                              <span className='ms-5 ps-1'>
                                <b>{remainingDays}</b>
                              </span>
                            </h4>
                          </div>

                          <div className='col-12 px-4 my-3 mb-3'>
                            <div className='d-flex align-items-center'>
                              <h4 className='my-1' style={{color: 'black'}}>
                                <label style={{minWidth: '100px'}} className='labl-gry'>
                                  {' '}
                                  Renewal{' '}
                                </label>
                              </h4>
                              <span className='ms-5 ps-1'>
                                <b>{tenancyData?.renewalDays ? tenancyData?.renewalDays : '-'}</b>
                              </span>

                              <span>
                                {' '}
                                <i className='mt-2 ms-2'> Days before term end</i>
                              </span>
                            </div>
                          </div>

                          <div className='col-12 ps-4 my-3 mb-3'>
                            <div className='d-flex'>
                              <div className='d-flex'>
                                <h4 className='my-1' style={{color: 'black'}}>
                                  <label style={{minWidth: '100px'}} className='labl-gry'>
                                    {' '}
                                    Benefits{' '}
                                  </label>
                                </h4>
                                <div className='test ms-5 ps-1'>
                                  {optionArry?.none && (
                                    <div className='d-flex align-items-center'>
                                      <p className='bullet-ic mb-0'></p>
                                      <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                        {' '}
                                        None
                                      </p>
                                    </div>
                                  )}

                                  {optionArry?.chillerFree === true && (
                                    <div className='d-flex align-items-center'>
                                      <p className='bullet-ic mb-0'></p>
                                      <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                        {' '}
                                        Chiller Free
                                      </p>
                                    </div>
                                  )}

                                  {optionArry?.maintenanceFree === true && (
                                    <div className='d-flex align-items-center'>
                                      <p className='bullet-ic mb-0'></p>
                                      <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                        {' '}
                                        Maintenance Free
                                      </p>
                                    </div>
                                  )}
                                  {otherOptions?.length > 0 &&
                                    otherOptions?.map((opt: any, ind: any) => (
                                      <div className='d-flex align-items-center'>
                                        <p className='bullet-ic mb-0'></p>
                                        <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                          {' '}
                                          {opt?.value}
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='row mx-1 mt-5'>
                            <div className='col-12 ps-0 mt-5'>
                              <div className='d-flex  align-items-center'>
                                <h3 className='ms-0' style={{fontWeight: '700'}}>
                                  Documents
                                </h3>
                              </div>
                            </div>

                            {contractDocuments?.map((doc: any, index: any) => {
                              return (
                                <>
                                  {doc.isCheck && (
                                    <>
                                      <div className='col-12 ps-0 pe-3 mt-5 pt-2'>
                                        <div className='d-flex  align-items-center'>
                                          <h4 className='' style={{fontWeight: '700'}}>
                                            {doc?.name}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className='col-12 px-2 mt-4'>
                                        {doc?.fileList?.length > 0 ? (
                                          <div
                                            className='tops d-flex gap-5 align-items-start mx-5'
                                            style={{overflow: 'scroll', overflowY: 'hidden'}}
                                          >
                                            {doc?.fileList?.map((file: any, ind: any) => {
                                              return (
                                                <>
                                                  {file.isChecked && (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='100'
                                                          height='100'
                                                          className='main_img mb-5'
                                                          onClick={() => {}}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            })}
                                          </div>
                                        ) : (
                                          <div className='d-flex justify-content-center'>
                                            {' '}
                                            <div className=''>
                                              {' '}
                                              {/* <h2 className='head-text'>No Files...</h2> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            })}
                            {contractDeletedDocuments?.map((doc: any, index: any) => {
                              return (
                                <>
                                  {doc.isCheck && (
                                    <>
                                      <div className='col-7 ps-0 pe-1 mt-4'>
                                        <div className='d-flex  align-items-center'>
                                          <h4 className='' style={{fontWeight: '700'}}>
                                            {doc?.name}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className='col-5 mt-4 ps-1'></div>
                                      <div className='col-12 px-0 mt-5 pt-4'>
                                        {doc?.fileList?.length > 0 ? (
                                          <div
                                            className='tops d-flex gap-5 align-items-start mx-5'
                                            style={{overflow: 'scroll', overflowY: 'hidden'}}
                                          >
                                            {doc?.fileList?.map((file: any, ind: any) => {
                                              return (
                                                <>
                                                  {file.isChecked && (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='100'
                                                          height='100'
                                                          className='main_img mb-5'
                                                          onClick={() => {}}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            })}
                                          </div>
                                        ) : (
                                          <div className='d-flex justify-content-center'>
                                            {' '}
                                            <div className=''>
                                              {' '}
                                              {/* <h2 className='head-text'>No Files...</h2> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            })}
                          </div>
                        </div>
                      </>

                      {/* Main Tenant Details */}
                      <>
                        <div className='row mx-1 mt-5 pt-3'>
                          <div className='col-7 ps-5'>
                            <h2 className='top ps-2'>
                              <b>Main Tenant Details</b>
                            </h2>
                          </div>

                          <div className='row mx-0 mt-3'>
                            <div className='col-12 px-4'>
                              <div className='d-flex'>
                                <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                  <label style={{width: '100px'}} className='labl-gry'>
                                    {' '}
                                    First Name{' '}
                                  </label>
                                  <span className='ms-5 ps-1'>
                                    <b>{formData?.firstName ? formData?.firstName : ''}</b>
                                  </span>
                                </h4>
                              </div>
                            </div>
                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  Last Name{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.lastName ? formData?.lastName : ''}</b>
                                </span>
                              </h4>
                            </div>
                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  Passport No.{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.passportNo ? formData?.passportNo : '-'}</b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  ID No.{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.idNo ? formData?.idNo : '-'}</b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  DOB{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>
                                    {formData?.DOB
                                      ? moment(formData?.DOB && formData?.DOB.split('T')[0]).format(
                                          'DD/MM/YYYY'
                                        )
                                      : ''}
                                  </b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  Nationality{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.nationality ? formData?.nationality : ''}</b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  Email{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.email ? formData?.email : ''}</b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-12 px-4'>
                              <h4 className='my-3 mb-3' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  Mobile No.{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>
                                    {formData?.phoneNumber
                                      ? `${formData?.countryCode} ${formData?.phoneNumber}`
                                      : ''}
                                  </b>
                                </span>
                              </h4>
                            </div>
                          </div>

                          <div className='row mx-1 mt-5'>
                            <div className='col-7 mt-5'>
                              <div className='d-flex  align-items-center'>
                                <h3 className='ms-0' style={{fontWeight: '700'}}>
                                  Documents
                                </h3>
                              </div>
                            </div>

                            {tenantDocuments?.map((doc: any, index: any) => {
                              return (
                                <>
                                  {doc.isCheck && (
                                    <>
                                      <div className='col-7 ps-3 pe-3 mt-5 pt-3'>
                                        <div className='d-flex  align-items-center'>
                                          <h4 className='' style={{fontWeight: '700'}}>
                                            {doc?.name}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className='col-12 px-4 mt-5'>
                                        {doc?.fileList?.length > 0 ? (
                                          <div
                                            className='tops d-flex gap-5 align-items-start mx-5'
                                            style={{overflow: 'scroll', overflowY: 'hidden'}}
                                          >
                                            {doc?.fileList?.map((file: any, ind: any) => {
                                              return (
                                                <>
                                                  {file.isChecked && (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='100'
                                                          height='100'
                                                          className='main_img mb-5'
                                                          onClick={() => {}}
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            })}
                                          </div>
                                        ) : (
                                          <div className='d-flex justify-content-center'>
                                            {' '}
                                            <div className=''>
                                              {' '}
                                              {/* <h2 className='head-text'>No Files...</h2> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            })}

                            {tenantDeletedDocuments?.map((doc: any, index: any) => {
                              return (
                                <>
                                  {doc.isCheck && (
                                    <>
                                      <div className='col-12 ps-4 pe-3 mt-4'>
                                        <div className='d-flex  align-items-center'>
                                          <h4 className='' style={{fontWeight: '700'}}>
                                            {doc?.name}
                                          </h4>
                                        </div>
                                      </div>

                                      <div className='col-12 px-4 mt-4'>
                                        {doc?.fileList?.length > 0 ? (
                                          <div
                                            className='tops d-flex gap-5 align-items-start mx-5'
                                            style={{overflow: 'scroll', overflowY: 'hidden'}}
                                          >
                                            {doc?.fileList?.map((file: any, ind: any) => {
                                              return (
                                                <>
                                                  {file.isChecked && (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='100'
                                                          height='100'
                                                          className='main_img mb-5'
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            })}
                                          </div>
                                        ) : (
                                          <div className='d-flex justify-content-center'>
                                            {' '}
                                            <div className=''>
                                              {' '}
                                              {/* <h2 className='head-text'>No Files...</h2> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            })}
                          </div>

                          {/* Sub Tenants */}
                          {subTenantData?.length > 0 && (
                            <div className='row mx-0 mt-5 pt-4'>
                              <div className='col-12 ps-4 pe-0 mt-3'>
                                <div className='d-flex  align-items-center'>
                                  <h2 className='ms-0' style={{fontWeight: '700'}}>
                                    Sub Tenants
                                  </h2>
                                </div>
                              </div>
                            </div>
                          )}

                          {subTenantData?.length > 0 && (
                            <div className='row mx-0'>
                              <div className='col-12 ps-5 pe-0 '>
                                <div className='table-responsive '>
                                  <table
                                    className='table align-middle  fs-6 gy-5'
                                    id='kt_ecommerce_sales_table'
                                  >
                                    <thead>
                                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className='text-start min-w-100px'>First Name</th>
                                        <th className='text-start min-w-100px'>Last Name</th>
                                        <th className='text-start min-w-100px'>Passport No.</th>
                                        <th className='text-start min-w-100px'>ID No.</th>
                                        <th className='text-start min-w-150px'>Email </th>
                                        <th className='text-start min-w-100px'>Mobile No.</th>
                                      </tr>
                                    </thead>

                                    <tbody className='fw-semibold text-gray-600'>
                                      {subTenantData?.length ? (
                                        subTenantData.map((sb: any, i: any) => {
                                          return (
                                            <>
                                              {sb?.id != '' && (
                                                <tr className='mt-2'>
                                                  <td className='text-start min-w-100px'>
                                                    {`${sb?.firstName}` ? `${sb?.firstName}` : '-'}
                                                  </td>
                                                  <td className='text-start min-w-100px'>
                                                    {`${sb?.lastName}` ? `${sb?.lastName}` : '-'}
                                                  </td>
                                                  <td className='text-start min-w-100px'>
                                                    {`${
                                                      sb?.passportNo != undefined &&
                                                      sb?.passportNo != null
                                                    }`
                                                      ? `${sb?.passportNo}`
                                                      : '-'}
                                                  </td>
                                                  <td className='text-start min-w-100px'>
                                                    {`${sb?.idNo != undefined && sb?.idNo != null}`
                                                      ? `${sb?.idNo}`
                                                      : '-'}
                                                  </td>
                                                  <td className='text-start min-w-150px'>
                                                    {`${sb?.email}` ? `${sb?.email}` : '-'}{' '}
                                                  </td>
                                                  <td className='text-start min-w-100px'>
                                                    {`${sb?.phoneNumber}}`
                                                      ? `${sb?.countryCode} ${sb?.phoneNumber}`
                                                      : '-'}
                                                  </td>
                                                </tr>
                                              )}
                                            </>
                                          )
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>

                      {/* Financials Details */}

                      <>
                        {incomePaymentPayload?.length > 0 && (
                          <div className='row mx-3 mt-5'>
                            <div className='col-7 px-5 mt-5 pt-5'>
                              <h2 className='top'>
                                <b>Financials</b>
                              </h2>
                            </div>
                          </div>
                        )}
                        {incomePaymentPayload?.length > 0 && (
                          <div className='row mx-4 mt-0'>
                            <div className='col-12 px-3'>
                              {incomePaymentPayload?.length > 0 ? (
                                <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0 test'>
                                  <table
                                    className='table align-start table-row-dashed fs-6 gy-5 '
                                    id='kt_ecommerce_sales_table'
                                  >
                                    <thead>
                                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className='min-w-100px'>Payment</th>
                                        <th className='min-w-100px'>Amount</th>
                                        <th className='min-w-100px'>Status</th>
                                        <th className='min-w-100px'>Due</th>
                                        <th className='min-w-100px'>Created At</th>
                                        <th className='min-w-150px'>Amount Paid</th>
                                        <th className='min-w-150px'>Date Paid</th>
                                        <th className='min-w-100px'></th>
                                      </tr>
                                    </thead>
                                    <tbody className='fw-semibold text-gray-600'>
                                      {incomePaymentPayload?.map((pm: any, ind: any) => {
                                        return (
                                          <>
                                            <tr
                                            // style={
                                            //   show[ind] == true
                                            //     ? {borderBottomStyle: 'none', height: '30px'}
                                            //     : show[ind] == false
                                            //     ? {borderBottomStyle: 'dashed', height: '30px'}
                                            //     : pm?.payment_sub_item?.length == 0
                                            //     ? {borderBottomStyle: 'dashed', height: '30px'}
                                            //     : {height: '30px'}
                                            // }
                                            >
                                              <td>
                                                <div className='d-flex align-items-center'>
                                                  {pm?.payment?.name}
                                                </div>
                                              </td>

                                              <td>
                                                {parseFloat(
                                                  pm?.payment?.totalAmount
                                                )?.toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                })}
                                              </td>

                                              <td>
                                                {pm?.payment?.paymentStatus === 0
                                                  ? 'Unpaid'
                                                  : pm?.payment?.paymentStatus === 1
                                                  ? 'Paid'
                                                  : pm?.payment?.paymentStatus === 2
                                                  ? 'Partially paid'
                                                  : pm?.payment?.paymentStatus === 3
                                                  ? 'Void'
                                                  : 'Draft'}
                                              </td>

                                              <td>
                                                {pm?.payment?.dueDate
                                                  ? moment(pm?.payment?.dueDate).format(
                                                      'DD.MM.YYYY - hh:mm A'
                                                    )
                                                  : '-'}
                                              </td>
                                              <td>
                                                {pm?.payment?.createdAt
                                                  ? moment(pm?.payment?.createdAt).format(
                                                      'DD.MM.YYYY - hh:mm A'
                                                    )
                                                  : '-'}
                                              </td>
                                              <td>
                                                {pm?.payment?.amountPaid?.toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                })}
                                              </td>
                                              <td>
                                                {pm?.payment?.datePaid
                                                  ? moment(pm?.payment?.datePaid).format(
                                                      'DD.MM.YYYY - hh:mm A'
                                                    )
                                                  : '-'}
                                              </td>
                                              <td>
                                                {/* <BsArrowRightSquareFill
                                                color='#35475E '
                                                fontSize={18}
                                                style={{cursor: 'pointer'}}
                                                className='mx-3 me-4'
                                                onClick={() => {
                                                  let values = {
                                                    firstName: formData?.firstName,
                                                    lastName: formData?.lastName,
                                                    email: formData?.email,
                                                    countryCode: formData?.countryCode,
                                                    phoneNumber: formData?.phoneNumber,
                                                  }
                                                  setTenantDetails(values)

                                                  console.log(pm?.payment?._id)
                                                  if (pm?.payment?._id) {
                                                    ApiGet(`corporate/payment/${pm?.payment?._id}`)
                                                      .then(async (res) => {
                                                        setDataToEdit(res?.data?.data)
                                                        setIndexToEdit(ind)
                                                        setEditIncomeModalDraft(true)
                                                      })
                                                      .catch((err) => console.log('err', err))
                                                  } else {
                                                    console.log(pm)
                                                    setDataToEdit(pm)
                                                    setIndexToEdit(ind)
                                                    setEditIncomeModalDraft(true)
                                                  }
                                                }}
                                              /> */}
                                              </td>
                                            </tr>
                                          </>
                                        )
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              ) : (
                                <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                                  <table
                                    className='table align-start table-row-dashed fs-6 gy-5'
                                    id='kt_ecommerce_sales_table'
                                  >
                                    <thead>
                                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className='min-w-100px'>Payment</th>
                                        <th className='min-w-100px'>Amount</th>
                                        <th className='min-w-100px'>Status</th>
                                        <th className='min-w-100px'>Due</th>
                                        <th className='min-w-100px'>Created At</th>
                                        <th className='min-w-150px'>Amount Paid</th>
                                        <th className='min-w-150px'>Date Paid</th>
                                        <th className='min-w-100px'></th>
                                      </tr>
                                    </thead>
                                    <tbody className='fw-semibold text-gray-600'>
                                      <tr>
                                        <td colSpan={10} className='text-center'>
                                          <img src={noData} alt='' width={350} />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </form>
      </div>
    </>
  )
}

export default RenewalTerminationRequest
