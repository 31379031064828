import React from 'react'
import {Tooltip, Box, Typography, styled} from '@mui/material'

// Styled Tooltip for Custom Design
const StyledTooltip = styled(({className, ...props}: any) => (
  <Tooltip
    {...props}
    classes={{popper: className}}
    PopperProps={{
      modifiers: [
        {
          name: 'flip',
          enabled: true,
          options: {
            fallbackPlacements: ['bottom', 'left', 'right'],
          },
        },
      ],
    }}
  />
))(() => ({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: '#fff',
    color: '#000',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    border: '1px solid #d1d5db',
    maxWidth: 320,
    fontSize: '14px',
    borderRadius: '8px',
    padding: '15px',
  },
  [`& .MuiTooltip-arrow`]: {
    color: '#fff',
  },
}))

interface CustomTooltipProps {
  title: React.ReactNode
  children: React.ReactNode
  placement?: 'top' | 'bottom' | 'left' | 'right'
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({title, children, placement = 'top'}) => {
  return (
    <StyledTooltip title={<Box>{title}</Box>} arrow placement={placement}>
      {children}
    </StyledTooltip>
  )
}

export default CustomTooltip
