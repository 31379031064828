import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  ApiDelete,
} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import starYellow from '../../../img/yellow-star.png'
import {Controller, useForm} from 'react-hook-form'
import swal from 'sweetalert2'
import moment from 'moment'
import PhoneInput from 'react-phone-input-2'
import backArrow from '../../../img/back-arrow.png'
import submitImg from '../../../img/Submit-White.png'
import trashImg from '../../../img/trash.png'
import saveGreenImg from '../../../img/save-dark-green.png'
import Select from 'react-select'
import addWhite from '../../../img/add-white.png'
import redCross from '../../../img/remove.png'
import homeImg from '../../../img/home.png'
import SelectPropertyModal from './SelectProperty'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import downGreen from '../../../img/down-arrow-green.png'
import propreImg from '../../../img/dev-clust-request-green.png'
import upGreen from '../../../img/up-arrow-green.png'
import selectListImg from '../../../img/select-list.png'
import SelectListModal from './SelectListModal'
import upload from '../../../img/upload-blue.png'
import pencil from '../../../img/edit-pen.png'
import crossBtn from '../../../img/cross-button.png'
import pdfDocument from '../../../img/pdf.svg'
import docCocument from '../../../img/doc.svg'
import fileIcon from '../../../img/file.svg'

import ModalLoader from '../General/ModalLoader'
import {capitalizeFirstLetter, getFileIcon} from '../../../Utilities/utils'
import {handleImageClick, useFancybox} from '../General/FilePreviewFunctions'

const services = [
  {
    name: 'AC Filter Change',
    units: 20,
    commonArea: '-',
    times: 'Once',
    frequency: 'Monthly',
    start: '12.01.2025',
    totalServices: 240,
    notStarted: 40,
    complete: 5,
    inProgress: 5,
    cancelled: '-',
  },
  {
    name: 'AC Gas Refill',
    units: 20,
    commonArea: '-',
    times: 'Once',
    frequency: 'Quarterly',
    start: '01.02.2025',
    totalServices: 80,
    notStarted: 35,
    complete: 35,
    inProgress: 10,
    cancelled: 11,
  },
  {
    name: 'Electric Box Check',
    units: 20,
    commonArea: '-',
    times: 'Once',
    frequency: 'Annually',
    start: 'Annually',
    totalServices: 20,
    notStarted: 15,
    complete: 15,
    inProgress: 8,
    cancelled: '-',
  },
  {
    name: 'Elevator Check',
    units: '-',
    commonArea: 4,
    times: 'Once',
    frequency: 'Quarterly',
    start: 'Quarterly',
    totalServices: 4,
    notStarted: 30,
    complete: 30,
    inProgress: 35,
    cancelled: 2,
  },
]

const ViewServiceContract = () => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const {flg, id} = useParams()

  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState<any>('Company')
  const [vendorData, setVendorData] = useState<any>({})

  const [isEdit, setIsEdit] = useState<any>(false)

  const [contactInformation, setContactInformation] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>(false)

  const [vendorTypeOptions, setVendorTypeOptions] = useState<any>([
    {value: 0, label: 'Service Provider'},
    {value: 1, label: 'Supplier'},
  ])

  const [autoSaveData, setAutoSaveData] = useState<any>(false)
  const [open, setOpen] = React.useState(false)

  const [country, setCountry] = useState<any>([])
  const [countryOptions, setCountryOptions] = useState<any>([])

  const [city, setCity] = useState<any>([])
  const [cityOptions, setCityOptions] = useState<any>([
    {
      label: 'Dubai',
      value: 'Dubai',
    },
    {
      label: 'Abu Dhabi',
      value: 'Abu Dhabi',
    },
    {
      label: 'Sharjah',
      value: 'Sharjah',
    },
    {
      label: 'Ajman',
      value: 'Ajman',
    },
    {
      label: 'Umm Al Quwain',
      value: 'Umm Al Quwain',
    },
    {
      label: 'Ras Al Khaimah',
      value: 'Ras Al Khaimah',
    },
    {
      label: 'Fujairah',
      value: 'Fujairah',
    },
  ])

  useEffect(() => {
    let values: any = []
    if (city == 'Dubai') {
      values = [
        {
          label: 'Al Jaddaf',
          value: 'Al Jaddaf',
        },
        {
          label: 'Barsha',
          value: 'Barsha',
        },
        {
          label: 'Culture Village',
          value: 'Culture Village',
        },
        {
          label: 'Jumeriah Lake Towers',
          value: 'Jumeriah Lake Towers',
        },
      ]
    } else if (city == 'Abu Dhabi') {
      values = [
        {
          label: 'Al Bateen',
          value: 'Al Bateen',
        },
        {
          label: 'Al Saadah',
          value: 'Al Saadah',
        },
        {
          label: 'Corniche',
          value: 'Corniche',
        },
        {
          label: 'Yas Island',
          value: 'Yas Island',
        },
      ]
    } else if (city == 'Sharjah') {
      values = [
        {
          label: 'Al Qasimia',
          value: 'Al Qasimia',
        },
        {
          label: 'Halwan',
          value: 'Halwan',
        },
        {
          label: 'Industrial Area',
          value: 'Industrial Area',
        },
        {
          label: 'Muwaileh',
          value: 'Muwaileh',
        },
      ]
    } else if (city == 'Ajman') {
      values = [
        {
          label: 'Al Bustan',
          value: 'Al Bustan',
        },
        {
          label: 'Al Hamidiya',
          value: 'Al Hamidiya',
        },
        {
          label: 'Al Jerf 1',
          value: 'Al Jerf 1',
        },
        {
          label: 'Al Jerf 2',
          value: 'Al Jerf 2',
        },
        {
          label: 'Al Rawda',
          value: 'Al Rawda',
        },
      ]
    } else if (city == 'Umm Al Quwain') {
      values = [
        {
          label: 'Al Butain',
          value: 'Al Butain',
        },
        {
          label: 'Al Neefah',
          value: 'Al Neefah',
        },
        {
          label: 'Al Raas',
          value: 'Al Raas',
        },
        {
          label: 'Al Raudah',
          value: 'Al Raudah',
        },
        {
          label: 'Al Salamah',
          value: 'Al Salamah',
        },
      ]
    } else if (city == 'Ras Al Khaimah') {
      values = [
        {
          label: 'Al Dhait South',
          value: 'Al Dhait South',
        },
        {
          label: 'Al Kharran',
          value: 'Al Kharran',
        },
        {
          label: 'Al Sewaan',
          value: 'Al Sewaan',
        },
        {
          label: 'Al Digdaga',
          value: 'Al Digdaga',
        },
        {
          label: 'Dafn Al Khor',
          value: 'Dafn Al Khor',
        },
      ]
    } else if (city == 'Fujairah') {
      values = [
        {
          label: 'Al Faseel',
          value: 'Al Faseel',
        },
        {
          label: 'Al Gurfah',
          value: 'Al Gurfah',
        },
        {
          label: 'Al Sharyah',
          value: 'Al Sharyah',
        },
        {
          label: 'Al Righailat',
          value: 'Al Righailat',
        },
        {
          label: 'Safad',
          value: 'Safad',
        },
      ]
    }
    setAreaOptions(values)
  }, [city])

  const [area, setArea] = useState<any>([])
  const [areaOptions, setAreaOptions] = useState<any>([])

  const [hide, setHide] = React.useState<any>(false)
  const [hideList, setHideList] = React.useState<any>(false)

  const [selectModal, setSelectModal] = useState<any>(false)
  const [selectListModal, setSelectListModal] = useState<any>(false)

  const [properties, setProperties] = useState<any>([])
  const [addedProperties, setAddedProperties] = useState<any>([])

  const [added, setAdded] = useState<any>(false)

  const [lists, setLists] = useState<any>([])

  let countryInd: any
  let cityInd: any
  let areaInd: any

  let propertiesSelected: any = 0
  let listsSelected: any = 0

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(res?.data?.data)
        let values: any = []
        res?.data?.data?.length > 0 &&
          res?.data?.data?.map((cn: any, ind: any) => {
            values[ind] = {
              label: `${cn?.country}`,
              value: `${cn?.country}`,
            }
          })

        setCountryOptions(values)
      })
      .catch((e: any) => {
        console.log('e', e)
      })
  }

  const handleAddContactInformation = () => {
    let values = [...contactInformation]
    values.push({
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
    })
    setContactInformation(values)
  }

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '50%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  useEffect(() => {
    getCountry()
  }, [])

  //
  const deleteVendor = () => {
    setIsLoading(true)
    let vId: any = []
    vId[0] = id

    const body = {
      vendorIds: vId,
    }

    ApiPost(`corporate/vendor/delete`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)
        navigate('/vendor-management')
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const handlePropertySelection = (data: any) => {
    console.log(data)

    let values: any = []
    let i: any = 0
    data?.map((dt: any, ind: any) => {
      if (dt?.check) {
        values[i] = {
          name: dt?.name,
          assignedProperties: {
            developmentId: dt?._id,
            buildingId: dt?.building?._id ? dt?.building?._id : undefined,
            clusterId: dt?.cluster?._id ? dt?.cluster?._id : undefined,
            unitGroupId: dt?.unitGroup?._id ? dt?.unitGroup?._id : undefined,
          },
          check: false,
          clusterName: dt?.cluster?.name,
          UnitGroupName: dt?.unitGroup?.name,
        }
        i++
      }
    })

    setProperties(values)

    let propertiesToSend: any = {
      developmentIds: [],
      buildingIds: [],
      unitGroupsIds: [],
      clusterIds: [],
    }

    values?.map((property: any) => {
      if (property?.assignedProperties?.developmentId != undefined)
        propertiesToSend.developmentIds[propertiesToSend.developmentIds?.length] =
          property?.assignedProperties?.developmentId

      if (property?.assignedProperties?.buildingId != undefined)
        propertiesToSend.buildingIds[propertiesToSend.buildingIds?.length] =
          property?.assignedProperties?.buildingId

      if (property?.assignedProperties?.clusterId != undefined)
        propertiesToSend.clusterIds[propertiesToSend.clusterIds?.length] =
          property?.assignedProperties?.clusterId

      if (property?.assignedProperties?.unitGroupId != undefined)
        propertiesToSend.unitGroupIds[propertiesToSend.unitGroupIds?.length] =
          property?.assignedProperties?.unitGroupId
    })

    console.log(propertiesToSend)

    setAddedProperties(propertiesToSend)
  }

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)
  const [attachments, setAttachments] = useState<any>([])

  const handleChange = async (e: any) => {
    e.preventDefault()

    const values = [...attachments]
    let newVal = values?.length - 1

    if (e.target.files && e.target.files[0]) {
      setShowLoader(true)
      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let x = values[values.length]?.src?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any = e.target.files[i]?.name

        await ApiPost(
          `upload/image/params?fileType=vendor_trade_license&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            newVal = newVal + 1
            values[newVal] = {
              src: fileURL,
              name: '',
              fileType: 'cheque',
              databaseId: data?._id,
              image: res?.data?.data?.image,
            }
            setUploadProgress(i + 1)
          })
          .catch((err) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }
      setAttachments(values)
      setShowLoader(false)
      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      e.target.value = null

      setTimeout(() => {
        setAutoSaveData(Date.now())
      }, 700)
    }
  }

  //
  const updateVendor = async (e: any, isSave: any) => {
    setOpen(true)
    if (e != '') e?.preventDefault()

    let attachedMedia: any = []
    attachments?.map((attach: any, ind: any) => {
      attachedMedia[ind] = attach?.image
    })

    let vdIds: any = []
    lists?.map((list: any, ind: any) => {
      vdIds[ind] = list?._id
    })

    let propertiesToSend: any = {
      developmentIds: [],
      buildingIds: [],
      unitGroupsIds: [],
      clusterIds: [],
    }

    properties?.map((property: any) => {
      if (property?.assignedProperties?.developmentId != undefined)
        propertiesToSend.developmentIds[propertiesToSend.developmentIds?.length] =
          property?.assignedProperties?.developmentId

      if (property?.assignedProperties?.buildingId != undefined)
        propertiesToSend.buildingIds[propertiesToSend.buildingIds?.length] =
          property?.assignedProperties?.buildingId

      if (property?.assignedProperties?.clusterId != undefined)
        propertiesToSend.clusterIds[propertiesToSend.clusterIds?.length] =
          property?.assignedProperties?.clusterId

      if (property?.assignedProperties?.unitGroupId != undefined)
        propertiesToSend.unitGroupIds[propertiesToSend.unitGroupIds?.length] =
          property?.assignedProperties?.unitGroupId
    })

    let newDev = propertiesToSend?.developmentIds?.filter((value: any, index: any, self: any) => {
      return self.indexOf(value) === index
    })

    let newClust = propertiesToSend?.clusterIds?.filter((value: any, index: any, self: any) => {
      return self.indexOf(value) === index
    })

    let newUnitGroup = propertiesToSend?.unitGroupsIds?.filter(
      (value: any, index: any, self: any) => {
        return self.indexOf(value) === index
      }
    )

    let newArr: any = {
      developmentIds: newDev,
      buildingIds: [],
      unitGroupsIds: newUnitGroup,
      clusterIds: newClust,
    }

    const body = {
      id: vendorData?._id,
      companyName: vendorData?.companyName,
      tradeLicenseNo: vendorData?.tradeLicenseNo,
      TRN: vendorData?.TRN,
      country: vendorData?.country,
      city: vendorData?.city,
      ratting: vendorData?.ratting,
      email: vendorData?.email,
      countryCode: vendorData?.countryCode,
      phoneNumber: vendorData?.phoneNumber,
      vendorType: vendorData?.vendorType,
      vendorListIds: vdIds,
      tradeLicenseImages: attachedMedia,
      contactInformation: contactInformation,
      area: vendorData?.area,
      address1: vendorData?.address1,
      address2: vendorData?.address2,
      landlineNumber: vendorData?.landlineNumber,
      assignPropertise: newArr,
      saveStatus: isSave ? 0 : 1,
    }

    await ApiPut(`corporate/vendor`, body)
      .then((res: any) => {
        setOpen(false)
        if (!isSave) {
          SuccessToast(res?.data?.message)
          getVendorById()
        }
      })
      .catch((err: any) => {
        setOpen(false)
        ErrorToast(err?.message)
      })
  }

  const getVendorById = () => {
    ApiGet(`corporate/vendor/${id}`)
      .then((res: any) => {
        setVendorData(res?.data?.data)

        setValue('companyName', res?.data?.data?.companyName)
        setValue('vendorType', res?.data?.data?.vendorType)
        setValue('tradeLicenseNo', res?.data?.data?.tradeLicenseNo)
        setValue('TRN', res?.data?.data?.TRN)
        setValue('country', res?.data?.data?.country)
        setValue('city', res?.data?.data?.city)
        setCity(res?.data?.data?.city)
        setValue('area', res?.data?.data?.area)
        setValue('address1', res?.data?.data?.address1)
        setValue('address2', res?.data?.data?.address2)
        setValue('email', res?.data?.data?.email)
        setValue('phoneNumber', res?.data?.data?.phoneNumber)
        setValue('landlineNumber', res?.data?.data?.landlineNumber)

        let values: any = []
        res?.data?.data?.tradeLicenseImages?.map((img: any, ind: any) => {
          values[ind] = {
            image: img,
          }
        })

        setAttachments(values)

        setAddedProperties(res?.data?.data?.assignPropertise)

        setAdded(true)

        let contact: any = []
        res?.data?.data?.contactInformation?.map((ct: any, ind: any) => {
          contact[ind] = {
            firstName: ct?.firstName,
            lastName: ct?.lastName,
            email: ct?.email,
            phoneNumber: ct?.phoneNumber,
          }
        })

        setContactInformation(contact)

        setValue('firstName', contact?.[0]?.firstName)
        setValue('lastName', contact?.[0]?.lastName)
        setValue('contactEmail', contact?.[0]?.email)
        setValue('contactPhone', contact?.[0]?.phoneNumber)

        let vendorValue: any = res?.data?.data?.vendorListIds

        let ind: any = 0

        ApiGet(`corporate/vendor_list?searchName=&sortType=A-Z`)
          .then((res: any) => {
            let values: any = []
            for (let i = 0; i < res?.data?.data?.length; i++) {
              for (let j = 0; j < vendorValue?.length; j++) {
                if (res?.data?.data[i]?._id == vendorValue[j]) {
                  values[ind] = {
                    name: res?.data?.data?.[i]?.name,
                    _id: res?.data?.data?.[i]?._id,
                    colorCode: res?.data?.data?.[i]?.colorCode,
                    check: false,
                  }
                  ind++
                }
              }
            }

            setLists(values)

            setIsEdit(false)
          })
          .catch(() => {})
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getVendorById()
  }, [])

  //
  const updatePropertyList = async () => {
    const body = {
      searchDevelopment: '',
      developmentIds: [],
      buildingIds: [],
      clusterIds: [],
      unitGroupIds: [],
    }
    await ApiPost(`corporate/vendor/get/development`, body)
      .then((res) => {
        let values = [...res?.data?.data]
        res?.data?.data?.map((res: any, ind: any) => {
          values[ind].check = false
        })

        for (let j = 0; j < res?.data?.data?.length; j++) {
          let isCheckForDev = false
          let isCheckForAll = false
          let isChckforDevClust = false
          let isCheckForDevUnitGrp = false

          if (
            values[j]?._id != undefined &&
            values[j]?.cluster?._id == undefined &&
            values[j]?.unitGroup?._id == undefined
          )
            isCheckForDev = true
          else if (
            values[j]?._id != undefined &&
            values[j]?.cluster?._id != undefined &&
            values[j]?.unitGroup?._id != undefined
          )
            isCheckForAll = true
          else if (
            values[j]?._id != undefined &&
            values[j]?.cluster?._id != undefined &&
            values[j]?.unitGroup?._id == undefined
          )
            isChckforDevClust = true
          else if (
            values[j]?._id != undefined &&
            values[j]?.cluster?._id == undefined &&
            values[j]?.unitGroup?._id != undefined
          )
            isCheckForDevUnitGrp = true

          // Dev only
          if (isCheckForDev) {
            let x = addedProperties?.developmentIds?.includes(values[j]?._id)

            if (x) values[j].check = true
          }
          // Dev, Clust and Unit Grouo
          else if (isCheckForAll) {
            let x = addedProperties?.developmentIds?.includes(values[j]?._id)

            let y = addedProperties?.clusterIds?.includes(values[j]?.cluster?._id)

            let z = addedProperties?.unitGroupIds?.includes(values[j]?.unitGroup?._id)

            if (x && y && z) values[j].check = true
          }
          // Dev and Clust
          else if (isChckforDevClust) {
            let x = addedProperties?.developmentIds?.includes(values[j]?._id)

            let y = addedProperties?.clusterIds?.includes(values[j]?.cluster?._id)

            if (x && y) values[j].check = true
          }

          // Dev and Unit Group
          else if (isCheckForDevUnitGrp) {
            let x = addedProperties?.developmentIds?.includes(values[j]?._id)

            let z = addedProperties?.unitGroupIds?.includes(values[j]?.unitGroup?._id)
            if (x && z) values[j].check = true
          }
        }

        // console.log(values)

        handlePropertySelection(values)
      })
      .catch((e) => {
        ErrorToast(e?.message)
      })
  }

  useEffect(() => {
    if (added) updatePropertyList()
  }, [added])

  useFancybox({
    dependencies: [], // Add any dependencies that should trigger a re-initialization
  })

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form onSubmit={handleSubmit((data: any, e: any) => updateVendor(e, false))}>
          <div id='' className='pt-0 mt-0'>
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              <div className=' d-flex flex-stack pt-1 mt-0 px-0'>
                {' '}
                {/* container-xxl */}
                <div className=''>
                  <div className='d-flex align-items-center mb-1'>
                    <span
                      className='rounded-circle cursor-pointer'
                      onClick={() => {
                        navigate('/vendor-management')
                      }}
                    >
                      <img src={backArrow} height={14} width={14} />
                    </span>
                    <h2 className='m-0 head-text ms-2'>
                      {!isEdit ? 'Service Contract' : 'Edit Vendor'}
                    </h2>
                  </div>
                </div>
                {/* <div className='d-flex align-items-center'>
                  {!isEdit ? (
                    <>
                      <img
                        src={pencil}
                        alt=''
                        height={19}
                        width={19}
                        className='ms-4 me-1 cursor-pointer'
                        onClick={() => {
                          setIsEdit(true)
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <>
                        <button
                          type='button'
                          className='btn btn-sm fw-bold  mx-3 red-hollow-btn'
                          onClick={() => {
                            setAdded(false)
                            getVendorById()
                            // setIsEdit(false)
                          }}
                          disabled={isLoading}
                        >
                          <img src={redCross} height={18} width={18} className='me-3' /> Cancel
                        </button>

                        <button
                          type='submit'
                          className='btn btn-sm fw-bold mx-3 green-hollow-btn'
                          disabled={isLoading}
                        >
                          <img src={saveGreenImg} height={18} width={18} className='me-3' /> {'  '}
                          Save
                        </button>
                      </>
                    </>
                  )}
                </div> */}
              </div>
            </div>

            <div className='card card-flush py-5 mt-5'>
              <div className='row mx-1'>
                {/* Left column - Tabs */}
                <div className='col-7 px-3'>
                  {/* Left Section */}
                  <>
                    <div className='row mx-4 mt-4'>
                      <h2 className='top ps-0 white-dark-theme-color'>
                        <b>Contract Details</b>
                      </h2>
                      <div className='col-12 px-5  ps-0 my-5 mt-6'>
                        <div className='d-flex'>
                          <h4 className='my-5 d-flex align-items-center' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              Contract No.
                            </label>
                            {!isEdit && (
                              <>
                                <span className='ms-1 white-dark-theme-color me-3'>
                                  <b>981738212</b>
                                </span>
                                {attachments?.map((file: any, ind: any) => {
                                  if (ind === 3) {
                                    file.URL = file?.image
                                    return (
                                      <>
                                        <img
                                          src={getFileIcon(file?.URL)}
                                          width='30'
                                          height='30'
                                          className='main_img cursor-pointer'
                                          // data-fancybox
                                          id={`clickedAttachments${ind}`}
                                          key={`clickedAttachments${ind}`}
                                          onClick={(e: any) => {
                                            handleImageClick(
                                              attachments?.map((tempFile: any) => ({
                                                URL: tempFile?.image,
                                                ...tempFile,
                                              })),
                                              'group_attachments_others',
                                              'clickedAttachments'
                                            )
                                          }}
                                          data-src={`${Bucket}${file?.URL}`}
                                        />
                                      </>
                                    )
                                  }
                                  return null
                                })}
                              </>
                            )}
                          </h4>
                        </div>
                        <div className='d-flex '>
                          <h4 className='my-5' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              Contract Type
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>AMC - Annual Maintenance Contract</b>
                              </span>
                            )}
                          </h4>
                        </div>
                        <div className='d-flex '>
                          <h4 className='my-5' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              Contract Value
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>AED 550,000 </b>
                              </span>
                            )}
                          </h4>
                        </div>
                        <div className='d-flex '>
                          <h4 className='my-5' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              Vendor Name
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>Handy Tech LLC.</b>
                              </span>
                            )}
                          </h4>
                        </div>
                        <div className='d-flex '>
                          <h4 className='my-5' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              Main Contact
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>Mr. Reza Malik</b>
                              </span>
                            )}
                          </h4>
                        </div>
                        <div className='d-flex '>
                          <h4 className='my-5' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            ></label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>reza.mail@email.com</b>
                              </span>
                            )}
                          </h4>
                        </div>
                        <div className='d-flex '>
                          <h4 className='my-5' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            ></label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>+971 0000000</b>
                              </span>
                            )}
                          </h4>
                        </div>
                        <div className='d-flex '>
                          <h4 className='my-5' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              Contract Start
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>01.01.2025</b>
                              </span>
                            )}
                          </h4>
                        </div>
                        <div className='d-flex '>
                          <h4 className='my-5' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              Contract End
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>31.12.2025</b>
                              </span>
                            )}
                          </h4>
                        </div>
                        <div className='d-flex '>
                          <h4 className='my-5' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              Expiring In
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>330</b> Days
                              </span>
                            )}
                          </h4>
                        </div>
                        <div className='d-flex '>
                          <h4 className='my-5 d-flex' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              Status
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <div className='status active-bar'>On Going</div>
                              </span>
                            )}
                          </h4>
                        </div>
                        <div className='d-flex '>
                          <h4 className='my-5 d-flex' style={{color: 'black'}}>
                            <label
                              style={{width: '130px'}}
                              className={`labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                            >
                              Job Rating
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <div className='d-flex align-items-center '>
                                  <img src={starYellow} height={18} width={18} className='me-1' />-
                                </div>
                              </span>
                            )}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </>
                </div>

                {/* Right Column */}

                <div className='col-5 px-5 d-flex flex-column justify-content-between'>
                  {/* Assign Prperties Modal */}
                  <div
                    className={
                      hide
                        ? 'card card-flush py-5 min-property mt-2 min-res pe-5 mb-5 px-3'
                        : 'card card-flush py-5 mt-2 pe-5 mb-5 px-3'
                    }
                    style={{position: 'relative', zIndex: '10'}}
                  >
                    <div className='d-flex justify-content-between'>
                      <div>
                        <div className='d-flex  align-items-center'>
                          <h2 className='ms-3 white-dark-theme-color fnt-700'>
                            {!isEdit ? 'Property' : 'Assign Properties'}
                          </h2>
                        </div>

                        <div className='d-flex '>
                          <h4 className='my-5' style={{color: 'black'}}>
                            <label
                              style={{width: '100px'}}
                              className={`ms-3 labl-gry light-dark-theme-color ${
                                isEdit && 'required'
                              }`}
                            >
                              Development
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>Prop Heights</b>
                              </span>
                            )}
                          </h4>
                        </div>
                        <div className='d-flex '>
                          <h4 className='my-5' style={{color: 'black'}}>
                            <label
                              style={{width: '100px'}}
                              className={`ms-3 labl-gry light-dark-theme-color ${
                                isEdit && 'required'
                              }`}
                            >
                              Units
                            </label>
                            {!isEdit && (
                              <span className='ms-1 white-dark-theme-color'>
                                <b>20 Units</b>
                              </span>
                            )}
                          </h4>
                        </div>
                      </div>
                      <div>
                        <img src={propreImg} width='100' height='100' />
                      </div>
                    </div>
                  </div>

                  {/* Lists */}
                  <div
                    className={
                      hideList
                        ? 'card card-flush py-5 min-property mt-2 min-res pe-5 mt-10 px-3'
                        : 'card card-flush py-5 mt-2 pe-5 mt-10 px-3'
                    }
                    style={{position: 'relative', zIndex: '10'}}
                  >
                    <div className='d-flex align-items-center'>
                      <h2 className='ms-3 white-dark-theme-color fnt-700'>Service Summary</h2>
                    </div>
                    <div className='d-flex '>
                      <h4 className='my-5' style={{color: 'black'}}>
                        <label
                          style={{width: '100px'}}
                          className={`ms-3 labl-gry light-dark-theme-color ${isEdit && 'required'}`}
                        >
                          Total Services
                        </label>
                        {!isEdit && (
                          <span className='ms-1 white-dark-theme-color'>
                            <b>344</b> Services
                          </span>
                        )}
                      </h4>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <div
                        className='ms-3 my-5 d-flex flex-column justify-content-center'
                        style={{color: 'black'}}
                      >
                        <div
                          className={`my-3 text-center labl-gry light-dark-theme-color ${
                            isEdit && 'required'
                          }`}
                        >
                          Not Started
                        </div>
                        <div className='status not-started  mx-auto status-w-75 text-dark  '>
                          <div className='text-dark'>40</div>
                        </div>
                      </div>
                      <div
                        className='ms-3 my-5 d-flex flex-column justify-content-center'
                        style={{color: 'black'}}
                      >
                        <div
                          className={`my-3 text-center labl-gry light-dark-theme-color ${
                            isEdit && 'required'
                          }`}
                        >
                          Complete
                        </div>
                        <div className='status renewed-st  mx-auto status-w-75 text-dark  '>
                          <div className='text-dark'> 5</div>
                        </div>
                      </div>
                      <div
                        className='ms-3 my-5 d-flex flex-column justify-content-center'
                        style={{color: 'black'}}
                      >
                        <div
                          className={`my-3 labl-gry light-dark-theme-color text-center  ${
                            isEdit && 'required'
                          }`}
                        >
                          In-Progress
                        </div>
                        <div className='status requested-st  mx-auto status-w-75 text-dark  '>
                          <div className='text-dark'> 5</div>
                        </div>
                      </div>
                      <div
                        className='ms-3 my-5 d-flex flex-column justify-content-center'
                        style={{color: 'black'}}
                      >
                        <div
                          className={`my-3 text-center labl-gry light-dark-theme-color ${
                            isEdit && 'required'
                          }`}
                        >
                          Cancelled
                        </div>
                        <div className='status cancelled-st  mx-auto status-w-75 text-dark  '>
                          <div className='text-dark'>-</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mx-2'>
                {(contactInformation?.length > 0 || isEdit) && (
                  <div className='card card-flush py-5 mt-2 min-card pe-5 mt-10 px-3'>
                    <div className='d-flex mx-3 mt-10 align-items-center'>
                      <h2 className='top ps-0 white-dark-theme-color'>
                        <b>Service Details</b>
                      </h2>
                    </div>
                    <table className='table mx-3 align-start table-row-dashed gy-6'>
                      <thead className='fs-7'>
                        <tr>
                          <th className='text-start min-w-200px'>Service Name</th>
                          <th className='text-center min-w-100px'>Units</th>
                          <th className='text-center min-w-100px'>Common Area</th>
                          <th className='text-center min-w-100px'>Times</th>
                          <th className='text-center min-w-100px'>Frequency</th>
                          <th className='text-center min-w-100px'>Start</th>
                          <th className='text-center min-w-100px'>Total Services</th>
                          <th className='text-center min-w-100px'>Not Started</th>
                          <th className='text-center min-w-100px'>Complete</th>
                          <th className='text-center min-w-100px'>In-Progress</th>
                          <th className='text-center min-w-100px'>Cancelled</th>
                        </tr>
                      </thead>
                      <tbody className='fw-semibold head-text'>
                        {services.map((service, index) => (
                          <tr key={index}>
                            <td className='text-start'>{service.name}</td>
                            <td className='text-center'>{service.units || '-'}</td>
                            <td className='text-center'>{service.commonArea || '-'}</td>
                            <td className='text-center'>{service.times}</td>
                            <td className='text-center'>{service.frequency}</td>
                            <td className='text-center'>{service.start}</td>
                            <td className='text-center fw-bolder'>{service.totalServices}</td>
                            <td className='text-center'>
                              <div className='status not-started mx-auto status-w-75'>
                                {service.notStarted}
                              </div>
                            </td>
                            <td className='text-center'>
                              <div className='status renewed-st mx-auto status-w-75 text-dark'>
                                {service.complete}
                              </div>
                            </td>
                            <td className='text-center'>
                              <div className='status requested-st mx-auto status-w-75'>
                                {service.inProgress}
                              </div>
                            </td>
                            <td className='text-center'>
                              <div className='status cancelled-st mx-auto status-w-75'>
                                {service.cancelled}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className='row mx-2'>
                {(contactInformation?.length > 0 || isEdit) && (
                  <div className='card card-flush py-5 mt-2 min-card pe-5 mt-10 px-3'>
                    <div className='d-flex mx-3 mt-10 align-items-center'>
                      <h2 className='top ps-0 white-dark-theme-color'>
                        <b>Jobs</b>
                      </h2>
                    </div>
                    <table
                      className='table align-start table-row-dashed gy-6'
                      id='kt_ecommerce_sales_table'
                    >
                      <thead className='fs-7'>
                        <tr>
                          <th className='text-start min-w-75px pe-2'>Job ID</th>
                          <th className='text-start min-w-75px pe-2'>Unit</th>
                          <th className='text-start min-w-75px pe-2'>Floor</th>
                          <th className='text-start min-w-175px pe-2'>Service</th>
                          <th className='text-start min-w-125px pe-2'>Job Status</th>
                          <th className='text-start min-w-175px pe-2'>Schedule</th>
                          <th className='text-start min-w-100px pe-2'>Job Start</th>
                          <th className='text-start min-w-100px pe-2'>Job End</th>
                          <th className='text-start min-w-75px pe-2'>Duration</th>
                          <th className='text-start min-w-75px pe-2'>Job Cost</th>
                          <th className='text-start min-w-75px pe-2'>Rating</th>
                        </tr>
                      </thead>
                      <tbody className='fw-semibold head-text'>
                        {[
                          {
                            id: '001',
                            unit: '101',
                            floor: 'Floor 1',
                            service: 'AC Filter Change',
                            status: 'In-Progress',
                            schedule: '19.01.2025 - 13:00-14:00',
                            scheduleStatus: 'On Time',
                            start: '18.01.2025 - 13:05',
                            end: '-',
                            duration: '-',
                            cost: 'AED -',
                            rating: '-',
                          },
                          {
                            id: '002',
                            unit: '505',
                            floor: 'Floor 5',
                            service: 'AC Filter Change',
                            status: 'In-Progress',
                            schedule: '18.01.2024 - 12:00-13:00',
                            scheduleStatus: 'On Time',
                            start: '18.01.2025 - 12:00',
                            end: '-',
                            duration: '-',
                            cost: 'AED -',
                            rating: '-',
                          },
                          {
                            id: '005',
                            unit: '701',
                            floor: 'Floor 7',
                            service: 'AC Filter Change',
                            status: 'In-Progress',
                            schedule: '18.01.2025 - 11:00-12:00',
                            scheduleStatus: 'Late',
                            start: '18.01.2025 - 11:15',
                            end: '-',
                            duration: '-',
                            cost: 'AED -',
                            rating: '-',
                          },
                          {
                            id: '004',
                            unit: '1001',
                            floor: 'Floor 10',
                            service: 'AC Filter Change',
                            status: 'Cancelled',
                            schedule: '15.01.2025 - 13:00-14:00',
                            scheduleStatus: 'No Show',
                            start: '-',
                            end: '-',
                            duration: '-',
                            cost: 'AED -',
                            rating: '-',
                          },
                          {
                            id: '008',
                            unit: '809',
                            floor: 'Floor 8',
                            service: 'AC Filter Change',
                            status: 'Not Started',
                            schedule: '24.01.2025 - 13:00-14:00',
                            scheduleStatus: '-',
                            start: '-',
                            end: '-',
                            duration: '-',
                            cost: 'AED -',
                            rating: '-',
                          },
                          {
                            id: '009',
                            unit: '903',
                            floor: 'Floor 9',
                            service: 'AC Filter Change',
                            status: 'Not Started',
                            schedule: '24.01.2025 - 12:00-13:00',
                            scheduleStatus: '-',
                            start: '-',
                            end: '-',
                            duration: '-',
                            cost: 'AED -',
                            rating: '-',
                          },
                          {
                            id: '007',
                            unit: '306',
                            floor: 'Floor 3',
                            service: 'AC Filter Change',
                            status: 'Complete',
                            schedule: '12.01.2025 - 13:00-14:00',
                            scheduleStatus: 'On Time',
                            start: '12.01.2024 - 13:10',
                            end: '12.01.2025 - 14:10',
                            duration: '1 Hr',
                            cost: 'AED 210',
                            rating: '4',
                          },
                          {
                            id: '003',
                            unit: '707',
                            floor: 'Floor 7',
                            service: 'AC Filter Change',
                            status: 'Complete',
                            schedule: '24.04.2024 - 13:00-14:00',
                            scheduleStatus: 'Late',
                            start: '12.01.2025 - 13:30',
                            end: '24.04.2024 - 15:30',
                            duration: '2 Hrs',
                            cost: 'AED 200',
                            rating: '2',
                          },
                        ].map((job, i) => (
                          <tr key={i}>
                            <td>
                              <div
                                className='fw-bolder text-success'
                                style={{
                                  color: '#0d9e91 !important',
                                  textDecoration: 'underline',
                                }}
                              >
                                {job.id}
                              </div>
                            </td>
                            <td>
                              <div className='fw-bolder'>{job.unit}</div>
                            </td>
                            <td>{job.floor}</td>
                            <td>{job.service}</td>
                            <td>
                              <div
                                className={
                                  job.status == 'Not Started'
                                    ? 'status booked-bar'
                                    : job.status == 'Complete'
                                    ? 'status active-bar  '
                                    : job.status == 'In-Progress'
                                    ? 'status expiring-bar'
                                    : job.status == 'Cancelled'
                                    ? 'status expired-bar'
                                    : 'status  no-st-bar'
                                }
                              >
                                {job.status}
                              </div>
                            </td>
                            <td>
                              <div
                                style={{
                                  color: 'red !important',
                                }}
                              >
                                {job.schedule}
                                <br />
                                <div
                                  className={
                                    job.scheduleStatus === 'On Time'
                                      ? 'text-success'
                                      : job.scheduleStatus === 'Late'
                                      ? 'text-warning'
                                      : job.scheduleStatus === 'No Show'
                                      ? 'text-danger'
                                      : job.scheduleStatus === 'No Show'
                                      ? 'text-danger'
                                      : ''
                                  }
                                >
                                  {job.scheduleStatus}
                                </div>
                              </div>
                            </td>
                            <td>{job.start}</td>
                            <td>{job.end}</td>
                            <td>{job.duration}</td>
                            <td>{job.cost}</td>

                            <td>
                              <div>
                                <img src={starYellow} height={18} width={18} className='me-1' />
                                {job.rating}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* Select Properties Modal */}
      {selectModal && (
        <SelectPropertyModal
          show={selectModal}
          handleClose={() => {
            setSelectModal(false)
          }}
          handleSubmitId={(data: any) => {
            handlePropertySelection(data)
          }}
          addedProperties={properties}
        />
      )}

      {/* Select LISTS MODAL */}
      {selectListModal && (
        <SelectListModal
          show={selectListModal}
          handleClose={() => {
            setSelectListModal(false)
          }}
          addedLists={lists}
          updateLists={(data: any) => {
            console.log(data)
            const values: any = []
            let j = 0
            for (let i = 0; i < data?.length; i++) {
              if (data[i]?.check) {
                values[j] = data[i]
                values[j].check = false
                j++
              }
            }
            setLists(values)
            setSelectListModal(false)
          }}
        />
      )}

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
        {/* <div style={{color: '#007a59', fontWeight: '700',
      }}>LOADING..</div> */}
      </Backdrop>

      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </>
  )
}

export default ViewServiceContract
