import { formatAmount } from '../../../../../../../../../../Utilities/utils';

const SecurityDepositDisplay = ({ chrgData }: any) => {
    const amount = chrgData?.previewData?.securityDepositAmount;
    const showAmount = chrgData?.previewData?.totalValue;

    return (
        <div className="d-flex flex-wrap align-items-center justify-content-between">
            <i className='d-block fnt-500'>
                <span>Calculated from</span>
                <span className="d-block mt-1">the total rent</span>
            </i>
            {showAmount && (
                <p className="fnt-500 light-dark-theme-color mb-0">
                    {formatAmount(amount) || '0'} AED
                </p>
            )}
        </div>
    );
};

export default SecurityDepositDisplay