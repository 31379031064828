import React, {useState, useEffect} from 'react'
import {Col, Row, Modal} from 'react-bootstrap'
import close from '../../../img/close.png'
import debitBlue from '../../../img/debit-blue.png'
import {ApiDelete, ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import debitNoteWhite from '../../../img/debit-white..png'
import ViewDebitNote from './ViewDebitNote'
import { ErrorToast } from '../../../apiCommon/helpers/Toast'

interface ButtonProps {
  show: any
  handleClose: any
  debitNotes: any
  paymentId: any
  tenantDetails: any
  getPaymentDebitNotes: any
  from:any
}

const ViewAllDebitNotesOfPayment = ({
  handleClose,
  show,
  debitNotes,
  paymentId,
  tenantDetails,
  getPaymentDebitNotes,
  from
}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [viewDebitNote, setViewDebitNote] = useState<any>(false)
  const [debitNoteToView, setDebitNoteToView] = useState<any>()

  const getDebitNoteById = async (id: any) => { 
    let url: any
    if (from == 'terminate-tenancy')
      url = `corporate/payment_debit_note/${id}?isCloneRecord=true`
    else url = `corporate/payment_debit_note/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setDebitNoteToView(res?.data?.data)
        setViewDebitNote(true)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto no-print'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 debit-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white' style={{fontWeight: '700'}}>
                Debit Notes
              </h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={handleClose}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '65px'}}>
              <img src={debitNoteWhite} height='80px' width='80px' className='me-3 ' />
            </Col>
          </Row>
        </div>

        <div
          className='modal-body pt-0 pe-lg-3 pb-0 ps-3'
          style={{
            transform: 'translate(0px, -80px)',
            height: '400px',
            maxHeight: '500px',
            overflowX: 'scroll',
            marginBottom: '-86px',
          }}
        >
          <div className='row px-3 pt-2'>
            {debitNotes?.length > 0 &&
              debitNotes.map((db: any, index: any) => (
                <div className='img-sets mw-350px col-2 mt-2 ps-2 text-center'>
                  <div className='text-center'>
                    <a
                     onClick={() => {
                      if (
                        ((from == 'tenancy' || from == 'terminate-tenancy') &&
                          ((user?.isSubUser &&
                            role?.leasing_residential?.lease_financial?.debit_notes
                              ?.view_details) ||
                            !user?.isSubUser)) ||
                        from == 'propertyProfile' ||
                        from == 'tenant' ||
                        from == 'financials'
                      )
                        getDebitNoteById(db?._id)
                      else
                        ErrorToast(`You don't have the permissions to view Debit Note details...`)
                    }}
                      // href={`${Bucket}${detailsToEdit?.invoiceURL}`}
                      // target='_blank'
                      style={{color: '#384a62'}}
                      className='d-flex justify-content-center'
                    >
                      <img
                        src={debitBlue}
                        width='50'
                        height='50'
                        className='main_img cursor-pointer ms-2'
                      />
                    </a>
                    <p
                      style={{
                        height: '15px',
                        fontSize: '0.9rem',
                        wordBreak: 'break-all',
                      }}
                      className='mt-1 text-center'
                    >
                      {db?.debitNoteNo}{' '}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>

      {viewDebitNote && (
        <ViewDebitNote
          show={viewDebitNote}
          handleClose={() => {
            setViewDebitNote(false)
          }}
          debitNote={debitNoteToView}
          paymentId={paymentId}
          tenantDetails={tenantDetails}
          dueDate={''}
          from={from}
        />
      )}
    </div>
  )
}

export default ViewAllDebitNotesOfPayment
