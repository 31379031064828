import React, {useEffect} from 'react'
import {useState} from 'react'
import './style.scss'
import {Modal} from 'react-bootstrap'
import addWhite from '../../../../../img/add-white.png'
import {Controller, useForm} from 'react-hook-form'
import {SuccessToast, ErrorToast} from '../../../../../apiCommon/helpers/Toast'
import {ApiPost, ApiGet, ApiPut} from '../../../../../apiCommon/helpers/API/ApiData'
import redCross from '../../../../../img/remove.png'
import tick from '../../../../../img/tick-white.png'
import Select from 'react-select'
import { capitalizeFirstLetter } from '../../../../../Utilities/utils'

interface ButtonProps {
  show: any
  handleClose: any
  type: any
  updateList: any
  mode: any
  serviceObj: any
  tab:any
  tabId:any
}

const AddNewService = ({show, handleClose, type, updateList, mode, serviceObj, tab, tabId}: ButtonProps) => {
  const [service, setService] = useState<any>(
    (mode == 'add' && tab == 'all')
      ? {
          name: '',
          list: '',
          mainId: '',
        }
      : (mode == 'add' && tab != 'all')
      ? {
          name: '',
          list: tabId,
          mainId: '',
        }
      :
      {
          id: serviceObj?._id,
          name: serviceObj?.name,
          list: serviceObj?.maintenance_service_list?._id,
          mainId: serviceObj?.maintenance_main_service?._id,
        }
  )

  const [isLoading, setIsLoading] = useState<any>(false)
  const [list, setList] = useState<any>([])

  const [mainServicesOptions, setMainServicesOptions] = useState<any>([])

  let listInd: any
  let mainInd: any

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  // React Select Filled
  const customStylesInputFilled = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #35475e',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  //
  const getServicesListForDropdown = async () => {
    await ApiGet(`corporate/maintenance_service_list`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setList(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    getServicesListForDropdown()
  }, [])

  //
  const getMainServicesForDropdown = async () => {
    await ApiGet(`corporate/maintenance_main_service?maintenanceServiceListId=${service?.list}`)
      .then((res: any) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setMainServicesOptions(values)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    if (type == 'sub' && service?.list != '') getMainServicesForDropdown()
  }, [service])

  const addService = () => {
    setIsLoading(true)
    let name = service?.name.charAt(0).toUpperCase() + service?.name.slice(1)
    const body = {
      name: name,
      isAppServiceEnable: true,
      maintenanceServiceListId: service?.list,
      maintenanceMainServiceId: type == 'sub' ? service?.mainId : undefined,
    }

    let url: any = ''
    if (type == 'main') url = 'corporate/maintenance_main_service'
    else url = 'corporate/maintenance_sub_service'

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        updateList()
        setIsLoading(false)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err.message)
      })
  }

  //
  const updateService = () => {
    setIsLoading(true)
    let name = service?.name.charAt(0).toUpperCase() + service?.name.slice(1)
    const body = {
      id: service?.id,
      name: name,
      maintenanceServiceListId: service?.list,
      maintenanceMainServiceId: type == 'sub' ? service?.mainId : undefined,
    }

    let url: any = ''
    if (type == 'main') url = 'corporate/maintenance_main_service'
    else url = 'corporate/maintenance_sub_service'

    ApiPut(`${url}`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        updateList()
        setIsLoading(false)
      })
      .catch((err) => {
        setIsLoading(false)
        ErrorToast(err.message)
      })
  }

  const isMain: any = isLoading || service?.name == '' || service?.list == ''

  const isSub: any =
    isLoading || service?.name == '' || service?.list == '' || service?.mainId == ''

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body px-7 pt-lg-3 pb-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '300px',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <div
            className='d-flex align-items-center pb-4 mt-2'
            style={{borderBottom: '0.1rem solid #bec3cb'}}
          >
            <h2 className='pt-2 mb-0 white-dark-theme-color'>
              <b>{mode == 'add' ? 'Add New Service' : 'Edit Service'}</b>
            </h2>
            <div className='d-flex ms-auto'>
              <button
                type='button'
                className='btn btn-sm fw-bold  mx-3 red-hollow-btn'
                onClick={() => {
                  handleClose()
                }}
                disabled={isLoading}
              >
                <img src={redCross} height={18} width={18} style={{marginRight: '7px'}} /> Cancel
              </button>
            </div>
          </div>

          {list &&
            list.map((ls: any, index: any) => {
              if (service?.list == ls?.value) listInd = index
              return <></>
            })}

          <div className='row mx-3'>
            <div className='col-10 px-5  ps-0 my-5 mt-10'>
              <div className='d-flex '>
                <h4 className='my-2' style={{color: 'black'}}>
                  <label style={{width: '130px'}} className='labl-gry required'>
                    {' '}
                    List
                  </label>
                </h4>
                <h4 style={{width: '-webkit-fill-available'}}>
                  <Select
                    defaultValue={mode == 'edit' || listInd != undefined ? list[listInd] : []}
                    value={mode == 'edit' || listInd != undefined ? list[listInd] : []}
                    styles={
                      mode == 'edit' || listInd != undefined
                        ? customStylesInputFilled
                        : customStyles
                    }
                    isSearchable={false}
                    options={list}
                    name='sortType'
                    onChange={(e: any) => {
                      let values = {...service}
                      values.list = e.value
                      setService(values)
                    }}
                    placeholder={'Select List'}
                  />
                </h4>
              </div>
            </div>
          </div>

          {mainServicesOptions &&
            mainServicesOptions.map((ls: any, index: any) => {
              if (service?.mainId == ls?.value) mainInd = index
              return <></>
            })}

          {type == 'sub' && (
            <div className='row mx-3'>
              <div className='col-10 px-5  ps-0 my-5 mt-3'>
                <div className='d-flex '>
                  <h4 className='my-2' style={{color: 'black'}}>
                    <label style={{width: '130px'}} className='labl-gry required'>
                      {' '}
                      Main Service
                    </label>
                  </h4>
                  <h4 style={{width: '-webkit-fill-available'}}>
                    <Select
                      defaultValue={
                        mode == 'edit' || mainInd != undefined ? mainServicesOptions[mainInd] : []
                      }
                      value={
                        mode == 'edit' || mainInd != undefined ? mainServicesOptions[mainInd] : []
                      }
                      styles={
                        mode == 'edit' || mainInd != undefined
                          ? customStylesInputFilled
                          : customStyles
                      }
                      isSearchable={false}
                      options={mainServicesOptions}
                      name='sortType'
                      onChange={(e: any) => {
                        let values = {...service}
                        values.mainId = e.value
                        setService(values)
                      }}
                      placeholder={'Select Main Service'}
                    />
                  </h4>
                </div>
              </div>
            </div>
          )}

          <div className='row mx-3'>
            <div className='col-10 px-5  ps-0 my-5 mt-3'>
              <div className='d-flex '>
                <h4 className='my-2' style={{color: 'black'}}>
                  <label style={{width: '130px'}} className='labl-gry required'>
                    {' '}
                    {type == 'main' ? 'Main Service' : 'Sub Service'}
                  </label>
                </h4>
                <h4 style={{width: '-webkit-fill-available'}}>
                  <input
                    type='text'
                    className='form-control form-control-solid mytest'
                    name='companyName'
                    value={service?.name}
                    onChange={(e) => {
                      let values = {...service}
                      let newVal : any = capitalizeFirstLetter(e.target.value)
                      values.name = newVal
                      setService(values)
                    }}
                    placeholder={`Enter a ${type == 'main' ? 'Main' : 'Sub'} Service Name`}
                  />
                </h4>
              </div>
            </div>
          </div>

          <div className='d-flex mx-3 justify-content-center mt-5 mb-10'>
            <button
              type='submit'
              className={`btn btn-sm fw-bold  me-0 ${
                mode == 'add' ? 'green-submit-btn' : 'select-btn'
              }  ps-3 pe-10 status-w-250 justify-content-center`}
              disabled={type == 'main' ? isMain : isSub}
              onClick={() => {
                if (mode == 'add') addService()
                else updateService()
              }}
            >
              <img
                src={mode == 'add' ? addWhite : tick}
                height={17}
                width={17}
                style={{marginRight: '10px'}}
              />
              {mode == 'add' ? 'Add Service' : 'Apply Changes'}
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddNewService
