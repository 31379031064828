import React from 'react'
import {useState} from 'react'
import './style.scss'
import {CirclePicker} from 'react-color'
import {Modal} from 'react-bootstrap'
import addToListIcon from '../../../img/vendor-lists-add.png'
import {Controller, useForm} from 'react-hook-form'
import colorPickerIcon from '../../../img/colr-picker.png'
import ColorPickerModal from './ColorPickerModal'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import { capitalizeFirstLetter } from '../../../Utilities/utils'

interface ButtonProps {
  show: any
  handleClose: any
  updateList: any
}

const AddNewVL = ({show, handleClose, updateList}: ButtonProps) => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [vl, setVl] = useState<any>({
    name: '',
    colorCode: '',
  })

  const [showPickerModal, setShowPickerModal] = useState<any>(false)
  const [cardPosition, setCardPosition] = useState({top: 0, left: 0})
  const [isLoading, setIsLoading] = useState<any>(false)

  const [clrError, setClrError] = useState<any>(false)

  const addVendorList = async (e: any) => {
    e.preventDefault()
    if (vl?.colorCode == '') {
      setClrError(true)
    } else {
      setShowPickerModal(false)
      setIsLoading(true)
      const body = {
        name: vl?.name,
        colorCode: vl?.colorCode,
      }

      await ApiPost(`corporate/vendor_list`, body)
        .then((res: any) => {
          SuccessToast(res?.data?.message)

          handleClose()
          setIsLoading(false)
          updateList()
          setVl({
            name: '',
            colorCode: '',
          })
        })
        .catch((err: any) => {
          setIsLoading(false)
          ErrorToast(err?.message)
        })
    }
  }

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <form onSubmit={handleSubmit((data: any, e: any) => addVendorList(e))}>
          <div
            className='modal-body px-7 pt-lg-3 pb-lg-3 modal-body-without-header'
            style={{
              minHeight: '300px',
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
          >
            <div className='my-1 pb-1 row'>
              <div className='col-md-12 text-end'>
                <label
                  className='head-text fs-5 cursor-pointer fnt-700 position-relative'
                  style={{ zIndex: '2'}}
                  onClick={() => {
                    handleClose()
                  }}
                >
                  X
                </label>
              </div>
            </div>

            <div
              className='d-flex align-items-center pb-3'
              style={{borderBottom: '0.1rem solid #bec3cb'}}
            >
              <h2 className='head-text pt-2 mb-0'>
                <b>Add New Vendor List</b>
              </h2>
              <div className='d-flex ms-auto'>
                <button
                  type='submit'
                  className='btn btn-sm fw-bold  me-0 select-btn ps-3 pe-10 btn-fit-content'
                  disabled={isLoading}
                >
                  <img src={addToListIcon} height={17} width={17} className='me-3' />{' '}
                  Add
                </button>
              </div>
            </div>

            <div className='row mx-3'>
              <div className='col-12 px-5  ps-0 my-5 mt-10'>
                <div className='d-flex '>
                  <h4 className='my-2' style={{color: 'black'}}>
                    <label style={{width: '100px'}} className='labl-gry required'>
                      {' '}
                      Name
                    </label>
                  </h4>
                  <h4 style={{width: '-webkit-fill-available'}}>
                    <input
                      {...register('vlName', {required: true})}
                      type='text'
                      className='form-control form-control-solid mytest'
                      placeholder='Vendor List Name'
                      name='companyName'
                      value={vl?.name}
                      onChange={(e) => {
                        let values = {...vl}
                        values.name = capitalizeFirstLetter(e.target.value)
                        setVl(values)
                        setValue('vlName', e.target.value)
                      }}
                    />

                    {errors.vlName && (
                      <p
                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                        className='m-0 mt-1'
                      >
                        This field is required
                      </p>
                    )}

                    {clrError && (
                      <p
                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                        className='m-0 mt-1'
                      >
                        Please pick a color for this vendor list
                      </p>
                    )}
                  </h4>
                  <h4>
                    <img
                      src={colorPickerIcon}
                      height={22}
                      width={22}
                      className='cursor-pointer mt-1 ms-5'
                      onClick={(event: any) => {
                        const buttonRect = event.target.getBoundingClientRect()
                        const newPosition = {
                          top: buttonRect.bottom + window.scrollY + 20,
                          left: buttonRect.left + window.scrollX - 300, // Adjust the value to position the card on the left
                        }

                        setShowPickerModal(!showPickerModal)
                        setCardPosition(newPosition)
                      }}
                    />
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>

      {/*  */}
      <ColorPickerModal
        show={showPickerModal}
        handleClose={() => {
          setShowPickerModal(false)
        }}
        selectedVL={vl}
        index={''}
        allVL={''}
        updateList={(clr: any) => {
          let values = {...vl}
          values.colorCode = clr
          setVl(values)
        }}
        position={{
          top: cardPosition.top + 'px',
          left: cardPosition.left + 'px',
        }}
        from={'addVL'}
      />
    </>
  )
}

export default AddNewVL
