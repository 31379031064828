import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { preventExpAndSign, removeLeadingZeros } from '../../../../Utilities/utils'

interface LatePaymentInputI {
  isDisabled: boolean
  iconSize: number
  icon?: string
  type?: string
  label: string
  name: string
  value: string
  onChange: any
  required?: boolean
  firstPlaceholder?: string
  secondPlaceHolder?: string
  className?: string
  setRightPosition?: string
  subLabel?: string
  minValue?: number
  isPercent?: boolean
  maxValue?: number
  errorClasses?: string
}

const FlexedInput = ({
  type = 'text',
  label,
  name,
  value = '',
  onChange,
  required = false,
  firstPlaceholder = '-',
  secondPlaceHolder = 'Days',
  icon,
  iconSize,
  className = '',
  setRightPosition = '',
  isDisabled,
  subLabel,
  minValue = 0,
  isPercent = false,
  maxValue,
  errorClasses = ''
}: LatePaymentInputI) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext()

  const [errorMessage, setErrorMessage] = useState<string>('')

  const handleChange = (e: any) => {
    let newValue = removeLeadingZeros(e.target.value)
    onChange(newValue)

    if (newValue.trim().length === 0) {
      setValue(name, newValue, {
        shouldValidate: true,
      })
    } else {
      setValue(name, newValue, {
        shouldValidate: true,
      })
    }
  }

  function enforceMinMax(event: any) {
    const el = event.target
    if (el.value != '') {
      if (parseInt(el.value) < parseInt(el.min)) {
        el.value = el.min
        setErrorMessage(`Value can not be less than ${el.min}`)
      }
      if (isPercent && parseInt(el.value) > 100) {
        el.value = 100
        setErrorMessage('Value can not exceed 100')
      } else if (maxValue && parseInt(el.value) > maxValue) {
        el.value = maxValue
        setErrorMessage(`Value can not exceed ${maxValue}`)
      }
    }

    setTimeout(() => {
      setErrorMessage('')
    }, 5000)

  }

  return (
    <div className={`${className} d-flex flex-column`}>
      <div className="d-flex align-items-center">
        {label != '' && (
          <div className='percentage-label-container'>
            <label
              className={`percentage-input-label ${required ? 'required' : ''} white-dark-theme-color fnt-500 status-w-130`}
            >
              {label}
            </label>
          </div>
        )}

        <div className='percentage-input-wrapper mt-2'>
          <input
            disabled={isDisabled}
            onInput={enforceMinMax}
            min={minValue}
            max={maxValue}
            type={type}
            name={name}
            value={value}
            onKeyDown={type === 'number' ? preventExpAndSign : () => { }}
            onChange={handleChange}
            className={`form-control form-control-solid latePaymentInput ${type === 'number' ? 'hide-number-input-arrows' : ''
              } ${value ? 'latePaymentInputFilled' : ''} percentage-input`}
            autoComplete='off'
            onWheel={(e) => e.currentTarget.blur()}
          />
          <span
            className={`percentage-placeholder-left ${value
              ? 'percentage-placeholder percentage-placeholder-filled'
              : 'percentage-placeholder'
              } ps-3`}
          >
            {value === '' || value === null ? `${firstPlaceholder}` : ''}
          </span>
          <span
            className={`${value
              ? 'percentage-placeholder percentage-placeholder-filled'
              : 'percentage-placeholder'
              }`}
            style={{ right: `${setRightPosition}`, backgroundColor: 'var(--kt-input-bg)', }}
          >
            {`${secondPlaceHolder}`}
          </span>
        </div>
      </div>

      {errorMessage && (
        <div className={`${errorClasses}`} style={{ marginLeft: '130px' }}>
          <p style={{ color: 'var(--kt-danger)' }} className='m-0 mt-1 fnt-400'>
            {errorMessage}
          </p>
        </div>
      )}

      {errors[name] && (
        <div className={`${errorClasses}`} style={{ marginLeft: '130px' }}>
          <p style={{ color: 'var(--kt-danger)' }} className='m-0 mt-1 fnt-400'>
            This field is required
          </p>
        </div>
      )}
    </div>
  )
}

export default FlexedInput