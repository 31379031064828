import React from 'react'
import {useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import ApexCharts from 'react-apexcharts'
import arrowUp from '../../../../img/caret-arrow-up-green.png'
import arrowDown from '../../../../img/caret-arrow-down-green.png'

interface TenantChartProps {
  chartData: DataPoint[];
}

interface DataPoint { 
  time: string
  complaints: number
  percentage: number
  increase: boolean
  decrease: boolean
}

const TenantComplaintChartMonthly : React.FC<TenantChartProps> = ({chartData}) =>{
  // const chartData: DataPoint[] = [
  //   {
  //     time: '2024-05-01T00:00:00Z',
  //     percentage: 20,
  //     complaints: 60,
  //     increase: true,
  //     decrease: false,
  //   },
  //   {
  //     time: '2024-06-02T00:00:00Z',
  //     percentage: 30,
  //     complaints: 10,
  //     increase: false,
  //     decrease: false,
  //   },
  //   {
  //     time: '2024-07-03T00:00:00Z',
  //     percentage: 40,
  //     complaints: 120,
  //     increase: false,
  //     decrease: true,
  //   },

  //   {
  //     time: '2024-08-04T00:00:00Z',
  //     percentage: 50,
  //     complaints: 90,
  //     increase: false,
  //     decrease: false,
  //   },
  //   {
  //     time: '2024-09-05T00:00:00Z',
  //     percentage: 60,
  //     complaints: 50,
  //     increase: true,
  //     decrease: false,
  //   },
  //   {
  //     time: '2024-10-06T00:00:00Z',
  //     percentage: 70,
  //     complaints: 60,
  //     increase: true,
  //     decrease: false,
  //   },
  //   {
  //     time: '2024-11-07T00:00:00Z',
  //     percentage: 40,
  //     complaints: 100,
  //     increase: true,
  //     decrease: false,
  //   },
  //   {
  //     time: '2024-12-08T00:00:00Z',
  //     percentage: 10,
  //     complaints: 120,
  //     increase: true,
  //     decrease: false,
  //   },
  //   {
  //     time: '2025-01-09T00:00:00Z',
  //     percentage: 80,
  //     complaints: 130,
  //     increase: true,
  //     decrease: false,
  //   },
  //   {
  //     time: '2025-02-10T00:00:00Z',
  //     percentage: 5,
  //     complaints: 150,
  //     increase: true,
  //     decrease: false,
  //   },
  //   {
  //     time: '2025-03-11T00:00:00Z',
  //     percentage: 8,
  //     complaints: 170,
  //     increase: true,
  //     decrease: false,
  //   },
  // ]

  const seriesData = {
    tenants: [] as number[],
  }

  chartData?.forEach((dataPoint) => {
    seriesData?.tenants?.push(dataPoint?.complaints)
  })

  const [series, setSeries] = useState([
    {
      name: 'Tenants',
      data: seriesData?.tenants,
      color: '#188383',
    },
  ])

  const categories: string[] = chartData?.map((dataPoint) => dataPoint?.time)

  // Find the maximum complaints value in the data
  const maxComplaints :any= Math?.max(
    ...chartData?.map((dataPoint) => dataPoint?.complaints)
);

const yAxisMax = Math?.ceil(maxComplaints / 10) * 10 
console.log(yAxisMax)

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      height: 350,
      type: 'bar',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
      },
      selection: {
        enabled: true
      },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
    },
    colors: ['#188383'],
    plotOptions: {
      bar: {
        horizontal: false,
        // columnWidth: '40px',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: ['#666'],
        fontSize: chartData?.length > 10 ? '6px' : '10px',
      },
      formatter: function (val, {series, seriesIndex, dataPointIndex, w}) {
        if(chartData?.length > 0){
        const percentage = Number?.isInteger(chartData?.[dataPointIndex]?.percentage) ? chartData?.[dataPointIndex]?.percentage : chartData?.[dataPointIndex]?.percentage?.toFixed(1);
        return `${chartData?.[dataPointIndex]?.increase ? `${percentage}% 🔻` : chartData?.[dataPointIndex]?.decrease ? `${percentage}%  ▲` : ''}`;
          }  else return ''
      },
      offsetY: -35,
      offsetX: -10,
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: 'datetime',
      categories: categories,
      labels: {
        format: 'MMM-yy',
        rotate: 0,
        rotateAlways: false,
        style: {
          fontSize: '12px',
        },
      },
      // tickAmount: Math.min(categories?.length, 10),
    },
    yaxis: {
      min: 0,
      max: yAxisMax == 0 ? 25 : yAxisMax,
      tickAmount: yAxisMax == 0 ? 5 : yAxisMax > 50 ? yAxisMax/10 : yAxisMax / 5,
      axisTicks: {
        show: false,
      },
      labels: {
        // formatter: function (value: number) {
        //   return `${value}%`
        // },
        style: {
          colors: '#666',
        },
      },
      axisBorder: {
        show: false,
      },
    },
    tooltip: {
      // y: {
      //   formatter: (value: number, {seriesIndex, dataPointIndex, w}: any) => {
      //     const dataPoint: any = chartData[dataPointIndex]
      //     return `${value.toFixed(2)}% (Units: ${dataPoint.units})`
      //   },
      // },

      custom: function ({series, seriesIndex, dataPointIndex, w}) {
        const dataPoint = chartData?.[dataPointIndex]
        const date = new Date(categories?.[dataPointIndex])?.toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
        return (
          `<div class="apexcharts-tooltip-custom">` +
          `<div style="text-align:center;">${date}</div>` +
          `<div>` +
          `<div style="display: flex; justify-content: center;">`+
          `<div style="font-weight:bold">${chartData?.[dataPointIndex]?.complaints}</div>` +
          `<div>Complaints</div>` +
          `<div>(${chartData?.[dataPointIndex]?.increase || chartData?.[dataPointIndex]?.decrease ? `${Number?.isInteger(chartData?.[dataPointIndex]?.percentage) ? chartData?.[dataPointIndex]?.percentage : chartData?.[dataPointIndex]?.percentage?.toFixed(1)}` : '-'}% ${chartData?.[dataPointIndex]?.increase || chartData?.[dataPointIndex]?.decrease ? `<img src="${chartData?.[dataPointIndex]?.increase ? arrowUp :arrowDown}" width="14" height="14" />`:'' })</div>` +
          `</div>` +
          `</div>` +
          `</div>`
        )
      },
    },
    title: {
      text: 'Tenant Complaints',
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        color: '#000',
      },
    },
    grid: {
      show: false,
    },
    states: {},
    annotations: {},
  }

  // Include CSS to style the tooltip as desired
  const styles = `
  .apexcharts-tooltip-custom {
    padding: 10px;
    background: #fff;
    border: 1px solid #ccc;
  }
  .apexcharts-tooltip-custom div {
    margin: 5px 3px;
  }
  .apexcharts-tooltip-custom .xyz span {
    // display: inline-block;
  }
`
  const styleSheet = document.createElement('style')
  styleSheet.innerText = styles
  document.head.appendChild(styleSheet)

  return (
    // <div className='row'>
    //   <div className='col-12'>
    //     <div className='card card-flush py-4 px-2'>
          <div id='chart'>
            <ApexCharts options={chartOptions} series={series ? series : []} type='bar' height={300} />
            
          </div>
    //     </div>
    //   </div>
    // </div>
  )
}

export default TenantComplaintChartMonthly
