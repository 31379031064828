import {Modal} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import {formatAmount} from '../../../../../../../../../Utilities/utils'

interface OverlappingModalI {
  show: boolean
  onHide: any
  showDetails: any
  unitsCount: any
}

const OverlappingModal = ({show, onHide, showDetails, unitsCount}: OverlappingModalI) => {
  const {type} = useParams()
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px mx-auto'
      show={show}
      onHide={() => {
        onHide()
      }}
      backdrop={true}
    >
      <div className='modal-body p-0 modal-body-without-header px-8 pb-7'>
        <div className='row py-5 m-0 pb-2'
          style={{position: 'sticky', top: 0, background: 'transparent', zIndex: 2}}
        >
          <div className={`col-12 px-0`}>
            <div className='d-flex justify-content-end pb-3'>
              <label
                className='head-text fs-4 cursor-pointer blue-dark-text fnt-700'
                onClick={() => {
                  onHide()
                }}
              >
                X
              </label>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-start'>
          <h2 className='text-dark white-dark-theme-color mb-4'>
            <b>Unable to activate this charge configuration.</b>
          </h2>
        </div>

        <div>
          <p className='my-3 text-start text-dark white-dark-theme-color fs-4 fnt-400 mb-4'>
            It seems that the units assigned to this configuration overlap with another
            configuration that’s currently active.
          </p>

          <p className='my-3 text-start text-dark white-dark-theme-color fs-4 fnt-400 mb-4'>
            To activate this charge, you must first deactivate the charge that is active and then
            proceed to activating this charge or remove the overlapping units from the existing
            active charge and then proceed to activating this configuration.
          </p>

          <p className='my-3 text-start text-dark white-dark-theme-color fs-4 fnt-700 mb-4 text-decoration-underline pt-5'>
            Details:
          </p>
        </div>

        <div className='row mx-0'>
          {' '}
          <div className='col-4 my-4 ps-0 pe-7'>
            <div className='my-4 '>
              <h4 className='my-2'>
                <label className='labl-gry '> Charge Name</label>
              </h4>

              <h4 className='my-2 text-dark'>
                <b>
                  {' '}
                  {type == 'contract-fee'
                    ? 'Contract Fees'
                    : type == 'admin-fee'
                    ? 'Admin Fees'
                    : type == 'management-fee'
                    ? 'Management Fees'
                    : type == 'maintenance-fee'
                    ? 'Maintenance Fees'
                    : type == 'housekeeping-fee'
                    ? 'Housekeeping Fees'
                    : type == 'booking-fee'
                    ? 'Booking Fees'
                    : type}
                </b>
              </h4>
            </div>
          </div>
          <div className='col-4 my-4 ps-0 pe-7'>
            <div className='my-4 '>
              <h4 className='my-2'>
                <label className='labl-gry '> Units Overlap</label>
              </h4>

              <h4 className='my-2 text-dark'>
                <b>{formatAmount(unitsCount)} Units</b>
              </h4>
            </div>
          </div>
          <div className='col-4 my-4'>
            <div className='my-4 '>
              <button
                type='button'
                className='btn btn-sm fw-bold blue-btn px-2 mx-auto justify-content-center status-w-175'
                onClick={() => {
                  showDetails()
                }}
              >
                View Overlapping Units
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default OverlappingModal
