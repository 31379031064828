import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../img/back-arrow.png'
// import './style.scss'
import {ApiGet, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import leasingImg from '../../../img/leasing.png'
import resImg from '../../../img/residential.png'
import commImg from '../../../img/commercial.png'
import noData from '../../../img/NoData1.svg'

const LeasingWorkflows = () => {
  const navigate = useNavigate()
  const {id} = useParams()

  const [subWorkflows, setSubWorkflows] = useState<any>()

  const getSubWorkFlows = () => {
    const body = {
      mainWorkflowId: id,
    }
    ApiPost('corporate/sub_workflow/get', body)
      .then((res: any) => {
        setSubWorkflows(res?.data?.data)
      })
      .catch(() => {})
  }
  useEffect(() => {
    getSubWorkFlows()
  }, [])

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
          <div className='d-flex align-items-center gap-3'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => navigate('/settings/workflows')}
            >
              <img src={backArrow} style={{stroke: 'red'}} height='14px' width='14px' />
            </span>
            <h2 className='page-heading m-0 head-text'> Leasing Workflows</h2>
          </div>
          <div className='ms-auto'>
            <img src={leasingImg} height='30px' width='30px' />
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3' style={{minHeight: '75vh'}}>
          <div className='card-body pt-0 mt-5 py-0 px-3'>
            <Row className='my-5'>
              {subWorkflows?.length > 0 ? (
                <>
                  {subWorkflows?.map((workflow: any, index: any) => {
                    return (
                      <Col md={6} className='mb-5'>
                        <div
                          className='card card-flush gap-5 p-3 cursor-pointer'
                          onClick={() => {
                            let type: any
                            if (workflow?.name == 'Residential Leasing') type = 1
                            else if (workflow?.name == 'Commercial Leasing') type = 2
                            navigate(`/settings/workflows/leasing/${id}/${type}/${workflow?._id}`)
                          }}
                        >
                          <div className='card-body p-3'>
                            <div className='d-flex align-items-center'>
                              <img
                                src={
                                  workflow?.name == 'Residential Leasing'
                                    ? resImg
                                    : workflow?.name == 'Commercial Leasing'
                                    ? commImg
                                    : ''
                                }
                                height={35}
                                width={35}
                              />
                              <h4 className='page-heading m-0 head-text ps-4'>
                                <b>{workflow?.name}</b>
                              </h4>
                              <div className='ms-auto text-center'>
                                <h5 className=' m-0 labl-gry mb-3 light-dark-theme-color'>Workflows</h5>
                                <h4 className=' m-0 head-text'>
                                  <b>{workflow?.totalWorkflows}</b>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    )
                  })}
                </>
              ) : (
                <>
                <Row>
                    <Col md={12} className='text-center'>
                      <img src={noData} alt='' width={350}  />
                    </Col>
                  </Row></>
              )}
              {/* <Col md={6} className='mb-5'>
                <div className='card card-flush gap-5 p-3 cursor-pointer' onClick={()=>navigate('/settings/workflows/leasing/2')}>
                  <div className='card-body p-3'>
                    <div className='d-flex align-items-center'>
                      <img src={commImg} height={32} width={28} />
                      <h4 className='page-heading m-0 head-text ps-4'>
                        <b>Commercial Leasing</b>
                      </h4>
                      <div className='ms-auto text-center'>
                        <h5 className=' m-0 labl-gry mb-3'>Workflows</h5>
                        <h4 className=' m-0 head-text'>
                          <b>3</b>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default LeasingWorkflows
