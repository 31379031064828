import React, {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import moment, {Moment} from 'moment'
import './style.scss'
import Select from 'react-select'
import chequeImg from '../../../img/cheque.png'
import {Button, ButtonGroup, Col, Dropdown, Form, Row, Collapse, Card} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {MultiSelect} from 'react-multi-select-component'
import {useNavigate} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import redCross from '../../../img/remove.png'
import addWhite from '../../../img/add-white.png'

interface ButtonProps {
  show: any
  handleSubmitId: (message: string) => void
  handleClose: any
}

let selectedProperty: any = []

const SelectPropertyModal = ({handleClose, show, handleSubmitId}: ButtonProps) => {
  const navigate = useNavigate()

  var devInd: any = null
  var commInd: any = null
  var unitInd: any = null
  var buildInd: any = null
  var floorInd: any = null
  var clustbuildInd: any = null

  const [filteredData, setFilteredData] = useState<any>()
  const [unitDetails, setUnitDetails] = useState<any>()
  const [disableSelect, setDisableSelect] = useState<any>(true)

  const [devOptions, setDevOptions] = useState<any>()
  const [clustOptions, setClustOptions] = useState<any>()
  const [UGBOptions, setUGBOptions] = useState<any>()

  const [clustBuildOptions, setClustBuildOptions] = useState<any>()
  const [clustUnitsOptions, setClustUnitsOptions] = useState<any>()
  const [buildUnitsOptions, setBuildUnitsOptions] = useState<any>()

  var developmentOptions: any

  const [radioChecked, setRadioChecked] = useState<any>(-1)

  const [tenancyId, setTenancyId] = useState<any>(null)
  const [tenancyContract, setTenancyContract] = useState<any>()

  const [unitId, setUnitId] = useState<any>()

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
      // marginLeft: '27px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        //       color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '1000',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
      minWidth: '50px'
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      // display: 'flex',
      flexWrap: 'nowrap',
      maxWidth: '100%',
      overflowX: 'scroll',
    }),
    multiValue: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      whiteSpace: 'nowrap',
      marginRight: '4px',
      minWidth: '50px'
    }),
  }

  const customStylesInputFilled = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
      minWidth: '50px'
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #35475e',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: ' #5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      // display: 'flex',
      flexWrap: 'nowrap',
      maxWidth: '100%',
      overflowX: 'scroll',
    }),
    multiValue: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      whiteSpace: 'nowrap',
      marginRight: '4px',
      minWidth: '50px'
    }),
  }

  const [unitsTenancy, setUnitsTenancy] = useState<any>([])
  const [totalTenancies, setTotalTenancies] = useState<any>(0)

  // Property Selction
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
    reset,
  } = useForm<any>()

  const [collapse, setcollapse] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [type, setType] = useState('')
  const [devlopment, setdevlopment] = useState<any>([])
  const [formData1, setFormData1] = useState<any>({})

  const [date, setdate] = useState()
  const activeTab: any = localStorage.getItem('activeTab')
  const requestServiceId: any = localStorage.getItem('serviceRequestId' + activeTab)

  const [unitName, setUnitName] = useState<any>()

  const [cluster, setcluster] = useState([])
  const [flour, setflour] = useState<any>([])
  const [clusterflour, setclusterflour] = useState([])
  const [unit, setunit] = useState([])
  const [clusterunit, setclusterunit] = useState([])
  const [updateclusterunit, setupdateclusterunit] = useState([])

  const [clusterunitgroup, setclusterunitgroup] = useState([])
  const [clustertype, setclustertype] = useState<any>('')
  const [clusterbuilding, setclusterbuilding] = useState([])
  const [ChnageUGB, setChnageUGB] = useState()

  var clust: any
  const [buildingName, setBuildingName] = useState<any>('')
  var build: any
  const unitsData: any = localStorage.getItem('isUnit_' + requestServiceId)
  const [selected, setSelected] = useState<any>([])
  const [communityId, setCommunityId] = useState<any>()
  const [mixedClusterData, setMixedClusterData] = useState<any>()
  const [unitGroupData, setUnitGroupData] = useState<any>()
  const [floorName, setFloorName] = useState<any>()
  const [developmentName, setDevelopmentName] = useState<any>()
  const [buildingId, setBuildingId] = useState<any>()
  const [selectedUnitData, setSelectedUnitData] = useState<any>([])
  const currentTab: any = localStorage.getItem('currentTab')
  const [propertySummary, setPropertySummary] = useState<any>()
  const [propertyDevelopmentSummary, setPropertyDevelopmentSummary] = useState<any>()
  const [isEdit, setIsEdit] = useState<any>(false)
  const [datatoUpdate, setDatatoUpdate] = useState<any>()
  const [commId, setCommId] = useState<any>()
  const [clusterId, setClusterId] = useState<any>()
  const [unitGrp, setUnitGrp] = useState<any>()

  const [developType, setDevelopType] = useState<any>()
  const [clusterName, setClusterName] = useState<any>('')
  const [isComm, setIsComm] = useState<boolean>(false)
  const [isBuild, setIsBuild] = useState<boolean>(false)

  const [commDatatoUpdate, setCommDatatoUpdate] = useState<any>()
  const [buildingDataToUpdate, setBuildingDataToUpdate] = useState<any>()

  const pDetail: any = localStorage.getItem('propertyDetail_' + requestServiceId)
  // const properties: any = JSON.parse(pDetail)
  const [peopertyUnit, setPropertyUnit] = useState<any>(JSON.parse(pDetail))

  //  select clusture
  const handleChnageCluster = (e: any) => {
    setDisableSelect(true)
    setBuildingName('')
    // setType(value.split('/')[1].split('-')[0])
    const {label, value} = e
    clust = value.split('/')[1]
    setClusterName(value.split('/')[1])
    setFormData1({...formData1, [label]: value.split('/')[0]})
    let dte: any = cluster?.filter((v: any, index: any) => v._id == value.split('/')[0])
    // setcluster([])
    setclustertype(dte[0].type)
    if (dte[0].type == 0) {
      setDevelopType('isCommClust')
      callunitbyclusterid(value.split('/')[0])
    } else if (dte[0].type == 1) {
      setDevelopType('isCommClustBuild')
      callclusterbuilding(value.split('/')[0])
    } else if (dte[0].type == 2) {
      setDevelopType('isCommClustMixed')
      callclusterunitgroup(value.split('/')[0])
    }
    setclusterunit([])
    setclusterflour([])
    setclusterbuilding([])
    setclusterunitgroup([])
  }
  const handleChnageclusterbuilding = (e: any) => {
    setflour([])
    setclusterunit([])
    setSelectedUnitData([])
    floorInd = null
    setFloorName('')

    const {label, value} = e
    setBuildingName(value.split('/')[1])
    build = value.split('/')[1]
    setclusterunit([])
    setclusterflour([])
    // setclusterbuilding([])
    setBuildingId(value.split('/')[0])
    callflour(value.split('/')[0])
    setFormData1({...formData1, [label]: value.split('/')[0]})
  }
  const handleChnageclusterunit = (e: any) => {
    setBuildingName('')
    const {name, value} = e.target
    setclusterunit([])
    callclusterunitbyclusterflour(value)
    setFormData1({...formData1, [name]: value})
  }
  const handleChnageUGB = (e: any) => {
    const {label, value} = e
    setBuildingName(value.split('/')[1])
    build = value.split('/')[1]
    setclusterunit([])
    setclusterflour([])
    setclusterbuilding([])
    setclusterunitgroup([])
    setChnageUGB(value.split('/')[1]?.split(' ')[0])
    if (value.split('/')[1]?.split(' ')[0] == 'Building') {
      callclusterflour(value.split('/')[0])
      setFormData1({...formData1, ['Building']: value.split('/')[0]})
    } else if (value.split('/')[1]?.split(' ')[0] == 'Unit') {
      callunitbyunitgroupid(value.split('/')[0])
      setFormData1({...formData1, ['Unit']: value.split('/')[0]})
    }
  }

  const callunitbyunitgroupid = (value: any) => {
    ApiGet(`corporate/unit?unitGroupId=${value}`)
      .then((res) => {
        setclusterunit(res?.data?.data)

        let clustunit = res?.data?.data.map((f: any) => {
          return {value: `${f._id}/${f?.unitNo}`, label: `/${f?.unitNo}`}
        })

        setClustUnitsOptions(clustunit)

        setupdateclusterunit([])
        setDatatoUpdate([])
        setIsEdit(false)
      })
      .catch((err) => console.log('err', err))
  }

  const callclusterunitgroup = (value: any) => {
    setflour([])
    setClustUnitsOptions([])
    setBuildUnitsOptions([])

    const body = {
      communityId: communityId,
      clusterId: value,
    }
    ApiPost(`corporate/service_request/get_unit_list`, body)
      .then((res) => {
        setMixedClusterData(res?.data?.data?.communityData[0]?.cluster[0]?.building)
        setUnitGroupData(res?.data?.data?.communityData[0]?.cluster[0]?.unit_group)

        let ugb = [...mixedClusterData, ...unitGroupData]?.map((data: any) => {
          return {value: `${data._id}/${data?.name}`, label: data.name}
        })

        setUGBOptions(ugb)

        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callunitbyclusterid = async (value: any) => {
    // ApiGet(`corporate/unit?clusterId=${value}`)
    //   .then((res) => {
    //     setclusterunit(res?.data?.data)
    //     setupdateclusterunit([])
    //     setDatatoUpdate([])
    //     setIsEdit(false)
    //     // setHeaderB(res)
    //   })
    //   .catch((err) => console.log('err', err))
    const body = {
      communityId: communityId ? communityId : null,
      clusterId: value,
      serviceRequestId: requestServiceId,
    }

    await ApiPost(`corporate/service_request/get_unit_list`, body)
      .then((res) => {
        setclusterunit(res?.data?.data?.communityData[0]?.cluster[0]?.unit)

        let clustunit = res?.data?.data?.communityData[0]?.cluster[0]?.unit.map((f: any) => {
          return {value: `${f._id}/${f?.unitNo}`, label: `${f?.unitNo}`}
        })

        setClustUnitsOptions(clustunit)

        setupdateclusterunit([])
        setDatatoUpdate([])
        setIsEdit(false)
        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callclusterunitbyclusterflour = (value: any) => {
    ApiGet(`corporate/unit?floorId=${value}`)
      .then((res) => {
        console.log('res', res)

        setclusterunit(res?.data?.data)
        setupdateclusterunit([])
        setDatatoUpdate([])
        setIsEdit(false)
        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callclusterflour = (value: any) => {
    console.log('\nvalue', value)
    ApiGet(`corporate/floor?buildingId=${value}`)
      .then((res) => {
        setclusterflour(res?.data?.data?.map((i: any) => ({label: i?.name, value: i?._id})))
        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callclusterbuilding = (value: any) => {
    ApiGet(`corporate/building?clusterId=${value}`)
      .then((res) => {
        setclusterbuilding(res?.data?.data)

        let clustbuild = res?.data?.data.map((data: any, i: any) => {
          return {value: `${data._id}/${data?.name}`, label: data.name}
        })

        setClustBuildOptions(clustbuild)

        // setHeaderB(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  const handleChnageflour = (e: any) => {
    const {name, value} = e.target
    setFloorName(value.split('/')[1])
    // callunitbyflour(value.split('/')[0])
    setFormData1({...formData1, [name]: value.split('/')[0]})
  }

  //
  const callunitbyflour = (event: any) => {
    setTenancyId(null)
    setFloorName(event.label)
    setUnitName('')
    setSelectedUnitData([])
    // selected?.map((i: any, index: any) => {
    //   const body = {
    //     buildingId: buildingId,
    //     floorId: i?._id,
    //     communityId: communityId ? communityId : null,
    //     clusterId: formData1?.Cluster ? formData1?.Cluster : null,
    //     serviceRequestId: requestServiceId,
    //   }
    //   bodyFunction(body, index)
    // })
    const body = {
      buildingId: buildingId ? buildingId : null,
      floorId: event?.value,
      communityId: communityId ? communityId : null,
      clusterId: formData1?.Cluster ? formData1?.Cluster : null,
      serviceRequestId: null,
    }
    bodyFunction(body, 0)
  }

  const bodyFunction = async (body: any, index: any) => {
    console.log(type)
    if (type === 'C') {
      await ApiPost(`corporate/service_request/get_unit_list`, body)
        .then((res) => {
          let newData: any
          for (let i = 0; i < res?.data?.data?.communityData[0]?.cluster?.length; i++) {
            if (res?.data?.data?.communityData[0]?.cluster[i]?.building?.[0]?._id == buildingId) {
              newData = res?.data?.data?.communityData[0]?.cluster[i]?.building?.[0]?.floor
            }
          }
          // console.log(newData)
          let buildunit
          newData?.map((f: any) => {
            if (f?.unit?.length > 0) {
              buildunit = f?.unit?.map((unit: any) => {
                return {value: `${unit._id}/${unit.unitNo}`, label: `${unit.unitNo}`}
              })
            }
          })

          setBuildUnitsOptions(buildunit)
          // console.log(buildUnitsOptions)
          setSelectedUnitData((prev: any) => [...prev, newData?.[0]])
        })
        .catch((err) => console.log('err', err))
    } else {
      await ApiPost(`corporate/service_request/get_unit_list`, body)
        .then((res) => {
          // console.log('res?.data?.data++', res?.data)
          const newData = res?.data?.data?.buildingData[0]?.floor
          setSelectedUnitData((prev: any) => [...prev, newData[0]])

          let buildunit
          newData?.map((f: any) => {
            if (f?.unit?.length > 0) {
              buildunit = f?.unit?.map((unit: any) => {
                return {value: `${unit._id}/${unit.unitNo}`, label: `${unit.unitNo}`}
              })
            }
          })

          setBuildUnitsOptions(buildunit)
        })
        .catch((err) => console.log('err', err))
    }
  }

  const callcluster = (value: any) => {
    ApiGet(`corporate/cluster?communityId=${value}`)
      .then((res) => {
        setcluster(res?.data?.data)

        let clusttOptions = res?.data?.data.map((data: any, i: any) => {
          return {value: `${data._id}/${data.name}`, label: data.name}
        })

        setClustOptions(clusttOptions)
        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }
  const callflour = (value: any) => {
    // console.log('\nvalueee', value)
    ApiGet(`corporate/floor?buildingId=${value}`)
      .then((res) => {
        // setflour(res?.data?.data)
        // console.log('\nres.data.data', res.data.data)
        if (res?.data?.data?.length) {
          setflour(res?.data?.data?.map((i: any) => ({label: i?.name, value: i?._id})))
        }

        // setHeaderB(res)
      })
      .catch((err) => console.log('err', err))
  }

  // floors unit data
  const selectedUnits = async (id: any, floorId: any, e: any) => {
    selectedProperty = []
    if (selectedProperty.includes(id)) {
      selectedProperty = selectedProperty?.filter((item: any) => item !== id)
    } else {
      selectedProperty.push({
        communityId: communityId ? communityId : null,
        clusterId: formData1?.Cluster ? formData1?.Cluster : null,
        buildingId: buildingId ? buildingId : null,
        floorId: floorId ? floorId : null,
        unitGroupId: formData1?.Unit ? formData1?.Unit : null,
        unitId: id,
      })
    }

    const body = {
      serviceRequestId: requestServiceId,
      service_job: selectedProperty,
    }
    setOpen(true)

    const bodyAllunits = {
      serviceRequestId: requestServiceId,
      communityId: communityId ? communityId : null,
      clusterId: formData1?.Cluster ? formData1?.Cluster : null,
      buildingId: buildingId ? buildingId : null,
      floorId: floorId ? floorId : null,
      unitGroupId: formData1?.Unit ? formData1?.Unit : null,
      unitId: id,
    }

    if (e.target.checked === true) {
      await ApiPost(`corporate/service_job/multiple_add`, body)
        .then((res) => {
          setOpen(false)
          localStorage.setItem('isUnitAdded' + requestServiceId, '1')
          localStorage.setItem('isPropertySelected_' + requestServiceId, '1')

          if (peopertyUnit !== null) {
            localStorage.setItem('propertyDetail_' + requestServiceId, JSON.stringify(peopertyUnit))
          } else {
            // setPropertyUnit([{developmentName: developmentName, totalunit:  selectedProperty.length}])
            localStorage.setItem(
              'propertyDetail_' + requestServiceId,
              JSON.stringify([
                {developmentName: developmentName, totalunit: selectedProperty.length},
              ])
            )
          }

          getSummary()
          // navigate(-1)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      await ApiPost(`corporate/service_request/select_propertise/all_unit`, bodyAllunits)
        .then((res) => {
          // if simple cluster
          if (clustertype === 0) {
            console.log(res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit)
            let newData = res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit
            for (let i = 0; i < newData.length; i++) {
              if (id === newData[i]._id) newData[i].isCheck = false
            }

            let newVal: any = []
            let x = newData
            for (let i = 0; i < x.length; i++) {
              if (x[i].isCheck === true) {
                let m = x[i]._id
                newVal[newVal.length] = m
              }
            }

            const data = {
              serviceRequestId: requestServiceId,
              communityId: communityId ? communityId : null,
              clusterId: formData1?.Cluster ? formData1?.Cluster : null,
              buildingId: buildingId ? buildingId : null,
              floorId: null,
              unitGroupId: formData1?.Unit ? formData1?.Unit : null,
              unitIds: newVal,
            }

            console.log(data)
            ApiPost(`corporate/service_request/select_propertise/update`, data)
              .then((res) => {
                setOpen(false)

                getSummary()
              })
              .catch((e) => {
                ErrorToast(e.message)
                console.log(e)
              })
          }
          // cluster-> building
          else if (clustertype === 1) {
            let newData: any
            setOpen(false)

            if (res?.data?.data?.developmentList[0]?.community[0]?.cluster.length !== 0) {
              res?.data?.data?.developmentList[0]?.community[0]?.cluster.map((cl: any) => {
                if (cl?.building.length !== 0) {
                  cl?.building.map((bld: any) => {
                    if (bld?.floor?.length !== 0) {
                      bld?.floor.map((fl: any) => {
                        if (fl?.unit.length !== 0) {
                          console.log()
                          newData = fl?.unit
                        }
                      })
                    }
                  })
                }
              })
            }

            for (let i = 0; i < newData.length; i++) {
              if (id === newData[i]._id) newData[i].isCheck = false
            }

            let newVal: any = []
            let x = newData
            for (let i = 0; i < x.length; i++) {
              if (x[i].isCheck === true) {
                let m = x[i]._id
                newVal[newVal.length] = m
              }
            }

            const data = {
              serviceRequestId: requestServiceId,
              communityId: communityId ? communityId : null,
              clusterId: formData1?.Cluster ? formData1?.Cluster : null,
              buildingId: buildingId ? buildingId : null,
              floorId: null,
              unitGroupId: formData1?.Unit ? formData1?.Unit : null,
              unitIds: newVal,
            }

            console.log(data)
            ApiPost(`corporate/service_request/select_propertise/update`, data)
              .then((res) => {
                setOpen(false)

                getSummary()
              })
              .catch((e) => {
                ErrorToast(e.message)
                console.log(e)
              })
          } else {
            let newData = res?.data?.data?.developmentList[0]?.building[0]?.floor[0]?.unit

            for (let i = 0; i < newData.length; i++) {
              if (id === newData[i]._id) newData[i].isCheck = false
            }

            let newVal: any = []
            let x = newData
            for (let i = 0; i < x.length; i++) {
              if (x[i].isCheck === true) {
                let m = x[i]._id
                newVal[newVal.length] = m
              }
            }

            const data = {
              serviceRequestId: requestServiceId,
              communityId: communityId ? communityId : null,
              clusterId: formData1?.Cluster ? formData1?.Cluster : null,
              buildingId: buildingId ? buildingId : null,
              floorId: null,
              unitGroupId: formData1?.Unit ? formData1?.Unit : null,
              unitIds: newVal,
            }

            console.log(data)
            ApiPost(`corporate/service_request/select_propertise/update`, data)
              .then((res) => {
                setOpen(false)

                getSummary()
              })
              .catch((e) => {
                ErrorToast(e.message)
                console.log(e)
              })
          }
        })
        .catch()
    }
  }

  // clusters unit data
  const unitData = async (id: any, e: any) => {
    selectedProperty = []
    if (selectedProperty.includes(id) === true) {
      selectedProperty = selectedProperty?.filter((item: any) => item !== id)
    } else {
      selectedProperty.push({
        communityId: communityId ? communityId : null,
        clusterId: formData1?.Cluster ? formData1?.Cluster : null,
        buildingId: buildingId ? buildingId : null,
        floorId: null,
        unitGroupId: formData1?.Unit ? formData1?.Unit : null,
        unitId: id,
      })
    }

    const body = {
      serviceRequestId: requestServiceId,
      service_job: selectedProperty,
    }

    const bodyAllUnits = {
      serviceRequestId: requestServiceId,
      communityId: communityId ? communityId : null,
      clusterId: formData1?.Cluster ? formData1?.Cluster : null,
      buildingId: buildingId ? buildingId : null,
      floorId: null,
      unitGroupId: formData1?.Unit ? formData1?.Unit : null,
      unitId: id,
    }

    setOpen(true)
    if (e.target.checked === true) {
      await ApiPost(`corporate/service_job/multiple_add`, body)
        .then((res) => {
          setOpen(false)
          localStorage.setItem('isUnitAdded' + requestServiceId, '1')
          localStorage.setItem('isPropertySelected_' + requestServiceId, '1')

          if (peopertyUnit !== null) {
            localStorage.setItem('propertyDetail_' + requestServiceId, JSON.stringify(peopertyUnit))
          } else {
            // setPropertyUnit([{developmentName: developmentName, totalunit:  selectedProperty.length}])
            localStorage.setItem(
              'propertyDetail_' + requestServiceId,
              JSON.stringify([
                {developmentName: developmentName, totalunit: selectedProperty.length},
              ])
            )
          }

          getSummary()
          // navigate(-1)
        })
        .catch((e) => {
          console.log(e)
        })
    } else {
      await ApiPost(`corporate/service_request/select_propertise/all_unit`, bodyAllUnits)
        .then((res) => {
          // console.log(res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit)
          let newData = res?.data?.data?.developmentList[0]?.community[0]?.cluster[0]?.unit
          for (let i = 0; i < newData.length; i++) {
            if (id === newData[i]._id) newData[i].isCheck = false
          }

          let newVal: any = []
          let x = newData
          for (let i = 0; i < x.length; i++) {
            if (x[i].isCheck === true) {
              let m = x[i]._id
              newVal[newVal.length] = m
            }
          }

          const data = {
            serviceRequestId: requestServiceId,
            communityId: communityId ? communityId : null,
            clusterId: formData1?.Cluster ? formData1?.Cluster : null,
            buildingId: buildingId ? buildingId : null,
            floorId: null,
            unitGroupId: formData1?.Unit ? formData1?.Unit : null,
            unitIds: newVal,
          }

          console.log(data)
          ApiPost(`corporate/service_request/select_propertise/update`, data)
            .then((res) => {
              setOpen(false)

              getSummary()
            })
            .catch((e) => {
              ErrorToast(e.message)
              console.log(e)
            })
        })
        .catch()
    }
  }

  const moveVendor = () => {
    navigate(`/request-management/service/vendor-list`)
    localStorage.setItem('inter', 'false')
  }

  const getSummary = async () => {
    const body = {
      serviceRequestId: requestServiceId,
    }
    await ApiPost(`corporate/service_request/select_propertise/summery`, body)
      .then((res) => {
        setPropertySummary(res?.data?.data)
        setPropertyDevelopmentSummary(res?.data?.data?.developmentList)
      })
      .catch()
  }

  // development/first-drop-down selection
  const handleChnage12 = (e: any) => {
    setDisableSelect(true)
    setBuildingName('')
    setDevelopmentName('')
    setClusterName('')
    setType('')
    setclustertype('')
    setclusterunit([])
    setSelectedUnitData([])

    const {label, value} = e
    // console.log('\nname 49', name, value)
    setFormData1({...formData1, [label]: value.split('/')[0]})
    setType(value.split('/')[1].split('-')[0])
    setDevelopmentName(value.split('/')[2])

    // if community is selected
    if (value.split('/')[1].split('-')[0] == 'C') {
      setclusterunit([])
      setclusterflour([])
      setclusterbuilding([])
      setclusterunitgroup([])
      callcluster(value.split('/')[0])
      setCommunityId(value.split('/')[0])
      setBuildingId('')
      // console.log('\n check 61', value.split('/')[1].split('-')[0])
      setIsComm(true)
      setIsBuild(false)
    }
    // if building is selected
    else if (value.split('/')[1].split('-')[0] == 'B') {
      callflour(value.split('/')[0])
      setunit([])
      setflour([])
      setBuildingId(value.split('/')[0])
      setCommunityId('')
      setIsComm(false)
      setIsBuild(true)
    }
  }

  // get All the development list either community or building i.e. first dropdown-list
  const callDevelopment = () => {
    ApiGet(`corporate/communities/get_development`)
      .then((res) => {
        setdevlopment(res?.data?.data)

        developmentOptions = res?.data?.data.map((data: any, i: any) => {
          return {value: `${data._id}/${data?.id}/${data.name}`, label: data.name}
        })

        setDevOptions(developmentOptions)

        // setHeaderB(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  // get tenancy
  const getTenancy = (event: any) => {
    setTenancyContract('')

    let e = event.value.split('/')[0]
    console.log(e)

    setUnitName(event.label)

    setUnitId(e)
    const body = {
      unitId: e,
    }
    ApiPost(`corporate/tenancy/get/payment`, body)
      .then((res) => {
        setDisableSelect(true)
        setUnitsTenancy(res?.data?.data)
        setFilteredData(res?.data?.data)
        setTotalTenancies(res?.data?.data?.length)
        if (res?.data?.data?.length === 0) {
          // setDisableSelect(false)
        }
        getUnitDetails(e)

      })
      .catch((err) => console.log('err', err))
  }

  // get unit details
  const getUnitDetails = (id: any) => {
    ApiGet(`corporate/unit/${id}`)
      .then((res) => {
        setUnitDetails(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  const [searchDevelopment, setSearchDevelopment] = useState<any>()
  const [searchUnitNo, setSearchUnitNo] = useState<any>()
  const [result, setResult] = useState<any>()
  const [floorsIDs, setFloorIDs] = useState<any>([])
  const [selectedId, setSelectedId] = useState<any>()

  const getTenancyList = async (e: any) => {
    let build: any = []
    if (buildingId) build[0] = buildingId

    let comm: any = []
    if (communityId) comm[0] = communityId

    let clust: any = []
    if (formData1?.Cluster) clust[0] = formData1?.Cluster

    let unitGp: any = []
    if (formData1?.Unit) unitGp[0] = formData1?.Unit

    let temp: any = []
    for (let i = 0; i < e.length; i++) {
      temp[i] = e[i].value
    }

    setFloorIDs(temp)

    const body = {
      searchDevelopment: searchDevelopment,
      searchUnitNo: searchUnitNo,
      communityIds: comm,
      buildingIds: build,
      clusterIds: clust,
      unitGroupIds: unitGp,
      floorIds: temp,
    }
    await ApiPost(`corporate/unit/get/property_list`, body)
      .then((res) => {
        setResult(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    updateList()
  }, [searchDevelopment, searchUnitNo, formData1, communityId, buildingId, formData1?.Unit])

  const updateList = async () => {
    let build: any = []
    if (buildingId) build[0] = buildingId

    let comm: any = []
    if (communityId) comm[0] = communityId

    let clust: any = []
    if (formData1?.Cluster) clust[0] = formData1?.Cluster

    let unitGp: any = []
    if (formData1?.Unit) unitGp[0] = formData1?.Unit

    const body = {
      searchDevelopment: searchDevelopment,
      searchUnitNo: searchUnitNo,
      communityIds: comm,
      buildingIds: build,
      clusterIds: clust,
      unitGroupIds: unitGp,
      floorIds: floorsIDs,
      disableCommonArea: true
    }
    await ApiPost(`corporate/unit/get/property_list`, body)
      .then((res) => {
        setResult(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    setDevelopmentName('')
    setClusterName('')
    setUnitName('')
    setBuildingName('')
    setFloorName('')
    setUnitName('')
  }, [])

  useEffect(() => {
    // callunitbyflour()
  }, [selected])

  useEffect(() => {
    setSelected([])
    setclusterunit([])
  }, [developmentName])

  React.useEffect(() => {
    callDevelopment()
  }, [])

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <Row>
          <div
            className='card p-5'
            style={{
              boxShadow: '0px 0px 20px 0px #516176',

              zIndex: '1',
              borderRadius: '0px',
            }}
          >
            <div className='d-flex align-items-center'>
              <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                Select Property
              </h2>

              <div className='d-flex ms-auto'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3  red-hollow-btn'
                  onClick={() => {
                    handleClose()
                  }}
                >
                  <img src={redCross} height={16} width={16} style={{marginRight: '10px'}} /> Cancel
                </button>

                <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3  select-btn'
                  onClick={() => {
                    handleSubmitId(selectedId)
                    handleClose()
                  }}
                >
                  <img src={addWhite} height={20} width={20} style={{marginRight: '10px'}} /> Add
                </button>
              </div>
            </div>

            <div className='row mt-4'>
              <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='labl-gry fs-5 mt-3'>
                  {' '}
                  Search
                </label>
              </div>
              <div className='col-11'>
                <div
                  className='align-items-end row'
                  style={{
                    // justifyContent: 'flex-start',
                    paddingLeft: '0px',
                    borderBottom: '0px',
                  }}
                >
                  <Col md={3} className='mb-5'>
                    <input
                      type='text'
                      className='form-control form-control-solid mytest filter-control'
                      name='contractNo'
                      value={searchDevelopment}
                      onChange={(e: any) => {
                        setSearchDevelopment(e.target.value)
                      }}
                      placeholder={'Development'}
                    />
                  </Col>

                  <Col md={3} className='mb-5'>
                    <input
                      type='text'
                      className='form-control form-control-solid mytest filter-control '
                      name='contractNo'
                      value={searchUnitNo}
                      onChange={(e: any) => {
                        setSearchUnitNo(e.target.value)
                      }}
                      placeholder={'Unit'}
                    />
                  </Col>
                </div>
              </div>
            </div>

            <div className='row mt-3'>
              <div className='col-1'>
                {' '}
                <label style={{width: '100px'}} className='labl-gry fs-5 mt-3'>
                  {' '}
                  Filter
                </label>
              </div>
              <div className='col-11'>
                <form>
                  <div
                    className='align-items-end row'
                    style={{
                      // justifyContent: 'flex-start',
                      paddingLeft: '0px',
                      borderBottom: '0px',
                    }}
                  >
                    {/* Development */}
                    <Col md={3} className='mb-5'>
                      <div className='d-flex align-items-center'>
                        {devOptions?.length > 0 &&
                          devOptions.map((item: any, index: any) => {
                            if (item.label == developmentName && item.label?.trim()?.length != 0) devInd = index
                          })}

                        {devInd ? (
                          <Select
                            defaultValue={devOptions[devInd]}
                            options={devOptions}
                            styles={customStylesInputFilled}
                            isSearchable={true}
                            onChange={(e) => handleChnage12(e)}
                          />
                        ) : (
                          <Select
                            options={devOptions}
                            styles={customStyles}
                            isSearchable={true}
                            onChange={(e) => handleChnage12(e)}
                            placeholder={'Search Development...'}
                          />
                        )}
                      </div>
                    </Col>
                    {type === 'C' && (
                      <>
                        <Col md={3} className='mb-5'>
                          <div className='d-flex align-items-center '>
                            {/* <label
                                 htmlFor='exampleFormControlInput1 me-5'
                                 className='form-label'
                                 style={{minWidth: '105px', color: '#a0a0b4'}}
                               >
                                 Cluster
                               </label> */}

                            {clustOptions?.length > 0 &&
                              clustOptions.map((item: any, index: any) => {
                                if (item.label == clusterName) commInd = index
                              })}
                            {commInd != null ? (
                              <Select
                                defaultValue={clustOptions[commInd]}
                                onChange={(e) => handleChnageCluster(e)}
                                options={clustOptions}
                                styles={customStylesInputFilled}
                                isSearchable={true}
                              />
                            ) : (
                              <Select
                                value={clust}
                                onChange={(e) => handleChnageCluster(e)}
                                options={clustOptions}
                                styles={customStyles}
                                isSearchable={true}
                                placeholder={'Search Cluster...'}
                              />
                            )}
                          </div>
                        </Col>
                        {clustertype == 2 && mixedClusterData ? (
                          <Col md={3} className='mb-5'>
                            <div className='d-flex align-items-center '>
                              <Select
                                placeholder={'UG/B'}
                                onChange={(e) => handleChnageUGB(e)}
                                options={[...mixedClusterData, ...unitGroupData]?.map(
                                  (data: any, i: any) => {
                                    return {
                                      value: `${data._id}/${data?.name}`,
                                      label: data.name,
                                    }
                                  }
                                )}
                                styles={customStyles}
                                isSearchable={true}
                              />
                            </div>
                          </Col>
                        ) : null}
                        {clustertype == 1 ? (
                          <Col md={3} className='mb-5'>
                            <div className='d-flex align-items-center '>
                              {clustBuildOptions?.length > 0 &&
                                clustBuildOptions.map((item: any, index: any) => {
                                  if (item.label == buildingName) clustbuildInd = index
                                })}
                              {clustbuildInd != null ? (
                                <Select
                                  value={build}
                                  defaultValue={clustBuildOptions[clustbuildInd]}
                                  onChange={(e) => handleChnageclusterbuilding(e)}
                                  options={clustBuildOptions}
                                  styles={customStylesInputFilled}
                                  isSearchable={true}
                                />
                              ) : (
                                <Select
                                  placeholder={'Search Building...'}
                                  value={build}
                                  onChange={(e) => handleChnageclusterbuilding(e)}
                                  options={clustBuildOptions}
                                  styles={customStyles}
                                  isSearchable={true}
                                />
                              )}
                            </div>
                          </Col>
                        ) : null}
                        {clustertype == 1 || (clustertype == 2 && ChnageUGB == 'Building') ? (
                          <Col md={3} className='mb-5'>
                            <div className=' d-flex align-items-center '>
                              {/* <label
                                   htmlFor='exampleFormControlInput1 me-5'
                                   className='form-label'
                                   style={{minWidth: '105px', color: '#a0a0b4'}}
                                 >
                                   Floor
                                 </label> */}

                              {flour?.length > 0 &&
                                flour.map((item: any, index: any) => {
                                  if (item.label == floorName) floorInd = index
                                })}

                              {floorInd != null ? (
                                <Select
                                  defaultValue={flour[floorInd]}
                                  onChange={(event: any) => {
                                    getTenancyList(event)
                                    callunitbyflour(event)
                                  }}
                                  options={flour}
                                  styles={customStylesInputFilled}
                                  isSearchable={true}
                                  isMulti={true}
                                />
                              ) : (
                                <Select
                                  placeholder={'Search Floor...'}
                                  // value={selected}
                                  onChange={(event: any) => {
                                    getTenancyList(event)
                                    callunitbyflour(event)
                                  }}
                                  options={flour}
                                  styles={customStyles}
                                  isSearchable={true}
                                  isMulti={true}
                                />
                              )}

                              {/* <MultiSelect
                                   options={flour}
                                   value={selected}
                                   onChange={setSelected}
                                   labelledBy='Select'
                                 />
                                 {errors.floor && (
                                   <p style={{color: 'var(--kt-danger)'}}>
                                     This field is required
                                   </p>
                                 )} */}
                            </div>
                          </Col>
                        ) : null}
                      </>
                    )}
                    {type === 'B' && (
                      <>
                        <Col md={3} className='mb-5'>
                          <div className='d-flex align-items-center'>
                            {/* <MultiSelect
                                 options={flour}
                                 value={selected}
                                 onChange={setSelected}
                                 labelledBy='Select'
                               /> */}

                            {flour?.length > 0 &&
                              flour.map((item: any, index: any) => {
                                if (item.label == floorName) floorInd = index
                              })}

                            {floorInd != null ? (
                              <Select
                                defaultValue={flour[floorInd]}
                                onChange={(event: any) => {
                                  getTenancyList(event)
                                  callunitbyflour(event)
                                }}
                                options={flour}
                                className='basic-multi-select'
                                classNamePrefix='select'
                                styles={customStylesInputFilled}
                                isSearchable={true}
                                isMulti={true}
                              />
                            ) : (
                              <Select
                                placeholder={'Search Floor...'}
                                onChange={(event: any) => {
                                  getTenancyList(event)
                                  callunitbyflour(event)
                                }}
                                options={flour}
                                className='basic-multi-select'
                                classNamePrefix='select'
                                styles={customStyles}
                                isSearchable={true}
                                isMulti={true}
                              />
                            )}

                            {errors.flour && (
                              <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                            )}
                          </div>
                        </Col>
                      </>
                    )}

                    {clusterunit.length > 0 && (
                      <></>
                      // <Col md={6} className='mb-5'>
                      //   <div className='min-w-200px d-flex align-items-center justify-content-start ps-3'>
                      //     <label
                      //       htmlFor='exampleFormControlInput1 me-5'
                      //       className='form-label'
                      //       style={{minWidth: '105px', color: '#a0a0b4'}}
                      //     >
                      //       Units
                      //     </label>

                      //     {clustUnitsOptions?.length > 0 &&
                      //       clustUnitsOptions.map((item: any, index: any) => {
                      //         if (item.label == unitName) unitInd = index
                      //       })}
                      //     {unitInd != null ? (
                      //       <Select
                      //         defaultValue={clustUnitsOptions[unitInd]}
                      //         options={clustUnitsOptions}
                      //         styles={customStylesInputFilled}
                      //          isSearchable={true}
                      //         onChange={(e) => getTenancy(e)}
                      //       />
                      //     ) : (
                      //       <Select
                      //         options={clustUnitsOptions}
                      //         styles={customStyles}
                      //          isSearchable={true}
                      //         onChange={(e) => getTenancy(e)}
                      //       />
                      //     )}
                      //   </div>
                      // </Col>
                    )}

                    {/* Buildings */}
                    {selectedUnitData.length > 0 && (
                      <></>
                      // <Col md={6} className='mb-5'>
                      //   <div className='min-w-200px d-flex align-items-center justify-content-start ps-3'>
                      //     <label
                      //       htmlFor='exampleFormControlInput1 me-5'
                      //       className='form-label'
                      //       style={{minWidth: '105px', color: '#a0a0b4'}}
                      //     >
                      //       Units
                      //     </label>
                      //     {/* <select className='form-select form-select-solid'>
                      //     <option selected value=''>
                      //       Select
                      //     </option>
                      //     {selectedUnitData?.map((f: any, i: any) => {
                      //       return (
                      //         <>
                      //           {f?.unit?.length > 0 &&
                      //             f?.unit?.map((unit: any) => (
                      //               <option value={`${unit._id}/${unit?.unitNo}`}>
                      //                 {unit?.unitNo}
                      //               </option>
                      //             ))}
                      //         </>
                      //       )
                      //     })}

                      //     {errors.building && (
                      //       <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                      //     )}
                      //   </select> */}

                      //     {buildUnitsOptions?.length > 0 &&
                      //       buildUnitsOptions.map((item: any, index: any) => {
                      //         if (item.label == unitName) unitInd = index
                      //       })}
                      //     {unitInd != null ? (
                      //       <Select
                      //         defaultValue={buildUnitsOptions[unitInd]}
                      //         options={buildUnitsOptions}
                      //         styles={customStylesInputFilled}
                      //          isSearchable={true}
                      //         onChange={(e) => getTenancy(e)}
                      //       />
                      //     ) : (
                      //       <Select
                      //         options={buildUnitsOptions}
                      //         styles={customStyles}
                      //         isSearchable={false}
                      //         onChange={(e) => getTenancy(e)}
                      //       />
                      //     )}
                      //   </div>
                      // </Col>
                    )}
                  </div>
                </form>
              </div>
            </div>

            <div className='row mt-3 mx-0' style={{borderTop: '2px solid lightgrey'}}></div>

            <div className='row mt-3'>
              <div className='col-12 pe-0'>
                <div className='table-responsive ' style={{maxHeight: '400px'}}>
                  <table className='table align-middle  fs-6 gy-5' id='kt_ecommerce_sales_table'>
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                        <th className='text-start w-25px'></th>
                        <th className='text-start min-w-100px'>Development</th>
                        <th className='text-start min-w-100px'>Unit</th>
                        <th className='text-start min-w-100px'>Tenancy Status</th>
                      </tr>
                    </thead>

                    <tbody className='fw-semibold text-gray-600'>
                      {result?.map((rs: any, index: any) => {
                        return (
                          <tr>
                            <td className='text-start w-25px'>
                              {radioChecked === index ? (
                                <div
                                  style={{
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: '#146c6a',
                                    borderRadius: '50%',
                                  }}
                                  className='me-5'
                                ></div>
                              ) : (
                                <div
                                  style={{
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: 'transparent',
                                    borderRadius: '50%',
                                    border: '1px solid #146c6a',
                                  }}
                                  onClick={() => {
                                    setRadioChecked(index)
                                    setSelectedId(rs?._id)
                                  }}
                                  className='me-5'
                                ></div>
                              )}
                            </td>
                            <td className='text-start min-w-100px'>
                              {rs?.community?.length > 0
                                ? `${rs?.community?.[0]?.name}`
                                : `${rs?.building?.[0]?.name}`}
                            </td>
                            <td className='text-start min-w-100px'>
                              {`${rs?.unitNo > 0}` ? `${rs?.unitNo}` : `-`}
                            </td>
                            <td className='text-start min-w-100px'>
                              <div
                                className={
                                  rs?.tenancyStatus == 0
                                    ? 'status booked-bar'
                                    : rs?.tenancyStatus == 1
                                    ? 'status active-bar'
                                    : rs?.tenancyStatus == 2
                                    ? 'status expiring-bar'
                                    : rs?.tenancyStatus == 3
                                    ? 'status expired-bar'
                                    : 'status  no-st-bar'
                                }
                              >
                                {rs?.tenancyStatus == 0
                                  ? 'Booked'
                                  : rs?.tenancyStatus == 1
                                  ? 'Active'
                                  : rs?.tenancyStatus == 2
                                  ? 'Expiring'
                                  : rs?.tenancyStatus == 3
                                  ? 'Expired'
                                  : '-'}
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Modal>
    </div>
  )
}

export default SelectPropertyModal
