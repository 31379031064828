import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ApiGet, ApiPost, ApiPut } from '../../../apiCommon/helpers/API/ApiData';
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast';

function CreateFacility() {
    const navigate = useNavigate()
    const [formData, setFormData] = useState<any>({})
    const [mainServiceData, setMainServiceData] = useState<any>()
    const [subServiceData, setSubServiceData] = useState<any>()

    const getmainServiceList = () => {
        ApiGet(`corporate/main_service`)
            .then((res) => {
                setMainServiceData(res?.data?.data)
            })
            .catch((err) => ErrorToast(err.message))
    }

    const getSubServiceData = () => {
        ApiGet(`corporate/sub_service`)
            .then((res) => {
                setSubServiceData(res?.data?.data)
            })
            .catch((err) => ErrorToast(err.message))
    }

    React.useEffect(() => {
        getmainServiceList()
        getSubServiceData()
    }, [])

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const handleAddServices = () => {
        if (formData?.mainServiceList !== undefined && formData?.subServiceList !== undefined) {
            const data = {
                id: formData?.subServiceList?.split('/')[0],
                name: formData?.subServiceList ? formData?.subServiceList?.split('/')[1] : formData?.subNewServiceList,
                note: formData?.note,
                serviceIds: [formData?.mainServiceList]
            }
            ApiPut(`corporate/sub_service`, data)
                .then((res) => {
                    SuccessToast(res?.data.message)
                    navigate('/facility')
                })
                .catch((err) => ErrorToast(err.message))
        } else if (formData?.mainServiceList !== undefined) {
            const data = {
                name: formData?.subServiceList ? formData?.subServiceList?.split('/')[1] : formData?.subNewServiceList,
                note: formData?.note,
                serviceIds: [formData?.mainServiceList]
            }
            ApiPost(`corporate/sub_service`, data)
                .then((res) => {
                    SuccessToast(res?.data.message)
                    navigate('/facility')
                })
                .catch((err) => ErrorToast(err.message))
        }
        else if (formData?.mainServiceList !== undefined || formData?.subNewServiceList !== undefined) {
            if (formData?.mainNewServiceList === undefined) {
                ErrorToast('without main service can not create subservice')
            } else {
                const body = {
                    name: formData?.mainServiceList ? formData?.mainServiceList : formData?.mainNewServiceList ? formData?.mainNewServiceList : '',
                    note: formData?.note,
                    type: 0
                }
                ApiPost(`corporate/main_service`, body)
                    .then((res) => {
                        const data = {
                            name: formData?.subServiceList ? formData?.subServiceList?.split('/')[1] : formData?.subNewServiceList,
                            note: formData?.note,
                            serviceIds: [res?.data?.data?._id]
                        }
                        ApiPost(`corporate/sub_service`, data)
                            .then((res) => {
                                SuccessToast(res?.data.message)
                                navigate('/facility')
                            })
                            .catch((err) => ErrorToast(err.message))

                    })
                    .catch((err) => ErrorToast(err.message))
            }


        } else if (formData?.mainNewServiceList !== undefined && formData?.subServiceList !== undefined) {
            const body = {
                name: formData?.mainServiceList ? formData?.mainServiceList : formData?.mainNewServiceList ? formData?.mainNewServiceList : '',
                note: formData?.note,
                type: 0
            }
            ApiPost(`corporate/main_service`, body)
                .then((res) => {

                    if (res?.data?.data?._id && formData?.subServiceList?.split('/')[1] !== undefined) {
                        const data = {
                            id: formData?.subServiceList?.split('/')[0],
                            name: formData?.subServiceList ? formData?.subServiceList?.split('/')[1] : formData?.subNewServiceList,
                            note: formData?.note,
                            serviceIds: [res?.data?.data?._id]
                        }
                        ApiPut(`corporate/sub_service`, data)
                            .then((res) => {
                                SuccessToast(res?.data.message)
                                navigate('/facility')
                            })
                            .catch((err) => ErrorToast(err.message))
                    } else {
                        SuccessToast(res?.data.message)
                        navigate('/facility')
                    }
                })
                .catch((err) => ErrorToast(err.message))
        }

    }

    return (
        <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
            <div id='' className='app-container container-xxl pt-0 mt-0'>
                <div className='d-flex align-items-baseline flex-wrap mr-5'>
                    <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
                        <li className='breadcrumb-item '>
                            <a
                                className='text-muted px-2 cursor-pointer'
                                onClick={() => navigate('/facility')}
                            >
                                Services
                            </a>
                        </li>
                        <li className='breadcrumb-item active'>
                            <a className='px-2'> Add New Services</a>
                        </li>
                    </ul>
                </div>
                <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-5'>
                    Add New Service
                </h1>
                <div className='d-flex flex-stack pt-3 mt-0 mb-10'>
                    <div className=''>
                        <a
                            // onClick={() => setShowCreateAppModal(true)}
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_create_app'
                            onClick={() => navigate('/facility')}
                        // onClick={() => {
                        //     navigate(-1)
                        // }}
                        >
                            Back
                        </a>
                    </div>
                    <div className=''>
                        <a
                            onClick={handleAddServices}
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            data-bs-toggle='modal'
                            data-bs-target='#kt_modal_create_app'
                        >
                            Add
                        </a>
                    </div>
                </div>
                <div className='card card-flush app-container py-5'>
                    <div className='mb-10 min-w-200px d-flex align-items-center flex-wrap justify-content-between'>
                        <label htmlFor='exampleFormControlInput1' className='title form-label m-0 me-5'>
                            Main Service
                        </label>
                        <div className="d-flex align-items-center">
                            <label htmlFor='exampleFormControlInput1' className='required min-w-100px form-label m-0'>
                                Service List
                            </label>
                            <select
                                name='mainServiceList'
                                className='form-select form-select-solid'
                                onChange={handleChnage}
                            >
                                <option selected value=''>
                                    Select
                                </option>
                                {mainServiceData?.map((data: any) => (
                                    <option value={data?._id}>{data.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex align-items-center">
                            <label htmlFor='exampleFormControlInput1' className='min-w-100px form-label m-0'>
                                New Service
                            </label>
                            <input
                                type='text'
                                className='form-control form-control-solid'
                                placeholder='New Service'
                                name='mainNewServiceList'
                                onChange={handleChnage}
                                disabled={formData?.mainServiceList ? true : false}
                            />
                        </div>
                    </div>
                    <div className='mb-10 service_list min-w-200px d-flex align-items-center flex-wrap justify-content-between'>
                        <label htmlFor='exampleFormControlInput1' className='form-label m-0 me-5'>
                            Sub-Service
                        </label>
                        <div className="d-flex align-items-center me-10 mb-3">
                            <label htmlFor='exampleFormControlInput1' className='required min-w-100px form-label m-0'>
                                Service List
                            </label>
                            <select
                                name='subServiceList'
                                className='form-select form-select-solid'
                                onChange={handleChnage}
                            >
                                <option selected value=''>
                                    Select
                                </option>
                                {subServiceData?.map((data: any) => (
                                    <option value={`${data._id}/${data.name}`}>{data.name}</option>
                                ))}
                            </select>
                        </div>
                        <div className="d-flex align-items-center mb-3">
                            <label htmlFor='exampleFormControlInput1' className='min-w-100px form-label m-0'>
                                New Service
                            </label>
                            <input
                                type='text'
                                className='form-control form-control-solid'
                                placeholder='New Service'
                                onChange={handleChnage}
                                name='subNewServiceList'
                                disabled={formData?.subServiceList ? true : false}
                            />
                        </div>
                    </div>
                    <Col md={6} className="d-flex me-10">
                        <label htmlFor='exampleFormControlInput1' className='required min-w-100px form-label m-0'>
                            Notes
                        </label>
                        <textarea
                            className='form-control form-control-solid'
                            placeholder='Type Your Notes....'
                            name='note'
                            onChange={handleChnage}
                        />
                    </Col>
                </div>
            </div>
        </div>
    );
}

export default CreateFacility;