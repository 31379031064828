import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Col, Row} from 'react-bootstrap'
import {useNavigate, useLocation} from 'react-router-dom'
import addGreen from '../../../../img/add.png'
import RecordAPayment from '../Expenses/RecordAPayment'
import pdfIcon from '../../../../img/pdf.svg'
import {Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import trashImg from '../../../../img/trash.png'
import openImg from '../../../../img/black-collap-down.png'
import closeImg from '../../../../img/black-collap-up.png'
import ViewEditPaymentRecord from '../Expenses/ViewEditPaymentRecord'
import swal from 'sweetalert2'
import {ApiPost} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import {useParams} from 'react-router-dom'
import axios from 'axios'
import AttachmentPreview from '../../General/AttachmentsPreview'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import fileIcon from '../../../../img/file.svg'
import videoImg from '../../../../img/video.png'
import pngImg from '../../../../img/png.png'
import invoiceGreen from '../../../../img/invoice-green.png'

interface CreditNoteProps {
  showw?: any
  handleClose?: any
  expenseData?: any
  updateParent?: any
  autoSaveParent?: any
  updateItemsInParent?: any
  refreshChild?: any
  selectedDev?: any
  autoUpdateParent?: any
  updateParentAfterDelete?: any
}

const CreditNote: React.FC<CreditNoteProps> = ({
  showw,
  handleClose,
  expenseData,
  updateParent,
  autoSaveParent,
  updateItemsInParent,
  refreshChild,
  selectedDev,
  autoUpdateParent,
  updateParentAfterDelete,
}) => {
  const {state} = useLocation()

  const {flg, type, id, tenancyId, unitId} = useParams()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [paymentRecords, setPaymentRecords] = useState<any>(
    []
    // expenseData?.paymentRecord ? expenseData?.paymentRecord : []
  )
  const [showRecordAPayment, setShowRecordAPayment] = useState<any>(false)
  const [show, setShow] = useState<any>([])

  const [selectedInd, setSelectedInd] = useState<any>(-1)
  const [selectedRecord, setSelectedRecord] = useState<any>()
  const [showEditAPayment, setShowEditAPayment] = useState<any>(false)

  // console.log('/*******************Payment Records********************/')
  // console.log(expenseData)

  useEffect(() => {
    setPaymentRecords(expenseData?.paymentRecords?.length > 0 ? expenseData?.paymentRecords : [])
  }, [expenseData])

  const [selectedDevelopment, setSelectedDevelopment] = useState<any>()

  useEffect(() => {
    // console.log(selectedDev)
    setSelectedDevelopment(selectedDev)
  }, [selectedDev])

  const deletePaymentRecord = (val: any, index: any) => {
    const body = {
      ids: [val],
      expenseId: id,
    }
    ApiPost(`corporate/expense_payment_record/delete`, body)
      .then((res: any) => {
        SuccessToast('Payment Record has been deleted successfully')
        let values = [...paymentRecords]
        values.splice(index, 1)
        setPaymentRecords(values)

        updateParentAfterDelete()
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  let [itemsForPayments, setItemsForPayments] = useState<any>([])

  const [attachmentsForPreview, setAttachmentsForPreview] = useState<any>([])

  const [itemsError, setItemsError] = useState<any>(false)
  const [noItemsError, setNoItemsError] = useState<any>(false)

  useEffect(() => {
    let values: any = []
    {
      expenseData?.paymentRecords?.map((inv: any, index: any) => {
        values[index] = {
          type: '',
          url: `${Bucket}${inv?.receipt?.URL}`,
          thumbnail: '',
        }

        if (inv?.URL?.split('.').pop() === 'pdf') values[index].type = 'pdf'
        else if (inv?.URL?.split('.').pop() === 'doc' || inv?.URL?.split('.').pop() === 'docx')
          values[index].type = 'doc'
        else if (
          inv?.URL?.split('.').pop() === 'jpeg' ||
          inv?.URL?.split('.').pop() === 'jpg' ||
          inv?.URL?.split('.').pop() === 'png' ||
          inv?.URL?.split('.').pop() === 'svg' ||
          inv?.URL?.split('.').pop() === 'gif'
        )
          values[index].type = 'image'
        else if (
          inv?.URL?.split('.').pop()?.toLowerCase() === 'mp4' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'webm' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'avi' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'mkv' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'mov' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'flv' ||
          inv?.URL?.split('.').pop()?.toLowerCase() === 'wmv'
        )
          values[index].type = 'video'
        else values[index].type = 'file'
      })
    }

    setAttachmentsForPreview(values)
  }, [expenseData])

  useEffect(() => {
    if (itemsError) {
      let err: any = false

      expenseData?.items?.map((itm: any, ind: any) => {
        if (
          itm?.name?.trim()?.length == 0 ||
          itm?.amount != '' ||
          itm?.quantity != '' ||
          itm?.VATPercentage != '' ||
          itm?.property == '' ||
          itm?.property == undefined ||
          itm?.property == 'undefined'
        )
          err = true
      })
      if (!err) setItemsError(false)
    }
  }, [expenseData])

  //
  // const handleImageClick = (val: any, src: any, lst: any, lstInd:any) => {

  //   {
  //     lst?.slice(1)?.map((receipt: any, idx: any) => {
  //       console.log(`${lstInd}${idx}`)
  //       const imgeNext = document.getElementById(`${lstInd}${idx}`)
  //       console.log(imgeNext)
  //       console.log(receipt?.URL)

  //       if (imgeNext) {
  //         imgeNext.setAttribute('data-fancybox', '')
  //         imgeNext.setAttribute('data-src', `${Bucket}${receipt?.URL}`)
  //       }
  //     })
  //   }

  //   console.log(lst)
  //   // Add data-fancybox attribute to the clicked image
  //   const image = document.getElementById(val)
  //   if (image) {
  //     image.setAttribute('data-fancybox', '')
  //     image.setAttribute('data-src', src)
  //   }

  // }

  const handleImageClick = (val: any, src: any, lst: any, lstInd: any) => {
    // Generate a unique group name for fancybox
    const groupName = `group_${lstInd}`

    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(val)
    if (image) {
      image.setAttribute('data-fancybox', groupName)
      image.setAttribute('data-src', src)
      // image.setAttribute('src', src)
    }

    // Add data-fancybox attribute to the related receipts
    lst?.slice(1)?.map((receipt: any, idx: any) => {
      const imgeNext = document.getElementById(`${lstInd}${idx}`)
      if (imgeNext) {
        imgeNext.setAttribute('data-fancybox', groupName)
        imgeNext.setAttribute('data-src', `${Bucket}${receipt?.URL}`)
        // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
      }
    })

    // Trigger the fancybox on the clicked image
    if (image) {
      image.click()
    }
  }

  return (
    <>
      <div className={'card card-flush py-5 mt-7  px-5 mb-5 mx-7 min-hg-300'}>
        <div className='d-flex  align-items-center'>
          <h2 className='' style={{fontWeight: '700'}}>
            {'Credit Notes'}
          </h2>

          <div className='d-black ms-auto'>
            <button
              type='button'
              className='btn btn-sm fw-bold px-2 green-hollow-btn ms-auto btn-fit-content pe-5'
            
            >
              <img src={invoiceGreen} height={18} width={14}  className='me-4' /> Generate Credit Note
            </button>

            {noItemsError && (
              <p className='text-danger mb-0 ms-5'> Please add an invoice first.</p>
            )}

            {itemsError && (
              <p className='text-danger mb-0 ms-5'> Please complete all invoice fields.</p>
            )}
          </div>
        </div>

        <div className='table-responsive mt-4' style={{maxHeight: '700px'}}>
          <table
            className='table align-middle table-row-dashed fs-6 gy-5'
            id='kt_ecommerce_sales_table'
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                <th className='text-start min-w-75px'>Credit Note No.</th>
                <th className='text-start min-w-150px'>Due Date</th>
                <th className='text-start min-w-125px'>Sub Total</th>
                <th className='text-center min-w-125px'>VAT</th>
                <th className='text-start min-w-125px'>Total Amount</th>
                <th className='text-center min-w-125px'>Other Docs</th>
                <th className='text-start min-w-200px'> Invoice Date </th>
                <th className='text-endmin-w-200px'></th>
              </tr>
            </thead>

            <tbody className='fw-semibold text-gray-600 payment-records'>
              {paymentRecords?.map((pr: any, ind: any) => {
                return (
                  <>
                    <tr
                      style={
                        show[ind] == true
                          ? {borderBottomStyle: 'none', height: '30px'}
                          : show[ind] == false
                          ? {borderBottomStyle: 'dashed', height: '30px'}
                          : pr?.items?.length == 1
                          ? {borderBottomStyle: 'dashed', height: '30px'}
                          : {height: '30px'}
                      }
                    >
                      <td className='text-start'>
                        {' '}
                        <span
                          className='cursor-pointer'
                          onClick={() => {
                            setSelectedInd(ind)
                            setSelectedRecord(pr)
                            setShowEditAPayment(true)
                          }}
                        >
                          {pr?.id ? pr?.id : '-'}
                        </span>
                        <div className='d-block'>
                          {pr?.items?.length > 1 && (
                            <img
                              src={show[ind] ? closeImg : openImg}
                              height='15'
                              width='15'
                              className='cursor-pointer'
                              onClick={() => {
                                if (show[ind] == true) {
                                  let values = [...show]
                                  values[ind] = false
                                  setShow(values)
                                } else {
                                  let values = [...show]

                                  values[ind] = true

                                  setShow(values)
                                }
                              }}
                            />
                          )}
                        </div>
                      </td>
                      <td
                        className='text-start'
                        onClick={() => {
                          setSelectedInd(ind)
                          setSelectedRecord(pr)
                          setShowEditAPayment(true)
                        }}
                      >
                        {pr?.items?.length > 1 ? (
                          <>{pr?.items?.length} items</>
                        ) : (
                          <>{pr?.items?.[0]?.name}</>
                        )}
                      </td>
                      <td className='text-start'>
                        {pr?.totalAmountPaid > 0
                          ? `AED ${pr?.totalAmountPaid?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}`
                          : 'AED -'}
                      </td>
                      {/* <td className='text-center'>
                      <div className='d-flex justify-content-center'>
                {pr?.receipts?.length > 0 ? (
                  <div className='d-flex align-items-baseline justify-content-center my-3'>
                    <img
                      src={pdfIcon}
                      height={30}
                      width={30}
                      className='mx-4 me-2 cursor-pointer'
                      // data-fancybox
                      // data-src={`${Bucket}${pr?.receipts?.[0]?.URL}`}
                      id={`clickedImage${ind}`}
                      onClick={() =>
                        handleImageClick(
                          `clickedImage${ind}`,
                          `${Bucket}${pr?.receipts?.[0]?.URL}`,
                          pr?.receipts,
                          `clickedImageReceipt${ind}-`
                        )
                      }
                    />
                    {pr?.receipts?.slice(1).map((receipt: any, idx: any) => (

<img src={pdfIcon} 
// style={{height:'0px', width: '0px'}}
                        id={`clickedImageReceipt${ind}-${idx}`}  />
                      // <a
                      //   key={idx}
                      //   href={`${Bucket}${receipt.URL}`}
                      //   data-fancybox
                      //   // style={{display: 'none'}}
                      //   style={{height:'0px', width: '0px'}}
                      //   id={`clickedImageReceipt${ind}-${idx}`}
                        
                      // >
                        // <img src={pdfIcon} />
                      // </a>
                    ))}
                    {pr?.receipts?.length > 1 && (
                      <div className='mb-1'> +{pr?.receipts?.length - 1}</div>
                    )}
                  </div>
                ) : (
                  '-'
                )}
              </div>
                      </td> */}

                      <td className='text-center'>
                        <div className='d-flex justify-content-center'>
                          {pr?.receipts?.length > 0 ? (
                            <div className='d-flex align-items-baseline justify-content-center my-3'>
                              <img
                                src={
                                  pr?.receipts?.[0]?.URL?.split('.')[1] === 'pdf'
                                    ? pdfDocument
                                    : pr?.receipts?.[0]?.URL?.split('.').pop() === 'doc' ||
                                      pr?.receipts?.[0]?.URL?.split('.').pop() === 'docx'
                                    ? docCocument
                                    : pr?.receipts?.[0]?.URL?.split('.').pop() === 'jpeg' ||
                                      pr?.receipts?.[0]?.URL?.split('.').pop() === 'jpg' ||
                                      pr?.receipts?.[0]?.URL?.split('.').pop() === 'png' ||
                                      pr?.receipts?.[0]?.URL?.split('.').pop() === 'svg' ||
                                      pr?.receipts?.[0]?.URL?.split('.').pop() === 'gif'
                                    ? pngImg
                                    : pr?.receipts?.[0]?.URL?.split('.').pop()?.toLowerCase() ===
                                        'mp4' ||
                                      pr?.receipts?.[0]?.URL?.split('.').pop()?.toLowerCase() ===
                                        'webm' ||
                                      pr?.receipts?.[0]?.URL?.split('.').pop()?.toLowerCase() ===
                                        'avi' ||
                                      pr?.receipts?.[0]?.URL?.split('.').pop()?.toLowerCase() ===
                                        'mkv' ||
                                      pr?.receipts?.[0]?.URL?.split('.').pop()?.toLowerCase() ===
                                        'mov' ||
                                      pr?.receipts?.[0]?.URL?.split('.').pop()?.toLowerCase() ===
                                        'flv' ||
                                      pr?.receipts?.[0]?.URL?.split('.').pop()?.toLowerCase() ===
                                        'wmv'
                                    ? ``
                                    : fileIcon
                                }
                                height={30}
                                width={30}
                                className='mx-4 me-2 cursor-pointer'
                                id={`clickedImage${ind}`}
                                onClick={() =>
                                  handleImageClick(
                                    `clickedImage${ind}`,
                                    `${Bucket}${pr?.receipts?.[0]?.URL}`,
                                    pr?.receipts,
                                    `clickedImageReceipt${ind}-`
                                  )
                                }
                              />
                              {pr?.receipts?.slice(1).map((receipt: any, idx: any) => (
                                <img
                                  key={idx}
                                  src={
                                    receipt?.URL?.split('.')[1] === 'pdf'
                                      ? pdfDocument
                                      : receipt?.URL?.split('.').pop() === 'doc' ||
                                        receipt?.URL?.split('.').pop() === 'docx'
                                      ? docCocument
                                      : receipt?.URL?.split('.').pop() === 'jpeg' ||
                                        receipt?.URL?.split('.').pop() === 'jpg' ||
                                        receipt?.URL?.split('.').pop() === 'png' ||
                                        receipt?.URL?.split('.').pop() === 'svg' ||
                                        receipt?.URL?.split('.').pop() === 'gif'
                                      ? pngImg
                                      : receipt?.URL?.split('.').pop()?.toLowerCase() === 'mp4' ||
                                        receipt?.URL?.split('.').pop()?.toLowerCase() === 'webm' ||
                                        receipt?.URL?.split('.').pop()?.toLowerCase() === 'avi' ||
                                        receipt?.URL?.split('.').pop()?.toLowerCase() === 'mkv' ||
                                        receipt?.URL?.split('.').pop()?.toLowerCase() === 'mov' ||
                                        receipt?.URL?.split('.').pop()?.toLowerCase() === 'flv' ||
                                        receipt?.URL?.split('.').pop()?.toLowerCase() === 'wmv'
                                      ? ``
                                      : fileIcon
                                  }
                                  height={30}
                                  width={30}
                                  className='d-none' // hide these images by default
                                  id={`clickedImageReceipt${ind}-${idx}`}
                                />
                              ))}
                              {pr?.receipts?.length > 1 && (
                                <div className='mb-1'> +{pr?.receipts?.length - 1}</div>
                              )}
                            </div>
                          ) : (
                            '-'
                          )}
                        </div>
                      </td>

                      <td className='text-center'>
                        <div className='d-flex justify-content-center'>
                          {pr?.attachments?.length > 0 ? (
                            <div className='d-flex align-items-baseline justify-content-center my-3'>
                              {/* <a href={`${Bucket}${pr?.attachment?.URL}`} target='_blank'> */}
                              <img
                                src={
                                  pr?.attachments?.[0]?.URL?.split('.')[1] === 'pdf'
                                    ? pdfDocument
                                    : pr?.attachments?.[0]?.URL?.split('.').pop() === 'doc' ||
                                      pr?.attachments?.[0]?.URL?.split('.').pop() === 'docx'
                                    ? docCocument
                                    : pr?.attachments?.[0]?.URL?.split('.').pop() === 'jpeg' ||
                                      pr?.attachments?.[0]?.URL?.split('.').pop() === 'jpg' ||
                                      pr?.attachments?.[0]?.URL?.split('.').pop() === 'png' ||
                                      pr?.attachments?.[0]?.URL?.split('.').pop() === 'svg' ||
                                      pr?.attachments?.[0]?.URL?.split('.').pop() === 'gif'
                                    ? pngImg
                                    : pr?.attachments?.[0]?.URL?.split('.').pop()?.toLowerCase() === 'mp4' ||
                                      pr?.attachments?.[0]?.URL?.split('.').pop()?.toLowerCase() === 'webm' ||
                                      pr?.attachments?.[0]?.URL?.split('.').pop()?.toLowerCase() === 'avi' ||
                                      pr?.attachments?.[0]?.URL?.split('.').pop()?.toLowerCase() ===
                                        'mkv' ||
                                      pr?.attachments?.[0]?.URL?.split('.').pop()?.toLowerCase() ===
                                        'mov' ||
                                      pr?.attachments?.[0]?.URL?.split('.').pop()?.toLowerCase() ===
                                        'flv' ||
                                      pr?.attachments?.[0]?.URL?.split('.').pop()?.toLowerCase() ===
                                        'wmv'
                                    ? ``
                                    : fileIcon
                                }
                                height={30}
                                width={30}
                                className='mx-4 me-2 cursor-pointer'
                                // data-fancybox
                                // data-src={`${Bucket}${pr?.attachments?.[0]?.URL}`}
                                id={`clickedImageAttachment${ind}`}
                                onClick={() =>
                                  handleImageClick(
                                    `clickedImageAttachment${ind}`,
                                    `${Bucket}${pr?.attachments?.[0]?.URL}`,
                                    pr?.attachments,
                                    `clickedImageAttach${ind}-`
                                  )
                                }
                              />
                              {pr?.attachments?.slice(1).map((attachmnt: any, idx: any) => (
                                <img
                                  key={idx}
                                  src={
                                    attachmnt?.URL?.split('.')[1] === 'pdf'
                                      ? pdfDocument
                                      : attachmnt?.URL?.split('.').pop() === 'doc' ||
                                        attachmnt?.URL?.split('.').pop() === 'docx'
                                      ? docCocument
                                      : attachmnt?.URL?.split('.').pop() === 'jpeg' ||
                                        attachmnt?.URL?.split('.').pop() === 'jpg' ||
                                        attachmnt?.URL?.split('.').pop() === 'png' ||
                                        attachmnt?.URL?.split('.').pop() === 'svg' ||
                                        attachmnt?.URL?.split('.').pop() === 'gif'
                                      ? pngImg
                                      : attachmnt?.URL?.split('.').pop()?.toLowerCase() === 'mp4' ||
                                        attachmnt?.URL?.split('.').pop()?.toLowerCase() ===
                                          'webm' ||
                                        attachmnt?.URL?.split('.').pop()?.toLowerCase() === 'avi' ||
                                        attachmnt?.URL?.split('.').pop()?.toLowerCase() === 'mkv' ||
                                        attachmnt?.URL?.split('.').pop()?.toLowerCase() === 'mov' ||
                                        attachmnt?.URL?.split('.').pop()?.toLowerCase() === 'flv' ||
                                        attachmnt?.URL?.split('.').pop()?.toLowerCase() === 'wmv'
                                      ? ``
                                      : fileIcon
                                  }
                                  height={30}
                                  width={30}
                                  className='d-none' // hide these images by default
                                  id={`clickedImageAttach${ind}-${idx}`}
                                />
                              ))}
                              {pr?.attachments?.length > 1 && (
                                <div className='mb-1'> +{pr?.attachments?.length - 1}</div>
                              )}
                              {/* </a> */}
                            </div>
                          ) : (
                            '-'
                          )}
                        </div>
                      </td>
                      <td className='text-start'>
                        {pr?.datePaid != null
                          ? `${moment?.utc(pr?.datePaid)?.local()?.format('DD.MM.YYYY')}`
                          : '-'}
                      </td>
                      <td className='text-end '>
                        <img
                          src={trashImg}
                          height={18}
                          width={18}
                          className='mx-5 cursor-pointer'
                          onClick={() => {
                            swal
                              .fire({
                                html: ` 
                                <div class='fs-3'> <b>Delete Payment Record</></div>
                                 <div class='fs-5 mt-4'>You are about to delete the payment record.</div>
                                <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                // icon: 'warning',
                                showConfirmButton: true,
                                confirmButtonColor: '#35475e',
                                confirmButtonText: 'Yes',
                                showCancelButton: true,
                                cancelButtonText: 'No',
                                cancelButtonColor: '#fff',
                                customClass: {
                                  confirmButton: 'custom-confirm-button',
                                  cancelButton: 'custom-cancel-button',
                                  popup: 'custom-popup',
                                },
                                reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                              })
                              .then((res: any) => {
                                if (res.isConfirmed) {
                                  if (flg == '1') {
                                    let values = [...paymentRecords]
                                    values.splice(ind, 1)
                                    setPaymentRecords(values)
                                  } else deletePaymentRecord(pr?._id, ind)
                                }
                              })
                          }}
                        />
                      </td>
                    </tr>

                    {pr?.items?.length > 1 &&
                      show[ind] == true &&
                      pr?.items?.map((prSub: any, index: any) => {
                        return (
                          <>
                            <tr
                              style={
                                index == pr?.items?.length - 1
                                  ? {borderBottomStyle: 'dashed', height: '30px'}
                                  : {borderBottomStyle: 'none', height: '30px'}
                              }
                            >
                              <td style={{paddingLeft: '5px'}}></td>
                              <td>{prSub?.name}</td>
                              <td>
                                {flg == '1' ? (
                                  <>
                                    {prSub?.totalAmountPaid > 0
                                      ? `AED ${prSub?.totalAmountPaid?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}`
                                      : 'AED -'}
                                  </>
                                ) : (
                                  <>
                                    {prSub?.amountPaid > 0
                                      ? `AED ${prSub?.amountPaid?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}`
                                      : 'AED -'}
                                  </>
                                )}
                              </td>
                            </tr>
                          </>
                        )
                      })}
                  </>
                )
              })}
            </tbody>
          </table>
        </div>

        {attachmentsForPreview && <AttachmentPreview attachments={attachmentsForPreview} />}
      </div>

      {/* Add Report Modal */}
      {showRecordAPayment && itemsForPayments && (
        <RecordAPayment
          show={showRecordAPayment}
          handleClose={() => {
            setShowRecordAPayment(false)
          }}
          expData={itemsForPayments}
          updateData={(dataPay: any, dataItem: any) => {
            // console.log(data)
            let values = [...paymentRecords]
            values.push(dataPay)

            // console.log(values)

            updateParent(values, dataItem)
            setPaymentRecords(values)

            autoSaveParent(dataPay, dataItem)

            for (let i = 0; i < values.length; i++) {
              let values = [...show]
              values[i] = false
              setShow(values)
            }

            setShowRecordAPayment(false)
          }}
          slctdDev={selectedDevelopment}
          updateItems={(data: any) => {
            // console.log(data)
            // updateItemsInParent(data)
          }}
        />
      )}

      {/* Add Report Modal */}
      {showEditAPayment && selectedRecord && (
        <ViewEditPaymentRecord
          show={showEditAPayment}
          handleClose={() => {
            setShowEditAPayment(false)
          }}
          expData={expenseData}
          updateData={(data: any) => {
            // console.log(data)
            let values = [...paymentRecords]
            values[selectedInd] = data
            setPaymentRecords(values)

            for (let i = 0; i < values.length; i++) {
              let values = [...show]
              values[i] = false
              setShow(values)
            }

            updateParent(values, values[selectedInd].items)

            autoUpdateParent(values[selectedInd])

            setShowRecordAPayment(false)
          }}
          updateDataAfterDelete={() => {
            let values = [...paymentRecords]
            values.splice(selectedInd, 1)
            setPaymentRecords(values)
            setShowEditAPayment(false)
          }}
          selectedItem={selectedRecord}
          slctdDev={selectedDevelopment}
        />
      )}
    </>
  )
}

export default CreditNote
