import moment from 'moment'
import React from 'react'
import {Col, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {ApiGet, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import img from '../../../img/300-1.jpg'
import {Bucket} from '../../../apiCommon/helpers/API/ApiData'
import NewMessage from './NewMessage'

const ViewMessage = () => {
  const navigate = useNavigate()
  const [tenantData, setTenantData] = React.useState<any>([])
  const [propertiType, setPropertiType] = React.useState('')
  const [messageData, setMessageData] = React.useState<any>([])
  const [newMessageModal, setNewMessageModal] = React.useState<any>(false)

  const getTenantData = async () => {
    await ApiGet(`corporate/tenant/${window.location.pathname.split('/')[2]}`)
      .then((res) => {
        // setTableData(res?.data?.data)
        setTenantData(res?.data?.data)
        console.log('\nres?.data?.data', res?.data?.data)
        //   console.log('first', res?.data?.data[0]?.tenantType === 1 ? 'Payments & Rent' : 'Documents')
        setPropertiType(res?.data?.data[0]?.tenantType === 1 ? 'Messages' : 'Documents')
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getMessageById = async () => {
    const body = {}
    await ApiGet(`corporate/message/${window.location.pathname.split('/')[2]}`)
      .then((res) => {
        // setTableData(res?.data?.data)
        console.info('res?.data?.data++', res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    getTenantData()
    getMessageById()
  }, [window.location.pathname.split('/')[2]])
  return (
    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
      <div className='d-flex flex-column flex-column-fluid'>
        <div id='kt_app_toolbar' className='app-toolbar py-3 py-lg-6'>
          <div
            id='kt_app_toolbar_container'
            className='app-container container-xxl d-flex flex-stack'
          >
            <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3'>
              <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                View Message Details
              </h1>
              <ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1'>
                <li className='breadcrumb-item text-muted'>
                  <div className='text-muted cursor-pointer' onClick={() => navigate('/tenants')}>
                    Tenant
                  </div>
                </li>
                <li className='breadcrumb-item'>
                  <span className='bullet bg-gray-400 w-5px h-2px'></span>
                </li>
                <li className='breadcrumb-item active'>
                  <a className='px-2'>Message details</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div id='kt_app_content' className='app-content flex-column-fluid'>
          <div id='kt_app_content_container' className='app-container container-xxl'>
            <div className='d-flex flex-column flex-lg-row'>
              <div className='flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10'>
                <div className='card mb-5 mb-xl-8'>
                  <div className='card-body'>
                    <div className='d-flex flex-stack fs-4 py-3'>
                      <div
                        className='fw-bold rotate collapsible'
                        data-bs-toggle='collapse'
                        role='button'
                        aria-expanded='false'
                        aria-controls='kt_user_view_details'
                      ></div>
                    </div>

                    <div id='kt_user_view_details' className='collapse show'>
                      <div className='pb-5 fs-6'>
                        <div className='row'>
                          <div className='col-lg-8 col-md-8 symbol symbol-100px symbol d-flex align-items-center'>
                            <img src={img} alt='image' style={{margin: '0 auto'}} />
                          </div>
                          <div className='col-lg-4 col-md-4' style={{textAlign: 'right'}}>
                            <a
                              // onClick={() => setShowCreateAppModal(true)}
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_create_app'
                              // onClick={handleSubmit}
                              onClick={() => {
                                navigate(`/edit-tenant/${window.location.pathname.split('/')[2]}`)
                              }}
                            >
                              Edit
                            </a>
                          </div>
                          <div className='col-12 mt-3'>
                            <span
                              className='text-gray-600'
                              style={{
                                fontSize: '16px',
                                textTransform: 'capitalize',
                                fontWeight: 600,
                              }}
                            >
                              {tenantData[0]?.firstName} {tenantData[0]?.lastName}
                            </span>
                          </div>
                        </div>
                        <div className='fw-bold mt-5'>
                          Type{' '}
                          <span className='text-gray-600'>
                            {tenantData[0]?.tenantType === 0
                              ? 'Main'
                              : tenantData[0]?.tenantType === 1
                              ? 'Sub'
                              : ''}
                          </span>
                        </div>
                        <div className='fw-bold mt-5'>
                          DOB{' '}
                          <span className='text-gray-600'>
                            {moment(tenantData[0]?.DOB).format('DD/MM/YYYY')}
                          </span>
                        </div>

                        <div className='fw-bold mt-5'>
                          ID <span className='text-gray-600'>{tenantData[0]?._id}</span>
                        </div>

                        <div className='fw-bold mt-5'>
                          Nationality{' '}
                          <span className='text-gray-600'>{tenantData[0]?.nationality}</span>
                        </div>

                        <div className='fw-bold mt-5'>
                          Email <span className='text-gray-600'>{tenantData[0]?.email}</span>
                        </div>

                        <div className='fw-bold mt-5'>
                          Mobile No.{' '}
                          <span className='text-gray-600'>
                            +{tenantData[0]?.countryCode} {tenantData[0]?.phoneNumber}
                          </span>
                        </div>

                        <div className='fw-bold mt-5'>
                          Source{' '}
                          <span className='text-gray-600'>
                            {tenantData[0]?.tenantSource === 1
                              ? 'Direct'
                              : tenantData[0]?.tenantSource === 0
                              ? 'Agent'
                              : ''}
                          </span>
                        </div>

                        <div className='d-flex mt-5 justify-content-between align-items-end'>
                          <div>
                            <div className='fw-bold mt-5'>
                              {' '}
                              Tenancy Status{' '}
                              <span className='text-gray-600'>
                                {tenantData[0]?.tenancy?.tenancyStatus === 0
                                  ? 'Booked'
                                  : tenantData[0]?.tenancy?.tenancyStatus === 1
                                  ? 'Active'
                                  : 'Expiring'}
                              </span>
                            </div>
                          </div>
                          <div>
                            <a
                              // onClick={() => setShowCreateAppModal(true)}
                              className='btn btn-sm fw-bold btn-primary btn-green '
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_create_app'
                              // onClick={handleSubmit}
                              onClick={() => {
                                navigate(
                                  `/tenancy-details/${tenantData[0]?.tenantId}/${tenantData[0]?.tenancy?._id}`
                                )
                                // navigate(`tenancy-details`)
                                // console.log('`tenancy-details/${tenantData[0]?.teancy?._id}`', `tenancy-details/${tenantData[0]?.tenancy?._id}`)
                              }}
                            >
                              View Tenancy
                            </a>
                          </div>
                        </div>
                        <div className='fw-bold mt-5'>
                          {' '}
                          Unit No.{' '}
                          <span className='text-gray-600'>
                            {tenantData[0]?.tenancy?.unit[0]?.unitNo}
                          </span>
                        </div>
                        <div className='fw-bold mt-5'>
                          {' '}
                          Unit Type{' '}
                          <span className='text-gray-600'>
                            {tenantData[0]?.tenancy?.unit[0]?.unitType === 'town_house'
                              ? 'TownHouse'
                              : tenantData[0]?.tenancy?.unit[0]?.unitType === 'other'
                              ? 'Other'
                              : tenantData[0]?.tenancy?.unit[0]?.unitType === 'common_area'
                              ? 'Common Area'
                              : tenantData[0]?.tenancy?.unit[0]?.unitType === 'villa'
                              ? 'Villa'
                              : tenantData[0]?.tenancy?.unit[0]?.unitType === 'apartment'
                              ? 'Apartment'
                              : tenantData[0]?.tenancy?.unit[0]?.unitType === 'penthouse'
                              ? 'Penthouse'
                              : ''}
                          </span>
                        </div>

                        {tenantData[0]?.tenancy?.unit[0]?.floor[0]?.name ? (
                          <div className='fw-bold mt-5'>
                            {' '}
                            Floor{' '}
                            <span className='text-gray-600'>
                              {tenantData[0]?.tenancy?.unit[0]?.floor[0]?.name}
                            </span>
                          </div>
                        ) : (
                          ''
                        )}
                        {tenantData[0]?.tenancy?.community[0]?.name ? (
                          <div className='fw-bold mt-5'>
                            {' '}
                            Development Name{' '}
                            <span className='text-gray-600'>
                              {tenantData[0]?.tenancy?.community[0]?.name}
                            </span>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className=''>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
                <div className='card' id='kt_chat_messenger'>
                  <div className='card-header' id='kt_chat_messenger_header'>
                    <div className='card-title'>
                      <div className='d-flex justify-content-center flex-column me-3'>
                        <a
                          href='#'
                          className='fs-4 fw-bold text-gray-900 text-hover-primary me-1 mb-2 lh-1'
                        >
                          Brian Cox
                        </a>
                        <div className='mb-0 lh-1'>
                          <span className='badge badge-success badge-circle w-10px h-10px me-1'></span>
                          <span className='fs-7 fw-semibold text-muted'>Active</span>
                        </div>
                      </div>
                    </div>
                    <div className='card-toolbar'>
                      <div className='me-n3'>
                        <button
                          className='btn btn-sm btn-icon btn-active-light-primary'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                        >
                          <i className='bi bi-three-dots fs-2'></i>
                        </button>
                        <div
                          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3'
                          data-kt-menu='true'
                        >
                          <div className='menu-item px-3'>
                            <div className='menu-content text-muted pb-2 px-3 fs-7 text-uppercase'>
                              Contacts
                            </div>
                          </div>
                          <div className='menu-item px-3'>
                            <a
                              href='#'
                              className='menu-link px-3'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_users_search'
                            >
                              Add Contact
                            </a>
                          </div>
                          <div className='menu-item px-3'>
                            <a
                              href='#'
                              className='menu-link flex-stack px-3'
                              data-bs-toggle='modal'
                              data-bs-target='#kt_modal_invite_friends'
                            >
                              Invite Contacts
                              <i
                                className='fas fa-exclamation-circle ms-2 fs-7'
                                data-bs-toggle='tooltip'
                                aria-label='Specify a contact email to send an invitation'
                                data-bs-original-title='Specify a contact email to send an invitation'
                                data-kt-initialized='1'
                              ></i>
                            </a>
                          </div>
                          <div
                            className='menu-item px-3'
                            data-kt-menu-trigger='hover'
                            data-kt-menu-placement='right-start'
                          >
                            <a href='#' className='menu-link px-3'>
                              <span className='menu-title'>Groups</span>
                              <span className='menu-arrow'></span>
                            </a>
                            <div className='menu-sub menu-sub-dropdown w-175px py-4'>
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                  data-bs-toggle='tooltip'
                                  data-bs-original-title='Coming soon'
                                  data-kt-initialized='1'
                                >
                                  Create Group
                                </a>
                              </div>
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                  data-bs-toggle='tooltip'
                                  data-bs-original-title='Coming soon'
                                  data-kt-initialized='1'
                                >
                                  Invite Members
                                </a>
                              </div>
                              <div className='menu-item px-3'>
                                <a
                                  href='#'
                                  className='menu-link px-3'
                                  data-bs-toggle='tooltip'
                                  data-bs-original-title='Coming soon'
                                  data-kt-initialized='1'
                                >
                                  Settings
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className='menu-item px-3 my-1'>
                            <a
                              href='#'
                              className='menu-link px-3'
                              data-bs-toggle='tooltip'
                              data-bs-original-title='Coming soon'
                              data-kt-initialized='1'
                            >
                              Settings
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-body' id='kt_chat_messenger_body'>
                    <div
                      className='scroll-y me-n5 pe-5 h-300px h-lg-auto'
                      data-kt-element='messages'
                      data-kt-scroll='true'
                      data-kt-scroll-activate='{default: false, lg: true}'
                      data-kt-scroll-max-height='auto'
                      data-kt-scroll-dependencies='#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
                      data-kt-scroll-wrappers='#kt_content, #kt_app_content, #kt_chat_messenger_body'
                      data-kt-scroll-offset='5px'
                      style={{maxHeight: '335px'}}
                    >
                      <div className='d-flex justify-content-start mb-10'>
                        <div className='d-flex flex-column align-items-start'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='symbol symbol-35px symbol-circle'>
                              <img
                                alt='Pic'
                                src='https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-25.jpg'
                              />
                            </div>
                            <div className='ms-3'>
                              <a
                                href='#'
                                className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'
                              >
                                Brian Cox
                              </a>
                              <span className='text-muted fs-7 mb-1'>2 mins</span>
                            </div>
                          </div>
                          <div
                            className='p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start'
                            data-kt-element='message-text'
                          >
                            How likely are you to recommend our company to your friends and family ?
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end mb-10'>
                        <div className='d-flex flex-column align-items-end'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='me-3'>
                              <span className='text-muted fs-7 mb-1'>5 mins</span>
                              <a
                                href='#'
                                className='fs-5 fw-bold text-gray-900 text-hover-primary ms-1'
                              >
                                You
                              </a>
                            </div>
                            <div className='symbol symbol-35px symbol-circle'>
                              <img
                                alt='Pic'
                                src='https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-1.jpg'
                              />
                            </div>
                          </div>
                          <div
                            className='p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end'
                            data-kt-element='message-text'
                          >
                            Hey there, we’re just writing to let you know that you’ve been
                            subscribed to a repository on GitHub.
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-start mb-10'>
                        <div className='d-flex flex-column align-items-start'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='symbol symbol-35px symbol-circle'>
                              <img
                                alt='Pic'
                                src='https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-25.jpg'
                              />
                            </div>
                            <div className='ms-3'>
                              <a
                                href='#'
                                className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'
                              >
                                Brian Cox
                              </a>
                              <span className='text-muted fs-7 mb-1'>1 Hour</span>
                            </div>
                          </div>
                          <div
                            className='p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start'
                            data-kt-element='message-text'
                          ></div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end mb-10'>
                        <div className='d-flex flex-column align-items-end'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='me-3'>
                              <span className='text-muted fs-7 mb-1'>2 Hours</span>
                              <a
                                href='#'
                                className='fs-5 fw-bold text-gray-900 text-hover-primary ms-1'
                              >
                                You
                              </a>
                            </div>
                            <div className='symbol symbol-35px symbol-circle'>
                              <img
                                alt='Pic'
                                src='https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-1.jpg'
                              />
                            </div>
                          </div>
                          <div
                            className='p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end'
                            data-kt-element='message-text'
                          >
                            You’ll receive notifications for all issues, pull requests!
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-start mb-10'>
                        <div className='d-flex flex-column align-items-start'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='symbol symbol-35px symbol-circle'>
                              <img
                                alt='Pic'
                                src='https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-25.jpg'
                              />
                            </div>
                            <div className='ms-3'>
                              <a
                                href='#'
                                className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'
                              >
                                Brian Cox
                              </a>
                              <span className='text-muted fs-7 mb-1'>3 Hours</span>
                            </div>
                          </div>
                          <div
                            className='p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start'
                            data-kt-element='message-text'
                          >
                            You can unwatch this repository immediately by clicking here:
                            <a href='https://keenthemes.com'>Keenthemes.com</a>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-end mb-10'>
                        <div className='d-flex flex-column align-items-end'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='me-3'>
                              <span className='text-muted fs-7 mb-1'>4 Hours</span>
                              <a
                                href='#'
                                className='fs-5 fw-bold text-gray-900 text-hover-primary ms-1'
                              >
                                You
                              </a>
                            </div>
                            <div className='symbol symbol-35px symbol-circle'>
                              <img
                                alt='Pic'
                                src='https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-1.jpg'
                              />
                            </div>
                          </div>
                          <div
                            className='p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end'
                            data-kt-element='message-text'
                          >
                            Most purchased Business courses during this sale!
                          </div>
                        </div>
                      </div>
                      <div className='d-flex justify-content-start mb-10'>
                        <div className='d-flex flex-column align-items-start'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='symbol symbol-35px symbol-circle'>
                              <img
                                alt='Pic'
                                src='https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-25.jpg'
                              />
                            </div>
                            <div className='ms-3'>
                              <a
                                href='#'
                                className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'
                              >
                                Brian Cox
                              </a>
                              <span className='text-muted fs-7 mb-1'>5 Hours</span>
                            </div>
                          </div>
                          <div
                            className='p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start'
                            data-kt-element='message-text'
                          >
                            Company BBQ to celebrate the last quater achievements and goals. Food
                            and drinks provided
                          </div>
                        </div>
                      </div>
                      <div
                        className='d-flex justify-content-end mb-10 d-none'
                        data-kt-element='template-out'
                      >
                        <div className='d-flex flex-column align-items-end'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='me-3'>
                              <span className='text-muted fs-7 mb-1'>Just now</span>
                              <a
                                href='#'
                                className='fs-5 fw-bold text-gray-900 text-hover-primary ms-1'
                              >
                                You
                              </a>
                            </div>
                            <div className='symbol symbol-35px symbol-circle'>
                              <img
                                alt='Pic'
                                src='https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-1.jpg'
                              />
                            </div>
                          </div>
                          <div
                            className='p-5 rounded bg-light-primary text-dark fw-semibold mw-lg-400px text-end'
                            data-kt-element='message-text'
                          ></div>
                        </div>
                      </div>
                      <div
                        className='d-flex justify-content-start mb-10 d-none'
                        data-kt-element='template-in'
                      >
                        <div className='d-flex flex-column align-items-start'>
                          <div className='d-flex align-items-center mb-2'>
                            <div className='symbol symbol-35px symbol-circle'>
                              <img
                                alt='Pic'
                                src='https://preview.keenthemes.com/metronic8/demo1/assets/media/avatars/300-25.jpg'
                              />
                            </div>
                            <div className='ms-3'>
                              <a
                                href='#'
                                className='fs-5 fw-bold text-gray-900 text-hover-primary me-1'
                              >
                                Brian Cox
                              </a>
                              <span className='text-muted fs-7 mb-1'>Just now</span>
                            </div>
                          </div>
                          <div
                            className='p-5 rounded bg-light-info text-dark fw-semibold mw-lg-400px text-start'
                            data-kt-element='message-text'
                          >
                            Right before vacation season we have the next Big Deal for you.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='card-footer pt-4' id='kt_chat_messenger_footer'>
                    <textarea
                      className='form-control form-control-flush mb-3'
                      rows={1}
                      data-kt-element='input'
                      placeholder='Type a message'
                    ></textarea>
                    <div className='d-flex flex-stack'>
                      <div className='d-flex align-items-center me-2'>
                        {/* <button className="btn btn-sm btn-icon btn-active-light-primary me-1" type="button" data-bs-toggle="tooltip" aria-label="Coming soon" data-bs-original-title="Coming soon" data-kt-initialized="1">
                                                    <i className="bi bi-paperclip fs-3"></i>
                                                </button>
                                                <button className="btn btn-sm btn-icon btn-active-light-primary me-1" type="button" data-bs-toggle="tooltip" aria-label="Coming soon" data-bs-original-title="Coming soon" data-kt-initialized="1">
                                                    <i className="bi bi-upload fs-3"></i>
                                                </button> */}
                      </div>
                      <a
                        // onClick={() => setShowCreateAppModal(true)}
                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_create_app'
                        // onClick={handleSubmit}
                      >
                        Send
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewMessage
