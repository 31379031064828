import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ApiDelete, ApiGet, ApiPost, ApiPut } from '../../../apiCommon/helpers/API/ApiData';
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast';
import UpdateService from './UpdateService';

function EditFacility() {
    const navigate = useNavigate()
    const [formData, setFormData] = useState<any>({})
    const [updateMsg, setUpdateMsg] = useState<any>(0)
    const [showModal, setShowModal] = useState<any>(false)
    const [labelDelete, setLabelDelete] = useState<any>()
    const [mainServiceData, setMainServiceData] = useState<any>()
    const [subServiceData, setSubServiceData] = useState<any>()
    const [subService, setSubService] = useState<any>()
    const [exitingService, setExitingService] = useState<any>()


    const getServicesById = () => {
        ApiGet(`corporate/main_service/${window.location.pathname.split('/')[2]}`)
            .then((res) => {
                setFormData(res?.data?.data[0])
                setSubService(res?.data?.data[0]?.sub_service[0])
            })
            .catch((err) => ErrorToast(err.message))
    }

    const getmainServiceList = () => {
        ApiGet(`corporate/main_service`)
            .then((res) => {
                setMainServiceData(res?.data?.data)
            })
            .catch((err) => ErrorToast(err.message))
    }

    const getSubServiceData = () => {
        ApiGet(`corporate/sub_service`)
            .then((res) => {
                setSubServiceData(res?.data?.data)
            })
            .catch((err) => ErrorToast(err.message))
    }

    React.useEffect(() => {
        getServicesById()
        getmainServiceList()
        getSubServiceData()
    }, [window.location.pathname.split('/')[2]])

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setExitingService({ ...exitingService, [name]: value })
    }

    React.useEffect(() => {
        update()
    }, [exitingService])

    const update = () => {
        if (exitingService?.mainServiceList) {
            setUpdateMsg(1)
        } else if (exitingService?.subServiceList) {
            setUpdateMsg(1)
        }
        if(exitingService?.mainServiceList && exitingService?.subServiceList) {
            setUpdateMsg(2)
        }
    }

    const updateServices = () => {  
        if(formData?.name === ''&& subService?.name === ''){
            ErrorToast('please fill the detail')
        }else{
            const body = {
                id: formData?._id,
                name: exitingService?.mainServiceList ? exitingService?.mainServiceList : formData?.name,
                type: 0
            }
            ApiPut(`corporate/main_service`, body)
                .then((res) => {
                    if (subService?._id === undefined) {
                        const data = {
                            name: exitingService?.subServiceList ? exitingService?.subServiceList : subService?.name ,
                            note: subService?.note,
                            serviceIds: [res?.data?.data?._id]
                        }
                        ApiPost(`corporate/sub_service`, data)
                            .then((res) => {
                                SuccessToast(res?.data.message)
                                navigate('/facility')
                            })
                            .catch((err) => ErrorToast(err.message))
                    }
                    else {
                        const data = {
                            id: subService?._id,
                            name: exitingService?.subServiceList  ?exitingService?.subServiceList  : subService?.name,
                            note: subService?.note,
                            serviceIds: [res?.data?.data?._id]
                        }
                        ApiPut(`corporate/sub_service`, data)
                            .then((res) => {
                                SuccessToast(res?.data.message)
                                navigate('/facility')
                            })
                            .catch((err) => ErrorToast(err.message))
                    }
                })
                .catch((err) => ErrorToast(err.message))

        }
       
      
    }
   
    const deleteServices = () => {
        ApiDelete(`corporate/main_service/${formData?._id}`)
            .then((res) => {

                ApiDelete(`corporate/sub_service/${subService?._id}`)
                    .then((res) => {
                        SuccessToast(res?.data.message)
                        navigate('/facility')
                    })
                    .catch((err) => ErrorToast(err.message))

            })
            .catch((err) => ErrorToast(err.message))
    }

    return (
        <>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>
                <div className='d-flex align-items-baseline flex-wrap mr-5'>
                    <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
                        <li className='breadcrumb-item '>
                            <a
                                className='text-muted px-2 cursor-pointer'
                                onClick={() => navigate('/facility')}
                            >
                                Services
                            </a>
                        </li>
                        <li className='breadcrumb-item active'>
                            <a className='px-2'> Edit Services</a>
                        </li>
                    </ul>
                </div>
                    <div className='d-flex flex-stack pt-3 mt-0 mb-10'>
                        <div className=''>
                            <a
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_create_app'
                                onClick={() => navigate('/facility')}

                            >
                                Cancel
                            </a>
                        </div>
                        <div className=''>
                          <a
                                onClick={() => Swal
                                    .fire({
                                        title: '<p class="text-start m-0">Update Service</p>',
                                        text: updateMsg === 0 ? 'Are you sure you want to update the details of this service? Updating the details will reflect on all past Service Requests submitted under this service' :
                                            updateMsg === 1 ? 'Are you sure you want to update the details of this service? Updating the details will reflect on all past Service Requests submitted under this service. ' : 'This service currently exists, continuing will merge the previous service with the latest update Are you sure you want to continue? ',
                                        icon: 'warning',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#007a59',
                                        confirmButtonText: 'Update',
                                        showCancelButton: true,
                                        cancelButtonText: 'Cancel',
                                        html: updateMsg === 0 ? '<p class="text-start m-0">Are you sure you want to update the details of this service? Updating the details will reflect on all past Service Requests submitted under this service</p>' :
                                        updateMsg === 1 ? '<p class="text-start m-0">Are you sure you want to update the details of this service? Updating the details will reflect on all past Service Requests submitted under this service.</p> ' : '<p class="text-start m-0">This service currently exists, continuing will merge the previous service with the latest update Are you sure you want to continue? </p>',
                                    })
                                    .then((res) => {
                                        if (res.isConfirmed) {
                                            updateServices()
                                        }
                                    })}
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_create_app'
                            >
                                Update
                            </a>
                            <a
                                onClick={() => Swal
                                    .fire({
                                        html: '<p class="text-start m-0">Are you sure you want to permanenetly delete this service?The service will still be retained for past Service Requests submitted under this service.</p>',
                                        title: '<p class="text-start m-0">Delete Service </p>',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#D72852',
                                        confirmButtonText: 'Delete',
                                        showCancelButton: true,
                                        cancelButtonText: 'Cancel',
                                    })
                                    .then((res) => {
                                        if (res.isConfirmed) {
                                            deleteServices()
                                        }
                                    })}
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_create_app'
                            >
                                Delete
                            </a>
                        </div>
                    </div>
                    <div className='card card-flush app-container py-5'>
                        <h4 className='mb-5'>Service Details</h4>
                        <div className='mb-10 sm:min-w-200px d-flex align-items-center flex-wrap justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <label htmlFor='exampleFormControlInput1' className='min-w-100px w-50 form-label m-0'>
                                    Existing Main Service
                                </label>
                                <select
                                    name='mainServiceList'
                                    className='form-select form-select-solid'
                                    onChange={handleChnage}
                                >
                                    <option selected value=''>
                                        Select
                                    </option>
                                    {mainServiceData?.map((data: any) => (
                                        <option value={data.name}>{data.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='d-flex align-items-center'>
                                <label htmlFor='exampleFormControlInput1' className='min-w-100px w-50 form-label m-0 me-3'>
                                    New Main Service
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-solid w-auto'
                                    placeholder='New Service'
                                    name='mainNewServiceList'
                                    value={formData?.name}
                                    onChange={(e) => setFormData({ ...formData, 'name': e.target.value })}
                                    disabled={formData?.mainServiceList ? true : false}
                                />
                            </div>
                        </div>
                        <div className='mb-10 min-w-200px d-flex align-items-center flex-wrap justify-content-between'>
                            <div className="d-flex align-items-center">
                                <label htmlFor='exampleFormControlInput1' className='min-w-100px w-50 form-label m-0'>
                                    Existing Sub-Service
                                </label>
                                <select
                                    name='subServiceList'
                                    className='form-select form-select-solid'
                                    onChange={handleChnage}
                                >
                                    <option selected value=''>
                                        Select
                                    </option>
                                    {subServiceData?.map((data: any) => (
                                        <option value={data.name}>{data.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="d-flex align-items-center ">
                                <label htmlFor='exampleFormControlInput1' className='min-w-100px w-50 form-label m-0 me-3'>
                                    New Sub-Service
                                </label>
                                <input
                                    type='text'
                                    className='form-control form-control-solid w-auto'
                                    placeholder='New Service'
                                    onChange={(e) => setSubService({ ...subService, 'name': e.target.value })}
                                    name='name'
                                    value={subService?.name}
                                    disabled={formData?.subServiceList ? true : false}
                                />
                            </div>
                        </div>
                        <Col lg={6} className="d-flex pe-5">
                            <label htmlFor='exampleFormControlInput1' className='min-w-100px form-label m-0'>
                                Notes
                            </label>
                            <textarea
                                className='form-control form-control-solid'
                                placeholder='Type Your Notes....'
                                name='note'
                                value={subService?.note}
                                onChange={(e) => setSubService({ ...subService, 'note': e.target.value })}
                            />
                        </Col>
                    </div>
                </div>
            </div>

            <UpdateService
                show={showModal}
                handleClose={() => {
                    setShowModal(false)
                }}
                labelDelete={labelDelete}
            />
        </>
    );
}

export default EditFacility;