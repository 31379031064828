import React, {useState, useEffect, useRef} from 'react'
import backArrow from '../../../img/back-arrow.png'
import {useNavigate, useParams} from 'react-router-dom'
import downloadImg from '../../../img/download-img.png'
import trashImg from '../../../img/trash.png'
import {Row, Col, OverlayTrigger, Tooltip, Modal} from 'react-bootstrap'
import moment, {Moment} from 'moment'
import TextEditor from '../General/TextEditor'
import swal from 'sweetalert2'
import DnDCard from '../General/DnDCard'
import {ApiGet, Bucket, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {Backdrop, CircularProgress} from '@mui/material'
import share from '../../../img/email.png'
import print from '../../../img/printer.png'
import {useLocation} from 'react-router-dom'
import ShareWithRecipients from './ShareWithRecipients'

const ViewPOTemplate = () => {
  const {flg, type, id, poId} = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState<any>()

  const [purchaseOrderData, setPurchaseOrderData] = useState<any>({
    name: '',
    id: '',
    items: [
      {
        description: '',
        quantity: 1,
        unitPrice: 0,
        totalPrice: 0,
      },
    ],
    sections: [],
  })

  const [paymentTerms, setPaymentTerms] = useState<any>({
    title: 'Payment Terms Defaullttt',
    text: '',
    html: '',
  })

  const {state} = useLocation()
  var totalPrice: any = 0

  const [vendorData, setVendorData] = useState<any>({})
  const [deliveryDetails, setDeliveryDetails] = useState<any>({
    address1: '',
    address2: '',
    email: '',
    deliverDate: null,
    phoneNumber: '',
    countryCode: '',
  })

  const [showDeliveryDetails, setShowDeliveryDetails] = useState<any>(false)

  const [poCompany, setPOCompany] = useState<any>({
    name: 'Corporate User Real Estate Services LLC.',
    address: 'Corporate Address, Street, Building, City',
    phoneNumber: '+92 341-0121298',
    email: 'CorporateAccount_Email@gmail.com',
  })

  const [logo, setLogo] = useState<any>('')
  const [selectedDate, setSelectedDate] = useState<any>(new Date())

  const [requestData, setRequestData] = useState<any>()
  const [open, setOpen] = useState<any>(false)

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  //
  const getPODetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setPOCompany(res?.data?.data?.purchaseOrderCompany)
        setLogo(res?.data?.data?.purchaseOrderLogo)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  //
  const getRequestById = () => {
    ApiGet(`corporate/maintenance_service_request/${id}`)
      .then((res: any) => {
        setRequestData(res?.data?.data)

        res?.data?.data?.maintenance_purchase_order?.map((mpo: any, ind: any) => {
          if (mpo?._id == poId) {
            let values = {
              name: mpo.documentName,
              purchaseOrderNo: mpo.purchaseOrderNo,
              items: mpo.items,
              sections: mpo.itemDescriptions,
              pdfURL: mpo?.pdfURL,
              createdAt: mpo?.createdAt,
            }

            let sections: any = []
            let sectInd: any = 0

            mpo.itemDescriptions?.map((sect: any, ind: any) => {
              if (sect?.title == 'Payment Terms Defaullttt') {
                setPaymentTerms(sect)
              } else {
                sections[sectInd] = sect
                sectInd++
              }

              return <></>
            })

            values.sections = sections

            setPurchaseOrderData(values)

            setPOCompany(mpo?.purchaseOrderCompany)
            setLogo(mpo?.purchaseOrderCompany?.logo)
            setVendorData(mpo?.vendorAddress)
            setDeliveryDetails(mpo?.deliverAddress)
            setSelectedDate(mpo?.deliverAddress?.deliverDate)

            if (
              mpo?.deliverAddress?.address1?.trim()?.length == 0 &&
              mpo?.deliverAddress?.address2?.trim()?.length == 0 &&
              mpo?.deliverAddress?.email?.trim()?.length == 0 &&
              mpo?.deliverAddress?.phoneNumber?.trim()?.length == 0 &&
              mpo?.deliverAddress?.deliverDate == null
            )
              setShowDeliveryDetails(false)
            else setShowDeliveryDetails(true)
          }

          return <></>
        })

        setIsLoading(false)

        setOpen(false)
      })
      .catch((err: any) => {
        setOpen(false)
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  //
  const getVendorById = (val: any) => {
    ApiGet(`corporate/vendor/${val}`)
      .then((res: any) => {
        setVendorData(res?.data?.data)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    if (flg == '1') getPODetails()
    getRequestById()
  }, [])

  //
  const addPO = (isDraft: any) => {
    setIsLoading(true)
    setOpen(true)

    let sections: any = []
    sections[0] = paymentTerms

    purchaseOrderData?.sections?.map((po: any, ind: any) => {
      sections[ind + 1] = po
      return <></>
    })

    let poData: any = [
      {
        _id: flg == '2' ? poId : undefined,
        saveStatus: isDraft ? 0 : 1,
        documentName: purchaseOrderData?.name,
        purchaseOrderNo: purchaseOrderData?.purchaseOrderNo,
        date: '',
        deliverAddress: deliveryDetails,
        pdfURL: '',
        purchaseOrderCompany: {
          name: poCompany?.name,
          address: poCompany?.address,
          countryCode: poCompany?.countryCode,
          phoneNumber: poCompany?.phoneNumber,
          email: poCompany?.email,
          logo: logo,
        },
        vendorAddress: {
          address1: vendorData?.address1,
          address2: vendorData?.address2,
          phoneNumber: vendorData?.phoneNumber,
          countryCode: vendorData?.countryCode,
          email: vendorData?.email,
        },
        items: purchaseOrderData?.items,
        itemDescriptions: sections,
        totalAmount: totalPrice,
        isManualPDF: false,
      },
    ]
    const body = {
      serviceRequest: {
        _id: id,
      },
      newPurchaseOrder: flg == '1' ? poData : [],
      updatePurchaseOrder: flg == '2' ? poData : [],
    }

    ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
      .then((res: any) => {
        if (!isDraft) {
          SuccessToast(res?.data?.message)

          let jobType: any
          if(requestData?.maintenanceJobType == 0)
            jobType = 'dev-clust'
          else if(requestData?.maintenanceJobType == 1)
            jobType = 'common-area'
          else if(requestData?.maintenanceJobType == 2)
            jobType = 'unit'

          navigate(`/maintenance/create-request/${type}/${flg}/${jobType}/${id}`, {
            state: {
              from: 'purchase',
            },
          })
        } else {
          if (flg == '1') {
            setTimeout(() => {
              setIsSaveFirst(true)
            }, 2000)
          } else {
            setOpen(false)
            setIsLoading(false)
          }
        }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setOpen(false)
        setIsLoading(false)
      })
  }

  //
  const deletePO = () => {
    setIsLoading(true)

    const body = {
      serviceRequest: {
        _id: id,
      },
      newPurchaseOrder: [],
      updatePurchaseOrder: [],
      deletePurchaseOrder: [{_id: poId}],
    }

    ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
      .then((res: any) => {
        SuccessToast('Purchase Order has been deleted successfully...')

        if (state?.from == 'view-maintenance-request') {
          navigate(`/maintenance/request/${type}/${id}`)
        } else {
          let jobType: any
          if(requestData?.maintenanceJobType == 0)
            jobType = 'dev-clust'
          else if(requestData?.maintenanceJobType == 1)
            jobType = 'common-area'
          else if(requestData?.maintenanceJobType == 2)
            jobType = 'unit'
          navigate(`/maintenance/create-request/${type}/${flg}/${jobType}/${id}`, {
            state: {
              from: 'purchase',
            },
          })
        }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setOpen(false)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (isSaveFirst == true) getRequestById()
  }, [isSaveFirst])

  useEffect(() => {
    if (flg == '2' && autoSaveData != false) addPO(true)
  }, [autoSaveData])

  const [showRecipientModal, setShowRecipientModal] = useState<any>(false)

  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const PAGE_HEIGHT = 1045
  const printElementRef = useRef(null)

  // const printAction = () => {
  //   const PAGE_HEIGHT = 1045
  //   const printElement = document.getElementById("print-component")
  //   console.log(printElement)
  //   if (printElement) {
  //       printElement.classList.add("temp-class-for-height")
  //       const height = printElement.clientHeight
  //       const numberOfPage = Math.ceil(height / PAGE_HEIGHT)
  //       console.log(numberOfPage)
  //       const heightWithSingleHeader = numberOfPage*PAGE_HEIGHT
  //       let requiredHeight = heightWithSingleHeader
  //       // console.log(numberOfPage)
  //       if (numberOfPage > 1) {
  //           const headerHeight = printElement.getElementsByTagName("thead")?.[0]?.clientHeight
  //           const footerHeight = printElement.getElementsByTagName("tfoot")?.[0]?.clientHeight
  //           requiredHeight -= (numberOfPage - 1) * (headerHeight + footerHeight)
  //       }
  //       printElement.style.height = `${requiredHeight}px`
  //       printElement.classList.remove("temp-class-for-height")
  //   }
  //   window.print()

  // }

  const [printtt, setPrinttt] = useState<any>(new Date())

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop
    console.log('')
    const currentPageNumber = Math.ceil(scrollTop / PAGE_HEIGHT) + 1
    setCurrentPage(currentPageNumber)
  }

  useEffect(() => {
    // setTimeout(()=>{
    const printElement: any = document.getElementById('print-component')

    if (printElement) {
      const height: any = printElement.clientHeight
      console.log(height)
      console.log(PAGE_HEIGHT)
      const numberOfPages = Math.ceil(height / PAGE_HEIGHT)
      console.log(numberOfPages)
      setTotalPages(numberOfPages)

      window.addEventListener('scroll', handleScroll)

      // return () => {
      //   window.removeEventListener('scroll', handleScroll)
      // }
    }
    // }, 2000)
  }, [printtt])

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2 no-print' id='kt_app_main '>
        <div id='' className='pt-0 mt-0'>
          {' '}
          {/* Header */}
          <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
            <div className=' d-flex flex-stack pt-1 mt-0 px-0'>
              {' '}
              <div className='d-flex align-items-center mb-1'>
                <span
                  className='rounded-circle cursor-pointer'
                  onClick={() => {
                    if (state?.from == 'view-maintenance-request') {
                      navigate(`/maintenance/request/${type}/${id}`)
                    } else{
                      let jobType: any
                        if(requestData?.maintenanceJobType == 0)
                          jobType = 'dev-clust'
                        else if(requestData?.maintenanceJobType == 1)
                          jobType = 'common-area'
                        else if(requestData?.maintenanceJobType == 2)
                          jobType = 'unit'
                      navigate(`/maintenance/create-request/${type}/${flg}/${jobType}/${id}`, {
                        state: {
                          from: 'purchase',
                        },
                      })
                    }
                  }}
                >
                  <img src={backArrow} height='14' width='14' />
                </span>
                <h2 className='m-0 head-text ms-2 text-capitalize'>{purchaseOrderData?.name}</h2>
              </div>
            </div>
          </div>
          <div className='d-flex mt-5 flex-wrap px-1'>
            <button
              className='btn btn-sm fw-bold ps-3 pe-4  text-white status-w-110 blue-btn'
              style={{
                backgroundColor: '#384a62',
                fontSize: '13px',
              }}
              onClick={() => {
                setShowRecipientModal(true)
              }}
            >
              <img src={share} height={18} width={18} className='me-4' /> {'  '}
              Share
            </button>

            <button
              className='btn btn-sm fw-bold ps-2 pe-4 ms-3 text-white status-w-110 h-31 d-flex align-items-center'
              style={{
                backgroundColor: '#8a8aa3',
                fontSize: '13px',
              }}
              onClick={() => {
                setPrinttt(new Date())
                window.print()
              }}
            >
              <img src={print} height={18} width={18} className='me-3' /> {'  '}
              Print
            </button>

            <div className='d-flex ms-auto'>
              <button
                type='button'
                className='btn btn-sm fw-bold  ms-3 red-hollow-btn ps-3 '
                onClick={() => {
                  swal
                    .fire({
                      html: ` 
                      <div class='fs-3'> <b>Delete Purchase Order</b></div>
                      <div class='fs-5 mt-4'> Are you want to delete this purchase order? </div>`,
                      // icon: 'warning',
                      showConfirmButton: true,
                      confirmButtonColor: '#35475e',
                      confirmButtonText: 'Yes',
                      showCancelButton: true,
                      cancelButtonText: 'No',
                      cancelButtonColor: '#fff',
                      customClass: {
                        confirmButton:
                          'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                        cancelButton:
                          'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                        popup: 'custom-popup',
                      },
                      // reverseButtons: true,
                    })
                    .then((res: any) => {
                      if (res.isConfirmed) {
                        deletePO()
                      }
                    })
                }}
                disabled={isLoading}
              >
                <img src={trashImg} height={18} width={18} className='me-4' /> Delete
              </button>

              <a href={`${Bucket}${purchaseOrderData?.pdfURL}`} target='_blank'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold blue-hollow-btn ms-3 btn-fit-content ps-2'
                  style={{width: 'fit-content'}}
                  onClick={() => {}}
                >
                  <img src={downloadImg} height={18} width={18} className='me-3' />
                  Download
                </button>
              </a>
            </div>
          </div>
          <div
            className='card mt-5 px-6 py-5 pb-20 lightgrey-box-shadow'
            style={{minHeight: '85vh'}}
          >
            <div className='d-flex justify-content-end'>
              {' '}
              <p style={{marginBottom: '0.1rem'}} className='fs-7 labl-gry'>
                1/2
              </p>
            </div>
            <Row className=''>
              <Col xs={6} sm={6} md={9} lg={9}>
                <div className='d-flex mb-3 mt-2 align-items-center'>
                  {logo == null ? (
                    <></>
                  ) : (
                    <img
                      // src={`${Bucket}${logo}`}
                      src={`${Bucket}${logo}`}
                      height='110'
                      width='110'
                      style={{borderRadius: '7px'}}
                      className='me-4'
                    />
                  )}
                  <div className='text-start me-6 fnt-600'>
                    <p style={{marginBottom: '0.5rem'}} className='white-dark-theme-color'>
                      {' '}
                      <b>{poCompany?.name != null && poCompany?.name}</b>
                    </p>
                    <p style={{marginBottom: '0.2rem'}} className='fs-7 white-dark-theme-color'>
                      {' '}
                      {poCompany?.address != null && poCompany?.address}
                    </p>

                    <p style={{marginBottom: '0.2rem'}} className='fs-7 white-dark-theme-color'>
                      {poCompany?.phoneNumber != null &&
                        poCompany?.countryCode + poCompany?.phoneNumber}
                    </p>
                    <p style={{marginBottom: '0.2rem'}} className='fs-7 white-dark-theme-color'>
                      {' '}
                      {poCompany?.email != null && poCompany?.email}
                    </p>
                  </div>
                </div>
              </Col>
              <Col xs={6} sm={6} md={3} lg={3} className='pe-4'>
                <div className='d-flex align-items-center my-2 '>
                  <h1 className='ms-auto mb-0 white-dark-theme-color'>PURCHASE ORDER</h1>
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2'>
                  <label
                    className='mb-1 me-3 ms-15 head-text fnt-600 light-dark-theme-color'
                    style={{
                      minWidth: '70px',
                      fontSize: '1rem',
                    }}
                  >
                    PO No.
                  </label>
                  <div className='ms-auto white-dark-theme-color fnt-500'>
                    <b> {flg == '2' ? purchaseOrderData?.purchaseOrderNo : '-'}</b>
                  </div>
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2'>
                  <label
                    className='mb-1 me-3 ms-15 head-text fnt-600 light-dark-theme-color'
                    style={{
                      minWidth: '70px',
                      fontSize: '1rem',
                    }}
                  >
                    Issued Date
                  </label>
                  <div className='ms-auto white-dark-theme-color fnt-500'>
                    {' '}
                    <b>
                      {purchaseOrderData?.createdAt
                        ? `${moment
                            .utc(purchaseOrderData?.createdAt)
                            .local()
                            .format('DD.MM.YYYY')} `
                        : '-'}
                    </b>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className='mt-5'>
              {/* Vendor Details */}
              <Col md={6}>
                <div className='w-75 ms-8'>
                  <div className='d-flex align-items-center'>
                    <h3 className='mb-4 fs-3 white-dark-theme-color fnt-600'>
                      <b>Vendor</b>
                    </h3>
                  </div>
                 
                  <h3 className='mb-3 fs-4 fnt-500 white-dark-theme-color'><b>{vendorData?.name}</b> </h3>
                  <h4 className='my-5 white-dark-theme-color' style={{color: 'black'}}>{vendorData?.address1 != null ? vendorData?.address1 : '-'} </h4>
                  <h4 className='my-5 white-dark-theme-color' style={{color: 'black'}}>{vendorData?.address2 != null ? vendorData?.address2 : '-'}</h4>
                  <h4 className='my-5 white-dark-theme-color' style={{color: 'black'}}>{vendorData?.phoneNumber != null
                      ? `${vendorData?.countryCode} ${vendorData?.phoneNumber}`
                      : '-'}
                  </h4>
                  <h4 className='my-5 white-dark-theme-color' style={{color: 'black'}}>{vendorData?.email != null ? vendorData?.email : '-'}</h4>
                </div>
              </Col>

              {/* Delivery Details */}
              {showDeliveryDetails && (
                <>
                  <Col md={6} className=''>
                    <div
                      className='justify-content-end'
                      style={{ display: 'grid'}}
                    >
                      <div className='d-flex align-items-center'>
                        <h3 className='mb-3 fs-3 white-dark-theme-color'>
                          <b>Deliver To</b>
                        </h3>
                      </div>
                      <div className='d-flex  vendor'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color status-w-130'>
                            {'Delivery Address '}
                          </label>
                        </h4>
                        <h4 className='my-2 white-dark-theme-color' style={{color: 'black'}}>
                          {' '}
                          {deliveryDetails?.address1 ? deliveryDetails?.address1 : '-'}
                        </h4>
                      </div>

                      {/* Address 2 */}
                      <div className='d-flex  vendor'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color status-w-130'>
                            {' '}
                          </label>
                        </h4>
                        <h4 className='my-2 white-dark-theme-color' style={{color: 'black'}}>
                          {' '}
                          {deliveryDetails?.address2 ? deliveryDetails?.address2 : '-'}
                        </h4>
                      </div>

                      {/* Phone No. */}
                      <div className='d-flex'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color status-w-130'>
                            {' '}
                          </label>
                        </h4>
                        <h4 className='my-2 white-dark-theme-color' style={{color: 'black'}}>
                          {' '}
                          {deliveryDetails?.phoneNumber
                            ? `+${deliveryDetails?.countryCode} ${deliveryDetails?.phoneNumber}`
                            : '-'}
                        </h4>
                      </div>

                      {/* Delivery Email*/}
                      <div className='d-flex  vendor'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color status-w-130'></label>
                        </h4>
                        <h4 className='my-2 white-dark-theme-color' style={{color: 'black'}}>
                          {deliveryDetails?.email ? deliveryDetails?.email : '-'}
                        </h4>
                      </div>

                      {/* */}
                      <div className='d-flex  vendor'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color status-w-130'>
                            {'Delivery By '}
                          </label>
                        </h4>
                        <h4 className='my-2 white-dark-theme-color' style={{color: 'black'}}>
                          {deliveryDetails?.date
                            ? `${moment.utc(deliveryDetails?.date).local().format('DD.MM.YYYY')}`
                            : '-'}
                        </h4>
                      </div>
                    </div>
                  </Col>
                </>
              )}
            </Row>
            <Row className='mt-5  mx-0' style={{borderTop: '1px solid lightgrey'}}></Row>

            <div
              className='table-responsive mt-4 mb-10'
              style={{maxHeight: '400px', overflow: 'auto'}}
            >
              <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
                <thead>
                  <th className='text-start min-w-25px ps-3'></th>
                  <th className='text-start min-w-225px ps-3'>Description</th>
                  <th className='text-start min-w-100px ps-3'>Quantity</th>
                  <th className='text-start min-w-100px ps-3'>Unit Priice</th>
                  <th className='text-start min-w-150px ps-3'>Total Price</th>
                </thead>

                <>
                  {purchaseOrderData?.items?.map((itm: any) => {
                    totalPrice = totalPrice + parseFloat(itm?.quantity) * parseFloat(itm?.unitPrice)
                    return <></>
                  })}
                </>

                <tbody className='fw-semibold head-text'>
                  {purchaseOrderData?.items?.map((item: any, index: any) => {
                    return (
                      <>
                        <tr>
                          <td className='text-start ps-3'><p className='pt-2 gry-dark-text i_grey'> {index < 10 ? `0${index + 1}` : index + 1}</p>
                          </td>
                          <td className='text-start ps-3'>
                            <p className='my-2 white-dark-theme-color'> {item?.description ? item?.description : '-'}</p>
                          </td>
                          <td className='text-start ps-3'>
                            <p className='my-2 white-dark-theme-color'> {item?.quantity ? item?.quantity : '-'}</p>
                          </td>
                          <td className='text-start ps-3'>
                           <p className='my-2 white-dark-theme-color'>
                             
                              {item?.unitPrice
                                ? item?.totalPrice?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })
                                : '-'}
                            </p>
                          </td>
                          <td className='text-start ps-3'>
                            <p className='pt-2 head-text'>
                              <b>
                                {'AED'}{' '}
                                {item?.totalPrice?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}
                              </b>
                            </p>
                          </td>
                        </tr>
                      </>
                    )
                  })}

                  <tr className='mt-5' style={{borderTop: '1px solid lightgrey'}}>
                    <td className='text-start ps-3'></td>
                    <td className='text-start ps-3'></td>
                    <td className='text-start ps-3'></td>
                    <td className='text-end ps-3'></td>
                    <td className='text-start ps-3'></td>
                  </tr>
                  <tr>
                    <td className='text-start ps-3'></td>
                    <td className='text-start ps-3'></td>
                    <td className='text-start ps-3'></td>
                    <td className='text-start ps-3'>
                      <p className='pt-2 head-text ps-10'>
                        <b>{'Total Amount'}</b>
                      </p>
                    </td>
                    <td className='text-start min-w-100px ps-3'>
                      <p className='pt-2 head-text'>
                        <b>
                          {' '}
                          {'AED'}{' '}
                          {totalPrice?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}{' '}
                        </b>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {purchaseOrderData?.sections?.length == 0 && (
              <>
                <Row className='mt-5  mx-0' style={{borderTop: '1px solid lightgrey'}}></Row>

                <div className='mt-20'>
                  <Row className='mt-15'>
                    <Col sm={6}>
                      <div className='d-flex'>
                        <p className='fs-4 status-w-120 white-dark-theme-color'> Authorized By </p>
                        <p className='white-dark-theme-color'>______________________________________________</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-10'>
                    <Col sm={6}>
                      <div className='d-flex'>
                        <p className='fs-4 status-w-120 white-dark-theme-color'> Signature </p>
                        <p className='white-dark-theme-color'>______________________________________________</p>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className='d-flex'>
                        <p className='fs-4 status-w-120 white-dark-theme-color'> Date </p>
                        <p className='white-dark-theme-color'>______________________________________________</p>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className='d-flex mb-5 mt-10 '>
                  <h3 className='m-0 head-text text-capitalize mt-4 '>
                    <b>{`Vendor`}</b>
                  </h3>
                </div>

                <Row className='mt-7 mb-10'>
                  <Col sm={6}>
                    <div className='d-flex'>
                      <p className='fs-4 status-w-120 white-dark-theme-color'> Signature </p>
                      <p className='white-dark-theme-color'>______________________________________________</p>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className='d-flex'>
                      <p className='fs-4 status-w-120 white-dark-theme-color'> Date </p>
                      <p className='white-dark-theme-color'>______________________________________________</p>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
          {purchaseOrderData?.sections?.length > 0 && (
            <>
              <div className='page-break'></div>
              <div
                className='card mt-10 px-6 py-5 lightgrey-box-shadow'
                style={{minHeight: '85vh'}}
              >
                <div className='d-flex justify-content-end'>
                  {' '}
                  <p style={{marginBottom: '0.1rem'}} className='fs-7 labl-gry'>
                    2/2
                  </p>
                </div>

                {/* <div className='d-flex mb-5'>
              <h3 className='m-0 head-text text-capitalize'>
                <b>{`Payment Terms`}</b>
              </h3>
            </div> */}

                {/* <div className='po-editor-view'>
              <TextEditor
                value={paymentTerms?.html}
                onChange={(val: any) => {
                  let values = {...paymentTerms}
                  values.html = val
                  setPaymentTerms(values)
                }}
                autoSaveText={() => {
                  if (flg == '2') {
                    setTimeout(() => {
                      setAutoSaveData(Date.now())
                    }, 700)
                  }
                }}
                refresh={paymentTerms}
                placeholder={'Add your payment terms here...'}
              />
            </div> */}

                {purchaseOrderData?.sections?.map((pr: any, ind: any) => {
                  return (
                    <div className={`mb-10`}>
                      <div className={`d-flex mb-4 mt-4 align-items-center`}>
                        <h3 className='m-0 text-capitalize white-dark-theme-color'>
                          <b>{pr?.title ? pr?.title : '-'}</b>
                        </h3>
                        <div className='d-flex ms-auto align-items-center'></div>
                      </div>
                      <div className='po-editor-view'>
                        <TextEditor
                          value={pr?.html}
                          onChange={(val: any) => {
                            // let values = {...purchaseOrderData}
                            // let valuesSections = [...purchaseOrderData?.sections]
                            // valuesSections[ind].html = val
                            // values.sections = valuesSections
                            // setPurchaseOrderData(values)
                          }}
                          autoSaveText={() => {
                            // if (flg == '2') {
                            //   setTimeout(() => {
                            //     setAutoSaveData(Date.now())
                            //   }, 700)
                            // }
                          }}
                          refresh={purchaseOrderData}
                          placeholder={'---'}
                          isReadOnly={true}
                        />
                      </div>
                    </div>
                  )
                })}

                {/* <Row className='mt-10  mx-0' style={{borderTop: '1px solid lightgrey'}}></Row> */}

                <div className='mt-20'>
                  <Row className='mt-10'>
                    <Col sm={6}>
                      <div className='d-flex'>
                        <p className='fs-4 status-w-120 white-dark-theme-color'> Authorized By </p>
                        <p className='white-dark-theme-color'>______________________________________________</p>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-10'>
                    <Col sm={6}>
                      <div className='d-flex'>
                        <p className='fs-4 status-w-120 white-dark-theme-color'> Signature </p>
                        <p className='white-dark-theme-color'>______________________________________________</p>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className='d-flex'>
                        <p className='fs-4 status-w-120 white-dark-theme-color'> Date </p>
                        <p className='white-dark-theme-color'>______________________________________________</p>
                      </div>
                    </Col>
                  </Row>
                </div>

                <div className='d-flex mb-5 mt-10 '>
                  <h3 className='m-0 head-text text-capitalize mt-4'>
                    <b>{`Vendor`}</b>
                  </h3>
                </div>

                <Row className='mt-7 mb-10'>
                  <Col sm={6}>
                    <div className='d-flex'>
                      <p className='fs-4 status-w-120 white-dark-theme-color'> Signature </p>
                      <p className='white-dark-theme-color'>______________________________________________</p>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className='d-flex'>
                      <p className='fs-4 status-w-120 white-dark-theme-color'> Date </p>
                      <p className='white-dark-theme-color'>______________________________________________</p>
                    </div>
                  </Col>
                </Row>

                {/* {purchaseOrderData && <DnDCard data={purchaseOrderData} />} */}
              </div>
            </>
          )}
        </div>
      </div>

      <ShareWithRecipients
        isVisible={showRecipientModal}
        handleClose={() => {
          setShowRecipientModal(false)
        }}
      />

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>

      {/* Screen for Print */}
      <div id='print-component' ref={printElementRef} className='bg-white'>
        <div className='app-main flex-column flex-row-fluid px-2 print-invoice '>
          <div id='' className='pt-0 mt-0 a4-min-height'>
            <div className='px-6 py-5 pb-20 pt-0'>
              {/* <div style={{position: 'fixed', top: '10px', right: '37px'}} className='labl-gry'>
                {currentPage}/{totalPages}
              </div> */}

              {/* <div className='d-flex justify-content-end'>
              {' '}
              <p style={{marginBottom: '0.1rem'}} className='fs-7 labl-gry'>
                1/2
              </p>
            </div> */}
              <Row className=''>
                <Col xs={6} sm={6} md={9} lg={9}>
                  <div className='d-flex mb-3 mt-0 align-items-center'>
                    {logo == null ? (
                      <></>
                    ) : (
                      <img
                        // src={`${Bucket}${logo}`}
                        src={`${Bucket}${logo}`}
                        height='110'
                        width='110'
                        style={{borderRadius: '7px'}}
                        className='me-4'
                      />
                    )}
                    <div className='text-start me-6' style={{fontWeight: '600'}}>
                      <p style={{marginBottom: '0.5rem'}}>
                        {' '}
                        <b>{poCompany?.name != null && poCompany?.name}</b>
                      </p>
                      <p style={{marginBottom: '0.2rem'}} className='fs-7'>
                        {' '}
                        {poCompany?.address != null && poCompany?.address}
                      </p>

                      <p style={{marginBottom: '0.2rem'}} className='fs-7'>
                        {poCompany?.phoneNumber != null &&
                          poCompany?.countryCode + poCompany?.phoneNumber}
                      </p>
                      <p style={{marginBottom: '0.2rem'}} className='fs-7'>
                        {' '}
                        {poCompany?.email != null && poCompany?.email}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={3} lg={3} className='pe-4'>
                  <div className='d-flex align-items-center my-2 mt-0 '>
                    <h1 className='ms-auto mb-0'>PURCHASE ORDER</h1>
                  </div>

                  {/*  */}
                  <div
                    className='d-flex align-items-center justify-content-start my-2'
                    style={{paddingLeft: '100px'}}
                  >
                    <label
                      className='mb-1 me-3 ms-15 ps-10'
                      style={{
                        minWidth: '70px',
                        fontSize: '1rem',
                        color: '#516176',
                        fontWeight: '600',
                      }}
                    >
                      PO No.
                    </label>
                    <div className='ms-auto' style={{fontWeight: '500'}}>
                      <b> {flg == '2' ? purchaseOrderData?.purchaseOrderNo : '-'}</b>
                    </div>
                  </div>

                  {/*  */}
                  <div
                    className='d-flex align-items-center justify-content-start my-2'
                    style={{paddingLeft: '100px'}}
                  >
                    <label
                      className='mb-1 me-3 ms-15 ps-10'
                      style={{
                        minWidth: '70px',
                        fontSize: '1rem',
                        color: '#516176',
                        fontWeight: '600',
                      }}
                    >
                      Issued Date
                    </label>
                    <div className='ms-auto' style={{fontWeight: '500'}}>
                      <b>
                        {purchaseOrderData?.createdAt
                          ? `${moment
                              .utc(purchaseOrderData?.createdAt)
                              .local()
                              .format('DD.MM.YYYY')} `
                          : '-'}
                      </b>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row className='mt-5'>
                <Col xs={6} sm={6} md={6} lg={6}>
                  <div className='mx-10'>
                    <div className='d-flex align-items-center mb-5'>
                      <h3 className='mb-0 fs-3' style={{fontWeight: '600'}}>
                        <b>Vendor</b>
                      </h3>
                    </div>

                    <h3 className='mb-5 fs-4'>
                      <b>{vendorData?.name ? vendorData?.name : '-'}</b>
                    </h3>

                    {vendorData?.address1 && (
                      <h4 className='mb-4' style={{color: 'black'}}>
                        {' '}
                        {vendorData?.address1 != null ? vendorData?.address1 : '-'}
                      </h4>
                    )}

                    {/* Vendor Address 02  */}
                    {vendorData?.address2 != null && (
                      <h4 className='mb-4' style={{color: 'black'}}>
                        {' '}
                        {vendorData?.address2 != null ? vendorData?.address2 : '-'}
                      </h4>
                    )}
                    {vendorData?.phoneNumber != null && (
                      <h4 className='my-4' style={{color: 'black'}}>
                        {' '}
                        {vendorData?.phoneNumber != null
                          ? `${vendorData?.countryCode} ${vendorData?.phoneNumber}`
                          : '-'}
                      </h4>
                    )}

                    <h4 className='mb-5' style={{color: 'black'}}>
                      {' '}
                      {vendorData?.email != null ? vendorData?.email : '-'}
                    </h4>
                  </div>
                </Col>
                {showDeliveryDetails && (
                  <Col xs={6} sm={6} md={6} lg={6} className='pe-4'>
                    <div
                      className=''
                      style={{
                        justifyContent: 'end',
                        display: 'grid',
                      }}
                    >
                      <div className='d-flex align-items-center'>
                        <h3 className='mb-3 fs-3'>
                          <b>Deliver To</b>
                        </h3>
                      </div>

                      <div className='d-flex  vendor'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label style={{width: '130px'}} className='labl-gry'>
                            {'Delivery Address '}
                          </label>
                        </h4>
                        <h4 className='my-2' style={{color: 'black'}}>
                          {' '}
                          {deliveryDetails?.address1 ? deliveryDetails?.address1 : '-'}
                        </h4>
                      </div>

                      {/* Address 2 */}
                      <div className='d-flex  vendor'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label style={{width: '130px'}} className='labl-gry'>
                            {' '}
                          </label>
                        </h4>
                        <h4 className='my-2' style={{color: 'black'}}>
                          {' '}
                          {deliveryDetails?.address2 ? deliveryDetails?.address2 : '-'}
                        </h4>
                      </div>

                      {/* Phone No. */}
                      <div className='d-flex'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label style={{width: '130px'}} className='labl-gry'>
                            {' '}
                          </label>
                        </h4>
                        <h4 className='my-2' style={{color: 'black'}}>
                          {' '}
                          {deliveryDetails?.phoneNumber
                            ? `+${deliveryDetails?.countryCode} ${deliveryDetails?.phoneNumber}`
                            : '-'}
                        </h4>
                      </div>

                      {/* Delivery Email*/}
                      <div className='d-flex  vendor'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label style={{width: '130px'}} className='labl-gry'>
                            {' '}
                          </label>
                        </h4>
                        <h4 className='my-2' style={{color: 'black'}}>
                          {' '}
                          {deliveryDetails?.email ? deliveryDetails?.email : '-'}
                        </h4>
                      </div>

                      {/* */}
                      <div className='d-flex  vendor'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label style={{width: '130px'}} className='labl-gry'>
                            {'Delivery By '}
                          </label>
                        </h4>
                        <h4 className='my-2' style={{color: 'black'}}>
                          {' '}
                          {deliveryDetails?.date
                            ? `${moment.utc(deliveryDetails?.date).local().format('DD.MM.YYYY')}`
                            : '-'}
                        </h4>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
              <Row className='mt-5  mx-0' style={{borderTop: '1px solid lightgrey'}}></Row>

              <div
                className='table-responsive mt-4 mb-10'
                style={{maxHeight: '400px', overflow: 'auto'}}
              >
                <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
                  <thead>
                    <th className='text-start min-w-25px ps-3'></th>
                    <th className='text-start min-w-225px ps-3'>Description</th>
                    <th className='text-start min-w-100px ps-3'>Quantity</th>
                    <th className='text-start min-w-100px ps-3'>Unit Priice</th>
                    <th className='text-start min-w-150px ps-3'>Total Price</th>
                  </thead>

                  <>
                    {purchaseOrderData?.items?.map((itm: any) => {
                      totalPrice =
                        totalPrice + parseFloat(itm?.quantity) * parseFloat(itm?.unitPrice)
                      return <></>
                    })}
                  </>

                  <tbody className='fw-semibold text-gray-600'>
                    {purchaseOrderData?.items?.map((item: any, index: any) => {
                      return (
                        <>
                          <tr>
                            <td className='text-start min-w-25px ps-3'>
                              {' '}
                              <p className='pt-2'> {index < 10 ? `0${index + 1}` : index + 1}</p>
                            </td>
                            <td className='text-start min-w-225px ps-3'>
                              <p className='my-2' style={{color: 'black'}}>
                                {' '}
                                {item?.description ? item?.description : '-'}
                              </p>
                            </td>
                            <td className='text-start min-w-100px ps-3'>
                              <p className='my-2' style={{color: 'black'}}>
                                {' '}
                                {item?.quantity ? item?.quantity : '-'}
                              </p>
                            </td>
                            <td className='text-start min-w-100px ps-3'>
                              <p className='my-2' style={{color: 'black'}}>
                                {' '}
                                {item?.unitPrice
                                  ? item?.totalPrice?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })
                                  : '-'}
                              </p>
                            </td>
                            <td className='text-start min-w-100px ps-3'>
                              <p className='pt-2 head-text'>
                                <b>
                                  {'AED'}{' '}
                                  {item?.totalPrice?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}
                                </b>
                              </p>
                            </td>
                          </tr>
                        </>
                      )
                    })}

                    <tr className='mt-5' style={{borderTop: '1px solid lightgrey'}}>
                      <td className='text-start ps-3'></td>
                      <td className='text-start ps-3'></td>
                      <td className='text-start ps-3'></td>
                      <td className='text-end ps-3'></td>
                      <td className='text-start ps-3'></td>
                    </tr>
                    <tr>
                      <td className='text-start ps-3'></td>
                      <td className='text-start ps-3'></td>
                      <td className='text-start ps-3'></td>
                      <td className='ps-3'>
                        <p className='pt-2 head-text ps-6'>
                          <b>{'Total Amount'}</b>
                        </p>
                      </td>
                      <td className='text-start min-w-100px ps-3'>
                        <p className='pt-2 head-text'>
                          <b>
                            {' '}
                            {'AED'}{' '}
                            {totalPrice?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}{' '}
                          </b>
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {purchaseOrderData?.sections?.length == 0 && (
                <>
                  {/* <Row className='mt-5  mx-0' style={{borderTop: '1px solid lightgrey'}}></Row> */}

                  <div className='mt-30'>
                    <Row className='mt-15'>
                      <Col sm={12}>
                        <div className='d-flex'>
                          <p className='fs-4 ' style={{minWidth: '100px'}}>
                            {' '}
                            Authorized By{' '}
                          </p>
                          <p>__________________________________</p>
                        </div>
                      </Col>
                    </Row>
                    <Row className='mt-10'>
                      <Col sm={6}>
                        <div className='d-flex'>
                          <p className='fs-4 ' style={{minWidth: '100px'}}>
                            {' '}
                            Signature{' '}
                          </p>
                          <p>__________________________________</p>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className='d-flex ms-10'>
                          <p className='fs-4 ' style={{minWidth: '80px'}}>
                            {' '}
                            Date{' '}
                          </p>
                          <p>__________________________________</p>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className='d-flex mb-5 mt-10 '>
                    <h3 className='m-0 head-text text-capitalize mt-4'>
                      <b>{`Vendor`}</b>
                    </h3>
                  </div>

                  <Row className='mt-10 mb-10'>
                    <Col sm={6}>
                      <div className='d-flex'>
                        <p className='fs-4 ' style={{minWidth: '100px'}}>
                          {' '}
                          Signature{' '}
                        </p>
                        <p>__________________________________</p>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className='d-flex ms-10'>
                        <p className='fs-4 ' style={{minWidth: '80px'}}>
                          {' '}
                          Date{' '}
                        </p>
                        <p>__________________________________</p>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
            {purchaseOrderData?.sections?.length > 0 && (
              <>
                <div className='page-break'></div>
                <div className='mt-0 px-6 py-5 pt-0'>
                  {/* <div className='d-flex justify-content-end'>
                  {' '}
                  <p style={{marginBottom: '0.1rem'}} className='fs-7 labl-gry'>
                    2/2
                  </p>
                </div> */}

                  {/* <div className='d-flex mb-5'>
              <h3 className='m-0 head-text text-capitalize'>
                <b>{`Payment Terms`}</b>
              </h3>
            </div> */}

                  {/* <div className='po-editor-view'>
              <TextEditor
                value={paymentTerms?.html}
                onChange={(val: any) => {
                  let values = {...paymentTerms}
                  values.html = val
                  setPaymentTerms(values)
                }}
                autoSaveText={() => {
                  if (flg == '2') {
                    setTimeout(() => {
                      setAutoSaveData(Date.now())
                    }, 700)
                  }
                }}
                refresh={paymentTerms}
                placeholder={'Add your payment terms here...'}
              />
            </div> */}

                  {purchaseOrderData?.sections?.map((pr: any, ind: any) => {
                    return (
                      <div className={`mb-10`}>
                        <div className={`d-flex mb-4 mt-4 align-items-center`}>
                          <h3 className='m-0 text-capitalize'>
                            <b>{pr?.title ? pr?.title : '-'}</b>
                          </h3>
                          <div className='d-flex ms-auto align-items-center'></div>
                        </div>
                        <div className='po-editor-view'>
                          <TextEditor
                            value={pr?.html}
                            onChange={(val: any) => {
                              let values = {...purchaseOrderData}
                              let valuesSections = [...purchaseOrderData?.sections]
                              valuesSections[ind].html = val
                              values.sections = valuesSections
                              setPurchaseOrderData(values)
                            }}
                            autoSaveText={() => {
                              if (flg == '2') {
                                setTimeout(() => {
                                  setAutoSaveData(Date.now())
                                }, 700)
                              }
                            }}
                            refresh={purchaseOrderData}
                            placeholder={'---'}
                          />
                        </div>
                      </div>
                    )
                  })}

                  {/* <Row className='mt-10  mx-0' style={{borderTop: '1px solid lightgrey'}}></Row> */}

                  <div className='' style={{marginTop: '100px'}}>
                    <Row className='mt-30'>
                      <Col sm={12}>
                        <div className='d-flex'>
                          <p className='fs-4 ' style={{minWidth: '100px'}}>
                            {' '}
                            Authorized By{' '}
                          </p>
                          <p>__________________________________</p>
                        </div>
                      </Col>
                    </Row>
                    <Row className='mt-10'>
                      <Col sm={6}>
                        <div className='d-flex'>
                          <p className='fs-4 ' style={{minWidth: '100px'}}>
                            {' '}
                            Signature{' '}
                          </p>
                          <p>__________________________________</p>
                        </div>
                      </Col>
                      <Col sm={6}>
                        <div className='d-flex ms-10'>
                          <p className='fs-4 ' style={{minWidth: '80px'}}>
                            {' '}
                            Date{' '}
                          </p>
                          <p>__________________________________</p>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className='d-flex mb-5 mt-10 '>
                    <h3 className='m-0 head-text text-capitalize mt-4'>
                      <b>{`Vendor`}</b>
                    </h3>
                  </div>

                  <Row className='mt-10 mb-10'>
                    <Col sm={6}>
                      <div className='d-flex'>
                        <p className='fs-4 ' style={{minWidth: '100px'}}>
                          {' '}
                          Signature{' '}
                        </p>
                        <p>__________________________________</p>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className='d-flex ms-10'>
                        <p className='fs-4 ' style={{minWidth: '80px'}}>
                          {' '}
                          Date{' '}
                        </p>
                        <p>__________________________________</p>
                      </div>
                    </Col>
                  </Row>

                  {/* {purchaseOrderData && <DnDCard data={purchaseOrderData} />} */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ViewPOTemplate
