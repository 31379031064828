import React, {useState, useEffect} from 'react'
import {Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import plusBlue from '../../../img/add-blue.png'
import moment, {Moment} from 'moment'
import close from '../../../img/close.png'
import Select from 'react-select'
import bin from '../../../img/bin.png'
import Swal from 'sweetalert2'
import reminderWhite from '../../../img/notification-white.png'
import receiptWhite from '../../../img/receipt-white.png'
import saveImg from '../../../img/Save-White.png'
import redCross from '../../../img/x-mark.png'
import invoiceWhite from '../../../img/invoice-white.png'
import file from '../../../img/file.png'
import chequeImg from '../../../img/cheque.png'
import editPay from '../../../img/edit-payment.png'
import up from '../../../img/black-collap-up.png'
import down from '../../../img/black-collap-down.png'
import invoiceBlue from '../../../img/invoice-blue.png'
import receiptBlue from '../../../img/receipt-blue.png'
import reminderBlue from '../../../img/notification-blue.png'
import calender from '../../../img/calendar-view.png'
import voidInvoiceImg from '../../../img/void-invoice.png'
import upWhite from '../../../img/up-collap.png'
import downWhite from '../../../img/down-arrow.png'
import creditNoteBlue from '../../../img/credit-note-blue.png'
import {Button, ButtonGroup, Col, Dropdown, Form, Row, Collapse, Card} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {MultiSelect} from 'react-multi-select-component'
import {useNavigate} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import refundBlue from '../../../img/refund-blue.png'

import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import editpen from '../../../img/pen.png'
import Backdrop from '@mui/material/Backdrop'
import {differenceInDays, parseISO} from 'date-fns'
import {DatePicker, Space} from 'antd'

import debitNoteBlue from '../../../img/debit-blue.png'
import {AiOutlineExclamationCircle} from 'react-icons/ai'

import ViewAllReceipts from './ViewAllReceipts'
import ViewAllAttachments from './ViewAllAttachments'
import ViewAllReminders from './ViewAllReminders'
import ViewAllInvoices from './ViewAllInvoices'
import ViewInvoice from './ViewInvoice'
import ViewReceipt from './ViewReceipt'
import CreditNote from './CreditNote'
import ViewCreditNote from './ViewCreditNote'
import ViewDraftInvoice from './ViewDraftInvoice'
import DebitNote from './DebitNote'
import ViewDebitNote from './ViewDebitNote'
import ViewAllDebitNotes from './ViewAllDebitNotes'
import ViewAllDebitNotesOfPayment from './VieAllDebitNotesOfPayment'
import Refund from './Refund'
import ViewRefund from './ViewRefund'
import ViewAllRefundsOfPayment from './ViewAllRefundsOfPayment'

import './style.scss'

import ReactPaginate from 'react-paginate'
import crossbtn from '../../../img/cross-button.png'
import linkImg from '../../../img/link.png'
import Switch from 'react-switch'

import lock from '../../../img/padlock.png'

const {RangePicker} = DatePicker

interface ButtonProps {
  show: any
  handleClose: any
  incomingPaymentId: any
  id: any
  subItemId: any
  subId: any
  getAllPayments: any
  unitDetails: any
  detailsToEdit: any
  type: any
}

let selectedProperty: any = []

const EditPaymentModal = ({
  handleClose,
  show,
  incomingPaymentId,
  id,
  subItemId,
  subId,
  getAllPayments,
  unitDetails,
  detailsToEdit,
  type,
}: ButtonProps) => {
  const navigate = useNavigate()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [viewReceipt, setViewReceipt] = useState<any>(false)
  const [receiptToShowEdit, setReceiptToShowEdit] = useState<any>()

  const [viewDraftInvoice, setViewDraftInvoice] = useState<any>(false)
  const [viewInvoice, setViewInvoice] = useState<any>(false)
  const [invoiceToShowEdit, setInvoiceToShowEdit] = useState<any>()

  const [paymentInvoices, setPaymentInvoices] = useState<any>([])
  const [paymentDebitNotes, setPaymentDebitNotes] = useState<any>([])

  const [isSelected, setIsSelected] = useState<any>(false)
  const [isEditt, setIsEditt] = useState<any>(false)

  const [showEdit, setShowEdit] = useState<any>(false)
  const [showEditMethod, setShowEditMethod] = useState<any>(false)
  const [showEditAttach, setShowEditAttach] = useState<any>(false)
  const [showEditNote, setShowEditNote] = useState<any>(false)
  const [showEditDue, setShowEditDue] = useState<any>(false)

  const [notesCount, setNotesCount] = useState<any>('0/100')

  const [isItemChanged, setIsItemChanged] = useState<any>(false)
  const [isInvChanged, setIsInvChanged] = useState<any>(false)

  const [developmentName, setDevelopmentName] = useState<any>('')
  const [clusterName, setClusterName] = useState<any>('')
  const [unitName, setUnitName] = useState<any>('')
  const [tenancyContract, setTenancyContract] = useState<any>('')
  const [buildingName, setBuildingName] = useState<any>('')
  const [floorName, setFloorName] = useState<any>('')

  const [discardChanges, setDiscardChanges] = useState<any>(false)

  const [checkedRec, setCheckedRec] = useState<any>(false)

  const [discardFields, setDiscardFields] = useState<any>([])

  const [deletedDontApply, setDeletedDontApply] = useState<any>([])
  const [applyChanges, setApplyChanges] = useState<any>([])

  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)

  const [lateID, setLateID] = useState<any>()

  const [isRadio, setIsRadio] = useState<any>([])

  const [dueDateForPayment, setDueDateForPayment] = useState<any>(null)
  const [checkVerify, setCheckVerify] = useState<any>(detailsToEdit?.isCardVerificationEnable)

  const [updatedItem, setUpdatedItem] = useState<any>([
    {
      _id: subItemId,
      // id: `${subId}`,
      id: subId,
      item: '',
      amount: '0',
      qty: '1',
      vat: '0',
      total: '',
      lateApplied: false,
      lateDetails: [],
      activeLATE: false,
      InactiveLATE: false,
      lateFeeTotal: 0,
      lateOnce: false,
    },
  ])

  const [paymentItems, setPaymentItems] = useState<any>([
    {value: '0', label: 'Booking'},
    {value: '1', label: 'Maintenance'},
    {value: '2', label: 'Rent'},
    {value: '3', label: 'Item 01'},
    {value: '4', label: 'Item 01'},
  ])

  const [cardItems, setCardItems] = useState<any>([
    {value: '0', label: 'Card'},
    {value: '1', label: 'Cheque'},
    {value: '2', label: 'Bank'},
    {value: '3', label: 'Cash'},
  ])
  const [bankAccountItems, setBankAccountItems] = useState<any>([
    {value: '0', label: 'Account 1'},
    {value: '1', label: 'Account 2'},
    {value: '2', label: 'Account 3'},
    {value: '3', label: 'Account 4'},
  ])

  const [selectedAccount, setSelectedAccount] = useState<any>()
  const [selectedAccountReceipt, setSelectedAccountReceipt] = useState<any>()
  const [selectedAccountInvoice, setSelectedAccountInvoice] = useState<any>()

  const [chequeBankName, setChequeBankName] = useState<any>()
  const [receiptChequeBankName, setReceiptChequeBankName] = useState<any>()
  const [invoiceChequeBankName, setInvoiceChequeBankName] = useState<any>()

  const [showFiltered, setShowFiltered] = useState<any>(false)
  const [filteredData, setFilteredData] = useState<any>()

  const [paymentCategory, setPaymentCategory] = useState<any>(0)
  const [collapse, setcollapse] = useState(false)
  const [collapseInv, setcollapseInv] = useState(false)
  const [collapseRec, setcollapseRec] = useState(false)
  const [collapseRemind, setcollapseRemind] = useState(false)
  const [collapseAttach, setcollapseAttach] = useState(false)
  const [collapseProp, setcollapseProp] = useState(false)
  const [collapseCreditNote, setcollapseCreditNote] = useState(false)
  const [collapseDebitNote, setcollapseDebitNote] = useState(false)
  const [collapseRefund, setcollapseRefund] = useState(false)

  const [creditNoteModal, setCreditNoteModal] = useState(false)

  const [recipientsModal, setRecipientsModal] = useState(false)
  const [lateFeeModal, setLateFeeModal] = useState(false)
  const [lateFeeData, setLateFeeData] = useState<any>([])

  const [recipientsEmails, setRecipientsEmails] = useState<any>([
    {
      email: '',
    },
  ])

  const handleEmailsAddFields = () => {
    const values = [...recipientsEmails]
    values.push({
      email: '',
    })
    setRecipientsEmails(values)
  }

  const handleEmailRemoveFields = (index: any) => {
    const values = [...recipientsEmails]
    values.splice(index, 1)
    setRecipientsEmails(values)
  }

  const [recordUpd, setRecordUpd] = useState<any>(1)

  const [viewAllInvoices, setViewAllInvoices] = useState<any>(false)
  const [viewAllReceipts, setViewAllReceipts] = useState<any>(false)
  const [viewAllAttachments, setViewAllAttachments] = useState<any>(false)
  const [viewAllReminders, setViewAllReminders] = useState<any>(false)
  const [idsToDelete, setIdsToDelete] = useState<any>([])
  const [isEmpty, setIsEmpty] = useState<any>(false)

  const [fields, setFields] = useState<any>([
    {
      _id: subItemId,
      id: subId,
      item: '',
      amount: '0',
      qty: '1',
      vat: '0',
      total: '',
      lateApplied: false,
      lateDetails: [],
      activeLATE: false,
      InactiveLATE: false,
      lateFeeTotal: 0,
      lateOnce: false,
      latePaymentSelectionStatus: 0,
      isLatePaymentFeesRecord: false,
    },
  ])

  const [editFields, setEditFields] = useState<any>([])

  // Dynamic Schedule
  const [schedule, setSchedule] = useState([
    {
      id: '',
      days: '',
      date: '',
    },
  ])

  const handleScheduleAddFields = () => {
    const values = [...schedule]
    values.push({
      id: '',
      days: '',
      date: '',
    })
    setSchedule(values)
  }

  const handleScheduleRemoveFields = (index: any, id: any) => {
    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment_reminder/${id}?isCloneRecord=true`
    else url = `corporate/payment_reminder/${id}`

    ApiDelete(`${url}`)
      .then((res: any) => {
        SuccessToast('Reminder deleted successfully..')
        const values = [...schedule]
        values.splice(index, 1)
        setSchedule(values)
        getPaymentById(detailsToEdit?._id)
      })
      .catch((err: any) => console.log('err', err))
  }

  const handleSchedule = (e: any, index: any) => {
    if (e.target.value != '') {
      const values = [...schedule]
      values[index].days = e.target.value
      // if (dueDate !==  && values[index].days) {
      let reminderDate: any = new Date(detailsToEdit?.dueDate)
      reminderDate.setDate(reminderDate.getDate() - parseInt(e.target.value))

      values[index].date = new Date(reminderDate).toLocaleDateString('fr-CA')

      // }

      if (new Date(reminderDate) < new Date()) {
        ErrorToast('Reminder cannot be set in a Past Date!')
      } else {
        const body = {
          reminderDate: values[index].date,
          daysBefore: values[index].days,
          paymentId: detailsToEdit?._id,
        }

        let url: any
        if (type == 'terminate-tenancy') url = `corporate/payment_reminder?isCloneRecord=true`
        else url = `corporate/payment_reminder`

        ApiPost(`${url}`, body)
          .then((res: any) => {
            values[index].id = res?.data?.data?._id
            setSchedule(values)
            SuccessToast('Reminder added successfully!!!')
            getPaymentById(detailsToEdit?._id)
          })
          .catch((err: any) => console.log('err', err))
      }
    } else {
      const values = [...schedule]
      values[index].days = e.target.value

      let reminderDate: any = new Date(dueDate)
      reminderDate.setDate(reminderDate.getDate() - parseInt(e.target.value))

      values[index].date = ''

      setSchedule(values)
    }
  }

  const [scheduleAdded, setScheduleAdded] = useState<any>(false)
  const [grandAmount, setGrandAmount] = useState<any>(0)

  var totalAED: any = 0
  var nonVat: any = 0
  var vatAmount: any = 0
  var accountInd: any

  const [paymentNameError, setPaymentNameError] = useState<any>(false)
  const [amountPaidError, setAmountPaidError] = useState<any>(false)
  const [datePaidError, setDatePaidError] = useState<any>(false)
  const [dueDateError, setDueDateError] = useState<any>(false)
  const [paymentMethodError, setPaymentMethodError] = useState<any>(false)

  const [paymentName, setPaymentName] = useState<any>('')
  const [paidAmount, setAmountPaid] = useState<any>(0)
  const [paidAmountReceipt, setAmountPaidReceipt] = useState<any>(0)
  const [paidAmountTotal, setAmountPaidTotal] = useState<any>(0)
  const [outstandingAmount, setOutstandingAmount] = useState<any>(0)
  const [datePaidFinal, setDatePaidFinal] = useState<any>(null)

  const [dueDate, setDueDate] = useState<any>(null)
  const [dueDateToShow, setDueDateToShow] = useState<any>(null)
  const [datePaid, setDatePaid] = useState<any>()

  const [notes, setNotes] = useState<any>('')
  const [invoiceNotes, setInvoiceNotes] = useState<any>('')

  const [invoiceAdded, setInvoiceAdded] = useState<any>(false)
  const [invoiceNo, setInvoiceNo] = useState<any>()
  const [invoiceNoError, setInvoiceNoError] = useState<any>(false)
  const [receiptNoError, setReceiptNoError] = useState<any>(false)

  const [receiptAdded, setReceiptAdded] = useState<any>(false)
  const [receiptNo, setReceiptNo] = useState<any>()

  const [activeLATE, setActiveLATE] = useState<any>(false)
  const [InactiveLATE, setInActiveLATE] = useState<any>(false)

  const [dataToDelete, setDataToDelete] = useState<any>([])

  const [disableSelect, setDisableSelect] = useState<any>(true)
  var fileToRename: any = ''

  const [invoiceUrl, setInvoiceUrl] = useState<any>('')
  const [receiptUrl, setReceiptUrl] = useState<any>('')
  const [receiptId, setReceiptId] = useState<any>('')

  const [invoiceDate, setInvoiceDate] = useState<any>()

  var today: any = new Date()
  const currentTimee = new Date()
  const currentHours = currentTimee.getHours()
  const currentMinutes = currentTimee.getMinutes()

  const [paymentMethod, setPaymentMethod] = useState<any>(0)
  const [receiptPaymentMethod, setReceiptPaymentMethod] = useState<any>(0)
  const [invoicePaymentMethod, setInvoicePaymentMethod] = useState<any>(0)

  const [showAcc, setShowAcc] = useState<any>(false)
  const [showAccReceipt, setShowAccReceipt] = useState<any>(false)
  const [showAccInvoice, setShowAccInvoice] = useState<any>(false)

  const [accountDetails, setAccountDetails] = useState({
    name: 'Corporate User Real Estate Services LLC',
    iban: 'AE470900000000056912235',
    acc: '56912235',
    bank_name: 'Abu Dhabi Commercial Bank',
  })

  const [tenantId, setTenantId] = useState<any>()
  const [tenantDetails, setTenantDetails] = useState<any>()

  const [invoiceModal, setInvoiceModal] = useState<any>(false)
  const [newInvoiceModal, setNewInvoiceModal] = useState<any>(false)
  const [voidInvoiceModal, setVoidInvoiceModal] = useState<any>(false)

  const [isNewInvoice, setIsNewInvoice] = useState<any>(false)
  const [invoiceView, setInvoiceView] = useState<any>(false)

  const [receiptModal, setReceiptModal] = useState<any>(false)
  const [scheduleModal, setScheduleModal] = useState<any>(false)

  const [devOptions, setDevOptions] = useState<any>()
  const [clustOptions, setClustOptions] = useState<any>()
  const [UGBOptions, setUGBOptions] = useState<any>()

  const [clustBuildOptions, setClustBuildOptions] = useState<any>()
  const [clustUnitsOptions, setClustUnitsOptions] = useState<any>()
  const [buildUnitsOptions, setBuildUnitsOptions] = useState<any>()

  var developmentOptions: any

  const [chequeMedia, setChequeMedia] = useState<any>([])
  const [receiptChequeMedia, setReceiptChequeMedia] = useState<any>([])
  const [invoiceChequeMedia, setInvoiceChequeMedia] = useState<any>([])

  const [attachedMedia, setAttachedMedia] = useState<any>([])
  const [invoiceAttachedMedia, setInvoiceAttachedMedia] = useState<any>([])
  const [receiptAttachedMedia, setReceiptAttachedMedia] = useState<any>([])

  const [radioChecked, setRadioChecked] = useState<any>(-1)
  const [radioLinkChecked, setRadioLinkChecked] = useState<any>(-1)

  const [tenancyId, setTenancyId] = useState<any>(null)
  const [unitId, setUnitId] = useState<any>()

  const getStatus = () => {
    if (!dueDate) {
      return null // No status when no due date is selected
    }

    const currentDate = new Date()
    const selectedDate = dueDate.toDate()

    let x = currentDate.toLocaleDateString('uk-UA')
    let y = selectedDate.toLocaleDateString('uk-UA')

    if (x === y) {
      return 'Upcoming'
    } else if (selectedDate < currentDate) {
      return 'Overdue'
    } else if (selectedDate > currentDate) {
      return 'Upcoming'
    }

    return null // No status when the due date is today
  }

  //
  const handleAddFields = () => {
    ApiPost(`corporate/payment_sub_item`)
      .then((res: any) => {
        const values = [...fields]
        console.log(values)
        values.push({
          _id: `${res?.data?.data._id}`,
          id: `${res?.data?.data.id}`,
          item: '',
          amount: '0',
          qty: '1',
          vat: '0',
          total: '',
          lateApplied: false,
          lateDetails: [],
          activeLATE: false,
          InactiveLATE: false,
          lateFeeTotal: 0,
          lateOnce: false,
        })
        setFields(values)
      })
      .catch((err: any) => console.log('err', err))
  }

  //
  const handleRemoveFields = (index: any, lateInd: any) => {
    console.log('lateIndddd')
    console.log(lateInd)

    const values = [...fields]

    let x = values[index]._id

    let y = values[lateInd]?._id

    const lateValues = [...idsToDelete]
    lateValues.push({_id: x})
    if (lateInd != undefined) lateValues.push({_id: y})
    setIdsToDelete(lateValues)

    setEditFields(fields)

    if (lateInd != undefined) {
      if (lateInd < index) {
        values.splice(lateInd, 1)
        values.splice(index - 1, 1)
      } else {
        values.splice(index, 1)
        values.splice(lateInd - 1, 1)
      }
    } else {
      values.splice(index, 1)
    }

    for (let i = 0; i < values?.length; i++) {
      for (let j = 0; j < values?.length; j++) {
        if (values[j]._id == values[i]?.latePaymentSubItemId) {
          values[i].lateItemIndex = j
        }
      }
    }
    setFields(values)

    // if (values?.length > 0)
    changeFields(values)
  }

  const handleRemoveFieldsOfEdit = (index: any) => {
    const values = [...fields]
    values.splice(index, 1)
    setFields(values)
  }

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '50%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '600'}),
  }

  const customStyles2 = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '700'}),
  }

  const customStylesInputFilled = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #35475e',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '600'}),
  }

  const customStylesInputFilled2 = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #35475e',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '600'}),
  }

  const customStylesInputFilledForNewItems = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '50%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #35475e',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '600'}),
  }

  const handleDueDateChange = (date: any) => {
    setDueDateError(false)
    setDueDate(date)
    // setDueDateToShow(date.toDate());
    setDueDateToShow(date.format('YYYY-MM-DD'))
  }

  const handleDatePaidChange = (date: any) => {
    setDatePaidError(false)
    setDatePaid(date.format('YYYY-MM-DD'))
  }

  const [unitsTenancy, setUnitsTenancy] = useState<any>([])
  const [totalTenancies, setTotalTenancies] = useState<any>(0)

  //
  const [dragActive, setDragActive] = React.useState(false)
  const inputRef = React.useRef(null)

  const nameRef = React.useRef<any>()
  const amountPaidRef = React.useRef<any>()
  const datePaidRef = React.useRef<any>()
  const dueDateRef = React.useRef<any>()

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  }

  // triggers the input when the button is clicked
  const onButtonClick = (e: any) => {
    // inputRef.current.click();
  }

  //
  const handleAttachmentChange = async (e: any) => {
    e.preventDefault()

    const values = [...attachedMedia]
    let newVal = values?.length - 1

    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[0]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let temp = e.target.files[0]?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let url: any
        if (type == 'terminate-tenancy')
          url = `upload/originalname/image/payment_attachment?isCloneRecord=true`
        else url = `upload/originalname/image/payment_attachment`

        await ApiPost(`${url}`, formdata)
          .then((res) => {
            let x = res?.data?.data?.image?.split('/')[2]

            values[values.length] = {
              src: res?.data?.data?.image,
              name: x + '.' + temp,
            }
          })
          .catch(() => {})
      }
      setAttachedMedia(values)
    }
  }
  // delete attachment
  const deleteAttachment = (att: any) => {
    const body = {
      url: att?.src,
    }

    let url: any
    if (type == 'terminate-tenancy') url = `upload/delete_file?isCloneRecord=true`
    else url = `upload/delete_file`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        const values = [...attachedMedia]
        let newData: any = []
        let j = 0
        for (let i = 0; i < values.length; i++) {
          if (values[i].src !== att?.src) {
            newData.push(values[i])
            j++
          }
        }
        setAttachedMedia(newData)
        SuccessToast('Attachment deleted successfully')
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  // const getLateFee = (field: any) => {
  //   // console.log(parseInt(field.amount) + parseInt(field.qty))
  //   // console.log((parseInt(field.amount) * parseInt(field.qty)) / 100)

  //   let x: any =
  //     (field?.lateDetails?.late_payment_item_charge?.[0]?.fixedAmount / 100) *
  //     parseInt(field?.lateDetails?.late_payment_item_charge?.[0]?.VATPercentage)

  //   // console.log(x)

  //   let y: any = parseInt(field?.lateDetails?.late_payment_item_charge?.[0]?.fixedAmount) + x

  //   // console.log(y)
  //   if (field?.lateDetails?.late_payment_item_charge?.[0]?.feeType === 0)
  //     // totalAED = totalAED + y;
  //     // console.log(totalAED)
  //     // console.log(y)

  //     return y
  // }

  // const getLateFeePercent = (field: any) => {
  //   let x: any =
  //     ((parseInt(field.amount) * parseInt(field.qty)) / 100) *
  //     parseInt(field?.lateDetails?.late_payment_item_charge?.[0]?.VATPercentage)

  //   let y: any =
  //     ((parseInt(field.amount) * parseInt(field.qty)) / 100) *
  //     parseInt(field?.lateDetails?.late_payment_item_charge?.[0]?.percentageAmount)

  //   let z = x + y
  //   // if(field?.lateDetails?.late_payment_item_charge?.[0]?.feeType === 1)
  //   // totalAED = totalAED + z;

  //   return z
  // }

  // const getLateFeePercentValue = (field: any) => {
  //   let y: any =
  //     ((parseInt(field.amount) * parseInt(field.qty)) / 100) *
  //     parseInt(field?.lateDetails?.late_payment_item_charge?.[0]?.percentageAmount)

  //   return y
  // }

  //
  const applyFilter = (event: any) => {
    setShowFiltered(true)
    if (event.target.value === '') setShowFiltered(false)

    const filteredItems = unitsTenancy.filter((item: any) =>
      item.contractNo.includes(event.target.value)
    )
    setFilteredData(filteredItems)
  }

  // get tenancy
  const getTenancy = () => {
    const body = {
      unitId: unitDetails?._id,
    }

    let url: any
    if (type == 'terminate-tenancy') url = `corporate/tenancy/get/payment?isCloneRecord=true`
    else url = `corporate/tenancy/get/payment`

    ApiPost(`${url}`, body)
      .then((res) => {
        setUnitsTenancy(res?.data?.data)
        setFilteredData(res?.data?.data)
        setTotalTenancies(res?.data?.data?.length)
        setTenantId(res?.data?.data?.[0]?.tenantId)
        setTenancyContract(res?.data?.data?.[0].contractNo)
        getTenantDetails(res?.data?.data?.[0]?.tenantId)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const getTenantDetails = async (id: any) => {
    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        setTenantDetails(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  // calculating late payment fee on an item
  // const getLatePaymentFee = (field: any) => {
  //   const currentDate = moment()
  //   const dueDatee: any = moment(detailsToEdit?.dueDate)
  //   // const diffInDays = currentDate.diff(dueDatee, 'days')
  //   let x = currentDate.diff(dueDatee, 'days')

  //   const diffInDays = x - field.lateDetails.late_payment_item_charge[0]?.dayAfterPaymentDue
  //   console.log(diffInDays)
  //   switch (field.lateDetails.late_payment_item_charge[0]?.frequency) {
  //     case 0: {
  //       if (field.lateDetails.late_payment_item_charge[0]?.feeType === 0)
  //         return diffInDays > 0
  //           ? field.lateDetails.late_payment_item_charge[0]?.fixedAmount * field?.qty
  //           : 0
  //       else {
  //         let x =
  //           ((field?.amount * field?.qty) / 100) *
  //           field.lateDetails.late_payment_item_charge[0]?.percentageAmount
  //         return diffInDays > 0 ? x * field?.qty : 0
  //       }
  //     }
  //     case 1: {
  //       if (field.lateDetails.late_payment_item_charge[0]?.feeType === 0)
  //         return diffInDays > 0
  //           ? field.lateDetails.late_payment_item_charge[0]?.fixedAmount * field?.qty * diffInDays
  //           : 0
  //       else {
  //         let x =
  //           ((field?.amount * field?.qty) / 100) *
  //           field.lateDetails.late_payment_item_charge[0]?.percentageAmount
  //         return diffInDays > 0 ? x * field?.qty * diffInDays : 0
  //       }
  //     }

  //     case 2: {
  //       if (field.lateDetails.late_payment_item_charge[0]?.feeType === 0) {
  //         const diffInWeeks = Math.floor(diffInDays / 7)
  //         return diffInWeeks > 0
  //           ? field.lateDetails.late_payment_item_charge[0]?.fixedAmount * diffInWeeks * field?.qty
  //           : 0
  //       } else {
  //         const diffInWeeks = Math.floor(diffInDays / 7)
  //         let x =
  //           ((field?.amount * field?.qty) / 100) *
  //           field.lateDetails.late_payment_item_charge[0]?.percentageAmount
  //         return diffInDays > 0 ? x * field?.qty * diffInWeeks : 0
  //       }
  //     }

  //     case 3: {
  //       if (field.lateDetails.late_payment_item_charge[0]?.feeType === 0) {
  //         const diffInMonths = Math.floor(diffInDays / 30)
  //         return diffInMonths > 0
  //           ? field.lateDetails.late_payment_item_charge[0]?.fixedAmount * diffInMonths * field?.qty
  //           : 0
  //       } else {
  //         console.log('I am here')
  //         const diffInMonths = Math.floor(diffInDays / 30)
  //         let x =
  //           ((field?.amount * field?.qty) / 100) *
  //           field.lateDetails.late_payment_item_charge[0]?.percentageAmount
  //         console.log(x)
  //         return diffInDays > 0 ? x * field?.qty * diffInMonths : 0
  //       }
  //     }

  //     default:
  //       return 0
  //   }
  // }

  // getting list of all bank accounts
  const getBankAccounts = () => {
    const body = {
      limit: 25,
      page: 1,
    }
    ApiPost(`corporate/payment_account/get`, body)
      .then((res: any) => {
        setBankAccountItems(res?.data?.data?.payment_account_data)
      })
      .catch((err: any) => console.log('err', err))
  }

  // triggers when file is selected with click
  const handleChange = async (e: any) => {
    e.preventDefault()

    const values = [...chequeMedia]
    let newVal = values?.length - 1

    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let x = values[values.length]?.src?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any = Date.now()

        await ApiPost(
          `upload/compress_image/params?fileType=cheque&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            newVal = newVal + 1
            values[newVal] = {
              src: fileURL,
              name: '',
              fileType: 'cheque',
              databaseId: data?._id,
              image: res?.data?.data?.image,
            }
          })
          .catch((err) => console.log('err', err))
      }
      setChequeMedia(values)
    }
  }

  const handleReceiptChequeChange = async (e: any) => {
    e.preventDefault()

    const values = [...receiptChequeMedia]
    let newVal = values?.length - 1
    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        // values[values.length] = {
        //   src: fileURL,
        //   name: '',
        //   fileType: 'cheque',
        //   databaseId: data?._id,
        // }

        let x = values[values.length]?.src?.name?.split('.')[1]

        // uploadCheque(values[values.length], x)

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any = Date.now()

        await ApiPost(
          `upload/compress_image/params?fileType=cheque&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            newVal = newVal + 1
            values[newVal] = {
              src: fileURL,
              name: '',
              fileType: 'cheque',
              databaseId: data?._id,
              image: res?.data?.data?.image,
            }
          })
          .catch((err) => console.log('err', err))
      }
      setReceiptChequeMedia(values)
    }
  }

  // set cheque number
  const renameChequeReceipt = async (chq: any, type: any) => {
    console.log(chq)
    let chequeImage: any = chq?.image

    let x = chq?.src?.name?.split('.')[1]
    // console.log(chq)
    const body = {
      image: chq?.image,
      newFileName: chq?.name + '.' + x,
    }

    await ApiPost(`upload/rename_file`, body)
      .then((res: any) => {
        const values = [...receiptChequeMedia]
        for (let i = 0; i < values.length; i++) {
          if (values[i].name === chq?.name) values[i].image = res?.data?.data?.image
        }
        setReceiptChequeMedia(values)
      })
      .catch((err: any) => {
        // ErrorToast(err.message)
        ErrorToast('Name Already Exists.. Please add another name..')
        console.log('err', err)
      })
  }

  // delete cheque
  const deleteChequeReceipt = (chq: any) => {
    const body = {
      url: chq?.image,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res: any) => {
        const values = [...receiptChequeMedia]
        let newData: any = []
        let j = 0
        for (let i = 0; i < values.length; i++) {
          if (values[i].image !== chq?.image) {
            newData.push(values[i])
            j++
          }
        }
        SuccessToast('Cheque deleted successfully')
        setReceiptChequeMedia(newData)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  const handleInvoiceChequeChange = async (e: any) => {
    e.preventDefault()
    console.log(e.target.files)
    const values = [...invoiceChequeMedia]
    let newVal = values?.length - 1

    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let x = values[values.length]?.src?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any = Date.now()

        await ApiPost(
          `upload/compress_image/params?fileType=cheque&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            newVal = newVal + 1
            values[values.length] = {
              src: fileURL,
              name: '',
              fileType: 'cheque',
              databaseId: data?._id,
              image: res?.data?.data?.image,
            }
          })
          .catch((err) => console.log('err', err))
      }
      setInvoiceChequeMedia(values)
    }
  }

  // delete cheque
  const deleteChequeInvoice = (chq: any) => {
    const body = {
      url: chq?.image,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res: any) => {
        const values = [...invoiceChequeMedia]
        let newData: any = []
        let j = 0
        for (let i = 0; i < values.length; i++) {
          if (values[i].image !== chq?.image) {
            newData.push(values[i])
            j++
          }
        }
        setInvoiceChequeMedia(newData)
        SuccessToast('Cheque deleted successfully')
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  // set cheque number
  const renameCheque = async (chq: any, type: any) => {
    console.log(chq)
    let chequeImage: any = chq?.image

    let x = chq?.src?.name?.split('.')[1]
    // console.log(chq)
    const body = {
      image: chq?.image,
      newFileName: chq?.name + '.' + x,
    }

    await ApiPost(`upload/rename_file`, body)
      .then((res: any) => {
        const values = [...chequeMedia]
        for (let i = 0; i < values.length; i++) {
          if (values[i].name === chq?.name) values[i].image = res?.data?.data?.image
        }
        setChequeMedia(values)
      })
      .catch((err: any) => {
        // ErrorToast(err.message)
        ErrorToast('Name Already Exists.. Please add another name..')
        console.log('err', err)
      })
  }

  // delete cheque
  const deleteCheque = (chq: any) => {
    const body = {
      url: chq?.image,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res: any) => {
        console.log(chequeMedia)
        const values = [...chequeMedia]
        let newData: any = []
        let j = 0
        for (let i = 0; i < values.length; i++) {
          if (values[i].image !== chq?.image) {
            newData.push(values[i])
            j++
          }
        }
        SuccessToast('Cheque deleted successfully')
        setChequeMedia(newData)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  // generate an invoice
  const generateInvoice = async (val: any) => {
    // Category
    let x = getStatus()
    if (x == 'Overdue') setPaymentCategory(1)
    else if (x == 'Upcoming') setPaymentCategory(0)

    let attachments: any = []

    for (let i = 0; i < invoiceAttachedMedia?.length; i++) {
      attachments[i] = invoiceAttachedMedia[i]?.src
    }

    console.log(attachments)

    let today = new Date()
    let pStatus: any
    if (paidAmount === 0 || paidAmount === '') pStatus = 0
    else if (paidAmount < totalAED) pStatus = 2
    else if (paidAmount >= totalAED) pStatus = 1
    else console.log()

    // Payment Method
    let payMethod: any
    if (paymentMethod === 'Bank') payMethod = 0
    else if (paymentMethod === 'Cheque') payMethod = 1
    else if (paymentMethod === 'Cash') payMethod = 2
    else payMethod = 2

    // Dynamic Data
    let newData: any = []
    let newObj: any = {}
    for (let i = 0; i < fields.length; i++) {
      if (fields[i]?.lateApplied) {
        // if(fields[i]?.lateDetails?.late_payment_item_charge){
        let x = fields[i]?.lateDetails?.late_payment_item_charge?.[0]

        let xId: any = x?._id
        newObj = {
          _id: xId,
          // id: '' + x?.id + '',
          dayAfterPaymentDue: x?.dayAfterPaymentDue,
          frequency: x?.frequency,
          feeType: x?.feeType,
          percentageAmount: x?.percentageAmount,
          fixedAmount: x?.fixedAmount,
          VATPercentage: x?.VATPercentage,
          VATAmount: x?.VATPercentage,
          totalAmount: fields[i].lateFeeTotal,
          // }
        }
      }

      let total: any
      // let isNo: any = isNaN(parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal))
      // if (
      //   parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal) == null ||
      //   isNo == true
      // ) {
      //   total = 0
      //   fields[i].total = 0
      // } else total = parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal)

      let isNo: any = isNaN(parseFloat(fields[i].total))
      if (parseFloat(fields[i].total) == null || isNo == true) total = 0
      else total = parseFloat(fields[i].total)

      newData[i] = {
        id: fields[i]?.id,
        _id: fields[i]._id,
        name: fields[i]?.lateDetails?.name,
        paymentItemListId: fields[i]?.lateDetails?._id,

        amount: parseFloat(fields[i].amount),
        quantity: parseFloat(fields[i].qty),
        totalAmount: fields[i]?.lateApplied ? total : parseFloat(fields[i].total),
        // totalAmount:  parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal),
        VATAmount:
          ((parseFloat(fields[i].amount) * parseFloat(fields[i].qty)) / 100) *
          parseFloat(fields[i].vat),
        VATPercentage: parseFloat(fields[i].vat),
        latePaymentFees: fields[i]?.lateApplied ? fields[i].lateFeeTotal : 0,
        latePaymentSelectionStatus:
          fields[i]?.activeLATE == true ? 1 : fields[i]?.InactiveLATE == true ? 2 : 0,
        paymentId: incomingPaymentId,
        latePaymentObject: newObj,
      }
    }

    let grandTotal: any = 0
    for (let i = 0; i < newData?.length; i++) {
      grandTotal = grandTotal + newData[i]?.totalAmount
    }

    const body = {
      invoiceNo: invoiceNo,
      currency: 'AED',
      name: detailsToEdit?.name,
      invoiceDate: invoiceDate,
      dueDate: dueDateToShow,
      _id: detailsToEdit?._id,
      unitId: detailsToEdit?.unitId,
      tenancyId: detailsToEdit?.tenancyId,
      tenantId: detailsToEdit?.tenantId,
      invoiceNote: invoiceNotes,
      invoiceAttachments: attachments,
      paymentMethod: parseInt(paymentMethod),
      paymentStatus: detailsToEdit?.paymentStatus,
      outstandingAmount: grandTotal - detailsToEdit?.amountPaid,
      categories: detailsToEdit?.categories,
      subTotalAmount: nonVat,
      totalVAT: vatAmount,
      totalAmount: grandTotal,
      amountPaid: detailsToEdit?.amountPaid,
    }
    //     // if( val == 1){
    //     // updatePaymentRecord(1)

    //     await ApiPost(`corporate/payment/invoice`, body)
    //       .then((res: any) => {
    //         setInvoiceUrl(res?.data?.data?.pdfURL)
    //         setInvoiceAdded(true)
    //         SuccessToast('Invoice generated successfully..')
    //         getPaymentById(detailsToEdit?._id)
    //         if(val == 1) {
    //           updatePaymentRecord(1)
    //         }

    //         // updatePayment(res?.data?.data?.pdfURL, paymentMethod)
    //       })
    //       .catch((err: any) => {
    //         console.log('err', err)
    //       })
    //     }
    // else{

    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment/invoice?isCloneRecord=true`
    else url = `corporate/payment/invoice`

    await ApiPost(`${url}`, body)
      .then((res: any) => {
        setInvoiceUrl(res?.data?.data?.pdfURL)
        detailsToEdit.invoiceURL = res?.data?.data?.pdfURL
        setInvoiceAdded(true)
        if (val != 1) SuccessToast('Invoice generated successfully..')

        // const updatePaymentRecord = (val: any) => {
        let subTotal: any = 0
        let total: any = 0

        for (let i = 0; i < updatedItem?.length; i++) {
          let y: any = updatedItem[i].amount * updatedItem[i].quantity
          subTotal = subTotal + y

          let x: any =
            updatedItem[i].amount * updatedItem[i].quantity +
            ((updatedItem[i].amount * updatedItem[i].quantity) / 100) * updatedItem[i].VATPercentage

          total = total + x
        }

        // Dynamic Data
        let newData: any = []
        let newObj: any = {}
        for (let i = 0; i < fields.length; i++) {
          if (fields[i]?.lateApplied) {
            // if(fields[i]?.lateDetails?.late_payment_item_charge){
            let x = fields[i]?.lateDetails?.late_payment_item_charge?.[0]

            let xId: any = x?._id
            newObj = {
              _id: xId,
              // id: '' + x?.id + '',
              dayAfterPaymentDue: x?.dayAfterPaymentDue,
              frequency: x?.frequency,
              feeType: x?.feeType,
              percentageAmount: x?.percentageAmount,
              fixedAmount: x?.fixedAmount,
              VATPercentage: x?.VATPercentage,
              VATAmount: x?.VATPercentage,
              totalAmount: fields[i].lateFeeTotal,
              // }
            }
          }

          let total: any
          // let isNo: any = isNaN(parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal))
          // if (
          //   parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal) == null ||
          //   isNo == true
          // ) {
          //   total = 0
          //   fields[i].total = 0
          // } else total = parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal)

          let isNo: any = isNaN(parseFloat(fields[i].total))
          if (parseFloat(fields[i].total) == null || isNo == true) total = 0
          else total = parseFloat(fields[i].total)

          newData[i] = {
            id: fields[i]?.id,
            _id: fields[i]._id,
            name: fields[i]?.lateDetails?.name,
            paymentItemListId: fields[i]?.lateDetails?._id,

            amount: parseFloat(fields[i].amount),
            quantity: parseFloat(fields[i].qty),
            totalAmount: fields[i]?.lateApplied ? total : parseFloat(fields[i].total),
            // totalAmount:  parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal),
            VATAmount:
              ((parseFloat(fields[i].amount) * parseFloat(fields[i].qty)) / 100) *
              parseFloat(fields[i].vat),
            VATPercentage: parseFloat(fields[i].vat),
            latePaymentFees: fields[i]?.lateApplied ? fields[i].lateFeeTotal : 0,
            latePaymentSelectionStatus:
              fields[i]?.activeLATE == true ? 1 : fields[i]?.InactiveLATE == true ? 2 : 0,
            paymentId: incomingPaymentId,
            latePaymentObject: newObj,
          }
        }

        let grandTotal: any = 0
        for (let i = 0; i < newData?.length; i++) {
          grandTotal = grandTotal + newData[i]?.totalAmount
        }

        // chqImages
        let chqImages: any = []
        if (paymentMethod == 1) {
          for (let i = 0; i < chequeMedia?.length; i++) {
            chqImages[i] = chequeMedia[i]?.image
          }
        }

        //  account selected is Cheque
        let chqBankName: any
        if (paymentMethod == 1) chqBankName = chequeBankName
        else chqBankName = null

        //  account selected is Bank
        let paymentAccountId: any
        if (paymentMethod == 2) paymentAccountId = selectedAccount?._id
        else paymentAccountId = null

        // chqImages
        let media: any = []
        for (let i = 0; i < attachedMedia?.length; i++) {
          media[i] = attachedMedia[i]?.src
        }
        detailsToEdit.invoiceURL = invoiceUrl
        const body = {
          payment: {
            _id: detailsToEdit?._id,
            name: detailsToEdit?.name,
            note: notes,
            // invoiceURL: res?.data?.data?.pdfURL,
            currency: 'AED',
            // invoiceNo: detailsToEdit?.invoiceNo,
            // invoiceDate: detailsToEdit?.invoiceDate,
            paymentStatus: detailsToEdit?.paymentStatus,
            categories: detailsToEdit?.categories,
            amountPaid: detailsToEdit?.amountPaid,
            outstandingAmount: grandTotal - detailsToEdit?.amountPaid,
            subTotalAmount: subTotal,
            totalVAT: detailsToEdit?.totalVAT,
            totalAmount: grandTotal,
            // paymentType: 0,
            paymentMethod: paymentMethod,
            paymentAccountId: paymentAccountId,
            chequeBankName: chqBankName,
            dueDate: detailsToEdit?.dueDate,
            attachments: media,
            chequeImages: chqImages,
            tenancyId: detailsToEdit?.tenancyId,
            unitId: detailsToEdit?.unitId,
            clusterId: detailsToEdit?.clusterId,
            unitGroupId: detailsToEdit?.unitGroupId,
            communityId: detailsToEdit?.communityId,
            floorId: detailsToEdit?.floorId,
            buildingId: detailsToEdit?.buildingId,
            serviceRequestId: null,
            serviceJobIds: [],
          },

          update_payment_sub_item: newData,
          delete_payment_sub_item: [],
        }

        let url: any
        if (type == 'terminate-tenancy')
          url = `corporate/payment/update_all_in_one?isCloneRecord=true`
        else url = `corporate/payment/update_all_in_one`

        ApiPost(`${url}`, body)
          .then(async (res: any) => {
            if (val == 1) {
              SuccessToast('Payment Items have been updated successfully...')
              setShowEdit(false)
            }
            getPaymentById(detailsToEdit?._id)
            getAllPayments()
          })
          .catch((err: any) => console.log('err', err))

        console.log(detailsToEdit)
        // }

        // getPaymentById(detailsToEdit?._id)
        // if(val == 1) {
        //   updatePaymentRecord(1)
        // }

        // updatePayment(res?.data?.data?.pdfURL, paymentMethod)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
    // }
  }

  const updatePayment = (url: any, pM: any) => {
    // Category
    let x = getStatus()
    if (x == 'Overdue') setPaymentCategory(1)
    else if (x == 'Upcoming') setPaymentCategory(0)

    // Payment Status
    let pStatus: any
    if (paidAmount === 0 || paidAmount === '') pStatus = 0
    else if (paidAmount < totalAED) pStatus = 2
    else if (paidAmount >= totalAED) pStatus = 1
    else console.log()

    // Dynamic Data
    let newData: any = []
    let newObj: any = {}
    for (let i = 0; i < fields.length; i++) {
      if (fields[i]?.lateApplied) {
        // if(fields[i]?.lateDetails?.late_payment_item_charge){
        let x = fields[i]?.lateDetails

        let xId: any = x.late_payment_item_charge[0]?._id
        newObj = {
          _id: xId,
          // id: '' + x.late_payment_item_charge[0]?.id + '',
          dayAfterPaymentDue: x.late_payment_item_charge[0]?.dayAfterPaymentDue,
          frequency: x.late_payment_item_charge[0]?.frequency,
          feeType: x.late_payment_item_charge[0]?.feeType,
          percentageAmount: x.late_payment_item_charge[0]?.percentageAmount,
          fixedAmount: x.late_payment_item_charge[0]?.fixedAmount,
          VATPercentage: x.late_payment_item_charge[0]?.VATPercentage,
          VATAmount: x.late_payment_item_charge[0]?.VATPercentage,
          totalAmount: fields[i].lateFeeTotal,
          // }
        }
      }

      let total: any
      let isNo: any = isNaN(parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal))
      if (parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal) == null || isNo == true) {
        total = 0
        fields[i].total = 0
      } else total = parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal)

      newData[i] = {
        id: fields[i]?.id,
        _id: fields[i]._id,
        name: fields[i]?.lateDetails?.name,
        paymentItemListId: fields[i]?.lateDetails?._id,

        amount: parseInt(fields[i].amount),
        quantity: parseInt(fields[i].qty),
        totalAmount: fields[i]?.lateApplied ? total : parseInt(fields[i].total),
        // totalAmount:  parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal),
        VATAmount:
          ((parseInt(fields[i].amount) * parseInt(fields[i].qty)) / 100) * parseInt(fields[i].vat),
        VATPercentage: parseInt(fields[i].vat),
        latePaymentFees: fields[i]?.lateApplied ? fields[i].lateFeeTotal : 0,
        latePaymentSelectionStatus: activeLATE === true ? 1 : InactiveLATE === true ? 2 : 0,
        paymentId: incomingPaymentId,
        latePaymentObject: newObj,
      }
    }

    // attachments
    let attachments: any = []
    for (let i = 0; i < invoiceAttachedMedia?.length; i++) {
      attachments[i] = invoiceAttachedMedia[i]?.src
    }

    // chqImages
    let chqImages: any = []
    if (paymentMethod === 1) {
      for (let i = 0; i < chequeMedia?.length; i++) {
        chqImages[i] = chequeMedia[i]?.src
      }
    }

    //  account selected is Bank
    let paymentAccountId: any
    if (paymentMethod == 2) paymentAccountId = selectedAccount?._id
    else paymentAccountId = null

    //  account selected is Cheque
    let chqBankName: any
    if (paymentMethod == 1) chqBankName = chequeBankName
    else chqBankName = null

    const body = {
      payment: {
        _id: incomingPaymentId,
        name: paymentName,
        note: notes,
        // invoiceURL: invoiceUrl,
        currency: 'AED',
        // invoiceNo: invoiceNo,
        // invoiceDate: invoiceDate,
        paymentStatus: pStatus,
        categories: paymentCategory,
        amountPaid: parseInt(paidAmount),
        outstandingAmount: parseInt(totalAED) - parseInt(paidAmount),
        subTotalAmount: nonVat,
        totalVAT: vatAmount,
        totalAmount: parseInt(totalAED),
        // paymentType: 0,
        paymentMethod: parseInt(pM),
        paymentAccountId: paymentAccountId,
        chequeBankName: chqBankName,
        dueDate: dueDateToShow,
        attachments: attachments,
        chequeImages: chqImages,
        tenancyId: tenancyId,
        unitId: unitDetails?._id,
        clusterId: unitDetails?.clusterId,
        unitGroupId: unitDetails?.unitGroupId,
        communityId: unitDetails?.communityId,
        floorId: unitDetails?.floorId,
        buildingId: unitDetails?.buildingId,
        // serviceRequestId: null,
        // serviceJobIds: [],
      },

      update_payment_sub_item: newData,
      delete_payment_sub_item: [],
    }

    let urlApi: any
    if (type == 'terminate-tenancy')
      urlApi = `corporate/payment/update_all_in_one?isCloneRecord=true`
    else urlApi = `corporate/payment/update_all_in_one`

    ApiPost(`${urlApi}`, body)
      .then((res: any) => {})
      .catch((err: any) => console.log('err', err))
  }

  // delete invoice
  const deleteInvoice = () => {
    const body = {
      url: invoiceUrl,
    }

    let urlApi: any
    if (type == 'terminate-tenancy') urlApi = `upload/delete_file?isCloneRecord=true`
    else urlApi = `upload/delete_file`

    ApiPost(`${urlApi}`, body)
      .then((res: any) => {
        SuccessToast('Invoice deleted successfully..')
        setInvoiceAdded(false)
        setInvoiceUrl('')
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  //
  const sentInvoiceToTenant = () => {
    const body = {
      paymentId: incomingPaymentId,
    }

    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment/invoice/sent?isCloneRecord=true`
    else url = `corporate/payment/invoice/sent`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        SuccessToast('The invoice has been successfully sent to the tenant..')
        setInvoiceModal(false)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  const [uploadInvoiceModal, setUploadInvoiceModal] = useState<any>(false)
  const [isReceiptManualPdf, setIsReceiptManualPdf] = useState<any>(false)
  const [receiptPdfUrl, setReceiptPdfUrl] = useState<any>('')
  const [isReceipt, setIsReceipt] = useState<any>(false)

  // Generate a new receipt for a payment
  const generateReceipt = () => {
    // payload for receipt
    let receiptItems: any = []
    for (let i = 0; i < receiptFields?.length; i++) {
      receiptItems[i] = {
        _id: receiptFields[i]?._id,
        name: receiptFields[i]?.name,
        paymentItemListId: receiptFields[i]?.paymentItemListId,
        totalAmount: parseFloat(receiptFields[i].previousOutstanding),
        amountPaid: parseFloat(receiptFields[i]?.amountPaid),
        outstandingAmount: parseFloat(receiptFields[i]?.outstanding),
        previousAmmountPaid: parseFloat(receiptFields[i].previousPaidAmount),
      }
    }

    let attachments: any = []

    for (let i = 0; i < receiptAttachedMedia?.length; i++) {
      attachments[i] = receiptAttachedMedia[i]?.src
    }

    let paymentAccountId: any
    if (receiptPaymentMethod == 2) paymentAccountId = selectedAccountReceipt?._id
    else paymentAccountId = null

    // chqImages
    let chqImages: any = []
    if (receiptPaymentMethod == 1) {
      for (let i = 0; i < receiptChequeMedia?.length; i++) {
        chqImages[i] = receiptChequeMedia[i]?.image
      }
    }

    //  account selected is Cheque
    let chqBankName: any
    if (receiptPaymentMethod == 1) chqBankName = receiptChequeBankName
    else chqBankName = null

    let body: any
    if (isReceiptManualPdf) {
      body = {
        receiptNo: receiptNo,
        invoiceNo: invoiceReceiptNo,
        paymentId: detailsToEdit?._id,
        paymentAccountId: paymentAccountId,
        datePaid: datePaid,
        amountPaid: parseFloat(paidAmountReceipt),
        outstandingAmount: parseFloat(outstandingAmount) - parseFloat(paidAmountReceipt),
        paymentMethod: parseInt(receiptPaymentMethod),
        attachments: attachments,
        paymentSubItems: receiptItems,
        chequeBankName: chqBankName,
        chequeImages: chqImages,
        pdfURL: receiptPdfUrl,
        isManualPDF: isReceiptManualPdf,
      }
    } else {
      body = {
        receiptNo: receiptNo,
        invoiceNo: invoiceReceiptNo,
        paymentId: detailsToEdit?._id,
        paymentAccountId: paymentAccountId,
        datePaid: datePaid,
        amountPaid: parseFloat(paidAmountReceipt),
        outstandingAmount: parseFloat(outstandingAmount) - parseFloat(paidAmountReceipt),
        paymentMethod: parseInt(receiptPaymentMethod),
        attachments: attachments,
        paymentSubItems: receiptItems,
        chequeBankName: chqBankName,
        chequeImages: chqImages,
      }
    }

    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment_receipt?isCloneRecord=true`
    else url = `corporate/payment_receipt`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        setReceiptLinkModal(false)
        handleClose()
        getAllPayments()

        // let x = parseFloat(outstandingAmount) - parseFloat(paidAmountReceipt)
        // setOutstandingAmount(x)

        // detailsToEdit.payment_receipt[detailsToEdit.payment_receipt.length] = {
        //   _id: res?.data?.data?._id,
        //   pdfURL: res?.data?.data?.pdfURL,
        //   receiptNo: res?.data?.data?.receiptNo,
        //   id: res?.data?.data?.id,
        // }

        // let temp = parseFloat(paidAmountTotal) + parseFloat(paidAmountReceipt)
        // setAmountPaidTotal(temp)

        // setDatePaidFinal(datePaid)
        // // setReceiptUrl(res?.data?.data?.pdfURL)
        // // setReceiptId(res?.data?.data?._id)
        // setReceiptAdded(true)
        setReceiptModal(false)
        // SuccessToast(res?.data?.message)
        setAmountPaidReceipt(0)
        setDatePaid(null)
        setReceiptNo('')
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  const uploadReceipt = async (e: any) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      // let fileURL = URL.createObjectURL(e.target.files[0])
      let fileURL = e.target.files[0]

      var formdata = new FormData()
      formdata.append('image', fileURL)

      await ApiPost(`upload/image/troubleshoot_request`, formdata)
        .then((res) => {
          setReceiptPdfUrl(res?.data?.data?.image)
          setIsReceiptManualPdf(true)
          setUploadInvoiceModal(false)
          setReceiptModal(false)
          setReceiptAdded(true)
          setIsReceipt(false)

          generateReceipt()
        })
        .catch(() => {})
    }
  }

  //  delete receipt
  const deleteReceipt = () => {
    let url: any
    if (type == 'terminate-tenancy')
      url = `corporate/payment_receipt/${receiptId}?isCloneRecord=true`
    else url = `corporate/payment_receipt/${receiptId}`

    ApiDelete(`${url}`)
      .then((res: any) => {
        SuccessToast('Receipt deleted successfully..')
        setReceiptAdded(false)
        setInvoiceUrl('')
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  //
  const handleInvoiceAttachmentChange = async (e: any) => {
    e.preventDefault()

    const values = [...invoiceAttachedMedia]
    let newVal = values?.length - 1

    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[0]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        let temp = e.target.files[0]?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        await ApiPost(`upload/originalname/image/invoice_attachment`, formdata)
          .then((res: any) => {
            let x = res?.data?.data?.image?.split('/')[2]
            newVal = newVal + 1

            values[newVal] = {
              src: res?.data?.data?.image,
              name: x + '.' + temp,
            }
          })
          .catch(() => {})
      }
      setInvoiceAttachedMedia(values)
    }
  }

  // delete attachment
  const deleteInvoiceAttachment = (att: any) => {
    const body = {
      url: att?.src,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res: any) => {
        const values = [...invoiceAttachedMedia]
        let newData: any = []
        let j = 0
        for (let i = 0; i < values.length; i++) {
          if (values[i].src !== att?.src) {
            newData.push(values[i])
            j++
          }
        }
        setInvoiceAttachedMedia(newData)
        SuccessToast('Attachment deleted successfully')
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  //
  const handleReceiptAttachmentChange = async (e: any) => {
    e.preventDefault()
    const values = [...receiptAttachedMedia]
    let newVal = values?.length - 1

    if (e.target.files && e.target.files[0]) {
      for (let i = 0; i < e.target.files.length; i++) {
        // let fileURL = URL.createObjectURL(e.target.files[0])
        let fileURL = e.target.files[0]

        let temp = e.target.files[0]?.name?.split('.')[1]

        var formdata = new FormData()
        formdata.append('image', fileURL)

        await ApiPost(`upload/originalname/image/receipt_attachment`, formdata)
          .then((res: any) => {
            let x = res?.data?.data?.image?.split('/')[2]
            newVal = newVal + 1

            values[newVal] = {
              src: res?.data?.data?.image,
              name: x + '.' + temp,
            }
          })
          .catch(() => {})
      }
      setReceiptAttachedMedia(values)
    }
  }

  // delete attachment
  const deleteReceiptAttachment = (att: any) => {
    const body = {
      url: att?.src,
    }
    ApiPost(`upload/delete_file`, body)
      .then((res: any) => {
        const values = [...receiptAttachedMedia]
        let newData: any = []
        let j = 0
        for (let i = 0; i < values.length; i++) {
          if (values[i].src !== att?.src) {
            newData.push(values[i])
            j++
          }
        }
        setReceiptAttachedMedia(newData)
        SuccessToast('Attachment deleted successfully')
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        console.log('err', err)
      })
  }

  //
  const getPaymentById = async (id: any) => {
    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment/${id}?isCloneRecord=true`
    else url = `corporate/payment/${id}`

    ApiGet(`${url}`)
      .then((res) => {
        setPaymentInvoices(res?.data?.data?.payment_invoice)
        setPaymentDebitNotes(res?.data?.data?.payment_debit_note)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const updatePaymentRecord = (val: any) => {
    let pCat: any = detailsToEdit?.categories

    if (val == 5) {
      pCat = 2
    } else if (val == 51) {
      pCat = 0
    } else if (val == 52) {
      pCat = 1
    }

    let fq: any = ''
    if (frequency == 'Once') fq = 0
    else if (frequency == 'Daily') fq = 1
    else if (frequency == 'Weekly') fq = 2
    else if (frequency == 'Monthly') fq = 3
    else console.log()

    let eDate: any = scheduleEndDate
    if (isEndDateChecked) eDate = null

    // let subTotal: any = 0
    // let total: any = 0
    // let vatTotal: any = 0

    let grandTotal: any = 0

    for (let i = 0; i < fields?.length; i++) {
      if (fields[i]?.isLatePaymentFeesRecord == false)
        grandTotal =
          grandTotal +
          ((fields[i]?.amount * fields[i]?.qty) / 100) * fields[i]?.vat +
          fields[i]?.amount * fields[i]?.qty +
          fields[i]?.lateFeeTotal
    }

    // Dynamic Data
    let updateData: any = []
    let newObj: any = {}
    let newData: any = []
    let j = 0

    for (let i = 0; i < fields.length; i++) {
      // if (fields[i]?.lateApplied) {
      // if(fields[i]?.lateDetails?.late_payment_item_charge){
      let x = fields[i]?.lateDetails?.late_payment_item_charge?.[0]
      let xId: any = x?._id

      newObj = {
        _id: xId,
        // id: '' + x?.id + '',
        dayAfterPaymentDue: x?.dayAfterPaymentDue,
        frequency: x?.frequency,
        feeType: x?.feeType,
        percentageAmount: x?.percentageAmount,
        fixedAmount: x?.fixedAmount,
        VATPercentage: x?.VATPercentage,
        VATAmount: x?.VATPercentage,
        // totalAmount: fields[i].lateFeeTotal,
      }
      // }
      // }

      let total: any
      // let isNo: any = isNaN(parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal))
      // if (
      //   parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal) == null ||
      //   isNo == true
      // ) {
      //   total = 0
      //   fields[i].total = 0
      // } else total = parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal)

      let isNo: any = isNaN(parseFloat(fields[i].total))
      if (parseFloat(fields[i].total) == null || isNo == true) total = 0
      else total = parseFloat(fields[i].total)

      updateData[j] = {
        id: fields[i]?.id,
        _id: fields[i]._id,
        name: fields[i]?.item,
        paymentItemListId: fields[i]?.paymentItemListId,
        amount: parseFloat(fields[i].amount),
        quantity: parseFloat(fields[i].qty),
        totalAmount: parseFloat(fields[i].total),
        // totalAmount:  parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal),
        VATAmount: fields[i]?.isLatePaymentFeesRecord
          ? (parseFloat(fields[i].amount) / 100) * parseFloat(fields[i].vat)
          : ((parseFloat(fields[i].amount) * parseFloat(fields[i].qty)) / 100) *
            parseFloat(fields[i].vat),
        VATPercentage: parseFloat(fields[i].vat),
        latePaymentFees: fields[i].lateFeeTotal,
        latePaymentSelectionStatus:
          fields[i]?.activeLATE == true ? 1 : fields[i]?.InactiveLATE == true ? 2 : 0,
        paymentId: incomingPaymentId,
        latePaymentObject: newObj,
      }
    }

    // let grandTotal: any = 0
    // for (let i = 0; i < newData?.length; i++) {
    //   grandTotal = grandTotal + newData[i]?.totalAmount
    // }

    // chqImages
    let chqImages: any = []
    if (paymentMethod == 1) {
      for (let i = 0; i < chequeMedia?.length; i++) {
        chqImages[i] = chequeMedia[i]?.image
      }
    }

    //  account selected is Cheque
    let chqBankName: any
    if (paymentMethod == 1) chqBankName = chequeBankName
    else chqBankName = null

    //  account selected is Bank
    let paymentAccountId: any
    if (paymentMethod == 2) paymentAccountId = selectedAccount?._id
    else paymentAccountId = null

    // chqImages
    let media: any = []
    for (let i = 0; i < attachedMedia?.length; i++) {
      media[i] = attachedMedia[i]?.src
    }

    // detailsToEdit.invoiceURL = invoiceUrl

    //  Updated Payment SubItems
    let checkUpd: any = []

    for (let i = 0; i < updatePaymentSubItem?.length; i++) {
      checkUpd[i] = {
        id: updatePaymentSubItem[i]?.id,
        _id: updatePaymentSubItem[i]._id,
        name: updatePaymentSubItem[i]?.name,
        paymentItemListId: updatePaymentSubItem[i]?.paymentItemListId,
        amount: parseFloat(updatePaymentSubItem[i].amount),
        quantity: parseFloat(updatePaymentSubItem[i].quantity),
        totalAmount: updatePaymentSubItem[i].totalAmount,
        VATAmount: updatePaymentSubItem[i]?.isLatePaymentFeesRecord
          ? (parseFloat(updatePaymentSubItem[i].amount) / 100) *
            parseFloat(updatePaymentSubItem[i].VATPercentage)
          : ((parseFloat(updatePaymentSubItem[i].amount) *
              parseFloat(updatePaymentSubItem[i].quantity)) /
              100) *
            parseFloat(updatePaymentSubItem[i].VATPercentage),
        VATPercentage: parseFloat(updatePaymentSubItem[i].VATPercentage),
        latePaymentFees: updatePaymentSubItem[i].latePaymentFees,
        latePaymentSelectionStatus: updatePaymentSubItem[i]?.latePaymentSelectionStatus,
        paymentId: incomingPaymentId,
        latePaymentObject: updatePaymentSubItem[i].latePaymentObject,
        nextLatePaymentCycle: updatePaymentSubItem[i]?.nextLatePaymentCycle,
        oldLatePaymentCycleList: updatePaymentSubItem[i]?.oldLatePaymentCycleList,
        serviceRequestId: updatePaymentSubItem[i]?.serviceRequestId,
        serviceJobId: updatePaymentSubItem[i]?.serviceJobId,
      }
    }

    // New Payment SubItems
    let checkNew: any = []

    for (let i = 0; i < newPaymentSubItem.length; i++) {
      checkNew[i] = {
        id: newPaymentSubItem[i]?.id,
        _id: newPaymentSubItem[i]._id,
        name: newPaymentSubItem[i]?.name,
        paymentItemListId: newPaymentSubItem[i]?.paymentItemListId,
        amount: parseFloat(newPaymentSubItem[i].amount),
        quantity: parseFloat(newPaymentSubItem[i].quantity),
        totalAmount: newPaymentSubItem[i].totalAmount,
        VATAmount: newPaymentSubItem[i]?.isLatePaymentFeesRecord
          ? (parseFloat(newPaymentSubItem[i].amount) / 100) *
            parseFloat(newPaymentSubItem[i].VATPercentage)
          : ((parseFloat(newPaymentSubItem[i].amount) * parseFloat(newPaymentSubItem[i].quantity)) /
              100) *
            parseFloat(newPaymentSubItem[i].VATPercentage),
        VATPercentage: parseFloat(newPaymentSubItem[i].VATPercentage),
        latePaymentFees: newPaymentSubItem[i].latePaymentFees,
        latePaymentSelectionStatus: newPaymentSubItem[i]?.latePaymentSelectionStatus,
        paymentId: incomingPaymentId,
        latePaymentObject: newPaymentSubItem[i].latePaymentObject,
        nextLatePaymentCycle: newPaymentSubItem[i]?.nextLatePaymentCycle,
        oldLatePaymentCycleList: newPaymentSubItem[i]?.oldLatePaymentCycleList,
        serviceRequestId: newPaymentSubItem[i]?.serviceRequestId,
        serviceJobId: newPaymentSubItem[i]?.serviceJobId,
      }
    }

    // deleted Items
    let deleteUpd: any = []
    for (let i = 0; i < deletePaymentSubItem?.length; i++) {
      deleteUpd[i] = {
        _id: deletePaymentSubItem[i]?._id,
      }
    }

    let body: any
    if (val === 1) {
      body = {
        payment: {
          _id: detailsToEdit?._id,
          name: detailsToEdit?.name,
          note: notes,
          currency: 'AED',
          paymentStatus: detailsToEdit?.paymentStatus,
          categories: pCat,
          amountPaid: detailsToEdit?.amountPaid,
          outstandingAmount: grandTotal - detailsToEdit?.amountPaid,
          subTotalAmount: nonVat,
          totalVAT: vatAmount,
          totalAmount: grandTotal,
          // paymentType: 0,
          paymentMethod: paymentMethod,
          paymentAccountId: paymentAccountId,
          chequeBankName: chqBankName,
          dueDate: dueDateToShow,
          attachments: media,
          chequeImages: chqImages,
          isCardVerificationEnable: checkVerify,
          tenancyId: detailsToEdit?.tenancyId,
          unitId: detailsToEdit?.unitId,
          clusterId: detailsToEdit?.clusterId,
          unitGroupId: detailsToEdit?.unitGroupId,
          communityId: detailsToEdit?.communityId,
          floorId: detailsToEdit?.floorId,
          buildingId: detailsToEdit?.buildingId,
          // serviceRequestId: null,
          // serviceJobIds: [],
          reminderAfterPayment: {
            startDate: scheduleStartDate,
            endDate: eDate,
            frequency: fq,
            isEndDateChecked: isEndDateChecked,
          },
        },
        new_payment_sub_item: checkNew,
        update_payment_sub_item: checkUpd,
        delete_payment_sub_item: idsToDelete,
        new_payment_invoice: newPaymentInv,
        update_payment_invoice: updatePaymentInv,
        delete_payment_invoice: deletePaymentInv,
      }
    } else {
      body = {
        payment: {
          _id: detailsToEdit?._id,
          name: detailsToEdit?.name,
          note: notes,
          // invoiceURL: detailsToEdit.invoiceURL,
          currency: 'AED',
          // invoiceNo: detailsToEdit?.invoiceNo,
          // invoiceDate: detailsToEdit?.invoiceDate,
          paymentStatus: detailsToEdit?.paymentStatus,
          categories: pCat,
          amountPaid: detailsToEdit?.amountPaid,
          outstandingAmount: grandTotal - detailsToEdit?.amountPaid,
          subTotalAmount: nonVat,
          totalVAT: vatAmount,
          totalAmount: grandTotal,
          // paymentType: 0,
          paymentMethod: paymentMethod,
          paymentAccountId: paymentAccountId,
          chequeBankName: chqBankName,
          dueDate: dueDateToShow,
          attachments: media,
          chequeImages: chqImages,
          isCardVerificationEnable: checkVerify,
          tenancyId: detailsToEdit?.tenancyId,
          unitId: detailsToEdit?.unitId,
          clusterId: detailsToEdit?.clusterId,
          unitGroupId: detailsToEdit?.unitGroupId,
          communityId: detailsToEdit?.communityId,
          floorId: detailsToEdit?.floorId,
          buildingId: detailsToEdit?.buildingId,
          // serviceRequestId: null,
          // serviceJobIds: [],
          reminderAfterPayment: {
            startDate: scheduleStartDate,
            endDate: eDate,
            frequency: fq,
            isEndDateChecked: isEndDateChecked,
          },
        },
        new_payment_sub_item: newData,
        update_payment_sub_item: updateData,
        delete_payment_sub_item: dataToDelete,
      }
    }

    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment/update_all_in_one?isCloneRecord=true`
    else url = `corporate/payment/update_all_in_one`

    ApiPost(`${url}`, body)
      .then(async (res: any) => {
        if (val == 1) {
          setInvoiceModal(false)
          setNewInvoiceModal(false)
          setTimeout(() => {
            refetchPaymentItems()
          }, 2000)
          setShowEdit(false)
          SuccessToast('Payment Items have been updated successfully!!!')
        }
        if (val == 2) {
          handleClose()
          SuccessToast('Payment Method has been updated successfully!!!')
          getPaymentById(detailsToEdit?._id)
          setShowEditMethod(false)
        }
        if (val == 3) {
          handleClose()
          SuccessToast('Attachments have been updated successfully!!!')
          getPaymentById(detailsToEdit?._id)
          setShowEditAttach(false)
        }
        if (val == 4) {
          handleClose()
          SuccessToast('Notes have been updated successfully!!!')
          getPaymentById(detailsToEdit?._id)
          setShowEditNote(false)
        }
        if (val == 5 || val == 51 || val == 52) {
          // handleClose()
          SuccessToast('Due date has been updated successfully!!!')
          // getPaymentById(detailsToEdit?._id)
          setShowEditDue(false)
          refetchPaymentItems()
        }

        if (val == 7) {
          handleClose()
          setGeneratedDates(generatedDatesUpdated)
          SuccessToast('Schedule has been updated successfully!!!')
          setScheduleModal(false)
        }

        getAllPayments()
      })
      .catch((err: any) => console.log('err', err))

    console.log(detailsToEdit)
  }

  const deletePayment = async () => {
    let url: any
    if (type == 'terminate-tenancy')
      url = `corporate/payment/${detailsToEdit?._id}?isCloneRecord=true`
    else url = `corporate/payment/${detailsToEdit?._id}`

    await ApiDelete(`${url}`)
      .then((res) => {
        SuccessToast(res?.data?.message)
        handleClose()
        getAllPayments()
      })
      .catch((err) => console.log('err', err))
  }

  const deleteSubItem = async (id: any) => {
    await ApiDelete(`corporate/payment_sub_item/${id}`).then((res) => {
      SuccessToast(res?.data?.message)
    })
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const getLatePaymentHistory = (item: any) => {
    const body = {
      limit: 10,
      page: page,
      paymentId: detailsToEdit?._id,
      latePaymentSubItemId: item?._id,
    }

    let url: any
    if (type == 'terminate-tenancy')
      url = `corporate/late_payment_item_history/get?isCloneRecord=true`
    else url = `corporate/late_payment_item_history/get`

    ApiPost(`${url}`, body)
      .then((res) => {
        setLateFeeData(res?.data?.data?.late_payment_item_history)
        setPageLimit(res?.data?.data?.state?.page_limit)
        setLateFeeModal(true)

        let values = [...isRadio]
        for (let i = 0; i < res?.data?.data?.late_payment_item_history.length; i++) {
          values[i] = {
            id: res?.data?.data?.late_payment_item_history?.[i]?._id,
            ischecked: false,
          }
        }
        console.log(values)
        setIsRadio(values)
      })
      .catch(() => {})
  }

  // useEffect(() => {
  //   // getLatePaymentHistory(lateID)
  // }, [page])

  const removeLatePaymentHistory = () => {
    let temp: any = []
    for (let i = 0; i < isRadio?.length; i++) {
      if (isRadio[i].ischecked == true) {
        temp.push(isRadio[i].id)
      }
    }
    const body = {
      latePaymentItemHistoryIds: temp,
      latePaymentSubItemId: lateID,
    }

    let url: any
    if (type == 'terminate-tenancy')
      url = `corporate/late_payment_item_history/delete_checker?isCloneRecord=true`
    else url = `corporate/late_payment_item_history/delete_checker`

    ApiPost(`${url}`, body)
      .then((res) => {
        const values = [...fields]
        for (let i = 0; i < fields?.length; i++) {
          if (lateID == fields[i]._id) {
            values[i].qty = res?.data?.data?.quantity
            values[i].amount = res?.data?.data?.amount
            values[i].vat = res?.data?.data?.VATPercentage
            values[i].total = res?.data?.data?.totalAmount
            values[i].removeHistoryIDs = temp
          }
        }
        setFields(values)

        changeFields(values)
        setLateFeeModal(false)
        //  SuccessToast(res?.data?.message)
        //  getPaymentById(detailsToEdit?._id)
      })
      .catch(() => {})

    // ApiPost(`corporate/late_payment_item_history/delete`, body)
    //   .then((res) => {
    //    SuccessToast(res?.data?.message)
    //    getPaymentById(detailsToEdit?._id)
    //   })
    //   .catch(() => {})
  }

  // CREATING a late payment item
  const getLatePaymentItem = async (item: any, index: any) => {
    const body = {
      payment: {
        dueDate: dueDateToShow,
      },
      payment_sub_item: {
        name: item?.lateDetails?.name,
        paymentItemListId:
          item?.paymentItemListId != null ? item?.paymentItemListId : item?.lateDetails?._id,
        amount: parseFloat(item?.amount),
        quantity: parseFloat(item?.qty),
        totalAmount: parseFloat(item?.total),
        VATAmount:
          ((parseFloat(item?.amount) * parseFloat(item?.qty)) / 100) * parseFloat(item?.vat),
        VATPercentage: parseFloat(item?.vat),
        latePaymentFees: item?.lateFeeTotal,
        latePaymentSelectionStatus:
          item?.activeLATE === true ? 1 : item?.InactiveLATE === true ? 2 : 0,
        latePaymentObject: {
          _id: item?.lateDetails?.late_payment_item_charge[0]?._id,
          dayAfterPaymentDue: item?.lateDetails?.late_payment_item_charge[0]?.dayAfterPaymentDue,
          frequency: item?.lateDetails?.late_payment_item_charge[0]?.frequency,
          feeType: item?.lateDetails?.late_payment_item_charge[0]?.feeType,
          percentageAmount: item?.lateDetails?.late_payment_item_charge[0]?.percentageAmount,
          fixedAmount: item?.lateDetails?.late_payment_item_charge[0]?.fixedAmount,
          VATPercentage: item?.lateDetails?.late_payment_item_charge[0]?.VATPercentage,
          VATAmount: item?.lateDetails?.late_payment_item_charge[0]?.VATAmount,
        },
      },
    }

    await ApiPost(`corporate/payment_sub_item/late_payment/calculation`, body)
      .then((res) => {
        const values = [...fields]
        // console.log(values)
        values.push({
          // _id: `${res?.data?.data._id}`,
          // id: `${res?.data?.data.id}`,
          item: res?.data?.data?.name,
          amount: res?.data?.data?.amount,
          qty: res?.data?.data?.quantity,
          vat: res?.data?.data?.VATPercentage,
          total: res?.data?.data?.totalAmount,
          lateApplied: false,
          lateDetails: res?.data?.data?.latePaymentObject,
          activeLATE: false,
          InactiveLATE: false,
          lateFeeTotal: 0,
          lateOnce: false,
          isLateItem: true,
          lateItemId: null,
          lateItemIndex: null,
          paymentItemListId: res?.data?.data?.paymentItemListId,
          latePaymentSubItemId: res?.data?.data?.latePaymentSubItemId,
          isLatePaymentFeesRecord: res?.data?.data?.isLatePaymentFeesRecord,
        })

        values[index].latePaymentSubItemId = `${res?.data?.data?._id}`
        values[index].lateItemIndex = fields?.length

        values[index].lateFeeTotal = res?.data?.data?.totalAmount
        setFields(values)

        changeFields(values)
      })
      .catch((err) => console.log('err', err))
  }

  // generate an invoice
  const generateNewInvoice = async () => {
    // Category
    let x = getStatus()
    if (x == 'Overdue') setPaymentCategory(1)
    else if (x == 'Upcoming') setPaymentCategory(0)

    let attachments: any = []

    for (let i = 0; i < invoiceAttachedMedia?.length; i++) {
      attachments[i] = invoiceAttachedMedia[i]?.src
    }

    console.log(attachments)

    let today = new Date()
    let pStatus: any
    if (paidAmount === 0 || paidAmount === '') pStatus = 0
    else if (paidAmount < totalAED) pStatus = 2
    else if (paidAmount >= totalAED) pStatus = 1
    else console.log()

    // Payment Method
    let payMethod: any
    if (paymentMethod === 'Bank') payMethod = 0
    else if (paymentMethod === 'Cheque') payMethod = 1
    else if (paymentMethod === 'Cash') payMethod = 2
    else payMethod = 2

    // Dynamic Data
    let newData: any = []
    let newObj: any = {}
    for (let i = 0; i < fields.length; i++) {
      // if (fields[i]?.lateApplied) {
      // if(fields[i]?.lateDetails?.late_payment_item_charge){
      let x = fields[i]?.lateDetails?.late_payment_item_charge?.[0]

      let xId: any = x?._id
      newObj = {
        _id: xId,
        // id: '' + x?.id + '',
        dayAfterPaymentDue: x?.dayAfterPaymentDue,
        frequency: x?.frequency,
        feeType: x?.feeType,
        percentageAmount: x?.percentageAmount,
        fixedAmount: x?.fixedAmount,
        VATPercentage: x?.VATPercentage,
        VATAmount: x?.VATPercentage,
        // totalAmount: fields[i].lateFeeTotal,
        // }
        // }
      }

      let total: any

      let isNo: any = isNaN(parseFloat(fields[i].total))
      if (parseFloat(fields[i].total) == null || isNo == true) total = 0
      else total = parseFloat(fields[i].total)

      newData[i] = {
        id: fields[i]?.id,
        _id: fields[i]._id,
        name: fields[i]?.lateDetails?.name,
        paymentItemListId:
          fields[i]?.paymentItemListId != null
            ? fields[i]?.paymentItemListId
            : fields[i]?.lateDetails?._id,

        amount: parseFloat(fields[i].amount),
        quantity: parseFloat(fields[i].qty),
        totalAmount: fields[i]?.lateApplied ? total : parseFloat(fields[i].total),
        // totalAmount:  parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal),
        VATAmount:
          ((parseFloat(fields[i].amount) * parseFloat(fields[i].qty)) / 100) *
          parseFloat(fields[i].vat),
        VATPercentage: parseFloat(fields[i].vat),
        latePaymentFees: fields[i]?.lateApplied ? fields[i].lateFeeTotal : 0,
        latePaymentSelectionStatus:
          fields[i]?.activeLATE == true ? 1 : fields[i]?.InactiveLATE == true ? 2 : 0,
        paymentId: incomingPaymentId,
        latePaymentObject: newObj,
      }
    }

    let grandTotal: any = 0
    for (let i = 0; i < newData?.length; i++) {
      grandTotal = grandTotal + newData[i]?.totalAmount
    }

    const body = {
      invoiceNo: invoiceNo,
      currency: 'AED',
      name: detailsToEdit?.name,
      invoiceDate: invoiceDate,
      dueDate: dueDateToShow,
      _id: detailsToEdit?._id,
      unitId: detailsToEdit?.unitId,
      tenancyId: detailsToEdit?.tenancyId,
      tenantId: detailsToEdit?.tenantId,
      invoiceNote: invoiceNotes,
      invoiceAttachments: attachments,
      paymentMethod: parseInt(paymentMethod),
      paymentStatus: detailsToEdit?.paymentStatus,
      outstandingAmount: grandTotal - detailsToEdit?.amountPaid,
      categories: detailsToEdit?.categories,
      subTotalAmount: nonVat,
      totalVAT: vatAmount,
      totalAmount: grandTotal,
      amountPaid: detailsToEdit?.amountPaid,
    }

    await ApiPost(`corporate/payment/invoice`, body)
      .then(() => {
        setNewInvoiceModal(false)
        SuccessToast('Payment has been updated successfully!')
        // updatePaymentRecord(1)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
    // }
  }

  const changeFields = async (fields: any) => {
    let temp: any = []
    let tempNew: any = []

    let k: any = 0
    let m: any = 0

    for (let i = 0; i < fields?.length; i++) {
      if (fields[i].isNew == false) {
        let x = fields[i]?.lateDetails?.late_payment_item_charge?.[0]
        let xId: any = x?._id

        temp[k] = {
          _id: fields[i]?._id,
          id: fields[i]?.id,
          name: fields[i]?.item,
          amount: parseFloat(fields[i]?.amount),
          quantity: parseFloat(fields[i]?.qty),
          totalAmount: parseFloat(fields[i]?.total),
          VATPercentage: parseFloat(fields[i]?.vat),
          VATAmount: fields[i]?.isLatePaymentFeesRecord
            ? (parseFloat(fields[i].amount) / 100) * parseFloat(fields[i].vat)
            : ((parseFloat(fields[i].amount) * parseFloat(fields[i].qty)) / 100) *
              parseFloat(fields[i].vat),
          // latePaymentFees: fields[i]?.lateFeeTotal,
          latePaymentObject: {
            _id: xId,
            // id: '' + x?.id + '',
            dayAfterPaymentDue: x?.dayAfterPaymentDue,
            frequency: x?.frequency,
            feeType: x?.feeType,
            percentageAmount: x?.percentageAmount,
            fixedAmount: x?.fixedAmount,
            VATPercentage: x?.VATPercentage,
            VATAmount: x?.VATPercentage,
            // totalAmount: fields[i].lateFeeTotal,
          },
          latePaymentFees: fields[i].lateFeeTotal,
          // latePaymentFees: fields[i]?.lateApplied ? fields[i].lateFeeTotal : 0,
          latePaymentSelectionStatus:
            fields[i]?.activeLATE == true ? 1 : fields[i]?.InactiveLATE == true ? 2 : 0,
          isLatePaymentFeesRecord: fields[i]?.isLatePaymentFeesRecord,
          paymentSubItemId: fields[i]?.paymentSubItemId,
          latePaymentSubItemId: fields[i]?.latePaymentSubItemId,
          paymentItemListId: fields[i]?.paymentItemListId,
          isEditable: fields[i]?.isEditable,
          isDeletable: fields[i]?.isDeletable,
          nextLatePaymentCycle: fields[i]?.nextLatePaymentCycle,
          oldLatePaymentCycleList: fields[i]?.oldLatePaymentCycleList,
          serviceRequestId: fields[i]?.serviceRequestId,
          serviceJobId: fields[i]?.serviceJobId,
        }
        k++
      } else {
        let x = fields[i]?.lateDetails?.late_payment_item_charge?.[0]

        let xId: any = x?._id

        tempNew[m] = {
          name: fields[i]?.item,
          amount: parseFloat(fields[i]?.amount),
          quantity: parseFloat(fields[i]?.qty),
          totalAmount: parseFloat(fields[i]?.total),
          VATPercentage: parseFloat(fields[i]?.vat),
          VATAmount: fields[i]?.isLatePaymentFeesRecord
            ? (parseFloat(fields[i].amount) / 100) * parseFloat(fields[i].vat)
            : ((parseFloat(fields[i].amount) * parseFloat(fields[i].qty)) / 100) *
              parseFloat(fields[i].vat),
          // latePaymentFees: fields[i]?.lateFeeTotal,
          latePaymentObject: {
            _id: xId,
            // id: '' + x?.id + '',
            dayAfterPaymentDue: x?.dayAfterPaymentDue,
            frequency: x?.frequency,
            feeType: x?.feeType,
            percentageAmount: x?.percentageAmount,
            fixedAmount: x?.fixedAmount,
            VATPercentage: x?.VATPercentage,
            VATAmount: x?.VATPercentage,
            // totalAmount: fields[i].lateFeeTotal,
          },
          latePaymentFees: fields[i].lateFeeTotal,
          // latePaymentFees: fields[i]?.lateApplied ? fields[i].lateFeeTotal : 0,
          latePaymentSelectionStatus:
            fields[i]?.activeLATE == true ? 1 : fields[i]?.InactiveLATE == true ? 2 : 0,
          isLatePaymentFeesRecord: fields[i]?.isLatePaymentFeesRecord,
          paymentSubItemId: fields[i]?.paymentSubItemId,
          latePaymentSubItemId: fields[i]?.latePaymentSubItemId,
          paymentItemListId: fields[i]?.lateDetails?._id,
          nextLatePaymentCycle: fields[i]?.nextLatePaymentCycle,
          oldLatePaymentCycleList: fields[i]?.oldLatePaymentCycleList,
          paymentSubItemStatus: 2,
          amountPaid: 0,
          outstandingAmount: parseFloat(fields[i]?.total),
          serviceRequestId: fields[i]?.serviceRequestId,
          serviceJobId: fields[i]?.serviceJobId,
        }
        m++
      }
    }

    const body = {
      paymentId: detailsToEdit?._id,
      payment_sub_item: temp,
      new_payment_sub_item: tempNew,
    }

    let url: any
    if (type == 'terminate-tenancy')
      url = `corporate/payment_sub_item/update/pre_checking?isCloneRecord=true`
    else url = `corporate/payment_sub_item/update/pre_checking`

    await ApiPost(`${url}`, body)
      .then((res) => {
        setDeletePaymentInv(res?.data?.data?.delete_payment_invoice)
        setDeletePaymentSubItem(res?.data?.data?.delete_payment_sub_item)
        setNewPaymentInv(res?.data?.data?.new_payment_invoice)
        setNewPaymentSubItem(res?.data?.data?.new_payment_sub_item)
        setUpdatePaymentInv(res?.data?.data?.update_payment_invoice)
        setUpdatePaymentSubItem(res?.data?.data?.update_payment_sub_item)

        if (res?.data?.data?.new_payment_invoice?.length > 0) {
          setIsNewPaymentInvoice(true)
          setPaymentInvoiceDetails(res?.data?.data?.new_payment_invoice?.[0])
          setInvoiceNo('')
          setInvoiceNotes('')
        } else {
          setIsNewPaymentInvoice(false)
          setPaymentInvoiceDetails([])
          setInvoiceNo('')
          setInvoiceNotes('')
        }

        if (res?.data?.data?.update_payment_invoice?.length > 0) {
          setIsUpdatedPaymentInvoice(true)
          setPaymentInvoiceDetails(res?.data?.data?.update_payment_invoice?.[0])
          setInvoiceNo(res?.data?.data?.update_payment_invoice?.[0]?.invoiceNo)
          setInvoiceNotes(res?.data?.data?.update_payment_invoice?.[0]?.note)
        } else {
          setIsUpdatedPaymentInvoice(false)
          setPaymentInvoiceDetails([])
          setInvoiceNo('')
          setInvoiceNotes('')
        }

        // const values = [...fields]

        // // console.log(values)
        // values[item?.lateItemIndex] = {
        //   // _id: `${res?.data?.data._id}`,
        //   // id: `${res?.data?.data.id}`,
        //   item: res?.data?.data?.name,
        //   amount: res?.data?.data?.amount,
        //   qty: res?.data?.data?.quantity,
        //   vat: res?.data?.data?.VATPercentage,
        //   total: res?.data?.data?.totalAmount,
        //   lateApplied: false,
        //   lateDetails: res?.data?.data?.latePaymentObject,
        //   activeLATE: false,
        //   InactiveLATE: false,
        //   lateFeeTotal: 0,
        //   lateOnce: false,
        //   isLateItem: true,
        //   lateItemId: null,
        //   lateItemIndex: null,
        //   paymentItemListId: res?.data?.data?.paymentItemListId,
        //   latePaymentSubItemId: res?.data?.data?.latePaymentSubItemId,
        //   isLatePaymentFeesRecord: res?.data?.data?.isLatePaymentFeesRecord,
        // }

        // values[index].latePaymentSubItemId = `${res?.data?.data?._id}`
        // // values[index].lateItemIndex = fields?.length

        // values[index].lateFeeTotal = res?.data?.data?.totalAmount
        // setFields(values)
      })
      .catch((err) => console.log('err', err))
  }

  const updateAmount = async (fields: any, field: any, ind: any) => {
    let temp: any = []
    let tempNew: any = []
    let k: any = 0
    let m: any = 0

    for (let i = 0; i < fields?.length; i++) {
      if (fields[i]._id.length > 0) {
        let x = fields[i]?.lateDetails?.late_payment_item_charge?.[0]
        let xId: any = x?._id

        temp[k] = {
          _id: fields[i]?._id,
          id: fields[i]?.id,
          name: fields[i]?.item,
          amount: parseFloat(fields[i]?.amount),
          quantity: parseFloat(fields[i]?.qty),
          totalAmount: parseFloat(fields[i]?.total),
          VATPercentage: parseFloat(fields[i]?.vat),
          VATAmount: fields[i]?.isLatePaymentFeesRecord
            ? (parseFloat(fields[i].amount) / 100) * parseFloat(fields[i].vat)
            : ((parseFloat(fields[i].amount) * parseFloat(fields[i].qty)) / 100) *
              parseFloat(fields[i].vat),
          // latePaymentFees: fields[i]?.lateFeeTotal,
          latePaymentObject: {
            _id: xId,
            // id: '' + x?.id + '',
            dayAfterPaymentDue: x?.dayAfterPaymentDue,
            frequency: x?.frequency,
            feeType: x?.feeType,
            percentageAmount: x?.percentageAmount,
            fixedAmount: x?.fixedAmount,
            VATPercentage: x?.VATPercentage,
            VATAmount: x?.VATPercentage,
            // totalAmount: fields[i].lateFeeTotal,
          },
          latePaymentFees: fields[i].lateFeeTotal,
          // latePaymentFees: fields[i]?.lateApplied ? fields[i].lateFeeTotal : 0,
          latePaymentSelectionStatus:
            fields[i]?.activeLATE == true ? 1 : fields[i]?.InactiveLATE == true ? 2 : 0,
          isLatePaymentFeesRecord: fields[i]?.isLatePaymentFeesRecord,
          paymentSubItemId: fields[i]?.paymentSubItemId,
          latePaymentSubItemId: fields[i]?.latePaymentSubItemId,
          paymentItemListId: fields[i]?.paymentItemListId,
          isEditable: fields[i]?.isEditable,
          isDeletable: fields[i]?.isDeletable,
          nextLatePaymentCycle: fields[i]?.nextLatePaymentCycle,
          oldLatePaymentCycleList: fields[i]?.oldLatePaymentCycleList,
          serviceRequestId: fields[i]?.serviceRequestId,
          serviceJobId: fields[i]?.serviceJobId,
        }
        k++
      } else {
        let x = fields[i]?.lateDetails?.late_payment_item_charge?.[0]

        let xId: any = x?._id
        tempNew[m] = {
          name: fields[i]?.item,
          amount: parseFloat(fields[i]?.amount),
          quantity: parseFloat(fields[i]?.qty),
          totalAmount: parseFloat(fields[i]?.total),
          VATPercentage: parseFloat(fields[i]?.vat),
          VATAmount: fields[i]?.isLatePaymentFeesRecord
            ? (parseFloat(fields[i].amount) / 100) * parseFloat(fields[i].vat)
            : ((parseFloat(fields[i].amount) * parseFloat(fields[i].qty)) / 100) *
              parseFloat(fields[i].vat),
          // latePaymentFees: fields[i]?.lateFeeTotal,
          latePaymentObject: {
            _id: xId,
            // id: '' + x?.id + '',
            dayAfterPaymentDue: x?.dayAfterPaymentDue,
            frequency: x?.frequency,
            feeType: x?.feeType,
            percentageAmount: x?.percentageAmount,
            fixedAmount: x?.fixedAmount,
            VATPercentage: x?.VATPercentage,
            VATAmount: x?.VATPercentage,
            // totalAmount: fields[i].lateFeeTotal,
          },
          latePaymentFees: fields[i].lateFeeTotal,
          // latePaymentFees: fields[i]?.lateApplied ? fields[i].lateFeeTotal : 0,
          latePaymentSelectionStatus:
            fields[i]?.activeLATE == true ? 1 : fields[i]?.InactiveLATE == true ? 2 : 0,
          isLatePaymentFeesRecord: fields[i]?.isLatePaymentFeesRecord,
          paymentSubItemId: fields[i]?.paymentSubItemId,
          latePaymentSubItemId: fields[i]?.latePaymentSubItemId,
          paymentItemListId: fields[i]?.lateDetails?._id,

          nextLatePaymentCycle: fields[i]?.nextLatePaymentCycle,
          oldLatePaymentCycleList: fields[i]?.oldLatePaymentCycleList,
          paymentSubItemStatus: 2,
          amountPaid: 0,
          outstandingAmount: parseFloat(fields[i]?.total),
          serviceRequestId: fields[i]?.serviceRequestId,
          serviceJobId: fields[i]?.serviceJobId,
        }
        m++
      }
    }

    const body = {
      paymentId: detailsToEdit?._id,
      payment_sub_item: temp,
      new_payment_sub_item: tempNew,
    }

    let url: any
    if (type == 'terminate-tenancy')
      url = `corporate/payment_sub_item/update/pre_checking?isCloneRecord=true`
    else url = `corporate/payment_sub_item/update/pre_checking`

    await ApiPost(`${url}`, body)
      .then((res) => {
        setDeletePaymentInv(res?.data?.data?.delete_payment_invoice)
        setDeletePaymentSubItem(res?.data?.data?.delete_payment_sub_item)
        setNewPaymentInv(res?.data?.data?.new_payment_invoice)
        setNewPaymentSubItem(res?.data?.data?.new_payment_sub_item)
        setUpdatePaymentInv(res?.data?.data?.update_payment_invoice)
        setUpdatePaymentSubItem(res?.data?.data?.update_payment_sub_item)

        for (let i = 0; i < res?.data?.data?.update_payment_sub_item?.length; i++) {
          console.log(res?.data?.data?.update_payment_sub_item[i]?._id)
          if (res?.data?.data?.update_payment_sub_item[i]?._id == field?.latePaymentSubItemId) {
            console.log('Condition true')
            let values = [...fields]
            values[field?.lateItemIndex].amount =
              res?.data?.data?.update_payment_sub_item[i]?.amount
            values[field?.lateItemIndex].qty = res?.data?.data?.update_payment_sub_item[i]?.quantity
            values[field?.lateItemIndex].total =
              res?.data?.data?.update_payment_sub_item[i]?.totalAmount
            values[ind].lateFeeTotal = res?.data?.data?.update_payment_sub_item[i]?.totalAmount

            setFields(values)
          }
        }

        if (res?.data?.data?.new_payment_invoice?.length > 0) {
          setIsNewPaymentInvoice(true)
          setPaymentInvoiceDetails(res?.data?.data?.new_payment_invoice?.[0])
          setInvoiceNo('')
          setInvoiceNotes('')
        } else {
          setIsNewPaymentInvoice(false)
          setPaymentInvoiceDetails([])
          setInvoiceNo('')
          setInvoiceNotes('')
        }

        if (res?.data?.data?.update_payment_invoice?.length > 0) {
          setIsUpdatedPaymentInvoice(true)
          setPaymentInvoiceDetails(res?.data?.data?.update_payment_invoice?.[0])
          setInvoiceNo(res?.data?.data?.update_payment_invoice?.[0]?.invoiceNo)
          setInvoiceNotes(res?.data?.data?.update_payment_invoice?.[0]?.note)
        } else {
          setIsUpdatedPaymentInvoice(false)
          setPaymentInvoiceDetails([])
          setInvoiceNo('')
          setInvoiceNotes('')
        }

        // const values = [...fields]

        // // console.log(values)
        // values[item?.lateItemIndex] = {
        //   // _id: `${res?.data?.data._id}`,
        //   // id: `${res?.data?.data.id}`,
        //   item: res?.data?.data?.name,
        //   amount: res?.data?.data?.amount,
        //   qty: res?.data?.data?.quantity,
        //   vat: res?.data?.data?.VATPercentage,
        //   total: res?.data?.data?.totalAmount,
        //   lateApplied: false,
        //   lateDetails: res?.data?.data?.latePaymentObject,
        //   activeLATE: false,
        //   InactiveLATE: false,
        //   lateFeeTotal: 0,
        //   lateOnce: false,
        //   isLateItem: true,
        //   lateItemId: null,
        //   lateItemIndex: null,
        //   paymentItemListId: res?.data?.data?.paymentItemListId,
        //   latePaymentSubItemId: res?.data?.data?.latePaymentSubItemId,
        //   isLatePaymentFeesRecord: res?.data?.data?.isLatePaymentFeesRecord,
        // }

        // values[index].latePaymentSubItemId = `${res?.data?.data?._id}`
        // // values[index].lateItemIndex = fields?.length

        // values[index].lateFeeTotal = res?.data?.data?.totalAmount
        // setFields(values)
      })
      .catch((err) => console.log('err', err))
  }

  const getInvoiceById = async (id: any) => {
    setItemToAdd([])

    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment_invoice/${id}?isCloneRecord=true`
    else url = `corporate/payment_invoice/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setInvoiceToShowEdit(res?.data?.data)
        if (res?.data?.data?.saveStatus == 0) setViewDraftInvoice(true)
        else setViewInvoice(true)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  const getReceiptById = async (id: any) => {
    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment_receipt/${id}?isCloneRecord=true`
    else url = `corporate/payment_receipt/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setReceiptToShowEdit(res?.data?.data)
        for (let i = 0; i < bankAccountItems?.length; i++) {
          if (bankAccountItems[i]?._id == res?.data?.data?.paymentAccountId) {
            setSelectedAccountReceipt(bankAccountItems[i])
          }
        }
        setViewReceipt(true)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  const [invoiceLinkData, setInvoiceLinkData] = useState<any>([])
  const [receiptLinkModal, setReceiptLinkModal] = useState<any>(false)
  const [receiptSubItemsModal, setReceiptSubItemsModal] = useState<any>(false)
  const [invoiceLinkChecked, setInvoiceLinkChecked] = useState<any>(-1)
  const [invoiceReceiptNo, setInvoiceReceiptNo] = useState<any>('')
  const [invoiceReceiptId, setInvoiceReceiptId] = useState<any>('')

  const [invoiceReceiptURL, setInvoiceReceiptURL] = useState<any>('')
  const [deletePaymentInv, setDeletePaymentInv] = useState<any>([])
  const [deletePaymentSubItem, setDeletePaymentSubItem] = useState<any>([])
  const [newPaymentInv, setNewPaymentInv] = useState<any>([])
  const [newPaymentSubItem, setNewPaymentSubItem] = useState<any>([])
  const [updatePaymentInv, setUpdatePaymentInv] = useState<any>([])
  const [updatePaymentSubItem, setUpdatePaymentSubItem] = useState<any>([])

  const [isUpdatedPaymentInvoice, setIsUpdatedPaymentInvoice] = useState<any>(false)
  const [isNewPaymentInvoice, setIsNewPaymentInvoice] = useState<any>(false)

  const [paymentInvoiceDetails, setPaymentInvoiceDetails] = useState<any>([])

  const [receiptFields, setReceiptFields] = useState<any>([])
  const [totalAmountPaid, setTotalAmountPaid] = useState<any>()

  /* Reminder */
  const [generatedDates, setGeneratedDates] = useState<string[]>([])
  const [generatedDatesUpdated, setGeneratedDatesUpdated] = useState<string[]>([])

  const [isEndDateChecked, setIsEndDateChecked] = useState<any>(false)
  const [frequency, setFrequency] = useState<any>(null)

  const [scheduleStartDate, setScheduleStartDate] = useState<any>(null)
  const [scheduleEndDate, setScheduleEndDate] = useState<any>(null)

  const options = [
    {value: '0', label: 'Once'},
    {value: '1', label: 'Daily'},
    {value: '2', label: 'Weekly'},
    {value: '3', label: 'Monthly'},
  ]

  const optionsChecked = [
    {value: '1', label: 'Daily'},
    {value: '2', label: 'Weekly'},
    {value: '3', label: 'Monthly'},
  ]

  //
  const handleStartDateChange = (date: any) => {
    // if (date < new Date(scheduleEndDate)){
    if (date != null || date !== null) {
      setScheduleStartDate(date.format('YYYY-MM-DD'))
      if (frequency != null) {
        const generatedDates: string[] = []
        const currentDate = new Date(date)
        const endDateObj = new Date(scheduleEndDate)

        if (frequency === 'Once') {
          generatedDates.push(currentDate.toDateString())
        } else {
          while (currentDate <= endDateObj) {
            generatedDates.push(currentDate.toDateString())

            if (frequency === 'Daily') {
              currentDate.setDate(currentDate.getDate() + 1)
            } else if (frequency === 'Weekly') {
              currentDate.setDate(currentDate.getDate() + 7)
            } else if (frequency === 'Monthly') {
              currentDate.setMonth(currentDate.getMonth() + 1)
            }
            // else if (frequency === 'Once') {
            //   currentDate.setDate(endDateObj.getDate() + 1)
            // }
          }
        }
        setGeneratedDatesUpdated(generatedDates)
      }
    } else {
      setScheduleStartDate(null)
    }
    // }
    // else{
    //   ErrorToast("")
    // }
  }

  //
  const handleEndDateChange = (date: any) => {
    // console.log(frequency)
    if (date != null || date !== null) {
      setScheduleEndDate(date.format('YYYY-MM-DD'))
      if (frequency != null) {
        const generatedDates: string[] = []
        const currentDate = new Date(scheduleStartDate)
        const endDateObj = new Date(date)

        if (frequency === 'Once') {
          generatedDates.push(currentDate.toDateString())
        } else {
          while (currentDate <= endDateObj) {
            generatedDates.push(currentDate.toDateString())

            if (frequency === 'Daily') {
              currentDate.setDate(currentDate.getDate() + 1)
            } else if (frequency === 'Weekly') {
              currentDate.setDate(currentDate.getDate() + 7)
            } else if (frequency === 'Monthly') {
              currentDate.setMonth(currentDate.getMonth() + 1)
            }
          }

          setGeneratedDatesUpdated(generatedDates)
        }
      }
    } else {
      setScheduleEndDate(null)
    }
  }

  //
  const calculateDates = (e: any) => {
    let frequency = e.label
    setFrequency(e.label)
    const generatedDates: string[] = []
    const currentDate = new Date(scheduleStartDate)
    const endDateObj = new Date(scheduleEndDate)

    if (frequency === 'Once') {
      generatedDates.push(currentDate.toDateString())
    } else {
      while (currentDate <= endDateObj) {
        generatedDates.push(currentDate.toDateString())

        if (frequency === 'Daily') {
          currentDate.setDate(currentDate.getDate() + 1)
        } else if (frequency === 'Weekly') {
          currentDate.setDate(currentDate.getDate() + 7)
        } else if (frequency === 'Monthly') {
          currentDate.setMonth(currentDate.getMonth() + 1)
        }
      }
    }

    setGeneratedDatesUpdated(generatedDates)
  }

  const [invRecDetails, setInvRecDetails] = useState<any>(null)
  const [invoiceCompany, setInvoiceCompany] = useState<any>(null)
  const [receiptCompany, setReceiptCompany] = useState<any>(null)

  //
  const getDetailsForInvoiceAndReceipt = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setInvRecDetails(res?.data?.data)
        setInvoiceCompany(res?.data?.data?.invoiceCompany)
        setReceiptCompany(res?.data?.data?.receiptCompany)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  function disabledDate(current: Moment) {
    // Disable dates before today's date
    return current && current < moment().startOf('day')
  }

  const [creditNoteToView, setCreditNoteToView] = useState<any>()
  const [viewCreditNote, setViewCreditNote] = useState<any>(false)

  const getCreditNoteById = async (id: any) => {
    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment_credit_note/${id}?isCloneRecord=true`
    else url = `corporate/payment_credit_note/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setCreditNoteToView(res?.data?.data)
        setViewCreditNote(true)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  const [indForMaintenance, setIndForMaintenance] = useState<any>(-1)
  const [indForMaintNew, setIndForMaintNew] = useState<any>(-1)

  /* ========= user  cancelled the entire invoice, now he wants to add new items =========*/
  const [newFields, setNewFields] = useState<any>([])
  var itemInd: any

  const [filteredPaymentItems, setFilteredPaymentItems] = useState<any>([
    {value: '0', label: 'Booking'},
    {value: '1', label: 'Maintenance'},
    {value: '2', label: 'Rent'},
  ])

  const [updDetailsToEdit, setUpdDetailsToEdit] = useState<any>()

  const [showLink, setShowLink] = useState<any>(false)

  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [disableLink, setDisableLink] = useState<any>(true)
  const [serviceJobId, setServiceJobId] = useState<any>('')
  const [serviceRequestId, setServiceRequestId] = useState<any>(null)

  const [jobId, setjobId] = useState<any>('')
  const [requestId, setRequestId] = useState<any>('')

  //
  const handleDateRangeChange = (dates: any) => {
    setStartDate(dates?.[0].format('YYYY.MM.DD'))
    setEndDate(dates?.[1].format('YYYY.MM.DD'))

    const body = {
      page: 1,
      limit: 25,
      startDate: dates?.[0].format('YYYY-MM-DD'),
      endDate: dates?.[1].format('YYYY-MM-DD'),
      unitId: unitId,
    }
    ApiPost(`corporate/service_job/list/payment`, body).then((res: any) => {
      setRadioLinkChecked(-1)
      setMaintenaceReqData(res?.data?.data?.service_job_data)
      setMainReqLinkModal(true)
    })
  }
  const [maintenanceReqData, setMaintenaceReqData] = useState<any>([])
  const [mainReqLinkModal, setMainReqLinkModal] = useState<any>(false)

  const handleAddNewFields = () => {
    const values = [...newFields]
    // console.log(values)
    values.push({
      _id: '',
      id: '',
      item: '',
      amount: '0',
      qty: '1',
      vat: '0',
      total: '',
      totalWithoutLate: 0,
      lateApplied: false,
      lateDetails: [],
      activeLATE: false,
      InactiveLATE: true,
      lateFeeTotal: 0,
      lateOnce: false,
      isLateItem: false,
      lateItemId: null,
      lateItemIndex: null,
      paymentItemListId: null,
      amountPaid: 0,
      outstanding: 0,
      oldLatePaymentCycleList: [],
      nextLatePaymentCycleList: null,
      serviceRequestId: null,
      serviceJobId: null,
      requestId: null,
      jobId: null,
    })
    setNewFields(values)
  }

  const handleAddNewFieldsInEdit = () => {
    const values = [...fields]
    // console.log(values)
    values.push({
      _id: '',
      id: '',
      item: '',
      amount: '0',
      qty: '1',
      vat: '0',
      total: '',

      lateApplied: false,
      lateDetails: [],
      activeLATE: false,
      InactiveLATE: true,
      lateFeeTotal: 0,
      lateOnce: false,
      isLatePaymentFeesRecord: false,
      lateItemId: null,
      lateItemIndex: null,
      paymentItemListId: null,
      amountPaid: 0,
      outstanding: 0,
      oldLatePaymentCycleList: [],
      nextLatePaymentCycleList: null,
      latePaymentSelectionStatus: 0,
      latePaymentSubItemId: null,
      paymentSubItemStatus: 0,
      isNew: true,
      isDeletable: true,
      isEditable: true,
      serviceRequestId: null,
      serviceJobId: null,
      requestId: null,
      jobId: null,
    })
    setFields(values)
  }

  const handleRemoveNewFields = (index: any, item: any) => {}

  //
  const changeNewFields = async (item: any, index: any) => {
    let due: any = null
    if (dueDateForNewItems != null) due = moment(dueDateForNewItems).format('YYYY-MM-DD')
    const body = {
      payment: {
        dueDate: due,
      },
      payment_sub_item: {
        name: item?.lateDetails?.name,
        paymentItemListId:
          item?.paymentItemListId != null ? item?.paymentItemListId : item?.lateDetails?._id,
        amount: parseFloat(item?.amount),
        quantity: parseFloat(item?.qty),
        totalAmount: item?.total == '' ? 0 : parseFloat(item?.total),
        VATAmount:
          ((parseFloat(item?.amount) * parseFloat(item?.qty)) / 100) * parseFloat(item?.vat),
        VATPercentage: parseFloat(item?.vat),
        latePaymentFees: item?.lateFeeTotal,
        latePaymentSelectionStatus:
          item?.activeLATE === true ? 1 : item?.InactiveLATE === true ? 2 : 0,
        latePaymentObject: {
          _id: item?.lateDetails?.late_payment_item_charge[0]?._id,
          dayAfterPaymentDue: item?.lateDetails?.late_payment_item_charge[0]?.dayAfterPaymentDue,
          frequency: item?.lateDetails?.late_payment_item_charge[0]?.frequency,
          feeType: item?.lateDetails?.late_payment_item_charge[0]?.feeType,
          percentageAmount: item?.lateDetails?.late_payment_item_charge[0]?.percentageAmount,
          fixedAmount: item?.lateDetails?.late_payment_item_charge[0]?.fixedAmount,
          VATPercentage: item?.lateDetails?.late_payment_item_charge[0]?.VATPercentage,
          VATAmount: item?.lateDetails?.late_payment_item_charge[0]?.VATAmount,
        },
        serviceRequestId: item?.serviceRequestId,
        serviceJobId: item?.serviceJobId,
      },
    }

    let url: any
    if (type == 'terminate-tenancy')
      url = `corporate/payment_sub_item/late_payment/calculation?isCloneRecord=true`
    else url = `corporate/payment_sub_item/late_payment/calculation`

    await ApiPost(`${url}`, body)
      .then((res) => {
        const values = [...newFields]
        if (res?.data?.data?.name != undefined) {
          // console.log(values)
          if (item?.lateItemIndex != -1) {
            values[item?.lateItemIndex] = {
              // _id: `${res?.data?.data._id}`,
              // id: `${res?.data?.data.id}`,
              item: res?.data?.data?.name,
              amount: res?.data?.data?.amount,
              qty: res?.data?.data?.quantity,
              vat: res?.data?.data?.VATPercentage,
              total: res?.data?.data?.totalAmount,
              lateApplied: false,
              lateDetails: res?.data?.data?.latePaymentObject,
              activeLATE: false,
              InactiveLATE: true,
              lateFeeTotal: 0,
              lateOnce: false,
              isLateItem: true,
              lateItemId: null,
              lateItemIndex: null,
              paymentItemListId: res?.data?.data?.paymentItemListId,
              amountPaid: 0,
              outstanding: res?.data?.data?.totalAmount,
              oldLatePaymentCycleList: [],
              nextLatePaymentCycleList: null,
            }
          } else {
            values.push({
              // _id: `${res?.data?.data._id}`,
              // id: `${res?.data?.data.id}`,
              item: res?.data?.data?.name,
              amount: res?.data?.data?.amount,
              qty: res?.data?.data?.quantity,
              vat: res?.data?.data?.VATPercentage,
              total: res?.data?.data?.totalAmount,
              lateApplied: false,
              lateDetails: res?.data?.data?.latePaymentObject,
              activeLATE: false,
              InactiveLATE: true,
              lateFeeTotal: 0,
              lateOnce: false,
              isLateItem: true,
              lateItemId: null,
              lateItemIndex: null,
              paymentItemListId: res?.data?.data?.paymentItemListId,
              amountPaid: 0,
              outstanding: res?.data?.data?.totalAmount,
              oldLatePaymentCycleList: [],
              nextLatePaymentCycleList: null,
            })

            values[index].lateItemId = `${res?.data?.data?._id}`
            values[index].lateItemIndex = newFields?.length

            values[index].lateFeeTotal = res?.data?.data?.totalAmount
            values[index].oldLatePaymentCycleList = res?.data?.data?.oldLatePaymentCycleList
            values[index].nextLatePaymentCycleList = res?.data?.data?.nextLatePaymentCycle
            setNewFields(values)
          }

          values[index].lateItemId = `${res?.data?.data?._id}`
          // values[index].lateItemIndex = fields?.length
          values[index].lateFeeTotal = res?.data?.data?.totalAmount
          values[index].oldLatePaymentCycleList = res?.data?.data?.oldLatePaymentCycleList
          values[index].nextLatePaymentCycleList = res?.data?.data?.nextLatePaymentCycle

          setNewFields(values)
        } else {
          values.splice(item?.lateItemIndex, 1)
          values[index].lateFeeTotal = 0
          setNewFields(values)
        }
      })
      .catch((err) => console.log('err', err))
  }

  // CREATING a late payment item
  const getLatePaymentItemForNewItem = async (item: any, index: any) => {
    let due: any = null
    if (dueDateForNewItems != null) due = moment(dueDateForNewItems).format('YYYY-MM-DD')
    const body = {
      payment: {
        dueDate: due,
      },
      payment_sub_item: {
        name: item?.lateDetails?.name,
        paymentItemListId:
          item?.paymentItemListId != null ? item?.paymentItemListId : item?.lateDetails?._id,
        amount: parseFloat(item?.amount),
        quantity: parseFloat(item?.qty),
        totalAmount: item?.total == '' ? 0 : parseFloat(item?.total),
        VATAmount:
          ((parseFloat(item?.amount) * parseFloat(item?.qty)) / 100) * parseFloat(item?.vat),
        VATPercentage: parseFloat(item?.vat),
        latePaymentFees: item?.lateFeeTotal,
        latePaymentSelectionStatus:
          item?.activeLATE === true ? 1 : item?.InactiveLATE === true ? 2 : 0,
        latePaymentObject: {
          _id: item?.lateDetails?.late_payment_item_charge[0]?._id,
          dayAfterPaymentDue: item?.lateDetails?.late_payment_item_charge[0]?.dayAfterPaymentDue,
          frequency: item?.lateDetails?.late_payment_item_charge[0]?.frequency,
          feeType: item?.lateDetails?.late_payment_item_charge[0]?.feeType,
          percentageAmount: item?.lateDetails?.late_payment_item_charge[0]?.percentageAmount,
          fixedAmount: item?.lateDetails?.late_payment_item_charge[0]?.fixedAmount,
          VATPercentage: item?.lateDetails?.late_payment_item_charge[0]?.VATPercentage,
          VATAmount: item?.lateDetails?.late_payment_item_charge[0]?.VATAmount,
        },
      },
    }

    let url: any
    if (type == 'terminate-tenancy')
      url = `corporate/payment_sub_item/late_payment/calculation?isCloneRecord=true`
    else url = `corporate/payment_sub_item/late_payment/calculation`

    await ApiPost(`${url}`, body)
      .then((res) => {
        const values = [...newFields]
        if (res?.data?.data?.name != undefined) {
          // console.log(values)
          values.push({
            // _id: `${res?.data?.data._id}`,
            // id: `${res?.data?.data.id}`,
            item: res?.data?.data?.name,
            amount: res?.data?.data?.amount,
            qty: res?.data?.data?.quantity,
            vat: res?.data?.data?.VATPercentage,
            total: res?.data?.data?.totalAmount,
            lateApplied: false,
            lateDetails: res?.data?.data?.latePaymentObject,
            activeLATE: false,
            InactiveLATE: true,
            lateFeeTotal: 0,
            lateOnce: false,
            isLateItem: true,
            lateItemId: null,
            lateItemIndex: null,
            paymentItemListId: res?.data?.data?.paymentItemListId,
            amountPaid: 0,
            outstanding: res?.data?.data?.totalAmount,
            oldLatePaymentCycleList: [],
            nextLatePaymentCycleList: null,
          })

          values[index].lateItemId = `${res?.data?.data?._id}`
          values[index].lateItemIndex = newFields?.length

          values[index].lateFeeTotal = res?.data?.data?.totalAmount
          values[index].oldLatePaymentCycleList = res?.data?.data?.oldLatePaymentCycleList
          values[index].nextLatePaymentCycleList = res?.data?.data?.nextLatePaymentCycle
          setNewFields(values)
        } else {
          values[index].lateFeeTotal = 0
          values[index].lateItemIndex = -1
          setNewFields(values)
        }
      })
      .catch((err) => console.log('err', err))
  }

  const getServiceJobForPayment = () => {
    const body = {
      page: 1,
      limit: 25,
      unitId: detailsToEdit?.unitId,
    }

    let url: any
    if (type == 'terminate-tenancy') url = `corporate/service_job/list/payment?isCloneRecord=true`
    else url = `corporate/service_job/list/payment`

    ApiPost(`${url}`, body)
      .then((res: any) => {
        setRadioLinkChecked(-1)
        setMaintenaceReqData(res?.data?.data?.service_job_data)
        setMainReqLinkModal(true)
      })
      .catch((err: any) => console.log('err', err))
  }

  const validateNewFields = () => {
    let due: any = null
    if (dueDateForNewItems != null) due = moment(dueDateForNewItems).format('YYYY-MM-DD')
    let vatAmount: any = 0
    let nonVat: any = 0

    newFields.map((f: any, ind: any) => {
      if (f?.isLateItem) {
        vatAmount = vatAmount + (f.amount / 100) * f.vat
        nonVat = nonVat + f.amount
      } else {
        vatAmount = vatAmount + ((f.amount * f.qty) / 100) * f.vat
        nonVat = nonVat + f.amount * f.qty
      }
    })

    // payment status
    let pS: any = 0
    let newOutstanding: any = 0

    let itmMissing: any = false
    let ind: any = false
    let mainReqErr: any = false

    for (let i = 0; i < newFields.length; i++) {
      if (
        newFields[i].item == '' ||
        newFields[i].amount == '' ||
        newFields[i].qty == '' ||
        newFields[i].vat == ''
      ) {
        itmMissing = true
        ind = i + 1
        i = newFields.length
      } else if (newFields[i].item == 'Maintenance') {
        if (newFields[i].serviceRequestId == null) mainReqErr = true
        ind = i + 1

        i = newFields.length
      }
    }
    if (itmMissing == true) ErrorToast(`Item # ${ind} has some missing fields..`)
    else if (mainReqErr) ErrorToast(`Please link Item # ${ind} to some Maintenance Request..`)
    else {
      // Dynamic Data related to latePayment Object and sub-Item
      let newData: any = []
      let newObj: any = {}
      for (let i = 0; i < newFields.length; i++) {
        if (newFields[i]?.lateApplied) {
          // if(fields[i]?.lateDetails?.late_payment_item_charge){
          let x = newFields[i]?.lateDetails

          let xId: any = x.late_payment_item_charge[0]?._id
          newObj = {
            _id: xId,
            dayAfterPaymentDue: x.late_payment_item_charge[0]?.dayAfterPaymentDue,
            frequency: x.late_payment_item_charge[0]?.frequency,
            feeType: x.late_payment_item_charge[0]?.feeType,
            percentageAmount: x.late_payment_item_charge[0]?.percentageAmount,
            fixedAmount: x.late_payment_item_charge[0]?.fixedAmount,
            VATPercentage: x.late_payment_item_charge[0]?.VATPercentage,
            VATAmount: x.late_payment_item_charge[0]?.VATPercentage,
            // }
          }
        }

        let total: any
        // let isNo: any = isNaN(parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal))
        // if (parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal) == null || isNo == true)
        //   total = 0
        // else total = parseFloat(fields[i].total) + parseFloat(fields[i].lateFeeTotal)

        let isNo: any = isNaN(parseFloat(newFields[i].total))
        if (parseFloat(newFields[i].total) == null || isNo == true) total = 0
        else total = parseFloat(newFields[i].total)

        if (newFields[i]?.isLateItem) {
          newData[i] = {
            name: newFields[i]?.item,
            paymentItemListId:
              newFields[i]?.paymentItemListId != null
                ? newFields[i]?.paymentItemListId
                : newFields[i]?.lateDetails?._id,

            amount: parseFloat(newFields[i].amount),
            quantity: parseFloat(newFields[i].qty),
            totalAmount: total,
            // totalAmount:  parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal),
            VATAmount: (parseFloat(newFields[i].amount) / 100) * parseFloat(newFields[i].vat),
            VATPercentage: parseFloat(newFields[i].vat),
            latePaymentFees: newFields[i].lateFeeTotal,
            latePaymentSelectionStatus:
              newFields[i]?.activeLATE === true ? 1 : newFields[i]?.InactiveLATE === true ? 2 : 0,
            paymentId: incomingPaymentId,
            latePaymentObject: newObj,
            amountPaid: parseFloat(newFields[i]?.amountPaid),
            outstandingAmount: parseFloat(newFields[i].outstanding),
            paymentSubItemStatus: 0,
            isLatePaymentFeesRecord: true,
            serviceRequestId: newFields[i].serviceRequestId ? newFields[i].serviceRequestId : null,
            serviceJobId: newFields[i].serviceJobId ? newFields[i].serviceJobId : null,
            // previousAmmountPaid: 0,
          }
        } else {
          newData[i] = {
            name: newFields[i]?.item,
            paymentItemListId:
              newFields[i]?.paymentItemListId != null
                ? newFields[i]?.paymentItemListId
                : newFields[i]?.lateDetails?._id,

            amount: parseFloat(newFields[i].amount),
            quantity: parseFloat(newFields[i].qty),
            totalAmount: total,
            // totalAmount:  parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal),
            VATAmount:
              ((parseFloat(newFields[i].amount) * parseFloat(newFields[i].qty)) / 100) *
              parseFloat(newFields[i].vat),
            VATPercentage: parseFloat(newFields[i].vat),
            latePaymentFees: newFields[i].lateFeeTotal,
            latePaymentSelectionStatus:
              newFields[i]?.activeLATE === true ? 1 : newFields[i]?.InactiveLATE === true ? 2 : 0,
            paymentId: incomingPaymentId,
            latePaymentObject: newObj,
            amountPaid: parseFloat(newFields[i]?.amountPaid),
            outstandingAmount: parseFloat(newFields[i].outstanding),
            // previousAmmountPaid: 0,
            oldLatePaymentCycleList: newFields[i]?.oldLatePaymentCycleList,
            nextLatePaymentCycle: newFields[i]?.nextLatePaymentCycleList,
            paymentSubItemStatus: 0,
            isLatePaymentFeesRecord: false,
            serviceRequestId: newFields[i].serviceRequestId,
            serviceJobId: newFields[i].serviceJobId,
          }
        }
      }

      const body = {
        payment: {
          _id: updDetailsToEdit?._id,
          name: updDetailsToEdit?.name,
          note: updDetailsToEdit?.note,
          currency: updDetailsToEdit?.currency,
          paymentStatus: updDetailsToEdit?.paymentStatus,
          categories: updDetailsToEdit?.categories,
          amountPaid: updDetailsToEdit?.amountPaid,
          outstandingAmount: parseFloat(totalAED) - parseFloat(paidAmount),
          subTotalAmount: parseFloat(nonVat),
          totalVAT: parseFloat(vatAmount),
          totalAmount: parseFloat(totalAED),
          paymentMethod: updDetailsToEdit?.paymentMethod,
          paymentAccountId: updDetailsToEdit?.paymentAccountId,
          chequeBankName: updDetailsToEdit?.chequeBankName,
          dueDate: due,
          attachments: updDetailsToEdit?.attachments,
          chequeImages: updDetailsToEdit?.chequeImages,
          tenancyId: updDetailsToEdit?.tenancyId,
          unitId: updDetailsToEdit?.unitId,
          clusterId: updDetailsToEdit?.clusterId,
          unitGroupId: updDetailsToEdit?.unitGroupId,
          communityId: updDetailsToEdit?.communityId,
          floorId: updDetailsToEdit?.floorId,
          buildingId: updDetailsToEdit?.buildingId,
          // serviceRequestId: serviceRequestId,
          // serviceJobIds: [],
          reminderAfterPayment: updDetailsToEdit?.reminderAfterPayment,
        },
        new_payment_sub_item: newData,
      }

      let url: any
      if (type == 'terminate-tenancy')
        url = `corporate/payment/update_all_in_one?isCloneRecord=true`
      else url = `corporate/payment/update_all_in_one`

      ApiPost(`${url}`, body)
        .then(async (res: any) => {
          SuccessToast('New Payment Items have been added successfully !!!')
          refetchPaymentItems()
          setShowEdit(false)
          // handleClose()
          // getAllPayments()
        })
        .catch((err: any) => console.log('err', err))
    }
  }

  const [dueDateForNewItems, setDueDateForNewItems] = useState<any>(null)
  const handleDueDateChangeForNewItems = (date: any) => {
    setDueDateError(false)
    setDueDateForNewItems(date)
    // setDueDateToShow(date.toDate());
    // setDueDateToShow(date.format('YYYY-MM-DD'))
  }

  const getNegative = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number > 0 ? number * -1 : number
  }

  const getPositive = (number: any) => {
    // if number is greater than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const [invoiceSubItemsModal, setInvoiceSubItemsModal] = useState<any>(false)
  const [invoiceFields, setInvoiceFields] = useState<any>([])

  const validateNewInvoice = (val: any) => {
    let vatAmount: any = 0
    let nonVat: any = 0

    invoiceFields.map((f: any, ind: any) => {
      if (f?.isSelected == 1) {
        if (f?.data?.isLatePaymentFeesRecord) {
          vatAmount = vatAmount + (f.amount / 100) * f.vat
          nonVat = nonVat + f.amount
        } else {
          vatAmount = vatAmount + ((f.amount * f.qty) / 100) * f.vat
          nonVat = nonVat + f.amount * f.qty
        }
      }
    })

    // Dynamic Data related to latePayment Object and sub-Item
    let newData: any = []
    let newObj: any = {}
    let k: any = 0
    for (let i = 0; i < invoiceFields.length; i++) {
      if (invoiceFields[i]?.isSelected == 1) {
        if (invoiceFields[i]?.lateApplied) {
          // if(fields[i]?.lateDetails?.late_payment_item_charge){
          let x = invoiceFields[i]?.data?.lateDetails

          let xId: any = x.late_payment_item_charge[0]?._id
          newObj = {
            _id: xId,
            dayAfterPaymentDue: x.late_payment_item_charge[0]?.dayAfterPaymentDue,
            frequency: x.late_payment_item_charge[0]?.frequency,
            feeType: x.late_payment_item_charge[0]?.feeType,
            percentageAmount: x.late_payment_item_charge[0]?.percentageAmount,
            fixedAmount: x.late_payment_item_charge[0]?.fixedAmount,
            VATPercentage: x.late_payment_item_charge[0]?.VATPercentage,
            VATAmount: x.late_payment_item_charge[0]?.VATPercentage,
            // }
          }
        }

        let total: any
        let isNo: any = isNaN(parseFloat(invoiceFields[i].total))
        if (parseFloat(invoiceFields[i].total) == null || isNo == true) total = 0
        else total = parseFloat(invoiceFields[i].total)

        newData[k] = {
          _id: invoiceFields[i]?._id,
          name: invoiceFields[i]?.name,
          paymentItemListId:
            invoiceFields[i]?.data?.paymentItemListId != null
              ? invoiceFields[i]?.data?.paymentItemListId
              : invoiceFields[i]?.data?.lateDetails?._id,

          amount: parseFloat(invoiceFields[i].amount),
          quantity: parseFloat(invoiceFields[i].qty),
          totalAmount: total,
          // totalAmount:  parseInt(fields[i].total) + parseInt(fields[i].lateFeeTotal),
          VATAmount: parseFloat(invoiceFields[i].vatAmount),
          VATPercentage: parseFloat(invoiceFields[i].vat),
          latePaymentFees: invoiceFields[i]?.data?.lateFeeTotal,
          latePaymentSelectionStatus: invoiceFields[i]?.data?.latePaymentSelectionStatus,

          paymentId: incomingPaymentId,
          latePaymentObject: newObj,
          amountPaid: parseFloat(invoiceFields[i]?.data?.amountPaid),
          outstandingAmount: parseFloat(invoiceFields[i]?.data?.outstandingAmount),
          paymentSubItemStatus: val == 0 ? 1 : 2,
          isLatePaymentFeesRecord: invoiceFields[i]?.isLateItem,
        }
        k++
      }
    }

    let newInv: any = [
      {
        isMainPDF: false,
        invoiceNo: '',
        name: updDetailsToEdit?.name,
        currency: updDetailsToEdit?.currency,
        date: moment(new Date()).format('YYYY-MM-DD'),
        dueDate: moment(new Date(updDetailsToEdit?.dueDate)).format('YYYY-MM-DD'),
        note: invoiceNotes,
        attachments: invoiceAttachedMedia,
        paymentSubItems: newData,
        paymentMethod: parseInt(invoicePaymentMethod),
        subTotalAmount: parseFloat(nonVat),
        totalVAT: parseFloat(vatAmount),
        totalAmount: parseFloat(nonVat) + parseFloat(vatAmount),
        amountPaid: updDetailsToEdit?.amountPaid,
        isManualPDF: false,
        saveStatus: val,
        isShareToTenant: checkedRec,
      },
    ]

    const body = {
      payment: {
        _id: updDetailsToEdit?._id,
        name: updDetailsToEdit?.name,
        note: updDetailsToEdit?.note,
        currency: updDetailsToEdit?.currency,
        paymentStatus: updDetailsToEdit?.paymentStatus,
        categories: updDetailsToEdit?.categories,
        amountPaid: updDetailsToEdit?.amountPaid,
        outstandingAmount: updDetailsToEdit?.outstandingAmount,
        subTotalAmount: updDetailsToEdit?.subTotalAmount,
        totalVAT: updDetailsToEdit?.totalVAT,
        totalAmount: updDetailsToEdit?.totalAmount,
        paymentMethod: parseInt(invoicePaymentMethod),
        paymentAccountId: selectedAccountInvoice?._id,
        chequeBankName: invoiceChequeBankName,
        dueDate: moment(new Date(updDetailsToEdit?.dueDate)).format('YYYY-MM-DD'),
        attachments: updDetailsToEdit?.attachments,
        chequeImages: updDetailsToEdit?.chequeImages,
        tenancyId: updDetailsToEdit?.tenancyId,
        unitId: updDetailsToEdit?.unitId,
        clusterId: updDetailsToEdit?.clusterId,
        unitGroupId: updDetailsToEdit?.unitGroupId,
        communityId: updDetailsToEdit?.communityId,
        floorId: updDetailsToEdit?.floorId,
        buildingId: updDetailsToEdit?.buildingId,
        // serviceRequestId: updDetailsToEdit?.serviceRequestId,
        // serviceJobIds: [],
        reminderAfterPayment: updDetailsToEdit?.reminderAfterPayment,
      },
      new_payment_invoice: newInv,
    }

    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment/update_all_in_one?isCloneRecord=true`
    else url = `corporate/payment/update_all_in_one`

    ApiPost(`${url}`, body)
      .then(async (res: any) => {
        if (val == 0) SuccessToast('New Payment Invoice has been saved successfully !!!')
        else SuccessToast('New Payment Invoice has been generated successfully !!!')
        setVoidInvoiceModal(false)
        setTimeout(() => {
          refetchPaymentItems()
        }, 2000)

        // handleClose()
        // getAllPayments()
      })
      .catch((err: any) => console.log('err', err))
  }

  const [linkMissingItemToInvoice, setLinkMissingItemToInvoice] = useState<any>(false)
  const [itemToAdd, setItemToAdd] = useState<any>([])

  const getAllInvoicesForMissing = async () => {
    const body = {
      limit: 10,
      page: 1,
      paymentId: detailsToEdit?._id,
      isForReceipt: false,
      isForCreditNote: false,
      isForMissingInvoicePaymentItem: true,
    }

    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment_invoice/get?isCloneRecord=true`
    else url = `corporate/payment_invoice/get`

    await ApiPost(`${url}`, body)
      .then((res) => {
        setLinkMissingItemToInvoice(true)
        setInvoiceLinkData(res?.data?.data?.payment_invoice_data)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  const refetchPaymentItems = () => {
    let url: any
    if (type == 'terminate-tenancy')
      url = `corporate/payment/${detailsToEdit?._id}?isCloneRecord=true`
    else url = `corporate/payment/${detailsToEdit?._id}`

    ApiGet(`${url}`)
      .then(async (res) => {
        detailsToEdit = res?.data?.data
        setUpdDetailsToEdit(res?.data?.data)

        if (detailsToEdit?.payment_sub_item?.length > 0) {
          setIsEmpty(false)

          let values: any = []
          if (unitDetails?.buildingId) {
            ApiGet(`corporate/payment_item_list/payment?buildingId=` + unitDetails?.buildingId)
              .then((res: any) => {
                setPaymentItems(res?.data?.data)
                setFilteredPaymentItems(res?.data?.data)
                let temp = res.data.data
                if (detailsToEdit?.payment_sub_item?.length > 0) {
                  for (let i = 0; i < detailsToEdit?.payment_sub_item?.length; i++) {
                    for (let j = 0; j < temp?.length; j++) {
                      if (detailsToEdit?.payment_sub_item[i]?.paymentItemListId == temp[j]._id) {
                        if (temp[j].latePaymentIsActive == true) {
                          values[i].lateDetails = temp[j]
                        } else {
                          values[i].lateDetails = []
                        }
                      }
                    }
                  }
                }
              })
              .catch((err: any) => console.log('err', err))
          } else if (unitDetails?.communityId) {
            ApiGet(`corporate/payment_item_list/payment?communityId=` + unitDetails?.communityId)
              .then((res: any) => {
                setPaymentItems(res?.data?.data)
                setFilteredPaymentItems(res?.data?.data)

                let temp = res.data.data

                if (detailsToEdit?.payment_sub_item?.length > 0) {
                  for (let i = 0; i < detailsToEdit?.payment_sub_item?.length; i++) {
                    for (let j = 0; j < temp?.length; j++) {
                      if (detailsToEdit?.payment_sub_item[i]?.paymentItemListId == temp[j]._id) {
                        if (temp[j].latePaymentIsActive == true) {
                          values[i].lateDetails = temp[j]
                        } else {
                          values[i].lateDetails = []
                        }
                      }
                    }
                  }
                }
              })
              .catch((err: any) => console.log('err', err))
          }

          for (let i = 0; i < detailsToEdit?.payment_sub_item?.length; i++) {
            values[i] = {
              _id: detailsToEdit?.payment_sub_item[i]._id,
              id: detailsToEdit?.payment_sub_item[i].id,
              item: detailsToEdit?.payment_sub_item[i].name,
              amount: detailsToEdit?.payment_sub_item[i].amount,
              qty: detailsToEdit?.payment_sub_item[i].quantity,
              vat: detailsToEdit?.payment_sub_item[i].VATPercentage,
              total: detailsToEdit?.payment_sub_item[i].totalAmount,
              lateApplied:
                detailsToEdit?.payment_sub_item[i]?.latePaymentSelectionStatus == 1 ? true : false,
              lateDetails: detailsToEdit?.payment_sub_item[i]?.latePaymentObject,
              activeLATE:
                detailsToEdit?.payment_sub_item[i]?.latePaymentSelectionStatus == 1 ? true : false,
              InactiveLATE:
                detailsToEdit?.payment_sub_item[i]?.latePaymentSelectionStatus == 2 ? true : false,
              lateFeeTotal: detailsToEdit?.payment_sub_item[i]?.latePaymentFees,
              lateOnce:
                detailsToEdit?.payment_sub_item[i]?.latePaymentSelectionStatus == 1 ? true : false,
              latePaymentSelectionStatus:
                detailsToEdit?.payment_sub_item[i]?.latePaymentSelectionStatus,
              isLatePaymentFeesRecord: detailsToEdit?.payment_sub_item[i]?.isLatePaymentFeesRecord,
              latePaymentSubItemId: detailsToEdit?.payment_sub_item[i]?.latePaymentSubItemId,
              paymentItemListId: detailsToEdit?.payment_sub_item[i]?.paymentItemListId,
              isDeletable: detailsToEdit?.payment_sub_item[i]?.isDeletable,
              isEditable: detailsToEdit?.payment_sub_item[i]?.isEditable,
              nextLatePaymentCycle: detailsToEdit?.payment_sub_item[i]?.nextLatePaymentCycle,
              oldLatePaymentCycleList: detailsToEdit?.payment_sub_item[i]?.oldLatePaymentCycleList,
              paymentSubItemStatus: detailsToEdit?.payment_sub_item[i]?.paymentSubItemStatus,
              isNew: false,
              refundAmount: detailsToEdit?.payment_sub_item[i]?.totalRefundAmount,
              amountPaid: detailsToEdit?.payment_sub_item[i]?.amountPaid,
              serviceRequestId: detailsToEdit?.payment_sub_item[i]?.serviceRequestId,
              serviceJobId: detailsToEdit?.payment_sub_item[i]?.serviceJobId,
              showLink:
                detailsToEdit?.payment_sub_item[i]?.serviceJobId != null ||
                detailsToEdit?.payment_sub_item[i]?.serviceRequestId != null
                  ? true
                  : false,
              jobId: detailsToEdit?.payment_sub_item[i]?.service_job?.[0]?.id,
              requestId: detailsToEdit?.payment_sub_item[i]?.service_request?.[0]?.id,
            }

            for (let j = 0; j < detailsToEdit?.payment_sub_item?.length; j++) {
              if (
                detailsToEdit?.payment_sub_item[j]._id ==
                detailsToEdit?.payment_sub_item[i]?.latePaymentSubItemId
              ) {
                values[i].lateItemIndex = j
              }
            }
          }

          setFields(values)
          setDiscardFields(values)
        } else {
          setIsEmpty(true)
          if (unitDetails?.buildingId) {
            ApiGet(`corporate/payment_item_list/payment?buildingId=` + unitDetails?.buildingId)
              .then((res: any) => {
                setFilteredPaymentItems(res?.data?.data)
              })
              .catch((err: any) => console.log('err', err))
          } else if (unitDetails?.communityId) {
            ApiGet(`corporate/payment_item_list/payment?communityId=` + unitDetails?.communityId)
              .then((res: any) => {
                setFilteredPaymentItems(res?.data?.data)
              })
              .catch((err: any) => console.log('err', err))
          }
          setFields([])
          setDiscardFields([])
        }

        setPaymentMethod(detailsToEdit?.paymentMethod)
        setInvoicePaymentMethod(detailsToEdit?.paymentMethod)
        setChequeBankName(detailsToEdit?.chequeBankName)
        setInvoiceChequeBankName(detailsToEdit?.chequeBankName)

        // Payment Method is Cheque
        if (detailsToEdit?.paymentMethod == 1) {
          setChequeMedia([])
          setInvoiceChequeMedia([])
          if (detailsToEdit?.chequeImages?.length > 0) {
            let values: any = []
            let userData: any = localStorage.getItem('userData')
            const data = JSON.parse(userData)
            for (let i = 0; i < detailsToEdit?.chequeImages?.length; i++) {
              // console.log(detailsToEdit?.chequeImages[i])
              if (detailsToEdit?.chequeImages[i] != null) {
                let x: any = detailsToEdit?.chequeImages[i]?.split('/')[2]
                let y: any = x.split('.')[0]
                values[i] = {
                  src: {
                    name: detailsToEdit?.chequeImages[i],
                  },
                  name: y,
                  fileType: 'cheque',
                  databaseId: data?._id,
                  image: detailsToEdit?.chequeImages[i],
                }
              }
            }
            setChequeMedia(values)
            setInvoiceChequeMedia(values)
          }
        }

        // Payment Method is Bank
        if (detailsToEdit?.paymentMethod == 2) {
          // console.log(detailsToEdit?.payment_account[0])
          setSelectedAccount(detailsToEdit?.payment_account[0])
          setSelectedAccountInvoice(detailsToEdit?.payment_account[0])
          // console.log(selectedAccount)
        }

        // Payment Invoices
        if (res?.data?.data?.payment_invoice?.length > 0) {
          setPaymentInvoices(res?.data?.data?.payment_invoice)
        } else {
          setPaymentInvoices([])
        }

        // Payment Debit Notes
        if (res?.data?.data?.payment_debit_note?.length > 0) {
          setPaymentDebitNotes(res?.data?.data?.payment_debit_note)
        } else {
          setPaymentDebitNotes([])
        }

        // Payment Refunds
        if (res?.data?.data?.payment_refund?.length > 0) {
          setPaymentRefunds(res?.data?.data?.payment_refund)
        } else {
          setPaymentRefunds([])
        }

        setPaymentCategory(detailsToEdit?.categories)

        setInvoiceLinkChecked(-1)
        setNewFields([])
        setItemToAdd([])
        setIdsToDelete([])

        setShowEdit(false)
        setDueDateForPayment(res?.data?.data?.dueDate)
        setIndForMaintenance(-1)
        setIndForMaintNew(-1)
        setInvoiceFields([])
      })
      .catch(() => {})
  }

  // ----------------------Debit Note ------------------------//
  const [selectInvoiceModal, setSelectInvoiceModal] = useState<any>(false)
  const [debitFields, setDebitFields] = useState<any>([])
  const [debitSubItemsModal, setDebitSubItemsModal] = useState<any>(false)
  const [totalDebitAmount, setTotalDebitAmount] = useState<any>()
  const [debitNoteModal, setDebitNoteModal] = useState<any>(false)
  const [debitNoteToView, setDebitNoteToView] = useState<any>()
  const [viewDebitNote, setViewDebitNote] = useState<any>(false)
  const [viewAllDebitNotes, setViewAllDebitNotes] = useState<any>(false)

  const getAllInvoices = async (val: any) => {
    let body: any

    if (val == 3)
      body = {
        limit: 10,
        page: 1,
        paymentId: detailsToEdit?._id,
        isForReceipt: true,
      }
    else if (val == 4)
      body = {
        limit: 10,
        page: 1,
        paymentId: detailsToEdit?._id,
        isForRefund: true,
      }
    else if (val == 5)
      body = {
        limit: 10,
        page: 1,
        paymentId: detailsToEdit?._id,
        isForDebitNote: true,
      }

    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment_invoice/get?isCloneRecord=true`
    else url = `corporate/payment_invoice/get`

    await ApiPost(`${url}`, body)
      .then((res) => {
        setInvoiceLinkData(res?.data?.data?.payment_invoice_data)
        //receipt
        if (val == 3) setReceiptLinkModal(true)
        // debit note
        else if (val == 5) setSelectInvoiceModal(true)
        else if (val == 4) setInvoiceForRefund(true)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  const getDebitNoteById = async (id: any) => {
    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment_debit_note/${id}?isCloneRecord=true`
    else url = `corporate/payment_debit_note/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setDebitNoteToView(res?.data?.data)
        setViewDebitNote(true)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  // ----------------------Refund ------------------------//
  const [refundModal, setRefundModal] = useState<any>(false)
  const [receiptRefundModal, setReceiptRefundModal] = useState<any>(false)
  const [invoiceForRefund, setInvoiceForRefund] = useState<any>(false)
  const [receiptRefundLinkChecked, setReceiptRefundLinkChecked] = useState<any>(-1)
  const [refundSubItemsModal, setRefundSubItemsModal] = useState<any>(false)
  const [refundReceiptSubItems, setRefundReceiptSubItems] = useState<any>([])
  const [totalRefundAmount, setTotalRefundAmount] = useState<any>(0)
  const [receiptRefundPaidAmount, setReceiptRefundPaidAmount] = useState<any>(0)
  const [invoiceForRefundPayload, setInvoiceForRefundPayload] = useState<any>()
  const [paymentRefunds, setPaymentRefunds] = useState<any>([])
  const [refundToView, setRefundToView] = useState<any>()
  const [viewRefund, setViewRefund] = useState<any>(false)
  const [viewAllRefunds, setViewAllRefunds] = useState<any>()

  const getRefundById = async (id: any) => {
    let url: any
    if (type == 'terminate-tenancy') url = `corporate/payment_refund/${id}?isCloneRecord=true`
    else url = `corporate/payment_refund/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setRefundToView(res?.data?.data)
        setViewRefund(true)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    setIndForMaintenance(-1)
    setIndForMaintNew(-1)
    setcollapseRefund(false)
    setcollapseCreditNote(false)
    setcollapseDebitNote(false)
    setLinkMissingItemToInvoice(false)
    setItemToAdd([])
    setIdsToDelete([])
    setFilteredPaymentItems([])
    setSelectedAccountInvoice('')
    setShowAccInvoice(false)
    setInvoiceFields([])
    setDueDateForNewItems(null)
    getDetailsForInvoiceAndReceipt()
    setReceiptPaymentMethod(0)
    setShowAccReceipt(false)
    setReceiptAttachedMedia([])
    setReceiptChequeBankName('')
    setReceiptAttachedMedia([])
    setViewInvoice(false)
    setViewReceipt(false)
    setInvoiceLinkData([])
    setReceiptLinkModal(false)
    setInvoiceLinkChecked(-1)
    setInvoiceReceiptNo('')
    totalAED = 0
    vatAmount = 0
    nonVat = 0
    setLateFeeModal(false)
    setApplyChanges([])
    setDeletedDontApply([])
    setcollapse(false)
    setcollapseAttach(false)
    setcollapseProp(false)
    setcollapseRec(false)
    setcollapseRemind(false)
    setcollapseInv(false)
    setInvoiceNotes(detailsToEdit?.invoiceNote)

    setAmountPaidTotal(detailsToEdit?.amountPaid)
    setAmountPaidReceipt(0)

    setDatePaidFinal(detailsToEdit?.datePaid)
    setOutstandingAmount(detailsToEdit?.outstandingAmount)

    if (unitDetails?.communityId != null) setDevelopmentName(unitDetails?.community?.[0]?.name)

    if (unitDetails?.buildingId != null) setDevelopmentName(unitDetails?.building?.[0]?.name)

    if (unitDetails?.clusterId != null) setClusterName(unitDetails?.cluster?.[0]?.name)

    if (unitDetails?.unitNo) setUnitName(unitDetails?.unitNo)

    if (detailsToEdit?.payment_sub_item?.length > 0) {
      setIsEmpty(false)
      let values: any = []
      if (unitDetails?.buildingId) {
        ApiGet(`corporate/payment_item_list/payment?buildingId=` + unitDetails?.buildingId)
          .then((res: any) => {
            setPaymentItems(res?.data?.data)
            setFilteredPaymentItems(res?.data?.data)
            let temp = res.data.data
            if (detailsToEdit?.payment_sub_item?.length > 0) {
              for (let i = 0; i < detailsToEdit?.payment_sub_item?.length; i++) {
                for (let j = 0; j < temp?.length; j++) {
                  if (detailsToEdit?.payment_sub_item[i]?.paymentItemListId == temp[j]._id) {
                    if (temp[j].latePaymentIsActive == true) {
                      values[i].lateDetails = temp[j]
                    } else {
                      values[i].lateDetails = []
                    }
                  }
                }
              }
            }
          })
          .catch((err: any) => console.log('err', err))
      } else if (unitDetails?.communityId) {
        ApiGet(`corporate/payment_item_list/payment?communityId=` + unitDetails?.communityId)
          .then((res: any) => {
            setPaymentItems(res?.data?.data)
            setFilteredPaymentItems(res?.data?.data)

            let temp = res.data.data

            if (detailsToEdit?.payment_sub_item?.length > 0) {
              for (let i = 0; i < detailsToEdit?.payment_sub_item?.length; i++) {
                for (let j = 0; j < temp?.length; j++) {
                  if (detailsToEdit?.payment_sub_item[i]?.paymentItemListId == temp[j]._id) {
                    if (temp[j].latePaymentIsActive == true) {
                      values[i].lateDetails = temp[j]
                    } else {
                      values[i].lateDetails = []
                    }
                  }
                }
              }
            }
          })
          .catch((err: any) => console.log('err', err))
      }

      for (let i = 0; i < detailsToEdit?.payment_sub_item?.length; i++) {
        values[i] = {
          _id: detailsToEdit?.payment_sub_item[i]._id,
          id: detailsToEdit?.payment_sub_item[i].id,
          item: detailsToEdit?.payment_sub_item[i].name,
          amount: detailsToEdit?.payment_sub_item[i].amount,
          qty: detailsToEdit?.payment_sub_item[i].quantity,
          vat: detailsToEdit?.payment_sub_item[i].VATPercentage,
          total: detailsToEdit?.payment_sub_item[i].totalAmount,
          lateApplied:
            detailsToEdit?.payment_sub_item[i]?.latePaymentSelectionStatus == 1 ? true : false,
          lateDetails: detailsToEdit?.payment_sub_item[i]?.latePaymentObject,
          activeLATE:
            detailsToEdit?.payment_sub_item[i]?.latePaymentSelectionStatus == 1 ? true : false,
          InactiveLATE:
            detailsToEdit?.payment_sub_item[i]?.latePaymentSelectionStatus == 2 ? true : false,
          lateFeeTotal: detailsToEdit?.payment_sub_item[i]?.latePaymentFees,
          lateOnce:
            detailsToEdit?.payment_sub_item[i]?.latePaymentSelectionStatus == 1 ? true : false,
          latePaymentSelectionStatus:
            detailsToEdit?.payment_sub_item[i]?.latePaymentSelectionStatus,
          isLatePaymentFeesRecord: detailsToEdit?.payment_sub_item[i]?.isLatePaymentFeesRecord,
          latePaymentSubItemId: detailsToEdit?.payment_sub_item[i]?.latePaymentSubItemId,
          paymentItemListId: detailsToEdit?.payment_sub_item[i]?.paymentItemListId,
          isDeletable: detailsToEdit?.payment_sub_item[i]?.isDeletable,
          isEditable: detailsToEdit?.payment_sub_item[i]?.isEditable,
          nextLatePaymentCycle: detailsToEdit?.payment_sub_item[i]?.nextLatePaymentCycle,
          oldLatePaymentCycleList: detailsToEdit?.payment_sub_item[i]?.oldLatePaymentCycleList,
          paymentSubItemStatus: detailsToEdit?.payment_sub_item[i]?.paymentSubItemStatus,
          isNew: false,
          refundAmount: detailsToEdit?.payment_sub_item[i]?.totalRefundAmount,
          amountPaid: detailsToEdit?.payment_sub_item[i]?.amountPaid,
          serviceRequestId: detailsToEdit?.payment_sub_item[i]?.serviceRequestId,
          serviceJobId: detailsToEdit?.payment_sub_item[i]?.serviceJobId,
          showLink:
            detailsToEdit?.payment_sub_item[i]?.serviceJobId != null ||
            detailsToEdit?.payment_sub_item[i]?.serviceRequestId != null
              ? true
              : false,
          jobId: detailsToEdit?.payment_sub_item[i]?.service_job?.[0]?.id,
          requestId: detailsToEdit?.payment_sub_item[i]?.service_request?.[0]?.id,
        }

        for (let j = 0; j < detailsToEdit?.payment_sub_item?.length; j++) {
          if (
            detailsToEdit?.payment_sub_item[j]._id ==
            detailsToEdit?.payment_sub_item[i]?.latePaymentSubItemId
          ) {
            values[i].lateItemIndex = j
          }
        }
      }

      setFields(values)
      setDiscardFields(values)
    } else {
      setIsEmpty(true)
      if (unitDetails?.buildingId) {
        ApiGet(`corporate/payment_item_list/payment?buildingId=` + unitDetails?.buildingId)
          .then((res: any) => {
            setFilteredPaymentItems(res?.data?.data)
          })
          .catch((err: any) => console.log('err', err))
      } else if (unitDetails?.communityId) {
        ApiGet(`corporate/payment_item_list/payment?communityId=` + unitDetails?.communityId)
          .then((res: any) => {
            setFilteredPaymentItems(res?.data?.data)
          })
          .catch((err: any) => console.log('err', err))
      }
      setFields([])
      setDiscardFields([])
    }
    setIsSelected(false)
    setPaymentName('')
    setAmountPaid(0)
    setDueDate(null)
    setDatePaid(null)
    setPaymentMethod(0)
    setAttachedMedia([])
    setInvoiceAttachedMedia([])
    setUnitsTenancy([])
    // setType('')
    setInvoiceNo('')
    setReceiptNo('')
    setSchedule([
      {
        id: '',
        days: '',
        date: '',
      },
    ])
    setRadioChecked(-1)
    // setclusterunit([])
    // setSelectedUnitData([])
    setIsEditt(false)
    setDisableSelect(true)
    setInvoiceAdded(false)
    setReceiptAdded(false)
    setInvoiceUrl('')

    setPaymentMethod(detailsToEdit?.paymentMethod)
    setInvoicePaymentMethod(detailsToEdit?.paymentMethod)
    setChequeBankName(detailsToEdit?.chequeBankName)
    setInvoiceChequeBankName(detailsToEdit?.chequeBankName)

    getTenancy()
    setShowEdit(false)
    setShowEditMethod(false)
    setShowEditAttach(false)
    setChequeMedia([])
    setAttachedMedia([])
    setSelectedAccount([])
    getBankAccounts()
    setShowEditNote(false)
    setNotes(detailsToEdit?.note)
    if (detailsToEdit?.note?.length > 0) setNotesCount(`${detailsToEdit?.note?.length}/1000`)
    else setNotesCount(`0/1000`)
    setIsItemChanged(false)
    setInvoiceNo(detailsToEdit?.invoiceNo)
    setIsInvChanged(true)
    setInvoiceUrl(detailsToEdit?.invoiceURL)
    setGrandAmount(detailsToEdit?.totalAmount)
    if (detailsToEdit?.dueDate != null)
      setDueDateToShow(moment(detailsToEdit?.dueDate).format('YYYY-MM-DD'))
    else setDueDateToShow(null)

    setShowEditDue(false)

    let today = new Date()
    let x = today.toLocaleDateString('fr-CA')
    setInvoiceDate(x)

    // Payment Method is Cheque
    if (detailsToEdit?.paymentMethod == 1) {
      setChequeMedia([])
      setInvoiceChequeMedia([])
      if (detailsToEdit?.chequeImages?.length > 0) {
        let values: any = []
        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)
        for (let i = 0; i < detailsToEdit?.chequeImages?.length; i++) {
          // console.log(detailsToEdit?.chequeImages[i])
          if (detailsToEdit?.chequeImages[i] != null) {
            let x: any = detailsToEdit?.chequeImages[i]?.split('/')[2]
            let y: any = x.split('.')[0]
            values[i] = {
              src: {
                name: detailsToEdit?.chequeImages[i],
              },
              name: y,
              fileType: 'cheque',
              databaseId: data?._id,
              image: detailsToEdit?.chequeImages[i],
            }
          }
        }
        setChequeMedia(values)
        setInvoiceChequeMedia(values)
      }
    }

    // Payment Method is Bank
    if (detailsToEdit?.paymentMethod == 2) {
      // console.log(detailsToEdit?.payment_account[0])
      setSelectedAccount(detailsToEdit?.payment_account[0])
      setSelectedAccountInvoice(detailsToEdit?.payment_account[0])
      // console.log(selectedAccount)
    }

    // Attachment
    if (detailsToEdit?.attachments?.length > 0) {
      let values: any = []

      for (let i = 0; i < detailsToEdit?.attachments?.length; i++) {
        if (detailsToEdit?.attachments[i] != null) {
          let x: any = detailsToEdit?.attachments[i]?.split('/')[2]
          let y: any = detailsToEdit?.attachments[i]?.split('/')[3]

          let temp: any = y.split('.')[1]
          values[i] = {
            src: detailsToEdit?.attachments[i],
            name: x + '.' + temp,
          }
        }
      }

      setAttachedMedia(values)
    }

    // Invoice Attachment
    if (detailsToEdit?.invoiceAttachments?.length > 0) {
      let values: any = []

      for (let i = 0; i < detailsToEdit?.invoiceAttachments?.length; i++) {
        if (detailsToEdit?.invoiceaAttachments[i] != null) {
          let x: any = detailsToEdit?.invoiceAttachments[i]?.split('/')[2]
          let y: any = detailsToEdit?.invoiceAttachments[i]?.split('/')[3]

          let temp: any = y.split('.')[1]
          values[i] = {
            src: detailsToEdit?.invoiceAttachments[i],
            name: x + '.' + temp,
          }
        }
      }
      setInvoiceAttachedMedia(values)
    }

    //
    if (detailsToEdit?.payment_reminder?.length > 0) {
      let values: any = []

      for (let i = 0; i < detailsToEdit?.payment_reminder?.length; i++) {
        let x: any = new Date(detailsToEdit?.dueDate)
        const start: any = parseISO(detailsToEdit?.dueDate)
        const end: any = parseISO(detailsToEdit?.payment_reminder[i].reminderDate.slice(0, 10))
        const dayDiff: any = differenceInDays(start, end)

        values[i] = {
          id: detailsToEdit?.payment_reminder[i]._id,
          days: dayDiff,
          date: new Date(detailsToEdit?.payment_reminder[i].reminderDate).toLocaleDateString(
            'fr-CA'
          ),
        }
      }

      setSchedule(values)
    } else setSchedule([])

    setUpdatedItem(detailsToEdit?.payment_sub_item)

    //  Payment Invoices
    if (detailsToEdit?.payment_invoice?.length > 0) {
      setPaymentInvoices(detailsToEdit?.payment_invoice)
    } else {
      setPaymentInvoices([])
    }

    //  Payment Debit Notes
    if (detailsToEdit?.payment_debit_note?.length > 0) {
      setPaymentDebitNotes(detailsToEdit?.payment_debit_note)
    } else {
      setPaymentDebitNotes([])
    }

    // Payment Refunds
    if (detailsToEdit?.payment_refund?.length > 0) {
      setPaymentRefunds(detailsToEdit?.payment_refund)
    } else {
      setPaymentRefunds([])
    }

    setPaymentCategory(detailsToEdit?.categories)
    setScheduleStartDate(detailsToEdit?.reminderAfterPayment?.startDate)
    setScheduleEndDate(detailsToEdit?.reminderAfterPayment?.endDate)
    setIsEndDateChecked(detailsToEdit?.reminderAfterPayment?.isEndDateChecked)

    if (detailsToEdit?.reminderAfterPayment?.frequency == 0) setFrequency('Once')
    else if (detailsToEdit?.reminderAfterPayment?.frequency == 1) setFrequency('Daily')
    else if (detailsToEdit?.reminderAfterPayment?.frequency == 2) setFrequency('Weekly')
    else if (detailsToEdit?.reminderAfterPayment?.frequency == 3) setFrequency('Monthly')

    const generatedDates: string[] = []
    const currentDate = new Date(detailsToEdit?.reminderAfterPayment?.startDate)
    const endDateObj = new Date(detailsToEdit?.reminderAfterPayment?.endDate)

    if (detailsToEdit?.reminderAfterPayment?.startDate != null) {
      while (currentDate <= endDateObj) {
        generatedDates.push(currentDate.toDateString())

        if (detailsToEdit?.reminderAfterPayment?.frequency == 1) {
          currentDate.setDate(currentDate.getDate() + 1)
        } else if (detailsToEdit?.reminderAfterPayment?.frequency == 2) {
          currentDate.setDate(currentDate.getDate() + 7)
        } else if (detailsToEdit?.reminderAfterPayment?.frequency == 3) {
          currentDate.setMonth(currentDate.getMonth() + 1)
        } else if (1) {
          currentDate.setDate(endDateObj.getDate() + 1)
        }
      }
    }

    setGeneratedDates(generatedDates)

    setReceiptChequeBankName('')
    setReceiptChequeMedia([])
    setReceiptPaymentMethod(0)
    setSelectedAccountReceipt(null)
    setShowAccReceipt(false)
    setDueDateForPayment(detailsToEdit?.dueDate)
    setUpdDetailsToEdit(detailsToEdit)
    setNewFields([])
    setCheckVerify(detailsToEdit?.isCardVerificationEnable)
  }, [detailsToEdit, tenantId])

  return (
    <div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto no-print'
        show={show}
        onHide={() => {
          handleClose()
          getAllPayments()
          setcollapse(false)
          setcollapseAttach(false)
          setcollapseProp(false)
          setcollapseRec(false)
          setcollapseRemind(false)
          setcollapseInv(false)
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        {/* Modal Header */}
        <div
          className='modal-header text-white ps-5 pe-1 '
          style={{
            zIndex: '1',
            opacity: '0.85',
            borderBottom: '0px',
            minHeight: '95px',
            backgroundColor: '#048c7c',
          }}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white' style={{fontWeight: '700'}}>
                Incoming Payment
              </h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross me-1'
                onClick={() => {
                  handleClose()
                  getAllPayments()
                  setcollapse(false)
                  setcollapseAttach(false)
                  setcollapseProp(false)
                  setcollapseRec(false)
                  setcollapseRemind(false)
                  setcollapseInv(false)
                }}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='header-bg-edit'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '50px'}}>
              <img
                src={editPay}
                height='110px'
                width='120px'
                className='me-3 ms-5'
                style={{transform: 'translate(10px, -16px)'}}
              />
            </Col>
          </Row>
        </div>

        {/* Property/ Development Details */}
        {type == 'financials' && (
          <div className='card' style={{transform: 'translate(0px, -95px)', borderRadius: '0px'}}>
            <Row className='py-4 mx-0 ps-lg-10 pe-lg-5 '>
              <Col md={6} className='px-0'>
                <h3 className='head-text' style={{fontWeight: '700'}}>
                  Property
                </h3>
              </Col>
              <Col md={6} className='text-end px-0 pe-2'>
                <button
                  className='btn btn-sm ms-auto'
                  style={{
                    height: '25px',
                    width: '20px',
                    backgroundColor: '#048c7c',
                    marginTop: '-2px',
                  }}
                >
                  <img
                    src={collapseProp ? upWhite : downWhite}
                    style={{
                      height: '20px',
                      width: '20px',
                      marginLeft: '-10px',
                      marginTop: '-8px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setcollapseProp(!collapseProp)}
                    aria-controls='example-collapse-text'
                    aria-expanded={collapse}
                  />
                </button>
              </Col>

              <Collapse in={collapseProp}>
                <div id='example-collapse-text' className='px-0 mt-3' style={{fontWeight: '600'}}>
                  <div className='row d-flex mb-0 mt-0 px-5'>
                    {developmentName != '' && (
                      <div className='col-md-4 mb-2 font-w-800 blue-label'>
                        <label className='mb-1 me-2 font-w-600'>Development</label>
                        {developmentName}
                      </div>
                    )}
                    {clusterName != '' && (
                      <div className='col-md-4 mb-2  text-center font-w-800 blue-label'>
                        <label className='mb-1 me-2 font-w-600 '>Cluster</label>
                        {clusterName}
                      </div>
                    )}
                    {buildingName != '' && (
                      <div className='col-md-4 mb-2  text-center font-w-800 blue-label'>
                        <label className='mb-1 me-2 font-w-600'>Building Name</label>
                        {buildingName}
                      </div>
                    )}

                    {unitName != '' && (
                      <div className='col-md-4 mb-2 font-w-800 blue-label'>
                        <label className='mb-1 me-2 font-w-600'>Unit</label>
                        {unitName}
                      </div>
                    )}

                    {tenancyContract && (
                      <div className='col-md-4 mb-2 font-w-800 blue-label'>
                        <label className='mb-1 me-2 font-w-600'>Tenancy Contract #</label>
                        {tenancyContract}
                      </div>
                    )}
                  </div>
                </div>
              </Collapse>
            </Row>
          </div>
        )}

        {type == 'propertyProfile' && (
          <Row
            className='py-4 mx-0 ps-lg-10 pe-lg-5'
            style={{backgroundColor: '#00513b', transform: 'translate(0px, -95px)'}}
          >
            <Col md={6} className='px-0'>
              <h4 className='text-white' style={{fontWeight: '700'}}>
                Tenancy
              </h4>
            </Col>
            <Col md={6} className='text-end px-0'>
              <button className='btn btn-sm ms-auto' style={{height: '25px', width: '20px'}}>
                <img
                  src={collapseProp ? upWhite : downWhite}
                  style={{
                    height: '20px',
                    width: '20px',
                    marginLeft: '-10px',
                    marginTop: '-16px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setcollapseProp(!collapseProp)}
                  aria-controls='example-collapse-text'
                  aria-expanded={collapse}
                />
              </button>
            </Col>

            <Collapse in={collapseProp}>
              <div
                id='example-collapse-text'
                className='text-white px-0 mt-3'
                style={{fontWeight: '600'}}
              >
                <div className='row d-flex mb-0 mt-0 px-5'>
                  {tenancyContract && (
                    <div className='col-md-4 mb-2 font-w-800'>
                      <label className='mb-1 me-2 font-w-600'>Tenancy Contract #</label>
                      {tenancyContract}
                    </div>
                  )}
                </div>
              </div>
            </Collapse>
          </Row>
        )}

        {/* Modal Body */}
        <div
          className='modal-body pt-0 pe-lg-3 pb-0 ps-3'
          style={{marginBottom: '-93px', maxHeight: '600px'}}
        >
          <Row className='' style={{transform: 'translate(0px, -95px)'}}>
            {isEmpty == false ? (
              <Col
                lg={7}
                md={7}
                className='align-items-center pt-5  ps-lg-10 '
                style={{maxHeight: '600px', overflow: 'scroll'}}
              >
                {showEdit == false ? (
                  <Row className='me-5'>
                    <Col md={6} className='ms-auto text-end' style={{marginRight: '-20px'}}>
                      {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                        ((user?.isSubUser && role?.leasing_residential?.lease_financial?.update) ||
                          !user?.isSubUser)) ||
                        type == 'propertyProfile' ||
                        type == 'tenant' ||
                        type == 'financials') && (
                        <img
                          src={editpen}
                          alt=''
                          height='20'
                          width='20'
                          className='mb-3 cursor-pointer'
                          onClick={() => {
                            if (detailsToEdit?.isLock)
                              ErrorToast(
                                'This payment is linked with a tenancy termination request. Once the termination has been approved you will be able to edit this payment.'
                              )
                            else {
                              setUpdatedItem(detailsToEdit?.payment_sub_item)
                              setShowEdit(true)
                              setDiscardChanges(true)
                              changeFields(fields)
                            }
                          }}
                        />
                      )}
                    </Col>
                  </Row>
                ) : (
                  <>
                    <div className='d-flex justify-content-center'>
                      <button
                        className='btn btn-sm fw-bold ps-2 text-white d-flex justify-content-center align-items-center me-5'
                        style={{
                          fontSize: '1.2rem',
                          paddingRight: '55px',
                          borderRadius: '5px',
                          backgroundColor: '#6e8aa6',
                          boxShadow: '0px 0px 20px 0px #6e8aa6',
                          width: '180px',
                          height: '34px',
                          // transform: 'translate(15px, 0px)',
                        }}
                        onClick={() => {
                          for (let i = 0; i < fields?.length; i++) {
                            if (fields[i]?.removeHistoryIDs?.length > 0) {
                              const body = {
                                latePaymentItemHistoryIds: fields[i]?.removeHistoryIDs,
                              }
                              ApiPost(`corporate/late_payment_item_history/delete`, body)
                                .then((res) => {})
                                .catch(() => {})
                            }
                          }
                          updatePaymentRecord(1)

                          // if (fields?.length > 0) {
                          //   if (isUpdatedPaymentInvoice) {
                          //     Swal.fire({
                          //       html: '<p class="text-center m-0" style="font-size: 16px"> You need to review the Invoice, and click GENERATE again to confirm the changes!! </p><p class="text-center m-0" style="font-size: 16px">Would you like to continue ?</p>',
                          //       showConfirmButton: true,
                          //       confirmButtonColor: 'rgb(4, 140, 124)',
                          //       confirmButtonText: 'Yes',
                          //       showCancelButton: true,
                          //       cancelButtonText: 'Cancel',
                          //     }).then((res) => {
                          //       if (res.isConfirmed) {
                          //         setIsNewInvoice(true)
                          //         let today = new Date()
                          //         let x = today.toLocaleDateString('fr-CA')
                          //         setInvoiceDate(x)
                          //         if (isUpdatedPaymentInvoice) setInvoiceModal(true)
                          //         if (isNewPaymentInvoice) setNewInvoiceModal(true)
                          //       }
                          //       if (res.isDismissed) {
                          //       }
                          //     })
                          //   } else if (isNewPaymentInvoice) {
                          //     Swal.fire({
                          //       html: '<p class="text-center m-0" style="font-size: 16px"> You need to review the Invoice, and click GENERATE again to confirm the changes!! </p><p class="text-center m-0" style="font-size: 16px">Would you like to continue ?</p>',
                          //       showConfirmButton: true,
                          //       confirmButtonColor: 'rgb(4, 140, 124)',
                          //       confirmButtonText: 'Yes',
                          //       showCancelButton: true,
                          //       cancelButtonText: 'Cancel',
                          //     }).then((res) => {
                          //       if (res.isConfirmed) {
                          //         setIsNewInvoice(true)
                          //         let today = new Date()
                          //         let x = today.toLocaleDateString('fr-CA')
                          //         setInvoiceDate(x)
                          //         if (isUpdatedPaymentInvoice) setInvoiceModal(true)
                          //         if (isNewPaymentInvoice) setNewInvoiceModal(true)
                          //       }
                          //       if (res.isDismissed) {
                          //       }
                          //     })
                          //   } else {
                          //     updatePaymentRecord(1)
                          //   }
                          // } else {
                          //   updatePaymentRecord(1)
                          // }
                        }}
                      >
                        <img
                          src={saveImg}
                          height='20'
                          width='20'
                          style={{
                            marginRight: '21px',
                            marginLeft: '12px',
                          }}
                        />{' '}
                        {'  '}
                        <p className='mb-0'>Save</p>
                      </button>

                      <button
                        className='btn btn-sm fw-bold ms-5 ps-2 text-white d-flex justify-content-center align-items-center'
                        style={{
                          fontSize: '1.2rem',
                          paddingRight: '55px',
                          borderRadius: '5px',
                          backgroundColor: '#D54645',
                          boxShadow: '0px 0px 20px 0px #6e8aa6',
                          width: '180px',
                          height: '34px',
                          // transform: 'translate(15px, 0px)',
                        }}
                        onClick={() => {
                          refetchPaymentItems()
                          // setShowEdit(false)
                        }}
                      >
                        <img
                          src={crossbtn}
                          height='20'
                          width='20'
                          style={{
                            marginRight: '21px',
                            marginLeft: '12px',
                          }}
                        />{' '}
                        {'  '}
                        <p className='mb-0'>Cancel</p>
                      </button>
                    </div>
                  </>
                )}

                {showEdit == false ? (
                  <>
                    {fields?.length > 0 &&
                      fields.map((field: any, index: number) => {
                        let total: any = 0
                        if (field?.isLatePaymentFeesRecord == false)
                          total =
                            field?.amount * field?.qty +
                            ((field?.amount * field?.qty) / 100) * field?.vat +
                            field?.lateFeeTotal
                        else total = field?.total
                        return (
                          <>
                            <div
                              style={
                                index > 0
                                  ? {borderTop: '0px', paddingTop: '10px'}
                                  : {borderTop: '0px'}
                              }
                              className='mb-5'
                            >
                              {field?.paymentSubItemStatus == 0 ? (
                                <div className='d-flex align-items-center justify-content-center mt-3 mb-0'>
                                  <h3 className='head-text' style={{fontWeight: '700'}}></h3>
                                  <div className='d-flex justify-content-center align-items-center ms-auto me-5 ms-5'>
                                    <h6 className='mb-0  text-danger' style={{fontWeight: '800'}}>
                                      Missing Invoice
                                    </h6>
                                  </div>
                                </div>
                              ) : field?.paymentSubItemStatus == 1 ? (
                                <div className='d-flex align-items-center justify-content-center mt-3 mb-0'>
                                  <h3 className='head-text' style={{fontWeight: '700'}}></h3>
                                  <div className='d-flex justify-content-center align-items-center ms-auto me-5 ms-5'>
                                    <h6 className='mb-0 text-warning' style={{fontWeight: '800'}}>
                                      Draft Invoice
                                    </h6>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                              {/* Payment Item Detail */}
                              <div className='d-flex align-items-center justify-content-center mt-1 mb-2'>
                                <h3 className='head-text me-auto' style={{fontWeight: '700'}}>
                                  Item Details
                                </h3>
                                {field?.paymentSubItemStatus == 0 && (
                                  <div className='d-flex align-items-center justify-content-center mt-4 mb-2 me-3 '>
                                    <h3 className='head-text' style={{fontWeight: '700'}}></h3>
                                    <div className='d-flex justify-content-center align-items-center ms-auto '>
                                      <button
                                        className='btn btn-sm fw-bold ms-3 px-0 text-white d-flex justify-content-center align-items-center hollow-btn'
                                        style={{
                                          width: '120px',
                                          height: '28px',
                                          transform: 'translate(0px, -2px)',
                                        }}
                                        onClick={() => {
                                          setInvoiceLinkChecked(-1)
                                          const values = [...invoiceFields]

                                          values[0] = {
                                            _id: field?._id,
                                            id: field?.id,
                                            name: field?.item,
                                            amount: field?.amount,
                                            qty: field?.qty,
                                            vat: field?.vat,
                                            isLateItem: field?.isLatePaymentFeesRecord,
                                            total: field?.total,
                                            amountPaid: field?.amountPaid,
                                            vatAmount: field?.isLatePaymentFeesRecord
                                              ? (field?.amount / 100) * field?.vat
                                              : ((field?.amount * field?.qty) / 100) * field?.vat,
                                            totalWithoutVat: field?.isLatePaymentFeesRecord
                                              ? field?.amount
                                              : field?.amount * field?.qty,
                                            finalAmount: 0,
                                            isSelected: 1,
                                            data: field,
                                          }

                                          values[0].finalAmount =
                                            values[0].totalWithoutVat + values[0]?.vatAmount

                                          setInvoiceFields(values)
                                          getAllInvoicesForMissing()
                                          setItemToAdd(field)
                                        }}
                                      >
                                        Link to Invoice
                                        {'  '}
                                      </button>
                                    </div>
                                  </div>
                                )}
                                <div className='d-flex justify-content-center align-items-center  me-5 '>
                                  <h6 className='mb-0 head-text' style={{fontWeight: '800'}}>
                                    {`${detailsToEdit?.id}_${field?.id}`}
                                  </h6>
                                </div>
                              </div>

                              <div className='align-items-center d-flex mb-4 ps-3'>
                                <div className='d-flex align-items-center w-50'>
                                  <label
                                    className='mb-1 me-5 pe-5 d-flex grey-label'
                                    style={{minWidth: '90px'}}
                                  >
                                    Item
                                  </label>
                                  <div style={{width: '-webkit-fill-available'}}>
                                    <p style={{fontWeight: '700'}} className='mb-1'>
                                      {field?.item}
                                    </p>
                                  </div>
                                </div>

                                {field?.item == 'Maintenance' && (
                                  <div className='d-flex align-items-center ps-3 w-50 me-3'>
                                    <p style={{fontWeight: '700'}} className='mb-1  ms-auto '>
                                      {field?.jobId}
                                      {'_'}
                                      {field?.requestId}
                                    </p>
                                  </div>
                                )}
                              </div>

                              <div className='align-items-center d-flex mb-4 ps-3'>
                                <label
                                  className='mb-1 me-5 pe-5 d-flex grey-label'
                                  style={{minWidth: '90px'}}
                                >
                                  Amount
                                </label>
                                <div style={{width: '-webkit-fill-available'}}>
                                  <p style={{fontWeight: '700'}} className='mb-1'>
                                    {field?.isLatePaymentFeesRecord == false
                                      ? (field?.amount).toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })
                                      : (field?.amount / field?.qty).toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                  </p>
                                </div>
                              </div>

                              <div className='d-flex align-items-center justify-content-center mb-4 ps-3'>
                                <div className='d-flex align-items-center w-50'>
                                  <label
                                    className='mb-1 me-5 pe-5 grey-label'
                                    style={{minWidth: '90px'}}
                                  >
                                    QTY
                                  </label>
                                  <p style={{fontWeight: '700'}} className='mb-1'>
                                    {field?.qty}
                                  </p>
                                </div>

                                <div className='d-flex align-items-center ps-3 ms-auto me-5 pe-5 w-50'>
                                  <label
                                    className='mb-1 me-5 ps-5 grey-label'
                                    style={{minWidth: '90px'}}
                                  >
                                    VAT
                                  </label>
                                  <p style={{fontWeight: '700'}} className='mb-1'>
                                    {field?.vat}%
                                  </p>
                                </div>
                              </div>

                              <div
                                className='d-flex align-items-center mb-4 ps-3 '
                                style={{color: '#384a62', fontWeight: '600'}}
                              >
                                <label
                                  className='mb-1 me-5 pe-5 grey-label'
                                  style={{minWidth: '90px'}}
                                >
                                  Total
                                </label>
                                <p style={{fontWeight: '700'}} className='fs-5 mb-1'>
                                  {' '}
                                  {total?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                                </p>
                              </div>

                              {field?.isLatePaymentFeesRecord == false && (
                                <div
                                  className='d-flex align-items-center mb-4 ps-3 mt-2'
                                  style={{color: '#384a62'}}
                                >
                                  <label className='mb-1 me-5 grey-label pe-5'>
                                    Late Payment Fees{' '}
                                  </label>
                                  <p style={{fontWeight: '700'}} className='mb-1'>
                                    {field?.latePaymentSelectionStatus == 0
                                      ? 'Not Selected'
                                      : field?.latePaymentSelectionStatus == 1
                                      ? field?.lateFeeTotal?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })
                                      : 'Not Applied'}
                                  </p>
                                </div>
                              )}

                              <div className='d-flex align-items-center justify-content-center mb-4 ps-3'>
                                <div className='d-flex align-items-center w-50'>
                                  <label
                                    className='mb-1 me-5 pe-5 grey-label'
                                    style={{minWidth: '90px'}}
                                  >
                                    Amount Paid
                                  </label>
                                  <p style={{fontWeight: '700'}} className='mb-1'>
                                    {field?.amountPaid?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}
                                  </p>
                                </div>

                                <div className='d-flex align-items-center  ps-3 ms-auto me-5 pe-5 w-50'>
                                  <label
                                    className='mb-1 me-5 ps-5 grey-label'
                                    style={{minWidth: '90px'}}
                                  >
                                    Refund
                                  </label>
                                  <p style={{fontWeight: '700'}} className='mb-1'>
                                    {field?.refundAmount?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                  </>
                ) : (
                  <>
                    <>
                      {fields?.length > 0 &&
                        fields.map((field: any, index: number) => {
                          return (
                            <>
                              {field?.isNew ? (
                                <>
                                  {field?.isLatePaymentFeesRecord == false ? (
                                    <>
                                      <div
                                        style={
                                          index > 0
                                            ? {borderTop: '1px solid lightgray', paddingTop: '10px'}
                                            : {borderTop: '0px'}
                                        }
                                        className='mb-5'
                                      >
                                        {field?.paymentSubItemStatus == 0 ? (
                                          <div className='d-flex align-items-center justify-content-center mt-1 mb-2'>
                                            <h3
                                              className='head-text'
                                              style={{fontWeight: '700'}}
                                            ></h3>
                                            <div className='d-flex justify-content-center align-items-center ms-auto me-2'>
                                              <h6
                                                className='mb-0  text-danger'
                                                style={{fontWeight: '800'}}
                                              >
                                                Missing Invoice
                                              </h6>
                                            </div>
                                          </div>
                                        ) : field?.paymentSubItemStatus == 1 ? (
                                          <div className='d-flex align-items-center justify-content-center mt-1 mb-2'>
                                            <h3
                                              className='head-text'
                                              style={{fontWeight: '700'}}
                                            ></h3>
                                            <div className='d-flex justify-content-center align-items-center ms-auto me-2'>
                                              <h6
                                                className='mb-0 text-warning'
                                                style={{fontWeight: '800'}}
                                              >
                                                Draft Invoice
                                              </h6>
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}

                                        <div className='d-flex align-items-center justify-content-center mt-3 mb-2'>
                                          <h3 className='head-text' style={{fontWeight: '700'}}>
                                            Item Details
                                          </h3>
                                          <div className='d-flex justify-content-center align-items-center ms-auto'>
                                            <h6
                                              className='mb-0 head-text me-3'
                                              style={{fontWeight: '800'}}
                                            >
                                              {/* {detailsToEdit?.id} {'_'}
                                        {field?.id} */}
                                            </h6>
                                            {field?.isDeletable && (
                                              <img
                                                src={bin}
                                                alt=''
                                                height='18'
                                                width='18'
                                                className='ms-3 me-1 cursor-pointer'
                                                onClick={() =>
                                                  Swal.fire({
                                                    html: '<p class="text-center m-0" style="font-size: 16px"> Deleting an Item will also delete the Late Payment Item associated with it, AND the item will be removed from the invoice as well, </p><p class="text-center m- mt-1" style="font-size: 16px">Do you still wish to proceed ? </p>',
                                                    showConfirmButton: true,
                                                    confirmButtonColor: '#007a59',
                                                    confirmButtonText: 'Confirm',
                                                    showCancelButton: true,
                                                    cancelButtonText: 'Cancel',
                                                  }).then((res) => {
                                                    if (res.isConfirmed) {
                                                      handleRemoveFieldsOfEdit(index)
                                                      // deleteSubItem(field?._id)
                                                    }
                                                  })
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <p></p>

                                        <div className='align-items-center d-flex mb-4 ps-3'>
                                          <label
                                            className='mb-1 me-5 pe-5 d-flex grey-label'
                                            style={{minWidth: '90px'}}
                                          >
                                            Item
                                          </label>
                                          <div
                                            style={{width: '-webkit-fill-available'}}
                                            className='d-flex'
                                          >
                                            {paymentItems &&
                                              paymentItems.map((pI: any, index: any) => {
                                                if (field.item == pI.name) itemInd = index
                                                return <></>
                                              })}

                                            {field?.item?.length > 0 ? (
                                              <Select
                                                options={
                                                  filteredPaymentItems &&
                                                  filteredPaymentItems?.map(
                                                    (pItem: any, index: any) => {
                                                      return {
                                                        label: `${pItem?.name}`,
                                                        value: `${pItem?._id}`,
                                                        id: pItem,
                                                      }
                                                    }
                                                  )
                                                }
                                                defaultValue={{
                                                  label: filteredPaymentItems[itemInd]?.name,
                                                  value: filteredPaymentItems[itemInd]?._id,
                                                  id: filteredPaymentItems[itemInd],
                                                }}
                                                styles={customStylesInputFilledForNewItems}
                                                isSearchable={false}
                                                onChange={(event: any) => {
                                                  const values = [...fields]

                                                  // console.log(event)
                                                  if (event.label == 'Maintenance') {
                                                    values[index].showLink = true
                                                    setIndForMaintenance(index)
                                                  } else {
                                                    values[index].serviceRequestId = null
                                                    values[index].requestId = null
                                                    values[index].serviceJobId = null
                                                    values[index].jobId = null
                                                  }

                                                  values[index].item = event.label
                                                  values[index].lateDetails = event.id

                                                  updateAmount(values, values[index], index)

                                                  setFields(values)
                                                }}
                                              />
                                            ) : (
                                              <Select
                                                options={
                                                  filteredPaymentItems &&
                                                  filteredPaymentItems?.map(
                                                    (pItem: any, index: any) => {
                                                      return {
                                                        label: `${pItem?.name}`,
                                                        value: `${pItem?._id}`,
                                                        id: pItem,
                                                      }
                                                    }
                                                  )
                                                }
                                                styles={customStyles}
                                                isSearchable={false}
                                                defaultValue={field.item}
                                                onChange={(event) => {
                                                  const values = [...fields]

                                                  // console.log(event)
                                                  if (event.label == 'Maintenance') {
                                                    values[index].showLink = true
                                                    setIndForMaintenance(index)
                                                  } else {
                                                    values[index].serviceRequestId = null
                                                    values[index].requestId = null
                                                    values[index].serviceJobId = null
                                                    values[index].jobId = null
                                                  }

                                                  values[index].item = event.label
                                                  values[index].lateDetails = event.id
                                                  // if (values[index].activeLATE == true) {
                                                  //   if (new Date(dueDate) < new Date())
                                                  //   updateAmount(values, values[index], index)
                                                  // }

                                                  updateAmount(values, values[index], index)

                                                  // if (invoiceAdded) setYellowInvoice(true)

                                                  setFields(values)
                                                }}
                                              />
                                            )}

                                            {field?.showLink == true &&
                                              field?.item == 'Maintenance' && (
                                                <>
                                                  {field?.serviceJobId != null ||
                                                  field?.serviceRequestId != null ? (
                                                    <button
                                                      className='btn btn-sm fw-bold ms-auto ps-2 text-white me-0 py-0'
                                                      style={{
                                                        fontSize: '0.7rem',
                                                        paddingRight: '15px',
                                                        borderRadius: '2px',
                                                        backgroundColor: '#35475e',
                                                        boxShadow: '0px 0px 20px 0px #516176',
                                                      }}
                                                      onClick={(event) => {
                                                        event.preventDefault()
                                                        setIndForMaintenance(index)
                                                        setTimeout(() => {
                                                          getServiceJobForPayment()
                                                        }, 1000)
                                                      }}
                                                    >
                                                      <img
                                                        src={linkImg}
                                                        style={{
                                                          height: '16px',
                                                          width: '16px',
                                                          marginRight: '5px',
                                                          marginLeft: '5px',
                                                          marginTop: '-3px',
                                                        }}
                                                      />
                                                      {field?.jobId}
                                                      {'_'}
                                                      {field?.requestId}
                                                    </button>
                                                  ) : (
                                                    <button
                                                      className='btn btn-sm fw-bold ms-auto ps-2 text-white me-0 '
                                                      style={{
                                                        fontSize: '1rem',
                                                        paddingRight: '15px',
                                                        borderRadius: '2px',
                                                        backgroundColor: '#35475e',
                                                        boxShadow: '0px 0px 20px 0px #516176',
                                                      }}
                                                      onClick={(event) => {
                                                        event.preventDefault()
                                                        setIndForMaintenance(index)
                                                        setTimeout(() => {
                                                          getServiceJobForPayment()
                                                        }, 1000)
                                                      }}
                                                    >
                                                      <img
                                                        src={linkImg}
                                                        style={{
                                                          height: '20px',
                                                          width: '20px',
                                                          marginRight: '12px',
                                                          marginLeft: '4px',
                                                          marginTop: '-3px',
                                                        }}
                                                      />{' '}
                                                      {'  '}
                                                      Link to Request
                                                    </button>
                                                  )}
                                                </>
                                              )}
                                          </div>
                                        </div>

                                        <div className='d-flex align-items-center mb-4 ps-3'>
                                          <label
                                            className='mb-1 me-5 pe-5 grey-label'
                                            style={{minWidth: '90px'}}
                                          >
                                            Amount
                                          </label>

                                          <input
                                            type='number'
                                            className='form-control form-control-solid-bg mytest input-values-style'
                                            placeholder='Enter Amount'
                                            name='item_id'
                                            value={field.amount}
                                            onChange={(event) => {
                                              const values = [...fields]
                                              values[index].amount = event.target.value

                                              let y: any =
                                                field.amount * field.qty +
                                                ((field.amount * field.qty) / 100) * field.vat
                                              values[index].total = y

                                              updateAmount(values, values[index], index)
                                              setFields(values)
                                            }}
                                            style={{
                                              width: '38.8%',
                                            }}

                                            // onMouseLeave={() => updateAmount(fields, field, index)}
                                          />
                                        </div>

                                        <div className='d-flex align-items-center justify-content-center mb-4 ps-3'>
                                          <div className='d-flex align-items-center justify-content-center'>
                                            <label
                                              className='mb-1 me-5 pe-5 grey-label'
                                              style={{minWidth: '90px'}}
                                            >
                                              QTY
                                            </label>

                                            <input
                                              type='number'
                                              className='form-control form-control-solid-bg mytest input-values-style'
                                              placeholder='Enter Qty'
                                              name='item_id'
                                              value={field.qty}
                                              onChange={(event) => {
                                                const values = [...fields]
                                                values[index].qty = event.target.value

                                                let y: any =
                                                  field.amount * field.qty +
                                                  ((field.amount * field.qty) / 100) * field.vat
                                                values[index].total = y

                                                updateAmount(values, values[index], index)
                                                setFields(values)
                                              }}
                                              // onMouseLeave={() => updateAmount(fields, field, index)}
                                            />
                                          </div>

                                          <div className='d-flex align-items-center justify-content-center ps-3 pe-2'>
                                            <label
                                              className='mb-1 me-5 ps-5 grey-label'
                                              style={{minWidth: '60px'}}
                                            >
                                              VAT %
                                            </label>

                                            <input
                                              type='number'
                                              className='form-control form-control-solid-bg mytest input-values-style'
                                              placeholder='VAT %'
                                              name='item_id'
                                              value={field.vat}
                                              onChange={(event) => {
                                                const values = [...fields]
                                                values[index].vat = event.target.value

                                                let y: any =
                                                  field.amount * field.qty +
                                                  ((field.amount * field.qty) / 100) * field.vat
                                                values[index].total = y
                                                updateAmount(values, values[index], index)
                                                setFields(values)
                                              }}
                                              // onMouseLeave={() => updateAmount(fields, field, index)}
                                            />
                                          </div>
                                        </div>

                                        <div
                                          className='d-flex align-items-center mb-4 ps-3 '
                                          style={{color: '#384a62', fontWeight: '600'}}
                                        >
                                          <label
                                            className='mb-1 me-5 pe-5 grey-label'
                                            style={{minWidth: '90px'}}
                                          >
                                            Total
                                          </label>
                                          {(
                                            field.amount * field.qty +
                                            ((field.amount * field.qty) / 100) * field.vat +
                                            field?.lateFeeTotal
                                          )?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })}
                                        </div>

                                        <div
                                          className='d-flex align-items-center mb-4 ps-3 mt-2'
                                          style={{color: '#384a62'}}
                                        >
                                          <label className='mb-1 me-5 grey-label pe-5'>
                                            Late Payment Fees
                                          </label>

                                          {/* Late Details */}
                                          {field?.lateDetails?.latePaymentIsActive === true ? (
                                            <>
                                              {field.lateApplied == true && (
                                                <span className='me-5 head-text'>
                                                  {field?.lateFeeTotal?.toFixed(2)}
                                                </span>
                                              )}
                                              <div className='d-flex head-text'>
                                                <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                                                  {field?.activeLATE === true ? (
                                                    <div
                                                      style={{
                                                        height: '18px',
                                                        width: '18px',
                                                        backgroundColor: '#384a62',
                                                        borderRadius: '50%',
                                                      }}
                                                    ></div>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        height: '18px',
                                                        width: '18px',
                                                        backgroundColor: 'transparent',
                                                        borderRadius: '50%',
                                                        border: '1px solid #384a62',
                                                      }}
                                                      onClick={() => {
                                                        if (
                                                          detailsToEdit?.dueDate === null ||
                                                          detailsToEdit?.dueDate === ''
                                                        )
                                                          ErrorToast(
                                                            'Please first select the Due Date!!'
                                                          )
                                                        else {
                                                          // getLatePaymentItem(field, index)

                                                          const values = [...fields]
                                                          values[index].lateApplied = true
                                                          values[index].activeLATE = true
                                                          values[index].InactiveLATE = false

                                                          if (values[index].lateOnce === false) {
                                                            values[index].lateOnce = true
                                                          }

                                                          changeFields(values)
                                                          setFields(values)
                                                        }
                                                      }}
                                                    ></div>
                                                  )}

                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='requestUrgency'
                                                    style={{marginRight: '25px'}}
                                                  >
                                                    Apply
                                                  </label>
                                                </div>
                                                <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                                  {field?.InactiveLATE == true ? (
                                                    <div
                                                      style={{
                                                        height: '18px',
                                                        width: '18px',
                                                        backgroundColor: '#384a62',
                                                        borderRadius: '50%',
                                                      }}
                                                    ></div>
                                                  ) : (
                                                    <div
                                                      style={{
                                                        height: '18px',
                                                        width: '18px',
                                                        backgroundColor: 'transparent',
                                                        borderRadius: '50%',
                                                        border: '1px solid #384a62',
                                                      }}
                                                      onClick={() => {
                                                        // setInActiveLATE(true)
                                                        // setActiveLATE(false)

                                                        if (
                                                          field?.isLatePaymentFeesRecord == false
                                                        ) {
                                                          // if (field?.latePaymentSubItemId != null) {
                                                          const values = [...fields]

                                                          values[index].lateApplied = false
                                                          values[index].activeLATE = false
                                                          values[index].InactiveLATE = true
                                                          changeFields(values)

                                                          if (values[index].lateItemIndex != '')
                                                            // values.splice(
                                                            //   values[index]?.lateItemIndex,
                                                            //   1
                                                            // )
                                                            setFields(values)
                                                        }
                                                      }}
                                                    ></div>
                                                  )}
                                                  <label
                                                    className='form-check-label'
                                                    htmlFor='Internally'
                                                    style={{marginRight: '6px'}}
                                                  >
                                                    Do not Apply
                                                  </label>
                                                </div>
                                              </div>
                                            </>
                                          ) : field?.lateDetails?.latePaymentIsActive === false ? (
                                            <p className='head-text mb-0'>
                                              <i>Inactive</i>
                                            </p>
                                          ) : (
                                            ''
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        style={
                                          index > 0
                                            ? {borderTop: '0px', paddingTop: '10px'}
                                            : {borderTop: '0px'}
                                        }
                                        className='mb-5'
                                      >
                                        {field?.paymentSubItemStatus == 0 ? (
                                          <div className='d-flex align-items-center justify-content-center mt-1 mb-2'>
                                            <h3
                                              className='head-text'
                                              style={{fontWeight: '700'}}
                                            ></h3>
                                            <div className='d-flex justify-content-center align-items-center ms-auto me-5 ms-5'>
                                              <h6
                                                className='mb-0  text-danger'
                                                style={{fontWeight: '800'}}
                                              >
                                                Missing Invoice
                                              </h6>
                                            </div>
                                          </div>
                                        ) : field?.paymentSubItemStatus == 1 ? (
                                          <div className='d-flex align-items-center justify-content-center mt-1 mb-2'>
                                            <h3
                                              className='head-text'
                                              style={{fontWeight: '700'}}
                                            ></h3>
                                            <div className='d-flex justify-content-center align-items-center ms-auto me-5 ms-5'>
                                              <h6
                                                className='mb-0 text-warning'
                                                style={{fontWeight: '800'}}
                                              >
                                                Draft Invoice
                                              </h6>
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {/* Payment Item Detail */}
                                        <div className='d-flex align-items-center justify-content-center mt-3 mb-2'>
                                          <h3 className='head-text' style={{fontWeight: '700'}}>
                                            Item Details
                                          </h3>
                                          <div className='d-flex justify-content-center align-items-center ms-auto '>
                                            <h6
                                              className='mb-0 head-text me-3'
                                              style={{fontWeight: '800'}}
                                            >
                                              {/* {field?.id != undefined
                                          ? `${detailsToEdit?.id}_${field?.id}`
                                          : ''} */}
                                            </h6>
                                          </div>
                                        </div>

                                        <div className='align-items-center d-flex mb-4 ps-3'>
                                          <label
                                            className='mb-1 me-5 pe-5 d-flex grey-label'
                                            style={{minWidth: '90px'}}
                                          >
                                            Item
                                          </label>
                                          <div style={{width: '-webkit-fill-available'}}>
                                            <p style={{fontWeight: '700'}} className='mb-1'>
                                              {field?.item}
                                            </p>
                                          </div>
                                        </div>

                                        <div className='align-items-center d-flex mb-4 ps-3'>
                                          <label
                                            className='mb-1 me-5 pe-5 d-flex grey-label'
                                            style={{minWidth: '90px'}}
                                          >
                                            Amount
                                          </label>
                                          <div style={{width: '-webkit-fill-available'}}>
                                            <p style={{fontWeight: '700'}} className='mb-1'>
                                              {field?.isLatePaymentFeesRecord == false
                                                ? field?.amount?.toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                  })
                                                : (field?.amount / field?.qty)?.toLocaleString(
                                                    'en-US',
                                                    {
                                                      minimumFractionDigits: 2,
                                                    }
                                                  )}
                                            </p>
                                          </div>
                                        </div>

                                        <div className='d-flex align-items-center justify-content-center mb-4 ps-3'>
                                          <div className='d-flex align-items-center justify-content-center'>
                                            <label
                                              className='mb-1 me-5 pe-5 grey-label'
                                              style={{minWidth: '90px'}}
                                            >
                                              QTY
                                            </label>
                                            <p style={{fontWeight: '700'}} className='mb-1'>
                                              {field?.qty}
                                            </p>
                                          </div>

                                          <div className='d-flex align-items-center justify-content-center ps-3 ms-auto me-5 pe-5'>
                                            <label
                                              className='mb-1 me-5 ps-5 grey-label'
                                              style={{minWidth: '90px'}}
                                            >
                                              VAT
                                            </label>
                                            <p style={{fontWeight: '700'}} className='mb-1'>
                                              {field?.vat}%
                                            </p>
                                          </div>
                                        </div>

                                        <div className='d-flex align-items-center justify-content-center mb-4 ps-3'>
                                          <div className='d-flex align-items-center justify-content-center'>
                                            <label
                                              className='mb-1 me-5 pe-5 grey-label'
                                              style={{minWidth: '90px'}}
                                            >
                                              Total
                                            </label>
                                            <p style={{fontWeight: '700'}} className='mb-1'>
                                              {field?.total?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </p>
                                          </div>

                                          <div className='d-flex align-items-center  ps-3 ms-auto me-5 pe-1'>
                                            <button
                                              className='btn btn-sm fw-bold ms-3 ps-2 text-white d-flex justify-content-center align-items-center'
                                              style={{
                                                fontSize: '1.2rem',
                                                // paddingRight: '55px',
                                                borderRadius: '5px',
                                                backgroundColor: '#35475e',
                                                boxShadow: '0px 0px 20px 0px #35475e',
                                                width: '140px',
                                                height: '34px',
                                                transform: 'translate(15px, 0px)',
                                              }}
                                              onClick={() => {
                                                setLateID(field?._id)
                                                getLatePaymentHistory(field)
                                              }}
                                            >
                                              {'  '}
                                              <p className='mb-0'>View All</p>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  {field?.isLatePaymentFeesRecord == false ? (
                                    <>
                                      <div
                                        style={
                                          index > 0
                                            ? {borderTop: '1px solid lightgray', paddingTop: '10px'}
                                            : {borderTop: '0px'}
                                        }
                                        className='mb-5'
                                      >
                                        {field?.paymentSubItemStatus == 0 ? (
                                          <div className='d-flex align-items-center justify-content-center mt-4 mb-2'>
                                            <h3
                                              className='head-text'
                                              style={{fontWeight: '700'}}
                                            ></h3>
                                            <div className='d-flex justify-content-center align-items-center ms-auto me-2'>
                                              <h6
                                                className='mb-0  text-danger'
                                                style={{fontWeight: '800'}}
                                              >
                                                Missing Invoice
                                              </h6>
                                            </div>
                                          </div>
                                        ) : field?.paymentSubItemStatus == 1 ? (
                                          <div className='d-flex align-items-center justify-content-center mt-4 mb-2'>
                                            <h3
                                              className='head-text'
                                              style={{fontWeight: '700'}}
                                            ></h3>
                                            <div className='d-flex justify-content-center align-items-center ms-auto me-2'>
                                              <h6
                                                className='mb-0 text-warning'
                                                style={{fontWeight: '800'}}
                                              >
                                                Draft Invoice
                                              </h6>
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}

                                        <div className='d-flex align-items-center justify-content-center mt-3 mb-2'>
                                          <h3 className='head-text' style={{fontWeight: '700'}}>
                                            Item Details
                                          </h3>
                                          <div className='d-flex justify-content-center align-items-center ms-auto'>
                                            {field?.paymentSubItemStatus == 0 && (
                                              <div className='d-flex align-items-center justify-content-center mt-4 mb-2 me-3'>
                                                <h3
                                                  className='head-text'
                                                  style={{fontWeight: '700'}}
                                                ></h3>
                                                <div className='d-flex justify-content-center align-items-center ms-auto '>
                                                  <button
                                                    className='btn btn-sm fw-bold ms-3 px-0 text-white d-flex justify-content-center align-items-center hollow-btn'
                                                    style={{
                                                      boxShadow: '0px 0px 20px 0px #35475e',
                                                      width: '120px',
                                                      height: '28px',
                                                      transform: 'translate(0px, -2px)',
                                                    }}
                                                    onClick={() => {
                                                      setInvoiceLinkChecked(-1)
                                                      const values = [...invoiceFields]

                                                      values[0] = {
                                                        _id: field?._id,
                                                        id: field?.id,
                                                        name: field?.item,
                                                        amount: field?.amount,
                                                        qty: field?.qty,
                                                        vat: field?.vat,
                                                        isLateItem: field?.isLatePaymentFeesRecord,
                                                        total: field?.total,
                                                        amountPaid: field?.amountPaid,
                                                        vatAmount: field?.isLatePaymentFeesRecord
                                                          ? (field?.amount / 100) * field?.vat
                                                          : ((field?.amount * field?.qty) / 100) *
                                                            field?.vat,
                                                        totalWithoutVat:
                                                          field?.isLatePaymentFeesRecord
                                                            ? field?.amount
                                                            : field?.amount * field?.qty,
                                                        finalAmount: 0,
                                                        isSelected: 1,
                                                        data: field,
                                                      }

                                                      values[0].finalAmount =
                                                        values[0].totalWithoutVat +
                                                        values[0]?.vatAmount

                                                      setInvoiceFields(values)
                                                      getAllInvoicesForMissing()
                                                      setItemToAdd(field)
                                                    }}
                                                  >
                                                    Link to Invoice
                                                    {'  '}
                                                  </button>
                                                </div>
                                              </div>
                                            )}
                                            <h6
                                              className='mb-0 head-text me-1'
                                              style={{fontWeight: '800'}}
                                            >
                                              {detailsToEdit?.id} {'_'}
                                              {field?.id}
                                            </h6>

                                            {field?.isDeletable && (
                                              <img
                                                src={bin}
                                                alt=''
                                                height='18'
                                                width='18'
                                                className='ms-3 me-1 cursor-pointer'
                                                onClick={() =>
                                                  Swal.fire({
                                                    html: '<p class="text-center m-0" style="font-size: 16px">  Deleting an Item will also delete the Late Payment Item associated with it, AND the item will be removed from the invoice as well, </p><p class="text-center m-0 mt-1" style="font-size: 16px">Do you still wish to proceed ? </p>',
                                                    showConfirmButton: true,
                                                    confirmButtonColor: '#007a59',
                                                    confirmButtonText: 'Confirm',
                                                    showCancelButton: true,
                                                    cancelButtonText: 'Cancel',
                                                  }).then((res) => {
                                                    if (res.isConfirmed) {
                                                      handleRemoveFields(
                                                        index,
                                                        field?.lateItemIndex
                                                      )
                                                    }
                                                  })
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                        <p></p>

                                        <div className='align-items-center d-flex mb-4 ps-3'>
                                          <div className='d-flex align-items-center w-50'>
                                            <label
                                              className='mb-1 me-5 pe-5 d-flex grey-label'
                                              style={{minWidth: '90px'}}
                                            >
                                              Item
                                            </label>
                                            <div style={{width: '-webkit-fill-available'}}>
                                              <input
                                                type='text'
                                                className='form-control form-control-solid-bg mytest input-values-style'
                                                placeholder='Item Name'
                                                name='item_id'
                                                value={field.item}
                                                style={{
                                                  borderRadius: '2px !important',
                                                  padding: '0.55rem 1rem !important',
                                                  color: '#384a62 !important',
                                                }}
                                                disabled
                                              />
                                            </div>
                                          </div>

                                          {field?.item == 'Maintenance' && (
                                            <div className='d-flex align-items-center ps-3 w-50 me-3'>
                                              <p
                                                style={{fontWeight: '700'}}
                                                className='mb-1  ms-auto '
                                              >
                                                {field?.jobId}
                                                {'_'}
                                                {field?.requestId}
                                              </p>
                                            </div>
                                          )}
                                        </div>

                                        <div className='d-flex align-items-center mb-4 ps-3'>
                                          <label
                                            className='mb-1 me-5 pe-5 grey-label'
                                            style={{minWidth: '90px'}}
                                          >
                                            Amount
                                          </label>

                                          <input
                                            type='number'
                                            className='form-control form-control-solid-bg mytest input-values-style'
                                            placeholder='Enter Amount'
                                            name='item_id'
                                            value={field.amount}
                                            onChange={(event) => {
                                              const values = [...fields]
                                              values[index].amount = event.target.value

                                              let y: any =
                                                field.amount * field.qty +
                                                ((field.amount * field.qty) / 100) * field.vat
                                              values[index].total = y
                                              updateAmount(values, values[index], index)
                                              setFields(values)
                                            }}
                                            style={{
                                              width: '38.8%',
                                            }}
                                            // onMouseLeave={() => updateAmount(fields, field, index)}
                                            disabled={field?.isEditable ? false : true}
                                          />
                                        </div>

                                        <div className='d-flex align-items-center justify-content-center mb-4 ps-3'>
                                          <div className='d-flex align-items-center justify-content-center'>
                                            <label
                                              className='mb-1 me-5 pe-5 grey-label'
                                              style={{minWidth: '90px'}}
                                            >
                                              QTY
                                            </label>

                                            <input
                                              type='number'
                                              className='form-control form-control-solid-bg mytest input-values-style'
                                              placeholder='Enter Qty'
                                              name='item_id'
                                              value={field.qty}
                                              onChange={(event) => {
                                                const values = [...fields]
                                                values[index].qty = event.target.value
                                                setFields(values)
                                                let y: any =
                                                  field.amount * field.qty +
                                                  ((field.amount * field.qty) / 100) * field.vat
                                                values[index].total = y
                                              }}
                                              disabled={field?.isEditable ? false : true}
                                            />
                                          </div>

                                          <div className='d-flex align-items-center justify-content-center ps-3 pe-2'>
                                            <label
                                              className='mb-1 me-5 ps-5 grey-label'
                                              style={{minWidth: '60px'}}
                                            >
                                              VAT %
                                            </label>

                                            <input
                                              type='number'
                                              className='form-control form-control-solid-bg mytest input-values-style'
                                              placeholder='VAT %'
                                              name='item_id'
                                              value={field.vat}
                                              onChange={(event) => {
                                                const values = [...fields]
                                                values[index].vat = event.target.value
                                                setFields(values)
                                                let y: any =
                                                  field.amount * field.qty +
                                                  ((field.amount * field.qty) / 100) * field.vat
                                                values[index].total = y
                                              }}
                                              disabled={field?.isEditable ? false : true}
                                            />
                                          </div>
                                        </div>

                                        <div
                                          className='d-flex align-items-center mb-4 ps-3 '
                                          style={{color: '#384a62', fontWeight: '600'}}
                                        >
                                          <label
                                            className='mb-1 me-5 pe-5 grey-label'
                                            style={{minWidth: '90px'}}
                                          >
                                            Total
                                          </label>
                                          {(
                                            field.amount * field.qty +
                                            ((field.amount * field.qty) / 100) * field.vat +
                                            field?.lateFeeTotal
                                          )?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })}
                                        </div>

                                        <div
                                          className='d-flex align-items-center mb-4 ps-3 mt-2'
                                          style={{color: '#384a62'}}
                                        >
                                          <label className='mb-1 me-5 grey-label pe-5'>
                                            Late Payment Fees
                                          </label>
                                          {field?.latePaymentSelectionStatus != 0 ? (
                                            <>
                                              {isItemChanged ? (
                                                <>
                                                  {field?.lateDetails?.latePaymentIsActive ===
                                                  true ? (
                                                    <>
                                                      {field.lateApplied === true && (
                                                        <span className='me-5 head-text'>
                                                          {field?.lateFeeTotal?.toLocaleString(
                                                            'en-US',
                                                            {
                                                              minimumFractionDigits: 2,
                                                            }
                                                          )}
                                                        </span>
                                                      )}
                                                      <div className='d-flex head-text'>
                                                        <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                                                          {field?.activeLATE === true ? (
                                                            <div
                                                              style={{
                                                                height: '18px',
                                                                width: '18px',
                                                                backgroundColor: '#384a62',
                                                                borderRadius: '50%',
                                                              }}
                                                            ></div>
                                                          ) : (
                                                            <div
                                                              style={{
                                                                height: '18px',
                                                                width: '18px',
                                                                backgroundColor: 'transparent',
                                                                borderRadius: '50%',
                                                                border: '1px solid #384a62',
                                                              }}
                                                              onClick={() => {
                                                                console.log(dueDate)
                                                                if (
                                                                  detailsToEdit?.dueDate === null ||
                                                                  detailsToEdit?.dueDate === ''
                                                                )
                                                                  ErrorToast(
                                                                    'Please first select the Due Date!!'
                                                                  )
                                                                else {
                                                                  // getLatePaymentItem(field, index)

                                                                  const values = [...fields]
                                                                  values[index].lateApplied = true
                                                                  values[index].activeLATE = true
                                                                  values[index].InactiveLATE = false

                                                                  if (
                                                                    values[index].lateOnce === false
                                                                  ) {
                                                                    values[index].lateOnce = true
                                                                  }

                                                                  changeFields(values)
                                                                  setFields(values)
                                                                }
                                                              }}
                                                            ></div>
                                                          )}

                                                          <label
                                                            className='form-check-label'
                                                            htmlFor='requestUrgency'
                                                            style={{marginRight: '25px'}}
                                                          >
                                                            Apply
                                                          </label>
                                                        </div>
                                                        <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                                          {field?.InactiveLATE === true ? (
                                                            <div
                                                              style={{
                                                                height: '18px',
                                                                width: '18px',
                                                                backgroundColor: '#384a62',
                                                                borderRadius: '50%',
                                                              }}
                                                            ></div>
                                                          ) : (
                                                            <div
                                                              style={{
                                                                height: '18px',
                                                                width: '18px',
                                                                backgroundColor: 'transparent',
                                                                borderRadius: '50%',
                                                                border: '1px solid #384a62',
                                                              }}
                                                              onClick={() => {
                                                                if (
                                                                  field?.isLatePaymentFeesRecord ==
                                                                  false
                                                                ) {
                                                                  // if (field?.latePaymentSubItemId != null) {
                                                                  const values = [...fields]
                                                                  // for (
                                                                  //   let i = 0;
                                                                  //   i < fields?.length;
                                                                  //   i++
                                                                  // ) {
                                                                  //   if (
                                                                  //     fields[i]._id ==
                                                                  //     field?.latePaymentSubItemId
                                                                  //   ) {
                                                                  //     values.splice(i, 1)
                                                                  //   }
                                                                  // }

                                                                  // const temp = [...dataToDelete]
                                                                  // temp.push({
                                                                  //   _id: field?.latePaymentSubItemId,
                                                                  // })
                                                                  // setDataToDelete(temp)

                                                                  values[index].lateApplied = false
                                                                  values[index].activeLATE = false
                                                                  values[index].InactiveLATE = true
                                                                  changeFields(values)

                                                                  // values[index].lateFeeTotal = 0

                                                                  if (
                                                                    values[index].lateItemIndex !=
                                                                    ''
                                                                  )
                                                                    // values.splice(
                                                                    //   values[index]?.lateItemIndex,
                                                                    //   1
                                                                    // )

                                                                    setFields(values)
                                                                }
                                                                // }
                                                              }}
                                                            ></div>
                                                          )}
                                                          <label
                                                            className='form-check-label'
                                                            htmlFor='Internally'
                                                            style={{marginRight: '6px'}}
                                                          >
                                                            Do not Apply
                                                          </label>
                                                        </div>
                                                      </div>
                                                    </>
                                                  ) : field?.lateDetails?.latePaymentIsActive ===
                                                    false ? (
                                                    <p className='head-text mb-0'>
                                                      <i>Inactive</i>
                                                    </p>
                                                  ) : (
                                                    ''
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  {/* {field.lateApplied === true && ( */}
                                                  <span className='me-5 head-text'>
                                                    {/* {field?.lateDetails?.late_payment_item_charge?.[0]?.feeType === 0
                                    ? field?.lateFeeTotal
                                    : getLateFeePercent(field)} */}
                                                    {field?.lateFeeTotal?.toLocaleString('en-US', {
                                                      minimumFractionDigits: 2,
                                                    })}
                                                  </span>
                                                  {/* )} */}
                                                  <div className='d-flex head-text'>
                                                    <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                                                      {field?.activeLATE === true ? (
                                                        <div
                                                          style={{
                                                            height: '18px',
                                                            width: '18px',
                                                            backgroundColor: '#384a62',
                                                            borderRadius: '50%',
                                                          }}
                                                        ></div>
                                                      ) : (
                                                        <div
                                                          style={{
                                                            height: '18px',
                                                            width: '18px',
                                                            backgroundColor: 'transparent',
                                                            borderRadius: '50%',
                                                            border: '1px solid #384a62',
                                                          }}
                                                          onClick={() => {
                                                            if (
                                                              detailsToEdit?.dueDate === null ||
                                                              detailsToEdit?.dueDate === ''
                                                            )
                                                              ErrorToast(
                                                                'Please first select the Due Date!!'
                                                              )
                                                            else {
                                                              // getLatePaymentItem(field, index)

                                                              const values = [...fields]
                                                              values[index].lateApplied = true
                                                              values[index].activeLATE = true
                                                              values[index].InactiveLATE = false

                                                              if (
                                                                values[index].lateOnce === false
                                                              ) {
                                                                values[index].lateOnce = true
                                                              }

                                                              changeFields(values)
                                                              setFields(values)
                                                            }
                                                          }}
                                                        ></div>
                                                      )}

                                                      <label
                                                        className='form-check-label'
                                                        htmlFor='requestUrgency'
                                                        style={{marginRight: '25px'}}
                                                      >
                                                        Apply
                                                      </label>
                                                    </div>
                                                    <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                                      {field?.InactiveLATE === true ? (
                                                        <div
                                                          style={{
                                                            height: '18px',
                                                            width: '18px',
                                                            backgroundColor: '#384a62',
                                                            borderRadius: '50%',
                                                          }}
                                                        ></div>
                                                      ) : (
                                                        <div
                                                          style={{
                                                            height: '18px',
                                                            width: '18px',
                                                            backgroundColor: 'transparent',
                                                            borderRadius: '50%',
                                                            border: '1px solid #384a62',
                                                          }}
                                                          onClick={() => {
                                                            // setInActiveLATE(true)
                                                            // setActiveLATE(false)

                                                            if (
                                                              field?.isLatePaymentFeesRecord ==
                                                              false
                                                            ) {
                                                              // if (field?.latePaymentSubItemId != null) {
                                                              const values = [...fields]
                                                              // for (
                                                              //   let i = 0;
                                                              //   i < fields?.length;
                                                              //   i++
                                                              // ) {
                                                              //   if (
                                                              //     fields[i]._id ==
                                                              //     field?.latePaymentSubItemId
                                                              //   ) {
                                                              //     values.splice(i, 1)
                                                              //   }
                                                              // }

                                                              // const temp = [...dataToDelete]
                                                              // temp.push({
                                                              //   _id: field?.latePaymentSubItemId,
                                                              // })
                                                              // setDataToDelete(temp)

                                                              values[index].lateApplied = false
                                                              values[index].activeLATE = false
                                                              values[index].InactiveLATE = true
                                                              changeFields(values)

                                                              if (values[index].lateItemIndex != '')
                                                                // values.splice(
                                                                //   values[index]?.lateItemIndex,
                                                                //   1
                                                                // )
                                                                setFields(values)

                                                              // getPaymentByIdAfterDeletion(field, index)
                                                              // }
                                                            }
                                                          }}
                                                        ></div>
                                                      )}
                                                      <label
                                                        className='form-check-label'
                                                        htmlFor='Internally'
                                                        style={{marginRight: '6px'}}
                                                      >
                                                        Do not Apply
                                                      </label>
                                                    </div>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <p style={{fontWeight: '700'}} className='mb-1'>
                                              <i> Inactive</i>
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div
                                        style={
                                          index > 0
                                            ? {borderTop: '0px', paddingTop: '10px'}
                                            : {borderTop: '0px'}
                                        }
                                        className='mb-5'
                                      >
                                        {field?.paymentSubItemStatus == 0 ? (
                                          <div className='d-flex align-items-center justify-content-center mt-1 mb-2'>
                                            <h3
                                              className='head-text'
                                              style={{fontWeight: '700'}}
                                            ></h3>
                                            <div className='d-flex justify-content-center align-items-center ms-auto me-5 ms-5'>
                                              <h6
                                                className='mb-0  text-danger'
                                                style={{fontWeight: '800'}}
                                              >
                                                Missing Invoice
                                              </h6>
                                            </div>
                                          </div>
                                        ) : field?.paymentSubItemStatus == 1 ? (
                                          <div className='d-flex align-items-center justify-content-center mt-1 mb-2'>
                                            <h3
                                              className='head-text'
                                              style={{fontWeight: '700'}}
                                            ></h3>
                                            <div className='d-flex justify-content-center align-items-center ms-auto me-5 ms-5'>
                                              <h6
                                                className='mb-0 text-warning'
                                                style={{fontWeight: '800'}}
                                              >
                                                Draft Invoice
                                              </h6>
                                            </div>
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {/* Payment Item Detail */}
                                        <div className='d-flex align-items-center justify-content-center mt-3 mb-2'>
                                          <h3 className='head-text' style={{fontWeight: '700'}}>
                                            Item Details
                                          </h3>
                                          <div className='d-flex justify-content-center align-items-center ms-auto '>
                                            <h6
                                              className='mb-0 head-text me-3'
                                              style={{fontWeight: '800'}}
                                            >
                                              {field?.id != undefined
                                                ? `${detailsToEdit?.id}_${field?.id}`
                                                : ''}
                                            </h6>
                                          </div>
                                        </div>

                                        <div className='align-items-center d-flex mb-4 ps-3'>
                                          <label
                                            className='mb-1 me-5 pe-5 d-flex grey-label'
                                            style={{minWidth: '90px'}}
                                          >
                                            Item
                                          </label>
                                          <div style={{width: '-webkit-fill-available'}}>
                                            <p style={{fontWeight: '700'}} className='mb-1'>
                                              {field?.item}
                                            </p>
                                          </div>
                                        </div>

                                        <div className='align-items-center d-flex mb-4 ps-3'>
                                          <label
                                            className='mb-1 me-5 pe-5 d-flex grey-label'
                                            style={{minWidth: '90px'}}
                                          >
                                            Amount
                                          </label>
                                          <div style={{width: '-webkit-fill-available'}}>
                                            <p style={{fontWeight: '700'}} className='mb-1'>
                                              {field?.isLatePaymentFeesRecord == false
                                                ? field?.amount?.toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                  })
                                                : (field?.amount / field?.qty)?.toLocaleString(
                                                    'en-US',
                                                    {
                                                      minimumFractionDigits: 2,
                                                    }
                                                  )}
                                            </p>
                                          </div>
                                        </div>

                                        <div className='d-flex align-items-center justify-content-center mb-4 ps-3'>
                                          <div className='d-flex align-items-center justify-content-center'>
                                            <label
                                              className='mb-1 me-5 pe-5 grey-label'
                                              style={{minWidth: '90px'}}
                                            >
                                              QTY
                                            </label>
                                            <p style={{fontWeight: '700'}} className='mb-1'>
                                              {field?.qty}
                                            </p>
                                          </div>

                                          <div className='d-flex align-items-center justify-content-center ps-3 ms-auto me-5 pe-5'>
                                            <label
                                              className='mb-1 me-5 ps-5 grey-label'
                                              style={{minWidth: '90px'}}
                                            >
                                              VAT
                                            </label>
                                            <p style={{fontWeight: '700'}} className='mb-1'>
                                              {field?.vat}%
                                            </p>
                                          </div>
                                        </div>

                                        <div className='d-flex align-items-center justify-content-center mb-4 ps-3'>
                                          <div className='d-flex align-items-center justify-content-center'>
                                            <label
                                              className='mb-1 me-5 pe-5 grey-label'
                                              style={{minWidth: '90px'}}
                                            >
                                              Total
                                            </label>
                                            <p style={{fontWeight: '700'}} className='mb-1'>
                                              {field?.total?.toLocaleString('en-US', {
                                                minimumFractionDigits: 2,
                                              })}
                                            </p>
                                          </div>

                                          <div className='d-flex align-items-center  ps-3 ms-auto me-5 pe-1'>
                                            <button
                                              className='btn btn-sm fw-bold ms-3 ps-2 text-white d-flex justify-content-center align-items-center'
                                              style={{
                                                fontSize: '1.2rem',
                                                // paddingRight: '55px',
                                                borderRadius: '5px',
                                                backgroundColor: '#35475e',
                                                boxShadow: '0px 0px 20px 0px #35475e',
                                                width: '140px',
                                                height: '34px',
                                                transform: 'translate(15px, 0px)',
                                              }}
                                              onClick={() => {
                                                setLateID(field?._id)
                                                getLatePaymentHistory(field)
                                              }}
                                            >
                                              {'  '}
                                              <p className='mb-0'>View All</p>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )
                        })}
                    </>
                    <>
                      {(detailsToEdit?.paymentStatus == 3 || detailsToEdit?.paymentStatus == 4) && (
                        <>
                          <div
                            className='d-flex align-items-center justify-content-center my-2 mx-auto'
                            onClick={() => handleAddNewFieldsInEdit()}
                            style={{width: 'fit-content'}}
                          >
                            <img
                              src={plusBlue}
                              style={{
                                height: '28px',
                                width: '28px',
                                cursor: 'pointer',
                              }}
                            />
                          </div>
                          <div className='align-items-center justify-content-center my-2 text-center'>
                            <p className='grey-label mb-2'>
                              {' '}
                              <i>Add another payment item</i>
                            </p>
                            <p
                              className='grey-label mx-auto'
                              style={{borderBottom: '2px dashed #a0a0b4', width: '60%'}}
                            ></p>
                          </div>
                        </>
                      )}
                    </>
                  </>
                )}
              </Col>
            ) : (
              <Col
                lg={7}
                md={7}
                className='align-items-center pt-5  ps-lg-10 pe-5'
                style={
                  isSelected === false
                    ? {maxHeight: '600px', overflow: 'scroll'}
                    : {maxHeight: '600px', overflow: 'scroll'}
                }
              >
                {newFields?.length > 0 && (
                  <div className='d-flex justify-content-center'>
                    <button
                      className='btn btn-sm fw-bold ps-2 text-white d-flex justify-content-center align-items-center me-5'
                      style={{
                        fontSize: '1.2rem',
                        paddingRight: '55px',
                        borderRadius: '5px',
                        backgroundColor: '#6e8aa6',
                        boxShadow: '0px 0px 20px 0px #6e8aa6',
                        width: '180px',
                        height: '34px',
                        // transform: 'translate(15px, 0px)',
                      }}
                      onClick={() => {
                        if (dueDateForNewItems === null || dueDateForNewItems === '')
                          ErrorToast('Please first select the Due Date!!')
                        else validateNewFields()
                      }}
                    >
                      <img
                        src={saveImg}
                        height='20'
                        width='20'
                        style={{
                          marginRight: '21px',
                          marginLeft: '12px',
                        }}
                      />{' '}
                      {'  '}
                      <p className='mb-0'>Save</p>
                    </button>

                    <button
                      className='btn btn-sm fw-bold ms-5 ps-2 text-white d-flex justify-content-center align-items-center'
                      style={{
                        fontSize: '1.2rem',
                        paddingRight: '55px',
                        borderRadius: '5px',
                        backgroundColor: '#D54645',
                        boxShadow: '0px 0px 20px 0px #6e8aa6',
                        width: '180px',
                        height: '34px',
                        // transform: 'translate(15px, 0px)',
                      }}
                      onClick={() => {
                        refetchPaymentItems()
                        // setNewFields([])
                      }}
                    >
                      <img
                        src={crossbtn}
                        height='20'
                        width='20'
                        style={{
                          marginRight: '21px',
                          marginLeft: '12px',
                        }}
                      />{' '}
                      {'  '}
                      <p className='mb-0'>Cancel</p>
                    </button>
                  </div>
                )}

                {newFields &&
                  newFields.map((field: any, index: number) => {
                    return (
                      <>
                        {field?.isLateItem ? (
                          <>
                            <div
                              style={
                                index > 0
                                  ? {borderTop: '0px', paddingTop: '10px'}
                                  : {borderTop: '0px'}
                              }
                              className='mb-5'
                            >
                              {/* Payment Item Detail */}
                              <div className='d-flex align-items-center justify-content-center mt-3 mb-2'>
                                <h3 className='head-text' style={{fontWeight: '700'}}>
                                  Item Details
                                </h3>
                                <div className='d-flex justify-content-center align-items-center ms-auto me-5 pe-5'>
                                  <h6 className='mb-0 head-text' style={{fontWeight: '800'}}>
                                    {/* {index === 0 ? `${id}_${subId}` : `${id}_${field.id}`} */}
                                  </h6>
                                </div>
                              </div>

                              <div className='align-items-center d-flex mb-4 ps-3'>
                                <label
                                  className='mb-1 me-5 pe-5 d-flex grey-label'
                                  style={{minWidth: '90px'}}
                                >
                                  Item
                                </label>
                                <div style={{width: '-webkit-fill-available'}}>
                                  <p style={{fontWeight: '700'}} className='mb-1 head-text'>
                                    {field?.item}
                                  </p>
                                </div>
                              </div>

                              <div className='align-items-center d-flex mb-4 ps-3'>
                                <label
                                  className='mb-1 me-5 pe-5 d-flex grey-label'
                                  style={{minWidth: '90px'}}
                                >
                                  Amount
                                </label>
                                <div style={{width: '-webkit-fill-available'}}>
                                  <p style={{fontWeight: '700'}} className='mb-1 head-text'>
                                    {field?.amount != 0
                                      ? (field?.amount / field?.qty)?.toFixed(2)
                                      : 0}
                                  </p>
                                </div>
                              </div>

                              <div className='d-flex align-items-center justify-content-center mb-4 ps-3'>
                                <div className='d-flex align-items-center justify-content-center'>
                                  <label
                                    className='mb-1 me-5 pe-5 grey-label'
                                    style={{minWidth: '90px'}}
                                  >
                                    QTY
                                  </label>
                                  <p style={{fontWeight: '700'}} className='mb-1 head-text'>
                                    {field?.qty}
                                  </p>
                                </div>

                                <div className='d-flex align-items-center justify-content-center ps-3 ms-auto me-5 pe-5'>
                                  <label
                                    className='mb-1 me-5 ps-5 grey-label'
                                    style={{minWidth: '90px'}}
                                  >
                                    VAT
                                  </label>
                                  <p style={{fontWeight: '700'}} className='mb-1 head-text'>
                                    {field?.vat}%
                                  </p>
                                </div>
                              </div>

                              <div
                                className='d-flex align-items-center mb-4 ps-3 '
                                style={{color: '#384a62', fontWeight: '600'}}
                              >
                                <label
                                  className='mb-1 me-5 pe-5 grey-label'
                                  style={{minWidth: '90px'}}
                                >
                                  Total
                                </label>
                                <p style={{fontWeight: '700'}} className='mb-1 head-text'>
                                  {' '}
                                  {field?.total?.toFixed(2)}
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              style={
                                index > 0
                                  ? {borderTop: '1px solid lightgray', paddingTop: '10px'}
                                  : {borderTop: '0px'}
                              }
                              className='mb-5'
                            >
                              <div className='d-flex align-items-center justify-content-center mt-3 mb-2'>
                                <h3 className='head-text' style={{fontWeight: '700'}}>
                                  Item Details
                                </h3>
                                <div className='d-flex justify-content-center align-items-center ms-auto'>
                                  <h6 className='mb-0 head-text' style={{fontWeight: '800'}}>
                                    {/* {index === 0 ? `${id}_${subId}` : `${id}_${field.id}`} */}
                                  </h6>
                                  <img
                                    src={bin}
                                    alt=''
                                    height='18px'
                                    width='18px'
                                    className='ms-5'
                                    style={{cursor: 'pointer'}}
                                    onClick={() =>
                                      Swal.fire({
                                        html: '<p class="text-center m-0" style="font-size: 16px"> You are about to delete the item, do you wish to proceed?</p>',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#007a59',
                                        confirmButtonText: 'Confirm',
                                        showCancelButton: true,
                                        cancelButtonText: 'Cancel',
                                      }).then((res) => {
                                        if (res.isConfirmed) {
                                          handleRemoveFields(index, field)
                                        }
                                      })
                                    }
                                  />
                                </div>
                              </div>

                              {/* Item Name */}
                              <div className='align-items-center d-flex mb-4 ps-3'>
                                <label
                                  className='mb-1 me-5 pe-5 d-flex grey-label'
                                  style={{minWidth: '90px'}}
                                >
                                  Item
                                </label>
                                <div style={{width: '-webkit-fill-available'}} className='d-flex'>
                                  {paymentItems &&
                                    paymentItems.map((pI: any, index: any) => {
                                      if (field.item == pI.name) itemInd = index
                                      return <></>
                                    })}

                                  {field?.item?.length > 0 ? (
                                    <Select
                                      options={
                                        filteredPaymentItems &&
                                        filteredPaymentItems?.map((pItem: any, index: any) => {
                                          return {
                                            label: `${pItem?.name}`,
                                            value: `${pItem?._id}`,
                                            id: pItem,
                                          }
                                        })
                                      }
                                      defaultValue={{
                                        label: filteredPaymentItems[itemInd]?.name,
                                        value: filteredPaymentItems[itemInd]?._id,
                                        id: filteredPaymentItems[itemInd],
                                      }}
                                      styles={customStylesInputFilledForNewItems}
                                      isSearchable={false}
                                      onChange={(event: any) => {
                                        const values = [...newFields]
                                        if (event.label == 'Maintenance') {
                                          values[index].showLink = true
                                          setIndForMaintNew(index)
                                        } else {
                                          values[index].serviceRequestId = null
                                          values[index].requestId = null
                                          values[index].serviceJobId = null
                                          values[index].jobId = null
                                        }

                                        values[index].item = event.label
                                        values[index].lateDetails = event.id
                                        if (values[index].activeLATE == true) {
                                          if (new Date(dueDate) < new Date())
                                            changeNewFields(field, index)
                                        }

                                        // if (invoiceAdded) setYellowInvoice(true)

                                        setReceiptAdded(false)
                                        for (let i = 0; i < values?.length; i++) {
                                          values[i].amountPaid = 0
                                        }

                                        setAmountPaid(0)
                                        setDatePaid(null)
                                        setNewFields(values)
                                      }}
                                    />
                                  ) : (
                                    <Select
                                      options={
                                        filteredPaymentItems &&
                                        filteredPaymentItems?.map((pItem: any, index: any) => {
                                          return {
                                            label: `${pItem?.name}`,
                                            value: `${pItem?._id}`,
                                            id: pItem,
                                          }
                                        })
                                      }
                                      styles={customStyles}
                                      isSearchable={false}
                                      defaultValue={field.item}
                                      onChange={(event) => {
                                        const values = [...newFields]
                                        if (event.label == 'Maintenance') {
                                          values[index].showLink = true
                                          setIndForMaintNew(index)
                                        } else {
                                          values[index].serviceRequestId = null
                                          values[index].requestId = null
                                          values[index].serviceJobId = null
                                          values[index].jobId = null
                                        }

                                        values[index].item = event.label
                                        values[index].lateDetails = event.id
                                        if (values[index].activeLATE == true) {
                                          if (new Date(dueDate) < new Date())
                                            changeNewFields(field, index)
                                        }

                                        // if (invoiceAdded) setYellowInvoice(true)

                                        setReceiptAdded(false)
                                        for (let i = 0; i < values?.length; i++) {
                                          values[i].amountPaid = 0
                                        }

                                        setAmountPaid(0)
                                        setDatePaid(null)
                                        setNewFields(values)
                                      }}
                                    />
                                  )}

                                  {
                                    (field.showLink = true && field?.item == 'Maintenance' && (
                                      <>
                                        {field?.serviceJobId != null ||
                                        field?.serviceRequestId != null ? (
                                          <button
                                            className='btn btn-sm fw-bold ms-auto ps-2 text-white me-0 py-0'
                                            style={{
                                              fontSize: '0.7rem',
                                              paddingRight: '15px',
                                              borderRadius: '2px',
                                              backgroundColor: '#35475e',
                                              boxShadow: '0px 0px 20px 0px #516176',
                                            }}
                                            onClick={(event) => {
                                              event.preventDefault()
                                              setIndForMaintNew(index)
                                              setTimeout(() => {
                                                getServiceJobForPayment()
                                              }, 1000)
                                            }}
                                          >
                                            <img
                                              src={linkImg}
                                              style={{
                                                height: '16px',
                                                width: '16px',
                                                marginRight: '5px',
                                                marginLeft: '5px',
                                                marginTop: '-3px',
                                              }}
                                            />
                                            {field?.jobId}
                                            {'_'}
                                            {field?.requestId}
                                          </button>
                                        ) : (
                                          <button
                                            className='btn btn-sm fw-bold ms-auto ps-2 text-white me-0 '
                                            style={{
                                              fontSize: '1rem',
                                              paddingRight: '15px',
                                              borderRadius: '2px',
                                              backgroundColor: '#35475e',
                                              boxShadow: '0px 0px 20px 0px #516176',
                                            }}
                                            onClick={(event) => {
                                              event.preventDefault()
                                              setIndForMaintNew(index)
                                              setTimeout(() => {
                                                getServiceJobForPayment()
                                              }, 1000)
                                            }}
                                          >
                                            <img
                                              src={linkImg}
                                              style={{
                                                height: '20px',
                                                width: '20px',
                                                marginRight: '12px',
                                                marginLeft: '4px',
                                                marginTop: '-3px',
                                              }}
                                            />{' '}
                                            {'  '}
                                            Link to Request
                                          </button>
                                        )}
                                      </>
                                    ))
                                  }
                                </div>
                              </div>

                              <div className='d-flex align-items-center mb-4 ps-3'>
                                <label
                                  className='mb-1 me-5 pe-5 grey-label'
                                  style={{minWidth: '90px'}}
                                >
                                  Amount
                                </label>

                                <input
                                  type='number'
                                  className='form-control form-control-solid-bg mytest'
                                  placeholder='Enter Amount'
                                  name='item_id'
                                  value={field.amount}
                                  onChange={(event) => {
                                    const values = [...newFields]
                                    values[index].amount = event.target.value

                                    let y: any =
                                      field.amount * field.qty +
                                      ((field.amount * field.qty) / 100) * field.vat
                                    values[index].total = y
                                    values[index].outstanding = y
                                    // setNewFields(values)

                                    if (event.target.value != '') {
                                      if (values[index].activeLATE == true) {
                                        if (new Date(dueDate) < new Date())
                                          changeNewFields(values[index], index)
                                      }
                                    }

                                    // if (invoiceAdded) setYellowInvoice(true)

                                    setReceiptAdded(false)
                                    for (let i = 0; i < values?.length; i++) {
                                      values[i].amountPaid = 0
                                    }

                                    setAmountPaid(0)
                                    setDatePaid(null)
                                    setNewFields(values)
                                  }}
                                  style={{
                                    // border: '0.1rem solid #bec3cb',
                                    borderRadius: '2px',
                                    padding: '0.55rem 1rem',
                                    width: '38.8%',
                                  }}
                                  onMouseLeave={(event: any) => {
                                    // const values = [...newFields]
                                    // if (event.target.value != '') {
                                    //   if (values[index].activeLATE == true) {
                                    //     if (new Date(dueDate) < new Date())
                                    //       changeNewFields(field, index)
                                    //   }
                                    // }
                                    // // if (invoiceAdded) setYellowInvoice(true)
                                    // setReceiptAdded(false)
                                    // for (let i = 0; i < values?.length; i++) {
                                    //   values[i].amountPaid = 0
                                    // }
                                    // setAmountPaid(0)
                                    // setDatePaid(null)
                                    // setNewFields(values)
                                  }}
                                />
                              </div>

                              <div className='d-flex align-items-center justify-content-center mb-4 ps-3'>
                                <div className='d-flex align-items-center justify-content-center'>
                                  <label
                                    className='mb-1 me-5 pe-5 grey-label'
                                    style={{minWidth: '90px'}}
                                  >
                                    QTY
                                  </label>

                                  <input
                                    type='number'
                                    className='form-control form-control-solid-bg mytest'
                                    placeholder='Enter Qty'
                                    name='item_id'
                                    value={field.qty}
                                    onChange={(event) => {
                                      const values = [...newFields]
                                      values[index].qty = event.target.value

                                      let y: any =
                                        field.amount * field.qty +
                                        ((field.amount * field.qty) / 100) * field.vat
                                      values[index].total = y
                                      values[index].outstanding = y

                                      // if (invoiceAdded) setYellowInvoice(true)

                                      setReceiptAdded(false)
                                      for (let i = 0; i < values?.length; i++) {
                                        values[i].amountPaid = 0
                                      }

                                      setAmountPaid(0)
                                      setDatePaid(null)
                                      setNewFields(values)
                                    }}
                                    style={{
                                      // border: '0.1rem solid #bec3cb',
                                      borderRadius: '2px',
                                      padding: '0.55rem 1rem ',
                                    }}
                                  />
                                </div>

                                <div className='d-flex align-items-center justify-content-center ps-3'>
                                  <label
                                    className='mb-1 me-5 ps-5 grey-label'
                                    style={{minWidth: '60px'}}
                                  >
                                    VAT %
                                  </label>

                                  <input
                                    type='number'
                                    className='form-control form-control-solid-bg mytest '
                                    placeholder='VAT %'
                                    name='item_id'
                                    value={field.vat}
                                    onChange={(event) => {
                                      const values = [...newFields]
                                      values[index].vat = event.target.value
                                      setNewFields(values)
                                      let y: any =
                                        field.amount * field.qty +
                                        ((field.amount * field.qty) / 100) * field.vat
                                      values[index].total = y
                                      values[index].outstanding = y

                                      // if (invoiceAdded) setYellowInvoice(true)

                                      setReceiptAdded(false)
                                      for (let i = 0; i < values?.length; i++) {
                                        values[i].amountPaid = 0
                                      }

                                      setAmountPaid(0)
                                      setDatePaid(null)
                                      setNewFields(values)
                                    }}
                                    style={{
                                      // border: '0.1rem solid #bec3cb',
                                      borderRadius: '2px',
                                      padding: '0.55rem 1rem',
                                    }}
                                  />
                                </div>
                              </div>

                              <div
                                className='d-flex align-items-center mb-4 ps-3 '
                                style={{color: '#384a62', fontWeight: '600'}}
                              >
                                <label
                                  className='mb-1 me-5 pe-5 grey-label'
                                  style={{minWidth: '90px'}}
                                >
                                  Total
                                </label>
                                {field.lateApplied === false
                                  ? (
                                      field.amount * field.qty +
                                      ((field.amount * field.qty) / 100) * field.vat
                                    )?.toFixed(2)
                                  : (
                                      field.amount * field.qty +
                                      ((field.amount * field.qty) / 100) * field.vat +
                                      field?.lateFeeTotal
                                    )?.toFixed(2)}
                              </div>

                              <div
                                className='d-flex align-items-center mb-4 ps-3 mt-2'
                                style={{color: '#384a62'}}
                              >
                                <label className='mb-1 me-5 grey-label pe-5'>
                                  Late Payment Fees
                                </label>

                                {/* Late Details */}
                                {field?.lateDetails?.latePaymentIsActive === true ? (
                                  <>
                                    {field.lateApplied == true && (
                                      <span className='me-5 head-text'>
                                        {field?.lateFeeTotal?.toFixed(2)}
                                      </span>
                                    )}
                                    <div className='d-flex head-text'>
                                      <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                                        {field?.activeLATE === true ? (
                                          <div
                                            style={{
                                              height: '18px',
                                              width: '18px',
                                              backgroundColor: '#384a62',
                                              borderRadius: '50%',
                                            }}
                                          ></div>
                                        ) : (
                                          <div
                                            style={{
                                              height: '18px',
                                              width: '18px',
                                              backgroundColor: 'transparent',
                                              borderRadius: '50%',
                                              border: '1px solid #384a62',
                                            }}
                                            onClick={() => {
                                              if (
                                                dueDateForNewItems === null ||
                                                dueDateForNewItems === ''
                                              )
                                                ErrorToast('Please first select the Due Date!!')
                                              else {
                                                const values = [...newFields]
                                                values[index].lateApplied = true
                                                values[index].activeLATE = true
                                                values[index].InactiveLATE = false
                                                // values[index].lateFeeTotal = x

                                                if (values[index].lateOnce === false) {
                                                  values[index].lateOnce = true
                                                  // values[index].total = values[index].total + x
                                                }

                                                // if (invoiceAdded) setYellowInvoice(true)

                                                setReceiptAdded(false)
                                                for (let i = 0; i < values?.length; i++) {
                                                  values[i].amountPaid = 0
                                                }

                                                setAmountPaid(0)
                                                setDatePaid(null)
                                                setNewFields(values)

                                                if (new Date(detailsToEdit?.dueDate) < new Date()) {
                                                  getLatePaymentItemForNewItem(field, index)
                                                }
                                              }
                                            }}
                                          ></div>
                                        )}

                                        <label
                                          className='form-check-label'
                                          htmlFor='requestUrgency'
                                          style={{marginRight: '25px'}}
                                        >
                                          Apply
                                        </label>
                                      </div>
                                      <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                        {field?.InactiveLATE == true ? (
                                          <div
                                            style={{
                                              height: '18px',
                                              width: '18px',
                                              backgroundColor: '#384a62',
                                              borderRadius: '50%',
                                            }}
                                          ></div>
                                        ) : (
                                          <div
                                            style={{
                                              height: '18px',
                                              width: '18px',
                                              backgroundColor: 'transparent',
                                              borderRadius: '50%',
                                              border: '1px solid #384a62',
                                            }}
                                            onClick={() => {
                                              const values = [...newFields]

                                              let lt: any

                                              if (values[index].activeLATE == true) {
                                                if (values[index].lateItemIndex != '') {
                                                  lt = values[index].lateItemIndex
                                                  if (values[index].lateItemIndex != -1)
                                                    values.splice(values[index]?.lateItemIndex, 1)
                                                }
                                                values[index].lateItemIndex = null
                                              }

                                              if (lt != -1) {
                                                for (let i = index; i < values?.length; i++) {
                                                  if (values[i]?.isLateItem == false) {
                                                    if (values[i].activeLATE == true) {
                                                      if (values[i]?.lateItemIndex != null) {
                                                        console.log(values[i]?.lateItemIndex)
                                                        if (values[i]?.lateItemIndex != -1)
                                                          values[i].lateItemIndex =
                                                            values[i]?.lateItemIndex - 1
                                                      }
                                                    }
                                                  }
                                                }
                                              }

                                              values[index].lateApplied = false
                                              values[index].activeLATE = false
                                              values[index].InactiveLATE = true

                                              if (values[index].lateOnce === true) {
                                                values[index].lateOnce = false
                                                // values[index].total = values[index].total - x
                                              }

                                              // if (invoiceAdded) setYellowInvoice(true)
                                              setReceiptAdded(false)
                                              for (let i = 0; i < values?.length; i++) {
                                                values[i].amountPaid = 0
                                              }

                                              setAmountPaid(0)
                                              setDatePaid(null)
                                              setNewFields(values)
                                            }}
                                          ></div>
                                        )}
                                        <label
                                          className='form-check-label'
                                          htmlFor='Internally'
                                          style={{marginRight: '6px'}}
                                        >
                                          Do not Apply
                                        </label>
                                      </div>
                                    </div>
                                  </>
                                ) : field?.lateDetails?.latePaymentIsActive === false ? (
                                  <p className='head-text mb-0'>
                                    <i>Inactive</i>
                                  </p>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )
                  })}

                <div
                  className='d-flex align-items-center justify-content-center my-2 mx-auto'
                  onClick={() => handleAddNewFields()}
                  style={{width: 'fit-content'}}
                >
                  <img
                    src={plusBlue}
                    style={{
                      height: '28px',
                      width: '28px',
                      cursor: 'pointer',
                    }}
                  />
                </div>
                <div className='align-items-center justify-content-center my-2 text-center'>
                  <p className='grey-label mb-2'>
                    {' '}
                    <i>Add another payment item</i>
                  </p>
                  <p
                    className='grey-label mx-auto'
                    style={{borderBottom: '2px dashed #a0a0b4', width: '60%'}}
                  ></p>
                </div>
              </Col>
            )}

            {/* Right Column: Payment Details */}
            <Col
              lg={5}
              md={5}
              className='pt-3 px-5'
              // style={{backgroundColor: '#f2f2f2', maxHeight: '400px', overflow: 'scroll'}}
              style={{maxHeight: '600px', overflow: 'scroll', backgroundColor: '#f2f2f2'}}
            >
              <div className='align-items-end d-flex pt-1 pb-5'>
                <div className='d-flex justify-content-center align-items-center ms-auto'>
                  <p className='mb-0 head-text fs-5' style={{fontWeight: '400'}}>
                    <i>{detailsToEdit?.id} </i>
                  </p>
                  {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                    ((user?.isSubUser && role?.leasing_residential?.lease_financial?.delete) ||
                      !user?.isSubUser)) ||
                    type == 'propertyProfile' ||
                    type == 'tenant' ||
                    type == 'financials') && (
                    <img
                      src={bin}
                      alt=''
                      height='28px'
                      width='28px'
                      className='ms-4'
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        if (detailsToEdit?.isLock)
                          ErrorToast(
                            'This payment is linked with a tenancy termination request. Once the termination has been approved you will be able to edit this payment.'
                          )
                        else {
                          Swal.fire({
                            html: '<p class="text-center m-0" style="font-size: 16px"> You are about to delete the payment record, do you wish to proceed?</p>',
                            showConfirmButton: true,
                            confirmButtonColor: '#007a59',
                            confirmButtonText: 'Confirm',
                            showCancelButton: true,
                            cancelButtonText: 'Cancel',
                          }).then((res: any) => {
                            if (res.isConfirmed) {
                              deletePayment()
                            }
                          })
                        }
                      }}
                    />
                  )}
                </div>
              </div>

              {fields?.length > 0 && (
                <>
                  {fields.map((f: any, ind: any) => {
                    if (f?.isLatePaymentFeesRecord === true) {
                      vatAmount = vatAmount + (f.amount / 100) * f.vat
                      nonVat = nonVat + f.amount
                    } else {
                      vatAmount = vatAmount + ((f.amount * f.qty) / 100) * f.vat
                      nonVat = nonVat + f.amount * f.qty
                    }
                    totalAED = 0
                    return <></>
                  })}
                </>
              )}

              {fields.map((f: any, ind: any) => {
                // totalAED = totalAED + f?.total
                //  setGrandAmount(totalAED)

                // if (f?.isLatePaymentFeesRecord == false)
                if (f?.isLatePaymentFeesRecord === false) {
                  totalAED = totalAED + ((f.amount * f.qty) / 100) * f.vat + f.amount * f.qty
                } else {
                  totalAED = totalAED + (f.amount / 100) * f.vat + f.amount
                }
                // totalAED = totalAED + f?.total
                // else return <></>
              })}

              {newFields?.length > 0 &&
                newFields.map((f: any, ind: any) => {
                  totalAED = totalAED + f?.total
                })}
              <h2
                className='head-text mb-5'
                style={{fontWeight: '800', textDecoration: 'underline'}}
                ref={nameRef}
              >
                AED {parseFloat(totalAED).toLocaleString('en-US', {minimumFractionDigits: 2})}
              </h2>

              <div className='d-flex align-items-center mb-4 head-text' style={{fontWeight: '700'}}>
                <label className='mb-1 me-5 pe-5 blue-label' style={{minWidth: '96px'}}>
                  Name
                </label>
                <p style={{fontWeight: '700'}} className='mb-1'>
                  {detailsToEdit?.name}
                </p>
              </div>
              <div className='d-flex align-items-center mb-4 head-text' style={{fontWeight: '700'}}>
                <label className='mb-1 me-5 pe-5  blue-label' style={{minWidth: '96px'}}>
                  Status
                </label>
                <p style={{fontWeight: '700'}} className='mb-1'>
                  {detailsToEdit?.paymentStatus == 0
                    ? 'Unpaid'
                    : detailsToEdit?.paymentStatus == 2
                    ? 'Partially Paid'
                    : detailsToEdit?.paymentStatus == 1
                    ? 'Paid'
                    : detailsToEdit?.paymentStatus == 3
                    ? 'Void'
                    : 'Draft'}
                </p>
              </div>

              <div className='d-flex align-items-center mb-4 head-text' style={{fontWeight: '700'}}>
                <label className='mb-1 me-5 pe-5  blue-label' style={{minWidth: '96px'}}>
                  Category
                </label>
                {/* {getStatus()} */}
                {detailsToEdit?.paymentStatus == 3 ? (
                  <p style={{fontWeight: '700'}} className='mb-1'>
                    {'-'}
                  </p>
                ) : (
                  <p style={{fontWeight: '700'}} className='mb-1'>
                    {detailsToEdit?.categories == 0
                      ? 'Upcoming'
                      : detailsToEdit?.categories == 1
                      ? 'Overdue'
                      : detailsToEdit?.categories == 2
                      ? 'Deferred'
                      : ''}
                  </p>
                )}
              </div>

              <div
                className='d-flex align-items-center mb-4 head-text'
                style={{fontWeight: '700'}}
                ref={amountPaidRef}
              >
                <label className='mb-1 me-5 pe-3  blue-label' style={{minWidth: '96px'}}>
                  Amount Paid
                </label>
                <p style={{fontWeight: '700'}} className='mb-1'>
                  {paidAmountTotal.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  {/* {detailsToEdit?.amountPaid} */}
                </p>
              </div>

              <div
                className='d-flex align-items-center mb-4 head-text'
                style={{fontWeight: '700'}}
                ref={amountPaidRef}
              >
                <label className='mb-1 me-5 pe-3  blue-label' style={{minWidth: '96px'}}>
                  Refunded
                </label>
                <p style={{fontWeight: '700'}} className='mb-1'>
                  {detailsToEdit?.totalRefundAmount}
                </p>
              </div>

              <div
                className='d-flex align-items-center  mb-4 head-text'
                style={{fontWeight: '700'}}
                ref={datePaidRef}
              >
                <label className='mb-1 me-5 pe-5 blue-label' style={{minWidth: '100px'}}>
                  Date Paid
                </label>
                <p style={{fontWeight: '700'}} className='mb-1'>
                  {datePaidFinal ? moment(datePaidFinal).format('DD.MM.YYYY') : '-'}
                </p>
              </div>

              <div
                className='d-flex align-items-center  mb-4  blue-label'
                style={{fontWeight: '700'}}
              >
                <label className='mb-1 me-5 pe-5 blue-label' style={{minWidth: '100px'}}>
                  Outstanding Amount
                </label>
                {parseFloat(totalAED) - parseFloat(paidAmountTotal) > 0 ? (
                  <p style={{fontWeight: '700'}} className='mb-1'>
                    {getNegative(
                      parseFloat(totalAED) - parseFloat(paidAmountTotal)
                    )?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}
                    {/* {detailsToEdit?.outstandingAmount ? detailsToEdit?.outstandingAmount : '-'} */}
                  </p>
                ) : (
                  <p style={{fontWeight: '700'}} className='mb-1'>
                    {getPositive(
                      parseFloat(totalAED) - parseFloat(paidAmountTotal)
                    )?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })}
                    {/* {detailsToEdit?.outstandingAmount ? detailsToEdit?.outstandingAmount : '-'} */}
                  </p>
                )}
              </div>

              {/* Due Date */}
              {newFields?.length > 0 ? (
                <div
                  className='d-flex align-items-center  pb-5 head-text me-1'
                  style={{fontWeight: '700'}}
                >
                  <label className='mb-1 me-5 pe-5 blue-label' style={{minWidth: '90px'}}>
                    Due
                  </label>

                  <div style={{width: '-webkit-fill-available'}}>
                    <DatePicker
                      onChange={handleDueDateChangeForNewItems}
                      className={
                        dueDateError
                          ? 'border-danger'
                          : dueDateForNewItems != null || dueDateForNewItems != null
                          ? 'blue-border'
                          : 'grey-border'
                      }
                    />
                  </div>
                </div>
              ) : (
                <>
                  {showEditDue ? (
                    <div style={{borderBottom: '2px dashed lightgray'}}>
                      <div
                        className='d-flex align-items-center  pb-5 head-text me-1'
                        style={{fontWeight: '700'}}
                        ref={dueDateRef}
                      >
                        <label className='mb-1 me-5 pe-5 blue-label' style={{minWidth: '90px'}}>
                          Due
                        </label>

                        <div style={{width: '-webkit-fill-available'}}>
                          <DatePicker
                            defaultValue={moment(new Date(detailsToEdit?.dueDate), 'DD-MM-YYYY')}
                            onChange={handleDueDateChange}
                            disabled={paidAmount == totalAED ? true : false}
                            className={
                              dueDateError
                                ? 'border-danger'
                                : detailsToEdit?.dueDate != null || dueDate != null
                                ? 'blue-border'
                                : 'grey-border'
                            }
                          />
                        </div>
                      </div>
                      <Row className='mb-4 me-2'>
                        <Col md={6}>
                          <button
                            className='btn btn-sm fw-bold text-white d-flex justify-content-center align-items-center'
                            style={{
                              fontSize: '1.2rem',
                              paddingRight: '55px',
                              borderRadius: '5px',
                              backgroundColor: '#6e8aa6',
                              boxShadow: '0px 0px 20px 0px #6e8aa6',
                              width: '150px',
                              height: '34px',
                              transform: 'translate(15px, 0px)',
                            }}
                            onClick={() => {
                              const today = new Date()
                              const currentDate = new Date(detailsToEdit?.dueDate)
                              const selectedDate = new Date(dueDateToShow)

                              let x = currentDate.toLocaleDateString('uk-UA')
                              let y = selectedDate.toLocaleDateString('uk-UA')
                              let z = today.toLocaleDateString('uk-UA')

                              if (selectedDate > currentDate) {
                                Swal.fire({
                                  html: '<p class="text-center m-0" style="font-size: 16px"> Postponing the ‘Due Date’ field will switch the category to ‘Deferred’. Do you wish to continue?</p>',
                                  showConfirmButton: true,
                                  confirmButtonColor: '#007a59',
                                  confirmButtonText: 'Yes',
                                  showCancelButton: true,
                                  cancelButtonText: 'No',
                                }).then((res: any) => {
                                  if (res.isConfirmed) {
                                    updatePaymentRecord(5)
                                  }
                                })
                              } else {
                                if (z === y) {
                                  updatePaymentRecord(51) // upcoming
                                } else if (selectedDate < today) {
                                  updatePaymentRecord(52) //overdue
                                } else if (selectedDate > today) {
                                  updatePaymentRecord(51) //upcoming
                                }
                              }
                            }}
                          >
                            <img
                              src={saveImg}
                              height='20'
                              width='20'
                              style={{
                                marginRight: '21px',
                                marginLeft: '12px',
                                // marginTop: '-8px',
                              }}
                            />{' '}
                            {'  '}
                            <p className='mb-0'>Save</p>
                          </button>
                        </Col>
                        <Col md={6} className='ps-0'>
                          <button
                            className='btn btn-sm fw-bold text-white d-flex justify-content-center align-items-center'
                            style={{
                              fontSize: '1.2rem',
                              paddingRight: '55px',
                              borderRadius: '5px',
                              backgroundColor: '#D54645',
                              boxShadow: '0px 0px 20px 0px #6e8aa6',
                              width: '150px',
                              height: '34px',
                              transform: 'translate(15px, 0px)',
                            }}
                            onClick={() => {
                              setShowEditDue(false)
                            }}
                          >
                            <img
                              src={crossbtn}
                              height='20'
                              width='20'
                              style={{
                                marginRight: '21px',
                                marginLeft: '12px',
                                // marginTop: '-8px',
                              }}
                            />{' '}
                            {'  '}
                            <p className='mb-0'>Cancel</p>
                          </button>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div
                      className='d-flex align-items-center  pb-5 head-text'
                      style={{fontWeight: '700', borderBottom: '2px dashed lightgray'}}
                      ref={dueDateRef}
                    >
                      <label className='mb-1 me-5 pe-5 blue-label' style={{minWidth: '90px'}}>
                        Due
                      </label>
                      <p style={{fontWeight: '700'}} className='mb-1 me-5'>
                        {dueDateForPayment ? moment(dueDateForPayment).format('DD.MM.YYYY') : '-'}
                      </p>
                      {fields?.length > 0 && (
                        <img
                          src={calender}
                          height='16'
                          width='16'
                          className='ms-5 cursor-pointer'
                          onClick={() => setShowEditDue(true)}
                          style={{marginTop: '-5px'}}
                        />
                      )}
                    </div>
                  )}
                </>
              )}

              {/* Payment Method Collapse */}
              {showEditMethod == false ? (
                <>
                  <Row className='py-4'>
                    <Col md={6} className=''>
                      <h4 className='head-text' style={{fontWeight: '700'}}>
                        Payment Method
                      </h4>
                    </Col>

                    <Col md={6}>
                      <div className='d-flex'>
                        <h6 className='head-text ms-auto me-3' style={{fontWeight: '500'}}>
                          {paymentMethod == 0
                            ? 'Card'
                            : paymentMethod == 1
                            ? 'Cheque'
                            : paymentMethod == 2
                            ? 'Bank'
                            : 'Cash'}
                        </h6>
                        {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                          ((user?.isSubUser &&
                            role?.leasing_residential?.lease_financial?.update) ||
                            !user?.isSubUser)) ||
                          type == 'propertyProfile' ||
                          type == 'tenant' ||
                          type == 'financials') && (
                          <img
                            src={editpen}
                            alt=''
                            className='me-2 cursor-pointer'
                            height='18'
                            width='18'
                            style={{
                              marginTop: '2px',
                            }}
                            onClick={() => {
                              if (detailsToEdit?.isLock)
                                ErrorToast(
                                  'This payment is linked with a tenancy termination request. Once the termination has been approved you will be able to edit this payment.'
                                )
                              else {
                                if (showEdit == false) {
                                  setShowEditMethod(true)
                                  if (collapse == false) setcollapse(!collapse)
                                }
                              }
                            }}
                          />
                        )}
                        {(paymentMethod == 2 || paymentMethod == 1 || paymentMethod == 0) && (
                          <button
                            className='btn btn-sm ms-2'
                            style={{height: '25px', width: '20px'}}
                          >
                            <img
                              src={collapse ? up : down}
                              height='20'
                              width='20'
                              className='cursor-pointer'
                              style={{
                                marginLeft: '-10px',
                                marginTop: '-16px',
                              }}
                              onClick={() => setcollapse(!collapse)}
                              aria-controls='example-collapse-text'
                              aria-expanded={collapse}
                            />
                          </button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <div className='align-items-center d-flex mb-3 py-4'>
                    <label
                      className='mb-1 me-3 pe-5 d-flex head-text fs-4'
                      style={{minWidth: '150px', fontWeight: '700'}}
                    >
                      Payment Method
                    </label>
                    <div style={{width: '-webkit-fill-available'}}>
                      <Select
                        options={cardItems}
                        styles={customStylesInputFilled}
                        isSearchable={false}
                        defaultValue={
                          paymentMethod == 0
                            ? cardItems[0]
                            : paymentMethod == 1
                            ? cardItems[1]
                            : paymentMethod == 2
                            ? cardItems[2]
                            : cardItems[3]
                        }
                        onChange={(event: any) => {
                          setPaymentMethod(event.value)
                          if (event.value == 2) getBankAccounts()
                        }}
                      />
                    </div>
                  </div>
                </>
              )}
              <div>
                <Collapse in={collapse}>
                  <div id='example-collapse-text' className=''>
                    {paymentMethod == 0 && (
                      <div className='d-flex align-items-center mt-1 justify-content-start mb-3'>
                        <i className='me-auto me-1 text-dark'>
                          Would you like to enable card verification ?
                        </i>
                        <Switch
                          onChange={(e) => {
                            if (
                              ((type == 'tenancy' || type == 'terminate-tenancy') &&
                                ((user?.isSubUser &&
                                  role?.leasing_residential?.lease_financial?.update) ||
                                  !user?.isSubUser)) ||
                              type == 'propertyProfile' ||
                              type == 'tenant' ||
                              type == 'financials'
                            )
                              setCheckVerify(e)
                            else ErrorToast(`You don't have the permissions to edit payment...`)
                          }}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          checked={checkVerify}
                          onColor='#35475e'
                          offColor='#b0bec5'
                        />{' '}
                      </div>
                    )}

                    {/* Payment Method: Bank */}
                    {paymentMethod == 2 ? (
                      <>
                        {showEditMethod && paymentMethod == 2 ? (
                          <>
                            <div className='align-items-center d-flex mb-3'>
                              <label
                                className='mb-1 me-2 pe-5 d-flex head-text'
                                style={{minWidth: '100px'}}
                              >
                                Bank Account
                              </label>
                              {bankAccountItems &&
                                bankAccountItems.map((bA: any, index: any) => {
                                  if (detailsToEdit?.paymentAccountId == bA._id) accountInd = index

                                  return <></>
                                })}
                              <div style={{width: '-webkit-fill-available'}}>
                                <Select
                                  defaultValue={{
                                    label: bankAccountItems[accountInd]?.accountHolderName,
                                    value: bankAccountItems[accountInd]?._id,
                                    id: bankAccountItems[accountInd],
                                  }}
                                  options={
                                    bankAccountItems &&
                                    bankAccountItems.map((bA: any, index: any) => {
                                      // console.log(pItem)
                                      return {
                                        label: `${bA?.accountHolderName}`,
                                        value: `${bA?._id}`,
                                        id: bA,
                                      }
                                    })
                                  }
                                  styles={
                                    selectedAccount == undefined
                                      ? customStyles2
                                      : customStylesInputFilled
                                  }
                                  isSearchable={false}
                                  onChange={(event: any) => {
                                    setShowAcc(true)
                                    setSelectedAccount(event.id)
                                  }}
                                />
                              </div>
                            </div>
                            {showAcc && (
                              <>
                                <div
                                  className='d-flex align-items-center mb-3 head-text'
                                  style={{fontWeight: '800'}}
                                >
                                  <label
                                    className='mb-1 me-1 pe-5 blue-label'
                                    style={{minWidth: '110px'}}
                                  >
                                    Account Name
                                  </label>
                                  {selectedAccount?.accountHolderName}
                                </div>
                                <div
                                  className='d-flex align-items-center my-2 head-text'
                                  style={{fontWeight: '800'}}
                                >
                                  <label
                                    className='mb-1 me-1 pe-5 blue-label'
                                    style={{minWidth: '110px'}}
                                  >
                                    IBAN
                                  </label>
                                  {selectedAccount?.IBAN}
                                </div>
                                <div
                                  className='d-flex align-items-center mb-3 head-text '
                                  style={{fontWeight: '800'}}
                                >
                                  <label
                                    className='mb-1 me-1 pe-5 blue-label'
                                    style={{minWidth: '110px'}}
                                  >
                                    Account No.
                                  </label>
                                  {selectedAccount?.accountNumber}
                                </div>
                                <div
                                  className='d-flex align-items-center mb-3 head-text'
                                  style={{fontWeight: '800'}}
                                >
                                  <label
                                    className='mb-1 me-1 pe-5 blue-label'
                                    style={{minWidth: '110px'}}
                                  >
                                    Bank Name
                                  </label>
                                  {selectedAccount?.bankName}
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div
                              className='d-flex align-items-center mb-3 head-text'
                              style={{fontWeight: '800'}}
                            >
                              <label
                                className='mb-1 me-1 pe-5 blue-label'
                                style={{minWidth: '110px'}}
                              >
                                Account Name
                              </label>
                              {selectedAccount?.accountHolderName}
                            </div>
                            <div
                              className='d-flex align-items-center my-2 head-text'
                              style={{fontWeight: '800'}}
                            >
                              <label
                                className='mb-1 me-1 pe-5 blue-label'
                                style={{minWidth: '110px'}}
                              >
                                IBAN
                              </label>
                              {selectedAccount?.IBAN}
                            </div>
                            <div
                              className='d-flex align-items-center mb-3 head-text '
                              style={{fontWeight: '800'}}
                            >
                              <label
                                className='mb-1 me-1 pe-5 blue-label'
                                style={{minWidth: '110px'}}
                              >
                                Account No.
                              </label>
                              {selectedAccount?.accountNumber}
                            </div>
                            <div
                              className='d-flex align-items-center mb-3 head-text'
                              style={{fontWeight: '800'}}
                            >
                              <label
                                className='mb-1 me-1 pe-5 blue-label'
                                style={{minWidth: '110px'}}
                              >
                                Bank Name
                              </label>
                              {selectedAccount?.bankName}
                            </div>
                          </>
                        )}
                      </>
                    ) : paymentMethod == 1 ? (
                      <>
                        {showEditMethod && paymentMethod == 1 ? (
                          <>
                            <div
                              className='d-flex align-items-center head-text'
                              style={{fontWeight: '700'}}
                              ref={amountPaidRef}
                            >
                              {showEditMethod == false ? (
                                <>
                                  <label
                                    className='mb-1 me-5 pe-3  blue-label'
                                    style={{minWidth: '100px'}}
                                  >
                                    Bank Name
                                  </label>
                                  <p style={{fontWeight: '700'}} className='mb-1'>
                                    {detailsToEdit?.chequeBankName}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <label
                                    className='mb-1 me-5 pe-3  blue-label'
                                    style={{minWidth: '100px'}}
                                  >
                                    Bank Name
                                  </label>
                                  <input
                                    type='text'
                                    className={
                                      detailsToEdit?.chequeBankName == '' ||
                                      detailsToEdit?.chequeBankName == null
                                        ? 'form-control form-control-solid-bg input-style grey-border'
                                        : 'form-control form-control-solid-bg input-style blue-border'
                                    }
                                    placeholder='Enter Bank Name'
                                    name='item_id'
                                    value={detailsToEdit?.chequeBankName}
                                    onChange={(event) => {
                                      setChequeBankName(event?.target.value)
                                      detailsToEdit.chequeBankName = event.target.value
                                    }}
                                  />
                                </>
                              )}
                            </div>

                            <div className='d-flex justify-content-center my-4 '>
                              <form
                                id='form-file-upload'
                                onDragEnter={handleDrag}
                                onSubmit={(e) => e.preventDefault()}
                                style={{height: '90px'}}
                              >
                                <label
                                  id='label-file-upload'
                                  htmlFor='input-file-upload'
                                  className={
                                    dragActive
                                      ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                      : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                                  }
                                  style={{
                                    fontSize: '16px',
                                    color: 'white',
                                  }}
                                  ref={inputRef}
                                >
                                  <div
                                    style={{
                                      height: '90px',
                                      width: '280px',
                                      border: '2px dashed #384a62',
                                      backgroundColor: '#f5f8fa',
                                    }}
                                    className=''
                                  >
                                    <div
                                      className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                                      style={{minWidth: '90px'}}
                                    >
                                      Drag & drop your document
                                    </div>
                                    <div
                                      className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                                      style={{minWidth: '90px'}}
                                    >
                                      <div
                                        className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                                        style={{
                                          fontSize: '1rem',
                                          paddingLeft: '15px',
                                          borderRadius: '7px',
                                          backgroundColor: '#35475e',
                                          boxShadow: '0px 0px 20px 0px #516176',
                                        }}
                                      >
                                        Browse Files
                                      </div>
                                    </div>
                                  </div>
                                </label>
                                <input
                                  type='file'
                                  multiple={true}
                                  onChange={handleChange}
                                  id='input-file-upload'
                                  ref={inputRef}
                                  style={{
                                    height: '90px',
                                    opacity: '-0.9',
                                    transform: 'translate(-2px, -90px)',
                                  }}
                                />
                                {dragActive && (
                                  <div
                                    id='drag-file-element'
                                    onDragEnter={handleDrag}
                                    onDragLeave={handleDrag}
                                    onDragOver={handleDrag}
                                    onDrop={handleDrop}
                                  ></div>
                                )}
                              </form>
                            </div>
                            {chequeMedia.length > 0 && (
                              <div
                                className='row align-items-center mb-2'
                                style={
                                  paymentMethod == 1
                                    ? {
                                        overflowY: 'scroll',
                                        minHeight: '96px',
                                        maxHeight: '200px',
                                        // transform: 'translate(-2px, -90px)',
                                      }
                                    : {overflowY: 'scroll', minHeight: '80px', maxHeight: '200px'}
                                }
                              >
                                {chequeMedia.map((chq: any, ind: any) => (
                                  <>
                                    <div className='img-sets mw-350px col-md-4 px-5 pt-2 ps-3'>
                                      <div className='d-flex'>
                                        <a
                                          href={`${Bucket}${chq?.image}`}
                                          target='_blank'
                                          style={{color: '#384a62'}}
                                          className='d-flex'
                                        >
                                          <img
                                            src={chequeImg}
                                            width='80'
                                            height='40'
                                            className='main_img cursor-pointer'
                                            alt='image'
                                            style={{objectFit: 'cover'}}
                                          />
                                        </a>
                                        <div className='h-0'>
                                          <img
                                            src={redCross}
                                            width='12'
                                            height='12'
                                            className='main_img cursor-pointer'
                                            style={{marginTop: '-25px', marginLeft: '5px'}}
                                            onClick={() => deleteCheque(chq)}
                                          />
                                        </div>
                                      </div>

                                      <input
                                        type='text'
                                        className='form-control form-control-solid-bg input-style mt-1 mytest'
                                        placeholder='Cheque No'
                                        name='item_id'
                                        value={chq.name}
                                        onChange={(event) => {
                                          fileToRename = chq
                                          // console.log(event)
                                          const values = [...chequeMedia]
                                          // let x = values[ind]?.src?.name?.split('.')[1]
                                          values[ind].name = event.target.value

                                          setChequeMedia(values)
                                        }}
                                        onBlur={() => {
                                          let fileToRenam = chq
                                          const values = [...chequeMedia]
                                          let x = values[ind]?.src?.name?.split('.')[1]
                                          renameCheque(fileToRenam, x)
                                        }}
                                        style={{height: '18px', fontSize: '8px', width: '80px'}}
                                      />
                                    </div>
                                  </>
                                ))}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            <div
                              className='d-flex align-items-center head-text'
                              style={{fontWeight: '700'}}
                              ref={amountPaidRef}
                            >
                              {showEditMethod == false ? (
                                <>
                                  <label
                                    className='mb-1 me-5 pe-3  blue-label'
                                    style={{minWidth: '100px'}}
                                  >
                                    Bank Name
                                  </label>
                                  <p style={{fontWeight: '700'}} className='mb-1'>
                                    {detailsToEdit?.chequeBankName}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <label
                                    className='mb-1 me-5 pe-3  blue-label'
                                    style={{minWidth: '100px'}}
                                  >
                                    Bank Name
                                  </label>
                                  <input
                                    type='text'
                                    className='form-control form-control-solid-bg input-style'
                                    placeholder='Enter Bank Name'
                                    name='item_id'
                                    value={chequeBankName}
                                    onChange={(event) => {
                                      setChequeBankName(event?.target.value)
                                    }}
                                  />
                                </>
                              )}
                            </div>

                            {detailsToEdit?.chequeImages.length > 0 && (
                              <div
                                className='row align-items-center mb-2'
                                style={
                                  paymentMethod == 1
                                    ? {
                                        overflowY: 'scroll',
                                        minHeight: '96px',
                                        maxHeight: '200px',
                                        // transform: 'translate(-2px, -90px)',
                                      }
                                    : {overflowY: 'scroll', minHeight: '80px', maxHeight: '200px'}
                                }
                              >
                                {detailsToEdit?.chequeImages.map((chq: any, ind: any) => (
                                  <>
                                    <div className='img-sets mw-350px col-md-4 px-5 pt-2 ps-3'>
                                      <div className='d-flex'>
                                        <a
                                          href={`${Bucket}${chq}`}
                                          target='_blank'
                                          style={{color: '#384a62'}}
                                          className='d-flex'
                                        >
                                          <img
                                            src={chequeImg}
                                            width='80'
                                            height='40'
                                            className='main_img cursor-pointer'
                                            alt='image'
                                            style={{objectFit: 'cover'}}
                                          />
                                        </a>
                                      </div>
                                      <p className='blue-label fs-7 text-center mb-1'>
                                        {' '}
                                        {chq.split('/')[2]}
                                      </p>
                                    </div>
                                  </>
                                ))}
                              </div>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    {showEditMethod && (
                      <Row className='mb-4 me-2'>
                        <Col md={6}>
                          <button
                            className='btn btn-sm fw-bold text-white d-flex justify-content-center align-items-center'
                            style={{
                              fontSize: '1.2rem',
                              paddingRight: '55px',
                              borderRadius: '5px',
                              backgroundColor: '#6e8aa6',
                              boxShadow: '0px 0px 20px 0px #6e8aa6',
                              width: '150px',
                              height: '34px',
                              transform: 'translate(15px, 0px)',
                            }}
                            onClick={() => updatePaymentRecord(2)}
                          >
                            <img
                              src={saveImg}
                              height='20'
                              width='20'
                              style={{
                                marginRight: '21px',
                                marginLeft: '12px',
                                // marginTop: '-8px',
                              }}
                            />{' '}
                            {'  '}
                            <p className='mb-0'>Save</p>
                          </button>
                        </Col>
                        <Col md={6} className='ps-0'>
                          <button
                            className='btn btn-sm fw-bold text-white d-flex justify-content-center align-items-center'
                            style={{
                              fontSize: '1.2rem',
                              paddingRight: '55px',
                              borderRadius: '5px',
                              backgroundColor: '#D54645',
                              boxShadow: '0px 0px 20px 0px #6e8aa6',
                              width: '150px',
                              height: '34px',
                              transform: 'translate(15px, 0px)',
                            }}
                            onClick={() => {
                              setShowEditMethod(false)
                            }}
                          >
                            <img
                              src={crossbtn}
                              height='20'
                              width='20'
                              style={{
                                marginRight: '21px',
                                marginLeft: '12px',
                                // marginTop: '-8px',
                              }}
                            />{' '}
                            {'  '}
                            <p className='mb-0'>Cancel</p>
                          </button>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Collapse>
              </div>

              {/* Invoice Collapse */}
              {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                ((user?.isSubUser &&
                  role?.leasing_residential?.lease_financial?.invoicing?.view_list) ||
                  !user?.isSubUser)) ||
                type == 'propertyProfile' ||
                type == 'tenant' ||
                type == 'financials') && (
                <Row className='py-4 mx-0' style={{borderTop: '2px dashed lightgray'}}>
                  <Col md={6} className='px-0'>
                    <h4 className='head-text' style={{fontWeight: '700'}}>
                      Invoice
                    </h4>
                  </Col>

                  <Col md={6} className='text-end px-0 d-flex'>
                    {collapseInv == false && (
                      <img
                        src={invoiceBlue}
                        alt=''
                        height='20px'
                        width='16px'
                        className='ms-5 mb-3 ms-auto me-3'
                      />
                    )}
                    <button
                      className={
                        collapseInv == true
                          ? 'btn btn-sm ms-auto collapse-btn'
                          : 'btn btn-sm collapse-btn'
                      }
                    >
                      <img
                        src={collapseInv ? up : down}
                        style={{
                          height: '20px',
                          width: '20px',
                          marginLeft: '-10px',
                          marginTop: '-16px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setcollapseInv(!collapseInv)
                        }}
                        aria-controls='example-collapse-text'
                        aria-expanded={collapse}
                      />
                    </button>
                  </Col>
                </Row>
              )}
              <div>
                <Collapse in={collapseInv}>
                  <div id='example-collapse-text' className=''>
                    <div className='d-flex'>
                      <div className='row' style={{width: '100%'}}>
                        {paymentInvoices?.length > 0 &&
                          paymentInvoices?.map((pi: any, index: any) => (
                            <>
                              {index < 6 && (
                                <div className='img-sets mw-350px col-4 mt-2 ps-2 text-center'>
                                  <div className=''>
                                    <a
                                      onClick={() => {
                                        if (
                                          ((type == 'tenancy' || type == 'terminate-tenancy') &&
                                            ((user?.isSubUser &&
                                              role?.leasing_residential?.lease_financial?.invoicing
                                                ?.view_details) ||
                                              !user?.isSubUser)) ||
                                          type == 'propertyProfile' ||
                                          type == 'tenant' ||
                                          type == 'financials'
                                        )
                                          getInvoiceById(pi?._id)
                                        else
                                          ErrorToast(
                                            `You don't have the permissions to view Invoice...`
                                          )
                                      }}
                                      // href={`${Bucket}${detailsToEdit?.invoiceURL}`}
                                      // target='_blank'
                                      style={{color: '#384a62'}}
                                      className='d-flex justify-content-center'
                                    >
                                      <img
                                        src={pi?.isVoid ? voidInvoiceImg : invoiceBlue}
                                        width='35'
                                        height='43'
                                        className='main_img cursor-pointer ms-2'
                                      />
                                      {pi?.invoiceNo == '' || pi?.invoiceNo == null ? (
                                        <div
                                          className=''
                                          style={{
                                            height: '20px',
                                            width: '20px',
                                            borderRadius: '50%',
                                            backgroundColor: '#ffc100',
                                            border: '2px solid  #516176',
                                            color: '#516176',
                                            marginTop: '-10px',
                                            marginLeft: '2px',
                                            fontWeight: '900',
                                            fontSize: '14px',
                                          }}
                                        >
                                          <p style={{marginTop: '-3px'}}>!</p>
                                        </div>
                                      ) : pi?.saveStatus == 0 ? (
                                        <div
                                          className=''
                                          style={{
                                            height: '16px',
                                            width: '16px',
                                            borderRadius: '50%',
                                            backgroundColor: '#ffc100',
                                            border: '2px solid  #516176',
                                            color: '#516176',
                                            marginTop: '-10px',
                                            marginLeft: '2px',
                                            fontWeight: '900',
                                            fontSize: '11px',
                                          }}
                                        >
                                          <p style={{marginTop: '-3px'}}>!</p>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                    </a>
                                  </div>
                                  {pi?.invoiceNo == '' || pi?.invoiceNo == null ? (
                                    <input
                                      type='text'
                                      className='form-control form-control-solid-bg input-style mt-1'
                                      placeholder=''
                                      value='-'
                                      name='item_id'
                                      style={{
                                        height: '20px',
                                        width: '80px',
                                        border: '2px solid #516176',
                                        fontSize: '12px',
                                        fontWeight: '900',
                                        textAlign: 'center',
                                      }}
                                      disabled
                                    />
                                  ) : (
                                    <p
                                      style={{
                                        height: '15px',
                                        fontSize: '0.9rem',
                                        wordBreak: 'break-all',
                                      }}
                                      className='mt-1 text-center'
                                    >
                                      {pi?.invoiceNo}{' '}
                                    </p>
                                  )}
                                </div>
                              )}
                            </>
                          ))}
                      </div>

                      {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                        ((user?.isSubUser &&
                          role?.leasing_residential?.lease_financial?.invoicing?.create) ||
                          !user?.isSubUser)) ||
                        type == 'propertyProfile' ||
                        type == 'tenant' ||
                        type == 'financials') && (
                        <div className='ms-auto mt-2' style={{transform: 'translate(0px, -15px)'}}>
                          <div
                            className='justify-content-center align-items-center ms-auto cursor-pointer'
                            style={{width: '60px'}}
                            onClick={(event) => {
                              if (detailsToEdit?.isLock)
                                ErrorToast(
                                  'This payment is linked with a tenancy termination request. Once the termination has been approved you will be able to edit this payment.'
                                )
                              else {
                                let j = 0
                                const values = [...invoiceFields]
                                for (let i = 0; i < fields?.length; i++) {
                                  if (fields[i]?.paymentSubItemStatus == 0) {
                                    values[j] = {
                                      _id: fields[i]?._id,
                                      id: fields[i]?.id,
                                      name: fields[i]?.item,
                                      amount: fields[i]?.amount,
                                      qty: fields[i]?.qty,
                                      vat: fields[i]?.vat,
                                      isLateItem: fields[i]?.isLatePaymentFeesRecord,
                                      total: fields[i]?.total,
                                      amountPaid: fields[i]?.amountPaid,
                                      vatAmount: fields[i]?.isLatePaymentFeesRecord
                                        ? (fields[i]?.amount / 100) * fields[i]?.vat
                                        : ((fields[i]?.amount * fields[i]?.qty) / 100) *
                                          fields[i]?.vat,
                                      totalWithoutVat: fields[i]?.isLatePaymentFeesRecord
                                        ? fields[i]?.amount
                                        : fields[i]?.amount * fields[i]?.qty,
                                      finalAmount: 0,
                                      isSelected: 0,
                                      data: fields[i],
                                    }
                                    values[j].finalAmount =
                                      values[j].totalWithoutVat + values[j]?.vatAmount
                                    j++
                                  }
                                }
                                setInvoiceFields(values)

                                if (values?.length > 0) {
                                  setInvoiceChequeMedia([])
                                  setInvoiceSubItemsModal(true)
                                } else
                                  ErrorToast(
                                    'Each item in the payment is listed in a specific invoice, so a new invoice cannot be generated!!!'
                                  )
                              }
                            }}
                          >
                            <div className='' style={{paddingLeft: '18px'}}>
                              {' '}
                              <img src={invoiceBlue} height='35px' width='27px' />
                            </div>

                            <p className='text-center grey-label' style={{fontSize: '0.8rem'}}>
                              <i>Generate Invoice</i>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    {paymentInvoices?.length > 6 && (
                      <Row className='mb-4'>
                        <button
                          className='btn btn-sm fw-bold ms-3 ps-2 text-white d-flex justify-content-center align-items-center'
                          style={{
                            fontSize: '1.2rem',
                            // paddingRight: '55px',
                            borderRadius: '5px',
                            backgroundColor: '#35475e',
                            boxShadow: '0px 0px 20px 0px #35475e',
                            width: '88%',
                            height: '34px',
                            transform: 'translate(15px, 0px)',
                          }}
                          onClick={() => setViewAllInvoices(true)}
                        >
                          {'  '}
                          <p className='mb-0'>View All</p>
                        </button>
                      </Row>
                    )}
                  </div>
                </Collapse>
              </div>

              {/* Receipt Collapse */}
              {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                ((user?.isSubUser &&
                  role?.leasing_residential?.lease_financial?.receipts?.view_list) ||
                  !user?.isSubUser)) ||
                type == 'propertyProfile' ||
                type == 'tenant' ||
                type == 'financials') && (
                <Row className='py-4 mx-0' style={{borderTop: '2px dashed lightgray'}}>
                  <Col md={6} className='px-0'>
                    <h4 className='head-text' style={{fontWeight: '700'}}>
                      Receipt
                    </h4>
                  </Col>
                  <Col md={6} className='text-end px-0'>
                    {collapseRec == false && (
                      <img
                        src={receiptBlue}
                        alt=''
                        height='22px'
                        width='22px'
                        className='ms-5 mb-3 ms-auto me-2'
                      />
                    )}
                    <button
                      className={
                        collapseRec == true
                          ? 'btn btn-sm ms-auto collapse-btn'
                          : 'btn btn-sm collapse-btn'
                      }
                    >
                      <img
                        src={collapseRec ? up : down}
                        height='20'
                        width='20'
                        className='cursor-pointer'
                        style={{
                          marginLeft: '-10px',
                          marginTop: '-16px',
                        }}
                        onClick={() => {
                          if (showEdit == false) {
                            setcollapseRec(!collapseRec)
                          }
                        }}
                        aria-controls='example-collapse-text'
                        aria-expanded={collapse}
                      />
                    </button>
                  </Col>
                </Row>
              )}

              <Collapse in={collapseRec}>
                <div id='example-collapse-text' className=''>
                  <div className='d-flex'>
                    <div className='row' style={{width: '100%'}}>
                      {detailsToEdit?.payment_receipt?.length > 0 &&
                        detailsToEdit?.payment_receipt.map((pm: any, index: any) => (
                          <>
                            {index < 6 && (
                              <div className='img-sets mw-350px col-4 mt-2 ps-2 text-center'>
                                <a
                                  // href={`${Bucket}${pm?.pdfURL}`}
                                  // target='_blank'
                                  onClick={() => {
                                    if (
                                      ((type == 'tenancy' || type == 'terminate-tenancy') &&
                                        ((user?.isSubUser &&
                                          role?.leasing_residential?.lease_financial?.receipts
                                            ?.view_details) ||
                                          !user?.isSubUser)) ||
                                      type == 'propertyProfile' ||
                                      type == 'tenant' ||
                                      type == 'financials'
                                    )
                                      getReceiptById(pm?._id)
                                    else
                                      ErrorToast(
                                        `You don't have the permissions to view Receipt...`
                                      )
                                  }}
                                  style={{color: '#384a62'}}
                                >
                                  <img
                                    src={receiptBlue}
                                    width='50'
                                    height='50'
                                    className='main_img cursor-pointer ms-1'
                                    style={{}}
                                  />

                                  <p
                                    style={{
                                      height: '15px',
                                      fontSize: '0.9rem',
                                      wordBreak: 'break-all',
                                    }}
                                    className='mt-1 text-center'
                                  >
                                    {pm?.receiptNo}{' '}
                                  </p>
                                </a>
                              </div>
                            )}
                          </>
                        ))}
                    </div>

                    <div className='ms-auto mt-2' style={{transform: 'translate(0px, -18px)'}}>
                      <button
                        className='btn justify-content-center align-items-center ms-auto me-5 pt-1'
                        style={{
                          width: '60px',
                          height: '100px',
                          fontStyle: 'italic',
                          fontWeight: '400',
                          fontSize: '0.8rem',
                          paddingLeft: '10px',
                        }}
                        // disabled={paidAmount === 0 || paidAmount === '' ? true : false}
                        onClick={() => {
                          if (
                            ((type == 'tenancy' || type == 'terminate-tenancy') &&
                              ((user?.isSubUser &&
                                role?.leasing_residential?.lease_financial?.receipts?.create) ||
                                !user?.isSubUser)) ||
                            type == 'propertyProfile' ||
                            type == 'tenant' ||
                            type == 'financials'
                          ) {
                            if (detailsToEdit?.isLock)
                              ErrorToast(
                                'This payment is linked with a tenancy termination request. Once the termination has been approved you will be able to edit this payment.'
                              )
                            else {
                              if (detailsToEdit?.invoiceURL == '')
                                ErrorToast('You must generate an Invoive first!!!')
                              else {
                                setInvoiceReceiptNo('')
                                setInvoiceLinkChecked(-1)
                                getAllInvoices(3)
                              }
                            }
                          } else {
                            ErrorToast(`You don't have the permissions to generate a receipt...`)
                          }
                        }}
                      >
                        <div className='' style={{paddingLeft: '17px'}}>
                          {' '}
                          <img src={receiptBlue} height={40} width={40} />
                        </div>
                        <p
                          className='text-center  grey-label'
                          style={{
                            marginLeft: '13px',
                            paddingTop: '2px',
                            fontWeight: '600',
                            fontSize: '0.8rem',
                          }}
                        >
                          Generate Receipt
                        </p>
                      </button>
                    </div>
                  </div>
                  {/* View All */}
                  {detailsToEdit?.payment_receipt?.length > 6 && (
                    <Row className='mb-4'>
                      <button
                        className='btn btn-sm fw-bold ms-3 ps-2 text-white d-flex justify-content-center align-items-center'
                        style={{
                          fontSize: '1.2rem',
                          // paddingRight: '55px',
                          borderRadius: '5px',
                          backgroundColor: '#35475e',
                          boxShadow: '0px 0px 20px 0px #35475e',
                          width: '88%',
                          height: '34px',
                          transform: 'translate(15px, 0px)',
                        }}
                        onClick={() => setViewAllReceipts(true)}
                      >
                        <p className='mb-0'>View All</p>
                      </button>
                    </Row>
                  )}
                </div>
              </Collapse>

              {/* Reminder Collapse */}
              {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                ((user?.isSubUser &&
                  role?.leasing_residential?.lease_financial?.payment_reminders?.view_list) ||
                  !user?.isSubUser)) ||
                type == 'propertyProfile' ||
                type == 'tenant' ||
                type == 'financials') && (
                <Row className='py-4 mx-0' style={{borderTop: '2px dashed lightgray'}}>
                  <Col md={6} className='px-0'>
                    <h4 className='head-text' style={{fontWeight: '700'}}>
                      Reminder
                    </h4>
                  </Col>
                  <Col md={6} className='text-end px-0'>
                    {collapseRemind == false && (
                      <img
                        src={reminderBlue}
                        alt=''
                        height={24}
                        width={24}
                        className='ms-5 mb-3 ms-auto me-1'
                      />
                    )}
                    <button
                      className={
                        collapseRemind == true
                          ? 'btn btn-sm ms-auto collapse-btn'
                          : 'btn btn-sm collapse-btn'
                      }
                    >
                      <img
                        src={collapseRemind ? up : down}
                        height={20}
                        width={20}
                        style={{
                          marginLeft: '-10px',
                          marginTop: '-16px',
                        }}
                        onClick={() => {
                          if (showEdit == false) {
                            setcollapseRemind(!collapseRemind)
                          }
                        }}
                        aria-controls='example-collapse-text'
                        aria-expanded={collapse}
                        className='cursor-pointer'
                      />
                    </button>
                  </Col>
                </Row>
              )}

              <Collapse in={collapseRemind}>
                <div id='example-collapse-text' className=''>
                  <div className='d-flex'>
                    {paymentCategory == 0 ? (
                      //Payment category -> Upcoming
                      <>
                        {schedule?.length > 0 && (
                          <ul style={{listStyle: 'initial', paddingLeft: '1.1rem'}}>
                            {schedule.map((sch: any, index: any) => (
                              <>
                                {index < 5 && (
                                  <li className='head-text' style={{fontWeight: '600'}}>
                                    <i>{moment(sch.date).format('DD.MM.YYYY')}</i>
                                  </li>
                                )}
                              </>
                            ))}
                          </ul>
                        )}
                      </>
                    ) : (
                      //Payment category -> Overdue
                      <>
                        {isEndDateChecked && scheduleStartDate != null ? (
                          <>
                            <ul
                              style={{
                                listStyle: 'initial',
                                paddingLeft: '1.1rem',
                              }}
                            >
                              <li className='head-text'>
                                <i>
                                  From{' '}
                                  <b>{moment(new Date(scheduleStartDate)).format('DD.MM.YYYY')}</b>{' '}
                                  , the Tenant will be notified on <b>{frequency}</b> basis until
                                  he/she completes the payment.
                                </i>
                              </li>
                            </ul>
                          </>
                        ) : (
                          <>
                            {generatedDates?.length > 0 && (
                              <ul
                                style={{
                                  listStyle: 'initial',
                                  paddingLeft: '1.1rem',
                                  maxHeight: '100px',
                                  overflow: 'scroll',
                                  width: '120px',
                                }}
                                className='mb-1'
                              >
                                {generatedDates.map((date: any, index: any) => (
                                  <li key={index} className='head-text'>
                                    <i>{moment(date).format('DD.MM.YYYY')}</i>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </>
                        )}
                      </>
                    )}
                    <div className='ms-auto ' style={{transform: 'translate(0px, -15px)'}}>
                      <button
                        className='btn justify-content-center align-items-center ms-auto me-5 pt-0 pb-0'
                        style={{
                          width: '60px',
                          height: '100px',
                          fontStyle: 'italic',
                          fontWeight: '400',
                          fontSize: '0.8rem',
                          paddingLeft: '12px',
                        }}
                        disabled={paidAmount >= totalAED ? true : false}
                        onClick={() => {
                          if (
                            ((type == 'tenancy' || type == 'terminate-tenancy') &&
                              ((user?.isSubUser &&
                                role?.leasing_residential?.lease_financial?.payment_reminders
                                  ?.update) ||
                                !user?.isSubUser)) ||
                            type == 'propertyProfile' ||
                            type == 'tenant' ||
                            type == 'financials'
                          ) {
                            if (detailsToEdit?.isLock)
                              ErrorToast(
                                'This payment is linked with a tenancy termination request. Once the termination has been approved you will be able to edit this payment.'
                              )
                            else {
                              if (dueDate === '')
                                ErrorToast('You must set the Payment Due Date first!!')
                              else setScheduleModal(true)
                            }
                          } else {
                            ErrorToast(`You don't have the permissions to schedule a Reminder...`)
                          }
                        }}
                      >
                        <div className='' style={{paddingLeft: '17px'}}>
                          {' '}
                          <img src={reminderBlue} height={40} width={40} />
                        </div>
                        <p className='text-center grey-label' style={{marginLeft: '13px'}}>
                          Schedule Reminder
                        </p>
                      </button>
                    </div>
                  </div>
                  {/* View All */}
                  {schedule?.length > 5 && (
                    <Row className='mb-4'>
                      <button
                        className='btn btn-sm fw-bold ms-3 ps-2 text-white d-flex justify-content-center align-items-center'
                        style={{
                          fontSize: '1.2rem',
                          // paddingRight: '55px',
                          borderRadius: '5px',
                          backgroundColor: '#35475e',
                          boxShadow: '0px 0px 20px 0px #35475e',
                          width: '88%',
                          height: '34px',
                          transform: 'translate(15px, 0px)',
                        }}
                        onClick={() => setViewAllReminders(true)}
                      >
                        <p className='mb-0'>View All</p>
                      </button>
                    </Row>
                  )}
                </div>
              </Collapse>

              {/* Refund Collapse */}
              {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                ((user?.isSubUser &&
                  role?.leasing_residential?.lease_financial?.refunds?.view_list) ||
                  !user?.isSubUser)) ||
                type == 'propertyProfile' ||
                type == 'tenant' ||
                type == 'financials') && (
                <Row className='py-4 mx-0' style={{borderTop: '2px dashed lightgray'}}>
                  <Col md={6} className='px-0'>
                    <h4 className='head-text' style={{fontWeight: '700'}}>
                      Refunds
                    </h4>
                  </Col>

                  <Col md={6} className='text-end px-0 d-flex'>
                    {collapseRefund == false && (
                      <img
                        src={refundBlue}
                        alt=''
                        height='22'
                        width='22'
                        className='ms-5 mb-3 ms-auto me-1'
                      />
                    )}
                    <button
                      className={
                        collapseRefund == true
                          ? 'btn btn-sm ms-auto collapse-btn'
                          : 'btn btn-sm collapse-btn'
                      }
                    >
                      <img
                        src={collapseRefund ? up : down}
                        style={{
                          height: '20px',
                          width: '20px',
                          marginLeft: '-10px',
                          marginTop: '-16px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (showEdit == false) {
                            setcollapseRefund(!collapseRefund)
                          }
                        }}
                        aria-controls='example-collapse-text'
                        aria-expanded={collapse}
                      />
                    </button>
                  </Col>
                </Row>
              )}
              <div>
                <Collapse in={collapseRefund}>
                  <div id='example-collapse-text' className=''>
                    <div className='d-flex'>
                      <div className='row' style={{width: '100%'}}>
                        {paymentRefunds?.length > 0 &&
                          paymentRefunds.map((rf: any, index: any) => (
                            <>
                              {index < 6 && (
                                <div className='img-sets mw-350px col-4 mt-2 ps-2 text-center'>
                                  <a
                                    // href={`${Bucket}${pm?.pdfURL}`}
                                    // target='_blank'
                                    onClick={() => {
                                      if (
                                        ((type == 'tenancy' || type == 'terminate-tenancy') &&
                                          ((user?.isSubUser &&
                                            role?.leasing_residential?.lease_financial?.refunds
                                              ?.view_details) ||
                                            !user?.isSubUser)) ||
                                        type == 'propertyProfile' ||
                                        type == 'tenant' ||
                                        type == 'financials'
                                      )
                                        getRefundById(rf?._id)
                                      else
                                        ErrorToast(
                                          `You don't have the permissions to view Refund Details...`
                                        )
                                    }}
                                    style={{color: '#384a62'}}
                                  >
                                    <img
                                      src={refundBlue}
                                      width='45'
                                      height='45'
                                      className='main_img cursor-pointer ms-1'
                                      style={{}}
                                    />

                                    <p
                                      style={{
                                        height: '15px',
                                        fontSize: '0.9rem',
                                        wordBreak: 'break-all',
                                      }}
                                      className='mt-1 text-center'
                                    >
                                      {rf?.refundNo}{' '}
                                    </p>
                                  </a>
                                </div>
                              )}
                            </>
                          ))}
                      </div>
                      {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                        ((user?.isSubUser &&
                          role?.leasing_residential?.lease_financial?.refunds?.create) ||
                          !user?.isSubUser)) ||
                        type == 'propertyProfile' ||
                        type == 'tenant' ||
                        type == 'financials') && (
                        <div className='ms-auto mt-2' style={{transform: 'translate(0px, -15px)'}}>
                          <div
                            className='justify-content-center align-items-center ms-auto cursor-pointer'
                            style={{width: '60px'}}
                            onClick={() => {
                              if (
                                ((type == 'tenancy' || type == 'terminate-tenancy') &&
                                  ((user?.isSubUser &&
                                    role?.leasing_residential?.lease_financial?.refunds?.create) ||
                                    !user?.isSubUser)) ||
                                type == 'propertyProfile' ||
                                type == 'tenant' ||
                                type == 'financials'
                              ) {
                                if (detailsToEdit?.isLock)
                                  ErrorToast(
                                    'This payment is linked with a tenancy termination request. Once the termination has been approved you will be able to edit this payment.'
                                  )
                                else {
                                  setReceiptRefundLinkChecked(-1)
                                  setInvoiceLinkChecked(-1)
                                  getAllInvoices(4)
                                  setTotalRefundAmount(0)
                                }
                              } else {
                                ErrorToast(`You don't have the permissions to issue refund...`)
                              }
                            }}
                          >
                            <div className='' style={{paddingLeft: '15px'}}>
                              {' '}
                              <img src={refundBlue} height='34px' width='34px' />
                            </div>

                            <p className='text-center grey-label' style={{fontSize: '0.8rem'}}>
                              <i>Issue Refund</i>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    {paymentRefunds?.length > 6 && (
                      <Row className='mb-4'>
                        <button
                          className='btn btn-sm fw-bold ms-3 ps-2 text-white d-flex justify-content-center align-items-center'
                          style={{
                            fontSize: '1.2rem',
                            // paddingRight: '55px',
                            borderRadius: '5px',
                            backgroundColor: '#35475e',
                            boxShadow: '0px 0px 20px 0px #35475e',
                            width: '88%',
                            height: '34px',
                            transform: 'translate(15px, 0px)',
                          }}
                          onClick={() => setViewAllRefunds(true)}
                        >
                          {'  '}
                          <p className='mb-0'>View All</p>
                        </button>
                      </Row>
                    )}
                  </div>
                </Collapse>
              </div>

              {/* Credit Note Collapse */}
              {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                ((user?.isSubUser &&
                  role?.leasing_residential?.lease_financial?.credit_notes?.view_list) ||
                  !user?.isSubUser)) ||
                type == 'propertyProfile' ||
                type == 'tenant' ||
                type == 'financials') && (
                <Row className='py-4 mx-0' style={{borderTop: '2px dashed lightgray'}}>
                  <Col md={6} className='px-0'>
                    <h4 className='head-text' style={{fontWeight: '700'}}>
                      Credit Notes
                    </h4>
                  </Col>

                  <Col md={6} className='text-end px-0 d-flex'>
                    {collapseCreditNote == false && (
                      <img
                        src={creditNoteBlue}
                        alt=''
                        height='22'
                        width='22'
                        className='ms-5 mb-3 ms-auto me-1'
                      />
                    )}
                    <button
                      className={
                        collapseCreditNote == true
                          ? 'btn btn-sm ms-auto collapse-btn'
                          : 'btn btn-sm collapse-btn'
                      }
                    >
                      <img
                        src={collapseCreditNote ? up : down}
                        style={{
                          height: '20px',
                          width: '20px',
                          marginLeft: '-10px',
                          marginTop: '-16px',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (showEdit == false) {
                            setcollapseCreditNote(!collapseCreditNote)
                          }
                        }}
                        aria-controls='example-collapse-text'
                        aria-expanded={collapse}
                      />
                    </button>
                  </Col>
                </Row>
              )}
              <div>
                <Collapse in={collapseCreditNote}>
                  <div id='example-collapse-text' className=''>
                    <div className='d-flex'>
                      <div className='row' style={{width: '100%'}}>
                        {detailsToEdit?.payment_credit_note?.length > 0 &&
                          detailsToEdit?.payment_credit_note.map((cr: any, index: any) => (
                            <>
                              {index < 6 && (
                                <div className='img-sets mw-350px col-4 mt-2 ps-2 text-center'>
                                  <a
                                    // href={`${Bucket}${pm?.pdfURL}`}
                                    // target='_blank'
                                    onClick={() => {
                                      if (
                                        ((type == 'tenancy' || type == 'terminate-tenancy') &&
                                          ((user?.isSubUser &&
                                            role?.leasing_residential?.lease_financial?.credit_notes
                                              ?.view_details) ||
                                            !user?.isSubUser)) ||
                                        type == 'propertyProfile' ||
                                        type == 'tenant' ||
                                        type == 'financials'
                                      )
                                        getCreditNoteById(cr?._id)
                                      else
                                        ErrorToast(
                                          `You don't have the permissions to view Credit Note details...`
                                        )
                                    }}
                                    style={{color: '#384a62'}}
                                  >
                                    <img
                                      src={creditNoteBlue}
                                      width='45'
                                      height='45'
                                      className='main_img cursor-pointer ms-1'
                                      style={{}}
                                    />

                                    <p
                                      style={{
                                        height: '15px',
                                        fontSize: '0.9rem',
                                        wordBreak: 'break-all',
                                      }}
                                      className='mt-1 text-center'
                                    >
                                      {cr?.creditNoteNo}{' '}
                                    </p>
                                  </a>
                                </div>
                              )}
                            </>
                          ))}
                      </div>

                      {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                        ((user?.isSubUser &&
                          role?.leasing_residential?.lease_financial?.credit_notes?.create) ||
                          !user?.isSubUser)) ||
                        type == 'propertyProfile' ||
                        type == 'tenant' ||
                        type == 'financials') && (
                        <div className='ms-auto mt-2' style={{transform: 'translate(0px, -15px)'}}>
                          <div
                            className='justify-content-center align-items-center ms-auto cursor-pointer'
                            style={{width: '60px'}}
                            onClick={() => {
                              if (
                                ((type == 'tenancy' || type == 'terminate-tenancy') &&
                                  ((user?.isSubUser &&
                                    role?.leasing_residential?.lease_financial?.credit_notes
                                      ?.create) ||
                                    !user?.isSubUser)) ||
                                type == 'propertyProfile' ||
                                type == 'tenant' ||
                                type == 'financials'
                              ) {
                                if (detailsToEdit?.isLock)
                                  ErrorToast(
                                    'This payment is linked with a tenancy termination request. Once the termination has been approved you will be able to edit this payment.'
                                  )
                                else {
                                  setCreditNoteModal(true)
                                }
                              } else {
                                ErrorToast(
                                  `You don't have the permissions to generate a Credit Note...`
                                )
                              }
                            }}
                          >
                            <div className='' style={{paddingLeft: '15px'}}>
                              {' '}
                              <img src={creditNoteBlue} height='34px' width='34px' />
                            </div>

                            <p className='text-center grey-label' style={{fontSize: '0.8rem'}}>
                              <i>Generate Credit Note</i>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    {detailsToEdit?.payment_credit_note?.length > 6 && (
                      <Row className='mb-4'>
                        <button
                          className='btn btn-sm fw-bold ms-3 ps-2 text-white d-flex justify-content-center align-items-center'
                          style={{
                            fontSize: '1.2rem',
                            // paddingRight: '55px',
                            borderRadius: '5px',
                            backgroundColor: '#35475e',
                            boxShadow: '0px 0px 20px 0px #35475e',
                            width: '88%',
                            height: '34px',
                            transform: 'translate(15px, 0px)',
                          }}
                          // onClick={() => setViewAllInvoices(true)}
                        >
                          {'  '}
                          <p className='mb-0'>View All</p>
                        </button>
                      </Row>
                    )}
                  </div>
                </Collapse>
              </div>

              {/* Debit Note Collapse */}
              {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                ((user?.isSubUser &&
                  role?.leasing_residential?.lease_financial?.debit_notes?.view_list) ||
                  !user?.isSubUser)) ||
                type == 'propertyProfile' ||
                type == 'tenant' ||
                type == 'financials') && (
                <Row className='py-4 mx-0' style={{borderTop: '2px dashed lightgray'}}>
                  <Col md={6} className='px-0'>
                    <h4 className='head-text' style={{fontWeight: '700'}}>
                      Debit Notes
                    </h4>
                  </Col>

                  <Col md={6} className='text-end px-0 d-flex'>
                    {collapseDebitNote == false && (
                      <img
                        src={debitNoteBlue}
                        alt=''
                        height={22}
                        width={22}
                        className='ms-5 mb-3 ms-auto me-1'
                      />
                    )}
                    <button
                      className={
                        collapseDebitNote == true
                          ? 'btn btn-sm ms-auto collapse-btn'
                          : 'btn btn-sm collapse-btn'
                      }
                    >
                      <img
                        className='cursor-pointer'
                        src={collapseDebitNote ? up : down}
                        height={20}
                        width={20}
                        style={{
                          marginLeft: '-10px',
                          marginTop: '-16px',
                        }}
                        onClick={() => {
                          if (showEdit == false) {
                            setcollapseDebitNote(!collapseDebitNote)
                          }
                        }}
                        aria-controls='example-collapse-text'
                        aria-expanded={collapse}
                      />
                    </button>
                  </Col>
                </Row>
              )}
              <div>
                <Collapse in={collapseDebitNote}>
                  <div id='example-collapse-text' className=''>
                    <div className='d-flex'>
                      <div className='row' style={{width: '100%'}}>
                        {paymentDebitNotes?.length > 0 &&
                          paymentDebitNotes?.map((db: any, index: any) => (
                            <>
                              {index < 6 && (
                                <div className='img-sets mw-350px col-4 mt-2 ps-2 text-center'>
                                  <a
                                    onClick={() => {
                                      if (
                                        ((type == 'tenancy' || type == 'terminate-tenancy') &&
                                          ((user?.isSubUser &&
                                            role?.leasing_residential?.lease_financial?.debit_notes
                                              ?.view_details) ||
                                            !user?.isSubUser)) ||
                                        type == 'propertyProfile' ||
                                        type == 'tenant' ||
                                        type == 'financials'
                                      )
                                        getDebitNoteById(db?._id)
                                      else
                                        ErrorToast(
                                          `You don't have the permissions to view Debit Note details...`
                                        )
                                    }}
                                    style={{color: '#384a62'}}
                                  >
                                    <img
                                      src={debitNoteBlue}
                                      width={45}
                                      height={45}
                                      className='main_img cursor-pointer ms-1'
                                      style={{}}
                                    />

                                    <p
                                      style={{
                                        height: '15px',
                                        fontSize: '0.9rem',
                                        wordBreak: 'break-all',
                                      }}
                                      className='mt-1 text-center'
                                    >
                                      {db?.debitNoteNo}{' '}
                                    </p>
                                  </a>
                                </div>
                              )}
                            </>
                          ))}
                      </div>

                      {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                        ((user?.isSubUser &&
                          role?.leasing_residential?.lease_financial?.debit_notes?.create) ||
                          !user?.isSubUser)) ||
                        type == 'propertyProfile' ||
                        type == 'tenant' ||
                        type == 'financials') && (
                        <div className='ms-auto mt-2' style={{transform: 'translate(0px, -15px)'}}>
                          <div
                            className='justify-content-center align-items-center ms-auto cursor-pointer'
                            style={{width: '60px'}}
                            onClick={() => {
                              if (
                                ((type == 'tenancy' || type == 'terminate-tenancy') &&
                                  ((user?.isSubUser &&
                                    role?.leasing_residential?.lease_financial?.debit_notes
                                      ?.create) ||
                                    !user?.isSubUser)) ||
                                type == 'propertyProfile' ||
                                type == 'tenant' ||
                                type == 'financials'
                              ) {
                                if (detailsToEdit?.isLock)
                                  ErrorToast(
                                    'This payment is linked with a tenancy termination request. Once the termination has been approved you will be able to edit this payment.'
                                  )
                                else {
                                  setInvoiceLinkChecked(-1)
                                  getAllInvoices(5)
                                }
                              } else {
                                ErrorToast(
                                  `You don't have the permissions to create a Debit Note...`
                                )
                              }
                            }}
                          >
                            <div className='' style={{paddingLeft: '15px'}}>
                              {' '}
                              <img src={debitNoteBlue} height={34} width={34} />
                            </div>

                            <p className='text-center grey-label' style={{fontSize: '0.8rem'}}>
                              <i>Generate Debit Note</i>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                    {paymentDebitNotes?.length > 6 && (
                      <Row className='mb-4'>
                        <button
                          className='btn btn-sm fw-bold ms-3 ps-2 text-white d-flex justify-content-center align-items-center'
                          style={{
                            fontSize: '1.2rem',
                            // paddingRight: '55px',
                            borderRadius: '5px',
                            backgroundColor: '#35475e',
                            boxShadow: '0px 0px 20px 0px #35475e',
                            width: '88%',
                            height: '34px',
                            transform: 'translate(15px, 0px)',
                          }}
                          onClick={() => setViewAllDebitNotes(true)}
                        >
                          {'  '}
                          <p className='mb-0'>View All</p>
                        </button>
                      </Row>
                    )}
                  </div>
                </Collapse>
              </div>

              {/* Attachments Collpase */}
              {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                ((user?.isSubUser &&
                  role?.leasing_residential?.lease_financial?.attachments?.view_list) ||
                  !user?.isSubUser)) ||
                type == 'propertyProfile' ||
                type == 'tenant' ||
                type == 'financials') && (
                <Row className='py-4 mx-0' style={{borderTop: '2px dashed lightgray'}}>
                  <Col md={6} className='px-0'>
                    <h4 className='head-text' style={{fontWeight: '700'}}>
                      Attachments
                    </h4>
                  </Col>
                  {showEditAttach ? (
                    <Col md={6} className='text-end px-0'></Col>
                  ) : (
                    <Col md={6} className='text-end px-0'>
                      {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                        ((user?.isSubUser && role?.leasing_residential?.attachments?.update) ||
                          !user?.isSubUser)) ||
                        type == 'propertyProfile' ||
                        type == 'tenant' ||
                        type == 'financials') && (
                        <img
                          src={editpen}
                          alt=''
                          className='me-2 cursor-pointer'
                          height={18}
                          width={18}
                          style={{
                            marginTop: '2px',
                          }}
                          onClick={() => {
                            if (detailsToEdit?.isLock)
                              ErrorToast(
                                'This payment is linked with a tenancy termination request. Once the termination has been approved you will be able to edit this payment.'
                              )
                            else {
                              if (showEdit == false) {
                                setShowEditAttach(true)
                                setcollapseAttach(true)
                              }
                            }
                          }}
                        />
                      )}
                      {attachedMedia?.length > 0 && (
                        <>
                          <button className='btn btn-sm collapse-btn'>
                            <img
                              src={collapseAttach ? up : down}
                              height={20}
                              width={20}
                              style={{
                                marginLeft: '-10px',
                                marginTop: '-16px',
                              }}
                              onClick={() => {
                                if (showEdit == false) {
                                  setcollapseAttach(!collapseAttach)
                                }
                              }}
                              aria-controls='example-collapse-text-attach'
                              aria-expanded={collapseAttach}
                              className='cursor-pointer'
                            />
                          </button>
                        </>
                      )}
                    </Col>
                  )}
                </Row>
              )}

              <Collapse in={collapseAttach}>
                <div>
                  <div id='example-collapse-text-attach' className=''>
                    {showEditAttach == true ? (
                      <>
                        <div className='d-flex justify-content-center my-4 '>
                          <form
                            id='form-file-upload'
                            onDragEnter={handleDrag}
                            onSubmit={(e) => e.preventDefault()}
                            style={{height: '90px'}}
                          >
                            <input
                              type='file'
                              multiple={true}
                              onChange={handleAttachmentChange}
                              id='mediaAttach'
                              style={{
                                height: '90px',
                                opacity: '-0.9',
                                position: 'relative',
                                zIndex: '1',
                              }}
                            />

                            <label
                              id='label-file-upload-attach'
                              htmlFor='mediaAttach'
                              style={{
                                fontSize: '16px',
                                color: 'white',
                                transform: 'translate(0px, -90px)',
                              }}
                              className={
                                dragActive
                                  ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                  : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                              }
                            >
                              <div
                                style={{
                                  height: '90px',
                                  width: '280px',
                                  border: '2px dashed #384a62',
                                  backgroundColor: '#f5f8fa',
                                }}
                                className=''
                              >
                                <div
                                  className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                                  style={{minWidth: '90px'}}
                                >
                                  Drag & drop your document
                                </div>
                                <div
                                  className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                                  style={{minWidth: '90px'}}
                                >
                                  <div
                                    className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                                    style={{
                                      fontSize: '1rem',
                                      paddingLeft: '15px',
                                      borderRadius: '7px',
                                      backgroundColor: '#35475e',
                                      boxShadow: '0px 0px 20px 0px #516176',
                                    }}
                                  >
                                    Browse Files
                                  </div>
                                </div>
                              </div>
                            </label>

                            {dragActive && (
                              <div
                                id='drag-file-element'
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              ></div>
                            )}
                          </form>
                        </div>
                        {attachedMedia?.length > 0 && (
                          <div
                            className='row align-items-center my-2'
                            style={{
                              overflowY: 'scroll',
                              minHeight: '96px',
                              maxHeight: '200px',
                            }}
                          >
                            {attachedMedia.map((chq: any, ind: any) => {
                              let fileName: any
                              let temp = chq?.src.split('.')[1]
                              let x = chq?.src?.split('/')[2]
                              fileName = x + '.' + temp
                              return (
                                <>
                                  <div className='img-sets mw-350px col-md-4 px-2 py-3'>
                                    <div className='d-flex'>
                                      <a
                                        href={
                                          ((type == 'tenancy' || type == 'terminate-tenancy') &&
                                            ((user?.isSubUser &&
                                              role?.leasing_residential?.attachments
                                                ?.view_details) ||
                                              !user?.isSubUser)) ||
                                          type == 'propertyProfile' ||
                                          type == 'tenant' ||
                                          type == 'financials'
                                            ? `${Bucket}${chq?.src}`
                                            : `${Bucket}aaa`
                                        }
                                        target='_blank'
                                        style={{color: '#384a62'}}
                                      >
                                        <img
                                          src={file}
                                          width={42}
                                          height={55}
                                          className='main_img cursor-pointer'
                                          alt='image'
                                          style={{marginLeft: '26px'}}
                                        />
                                      </a>
                                      {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                                        ((user?.isSubUser &&
                                          role?.leasing_residential?.attachments?.delete) ||
                                          !user?.isSubUser)) ||
                                        type == 'propertyProfile' ||
                                        type == 'tenant' ||
                                        type == 'financials') && (
                                        <div className='h-0'>
                                          <img
                                            src={redCross}
                                            width={12}
                                            height={12}
                                            className='main_img cursor-pointer'
                                            style={{marginTop: '-25px', marginLeft: '2px'}}
                                            onClick={() => deleteAttachment(chq)}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <p
                                      style={{
                                        fontSize: '12px',
                                        wordBreak: 'break-all',
                                        padding: '0px 5px',
                                      }}
                                      className='mt-1'
                                    >
                                      {fileName}
                                    </p>
                                  </div>
                                </>
                              )
                            })}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {attachedMedia?.length > 0 && (
                          <>
                            <div
                              className='row align-items-center my-2'
                              style={{
                                // transform: 'translate(-2px, -180px)',
                                // overflowY: 'scroll',
                                minHeight: '96px',
                                // maxHeight: '230px',
                              }}
                            >
                              {attachedMedia.map((chq: any, ind: any) => {
                                let fileName: any
                                let temp = chq?.src?.split('.')[1]
                                let x = chq?.src?.split('/')[2]
                                fileName = x + '.' + temp
                                return (
                                  <>
                                    {ind < 6 && (
                                      <>
                                        <div className='img-sets mw-350px col-md-4 px-2 py-3'>
                                          <div className='d-flex'>
                                            <a
                                              href={
                                                ((type == 'tenancy' ||
                                                  type == 'terminate-tenancy') &&
                                                  ((user?.isSubUser &&
                                                    role?.leasing_residential?.attachments
                                                      ?.view_details) ||
                                                    !user?.isSubUser)) ||
                                                type == 'propertyProfile' ||
                                                type == 'tenant' ||
                                                type == 'financials'
                                                  ? `${Bucket}${chq?.src}`
                                                  : `${Bucket}aaa`
                                              }
                                              target='_blank'
                                              style={{color: '#384a62'}}
                                            >
                                              <img
                                                src={file}
                                                width='42px'
                                                height='55px'
                                                className='main_img cursor-pointer'
                                                alt='image'
                                                style={{marginLeft: '26px'}}
                                              />
                                            </a>
                                          </div>

                                          <p
                                            style={{
                                              fontSize: '12px',
                                              wordBreak: 'break-all',
                                              padding: '0px 5px',
                                            }}
                                            className='mt-1'
                                          >
                                            {fileName}
                                          </p>
                                        </div>
                                      </>
                                    )}
                                  </>
                                )
                              })}
                            </div>
                            {/* View All */}
                            {attachedMedia?.length > 6 && (
                              <Row className='mb-4'>
                                <button
                                  className='btn btn-sm fw-bold ms-3 ps-2 text-white d-flex justify-content-center align-items-center'
                                  style={{
                                    fontSize: '1.2rem',
                                    // paddingRight: '55px',
                                    borderRadius: '5px',
                                    backgroundColor: '#35475e',
                                    boxShadow: '0px 0px 20px 0px #35475e',
                                    width: '88%',
                                    height: '34px',
                                    transform: 'translate(15px, 0px)',
                                  }}
                                  onClick={() => setViewAllAttachments(true)}
                                >
                                  <p className='mb-0'>View All</p>
                                </button>
                              </Row>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  {showEditAttach && (
                    <Row className=' mb-4 me-2'>
                      <Col md={6}>
                        <button
                          className='btn btn-sm fw-bold text-white d-flex justify-content-center align-items-center'
                          style={{
                            fontSize: '1.2rem',
                            paddingRight: '55px',
                            borderRadius: '5px',
                            backgroundColor: '#6e8aa6',
                            boxShadow: '0px 0px 20px 0px #6e8aa6',
                            width: '150px',
                            height: '34px',
                            transform: 'translate(15px, 0px)',
                          }}
                          onClick={() => updatePaymentRecord(3)}
                        >
                          <img
                            src={saveImg}
                            style={{
                              height: '20px',
                              width: '20px',
                              marginRight: '21px',
                              marginLeft: '12px',
                              // marginTop: '-8px',
                            }}
                          />{' '}
                          {'  '}
                          <p className='mb-0'>Save</p>
                        </button>
                      </Col>
                      <Col md={6} className='ps-0'>
                        <button
                          className='btn btn-sm fw-bold text-white d-flex justify-content-center align-items-center'
                          style={{
                            fontSize: '1.2rem',
                            paddingRight: '55px',
                            borderRadius: '5px',
                            backgroundColor: '#D54645',
                            boxShadow: '0px 0px 20px 0px #6e8aa6',
                            width: '150px',
                            height: '34px',
                            transform: 'translate(15px, 0px)',
                          }}
                          onClick={() => {
                            setShowEditAttach(false)
                          }}
                        >
                          <img
                            src={crossbtn}
                            height='20'
                            width='20'
                            style={{
                              marginRight: '21px',
                              marginLeft: '12px',
                              // marginTop: '-8px',
                            }}
                          />{' '}
                          {'  '}
                          <p className='mb-0'>Cancel</p>
                        </button>
                      </Col>
                    </Row>
                  )}
                </div>
              </Collapse>

              {/* Notes */}
              {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                ((user?.isSubUser &&
                  role?.leasing_residential?.lease_financial?.notes?.view_list) ||
                  !user?.isSubUser)) ||
                type == 'propertyProfile' ||
                type == 'tenant' ||
                type == 'financials') && (
                <div
                  className='my-2 pt-3 mb-5'
                  style={{
                    borderTop: '2px dashed lightgray',
                  }}
                >
                  <div className='d-flex'>
                    <label className='head-text fs-4 mb-2' style={{fontWeight: '700'}}>
                      Notes
                    </label>
                    {showEditNote == false && (
                      <>
                        {(((type == 'tenancy' || type == 'terminate-tenancy') &&
                          ((user?.isSubUser &&
                            role?.leasing_residential?.lease_financial?.notes?.update) ||
                            !user?.isSubUser)) ||
                          type == 'propertyProfile' ||
                          type == 'tenant' ||
                          type == 'financials') && (
                          <img
                            src={editpen}
                            alt=''
                            height={18}
                            width={18}
                            className='me-2 ms-auto cursor-pointer'
                            style={{
                              marginTop: '2px',
                            }}
                            onClick={() => {
                              if (detailsToEdit?.isLock)
                                ErrorToast(
                                  'This payment is linked with a tenancy termination request. Once the termination has been approved you will be able to edit this payment.'
                                )
                              else {
                                if (showEdit == false) {
                                  setShowEditNote(true)
                                }
                              }
                            }}
                          />
                        )}
                      </>
                    )}
                  </div>

                  {showEditNote ? (
                    <>
                      {notes != '' ? (
                        <textarea
                          className='form-control  form-control-solid-bg py-2 input-style mytest'
                          name='comment'
                          value={notes}
                          onChange={(e) => {
                            setNotes(e.target.value)
                            const target = e.target

                            // Get the `maxlength` attribute
                            const maxLength = target.getAttribute('maxlength')
                            // Count the current number of characters
                            const currentLength = target.value.length

                            setNotesCount(`${currentLength}/${maxLength}`)
                          }}
                          style={{height: '100px'}}
                          maxLength={1000}
                        ></textarea>
                      ) : (
                        <textarea
                          className='form-control form-control-solid-bg py-2 input-style'
                          name='comment'
                          value={notes}
                          onChange={(e) => {
                            setNotes(e.target.value)
                            const target = e.target

                            // Get the `maxlength` attribute
                            const maxLength = target.getAttribute('maxlength')
                            // Count the current number of characters
                            const currentLength = target.value.length

                            setNotesCount(`${currentLength}/${maxLength}`)
                          }}
                          style={{height: '100px'}}
                          maxLength={1000}
                        ></textarea>
                      )}
                      <div className='d-flex align-items-center justify-content-end mb-2'>
                        <label className='mb-1 blue-label fs-7'>{notesCount}</label>
                      </div>

                      <Row className='mb-4 me-2'>
                        <Col md={6}>
                          <button
                            className='btn btn-sm fw-bold text-white d-flex justify-content-center align-items-center'
                            style={{
                              fontSize: '1.2rem',
                              paddingRight: '55px',
                              borderRadius: '5px',
                              backgroundColor: '#6e8aa6',
                              boxShadow: '0px 0px 20px 0px #6e8aa6',
                              width: '150px',
                              height: '34px',
                              transform: 'translate(15px, 0px)',
                            }}
                            onClick={() => updatePaymentRecord(4)}
                          >
                            <img
                              src={saveImg}
                              style={{
                                height: '20px',
                                width: '20px',
                                marginRight: '21px',
                                marginLeft: '12px',
                                // marginTop: '-8px',
                              }}
                            />{' '}
                            {'  '}
                            <p className='mb-0'>Save</p>
                          </button>
                        </Col>{' '}
                        <Col md={6} className='ps-0'>
                          <button
                            className='btn btn-sm fw-bold text-white d-flex justify-content-center align-items-center'
                            style={{
                              fontSize: '1.2rem',
                              paddingRight: '55px',
                              borderRadius: '5px',
                              backgroundColor: '#D54645',
                              boxShadow: '0px 0px 20px 0px #6e8aa6',
                              width: '150px',
                              height: '34px',
                              transform: 'translate(15px, 0px)',
                            }}
                            onClick={() => {
                              setShowEditNote(false)
                            }}
                          >
                            <img
                              src={crossbtn}
                              height='20'
                              width='20'
                              style={{
                                marginRight: '21px',
                                marginLeft: '12px',
                                // marginTop: '-8px',
                              }}
                            />{' '}
                            {'  '}
                            <p className='mb-0'>Cancel</p>
                          </button>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <div
                      className='form-control  form-control-solid-bg py-2 input-style bg-white border-white head-text p-3'
                      style={{minHeight: '100px', borderRadius: '10px'}}
                    >
                      {notes}
                    </div>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </div>
      </Modal>

      {/* Invoice Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={invoiceModal}
        onHide={() => {
          setInvoiceModal(false)
          // setInvoiceView(false)
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 invoice-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Invoice</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => {
                  setInvoiceModal(false)
                  // setInvoiceView(false)
                }}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='invoice-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '80px'}}>
              <img src={invoiceWhite} height='80px' width='65px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body px-lg-10'
          style={{
            maxHeight: '600px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
            marginBottom: '-100px',
          }}
        >
          {/* <div className='d-flex my-2 pt-4'> */}
          <Row className='mt-2'>
            <Col md={8}>
              {/* {invoiceView ? (
                <>
                  <a
                    className='btn btn-sm fw-bold ps-2 pe-4 text-white d-block'
                    style={{width: '140px', backgroundColor: '#008081'}}
                    href={`${Bucket}${detailsToEdit?.invoiceURL}`}
                    target='_blank'
                  >
                    <img
                      src={download}
                      style={{height: '18px', width: '18px', marginRight: '3px', marginTop: '-2px'}}
                    />{' '}
                    Download
                  </a>
                  <button
                    className='btn btn-sm fw-bold ps-1 pe-4 mt-2'
                    style={{
                      width: '140px',
                      backgroundColor: '#ff0000',
                      color: 'white',
                      fontSize: '13px',
                    }}
                    onClick={() => {
                      setRecipientsModal(true)
                    }}
                  >
                    <img
                      src={share}
                      style={{
                        height: '18px',
                        width: '18px',
                        marginRight: '12px',
                        marginTop: '-2px',
                      }}
                    />{' '}
                    {'  '}
                    Share
                  </button>
                </>
              ) : ( */}
              <button
                className='btn btn-sm fw-bold ps-2 pe-4 text-white'
                style={{width: '140px', backgroundColor: '#008081'}}
                onClick={() => {
                  if (invoiceNo == '' || invoiceNo == undefined) setInvoiceNoError(true)
                  else {
                    for (let i = 0; i < fields?.length; i++) {
                      if (fields[i]?.removeHistoryIDs?.length > 0) {
                        const body = {
                          latePaymentItemHistoryIds: fields[i]?.removeHistoryIDs,
                        }

                        ApiPost(`corporate/late_payment_item_history/delete`, body)
                          .then((res) => {})
                          .catch(() => {})
                      }
                    }

                    updatePaymentRecord(1)
                    // setInvoiceModal(false)
                    //   if (detailsToEdit?.invoiceURL != '') {
                    //     Swal.fire({
                    //       html: '<p class="text-center m-0" style="font-size: 16px"> Are you sure you want to generate a new invoice. Generating a new invoice will replace the existing invoice. </p>',
                    //       showConfirmButton: true,
                    //       confirmButtonColor: '#008081',
                    //       confirmButtonText: 'Replace',
                    //       showCancelButton: true,
                    //       cancelButtonText: 'Cancel',
                    //     }).then((res) => {
                    //       if (res.isConfirmed) {
                    //         generateInvoice(2)
                    //       }
                    //     })
                    //   } else generateInvoice(2)
                  }
                }}
                // disabled={isInvChanged ? true : false}
              >
                {/* <img
                  src={submitImg}
                  style={{height: '20px', width: '20px', marginRight: '7px'}}
                />{' '} */}
                {'  '}
                Generate
              </button>
              {/* )} */}
              {/* {invoiceView ? (
                <div className='d-flex mb-3 mt-2 '>
                  <label
                    htmlFor='media'
                    className='btn btn-sm fw-bold form-label m-0 upload text-center pb-5'
                    style={{
                      // height: '50px',
                      width: '140px',
                      backgroundColor: '#8a8aa3',
                      fontSize: '16px',
                      // paddingLeft: '40px',
                      color: 'white',
                    }}
                  >
                    <img />
                    <b
                      style={{
                        fontSize: '22px',
                        display: 'block',
                        marginTop: '-25px',
                        fontWeight: '700',
                        marginBottom: '5px',
                      }}
                    >
                      LOGO
                    </b>
                    Click to upload
                  </label>

                  <input
                    type='file'
                    hidden
                    id='media'
                    className='form-control form-control-solid'
                    name='passport'
                    // onChange={(e: any) => onFilesAddedSingle(e)}
                  />
                </div>
              ) : ( */}
              <div className='d-flex my-4 '>
                {paymentInvoiceDetails?.invoiceLogo && (
                  <img
                    src={`${Bucket}${paymentInvoiceDetails?.invoiceLogo}`}
                    height='140'
                    width='140'
                    style={{borderRadius: '7px'}}
                  />
                )}
                {/* <label
                  htmlFor='media'
                  className='btn btn-sm fw-bold form-label m-0 upload text-center pb-5'
                  style={{
                    // height: '50px',
                    width: '150px',
                    backgroundColor: '#8a8aa3',
                    fontSize: '16px',
                    // paddingLeft: '40px',
                    color: 'white',
                  }}
                >
                  <img />
                  <b
                    style={{
                      fontSize: '22px',
                      display: 'block',
                      marginTop: '-15px',
                      fontWeight: '700',
                      marginBottom: '12px',
                    }}
                  >
                    LOGO
                  </b>
                  Click to upload (Optional)
                </label>

                <input
                  type='file'
                  hidden
                  id='media'
                  className='form-control form-control-solid'
                  name='passport'
                  // onChange={(e: any) => onFilesAddedSingle(e)}
                /> */}
              </div>
              {/* )} */}
            </Col>
            <Col md={4} className='pe-4'>
              <div className='d-flex align-items-center my-2'>
                <label
                  className='mb-1 me-5 pe-5 ms-auto head-text'
                  style={{minWidth: '50px', maxWidth: '50px'}}
                >
                  <b>TRN</b>
                </label>
                <b>
                  {paymentInvoiceDetails?.invoiceCompany?.trn
                    ? paymentInvoiceDetails?.invoiceCompany?.trn
                    : '-'}
                </b>
              </div>
              <div className='d-flex align-items-center my-2 '>
                <h1 className='ms-auto'>INVOICE</h1>
                {invoiceView == false && (
                  <img
                    src={editpen}
                    alt=''
                    className='ms-2'
                    style={{cursor: 'pointer', height: '14px', width: '14px', marginTop: '-25px'}}
                    // onClick={() => {
                    // }}
                  />
                )}
              </div>

              {/*  */}
              <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px', fontSize: '1rem'}}>
                  Invoice No.
                </label>
                {/* {invoiceView ? (
                  <div className='ms-auto' style={{fontWeight: '500'}}>
                    {' '}
                    <p style={{fontWeight: '800', fontSize: '1rem'}} className='mb-1'>
                      {invoiceNo}
                    </p>
                  </div>
                ) : ( */}
                <input
                  type='text'
                  className={
                    invoiceNoError
                      ? 'form-control form-control-solid-bg input-style border-danger'
                      : 'form-control form-control-solid-bg input-style mytest'
                  }
                  // className='form-control form-control-solid-bg input-style'
                  placeholder='Enter Invoice No'
                  name='item_id'
                  value={invoiceNo}
                  onChange={(event) => {
                    setInvoiceNo(event.target.value)
                    setInvoiceNoError(false)
                    if (isUpdatedPaymentInvoice) updatePaymentInv[0].invoiceNo = event.target.value

                    if (isNewPaymentInvoice) newPaymentInv[0].invoiceNo = event.target.value
                  }}
                  // disabled={detailsToEdit?.invoiceURL != '' ? true : false}
                />
                {/* )} */}
              </div>

              {/*  */}
              <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                <label className='mb-1 me-5 pe-5' style={{minWidth: '100px', fontSize: '1rem'}}>
                  Invoice Date
                </label>
                <div className='ms-auto' style={{fontWeight: '500'}}>
                  <p style={{fontWeight: '800', fontSize: '1rem'}} className='mb-1'>
                    {moment(paymentInvoiceDetails?.date).format('DD-MM-YYYY')}
                  </p>
                </div>
              </div>

              <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                <label className='mb-1 me-5 pe-5  ' style={{minWidth: '100px', fontSize: '1rem'}}>
                  Due Date
                </label>
                <div className='ms-auto' style={{fontWeight: '500'}}>
                  <p style={{fontWeight: '800', fontSize: '1rem'}} className='mb-1'>
                    {dueDateToShow}
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row className='mt-5 pb-5' style={{borderBottom: '2px dashed lightgray'}}>
            <Col md={6} style={{fontWeight: '600'}}>
              <div className='d-flex align-items-center'>
                <h5 style={{fontWeight: '700'}}>Recipient</h5>
                {/* {invoiceView == false && (
                  <button
                    className='btn btn-sm fw-bold  mx-3 ps-1 pe-4'
                    style={{
                      width: '125px',
                      backgroundColor: '#ff0000',
                      color: 'white',
                      fontSize: '13px',
                    }}
                    onClick={() => sentInvoiceToTenant()}
                    disabled={invoiceAdded === true ? false : true}
                  >
                    <img src={send} style={{height: '20px', width: '20px', marginRight: '3px'}} />{' '}
                    {'  '}
                    Send Invoice
                  </button>
                )} */}
              </div>
              <p style={{marginBottom: '0.1rem'}}>
                {tenantDetails?.firstName} {tenantDetails?.lastName}
              </p>
              <p style={{marginBottom: '0.1rem'}}>
                {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
              </p>
              <p style={{marginBottom: '0.1rem'}}>{tenantDetails?.email}</p>
            </Col>
            <Col md={6} className='text-end' style={{fontWeight: '600'}}>
              <p style={{marginBottom: '0.1rem'}}>{paymentInvoiceDetails?.invoiceCompany?.name}</p>
              <p style={{marginBottom: '0.1rem'}}>
                {paymentInvoiceDetails?.invoiceCompany?.address}
              </p>

              <p style={{marginBottom: '0.1rem'}}>
                {paymentInvoiceDetails?.invoiceCompany?.countryCode}{' '}
                {paymentInvoiceDetails?.invoiceCompany?.phoneNumber}
              </p>
              <p style={{marginBottom: '0.1rem'}}>{paymentInvoiceDetails?.invoiceCompany?.email}</p>
            </Col>
          </Row>

          <table
            className='table align-middle fs-6 gy-5 mt-3 pb-5'
            id='kt_ecommerce_sales_table'
            style={{borderBottom: '1.5px dashed lightgray'}}
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-50px'>#</th>
                <th className='min-w-100px'>Item</th>
                <th className='min-w-100px'>Qty</th>
                <th className='min-w-100px'>Rate</th>
                <th className='min-w-100px'>Vat</th>
                <th className='min-w-100px'>Amount</th>
              </tr>
            </thead>
            <tbody style={{fontWeight: '500'}}>
              {paymentInvoiceDetails?.paymentSubItems?.map((field: any, index: any) => {
                // let y: any = ((field.amount * field.qty) / 100) * field.vat
                // let total: number = parseInt(y) + field.amount * field.qty
                return (
                  <tr style={{borderBottom: '1px solid lightgray', height: '45px'}}>
                    <td className='py-2'>{index + 1}</td>
                    <td className='py-2'>{field.name}</td>
                    <td className='py-2'>{field.quantity}</td>
                    <td className='py-2'>
                      {' '}
                      {field?.isLatePaymentFeesRecord
                        ? (field.amount / field?.quantity)?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })
                        : field.amount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                    </td>
                    <td className='py-2'>
                      {' '}
                      {field?.isLatePaymentFeesRecord
                        ? ((field.amount / 100) * field.VATPercentage)?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })
                        : (
                            ((field.amount * field.quantity) / 100) *
                            field.VATPercentage
                          )?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                    </td>
                    <td className='py-2'>
                      {field?.totalAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                    </td>
                  </tr>
                )
              })}

              <>
                {paymentInvoiceDetails?.paymentSubItems?.map((f: any, ind: any) => {
                  vatAmount = 0
                  nonVat = 0
                  return <></>
                })}
              </>

              {paymentInvoiceDetails?.paymentSubItems?.map((f: any, ind: any) => {
                if (f?.isLatePaymentFeesRecord) {
                  vatAmount = vatAmount + (f.amount / 100) * f.VATPercentage
                  nonVat = nonVat + f.amount
                } else {
                  vatAmount = vatAmount + ((f.amount * f.quantity) / 100) * f.VATPercentage
                  nonVat = nonVat + f.amount * f.quantity
                }
                return <></>
              })}

              <tr style={{height: '55px'}}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5' style={{color: '#B5B5C3'}}>
                  SUB TOTAL
                </td>
                <td className='py-2'>
                  {nonVat?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                </td>
              </tr>

              <tr style={{height: '30px'}}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5' style={{color: '#B5B5C3'}}>
                  VAT
                </td>
                <td className='py-2'>
                  {vatAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                </td>
              </tr>
              <tr style={{height: '55px'}} className='pb-4'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5'>Total</td>
                <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                  {' '}
                  <b>
                    AED {(nonVat + vatAmount)?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>

          <Row>
            <Col md={7}>
              <div className='row my-2 mb-5'>
                <div className='col-md-6'>
                  <label className='mb-1 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Payment Method
                  </label>
                </div>
                <div className='col-md-6 text-center'>
                  <label
                    className='mb-1 me-1 fs-6 pt-1'
                    style={{minWidth: '160px', fontWeight: '500'}}
                  >
                    {paymentMethod == 0
                      ? 'Card'
                      : paymentMethod == 1
                      ? 'Cheque'
                      : paymentMethod == 2
                      ? 'Bank'
                      : 'Cash'}
                  </label>
                </div>
              </div>
              {paymentMethod == 2 && (
                <>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '100px', fontWeight: '500'}}
                    >
                      Account Name
                    </label>
                    <b> {selectedAccount?.accountHolderName}</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      IBAN
                    </label>
                    <b> {selectedAccount?.IBAN}</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      Account No.
                    </label>
                    <b> {selectedAccount?.accountNumber}</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      Bank Name
                    </label>
                    <b> {selectedAccount?.bankName}</b>
                  </div>
                </>
              )}

              {paymentMethod == 1 && (
                <>
                  {chequeMedia.length > 0 && (
                    <div
                      className='row align-items-center my-2'
                      style={
                        paymentMethod == 1
                          ? {
                              overflowY: 'scroll',
                              minHeight: '96px',
                              maxHeight: '200px',
                              // transform: 'translate(-2px, -90px)',
                            }
                          : {overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}
                      }
                    >
                      {chequeMedia.map((chq: any, ind: any) => (
                        <>
                          <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                            <div className='d-flex'>
                              <a
                                href={`${Bucket}${chq?.image}`}
                                target='_blank'
                                style={{color: '#384a62'}}
                                className='d-flex'
                              >
                                <img
                                  src={chequeImg}
                                  width='80px'
                                  height='40px'
                                  className='main_img cursor-pointer'
                                  alt='image'
                                  style={{objectFit: 'cover'}}
                                />
                              </a>
                            </div>
                            <p className='fs-7 ps-1'>{chq.name}</p>
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </>
              )}
            </Col>
            <Col md={5}>
              {/* Notes */}
              <div className='my-2 mb-5 h-10'>
                <label className='mb-4 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                  Notes
                </label>
                {invoiceView ? (
                  <p style={{marginBottom: '0.1rem'}}>{invoiceNotes}</p>
                ) : (
                  <textarea
                    className='form-control form-control-solid-bg input-style mytest'
                    name='comment'
                    placeholder='Enter any additional notes to the recipient'
                    style={{height: '130px', borderColor: 'lightgray'}}
                    value={invoiceNotes}
                    onChange={(e) => {
                      setInvoiceNotes(e.target.value)

                      if (isUpdatedPaymentInvoice) updatePaymentInv[0].note = e.target.value

                      if (isNewPaymentInvoice) newPaymentInv[0].note = e.target.value
                    }}
                    maxLength={1000}
                  ></textarea>
                )}
              </div>

              {invoiceView == false && (
                <>
                  <div className='d-flex my-2 pt-4'>
                    <label
                      className='mb-1 me-1 fs-4'
                      style={{minWidth: '160px', fontWeight: '700'}}
                    >
                      Attachments
                    </label>
                  </div>

                  <div className='d-flex justify-content-center my-4 '>
                    <form
                      id='form-file-upload-invoice'
                      onDragEnter={handleDrag}
                      onSubmit={(e) => e.preventDefault()}
                      style={{height: '90px'}}
                    >
                      <input
                        type='file'
                        multiple={true}
                        onChange={handleInvoiceAttachmentChange}
                        id='mediaAttachInvoice'
                        style={{height: '90px', opacity: '-0.9', position: 'relative', zIndex: '1'}}
                      />

                      <label
                        htmlFor='mediaAttachInvoice'
                        className={
                          dragActive
                            ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                            : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                        }
                        style={{
                          fontSize: '16px',
                          color: 'white',
                          transform: 'translate(-2px, -90px)',
                        }}
                      >
                        <div
                          style={{
                            height: '90px',
                            width: '280px',
                            border: '2px dashed #384a62',
                            backgroundColor: '#f5f8fa',
                          }}
                          className=''
                        >
                          <div
                            className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                            style={{minWidth: '90px'}}
                          >
                            Drag & drop your document
                          </div>
                          <div
                            className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                            style={{minWidth: '90px'}}
                          >
                            <div
                              className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                              style={{
                                fontSize: '1rem',
                                paddingLeft: '15px',
                                borderRadius: '7px',
                                backgroundColor: '#35475e',
                                boxShadow: '0px 0px 20px 0px #516176',
                              }}
                            >
                              Browse Files
                            </div>
                          </div>
                        </div>
                      </label>
                      {dragActive && (
                        <div
                          id='drag-file-element'
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        ></div>
                      )}
                    </form>
                  </div>
                  {invoiceAttachedMedia.length > 0 && (
                    <div
                      className='row align-items-center mt-2'
                      // style={{overflowY: 'scroll', minHeight: '96px', maxHeight: '200px',transform: 'translate(0px, -90px)', marginBottom: '-90px'}}
                    >
                      {invoiceAttachedMedia.map((chq: any, ind: any) => (
                        <>
                          <div className='img-sets mw-350px col-md-4 px-2 py-3'>
                            <div className='d-flex'>
                              <a
                                href={`${Bucket}${chq?.src}`}
                                target='_blank'
                                style={{color: '#384a62'}}
                                className='d-flex'
                              >
                                <img
                                  src={file}
                                  width='42px'
                                  height='55px'
                                  className='main_img cursor-pointer'
                                  alt='image'
                                  style={{marginLeft: '26px'}}
                                />
                              </a>
                              <div className='h-0'>
                                <img
                                  src={redCross}
                                  width='12px'
                                  height='12px'
                                  className='main_img cursor-pointer'
                                  style={{marginTop: '-25px', marginLeft: '-3px'}}
                                  onClick={() => deleteInvoiceAttachment(chq)}
                                />
                              </div>
                            </div>

                            <p
                              style={{fontSize: '12px', wordBreak: 'break-all', padding: '0px 5px'}}
                              className='mt-1'
                            >
                              {chq.name}{' '}
                            </p>
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>

          {/* </div> */}
        </div>
      </Modal>

      {/*  Receipt Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={receiptModal}
        onHide={() => {
          setReceiptModal(false)
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 receipt-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Receipt</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => setReceiptModal(false)}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>
        <div className='receipt-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '15px'}}>
              <img src={receiptWhite} height='80px' width='85px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>

        <div
          className='modal-body px-lg-10'
          style={{
            height: '600px',
            transform: 'translate(0px, -94px)',
            maxHeight: '600px',
            overflow: 'scroll',
            marginBottom: '-94px',
          }}
        >
          <div className='d-flex align-items-center justify-content-center my-2'>
            <div
              className='btn btn-sm fw-bold  ps-2 pe-4 text-white'
              style={{width: '275px', backgroundColor: '#2e9a64'}}
              onClick={() => {
                if (invRecDetails?.receiptSequence?.activityStatus == 0) {
                  if (receiptNo == '' || receiptNo == undefined) setReceiptNoError(true)
                  else {
                    if (datePaid == null) setDatePaidError(true)
                    else if (receiptPaymentMethod == 1) {
                      if (receiptChequeBankName == '') {
                        ErrorToast(`Please enter Bank Name!!!`)
                      } else if (receiptChequeMedia?.length <= 0) {
                        ErrorToast(`Please Upload atleast one Cheque!!!`)
                      } else {
                        Swal.fire({
                          html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any prevoius Receipt? </p>',
                          showConfirmButton: true,
                          confirmButtonColor: '#2e9a64',
                          confirmButtonText: 'Upload',
                          showCancelButton: true,
                          cancelButtonText: 'Continue',
                        }).then((res) => {
                          if (res.isConfirmed) {
                            setUploadInvoiceModal(true)
                            setIsReceipt(true)
                            //  setInvoiceModal(false)
                          }
                          if (res.isDismissed) {
                            setReceiptModal(false)
                            setReceiptLinkModal(false)
                            setReceiptAdded(true)
                            setIsReceiptManualPdf(false)
                            generateReceipt()
                          }
                        })
                      }
                    } else if (receiptPaymentMethod == 2) {
                      if (selectedAccountReceipt == null) {
                        ErrorToast(`Please select Payment Acccount!!!`)
                      } else {
                        Swal.fire({
                          html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any prevoius Receipt? </p>',
                          showConfirmButton: true,
                          confirmButtonColor: '#2e9a64',
                          confirmButtonText: 'Upload',
                          showCancelButton: true,
                          cancelButtonText: 'Continue',
                        }).then((res) => {
                          if (res.isConfirmed) {
                            setUploadInvoiceModal(true)
                            setIsReceipt(true)
                            //  setInvoiceModal(false)
                          }
                          if (res.isDismissed) {
                            setReceiptModal(false)
                            setReceiptLinkModal(false)
                            setReceiptAdded(true)
                            setIsReceiptManualPdf(false)
                            generateReceipt()
                          }
                        })
                      }
                    } else {
                      Swal.fire({
                        html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any prevoius Receipt ? </p>',
                        showConfirmButton: true,
                        confirmButtonColor: '#2e9a64',
                        confirmButtonText: 'Upload',
                        showCancelButton: true,
                        cancelButtonText: 'Continue',
                      }).then((res) => {
                        if (res.isConfirmed) {
                          setUploadInvoiceModal(true)
                          setIsReceipt(true)
                          //  setInvoiceModal(false)
                        }
                        if (res.isDismissed) {
                          setReceiptModal(false)
                          setReceiptLinkModal(false)
                          setReceiptAdded(true)
                          setIsReceiptManualPdf(false)
                          generateReceipt()
                        }
                      })

                      // setReceiptModal(false)
                      // setReceiptLinkModal(false)
                      // setReceiptAdded(true)
                    }
                  }
                } else {
                  if (datePaid == null) setDatePaidError(true)
                  else if (receiptPaymentMethod == 1) {
                    if (receiptChequeBankName == '') {
                      ErrorToast(`Please enter Bank Name!!!`)
                    } else if (receiptChequeMedia?.length <= 0) {
                      ErrorToast(`Please Upload atleast one Cheque!!!`)
                    } else {
                      Swal.fire({
                        html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any prevoius Receipt? </p>',
                        showConfirmButton: true,
                        confirmButtonColor: '#2e9a64',
                        confirmButtonText: 'Upload',
                        showCancelButton: true,
                        cancelButtonText: 'Continue',
                      }).then((res) => {
                        if (res.isConfirmed) {
                          setUploadInvoiceModal(true)
                          setIsReceipt(true)
                          //  setInvoiceModal(false)
                        }
                        if (res.isDismissed) {
                          setReceiptModal(false)
                          setReceiptLinkModal(false)
                          setReceiptAdded(true)
                          setIsReceiptManualPdf(false)
                          generateReceipt()
                        }
                      })
                    }
                  } else if (receiptPaymentMethod == 2) {
                    if (selectedAccountReceipt == null) {
                      ErrorToast(`Please select Payment Acccount!!!`)
                    } else {
                      Swal.fire({
                        html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any prevoius Receipt? </p>',
                        showConfirmButton: true,
                        confirmButtonColor: '#2e9a64',
                        confirmButtonText: 'Upload',
                        showCancelButton: true,
                        cancelButtonText: 'Continue',
                      }).then((res) => {
                        if (res.isConfirmed) {
                          setUploadInvoiceModal(true)
                          setIsReceipt(true)
                          //  setInvoiceModal(false)
                        }
                        if (res.isDismissed) {
                          setReceiptModal(false)
                          setReceiptLinkModal(false)
                          setReceiptAdded(true)
                          setIsReceiptManualPdf(false)
                          generateReceipt()
                        }
                      })
                    }
                  } else {
                    Swal.fire({
                      html: '<p class="text-center m-0" style="font-size: 16px"> Do you wish to upload any prevoius Receipt ? </p>',
                      showConfirmButton: true,
                      confirmButtonColor: '#2e9a64',
                      confirmButtonText: 'Upload',
                      showCancelButton: true,
                      cancelButtonText: 'Continue',
                    }).then((res) => {
                      if (res.isConfirmed) {
                        setUploadInvoiceModal(true)
                        setIsReceipt(true)
                        //  setInvoiceModal(false)
                      }
                      if (res.isDismissed) {
                        setReceiptModal(false)
                        setReceiptLinkModal(false)
                        setReceiptAdded(true)
                        setIsReceiptManualPdf(false)
                        generateReceipt()
                      }
                    })

                    // setReceiptModal(false)
                    // setReceiptLinkModal(false)
                    // setReceiptAdded(true)
                  }
                }
              }}
            >
              Generate
            </div>
          </div>
          <div className='d-flex align-items-center justify-content-center my-2'>
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
              Invoice No.
            </label>

            <a
              style={{textDecoration: 'underline', color: 'dodgerblue'}}
              href={`${Bucket}${invoiceReceiptURL}`}
              target='_blank'
            >
              {invoiceReceiptNo}
            </a>
          </div>

          <div className='d-flex align-items-center justify-content-center my-2 '>
            <h1 className=''>RECEIPT</h1>
          </div>

          <div className='d-flex align-items-center justify-content-center my-2'>
            {invRecDetails?.receiptSequence?.activityStatus == 1 ? (
              <>
                {invRecDetails?.receiptSequence?.prefix != null && (
                  <>
                    <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
                      Receipt No.
                    </label>

                    <b> {invRecDetails?.receiptSequence?.prefix}</b>
                  </>
                )}
              </>
            ) : (
              <input
                type='text'
                className={
                  receiptNoError
                    ? 'form-control form-control-solid-bg w-25 input-style border-danger'
                    : 'form-control form-control-solid-bg w-25 input-style mytest'
                }
                placeholder='Receipt No'
                name='item_id'
                value={receiptNo}
                onChange={(event) => {
                  setReceiptNo(event.target.value)
                  setReceiptNoError(false)
                }}
                maxLength={15}
              />
            )}
          </div>

          <div
            className='d-flex align-items-center justify-content-center my-2 mt-4 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>
              {receiptCompany?.name != null && receiptCompany?.name}
            </p>
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-2 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>
              {receiptCompany?.address != null && receiptCompany?.address}
            </p>{' '}
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-2 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>
              {receiptCompany?.phoneNumber != null &&
                receiptCompany?.countryCode + ' ' + receiptCompany?.phoneNumber}
            </p>{' '}
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-2 fs-5'
            style={{fontWeight: '600'}}
          >
            <p style={{marginBottom: '0.1rem'}}>
              {receiptCompany?.email != null && receiptCompany?.email}
            </p>{' '}
          </div>
          <div
            className='d-flex align-items-center justify-content-center my-5 fs-3'
            style={{fontWeight: '600'}}
          >
            <label className='me-5 pe-5 ' style={{minWidth: '50px'}}>
              Total
            </label>

            <b style={{fontWeight: '700'}} className='fs-3'>
              {/* AED {parseFloat(totalAED) - parseFloat(detailsToEdit?.amountPaid)} */}
              AED {outstandingAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
            </b>
          </div>

          <div className='row mt-3'>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5 d-flex align-items-center head-text'>
              <label className='mb-1 me-5 head-text' style={{minWidth: '90px'}}>
                Amount Paid
              </label>
              {paidAmountReceipt.toLocaleString('en-US', {minimumFractionDigits: 2})}
              <img
                src={editpen}
                alt=''
                height='18'
                width='18'
                className='mb-3 cursor-pointer'
                style={{marginLeft: '50px', marginTop: '6px', cursor: 'pointer'}}
                onClick={() => {
                  setReceiptModal(false)
                  setReceiptSubItemsModal(true)
                }}
              />
            </div>
          </div>
          {/* <div className='d-flex align-items-center justify-content-center my-5'>
            <div className='mx-auto'>
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
              Amount Paid
            </label>
            <b>{paidAmount}</b>
          </div>
          </div> */}
          <div className='row mt-3'>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5 d-flex align-items-center head-text'>
              <label className='mb-1 me-5 head-text' style={{minWidth: '90px'}}>
                Date Paid
              </label>
              <div style={{width: '-webkit-fill-available'}}>
                <DatePicker
                  onChange={handleDatePaidChange}
                  disabled={paidAmountReceipt == 0 || paidAmountReceipt == '' ? true : false}
                  // className={datePaidError ? 'border-danger' : ''}
                  className={datePaid != null ? 'mytest' : datePaidError ? 'border-danger' : ''}
                  format={'DD-MM-YYYY'}
                />
              </div>
              {/* {datePaid} */}
            </div>
          </div>

          {/* <div className='d-flex align-items-center justify-content-center my-5'>
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
              Date Paid
            </label>
            {datePaid}
          </div> */}

          <div className='row mt-3'>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5 d-flex align-items-center head-text'>
              <label className='mb-1 me-5 head-text' style={{minWidth: 'fit-content'}}>
                Payment Method
              </label>
              <div style={{width: '-webkit-fill-available'}}>
                <Select
                  options={cardItems}
                  styles={customStylesInputFilled2}
                  isSearchable={false}
                  defaultValue={
                    receiptPaymentMethod == 0
                      ? cardItems[0]
                      : receiptPaymentMethod == 1
                      ? cardItems[1]
                      : receiptPaymentMethod == 2
                      ? cardItems[2]
                      : cardItems[3]
                  }
                  onChange={(event: any) => {
                    setReceiptPaymentMethod(event.value)
                    if (event.value == 2) getBankAccounts()
                    else {
                      setShowAccReceipt(false)
                      setSelectedAccountReceipt(null)
                    }
                    // updatePayment(invoiceUrl, event.value)
                  }}
                />
              </div>
            </div>
          </div>

          <div className='row mt-3'>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5'>
              {/* Payment Method: Bank */}
              {receiptPaymentMethod == 2 ? (
                <>
                  <div className='align-items-center d-flex mb-3'>
                    <label
                      className='mb-1 me-2 pe-5 d-flex head-text'
                      style={{minWidth: 'fit-content'}}
                    >
                      Bank Account
                    </label>

                    {bankAccountItems &&
                      bankAccountItems.map((bA: any, index: any) => {
                        if (selectedAccountReceipt?._id == bA._id) accountInd = index
                        return <></>
                      })}

                    <div style={{width: '-webkit-fill-available'}}>
                      {accountInd != null ? (
                        <Select
                          defaultValue={{
                            label: bankAccountItems[accountInd]?.accountHolderName,
                            value: bankAccountItems[accountInd]?._id,
                            id: bankAccountItems[accountInd],
                          }}
                          // bankAccountItems[accountInd]}
                          options={
                            bankAccountItems &&
                            bankAccountItems.map((bA: any, index: any) => {
                              // console.log(pItem)
                              return {
                                label: `${bA?.accountHolderName}`,
                                value: `${bA?._id}`,
                                id: bA,
                              }
                            })
                          }
                          styles={customStylesInputFilled2}
                          isSearchable={false}
                          onChange={(event: any) => {
                            setShowAccReceipt(true)
                            setSelectedAccountReceipt(event.id)
                          }}
                        />
                      ) : (
                        <Select
                          // defaultValue={{
                          //   label: bankAccountItems[accountInd]?.accountHolderName,
                          //   value: bankAccountItems[accountInd]?._id,
                          //   id: bankAccountItems[accountInd],
                          // }}
                          // bankAccountItems[accountInd]}
                          options={
                            bankAccountItems &&
                            bankAccountItems.map((bA: any, index: any) => {
                              // console.log(pItem)
                              return {
                                label: `${bA?.accountHolderName}`,
                                value: `${bA?._id}`,
                                id: bA,
                              }
                            })
                          }
                          styles={customStyles2}
                          isSearchable={false}
                          onChange={(event: any) => {
                            setShowAccReceipt(true)
                            setSelectedAccountReceipt(event.id)
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {showAccReceipt && (
                    <>
                      <div
                        className='d-flex align-items-center mb-3 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          Account Name
                        </label>
                        {selectedAccountReceipt?.accountHolderName}
                      </div>
                      <div
                        className='d-flex align-items-center my-2 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          IBAN
                        </label>
                        {selectedAccountReceipt?.IBAN}
                      </div>
                      <div
                        className='d-flex align-items-center mb-3 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          Account No.
                        </label>
                        {selectedAccountReceipt?.accountNumber}
                      </div>
                      <div
                        className='d-flex align-items-center mb-3 head-text'
                        style={{fontWeight: '800'}}
                      >
                        <label
                          className='mb-1 me-1 pe-5'
                          style={{minWidth: '110px', fontWeight: '600'}}
                        >
                          Bank Name
                        </label>
                        {selectedAccountReceipt?.bankName}
                      </div>
                    </>
                  )}
                </>
              ) : receiptPaymentMethod == 1 ? (
                <>
                  <div className='d-flex align-items-center justify-content-center mb-2'>
                    <label className='mb-1 me-5 pe-5  blue-label' style={{minWidth: 'fit-content'}}>
                      Bank Name
                    </label>

                    <input
                      type='text'
                      className='form-control form-control-solid-bg input-style mytest'
                      placeholder='Enter Name'
                      name='item_id'
                      value={receiptChequeBankName}
                      onChange={(event) => {
                        setReceiptChequeBankName(event?.target.value)
                      }}
                    />
                  </div>

                  <div className='d-flex justify-content-center my-4 '>
                    <form
                      id='form-file-upload'
                      onDragEnter={handleDrag}
                      onSubmit={(e) => e.preventDefault()}
                      style={{height: '90px', marginLeft: '26px'}}
                    >
                      <label
                        id='label-file-upload'
                        htmlFor='input-file-upload'
                        className={
                          dragActive
                            ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                            : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                        }
                        style={{
                          fontSize: '16px',
                          color: 'white',
                          // transform: 'translate(-2px, -90px)',
                        }}
                        ref={inputRef}
                      >
                        <div
                          style={{
                            height: '90px',
                            width: '280px',
                            border: '2px dashed #384a62',
                            backgroundColor: '#f5f8fa',
                          }}
                          className=''
                        >
                          <div
                            className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                            style={{minWidth: '90px'}}
                          >
                            Drag & drop your document
                          </div>
                          <div
                            className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                            style={{minWidth: '90px'}}
                          >
                            <div
                              className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                              style={{
                                fontSize: '1rem',
                                paddingLeft: '15px',
                                borderRadius: '7px',
                                backgroundColor: '#35475e',
                                boxShadow: '0px 0px 20px 0px #516176',
                              }}
                            >
                              Browse Files
                            </div>
                          </div>
                        </div>
                      </label>
                      <input
                        type='file'
                        multiple={true}
                        onChange={handleReceiptChequeChange}
                        id='input-file-upload'
                        ref={inputRef}
                        style={{
                          height: '90px',
                          opacity: '-0.9',
                          transform: 'translate(-2px, -90px)',
                        }}
                      />
                      {dragActive && (
                        <div
                          id='drag-file-element'
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        ></div>
                      )}
                    </form>
                  </div>
                  {receiptChequeMedia.length > 0 && (
                    <div
                      className='row align-items-center my-2'
                      style={
                        receiptPaymentMethod == 1
                          ? {
                              overflowY: 'scroll',
                              minHeight: '96px',
                              maxHeight: '200px',
                              // transform: 'translate(-2px, -90px)',
                            }
                          : {overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}
                      }
                    >
                      {receiptChequeMedia.map((chq: any, ind: any) => (
                        <>
                          <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                            <div className='d-flex'>
                              <a
                                href={`${Bucket}${chq?.image}`}
                                target='_blank'
                                style={{color: '#384a62'}}
                                className='d-flex'
                              >
                                <img
                                  src={chequeImg}
                                  width='80px'
                                  height='40px'
                                  className='main_img cursor-pointer'
                                  alt='image'
                                  style={{objectFit: 'cover'}}
                                />
                              </a>
                              <div className='h-0'>
                                <img
                                  src={redCross}
                                  width='12px'
                                  height='12px'
                                  className='main_img cursor-pointer'
                                  style={{marginTop: '-25px', marginLeft: '5px'}}
                                  onClick={() => deleteChequeReceipt(chq)}
                                />
                              </div>
                            </div>
                            <input
                              type='text'
                              className='form-control form-control-solid-bg input-style mt-1 mytest'
                              placeholder='Cheque No'
                              name='item_id'
                              value={chq.name}
                              onChange={(event) => {
                                fileToRename = chq
                                // console.log(event)
                                const values = [...receiptChequeMedia]
                                // let x = values[ind]?.src?.name?.split('.')[1]
                                values[ind].name = event.target.value

                                setReceiptChequeMedia(values)
                              }}
                              onBlur={() => {
                                let fileToRenam = chq
                                const values = [...receiptChequeMedia]
                                let x = values[ind]?.src?.name?.split('.')[1]
                                renameChequeReceipt(fileToRenam, x)
                              }}
                              style={{height: '18px', fontSize: '8px', width: '80px'}}
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div className='row '>
            <div className='col-md-3'></div>
            <div className='col-md-6 ps-5 fs-5'>
              <label className='mb-1 me-5 pe-2  head-text' style={{minWidth: '150px'}}>
                Outstanding Amount
              </label>

              {(outstandingAmount - paidAmountReceipt)?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
              })}
            </div>
          </div>

          {/* <div className='d-flex align-items-center justify-content-center my-5'>
            <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px'}}>
              Outstanding Amount
            </label>

            <b> {totalAED - paidAmount}</b>
          </div> */}

          <div className='align-items-center justify-content-center my-2 text-center'>
            <p
              className='grey-label mx-auto mt-3'
              style={{borderBottom: '2px dashed lightgray', width: '60%'}}
            ></p>
          </div>

          <div className='d-flex align-items-center justify-content-center my-5'>
            <label className='head-text fs-4' style={{fontWeight: '700'}}>
              Attachments
            </label>
          </div>

          <div className='d-flex justify-content-center my-4 '>
            <form
              id='form-file-upload-receipt'
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
              style={{height: '90px'}}
            >
              <label
                htmlFor='mediaAttachReceipt'
                className={
                  dragActive
                    ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                    : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                }
                style={{
                  fontSize: '16px',
                  color: 'white',
                  // transform: 'translate(-2px, -90px)',
                }}
              >
                <div
                  style={{
                    height: '90px',
                    width: '280px',
                    border: '2px dashed #384a62',
                    backgroundColor: '#f5f8fa',
                  }}
                  className=''
                >
                  <div
                    className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                    style={{minWidth: '90px'}}
                  >
                    Drag & drop your document
                  </div>
                  <div
                    className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                    style={{minWidth: '90px'}}
                  >
                    <div
                      className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                      style={{
                        fontSize: '1rem',
                        paddingLeft: '15px',
                        borderRadius: '7px',
                        backgroundColor: '#35475e',
                        boxShadow: '0px 0px 20px 0px #516176',
                      }}
                    >
                      Browse Files
                    </div>
                  </div>
                </div>
              </label>
              <input
                type='file'
                multiple={true}
                onChange={handleReceiptAttachmentChange}
                id='mediaAttachReceipt'
                style={{height: '90px', opacity: '-0.9', transform: 'translate(-2px, -90px)'}}
              />
              {dragActive && (
                <div
                  id='drag-file-element'
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
          </div>
          <div className='row'>
            <div className='col-3'></div>
            <div className='col-6'>
              {receiptAttachedMedia.length > 0 && (
                <div className='row align-items-center my-2'>
                  {receiptAttachedMedia.map((chq: any, ind: any) => (
                    <>
                      <div className='img-sets mw-350px col-md-4 px-2 py-3'>
                        <div className='d-flex'>
                          <a
                            href={`${Bucket}${chq?.src}`}
                            target='_blank'
                            style={{color: '#384a62'}}
                            className='d-flex'
                          >
                            <img
                              src={file}
                              width='42px'
                              height='55px'
                              className='main_img cursor-pointer'
                              alt='image'
                              style={{marginLeft: '26px'}}
                            />
                          </a>
                          <div className='h-0'>
                            <img
                              src={redCross}
                              width='12px'
                              height='12px'
                              className='main_img cursor-pointer'
                              style={{marginTop: '-25px', marginLeft: '-3px'}}
                              onClick={() => deleteReceiptAttachment(chq)}
                            />
                          </div>
                        </div>

                        <p
                          style={{fontSize: '12px', wordBreak: 'break-all', padding: '0px 5px'}}
                          className='mt-1'
                        >
                          {chq.name}{' '}
                        </p>
                      </div>
                    </>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/*  Schedule Reminder Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto'
        show={scheduleModal}
        onHide={() => {
          setScheduleModal(false)
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 '
          style={{
            zIndex: '1',
            opacity: '0.85',
            borderBottom: '0px',
            minHeight: '95px',
            backgroundColor: ' #0098cd',
          }}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Reminders</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => setScheduleModal(false)}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>
        <div className='schedule-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-1' style={{paddingLeft: '60px'}}>
              <img src={reminderWhite} height='90px' width='90px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        {/* Upcoming Payment */}
        {paymentCategory == 0 ? (
          <div
            className='modal-body py-lg-10 px-lg-10 pt-lg-10'
            style={{transform: 'translate(0px, -80px)', height: '500px'}}
          >
            <Row className=''>
              <Col lg={4} md={4} className='align-items-center pe-5 mb-5'>
                <label className='required mb-2 label-color'>Days before payment is due</label>
              </Col>
              <Col lg={4} className='align-items-center ' style={{paddingLeft: '70px'}}>
                <label className='mb-2 label-color'>Reminder Schedule</label>
              </Col>
              <Col lg={4} md={4} className='align-items-center text-end'>
                <button
                  className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                  style={{
                    fontSize: '1.2rem',
                    paddingRight: '55px',
                    borderRadius: '2px',
                    backgroundColor: '#35475e',
                    boxShadow: '0px 0px 20px 0px #516176',
                  }}
                  onClick={() => {
                    setScheduleAdded(true)
                    setScheduleModal(false)
                  }}
                >
                  <img
                    src={reminderWhite}
                    style={{
                      height: '20px',
                      width: '20px',
                      marginRight: '15px',
                      marginLeft: '4px',
                      marginTop: '-3px',
                    }}
                  />{' '}
                  {'  '}
                  Schedule
                </button>
              </Col>
            </Row>
            <div
              className=''
              style={{maxHeight: '480px', overflowY: 'scroll', overflowX: 'hidden'}}
            >
              {schedule.map((sch: any, index: any) => {
                return (
                  <Row>
                    <Col lg={4} md={4} className='align-items-center pe-5 mb-5'>
                      {/* <label className='required mb-2 label-color'>Days after payment is due</label> */}
                      <input
                        type='text'
                        className='form-control form-control-solid-bg mytest'
                        placeholder='Enter Days'
                        name='item_id'
                        value={sch.days}
                        onChange={(e) => {
                          const values = [...schedule]
                          values[index].days = e.target.value
                          setSchedule(values)
                        }}
                        onBlur={(e) => handleSchedule(e, index)}
                        style={{
                          border: '0.1rem solid #bec3cb',
                          borderRadius: '2px',
                          padding: '0.4rem 1rem',
                          width: '80%',
                        }}
                        disabled={new Date(sch.date) <= new Date() ? true : false}
                      />
                    </Col>
                    <Col
                      lg={4}
                      className='align-items-center mb-0 mt-2'
                      style={{paddingLeft: '70px'}}
                    >
                      <div className='d-flex align-items-center'>
                        {sch.date ? moment(new Date(sch.date)).format('DD-MM-YYYY') : ''}
                        {new Date(sch.date) > new Date() && (
                          <img
                            src={redCross}
                            width='16px'
                            height='16px'
                            className='main_img cursor-pointer ms-auto'
                            onClick={() => handleScheduleRemoveFields(index, sch.id)}
                          />
                        )}
                      </div>
                    </Col>
                    <Col lg={4} md={4} className='align-items-center text-end'></Col>
                  </Row>
                )
              })}

              <div
                className='d-flex align-items-center  my-2 cursor-pointer'
                onClick={() => handleScheduleAddFields()}
              >
                <img
                  src={plusBlue}
                  style={{
                    height: '16px',
                    width: '16px',
                  }}
                />
                <p className='head-text ms-3 mb-0'>
                  {' '}
                  <i>Add another reminder</i>
                </p>
              </div>
            </div>
          </div>
        ) : (
          // Overdue Payment
          <div
            className='modal-body py-lg-10 px-lg-10 pt-lg-5'
            style={{transform: 'translate(0px, -80px)', height: '400px'}}
          >
            <Row className='mt-3'>
              <Col lg={3} md={3} className='align-items-center pe-5 mb-2'>
                <label className='mt-3 label-color'>Start Date</label>
              </Col>
              <Col lg={5} className='align-items-center ps-5 ' style={{paddingLeft: '70px'}}>
                <label className='mt-3 label-color'>End Date</label>
              </Col>
              <Col lg={4} md={4} className='align-items-center text-end'>
                <button
                  className='btn btn-sm fw-bold ms-3 ps-2 text-white'
                  style={{
                    fontSize: '1.2rem',
                    paddingRight: '55px',
                    borderRadius: '2px',
                    backgroundColor: '#35475e',
                    boxShadow: '0px 0px 20px 0px #516176',
                  }}
                  onClick={() => {
                    console.log('isEndDateChecked ' + isEndDateChecked)
                    if (isEndDateChecked == true) setScheduleEndDate(null)
                    updatePaymentRecord(7)
                  }}
                >
                  <img
                    src={reminderWhite}
                    style={{
                      height: '20px',
                      width: '20px',
                      marginRight: '15px',
                      marginLeft: '4px',
                      marginTop: '-3px',
                    }}
                  />{' '}
                  {'  '}
                  Schedule
                </button>
              </Col>
            </Row>

            <Row className='mt-3'>
              <Col lg={3} md={3} className='align-items-center pe-5 mb-5'>
                {/* <label className='required mb-2 label-color'>Days after payment is due</label> */}
                {scheduleStartDate != null ? (
                  <DatePicker
                    defaultValue={moment(new Date(scheduleStartDate), 'DD-MM-YYYY')}
                    value={moment(new Date(scheduleStartDate), 'DD-MM-YYYY')}
                    onChange={handleStartDateChange}
                    className={
                      scheduleStartDate != null ? 'mytest' : datePaidError ? 'border-danger' : ''
                    }
                    disabledDate={disabledDate}
                    format={'DD-MM-YYYY'}
                  />
                ) : (
                  <DatePicker
                    onChange={handleStartDateChange}
                    className={
                      scheduleStartDate != null ? 'mytest' : datePaidError ? 'border-danger' : ''
                    }
                    disabledDate={disabledDate}
                    format={'DD-MM-YYYY'}
                  />
                )}
              </Col>
              <Col lg={3} className='align-items-center mb-0 ps-5' style={{paddingLeft: '70px'}}>
                {scheduleEndDate != null ? (
                  <DatePicker
                    defaultValue={moment(new Date(scheduleEndDate), 'DD-MM-YYYY')}
                    value={moment(new Date(scheduleEndDate), 'DD-MM-YYYY')}
                    onChange={handleEndDateChange}
                    className={
                      scheduleEndDate != null ? 'mytest' : datePaidError ? 'border-danger w-75' : ''
                    }
                    disabled={isEndDateChecked}
                    disabledDate={disabledDate}
                    format={'DD-MM-YYYY'}
                  />
                ) : (
                  <DatePicker
                    onChange={handleEndDateChange}
                    className={
                      scheduleEndDate != null ? 'mytest' : datePaidError ? 'border-danger w-75' : ''
                    }
                    disabled={isEndDateChecked}
                    disabledDate={disabledDate}
                    format={'DD-MM-YYYY'}
                  />
                )}
              </Col>
              <Col lg={6} md={6} className='align-items-center '>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value=''
                    id='schedule-check'
                    style={{cursor: 'pointer', marginTop: '-6px'}}
                    defaultChecked={isEndDateChecked}
                    onChange={(e) => {
                      console.log(e)
                      setIsEndDateChecked(e.target.checked)
                    }}

                    // onChange={(e) => selectedUnits(i?._id, f?._id, e)}
                  />
                  <label className='head-text  px-3'>
                    Keep sending reminder, until the Tenant completes the payment
                  </label>
                </div>
              </Col>
            </Row>

            {scheduleStartDate != null && (scheduleEndDate != null || isEndDateChecked) && (
              <div className='row'>
                <div className='col-md-3'>
                  <label className='mb-2 label-color'>Frequency</label>
                  {frequency == null ? (
                    <Select
                      options={isEndDateChecked ? optionsChecked : options}
                      styles={customStyles2}
                      isSearchable={false}
                      onChange={(event) => {
                        calculateDates(event)
                      }}
                    />
                  ) : (
                    <>
                      {isEndDateChecked ? (
                        <Select
                          defaultValue={
                            frequency == 'Daily'
                              ? optionsChecked[0]
                              : frequency == 'Weekly'
                              ? optionsChecked[1]
                              : optionsChecked[2]
                          }
                          options={optionsChecked}
                          styles={customStylesInputFilled2}
                          isSearchable={false}
                          onChange={(event) => {
                            calculateDates(event)
                          }}
                        />
                      ) : (
                        <Select
                          defaultValue={
                            frequency == 'Once'
                              ? options[0]
                              : frequency == 'Daily'
                              ? options[1]
                              : frequency == 'Weekly'
                              ? options[2]
                              : options[3]
                          }
                          options={options}
                          styles={customStylesInputFilled2}
                          isSearchable={false}
                          onChange={(event) => {
                            calculateDates(event)
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            {isEndDateChecked == false && (
              <>
                {generatedDatesUpdated?.length > 0 &&
                  scheduleStartDate != null &&
                  scheduleEndDate != null && (
                    <ul
                      style={{
                        listStyle: 'initial',
                        paddingLeft: '1.1rem',
                        maxHeight: '260px',
                        overflow: 'scroll',
                      }}
                      className='mt-5'
                    >
                      {generatedDatesUpdated.map((date: any, index: any) => (
                        <li key={index} className='head-text'>
                          {moment(date).format('DD.MM.YYYY')}
                        </li>
                      ))}
                    </ul>
                  )}
              </>
            )}
          </div>
        )}
      </Modal>

      {/* View All Invoices */}
      <ViewAllInvoices
        show={viewAllInvoices}
        handleClose={() => {
          setViewAllInvoices(false)
        }}
        invoices={paymentInvoices}
        dueDate={detailsToEdit?.dueDate}
        paymentId={detailsToEdit?._id}
        tenantDetails={tenantDetails}
        paymentMethod={paymentMethod}
        selectedAccount={selectedAccount}
        chequeMedia={chequeMedia}
        getPaymentInvoices={getPaymentById}
        invRecDetails={invRecDetails}
        from={type}
      />

      {/* View Reciepts*/}
      <ViewAllReceipts
        show={viewAllReceipts}
        handleClose={() => {
          setViewAllReceipts(false)
        }}
        receipts={detailsToEdit?.payment_receipt}
        bankAccountItems={bankAccountItems}
        paymentId={detailsToEdit?._id}
        from={type}
      />

      {/* View All Attachments*/}
      <ViewAllAttachments
        show={viewAllAttachments}
        handleClose={() => {
          setViewAllAttachments(false)
        }}
        media={attachedMedia}
        from={type}
      />

      {/* View All Reminders */}
      <ViewAllReminders
        show={viewAllReminders}
        handleClose={() => {
          setViewAllReminders(false)
        }}
        media={schedule}
        from={type}
      />

      {/* View Single Invoice */}
      {viewInvoice && (
        <ViewInvoice
          show={viewInvoice}
          handleClose={() => {
            setViewInvoice(false)
          }}
          invoice={invoiceToShowEdit}
          dueDate={detailsToEdit?.dueDate}
          paymentId={detailsToEdit?._id}
          tenantDetails={tenantDetails}
          paymentMethod={paymentMethod}
          getPaymentInvoices={getPaymentById}
          invRecDetails={invRecDetails}
          from={type}
        />
      )}

      {/* View Late Fee History */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto'
        show={lateFeeModal}
        onHide={() => {
          setLateFeeModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div style={{height: '95px'}}>
          <div
            className='modal-header text-white ps-5 pe-1 header-style'
            style={{
              zIndex: '1',
              opacity: '0.85',
              borderBottom: '0px',
              minHeight: '95px',
              position: 'relative',
            }}
          >
            <Row className='w-100'>
              <Col md={8}>
                <h2 className='mx-0 my-1 mt-2 text-white'>Manage Late Payment Fee</h2>
              </Col>
              <Col md={4} className='text-end pe-0'>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                  onClick={() => setLateFeeModal(false)}
                >
                  <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
                </div>
              </Col>
            </Row>
          </div>

          <div className='header-bg'>
            <Row className='mx-2'>
              <Col md={9}></Col>
              <Col md={2} className='pt-2' style={{paddingLeft: '85px'}}>
                {/* <img src={linkImg} height='80px' width='80px' className='me-3 ms-5' /> */}
              </Col>
            </Row>
          </div>
        </div>
        <div
          className='modal-body py-lg-10 px-lg-5  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <Row className='my-5 pb-4 mx-2'>
            <Col md={6}>
              <label className='head-text fs-2 ' style={{fontWeight: '600'}}>
                Late Payment Fee
              </label>
            </Col>

            <Col lg={6} md={6} className='align-items-center text-end'>
              <button
                className='btn btn-sm fw-bold ms-auto ps-2 text-white me-1'
                style={{
                  fontSize: '1rem',
                  paddingRight: '15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                  width: '120px',
                }}
                onClick={(event) => {
                  removeLatePaymentHistory()
                }}
                // disabled={disableLink ? true : false}
              >
                {/* <img
                  src={linkImg}
                  style={{
                    height: '20px',
                    width: '20px',
                    marginRight: '12px',
                    marginLeft: '4px',
                    marginTop: '-3px',
                  }}
                />{' '} */}
                {'  '}
                Remove
              </button>
            </Col>
          </Row>

          <div className='my-5 py-5'>
            {lateFeeData?.length > 0 &&
              lateFeeData.map((ut: any, index: any) => {
                return (
                  <>
                    <div className='d-flex my-5 row '>
                      <div className='col-1'>
                        {
                          // ut?.paymentReceiptIds?.length == 0 && (
                          ut?.payment_invoice?.[0]?.invoiceNo == null && (
                            <>
                              {isRadio[index].ischecked == true ? (
                                <div
                                  style={{
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: '#384a62',
                                    borderRadius: '50%',
                                    marginTop: '2px',
                                  }}
                                  className='mx-5 '
                                ></div>
                              ) : (
                                <div
                                  style={{
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: 'transparent',
                                    borderRadius: '50%',
                                    border: '1px solid #384a62',
                                    marginTop: '2px',
                                  }}
                                  onClick={() => {
                                    const temp = [...isRadio]
                                    temp[index].ischecked = true
                                    setIsRadio(temp)
                                  }}
                                  className='mx-5 '
                                ></div>
                              )}
                            </>
                          )
                        }
                      </div>
                      <div className='col-1 head-text px-2'>{index + 1}</div>

                      <h6 className='m-0 p-0 col-2 head-text px-2'>
                        {ut?.dateApplied != null
                          ? moment(ut?.dateApplied).format('DD.MM.YYYY')
                          : '-'}
                      </h6>
                      <h6 className='m-0 p-0 col-2 head-text px-2'>{ut?.amount}</h6>
                      <h6 className='m-0 p-0 col-2 head-text'>
                        {ut?.payment_invoice?.[0]?.invoiceNo}
                      </h6>
                      <h6 className='m-0 p-0 col-2 head-text '>
                        {ut?.paymentReceiptIds?.length > 0
                          ? 'Receipt Issued'
                          : 'Receipt not Issued'}
                      </h6>
                      <h6 className='m-0 p-0 col-2 head-text ps-5'>{ut?.receiptAmount}</h6>
                    </div>
                  </>
                )
              })}
          </div>

          {/* Pagination */}
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous disabled'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* New Invoice modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={newInvoiceModal}
        onHide={() => {
          setNewInvoiceModal(false)
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 invoice-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Invoice</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => {
                  setNewInvoiceModal(false)
                }}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='invoice-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '80px'}}>
              <img src={invoiceWhite} height='80px' width='65px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body px-lg-10'
          style={{
            maxHeight: '600px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
            marginBottom: '-100px',
          }}
        >
          {/* <div className='d-flex my-2 pt-4'> */}
          <Row className='mt-2'>
            <Col md={8}>
              <button
                className='btn btn-sm fw-bold ps-2 pe-4 text-white'
                style={{width: '150px', backgroundColor: '#008081'}}
                onClick={() => {
                  if (invoiceNo == '' || invoiceNo == undefined) setInvoiceNoError(true)
                  else {
                    for (let i = 0; i < fields?.length; i++) {
                      if (fields[i]?.removeHistoryIDs?.length > 0) {
                        const body = {
                          latePaymentItemHistoryIds: fields[i]?.removeHistoryIDs,
                        }

                        ApiPost(`corporate/late_payment_item_history/delete`, body)
                          .then((res) => {})
                          .catch(() => {})
                      }
                    }
                    updatePaymentRecord(1)
                    // generateNewInvoice()
                  }
                }}
              >
                Generate
              </button>

              <div className='d-flex my-4 '>
                <label
                  htmlFor='media'
                  className='btn btn-sm fw-bold form-label m-0 upload text-center pb-5'
                  style={{
                    // height: '50px',
                    width: '150px',
                    backgroundColor: '#8a8aa3',
                    fontSize: '16px',
                    // paddingLeft: '40px',
                    color: 'white',
                  }}
                >
                  <img />
                  <b
                    style={{
                      fontSize: '22px',
                      display: 'block',
                      marginTop: '-15px',
                      fontWeight: '700',
                      marginBottom: '12px',
                    }}
                  >
                    LOGO
                  </b>
                  Click to upload (Optional)
                </label>

                <input
                  type='file'
                  hidden
                  id='media'
                  className='form-control form-control-solid'
                  name='passport'
                  // onChange={(e: any) => onFilesAddedSingle(e)}
                />
              </div>
            </Col>
            <Col md={4} className='pe-4'>
              <div className='d-flex align-items-center my-2'>
                <label
                  className='mb-1 me-5 pe-5 ms-auto head-text'
                  style={{minWidth: '50px', maxWidth: '50px'}}
                >
                  <b>TRN</b>
                </label>
                <b>1234567890</b>
              </div>
              <div className='d-flex align-items-center my-2 '>
                <h1 className='ms-auto'>INVOICE</h1>
                {invoiceView == false && (
                  <img
                    src={editpen}
                    alt=''
                    className='ms-2'
                    style={{cursor: 'pointer', height: '14px', width: '14px', marginTop: '-25px'}}
                    // onClick={() => {
                    // }}
                  />
                )}
              </div>

              {/*  */}
              <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                <label className='mb-1 me-5 pe-5 ' style={{minWidth: '100px', fontSize: '1rem'}}>
                  Invoice No.
                </label>

                <input
                  type='text'
                  className={
                    invoiceNoError
                      ? 'form-control form-control-solid-bg input-style border-danger'
                      : 'form-control form-control-solid-bg input-style mytest'
                  }
                  // className='form-control form-control-solid-bg input-style'
                  placeholder='Enter Invoice No'
                  name='item_id'
                  value={invoiceNo}
                  onChange={(event) => {
                    setInvoiceNo(event.target.value)
                    setInvoiceNoError(false)
                  }}
                />
              </div>

              {/*  */}
              <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                <label className='mb-1 me-5 pe-5' style={{minWidth: '100px', fontSize: '1rem'}}>
                  Invoice Date
                </label>
                <div className='ms-auto' style={{fontWeight: '500'}}>
                  <p style={{fontWeight: '800', fontSize: '1rem'}} className='mb-1'>
                    {invoiceDate}
                  </p>
                </div>
              </div>

              <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                <label className='mb-1 me-5 pe-5  ' style={{minWidth: '100px', fontSize: '1rem'}}>
                  Due Date
                </label>
                <div className='ms-auto' style={{fontWeight: '500'}}>
                  <p style={{fontWeight: '800', fontSize: '1rem'}} className='mb-1'>
                    {dueDateToShow}
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <Row className='mt-5 pb-5' style={{borderBottom: '2px dashed lightgray'}}>
            <Col md={6} style={{fontWeight: '600'}}>
              <div className='d-flex align-items-center'>
                <h5 style={{fontWeight: '700'}}>Recipient</h5>
                {/* {invoiceView == false && (
                  <button
                    className='btn btn-sm fw-bold  mx-3 ps-1 pe-4'
                    style={{
                      width: '125px',
                      backgroundColor: '#ff0000',
                      color: 'white',
                      fontSize: '13px',
                    }}
                    onClick={() => sentInvoiceToTenant()}
                    disabled={invoiceAdded === true ? false : true}
                  >
                    <img src={send} style={{height: '20px', width: '20px', marginRight: '3px'}} />{' '}
                    {'  '}
                    Send Invoice
                  </button>
                )} */}
              </div>
              <p style={{marginBottom: '0.1rem'}}>
                {tenantDetails?.firstName} {tenantDetails?.lastName}
              </p>
              <p style={{marginBottom: '0.1rem'}}>
                {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
              </p>
              <p style={{marginBottom: '0.1rem'}}>{tenantDetails?.email}</p>
            </Col>
            <Col md={6} className='text-end' style={{fontWeight: '600'}}>
              <p style={{marginBottom: '0.1rem'}}>Corporate User Real Estate Services LLC.</p>
              <p style={{marginBottom: '0.1rem'}}>Corporate Address, Street, Building, City</p>

              <p style={{marginBottom: '0.1rem'}}>+92 111 2233444</p>
              <p style={{marginBottom: '0.1rem'}}>CorporateAccount@gmail.com</p>
            </Col>
          </Row>

          <table
            className='table align-middle fs-6 gy-5 mt-3 pb-5'
            id='kt_ecommerce_sales_table'
            style={{borderBottom: '1.5px dashed lightgray'}}
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                <th className='min-w-50px'>#</th>
                <th className='min-w-100px'>Item</th>
                <th className='min-w-100px'>Qty</th>
                <th className='min-w-100px'>Rate</th>
                <th className='min-w-100px'>Vat</th>
                <th className='min-w-100px'>Amount</th>
              </tr>
            </thead>
            <tbody style={{fontWeight: '500'}}>
              {fields.map((field: any, index: any) => {
                let y: any = ((field.amount * field.qty) / 100) * field.vat
                let total: number = parseInt(y) + field.amount * field.qty
                return (
                  <tr style={{borderBottom: '1px solid lightgray', height: '45px'}}>
                    <td className='py-2'>{index + 1}</td>
                    <td className='py-2'>{field.item}</td>
                    <td className='py-2'>{field.qty}</td>
                    <td className='py-2'>
                      {field?.isLatePaymentFeesRecord
                        ? (field.amount / field?.qty)?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })
                        : field.amount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                    </td>
                    <td className='py-2'>
                      {field?.isLatePaymentFeesRecord
                        ? ((field.amount / 100) * field.vat)?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })
                        : (((field.amount * field.qty) / 100) * field.vat)?.toLocaleString(
                            'en-US',
                            {minimumFractionDigits: 2}
                          )}
                    </td>
                    <td className='py-2'>
                      {field?.isLatePaymentFeesRecord
                        ? (field.amount + (field.amount / 100) * field.vat)?.toLocaleString(
                            'en-US',
                            {minimumFractionDigits: 2}
                          )
                        : total?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                    </td>
                  </tr>
                )
              })}

              <>
                {fields.map((f: any, ind: any) => {
                  vatAmount = 0
                  nonVat = 0
                  return <></>
                })}
              </>
              {fields?.length > 0 && (
                <>
                  {fields.map((f: any, ind: any) => {
                    if (f?.isLatePaymentFeesRecord === true) {
                      vatAmount = vatAmount + (f.amount / 100) * f.vat
                      nonVat = nonVat + f.amount
                    } else {
                      vatAmount = vatAmount + ((f.amount * f.qty) / 100) * f.vat
                      nonVat = nonVat + f.amount * f.qty
                    }
                    return <></>
                  })}
                </>
              )}

              <tr style={{height: '55px'}}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5' style={{color: '#B5B5C3'}}>
                  SUB TOTAL
                </td>
                <td className='py-2'>
                  {nonVat?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                </td>
              </tr>

              <tr style={{height: '30px'}}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5' style={{color: '#B5B5C3'}}>
                  VAT
                </td>
                <td className='py-2'>
                  {vatAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                </td>
              </tr>
              <tr style={{height: '55px'}} className='pb-4'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5'>Total</td>
                <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                  {' '}
                  <b>
                    AED {(vatAmount + nonVat)?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>

          <Row>
            <Col md={7}>
              <div className='row my-2 mb-5'>
                <div className='col-md-6'>
                  <label className='mb-1 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Payment Method
                  </label>
                </div>
                <div className='col-md-6 text-center'>
                  <label
                    className='mb-1 me-1 fs-6 pt-1'
                    style={{minWidth: '160px', fontWeight: '500'}}
                  >
                    {paymentMethod == 0
                      ? 'Card'
                      : paymentMethod == 1
                      ? 'Cheque'
                      : paymentMethod == 2
                      ? 'Bank'
                      : 'Cash'}
                  </label>
                </div>
              </div>
              {paymentMethod == 2 && (
                <>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '100px', fontWeight: '500'}}
                    >
                      Account Name
                    </label>
                    <b> {selectedAccount?.accountHolderName}</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      IBAN
                    </label>
                    <b> {selectedAccount?.IBAN}</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      Account No.
                    </label>
                    <b> {selectedAccount?.accountNumber}</b>
                  </div>
                  <div className='d-flex align-items-center mb-3'>
                    <label
                      className='mb-1 me-1'
                      style={{minWidth: '110px', maxWidth: '110px', fontWeight: '500'}}
                    >
                      Bank Name
                    </label>
                    <b> {selectedAccount?.bankName}</b>
                  </div>
                </>
              )}

              {paymentMethod == 1 && (
                <>
                  {chequeMedia.length > 0 && (
                    <div
                      className='row align-items-center my-2'
                      style={
                        paymentMethod == 1
                          ? {
                              overflowY: 'scroll',
                              minHeight: '96px',
                              maxHeight: '200px',
                              // transform: 'translate(-2px, -90px)',
                            }
                          : {overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}
                      }
                    >
                      {chequeMedia.map((chq: any, ind: any) => (
                        <>
                          <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                            <div className='d-flex'>
                              <a
                                href={`${Bucket}${chq?.image}`}
                                target='_blank'
                                style={{color: '#384a62'}}
                                className='d-flex'
                              >
                                <img
                                  src={chequeImg}
                                  width='80px'
                                  height='40px'
                                  className='main_img cursor-pointer'
                                  alt='image'
                                  style={{objectFit: 'cover'}}
                                />
                              </a>
                            </div>
                            <p className='fs-7 ps-1'>{chq.name}</p>
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </>
              )}
            </Col>
            <Col md={5}>
              {/* Notes */}
              <div className='my-2 mb-5 h-10'>
                <label className='mb-4 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                  Notes
                </label>
                {invoiceView ? (
                  <p style={{marginBottom: '0.1rem'}}>{invoiceNotes}</p>
                ) : (
                  <textarea
                    className='form-control form-control-solid-bg input-style mytest'
                    name='comment'
                    placeholder='Enter any additional notes to the recipient'
                    style={{height: '130px', borderColor: 'lightgray'}}
                    value={invoiceNotes}
                    onChange={(e) => setInvoiceNotes(e.target.value)}
                    maxLength={1000}
                  ></textarea>
                )}
              </div>

              {invoiceView == false && (
                <>
                  <div className='d-flex my-2 pt-4'>
                    <label
                      className='mb-1 me-1 fs-4'
                      style={{minWidth: '160px', fontWeight: '700'}}
                    >
                      Attachments
                    </label>
                  </div>

                  <div className='d-flex justify-content-center my-4 '>
                    <form
                      id='form-file-upload-invoice'
                      onDragEnter={handleDrag}
                      onSubmit={(e) => e.preventDefault()}
                      style={{height: '90px'}}
                    >
                      <input
                        type='file'
                        multiple={true}
                        onChange={handleInvoiceAttachmentChange}
                        id='mediaAttachInvoice'
                        style={{height: '90px', opacity: '-0.9', position: 'relative', zIndex: '1'}}
                      />

                      <label
                        htmlFor='mediaAttachInvoice'
                        className={
                          dragActive
                            ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                            : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                        }
                        style={{
                          fontSize: '16px',
                          color: 'white',
                          transform: 'translate(-2px, -90px)',
                        }}
                      >
                        <div
                          style={{
                            height: '90px',
                            width: '280px',
                            border: '2px dashed #384a62',
                            backgroundColor: '#f5f8fa',
                          }}
                          className=''
                        >
                          <div
                            className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                            style={{minWidth: '90px'}}
                          >
                            Drag & drop your document
                          </div>
                          <div
                            className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                            style={{minWidth: '90px'}}
                          >
                            <div
                              className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                              style={{
                                fontSize: '1rem',
                                paddingLeft: '15px',
                                borderRadius: '7px',
                                backgroundColor: '#35475e',
                                boxShadow: '0px 0px 20px 0px #516176',
                              }}
                            >
                              Browse Files
                            </div>
                          </div>
                        </div>
                      </label>
                      {dragActive && (
                        <div
                          id='drag-file-element'
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        ></div>
                      )}
                    </form>
                  </div>
                  {invoiceAttachedMedia.length > 0 && (
                    <div
                      className='row align-items-center mt-2'
                      // style={{overflowY: 'scroll', minHeight: '96px', maxHeight: '200px',transform: 'translate(0px, -90px)', marginBottom: '-90px'}}
                    >
                      {invoiceAttachedMedia.map((chq: any, ind: any) => (
                        <>
                          <div className='img-sets mw-350px col-md-4 px-2 py-3'>
                            <div className='d-flex'>
                              <a
                                href={`${Bucket}${chq?.src}`}
                                target='_blank'
                                style={{color: '#384a62'}}
                                className='d-flex'
                              >
                                <img
                                  src={file}
                                  width='42px'
                                  height='55px'
                                  className='main_img cursor-pointer'
                                  alt='image'
                                  style={{marginLeft: '26px'}}
                                />

                                <img
                                  src={redCross}
                                  width='12px'
                                  height='12px'
                                  className='main_img cursor-pointer'
                                  style={{marginTop: '-8px', marginLeft: '-3px'}}
                                  onClick={() => deleteInvoiceAttachment(chq)}
                                />
                              </a>
                            </div>

                            <p
                              style={{fontSize: '12px', wordBreak: 'break-all', padding: '0px 5px'}}
                              className='mt-1'
                            >
                              {chq.name}{' '}
                            </p>
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </>
              )}
            </Col>
          </Row>

          {/* </div> */}
        </div>
      </Modal>

      {/* LINK invoice to Receipt */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto'
        show={receiptLinkModal}
        onHide={() => {
          setReceiptLinkModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div style={{height: '95px'}}>
          <div
            className='modal-header text-white ps-5 pe-1 header-style'
            style={{
              zIndex: '1',
              opacity: '0.85',
              borderBottom: '0px',
              minHeight: '95px',
              position: 'relative',
            }}
          >
            <Row className='w-100'>
              <Col md={8}>
                <h2 className='mx-0 my-1 mt-2 text-white'>Link Invoice to Receipt</h2>
              </Col>
              <Col md={4} className='text-end pe-0'>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                  onClick={() => setReceiptLinkModal(false)}
                >
                  <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
                </div>
              </Col>
            </Row>
          </div>

          <div className='header-bg'>
            <Row className='mx-2'>
              <Col md={9}></Col>
              <Col md={2} className='pt-2' style={{paddingLeft: '85px'}}>
                {/* <img src={linkImg} height='80px' width='80px' className='me-3 ms-5' /> */}
              </Col>
            </Row>
          </div>
        </div>
        <div
          className='modal-body py-lg-10 px-lg-5  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <Row className='my-5 pb-4 mx-2'>
            <Col md={6}>
              <label className='head-text fs-2 ' style={{fontWeight: '600'}}>
                Invoices
              </label>
            </Col>

            <Col lg={6} md={6} className='align-items-center text-end'>
              <button
                className='btn btn-sm fw-bold ms-auto ps-2 text-white me-1'
                style={{
                  fontSize: '1rem',
                  paddingRight: '15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                  width: '120px',
                }}
                onClick={(event) => {
                  if (invoiceReceiptNo == '') ErrorToast('Please Select an Invoice!!!')
                  else {
                    setReceiptLinkModal(false)
                    setReceiptSubItemsModal(true)
                  }
                  // setReceiptModal(true)
                }}
                // disabled={disableLink ? true : false}
              >
                {/* <img
                  src={linkImg}
                  style={{
                    height: '20px',
                    width: '20px',
                    marginRight: '12px',
                    marginLeft: '4px',
                    marginTop: '-3px',
                  }}
                />{' '} */}
                {'  '}
                Select
              </button>
            </Col>
          </Row>

          <div className='d-flex mt-5 row '>
            <h6 className='m-0 p-0 col-1'></h6>
            <h6
              className='m-0 p-0 col-1 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              #
            </h6>
            <h6
              className='m-0 p-0 col-2 head-text px-2 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Total Amount
            </h6>

            <h6
              className='m-0 p-0 col-2 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Invoice No
            </h6>
            <h6
              className='m-0 p-0 col-2 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Status
            </h6>
            <h6
              className='m-0 p-0 col-4 head-text ps-5 text-decoration-underline fs-5 '
              style={{fontWeight: '600'}}
            >
              Receipt Amount
            </h6>
          </div>

          <div className='py-5'>
            {invoiceLinkData?.length > 0 &&
              invoiceLinkData.map((ut: any, index: any) => {
                return (
                  <>
                    <div className='d-flex my-5 row '>
                      <div className='col-1'>
                        {ut?.invoiceNo != null && (
                          <>
                            {invoiceLinkChecked == index ? (
                              <div
                                style={{
                                  height: '16px',
                                  width: '16px',
                                  backgroundColor: '#384a62',
                                  borderRadius: '50%',
                                }}
                                className='mx-5 '
                              ></div>
                            ) : (
                              <div
                                style={{
                                  height: '16px',
                                  width: '16px',
                                  backgroundColor: 'transparent',
                                  borderRadius: '50%',
                                  border: '1px solid #384a62',
                                }}
                                onClick={() => {
                                  setInvoiceLinkChecked(index)
                                  setInvoiceReceiptNo(ut?.invoiceNo)
                                  setInvoiceReceiptURL(ut?.pdfURL)

                                  ApiGet(`corporate/payment_invoice/${ut?._id}`)
                                    .then((res) => {
                                      setInvoiceToShowEdit(res?.data?.data)
                                      // console.log(res?.data?.data)
                                      const values = [...receiptFields]
                                      for (
                                        let i = 0;
                                        i < res?.data?.data?.paymentSubItems?.length;
                                        i++
                                      ) {
                                        values[i] = {
                                          _id: res?.data?.data?.paymentSubItems[i]?._id,
                                          name: res?.data?.data?.paymentSubItems[i]?.name,
                                          amount: res?.data?.data?.paymentSubItems[i]?.amount,
                                          qty: res?.data?.data?.paymentSubItems[i]?.quantity,
                                          vat: res?.data?.data?.paymentSubItems[i]?.VATPercentage,
                                          isLateItem:
                                            res?.data?.data?.paymentSubItems[i]
                                              ?.isLatePaymentFeesRecord,
                                          total: res?.data?.data?.paymentSubItems[i]?.totalAmount,
                                          amountPaid: 0,
                                          outstanding:
                                            res?.data?.data?.paymentSubItems[i]?.outstandingAmount,
                                          previousPaidAmount:
                                            res?.data?.data?.paymentSubItems[i]?.amountPaid,
                                          previousOutstanding:
                                            res?.data?.data?.paymentSubItems[i]?.outstandingAmount,
                                        }
                                      }
                                      setReceiptFields(values)
                                      console.log(values)
                                    })
                                    .catch((err: any) => {
                                      console.log('err', err)
                                    })
                                }}
                                className='mx-5 '
                              ></div>
                            )}
                          </>
                        )}
                      </div>
                      <div className='col-1 head-text px-2'>{index + 1}</div>

                      {/* <h6 className='m-0 p-0 col-2 head-text px-2'>
                        {ut?.dateApplied != null
                          ? moment(ut?.dateApplied).format('DD.MM.YYYY')
                          : '-'}
                      </h6> */}
                      <h6 className='m-0 p-0 col-2 head-text px-2'>
                        {ut?.totalAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </h6>
                      <h6 className='m-0 p-0 col-2 head-text px-3'>{ut?.invoiceNo}</h6>
                      <h6 className='m-0 p-0 col-2 head-text px-3'>
                        {ut?.paymentReceiptIds?.length > 0
                          ? 'Receipt Issued'
                          : 'Receipt not Issued'}
                      </h6>
                      <h6 className='m-0 p-0 col-4 head-text ps-5'>
                        {ut?.receiptAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </h6>
                    </div>
                  </>
                )
              })}
          </div>

          {/* Pagination */}
          {/* <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous disabled'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                />
              </div>
            </div>
          </div> */}
        </div>
      </Modal>

      {/* Receipt SubItems Detail modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={receiptSubItemsModal}
        onHide={() => {
          setReceiptSubItemsModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        {/* <div style={{height: '95px'}}>
          <div
            className='modal-header text-white ps-5 pe-1 header-style'
            style={{
              zIndex: '1',
              opacity: '0.85',
              borderBottom: '0px',
              minHeight: '95px',
              position: 'relative',
            }}
          >
            <Row className='w-100'>
              <Col md={8}>
                <h2 className='mx-0 my-1 mt-2 text-white'>Invoice {invoiceNo}</h2>
              </Col>
              <Col md={4} className='text-end pe-0'>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                  onClick={() => setReceiptLinkModal(false)}
                >
                  <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
                </div>
              </Col>
            </Row>
          </div>

          <div className='header-bg'>
            <Row className='mx-2'>
              <Col md={9}></Col>
              <Col md={2} className='pt-2' style={{paddingLeft: '85px'}}>
                {/* <img src={linkImg} height='80px' width='80px' className='me-3 ms-5' /> */}
        {/* </Col> */}
        {/* </Row> */}
        {/* </div> */}
        {/* </div> */}
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setReceiptSubItemsModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='my-5 pb-4 row me-2'>
            <div className='col-md-6'>
              <label className='head-text fs-4 d-block' style={{fontWeight: '700'}}>
                Invoice {invoiceToShowEdit?.invoiceNo}
              </label>
              <label className='head-text fs-5 d-block' style={{fontWeight: '700'}}>
                Total{' '}
                {invoiceToShowEdit?.totalAmount?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                })}
              </label>
            </div>

            <div className='col-md-6 align-items-center text-end'>
              <button
                className='btn btn-md fw-bold ms-auto ps-2 text-white me-1'
                style={{
                  fontSize: '1rem',
                  paddingRight: '15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                  width: 'inherit',
                }}
                onClick={(event) => {
                  if (paidAmountReceipt > 0) {
                    let temp: any = 0
                    const values = [...receiptFields]
                    for (let i = 0; i < values?.length; i++) {
                      if (values[i]?.amountPaid != undefined)
                        temp = parseFloat(temp) + parseFloat(values[i]?.amountPaid)
                    }
                    setAmountPaidReceipt(temp)

                    setReceiptSubItemsModal(false)
                    setReceiptModal(true)
                  } else {
                    ErrorToast('Amount Paid must be Greater than 0 !!!')
                  }
                }}
              >
                Generate Receipt
              </button>
            </div>
          </div>

          <div className='d-flex my-5 row align-items-start'>
            <h6
              className='m-0 p-0 col-2 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Item Name
            </h6>

            <h6
              className='m-0 p-0 col-2 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Total Amount
            </h6>
            <h6
              className='m-0 p-0 col-2 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Previous Amount Paid
            </h6>
            <h6
              className='m-0 p-0 col-3 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Amount Paid
            </h6>
            <h6
              className='m-0 p-0 col-2 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Outstanding
            </h6>
          </div>

          <div className='my-5 py-3'>
            {receiptFields?.length > 0 &&
              receiptFields?.map((field: any, index: any) => {
                return (
                  <>
                    <div
                      className='d-flex py-3 row align-items-center'
                      style={{borderBottom: '2px solid lightgray'}}
                    >
                      <h6 className='m-0 p-0 col-2 head-text px-3'>{field?.name}</h6>

                      <h6 className='m-0 p-0 col-2 head-text px-3'>
                        {field?.isLateItem
                          ? field?.total?.toLocaleString('en-US', {minimumFractionDigits: 2})
                          : (
                              field.amount * field.qty +
                              ((field.amount * field.qty) / 100) * field.vat
                            )?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </h6>
                      <h6 className='m-0 p-0 col-2 head-text px-3'>
                        {field?.previousPaidAmount?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}
                      </h6>
                      <h6 className='m-0 p-0 col-3 head-text px-3'>
                        <input
                          type='number'
                          className='form-control form-control-bg input-style mytest'
                          placeholder='Amount Paid'
                          name='item_id'
                          value={field?.amountPaid}
                          onChange={(event: any) => {
                            const values = [...receiptFields]
                            values[index].amountPaid = event.target.value

                            values[index].outstanding =
                              values[index]?.previousOutstanding - parseFloat(event.target.value)

                            if (event.target.value.length == 0)
                              values[index].outstanding = values[index]?.previousOutstanding

                            setReceiptFields(values)

                            let temp: any = 0
                            // const values = [...receiptFields]
                            for (let i = 0; i < values?.length; i++) {
                              if (values[i]?.amountPaid != undefined)
                                temp = parseFloat(temp) + parseFloat(values[i]?.amountPaid)
                            }
                            setAmountPaidReceipt(temp)
                          }}
                        />
                      </h6>
                      <h6 className='m-0 p-0 col-2 head-text px-3'>
                        {field?.outstanding?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </h6>
                    </div>
                  </>
                )
              })}
          </div>
        </div>
      </Modal>

      {/* Manual Pdf File */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto'
        show={uploadInvoiceModal}
        onHide={() => {
          setUploadInvoiceModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setUploadInvoiceModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='pb-4 row me-2'>
            <div className='col-md-6'>
              <label className='head-text fs-4 d-block' style={{fontWeight: '700'}}>
                {isReceipt ? 'Upload Receipt' : 'Upload Invoice'}
              </label>
            </div>
          </div>

          <div className='d-flex justify-content-center my-4 '>
            <form
              id='form-file-upload-invoice'
              onDragEnter={handleDrag}
              onSubmit={(e) => e.preventDefault()}
              style={{height: '90px'}}
            >
              <input
                type='file'
                multiple={true}
                onChange={(e: any) => {
                  uploadReceipt(e)
                }}
                id='input-file-upload-invoice'
                ref={inputRef}
                style={{height: '90px', opacity: '-0.9', position: 'relative', zIndex: '1'}}
              />
              <label
                id='label-file-upload'
                htmlFor='input-file-upload-invoice'
                className={
                  dragActive
                    ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto cursor-pointer'
                    : 'form-label m-0 upload d-flex gap-2 text-center p-auto cursor-pointer'
                }
                style={{
                  fontSize: '16px',
                  color: 'white',
                  transform: 'translate(-2px, -90px)',
                }}
                ref={inputRef}
              >
                <div
                  style={{
                    height: '90px',
                    width: '280px',
                    border: '2px dashed #384a62',
                    backgroundColor: '#f5f8fa',
                  }}
                  className=''
                >
                  <div
                    className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                    style={{minWidth: '90px'}}
                  >
                    Drag & drop your document
                  </div>
                  <div
                    className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                    style={{minWidth: '90px'}}
                  >
                    <div
                      className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                      style={{
                        fontSize: '1rem',
                        paddingLeft: '15px',
                        borderRadius: '7px',
                        backgroundColor: '#35475e',
                        boxShadow: '0px 0px 20px 0px #516176',
                      }}
                    >
                      Browse Files
                    </div>
                  </div>
                </div>
              </label>
              {dragActive && (
                <div
                  id='drag-file-element'
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={handleDrop}
                ></div>
              )}
            </form>
          </div>
        </div>
      </Modal>

      {/* View Single Receipt */}
      {viewReceipt && (
        <ViewReceipt
          show={viewReceipt}
          handleClose={() => {
            setViewReceipt(false)
          }}
          receipt={receiptToShowEdit}
          paymentId={detailsToEdit?._id}
          selectedAccountReceipt={selectedAccountReceipt}
          tenantDetails={tenantDetails}
          from={type}
        />
      )}

      {/* Add Credit Note Modal */}
      {creditNoteModal && (
        <CreditNote
          show={creditNoteModal}
          handleClose={() => {
            setCreditNoteModal(false)
          }}
          paymentId={detailsToEdit?._id}
          tenantDetails={tenantDetails}
          closeEdit={() => {
            handleClose()
            getAllPayments()
          }}
          from={type}
        />
      )}

      {/* View Single Credit Note */}
      {viewCreditNote && (
        <ViewCreditNote
          show={viewCreditNote}
          handleClose={() => {
            setViewCreditNote(false)
          }}
          creditNote={creditNoteToView}
          paymentId={detailsToEdit?._id}
          tenantDetails={tenantDetails}
          dueDate={detailsToEdit?.dueDate}
          from={type}
        />
      )}

      {/* Maintenance Req Link Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={mainReqLinkModal}
        onHide={() => {
          setMainReqLinkModal(false)
          const values = [...fields]
          const values1 = [...newFields]

          if (indForMaintNew != -1) {
            values1[indForMaintNew].serviceRequestId = null
            values1[indForMaintNew].requestId = null
            values1[indForMaintNew].serviceJobId = null
            values1[indForMaintNew].jobId = null
          } else {
            values[indForMaintenance].serviceRequestId = null
            values[indForMaintenance].requestId = null
            values[indForMaintenance].serviceJobId = null
            values[indForMaintenance].jobId = null
          }
        }}
        backdrop={true}
        size='sm'
      >
        <div style={{height: '95px'}}>
          <div
            className='modal-header text-white ps-5 pe-1 header-style'
            style={{
              zIndex: '1',
              opacity: '0.85',
              borderBottom: '0px',
              minHeight: '95px',
              position: 'relative',
            }}
          >
            <Row className='w-100'>
              <Col md={8}>
                <h2 className='mx-0 my-1 mt-2 text-white'>Link a Maintenance Request</h2>
              </Col>
              <Col md={4} className='text-end pe-0'>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                  onClick={() => {
                    setMainReqLinkModal(false)
                    const values = [...fields]
                    const values1 = [...newFields]

                    if (indForMaintNew != -1) {
                      values1[indForMaintNew].serviceRequestId = null
                      values1[indForMaintNew].requestId = null
                      values1[indForMaintNew].serviceJobId = null
                      values1[indForMaintNew].jobId = null
                    } else {
                      values[indForMaintenance].serviceRequestId = null
                      values[indForMaintenance].requestId = null
                      values[indForMaintenance].serviceJobId = null
                      values[indForMaintenance].jobId = null
                    }
                  }}
                >
                  <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
                </div>
              </Col>
            </Row>
          </div>

          <div className='header-bg'>
            <Row className='mx-2'>
              <Col md={9}></Col>
              <Col md={2} className='pt-2' style={{paddingLeft: '85px'}}>
                <img src={linkImg} height='80px' width='80px' className='me-3 ms-5' />
              </Col>
            </Row>
          </div>
        </div>
        <div
          className='modal-body py-lg-10 px-lg-5  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <Row className='my-5 pb-4 mx-2'>
            <Col md={5}>
              <label className='head-text fs-2 ' style={{fontWeight: '600'}}>
                Requests
              </label>
            </Col>
            <Col lg={4}>
              {' '}
              <div className='min-w-200px align-items-center ps-3 ms-5 '>
                <Space direction='vertical' size={12}>
                  <RangePicker onChange={handleDateRangeChange} />
                </Space>
                {/* <Datepicker
              value={value}
              onChange={handleValueChange}
               
                // onChange={handleValueChange}
            /> */}
              </div>
            </Col>
            <Col lg={3} md={3} className='align-items-center text-end'>
              <button
                className='btn btn-sm fw-bold ms-auto ps-2 text-white me-1'
                style={{
                  fontSize: '1rem',
                  paddingRight: '15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={(event) => {
                  event.preventDefault()
                  // submitIncomingPayment()
                  setMainReqLinkModal(false)
                }}
                disabled={disableLink ? true : false}
              >
                <img
                  src={linkImg}
                  style={{
                    height: '20px',
                    width: '20px',
                    marginRight: '12px',
                    marginLeft: '4px',
                    marginTop: '-3px',
                  }}
                />{' '}
                {'  '}
                Link to Request
              </button>
            </Col>
          </Row>

          <div className='my-5 py-5' style={{color: '#007a59'}}>
            {maintenanceReqData?.length > 0 &&
              maintenanceReqData.map((ut: any, index: any) => {
                return (
                  <>
                    <div className='d-flex my-5 row '>
                      <div className='col-1'>
                        {radioLinkChecked === index ? (
                          <div
                            style={{
                              height: '16px',
                              width: '16px',
                              backgroundColor: '#384a62',
                              borderRadius: '50%',
                            }}
                            className='mx-5 '
                          ></div>
                        ) : (
                          <div
                            style={{
                              height: '16px',
                              width: '16px',
                              backgroundColor: 'transparent',
                              borderRadius: '50%',
                              border: '1px solid #384a62',
                            }}
                            onClick={() => {
                              setRadioLinkChecked(index)
                              setServiceJobId(ut._id)
                              setjobId(ut.id)

                              const values = [...fields]
                              const values1 = [...newFields]

                              if (indForMaintNew != -1) {
                                values1[indForMaintNew].serviceRequestId =
                                  ut.service_request?.[0]?._id
                                values1[indForMaintNew].requestId = ut.service_request?.[0]?.id
                                values1[indForMaintNew].serviceJobId = ut._id
                                values1[indForMaintNew].jobId = ut.id
                              } else {
                                values[indForMaintenance].serviceRequestId =
                                  ut.service_request?.[0]?._id
                                values[indForMaintenance].requestId = ut.service_request?.[0]?.id
                                values[indForMaintenance].serviceJobId = ut._id
                                values[indForMaintenance].jobId = ut.id
                              }

                              setServiceRequestId(ut.service_request?.[0]?._id)
                              setRequestId(ut.service_request?.[0]?.id)
                              setDisableLink(false)
                            }}
                            className='mx-5 '
                          ></div>
                        )}
                      </div>
                      {/* <input
                                      className='form-check-input me-5'
                                      type='radio'
                                      // checked={selectedRadio === scg._id}
                                      // onChange={(e: any) => selectSchedule(e, i)}
                                    /> */}
                      <h6 className='m-0 p-0 col-3' style={{fontWeight: '600', color: '#007a59'}}>
                        {ut?.service_request?.[0]?.id}
                        {'_'}
                        {ut?.id}
                      </h6>
                      <h6 className='m-0 p-0 col-3' style={{fontWeight: '600', color: '#007a59'}}>
                        {ut?.service_request?.[0]?.main_service?.[0]?.name} {' - '}
                        {ut?.service_request?.[0]?.sub_service?.[0]?.name}
                      </h6>
                      <h6 className='m-0 p-0 col-2' style={{fontWeight: '600', color: '#007a59'}}>
                        {ut?.schedule?.date != null
                          ? moment(ut?.schedule?.date).format('DD.MM.YYYY')
                          : '-'}
                      </h6>
                      <h6 className='m-0 p-0 col-2' style={{fontWeight: '600', color: '#007a59'}}>
                        {ut?.schedule?.status === 0
                          ? 'Not Started'
                          : ut?.schedule?.status === 1
                          ? 'Started'
                          : ut?.schedule?.status === 2
                          ? 'Pending Tenant Approval'
                          : ut?.schedule?.status === 3
                          ? 'Incomplete'
                          : ut?.schedule?.status === 4
                          ? 'Complete'
                          : 'Cancelled'}
                      </h6>
                    </div>
                  </>
                )
              })}
          </div>
        </div>
      </Modal>

      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto'
        show={invoiceSubItemsModal}
        onHide={() => {
          setInvoiceSubItemsModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setInvoiceSubItemsModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='my-5 pb-4 row me-0'>
            <div className='col-md-6 ps-5 pt-4'>
              <label className='head-text fs-4 d-block' style={{fontWeight: '700'}}>
                Select Items
              </label>
            </div>

            <div className='col-md-3 align-items-end text-end ms-auto pe-0 me-2'>
              <button
                className='btn btn-md fw-bold ms-auto text-white me-1'
                style={{
                  fontSize: '1rem',
                  padding: '10px 15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={() => {
                  setInvoiceSubItemsModal(false)
                  setVoidInvoiceModal(true)
                }}
              >
                Add to Invoice
              </button>
            </div>
          </div>

          {/*  */}
          <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-capitalize gs-0 border-white'>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 mw-50px'
                    style={{minWidth: '30px'}}
                  ></th>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 mw-100px'
                    style={{minWidth: '50px'}}
                  >
                    #
                  </th>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 mw-100px'
                    style={{minWidth: '100px'}}
                  >
                    Item Name
                  </th>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 mw-100px '
                    style={{minWidth: '50px'}}
                  >
                    Qty
                  </th>

                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 mw-100px'
                    style={{minWidth: '100px'}}
                  >
                    {' '}
                    Total Amount
                  </th>

                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 mw-100px'
                    style={{minWidth: '50px'}}
                  >
                    VAT
                  </th>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 mw-100px'
                    style={{minWidth: '100px'}}
                  >
                    {' '}
                    Final Amount
                  </th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {invoiceFields?.length > 0 &&
                  invoiceFields?.map((field: any, index: any) => {
                    return (
                      <>
                        <tr style={{height: '45px', borderBottom: '2px solid lightgray'}}>
                          <td className='m-0 p-0 head-text p-3'>
                            <>
                              {field?.isSelected == 1 ? (
                                <div
                                  style={{
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: '#384a62',
                                    borderRadius: '50%',
                                  }}
                                  className='mx-1 '
                                  onClick={() => {
                                    const values = [...invoiceFields]
                                    values[index].isSelected = 0
                                    setInvoiceFields(values)
                                  }}
                                ></div>
                              ) : (
                                <div
                                  style={{
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: 'transparent',
                                    borderRadius: '50%',
                                    border: '1px solid #384a62',
                                  }}
                                  onClick={() => {
                                    const values = [...invoiceFields]
                                    values[index].isSelected = 1
                                    setInvoiceFields(values)
                                  }}
                                  className='mx-1 '
                                ></div>
                              )}
                            </>
                          </td>
                          <td className='m-0 p-0 head-text p-3'>{field?.id}</td>

                          <td className='m-0 p-0 head-text p-3'>{field?.name}</td>
                          <td className='m-0 p-0 head-text p-3'>{field.qty}</td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.totalWithoutVat?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {field?.vatAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {field?.finalAmount?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      </>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>

      {/* VOID Invoice Modal used to genearte any new invoice */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={voidInvoiceModal}
        onHide={() => {
          setVoidInvoiceModal(false)
        }}
        backdrop={true}
        size='sm'
        style={{}}
      >
        <div
          className='modal-header text-white ps-5 pe-1 invoice-header'
          style={{zIndex: '1', opacity: '0.85', borderBottom: '0px', minHeight: '95px'}}
        >
          <Row className='w-100'>
            <Col md={8}>
              <h2 className='mx-0 my-1 mt-2 text-white'>Invoice</h2>
            </Col>
            <Col md={4} className='text-end pe-0'>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary ps-auto modal-header-cross'
                onClick={() => {
                  setVoidInvoiceModal(false)
                }}
              >
                <img className='svg-icon-1 text-black' src={close} width='14px' height='14px' />
              </div>
            </Col>
          </Row>
        </div>

        <div className='invoice-bg'>
          <Row className='mx-2'>
            <Col md={9}></Col>
            <Col md={2} className='pt-2' style={{paddingLeft: '80px'}}>
              <img src={invoiceWhite} height='80px' width='65px' className='me-3 ms-5' />
            </Col>
          </Row>
        </div>
        <div
          className='modal-body px-lg-6'
          style={{
            maxHeight: '600px',
            overflow: 'scroll',
            transform: 'translate(0px, -94px)',
            marginBottom: '-100px',
          }}
        >
          <Row className='mt-2'>
            <Col md={8}>
              <button
                className='btn btn-sm fw-bold ps-2 pe-4 text-white'
                style={{width: '120px', backgroundColor: '#008081', fontSize: '13px'}}
                onClick={() => {
                  validateNewInvoice(1)
                }}
              >
                Generate
              </button>

              <button
                className='btn btn-sm fw-bold ps-1 pe-4 ms-3'
                style={{
                  width: '120px',
                  backgroundColor: '#8a8aa3',
                  color: 'white',
                  fontSize: '13px',
                }}
                onClick={() => {
                  validateNewInvoice(0)
                }}
              >
                <img
                  src={saveImg}
                  style={{
                    height: '18px',
                    width: '18px',
                    marginRight: '12px',
                    marginTop: '-2px',
                  }}
                />{' '}
                {'  '}
                Save
              </button>
            </Col>
            <Col md={4}>
              <div className='d-flex align-items-center mt-1 justify-content-end'>
                <Switch
                  onChange={(e) => {
                    setCheckedRec(e)
                  }}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  checked={checkedRec}
                  onColor='#008081'
                  offColor='#b0bec5'
                />{' '}
                <i className='ms-3 me-1 text-dark'>Share copy with recipient</i>
              </div>
            </Col>
          </Row>
          <div
            className='card px-6 py-3 mt-5'
            style={{boxShadow: '0px 0px 20px 0px darkgray !important;'}}
          >
            <Row className='mt-2'>
              <Col md={5} className='pe-4'>
                <div className='d-flex align-items-center my-2 '>
                  <h1 className=''>INVOICE</h1>
                  {invoiceView == false && (
                    <img
                      src={editpen}
                      alt=''
                      className='ms-2 cursor-pointer'
                      style={{height: '14px', width: '14px', marginTop: '-25px'}}
                    />
                  )}
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2'>
                  {invRecDetails?.invoiceSequence?.activityStatus == 1 ? (
                    <>
                      {invRecDetails?.invoiceSequence?.prefix != null && (
                        <>
                          <label
                            className='mb-1 me-5 pe-5 label-color'
                            style={{minWidth: '100px', fontSize: '1rem'}}
                          >
                            Invoice No.
                          </label>

                          <input
                            type='text'
                            className={
                              invoiceNoError
                                ? 'form-control form-control-solid-bg input-style border-danger '
                                : 'form-control form-control-solid-bg input-style mytest'
                            }
                            placeholder='Enter Invoice No'
                            name='item_id'
                            value={invRecDetails?.invoiceSequence?.prefix}
                            disabled
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <label
                        className='mb-1 me-5 pe-5 label-color'
                        style={{minWidth: '100px', fontSize: '1rem'}}
                      >
                        Invoice No.
                      </label>

                      <input
                        type='text'
                        className={
                          invoiceNoError
                            ? 'form-control form-control-solid-bg input-style border-danger '
                            : 'form-control form-control-solid-bg input-style mytest'
                        }
                        placeholder='Enter Invoice No'
                        name='item_id'
                        value={invoiceNo}
                        onChange={(event) => {
                          setInvoiceNo(event.target.value)
                          setInvoiceNoError(false)
                        }}
                      />
                    </>
                  )}
                </div>

                {/*  */}
                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 pe-5' style={{minWidth: '100px', fontSize: '1rem'}}>
                    Invoice Date
                  </label>
                  <div style={{fontWeight: '500'}}>
                    <p style={{fontWeight: '800', fontSize: '1rem'}} className='mb-1'>
                      {moment(new Date()).format('DD.MM.YYYY')}
                    </p>
                  </div>
                </div>

                <div className='d-flex align-items-center justify-content-start my-2 label-color'>
                  <label className='mb-1 me-5 pe-5  ' style={{minWidth: '100px', fontSize: '1rem'}}>
                    Due Date
                  </label>
                  <div style={{fontWeight: '500'}}>
                    <p style={{fontWeight: '800', fontSize: '1rem'}} className='mb-1'>
                      {moment(new Date(detailsToEdit?.dueDate)).format('DD.MM.YYYY')}
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={7}>
                <div className='d-flex my-4 '>
                  {invRecDetails?.invoiceLogo != null && (
                    <img
                      src={`${Bucket}${invRecDetails?.invoiceLogo}`}
                      height='140'
                      width='140'
                      style={{borderRadius: '7px'}}
                      className='ms-auto'
                    />
                  )}
                </div>
              </Col>
            </Row>

            <Row className='mt-3 pb-5'>
              <Col md={6} className='text-stary pe-3' style={{fontWeight: '600'}}>
                <p style={{marginBottom: '0.1rem'}}>
                  {' '}
                  {invoiceCompany?.name != null && invoiceCompany?.name}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {' '}
                  {invoiceCompany?.address != null && invoiceCompany?.address}
                </p>

                <p style={{marginBottom: '0.1rem'}}>
                  {' '}
                  {invoiceCompany?.phoneNumber != null &&
                    invoiceCompany?.countryCode + ' ' + invoiceCompany?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {' '}
                  {invoiceCompany?.email != null && invoiceCompany?.email}
                </p>
              </Col>
              <Col md={6} style={{fontWeight: '600'}} className='text-end'>
                <h5 style={{fontWeight: '700'}}>Recipient</h5>

                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.firstName} {tenantDetails?.lastName}
                </p>
                <p style={{marginBottom: '0.1rem'}}>
                  {tenantDetails?.countryCode} {tenantDetails?.phoneNumber}
                </p>
                <p style={{marginBottom: '0.1rem'}}>{tenantDetails?.email}</p>
              </Col>
            </Row>
            <Row className='pb-5' style={{borderBottom: '2px dashed lightgray'}}>
              <Col sm={12}>
                <div className='d-flex align-items-start my-2'>
                  <label
                    className='mb-1 me-5 pe-5 head-text'
                    style={{minWidth: '50px', maxWidth: '50px'}}
                  >
                    <b>TRN</b>
                  </label>
                  <b>{invoiceCompany?.trn != null ? invoiceCompany?.trn : '-'}</b>
                </div>
              </Col>
            </Row>

            <table
              className='table align-middle fs-6 gy-5 mt-3 pb-5'
              id='kt_ecommerce_sales_table'
              style={{borderBottom: '1.5px dashed lightgray'}}
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-50px'>#</th>
                  <th className='min-w-100px'>Item</th>
                  <th className='min-w-100px'>Qty</th>
                  <th className='min-w-100px'>Rate</th>
                  <th className='min-w-100px'>Vat</th>
                  <th className='min-w-100px'>Amount</th>
                </tr>
              </thead>
              <tbody style={{fontWeight: '500'}}>
                {invoiceFields.map((field: any, index: any) => {
                  if (field?.isSelected == 1) {
                    let y: any = ((field.amount * field.qty) / 100) * field.vat
                    let total: number = parseInt(y) + field.amount * field.qty
                    return (
                      <tr style={{borderBottom: '1px solid lightgray', height: '45px'}}>
                        <td className='py-2'>{index + 1}</td>
                        <td className='py-2'>{field.name}</td>
                        <td className='py-2'>{field.qty}</td>
                        <td className='py-2'>
                          {field?.isLateItem
                            ? (field.amount / field?.qty)?.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                              })
                            : field.amount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                        </td>
                        <td className='py-2'>
                          {field?.isLateItem
                            ? ((field.amount / 100) * field.vat)?.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                              })
                            : (((field.amount * field.qty) / 100) * field.vat)?.toLocaleString(
                                'en-US',
                                {minimumFractionDigits: 2}
                              )}
                        </td>
                        <td className='py-2'>
                          {field?.isLateItem
                            ? (field.amount + (field.amount / 100) * field.vat)?.toLocaleString(
                                'en-US',
                                {minimumFractionDigits: 2}
                              )
                            : total?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                        </td>
                      </tr>
                    )
                  }
                })}

                <>
                  {invoiceFields.map((f: any, ind: any) => {
                    vatAmount = 0
                    nonVat = 0
                    return <></>
                  })}
                </>
                {invoiceFields?.length > 0 && (
                  <>
                    {invoiceFields.map((f: any, ind: any) => {
                      if (f?.isSelected == 1) {
                        if (f?.isLateItem === true) {
                          vatAmount = vatAmount + (f.amount / 100) * f.vat
                          nonVat = nonVat + f.amount
                        } else {
                          vatAmount = vatAmount + ((f.amount * f.qty) / 100) * f.vat
                          nonVat = nonVat + f.amount * f.qty
                        }
                      }
                      return <></>
                    })}
                  </>
                )}

                <tr style={{height: '55px'}}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5' style={{color: '#B5B5C3'}}>
                    SUB TOTAL
                  </td>
                  <td className='py-2'>
                    {nonVat?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>

                <tr style={{height: '30px'}}>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5' style={{color: '#B5B5C3'}}>
                    VAT
                  </td>
                  <td className='py-2'>
                    {vatAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                  </td>
                </tr>
                <tr style={{height: '55px'}} className='pb-4'>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='py-2 ps-5'>Total</td>
                  <td className='py-2 fs-3' style={{fontWeight: '800px'}}>
                    {' '}
                    <b>
                      AED{' '}
                      {(vatAmount + nonVat)?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>

            <Row>
              <Col md={7}>
                <div className='row mt-3'>
                  <div className='col-md-8 ps-5 fs-5 d-flex align-items-center head-text'>
                    <label
                      className='mb-1 me-5 head-text'
                      style={{minWidth: '120px', fontWeight: '800'}}
                    >
                      Payment Method
                    </label>
                    <div style={{width: '-webkit-fill-available'}}>
                      <Select
                        options={cardItems}
                        styles={customStylesInputFilled2}
                        isSearchable={false}
                        defaultValue={
                          invoicePaymentMethod == 0
                            ? cardItems[0]
                            : paymentMethod == 1
                            ? cardItems[1]
                            : paymentMethod == 2
                            ? cardItems[2]
                            : cardItems[3]
                        }
                        onChange={(event: any) => {
                          setInvoicePaymentMethod(event.value)
                          if (event.value == 2) getBankAccounts()
                          else {
                            setShowAccInvoice(false)
                            setSelectedAccountInvoice(null)
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='row mt-3'>
                  <div className='col-md-8 ps-5 fs-5'>
                    {/* Payment Method: Bank */}
                    {invoicePaymentMethod == 2 ? (
                      <>
                        <div className='align-items-center d-flex mb-3'>
                          <label
                            className='mb-1 me-2 pe-5 d-flex head-text'
                            style={{minWidth: '120px', fontWeight: '800'}}
                          >
                            Bank Account
                          </label>

                          {bankAccountItems &&
                            bankAccountItems.map((bA: any, index: any) => {
                              if (selectedAccountInvoice?._id == bA._id) accountInd = index
                              return <></>
                            })}

                          <div style={{width: '-webkit-fill-available'}}>
                            {accountInd != null ? (
                              <Select
                                defaultValue={{
                                  label: bankAccountItems[accountInd]?.accountHolderName,
                                  value: bankAccountItems[accountInd]?._id,
                                  id: bankAccountItems[accountInd],
                                }}
                                options={
                                  bankAccountItems &&
                                  bankAccountItems.map((bA: any, index: any) => {
                                    return {
                                      label: `${bA?.accountHolderName}`,
                                      value: `${bA?._id}`,
                                      id: bA,
                                    }
                                  })
                                }
                                styles={customStylesInputFilled2}
                                isSearchable={false}
                                onChange={(event: any) => {
                                  setShowAccInvoice(true)
                                  setSelectedAccountInvoice(event.id)
                                }}
                              />
                            ) : (
                              <Select
                                options={
                                  bankAccountItems &&
                                  bankAccountItems.map((bA: any, index: any) => {
                                    // console.log(pItem)
                                    return {
                                      label: `${bA?.accountHolderName}`,
                                      value: `${bA?._id}`,
                                      id: bA,
                                    }
                                  })
                                }
                                styles={customStyles2}
                                isSearchable={false}
                                onChange={(event: any) => {
                                  setShowAccInvoice(true)
                                  setSelectedAccountInvoice(event.id)
                                }}
                              />
                            )}
                          </div>
                        </div>
                        {showAccInvoice && (
                          <>
                            <div
                              className='d-flex align-items-center mb-3 head-text'
                              style={{fontWeight: '800'}}
                            >
                              <label
                                className='mb-1 me-1 pe-5'
                                style={{minWidth: '120px', fontWeight: '600'}}
                              >
                                Account Name
                              </label>
                              {selectedAccountInvoice?.accountHolderName}
                            </div>
                            <div
                              className='d-flex align-items-center my-2 head-text'
                              style={{fontWeight: '800'}}
                            >
                              <label
                                className='mb-1 me-1 pe-5'
                                style={{minWidth: '120px', fontWeight: '600'}}
                              >
                                IBAN
                              </label>
                              {selectedAccountInvoice?.IBAN}
                            </div>
                            <div
                              className='d-flex align-items-center mb-3 head-text'
                              style={{fontWeight: '800'}}
                            >
                              <label
                                className='mb-1 me-1 pe-5'
                                style={{minWidth: '120px', fontWeight: '600'}}
                              >
                                Account No.
                              </label>
                              {selectedAccountInvoice?.accountNumber}
                            </div>
                            <div
                              className='d-flex align-items-center mb-3 head-text'
                              style={{fontWeight: '800'}}
                            >
                              <label
                                className='mb-1 me-1 pe-5'
                                style={{minWidth: '120px', fontWeight: '600'}}
                              >
                                Bank Name
                              </label>
                              {selectedAccountInvoice?.bankName}
                            </div>
                          </>
                        )}
                      </>
                    ) : invoicePaymentMethod == 1 ? (
                      <>
                        <div className='d-flex align-items-center justify-content-center mb-2'>
                          <label
                            className='mb-1 me-5 pe-5  blue-label'
                            style={{minWidth: '120px', fontWeight: '800'}}
                          >
                            Bank Name
                          </label>

                          <input
                            type='text'
                            className='form-control form-control-solid-bg input-style mytest'
                            placeholder='Enter Name'
                            name='item_id'
                            value={invoiceChequeBankName}
                            onChange={(event) => {
                              setInvoiceChequeBankName(event?.target.value)
                            }}
                          />
                        </div>

                        <div className='d-flex justify-content-center my-4 '>
                          <form
                            id='form-file-upload'
                            onDragEnter={handleDrag}
                            onSubmit={(e) => e.preventDefault()}
                            style={{height: '90px', marginLeft: '26px'}}
                          >
                            <label
                              id='label-file-upload'
                              htmlFor='input-file-upload'
                              className={
                                dragActive
                                  ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                  : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                              }
                              style={{
                                fontSize: '16px',
                                color: 'white',
                              }}
                              ref={inputRef}
                            >
                              <div
                                style={{
                                  height: '90px',
                                  width: '280px',
                                  border: '2px dashed #384a62',
                                  backgroundColor: '#f5f8fa',
                                }}
                                className=''
                              >
                                <div
                                  className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                                  style={{minWidth: '90px'}}
                                >
                                  Drag & drop your document
                                </div>
                                <div
                                  className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                                  style={{minWidth: '90px'}}
                                >
                                  <div
                                    className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                                    style={{
                                      fontSize: '1rem',
                                      paddingLeft: '15px',
                                      borderRadius: '7px',
                                      backgroundColor: '#35475e',
                                      boxShadow: '0px 0px 20px 0px #516176',
                                    }}
                                  >
                                    Browse Files
                                  </div>
                                </div>
                              </div>
                            </label>
                            <input
                              type='file'
                              multiple={true}
                              onChange={handleInvoiceChequeChange}
                              id='input-file-upload'
                              ref={inputRef}
                              style={{
                                height: '90px',
                                opacity: '-0.9',
                                transform: 'translate(-2px, -90px)',
                              }}
                            />
                            {dragActive && (
                              <div
                                id='drag-file-element'
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              ></div>
                            )}
                          </form>
                        </div>
                        {invoiceChequeMedia.length > 0 && (
                          <div
                            className='row align-items-center my-2'
                            style={
                              invoicePaymentMethod == 1
                                ? {
                                    overflowY: 'scroll',
                                    minHeight: '96px',
                                    maxHeight: '200px',
                                  }
                                : {overflowY: 'scroll', minHeight: '96px', maxHeight: '200px'}
                            }
                          >
                            {invoiceChequeMedia.map((chq: any, ind: any) => (
                              <>
                                <div className='img-sets mw-350px col-md-4 px-5 py-3'>
                                  <div className='d-flex'>
                                    <a
                                      href={`${Bucket}${chq?.image}`}
                                      target='_blank'
                                      style={{color: '#384a62'}}
                                      className='d-flex'
                                    >
                                      <img
                                        src={chequeImg}
                                        width='80px'
                                        height='40px'
                                        className='main_img cursor-pointer'
                                        alt='image'
                                        style={{objectFit: 'cover'}}
                                      />
                                    </a>
                                    <div className='h-0'>
                                      <img
                                        src={redCross}
                                        width='12px'
                                        height='12px'
                                        className='main_img cursor-pointer'
                                        style={{
                                          marginTop: '-25px',
                                          marginLeft: '5px',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => deleteChequeInvoice(chq)}
                                      />
                                    </div>
                                  </div>
                                  <input
                                    type='text'
                                    className='form-control form-control-solid-bg input-style mt-1 mytest'
                                    placeholder='Cheque No'
                                    name='item_id'
                                    value={chq.name}
                                    onChange={(event) => {
                                      fileToRename = chq
                                      // console.log(event)
                                      const values = [...invoiceChequeMedia]
                                      // let x = values[ind]?.src?.name?.split('.')[1]
                                      values[ind].name = event.target.value

                                      setInvoiceChequeMedia(values)
                                    }}
                                    onBlur={() => {
                                      let fileToRenam = chq
                                      const values = [...invoiceChequeMedia]
                                      let x = values[ind]?.src?.name?.split('.')[1]
                                      renameCheque(fileToRenam, x)
                                    }}
                                    style={{height: '18px', fontSize: '8px', width: '80px'}}
                                  />
                                </div>
                              </>
                            ))}
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </Col>
              <Col md={5}>
                {/* Notes */}
                <div className='my-2 mb-5 h-10'>
                  <label className='mb-4 me-1 fs-4' style={{minWidth: '160px', fontWeight: '700'}}>
                    Notes
                  </label>
                  {invoiceView ? (
                    <p style={{marginBottom: '0.1rem'}}>{invoiceNotes}</p>
                  ) : (
                    <textarea
                      className='form-control form-control-solid-bg input-style mytest'
                      name='comment'
                      placeholder='Enter any additional notes to the recipient'
                      style={{height: '130px', borderColor: 'lightgray'}}
                      value={invoiceNotes}
                      onChange={(e) => setInvoiceNotes(e.target.value)}
                      maxLength={1000}
                    ></textarea>
                  )}
                </div>

                {invoiceView == false && (
                  <>
                    <div className='d-flex my-2 pt-4'>
                      <label
                        className='mb-1 me-1 fs-4'
                        style={{minWidth: '160px', fontWeight: '700'}}
                      >
                        Attachments
                      </label>
                    </div>

                    <div className='d-flex justify-content-center my-4 '>
                      <form
                        id='form-file-upload-invoice'
                        onDragEnter={handleDrag}
                        onSubmit={(e) => e.preventDefault()}
                        style={{height: '90px'}}
                      >
                        <input
                          type='file'
                          multiple={true}
                          onChange={handleInvoiceAttachmentChange}
                          id='mediaAttachInvoice'
                          style={{
                            height: '90px',
                            opacity: '-0.9',
                            position: 'relative',
                            zIndex: '1',
                          }}
                        />

                        <label
                          htmlFor='mediaAttachInvoice'
                          className={
                            dragActive
                              ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                              : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                          }
                          style={{
                            fontSize: '16px',
                            color: 'white',
                            transform: 'translate(-2px, -90px)',
                          }}
                        >
                          <div
                            style={{
                              height: '90px',
                              width: '280px',
                              border: '2px dashed #384a62',
                              backgroundColor: '#f5f8fa',
                            }}
                            className=''
                          >
                            <div
                              className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                              style={{minWidth: '90px'}}
                            >
                              Drag & drop your document
                            </div>
                            <div
                              className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                              style={{minWidth: '90px'}}
                            >
                              <div
                                className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center'
                                style={{
                                  fontSize: '1rem',
                                  paddingLeft: '15px',
                                  borderRadius: '7px',
                                  backgroundColor: '#35475e',
                                  boxShadow: '0px 0px 20px 0px #516176',
                                }}
                              >
                                Browse Files
                              </div>
                            </div>
                          </div>
                        </label>
                        {dragActive && (
                          <div
                            id='drag-file-element'
                            onDragEnter={handleDrag}
                            onDragLeave={handleDrag}
                            onDragOver={handleDrag}
                            onDrop={handleDrop}
                          ></div>
                        )}
                      </form>
                    </div>
                    {invoiceAttachedMedia.length > 0 && (
                      <div className='row align-items-center mt-2'>
                        {invoiceAttachedMedia.map((chq: any, ind: any) => (
                          <>
                            <div className='img-sets mw-350px col-md-4 px-2 py-3'>
                              <div className='d-flex'>
                                <a
                                  href={`${Bucket}${chq?.src}`}
                                  target='_blank'
                                  style={{color: '#384a62'}}
                                  className='d-flex'
                                >
                                  <img
                                    src={file}
                                    width='42px'
                                    height='55px'
                                    className='main_img cursor-pointer'
                                    alt='image'
                                    style={{marginLeft: '26px'}}
                                  />
                                </a>
                                <div className='h-0'>
                                  <img
                                    src={redCross}
                                    width='12px'
                                    height='12px'
                                    className='main_img cursor-pointer'
                                    style={{marginTop: '-25px', marginLeft: '-3px'}}
                                    onClick={() => deleteInvoiceAttachment(chq)}
                                  />
                                </div>
                              </div>

                              <p
                                style={{
                                  fontSize: '12px',
                                  wordBreak: 'break-all',
                                  padding: '0px 5px',
                                }}
                                className='mt-1'
                              >
                                {chq.name}{' '}
                              </p>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Modal>

      {/* View Single Invoice */}
      {viewDraftInvoice && (
        <ViewDraftInvoice
          show={viewDraftInvoice}
          handleClose={() => {
            setViewDraftInvoice(false)
          }}
          invoice={invoiceToShowEdit}
          dueDate={detailsToEdit?.dueDate}
          paymentId={detailsToEdit?._id}
          tenantDetails={tenantDetails}
          paymentMethod={paymentMethod}
          getPaymentInvoices={getPaymentById}
          invRecDetails={invRecDetails}
          itemToAdd={itemToAdd}
          detailsToEdit={updDetailsToEdit}
          updateRecord={() => {
            refetchPaymentItems()
          }}
          disableAction={showEdit}
          from={type}
        />
      )}

      {/* LINK invoice to SubItem */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-750px mx-auto'
        show={linkMissingItemToInvoice}
        onHide={() => {
          setLinkMissingItemToInvoice(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-5  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setLinkMissingItemToInvoice(false)
                }}
              >
                X
              </label>
            </div>
          </div>
          <Row className='my-5 pb-4 mx-2'>
            <Col md={6}>
              <label className='head-text fs-2 ' style={{fontWeight: '600'}}>
                Select Invoice
              </label>
            </Col>

            <Col lg={6} md={6} className='align-items-center text-end'>
              <button
                className='btn btn-sm fw-bold ms-auto me-1 hollow-btn'
                onClick={(event) => {
                  setLinkMissingItemToInvoice(false)
                  setVoidInvoiceModal(true)
                }}
                disabled={invoiceLinkChecked < 0 ? false : true}
              >
                {'  '}
                Add to New Invoice
              </button>
            </Col>
          </Row>

          {invoiceLinkData?.length > 0 ? (
            <div className='d-flex my-5 row'>
              <h6 className='m-0 p-0 col-1'></h6>
              <h6
                className='m-0 p-0 col-2 head-text px-2 text-decoration-underline fs-5'
                style={{fontWeight: '600'}}
              >
                Invoice No
              </h6>
              <h6
                className='m-0 p-0 col-2 head-text px-2 text-decoration-underline fs-5'
                style={{fontWeight: '600'}}
              >
                Total Amount
              </h6>

              <h6
                className='m-0 p-0 col-2 head-text px-3 text-decoration-underline fs-5'
                style={{fontWeight: '600'}}
              >
                Items
              </h6>
              <h6
                className='m-0 p-0 col-2 head-text ps-5 text-decoration-underline fs-5 '
                style={{fontWeight: '600'}}
              >
                Date Created
              </h6>
              <h6
                className='m-0 p-0 col-3 head-text px-3 text-decoration-underline fs-5'
                style={{fontWeight: '600'}}
              ></h6>
            </div>
          ) : (
            <div className='d-flex my-5 row'>
              <div className=''>
                <h6
                  className='m-0 p-0 col-12 head-text px-2 fs-5 text-center'
                  style={{fontWeight: '600'}}
                >
                  {' '}
                  No Draft Invoices to Link. Please add to a New Invoice
                </h6>
              </div>
            </div>
          )}

          <div className='py-5 mx-1'>
            {invoiceLinkData?.length > 0 &&
              invoiceLinkData.map((ut: any, index: any) => {
                return (
                  <>
                    <div className='d-flex my-3 row align-items-center '>
                      <div className='col-1'>
                        {ut?.invoiceNo != null && (
                          <>
                            {invoiceLinkChecked == index ? (
                              <div
                                style={{
                                  height: '16px',
                                  width: '16px',
                                  backgroundColor: '#384a62',
                                  borderRadius: '50%',
                                }}
                                className='mx-5 '
                              ></div>
                            ) : (
                              <div
                                style={{
                                  height: '16px',
                                  width: '16px',
                                  backgroundColor: 'transparent',
                                  borderRadius: '50%',
                                  border: '1px solid #384a62',
                                }}
                                onClick={() => {
                                  setInvoiceLinkChecked(index)

                                  ApiGet(`corporate/payment_invoice/${ut?._id}`)
                                    .then((res) => {
                                      setInvoiceToShowEdit(res?.data?.data)

                                      // console.log(res?.data?.data)
                                      const values = [...receiptFields]
                                      for (
                                        let i = 0;
                                        i < res?.data?.data?.paymentSubItems?.length;
                                        i++
                                      ) {
                                        values[i] = {
                                          _id: res?.data?.data?.paymentSubItems[i]?._id,
                                          name: res?.data?.data?.paymentSubItems[i]?.name,
                                          amount: res?.data?.data?.paymentSubItems[i]?.amount,
                                          qty: res?.data?.data?.paymentSubItems[i]?.quantity,
                                          vat: res?.data?.data?.paymentSubItems[i]?.VATPercentage,
                                          isLateItem:
                                            res?.data?.data?.paymentSubItems[i]
                                              ?.isLatePaymentFeesRecord,
                                          total: res?.data?.data?.paymentSubItems[i]?.totalAmount,
                                          amountPaid: 0,
                                          outstanding:
                                            res?.data?.data?.paymentSubItems[i]?.outstandingAmount,
                                          previousPaidAmount:
                                            res?.data?.data?.paymentSubItems[i]?.amountPaid,
                                          previousOutstanding:
                                            res?.data?.data?.paymentSubItems[i]?.outstandingAmount,
                                        }
                                      }
                                      setReceiptFields(values)
                                      console.log(values)
                                    })
                                    .catch((err: any) => {
                                      console.log('err', err)
                                    })
                                }}
                                className='mx-5 '
                              ></div>
                            )}
                          </>
                        )}
                      </div>
                      <div className=' m-0 p-0  col-2 head-text px-2'>{ut?.invoiceNo}</div>

                      <h6 className='m-0 p-0 col-2 head-text px-2'>
                        {ut?.totalAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                      </h6>

                      <h6 className='m-0 p-0 col-2 head-text px-3'>
                        {' '}
                        {ut?.paymentSubItems?.length}
                      </h6>
                      <h6 className='m-0 p-0 col-2 head-text ps-5'>
                        {moment(ut?.createdAt).format('DD-MM-YYYY')}
                      </h6>
                      <h6 className='m-0 p-0 col-3 head-text px-3'>
                        <button
                          className='btn btn-sm fw-bold ms-auto ps-2 text-white me-1'
                          style={{
                            fontSize: '1rem',
                            paddingRight: '15px',
                            paddingLeft: '15px',

                            borderRadius: '2px',
                            backgroundColor: '#35475e',
                            boxShadow: '0px 0px 20px 0px #516176',
                            width: '100px',
                          }}
                          onClick={(event) => {
                            setLinkMissingItemToInvoice(false)
                            setViewDraftInvoice(true)
                          }}
                          disabled={invoiceLinkChecked == index ? false : true}
                        >
                          Add
                        </button>
                      </h6>
                    </div>
                  </>
                )
              })}
          </div>

          {/* Pagination */}
          {/* <div className='row mb-5'>
           <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
           <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
             <div
               className='dataTables_paginate paging_simple_numbers'
               id='kt_ecommerce_sales_table_paginate'
             >
               <ReactPaginate
                 breakLabel='...'
                 nextLabel='>'
                 pageClassName='paginate_button page-item +'
                 pageLinkClassName='page-link'
                 containerClassName='pagination'
                 activeClassName='active'
                 previousClassName='paginate_button page-item previous disabled'
                 nextClassName='paginate_button page-item next'
                 previousLinkClassName='page-link'
                 nextLinkClassName='page-link'
                 onPageChange={handlePageClick}
                 pageRangeDisplayed={5}
                 pageCount={pageLimit}
                 previousLabel='<'
               />
             </div>
           </div>
         </div> */}
        </div>
      </Modal>

      {/* Select Invoice for Debit Note */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-750px mx-auto'
        show={selectInvoiceModal}
        onHide={() => {
          setSelectInvoiceModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setSelectInvoiceModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='my-5 pb-4 row me-2'>
            <div className='col-md-6 pt-3'>
              <label className='head-text fs-4 d-block' style={{fontWeight: '700'}}>
                Select Invoice
              </label>
            </div>

            <div className='col-md-6 align-items-center text-end'>
              <button
                className='btn btn-md fw-bold ms-auto ps-2 text-white me-1'
                style={{
                  fontSize: '1rem',
                  paddingRight: '15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                  width: 'inherit',
                }}
                onClick={(event) => {
                  if (invoiceLinkChecked < 0) ErrorToast('Please Select an Invoice!!!')
                  else {
                    for (let i = 0; i < debitFields?.length; i++) {
                      debitFields[i].debitAmount = 0
                      debitFields[i].debitVat = 0
                    }
                    setDebitSubItemsModal(true)
                    setSelectInvoiceModal(false)
                    setTotalDebitAmount(0)
                    // handleClose()
                  }
                }}
              >
                Select
              </button>
            </div>
          </div>

          <div className='d-flex mt-5 row '>
            <h6 className='m-0 p-0 col-1'></h6>
            <h6
              className='m-0 p-0 col-1 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              #
            </h6>
            <h6
              className='m-0 p-0 col-2 head-text px-2 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Total Amount
            </h6>

            <h6
              className='m-0 p-0 col-2 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Invoice No
            </h6>
            <h6
              className='m-0 p-0 col-2 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Date Generated
            </h6>
            <h6
              className='m-0 p-0 col-4 head-text ps-5 text-decoration-underline fs-5 '
              style={{fontWeight: '600'}}
            >
              Receipt Amount
            </h6>
          </div>

          <div className='py-5'>
            {invoiceLinkData?.length > 0 &&
              invoiceLinkData.map((ut: any, index: any) => {
                return (
                  <>
                    {ut?.invoiceNo?.length > 0 && (
                      <>
                        <div className='d-flex my-5 row '>
                          <div className='col-1'>
                            {ut?.invoiceNo != null && (
                              <>
                                {invoiceLinkChecked == index ? (
                                  <div
                                    style={{
                                      height: '16px',
                                      width: '16px',
                                      backgroundColor: '#384a62',
                                      borderRadius: '50%',
                                    }}
                                    className='mx-5 '
                                  ></div>
                                ) : (
                                  <div
                                    style={{
                                      height: '16px',
                                      width: '16px',
                                      backgroundColor: 'transparent',
                                      borderRadius: '50%',
                                      border: '1px solid #384a62',
                                    }}
                                    onClick={() => {
                                      setInvoiceLinkChecked(index)
                                      ApiGet(`corporate/payment_invoice/${ut?._id}`)
                                        .then((res) => {
                                          setInvoiceToShowEdit(res?.data?.data)
                                          // console.log(res?.data?.data)
                                          const values = [...debitFields]
                                          for (
                                            let i = 0;
                                            i < res?.data?.data?.paymentSubItems?.length;
                                            i++
                                          ) {
                                            values[i] = {
                                              _id: res?.data?.data?.paymentSubItems[i]?._id,
                                              name: res?.data?.data?.paymentSubItems[i]?.name,
                                              amount: res?.data?.data?.paymentSubItems[i]?.amount,
                                              qty: res?.data?.data?.paymentSubItems[i]?.quantity,
                                              vat: res?.data?.data?.paymentSubItems[i]
                                                ?.VATPercentage,
                                              isLateItem:
                                                res?.data?.data?.paymentSubItems[i]
                                                  ?.isLatePaymentFeesRecord,
                                              total:
                                                res?.data?.data?.paymentSubItems[i]?.totalAmount,
                                              amountPaid:
                                                res?.data?.data?.paymentSubItems[i]?.amountPaid,
                                              debitAmount: 0,
                                              debitVat: 0,
                                              vatAmount:
                                                res?.data?.data?.paymentSubItems[i]?.VATAmount,
                                              totalWithoutVat: res?.data?.data?.paymentSubItems[i]
                                                ?.isLatePaymentFeesRecord
                                                ? res?.data?.data?.paymentSubItems[i]?.amount
                                                : res?.data?.data?.paymentSubItems[i]?.amount *
                                                  res?.data?.data?.paymentSubItems[i]?.quantity,
                                              newTotal:
                                                res?.data?.data?.paymentSubItems[i]?.totalAmount,
                                              newVat:
                                                res?.data?.data?.paymentSubItems[i]?.VATPercentage,
                                              previousDebitAmount:
                                                res?.data?.data?.paymentSubItems[i]?.debitAmount +
                                                res?.data?.data?.paymentSubItems[i]?.debitVATAmount,
                                              totalAmount: 0,
                                              finalAmount: 0,
                                              outstanding:
                                                res?.data?.data?.paymentSubItems[i]
                                                  ?.outstandingAmount,
                                            }
                                            values[i].totalAmount =
                                              values[i]?.totalWithoutVat +
                                              values[i]?.previousDebitAmount
                                            values[i].finalAmount =
                                              values[i].totalAmount + values[i]?.vatAmount
                                            values[i].outstanding =
                                              values[i].finalAmount - values[i]?.amountPaid
                                          }
                                          setDebitFields(values)
                                        })
                                        .catch((err: any) => {
                                          console.log('err', err)
                                        })
                                    }}
                                    className='mx-5 '
                                  ></div>
                                )}
                              </>
                            )}
                          </div>
                          <div className='col-1 head-text px-2'>{index + 1}</div>

                          {/* <h6 className='m-0 p-0 col-2 head-text px-2'>
                        {ut?.dateApplied != null
                          ? moment(ut?.dateApplied).format('DD.MM.YYYY')
                          : '-'}
                      </h6> */}
                          <h6 className='m-0 p-0 col-2 head-text px-2'>
                            {ut?.totalAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </h6>
                          <h6 className='m-0 p-0 col-2 head-text px-3'>{ut?.invoiceNo}</h6>
                          <h6 className='m-0 p-0 col-2 head-text px-3'>
                            {moment(ut?.createdAt).format('DD.MM.YYYY')}
                          </h6>
                          <h6 className='m-0 p-0 col-4 head-text ps-5'>
                            {ut?.receiptAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </h6>
                        </div>
                      </>
                    )}
                  </>
                )
              })}
          </div>
        </div>
      </Modal>

      {/* SubItems modal for debit note */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-950px mx-auto'
        show={debitSubItemsModal}
        onHide={() => {
          setDebitSubItemsModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setDebitSubItemsModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='my-5 pb-4 row me-0'>
            <div className='col-md-6 ps-5'>
              <label className='head-text fs-4 d-block' style={{fontWeight: '700'}}>
                Invoice {invoiceToShowEdit?.invoiceNo}
              </label>
              <label className='head-text fs-5 d-block mt-3' style={{fontWeight: '700'}}>
                Total{' '}
                {invoiceToShowEdit?.totalAmount?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                })}
              </label>
            </div>

            <div className='col-md-3 align-items-center text-center ms-auto pe-0'>
              <button
                className='btn btn-md fw-bold ms-auto text-white me-1'
                style={{
                  fontSize: '1rem',
                  padding: '10px 15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                }}
                onClick={(event) => {
                  if (getPositive(totalDebitAmount) == 0) {
                    ErrorToast('Please input some value to DEBIT !!!')
                  } else {
                    setDebitNoteModal(true)
                    setDebitSubItemsModal(false)
                  }
                }}
              >
                Generate Debit Note
              </button>
              <label className='head-text fs-5 d-block mt-3' style={{fontWeight: '700'}}>
                Debit Amount{' '}
                <span className='ms-2'>
                  {totalDebitAmount?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}{' '}
                </span>
              </label>
            </div>
          </div>

          {/*  */}
          <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'>
            <table
              className='table align-start table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-capitalize gs-0 border-white'>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-100px '>
                    Item Name
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-50px '>
                    Qty
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-125px'>
                    {' '}
                    Invoice Amount
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-175px'>
                    {' '}
                    Previous Debit Amount
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-100px'>
                    {' '}
                    Total Amount
                  </th>

                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-50px'>
                    VAT
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-100px'>
                    {' '}
                    Final Amount
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-100px'>
                    {' '}
                    Amount Paid
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-100px'>
                    {' '}
                    Outstanding
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-150px'>
                    Debit Amount{' '}
                    <span>
                      <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 400}}
                        overlay={
                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                            {' '}
                            Enter the amount you wish to debit or add to the total amount of the
                            item in the selected invoice (Exc. VAT). Once debit amount is set and
                            debit note is generated, VAT will be automatically updated in the
                            payments page to reflect the new VAT amount on the new Total Amount. If
                            you have applied VAT on the item, then VAT is calculated separately
                          </Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <AiOutlineExclamationCircle
                            color='#35475e '
                            fontSize={14}
                            fontWeight={600}
                            className='ms-3 cursor-pointer'
                            style={{cursor: 'pointer', marginBottom: '2px'}}
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-100px'>
                    VAT
                  </th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {debitFields?.length > 0 &&
                  debitFields?.map((field: any, index: any) => {
                    return (
                      <>
                        <tr style={{height: '45px', borderBottom: '2px solid lightgray'}}>
                          <td className='m-0 p-0 head-text p-3'>{field?.name}</td>
                          <td className='m-0 p-0 head-text p-3'>{field.qty}</td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.totalWithoutVat?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.previousDebitAmount?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.totalAmount?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>

                          <td className='m-0 p-0 head-text p-3'>
                            {field?.vatAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {field?.finalAmount?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>

                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.amountPaid?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.outstanding?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            <input
                              type='number'
                              className='form-control form-control-bg input-style mytest'
                              placeholder='Debit Amount'
                              name='item_id'
                              value={field?.debitAmount}
                              onChange={(event: any) => {
                                const values = [...debitFields]
                                values[index].debitAmount = getPositive(event.target.value)

                                if (event.target.value.length == 0) values[index].debitAmount = 0

                                let newTotal =
                                  getPositive(parseFloat(values[index]?.totalWithoutVat)) +
                                  getPositive(parseFloat(values[index].debitAmount))
                                values[index].newTotalWithoutVat = newTotal

                                let newVat: any = 0

                                values[index].debitVat = 0

                                // if vat applied then calculate Credit vat
                                if (values[index]?.vat > 0) {
                                  newVat = (newTotal / 100) * values[index]?.vat
                                  values[index].newVat = newVat
                                  console.log(newVat)
                                  values[index].debitVat = getPositive(
                                    newVat - parseFloat(values[index]?.vatAmount)
                                  )
                                }

                                values[index].newTotal = newTotal + newVat
                                setDebitFields(values)

                                let temp: any = 0
                                for (let i = 0; i < values?.length; i++) {
                                  if (values[i]?.debitAmount != undefined)
                                    temp =
                                      parseFloat(temp) +
                                      parseFloat(values[i]?.debitAmount) +
                                      parseFloat(values[i]?.debitVat)
                                }
                                setTotalDebitAmount(temp)
                              }}
                            />
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.debitVat?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      </>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>

      {/* Debit Note Modal */}
      {debitNoteModal && (
        <DebitNote
          show={debitNoteModal}
          handleClose={() => {
            setDebitNoteModal(false)
          }}
          paymentId={detailsToEdit?._id}
          tenantDetails={tenantDetails}
          closeEdit={() => {
            handleClose()
            getAllPayments()
          }}
          debitFields={debitFields}
          invoiceData={invoiceToShowEdit}
          refetch={() => {
            refetchPaymentItems()
          }}
          from={type}
        />
      )}

      {/* View Single Debit Note */}
      {viewDebitNote && (
        <ViewDebitNote
          show={viewDebitNote}
          handleClose={() => {
            setViewDebitNote(false)
          }}
          debitNote={debitNoteToView}
          paymentId={detailsToEdit?._id}
          tenantDetails={tenantDetails}
          dueDate={detailsToEdit?.dueDate}
          from={type}
        />
      )}

      {/* View All Debit Notes of Payment */}
      {viewAllDebitNotes && (
        <ViewAllDebitNotesOfPayment
          show={viewAllDebitNotes}
          handleClose={() => {
            setViewAllDebitNotes(false)
          }}
          debitNotes={paymentDebitNotes}
          paymentId={detailsToEdit?._id}
          tenantDetails={tenantDetails}
          getPaymentDebitNotes={getPaymentById}
          from={type}
        />
      )}

      {/* Refund Modal */}
      {refundModal && (
        <Refund
          show={refundModal}
          handleClose={() => {
            setRefundModal(false)
          }}
          paymentId={detailsToEdit?._id}
          tenantDetails={tenantDetails}
          closeEdit={() => {
            handleClose()
            getAllPayments()
          }}
          receiptFields={receiptFields}
          receiptData={receiptToShowEdit}
          refetch={() => {
            refetchPaymentItems()
          }}
          invoiceId={invoiceForRefundPayload}
          from={type}
        />
      )}

      {/* Select Invoice for Debit Note */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-750px mx-auto'
        show={invoiceForRefund}
        onHide={() => {
          setInvoiceForRefund(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setInvoiceForRefund(false)
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='my-5 pb-4 row me-2'>
            <div className='col-md-6 pt-3'>
              <label className='head-text fs-4 d-block' style={{fontWeight: '700'}}>
                Select Invoice
              </label>
            </div>

            <div className='col-md-6 align-items-center text-end'>
              <button
                className='btn btn-md fw-bold ms-auto ps-2 text-white me-1'
                style={{
                  fontSize: '1rem',
                  paddingRight: '15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                  width: 'inherit',
                }}
                onClick={(event) => {
                  if (invoiceLinkChecked < 0) ErrorToast('Please Select an Invoice!!!')
                  else {
                    setReceiptRefundModal(true)
                    setInvoiceForRefund(false)
                  }
                }}
              >
                Select
              </button>
            </div>
          </div>

          <div className='d-flex mt-5 row '>
            <h6 className='m-0 p-0 col-1'></h6>
            <h6
              className='m-0 p-0 col-1 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              #
            </h6>
            <h6
              className='m-0 p-0 col-2 head-text px-2 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Total Amount
            </h6>

            <h6
              className='m-0 p-0 col-2 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Invoice No
            </h6>
            <h6
              className='m-0 p-0 col-2 head-text px-3 text-decoration-underline fs-5'
              style={{fontWeight: '600'}}
            >
              Date Generated
            </h6>
            <h6
              className='m-0 p-0 col-4 head-text ps-5 text-decoration-underline fs-5 '
              style={{fontWeight: '600'}}
            >
              Receipt Amount
            </h6>
          </div>

          <div className='py-5'>
            {invoiceLinkData?.length > 0 &&
              invoiceLinkData.map((ut: any, index: any) => {
                return (
                  <>
                    {ut?.invoiceNo?.length > 0 && (
                      <>
                        <div className='d-flex my-5 row '>
                          <div className='col-1'>
                            {ut?.invoiceNo != null && (
                              <>
                                {invoiceLinkChecked == index ? (
                                  <div
                                    style={{
                                      height: '16px',
                                      width: '16px',
                                      backgroundColor: '#384a62',
                                      borderRadius: '50%',
                                    }}
                                    className='mx-5 '
                                  ></div>
                                ) : (
                                  <div
                                    style={{
                                      height: '16px',
                                      width: '16px',
                                      backgroundColor: 'transparent',
                                      borderRadius: '50%',
                                      border: '1px solid #384a62',
                                    }}
                                    onClick={() => {
                                      setInvoiceLinkChecked(index)
                                      setInvoiceForRefundPayload(ut?._id)
                                      const body = {
                                        paymentInvoiceId: ut?._id,
                                      }
                                      ApiPost(`corporate/payment_refund/get/payment_item`, body)
                                        .then((res) => {
                                          setRefundReceiptSubItems(res?.data?.data)
                                        })
                                        .catch((err: any) => {
                                          console.log('err', err)
                                        })
                                    }}
                                    className='mx-5 '
                                  ></div>
                                )}
                              </>
                            )}
                          </div>
                          <div className='col-1 head-text px-2'>{index + 1}</div>

                          {/* <h6 className='m-0 p-0 col-2 head-text px-2'>
                        {ut?.dateApplied != null
                          ? moment(ut?.dateApplied).format('DD.MM.YYYY')
                          : '-'}
                      </h6> */}
                          <h6 className='m-0 p-0 col-2 head-text px-2'>
                            {ut?.totalAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </h6>
                          <h6 className='m-0 p-0 col-2 head-text px-3'>{ut?.invoiceNo}</h6>
                          <h6 className='m-0 p-0 col-2 head-text px-3'>
                            {moment(ut?.createdAt).format('DD.MM.YYYY')}
                          </h6>
                          <h6 className='m-0 p-0 col-4 head-text ps-5'>
                            {ut?.receiptAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </h6>
                        </div>
                      </>
                    )}
                  </>
                )
              })}
          </div>
        </div>
      </Modal>

      {/* Select Receipt Modal for Refund */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-950px mx-auto'
        show={receiptRefundModal}
        onHide={() => {
          setReceiptRefundModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setReceiptRefundModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='my-5 pb-4 row me-0'>
            <div className='col-md-6 ps-5 pt-3'>
              <label className='head-text fs-4 d-block' style={{fontWeight: '700'}}>
                Select Receipt
              </label>
            </div>

            <div className='col-md-3 align-items-center text-center ms-auto pe-0'>
              <button
                className='btn btn-md fw-bold ms-auto text-white me-1'
                style={{
                  fontSize: '1rem',
                  padding: '10px 15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                  width: '150px',
                }}
                onClick={(event) => {
                  if (receiptRefundLinkChecked == -1) ErrorToast('Please select a Receipt')
                  else {
                    setReceiptRefundModal(false)
                    setRefundSubItemsModal(true)
                  }
                }}
              >
                Select
              </button>
            </div>
          </div>

          {refundReceiptSubItems?.length > 0 && (
            <>
              {refundReceiptSubItems?.map((rf: any, index: any) => {
                return (
                  <>
                    <div
                      className='d-flex mt-5 row mx-2 pb-2 me-5'
                      style={{borderBottom: '1px solid lightgray'}}
                    >
                      <h6 className='p-0 col-3 head-text  fs-4 ps-2' style={{fontWeight: '600'}}>
                        {rf?.name}
                      </h6>
                      <h6 className='p-0 col-4 head-text px-5  fs-4' style={{fontWeight: '600'}}>
                        Total Amount{' '}
                        <b className='ps-2'>
                          {' '}
                          {rf?.totalAmount?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}
                        </b>
                      </h6>

                      <h6
                        className='p-0 col-4 head-text px-3  fs-4 px-5'
                        style={{fontWeight: '600'}}
                      >
                        Amount Received{' '}
                        <b className='ps-2'>
                          {' '}
                          {rf?.amountPaid?.toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                          })}
                        </b>
                      </h6>
                    </div>
                    <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'>
                      <table
                        className='table align-start table-row-dashed fs-6 gy-5'
                        id='kt_ecommerce_sales_table'
                      >
                        <thead>
                          <tr className='text-start text-gray-400 fw-bold fs-7 text-capitalize gs-0 border-white'>
                            <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-25px'></th>
                            <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-25px'>
                              #
                            </th>
                            <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-100px'>
                              Receipt No.
                            </th>
                            <th className='m-0 p-3 head-text text-decoration-underline fs-5min-w-100px '>
                              Date Generated
                            </th>
                            <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-150px'>
                              {' '}
                              Total Receipt Amount
                            </th>
                            <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-150px'>
                              {' '}
                              Item Paid Amount
                            </th>
                            <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-150px'>
                              {' '}
                              Previous Refund Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody className='fw-semibold text-gray-600'>
                          {rf?.sub_items?.length > 0 && (
                            <>
                              {rf?.sub_items?.map((rf_sb: any, ind: any) => {
                                return (
                                  <tr style={{height: '45px'}}>
                                    <td className='m-0 p-0 head-text p-3'>
                                      {rf_sb?.receiptAmountPaid != rf_sb?.previousRefundAmount && (
                                        <>
                                          {receiptRefundLinkChecked == rf_sb?.receiptId ? (
                                            <div
                                              style={{
                                                height: '16px',
                                                width: '16px',
                                                backgroundColor: '#384a62',
                                                borderRadius: '50%',
                                              }}
                                              className='me-5 '
                                            ></div>
                                          ) : (
                                            <div
                                              style={{
                                                height: '16px',
                                                width: '16px',
                                                backgroundColor: 'transparent',
                                                borderRadius: '50%',
                                                border: '1px solid #384a62',
                                              }}
                                              onClick={() => {
                                                setReceiptFields([])
                                                setReceiptRefundPaidAmount(rf_sb?.receiptAmountPaid)
                                                setReceiptRefundLinkChecked(rf_sb?.receiptId)
                                                ApiGet(
                                                  `corporate/payment_receipt/${rf_sb?.receiptId}`
                                                )
                                                  .then((res) => {
                                                    setReceiptToShowEdit(res?.data?.data)
                                                    const values = [...receiptFields]
                                                    for (
                                                      let i = 0;
                                                      i < res?.data?.data?.paymentSubItems?.length;
                                                      i++
                                                    ) {
                                                      values[i] = {
                                                        _id: res?.data?.data?.paymentSubItems[i]
                                                          ?._id,
                                                        name: res?.data?.data?.paymentSubItems[i]
                                                          ?.name,
                                                        amount: res?.data?.data?.paymentSubItems[i]
                                                          ?.isLatePaymentFeesRecord
                                                          ? res?.data?.data?.paymentSubItems[i]
                                                              ?.amount /
                                                            res?.data?.data?.paymentSubItems[i]
                                                              ?.quantity
                                                          : res?.data?.data?.paymentSubItems[i]
                                                              ?.amount,
                                                        qty: res?.data?.data?.paymentSubItems[i]
                                                          ?.quantity,
                                                        vat: res?.data?.data?.paymentSubItems[i]
                                                          ?.VATPercentage,
                                                        isLateItem:
                                                          res?.data?.data?.paymentSubItems[i]
                                                            ?.isLatePaymentFeesRecord,
                                                        total:
                                                          res?.data?.data?.paymentSubItems[i]
                                                            ?.totalAmount,
                                                        amountPaid:
                                                          res?.data?.data?.paymentSubItems[i]
                                                            ?.amountPaid,
                                                        refundAmount: 0,
                                                        refundVat: 0,
                                                        vatAmount:
                                                          res?.data?.data?.paymentSubItems[i]
                                                            ?.VATAmount,
                                                        totalWithoutVat: res?.data?.data
                                                          ?.paymentSubItems[i]
                                                          ?.isLatePaymentFeesRecord
                                                          ? res?.data?.data?.paymentSubItems[i]
                                                              ?.amount
                                                          : res?.data?.data?.paymentSubItems[i]
                                                              ?.amount *
                                                            res?.data?.data?.paymentSubItems[i]
                                                              ?.quantity,
                                                        newTotal:
                                                          res?.data?.data?.paymentSubItems[i]
                                                            ?.totalAmount,
                                                        newVat:
                                                          res?.data?.data?.paymentSubItems[i]
                                                            ?.VATPercentage,
                                                        previousDebitAmount:
                                                          res?.data?.data?.paymentSubItems[i]
                                                            ?.debitAmount +
                                                          res?.data?.data?.paymentSubItems[i]
                                                            ?.debitVATAmount,
                                                        totalAmount: 0,
                                                        finalAmount: 0,
                                                        outstanding:
                                                          res?.data?.data?.paymentSubItems[i]
                                                            ?.outstandingAmount,
                                                      }
                                                    }
                                                    setReceiptFields(values)
                                                  })
                                                  .catch((err: any) => {
                                                    console.log('err', err)
                                                  })
                                              }}
                                              className='me-5 '
                                            ></div>
                                          )}
                                        </>
                                      )}
                                    </td>
                                    <td className='m-0 p-0 head-text p-3'>{ind + 1}</td>
                                    <td className='m-0 p-0 head-text p-3'>{rf_sb?.receiptNo}</td>
                                    <td className='m-0 p-0 head-text p-3'>
                                      {moment(rf_sb?.datePaid).format('DD.MM.YYYY')}
                                    </td>
                                    <td className='m-0 p-0 head-text p-3'>
                                      {' '}
                                      {rf_sb?.receiptAmountPaid?.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                    <td className='m-0 p-0 head-text p-3'>
                                      {rf_sb?.itemAmountPaid?.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                    <td className='m-0 p-0 head-text p-3'>
                                      {rf_sb?.previousRefundAmount?.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                  </tr>
                                )
                              })}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </>
                )
              })}
            </>
          )}

          {/*  */}
        </div>
      </Modal>

      {/* SubItems modal for refund */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-950px mx-auto'
        show={refundSubItemsModal}
        onHide={() => {
          setRefundSubItemsModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setRefundSubItemsModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>
          <div className='my-5 pb-4 row me-0'>
            <div className='col-md-6 ps-5 pt-1'>
              <label className='head-text fs-4 d-block' style={{fontWeight: '700'}}>
                Receipt {receiptToShowEdit?.receiptNo}
              </label>
              <label className='head-text fs-5 d-block mt-3' style={{fontWeight: '700'}}>
                Total{' '}
                {receiptToShowEdit?.totalAmount?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                })}
              </label>
            </div>

            <div className='col-md-3 align-items-center text-center ms-auto pe-0'>
              <button
                className='btn btn-md fw-bold ms-auto text-white me-1'
                style={{
                  fontSize: '1rem',
                  padding: '10px 15px',
                  borderRadius: '2px',
                  backgroundColor: '#35475e',
                  boxShadow: '0px 0px 20px 0px #516176',
                  width: '160px',
                }}
                onClick={(event) => {
                  if (getPositive(totalRefundAmount) == 0) {
                    ErrorToast('Please input some value to REFUND !!!')
                  } else {
                    setRefundModal(true)
                    setRefundSubItemsModal(false)
                  }
                }}
              >
                Generate Refund
              </button>
              <label className='head-text fs-5 d-block mt-3' style={{fontWeight: '700'}}>
                Refund Amount{' '}
                <span className='ms-2'>
                  {totalRefundAmount?.toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}{' '}
                </span>
              </label>
            </div>
          </div>

          {/*  */}
          <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'>
            <table
              className='table align-start table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-capitalize gs-0 border-white'>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-100px'>
                    Item Name
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-50px'>
                    Qty
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-125px'>
                    {' '}
                    Total Amount
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-50px'>
                    {' '}
                    VAT
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-150px'>
                    {' '}
                    Total Paid Amount
                  </th>

                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-150px'>
                    Receipt Paid Amount
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-125px'>
                    {' '}
                    Outstanding
                  </th>
                  <th className='m-0 p-3 head-text text-decoration-underline fs-5 min-w-150px'>
                    {' '}
                    Refund Amount
                    <span>
                      <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 400}}
                        overlay={
                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                            {' '}
                            Enter the amount you wish to refund
                          </Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <AiOutlineExclamationCircle
                            color='#35475e '
                            fontSize={14}
                            fontWeight={600}
                            className='ms-3 cursor-pointer'
                            style={{cursor: 'pointer', marginBottom: '2px'}}
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                  </th>
                  <th
                    className='m-0 p-3 head-text text-decoration-underline fs-5 mw-100px'
                    style={{minWidth: '100px'}}
                  >
                    {' '}
                    VAT
                  </th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {receiptFields?.length > 0 &&
                  receiptFields?.map((field: any, index: any) => {
                    return (
                      <>
                        <tr style={{height: '45px', borderBottom: '2px solid lightgray'}}>
                          <td className='m-0 p-0 head-text p-3'>{field?.name}</td>
                          <td className='m-0 p-0 head-text p-3'>{field.qty}</td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.totalWithoutVat?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {field?.vatAmount?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.amountPaid?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>{receiptRefundPaidAmount}</td>

                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.outstanding > 0
                              ? getNegative(field?.outstanding)?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })
                              : getPositive(field?.outstanding)?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            <input
                              type='number'
                              className='form-control form-control-bg input-style mytest'
                              placeholder='Refund Amount'
                              name='item_id'
                              value={field?.refundAmount}
                              onChange={(event: any) => {
                                const values = [...receiptFields]
                                values[index].refundAmount = getPositive(event.target.value)

                                if (event.target.value.length == 0) values[index].refundAmount = 0
                                let newTotal = 0
                                let newVat: any = 0
                                values[index].refundVat = 0

                                // if no extra amount is paid
                                if (values[index].outstanding > 0) {
                                  let newTotal =
                                    getPositive(parseFloat(values[index]?.totalWithoutVat)) -
                                    getPositive(parseFloat(values[index].refundAmount))
                                  values[index].newTotalWithoutVat = newTotal

                                  // if vat applied then calculate Credit vat
                                  if (values[index]?.vat > 0) {
                                    newVat = (newTotal / 100) * values[index]?.vat
                                    values[index].newVat = newVat
                                    console.log(newVat)
                                    values[index].refundVat = getPositive(
                                      newVat - parseFloat(values[index]?.vatAmount)
                                    )
                                  }
                                }
                                // if extra amount is paid
                                else {
                                  if (
                                    parseFloat(event.target.value) >
                                    getPositive(values[index].outstanding)
                                  ) {
                                    let temp =
                                      parseFloat(event.target.value) -
                                      getPositive(values[index].outstanding)
                                    let tempTotal =
                                      getPositive(parseFloat(values[index]?.totalWithoutVat)) - temp
                                    let tempVat = (tempTotal / 100) * values[index]?.vat
                                    values[index].refundVat = getPositive(
                                      parseFloat(values[index]?.vatAmount) - tempVat
                                    )
                                  } else {
                                    values[index].refundVat = 0
                                  }
                                }

                                values[index].newTotal = newTotal + newVat
                                setReceiptFields(values)

                                let temp: any = 0
                                for (let i = 0; i < values?.length; i++) {
                                  if (values[i]?.refundAmount != undefined)
                                    temp =
                                      parseFloat(temp) +
                                      parseFloat(values[i]?.refundAmount) +
                                      parseFloat(values[i]?.refundVat)
                                }
                                setTotalRefundAmount(temp)
                              }}
                            />
                          </td>
                          <td className='m-0 p-0 head-text p-3'>
                            {' '}
                            {field?.refundVat?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                      </>
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>

      {/* View Single Refund */}
      {viewRefund && (
        <ViewRefund
          show={viewRefund}
          handleClose={() => {
            setViewRefund(false)
          }}
          refund={refundToView}
          paymentId={detailsToEdit?._id}
          tenantDetails={tenantDetails}
          dueDate={detailsToEdit?.dueDate}
          bankAccountItems={bankAccountItems}
          from={type}
        />
      )}

      {/* View All Debit Notes of Payment */}
      {viewAllRefunds && (
        <ViewAllRefundsOfPayment
          show={viewAllRefunds}
          handleClose={() => {
            setViewAllRefunds(false)
          }}
          refunds={paymentRefunds}
          paymentId={detailsToEdit?._id}
          tenantDetails={tenantDetails}
          getPaymentRefunds={getPaymentById}
          bankAccountItems={bankAccountItems}
          from={type}
        />
      )}
    </div>
  )
}

export default EditPaymentModal
