import moment from 'moment'
import React, {useRef, useState} from 'react'
import {Modal, Col, Row} from 'react-bootstrap'
import ReactToPrint from 'react-to-print'
import {ApiGet, ApiPost, ApiUpload} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../../_metronic/helpers'
import PhoneInput from 'react-phone-input-2'


interface ButtonProps {
  show: any
  handleClose: any
  tenancyId: any
  tenantId:any
}
const AddSubTenant = ({show, handleClose, tenancyId, tenantId}: ButtonProps) => {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [mobileNo, setMobileNo] = useState('')

  const [countryCode, setCountryCode] = useState<any>()
  const [phoneNumber, setPhoneNumber] = useState<any>()

  const handleNumber = (Phone: any, e: any) => {
    let CountryCode = e.dialCode
    let PhoneNumber = Phone.split(CountryCode)[1]

    setCountryCode(CountryCode)
    setPhoneNumber(PhoneNumber)
    // console.log('Phone', Phone)
    setMobileNo(Phone)
  }

  //   const addSubTenant = () => {
  //     let body = {
  //       page: 1,
  //       limit: 10,
  //       tenancyId: window.location.pathname.split('/')[2],
  //     }
  //     ApiPost('corporate/reminder/get', body)
  //       .then((res) => {
  //         // SuccessToast(res?.data?.message)
  //       })
  //       .catch((err) => console.log('err', err))
  //   }

  const addSubTenant = () => {
    let body = {
      firstName: firstName,
      lastName: lastName,
      phoneNumber: phoneNumber,
      countryCode: countryCode,
      email: email,
      tenantType: 1,
      tenancyId: tenancyId,
      tenantId: tenantId
    }
    ApiPost('corporate/tenant', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        handleClose()
      })
      .catch((err) => console.log('err', err))
  }

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-750px'
      show={show}
      onHide={() => {
        handleClose()
      }}
      backdrop={true}
    >
      <div className='modal-header'>
        <h1 className='head-text'>Add Sub Tenant</h1>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1 text-black' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>

      <div className='modal-body'>
        <Row>
          <Col md={6}>
            <div className='payment-receipt-item mt-5'>
              <h4 className='head-text'>
                <label style={{width: '80px'}}>
                  <b>First Name</b>
                </label>

                <span className='mx-5'>
                  <input
                    type='text'
                    className='form-control d-inline-block form-control-solid'
                    placeholder='Enter First Name...'
                    name='first_name'
                    style={{width: '200px'}}
                    onChange={(e) => {
                      setFirstName(e.target.value)
                    }}
                  />
                </span>
              </h4>
            </div>
          </Col>
          <Col md={6}>
            <div className='payment-receipt-item mt-5'>
              <h4 className='head-text'>
                <label style={{width: '80px'}}>
                  <b>Last Name</b>
                </label>

                <span className='mx-5'>
                  <input
                    type='text'
                    className='form-control d-inline-block form-control-solid'
                    placeholder='Enter Last Name...'
                    name='last_name'
                    style={{width: '200px'}}
                    onChange={(e) => {
                      setLastName(e.target.value)
                    }}
                  />
                </span>
              </h4>
            </div>
          </Col>
          <Col md={6}>
            <div className='payment-receipt-item mt-5'>
              <h4 className='head-text'>
                <label style={{width: '80px'}}>
                  <b>Email</b>
                </label>
                <span className='mx-5'>
                  <input
                    type='email'
                    className='form-control d-inline-block form-control-solid'
                    placeholder='Enter Email...'
                    name='Email'
                    style={{width: '200px'}}
                    onChange={(e) => {
                      setEmail(e.target.value)
                    }}
                  />
                </span>
              </h4>
            </div>
          </Col>

          <Col md={6}>
            <div className='payment-receipt-item mt-5'>
              <h4 className='head-text d-flex align-items-center'>
                <label style={{width: '80px'}} className='pt-3'>
                  <b>Mobile No</b>
                </label>
                <span className='mx-5 mt-2' style={{marginLeft: '20px'}}>
                  <PhoneInput
                    placeholder='Enter Phone Number...'
                    inputClass='form-control d-inline-block form-control-solid mt-1'
                    value={''}
                    inputProps={{width: '100px'}}
                    onChange={(phone, e) => handleNumber(phone, e)}
                    // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                  />
                </span>
              </h4>
            </div>
          </Col>
          <Col md={12} className='text-center mt-5'>
            <a
              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
              data-kt-stepper-action='previous'
              onClick={() => {
                addSubTenant()
                // setCard('')
              }}
              style={{width: '120px'}}
            >
              Add
            </a>
            {/* <a
              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
              data-kt-stepper-action='previous'
              onClick={() => {
                handleClose()
              }}
              style={{width: '120px'}}
            >
              Cancel
            </a> */}
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default AddSubTenant
