import React, {useState, useEffect, useRef} from 'react'
import backArrow from '../../../img/back-arrow.png'
import {useNavigate, useParams} from 'react-router-dom'
import submitImg from '../../../img/Submit-White.png'
import redCross from '../../../img/remove.png'
import redCrossFilled from '../../../img/remove-filled.png'
import downloadImg from '../../../img/download-img.png'
import trashImg from '../../../img/trash.png'
import saveGreenImg from '../../../img/save-dark-green.png'
import {Row, Col, OverlayTrigger, Tooltip, Modal} from 'react-bootstrap'
import templateLOGO from '../../../img/template-logo.jpeg'
import PhoneInput from 'react-phone-input-2'
import addWhite from '../../../img/add-white.png'
import {DatePicker} from 'antd'
import moment, {Moment} from 'moment'
import plusBlue from '../../../img/add-blue.png'
import TextEditor from '../General/TextEditor'
import swal from 'sweetalert2'
import DnDCard from '../General/DnDCard'
import {ApiGet, Bucket, ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {Backdrop, CircularProgress} from '@mui/material'
import moveUpImg from '../../../img/scroll-up.png'
import movedownImg from '../../../img/scroll-down.png'
import {Controller, useForm} from 'react-hook-form'
import Maintenance from '../UserSettings/Maintenance'
import {useLocation} from 'react-router-dom'

const POTemplate = () => {
  const {flg, type, id, poId, poType} = useParams()
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const paymentTermsRef = React.useRef<any>()

  const navigate = useNavigate()
  const {state} = useLocation()
  const [isLoading, setIsLoading] = useState<any>()

  const [purchaseOrderData, setPurchaseOrderData] = useState<any>({
    name: '',
    id: '',
    items: [
      {
        description: '',
        quantity: '',
        unitPrice: '',
        totalPrice: '',
      },
    ],
    sections: [
      {
        title: 'Payment Terms',
        text: 'Payment Terms Defaullttt',
        html: '',
      },
    ],
  })

  const [paymentTerms, setPaymentTerms] = useState<any>({
    title: 'Payment Terms',
    text: 'Payment Terms Defaullttt',
    html: '',
  })

  const [showPaymentTerms, setShowPaymentTerms] = useState<any>(false)

  var totalPrice: any = 0

  const [vendorData, setVendorData] = useState<any>({})

  const [deliveryDetails, setDeliveryDetails] = useState<any>({
    address1: '',
    address2: '',
    email: '',
    deliverDate: null,
    phoneNumber: '',
    countryCode: '',
  })

  const [showDeliveryDetails, setShowDeliveryDetails] = useState<any>(false)

  const [poCompany, setPOCompany] = useState<any>({
    name: 'Corporate User Real Estate Services LLC.',
    address: 'Corporate Address, Street, Building, City',
    phoneNumber: '+92 341-0121298',
    email: 'CorporateAccount_Email@gmail.com',
  })

  const [logo, setLogo] = useState<any>('')
  const [selectedDate, setSelectedDate] = useState<any>(null)

  const [requestData, setRequestData] = useState<any>()
  const [open, setOpen] = useState<any>(false)

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)
  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  const handleDateChange = (date: any) => {
    if (date != null) {
      setSelectedDate(date.format('YYYY-MM-DD'))
      const values = {...deliveryDetails}
      values.deliverDate = date.format('YYYY-MM-DD')
      setDeliveryDetails(values)
      setValue('deliveryDate', date.format('YYYY-MM-DD'))
    } else {
      setSelectedDate(null)
      const values = {...deliveryDetails}
      values.deliverDate = null
      setDeliveryDetails(values)
      setValue('deliveryDate', '')
    }

    if (flg == '2') {
      setTimeout(() => {
        setAutoSaveData(Date.now())
      }, 700)
    }
  }

  function disabledDate(current: Moment) {
    // Disable dates before today's date
    return current && current < moment().startOf('day')
  }

  //  Purchase Items
  const handleAddNewItems = () => {
    let values = {...purchaseOrderData}
    let valueItems: any = [...purchaseOrderData?.items]
    valueItems.push({
      id: '',
      description: '',
      quantity: '',
      unitPrice: '',
      totalPrice: '',
    })
    values.items = valueItems
    setPurchaseOrderData(values)
  }

  const handleRemoveItems = (index: any) => {
    let values = {...purchaseOrderData}
    let valueItems: any = [...purchaseOrderData?.items]
    valueItems.splice(index, 1)
    values.items = valueItems
    setPurchaseOrderData(values)

    if (flg == '2') {
      setTimeout(() => {
        setAutoSaveData(Date.now())
      }, 700)
    }
  }

  // Sections
  const handleAddNewSections = () => {
    let values = {...purchaseOrderData}
    let valueItems: any = [...purchaseOrderData?.sections]
    valueItems.push({
      name: '',
      content: '',
    })
    values.sections = valueItems
    setPurchaseOrderData(values)
  }

  const handleRemoveSections = (index: any) => {
    let values = {...purchaseOrderData}
    let valueItems: any = [...purchaseOrderData?.sections]
    valueItems.splice(index, 1)
    values.sections = valueItems
    setPurchaseOrderData(values)

    if (flg == '2') {
      setTimeout(() => {
        setAutoSaveData(Date.now())
      }, 700)
    }
  }

  //
  const getPODetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setPOCompany(res?.data?.data?.purchaseOrderCompany)
        setLogo(res?.data?.data?.purchaseOrderLogo)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  //
  const getRequestById = () => {
    ApiGet(`corporate/maintenance_service_request/${id}`)
      .then((res: any) => {
        setRequestData(res?.data?.data)

        if (flg == '1') {
          if (isSaveFirst == true) {
            let poLength = res?.data?.data?.maintenance_purchase_order?.length
            navigate(
              `/maintenance/create-request/purchase-order/${type}/2/${id}/${res?.data?.data?.maintenance_purchase_order?.[0]?._id}/${poType}`,
              {
                state: {
                  from: state?.from,
                },
              }
            )

            let values = {
              name: res?.data?.data?.maintenance_purchase_order?.[0]?.documentName,
              purchaseOrderNo: res?.data?.data?.maintenance_purchase_order?.[0]?.purchaseOrderNo,
              items: res?.data?.data?.maintenance_purchase_order?.[0]?.items,
              sections: res?.data?.data?.maintenance_purchase_order?.[0]?.itemDescriptions,
            }

            // let sections: any = []
            // let sectInd: any = 0

            // res?.data?.data?.maintenance_purchase_order?.[0].itemDescriptions?.map(
            //   (sect: any, ind: any) => {
            //     if (sect?.text == 'Payment Terms Defaullttt') {
            //       setPaymentTerms(sect)
            //     } else {
            //       sections[sectInd] = sect
            //       sectInd++
            //     }

            //     return <></>
            //   }
            // )

            // values.sections = sections

            setPurchaseOrderData(values)
            setShowPaymentTerms(true)

            setPOCompany(res?.data?.data?.maintenance_purchase_order?.[0]?.purchaseOrderCompany)
            setLogo(res?.data?.data?.maintenance_purchase_order?.[0]?.purchaseOrderCompany?.logo)
            setVendorData(res?.data?.data?.maintenance_purchase_order?.[0]?.vendorAddress)
            setDeliveryDetails(res?.data?.data?.maintenance_purchase_order?.[0]?.deliverAddress)
            setSelectedDate(
              res?.data?.data?.maintenance_purchase_order?.[0]?.deliverAddress?.deliverDate
            )
          } else {
            if (res?.data?.data?.vendor?.[0]?._id != undefined && !isSaveFirst && poType == '1')
              getVendorById(res?.data?.data?.vendor?.[0]?._id)
          }

          setTimeout(()=>{
            setOpen(false)
          }, 1000)
        }

        if (flg == '2') {
          res?.data?.data?.maintenance_purchase_order?.map((mpo: any, ind: any) => {
            if (mpo?._id == poId) {
              let values = {
                name: mpo.documentName,
                purchaseOrderNo: mpo.purchaseOrderNo,
                items: mpo.items,
                sections: mpo.itemDescriptions,
              }

              mpo?.items?.map((itm: any, ind: any) => {
                setValue(`description${ind}`, itm?.description)
                setValue(`quantity${ind}`, itm?.quantity)
                setValue(`unitPrice${ind}`, itm?.unitPrice)
              })

              setValue('documentName', mpo.documentName)

              // let sections: any = []
              // let sectInd: any = 0
              // mpo.itemDescriptions?.map((sect: any, ind: any) => {
              //   if (sect?.text == 'Payment Terms Defaullttt') {
              //     setPaymentTerms(sect)
              //   } else {
              //     sections[sectInd] = sect
              //     sectInd++
              //   }

              //   return <></>
              // })

              // values.sections = sections
              setPurchaseOrderData(values)

              setShowPaymentTerms(true)

              setPOCompany(mpo?.purchaseOrderCompany)
              setLogo(mpo?.purchaseOrderCompany?.logo)

              setVendorData(mpo?.vendorAddress)
              setValue('vendorCompany', mpo?.vendorAddress?.name)
              setValue('vendorAddress1', mpo?.vendorAddress?.address1)
              setValue('vendorAddress2', mpo?.vendorAddress?.address2)
              setValue('vendorEmail', mpo?.vendorAddress?.email)
              setValue('vendorPhoneNumber', mpo?.vendorAddress?.phoneNumber)

              setDeliveryDetails(mpo?.deliverAddress)
              setValue('deliveryAddress1', mpo?.deliverAddress?.address1)
              setValue('deliveryAddress2', mpo?.deliverAddress?.address2)
              setValue('deliveryEmail', mpo?.deliverAddress?.email)
              setValue('deliveryPhoneNumber', mpo?.deliverAddress?.phoneNumber)
              setValue('deliveryDate', mpo?.deliverAddress?.deliverDate)

              setSelectedDate(mpo?.deliverAddress?.deliverDate)

              if (
                mpo?.deliverAddress?.address1?.trim()?.length == 0 &&
                mpo?.deliverAddress?.address2?.trim()?.length == 0 &&
                mpo?.deliverAddress?.email?.trim()?.length == 0 &&
                mpo?.deliverAddress?.phoneNumber?.trim()?.length == 0 &&
                mpo?.deliverAddress?.deliverDate == null
              )
                setShowDeliveryDetails(false)
              else setShowDeliveryDetails(true)
            }

            return <></>
          })

          setTimeout(()=>{
            setOpen(false)
          }, 1000)
        }

        setIsLoading(false)
      })
      .catch((err: any) => {
        setOpen(false)
        ErrorToast(err?.message)
        setIsLoading(false)
      })
  }

  //
  const getVendorById = (val: any) => {
    ApiGet(`corporate/vendor/${val}`)
      .then((res: any) => {
        let values: any = {
          address1: res?.data?.data?.address1,
          address2: res?.data?.data?.address2,
          phoneNumber: res?.data?.data?.phoneNumber,
          countryCode: res?.data?.data?.countryCode,
          email: res?.data?.data?.email,
          name: res?.data?.data?.companyName,
        }
        setVendorData(values)
        setValue('vendorCompany', res?.data?.data?.companyName)
        setValue('vendorAddress1', res?.data?.data?.address1)
        setValue('vendorAddress2', res?.data?.data?.address2)
        setValue('vendorEmail', res?.data?.data?.email)
        setValue('vendorPhoneNumber', res?.data?.data?.phoneNumber)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    if (flg == '1') getPODetails()
    getRequestById()
  }, [])

  const [payTermsError, setPayTermsError] = useState<any>(false)

  //
  const addPO = async (isDraft: any) => {
    let isValid: any = true
    if (!isDraft) {
      isValid = await trigger()
    }

    if (isValid) {
      // if (paymentTerms?.html?.trim()?.length == 0 && !isDraft) {
      //   setPayTermsError(true)
      //   ErrorToast('Please add payment terms')
      //   setTimeout(() => {
      //     paymentTermsRef.current.scrollIntoView({behavior: 'smooth'})
      //   }, 200)
      // } else {
        setIsLoading(true)
        setOpen(true)

        let sections: any = []
        // sections[0] = paymentTerms
        // sections[0].html = paymentTerms?.html.replace(/\\"/g, '"')

        purchaseOrderData?.sections?.map((po: any, ind: any) => {
          sections[ind] = po
          sections[ind].html = po?.html.replace(/\\"/g, '"')
          return <></>
        })

        let poData: any = [
          {
            _id: flg == '2' ? poId : undefined,
            maintenancePurchaseOrderType: Number(poType),
            saveStatus: isDraft ? 0 : 1,
            documentName: purchaseOrderData?.name,
            purchaseOrderNo: purchaseOrderData?.purchaseOrderNo,
            date: '',
            deliverAddress: deliveryDetails,
            pdfURL: '',
            purchaseOrderCompany: {
              name: poCompany?.name,
              address: poCompany?.address,
              countryCode: poCompany?.countryCode,
              phoneNumber: poCompany?.phoneNumber,
              email: poCompany?.email,
              logo: logo,
            },
            vendorAddress: {
              address1: vendorData?.address1,
              address2: vendorData?.address2,
              phoneNumber: vendorData?.phoneNumber,
              countryCode: vendorData?.countryCode,
              email: vendorData?.email,
              name: vendorData?.name,
            },
            items: purchaseOrderData?.items,
            itemDescriptions: sections,
            totalAmount: totalPrice,
            isManualPDF: false,
          },
        ]
        const body = {
          serviceRequest: {
            _id: id,
          },
          newPurchaseOrder: flg == '1' ? poData : [],
          updatePurchaseOrder: flg == '2' ? poData : [],
        }

        ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
          .then((res: any) => {
            if (!isDraft) {
              SuccessToast(res?.data?.message)

              if (state?.from == 'view-maintenance-request') {
                navigate(`/maintenance/request/${type}/${id}`)
              } else {
                let jobType: any
                if(requestData?.maintenanceJobType == 0)
                  jobType = 'dev-clust'
                else if(requestData?.maintenanceJobType == 1)
                  jobType = 'common-area'
                else if(requestData?.maintenanceJobType == 2)
                  jobType = 'unit'
                navigate(`/maintenance/create-request/${type}/2/${jobType}/${id}`, {
                  state: {
                    from: 'purchase',
                  },
                })
              }
            } else {
              if (flg == '1') {
                setTimeout(() => {
                  setIsSaveFirst(true)
                }, 2000)
              } else {
                getRequestById()
               
                setIsLoading(false)
              }
            }
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setOpen(false)
            setIsLoading(false)
          })
      // }
    }
  }

  const deletePO = () => {
    setIsLoading(true)

    const body = {
      serviceRequest: {
        _id: id,
      },
      newPurchaseOrder: [],
      updatePurchaseOrder: [],
      deletePurchaseOrder: [{_id: poId}],
    }

    ApiPost(`corporate/maintenance_service_request/all_in_one`, body)
      .then((res: any) => {
        SuccessToast('Purchase Order has been deleted successfully...')

        if (state?.from == 'view-maintenance-request') {
          navigate(`/maintenance/request/${type}/${id}`)
        } else {
          let jobType: any
          if(requestData?.maintenanceJobType == 0)
            jobType = 'dev-clust'
          else if(requestData?.maintenanceJobType == 1)
            jobType = 'common-area'
          else if(requestData?.maintenanceJobType == 2)
            jobType = 'unit'
          navigate(`/maintenance/create-request/${type}/2/${jobType}/${id}`, {
            state: {
              from: 'purchase',
            },
          })
        }
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setOpen(false)
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (isSaveFirst == true) getRequestById()
  }, [isSaveFirst])

  useEffect(() => {
    if (flg == '2' && autoSaveData != false) addPO(true)
  }, [autoSaveData])

  //
  const swapItems = (prevInd: any, nextInd: any) => {
    const values = {...purchaseOrderData}
    const valuesSections = [...purchaseOrderData?.sections]

    // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    setPurchaseOrderData({...values, sections: valuesSections})

    setTimeout(() => {
      let temp = valuesSections[prevInd]
      valuesSections[prevInd] = valuesSections[nextInd]
      valuesSections[nextInd] = temp
      valuesSections[prevInd].animationClass = ''
      valuesSections[nextInd].animationClass = ''

      values.sections = valuesSections
      setPurchaseOrderData(values)
    }, 300)

    if (flg == '2') {
      setTimeout(() => {
        setAutoSaveData(Date.now())
      }, 700)
    }

    // setTimeout(() => {
    //   valuesSections[prevInd].animationClass = ''
    //   valuesSections[nextInd].animationClass = ''

    //   values.sections = valuesSections
    //   setPurchaseOrderData(values)
    // }, 200)
  }

  const firstErrorInputRef = useRef<any>(null)

  useEffect(() => {
    console.log(errors)
    if (firstErrorInputRef.current) {
      // firstErrorInputRef.current.focus();
      // setTimeout(() => {
      //   firstErrorInputRef.current.scrollIntoView({behavior: 'smooth'})
      // }, 200)
    }
  }, [errors])

  return (
    <>
      <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
        <form onSubmit={handleSubmit((data: any, e: any) => addPO(false))}>
          <div id='' className='pt-0 mt-0'>
            {' '}
            {/* Header */}
            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              <div className=' d-flex flex-stack pt-1 mt-0 px-0'>
                {' '}
                <div className='d-flex align-items-center mb-1'>
                  <span
                    className='rounded-circle cursor-pointer'
                    onClick={() => {
                      if (state?.from == 'view-maintenance-request') {
                        navigate(`/maintenance/request/${type}/${id}`)
                      } else {
                        let jobType: any
                        if(requestData?.maintenanceJobType == 0)
                          jobType = 'dev-clust'
                        else if(requestData?.maintenanceJobType == 1)
                          jobType = 'common-area'
                        else if(requestData?.maintenanceJobType == 2)
                          jobType = 'unit'
                        navigate(`/maintenance/create-request/${type}/2/${jobType}/${id}`, {
                          state: {
                            from: 'purchase',
                          },
                        })
                      }
                    }}
                  >
                    <img src={backArrow} height='14' width='14' />
                  </span>
                  <h2 className='m-0 head-text ms-2 text-capitalize'>{`Purchase Order Template`}</h2>
                </div>
              </div>
            </div>
            <div className='d-flex mt-5 flex-wrap px-1'>
              <button
                type='button'
                className='btn btn-sm fw-bold green-submit-btn ps-3'
                onClick={(e: any) => {
                  swal
                    .fire({
                      html: ` 
                    <div class='fs-3 mb-3'> <b>Generate Purchase Order</b></div>
                    <div class='fs-5 mt-5 mb-3'> You are about to generate this purchase order. 
                    Once generated you will not be able to edit the document. ? </div>`,
                      showConfirmButton: true,
                      confirmButtonColor: '#35475e',
                      confirmButtonText: 'Generate',
                      showCancelButton: true,
                      cancelButtonText: 'Cancel',
                      cancelButtonColor: '#fff',
                      customClass: {
                        confirmButton:
                          'green-submit-btn status-w-100 d-flex justify-content-center',
                        cancelButton: 'red-hollow-btn status-w-100 d-flex justify-content-center',
                        // popup: 'custom-popup',
                      },
                      // reverseButtons: true,
                    })
                    .then((res: any) => {
                      if (res.isConfirmed) {
                        addPO(false)
                      }
                    })
                }}
                disabled={isLoading}
              >
                <img src={submitImg} height={19} width={19} className='me-4' /> {'  '}
                Generate
              </button>

              <div className='d-flex ms-auto'>
                {flg == '1' && (
                  <button
                    type='button'
                    className='btn btn-sm fw-bold ms-3 green-hollow-btn ps-3 '
                    onClick={(e) => {
                      addPO(true)
                    }}
                    disabled={isLoading}
                  >
                    <img src={saveGreenImg} height={18} width={18} className='me-4' /> {'  '}Save
                  </button>
                )}

                {flg == '2' && (
                  <button
                    type='button'
                    className='btn btn-sm fw-bold  ms-3 red-hollow-btn ps-3 '
                    onClick={() => {
                      swal
                        .fire({
                          html: ` 
                          <div class='fs-3'> <b>Delete Purchase Order</b></div>
                          <div class='fs-5 mt-4'> Are you want to delete this purchase order? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton:
                              'custom-cancel-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                            cancelButton:
                              'custom-confirm-button status-w-100 d-flex justify-content-center h-31 align-items-center',
                            popup: 'custom-popup',
                          },
                          // reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                            deletePO()
                          }
                        })
                    }}
                    disabled={isLoading}
                  >
                    <img src={trashImg} height={18} width={18} className='me-4' /> Delete
                  </button>
                )}

                {flg == '2' && (
                  <button
                    type='button'
                    className='btn btn-sm fw-bold blue-hollow-btn ms-3 btn-fit-content ps-2'
                    style={{width: 'fit-content'}}
                    onClick={() => {}}
                  >
                    <img src={downloadImg} height={18} width={18} className='me-3' />
                    Download
                  </button>
                )}
              </div>
            </div>
            <div>
              <div className='d-flex mt-5 px-1'>
                <h4 className='my-2 text-dark'>
                  <label className='labl-gry status-w-130 ps-1'> Document name</label>
                </h4>
                <h4>
                  <div>
                    <input
                      {...register('documentName', {required: true})}
                      type='text'
                      className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                      name='documentName'
                      value={purchaseOrderData?.name}
                      style={{height: '35px', width: '300px'}}
                      placeholder={'Enter Document Name'}
                      onChange={(e: any) => {
                        let values = {...purchaseOrderData}
                        let newVal: any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
                        values.name = newVal
                        setPurchaseOrderData(values)
                        setValue('documentName', e.target.value)
                      }}
                      onBlur={() => {
                        if (flg == '2') {
                          setTimeout(() => {
                            setAutoSaveData(Date.now())
                          }, 700)
                        }
                      }}
                      autoComplete='off'
                    />

                    {errors.documentName && (
                      <p
                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                        className='m-0 mt-1'
                      >
                        This field is required
                      </p>
                    )}
                  </div>
                </h4>
              </div>
            </div>
            <div className='card mt-5 px-6 py-5' style={{minHeight: '75vh'}}>
              <Row className=''>
                <Col xs={6} sm={6} md={9} lg={9}>
                  <div className='d-flex mb-3 mt-2 align-items-center'>
                    {logo == null ? (
                      <></>
                    ) : (
                      <img
                        // src={`${Bucket}${logo}`}
                        src={`${Bucket}${logo}`}
                        height='110'
                        width='110'
                        style={{borderRadius: '7px'}}
                        className='me-4'
                      />
                    )}
                    <div className='text-start me-6' style={{fontWeight: '600'}}>
                      <p style={{marginBottom: '0.1rem'}} className='white-dark-theme-color'>
                        {' '}
                        <b>{poCompany?.name != null && poCompany?.name}</b>
                      </p>
                      <p style={{marginBottom: '0.1rem'}} className='fs-7 white-dark-theme-color'>
                        {' '}
                        {poCompany?.address != null && poCompany?.address}
                      </p>

                      <p style={{marginBottom: '0.1rem'}} className='fs-7 white-dark-theme-color'>
                        {poCompany?.phoneNumber != null &&
                          poCompany?.countryCode + poCompany?.phoneNumber}
                      </p>
                      <p style={{marginBottom: '0.1rem'}} className='fs-7 white-dark-theme-color'>
                        {' '}
                        {poCompany?.email != null && poCompany?.email}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={3} lg={3} className='pe-4'>
                  {/* <div className='d-flex align-items-center my-2'>
                      <label
                        className='mb-1 me-5 pe-5 ms-auto head-text'
                        style={{minWidth: '50px', maxWidth: '50px'}}
                      >
                        <b>TRN</b>
                      </label>
                      <b>{poCompany?.trn ? poCompany?.trn : '-'}</b>
                    </div> */}
                  <div className='d-flex align-items-center my-2 '>
                    <h1 className='ms-auto mb-0 white-dark-theme-color'>PURCHASE ORDER</h1>
                  </div>

                  {/*  */}
                  <div className='d-flex align-items-center justify-content-start my-2'>
                    <label
                      className='mb-1 me-3 ms-15 fnt-600 head-text light-dark-theme-color status-w-75'
                      style={{fontSize: '1rem'}}
                    >
                      PO No.
                    </label>
                    <div className='ms-auto white-dark-theme-color fnt-500'>
                      {flg == '2' ? purchaseOrderData?.purchaseOrderNo : '-'}
                    </div>
                  </div>

                  {/*  */}
                  <div className='d-flex align-items-center justify-content-start my-2 '>
                    <label
                      className='mb-1 me-3 ms-15 fnt-600 head-text light-dark-theme-color status-w-75'
                      style={{fontSize: '1rem'}}
                    >
                      Issued Date
                    </label>
                    <div className='ms-auto fnt-500 white-dark-theme-color'>
                      {' '}
                      {'-'}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold select-btn btn-fit-content ps-2 ms-auto'
                    onClick={() => {
                      setShowDeliveryDetails(true)
                      let values = {
                        address1: '',
                        address2: '',
                        email: '',
                        deliverDate: null,
                        phoneNumber: '',
                        countryCode: '',
                      }

                      setDeliveryDetails(values)
                    }}
                    disabled={showDeliveryDetails}
                  >
                    <img src={addWhite} height={18} width={18} className='me-3' /> Add Delivery
                    Details
                  </button>
                </Col>
              </Row>
              <Row className='mt-5'>
                {/* Vendor Details */}
                <Col md={6}>
                  <div style={{fontWeight: '600'}} className='w-75'>
                    <div className='d-flex align-items-center'>
                      <h3 className='mb-3 fs-3 white-dark-theme-color'>
                        <b>Vendor</b>
                      </h3>
                    </div>
                    {poType == '1' ? (
                      <p style={{marginBottom: '0.1rem'}}>
                        <h3 className='mb-2 fs-4 white-dark-theme-color'>
                          <b>{vendorData?.name ? vendorData?.name : '-'}</b>
                        </h3>
                      </p>
                    ) : (
                      <>
                        <input
                          {...register('vendorCompany', {required: true})}
                          type='text'
                          className='form-control form-control-solid mytest mt-3 me-5'
                          placeholder='Name'
                          name='vendorCompany'
                          value={vendorData?.name}
                          onChange={(e) => {
                            let values = {...vendorData}
                            let newVal: any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
                            values.name = newVal
                            setVendorData(values)
                            setValue('vendorCompany', e.target.value)
                          }}
                          onBlur={() => {
                            if (flg == '2') {
                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 700)
                            }
                          }}
                          autoComplete='off'
                        />

                        {errors.vendorCompany && (
                          <p
                            style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                            className='m-0 mt-1'
                          >
                            This field is required
                          </p>
                        )}
                      </>
                    )}

                    <input
                      // {...register('vendorAddress1', {required: true})}
                      type='text'
                      className='form-control form-control-solid mytest mt-3 me-5'
                      placeholder='Address 1'
                      name='vendorAddress1'
                      value={vendorData?.address1}
                      onChange={(e) => {
                        let values = {...vendorData}
                        let newVal: any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
                        values.address1 = newVal
                        setVendorData(values)
                        // setValue('vendorAddress1', e.target.value)
                      }}
                      onBlur={() => {
                        if (flg == '2') {
                          setTimeout(() => {
                            setAutoSaveData(Date.now())
                          }, 700)
                        }
                      }}
                      autoComplete='off'
                    />

                    {/* {errors.vendorAddress1 && (
                      <p
                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                        className='m-0 mt-1'
                      >
                        This field is required
                      </p>
                    )} */}

                    {/* Vendor Address 02  */}
                    <input
                      // {...register('vendorAddress2', {required: true})}
                      type='text'
                      className='form-control form-control-solid mytest my-3 me-5'
                      placeholder='Address 2'
                      name='vendorAddress2'
                      value={vendorData?.address2}
                      onChange={(e) => {
                        let values = {...vendorData}
                        let newVal: any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
                        values.address2 = newVal
                        setVendorData(values)
                        // setValue('vendorAddress2', e.target.value)
                      }}
                      onBlur={() => {
                        if (flg == '2') {
                          setTimeout(() => {
                            setAutoSaveData(Date.now())
                          }, 700)
                        }
                      }}
                      autoComplete='off'
                    />
                    {/* {errors.vendorAddress2 && (
                      <p
                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                        className='m-0 mt-0 mb-3'
                      >
                        This field is required
                      </p>
                    )} */}

                    {/* Vendor Contact */}
                    <div className='po-phone'>
                      {/* <Controller
                        name='vendorPhoneNumber'
                        control={control}
                        rules={{
                          required: 'This field is required',
                        }}
                        render={({field}) => ( */}
                      <PhoneInput
                        placeholder='Phone Number'
                        value={`${vendorData?.countryCode} ${vendorData?.phoneNumber}`}
                        onChange={(phone: any, e: any) => {
                          let CountryCode: any = e?.dialCode
                          let PhoneNumber: any = phone.split(CountryCode)[1]
                            ? phone.split(CountryCode)[1]
                            : ''

                          setVendorData({
                            ...vendorData,
                            ['countryCode']: phone?.trim()?.length > 0 ? CountryCode : '',
                            ['phoneNumber']: PhoneNumber ? PhoneNumber : '',
                          })

                          // setValue(
                          //   'vendorPhoneNumber',
                          //   `${phone?.trim()?.length > 0 ? CountryCode : ''}${PhoneNumber}`
                          // )
                        }}
                        onBlur={() => {
                          if (flg == '2') {
                            setTimeout(() => {
                              setAutoSaveData(Date.now())
                            }, 700)
                          }
                        }}
                      />
                      {/* )}
                      /> */}

                      {/* {errors.vendorPhoneNumber && (
                        <p
                          style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                          className='m-0 mt-1'
                        >
                          This field is required
                        </p>
                      )} */}
                    </div>

                    {/* Vendor Email*/}
                    <Controller
                      name='vendorEmail'
                      control={control}
                      rules={{
                        // required: 'Email is required',
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: 'Invalid email address',
                        },
                      }}
                      render={({field}) => (
                        <input
                          {...register(
                            'vendorEmail'
                            // {required: true}
                          )}
                          type='text'
                          className='form-control form-control-solid mytest mt-3 me-5'
                          placeholder='Email'
                          name='vendorEmail'
                          value={vendorData?.email}
                          onChange={(e) => {
                            setVendorData({
                              ...vendorData,
                              ['email']: e.target.value,
                            })
                            setValue('vendorEmail', e.target.value)
                          }}
                          onBlur={() => {
                            if (flg == '2') {
                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 700)
                            }
                          }}
                          autoComplete='off'
                        />
                      )}
                    />

                    {errors.vendorEmail && (
                      <p
                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                        className='m-0 mt-1'
                      >
                        Please enter a valid email address
                      </p>
                    )}
                  </div>
                </Col>
                {/* Delivery Details */}
                {showDeliveryDetails && (
                  <>
                    <Col md={6} className=''>
                      <div className='w-75 ms-auto fnt-600'>
                        <div className='d-flex align-items-center'>
                          <h3 className='mb-3 fs-3 white-dark-theme-color'>
                            <b>Deliver To</b>
                          </h3>
                          <img
                            src={redCrossFilled}
                            height={18}
                            width={18}
                            className='ms-auto cursor-pointer'
                            onClick={() => {
                              setShowDeliveryDetails(false)
                            }}
                          />
                        </div>
                        <div className='d-flex vendor'>
                          <h4 className='mt-4' style={{color: 'black'}}>
                            <label className='labl-gry status-w-130'>
                              {'Delivery Address '}
                            </label>
                          </h4>
                          <h4 style={{width: '-webkit-fill-available'}} className='mb-0'>
                            <div>
                              <input
                                {...register('deliveryAddress1', {required: true})}
                                type='text'
                                className='form-control form-control-solid mytest mt-3'
                                placeholder='Address 1'
                                name='deliveryAddress1'
                                value={deliveryDetails?.address1}
                                onChange={(e) => {
                                  let values = {...deliveryDetails}
                                  let newVal: any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
                                  values.address1 = newVal
                                  setDeliveryDetails(values)
                                  setValue('deliveryAddress1', e.target.value)
                                }}
                                onBlur={() => {
                                  if (flg == '2') {
                                    setTimeout(() => {
                                      setAutoSaveData(Date.now())
                                    }, 700)
                                  }
                                }}
                                 autoComplete='off'
                              />
                              {errors.deliveryAddress1 && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </div>
                          </h4>
                        </div>

                        {/* Address 2 */}
                        <div className='d-flex  vendor'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label className='labl-gry status-w-130'>
                              {' '}
                            </label>
                          </h4>

                          <h4 style={{width: '-webkit-fill-available'}} className='mb-2'>
                            <div>
                              <input
                                {...register('deliveryAddress2', {required: true})}
                                type='text'
                                className='form-control form-control-solid mytest mt-2'
                                placeholder='Address 2'
                                name='deliveryAddress2'
                                value={deliveryDetails?.address2}
                                onChange={(e) => {
                                  let values = {...deliveryDetails}
                                  let newVal: any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;
                                  values.address2 = newVal
                                  setDeliveryDetails(values)
                                  setValue('deliveryAddress2', e.target.value)
                                }}
                                onBlur={() => {
                                  if (flg == '2') {
                                    setTimeout(() => {
                                      setAutoSaveData(Date.now())
                                    }, 700)
                                  }
                                }}
                                 autoComplete='off'
                              />
                              {errors.deliveryAddress2 && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </div>
                          </h4>
                        </div>

                        {/* Phone No. */}
                        <div className='d-flex'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label className='labl-gry status-w-130'>
                              {' '}
                            </label>
                          </h4>
                          <h4 style={{width: '-webkit-fill-available'}} className='mb-0'>
                            <div className='po-phone'>
                              <Controller
                                name='deliveryPhoneNumber'
                                control={control}
                                rules={{
                                  required: 'This field is required',
                                }}
                                render={({field}) => (
                                  <PhoneInput
                                    placeholder='Phone Number'
                                    value={`${deliveryDetails?.countryCode} ${deliveryDetails?.phoneNumber}`}
                                    onChange={(phone: any, e: any) => {
                                      let CountryCode: any = e?.dialCode
                                      let PhoneNumber: any = phone.split(CountryCode)[1]
                                        ? phone.split(CountryCode)[1]
                                        : ''

                                      setDeliveryDetails({
                                        ...deliveryDetails,
                                        ['countryCode']:
                                          phone?.trim()?.length > 0 ? CountryCode : '',
                                        ['phoneNumber']: PhoneNumber ? PhoneNumber : '',
                                      })

                                      setValue(
                                        'deliveryPhoneNumber',
                                        `${
                                          phone?.trim()?.length > 0 ? CountryCode : ''
                                        }${PhoneNumber}`
                                      )
                                    }}
                                    onBlur={() => {
                                      if (flg == '2') {
                                        setTimeout(() => {
                                          setAutoSaveData(Date.now())
                                        }, 700)
                                      }
                                    }}
                                  />
                                )}
                              />

                              {errors.deliveryPhoneNumber && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </div>
                          </h4>
                        </div>

                        {/* Delivery Email*/}
                        <div className='d-flex  vendor'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label className='labl-gry status-w-130'>
                              {' '}
                            </label>
                          </h4>
                          <h4 style={{width: '-webkit-fill-available'}}>
                            <Controller
                              name='deliveryEmail'
                              control={control}
                              rules={{
                                required: 'Email is required',
                                pattern: {
                                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                  message: 'Invalid email address',
                                },
                              }}
                              render={({field}) => (
                                <input
                                  {...register('deliveryEmail', {required: true})}
                                  type='text'
                                  className='form-control form-control-solid mytest mt-3'
                                  placeholder='Email'
                                  name='deliveryEmail'
                                  value={deliveryDetails?.email}
                                  onChange={(e) => {
                                    setDeliveryDetails({
                                      ...deliveryDetails,
                                      ['email']: e.target.value,
                                    })
                                    setValue('deliveryEmail', e.target.value)
                                  }}
                                  onBlur={() => {
                                    if (flg == '2') {
                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 700)
                                    }
                                  }}
                                   autoComplete='off'
                                />
                              )}
                            />

                            {errors.deliveryEmail && (
                              <p
                                style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                className='m-0 mt-1'
                              >
                                Please enter a valid email address
                              </p>
                            )}
                          </h4>
                        </div>

                        {/* */}
                        <div className='d-flex  vendor'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '130px'}} className='labl-gry'>
                              {'Delivery By '}
                            </label>
                          </h4>
                          <h4 style={{width: '-webkit-fill-available'}}>
                            <div>
                              <Controller
                                name='deliveryDate'
                                control={control}
                                rules={{
                                  required: 'this is required',
                                  pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: '',
                                  },
                                }}
                                render={({field}) => (
                                  <>
                                    {selectedDate != null ? (
                                      <DatePicker
                                        name='deliveryDate'
                                        defaultValue={moment(selectedDate, 'YYYY-MM-DD')}
                                        disabledDate={disabledDate}
                                        onChange={handleDateChange}
                                        // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                                        placeholder='Select Date'
                                      />
                                    ) : (
                                      <DatePicker
                                        name='deliveryDate'
                                        disabledDate={disabledDate}
                                        onChange={handleDateChange}
                                        // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                                        placeholder='Select Date'
                                      />
                                    )}
                                  </>
                                )}
                              />
                              {errors.deliveryDate && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </div>
                          </h4>
                        </div>
                      </div>
                    </Col>
                  </>
                )}
              </Row>
              <Row className='mt-7 separator mx-0'></Row>

              <div
                className='table-responsive mt-4 mb-10'
                style={{maxHeight: '400px', overflow: 'auto'}}
              >
                <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
                  <thead>
                    <th className='text-start min-w-25px ps-3'></th>
                    <th className='text-start min-w-225px ps-3'>Description</th>
                    <th className='text-start min-w-100px ps-3'>Quantity</th>
                    <th className='text-start min-w-100px ps-3'>Unit Priice</th>
                    <th className='text-start min-w-100px ps-3'>Total Price</th>
                    <th className='text-start min-w-100px'>
                      <button
                        type='button'
                        className='btn btn-sm fw-bold blue-hollow-btn ms-auto btn-fit-content ps-2'
                        style={{width: 'fit-content'}}
                        onClick={() => {
                          handleAddNewItems()
                        }}
                      >
                        <img src={plusBlue} height={18} width={18} className='me-3' />
                        Add Purchase Item
                      </button>
                    </th>
                  </thead>

                  <>
                    {purchaseOrderData?.items?.map((itm: any) => {
                      totalPrice =
                        totalPrice + parseFloat(itm?.quantity) * parseFloat(itm?.unitPrice)
                      return <></>
                    })}
                  </>

                  <tbody className='fw-semibold head-text'>
                    {purchaseOrderData?.items?.map((item: any, index: any) => {
                      return (
                        <>
                          <tr key={index}>
                            <td className='text-start ps-3'>
                              {' '}
                              <p className='pt-2' style={{fontWeight: '700'}}>
                                {index < 10 ? `0${index + 1}` : index + 1}
                              </p>
                            </td>
                            <td className='text-start ps-3'>
                              <input
                                {...register(`description${index}`, {required: true})}
                                type='text'
                                className='form-control form-control-solid mytest tenancy-control mb-1'
                                name={`description${index}`}
                                value={item?.description}
                                style={{height: '35px'}}
                                placeholder={'Item description'}
                                onChange={(e: any) => {
                                  let values = {...purchaseOrderData}
                                  let valueItems: any = [...purchaseOrderData?.items]

                                  let newVal: any = e.target.value != '' ? (e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)) : e.target.value;

                                  valueItems[index].description = newVal

                                  values.items = valueItems
                                  setPurchaseOrderData(values)

                                  setValue(`description${index}`, e.target.value)
                                }}
                                onBlur={() => {
                                  if (flg == '2') {
                                    setTimeout(() => {
                                      setAutoSaveData(Date.now())
                                    }, 700)
                                  }
                                }}
                                ref={errors[`description${index}`] ? firstErrorInputRef : null}
                                autoComplete='off'
                              />{' '}
                              {errors[`description${index}`] && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </td>
                            <td className='text-start ps-3'>
                              <input
                                {...register(`quantity${index}`, {required: true})}
                                type='number'
                                className='form-control form-control-solid mytest tenancy-control mb-1'
                                name={`quantity${index}`}
                                value={item?.quantity}
                                style={{height: '35px'}}
                                placeholder={'QTY'}
                                onChange={(e: any) => {
                                  const newValue = e.target.value.replace(/^0+/, '')
                                  let values = {...purchaseOrderData}
                                  let valueItems: any = [...purchaseOrderData?.items]
                                  valueItems[index].quantity = newValue != '' ? newValue : ''
                                  valueItems[index].totalPrice =
                                    newValue * parseFloat(valueItems[index].unitPrice)
                                  values.items = valueItems

                                  setPurchaseOrderData(values)
                                  setValue(`quantity${index}`, e.target.value)
                                }}
                                onBlur={() => {
                                  if (flg == '2') {
                                    setTimeout(() => {
                                      setAutoSaveData(Date.now())
                                    }, 700)
                                  }
                                }}
                                 autoComplete='off'
                              />{' '}
                              {errors[`quantity${index}`] && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </td>
                            <td className='text-start ps-3'>
                              <input
                                {...register(`unitPrice${index}`, {required: true})}
                                type='number'
                                className='form-control form-control-solid mytest tenancy-control mb-1'
                                name={`unitPrice${index}`}
                                value={item?.unitPrice}
                                style={{height: '35px'}}
                                placeholder={'Enter Amount'}
                                onChange={(e: any) => {
                                  const newValue = e.target.value.replace(/^0+/, '')
                                  let values = {...purchaseOrderData}
                                  let valueItems: any = [...purchaseOrderData?.items]
                                  valueItems[index].unitPrice = newValue != '' ? newValue : ''
                                  valueItems[index].totalPrice =
                                    newValue * parseFloat(valueItems[index].quantity)
                                  values.items = valueItems
                                  setPurchaseOrderData(values)

                                  setValue(`unitPrice${index}`, e.target.value)
                                }}
                                onBlur={() => {
                                  if (flg == '2') {
                                    setTimeout(() => {
                                      setAutoSaveData(Date.now())
                                    }, 700)
                                  }
                                }}
                                 autoComplete='off'
                              />{' '}
                              {errors[`unitPrice${index}`] && (
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0 mt-1'
                                >
                                  This field is required
                                </p>
                              )}
                            </td>
                            <td className='text-start ps-3'>
                              <p className='pt-2' style={{fontWeight: '800'}}>
                                {'AED'}{' '}
                                {item?.totalPrice
                                  ? item?.totalPrice?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })
                                  : '-'}
                              </p>
                            </td>
                            <td className='text-end ps-3'>
                              {purchaseOrderData?.items?.length > 1 && (
                                <img
                                  src={redCross}
                                  height={18}
                                  width={18}
                                  className='ms-auto cursor-pointer mt-2'
                                  onClick={() => {
                                    handleRemoveItems(index)
                                  }}
                                />
                              )}
                            </td>
                          </tr>
                        </>
                      )
                    })}

                    <tr className='mt-5' style={{borderTop: '1px solid lightgrey'}}>
                      <td className='text-start min-w-25px ps-3'></td>
                      <td className='text-start min-w-200px ps-3'></td>
                      <td className='text-start min-w-100px ps-3'></td>
                      <td className='text-end min-w-100px ps-3'></td>
                      <td className='text-start min-w-100px ps-3'></td>
                      <td className='text-end min-w-100px ps-3'></td>
                    </tr>
                    <tr>
                      <td className='text-start min-w-25px ps-3'></td>
                      <td className='text-start min-w-200px ps-3'></td>
                      <td className='text-start min-w-100px ps-3'></td>
                      <td className='text-end min-w-100px ps-3'>
                        <p className='pt-2 me-5'>
                          <b>{'Total Amount'}</b>
                        </p>
                      </td>
                      <td className='text-start min-w-100px ps-3'>
                        <p className='pt-2'>
                          <b>
                            {' '}
                            {'AED'}{' '}
                            {isNaN(totalPrice)
                              ? '-'
                              : totalPrice?.toLocaleString('en-US', {
                                  minimumFractionDigits: 2,
                                })}{' '}
                          </b>
                        </p>
                      </td>
                      <td className='text-end min-w-100px ps-3'></td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* <div className='d-flex mb-5'>
                <h3 className='m-0 head-text text-capitalize'>
                  <b>{`Payment Terms`}</b>
                </h3>
              </div> */}

              {/* {showPaymentTerms && ( */}
              {/* <div ref={paymentTermsRef}>
                <TextEditor
                  value={paymentTerms?.html}
                  onChange={(val: any) => {
                    let values = {...paymentTerms}
                    values.html = val
                    setPaymentTerms(values)
                  }}
                  autoSaveText={() => {
                    if (flg == '2') {
                      setTimeout(() => {
                        setAutoSaveData(Date.now())
                      }, 700)
                    }
                  }}
                  refresh={paymentTerms}
                  placeholder={'Add your payment terms here...'}
                /> */}

              {/* // )} */}
              {/* </div> */}

              {payTermsError && (
                <div className='d-flex px-1'>
                  <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 '>
                    This field is required
                  </p>
                </div>
              )}

              {purchaseOrderData?.sections?.map((pr: any, ind: any) => {
                return (
                  <div className={`${pr.animationClass}`}>
                    <div className={`d-flex mb-5 mt-10 align-items-center`}>
                      {pr?.text == 'Payment Terms Defaullttt' ? (
                        <h3 className='m-0 head-text text-capitalize'>
                          <b>{`Payment Terms`}</b>
                        </h3>
                      ) : (
                        <h3 className='m-0 head-text text-capitalize'>
                          <input
                            type='text'
                            className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                            name='mainService'
                            value={pr?.title ? pr?.title : ''}
                            style={{height: '35px', width: '300px'}}
                            placeholder={'Add Section Title'}
                            onChange={(e: any) => {
                              let values = {...purchaseOrderData}
                              let valuesSections = [...purchaseOrderData?.sections]
                              valuesSections[ind].title = e.target.value
                              values.sections = valuesSections
                              setPurchaseOrderData(values)
                            }}
                            onBlur={() => {
                              if (flg == '2') {
                                setTimeout(() => {
                                  setAutoSaveData(Date.now())
                                }, 700)
                              }
                            }}
                          />
                        </h3>
                      )}
                      <div className='d-flex ms-auto align-items-center'>
                        {purchaseOrderData?.sections?.length > 1 && (
                          <>
                            {ind != purchaseOrderData?.sections?.length - 1 && (
                              <OverlayTrigger
                                placement='top'
                                delay={{show: 250, hide: 400}}
                                overlay={
                                  <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                    {`Move this section one step down`}
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {' '}
                                  <img
                                    src={movedownImg}
                                    height={20}
                                    width={20}
                                    className='cursor-pointer'
                                    onClick={() => {
                                      swapItems(ind, ind + 1)
                                    }}
                                  />
                                </span>
                              </OverlayTrigger>
                            )}

                            {ind != 0 && (
                              <OverlayTrigger
                                placement='top'
                                delay={{show: 250, hide: 400}}
                                overlay={
                                  <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                    {`Move this section one step up`}
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {' '}
                                  <img
                                    src={moveUpImg}
                                    height={20}
                                    width={20}
                                    className='ms-5 cursor-pointer'
                                    onClick={() => {
                                      swapItems(ind - 1, ind)
                                    }}
                                  />
                                </span>
                              </OverlayTrigger>
                            )}
                          </>
                        )}

                        <img
                          src={redCrossFilled}
                          height={18}
                          width={18}
                          className='ms-5 cursor-pointer'
                          onClick={() => {
                            handleRemoveSections(ind)
                          }}
                        />
                      </div>
                    </div>

                    <TextEditor
                      value={pr?.html}
                      onChange={(val: any) => {
                        let values = {...purchaseOrderData}
                        let valuesSections = [...purchaseOrderData?.sections]
                        valuesSections[ind].html = val
                        values.sections = valuesSections
                        setPurchaseOrderData(values)
                      }}
                      autoSaveText={() => {
                        if (flg == '2') {
                          setTimeout(() => {
                            setAutoSaveData(Date.now())
                          }, 700)
                        }
                      }}
                      refresh={purchaseOrderData}
                      placeholder={'Add section details here...'}
                    />
                  </div>
                )
              })}

              <div className='d-flex justif-content-center mt-5 mb-10'>
                <button
                  type='button'
                  className='btn btn-sm fw-bold select-btn btn-fit-content ps-2 mx-auto pe-5 mb-5'
                  onClick={() => {
                    handleAddNewSections()
                  }}
                >
                  <img src={addWhite} height={18} width={18} className='me-5' /> Add Section
                </button>
              </div>

              <Row className='mt-5 separator mb-10'></Row>

              <Row className='mt-10'>
                <Col sm={6}>
                  <div className='d-flex'>
                    <p className='fs-4 status-w-120 white-dark-theme-color'> Authorized By </p>
                    <p className='white-dark-theme-color'>______________________________________________</p>
                  </div>
                </Col>
              </Row>
              <Row className='mt-10'>
                <Col sm={6}>
                  <div className='d-flex'>
                    <p className='fs-4 status-w-120 white-dark-theme-color'> Signature </p>
                    <p className='white-dark-theme-color'>______________________________________________</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className='d-flex'>
                    <p className='fs-4 status-w-120 white-dark-theme-color'> Date </p>
                    <p className='white-dark-theme-color'>______________________________________________</p>
                  </div>
                </Col>
              </Row>

              <div className='d-flex mb-5 mt-10 '>
                <h3 className='m-0 head-text text-capitalize mt-4'>
                  <b>{`Vendor`}</b>
                </h3>
              </div>

              <Row className='mt-7 mb-10'>
                <Col sm={6}>
                  <div className='d-flex'>
                    <p className='fs-4 status-w-120 white-dark-theme-color'> Signature </p>
                    <p className='white-dark-theme-color'>______________________________________________</p>
                  </div>
                </Col>
                <Col sm={6}>
                  <div className='d-flex'>
                    <p className='fs-4 status-w-120 white-dark-theme-color'> Date </p>
                    <p className='white-dark-theme-color'>______________________________________________</p>
                  </div>
                </Col>
              </Row>

              {/* {purchaseOrderData && <DnDCard data={purchaseOrderData} />} */}
            </div>
          </div>
        </form>
      </div>

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default POTemplate
