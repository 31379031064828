import React, {useEffect, useState} from 'react'
import clsx from 'clsx'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../../helpers'
import {useLayout} from '../../../core'
import facilityIcon from '../../../../../img/wrench.png'
import vendorIcon from '../../../../../img/vendor-manage.png'
import propertyIcon from '../../../../../img/building-white.png'
import allPropImg from '../../../../../img/allProperties.png'
import requestIcon from '../../../../../img/document.png'
import tenancyImg from '../../../../../img/all-tenc.png'
import tenantImg from '../../../../../img/tenant-white.png'
import annouceImg from '../../../../../img/annouce.png'
import mainImg from '../../../../../img/maintenanceWhite.png'
import { ApiPost } from '../../../../../apiCommon/helpers/API/ApiData'

type Props = {
  to: any
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  hasProperty?: boolean
  hasRequest?: boolean
  facility?: any
  vendorManagement?: any
  dashboard?: boolean
  hasTenancy?: boolean
  hasTenant?: boolean
  hasAnnouce?: boolean
  hasMaintenance?: any
}

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet,
  hasProperty,
  hasRequest,
  facility,
  vendorManagement,
  hasTenancy,
  hasTenant,
  hasAnnouce,
  hasMaintenance
}) => {
  const {pathname} = useLocation()
  // const isActive = checkIsActive(pathname, to)
  const isActive = to.includes(pathname)
  const {config} = useLayout()
  const {app} = config
  const [disableSideBar, setDisableSideBar] = useState<any>(false)

  const refresh = () => {
    let temp = localStorage.getItem('isFormDirty')
    if (temp != null) {
      if (JSON.parse(temp) == true) {
        setDisableSideBar(true)
      }
    } else {
      setDisableSideBar(false)
    }
  }

  useEffect(() => {
    refresh()
  }, [])

  setInterval(refresh, 5000)

  useEffect(() => {
    localStorage.setItem('fb_page', '1')
    let temp = localStorage.getItem('DeleteClone')
    if (temp != null) {
      let val = JSON.parse(temp)
      const body = {
        tenancyId: val?.tenancyId,
      }

      ApiPost(`corporate/tenancy/terminate/delete_clone`, body)
        .then(() => {
          localStorage.removeItem('DeleteClone')
        })
        .catch((err: any) => {})
    }
  }, [isActive])

  return (
    <>
      {disableSideBar ? (
        <div
          className={clsx('menu-item', {'here show': isActive}, '')}
          style={{opacity: '0.7', cursor: 'not-allowed'}}
          // className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
          // data-kt-menu-trigger='click'
        >
          <span className='menu-link hover'> 
          
            {hasRequest && (
              <span className='menu-icon'>
                <img src={requestIcon} alt='chat' width='21px' height='22px' />
              </span>
            )}
            {hasProperty && (
              <span className='menu-icon'>
                <img src={allPropImg} alt='chat' width='19px' height='19px' />
              </span>
            )}

           {hasTenancy && (
              <span className='menu-icon'>
                <img src={tenancyImg} alt='chat' width='19px' height='19px' />
              </span>
            )}

            {hasTenant && (
              <span className='menu-icon'>
                <img src={tenantImg} alt='chat' width='19px' height='19px' />
              </span>
            )}
            {hasBullet && (
              <span className='menu-bullet ' style={{marginLeft: '1.1rem'}}>
                <span className='bullet bullet-dot' style={{height: '5px', width: '5px'}}></span>
              </span>
            )}
            {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
              <i className={clsx('bi fs-3', fontIcon)}></i>
            )}
            {facility && (
              <span className='menu-icon'>
                <img src={facilityIcon} alt='chat' width='19px' height='19px' />
              </span>
            )}
            {vendorManagement && (
              <span className='menu-icon'>
                <img src={vendorIcon} alt='chat' width='23px' height='23px' />
              </span>
            )}
            <span className='menu-title text-white'>{title}</span>
            <span className='menu-arrow'></span>
          </span>
          <div
            className={clsx('menu-sub menu-sub-accordion text-white', {'menu-active-bg': isActive})}
          >
            {children}
          </div>
        </div>
      ) : (
        <div
          className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
          // className={clsx('menu-item', {'here show': isActive}, 'menu-accordion')}
          data-kt-menu-trigger='click'
        >
          <span className='menu-link hover'>
            {hasRequest && (
              <span className='menu-icon'>
                <img src={requestIcon} alt='chat' width='21px' height='22px' />
              </span>
            )}
            {hasProperty && (
              <span className='menu-icon'>
                <img src={allPropImg} alt='chat' width='22px' height='22px' />
              </span>
            )}

           {hasTenancy && (
              <span className='menu-icon'>
                <img src={tenancyImg} alt='chat' width='18px' height='19px' />
              </span>
            )}

            {hasTenant && (
              <span className='menu-icon'>
                <img src={tenantImg} alt='chat' width='19px' height='19px' />
              </span>
            )}
            {hasAnnouce && (
              <span className='menu-icon'>
                <img src={annouceImg} alt='chat' width='19px' height='19px' />
              </span>
            )}
            {hasMaintenance && (
              <span className='menu-icon'>
                <img src={mainImg} alt='chat' width='19px' height='19px' />
              </span>
            )}
            {hasBullet && (
              <span className='menu-bullet ' style={{marginLeft: '1.1rem'}}>
                <span className='bullet bullet-dot' style={{height: '5px', width: '5px'}}></span>
              </span>
            )}
            
            {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
              <span className='menu-icon'>
                <KTSVG path={icon} className='svg-icon-2' />
              </span>
            )}
            {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
              <i className={clsx('bi fs-3', fontIcon)}></i>
            )}
            {facility && (
              <span className='menu-icon'>
                <img src={facilityIcon} alt='chat' width='19px' height='19px' />
              </span>
            )}
            {vendorManagement && (
              <span className='menu-icon'>
                <img src={vendorIcon} alt='chat' width='23px' height='23px' />
              </span>
            )}
            <span className='menu-title text-white'>{title}</span>
            <span className='menu-arrow'></span>
          </span>
          <div
            className={clsx('menu-sub menu-sub-accordion text-white', {'menu-active-bg': isActive})}
          >
            {children}
          </div>
        </div>
      )}
    </>
  )
}

export {SidebarMenuItemWithSub}
