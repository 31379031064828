import React, {useState} from 'react'
import {Modal} from 'react-bootstrap'
import {CircularProgress} from '@mui/material'
import redCross from '../../../img/remove.png'
import './style.scss'

interface SelectTemplateProps {
  isVisible: boolean
  handleClose: any
  temps: any
  selectedTemplate: any
  updateTemplate:any
}

const SelectTemplate: React.FC<SelectTemplateProps> = ({
  isVisible,
  handleClose,
  temps,
  selectedTemplate,
  updateTemplate
}) => {
  const [templates, setTemplates] = useState<any>(temps ? temps : [])
  const [selected, setSelected] = useState<any>(selectedTemplate ? selectedTemplate : '')

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={isVisible}
        onHide={() => handleClose()}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-3 px-lg-7'
          style={{
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '400px',
            // maxHeight: '600px',
            // overflow: 'auto',
          }}
        >
          <div className='d-flex align-items-center mt-3 mb-1'>
            <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
              Select Template
            </h2>

            <div className='d-flex ms-auto'>
              <button
                type='button'
                className='btn btn-sm fw-bold  me-3  red-hollow-btn'
                onClick={() => {
                  handleClose()
                }}
              >
                <img src={redCross} height={16} width={16} style={{marginRight: '10px'}} /> Cancel
              </button>
            </div>
          </div>

          <div className='row mt-5 mx-0 separator'></div>

          {templates?.length > 0 ? (
            <div className='tops d-flex gap-5 align-items-start mx-5 flex-wrap mt-5'
            style={{maxHeight: '400px', overflow: 'auto'}}
            >
              {templates?.map((file: any, ind: any) => {
                return (
                  <>
                    <div className='imgs-set d-block align-items-center text-center me-3'>
                      <div className='d-flex mw-350px'>
                        <img
                          src={file?.src}
                          width={120}
                          height={150}
                          className={`main_img mb-2 cursor-pointer ${
                            selected == file.name ? 'green-template-border' : 'template-border'
                          }`}
                          onClick={()=>{
                            setSelected(file?.name)
                            updateTemplate(file?.name)}}
                        />
                      </div>
                      <h5 className='text-capitalize'>
                        <b>{file?.name}</b>
                      </h5>
                    </div>
                  </>
                )
              })}
            </div>
          ) : (
            <div className='ms-3'>{'-'}</div>
          )}
        </div>
      </Modal>
    </>
  )
}

export default SelectTemplate
