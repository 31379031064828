import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {CreateAppModal} from '../../../_metronic/partials'
import swal from 'sweetalert2'
import AddBuilding from './AddBuilding'
import {TbChevronDown} from 'react-icons/tb'
import {KTSVG} from '../../../_metronic/helpers'
import noData from '../../../img/NoData1.svg'
import {Col, Dropdown, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import TitleHead from '../units/comman/TitleHead'
import SubHead from '../units/comman/SubHead'
import LabelTitle from '../units/comman/LabelTitle'

const BuildingCluster = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
  } = useForm<any>()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [tableData, setTableData] = useState([])
  const [updateDataId, setUpdateDataId] = useState('')
  const [propertiType, setPropertiType] = useState('Buildings')
  const [show, setShow] = useState(false)
  const [showCom, setShowCom] = useState(false)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [isEdit, setIsEdit] = useState(false)
  const [pageLimit, setPageLimit] = useState<any>(1)

  const [header, setHeader] = useState<any>({})
  const [headerB, setHeaderB] = useState<any>([])
  const [formData, setFormData] = useState<any>({})
  console.log('\nheaderB', header)

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }

  const building = async (a: any, b: any) => {
    if (propertiType === 'Buildings') {
      const body = {
        page: page,
        limit: 100,
        type: 1,
        clusterId: window.location.pathname.split('/')[3],
      }
      await ApiPost('corporate/building/get', body)
        .then((res) => {
          setTableData(res?.data?.data?.building_data)
          setPageLimit(res?.data?.data?.state?.page_limit)
          console.log('res', res)
          // setFormData({
          //   portfolioType: a,
          //   portfolio: b,
          // })
        })
        .catch((e) => {
          console.log(e)
        })
    }
    if (propertiType === 'Communities') {
      const body = {
        page: page,
        limit: 100,
      }
      await ApiPost('corporate/communities/get', body)
        .then((res) => {
          setTableData(res?.data?.data?.communities_data)
          console.log('res', res)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const deleteProperty = (id: any) => {
    if (propertiType === 'Buildings') {
      ApiDelete(`corporate/building/${id}`)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          building(formData?.portfolioType, formData?.portfolio)
        })

        .catch((err) => ErrorToast(err.message))
    } else {
      ApiDelete(`corporate/communities/${id}`)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          building(formData?.portfolioType, formData?.portfolio)
        })

        .catch((err) => ErrorToast(err.message))
    }
  }

  const editBtn = (id: any) => {
    setUpdateDataId(id)
    setIsEdit(true)
    setShowCreateAppModal(true)
    ApiGet(`corporate/building/${id}`)
      .then((res) => {
        console.log('res', res?.data?.data[0])
        setFormData(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  const handleSubmit_main = () => {
    if (isEdit) {
      delete formData._id
      delete formData.updatedBy
      delete formData.status
      if (propertiType === 'Buildings') {
        const body = {...formData, managerId: formData?.managerId, id: updateDataId}
        ApiPut('corporate/building', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building(formData?.portfolioType, formData?.portfolio)
            getHeaderfData()
            setIsEdit(false)
            console.log('addProperty', res)
            setShowCreateAppModal(false)
            setFormData({
              portfolioType: formData?.portfolioType,
              portfolio: formData.portfolio,
            })
          })
          .catch((err) => ErrorToast(err.message))
      } else {
        const body = {
          name: formData?.name,
          city: formData?.city,
          street: formData?.street,
          area: formData?.area,
          totalFloors: 0,
          totalClusters: 0,
          unitClusters: formData?.totalZones,
          buildingClusters: formData?.totalBuildings,
          mixedClusters: 0,
          managerId: formData?.managerId,
          id: updateDataId,
        }
        ApiPut('corporate/communities', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building(formData?.portfolioType, formData?.portfolio)
            getHeaderfData()
            setIsEdit(false)
            setShowCreateAppModal(false)
            setFormData({
              portfolioType: formData?.portfolioType,
              portfolio: formData.portfolio,
            })
          })
          .catch((err) => ErrorToast(err.message))
      }
    } else {
      if (propertiType === 'Buildings') {
        // const body = {...formData, managerId: formData?.managerId}
        const body = {
          name: formData?.name,
          managerId: formData?.managerId,
          type: 1,
          totalFloors: formData?.totalFloors,
          city: formData?.city,
          street: formData?.street,
          area: formData?.area,
          clusterId: window.location.pathname.split('/')[3],
        }
        ApiPost('corporate/building', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building(formData?.portfolioType, formData?.portfolio)
            getHeaderfData()
            setShowCreateAppModal(false)
            setFormData({
              portfolioType: formData?.portfolioType,
              portfolio: formData.portfolio,
            })
          })
          .catch((err) => ErrorToast(err.message))
      } else {
        // const body = {...formData, managerId: formData?.managerId}
        const body = {
          name: formData?.name,
          city: formData?.city,
          street: formData?.street,
          area: formData?.area,
          totalFloors: 0,
          totalClusters: 0,
          unitClusters: formData?.totalZones,
          buildingClusters: formData?.totalBuildings,
          mixedClusters: 0,
          managerId: formData?.managerId,
        }
        ApiPost('corporate/communities', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            building(formData?.portfolioType, formData?.portfolio)
            getHeaderfData()
            setShowCreateAppModal(false)
            setFormData({
              portfolioType: formData?.portfolioType,
              portfolio: formData.portfolio,
            })
          })
          .catch((err) => ErrorToast(err.message))
      }
    }
  }
  const handleSubmitCom = () => {
    if (isEdit) {
      delete formData._id
      delete formData.updatedBy
      delete formData.status
      const body = {...formData, managerId: '6329ee43396e812bcc0964e5', id: updateDataId}
      ApiPut('corporate/properties', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building(formData?.portfolioType, formData?.portfolio)
          getHeaderfData()
          setIsEdit(false)
          setShowCreateAppModal(false)
          setFormData({
            portfolioType: formData?.portfolioType,
            portfolio: formData.portfolio,
          })
        })
        .catch((err) => ErrorToast(err.message))
    } else {
      // const body = {...formData, managerId: '6329ee43396e812bcc0964e5'}
      const body = {
        portfolioType: formData?.portfolioType,
        portfolio: formData?.portfolio,
        name: formData?.name,
        city: formData?.city,
        location: formData?.location,
        street: formData?.street,
        totalZones: formData?.totalZones,
        totalBuildings: formData?.totalBuildings,
        managerId: '632aa52cacdb90269c4655d5',
      }
      ApiPost('corporate/properties', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building(formData?.portfolioType, formData?.portfolio)
          getHeaderfData()
          setShowCreateAppModal(false)
          setFormData({
            portfolioType: formData?.portfolioType,
            portfolio: formData.portfolio,
          })
        })
        .catch((err) => ErrorToast(err.message))
    }
  }
  const getHeaderfData = async () => {
    await ApiGet(`corporate/cluster/${window.location.pathname.split('/')[3]}`)
      .then((res) => {
        setHeader(res?.data?.data)
        ApiGet(`corporate/communities/${res?.data?.data[0]?.communityId}`)
          .then((res) => {
            setHeaderB(res?.data?.data)
          })
          .catch((err) => console.log('err', err))
      })
      .catch((err) => console.log('err', err))
  }
  // let id = header[0]?.communityId
  // console.log('id', id)
  // const getHeaderbData = async () => {
  //   await ApiGet(`corporate/communities/${id}`)
  //     .then((res) => {
  //       console.log('res', res?.data?.data)
  //       setHeaderB(res?.data?.data)
  //     })
  //     .catch((err) => console.log('err', err))
  // }

  useEffect(() => {
    building(page, limit)
  }, [propertiType, page])

  useEffect(() => {
    building(page, limit)
    getHeaderfData()

    if (isEdit) {
      ApiGet(`corporate/properties/${updateDataId}`)
        .then((response) => {
          console.log('response', response)
          setFormData(response?.data?.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
    // getHeaderbData()
  }, [propertiType, page])

  const onSubmit = (data: any, event: any) => {
    event.preventDefault()
  }

  const navigate = useNavigate()
  return (
    <>
      <form onSubmit={handleSubmit((data: any, e: any) => onSubmit(formData, e))}>
        <div
          className='app-container container-xxl m-auto gap-5 d-flex flex-column '
          id='kt_app_main'
        >
          <div className='d-flex align-items-baseline flex-wrap m-0'>
            <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
              <li className='breadcrumb-item'>
                <a
                  className='text-muted px-2 cursor-pointer'
                  onClick={() => navigate('/dashboard')}
                >
                  Manage Properties
                </a>
              </li>
              <li className='breadcrumb-item '>
                <a className='text-muted px-2 cursor-pointer' onClick={() => navigate('/building')}>
                  Communities
                </a>
              </li>
              <li className='breadcrumb-item '>
                <a
                  className='text-muted px-2 cursor-pointer'
                  // onClick={() => navigate('/building')}
                  onClick={() => navigate(`/areaCom/${window.location.pathname.split('/')[2]}`)}
                >
                  Clusters
                </a>
              </li>
              {/* <li className='breadcrumb-item '>
                          <a
                            className='text-muted px-2 cursor-pointer'
                            // onClick={() => navigate('/building')}
                            onClick={() =>
                              navigate(
                                `/unitscom/${window.location.pathname.split('/')[2]}/${
                                  window.location.pathname.split('/')[3]
                                }`
                              )
                            }
                          >
                            Unit
                          </a>
                        </li> */}
              <li className='breadcrumb-item  active'>
                <a className='px-2'></a>
              </li>
            </ul>
          </div>

          <TitleHead title='Buildings' btnTitle='Add Building' />
          <div className='card grid-out-diff-gap p-5'>
            <div className='side'>
              <h6 className='text-muted mx-0 align-items-center '>
                Community
                <div className='mx-1 value_black'>{headerB[0]?.name}</div>
              </h6>
              <h6 className='text-muted mx-0 align-items-center '>
                Cluster Type
                <div className='mx-1 value_black'>Building Cluster</div>
              </h6>
              <h6 className='text-muted mx-0 align-items-center '>
                Cluster Name
                <div className='mx-1 value_black'>{header[0]?.name}</div>
              </h6>
            </div>
            <div className='side'>
              <h6 className='text-muted mx-0 align-items-center '>
                Total Building
                <div className='mx-1 value_black'>{header[0]?.totalBuildings}</div>
              </h6>
              <h6 className='text-muted mx-0 align-items-center '>
                Total Unit
                <div className='mx-1 value_black'>{header[0]?.totalUnits}</div>
              </h6>
            </div>
          </div>

          {show && propertiType === 'Buildings' && (
            <>
              <div className='card p-5 grid-out-diff-gap'>
                <h3 className='tops green_color'>ADD NEW BUILDINGS</h3>
                <div className='side'>
                  <div className=' text-muted mx-0 align-items-center '>
                    <LabelTitle title='Building Name' htmlFor='name1' />
                    <div className='mx-1 value_black'>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        // data-kt-select2='true'
                        // data-placeholder='Select option'
                        // data-dropdown-parent='#kt_menu_631f08e971923'
                        // data-allow-clear='true'
                        name='name'
                        id='name1'
                        value={formData?.name}
                        onChange={handleChnage}
                      />
                    </div>
                  </div>
                  <div className='text-muted mx-0 align-items-center '>
                    <LabelTitle title='Total Floor' htmlFor='totalFloors' />
                    <div className='mx-1 value_black'>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        name='totalFloors'
                        id='totalFloors'
                        value={formData?.totalFloors}
                        onChange={handleChnage}
                      />
                    </div>
                  </div>
                  <div className='text-muted mx-0 align-items-center '>
                    <LabelTitle title='Building Manager' htmlFor='managerId' />
                    <div className='mx-1 value_black'>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        name='managerId'
                        id='managerId'
                        value={formData?.managerId}
                        onChange={handleChnage}
                      />
                    </div>
                  </div>
                </div>
                <div className='side'>
                  <div className=' text-muted mx-0 align-items-center '>
                    <LabelTitle title='City' htmlFor='city' />
                    <div className='mx-1 value_black'>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        name='city'
                        id='city'
                        value={formData?.city}
                        onChange={handleChnage}
                      />
                    </div>
                  </div>
                  <div className='text-muted mx-0 align-items-center '>
                    <LabelTitle title='Location' htmlFor='location' />
                    <div className='mx-1 value_black'>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        name='location'
                        id='location'
                        value={formData?.location}
                        onChange={handleChnage}
                      />
                    </div>
                  </div>
                  <div className='text-muted mx-0 align-items-center '>
                    <LabelTitle title='Street' htmlFor='street' />
                    <div className='mx-1 value_black'>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        name='street'
                        id='street'
                        value={formData?.street}
                        onChange={handleChnage}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end mb-4 px-4 tops'>
                  <div className='me-2'>
                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                      onClick={() => setShow(false)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 me-1 '
                      />{' '}
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button
                      type='button'
                      className='btn btn-lg btn-primary btn-green'
                      data-kt-stepper-action='submit'
                      onClick={handleSubmit_main}
                    >
                      Submit{' '}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {showCom && propertiType === 'Communities' && (
            <>
              <div className='card p-5 grid-out-diff-gap'>
                <h3 className='tops green_color'>ADD NEW COMMUNITIES</h3>
                <div className='side'>
                  <div className=' text-muted mx-0 align-items-center '>
                    <LabelTitle title='Communities Name' htmlFor='name' />
                    <div className='mx-1 value_black'>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        name='name'
                        id='name'
                        value={formData?.name}
                        onChange={handleChnage}
                      />
                    </div>
                  </div>
                  <div className='text-muted mx-0 align-items-center '>
                    <LabelTitle title='Community Manager' htmlFor='managerId' />
                    <div className='mx-1 value_black'>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        name='managerId'
                        id='managerId'
                        value={formData?.managerId}
                        onChange={handleChnage}
                      />
                    </div>
                  </div>
                  <div className='text-muted mx-0 align-items-center '>
                    <LabelTitle title='Total Zones' htmlFor='totalZones' />
                    <div className='mx-1 value_black'>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        name='totalZones'
                        id='totalZones'
                        value={formData?.totalZones}
                        onChange={handleChnage}
                      />
                    </div>
                  </div>
                  <div className='text-muted mx-0 align-items-center '>
                    <LabelTitle title='Total Buildings' htmlFor='totalBuildings' />
                    <div className='mx-1 value_black'>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        name='totalBuildings'
                        id='totalBuildings'
                        value={formData?.totalBuildings}
                        onChange={handleChnage}
                      />
                    </div>
                  </div>
                </div>
                <div className='side'>
                  <div className=' text-muted mx-0 align-items-center '>
                    <LabelTitle title='City' htmlFor='city' />
                    <div className='mx-1 value_black'>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        name='city'
                        id='city'
                        value={formData?.city}
                        onChange={handleChnage}
                      />
                    </div>
                  </div>
                  <div className='text-muted mx-0 align-items-center '>
                    <LabelTitle title='Location' htmlFor='location' />
                    <div className='mx-1 value_black'>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        name='location'
                        id='location'
                        value={formData?.location}
                        onChange={handleChnage}
                      />
                    </div>
                  </div>
                  <div className='text-muted mx-0 align-items-center '>
                    <LabelTitle title='Street' htmlFor='street' />
                    <div className='mx-1 value_black'>
                      <input
                        className='form-control form-control-solid'
                        type='text'
                        data-kt-select2='true'
                        data-placeholder='Select option'
                        data-dropdown-parent='#kt_menu_631f08e971923'
                        data-allow-clear='true'
                        name='street'
                        id='street'
                        value={formData?.street}
                        onChange={handleChnage}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-end mb-4 px-4 tops'>
                  <div className='me-2'>
                    <button
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                      onClick={() => setShowCom(false)}
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-3 me-1 '
                      />{' '}
                      Cancel
                    </button>
                  </div>
                  <div>
                    <button
                      type='button'
                      className='btn btn-lg btn-primary btn-green'
                      data-kt-stepper-action='submit'
                      onClick={handleSubmitCom}
                    >
                      Submit{' '}
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr064.svg'
                        className='svg-icon-3 ms-2 me-0'
                      />
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}

          <div className='card p-5'>
            <div className='table-responsive mb-5'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    {/* <th className='w-10px pe-2'>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            data-kt-check='true'
                            data-kt-check-target='#kt_ecommerce_sales_table .form-check-input'
                            value='1'
                          />
                        </div>
                      </th> */}
                    <th className='text-center min-w-100px'>#</th>
                    <th className='min-w-100px'>
                      {propertiType === 'Buildings' ? 'BUILDING NAME' : ' COMMUNITY NAME'}
                    </th>
                    <th className='text-center min-w-100px'>
                      {' '}
                      {propertiType === 'Buildings' ? 'TOTAL FLOORS' : ' TOTAL CLUSTERS'}
                    </th>
                    <th className='text-center min-w-70px'>
                      {propertiType === 'Buildings' ? 'TOTAL PROPERTIES' : ' UNIT CLUSTERS'}
                    </th>
                    {propertiType === 'Buildings' ? (
                      ''
                    ) : (
                      <>
                        <th className='text-center min-w-100px'>BUILDING CLUSTERS</th>
                        <th className='text-center min-w-100px'>MIXED CLUSTERS</th>
                        <th className='text-center min-w-100px'>TOTAL PROPERTIES</th>
                      </>
                    )}
                    <th className='text-center min-w-100px'>
                      {propertiType === 'Buildings' ? 'BUILDING MANAGER' : ' COMMUNITY MANAGER'}
                    </th>
                    <th className='text-center min-w-100px'></th>

                    <th className='text-end min-w-100px'></th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-800'>
                  {tableData?.length ? (
                    tableData?.map((v: any, i: any) => {
                      return (
                        <tr>
                          {/* <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                          <input className='form-check-input' type='checkbox' value='1' />
                        </div>
                      </td> */}
                          {propertiType === 'Buildings' ? (
                            <>
                              {' '}
                              <td data-kt-ecommerce-order-filter='order_id' className='text-center'>
                                {i + 1}
                              </td>
                              <td data-kt-ecommerce-order-filter='order_id'>{v?.name}</td>
                              <td className='text-center'>{v?.totalFloors}</td>
                              <td className='text-center'>{v?.totalProperties}</td>
                              <td className='text-center'>{v?.managerId}</td>
                            </>
                          ) : (
                            <>
                              {' '}
                              <td data-kt-ecommerce-order-filter='order_id'>{v?.name}</td>
                              <td className='text-center'>{v?.totalClusters}</td>
                              <td className='text-center'>{v?.unitClusters}</td>
                              <td className='text-center'>{v?.buildingClusters}</td>
                              <td className='text-center'>{v?.mixedClusters}</td>
                              <td className='text-center'>{v?.totalProperties}</td>
                              <td className='text-center'>{v?.managerId}</td>
                            </>
                          )}
                          <td className='text-center'>
                            <div className='menu-item px-3'>
                              {/* <a
                                onClick={() => navigate(`/area/${v?._id}`)}
                                className='btn btn-sm fw-bold btn-primary btn-green'
                                data-bs-toggle='modal'
                                data-bs-target='#kt_modal_create_app'
                              >
                                Area
                              </a> */}
                              <div
                                onClick={() => {
                                  propertiType === 'Communities'
                                    ? navigate(`/areaCom/${v?._id}`)
                                    : navigate(`/area/${v?._id}`)
                                }}
                                className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                              >
                                {propertiType === 'Communities' ? 'Clusters' : 'Floors'}
                              </div>
                            </div>
                          </td>

                          <td className='text-end d-flex ' style={{justifyContent: 'end'}}>
                            <Dropdown className='' style={{width: 'fit-content'}}>
                              <Dropdown.Toggle
                                variant='success'
                                id='dropdown-basic'
                                className='btn btn-sm btn-light btn-active-light-primary text-hover-green'
                              >
                                Actions <TbChevronDown />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  href='#/action-1'
                                  // onClick={() => {
                                  //   console.log('clicked')

                                  //   setUpdateDataId(v?._id)
                                  //   setIsEdit(true)
                                  //   setShowCreateAppModal(true)
                                  // }}
                                  // className='menu-link px-3'
                                  onClick={() => editBtn(v?._id)}
                                >
                                  Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href='#/action-2'
                                  onClick={() => {
                                    swal
                                      .fire({
                                        text: 'Are you sure you want to permanently delete this community? Deleting this community will delete all the data registered under the community.',
                                        icon: 'warning',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#D72852',
                                        confirmButtonText: 'Yes',
                                        showCancelButton: true,
                                        // cancelButtonColor: "transparent",
                                        cancelButtonText: 'Cancel',
                                      })
                                      .then((res) => {
                                        if (res.isConfirmed) {
                                          deleteProperty(v?._id)
                                        }
                                      })
                                  }}
                                  className='menu-link px-3'
                                  data-kt-ecommerce-order-filter='delete_row'
                                >
                                  Delete{' '}
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                            {/* <a
                              className='btn btn-sm btn-light btn-active-light-primary text-hover-green'
                              data-kt-menu-trigger='click'
                              data-kt-menu-placement='bottom-end'
                            >
                              <span className='text-hover-green'>Actions</span>
                              <span className='svg-icon svg-icon-5 m-0 text-hover-green'>
                                <svg
                                  width='24'
                                  height='24'
                                  viewBox='0 0 24 24'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
                                    fill='currentColor'
                                  />
                                </svg>
                              </span>
                            </a>
                            <div
                              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                              data-kt-menu='true'
                            >
                              <div className='menu-item px-3'>
                                <a
                                  onClick={() => {
                                    console.log('clicked')

                                    setUpdateDataId(v?._id)
                                    setIsEdit(true)
                                    setShowCreateAppModal(true)
                                  }}
                                  className='menu-link px-3'
                                >
                                  Edit
                                </a>
                              </div>
                              <div className='menu-item px-3'>
                                <a
                                  // onClick={() => deleteProperty(v?._id)}
                                  onClick={() => {
                                    swal
                                      .fire({
                                        text: 'Are you sure you want to permanently delete this building? Deleting this building will delete all the data registered under the building.',
                                        icon: 'warning',
                                        showConfirmButton: true,
                                        confirmButtonColor: '#D72852',
                                        confirmButtonText: 'Yes',
                                        showCancelButton: true,
                                        // cancelButtonColor: "transparent",
                                        cancelButtonText: 'Cancel',
                                      })
                                      .then((res) => {
                                        if (res.isConfirmed) {
                                          deleteProperty(v?._id)
                                        }
                                      })
                                  }}
                                  className='menu-link px-3'
                                  data-kt-ecommerce-order-filter='delete_row'
                                >
                                  Delete
                                </a>
                              </div>
                            </div> */}
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={7} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                {/* <div className='dataTables_length' id='kt_ecommerce_sales_table_length'>
                <label>
                  <select
                    name='kt_ecommerce_sales_table_length'
                    aria-controls='kt_ecommerce_sales_table'
                    className='form-select form-select-sm form-select-solid'
                  >
                    <option value='10'>10</option>
                    <option value='25'>25</option>
                    <option value='50'>50</option>
                    <option value='100'>100</option>
                  </select>
                </label>
              </div> */}
              </div>
              <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_ecommerce_sales_table_paginate'
                >
                  <ul className='pagination'>
                    <li
                      className='paginate_button page-item previous disabled'
                      id='kt_ecommerce_sales_table_previous'
                    >
                      <a
                        href='#'
                        aria-controls='kt_ecommerce_sales_table'
                        data-dt-idx='0'
                        tabIndex={0}
                        className='page-link'
                      >
                        <i className='previous'></i>
                      </a>
                    </li>
                    <li className={`paginate_button page-item + ${page === 1 ? 'active' : ''}`}>
                      <a
                        href='#'
                        aria-controls='kt_ecommerce_sales_table'
                        data-dt-idx='1'
                        tabIndex={0}
                        className='page-link'
                        onClick={() => setPage(1)}
                      >
                        1
                      </a>
                    </li>
                    {pageLimit >= 2 && (
                      <li className={`paginate_button page-item + ${page === 2 ? 'active' : ''}`}>
                        <a
                          href='#'
                          aria-controls='kt_ecommerce_sales_table'
                          data-dt-idx='2'
                          tabIndex={0}
                          className='page-link'
                          onClick={() => setPage(2)}
                        >
                          2
                        </a>
                      </li>
                    )}
                    {pageLimit >= 3 && (
                      <li className={`paginate_button page-item + ${page === 3 ? 'active' : ''}`}>
                        <a
                          href='#'
                          aria-controls='kt_ecommerce_sales_table'
                          data-dt-idx='3'
                          tabIndex={0}
                          className='page-link'
                          onClick={() => setPage(3)}
                        >
                          3
                        </a>
                      </li>
                    )}
                    {pageLimit >= 4 && (
                      <li className={`paginate_button page-item + ${page === 4 ? 'active' : ''}`}>
                        <a
                          href='#'
                          aria-controls='kt_ecommerce_sales_table'
                          data-dt-idx='4'
                          tabIndex={0}
                          className='page-link'
                          onClick={() => setPage(4)}
                        >
                          4
                        </a>
                      </li>
                    )}
                    {pageLimit >= 5 && (
                      <li className={`paginate_button page-item + ${page === 5 ? 'active' : ''}`}>
                        <a
                          href='#'
                          aria-controls='kt_ecommerce_sales_table'
                          data-dt-idx='5'
                          tabIndex={0}
                          className='page-link'
                          onClick={() => setPage(5)}
                        >
                          5
                        </a>
                      </li>
                    )}
                    <li
                      className='paginate_button page-item next'
                      id='kt_ecommerce_sales_table_next'
                    >
                      <a
                        href='#'
                        aria-controls='kt_ecommerce_sales_table'
                        data-dt-idx='6'
                        tabIndex={0}
                        className='page-link'
                      >
                        <i className='next'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      {showCreateAppModal && (
        <AddBuilding
          show={showCreateAppModal}
          handleClose={() => {
            setShowCreateAppModal(false)
            setIsEdit(false)
            setFormData({})
          }}
          building={building}
          handleChnage={handleChnage}
          updateDataId={updateDataId}
          isEdit={isEdit}
          propertiType={propertiType}
          formData={formData}
          onSubmit={handleSubmit_main}
        />
      )}
    </>
  )
}

export default BuildingCluster
