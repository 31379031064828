import React from 'react'
import {Box, Grid, TextField, Typography} from '@mui/material'
import {Controller} from 'react-hook-form'
import InputNotRequired from '../../../../../../../../General/InputNotRequired'

interface DocumentNameProps {
  type: string
  register: any
  errors: any
  itemData: any
  setItemData: (data: any) => void
  setValue: any
  trigger: any
  placeholderText:string
}

const DocumentName: React.FC<DocumentNameProps> = ({
  type,
  register,
  errors,
  itemData,
  setItemData,
  setValue,
  trigger,
  placeholderText
}) => {
 

  return (
    <Grid container spacing={3} sx={{mt: 0, ml: 0, mr: 4}}>
      <Grid item xs={10} className='px-0'>
        <Typography variant='h6' fontWeight={700} className='required'>
          Name
        </Typography>

        <Box display='flex' alignItems='center' mt={1}>
          <Typography variant='body2' fontWeight={500}>
            {type} {'  -  '}
          </Typography>

          <Box sx={{width: '60%', ml: 3}}>
            <InputNotRequired
              required={true}
              register={register}
              errors={errors}
              value={itemData?.name}
              setValue={setValue}
              trigger={trigger}
              onBlur={() => {}}
              onChange={(val: any) => {
                setItemData({...itemData, ['name']: val})
              }} // Pass the callback function to handle input changes
              placeholder={placeholderText}
              name='templateName'
              pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
              maxLength={70} // Maximum length of 70 characters
              className=''
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

export default DocumentName
