import React, {useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useParams} from 'react-router-dom'
import ShowMore from './ShowMore'

interface AssignedPropertiesProps {
  chrgData?: any
}

const AssignedProperties = ({chrgData}: AssignedPropertiesProps) => {
  const {flg} = useParams()
  const [tableData, setTableData] = useState<any>([])
  const [showShowMoreModal, setShowMoreModal] = useState<boolean>(false)
  const [selectedColumn, setSelectedColumn] = useState<any>('')
  const [selectedColumnData, setSelectedColumnData] = useState<any>([])

  React.useEffect(() => {
    if (flg == '2') setTableData(chrgData?.developmentConfiguration)
  }, [chrgData])

  return (
    <>
      <Row className='lease-charges'>
        <Col md={12}>
          <div
            className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3'
            style={{minHeight: '450px'}}
          >
            <div className='card-body pt-0 mt-5 py-0 px-3'>
              <div className='d-flex'>
                <div>
                  <h3 className='page-heading m-0 white-dark-theme-color fnt-700'>
                    {chrgData?.appliesToAllUnits == 0
                      ? 'Assigned Properties'
                      : 'All Properties Selected.'}
                  </h3>
                  {chrgData?.appliesToAllUnits == 1 && (
                    <p
                      className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'
                      style={{color: '#a4a4a4'}}
                    >
                      The charge will be applied to all annual leases created for all properties.
                    </p>
                  )}
                </div>
              </div>

              {chrgData?.appliesToAllUnits == 0 && (
                <>
                  {/*  */}
                  {tableData?.length > 0 && (
                    <div
                      className='table-responsive mt-10'
                      style={{minHeight: '300px', maxHeight: '600px', overflow: 'auto'}}
                    >
                      <table className='table align-middle gy-5 ' id='kt_ecommerce_sales_table'>
                        <thead className='fs-5 text-dark fnt-500'>
                          <th className='text-start min-w-350px ps-0 pe-10'>Development</th>
                          <th className='text-start min-w-225px pe-0'> Furnishing Type</th>
                          <th className='text-start min-w-225px max-w-300px ps-0'>Unit Type</th>
                          <th className='text-start min-w-225px ps-0'>Bedrooms</th>
                          <th className='text-start min-w-100px ps-0'>Units</th>
                        </thead>
                        <tbody className='fw-semibold head-text'>
                          {tableData?.map((tb: any, ind: any) => {
                            return (
                              <tr key={ind}>
                                <td className='text-start ps-0 pe-10'>
                                  <div className='d-flex'>
                                    <div style={{width: '10px'}} className='pt-2'>
                                      <div className='bullet-item me-3 mb-0'></div>
                                    </div>
                                    <h3 className='text-dark fs-5 fnt-700 mb-1 ps-2'>
                                      {tb?.developmentName}
                                    </h3>
                                  </div>
                                </td>
                                <td className='text-start ps-0 pe-7'>
                                  <h3 className='text-dark fs-5 fnt-700 mb-1 ps-4'>
                                    {(tb?.furnishingTypes?.length
                                      ? tb?.furnishingTypes
                                      : tb?.furnishTypesOptions
                                    )
                                      ?.map((bd: any) => bd.label)
                                      .sort()
                                      .slice(0, 3)
                                      .join(', ')}
                                    {(tb?.furnishingTypes?.length ||
                                      tb?.furnishTypesOptions?.length) > 3 &&
                                      ((tb?.furnishingTypes?.length || 0) > 3 ||
                                        (tb?.furnishingTypes?.length === 0 &&
                                          tb?.furnishTypesOptions?.length > 3)) && (
                                        <>
                                          ,{' '}
                                          <span
                                            className='cursor-pointer'
                                            onClick={() => {
                                              setSelectedColumn('Furnishing Type')
                                              setSelectedColumnData(
                                                tb?.furnishingTypes?.length
                                                  ? tb?.furnishingTypes
                                                  : tb?.furnishTypesOptions
                                              )
                                              setShowMoreModal(true)
                                            }}
                                            style={{color: '#a4a4a4'}}
                                          >
                                            +
                                            {tb?.furnishingTypes?.length
                                              ? tb?.furnishingTypes?.length - 3
                                              : tb?.furnishTypesOptions?.length - 3}{' '}
                                          </span>
                                        </>
                                      )}
                                  </h3>
                                </td>
                                <td className='text-start ps-0 pe-7'>
                                  <h3 className='text-dark fs-5 fnt-700 mb-1 ps-4'>
                                    {(tb?.unitTypes?.length ? tb?.unitTypes : tb?.unitTypesOptions)
                                      ?.map((bd: any) => bd.label)
                                      .sort()
                                      .slice(0, 3)
                                      .join(', ')}
                                    {(tb?.unitTypes?.length || tb?.unitTypesOptions?.length) > 3 &&
                                      ((tb?.unitTypes?.length || 0) > 3 ||
                                        (tb?.unitTypes?.length === 0 &&
                                          tb?.unitTypesOptions?.length > 3)) && (
                                        <>
                                          ,{' '}
                                          <span
                                            className='cursor-pointer'
                                            onClick={() => {
                                              setSelectedColumn('Unit Types')
                                              setSelectedColumnData(
                                                tb?.unitTypes?.length
                                                  ? tb?.unitTypes
                                                  : tb?.unitTypesOptions
                                              )
                                              setShowMoreModal(true)
                                            }}
                                            style={{color: '#a4a4a4'}}
                                          >
                                            +
                                            {tb?.unitTypes?.length
                                              ? tb?.unitTypes?.length - 3
                                              : tb?.unitTypesOptions?.length - 3}{' '}
                                          </span>
                                        </>
                                      )}
                                  </h3>
                                </td>
                                <td className='text-start ps-0 pe-7'>
                                  <h3 className='text-dark fs-5 fnt-700 mb-1 ps-4'>
                                    {(tb?.bedrooms?.length ? tb?.bedrooms : tb?.bedroomsOptions)
                                      ?.map((bd: any) => bd.label)
                                      .sort()
                                      .slice(0, 3)
                                      .join(', ')}
                                    {(tb?.bedrooms?.length || tb?.bedroomsOptions?.length) > 3 &&
                                      ((tb?.bedrooms?.length || 0) > 3 ||
                                        (tb?.bedrooms?.length === 0 &&
                                          tb?.bedroomsOptions?.length > 3)) && (
                                        <>
                                          ,{' '}
                                          <span
                                            className='cursor-pointer'
                                            style={{color: '#a4a4a4'}}
                                            onClick={() => {
                                              setSelectedColumn('Bedrooms')
                                              setSelectedColumnData(
                                                tb?.bedrooms?.length
                                                  ? tb?.bedrooms
                                                  : tb?.bedroomsOptions
                                              )
                                              setShowMoreModal(true)
                                            }}
                                          >
                                            +
                                            {tb?.bedrooms?.length
                                              ? tb?.bedrooms?.length - 3
                                              : tb?.bedroomsOptions?.length - 3}{' '}
                                          </span>
                                        </>
                                      )}
                                  </h3>
                                </td>
                                <td className='text-start ps-0 pe-5'>
                                  <h3 className='text-dark fs-5 fnt-700 mb-1 ps-4 text-decoration-underline'>
                                    {tb?.selectedUnits?.length}
                                  </h3>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>

      {/* Show More Modal */}
      <ShowMore
        show={showShowMoreModal}
        onHide={() => {
          setShowMoreModal(false)
        }}
        headingText={selectedColumn}
        selectedData={selectedColumnData}
      />
    </>
  )
}

export default AssignedProperties