import {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useIntl} from 'react-intl'
import moment from 'moment'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import noData from '../../../../img/NoData1.svg'
import {ApiGet, ApiPost, ApiPut, Bucket} from '../../../../apiCommon/helpers/API/ApiData'
// import TenanciesFilter from './TenanciesFilter'
// import ReactPaginate from 'react-paginate'
// import NewMessage from './NewMessage'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
// import MoveInOutTable from '../Tenancies/Tables/MoveInOutTable'
// import TenatsFilter1 from '../Tenancies/TenanciesFilter1'
import leftArrow from '../../../../img/arrowleft.svg'
import zoom from '../../../../img/zoom.svg'
import {setComefrom, setRequestorId} from '../../../../redux/counterSlice'
import backArrow from '../../../../img/back-arrow.png'
import sync from '../../../../img/sync.png'
import redCross from '../../../../img/remove.png'
import terminate from '../../../../img/expired-tenc.png'

function ViewRequest() {
  const navigate = useNavigate()
  const {state} = useLocation()
  const [count, setCount] = useState<any>([])
  const [byiddata, setbyiddata] = useState<any>(false)

  const [propertiType, setPropertiType] = useState<any>('Overview')
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const [date, setDate] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<any>('')
  const [type, setType] = useState('')
  const [page, setPage] = useState<any>(1)
  const [tableData, setTableData] = useState([])
  const [newMessageModal, setNewMessageModal] = useState(false)
  const intl = useIntl()
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [propertiTypeTH, setPropertiTypeTH] = useState('Tenants')
  const [filter, setfilter] = useState<any>([])
  const [datefilter, setdatefilter] = useState<any>({})
  const [typetable, settypetable] = useState<any>()
  const [showTable, setShowTable] = useState<any>(1)
  const [page1, setPage1] = useState<any>(1)
  const [pageLimit1, setPageLimit1] = useState<any>(1)
  const [tabledata1, settabledata1] = useState<any>([])
  const [showCreateAppModal1, setShowCreateAppModal1] = useState<boolean>(false)

  const [moveinview, setmoveinview] = useState<any>(false)
  const [moveoutview, setmoveoutview] = useState<any>(false)
  const [genralview, setgenralview] = useState<any>(false)
  const [otherview, setotherview] = useState<any>(false)
  const [renewalview, setrenewalview] = useState<boolean>(false)
  const [terminationview, setterminationview] = useState<boolean>(false)
  const [show_status_btn, setShow_status_btn] = useState<boolean>(true)
  const [show_img, setShow_img] = useState<any>(false)
  const [formData, setFormData] = useState<any>({
    // propertyId: window.location.pathname?.split('/')[2],
  })
  const dispatch = useDispatch()

  const handlePageClick1 = (event: any) => {
    setPage1(event.selected + 1)
  }

  //////////////////
  const {unitId, requestId, reqType} = useParams()

  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)

  useEffect(() => {
    calldatabyid()
  }, [])

  const calldatabyid = async () => {
    await ApiGet(`corporate/tenant_request?tenantRequestId=${requestId}&type=${reqType}`)
      .then((res: any) => {
        setbyiddata(res?.data?.data[0])
        //  setUnitDataApi(res?.data?.data)
        dispatch(setRequestorId(requestId))
        dispatch(setComefrom('tenant'))

        if (reqType === '0') setPropertiTypeTH('MoveIn')
        else if (reqType === '1') setPropertiTypeTH('MoveOut')
        else if (reqType === '2') setPropertiTypeTH('TenancyRenewals')
        else if (reqType === '3') setPropertiTypeTH('TenancyTerminations')
        else if (reqType === '4') setPropertiTypeTH('GeneralComplaints')
        else setPropertiTypeTH('OtherRequests')

        if (
          reqType == '2' &&
          res?.data?.data?.[0]?.tenancyId != null &&
          res?.data?.data?.[0]?.clone_tenancy?.[0]?.isDraft == false
        ) {
          getTenancyData(res?.data?.data?.[0]?.tenancyId)
          getAllPayments(res?.data?.data?.[0]?.tenancyId)
        }

        if (reqType == '3') {
          let temp = localStorage.getItem('DiscardSession')
          // console.log(temp)
          if (temp != null) {
            let val = JSON.parse(temp)
            // console.log(val)
            // console.log(" discard session "+res?.data?.data?.[0]?._id)
            if (val?.requestId == res?.data?.data?.[0]?._id) {
              // discardSession()
              const body = {
                tenancyId: res?.data?.data?.[0]?.tenancyId,
              }

              ApiPost(`corporate/tenancy/apply/session?isCloneRecord=true`, body)
                .then(() => {
                  localStorage.removeItem('DiscardSession')
                })
                .catch((err: any) => {
                  ErrorToast(err?.message)
                })
            }
          }

          let temp2 = localStorage.getItem('DeleteClone')
          if (temp2 != null) {
            let val = JSON.parse(temp2)
            if (val?.requestId == res?.data?.data?.[0]?._id) {
              // deleteClone()
              const body = {
                tenancyId: res?.data?.data?.[0]?.tenancyId,
              }

              ApiPost(`corporate/tenancy/terminate/delete_clone`, body)
                .then(() => {
                  localStorage.removeItem('DeleteClone')
                })
                .catch((err: any) => {
                  ErrorToast(err?.message)
                })
            }
          }

          if (res?.data?.data?.[0]?.terminationOffer != undefined)
            getTerminationOffer(res?.data?.data?.[0]?.terminationOffer)
        }
      })
      .catch((e) => {
        console.error(e)
      })
  }

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
    setType(formData?.unitType)
  }
  const getCount = () => {
    ApiGet('corporate/tenant/count')
      .then((res: any) => {
        setCount(res?.data?.data)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }
  const tenantData = () => {
    let body = {
      page: page,
      limit: 10,
      searchTenant: formData?.searchTenant,
      searchContractNo: formData?.searchContractNo,
      tenancyStatus: Number(formData?.tenancyStatus),
      remainingDays: Number(formData?.remainingDays),
    }
    ApiPost('corporate/tenant/overview', body)
      .then((res: any) => {
        setTableData(res?.data?.data?.tenant_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err: any) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }
  useEffect(() => {
    getCount()
    tenantData()
  }, [
    page,
    formData?.searchTenant,
    formData?.searchContractNo,
    formData?.tenancyStatus,
    formData?.remainingDays,
  ])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const requeststatuschange = async (id: any, type: any, state: any) => {
    console.log(id, type)
    let body = {
      id: id,
      status: type,
    }
    ApiPut(`corporate/tenant_request/status`, body)
      .then((res: any) => {
        SuccessToast(res.data.message)
        if (state == 0) {
          setPropertiTypeTH('MoveIn')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 0)
          settypetable(0)
          setmoveinview(false)
        } else if (state == 1) {
          setPropertiTypeTH('MoveOut')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 1)
          settypetable(1)
          setmoveoutview(false)
        } else if (state == 2) {
          setPropertiTypeTH('TenancyRenewals')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 2)
          settypetable(2)
          setrenewalview(false)
        } else if (state == 3) {
          setPropertiTypeTH('TenancyTerminations')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 3)
          settypetable(3)
          setterminationview(false)
        } else if (state == 5) {
          setPropertiTypeTH('GeneralComplaints')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 4)
          settypetable(5)
          setgenralview(false)
        } else if (state == 6) {
          setPropertiTypeTH('OtherRequests')
          setfilter([])
          setdatefilter({})
          callmoveindata(1, null, null, 5)
          settypetable(6)
          setotherview(false)
        }
        navigate(-1)

        // getTenancyPayment()
        // arry = [];
        // setIsEditPaymentId('')
      })
      .catch((err: any) => ErrorToast(err.message))
  }

  const callmoveindata = (page: any, unitids: any, date: any, typetable: any) => {
    setShowTable(1)
    let body: any = {
      page: page,
      limit: 10,
      type: typetable,
      unitIds: [],
    }
    if (unitids?.length > 0) body.unitIds = unitids
    if (date?.startdate && date?.enddate) {
      body.startDate = date?.startdate
      body.endDate = date?.enddate
    }

    ApiPost('corporate/tenant_request/get', body)
      .then((res: any) => {
        console.log(res)
        settabledata1(res?.data?.data?.tenant_request_data)
        setPage1(res?.data?.data?.state?.page)
        // setPageLimit1(res?.data?.data?.state?.page_limit)

        // setPreviousTenancy(res?.data?.data)
      })
      .catch((err: any) => console.log('err', err))
  }

  const callextrapagere = () => {
    setShowCreateAppModal1(false)
    if (propertiTypeTH == 'MoveIn') {
      setfilter([])
      setdatefilter({})
      callmoveindata(1, null, null, 0)
      settypetable(0)
      setmoveinview(false)
    } else if (propertiTypeTH == 'MoveOut') {
      setfilter([])
      setdatefilter({})
      callmoveindata(1, null, null, 1)
      settypetable(1)
      setmoveoutview(false)
    }
    // setPropertiTypeTH('MoveIn')
  }

  const setradiobut = async (state: any) => {
    let body = {
      id: byiddata?._id,
      priority: state,
    }
    ApiPut(`corporate/tenant_request`, body)
      .then((res: any) => {
        SuccessToast(res.data.message)
        if (propertiTypeTH == 'GeneralComplaints') {
          //   calldatabyid(byiddata?._id, 4)
        } else if (propertiTypeTH == 'OtherRequests') {
          //   calldatabyid(byiddata?._id, 5)
        }
        // getTenancyPayment()
        // arry = [];
        // setIsEditPaymentId('')
      })
      .catch((err: any) => ErrorToast(err.message))
  }

  const handleClose = () => {
    setShowCreateAppModal(false)
    setShowModal('')
    setDate(false)
    console.log('\nclicked', 'clicked')
  }

  const handleData = (newData: any) => {
    console.log(newData, 'jayrak')
    // if(newData?.length>0){
    setfilter(newData)
    callmoveindata(page1, newData, datefilter, typetable)
    // }
  }
  const handleData1 = (newData: any) => {
    console.log(newData, 'sandip')
    // if(newData){
    setdatefilter(newData)
    callmoveindata(page1, filter, newData, typetable)
    // }

    // setdatefilter(newData)
  }

  const tenantNamesearch = (e: any, type: any) => {
    // console.log('\ntabledata1', tabledata1)

    let body: any = {
      page: 1,
      limit: 10,
      type: type,
      unitIds: [],
      searchTenantName: e.target.value,
    }
    // if (unitids?.length > 0) body.unitIds = unitids
    // if (date?.startdate && date?.enddate) {
    //   body.startDate = date?.startdate
    //   body.endDate = date?.enddate
    // }

    ApiPost('corporate/tenant_request/get', body)
      .then((res: any) => {
        console.log(res)
        settabledata1(res?.data?.data?.tenant_request_data)
        setPage1(res?.data?.data?.state?.page)
        // setPageLimit1(res?.data?.data?.state?.page_limit)

        // setPreviousTenancy(res?.data?.data)
      })
      .catch((err: any) => console.log('err', err))

    // if (data.length > 0) {
    //   settabledata1(
    //     tabledata1?.filter((flg: any) =>
    //       flg?.tenant?.filter((tan: any) =>
    //         tan.firstName?.toLowerCase().includes(data.toLowerCase())
    //       )
    //     )
    //   )
    // } else if (data.length == 0) {
    //   console.log('\na', '00000000000000000000')
    //   let body: any = {
    //     page: page,
    //     limit: 10,
    //     type: typetable,
    //     unitIds: [],
    //   }
    //   ApiPost('corporate/tenant_request/get', body)
    //     .then((res:any) => {
    //       settabledata1(res?.data?.data?.tenant_request_data)
    //     })
    //     .catch((err:any) => console.log('err', err))
    // }
  }

  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n)
    let newVal: any = temp.toLocaleTimeString()
    // console.log(newVal);

    let h1: any = newVal.slice(0, 2) * 60
    let newValHours = parseInt(h1)
    //  console.log( newValHours)

    let newValMins: any = parseInt(newVal.slice(3, 5))
    //  console.log(newValMins)

    var num: number = newValHours + newValMins
    // console.log(num)
    var hours = num / 60
    var rhours = Math.floor(hours)
    var fhour: any = '' + rhours
    var minutes = (hours - rhours) * 60
    var rminutes = Math.round(minutes)
    var fminutes: any = '' + rminutes
    if (rhours < 10) fhour = '0' + rhours
    if (rminutes < 10) fminutes = '0' + rminutes
    let x: any = fhour + ':' + fminutes
    return x
  }

  /***************************Tenancy Details *****************************/
  const [tenancyData, setTenancyData] = useState<any>({
    renewalDays: 90,
  })
  const [isOriginal, setIsOriginal] = useState<any>(false)
  const [incomePaymentPayload, setIncomePaymentPayload] = useState<any>([])
  const [newTenantId, setNewTenantID] = useState<any>('')
  const [tenancyPayment, setTenancyPayment] = useState<any>([])
  const [subTenantData, setSubTenantData] = useState<any>([])
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)
  const [totalDays, setTotalDays] = useState('0 Days')
  const [ttlDays, setTtlDays] = useState<any>('0')
  const [remainingDays, setRemainingDays] = useState('0 Days')
  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)
  const [tenantDocuments, setTenantDocuments] = useState<any>([])
  const [tenantDeletedDocuments, setTenantDeletedDocuments] = useState<any>([])
  const [contractDocuments, setContractDocuments] = useState<any>([])
  const [contractDeletedDocuments, setContractDeletedDocuments] = useState<any>([])
  const [optionArry, setOptionArry] = useState<any>({
    none: false,
    maintenanceFree: false,
    other: [],
    chillerFree: false,
  })
  const [otherOptions, setOtherOptions] = useState<any>([
    // {
    //   name: '',
    // },
  ])

  const getTenancyData = (id: any) => {
    ApiGet(`corporate/tenancy/full_detail/${id}?isCloneRecord=true`)
      .then((res) => {
        console.log('\nres.data.data', res.data.data)

        if (res?.data?.data?.[0]?.tenant?.length > 0) {
          setNewTenantID(res?.data?.data?.[0]?.tenant?.[0]?._id)
          setFormData(res?.data?.data?.[0]?.tenant?.[0])
        }

        setTenancyData(res?.data?.data[0])
        setTenancyPayment(res?.data?.data[0].tenancy_payment)

        // SubTenant Data
        let values: any = []
        for (let i = 0; i < res?.data?.data?.[0].sub_tenant?.length; i++) {
          values[i] = {
            id: res?.data?.data?.[0].sub_tenant?.[i]?._id,
            firstName: res?.data?.data?.[0].sub_tenant?.[i]?.firstName,
            lastName: res?.data?.data?.[0].sub_tenant?.[i]?.lastName,
            email: res?.data?.data?.[0].sub_tenant?.[i]?.email,
            countryCode: `${res?.data?.data?.[0].sub_tenant?.[i]?.countryCode}`,
            phoneNumber: `${res?.data?.data?.[0].sub_tenant?.[i]?.phoneNumber}`,
          }
        }
        setSubTenantData(values)

        if (res?.data?.data[0]?.duration?.start_date != null) {
          setStart(moment(res?.data?.data[0]?.duration?.start_date).format('DD/MM/YYYY'))
          setStartDatePayload(moment(res?.data?.data[0]?.duration?.start_date).format('YYYY-MM-DD'))
        }

        if (res?.data?.data[0]?.duration?.end_date != null) {
          if (state?.terminationDate) {
            setEnd(moment(new Date(state?.terminationDate)).format('DD/MM/YYYY'))
            setEndDatePayload(moment(new Date(state?.terminationDate)).format('YYYY-MM-DD'))
          } else {
            setEnd(moment(res?.data?.data[0]?.duration?.end_date).format('DD/MM/YYYY'))
            setEndDatePayload(moment(res?.data?.data[0]?.duration?.end_date).format('YYYY-MM-DD'))
          }
        }

        setTotalDays(res?.data?.data[0]?.duration?.days + ' days')
        setRemainingDays(res?.data?.data[0]?.remainingDays + ' days')
        setNewTenantID(res?.data?.data?.[0]?.tenantId)

        let final_obj: any = {
          none: false,
          maintenanceFree: false,
          chillerFree: false,
          other: [],
          ...res?.data?.data[0]?.benefits,
        }
        setOptionArry(final_obj)

        let temp: any = []
        for (let i = 0; i < res?.data?.data[0]?.benefits?.other?.length; i++) {
          temp[i] = {
            value: res?.data?.data[0]?.benefits?.other?.[i],
          }
        }
        setOtherOptions(temp)

        getTenantDocuments(
          res?.data?.data?.[0]?.tenant?.[0]?._id,
          res?.data?.data?.[0]?.tenantDocument,
          res?.data?.data?.[0]?.contractDocument
        )

        getContractDocuments(
          res?.data?.data?.[0]?.tenant?.[0]?._id,
          res?.data?.data?.[0]?.tenantDocument,
          res?.data?.data?.[0]?.contractDocument
        )
      })
      .catch((err) => console.log('err', err))
  }

  //
  const getTerminationOffer = (data: any) => {
    /********************** Tenancy Data */
    if (data?.tenancyData?.[0]?.tenant?.length > 0) {
      setNewTenantID(data?.tenancyData?.[0]?.tenant?.[0]?._id)
      setFormData(data?.tenancyData?.[0]?.tenant?.[0])
    }

    setTenancyData(data?.tenancyData?.[0])
    // SubTenant Data
    let values: any = []
    for (let i = 0; i < data?.tenancyData?.[0]?.sub_tenant?.length; i++) {
      values[i] = {
        id: data?.tenancyData?.[0]?.sub_tenant?.[i]?._id,
        firstName: data?.tenancyData?.[0]?.sub_tenant?.[i]?.firstName,
        lastName: data?.tenancyData?.[0]?.sub_tenant?.[i]?.lastName,
        email: data?.tenancyData?.[0]?.sub_tenant?.[i]?.email,
        countryCode: `${data?.tenancyData?.[0]?.sub_tenant?.[i]?.countryCode}`,
        phoneNumber: `${data?.tenancyData?.[0]?.sub_tenant?.[i]?.phoneNumber}`,
      }
    }
    setSubTenantData(values)

    if (data?.tenancyData?.[0]?.duration?.start_date != null) {
      setStart(moment(data?.tenancyData?.[0]?.duration?.start_date).format('DD/MM/YYYY'))
      setStartDatePayload(moment(data?.tenancyData?.[0]?.duration?.start_date).format('YYYY-MM-DD'))
    }

    if (data?.tenancyData?.[0]?.duration?.end_date != null) {
      if (state?.terminationDate) {
        setEnd(moment(new Date(state?.terminationDate)).format('DD/MM/YYYY'))
        setEndDatePayload(moment(new Date(state?.terminationDate)).format('YYYY-MM-DD'))
      } else {
        setEnd(moment(data?.tenancyData?.[0]?.duration?.end_date).format('DD/MM/YYYY'))
        setEndDatePayload(moment(data?.tenancyData?.[0]?.duration?.end_date).format('YYYY-MM-DD'))
      }
    }

    setTotalDays(data?.tenancyData?.[0]?.duration?.days + ' days')
    setRemainingDays(data?.tenancyData?.[0]?.remainingDays + ' days')
    setNewTenantID(data?.tenancyData?.[0]?.tenantId)

    let final_obj: any = {
      none: false,
      maintenanceFree: false,
      chillerFree: false,
      other: [],
      ...data?.tenancyData?.[0]?.benefits,
    }
    setOptionArry(final_obj)

    let temp: any = []
    for (let i = 0; i < data?.tenancyData?.[0]?.benefits?.other?.length; i++) {
      temp[i] = {
        value: data?.tenancyData?.[0]?.benefits?.other?.[i],
      }
    }
    setOtherOptions(temp)

    getTenantDocuments(
      data?.tenancyData?.[0]?.tenant?.[0]?._id,
      data?.tenancyData?.[0]?.tenantDocument,
      data?.tenancyData?.[0]?.contractDocument
    )

    getContractDocuments(
      data?.tenancyData?.[0]?.tenant?.[0]?._id,
      data?.tenancyData?.[0]?.tenantDocument,
      data?.tenancyData?.[0]?.contractDocument
    )

    // payments

    let values1: any = []

    let temp1: any = []

    for (let i = 0; i < data?.paymentData?.length; i++) {
      values1[i] = {
        payment: {
          _id: data?.paymentData?.[i]?._id,
          name: data?.paymentData?.[i]?.name,
          note: data?.paymentData?.[i]?.note,
          currency: 'AED',
          paymentStatus: data?.paymentData?.[i]?.paymentStatus,
          categories: data?.paymentData?.[i]?.categories,
          amountPaid: parseFloat(data?.paymentData?.[i]?.amountPaid),
          outstandingAmount: data?.paymentData?.[i]?.outstandingAmount,
          subTotalAmount: data?.paymentData?.[i]?.subTotalAmount,
          totalVAT: data?.paymentData?.[i]?.totalVAT,
          totalAmount: data?.paymentData?.[i]?.totalAmount,
          // paymentType: 0,
          paymentMethod: data?.paymentData?.[i]?.paymentMethod,
          paymentAccountId: data?.paymentData?.[i]?.paymentAccountId,
          chequeBankName: data?.paymentData?.[i]?.chequeBankName,
          isCardVerificationEnable: data?.paymentData?.[i]?.isCardVerificationEnable,
          dueDate: data?.paymentData?.[i]?.dueDate,
          datePaid: data?.paymentData?.[i]?.datePaid,
          createdAt: data?.paymentData?.[i]?.createdAt,
          attachments: data?.paymentData?.[i]?.attachments,
          chequeImages: data?.paymentData?.[i]?.chequeImages,
          tenancyId: data?.tenancyData?.[0]?._id,

          // unitId: unitDetails?._id,
          // clusterId: unitDetails?.clusterId,
          // unitGroupId: unitDetails?.unitGroupId,
          // communityId: unitDetails?.communityId,
          // floorId: unitDetails?.floorId,
          // buildingId: unitDetails?.buildingId,
        },
        // new_payment_sub_item: payment,
        // new_payment_invoice: payInv,
        // new_payment_receipt: prec,
        // new_payment_reminder: remindersSched,
      }
      for (let j = 0; j < data?.paymentData?.[i]?.payment_sub_item?.length; j++) {
        temp1[j] = {
          _id: data?.paymentData?.[i]?.payment_sub_item?.[j]?._id,
          name: data?.paymentData?.[i]?.payment_sub_item?.[j]?.name,
          amount: data?.paymentData?.[i]?.payment_sub_item?.[j]?.amount,
          quantity: data?.paymentData?.[i]?.payment_sub_item?.[j]?.quantity,
          isLateItem: data?.paymentData?.[i]?.payment_sub_item?.[j]?.isLatePaymentFeesRecord
            ? true
            : false,
        }
      }
      values1[i].new_payment_sub_item = temp1
    }

    setIncomePaymentPayload(values1)
  }

  const getTenantDocuments = async (id: any, dataTenant: any, dataContract: any) => {
    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        let tentFold: any = []
        let t = 0

        let deletedFolder: any = []
        let d: any = 0

        //  Documents Section
        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          let isInc: any = false
          let incTenant: any = false
          let ind: any = 0
          let indTen: any = 0
          let isDeleted = true

          for (let x = 0; x < dataTenant?.length; x++) {
            if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
              isDeleted = false
              x = dataTenant?.length
            }
          }

          if (!isDeleted) {
            //  Tenant Documents
            if (res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
              for (let x = 0; x < dataTenant?.length; x++) {
                if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
                  incTenant = true
                  ind = x
                  x = dataTenant?.length
                } else {
                  incTenant = false
                }
              }

              if (incTenant) {
                tentFold[t] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: true,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  let isAvailable: any = false
                  let isAvailableTenant: any = false

                  for (let x = 0; x < dataTenant[ind]?.fileList?.length; x++) {
                    if (
                      res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                      dataTenant[ind]?.fileList?.[x]?._id
                    ) {
                      // console.log(res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id + ' ' + dataTenant[ind]?.fileList?.[x]?._id)
                      isAvailableTenant = true
                      x = dataTenant[ind]?.fileList?.length
                    } else {
                      isAvailableTenant = false
                    }
                  }

                  // console.log(isAvailableTenant)

                  if (isAvailableTenant) {
                    tentFold[t].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: true,
                    }
                  } else {
                    tentFold[t].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                }
                t++
              } else {
                // console.log(res?.data?.data?.tenant_folder?.[i]?.name)
                tentFold[t] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: false,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  // tentFold[t].isCheck = false,
                  tentFold[t].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: false,
                  }
                }
                t++
              }
            }
          }
        }

        let temp: any = false
        let tempInd: any = 0
        for (let x = 0; x < dataTenant?.length; x++) {
          for (let y = 0; y < tentFold?.length; y++) {
            if (tentFold[y]._id == dataTenant[x]?._id) {
              console.log(tentFold[y]._id + ' ' + dataTenant[x]?._id)
              temp = true
              // tempInd = x
              y = tentFold?.length
            } else {
              temp = false
              tempInd = x
            }
          }
          if (temp == false) {
            if (dataTenant?.[tempInd]?.name != 'Contracts') {
              deletedFolder[d] = {
                _id: dataTenant?.[tempInd]?._id,
                name: dataTenant?.[tempInd]?.name,
                isSystemGenerated: dataTenant?.[tempInd]?.isSystemGenerated,
                isCheck: true,
                fileList: dataTenant?.[tempInd]?.fileList,
              }

              for (let j = 0; j < dataTenant?.[tempInd]?.fileList?.length; j++) {
                deletedFolder[d].fileList[j] = {
                  _id: dataTenant?.[tempInd]?.fileList?.[j]?._id,
                  fileName: dataTenant?.[tempInd]?.fileList?.[j]?.fileName,
                  fileURL: dataTenant?.[tempInd]?.fileList?.[j]?.fileURL,
                  isChecked: true,
                }
              }
            }
            d++
          }
        }

        console.log(' Tenant Deleted Folder')
        console.log(deletedFolder)

        console.log('Tenant Documents')
        console.log(tentFold)

        setTenantDocuments(tentFold)
        setTenantDeletedDocuments(deletedFolder)
      })
      .catch((err) => console.log('err', err))
  }

  const getContractDocuments = async (id: any, dataTenant: any, dataContract: any) => {
    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        let tentFold: any = []
        let t = 0

        let contFold: any = []
        let c = 0

        let deletedFolder: any = []
        let d: any = 0

        //  Documents Section
        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          let isInc: any = false
          let incTenant: any = false
          let ind: any = 0
          let indTen: any = 0
          let isDeleted = true

          // Contract/Tenancy Documents
          // console.log(dataContract)
          // console.log(contractDocuments)
          for (let x = 0; x < dataContract?.length; x++) {
            if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
              isDeleted = false
              x = dataTenant?.length
            }
          }

          if (!isDeleted) {
            if (
              res?.data?.data?.tenant_folder?.[i].name != 'Passports' &&
              res?.data?.data?.tenant_folder?.[i].name != 'Residencies' &&
              res?.data?.data?.tenant_folder?.[i].name != 'IDs'
            ) {
              for (let x = 0; x < dataContract?.length; x++) {
                if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
                  isInc = true
                  indTen = x
                  x = dataContract?.length
                } else {
                  isInc = false
                }
              }

              if (isInc) {
                contFold[c] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: true,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  let isAvailable: any = false

                  for (let x = 0; x < dataContract[indTen]?.fileList?.length; x++) {
                    if (
                      res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                      dataContract[indTen]?.fileList?.[x]?._id
                    ) {
                      isAvailable = true
                      x = dataContract[indTen]?.fileList?.length
                    } else {
                      isAvailable = false
                    }
                  }

                  if (isAvailable) {
                    contFold[c].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: true,
                    }
                  } else {
                    contFold[c].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                }
                c++
              } else {
                contFold[c] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: false,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  // tentFold[t].isCheck = false,
                  contFold[c].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: false,
                  }
                }
                c++
              }
            }
          }
        }

        let temp: any = false
        let tempInd: any = 0
        for (let x = 0; x < dataContract?.length; x++) {
          for (let y = 0; y < contFold?.length; y++) {
            if (contFold[y]._id == dataContract[x]?._id) {
              console.log(contFold[y]._id + ' ' + dataContract[x]?._id)
              temp = true
              // tempInd = x
              y = contFold?.length
            } else {
              temp = false
              tempInd = x
            }
          }
          if (temp == false) {
            if (
              dataContract?.[tempInd]?.name != 'Passports' &&
              dataContract?.[tempInd]?.name != 'Residencies' &&
              dataContract?.[tempInd]?.name != 'IDs'
            ) {
              deletedFolder[d] = {
                _id: dataContract?.[tempInd]?._id,
                name: dataContract?.[tempInd]?.name,
                isSystemGenerated: dataContract?.[tempInd]?.isSystemGenerated,
                isCheck: true,
                fileList: dataContract?.[tempInd]?.fileList,
              }

              for (let j = 0; j < dataContract?.[tempInd]?.fileList?.length; j++) {
                deletedFolder[d].fileList[j] = {
                  _id: dataContract?.[tempInd]?.fileList?.[j]?._id,
                  fileName: dataContract?.[tempInd]?.fileList?.[j]?.fileName,
                  fileURL: dataContract?.[tempInd]?.fileList?.[j]?.fileURL,
                  isChecked: true,
                }
              }
            }
            d++
          }
        }

        console.log('Contract  Deleted Folder')
        console.log(deletedFolder)

        console.log('Contract Documents')
        console.log(contFold)

        setContractDocuments(contFold)
        setContractDeletedDocuments(deletedFolder)
      })
      .catch((err) => console.log('err', err))
  }

  const getAllPayments = (id: any) => {
    let tenancyIDs: any = [id]

    const body = {
      limit: 50,
      page: 1,
      tenancyIds: tenancyIDs,
    }
    ApiPost(`corporate/payment/get?isCloneRecord=true`, body)
      .then((res) => {
        let values: any = []

        let temp: any = []

        for (let i = 0; i < res?.data?.data?.payment_data?.length; i++) {
          values[i] = {
            payment: {
              _id: res?.data?.data?.payment_data?.[i]?._id,
              name: res?.data?.data?.payment_data?.[i]?.name,
              note: res?.data?.data?.payment_data?.[i]?.note,
              currency: 'AED',
              paymentStatus: res?.data?.data?.payment_data?.[i]?.paymentStatus,
              categories: res?.data?.data?.payment_data?.[i]?.categories,
              amountPaid: parseFloat(res?.data?.data?.payment_data?.[i]?.amountPaid),
              outstandingAmount: res?.data?.data?.payment_data?.[i]?.outstandingAmount,
              subTotalAmount: res?.data?.data?.payment_data?.[i]?.subTotalAmount,
              totalVAT: res?.data?.data?.payment_data?.[i]?.totalVAT,
              totalAmount: res?.data?.data?.payment_data?.[i]?.totalAmount,
              // paymentType: 0,
              paymentMethod: res?.data?.data?.payment_data?.[i]?.paymentMethod,
              paymentAccountId: res?.data?.data?.payment_data?.[i]?.paymentAccountId,
              chequeBankName: res?.data?.data?.payment_data?.[i]?.chequeBankName,
              isCardVerificationEnable:
                res?.data?.data?.payment_data?.[i]?.isCardVerificationEnable,
              dueDate: res?.data?.data?.payment_data?.[i]?.dueDate,
              attachments: res?.data?.data?.payment_data?.[i]?.attachments,
              chequeImages: res?.data?.data?.payment_data?.[i]?.chequeImages,
              tenancyId: id,
              // unitId: unitDetails?._id,
              // clusterId: unitDetails?.clusterId,
              // unitGroupId: unitDetails?.unitGroupId,
              // communityId: unitDetails?.communityId,
              // floorId: unitDetails?.floorId,
              // buildingId: unitDetails?.buildingId,
            },
            // new_payment_sub_item: payment,
            // new_payment_invoice: payInv,
            // new_payment_receipt: prec,
            // new_payment_reminder: remindersSched,
          }
          for (let j = 0; j < res?.data?.data?.payment_data?.[i]?.payment_sub_item?.length; j++) {
            temp[j] = {
              _id: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?._id,
              name: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?.name,
              amount: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?.amount,
              quantity: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?.quantity,
              isLateItem: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]
                ?.isLatePaymentFeesRecord
                ? true
                : false,
            }
          }
          values[i].new_payment_sub_item = temp
        }

        setIncomePaymentPayload(values)
        setPageLimit(res?.data?.data?.state?.page_limit)
        // for (let i = 0; i < res?.data?.data?.payment_data.length; i++) {
        //   let values = [...show]
        //   values[i] = false
        //   setShow(values)
        // }
      })
      .catch((err) => console.log('err', err))
  }

  //
  const addTerminateTenancy = (uId: any, tenId: any, reqId: any, reqDate: any) => {
    const body = {
      tenancyId: tenId,
      tenantRequestId: reqId,
    }

    ApiPost(`corporate/tenancy/terminate`, body)
      .then((res) => {
        navigate(`/tenancy-termination/3/${byiddata?.unit?.[0]?._id}/${byiddata?.tenancyId}`, {
          state: {
            tenantReqId: byiddata?._id,
            terminationDate: byiddata?.terminationDate,
          },
        })
      })
      .catch(() => {})
  }

  return (
    <>
      <div className='d-flex align-items-center gap-2 gap-lg-3'>
        <div className='d-flex align-items-start gap-2 mb-0 gap-lg-3 ms-4 '>
          <div className='d-flex align-items-center gap-5 mt-2'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => {
                if (state?.tenancyId != undefined) navigate('/tenancies')
                else {
                  if (comefrom == 'properties') navigate('/dashboard')
                  else if (comefrom == 'build') navigate('/building-dashboard')
                  else navigate('/community-dashboard')
                }
              }}
            >
              <img src={backArrow} style={{stroke: 'red'}} height='19px' width='19px' />
            </span>
            <h1 className='page-heading m-0 head-text'>Property Profile</h1>
          </div>
          {/* Propert Profile Tabs */}
          <div
            className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center mx-5 ps-5'
            style={{marginBottom: '28px'}}
          >
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 me-5 px-5 e'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId,
                      },
                    })
                  }
                >
                  Overview
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5 '
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/tenancy`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId,
                      },
                    })
                  }
                >
                  Tenancy
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/tenant`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId,
                      },
                    })
                  }
                >
                  Tenants
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/financials/income`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId,
                      },
                    })
                  }
                >
                  Financials
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/annoucements`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId,
                      },
                    })
                  }
                >
                  Annoucements
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5 active'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/requests`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId,
                      },
                    })
                  }
                >
                  Requests
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {propertiTypeTH === 'MoveIn' && (
        <div className='grid-out p-5  px-2'>
          <div
            className='card tops p-5  mt-0'
            style={{justifyContent: 'space-between', flexDirection: 'row'}}
          >
            <div className='d-flex align-items-center gap-5'>
              <h3 className='m-0'>Move In Request</h3>
            </div>

            {byiddata?.status === 1 ||
            byiddata?.status === 2 ||
            byiddata?.status === 3 ||
            byiddata?.status === 4 ? (
              <></>
            ) : (
              <div className=''>
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                  onClick={() => {
                    requeststatuschange(byiddata?._id, 1, 0)
                    setShow_status_btn(false)
                  }}
                >
                  Approve
                </a>
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                  onClick={() => {
                    requeststatuschange(byiddata?._id, 3, 0)
                    setShow_status_btn(false)
                  }}
                >
                  Reject
                </a>
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                  onClick={() => {
                    requeststatuschange(byiddata?._id, 4, 0)
                    setShow_status_btn(false)
                  }}
                >
                  Cancel Request
                </a>
              </div>
            )}
          </div>
          <div className='card grid px-5 py-3 side'>
            <h1 className='top green_color'>Request Summary</h1>
            <h6 className='text-muted border-outline mx-0 my-2 '>
              <div className='d-flex align-items-center' style={{justifyContent: 'space-between'}}>
                Status
                <div
                  className={
                    byiddata?.status === 3 || byiddata?.status === 4 ? 'dot reds' : 'dot greens'
                  }
                ></div>
              </div>
              <div className='mx-1 value_black'>
                {byiddata?.status === 0
                  ? 'Received'
                  : byiddata?.status === 1
                  ? 'Approved'
                  : byiddata?.status === 2
                  ? 'Approved (Rescheduled)'
                  : byiddata?.status === 3
                  ? 'Rejected'
                  : byiddata?.status === 4
                  ? 'Cancelled'
                  : byiddata?.status === 5
                  ? 'Awaiting Vendor Confirmation'
                  : byiddata?.status === 6
                  ? 'Accepted'
                  : byiddata?.status === 7
                  ? 'Booked'
                  : byiddata?.status === 8
                  ? 'Variation Request'
                  : 'Canceled'}
              </div>
            </h6>
            <h6 className='text-muted border-outline mx-0 my-2'>
              Request ID
              <div className='mx-1 value_black'>{byiddata?.id}</div>
            </h6>
            <h6 className='text-muted border-outline mx-0 my-2'>
              Request Date
              <div className='mx-1 value_black'>
                {moment(byiddata?.createdAt).format('DD-MM-YYYY')}
              </div>
            </h6>
            <h6 className='text-muted border-outline mx-0 my-2'>
              Requestor
              <div className='mx-1 value_black'>
                {byiddata?.requestor?.length > 0
                  ? byiddata?.requestor[0]?.firstName + ' ' + byiddata?.requestor[0]?.lastName
                  : 'N/A'}
              </div>
            </h6>
          </div>
          <div className='card grid px-5 py-3 side'>
            <h1 className='top green_color'>Property Details</h1>
            <h6 className='text-muted border-outline mx-0 my-2'>
              Development
              {byiddata?.unit?.length > 0 ? (
                <div className='mx-1 value_black'>
                  {byiddata?.unit[0]?.community?.length > 0
                    ? byiddata?.unit[0]?.community[0]?.name
                    : byiddata?.unit[0]?.building[0]?.name}
                </div>
              ) : (
                'N/A'
              )}
            </h6>
            <h6 className='text-muted border-outline mx-0 my-2 '>
              Unit
              <div className='mx-1 value_black'>
                {byiddata?.unit?.length > 0 ? byiddata?.unit[0]?.unitNo : 'N/A'}
              </div>
            </h6>
            <h6 className='text-muted border-outline mx-0 my-2'>
              Current Contract ID
              <div className='mx-1 value_black'>
                {byiddata?.unit?.length > 0 ? byiddata?.unit[0]?.tenancy[0]?.contractNo : 'N/A'}
              </div>
            </h6>
          </div>
          <div className='card tops text-muted '>
            <div className='px-5 py-3 mt-0'>
              <h1 className='top green_color'>Request Details</h1>
              <h6 className='text-muted mx-0 my-2 align-items-center'>
                Move In Schedule
                <div className='d-flex align-items-center gap-5 '>
                  <div className='mx-1 value_black'>
                    {moment(byiddata?.scheduleDate).format('DD-MM-YYYY - HH:mm')}
                  </div>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green py-2'
                    onClick={() => setShowCreateAppModal1(true)}
                  >
                    Reschedule
                  </a>
                </div>
              </h6>
              <h6 className='text-muted border-outline mx-0 my-2'>
                Tenant ID
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0 ? byiddata?.tenant[0]?.id : 'N/A'}
                </div>
              </h6>
              <h6 className='text-muted border-outline mx-0 my-2'>
                Tenant Name
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0
                    ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
              <h6 className='text-muted border-outline mx-0 my-2'>
                Type
                <div className='mx-1 value_black'>{byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</div>
              </h6>
            </div>
            <div className='px-5 py-3 mt-0 mh-100'>
              <h6 className='text-muted border-outline mx-0 my-2 comment'>
                Tenant Comments
                <textarea
                  className='mx-1 p-3'
                  style={{height: '150px', borderRadius: '7px'}}
                  disabled
                  value={byiddata?.comment}
                ></textarea>
              </h6>
            </div>
          </div>
        </div>
      )}
      {propertiTypeTH === 'MoveOut' && (
        <>
          <div className='grid-out p-5  px-2'>
            <div
              className='card tops p-5 m-0'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            >
              <div className='d-flex align-items-center gap-5'>
                <h3 className='m-0'>Move Out Request</h3>
              </div>

              {byiddata?.status === 1 ||
              byiddata?.status === 2 ||
              byiddata?.status === 3 ||
              byiddata?.status === 4 ? (
                <></>
              ) : (
                <div className=''>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 1, 1)}
                  >
                    Approve
                  </a>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 3, 1)}
                  >
                    Reject
                  </a>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 4, 1)}
                  >
                    Cancel Request
                  </a>
                </div>
              )}
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Request Summary</h1>
              <h6 className='mx-0 text-muted'>
                <div
                  className='d-flex align-items-center'
                  style={{justifyContent: 'space-between'}}
                >
                  Status
                  <div
                    className={
                      byiddata?.status === 3 || byiddata?.status === 4 ? 'dot reds' : 'dot greens'
                    }
                  ></div>
                </div>
                <div className='mx-1 value_black'>
                  {byiddata?.status === 0
                    ? 'Received'
                    : byiddata?.status === 1
                    ? 'Approved'
                    : byiddata?.status === 2
                    ? 'Approved (Rescheduled)'
                    : byiddata?.status === 3
                    ? 'Rejected'
                    : byiddata?.status === 4
                    ? 'Cancelled'
                    : byiddata?.status === 5
                    ? 'Awaiting Vendor Confirmation'
                    : byiddata?.status === 6
                    ? 'Accepted'
                    : byiddata?.status === 7
                    ? 'Booked'
                    : byiddata?.status === 8
                    ? 'Variation Request'
                    : 'Canceled'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request ID
                <div className='mx-1 value_black'>{byiddata?.id}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request Date
                <div className='mx-1 value_black'>
                  {moment(byiddata?.createdAt).format('DD-MM-YYYY')}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Requestor
                <div className='mx-1 value_black'>
                  {byiddata?.requestor?.length > 0
                    ? byiddata?.requestor[0]?.firstName + ' ' + byiddata?.requestor[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Current Contract ID
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit[0]?.tenancy[0]?.contractNo : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Property Details</h1>
              <h6 className='mx-0 text-muted'>
                Tenant ID
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0 ? byiddata?.tenant[0]?.id : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Tenant Name
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0
                    ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Type
                <div className='mx-1 value_black'>{byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Development
                {byiddata?.unit?.length > 0 ? (
                  <div className='mx-1 value_black'>
                    {byiddata?.unit[0]?.community?.length > 0
                      ? byiddata?.unit[0]?.community[0]?.name
                      : byiddata?.unit[0]?.building[0]?.name}
                  </div>
                ) : (
                  'N/A'
                )}
              </h6>
              <h6 className='mx-0 text-muted'>
                Unit
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit[0]?.unitNo : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card tops text-muted '>
              <div className='px-5 py-3 mt-0'>
                <h1 className='top green_color'>Request Details</h1>
                <h6 className='text-muted mx-0 my-2 align-items-center'>
                  Move Out Schedule
                  <div className='d-flex align-items-center gap-5 '>
                    <div className='mx-1 value_black'>
                      {moment(byiddata?.scheduleDate).format('DD-MM-YYYY HH:mm')}
                    </div>
                    <div className='col-md-4'>
                      <a
                        className='btn btn-sm fw-bold btn-primary btn-green mx-10'
                        onClick={() => setShowCreateAppModal1(true)}
                      >
                        Reschedule
                      </a>
                    </div>
                  </div>
                </h6>
              </div>
              <div className='px-5 py-3 mt-0 mh-100'>
                <h6 className='text-muted border-outline mx-0 my-2 comment'>
                  Tenant Comments
                  <textarea
                    className='mx-1 p-3'
                    style={{height: '150px', borderRadius: '7px'}}
                    disabled
                    value={byiddata?.comment}
                  ></textarea>
                </h6>
              </div>
            </div>
          </div>
        </>
      )}
      {propertiTypeTH === 'TenancyRenewals' && (
        <>
          <div className='grid-out p-5  px-2'>
            <div
              className='card tops p-5 m-0'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            >
              <div className='d-flex align-items-center gap-5'>
                <h3 className='m-0'>Tenancy Renewal Request</h3>
              </div>

              {byiddata?.status === 4 ||
              byiddata?.status === 6 ||
              byiddata?.status === 5 ||
              byiddata?.status === 2 ? (
                <></>
              ) : (
                <div className='d-flex'>
                  {/* {byiddata?.status === 0 ? (
               <>
                 {byiddata?.previousTenancyId === null ? (
                   <a
                     className='btn btn-sm fw-bold renew-btn mx-3 pe-0 ps-2'
                     onClick={() => {
                       localStorage.setItem('page', 'tenants')
                       navigate(
                         `/tenancy/draft/4/${byiddata?.unit?.[0]?._id}/${byiddata?.tenancyId}`,
                         {
                           state: {
                             tenantReqId: byiddata?._id,
                           },
                         }
                       )
                       // navigate(
                       //   `/renew-tenancy/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]._id}`,
                       //   {
                       //     state: {
                       //       tenantReqId: byiddata?._id,
                       //     },
                       //   }
                       // )

                       // navigate(
                       //   `/create-tenant/2/${byiddata?.tenant[0]?._id}/${byiddata?.unit?.[0]?.tenancy?.[0]?._id}`
                       // )
                     }}
                   >
                     <img src={sync} height={20} width={20} className='cursor-pointer me-4' />
                     Renew Tenancy
                   </a>
                 ) : (
                   <>
                     {byiddata?.tenancy?.[0]?.isDraft === true && (
                       <a
                         className='btn btn-sm fw-bold renew-btn mx-3 pe-0 ps-2'
                         // style={{border:'2px solid #b89714', color: '#b89714'}}
                         // onClick={() => requeststatuschange(byiddata?._id, 1, 2)}
                         onClick={() => {
                           localStorage.setItem('page', 'tenants')
                           // navigate(
                           //   `/create-tenant/2/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]?._id}`
                           // )
                           navigate(
                             `/tenancy/draft/2/${byiddata?.unit?.[0]?._id}/${byiddata?.tenancy?.[0]?._id}`
                           )
                         }}
                       >
                         <img
                           src={sync}
                           height={20}
                           width={20}
                           className='cursor-pointer me-4'
                         />
                         Continue Renewal
                       </a>
                     )}
                   </>
                 )}
               </>
             ) :  */}
                  {byiddata?.status === 10 || byiddata?.status === 0 ? (
                    <a
                      className='btn btn-sm fw-bold renew-btn mx-3 pe-0 ps-2'
                      onClick={() => {
                        if (byiddata?.tenancyId != null) {
                          navigate(
                            `/renew-tenancy/2/${byiddata?.unit?.[0]?._id}/${byiddata?.tenancyId}`,
                            {
                              state: {
                                tenantReqId: byiddata?._id,
                              },
                            }
                          )
                        } else {
                          navigate(
                            `/renew-tenancy/4/${byiddata?.unit?.[0]?._id}/${byiddata?.previousTenancyId}`,
                            {
                              state: {
                                tenantReqId: byiddata?._id,
                              },
                            }
                          )
                        }

                        // navigate(
                        //   `/renew-tenancy/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]._id}`,
                        //   {
                        //     state: {
                        //       tenantReqId: byiddata?._id,
                        //     },
                        //   }
                        // )

                        // navigate(
                        //   `/create-tenant/2/${byiddata?.tenant[0]?._id}/${byiddata?.unit?.[0]?.tenancy?.[0]?._id}`
                        // )
                      }}
                    >
                      <img src={sync} height={20} width={20} className='cursor-pointer me-4' />
                      {byiddata?.tenancyId != null ? 'Continue Renewal' : 'Renew Tenancy'}
                    </a>
                  ) : (
                    <></>
                  )}

                  <a
                    className='btn btn-sm fw-bold  ms-3 red-hollow-btn req-red-btn ps-2'
                    onClick={() => requeststatuschange(byiddata?._id, 6, 2)}
                  >
                    <img src={redCross} height={18} width={18} className='me-4' /> Cancel Request
                  </a>
                </div>
              )}
            </div>
            <div className='card grid px-7 py-5 side'>
              <h1 className='top'>
                <b>Request Summary</b>
              </h1>

              <div className='mt-4 mb-2 '>
                <h4 className='my-1 d-flex' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Status{' '}
                  </label>
                  <div className='ms-5 ps-1 d-flex'>
                    <div
                      className={
                        byiddata?.status === 3 ||
                        byiddata?.status === 4 ||
                        byiddata?.status === 5 ||
                        byiddata?.status == 6
                          ? 'dot reds ps-1'
                          : 'dot greens ps-1'
                      }
                    ></div>
                    <span className='ms-5 ps-1'>
                      <b>
                        {byiddata?.status === 0
                          ? 'Requested'
                          : byiddata?.status === 1
                          ? 'Submitted - Pending Tenant Approval'
                          : byiddata?.status === 2
                          ? 'Tenant Approved'
                          : byiddata?.status === 4 || byiddata?.status == 6
                          ? 'Cancelled'
                          : byiddata?.status === 5
                          ? 'Tenant Rejected'
                          : byiddata?.status === 10
                          ? 'Draft'
                          : '-'}
                      </b>
                    </span>
                  </div>
                </h4>
              </div>

              {/* <div className='my-2 d-flex' style={{color: 'black'}}>
             <label style={{minWidth: '100px'}} className='labl-gry'>
               {' '}
               Status{' '}
             </label>
             <span
               className={
                 byiddata?.status === 3 ||
                 byiddata?.status === 4 ||
                 byiddata?.status === 5 ||
                 byiddata?.status == 6
                   ? 'dot reds ms-5 ps-1 mt-1'
                   : 'dot greens ms-5 ps-1 mt-1'
               }
             ></span>
             <span className='ms-5 ps-1'>
               <b>
                 {byiddata?.status === 0
                   ? 'Requested'
                   : byiddata?.status === 1
                   ? 'Submitted - Pending Tenant Approval'
                   : byiddata?.status === 2
                   ? 'Tenant Approved'
                   : byiddata?.status === 4 || byiddata?.status == 6
                   ? 'Cancelled'
                   : byiddata?.status === 5
                   ? 'Tenant Rejected'
                   : byiddata?.status === 10
                   ? 'Draft'
                   : '-'}
               </b>
             </span>
           </div> */}

              <div className='mb-3'>
                <label style={{minWidth: '100px'}} className='labl-gry'>
                  {' '}
                </label>
                <span className='ms-5 ps-1'>
                  <i>
                    {byiddata?.updatedBy?.[0] &&
                      `${byiddata?.updatedBy?.[0]?.firstName} ${byiddata?.updatedBy?.[0]?.lastName} - `}

                    {byiddata?.statusDateTime
                      ? `${moment(byiddata?.statusDateTime).format('DD.MM.YYYY - HH:mm A')}`
                      : '-'}
                  </i>
                </span>
              </div>

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Request ID
                  </label>
                  <span className='ms-5 ps-1'>
                    <b>{byiddata?.id}</b>
                  </span>
                </h4>
              </div>

              {/* <div className='my-2 mb-3' style={{color: 'black'}}>
             <label style={{minWidth: '100px'}} className='labl-gry'>
               {' '}
               Request ID
             </label>
             <span className='ms-5 ps-1'>
               <b>{byiddata?.id}</b>
             </span>
           </div> */}

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Request Date
                  </label>
                  <span className='ms-5 ps-1'>
                    <b> {moment(byiddata?.createdAt).format('DD.MM.YYYY')}</b>
                  </span>
                </h4>
              </div>

              {/* <div className='my-2 mb-3' style={{color: 'black'}}>
             <label style={{minWidth: '100px'}} className='labl-gry'>
               {' '}
               Request Date
             </label>
             <span className='ms-5 ps-1'>
               <b> {moment(byiddata?.createdAt).format('DD.MM.YYYY')}</b>
             </span>
           </div> */}

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Requestor
                  </label>
                  <span className='ms-5 ps-1'>
                    <b>
                      {' '}
                      {/* {Object?.keys(byiddata?.requestor)?.length != 0 */}
                      {byiddata?.requestor?.length != 0
                        ? byiddata?.requestor?.firstName + ' ' + byiddata?.requestor?.lastName
                        : 'N/A'}
                    </b>
                  </span>
                </h4>
              </div>

              {/* <div className='my-2 mb-3' style={{color: 'black'}}>
             <label style={{minWidth: '100px'}} className='labl-gry'>
               {' '}
               Requestor
             </label>
             <span className='ms-5 ps-1'>
               <b>
                 {' '}
                
                 {byiddata?.requestor?.length != 0
                   ? byiddata?.requestor?.firstName + ' ' + byiddata?.requestor?.lastName
                   : 'N/A'}
               </b>
             </span>
           </div> */}
              {byiddata?.requestorType == 0 && (
                <>
                  <div className='mt-4 mb-2'>
                    <h4 className='my-1' style={{color: 'black'}}>
                      <label style={{minWidth: '100px'}} className='labl-gry'>
                        {' '}
                        Term{' '}
                      </label>
                      <span className='ms-5 ps-2'>
                        <b>
                          {byiddata?.startDate
                            ? moment(byiddata?.startDate).format('DD.MM.YYYY')
                            : '-'}{' '}
                          {' - '}
                          {byiddata?.endDate ? moment(byiddata?.endDate).format('DD.MM.YYYY') : '-'}
                        </b>
                      </span>
                    </h4>
                  </div>

                  {/* <div className='my-2 mb-3' style={{color: 'black'}}>
                 <label style={{minWidth: '100px'}} className='labl-gry'>
                   {' '}
                   Start
                 </label>
                 <span className='ms-5 ps-1'>
                   <b>
                     {' '}
                     {byiddata?.startDate
                       ? moment(byiddata?.startDate).format('DD-MM-YYYY')
                       : '-'}
                   </b>
                 </span>
               </div> */}

                  {/* <div className='my-2 mb-3' style={{color: 'black'}}>
                 <label style={{minWidth: '100px'}} className='labl-gry'>
                   {' '}
                   End
                 </label>
                 <span className='ms-5 ps-1'>
                   <b>
                     {' '}
                     {byiddata?.endDate ? moment(byiddata?.endDate).format('DD-MM-YYYY') : '-'}
                   </b>
                 </span>
               </div> */}

                  <div className='mt-4 mb-2'>
                    <h4 className='my-1' style={{color: 'black'}}>
                      <label style={{minWidth: '100px'}} className='labl-gry'>
                        {' '}
                        Duration
                      </label>
                      <span className='ms-5 ps-1'>
                        <b>
                          {' '}
                          {(new Date(byiddata?.endDate).getTime() -
                            new Date(byiddata?.startDate).getTime()) /
                            (1000 * 3600 * 24)}
                        </b>
                      </span>
                    </h4>
                  </div>
                  {/* <div className='my-2 mb-3' style={{color: 'black'}}>
                 <label style={{minWidth: '100px'}} className='labl-gry'>
                   {' '}
                   Duration
                 </label>
                 <span className='ms-5 ps-1'>
                   <b>
                     {' '}
                     {(new Date(byiddata?.endDate).getTime() -
                       new Date(byiddata?.startDate).getTime()) /
                       (1000 * 3600 * 24)}
                   </b>
                 </span>
               </div> */}
                </>
              )}
            </div>
            <div className='card grid px-7 py-5 side'>
              <h1 className='top '>
                <b>Property Details</b>
              </h1>

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Tenant ID
                  </label>
                  <span className='ms-5 ps-1'>
                    <b> {byiddata?.tenant?.length > 0 ? byiddata?.tenant[0]?.id : 'N/A'}</b>
                  </span>
                </h4>
              </div>

              {/* <div className='my-2 mb-3' style={{color: 'black'}}>
             <label style={{minWidth: '100px'}} className='labl-gry'>
               {' '}
               Tenant ID
             </label>
             <span className='ms-5'>
               <b> {byiddata?.tenant?.length > 0 ? byiddata?.tenant[0]?.id : 'N/A'}</b>
             </span>
           </div> */}

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Tenant Name
                  </label>
                  <span className='ms-5 ps-1'>
                    <b>
                      {' '}
                      {byiddata?.tenant?.length > 0
                        ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                        : 'N/A'}
                    </b>
                  </span>
                </h4>
              </div>

              {/* <div className='my-2 mb-3' style={{color: 'black'}}>
             <label style={{minWidth: '100px'}} className='labl-gry'>
               {' '}
               Tenant Name
             </label>
             <span className='ms-5'>
               <b>
                 {' '}
                 {byiddata?.tenant?.length > 0
                   ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                   : 'N/A'}
               </b>
             </span>
           </div> */}

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Type
                  </label>
                  <span className='ms-5 ps-1'>
                    <b> {byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</b>
                  </span>
                </h4>
              </div>

              {/* <div className='my-2 mb-3' style={{color: 'black'}}>
             <label style={{minWidth: '100px'}} className='labl-gry'>
               {' '}
               Type
             </label>
             <span className='ms-5'>
               <b> {byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</b>
             </span>
           </div> */}

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Development
                  </label>
                  <span className='ms-5 ps-2'>
                    <b>
                      {byiddata?.unit?.length > 0 ? (
                        <>
                          {byiddata?.unit?.[0]?.community?.length > 0
                            ? byiddata?.unit?.[0]?.community[0]?.name
                            : byiddata?.unit?.[0]?.building[0]?.name}
                        </>
                      ) : (
                        'N/A'
                      )}
                    </b>
                  </span>
                </h4>
              </div>

              {/* <div className='my-2 mb-3' style={{color: 'black'}}>
             <label style={{minWidth: '100px'}} className='labl-gry'>
               {' '}
               Development
             </label>
             <span className='ms-5 ps-1'>
               <b>
                 {byiddata?.unit?.length > 0 ? (
                   <>
                     {byiddata?.unit?.[0]?.community?.length > 0
                       ? byiddata?.unit?.[0]?.community[0]?.name
                       : byiddata?.unit?.[0]?.building[0]?.name}
                   </>
                 ) : (
                   'N/A'
                 )}
               </b>
             </span>
           </div> */}

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Unit
                  </label>
                  <span className='ms-5 ps-2'>
                    <b>{byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.unitNo : 'N/A'}</b>
                  </span>
                </h4>
              </div>

              {/* <div className='my-2 mb-3' style={{color: 'black'}}>
             <label style={{minWidth: '100px'}} className='labl-gry'>
               {' '}
               Unit
             </label>
             <span className='ms-5 ps-1'>
               <b>{byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.unitNo : 'N/A'}</b>
             </span>
           </div> */}

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Previous Contract No.
                  </label>
                  <span className='ms-5 ps-1'>
                    <b>
                      {' '}
                      {byiddata?.unit?.length > 0
                        ? byiddata?.previous_tenancy?.[0]?.contractNo
                        : 'N/A'}
                    </b>
                  </span>
                </h4>
              </div>

              {/* <div className='my-2 mb-3' style={{color: 'black'}}>
             <label style={{width: '130px'}} className='labl-gry'>
               {' '}
               Previous Contract No.
             </label>
             <span className='ms-5 ps-1'>
               <b>
                 {' '}
                 {byiddata?.unit?.length > 0
                   ? byiddata?.previous_tenancy?.[0]?.contractNo
                   : 'N/A'}
               </b>
             </span>
           </div> */}
            </div>

            {/* <div className='card tops text-muted '>
         <div className='px-5 py-3 mt-0'>
           <h1 className='top green_color'>Renewal Details</h1>

           <h6 className='mx-0 text-muted'>
             Start
             <div className='mx-1 value_black'>
               {moment(byiddata?.startDate).format('DD-MM-YYYY')}
             </div>
           </h6>
           <h6 className='mx-0 text-muted'>
             End
             <div className='mx-1 value_black'>
               {moment(byiddata?.endDate).format('DD-MM-YYYY')}
             </div>
           </h6>
           <h6 className='mx-0 text-muted'>
             Duration
             <div className='mx-1 value_black'>
               {byiddata?.tenancy?.length > 0
                 ? `${byiddata?.tenancy?.[0]?.duration?.days} Days`
                 : '-'}{' '}
             </div>
           </h6>
           <h6 className='mx-0 text-muted'>
             Remaining
             <div className='mx-1 value_black'>
               {byiddata?.unit?.length > 0
                 ? byiddata?.unit?.[0]?.tenancy?.[0]?.remainingDays
                 : 'N/A'}{' '}
               Days
             </div>
           </h6>
         </div>
         <div className='px-5 py-3 mt-0'></div>
       </div> */}
          </div>
          {byiddata?.clone_tenancy?.length > 0 && byiddata?.clone_tenancy?.[0]?.isDraft == false && (
            <div className='p-5  px-2 pt-3'>
              <div
                className='card card-flush py-5 px-0  m-0'
                // style={{boxShadow: 'lightgrey 0px 1px 4px 4px'}}
              >
                <div className='row mx-1'>
                  <div className='col-12 px-5'>
                    <div className='d-flex'>
                      <h1 className='top ps-2'>
                        <b>Renewal Offer</b>
                      </h1>
                      <div className=' d-flex ms-auto'>
                        {byiddata?.status === 2 && (
                          <a
                            className='btn btn-sm fw-bold view-btn me-3 '
                            onClick={() => {
                              navigate(
                                `/tenancy-view/2/${byiddata?.unit?.[0]?._id}/${byiddata?.tenancyId}`
                              )
                            }}
                          >
                            View Tenancy
                          </a>
                        )}
                        {/* <div className='me-5'>
                         <div
                           className={
                             flg == '4'
                               ? 'status  no-st-bar ms-5'
                               : tenancyData?.tenancyStatus == 0
                               ? 'status booked-bar ms-5'
                               : tenancyData?.tenancyStatus == 1
                               ? 'status active-bar ms-5'
                               : tenancyData?.tenancyStatus == 2
                               ? 'status expiring-bar ms-5'
                               : tenancyData?.tenancyStatus == 3
                               ? 'status expired-bar ms-5'
                               : 'status  no-st-bar ms-5'
                           }
                         >
                           {flg == '4'
                             ? '-'
                             : tenancyData?.tenancyStatus == 0
                             ? 'Booked'
                             : tenancyData?.tenancyStatus == 1
                             ? 'Active'
                             : tenancyData?.tenancyStatus == 2
                             ? 'Expiring'
                             : tenancyData?.tenancyStatus == 3
                             ? 'Expired'
                             : '-'}
                         </div>
                         <i>
                           {' '}
                           {tenancyData?.tenancyStatusDateTime
                             ? `${moment(tenancyData?.tenancyStatusDateTime).format(
                                 'DD.MM.YYYY - HH:mm A'
                               )} `
                             : ''}
                         </i>
                       </div> */}
                        {/* <div>
                   {tenancyData?.isDraft ? (
                     <>
                       <div
                         className={
                           tenancyData?.isDraft
                             ? 'status draft-st ms-5'
                             : 'status create-st ms-5'
                         }
                       >
                         {tenancyData?.isDraft ? 'Draft' : 'Created'}
                       </div>
                       <i>
                         {' '}
                         {tenancyData?.draftStatusDateTime
                           ? `${moment(tenancyData?.draftStatusDateTime).format(
                               'DD.MM.YYYY - HH:mm A'
                             )} `
                           : ''}
                       </i>
                     </>
                   ) : (
                     <div className='status not-saved'>-</div>
                   )}
                 </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Contract Details */}
                <>
                  <div className='row mx-4 mt-5 '>
                    <div className='col-12 px-4 '>
                      <h2 className='top ps-0'>
                        <b>Contract Details</b>
                      </h2>
                    </div>

                    <div className='col-12 px-4 mt-4 mb-3'>
                      <h4 className='my-1' style={{color: 'black'}}>
                        <label style={{minWidth: '100px'}} className='labl-gry'>
                          {' '}
                          Contract No.{' '}
                        </label>
                        <span className='ms-5 ps-1'>
                          <b>{tenancyData?.contractNo ? tenancyData?.contractNo : ''}</b>
                        </span>
                      </h4>
                    </div>

                    <div className='col-12 px-4 mt-4 mb-3'>
                      <h4 className='my-1' style={{color: 'black'}}>
                        <label style={{minWidth: '100px'}} className='labl-gry'>
                          {' '}
                          Term{' '}
                        </label>
                        <span className='ms-5 ps-0'>
                          <b>
                            {start_date != null || end_date != null ? (
                              <>
                                {' '}
                                {start_date != null || end_date != null
                                  ? `${start_date} - ${end_date} `
                                  : '-'}
                              </>
                            ) : (
                              ''
                            )}
                          </b>
                        </span>
                      </h4>
                    </div>

                    <div className='col-12 px-4 mt-3 mb-3'>
                      <h4 className='my-1' style={{color: 'black'}}>
                        <label style={{minWidth: '100px'}} className='labl-gry'>
                          {' '}
                          Duration
                        </label>
                        <span className='ms-5 ps-1'>
                          <b>{totalDays}</b>
                        </span>
                      </h4>
                    </div>

                    <div className='col-12 px-4 my-3 mb-3'>
                      <h4 className='my-1' style={{color: 'black'}}>
                        <label style={{minWidth: '100px'}} className='labl-gry'>
                          {' '}
                          Remaining
                        </label>
                        <span className='ms-5 ps-1'>
                          <b>{remainingDays}</b>
                        </span>
                      </h4>
                    </div>

                    <div className='col-12 px-4 my-3 mb-3'>
                      <div className='d-flex align-items-center'>
                        <h4 className='my-1' style={{color: 'black'}}>
                          <label style={{minWidth: '100px'}} className='labl-gry'>
                            {' '}
                            Renewal{' '}
                          </label>
                        </h4>
                        <span className='ms-5 ps-1'>
                          <b>{tenancyData?.renewalDays ? tenancyData?.renewalDays : '-'}</b>
                        </span>

                        <span>
                          {' '}
                          <i className='mt-2 ms-2'> Days before term end</i>
                        </span>
                      </div>
                    </div>

                    <div className='col-12 ps-4 my-3 mb-3'>
                      <div className='d-flex'>
                        <div className='d-flex'>
                          <h4 className='my-1' style={{color: 'black'}}>
                            <label style={{minWidth: '100px'}} className='labl-gry'>
                              {' '}
                              Benefits{' '}
                            </label>
                          </h4>
                          <div className='test ms-5 ps-1'>
                            {optionArry?.none && (
                              <div className='d-flex align-items-center'>
                                <p className='bullet-ic mb-0'></p>
                                <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                  {' '}
                                  None
                                </p>
                              </div>
                            )}

                            {optionArry?.chillerFree === true && (
                              <div className='d-flex align-items-center'>
                                <p className='bullet-ic mb-0'></p>
                                <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                  {' '}
                                  Chiller Free
                                </p>
                              </div>
                            )}

                            {optionArry?.maintenanceFree === true && (
                              <div className='d-flex align-items-center'>
                                <p className='bullet-ic mb-0'></p>
                                <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                  {' '}
                                  Maintenance Free
                                </p>
                              </div>
                            )}
                            {otherOptions?.length > 0 &&
                              otherOptions?.map((opt: any, ind: any) => (
                                <div className='d-flex align-items-center'>
                                  <p className='bullet-ic mb-0'></p>
                                  <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                    {' '}
                                    {opt?.value}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row mx-1 mt-5'>
                      <div className='col-12 ps-0 mt-5'>
                        <div className='d-flex  align-items-center'>
                          <h3 className='ms-0' style={{fontWeight: '700'}}>
                            Documents
                          </h3>
                        </div>
                      </div>

                      {contractDocuments?.map((doc: any, index: any) => {
                        return (
                          <>
                            {doc.isCheck && (
                              <>
                                <div className='col-12 ps-0 pe-3 mt-5 pt-2'>
                                  <div className='d-flex  align-items-center'>
                                    <h4 className='' style={{fontWeight: '700'}}>
                                      {doc?.name}
                                    </h4>
                                  </div>
                                </div>
                                <div className='col-12 px-2 mt-4'>
                                  {doc?.fileList?.length > 0 ? (
                                    <div
                                      className='tops d-flex gap-5 align-items-start mx-5'
                                      style={{overflow: 'scroll', overflowY: 'hidden'}}
                                    >
                                      {doc?.fileList?.map((file: any, ind: any) => {
                                        return (
                                          <>
                                            {file.isChecked && (
                                              <div className='imgs-set'>
                                                <div className='d-flex mw-350px me-3'>
                                                  <img
                                                    src={Bucket + file?.fileURL}
                                                    width='100'
                                                    height='100'
                                                    className='main_img mb-5'
                                                    onClick={() => {}}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )
                                      })}
                                    </div>
                                  ) : (
                                    <div className='d-flex justify-content-center'>
                                      {' '}
                                      <div className=''>
                                        {' '}
                                        {/* <h2 className='head-text'>No Files...</h2> */}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )
                      })}
                      {contractDeletedDocuments?.map((doc: any, index: any) => {
                        return (
                          <>
                            {doc.isCheck && (
                              <>
                                <div className='col-7 ps-0 pe-1 mt-4'>
                                  <div className='d-flex  align-items-center'>
                                    <h4 className='' style={{fontWeight: '700'}}>
                                      {doc?.name}
                                    </h4>
                                  </div>
                                </div>
                                <div className='col-5 mt-4 ps-1'></div>
                                <div className='col-12 px-0 mt-5 pt-4'>
                                  {doc?.fileList?.length > 0 ? (
                                    <div
                                      className='tops d-flex gap-5 align-items-start mx-5'
                                      style={{overflow: 'scroll', overflowY: 'hidden'}}
                                    >
                                      {doc?.fileList?.map((file: any, ind: any) => {
                                        return (
                                          <>
                                            {file.isChecked && (
                                              <div className='imgs-set'>
                                                <div className='d-flex mw-350px me-3'>
                                                  <img
                                                    src={Bucket + file?.fileURL}
                                                    width='100'
                                                    height='100'
                                                    className='main_img mb-5'
                                                    onClick={() => {}}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )
                                      })}
                                    </div>
                                  ) : (
                                    <div className='d-flex justify-content-center'>
                                      {' '}
                                      <div className=''>
                                        {' '}
                                        {/* <h2 className='head-text'>No Files...</h2> */}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )
                      })}
                    </div>
                  </div>
                </>

                {/* Main Tenant Details */}
                <>
                  <div className='row mx-1 mt-5 pt-3'>
                    <div className='col-7 ps-5'>
                      <h2 className='top ps-2'>
                        <b>Main Tenant Details</b>
                      </h2>
                    </div>

                    <div className='row mx-0 mt-3'>
                      <div className='col-12 px-4'>
                        <div className='d-flex'>
                          <h4 className='my-3 mb-3' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry'>
                              {' '}
                              First Name{' '}
                            </label>
                            <span className='ms-5 ps-1'>
                              <b>{formData?.firstName ? formData?.firstName : ''}</b>
                            </span>
                          </h4>
                        </div>
                      </div>
                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            {' '}
                            Last Name{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>{formData?.lastName ? formData?.lastName : ''}</b>
                          </span>
                        </h4>
                      </div>
                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            {' '}
                            Passport No.{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>{formData?.passportNo ? formData?.passportNo : '-'}</b>
                          </span>
                        </h4>
                      </div>

                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            {' '}
                            ID No.{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>{formData?.idNo ? formData?.idNo : '-'}</b>
                          </span>
                        </h4>
                      </div>

                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            {' '}
                            DOB{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>
                              {formData?.DOB
                                ? moment(formData?.DOB && formData?.DOB.split('T')[0]).format(
                                    'DD/MM/YYYY'
                                  )
                                : ''}
                            </b>
                          </span>
                        </h4>
                      </div>

                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            Nationality{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>{formData?.nationality ? formData?.nationality : ''}</b>
                          </span>
                        </h4>
                      </div>

                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            Email{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>{formData?.email ? formData?.email : ''}</b>
                          </span>
                        </h4>
                      </div>

                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            Mobile No.{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>
                              {formData?.phoneNumber
                                ? `${formData?.countryCode} ${formData?.phoneNumber}`
                                : ''}
                            </b>
                          </span>
                        </h4>
                      </div>
                    </div>

                    <div className='row mx-1 mt-5'>
                      <div className='col-7 mt-5'>
                        <div className='d-flex  align-items-center'>
                          <h3 className='ms-0' style={{fontWeight: '700'}}>
                            Documents
                          </h3>
                        </div>
                      </div>

                      {tenantDocuments?.map((doc: any, index: any) => {
                        return (
                          <>
                            {doc.isCheck && (
                              <>
                                <div className='col-7 ps-3 pe-3 mt-5 pt-3'>
                                  <div className='d-flex  align-items-center'>
                                    <h4 className='' style={{fontWeight: '700'}}>
                                      {doc?.name}
                                    </h4>
                                  </div>
                                </div>
                                <div className='col-12 px-4 mt-5'>
                                  {doc?.fileList?.length > 0 ? (
                                    <div
                                      className='tops d-flex gap-5 align-items-start mx-5'
                                      style={{overflow: 'scroll', overflowY: 'hidden'}}
                                    >
                                      {doc?.fileList?.map((file: any, ind: any) => {
                                        return (
                                          <>
                                            {file.isChecked && (
                                              <div className='imgs-set'>
                                                <div className='d-flex mw-350px me-3'>
                                                  <img
                                                    src={Bucket + file?.fileURL}
                                                    width='100'
                                                    height='100'
                                                    className='main_img mb-5'
                                                    onClick={() => {}}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )
                                      })}
                                    </div>
                                  ) : (
                                    <div className='d-flex justify-content-center'>
                                      {' '}
                                      <div className=''>
                                        {' '}
                                        {/* <h2 className='head-text'>No Files...</h2> */}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )
                      })}

                      {tenantDeletedDocuments?.map((doc: any, index: any) => {
                        return (
                          <>
                            {doc.isCheck && (
                              <>
                                <div className='col-12 ps-4 pe-3 mt-4'>
                                  <div className='d-flex  align-items-center'>
                                    <h4 className='' style={{fontWeight: '700'}}>
                                      {doc?.name}
                                    </h4>
                                  </div>
                                </div>

                                <div className='col-12 px-4 mt-4'>
                                  {doc?.fileList?.length > 0 ? (
                                    <div
                                      className='tops d-flex gap-5 align-items-start mx-5'
                                      style={{overflow: 'scroll', overflowY: 'hidden'}}
                                    >
                                      {doc?.fileList?.map((file: any, ind: any) => {
                                        return (
                                          <>
                                            {file.isChecked && (
                                              <div className='imgs-set'>
                                                <div className='d-flex mw-350px me-3'>
                                                  <img
                                                    src={Bucket + file?.fileURL}
                                                    width='100'
                                                    height='100'
                                                    className='main_img mb-5'
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )
                                      })}
                                    </div>
                                  ) : (
                                    <div className='d-flex justify-content-center'>
                                      {' '}
                                      <div className=''>
                                        {' '}
                                        {/* <h2 className='head-text'>No Files...</h2> */}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )
                      })}
                    </div>

                    {/* Sub Tenants */}
                    {subTenantData?.length > 0 && (
                      <div className='row mx-0 mt-5 pt-4'>
                        <div className='col-12 ps-4 pe-0 mt-3'>
                          <div className='d-flex  align-items-center'>
                            <h2 className='ms-0' style={{fontWeight: '700'}}>
                              Sub Tenants
                            </h2>
                          </div>
                        </div>
                      </div>
                    )}

                    {subTenantData?.length > 0 && (
                      <div className='row mx-0'>
                        <div className='col-12 ps-5 pe-0 '>
                          <div className='table-responsive '>
                            <table
                              className='table align-middle  fs-6 gy-5'
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                  <th className='text-start min-w-100px'>First Name</th>
                                  <th className='text-start min-w-100px'>Last Name</th>
                                  <th className='text-start min-w-100px'>Passport No.</th>
                                  <th className='text-start min-w-100px'>ID No.</th>
                                  <th className='text-start min-w-150px'>Email </th>
                                  <th className='text-start min-w-100px'>Mobile No.</th>
                                </tr>
                              </thead>

                              <tbody className='fw-semibold text-gray-600'>
                                {subTenantData?.length ? (
                                  subTenantData.map((sb: any, i: any) => {
                                    return (
                                      <>
                                        {sb?.id != '' && (
                                          <tr className='mt-2'>
                                            <td className='text-start min-w-100px'>
                                              {`${sb?.firstName}` ? `${sb?.firstName}` : '-'}
                                            </td>
                                            <td className='text-start min-w-100px'>
                                              {`${sb?.lastName}` ? `${sb?.lastName}` : '-'}
                                            </td>
                                            <td className='text-start min-w-100px'>
                                              {`${
                                                sb?.passportNo != undefined &&
                                                sb?.passportNo != null
                                              }`
                                                ? `${sb?.passportNo}`
                                                : '-'}
                                            </td>
                                            <td className='text-start min-w-100px'>
                                              {`${sb?.idNo != undefined && sb?.idNo != null}`
                                                ? `${sb?.idNo}`
                                                : '-'}
                                            </td>
                                            <td className='text-start min-w-150px'>
                                              {`${sb?.email}` ? `${sb?.email}` : '-'}{' '}
                                            </td>
                                            <td className='text-start min-w-100px'>
                                              {`${sb?.phoneNumber}}`
                                                ? `${sb?.countryCode} ${sb?.phoneNumber}`
                                                : '-'}
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    )
                                  })
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>

                {/* Financials Details */}

                <>
                  <div className='row mx-3 mt-5'>
                    <div className='col-7 px-5 mt-5 pt-5'>
                      <h2 className='top'>
                        <b>Financials</b>
                      </h2>
                    </div>
                  </div>
                  {incomePaymentPayload?.length > 0 && (
                    <div className='row mx-4 mt-0'>
                      <div className='col-12 px-3'>
                        {incomePaymentPayload?.length > 0 ? (
                          <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0 test'>
                            <table
                              className='table align-start table-row-dashed fs-6 gy-5 '
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                  <th className='min-w-100px'>Payment</th>
                                  <th className='min-w-100px'>Amount</th>
                                  <th className='min-w-100px'>Status</th>
                                  <th className='min-w-100px'>Due</th>
                                  <th className='min-w-100px'>Created At</th>
                                  <th className='min-w-150px'>Amount Paid</th>
                                  <th className='min-w-150px'>Date Paid</th>
                                  <th className='min-w-100px'></th>
                                </tr>
                              </thead>
                              <tbody className='fw-semibold text-gray-600'>
                                {incomePaymentPayload?.map((pm: any, ind: any) => {
                                  return (
                                    <>
                                      <tr
                                      // style={
                                      //   show[ind] == true
                                      //     ? {borderBottomStyle: 'none', height: '30px'}
                                      //     : show[ind] == false
                                      //     ? {borderBottomStyle: 'dashed', height: '30px'}
                                      //     : pm?.payment_sub_item?.length == 0
                                      //     ? {borderBottomStyle: 'dashed', height: '30px'}
                                      //     : {height: '30px'}
                                      // }
                                      >
                                        <td>
                                          <div className='d-flex align-items-center'>
                                            {pm?.payment?.name}
                                          </div>
                                        </td>

                                        <td>
                                          {parseFloat(pm?.payment?.totalAmount)?.toLocaleString(
                                            'en-US',
                                            {
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                        </td>

                                        <td>
                                          {pm?.payment?.paymentStatus === 0
                                            ? 'Unpaid'
                                            : pm?.payment?.paymentStatus === 1
                                            ? 'Paid'
                                            : pm?.payment?.paymentStatus === 2
                                            ? 'Partially paid'
                                            : pm?.payment?.paymentStatus === 3
                                            ? 'Void'
                                            : 'Draft'}
                                        </td>

                                        <td>
                                          {pm?.payment?.dueDate
                                            ? moment(pm?.payment?.dueDate).format(
                                                'DD.MM.YYYY - hh:mm A'
                                              )
                                            : '-'}
                                        </td>
                                        <td>
                                          {pm?.payment?.dueDate
                                            ? moment(pm?.payment?.createdAt).format(
                                                'DD.MM.YYYY - hh:mm A'
                                              )
                                            : '-'}
                                        </td>
                                        <td>
                                          {pm?.payment?.amountPaid?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })}
                                        </td>
                                        <td>
                                          {pm?.payment?.new_payment_receipt?.[0]?.datePaid
                                            ? moment(
                                                pm?.payment?.new_payment_receipt?.[0]?.datePaid
                                              ).format('DD.MM.YYYY - hh:mm A')
                                            : '-'}
                                        </td>
                                        <td>
                                          {/* <BsArrowRightSquareFill
                                           color='#35475E '
                                           fontSize={18}
                                           style={{cursor: 'pointer'}}
                                           className='mx-3 me-4'
                                           onClick={() => {
                                             let values = {
                                               firstName: formData?.firstName,
                                               lastName: formData?.lastName,
                                               email: formData?.email,
                                               countryCode: formData?.countryCode,
                                               phoneNumber: formData?.phoneNumber,
                                             }
                                             setTenantDetails(values)

                                             console.log(pm?.payment?._id)
                                             if (pm?.payment?._id) {
                                               ApiGet(`corporate/payment/${pm?.payment?._id}`)
                                                 .then(async (res) => {
                                                   setDataToEdit(res?.data?.data)
                                                   setIndexToEdit(ind)
                                                   setEditIncomeModalDraft(true)
                                                 })
                                                 .catch((err) => console.log('err', err))
                                             } else {
                                               console.log(pm)
                                               setDataToEdit(pm)
                                               setIndexToEdit(ind)
                                               setEditIncomeModalDraft(true)
                                             }
                                           }}
                                         /> */}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                            <table
                              className='table align-start table-row-dashed fs-6 gy-5'
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                  <th className='min-w-100px'>Payment</th>
                                  <th className='min-w-100px'>Amount</th>
                                  <th className='min-w-100px'>Status</th>
                                  <th className='min-w-100px'>Due</th>
                                  <th className='min-w-100px'>Created At</th>
                                  <th className='min-w-150px'>Amount Paid</th>
                                  <th className='min-w-150px'>Date Paid</th>
                                  <th className='min-w-100px'></th>
                                </tr>
                              </thead>
                              <tbody className='fw-semibold text-gray-600'>
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={350} />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          )}
        </>
      )}

      {propertiTypeTH === 'TenancyTerminations' && (
        <>
          <div className='grid-out p-5  px-2'>
            <div
              className='card tops p-5 m-0'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            >
              <div className='d-flex align-items-center gap-5'>
                <h3 className='m-0'>Tenancy Termination Request</h3>
              </div>

              {byiddata?.status === 4 ||
              byiddata?.status === 6 ||
              byiddata?.status === 5 ||
              byiddata?.status === 2 ? (
                <></>
              ) : (
                <div className='d-flex'>
                  {byiddata?.status === 10 || byiddata?.status === 0 ? (
                    <a
                      className='btn btn-sm fw-bold term-btn mx-3 pe-0 ps-2'
                      onClick={() => {
                        if (byiddata?.clone_tenancy?.length > 0) {
                          navigate(
                            `/tenancy-termination/2/${byiddata?.unit?.[0]?._id}/${byiddata?.tenancyId}`,
                            {
                              state: {
                                tenantReqId: byiddata?._id,
                                terminationDate: byiddata?.terminationDate,
                              },
                            }
                          )
                        } else {
                          addTerminateTenancy(
                            byiddata?.unit?.[0]?._id,
                            byiddata?.tenancyId,
                            byiddata?._id,
                            byiddata?.terminationDate
                          )
                        }

                        let start = byiddata?.unit?.[0]?.tenancy?.[0]?.duration?.start_date
                        let end = byiddata?.unit?.[0]?.tenancy?.[0]?.duration?.end_date

                        let duration = {
                          start: start,
                          end: end,
                        }

                        localStorage.setItem('OriginalTerm', JSON.stringify(duration))
                        localStorage.setItem('termTenantReqId', byiddata?._id)

                        // navigate(
                        //   `/renew-tenancy/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]._id}`,
                        //   {
                        //     state: {
                        //       tenantReqId: byiddata?._id,
                        //     },
                        //   }
                        // )

                        // navigate(
                        //   `/create-tenant/2/${byiddata?.tenant[0]?._id}/${byiddata?.unit?.[0]?.tenancy?.[0]?._id}`
                        // )
                      }}
                    >
                      <img src={terminate} height={18} width={18} className='cursor-pointer me-4' />
                      {byiddata?.clone_tenancy?.length > 0
                        ? 'Continue Termination'
                        : 'Terminate Tenancy'}
                    </a>
                  ) : (
                    <></>
                  )}

                  <a
                    className='btn btn-sm fw-bold  ms-3 red-hollow-btn req-red-btn ps-2'
                    onClick={() => requeststatuschange(byiddata?._id, 6, 3)}
                  >
                    <img src={redCross} height={18} width={18} className='me-4' /> Cancel Request
                  </a>
                </div>
              )}

              {/* {byiddata?.status === 4 || byiddata?.status === 5 || byiddata?.status === 6 ? (
                  <></>
                ) : byiddata?.status == 10 ? (
                  <div className='d-flex'>
                    <a
                      className='btn btn-sm fw-bold terminate-btn mx-3'
                      onClick={() => {
                        navigate(
                          `/tenancy/create/3/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]?._id}`
                        )
                      }}
                    >
                      Continue Termination
                    </a>

                    <a
                      className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                      onClick={() => requeststatuschange(byiddata?._id, 6, 3)}
                    >
                      Cancel
                    </a>
                  </div>
                ) : byiddata?.status === 2 || byiddata?.status === 1 ? (
                  <>
                    <a
                      className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                      onClick={() => requeststatuschange(byiddata?._id, 6, 3)}
                    >
                      Cancel
                    </a>
                  </>
                ) : (
                  <>
                    {byiddata?.tenancy?.[0]?.renewalStatus == 6 ? (
                      <div className=''>
                        <a
                          className='btn btn-sm fw-bold terminate-btn mx-3'
                          onClick={() => {
                            navigate(
                              `/tenancy/create/3/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]?._id}`
                            )
                          }}
                        >
                          Continue Termination
                        </a>
                        <a
                          className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                          onClick={() => requeststatuschange(byiddata?._id, 6, 3)}
                        >
                          Cancel
                        </a>
                      </div>
                    ) : (
                      <div className=''>
                        <a
                          className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                          onClick={() => {
                           
                            let body = {
                              tenancyId: byiddata?.tenancy?.[0]?._id,
                              terminationDate: moment(byiddata?.terminationDate).format(
                                'YYYY-MM-DD'
                              ),
                              tenantRequestId: byiddata?._id,
                            }
                            ApiPost('corporate/tenancy/terminate', body)
                              .then((res) => {
                                navigate(
                                  `/tenancy/create/3/${byiddata?.tenancy?.[0]?.unitId}/${byiddata?.tenancy?.[0]?._id}`,
                                  {state: {terminationDate: byiddata?.terminationDate}}
                                )
                                
                              })
                              .catch((err) => ErrorToast(err.message))
                          }}
                        >
                          Terminate Tenancy
                        </a>
                        <a
                          className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                          onClick={() => requeststatuschange(byiddata?._id, 6, 3)}
                        >
                          Cancel
                        </a>
                       
                      </div>
                    )}
                  </>
                )} */}
            </div>
            <div className='card grid px-7 py-5 side'>
              <h1 className='top'>
                <b>Request Summary</b>
              </h1>

              <div className='mt-4 mb-2 '>
                <h4 className='my-1 d-flex' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Status{' '}
                  </label>
                  <div className='ms-5 ps-1 d-flex'>
                    <div
                      className={
                        byiddata?.status === 3 ||
                        byiddata?.status === 4 ||
                        byiddata?.status === 5 ||
                        byiddata?.status == 6
                          ? 'dot reds ps-1'
                          : 'dot greens ps-1'
                      }
                    ></div>
                    <span className='ms-5 ps-1'>
                      <b>
                        {byiddata?.status === 0
                          ? 'Requested'
                          : byiddata?.status === 1
                          ? 'Submitted - Pending Tenant Approval'
                          : byiddata?.status === 2
                          ? 'Tenant Approved'
                          : byiddata?.status === 4 || byiddata?.status == 6
                          ? 'Cancelled'
                          : byiddata?.status === 5
                          ? 'Tenant Rejected'
                          : byiddata?.status === 10
                          ? 'Draft'
                          : '-'}
                      </b>
                    </span>
                  </div>
                </h4>
              </div>

              <div className='mb-3'>
                <label style={{minWidth: '100px'}} className='labl-gry'>
                  {' '}
                </label>
                <span className='ms-5 ps-1'>
                  <i>
                    {byiddata?.updatedBy?.[0] &&
                      `${byiddata?.updatedBy?.[0]?.firstName} ${byiddata?.updatedBy?.[0]?.lastName} - `}

                    {byiddata?.statusDateTime
                      ? `${moment(byiddata?.statusDateTime).format('DD.MM.YYYY - HH:mm A')}`
                      : '-'}
                  </i>
                </span>
              </div>

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Request ID
                  </label>
                  <span className='ms-5 ps-1'>
                    <b>{byiddata?.id}</b>
                  </span>
                </h4>
              </div>

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Request Date
                  </label>
                  <span className='ms-5 ps-1'>
                    <b> {moment(byiddata?.createdAt).format('DD.MM.YYYY')}</b>
                  </span>
                </h4>
              </div>

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Requestor
                  </label>
                  <span className='ms-5 ps-1'>
                    <b>
                      {' '}
                      {/* {Object?.keys(byiddata?.requestor)?.length != 0 */}
                      {byiddata?.requestor?.length != 0
                        ? byiddata?.requestor?.firstName + ' ' + byiddata?.requestor?.lastName
                        : 'N/A'}
                    </b>
                  </span>
                </h4>
              </div>

              {byiddata?.requestorType == 0 && (
                <>
                  <div className='mt-4 mb-2'>
                    <h4 className='my-1' style={{color: 'black'}}>
                      <label style={{minWidth: '100px'}} className='labl-gry'>
                        {' '}
                        Termination Date{' '}
                      </label>
                      <span className='ms-5 ps-2'>
                        <b>{moment(byiddata?.terminationDate).format('DD-MM-YYYY')}</b>
                      </span>
                    </h4>
                  </div>

                  <div className='mt-4 mb-2'>
                    <h4 className='my-1' style={{color: 'black'}}>
                      <label style={{minWidth: '100px'}} className='labl-gry'>
                        {' '}
                        Remaining Days
                      </label>
                      <span className='ms-5 ps-1'>
                        <b>
                          {' '}
                          {byiddata?.unit?.length > 0 &&
                          byiddata?.unit?.[0]?.tenancy?.[0]?.remainingDays > 0
                            ? byiddata?.unit?.[0]?.tenancy?.[0]?.remainingDays
                            : '0 '}{' '}
                          Days
                        </b>
                      </span>
                    </h4>
                  </div>
                </>
              )}
            </div>
            <div className='card grid px-7 py-5 side'>
              <h1 className='top '>
                <b>Property Details</b>
              </h1>

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Tenant ID
                  </label>
                  <span className='ms-5 ps-1'>
                    <b> {byiddata?.tenant?.length > 0 ? byiddata?.tenant[0]?.id : 'N/A'}</b>
                  </span>
                </h4>
              </div>

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Tenant Name
                  </label>
                  <span className='ms-5 ps-1'>
                    <b>
                      {' '}
                      {byiddata?.tenant?.length > 0
                        ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                        : 'N/A'}
                    </b>
                  </span>
                </h4>
              </div>

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Type
                  </label>
                  <span className='ms-5 ps-1'>
                    <b> {byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</b>
                  </span>
                </h4>
              </div>

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Development
                  </label>
                  <span className='ms-5 ps-2'>
                    <b>
                      {byiddata?.unit?.length > 0 ? (
                        <>
                          {byiddata?.unit?.[0]?.community?.length > 0
                            ? byiddata?.unit?.[0]?.community[0]?.name
                            : byiddata?.unit?.[0]?.building[0]?.name}
                        </>
                      ) : (
                        'N/A'
                      )}
                    </b>
                  </span>
                </h4>
              </div>

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Unit
                  </label>
                  <span className='ms-5 ps-2'>
                    <b>{byiddata?.unit?.length > 0 ? byiddata?.unit?.[0]?.unitNo : 'N/A'}</b>
                  </span>
                </h4>
              </div>

              <div className='mt-4 mb-2'>
                <h4 className='my-1' style={{color: 'black'}}>
                  <label style={{minWidth: '100px'}} className='labl-gry'>
                    {' '}
                    Previous Contract No.
                  </label>
                  <span className='ms-5 ps-1'>
                    <b>
                      {' '}
                      {byiddata?.unit?.length > 0
                        ? byiddata?.unit?.[0]?.tenancy?.[0]?.contractNo
                        : 'N/A'}
                    </b>
                  </span>
                </h4>
              </div>
            </div>
          </div>

          {byiddata?.terminationOffer != undefined && (
            <div className='p-5  px-2 pt-3'>
              <div
                className='card card-flush py-5 px-0  m-0'
                // style={{boxShadow: 'lightgrey 0px 1px 4px 4px'}}
              >
                <div className='row mx-1'>
                  <div className='col-12 px-5'>
                    <div className='d-flex'>
                      <h1 className='top ps-2'>
                        <b>{!isOriginal ? 'Termination Details' : 'Original Tenancy Details'}</b>
                      </h1>
                      <div className=' d-flex ms-auto'>
                        {byiddata?.status === 2 && (
                          <>
                            {!isOriginal ? (
                              <a
                                className='btn btn-sm fw-bold view-btn me-3 '
                                onClick={() => {
                                  setIsOriginal(true)
                                  getTerminationOffer(byiddata?.beforeTerminationTenancyData)
                                }}
                              >
                                Original Tenancy
                              </a>
                            ) : (
                              <a
                                className='btn btn-sm fw-bold view-btn me-3 '
                                onClick={() => {
                                  setIsOriginal(false)
                                  getTerminationOffer(byiddata?.terminationOffer)
                                }}
                              >
                                Termination Details
                              </a>
                            )}
                          </>
                        )}
                        {/* <div className='me-5'>
                              <div
                                className={
                                  flg == '4'
                                    ? 'status  no-st-bar ms-5'
                                    : tenancyData?.tenancyStatus == 0
                                    ? 'status booked-bar ms-5'
                                    : tenancyData?.tenancyStatus == 1
                                    ? 'status active-bar ms-5'
                                    : tenancyData?.tenancyStatus == 2
                                    ? 'status expiring-bar ms-5'
                                    : tenancyData?.tenancyStatus == 3
                                    ? 'status expired-bar ms-5'
                                    : 'status  no-st-bar ms-5'
                                }
                              >
                                {flg == '4'
                                  ? '-'
                                  : tenancyData?.tenancyStatus == 0
                                  ? 'Booked'
                                  : tenancyData?.tenancyStatus == 1
                                  ? 'Active'
                                  : tenancyData?.tenancyStatus == 2
                                  ? 'Expiring'
                                  : tenancyData?.tenancyStatus == 3
                                  ? 'Expired'
                                  : '-'}
                              </div>
                              <i>
                                {' '}
                                {tenancyData?.tenancyStatusDateTime
                                  ? `${moment(tenancyData?.tenancyStatusDateTime).format(
                                      'DD.MM.YYYY - HH:mm A'
                                    )} `
                                  : ''}
                              </i>
                            </div> */}
                        {/* <div>
                        {tenancyData?.isDraft ? (
                          <>
                            <div
                              className={
                                tenancyData?.isDraft
                                  ? 'status draft-st ms-5'
                                  : 'status create-st ms-5'
                              }
                            >
                              {tenancyData?.isDraft ? 'Draft' : 'Created'}
                            </div>
                            <i>
                              {' '}
                              {tenancyData?.draftStatusDateTime
                                ? `${moment(tenancyData?.draftStatusDateTime).format(
                                    'DD.MM.YYYY - HH:mm A'
                                  )} `
                                : ''}
                            </i>
                          </>
                        ) : (
                          <div className='status not-saved'>-</div>
                        )}
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Contract Details */}
                <>
                  <div className='row mx-4 mt-5 '>
                    <div className='col-12 px-4 '>
                      <h2 className='top ps-0'>
                        <b>Contract Details</b>
                      </h2>
                    </div>

                    <div className='col-12 px-4 mt-4 mb-3'>
                      <h4 className='my-1' style={{color: 'black'}}>
                        <label style={{minWidth: '100px'}} className='labl-gry'>
                          {' '}
                          Contract No.{' '}
                        </label>
                        <span className='ms-5 ps-1'>
                          <b>{tenancyData?.contractNo ? tenancyData?.contractNo : ''}</b>
                        </span>
                      </h4>
                    </div>

                    <div className='col-12 px-4 mt-4 mb-3'>
                      <h4 className='my-1' style={{color: 'black'}}>
                        <label style={{minWidth: '100px'}} className='labl-gry'>
                          {' '}
                          Term{' '}
                        </label>
                        <span className='ms-5 ps-0'>
                          <b>
                            {start_date != null || end_date != null ? (
                              <>
                                {' '}
                                {start_date != null || end_date != null
                                  ? `${start_date} - ${end_date} `
                                  : '-'}
                              </>
                            ) : (
                              ''
                            )}
                          </b>
                        </span>
                      </h4>
                    </div>

                    <div className='col-12 px-4 mt-3 mb-3'>
                      <h4 className='my-1' style={{color: 'black'}}>
                        <label style={{minWidth: '100px'}} className='labl-gry'>
                          {' '}
                          Duration
                        </label>
                        <span className='ms-5 ps-1'>
                          <b>{totalDays}</b>
                        </span>
                      </h4>
                    </div>

                    <div className='col-12 px-4 my-3 mb-3'>
                      <h4 className='my-1' style={{color: 'black'}}>
                        <label style={{minWidth: '100px'}} className='labl-gry'>
                          {' '}
                          Remaining
                        </label>
                        <span className='ms-5 ps-1'>
                          <b>{remainingDays}</b>
                        </span>
                      </h4>
                    </div>

                    <div className='col-12 px-4 my-3 mb-3'>
                      <div className='d-flex align-items-center'>
                        <h4 className='my-1' style={{color: 'black'}}>
                          <label style={{minWidth: '100px'}} className='labl-gry'>
                            {' '}
                            Renewal{' '}
                          </label>
                        </h4>
                        <span className='ms-5 ps-1'>
                          <b>{tenancyData?.renewalDays ? tenancyData?.renewalDays : '-'}</b>
                        </span>

                        <span>
                          {' '}
                          <i className='mt-2 ms-2'> Days before term end</i>
                        </span>
                      </div>
                    </div>

                    <div className='col-12 ps-4 my-3 mb-3'>
                      <div className='d-flex'>
                        <div className='d-flex'>
                          <h4 className='my-1' style={{color: 'black'}}>
                            <label style={{minWidth: '100px'}} className='labl-gry'>
                              {' '}
                              Benefits{' '}
                            </label>
                          </h4>
                          <div className='test ms-5 ps-1'>
                            {optionArry?.none && (
                              <div className='d-flex align-items-center'>
                                <p className='bullet-ic mb-0'></p>
                                <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                  {' '}
                                  None
                                </p>
                              </div>
                            )}

                            {optionArry?.chillerFree === true && (
                              <div className='d-flex align-items-center'>
                                <p className='bullet-ic mb-0'></p>
                                <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                  {' '}
                                  Chiller Free
                                </p>
                              </div>
                            )}

                            {optionArry?.maintenanceFree === true && (
                              <div className='d-flex align-items-center'>
                                <p className='bullet-ic mb-0'></p>
                                <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                  {' '}
                                  Maintenance Free
                                </p>
                              </div>
                            )}
                            {otherOptions?.length > 0 &&
                              otherOptions?.map((opt: any, ind: any) => (
                                <div className='d-flex align-items-center'>
                                  <p className='bullet-ic mb-0'></p>
                                  <p className='ms-3 mb-0' style={{fontWeight: '600'}}>
                                    {' '}
                                    {opt?.value}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='row mx-1 mt-5'>
                      <div className='col-12 ps-0 mt-5'>
                        <div className='d-flex  align-items-center'>
                          <h3 className='ms-0' style={{fontWeight: '700'}}>
                            Documents
                          </h3>
                        </div>
                      </div>

                      {contractDocuments?.map((doc: any, index: any) => {
                        return (
                          <>
                            {doc.isCheck && (
                              <>
                                <div className='col-12 ps-0 pe-3 mt-5 pt-2'>
                                  <div className='d-flex  align-items-center'>
                                    <h4 className='' style={{fontWeight: '700'}}>
                                      {doc?.name}
                                    </h4>
                                  </div>
                                </div>
                                <div className='col-12 px-2 mt-4'>
                                  {doc?.fileList?.length > 0 ? (
                                    <div
                                      className='tops d-flex gap-5 align-items-start mx-5'
                                      style={{overflow: 'scroll', overflowY: 'hidden'}}
                                    >
                                      {doc?.fileList?.map((file: any, ind: any) => {
                                        return (
                                          <>
                                            {file.isChecked && (
                                              <div className='imgs-set'>
                                                <div className='d-flex mw-350px me-3'>
                                                  <img
                                                    src={Bucket + file?.fileURL}
                                                    width='100'
                                                    height='100'
                                                    className='main_img mb-5'
                                                    onClick={() => {}}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )
                                      })}
                                    </div>
                                  ) : (
                                    <div className='d-flex justify-content-center'>
                                      {' '}
                                      <div className=''>
                                        {' '}
                                        {/* <h2 className='head-text'>No Files...</h2> */}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )
                      })}
                      {contractDeletedDocuments?.map((doc: any, index: any) => {
                        return (
                          <>
                            {doc.isCheck && (
                              <>
                                <div className='col-7 ps-0 pe-1 mt-4'>
                                  <div className='d-flex  align-items-center'>
                                    <h4 className='' style={{fontWeight: '700'}}>
                                      {doc?.name}
                                    </h4>
                                  </div>
                                </div>
                                <div className='col-5 mt-4 ps-1'></div>
                                <div className='col-12 px-0 mt-5 pt-4'>
                                  {doc?.fileList?.length > 0 ? (
                                    <div
                                      className='tops d-flex gap-5 align-items-start mx-5'
                                      style={{overflow: 'scroll', overflowY: 'hidden'}}
                                    >
                                      {doc?.fileList?.map((file: any, ind: any) => {
                                        return (
                                          <>
                                            {file.isChecked && (
                                              <div className='imgs-set'>
                                                <div className='d-flex mw-350px me-3'>
                                                  <img
                                                    src={Bucket + file?.fileURL}
                                                    width='100'
                                                    height='100'
                                                    className='main_img mb-5'
                                                    onClick={() => {}}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )
                                      })}
                                    </div>
                                  ) : (
                                    <div className='d-flex justify-content-center'>
                                      {' '}
                                      <div className=''>
                                        {' '}
                                        {/* <h2 className='head-text'>No Files...</h2> */}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )
                      })}
                    </div>
                  </div>
                </>

                {/* Main Tenant Details */}
                <>
                  <div className='row mx-1 mt-5 pt-3'>
                    <div className='col-7 ps-5'>
                      <h2 className='top ps-2'>
                        <b>Main Tenant Details</b>
                      </h2>
                    </div>

                    <div className='row mx-0 mt-3'>
                      <div className='col-12 px-4'>
                        <div className='d-flex'>
                          <h4 className='my-3 mb-3' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry'>
                              {' '}
                              First Name{' '}
                            </label>
                            <span className='ms-5 ps-1'>
                              <b>{formData?.firstName ? formData?.firstName : ''}</b>
                            </span>
                          </h4>
                        </div>
                      </div>
                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            {' '}
                            Last Name{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>{formData?.lastName ? formData?.lastName : ''}</b>
                          </span>
                        </h4>
                      </div>
                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            {' '}
                            Passport No.{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>{formData?.passportNo ? formData?.passportNo : '-'}</b>
                          </span>
                        </h4>
                      </div>

                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            {' '}
                            ID No.{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>{formData?.idNo ? formData?.idNo : '-'}</b>
                          </span>
                        </h4>
                      </div>

                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            {' '}
                            DOB{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>
                              {formData?.DOB
                                ? moment(formData?.DOB && formData?.DOB.split('T')[0]).format(
                                    'DD/MM/YYYY'
                                  )
                                : ''}
                            </b>
                          </span>
                        </h4>
                      </div>

                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            Nationality{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>{formData?.nationality ? formData?.nationality : ''}</b>
                          </span>
                        </h4>
                      </div>

                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            Email{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>{formData?.email ? formData?.email : ''}</b>
                          </span>
                        </h4>
                      </div>

                      <div className='col-12 px-4'>
                        <h4 className='my-3 mb-3' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            Mobile No.{' '}
                          </label>
                          <span className='ms-5 ps-1'>
                            <b>
                              {formData?.phoneNumber
                                ? `${formData?.countryCode} ${formData?.phoneNumber}`
                                : ''}
                            </b>
                          </span>
                        </h4>
                      </div>
                    </div>

                    <div className='row mx-1 mt-5'>
                      <div className='col-7 mt-5'>
                        <div className='d-flex  align-items-center'>
                          <h3 className='ms-0' style={{fontWeight: '700'}}>
                            Documents
                          </h3>
                        </div>
                      </div>

                      {tenantDocuments?.map((doc: any, index: any) => {
                        return (
                          <>
                            {doc.isCheck && (
                              <>
                                <div className='col-7 ps-3 pe-3 mt-5 pt-3'>
                                  <div className='d-flex  align-items-center'>
                                    <h4 className='' style={{fontWeight: '700'}}>
                                      {doc?.name}
                                    </h4>
                                  </div>
                                </div>
                                <div className='col-12 px-4 mt-5'>
                                  {doc?.fileList?.length > 0 ? (
                                    <div
                                      className='tops d-flex gap-5 align-items-start mx-5'
                                      style={{overflow: 'scroll', overflowY: 'hidden'}}
                                    >
                                      {doc?.fileList?.map((file: any, ind: any) => {
                                        return (
                                          <>
                                            {file.isChecked && (
                                              <div className='imgs-set'>
                                                <div className='d-flex mw-350px me-3'>
                                                  <img
                                                    src={Bucket + file?.fileURL}
                                                    width='100'
                                                    height='100'
                                                    className='main_img mb-5'
                                                    onClick={() => {}}
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )
                                      })}
                                    </div>
                                  ) : (
                                    <div className='d-flex justify-content-center'>
                                      {' '}
                                      <div className=''>
                                        {' '}
                                        {/* <h2 className='head-text'>No Files...</h2> */}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )
                      })}

                      {tenantDeletedDocuments?.map((doc: any, index: any) => {
                        return (
                          <>
                            {doc.isCheck && (
                              <>
                                <div className='col-12 ps-4 pe-3 mt-4'>
                                  <div className='d-flex  align-items-center'>
                                    <h4 className='' style={{fontWeight: '700'}}>
                                      {doc?.name}
                                    </h4>
                                  </div>
                                </div>

                                <div className='col-12 px-4 mt-4'>
                                  {doc?.fileList?.length > 0 ? (
                                    <div
                                      className='tops d-flex gap-5 align-items-start mx-5'
                                      style={{overflow: 'scroll', overflowY: 'hidden'}}
                                    >
                                      {doc?.fileList?.map((file: any, ind: any) => {
                                        return (
                                          <>
                                            {file.isChecked && (
                                              <div className='imgs-set'>
                                                <div className='d-flex mw-350px me-3'>
                                                  <img
                                                    src={Bucket + file?.fileURL}
                                                    width='100'
                                                    height='100'
                                                    className='main_img mb-5'
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        )
                                      })}
                                    </div>
                                  ) : (
                                    <div className='d-flex justify-content-center'>
                                      {' '}
                                      <div className=''>
                                        {' '}
                                        {/* <h2 className='head-text'>No Files...</h2> */}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )
                      })}
                    </div>

                    {/* Sub Tenants */}
                    {subTenantData?.length > 0 && (
                      <div className='row mx-0 mt-5 pt-4'>
                        <div className='col-12 ps-4 pe-0 mt-3'>
                          <div className='d-flex  align-items-center'>
                            <h2 className='ms-0' style={{fontWeight: '700'}}>
                              Sub Tenants
                            </h2>
                          </div>
                        </div>
                      </div>
                    )}

                    {subTenantData?.length > 0 && (
                      <div className='row mx-0'>
                        <div className='col-12 ps-5 pe-0 '>
                          <div className='table-responsive '>
                            <table
                              className='table align-middle  fs-6 gy-5'
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                  <th className='text-start min-w-100px'>First Name</th>
                                  <th className='text-start min-w-100px'>Last Name</th>
                                  <th className='text-start min-w-100px'>Passport No.</th>
                                  <th className='text-start min-w-100px'>ID No.</th>
                                  <th className='text-start min-w-150px'>Email </th>
                                  <th className='text-start min-w-100px'>Mobile No.</th>
                                </tr>
                              </thead>

                              <tbody className='fw-semibold text-gray-600'>
                                {subTenantData?.length ? (
                                  subTenantData.map((sb: any, i: any) => {
                                    return (
                                      <>
                                        {sb?.id != '' && (
                                          <tr className='mt-2'>
                                            <td className='text-start min-w-100px'>
                                              {`${sb?.firstName}` ? `${sb?.firstName}` : '-'}
                                            </td>
                                            <td className='text-start min-w-100px'>
                                              {`${sb?.lastName}` ? `${sb?.lastName}` : '-'}
                                            </td>
                                            <td className='text-start min-w-100px'>
                                              {`${
                                                sb?.passportNo != undefined &&
                                                sb?.passportNo != null
                                              }`
                                                ? `${sb?.passportNo}`
                                                : '-'}
                                            </td>
                                            <td className='text-start min-w-100px'>
                                              {`${sb?.idNo != undefined && sb?.idNo != null}`
                                                ? `${sb?.idNo}`
                                                : '-'}
                                            </td>
                                            <td className='text-start min-w-150px'>
                                              {`${sb?.email}` ? `${sb?.email}` : '-'}{' '}
                                            </td>
                                            <td className='text-start min-w-100px'>
                                              {`${sb?.phoneNumber}}`
                                                ? `${sb?.countryCode} ${sb?.phoneNumber}`
                                                : '-'}
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    )
                                  })
                                ) : (
                                  <></>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>

                {/* Financials Details */}

                <>
                  <div className='row mx-3 mt-5'>
                    <div className='col-7 px-5 mt-5 pt-5'>
                      <h2 className='top'>
                        <b>Financials</b>
                      </h2>
                    </div>
                  </div>
                  {incomePaymentPayload?.length > 0 && (
                    <div className='row mx-4 mt-0'>
                      <div className='col-12 px-3'>
                        {incomePaymentPayload?.length > 0 ? (
                          <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0 test'>
                            <table
                              className='table align-start table-row-dashed fs-6 gy-5 '
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                  <th className='min-w-100px'>Payment</th>
                                  <th className='min-w-100px'>Amount</th>
                                  <th className='min-w-100px'>Status</th>
                                  <th className='min-w-100px'>Due</th>
                                  <th className='min-w-100px'>Created At</th>
                                  <th className='min-w-150px'>Amount Paid</th>
                                  <th className='min-w-150px'>Date Paid</th>
                                  <th className='min-w-100px'></th>
                                </tr>
                              </thead>
                              <tbody className='fw-semibold text-gray-600'>
                                {incomePaymentPayload?.map((pm: any, ind: any) => {
                                  return (
                                    <>
                                      <tr
                                      // style={
                                      //   show[ind] == true
                                      //     ? {borderBottomStyle: 'none', height: '30px'}
                                      //     : show[ind] == false
                                      //     ? {borderBottomStyle: 'dashed', height: '30px'}
                                      //     : pm?.payment_sub_item?.length == 0
                                      //     ? {borderBottomStyle: 'dashed', height: '30px'}
                                      //     : {height: '30px'}
                                      // }
                                      >
                                        <td>
                                          <div className='d-flex align-items-center'>
                                            {pm?.payment?.name}
                                          </div>
                                        </td>

                                        <td>
                                          {parseFloat(pm?.payment?.totalAmount)?.toLocaleString(
                                            'en-US',
                                            {
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                        </td>

                                        <td>
                                          {pm?.payment?.paymentStatus === 0
                                            ? 'Unpaid'
                                            : pm?.payment?.paymentStatus === 1
                                            ? 'Paid'
                                            : pm?.payment?.paymentStatus === 2
                                            ? 'Partially paid'
                                            : pm?.payment?.paymentStatus === 3
                                            ? 'Void'
                                            : 'Draft'}
                                        </td>

                                        <td>
                                          {pm?.payment?.dueDate
                                            ? moment(pm?.payment?.dueDate).format(
                                                'DD.MM.YYYY - hh:mm A'
                                              )
                                            : '-'}
                                        </td>
                                        <td>
                                          {pm?.payment?.createdAt
                                            ? moment(pm?.payment?.createdAt).format(
                                                'DD.MM.YYYY - hh:mm A'
                                              )
                                            : '-'}
                                        </td>
                                        <td>
                                          {pm?.payment?.amountPaid?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })}
                                        </td>
                                        <td>
                                          {pm?.payment?.datePaid
                                            ? moment(pm?.payment?.datePaid).format(
                                                'DD.MM.YYYY - hh:mm A'
                                              )
                                            : '-'}
                                        </td>
                                        <td>
                                          {/* <BsArrowRightSquareFill
                                                color='#35475E '
                                                fontSize={18}
                                                style={{cursor: 'pointer'}}
                                                className='mx-3 me-4'
                                                onClick={() => {
                                                  let values = {
                                                    firstName: formData?.firstName,
                                                    lastName: formData?.lastName,
                                                    email: formData?.email,
                                                    countryCode: formData?.countryCode,
                                                    phoneNumber: formData?.phoneNumber,
                                                  }
                                                  setTenantDetails(values)

                                                  console.log(pm?.payment?._id)
                                                  if (pm?.payment?._id) {
                                                    ApiGet(`corporate/payment/${pm?.payment?._id}`)
                                                      .then(async (res) => {
                                                        setDataToEdit(res?.data?.data)
                                                        setIndexToEdit(ind)
                                                        setEditIncomeModalDraft(true)
                                                      })
                                                      .catch((err) => console.log('err', err))
                                                  } else {
                                                    console.log(pm)
                                                    setDataToEdit(pm)
                                                    setIndexToEdit(ind)
                                                    setEditIncomeModalDraft(true)
                                                  }
                                                }}
                                              /> */}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                            <table
                              className='table align-start table-row-dashed fs-6 gy-5'
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                  <th className='min-w-100px'>Payment</th>
                                  <th className='min-w-100px'>Amount</th>
                                  <th className='min-w-100px'>Status</th>
                                  <th className='min-w-100px'>Due</th>
                                  <th className='min-w-100px'>Created At</th>
                                  <th className='min-w-150px'>Amount Paid</th>
                                  <th className='min-w-150px'>Date Paid</th>
                                  <th className='min-w-100px'></th>
                                </tr>
                              </thead>
                              <tbody className='fw-semibold text-gray-600'>
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={350} />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          )}

          {/* <div className='card tops text-muted'>
                <div className='px-5 py-3 mt-0'>
                  <h1 className='top green_color'>Termination Details</h1>
                  <h6 className='mx-0 text-muted'>
                    Termination Date
                    <div className='mx-1 value_black'>
                      {moment(byiddata?.terminationDate).format('DD-MM-YYYY')}
                    </div>
                  </h6>
                  <h6 className='mx-0 text-muted'>
                    Remaining
                    <div className='mx-1 value_black'>
                      {byiddata?.unit?.length > 0
                        ? byiddata?.unit?.[0]?.tenancy?.[0]?.remainingDays
                        : 'N/A'}{' '}
                      Days
                    </div>
                  </h6>
                </div>
                <div className='px-5 py-3 mt-0'></div>
              </div> */}
        </>
      )}

      {propertiTypeTH === 'TenancyUpdate' && (
        <>
          <div className='grid-out p-5  px-2'>
            <div
              className='card tops p-5 m-0'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            >
              <div className='d-flex align-items-center gap-5'>
                <h3 className='m-0'>Tenancy Termination Request</h3>
              </div>

              {byiddata?.status === 2 || byiddata?.status === 3 ? (
                <></>
              ) : (
                <div className=''>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 2, 3)}
                  >
                    Terminate Tenancy
                  </a>
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 3, 3)}
                  >
                    Reject
                  </a>
                  {/* <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            onClick={() => requeststatuschange(byiddata?._id,4,1)}
                          >
                            Cancel Request
                          </a> */}
                </div>
              )}
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Request Summary</h1>
              <h6 className='mx-0 text-muted'>
                <div
                  className='d-flex align-items-center'
                  style={{justifyContent: 'space-between'}}
                >
                  Status
                  <div
                    className={
                      byiddata?.status === 3 ||
                      byiddata?.status === 5 ||
                      byiddata?.status === 4 ||
                      byiddata?.status === 6
                        ? 'dot reds'
                        : 'dot greens'
                    }
                  ></div>
                </div>
                <div className='mx-1 value_black'>
                  {byiddata?.status === 0
                    ? 'Request'
                    : byiddata?.status === 1
                    ? 'Termination submitted'
                    : byiddata?.status === 2
                    ? 'Tenant approved'
                    : byiddata?.status === 3
                    ? 'Rejected'
                    : byiddata?.status === 4
                    ? 'Tenant cancelled'
                    : byiddata?.status === 5
                    ? 'Tenant rejected'
                    : byiddata?.status === 6
                    ? 'Accepted'
                    : byiddata?.status === 7
                    ? 'Booked'
                    : byiddata?.status === 8
                    ? 'Variation Request'
                    : 'Canceled'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request ID
                <div className='mx-1 value_black'>{byiddata?.id}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request Date
                <div className='mx-1 value_black'>
                  {moment(byiddata?.createdAt).format('DD-MM-YYYY')}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Requestor
                <div className='mx-1 value_black'>
                  {byiddata?.requestor?.length > 0
                    ? byiddata?.requestor[0]?.firstName + ' ' + byiddata?.requestor[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Property Details</h1>
              <h6 className='mx-0 text-muted'>
                Tenant Name
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0
                    ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Type
                <div className='mx-1 value_black'>{byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Development
                {byiddata?.unit?.length > 0 ? (
                  <div className='mx-1 value_black'>
                    {byiddata?.unit[0]?.community?.length > 0
                      ? byiddata?.unit[0]?.community[0]?.name
                      : byiddata?.unit[0]?.building[0]?.name}
                  </div>
                ) : (
                  'N/A'
                )}
              </h6>
              <h6 className='mx-0 text-muted'>
                Unit
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit[0]?.unitNo : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Current Contract ID
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit[0]?.tenancy[0]?.contractNo : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card tops text-muted'>
              <div className='px-5 py-3 mt-0'>
                <h1 className='top green_color'>Termination Details</h1>
                <h6 className='mx-0 text-muted'>
                  Termination Date
                  <div className='mx-1 value_black'>
                    {moment(byiddata?.terminationDate).format('DD-MM-YYYY')}
                  </div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  Remaining
                  <div className='mx-1 value_black'>
                    {byiddata?.unit?.length > 0
                      ? byiddata?.unit[0]?.tenancy[0]?.remainingDays
                      : 'N/A'}{' '}
                    Days
                  </div>
                </h6>
              </div>
              <div className='px-5 py-3 mt-0'></div>
            </div>
          </div>
        </>
      )}
      {propertiTypeTH === 'GeneralComplaints' && (
        <>
          <div className='grid-out p-5  px-2'>
            <div
              className='card tops p-5 m-0'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            >
              <div className='d-flex align-items-center gap-5'>
                <h3 className='m-0'>General Complaint Request</h3>
              </div>

              {byiddata?.status === 2 ? (
                <></>
              ) : (
                <div className=''>
                  {byiddata?.status === 1 ? (
                    <></>
                  ) : (
                    <a
                      className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                      onClick={() => requeststatuschange(byiddata?._id, 1, 5)}
                    >
                      In Progress
                    </a>
                  )}
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 2, 5)}
                  >
                    Resolved
                  </a>
                  {/* <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            onClick={() => requeststatuschange(byiddata?._id,4,1)}
                          >
                            Cancel Request
                          </a> */}
                </div>
              )}
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Request Summary</h1>
              <h6 className='mx-0 text-muted'>
                <div
                  className='d-flex align-items-center'
                  style={{justifyContent: 'space-between'}}
                >
                  Status
                  <div className={byiddata?.status === 3 ? 'dot reds' : 'dot greens'}></div>
                </div>
                <div className='mx-1 value_black'>
                  {byiddata?.status === 0
                    ? 'Received'
                    : byiddata?.status === 1
                    ? 'In Progress'
                    : byiddata?.status === 2
                    ? 'Resolved'
                    : byiddata?.status === 3
                    ? 'Rejected'
                    : byiddata?.status === 4
                    ? 'Approved'
                    : byiddata?.status === 5
                    ? 'Awaiting Vendor Confirmation'
                    : byiddata?.status === 6
                    ? 'Accepted'
                    : byiddata?.status === 7
                    ? 'Booked'
                    : byiddata?.status === 8
                    ? 'Variation Request'
                    : 'Canceled'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request ID
                <div className='mx-1 value_black'>{byiddata?.id}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request Date
                <div className='mx-1 value_black'>
                  {moment(byiddata?.createdAt).format('DD-MM-YYYY')}
                </div>
              </h6>

              <h6 className='mx-0 text-muted'>
                Requestor
                <div className='mx-1 value_black'>
                  {byiddata?.requestor?.length > 0
                    ? byiddata?.requestor[0]?.firstName + ' ' + byiddata?.requestor[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Property Details</h1>
              <h6 className='mx-0 text-muted'>
                Tenant ID
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0 ? byiddata?.tenant[0]?.id : 'N/A'}
                </div>
              </h6>

              <h6 className='mx-0 text-muted'>
                Tenant Name
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0
                    ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>

              <h6 className='mx-0 text-muted'>
                Type
                <div className='mx-1 value_black'>{byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</div>
              </h6>

              <h6 className='mx-0 text-muted'>
                Development
                {byiddata?.unit?.length > 0 ? (
                  <div className='mx-1 value_black'>
                    {byiddata?.unit[0]?.community?.length > 0
                      ? byiddata?.unit[0]?.community[0]?.name
                      : byiddata?.unit[0]?.building[0]?.name}
                  </div>
                ) : (
                  'N/A'
                )}
              </h6>

              <h6 className='mx-0 text-muted'>
                Unit
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit[0]?.unitNo : 'N/A'}
                </div>
              </h6>

              <h6 className='mx-0 text-muted'>
                Current Contract ID
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit[0]?.tenancy[0]?.contractNo : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card tops text-muted'>
              <div className='px-5 py-3 mt-0'>
                <h1 className='top green_color'>General Complaint Details</h1>
                <h6 className='mx-0 text-muted'>
                  Complaint Name
                  <div className='mx-1 value_black '>{byiddata?.name}</div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  Complaint Details
                  <div className='mx-1 value_black'>{byiddata?.comment}</div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  Priority
                  <div className='d-flex gap-5 align-items-center'>
                    <div>
                      <span className='mx-0 ' style={{color: 'black', paddingTop: '7px'}}>
                        <input
                          className='form-check-input  '
                          type='radio'
                          value='high'
                          id='priority'
                          // {...register("clusterunitNo", { required: true })}
                          name='high'
                          checked={byiddata?.priority == 2 ? true : false}
                          onChange={() => setradiobut(2)}
                          disabled={byiddata?.status == 3}
                        />
                        <label
                          className='form-check-label '
                          htmlFor='Internally'
                          style={{marginLeft: '6px', opacity: '1', fontWeight: '500'}}
                        >
                          High
                        </label>
                      </span>
                    </div>
                    <div className=''>
                      <span className='mx-0 ' style={{color: 'black', paddingTop: '7px'}}>
                        <input
                          className='form-check-input '
                          type='radio'
                          value='medium'
                          id='priority'
                          // {...register("clusterunitNo", { required: true })}
                          name='medium'
                          checked={byiddata?.priority == 1 ? true : false}
                          onChange={() => setradiobut(1)}
                          // checked={formData1?.clusterunitNo == data._id ? true : false}
                          // onChange={handleChnageunit}
                          disabled={byiddata?.status == 3}
                        />
                        <label
                          className='form-check-label '
                          htmlFor='Internally'
                          style={{marginLeft: '6px', opacity: '1', fontWeight: '500'}}
                        >
                          Medium
                        </label>
                      </span>
                    </div>
                    <div className=''>
                      <span className='mx-0 ' style={{color: 'black', paddingTop: '7px'}}>
                        <input
                          className='form-check-input pr-5'
                          type='radio'
                          value='low'
                          id='priority'
                          // {...register("clusterunitNo", { required: true })}
                          name='low'
                          checked={byiddata?.priority == 0 ? true : false}
                          onChange={() => setradiobut(0)}
                          // checked={formData1?.clusterunitNo == data._id ? true : false}
                          // onChange={handleChnageunit}
                          disabled={byiddata?.status == 3}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='Internally'
                          style={{marginLeft: '6px', opacity: '1', fontWeight: '500'}}
                        >
                          Low
                        </label>
                      </span>
                    </div>
                  </div>
                </h6>
              </div>
              <div className='px-5 py-3 mt-0 d-flex flex-column gap-3'>
                <h6 className='mx-0 text-muted'>Tenant Uploaded Media</h6>
                {byiddata?.media?.length > 0 &&
                  byiddata?.media?.map((v: any, i: any) => (
                    <>
                      <a
                        className='mx-0 img_link'
                        // style={{width: '85px'}}
                        href={`${Bucket}${v}`}
                        target='_blank'
                      >
                        {/* {v.split('.')[v.split('.')?.length - 1] ? 'Image' : 'Video'} */}
                        <img
                          src={`${Bucket}${v}`}
                          alt=''
                          className='big_screen_img'
                          // onMouseEnter={() => setShow_img(true)}
                          // onMouseLeave={() => setShow_img(false)}
                        />
                        {/* <img
                            src={zoom}
                            className={show_img ? 'view_btn' : ' d-none'}
                            // onClick={() => navigate(`${Bucket}${v}`)}
                            onMouseEnter={() => setShow_img(true)}
                            onMouseLeave={() => setShow_img(true)}
                          /> */}
                      </a>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
      {propertiTypeTH === 'OtherRequests' && (
        <>
          <div className='grid-out p-5  px-2'>
            <div
              className='card tops p-5 m-0'
              style={{justifyContent: 'space-between', flexDirection: 'row'}}
            >
              <div className='d-flex align-items-center gap-5'>
                <h3 className='m-0'>Other Request</h3>
              </div>

              {byiddata?.status === 2 ? (
                <></>
              ) : (
                <div className=''>
                  {byiddata?.status === 1 ? (
                    <></>
                  ) : (
                    <a
                      className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                      onClick={() => requeststatuschange(byiddata?._id, 1, 6)}
                    >
                      In Progress
                    </a>
                  )}
                  <a
                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                    onClick={() => requeststatuschange(byiddata?._id, 2, 6)}
                  >
                    Resolved
                  </a>
                  {/* <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            onClick={() => requeststatuschange(byiddata?._id,4,1)}
                          >
                            Cancel Request
                          </a> */}
                </div>
              )}
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Request Summary</h1>
              <h6 className='mx-0 text-muted'>
                <div
                  className='d-flex align-items-center'
                  style={{justifyContent: 'space-between'}}
                >
                  Status
                  <div className={byiddata?.status === 3 ? 'dot reds' : 'dot greens'}></div>
                </div>
                <div className='mx-1  value_black'>
                  {byiddata?.status === 0
                    ? 'Received'
                    : byiddata?.status === 1
                    ? 'In Progress'
                    : byiddata?.status === 2
                    ? 'Resolved'
                    : byiddata?.status === 3
                    ? 'Rejected'
                    : byiddata?.status === 4
                    ? 'Approved'
                    : byiddata?.status === 5
                    ? 'Awaiting Vendor Confirmation'
                    : byiddata?.status === 6
                    ? 'Accepted'
                    : byiddata?.status === 7
                    ? 'Booked'
                    : byiddata?.status === 8
                    ? 'Variation Request'
                    : 'Canceled'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request ID
                <div className='mx-1 value_black'>{byiddata?.id}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Request Date
                <div className='mx-1 value_black'>
                  {moment(byiddata?.createdAt).format('DD-MM-YYYY')}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Requestor
                <div className='mx-1 value_black'>
                  {byiddata?.requestor?.length > 0
                    ? byiddata?.requestor[0]?.firstName + ' ' + byiddata?.requestor[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card grid px-5 py-3 side'>
              <h1 className='top green_color'>Properties Details</h1>
              <h6 className='mx-0 text-muted'>
                Tenant ID
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0 ? byiddata?.tenant[0]?.id : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Tenant Name
                <div className='mx-1 value_black'>
                  {byiddata?.tenant?.length > 0
                    ? byiddata?.tenant[0]?.firstName + ' ' + byiddata?.tenant[0]?.lastName
                    : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Type
                <div className='mx-1 value_black'>{byiddata?.tenantType == 0 ? 'Main' : 'Sub'}</div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Development
                {byiddata?.unit?.length > 0 ? (
                  <div className='mx-1 value_black'>
                    {byiddata?.unit[0]?.community?.length > 0
                      ? byiddata?.unit[0]?.community[0]?.name
                      : byiddata?.unit[0]?.building[0]?.name}
                  </div>
                ) : (
                  'N/A'
                )}
              </h6>
              <h6 className='mx-0 text-muted'>
                Unit
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit[0]?.unitNo : 'N/A'}
                </div>
              </h6>
              <h6 className='mx-0 text-muted'>
                Current Contract ID
                <div className='mx-1 value_black'>
                  {byiddata?.unit?.length > 0 ? byiddata?.unit[0]?.tenancy[0]?.contractNo : 'N/A'}
                </div>
              </h6>
            </div>
            <div className='card tops text-muted'>
              <div className='px-5 py-3 mt-0'>
                <h1 className='top green_color'>Request Details</h1>
                <h6 className='mx-0 text-muted'>
                  Request Name
                  <div className='mx-1 value_black'>{byiddata?.name}</div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  Request Details
                  <div className='mx-1 value_black'>{byiddata?.comment}</div>
                </h6>
                <h6 className='mx-0 text-muted'>
                  Priority
                  <div className='d-flex gap-5 align-items-center'>
                    <div className=''>
                      <span className='mx-0 value_black'>
                        <input
                          className='form-check-input mr-2 '
                          type='radio'
                          value='high'
                          id='priority'
                          // {...register("clusterunitNo", { required: true })}
                          name='high'
                          checked={byiddata?.priority == 2 ? true : false}
                          onChange={() => setradiobut(2)}
                          disabled={byiddata?.status == 3}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='Internally'
                          style={{marginLeft: '6px', opacity: '1', fontWeight: '500'}}
                        >
                          High
                        </label>
                      </span>
                    </div>
                    <div className=''>
                      <span className='mx-0 value_black'>
                        <input
                          className='form-check-input mr-2'
                          type='radio'
                          value='medium'
                          id='priority'
                          // {...register("clusterunitNo", { required: true })}
                          name='medium'
                          checked={byiddata?.priority == 1 ? true : false}
                          onChange={() => setradiobut(1)}
                          // checked={formData1?.clusterunitNo == data._id ? true : false}
                          // onChange={handleChnageunit}
                          disabled={byiddata?.status == 3}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='Internally'
                          style={{marginLeft: '6px', opacity: '1', fontWeight: '500'}}
                        >
                          Medium
                        </label>
                      </span>
                    </div>
                    <div className=''>
                      <span className='mx-0 value_black'>
                        <input
                          className='form-check-input mr-2'
                          type='radio'
                          value='low'
                          id='priority'
                          // {...register("clusterunitNo", { required: true })}
                          name='low'
                          checked={byiddata?.priority == 0 ? true : false}
                          onChange={() => setradiobut(0)}
                          // checked={formData1?.clusterunitNo == data._id ? true : false}
                          // onChange={handleChnageunit}
                          disabled={byiddata?.status == 3}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='Internally'
                          style={{marginLeft: '6px', opacity: '1', fontWeight: '500'}}
                        >
                          Low
                        </label>
                      </span>
                    </div>
                  </div>
                </h6>
              </div>
              <div className='px-5 d-flex flex-column gap-3'>
                <h6 className='m-0 text-muted'>Tenant Uploaded Media</h6>
                {byiddata?.media?.length > 0 &&
                  byiddata?.media?.map((v: any, i: any) => (
                    <>
                      <a className='mx-0 img_link' href={`${Bucket}${v}`} target='_blank'>
                        {/* {v.split('.')[v.split('.')?.length - 1] ? 'Image' : 'Video'} */}
                        <img
                          src={`${Bucket}${v}`}
                          alt=''
                          className='big_screen_img'
                          // onMouseEnter={() => setShow_img(true)}
                          // onMouseLeave={() => setShow_img(false)}
                        />
                        {/* <img
                            src={zoom}
                            className={show_img ? 'view_btn' : ' d-none'}
                            // onClick={() => navigate(`${Bucket}${v}`)}
                            onMouseEnter={() => setShow_img(true)}
                            onMouseLeave={() => setShow_img(true)}
                          /> */}
                      </a>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ViewRequest
