import React, {useEffect, useState} from 'react'
import backArrow from '../../../../../../../../../img/back-arrow.png'
import buildingGreenIcon from '../../../../../../../../../img/allBuildingGreen.png'
import unfurnishedGreenIcon from '../../../../../../../../../img/unfurnishedGreenIcon.png'
import furnishedGreenIcon from '../../../../../../../../../img/furnishedGreenIcon.png'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {Row, Col} from 'react-bootstrap'
import {ApiPost} from '../../../../../../../../../apiCommon/helpers/API/ApiData'
import noData from '../../../../../../../../../img/NoData1.svg'

const UnitCard: React.FC<{
  type: any
  icon: any
  name: string
  stateObj: any
  navigate: any
  unitCount: number
  configurationCount: number
}> = ({name, unitCount, icon, configurationCount, stateObj, navigate, type}) => (
  <Col md={12}>
    <div
      className='card card-flush gap-5 p-3 ms-3 cursor-pointerlight-gry-dark-shadow cursor-pointer lease-setting-table-card mt-4 mb-10'
      onClick={() => {
        if (stateObj?.path) navigate(stateObj.path, {state: {...stateObj?.stateArgs, furnishingType: type}})
      }}
    >
      <div className='card-body p-1'>
        <div className='d-flex align-items-center mb-2'>
          <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4 ps-4'>{name}</p>
          <img className='ms-auto' src={icon} alt='Icon' width='30px' />
        </div>

        {/* Units */}
        <div className='d-flex align-items-center mb-1'>
          <p className='page-heading m-0 labl-gry light-dark-theme-color fnt-600 fs-4 ps-4 status-w-130'>Units</p>
          <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4 ps-4'>{unitCount}</p>
        </div>
        {/* configured units */}
        <div className='d-flex align-items-center'>
          <p className='page-heading m-0 labl-gry light-dark-theme-color fnt-600 fs-4 ps-4 status-w-130'>Configured</p>
          <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4 ps-4'>{configurationCount}</p>
        </div>
      </div>
    </div>
  </Col>
)

const RentDetails = () => {
  const navigate = useNavigate()
  const {leaseType, id, developmentId} = useParams()
  const location = useLocation()
  const passedState = location.state
  const [unitList, setUnitList] = useState<any>([])

  const [unitCountObj, setUnitCountObj] = useState<any>({
    totalUnit: 0,
    configurationCount: 0,
  })

  const getDevelopmentList = async () => {
    let body: any = {
      showFurnishingTypes: true,
      showUnitTypes: false,
      showBedroomTypes: false,
      showUnitList: false,
      leaseType: 0,
      propertyTypes:[0],
      leaseChargeListIds:[id]
      // buildingDevelopmentIds: [developmentId],
    }
    if (passedState?.isBuilding) {
      body = {
        ...body,
        buildingDevelopmentIds: [developmentId],
      }
    } else if (passedState?.isCommunity || (passedState?.isCommunity && passedState?.isUnitGroup)) {
      body = {
        ...body,
        communityIds: [developmentId],
        clusterIds: [passedState?.ids?.clusterId],
      }

      if (passedState?.isUnitGroupInMixedCluster) {
        body = {
          ...body,
          unitGroupIds: [passedState?.ids?.unitGroupId],
        }
      } else if (passedState?.isCommunityBuilding || passedState?.isBuildingCluster) {
        body = {
          ...body,
          buildingIds: [passedState?.ids?.buildingId],
        }
      }
    }
    try {
      const response = await ApiPost('corporate/lease_fee_list/rent/unit_list', body)
      // console.log(response?.data?.data)
      const updatedUnitTypes = response?.data?.data?.map((item: any) => {
        let path = `/settings/residential-leasing/annual-leases/rent-fee/${id}/rent-details/${developmentId}/${item?.furnishingType}`
        let stateObj = {
          stateArgs: {
            ...passedState,
            name: passedState.name,
          },
          path: path,
        }
        return {
          ...item,
          name:
            item?.furnishingType === 0
              ? 'Unfurnished Properties'
              : item?.furnishingType === 1
              ? 'Furnished Properties'
              : '',
          type: item?.furnishingType,
          icon:
            item?.furnishingType === 0 ? unfurnishedGreenIcon : item?.furnishingType === 1 ? furnishedGreenIcon : '',
          stateObj,
        }
      })
      // return updatedUnitTypes
      const sortList = updatedUnitTypes?.sort((a:any,b:any) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      
        // names must be equal
        return 0;
      })
      setUnitList(sortList)
    } catch (error: any) {
      console.error('Failed to fetch lease types:', error)
      if (error?.message === 'Your account has been suspended!') {
        window.location.pathname = '/auth'
        localStorage.clear()
      }
    }
  }

  const getDevelopmentCount = async () => {
    let body: any = {
      showFurnishingTypes: false,
      showUnitTypes: false,
      showBedroomTypes: false,
      showUnitList: false,
      leaseType: 0,
      onlyConfigurationCount: true,
      propertyTypes:[0],
      leaseChargeListIds:[id]
      // furnishingTypes: [Number(furnitureType)],
      // unitTypes: [Number(unitType)],
      // bedrooms: [Number(passedState?.bedrooms)],
    }
    if (passedState?.isBuilding) {
      body = {
        ...body,
        buildingDevelopmentIds: [developmentId],
      }
    } else if (passedState?.isCommunity || (passedState?.isCommunity && passedState?.isUnitGroup)){
      body = {
        ...body,
        communityIds: [developmentId],
        clusterIds: [passedState?.ids?.clusterId],
      }

      if (passedState?.isUnitGroupInMixedCluster) {
        body = {
          ...body,
          unitGroupIds: [passedState?.ids?.unitGroupId],
        }
      } else if (passedState?.isCommunityBuilding || passedState?.isBuildingCluster)  {
        body = {
          ...body,
          buildingIds: [passedState?.ids?.buildingId],
        }
      }
    }
    try {
      const response = await ApiPost('corporate/lease_fee_list/rent/unit_list', body)
      // console.log(response?.data?.data)

      setUnitCountObj({
        configurationCount: response?.data?.data?.[0]?.configurationCount,
        totalUnit: response?.data?.data?.[0]?.totalUnit,
      })
    } catch (error: any) {
      console.error('Failed to fetch lease types:', error)
      if (error?.message === 'Your account has been suspended!') {
        window.location.pathname = '/auth'
        localStorage.clear()
      }
    }
  }

  useEffect(() => {
    getDevelopmentList()
    getDevelopmentCount()
  }, [])

  return (
    <>
      <div className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-4 gap-lg-3'>
          <div className='d-flex align-items-start gap-3'>
            <span
              className='rounded-circle cursor-pointer'
              onClick={() => navigate(`/settings/residential-leasing/annual-leases/rent-fee/${id}`)}
            >
              <img src={backArrow} height={14} width={14} alt='Back' />
            </span>
            <div>
              <h2 className='page-heading m-0 head-text mb-1'>Rent Configurations</h2>
              <p className='labl-gry fs-4 mb-0 light-dark-theme-color'>
                Setup you rental rates across your property portfolio. Rent configrations will apply to new leases
                created.
              </p>
            </div>
          </div>
        </div>

        {/* card */}

        <div className='card card-flush gap-5 p-5 pt-0 pb-0 mt-5 px-3' style={{minHeight: '75vh'}}>
          <div className='card-body pt-0 py-0 px-3'>
            <Row>
              <Col className='pt-5 d-flex flex-column' md={3}>
                <div>
                  <div style={{margin: '12px 0px'}}>
                    <p className='page-heading m-0 white-dark-theme-color fnt-400 fs-4 mb-1'>Development</p>
                    <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4'>
                      {passedState?.name ? passedState?.name : '-'}
                    </p>
                  </div>
                  <div style={{margin: '12px 0px'}}>
                    <p className='page-heading m-0 white-dark-theme-color fnt-400 fs-4 mb-1'>Furnishing Type</p>
                    <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4'>
                      {/* {passedState?.furnishingType ? passedState?.furnishingType : '-'} */}
                      {passedState?.furnishingType == 0
                        ? 'Unfurnished Properties'
                        : passedState?.furnishingType == 1
                        ? 'Furnished Properties'
                        : '-'}
                    </p>
                  </div>
                  <div style={{margin: '12px 0px'}}>
                    <p className='page-heading m-0 white-dark-theme-color fnt-400 fs-4 mb-1'>Unit Type</p>
                    <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4'>
                      {/* {passedState?.unitType ? passedState?.unitType : '-'} */}
                      {passedState?.unitType == 0
                        ? 'Apartments'
                        : passedState?.unitType == 1
                        ? 'Penthouses'
                        : passedState?.unitType == 3
                        ? 'Townhouses'
                        : passedState?.unitType == 4
                        ? 'Villas'
                        : passedState?.unitType == 5
                        ? 'Other - Duplex'
                        : '-'}
                    </p>
                  </div>
                  <div style={{margin: '12px 0px'}}>
                    <p className='page-heading m-0 white-dark-theme-color fnt-400 fs-4 mb-1'>Bedrooms</p>
                    <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4'>
                      {passedState?.bedrooms ? passedState?.bedrooms : '-'}
                    </p>
                  </div>
                </div>

                {/* count box */}
                <div
                  className='status-w-170 p-3 mb-15 mt-auto'
                  style={{
                    border: '2px solid lightgrey',
                    borderRadius: '5px',
                    margin: '12px 0px',
                    backgroundColor: 'lightgrey',
                  }}
                >
                  <div className='d-flex align-items-center mb-1'>
                    <p className='page-heading m-0 labl-gry light-dark-theme-color fnt-600 fs-4 status-w-110'>Units</p>
                    <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4 text-start'>
                      {unitCountObj?.totalUnit}
                    </p>
                  </div>
                  {/* configured units */}
                  <div className='d-flex align-items-center'>
                    <p className='page-heading m-0 labl-gry light-dark-theme-color fnt-600 fs-4 status-w-110'>
                      Configured
                    </p>
                    <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4'>
                      {unitCountObj?.configurationCount}
                    </p>
                  </div>
                </div>
              </Col>

              <Col className='pt-5' md={9} style={{borderLeft: '2px solid lightgrey', minHeight: '75vh'}}>
                {unitList?.length > 0 ? (
                  unitList?.map((unitItem: any) => {
                    return (
                      <UnitCard
                        icon={
                          unitItem?.type === 0 ? unfurnishedGreenIcon : unitItem?.type === 1 ? furnishedGreenIcon : ''
                        }
                        type={unitItem?.type}
                        unitCount={unitItem?.totalUnits}
                        configurationCount={unitItem?.configurationCount}
                        name={unitItem?.name}
                        key={unitItem?._id}
                        navigate={navigate}
                        stateObj={unitItem?.stateObj}
                      />
                    )
                  })
                ) : (
                  <>
                    <div className='w-100 d-flex align-items-center justify-content-center'>
                      <img className='text-center' src={noData} alt='' width={400} />
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default RentDetails


