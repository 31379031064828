import React from 'react'
import {Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {KTSVG} from '../../../_metronic/helpers'

const AddBuilding = ({
  show,
  handleClose,
  building,
  updateDataId,
  propertiType,
  isEdit,
  formData,
  handleChnage,
  onSubmit,
}: any) => {
  console.log('formData', formData)
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
  } = useForm<any>()
  console.info('errors++', errors)
  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px'
        show={show}
        onHide={handleClose}
        backdrop={true}
        size='sm'
      >
        <div className='modal-header'>
          <h2>
            {isEdit ? 'Update' : 'Add New'}{' '}
            {propertiType === 'Buildings'
              ? 'Buildings'
              : propertiType === 'Communities'
              ? 'Communities'
              : ''}
          </h2>
          {/* begin::Close */}
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
          {/* end::Close */}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='modal-body py-lg-10 px-lg-10'>
            {/*begin::Stepper */}
            {propertiType === 'Buildings' && (
              <div
                // ref={stepperRef}
                className='stepper stepper-pills stepper-column flex-column flex-xl-row flex-row-fluid'
                id='kt_modal_create_app_stepper'
              >
                {/*begin::Content */}
                <div className='mb-10  min-w-200px'>
                  <label className='form-label fw-semibold'>Building Name:</label>
                  <div>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      {...register('name', {required: true})}
                      placeholder='Ex-Atlanta'
                      name='name'
                      value={formData?.name}
                      onChange={handleChnage}
                    />
                    {errors.name && (
                      <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                    )}
                  </div>
                </div>
                <div className='mb-10  min-w-200px'>
                  <label className='form-label fw-semibold'>Total Floor</label>
                  <div>
                    <input
                      className='form-control form-control-solid'
                      placeholder='e.g. - 4'
                      type='text'
                      {...register('totalFloors', {required: true})}
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      name='totalFloors'
                      value={formData?.totalFloors}
                      onChange={handleChnage}
                      disabled={isEdit && formData?.totalFloors !== 0 && true}
                    />
                    {errors.totalFloors && (
                      <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                    )}
                  </div>
                </div>
                <div className='mb-10  min-w-200px'>
                  <label className='form-label fw-semibold'>City</label>
                  <div>
                    <select
                      {...register('city', {required: true})}
                      name='city'
                      className='form-select form-select-solid'
                      value={formData?.city}
                      onChange={handleChnage}
                    >
                      <option disabled selected value=''>
                        Select
                      </option>
                      <option value='Dubai'>Dubai</option>
                      <option value='Abu Dhabi'>Abu Dhabi</option>
                      <option value='Sharjah'>Sharjah</option>
                      <option value='Al Ain'>Al Ain</option>
                    </select>
                  </div>
                  {errors.city && <p style={{color: 'var(--kt-danger)'}}>This field is required</p>}
                </div>
                <div className='mb-10  min-w-200px'>
                  <label className='form-label fw-semibold'>Area</label>
                  <div>
                    <select
                      {...register('area', {required: true})}
                      name='area'
                      className='form-select form-select-solid'
                      value={formData?.area}
                      onChange={handleChnage}
                    >
                      <option disabled selected value=''>
                        Select
                      </option>
                      <option value='Al Bastakiya'>Al Bastakiya </option>
                      <option value='Al Karama'>Al Karama</option>
                      <option value='Bur Dubai'>Bur Dubai</option>
                      <option value='Business Bay'>Business Bay</option>
                    </select>
                  </div>
                  {errors.area && <p style={{color: 'var(--kt-danger)'}}>This field is required</p>}
                </div>
                <div className='mb-10  min-w-200px'>
                  <label className='form-label fw-semibold'>Street</label>
                  <div>
                    <input
                      className='form-control form-control-solid'
                      type='text'
                      placeholder='e.g.- 102,L.H. Road'
                      data-kt-select2='true'
                      data-placeholder='Select option'
                      data-dropdown-parent='#kt_menu_631f08e971923'
                      data-allow-clear='true'
                      {...register('street', {required: true})}
                      name='street'
                      value={formData?.street}
                      onChange={handleChnage}
                    />
                    {errors.street && (
                      <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                    )}
                  </div>
                </div>
                <div className='mb-10  min-w-200px'>
                  <label className='form-label fw-semibold'>Building Manager</label>
                  <div>
                    <select
                      {...register('managerId', {required: true})}
                      name='managerId'
                      className='form-select form-select-solid'
                      value={formData?.managerId}
                      onChange={handleChnage}
                    >
                      <option disabled selected value=''>
                        Select
                      </option>
                      <option value='John'>John</option>
                      <option value='Max'>Max</option>
                      <option value='Root'>Root</option>
                      {/* <option value='Al Ain'>Al Ain</option> */}
                    </select>
                  </div>
                  {errors.managerId && (
                    <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                  )}
                </div>
                {/*end::Content */}
              </div>
            )}
            {propertiType === 'Communities' && (
              <div
                // ref={stepperRef}
                className='stepper stepper-pills stepper-column flex-column flex-xl-row flex-row-fluid'
                id='kt_modal_create_app_stepper'
              >
                {/*begin::Content */}
                <div className='mb-10'>
                  <label htmlFor='exampleFormControlInput1' className='required form-label'>
                    Community Name
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='e.g.-Atlanta'
                    name='name'
                    value={formData?.name}
                    onChange={handleChnage}
                  />
                </div>
                <div className='mb-10'>
                  <label htmlFor='exampleFormControlInput1' className='required form-label'>
                    City
                  </label>
                  <select
                    name='city'
                    className='form-select form-select-solid'
                    value={formData?.city}
                    onChange={handleChnage}
                  >
                    <option disabled selected>
                      Select
                    </option>
                    <option value='Dubai'>Dubai</option>
                    <option value='Abu Dhabi'>Abu Dhabi</option>
                    <option value='Sharjah'>Sharjah</option>
                    <option value='Al Ain'>Al Ain</option>
                  </select>
                </div>
                <div className='mb-10'>
                  <label htmlFor='exampleFormControlInput1' className='required form-label'>
                    Area
                  </label>
                  <select
                    name='area'
                    className='form-select form-select-solid'
                    value={formData?.area}
                    onChange={handleChnage}
                  >
                    <option disabled selected>
                      Select
                    </option>
                    <option value='Al Bastakiya'>Al Bastakiya </option>
                    <option value='Al Karama'>Al Karama</option>
                    <option value='Bur Dubai'>Bur Dubai</option>
                    <option value='Business Bay'>Business Bay</option>
                  </select>
                </div>
                <div className='mb-10'>
                  <label htmlFor='exampleFormControlInput1' className='required form-label'>
                    Street
                  </label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder='e.g.-101,L.H.Road'
                    name='street'
                    value={formData?.street}
                    onChange={handleChnage}
                  />
                </div>
                <div className='mb-10'>
                  <label htmlFor='exampleFormControlInput1' className='required form-label'>
                    Community Manager
                  </label>
                  <select
                    name='managerId'
                    className='form-select form-select-solid'
                    value={formData?.managerId}
                    onChange={handleChnage}
                  >
                    <option disabled selected>
                      Select
                    </option>
                    <option value='John'>John</option>
                    <option value='Max'>Max</option>
                    <option value='Root'>Root</option>
                    {/* <option value='Al Ain'>Al Ain</option> */}
                  </select>
                </div>
              </div>
            )}
            {/* end::Stepper */}
          </div>
          <div className='modal-footer'>
            <div className='flex-row-fluid '>
              <div className='d-flex justify-content-end '>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={handleClose}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1 text-green'
                    />{' '}
                    Cancel
                  </button>
                </div>
                <div>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary btn-green'
                    // onClick={handleSubmit}
                  >
                    Submit{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    />
                  </button>
                </div>
              </div>
              {/*end::Form */}
            </div>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default AddBuilding
