import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ApiDelete, ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import AddArea from './AddArea'
import swal from 'sweetalert2'
import Card1 from './card'
import {DndProvider} from 'react-dnd'
import {HTML5Backend} from 'react-dnd-html5-backend'
import {setUserInfo} from '../../../apiCommon/utils/user.util'
import update from 'immutability-helper'
import rightarrow from '../../../img/rightArrow.svg'
import {Col, Row} from 'react-bootstrap'

const style = {
  width: '100 %',
}
const Area = () => {
  const [showCreateAppModal, setShowCreateAppModal] = useState(false)
  const [tableData, setTableData] = useState([])
  const [updateDataId, setUpdateDataId] = useState('')
  const [info, setInfo] = useState([])
  console.log('info', info)
  console.log('🚀 ~ file: Building.tsx ~ line 10 ~ Building ~ updateDataId', updateDataId)
  const [isEdit, setIsEdit] = useState(false)
  console.log('🚀 ~ file: Building.tsx ~ line 12 ~ Building ~ isEdit', isEdit)
  const [formData, setFormData] = useState({
    propertyId: window.location.pathname?.split('/')[2],
  })
  console.log('formData', formData)

  const handleChnage = (e) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }

  const building = async () => {
    const body = {
      // propertyId: window.location.pathname?.split('/')[2],
      // isBuilding: false,
      page: 1,
      limit: 1000,
      buildingId: window.location.pathname?.split('/')[2],
    }
    await ApiPost(`corporate/floor/get`, body)
      .then((res) => {
        setTableData(res?.data?.data?.floor_data)
        console.log('res', res)
      })
      .catch((e) => {
        console.log(e)
      })
  }
  const moveCard = (dragIndex, hoverIndex) => {
    console.log(dragIndex, hoverIndex)
    const dragCard = tableData[dragIndex]
    console.log(dragCard, 'ccd')
    console.log(dragIndex, 'cxv')
    console.log(hoverIndex, 'cxvcxv')
    const body = {
      floorId: dragCard._id,
      index: hoverIndex + 1,
    }
    ApiPost('corporate/floor/set_sequence', body)
      .then((res) => {
        console.log(res)

        setTableData(
          update(tableData, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragCard],
            ],
          })
        )
      })
      .catch((err) => {
        console.log('Error')
      })
  }
  const deleteProperty = (id) => {
    console.log('deleteProperty', id)
    ApiDelete(`corporate/floor/${id}`)
      .then((res) => {
        SuccessToast(res?.data?.message)
        building()
        getArea()
      })
      .catch((err) => ErrorToast(err.message))
  }
  const handleSubmit = () => {
    if (isEdit) {
      delete formData._id
      delete formData.updatedBy
      delete formData.status
      // const body = {...formData, managerId: '6329ee43396e812bcc0964e5', id: updateDataId}
      const body = {
        id: updateDataId,
        name: formData?.name,
        totalProperties: formData?.totalProperties,
        managerId: '6329ee43396e812bcc0964e5',
        buildingId: formData?.propertyId,
      }
      ApiPut('corporate/floor', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building()
          getArea()
          setIsEdit(false)
          console.log('addProperty', res)
          setShowCreateAppModal(false)
          // setFormData({propertyId: window.location.pathname?.split('/')[2]})
        })
        .catch((err) => ErrorToast(err.message))
    } else {
      // const body = {...formData, managerId: '6329ee43396e812bcc0964e5'}
      const body = {
        name: formData?.name,
        areaType: 'floor',
        managerId: 'John',
        buildingId: formData?.propertyId,
      }
      ApiPost('corporate/floor', body)
        .then((res) => {
          SuccessToast(res?.data?.message)
          building()
          getArea()
          console.log('addProperty', res)
          setShowCreateAppModal(false)
          // setFormData({propertyId: window.location.pathname?.split('/')[2]})
        })
        .catch((err) => ErrorToast(err.message))
    }
  }
  const getArea = () => {
    const id = window.location.pathname?.split('/')[2]
    ApiGet(`corporate/building/${id}`)
      .then((res) => {
        console.log('res', res?.data?.data)
        setInfo(res?.data?.data)
      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    building()
    getArea()

    if (isEdit) {
      ApiGet(`corporate/floor/${updateDataId}`)
        .then((response) => {
          console.log('response', response)
          setFormData(response?.data?.data)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [isEdit, formData])

  const navigate = useNavigate()
  return (
    <>
      <div
        className='app-container container-xxl mx-auto d-flex gap-5 flex-column p-0 m-0'
        id='kt_app_main'
      >
        <div className=''>
          <ul className='d-flex m-0 p-0 gap-2 lists align-items-center'>
            <li className=''>
              <a className='gray_color px-2 cursor-pointer ' onClick={() => navigate('/dashboard')}>
                Manage Properties
              </a>
            </li>
            <li className='my-auto'>
              <img src={rightarrow} />
            </li>
            <li className=''>
              <a className='gray_color px-2 cursor-pointer' onClick={() => navigate('/building')}>
                Building
              </a>
            </li>
            <li className='my-auto'>
              <img src={rightarrow} />
            </li>
            {/* <li className=''>
              <a
                className='text-muted px-2 cursor-pointer text-uppercase'
                // onClick={() => navigate('/building')}
              >
                Clusters
              </a>
            </li> */}
            {/* <li className=''>
              <a className='text-muted px-2 cursor-pointer'>Buildings</a>
            </li> */}
            <li className='active'>
              <a className='green_color' style={{borderBottom: '1px solid #007a59'}}>
                Floor
              </a>
            </li>
          </ul>
        </div>
        <div className='card p-5 flex-row justify-content-between'>
          <h1 className='m-0 green_color'>Cluster</h1>
          <a
            onClick={() => setShowCreateAppModal(true)}
            className='btn btn-sm fw-bold btn-primary btn-green'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_create_app'
          >
            Add Floor
          </a>
        </div>
        <div className='card p-5 text-muted'>
          <div className='side px-3'>
            <h6 className='mx-0 text-muted'>
              Building Name <div className='mx-1 value_black'>{info?.name}</div>
            </h6>
            <h6 className='mx-0 text-muted'>
              Total Floor <div className='mx-1 value_black'>{info?.totalFloors}</div>
            </h6>
            <h6 className='mx-0 text-muted'>
              Total Properties <div className='mx-1 value_black'>{info?.totalProperties}</div>
            </h6>
            <h6 className='mx-0 text-muted'>
              Community Manager <div className='mx-1 value_black'>{info?.managerId}</div>
            </h6>
          </div>
          <div className='side'>
            <h6 className='mx-0 text-muted'>
              City <div className='mx-1 value_black'>{info?.city || '--'}</div>
            </h6>
            <h6 className='mx-0 text-muted'>
              Area <div className='mx-1 value_black'>{info?.area || '--'}</div>
            </h6>
            <h6 className='mx-0 text-muted'>
              Street <div className='mx-1 value_black'>{info?.street || '--'}</div>
            </h6>
          </div>
        </div>
        <div className='card p-5'>
          <div className='table-responsive mb-5 px-3'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='text-start min-w-150px'>#</th>
                  <th className='text-start min-w-150px'>FLOORS NAME </th>
                  <th className='text-start min-w-150px'>TOTAL PROPERTIES</th>
                  <th className='text-start min-w-100px'></th>
                  <th className='text-start min-w-100px'></th>
                </tr>
              </thead>

              <tbody className='fw-semibold text-gray-600'>
                {tableData?.map((v) => {
                  return (
                    <tr style={{height: '50px'}}>
                    <td className='text-start'>{v?.id}</td>

                      <td data-kt-ecommerce-order-filter='order_id'>{v?.name}</td>
                      <td className='text-start'>{v?.totalProperties}</td>
                      <td className='text-start'>
                        <a
                          onClick={() =>
                            navigate(`/units/${window.location.pathname?.split('/')[2]}/${v?._id}`)
                          }
                          className='btn btn-sm fw-bold btn-primary btn-green'
                        >
                          Units
                        </a>
                      </td>

                      <td className='text-start'>
                        <a
                          className='btn btn-sm btn-light btn-active-light-primary'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                        >
                          Actions
                          <span className='svg-icon svg-icon-5 m-0'>
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z'
                                fill='currentColor'
                              />
                            </svg>
                          </span>
                        </a>
                        <div
                          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4'
                          data-kt-menu='true'
                        >
                          <div className='menu-item px-3'>
                            <a
                              onClick={() => {
                                console.log('clicked')

                                setUpdateDataId(v?._id)
                                setIsEdit(true)
                                setShowCreateAppModal(true)
                              }}
                              className='menu-link px-3'
                            >
                              Edit
                            </a>
                          </div>
                          <div className='menu-item px-3'>
                            <a
                              onClick={() => {
                                swal
                                  .fire({
                                    text: ' Are you sure you want to permanently delete this property?  Deleting this property will delete all the data belonging to the property.',
                                    icon: 'warning',
                                    showConfirmButton: true,
                                    confirmButtonColor: '#D72852',
                                    confirmButtonText: 'Yes',
                                    showCancelButton: true,
                                    cancelButtonText: 'Cancel',
                                  })
                                  .then((res) => {
                                    if (res.isConfirmed) {
                                      deleteProperty(v?._id)
                                    }
                                  })
                              }}
                              className='menu-link px-3'
                              data-kt-ecommerce-order-filter='delete_row'
                            >
                              Delete
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody> 
            </table>
            {/* <DndProvider backend={HTML5Backend}>
              <div lg='10' md='10' sm='10' style={style}>
                {tableData.map((card, i) => (
                  <Card1
                    key={card._id}
                    index={i}
                    id={card._id}
                    text={card.name}
                    text1={card.totalProperties}
                    path={card.image}
                    moveCard={moveCard}
                    building={building}
                  />
                ))}
              </div>
            </DndProvider> */}
          </div>
        </div>
      </div>

      <AddArea
        show={showCreateAppModal}
        handleClose={() => setShowCreateAppModal(false)}
        building={building}
        updateDataId={updateDataId}
        isEdit={isEdit}
        formData={formData}
        handleSubmit={handleSubmit}
        handleChnage={handleChnage}
      />
    </>
  )
}

export default Area
