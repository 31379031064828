import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import noData from '../../../img/NoData1.svg'
import {setChatScreen, setChatSelectedDataList} from '../../../redux/counterSlice'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'

function Tenant() {
  const dispatch = useDispatch()
  const data = useSelector((state: any) => state.counterReducer.chatDataSearch)

  const [selectedDataListTenant, setSelectedDataListTenant] = useState<any>([])

  const goToNewMessage = async () => {
    dispatch(setChatScreen(2))
    let body: any = {
      tenantIds: selectedDataListTenant,
      staffIds: [],
    }
    if (selectedDataListTenant.length == 1) {
      body['type'] = 0
    } else if (selectedDataListTenant.length > 1) {
      body['type'] = 1
    }
    console.log('\nbody', body)
    dispatch(setChatSelectedDataList(body))
  }

  const makeGroup = (e: any, i: any) => {
    let id: any = data[i]?._id

    if (e.target.checked) {
      if (!selectedDataListTenant.includes(id)) {
        setSelectedDataListTenant((prev: any) => [...prev, id])
      }
    } else {
      setSelectedDataListTenant(selectedDataListTenant.filter((e: any) => e !== id))
    }
  }

  const makeGroupOfTenants = (e: any, i: any, chck: any) => {
    if (chck) {
      if (selectedDataListTenant.find((e: any) => e._id === i.tenant?.[0]?._id) == undefined) {
        setSelectedDataListTenant([i])
      }
    } else {
      setSelectedDataListTenant(selectedDataListTenant.filter((e: any) => e !== i))
    }
  }

  const [radioChecked, setRadioChecked] = useState<any>()
  return (
    <>
      <table
        className='table align-middle table-row-dashed fs-6 gy-5'
        id='kt_ecommerce_sales_table'
      >
        <thead>
          <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
            <th className=' min-w-30px'>#</th>
            <th className=' min-w-100px'>Name</th>
            <th className=' min-w-100px'>Role</th>
            <th className=' min-w-75px'>Unit No.</th>
            <th className=' min-w-100px'>Lease Status</th>
          </tr>
        </thead>
        <tbody className='fw-semibold head-text'>
          {data.length ? (
            data.map((v: any, i: any) => (
              <tr>
                <>
                  <td data-kt-ecommerce-order-filter='order_id ' className='test ps-0'>
                    {radioChecked === i ? (
                      <div className='me-5 cursor-pointer green-radio-filled'></div>
                    ) : (
                      <div
                        onClick={(e: any) => {
                          setRadioChecked(i)
                          makeGroupOfTenants(e, v, true)
                        }}
                        className='me-5 cursor-pointer green-radio-hollow'
                      ></div>
                    )}
                  </td>
                  <td className='' data-order='2022-09-11'>
                    {v?.tenant?.[0]?.firstName || v?.tenant?.[0]?.lastName ? (
                      <>
                        {v?.tenant?.[0]?.firstName} {v?.tenant?.[0]?.lastName}{' '}
                      </>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td className='' data-order='2022-09-11'>
                    {v?.tenant?.[0]?.role ? v?.tenant?.[0]?.role : '-'}
                  </td>
                  <td className='' data-order='2022-09-11'>
                    {v?.unit?.length > 0 ? v?.unit?.[0]?.unitNo : '-'}
                  </td>
                  <td>
                    <div
                      className={
                        v?.tenancyStatus == 0
                          ? 'status booked-bar me-2 my-2'
                          : v?.tenancyStatus == 1
                          ? 'status active-bar me-2 my-2'
                          : v?.tenancyStatus == 2
                          ? 'status expiring-bar me-2 my-2'
                          : v?.tenancyStatus == 3
                          ? 'status expired-bar me-2 my-2'
                          : 'status  no-st-bar me-2 my-2'
                      }
                    >
                      {v?.tenancyStatus == 0
                        ? 'Booked'
                        : v?.tenancyStatus == 1
                        ? 'Active'
                        : v?.tenancyStatus == 2
                        ? 'Expiring'
                        : v?.tenancyStatus == 3
                        ? 'Expired'
                        : '-'}
                    </div>
                  </td>
                </>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={10} className='text-center'>
                <img src={noData} alt='' width={250} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <div className='d-flex align-items-center justify-content-end'>
        <a
          className='btn btn-sm fw-bold btn-primary btn-green m-0'
          onClick={() => goToNewMessage()}
        >
          Done
        </a>
      </div>
    </>
  )
}

export default Tenant
