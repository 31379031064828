import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import ReactApexChart from 'react-apexcharts'
import {ApexOptions} from 'apexcharts'

interface IncomeVsExpanseChartProps {
  chartData: Series[]
}

interface DataPoint {
  x: string
  y: number
  amount: number
}

interface Series {
  name: string
  data: DataPoint[]
}

const IncomeExpenseBreakdownChartMonthly: React.FC<IncomeVsExpanseChartProps> = ({chartData}) => {
    const [series, setSeries] = useState<Series[]>([
      {
        name: 'Income',
        data: [
          {x: '2024-05-01T00:00:00Z', y: 60, amount: 30},
          {x: '2024-05-02T00:00:00Z', y: 40, amount: 20},
          {x: '2024-05-03T00:00:00Z', y: 10, amount: 10},
          {x: '2024-05-04T00:00:00Z', y: 40, amount: 40},
          {x: '2024-05-05T00:00:00Z', y: 10, amount: 10},
          {x: '2024-05-06T00:00:00Z', y: 40, amount: 40},
          {x: '2024-05-07T00:00:00Z', y: 50, amount: 50},
          {x: '2024-05-08T00:00:00Z', y: 20, amount: 20},
          {x: '2024-05-09T00:00:00Z', y: 10, amount: 10},
          {x: '2024-05-10T00:00:00Z', y: 40, amount: 40},
        ],
      },
      {
        name: 'Expense',
        data: [
          {x: '2024-05-01T00:00:00Z', y: -40, amount: 30},
          {x: '2024-05-02T00:00:00Z', y: -60, amount: 20},
          {x: '2024-05-03T00:00:00Z', y: -10, amount: 10},
          {x: '2024-05-04T00:00:00Z', y: -40, amount: 40},
          {x: '2024-05-05T00:00:00Z', y: -35, amount: 10},
          {x: '2024-05-06T00:00:00Z', y: -45, amount: 40},
          {x: '2024-05-07T00:00:00Z', y: -88, amount: 50},
          {x: '2024-05-08T00:00:00Z', y: -10, amount: -20},
          {x: '2024-05-09T00:00:00Z', y: 0, amount: -10},
          {x: '2024-05-10T00:00:00Z', y: 0, amount: -40},
        ],
      },
    ])

  // const [series, setSeries] = useState<any>(chartData?.length > 0 ? chartData : [])
  // console.log(chartData)

  const [refresh, setRefresh] = useState<any>(new Date())

  const maxPercentage = chartData?.reduce((max: number, series: Series) => {
    const maxSeriesPercentage = Math?.max(...series?.data?.map((dataPoint: DataPoint) => Math?.abs(dataPoint?.y)))
    return Math?.max(max, maxSeriesPercentage)
  }, 0)

  useEffect(() => {

    console.log("INCOME EXPENSE BREAKDOWN")
    console.log(chartData)
    setSeries(chartData)

    
   
  }, [chartData])

  

  const options: ApexOptions = {
    chart: {
      type: 'area',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
      },
      selection: {
        enabled: true,
      },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    colors: ['#0d9e91', '#D54645'],
    title: {
      text: 'Income vs Expenses Breakdown',
      align: 'left',
      style: {
        fontSize: '14px',
      },
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      // tickAmount: 5,
      floating: false,
      min:0, 
      max:  (Math.ceil(maxPercentage / 10) * 10), //102%
      labels: {
        style: {
          colors: '#666',
        },
        formatter: (val: number) => {
          return `${val.toFixed(0)}%`
        },
        offsetY: -7,
        offsetX: 0,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
     
     
    },
    legend: {
      show: false,
    },
    fill: {
      opacity: 0.5,
    },
    tooltip: {
      x: {
        format: 'yyyy',
      },
      fixed: {
        enabled: false,
        position: 'topRight',
      },
      custom: function ({series, seriesIndex, dataPointIndex, w}) {
        const incomeAmount = w.config.series[0].data[dataPointIndex].amount
        const expenseAmount = w.config.series[1].data[dataPointIndex].amount
        const incomePercentage = series[0][dataPointIndex]
        const expensePercentage = series[1][dataPointIndex]

        return `
          <div class="apexcharts-tooltip-custom">
            <div>
              <span style="color: ${w.globals.colors[0]}">&#9679;</span>
              <span>Income: AED ${incomeAmount?.toLocaleString(
                'en-US'
              )} (${incomePercentage}%)</span>
            </div>
            <div>
              <span style="color: ${w.globals.colors[1]}">&#9679;</span>
              <span>Expense: AED ${expenseAmount?.toLocaleString(
                'en-US'
              )} (${expensePercentage}%)</span>
            </div>
          </div>
        `
      },
    },
    grid: {
      show: false,
      yaxis: {
        lines: {
          // offsetX: -30,
        },
      },
      padding: {
        left: 20,
      },
    },
  }

  const styles = `
  .apexcharts-tooltip-custom {
    padding: 10px 10px 10px 5px;
    background: #fff;
    border: 1px solid #ccc;
    min-width:220px !important;
    width: fit-content !important;
  }
  .apexcharts-tooltip-custom div {
    color: #666;
  }
`
  const styleSheet = document.createElement('style')
  styleSheet.innerText = styles
  document.head.appendChild(styleSheet)

  return (
    // <div className='row'>
    //   <div className='col-12'>
        <div className='card card-flush py-4 px-2 h-100 min-h-410'>
          <div id='chart'>
            {/* {series && ( */}
              <ReactApexChart options={options} series={series ? series : []} type='area' height={350} />
              {/* )}   */}
          </div>
        </div>
    //   </div>
    // </div>
  )
}

export default IncomeExpenseBreakdownChartMonthly
