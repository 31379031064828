import React from 'react'
import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  show: any
  handleClose: any
  showMediaType?: any
  propertyList: any
}

function MediaComments({show, handleClose, showMediaType, propertyList}: ButtonProps) {
  const handleChnage = () => {}
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={() => {
        handleClose()
      }}
      backdrop={true}
      size='sm'
    >
      <div className='modal-header'>
        <h2>Unit # </h2>
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1 text-black' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <div className='d-flex justify-content-center'>
          {showMediaType === 'view-request' ? (
            <button className='btn btn-sm fw-bold btn-primary btn-green mx-3'>Submit</button>
          ) : (
            <button className='btn btn-sm fw-bold btn-primary btn-green mx-3'>Update</button>
          )}
        </div>
        <div className='media-content mb-3'>
          <p className='mb-0'>Media</p>
          <div className='media-image'>
            <button className='btn btn-sm fw-bold btn-primary btn-green me-3 mb-3'>Image</button>
            <button className='btn btn-sm fw-bold btn-primary btn-green me-3 mb-3'>Image</button>
            <button className='btn btn-sm fw-bold btn-primary btn-green me-3 mb-3'>Video</button>
            <button className='btn btn-sm fw-bold btn-primary btn-green me-3 mb-3'>Video</button>
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <button className='btn btn-sm fw-bold btn-primary btn-green mx-3'>Upload</button>
        </div>
        <div className='comment-box'>
          <h6 className='text-muted m-0 mr-2 min-w-100px mb-2'>Comments</h6>
          <textarea
            className='form-control form-control-solid mb-8'
            rows={3}
            name='comment'
            onChange={handleChnage}
            placeholder='Enter Comment'
          ></textarea>
        </div>
        <p className='userName mb-0'>
          <b>USERNAME - DD.MM.YYYY-HH:MM</b>
        </p>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam, similique!</p>
      </div>
    </Modal>
  )
}

export default MediaComments
