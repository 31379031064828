import React, {useEffect, useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import {CustomValueRenderer} from '../../../../../../../../../Utilities/utils'
import sync from '../../../../../../../../../img/refreshIcon.png'
import editIcon from '../../../../../../../../../img/edit-pen.png'
import removeIcon from '../../../../../../../../../img/x-mark.png'
import SelectUnits from './SelectUnits'
import DropdownFilter from './DropdownFilter'
import {ApiPost} from '../../../../../../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../../../../../../../apiCommon/helpers/Toast'
import Loader from './Loader'
import { useParams } from 'react-router-dom'

interface PropertySettingsProps {
  chrgData?: any
  setChrgData: (data: any) => void
  setDevelopmentConfiguration: (data: any) => void
}


const PropertySettings = ({chrgData, setChrgData, setDevelopmentConfiguration}: PropertySettingsProps) => {
  const {flg, leasingType} = useParams()
  const [developments, setDevelopments] = useState<any>([])
  const [selectedDevelopments, setSelectedDevelopments] = useState<any>([])

  const [furnishTypesBackup, setFurnishTypesBackup] = useState<any>([
    {label: 'UnFurnished', value: 0},
    {label: 'Furnished', value: 1},
  ])
  const [furnishTypes, setFurnishTypes] = useState<any>([])
  const [selectedFurnishTypes, setSelectedFurnishTypes] = useState<any>([])

  const [unitTypesBackup, setUnitTypesBackup] = useState<any>([
    {label: 'Apartment', value: 0},
    {label: 'Penthouse', value: 1},
    {label: 'Common Area', value: 2},
    {label: 'Townhouse', value: 3},
    {label: 'Villa', value: 4},
    {label: 'Other', value: 5},
    {label: 'Office', value: 6},
    {label: 'Restaurant', value: 7},
    {label: 'Cafe', value: 8},
    {label: 'Retail', value: 9},
  ])
  const [unitTypes, setUnitTypes] = useState<any>([])
  const [selectedUnitTypes, setSelectedUnitTypes] = useState<any>([])

  const [bedroomsBackup, setBedroomsBackup] = useState<any>([
    {value: 1, label: '1 BR'},
    {value: 2, label: '2 BR'},
    {value: 3, label: '3 BR'},
    {value: 4, label: '4 BR'},
    {value: 5, label: '5 BR'},
    {value: 6, label: '6 BR'},
    {value: 7, label: '7 BR'},
    {value: 8, label: '8 BR'},
    {value: 9, label: '9 BR'},
    {value: 10, label: '10 BR'},
  ])
  const [bedrooms, setBedrooms] = useState<any>([])

  const [unitTypesString, setUnitTypesString] =  useState<any>([])

  const [selectedBedrooms, setSelectedBedrooms] = useState<any>([])

  const [totalUnits, setTotalUnits] = useState<any>(0)
  const [tableData, setTableData] = useState<any>([])

  const [showUnitsModal, setShowUnitsModal] = useState<any>(false)

  const [refreshFilters, setRefreshFilters] = useState<any>(false)
  const [selectedFilter, setSelectedFilter] = useState<any>('no-filter')
  const [disableFiltersSection, setDisableFiltersSection] = useState<any>(false)

  const [selectedDevelopmentDetails, setSelectedDevelopmentDetails] = useState<any>()
  const [selectedDevelopmentInd, setSelectedDevelopmentInd] = useState<any>(-1)

  const [open, setOpen] = useState<any>(false)

  // 
  const getDropdownFilters = () => {
    let commIds: any = []
    let buildIds: any = []

    let clusterIds: any = []
    let unitGroupIds: any = []

    let buildingIds: any = []

    selectedDevelopments?.map((sl: any) => {
      // console.log(sl)
      if (sl?.type == 'Community') {
        commIds?.push(sl?.id)
        clusterIds?.push(sl?.cluster)
        if (sl?.unitGroup != null && sl?.unitGroup != undefined) unitGroupIds?.push(sl?.unitGroup)
        if (sl?.building != null && sl?.building != undefined) buildingIds?.push(sl?.building)
      } else if (sl?.type == 'Building') buildIds?.push(sl?.id)
    })

    const uniqueCommIds = Array.from(new Set(commIds));
    const uniqueClusterIds = Array.from(new Set(clusterIds));
    const uniqueUnitGroupIds = Array.from(new Set(unitGroupIds));
    const uniqueBuildingIds = Array.from(new Set(buildingIds));
    const uniqueBuildIds = Array.from(new Set(buildIds));

    console.log(uniqueCommIds, uniqueClusterIds, uniqueUnitGroupIds, uniqueBuildingIds, uniqueBuildIds);

    let fTypes: any = []
    selectedFurnishTypes?.map((sl: any) => {
      fTypes?.push(sl?.value)
    })

    let uTypes: any = []
    selectedUnitTypes?.map((sl: any) => {
      uTypes?.push(sl?.value)
    })

    let rooms: any = []
    selectedBedrooms?.map((sl: any) => {
      rooms?.push(sl?.value)
    })

    const body =
      // selectedDevelopments?.length > 0 || selectedFilter != 'dev'
        // ? 
        {
            filters: {
              showDevelopments: selectedFilter == 'no-filter' ? true : false,
              buildingDevelopmentIds:  selectedFilter != 'no-filter' ? uniqueBuildIds : undefined,
              communityIds: selectedFilter != 'no-filter' ? uniqueCommIds : undefined,
              unitGroupIds: selectedFilter != 'no-filter' ? uniqueUnitGroupIds : undefined,
              clusterIds: selectedFilter != 'no-filter' ? uniqueClusterIds : undefined,
              buildingIds: selectedFilter != 'no-filter' ? uniqueBuildingIds : undefined,
              furnishingTypes: (selectedFilter != 'no-filter' && selectedFilter != 'dev')  ? fTypes : undefined,
              unitTypeStrings: (selectedFilter != 'no-filter' && selectedFilter != 'dev') ? uTypes : undefined,
              bedrooms: (selectedFilter != 'no-filter' && selectedFilter != 'dev') ? rooms : undefined,
              propertyTypes: leasingType == 'residential-leasing' ? [0] :  leasingType == 'commercial-leasing' ? [1] : []
            },
            // filters: {
            //   showDevelopments: selectedFilter == 'no-filter' ? true : false,
            //   buildingDevelopmentIds: selectedFilter == 'dev' ? buildIds : [],
            //   communityIds: selectedFilter == 'dev' ? commIds : [],
            //   unitGroupIds: selectedFilter == 'dev' ? unitGroupIds : [],
            //   clusterIds: selectedFilter == 'dev' ? clusterIds : [],
            //   buildingIds: selectedFilter == 'dev' ? buildingIds : [],
            //   furnishingTypes: selectedFilter == 'furnish' ? fTypes : [],
            //   unitTypes: selectedFilter == 'unit' ? uTypes : [],
            //   bedrooms: selectedFilter == 'bedroom' ? rooms : [],
            //   propertyTypes: [0,1]
            // },
          }
        // : {}

    ApiPost(`corporate/lease_fee_configuration/filter`, body)
      .then((res: any) => {
        // Developments
        let devs: any = []
        
        if(selectedFilter == 'no-filter'){
          res?.data?.data?.developments?.map((dev: any) => {
            devs?.push({
              label: `${dev?.name}${dev?.isCommunity ? ` - ${dev.cluster?.name}` : ''}${
                dev?.isCommunity && dev?.cluster?.type == 1 ? ` - ${dev?.building?.name}` : ''
              }${
                dev?.isCommunity && dev?.cluster?.type == 2 && dev.hasOwnProperty('unitGroup')
                  ? ` - ${dev?.unitGroup?.name}`
                  : ''
              }${
                dev?.isCommunity && dev?.cluster?.type == 2 && dev.hasOwnProperty('building')
                  ? ` - ${dev?.building?.name}`
                  : ''
              }`,
              value: `${dev?._id}${dev?.isCommunity ? `${dev.cluster?._id}` : ''}${
                dev?.isCommunity && dev?.cluster?.type == 1 ? `${dev?.building?._id}` : ''
              }${
                dev?.isCommunity && dev?.cluster?.type == 2 && dev.hasOwnProperty('unitGroup')
                  ? `${dev?.unitGroup?._id}`
                  : ''
              }${
                dev?.isCommunity && dev?.cluster?.type == 2 && dev.hasOwnProperty('building')
                  ? `${dev?.building?._id}`
                  : ''
              }`,
              id: dev?._id,
              type: dev?.isCommunity ? 'Community' : dev?.isBuilding ? 'Building' : '',
              isSelected: dev?.isBuilding && dev?.isSelected || 
                          (dev?.isCommunity && dev?.isSelected && dev?.cluster?.type == 0 && dev?.cluster?.isSelected)  ||
                          (dev?.isCommunity && dev?.isSelected && dev?.cluster?.type === 1 && dev?.building?.isSelected) ||
                          (dev?.isCommunity && dev?.isSelected && dev?.cluster?.type === 2 && (dev?.unitGroup?.isSelected || dev?.building?.isSelected)),
              cluster: dev?.isCommunity ? dev?.cluster?._id : null,
              unitGroup:
                dev?.isCommunity && dev.hasOwnProperty('unitGroup') ? dev?.unitGroup?._id : null,
              building:
                dev?.isCommunity && dev.hasOwnProperty('building') ? dev?.building?._id : null,
            })
          })

          setDevelopments(devs)
        }

        const selectedDevs = devs.filter((dev: any) => dev.isSelected)

        // setSelectedDevelopments(selectedDevs)

        // Furnishing Types
        const backendFurnishTypes = res?.data?.data?.furnishingTypes || []
        const formattedFurnishTypes = furnishTypesBackup.map((type: any) => ({
          ...type,
          isSelected: backendFurnishTypes.includes(type.value),
        }))
       
        if(selectedFilter == 'no-filter'){
          const furnishTypesList = formattedFurnishTypes.filter((type: any) => type.isSelected)
          const updatedSelectedFurnishType = selectedFurnishTypes.filter((item: any) =>
            furnishTypesList.some((type: any) => type.value === item.value)
          )

          setSelectedFurnishTypes(updatedSelectedFurnishType)
          setFurnishTypes(furnishTypesList)
        }

        if(selectedDevelopments?.length == 0)
          setSelectedFurnishTypes([])

        // const selectedFurnishTypes = formattedFurnishTypes.filter((type: any) => type.isSelected)
        // if (selectedDevs?.length > 0) {
        //   setSelectedFurnishTypes(selectedFurnishTypes)
        //   fTypes = selectedFurnishTypes.map((un: any) => un?.value)
        // } else {
        //   setSelectedFurnishTypes([])
        //   fTypes = []
        // }

        // if(refreshFilters == false) setFurnishTypes(selectedFurnishTypes)

        // Unit Types
        const backendUnitTypes = res?.data?.data?.unitTypes || []
        const formattedUnitTypes = unitTypesBackup.map((type: any) => ({
          ...type,
          isSelected: backendUnitTypes.includes(type.value),
        }))

        if(selectedFilter == 'no-filter'){
          const unitTypesList = formattedUnitTypes.filter((type: any) => type.isSelected)
           setUnitTypes(unitTypesList)
        }

        if(selectedDevelopments?.length == 0)
          setSelectedUnitTypes([])

        // const selectedUnitTypes = formattedUnitTypes.filter((type: any) => type.isSelected)
        // if (selectedDevs?.length > 0) {
        //   setSelectedUnitTypes(selectedUnitTypes)
        //   uTypes = selectedUnitTypes.map((un: any) => un?.value)
        // } else {
        //   setSelectedUnitTypes([])
        //   uTypes = []
        // }

        // if(refreshFilters == false) setUnitTypes(selectedUnitTypes)

        // Bedrooms
        const backendBedrooms = res?.data?.data?.bedrooms || []
        const formattedBedrooms = bedroomsBackup.map((type: any) => ({
          ...type,
          isSelected: backendBedrooms.includes(type.value),
        }))
        if(selectedFilter == 'no-filter'){
          const bedroomsList = formattedBedrooms.filter((type: any) => type.isSelected)

          const updatedSelectedRooms = selectedBedrooms.filter((item: any) =>
            bedroomsList.some((type: any) => type.value === item.value)
          )

          setSelectedBedrooms(updatedSelectedRooms)

          setBedrooms(bedroomsList)
        }

        let unitStringTypes:any = []

        if(selectedFilter == 'no-filter'){
        
        res?.data?.data?.unitTypeStrings?.map((un:any)=>{
          unitStringTypes?.push({
            value: un,
            label: un
          })

        })

        const updatedUnitTypeStrings = selectedUnitTypes.filter((item: any) =>
          unitStringTypes.some((type: any) => type.value === item.value)
        )

        setSelectedUnitTypes(updatedUnitTypeStrings)
    
        setUnitTypesString(unitStringTypes)
      }


        // const selectedBedrooms = formattedBedrooms.filter((type: any) => type.isSelected)
        // if (selectedDevs?.length > 0) {
        //   setSelectedBedrooms(selectedBedrooms)
        //   rooms = selectedBedrooms.map((un: any) => un?.value)
        // } else {
        //   setSelectedBedrooms([])
        //   rooms = []
        // }

        // if(refreshFilters == false) setBedrooms(selectedBedrooms)

        setTotalUnits(res?.data?.data?.matchingUnits)

        //   if(refreshFilters != false){

        // const body1 = {
        //   // buildingDevelopmentIds: buildIds,
        //   // communityIds: commIds,
        //   // unitGroupIds: unitGroupIds,
        //   // clusterIds: clusterIds,
        //   // buildingIds: buildingIds,
        //   // furnishingTypes: fTypes,
        //   // unitTypes: uTypes,
        //   // bedrooms: rooms,

        //   buildingDevelopmentIds: selectedFilter == 'dev' ? buildIds : [],
        //   communityIds: selectedFilter == 'dev' ? commIds : [],
        //   unitGroupIds: selectedFilter == 'dev' ? unitGroupIds : [],
        //   clusterIds: selectedFilter == 'dev' ? clusterIds : [],
        //   buildingIds: selectedFilter == 'dev' ? buildingIds : [],
        //   furnishingTypes: selectedFilter == 'furnish' ? fTypes : [],
        //   unitTypes: selectedFilter == 'unit' ? uTypes : [],
        //   bedrooms: selectedFilter == 'bedroom' ? rooms : [],
        // }

        // ApiPost(`corporate/lease_fee_configuration/get_unit`, body1)
        //   .then((res: any) => {
        //     if (selectedDevs?.length > 0) setTotalUnits(res?.data?.data?.length)
        //     else setTotalUnits(0)
        //   })
        //   .catch((err) => {
        //     ErrorToast(err.message)
        //     if (err?.message === 'Your account has been suspended!') {
        //       window.location.pathname = '/auth'
        //       localStorage.clear()
        //     }
        //   })
        // }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    // if (refreshFilters != false)
       getDropdownFilters()
  }, [refreshFilters])

  // 
  const getTablesData = async () => {
    setFilterModalValue('Adding')
    setOpen(true)
    try {
      let tblData: any[] = [...tableData]

      const promises = selectedDevelopments?.map(async (sl: any) => {
        let fTypes: any = []
        selectedFurnishTypes?.map((sl: any) => {
          fTypes?.push(sl?.value)
        })

        let uTypes: any = []
        selectedUnitTypes?.map((sl: any) => {
          uTypes?.push(sl?.value)
        })

        let rooms: any = []
        selectedBedrooms?.map((sl: any) => {
          rooms?.push(sl?.value)
        })

        const body = {
          buildingDevelopmentIds: sl?.type === 'Building' ? [sl?.id] : [],
          communityIds: sl?.type === 'Community' ? [sl?.id] : [],
          unitGroupIds: sl?.unitGroup != null ? [sl?.unitGroup] : [],
            // sl?.type === 'Community' && sl?.cluster?.type === 2 && sl.hasOwnProperty('unitGroup')
            //   ? [sl?.unitGroup]
            //   : [],
          buildingIds: sl?.building != null ? [sl?.building] : [],
            // sl?.type === 'Community' && sl?.cluster?.type === 1 && sl.hasOwnProperty('building')
            //   ? [sl?.building]
            //   : [],
          clusterIds: sl?.cluster != null ? [sl?.cluster] : [],
          furnishingTypes: fTypes,
          unitTypeStrings: uTypes,
          bedrooms: rooms,
          isGroupingId: false,
        }

        try {
          const res: any = await ApiPost(`corporate/lease_fee_configuration/get_unit`, body)
          return {
            developmentName: sl?.label,
            units: res?.data?.data.map((unit: any) => ({
              ...unit,
              isSelected: true,
            })),
            furnishingTypes: selectedFurnishTypes,
            furnishTypesOptions: furnishTypes,
            unitTypes: selectedUnitTypes,
            unitTypesOptions: unitTypesString,
            bedrooms: selectedBedrooms,
            bedroomsOptions: bedrooms,
            selectedUnits: res?.data?.data.map((unit: any) => unit?._id),
            isBuilding: sl?.type == 'Building' ? true : false,
            isCommunity: sl?.type == 'Community' ? true : false,
            id: sl?.id,
            unitGroupIds: sl?.unitGroup != null ? [sl?.unitGroup] : null,
            buildingIds:  sl?.building != null  ? [sl?.building] : null,
            clusterIds:  sl?.cluster != null  ? [sl?.cluster] : null,
            uniqueId: sl?.value
          }
        } catch (err: any) {
          ErrorToast(err.message)
          if (err?.message === 'Your account has been suspended!') {
            window.location.pathname = '/auth'
            localStorage.clear()
          }
          return null // Return null in case of an error for this iteration
        }
      })

      // Wait for all API calls to complete
      const results = await Promise.all(promises)

      // Filter out any null values (in case some API calls failed)
      let newTblData = results.filter((result) => result !== null)

      // Update state only after all API calls are complete
      // newTblData?.map((newItem:any, ind:any)=>{
      //   tblData?.push(newItem)
      // })

      newTblData?.forEach((newItem: any) => {
        const existingIndex = tblData.findIndex((item) => item.uniqueId === newItem.uniqueId);
        if (existingIndex > -1) {
          tblData[existingIndex] = newItem; // Replace existing item
        } else {
          tblData.push(newItem); // Add new item
        }
      });

      
      setTableData(tblData)
      setDevelopmentConfiguration(tblData)
      setDisableFiltersSection(true)

      setSelectedDevelopments([])
      setSelectedBedrooms([])
      setUnitTypes([])
      setSelectedFurnishTypes([])
      setOpen(false)

      setTotalUnits(0)
      
    } catch (err) {
      console.error('An error occurred while fetching table data:', err)
      setOpen(false)
    }
  }

  // 
  const updateSingleRecordInTableData = async (record: any) => {
    setFilterModalValue('Filtering')
    setOpen(true);
  
    try {
      let fTypes: any[] = [];
      record?.furnishingTypes?.forEach((sl: any) => {
        fTypes.push(sl?.value);
      });
  
      let uTypes: any[] = [];
      record?.unitTypes?.forEach((sl: any) => {
        uTypes.push(sl?.value);
      });
  
      let rooms: any[] = [];
      record?.bedrooms?.forEach((sl: any) => {
        rooms.push(sl?.value);
      });

      // console.log(record)
  
      const body = {
        buildingDevelopmentIds: record?.isBuilding ? [record?.id] : [],
        communityIds: record?.isCommunity ? [record?.id] : [],
        unitGroupIds: record?.unitGroupIds ?  record?.unitGroupIds : [],
        buildingIds: record?.buildingIds ?  record?.buildingIds : [],
        clusterIds: record?.clusterIds ? record?.clusterIds : [],
        furnishingTypes: fTypes,
        unitTypeStrings: uTypes,
        bedrooms: rooms,
        isGroupingId: false,
      };
  
      try {
        const res: any = await ApiPost(
          `corporate/lease_fee_configuration/get_unit`,
          body
        );

        // console.log(res?.data?.data)

        const newRecords = res?.data?.data || [];
        const existingRecords = record?.units || [];

        // Create updated records
        const updatedUnits = newRecords.map((newUnit: any) => {
          const existingUnit = existingRecords.find((u: any) => u._id === newUnit._id);

          return {
            ...newUnit,
            isSelected: existingUnit ? existingUnit.isSelected : false, // Retain `isSelected` if exists, else set false
         };
        });

        // console.log(updatedUnits)
  
        // Create updated record
        const updatedRecord = {
          developmentName: record?.developmentName,
          units: updatedUnits,
          furnishingTypes: record?.furnishingTypes,
          furnishTypesOptions: record?.furnishTypesOptions,
          unitTypes:record?.unitTypes,
          unitTypesOptions: record?.unitTypesOptions,
          bedrooms: record?.bedrooms,
          bedroomsOptions: record?.bedroomsOptions,
          selectedUnits: res?.data?.data.map((unit: any) => unit?._id),
          isBuilding: record?.isBuilding,
          isCommunity: record?.isCommunity,
          id: record?.id,
          unitGroupIds: record?.unitGroupIds,
          buildingIds:  record?.buildingIds,
          clusterIds:  record?.clusterIds,
          uniqueId: record?.uniqueId
          
        };
  
        // Update the tableData state
        setTableData((prevTableData: any[]) => {
          // Find the index of the record to update
          const index = prevTableData.findIndex((item) => item.uniqueId === record?.uniqueId);
  
          // Update the record if it exists
          if (index !== -1) {
            const newTableData = [...prevTableData];
            newTableData[index] = updatedRecord;
            return newTableData;
          }
  
          // If the record does not exist, return previous data
          return prevTableData;
        });
  
        // Optionally, update other states
        // setDevelopmentConfiguration((prevConfig: any[]) => {
        //   console.log(record.id)
     
        //   const index = prevConfig.findIndex((item) => item.id === record?.id);

        //   console.log(index)
        //   if (index !== -1) {
        //     const newConfig = [...prevConfig];
        //     newConfig[index] = updatedRecord;
        //     return newConfig;
        //   }
        //   return prevConfig;
        // });

        // let values: any = [...]
  
      } catch (err: any) {
        ErrorToast(err.message);
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth';
          localStorage.clear();
        }
      }
    } catch (err) {
      console.error('An error occurred while updating table data:', err);
    } finally {
      setOpen(false);
    }
  };

  React.useEffect(()=>{
    // console.log(chrgData)
    if(flg == '2')
      setTableData(chrgData?.developmentConfiguration)
  },[chrgData])

  const [filterModalValue, setFilterModalValue] = useState<any>('Adding')
  
  return (
    <>
      <Row className='lease-charges'>
        <Col md={12}>
          <div
            className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3'
            style={{minHeight: chrgData?.appliesToAllUnits == 0 ? '700px' : '500px'}}
          >
            <div className='card-body pt-0 mt-5 py-0 px-3'>
              <div className='d-flex'>
                <div>
                  <h3 className='page-heading m-0 white-dark-theme-color fnt-700'>
                    Property Settings
                  </h3>
                  <p className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>
                    Select the property portfolio you would like to apply this charge to. Based on
                    the portfolio selected this charge will automatically be applied in the lease
                    generated for the set properties.
                  </p>
                </div>
              </div>

              <div className='row mt-7'>
                <div className='col-6 col-md-4'>
                  <div className='d-flex align-items-start tst mb-4'>
                    <div style={{width: '30px', height: '30px'}}>
                      <input
                        type='radio'
                        className='me-3 cursor-pointer form-check-input'
                        id='allProperties'
                        value='allProperties'
                        checked={chrgData?.appliesToAllUnits == 1}
                        onChange={(e: any) => {
                          setChrgData(1)
                        }}
                      />
                    </div>
                    <div>
                      <h5 className='m-0'>
                        <label htmlFor='allProperties' className='fs-5 cursor-pointer'>
                          All Properties
                        </label>
                      </h5>
                      <p
                        className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'
                        style={{color: '#a4a4a4'}}
                      >
                        This charge will be applied to all annual leases created for all properties.
                      </p>
                    </div>
                  </div>
                </div>
                <div className='col-6 col-md-4'>
                  <div className='d-flex align-items-start tst mb-4'>
                    <div style={{width: '30px', height: '30px'}}>
                      <input
                        type='radio'
                        className='me-3 cursor-pointer form-check-input'
                        id='selectedProperties'
                        value='selectedProperties'
                        checked={chrgData?.appliesToAllUnits == 0}
                        onChange={(e: any) => {
                          setChrgData(0)

                          setRefreshFilters(new Date())
                        }}
                      />
                    </div>
                    <div>
                      <h5 className='m-0'>
                        <label htmlFor='selectedProperties' className='fs-5 cursor-pointer'>
                          Selected Properties
                        </label>
                      </h5>
                      <p
                        className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'
                        style={{color: '#a4a4a4'}}
                      >
                        This charge will be applied to annual leases for a selected portfolio of
                        properties.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {chrgData?.appliesToAllUnits == 0 && (
                <>
                  <div className='position-relative'>
                    {/* {(disableFiltersSection && tableData?.length != 0) && (
                      <div
                        className='tenantOpacityBox'
                        style={{zIndex: '1', opacity: '0.7', cursor: 'not-allowed'}}
                      ></div>
                    )} */}
                    <div className='card card-flush gap-5 p-3 cursor-pointerlight-gry-dark-shadow cursor-pointer lease-setting-table-card mt-4'>
                      <div className='card-body p-3'>
                        {/* Filters */}
                        <div className='row mt-1 mb-3'>
                          {/* Developments */}
                          <Col md={6} className='test property py-5 px-4 lease-charges-property' style={{backgroundColor: '#e9edf3', borderRadius: '5px', position: 'relative', zIndex: '3'}}>
                          
                            <DropdownFilter
                              title='Developments'
                              description='Select the developments this charge will apply to:'
                              options={developments}
                              selectedOptions={selectedDevelopments}
                              onChange={(e: any) => {
                                setSelectedDevelopments(e)
                                setSelectedFilter('dev')
                                setRefreshFilters(new Date())
                              }}
                            />
                            </Col>
                            <Col md={6}>

                             <div
                                className='filter-div d-flex align-items-center justify-content-center cursor-pointer ms-auto'
                                onClick={() => {
                                  setSelectedDevelopments([])
                                  setSelectedUnitTypes([])
                                  setSelectedFurnishTypes([])
                                  setSelectedBedrooms([])
                                  // setTableData([])
                                  // setDevelopmentConfiguration([])
                                  // setRefreshFilters(new Date())
                                  setDisableFiltersSection(false)
                                  setTotalUnits(0)
                                }}
                                style={{position:'relative', zIndex: '20'}}
                              >
                                <img src={sync} height={18} width={18} />
                              </div>
                           
                          </Col>

                        </div>

                        <div className='row my-7'>
                          <h3 className='m-0'>
                          <label htmlFor='selectedProperties' className=''>
                            Property Criteria
                          </label>
                          </h3>
                        </div>

                        <div className='position-relative'>
                          {(selectedDevelopments?.length == 0) && (
                            <div
                              className='tenantOpacityBox'
                              style={{zIndex: '1', opacity: '0.7', cursor: 'not-allowed'}}
                            ></div>
                          )}

                          <div className='row mt-3'>
                          {/* Furnishing Type */}
                          <Col md={4} className='test property lease-charges-property sub-filters'>
                            <DropdownFilter
                              title='Furnishing Type'
                              description='Select the furnishing type this charge will apply to:'
                              options={furnishTypes}
                              selectedOptions={selectedDevelopments?.length > 0 ? selectedFurnishTypes : []}
                              onChange={(e: any) => {
                                setSelectedFurnishTypes(e)
                                setSelectedFilter('furnish')
                                setRefreshFilters(new Date())
                              }}
                            />
                          </Col>

                          {/* Unit Type */}
                          <Col md={4} className='test property lease-charges-property sub-filters'>
                            <DropdownFilter
                              title='Unit Type'
                              description='Select the unit type this charge will apply to:'
                              options={unitTypesString}
                              selectedOptions={selectedDevelopments?.length > 0 ? selectedUnitTypes : []}
                              onChange={(e: any) => {
                                setSelectedUnitTypes(e)
                                setSelectedFilter('unit')
                                setRefreshFilters(new Date())
                              }}
                            />
                          </Col>

                          {/* Bedrooms */}
                          <Col md={4} className='test property lease-charges-property sub-filters'>
                            <div className='d-flex'>
                              <DropdownFilter
                                title='Bedrooms'
                                description='Select the bedroom this charge will apply to:'
                                options={bedrooms}
                                selectedOptions={selectedDevelopments?.length > 0 ? selectedBedrooms : []}
                                onChange={(e: any) => {
                                  setSelectedBedrooms(e)
                                  setSelectedFilter('bedroom')
                                  setRefreshFilters(new Date())
                                }}
                              />

                             
                            </div>
                          </Col>
                          </div>
                        </div>

                        <div className='d-flex align-items-end mt-8'>
                          <div>
                            <h3 className='text-dark fs-4 fnt-700 mb-1'> Units</h3>
                            <p className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-3 light-dark-theme-color'>
                              Units that this charge will apply to when a booking or lease is
                              created:{' '}
                              <span className='ms-5 text-dark'>
                                <b>{(totalUnits > 0 && selectedDevelopments?.length > 0 ) ? totalUnits : '-'} Units</b>
                              </span>
                            </p>
                          </div>

                          <button
                            type='button'
                            className='btn btn-sm fw-bold green-submit-btn px-7 justify-content-center status-w-200 ms-auto'
                            onClick={() => {
                              getTablesData()
                            }}
                            disabled={totalUnits == 0}
                          >
                            Add Properties
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  */}
                  {tableData?.length > 0 && (
                    <div className='table-responsive px-5 mt-10' style={{minHeight: '300px', maxHeight: '600px',overflow: 'auto' }}>
                      <table className='table align-middle gy-5 ' id='kt_ecommerce_sales_table'>
                        <thead className='fs-5 text-dark fnt-500'>
                          <th className='text-start min-w-450px ps-0 pe-10'>Development</th>
                          <th className='text-start min-w-225px pe-0'> Furnishing Type</th>
                          <th className='text-start min-w-225px ps-0'>Unit Type</th>
                          <th className='text-start min-w-225px ps-0'>Bedrooms</th>
                          <th className='text-start min-w-100px ps-5'>Units</th>
                          <th className='text-end min-w-75px pe-10'></th>
                        </thead>
                        <tbody className='fw-semibold head-text'>
                          {tableData?.map((tb: any, ind: any) => {
                            return (
                              <tr key={ind}>
                                <td className='text-start ps-0 pe-10'>
                                  <h3 className='text-dark fs-5 fnt-700 mb-1 ps-5'>
                                    {tb?.developmentName}
                                  </h3>
                                </td>
                                <td className='text-start ps-0 test'>
                                  <div className='test property'>
                                    <div
                                      className={`multi-select-container jobs-property ${
                                        tb?.furnishingTypes?.length === 0
                                          ? 'no-value select'
                                          : 'has-value'
                                      }`}
                                    >
                                      <MultiSelect
                                        options={tb?.furnishTypesOptions}
                                        value={tb?.furnishingTypes}
                                        onChange={(e: any) => {
                                          let values:any = [...tableData]
                                          values[ind].furnishingTypes = e
                                          setTableData(values)

                                          updateSingleRecordInTableData(tb)
                                        }}
                                        labelledBy='Furnishing Type'
                                        overrideStrings={{
                                          allItemsAreSelected: 'All Furnishing Types',
                                        }}
                                        valueRenderer={CustomValueRenderer}
                                        disableSearch
                                        onMenuToggle={()=>{}}
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td className='text-start ps-0 test'>
                                  <div className='test property'>
                                    <div
                                      className={`multi-select-container jobs-property ${
                                        tb?.unitTypes?.length === 0
                                          ? 'no-value select'
                                          : 'has-value'
                                      }`}
                                    >
                                      <MultiSelect
                                        options={tb?.unitTypesOptions}
                                        value={tb?.unitTypes}
                                        onChange={(e: any) => {
                                          let values:any = [...tableData]
                                          values[ind].unitTypes = e
                                          setTableData(values)

                                          updateSingleRecordInTableData(tb)
                                        }}
                                        labelledBy='Unit Type'
                                        overrideStrings={{
                                          allItemsAreSelected: 'All Unit Types',
                                        }}
                                        valueRenderer={CustomValueRenderer}
                                        disableSearch
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td className='text-start ps-0 test'>
                                  <div className='test property'>
                                    <div
                                      className={`multi-select-container jobs-property ${
                                        tb?.bedrooms?.length === 0
                                          ? 'no-value select'
                                          : 'has-value'
                                      }`}
                                    >
                                      <MultiSelect
                                        options={tb?.bedroomsOptions}
                                        value={tb?.bedrooms}
                                        onChange={(e: any) => {
                                          let values:any = [...tableData]
                                          values[ind].bedrooms = e
                                          setTableData(values)

                                          updateSingleRecordInTableData(tb)
                                        }}
                                        labelledBy='Bedrooms'
                                        overrideStrings={{
                                          allItemsAreSelected: 'All Bedrooms',
                                        }}
                                        valueRenderer={CustomValueRenderer}
                                        disableSearch
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td className='text-start ps-5 test'>
                                  {' '}
                                  <h3 className='text-dark fs-5 fnt-700 mb-1'>
                                    {tb?.selectedUnits?.length}
                                  </h3>
                                </td>
                                <td className='text-end ps-0 test pe-10'>
                                  <div className='d-flex align-items-center justify-content-end'>
                                    <img
                                      src={editIcon}
                                      height={17}
                                      width={17}
                                      className='me-7 cursor-pointer'
                                      onClick={() => {
                                        setSelectedDevelopmentInd(ind)
                                        setSelectedDevelopmentDetails(tb)
                                        setShowUnitsModal(true)
                                      }}
                                    />
                                    <img
                                      src={removeIcon}
                                      height={17}
                                      width={17}
                                      className='cursor-pointer'
                                      onClick={() => {
                                        let values = [...tableData]
                                        values.splice(ind, 1)
                                        setTableData(values)
                                        setDevelopmentConfiguration(values)

                                        if(values?.length == 0)
                                          setTotalUnits(0)
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>

      {/*  */}
      <SelectUnits
        show={showUnitsModal}
        handleClose={() => {
          setShowUnitsModal(false)
        }}
        updateUnits={(updatedDev: any) => {
          let values: any = [...tableData]
          values[selectedDevelopmentInd] = updatedDev

          let ids: any = []
          updatedDev?.units?.map((un: any) => {
            if (un?.isSelected) {
              ids?.push(un?._id)
            }
          })

          values[selectedDevelopmentInd].selectedUnits = ids

          setTableData(values)
          setDevelopmentConfiguration(values)
        }}
        unitsData={selectedDevelopmentDetails}
      />

        {/*  */}
       <Loader isVisible={open} mode={filterModalValue}/>
    </>
  )
}

export default PropertySettings
