import React from 'react'
import {useFormContext} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {Box, Typography, Grid} from '@mui/material'

interface ChargeSelectorProps {
  itemData?: any
  setItemData: (value: any) => void
}

const ChargeSelector: React.FC<ChargeSelectorProps> = ({itemData, setItemData}) => {
  const {
    control,
    register,
    setValue,
    formState: {errors},
    trigger,
    reset,
  } = useFormContext()

  const {flg} = useParams()
  return (
    <div>
      <div className='position-relative'>
        {flg == '2' && (
          <div className='tenantOpacityBox' style={{opacity: '0.7', cursor: 'not-allowed'}}></div>
        )}
        {/* <div className='row mt-7'>
          <div className='col-12'>
            <h4 className='page-heading m-0 white-dark-theme-color fnt-700 required'></h4>
            <p className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>
              Select a charge type:
            </p>
          </div>
        </div> */}

        <Grid container spacing={3} sx={{mt: 0, ml: 0, mr: 4}}>
          <Grid item xs={10} className='px-0'>
            <Typography variant='h6' className='required fnt-700'>
              Type
            </Typography>

            <Typography className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>
              Select a charge type:
            </Typography>
          </Grid>
        </Grid>

        <div className='row mt-5'>
          <div className='col-6'>
            <div className='d-flex align-items-start tst mb-4'>
              <div style={{width: '30px', height: '30px'}}>
                <input
                  type='radio'
                  className='me-3 cursor-pointer form-check-input'
                  {...register('type', {
                    required: 'Please select an option to proceed',
                  })}
                  id='singleCharge'
                  value='singleCharge'
                  checked={itemData?.type == 0}
                  onChange={(e: any) => {
                    setItemData({...itemData, ['type']: 0})
                  }}
                />
              </div>
              <div>
                <h5 className='m-0'>
                  <label htmlFor='singleCharge' className='fs-5 cursor-pointer'>
                    Single Charge
                  </label>
                </h5>
                <p
                  className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'
                  style={{color: '#a4a4a4'}}
                >
                  The charge will be generated once and can be applied once or split into
                  installments.
                </p>
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className='d-flex align-items-start tst mb-4'>
              <div style={{width: '30px', height: '30px'}}>
                <input
                  type='radio'
                  className='me-3 cursor-pointer form-check-input'
                  {...register('type', {
                    required: 'Please select an option to proceed',
                  })}
                  id='recurringCharge'
                  value='recurringCharge'
                  checked={itemData?.type == 1}
                  onChange={(e: any) => {
                    setItemData({...itemData, ['type']: 1})
                  }}
                />
              </div>
              <div>
                <h5 className='m-0'>
                  <label htmlFor='recurringCharge' className='fs-5 cursor-pointer'>
                    Recurring Charge
                  </label>
                </h5>
                <p
                  className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'
                  style={{color: '#a4a4a4'}}
                >
                  The charge will be generated with the same fee amount every time for the entire
                  frequency set.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChargeSelector
