/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'

import maintenancenotification from '../../../../img/maintenancenotification.svg'
import messagenotification from '../../../../img/messagenotification.svg'
import starnotification from '../../../../img/yellow-star.png'
import mailnotification from '../../../../img/mailnotification.svg'
import circlenotification from '../../../../img/circlenotification.svg'

import {onMessageListener} from '../../../../apiCommon/helpers/firebase/firebase'

const HeaderUserMenu = ({showNotification, payloadData}: any) => {
  const navigate = useNavigate()
  // onMessageListener()
  //   .then((payload) => {
  //     console.log('payload', payload)
  //     setNotificationData({title: payload.notification.title, body: payload.notification.body})
  //   })
  //   .catch((err) => console.log('failed: ', err))

  const logout = () => {
    localStorage.clear()
    window.location.pathname = '/auth'
  }
  const notificationView = () => {
    navigate('/request-management/service/create-request-service')
  }

  const notificationStaticData = [
    {
      img: maintenancenotification,
      title: 'Maintenance',
      message: 'lorem lorem lorem',
      time: '5 min',
    },
    {img: messagenotification, title: 'Message', message: 'lorem lorem lorem', time: '2 min'},
    {img: starnotification, title: 'Maintenance', message: 'lorem lorem lorem', time: '3 min'},
    {img: mailnotification, title: 'Invitation', message: 'lorem lorem lorem', time: '5 min'},
    {img: circlenotification, title: 'Maintenance', message: 'lorem lorem lorem', time: '2 min'},
  ]
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold fs-6 w-275px tests light-gry-dark-shadow'
      data-kt-menu='true'
    >
      {showNotification == 1 ? (
        <>
          <div className='card menu-item px-5' style={{boxShadow: 'none'}}>
            <ul className='notification p-0 m-0'>
              <li className='text-center'>
                <h1 className='green_color my-3'>Notifications</h1>
              </li>
              {/* {payloadData ? ( */}
              {notificationStaticData.map((el: any, index:number) => (
                // <li
                //   className='d-flex m-0 p-3 gap-5'
                //   style={{justifyContent: 'space-between', borderTop: '1px solid #aaaaaa50'}}
                // >
                //   <img src={maintenance} width='30px' />
                //   <div className='grid-auto note-gap ' style={{alignItems: 'center'}}>
                //     <h5 className='top m-0'>{payloadData?.notification?.title}</h5>
                //     <p className='m-0 text-end'>5 min</p>
                //     <p className='m-0'>Message: {payloadData?.notification?.body} </p>
                //     <button
                //       className='btn btn-sm fw-bold btn-primary btn-green py-2 w-70px'
                //       onClick={notificationView}
                //     >
                //       View
                //     </button>
                //   </div>
                // </li>
                <li
                  className='d-flex m-0 p-3 gap-5'
                  style={{justifyContent: 'space-between', borderTop: '1px solid #aaaaaa50'}}
                  key={`header${index}`}
                >
                  <img src={el.img} width={30} height={30} />
                  <div className='grid-auto note-gap ' style={{alignItems: 'center'}}>
                    <h5 className='top m-0 white-dark-theme-color'>{el.title}</h5>
                    <p className='m-0 text-end light-dark-theme-color'>{el.time}</p>
                    <p className='m-0 light-dark-theme-color'>Message: {el.message} </p>
                    <button
                      className='btn btn-sm fw-bold btn-primary btn-green py-2 w-70px'
                      onClick={notificationView}
                    >
                      View
                    </button>
                  </div>
                </li>
              ))}
              {/* ) : (
                <li className='text-center'>No data Found</li>
              )} */}
            </ul>
          </div>
        </>
      ) : showNotification == 2 ? (
        <div className='menu-item px-5 my-3'>
          <a onClick={logout} className='menu-link px-5 white-dark-theme-color'>
            Sign Out
          </a>
        </div>
      ) : null}
    </div>
  )
}

export default HeaderUserMenu
