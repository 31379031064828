import React, {useState, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import moment from 'moment'
import {Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import yellowStar from '../../../../img/yellow-star.png'
import additionalImg from '../../../../img/add-require-green.png'
import incompleteImg from '../../../../img/job-incomplete-green.png'
import completeImg from '../../../../img/job-complete-green.png'
import {Fancybox as fancybox} from '@fancyapps/ui'
import '@fancyapps/ui/dist/fancybox/fancybox.css'
import {Fancybox} from '@fancyapps/ui'
import styled from 'styled-components'
import ReactDOMServer from 'react-dom/server'
import { getFileIcon } from '../../../../Utilities/utils'

interface ButtonProps {
  show: any
  handleClose: any
  updateDetails?: any
  modalData: any
}

const ViewCorporateReport = ({show, handleClose, updateDetails, modalData}: ButtonProps) => {
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  //
  const convertUTCToLocalTime = (utcTimeString: any, flg: any) => {
    const localDate = moment.utc(utcTimeString).local()
    if (flg == 1) return localDate.format('DD.MM.YYYY - hh:mm a')
    else return localDate.format('DD.MM.YYYY - hh:mm')
  }

  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })

    return () => {
      fancybox.destroy();
    };
  }, []);

  //
  const handleImageClick = (
    attachmentId: string,
    attachmentSrc: string,
    comm: any,
    val:any
  ) => {
    const groupName = `group_${attachmentId}_${val}`

    let addedFiles:any = []
    if(val == 'addedAttachments')
      addedFiles = comm
    else
    addedFiles = comm?.attachments

    addedFiles?.forEach((receipt: any, idx: any) => {
      const imgeNext = document.getElementById(`${val}${idx}`)
      const fileExtension = receipt.split('.').pop().toLowerCase()
      const isImage = ['jpg', 'jpeg', 'png', 'gif', 'svg', 'pdf', 'mp4', 'webm'].includes(
        fileExtension
      )
      const isDocument = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx'].includes(fileExtension)
      const isPlainText = ['txt', 'csv'].includes(fileExtension)

      imgeNext?.removeAttribute('data-state')

      if (imgeNext && isImage) {
        imgeNext.setAttribute('data-src', `${Bucket}${receipt}`)
        imgeNext.setAttribute('data-fancybox', groupName)
      } else if (imgeNext && isDocument) {
        let viewerUrl: any

        if (fileExtension === 'pptx' || fileExtension === 'ppt') {
          viewerUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
            `${Bucket}${receipt}`
          )}`
        } else if (['doc', 'docx', 'xls', 'xlsx'].includes(fileExtension)) {
          viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
            `${Bucket}${receipt}`
          )}`
        }

        imgeNext.setAttribute('data-src', viewerUrl)
        imgeNext.setAttribute('data-type', 'iframe')
        imgeNext.setAttribute('data-fancybox', groupName)
      } else if (imgeNext && isPlainText) {
        const fileUrl = `${Bucket}${receipt}`
        imgeNext.setAttribute('data-src', fileUrl)
        imgeNext.setAttribute('data-type', 'html')
        imgeNext.setAttribute('data-fancybox', groupName)
      }
      
    })
      
    // Add data-fancybox attribute to the clicked image
    // const image = document.getElementById(attachmentId)
    // if (image) {
    //   image.setAttribute('data-fancybox', groupName)
    //   image.setAttribute('data-src', attachmentSrc)
    // }

    // // Add data-fancybox attribute to the related attachments
    // addedFiles?.forEach((attach: any, idx: number) => {
    //   const imageNext = document.getElementById(`${val}${idx}`)
    //   if (imageNext) {
    //     imageNext.setAttribute('data-fancybox', groupName)
    //     imageNext.setAttribute('data-src', `${Bucket}${attach}`)
    //   }
    // })

    // Destroy and reinitialize Fancybox to ensure it recognizes the new attributes
    // fancybox.destroy()
    // fancybox.bind("[data-fancybox]", {})

    // Trigger the fancybox on the clicked image
    // if (image) {
    //   image.click()
    // }
  }

    // 
    const handleIframeLoad = (iframeElement: HTMLIFrameElement, timeoutDuration = 30000) => {
      return new Promise<void>((resolve, reject) => {
        console.log('handleIframe', iframeElement)
        iframeElement.onload = null
        iframeElement.onerror = null
        // Set up timeout
        const timeoutId = setTimeout(() => {
          reject(new Error('Iframe loading timed out'))
        }, timeoutDuration)
  
        // Handle successful load
        iframeElement.onload = () => {
          // console.log('run')
          clearTimeout(timeoutId)
          resolve()
        }
  
        // Handle loading errors
        iframeElement.onerror = () => {
          clearTimeout(timeoutId)
          reject(new Error('Iframe failed to load'))
        }
  
        // Reload the iframe to ensure the event triggers
        const currentSrc = iframeElement.src
        iframeElement.src = '' // Reset src
        iframeElement.src = currentSrc // Reapply src
      })
    }
  
    let isAlreadyReveal = false
  
    useEffect(() => {
      Fancybox.destroy()
  
      Fancybox.bind('[data-fancybox]', {
        Hash: false,
        on: {
          init: (fancybox: any) => {
            // Initialize slide states when fancybox opens
            fancybox?.Carousel?.slides?.forEach((slide: any) => {
              slide.state = undefined
            })
          },
          loading: async (fancybox: any, slide: any) => {
            setTimeout(async () => {
              // This handles both initial load and subsequent reveals
              if (isAlreadyReveal === false) {
                if (!slide || slide.state === 'done') return
                // console.log('run loading')
                await processSlide(fancybox, slide)
              }
            }, 0)
          },
          // Handle slide change
          reveal: async (fancybox, slide) => {
            if (isAlreadyReveal === false) {
              if (!slide || slide.state === 'done') return
              console.log('run reveal')
              await processSlide(fancybox, slide)
            }
          },
          'Carousel.change': async (fancybox: any, carousel: any) => {
            // This event triggers when slides change
            const currentSlide = carousel.slides[carousel.page]
            if (!currentSlide || currentSlide.state === 'done') return
            await processSlide(fancybox, currentSlide)
          },
          close: () => {
            // Reset all slide states when closing fancybox
            isAlreadyReveal = false
            const slides = document.querySelectorAll('.fancybox__slide')
            slides.forEach((slide: any) => {
              if (slide) {
                slide.state = undefined
              }
            })
          },
        },
      })
  
      const processSlide = async (fancybox: any, slide: any) => {
        setTimeout(async () => {
          isAlreadyReveal = true
          const slideContent = slide.contentEl
          const fileUrl = slide.src
          const fileExtension = fileUrl?.split('.')?.pop()?.toLowerCase()
          //  console.log(slideContent)
          if (!slideContent) return
  
          if (fileExtension === 'pptx' || fileExtension === 'ppt') {
            const iframe = slideContent.querySelector('iframe')
  
            try {
              // console.log('run again')
              fancybox.showLoading(slide)
              await handleIframeLoad(iframe, 30000)
              fancybox.hideLoading(slide)
              slide.state = 'done'
            } catch (error) {
              console.error('Error loading PPT:', error)
              fancybox.hideLoading(slide)
  
              const errorHtml = ReactDOMServer.renderToString(
                <ErrorContainer>
                  <p style={{fontWeight: 'bold', fontSize: '18px'}}>Preview not available</p>
                </ErrorContainer>
              )
              slideContent.innerHTML = errorHtml
              //  slide.state = 'error';
              slide.state = 'error'
            }
          } else if (fileExtension === 'txt' || fileExtension === 'csv') {
            try {
              fancybox.showLoading(slide)
              const response = await fetch(fileUrl)
              if (!response.ok) throw new Error('Failed to fetch file')
  
              const content = await response.text()
              const previewComponent = (
                <TextFilePreview content={content} fileExtension={fileExtension} />
              )
  
              const previewHtml = ReactDOMServer.renderToString(previewComponent)
              slideContent.innerHTML = previewHtml
  
              fancybox.hideLoading(slide)
              slide.state = 'done'
            } catch (error) {
              console.error('Error loading text file:', error)
              fancybox.hideLoading(slide)
              const errorHtml = ReactDOMServer.renderToString(
                <ErrorContainer>
                  <p style={{fontWeight: 'bold', fontSize: '18px'}}>Preview not available</p>
                </ErrorContainer>
              )
              slideContent.innerHTML = errorHtml
              slide.state = 'error'
            }
          }
        }, 0)
      }
  
      return () => {
        Fancybox.destroy()
      }
    }, [])
  
    const PreviewContainer = styled.div`
      padding: 2rem;
      background: #ffffff;
      overflow: auto;
      max-height: 80vh;
      min-width: 80vw;
      max-width: 80vw;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  
      /* Custom scrollbar */
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
  
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
      }
  
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }
  
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    `
  
    const CsvTable = styled.table`
      width: 100%;
      border-collapse: collapse;
      margin: 0;
      background: #ffffff;
      font-size: 14px;
  
      th,
      td {
        border: 1px solid #e2e8f0;
        padding: 12px;
        text-align: left;
      }
  
      th {
        background: #f8fafc;
        font-weight: 600;
        color: #1a202c;
      }
  
      tr:nth-child(even) {
        background: #f8fafc;
      }
  
      tr:hover {
        background: #edf2f7;
      }
    `
  
    const TxtContent = styled.pre`
      font-family: 'Monaco', 'Consolas', monospace;
      white-space: pre-wrap;
      word-wrap: break-word;
      padding: 1rem;
      background: #f8fafc;
      border-radius: 4px;
      color: #1a202c;
      line-height: 1.5;
      font-size: 14px;
      margin: 0;
    `
  
    const ErrorContainer = styled.div`
      display: flex;
      background-color: #ffffff;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 2rem;
      text-align: center;
      color: #ef4444;
      margin: auto;
  
      svg {
        margin-bottom: 1rem;
      }
    `
  
    const TextFilePreview = ({content, fileExtension}: any) => {
      if (fileExtension === 'csv') {
        try {
          const rows = content
            .split('\n')
            .filter((row: any) => row.trim()) // Remove empty rows
            .map((row: any) => row.split(','))
  
          const headers = rows[0]
          const dataRows = rows.slice(1)
  
          return (
            <PreviewContainer>
              <CsvTable>
                <thead>
                  <tr>
                    {headers.map((header: any, i: any) => (
                      <th key={i}>{header.trim()}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {dataRows.map((row: any, i: any) => (
                    <tr key={i}>
                      {row.map((cell: any, j: any) => (
                        <td key={j}>{cell.trim()}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </CsvTable>
            </PreviewContainer>
          )
        } catch (error) {
          console.error('Error parsing CSV:', error)
          return (
            <ErrorContainer>
              <p style={{fontWeight: 'bold'}}>Error parsing CSV file</p>
            </ErrorContainer>
          )
        }
      }
  
      return (
        <PreviewContainer>
          <TxtContent>{content}</TxtContent>
        </PreviewContainer>
      )
    }

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-700px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '600px',
            maxHeight: '650px',
            overflow: 'auto',
          }}
        >
          <div className='d-flex align-items-center mt-3'>
            <img
              src={
                modalData?.reportType == 1
                  ? incompleteImg
                  : modalData?.reportType == 2
                  ? completeImg
                  : additionalImg
              }
              height={24}
              width={24}
            />
            <h2 className='mb-0 ms-3 white-dark-theme-color'>
              <b>
                {modalData?.reportType == 1
                  ? 'Job Incomplete'
                  : modalData?.reportType == 2
                  ? 'Job Complete'
                  : 'Additional Requirements'}{' '}
              </b>
            </h2>

            <label
              className='head-text fs-2 cursor-pointer ms-auto'
              style={{fontWeight: '700'}}
              onClick={() => {
                handleClose()
              }}
            >
              X
            </label>
          </div>

          <div className='row'>
            {/* Report ID */}
            <div className='col-12 col-md-8'>
              <div className='d-flex my-3 mt-6'>
                <h4 className='my-2' style={{color: 'black'}}>
                  <label className='labl-gry status-w-120 light-dark-theme-color'> Report ID</label>
                  <span className='ms-1 white-dark-theme-color'>
                    <b>{modalData?.id ? modalData?.id : '-'}</b>
                  </span>
                </h4>
              </div>
            </div>

            {/* Status */}
            {modalData?.reportType == 2 && (
              <div className='col-12 col-md-4'>
                <div className='d-flex my-3 mt-6 ms-auto'>
                  <h4 className='my-2' style={{color: 'black'}}>
                    <label className='labl-gry me-5 light-dark-theme-color'> Status</label>
                  </h4>
                  <div
                    className={
                      modalData?.reportStatus == 0
                        ? 'tenancy-stats  not-saved '
                        : modalData?.reportStatus == 1
                        ? 'tenancy-stats  requested-st '
                        : modalData?.reportStatus == 2
                        ? 'tenancy-stats  renewed-st '
                        : modalData?.reportStatus == 3
                        ? 'tenancy-stats cancelled-st mx-auto'
                        : 'tenancy-stats  not-saved '
                    }
                  >
                    {modalData?.reportStatus == 0
                      ? 'Not Approved'
                      : modalData?.reportStatus == 1
                      ? 'Pending Tenant'
                      : modalData?.reportStatus == 2
                      ? 'Approved'
                      : modalData?.reportStatus == 3
                      ? 'Rejected'
                      : '-'}
                  </div>
                </div>

                {modalData?.reportStatus != 1 && (
                  <>
                    <div className='d-flex mb-1 ms-auto'>
                      <h4 className='ms-auto me-4' style={{color: 'black'}}>
                        <b className='white-dark-theme-color'>
                          {modalData?.replayByType == 0
                            ? `${modalData?.replayUser?.firstName} ${modalData?.replayUser?.lastName}`
                            : 'Tenant'}
                        </b>
                      </h4>
                    </div>

                    <div className='d-flex'>
                      <h5 className='ms-auto me-4 labl-gry white-dark-theme-color'>
                        {modalData?.replayResponse?.createdAt
                          ? `${convertUTCToLocalTime(modalData?.replayResponse?.createdAt, 1)}`
                          : ''}
                      </h5>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div className='row'>
            {/* Submitted By */}
            <div className='col-12 col-md-8'>
              <h4 className='my-2 mb-0' style={{color: 'black'}}>
                <label className='labl-gry status-w-120 light-dark-theme-color'> Submitted By</label>
                <span className='ms-1 white-dark-theme-color'>
                  <b>
                    {modalData?.submitUser
                      ? `${modalData?.submitUser?.firstName} ${modalData?.submitUser?.lastName}`
                      : '-'}
                  </b>
                </span>
              </h4>

              <div className='d-flex my-3 mb-1'>
                <h5 className='mt-0 mb-2' style={{color: 'black'}}>
                  <label className='labl-gry status-w-120'> </label>
                  <span className='ms-1 labl-gry light-dark-theme-color'>
                    {/* <i> */}
                    {modalData?.createdAt
                      ? `${convertUTCToLocalTime(modalData?.createdAt, 1)}`
                      : ''}
                    {/* </i> */}
                  </span>
                </h5>
              </div>
            </div>

            {/*Job Rating */}
            {modalData?.reportType == 2 &&
              modalData?.reportStatus != 1 &&
              modalData?.reportStatus != 0 && (
                <div className='col-12 col-md-4'>
                  <div className='d-flex ms-auto align-items-center'>
                    <h4 className='my-2' style={{color: 'black'}}>
                      <label className='labl-gry me-5 light-dark-theme-color'> Job Rating</label>

                      <span className='ms-1 white-dark-theme-color'>
                        <b>{modalData?.jobRating ? modalData?.jobRating : '-'}</b>
                      </span>
                      <span><img
                            src={yellowStar}
                            height={16}
                            width={16}
                            className='cursor-pointer'
                            style={{marginTop: '-4px'}}/></span>
                    </h4>

                  </div>
                </div>
              )}
          </div>

          <div
            className='d-flex align-items-center mt-3 pb-2 '
            style={{borderBottom: '0.1rem solid #bec3cb'}}
          ></div>

          {/* Report Details */}
          <div className='card card-flush  mt-10 py-5 px-5' style={{minHeight: '150px'}}>
            <div className='d-flex justify-content-center mt-2'>
              <h2 className='top ps-0 white-dark-theme-color'>
                <b>Report Details</b>
              </h2>
            </div>

            {modalData?.submitResponse?.description?.trim()?.length > 0 && (
              <>
                {/* Comments */}
                <div className='d-flex mt-10'>
                  <h3 className='top ps-0 white-dark-theme-color'>
                    <b>
                      {' '}
                      {modalData?.submitUser
                        ? `${modalData?.submitUser?.firstName} ${modalData?.submitUser?.lastName}`
                        : '-'}
                    </b>{' '}
                  </h3>

                  <h4 className='top ps-0 ms-auto white-dark-theme-color'>
                    {modalData?.submitResponse?.createdAt
                      ? `${convertUTCToLocalTime(modalData?.submitResponse?.createdAt, 2)}`
                      : ''}
                  </h4>
                </div>

                <div className='d-flex mt-1 white-dark-theme-color'>
                  <p>{modalData?.submitResponse ? modalData?.submitResponse?.description : ''}</p>
                </div>
              </>
            )}

            {(modalData?.reportStatus == 2 || modalData?.reportStatus == 3) &&
              modalData?.replayByType == 0 && (
                <>
                  <div className='d-flex mt-10'>
                    <h3 className='top ps-0 white-dark-theme-color'>
                      <b> Job Rating</b>{' '}
                    </h3>
                  </div>

                  <div className='d-flex ms-4 align-items-center'>
                    <h1 className='top ps-0 head-text'>
                      <b>{modalData?.jobRating ? modalData?.jobRating : '-'}</b>
                        <span><img
                            src={ yellowStar}
                            height={20}
                            width={20}
                            className='cursor-pointer'
                            style={{marginTop: '-4px'}}
                            
                          /></span>
                    </h1>
                  </div>
                </>
              )}

            {/* Attachments */}
            <div className='d-flex mt-10'>
              <div className='d-block'>
                <h3 className='top ps-0 mb-0 white-dark-theme-color'>
                  <b>Attachments</b>
                </h3>
              </div>
            </div>

            <div className='d-flex flex-wrap mt-5' style={{maxHeight: '250px', overflow: 'auto'}}>
              {modalData?.submitResponse?.attachments?.length > 0 && (
                <div
                  className='tops d-flex gap-5 align-items-start  flex-wrap'
                >
                  {modalData?.submitResponse?.attachments?.map((file: any, ind: any) => {
                    return (
                      <>
                        <div className='imgs-set'>
                          <div className='d-flex mw-350px me-3 '>
                            {/* <a target='_blank' href={`${Bucket}${file}`}> */}
                              <img
                                src={
                                    file?.split('.')[1] === 'jpeg' ||
                                      file?.split('.')[1] === 'jpg' ||
                                      file?.split('.')[1] === 'png' ||
                                      file?.split('.')[1] === 'svg' ||
                                      file?.split('.')[1] === 'gif'
                                    ? `${Bucket}${file}`
                                    : getFileIcon(file)
                                }
                                width='100'
                                height='100'
                                className='main_img mb-5 cursor-pointer'
                                id={`submitResponseAttachments${ind}`}
                                onClick={() =>
                                  handleImageClick(
                                    `submitResponseAttachments${ind}`,
                                    `${Bucket}${file}`,
                                    modalData?.submitResponse,
                                    `submitResponseAttachments`
                                  )
                                }
                              />
                            {/* </a> */}
                          </div>
                        </div>
                      </>
                    )
                  })}
                </div>
              )}
            </div>
          </div>

          {/* Feedback */}
          {modalData?.replayByType != 0 && (
            <div className='card card-flush  mt-10 py-5 px-5' style={{minHeight: '150px'}}>
              <div className='d-flex justify-content-center mt-2'>
                <h2 className='top ps-0 white-dark-theme-color'>
                  {modalData?.reportType != 2 ? (
                    <b>Vendor Feedback</b>
                  ) : modalData?.replayByType == 1 ? (
                    <b>Tenant Feedback</b>
                  ) : (
                    <></>
                  )}
                </h2>
              </div>

              <>
                {/* Comments */}
                {modalData?.replayResponse?.description?.trim()?.length > 0 && (
                  <>
                    <div className='d-flex mt-10'>
                      <h3 className='top ps-0 white-dark-theme-color'>
                        <b>
                          {' '}
                          {(modalData?.replayUser && modalData?.replayByType == 1)
                            ? `${modalData?.replayUser?.firstName} ${modalData?.replayUser?.lastName}`
                            : (modalData?.replayUser && modalData?.replayByType == 2)
                            ? `${modalData?.replayUser?.companyName}`
                            :'-'}
                        </b>{' '}
                      </h3>

                      <h4 className='top ps-0 ms-auto white-dark-theme-color'>
                        {modalData?.replayResponse?.createdAt
                          ? `${convertUTCToLocalTime(modalData?.replayResponse?.createdAt, 2)}`
                          : '-'}
                      </h4>
                    </div>

                    <div className='d-flex mt-1 white-dark-theme-color'>
                      <p>
                        {modalData?.replayResponse ? modalData?.replayResponse?.description : ''}
                      </p>
                    </div>
                  </>
                )}

                {(modalData?.reportStatus == 2 || modalData?.reportStatus == 3) &&
                  modalData?.replayByType == 1 && (
                    <>
                      <div className='d-flex mt-10'>
                        <h3 className='top ps-0 white-dark-theme-color'>
                          <b> Job Rating</b>{' '}
                        </h3>
                      </div>

                      <div className='d-flex ms-4 '>
                        <h1 className='top ps-0 head-text'>
                          <b>{modalData?.jobRating ? modalData?.jobRating : '-'}</b>
                          <span><img
                            src={ yellowStar}
                            height={20}
                            width={20}
                            className='cursor-pointer'
                            style={{marginTop: '-4px'}}
                            
                          /></span>
                        </h1>
                      </div>
                    </>
                  )}

                {/* Attachments */}
                {modalData?.replayResponse?.attachments?.length > 0 && (
                  <div className='d-flex mt-10'>
                    <div className='d-block'>
                      <h3 className='top ps-0 mb-0 white-dark-theme-color'>
                        <b>Attachments</b>
                      </h3>
                    </div>
                  </div>
                )}

                <div
                  className='d-flex flex-wrap mt-5'
                  style={{maxHeight: '250px', overflow: 'auto'}}
                >
                  {modalData?.replayResponse?.attachments?.length > 0 && (
                    <div
                      className='tops d-flex gap-5 align-items-start flex-wrap'
                    >
                      {modalData?.replayResponse?.attachments?.map((file: any, ind: any) => {
                        return (
                          <>
                            <div className='imgs-set'>
                              <div className='d-flex mw-350px me-3 '>
                                {/* <a target='_blank' href={`${Bucket}${file}`}> */}
                                  <img
                                    src={ file?.split('.')[1] === 'jpeg' ||
                                          file?.split('.')[1] === 'jpg' ||
                                          file?.split('.')[1] === 'png' ||
                                          file?.split('.')[1] === 'svg' ||
                                          file?.split('.')[1] === 'gif'
                                        ? `${Bucket}${file}`
                                        : getFileIcon(file)
                                    }
                                    width='100'
                                    height='100'
                                    className='main_img mb-5 cursor-pointer'
                                    id={`replayResponseAttachments${ind}`}
                                    onClick={() =>
                                      handleImageClick(
                                        `replayResponseAttachments${ind}`,
                                        `${Bucket}${file}`,
                                        modalData?.replayResponse,
                                        `replayResponseAttachments`
                                      )
                                      }
                                  />
                                {/* </a> */}
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </div>
                  )}
                </div>
              </>
            </div>
          )}
        </div>
      </Modal>
    </div>
  )
}

export default ViewCorporateReport
