import React from 'react'
import {Col, Row} from 'react-bootstrap'
import {AiFillDelete} from 'react-icons/ai'
import {useNavigate, useParams} from 'react-router-dom'
import moment from 'moment'
import Swal from 'sweetalert2'
import {ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {SuccessToast} from '../../../apiCommon/helpers/Toast'
import MediaPopUp from './MediaPopUp'
import noData from '../../../img/NoData1.svg'
import leftArrow from '../../../img/arrowleft.svg'
import {request} from 'https'
import {Modal, Button, DatePicker} from 'antd'
import sendBack from '../../../img/share.png'
import approve from '../../../img/approve.png'
import sign from '../../../img/sign.png'
import submit from '../../../img/submit.png'
import crossbtn from '../../../img/cross-button.png'

let arry: any = [{id: (1).toString()}]
const ServiceDetail = () => {
  const {serviceId} = useParams()
  const navigate = useNavigate()
  const Vendors = new Array(2).fill(0)
  const [addrow, setAddRow] = React.useState<any>(0)
  const [serviceDetail, setServiceDetail] = React.useState<any>()
  // const serviceId = window.location.pathname?.split('/')[5]
  const [requestData, setRequestData] = React.useState<any>()
  const [showMedia, setShowMedia] = React.useState<any>(false)
  const [unitNo, setUnitNo] = React.useState<any>()

  const handleAddRow = () => {
    setAddRow(addrow + 1)
    arry.push({id: (arry.length + 1).toString()})
  }

  const getServiceDetail = async () => {
    const body = {
      serviceRequestId: serviceId,
    }
    await ApiPost(`corporate/service_request/get_details`, body)
      .then((res) => {
        setServiceDetail(res?.data?.data?.service_request_list)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getServiceData = async () => {
    await ApiGet(`corporate/service_request/${window.location.pathname?.split('/')[5]}`)
      .then((res) => {
        setRequestData(res?.data?.data[0])
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    getServiceDetail()
    getServiceData()
  }, [])

  //  Cancel request
  const cancelRequest = async () => {
    const body = {
      id: window.location.pathname?.split('/')[5],
      requestStatus: 10,
    }
    await ApiPut(`corporate/service_request`, body)
      .then((res) => {
        SuccessToast(res.data.message)
      })
      .catch((e) => {
        console.log(e)
      })
    navigate('/request-management/service')
  }

  // Submit to vendor
  const submitToVendor = async () => {
    const body = {
      id: window.location.pathname?.split('/')[5],
      requestStatus: 6,
    }
    await ApiPut(`corporate/service_request`, body)
      .then((res) => {
        SuccessToast('Request has been submitted to vendor successfully!!!')
      })
      .catch((e) => {
        console.log(e)
      })
    navigate('/request-management/service')
  }

  return (
    <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
      <div id='' className='app-container container-xxl pt-0 mt-0'>
        <div className='d-flex align-items-center flex-wrap mr-5'>
          {/* <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'> */}
          {/* <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate(`/request-management/service`)}
              >
                Request
              </a>
            </li> */}
          {/* <li className='breadcrumb-item active'>
              <a className='px-2'> View Service Detail</a>
            </li> */}

          {/* </ul> */}

          {/* Back  */}
          <span
            className='rounded-circle'
            style={{cursor: 'pointer'}}
            onClick={() => navigate(`/request-management/service`)}
          >
            <img src={leftArrow} style={{stroke: 'red'}} className='me-2' />
          </span>
          <h3 className='page-heading green_color m-0'>View Request</h3>

          <a
            className='btn btn-sm fw-bold ms-auto text-white'
            style={{backgroundColor: 'rgb(213, 70, 69)'}}
            onClick={() =>
              Swal.fire({
                html: '<p class="text-center m-0" style="font-size: 16px"> You are about to cancel the entire request, do you wish to proceed?</p>',
                showConfirmButton: true,
                confirmButtonColor: ' #007a59',
                confirmButtonText: 'Confirm',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
              }).then((res) => {
                if (res.isConfirmed) {
                  cancelRequest()
                }
              })
            }
          >
            <img
              src={crossbtn}
              style={{height: '20px', width: '20px', marginRight: '7px', marginLeft: '-10px'}}
            />
            Cancel Request
          </a>

          {requestData?.requestType === 1 && requestData?.vendorStatus === 0 && (
            <a
              className='btn btn-sm fw-bold ms-5 text-white'
              onClick={() =>  navigate(`/request-management/service/create-request-service/request-log-tab/${serviceId}`)}
              style={{backgroundColor: 'rgb(72, 71, 77)'}}
            >
              <img
                src={submit}
                style={{height: '20px', width: '20px', marginRight: '7px', marginLeft: '-10px'}}
              />
              Submit To Vendor
            </a>
          )}
        </div>

        <div className='card card-flush app-container py-3 mt-5'>
          {/* <Row>
            <div className='col-md-9'>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                onClick={() => navigate(`/request-management/service`)}
              >
                Back
              </a>
            </div>
            <div className='col-md-3'></div>
            <div className='col-md-3'></div>
            <div className='col-md-3' style={{textAlign: 'end'}}>
            
            </div>
          </Row> */}
          <Row className='mt-3'>
            <Col md={4}>
              <h6 className='text-muted'>
                Request ID{' '}
                <span className='mx-1' style={{color: 'black'}}>
                  {requestData?.id}
                </span>
              </h6>
            </Col>
            <Col md={4}>
              <h6 className='text-muted'>
                Request Type
                <span className='mx-1' style={{color: 'black'}}>
                  {requestData?.requestType === 1 ? 'External' : 'Internal'}
                </span>
              </h6>
            </Col>
            <Col md={4}>
              <h6 className='text-muted'>
                Date Requested{' '}
                <span className='mx-1' style={{color: 'black'}}>
                  {moment(requestData?.createdAt).format('DD/MM/YYYY')}
                </span>
              </h6>
            </Col>
            <Col md={4}>
              <h6 className='text-muted'>
                Assigned To{' '}
                <span className='mx-1' style={{color: 'black'}}>
                  {(requestData?.staff[0]?.firstName || requestData?.staff[0]?.lastName) &&
                    requestData?.staff[0]?.firstName + ' ' + requestData?.staff[0]?.lastName}
                </span>
              </h6>
            </Col>

            <Col md={4}>
              <h6 className='text-muted'>
                Handled By{' '}
                <span className='mx-1' style={{color: 'black'}}>
                  {requestData?.requestType === 1
                    ? requestData?.vendor[0]?.companyName
                    : 'Internal Team'}
                </span>
              </h6>
            </Col>

            <Col md={4}>
              <h6 className='text-muted'>
                Overall Status{' '}
                <span className='mx-1' style={{color: 'black'}}>
                  {requestData?.serviceOverallStatus === 0
                    ? 'Not Started'
                    : requestData?.serviceOverallStatus === 1
                    ? 'Pending'
                    : requestData?.serviceOverallStatus === 2
                    ? 'Started'
                    : requestData?.serviceOverallStatus === 3
                    ? 'Completed'
                    : ''}
                </span>
              </h6>
            </Col>
            <Col md={4}>
              <h6 className='text-muted'>
                Request Urgency{' '}
                <span className='mx-1' style={{color: 'black'}}>
                  {requestData?.requestUrgency === 0 ? 'Standard' : 'Emergency'}
                </span>
              </h6>
            </Col>
          </Row>

          {/* <div className='grid-out container-xxl'>
         
            <div className='side'>
              <h6 className='text-muted'>
                Request Type
                <span className='mx-1' style={{color: 'black'}}>
                  { requestData?.requestType === 1 ? 'External' : 'Internal'}
                </span>
              </h6>
              <h6 className='text-muted'>
                Request ID{' '}
                <span className='mx-1' style={{color: 'black'}}>
                  {requestData?.id}
                </span>
              </h6>
             
              <h6 className='text-muted'>
                Assigned To{' '}
                <span className='mx-1' style={{color: 'black'}}>
                  {requestData?.staff[0]?.firstName &&
                    requestData?.staff[0]?.firstName + ' ' + requestData?.staff[0]?.lastName}
                </span>
              </h6>
              <h6 className='text-muted'>
                Request Urgency{' '}
                <span className='mx-1' style={{color: 'black'}}>
                  {requestData?.requestUrgency === 0 ? 'Standard' : 'Emergency'}
                </span>
              </h6>
            </div>
            <div className='side'>
            <h6 className='text-muted'>
                Date Requested{' '}
                <span className='mx-1' style={{color: 'black'}}>
                  {moment(requestData?.createdAt).format('DD/MM/YYYY')}
                </span>
              </h6>
              <h6 className='text-muted'>
                Handled By{' '}
                <span className='mx-1' style={{color: 'black'}}>
                  {requestData?.requestType === 1
                    ? requestData?.vendor[0]?.companyName
                    : 'Internal Team'}
                </span>
              </h6>
              
              <h6 className='text-muted'>
                Overall Status{' '}
                <span className='mx-1' style={{color: 'black'}}>
                  {requestData?.serviceOverallStatus === 0
                    ? 'Not Started'
                    : requestData?.serviceOverallStatus === 1
                    ? 'Pending'
                    : requestData?.serviceOverallStatus === 2
                    ? 'Started'
                    : requestData?.serviceOverallStatus === 3
                    ? 'Completed'
                    : 'Canceled'}
                </span>
              </h6>
            </div>
          </div> */}
        </div>
        <div className='d-flex align-items-center justify-content-center gap-2 m-7 gap-lg-3'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li
              className='nav-item'
              onClick={() => {
                navigate(
                  `/request-management/service/create-request-service/service-detail/${serviceId}`
                )
              }}
            >
              <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                Service
              </a>
            </li>
            <li
              className='nav-item'
              onClick={() => {
                navigate(
                  `/request-management/service/create-request-service/payment-tab/${serviceId}`
                )
              }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Payment
              </a>
            </li>
            <li
              className='nav-item'
              onClick={() => {
                navigate(
                  `/request-management/service/create-request-service/request-log-tab/${serviceId}`
                )
              }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Request Log
              </a>
            </li>
          </ul>
        </div>

        <div className='card p-5 mb-5'>
          <div className='d-flex align-items-center' style={{justifyContent: 'space-between'}}>
            <h1 className='top green_color pt-2'>Overview</h1>
            <div className='d-flex align-items-center justify-content-end gap-2'>
              {/* <a
                className='btn btn-sm fw-bold btn-primary btn-green'
                // onClick={serviceComplition}
              >
                Service Completion Reports
              </a> */}
              {/* <a
                className='btn btn-sm fw-bold btn-primary btn-green ms-3'
                style={{width: '80px'}}
                // onClick={() => setShowMedia(true)}
              >
                Media
              </a> */}
              <a
                className='btn btn-sm fw-bold btn-primary btn-green'
                onClick={() => {
                  navigate(
                    `/request-management/service/external-service-details/${serviceId}/1`,
                    {state: {status: 10}}
                    // `/request-management/service/create-request-service/service-detail/scope-detail/${serviceId}`
                  )
                }}
              >
                View All
              </a>
            </div>
          </div>
          <div className='card-body pt-0 table-responsive mt-5 px-0'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  {/* <th className='text-center min-w-100px'>Service Name</th> */}
                  <th className='text-center min-w-100px'>Development</th>
                  <th className='text-center min-w-100px'>Total Jobs</th>
                  <th className='text-center min-w-100px'>Not Started</th>
                  <th className='text-center min-w-100px'>In Progress</th>
                  <th className='text-center min-w-100px'>Incomplete</th>
                  <th className='text-center min-w-100px'>Complete</th>
                  <th className='text-center min-w-100px'>Last Update</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {serviceDetail?.length ? (
                  serviceDetail?.map((f: any, i: any) => {
                    return (
                      <tr>
                        {/* <td className='text-center min-w-100px'>{f?.main_service[0]?.name}</td> */}
                        <td className='text-center min-w-100px'>
                          {f?.building?.name ? f?.building?.name : f?.community?.name}
                        </td>
                        <td className='text-center min-w-100px'> 
                        {f?.community ?
                        <a
                            style={{textDecoration: 'underline', color: '#007a59'}}
                          
                            onClick={() => {
                              navigate(
                                `/request-management/service/external-service-details/${serviceId}/${f?.community._id}`,
                                {state: {status: 11}}
                              )
                            }}
                          >{f?.totalProperties ?? '-'}</a>
                          :
                          <a
                          style={{textDecoration: 'underline', color: '#007a59'}}
                        
                          onClick={() => {
                            navigate(
                              `/request-management/service/external-service-details/${serviceId}/${f?.building._id}`,
                              {state: {status: 12}}
                            )
                          }}
                        >{f?.totalProperties ?? '-'}</a>
                  }

                          </td>
                        <td className='text-center min-w-100px'>
                          <a
                            style={{textDecoration: 'underline', color: '#007a59'}}
                            onClick={() => {
                              navigate(
                                `/request-management/service/external-service-details/${serviceId}/${f?._id}`,
                                {state: {status: 0}}
                              )
                            }}
                          >
                            {f?.totalNotStarted ?? '-'}
                          </a>
                        </td>
                        <td className='text-center min-w-100px'>
                          <a
                            style={{textDecoration: 'underline', color: '#007a59'}}
                            onClick={() => {
                              navigate(
                                `/request-management/service/external-service-details/${serviceId}/${f?._id}`,
                                {state: {status: 1}}
                              )
                            }}
                          >
                            {f?.totalInProgress ?? '-'}
                          </a>
                        </td>
                        <td className='text-center min-w-100px'>
                          <a
                            style={{textDecoration: 'underline', color: '#007a59'}}
                            onClick={() => {
                              navigate(
                                `/request-management/service/external-service-details/${serviceId}/${f?._id}`,
                                {state: {status: 3}}
                              )
                            }}
                          >
                            {' '}
                            {f?.totalInComplete ?? '-'}
                          </a>
                        </td>
                        <td className='text-center min-w-100px'>
                          <a
                            style={{textDecoration: 'underline', color: '#007a59'}}
                            onClick={() => {
                              navigate(
                                `/request-management/service/external-service-details/${serviceId}/${f?._id}`,
                                {state: {status: 4}}
                              )
                            }}
                          >
                            {f?.totalComplete ?? '-'}
                          </a>
                        </td>
                        <td className='text-center min-w-100px'>
                          {moment(f?.updatedAt).format('DD/MM/YYYY - HH:mm')}
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={16} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>

        {/* <div className='card p-5 mb-5 mt-5'>
          <h1 className='top green_color'>Consumption</h1>

          <div className='card-body pt-0 table-responsive mt-5 ' style={{overflow: 'hidden'}}>
            {arry.map(() => {
              return (
                <Row>
                  <Col>
                    <select name={`rentType`} className='form-select fst-italic'>
                      <option className='fst-italic' disabled selected>
                        Select
                      </option>
                      <option className='fst-italic' value={0}>
                        Rent
                      </option>
                      <option className='fst-italic' value={1}>
                        Security Deposit
                      </option>
                      <option className='fst-italic' value={2}>
                        Booking
                      </option>
                      <option className='fst-italic' value={3}>
                        Other
                      </option>
                    </select>
                  </Col>
                  <Col>
                    <input
                      type='number'
                      className='form-control mx-2'
                      placeholder=''
                      // name='amount'
                      name={`amount`}
                      // name={`amount ${i}`}
                    />
                  </Col>
                  <Col>
                 <input
                      // type='date'
                      // className='form-control mx-2'
                      // placeholder=''
                      // name='amount'
                      // name={`amount`}
                      // name={`amount ${i}`}
                    /> 
                     
          <DatePicker
          className='form-control mx-2 h-100'

           
            // defaultValue={moment(show.date).format('DD/MM/YYYY')}
          />
                  </Col>
                  <Col>
                    <select name={`rentType`} className='form-select fst-italic'>
                      <option className='fst-italic' disabled selected>
                        Select
                      </option>
                      <option className='fst-italic' value={0}>
                        Rent
                      </option>
                      <option className='fst-italic' value={1}>
                        Security Deposit
                      </option>
                      <option className='fst-italic' value={2}>
                        Booking
                      </option>
                      <option className='fst-italic' value={3}>
                        Other
                      </option>
                    </select>
                  </Col>
                  <Col>
                       <input  
                      // type='date'
                      // className='form-control mx-2'
                      // placeholder=''
                      // name='amount'
                      // name={`amount`}
                      // name={`amount ${i}`}
                    /> 
                    <DatePicker
          className='form-control mx-2 h-100'

           
            // defaultValue={moment(show.date).format('DD/MM/YYYY')}
          />
                  </Col>
                  <Col className='' >
                    <a
                      className='btn btn-sm fw-bold btn-primary btn-green mt-1'
                      style={{width: '80px', marginLeft: '57px'}}
                    >
                      Submit
                    </a>
                  </Col>
                </Row>
              )
            })}
            <div className='mt-5'>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green'
                style={{width: '80px'}}
                onClick={handleAddRow}
              >
                + Add
              </a>
            </div>
          </div> 
          
          <div className='card-body pt-0 table-responsive mt-5 px-0'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='text-center min-w-100px'>Asset</th>
                  <th className='text-center min-w-100px'>Quantity</th>
                  <th className='text-center min-w-100px'>Consumption Date </th>
                  <th className='text-center min-w-100px'>Property</th>
                  <th className='text-center min-w-100px'>Job ID</th>
                  <th className='text-center min-w-100px'> Entered By</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {serviceDetail?.length ? (
                  serviceDetail?.map((f: any, i: any) => {
                    return (
                      <tr>
                        <td className='text-center min-w-100px'>Asset 01</td>
                        <td className='text-center min-w-100px'>03</td>
                        <td className='text-center min-w-100px'>Lorem Ipsum</td>
                        <td className='text-center min-w-100px'>Service Provider</td>
                        <td className='text-center min-w-100px'>5427850</td>
                        <td className='text-center min-w-100px'>5427850</td>
                        <td>
                          <a
                            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                            style={{width: '80px'}}
                          >
                            View
                          </a>
                        </td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={16} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div> 

         
        </div> */}
      </div>

      <MediaPopUp
        unitNo={unitNo}
        show={showMedia}
        handleClose={() => {
          setShowMedia(false)
        }}
        propertyList={serviceDetail}
        id={window.location.pathname?.split('/')[5]}
      />
    </div>
  )
}

export default ServiceDetail
