import React, {useEffect, useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../../../../../img/back-arrow.png'
import addWhite from '../../../../../../../../img/add-white.png'
import Switch from 'react-switch'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {IoMdInformationCircleOutline} from 'react-icons/io'
import settingIcon from '../../../../../../../../img/settings-white.png'
import {ApiPost, ApiPut} from '../../../../../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../../../../../apiCommon/helpers/Toast'
import ActivityStatus from './components/ActivityStatus'
import {CircularProgress} from '@mui/material'
import ReactPaginate from 'react-paginate'
// Sudeep Changes Start
import BookingFeeDetails from './BookingFee/BookingFeeDetails'
// Sudeep Changes End

import switchOffIcon from '../../../../../../../../img/switch-off.png'
import switchOnIcon from '../../../../../../../../img/switch-on.png'
import OverlappingModal from './components/OverlappingModal'
import UnitsList from './components/UnitsList'
import { getLatePaymentFeeList } from '../../../../../../../services/api/late-payment-fee.api'

const Configurations = () => {
  const navigate = useNavigate()
  const {type, id} = useParams()

  const [configurationsData, setConfigurationsData] = useState<any>([])

  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [limit, setLimit] = useState<any>(10)
  const [dataCount, setDataCount] = useState<any>(0)

  const [showStatusModal, setShowStatusModal] = useState<any>(false)
  const [selectedConfig, setSelectedConfig] = useState<any>()
  const [isLoading, setIsLoading] = useState<any>(true)

  const [showOverlappingModal, setShowOverlappingModal] = useState<any>(false)
  const [overlappingUnits, setOverlappingUnits] = useState<any>([])
  const [overlappingUnitsCount, setOverlappingUnitsCount] = useState<any>(0)
  const [showOverlappingUnitListModal, setShowOverlappingUnitListModal] = useState<any>(false)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const getConfigurations = async () => {
    setIsLoading(true)
    const body = {
      limit,
      page,
      leaseFeeListIds: [id],
    }

    try {
      const response = await ApiPost('corporate/lease_fee_configuration/get', body)
      setConfigurationsData(response?.data?.data?.data)

      console.log(response?.data?.data?.data)

      setPageLimit(response?.data?.data?.state?.page_limit)
      setDataCount(response?.data?.data?.state?.data_count)
      setIsLoading(false)
    } catch (error) {
      console.error('Failed to fetch lease types:', error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getConfigurations()
  }, [page])

  //
  const updateStatus = (item: any) => {
    const body = {
      id: item?._id,
      activityStatus: item?.activityStatus ? 0 : 1,
    }
    ApiPost(`corporate/lease_fee_configuration/activity_update`, body)
      .then(() => {
        getConfigurations()
        setShowStatusModal(false)
      })
      .catch((err) => {
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }

        if (err?.status == 400) {
          setShowStatusModal(false)
          setShowOverlappingModal(true)
          setOverlappingUnits(err?.data)
          let count: any = 0
          err?.data?.map((dt: any) => {
            count = count + dt?.units?.length
          })
          setOverlappingUnitsCount(count)
          console.log(err?.data)
        } else {
          ErrorToast(err.message)
        }
      })
  }

  const furnishingTypeMap: {[key: number]: string} = {
    0: 'Unfurnished',
    1: 'Furnished',
  }

  const unitTypeMap: {[key: number]: string} = {
    0: 'Apartment',
    1: 'Penthouse',
    2: 'Common Area',
    3: 'Townhouse',
    4: 'Villa',
    5: 'Other',
    6: 'Office',
    7: 'Restaurant',
    8: 'Cafe',
    9: 'Retail',
  }

  const bedroomsMap: {[key: number]: string} = {
    1: '1 BR',
    2: '2 BR',
    3: '3 BR',
    4: '4 BR',
    5: '5 BR',
    6: '6 BR',
    7: '7 BR',
    8: '8 BR',
    9: '9 BR',
    10: '10 BR',
  }

  const extractAndMap = (
    data: any,
    key:
      | keyof typeof furnishingTypeMap
      | keyof typeof unitTypeMap
      | keyof typeof bedroomsMap
      | string,
    map: {[key: number]: string}
  ): string => {
    // Extract the array corresponding to the key
    const values = data[key]

    if (!Array.isArray(values)) {
      return '' // Return empty string if the key is not an array
    }

    // Sort the values numerically
    const sortedValues = values.sort((a, b) => a - b)

    // Map the values to their corresponding labels
    return sortedValues
      .map((value) => map[value] || '') // Convert values to labels using the map
      .filter((label) => label) // Remove empty labels
      .join(', ')
  }

  return (
    <>
      <div className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-4 gap-lg-3'>
          <div className='d-flex align-items-start gap-3'>
            <span
              className='rounded-circle cursor-pointer'
              onClick={() => navigate('/settings/residential-leasing/annual-leases')}
            >
              <img src={backArrow} height={14} width={14} alt='Back' />
            </span>
            <div>
              <h2 className='page-heading m-0 head-text mb-1'>
                {type == 'contract-fee'
                  ? 'Contract Fees'
                  : type == 'admin-fee'
                  ? 'Admin Fees'
                  : type == 'management-fee'
                  ? 'Management Fees'
                  : type == 'maintenance-fee'
                  ? 'Maintenance Fees'
                  : type == 'housekeeping-fee'
                  ? 'Housekeeping Fees'
                  : type == 'booking-fee'
                  ? 'Booking Fees'
                  : type}{' '}
                Configurations
              </h2>
              <p className='labl-gry fs-4 mb-0 light-dark-theme-color'>
                Allows you to configure and apply
                {type == 'contract-fee'
                  ? ' contract '
                  : type == 'admin-fee'
                  ? ' admin '
                  : type == 'management-fee'
                  ? ' management '
                  : type == 'maintenance-fee'
                  ? ' maintenance '
                  : type == 'housekeeping-fee'
                  ? ' housekeeping '
                  : type == 'booking-fee'
                  ? ' booking '
                  : ` ${type} `}
                related fees on your leases and charge them directly to your tenant.
              </p>
            </div>
          </div>

          <button
            type='button'
            className='btn btn-sm fw-bold green-submit-btn px-4 justify-content-center btn-fit-content ms-auto btn-fit-content'
            onClick={() => {
              const basePath = `/settings/residential-leasing/annual-leases/${type}/${id}`
              const path =
                type === 'booking-fee'
                  ? `${basePath}/add-booking-fee-configuration/1`
                  : `${basePath}/add-configuration/1`

              navigate(path)
            }}
          >
            <img src={addWhite} height={16} width={16} className='me-2' />
            Configure
          </button>
        </div>

        <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3' style={{minHeight: '75vh'}}>
          <div className='card-body pt-0 mt-5 py-0 px-3'>
            {configurationsData?.length > 0 ? (
              <>
                {' '}
                {configurationsData?.map((tb: any, ind: any) => {
                  let totalUnits: any = 0

                  tb?.developmentConfigurations?.map((un: any) => {
                    totalUnits = totalUnits + un?.unitIds?.length
                  })
                  return (
                    <div
                      onClick={() => {
                        const basePath = `/settings/residential-leasing/annual-leases/${type}/${id}`
                        type === 'booking-fee'
                          ? navigate(`${basePath}/add-booking-fee-configuration/2/${tb?._id}`)
                          : navigate(`${basePath}/add-configuration/2/${tb?._id}/view`)
                      }}
                      className='cursor-pointer'
                    >
                      <div className='d-flex align-items-center mb-3'>
                        <div>
                          <h3 className='page-heading m-0 white-dark-theme-color fnt-700'>
                            {tb?.lease_fee_list?.[0]?.name}
                            {' - '} {tb?.name}
                          </h3>
                        </div>
                        {/* Sudeep Changes Start */}
                        <div
                          className='d-flex ms-auto align-items-center'
                          onClick={(e) => e.stopPropagation()}
                        >
                          <div className='blue-circle-filled d-flex align-items-center justify-content-center me-5 cursor-pointer'>
                            <img
                              src={settingIcon}
                              height={20}
                              width={20}
                              alt='settingIcon'
                              onClick={() => {
                                const basePath = `/settings/residential-leasing/annual-leases/${type}/${id}`
                                type === 'booking-fee'
                                  ? navigate(
                                      `${basePath}/add-booking-fee-configuration/2/${tb?._id}`
                                    )
                                  : navigate(`${basePath}/add-configuration/2/${tb?._id}/edit`)
                              }}
                            />
                          </div>
                          {/* Sudeep Changes End */}
                          <p className='mb-0 me-5'>
                            <span>
                              <OverlayTrigger
                                placement='top'
                                delay={{show: 250, hide: 400}}
                                flip
                                overlay={
                                  <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                    <p className='m-0 fnt-700 fs-5 mb-1 text-start'>
                                      Charge Activation
                                    </p>
                                    <p className='mb-2 text-start'>
                                      You are able to activate and de-activate the charge on your
                                      leases by switching between the ‘Active’ and ‘Inactive’
                                      statuses:
                                    </p>
                                    <div className='d-flex align-items-center'>
                                      <img src={switchOnIcon} height={25} width={25} />
                                      <p className='m-0 ms-3 fnt-700'>Active</p>
                                    </div>

                                    <p className='mb-2 text-start'>
                                      Will automatically add the charge into the list of lease
                                      charges in the lease you are creating.
                                    </p>
                                    <div className='d-flex align-items-center'>
                                      <img src={switchOffIcon} height={25} width={25} />
                                      <p className='m-0 ms-3 fnt-700'>Inactive</p>
                                    </div>
                                    <p className='mb-2 text-start'>
                                      Will not automatically add the charge into the list of lease
                                      charges in the lease being created, however, the charge will
                                      be available and can be added manually into the lease.
                                    </p>
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {' '}
                                  <IoMdInformationCircleOutline
                                    className='ms-0 cursor-pointer'
                                    style={{height: '18px', width: '18px'}}
                                  />
                                </span>
                              </OverlayTrigger>
                            </span>
                          </p>

                          <Switch
                            onChange={(val: any) => {
                              setSelectedConfig(tb)
                              setShowStatusModal(true)
                            }}
                            checked={tb?.activityStatus}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor='#00cf99'
                            offColor='#b0bec5'
                            className='ms-auto me-5'
                            height={23}
                            width={42}
                          />
                          <p className='fs-5 fnt-500 pt-2 head-text mb-1 light-dark-theme-color'>
                            <i>{tb?.activityStatus ? 'Active' : 'Inactive'}</i>
                          </p>
                        </div>
                      </div>

                      <Row className='mb-5 '>
                        <Col md={7} style={{display: 'flex', flexDirection: 'column'}}>
                          <div
                            className={
                              'card card-flush py-5 mt-2  pe-5 mb-4 px-6 po-table-card h-100'
                            }
                          >
                            {/* Sudeep Changes Start */}
                            {type === 'booking-fee' ? (
                              <BookingFeeDetails tb={tb} />
                            ) : (
                              <div className='row mx-1'>
                                {/* Charge Type */}
                                <div className='col-12 col-md-6 px-0 mt-4'>
                                  <div className='my-4 '>
                                    <h4 className='my-2'>
                                      <label className='labl-gry'> Charge Type</label>
                                    </h4>

                                    <h4 className='my-2 text-dark'>
                                      <b>
                                        {tb?.type == 0
                                          ? 'Single Charge'
                                          : tb?.type == 1
                                          ? 'Recurring Charge'
                                          : '-'}
                                      </b>
                                    </h4>
                                  </div>
                                </div>
                                {/* Amount Calculation */}
                                <div className='col-12 col-md-6 px-0 mt-4'>
                                  <div className='my-4 '>
                                    <h4 className='my-2'>
                                      <label className='labl-gry '> Amount Calculation</label>
                                    </h4>

                                    <h4 className='my-2 text-dark'>
                                      <b>
                                        {tb?.amountCalculationType == 0
                                          ? 'Fixed Fee'
                                          : tb?.amountCalculationType == 1
                                          ? '% from the charges applied in a lease'
                                          : '-'}
                                      </b>
                                    </h4>
                                  </div>
                                </div>

                                {/* Fee */}
                                <div className='col-12 col-md-6 px-0 mt-4'>
                                  <div className='my-4 '>
                                    <h4 className='my-2'>
                                      <label className='labl-gry '>Fee</label>
                                    </h4>

                                    <h4 className='my-2 text-dark'>
                                      <b>
                                        {tb?.amountCalculationType == 0
                                          ? `${tb?.fixedAmount} AED`
                                          : tb?.amountCalculationType == 1
                                          ? `${tb?.feePercentage}% `
                                          : '-'}
                                      </b>
                                    </h4>
                                  </div>
                                </div>

                                {/* Charge */}
                                {tb?.amountCalculationType == 1 && (
                                  <div className='col-12 col-md-6 px-0 mt-4'>
                                    <div className='my-4 '>
                                      <h4 className='my-2'>
                                        <label className='labl-gry '>Charge</label>
                                      </h4>

                                      <h4 className='my-2 text-dark'>
                                        <b>
                                          {tb?.selectLeaseChargeType == 0
                                            ? 'Total Rent Value'
                                            : tb?.selectLeaseChargeType == 1
                                            ? 'Single Rent Installment'
                                            : '-'}
                                        </b>
                                      </h4>
                                    </div>
                                  </div>
                                )}

                                {/* Split/Frequency */}
                                <div className='col-12 col-md-6 px-0 mt-4'>
                                  <div className='my-4 '>
                                    <h4 className='my-2'>
                                      <label className='labl-gry '>
                                        {' '}
                                        {tb?.type == 0 ? 'Split' : 'Frequency'}
                                      </label>
                                    </h4>

                                    {tb?.type == 0 ? (
                                      <h4 className='my-2 text-dark'>
                                        <b>
                                          {' '}
                                          {tb?.splitIntoInstallments == 0
                                            ? 'Rent Installments'
                                            : tb?.splitIntoInstallments == 1
                                            ? 'Custom'
                                            : tb?.splitIntoInstallments == 2
                                            ? 'No Split'
                                            : '-'}
                                        </b>
                                      </h4>
                                    ) : (
                                      <h4 className='my-2 text-dark'>
                                        <b>
                                          {tb?.frequency == 0
                                            ? 'As Per Rent Installments'
                                            : tb?.frequency == 1
                                            ? 'Daily'
                                            : tb?.frequency == 2
                                            ? 'Weekly'
                                            : tb?.frequency == 3
                                            ? 'Monthly'
                                            : tb?.frequency == 4
                                            ? 'Custom'
                                            : '-'}
                                        </b>
                                      </h4>
                                    )}
                                  </div>
                                </div>

                                {/* Due Date - Percentage Based */}
                                {tb?.amountCalculationType == 1 && (
                                  <div className='col-12 col-md-6 px-0 mt-4'>
                                    <div className='my-4 '>
                                      <h4 className='my-2'>
                                        <label className='labl-gry '>Due Date</label>
                                      </h4>

                                      <h4 className='my-2 text-dark'>
                                        <b>
                                          {tb?.dueDateType == 0
                                            ? 'Time when charge is applied'
                                            : tb?.dueDateType == 1
                                            ? `${tb?.daysAfterChargeIsApplied} days after charge is applied`
                                            : tb?.dueDateType == 2
                                            ? 'Same Day Rent Installment Is Due'
                                            : '-'}
                                        </b>
                                      </h4>
                                    </div>
                                  </div>
                                )}

                                {/* Times Applied */}
                                {tb?.frequency == 4 && (
                                  <div className='col-12 col-md-6 px-0 mt-4'>
                                    <div className='my-4 '>
                                      <h4 className='my-2'>
                                        <label className='labl-gry '>Times Applied</label>
                                      </h4>

                                      <h4 className='my-2 text-dark'>
                                        <b>
                                          {tb?.customTimesApplied ? tb?.customTimesApplied : '-'}{' '}
                                          Times
                                        </b>
                                      </h4>
                                    </div>
                                  </div>
                                )}

                                {/* Start Applying*/}
                                {((tb?.type == 1 && tb?.frequency != 0) ||
                                  (tb?.type == 0 && tb?.splitIntoInstallments == 1)) && (
                                  <div className='col-12 col-md-6 px-0 mt-4'>
                                    <div className='my-4 '>
                                      <h4 className='my-2'>
                                        <label className='labl-gry '>Start Applying</label>
                                      </h4>

                                      <h4 className='my-2 text-dark'>
                                        <b>
                                          {tb?.startApplying == 0
                                            ? 'Start Date Of The Lease'
                                            : tb?.startApplying == 1
                                            ? 'When A % Of The Payments In A Lease Are Received'
                                            : tb?.startApplying == 2
                                            ? 'Manually Set The Start Date At The Time Of Creating The Booking Or Lease'
                                            : '-'}
                                        </b>
                                      </h4>
                                    </div>
                                  </div>
                                )}

                                {/* Stop Applying -- Percentage Based */}
                                {tb?.amountCalculationType == 1 &&
                                  (tb?.frequency == 1 ||
                                    tb?.frequency == 2 ||
                                    tb?.frequency == 3) && (
                                    <div className='col-12 col-md-6 px-0 mt-4'>
                                      <div className='my-4 '>
                                        <h4 className='my-2'>
                                          <label className='labl-gry '>Stop Applying</label>
                                        </h4>

                                        <h4 className='my-2 text-dark'>
                                          <b>
                                            {tb?.endApplying == 0
                                              ? 'End Date Of The Lease'
                                              : tb?.endApplying == 1
                                              ? 'Until A % Of The Payments In A Lease Are Received'
                                              : tb?.endApplying == 2
                                              ? 'Manually Set The End Date At The Time Of Creating The Booking Or Lease'
                                              : '-'}
                                          </b>
                                        </h4>
                                      </div>
                                    </div>
                                  )}

                                {/* Due Date -- Fixed Amount*/}
                                {tb?.amountCalculationType == 0 && (
                                  <div className='col-12 col-md-6 px-0 mt-4'>
                                    <div className='my-4 '>
                                      <h4 className='my-2'>
                                        <label className='labl-gry '>Due Date</label>
                                      </h4>

                                      <h4 className='my-2 text-dark'>
                                        <b>
                                          {tb?.dueDateType == 0
                                            ? 'Time when charge is applied'
                                            : tb?.dueDateType == 1
                                            ? `${tb?.daysAfterChargeIsApplied} days after charge is applied`
                                            : tb?.dueDateType == 2
                                            ? 'Same Day Rent Installment Is Due'
                                            : '-'}
                                        </b>
                                      </h4>
                                    </div>
                                  </div>
                                )}

                                {/* Invoicing*/}
                                <div className='col-12 col-md-6 px-0 mt-4'>
                                  <div className='my-4 '>
                                    <h4 className='my-2'>
                                      <label className='labl-gry '>Invoicing</label>
                                    </h4>

                                    <h4 className='my-2 text-dark d-flex align-items-center'>
                                      <div
                                        className={`me-2 ${
                                          tb?.autoInvoicingEnabled ? 'light-green-dot ' : 'red-dot'
                                        } `}
                                      ></div>
                                      <b>{tb?.autoInvoicingEnabled ? 'Enabled' : 'Disabled'}</b>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            )}
                            {/* Sudeep Changes End */}
                          </div>
                        </Col>
                        <Col md={5} style={{display: 'flex', flexDirection: 'column'}}>
                          <div
                            className={
                              'card card-flush py-5 mt-2  pe-5 mb-4 px-6 po-table-card h-100'
                            }
                          >
                            <div className='row mx-1'>
                              {/* Development*/}
                              <div className='col-12 col-md-6 px-0 mt-4'>
                                <div className='my-4 '>
                                  <h4 className='my-2'>
                                    <label className='labl-gry'> Development</label>
                                  </h4>

                                  <h4 className='my-2 text-dark'>
                                    <b>
                                      {tb?.appliesToAllUnits
                                        ? 'All'
                                        : tb?.developmentConfigurations?.length}
                                    </b>
                                  </h4>
                                </div>
                              </div>
                              {/* Furnishing Type*/}
                              <div className='col-12 col-md-6 px-0'>
                                <div className='row mt-4'>
                                  <div className='col-12'>
                                    <div className='my-4 '>
                                      <h4 className='my-2'>
                                        <label className='labl-gry'> Furnishing Type</label>
                                      </h4>

                                      <h4 className='my-2 text-dark'>
                                        <b>
                                          {tb?.appliesToAllUnits
                                            ? 'Furnished, Unfurnished'
                                            : extractAndMap(
                                                tb.unit,
                                                'furnishingTypes',
                                                furnishingTypeMap
                                              )}
                                        </b>
                                      </h4>
                                    </div>
                                  </div>
                                </div>

                                {/* Unit Type*/}
                                <div className='row mt-4'>
                                  <div className='col-12'>
                                    <div className='my-4 '>
                                      <h4 className='my-2'>
                                        <label className='labl-gry'> Unit Type</label>
                                      </h4>

                                      <h4 className='my-2 text-dark'>
                                        <b>
                                          {tb?.appliesToAllUnits
                                            ? 'All'
                                            : extractAndMap(tb.unit, 'unitTypes', unitTypeMap)}
                                        </b>
                                      </h4>
                                    </div>
                                  </div>
                                </div>

                                {/* Bedrooms*/}
                                <div className='row mt-4'>
                                  <div className='col-12'>
                                    <div className='my-4 '>
                                      <h4 className='my-2'>
                                        <label className='labl-gry'> Bedrooms</label>
                                      </h4>

                                      <h4 className='my-2 text-dark'>
                                        <b>
                                          {tb?.appliesToAllUnits
                                            ? 'All'
                                            : extractAndMap(tb.unit, 'bedrooms', bedroomsMap)}
                                        </b>
                                      </h4>
                                    </div>
                                  </div>
                                </div>

                                {/* Total Units*/}
                                <div className='row mt-4'>
                                  <div className='col-12'>
                                    <div className='my-4 '>
                                      <h4 className='my-2'>
                                        <label className='labl-gry'> Total Units</label>
                                      </h4>

                                      <h4 className='my-2 text-dark'>
                                        {' '}
                                        <b>
                                          {tb?.appliesToAllUnits ? tb?.unit?.totalUnit : totalUnits}{' '}
                                          {tb?.unit?.totalUnit == 1 || totalUnits == 1
                                            ? 'Unit'
                                            : 'Units'}
                                        </b>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )
                })}
              </>
            ) : (
              <>
                {!isLoading ? (
                  <div
                    className='d-flex justify-content-center'
                    style={{minHeight: '400px', verticalAlign: 'middle'}}
                  >
                    <h3 className='top ps-0 d-block my-auto light-dark-theme-color'>
                      <i className='gry-dark-text i_grey'>
                        {' '}
                        The configurations for{' '}
                        {type == 'contract-fee'
                          ? 'Contract Fees'
                          : type == 'admin-fee'
                          ? 'Admin Fees'
                          : type == 'management-fee'
                          ? 'Management Fees'
                          : type == 'maintenance-fee'
                          ? 'Maintenance Fees'
                          : type == 'housekeeping-fee'
                          ? 'Housekeeping Fees'
                          : type == 'booking-fee'
                          ? 'Booking Fees'
                          : type}{' '}
                        will appear here, once added.
                      </i>
                    </h3>
                  </div>
                ) : (
                  <div
                    className='d-flex justify-content-center align-items-center w-100'
                    style={{minHeight: '400px'}}
                  >
                    <CircularProgress style={{color: '#007a59'}} />
                  </div>
                )}
              </>
            )}

            {dataCount > 10 && (
              <div className='row mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                <div className='row mt-5'>
                  <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                    <div
                      className='dataTables_paginate paging_simple_numbers'
                      id='kt_ecommerce_sales_table_paginate'
                    >
                      <ReactPaginate
                        breakLabel='...'
                        nextLabel='>'
                        pageClassName='paginate_button page-item +'
                        pageLinkClassName='page-link'
                        containerClassName='pagination'
                        activeClassName='active'
                        previousClassName='paginate_button page-item previous'
                        nextClassName='paginate_button page-item next'
                        previousLinkClassName='page-link'
                        nextLinkClassName='page-link'
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageLimit}
                        forcePage={page - 1}
                        previousLabel='<'
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <ActivityStatus
        show={showStatusModal}
        onHide={() => {
          setShowStatusModal(false)
        }}
        onAdd={() => {
          updateStatus(selectedConfig)
        }}
        selectedConfig={selectedConfig}
      />

      {/* Overlapping Modal */}
      <OverlappingModal
        show={showOverlappingModal}
        onHide={() => {
          setShowOverlappingModal(false)
        }}
        showDetails={() => {
          setShowOverlappingUnitListModal(true)
          setShowOverlappingModal(false)
        }}
        unitsCount={overlappingUnitsCount}
      />

      {/* Units List */}
      <UnitsList
        show={showOverlappingUnitListModal}
        onHide={() => {
          setShowOverlappingUnitListModal(false)
        }}
        unitsDetails={overlappingUnits}
        unitsCount={overlappingUnitsCount}
      />
    </>
  )
}

export default Configurations
