import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import trashImg from '../../../../img/trash.png'
import openImg from '../../../../img/black-collap-down.png'
import closeImg from '../../../../img/black-collap-up.png'
import swal from 'sweetalert2'
import {useParams} from 'react-router-dom'
import invoiceGreen from '../../../../img/invoice-green.png'
import {getFileIcon} from '../../../../Utilities/utils'
import {formatAmount} from '../../../../Utilities/utils'

interface InvoicesProps {
  incmData?: any
  updateParent?: any
  autoSaveParent?: any
  updateParentToGenerateInvoice?: any
  deleteInvoice?: any
  incmAPIDetails?: any
}

const Invoices: React.FC<InvoicesProps> = ({
  incmData,
  updateParent,
  autoSaveParent,
  updateParentToGenerateInvoice,
  deleteInvoice,
  incmAPIDetails,
}) => {
  const {state} = useLocation()
  const navigate = useNavigate()

  const {flg, id} = useParams()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [invoices, setInvoices] = useState<any>([
    // {
    //   id: 201,
    //   _id: '66a32e4b4f68f82c82793f48',
    //   rctId: 'RCT9234',
    //   items: [
    //     {
    //       paymentItemListId: '665cd669f0c97aa475d83fdd',
    //       name: 'Rent',
    //       frontendStorage: {
    //         level: 2,
    //         details: {
    //           _id: '66698285d20741c51977897a',
    //           unitNo: 'other name 2',
    //           id: '219',
    //         },
    //       },
    //       amount: 2000,
    //       quantity: 1,
    //       totalAmount: 2200,
    //       amountPaid: 500,
    //       VATPercentage: 10,
    //       VATAmount: 200,
    //       outstandingAmount: 700,
    //       _id: '66a32e4b4f68f82c82793f49',
    //       property: {
    //         level: 2,
    //         details: {
    //           _id: '66698285d20741c51977897a',
    //           unitNo: 'other name 2',
    //           id: '219',
    //         },
    //       },
    //     },
    //     {
    //       paymentItemListId: '665cd669f0c97aa475d83fd4',
    //       name: 'Security Deposit',
    //       frontendStorage: {
    //         level: 2,
    //         details: {
    //           _id: '6669330ad20741c51976def5',
    //           unitNo: 'unit 3',
    //           id: '213',
    //         },
    //       },
    //       amount: 1000,
    //       quantity: 1,
    //       totalAmount: 1100,
    //       amountPaid: 500,
    //       VATPercentage: 10,
    //       VATAmount: 100,
    //       outstandingAmount: 600,
    //       _id: '66a32e4b4f68f82c82793f4a',
    //       property: {
    //         level: 2,
    //         details: {
    //           _id: '6669330ad20741c51976def5',
    //           unitNo: 'unit 3',
    //           id: '213',
    //         },
    //       },
    //     },
    //   ],
    //   datePaid: '2024-07-17T00:00:00.000Z',
    //   totalAmountPaid: 1000,
    //   receipts: [
    //     {
    //       name: '2024-06-04T15_23_40.647Z_1717514616395.jpg',
    //       URL: '6582bac50d9a049c6882be03/invoice_6582bac50d9a049c6882be03/2024-06-04T15_23_40.647Z_1717514616395.jpg',
    //       _id: '66a32e4b4f68f82c82793f4b',
    //     },
    //   ],
    //   attachments: [
    //     {
    //       name: 'Pdf-lib_creation_example.pdf',
    //       URL: '6582bac50d9a049c6882be03/invoice_6582bac50d9a049c6882be03/Pdf-lib_creation_example.pdf',
    //       _id: '66a32e4b4f68f82c82793f4c',
    //     },
    //   ],
    //   createdAt: '2024-07-26T05:04:11.712Z',
    //   updatedAt: '2024-07-26T05:04:11.712Z',
    //   amountPaid: 1000,
    //   pdfURL: '6582bac50d9a049c6882be03/pdf/1717352049516.pdf'
    // },
  ])

  const [show, setShow] = useState<any>([])

  useEffect(() => {
    setInvoices(incmAPIDetails?.payment_invoice?.length > 0 ? incmAPIDetails?.payment_invoice : [])
  }, [incmAPIDetails])

  const [itemsError, setItemsError] = useState<any>(false)
  const [noItemsError, setNoItemsError] = useState<any>(false)

  const handleImageClick = (val: any) => {
    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(val)
    if (image) {
      image.setAttribute('data-fancybox', '')
    }
  }

  return (
    <>
      <div className={'card card-flush py-5 mt-7  px-5 mb-5 mx-7 min-hg-300'}>
        <div className='d-flex  align-items-center'>
          <h2 className='' style={{fontWeight: '700'}}>
            {'Invoices '}
          </h2>

          <div className='d-black ms-auto'>
            <button
              type='button'
              className='btn btn-sm fw-bold px-2 green-hollow-btn ms-auto btn-fit-content pe-5'
              // onClick={()=>{navigate('/financials/add-invoice/1')}}

              onClick={() => {
                if (flg == '1') {
                  swal
                    .fire({
                      html: ` 
                    <div class='fs-3'> <b>  Save Content Confirmation</b></div>
                    <div class='fs-5 mt-4'> Before proceeding to the next screen, please ensure to save the current content as it will be utilized in the upcoming screen. </div>`,
                      // icon: 'warning',
                      showConfirmButton: true,
                      confirmButtonColor: '#35475e',
                      confirmButtonText: 'Save & Continue',
                      showCancelButton: true,
                      cancelButtonText: 'Cancel',
                      cancelButtonColor: '#fff',
                      customClass: {
                        confirmButton:
                          'custom-cancel-button status-w-120 d-flex justify-content-center h-31 align-items-center px-0',
                        cancelButton:
                          'custom-confirm-button status-w-120 d-flex justify-content-center h-31 align-items-center px-0',
                        popup: 'custom-popup',
                      },
                      // reverseButtons: true,
                    })
                    .then((res: any) => {
                      if (res.isConfirmed) {
                        updateParentToGenerateInvoice()
                      }
                    })
                } else {
                  updateParentToGenerateInvoice()
                }
              }}
              disabled={incmAPIDetails?.payment_invoice?.length > 0}
            >
              <img src={invoiceGreen} height={18} width={14} className='me-4' /> Generate an Invoice
            </button>

            {noItemsError && (
              <p className='text-danger mb-0 ms-5'> Please add an payment item first.</p>
            )}

            {itemsError && (
              <p className='text-danger mb-0 ms-5'> Please complete all payment item fields.</p>
            )}
          </div>
        </div>

        <div className='table-responsive mt-4' style={{maxHeight: '700px'}}>
          <table
            className='table align-middle table-row-dashed fs-6 gy-5'
            id='kt_ecommerce_sales_table'
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                <th className='text-start min-w-100px ' style={{maxWidth: '100px'}}>
                  Invoice No
                </th>
                <th className='text-start min-w-150px' style={{maxWidth: '150px'}}>
                  Payment Items
                </th>
                <th className='text-start min-w-200px ' style={{maxWidth: '200px'}}>
                  Due Date
                </th>
                <th className='text-start min-w-125px' style={{maxWidth: '125px'}}>
                  Sub Total
                </th>
                <th className='text-start min-w-125px' style={{maxWidth: '125px'}}>
                  VAT
                </th>
                <th className='text-start min-w-125px' style={{maxWidth: '125px'}}>
                  {' '}
                  Total Amount
                </th>
                <th className='text-start min-w-125px' style={{maxWidth: '125px'}}>
                  Invoice Date
                </th>
                <th className='text-start min-w-200px' style={{maxWidth: '200px'}}></th>
                <th className='text-end min-w-100px' style={{maxWidth: '100px'}}></th>
              </tr>
            </thead>

            <tbody className='fw-semibold payment-records head-text'>
              {invoices?.map((pr: any, ind: any) => {
                return (
                  <>
                    <tr
                      style={
                        show[ind] == true
                          ? {borderBottomStyle: 'none', height: '30px'}
                          : show[ind] == false
                          ? {borderBottomStyle: 'dashed', height: '30px'}
                          : pr?.paymentSubItems?.length == 1
                          ? {borderBottomStyle: 'dashed', height: '30px'}
                          : {height: '30px'}
                      }
                    >
                      <td className='text-start'>
                        <a
                          className='green_color text-decoration-underline'
                          onClick={() => {
                            navigate(`/financials/add-invoice/1/${id}`)
                          }}
                        >
                          {pr?.invoiceNo ? pr?.invoiceNo : '-'}
                        </a>

                        <div className='d-block'>
                          {pr?.paymentSubItems?.length > 1 && (
                            <img
                              src={show[ind] ? closeImg : openImg}
                              height='15'
                              width='15'
                              className='cursor-pointer'
                              onClick={() => {
                                if (show[ind] == true) {
                                  let values = [...show]
                                  values[ind] = false
                                  setShow(values)
                                } else {
                                  let values = [...show]

                                  values[ind] = true

                                  setShow(values)
                                }
                              }}
                            />
                          )}
                        </div>
                      </td>
                      <td className='text-start ps-2'>
                        <div className='d-flex align-items-center'>
                          {pr?.saveStatus == 1 && (
                            <img
                              src={getFileIcon(pr?.pdfURL)}
                              height={30}
                              width={30}
                              className='me-2 cursor-pointer'
                              data-src={`${Bucket}${pr?.pdfURL}`}
                              id={`clickedInvoice${ind}`}
                              onClick={() => handleImageClick(`clickedInvoice${ind}`)}
                            />
                          )}
                          {pr?.paymentSubItems?.length > 1 ? (
                            <>{pr?.paymentSubItems?.length} items</>
                          ) : (
                            <>{pr?.paymentSubItems?.[0]?.name}</>
                          )}
                        </div>
                      </td>
                      <td className='text-start'>
                        {incmData?.dueDate != null
                          ? `${moment?.utc(incmData?.dueDate)?.local()?.format('DD.MM.YYYY')}`
                          : '-'}
                      </td>
                      <td className='text-start'>
                        {pr?.subTotalAmount != undefined
                          ? `AED ${formatAmount(pr?.subTotalAmount)}`
                          : 'AED 0'}
                      </td>
                      <td className='text-start'>
                        {pr?.totalVAT != undefined ? `AED ${formatAmount(pr?.totalVAT)}` : 'AED 0'}
                      </td>

                      <td className='text-start'>
                        {pr?.totalAmount > 0 ? `AED ${formatAmount(pr?.totalAmount)}` : 'AED 0'}
                      </td>

                      <td className='text-start'>
                        {pr?.date != null
                          ? `${moment?.utc(pr?.date)?.local()?.format('DD.MM.YYYY')}`
                          : '-'}
                      </td>
                      <td></td>
                      <td className='text-end '>
                        <img
                          src={trashImg}
                          height={18}
                          width={18}
                          className='mx-5 cursor-pointer'
                          onClick={() => {
                            swal
                              .fire({
                                html: ` 
                                <div class='fs-3'> <b>Delete Invoice</></div>
                                 <div class='fs-5 mt-4'>You are about to delete this invoice.</div>
                                <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                // icon: 'warning',
                                showConfirmButton: true,
                                confirmButtonColor: '#35475e',
                                confirmButtonText: 'Yes',
                                showCancelButton: true,
                                cancelButtonText: 'No',
                                cancelButtonColor: '#fff',
                                customClass: {
                                  confirmButton: 'custom-confirm-button',
                                  cancelButton: 'custom-cancel-button',
                                  popup: 'custom-popup',
                                },
                                reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                              })
                              .then((res: any) => {
                                if (res.isConfirmed) {
                                  deleteInvoice(pr?._id)
                                }
                              })
                          }}
                        />
                      </td>
                    </tr>

                    {pr?.paymentSubItems?.length > 1 &&
                      show[ind] == true &&
                      pr?.paymentSubItems?.map((prSub: any, index: any) => {
                        return (
                          <>
                            <tr
                              style={
                                index == pr?.paymentSubItems?.length - 1
                                  ? {borderBottomStyle: 'dashed', height: '30px'}
                                  : {borderBottomStyle: 'none', height: '30px'}
                              }
                            >
                              <td style={{paddingLeft: '5px'}}></td>
                              <td>{prSub?.name}</td>
                              <td></td>
                              <td>
                                {' '}
                                {prSub?.amount != undefined
                                  ? `AED ${formatAmount(prSub?.amount * prSub?.quantity)}`
                                  : 'AED 1'}
                              </td>
                              <td>
                                {' '}
                                {prSub?.VATAmount != undefined
                                  ? `AED ${formatAmount(prSub?.VATAmount)}`
                                  : 'AED 0'}
                              </td>
                              <td>
                                {' '}
                                {prSub?.totalAmount != undefined
                                  ? `AED ${formatAmount(prSub?.totalAmount)}`
                                  : 'AED 0'}
                              </td>
                              <td></td>
                            </tr>
                          </>
                        )
                      })}
                  </>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default Invoices
