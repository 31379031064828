import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {
  ApiGet,
  ApiPost,
  ApiUpload,
  ApiPut,
  ApiDelete,
  ApiGetNoAuth,
} from '../../../apiCommon/helpers/API/ApiData'
import img from '../../../img/300-1.jpg'
import folderDocument from '../../../img/folder-document.svg'
import pdfDocument from '../../../img/pdf.svg'
import docCocument from '../../../img/doc.svg'
import {Bucket} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import backArrow from '../../../img/back-arrow.png'
import {Modal, Button} from 'react-bootstrap'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Swal from 'sweetalert2'
import eye from '../../../img/eye-blue.png'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import check from '../../../img/check-white.png'
import {Controller, useForm} from 'react-hook-form'
import plusBlue from '../../../img/add-blue.png'
import addFolderImg from '../../../img/add-fold-ehite.png'
import redCross from '../../../img/remove.png'
import upload from '../../../img/upload-blue.png'
import trashImg from '../../../img/trash.png'
import correct from '../../../img/correct.png'
import Box from '@mui/material/Box'
import './style.scss'
import downloadImg from '../../../img/download-img.png'
import pencil from '../../../img/pencil-blue.png'
import axios from 'axios'

interface ButtonProps {
  show: any
  handleClose: any
  refetchTenants: any
  tenant: any
  tentFolder: any
  contFolder: any
  onSuccess: any
  from: any
}

const TenantFolders = ({
  show,
  handleClose,
  refetchTenants,
  tenant,
  tentFolder,
  contFolder,
  onSuccess,
  from,
}: ButtonProps) => {
  const navigate = useNavigate()
  // const dispatch = useDispatch()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const {state} = useLocation()
  const [tenantData, setTenantData] = React.useState<any>([])

  const [open, setOpen] = React.useState(false)
  const [isDel, setIsDel] = React.useState(false)

  const [index, setIndex] = React.useState(-1)
  const [slides, setSlides] = React.useState<any>([])
  const [totalImages, setTotalImages] = React.useState<any>(0)

  const [viewFolderInd, setViewFolderInd] = useState<any>()
  const [totalFolders, setTotalFolders] = useState<any>()
  const [totalFiles, setTotalFiles] = useState<any>()

  const [compareFold, setCompareFolder] = useState<any>()
  const [newName, setNewName] = useState<any>()
  const [newNameInd, setNewNameInd] = useState<any>(0)
  const [extension, setExtension] = useState<any>()

  const [isAdded, setIsAdded] = useState<any>(false)
  const [isDeselected, setIsDeselected] = useState<any>(false)
  const [isManipulated, setIsManipulated] = useState<any>(false)

  let selectedFoldCnt: any = 0
  let selectedFilesCnt: any = 0

  const getTenantData = async () => {
    await ApiGet(`corporate/tenant/${tenant}`)
      .then((res) => {
        setTenantData(res?.data?.data)

        console.log('\nres?.data?.data', res?.data?.data)
        //  setPropertiType(res?.data?.data[0]?.tenantType === 1 ? 'Messages' : 'Documents')

        const values: any = [...tentFolder]
        let temp: any

        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          // For Tenant Documents
          if (from == 'tenant' && res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
            let isInc: any = false
            for (let j = 0; j < values?.length; j++) {
              if (values?.[j]?._id == res?.data?.data?.tenant_folder?.[i]?._id) {
                isInc = true
                temp = j
                j = values?.length
              }
            }
            if (isInc) {
              for (let m = 0; m < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; m++) {
                let isAvailable: any = false
                // if (values?.[temp]?.fileList?.length == 0) isAvailable = true

                for (let k = 0; k < values?.[temp]?.fileList?.length; k++) {
                  if (
                    res?.data?.data?.tenant_folder?.[i]?.fileList[m]?._id ==
                    values?.[temp]?.fileList[k]?._id
                  ) {
                    isAvailable = true
                    k = values?.[temp]?.fileList?.length
                  }
                }

                if (isAvailable == false) {
                  values[temp].fileList[values[temp].fileList?.length] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[m]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[m]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[m]?.fileURL,
                    isChecked: false,
                  }
                }
              }
            } else {
              values[values?.length] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: false,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }

              for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                  if (values[values?.length] != undefined) {
                    values[values?.length].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                } else {
                  if (values[values?.length] != undefined) {
                    console.log(values[values?.length].fileList[j])
                    values[values?.length].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  } else {
                    // values[values?.length-1].fileList[j] = {
                    //   _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    //   fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    //   fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    //   isChecked: false,
                    // }
                  }
                }
              }
            }
          }

          //  For Contract Documents
          if (
            from == 'contract' &&
            res?.data?.data?.tenant_folder?.[i].name != 'Passports' &&
            res?.data?.data?.tenant_folder?.[i].name != 'Residencies' &&
            res?.data?.data?.tenant_folder?.[i].name != 'IDs'
          ) {
            let isInc: any = false
            for (let j = 0; j < values?.length; j++) {
              if (values?.[j]?._id == res?.data?.data?.tenant_folder?.[i]?._id) {
                isInc = true
                temp = j
                j = values?.length
              }
            }
            if (isInc) {
              for (let m = 0; m < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; m++) {
                let isAvailable: any = false
                // if (values?.[temp]?.fileList?.length == 0) isAvailable = true

                for (let k = 0; k < values?.[temp]?.fileList?.length; k++) {
                  if (
                    res?.data?.data?.tenant_folder?.[i]?.fileList[m]?._id ==
                    values?.[temp]?.fileList[k]?._id
                  ) {
                    isAvailable = true
                    k = values?.[temp]?.fileList?.length
                  }
                }

                if (isAvailable == false) {
                  values[temp].fileList[values[temp].fileList?.length] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[m]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[m]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[m]?.fileURL,
                    isChecked: false,
                  }
                }
              }
            } else {
              values[values?.length] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: false,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }

              for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                  if (values[values?.length] != undefined) {
                    values[values?.length].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                } else {
                  if (values[values?.length] != undefined) {
                    values[values?.length].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  } else {
                    // values[values?.length-1].fileList[j] = {
                    //   _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    //   fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    //   fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    //   isChecked: false,
                    // }
                  }
                }
              }
            }
          }
        }
        console.log(values)
        setFolders(values)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    console.log('Tenant Folder Modal')
    getTenantData()
  }, [])

  /************************* Documents **********************/
  const [folders, setFolders] = useState<any>()
  const [viewFolder, setViewFolder] = useState<any>(false)
  const [folderFiles, setFolderFiles] = useState<any>()
  const [selectedFolder, setSelectedFolder] = useState<any>()
  const [folderName, setFolderName] = useState<any>('')
  const [folderNameModal, setFolderNameModal] = useState<any>(false)

  const getFolderFilesAfterUpload = (folder: any) => {
    ApiGet(`corporate/tenant_folder/${folder?._id}`)
      .then((res: any) => {
        setViewFolder(true)

        let temp: any = {...folder}
        let tempInd: any = 0

        for (let m = 0; m < res?.data?.data?.fileList?.length; m++) {
          let isAvailable: any = false
          if (folder?.fileList?.length == 0) isAvailable = true

          for (let k = 0; k < folder?.fileList?.length; k++) {
            if (res?.data?.data?.fileList[m]?._id == folder?.fileList[k]?._id) {
              isAvailable = true
              tempInd = k
              k = folder?.fileList?.length
            }
          }

          if (isAvailable == false) {
            temp.fileList[m] = {
              _id: res?.data?.data?.fileList?.[m]?._id,
              fileName: res?.data?.data?.fileList?.[m]?.fileName,
              fileURL: res?.data?.data?.fileList?.[m]?.fileURL,
              isChecked: false,
            }
          } else {
            temp.fileList[m] = {
              _id: folder.fileList?.[tempInd]?._id,
              fileName: folder.fileList?.[tempInd]?.fileName,
              fileURL: folder.fileList?.[tempInd]?.fileURL,
              isChecked: folder.fileList?.[tempInd]?.isChecked,
            }
          }
          if (folder.fileList?.length == 0 && res?.data?.data?.fileList?.length > 0) {
            temp.fileList[0] = {
              _id: res?.data?.data?.fileList?.[m]?._id,
              fileName: res?.data?.data?.fileList?.[m]?.fileName,
              fileURL: res?.data?.data?.fileList?.[m]?.fileURL,
              isChecked: false,
            }
          }
        }
        setSelectedFolder(temp)

        if (res?.data?.data?.fileList?.length == 0) setFolderFiles([])
        else setFolderFiles(folder?.fileList)

        let values = [...folders]

        for (let x = 0; x < folders?.length; x++) {
          if (values[x]?._id == temp?._id) {
            values[x] = temp
            let isChk = false
            for (let y = 0; y < folder?.fileList?.length; y++) {
              if (temp?.fileList[y]?.isChecked == true) {
                isChk = true
              }
            }
            if (!isChk) values[x].isCheck = false
          }
        }

        setFolders(values)

        let y: any = []
        for (let i = 0; i < res?.data?.data?.fileList?.length; i++) {
          y[y.length] = {
            src: Bucket + res?.data?.data?.fileList[i]?.fileURL,
          }
        }

        setSlides(y)
        setTotalImages(res?.data?.data?.fileList?.length)
      })
      .catch((err: any) => {})
  }

  const getFolderFiles = (folder: any) => {
    ApiGet(`corporate/tenant_folder/${folder?._id}`)
      .then((res: any) => {
        setViewFolder(true)

        for (let m = 0; m < res?.data?.data?.fileList?.length; m++) {
          let isAvailable: any = false
          if (folder?.fileList?.length == 0) isAvailable = true

          for (let k = 0; k < folder?.fileList?.length; k++) {
            if (res?.data?.data?.fileList[m]?._id == folder?.fileList[k]?._id) {
              isAvailable = true
              k = folder?.fileList?.length
            }
          }

          if (isAvailable == false) {
            folder.fileList[folder.fileList?.length] = {
              _id: res?.data?.data?.fileList?.[m]?._id,
              fileName: res?.data?.data?.fileList?.[m]?.fileName,
              fileURL: res?.data?.data?.fileList?.[m]?.fileURL,
              isChecked: false,
            }
          }
          if (folder.fileList?.length == 0 && res?.data?.data?.fileList?.length) {
            folder.fileList[0] = {
              _id: res?.data?.data?.fileList?.[m]?._id,
              fileName: res?.data?.data?.fileList?.[m]?.fileName,
              fileURL: res?.data?.data?.fileList?.[m]?.fileURL,
              isChecked: false,
            }
          }
        }

        setSelectedFolder(folder)

        if (res?.data?.data?.fileList?.length == 0) setFolderFiles([])
        else setFolderFiles(folder?.fileList)

        let values = [...folders]

        for (let x = 0; x < folders?.length; x++) {
          if (values[x]?._id == folder?._id) {
            values[x] = folder
            let isChk = false
            for (let y = 0; y < folder?.fileList?.length; y++) {
              if (folder?.fileList[y]?.isChecked == true) {
                isChk = true
              }
            }
            if (!isChk) values[x].isCheck = false
          }
        }

        setFolders(values)

        let y: any = []
        for (let i = 0; i < res?.data?.data?.fileList?.length; i++) {
          y[y.length] = {
            src: Bucket + res?.data?.data?.fileList[i]?.fileURL,
          }
        }

        setSlides(y)
        setTotalImages(res?.data?.data?.fileList?.length)
      })
      .catch((err: any) => {})
  }

  /**********************************/
  const [editFile, setEditFile] = useState<any>(-1)
  const [fileFound, setFileFound] = useState<any>(false)
  let fileLists_single: any = []

  const [fileToReplace, setFileToReplace] = useState<any>()
  var replaceFile: any

  //
  const confirmAction = () => {
    // return new Promise((resolve) => {
    return Swal.fire({
      text: `${replaceFile?.name} exists already. Do you wish to REPLACE or KEEP BOTH ? `,
      icon: 'warning',
      showConfirmButton: true,
      confirmButtonColor: '#007a59',
      confirmButtonText: 'Keep Both',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      showDenyButton: true,
      denyButtonText: 'Replace',
      denyButtonColor: '#D72852',
    }).then((res: any) => {
      if (res.isConfirmed) {
        return 'keep'
      } else if (res.isDenied) {
        return 'replace'
      } else {
      }
    })
    // });
  }

  //
  const onFilesAddedSingle = async (event: any, val: any, id: any) => {
    let temp = [...folderFiles]

    if (event.target.files) {
      for (let m = 0; m < event.target.files.length; m++) {
        try {
          // Add File
          if (val == 0) {
            const files = event.target.files
            fileLists_single = Array.from(files)

            setFileToReplace(event.target.files[m])
            replaceFile = event.target.files[m]

            let isFound: any = false
            for (let i = 0; i < folderFiles?.length; i++) {
              if (event.target.files?.[m]?.name == folderFiles[i]?.fileName) {
                isFound = true
                i = folderFiles?.length
              }
            }

            if (!isFound) {
              setOpen(true)

              // for (let i = 0; i < fileLists_single.length; i++) {
              let formData: any = new FormData()
              let data: any = fileLists_single[m]
              let fileURL: any = URL.createObjectURL(data)
              let filetypes: any = fileLists_single[m].type
              data.fileURL = fileURL
              formData.append('image', data)

              let url = 'upload/image/tenant_folder'

              await ApiUpload(`${url}`, formData)
                .then(async (res) => {
                  let temp = [...folderFiles]
                  temp[folderFiles?.length] = {
                    fileName: event?.target?.files[m]?.name,
                    fileURL: res?.data?.data?.image,
                    fileSize: event?.target?.files[m]?.size,
                    createdAt: moment(new Date()).format('YYYY-MM-DD'),
                  }

                  const body = {
                    id: selectedFolder?._id,
                    name: selectedFolder?.name,
                    fileList: temp,
                  }
                  await ApiPut(`corporate/tenant_folder`, body)
                    .then((res: any) => {
                      getFolderFiles(selectedFolder)
                      setOpen(false)
                    })
                    .catch((err: any) => {})
                })
                .catch((err) => {
                  console.log('res_blob', err)
                  ErrorToast(err?.message)
                })
              // }
            } else {
              const userChoice = await confirmAction()
              console.log(userChoice)
              if (userChoice == 'keep') {
                setOpen(true)

                let formData: any = new FormData()
                let data: any = replaceFile
                console.log(replaceFile)
                let fileURL: any = URL.createObjectURL(data)
                let filetypes: any = replaceFile.type
                let filename: any = replaceFile.name
                data.fileURL = fileURL
                formData.append('image', data)
                console.log('\ntype', filetypes)

                let url = 'upload/image/tenant_folder'

                await ApiUpload(`${url}`, formData)
                  .then(async (res) => {
                    // let temp = [...folderFiles]

                    var name = Date.now()
                    temp[temp?.length] = {
                      fileName: `${getFilename(filename, false)}-${name}.${getFilename(
                        filename,
                        true
                      )}`,
                      fileURL: res?.data?.data?.image,
                      fileSize: replaceFile.size,
                      createdAt: moment(new Date()).format('YYYY-MM-DD'),
                    }

                    const body = {
                      id: selectedFolder?._id,
                      name: selectedFolder?.name,
                      fileList: temp,
                    }
                    await ApiPut(`corporate/tenant_folder`, body)
                      .then((res: any) => {
                        getFolderFilesAfterUpload(selectedFolder)
                        // setOpen(false)
                      })
                      .catch((err: any) => {})
                  })
                  .catch((err) => {
                    console.log('res_blob', err)
                    ErrorToast(err?.message)
                  })
                  .catch((err) => {
                    console.log('res_blob', err)
                    ErrorToast(err?.message)
                  })
              }
              // replaceKeepFile('keep')
              else {
                setOpen(true)

                let formData: any = new FormData()
                let data: any = replaceFile
                console.log(replaceFile)
                let fileURL: any = URL.createObjectURL(data)
                let filetypes: any = replaceFile.type
                let filename: any = replaceFile.name
                data.fileURL = fileURL
                formData.append('image', data)
                console.log('\ntype', filetypes)

                let url = 'upload/image/tenant_folder'

                await ApiUpload(`${url}`, formData)
                  .then((res) => {
                    // let temp = [...folderFiles]

                    for (let j = 0; j < folderFiles?.length; j++) {
                      if (filename == folderFiles[j]?.fileName) {
                        temp[j] = {
                          fileName: filename,
                          fileURL: res?.data?.data?.image,
                          fileSize: replaceFile.size,
                          createdAt: moment(new Date()).format('YYYY-MM-DD'),
                        }
                      }
                    }

                    const body = {
                      id: selectedFolder?._id,
                      name: selectedFolder?.name,
                      fileList: temp,
                    }
                    ApiPut(`corporate/tenant_folder`, body)
                      .then((res: any) => {
                        getFolderFilesAfterUpload(selectedFolder)
                        // setOpen(false)
                      })
                      .catch((err: any) => {})
                  })
                  .catch((err) => {
                    console.log('res_blob', err)
                    ErrorToast(err?.message)
                  })
              }
              // replaceKeepFile('replace')
            }
            setOpen(false)
          }
          // }, 1000)
        } catch (error) {
          console.error(error)
          // Handle errors
        }
      }
    }

    // delete file
    if (val == 1) {
      let temp = [...folderFiles]

      for (let i = 0; i < temp?.length; i++) {
        if (temp[i]?._id == id) temp.splice(i, 1)
      }

      const body = {
        id: selectedFolder?._id,
        name: selectedFolder?.name,
        fileList: temp,
      }
      ApiPut(`corporate/tenant_folder`, body)
        .then((res: any) => {
          setIsDel(true)
          let values = {...selectedFolder}
          values.fileList = temp
          setSelectedFolder(values)

          getFolderFiles(values)
          setOpen(false)
        })
        .catch((err: any) => {})
    }
  }

  //
  const getFilename = (file: any, type: any) => {
    if (type) return file.split('.')[1]
    else return file.split('.')[0]
  }

  //
  const addFolder = () => {
    let isFound: any = false
    for (let i = 0; i < folders?.length; i++) {
      if (folderName == folders[i]?.name) {
        isFound = true
        setFileFound(true)
        i = folders?.length
      }
    }

    if (isFound == false) {
      const body = {
        name: folderName,
        tenantId: `${tenant}`,
        fileList: [],
      }
      ApiPost(`corporate/tenant_folder`, body)
        .then((res: any) => {
          SuccessToast('New Folder has been added successfully!')
          setFolderNameModal(false)
          setTimeout(() => {
            getTenantData()
          }, 1500)
        })
        .catch((err: any) => {})
    } else {
    }
  }

  //
  const updateName = () => {
    const body = {
      id: selectedFolder?._id,
      name: `${newName}.${extension}`,
      fileList: folderFiles,
    }
    ApiPut(`corporate/tenant_folder`, body)
      .then((res: any) => {
        // getFolderFiles(selectedFolder)
        setEditFile(-1)

        const values = [...folderFiles]
        values[newNameInd].fileName = `${newName}.${extension}`
        setFolderFiles(values)
      })
      .catch((err: any) => {})
  }

  // Replace OR Keep File
  const replaceKeepFile = async (val: any) => {
    console.log(replaceFile)
    const files = replaceFile
    // fileLists_single = Array.from(files)

    setOpen(true)

    for (let i = 0; i < fileLists_single.length; i++) {
      let formData: any = new FormData()
      let data: any = files
      console.log(files)
      let fileURL: any = URL.createObjectURL(data)
      let filetypes: any = files.type
      let filename: any = files.name
      data.fileURL = fileURL
      formData.append('image', data)
      console.log('\ntype', filetypes)

      let url = 'upload/image/tenant_folder'

      await ApiUpload(`${url}`, formData)
        .then((res) => {
          let temp = [...folderFiles]
          if (val == 'replace') {
            for (let j = 0; j < folderFiles?.length; j++) {
              if (filename == folderFiles[j]?.fileName) {
                temp[j] = {
                  fileName: filename,
                  fileURL: res?.data?.data?.image,
                  fileSize: files.size,
                  createdAt: moment(new Date()).format('YYYY-MM-DD'),
                }
              }
            }
          } else {
            temp[folderFiles?.length] = {
              fileName: `${getFilename(filename, false)}-copy.${getFilename(filename, true)}`,
              fileURL: res?.data?.data?.image,
              fileSize: files.size,
              createdAt: moment(new Date()).format('YYYY-MM-DD'),
            }
          }

          const body = {
            id: selectedFolder?._id,
            name: selectedFolder?.name,
            fileList: temp,
          }
          ApiPut(`corporate/tenant_folder`, body)
            .then((res: any) => {
              getFolderFiles(selectedFolder)
              setOpen(false)
            })
            .catch((err: any) => {})
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    }
  }

  const [selectedFiles, setSelectedFiles] = useState<any>(0)
  const [selectedFolderCount, setSelectedFolderCount] = useState<any>(0)

  useEffect(() => {
    let values: any = []

    let total: any = 0
    let totalFls: any = 0
    contFolder?.map((fl: any, index: any) => {
      values[index] = fl
      if (fl.isCheck == true) {
        total = total + 1
        for (let j = 0; j < fl?.fileList?.length; j++) {
          if (fl?.fileList?.[j]?.isChecked == true) {
            totalFls = totalFls + 1
          }
        }
      }
    })

    setCompareFolder(values)
    setTotalFolders(total)
    setTotalFiles(totalFls)

    // console.log('total Folders ' + total)
    // console.log('total Files ' + totalFls)
  }, [])

  const [isDisable, setIsDisable] = useState<any>(false)

  const handleDownload = async (imageUrl: any) => {
    setIsDisable(true)
    try {
      const response = await axios.get(imageUrl, {
        responseType: 'blob',
      })

      const blob = new Blob([response.data], {type: response.headers['content-type']})

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'downloaded_image.png'

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      setIsDisable(false)
    } catch (error) {
      setIsDisable(false)
      ErrorToast('Error downloading image')
    }
  }
  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto'
        show={show}
        onHide={() => {
          handleClose(folders, isDel)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            minHeight: '600px',
            maxHeight: '620px',
            overflow: 'auto',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          {/* <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-4 cursor-pointer'
                style={{fontWeight: '800'}}
                onClick={() => {
                  handleClose()
                }}
              >
                X
              </label>
            </div>
          </div> */}

          <div className='d-flex flex-column flex-column-fluid'>
            {/* <form onSubmit={handleSubmit((data: any, e: any) => addTenant(tenantData, e))}> */}
            <div id='kt_app_content' className='app-content flex-column-fluid py-0'>
              <div className='container-xxl pt-0 mt-0 px-0 '>
                <>
                  {folders?.map((fl: any, index: any) => {
                    let total: any = 0
                    if (fl.isCheck === true) {
                      selectedFoldCnt = selectedFoldCnt + 1

                      for (let j = 0; j < fl?.fileList?.length; j++) {
                        if (fl?.fileList?.[j]?.isChecked == true) {
                          total = total + 1
                          selectedFilesCnt = selectedFilesCnt + 1
                        }
                      }
                      if (total == 0) {
                        const values = [...folders]
                        values[index].isCheck = false
                        setFolders(values)
                      }
                    } else {
                      for (let j = 0; j < fl?.fileList?.length; j++) {
                        fl.fileList[j].isChecked = false
                      }
                    }

                    if (index == folders?.length) {
                      setSelectedFolderCount(selectedFoldCnt)
                      setSelectedFiles(selectedFilesCnt)
                    }

                    return <></>
                  })}
                </>

                <div className='d-flex align-items-center'>
                  <h2 className='m-0  ms-2'>
                    <b>{'Select Folder'}</b>
                  </h2>
                  <div className='d-flex ms-auto'>
                    {isManipulated ? (
                      <>
                        {isDeselected ? (
                          <button
                            className='btn btn-sm fw-bold select-btn'
                            type='submit'
                            onClick={() => {
                              onSuccess(folders)
                              // handleClose()
                            }}
                          >
                            <img src={addFolderImg} height={22} width={24} className='me-5' />
                            Update
                          </button>
                        ) : totalFolders < selectedFoldCnt ? (
                          <button
                            className='btn btn-sm fw-bold select-btn'
                            type='submit'
                            onClick={() => {
                              onSuccess(folders)
                              // handleClose()
                            }}
                          >
                            <img src={addFolderImg} height={22} width={24} className='me-5' />
                            Add
                          </button>
                        ) : totalFolders > selectedFoldCnt ? (
                          <button
                            className='btn btn-sm fw-bold select-btn'
                            type='submit'
                            onClick={() => {
                              console.log(folders)
                              onSuccess(folders)
                              // handleClose()
                            }}
                          >
                            <img src={addFolderImg} height={22} width={24} className='me-5' />
                            Update
                          </button>
                        ) : totalFolders == selectedFoldCnt ? (
                          <>
                            <button
                              className='btn btn-sm fw-bold select-btn'
                              type='submit'
                              onClick={() => {
                                console.log(folders)
                                onSuccess(folders)
                                // handleClose()
                              }}
                            >
                              <img src={addFolderImg} height={22} width={24} className='me-5' />
                              Update
                            </button>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    ) : (
                      ''
                    )}

                    <button
                      type='button'
                      className='btn btn-sm fw-bold  ms-3 red-hollow-btn me-3'
                      onClick={(event: any) => {
                        handleClose(folders, isDel)
                        event.stopPropagation()
                      }}
                    >
                      <img src={redCross} height={18} width={18} style={{marginRight: '7px'}} />{' '}
                      Cancel
                    </button>
                  </div>
                </div>
              </div>

              <>
                {folders?.map((fl: any, index: any) => {
                  selectedFoldCnt = 0
                  selectedFilesCnt = 0

                  return <></>
                })}
              </>

              <>
                {folders?.map((fl: any, index: any) => {
                  let total: any = 0
                  if (fl.isCheck === true) {
                    selectedFoldCnt = selectedFoldCnt + 1

                    for (let j = 0; j < fl?.fileList?.length; j++) {
                      if (fl?.fileList?.[j]?.isChecked == true) {
                        total = total + 1
                        selectedFilesCnt = selectedFilesCnt + 1
                      }
                    }
                    if (total == 0) {
                      const values = [...folders]
                      values[index].isCheck = false
                      setFolders(values)
                    }
                  } else {
                    for (let j = 0; j < fl?.fileList?.length; j++) {
                      fl.fileList[j].isChecked = false
                    }
                  }

                  if (index == folders?.length) {
                    setSelectedFolderCount(selectedFoldCnt)
                    setSelectedFiles(selectedFilesCnt)
                  }

                  return <></>
                })}
              </>

              {/*  */}
              <></>

              <div className='d-flex align-items-center '>
                <h4 className='ms-3 my-2 me-4' style={{color: 'black'}}>
                  <label style={{width: 'fit-content'}} className='labl-gry'>
                    {' '}
                    Folders Selected{' '}
                  </label>
                  <span className='ms-5 ps-1'>
                    <b>{selectedFoldCnt}</b>
                  </span>
                </h4>
                <h4 className='ms-5 my-2' style={{color: 'black'}}>
                  <label style={{width: 'fit-content'}} className='labl-gry'>
                    {' '}
                    Files Selected{' '}
                  </label>
                  <span className='ms-5 ps-1'>
                    <b>{selectedFilesCnt}</b>
                  </span>
                </h4>
              </div>
              <div id='kt_app_content_container' className='container-xxl mt-2'>
                <div className='d-flex flex-column '>
                  <>
                    <div className='row g-6 g-xl-9 mb-6 mb-xl-12'>
                      {!viewFolder && (
                        <div className='col-lg-12 col-md-12 text-end' style={{textAlign: 'right'}}>
                          <button
                            type='button'
                            className='btn btn-sm fw-bold blue-hollow-btn ms-auto'
                            style={{width: '130px'}}
                            onClick={() => {
                              setFileFound(false)
                              setFolderNameModal(true)
                            }}
                          >
                            <img
                              src={plusBlue}
                              height={18}
                              width={18}
                              style={{
                                marginRight: '7px',
                              }}
                            />
                            New Folder
                          </button>
                        </div>
                      )}

                      {/* Add New Folder Card */}
                      {folderNameModal && (
                        <div className='col-md-6 col-lg-4 col-xl-4'>
                          <div className='card h-100'>
                            <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4 test'>
                              <div className='d-flex'>
                                <img
                                  src={trashImg}
                                  height={18}
                                  width={18}
                                  className='cursor-pointer'
                                  onClick={() => setFolderNameModal(false)}
                                />

                                <img
                                  style={
                                    folderName?.trim()?.length > 0
                                      ? {opacity: '1', cursor: 'pointer'}
                                      : {opacity: '0.7', cursor: 'not-allowed'}
                                  }
                                  src={correct}
                                  height={30}
                                  width={30}
                                  className='ms-auto'
                                  onClick={() => {
                                    if (folderName?.trim()?.length > 0) addFolder()
                                  }}
                                />
                              </div>

                              <a className='text-gray-800 text-hover-primary d-flex flex-column'>
                                <div className='symbol symbol-60px mb-5'>
                                  <img
                                    src={
                                      tenantData?.document?.contract?.split('.')[1] === 'pdf'
                                        ? pdfDocument
                                        : tenantData?.other?.split('.')[1] === 'doc'
                                        ? docCocument
                                        : folderDocument
                                    }
                                    className='theme-light-show'
                                    alt=''
                                  />
                                  <img
                                    src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                    className='theme-dark-show'
                                    alt=''
                                  />
                                </div>
                                <div className='fs-5 fw-bold mb-2 '>
                                  <input
                                    type='text'
                                    className='form-control form-control-solid mytest w-75 m-auto'
                                    placeholder='Enter Folder Name'
                                    onChange={(e: any) => setFolderName(e.target.value)}
                                  >
                                    {fileFound ? (
                                      <p className='mt-2' style={{color: 'red'}}>
                                        Folder name already exists
                                      </p>
                                    ) : null}
                                  </input>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                      {folders?.length > 0 ? (
                        <>
                          {/* { !viewFolder  && */}
                          <>
                            {folders?.map((fl: any, index: any) => {
                              return (
                                <div className='col-md-6 col-lg-4 col-xl-4'>
                                  <div className='card h-100'>
                                    <div className='card-body d-flex text-center flex-column px-4 py-4 test'>
                                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          id='vacant0'
                                          name='none'
                                          checked={fl?.isCheck ? true : false}
                                          // value={fl.selected}
                                          onChange={() => {
                                            if (fl?.fileList?.length > 0) {
                                              setIsManipulated(true)

                                              // if (!fl?.isSystemGenerated) {
                                              const values = [...folders]
                                              values[index].isCheck = !values[index].isCheck

                                              if (values[index].isCheck == false) {
                                                setIsDeselected(true)
                                                for (
                                                  let i = 0;
                                                  i < values[index]?.fileList?.length;
                                                  i++
                                                ) {
                                                  values[index].fileList[i].isChecked = false
                                                }
                                              } else {
                                                // setIsAdded('new')
                                                for (
                                                  let i = 0;
                                                  i < values[index]?.fileList?.length;
                                                  i++
                                                ) {
                                                  values[index].fileList[i].isChecked = true
                                                }
                                              }

                                              setFolders(values)
                                              // console.log(values)

                                              for (let k = 0; k < values?.length; k++) {
                                                if (values[k].isCheck == false) {
                                                  // console.log(values[k].isCheck)
                                                  //
                                                  for (let j = 0; j < contFolder?.length; j++) {
                                                    // console.log(contFolder[j].isCheck)

                                                    if (contFolder[j].isCheck == true) {
                                                      // console.log(contFolder[j]._id)
                                                      // console.log(values[k]._id)
                                                      if (contFolder[j]._id == values[k]._id) {
                                                        // console.log(contFolder[j]._id)
                                                        // console.log(values[k]._id)
                                                        if (values[k].isCheck == false) {
                                                          setIsDeselected(true)
                                                          setIsAdded(true)
                                                          j = contFolder?.length
                                                          k = values?.length
                                                        } else {
                                                          setIsAdded(true)
                                                          setIsDeselected(false)
                                                        }
                                                      }
                                                    }
                                                  }
                                                } else {
                                                }
                                              }
                                              // console.log(isAdded)
                                              // console.log(isDeselected)

                                              // }
                                            } else {
                                              ErrorToast('This folder does not contain any file..')
                                            }
                                          }}
                                        />
                                      </div>

                                      <a
                                        // href={`${Bucket}${tenantData?.document?.contract}`}
                                        // target='_blank'
                                        onClick={() => {
                                          setViewFolderInd(index)
                                          getFolderFiles(fl)
                                        }}
                                        className='text-gray-800 text-hover-primary d-flex flex-column'
                                      >
                                        <div className='symbol symbol-60px mb-5'>
                                          <img
                                            src={
                                              tenantData?.document?.contract?.split('.')[1] ===
                                              'pdf'
                                                ? pdfDocument
                                                : tenantData?.other?.split('.')[1] === 'doc'
                                                ? docCocument
                                                : folderDocument
                                            }
                                            className='theme-light-show'
                                            alt=''
                                          />
                                          <img
                                            src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                            className='theme-dark-show'
                                            alt=''
                                          />
                                        </div>
                                        <div className='fs-5 fw-bold mb-2'>{fl?.name}</div>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </>
                        </>
                      ) : (
                        <>
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '440px',
                              overflow: 'hidden',
                            }}
                          >
                            <CircularProgress style={{color: '#146c6a'}} />
                          </Box>
                        </>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* View Folder Modal */}
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-800px mx-auto'
        show={viewFolder}
        onHide={() => {
          setViewFolder(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-3 px-lg-3  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            maxHeight: '630px',
            overflow: 'auto',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
          }}
        >
          <div className='d-flex flex-column flex-column-fluid '>
            <div id='kt_app_content' className='app-content flex-column-fluid py-0'>
              <div id='kt_app_content_container' className='container-xxl mt-1'>
                <div className='d-flex flex-column '>
                  <>
                    <div className='row g-6 g-xl-9 mb-6 mb-xl-12'>
                      {folders?.length > 0 && (
                        <>
                          {viewFolder ? (
                            <>
                              <div className='d-flex align-items-center'>
                                <span
                                  className='rounded-circle'
                                  style={{cursor: 'pointer'}}
                                  onClick={() => {
                                    setViewFolder(false)
                                    setEditFile(-1)
                                  }}
                                >
                                  <img
                                    src={backArrow}
                                    style={{stroke: 'red'}}
                                    height='14'
                                    width='14'
                                  />
                                </span>
                                <h2 className='m-0 ms-2'>
                                  <b>{selectedFolder?.name}</b>
                                </h2>

                                <label
                                  title='Add New File'
                                  htmlFor='uploadPassport'
                                  className='btn btn-sm fw-bold upload-btn ms-auto'
                                >
                                  <img src={upload} height={18} width={18} className='me-3' />
                                  Upload File{' '}
                                </label>
                                <input
                                  type='file'
                                  hidden
                                  id='uploadPassport'
                                  className='form-control form-control-solid'
                                  placeholder='upload'
                                  name='passport'
                                  onChange={(e: any) => {
                                    onFilesAddedSingle(e, 0, '')
                                  }}
                                  multiple
                                />
                              </div>
                              <div className='col-md-12 col-lg-12 col-xl-12'>
                                <div className='h-100'>
                                  <div className='d-flex justify-content-center flex-column py-4 px-2'>
                                    {selectedFolder?.fileList?.length > 0 ? (
                                      <>
                                        <div className='row py-3' style={{color: '#a1a5b7'}}>
                                          <div className='col-md-1'></div>
                                          <div className='col-md-5 '>File</div>
                                          <div className='col-md-2 '>Date Added</div>
                                          <div className='col-md-4'></div>
                                        </div>

                                        {selectedFolder?.fileList?.map((file: any, ind: any) => {
                                          return (
                                            <div
                                              className='row py-3'
                                              style={{borderBottom: '1px solid lightgrey'}}
                                            >
                                              <div className='col-md-1 test'>
                                                <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 '>
                                                  <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    id='vacant01'
                                                    name='none-1'
                                                    checked={file?.isChecked ? true : false}
                                                    // value={file.isChecked}
                                                    onChange={() => {
                                                      setIsManipulated(true)
                                                      console.log()
                                                      console.log(selectedFolder)
                                                      const values = {...selectedFolder}

                                                      values.fileList[ind].isChecked =
                                                        !values.fileList[ind].isChecked

                                                      if (values.fileList[ind].isChecked == false) {
                                                        setIsDeselected(true)
                                                      }

                                                      let temp = [...folders]

                                                      if (values.fileList[ind].isChecked)
                                                        temp[viewFolderInd].isCheck = true

                                                      setSelectedFolder(values)
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                              {editFile == ind ? (
                                                <>
                                                  <div className='col-md-5 head-text pe-5'>
                                                    <div className='d-flex'>
                                                      <input
                                                        type='text'
                                                        value={newName}
                                                        className='form-control form-control-solid p-2'
                                                        onChange={(e: any) => {
                                                          let temp = getFilename(
                                                            file?.fileName,
                                                            true
                                                          )
                                                          setNewName(e.target.value)

                                                          setExtension(temp)
                                                        }}
                                                      />
                                                      <a
                                                        className='btn btn-sm fw-bold btn-primary btn-green'
                                                        onClick={() => updateName()}
                                                      >
                                                        <img src={check} height={18} width={18} />
                                                      </a>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <div className='col-md-5 head-text'>
                                                  {getFilename(file?.fileName, false)?.length > 30
                                                    ? `${file?.fileName?.slice(
                                                        0,
                                                        30
                                                      )}...${getFilename(file?.fileName, true)}`
                                                    : file?.fileName}
                                                </div>
                                              )}
                                              <div className='col-md-2 head-text'>
                                                {moment(file?.createdAt).format('DD.MM.YYYY')}
                                              </div>
                                              <div className='col-md-4 head-text text-end'>
                                                <div className='d-flex justify-content-end'>
                                                  {(((user?.isSubUser &&
                                                    role?.leasing_residential?.lease_agreements
                                                      ?.tenant_detail?.create) ||
                                                    !user?.isSubUser) ||
                                                    ((user?.isSubUser &&
                                                      role?.leasing_residential?.lease_agreements
                                                        ?.tenant_detail?.update) ||
                                                    !user?.isSubUser)) && (
                                                    <img
                                                      src={eye}
                                                      height={20}
                                                      width={20}
                                                      className='me-3 cursor-pointer'
                                                      onClick={() => {
                                                        setIndex(ind)
                                                      }}
                                                    />
                                                  )}
                                                  <img
                                                    src={downloadImg}
                                                    height={22}
                                                    width={22}
                                                    className='me-10 cursor-pointer'
                                                    onClick={() => {
                                                      if (!isDisable)
                                                        handleDownload(Bucket + file?.fileURL)
                                                    }}
                                                    style={
                                                      isDisable ? {opacity: '0.6'} : {opacity: '1'}
                                                    }
                                                  />

                                                  <img
                                                    src={pencil}
                                                    height={17}
                                                    width={17}
                                                    className='me-3 cursor-pointer'
                                                    onClick={() => {
                                                      setNewName(getFilename(file?.fileName, false))
                                                      setEditFile(ind)
                                                      setNewNameInd(ind)
                                                    }}
                                                  />
                                                  <img
                                                    src={trashImg}
                                                    height={18}
                                                    width={18}
                                                    className='me-5'
                                                    style={{cursor: 'pointer'}}
                                                    onClick={(e) => {
                                                      Swal.fire({
                                                        text: 'Are you sure you want to permanently delete this file?',
                                                        icon: 'warning',
                                                        showConfirmButton: true,
                                                        confirmButtonColor: '#D72852',
                                                        confirmButtonText: 'Yes',
                                                        showCancelButton: true,
                                                        // cancelButtonColor: "transparent",
                                                        cancelButtonText: 'Cancel',
                                                      }).then((res: any) => {
                                                        if (res.isConfirmed) {
                                                          onFilesAddedSingle(e, 1, file?._id)
                                                        }
                                                      })
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          )
                                        })}
                                      </>
                                    ) : (
                                      <div className='text-center'> No Files</div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {folders?.map((fl: any, index: any) => {
                                return (
                                  <div className='col-md-6 col-lg-4 col-xl-4'>
                                    <div className='card h-100'>
                                      <div className='card-body d-flex justify-content-center text-center flex-column px-4 py-4 test'>
                                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                          <input
                                            className='form-check-input'
                                            type='checkbox'
                                            id='vacant0'
                                            name='none'
                                            checked={fl?.isCheck ? true : false}
                                            // value={fl.selected}
                                            onChange={() => {
                                              if (!fl?.isSystemGenerated) {
                                                const values = [...folders]
                                                console.log(values)
                                                values[index].isCheck = !values[index].isCheck
                                                setFolders(values)
                                              }
                                            }}
                                          />
                                        </div>

                                        <a
                                          onClick={() => getFolderFiles(fl)}
                                          className='text-gray-800 text-hover-primary d-flex flex-column'
                                        >
                                          <div className='symbol symbol-60px mb-5'>
                                            <img
                                              src={
                                                tenantData?.document?.contract?.split('.')[1] ===
                                                'pdf'
                                                  ? pdfDocument
                                                  : tenantData?.other?.split('.')[1] === 'doc'
                                                  ? docCocument
                                                  : folderDocument
                                              }
                                              className='theme-light-show'
                                              alt=''
                                            />
                                            <img
                                              src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                                              className='theme-dark-show'
                                              alt=''
                                            />
                                          </div>
                                          <div className='fs-5 fw-bold mb-2'>{fl?.name}</div>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                )
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/*  */}
      <Lightbox
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        slides={slides}
        carousel={{finite: slides.length <= 1}}
        render={{
          buttonPrev: slides.length <= 1 ? () => null : undefined,
          buttonNext: slides.length <= 1 ? () => null : undefined,
        }}
      />

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default TenantFolders
