import moment from 'moment'
import React, {useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {AiFillDelete, AiFillDownSquare, AiFillUpSquare} from 'react-icons/ai'
import PhoneInput from 'react-phone-input-2'
import {useNavigate} from 'react-router-dom'
import noData from '../../../img/NoData1.svg'

import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../_metronic/helpers'
import AppVendormodal from './AppVendormodal'
import SignatureModal from './SignatureModal'

const vendorListArrayData: any = []

const ApprovalWorkflow = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
  } = useForm<any>()
  const [country, setCountry] = React.useState([])
  const [city, setCity] = React.useState([])
  const [formData, setFormData] = useState<any>({})
  const [showAddVendorModal, setShowAppVendorModal] = React.useState<boolean>(false)
  const [signatureModal, setSignatureModal] = React.useState<boolean>(false)
  const [vendorsList, setVendorsList] = React.useState('Vendor List')
  const [listData, setListData] = React.useState<any>()
  const [uploadedFile, setUploadedFile] = React.useState<any>('')
  const [countryName, setCountryName] = React.useState<any>('')
  const [vendorList, setVendorList] = React.useState<any>()
  const [vendorListArray, setVendrorListArray] = React.useState<any>()
  const [approvalData, setApprovalData] = React.useState<any>()
  const [data, setData] = React.useState<any>()
  const [hide, setHide] = React.useState<any>(false)

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(res?.data?.data)
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  const vendorListItems = new Array(5).fill(0)
  const approvalWorkflow = new Array(2).fill(0)

  React.useEffect(() => {
    getCountry()
  }, [])

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }

  const uploadFilelHandler = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/document', formData)
      .then((res: any) => setUploadedFile(res?.data?.data?.image))
      .catch((err: any) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }

  const handleCountryChnage = async (e: any) => {
    const {name, value} = e.target
    const data = value.split('/')
    setCountryName(data[1])
    await ApiGet(`country/cities?countryId=${data[0]}`)
      .then((res) => {
        setCity(res?.data?.data)
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  const handleNumber = (Phone: any, e: any) => {
    let CountryCode = e.dialCode
    let PhoneNumber = Phone.split(CountryCode)[1]

    setFormData({
      ...formData,
      ['countryCode']: parseInt(CountryCode),
      ['phoneNumber']: parseInt(PhoneNumber),
    })
  }

  const geApprovalData = async () => {
    const body = {
      vendorId: window.location.pathname?.split('/')[4],
    }
    await ApiPost('corporate/approval_request/get', body)
      .then((res) => {
        setListData(res?.data?.data)
        setApprovalData(res?.data?.data?.vendorApprovalRequest)
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  const getvendor = async () => {
    await ApiGet(`corporate/vendor/${window.location.pathname?.split('/')[4]}`)
      .then((res) => {
        setFormData(res?.data?.data[0])
        setVendrorListArray(res?.data?.data[0]?.vendorListIds)
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  const getvendorList = () => {
    ApiGet('corporate/vendor_list')
      .then((res) => {
        setVendorList(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  React.useEffect(() => {
    geApprovalData()
    getvendor()
    getvendorList()
  }, [])

  const sendBackApproval = () => {
    const body = {
      id: approvalData[0]?._id,
      approvalStatus: 4,
    }

    ApiPut('corporate/approval_request', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        navigate(`/vendor-management/management-requests`)
      })
      .catch((err) => ErrorToast(err.message))
  }

  const rejectApproval = () => {
    const body = {
      id: approvalData[0]?._id,
      approvalStatus: 3,
    }

    ApiPut('corporate/approval_request', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        navigate(`/vendor-management/management-requests`)
      })
      .catch((err) => ErrorToast(err.message))
  }
  const deleteVendorList = (id: any) => {
    const body = {
      vendorId: window.location.pathname?.split('/')[4],
      vendorListId: id,
    }
    ApiPost('corporate/vendor_list/remove_vendor/by_id', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        getvendor()
      })
      .catch((err) => ErrorToast(err.message))
  }

  const deleteTradeLicence = () => {
    const body = {
      url: formData?.image,
    }
    ApiPost('upload/delete_file', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        editVendor()
      })
      .catch((err) => ErrorToast(err.message))
  }

  const editVendor = async () => {
    const body = {
      id: window.location.pathname?.split('/')[4],
      image: null,
    }
    await ApiPut(`corporate/vendor`, body)
      .then((res) => {
        getvendor()
      })
      .catch((err) => ErrorToast(err.message))
  }

  return (
    <>
      <div
        className='pp-container container-xxl pt-0 mt-0 d-flex flex-column gap-5'
        id='kt_app_main'
      >
        <div className='d-flex align-items-baseline flex-wrap mr-5'>
          <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate('/vendor-management/management-requests')}
              >
                Requests
              </a>
            </li>
            <li className='breadcrumb-item active'>
              <a className='px-2'> Approved workflow</a>
            </li>
          </ul>
        </div>
        <div className='card p-5 align-items-center flex-row '>
          {/* <button
            className='btn btn-sm fw-bold btn-primary btn-green  hover-none'
            onClick={() => navigate(`/vendor-management/management-requests`)}
          >
            Back
          </button> */}
          <Row className='w-100'>
            <Col md={4}>
            <div className='d-flex gap-5'>
            <h6 className='p-0 m-0 text-muted align-items-center'>
              Request Status :
              <div className='mx-1 value_black'>
                <div className='d-flex gap-1 align-items-center'>
                  <input
                    className='form-check-input'
                    type='radio'
                    checked={listData?.isUnderApproval}
                    disabled
                  />
                  Under Approval
                </div>
              </div>
            </h6>
          </div>
            </Col>
            <Col md={3}>
            <div>
            <h6 className='p-0 m-0 text-muted align-items-center'>
              Pending With :
              <div className='mx-1 value_black'>
                {listData?.pendingWith[0]?.corporate[0]?.firstName}
                {listData?.pendingWith[0]?.corporate[0]?.lastName}{' '}
                {moment(listData?.pendingWith[0]?.corporate[0]?.updatedAt).format(
                  'DD/MM/YYYY  HH:mm:ss'
                )}
              </div>
            </h6>
          </div>
          </Col>
          <Col md={5}>
          <div className='d-flex align-items-center gap-5'>
            <button
              onClick={() => setSignatureModal(true)}
              className='btn btn-sm fw-bold btn-primary btn-green p-auto hover-none'
            >
              Approve
            </button>
            <button
              onClick={() => sendBackApproval()}
              className='btn btn-sm fw-bold btn-primary btn-green p-auto hover-none'
            >
              Send Back
            </button>
            <button
              onClick={() => rejectApproval()}
              className='btn btn-sm fw-bold btn-primary btn-green p-auto hover-none'
            >
              Reject
            </button>
          </div>
          </Col>
            
          </Row>
         
       
         
        </div>
        {/* <div className='mb-8 min-w-375px d-flex align-items-center justify-content-between w-auto'>
            <div className=''>
              <div className=''>
                <button
                  className='btn btn-sm fw-bold me-5 btn-primary btn-green w-80px mb-2 hover-none'
                  onClick={() => navigate(`/vendor-management/management-requests`)}
                >
                  Back
                </button>
              </div>
            </div>

            <div className='d-flex'>
              <div>Request Status</div>:{' '}
              <div className='d-flex'>
                {' '}
                {listData?.isUnderApproval === true ? (
                  <div
                    style={{
                      height: '10px',
                      width: '10px',
                      borderRadius: '50%',
                      background: '#007a59',
                      marginTop: '5px',
                      marginRight: '3px',
                    }}
                  ></div>
                ) : (
                  <div
                    style={{
                      height: '10px',
                      width: '10px',
                      borderRadius: '50%',
                      border: '2px solid black',
                      marginTop: '5px',
                      marginRight: '3px',
                    }}
                  ></div>
                )}
                Under Approval{' '}
              </div>{' '}
            </div>

            <div>
              Pending With: {listData?.pendingWith[0]?.corporate[0]?.firstName}
              {listData?.pendingWith[0]?.corporate[0]?.lastName}-
              {moment(listData?.pendingWith[0]?.corporate[0]?.updatedAt).format(
                'DD/MM/YYYY  HH:mm:ss'
              )}
            </div>
            <div className=''>
              <div className=''>
                <button
                  onClick={() => setSignatureModal(true)}
                  className='btn btn-sm fw-bold me-5 btn-primary btn-green w-80px me-2 hover-none'
                >
                  Approve
                </button>
                <button
                  onClick={() => sendBackApproval()}
                  className='btn btn-sm fw-bold me-5 btn-primary btn-green w-100px me-2 hover-none'
                >
                  Send Back
                </button>
                <button
                  onClick={() => rejectApproval()}
                  className='btn btn-sm fw-bold me-5 btn-primary btn-green w-80px me-2 hover-none'
                >
                  Reject
                </button>
              </div>
            </div>
          </div> */}
        {hide !== true && (
          <div className='card p-5 mb-10'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-center text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-100px'>User</th>
                  <th className='min-w-100px'>Updated</th>
                  <th className='min-w-70px'>Status</th>
                  <th className='min-w-100px'>Signature</th>
                  <th className='min-w-100px'>Comments</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {approvalData?.length ? (
                  approvalData?.map((f: any, i: any) => {
                    return (
                      <tr className='text-center'>
                        <td>
                          {f.corporate[0]?.firstName} {f.corporate[0]?.lastName}
                        </td>
                        <td>{moment(f.corporate[0]?.updatedAt).format('DD/MM/YYYY')}</td>
                        <td>
                          {f?.approvalStatus === 2
                            ? 'Approved'
                            : f?.approvalStatus === 3
                            ? 'Rejected'
                            : f?.approvalStatus === 1
                            ? 'Requested'
                            : f?.approvalStatus === 0
                            ? 'pending Approval'
                            : 'Send Back'}
                        </td>
                        <td>
                          {f?.eSignature && (
                            <a
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                              href={`${Bucket}${f?.eSignature}`}
                              target='_blank'
                            >
                              e signature
                            </a>
                          )}
                          {f?.writtenSignature && (
                            <a
                              className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                              href={`${Bucket}${f?.writtenSignature}`}
                              target='_blank'
                            >
                              written signature
                            </a>
                          )}
                        </td>
                        <td>{f.comment}</td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <td colSpan={16} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        <div className='card p-5 text-muted arrow_approval_request_bottom'>
          <div
            className='d-flex justify-content-center arrow_approval_request'
            style={{cursor: 'pointer'}}
          >
            {hide === false && (
              <AiFillUpSquare color='#007a59' fontSize={40} onClick={() => setHide(true)} />
            )}
            {hide === true && (
              <AiFillDownSquare color='#007a59' fontSize={40} onClick={() => setHide(false)} />
            )}
          </div>

          <div className='tops d-flex justify-content-between align-items-center p-5'>
            <h6 className=' mx-0 text-muted align-items-center'>
              Vendor Type
              <div className='mx-1 value_black'>Service Provider</div>
            </h6>
            <h6 className=' mx-0 text-muted align-items-center'>
              Vendor ID
              <div className='mx-1 value_black'>{formData?.id}</div>
            </h6>
            <h6 className=' mx-0 text-muted align-items-center'>
              Date Added
              <div className='mx-1 value_black'>
                {moment(formData?.createdAt).format('DD/MM/YYYY')}
              </div>
            </h6>
          </div>
          <div className='px-5 py-3 side'>
            <h1 className='top green_color'>Company Details</h1>
            <h6 className='mx-0 text-muted align-items-center'>
              Company Name
              <div className='mx-1 value_black'>{formData?.companyName}</div>
            </h6>{' '}
            <h6 className='mx-0 text-muted align-items-center'>
              Trade License No.
              <div className='mx-1 value_black d-flex gap-2 align-items-center'>
                {formData?.tradeLicenseNo}{' '}
                {formData?.image && (
                  <div className='d-flex'>
                    <a
                      className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                      href={`${Bucket}${formData?.image}`}
                      target='_blank'
                    >
                      Trade License
                    </a>
                    <AiFillDelete
                      style={{cursor: 'pointer'}}
                      onClick={deleteTradeLicence}
                      color='#007a59'
                      fontSize={20}
                    />
                  </div>
                )}
              </div>
            </h6>{' '}
            <h6 className='mx-0 text-muted align-items-center'>
              TRN
              <div className='mx-1 value_black'>{formData?.TRN}</div>
            </h6>{' '}
            <h6 className='mx-0 text-muted align-items-center'>
              Country
              <div className='mx-1 value_black'>{formData?.country}</div>
            </h6>{' '}
            <h6 className='mx-0 text-muted align-items-center'>
              City
              <div className='mx-1 value_black'>{formData?.city}</div>
            </h6>
            <h6 className='mx-0 text-muted align-items-center'>
              Vendor List
              <div className='mx-1 value_black'>
                {' '}
                <button
                  className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                  onClick={() => setShowAppVendorModal(true)}
                >
                  Add To List
                </button>
              </div>
            </h6>
            <ul className='d-flex flex-wrap'>
              {formData &&
                vendorList
                  ?.filter((v: any) => vendorListArray?.includes(v._id))
                  .map((item: any, index: any) => {
                    return (
                      <div className='d-flex pe-10'>
                        <li className='pe-2'>{item?.name}</li>
                        <AiFillDelete
                          color='#007a59'
                          fontSize={20}
                          onClick={() => deleteVendorList(item?._id)}
                        />
                      </div>
                    )
                  })}
            </ul>
          </div>
          <div className='px-5 py-3 side'>
            <h1 className='top green_color'>Main Contact Detail</h1>
            <h6 className=' mm-0 text-muted align-items-center'>
              Contact Name
              <div className='w-50 d-flex align-items-center'>
                {formData?.firstName} {formData?.lastName}
              </div>
            </h6>{' '}
            <h6 className=' mx-0 text-muted align-items-center'>
              Contact Email
              <div className='mx-1 value_black'>{formData?.email}</div>
            </h6>{' '}
            <h6 className=' mx-0 text-muted align-items-center'>
              Contract (Mob No)
              <div className='mx-1 value_black'>{formData?.phoneNumber}</div>
            </h6>{' '}
          </div>

          {/* <Row className='py-2'>
            <div className='d-flex justify-content-between'>
              <span className='mx-10 mt-5'>
                <b>Vendor Type: </b>Service Provider
              </span>
              <span className='mx-10 mt-5'>
                <b>Vendor ID: </b>
                {formData?.id}{' '}
              </span>
              <span className='mx-10 mt-5'>
                <b>Date Added: </b>
                {moment(formData?.createdAt).format('DD/MM/YYYY')}
              </span>
            </div>
            <Col lg={6}>
              <h3 className='mx-10 mb-10 mt-10'>Company Details </h3>
              <div className='min-w-200px mx-10'>
                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                  <h6 className='text-muted m-0 me-2'>Company Name:</h6>
                  <b>{formData?.companyName}</b>
                </div>
                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                  <h6 className='text-muted m-0 me-2'>Trade License No.:</h6>
                  <b>{formData?.tradeLicenseNo}</b>
                  {formData?.image && (
                    <div className='d-flex'>
                      <a
                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                        href={`${Bucket}${formData?.image}`}
                        target='_blank'
                      >
                        Trade License
                      </a>
                      <AiFillDelete
                        style={{cursor: 'pointer'}}
                        onClick={deleteTradeLicence}
                        color='#007a59'
                        fontSize={20}
                      />
                    </div>
                  )}
                </div>
                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                  <h6 className='text-muted m-0 me-2'>TRN:</h6>
                  <b>{formData?.TRN}</b>
                </div>
                {errors.companyName && (
                  <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                )}
                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                  <div className='pe-5 w-50 d-flex align-items-center'>
                    <h6 className='text-muted m-0 me-2'>Country:</h6>
                    <b>{formData?.country}</b>
                  </div>
                  <div className='w-50 d-flex align-items-center'>
                    <h6 className='text-muted m-0 me-2'>City:</h6>
                    <b>{formData?.city}</b>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <h3 className='mx-10 mb-10 mt-10'>Main Contact Detail </h3>
              <div className='min-w-200px mx-10'>
                <div className='payment-receipt-item mb-5'>
                  <div className='d-flex'>
                    <h6 className='text-muted m-0 me-2 min-w-100px'>Contact Name</h6>
                    <div className='w-50 pe-5 d-flex align-items-center'>
                      <h6 className='text-muted m-0 me-2'>First Name:</h6>
                      <b>{formData?.firstName}</b>
                    </div>
                    <div className='w-50 d-flex align-items-center'>
                      <h6 className='text-muted m-0 me-2'>Last Name:</h6>
                      <b>{formData?.lastName}</b>
                    </div>
                  </div>
                </div>
                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                  <h6 className='text-muted m-0 me-2'>Contact Email:</h6>
                  <b>{formData?.email}</b>
                </div>
                {errors.companyName && (
                  <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                )}
                <div className='payment-receipt-item d-flex align-items-center mb-5'>
                  <h6 className='text-muted m-0 me-2'>Contract (Mob No):</h6>
                  <b>{formData?.phoneNumber}</b>
                </div>
              </div>
            </Col>
            <div className='payment-receipt-item mb-5 mx-10'>
              <div className='d-flex align-items-center mb-5'>
                <h6 className='text-muted m-0 me-2'>Vendor List</h6>
                <button
                  className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none'
                  onClick={() => setShowAppVendorModal(true)}
                >
                  Add To List
                </button>
              </div>
              <ul className='d-flex flex-wrap'>
                {formData &&
                  vendorList
                    ?.filter((v: any) => vendorListArray?.includes(v._id))
                    .map((item: any, index: any) => {
                      return (
                        <div className='d-flex pe-10'>
                          <li className='pe-2'>{item?.name}</li>
                          <AiFillDelete
                            color='#007a59'
                            fontSize={20}
                            onClick={() => deleteVendorList(item?._id)}
                          />
                        </div>
                      )
                    })}
              </ul>
            </div>
          </Row> */}
        </div>
      </div>

      <SignatureModal
        show={signatureModal}
        handleClose={() => {
          setSignatureModal(false)
        }}
        setFormData={setFormData}
        formData={approvalData?.map((f: any) => f)}
      />

      <AppVendormodal
        show={showAddVendorModal}
        handleClose={() => {
          setShowAppVendorModal(false)
        }}
        setFormData={setFormData}
        formData={formData}
        vendorsList={vendorsList}
        vendorList={vendorList}
        vendorListArray={vendorListArrayData}
        setVendrorListArray={setVendrorListArray}
        type={'approvalVendor'}
        getVendorDataById={getvendor}
      />
    </>
  )
}

export default ApprovalWorkflow
