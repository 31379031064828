import React from 'react'
import {Col, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import {AiFillDelete} from 'react-icons/ai'
import {ApiPost, ApiPut, ApiGet} from '../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import back from '../../../img/left-arrow.png'
import plus from '../../../img/plus.svg'
import approve from '../../../img/approve.png'
import search from '../../../img/search.png' 


const InternalTeam = () => {
  const navigate = useNavigate()
  const Vendors = new Array(2).fill(0)
  const [internalStaff, setInternalStaff] = React.useState<any>()
  const [searchStaff, setSearchStaff] = React.useState<any>()
  const [searchDepartMentName, setSearchDepartMent] = React.useState<any>()
  const [searchTitle, setSearchTitle] = React.useState<any>()
  const [pageLimit, setPageLimit] = React.useState<any>()
  const [page, setPage] = React.useState<any>(1)
  const [checked, setChecked] = React.useState<any>()
  const [selectedItem, setSelectedItem] = React.useState<any>()
  const currentTab: any = localStorage.getItem('currentTab')
  // const requestServiceId: any = localStorage.getItem(currentTab)
  // const requestServiceId: any = localStorage.getItem('serviceRequestId')

  const activeTab: any = localStorage.getItem('activeTab')
  const requestServiceId: any = localStorage.getItem('serviceRequestId'+activeTab)

  var staffServiceId: any  

  const getInternalStaff = async () => {
    // const body = {
    //   page: page,
    //   limit: 10,
    //   searchName: searchStaff ? searchStaff : '',
    //   searchDepartment: searchDepartMentName ? searchDepartMentName : '',
    //   searchTitle: searchTitle ? searchTitle : '',
    // }
    // await ApiPost(`corporate/staff/get`, body)
    //   .then((res) => {
    //     // setTableData(res?.data?.data)
    //     setInternalStaff(res?.data?.data?.staff_data)
    //     setPageLimit(res?.data?.data?.state?.page_limit)

    //     console.log(staffServiceId)

    //     let temp = res?.data?.data?.staff_data
    //     for(let i=0; i< temp.length; i++){
    //       if(temp[i]._id === staffServiceId){

    //         setSelectedItem(temp[i])
    //         setChecked(i)
    //       }

    //     }
    //   })
    //   .catch((e) => {
    //     console.log(e)
    //   })
  }

  const addServiceRequest = async () => {
    const body = {
      requestType: 2,
    }
    await ApiPost(`corporate/service_request`, body)
      .then((res) => {
        localStorage.setItem(currentTab, res?.data?.data?._id)
      })
      .catch((e) => {
        console.log(e)
      })
  }


  const getRequestDataById = async () => {
    // console.log(requestServiceId)
    // let temp = localStorage.getItem('serviceRequestId' + requestServiceId)
    // console.log(temp)

   let mainService:any
   let subService : any
   let staff : any
   let vendor: any
    await ApiGet(`corporate/service_request/`+requestServiceId)
      .then((res) => {
        // console.log("In property list service by ID ")
        // console.log(res?.data?.data)

        let x = localStorage.getItem('requestType')
        if (x === '1') {
          // console.log(res?.data?.data[0].staff[0].firstName)
          let temp = res?.data?.data[0].staff[0].firstName + ' ' + res?.data?.data[0].staff[0].lastName
          localStorage.setItem('internalExternalAssigneeName'+requestServiceId,temp)
        }

        if (x === '2') {
          let temp = res?.data?.data[0].staff[0].firstName + ' ' + res?.data?.data[0].staff[0].lastName
          localStorage.setItem('internalAssigneeName'+requestServiceId,temp)
        }
      
        // main service 
        if(res?.data?.data[0].main_service.length === 0){
          mainService = null
        }
        else{
          mainService = res?.data?.data[0].main_service[0]?._id
        }

        // sub service 
        if(res?.data?.data[0].sub_service.length === 0){
          subService = null
        }
        else{
          subService = res?.data?.data[0]?.sub_service[0]?._id
        }
 
        // staff
        if(res?.data?.data[0].staff.length === 0){
          staff = null
        }
        else{
          staff = res?.data?.data[0]?.staff[0]?._id
        }

         // vendor
        if(res?.data?.data[0].vendor.length === 0){
          vendor = null
        }
        else{
          vendor = res?.data?.data[0]?.vendor[0]?._id
        }

        if(res.data.data[0].requestType === '1' || res.data.data[0].requestType === 1)
        localStorage.setItem('intExtStaff_'+requestServiceId,staff)
        else if(res.data.data[0].requestType === '2' || res.data.data[0].requestType === 2)
        localStorage.setItem('intStaff_'+requestServiceId,staff)

        const body = {
          serviceRequests: [
            {
              _id: requestServiceId,
              mainServiceId: mainService,
              subServiceId: subService,
              vendorId: vendor,
              staffId: staff,
              requestUrgency: res.data.data[0].requestUrgency,
              requestType: res.data.data[0].requestType,
              saveStatus: 0,
              comment: res.data.data[0].comment,
              vendorComment: res.data.data[0].vendorComment

            },
          ],
          isSave: true,
          isDelete: false,
        }

        ApiPost(`corporate/service_request/multiple/draft_delete`, body)
        .then((res) => {
          localStorage.setItem('savedOnce'+requestServiceId, '1')
          localStorage.setItem('internalStaff_'+requestServiceId,staff)

         

            navigate(-1)
        })
        .catch()
  
      })
      .catch((e) => {
        console.log(e)
      })
  }


  // const getRequestDataById = async () => {
   
  //   await ApiGet(`corporate/service_request/${requestServiceId}`)
  //     .then((res) => {
  //       let x = localStorage.getItem('requestType')
  //       if (x === '1') {
  //         console.log("hssgdhasdhjasg")
  //         console.log(res?.data?.data[0].staff[0].firstName)
  //         let temp = res?.data?.data[0].staff[0].firstName + ' ' + res?.data?.data[0].staff[0].lastName
  //         localStorage.setItem('internalExternalAssigneeName'+requestServiceId,temp)
  //       }

  //       if (x === '2') {
  //         let temp = res?.data?.data[0].staff[0].firstName + ' ' + res?.data?.data[0].staff[0].lastName
  //         localStorage.setItem('internalAssigneeName'+requestServiceId,temp)
          
  //       }
  //       navigate(-1)
  //     })
  //     .catch((e) => {
  //       console.log(e)
  //     })
  // }


  React.useEffect(() => {
    // if (!requestServiceId) {
    // addServiceRequest()
    // }
   
    getInitialData()
    getInternalStaff()
  }, [searchDepartMentName, searchTitle])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const handleSelectTeam = (item: any, index: any) => {
    setChecked(index)
    setSelectedItem(item)
  }

  const removeTeam = () => {
    setChecked('')
    setSelectedItem('')
  }

  const addStaff = async () => {
    let temp = localStorage.getItem('requestType')
    let vendorID : any
    let staffID : any

    if(temp !== null){
    if(temp === '1'){
    // If a user first selects the vendor and then internal team for that vendor
    let vendorSelected = localStorage.getItem('vendor'+requestServiceId)
    console.log("vendorSelected   "+vendorSelected)
   
    if(vendorSelected !== null){
      vendorID = vendorSelected
    }
    else{
      vendorID = null
    }
 
  }
  }

    if (!selectedItem) {
      navigate(-1)
    } else {
      const body = {
        id: requestServiceId,
        staffId: selectedItem?._id,
        requestType: temp,
        vendorId: vendorID,
      }
      await ApiPut(`corporate/service_request`, body)
        .then((res) => {
          if(temp === '1')
          localStorage.setItem('internalForVendor'+requestServiceId, selectedItem?._id)
          else
          localStorage.setItem('internal'+requestServiceId, selectedItem?._id)
         
          localStorage.setItem('requestType', ''+temp+'')
          getRequestDataById()
        

        })
        .catch((e) => {
          console.log(e)
        })
    }
  }

  const moveVendor = () => {
    navigate(`/request-management/service/vendor-list`)
    localStorage.setItem('inter', 'false')
  }

  const getInitialData= async () => {
    setChecked('')
    // console.log(requestServiceId)
    // let temp = localStorage.getItem('serviceRequestId' + requestServiceId)
    // console.log(temp)

    let mainService: any
    let subService: any
    let staff: any
    let vendor: any
    await ApiGet(`corporate/service_request/` + requestServiceId)
      .then((res) => {
        // console.log('In property list service by ID ')
        // console.log(res?.data?.data)

        // main service
        if (res?.data?.data[0].main_service.length === 0) {
          mainService = null
        } else {
          mainService = res?.data?.data[0].main_service[0]?._id
        }

        // sub service
        if (res?.data?.data[0].sub_service.length === 0) {
          subService = null
        } else {
          subService = res?.data?.data[0]?.sub_service[0]?._id
        }

        // staff
        if (res?.data?.data[0].staff.length === 0) {
          staff = null
        } else {
          staff = res?.data?.data[0]?.staff[0]?._id
        }

        // vendor
        if (res?.data?.data[0].vendor.length === 0) {
          vendor = null
        } else {
          vendor = res?.data?.data[0]?.vendor[0]?._id
        }

    if(res?.data?.data[0].requestType === 1){
      let x = localStorage.getItem('intExtStaff_' + requestServiceId)
      if(x !== null)
      staffServiceId = x
    }
    else{
      let x = localStorage.getItem('intStaff_' + requestServiceId)
      if(x !== null)
      staffServiceId = x
    }

    const body = {
      page: page,
      limit: 10,
      searchName: searchStaff ? searchStaff : '',
      searchDepartment: searchDepartMentName ? searchDepartMentName : '',
      searchTitle: searchTitle ? searchTitle : '',
    }
    ApiPost(`corporate/staff/get`, body)
      .then((res) => {
        // setTableData(res?.data?.data)
        setInternalStaff(res?.data?.data?.staff_data)
        setPageLimit(res?.data?.data?.state?.page_limit)

        // console.log(staffServiceId)

        let temp = res?.data?.data?.staff_data
        for(let i=0; i< temp.length; i++){
          if(temp[i]._id === staffServiceId){
            setSelectedItem(temp[i])
            setChecked(i)
          }
        }
      })
      .catch((e) => {
        console.log(e)
      })
          
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
      <div id='' className='app-container container-xxl pt-0 mt-0'>
        {/* <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li
              className='nav-item'
              onClick={() => {
                navigate(`/request-management/service/create-request-service`)
              }}
            >
              <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                Internal
              </a>
            </li>
            <li
              className='nav-item'
              // onClick={() => {
              //     navigate(`/request-management/quotation`)
              // }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Marketplace
              </a>
            </li>
            <li className='nav-item' onClick={() => moveVendor()}>
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                VendorList
              </a>
            </li>
          </ul>
        </div> */}

        <div className='card card-flush app-container py-5'>
          <Row>
          <div className='col-6 col-md-6 py-2'>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green '
                onClick={() => navigate(-1)}
                // onClick={() => navigate(`/request-management/service/create-request-service`)}
              >
                <img src={back} style={{height: '16px', width: '16px', marginRight: '7px', marginLeft: '-10px', marginTop: '-2px'}} />
                Back
              </a>
            </div>
           
            <div className='col-6 col-md-6 py-2' style={{textAlign: 'end'}}>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green '
                onClick={() => addStaff()}
              >
                <img src={approve} style={{height: '18px', width: '18px', marginRight: '7px', marginLeft: '-10px'}} />

                Assign
              </a>
            </div>
          </Row>

          <Row>
            <div className='col-md-6'></div>
            <div className='col-md-6 mt-2 '>
            <div className='select-box p-3'>
              <h3 className='page-heading d-flex text-dark fw-bold fs-3 mt-2 flex-column justify-content-center mb-5'>
                Selected Staff
              </h3>
              <div className="table-responsive">
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='text-center min-w-100px'>Username</th>
                    <th className='text-center min-w-100px'>Department</th>
                    <th className='text-center min-w-100px'>Title</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {selectedItem && (
                    <tr>
                      <td className='text-center min-w-100px'>
                        {selectedItem?.firstName} {selectedItem?.lastName}
                      </td>
                      <td className='text-center min-w-100px'>
                        {selectedItem?.department[0]?.name}
                      </td>
                      <td className='text-center min-w-100px'>{selectedItem?.title}</td>
                      <td>
                        <AiFillDelete style={{cursor: 'pointer'}} onClick={() => removeTeam()} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              </div>
              </div>
            </div>
          </Row>
          <h3 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-5 px-1'>
            Assign Request
          </h3>
          <div className='mb-10 px-1'>
            <Row>
              <Col lg={5} md={5} className='d-flex align-items-center mb-3'>
                <label htmlFor='exampleFormControlInput1' className='form-label m-0'style={{minWidth: '85px'}}>
                  Search Staff
                </label>
                <input
                  type='text'
                  name='sub_service'
                  className='form-control form-control-solid solid'
                  onChange={(e) => setSearchStaff(e.target.value)}
                />
                <button
                  className='btn btn-sm fw-bold btn-primary btn-green hover-none align-item-center'
                  onClick={() => getInternalStaff()}
                  style={{marginLeft: '10px', width: '155px', height:'38px'}}

                >
                <img src={search} style={{height: '17px', width: '17px', marginRight: '7px', marginLeft: '-10px', marginTop: '-1px'}} className=''/>

                  Search
                </button>
              </Col>
              <Col lg={3} md={3} className='d-flex align-items-center mb-3'>
                <label htmlFor='exampleFormControlInput1' className='form-label m-0' style={{minWidth: '85px'}}>
                  Department
                </label>
                <select
                  name='request_source'
                  className='form-select form-select-solid'
                  onChange={(e) => setSearchDepartMent(e.target.value)}
                >
                  <option selected value=''>
                    Select
                  </option>
                  {internalStaff?.map((item: any) => {
                    return <option>{item?.department[0]?.name}</option>
                  })}
                </select>
              </Col>
              <Col lg={3} md={3} className='d-flex align-items-center mb-3'>
                <label htmlFor='exampleFormControlInput1' className='form-label m-0' style={{minWidth: '85px'}}>
                  Title
                </label>
                <select
                  name='request_status '
                  className='form-select form-select-solid'
                  onChange={(e) => setSearchTitle(e.target.value)}
                >
                  <option selected value=''>
                    Select
                  </option>
                  {internalStaff?.map((item: any) => {
                    return <option>{item?.title}</option>
                  })}
                </select>
              </Col>
            </Row>
          </div>
          <div className='card-body pt-0 table-responsive mt-5 px-3'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='text-center min-w-10px'></th>
                  <th className='min-w-100px'>Staff Name</th>
                  <th className='min-w-100px'>Department</th>
                  <th className='min-w-100px'>Title </th>
                  <th className='min-w-100px'>Expertise</th>
                  <th className='min-w-100px'>Avaibility</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {internalStaff?.map((item: any, index: any) => {
                  return (
                    <tr style={{height:'40px'}}>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='radio'
                            checked={checked === index}
                            style={{cursor: 'pointer'}}
                            onChange={() => handleSelectTeam(item, index)}
                          />
                        </div>
                      </td>
                      <td>
                        {item?.firstName} {item?.lastName}
                      </td>
                      <td>{item?.department[0]?.name}</td>
                      <td>{item?.title}</td>
                      <td>{item?.expertise}</td>
                      <td>{item?.availability === 1 ? 'Availabel' : 'Unavailabel'}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous disabled'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InternalTeam
