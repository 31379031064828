import {initializeApp} from 'firebase/app'
import {getToken, getMessaging, onMessage} from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyDpYBr-1aXTLzak1PL5G0-I2X8zvOgLdT0',
  authDomain: 'propertise-dfaeb.firebaseapp.com',
  projectId: 'propertise-dfaeb',
  storageBucket: 'propertise-dfaeb.appspot.com',
  messagingSenderId: '58539565733',
  appId: '1:58539565733:web:93db94b6cafcfe02fee34c',
  measurementId: 'G-N6CEFLD65N',
}

// Testing

// const firebaseConfig = {
//   apiKey: 'AIzaSyCO87IJcMfkdEpolIfpemD52uzB-wejH_E',
//   authDomain: 'propertise-bed21.firebaseapp.com',
//   projectId: 'propertise-bed21',
//   storageBucket: 'propertise-bed21.appspot.com',
//   messagingSenderId: '167611556290',
//   appId: '1:167611556290:web:0b7bcc21230c7e24aa365b',
//   measurementId: 'G-YZN7Z26WZF',
// }
const firebaseApp = initializeApp(firebaseConfig)
const messaging = getMessaging(firebaseApp)

export const getTokenData = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      'BEXSfj6Gci4l-SE2sIYJniK36nnjhkHW1ZQc-Kk8qMN_ZHRPGGnCNoQJuAUAEGlLLVXGZIJFO5-Iep72ctNWeIs',
    //Test  'BE_hrJVBZCfoZkcdnoS7rKCx1YK0xd1qh48lKmTRo9lQ1YEvPLJPS8DFdEw8_lugVyI97IOKVrKWe9OVWzgDilI',
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken)
        setTokenFound(currentToken)
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        // alert('Please allow Notification')
        console.log('No registration token available. Request permission to generate one.')
        setTokenFound(false)
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      // alert('Please allow Notification')

      console.log('An error occurred while retrieving token. ', err)
      // catch error while creating client token
    })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload)
    })
  })
