import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import noData from '../../../img/NoData1.svg'
import {useNavigate, useLocation} from 'react-router-dom'
import {ApiGet, ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import {SuccessToast, ErrorToast} from '../../../apiCommon/helpers/Toast'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import Select from 'react-select'
import trashImg from '../../../img/trash.png'
import swal from 'sweetalert2'
import addWhite from '../../../img/add-white.png'
import addToListIcon from '../../../img/add-to-list.png'
import manageIcon from '../../../img/manage-list.png'
import './style.scss'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {MultiSelect} from 'react-multi-select-component'
import starYellow from '../../../img/yellow-star.png'
import AddToLists from './AddToListGlobal'
import eye from '../../../img/eye-blue.png'
import {SketchPicker} from 'react-color'
import colorPickerIcon from '../../../img/colr-picker.png'
import ColorPickerModal from './ColorPickerModal'
import correct from '../../../img/correct.png'
import removed from '../../../img/remove-filled.png'
import crossBtn from '../../../img/cross-button.png'

class Component extends React.Component {
  render() {
    return <SketchPicker />
  }
}

const service_contracts_data = [
  {
    company: 'Handy Tech LLC.',
    contractType: 'AMC',
    contractNumber: '981738212',
    start: '01.01.2025',
    end: '31.12.2025',
    expiringIn: '330 Days',
    status: 'Ongoing',
    totalServices: 50,
    notStarted: 40,
    complete: 5,
    inProgress: 5,
    cancelled: '-',
  },
  {
    company: 'Fix It All LLC.',
    contractType: 'Reactive Maintenance',
    contractNumber: '562381933',
    start: '01.01.2025',
    end: '31.12.2025',
    expiringIn: '330 Days',
    status: 'Ongoing',
    totalServices: 100,
    notStarted: 35,
    complete: 35,
    inProgress: 10,
    cancelled: 11,
  },
  {
    company: 'Plumbers Co.',
    contractType: 'Plumbing',
    contractNumber: '111188837',
    start: '16.06.2024',
    end: '15.06.2025',
    expiringIn: '120 Days',
    status: 'Ongoing',
    totalServices: 35,
    notStarted: 29,
    complete: 29,
    inProgress: 3,
    cancelled: '-',
  },
  {
    company: 'Zappers LLC.',
    contractType: 'Pest Control',
    contractNumber: '617362834',
    start: '29.05.2024',
    end: '28.05.2025',
    expiringIn: '100 Days',
    status: 'Ongoing',
    totalServices: 67,
    notStarted: 15,
    complete: 15,
    inProgress: 8,
    cancelled: '-',
  },
  {
    company: 'Gardeners LLC.',
    contractType: 'Landscaping',
    contractNumber: '716382931',
    start: '01.04.2024',
    end: '31.03.2025',
    expiringIn: '95 Days',
    status: 'Expiring',
    totalServices: 89,
    notStarted: 30,
    complete: 30,
    inProgress: 35,
    cancelled: 2,
  },
  {
    company: 'Handy Tech LLC.',
    contractType: 'AMC',
    contractNumber: '253718394',
    start: '01.03.2024',
    end: '28.02.2025',
    expiringIn: '60 Days',
    status: 'Expiring',
    totalServices: 10,
    notStarted: 9,
    complete: 9,
    inProgress: 1,
    cancelled: '-',
  },
  {
    company: 'Electricians LLC.',
    contractType: 'Electrical',
    contractNumber: '091738173',
    start: '10.10.2023',
    end: '09.10.2024',
    expiringIn: '- Days',
    status: 'Expired',
    totalServices: 20,
    notStarted: 20,
    complete: 20,
    inProgress: '-',
    cancelled: '-',
    rating: 4,
  },
]

function Vendors() {
  const {state, pathname} = useLocation()
  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [allRequestVendor, setAllRequestVendor] = useState<any>([])
  const [filteredContracts, setFilteredContracts] = useState(service_contracts_data)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const getVendorAllInfoFilters: any = localStorage.getItem('vendorAllInfoFilters')
  const allInfoFilters = JSON.parse(getVendorAllInfoFilters)

  const getVendorVlaInfoFilters: any = localStorage.getItem('vendorVlaInfoFilters')
  const vlaInfoFilters = JSON.parse(getVendorVlaInfoFilters)

  const getVendorAllRequestFilters: any = localStorage.getItem('vendorAllRequestFilters')
  const allRequestFilters = JSON.parse(getVendorAllRequestFilters)

  const getVendorVlaRequestFilters: any = localStorage.getItem('vendorVlaRequestFilters')
  const vlaRequestFilters = JSON.parse(getVendorVlaRequestFilters)

  const getVendorGlobalFilter: any = localStorage.getItem('vendorGlobalFilter')
  const vendorGlobalFilters = JSON.parse(getVendorGlobalFilter)

  const getactiveTabID: any = localStorage.getItem('vendorActiveTabId')
  const activeTabID: any = JSON.parse(getactiveTabID)

  const getVendorInfoTablePage: any = localStorage.getItem('vendorInfoTablePage')
  const vendorInfoTablePage = JSON.parse(getVendorInfoTablePage)

  const getVendorRequestTablePage: any = localStorage.getItem('vendorRequestTablePage')
  const vendorRequestTablePage = JSON.parse(getVendorRequestTablePage)

  const [developments, setDevelopments] = useState<any>([])
  const [allInfoDevelopment, setAllInfoDevelopment] = useState<any>(
    allInfoFilters?.developments?.length > 0 ? allInfoFilters?.developments : []
  )
  const [allRequestDevelopment, setAllRequestDevelopment] = useState<any>(
    allRequestFilters?.developments?.length > 0 ? allRequestFilters?.developments : []
  )

  const [units, setUnits] = useState<any>([])
  const [allInfoUnits, setAllInfoUnits] = useState<any>(
    allInfoFilters?.units?.length > 0 ? allInfoFilters?.units : []
  )
  const [allRequestUnits, setAllRequestUnits] = useState<any>(
    allRequestFilters?.units?.length > 0 ? allRequestFilters?.units : []
  )

  let active: any = localStorage.getItem('vendorActiveTab')

  const getActiveTab: any = localStorage.getItem('vendorGlobalActiveTab')
  const tab = JSON.parse(getActiveTab)

  const [isInfoList, setIsInfoList] = useState<any>(active == 'info' ? true : false)
  const [isContract, setIsContract] = useState<any>(active == 'servicecontracts' ? true : false)

  const [tableData, setTableData] = useState([
    // {
    //   _id: '',
    // },
  ])

  const [tableDataRequest, setTableDataRequest] = useState([])
  const [page, setPage] = useState<any>(vendorInfoTablePage ? vendorInfoTablePage : 1)
  const [reqPage, setReqPage] = useState<any>(vendorRequestTablePage ? vendorRequestTablePage : 1)

  const [limit, setLimit] = useState<any>(25)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [reqPageLimit, setReqPageLimit] = useState<any>(1)

  const navigate = useNavigate()
  const [isActive, setIsActive] = useState<any>(true)
  const [checkedValues, setCheckedValues] = useState<any>([])

  const [allInfoVendorType, setAllInfoVendorType] = useState<any>(
    allInfoFilters?.vendorType ? allInfoFilters?.vendorType : []
  )
  const [vlaInfoVendorType, setVlaInfoVendorType] = useState<any>(
    vlaInfoFilters?.vendorType ? vlaInfoFilters?.vendorType : []
  )
  const [allRequestVendorType, setAllRequestVendorType] = useState<any>(
    allRequestFilters?.vendorType ? allRequestFilters?.vendorType : []
  )
  const [vlaRequestVendorType, setVlaRequestVendorType] = useState<any>(
    vlaRequestFilters?.vendorType ? vlaRequestFilters?.vendorType : []
  )
  const [vendorTypeOptions, setVendorTypeOptions] = useState<any>([
    {value: 0, label: 'Service Provider'},
    {value: 1, label: 'Supplier'},
  ])

  const [allInfoVendorList, setAllInfoVendorList] = useState<any>(
    allInfoFilters?.vendorList ? allInfoFilters?.vendorList : []
  )
  const [vlaInfoVendorList, setVlaInfoVendorList] = useState<any>(
    vlaInfoFilters?.vendorList ? vlaInfoFilters?.vendorList : []
  )
  const [allRequestVendorList, setAllRequestVendorList] = useState<any>(
    allRequestFilters?.vendorList ? allRequestFilters?.vendorList : []
  )
  const [vlaRequestVendorList, setVlaRequestVendorList] = useState<any>(
    vlaRequestFilters?.vendorList ? vlaRequestFilters?.vendorList : []
  )
  const [vendorListOptions, setVendorListOptions] = useState<any>([])

  const [sortType, setSortType] = useState<any>(
    vendorGlobalFilters?.sortType?.length > 0
      ? vendorGlobalFilters?.sortType
      : state?.sortType
      ? [{value: state?.sortType}]
      : []
  )
  const [sortOptions, setSortOptions] = useState<any>([
    {value: 'A-Z', label: 'Alphabetic (A-Z)'},
    {value: 'Z-A', label: 'Alphabetic (Z-A)'},
    {value: 'latest', label: '(Latest - Oldest)'},
    {value: 'oldest', label: '(Oldest - Latest)'},
  ])

  const [requestSortOptions, setRequestSortOptions] = useState<any>([
    {value: 'A-Z', label: 'Vendors (A-Z)'},
    {value: 'Z-A', label: 'Vendors (Z-A)'},
    {value: 'requests-high', label: 'Requests (High - Low)'},
    {value: 'requests-low', label: 'Requests (Low - High)'},
    {value: 'complete-high', label: 'Complete (High - Low)'},
    {value: 'complete-low', label: 'Complete (Low - High)'},
    {value: 'in-progress-high', label: 'In Progress (High - Low)'},
    {value: 'in-progress-low', label: 'In Progress (Low - High)'},
    {value: 'cancelled-high', label: 'Cancelled (High - Low)'},
    {value: 'cancelled-low', label: 'Cancelled (Low - High)'},
    {value: 'rating-high', label: 'Rating (High - Low)'},
    {value: 'rating-low', label: 'Rating (Low - High)'},
  ])

  const [allRequestSortType, setAllRequestSortType] = useState<any>(
    allRequestFilters?.sort?.length
      ? allRequestFilters?.sort
      : state?.sortType
      ? [{value: state?.sortType}]
      : []
  )

  const [vlaRequestSortType, setVlaRequestSortType] = useState<any>(
    vlaRequestFilters?.sort?.length > 0
      ? vlaRequestFilters?.sort
      : state?.sortType
      ? [{value: state?.sortType}]
      : []
  )

  const [isColorPickerEnabled, setIsColorPickerEnabled] = useState<any>(false)

  let totalSelected: any = 0
  const [cardPosition, setCardPosition] = useState({top: 0, left: 0})

  const [searchName, setSearchName] = useState<any>(
    vendorGlobalFilters?.searchName ? vendorGlobalFilters?.searchName : ''
  )

  const [vendorsTabList, setVendorsTabList] = useState<any>([])
  const [showPickerModal, setShowPickerModal] = useState<any>(false)

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '170px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#ffff',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
      minHeight: '35px',
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
    indicatorsContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const getDevelopments = () => {
    const body = {
      search: '',
      developmentIds: [],
    }

    ApiPost(`corporate/development/get`, body)
      .then((res: any) => {
        const devs =
          res?.data?.data?.map((val: any) => ({
            label: val?.name || '',
            value: val?._id || '',
            devType:
              val?.isCommunity === 'true' ? 'comm' : val?.isBuilding === 'true' ? 'build' : '',
          })) || []

        console.log(devs)

        setDevelopments(devs) // Set the entire array of objects
      })
      .catch((err: any) => {
        console.error('Error fetching developments:', err)
      })
  }

  useEffect(() => {
    getDevelopments()
  }, [])

  const getUnits = () => {
    let devs: any = []
    if (active === 'info') {
      allInfoDevelopment?.map((al: any, ind: any) => {
        devs?.push(al?.value)
      })
    } else if (active === 'requests') {
      allRequestDevelopment?.map((al: any, ind: any) => {
        devs?.push(al?.value)
      })
    }

    const body = {
      search: '',
      developmentIds: devs,
    }

    ApiPost(`corporate/development/unit/get`, body)
      .then((res: any) => {
        const unts =
          res?.data?.data
            ?.filter(
              (val: any) =>
                val?.unitNo ||
                (val?.unitType === 2 && val?.commonAreaName) ||
                (val?.unitType === 5 && val?.otherName)
            ) // Filter out records with empty unitNo, commonAreaName, or otherName
            ?.map((val: any) => ({
              label:
                val?.unitType == 2
                  ? `${val?.unitNo} - ${val?.commonAreaName}`
                  : val?.unitType == 5
                  ? `${val?.unitNo} - ${val?.otherName}`
                  : val?.unitNo || '',
              value: val?._id || '',
            })) || []

        console.log(unts)

        setUnits(unts) // Set the entire array of objects
      })
      .catch((err: any) => {
        console.error('Error fetching units:', err)
      })
  }

  useEffect(() => {
    if (active === 'info') {
      if (allInfoDevelopment?.length > 0) getUnits()
      else setUnits([])
    } else if (active === 'requests') {
      if (allRequestDevelopment?.length > 0) getUnits()
      else setUnits([])
    }
  }, [allInfoDevelopment])

  //
  const getVendorsListForTab = async () => {
    let body = {
      page: page,
      limit: limit,
      searchName: searchName,
      sortType: sortType?.length > 0 ? sortType?.[0]?.value : 'A-Z',
    }

    await ApiPost(`corporate/vendor_list/get`, body)
      .then((res) => {
        setVendorsTabList(res?.data?.data?.vendor_data)
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  //
  const getVendorsListForDropdown = async () => {
    await ApiGet(`corporate/vendor_list`)
      .then((res) => {
        let values: any = []
        res?.data?.data?.map((val: any, ind: any) => {
          values[ind] = {
            label: `${val?.name}`,
            value: `${val?._id}`,
          }
        })
        setVendorListOptions(values)

        // if (active === 'info' && activeTab === 'all') {
        //   setAllInfoVendorList([])
        // }else{
        //   setVlaInfoVendorList([])
        // }

        // if (active === 'requests' && activeTab === 'all') {
        //   setAllRequestVendorList([])
        // }else{
        //   setVlaRequestVendorList([])
        // }

        let temp = [...values]
        // Filter out the Properties with checkboxes checked
        const filteredList = temp.filter((list) => list?.value !== activeTabId)
        setVendorListOptions(filteredList)
      })
      .catch((err) => {
        ErrorToast(err?.message)
      })
  }

  const [activeTab, setActiveTab] = useState<any>(tab ? tab : 'all')
  const [activeTabId, setActiveTabId] = useState<any>(activeTabID ? activeTabID : '')

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    getVendorsListInfoData(event.selected + 1)
  }

  const handlePageClickRequest = (event: any) => {
    setReqPage(event.selected + 1)
    getRequestsData(event.selected + 1)
  }

  const [selectModal, setSelectModal] = useState<any>(false)
  const [selected, setSelected] = useState<any>('')
  const [selectedInd, setSelectedInd] = useState<any>(-1)

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [allCount, setAllCount] = useState<any>()

  const [allInfoSearchVendor, setAllInfoSearchVendor] = useState<any>(
    allInfoFilters?.searchVendor ? allInfoFilters?.searchVendor : ''
  )
  const [vlaInfoSearchVendor, setVlaInfoSearchVendor] = useState<any>(
    vlaInfoFilters?.searchVendor ? vlaInfoFilters?.searchVendor : ''
  )
  const [allRequestSearchVendor, setAllRequestSearchVendor] = useState<any>(
    allRequestFilters?.searchVendor ? allRequestFilters?.searchVendor : ''
  )
  const [vlaRequestSearchVendor, setVlaRequestSearchVendor] = useState<any>(
    vlaRequestFilters?.searchVendor ? vlaRequestFilters?.searchVendor : ''
  )

  const [allInfoSearchProperty, setAllInfoSearchProperty] = useState<any>(
    allInfoFilters?.searchProperty ? allInfoFilters?.searchProperty : ''
  )
  const [vlaInfoSearchProperty, setVlaInfoSearchProperty] = useState<any>(
    vlaInfoFilters?.searchProperty ? vlaInfoFilters?.searchProperty : ''
  )
  const [allRequestSearchProperty, setAllRequestSearchProperty] = useState<any>(
    allRequestFilters?.searchProperty ? allRequestFilters?.searchProperty : ''
  )
  const [vlaRequestSearchProperty, setVlaRequestSearchProperty] = useState<any>(
    vlaRequestFilters?.searchProperty ? vlaRequestFilters?.searchProperty : ''
  )

  const [addToListModal, setAddToListModal] = useState<any>(false)
  const [show, setShow] = useState<any>([])

  //
  const getAllCount = () => {
    ApiGet('corporate/vendor/count')
      .then((res) => {
        setAllCount(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  // Info Tab table data
  const getVendorsListInfoData = async (pageVal: any) => {
    setTableDataRequest([])

    let vType: any = []
    let vList: any = []

    if (active === 'info' && activeTab === 'all') {
      allInfoVendorType?.map((v: any, ind: any) => {
        vType[ind] = v?.value
      })

      allInfoVendorList?.map((v: any, ind: any) => {
        vList[ind] = v?.value
      })
    }

    if (active === 'info' && activeTab !== 'all') {
      vlaInfoVendorType?.map((v: any, ind: any) => {
        vType[ind] = v?.value
      })

      vlaInfoVendorList?.map((v: any, ind: any) => {
        vList[ind] = v?.value
      })
    }

    if (activeTab != 'all') vList[vList?.length] = activeTabId

    let devs: any = []
    allInfoDevelopment?.map((al: any, ind: any) => {
      devs?.push(al?.value)
    })

    let units: any = []
    allInfoUnits.map((al: any, ind: any) => {
      units?.push(al?.value)
    })

    let body = {
      page: pageVal,
      limit: limit,
      searchVendorName: activeTab === 'all' ? allInfoSearchVendor : vlaInfoSearchVendor,
      vendorType: vType,
      vendorListIds: activeTab == 'all' ? vList : undefined,
      vendorListIdsAndOperation: activeTab !== 'all' ? vList : undefined,
      developmentIds: devs,
      unitIds: units,
    }

    await ApiPost('corporate/vendor/get/info', body)
      .then((res) => {
        let values: any = []
        for (let i = 0; i < res?.data?.data?.tenancy_data; i++) {
          values[i] = false
        }
        setCheckedValues(values)

        setTableData(res?.data?.data?.vendor_data)

        setPageLimit(res?.data?.data?.state?.page_limit)

        let temp = []
        for (let i = 0; i < res?.data?.data?.vendor_data.length; i++) {
          temp[i] = false
          setShow(temp)
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    if (isInfoList) {
      setPage(1)
      getVendorsListInfoData(1)
      getAllCount()
    }
  }, [
    isInfoList,
    activeTab,
    allInfoSearchProperty,
    allInfoSearchVendor,
    allInfoVendorType,
    allInfoVendorList,
    vlaInfoSearchProperty,
    vlaInfoSearchVendor,
    vlaInfoVendorType,
    vlaInfoVendorList,
    allInfoDevelopment,
    allInfoUnits,
  ])

  useEffect(() => {
    getVendorsListForTab()
  }, [searchName, sortType])

  //
  const getRequestsData = async (pageVal: any) => {
    setTableData([])

    let vType: any = []
    let vList: any = []

    if (active === 'requests' && activeTab === 'all') {
      allRequestVendorType?.map((v: any, ind: any) => {
        vType[ind] = v?.value
      })

      allRequestVendorList?.map((v: any, ind: any) => {
        vList[ind] = v?.value
      })
    }

    if (active === 'requests' && activeTab !== 'all') {
      vlaRequestVendorType?.map((v: any, ind: any) => {
        vType[ind] = v?.value
      })

      vlaRequestVendorList?.map((v: any, ind: any) => {
        vList[ind] = v?.value
      })
    }

    if (activeTab != 'all') vList[vList?.length] = activeTabId

    let devs: any = []
    allRequestDevelopment?.map((al: any, ind: any) => {
      devs?.push(al?.value)
    })

    let units: any = []
    allRequestUnits.map((al: any, ind: any) => {
      units?.push(al?.value)
    })

    let body = {
      page: pageVal,
      limit: limit,
      searchVendorName: activeTab === 'all' ? allRequestSearchVendor : vlaRequestSearchVendor,
      vendorType: vType,
      vendorListIds: vList,
      sortType:
        activeTab === 'all' && allRequestSortType?.length > 0
          ? allRequestSortType?.[0]?.value
          : activeTab != 'all' && vlaRequestSortType?.length == 0
          ? 'A-Z'
          : 'A-Z',
      developmentIds: devs,
      unitIds: units,
    }

    await ApiPost('corporate/vendor/get/request', body)
      .then((res) => {
        let values: any = []
        for (let i = 0; i < res?.data?.data?.tenancy_data; i++) {
          values[i] = false
        }
        setCheckedValues(values)
        setTableDataRequest(res?.data?.data?.vendor_data)

        setReqPageLimit(res?.data?.data?.state?.page_limit)

        let temp = []
        for (let i = 0; i < res?.data?.data?.vendor_data.length; i++) {
          temp[i] = false
          setShow(temp)
        }
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  //
  useEffect(() => {
    if (!isInfoList) {
      setReqPage(1)
      getRequestsData(1)
      getAllCount()
    }
  }, [
    isInfoList,
    activeTab,
    allRequestSearchVendor,
    allRequestSearchProperty,
    allRequestVendorType,
    allRequestVendorList,
    vlaRequestSearchVendor,
    vlaRequestSearchProperty,
    vlaRequestVendorType,
    vlaRequestVendorList,
    allRequestSortType,
    vlaRequestSortType,
    allRequestDevelopment,
    allRequestUnits,
  ])

  const goToVendor = (v: any) => {
    if (v?.saveStatus == 0) navigate(`/add-vendor/2/${v?._id}`)
    else navigate(`/vendor/${v?._id}`)
  }

  useEffect(() => {
    getVendorsListForDropdown()
  }, [activeTabId])

  const [isLoading, setIsLoading] = useState<any>()

  //
  const deleteVendors = () => {
    setIsLoading(true)
    let vIds: any = []

    let i: any = 0

    const tblData: any = tableData || [] // Ensure tableData is typed correctly

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        vIds[i] = tblData[ind]?._id
        i++
      }
    })

    const body = {
      vendorIds: vIds,
    }

    ApiPost(`corporate/vendor/delete`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)

        getVendorsListInfoData(page)
        getAllCount()
        getVendorsListForTab()

        let values = [...checkedValues]

        for (let i = 0; i < tableData?.length; i++) {
          values[i] = false
        }

        setCheckedValues(values)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  //
  const addToVendors = (vlIds: any) => {
    setIsLoading(true)

    let vIds: any = []
    let i: any = 0

    const tblData: any = tableData || [] // Ensure tableData is typed correctly

    checkedValues?.map((ch: any, ind: any) => {
      if (ch == true) {
        vIds[i] = tblData[ind]?._id
        i++
      }
    })

    const body = {
      vendorIds: vIds,
      vendorListIds: vlIds,
    }

    ApiPost(`corporate/vendor_list/add_vendor`, body)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsLoading(false)

        getVendorsListInfoData(page)
        getAllCount()
        getVendorsListForTab()

        let values = [...checkedValues]

        for (let i = 0; i < tableData?.length; i++) {
          values[i] = false
        }

        setCheckedValues(values)
        setAddToListModal(false)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const handleChangeColor = () => {}

  const [editFile, setEditFile] = useState<any>(-1)

  const updateColor = async () => {
    const body = {
      id: selected?._id,
      colorCode: selected?.colorCode,
    }
    await ApiPut(`corporate/vendor_list`, body)
      .then((res: any) => {
        setSelectedInd(-1)
        setShowPickerModal(false)
        SuccessToast(res?.data?.message)
        if (isInfoList) getVendorsListInfoData(page)
        else getRequestsData(reqPage)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  useEffect(() => {
    if (pathname === '/vendor-management' && activeTab === 'all' && active === 'info') {
      const filters = {
        searchVendor: allInfoSearchVendor,
        searchProperty: allInfoSearchProperty,
        vendorType: allInfoVendorType,
        vendorList: allInfoVendorList,
        developments: allInfoDevelopment,
        units: allInfoUnits,
      }
      localStorage.setItem('vendorAllInfoFilters', JSON.stringify(filters))
    } else if (pathname === '/vendor-management' && activeTab === 'all' && active === 'requests') {
      const filters = {
        searchVendor: allRequestSearchVendor,
        searchProperty: allRequestSearchProperty,
        vendorType: allRequestVendorType,
        vendorList: allRequestVendorList,
        sort: allRequestSortType,
        developments: allRequestDevelopment,
        units: allRequestUnits,
      }
      localStorage.setItem('vendorAllRequestFilters', JSON.stringify(filters))
    } else if (pathname === '/vendor-management' && activeTab !== 'all' && active === 'info') {
      const filters = {
        searchVendor: vlaInfoSearchVendor,
        searchProperty: vlaInfoSearchProperty,
        vendorType: vlaInfoVendorType,
        vendorList: vlaInfoVendorList,
      }
      localStorage.setItem('vendorVlaInfoFilters', JSON.stringify(filters))
    } else if (pathname === '/vendor-management' && activeTab !== 'all' && active === 'requests') {
      const filters = {
        searchVendor: vlaRequestSearchVendor,
        searchProperty: vlaRequestSearchProperty,
        vendorType: vlaRequestVendorType,
        vendorList: vlaRequestVendorList,
        sort: vlaRequestSortType,
      }
      localStorage.setItem('vendorVlaRequestFilters', JSON.stringify(filters))
    }
  }, [
    activeTab,
    allInfoSearchVendor,
    allInfoSearchProperty,
    allInfoVendorList,
    allRequestSearchProperty,
    allRequestSearchVendor,
    allRequestSortType,
    allRequestVendorList,
    allRequestVendorType,
    vlaInfoSearchVendor,
    vlaInfoSearchProperty,
    vlaInfoVendorList,
    vlaRequestSearchProperty,
    vlaRequestSearchVendor,
    vlaRequestSortType,
    vlaRequestVendorList,
    vlaRequestVendorType,
    allInfoDevelopment,
    allInfoUnits,
    allRequestDevelopment,
    allRequestUnits,
  ])

  useEffect(() => {
    if (pathname === '/vendor-management' && activeTab !== 'all' && active === 'info') {
      const filters = {
        developments: allInfoDevelopment,
        units: allInfoUnits,
      }
      localStorage.setItem('vendorAllInfoFilters', JSON.stringify(filters))
    } else if (pathname === '/vendor-management' && activeTab !== 'all' && active === 'requests') {
      const filters = {
        developments: allRequestDevelopment,
        units: allRequestUnits,
      }
      localStorage.setItem('vendorAllRequestFilters', JSON.stringify(filters))
    }
  }, [allInfoDevelopment, allInfoUnits, allRequestDevelopment, allRequestUnits])

  useEffect(() => {
    if (pathname === '/vendor-management') {
      const filters = {
        searchName: searchName,
        sortType: sortType,
      }
      localStorage.setItem('vendorGlobalFilter', JSON.stringify(filters))
    }
  }, [searchName, sortType])

  useEffect(() => {
    localStorage.setItem('vendorGlobalActiveTab', JSON.stringify(activeTab))
    localStorage.setItem('vendorActiveTabId', JSON.stringify(activeTabId))
    localStorage.setItem('vendorInfoTablePage', JSON.stringify(page))
    localStorage.setItem('vendorRequestTablePage', JSON.stringify(reqPage))
  }, [activeTab, activeTabId, page, reqPage])

  const CustomValueRenderer = (selected: any, _options: any) => {
    return selected.length > 0 ? `${selected.length} Selected` : 'Select Cluster'
  }

  return (
    <>
      <div className='mx-auto d-flex gap-5 flex-column p-0 m-0 px-2' id='kt_app_main'>
        {' '}
        {/* container-xxl */}
        <div className='row'>
          <div className='col-12'>
            <h1 className='page-heading  m-0'>
              <b>Vendors</b>
            </h1>
          </div>
        </div>
        <div
          className='card card-flush py-5  px-4 overflow-auto'
          style={{maxHeight: '300px', overflowY: 'auto'}}
        >
          <div
            className='d-flex align-items-center py-5 pt-0 gy-3 mx-0 mt-0 xyz tst'
            // style={{ justifyContent: "space-between" }}
          >
            <label className='head-text me-5 pe-3 fnt-600'>Search </label>
            {/* Property */}
            <div className='me-4'>
              <Paper component='form' style={searchContainerStyle}>
                <InputBase
                  placeholder='List Name...'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchName}
                  onChange={(e: any) => setSearchName(e.target.value)}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

            {/* Type */}
            {/* <div className='me-4'>
              <Select
                styles={customStyles}
                isSearchable={false}
                options={sortOptions}
                name='sortType'
                onChange={(e: any) => {
                  console.log(e)
                  setSortType(e.value)
                }}
                placeholder={'Sort'}
              />
            </div> */}

            <div className='me-4 test jobs'>
              <div className='property-test d-none-multi-checbox'>
                <div
                  className={`multi-select-container jobs-property ms-0 me-5 sort-type ${
                    sortType?.length === 0 ? 'no-value select ' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={sortOptions}
                    value={sortType}
                    onChange={(e: any) => {
                      let newVal: any = e
                      if (e?.length > 1) newVal = e.slice(1)
                      setSortType(newVal)
                    }}
                    labelledBy=''
                    overrideStrings={{
                      allItemsAreSelected: '',
                    }}
                    hasSelectAll={false}
                    disableSearch
                    closeOnChangedValue={true}
                  />
                </div>
              </div>
            </div>

            <div className='d-flex ms-auto align-items-center'>
              {/* {isColorPickerEnabled ? (
                <>
                  <>
                    <button
                      type='button'
                      className='btn btn-sm fw-bold  ms-3 red-hollow-btn'
                      onClick={() => {
                        getVendorsListForTab()
                        setIsColorPickerEnabled(false)
                      }}
                      disabled={isLoading}
                    >
                      <img src={redCross} height={16} width={16} className='me-3' /> Cancel
                    </button>

                    <button
                      type='button'
                      className='btn btn-sm fw-bold ms-3 green-submit-btn'
                      onClick={(e) => {
                        updateColors()
                      }}
                      disabled={isLoading}
                    >
                      <img src={saveWhiteImg} height={18} width={18} className='me-3' /> {'  '}
                      Save
                    </button>
                  </>
                </>
              ) : ( */}
              <>
                <img
                  src={colorPickerIcon}
                  height={22}
                  width={22}
                  className='cursor-pointer'
                  onClick={() => {
                    setSelectedInd(-1)
                    setShowPickerModal(false)
                    setIsColorPickerEnabled(!isColorPickerEnabled)
                  }}
                />
              </>
              {/* )} */}

              <button
                type='button'
                className='btn fw-bold manage-list-btn px-2 ms-3 btn-fit-content'
                onClick={() => {
                  navigate(`/manage-vendors`)
                }}
              >
                <img src={manageIcon} height={18} width={18} className='me-4' /> Manage Lists
              </button>
            </div>
          </div>

          <div className='d-flex flex-wrap mt-3'>
            <div className='me-5 mb-5'>
              {isColorPickerEnabled && (
                <div className='me-1 mb-2 text-end' style={{height: '18px', width: '18px'}}>
                  {/* <img
                    src={colorPickerIcon}
                    height={18}
                    width={18}
                    className='cursor-pointer ms-auto'
                    onClick={() => {
                      // setShowPickerModal(true)
                    }}
                  /> */}
                </div>
              )}
              <div
                className={'tenancy-green-card d-flex align-items-center'}
                onClick={() => {
                  setIsActive(true)
                  setActiveTab('all')
                  setActiveTabId('')
                  // setPage(1)
                }}
              >
                <div className='px-5'>
                  <h2 className='mb-0 text-white'>
                    {allCount ? allCount?.toLocaleString('en-US', {}) : 0}
                  </h2>
                  <h5 className='mb-0 text-white'>All</h5>
                </div>
              </div>
              {activeTab == 'all' && <div className='active-filter'></div>}
            </div>

            {/* Tabs tab */}
            {vendorsTabList?.length > 0 &&
              vendorsTabList?.map((tab: any, index: any) => {
                return (
                  <div className='me-5 mb-3'>
                    {isColorPickerEnabled && (
                      <>
                        {selectedInd == index ? (
                          <div className='d-flex me-1 mb-2'>
                            <img
                              src={removed}
                              height={18}
                              width={18}
                              className='ms-auto cursor-pointer'
                              onClick={() => {
                                setSelectedInd(-1)
                                setShowPickerModal(false)
                                getVendorsListForTab()
                              }}
                            />
                            <img
                              src={correct}
                              height={18}
                              width={18}
                              className='ms-3 cursor-pointer'
                              onClick={() => updateColor()}
                            />
                          </div>
                        ) : (
                          <div className='me-1 mb-2 text-end'>
                            <img
                              src={colorPickerIcon}
                              height={18}
                              width={18}
                              className='cursor-pointer ms-auto'
                              onClick={(event: any) => {
                                const buttonRect = event.target.getBoundingClientRect()
                                const newPosition = {
                                  top: buttonRect.bottom + window.scrollY + 20,
                                  left: buttonRect.left + window.scrollX - 300, // Adjust the value to position the card on the left
                                }

                                setSelected(tab)
                                setSelectedInd(index)
                                setShowPickerModal(true)
                                setCardPosition(newPosition)
                              }}
                            />
                          </div>
                        )}
                      </>
                    )}
                    <div
                      className={`d-flex align-items-center ${
                        activeTab === tab?.name ? 'tenancy-active-card' : 'tenancy-green-card'
                      }`}
                      style={{
                        backgroundColor: tab?.colorCode,
                      }}
                      onClick={() => {
                        setIsActive(true)
                        setActiveTab(tab?.name)
                        setActiveTabId(tab?._id)
                        // setPage(1)
                      }}
                    >
                      <div className='px-5'>
                        <h2 className='mb-0 text-white'>
                          {tab?.count ? tab?.count?.toLocaleString('en-US', {}) : 0}
                        </h2>
                        <OverlayTrigger
                          placement='top'
                          flip
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                              {tab?.name}
                            </Tooltip>
                          }
                        >
                          <div>
                            <h5 className='mb-0 text-white'>
                              {tab?.name?.substring(0, 20)}
                              {tab?.name?.length > 23 && '..'}
                            </h5>
                          </div>
                        </OverlayTrigger>
                      </div>
                    </div>
                    {activeTab == tab?.name && (
                      <div
                        className='active-filter'
                        style={{
                          backgroundColor: tab?.colorCode,
                        }}
                      ></div>
                    )}
                  </div>
                )
              })}
          </div>
        </div>
        <div className=' ps-0 row mt-2'>
          <div className='col-5'>
            <h2 className='page-heading  m-0 text-capitalize pt-2'>
              <b>{activeTab}</b>
            </h2>
          </div>
          <div className='col-7'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2 xyz'>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${
                    isInfoList && 'active'
                  }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setIsInfoList(true)
                    setIsContract(false)
                    localStorage.setItem('vendorActiveTab', 'info')
                  }}
                >
                  Info
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${
                    !isInfoList && !isContract && 'active'
                  }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setIsInfoList(false)
                    setIsContract(false)
                    localStorage.setItem('vendorActiveTab', 'requests')
                  }}
                >
                  Requests
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={`nav-link text-active-primary pb-2 me-5 px-5 ${
                    isContract && 'active'
                  }`}
                  data-bs-toggle='tab'
                  onClick={() => {
                    setIsInfoList(false)
                    setIsContract(true)
                    localStorage.setItem('vendorActiveTab', 'servicecontracts')
                  }}
                >
                  Service Contracts
                </a>
              </li>
            </ul>
          </div>
        </div>
        {!isContract && (
          <div
            className='d-flex align-items-center py-5 pt-2 gy-3 mx-0 mt-4 xyz tst'
            // style={{ justifyContent: "space-between" }}
          >
            <label className='head-text me-5 pe-3' style={{fontWeight: '600'}}>
              Search{' '}
            </label>

            {active === 'info' && activeTab === 'all' && (
              <>
                {/* Seach by Vendor */}
                <div className='me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Vendor...'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'search'}}
                      value={allInfoSearchVendor}
                      onChange={(e: any) => setAllInfoSearchVendor(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='search'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>

                {/* Property */}
                {/* <div className='me-4'> */}
                {/* <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Property...'
                    style={inputBaseStyle}
                    inputProps={{ 'aria-label': 'search' }}
                    value={allInfoSearchProperty}
                    onChange={(e: any) => setAllInfoSearchProperty(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper> */}
                {/* </div> */}

                {/* Developments */}
                <div className='me-4 property test  multi-select-white-bg'>
                  <div
                    className={`multi-select-container dev ${
                      allInfoDevelopment?.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={developments}
                      value={allInfoDevelopment}
                      onChange={setAllInfoDevelopment}
                      labelledBy='Development...'
                      overrideStrings={{
                        allItemsAreSelected: 'All Developments', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* Units */}
                <div className='me-4 property test  multi-select-white-bg'>
                  <div
                    className={`multi-select-container unit ${
                      allInfoUnits?.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={units}
                      value={allInfoUnits}
                      onChange={setAllInfoUnits}
                      labelledBy='Unit...'
                      overrideStrings={{
                        allItemsAreSelected: 'All Units', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* Type */}
                <div className='me-4 property test  multi-select-white-bg'>
                  <div
                    className={`multi-select-container type ${
                      allInfoVendorType.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={vendorTypeOptions}
                      value={allInfoVendorType}
                      onChange={setAllInfoVendorType}
                      labelledBy='Type'
                      overrideStrings={{
                        allItemsAreSelected: 'All Types', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* List */}
                <div className='me-4  property test  multi-select-white-bg'>
                  <div
                    className={`multi-select-container list ${
                      allInfoVendorList.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={vendorListOptions}
                      value={allInfoVendorList}
                      onChange={setAllInfoVendorList}
                      labelledBy='List'
                      overrideStrings={{
                        allItemsAreSelected: 'All Lists', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>
              </>
            )}

            {active === 'requests' && activeTab === 'all' && (
              <>
                {/* Seach by Vendor */}
                <div className='me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Vendor...'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'search'}}
                      value={allRequestSearchVendor}
                      onChange={(e: any) => setAllRequestSearchVendor(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='search'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>

                {/* Property */}
                {/* <div className='me-4'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Property...'
                    style={inputBaseStyle}
                    inputProps={{ 'aria-label': 'search' }}
                    value={allRequestSearchProperty}
                    onChange={(e: any) => setAllRequestSearchProperty(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div> */}

                {/* Developments */}
                <div className='me-4 property test  multi-select-white-bg'>
                  <div
                    className={`multi-select-container dev ${
                      allRequestDevelopment?.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={developments}
                      value={allRequestDevelopment}
                      onChange={setAllRequestDevelopment}
                      labelledBy='Development...'
                      overrideStrings={{
                        allItemsAreSelected: 'All Developments', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* Units */}
                <div className='me-4 property test  multi-select-white-bg'>
                  <div
                    className={`multi-select-container unit ${
                      allRequestUnits?.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={units}
                      value={allRequestUnits}
                      onChange={setAllRequestUnits}
                      labelledBy='Unit...'
                      overrideStrings={{
                        allItemsAreSelected: 'All Units', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* Type */}
                <div className='me-4 property test multi-select-white-bg'>
                  <div
                    className={`multi-select-container type ${
                      allRequestVendorType.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={vendorTypeOptions}
                      value={allRequestVendorType}
                      onChange={setAllRequestVendorType}
                      labelledBy='Type'
                      overrideStrings={{
                        allItemsAreSelected: 'All Types', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* List */}
                <div className='me-4  property test multi-select-white-bg'>
                  <div
                    className={`multi-select-container  list ${
                      allRequestVendorList.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={vendorListOptions}
                      value={allRequestVendorList}
                      onChange={setAllRequestVendorList}
                      labelledBy='List'
                      overrideStrings={{
                        allItemsAreSelected: 'All Lists', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* <div className='me-4'>
                <Select
                  styles={customStyles}
                  isSearchable={false}
                  options={requestSortOptions}
                  name='sortType'
                  onChange={(e: any) => {
                    setAllRequestSortType(Number(e.value))
                  }}
                  placeholder={'Sort'} 
                />
              </div> */}

                <div className='me-4 test jobs'>
                  <div className='property-test d-none-multi-checbox'>
                    <div
                      className={`multi-select-container jobs-property ms-0 me-5 sort-type ${
                        allRequestSortType?.length === 0 ? 'no-value' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={requestSortOptions}
                        value={allRequestSortType}
                        onChange={(e: any) => {
                          let newVal: any = e
                          if (e?.length > 1) newVal = e.slice(1)
                          setAllRequestSortType(newVal)
                        }}
                        labelledBy=''
                        overrideStrings={{
                          allItemsAreSelected: '',
                        }}
                        hasSelectAll={false}
                        disableSearch
                        closeOnChangedValue={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {active === 'info' && activeTab !== 'all' && (
              <>
                {/* Seach by Vendor */}
                <div className='me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Vendor...'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'search'}}
                      value={vlaInfoSearchVendor}
                      onChange={(e: any) => setVlaInfoSearchVendor(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='search'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>

                {/* Property */}
                {/* <div className='me-4'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Property...'
                    style={inputBaseStyle}
                    inputProps={{ 'aria-label': 'search' }}
                    value={vlaInfoSearchProperty}
                    onChange={(e: any) => setVlaInfoSearchProperty(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div> */}

                {/* Developments */}
                <div className='me-4 property test  multi-select-white-bg'>
                  <div
                    className={`multi-select-container dev ${
                      allInfoDevelopment?.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={developments}
                      value={allInfoDevelopment}
                      onChange={setAllInfoDevelopment}
                      labelledBy='Development...'
                      overrideStrings={{
                        allItemsAreSelected: 'All Developments', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* Units */}
                <div className='me-4 property test  multi-select-white-bg'>
                  <div
                    className={`multi-select-container unit ${
                      allInfoUnits?.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={units}
                      value={allInfoUnits}
                      onChange={setAllInfoUnits}
                      labelledBy='Unit...'
                      overrideStrings={{
                        allItemsAreSelected: 'All Units', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* Type */}
                <div className='me-4 property test multi-select-white-bg'>
                  <div
                    className={`multi-select-container type ${
                      vlaInfoVendorType.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={vendorTypeOptions}
                      value={vlaInfoVendorType}
                      onChange={setVlaInfoVendorType}
                      labelledBy='Type'
                      overrideStrings={{
                        allItemsAreSelected: 'All Types', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* List */}
                <div className='me-4  property test multi-select-white-bg'>
                  <div
                    className={`multi-select-container list ${
                      vlaInfoVendorList.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={vendorListOptions}
                      value={vlaInfoVendorList}
                      onChange={setVlaInfoVendorList}
                      labelledBy='List'
                      overrideStrings={{
                        allItemsAreSelected: 'All Lists', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>
              </>
            )}

            {active === 'requests' && activeTab !== 'all' && (
              <>
                {/* Seach by Vendor */}
                <div className='me-4'>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Vendor...'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'search'}}
                      value={vlaRequestSearchVendor}
                      onChange={(e: any) => setVlaRequestSearchVendor(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='search'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>

                {/* Property */}
                {/* <div className='me-4'>
                <Paper component='form' style={searchContainerStyle}>
                  <InputBase
                    placeholder='Property...'
                    style={inputBaseStyle}
                    inputProps={{ 'aria-label': 'search' }}
                    value={vlaRequestSearchProperty}
                    onChange={(e: any) => setVlaRequestSearchProperty(e.target.value)}
                  />
                  <IconButton style={searchIconStyle} aria-label='search'>
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div> */}

                {/* Developments */}
                <div className='me-4 property test  multi-select-white-bg'>
                  <div
                    className={`multi-select-container dev ${
                      allRequestDevelopment?.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={developments}
                      value={allRequestDevelopment}
                      onChange={setAllRequestDevelopment}
                      labelledBy='Development...'
                      overrideStrings={{
                        allItemsAreSelected: 'All Developments', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* Units */}
                <div className='me-4 property test  multi-select-white-bg'>
                  <div
                    className={`multi-select-container unit ${
                      allRequestUnits?.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={units}
                      value={allRequestUnits}
                      onChange={setAllRequestUnits}
                      labelledBy='Unit...'
                      overrideStrings={{
                        allItemsAreSelected: 'All Units', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* Type */}
                <div className='me-4 property test multi-select-white-bg'>
                  <div
                    className={`multi-select-container type ${
                      vlaRequestVendorType.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={vendorTypeOptions}
                      value={vlaRequestVendorType}
                      onChange={setVlaRequestVendorType}
                      labelledBy='Type'
                      overrideStrings={{
                        allItemsAreSelected: 'All Types', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* List */}
                <div className='me-4  property test multi-select-white-bg'>
                  <div
                    className={`multi-select-container list ${
                      vlaRequestVendorList.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={vendorListOptions}
                      value={vlaRequestVendorList}
                      onChange={setVlaRequestVendorList}
                      labelledBy='List'
                      overrideStrings={{
                        allItemsAreSelected: 'All Lists', // Custom text for when all items are selected
                      }}
                      valueRenderer={CustomValueRenderer}
                    />
                  </div>
                </div>

                {/* <div className='me-4'>
                <Select
                  styles={customStyles}
                  isSearchable={false}
                  options={requestSortOptions}
                  name='sortType'
                  onChange={(e: any) => {
                    setVlaRequestSortType(Number(e.value))
                  }}
                  placeholder={'Sort'}
                />
              </div> */}

                <div className='me-4 test jobs'>
                  <div className='property-test d-none-multi-checbox'>
                    <div
                      className={`multi-select-container jobs-property ms-0 me-5 sort-type ${
                        vlaRequestSortType?.length === 0 ? 'no-value' : 'has-value'
                      }`}
                    >
                      <MultiSelect
                        options={requestSortOptions}
                        value={vlaRequestSortType}
                        onChange={(e: any) => {
                          let newVal: any = e
                          if (e?.length > 1) newVal = e.slice(1)
                          setVlaRequestSortType(newVal)
                        }}
                        labelledBy=''
                        overrideStrings={{
                          allItemsAreSelected: '',
                        }}
                        hasSelectAll={false}
                        disableSearch
                        closeOnChangedValue={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Seach by Vendor */}
            {/* <div className='me-4'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='Vendor...'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchVendor}
                onChange={(e: any) => setSearchVendor(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div> */}

            {/* Property */}
            {/* <div className='me-4'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='Property...'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchProperty}
                onChange={(e: any) => setSearchProperty(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div> */}

            {/* Type */}
            {/* <div className='me-4 property test'>
            <div
              className={`multi-select-container ${
                vendorType.length === 0 ? 'no-value type' : 'has-value'
              }`}
            >
              <MultiSelect
                options={vendorTypeOptions}
                value={vendorType}
                onChange={setVendorType}
                labelledBy='Type'
                overrideStrings={{
                  allItemsAreSelected: 'All Types', // Custom text for when all items are selected
                }}
              />
             
            </div>
          </div> */}

            {/* List */}
            {/* <div className='me-4  property test'>
            <div
              className={`multi-select-container ${
                vendorList.length === 0 ? 'no-value list' : 'has-value'
              }`}
            >
              <MultiSelect
                options={vendorListOptions}
                value={vendorList}
                onChange={setVendorList}
                labelledBy='List' 
                overrideStrings={{
                  allItemsAreSelected: 'All Lists', // Custom text for when all items are selected
                }}
              />
            </div>
          </div> */}

            {/* Sort Type */}
            {/* {!isInfoList && (
            <div className='me-4'>
              <Select
                styles={customStyles}
                isSearchable={false}
                options={requestSortOptions}
                name='sortType'
                onChange={(e: any) => {
                  setRequestSortType(Number(e.value))
                }}
                placeholder={'Sort'}
              />
            </div>
          )} */}

            {checkedValues?.length > 0 && (
              <>
                {checkedValues?.map((ch: any, ind: any) => {
                  if (ch == true) totalSelected = totalSelected + 1
                  return <></>
                })}
              </>
            )}
            {isInfoList && (
              <div className='ms-auto'>
                <div className='d-flex'>
                  {totalSelected > 0 && (
                    <>
                      <div className='d-flex '>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color'>
                            {' '}
                            <i>Total Selected </i>
                          </label>
                        </h4>
                        <span className='ms-5 ps-1 my-2 me-4 white-dark-theme-color'>
                          <b>{totalSelected}</b>
                        </span>
                      </div>
                      {activeTab == 'all' ? (
                        <>
                          {/* Delete CTA */}
                          <button
                            type='button'
                            className='btn btn-sm fw-bold  ms-3 red-hollow-btn ps-2 '
                            onClick={() => {
                              // swal
                              //   .fire({
                              //     text: 'Are you sure you want to delete the selected vendors ?',
                              //     icon: 'warning',
                              //     showConfirmButton: true,
                              //     confirmButtonColor: '#D72852',
                              //     confirmButtonText: 'Yes',
                              //     showCancelButton: true,
                              //     // cancelButtonColor: "transparent",
                              //     cancelButtonText: 'Cancel',
                              //   })
                              swal
                                .fire({
                                  html: `<div class='fs-3'> <b>Delete Vendors </b></div>
                                      <div class='fs-5 mt-4'> Are you sure you want to delete the selected vendors? </div>`,
                                  // icon: 'warning',
                                  showConfirmButton: true,
                                  confirmButtonColor: '#35475e',
                                  confirmButtonText: 'Yes',
                                  showCancelButton: true,
                                  cancelButtonText: 'No',
                                  cancelButtonColor: '#fff',
                                  customClass: {
                                    confirmButton: 'custom-confirm-button',
                                    cancelButton: 'custom-cancel-button',
                                    popup: 'custom-popup',
                                  },
                                  // reverseButtons: true,
                                })
                                .then((res) => {
                                  if (res.isConfirmed) {
                                    deleteVendors()
                                  }
                                })
                            }}
                            disabled={isLoading}
                          >
                            <img src={trashImg} height={18} width={18} className='me-4' /> Delete
                          </button>
                          {/* Add to List CTA */}
                          <button
                            type='button'
                            className='btn btn-sm fw-bold  mx-3 px-2 select-btn'
                            onClick={() => {
                              setAddToListModal(true)
                            }}
                            disabled={isLoading}
                          >
                            <img src={addToListIcon} height={18} width={18} className='me-4' /> Add
                            to List
                          </button>
                        </>
                      ) : (
                        <button
                          type='button'
                          className='btn btn-sm fw-bold  mx-3 term-btn px-2 btn-fit-content'
                          onClick={() => {
                            swal
                              .fire({
                                text: 'Are you sure you want to remove the selected vendors from list ?',
                                icon: 'warning',
                                showConfirmButton: true,
                                confirmButtonColor: '#D72852',
                                confirmButtonText: 'Yes',
                                showCancelButton: true,
                                // cancelButtonColor: "transparent",
                                cancelButtonText: 'Cancel',
                              })
                              .then((res) => {
                                if (res.isConfirmed) {
                                }
                              })
                          }}
                        >
                          <img src={crossBtn} height={18} width={18} className='me-4' /> Remove from
                          List
                        </button>
                      )}
                    </>
                  )}

                  {isInfoList && (
                    <button
                      type='button'
                      className='btn btn-sm fw-bold green-submit-btn status-w-120 ps-2 pe-3 ms-auto btn-fit-content'
                      onClick={() => {
                        navigate('/add-vendor/1')
                      }}
                    >
                      <img src={addWhite} height={18} width={18} className='me-4' /> New Vendor
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {isContract && (
          <div className='d-flex align-items-center py-5 pt-2 gy-3 mx-0 mt-4 xyz tst'>
            <label className='head-text me-5 pe-3' style={{fontWeight: '600'}}>
              Search{' '}
            </label>

            <>
              <div className='me-4 property test  multi-select-white-bg'>
                <div
                  className={`multi-select-container vendor ${
                    allInfoDevelopment?.length === 0 ? 'no-value' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    // options={service_contracts_data.map((val: any) => ({
                    //   label: val?.company || '',
                    //   value: val?.company || '',
                    //   devType:
                    //     val?.isCommunity === 'true'
                    //       ? 'comm'
                    //       : val?.isBuilding === 'true'
                    //       ? 'build'
                    //       : '',
                    // }))}
                    options={[]}
                    value={allRequestVendor}
                    onChange={(selectedValues: string[]) => {
                      setAllRequestVendor(selectedValues)

                      // Filter the contracts based on the selected developments
                      const filtered = service_contracts_data.filter(
                        (contract) => selectedValues.includes(contract.company) // You can customize the filter logic here
                      )

                      setFilteredContracts(filtered)
                    }}
                    labelledBy='Vendor...'
                    overrideStrings={{
                      allItemsAreSelected: 'All Developments', // Custom text for when all items are selected
                    }}
                    valueRenderer={CustomValueRenderer}
                  />
                </div>
              </div>

              {/* Units */}
              <div className='me-4 property test  multi-select-white-bg'>
                <div
                  className={`multi-select-container Contract-Type ${
                    allInfoUnits?.length === 0 ? 'no-value' : 'has-value'
                  }`}
                >
                  <MultiSelect
                    options={[]}
                    value={allInfoUnits}
                    onChange={() => {}}
                    labelledBy='Unit...'
                    overrideStrings={{
                      allItemsAreSelected: 'All Units', // Custom text for when all items are selected
                    }}
                    valueRenderer={CustomValueRenderer}
                  />
                </div>
              </div>

              <div className='me-4 test jobs'>
                <div className='property-test d-none-multi-checbox'>
                  <div
                    className={`multi-select-container jobs-property ms-0 me-5 sort-type ${
                      allRequestSortType?.length === 0 ? 'no-value' : 'has-value'
                    }`}
                  >
                    <MultiSelect
                      options={requestSortOptions}
                      value={allRequestSortType}
                      onChange={(e: any) => {
                        let newVal: any = e
                        if (e?.length > 1) newVal = e.slice(1)
                        setAllRequestSortType(newVal)
                      }}
                      labelledBy=''
                      overrideStrings={{
                        allItemsAreSelected: '',
                      }}
                      hasSelectAll={false}
                      disableSearch
                      closeOnChangedValue={true}
                    />
                  </div>
                </div>
              </div>
            </>
          </div>
        )}
        <div className='card card-flush py-5  px-7'>
          {' '}
          {/* container-xxl */}
          <div className='app-main flex-column flex-row-fluid' id='------------kt_app_main'>
            <div className='table-responsive '>
              {/*  */}
              {!isInfoList && !isContract ? (
                <table
                  className='table align-start table-row-dashed fs-8 gy-5'
                  id='kt_ecommerce_sales_table'
                >
                  <thead className='fs-7'>
                    <th className='text-start min-w-175px pe-2'>Company</th>
                    <th className='text-start min-w-125px pe-2'>Type</th>
                    <th className='text-start min-w-125px pe-2'>List</th>
                    <th className='text-start min-w-100px pe-2'>Requests </th>
                    <th className='text-center min-w-150px '>Complete </th>
                    <th className='text-center min-w-150px px-3'>In Progress</th>
                    <th className='text-center min-w-150px px-3'>Cancelled </th>
                    <th className='text-start min-w-75px pe-0'>Rating </th>
                  </thead>
                  <tbody className='fw-semibold head-text'>
                    {' '}
                    {/* className='fw-semibold text-gray-600'*/}
                    {tableDataRequest?.length ? (
                      tableDataRequest?.map((vd: any, i: any) => {
                        return (
                          <tr>
                            <td
                              data-kt-ecommerce-order-filter='order_id'
                              className='text-start ps-0'
                            >
                              {(page - 1) * 10 + (i + 1)}
                              {'. '}
                              {vd?.companyName ? vd?.companyName : ''}
                            </td>

                            <td className='text-start ps-0'>
                              {vd?.vendorType == 0
                                ? 'Service Provider'
                                : vd?.vendorType == 1
                                ? 'Supplier'
                                : '-'}
                            </td>

                            <td className='text-start ps-0 pe-5' data-order='Denied'>
                              <div>
                                <div className='d-flex align-items-center'>
                                  <div
                                    className='bullet-div-sm me-3 '
                                    style={{backgroundColor: `${vd?.vendor_list?.[0]?.colorCode}`}}
                                  ></div>
                                  {vd?.vendor_list?.[0]?.name ? vd?.vendor_list?.[0]?.name : '-'}
                                  {vd?.vendor_list?.length > 1 && (
                                    <img
                                      src={eye}
                                      height={18}
                                      width={18}
                                      className='ms-auto cursor-pointer me-3'
                                      onClick={() => {
                                        if (show[i] == true) {
                                          let values = [...show]
                                          values[i] = false
                                          setShow(values)
                                        } else {
                                          let values = [...show]

                                          values?.map((vl: any, ind: any) => {
                                            if (i == ind) values[ind] = true
                                            else values[ind] = false
                                          })

                                          setShow(values)
                                        }
                                      }}
                                    />
                                  )}
                                </div>
                                {show[i] && (
                                  <div
                                    style={
                                      i == tableDataRequest?.length - 1 ||
                                      i == tableDataRequest?.length - 2
                                        ? {height: 'unset'}
                                        : {height: '10px'}
                                    }
                                  >
                                    <div className='card p-3 status-w-225 mt-3 vend-lists mb-7'>
                                      {vd?.vendor_list?.map((vl: any, ind: any) => {
                                        return (
                                          <div className='d-flex align-items-baseline '>
                                            <div
                                              className='bullet-div-sm me-3 '
                                              style={{backgroundColor: `${vl?.colorCode}`}}
                                            ></div>
                                            {vl?.name ? vl?.name : '-'}
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className='text-start ps-0'>{vd?.requestSummery?.allRequests}</td>

                            <td className='text-center px-3'>
                              <div className='status renewed-st mx-auto status-w-120'>
                                {vd?.requestSummery?.completeRequests}
                              </div>
                            </td>

                            <td className='text-center px-3' data-order='2022-09-11'>
                              <div className='status requested-st  mx-auto status-w-120'>
                                {vd?.requestSummery?.inprogressRequests}
                              </div>
                            </td>

                            <td className='text-center px-3' data-order='2022-09-09'>
                              <div className='status cancelled-st  mx-auto status-w-120'>
                                {vd?.requestSummery?.cancelledRequests}
                              </div>
                            </td>
                            <td className='text-start ps-0' data-order='2022-09-11'>
                              <div className='d-flex align-items-center '>
                                <img src={starYellow} height={18} width={18} className='me-1' />
                                {vd?.rating ? vd?.rating : '-'}
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <img src={noData} alt='' width={350} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : isContract ? (
                <table
                  className='table align-start table-row-dashed fs-8 gy-4 '
                  id='kt_ecommerce_sales_table'
                >
                  <thead className='fs-7'>
                    <th className='text-start min-w-175px pe-2'>Company</th>
                    <th className='text-start min-w-125px pe-2'>Contract Type</th>
                    <th className='text-start min-w-125px pe-2'>Contract #</th>
                    <th className='text-start min-w-100px pe-2'>Start</th>
                    <th className='text-start min-w-100px pe-2'>End</th>
                    <th className='text-start min-w-80px px-3'>Expiring In</th>
                    <th className='text-center min-w-100px px-3'>Status</th>
                    <th className='text-center min-w-100px px-3'>Total Services </th>
                    <th className='text-center min-w-100px px-3'>Not Started</th>
                    <th className='text-center min-w-100px px-3'>Complete</th>
                    <th className='text-center min-w-100px px-3'>In-Progress</th>
                    <th className='text-center min-w-100px px-3'>Cancelled</th>
                    <th className='text-center min-w-75px pe-0'>Rating</th>
                  </thead>
                  <tbody className='fw-semibold head-text'>
                    {' '}
                    {/* className='fw-semibold text-gray-600'*/}
                    {filteredContracts?.length ? (
                      filteredContracts?.map((vd: any, i: any) => {
                        return (
                          <tr
                            className='cursor-pointer'
                            onClick={() => {
                              navigate('/service-contract/67358b4e9b1176d803079189')
                            }}
                          >
                            <td
                              data-kt-ecommerce-order-filter='order_id'
                              className='text-start ps-0'
                            >
                              {(page - 1) * 10 + (i + 1)}
                              {'. '}
                              {vd?.company ? vd?.company : ''}
                            </td>

                            <td className='text-start ps-0'>
                              {vd?.contractType ? vd?.contractType : ''}
                            </td>
                            <td className='text-start ps-0'>{vd?.contractNumber}</td>
                            <td className='text-start ps-0'>{vd?.start}</td>
                            <td className='text-start ps-0'>{vd?.end}</td>
                            <td className='text-center ps-0'>{vd?.expiringIn}</td>
                            <td className='text-center ps-0'>
                              <div
                                className={
                                  vd?.status == ''
                                    ? 'status booked-bar'
                                    : vd?.status == 'Ongoing'
                                    ? 'status active-bar  '
                                    : vd?.status == 'Expiring'
                                    ? 'status expiring-bar'
                                    : vd?.status == 'Expired'
                                    ? 'status expired-bar'
                                    : 'status  no-st-bar'
                                }
                              >
                                {vd?.status}
                              </div>
                            </td>
                            {/* <td className='text-start ps-0'>{vd?.status}</td> */}

                            <td className='text-center px-3' data-order='2022-09-09'>
                              <div className='fw-bolder'>{vd?.totalServices}</div>
                            </td>
                            <td className='text-center px-3' data-order='2022-09-09'>
                              <div className='status not-started mx-auto status-w-75'>
                                <div className='text-dark'>{vd?.notStarted}</div>
                              </div>
                            </td>
                            <td className='text-center px-3' data-order='2022-09-09'>
                              <div className='status renewed-st  mx-auto status-w-75 text-dark  '>
                                <div className='text-dark'> {vd?.complete}</div>
                              </div>
                            </td>
                            <td className='text-center px-3' data-order='2022-09-09'>
                              <div className='status requested-st  mx-auto status-w-75'>
                                <div className='text-dark'> {vd?.inProgress}</div>
                              </div>
                            </td>
                            <td className='text-center px-3' data-order='2022-09-09'>
                              <div className='status cancelled-st  mx-auto status-w-75'>
                                <div className='text-dark'> {vd?.cancelled}</div>
                              </div>
                            </td>
                            <td className='text-center ps-0' data-order='2022-09-11'>
                              <div className='d-flex align-items-center '>
                                <img src={starYellow} height={18} width={18} className='me-1' />
                                {vd?.rating ? vd?.rating : '-'}
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <img src={noData} alt='' width={350} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              ) : (
                <table
                  className='table align-start table-row-dashed fs-8 gy-5 '
                  id='kt_ecommerce_sales_table'
                >
                  <thead className='fs-7'>
                    <>
                      <th className='text-start min-w-25px test'>
                        <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                          <input
                            className='form-check-input cursor-pointer'
                            type='checkbox'
                            id='vacant0'
                            name='none'
                            checked={
                              totalSelected == tableData?.length && tableData?.length != 0
                                ? true
                                : false
                            }
                            onChange={(e: any) => {
                              let values = [...checkedValues]
                              if (e.target.checked) {
                                for (let i = 0; i < tableData?.length; i++) {
                                  values[i] = true
                                }
                              } else {
                                for (let i = 0; i < tableData?.length; i++) {
                                  values[i] = false
                                }
                              }
                              setCheckedValues(values)
                            }}
                          />
                        </div>
                      </th>
                    </>

                    <th className='text-start min-w-175px ps-2 pe-5'>Company</th>
                    <th className='text-start min-w-125px ps-2 pe-5'>Type</th>
                    <th className='text-start min-w-200px ps-2 pe-5'>List </th>
                    {activeTab == 'all' && (
                      <th className='text-start min-w-125px ps-2 pe-5'>Properties </th>
                    )}
                    <th className='text-start min-w-125px ps-2 pe-5'>TRN</th>
                    <th className='text-start min-w-150px ps-2 pe-5'>Trade License </th>
                    <th className='text-start min-w-150px ps-2 pe-5'>Email</th>
                    <th className='text-start min-w-125px ps-2 pe-5'>Mobile</th>
                    <th className='text-start min-w-150px ps-2 pe-5'>Phone</th>
                    <th className='text-start min-w-100px ps-2 pe-5'>Rating</th>
                    <th className='text-start min-w-150px ps-2 pe-5'>Registration Status</th>
                    <th className='text-center min-w-125px'>Status</th>
                    <th className='text-start min-w-150px ps-2 pe-5'>Created By</th>
                    <th className='text-start min-w-150px ps-2 pe-5'>Last Updated</th>
                  </thead>
                  <tbody className='fw-semibold head-text'>
                    {' '}
                    {/* className='fw-semibold text-gray-600'*/}
                    {tableData?.length > 0 ? (
                      tableData?.map((vd: any, i: any) => {
                        return (
                          <tr>
                            <td className='text-start ps-0 test'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                <input
                                  className='form-check-input cursor-pointer'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={checkedValues[i] == true ? true : false}
                                  onChange={(e: any) => {
                                    const values = [...checkedValues]
                                    values[i] = !values[i]
                                    setCheckedValues(values)
                                  }}
                                />
                              </div>
                            </td>

                            <td
                              data-kt-ecommerce-order-filter='order_id'
                              className='text-start ps-0 cursor-pointer ps-2 pe-5'
                              onClick={() => goToVendor(vd)}
                            >
                              {(page - 1) * limit + (i + 1)}
                              {'. '}
                              {vd?.companyName ? vd?.companyName : ''}
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer ps-2 pe-5'
                              onClick={() => goToVendor(vd)}
                            >
                              {vd?.vendorType == 0
                                ? 'Service Provider'
                                : vd?.vendorType == 1
                                ? 'Supplier'
                                : '-'}
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer ps-2 pe-5'
                              data-order='Denied'
                            >
                              <div>
                                {vd?.vendor_list?.length > 0 ? (
                                  <div className='d-flex align-items-center'>
                                    <div
                                      className='d-flex align-items-center'
                                      onClick={() => goToVendor(vd)}
                                    >
                                      <div
                                        className='bullet-div-sm me-3'
                                        style={{
                                          backgroundColor: `${vd?.vendor_list?.[0]?.colorCode}`,
                                        }}
                                      ></div>
                                      {vd?.vendor_list?.[0]?.name
                                        ? vd?.vendor_list?.[0]?.name
                                        : '-'}
                                    </div>
                                    {vd?.vendor_list?.length > 1 && (
                                      <img
                                        src={eye}
                                        height={18}
                                        width={18}
                                        className='ms-auto cursor-pointer me-3'
                                        onClick={() => {
                                          if (show[i] == true) {
                                            let values = [...show]
                                            values[i] = false
                                            setShow(values)
                                          } else {
                                            let values = [...show]

                                            values?.map((vl: any, ind: any) => {
                                              if (i == ind) values[ind] = true
                                              else values[ind] = false
                                            })

                                            setShow(values)
                                          }
                                        }}
                                      />
                                    )}
                                  </div>
                                ) : (
                                  <div className='' onClick={() => goToVendor(vd)}>
                                    {'-'}
                                  </div>
                                )}

                                {show[i] && (
                                  <div
                                    style={
                                      i == tableData?.length - 1 || i == tableData?.length - 2
                                        ? {height: 'unset'}
                                        : {height: '10px'}
                                    }
                                  >
                                    <div className='card p-3 status-w-225 mt-3 vend-lists mb-7'>
                                      {vd?.vendor_list?.map((vl: any, ind: any) => {
                                        return (
                                          <div className='d-flex align-items-baseline '>
                                            <div
                                              className='bullet-div-sm me-3 '
                                              style={{backgroundColor: `${vl?.colorCode}`}}
                                            ></div>
                                            {vl?.name ? vl?.name : '-'}
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                            {activeTab == 'all' && (
                              <td
                                className='text-start ps-0 cursor-pointer ps-2 pe-5'
                                onClick={() => goToVendor(vd)}
                                data-order='Denied'
                              >
                                {vd?.property > 0 ? vd?.property : 'Not Assigned'}
                              </td>
                            )}
                            <td
                              className='text-start ps-0 cursor-pointer  ps-2 pe-5'
                              onClick={() => goToVendor(vd)}
                            >
                              {vd?.TRN ? vd?.TRN : ''}
                            </td>

                            <td
                              className='text-start cursor-pointer ps-2 pe-5'
                              onClick={() => goToVendor(vd)}
                            >
                              {vd?.tradeLicenseNo ? vd?.tradeLicenseNo : '-'}
                            </td>

                            <td
                              className='text-start cursor-pointer ps-2 pe-5'
                              onClick={() => goToVendor(vd)}
                              data-order='2022-09-11'
                            >
                              {vd?.email ? vd?.email : '-'}
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer ps-2 pe-5'
                              onClick={() => goToVendor(vd)}
                              data-order='2022-09-11'
                            >
                              {vd?.phoneNumber ? `+${vd?.countryCode} ${vd?.phoneNumber}` : ''}
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer ps-2 pe-5'
                              onClick={() => goToVendor(vd)}
                              data-order='2022-09-11'
                            >
                              {vd?.landlineNumber ? vd?.landlineNumber : '-'}
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer ps-2 pe-5'
                              onClick={() => goToVendor(vd)}
                              data-order='2022-09-11'
                            >
                              <div className='d-flex align-items-center'>
                                <img src={starYellow} height={18} width={18} />
                                {vd?.rating ? vd?.rating : '-'}
                              </div>
                            </td>

                            <td
                              className='text-start ps-0 cursor-pointer ps-2 pe-5'
                              onClick={() => goToVendor(vd)}
                              data-order='2022-09-11'
                            >
                              <div className='d-flex align-items-center'>
                                {vd?.registrationStatus == 0 ? (
                                  <div className='red-dot me-1'></div>
                                ) : vd?.registrationStatus == 1 ? (
                                  <div className='green-dot me-1'></div>
                                ) : (
                                  <></>
                                )}
                                <span
                                  className={
                                    vd?.registrationStatus == 0
                                      ? 'red-clr'
                                      : vd?.registrationStatus == 1
                                      ? 'green-clr'
                                      : ''
                                  }
                                >
                                  {' '}
                                  {vd?.registrationStatus == 0
                                    ? 'Not Registered'
                                    : vd?.registrationStatus == 1
                                    ? 'Registered'
                                    : '-'}
                                </span>
                              </div>
                            </td>

                            <td
                              className='text-center  cursor-pointer'
                              onClick={() => goToVendor(vd)}
                              data-order='2022-09-11'
                            >
                              <div
                                className={
                                  vd?.saveStatus == 0
                                    ? 'status draft-st status-w-120 mx-auto'
                                    : vd?.saveStatus == 1
                                    ? 'status create-st status-w-120 mx-auto'
                                    : 'status not-saved status-w-120 mx-auto'
                                }
                              >
                                {vd?.saveStatus == 0
                                  ? 'Draft'
                                  : vd?.saveStatus == 1
                                  ? 'Created'
                                  : '-'}
                              </div>
                            </td>

                            <td
                              className='py-3 ps-0 cursor-pointer ps-2 pe-5'
                              onClick={() => goToVendor(vd)}
                            >
                              <p className='my-0'>
                                {vd?.createdBy?.[0] &&
                                  `${vd?.createdBy?.[0]?.firstName} ${vd?.createdBy?.[0]?.lastName} - `}
                              </p>
                              <p className='my-0'>
                                {vd?.createdAt
                                  ? `${moment(vd?.createdAt).format('DD.MM.YYYY - h:m a')}`
                                  : '-'}
                              </p>
                            </td>

                            <td
                              className='py-3 ps-0 cursor-pointer ps-2 pe-5'
                              onClick={() => goToVendor(vd)}
                            >
                              {vd?.updatedBy?.length > 0 ? (
                                <>
                                  <p className='my-0'>
                                    {vd?.updatedBy?.[0] &&
                                      `${vd?.updatedBy?.[0]?.firstName} ${vd?.updatedBy?.[0]?.lastName} - `}
                                  </p>
                                  <p className='my-0'>
                                    {vd?.updatedAt
                                      ? ` ${moment(vd?.updatedAt).format('DD.MM.YYYY - h:m a')}`
                                      : '- -'}
                                  </p>
                                </>
                              ) : (
                                <>{'-'}</>
                              )}
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={10} className='text-center'>
                          <img src={noData} alt='' width={350} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>

            <div className='row mb-5'>
              <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
              <div className='row mt-5'>
                <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end mt-5'>
                  <div
                    className='dataTables_paginate paging_simple_numbers'
                    id='kt_ecommerce_sales_table_paginate'
                  >
                    {isInfoList ? (
                      <ReactPaginate
                        breakLabel='...'
                        nextLabel='>'
                        pageClassName='paginate_button page-item +'
                        pageLinkClassName='page-link'
                        containerClassName='pagination'
                        activeClassName='active'
                        previousClassName='paginate_button page-item previous'
                        nextClassName='paginate_button page-item next'
                        previousLinkClassName='page-link'
                        nextLinkClassName='page-link'
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={5}
                        pageCount={pageLimit}
                        forcePage={page - 1}
                        previousLabel='<'
                      />
                    ) : (
                      <ReactPaginate
                        breakLabel='...'
                        nextLabel='>'
                        pageClassName='paginate_button page-item +'
                        pageLinkClassName='page-link'
                        containerClassName='pagination'
                        activeClassName='active'
                        previousClassName='paginate_button page-item previous'
                        nextClassName='paginate_button page-item next'
                        previousLinkClassName='page-link'
                        nextLinkClassName='page-link'
                        onPageChange={handlePageClickRequest}
                        pageRangeDisplayed={5}
                        pageCount={reqPageLimit}
                        forcePage={reqPage - 1}
                        previousLabel='<'
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Select LISTS MODAL */}
      {addToListModal && (
        <AddToLists
          show={addToListModal}
          handleClose={() => {
            setAddToListModal(false)
          }}
          addedVendors={totalSelected}
          addedLists={''}
          updateLists={(data: any) => {
            console.log(data)

            let values: any = []
            let i: any = 0

            data?.map((dt: any, ind: any) => {
              if (dt?.check) {
                values[i] = dt?._id
                i++
              }
            })

            addToVendors(values)
          }}
        />
      )}

      {/*  */}
      <ColorPickerModal
        show={showPickerModal}
        handleClose={() => {
          setShowPickerModal(false)
        }}
        selectedVL={selected}
        index={selectedInd}
        allVL={vendorsTabList}
        updateList={(data: any, data2: any) => {
          setSelected(data)
          setVendorsTabList(data2)
        }}
        position={{
          top: cardPosition.top + 'px',
          left: cardPosition.left + 'px',
        }}
        from={'vendors'}
      />
    </>
  )
}

export default Vendors
