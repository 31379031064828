const BookingFeeDetails = ({ tb }) => {

    return (
        <div className='row mx-1'>
            {/* Amount Calculation */}
            <div className='col-12 col-md-6 px-0 mt-4'>
                <div className='my-4'>
                    <h4 className='my-2'>
                        <label className='labl-gry'> Amount Calculation</label>
                    </h4>
                    <h4 className='my-2 text-dark'>
                        <b>
                            {tb?.amountCalculationType === 0
                                ? 'Fixed Fee'
                                : tb?.amountCalculationType === 1
                                    ? '% from the charges applied in a lease'
                                    : '-'}
                        </b>
                    </h4>
                </div>
            </div>

            {/* Fee */}
            <div className='col-12 col-md-6 px-0 mt-4'>
                <div className='my-4'>
                    <h4 className='my-2'>
                        <label className='labl-gry'>Fee</label>
                    </h4>
                    <h4 className='my-2 text-dark'>
                        <b>
                            {tb?.amountCalculationType === 0
                                ? `${tb?.fixedAmount} AED`
                                : tb?.amountCalculationType === 1
                                    ? `${tb?.feePercentage}% `
                                    : '-'}
                        </b>
                    </h4>
                </div>
            </div>

            {/* Charge - Only shown for percentage based */}
            {tb?.amountCalculationType === 1 && (
                <div className='col-12 col-md-6 px-0 mt-4'>
                    <div className='my-4'>
                        <h4 className='my-2'>
                            <label className='labl-gry'>Charge</label>
                        </h4>
                        <h4 className='my-2 text-dark'>
                            <b>
                                {tb?.selectLeaseChargeType === 0
                                    ? 'Total Rent Value'
                                    : tb?.selectLeaseChargeType === 1
                                        ? 'Single Rent Installment'
                                        : '-'}
                            </b>
                        </h4>
                    </div>
                </div>
            )}

            {/* Credited From */}
            <div className='col-12 col-md-6 px-0 mt-4'>
                <div className='my-4'>
                    <h4 className='my-2'>
                        <label className='labl-gry'>Credited From</label>
                    </h4>
                    <h4 className='my-2 text-dark'>
                        <b>
                            {tb?.creditType === 0
                                ? 'Security Deposit'
                                : tb?.creditType === 1
                                    ? 'Rent Charges'
                                    : '-'}
                        </b>
                    </h4>
                </div>
            </div>

            {/* Due Date */}
            <div className='col-12 col-md-6 px-0 mt-4'>
                <div className='my-4'>
                    <h4 className='my-2'>
                        <label className='labl-gry'>Due Date</label>
                    </h4>
                    <h4 className='my-2 text-dark'>
                        <b>

                            Time charge is applied
                        </b>

                    </h4>
                </div>
            </div>

            {/* Invoicing */}
            <div className='col-12 col-md-6 px-0 mt-4'>
                <div className='my-4'>
                    <h4 className='my-2'>
                        <label className='labl-gry'>Invoicing</label>
                    </h4>
                    <h4 className='my-2 text-dark d-flex align-items-center'>
                        <div className={`me-2 ${tb?.autoInvoicingEnabled ? 'light-green-dot ' : 'red-dot'}`}></div>
                        <b>{tb?.autoInvoicingEnabled ? 'Enabled' : 'Disabled'}</b>
                    </h4>
                </div>
            </div>
        </div>
    );
};

export default BookingFeeDetails;