import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {setChatScreen, setChatSelectedDataList} from '../../../redux/counterSlice'
import noData from '../../../img/NoData1.svg'

function All() {
  const myDivRef = useRef(null)

  const dispatch = useDispatch()
  const chatComeFrom = useSelector((state: any) => state.counterReducer.chatComeFrom)
  const chatDataSearch = useSelector((state: any) => state.counterReducer.chatDataSearch)

  const [selectedDataListTenant, setSelectedDataListTenant] = useState<any>([])
  const [selectedDataListStaff, setSelectedDataListStaff] = useState<any>([])

  const goToNewMessage = async () => {
    if (chatComeFrom == 'page') {
      dispatch(setChatScreen(1))
    }
    let body: any = {
      tenantIds: selectedDataListTenant,
      staffIds: selectedDataListStaff,
    }
    if (selectedDataListStaff.length + selectedDataListTenant.length == 1) {
      body['type'] = 0
    } else if (selectedDataListStaff.length + selectedDataListTenant.length > 1) {
      body['type'] = 1
    }
    dispatch(setChatSelectedDataList(body))
  }

  const makeGroup = (e: any, i: any) => {
    let id: any = chatDataSearch[i]
    if (chatDataSearch[i].role == 'Tenant') {
      if (e.target.checked) {
        if (selectedDataListTenant.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListTenant((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListTenant(selectedDataListTenant.filter((e: any) => e !== id))
      }
    } else {
      if (e.target.checked) {
        if (selectedDataListStaff.find((e: any) => e._id === id._id) == undefined) {
          setSelectedDataListStaff((prev: any) => [...prev, id])
        }
      } else {
        setSelectedDataListStaff(selectedDataListStaff.filter((e: any) => e !== id))
      }
    }
  }

  return (
    <>
      <table
        className='table align-middle table-row-dashed fs-6 gy-5 '
        id='kt_ecommerce_sales_table'
      >
        <thead>
          <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
            <th className=' min-w-30px'>#</th>
            <th className=' min-w-100px'>Name</th>
            <th className=' min-w-100px'>Role</th>
          </tr>
        </thead>
        <tbody className='fw-semibold text-gray-600'>
          {chatDataSearch.length ? (
            chatDataSearch.map((v: any, i: any) => (
              <tr>
                <>
                  <td
                    data-kt-ecommerce-order-filter='order_id'
                    className='form-check form-check-custom form-check-solid form-check-sm'
                    style={{border: 'none'}}
                  >
                    <input
                      className='form-check-input'
                      type='checkbox'
                      onChange={(e: any) => makeGroup(e, i)}
                    />
                  </td>
                  <td className='' data-order='2022-09-11'>
                    {v.firstName} {v.lastName}
                  </td>
                  <td className='' data-order='2022-09-11'>
                    {v.role}
                  </td>
                </>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={10} className='text-center'>
                <img src={noData} alt='' width={250} />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className='d-flex align-items-center justify-content-end mb-5'>
        <a
          className='btn btn-sm fw-bold btn-primary btn-green m-0'
          onClick={() => goToNewMessage()}
        >
          Done
        </a>
      </div>
      {/* <div className='card-title'>
        <div className='symbol-group symbol-hover'>
          <div className='symbol  symbol-35px symbol-circle '>
            <img alt='Pic' src='media/avatars/300-23.jpg' />
          </div>
          <div className='symbol  symbol-35px symbol-circle '>
            <img alt='Pic' src='media/avatars/300-12.jpg' />
          </div>
          <div className='symbol  symbol-35px symbol-circle '>
            <span className='symbol-label  bg-light-warning text-warning 40px '>C</span>
          </div>
          <div className='symbol  symbol-35px symbol-circle '>
            <img alt='Pic' src='media/avatars/300-1.jpg' />
          </div>
          <div className='symbol  symbol-35px symbol-circle '>
            <span className='symbol-label  bg-light-danger text-danger 40px '>O</span>
          </div>
          <div className='symbol  symbol-35px symbol-circle '>
            <span className='symbol-label  bg-light-primary text-primary 40px '>N</span>
          </div>
          <div className='symbol  symbol-35px symbol-circle '>
            <img alt='Pic' src='media/avatars/300-1.jpg' />
          </div>
          <a
            href='#'
            className='symbol symbol-35px symbol-circle'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_view_users'
          >
            <span
              className='symbol-label fs-8 fw-bold'
              data-bs-toggle='tooltip'
              data-bs-trigger='hover'
              data-bs-original-title='View more users'
              data-kt-initialized='1'
              onClick={() => {
                setmodalopen(true)
                console.log('\nsetmodalopen(true)', modalopen)
              }}
            >
              +42
            </span>
          </a>
        </div>
      </div>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-600px'
        show={modalopen}
        backdrop={true}
        size='sm'
      >
        <div className='modal-dialog mw-650px'>
          <div className='modal-content p-5' style={{width: '460px'}}>
            <div className='modal-header p-0 border-0 justify-content-end'></div>

            <div className='scroll-y d-flex flex-column gap-5'>
              <div className='align-items-center make_grid'>
                <h1 className='m-0 text-center green_color'>Users List</h1>
                <div
                  className='btn btn-sm btn-icon btn-active-color-primary'
                  data-bs-dismiss='modal'
                  onClick={() => setmodalopen(false)}
                >
                  <img src={close} alt='' />
                </div>
              </div>

              <div className='d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed'>
                <div className='d-flex align-items-center gap-5 '>
                  <div className='symbol symbol-35px symbol-circle'>
                    <img alt='Pic' src='media/avatars/300-6.jpg' />
                  </div>

                  <div className='ms-0'>
                    <h6 className='d-flex align-items-center fs-5 fw-bold'>
                      Emma Smith
                      <span className='badge badge-light fs-8 fw-semibold ms-2'>Art Director </span>
                    </h6>

                    <div className='fw-semibold text-muted'>smith@kpmg.com</div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed'>
                <div className='d-flex align-items-center gap-5 '>
                  <div className='symbol symbol-35px symbol-circle'>
                    <img alt='Pic' src='media/avatars/300-6.jpg' />
                  </div>

                  <div className='ms-0'>
                    <h6 className='d-flex align-items-center fs-5 fw-bold'>
                      Emma Smith
                      <span className='badge badge-light fs-8 fw-semibold ms-2'>Art Director </span>
                    </h6>

                    <div className='fw-semibold text-muted'>smith@kpmg.com</div>
                  </div>
                </div>
              </div>
              <div className='d-flex flex-stack py-5 border-bottom border-gray-300 border-bottom-dashed'>
                <div className='d-flex align-items-center gap-5 '>
                  <div className='symbol symbol-35px symbol-circle'>
                    <img alt='Pic' src='media/avatars/300-6.jpg' />
                  </div>

                  <div className='ms-0'>
                    <h6 className='d-flex align-items-center fs-5 fw-bold'>
                      Emma Smith
                      <span className='badge badge-light fs-8 fw-semibold ms-2'>Art Director </span>
                    </h6>

                    <div className='fw-semibold text-muted'>smith@kpmg.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}
    </>
  )
}

export default All
