import React, {useEffect, useState, useRef} from 'react'
import {Row, Col, OverlayTrigger, Tooltip, Modal} from 'react-bootstrap'
import {ApiPost, Bucket, ApiGet} from '../../../../../../../apiCommon/helpers/API/ApiData'
import moment from 'moment'
import InputNotRequired from '../../../../../General/InputNotRequired'
import {DatePicker} from 'antd'
import {Moment} from 'moment'
import {MultiSelect} from 'react-multi-select-component'
import {Controller, useForm} from 'react-hook-form'
import {useParams} from 'react-router-dom'
import {formatAmount, capitalizeFirstLetter} from '../../../../../../../Utilities/utils'
import redCrossFilled from '../../../../../../../img/remove-filled.png'
import addIcon from '../../../../../../../img/add-black.png'
import TextEditor from '../../../../../General/TextEditor'
import addWhite from '../../../../../../../img/add-white.png'
import moveUpImg from '../../../../../../../img/scroll-up.png'
import movedownImg from '../../../../../../../img/scroll-down.png'

interface ButtonProps {
  slctdInvoices?: any
  noteCardData?: any
  register?: any
  errors?: any
  setValue?: any
  trigger?: any
  control?: any
  updateNoteData?: any
  editMode?: boolean
  from?: string
  isPrint?: boolean,
  innerRef?: any,
  updateNote: any
}

const NoteDetailsCard = ({
  slctdInvoices,
  noteCardData,
  register,
  errors,
  setValue,
  trigger,
  control,
  updateNoteData,
  editMode,
  from,
  isPrint,
  innerRef,
  updateNote
}: ButtonProps) => {
  const {flg, id} = useParams()
  const [logo, setLogo] = useState<any>(null)


  const [noteData, setNoteData] = useState<any>(noteCardData)
  const [noteCompany, setNoteCompany] = useState<any>({
    name: '',
    address: '',
    phoneNumber: '',
    email: '',
    countryCode: '',
  })

  const [notesCount, setNotesCount] = useState<any>(`0/1000`)

  const [noteSettingsDetails, setNoteSettingsDetails] = useState<any>(null)
  //
  const getNoteSettingsDetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        setNoteSettingsDetails(res?.data?.data)
        if (flg == '1' && from === 'credit note'){
          setNoteCompany(res?.data?.data?.creditNoteCompany)
          setLogo(res?.data?.data?.creditNoteLogo)
        } else if(flg == '1' && from === 'debit note'){
          setNoteCompany(res?.data?.data?.debitNoteCompany)
          setLogo(res?.data?.data?.debitNoteLogo)
        }

      })
      .catch((err) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    if (flg != undefined) getNoteSettingsDetails()
  }, [])

  // function disabledDate(current: Moment) {
  //   return current && current > moment().endOf('day')
  // }
  function disabledDate(current: Moment) {
    return current && current < moment().startOf('day')
  }

  const [pickerKey, setPickerKey] = useState(Date.now())
  const handleDateChange = (date: any) => {
    if (date != null) {
      let values = {...noteData}
      values.dueDate = date.format('YYYY-MM-DD')
      setNoteData(values)
      updateNoteData({...noteData, ['dueDate'] : date.format('YYYY-MM-DD')})

      setValue('dueDate', date.format('YYYY-MM-DD'), {shouldValidate: true})
    } else {
      let values = {...noteData}
      values.dueDate = null

      setNoteData(values)
      if (!date) {
        // Force re-render by updating the key
        setPickerKey(Date.now())
      }
      updateNoteData({...noteData, ['dueDate']: null})

      setValue('dueDate', '', {shouldValidate: true})
    }
  }

  useEffect(() => {
    if(flg == '2' || editMode === false || flg == '3'){
      setNoteCompany(from ===  'credit note' ? noteCardData?.creditNoteCompany : from === 'debit note' ? noteCardData?.debitNoteCompany : {})
    }
    setNoteData(noteCardData)
  }, [noteCardData])

  const intervalRef = useRef<NodeJS.Timer | null>(null)

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const checkboxes = document.querySelectorAll(
        '.property-test .multi-select-container .rmsc .item-renderer input[type=checkbox]'
      )
      checkboxes.forEach((checkbox) => {
        checkbox.setAttribute('type', 'radio')
      })
    }, 100)

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  const [selectedInvoices, setSelectedInvoices] = useState<any>([])

  useEffect(() => {
    setSelectedInvoices(slctdInvoices)
  }, [slctdInvoices])

  var nonVat: any = 0
  var vatAmount: any = 0

  // Sections
  const handleAddNewSections = () => {
    let values = {...noteData}
    let valueItems: any = [...noteData?.itemDescriptions]
    valueItems.push({
      name: '',
      content: '',
    })
    values.itemDescriptions = valueItems
    updateNoteData(values)
  }

  //
  const handleRemoveSections = (index: any) => {
    let values = {...noteData}
    let valueItems: any = [...noteData?.itemDescriptions]
    valueItems.splice(index, 1)
    values.itemDescriptions = valueItems
    updateNoteData(values)
  }

  //
  const swapItems = (prevInd: any, nextInd: any) => {
    const values = {...noteData}
    const valuesSections = [...noteData?.itemDescriptions]

    // Add animation classes
    valuesSections[prevInd].animationClass = 'move-down'
    valuesSections[nextInd].animationClass = 'move-up'

    updateNoteData({...values, itemDescriptions: valuesSections})

    setTimeout(() => {
      let temp = valuesSections[prevInd]
      valuesSections[prevInd] = valuesSections[nextInd]
      valuesSections[nextInd] = temp
      valuesSections[prevInd].animationClass = ''
      valuesSections[nextInd].animationClass = ''

      values.itemDescriptions = valuesSections
      updateNoteData(values)
    }, 300)
  }

  const groupedByInvoice = noteData?.payment_sub_item?.reduce((acc: any, item: any) => {
    if (!acc[item.invoiceId]) {
      acc[item.invoiceId] = [];
    }
    acc[item.invoiceId].push(item);
    return acc;
  }, {})

  // Step 2: Convert to array of grouped items and sort by invoiceDate
const sortedGroups = Object.values(groupedByInvoice).sort((a: any, b: any) => {
  const dateA = new Date(a[0]?.frontendStorage?.invoiceDate);
  const dateB = new Date(b[0]?.frontendStorage?.invoiceDate);
  return dateA.getTime() - dateB.getTime(); // Sort by invoiceDate, oldest first
});



const sortedGroupsWithItems = sortedGroups.map((items: any) =>
  items.sort((a:any, b:any) => {
    const addedAtA = new Date(a?.frontendStorage?.addedAt);
    const addedAtB = new Date(b?.frontendStorage?.addedAt);
    return  addedAtB.getTime() - addedAtA.getTime();
  })
)

let trackIndex = 0



  return (
    <div>
      <div className='card card-flush py-7 px-5 pb-13 mb-5 mt-7'>
        <div ref={innerRef} className='invoice-content'>
        <Row className=''>
          <Col xs={6} sm={isPrint ? 8 : 6} md={9} lg={9}>
            <div className='d-flex mb-3 mt-2 align-items-center'>
              {logo == null || logo?.trim()?.length == 0 ? (
                <></>
              ) : (
                <img
                  src={`${Bucket}${logo}`}
                  height='110'
                  width='110'
                  style={{borderRadius: '7px'}}
                  className='me-4'
                />
              )}
            </div>
          </Col>
          <Col xs={6} sm={isPrint ? 4 : 6} md={3} lg={3} className='pe-4'>
            <div className='d-flex align-items-center my-2'>
              <label
                className='mb-1 me-5 pe-5 ms-auto head-text light-dark-theme-color'
                style={{minWidth: '50px', maxWidth: '50px'}}
              >
                <b>TRN</b>
              </label>
              <b className='white-dark-theme-color'>{noteCompany?.trn ? noteCompany?.trn : '-'}</b>
            </div>

            {/* Document Name */}
            <div className='text-end'>
              {editMode ? (
                <>
                  <div className='my-5 '>
                    <InputNotRequired
                      required={true}
                      register={register}
                      errors={errors}
                      value={noteData?.name}
                      setValue={setValue}
                      trigger={trigger}
                      onBlur={() => {
                        updateNoteData(noteData)
                      }}
                      onChange={(val: any) => {
                        setNoteData({...noteData, ['name']: val})
                      }} // Pass the callback function to handle input changes
                      // placeholder='Enter Document Name'
                      placeholder={`Enter ${from == 'credit note' ? 'Credit Note Name' : 'Debit Note Name'}`}
                      name='documentName'
                      pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                      maxLength={30} // Maximum length of 50 characters
                      className='text-end document-name-input-field'
                    />
                  </div>
                  {/* <h3 className='text-danger mb-0 ms-2 my-5'>
                  <b>*</b>
                </h3> */}
                </>
              ) : (
                <p className='ms-auto pt-1 document-name-input-field my-5 fnt-500 white-dark-theme-color'>
                  {`${noteData?.name || ''}`}
                </p>
              )}
            </div>

            {/* Credit Note No. */}
            <div className='d-flex justify-content-start my-2 position-relative'>
              <label
                className='mb-1 ms-3 pt-2 fnt-500  status-w-100 text-start light-dark-theme-color'
                style={{
                  fontSize: '1rem',
                }}
              >
                {from == 'credit note' ? 'Credit ' : 'Debit'} Note No.
              </label>
              {editMode ? (
                <>
                  {noteSettingsDetails?.creditNoteSequence?.activityStatus == 1 ? (
                    <>
                      {from === 'credit note' ? <p className='pt-2 mb-1 ms-auto  white-dark-theme-color'>
                        <b>{noteData?.creditNoteNo ? noteData?.creditNoteNo : '-'}</b>
                      </p> : <p className='pt-2 mb-1 ms-auto  white-dark-theme-color'>
                        <b>{noteData?.debitNoteNo ? noteData?.debitNoteNo : '-'}</b>
                      </p>}
                    </>
                  ) : (
                    <div className='ms-auto receipt-date'>
                      <InputNotRequired
                        required={true}
                        register={register}
                        errors={errors}
                        value={from === 'credit note' ? noteData?.creditNoteNo : noteData?.debitNoteNo}
                        setValue={setValue}
                        trigger={trigger}
                        onBlur={() => {
                          updateNoteData(noteData)
                        }}
                        onChange={(val: any) => {
                          setNoteData({...noteData, [from === 'credit note' ? 'creditNoteNo' : 'debitNoteNo']: val})
                        }} // Pass the callback function to handle input changes
                        placeholder={`Enter ${from == 'credit note' ? 'Credit' : 'Debit'} Note No.`}
                        name='documentReceiptNo'
                        pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                        maxLength={15} // Maximum length of 50 characters
                        style={{height: '35px'}}
                        className='your-custom-class receipt-date text-start'
                      />
                    </div>
                  )}
                  {noteSettingsDetails?.creditNoteSequence?.activityStatus == 0 && (
                    <h3 className='text-danger mb-0 ms-2 pt-2 position-absolute' style={{top:0,right:'-10px'}}>
                      <b>*</b>
                    </h3>
                  )}
                </>
              ) : (
                <>
                {from === 'credit note' ? <label className='ms-auto pt-1 white-dark-theme-color'>
                  <b>{`${noteData?.creditNoteNo || ''}`}</b>
                </label> : <label className='ms-auto pt-1 white-dark-theme-color'>
                  <b>{`${noteData?.debitNoteNo || ''}`}</b>
                </label>}
                </>
              )}
            </div>

            {/* Issued Date */}
            <div className='d-flex align-items-start justify-content-start my-2'>
              <label
                className='mb-1 ms-3 pt-2 fnt-500  status-w-100 text-start light-dark-theme-color'
                style={{
                  fontSize: '1rem',
                }}
              >
                Issued Date
              </label>

              <label className='ms-auto pt-2'>
                {editMode ? (
                  <b className='white-dark-theme-color'>-</b>
                ) : (
                  <b className='white-dark-theme-color'>
                    {noteData?.createdAt ? moment(noteData?.createdAt).format('DD.MM.YYYY') : '-'}
                  </b>
                )}
              </label>
            </div>
            {/* Due Date */}
            {from === 'debit note' && <>
              <div>
              <div className='d-flex justify-content-start my-2 position-relative'>
              <label
                className='mb-1 ms-3 pt-2 fnt-500  status-w-100 text-start light-dark-theme-color'
                style={{
                  fontSize: '1rem',
                }}
              >
                Due Date
              </label>
                {editMode == true ? (
                      <div className='ms-auto' style={{fontWeight: '500'}}>
                        <div className='d-flex'>
                          <div>
                            <div>
                              <Controller
                                name='dueDate'
                                control={control}
                                rules={{
                                  required: 'this is required',
                                }}
                                render={({field}) => (
                                  <div className={`expense-date tst tst-picker`}>
                                    {noteData?.dueDate != null ? (
                                      <DatePicker
                                      className={`${noteData?.dueDate ? 'isFilled' : ''}`}
                                      key={pickerKey}
                                        name='dueDate'
                                        value={moment(
                                          new Date(noteData?.dueDate),
                                          'DD.MM.YYYY'
                                        )}
                                        format={'DD.MM.YYYY'}
                                        disabledDate={disabledDate}
                                        onChange={handleDateChange}
                                        placeholder='Select Due Date'
                                      />
                                    ) : (
                                      <DatePicker
                                      className={`${noteData?.dueDate ? 'isFilled' : ''}`}
                                        key={pickerKey}
                                        name='dueDate'
                                        disabledDate={disabledDate}
                                        onChange={handleDateChange}
                                        placeholder='Select Due Date'
                                      />
                                    )}
                                  </div>
                                )}
                              />
                              
                            </div>
                          </div>
                          <h3 className='text-danger mb-0 ms-2 pt-2 position-absolute' style={{top:0,right:'-10px'}}>
                            <b>*</b>
                        </h3>
                        </div>
                        {editMode == true ? (
                    <div style={{marginTop:'-15px'}}>
                      <label
                        className=''
                        style={{
                          minWidth: '70px',
                          fontSize: '1rem',
                          color: '#516176',
                          fontWeight: '400',
                        }}
                      ></label>
                      {errors.dueDate && (
                        <p
                          style={{color: 'var(--kt-danger)', gridColumn: '2/2',fontWeight:'normal'}}
                          className='m-0'
                        >
                          This field is required
                        </p>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                      </div>
                    ) : (
                      <p className='pt-2 mb-0 ms-auto white-dark-theme-color'>
                           <b>
                        {moment(noteData?.dueDate)?.format('DD.MM.YYYY')}
                      </b>
                      </p>
                     
                    )}
                  </div> 
              </div>
               
            </>}
          </Col>
        </Row>

        <Row className='pt-10'>
          <Col xs={6} sm={6} md={9} lg={9}>
            <div className='w-75'>
              <div className='d-flex align-items-center'>
                <p className='mb-4 fs-4 fnt-600 white-dark-theme-color'>Recipient</p>
              </div>

              <p style={{marginBottom: '0.1rem'}} className='fnt-500 white-dark-theme-color'>
                {/* {noteData?.tenant?.firstName} {noteData?.tenant?.lastName} */}
                {noteData?.payorData?.firstName &&
                noteData?.payorData?.lastName
                  ? `${noteData?.payorData?.firstName} ${noteData?.payorData?.lastName}`
                  : '-'}
              </p>
              <p style={{marginBottom: '0.1rem'}} className='fnt-500 white-dark-theme-color'>
                {noteData?.payorData?.countryCode &&
                noteData?.payorData?.phoneNumber
                  ? `+${noteData?.payorData?.countryCode} ${noteData?.payorData?.phoneNumber}`
                  : '-'}
              </p>
              <p style={{marginBottom: '0.1rem'}} className='fnt-500 white-dark-theme-color'>
                {noteData?.payorData?.email
                  ? noteData?.payorData?.email
                  : '-'}
              </p>
            </div>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} className='pe-4'>
            <div className='text-end'>
              <p style={{marginBottom: '0.1rem'}} className='fs-4 fnt-600 mb-4 white-dark-theme-color'>
                {' '}
                {noteCompany?.name != null && noteCompany?.name}
              </p>
              <p style={{marginBottom: '0.1rem'}} className='fs-7 fnt-500  white-dark-theme-color'>
                {' '}
                {noteCompany?.address != null && noteCompany?.address}
              </p>

              <p style={{marginBottom: '0.1rem'}} className='fs-7 fnt-500  white-dark-theme-color'>
                {noteCompany?.phoneNumber != null &&
                  noteCompany?.countryCode + noteCompany?.phoneNumber}
              </p>
              <p style={{marginBottom: '0.1rem'}} className='fs-7 fnt-500  white-dark-theme-color'>
                {' '}
                {noteCompany?.email != null && noteCompany?.email}
              </p>
            </div>
          </Col>
        </Row>

        {/* Separator */}
        <Row className='mt-7 separator mx-0'></Row>

        {/* Items Details */}
        <div className={`${isPrint ? '' : 'table-responsive'} mt-4 mb-10`}>
          <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
            <thead>
              <th className='text-start min-w-25px ps-3'>#</th>
              <th className={`text-start ${isPrint ? 'min-w-175px' : 'min-w-200px'} ps-3`}>DESCRIPTION</th>
              <th className='text-start min-w-100px ps-3'>INVOICE</th>
              <th className='text-start min-w-100px ps-3'>RATE</th>
              <th className='text-start min-w-100px ps-3'>QTY</th>
              <th className='text-start min-w-100px ps-3'>VAT</th>
              <th className='text-start min-w-100px ps-3'>{from === 'credit note' ? 'CREDIT AMOUNT' : 'DEBIT AMOUNT'}</th>
            </thead>
            <tbody className='fw-semibold'>
           
              {sortedGroupsWithItems.map((items: any, groupIndex: number) => 
                  (<React.Fragment key={groupIndex}>
                   {items?.map((item: any, index: any) => { 
                    noteData.payment_sub_item[trackIndex] = item
                    trackIndex += 1
                  return (
                  <>
                    <tr key={`${groupIndex}-${index}`} className='white-dark-theme-color'>
                      <td className='ps-3'>
                        {' '}
                        <p className='' style={{fontWeight: '700'}}>
                          {trackIndex < 9 ? `0${trackIndex}` : trackIndex}
                          {/* {index < 9 ? `0${index + 1}` : index + 1} */}
                        </p>
                      </td>
                      <td className='ps-3'>{item?.name ? item?.name : '-'}</td>
                      <td className='ps-3'>{item?.invoiceNo ? item?.invoiceNo : '-'}</td>
                      {from === 'credit note' ? <td className=' ps-3'>{formatAmount(item?.addedCredit?.creditRateAmount)}</td> : <td className=' ps-3'>{formatAmount(item?.addedDebit?.debitRateAmount)}</td>}
                      {from === 'credit note' ? <td className=' ps-3'>{formatAmount(item?.addedCredit?.quantity)}</td> : <td className=' ps-3'>{formatAmount(item?.addedDebit?.quantity)}</td>}
                      {from === 'credit note' ? <td className=' ps-3'>
                        {item?.frontendStorage?.isLatePaymentItem ? (
                          <>{formatAmount((Number(item?.addedCredit?.creditRateAmount) / 100) * Number(item?.addedCredit?.creditVATAmount))}</>
                        ) : (
                          <>{formatAmount(((Number(item?.addedCredit?.creditRateAmount) * Number(item?.addedCredit?.quantity)) / 100) * Number(item?.addedCredit?.creditVATAmount))}</>
                        )}
                      </td> : <td className=' ps-3'>
                        {item?.frontendStorage?.isLatePaymentItem ? (
                          <>{formatAmount((Number(item?.addedDebit?.debitRateAmount) / 100) * Number(item?.addedDebit?.debitVATAmount))}</>
                        ) : (
                          <>{formatAmount(((Number(item?.addedDebit?.debitRateAmount) * Number(item?.addedDebit?.quantity)) / 100) * Number(item?.addedDebit?.debitVATAmount))}</>
                        )}
                      </td>}
                      {from === 'credit note' ? <td className='ps-3'>{formatAmount(item?.addedCredit?.totalCreditAmount)}</td> 
                      : <td className='ps-3'>{formatAmount(item?.addedDebit?.totalDebitAmount)}</td>}
                    </tr>
                  </>
                )
              })}
                 </React.Fragment>)
              )}
              <>
                {noteData?.payment_sub_item?.map((f: any, ind: any) => {
                  vatAmount = 0
                  nonVat = 0
                  return <></>
                })}
              </>
              {noteData?.payment_sub_item?.length > 0 && (
                <>
                  {noteData?.payment_sub_item?.map((f: any, ind: any) => {
                   if(from === 'credit note'){
                    if (f?.frontendStorage?.isLatePaymentItem) {
                      vatAmount = vatAmount + (Number(f?.addedCredit?.creditRateAmount) / 100) * Number(f?.addedCredit?.creditVATAmount)
                      nonVat = nonVat + f.amount
                    } else {
                      vatAmount = vatAmount + ((Number(f?.addedCredit?.creditRateAmount) * Number(f?.addedCredit?.quantity)) / 100) * Number(f?.addedCredit?.creditVATAmount)
                      nonVat = nonVat + Number(f?.addedCredit?.creditRateAmount) * Number(f?.addedCredit?.quantity)
                    }
                   }else{
                    if (f?.frontendStorage?.isLatePaymentItem) {
                      vatAmount = vatAmount + (Number(f?.addedDebit?.debitRateAmount) / 100) * Number(f?.addedDebit?.debitVATAmount)
                      nonVat = nonVat + f.amount
                    } else {
                      vatAmount = vatAmount + ((Number(f?.addedDebit?.debitRateAmount) * Number(f?.addedDebit?.quantity)) / 100) * Number(f?.addedDebit?.debitVATAmount)
                      nonVat = nonVat + Number(f?.addedDebit?.debitRateAmount) * Number(f?.addedDebit?.quantity)
                    }
                   }
                    return <></>
                  })}
                </>
              )}


              <tr className='mt-5' style={{height: '50px',verticalAlign:'middle', borderTop:'2px solid lightgrey'}}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5 fs-4' style={{color: '#B5B5C3'}}>
                  SUB TOTAL
                </td>
                <td className='py-2 fs-4 white-dark-theme-color'>{formatAmount(nonVat)}</td>
              </tr>

              <tr style={{height: '50px',verticalAlign:'middle'}}>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5 fs-4' style={{color: '#B5B5C3'}}>
                  VAT
                </td>
                <td className='py-2 fs-4 white-dark-theme-color'>{formatAmount(vatAmount)}</td>
              </tr>
              <tr style={{height: '50px',verticalAlign:'middle'}} className='pb-4'>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className='py-2 ps-5 fs-3 white-dark-theme-color'>{from === 'credit note' ? 'Total Credit' : 'Total Debit'}</td>
                <td className='py-2 fs-3 white-dark-theme-color' style={{fontWeight: '800px'}}>
                  {' '}
                  <b>AED {formatAmount(nonVat + vatAmount)}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Separator */}
        <Row className='mt-7 separator mx-0 mb-15'></Row>

        {/* Notes */}
        {editMode ? (
          <Row>
            {noteData?.showNotes ? (
              <Col sm={12} md={12} style={{display: 'flex', flexDirection: 'column'}}>
                <div className={'card card-flush py-5 mt-2  pe-5 mb-5 px-6 h-100'}>
                  <div className='mb-5 h-10'>
                    <div className='d-flex align-items-start mb-4'>
                      <h2 className='me-1 white-dark-theme-color' style={{minWidth: '160px', fontWeight: '700'}}>
                        Notes
                      </h2>
                      {editMode ? (
                        <img
                          src={redCrossFilled}
                          height={18}
                          width={18}
                          className='cursor-pointer ms-auto'
                          onClick={() => {
                            // setShowNotes(false)

                            let values = {...noteData}
                            values.showNotes = false
                            updateNoteData(values)
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>

                    <textarea
                      className='form-control form-control-solid-bg input-style mytest bg-white'
                      name='comment'
                      placeholder='Enter the reasons for issuing or any additional notes to the recipient'
                      style={{height: '150px', borderColor: 'lightgray'}}
                      value={noteData?.note}
                      onChange={(e) => {
                        const values = {...noteData}
                        values.note = e.target.value
                        updateNote(values)
                      }}
                      maxLength={1000}
                      onBlur={() => {
                        updateNoteData(noteData)
                      }}
                    ></textarea>
                  </div>
                </div>
              </Col>
            ) : (
              <Col sm={6} md={6} style={{display: 'flex', flexDirection: 'column'}} className='mx-auto'>
                <div
                  className='custom-section'
                  onClick={() => {
                    // setShowNotes(true)

                    let values = {...noteData}
                    values.showNotes = true
                    updateNoteData(values)

                    if (flg == '2') {
                    }
                  }}
                >
                  <div className='content'>
                    <img src={addIcon} height={18} width={18} className='me-4' />
                    <h4 className='my-2 mb-3'>
                      <label className={`text-gray-400 fw-bold fs-4`}>
                        {' '}
                        Add Recipient Notes Section
                      </label>
                    </h4>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        ) : (
          <Row>
            {noteData?.note && noteData?.showNotes && !isPrint ? (
              <Col sm={12} md={12} style={{display: 'flex', flexDirection: 'column'}}>
                <div className={'py-5  mt-2  pe-5 mb-3 px-6'}>
                  <div className=''>
                    <div className='d-flex align-items-start mb-4'>
                      <h2 className='me-1 white-dark-theme-color' style={{minWidth: '160px', fontWeight: '700'}}>
                        Notes
                      </h2>
                    </div>

                    <p className=' mb-0 ms-auto light-dark-theme-color'>
                      {noteData?.note && noteData?.showNotes ? noteData?.note : '-'}
                    </p>
                  </div>
                </div>
              </Col>
            ) : noteData?.note && noteData?.showNotes && isPrint ? (
              <Col sm={12} md={12} style={{display: 'flex', flexDirection: 'column'}}>
                <div className={'py-5  mt-2  pe-5 mb-3 px-6'}>
                  <div className=' '>
                    <div className='d-flex align-items-start mb-4'>
                      <h2 className='me-1' style={{minWidth: '160px', fontWeight: '700'}}>
                        Notes
                      </h2>
                    </div>

                    <p className=' mb-0 ms-auto'>
                      {noteData?.note && noteData?.showNotes ? noteData?.note : '-'}
                    </p>
                  </div>
                </div>
              </Col>
            ) : !noteData?.note && noteData?.showNotes && !isPrint ? (
              <Col sm={12} md={12} style={{display: 'flex', flexDirection: 'column'}}>
                <div className={'py-5  mt-2  pe-5 mb-3 px-6'}>
                  <div className=' '>
                    <div className='d-flex align-items-start mb-4'>
                      <h2 className='me-1 white-dark-theme-color' style={{minWidth: '160px', fontWeight: '700'}}>
                        Notes
                      </h2>
                    </div>

                    <p className=' mb-0 ms-auto light-dark-theme-color'>
                      {noteData?.note && noteData?.showNotes ? noteData?.note : '-'}
                    </p>
                  </div>
                </div>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        )}

        {/* Other Sections */}
        {noteData?.itemDescriptions?.map((pr: any, ind: any) => {
          return (
            <div className={`${pr.animationClass} px-6 ${editMode ? '' : 'po-editor-view pb-7'}`}>
              <div className={`d-flex mb-2 mt-7 align-items-center `}>
                <h3 className='m-0 head-text text-capitalize'>
                  {editMode ? (
                    <input
                      type='text'
                      className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                      name='mainService'
                      value={pr?.title ? pr?.title : ''}
                      style={{height: '35px', width: '300px'}}
                      placeholder={'Add Section Title'}
                      onChange={(e: any) => {
                        const value = e.target.value
                        let values = {...noteData}
                        let valuesSections = [...noteData?.itemDescriptions]
                        valuesSections[ind].title = capitalizeFirstLetter(value)
                        values.itemDescriptions = valuesSections
                        // updateNoteData(values)
                        updateNote(values)
                      }}
                      onBlur={() => {
                        updateNoteData(noteData)
                      }}
                      autoComplete='off'
                    />
                  ) : (
                    <p className='pt-2 mb-0 ms-auto'>
                      <h2 className='top ps-0'>
                        <b>{pr?.title ? pr?.title : '-'}</b>
                      </h2>
                    </p>
                  )}
                </h3>

                <div className='d-flex ms-auto align-items-center'>
                  {noteData?.itemDescriptions?.length > 1 && (
                    <>
                      {ind != noteData?.itemDescriptions?.length - 1 && editMode && (
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                              {`Move this section one step down`}
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <img
                              src={movedownImg}
                              height={20}
                              width={20}
                              className='cursor-pointer'
                              onClick={() => {
                                swapItems(ind, ind + 1)
                              }}
                            />
                          </span>
                        </OverlayTrigger>
                      )}

                      {ind != 0 && editMode && (
                        <OverlayTrigger
                          placement='top'
                          delay={{show: 250, hide: 400}}
                          overlay={
                            <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                              {`Move this section one step up`}
                            </Tooltip>
                          }
                        >
                          <span>
                            {' '}
                            <img
                              src={moveUpImg}
                              height={20}
                              width={20}
                              className='ms-5 cursor-pointer'
                              onClick={() => {
                                swapItems(ind - 1, ind)
                              }}
                            />
                          </span>
                        </OverlayTrigger>
                      )}
                    </>
                  )}

                  {editMode ? (
                    <img
                      src={redCrossFilled}
                      height={18}
                      width={18}
                      className='ms-5 cursor-pointer'
                      onClick={() => {
                        handleRemoveSections(ind)
                      }}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <TextEditor
                value={pr?.html}
                onChange={(val: any) => {
                  let values = {...noteData}
                  let valuesSections = [...noteData?.itemDescriptions]
                  valuesSections[ind].html = val
                  values.itemDescriptions = valuesSections
                  // updateNoteData(values)
                  updateNote(values)
                }}

                autoSaveText={() => {
                  updateNoteData(noteData)
                }}
                refresh={noteData}
                placeholder={'Add section details here...'}
                isReadOnly={editMode ? false : true}
                onCursorEnter={()=>{}}
              />
            </div>
          )
        })}

        {editMode ? (
          <div className='d-flex justif-content-center mt-5 mb-10'>
            <button
              disabled={
                noteData?.itemDescriptions?.length > 0 &&
                noteData?.itemDescriptions?.some((itm: any) => {
                  const tempElement = document.createElement('div')
                  tempElement.innerHTML = itm?.html
                  return (
                    !tempElement.textContent?.trim() ||
                    !tempElement.innerText?.trim() ||
                    !itm?.title?.trim()
                  )
                })
              }
              type='button'
              className='btn btn-sm fw-bold select-btn btn-fit-content ps-2 mx-auto pe-5 mb-5'
              onClick={() => {
                handleAddNewSections()
              }}
            >
              <img src={addWhite} height={18} width={18} className='me-5' /> Add Section
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      </div>
    </div>
  )
}

export default NoteDetailsCard
