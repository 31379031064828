import React from 'react';

interface FixedFeeProps { 
}

const FixedFee: React.FC<FixedFeeProps> = ({ }) => {
    return (
        <div>
            
        </div>
    );
};

export default FixedFee;
