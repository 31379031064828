import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import apiEndPoints from '../end-points/apiEndPoints'

//
export const getLatePaymentFeeList = async (body: any) => {
  try {
    const response: any = await ApiPost(apiEndPoints?.latePaymentFeeListEndPoint, body)
    console.log(response)
    return {result: response?.data?.data, error: null}
  } catch (error: any) {
    return {result: null, error: error.message || 'API call failed'}
  }
}
