import React, {useEffect, useState, useRef} from 'react'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import 'antd/dist/antd.css'
import moment from 'moment'
import backArrow from '../../../../img/back-arrow.png'
import plus from '../../../../img/add-white.png'
import open from '../../../../img/payment-col-open.png'
import close from '../../../../img/payment-col-close.png'
import {ApiPost, Bucket, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import IncomingPaymentModal from './PaymentIncomingModal'
import EditPaymentModal from '../../Financials/EditPaymentModal'
import Select from 'react-select'
import {DatePicker, Space} from 'antd'
import closes from '../../../../img/close.svg'
import window from '../../../../img/window.png'
import ReactPaginate from 'react-paginate'
import {CarTwoTone} from '@ant-design/icons'
import {ErrorToast} from '../../../../apiCommon/helpers/Toast'
import noData from '../../../../img/NoData1.svg'
import {AnyIfEmpty} from 'react-redux'
import {useDispatch, useSelector} from 'react-redux'
import lock from '../../../../img/padlock.png'

const {RangePicker} = DatePicker

const FinancialIncome = () => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const {unitId} = useParams()

  let temp = localStorage.getItem('tenancyContract')

  const [tenancyContractNo, setTenancyContractNo] = useState(temp)

  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)

  const [incomeModal, setIncomeModal] = useState<any>(false)
  const [editIncomeModal, setEditIncomeModal] = useState<any>(false)

  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)

  const [incomingPaymentId, setIncomingPaymentId] = useState<any>()
  const [paymentId, setPaymentId] = useState<any>()
  const [subItemId, setSubItemId] = useState<any>()
  const [itemId, setItemId] = useState<any>()
  const [payments, setPayments] = useState<any>()
  const [show, setShow] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)

  const [isSearchable, setIsSearchable] = useState<any>(true)

  const [idTenancy, setIdTenancy] = useState<any>(null)

  const [items, setItems] = useState<any>([
    {value: '0', label: 'Booking'},
    {value: '1', label: 'Maintenance'},
    {value: '2', label: 'Rent'},
    {value: '3', label: 'Item 01'},
    {value: '4', label: 'Item 01'},
  ])
  const [pStatus, setPStatus] = useState<any>()
  var paymentStatus: any = 0
  const [paymentCat, setPaymentCat] = useState<any>()
  const [dueStartDate, setDueStartDate] = useState<any>()
  const [dueEndDate, setDueEndDate] = useState<any>()
  const [paidStartDate, setPaidStartDate] = useState<any>()
  const [paidEndDate, setPaidEndDate] = useState<any>()
  const [tenancyCont, setTenancyCont] = useState<any>()
  const [paymentMethods, setPaymentMethods] = useState<any>([
    {label: 'Card', value: 0},
    {label: 'Cheque', value: 1},
    {label: 'Bank', value: 2},
    {label: 'Cash', value: 3},
  ])
  const [paymentMethod, setPaymentMethod] = useState<any>()
  const [invoice, setInvoice] = useState<any>()
  const [invoiceList, setInvoiceList] = useState<any>([
    {label: 'Inv # 1', value: 0},
    {label: 'Inv # 2', value: 1},
    {label: 'Inv # 2', value: 2},
    {label: 'Inv # 4', value: 3},
  ])
  const [outstandAmountFrom, setOutstandAmountFrom] = useState<any>()
  const [outstandAmountTo, setOutstandAmountTo] = useState<any>()
  const [tenancy, setTenancy] = useState<any>()

  const [cat, setCat] = useState<any>()
  var checked: any = 0

  const [unitDetails, setUnitDetails] = useState<any>()
  var ud: any

  const [tenancyId, setTenancyId] = useState<any>([])
  const [paymentInvId, setPaymentInvId] = useState<any>([])
  const [paymentIdd, setPaymentIdd] = useState<any>([])

  const [dataToEdit, setDataToEdit] = useState<any>()

  const tableRef = useRef<HTMLTableElement | null>(null)

  var dueSDate: any
  var dueEDate: any
  var paidSDate: any
  var paidEDate: any

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '50%',
      marginLeft: '27px',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#000;',
      backgroundColor: state.isSelected ? '#007a59;' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',

      zIndex: '1',
    }),
  }

  //
  const handleDueStartDateChange = (date: any) => {
    setDueStartDate(date.format('YYYY-MM-DD'))
    dueSDate = date.format('YYYY-MM-DD')
    applyFilters(dueSDate, 31)
  }

  const handleDueEndDateChange = (date: any) => {
    setDueEndDate(date.format('YYYY-MM-DD'))
    dueEDate = date.format('YYYY-MM-DD')
    applyFilters(dueEDate, 32)
  }

  const handlePaidStartDateChange = (date: any) => {
    paidSDate = date.format('YYYY-MM-DD')
    setPaidStartDate(date.format('YYYY-MM-DD'))
    applyFilters(paidSDate, 41)
  }

  const handlePaidEndDateChange = (date: any) => {
    paidEDate = date.format('YYYY-MM-DD')
    setPaidEndDate(date.format('YYYY-MM-DD'))
    applyFilters(paidEDate, 42)
  }

  // to get paymentID
  const addIncomingPaymentFunc = () => {
    ApiPost(`corporate/payment`)
      .then((res) => {
        setIncomingPaymentId(res?.data?.data._id)
        setPaymentId(res?.data?.data.id)
      })
      .catch((err) => console.log('err', err))
  }

  // to get subItem ID
  const addSubItemFunc = () => {
    ApiPost(`corporate/payment_sub_item`)
      .then((res) => {
        setSubItemId(res?.data?.data._id)
        setItemId(res?.data?.data.id)
        // console.log(res?.data?.data.id)
        setIncomeModal(true)
      })
      .catch((err) => console.log('err', err))
  }

  // getting All Payments of specific unit
  const getAllPayments = () => {

    let body: any
    if(state?.tenancyId != undefined){
      body = {
        limit: 10,
        page: page,
        unitId: unitId,
        tenancyId: state?.tenancyId
      }
    }
    else{
      body = {
        limit: 10,
        page: page,
        unitId: unitId,
      }
    }
   
    ApiPost(`corporate/payment/get`, body)
      .then((res) => {
        setPayments(res?.data?.data?.payment_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
        for (let i = 0; i < res?.data?.data?.payment_data.length; i++) {
          let values = [...show]
          values[i] = false
          setShow(values)
        }
      })
      .catch((err) => console.log('err', err))
  }

  const getPaymentItemsList = () => {
    ApiGet(`corporate/payment_item_list/unit/${unitId}`)
      .then((res: any) => {
        setItems(res?.data?.data)
        // console.log(res?.data?.data?.payment_item_list_data)
      })
      .catch((err: any) => console.log('err', err))

    // if (ud?.buildingId) {
    //   ApiGet(`corporate/payment_item_list/payment?buildingId=` + ud?.buildingId)
    //     .then((res: any) => {
    //       setItems(res?.data?.data)
    //       // console.log(res?.data?.data?.payment_item_list_data)
    //     })
    //     .catch((err: any) => console.log('err', err))
    // } else if (ud?.communityId) {
    //   ApiGet(`corporate/payment_item_list/payment?communityId=` + ud?.communityId)
    //     .then((res: any) => {
    //       setItems(res?.data?.data)
    //       // console.log(res?.data?.data?.payment_item_list_data)
    //     })
    //     .catch((err: any) => console.log('err', err))
    // }
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  // get unit details
  const getUnitDetails = () => {
    ApiGet(`corporate/unit/${unitId}`)
      .then((res) => {
        setUnitDetails(res?.data?.data)
        ud = res?.data?.data
        getPaymentItemsList()
        setTenancy(res?.data?.data?.tenancy)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const getInvoiceNoList = () => {
    const body = {
      unitId: state.details.unitId,
    }
    ApiPost(`corporate/payment/get_invoice_no`, body)
      .then((res) => {
        setInvoiceList(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const getTenancyContract = () => {
    const body = {
      unitId: unitId,
    }
    ApiPost(`corporate/tenancy/get_contract_no`, body)
      .then((res) => {
        setTenancyCont(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  const applyFilters = (val: any, typ: any) => {
    let pS: any
    if (typ == 1) {
      pS = val
    }

    let pC: any
    if (typ == 2) {
      pC = val
    }

    let dueS: any
    if (typ == 31) dueS = val

    let dueE: any
    if (typ == 32) dueE = val

    let paidS: any
    if (typ == 41) paidS = val

    let paidE: any
    if (typ == 42) paidE = val

    let pM: any
    if (typ == 5) pM = val

    let outF: any
    if (typ == 61) outF = val

    let outT: any
    if (typ == 62) outT = val

    let tId: any
    if (typ == 7) tId = val

    let invId: any
    if (typ == 8) invId = val

    let itmId: any
    if (typ == 9) itmId = val

    let newData: any = []
    if (state.details?.details?.communityId)
      newData[newData.length] = state.details?.details?.communityId

    let newData2: any = []
    if (state.details?.details?.buildingId)
      newData2[newData2.length] = state.details?.details?.buildingId

    const body = {
      limit: 10,
      page: page,
      dueStartDate: typ == 31 ? dueS : dueStartDate,
      dueEndDate: typ == 32 ? dueE : dueEndDate,
      datePaidStartDate: typ == 41 ? paidS : paidStartDate,
      datePaidEndDate: typ == 42 ? paidE : paidEndDate,
      paymentStatus: typ == 1 ? pS : pStatus,
      categories: typ == 2 ? pC : cat,
      communityIds: newData,
      buildingId: newData2,
      outStandingAmountFrom: typ == 61 ? outF : outstandAmountFrom,
      outStandingAmountUpto: typ == 62 ? outT : outstandAmountTo,
      paymentMethod: typ == 5 ? pM : paymentMethod,
      unitId: unitId,
      tenancyIds: typ == 7 ? [`${tId}`] : tenancyId,
      paymentIds: typ == 8 ? [`${invId}`] : paymentInvId,
      paymentItemListIds: typ == 9 ? [`${itmId}`] : paymentIdd,
    }
    ApiPost(`corporate/payment/get`, body)
      .then((res) => {
        setPayments(res?.data?.data?.payment_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
        for (let i = 0; i < res?.data?.data?.payment_data.length; i++) {
          let values = [...show]
          values[i] = false
          setShow(values)
        }
      })
      .catch((err) => console.log('err', err))
  }

  const getPaymentById = async (id: any) => {
    ApiGet(`corporate/payment/${id}`)
      .then((res) => {
        setDataToEdit(res?.data?.data)
        setEditIncomeModal(true)
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {

    setTimeout(()=>{
      getUnitDetails()
      getTenancyContract()
      getInvoiceNoList()
      enableScrolling()
    }, 1500)

    console.log(state?.details?.details)
   
  }, [])

  useEffect(() => {
    setIdTenancy(state?.tenancyId)
    getAllPayments()
  }, [page])

  const handleMouseWheel = (e: React.WheelEvent) => {
    if (tableRef.current) {
      if (isMouseOverTable(e, tableRef.current)) {
        e.preventDefault()
        tableRef.current.scrollLeft += e.deltaY
      }
    }
  }

  const isMouseOverTable = (e: React.WheelEvent, element: HTMLElement) => {
    // document.body.style.overflow = 'hidden';
    const rect = element.getBoundingClientRect()
    const mouseX = e.clientX - rect.left
    const mouseY = e.clientY - rect.top
    return mouseX >= 0 && mouseX <= rect.width && mouseY >= 0 && mouseY <= rect.height
  }

  const disableScrolling = () => {
    document.body.style.overflow = 'hidden'
  }

  const enableScrolling = () => {
    document.body.style.overflow = '' // Reset to default
  }

  const handleMouseEnter = () => {
    disableScrolling()
  }

  const handleMouseLeave = () => {
    enableScrolling()
  }

  const getPositive = (number: any) => {
    // if number is less than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  return (
    <>
      <div id='' className='pt-0 mt-0 no-print px-2'  style={{padding: '0px 0px 0px 14px'}}>
        <div className='d-flex align-items-start gap-2 mb-0 gap-lg-3 '>
          <div className='d-flex align-items-center gap-5 mt-2'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => {
                if(state?.tenancyId != undefined)
                navigate('/tenancies')
                else{
                  if(comefrom == 'properties')
                  navigate('/dashboard')
                  else if(comefrom == 'build')
                  navigate('/building-dashboard')
                  else 
                  navigate('/community-dashboard')
                }
              }
            }
            >
              <img src={backArrow} style={{stroke: 'red'}} height='19px' width='19px' />
            </span>
            {state?.tenancyId != undefined ? (
              <h1 className='page-heading m-0 head-text'>
                Property Profile - {tenancyContractNo}
              </h1>
            ) : (
              <h1 className='page-heading m-0 head-text'>Property Profile</h1>
            )}
           
          </div>
           {/* Propert Profile Tabs */}
        <div
          className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center mx-5 ps-5'
          style={{marginBottom: '28px'}}
        >
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li className='nav-item'>
              <a className='nav-link text-active-primary pb-2 me-5 px-5' data-bs-toggle='tab'
              onClick={() =>
                navigate(`/overViewData/${unitId}`, {
                  state: {
                    details : state.details,
                    id: state.id,
                    tenancyId: state?.tenancyId
                  }
                })}>
                Overview
              </a>
            </li>
            { state?.details?.details?.unitType != 'common_area' && (
            <li className='nav-item'>
              <a className='nav-link text-active-primary pb-2 mx-5 px-5' data-bs-toggle='tab'
               onClick={()=>navigate(`/overViewData/${unitId}/tenancy`,
               {
                 state: {
                   details : state.details,
                   id: state.id,
                   tenancyId: state?.tenancyId
                 }
               })}>
                Tenancy
              </a>
            </li>
            )}
             { state?.details?.details?.unitType != 'common_area' && (
            <li className='nav-item'>
              <a className='nav-link text-active-primary pb-2 mx-5 px-5' data-bs-toggle='tab'
               onClick={() =>
                navigate(`/overViewData/${unitId}/tenant`, {
                  state: {
                    details: state.details,
                    id: state.id,
                    tenancyId: state?.tenancyId
                  },
                })
              }>
                Tenants
              </a>
            </li>
             )}
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 mx-5 active px-5'
                data-bs-toggle='tab'
                onClick={()=>navigate(`/overViewData/${unitId}/financials/income`,
               {
                 state: {
                   details : state.detail,
                   id: state.id,
                   tenancyId: state?.tenancyId
                 }
               })}
              >
                Financials
              </a>
            </li>
            { state?.details?.details?.unitType != 'common_area' && (
            <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/annoucements`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Annoucements
                </a>
              </li>
            )}
              <li className='nav-item'>
                <a className='nav-link text-active-primary pb-2 mx-5 px-5' data-bs-toggle='tab'
                onClick={() =>
                    navigate(`/overViewData/${unitId}/requests`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }>
                  Requests
                </a>
              </li>
          </ul>
        </div>
        </div>

        {/* Financials Tabs */}
        <div className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center mb-5 pb-3'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 active me-5 px-5'
                data-bs-toggle='tab'
              >
                Income
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link text-active-primary pb-2 mx-5 px-5' data-bs-toggle='tab'>
                Expenses
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link text-active-primary pb-2 mx-5 px-5' data-bs-toggle='tab'>
                Statement Reports
              </a>
            </li>

            <li className='nav-item'>
              <a className='nav-link text-active-primary pb-2 mx-5 px-5' data-bs-toggle='tab'>
                Payment Reminders
              </a>
            </li>
          </ul>
        </div>

        {/* Income */}
        <div className='d-flex align-items-center gap-2 my-8 gap-lg-3'>
          <div className='d-flex align-items-center '>
            <input
              type='text'
              className='form-control form-control-settings'
              placeholder='Search invoice #'
              name='item_id'
            />
            <button
              className='btn btn-sm fw-bold btn-primary btn-green ms-3 fs-4 d-flex'
              style={{width: '130px', height: '37px'}}
              onClick={() => setShowFilter(true)}
            >
              <svg
                style={{
                  height: '18px',
                  width: '18px',
                  marginRight: '7px',
                  marginLeft: '-10px',
                  marginTop: '1px',
                }}
                viewBox='0 0 1024 1024'
                xmlns='http://www.w3.org/2000/svg'
                className='me-1'
              >
                <path
                  fill='#ffff'
                  d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                />
              </svg>
              <p style={{marginLeft: '8px'}}>Filter</p>
            </button>
          </div>

          <div className='ms-auto'>
            <button
              className='btn btn-sm fw-bold text-white '
              style={{backgroundColor: '#35475e'}}
              onClick={() => {
                if(state?.tenancyId != undefined)
                {
                  setIncomeModal(true)

                }
                else{
                if (tenancy) {
                  setIncomeModal(true)
                  // addIncomingPaymentFunc()
                  // addSubItemFunc()
                } else {
                  ErrorToast(
                    "No tenancy is associated with this unit, so payment can't be generated..."
                  )
                }
              }
              }
            
            }
            >
              <img
                src={plus}
                style={{
                  height: '20px',
                  width: '20px',
                  marginRight: '7px',
                  marginLeft: '-10px',
                }}
              />
              Add Incoming Payment
            </button>
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pe-2'>
          {payments?.length > 0 ? (
            <div
              className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'
              id="global-table"
              onWheel={handleMouseWheel}
              ref={tableRef}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <table
                className='table align-start table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-100px'>#</th>
                    <th className='min-w-150px '>Payment</th>
                    <th className='min-w-150px'>Amount</th>
                    <th className='min-w-125px'>Date Created</th>
                    <th className='min-w-100px'>Status</th>
                    <th className='min-w-100px'>Category</th>
                    <th className='min-w-100px'>Due</th>
                    <th className='min-w-100px'>Reminder</th>
                    <th className='min-w-150px'>Amount Paid</th>
                    <th className='min-w-100px'>Date Paid</th>
                    <th className='min-w-150px'>Oustanding</th>
                    <th className='min-w-100px'>Method</th>
                    {/* <th className='min-w-100px'>Invoice</th> */}
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {payments.map((pm: any, ind: any) => {
                    let x = pm?.invoiceURL?.split('.')[1]
                    let y = pm?.invoiceURL?.split('/')[2]
                    // let inv: any = y + '.' + x
                    let inv: any = y

                    return (
                      <>
                        <tr
                          style={
                            show[ind] == true
                              ? {borderBottomStyle: 'none', height: '30px'}
                              : show[ind] == false 
                              ? {borderBottomStyle: 'dashed', height: '30px'}
                              : pm?.payment_sub_item?.length == 0
                              ? {borderBottomStyle: 'dashed', height: '30px'}
                              :  {height: '30px'}
                          }
                        >
                          <td>
                            <div className='d-flex align-items-center'>
                              {pm?.payment_sub_item?.length > 0 && (
                                <img
                                  src={show[ind] ? close : open}
                                  height='15'
                                  width='15'
                                  className='cursor-pointer'
                                  style={{marginLeft: '-30px', marginRight: '15px'}}
                                  onClick={() => {
                                    if (show[ind] == true) {
                                      let values = [...show]
                                      values[ind] = false
                                      setShow(values)
                                    } else {
                                      let values = [...show]

                                      values[ind] = true

                                      setShow(values)
                                    }
                                  }}
                                />
                              )}
                              <a
                              
                                // href={`${Bucket}${pm?.invoiceURL}`}
                                // target='_blank'
                                style={{textDecoration: 'underline', color: '#007a59', marginLeft: '-3px'}}
                                onClick={() => getPaymentById(pm?._id)}
                              >
                                {' '}
                                {pm.id}
                              </a>
                            </div>
                          </td>
                          <td className='max-w-150px'>
                            <div className='d-flex'>
                              {pm?.isLock && <img src={lock} height={16} width={16} className='me-2'/> }{pm.name}</div></td>
                          <td>
                            {parseFloat(pm?.totalAmount)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td>
                            {pm?.createdAt ? moment(pm?.createdAt).format('DD.MM.YYYY') : '-'}
                          </td>
                          <td>
                            {pm.paymentStatus === 0
                              ? 'Unpaid'
                              : pm.paymentStatus === 1
                              ? 'Paid'
                              : pm.paymentStatus === 2
                              ? 'Partially paid'
                            :pm.paymentStatus === 3
                            ? 'Void'
                            : 'Draft'
                            }
                          </td>
                          <td>
                            {pm.categories == 0
                              ? 'Upcoming'
                              : pm.categories == 1
                              ? 'Overdue'
                              : 'Deferred'}
                          </td>
                          <td>{pm?.dueDate ? moment(pm?.dueDate).format('DD.MM.YYYY') : '-'}</td>
                          <td>
                            {pm?.payment_reminder?.length > 0
                              ? moment(pm?.payment_reminder[0]?.reminderDate).format('DD.MM.YYYY')
                              : '-'}
                          </td>
                          <td>
                            {pm?.amountPaid?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </td>
                          <td>{pm?.datePaid ? moment(pm?.datePaid).format('DD.MM.YYYY') : '-'}</td>
                          <td>
                            {pm?.outstandingAmount <= 0 ? '' : '-'}
                            {parseFloat(getPositive(pm?.outstandingAmount))?.toLocaleString(
                              'en-US',
                              {
                                minimumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td>
                            {pm?.paymentMethod == 0
                              ? 'Card'
                              : pm?.paymentMethod == 1
                              ? 'Cheque'
                              : pm?.paymentMethod == 2
                              ? 'Bank'
                              : 'Cash'}
                          </td>
                          {/* <td>
                            {pm?.invoiceURL ? (
                              <a
                                href={`${Bucket}${pm?.invoiceURL}`}
                                target='_blank'
                                style={{textDecoration: 'underline', color: '#007a59'}}
                              >
                                {inv}
                              </a>
                            ) : (
                              '-'
                            )}
                          </td> */}
                        </tr>

                        {pm?.payment_sub_item?.length > 0 && show[ind] == true ? (
                          pm?.payment_sub_item.map((pmSub: any, index: any) => {
                            return (
                              <>
                                <tr style={{borderBottomStyle: 'none', height: '30px'}}>
                                  <td style={{paddingLeft: '5px'}}>
                                    {pm.id}
                                    {'_'}
                                    {pmSub?.id}
                                  </td>
                                  <td>{pmSub?.name}</td>
                                  <td>
                                    {pmSub?.isLatePaymentFeesRecord
                                      ? pmSub?.amount?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })
                                      : (pmSub?.amount * pmSub?.quantity)?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                  </td>
                                  <td></td>
                                  <td>
                                    {pmSub.paymentStatus === 0
                                      ? 'Unpaid'
                                      : pmSub.paymentStatus === 1
                                      ? 'Paid'
                                      : pmSub.paymentStatus === 2
                                      ? 'Partially paid'
                                      : pmSub.paymentStatus === 3
                                      ? 'Void'
                                      : 'Draft'
                                  }
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>

                                  <td>
                                    {pmSub?.amountPaid?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                  <td>
                                    {pmSub?.datePaid
                                      ? moment(pmSub?.datePaid).format('DD.MM.YYYY')
                                      : '-'}
                                  </td>
                                  <td>
                                    {pmSub?.outstandingAmount <= 0 ? '' : '-'}
                                    {parseFloat(
                                      getPositive(pmSub?.outstandingAmount)
                                    )?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                                {index == pm?.payment_sub_item?.length - 1 && (
                                  <tr style={{height: '30px'}}>
                                    <td></td>
                                    <td>{' Total VAT'}</td>
                                    <td>
                                      {pm?.totalVAT?.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                  </tr>
                                )}
                              </>
                            )
                          })
                        ) : (
                          <></>
                        )}
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'>
              <table
                className='table align-start table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-100px'>#</th>
                    <th className='min-w-150px '>Payment</th>
                    <th className='min-w-150px'>Amount</th>
                    <th className='min-w-150px'>Date Created</th>
                    <th className='min-w-100px'>Status</th>
                    <th className='min-w-100px'>Category</th>
                    <th className='min-w-150px'>Due</th>
                    <th className='min-w-150px'>Reminder</th>
                    <th className='min-w-150px'>Amount Paid</th>
                    <th className='min-w-100px'>Date Paid</th>
                    <th className='min-w-150px'>Oustanding</th>
                    <th className='min-w-100px'>Method</th>
                    {/* <th className='min-w-100px'>Invoice</th> */}
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  <tr>
                    <td colSpan={10} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end mt-2'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>

        {incomeModal && (
          <IncomingPaymentModal
            show={incomeModal}
            handleClose={() => {
              setIncomeModal(false)
            }}
            incomingPaymentId={incomingPaymentId}
            id={paymentId}
            subItemId={subItemId}
            subId={itemId}
            getAllPayments={getAllPayments}
            unitDetails={unitDetails}
            tnancy = {idTenancy}
          />
        )}
      </div>

      {/* Filters */}
      <div
        className={showFilter == true ? 'filter-payments' : 'd-none'}
        style={{
          width: '420px',
          backgroundColor: 'white',
          zIndex: '999',
          boxShadow: '0px 0px 20px 0px rgba(18, 32, 78, 0.2)',
        }}
      >
        <div
          className='card m-0 p-0 '
          style={{
            overflow: 'hidden',
            borderRadius: '0px',
            boxShadow: 'none',
          }}
        >
          {/* header */}
          <h3
            className='m-0 d-flex align-items-center msg_title'
            style={{
              justifyContent: 'space-between',
              borderRadius: '0px',
              zIndex: '1',
              opacity: '0.85',
            }}
          >
            Filter
            <span className='mx-0' style={{cursor: 'pointer', marginTop: '-20px'}}>
              <img src={closes} alt='' onClick={() => setShowFilter(false)} />
            </span>
          </h3>

          <h3
            className='m-0 d-flex align-items-center '
            style={{
              justifyContent: 'flex-end',
              borderRadius: '0px',
              transform: 'translate(0px, -50px)',
              color: 'white',
              backgroundColor: '#007a59',
              paddingBottom: '5px',
            }}
          >
            <span className='mx-1' style={{cursor: 'pointer'}}>
              <svg
                style={{
                  height: '40px',
                  width: '38px',
                  marginRight: '7px',
                  marginLeft: '-10px',
                  marginTop: '5px',
                  transform: 'translate(-30px, 0px)',
                }}
                viewBox='0 0 1024 1024'
                xmlns='http://www.w3.org/2000/svg'
                className='me-1'
              >
                <path
                  fill='#ffff'
                  d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                />
              </svg>
            </span>
          </h3>
        </div>

        <div style={{height: '100%', overflow: 'scroll', transform: 'translate(0px, -50px)'}}>
          {/* Property Item */}
          <div className=' align-items-center ps-3 ms-2 mt-5 '>
            <div className='d-flex align-items-baseline'>
              <img src={window} height='14' width='14' className='me-4' />

              <label className='mb-1 me-5 pe-5 d-flex grey-label fs-4 ' style={{fontWeight: '600'}}>
                Item
              </label>
            </div>

            <Select
              styles={customStyles}
              isSearchable={false}
              options={
                items &&
                items?.map((itm: any) => {
                  return {
                    label: `${itm?.name}`,
                    value: `${itm?._id}`,
                    id: itm,
                  }
                })
              }
              onChange={(e: any) => {
                let temp: any = []
                temp[0] = e.value
                setPaymentIdd(temp)
                applyFilters(e.value, 9)
              }}
            />
            <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 me-3'></div>
          </div>

          {/* Payment Status */}
          <div className='min-w-200px align-items-center ps-3 ms-2 mt-3 pt-1'>
            <div className='d-flex align-items-baseline'>
              <img src={window} height='14' width='14' className='me-4' />
              {/* <h4 className='' style={{minWidth: '105px ', color: '#b5b5c3;', fontWeight: '400'}}>
                <b>Status</b>
              </h4> */}
              <label className='mb-1 me-5 pe-5 d-flex grey-label fs-4 ' style={{fontWeight: '600'}}>
                Status
              </label>
            </div>

            <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
              <div className='col-4 d-flex align-items-center'>
                <input
                  className='form-check-input me-3 cursor-pointer'
                  type='radio'
                  checked={pStatus == 0}
                  onChange={() => {
                    paymentStatus = 0
                    setPStatus(0)
                    applyFilters(0, 1)
                  }}
                />{' '}
                Unpaid
              </div>
              <div className='col-4 d-flex align-items-center'>
                <input
                  className='form-check-input me-3 cursor-pointer'
                  type='radio'
                  checked={pStatus == 2}
                  onChange={() => {
                    setPStatus(2)
                    paymentStatus = 2
                    applyFilters(2, 1)
                  }}
                />{' '}
                Partially Paid
              </div>
              <div className='col-4 d-flex align-items-center'>
                <input
                  className='form-check-input me-3 cursor-pointer'
                  type='radio'
                  checked={pStatus == 1}
                  onChange={() => {
                    setPStatus(1)
                    paymentStatus = 1
                    applyFilters(1, 1)
                  }}
                />{' '}
                Paid
              </div>
            </div>
            <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 me-3'></div>
          </div>

          {/* Category */}
          <div className='min-w-200px align-items-center ps-3 ms-2 mt-3 pt-1'>
            <div className='d-flex align-items-baseline'>
              <img src={window} height='14' width='14' className='me-4' />

              <label className='mb-1 me-5 pe-5 d-flex grey-label fs-4 ' style={{fontWeight: '600'}}>
                Category
              </label>
            </div>

            <div className='row mb-5 ' style={{paddingLeft: '27px'}}>
              <div className='col-4 d-flex align-items-center'>
                <input
                  className='form-check-input me-3 cursor-pointer'
                  type='radio'
                  checked={cat === 0}
                  onChange={() => {
                    setCat(0)
                    checked = 0
                    applyFilters(0, 2)
                  }}
                />{' '}
                Upcoming
              </div>
              <div className='col-4 d-flex align-items-center'>
                <input
                  className='form-check-input me-3 cursor-pointer'
                  type='radio'
                  checked={cat === 1}
                  onChange={() => {
                    setCat(1)
                    checked = 1
                    applyFilters(1, 2)
                  }}
                />{' '}
                Overdue
              </div>
              <div className='col-4 d-flex align-items-center'>
                <input
                  className='form-check-input me-3 cursor-pointer'
                  type='radio'
                  checked={cat === 2}
                  onChange={() => {
                    setCat(2)
                    checked = 2
                    applyFilters(2, 3)
                  }}
                />{' '}
                Deffered
              </div>
            </div>
            <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 me-3'></div>
          </div>

          {/* Due Date */}
          <div className='min-w-200px align-items-center ps-3 ms-2 mt-3 pt-1'>
            <div className='d-flex align-items-baseline'>
              <img src={window} height='14' width='14' className='me-4' />

              <label className='mb-1 me-5 pe-5 d-flex grey-label fs-4 ' style={{fontWeight: '600'}}>
                Due Date
              </label>
            </div>
            <div className='d-flex mb-2' style={{paddingLeft: '27px'}}>
              <DatePicker
                onChange={handleDueStartDateChange}
                style={{width: '75%'}}
                className='me-5'
                placeholder='From'
              />

              <DatePicker
                onChange={handleDueEndDateChange}
                style={{width: '75%'}}
                className='me-5'
                placeholder='To'
              />
            </div>
            <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 me-3'></div>
          </div>

          {/* Date Paid */}
          <div className='min-w-200px align-items-center ps-3 ms-2 mt-3 pt-1'>
            <div className='d-flex align-items-baseline'>
              <img src={window} height='14' width='14' className='me-4' />

              <label className='mb-1 me-5 pe-5 d-flex grey-label fs-4 ' style={{fontWeight: '600'}}>
                Date Paid
              </label>
            </div>
            <div className='d-flex mb-2' style={{paddingLeft: '27px'}}>
              <DatePicker
                onChange={handlePaidStartDateChange}
                style={{width: '75%'}}
                className='me-5'
                placeholder='From'
              />

              <DatePicker
                onChange={handlePaidEndDateChange}
                style={{width: '75%'}}
                className='me-5'
                placeholder='To'
              />
            </div>
            <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 me-3'></div>
          </div>

          {/* Tenancy Contract */}
          <div className='min-w-200px align-items-center ps-3 ms-2 mt-3 pt-1'>
            <div className='d-flex align-items-baseline'>
              <img src={window} height='14' width='14' className='me-4' />

              <label className='mb-1 me-5 pe-5 d-flex grey-label fs-4 ' style={{fontWeight: '600'}}>
                Tenancy Contract
              </label>
            </div>

            <Select
              className='basic-single'
              classNamePrefix='select'
              styles={customStyles}
              isSearchable={isSearchable}
              options={
                tenancyCont &&
                tenancyCont?.map((itm: any) => {
                  return {
                    label: `${itm?.contractNo}`,
                    value: `${itm?.tenancyId}`,
                    id: itm,
                  }
                })
              }
              placeholder='Select'
              onChange={(e: any) => {
                let temp: any = []
                temp[0] = e.value
                setTenancyId(temp)
                applyFilters(e.value, 7)
              }}
            />
            <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 me-3'></div>
          </div>

          {/* Payment Method */}
          <div className='min-w-200px align-items-center ps-3 ms-2 mt-3 pt-1'>
            <div className='d-flex align-items-baseline'>
              <img src={window} height='14' width='14' className='me-4' />

              <label className='mb-1 me-5 pe-5 d-flex grey-label fs-4 ' style={{fontWeight: '600'}}>
                Payment Method
              </label>
            </div>

            <Select
              styles={customStyles}
              isSearchable={false}
              options={paymentMethods}
              onChange={(e: any) => {
                setPaymentMethod(e.value)
                applyFilters(e.value, 5)
              }}
            />
            <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 me-3'></div>
          </div>

          {/* Invoice */}
          <div className='min-w-200px align-items-center ps-3 ms-2 mt-3 pt-1'>
            <div className='d-flex align-items-baseline'>
              <img src={window} height='14' width='14' className='me-4' />

              <label className='mb-1 me-5 pe-5 d-flex grey-label fs-4 ' style={{fontWeight: '600'}}>
                Invoice
              </label>
            </div>

            <Select
              styles={customStyles}
              isSearchable={true}
              options={
                invoiceList &&
                invoiceList.map((inv: any) => {
                  return {
                    label: `${inv?.paymentId}`,
                    value: `${inv?.paymentId}`,
                    id: inv,
                  }
                })
              }
              onChange={(e: any) => {
                let temp: any = []
                temp[0] = e.value
                setPaymentInvId(temp)
                applyFilters(e.value, 8)
              }}
            />
            <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 me-3'></div>
          </div>

          {/* Outstanding Amount */}
          <div className='min-w-200px align-items-center ps-3 ms-2 mt-3 pt-1'>
            <div className='d-flex align-items-baseline'>
              <img src={window} height='14' width='14' className='me-4' />

              <label className='mb-1 me-5 pe-5 d-flex grey-label fs-4 ' style={{fontWeight: '600'}}>
                Outstanding Amount
              </label>
            </div>
            <div className='d-flex' style={{paddingLeft: '27px'}}>
              <input
                type='number'
                placeholder='From (Amount)'
                className='form-control form-control-solid-bg w-75 me-5'
                style={{
                  border: '0.1rem solid #bec3cb',
                  borderRadius: '2px',
                  padding: '0.5rem 1rem',
                }}
                value={outstandAmountFrom}
                onChange={(event: any) => {
                  setOutstandAmountFrom(event?.target.value)
                }}
                onBlur={(event: any) => {
                  applyFilters(event?.target.value, 61)
                }}
              />
              <input
                type='number'
                placeholder='Upto (Amount)'
                className='form-control form-control-solid-bg w-75 me-4'
                style={{
                  border: '0.1rem solid #bec3cb',
                  borderRadius: '2px',
                  padding: '0.5rem 1rem',
                }}
                value={outstandAmountTo}
                onChange={(event: any) => {
                  setOutstandAmountTo(event?.target.value)
                }}
                onBlur={(event: any) => {
                  applyFilters(event?.target.value, 62)
                }}
              />
            </div>

            <div style={{borderTop: '1.5px solid #b5b5c3'}} className='mt-3 me-3'></div>
          </div>

          <div className='d-flex align-items-center justify-content-center ps-3 ms-5 mt-5'>
            <button
              className='btn btn-sm fw-bold btn-primary btn-green mx-3 fs-4 d-flex mt-5'
              style={{width: '130px', height: '37px'}}
              onClick={() => setShowFilter(false)}
            >
              <p style={{marginLeft: '27px'}}>Apply</p>
            </button>
          </div>
        </div>
      </div>

      {dataToEdit && (
        <EditPaymentModal
          show={editIncomeModal}
          handleClose={() => {
            setEditIncomeModal(false)
          }}
          incomingPaymentId={incomingPaymentId}
          id={paymentId}
          subItemId={subItemId}
          subId={itemId}
          getAllPayments={getAllPayments}
          unitDetails={unitDetails}
          detailsToEdit={dataToEdit}
          type={'propertyProfile'}
        />
      )}
    </>
  )
}

export default FinancialIncome
