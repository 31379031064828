import React, { useState, useEffect } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { FaTimesCircle } from 'react-icons/fa';
import './style.scss';
import prevMonthImg from '../../../img/prev-month.png';
import prevYearImg from '../../../img/prev-year.png';
import nextMonthImg from '../../../img/next-month.png';
import nextYearImg from '../../../img/next-year.png';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';


interface DateStatus {
  date: moment.Moment;
  status: string;
}

interface DateRangePickerProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  onDatesChange: ({ startDate, endDate }: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => void;
  autoSave: () => void;
  from: any;
  dateStatuses: DateStatus[];
  disabled: boolean;
  isLinked?: boolean; // New prop to indicate if the DateRangePicker is linked
  linkedValue?: moment.Moment; // New prop for the linked value
  onUpdateLinkedValue?: (value: moment.Moment) => void; // Callback to update linked value
}

const CustomDateRangePicker: React.FC<DateRangePickerProps> = ({
  startDate,
  endDate,
  onDatesChange,
  dateStatuses,
  autoSave,
  from,
  disabled,
  isLinked = false,
  linkedValue,
  onUpdateLinkedValue,
}: DateRangePickerProps) => {
  const [focusedInput, setFocusedInput] = useState<any>(null);
  const { flg, unitId, id } = useParams();
  const [key, setKey] = useState(0);
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  useEffect(() => {
    if (isLinked && linkedValue) {
      setCurrentMonth(linkedValue.clone());
      setSelectedMonth(linkedValue.month());
      setSelectedYear(linkedValue.year());
    }
  }, [isLinked, linkedValue]);

  const getStatusForDate = (day: moment.Moment): string | null => {
    const backendDateFormatted = day.format('YYYY-MM-DD');
    const foundDate = dateStatuses?.find((date) => date.date.format('YYYY-MM-DD') === backendDateFormatted);

    return foundDate ? foundDate.status : 'default';
  };

  const handleDateChange = ({ startDate, endDate }: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
    onDatesChange({ startDate, endDate });

    if (startDate !== null && endDate !== null) {
      autoSave();
    }
  };

  const isOutsideRange = (day: moment.Moment) => {
    if (from === 'renew' && day.isBefore(moment(), 'day')) {
      return true;
    }

    const status = getStatusForDate(day);
    const isInRange = startDate !== null && endDate !== null && day.isBetween(startDate, endDate, 'day', '[]');

    if (!isInRange) {
      const isDisabled =
        status === 'booked' || status === 'active' || status === 'expiring' || status === 'draft';

      return isDisabled;
    }
  };

  const clearDates = () => {
    setFocusedInput('startDate');
    onDatesChange({ startDate: null, endDate: null });
    autoSave();
  };

  let min = "1960-07-20"
  let max = "2050-12-30"

  // const returnYears = () => {
  //   let years = [];
  //   for (
  //     let i = parseInt(moment(min).format("YYYY"));
  //     i <= parseInt(moment(max).format("YYYY"));
  //     i++
  //   ) {
  //     years.push(<option value={i}>{i}</option>);
  //   }
  //   return years;
  // };

  const returnYears = (selectedYear: number) => {
    const years = [];
    for (let i = selectedYear - 5; i <= selectedYear + 5; i++) {
      years.push(<option key={i} value={i}>{i}</option>);
    }
    return years;
  };
  




  // const renderMonthElement: React.FC<any> = ({ month, onMonthSelect, onYearSelect }) => {
  //   return (

  //     // <div style={{ display: "flex", justifyContent: "center" }} className="month-year-selector">
  //     //   <div style={{fontSize: '14px'}} className="mb-1">
  //     //     <BootstrapSelect
  //     //       options={moment.months().map((label, value) => ({ value, label }))}
  //     //       selectedOption={selectedMonth !== null ? { value: selectedMonth, label: moment.months()[selectedMonth] } : null}
  //     //       onOptionSelect={(option:any) => {
  //     //         setSelectedMonth(option.value);
  //     //         onMonthSelect(month, option.value);
  //     //       }}
  //     //     />
  //     //   </div>
  //     //   <div style={{fontSize: '14px'}} className="mb-1">
  //     //     <BootstrapSelect
  //     //       options={returnYears()}
  //     //       selectedOption={selectedYear !== null ? { value: selectedYear, label: selectedYear.toString() } : null}
  //     //       onOptionSelect={(option:any) => {
  //     //         setSelectedYear(option.value);
  //     //         onYearSelect(month, option.value);
  //     //       }}
  //     //     />
  //     //   </div>
  //     // </div>
     
  //     <div style={{ display: "flex", justifyContent: "center" }} className="month-year-selector">
  //       <div style={{fontSize: '14px'}} className="mb-1 selectpicker">
  //         <select
  //          className="custom-select"
  //           value={
  //             moment(month).isBefore(moment(min))
  //               ? moment(min).month()
  //               : month.month()
  //           }
  //           onChange={e => {
  //             if (month.isBefore(moment(min))) {
  //               onMonthSelect(moment(min), moment(min).month());
  //             } else {
  //               onMonthSelect(month, e.target.value);
  //             }
  //           }}
  //         >
            
  //           {moment.months().map((label, value) => (
  //             <option value={value}>{label}</option>
  //           ))}
           
  //         </select>
          
  //       </div>
  //       <div style={{fontSize: '14px'}} className="mb-1">
  //         <select
  //          className="custom-select"
  //           value={month.year()}
  //           onChange={e => {
  //             onYearSelect(month, e.target.value);
  //           }}
  //         >
  //           {returnYears()}
  //         </select>
  //       </div>
  //     </div>

  //   //   <div style={{ display: "flex", justifyContent: "center" }} className="month-year-selector">
  //   //   <FormControl variant="outlined" className="mb-1">
  //   //     <InputLabel htmlFor="month-select">Month</InputLabel>
  //   //     <Select
  //   //       value={moment(month).isBefore(moment(min)) ? moment(min).month() : month.month()}
  //   //       onChange={(e) => {
  //   //         if (month.isBefore(moment(min))) {
  //   //           onMonthSelect(moment(min), moment(min).month());
  //   //         } else {
  //   //           onMonthSelect(month, e.target.value);
  //   //         }
  //   //       }}
  //   //       label="Month"
  //   //       inputProps={{
  //   //         name: 'month',
  //   //         id: 'month-select',
  //   //       }}
  //   //     >
  //   //       {moment.months().map((label, value) => (
  //   //         <MenuItem key={value} value={value}>{label}</MenuItem>
  //   //       ))}
  //   //     </Select>
  //   //   </FormControl>
  //   //   <FormControl variant="outlined" className="mb-1">
  //   //     <InputLabel htmlFor="year-select">Year</InputLabel>
  //   //     <Select
  //   //       value={month.year()}
  //   //       onChange={(e) => {
  //   //         onYearSelect(month, e.target.value);
  //   //       }}
  //   //       label="Year"
  //   //       inputProps={{
  //   //         name: 'year',
  //   //         id: 'year-select',
  //   //       }}
  //   //     >
  //   //       {returnYears()}
  //   //     </Select>
  //   //   </FormControl>
  //   // </div>
  //   );
  // };



  // const renderMonthElement: React.FC<any> = ({ month, onMonthSelect, onYearSelect }) => {


  //   const months = moment.months();
  //   const currentYear = moment().year();
  //   const years = Array.from({ length: 21 }, (_, index) => currentYear - 10 + index); // Generate years from current year - 10 to current year + 10

  //   return (
  //     <div className="Month-year-selector">
  //       <select 
  //         value={selectedMonth !== null ? selectedMonth : month.month()}
  //         onChange={(e) => {
  //           setSelectedMonth(parseInt(e.target.value));
  //           onMonthSelect(month, parseInt(e.target.value));
  //         }}
  //       >
  //         {months.map((monthName: string, index: number) => (
  //           <option key={index} value={index}>
  //             {monthName}
  //           </option>
  //         ))}
  //       </select>
  //       <select
  //         value={selectedYear !== null ? selectedYear : month.year()}
  //         onChange={(e) => {
  //           setSelectedYear(parseInt(e.target.value));
  //           onYearSelect(month, parseInt(e.target.value));
  //         }}
  //       >
  //         {years.map((year: number, index: number) => (
  //           <option key={index} value={year}>
  //             {year}
  //           </option>
  //         ))}
  //       </select>
  //     </div>
  //   );
  // };

  const renderMonthElement: React.FC<any> = ({ month, onMonthSelect, onYearSelect }) => {
    const selectedMonth = month.month();
    const selectedYear = month.year();
  
    return (
      <div style={{ display: "flex", justifyContent: "center" }} className="month-year-selector">
        <div style={{ fontSize: '14px' }} className="mb-1 selectpicker">
          <select
            className="custom-select"
            value={selectedMonth}
            onChange={(e) => {
              onMonthSelect(month, parseInt(e.target.value));
            }}
          >
            {moment.months().map((label, value) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        </div>
        <div style={{ fontSize: '14px' }} className="mb-1">
          <select
            className="custom-select"
            value={selectedYear}
            onChange={(e) => {
              onYearSelect(month, parseInt(e.target.value));
            }}
          >
            {returnYears(selectedYear)}
          </select>
        </div>
      </div>
    );
  };
  

  const dayRender = (day: moment.Moment): React.ReactNode => {
    const isPastDate = day.isBefore(moment(), 'day');

    const status = getStatusForDate(day);
    const isInRange = startDate !== null && endDate !== null && day.isBetween(startDate, endDate, 'day', '[]');

    let backgroundColor;
    let color;

    if (!isPastDate && !isInRange) {
      switch (status) {
        case 'booked':
          backgroundColor = '#0099cc';
          color = 'white';
          break;
        case 'active':
          backgroundColor = '#00b8a9';
          color = 'white';
          break;
        case 'expiring':
          backgroundColor = '#f38513 ';
          color = 'white';
          break;
        case 'expired':
          backgroundColor = '#e46464 ';
          color = 'white';
          break;
        case 'draft':
          backgroundColor = '#b0b7c2 ';
          color = 'black';
          break;
        default:
          backgroundColor = 'inherit';
          break;
      }
    }

    return (
      <div style={{ backgroundColor, color }} className="day-render">
        {day.format('D')}
      </div>
    );
  };

  const handlePrevYear = () => {
    const newMonth = currentMonth.clone().subtract(1, 'year');
    setCurrentMonth(newMonth);
    setKey((prevKey) => prevKey + 1);
    setFocusedInput(focusedInput);
    if (isLinked && onUpdateLinkedValue) {
      onUpdateLinkedValue(newMonth);
    }
  };

  const handleNextYear = () => {
    const newMonth = currentMonth.clone().add(1, 'year');
    setCurrentMonth(newMonth);
    setKey((prevKey) => prevKey + 1);
    setFocusedInput(focusedInput);
    if (isLinked && onUpdateLinkedValue) {
      onUpdateLinkedValue(newMonth);
    }
  };

  const handlePrevMonth = () => {
    const newMonth = currentMonth.clone().subtract(1, 'month');
    setCurrentMonth(newMonth);
    setKey((prevKey) => prevKey + 1);
    setFocusedInput(focusedInput);
    if (isLinked && onUpdateLinkedValue) {
      onUpdateLinkedValue(newMonth);
    }
  };

  const handleNextMonth = () => {
    const newMonth = currentMonth.clone().add(1, 'month');
    setCurrentMonth(newMonth);
    setKey((prevKey) => prevKey + 1);
    setFocusedInput(focusedInput);
    if (isLinked && onUpdateLinkedValue) {
      onUpdateLinkedValue(newMonth);
    }
  };

  return (
    <div className="custom-date-range-picker">
      <DateRangePicker
        key={key}
        numberOfMonths={2}
        renderDayContents={(day: any) => dayRender(day)}
        startDate={startDate}
        startDateId="start_date_id"
        endDate={endDate}
        endDateId="end_date_id"
        onDatesChange={handleDateChange}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput: any) => {
          setFocusedInput(focusedInput);
        }}
        displayFormat="DD/MM/YYYY"
        isOutsideRange={isOutsideRange}
        customInputIcon={startDate && endDate && <FaTimesCircle onClick={clearDates} />}
        className="form-control form-control-solid mytest"
        readOnly
        disabled={disabled}
        // initialVisibleMonth={() => currentMonth}
        // renderMonthElement={renderMonthElement}
        renderMonthElement={(props:any) => renderMonthElement({ ...props, selectedMonth, selectedYear })}
      />

      {/* {focusedInput != null && ( */}
        {/* <div className="navigation-buttons date-picker-navigation d-flex">
          <button onClick={handlePrevYear} type="button" className="date-picker-navigation-btns mx-5">
            <img src={prevYearImg} height={18} width={18} alt="Previous Year" />
          </button>
          <button onClick={handlePrevMonth} type="button" className="date-picker-navigation-btns">
            <img src={prevMonthImg} height={18} width={18} alt="Previous Month" />
          </button>
          <div className="d-flex ms-auto">
            <button onClick={handleNextMonth} type="button" className="date-picker-navigation-btns ms-auto">
              <img src={nextMonthImg} height={18} width={18} alt="Next Month" />
            </button>
            <button onClick={handleNextYear} type="button" className="date-picker-navigation-btns mx-5">
              <img src={nextYearImg} height={18} width={18} alt="Next Year" />
            </button>
          </div>
        </div> */}
      {/* )} */}

      {/* <div style={focusedInput == null ? { display: 'none' } : { marginTop: '10px', display: 'flex' }}>
        <div style={{ display: 'flex', marginRight: '20px' }}>
          <div style={{ width: '20px', height: '20px', backgroundColor: '#b0b7c2 ' }}></div>
          <span style={{ marginLeft: '5px' }}>Draft</span>
        </div>
        <div style={{ display: 'flex', marginRight: '20px' }}>
          <div style={{ width: '20px', height: '20px', backgroundColor: '#0099cc' }}></div>
          <span style={{ marginLeft: '5px' }}>Booked</span>
        </div>
        <div style={{ display: 'flex', marginRight: '20px' }}>
          <div style={{ width: '20px', height: '20px', backgroundColor: '#00b8a9' }}></div>
          <span style={{ marginLeft: '5px' }}>Active</span>
        </div>
        <div style={{ display: 'flex', marginRight: '20px' }}>
          <div style={{ width: '20px', height: '20px', backgroundColor: '#f38513' }}></div>
          <span style={{ marginLeft: '5px' }}>Expiring</span>
        </div>
        <div style={{ display: 'flex', marginRight: '20px' }}>
          <div style={{ width: '20px', height: '20px', backgroundColor: '#e46464' }}></div>
          <span style={{ marginLeft: '5px' }}>Expired</span>
        </div>
      </div> */}
    </div>
  );
};

export default CustomDateRangePicker;
