import React from 'react';

interface PreviewProps { 
}

const Preview: React.FC<PreviewProps> = ({ }) => {
    return (
        <div>
            
        </div>
    );
};

export default Preview;
