import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import backArrow from '../../../img/back-arrow.png'
import receiptImg from '../../../img/receipt-blue.png'
import { ApiGet, ApiPut } from '../../../apiCommon/helpers/API/ApiData'
import { SuccessToast, ErrorToast } from '../../../apiCommon/helpers/Toast'
import editpen from '../../../img/pen.png'
import InvConfigureModal from './InvConfigureModal'
import Switch from 'react-switch'

interface ReceiptSeq {
  activityStatus: number
  startNumber: number
  increment: number
  noOfDigits: number
  prefix: string | null
  suffix: string | null
  lastCount: number
}

const Receipt: React.FC = () => {
  const navigate = useNavigate()
  const [invNoModal, setInvNoModal] = useState<boolean>(false)
  const [details, setDetails] = useState<any>(null)
  const [receiptSeq, setReceiptSeq] = useState<ReceiptSeq | null>(null)

  const getDetails = async (): Promise<void> => {
    try {
      const res = await ApiGet('corporate/corporate_setting')
      setDetails(res?.data?.data)
      setReceiptSeq(res?.data?.data?.receiptSequence)
    } catch (err) {
      console.error('Error fetching details:', err)
    }
  }

  const updateActivityStatus = (val: boolean): void => {
    const status = val ? 1 : 0

    const body = {
      activityStatus: status,
    }

    ApiPut('corporate/corporate_setting/receipt_activity', body)
      .then(() => getDetails())
      .catch((err) => ErrorToast(err.message))
  }

  useEffect(() => {
    getDetails()
  }, [])

  const renderLabel = (label: string, value: any) => (
    <Col xs={6} sm={6} md={4} lg={4}>
      <div className="d-flex grid_big_col py-2 fs-7">
        <label className="form-label blue-label fs-7 light-dark-theme-color" style={{ minWidth: '115px' }}>
          {label}
        </label>
        <div className="d-flex white-dark-theme-color">{value ?? '-'}</div>
      </div>
    </Col>
  );

  return (
    <>
      <div className="px-2">
        <div className="d-flex align-items-center mb-10">
          <div className="d-flex align-items-center">
            <span className="rounded-circle cursor-pointer" onClick={() => navigate('/settings')}>
              <img src={backArrow} height={14} width={14} className="me-2" />
            </span>
            <h2 className="page-heading m-0 head-text">Receipt</h2>
          </div>
          <div className="ms-auto"></div>
          <div className="ms-5">
            <img src={receiptImg} height="40px" width="40px" />
          </div>
        </div>

        {/* Tabs */}
        <div
          className="d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center"
          style={{ marginBottom: '35px' }}
        >
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
            <li className="nav-item">
              <a
                className="nav-link text-active-primary pb-2 me-5 active"
                data-bs-toggle="tab"
                onClick={() => navigate('/settings/receipt')}
              >
                Receipt No. Generator
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-active-primary pb-2 mx-5 light-dark-theme-color"
                data-bs-toggle="tab"
                onClick={() => navigate('/settings/receipt-design')}
              >
                Receipt Design
              </a>
            </li>
          </ul>
        </div>

        <Row>
          <Col md={12} className="pe-5">
            {/* Invoice No Configuration */}
            <div className="card px-5 py-5 mt-5">
              <div className="d-flex">
                <div className="d-flex ms-auto">
                  <Switch
                    onChange={updateActivityStatus}
                    checked={receiptSeq?.activityStatus === 1}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor="#00cf99"
                    offColor="#b0bec5"
                    className="ms-auto me-5"
                  />
                  <img
                    src={editpen}
                    alt="Edit"
                    className="ms-4 me-2"
                    style={{ cursor: 'pointer', height: '22px', width: '22px', marginTop: '3px' }}
                    onClick={() => setInvNoModal(true)}
                  />
                </div>
              </div>

              <Row className="mt-3">
                {renderLabel('Start No.', receiptSeq?.startNumber)}
                {renderLabel('Increment By', receiptSeq?.increment)}
                {renderLabel('No. of Digits', receiptSeq?.noOfDigits)}
                {renderLabel('Prefix', receiptSeq?.prefix)}
                {renderLabel('Suffix', receiptSeq?.suffix)}
                {renderLabel('Last Count', receiptSeq?.lastCount)}
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      {/* Modal */}
      {invNoModal && (
        <InvConfigureModal
          show={invNoModal}
          handleClose={() => setInvNoModal(false)}
          type="receipt"
          invSeq={receiptSeq}
          getDetails={getDetails}
        />
      )}
    </>
  )
}

export default Receipt
