import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  ApiDelete,
} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {Col, Row, Tabs, Tab, Modal} from 'react-bootstrap'
import TenanciesFilter from './TenanciesFilter'
import {Controller, useForm} from 'react-hook-form'
import {KTSVG} from '../../../_metronic/helpers'
import {AiFillDelete} from 'react-icons/ai'
import swal from 'sweetalert2'
import {DatePicker} from 'antd'
import 'antd/dist/antd.css'
import type {RangePickerProps} from 'antd/es/date-picker'
import moment from 'moment'
import PhoneInput from 'react-phone-input-2'
import townhouse from '../../../img/TownHouse.svg'
import appartment from '../../../img/Apartment.svg'
import villa from '../../../img/Villa.svg'
import other from '../../../img/other.svg'
import buildings from '../../../img/buildings.svg'
import common_area from '../../../img/common_area.svg'
import penthouse from '../../../img/PentHouse.svg'
import plus from '../../../img/plus.svg'
import upload from '../../../img/upload.svg'
import closered from '../../../img/closered.svg'
import fileicon from '../../../img/file.svg'
import noData from '../../../img/NoData1.svg'
import {Button, Spinner} from 'react-bootstrap'
import Label from '../../../app/modules/comman/Label'
import pencil from '../../../img/pencil.svg'
import info from '../../../img/info.svg'
import pen from '../../../img/pen.png'
import {async} from '@firebase/util'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import IncomingPaymentModal from './IncomingPaymentModal'
import open from '../../../img/payment-col-open.png'
import close from '../../../img/payment-col-close.png'
import ReactPaginate from 'react-paginate'
import EditPaymentModal from '../Financials/EditPaymentModal'
import backArrow from '../../../img/back-arrow.png'
import lock from '../../../img/padlock.png'
import folderDocument from '../../../img/folder-document.svg'
import saveImg from '../../../img/Save-White.png'
import submitImg from '../../../img/Submit-White.png'
import EditPaymentModalDraft from './EditPaymentModal'
import AddNewTenant from './AddNewTenantModal'
import plusBlue from '../../../img/add-blue.png'
import redCross from '../../../img/remove.png'
import redCrossFilled from '../../../img/remove-filled.png'

// import PaidModal from './PaidModal'

let arry: any = []

const CreateTenant = () => {
  const {flg, id, tenancyId} = useParams()

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [isFormDirty, setIsFormDirty] = useState(false)

  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const {state} = useLocation()
  const [showCheange, setShowCheange] = useState<boolean>(false)
  const [showPaid, setShowPaid] = useState<boolean>(false)
  const [showImageName, setShowImageName] = useState<boolean>(false)
  const [country, setCountry] = useState([])
  const [tableData, setTableData] = useState([])
  const [card, setCard] = useState('')
  const [cardA, setCardA] = useState<any>([])
  const [showModal, setShowModal] = useState<any>('Filter')
  const [type, setType] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)
  const [totalDays, setTotalDays] = useState('0 Days')
  const [propertiType, setPropertiType] = useState<any>('Tenants')
  const [payment, setPayment] = useState<number>(0)
  const [option, setOption] = useState(1)
  const [formData, setFormData] = useState<any>({
    propertyId: window.location.pathname?.split('/')[2],
    propertyAreaId: window.location.pathname?.split('/')[3],
  })
  const [create, setCreate] = useState<any>(false)
  const [status, setStatus] = useState<any>(false)
  const [phone, setPhone] = useState('')
  const [header, setHeader] = useState<any>({})
  const [unitData, setUnitData] = useState<any>([])
  const [optionArry, setOptionArry] = useState<any>({
    none: false,
    maintenanceFree: false,
    other: [],
    chillerFree: false,
  })
  const [headerB, setHeaderB] = useState<any>([])
  const [tenancyData, setTenancyData] = useState<any>({})
  const [paymentData, setPaymentData] = useState<any>({})
  const [createpaymentData, setCreatePaymentData] = useState<any>(0)
  const [subTenant, setSubTenant] = useState<any>({})
  const [otherOptions, setOtherOptions] = useState<any>([
    // {
    //   name: '',
    // },
  ])

  const [subTenantData, setSubTenantData] = useState<any>([
    {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      mobileNo: '',
    },
  ])

  // Handle Add Sub Tenants
  const handleAddFields = () => {
    const values = [...subTenantData]
    values.push({
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      mobileNo: '',
    })
    setSubTenantData(values)
  }

  // Handle Remove Sub Tenants
  const handleRemoveFields = (index: any, item: any) => {
    const values = [...subTenantData]
    values.splice(index, 1)
    setSubTenantData(values)
  }

  // Handle Add Other Options
  const handleAddOther = () => {
    const values = [...otherOptions]
    values.push({
      value: '',
    })
    setOtherOptions(values)
  }

  // Handle Remove Other Options
  const handleRemoveOther = (index: any) => {
    const values = [...otherOptions]
    values.splice(index, 1)
    setOtherOptions(values)

    setIsFormDirty(true)
  }

  const [count, setcount] = useState<any>(0)
  const [totalamount, settotalamount] = useState<any>(0)
  const [totalpayment, settotalpayment] = useState<any>(0)
  const [createtotalpayment, setCreatetotalpayment] = useState<any>(0)
  const [modeldata, setmodeldata] = useState<any>()
  const [modelindex, setmodelindex] = useState<any>()
  const [modelData, setmodelData] = useState<any>({})
  const [popUpData, setpopUpData] = useState<any>({})
  const [modelchequeData, setmodelchequeData] = useState<any>()
  const [popUpReceiptData, setpopUpReceiptData] = useState<any>()
  const [popupindex, setpopupindex] = useState<any>()
  const [tenancyPayment, setTenancyPayment] = useState<any>([])
  const [createtenancyPayment, setCreateTenancyPayment] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>()
  const [updateIds, setUpdateIds] = useState<any>([])
  const [deletePayment, setDeletePayment] = useState<any>([])
  const [updatePayment, setUpdatePayment] = useState<any>([])
  const [newPayment, setNewPayment] = useState<any>([])
  const [tenanatData, setTenantData] = useState<any>([])

  const [subTenantIds, setSubTenantIds] = useState<any>([])

  const [imgerrors, setImgError] = useState<any>({})
  const [modalBtnIsLoading, setModalBtnIsLoading] = useState<any>(false)
  const [deleteSubtenantArr, setDeleteSubtenantArr] = useState<any>([])
  const [sendError, setSendError] = useState<any>(true)
  const [finalPaid, setFinalPaid] = useState<any>()

  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)

  var optionArryPayload: any

  const unitId = localStorage.getItem('UnitId')
  const navigatePage = localStorage.getItem('page')

  const unitIDfromProperties = useSelector(
    (state: any) => state.counterReducer.unitIDfromProperties
  )
  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)
  var tenancyStatus: any = 0

  const {RangePicker} = DatePicker
  const [isReqCheck, setisReqCheck] = useState<any>(false)
  let status_isDraft: any = localStorage.getItem('isDraft')

  useEffect(() => {
    if (flg == '1') {
      setisReqCheck(true)
    } else {
      setisReqCheck(false)
    }
    enableScrolling()
  }, [])

  const requestorId = useSelector((state: any) => state.counterReducer.requestorId)
  const comefromtenant = useSelector((state: any) => state.counterReducer.comefrom)

  const dateFormat = 'DD/MM/YYYY'

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})

    // setTimeout(() => {
    //   autoSaveTenant(0, newTenantId)
    // }, 2000)
  }
  const popUpchange = (name: string, value: any) => {
    // let {name, value} = e.target
    setpopUpData({...popUpData, [name]: value})
    // setpopUpData({...popUpData, [name]: isNaN(value) ? value : +value})
  }
  const modelchange = (e: any) => {
    let {name, value} = e.target
    setmodelData({...modelData, [name]: value})
  }
  const modelSubmit = () => {
    let data: any
    if (modeldata == '1') {
      data = {
        cheque: modelData,
      }
      data.cheque.image = modelchequeData
    } else if (modeldata == '2') {
      data = {
        bank: modelData,
      }
    } else if (modeldata == '0') {
      data = {
        card: {},
      }
    }
    // else if (modeldata == '1') {
    //   data = {
    //     card: {},
    //   }
    // }
    arry = arry.map((q: any, i: any) => {
      if (i === modelindex) {
        // 👇️ change value of name property
        return {...q, ['paymentValue']: data}
      }
      return q
    })

    setcount(count + 1)
    setShowCheange(false)
  }
  const popUpSubmit = (event: any, index: any) => {
    event.preventDefault()
    let data: any
    setmodelindex(index)
    if (paymentMethod === '0') {
      data = {
        card: {},
      }
      // data.card.image = popUpReceiptData
    } else if (paymentMethod === '1') {
      data = {
        cheque: {
          chequeNo: popUpData?.chequeNo,
          bankName: popUpData?.bankName,
          image: modelchequeData,
        },
      }
      // data.cheque.receiptImage = popUpReceiptData
      // data.cheque.chequeImage = modelchequeData
    } else if (paymentMethod === '2') {
      data = {
        bank: {
          IBAN: popUpData?.IBAN,
          accountNo: popUpData?.accountNo,
          bankName: popUpData?.bankName,
        },
      }
      // data.bank.image = popUpReceiptData
    } else {
      data = {
        cash: {},
      }
      // data.cash.image = popUpReceiptData
    }
    let receiptd: any = {
      referenceNo: popUpData?.referenceNo,
      receiptDate: popUpData?.receiptDate,
      image: popUpReceiptData,
    }

    let temp: any = {...popUpData, paymentValue: data, paymentMethod}
    setFinalPaid([temp])
    console.log('\ntemp', temp)
    const new_tenancyPayment = tenancyPayment.map((el: any, i: any) => {
      if (i == popupindex) {
        return {
          ...el,
          amount: temp.amountPaid,
          amountPaid: temp.amountPaid,
          amountReceived: temp.amountReceived,
          outstandingBalance: temp.outstandingBalance,
          serviceFee: temp.serviceFee,
          paymentValue: temp.paymentValue,
          paymentMethod: temp.paymentMethod,
          receiptDate: temp.receiptDate,
          paymentDate: temp.paymentDate,
          receiptURL: popUpReceiptData,
        }
      } else {
        return el
      }
    })
    const new_createtenancyPayment = createtenancyPayment.map((el: any, i: any) => {
      if (i == popupindex) {
        return {
          ...el,
          amount: temp.amountPaid,
          amountPaid: temp.amountPaid,
          amountReceived: temp.amountReceived,
          outstandingBalance: temp.outstandingBalance,
          serviceFee: temp.serviceFee,
          paymentValue: temp.paymentValue,
          paymentMethod: temp.paymentMethod,
          receiptDate: temp.receiptDate,
          paymentDate: temp.paymentDate,
          receiptURL: popUpReceiptData,
        }
      } else {
        return el
      }
    })
    setTenancyPayment(new_tenancyPayment)
    setCreateTenancyPayment(new_createtenancyPayment)

    setpopUpData({})
    setmodelchequeData('')
    setpopUpReceiptData('')

    setcount(count + 1)
    setShowPaid(false)
  }

  const handleChangeContractNo = (e: any) => {
    const {name, value} = e.target
    setTenancyData({...tenancyData, [name]: value})
    setIsFormDirty(true)
  }

  const handleChnageTenancy = (e: any) => {
    console.log(e.target.value)
    const {name, value} = e.target
    setTenancyData({...tenancyData, [name]: value})
    tenancyStatus = e.target.value
    setTimeout(() => {
      autoSaveTenancy()
    }, 2500)
  }

  const handleChnagePayment = (e: any, i: any, v: any) => {
    let {name, value} = e.target
    if (name == 'amount' || name == 'paymentMethod' || name == 'rentType' || name == 'status') {
      value = parseInt(value)
    }
    if (flg == '1') {
      if (name == 'amount') {
        let dat = createtenancyPayment[i].amount
        if (!value) {
          value = 0
        }

        if (dat) {
          settotalamount(totalamount - dat + value)
        } else {
          settotalamount(totalamount + value)
        }
      }
      let updateTenancyPayment = createtenancyPayment.map((q: any, k: any) => {
        if (k === i) {
          if (name == 'paymentSchedule' || name == 'paymentRemainder') {
            let d = new Date(value)
            return {...q, [name]: moment(d).format('YYYY-MM-DD')}
          } else {
            return {...q, [name]: value}
          }
        }
        return q
      })
      setCreateTenancyPayment(updateTenancyPayment)
      setcount(count + 1)
      setPaymentData({...paymentData, [name]: value})
    } else {
      if (name == 'amount') {
        let dat = tenancyPayment[i].amount
        if (!value) {
          value = 0
        }

        if (dat) {
          settotalamount(totalamount - dat + value)
        } else {
          settotalamount(totalamount + value)
        }
      }
      let updateTenancyPayment = tenancyPayment.map((q: any, k: any) => {
        if (k === i) {
          if (name == 'paymentSchedule' || name == 'paymentRemainder') {
            let d = new Date(value)
            return {...q, [name]: moment(d).format('YYYY-MM-DD')}
          } else {
            return {...q, [name]: value}
          }
        }
        return q
      })
      setTenancyPayment(updateTenancyPayment)
      setcount(count + 1)
      setPaymentData({...paymentData, [name]: value})
      if (!updateIds.includes(v._id)) {
        setUpdateIds([...updateIds, v._id])
      }
    }
  }

  const callmodel = (data: any, index: any) => {
    setmodeldata(data)
    setmodelindex(index)
    setcount(count + 1)
    setShowCheange(true)
  }
  const callpopUp = (data: any, index: any) => {
    setmodelindex(index)
    setcount(count + 1)
    setShowPaid(true)
  }

  // work in progress

  const handleChnageOption = (e: any) => {
    let {name, checked} = e.target
    console.log('e.target.value', e.target.value)
    if (name == 'other') {
      setOptionArry({...optionArry, [name]: [e.target.value]})
      optionArryPayload = {...optionArry, [name]: [e.target.value]}
    } else {
      if (name == 'none' && checked == true) {
        setOption(0)
        setOptionArry({
          ...optionArry,
          ['other']: [],
          ['chillerFree']: false,
          ['maintenanceFree']: false,
          [name]: checked,
        })
        optionArryPayload = {
          ...optionArry,
          ['other']: [],
          ['chillerFree']: false,
          ['maintenanceFree']: false,
          [name]: checked,
        }
      } else {
        setOptionArry({...optionArry, [name]: checked})
        optionArryPayload = {...optionArry, [name]: checked}
      }
    }

    setcount(count + 1)
    setIsFormDirty(true)
  }

  const getUbitDataById = () => {
    if (comefrom == 'properties') {
      ApiGet(`corporate/unit/${unitIDfromProperties}`)
        .then((res) => {
          setUnitData(res?.data?.data)
          setSendError(false)
          setDisabledBtn(false)
        })
        .catch((err) => console.log('err', err))
    } else if (comefrom == 'tenancy' && unitId != null) {
      ApiGet(`corporate/unit/${unitId}`)
        .then((res) => {
          setUnitData(res?.data?.data)
          setSendError(false)
          setDisabledBtn(false)
        })
        .catch((err) => console.log('err', err))
    } else {
      if (unitId != null) {
        ApiGet(`corporate/unit/${unitId}`)
          .then((res) => {
            setUnitData(res?.data?.data)
            setSendError(false)
            setDisabledBtn(false)
          })
          .catch((err) => console.log('err', err))
      }
      // else {
      //   ApiGet(`corporate/unit/${id}`)
      //     .then((res) => {
      //       setUnitData(res?.data?.data)
      //       setSendError(false)
      //       setDisabledBtn(false)
      //     })
      //     .catch((err) => console.log('err', err))
      // }
    }
  }

  const getUnitById = (value: any) => {
    ApiGet(`corporate/unit/${value}`)
      .then((res) => {
        setUnitData(res?.data?.data)
        setSendError(false)
        setDisabledBtn(false)
      })
      .catch((err) => console.log('err', err))
  }

  const handleSubmitId = (value: any) => {
    setIsFormDirty(true)
    getUnitById(value)
  }
  const handleChnageSubTenant = (e: any) => {
    const {name, value} = e.target
    setSubTenant({...subTenant, [name]: value})
  }
  const handleChnageTenancyDate = (dates: any) => {
    // console.log(dates)
    // setStart(moment(e[0]?._d).format('YYYY-MM-DD'))
    // setEnd(moment(e[1]?._d).format('YYYY-MM-DD'))

    setStart(dates?.[0].format('DD/MM/YYYY'))
    setStartDatePayload(dates?.[0].format('YYYY-MM-DD'))

    setEnd(dates?.[1].format('DD/MM/YYYY'))
    setEndDatePayload(dates?.[1].format('YYYY-MM-DD'))

    var start = moment(dates?.[0])
    var end = moment(dates?.[1])
    setTotalDays(end.diff(start, 'days', true) + ' days')

    setIsFormDirty(true)
  }

  const handleChnage12 = (e: any) => {
    const {name, value} = e.target
    setPaymentMethod(e.target.value)
  }

  const deleteSubtenant = (j: any) => {
    setDeleteSubtenantArr((prev: any) => [
      ...prev,
      subTenantData.filter((el: any, i: any) => i == j),
    ])
    setSubTenantData(subTenantData.filter((el: any, i: any) => i !== j))
  }
  const addSubTenant = () => {
    setSubTenantIds([...subTenantIds, subTenantId])
    setSubTenantData([...subTenantData, subTenant])
    setCreate(false)

    let values = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      countryCode: '',
      email: '',
    }
    setSubTenant(values)
    setSubTenantId('')

    // autoSaveTenant(1, subTenantId)
    // setTimeout(() => {
    //   setSubTenant({})

    //   getSubTenants(newTenantId)
    //   // getTenantData()
    // }, 1000)
  }

  const deleteRow = (i: any, amount: any, deleteData: any) => {
    if (flg == '1') {
      setCreateTenancyPayment(createtenancyPayment.filter((v: any, index: any) => index !== i))
    } else if (flg == '2' || flg == '3') {
      setTenancyPayment(tenancyPayment.filter((v: any, index: any) => index !== i))
      setDeletePayment([...deletePayment, deleteData])
      if (amount == undefined) {
        amount = 0
      }
      settotalamount(totalamount - amount)
      settotalpayment(totalpayment - 1)
      setcount(count + 1)
      if (tenancyPayment.length === 0) {
        setCard('')
      }
      let a: any = []
      tenancyPayment.map((v: any) => {
        if (v?.paymentMethod === 0) {
          a.push(v?.paymentMethod)
        }
        if (v?.paymentMethod === 1) {
          a.push(v?.paymentMethod)
        }
      })
      const ages = a
      const uniqueAges = ages.filter((x: any, i: any, a: any) => a.indexOf(x) == i)

      if (uniqueAges.length === 2) {
        setCard('2')
      }
      if (uniqueAges.length === 1) {
        if (uniqueAges[0] === 0) {
          setCard('0')
        } else {
          setCard('1')
        }
      }
      if (uniqueAges.length === 0) {
        if (a.length === 0) {
          setCard('')
        }
      }
    }
  }

  const imageCheque = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/cheque', formData)
      .then(async (res) => {
        setmodelchequeData(res?.data?.data?.image)
      })
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const imagerecipt = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/cheque', formData)
      .then(async (res) => {
        setpopUpReceiptData(res?.data?.data?.image)
      })
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }

  const [uploadOtherImgTenancy, setuploadOtherImgTenancy] = useState<any>([])
  const [uploadContractTenancy, setuploadContractTenancy] = useState<any>([])

  const [uploadOtherImgTenant, setuploadOtherImgTenant] = useState<any>([])
  const [uploadIdTenant, setuploadIdTenant] = useState<any>([])
  const [uploadresidencyTenant, setuploadresidencyTenant] = useState<any>([])
  const [uploadPassportTenant, setuploadPassportTenant] = useState<any>([])
  const [selectedUploadFile, setSelectedUploadFile] = useState<any>()
  const [finalFileUpload, setFinalFileUpload] = useState<any>([])
  const [fileFound, setFileFound] = useState<any>(false)
  const [newTenancyID, setNewTenancyID] = useState<any>(null)
  const [newTenancyData, setNewTenancyData] = useState<any>(state?.tenancyData)
  const [newTenantId, setNewTenantID] = useState<any>('')

  const [editName, setEditName] = useState(false)
  // const dropArea: any = document.querySelector('.drag-area'),
  //   dragText = dropArea.querySelector('header'),
  //   button = dropArea.querySelector('button'),
  //   input = dropArea.querySelector('input')
  // let file: any = ''
  // button.onclick = () => {
  //   input.click() //if user click on the button then the input also clicked
  // }
  // input.addEventListener('change', function (e: any) {
  //   //getting user select file and [0] this means if user select multiple files then we'll select only the first one
  //   console.log('this.files[0]', e)
  //   // file = this.files[0]
  // })

  const [payments, setPayments] = useState<any>()
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)
  const [unitDetails, setUnitDetails] = useState<any>()
  const [editIncomeModal, setEditIncomeModal] = useState<any>(false)
  const [editIncomeModalDraft, setEditIncomeModalDraft] = useState<any>(false)
  const [dataToEdit, setDataToEdit] = useState<any>()
  const [indexToEdit, setIndexToEdit] = useState<any>()

  const [addNewTenant, setAddNewTenant] = useState<any>(false)

  const getAllPayments = () => {
    let newId = localStorage.getItem('newTenancyID')
    let tenancyIDs: any = []
    if (flg == '1') tenancyIDs[0] = newId
    if (flg == '2' || flg === '3') tenancyIDs[0] = tenancyId

    const body = {
      limit: 20,
      page: page,
      tenancyIds: tenancyIDs,
    }
    if (flg === '3')
      ApiPost(`corporate/payment/get?isCloneRecord=true`, body)
        .then((res) => {
          setPayments(res?.data?.data?.payment_data)
          setPageLimit(res?.data?.data?.state?.page_limit)
          for (let i = 0; i < res?.data?.data?.payment_data.length; i++) {
            let values = [...show]
            values[i] = false
            setShow(values)
          }
        })
        .catch((err) => console.log('err', err))
    else
      ApiPost(`corporate/payment/get`, body)
        .then((res) => {
          let values: any = []

          let temp: any = []

          for (let i = 0; i < res?.data?.data?.payment_data?.length; i++) {
            values[i] = {
              payment: {
                _id: res?.data?.data?.payment_data?.[i]?._id,
                name: res?.data?.data?.payment_data?.[i]?.name,
                note: res?.data?.data?.payment_data?.[i]?.note,
                currency: 'AED',
                paymentStatus: res?.data?.data?.payment_data?.[i]?.paymentStatus,
                categories: res?.data?.data?.payment_data?.[i]?.categories,
                amountPaid: parseFloat(res?.data?.data?.payment_data?.[i]?.amountPaid),
                outstandingAmount: res?.data?.data?.payment_data?.[i]?.outstandingAmount,
                subTotalAmount: res?.data?.data?.payment_data?.[i]?.subTotalAmount,
                totalVAT: res?.data?.data?.payment_data?.[i]?.totalVAT,
                totalAmount: res?.data?.data?.payment_data?.[i]?.totalAmount,
                // paymentType: 0,
                paymentMethod: res?.data?.data?.payment_data?.[i]?.paymentMethod,
                paymentAccountId: res?.data?.data?.payment_data?.[i]?.paymentAccountId,
                chequeBankName: res?.data?.data?.payment_data?.[i]?.chequeBankName,
                isCardVerificationEnable:
                  res?.data?.data?.payment_data?.[i]?.isCardVerificationEnable,
                dueDate: res?.data?.data?.payment_data?.[i]?.dueDate,
                attachments: res?.data?.data?.payment_data?.[i]?.attachments,
                chequeImages: res?.data?.data?.payment_data?.[i]?.chequeImages,
                tenancyId: tenancyId,
                unitId: unitDetails?._id,
                clusterId: unitDetails?.clusterId,
                unitGroupId: unitDetails?.unitGroupId,
                communityId: unitDetails?.communityId,
                floorId: unitDetails?.floorId,
                buildingId: unitDetails?.buildingId,
              },
              // new_payment_sub_item: payment,
              // new_payment_invoice: payInv,
              // new_payment_receipt: prec,
              // new_payment_reminder: remindersSched,
            }
            for (let j = 0; j < res?.data?.data?.payment_data?.[i]?.payment_sub_item?.length; j++) {
              temp[j] = {
                _id: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?._id,
                name: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?.name,
                amount: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?.amount,
                quantity: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?.quantity,
                isLateItem: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]
                  ?.isLatePaymentFeesRecord
                  ? true
                  : false,
              }
            }
            values[i].new_payment_sub_item = temp
          }
          console.log(values)
          setIncomePaymentPayload(values)
          setPageLimit(res?.data?.data?.state?.page_limit)
          for (let i = 0; i < res?.data?.data?.payment_data.length; i++) {
            let values = [...show]
            values[i] = false
            setShow(values)
          }
        })
        .catch((err) => console.log('err', err))
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const getPaymentById = async (id: any) => {
    ApiGet(`corporate/payment/${id}`)
      .then(async (res) => {
        setDataToEdit(res?.data?.data)

        await ApiGet(`corporate/unit/${res?.data?.data?.unitId}`)
          .then((res) => {
            setUnitDetails(res?.data?.data)
            setEditIncomeModal(true)
          })
          .catch((err) => console.log('err', err))
      })
      .catch((err) => console.log('err', err))
  }

  const [randomDatabaseId, setRandomDatabaseId] = useState<any>()
  useEffect(() => {
    setRandomDatabaseId(new Date().getTime())

    if (flg == '1') {
      // let newId = localStorage.getItem('newTenancyID')
      // setNewTenancyID(newId)
      // let newTenantId = localStorage.getItem('newTenantID')
    } else {
      getSubTenants(newTenantId)
    }
  }, [])

  let fileLists: any = []

  const onFilesAdded = async (event: any, type: any) => {
    setFileFound(false)
    const files = event.target.files
    fileLists = Array.from(files)
    setSelectedUploadFile({files: fileLists, type: type})
    fileLists.forEach((element: any, i: any) => {
      setFinalFileUpload((prev: any) => {
        if (prev[i]) {
          prev[i].index = i
          prev[i].fileName = type == 'tenancy' || type == 'tenant' ? '' : element.name
          prev[i].selctedfile = element
          prev[i].type = type
          return [...prev]
        } else {
          return [...prev, {index: i, fileName: '', selctedfile: element, type: type}]
        }
      })
    })
    if (type == 'tenancy' || type == 'tenant') {
      handleShow()
    } else {
      saveImageFileName()
    }
  }
  const uploadImageName = (e: any, selctedfile: any, i: any) => {
    setFinalFileUpload((prev: any) => {
      prev[i].fileName = e.target.value
      return [...prev]
    })
  }
  const getFilename = (file: any) => {
    return file.split('/').pop()
  }

  const getFilenameToSelect = (file: any, type: any) => {
    if (type) return file.split('.')[1]
    else return file.split('.')[0]
  }
  const saveImageFileName = async () => {
    setModalBtnIsLoading(true)
    setFileFound(false)
    for (let i = 0; i < finalFileUpload.length; i++) {
      let formData: any = new FormData()
      let data: any = finalFileUpload[i]
      let fileURL: any = URL.createObjectURL(data.selctedfile)
      let extensionType = data?.selctedfile?.name?.split('.')[1]
      let type: any = finalFileUpload[i].type

      data.fileURL = fileURL
      formData.append('image', data?.selctedfile)
      let fileType: any = ''

      switch (type) {
        case 'tenancy':
          fileType = 'tenancy_other'
          break
        case 'tenant':
          fileType = 'tenant_other'
          break
        default:
          break
      }

      // if (type == 'tenancy' || type == 'tenant') {
      await ApiPost(
        `upload/file_checker?fileType=${fileType}&databaseId=${randomDatabaseId}&fileName=${finalFileUpload[i]?.fileName}.${extensionType}`,
        formData
      )
        .then(async (res) => {
          if (res?.data?.data?.isFileExist) {
            setFileFound(true)
          } else {
            await ApiUpload(
              `upload/image/params?fileType=${fileType}&databaseId=${randomDatabaseId}&fileName=${finalFileUpload[i]?.fileName}.${extensionType}`,
              formData
            )
              .then((res) => {
                if (type == 'tenant') {
                  setuploadOtherImgTenant([...uploadOtherImgTenant, res?.data?.data?.image])
                } else if (type == 'tenancy') {
                  setuploadOtherImgTenancy([...uploadOtherImgTenancy, res?.data?.data?.image])
                }
                setIsFormDirty(true)
                handleClose()
              })
              .catch((err) => {
                console.log('res_blob', err)
                ErrorToast(err?.message)
              })

            if (type == 'tenancy') {
              setTimeout(() => {
                autoSaveTenancy()
              }, 2000)
            } else {
              setTimeout(() => {
                autoSaveTenant(0, newTenantId)
              }, 2000)
            }
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
          setModalBtnIsLoading(false)
        })
    }
  }

  let fileLists_single: any = []

  const onFilesAddedSingle = async (event: any, type: any, isTenancy: any) => {
    const files = event.target.files
    fileLists_single = Array.from(files)

    for (let i = 0; i < fileLists_single.length; i++) {
      let formData: any = new FormData()
      let data: any = fileLists_single[i]
      let fileURL: any = URL.createObjectURL(data)
      let filetypes: any = fileLists_single[i].type
      data.fileURL = fileURL
      formData.append('image', data)
      console.log('\ntype', filetypes)

      let url = ''
      if (type == 'tenant') {
        url = 'upload/image/tenant_other'
      } else if (type == 'tenancy') {
        url = 'upload/image/tenancy_other'
      } else if (type == 'uploadId') {
        url = 'upload/image/tenant_id'
      } else if (type == 'uploadResidency') {
        url = 'upload/image/tenant_residency'
      } else if (type == 'uploadPassport') {
        url = 'upload/image/tenant_passport'
      } else if (type == 'uploadContract') {
        url = 'upload/image/tenancy_contract'
      }

      await ApiUpload(`${url}`, formData)
        .then((res) => {
          if (type == 'uploadId') {
            setuploadIdTenant([res?.data?.data?.image])
          } else if (type == 'uploadResidency') {
            setuploadresidencyTenant([res?.data?.data?.image])
          } else if (type == 'uploadPassport') {
            setuploadPassportTenant([res?.data?.data?.image])
          } else if (type == 'uploadContract') {
            setuploadContractTenancy([res?.data?.data?.image])
          }

          if (isTenancy) {
            setTimeout(() => {
              autoSaveTenancy()
            }, 2500)
          } else {
            setTimeout(() => {
              autoSaveTenant(0, newTenantId)
            }, 2500)
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    }
  }

  const handleClose = () => {
    setModalBtnIsLoading(false)
    setShowImageName(false)
  }
  const handleShow = () => setShowImageName(true)

  const deleteUploadImg = async (e: any, type: any, ele: any) => {
    const url = ele.replace(`${Bucket}`, '')
    await ApiPost('upload/delete_file', {url})
      .then(() => {
        if (type == 'tenant') {
          setuploadOtherImgTenant(uploadOtherImgTenant.filter((flg: any, j: any) => j !== e))
          setTimeout(() => {
            autoSaveTenant(0, newTenantId)
          }, 2500)
        } else if (type == 'tenancy') {
          setuploadOtherImgTenancy(uploadOtherImgTenancy.filter((flg: any, j: any) => j !== e))
          setTimeout(() => {
            autoSaveTenancy()
          }, 2500)
        } else if (type == 'uploadId') {
          setuploadIdTenant(uploadIdTenant.filter((flg: any, j: any) => j !== e))
          setTimeout(() => {
            autoSaveTenancy()
            autoSaveTenant(0, newTenantId)
          }, 2500)
        } else if (type == 'uploadResidency') {
          setuploadresidencyTenant(uploadresidencyTenant.filter((flg: any, j: any) => j !== e))
          setTimeout(() => {
            autoSaveTenant(0, newTenantId)
          }, 2500)
        } else if (type == 'uploadPassport') {
          setuploadPassportTenant(uploadPassportTenant.filter((flg: any, j: any) => j !== e))
          setTimeout(() => {
            autoSaveTenant(0, newTenantId)
          }, 2500)
        } else if (type == 'uploadContract') {
          setuploadContractTenancy(uploadContractTenancy.filter((flg: any, j: any) => j !== e))
          setTimeout(() => {
            autoSaveTenancy()
            autoSaveTenant(0, newTenantId)
          }, 2500)
        }
      })

      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const [newName, setnewName] = useState<any>()
  const [isEditImgName, setIsEditImgName] = useState<any>(false)
  const changeFileNameOther = async (type: any, index: any) => {
    let body: any
    if (isEditImgName) {
      if (type == 'tenancy') {
        body = {
          newFileName: newName + '.' + uploadOtherImgTenancy[index].split('.').pop(),
          image: uploadOtherImgTenancy[index],
        }
      } else if (type == 'tenant') {
        body = {
          newFileName: newName + '.' + uploadOtherImgTenant[index].split('.').pop(),
          image: uploadOtherImgTenant[index],
        }
      }
      console.log('\nbody', body)

      await ApiPost('upload/rename_file', body)
        .then((res) => {
          if (type == 'tenancy') {
            setEditName(false)
            // setuploadOtherImgTenancy([...uploadOtherImgTenancy, res?.data?.data?.image])
            const remove_index = uploadOtherImgTenancy.filter((el: any, i: any) => i !== index)
            setuploadOtherImgTenancy([...remove_index, res?.data?.data?.image])
          } else if (type == 'tenant') {
            setEditName(false)
            const remove_index = uploadOtherImgTenant.filter((el: any, i: any) => i !== index)
            setuploadOtherImgTenant([...remove_index, res?.data?.data?.image])
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    } else {
      setEditName(false)
    }
  }

  const handleNumber = (Phone: any, e: any) => {
    let CountryCode: any = e?.dialCode
    let PhoneNumber: any = Phone.split(CountryCode)[1]
    setFormData({
      ...formData,
      ['countryCode']: parseInt(CountryCode),
      ['phoneNumber']: parseInt(PhoneNumber),
    })
    setPhone(Phone)
  }

  const handleNumber1 = (Phone: any, e: any) => {
    let CountryCode: any = e.dialCode
    let PhoneNumber: any = Phone.split(CountryCode)[1]

    setSubTenant({
      ...subTenant,
      ['countryCode']: parseInt(CountryCode),
      ['phoneNumber']: parseInt(PhoneNumber),
    })
  }
  setTimeout(() => {
    setIsLoading(false)
  }, 5000)

  const varificationCodeValidation = () => {
    let errorMsg: any = {
      error: {},
      isValid: true,
    }

    // tenant
    // if (!uploadPassportTenant.length) {
    //   errorMsg.isValid = false
    //   errorMsg.error['passport'] = 'this field is Required'
    // }
    // if (!uploadresidencyTenant.length) {
    //   errorMsg.isValid = false
    //   errorMsg.error['residancy'] = 'this field is Required'
    // }
    // if (!uploadIdTenant.length) {
    //   errorMsg.isValid = false
    //   errorMsg.error['id'] = 'this field is Required'
    // }
    // if (!uploadOtherImgTenant.length) {
    //   errorMsg.isValid = false
    //   errorMsg.error['otherimgtenant'] = 'this field is Required'
    // }

    // tenancy
    // if (!uploadContractTenancy.length) {
    //   errorMsg.isValid = false
    //   errorMsg.error['contract'] = 'this field is Required'
    // }
    // if (!uploadOtherImgTenancy.length) {
    //   errorMsg.isValid = false
    //   errorMsg.error['otherimgtenancy'] = 'this field is Required'
    // }
    if (!unitData?._id) {
      errorMsg.isValid = false
      errorMsg.error['unitdata'] = 'This field is Required'
    }
    return errorMsg
  }

  const checkIsImage = (fileUrl: any) => {
    if (fileUrl?.includes('blob')) {
      return true
    } else if (fileUrl?.match(/\.(jpg|jpeg|png|gif)$/i)) {
      return true
    }
    return false
  }

  const removeBucket = (url: any) => {
    if (typeof url === 'string') {
      return url.replaceAll(Bucket, '')
    } else {
      let urlData: any = []
      url?.forEach((element: any) => {
        urlData?.push(element?.replaceAll(Bucket, ''))
      })
      return urlData
    }
  }

  const onSubmitNewFlow = (data: any, event: any, isDraft: any) => {
    event.preventDefault()
    console.log(otherOptions)

    let temp: any = []
    for (let i = 0; i < otherOptions?.length; i++) {
      temp[i] = otherOptions[i]?.value
    }

    let valuesBenefit = {...optionArry}
    valuesBenefit.other = temp

    setOptionArry(valuesBenefit)

    // console.log(temp)

    // setOptionArry(temp)

    console.log(optionArry)

    setIsLoading(true)

    let subIds: any = []
    let j: any = 0
    for (let i = 0; i < subTenantData?.length; i++) {
      if (subTenantData[i]?.id != '') {
        subIds[j] = subTenantData[i]?.id
        j++
      }
    }

    let validationCall: any = varificationCodeValidation()
    setImgError(validationCall.error)

    let benifit_optionArray: any = optionArry

    if (flg == '2' || flg == '3') {
      delete optionArry._id
      benifit_optionArray = optionArry
    }

    if (validationCall.isValid) {
      // terminate tenancy
      if (flg == '3') {
        const body = {
          id: tenancyData?.tenantRequestId,
          status: 1,
        }
        ApiPut(`corporate/tenant_request/status`, body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            setIsLoading(false)

            setTimeout(() => {
              navigate(-1)
            }, 500)
          })
          .catch((err) => ErrorToast(err.message))
      } else if (flg == '2') {
        const body = {
          unitId: unitData?._id ? unitData?._id : null,
          mainTenantId: newTenantId,
          subTenantIds: subIds,
          subTenants: [],
          tenancy: {
            _id: tenancyId,
            isDraft: isDraft,
            contractNo: tenancyData?.contractNo,
            duration: {
              start_date: startDatePayload,
              end_date: endDatePayload,
              days: totalDays.split(' ')[0],
            },
            benefits: valuesBenefit,
            document: {
              contract:
                uploadContractTenancy?.[0] == null
                  ? null
                  : uploadContractTenancy?.length > 0
                  ? removeBucket(uploadContractTenancy[0])
                  : null,
              other: uploadOtherImgTenancy?.length
                ? removeBucket(uploadOtherImgTenancy)
                : removeBucket(formData?.tenancy?.document?.other),
              tenantPassport:
                uploadPassportTenant?.length > 0 ? removeBucket(uploadPassportTenant[0]) : null,
              tenantResidency:
                uploadresidencyTenant?.length > 0 ? removeBucket(uploadresidencyTenant[0]) : null,
              tenantId: uploadIdTenant?.length > 0 ? removeBucket(uploadIdTenant[0]) : null,
              tenantOther: uploadOtherImgTenant?.length
                ? removeBucket(uploadOtherImgTenant)
                : removeBucket(formData?.document?.other),
            },
          },
          paymentList: incomePaymentPayload,
        }
        ApiPost('corporate/tenancy/form/handler', body)
          .then((res) => {
            setIsFormDirty(false)
            setIsLoading(false)
            if (isDraft) {
              SuccessToast('Tenancy has been saved as DRAFT')
              localStorage.setItem('isDraft', '2')
              localStorage.setItem('BuildingId', unitData?.buildingId)
              localStorage.setItem('CommunityId', unitData?.communityId)
              localStorage.setItem('ClusterId', unitData?.clusterId)
              localStorage.setItem('UnitId', unitData?.unitId)

              // navigate(`/create-tenant/2/${res?.data?.data?._id}/${res?.data?.data?._id}`)

              setTimeout(() => {
                setIsSaveFirst(true)
              }, 2000)
            } else {
              SuccessToast(res?.data?.message)
              setTimeout(() => {
                navigate(-1)
              }, 1000)
            }
          })
          .catch((err) => ErrorToast(err.message))
      } else {
        const body = {
          unitId: unitData?._id ? unitData?._id : null,
          // mainTenant: {
          //   // // id: formData?._id,
          //   // idNo: formData?.idNo,
          //   // passportNo: formData?.passportNo,
          //   // otherNo: formData?.otherNo,
          //   // firstName: formData?.firstName,
          //   // lastName: formData?.lastName,
          //   // phoneNumber: formData?.phoneNumber,
          //   // countryCode: formData?.countryCode,
          //   // email: formData?.email,
          //   // nationality: formData?.nationality,
          //   // DOB: formData?.DOB,
          //   // folder_list: tenantFolders,
          // },
          mainTenantId: newTenantId,
          // subTenantIds: subTenantIds,
          subTenantIds: subIds,
          subTenants: [],
          tenancy: {
            isDraft: isDraft,
            contractNo: tenancyData?.contractNo,
            duration: {
              start_date: startDatePayload,
              end_date: endDatePayload,
              days: totalDays.split(' ')[0],
            },
            benefits: valuesBenefit,
            document: {
              contract:
                uploadContractTenancy?.length > 0 ? removeBucket(uploadContractTenancy[0]) : null,
              other: uploadOtherImgTenancy?.length
                ? removeBucket(uploadOtherImgTenancy)
                : removeBucket(formData?.tenancy?.document?.other),
              tenantPassport:
                uploadPassportTenant?.length > 0 ? removeBucket(uploadPassportTenant[0]) : null,
              tenantResidency:
                uploadresidencyTenant?.length > 0 ? removeBucket(uploadresidencyTenant[0]) : null,
              tenantId: uploadIdTenant?.length > 0 ? removeBucket(uploadIdTenant[0]) : null,
              tenantOther: uploadOtherImgTenant?.length
                ? removeBucket(uploadOtherImgTenant)
                : removeBucket(formData?.document?.other),
            },
          },
          paymentList: incomePaymentPayload,
        }
        ApiPost('corporate/tenancy/form/handler', body)
          .then((res) => {
            setIsFormDirty(false)
            setIsLoading(false)
            if (isDraft) {
             
              SuccessToast('Tenancy has been saved as DRAFT')
              localStorage.setItem('isDraft', '2')
              localStorage.setItem('BuildingId', unitData?.buildingId)
              localStorage.setItem('CommunityId', unitData?.communityId)
              localStorage.setItem('ClusterId', unitData?.clusterId)
              localStorage.setItem('UnitId', unitData?.unitId)

              navigate(`/create-tenant/2/${res?.data?.data?._id}/${res?.data?.data?._id}`)

              setTimeout(() => {
                setIsSaveFirst(true)
              }, 2500)
            } else {
              SuccessToast(res?.data?.message)
              setTimeout(() => {
                navigate(-1)
              }, 1000)
            }
          })
          .catch((err) => ErrorToast(err.message))
      }

      // const body = {
      //   id: newTenancyID,
      //   isDraft: false,
      // }
      // ApiPut('corporate/tenancy', body)
      //   .then((res) => {
      //     SuccessToast(res?.data?.message)
      //     setIsLoading(false)

      //     setTimeout(() => {
      //       navigate(-1)
      //       //   navigate(`/${navigatePage}`)
      //     }, 1000)
      //   })
      //   .catch((err) => ErrorToast(err.message))
    }
  }

  const onSubmit = (data: any, event: any) => {
    event.preventDefault()
    setIsLoading(true)
    let validationCall: any = varificationCodeValidation()
    setImgError(validationCall.error)

    if (validationCall.isValid) {
      let benifit_optionArray: any = optionArry

      if (flg == '2' || flg == '3') {
        delete optionArry._id
        benifit_optionArray = optionArry
      }

      let body: any = {
        unitId: unitData?._id ? unitData?._id : null,

        isDraft: status_isDraft === '1',
        tenant: {
          isUpdate: isUpdate,
          firstName: formData?.firstName,
          lastName: formData?.lastName,
          phoneNumber: (formData?.phoneNumber).toString(),
          countryCode: (formData?.countryCode).toString(),
          email: formData?.email,
          nationality: formData?.nationality,
          tenantSource: formData?.tenantSource,
          DOB: formData?.DOB,
          document: {
            passport: uploadPassportTenant.length
              ? removeBucket(uploadPassportTenant[0])
              : removeBucket(formData?.document?.passport),
            residency: uploadresidencyTenant.length
              ? removeBucket(uploadresidencyTenant[0])
              : removeBucket(formData?.document?.residency),
            id: uploadIdTenant.length
              ? removeBucket(uploadIdTenant[0])
              : removeBucket(formData?.document?.id),
            other: uploadOtherImgTenant.length
              ? removeBucket(uploadOtherImgTenant)
              : removeBucket(formData?.document?.other),
          },
        },
        tenancy: {
          contractNo: tenancyData?.contractNo,
          duration: {
            start_date: start_date,
            end_date: end_date,
            days: totalDays.split(' ')[0],
          },
          benefits: benifit_optionArray,
          tenancyStatus: Number(tenancyData?.tenancyStatus),
          totalAmounts: totalamount,
          totalPayments: totalpayment,
          document: {
            contract: uploadContractTenancy.length
              ? removeBucket(uploadContractTenancy[0])
              : removeBucket(formData?.tenancy?.document?.contract),
            other: uploadOtherImgTenancy.length
              ? removeBucket(uploadOtherImgTenancy)
              : removeBucket(formData?.tenancy?.document?.other),
          },
        },
      }

      if (flg == '1') {
        // create tenancy tab save button and create button
        body.incomePayment = incomePaymentPayload
        body.communityId = unitData?.communityId || null
        body.buildingId = unitData?.buildingId || null
        body['subTenant'] = subTenantData.map((val: any) => ({
          ...val,
          phoneNumber: val.phoneNumber.toString(),
        }))
        body['subTenant'].map((el: any) => {
          delete el['registrationStatus']
          delete el['tenantSource']
          delete el['_id']
          return el
        })

        delete body['tenancy']['tenancyStatus']
        let new_createtenancyPayment = createtenancyPayment?.map((el: any) => {
          if (el.receiptDate == null) {
            delete el.receiptDate
          }
          if (el.paymentDate == null) {
            delete el.paymentDate
          }
          if (el.receiptURL == null) {
            delete el.receiptURL
          }
          return el
        })
        // body['payment'] = new_createtenancyPayment
        if (isUpdate) {
          body['tenant']['_id'] = changeid
        }
        console.log('\nbody', body)
        ApiPost('corporate/tenancy/form', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            // building()
            setTimeout(() => {
              navigate(-1)
            }, 1000)
            setIsLoading(false)
          })
          .catch((err) => ErrorToast(err.message))
      } else if (flg == '3') {
        // edit tenancy tab save button
        // delete body['tenancy']?.['benefits']?.['_id']
        delete body['unitId']
        delete body['isDraft']
        delete body['tenancy']['tenancyStatus']
        if (isUpdate) {
          body['tenant']['_id'] = changeid
        } else {
          body['tenant']['_id'] = id
        }
        body['tenancy']['_id'] = tenancyId
        body['tenancy']['isDraft'] = status_isDraft == '1'
        // subtenantdata
        let update_subTenant = subTenantData
          .filter((el: any) => el._id)
          .map((val: any) => ({
            ...val,
            phoneNumber: val.phoneNumber.toString(),
          }))
        body['updateSubTenant'] = update_subTenant
        body['deleteSubTenant'] = deleteSubtenantArr

        let new_subtenant: any = subTenantData
          .filter((el: any) => !el._id)
          .map((val: any) => ({
            ...val,
            phoneNumber: val.phoneNumber.toString(),
          }))
        body['newSubTenant'] = new_subtenant
        body['newSubTenant'].map((el: any) => {
          delete el['registrationStatus']
          delete el['tenantSource']
          delete el['_id']
          return el
        })
        body['deleteSubTenant'].map((el: any) => {
          delete el['registrationStatus']
          delete el['tenantSource']
          delete el['_id']
          return el
        })
        body['updateSubTenant'].map((el: any) => {
          delete el['registrationStatus']
          delete el['tenantSource']
          delete el['_id']
          return el
        })
        // paymentdata
        body['deletePayment'] = deletePayment?.map((el: any) => {
          delete el.workSpaceId
          delete el.paymentDate
          delete el.tenantId
          delete el.tenancyId
          delete el.comment
          delete el.createdAt
          if (el.receiptDate == null) {
            delete el.receiptDate
          }
          if (el.paymentDate == null) {
            delete el.paymentDate
          }
          if (el.receiptURL == null) {
            delete el.receiptURL
          }
          return el
        })

        let new_defaultTenanvyPayment: any = []
        let defaultTenanvyPayment_data = tenancyPayment.filter(
          (u: any) => !tenancyPayment.some((n: any) => n._id === u._id)
        )
        let new_defaultTenanvy: any = [
          ...defaultTenanvyPayment_data,
          ...deletePayment,
          ...tenancyPayment,
        ]

        let updatedIdPayments: any = []
        new_defaultTenanvy.map((el: any) => {
          if (!el?._id) {
            if (el.receiptDate == null) {
              delete el.receiptDate
            }
            delete el.workSpaceId
            delete el.paymentDate
            delete el._id
            delete el.tenantId
            delete el.tenancyId
            delete el.comment
            delete el.createdAt
            if (el.receiptDate == null) {
              delete el.receiptDate
            }
            if (el.paymentDate == null) {
              delete el.paymentDate
            }
            if (el.receiptURL == null) {
              delete el.receiptURL
            }
            new_defaultTenanvyPayment.push(el)
          }
          if (updateIds.includes(el._id)) {
            updatedIdPayments.push(el)
          }
        })
        body['newPayment'] = new_defaultTenanvyPayment
        updatedIdPayments = updatedIdPayments.filter(
          (u: any) => !new_defaultTenanvyPayment.some((n: any) => n.paymentNo === u.paymentNo)
        )
        updatedIdPayments = [...updatedIdPayments, ...tenancyPayment]
        updatedIdPayments = updatedIdPayments.filter(
          (value: any, index: any, self: any) =>
            index === self.findIndex((t: any) => t._id === value._id)
        )
        body['updatePayment'] = updatedIdPayments
          ?.filter((item: any) => item._id)
          .map((el: any) => {
            delete el.workSpaceId
            delete el.paymentDate
            delete el.tenantId
            delete el.tenancyId
            delete el.comment
            delete el.createdAt
            if (el.receiptDate == null) {
              delete el.receiptDate
            }
            if (el.receiptURL == null) {
              delete el.receiptURL
            }
            if (el.paymentDate == null) {
              delete el.paymentDate
            }
            return el
            // if (el.paymentNo) {
            //   //   new_defaultTenanvyPayment.find((newel: any) => newel.paymentNo !== el.paymentNo)
            //   //   return el
            //   // }
            //   new_defaultTenanvyPayment = new_defaultTenanvyPayment.filter((item: any) => !item._id)
            // }
            // if (new_defaultTenanvyPayment.filter((el:any)=>el.paymentNo) {
            //   updatedIdPayments.push(el)
            // }
          })
        // body['subTenant']['_id'] = tenancyId
        // console.log('\nbody', body)
        ApiPut('corporate/tenancy/form', body)
          .then((res) => {
            SuccessToast(res?.data?.message)
            // building()
            setIsLoading(false)

            setTimeout(() => {
              // navigate(`/tenancies`)
              navigate(`/${navigatePage}`)
            }, 1000)
          })
          .catch((err) => ErrorToast(err.message))
      } else if (flg == '2') {
        if (comefromtenant == 'tenant') {
          body['tenantRequestId'] = requestorId
        } else {
          delete body['tenantRequestId']
        }
        // renew tenancy tab save button and renew button
        if (status_isDraft == '2') {
          delete body['tenancy']['benefits']['_id']
          delete body['tenancy']['tenancyStatus']
          body.communityId = unitData?.communityId || null
          body.buildingId = unitData?.buildingId || null
          delete body['tenantId']
          body['isDraft'] = status_isDraft == '1'
          // subtenantdata
          body['deleteSubTenant'] = deleteSubtenantArr
          body['newSubTenant'] = subTenantData
            .filter((el: any) => !el._id)
            .map((val: any) => ({
              ...val,
              phoneNumber: val.phoneNumber.toString(),
            }))
          let update_subTenant = subTenantData
            .filter((el: any) => el._id)
            .map((val: any) => ({
              ...val,
              phoneNumber: val.phoneNumber.toString(),
            }))
          body['updateSubTenant'] = update_subTenant

          body['newSubTenant'].map((el: any) => {
            delete el['registrationStatus']
            delete el['tenantSource']
            delete el['_id']
            return el
          })
          body['deleteSubTenant'].map((el: any) => {
            delete el['registrationStatus']
            delete el['tenantSource']
            delete el['_id']
            return el
          })
          body['updateSubTenant'].map((el: any) => {
            delete el['registrationStatus']
            delete el['tenantSource']
            delete el['_id']
            return el
          })
          // paymentdata
          let new_tenancyPayment = tenancyPayment?.map((el: any) => {
            if (el.receiptDate == null) {
              delete el.receiptDate
            }
            if (el.paymentDate == null) {
              delete el.paymentDate
            }
            if (el.receiptURL == null) {
              delete el.receiptURL
            }
            return el
          })
          if (isUpdate) {
            body['tenant']['_id'] = changeid
          } else {
            body['tenant']['_id'] = id
          }
          body['payment'] = new_tenancyPayment
          // console.log('\nbody post', body)
          ApiPost('corporate/tenancy/renew', body)
            .then((res) => {
              SuccessToast(res?.data?.message)
              setIsLoading(false)
              localStorage.setItem('isDraft', '1')
              setTimeout(() => {
                // navigate(`/tenancies`)
                navigate(`/${navigatePage}`)
              }, 1000)
            })
            .catch((err) => ErrorToast(err.message))
        } else {
          delete body['communityId']
          delete body['unitId']
          delete body['isDraft']
          delete body['buildingId']
          delete body['tenantId']
          delete body['subTenant']
          delete body['payment']

          if (isUpdate) {
            body['tenant']['_id'] = changeid
          } else {
            body['tenant']['_id'] = id
          }
          body['tenancy']['_id'] = tenancyId
          body['tenancy']['isDraft'] = status_isDraft == '1'
          // subtenantdata

          let update_subTenant = subTenantData
            .filter((el: any) => el._id)
            .map((val: any) => ({
              ...val,
              phoneNumber: val.phoneNumber.toString(),
            }))
          body['updateSubTenant'] = update_subTenant
          body['deleteSubTenant'] = deleteSubtenantArr

          let new_subtenant: any = subTenantData
            .filter((el: any) => !el._id)
            .map((val: any) => ({
              ...val,
              phoneNumber: val.phoneNumber.toString(),
            }))
          body['newSubTenant'] = new_subtenant
          body['newSubTenant'].map((el: any) => {
            delete el['registrationStatus']
            delete el['tenantSource']
            delete el['_id']
            return el
          })
          body['deleteSubTenant'].map((el: any) => {
            delete el['registrationStatus']
            delete el['tenantSource']
            delete el['_id']
            return el
          })
          body['updateSubTenant'].map((el: any) => {
            delete el['registrationStatus']
            delete el['tenantSource']
            delete el['_id']
            return el
          })
          // paymentdata
          body['deletePayment'] = deletePayment?.map((el: any) => {
            delete el.workSpaceId
            delete el.paymentDate
            delete el.tenantId
            delete el.tenancyId
            delete el.comment
            delete el.createdAt
            if (el.receiptDate == null) {
              delete el.receiptDate
            }
            if (el.receiptURL == null) {
              delete el.receiptURL
            }
            if (el.paymentDate == null) {
              delete el.paymentDate
            }
            return el
          })

          let new_defaultTenanvyPayment: any = []
          let defaultTenanvyPayment_data = tenancyPayment.filter(
            (u: any) => !tenancyPayment.some((n: any) => n._id === u._id)
          )
          let new_defaultTenanvy: any = [
            ...defaultTenanvyPayment_data,
            ...deletePayment,
            ...tenancyPayment,
          ]

          let updatedIdPayments: any = []
          new_defaultTenanvy.map((el: any) => {
            if (!el?._id) {
              delete el.workSpaceId
              delete el.paymentDate
              delete el._id
              delete el.tenantId
              delete el.tenancyId
              delete el.comment
              delete el.createdAt
              if (el.receiptDate == null) {
                delete el.receiptDate
              }
              if (el.paymentDate == null) {
                delete el.paymentDate
              }
              if (el.receiptURL == null) {
                delete el.receiptURL
              }
              new_defaultTenanvyPayment.push(el)
            }
            if (updateIds.includes(el._id)) {
              updatedIdPayments.push(el)
            }
          })
          body['newPayment'] = new_defaultTenanvyPayment
          updatedIdPayments = updatedIdPayments.filter(
            (u: any) => !new_defaultTenanvyPayment.some((n: any) => n.paymentNo === u.paymentNo)
          )
          body['updatePayment'] = updatedIdPayments
            ?.filter((item: any) => item._id)
            .map((el: any) => {
              delete el.workSpaceId
              delete el.paymentDate
              delete el.tenantId
              delete el.tenancyId
              delete el.comment
              delete el.createdAt
              if (el.receiptDate == null) {
                delete el.receiptDate
              }
              if (el.receiptURL == null) {
                delete el.receiptURL
              }
              if (el.paymentDate == null) {
                delete el.paymentDate
              }
              return el
            })

          // console.log('\nbody put', body)
          ApiPut('corporate/tenancy/form', body)
            .then((res) => {
              SuccessToast(res?.data?.message)
              setIsLoading(false)

              setTimeout(() => {
                // navigate(-1)
                navigate(`/${navigatePage}`)
              }, 1000)
            })
            .catch((err) => ErrorToast(err.message))
        }
      }
      // localStorage.setItem('isDraft', '1')
    }
  }

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(res?.data?.data)
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  const navigate = useNavigate()
  // useEffect(() => {
  //   arry.map((a: any, i: any) => {
  //     if (i === modelindex) {
  //       const payedAmount = +a.amount - +popUpData?.amountPaid;
  //       setoutstandingBalance(payedAmount)
  //     }
  //   })
  // }, [popUpData])
  // useEffect(() => {
  //   setpopUpData({ ...popUpData, outstandingBalance: outstandingBalance })
  // }, [outstandingBalance])

  const getTenantData = () => {
    let id: any = localStorage.getItem('newTenancyID')
    if (flg === '2' || flg === '3') id = tenancyId
    if (flg == '3')
      ApiGet(`corporate/tenant?isNullFilter=true&isCloneRecord=true`)
        // ApiGet(`corporate/tenant/tenancy_record?tenancyId=${id}&isCloneRecord=true`)
        .then((res) => {
          setTenantData(res?.data?.data)
        })
        .catch((err) => console.log('err', err))
    // ApiGet(`corporate/tenant/tenancy_record?tenancyId=${id}`)
    else
      ApiGet(`corporate/tenant?isNullFilter=true`)
        .then((res) => {
          setTenantData(res?.data?.data)
        })
        .catch((err) => console.log('err', err))
  }
  const [changeid, setchangeid] = useState<any>(null)
  const [isUpdate, setIsUpdate] = useState<any>(false)
  const [subTenantId, setSubTenantId] = useState<any>('')
  const [editTenant, setEditTenant] = useState<any>(-1)
  const [tenantFolders, setTenantFolders] = useState<any>([])
  const [residencyFolder, setResidencyFolder] = useState<any>()
  const [passportFolder, setPassportFolder] = useState<any>()
  const [iDsFolder, setIDsFolder] = useState<any>()
  const [contractsFolder, setContractsFolder] = useState<any>()
  const [dataToSelectFrom, setDataToSelectFrom] = useState<any>()
  const [folderFileModal, setFolderFileModal] = useState<any>(false)
  const [fileType, setFileType] = useState<any>()
  const [showBck, setShowBck] = useState<any>()
  const [selectedFolder, setSelectedFolder] = useState<any>()
  const [selectedTenantName, setSelectedTenantName] = useState<any>()

  var tenantName: any

  const handleChnageTenant = async (e: any) => {
    let isSel: any = false

    console.log(subTenantIds)

    for (let i = 0; i < subTenantIds?.length; i++) {
      if (e.target.value == subTenantIds?.[i]) {
        isSel = true
        i = subTenantIds?.length
      }
    }

    if (isSel) {
      ErrorToast('Tenant already Select as Sub-Tenant')
    } else {
      
      if (e.target.value == 'new') {
        // let newTenantId = localStorage.getItem('newTenantID')
        // setNewTenantID(newTenantId)
        setFormData([])
        setuploadresidencyTenant([])
        setuploadPassportTenant([])
        setuploadIdTenant([])
        setuploadOtherImgTenant([])
        setchangeid(null)

        // setTimeout(() => {
        //   getSubTenants(newTenantId)
        // }, 1500)
      } else {
        setIsUpdate(true)
        setchangeid(e.target.value)
        setNewTenantID(e.target.value)
        if (e.target.value) {
          await ApiGet(`corporate/tenant/${e.target.value}`)
            .then((res) => {
              setIsFormDirty(true)
              let temp = res?.data?.data?.firstName + res?.data?.data?.lastName
              setSelectedTenantName(temp)
              tenantName = temp

              setFormData(res?.data?.data)

              setuploadContractTenancy([])
              setuploadresidencyTenant([])
              setuploadPassportTenant([])
              setuploadIdTenant([])
              setuploadOtherImgTenant([])

              setTenantFolders(res?.data?.data?.tenant_folder)
              for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
                if (res?.data?.data?.tenant_folder?.[i].name == 'Passports')
                  setPassportFolder(res?.data?.data?.tenant_folder?.[i])
                else if (res?.data?.data?.tenant_folder?.[i].name == 'Contracts')
                  setContractsFolder(res?.data?.data?.tenant_folder?.[i])
                else if (res?.data?.data?.tenant_folder?.[i].name == 'IDs')
                  setIDsFolder(res?.data?.data?.tenant_folder?.[i])
                else if (res?.data?.data?.tenant_folder?.[i].name == 'Residencies')
                  setResidencyFolder(res?.data?.data?.tenant_folder?.[i])
              }

              // setStart(res?.data?.data?.tenancy?.duration?.start_date)
              // setEnd(res?.data?.data?.tenancy?.duration?.end_date)

              // setuploadresidencyTenant(
              //   res?.data?.data?.document?.residency ? [res?.data?.data?.document?.residency] : []
              // )
              // setuploadPassportTenant(
              //   res?.data?.data?.document?.passport ? [res?.data?.data?.document?.passport] : []
              // )
              // setuploadIdTenant(res?.data?.data?.document?.id ? [res?.data?.data?.document?.id] : [])
              // setuploadOtherImgTenant(
              //   res?.data?.data?.document?.other ? res?.data?.data?.document?.other : ''
              // )
              // console.log('\n res?.data?.data?.document?.other 1', res?.data?.data?.document?.other)
              // setTimeout(() => {
              //   getSubTenants(e.target.value)
              // }, 1500)
            })
            .catch((err) => console.log('err', err))
        }
      }
    }
  }

  const handleSelectSubTenant = async (e: any, ind: any) => {
    if (e.target.value == newTenantId) {
      ErrorToast('Tenant already Selected as Main Tenant')
    } else {
      const temp = [...subTenantData]
      temp[ind].id = e.target.value
      // setSubTenantId(e.target.value)
      if (e.target.value == 'new') {
        // let id = localStorage.getItem('newSubId')
        // setSubTenantId(id)
        // let values = {
        //   firstName: '',
        //   lastName: '',
        //   phoneNumber: '',
        //   countryCode: '',
        //   email: '',
        // }
        // setSubTenant(values)
      } else {
        if (e.target.value) {
          await ApiGet(`corporate/tenant/${e.target.value}`)
            .then((res) => {
              setIsFormDirty(true)
              temp[ind] = {
                firstName: res?.data?.data?.firstName ? res?.data?.data?.firstName : '-',
                lastName: res?.data?.data?.lastName ? res?.data?.data?.lastName : '-',
                phoneNumber: res?.data?.data?.phoneNumber ? res?.data?.data?.phoneNumber : '-',
                countryCode: res?.data?.data?.countryCode ? res?.data?.data?.countryCode : '-',
                email: res?.data?.data?.email ? res?.data?.data?.email : '-',
                id: res?.data?.data?._id,
              }
              setSubTenantData(temp)

              setSubTenantIds([...subTenantIds, res?.data?.data?._id])
            })
            .catch((err) => console.log('err', err))
        }
      }
    }
  }

  const createTenant = () => {
    ApiPost('corporate/tenant')
      .then((res) => {
        localStorage.setItem('newTenantID', res?.data?.data?._id)
        setNewTenantID(res?.data?.data?._id)
      })
      .catch((err: any) => ErrorToast(err.message))
  }

  const getTenancyData = (id: any) => {
    if (flg == '3')
      ApiGet(`corporate/tenancy/full_detail/${id}?isCloneRecord=true`)
        .then((res) => {
          // setNewTenantID(res?.data?.data?.[0]?.tenant?.[0]?._id)
          console.log('\nres.data.data', res.data.data)

          setuploadresidencyTenant(
            res?.data?.data[0]?.document?.tenantResidency
              ? [res?.data?.data[0]?.document?.tenantResidency]
              : null
          )
          setuploadPassportTenant(
            res?.data?.data[0]?.document?.tenantPassport
              ? [res?.data?.data[0]?.document?.tenantPassport]
              : null
          )
          setuploadIdTenant(
            res?.data?.data[0]?.document?.tenantId ? [res?.data?.data[0]?.document?.tenantId] : null
          )
          setuploadOtherImgTenant(
            res?.data?.data[0]?.document?.tenantOther && res?.data?.data[0]?.document?.tenantOther
          )

          if (res?.data?.data?.[0]?.tenant?.length > 0) {
            setFormData(res?.data?.data?.[0]?.tenant?.[0])
          }

          setNewTenancyID(res.data.data?.[0]?._id)
          setTenancyData(res?.data?.data[0])
          setTenancyPayment(res?.data?.data[0].tenancy_payment)

          // subTenant Data
          let values: any = []
          for (let i = 0; i < res?.data?.data?.[0].sub_tenant?.length; i++) {
            values[i] = {
              id: res?.data?.data?.[0].sub_tenant?.[i]?._id,
              firstName: res?.data?.data?.[0].sub_tenant?.[i]?.firstName,
              lastName: res?.data?.data?.[0].sub_tenant?.[i]?.lastName,
              email: res?.data?.data?.[0].sub_tenant?.[i]?.email,
              countryCode: `${res?.data?.data?.[0].sub_tenant?.[i]?.countryCode}`,
              phoneNumber: `${res?.data?.data?.[0].sub_tenant?.[i]?.phoneNumber}`,
            }
          }
          setSubTenantData(values)

          if (res?.data?.data[0]?.duration?.start_date != null) {
            setStart(moment(res?.data?.data[0]?.duration?.start_date).format('DD/MM/YYYY'))
            setStartDatePayload(
              moment(res?.data?.data[0]?.duration?.start_date).format('YYYY-MM-DD')
            )
          }

          if (res?.data?.data[0]?.duration?.end_date != null) {
            if (state?.terminationDate) {
              setEnd(moment(new Date(state?.terminationDate)).format('DD/MM/YYYY'))
              setEndDatePayload(moment(new Date(state?.terminationDate)).format('YYYY-MM-DD'))
            } else {
              setEnd(moment(res?.data?.data[0]?.duration?.end_date).format('DD/MM/YYYY'))
              setEndDatePayload(moment(res?.data?.data[0]?.duration?.end_date).format('YYYY-MM-DD'))
            }
          }

          setTotalDays(res?.data?.data[0]?.duration?.days + ' days')
          setuploadContractTenancy([res?.data?.data[0]?.document?.contract])
          if (res?.data?.data[0]?.document?.other == null) setuploadOtherImgTenancy([])
          else setuploadOtherImgTenancy(res?.data?.data[0]?.document?.other)

          setTenantFolders(res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder)
          for (let i = 0; i < res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.length; i++) {
            if (res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i].name == 'Passports')
              setPassportFolder(res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i])
            else if (res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i].name == 'Contracts')
              setContractsFolder(res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i])
            else if (res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i].name == 'IDs')
              setIDsFolder(res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i])
            else if (res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i].name == 'Residencies')
              setResidencyFolder(res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i])
          }

          // setuploadPassportTenant([res?.data?.data[0]?.tenant[0]?.document?.passport])
          // setuploadresidencyTenant([res?.data?.data[0]?.tenant[0]?.document?.residency])
          // setuploadIdTenant([res?.data?.data[0]?.tenant[0]?.document?.id])
          // setuploadOtherImgTenant(res?.data?.data[0]?.tenant[0]?.document?.other)
          let final_obj: any = {
            none: false,
            maintenanceFree: false,
            chillerFree: false,
            other: [],
            ...res?.data?.data[0]?.benefits,
          }
          setOptionArry(final_obj)

          let temp: any = []
          for (let i = 0; i < res?.data?.data[0]?.benefits?.other?.length; i++) {
            temp[i] = {
              value: res?.data?.data[0]?.benefits?.other?.[i],
            }
          }
          setOtherOptions(temp)
        })

        .catch((err) => console.log('err', err))
    else
      ApiGet(`corporate/tenancy/full_detail/${id}`)
        .then((res) => {
          console.log('\nres.data.data', res.data.data)

          // if (res?.data?.data?.[0]?.tenantId == null) createTenant()

          setuploadresidencyTenant(
            res?.data?.data[0]?.document?.tenantResidency
              ? [res?.data?.data[0]?.document?.tenantResidency]
              : null
          )
          setuploadPassportTenant(
            res?.data?.data[0]?.document?.tenantPassport
              ? [res?.data?.data[0]?.document?.tenantPassport]
              : null
          )
          setuploadIdTenant(
            res?.data?.data[0]?.document?.tenantId ? [res?.data?.data[0]?.document?.tenantId] : null
          )
          setuploadOtherImgTenant(
            res?.data?.data[0]?.document?.tenantOther && res?.data?.data[0]?.document?.tenantOther
          )

          if (res?.data?.data?.[0]?.tenant?.length > 0) {
            setFormData(res?.data?.data?.[0]?.tenant?.[0])
          }

          if (res?.data?.data?.[0]?.tenant?.length > 0) {
            setNewTenantID(res?.data?.data?.[0]?.tenant?.[0]?._id)
            console.log(res?.data?.data?.[0]?.tenant?.length)
            setFormData(res?.data?.data?.[0]?.tenant?.[0])
          }

          setTenantFolders(res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder)
          for (let i = 0; i < res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.length; i++) {
            if (res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i].name == 'Passports')
              setPassportFolder(res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i])
            else if (res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i].name == 'Contracts')
              setContractsFolder(res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i])
            else if (res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i].name == 'IDs')
              setIDsFolder(res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i])
            else if (res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i].name == 'Residencies')
              setResidencyFolder(res?.data?.data?.[0]?.tenant?.[0]?.tenant_folder?.[i])
          }

          setNewTenancyID(res.data.data?.[0]?._id)
          setTenancyData(res?.data?.data[0])
          setTenancyPayment(res?.data?.data[0].tenancy_payment)

          // SubTenant Data
          let values: any = []
          for (let i = 0; i < res?.data?.data?.[0].sub_tenant?.length; i++) {
            values[i] = {
              id: res?.data?.data?.[0].sub_tenant?.[i]?._id,
              firstName: res?.data?.data?.[0].sub_tenant?.[i]?.firstName,
              lastName: res?.data?.data?.[0].sub_tenant?.[i]?.lastName,
              email: res?.data?.data?.[0].sub_tenant?.[i]?.email,
              countryCode: `${res?.data?.data?.[0].sub_tenant?.[i]?.countryCode}`,
              phoneNumber: `${res?.data?.data?.[0].sub_tenant?.[i]?.phoneNumber}`,
            }
          }
          setSubTenantData(values)

          if (res?.data?.data[0]?.duration?.start_date != null) {
            setStart(moment(res?.data?.data[0]?.duration?.start_date).format('DD/MM/YYYY'))
            setStartDatePayload(
              moment(res?.data?.data[0]?.duration?.start_date).format('YYYY-MM-DD')
            )
          }

          if (res?.data?.data[0]?.duration?.end_date != null) {
            if (state?.terminationDate) {
              setEnd(moment(new Date(state?.terminationDate)).format('DD/MM/YYYY'))
              setEndDatePayload(moment(new Date(state?.terminationDate)).format('YYYY-MM-DD'))
            } else {
              setEnd(moment(res?.data?.data[0]?.duration?.end_date).format('DD/MM/YYYY'))
              setEndDatePayload(moment(res?.data?.data[0]?.duration?.end_date).format('YYYY-MM-DD'))
            }
          }

          setTotalDays(res?.data?.data[0]?.duration?.days + ' days')
          setuploadContractTenancy([res?.data?.data[0]?.document?.contract])
          if (res?.data?.data[0]?.document?.other == null) setuploadOtherImgTenancy([])
          else setuploadOtherImgTenancy(res?.data?.data[0]?.document?.other)
          setNewTenantID(res?.data?.data?.[0]?.tenantId)

          // setuploadPassportTenant([res?.data?.data[0]?.tenant[0]?.document?.passport])
          // setuploadresidencyTenant([res?.data?.data[0]?.tenant[0]?.document?.residency])
          // setuploadIdTenant([res?.data?.data[0]?.tenant[0]?.document?.id])
          // setuploadOtherImgTenant(res?.data?.data[0]?.tenant[0]?.document?.other)
          let final_obj: any = {
            none: false,
            maintenanceFree: false,
            chillerFree: false,
            other: [],
            ...res?.data?.data[0]?.benefits,
          }
          setOptionArry(final_obj)

          let temp: any = []
          for (let i = 0; i < res?.data?.data[0]?.benefits?.other?.length; i++) {
            temp[i] = {
              value: res?.data?.data[0]?.benefits?.other?.[i],
            }
          }
          setOtherOptions(temp)
        })

        .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    if (flg == '2' || flg == '3') {
      setSendError(false)
      getTenancyData(tenancyId)
      getAllPayments()
    }
    getTenantData()
    getUbitDataById()
    getCountry()
  }, [])

  const fetchTenant = async (id: any) => {
    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        setFormData(res?.data?.data)
        setuploadresidencyTenant([res?.data?.data?.document?.residency])
        setuploadPassportTenant([res?.data?.data?.document?.passport])
        setuploadIdTenant([res?.data?.data?.document?.id])
        setuploadOtherImgTenant(res?.data?.data?.document?.other)
        console.log('\nres?.data?.data?.document?.other 2', res?.data?.data?.document?.other)
      })
      .catch((err) => console.log('err', err))
  }
  React.useEffect(() => {
    reset(formData)
  }, [formData])

  const getImage: any = () => {
    if (unitData?.images?.length > 0 && unitData?.images[0].length > 0) {
      return <img src={`${Bucket}${unitData?.images[0]}`} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'town_house') {
      return <img src={townhouse} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'other') {
      return <img src={other} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'common_area') {
      return <img src={common_area} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'villa') {
      return <img src={villa} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'apartment') {
      return <img src={appartment} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'penthouse') {
      return <img src={penthouse} alt='' width={170} height={150} />
    }
  }

  const total: any = tenancyPayment?.reduce((acc: any, obj: any) => {
    return acc + obj.amount
  }, 0)

  const [disabledBtn, setDisabledBtn] = useState<any>(true)
  const [incomeModal, setIncomeModal] = useState<any>(false)
  const [tenantDetails, setTenantDetails] = useState<any>()
  const [incomePaymentPayload, setIncomePaymentPayload] = useState<any>([])

  const [filteredPaymentItems, setFilteredPaymentItems] = useState<any>([
    {value: '0', label: 'Booking'},
    {value: '1', label: 'Maintenance'},
    {value: '2', label: 'Rent'},
  ])

  const [show, setShow] = useState<any>([])

  const getPaymentItemsList = () => {
    console.log(unitData)
    if (unitData?.communityId !== undefined && unitData?.communityId !== null) {
      ApiGet(`corporate/payment_item_list/payment?communityId=` + unitData.communityId)
        .then((res) => {
          setFilteredPaymentItems(res?.data?.data)
        })
        .catch((err) => console.log('err', err))
    } else if (unitData?.buildingId !== undefined && unitData?.buildingId !== null) {
      ApiGet(`corporate/payment_item_list/payment?buildingId=` + unitData.buildingId)
        .then((res) => {
          setFilteredPaymentItems(res?.data?.data)
        })
        .catch((err) => console.log('err', err))
    }
  }

  const updateIncomePaymentPayload = (val: any, from: any, ind: any) => {
    const values = [...incomePaymentPayload]
    if (from == 'add') values[values.length] = val
    else values[ind] = val

    setIncomePaymentPayload(values)

    setIsFormDirty(true)

    for (let i = 0; i < values.length; i++) {
      let values = [...show]
      values[i] = false
      setShow(values)
    }
  }

  const tableRef = useRef<HTMLTableElement | null>(null)

  const getPositive = (number: any) => {
    // if number is less than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const handleMouseWheel = (e: React.WheelEvent) => {
    if (tableRef.current) {
      if (isMouseOverTable(e, tableRef.current)) {
        e.preventDefault()
        tableRef.current.scrollLeft += e.deltaY
      }
    }
  }

  const isMouseOverTable = (e: React.WheelEvent, element: HTMLElement) => {
    // document.body.style.overflow = 'hidden';
    const rect = element.getBoundingClientRect()
    const mouseX = e.clientX - rect.left
    const mouseY = e.clientY - rect.top
    return mouseX >= 0 && mouseX <= rect.width && mouseY >= 0 && mouseY <= rect.height
  }

  const disableScrolling = () => {
    document.body.style.overflow = 'hidden'
  }

  const enableScrolling = () => {
    document.body.style.overflow = '' // Reset to default
  }

  const handleMouseEnter = (event: any) => {
    disableScrolling()
  }

  const handleMouseLeave = () => {
    enableScrolling()
  }

  const autoSaveTenancy = () => {
    // console.log(uploadContractTenancy?.[0])
    let benifit_optionArray: any = optionArry

    if (flg == '2' || flg == '3') {
      delete optionArry._id
      benifit_optionArray = optionArry
      optionArryPayload = optionArry
    }

    const body = {
      id: flg == '1' ? newTenancyID : tenancyId,
      contractNo: tenancyData?.contractNo,
      duration: {
        start_date: start_date,
        end_date: end_date,
        days: totalDays?.split(' ')[0],
        _id: newTenancyData?.duration?._id,
      },
      document: {
        contract:
          uploadContractTenancy?.[0] === null
            ? null
            : uploadContractTenancy?.length > 0
            ? removeBucket(uploadContractTenancy[0])
            : uploadContractTenancy?.length === 0
            ? null
            : null,
        other: uploadOtherImgTenancy?.length > 0 ? removeBucket(uploadOtherImgTenancy) : [],
        tenantPassport:
          uploadPassportTenant?.length > 0 ? removeBucket(uploadPassportTenant[0]) : null,
        tenantResidency:
          uploadresidencyTenant?.length > 0 ? removeBucket(uploadresidencyTenant[0]) : null,
        tenantId: uploadIdTenant?.length > 0 ? removeBucket(uploadIdTenant[0]) : null,
        tenantOther: uploadOtherImgTenant?.length > 0 ? removeBucket(uploadOtherImgTenant) : null,
        _id: newTenancyData?.document?._id,
      },
      benefits: optionArryPayload,
      isDraft: true,
      tenancyStatus: tenancyData?.tenancyStatus != null ? parseInt(tenancyData?.tenancyStatus) : 0,
      unitId: unitData?._id,
      communityId: unitData?.communityId,
      tenantId: flg == '3' ? tenancyData?.tenant?.[0]?._id : newTenantId,
      buildingId: unitData?.buildingId,
      tenantSource: formData?.tenantSource != null ? parseInt(formData?.tenantSource) : 0,
      subTenantIds: subTenantIds,
    }

    // if (flg == '3')
    //   ApiPut('corporate/tenancy?isCloneRecord=true', body)
    //     .then((res) => {})
    //     .catch((err) => ErrorToast(err.message))
    // else
    //   ApiPut('corporate/tenancy', body)
    //     .then((res) => {})
    //     .catch((err) => ErrorToast(err.message))
  }

  const autoSaveTenant = (type: any, id: any) => {
    let body: any
    if (type == 1) {
      body = {
        id: id,
        // firstName: subTenant?.firstName ? subTenant?.firstName : null,
        // lastName: subTenant?.lastName ? subTenant?.lastName : null,
        // phoneNumber: subTenant?.phoneNumber?.toString() ? subTenant?.phoneNumber?.toString() : null,
        // countryCode: subTenant?.countryCode?.toString() ? subTenant?.countryCode?.toString() : null,
        // email: subTenant?.email ? subTenant?.email : null,
        tenantType: type,
        unitId: unitData?._id,
        tenantId: flg == '3' ? tenancyData?.tenant?.[0]?._id : newTenantId,
        communityId: unitData?.communityId,
        buildingId: unitData?.buildingId,
        tenancyId: flg == '1' ? newTenancyID : tenancyId,
      }
    } else if (formData?.DOB == null) {
      body = {
        id: flg == '3' ? tenancyData?.tenant?.[0]?._id : id,
        // firstName: formData?.firstName ? formData?.firstName : null,
        // lastName: formData?.lastName ? formData?.lastName : null,
        // phoneNumber: formData?.phoneNumber?.toString() ? formData?.phoneNumber?.toString() : null,
        // countryCode: formData?.countryCode?.toString() ? formData?.countryCode?.toString() : null,
        // email: formData?.email ? formData?.email : null,
        // nationality: formData?.nationality ? formData?.nationality : null,
        // tenantSource: formData?.tenantSource != null ? parseInt(formData?.tenantSource) : 0,
        // document: {
        //   passport: uploadPassportTenant?.length > 0 ? removeBucket(uploadPassportTenant[0]) : null,
        //   residency:
        //     uploadresidencyTenant?.length > 0 ? removeBucket(uploadresidencyTenant[0]) : null,
        //   id: uploadIdTenant?.length > 0 ? removeBucket(uploadIdTenant[0]) : null,
        //   other: uploadOtherImgTenant?.length > 0 ? removeBucket(uploadOtherImgTenant) : null,
        // },
        tenantType: type,
        unitId: unitData?._id,
        tenantId: null,
        communityId: unitData?.communityId,
        buildingId: unitData?.buildingId,
        tenancyId: flg == '1' ? newTenancyID : tenancyId,
      }
    } else {
      body = {
        id: flg == '3' ? tenancyData?.tenant?.[0]?._id : id,
        // firstName: formData?.firstName ? formData?.firstName : null,
        // lastName: formData?.lastName ? formData?.lastName : null,
        // phoneNumber: formData?.phoneNumber?.toString() ? formData?.phoneNumber?.toString() : null,
        // countryCode: formData?.countryCode?.toString() ? formData?.countryCode?.toString() : null,
        // email: formData?.email ? formData?.email : null,
        // nationality: formData?.nationality ? formData?.nationality : null,
        // tenantSource: formData?.tenantSource != null ? parseInt(formData?.tenantSource) : 0,
        // DOB: formData?.DOB,
        // document: {
        //   passport: uploadPassportTenant?.length > 0 ? removeBucket(uploadPassportTenant[0]) : null,
        //   residency:
        //     uploadresidencyTenant?.length > 0 ? removeBucket(uploadresidencyTenant[0]) : null,
        //   id: uploadIdTenant?.length > 0 ? removeBucket(uploadIdTenant[0]) : null,
        //   other: uploadOtherImgTenant?.length > 0 ? removeBucket(uploadOtherImgTenant) : null,
        // },
        tenantType: type,
        unitId: unitData?._id,
        tenantId: null,
        communityId: unitData?.communityId,
        buildingId: unitData?.buildingId,
        tenancyId: flg == '1' ? newTenancyID : tenancyId,
      }
    }
    if (flg == '3')
      ApiPut('corporate/tenant?isCloneRecord=true', body)
        .then((res) => {})
        .catch((err) => ErrorToast(err.message))
    else
      ApiPut('corporate/tenant', body)
        .then((res) => {})
        .catch((err) => ErrorToast(err.message))
  }

  const deleteTenant = (id: any, type: any) => {
    ApiDelete(`corporate/tenant/${id}`)
      .then((response) => {
        if (type == 0) setCreate(false)
        else {
          SuccessToast(response?.data?.message)
          getSubTenants(newTenantId)
        }
        getTenantData()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  //
  const updateSubTenant = (subTen: any) => {
    setNewTenantID(subTen?.tenantId)
    const body = {
      id: subTen?._id,
      firstName: subTen?.firstName ? subTen?.firstName : null,
      lastName: subTen?.lastName ? subTen?.lastName : null,
      phoneNumber: subTen?.phoneNumber?.toString() ? subTen?.phoneNumber?.toString() : null,
      countryCode: subTen?.countryCode?.toString() ? subTen?.countryCode?.toString() : null,
      email: subTen?.email ? subTen?.email : null,
      tenantType: subTen?.tenantType,
      tenantId: subTen?.tenantId,
    }

    if (flg == '3')
      ApiPut('corporate/tenant?isCloneRecord=true', body)
        .then((res) => {
          setTimeout(() => {
            getSubTenants(subTen?.tenantId)
            setEditTenant(-1)
          }, 1000)
        })
        .catch((err) => ErrorToast(err.message))
    else
      ApiPut('corporate/tenant', body)
        .then((res) => {
          setTimeout(() => {
            getSubTenants(subTen?.tenantId)
            setEditTenant(-1)
          }, 1000)
        })
        .catch((err) => ErrorToast(err.message))
  }

  //
  const getSubTenants = (id: any) => {
    let body: any

    if (flg == '1') {
      let newId = localStorage.getItem('newTenancyID')
      body = {
        page: page,
        limit: 10,
        mainTenantId: '',
        tenancyId: newId,
      }
    } else {
      body = {
        page: page,
        limit: 10,
        mainTenantId: '',
        tenancyId: tenancyId,
      }
    }

    if (flg == '3')
      ApiPost('corporate/tenant/sub_tenant/get?isCloneRecord=true', body)
        .then((res) => {
          let values: any = []
          for (let i = 0; i < subTenantData?.length; i++) {
            values[i] = {
              id: res?.data?.data?.tenant_data?.[i]?._id,
              firstName: res?.data?.data?.tenant_data?.[i]?.firstName,
              lastName: res?.data?.data?.tenant_data?.[i]?.lastName,
              email: res?.data?.data?.tenant_data?.[i]?.email,
              mobileNo: res?.data?.data?.tenant_data?.[i]?.mobileNo,
            }
          }
          setSubTenantData(values)
        })
        .catch((err) => ErrorToast(err.message))
    else
      ApiPost('corporate/tenant/sub_tenant/get', body)
        .then((res) => {
          let values: any = []
          for (let i = 0; i < subTenantData?.length; i++) {
            values[i] = {
              id: res?.data?.data?.tenant_data?.[i]?._id,
              firstName: res?.data?.data?.tenant_data?.[i]?.firstName,
              lastName: res?.data?.data?.tenant_data?.[i]?.lastName,
              email: res?.data?.data?.tenant_data?.[i]?.email,
              mobileNo: res?.data?.data?.tenant_data?.[i]?.mobileNo,
            }
          }
          setSubTenantData(values)
        })
        .catch((err) => ErrorToast(err.message))
  }

  const cancelTenancy = () => {
    ApiGet(`corporate/tenancy/cancel/${tenancyId}`)
      .then((response: any) => {
        SuccessToast(response?.data?.message)
        navigate(-1)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  // useEffect(() => {
  //   if(flg != '1')
  //   getAllPayments()
  // }, [page])

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)

  useEffect(() => {
    if (isSaveFirst == true) {
      // if (flg == '2' || flg == '3') {
      setSendError(false)
      getTenancyData(tenancyId)
      // }
      getTenantData()
      // getSubTenants(tenancyId)
      getAllPayments()
    }
  }, [isSaveFirst])

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (isFormDirty) {
        e.preventDefault()
        e.returnValue = 'You have unsaved changes. Are you sure you want to leave this page'
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isFormDirty])

  return (
    <>
      <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
        <form onSubmit={handleSubmit((data: any, e: any) => onSubmitNewFlow(formData, e, false))}>
          <div id='' className='container-xxl pt-0 mt-0'>
            {/* <div className='d-flex align-items-center flex-wrap mr-1 '>
              {window.location.pathname === '/create-tenant' ? (
                <div className='d-flex align-items-baseline flex-wrap mr-5'>
                  <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
                    <li className='breadcrumb-item '>
                      <a
                        className='text-muted px-2 cursor-pointer'
                        onClick={() => navigate('/tenants')}
                      >
                        Tenant
                      </a>
                    </li>
                    <li className='breadcrumb-item active'>
                      <a className='px-2'>Create tenant</a>
                    </li>
                  </ul>
                </div>
              ) : (
                <div className='d-flex align-items-baseline flex-wrap mr-5'>
                  <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
                    <li className='breadcrumb-item '>
                      <a
                        className='text-muted px-2 cursor-pointer'
                        onClick={() => navigate('/tenancies')}
                      >
                        Tenancies
                      </a>
                    </li>
                    <li className='breadcrumb-item active'>
                      <a className='px-2'>Create Tenancies</a>
                    </li>
                  </ul>
                </div>
              )}
            </div> */}

            <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
              <div className='container-xxl d-flex flex-stack pt-1 mt-0 px-0'>
                <div className=''>
                  {flg == '3' ? (
                    <div className='d-flex align-items-center mb-1'>
                      <span
                        className='rounded-circle'
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          navigate(-1)
                        }}
                      >
                        <img src={backArrow} style={{stroke: 'red'}} height='16' width='16' />
                      </span>
                      <h1 className='m-0 head-text ms-2'>Tenancy Termination</h1>
                    </div>
                  ) : (
                    <div className='d-flex align-items-center mb-1'>
                      <span
                        className='rounded-circle'
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          if (
                            isFormDirty &&
                            !window.confirm(
                              'Changes that you made may not be saved ?'
                            )
                          ) {
                            return
                          }
                          navigate('/tenancies') // Change this to the route you want to navigate to
                        }}
                      >
                        <img src={backArrow} style={{stroke: 'red'}} height='16' width='16' />
                      </span>
                      <h1 className='m-0 head-text ms-2'>
                        {flg == '1' ? 'Create Tenancy' : 'Edit Tenancy'}
                      </h1>
                    </div>
                  )}

                  {/* <>
                  {flg !== '3' ? (
                    <Button
                      type='submit'
                      // onClick={() => }
                      disabled={isLoading}
                      className='btn-green btn-sm'
                    >
                      {isLoading == 'save' ? <Spinner animation='border' size='sm' /> : 'Save'}
                    </Button>
                  ) : (
                    <></>
                  )}
                  </> */}
                </div>

                <div className=' d-flex'>
                  {flg == '2' && (
                    <>
                      {tenancyData?.tenancyStatus == 0 && (
                        <button
                          className='btn btn-sm fw-bold terminate-btn me-3'
                          onClick={() => cancelTenancy()}
                        >
                          Cancel Tenancy
                        </button>
                      )}
                    </>
                  )}

                  <button
                    className='btn btn-sm fw-bold btn-primary btn-green me-3'
                    type='button'
                    onClick={(e) => {
                      onSubmitNewFlow(formData, e, true)
                    }}
                    style={{width: '100px', fontSize: '13px'}}
                  >
                    <img
                      src={saveImg}
                      style={{height: '20px', width: '20px', marginRight: '13px'}}
                    />{' '}
                    {'  '}
                    Save
                  </button>

                  <button
                    type='submit'
                    className='btn btn-sm fw-bold  ms-3 ps-2 pe-4'
                    style={{
                      border: '1px solid #48474D',
                      color: 'white',
                      backgroundColor: '#48474D',
                      width: '100px',
                      fontSize: '13px',
                    }}
                    disabled={isLoading}
                  >
                    <img
                      src={submitImg}
                      style={{height: '20px', width: '20px', marginRight: '7px'}}
                    />{' '}
                    {'  '}
                    {isLoading == 'create' ? (
                      <Spinner animation='border' size='sm' />
                    ) : flg == '1' ? (
                      'Create'
                    ) : flg == '2' ? (
                      'Create'
                    ) : flg == '3' ? (
                      'Terminate'
                    ) : (
                      ''
                    )}
                  </button>
                  {/* <Button type='submit' className='btn-green btn-sm' disabled={isLoading}>
                    {isLoading == 'create' ? (
                      <Spinner animation='border' size='sm' />
                    ) : flg == '1' ? (
                      'Create'
                    ) : flg == '2' ? (
                      'Create'
                    ) : flg == '3' ? (
                      'Terminate'
                    ) : (
                      ''
                    )}
                  </Button> */}
                </div>
              </div>
            </div>

            {flg != '3' && tenancyData?.terminate_request == null && (
              <>
                <div className='card card-flush mt-8'>
                  <Row>
                    <Col md={3} className='m-5'>
                      <h2>Property Details</h2>
                    </Col>
                    <Col md={8} className='m-5'>
                      <a
                        onClick={() => setShowCreateAppModal(true)}
                        className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                      >
                        Select Another Property
                      </a>
                      {sendError && (
                        <p style={{color: 'var(--kt-danger)'}} className='m-0 px-5'>
                          Property not selected yet !
                        </p>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      {/* <div className='m-5'>
                    <img src={buildings} alt='' width={170} height={150} />
                  </div> */}
                      <div className='m-5'>{getImage()}</div>
                    </Col>
                    <Col md={10} className='m-auto'>
                      <div className='card-header align-items-center py-5  gap-md-2 d-flex border-0 p-0'>
                        <div className='min-w-300px'>
                          <h6 className='mx-10 text-muted'>
                            Property ID
                            <span className='mx-1' style={{color: 'black'}}>
                              {tenancyData?.id ? tenancyData?.id : unitData?.id}
                              {/* {unitData?.id} */}
                            </span>
                          </h6>
                        </div>
                        <div className='min-w-300px'>
                          <h6 className='mx-10 text-muted'>
                            Unit No.{' '}
                            <span className='mx-1' style={{color: 'black'}}>
                              {tenancyData?.unitData?.unitType
                                ? tenancyData?.unitData?.unitType
                                : unitData?.unitNo}
                            </span>
                          </h6>
                        </div>
                        <div className='min-w-300px'>
                          <h6 className='mx-10 text-muted'>
                            Bedrooms{' '}
                            <span className='mx-1' style={{color: 'black'}}>
                              {unitData?.bedrooms}
                            </span>
                          </h6>
                        </div>
                        <div className='min-w-300px'>
                          <h6 className='mx-10 text-muted'>
                            Unit Type{' '}
                            <span className='mx-1' style={{color: 'black'}}>
                              {unitData?.unitType === 'town_house'
                                ? 'TownHouse'
                                : unitData?.unitType === 'other'
                                ? 'Other'
                                : unitData?.unitType === 'common_area'
                                ? 'Common Area'
                                : unitData?.unitType === 'villa'
                                ? 'Villa'
                                : unitData?.unitType === 'apartment'
                                ? 'Apartment'
                                : unitData?.unitType === 'penthouse'
                                ? 'Penthouse'
                                : ''}
                            </span>
                          </h6>
                        </div>
                        <div className='min-w-300px'>
                          <h6 className='mx-10 text-muted'>
                            Property Manager{' '}
                            <span className='mx-1' style={{color: 'black'}}>
                              {unitData?.managerId}
                            </span>
                          </h6>
                        </div>
                        <div className='min-w-300px'>
                          <h6 className='mx-10 text-muted'>
                            Occupancy{' '}
                            <span className='mx-1' style={{color: 'black'}}>
                              {unitData?.occupy === 0 ? 'Vacant ' : 'Occupied'}
                            </span>
                          </h6>
                        </div>
                        <div className='min-w-300px'>
                          <h6 className='mx-10 text-muted'>
                            Development{' '}
                            <span className='mx-1' style={{color: 'black'}}>
                              {unitData?.communityId ? 'Community' : 'Building'}
                            </span>
                          </h6>
                        </div>
                        <div className='min-w-300px'>
                          <h6 className='mx-10 text-muted'>
                            Cluster{' '}
                            <span className='mx-1' style={{color: 'black'}}>
                              {unitData?.buildingId ? 'Building' : 'Unit'}
                            </span>
                          </h6>
                        </div>
                        <div className='min-w-300px'>
                          <h6 className='mx-10 text-muted'>
                            Unit Group{' '}
                            <span className='mx-1' style={{color: 'black'}}>
                              {unitData?.unit_group?.length > 0
                                ? unitData?.unit_group[0]?.name
                                : '-'}
                            </span>
                          </h6>
                        </div>
                        <div className='min-w-300px'>
                          <h6 className='mx-10 text-muted'>
                            Building{' '}
                            <span className='mx-1' style={{color: 'black'}}>
                              {unitData?.building?.length > 0 ? unitData?.building[0]?.name : '-'}
                            </span>
                          </h6>
                        </div>
                        <div className='min-w-300px'>
                          <h6 className='mx-10 text-muted'>
                            Floor{' '}
                            <span className='mx-1' style={{color: 'black'}}>
                              {unitData?.floor?.length > 0 ? unitData?.floor[0]?.name : '-'}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </>
            )}
            <div
              className='d-flex align-items-center gap-2 gap-lg-3 justfy-content-center py-5 mb-5'
              style={{justifyContent: 'center'}}
            >
              <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                <li
                  className='nav-item cursor-pointer'
                  onClick={() => {
                    tenantName = selectedTenantName
                    setPropertiType('Tenants')

                    // handleSubmit()
                  }}
                >
                  <a
                    className='nav-link text-active-primary pb-3 text-center mx-10 active '
                    data-bs-toggle='tab'
                  >
                    1. Tenants
                  </a>
                </li>
                <li
                  className='nav-item cursor-pointer'
                  onClick={() => {
                    setPropertiType('Tenancy')
                    tenantName = selectedTenantName
                  }}
                >
                  <a
                    className='nav-link text-active-primary pb-3  text-center mx-10'
                    data-bs-toggle='tab'
                  >
                    2. Tenancy
                  </a>
                </li>
                <li
                  className='nav-item cursor-pointer'
                  onClick={() => {
                    tenantName = selectedTenantName
                    getPaymentItemsList()
                    setPropertiType('Financials')
                    // let validationCall: any = varificationCodeValidation()
                    // setImgError(validationCall.error)

                    // if (validationCall.isValid) setDisabledBtn(false)
                    // else setDisabledBtn(true)
                    if (sendError) setDisabledBtn(true)
                    else setDisabledBtn(false)

                    // handleSubmit()
                  }}
                >
                  <a
                    className='nav-link text-active-primary pb-3 text-center mx-10'
                    data-bs-toggle='tab'
                  >
                    3. Financials
                    <span className='ms-3'>
                      <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 400}}
                        overlay={
                          <Tooltip id='button-tooltip-2' className='custom-tooltip'>
                            {' '}
                            Please select some PROPERTY, so that you can add an Incoming Payment.
                          </Tooltip>
                        }
                      >
                        <span>
                          {' '}
                          <AiOutlineExclamationCircle
                            color='#35475e '
                            fontSize={14}
                            fontWeight={600}
                            className='cursor-pointer'
                            style={{cursor: 'pointer', marginBottom: '2px'}}
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* </div> */}

          <div
            id='kt_app_content'
            className='app-content flex-column-fluid pt-0'
            style={
              sendError ? {paddingTop: '0px', opacity: '0.4'} : {paddingTop: '0px', opacity: '1'}
            }
          >
            <div id='kt_app_content_container' className='container-xxl m/tenanciest-10'>
              {/* Tenants Tab */}
              {propertiType === 'Tenants' && (
                <>
                  <div className='grid-out p-0 container-xxl'>
                    <div className='card side px-7 py-3'>
                      <div className='text-muted grid_big_col py-2'>
                        <label htmlFor='exampleFormControlInput1' className='form-label m-0'>
                          Tenant Source
                        </label>

                        <select
                          // {...register('tenantSource', {required: true})}
                          name='tenanttSource'
                          className='form-select form-select-solid'
                          value={formData?.tenanttSource}
                          onChange={(e) => {
                            const {name, value} = e.target
                            setFormData({...formData, [name]: value})
                            setIsFormDirty(true)
                          }}
                          disabled={sendError}
                        >
                          <option value={0} selected>
                            {' '}
                            Agent{' '}
                          </option>
                          <option value={1}>Direct</option>
                          {/* <option value={2}>Renewed</option> */}
                        </select>
                        {/* {errors.tenantSource && (
                          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0'>
                            This field is required
                          </p>
                        )} */}
                      </div>
                      {flg != '3' && (
                        <div className='text-muted grid_big_col py-2 align-items-center'>
                          <label
                            htmlFor='exampleFormControlInput1'
                            className={flg == '3' ? 'form-label m-0' : 'required form-label m-0'}
                          >
                            Select Tenant
                          </label>
                          <div className='mx-1 d-flex' style={{color: 'black'}}>
                            <select
                              {...register('mainTenant', {required: true})}
                              name='mainTenant'
                              className='form-select form-select-solid'
                              onChange={(e: any) => handleChnageTenant(e)}
                              style={{width: '60%'}}
                              disabled={sendError}
                              placeholder=' '
                              value={newTenantId}
                            >
                              <option value='' disabled selected>
                                ---
                              </option>

                              {tenanatData?.map((i: any) => {
                                return (
                                  <option value={i?._id}>{`${i?.firstName} ${i?.lastName}`}</option>
                                )
                              })}
                            </select>
                            <button
                              type='button'
                              className='btn btn-sm fw-bold btn-primary btn-green ms-auto'
                              onClick={() => {
                                setAddNewTenant(true)
                                // ApiPost('corporate/tenant')
                                //   .then((res) => {
                                //     navigate(`/edit-tenant/${res?.data?.data?._id}`, {
                                //       state: {newTenantId: true},
                                //     })
                                //   })
                                //   .catch((err) => ErrorToast(err.message))
                              }}
                              disabled={sendError}
                            >
                              Add New Tenant
                            </button>
                          </div>
                          {errors.mainTenant && (
                            <p
                              style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                              className='m-0'
                            >
                              This field is required
                            </p>
                          )}
                        </div>
                      )}
                      <h1 className='top green_color'>Main Tenant Details</h1>
                      <div className=' text-muted grid_big_col py-2'>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className={flg == '3' ? 'form-label m-0' : 'form-label m-0'}
                        >
                          First Name
                        </label>
                        {flg == '3' || flg == '2' || flg == '1' ? (
                          <>
                            <span className=' head-text'>
                              <b>{formData?.firstName ? formData?.firstName : '-'}</b>
                            </span>
                          </>
                        ) : (
                          <input
                            type='text'
                            {...register('firstName', {required: true})}
                            className='form-control form-control-solid'
                            placeholder=''
                            name='firstName'
                            value={formData?.firstName}
                            onChange={(e) => {
                              const {name, value} = e.target
                              setFormData({...formData, [name]: value})
                            }}
                          />
                        )}
                        {errors.firstName && (
                          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0'>
                            This field is required
                          </p>
                        )}
                      </div>
                      <div className=' text-muted grid_big_col py-2'>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className={flg == '3' ? 'form-label m-0' : ' form-label m-0'}
                        >
                          Last Name
                        </label>
                        {flg == '3' || flg == '2' || flg == '1' ? (
                          <>
                            <span className=' head-text'>
                              <b>{formData?.lastName ? formData?.lastName : '-'}</b>
                            </span>
                          </>
                        ) : (
                          <input
                            type='text'
                            {...register('lastName', {required: true})}
                            className='form-control form-control-solid'
                            placeholder=''
                            name='lastName'
                            value={formData?.lastName}
                            onChange={(e: any) => {
                              const {name, value} = e.target
                              setFormData({...formData, [name]: value})
                            }}
                          />
                        )}
                        {errors.lastName && (
                          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0'>
                            This field is required
                          </p>
                        )}
                      </div>
                      <div className=' text-muted grid_big_col py-2 '>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className={flg == '3' ? 'form-label m-0' : 'form-label m-0'}
                        >
                          Date of Birth
                        </label>
                        {flg == '3' || flg == '2' || flg == '1' ? (
                          <>
                            <span className=' head-text'>
                              <b>
                                {formData?.DOB
                                  ? moment(formData?.DOB && formData?.DOB.split('T')[0]).format(
                                      'DD/MM/YYYY'
                                    )
                                  : '-'}
                              </b>
                            </span>
                          </>
                        ) : (
                          <input
                            type='date'
                            {...register('DOB', {required: true})}
                            className='form-control form-control-solid'
                            placeholder=''
                            max={new Date()?.toISOString()?.split('T')[0]}
                            name='DOB'
                            value={moment(formData?.DOB && formData?.DOB.split('T')[0]).format(
                              'YYYY-MM-DD'
                            )}
                            onChange={handleChnage}
                          />
                        )}
                        {errors.DOB && (
                          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0'>
                            This field is required
                          </p>
                        )}
                      </div>
                      <div className=' text-muted grid_big_col py-2 '>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className={flg == '3' ? 'form-label m-0' : 'form-label m-0'}
                        >
                          Nationality
                        </label>
                        {flg == '3' || flg == '2' || flg == '1' ? (
                          <>
                            <span className=' head-text'>
                              <b>{formData?.nationality ? formData?.nationality : '-'}</b>
                            </span>
                          </>
                        ) : (
                          <select
                            {...register('nationality', {required: true})}
                            name='nationality'
                            className='form-select form-select-solid'
                            value={formData?.nationality}
                            onChange={handleChnage}
                            style={{width: '150px'}}
                            disabled={flg == '3' ? true : false}
                          >
                            <option value='' disabled selected>
                              Select country
                            </option>
                            {country?.map((v: any) => (
                              <option value={v.country}>{v.country}</option>
                            ))}
                          </select>
                        )}
                        {errors.nationality && (
                          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0'>
                            This field is required
                          </p>
                        )}
                      </div>
                      <div className=' text-muted grid_big_col py-2 '>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className={flg == '3' ? 'form-label m-0' : 'form-label m-0'}
                        >
                          Email
                        </label>
                        {flg == '3' || flg == '2' || flg == '1' ? (
                          <>
                            <span className=' head-text'>
                              <b>{formData?.email ? formData?.email : '-'}</b>
                            </span>
                          </>
                        ) : (
                          <input
                            {...register('email', {required: true})}
                            type='email'
                            className='form-control form-control-solid'
                            placeholder=''
                            name='email'
                            value={formData?.email}
                            onChange={(e: any) => {
                              const {name, value} = e.target
                              setFormData({...formData, [name]: value})
                            }}
                          />
                        )}
                        {errors.email && (
                          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0'>
                            This field is required
                          </p>
                        )}
                      </div>
                      <div className=' text-muted grid_big_col py-2 '>
                        <label
                          htmlFor='exampleFormControlInput1'
                          className={flg == '3' ? 'form-label m-0' : 'form-label m-0'}
                        >
                          Mobile No.
                        </label>
                        {flg == '3' || flg == '2' || flg == '1' ? (
                          <>
                            <span className=' head-text'>
                              <b>
                                {formData?.phoneNumber
                                  ? `${formData?.countryCode} ${formData?.phoneNumber}`
                                  : '-'}
                              </b>
                            </span>
                          </>
                        ) : (
                          <div className='d-flex flex-column'>
                            <PhoneInput
                              {...register('phoneNumber', {required: true})}
                              placeholder='Enter phone number'
                              value={`${formData?.countryCode} ${formData?.phoneNumber}`}
                              onChange={(phone, e) => handleNumber(phone, e)}
                              // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                            />
                            {/* {errors.phone && <div style={{ color: 'var(--kt-danger)' }}>This field is required</div>} */}
                            {errors.phoneNumber && (
                              <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                            )}
                          </div>
                        )}
                      </div>{' '}
                    </div>
                    <div className='card side px-7 py-3 gap-3'>
                      <h1 className='top green_color'>Documents</h1>
                      <div className='text-muted py-2 img--box--style'>
                        <div>
                          <label>Passport</label>
                        </div>
                        {uploadPassportTenant?.length == 0 || uploadPassportTenant?.[0] == null ? (
                          <>
                            <div className='d-flex justify-content-end'>
                              <button
                                className='btn btn-green btn-sm text-white me-3'
                                onClick={() => {
                                  if (newTenantId != null) {
                                    setShowBck(false)
                                    setDataToSelectFrom(passportFolder)
                                    setFolderFileModal(true)
                                    setFileType('passport')
                                  } else {
                                    ErrorToast('Please first select a tenant..')
                                  }
                                }}
                                type='button'
                                disabled={sendError}
                              >
                                {' '}
                                Select
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                          // <label>Passport</label>
                        )}
                        <input
                          type='file'
                          hidden
                          id='uploadPassport'
                          className='form-control form-control-solid'
                          placeholder='upload'
                          name='passport'
                          value={formData?.passport}
                          // onChange={(e: any) => onFilesAddedSingle(e, 'uploadPassport', false)}
                          onChange={() => {
                            setDataToSelectFrom(passportFolder)
                            setFolderFileModal(true)
                          }}
                        />
                        <div className='tops'>
                          {imgerrors.passport && (
                            <p style={{color: 'var(--kt-danger)'}} className='m-0'>
                              {imgerrors.passport}
                            </p>
                          )}
                        </div>
                        <div className='tops'>
                          {uploadPassportTenant?.length > 0
                            ? uploadPassportTenant?.map((el: any, i: any) => (
                                <div className='text-auto-fr'>
                                  <div className='img-sets mw-350px'>
                                    {checkIsImage(el) ? (
                                      <img
                                        src={Bucket + el}
                                        width='80px'
                                        height='80px'
                                        className='main_img'
                                      />
                                    ) : (
                                      <img
                                        src={fileicon}
                                        width='80px'
                                        height='80px'
                                        className='main_img'
                                      />
                                    )}

                                    <img
                                      src={closered}
                                      className='closered'
                                      width='25px'
                                      height='25px'
                                      onClick={
                                        () => {
                                          setuploadPassportTenant([])
                                          setIsFormDirty(true)
                                        }
                                        // deleteUploadImg(i, 'uploadPassport', el)
                                      }
                                    />
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                      <div className='text-muted py-2 img--box--style'>
                        <div>
                          {' '}
                          <label>Residency</label>
                        </div>
                        {(uploadresidencyTenant?.length == 0 ||
                          uploadresidencyTenant?.[0] == null) && (
                          <>
                            <div className='d-flex justify-content-end'>
                              <button
                                className='btn btn-green btn-sm text-white me-3'
                                onClick={() => {
                                  if (newTenantId != null) {
                                    setDataToSelectFrom(residencyFolder)
                                    setFolderFileModal(true)
                                    setFileType('residency')
                                    setShowBck(false)
                                  } else {
                                    ErrorToast('Please first select a tenant..')
                                  }
                                }}
                                type='button'
                                disabled={sendError}
                              >
                                {' '}
                                Select
                              </button>
                              {/* <Label title='Upload Residency' htmlFor='uploadResidency' /> */}
                            </div>
                          </>
                        )}
                        <input
                          type='file'
                          hidden
                          id='uploadResidency'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='residency'
                          value={formData?.residency}
                          // onChange={(e: any) => onFilesAdded(e, 'uploadResidency')}
                          onChange={(e: any) => onFilesAddedSingle(e, 'uploadResidency', false)}
                        />
                        <div className='tops'>
                          {imgerrors.residancy && (
                            <p style={{color: 'var(--kt-danger)'}} className='m-0'>
                              {imgerrors.residancy}
                            </p>
                          )}
                        </div>
                        <div className='tops'>
                          {uploadresidencyTenant?.length > 0
                            ? uploadresidencyTenant?.map((el: any, i: any) => (
                                <div className='text-auto-fr'>
                                  <div className='img-sets mw-350px'>
                                    {checkIsImage(el) ? (
                                      <img
                                        src={Bucket + el}
                                        width='80px'
                                        height='80px'
                                        className='main_img'
                                      />
                                    ) : (
                                      <img
                                        src={fileicon}
                                        width='80px'
                                        height='80px'
                                        className='main_img'
                                      />
                                    )}

                                    <img
                                      src={closered}
                                      className='closered'
                                      width='25px'
                                      height='25px'
                                      onClick={() => {
                                        // deleteUploadImg(i, 'uploadResidency', el)

                                        setuploadresidencyTenant([])
                                        setIsFormDirty(true)
                                      }}
                                    />
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                      <div className='text-muted py-2 img--box--style'>
                        <div>
                          <label>ID</label>
                        </div>
                        {(uploadIdTenant?.length == 0 || uploadIdTenant?.[0] == null) && (
                          <>
                            <div className='d-flex justify-content-end'>
                              <button
                                className='btn btn-green btn-sm text-white me-3'
                                onClick={() => {
                                  if (newTenantId != null) {
                                    setDataToSelectFrom(iDsFolder)
                                    setFolderFileModal(true)
                                    setFileType('ids')
                                    setShowBck(false)
                                  } else {
                                    ErrorToast('Please first select a tenant..')
                                  }
                                }}
                                type='button'
                                disabled={sendError}
                              >
                                Select
                              </button>
                              {/* <Label title='Upload ID' htmlFor='uploadId' /> */}
                            </div>
                          </>
                        )}
                        <input
                          type='file'
                          hidden
                          id='uploadId'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='ID'
                          value={formData?.ID}
                          onChange={(e: any) => onFilesAddedSingle(e, 'uploadId', false)}
                          // onChange={(e: any) => onFilesAdded(e, 'uploadId')}
                        />

                        <div className='tops'>
                          {imgerrors.id && (
                            <p style={{color: 'var(--kt-danger)'}} className='m-0'>
                              {imgerrors.id}
                            </p>
                          )}
                        </div>
                        <div className='tops'>
                          {uploadIdTenant?.length > 0
                            ? uploadIdTenant?.map((el: any, i: any) => (
                                <div className='text-auto-fr'>
                                  <div className='img-sets mw-350px'>
                                    {checkIsImage(el) ? (
                                      <img
                                        src={Bucket + el}
                                        width='80px'
                                        height='80px'
                                        className='main_img'
                                      />
                                    ) : (
                                      <img
                                        src={fileicon}
                                        width='80px'
                                        height='80px'
                                        className='main_img'
                                      />
                                    )}

                                    <img
                                      src={closered}
                                      className='closered'
                                      width='25px'
                                      height='25px'
                                      onClick={
                                        () => {
                                          setuploadIdTenant([])
                                          setIsFormDirty(true)
                                        }
                                        // deleteUploadImg(i, 'uploadId', el)
                                      }
                                    />
                                  </div>
                                </div>
                              ))
                            : null}
                        </div>
                      </div>

                      <div className='text-muted grid_big_col py-2 img--box--style'>
                        <>
                          <div>
                            <label>Others</label>
                          </div>
                          <div className='d-flex justify-content-end'>
                            <button
                              className='btn btn-green btn-sm text-white me-3'
                              onClick={() => {
                                if (newTenantId != null) {
                                  setFolderFileModal(true)
                                  setFileType('other')
                                  setShowBck(false)
                                } else {
                                  ErrorToast('Please first select a tenant..')
                                }
                              }}
                              type='button'
                              disabled={sendError}
                            >
                              Select
                            </button>
                            {/* <Label title='Upload Other' htmlFor='tenant' /> */}
                          </div>
                        </>

                        <input
                          type='file'
                          hidden
                          id='tenant'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='Other'
                          value={formData?.Other}
                          onChange={(e: any) => onFilesAdded(e, 'tenant')}
                        />
                        <div className='tops'>
                          {imgerrors.otherimgtenant && (
                            <p style={{color: 'var(--kt-danger)'}} className='m-0'>
                              {imgerrors.otherimgtenant}
                            </p>
                          )}
                        </div>

                        <div className='tops mw-350px d-flex flex-wrap gap-5 align-items-start'>
                          {uploadOtherImgTenant?.length > 0
                            ? uploadOtherImgTenant?.map((el: any, i: any) => (
                                <div className='img-sets '>
                                  {checkIsImage(Bucket + el) ? (
                                    <>
                                      <img
                                        src={Bucket + el}
                                        width='80px'
                                        height='80px'
                                        className='main_img'
                                      />
                                      {editName !== i ? (
                                        <div
                                          className='mt-3 d-flex align-items-center gap-3 flex-wrap'
                                          style={{width: '80px'}}
                                        >
                                          <p className='m-0 p-0' style={{}}>
                                            {getFilename(el)?.length > 9
                                              ? getFilename(el)?.slice(0, 9) + '...'
                                              : getFilename(el)}
                                          </p>
                                          {/* <img
                                            src={pencil}
                                            alt=''
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                              setEditName(i)
                                              setnewName(
                                                uploadOtherImgTenant[i]
                                                  .split('/')
                                                  .pop()
                                                  .split('.')[0]
                                              )
                                            }}
                                          /> */}
                                        </div>
                                      ) : (
                                        <>
                                          <br />
                                          <div className='d-flex gap-2 mt-2'>
                                            <input
                                              type='text'
                                              value={newName}
                                              style={{maxWidth: '100px'}}
                                              className='form-control form-control-solid p-2'
                                              onChange={(e: any) => {
                                                setnewName(e.target.value)
                                                setIsEditImgName(true)
                                              }}
                                            />
                                            <a
                                              className='btn btn-sm fw-bold btn-primary btn-green'
                                              onClick={() => changeFileNameOther('tenant', i)}
                                            >
                                              Update
                                            </a>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      src={fileicon}
                                      width='80px'
                                      height='80px'
                                      className='main_img'
                                    />
                                  )}

                                  <img
                                    src={closered}
                                    className='closered'
                                    width='25px'
                                    height='25px'
                                    onClick={() => {
                                      const values = [...uploadOtherImgTenant]
                                      values?.splice(i, 1)
                                      setuploadOtherImgTenant(values)
                                      setIsFormDirty(true)
                                    }}
                                  />
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>

                    {/* Add Sub Tenant Card */}
                    <div className='card tops p-5 min_height'>
                      {create ? (
                        <>
                          <div className='d-flex justify-content-between pb-5'>
                            <div className=''>
                              <h3 className='green_color'>Add Sub Tenant</h3>
                            </div>
                            <div className=''>
                              <a
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                onClick={() => {
                                  if (subTenantId != '') addSubTenant()
                                  else ErrorToast('Please select a Sub Tenant..')
                                }}
                              >
                                Done
                              </a>
                              <a
                                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                onClick={() => {
                                  setCreate(false)
                                  let values = {
                                    firstName: '',
                                    lastName: '',
                                    phoneNumber: '',
                                    countryCode: '',
                                    email: '',
                                  }
                                  setSubTenant(values)
                                  setSubTenantId('')
                                }}
                              >
                                Cancel
                              </a>
                            </div>
                          </div>
                          <div
                            className='d-flex  align-items-center gap-md-2'
                            style={{justifyContent: 'flex-start'}}
                          >
                            <div className='mb-10 min-w-200px me-10'>
                              <label htmlFor='exampleFormControlInput1' className='form-label'>
                                Select
                              </label>

                              <select
                                className='form-select form-select-solid'
                                value={`${subTenantId}`}
                                onChange={(e: any) => handleSelectSubTenant(e, '')}
                              >
                                <option value='' disabled selected>
                                  ---
                                </option>

                                {tenanatData?.map((i: any) => {
                                  return (
                                    <option value={i?._id}>
                                      {`${i?.firstName} ${i?.lastName}`}
                                    </option>
                                  )
                                })}
                              </select>
                            </div>
                            <div className='mb-10 min-w-200px me-10'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label'
                              >
                                First Name
                              </label>
                              <input
                                type='text'
                                className='form-control form-control-solid'
                                placeholder=''
                                name='firstName'
                                value={subTenant?.firstName}
                                onChange={handleChnageSubTenant}
                                disabled
                              />
                            </div>
                            <div className='mb-10 min-w-200px me-10'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label'
                              >
                                Last Name
                              </label>
                              <input
                                type='text'
                                className='form-control form-control-solid'
                                placeholder=''
                                name='lastName'
                                value={subTenant?.lastName}
                                onChange={handleChnageSubTenant}
                                disabled
                              />
                            </div>
                            <div className='mb-10 min-w-200px me-10'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label'
                              >
                                Email
                              </label>
                              <input
                                type='email'
                                className='form-control form-control-solid'
                                placeholder=''
                                name='email'
                                value={subTenant?.email}
                                onChange={handleChnageSubTenant}
                                disabled
                              />
                            </div>
                            <div className='mb-10 min-w-200px me-10'>
                              <label
                                htmlFor='exampleFormControlInput1'
                                className='required form-label'
                              >
                                Mobile No.
                              </label>
                              <div className='d-flex'>
                                <PhoneInput
                                  placeholder='Enter phone number'
                                  value={`${subTenant?.countryCode} ${subTenant?.phoneNumber}`}
                                  onChange={(phone, e) => handleNumber1(phone, e)}
                                  // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                                  disabled
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className='d-flex align-items-center pb-5'
                            style={{justifyContent: 'space-between'}}
                          >
                            <h2 className='green_color'>Sub Tenants</h2>
                            {flg != '3' && (
                              <div className='d-flex'>
                                <button
                                  type='button'
                                  onClick={() => {
                                    setAddNewTenant(true)
                                  }}
                                  className='btn btn-sm fw-bold btn-primary btn-green  me-3 '
                                  disabled={sendError}
                                >
                                  Add New Tenant
                                </button>
                                {/* <button
                                  type='button'
                                  onClick={() => {
                                    // // getTenantData()
                                    // ApiPost('corporate/tenant')
                                    //   .then((res) => {
                                    //     setSubTenantId(res?.data?.data?._id)
                                    //     localStorage.setItem('newSubId', res?.data?.data?._id)
                                    //   })
                                    //   .catch((err) => ErrorToast(err.message))
                                    // setTimeout(() => {
                                    setCreate(true)
                                    // })
                                  }}
                                  className='btn btn-sm fw-bold btn-primary btn-green '
                                  disabled={sendError}
                                >
                                  Add
                                </button> */}
                              </div>
                            )}
                          </div>

                          <div
                            className='d-flex align-items-center  my-2 cursor-pointer'
                            onClick={() => {
                              handleAddFields()
                            }}
                          >
                            <img
                              src={plusBlue}
                              style={{
                                height: '16px',
                                width: '16px',
                              }}
                            />
                            <p className='head-text ms-3 mb-0'>
                              {' '}
                              <i>Add another sub tenant</i>
                            </p>
                          </div>

                          {subTenantData.map((sb: any, i: any) => (
                            <div
                              className='d-flex  align-items-center gap-md-2 mt-4'
                              style={{justifyContent: 'flex-start'}}
                            >
                              <div className='mb-10 min-w-200px me-10'>
                                {/* <label htmlFor='exampleFormControlInput1' className='form-label'>
                                  Select
                                </label> */}

                                <select
                                  className='form-select form-select-solid'
                                  value={`${sb?.id}`}
                                  onChange={(e: any) => handleSelectSubTenant(e, i)}
                                >
                                  <option value='' disabled selected>
                                    Select Tenant
                                  </option>

                                  {tenanatData?.map((i: any) => {
                                    return (
                                      <option value={i?._id}>
                                        {`${i?.firstName} ${i?.lastName}`}
                                      </option>
                                    )
                                  })}
                                </select>
                              </div>
                              <div className='mb-10 min-w-200px me-10'>
                                {/* <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label'
                                >
                                  First Name
                                </label> */}
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  placeholder=''
                                  name='firstName'
                                  value={`${sb?.firstName}`}
                                  onChange={handleChnageSubTenant}
                                  disabled
                                />
                              </div>
                              <div className='mb-10 min-w-200px me-10'>
                                {/* <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label'
                                >
                                  Last Name
                                </label> */}
                                <input
                                  type='text'
                                  className='form-control form-control-solid'
                                  placeholder=''
                                  name='lastName'
                                  value={`${sb?.lastName}`}
                                  onChange={handleChnageSubTenant}
                                  disabled
                                />
                              </div>
                              <div className='mb-10 min-w-200px me-10'>
                                {/* <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label'
                                >
                                  Email
                                </label> */}
                                <input
                                  type='email'
                                  className='form-control form-control-solid'
                                  placeholder=''
                                  name='email'
                                  value={`${sb?.email}`}
                                  onChange={handleChnageSubTenant}
                                  disabled
                                />
                              </div>
                              <div className='mb-10 min-w-200px me-10'>
                                {/* <label
                                  htmlFor='exampleFormControlInput1'
                                  className='required form-label'
                                >
                                  Mobile No.
                                </label> */}
                                <div className='d-flex'>
                                  <PhoneInput
                                    placeholder='Enter phone number'
                                    value={`${sb?.countryCode} ${sb?.phoneNumber}`}
                                    onChange={(phone, e) => handleNumber1(phone, e)}
                                    // className='block max-w-lg w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md'
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className='flex mb-10'>
                                {subTenantData?.length != 1 && (
                                  <AiFillDelete
                                    color='#D72852'
                                    fontSize={20}
                                    onClick={() => {
                                      swal
                                        .fire({
                                          text: 'Are you sure you want to remove this tenant from Sub Tenants List ?',
                                          icon: 'warning',
                                          showConfirmButton: true,
                                          confirmButtonColor: '#D72852',
                                          confirmButtonText: 'Yes',
                                          showCancelButton: true,
                                          // cancelButtonColor: "transparent",
                                          cancelButtonText: 'Cancel',
                                        })
                                        .then((res) => {
                                          if (res.isConfirmed) {
                                            // deleteTenant(v?._id, 1)
                                            let temp = [...subTenantIds]
                                            temp.splice(i, 1)
                                            setSubTenantIds(temp)

                                            let values = [...subTenantData]
                                            values.splice(i, 1)
                                            setSubTenantData(values)

                                            setIsFormDirty(true)
                                          }
                                        })
                                    }}
                                    style={{cursor: 'pointer'}}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
              {/* Tenancy Tab */}
              {propertiType === 'Tenancy' && (
                <>
                  <div className='grid-out p-0 container-xxl'>
                    <div className='card side px-5 py-3'>
                      <h1 className='top green_color'>Contract Details </h1>
                      <div className=' text-muted grid_big_col py-2'>
                        <label htmlFor='exampleFormControlInput1' className='required form-label'>
                          Contract No.
                        </label>
                        <input
                          type='number'
                          {...register('contractNo', {required: true})}
                          className='form-control form-control-solid mytest'
                          placeholder=''
                          name='contractNo'
                          value={tenancyData?.contractNo}
                          onChange={handleChangeContractNo}
                          disabled={sendError}
                          style={{height: '35px'}}
                        />
                        {errors.contractNo && (
                          <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                        )}
                      </div>
                      {/* <div className=' text-muted grid_big_col py-2'>
                        <label htmlFor='exampleFormControlInput1' className='required form-label'>
                          Tenancy Status
                        </label>
                        <select
                          className='form-select form-select-solid'
                          {...register('tenancyStatus', {required: true})}
                          value={Number(tenancyData?.tenancyStatus)}
                          onChange={handleChnageTenancy}
                          name='tenancyStatus'
                          disabled={sendError}
                        >
                          <option selected value=''>
                            Select
                          </option>
                          <option value={0}>Booked</option>
                          <option value={1}>Active</option>
                          <option value={4}>Renewed</option>
                        </select>
                        {errors.tenancyStatus && (
                          <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                        )}
                      </div> */}
                      <div className=' text-muted grid_big_col py-2'>
                        <label htmlFor='exampleFormControlInput1' className='required form-label'>
                          Start - End
                        </label>
                        {start_date != null || end_date != null ? (
                          <Controller
                            control={control}
                            name='start'
                            render={({
                              field: {onChange, onBlur, value, name, ref},
                              fieldState: {invalid, isTouched, isDirty, error},
                              formState,
                            }) => (
                              // <Checkbox
                              //   onBlur={onBlur} // notify when input is touched
                              //   onChange={onChange} // send value to hook form
                              //   checked={value}
                              //   inputRef={ref}
                              // />

                              <RangePicker
                                // disabledDate={disabledDate}

                                // value={[
                                //   moment(start_date ? start_date : new Date(), dateFormat),
                                //   moment(end_date ? end_date : new Date(), dateFormat),
                                // ]}

                                defaultValue={[
                                  moment(start_date ? start_date : new Date(), dateFormat),
                                  moment(end_date ? end_date : new Date(), dateFormat),
                                ]}
                                {...register('start', {required: true})}
                                format={dateFormat}
                                onChange={(e: any) => handleChnageTenancyDate(e)}
                                className='form-control form-control-solid mytest'
                                disabled={sendError}
                              />
                            )}
                          />
                        ) : (
                          <Controller
                            control={control}
                            name='start'
                            render={({
                              field: {onChange, onBlur, value, name, ref},
                              fieldState: {invalid, isTouched, isDirty, error},
                              formState,
                            }) => (
                              // <Checkbox
                              //   onBlur={onBlur} // notify when input is touched
                              //   onChange={onChange} // send value to hook form
                              //   checked={value}
                              //   inputRef={ref}
                              // />

                              <RangePicker
                                // disabledDate={disabledDate}

                                // value={[
                                //   moment(start_date ? start_date : new Date(), dateFormat),
                                //   moment(end_date ? end_date : new Date(), dateFormat),
                                // ]}

                                // defaultValue={[
                                //   moment(start_date ? start_date : new Date(), dateFormat),
                                //   moment(end_date ? end_date : new Date(), dateFormat),
                                // ]}

                                {...register('start', {required: true})}
                                format={dateFormat}
                                onChange={(e: any) => handleChnageTenancyDate(e)}
                                className='form-control form-control-solid'
                                disabled={sendError}
                              />
                            )}
                          />
                        )}
                        {errors.start && (
                          <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                        )}
                      </div>
                      <div
                        className='text-muted grid_big_col py-2 '
                        style={{alignItems: 'flex-start'}}
                      >
                        <label htmlFor='exampleFormControlInput1' className='form-label'>
                          Duration
                        </label>
                        <div
                          className='form-control form-control-solid mytest'
                          style={{height: '35px'}}
                        >
                          {totalDays}
                        </div>
                        {/* {errors.start && <p style={{ color: 'var(--kt-danger)' }}>This field is required</p>} */}
                      </div>
                      <div
                        className='text-muted grid_big_col py-2 '
                        style={{alignItems: 'flex-start'}}
                      >
                        <label htmlFor='exampleFormControlInput1' className='form-label'>
                          Benefits
                        </label>
                        <div className=''>
                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='vacant0'
                              name='none'
                              // defaultChecked
                              checked={optionArry?.none === true ? true : false}
                              onChange={handleChnageOption}
                              disabled={sendError}
                            />
                            <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                              {' '}
                              None
                            </p>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='occupied1'
                              name='chillerFree'
                              checked={optionArry?.chillerFree === true ? true : false}
                              disabled={
                                optionArry?.none === true || sendError == true ? true : false
                              }
                              onChange={handleChnageOption}
                            />
                            <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                              {' '}
                              Chiller Free
                            </p>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='occupied2'
                              name='maintenanceFree'
                              checked={optionArry?.maintenanceFree === true ? true : false}
                              disabled={
                                optionArry?.none === true || sendError == true ? true : false
                              }
                              onChange={handleChnageOption}
                            />
                            <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                              {' '}
                              Maintenance Free
                            </p>
                          </div>
                          {otherOptions?.length > 0 &&
                            otherOptions?.map((opt: any, ind: any) => (
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                <input
                                  className='form-control form-control-solid mytest w-50'
                                  type='text'
                                  value={opt.value}
                                  onChange={(e: any) => {
                                    const values = [...otherOptions]
                                    values[ind] = {
                                      value: e.target.value,
                                    }
                                    setOtherOptions(values)

                                    setIsFormDirty(true)
                                    
                                  }}
                                  style={{height: '35px'}}
                                />

                                <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                                  <img
                                    src={redCross}
                                    width='16px'
                                    height='16px'
                                    className='main_img cursor-pointer ms-auto'
                                    onClick={() => handleRemoveOther(ind)}
                                  />
                                </p>
                              </div>
                            ))}
                          <div
                            className='form-check form-check-custom form-check-solid form-check-sm mb-1 w-fit-content'
                            onClick={() => {
                              if (optionArry?.none === true || sendError == true) {
                              } else handleAddOther()
                            }}
                          >
                            <img
                              src={plusBlue}
                              style={{
                                height: '16px',
                                width: '16px',
                              }}
                            />
                            {/* <input
                              className='form-check-input'
                              type='checkbox'
                              id='occupied3'
                              name='other'
                              // checked={optionArry?.other?.length ? true : false}
                              checked={option === 4 ? true : false}
                              // disabled={optionArry?.none === true ? true : false}
                              // onChange={handleChnageOption}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setOption(4)
                                } else {
                                  setOption(0)
                                }
                              }}
                              disabled={sendError}
                            /> */}
                            <div className=''>
                              <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                                {' '}
                                Other
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div
                        className='d-flex align-items-left py-2 gap-5'
                        style={{flexDirection: 'column'}}
                      >
                        <label className='form-label'>
                          <span className=''>Benefits</span>
                        </label>
                        <div className='d-flex align-items-center py-2 gap-5'>
                          <div className='form-check form-check-custom form-check-solid form-check-sm'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='vacant0'
                              name='none'
                              // defaultChecked
                              checked={optionArry?.none === true ? true : false}
                              onChange={handleChnageOption}
                              // onClick={() => {
                              //   setOption(1)
                              // }}
                              disabled={sendError}
                            />
                            <label className='form-check-label' htmlFor='vacant0'>
                              None
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm '>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='occupied1'
                              name='chillerFree'
                              checked={optionArry?.chillerFree === true ? true : false}
                              disabled={
                                optionArry?.none === true || sendError == true ? true : false
                              }
                              onChange={handleChnageOption}
                              // onClick={() => {
                              //   setOption(2)
                              // }}
                            />
                            <label className='form-check-label' htmlFor='occupied1'>
                              Chiller Free
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm '>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='occupied2'
                              name='maintenanceFree'
                              checked={optionArry?.maintenanceFree === true ? true : false}
                              disabled={
                                optionArry?.none === true || sendError == true ? true : false
                              }
                              onChange={handleChnageOption}
                              // onClick={() => {
                              //   setOption(3)
                              // }}
                            />
                            <label className='form-check-label' htmlFor='occupied2'>
                              Maintenance Free
                            </label>
                          </div>
                          <div className='form-check form-check-custom form-check-solid form-check-sm '>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='occupied3'
                              name='other'
                              // checked={optionArry?.other?.length ? true : false}
                              checked={option === 4 ? true : false}
                              // disabled={optionArry?.none === true ? true : false}
                              // onChange={handleChnageOption}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setOption(4)
                                } else {
                                  setOption(0)
                                }
                              }}
                              disabled={sendError}
                            />
                            <div className=''>
                              <label className='form-check-label' htmlFor='occupied3'>
                                Other
                              </label>
                            </div>
                          </div>
                        </div>
                        {option === 4 ? (
                          <>
                            <div className='d-flex gap-3'>
                              <img src={info} width='24px' height='24px' />
                              <p className='m-0'>Please enter comma separated values</p>
                            </div>
                            <input
                              type='other'
                              className='form-control form-control-solid'
                              placeholder=''
                              name='other'
                              value={optionArry?.other}
                              onChange={handleChnageOption}
                            />
                          </>
                        ) : null}
                        {optionArry?.length == 0 ? (
                          <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                        ) : null}
                      </div> */}
                    </div>
                    <div className='card side px-7 py-3 gap-3'>
                      <h1 className='top green_color'>Documents</h1>
                      <div className=' text-muted  py-2  img--box--style'>
                        <div>
                          {' '}
                          <label>Contract </label>
                        </div>
                        {uploadContractTenancy?.length == 0 ? (
                          <>
                            <div className='d-flex justify-content-end'>
                              <button
                                type='button'
                                className='btn btn-green btn-sm text-white  me-3'
                                onClick={() => {
                                  if (newTenantId != null) {
                                    setDataToSelectFrom(contractsFolder)
                                    setFolderFileModal(true)
                                    setFileType('contract')
                                    setShowBck(false)
                                  } else {
                                    ErrorToast('Please first select a tenant..')
                                  }
                                }}
                                disabled={sendError}
                              >
                                {' '}
                                Select
                              </button>
                              {/* <Label title='Upload Contract' htmlFor='uploadcontract' /> */}
                            </div>
                          </>
                        ) : uploadContractTenancy == '' ? (
                          <>
                            <div className='d-flex justify-content-end'>
                              <button
                                className='btn btn-green  btn-sm text-white me-3'
                                onClick={() => {
                                  if (newTenantId != null) {
                                    setDataToSelectFrom(contractsFolder)
                                    setFolderFileModal(true)
                                    setFileType('contract')
                                    setShowBck(false)
                                  } else {
                                    ErrorToast('Please first select a tenant..')
                                  }
                                }}
                                type='button'
                                disabled={sendError}
                              >
                                {' '}
                                Select
                              </button>
                            </div>
                          </>
                        ) : (
                          <></>
                          // <label>Contract </label>
                        )}

                        <input
                          type='file'
                          hidden
                          id='uploadcontract'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='contract'
                          value={tenancyData?.contract}
                          onChange={(e: any) => onFilesAddedSingle(e, 'uploadContract', true)}
                          disabled={sendError}
                          // onChange={(e: any) => onFilesAdded(e, 'uploadContract')}
                        />

                        <div className='tops'>
                          {imgerrors.contract && (
                            <p style={{color: 'var(--kt-danger)'}} className='m-0'>
                              {/* This field is required ! */}
                              {imgerrors.contract}
                            </p>
                          )}
                        </div>
                        <div className='tops'>
                          {uploadContractTenancy?.length > 0
                            ? uploadContractTenancy[0]?.slice(-9) == undefined
                              ? null
                              : uploadContractTenancy?.map((el: any, i: any) => (
                                  <div className='text-auto-fr'>
                                    <div className='img-sets mw-350px'>
                                      {checkIsImage(el) ? (
                                        <img
                                          src={Bucket + el}
                                          width='80px'
                                          height='80px'
                                          className='main_img'
                                        />
                                      ) : (
                                        <img
                                          src={fileicon}
                                          width='80px'
                                          height='80px'
                                          className='main_img'
                                        />
                                      )}
                                      <img
                                        src={closered}
                                        className='closered'
                                        width='25px'
                                        height='25px'
                                        onClick={
                                          () => {
                                            setuploadContractTenancy([])
                                            setIsFormDirty(true)
                                          }

                                          // deleteUploadImg(i, 'uploadContract', el)
                                        }
                                      />
                                    </div>
                                  </div>
                                ))
                            : null}
                        </div>
                      </div>
                      <div className=' text-muted  py-2  img--box--style'>
                        <>
                          <div>
                            {' '}
                            <label>Others </label>
                          </div>
                          <div className='d-flex justify-content-end'>
                            <label
                              htmlFor='tenancy'
                              className='btn btn-green btn-sm text-white me-3'
                            >
                              Upload
                            </label>
                            {/* <Label title='Upload' htmlFor='tenancy' /> */}
                          </div>
                        </>
                        <input
                          type='file'
                          hidden
                          id='tenancy'
                          className='form-control form-control-solid'
                          placeholder=''
                          name='otherT'
                          value={tenancyData?.otherT}
                          onChange={(e: any) => onFilesAdded(e, 'tenancy')}
                          disabled={sendError}
                        />
                        <div className='tops'>
                          {imgerrors.otherimgtenancy && (
                            <p style={{color: 'var(--kt-danger)'}} className='m-0'>
                              {imgerrors.otherimgtenancy}
                            </p>
                          )}
                        </div>
                        {''}
                        <div className='tops mw-350px d-flex flex-wrap gap-5 align-items-center'>
                          {uploadOtherImgTenancy?.length
                            ? uploadOtherImgTenancy?.map((el: any, i: any) => (
                                <div className='img-sets'>
                                  {checkIsImage(el) ? (
                                    <>
                                      <img
                                        src={Bucket + el}
                                        width='80px'
                                        height='80px'
                                        className='main_img'
                                      />
                                      {editName !== i ? (
                                        <div
                                          className=' mt-3 d-flex align-items-center gap-3 flex-wrap'
                                          style={{width: '80px'}}
                                        >
                                          <p className='m-0 p-0' style={{width: '50px'}}>
                                            {getFilename(el)?.length > 6
                                              ? getFilename(el)?.slice(0, 6) + '...'
                                              : getFilename(el)}
                                          </p>
                                          <img
                                            src={pencil}
                                            alt=''
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                              setEditName(i)
                                              setnewName(
                                                uploadOtherImgTenancy[i]
                                                  .split('/')
                                                  .pop()
                                                  .split('.')[0]
                                              )
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        <>
                                          <br />
                                          <div className='d-flex gap-2 mt-2'>
                                            <input
                                              type='text'
                                              value={newName}
                                              style={{maxWidth: '100px'}}
                                              className='form-control form-control-solid p-2'
                                              onChange={(e: any) => {
                                                setnewName(e.target.value)
                                                setIsEditImgName(true)
                                              }}
                                            />
                                            <a
                                              className='btn btn-sm fw-bold btn-primary btn-green'
                                              onClick={() => changeFileNameOther('tenancy', i)}
                                            >
                                              Update
                                            </a>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  ) : (
                                    <img
                                      src={fileicon}
                                      width='80px'
                                      height='80px'
                                      className='main_img'
                                    />
                                  )}
                                  <img
                                    src={closered}
                                    className='closered'
                                    width='25px'
                                    height='25px'
                                    onClick={() => deleteUploadImg(i, 'tenancy', el)}
                                  />
                                </div>
                              ))
                            : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {propertiType === 'Financials' && (
                <>
                  <div className=''>
                    <div className='d-flex align-items-end justify-content-end gap-2 my-8 gap-lg-3 '>
                      {/* <div className='d-flex align-items-center '>
                        <input
                          type='text'
                          className='form-control form-control-settings'
                          placeholder='Search invoice #'
                          name='item_id'
                        />
                        <button
                          className='btn btn-sm fw-bold btn-primary btn-green mx-3 fs-4 d-flex'
                          style={{width: '130px', height: '37px'}}
                          onClick={() => setShowFilter(true)}
                        >
                          <svg
                            style={{
                              height: '18px',
                              width: '18px',
                              marginRight: '7px',
                              marginLeft: '-10px',
                              marginTop: '1px',
                            }}
                            viewBox='0 0 1024 1024'
                            xmlns='http://www.w3.org/2000/svg'
                            className='me-1'
                          >
                            <path
                              fill='#ffff'
                              d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                            />
                          </svg>
                          <p style={{marginLeft: '8px'}}>Filter</p>
                        </button>
                      </div> */}
                      <div className='ms-auto'>
                        <button
                          className='btn btn-sm fw-bold text-white '
                          style={{backgroundColor: '#35475e'}}
                          onClick={() => {
                            let values = {
                              firstName: formData?.firstName,
                              lastName: formData?.lastName,
                              email: formData?.email,
                              countryCode: formData?.countryCode,
                              phoneNumber: formData?.phoneNumber,
                            }
                            setTenantDetails(values)
                            setIncomeModal(true)
                          }}
                          disabled={disabledBtn ? true : false}
                          type='button'
                        >
                          <img
                            src={plus}
                            style={{
                              height: '20px',
                              width: '20px',
                              marginRight: '7px',
                              marginLeft: '-10px',
                            }}
                          />
                          Add Incoming Payment
                        </button>
                      </div>
                    </div>

                    {/* <div className='card card-flush gap-5 p-5 pt-1 pe-2'>
                      {payments?.length > 0 ? (
                        <div
                          className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'
                          id='global-table'
                          onWheel={handleMouseWheel}
                          ref={tableRef}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <table
                            className='table align-start table-row-dashed fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className='min-w-100px'>#</th>
                                <th className='min-w-150px '>Payment</th>
                                <th className='min-w-150px'>Amount</th>
                                <th className='min-w-100px'>Status</th>
                                <th className='min-w-100px'>Category</th>
                                <th className='min-w-100px'>Due</th>
                                <th className='min-w-100px'>Reminder</th>
                                <th className='min-w-150px'>Amount Paid</th>
                                <th className='min-w-100px'>Date Paid</th>
                                <th className='min-w-150px'>Oustanding</th>
                                <th className='min-w-100px'>Method</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {payments.map((pm: any, ind: any) => {
                                return (
                                  <>
                                    <tr
                                      style={
                                        show[ind] == true
                                          ? {borderBottomStyle: 'none', height: '30px'}
                                          : show[ind] == false
                                          ? {borderBottomStyle: 'dashed', height: '30px'}
                                          : pm?.payment_sub_item?.length == 0
                                          ? {borderBottomStyle: 'dashed', height: '30px'}
                                          : {height: '30px'}
                                      }
                                    >
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          {pm?.payment_sub_item?.length > 0 && (
                                            <img
                                              src={show[ind] ? close : open}
                                              height='15'
                                              width='15'
                                              className='cursor-pointer'
                                              style={{marginLeft: '-30px', marginRight: '15px'}}
                                              onClick={() => {
                                                if (show[ind] == true) {
                                                  let values = [...show]
                                                  values[ind] = false
                                                  setShow(values)
                                                } else {
                                                  let values = [...show]

                                                  values[ind] = true

                                                  setShow(values)
                                                }
                                              }}
                                            />
                                          )}
                                          <a
                                            style={{
                                              textDecoration: 'underline',
                                              color: '#007a59',
                                              marginLeft: '-3px',
                                            }}
                                            onClick={() => getPaymentById(pm?._id)}
                                          >
                                            {' '}
                                            {pm.id}
                                          </a>
                                        </div>
                                      </td>
                                      <td className='max-w-150px'>
                                        <div className='d-flex'>
                                          {pm?.isLock && (
                                            <img
                                              src={lock}
                                              height={16}
                                              width={16}
                                              className='me-2'
                                            />
                                          )}
                                          {pm.name}
                                        </div>
                                      </td>
                                      <td>
                                        {parseFloat(pm?.totalAmount)?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </td>

                                      <td>
                                        {pm.paymentStatus === 0
                                          ? 'Unpaid'
                                          : pm.paymentStatus === 1
                                          ? 'Paid'
                                          : pm.paymentStatus === 2
                                          ? 'Partially paid'
                                          : pm.paymentStatus === 3
                                          ? 'Void'
                                          : 'Draft'}
                                      </td>
                                      <td>
                                        {pm.categories == 0
                                          ? 'Upcoming'
                                          : pm.categories == 1
                                          ? 'Overdue'
                                          : 'Deferred'}
                                      </td>
                                      <td>
                                        {pm?.dueDate
                                          ? moment(pm?.dueDate).format('DD.MM.YYYY')
                                          : '-'}
                                      </td>
                                      <td>
                                        {pm?.payment_reminder?.length > 0
                                          ? moment(pm?.payment_reminder[0]?.reminderDate).format(
                                              'DD.MM.YYYY'
                                            )
                                          : '-'}
                                      </td>
                                      <td>
                                        {pm?.amountPaid?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </td>
                                      <td>
                                        {pm?.datePaid
                                          ? moment(pm?.datePaid).format('DD.MM.YYYY')
                                          : '-'}
                                      </td>
                                      <td>
                                        {pm?.outstandingAmount <= 0 ? '' : '-'}
                                        {parseFloat(
                                          getPositive(pm?.outstandingAmount)
                                        )?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </td>
                                      <td>
                                        {pm?.paymentMethod == 0
                                          ? 'Card'
                                          : pm?.paymentMethod == 1
                                          ? 'Cheque'
                                          : pm?.paymentMethod == 2
                                          ? 'Bank'
                                          : 'Cash'}
                                      </td>
                                    </tr>

                                    {pm?.payment_sub_item?.length > 0 && show[ind] == true ? (
                                      pm?.payment_sub_item.map((pmSub: any, index: any) => {
                                        return (
                                          <>
                                            <tr style={{borderBottomStyle: 'none', height: '30px'}}>
                                              <td style={{paddingLeft: '5px'}}>
                                                {pm.id}
                                                {'_'}
                                                {pmSub?.id}
                                              </td>
                                              <td>{pmSub?.name}</td>
                                              <td>
                                                {pmSub?.isLatePaymentFeesRecord
                                                  ? pmSub?.amount?.toLocaleString('en-US', {
                                                      minimumFractionDigits: 2,
                                                    })
                                                  : (
                                                      pmSub?.amount * pmSub?.quantity
                                                    )?.toLocaleString('en-US', {
                                                      minimumFractionDigits: 2,
                                                    })}
                                              </td>

                                              <td>
                                                {pmSub.paymentStatus === 0
                                                  ? 'Unpaid'
                                                  : pmSub.paymentStatus === 1
                                                  ? 'Paid'
                                                  : pmSub.paymentStatus === 2
                                                  ? 'Partially paid'
                                                  : pmSub.paymentStatus === 3
                                                  ? 'Void'
                                                  : 'Draft'}
                                              </td>
                                              <td></td>
                                              <td></td>
                                              <td></td>

                                              <td>
                                                {pmSub?.amountPaid?.toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                })}
                                              </td>
                                              <td>
                                                {pmSub?.datePaid
                                                  ? moment(pmSub?.datePaid).format('DD.MM.YYYY')
                                                  : '-'}
                                              </td>
                                              <td>
                                                {pmSub?.outstandingAmount <= 0 ? '' : '-'}
                                                {parseFloat(
                                                  getPositive(pmSub?.outstandingAmount)
                                                )?.toLocaleString('en-US', {
                                                  minimumFractionDigits: 2,
                                                })}
                                              </td>
                                            </tr>
                                            {index == pm?.payment_sub_item?.length - 1 && (
                                              <tr style={{height: '30px'}}>
                                                <td></td>
                                                <td>{' Total VAT'}</td>
                                                <td>
                                                  {pm?.totalVAT?.toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                  })}
                                                </td>
                                              </tr>
                                            )}
                                          </>
                                        )
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'>
                          <table
                            className='table align-start table-row-dashed fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className='min-w-100px'>#</th>
                                <th className='min-w-150px '>Payment</th>
                                <th className='min-w-150px'>Amount</th>
                                <th className='min-w-150px'>Date Created</th>
                                <th className='min-w-100px'>Status</th>
                                <th className='min-w-100px'>Category</th>
                                <th className='min-w-150px'>Due</th>
                                <th className='min-w-150px'>Reminder</th>
                                <th className='min-w-150px'>Amount Paid</th>
                                <th className='min-w-100px'>Date Paid</th>
                                <th className='min-w-150px'>Oustanding</th>
                                <th className='min-w-100px'>Method</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              <tr>
                                <td colSpan={10} className='text-center'>
                                  <img src={noData} alt='' width={350} />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}

                      {/* Pagination */}
                    {/* <div className='row mb-5'>
                        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
                        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                          <div
                            className='dataTables_paginate paging_simple_numbers'
                            id='kt_ecommerce_sales_table_paginate'
                          >
                            <ReactPaginate
                              breakLabel='...'
                              nextLabel='>'
                              pageClassName='paginate_button page-item +'
                              pageLinkClassName='page-link'
                              containerClassName='pagination'
                              activeClassName='active'
                              previousClassName='paginate_button page-item previous disabled'
                              nextClassName='paginate_button page-item next'
                              previousLinkClassName='page-link'
                              nextLinkClassName='page-link'
                              onPageChange={handlePageClick}
                              pageRangeDisplayed={5}
                              pageCount={pageLimit}
                              previousLabel='<'
                            />
                          </div>
                        </div>
                      </div> */}
                    {/* </div>  */}

                    {/* Payments List - State */}
                    <div className='card card-flush gap-5 p-5 pt-1 pe-2'>
                      {incomePaymentPayload?.length > 0 ? (
                        <div
                          className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'
                          id='global-table'
                          onWheel={handleMouseWheel}
                          ref={tableRef}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                        >
                          <table
                            className='table align-start table-row-dashed fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className='min-w-100px'>Payment</th>
                                <th className='min-w-150px'>Amount</th>
                                <th className='min-w-100px'>Status</th>
                                <th className='min-w-100px'>Category</th>
                                <th className='min-w-100px'>Due</th>
                                <th className='min-w-100px'>Reminder</th>
                                <th className='min-w-150px'>Amount Paid</th>
                                <th className='min-w-100px'>Method</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              {incomePaymentPayload?.map((pm: any, ind: any) => {
                                return (
                                  <>
                                    <tr
                                      style={
                                        show[ind] == true
                                          ? {borderBottomStyle: 'none', height: '30px'}
                                          : show[ind] == false
                                          ? {borderBottomStyle: 'dashed', height: '30px'}
                                          : pm?.payment_sub_item?.length == 0
                                          ? {borderBottomStyle: 'dashed', height: '30px'}
                                          : {height: '30px'}
                                      }
                                    >
                                      <td>
                                        <div className='d-flex align-items-center'>
                                          {pm?.new_payment_sub_item?.length > 0 && (
                                            <img
                                              src={show[ind] ? close : open}
                                              height='15'
                                              width='15'
                                              className='cursor-pointer'
                                              style={{marginLeft: '-30px', marginRight: '15px'}}
                                              onClick={() => {
                                                if (show[ind] == true) {
                                                  let values = [...show]
                                                  values[ind] = false
                                                  setShow(values)
                                                } else {
                                                  let values = [...show]

                                                  values[ind] = true

                                                  setShow(values)
                                                }
                                              }}
                                            />
                                          )}
                                          <a
                                            style={{
                                              textDecoration: 'underline',
                                              color: '#007a59',
                                              marginLeft: '-3px',
                                            }}
                                            onClick={() => {
                                              let values = {
                                                firstName: formData?.firstName,
                                                lastName: formData?.lastName,
                                                email: formData?.email,
                                                countryCode: formData?.countryCode,
                                                phoneNumber: formData?.phoneNumber,
                                              }
                                              setTenantDetails(values)

                                              console.log(pm?.payment?._id)
                                              if (pm?.payment?._id) {
                                                ApiGet(`corporate/payment/${pm?.payment?._id}`)
                                                  .then(async (res) => {
                                                    setDataToEdit(res?.data?.data)
                                                    setIndexToEdit(ind)
                                                    setEditIncomeModalDraft(true)
                                                  })
                                                  .catch((err) => console.log('err', err))
                                              } else {
                                                console.log(pm)
                                                setDataToEdit(pm)
                                                setIndexToEdit(ind)
                                                setEditIncomeModalDraft(true)
                                              }
                                            }}
                                          >
                                            {pm?.payment?.name}
                                          </a>
                                        </div>
                                      </td>

                                      <td>
                                        {parseFloat(pm?.payment?.totalAmount)?.toLocaleString(
                                          'en-US',
                                          {
                                            minimumFractionDigits: 2,
                                          }
                                        )}
                                      </td>

                                      <td>
                                        {pm?.payment?.paymentStatus === 0
                                          ? 'Unpaid'
                                          : pm?.payment?.paymentStatus === 1
                                          ? 'Paid'
                                          : pm?.payment?.paymentStatus === 2
                                          ? 'Partially paid'
                                          : pm?.payment?.paymentStatus === 3
                                          ? 'Void'
                                          : 'Draft'}
                                      </td>
                                      <td>
                                        {pm?.payment?.categories == 0
                                          ? 'Upcoming'
                                          : pm?.payment?.categories == 1
                                          ? 'Overdue'
                                          : 'Deferred'}
                                      </td>
                                      <td>
                                        {pm?.payment?.dueDate
                                          ? moment(pm?.payment?.dueDate).format('DD.MM.YYYY')
                                          : '-'}
                                      </td>
                                      <td>
                                        {pm?.new_payment_reminder?.length > 0
                                          ? moment(
                                              pm?.new_payment_reminder?.[0]?.reminderDate
                                            ).format('DD.MM.YYYY')
                                          : '-'}
                                      </td>
                                      <td>
                                        {pm?.payment?.amountPaid?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                      </td>
                                      <td>
                                        {pm?.payment?.paymentMethod == 0
                                          ? 'Card'
                                          : pm?.payment?.paymentMethod == 1
                                          ? 'Cheque'
                                          : pm?.payment?.paymentMethod == 2
                                          ? 'Bank'
                                          : 'Cash'}
                                      </td>
                                    </tr>

                                    {pm?.new_payment_sub_item?.length > 0 && show[ind] == true ? (
                                      pm?.new_payment_sub_item.map((pmSub: any, index: any) => {
                                        return (
                                          <>
                                            <tr style={{borderBottomStyle: 'none', height: '30px'}}>
                                              <td style={{paddingLeft: '10px'}}>{pmSub?.name}</td>

                                              <td>
                                                {pmSub?.isLateItem
                                                  ? pmSub?.amount?.toLocaleString('en-US', {
                                                      minimumFractionDigits: 2,
                                                    })
                                                  : (
                                                      pmSub?.amount * pmSub?.quantity
                                                    )?.toLocaleString('en-US', {
                                                      minimumFractionDigits: 2,
                                                    })}
                                              </td>

                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td></td>

                                              <td></td>
                                            </tr>
                                            {index == pm?.new_payment_sub_item?.length - 1 && (
                                              <tr style={{height: '30px'}}>
                                                <td style={{paddingLeft: '10px'}}>
                                                  {' Total VAT'}
                                                </td>
                                                <td>
                                                  {pm?.payment?.totalVAT?.toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                  })}
                                                </td>
                                              </tr>
                                            )}
                                          </>
                                        )
                                      })
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'>
                          <table
                            className='table align-start table-row-dashed fs-6 gy-5'
                            id='kt_ecommerce_sales_table'
                          >
                            <thead>
                              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className='min-w-100px'>Payment</th>
                                <th className='min-w-150px'>Amount</th>
                                <th className='min-w-150px'>Date Created</th>
                                <th className='min-w-100px'>Status</th>
                                <th className='min-w-100px'>Category</th>
                                <th className='min-w-150px'>Due</th>
                                <th className='min-w-150px'>Reminder</th>
                                <th className='min-w-150px'>Amount Paid</th>
                                <th className='min-w-100px'>Date Paid</th>
                                <th className='min-w-150px'>Oustanding</th>
                                <th className='min-w-100px'>Method</th>
                              </tr>
                            </thead>
                            <tbody className='fw-semibold text-gray-600'>
                              <tr>
                                <td colSpan={10} className='text-center'>
                                  <img src={noData} alt='' width={350} />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          {/* </div> */}
        </form>
      </div>
      <Modal show={showImageName} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUploadFile?.files.map((el: any, i: any) => (
            <>
              <label htmlFor='exampleFormControlInput1' className='required form-label m-0'>
                Input File Name
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                onChange={(e: any) => uploadImageName(e, el, i)}
              />
              {fileFound ? (
                <p className='mt-2' style={{color: 'red'}}>
                  Filename Exist already
                </p>
              ) : null}
            </>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={saveImageFileName}
            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
            disabled={modalBtnIsLoading}
          >
            Save {''}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Property Management '})}</PageTitle>
      <DashboardPage /> */}
      <TenanciesFilter
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false)
        }}
        // type={type}
        showModal={showModal}
        // building={building}
        // updateDataId={updateDataId}
        // isEdit={isEdit}
        // formData={formData}
        // propertiType={propertiType}
        // sethandleSubmitIdv={sethandleSubmitIdv}
        handleSubmitId={handleSubmitId}
        // handleChnage={handleChnage}
        // imageChange={imageChange}
        unitData={unitData._id}
      />

      {/* {incomeModal && (
        <IncomingPaymentModal
          show={incomeModal}
          handleClose={() => setIncomeModal(false)}
          paymentItems={filteredPaymentItems}
          tenantDetails={tenantDetails}
          handlePayload={updateIncomePaymentPayload}
          allPayments={() => getAllPayments()}
          unitDetails={unitData}
          tenancyId={flg == '1' ? newTenancyID : tenancyId}
          from={'previouscode'}
        />
      )} */}

      {/* Edit Payment Modal */}
      {editIncomeModal && (
        <EditPaymentModal
          show={editIncomeModal}
          handleClose={() => {
            setEditIncomeModal(false)
          }}
          incomingPaymentId={''}
          id={''}
          subItemId={''}
          subId={''}
          getAllPayments={getAllPayments}
          unitDetails={unitDetails}
          detailsToEdit={dataToEdit}
          type={'tenancy'}
        />
      )}

      {/* {(editIncomeModalDraft && filteredPaymentItems)  && ( */}
        {/* <EditPaymentModalDraft
          show={editIncomeModalDraft}
          handleClose={() => {
            setEditIncomeModalDraft(false)
          }}
          paymentItems={filteredPaymentItems}
          tenantDetails={tenantDetails}
          handlePayload={updateIncomePaymentPayload}
          allPayments={() => getAllPayments()}
          unitDetails={unitData}
          tenancyId={flg == '1' ? newTenancyID : tenancyId}
          detailsToEdit={dataToEdit}
          indexToUpdate={indexToEdit}
        /> */}
       {/* )}  */}

      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={folderFileModal}
        onHide={() => {
          setFolderFileModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            // minHeight: '150px',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setFolderFileModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>

          {showBck && (
            <div className='d-flex align-items-center mb-3'>
              <span
                className='rounded-circle'
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setFileType('other')
                  setShowBck(false)
                }}
              >
                <img src={backArrow} style={{stroke: 'red'}} height='14' width='14' />
              </span>
              <h2 className='m-0 head-text ms-2'>{selectedFolder?.name}</h2>
            </div>
          )}

          {fileType == 'other' ? (
            <>
              {tenantFolders?.length > 0 ? (
                <>
                  <div className='d-flex'>
                    {tenantFolders?.map((folder: any) => {
                      return (
                        <a
                          onClick={() => {}}
                          className='text-gray-800 text-hover-primary d-flex flex-column mx-5 text-center'
                        >
                          <div
                            className='symbol symbol-60px mb-5'
                            onClick={() => {
                              if (folder?.name == 'Passports') {
                                setDataToSelectFrom(passportFolder)
                                setFileType('passport')
                              } else if (folder?.name == 'Contracts') {
                                setDataToSelectFrom(contractsFolder)
                                setFileType('contract')
                              } else if (folder?.name == 'IDs') {
                                setDataToSelectFrom(iDsFolder)
                                setFileType('ids')
                              } else if (folder?.name == 'Residencies') {
                                setDataToSelectFrom(residencyFolder)
                                setFileType('residency')
                              }
                              setSelectedFolder(folder)
                              setShowBck(true)
                            }}
                          >
                            <img src={folderDocument} className='theme-light-show' alt='' />
                            <img
                              src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                              className='theme-dark-show'
                              alt=''
                            />
                          </div>
                          <div className='fs-5 fw-bold mb-2'>{folder?.name}</div>
                        </a>
                      )
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div className='d-flex justify-content-center'>
                    {' '}
                    <div className=''>
                      {' '}
                      <h2 className='head-text'>No Files...</h2>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {dataToSelectFrom?.fileList?.length > 0 ? (
                <div className='tops mw-350px d-flex gap-5 align-items-start mx-5'>
                  {dataToSelectFrom?.fileList?.map((file: any, ind: any) => {
                    return (
                      <div className='imgs-set'>
                        <div className='d-flex mw-350px me-3'>
                          <img
                            src={Bucket + file?.fileURL}
                            width='100'
                            height='100'
                            className='main_img'
                            onClick={() => {
                              if (showBck) {
                                setuploadOtherImgTenant([...uploadOtherImgTenant, file?.fileURL])
                              } else {
                                if (fileType == 'passport') setuploadPassportTenant([file?.fileURL])
                                else if (fileType == 'contract')
                                  setuploadContractTenancy([file?.fileURL])
                                else if (fileType == 'residency')
                                  setuploadresidencyTenant([file?.fileURL])
                                else if (fileType == 'ids') setuploadIdTenant([file?.fileURL])
                              }

                              setIsFormDirty(true)

                              setFolderFileModal(false)

                              // setTimeout(() => {
                              //   autoSaveTenancy()
                              // }, 2500)
                            }}
                          />
                        </div>
                        <p className=''>
                          {getFilenameToSelect(file?.fileName, false)?.length > 6
                            ? `${file?.fileName?.slice(0, 6)}...${getFilenameToSelect(
                                file?.fileName,
                                true
                              )}`
                            : file?.fileName}
                        </p>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className='d-flex justify-content-center'>
                  {' '}
                  <div className=''>
                    {' '}
                    <h2 className='head-text'>No Files...</h2>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Modal>

      {/* Add New tenant Modal */}
      {addNewTenant && (
        <AddNewTenant
          show={AddNewTenant}
          handleClose={() => setAddNewTenant(false)}
          refetchTenants={() => getTenantData()}
          onSuccess={(val:any)=>{}}
        />
      )}
    </>
  )
}

export default CreateTenant
