import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import noData from '../../../img/NoData1.svg'
import {ApiGet, ApiPost, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import plusImg from '../../../img/add.png'
import {BsArrowRightSquareFill} from 'react-icons/bs'
import AddFeedbackModal from './AddFeedBackModal'
import {useDispatch, useSelector} from 'react-redux'
import {setComefrom} from '../../../redux/counterSlice'
import Select from 'react-select'

function Feedback() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)

  const [feedbackList, setFeedBackList] = useState<any>()
  const [addModal, setAddModal] = useState<any>(false)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)

  const [perPage, setPerPage] = useState<any>(50)

  const [corporateDate, setCorporateData] = useState<any>([])

  const [searchName, setSearchName] = useState<any>('')
  const [createdByIds, setCreatedByIds] = useState<any>([])
  const [status, setStatus] = useState<any>()

  const [defaultSelectedOptions, setDefaultSelectedOptions] = useState<any>()

  const options: any = [
    {value: null, label: 'Status'},
    {value: '0', label: 'Pending'},
    {value: '1', label: 'Waqar In-Progress'},
    {value: '2', label: 'Waqar Completed'},
    {value: '3', label: 'Mukund In-Progress'},
    {value: '4', label: 'Mukund Completed'},
    {value: '5', label: 'Ketan In-Progress'},
    {value: '6', label: 'Ketan Completed'},
    {value: '7', label: 'Emad Confirmed'},
    {value: '8', label: 'User Confirmed'},
  ]

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#fff',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '0.475rem',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '600'}),
    valueContainer: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      display: 'flex',
      flexWrap: 'nowrap',

      maxWidth: '100%',
      overflowX: 'scroll',
    }),
    multivalue: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      whiteSpace: 'nowrap',
      marginRight: '4px',
    }),
  }

  //
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
    getAllFeedbackList(event.selected + 1, perPage)
  }

  //
  const getAllFeedbackList = (val: any, records: any) => {
    const body = {
      limit: parseInt(records),
      page: parseInt(val),
      status: status ? parseInt(status) : null,
      createdByIds: createdByIds,
      searchName: searchName,
    }
    ApiPost(`corporate/feedback/get`, body)
      .then((res) => {
        setFeedBackList(res?.data?.data?.feedback_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch(() => {})
  }

  const getCorporate = () => {
    ApiGet(`corporate/account/all`)
      .then((res) => {
        setCorporateData(res?.data?.data)
      })
      .catch(() => {})
  }

  useEffect(() => {
    let temp: any = localStorage.getItem('fb_CreatedIds')
    let ids : any = []
    // if(temp){
    //   ids = JSON.parse(temp)
    // }

    let name: any = localStorage.getItem('fb_name')
    let st: any = localStorage.getItem('fb_status')
    let val2: any = localStorage.getItem('fb_records')
    if (comefrom == 'feedback') {
      let val: any = localStorage.getItem('fb_page')
      if (val != null) {
        setPage(val)
        setPerPage(val2)
        // setSearchName(name)
        // setCreatedByIds(ids)
        // setStatus(Number(st))
        // getAllFeedbackList(val, val2, name, ids, st)
        getAllFeedbackList(val, val2)
      
      } else {
        setPage(1)
        setPerPage(50)
        // setSearchName(name)
        // setCreatedByIds(ids)
        // setStatus(Number(st))
        // getAllFeedbackList(1, perPage, name, ids, st)
        getAllFeedbackList(1, perPage)
      }
    } else {
      setPage(1)
      setPerPage(val2)
      // setSearchName(name)
      // setCreatedByIds(ids)
      // setStatus(Number(st))
      // getAllFeedbackList(1, val2, name, ids, st)
      getAllFeedbackList(1, val2)
    }
  }, [status, createdByIds, searchName])

  useEffect(() => {
    getCorporate()
  }, [])

  return (
    <>
      <div
        id=''
        className='pt-0 mt-0 no-print px-2'
        style={{padding: '0px 0px 0px 14px'}}
      >
        <div className='d-flex align-items-center'>
          <div className='page-title d-flex flex-wrap min-w-200px'>
            <h1 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0'>
              Feedback
            </h1>
          </div>
          <div className='d-flex ms-auto align-items-center'>
            <button
              className='btn btn-sm fw-bold green-hollow-btn btn-fit-content px-3'
              style={{backgroundColor: '', border: '2px solid #007a59', color: '#007a59'}}
              onClick={() => {
                setAddModal(true)
              }}
            >
              <img
                src={plusImg}
                height={20}
                width={20}
                className='me-4'
                
              />
              Add Feedback
            </button>
            <div className='ps-5 d-flex align-items-center'>
              <div className='d-flex'>
                <select
                  className='form-select me-2'
                  data-kt-select2='true'
                  data-placeholder='Select option'
                  data-dropdown-parent='#kt_menu_631f08e971923'
                  data-allow-clear='true'
                  value={perPage}
                  onChange={(e: any) => {
                    setPerPage(e.target.value)
                    setPage(1)
                    getAllFeedbackList(page, e.target.value)
                    localStorage.setItem('fb_records', e.target.value)
                  }}
                >
                  <option value={10}>10 records per page</option>
                  <option value={25}>25 records per page</option>
                  <option value={50}>50 records per page</option>
                  <option value={100}>100 records per page</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pe-2 mt-5 px-2'>
          <div className='d-flex align-items-center py-5 pt-2 gy-3 row mx-0 mt-4'>
            <div className='mb-2 col-md-3 pe-5'>
              <input
                type='text'
                className='form-control mytest'
                placeholder='Search by Name..'
                name='searchTenant'
                value={searchName}
                onChange={(e: any) => {
                  // localStorage.setItem('fb_name', e.target.value)
                  setSearchName(e.target.value)
                  // getAllFeedbackList(page, perPage, e.target.value, createdByIds, status)
                }}
              />
            </div>

            <div className='mb-2 col-md-3 ps-5 pe-5'>
              <Select
                options={
                  corporateDate &&
                  corporateDate?.map((crp: any, index: any) => {
                    return {
                      label: `${crp?.firstName} ${crp?.lastName}`,
                      value: `${crp?._id}`,
                    }
                  })
                }
                styles={customStyles}
                isSearchable={false}
                isMulti
                // className="basic-multi-select"
                placeholder={'CreatedBy..'}
                onChange={(e: any) => {
                  console.log(e)
                  let values: any = []
                  for (let i = 0; i < e?.length; i++) {
                    values[i] = e[i]?.value
                  }
                  setCreatedByIds(values)
                 
                  // getAllFeedbackList(page, perPage, searchName, values, status)
                  // localStorage.setItem('fb_CreatedIds', JSON.stringify(values))
                }}
                value={defaultSelectedOptions}
                className='custom-select'
              />
            </div>
            <div className='mb-2 col-md-3 ps-5 pe-5'>
              <Select
                options={options}
                defaultValue={
                  status == 0
                    ? options[1]
                    : status == 1
                    ? options[2]
                    : status == 2
                    ? options[3]
                    : status == 3
                    ? options[4]
                    : status == 4
                    ? options[5]
                    : status == 5
                    ? options[6]
                    : status == 6
                    ? options[7]
                    : status == 7
                    ? options[8]
                    : status == 8
                    ? options[9]
                    : options[0]
                }
                styles={customStyles}
                isSearchable={false}
                placeholder={'Status...'}
                onChange={(e: any) => {
                  setStatus(e.value)
                  // localStorage.setItem('fb_status', e.value)
                  // getAllFeedbackList(page, perPage, searchName, createdByIds, e.value)
                }}
              /> 
            </div>
          </div>

          {feedbackList?.length > 0 ? (
            <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5 px-4' id='global-table'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-50px'>ID</th>
                    <th className='min-w-100px '>Feedback Point</th>
                    <th className='min-w-100px'>Created By</th>
                    <th className='min-w-100px'>Last Updated</th>
                    <th className='min-w-100px'>Status</th>
                    <th className='min-w-100px'></th>
                  </tr>
                </thead>
                <tbody>
                  {feedbackList?.map((fd: any, ind: any) => {
                    return (
                      <tr style={{borderBottomStyle: 'dashed', height: '40px'}}>
                        <td>{fd?.id}</td>
                        <td className='py-3'>{fd?.name}</td>

                        <td className='py-3'>
                          {fd?.corporate?.[0] &&
                            `${fd?.corporate?.[0]?.firstName} ${fd?.corporate?.[0]?.lastName} - `}
                          {fd?.createdAt
                            ? `${moment(fd?.createdAt).format('DD/MM/YYYY hh:mm A')}`
                            : '-'}
                        </td>
                        <td className='py-3'>
                          {fd?.statusDateTime
                            ? `${moment(fd?.statusDateTime).format('DD/MM/YYYY hh:mm A')}`
                            : '-'}
                        </td>
                        <td className='py-3'>
                          {fd?.status == 0
                            ? 'Pending'
                            : fd?.status == 1
                            ? 'Waqar In Progress'
                            : fd?.status == 2
                            ? 'Waqar Completed'
                            : fd?.status == 3
                            ? 'Mukund In Progress'
                            : fd?.status == 4
                            ? 'Mukund Completed'
                            : fd?.status == 5
                            ? 'Ketan In Progress'
                            : fd?.status == 6
                            ? 'Ketan Confirmed'
                            : fd?.status == 7
                            ? 'Emad Confirmed'
                            : fd?.status == 8
                            ? 'User Confirmed'
                            : '-'}
                        </td>

                        <td className='text-start' data-order='2022-09-11'>
                          <BsArrowRightSquareFill
                            color='#007a59'
                            fontSize={18}
                            style={{cursor: 'pointer'}}
                            className='mx-3'
                            onClick={() => {
                              localStorage.setItem('fb_page', page)
                              localStorage.setItem('fb_records', perPage)
                              dispatch(setComefrom('feedback'))
                              navigate(`/feedback/${fd?._id}`)
                            }}
                          />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5 px-4' id='global-table'>
              <table
                className='table align-start table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-50px'>ID</th>
                    <th className='min-w-100px '>Feedback Point</th>
                    <th className='min-w-100px'>Created By</th>
                    <th className='min-w-100px'>Last Updated</th>
                    <th className='min-w-100px'>Status</th>
                    <th className='min-w-100px'></th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  <tr>
                    <td colSpan={10} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous disabled'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                  forcePage={page - 1}
                  // initialPage={page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {addModal && (
        <AddFeedbackModal
          show={addModal}
          handleClose={() => setAddModal(false)}
          getDetails={() => {
            getAllFeedbackList(page, perPage)
          }}
        />
      )}
    </>
  )
}

export default Feedback
