import React, {useEffect, useState, useRef} from 'react'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import 'antd/dist/antd.css'
import moment from 'moment'
import backArrow from '../../../../img/back-arrow.png'
import plus from '../../../../img/add-white.png'
import open from '../../../../img/payment-col-open.png'
import close from '../../../../img/payment-col-close.png'
import {
  ApiPost,
  Bucket,
  ApiGet,
  ApiGetNoAuth,
  ApiPut,
  ApiDelete,
  ApiUpload,
} from '../../../../apiCommon/helpers/API/ApiData'
import Select from 'react-select'
import {DatePicker, Space} from 'antd'
import closes from '../../../../img/close.svg'
import window from '../../../../img/window.png'
import ReactPaginate from 'react-paginate'
import {CarTwoTone} from '@ant-design/icons'
import {ErrorToast} from '../../../../apiCommon/helpers/Toast'
import noData from '../../../../img/NoData1.svg'
import {AnyIfEmpty} from 'react-redux'
import {Controller, useForm} from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'

import file from '../../../../img/file.svg'
import pen from '../../../../img/pen.png'
import {SuccessToast} from '../../../../apiCommon/helpers/Toast'
import {AiFillDelete} from 'react-icons/ai'
import swal from 'sweetalert2'
import TenancyDetails from '../../Tenancies/TenancyDetails'
import Swal from 'sweetalert2'
import {AiOutlineEye} from 'react-icons/ai'
import {BsArrowRightSquareFill} from 'react-icons/bs'
import {Row, Col} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'

const Annoucements = () => {
  const [formData, setFormData] = useState<any>({
    // propertyId: window.location.pathname?.split('/')[2],
    // propertyAreaId: window.location.pathname?.split('/')[3],
  })

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)

  const navigate = useNavigate()
  const {state} = useLocation()

  const {unitId} = useParams()

  let temp = localStorage.getItem('tenancyContract')
  const [tenancyContractNo, setTenancyContractNo] = useState(temp)

  const [page, setPage] = useState<any>(1)
  const [annouces, setAnnouces] = useState<any>()
  const [pageLimit, setPageLimit] = useState<any>(1)

  // get unit details
  const getAnnoucements = () => {
    let body:any

    if(state?.tenancyId != undefined)
       {
    body = {
      page: page,
      limit: 10,
      unitId: unitId,
      tenancyId: state?.tenancyId
    }
  }
  else{
    body = {
      page: page,
      limit: 10,
      unitId: unitId
    }
  }

    ApiPost(`corporate/announcement/overview`, body)
      .then((res) => {
        setAnnouces(res?.data?.data?.announcement_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((err) => console.log('err', err))
  }

  //
  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const deleteAnnoucements = (id: any) => {
    ApiDelete(`corporate/announcement/${id}`)
      .then((response) => {
        SuccessToast(response?.data?.message)
        getAnnoucements()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    setTimeout(() => {
      getAnnoucements()
    }, 1000)
  }, [page])

  return (
    <>
      <div
        id=''
        className='pt-0 mt-0 no-print px-2'
        style={{padding: '0px 0px 0px 14px'}}
      >
        <div className='d-flex align-items-start gap-2 mb-0 gap-lg-3 '>
          <div className='d-flex align-items-center gap-5 mt-2'>
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => {
                if(state?.tenancyId != undefined)
                navigate('/tenancies')
                else{
                  if(comefrom == 'properties')
                  navigate('/dashboard')
                  else if(comefrom == 'build')
                  navigate('/building-dashboard')
                  else 
                  navigate('/community-dashboard')
                }
              }
            }
            >
              <img src={backArrow} style={{stroke: 'red'}} height='19px' width='19px' />
            </span>
            {state?.tenancyId != undefined ? (
              <h1 className='page-heading m-0 head-text'>
                Property Profile - {tenancyContractNo}
              </h1>
            ) : (
              <h1 className='page-heading m-0 head-text'>Property Profile</h1>
            )}
          </div>
          {/* Propert Profile Tabs */}
          <div
            className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center mx-5 ps-5'
            style={{marginBottom: '28px'}}
          >
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 me-5 px-5 e'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Overview
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5 '
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/tenancy`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Tenancy
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/tenant`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Tenants
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/financials/income`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Financials
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5 active'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/annoucements`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Annoucements
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link text-active-primary pb-2 mx-5 px-5'
                  data-bs-toggle='tab'
                  onClick={() =>
                    navigate(`/overViewData/${unitId}/requests`, {
                      state: {
                        details: state.details,
                        id: unitId,
                        tenancyId: state?.tenancyId
                      },
                    })
                  }
                >
                  Requests
                </a>
              </li>
            </ul>
          </div>
        </div>
        <Row className='my-3'>
          { state?.tenancyId == undefined && (
          <Col lg={3} className='d-flex justify-content-end ms-auto'>
            <div
              className='btn btn-sm fw-bold btn-primary btn-green hover-none'
              onClick={() => {
                navigate('/create-announcements', {
                  state: {
                    unit: state?.details?.details,
                  },
                })
              }}
            >
              Create
            </div>
          </Col>
          )}
        </Row>

        <div className='p-0 ' style={{backgroundColor: 'none'}}>
          <div className='my-5' style={{backgroundColor: 'none'}}>
            <div className='card card-flush px-5 py-2 h-100'>
              <div
                className='table-responsive mb-5'
                // onWheel={handleMouseWheel}
                // ref={tableRef}
                // onMouseEnter={handleMouseEnter}
                // onMouseLeave={handleMouseLeave}
              >
                <table
                  className='table align-middle table-row-dashed fs-6 gy-5'
                  id='kt_ecommerce_sales_table'
                >
                  <thead>
                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                      <th className='text-start min-w-50px'>#</th>
                      <th className='text-start min-w-100px'>Title</th>
                      <th className='text-start min-w-100px'>Status</th>

                      {/* <th className='text-start min-w-100px'>Development</th> */}
                      <th className='text-start min-w-100px'>Total Tenants</th>
                      <th className='text-start min-w-100px'>Start - End </th>

                      <th className='text-start min-w-100px'></th>
                    </tr>
                  </thead>
                  <tbody className='fw-semibold text-gray-600'>
                    {annouces?.length > 0 ? (
                      <>
                        {annouces?.map((an: any, i: any) => {
                          return (
                            <>
                              <tr>
                                <td
                                  data-kt-ecommerce-order-filter='order_id'
                                  className='text-start'
                                >
                                  {i + 1}
                                </td>
                                <td
                                  data-kt-ecommerce-order-filter='order_id'
                                  className='text-start'
                                >
                                  {an?.title}
                                </td>
                                <td className='text-start'>
                                  {' '}
                                  <span
                                  // className={
                                  //   an?.tenancyStatus == 1
                                  //     ? 'active-color'
                                  //     : tn?.tenancyStatus === 3
                                  //     ? 'ended-color'
                                  //     : tn?.tenancyStatus === 4
                                  //     ? 'renewed-color'
                                  //     : tn?.tenancyStatus === 5
                                  //     ? 'terminated-color'
                                  //     : ''
                                  // }
                                  >
                                    {' '}
                                    <b className=''>{an?.saveStatus === 0 ? 'Draft' : 'Saved'}</b>
                                  </span>
                                </td>
                                <td
                                  data-kt-ecommerce-order-filter='order_id'
                                  className='text-start'
                                >
                                  {an?.tenantIds?.length}
                                </td>
                                <td className='text-start' data-order='2022-09-11'>
                                  {an?.startDate ? moment(an?.startDate).format('YYYY.MM.DD') : '-'}
                                  {' - '}{' '}
                                  {an?.endDate ? moment(an?.endDate).format('YYYY.MM.DD') : '-'}
                                </td>

                                <td className='text-start'>
                                  <div className='menu-item px-3 d-flex'>
                                    {an?.saveStatus == 1 && (
                                      <BsArrowRightSquareFill
                                        color='#007a59'
                                        fontSize={18}
                                        style={{cursor: 'pointer'}}
                                        className='mx-3'
                                        onClick={() => navigate(`/viewannouncement/${an?._id}`,
                                        {
                                          state: {
                                            unit: state?.details?.details,
                                          },
                                        })}
                                      />
                                    )}

                                    {an?.saveStatus == 0 && (
                                      <img
                                        src={pen}
                                        height='18'
                                        width='18'
                                        className='cursor-pointer mx-3'
                                        onClick={() =>
                                          navigate(`/edit-announcement/${an?._id}`, {
                                            state: {
                                              id: an?.announcement_recipient?._id,
                                              unit: state?.details?.details,
                                            },
                                          })
                                        }
                                        style={{cursor: 'pointer'}}
                                      />
                                    )}

                                    <AiFillDelete
                                      color='#D72852'
                                      fontSize={20}
                                      // onClick={() => deleteRow(i)}
                                      onClick={() => {
                                        Swal.fire({
                                          text: 'Are you sure you want to permanently delete this Annoncements Draft?',
                                          icon: 'warning',
                                          showConfirmButton: true,
                                          confirmButtonColor: '#D72852',
                                          confirmButtonText: 'Yes',
                                          showCancelButton: true,
                                          // cancelButtonColor: "transparent",
                                          cancelButtonText: 'Cancel',
                                        }).then((res: any) => {
                                          if (res.isConfirmed) {
                                            deleteAnnoucements(an?._id)
                                          }
                                        })
                                      }}
                                      style={{cursor: 'pointer'}}
                                    />
                                  </div>
                                </td>
                              </tr>
                            </>
                          )
                        })}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={15} className='text-center'>
                          <img src={noData} alt='' width={350} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-end justify-content-md-end mt-5'>
                <div
                  className='dataTables_paginate paging_simple_numbers'
                  id='kt_ecommerce_sales_table_paginate'
                >
                  <ReactPaginate
                    breakLabel='...'
                    nextLabel='>'
                    pageClassName='paginate_button page-item +'
                    pageLinkClassName='page-link'
                    containerClassName='pagination'
                    activeClassName='active'
                    previousClassName='paginate_button page-item previous'
                    nextClassName='paginate_button page-item next'
                    previousLinkClassName='page-link'
                    nextLinkClassName='page-link'
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageLimit}
                    previousLabel='<'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Annoucements
