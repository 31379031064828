import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Switch from 'react-switch';
import { ApiDelete, ApiGet, ApiPost, ApiPut } from '../../../apiCommon/helpers/API/ApiData';
import { SuccessToast, ErrorToast } from '../../../apiCommon/helpers/Toast';
import InvConfigureModal from './InvConfigureModal';
import backArrow from '../../../img/back-arrow.png';
import invoiceImg from '../../../img/invoice-blue.png';
import editpen from '../../../img/pen.png';

interface InvoiceSequence {
  startNumber: number;
  increment: number;
  noOfDigits: number;
  prefix: string | null;
  suffix: string | null;
  lastCount: number;
  activityStatus: number;
}

const Invoicing: React.FC = () => {
  const navigate = useNavigate();
  const [invNoModal, setInvNoModal] = useState(false);
  const [details, setDetails] = useState<InvoiceSequence | null>(null);
  const [invoiceSeq, setInvoiceSeq] = useState<InvoiceSequence | null>(null);

  const getDetails = () => {
    ApiGet('corporate/corporate_setting')
      .then((res) => {
        const data = res?.data?.data;
        setDetails(data);
        setInvoiceSeq(data?.invoiceSequence);
      })
      .catch((err) => ErrorToast(err.message));
  };

  const updateActivityStatus = (isActive: boolean) => {
    const status = isActive ? 1 : 0;
    ApiPut('corporate/corporate_setting/invoice_activity', { activityStatus: status })
      .then(() => getDetails())
      .catch((err) => ErrorToast(err.message));
  };

  useEffect(() => {
    getDetails();
  }, []);

  const renderLabel = (label: string, value: any) => (
    <Col xs={6} sm={6} md={4} lg={4}>
      <div className="d-flex grid_big_col py-2 fs-7">
        <label className="form-label blue-label fs-7 light-dark-theme-color" style={{ minWidth: '115px' }}>
          {label}
        </label>
        <div className="d-flex white-dark-theme-color">{value ?? '-'}</div>
      </div>
    </Col>
  );

  return (
    <>
      <div className="px-2">
        <div className="d-flex align-items-center mb-10">
          <span className="rounded-circle cursor-pointer" onClick={() => navigate('/settings')}>
            <img src={backArrow} height={14} width={14} className="me-2" alt="Back" />
          </span>
          <h2 className="page-heading m-0 head-text">Invoicing</h2>
          <div className="ms-auto">
            <img src={invoiceImg} height="40px" width="30px" alt="Invoice" />
          </div>
        </div>

        <div className="d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center mb-5">
          <ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2">
            <li className="nav-item">
              <a className="nav-link text-active-primary pb-2 me-5 active" onClick={() => navigate('/settings/invoicing')}>
                Invoice No. Generator
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-active-primary pb-2 mx-5 white-dark-theme-color" onClick={() => navigate('/settings/invoice-design')}>
                Invoice Design
              </a>
            </li>
          </ul>
        </div>

        <Row>
          <Col md={12} className="pe-5">
            <div className="card px-5 py-5 mt-5">
              <div className="d-flex">
                <Switch
                  onChange={updateActivityStatus}
                  checked={!!invoiceSeq?.activityStatus}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  onColor="#00cf99"
                  offColor="#b0bec5"
                  className="ms-auto me-5"
                />
                <img
                  src={editpen}
                  alt="Edit"
                  className="ms-4 me-2"
                  style={{ cursor: 'pointer', height: '22px', width: '22px', marginTop: '3px' }}
                  onClick={() => setInvNoModal(true)}
                />
              </div>

              <Row className="mt-3">
                {renderLabel('Start No.', invoiceSeq?.startNumber)}
                {renderLabel('Increment By', invoiceSeq?.increment)}
                {renderLabel('No. of Digits', invoiceSeq?.noOfDigits)}
                {renderLabel('Prefix', invoiceSeq?.prefix)}
                {renderLabel('Suffix', invoiceSeq?.suffix)}
                {renderLabel('Last Count', invoiceSeq?.lastCount)}
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      {invNoModal && (
        <InvConfigureModal
          show={invNoModal}
          handleClose={() => setInvNoModal(false)}
          type="invoice"
          invSeq={invoiceSeq}
          getDetails={getDetails}
        />
      )}
    </>
  );
};

export default Invoicing;
