import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import queryString from 'query-string';
import { ApiGet, ApiPost } from '../../../apiCommon/helpers/API/ApiData';
import { ErrorToast } from '../../../apiCommon/helpers/Toast';
import moment from 'moment';

function FacilityTeam() {
    const [formData, setFormData] = useState<any>({})
    const [facilityData,setFacilityData] = useState<any>()
    const [count,setCount] = useState<any>()
    const navigate = useNavigate()

    const handleChnage = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    const getCount = ()=>{
        ApiGet('corporate/main_service/count')
        .then((res) => {
            setCount(res?.data?.data)
        })
        .catch((err) => ErrorToast(err.message))
    }

    const getFacilityList =()=>{
        const body ={
            page: 1,
            limit: 10,
            search: ""
        }
        ApiPost('corporate/main_service/overview', body)
        .then((res) => {
            setFacilityData(res?.data?.data?.services_data)
        })
        .catch((err) => ErrorToast(err.message))
    }

    useEffect(()=>{
        getFacilityList()
        getCount()
    },[])

    return (
        <>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>
                    <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 min-w-200px'>
                        <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
                            Facilities Management
                        </h1>
                    </div>
                    <div className='d-flex align-items-center gap-2 gap-lg-3'>
                        <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/facility-maintenancePlanner`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                                    Maintenance Planner
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/facility-team`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                                    Team
                                </a>
                            </li>
                            <li
                                className='nav-item'
                                onClick={() => {
                                    navigate(`/facility`)
                                }}
                            >
                                <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                                    Services List
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FacilityTeam;