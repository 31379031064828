import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ApiPost } from '../../../apiCommon/helpers/API/ApiData';
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast';

function ManagementRequests() {
    const navigate = useNavigate()
    const [requestList,setRequestList] = React.useState<any>()
    const [requestCount,setRequestCount] = React.useState<any>()
    const [pageLimit, setPageLimit] = useState<any>()
    const [page, setPage] = useState<any>(1)

    const getRequestPagination = async()=>{
        const body ={
            page: page,
            limit: 10
        }
        await ApiPost(`corporate/vendor/request`, body)
        .then((res) => {
            setRequestList(res?.data?.data?.vendor_data)
            setRequestCount(res?.data?.data?.state?.data_count)
            setPageLimit(res?.data?.data?.state?.page_limit)

        })
        .catch((err) => ErrorToast(err.message))
    }

    React.useEffect(()=>{
        getRequestPagination()
    },[page])

    const handlePageClick = (event: any) => {
        setPage(event.selected + 1)
    }
   
    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div id='' className='app-container container-xxl pt-0 mt-0'>
                <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                    <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/vendor-management/management-list`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                                Lists
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/vendor-management/management-vendors`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                                Vendors
                            </a>
                        </li>
                        <li
                            className='nav-item'
                            onClick={() => {
                                navigate(`/vendor-management/management-requests`)
                            }}
                        >
                            <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                                Requests
                            </a>
                        </li>
                    </ul>

                </div>
                <div className='d-flex mb-10'>
                    <h5 className='gray-500 me-10 mb-0 w-200px'>
                        Total Requests:{' '}
                        <span className='' style={{ color: 'black' }}>
                            {requestCount}
                        </span>
                    </h5>

                </div>
            
            <div className='card card-flush px-2'>
                <div className='card-body pt-0 table-responsive px-3'>
                    <table
                        className='table align-middle table-row-dashed fs-6 gy-5 mt-2'
                        id='kt_ecommerce_sales_table'
                    >
                        <thead>
                            <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                <th className='min-w-100px'>Vendor ID </th>
                                <th className='min-w-150px'>Vendor Status</th>
                                <th className='min-w-100px'>Vendor Name</th>
                                <th className='min-w-70px'>Type</th>
                                <th className='min-w-70px'>TRN</th>
                                <th className='min-w-150px'>Trade License</th>
                                <th className='min-w-70px'>City</th>
                                <th className='min-w-70px'>Main Contact (Email)</th>
                                <th className='min-w-200px'>Main Contact (Mob. No.)</th>
                                <th className='min-w-70px'>Requestor</th>
                                <th className='min-w-70px'>Completed By</th>
                                <th className='min-w-100px'>Pending with</th>
                               
                            </tr>
                        </thead>
                        <tbody className='fw-semibold text-gray-600'>
                            {requestList?.map((i:any, index:any) => {
                                return (
                                    <tr>
                                        <td style={{ cursor: 'pointer' }} onClick={()=>navigate(`/vendor-management/management-vendors/approvalWorkflow/${i?._id}`)}><a style={{textDecoration: 'underline', color:' #1890ff'}}>{i?.id}</a></td>
                                        <td>{i?.saveStatus === 0?'Draft':'Save'}</td>
                                        <td>{i?.firstName}{i.lastName}</td>
                                        <td>{i?.vendorType === 0 ?'Service Provider' : ''}</td>
                                        <td>{i?.TRN}</td>
                                        <td>{i?.tradeLicenseNo}</td>
                                        <td>{i?.city}</td>
                                        <td>{i?.email}</td>
                                        <td>{i?.phoneNumber}</td>
                                        <td>{i?.requestor[0]?.firstName}{i?.requestor[0]?.lastName}-{moment(i?.requestor[0]?.updatedAt).format('DD/MM/YYYY  HH:mm:ss')}</td>
                                        <td>{i?.approval_request[0]?.complete_with[0]?.user[0]?.firstName}{i?.approval_request[0]?.complete_with[0]?.user[0]?.lastName}{moment(i?.approval_request[0]?.complete_with[0]?.user[0]?.updatedAt).format('DD/MM/YYYY  HH:mm:ss')}</td>
                                        <td>{i?.approval_request[0]?.pending_with[0]?.user[0]?.firstName}{i?.approval_request[0]?.pending_with[0]?.user[0]?.lastName}{moment(i?.approval_request[0]?.pending_with[0]?.user[0]?.updatedAt).format('DD/MM/YYYY  HH:mm:ss')}</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
                <div className='row mb-5'>
                        <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                        </div>
                        <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                            <div
                                className='dataTables_paginate paging_simple_numbers'
                                id='kt_ecommerce_sales_table_paginate'
                            >
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    pageClassName="paginate_button page-item +"
                                    pageLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    previousClassName="paginate_button page-item previous disabled"
                                    nextClassName="paginate_button page-item next"
                                    previousLinkClassName="page-link"
                                    nextLinkClassName="page-link"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={5}
                                    pageCount={pageLimit}
                                    previousLabel="<"
                                />
                            </div>
                        </div>
                    </div>
            </div>
            </div>
        </div >
    );
}

export default ManagementRequests;