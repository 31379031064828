import moment from 'moment';
import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ApiGet, ApiPost, ApiPut, Bucket } from '../../../apiCommon/helpers/API/ApiData';
import { ErrorToast, SuccessToast } from '../../../apiCommon/helpers/Toast';
import NewMessage from '../Tenants/NewMessage';
import MediaComments from './MediaComments';
import MediaPopUp from './MediaPopUp';

function ExternalRequestLogTab() {
    const navigate = useNavigate()
    const [showMedia, setShowMedia] = useState<any>(false)
    const Vendors = new Array(2).fill(0);
    const [signatureModal, setSignatureModal] = React.useState<boolean>(false)
    const [formData, setFormData] = React.useState<any>({})
    const [mediaPopup, setMediaPopup] = React.useState<any>(false)
    const [hide, setHide] = React.useState<any>(false)
    const [requestData, setRequestData] = React.useState<any>()
    const [serviceData, setServicedata] = React.useState<any>()
    const [approvalRequest, setApprovalRequest] = React.useState<any>()
    const [propertyList, setPropertyList] = React.useState<any>()
    const [pageLimit, setPageLimit] = React.useState<any>()
    const [newMessageModal, setNewMessageModal] = React.useState<any>(false)
    const [page, setPage] = React.useState<any>(1)
    const[tenantData,setTenantData] = React.useState<any>()
    const serviceId = window.location.pathname?.split('/')[4]
    const [jobId,setJobId] = React.useState<any>()
    const[unitNo, setUnitNo] =  useState<any>()

    const getServiceData = async () => {
        await ApiGet(`corporate/service_request/${window.location.pathname?.split('/')[4]}`)
            .then((res) => {
                setRequestData(res?.data?.data[0])
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const getServiceJob = async () => {
        const body = {
            serviceRequestId: window.location.pathname?.split('/')[4]
        }
        await ApiPost(`corporate/service_job/job_list`, body)
            .then((res) => {
                setServicedata(res?.data?.data)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    const getApprovalServiceRequest = async () => {
        const body = {
            serviceRequestId: window.location.pathname?.split('/')[4]
        }
        await ApiPost(`corporate/approval_request/get`, body)
            .then((res) => {
                setApprovalRequest(res?.data?.data?.serviceApprovalRequest)
            })
            .catch((e) => {
                console.log(e)
            })

    }

    const serviceRequestData = async () => {
        const body = {
            page: page,
            limit: 10,
            serviceRequestId: window.location.pathname?.split('/')[4]
        }
        await ApiPost(`corporate/service_job/get`, body)
            .then((res) => {
                setPropertyList(res?.data?.data?.service_job_data)
                setPageLimit(res?.data?.data?.state?.page_limit)
            })
            .catch((e) => {
                console.log(e)
            })
    }

    React.useEffect(() => {
        getServiceData()
        getServiceJob()
        getApprovalServiceRequest()
        serviceRequestData()
    }, [window.location.pathname?.split('/')[4], page])

    const handlePageClick = (event: any) => {
        setPage(event.selected + 1)
    }

    const openModal = (id:any) =>{
        setJobId(id)
        setMediaPopup(true)
    }
    const submitApproval = () => {
        const body = {
            id: serviceId,
            requestStatus: 5,
        }

        ApiPut('corporate/service_request', body)
            .then((res) => {
                SuccessToast(res?.data?.message)
                // navigate(`/request-management/service`)

            })
            .catch((err) => ErrorToast(err.message))
    }
    function timeConvert(n: any) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + ":" + rminutes
    }
    return (
        <>
            <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>
                    <div className='card card-flush app-container py-5'>
                        <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
                            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
                                <li
                                    className='nav-item'
                                    onClick={() => {
                                        navigate(`/request-management/service/create-request-service/service-detail/${serviceId}`)
                                    }}
                                >
                                    <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                                        Service
                                    </a>
                                </li>
                                <li
                                    className='nav-item'
                                    onClick={() => {
                                        // navigate(`/request-management/service/create-request-service/payment-tab`)
                                    }}
                                >
                                    <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                                        Payment
                                    </a>
                                </li>
                                <li
                                    className='nav-item'
                                    onClick={() => {
                                        navigate(`/request-management/service/external-request-log-tab/${serviceId}`)
                                    }}
                                >
                                    <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                                        Request Log
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex justify-content-between">
                            <h6 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center mb-0'>
                            Approved Workflow
                            </h6>
                            <div>
                                <button className='btn btn-sm fw-bold btn-primary btn-green mx-3' onClick={()=>submitApproval()}>Submit To Vendor</button>
                            </div>
                        </div>
                        <div className='pt-0 table-responsive'>
                            <table
                                className='table align-middle table-row-dashed fs-6 gy-5'
                                id='kt_ecommerce_sales_table'
                            >
                                <thead>
                                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className='text-center'>User</th>
                                        <th className='text-center min-w-100px'>Updated</th>
                                        <th className='text-center min-w-100px'>Status</th>
                                        <th className='text-center min-w-100px'>Signature</th>
                                        <th className='text-center min-w-100px'></th>
                                    </tr>
                                </thead>
                                <tbody className='fw-semibold text-gray-600'>
                                    {approvalRequest?.map((f: any, i: any) => {
                                        return (
                                            <tr>
                                                <td className='text-center'>{f?.corporate[0]?.fullName}</td>
                                                <td className='text-center'>{moment(f?.updatedAt).format('DD/MM/YYYY') }</td>
                                                <td className='text-center'>{f?.approvalStatus === 2 ? 'Approve' :
                                                    f?.approvalStatus === 3 ? 'Reject' :
                                                        f?.approvalStatus === 1 ? 'Requested' :
                                                            f?.approvalStatus === 0 ? 'pending Approval' :
                                                                'Send Back'}</td>
                                                <td className='text-center'>{f?.eSignature && <a
                                                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                    href={`${Bucket}${f?.eSignature}`} target="_blank">
                                                    e signature
                                                </a>}{f?.writtenSignature && <a
                                                    className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                                                    href={`${Bucket}${f?.writtenSignature}`} target="_blank">
                                                    written signature
                                                </a>}</td>
                                                <td className='text-center'>{f.comment}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                        <h3 className='fw-bold'> Request Details</h3>
                        <div className='d-flex'>
                            <p className='me-5 min-w-150px'><b>Request ID:  {requestData?.id} </b></p>
                            <p className='me-5 min-w-150px'><b>Request Source: External - Vendor List  </b></p>
                        </div>
                        <div className='my-5 d-flex'>
                            <Col lg={6}>
                                <h3 className='page-heading d-flex text-darkfs-3 flex-column justify-content-center mb-0 mb-5'>Request Summary</h3>
                                <div className='select-box p-5 d-inline-block'>
                                    <div className='d-flex'>
                                        <div className='me-10'>
                                            <p className='m-0'>Service: <b><p className='m-0'>Service: {requestData?.main_service[0]?.name} - {requestData?.sub_service[0]?.name}</p></b></p>
                                            <p className='m-0'>Assigned To: <b>{requestData?.staff[0]?.name}</b></p>
                                            <p className='m-0'>Total Buildings/Communities:<b>{serviceData?.total_building}</b> </p>
                                            <p className='m-0'>Total Units:<b>{serviceData?.total_units}</b> </p>
                                            <p className='m-0'>Total Jobs:<b>{serviceData?.total_units}</b> </p>
                                        </div>
                                        <div className='me-10'>
                                            <p className='m-0 d-flex align-items-center'>Request Urgency: <b><span style={{ marginLeft: "5px", width: "10px", height: "10px", borderRadius: "50%", background: "green", display: "inline-block" }}></span> Standard</b></p>
                                            <p className='m-0'>Preferred Schedules For Service </p>
                                            <p className='m-0'><b>1. {requestData?.schedule[0]?.preferScheduleDate&& moment(requestData?.schedule[0]?.preferScheduleDate).format('DD/MM/YYYY')} - {timeConvert(requestData?.schedule[0]?.preferScheduleTime)} </b></p>
                                            {/* <p className='m-0'><b>2. DD.MM.YYYY - HH:MM AM/PM</b></p> */}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="mb-3">
                                    <h6 className="text-muted m-0 mr-2 min-w-100px mb-2">Request Details (For Selected Vendor)</h6>
                                    <textarea
                                        className="form-control form-control-solid mb-8"
                                        rows={3}
                                        name="comment"
                                        placeholder="Type or paste emails here"></textarea>
                                </div>
                            </Col>
                        </div>
                        <div className='d-flex align-items-center gap-2 gap-lg-3'>
                            <h3 className='page-heading d-flex text-dark fw-bold m-0 me-5 fs-3 flex-column justify-content-center'>
                                Properties
                            </h3>
                        </div>
                        <div className='pt-0 table-responsive'>
                            <table
                                className='table align-middle table-row-dashed fs-6 gy-5'
                                id='kt_ecommerce_sales_table'
                            >
                                <thead>
                                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className='text-center min-w-100px'>Job ID</th>
                                        <th className='text-center min-w-100px'>Development</th>
                                        <th className='text-center min-w-100px'>Floor#/Cluster</th>
                                        <th className='text-center min-w-100px'>Unit ### </th>
                                        <th className='text-center min-w-100px'>Unit Type </th>
                                        <th className='text-center min-w-100px'># Bedrooms </th>
                                        <th className='text-center min-w-100px'></th>
                                    </tr>
                                </thead>
                                <tbody className='fw-semibold text-gray-600'>
                                    {propertyList?.map((f: any, i: any) => {
                                        return (
                                            <tr>
                                                <td>{f?._id}</td>
                                                <td>{f?.communityId ? 'Community' : 'Building'}</td>
                                                <td>{f?.communityId ? 'Cluster' : 'Floor'}</td>
                                                <td>{f?.unit[0]?.unitNo}</td>
                                                <td>{f?.unit[0]?.unitType}</td>
                                                <td>{f?.unit[0]?.bedrooms}</td>
                                                <td>
                                                    <button
                                                        className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                                                        onClick={() =>openModal(f?._id)}>
                                                        Media & Comments
                                                    </button></td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <MediaPopUp
                unitNo={unitNo}
                show={showMedia}
                handleClose={() => {
                    setShowMedia(false)
                }}
                propertyList={propertyList}
                id={jobId}
            />
            <NewMessage
             show={newMessageModal}
             tenantdata={tenantData}
             getMessageList={''}
             handleClose={() => {
               setNewMessageModal(false)
             }}
            />

        </>
    );
}

export default ExternalRequestLogTab;