import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import backArrow from '../../../../../img/back-arrow.png';
import resImg from '../../../../../img/residential.png';
import './../styles.scss'

const leaseTypes = [
  {
    title: 'Annual Leases',
    description: 'Leases that are created on an annual basis.',
    path: '/settings/residential-leasing/annual-leases',
  },
  // {
  //   title: 'Semi-Annual Leases',
  //   description: 'Leases that have a total duration of 180 days.',
  //   path: '',
  // },
  // {
  //   title: 'Quarterly Leases',
  //   description: 'Short-term leases that have a total duration of 90 days.',
  //   path: '',
  // },
  {
    title: 'Monthly Leases',
    description: 'Leases that are created on a monthly basis.',
    path: '',
  },
  {
    title: 'Weekly Leases',
    description: 'Leases that are created on a weekly basis.',
    path: '',
  },
  {
    title: 'Daily Leases',
    description: 'Leases that are created on a daily basis.',
    path: '',
  },
];

const LeaseCard: React.FC<{ title: string; description: string; path: string; navigate: (path: string) => void }> = ({
  title,
  description,
  path,
  navigate,
}) => (
  <Col md={6} className="mb-5">
    <div
      className="card card-flush gap-5 p-3 cursor-pointer lease-setting-table-card"
      onClick={() => {
        if (path) navigate(path);
      }}
    >
      <div className="card-body p-3">
        <h4 className="page-heading m-0 px-3 white-dark-theme-color fnt-600">
          {title}
        </h4>
        <p className="pe-13 fs-5 fnt-500 px-3 pt-2 labl-gry mb-1 light-dark-theme-color">{description}</p>
      </div>
    </div>
  </Col>
);

const AnnualLeases: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div id="" className="pt-0 mt-0 px-2 ps-1">
      <div className="d-flex align-items-center gap-2 gap-lg-3">
        <div className="d-flex align-items-center gap-3">
          <span className="rounded-circle cursor-pointer" onClick={() => navigate('/settings')}>
            <img src={backArrow} alt="Back" height={14} width={14} />
          </span>
          <h2 className="page-heading m-0 head-text">Residential Leasing</h2>
        </div>
        <div className="ms-auto">
          <img src={resImg} alt="Residential" height="30px" width="30px" />
        </div>
      </div>

      <div className="card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3" style={{ minHeight: '75vh' }}>
        <div className="card-body pt-0 mt-5 py-0 px-3">
          <Row className="my-5">
            {leaseTypes.map((lease) => (
              <LeaseCard
                key={lease.title}
                title={lease.title}
                description={lease.description}
                path={lease.path}
                navigate={navigate} // Pass the navigate function as a prop
              />
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AnnualLeases;
