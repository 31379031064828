import React, {useEffect, useRef, useState} from 'react'
import daysDueIcon from '../../../../img/daysDue.png'
import {useFormContext} from 'react-hook-form'
import {preventExpAndSign, removeLeadingZeros} from '../../../../Utilities/utils'

interface LatePaymentInputI {
  isDisabled: boolean
  iconSize: number
  icon?: string
  type?: string
  label?: string
  name: string
  value: string
  onChange: any
  required?: boolean
  firstPlaceholder?: string
  secondPlaceHolder?: string
  className?: string
  setRightPosition?: string
  subLabel?: string
  minValue?: number
  maxValue?: number
  isPercent?: boolean
  isFrequency?: boolean
  errorClasees?: string
  chrgData?: any
  setChrgData?: any
  
}

const CustomFrequencyInput = ({
  type = 'text',
  label,
  name,
  value = '',
  onChange,
  required = false,
  firstPlaceholder = '-',
  secondPlaceHolder = 'Days',
  icon,
  iconSize,
  className = '',
  setRightPosition = '',
  isDisabled,
  subLabel,
  minValue = 0,
  maxValue,
  isPercent = false,
  isFrequency = false,
  errorClasees,
  chrgData,
  setChrgData
}: // capitalizeFirst = false
LatePaymentInputI) => {
  const {
    register,
    setValue,
    formState: {errors},
  } = useFormContext()

    const [errorMessage, setErrorMessage] = useState<any>('')
    const inputRef = useRef(null);

  const handleChange = (e: any) => {
    let newValue = removeLeadingZeros(e.target.value)
    onChange(newValue)

    if (newValue.trim().length === 0) {
      setValue(name, newValue, {
        shouldValidate: true,
      })
    } else {
      setValue(name, Number(newValue), {
        shouldValidate: true,
      })
    }
  }

  function enforceMinMax(event: any) {
    console.log(event)
    const el = event.target
    if (el.value != '') {
      if (parseInt(el.value) < parseInt(el.min)) {
        el.value = value
        setErrorMessage(`${isFrequency ? 'Times applied' : 'Installments'} must be greater than ${Number(minValue)}.`)
      }
      if (parseInt(el.value) > 100 && isPercent) {
        el.value = value
        setErrorMessage(`Value can not exceed 100`)
      }
      if (parseInt(el.value) > parseInt(el.max)) {
        el.value = value
        setErrorMessage(`${isFrequency ? 'Times applied' : 'Installments'} must be less than or equal to ${maxValue}.`)
      }
    }

    setTimeout(() => {
      setErrorMessage('')
    }, 7000)
  }


//   useEffect(() => {
//     // if (String(value)?.trim()?.length > 0) {
//     //     setValue(name, value, {
//     //       shouldValidate: true,
//     //     })
//     // } 

// },[maxValue])


useEffect(() => {
  const updateChrgData = {...chrgData}
  if (chrgData?.customFrequencyType?.[0]?.value === 0 && parseInt(chrgData?.customTimesApplied) > 365) {
    chrgData.customTimesApplied = null
  }
  if (chrgData?.customFrequencyType?.[0]?.value === 1 && parseInt(chrgData?.customTimesApplied) > 52) {
    chrgData.customTimesApplied = null
  }
  if (chrgData?.customFrequencyType?.[0]?.value === 2 && parseInt(chrgData?.customTimesApplied) > 12) {
    chrgData.customTimesApplied = null
  }
  setChrgData(updateChrgData)
},[chrgData?.customFrequencyType])




  return (
    <div className={`${className}`}>
      {(label || subLabel )&& (
        <div className='percentage-label-container'>
          {label && (<label className={`percentage-input-label ${required && 'required'} white-dark-theme-color`}>{label}</label>  )}
          {subLabel && (
            <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2'>{subLabel}</p>
          )}
        </div>
      )}
      <h4>
        <div className='percentage-input-wrapper'>
          <input
            ref={inputRef}
            disabled={isDisabled}
            // {...register(name, {required:required,min:Number(minValue),max:Number(maxValue)})}
            onInput={enforceMinMax}
            min={minValue}
            max={maxValue}
            type={type}
            name={name}
            value={value}
            onKeyDown={type === 'number' ? preventExpAndSign : () => {}}
            onChange={handleChange}
            className={`form-control form-control-solid  latePaymentInput ${
              type === 'number' ? 'hide-number-input-arrows' : ''
            } ${value ? 'latePaymentInputFilled' : ''} percentage-input`}
            autoComplete='off'
            onWheel={(e) => e.currentTarget.blur()}
          />
          <span
            className={`percentage-placeholder-left ${
              value ? 'percentage-placeholder percentage-placeholder-filled' : 'percentage-placeholder'
            } ps-3`}
          >
            {value === '' || value === null ? `${firstPlaceholder}` : ''}
          </span>
          <span
            className={`${value ? 'percentage-placeholder percentage-placeholder-filled' : 'percentage-placeholder'}`}
            style={{right: `${setRightPosition}`}}
          >
            {`${secondPlaceHolder}`}
          </span>
        </div>

        {/* <div className=''>
          {errors[name]?.type === "required" 
          ? (<p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 fnt-400'>
              This field is required 
            </p>) 
          : errors[name]?.type === "min" 
          ?  (<p style={{color: 'var(--kt-danger)', margin: '0.5rem 0'}} className='m-0 mt-1 fnt-400'>{`${isFrequency ? 'Times applied' : 'Installments'} must be greater than ${Number(minValue)-1}.`}</p>) 
          : errors[name]?.type === "max" 
          ?  (<p style={{color: 'var(--kt-danger)', margin: '0.5rem 0'}} className='m-0 mt-1 fnt-400'>{`${isFrequency ? 'Times applied' : 'Installments'} must be less than or equal to ${maxValue}.`}</p>)   
          : ''}
        </div> */}

        {errorMessage?.trim()?.length > 0 && (
          <div className={`${errorClasees}`}>
            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 fnt-400'>
              {errorMessage}
            </p>
          </div>
        )}
      </h4>
    </div>
  )
}

export default CustomFrequencyInput
