import React, { useEffect, useState } from 'react'
import ApexCharts from 'react-apexcharts'

interface IncomeBreakdownChartMonthlyProps {
  chartData: any
}

const IncomeBreakdownChartMonthly: React.FC<IncomeBreakdownChartMonthlyProps> = ({ chartData }) => {
  const [chartSeries, setChartSeries] = useState<number[]>([])
  const [chartValues, setChartValues] = useState<number[]>([])
  const [chartLabels, setChartLabels] = useState<string[]>([])
  const [chartColors] = useState<string[]>(['#146c6a', '#188383', '#0d9e91', '#00b8a9'])

  useEffect(() => {
    if (chartData) {
      const dataEntries = Object.keys(chartData)
        .map(key => ({
          label: key,
          ...chartData[key]
        }))
        .filter(entry => entry.value)
        .sort((a, b) => b.percentage - a.percentage) // Changed to sort in descending order

      setChartSeries(dataEntries.map(entry => entry.percentage))
      setChartValues(dataEntries.map(entry => entry.value))
      setChartLabels(dataEntries.map(entry => entry.label))
    }
  }, [chartData])

  const chartOptions: ApexCharts.ApexOptions = {
    chart: {
      type: 'pie',
    },
    labels: chartLabels,
    colors: chartColors,
    legend: {
      position: 'right',
    },
    tooltip: {
      custom: function ({ series, seriesIndex }) {
        return (
          `<div class="apexcharts-tooltip-custom">` +
          `<div><span style="color:${chartColors[seriesIndex]}">&#9679;</span>  AED ${
            chartValues[seriesIndex]
              ? chartValues[seriesIndex].toLocaleString('en-US', { minimumFractionDigits: 1 })
              : 0
          } (${series[seriesIndex].toFixed(2)}%)</div>` +
          `</div>`
        )
      },
    },
  }

  const styles = `
  .apexcharts-tooltip-custom {
    padding: 10px;
    background: #fff;
    border: 1px solid #ccc;
    width: 'fit-content'
  }
  .apexcharts-tooltip-custom div {
    color:#666;
  }
`
  useEffect(() => {
    const styleSheet = document.createElement('style')
    styleSheet.innerText = styles
    document.head.appendChild(styleSheet)
  }, [])

  const isEmptyObject = (obj: any) => {
    return obj && Object.keys(obj).length === 0
  }

  return (
    <div className='card card-flush py-4 px-2 h-100' style={{minHeight: '375px'}}>
      <div id='chart'>
        {chartData === null || chartData === undefined || isEmptyObject(chartData) ? (
          <>
            <p style={{fontSize: '14px', fontWeight: '800'}} className='ps-2'>
              {' '}
              Income Breakdown
            </p>
            <div
              className='d-flex justify-content-center align-items-center'
              style={{minHeight: '285px'}}
            >
              <p className='labl-gry'>
                <i>Data is unavailable for the selected period. </i>
              </p>
            </div>
          </>
        ) : (
          <>
          <p style={{fontSize: '14px', fontWeight: '800'}} className='ps-2'>
              {' '}
              Income Breakdown
            </p>
            <div className="d-flex justify-content-center">
          <ApexCharts options={chartOptions} series={chartSeries} type='pie' width='450' />
          </div>
          </>
        )}
      </div>
    </div>
  )
}

export default IncomeBreakdownChartMonthly
