import React from 'react'
import {useState, useRef} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import backArrow from '../../../../img/back-arrow.png'
import addWhite from '../../../../img/add-white.png'
import './../style.scss'
import crossBtn from '../../../../img/cross-button.png'
import redCross from '../../../../img/remove.png'
import {Tabs, Tab, Paper} from '@mui/material'
import AddUserModal from './AddUser'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import swal from 'sweetalert2'
import profileWhite from '../../../../img/profile-white.png'
import Select from 'react-select'
import trashImg from '../../../../img/trash.png'
import eye from '../../../../img/eye-blue.png'
import profileImg from '../../../../img/user.jpeg'
import userGrayIcon from '../../../../img/user-gray.png'

const GlobalUsers = () => {
  const navigate = useNavigate()
  const [roleData, setRoleData] = useState<any>()
  const [hide, setHide] = React.useState<any>(true)
  const [tabValue, setTabValue] = useState(0)
  const [addFeatureModal, setAddFeatureModal] = useState<any>(false)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [addUser, setAddUser] = useState<any>(false)
  const [searchUser, setSearchUser] = useState<any>()
  const [searchProperty, setSearchProperty] = useState<any>()
  const [roleOptions, setRoleOptions] = useState<any>([
    {value: '0', label: 'Admininstrator'},
    {value: '1', label: 'Leasing Manager'},
    {value: '2', label: 'Role #1'},
  ])
  let totalSelected: any = 0

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    // backgroundColor: 'transparent',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const handleChange = (event: any, newValue: any) => {
    setTabValue(newValue)
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }

  const handleChangeRoleData = () => {}
  return (
    <>
      <div id='' className='px-2'>
        <div className='d-flex align-items-center mb-10'>
          <div className='d-flex align-items-center'>
            <span className='rounded-circle cursor-pointer' onClick={() => navigate('/settings')}>
              <img src={backArrow} height={14} width={14} className='me-2' />
            </span>
            <h2 className='page-heading m-0 head-text'>Users</h2>
          </div>
          <div className='d-flex ms-auto'>
            {/* <a className='text-decoration-underline' style={{color: '#000'}}> */}
            <h3 className='page-heading m-0 head-text'>
              <b>Total Users 5</b>
            </h3>
            {/* </a> */}
          </div>
        </div>

        <div className='d-flex align-items-center py-5 pt-2 gy-3 row mx-0 mt-4'>
          <div className='mb-2 col-md-1 pe-5 xyz'>
            <label className='head-text' style={{fontWeight: '600'}}>
              {' '}
              Search{' '}
            </label>
          </div>
          <div className='mb-2 col-md-2 pe-5 xyz global-users'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='User...'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchUser}
                onChange={(e: any) => setSearchUser(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>
          <div className='mb-2 col-md-2 pe-5 xyz'>
            <Select
              placeholder={'Role'}
              styles={customStyles}
              isSearchable={false}
              options={roleOptions}
              name='role'
              onChange={(e) => {}}
            />
          </div>
          <div className='mb-2 col-md-2 pe-5 xyz global-users'>
            <Paper component='form' style={searchContainerStyle}>
              <InputBase
                placeholder='Property...'
                style={inputBaseStyle}
                inputProps={{'aria-label': 'search'}}
                value={searchProperty}
                onChange={(e: any) => setSearchProperty(e.target.value)}
              />
              <IconButton style={searchIconStyle} aria-label='search'>
                <SearchIcon />
              </IconButton>
            </Paper>
          </div>

          {/* {checkedValues?.length > 0 && (
          <>
            {checkedValues?.map((ch: any, ind: any) => {
              if (ch == true) totalSelected = totalSelected + 1
              return <></>
            })}
          </>
        )} */}

          <div className='mb-2 col-md-4 xyz ms-auto pe-0 text-end'>
            <div className='d-flex justify-content-end'>
              {totalSelected > 0 && (
                <>
                  <div className='d-flex align-items-center'>
                    <h3 className='my-2' style={{color: 'black'}}>
                      <label className='labl-gry '>
                        {' '}
                        <i>Total Selected </i>
                      </label>

                      <span className='ms-5 ps-1 my-2 me-4'>
                        <b>{totalSelected}</b>
                      </span>
                    </h3>
                  </div>
                  <button
                    type='button'
                    className='btn btn-sm fw-bold  mx-3 red-hollow-btn'
                    onClick={() => {
                      swal
                        .fire({
                          text: 'Are you sure you want to delete the selected users ?',
                          icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#D72852',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          // cancelButtonColor: "transparent",
                          cancelButtonText: 'Cancel',
                        })
                        .then((res) => {
                          if (res.isConfirmed) {
                          }
                        })
                    }}
                  >
                    <img src={trashImg} height={20} width={20} style={{marginRight: '7px'}} />{' '}
                    Delete
                  </button>
                </>
              )}
              <button
                type='button'
                className='btn btn-sm fw-bold  me-0 select-btn ps-3 pe-10'
                onClick={() => {
                  setAddUser(true)
                }}
                style={{width: 'fit-content'}}
              >
                <img
                  src={profileWhite}
                  height={18}
                  width={18}
                  style={{marginLeft: '3px'}}
                  className='me-4'
                />
                Add
              </button>
            </div>
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-0 px-3'>
          <div className='card-body pt-0 mt-5 py-3 px-3'>
            <div className='table-responsive '>
              <table className='table align-middle  fs-6 gy-5 ' id='kt_ecommerce_sales_table'>
                <thead>
                  <th className='text-start min-w-25px test'>
                    <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        id='vacant0'
                        name='none'
                        checked={false}
                        onChange={(e: any) => {}}
                      />
                    </div>
                  </th>
                  <th className='text-start min-w-200px'>Users</th>

                  <th className='text-start min-w-150px'>Role</th>
                  <th className='text-start min-w-150px'>Activity</th>
                  <th className='text-start min-w-150px'>Created</th>
                  <th className='text-start min-w-150px'>Account Status</th>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  <tr>
                    <td className='text-start ps-0 test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={false}
                          onChange={(e: any) => {}}
                        />
                      </div>
                    </td>
                    <td className='text-start ps-0 cursor-pointer' onClick={() => {
                      navigate('/settings/users/view-user')
                    }}>
                      <div className="d-flex align-items-center">
                      <div className='symbol-group symbol-hover m-0'>
                      <div className='symbol symbol-40px symbol-circle '>
                        
                         
                          <img alt='' src={userGrayIcon}></img>
                       
                      </div>
                    </div>
                      <div className='head-text ms-3 cursor-pointer'>
                        <p className='user-head pe-2 mb-1'>Mason Wilson </p>
                        <p className='user-sub-head mb-0'>finance.manager@mailinator.com</p>
                      </div>
                      </div>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'  onClick={() => {
                      navigate('/settings/users/view-user')
                    }}>
                      <p className='head-text user-head pe-2 mb-1'>
                        Finance Manager{' '}
                        <span>
                          <img
                            src={eye}
                            height={18}
                            width={18}
                            className=' ms-5 me-3 cursor-pointer'
                            onClick={() => {}}
                          />
                        </span>{' '}
                      </p>
                    </td>

                    <td className='text-start ps-0 cursor-pointer'  onClick={() => {
                      navigate('/settings/users/view-user')
                    }}>
                      <div className='d-flex align-items-center'>
                        {' '}
                        <div className='green-dot me-2'></div>
                        <p className='head-text user-head pe-2 mb-0'>Online</p>
                      </div>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'  onClick={() => {
                      navigate('/settings/users/view-user')
                    }}>
                      <p className='head-text user-head pe-2 mb-1'>24.12.2023 – 12:30pm</p>
                    </td>
                    <td className='text-start ps-0 '  onClick={() => {
                      navigate('/settings/users/view-user')
                    }}>
                      <p className='user-head pe-2 mb-1 red-clr'>Disabled</p>
                    </td>
                  </tr>
                  <tr>
                    <td className='text-start ps-0 test'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={false}
                          onChange={(e: any) => {}}
                        />
                      </div>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <div className="d-flex align-items-center">
                      <div className='symbol-group symbol-hover m-0'>
                      <div className='symbol symbol-40px symbol-circle '>
                        
                         
                          <img alt='' src={userGrayIcon}></img>
                       
                      </div>
                    </div>
                      <div className='head-text ms-3'>
                        <p className='user-head pe-2 mb-1'> Lucas Martinez </p>
                        <p className='user-sub-head mb-0'>property.manager@mailinator.com</p>
                      </div>
                      </div>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <p className='head-text user-head pe-2 mb-1'>
                        
                      Leasing Manager{' '}
                        <span>
                          <img
                            src={eye}
                            height={18}
                            width={18}
                            className=' ms-5 me-3 cursor-pointer'
                            onClick={() => {}}
                          />
                        </span>{' '}
                      </p>
                    </td>

                    <td className='text-start ps-0 cursor-pointer'>
                      <div className='d-flex align-items-center'>
                        {' '}
                        <div className='orange-dot me-2'></div>
                        <p className='head-text user-head pe-2 mb-0'>Inactive 20 min ago</p>
                      </div>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <p className='head-text user-head pe-2 mb-1'>10.02.2024 – 8:15pm</p>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <p className='user-head pe-2 mb-1 select-color'>Active</p>
                    </td>
                  </tr>
                  <tr>
                    <td className='text-start ps-0 test cursor-pointer'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={false}
                          onChange={(e: any) => {}}
                        />
                      </div>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <div className="d-flex align-items-center">
                      <div className='symbol-group symbol-hover m-0'>
                      <div className='symbol symbol-40px symbol-circle '>
                        
                         
                          <img alt='' src={userGrayIcon}></img>
                       
                      </div>
                    </div>
                      <div className='head-text ms-3'>
                        <p className='user-head pe-2 mb-1'> Noah Jones </p>
                        <p className='user-sub-head mb-2'>procurement.officer@mailinator.com</p>
                      </div>
                      </div>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <p className='head-text user-head pe-2 mb-1'>
                      Procurement Officer{' '}
                        <span>
                          <img
                            src={eye}
                            height={18}
                            width={18}
                            className=' ms-5 me-3 cursor-pointer'
                            onClick={() => {}}
                          />
                        </span>{' '}
                      </p>
                    </td>

                    <td className='text-start ps-0 cursor-pointer'>
                      <div className='d-flex align-items-center'>
                        {' '}
                        <div className='red-dot me-2'></div>
                        <p className='head-text user-head pe-2 mb-0'>Offline 3 days ago </p>
                      </div>
                      {/* <p className='user-sub-head mb-2'>13.12.2023 – 5:06pm</p> */}
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <p className='head-text user-head pe-2 mb-1'>14.08.2023 – 10:30am</p>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <p className='user-head pe-2 mb-1 select-color'>Active</p>
                    </td>
                  </tr>
                  <tr>
                    <td className='text-start ps-0 test cursor-pointer'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={false}
                          onChange={(e: any) => {}}
                        />
                      </div>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <div className="d-flex align-items-center">
                      <div className='symbol-group symbol-hover m-0'>
                      <div className='symbol symbol-40px symbol-circle '>
                        
                         
                          <img alt='' src={userGrayIcon}></img>
                       
                      </div>
                    </div>
                      <div className='head-text ms-3'>
                        <p className='user-head pe-2 mb-1'> Noah Jones </p>
                        <p className='user-sub-head mb-2'>procurement.officer@mailinator.com</p>
                      </div>
                      </div>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <p className='head-text user-head pe-2 mb-1'>
                      Procurement Officer{' '}
                        <span>
                          <img
                            src={eye}
                            height={18}
                            width={18}
                            className=' ms-5 me-3 cursor-pointer'
                            onClick={() => {}}
                          />
                        </span>{' '}
                      </p>
                    </td>

                    <td className='text-start ps-0 cursor-pointer'>
                      <div className='d-flex align-items-center'>
                        {' '}
                        <div className='red-dot me-2'></div>
                        <p className='head-text user-head pe-2 mb-0'>Offline 3 days ago </p>
                      </div>
                      {/* <p className='user-sub-head mb-2'>13.12.2023 – 5:06pm</p> */}
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <p className='head-text user-head pe-2 mb-1'>14.08.2023 – 10:30am</p>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <p className='user-head pe-2 mb-1 select-color'>Active</p>
                    </td>
                  </tr>
                  <tr>
                    <td className='text-start ps-0 test cursor-pointer'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='vacant0'
                          name='none'
                          checked={false}
                          onChange={(e: any) => {}}
                        />
                      </div>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <div className="d-flex align-items-center">
                      <div className='symbol-group symbol-hover m-0'>
                      <div className='symbol symbol-40px symbol-circle '>
                        
                         
                          <img alt='' src={userGrayIcon}></img>
                       
                      </div>
                    </div>
                      <div className='head-text ms-3'>
                        <p className='user-head pe-2 mb-1'> 
                        Emma Johnson </p>
                        <p className='user-sub-head mb-2'>maintenance.officer@mailinator.com</p>
                      </div>
                      </div>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <p className='head-text user-head pe-2 mb-1'>
                      Maintenance Officer{' '}
                        <span>
                          <img
                            src={eye}
                            height={18}
                            width={18}
                            className=' ms-5 me-3 cursor-pointer'
                            onClick={() => {}}
                          />
                        </span>{' '}
                      </p>
                    </td>

                    <td className='text-start ps-0 cursor-pointer'>
                      <div className='d-flex align-items-center'>
                        {' '}
                        <div className='green-dot me-2'></div>
                        <p className='head-text user-head pe-2 mb-0'>Online </p>
                      </div>
                      {/* <p className='user-sub-head mb-2'>13.12.2023 – 5:06pm</p> */}
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <p className='head-text user-head pe-2 mb-1'>19.05.2024 – 9:00am</p>
                    </td>
                    <td className='text-start ps-0 cursor-pointer'>
                      <p className='user-head pe-2 mb-1 select-color'>Active</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Add User Modal */}
      {addUser && <AddUserModal show={addUser} handleClose={() => setAddUser(false)} />}
    </>
  )
}

export default GlobalUsers
