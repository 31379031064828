import React, {useEffect, useState} from 'react'
import {ApiGet, ApiPost} from '../../../../../../../apiCommon/helpers/API/ApiData'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {CircularProgress} from '@mui/material'
import noData from '../../../../../../../img/NoData1.svg'
import swal from 'sweetalert2'
import {
  formatAmount,
  getUnitTypeName,
  invertOutstandingAmount,
} from '../../../../../../../Utilities/utils'
import moment from 'moment'

interface ButtonProps {
  from?: string
  slctdInvoices?: any
  updateSelectedLease?: any
  rcptData?: any
  updateSelectedInvoices?: any
}

const LeaseSelection = ({
  slctdInvoices,
  updateSelectedLease,
  rcptData,
  updateSelectedInvoices,
  from = 'credit note',
}: ButtonProps) => {
  const [isLoading, setIsLoading] = useState<any>(false)

  const [searchTenant, setSearchTenant] = useState<any>('')
  const [payorData, setPayorData] = useState<any>([])

  const [radioChecked, setRadioChecked] = useState<any>(-1)
  const [selectedPayor, setSelectedPayor] = useState<any>({_id: ''})

  const [searchContractNo, setSearchContractNo] = useState<any>('')
  const [searchDevelopment, setSearchDevelopment] = useState<any>('')
  const [searchUnit, setSearchUnit] = useState<any>('')

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const getPayorList = () => {
    setIsLoading(true)
    const body = {
      page: 1,
      limit: 10000,
      // searchTenant: searchTenant,
      searchDevelopment: searchDevelopment,
      searchContractNo: searchContractNo,
      searchUnit: searchUnit,
      tenantIds: [rcptData?.payorData?._id],
    }

    ApiPost('corporate/tenancy/overview', body)
      .then((res: any) => {
        setPayorData(res?.data?.data?.tenancy_data)
        setIsLoading(false)
      })
      .catch((err: any) => { setIsLoading(false)})
  }

  useEffect(() => {
    getPayorList()
    setRadioChecked(rcptData?.tenancyData?._id != undefined ? rcptData?.tenancyData?._id : -1)
  }, [rcptData, searchDevelopment, searchUnit, searchContractNo])

  return (
    <div>
      <div className='card card-flush py-4 mt-7 px-5 mb-5 min-h-350'>
        <div className='d-flex align-items-top'>
          <h2 className='pt-4 mb-0 white-dark-theme-color'>
            <b>Select Lease</b>
          </h2>
        </div>

        <div className='d-flex  align-items-center'>
          <h3 className='mb-0 fnt-500 mt-2 light-dark-theme-color'>
            {'Payor:   '}
            <span className='mb-0 ms-2 fnt-700 white-dark-theme-color'>
              {' '}
              {rcptData?.payorData?.firstName} {rcptData?.payorData?.lastName} 
            </span>
          </h3>{' '}
        </div>

        <div className='d-flex align-items-center'>
          <h4 className='pt-3 mb-0 light-dark-theme-color'>{`Select the lease that you want to issue a ${from} for:`}</h4>
        </div>

        <>
          <div className='d-flex align-items-center px-0 mt-8 flex-wrap'>
            <label className='head-text status-w-80' style={{fontWeight: '600'}}>
              {' '}
              Search{' '}
            </label>

            <div className='mb-2 property xyz tst me-3'>
              <Paper
                component='form'
                style={searchContainerStyle}
                onSubmit={(e) => e.preventDefault()}
              >
                <InputBase
                  placeholder='Contract No.'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchContractNo}
                  onChange={(e: any) => setSearchContractNo(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

            <div className='mb-2 property xyz tst me-3'>
               <Paper component='form' style={searchContainerStyle} onSubmit={(e) => e.preventDefault()}>
                <InputBase
                  placeholder='Development'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchDevelopment}
                  onChange={(e: any) => setSearchDevelopment(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>

            <div className='mb-2 property xyz tst me-3'>
              <Paper
                component='form'
                style={searchContainerStyle}
                onSubmit={(e) => e.preventDefault()}
              >
                <InputBase
                  placeholder='Unit'
                  style={inputBaseStyle}
                  inputProps={{'aria-label': 'search'}}
                  value={searchUnit}
                  onChange={(e: any) => setSearchUnit(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}
                />
                <IconButton style={searchIconStyle} aria-label='search'>
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-12 pe-0'>
              <div className=''>
                <div className='table-responsive' style={{maxHeight: '350px', overflow: 'auto'}}>
                  <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                        {payorData?.length > 0 && (<th className='text-start w-25px test'></th> )}
                        <th className='text-start min-w-125px ps-0'>Development</th>
                        <th className='text-start min-w-150px'>Unit</th>
                        <th className='text-start min-w-125px'>Type</th>
                        <th className='text-center min-w-125px'>Lease Status</th>
                        <th className='text-start min-w-150px'>Lease Term</th>
                        <th className='text-start min-w-150px'>Contract No.</th>
                      </tr>
                    </thead>

                    <tbody className='fw-semibold head-text'>
                      {payorData?.length > 0 ? (
                        <>
                          {payorData?.map((ls: any, index: any) => {
                            return (
                              <tr style={{height: '30px'}}>
                                <td className='text-start w-25px  test ps-0'>
                                  {radioChecked === ls?._id ? (
                                    <div
                                      style={{
                                        height: '16px',
                                        width: '16px',
                                        backgroundColor: '#146c6a',
                                        borderRadius: '50%',
                                      }}
                                      className='cursor-pointer'
                                    ></div>
                                  ) : (
                                    <div
                                      style={{
                                        height: '16px',
                                        width: '16px',
                                        backgroundColor: 'transparent',
                                        borderRadius: '50%',
                                        border: '1px solid #146c6a',
                                      }}
                                      onClick={() => {
                                        if (
                                          radioChecked != ls?._id &&
                                          radioChecked != -1 &&
                                          slctdInvoices?.length > 0
                                        ) {
                                          swal
                                            .fire({
                                              html: ` 
                                                <div class='fs-3'> <b>  Switch Lease Confirmation</b></div>
                                                <div class='fs-5 mt-4'>Switching the selected lease will discard all currently selected invoices because you cannot select invoices with different leases.</div>
                                                <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                                              // icon: 'warning',
                                              showConfirmButton: true,
                                              confirmButtonColor: '#35475e',
                                              confirmButtonText: 'Yes',
                                              showCancelButton: true,
                                              cancelButtonText: 'No',
                                              cancelButtonColor: '#fff',
                                              customClass: {
                                                confirmButton: 'custom-confirm-button',
                                                cancelButton: 'custom-cancel-button',
                                                popup: 'custom-popup',
                                              },
                                              reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                                            })
                                            .then((res: any) => {
                                              if (res.isConfirmed) {
                                                setRadioChecked(ls?._id)

                                                updateSelectedInvoices(ls, [])
                                              }
                                            })
                                        } else {
                                          setRadioChecked(ls?._id)

                                          updateSelectedLease(ls)
                                        }
                                      }}
                                      className='cursor-pointer'
                                    ></div>
                                  )}
                                </td>
                                <td className='text-start ps-0'>
                                  <div className='position-relative'>
                                    {radioChecked != ls?._id && radioChecked != -1 && (
                                      <div className='requestBoxOpacity z-index-10 border-radius-5px'></div>
                                    )}
                                    <div>
                                      {ls?.community?.length > 0
                                        ? `${ls?.community?.[0]?.name}`
                                        : `${ls?.building?.[0]?.name}`}
                                    </div>
                                  </div>
                                </td>
                                <td className='text-start'>
                                  <div className='position-relative'>
                                    {radioChecked != ls?._id && radioChecked != -1 && (
                                      <div className='requestBoxOpacity z-index-10 border-radius-5px'></div>
                                    )}
                                    <div>
                                      {ls?.unit?.[0]?.unitNo ? `${ls?.unit?.[0]?.unitNo}` : '-'}
                                    </div>
                                  </div>
                                </td>

                                <td className='text-start'>
                                  <div className='position-relative'>
                                    {radioChecked != ls?._id && radioChecked != -1 && (
                                      <div className='requestBoxOpacity z-index-10 border-radius-5px'></div>
                                    )}
                                    <div>
                                      {getUnitTypeName(ls?.unit?.[0]?.unitType, ls?.unit?.[0])}
                                    </div>
                                  </div>
                                </td>

                                <td className='text-start'>
                                  <div className='position-relative'>
                                    {radioChecked != ls?._id && radioChecked != -1 && (
                                      <div className='requestBoxOpacity z-index-10 border-radius-5px'></div>
                                    )}
                                    <div className='d-flex justify-content-center'>
                                      <div
                                        className={
                                          ls?.tenancyStatus == 0
                                            ? 'status booked-bar'
                                            : ls?.tenancyStatus == 1
                                            ? 'status active-bar  '
                                            : ls?.tenancyStatus == 2
                                            ? 'status expiring-bar'
                                            : ls?.tenancyStatus == 3
                                            ? 'status expired-bar'
                                            : 'status  no-st-bar'
                                        }
                                      >
                                        {ls?.tenancyStatus == 0
                                          ? 'Booked'
                                          : ls?.tenancyStatus == 1
                                          ? 'Active'
                                          : ls?.tenancyStatus == 2
                                          ? 'Expiring'
                                          : ls?.tenancyStatus == 3
                                          ? 'Expired'
                                          : '-'}
                                      </div>
                                    </div>
                                  </div>
                                </td>

                                <td className='text-start '>
                                  <div className='position-relative'>
                                    {radioChecked != ls?._id && radioChecked != -1 && (
                                      <div className='requestBoxOpacity z-index-10 border-radius-5px'></div>
                                    )}
                                    <div>
                                      {ls?.duration?.start_date
                                        ? `${moment
                                            .utc(ls?.duration?.start_date)
                                            .local()
                                            .format('DD.MM.YYYY')} `
                                        : '-'}
                                      {'-'}
                                      {ls?.duration?.end_date
                                        ? `${moment
                                            .utc(ls?.duration?.end_date)
                                            .local()
                                            .format('DD.MM.YYYY')} `
                                        : '-'}
                                    </div>
                                  </div>
                                </td>

                                <td className='text-start '>
                                  <div className='position-relative'>
                                    {radioChecked != ls?._id && radioChecked != -1 && (
                                      <div className='requestBoxOpacity z-index-10 border-radius-5px'></div>
                                    )}
                                    <div>{ls?.contractNo ? `${ls?.contractNo}` : '-'}</div>
                                  </div>
                                </td>
                              </tr>
                            )
                          })}
                        </>
                      ) : (
                        <tr>
                          {/* {!isLoading ? (
                            <td colSpan={7} className='text-center'>
                              <img src={noData} alt='' width={350} />
                            </td>
                          ) : (*/}
                          {isLoading && (
                            <td colSpan={6} className='text-center'>
                              <div
                                className='d-flex justify-content-center align-items-center w-100'
                                style={{height: '150px'}}
                              >
                                <CircularProgress style={{color: '#007a59'}} />
                              </div>
                            </td>
                          )}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    </div>
  )
}

export default LeaseSelection
