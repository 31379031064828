import React, {useState} from 'react'
import daysDueIcon from '../../../../img/daysDue.png'
import {useFormContext} from 'react-hook-form'
import {preventExpAndSign, removeLeadingZeros} from '../../../../Utilities/utils'

interface LatePaymentInputI {
  isDisabled: boolean
  iconSize: number
  icon?: string
  type?: string
  label?: string
  name: string
  value: string
  onChange: any
  required?: boolean
  firstPlaceholder?: string
  secondPlaceHolder?: string
  className?: string
  setRightPosition?: string
  subLabel?: string
  minValue?: number
  isPercent?: boolean
  maxValue?: number
  errorClasees?: string
}

const PercentageInput = ({
  type = 'text',
  label,
  name,
  value = '',
  onChange,
  required = false,
  firstPlaceholder = '-',
  secondPlaceHolder = 'Days',
  icon,
  iconSize,
  className = '',
  setRightPosition = '',
  isDisabled,
  subLabel,
  minValue = 0,
  isPercent = false,
  maxValue = undefined,
  errorClasees = ''
}: // capitalizeFirst = false
LatePaymentInputI) => {
  const {
    register,
    setValue,
    formState: {errors},
  } = useFormContext()

  const [errorMessage, setErrorMessage] = useState<any>('')
  const handleChange = (e: any) => {
    let newValue = removeLeadingZeros(e.target.value)
    onChange(newValue)

    if (newValue.trim().length === 0) {
      setValue(name, newValue, {
        shouldValidate: true,
      })
    } else {
      setValue(name, newValue, {
        shouldValidate: true,
      })
    }
  }

  function enforceMinMax(event: any) {
    const el = event.target
    if (el.value != '') {
      if (parseInt(el.value) < parseInt(el.min)) {
        el.value = value
        setErrorMessage(`Value can not be less than ${el.min}`)
      }
      if (parseInt(el.value) > 100 && isPercent) {
        el.value = value
        setErrorMessage(`Value can not exceed 100`)
      }
      if (parseInt(el.value) > parseInt(el.max)) {
        el.value = value
        setErrorMessage(`Value can not exceed ${el.max}`)
      }
    }

    setTimeout(() => {
      setErrorMessage('')
    }, 7000)
  }

  return (
    <div className={`${className}`}>
      {label && (
        <div className='percentage-label-container'>
          <label
            className={`percentage-input-label ${required && 'required'} white-dark-theme-color`}
          >
            {label}
          </label>
          {subLabel && (
            <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2'>
              {subLabel}
            </p>
          )}
        </div>
      )}
      <h4>
        <div className='percentage-input-wrapper'>
          <input
            disabled={isDisabled}
            {...register(name, {required})}
            onInput={enforceMinMax}
            min={minValue}
            max={maxValue}
            type={type}
            name={name}
            value={value}
            onKeyDown={type === 'number' ? preventExpAndSign : () => {}}
            onChange={handleChange}
            className={`form-control form-control-solid  latePaymentInput ${
              type === 'number' ? 'hide-number-input-arrows' : ''
            } ${value ? 'latePaymentInputFilled' : ''} percentage-input`}
            autoComplete='off'
            onWheel={(e) => e.currentTarget.blur()}
          />
          <span
            className={`percentage-placeholder-left ${
              value
                ? 'percentage-placeholder percentage-placeholder-filled'
                : 'percentage-placeholder'
            } ps-3`}
          >
            {value === '' || value === null ? `${firstPlaceholder}` : ''}
          </span>
          <span
            className={`${
              value
                ? 'percentage-placeholder percentage-placeholder-filled'
                : 'percentage-placeholder'
            }`}
            style={{right: `${setRightPosition}`}}
          >
            {`${secondPlaceHolder}`}
          </span>
        </div>

        {errors[name] && (
          <div className={`${errorClasees}`}>
            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 fnt-400'>
              This field is required
            </p>
          </div>
        )}

        {errorMessage?.trim()?.length > 0 && (
          <div className={`${errorClasees}`}>
            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 fnt-400'>
              {errorMessage}
            </p>
          </div>
        )}
      </h4>
    </div>
  )
}

export default PercentageInput
