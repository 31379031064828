import React from 'react'
import {MultiSelect} from 'react-multi-select-component'
import {CustomValueRenderer} from '../../../Utilities/utils'

interface MultiSelectInputProps {
  name: string
  options: any[] // Options for the multiselect
  selectedOption: any[] // Currently selected options
  setSelectedOption: (value: any[]) => void // Function to update selected options
  required?: boolean
  disableSearch?: boolean
  disableInput?: boolean
  hasSelectAll?: boolean
}

const MultiSelectInput: React.FC<MultiSelectInputProps> = ({
  name,
  options,
  selectedOption,
  setSelectedOption,
  required,
  disableSearch = false,
  disableInput = false,
  hasSelectAll = false,
}) => {
  const handleChange = (e: any) => {
    let newVal: any = e

    setSelectedOption(newVal)
  }

  return (
    <div
      className={`multi-select-container ms-0 ${
        selectedOption.length === 0 ? 'no-value select' : 'has-value'
      } `}
    >
      <MultiSelect
        disabled={disableInput}
        options={options}
        value={selectedOption}
        onChange={handleChange}
        labelledBy=''
        overrideStrings={{allItemsAreSelected: `${selectedOption?.[0]?.label}`}}
        disableSearch={disableSearch}
        valueRenderer={CustomValueRenderer}
        hasSelectAll={hasSelectAll}
      />
    </div>
  )
}

export default MultiSelectInput
