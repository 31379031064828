import React, {useState, useRef, useEffect} from 'react'
import {Modal} from 'react-bootstrap'
import './styles.scss'
import atIcon from '../../../../../img/atIcon.png'
import {ApiPost} from '../../../../../apiCommon/helpers/API/ApiData'
import {getEmailHTMLFile, convertHtmlToPng} from '../../../../../Utilities/utils'
import SectionRenderer from './SectionRenderer'
import {ErrorToast, SuccessToast} from '../../../../../apiCommon/helpers/Toast'

interface ButtonProps {
  show: any
  handleClose?: any
  htmlContent?: any
  htmlSections?: any[]
}

const TestEmailTemplate = ({show, handleClose, htmlContent, htmlSections}: ButtonProps) => {
  const [testEmail, setTestEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const emailRef = useRef<HTMLDivElement>(null)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  const sendTestEmail = () => {
    setIsDisabled(true)
    const htmlFile: any = getEmailHTMLFile(htmlContent)

    // convert html to image
    // if (emailRef.current) {
    //   const htmlContent = emailRef?.current
    //   convertHtmlToPng(htmlContent)
    // }

    const formData = new FormData()
    formData.append('html', htmlFile, 'email-template.html')

    ApiPost(`test/email/html?email=${testEmail}`, formData)
      .then((res: any) => {
        SuccessToast(res?.data?.message)
        setIsDisabled(false)
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
        setIsDisabled(false)
      })
  }

  useEffect(()=>{
    // console.log(htmlSections)
  },[htmlSections])

  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '350px',
            maxHeight: '550px',
            overflow: 'auto',
          }}
        >
          <div className='my-1 pb-1 row mt-3'>
            <div className='col-md-8 text-end'>
              <div className='d-flex'>
                <h2 className='head-text mb-0 flex-grow-1'>
                  <b>Test Your Email Template</b>
                </h2>
              </div>
            </div>
            <div className='col-md-4 text-end'>
              {' '}
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  handleClose()
                }}
              >
                X
              </label>
            </div>
          </div>
          {/*  */}
          <div className='my-1 pb-1 row mt-3'>
            <div className='col-md-6'>
              <button
                type='button'
                className='btn btn-sm fw-bold green-submit-btn px-2  ps-3 mt-2 status-w-120  d-flex justify-content-center'
                style={{width: '120px'}}
                onClick={() => {
                  handleClose()
                }}
              >
                Back to Design
              </button>
            </div>
            <div className='col-md-6 text-end'>
            <button
                type='button'
                className='btn btn-sm fw-bold green-submit-btn px-2  ps-3 mt-2 status-w-120 d-flex justify-content-center ms-auto'
                onClick={() => {
                  setIsDisabled(true)
                  sendTestEmail()
                }}
                disabled={testEmail?.trim()?.length == 0 || isDisabled}
              >
                Send Test Email
              </button>
            </div>
          </div>

          {/*  */}
          <div className='my-1 pb-1 row mt-3'>
            <div className='col-md-12 text-center'>
              <h2 className='head-text mb-0 flex-grow-1'>
                <b>Enter Email Address</b>
              </h2>
              <p className='labl-gry mb-0'>
                Please enter the email address which you would like to test this email template
                with:
              </p>
            </div>
          </div>

          {/*  */}
          <div className='my-1 pb-1 row mt-3'>
            <div className='col-md-12 text-center'>
              <div
                className='d-flex align-items-center justify-content-center'
                style={{columnGap: '20px'}}
              >
                <img src={atIcon} alt='at Icon' width={20} />
                {/* EMAIL */}
                <h4 className='my-2 test' style={{width: '40%'}}>
                  <input
                    type='text'
                    className={`form-control form-control-solid mytest ${
                      isEmailValid ? 'inputBorderGreen' : 'inputBorderRed'
                    }`}
                    placeholder='Enter email address'
                    autoComplete='off'
                    style={{backgroundColor: '#fff'}}
                    name='email'
                    value={testEmail}
                    onChange={(e) => {
                      setTestEmail(e.target.value.replace(/\s+/g, ''))
                    }}
                  />
                </h4>
              </div>
            </div>
          </div>

          {/*  */}
          <div className='my-1 pb-1 row mt-3'>
            <div className='col-9 mx-auto'>
              <div className='border px-10 py-5' style={{borderRadius: '10px'}}>
                {htmlSections && (
                  <div
                    className='builder-preview px-7 py-5 h-100'
                    style={{backgroundColor: '#f5f8fa'}}
                    // onClick={() => handleSectionClick(null)}
                    ref={emailRef}
                  >
                    {/* Render all sections here */}

                    <>
                      <div>
                        <div>
                          {htmlSections?.map((section: any, index: any) => (
                            <div className={`cursor-pointer d-flex`}>
                              {/* Render section based on its type */}
                              <div className={`w-100`}>
                                <SectionRenderer
                                  section={section}
                                  isSelected={false}
                                  setSelectedSectionStyling={(data: any) => {}}
                                  // textEditorRef={null}
                                  updateSelectedText={(val:any)=>{}}
                                  selectedField={null}
                                  selectedDocumentField={null}
                                  disableDynamicTextImg={(val:any)=>{}}
                                  updateDynamicText={(val:any)=>{}}
                                  disableDynamicDocumentImg={(val:any)=>{}}
                                  updateDynamicDocument={(val:any)=>{}}
                                  disableDTextBCZofDocument={(val:any)=>{}}
                                  toggleSquareBracket={()=>{}}
                                  updateDisableDTextBCZofDocument={(val:any)=>{}}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/*  */}
         
        </div>
      </Modal>
    </>
  )
}

export default TestEmailTemplate
