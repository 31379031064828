import React from 'react'
import SearchInput from '../../../../../../../../components/Input/SearchInput'
import MultiSelectInput from '../../../../../../../../components/Select/MultiSelectInput'
import constants from '../../../../../../../../constants/options.constants'

interface FiltersProps {
  name: string
  status: string[]
  type: string[]
  onNameChange: (value: string) => void
  onStatusChange: (value: string[]) => void
  onTypeChange: (value: string[]) => void
}

const Filters: React.FC<FiltersProps> = ({
  name,
  status,
  type,
  onNameChange,
  onStatusChange,
  onTypeChange,
}) => {
  const handleTypeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    onTypeChange(event.target.value.split(','))
  }

  return (
    <>
      {/* Name */}
      <div className='xyz me-4'>
        <SearchInput
          value={name}
          onChange={(e) => onNameChange(e.target.value)}
          placeholder='Name'
          searchContainerStyle={{display: 'flex', alignItems: 'center', padding: '5px'}}
          inputBaseStyle={{flex: 1}}
          searchIconStyle={{padding: '10px'}}
          ariaLabel='latePaymentFeeName'
        />
      </div>

      {/* Status */}
      <div className='me-4 test jobs'>
        <div className='property-test'>
          <MultiSelectInput
            name={'latePaymentStatus'}
            options={constants?.latePaymentFeeStatusOptions}
            selectedOption={status}
            setSelectedOption={(newVal: any) => onStatusChange(newVal)}
            required={false}
            disableSearch={true}
            hasSelectAll={true}
          />
        </div>
      </div>

      {/*  Type */}
      <div className='me-4 test jobs'>
        <div className='property-test'>
          <MultiSelectInput
            name={'amountType'}
            options={constants?.amountCalculationTypeOptions}
            selectedOption={type}
            setSelectedOption={(newVal: any) => onTypeChange(newVal)}
            required={false}
            disableSearch={true}
            hasSelectAll={true}
          />
        </div>
      </div>
    </>
  )
}

export default Filters
