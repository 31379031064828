// SearchInput.tsx
import React from 'react';
import { Paper, InputBase, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface SearchInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  searchContainerStyle?: React.CSSProperties;
  inputBaseStyle?: React.CSSProperties;
  searchIconStyle?: React.CSSProperties;
  ariaLabel?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  placeholder = 'Search...',
  searchContainerStyle,
  inputBaseStyle,
  searchIconStyle,
  ariaLabel = 'search'
}) => {
  return (
    <Paper component='form' style={searchContainerStyle}>
      <InputBase
        placeholder={placeholder}
        style={inputBaseStyle}
        inputProps={{ 'aria-label': ariaLabel }}
        value={value}
        onChange={onChange}
      />
      <IconButton style={searchIconStyle} aria-label={ariaLabel}>
        <SearchIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchInput;
