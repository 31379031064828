import { TimePicker } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import MediaComments from './MediaComments';
import MediaPopUp from './MediaPopUp';

function ServiceRequest() {
    const [mainService, setMainService] = useState<any>();
    const [subService, setSubService] = useState<any>();
    const [assignedTo, setAssignedTo] = useState<any>();
    const [showMedia, setShowMedia] = useState<any>(false);
    const [propertyList,setPropertyList]= useState<any>()
    const format = 'HH:mm';

    const PropertiesTable = new Array(2).fill(0);

    const getSubService = () => { }
    const getAssignedTo = () => { }
    const handleChnage = () => { }
    const handleChangeTime = () => { }
    const getServiceProvider = () => { }


    return (
        <>
            <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
                <div id='' className='app-container container-xxl pt-0 mt-0'>

                    <div className='card card-flush app-container py-5'>
                        <div className='d-flex justify-content-end mb-5'>
                            <button className='btn btn-sm fw-bold btn-primary btn-green mx-3'>Submit</button>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <div className='d-flex'>
                                <p className='me-5 mb-0'>Request ID: #### </p>
                                <p className='me-5 mb-0'>Requestor: USERNAME - Tenant </p>
                            </div>
                            <ul className='d-flex'>
                                <li className='ms-10'>Handle Internally</li>
                                <li className='ms-10'>Handel Externally </li>
                            </ul>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <h3 className='fw-bold'>Service Details</h3>
                            <p className='min-w-500px'><b>Internal Team</b>(if "o Handle Internally" Selected)</p>
                        </div>
                        <div className='row'>
                            <Col lg={4} md={4}>
                                <div className="d-flex align-items-center me-10 mb-3">
                                    <label htmlFor='exampleFormControlInput1' className='form-label m-0 min-w-150px'>
                                        Search Main Service
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control form-control-solid'
                                        name='subNewServiceList'
                                        onChange={(e) => setMainService(e.target.value)}
                                    />
                                </div>
                                <div>
                                    <h3 className='mt-5'> Request Urgency </h3>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm py-3'>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            value={0}
                                            id="Internally"
                                            name='standard'
                                            onChange={handleChnage}
                                        />
                                        <label className='form-check-label required' htmlFor='Internally' style={{ marginRight: '6px' }}>
                                            Standard
                                        </label>
                                    </div>
                                    <div className='form-check form-check-custom form-check-solid form-check-sm'>
                                        <input
                                            className='form-check-input'
                                            type='radio'
                                            value={1}
                                            id="Internally"
                                            name='standard'
                                            onChange={handleChnage}
                                        />
                                        <label className='form-check-label required' htmlFor='Internally' style={{ marginRight: '6px' }}>
                                            Emergency
                                        </label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4}>
                                <div className="d-flex align-items-center">
                                    <label htmlFor='exampleFormControlInput1' className="w-200px form-label m-0">
                                        Search Staff
                                    </label>
                                    <input
                                        type='text'
                                        name='sub_service'
                                        className='form-control form-control-solid solid'
                                        onChange={(e) => setSubService(e.target.value)}
                                    />
                                    <button className="btn btn-sm fw-bold btn-primary btn-green hover-none" onClick={() => getSubService()} style={{ marginLeft: '10px' }}>Select</button>
                                </div>
                                <div className='service_list'>
                                    <h3 className='mb-5 mt-5'>  Preferred Schedules For Service</h3>
                                    <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                        <label className='text-muted mr-2 required'>
                                            Date
                                        </label>
                                        <input
                                            type="date"
                                            className='mx-1 form-control form-control-solid'
                                            name="fromDate"
                                            onChange={handleChnage}

                                        />
                                        <label className='text-muted mr-2 required'>
                                            Time
                                        </label>
                                        <TimePicker onChange={handleChangeTime} className='form-control form-control-solid' size="large" defaultOpenValue={moment('00:00', 'HH:mm')} format={format} />
                                    </div>
                                    <div className='payment-receipt-item d-flex align-items-center mb-5'>
                                        <label className='text-muted mr-2 '>
                                            Date
                                        </label>
                                        <input
                                            type="date"
                                            className='mx-1 form-control form-control-solid'
                                            name="fromTime"
                                            onChange={handleChnage}
                                        />
                                        <label className='text-muted mr-2 '>
                                            Time
                                        </label>
                                        <TimePicker onChange={handleChangeTime} className='form-control form-control-solid' size="large" defaultOpenValue={moment('00:00', 'HH:mm')} format={format} />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4} md={4}>
                                <div className="d-flex align-items-center">
                                    <label htmlFor='exampleFormControlInput1' className="w-200px form-label m-0">
                                        Assigned To
                                    </label>
                                    <input
                                        type='text'
                                        name='assigned_to'
                                        className='form-control form-control-solid solid'
                                        onChange={(e) => setAssignedTo(e.target.value)}
                                    />
                                    <button className="btn btn-sm fw-bold btn-primary btn-green hover-none" onClick={() => getAssignedTo()} style={{ marginLeft: '10px' }}>Select</button>
                                </div>
                                <div className='d-flex justify-content-center mb-5 mt-5'>
                                    <button className="btn btn-sm fw-bold btn-primary btn-green hover-none">Schedule Inspection</button>
                                </div>
                                <p className='min-w-500px'><b>Service Provider</b>(if "o Handle Internally" Selected)</p>
                                <div className="d-flex align-items-center">
                                    <label htmlFor='exampleFormControlInput1' className="form-label m-0" style={{ minWidth: "110px" }}>
                                        Service Provider
                                    </label>
                                    <input
                                        type='text'
                                        name='service_provider'
                                        className='form-control form-control-solid solid'
                                        onChange={(e) => handleChnage()}
                                    />
                                    <button className="btn btn-sm fw-bold btn-primary btn-green hover-none" onClick={() => getServiceProvider()} style={{ marginLeft: '10px' }}>Select</button>
                                </div>
                            </Col>
                        </div>
                        <h3 className='fw-bold'>Properties</h3>
                        <Row>
                            <Col lg={8} md className='pt-0 table-responsive'>
                                <table
                                    className='table align-middle table-row-dashed fs-6 gy-5'
                                    id='kt_ecommerce_sales_table'
                                >
                                    <thead>
                                        <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                            <th className='text-center min-w-100px'>Job ID</th>
                                            <th className='text-center min-w-100px'>Development</th>
                                            <th className='text-center min-w-100px'>Floor#/Cluster</th>
                                            <th className='text-center min-w-100px'>Unit ### </th>
                                            <th className='text-center min-w-100px'>Unit Type </th>
                                            <th className='text-center min-w-100px'># Bedrooms </th>
                                            <th className='text-center min-w-100px'></th>
                                        </tr>
                                    </thead>
                                    <tbody className='fw-semibold text-gray-600'>
                                        {PropertiesTable.map((f, i) => {
                                            return (
                                                <tr>
                                                    <td className='text-center min-w-100px'>Job ID</td>
                                                    <td className='text-center min-w-100px'>Development</td>
                                                    <td className='text-center min-w-100px'>Floor#/Cluster</td>
                                                    <td className='text-center min-w-100px'>Unit ###</td>
                                                    <td className='text-center min-w-100px'>Unit Type</td>
                                                    <td className='text-center min-w-100px'># Bedrooms</td>
                                                    <td>
                                                        <button
                                                            className='btn min-w-150px btn-sm fw-bold btn-primary btn-green mx-3'
                                                            data-bs-toggle='modal'
                                                            data-bs-target='#kt_modal_create_app'
                                                            onClick={() => setShowMedia(true)}
                                                        >
                                                            Media & Comments
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                            </Col>
                            <Col md={4}>
                                <div className='select-box px-5'>
                                    <div className='mb-10 service_list'>
                                        <Row className='mb-5'>
                                            <Col md={5}>
                                                <h5 className='mb-5 mt-5' style={{ color: '#007a59' }}>  Properties Selected</h5>
                                            </Col>
                                            <Col md={7}>
                                                <h5 className='mb-2 mt-5'>  Total Buildings/Communities :</h5>
                                                <h5 className='mb-5 '>  Total Units :</h5>
                                            </Col>
                                        </Row>
                                        <Row className='mb-5'>
                                            <Col md={5}>
                                                <h5 className='mb-5 mt-5' style={{ color: '#007a59' }}>Building/Community Name</h5>
                                            </Col>
                                            <Col md={7}>
                                                <h5 className='mb-2 mt-5'>Total Units:</h5>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            {/* <MediaPopUp
                show={showMedia}
                handleClose={() => {
                    setShowMedia(false)
                }}
                propertyList={propertyList}
            // showMediaType={showMediaType}
            /> */}

        </>
    );
}

export default ServiceRequest;