import React from 'react'
import {Col, Modal, Row} from 'react-bootstrap'
import {ApiGet, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  show: any
  handleClose: any
  showBankData: any
  paymentId: any
}

const BankData = ({show, handleClose, showBankData, paymentId}: ButtonProps) => {
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
      size='sm'
    >
      <div className='modal-header'>
        <h2>Bank Details</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <Row>
          <Col md={12} className=''>
            <div className='payment-receipt-item'>
              {showBankData?.bank?.chequeNo && (
                <h6 className='text-muted'>
                  cheque No :
                  <span className='mx-1' style={{color: 'black'}}>
                    {showBankData?.bank?.chequeNo}
                  </span>
                </h6>
              )}
            </div>
            <div className='payment-receipt-item'>
              {showBankData?.bank?.IBAN && (
                <h6 className='text-muted'>
                  Bank :
                  <span className='mx-1' style={{color: 'black'}}>
                    {showBankData?.bank?.IBAN}
                  </span>
                </h6>
              )}
            </div>
            <div className='payment-receipt-item'>
              {showBankData?.bank?.accountNo && (
                <h6 className='text-muted'>
                  Account No :
                  <span className='mx-1' style={{color: 'black'}}>
                    {showBankData?.bank?.accountNo}
                  </span>
                </h6>
              )}
            </div>
            <div className='payment-receipt-item'>
              {showBankData?.bank?.bankName && (
                <h6 className='text-muted'>
                  Bank Name :
                  <span className='mx-1' style={{color: 'black'}}>
                    {showBankData?.bank?.bankName}
                  </span>
                </h6>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default BankData
