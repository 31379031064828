import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ApiPost, Bucket, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import {useParams} from 'react-router-dom'
import Paper from '@mui/material/Paper'
import InputBase from '@mui/material/InputBase'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import {CircularProgress} from '@mui/material'
import noData from '../../../../img/NoData1.svg'

interface ButtonProps {
  show?: any
  handleClose?: any
  updateDetails?: any
  requestData?: any
  requestDetails?:any
}

const LinkToARequestModal = ({show, handleClose, updateDetails, requestData, requestDetails}: ButtonProps) => {
  // console.log(requestData)
  const {id} = useParams()
  const [selectedType, setSelectedType] = useState<any>('')

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [isLoading, setIsLoading] = useState<any>(false)

  const [requestList, setRequestList] = useState<any>([])

  const [radioChecked, setRadioChecked] = useState<any>(-1)
  const [selectedRequest, setSelectedRequest] = useState<any>()

  const searchContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    // marginLeft: '10px',
  }

  const searchIconStyle = {
    padding: '10px',
    color: 'gray',
  }

  const inputBaseStyle = {
    flex: 1,
    padding: '5px',
  }

  const [expenseItems, setExpenseItems] = useState<any>([])
  const [searchRequest, setSearchRequest] = useState<any>('')

  const getMaintenanceRequests = async () => {
    const body = {
      buildingId: requestData?.[0]?.devType == 'build' ? requestData?.[0]?.value : '',
      communityId: requestData?.[0]?.devType == 'comm' ? requestData?.[0]?.value : '',
      searchName: searchRequest,
      page: 1,
      limit: 10000,
    }
    await ApiPost(`corporate/maintenance_service_request/get/expense`, body)
      .then((res) => {
        setRequestList(res?.data?.data)
        setRadioChecked(requestDetails ? requestDetails?._id : -1)
        console.log(requestDetails ? requestDetails?._id : -1)
      })
      .catch((e) => {
        setIsLoading(false)
        ErrorToast(e?.message)
      })
  }

  // console.log(requestDetails)

  useEffect(() => {
    getMaintenanceRequests()
  }, [searchRequest])

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={show}
        onHide={() => {
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            minHeight: '500px',
            // maxHeight: '500px',
            // overflow: 'auto',
          }}
        >
          <div className='d-flex align-items-top'>
            <h2 className=' pt-5 mb-0'>
              <b>Select Request</b>
            </h2>

            <button
              type='button'
              className='btn btn-sm fw-bold ms-3 green-submit-btn ps-3 d-flex justify-content-center status-w-175 ms-auto mt-5'
              onClick={(e: any) => {updateDetails(selectedRequest)}}
              disabled={radioChecked == -1}
              
            >
              {/* <img src={submitImg} height={19} width={19} className='me-4' /> {'  '} */}
              Link
            </button>

            <label
              className='head-text fs-2 cursor-pointer ms-3'
              style={{fontWeight: '700'}}
              onClick={() => {
                handleClose()
              }}
            >
              X
            </label>
          </div>
          <div className='d-flex align-items-center w-75'>
            <p className=' pt-0 mb-0 pe-13'>
              Select the request you would like to link this expense to. Once the request is
              selected, the jobs under the request will be automatically allocated to each expense
              item added based on the property assigned to it.
            </p>
          </div>

          <div>
            <>
              <div className='d-flex align-items-center px-0 mt-5'>
                <h3 className=' mb-0'>
                  <b>Requests - {requestData?.[0]?.label} </b>
                </h3>
                <label className='head-text ms-auto status-w-100' style={{fontWeight: '600'}}>
                  {' '}
                  Search{' '}
                </label>

                <div className='mb-2 xyz '>
                  <Paper component='form' style={searchContainerStyle}>
                    <InputBase
                      placeholder='Request'
                      style={inputBaseStyle}
                      inputProps={{'aria-label': 'search'}}
                      value={searchRequest}
                      onChange={(e: any) => setSearchRequest(e.target.value)}
                    />
                    <IconButton style={searchIconStyle} aria-label='search'>
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-12 pe-0'>
                  <div className=''>
                    <div
                      className='table-responsive'
                      style={{maxHeight: '380px', overflow: 'auto'}}
                    >
                      <table
                        className='table align-middle  fs-6 gy-5'
                        id='kt_ecommerce_sales_table'
                      >
                        <thead>
                          <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                            <th className='text-start w-25px test'></th>
                            <th className='text-start min-w-100px'>Request ID</th>
                            <th className='text-start min-w-100px'>Type</th>
                            <th className='text-start min-w-100px'>Request Name</th>
                            <th className='text-start min-w-75px'>Jobs</th>
                            <th className='text-start min-w-100px'>Handled By</th>
                            <th className='text-center min-w-100px'>Status</th>
                          </tr>
                        </thead>

                        <tbody className='fw-semibold text-gray-600'>
                          {requestList?.length > 0 ? (
                            <>
                              {requestList?.map((rs: any, index: any) => {
                                return (
                                  <tr>
                                    <td className='text-start w-25px  test ps-0'>
                                      {radioChecked === rs?._id ? (
                                        <div
                                          style={{
                                            height: '16px',
                                            width: '16px',
                                            backgroundColor: '#146c6a',
                                            borderRadius: '50%',
                                          }}
                                          className='cursor-pointer'
                                        ></div>
                                      ) : (
                                        <div
                                          style={{
                                            height: '16px',
                                            width: '16px',
                                            backgroundColor: 'transparent',
                                            borderRadius: '50%',
                                            border: '1px solid #146c6a',
                                          }}
                                          onClick={() => {
                                            setRadioChecked(rs?._id)
                                            setSelectedRequest(rs)
                                          }}
                                          className='cursor-pointer'
                                        ></div>
                                      )}
                                    </td>
                                    <td className='text-start'>{rs?.id ? rs?.id : '-'}</td>
                                    <td className='text-start '>
                                      {rs?.maintenanceRequestType == 0
                                        ? 'Reactive'
                                        : 'Planned Preventative'}
                                    </td>
                                    <td className='text-start '>
                                      <div className='d-flex align-items-center'>
                                        {rs?.maintenance_sub_service?.length > 0 ||
                                        rs?.maintenance_main_service?.length > 0
                                          ? `${rs?.maintenance_main_service?.[0]?.name} - ${rs?.maintenance_sub_service?.[0]?.name}`
                                          : '-'}
                                      </div>
                                    </td>
                                    <td className='text-start'>
                                      {rs?.totalJob ? rs?.totalJob : '-'}
                                    </td>
                                    <td className='text-start '>
                                      {rs?.handlerType == 0 ? 'Internal Team' : 'External Team'}
                                    </td>
                                    <td className='text-center '>
                                      <div className='d-flex justify-content-center'>
                                        <div
                                          className={
                                            rs?.maintenanceRequestStatus == 0
                                              ? 'tenancy-stats booked-bar ms-5'
                                              : rs?.maintenanceRequestStatus == 1
                                              ? 'tenancy-stats expiring-bar ms-5'
                                              : rs?.maintenanceRequestStatus == 2
                                              ? 'tenancy-stats active-bar ms-5'
                                              : rs?.maintenanceRequestStatus == 3
                                              ? 'tenancy-stats expired-bar ms-5'
                                              : rs?.maintenanceRequestStatus == 4
                                              ? 'tenancy-stats req-bar ms-5'
                                              : 'tenancy-stats  no-st-bar ms-5'
                                          }
                                        >
                                          {rs?.maintenanceRequestStatus == 0
                                            ? 'Not Started'
                                            : rs?.maintenanceRequestStatus == 1
                                            ? 'In-Progress'
                                            : rs?.maintenanceRequestStatus == 2
                                            ? 'Completed'
                                            : rs?.maintenanceRequestStatus == 3
                                            ? 'Cancelled'
                                            : rs?.maintenanceRequestStatus == 4
                                            ? 'Requested'
                                            : '-'}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )
                              })}
                            </>
                          ) : (
                            <tr>
                              {!isLoading ? (
                                <td colSpan={7} className='text-center'>
                                  <img src={noData} alt='' width={350} />
                                </td>
                              ) : (
                                <td colSpan={7} className='text-center'>
                                  <div
                                    className='d-flex justify-content-center align-items-center w-100'
                                    style={{height: '25vh'}}
                                  >
                                    <CircularProgress style={{color: '#007a59'}} />
                                  </div>
                                </td>
                              )}
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default LinkToARequestModal
