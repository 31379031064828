import React from 'react'
import {Modal} from 'react-bootstrap'
import {useForm} from 'react-hook-form'
import {Navigate, useNavigate} from 'react-router-dom'
import {ApiPut, ApiUpload} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  show: any
  handleClose: any
  setFormData: any
  formData: any
}

function SignatureModal({show, handleClose, setFormData, formData}: ButtonProps) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
  } = useForm<any>()
  const [uploadedFile, setUploadedFile] = React.useState<any>('')
  const navigate = useNavigate()

  const uploadFilelHandler = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/document', formData)
      .then((res: any) => setUploadedFile(res?.data?.data?.image))
      .catch((err: any) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const onSubmit = () => {
    const body = {
      id: formData && formData[0]?._id,
      approvalStatus: 2,
      comment: 'TEST',
      eSignature: uploadedFile,
      writtenSignature: 'TEST',
    }
    console.log('body', body)
    ApiPut('corporate/approval_request', body)
      .then((res) => {
        SuccessToast(res?.data?.message)
        navigate(`/vendor-management/management-requests`)
      })
      .catch((err) => ErrorToast(err.message))
  }

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={() => {
        handleClose()
      }}
      backdrop={true}
      size='sm'
    >
      <div className='modal-body py-lg-10 px-lg-10'>
        <div className='d-flex justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
            <KTSVG
              className='svg-icon-1 text-black'
              path='/media/icons/duotune/arrows/arr061.svg'
            />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='d-flex justify-content-center mb-5'>
            <button
              className='btn btn-sm fw-bold me-5 btn-primary btn-green w-150px me-2 hover-none'
              type='submit'
            >
              Approve & Submit
            </button>
          </div>
          <p className='mb-5'>*Your e-signature is required to complete this step.</p>
          <p>E-Signature</p>
          <div className='d-flex justify-content-center'>
            <div>
              <button className='btn btn-sm fw-bold ms-5 btn-primary btn-green hover-none position-relative'>
                Upload
                <input
                  type='file'
                  {...register('esignature', {required: true})}
                  className='form-control form-control-solid position-absolute '
                  placeholder=''
                  name='esignature'
                  onChange={uploadFilelHandler}
                  style={{top: 0, left: 0, width: '100%', height: '100%', opacity: 0}}
                />
              </button>
              {errors.esignature && (
                <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
              )}
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default SignatureModal
