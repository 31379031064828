import React, {useEffect, useState} from 'react'
import ApexCharts from 'react-apexcharts'
import {CircularProgress} from '@mui/material'

interface IncomeVsExpanseChartMonthlyProps {
  chartData: DataPoint[]
}

interface DataPoint {
  time: string
  income: {
    value: number
  }
  expense: {
    value: number
  }
}

const IncomeVsExpanseChartMonthly: React.FC<IncomeVsExpanseChartMonthlyProps> = ({chartData}) => {
  const [seriesData, setSeriesData] = useState({
    income: [] as number[],
    expense: [] as number[],
  })

  const [visibleSeries, setVisibleSeries] = useState({
    income: true,
    expense: true,
  })

  const [isSkeleton, setIsSkeleton] = useState<any>(false)

  const handleCheckboxChange = (seriesName: keyof typeof visibleSeries) => {
    setIsSkeleton(true)
    setVisibleSeries((prevState) => ({
      ...prevState,
      [seriesName]: !prevState[seriesName],
    }))

    setTimeout(() => {
      setIsSkeleton(false)
    }, 300)
  }

  const seriesColors = {
    income: '#00b9a9',
    expense: '#D54645',
  }

  useEffect(() => {
    const newSeriesData = {
      income: [] as number[],
      expense: [] as number[],
    }

    chartData?.forEach((dataPoint) => {
      newSeriesData?.income?.push(dataPoint?.income?.value)
      newSeriesData?.expense?.push(dataPoint?.expense?.value)
    })

    setSeriesData(newSeriesData)

    console.log(newSeriesData)
  }, [chartData])

  const series = [
    {
      name: 'Income',
      data: seriesData.income,
      color: '#00b9a9',
      strokeDashArray: 4,
    },
    {
      name: 'Expense',
      data: seriesData.expense,
      color: '#D54645',
      stroke: {
        dashArray: 0,
      },
    },
  ].filter((s) => visibleSeries?.[s.name.toLowerCase() as keyof typeof visibleSeries])

  const categories: string[] = chartData?.map((dataPoint) => dataPoint.time)

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true,
          customIcons: [],
        },
      },
      selection: {
        enabled: true,
      },
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true,
      },
    },
    grid: {
      show: false,
    },
    title: {
      text: 'Income vs. Expenses (Overall)',
      align: 'left',
      style: {
        fontSize: '14px',
        fontWeight: '800',
      },
    },
    xaxis: {
      categories: categories,
      type: 'datetime',
      labels: {
        format: 'MMM-yy',
        rotateAlways: true,
        minHeight: 55,
      },
    },
    yaxis: {
      labels: {
        formatter: (value: any) => {
          return ` ${
            !isNaN(parseFloat(value))
              ? Number.isInteger(value)
                ? parseFloat(value)?.toLocaleString('en-US')
                : parseFloat(value)?.toLocaleString('en-US', {
                    maximumFractionDigits: 0,
                  })
              : ''
          }`
        },
      },
      min: 0,
      max: Math.max(...seriesData.income, ...seriesData.expense),
    },
    tooltip: {
      shared: true,
      intersect: false,
      custom: function ({series, seriesIndex, dataPointIndex, w}) {
        const dataPoint = chartData[dataPointIndex]
        const date = new Date(categories[dataPointIndex]).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
        })

        const incomeVisible = visibleSeries.income
        const expenseVisible = visibleSeries.expense

        let tooltipContent =
          `<div class="apexcharts-tooltip-custom" style="width: fit-content">` +
          `<div style="text-align:center;" class="text-decoration-underline">${date}</div>` +
          `<div style="display: block; justify-content: space-between;">`

        if (incomeVisible) {
          tooltipContent += `<div><span style="color: #00b9a9">&#9679;</span>AED ${seriesData.income[
            dataPointIndex
          ]?.toLocaleString('en-US')}</div>`
        }

        if (expenseVisible) {
          tooltipContent += `<div><span style="color: #D54645">&#9679;</span>AED ${seriesData.expense[
            dataPointIndex
          ]?.toLocaleString('en-US')}</div>`
        }

        tooltipContent += `</div></div>`
        return tooltipContent
      },
    },
    stroke: {
      curve: 'smooth',
    },
    series: series,
    legend: {
      show: false,
      position: 'top',
      horizontalAlign: 'right',
      offsetY: 0,
      offsetX: 0,
    },
  }

  useEffect(() => {
    const styles = `
    .apexcharts-tooltip-custom {
      padding: 3px 7px 3px 17px;
      background: #fff;
      border: 1px solid #ccc;
      width: fit-content
    }
    .apexcharts-tooltip-custom div {
      color:#666;
    }`

    const styleSheet = document.createElement('style')
    styleSheet.innerText = styles
    document.head.appendChild(styleSheet)

    return () => {
      document.head.removeChild(styleSheet)
    }
  }, [])

  return (
    <div className='card card-flush py-4 px-2 h-100 min-h-410'>
      <div className='chart-container'>
        <div className='legend-container d-flex w-100'>
          <div className=' d-flex align-items ms-auto'>
            {Object?.keys(visibleSeries)?.map((seriesName) => (
              <label
                key={seriesName}
                style={{marginRight: '15px', display: 'flex', alignItems: 'center'}}
              >
                <input
                  type='checkbox'
                  className='form-check-input me-2'
                  checked={visibleSeries[seriesName as keyof typeof visibleSeries]}
                  onChange={() => handleCheckboxChange(seriesName as keyof typeof visibleSeries)}
                />
                <span
                  style={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '50%',
                    backgroundColor: seriesColors[seriesName as keyof typeof seriesColors],
                    marginRight: '5px',
                  }}
                ></span>
                {seriesName.charAt(0).toUpperCase() + seriesName.slice(1)}
              </label>
            ))}
          </div>
        </div>
        {!isSkeleton ? (
          <ApexCharts options={options} series={series} type='line' height={350} />
        ) : (
          <div
            className='d-flex justify-content-center align-items-center w-100'
            style={{height: '350px'}}
          >
            <CircularProgress style={{color: '#007a59'}} />
          </div>
        )}
      </div>
    </div>
  )
}

export default IncomeVsExpanseChartMonthly
