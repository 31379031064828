import React, {useEffect} from 'react'
import {useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import backArrow from '../../../../../img/back-arrow.png'
import addWhite from '../../../../../img/add-white.png'
import {ApiGet, ApiPost} from '../../../../../apiCommon/helpers/API/ApiData'
import noData from '../../../../../img/NoData1.svg'
import workFlowsImg from '../../../../../img/workflows.png'
import emailsImg from '../../../../../img/crm-email-black.png'
import tenantPortalImg from '../../../../../img/crm-tenant-portal-black.png'
import webformsImg from '../../../../../img/crm-webforms-black.png'
import webchatImg from '../../../../../img/crm-web-chat-black.png'
import socialImg from '../../../../../img/crm-social-black.png'
import callCenterImg from '../../../../../img/crm-call-center-black.png'
import {ErrorToast} from '../../../../../apiCommon/helpers/Toast'

const Channels = () => {
  const navigate = useNavigate()

  const [mainWorkflows, setMainWorkflows] = useState<any>()
  const [count, setCount] = useState<any>({})

  const getMainWorkFlows = () => {
    ApiPost('')
      .then((res: any) => {
        setMainWorkflows(res?.data?.data)
      })
      .catch(() => {})
  }

  const getCount = () => {
    ApiGet('corporate/channel/get/count')
      .then((res) => {
        console.log(res?.data?.data)
        setCount(res?.data?.data)
      })
      .catch((err) => ErrorToast(err.message))
  }

  useEffect(() => {
    getCount()
  }, [])

  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <div className='d-flex align-items-center gap-2 mb-4 gap-lg-3'>
          <div className='d-flex align-items-start gap-3'>
            <span className='rounded-circle' style={{cursor: 'pointer'}} onClick={() => navigate('/settings')}>
              <img src={backArrow} height={14} width={14} />
            </span>
            <div>
              <h2 className='page-heading m-0 head-text mb-1'>Channels</h2>
              <p className='labl-gry fs-4 mb-0 light-dark-theme-color'>
                Connect your customer facing channels to your CRM so you can start generating tickets and manage your customer requests in one place.
              </p>
            </div>
          </div>
          <div className='ms-auto'>
            <img src={workFlowsImg} height={30} width={30} />
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-8 px-3' style={{minHeight: '75vh'}}>
          <div className='card-body pt-0 mt-5 py-0 px-3'>
            <Row className='my-5'>
              {/* {mainWorkflows?.length > 0 ? (
                <> */}
              {/* {mainWorkflows?.map((workflow: any, index: any) => {
                    return ( */}
              <Col md={6} className='mb-5'>
                <div
                  className={`card card-flush gap-5 p-3 cursor-pointer ${count?.emailCount > 0 ? 'active-border-right-1px active-border-left-1px active-border-right-6px':'grey-border-right-1px grey-border-left-1px grey-border-right-6px'}`}
                  onClick={() => {
                    navigate('/settings/crm-residents/channels/emails')
                  }}
                >
                  <div className='card-body p-3'>
                    <div className='d-flex align-items-center'>
                      <img src={emailsImg} height={35} width={35} />
                      <h4 className='page-heading m-0 head-text ps-4'>
                        <b>Emails</b>
                      </h4>
                      <div className='ms-auto text-center'>
                        <h5 className=' m-0 labl-gry mb-3 light-dark-theme-color'>Linked</h5>
                        <h4 className=' m-0 head-text'>
                          <b>{count?.emailCount}</b>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} className='mb-5'>
                <div className='card card-flush gap-5 p-3 active-border-right-1px active-border-left-1px active-border-right-6px'>
                  <div className='card-body p-3'>
                    <div className='d-flex align-items-center'>
                      <img src={tenantPortalImg} height={35} width={35} />
                      <h4 className='page-heading m-0 head-text ps-4'>
                        <b>Tenant Portal</b>
                      </h4>
                      <div className='ms-auto text-center'>
                        <h5 className=' m-0 labl-gry mb-3 light-dark-theme-color'>Linked</h5>
                        <h4 className=' m-0 head-text'>
                          <b>1</b>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} className='mb-5'>
              <div className='position-relative'>
                  <div className='requestBoxOpacity z-index-999 border-radius-5px channels-opacity'>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                      <h2 className='labl-gry'>Coming Soon</h2>
                    </div>
                  </div>
                <div className='card card-flush gap-5 p-3 cursor-pointer grey-border-right-1px grey-border-left-1px grey-border-right-6px'>
                  <div className='card-body p-3'>
                    <div className='d-flex align-items-center'>
                      <img src={webformsImg} height={35} width={35} />
                      <h4 className='page-heading m-0 head-text ps-4'>
                        <b>Webforms</b>
                      </h4>
                      <div className='ms-auto text-center'>
                        <h5 className=' m-0 labl-gry mb-3 light-dark-theme-color'>Linked</h5>
                        <h4 className=' m-0 head-text'>
                          <b>{count?.webFormsCount}</b>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </Col>
              <Col md={6} className='mb-5 '>
                <div className='position-relative'>
                  <div className='requestBoxOpacity z-index-999 border-radius-5px channels-opacity'>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                      <h2 className='labl-gry'>Coming Soon</h2>
                    </div>
                  </div>
                  <div className='card card-flush gap-5 p-3 cursor-pointer grey-border-right-1px grey-border-left-1px grey-border-right-6px'>
                    <div className='card-body p-3'>
                      <div className='d-flex align-items-center'>
                        <img src={webchatImg} height={35} width={35} />
                        <h3 className='page-heading m-0 head-text ps-4'>
                          <b>Web Chat</b>
                        </h3>
                        <div className='ms-auto text-center'>
                          <h5 className=' m-0 labl-gry mb-3 light-dark-theme-color'>Linked</h5>
                          <h4 className=' m-0 head-text'>
                            <b>{count?.chatCount}</b>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} className='mb-5'>
                <div className='position-relative'>
                  <div className='requestBoxOpacity z-index-999 border-radius-5px channels-opacity'>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                      <h2 className='labl-gry'>Coming Soon</h2>
                    </div>
                  </div>
                  <div className='card card-flush gap-5 p-3 cursor-pointer grey-border-right-1px grey-border-left-1px grey-border-right-6px'>
                    <div className='card-body p-3'>
                      <div className='d-flex align-items-center'>
                        <img src={socialImg} height={35} width={35} />
                        <h3 className='page-heading m-0 head-text ps-4'>
                          <b>Social</b>
                        </h3>
                        <div className='ms-auto text-center'>
                          <h5 className=' m-0 labl-gry mb-3 light-dark-theme-color'>Linked</h5>
                          <h4 className=' m-0 head-text'>
                            <b>{count?.socialCount}</b>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} className='mb-5'>
                <div className='position-relative'>
                  <div className='requestBoxOpacity z-index-999 border-radius-5px channels-opacity'>
                    <div className='d-flex justify-content-center align-items-center h-100'>
                      <h2 className='labl-gry'>Coming Soon</h2>
                    </div>
                  </div>
                  <div className='card card-flush gap-5 p-3 cursor-pointer grey-border-right-1px grey-border-left-1px grey-border-right-6px'>
                    <div className='card-body p-3'>
                      <div className='d-flex align-items-center'>
                        <img src={callCenterImg} height={35} width={35} />
                        <h3 className='page-heading m-0 head-text ps-4'>
                          <b>Call Center</b>
                        </h3>
                        <div className='ms-auto text-center'>
                          <h5 className=' m-0 labl-gry mb-3 light-dark-theme-color'>Linked</h5>
                          <h4 className=' m-0 head-text'>
                            <b>{count?.phoneCount}</b>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              {/* )
                   })} */}
              {/* </>
              ) : (
                <>
                  <Row>
                    <Col md={12} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </Col>
                  </Row>
                </>
              )} */}
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default Channels
