import React, {useState} from 'react'
import ApexCharts from 'react-apexcharts'
import averageVacantDays from '../../../img/averageVacantDays.png'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {AiOutlineExclamationCircle} from 'react-icons/ai'

interface vacantDaysProps {
  chartData?: any
}

const AverageVacantDays: React.FC<vacantDaysProps> = ({chartData}) => {
  return (
    <div className='card card-flush py-5 px-7 h-100' style={{width: '275px'}}>
      <div className='ms-auto'>
        <OverlayTrigger
          placement='top'
          delay={{show: 250, hide: 200}}
          overlay={
            <Tooltip id='button-tooltip-2'>
              The average time it takes to rent a vacant property.
            </Tooltip>
          }
        >
          <span>
            {' '}
            <AiOutlineExclamationCircle
              color='#35475e '
              fontSize={14}
              fontWeight={500}
              className='cursor-pointer'
              style={{cursor: 'pointer', marginBottom: '2px'}}
            />
          </span>
        </OverlayTrigger>
      </div>
      <div className='d-flex flex-column align-items-center justify-content-between h-100 text-center'>
        <div style={{marginTop: '35px'}}>
          <span style={{fontSize: '18px', margin: '0px', display: 'block', fontWeight: 'bold'}}>
            {Number?.isInteger(chartData) ? chartData : chartData?.toFixed(1)}
          </span>
          <span style={{margin: '0px', fontSize: '14px', display: 'block'}}>Days</span>
          <img src={averageVacantDays} width={45} height={45} className='mt-3' />
        </div>
        <div className='d-flex justify-content-center mt-auto'>
          <p className='fnt-500 fs-3 mb-3'>Average Vacant Days</p>
        </div>
      </div>
    </div>
  )
}

export default AverageVacantDays
