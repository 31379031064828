import React from 'react'
import {Col, Row} from 'react-bootstrap'
import {AiFillDelete} from 'react-icons/ai'
import ReactPaginate from 'react-paginate'
import {useNavigate} from 'react-router-dom'
import {ApiPost, ApiPut} from '../../../apiCommon/helpers/API/ApiData'

function PropertiseServiceList() {
  const navigate = useNavigate()
  const Vendors = new Array(5).fill(0)
  const unit = new Array(18).fill(0)
  const [propertyList, setPropertyList] = React.useState<any>()
  const [mainService, setMainService] = React.useState<any>()
  const [subService, setSubService] = React.useState<any>()
  const [page, setPage] = React.useState<any>(1)
  const [pageLimit, setPageLimit] = React.useState<any>()
  const [checked, setChecked] = React.useState<any>()
  const [selectedSrvices, setselectedSrvices] = React.useState<any>()
  const currentTab: any = localStorage.getItem('currentTab')
  const requestServiceId: any = localStorage.getItem('serviceRequestId')

  const getPropertiseList = async () => {
    const body = {
      page: page,
      limit: 10,
      mainServiceName: mainService ? mainService : '',
      subServiceName: subService ? subService : '',
    }
    await ApiPost(`corporate/main_service/propertise/get`, body)
      .then((res) => {
        setPropertyList(res?.data?.data?.services_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const addServiceRequest = async () => {
    const body = {
      requestType: 1,
    }
    await ApiPost(`corporate/service_request`, body)
      .then((res) => {
        localStorage.setItem(currentTab, res?.data?.data?._id)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  React.useEffect(() => {
    // if (!requestServiceId) {
    //   addServiceRequest()
    // }
    getPropertiseList()
  }, [page, mainService, subService])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const handleSelectService = (item: any, index: any) => {
    setChecked(index)
    setselectedSrvices(item)
  }

  const removeService = () => {
    setChecked('')
    setselectedSrvices('')
  }
  const addService = async () => {
    const body = {
      id: requestServiceId,
      mainServiceId: selectedSrvices?._id,
      subServiceId: selectedSrvices?.sub_service?._id,
      requestType: 1,
    }
    await ApiPut(`corporate/service_request`, body)
      .then((res) => {})
      .catch((e) => {
        console.log(e)
      })
    moveVendor()
  }
  const moveVendor = () => {
    navigate(`/request-management/service/vendor-list`)
    localStorage.setItem('inter', 'false')
  }

  return (
    <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
      <div id='' className='app-container container-xxl pt-0 mt-0'>
        {/* <div className='d-flex align-items-baseline flex-wrap mr-5'>
          <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'>
            <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate(`/request-management/service`)}
              >
                Request
              </a>
            </li>
            <li className='breadcrumb-item '>
              <a className='text-muted px-2 cursor-pointer' onClick={() => moveVendor()}>
                External Request
              </a>
            </li>
            <li className='breadcrumb-item active'>
              <a className='px-2'> Propertise List</a>
            </li>
          </ul>
        </div>
        <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li
              className='nav-item'
              onClick={() => {
                navigate(`/request-management/service/create-request-service`)
              }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Internal
              </a>
            </li>
            <li
              className='nav-item'
              // onClick={() => {
              //     navigate(`/request-management/quotation`)
              // }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                Marketplace
              </a>
            </li>
            <li className='nav-item' onClick={() => moveVendor()}>
              <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                VendorList
              </a>
            </li>
          </ul>
        </div> */}

        <div className='card card-flush app-container py-5'>
          <Row>
            <div className='col-md-3'>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                onClick={() => navigate('/request-management/service/vendor-list')}
              >
                Back
              </a>
            </div>
            <div className='col-md-3'></div>
            <div className='col-md-3'></div>
            <div className='col-md-3' style={{textAlign: 'end'}}>
              <a
                className='btn btn-sm fw-bold btn-primary btn-green mx-3'
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_create_app'
                onClick={() => addService()}
              >
                Add
              </a>
            </div>
          </Row>
          <div className='mb-10 mt-10'>
            <Row>
              <Col lg={6} md={6}></Col>
              <Col lg={6} md={6} className='select-box'>
                <h3 className='page-heading mt-2 text-dark fw-bold fs-3 mb-5'>Service Selected</h3>
                <table
                  className='table align-middle table-row-dashed fs-6 gy-5'
                  id='kt_ecommerce_sales_table'
                >
                  <thead>
                    <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                      <th className='text-center min-w-100px'>Main Service Name</th>
                      <th className='text-center min-w-100px'>Sub Service Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className='fw-semibold text-gray-600'>
                    {selectedSrvices?.name && (
                      <tr>
                        <td className='text-center min-w-100px'>{selectedSrvices?.name}</td>
                        <td className='text-center min-w-100px'>
                          {selectedSrvices?.sub_service?.name}
                        </td>
                        <td>
                          <AiFillDelete
                            style={{cursor: 'pointer'}}
                            onClick={() => removeService()}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
          <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/request-management/service/vendor-list/request-custom-list`)
                }}
              >
                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                  Custom List
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(`/request-management/service/vendor-list/propertise-service-list`)
                }}
              >
                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                  Propertise List
                </a>
              </li>
            </ul>
          </div>
          <Col md={8}>
            <h3 className='page-heading text-dark fw-bold fs-3 mb-5'>Select Service</h3>
            <div className='mb-10 d-flex align-items-center'>
              <div className='d-flex align-items-center me-10 mb-3'>
                <label htmlFor='exampleFormControlInput1' className='form-label m-0 min-w-150px'>
                  Search Main Service
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  name='subNewServiceList'
                  onChange={(e) => setMainService(e.target.value)}
                />
              </div>
              <div className='d-flex align-items-center mb-3'>
                <label htmlFor='exampleFormControlInput1' className='form-label m-0 min-w-150px'>
                  Search Sub-Service
                </label>
                <input
                  type='text'
                  className='form-control form-control-solid'
                  name='subNewServiceList'
                  onChange={(e) => setSubService(e.target.value)}
                />
              </div>
            </div>

            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='min-w-10px'></th>
                  <th className='min-w-10px'>Main Service</th>
                  <th className='min-w-10px'>Sub Service</th>
                  <th className=' min-w-10px'>Notes</th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {propertyList?.map((item: any, index: any) => {
                  return (
                    <tr>
                      <td>
                        <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                          <input
                            className='form-check-input'
                            type='radio'
                            value=''
                            checked={checked === index}
                            id='flexCheckDefault'
                            style={{cursor: 'pointer'}}
                            onChange={() => handleSelectService(item, index)}
                          />
                        </div>
                      </td>
                      <td>{item?.name}</td>
                      <td>{item?.sub_service?.name}</td>
                      <td>{item?.sub_service?.note}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </Col>
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous disabled'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertiseServiceList
