import React from 'react'
import {Modal} from 'react-bootstrap'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  show: any
  handleClose: any
  vendorsList?: any
  vendorList: any
  vendorListArray: any
  handleListChnage: any
  getvendorList: any
  setRemoveList: any
  setCheckedState: any
}

const AddVendorListPopup = ({
  show,
  handleClose,
  vendorsList,
  vendorList,
  handleListChnage,
  setRemoveList,
  getvendorList,
  vendorListArray,
  setCheckedState,
}: ButtonProps) => {
  const handleChangeVendorlist = (i: any) => {
    if (vendorListArray.includes(i?._id)) {
      vendorListArray = vendorListArray.filter((item: any) => item !== i?._id)
      setRemoveList(vendorListArray)
      setCheckedState(new Array(vendorListArray?.length).fill(false))
    } else {
      vendorListArray.push(i?._id)
      setRemoveList(vendorListArray)
      setCheckedState(new Array(vendorListArray?.length).fill(false))
    }
  }

  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={() => {
        handleClose()
      }}
      backdrop={true}
      size='sm'
    >
      <div className='modal-header'>
        {vendorsList === 'Vendor List' ? <h2>Vendor Lists</h2> : <h2>Vendors</h2>}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1 text-black' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <div className='d-flex justify-content-center mb-5'>
          <div
            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
            onClick={() => handleClose()}
          >
            Add
          </div>
        </div>

        <div className='d-flex align-items-center mb-5'>
          <input
            type='text'
            className='form-control w-auto'
            placeholder='Search Vendor'
            name='searchVendor'
            onChange={handleListChnage}
          />
          <div className='menu-item px-3'>
            <div
              onClick={() => getvendorList()}
              className='btn btn-sm fw-bold btn-primary btn-green hover-none'
            >
              Search
            </div>
          </div>
        </div>

        <div>
          {vendorList?.map((i: any) => {
            return (
              <div className='d-flex align-items-center justify-content-start gap-2 mb-10 gap-lg-3'>
                <div className='form-check form-check-sm form-check-custom form-check-solid me-3'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    id='flexCheckDefault'
                    style={{cursor: 'pointer'}}
                    onChange={() => handleChangeVendorlist(i)}
                  />
                </div>
                <h6>
                  {i?.firstName}
                  {i.lastName}
                </h6>
              </div>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

export default AddVendorListPopup
