import { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import backArrow from '../../../../../../../../../img/back-arrow.png'
import redCross from '../../../../../../../../../img/remove.png'
import InputNotRequired from '../../../../../../../General/InputNotRequired'
import { useForm, FormProvider } from 'react-hook-form'
import Switch from 'react-switch'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import SingleCharge from './FixedCharge'
import RecurringCharge from './PercantageCharge'
import FixedBasedBookingFeePreview from './FixedBasedBookingFeePreview'
import PercentageBasedBookingFeePreview from './PercentageBasedBookingFeePreview'
import PropertySettings from '../components/PropertySettings'
import { ApiGet, ApiPost, ApiPut } from '../../../../../../../../../apiCommon/helpers/API/ApiData'
import { ErrorToast, SuccessToast } from '../../../../../../../../../apiCommon/helpers/Toast'
import ConfirmationModal from '../components/ConfirmationModal'
import {
  customFrequencyOptions,
  dueDateOptions,
  installmentOptions,
  startApplyingOptions,
  frequencyOptions,
  applicableEndOptions,
  dueDateRentOptions
} from '../components/Options'
import { CircularProgress } from '@mui/material'

const AddBookingFeeConfiguration = () => {
  const navigate = useNavigate()
  const { type, id, flg, configId } = useParams()
  const methods = useForm<any>({ mode: 'onSubmit', reValidateMode: 'onChange' })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
  } = methods

  const [chargeData, setChargeData] = useState<any>({
    name: '',
    type: null,
    amountCalculationType: null,
    activityStatus: 1,
    appliesToAllUnits: null,
    feePercentage: null,
    VATPercentage: null,
    minimumAmount: null,
    invoicingType: 0,
    leaseFeeListId: id,
    selectChargeLeaseFeeListId: [],
    selectLeaseChargeType: [],
    frequency: [],
    dueDateType: [],
    creditOptionType: [],
    autoInvoicingEnabled: [{ label: 'Enabled', value: true }],
    startApplying: [],
    startApplyPercentagePaymentReceived: null,
    startApplySelectChargeLeaseFeeListId: [],
    startApplySelectChargeLeaseFeeListType: [],
    endApplying: [],
    endApplyPercentagePaymentReceived: null,
    endApplySelectChargeLeaseFeeListId: [],
    endApplySelectChargeLeaseFeeListType: [],
    daysAfterChargeIsApplied: null,
    splitIntoInstallments: [],
    customTimesApplied: null,
    customFrequencyType: [],
    developmentConfiguration: [],
    fixedAmount: null,
    previewData: {
      // s
      chargeSeries: [],
      totalRentValue: null,
      startApplingReceivedDate: null,

      //c
      totalValue: null,
      startDate: null,
      endDate: null,
      feeAmount: null,
      VATRate: null,
      dailyRate: null,
      noOfInstallments: null,
      singleRentInstallment: null,

      //w
      totalAmountWithVAT: null,
      totalApplied: null,
      subTotal: null,
      totalVAT: null,
      totalAmount: null,
      startDateReceived: null,
      endDateReceived: null,
      dateApplied: null,
      dateStopped: null,
      developmentConfiguration: [],
      VATAmountForFixes: null
    },
  })

  function booleanToInt(value: any) {
    return value ? 1 : 0
  }

  const [isChargeDataChanged, setIsChargeDataChanged] = useState<any>(false)
  const [disableAddCTA, setDisableAddCTA] = useState<any>(true)
  const [askForConfirmation, setAskForConfirmation] = useState<any>(false)
  const [isSkeleton, setIsSkeleton] = useState<any>(false)

  const addConfiguration = () => {
    setDisableAddCTA(true)
    let startApplyCharge: any = []
    let endApplyCharge: any = []


    chargeData?.startApplySelectChargeLeaseFeeListType?.map((chg: any) => {
      startApplyCharge?.push(chg?.value)
    })


    chargeData?.endApplySelectChargeLeaseFeeListType?.map((chg: any) => {
      endApplyCharge?.push(chg?.value)
    })

    let devConfigs: any = []

    if (!chargeData?.appliesToAllUnits) {
      chargeData?.developmentConfiguration?.map((dev: any) => {
        devConfigs?.push({
          buildingId: dev?.isBuilding
            ? dev?.id
            : dev?.isCommunity && dev?.buildingIds != null
              ? dev?.buildingIds?.[0]
              : null,
          communityId: dev?.isCommunity ? dev?.id : null,
          isBuilding: dev?.isBuilding,
          isCommunity: dev?.isCommunity,
          unitIds: dev?.selectedUnits,
          furnishingTypes: dev?.furnishingTypes.map((fr: any) => fr?.value),
          unitTypes: dev?.unitTypes.map((unt: any) => unt?.value),
          bedrooms: dev?.bedrooms.map((bd: any) => bd?.value),
          clusterId: dev?.clusterIds != null ? dev?.clusterIds?.[0] : undefined,
          unitGroupId: dev?.unitGroupIds != null ? dev?.unitGroupIds?.[0] : undefined,
        })
      })
    }

    let body: any = {
      id: flg == '2' ? configId : undefined,
      name: chargeData?.name,
      type: chargeData?.type,
      amountCalculationType: chargeData?.amountCalculationType,
      feePercentage: chargeData?.amountCalculationType == 1 ? Number(chargeData?.feePercentage) : null,
      VATPercentage: Number(chargeData?.VATPercentage),
      minimumAmount: chargeData?.amountCalculationType == 1 ? Number(chargeData?.minimumAmount) : null,
      creditType: chargeData?.creditOptionType?.[0]?.value, // Get number value from selected option
      dueDateType: chargeData?.dueDateType?.length > 0 ? chargeData?.dueDateType?.[0]?.value : null,
      daysAfterChargeIsApplied: chargeData?.dueDateType?.[0]?.value == 1 ? Number(chargeData?.daysAfterChargeIsApplied) : null,
      autoInvoicingEnabled: chargeData?.autoInvoicingEnabled?.[0]?.value,
      invoicingType: chargeData?.invoicingType,
      appliesToAllUnits: chargeData?.appliesToAllUnits,
      activityStatus: chargeData?.activityStatus,
      leaseFeeListId: id,
      selectLeaseChargeType:
        chargeData?.selectLeaseChargeType?.length > 0
          ? chargeData?.selectLeaseChargeType?.[0]?.value
          : null,
      startApplying:
        chargeData?.startApplying?.length > 0 ? chargeData?.startApplying?.[0]?.value : null,
      startApplySelectChargeLeaseFeeListType: (chargeData?.startApplying?.[0]?.value == 1 && chargeData?.startApplySelectChargeLeaseFeeListType?.length > 0) ? chargeData?.startApplySelectChargeLeaseFeeListType?.[0]?.value : null,
      startApplyPercentagePaymentReceived: chargeData?.startApplying?.[0]?.value == 1 ? Number(chargeData?.startApplyPercentagePaymentReceived) : null,

      endApplying: chargeData?.endApplying?.length > 0 ? chargeData?.endApplying?.[0]?.value : null,
      endApplySelectChargeLeaseFeeListType: (chargeData?.endApplying?.[0]?.value == 1 && chargeData?.endApplySelectChargeLeaseFeeListType?.length > 0) ? chargeData?.endApplySelectChargeLeaseFeeListType?.[0]?.value : null,
      endApplyPercentagePaymentReceived: chargeData?.endApplying?.[0]?.value == 1 ? Number(chargeData?.endApplyPercentagePaymentReceived) : null,

      frontendStorage: chargeData?.previewData,
      developmentConfigurations: devConfigs,
      fixedAmount: chargeData?.amountCalculationType == 0 ? Number(chargeData?.fixedAmount) : null
    }

    if (chargeData?.type == 0) {
      body = {
        ...body,
        splitIntoInstallments:
          chargeData?.splitIntoInstallments?.length > 0
            ? chargeData?.splitIntoInstallments?.[0]?.value
            : null,
        customTimesApplied: chargeData?.customTimesApplied
          ? Number(chargeData?.customTimesApplied)
          : null,
        customFrequencyType: chargeData?.customFrequencyType
          ? chargeData?.customFrequencyType?.[0]?.value
          : null,
        frequency: null,
      }
    } else {
      body = {
        // fixedAmount : 50.0,
        ...body,
        splitIntoInstallments: null,
        frequency: chargeData?.frequency?.length > 0 ? chargeData?.frequency?.[0]?.value : null,
        customFrequencyType: chargeData?.frequency?.[0]?.value == 4 && chargeData?.customFrequencyType?.length > 0
          ? chargeData?.customFrequencyType?.[0]?.value
          : null,
        customTimesApplied: chargeData?.frequency?.[0]?.value == 4 && chargeData?.customTimesApplied
          ? Number(chargeData?.customTimesApplied)
          : null,
      }
    }

    console.log(body)

    if (configId) {
      ApiPut(`corporate/lease_fee_configuration`, body)
        .then(() => {
          SuccessToast('Charge has been updated successfully.')
          navigate(`/settings/residential-leasing/annual-leases/${type}/${id}`)
        })
        .catch((err) => {
          ErrorToast(err.message)
          if (err?.message === 'Your account has been suspended!') {
            window.location.pathname = '/auth'
            localStorage.clear()
          }
          setDisableAddCTA(false)
        })
    } else {
      ApiPost(`corporate/lease_fee_configuration`, body)
        .then(() => {
          SuccessToast('Charge has been added successfully.')
          navigate(`/settings/residential-leasing/annual-leases/${type}/${id}`)
        })
        .catch((err) => {
          ErrorToast(err.message)
          if (err?.message === 'Your account has been suspended!') {
            window.location.pathname = '/auth'
            localStorage.clear()
          }
          setDisableAddCTA(false)
        })
    }
  }

  useEffect(() => {
    // Fixed Fee validation
    console.log(chargeData?.appliesToAllUnits >= 0)
    if (chargeData?.amountCalculationType === 0) {
      const isValid =
        chargeData?.name?.trim()?.length > 0 &&
        chargeData?.amountCalculationType != null &&
        chargeData?.fixedAmount != null &&
        chargeData?.VATPercentage != null &&
        chargeData?.autoInvoicingEnabled?.length > 0 &&
        chargeData?.creditOptionType?.length > 0 &&
        (chargeData?.appliesToAllUnits !== null && chargeData?.appliesToAllUnits >= 0); setDisableAddCTA(!isValid);
    }
    // Percentage Fee validation
    else if (chargeData?.amountCalculationType === 1) {
      const isValid =
        chargeData?.name?.trim()?.length > 0 &&
        chargeData?.amountCalculationType === 1 &&
        chargeData?.feePercentage != null &&
        chargeData?.selectLeaseChargeType?.length > 0 &&
        chargeData?.VATPercentage != null &&
        chargeData?.minimumAmount != null &&
        chargeData?.autoInvoicingEnabled?.length > 0 &&
        chargeData?.creditOptionType?.length > 0 &&
        (chargeData?.appliesToAllUnits !== null && chargeData?.appliesToAllUnits >= 0);
      setDisableAddCTA(!isValid);
    }
  }, [chargeData]);

  const getConfigurationById = () => {
    setIsSkeleton(true)
    ApiGet(`corporate/lease_fee_configuration/${configId}`)
      .then((res: any) => {

        const dueDateData = res?.data?.data?.dueDateType == 2
          ? dueDateRentOptions?.filter(
            (option: any) => option.value == res?.data?.data?.dueDateType)
          : dueDateOptions?.filter(
            (option: any) => option.value == res?.data?.data?.dueDateType
          )
        const installmentData = installmentOptions?.filter(
          (option: any) => option.value == res?.data?.data?.splitIntoInstallments
        )
        const customFrequencyData = customFrequencyOptions?.filter(
          (option: any) => option.value == res?.data?.data?.customFrequencyType
        )
        const startApplyingData = startApplyingOptions?.filter(
          (option: any) => option.value == res?.data?.data?.startApplying
        )
        const stopApplyingData = applicableEndOptions?.filter(
          (option: any) => option.value == res?.data?.data?.startApplying
        )
        const frequencyData = frequencyOptions?.filter(
          (option: any) => option.value == res?.data?.data?.frequency
        )

        setValue('templateName', res?.data?.data?.name)
        setValue('feePercentage', res?.data?.data?.feePercentage)
        setValue('selectCharge', res?.data?.data?.selectLeaseChargeType)
        setValue('VATPercentage', res?.data?.data?.VATPercentage)
        setValue('minimumAmount', res?.data?.data?.minimumAmount)
        setValue('splitIntoInstallments', installmentData)
        setValue('dueDate', dueDateData)
        setValue('daysAfterChargeIsApplied', res?.data?.data?.daysAfterChargeIsApplied)
        setValue('installmentFrequency', res?.data?.data?.customTimesApplied)
        setValue('customFrequencyType', customFrequencyData)
        setValue('startApplying', startApplyingData)
        setValue(
          'startApplyPercentagePaymentReceived',
          res?.data?.data?.startApplyPercentagePaymentReceived
        )
        setValue('startApplyingSelectCharge', res?.data?.data?.startApplySelectChargeLeaseFeeType + '123')
        setValue('invoicingType', res?.data?.data?.invoicingType)

        setValue('frequency', frequencyData)
        setValue('stopApplying', stopApplyingData)
        setValue('credit', res?.data?.data?.creditType)

        let chrgDataTemp: any = {}
        chrgDataTemp = {
          name: res?.data?.data?.name,
          type: res?.data?.data?.type,
          amountCalculationType: res?.data?.data?.amountCalculationType,
          activityStatus: res?.data?.data?.activityStatus,
          appliesToAllUnits: res?.data?.data?.appliesToAllUnits,
          feePercentage: res?.data?.data?.feePercentage,
          VATPercentage: res?.data?.data?.VATPercentage,
          minimumAmount: res?.data?.data?.minimumAmount,
          invoicingType: res?.data?.data?.invoicingType,
          leaseFeeListId: res?.data?.data?.leaseFeeListId,
          creditOptionType: res?.data?.data?.creditType === 0
            ? [{ label: 'Security Deposit', value: 0 }]
            : [{ label: 'Rent Charges', value: 1 }],
          selectChargeLeaseFeeListId: res?.data?.data?.selectChargeLeaseFeeListData?.map(
            (feeListItem: any) => ({
              value: feeListItem?._id,
              label: feeListItem?.name,
              type:
                feeListItem?.name?.toLowerCase() === 'total rent value'
                  ? 0
                  : feeListItem?.name?.toLowerCase() === 'single rent installment'
                    ? 1
                    : 2,
            })
          ),
          selectLeaseChargeType:
            res?.data?.data?.selectLeaseChargeType == 0
              ? [{ label: 'Total Rent Value', value: 0 }]
              : res?.data?.data?.selectLeaseChargeType == 1
                ? [{ label: 'Single Rent Installment', value: 1 }]
                : [],
          frequency: frequencyOptions?.filter(
            (option: any) => option.value == res?.data?.data?.frequency
          ),
          dueDateType: res?.data?.data?.dueDateType == 2
            ? dueDateRentOptions?.filter(
              (option: any) => option.value == res?.data?.data?.dueDateType)
            : dueDateOptions?.filter(
              (option: any) => option.value == res?.data?.data?.dueDateType
            ),
          autoInvoicingEnabled:
            res?.data?.data?.autoInvoicingEnabled === true
              ? [{ label: 'Enabled', value: true }]
              : [{ label: 'Disabled', value: false }],
          startApplying: startApplyingOptions?.filter(
            (option: any) => option.value == res?.data?.data?.startApplying
          ),
          startApplyPercentagePaymentReceived: res?.data?.data?.startApplyPercentagePaymentReceived,
          startApplySelectChargeLeaseFeeListId:
            res?.data?.data?.startApplySelectChargeLeaseFeeListData.map((feeListItem: any) => ({
              value: feeListItem?._id,
              label: feeListItem?.name,
            })),
          startApplySelectChargeLeaseFeeListType:
            res?.data?.data?.startApplySelectChargeLeaseFeeListType == 0
              ? [{ label: 'Total Rent Value', value: 0 }]
              : res?.data?.data?.startApplySelectChargeLeaseFeeListType == 1
                ? [{ label: 'Single Rent Installment', value: 1 }]
                : [],
          endApplying: applicableEndOptions?.filter(
            (option: any) => option.value == res?.data?.data?.endApplying
          ),
          endApplyPercentagePaymentReceived: res?.data?.data?.endApplyPercentagePaymentReceived,
          endApplySelectChargeLeaseFeeListId:
            res?.data?.data?.endApplySelectChargeLeaseFeeListData.map((feeListItem: any) => ({
              value: feeListItem?._id,
              label: feeListItem?.name,
            })),
          endApplySelectChargeLeaseFeeListType:
            res?.data?.data?.endApplySelectChargeLeaseFeeListType == 0
              ? [{ label: 'Total Rent Value', value: 0 }]
              : res?.data?.data?.endApplySelectChargeLeaseFeeListType == 1
                ? [{ label: 'Single Rent Installment', value: 1 }]
                : [],
          daysAfterChargeIsApplied: res?.data?.data?.daysAfterChargeIsApplied,
          splitIntoInstallments: installmentOptions?.filter(
            (option: any) => option.value == res?.data?.data?.splitIntoInstallments
          ),
          customTimesApplied: res?.data?.data?.customTimesApplied,
          customFrequencyType: customFrequencyOptions?.filter(
            (option: any) => option.value == res?.data?.data?.customFrequencyType
          ),
          developmentConfiguration: res?.data?.data?.frontendStorage?.developmentConfiguration,
          previewData: res?.data?.data?.frontendStorage,
          fixedAmount: res?.data?.data?.fixedAmount ? res?.data?.data?.fixedAmount : null
        }

        chrgDataTemp.previewData.totalValue = null
        chrgDataTemp.previewData.totalRentValue = null
        chrgDataTemp.previewData.noOfInstallments = null
        chrgDataTemp.previewData.singleRentInstallment = null

        console.log(chrgDataTemp)

        setChargeData(chrgDataTemp)
        setIsSkeleton(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsSkeleton(false)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    if (flg == '2') getConfigurationById()
  }, [])

  return (
    <>
      {!isSkeleton ? (
        <div className='pt-0 mt-0 px-2 ps-1'>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit((data: any, e: any) => {
                addConfiguration()
              })}
            >
              <div className='d-flex align-items-end gap-2 mb-3 gap-lg-3'>
                <div className='d-flex align-items-start gap-3'>
                  <span
                    className='rounded-circle'
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      navigate(`/settings/residential-leasing/annual-leases/${type}/${id}`)
                    }
                  >
                    <img src={backArrow} height={14} width={14} alt='Back' />
                  </span>
                  <div>
                    <h2 className='page-heading m-0 head-text mb-1'>
                      Charge Configuration Settings
                    </h2>
                    <p className='labl-gry fs-4 mb-0 light-dark-theme-color'>
                      Configure your charge settings
                    </p>
                  </div>
                </div>

                <button
                  type='button'
                  className='btn btn-sm fw-bold  me-3  red-hollow-btn ms-auto me-3'
                  onClick={() => {
                    navigate(`/settings/residential-leasing/annual-leases/${type}/${id}`)
                  }}
                >
                  <img src={redCross} height={16} width={16} style={{ marginRight: '10px' }} /> Cancel
                </button>

                <button
                  type='button'
                  className='btn btn-sm fw-bold green-submit-btn px-7 justify-content-center btn-fit-content'
                  disabled={disableAddCTA}
                  onClick={async () => {
                    // console.log(errors)
                    let isValid: any = true
                    isValid = await trigger()
                    console.log(isValid)
                    // console.log(isValid)
                    if (isValid) {
                      if (
                        chargeData?.appliesToAllUnits == false &&
                        chargeData?.developmentConfiguration?.length == 0
                      )
                        ErrorToast(
                          "Since you have chosen to apply this configuration to 'Selected Properties', please select at least one propert."
                        )
                      else if (
                        chargeData?.startApplying?.[0]?.value == 1 &&
                        chargeData?.endApplying?.[0]?.value == 1 &&
                        (chargeData?.endApplyPercentagePaymentReceived <=
                          chargeData?.startApplyPercentagePaymentReceived ||
                          chargeData?.startApplyPercentagePaymentReceived >=
                          chargeData?.endApplyPercentagePaymentReceived)
                      )
                        ErrorToast(chargeData?.previewData.percentError)
                      else setAskForConfirmation(true)
                    }
                  }}
                >
                  {flg == '1' ? 'Add ' : 'Update '} Configuration
                </button>
              </div>

              <Row className='mb-4 d-flex'>
                <Col md={9} style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3 pb-10'>
                    <div className='card-body pt-0 mt-5 py-0 px-3'>
                      <div className='d-flex'>
                        <div>
                          <h3 className='page-heading m-0 white-dark-theme-color fnt-700'>
                            Charge Settings
                          </h3>
                          <p className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>
                            Set your charge structure
                          </p>
                        </div>

                        <div className='d-flex ms-auto align-items-center'>
                          <p className='mb-0 me-5'>
                            <span>
                              <OverlayTrigger
                                placement='top'
                                delay={{ show: 250, hide: 400 }}
                                flip
                                overlay={
                                  <Tooltip id='button-tooltip-3' className='custom-tooltip'>
                                    <p className='mb-2'>Charge Settings</p>
                                  </Tooltip>
                                }
                              >
                                <span>
                                  {' '}
                                  <IoMdInformationCircleOutline
                                    className='ms-0 cursor-pointer'
                                    style={{ height: '18px', width: '18px' }}
                                  />
                                </span>
                              </OverlayTrigger>
                            </span>
                          </p>

                          <Switch
                            onChange={(val: any) => {
                              console.log(val)
                              setChargeData({ ...chargeData, ['activityStatus']: booleanToInt(val) })
                            }}
                            checked={chargeData?.activityStatus === 1}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor='#00cf99'
                            offColor='#b0bec5'
                            className='ms-auto me-5'
                            height={23}
                            width={42}
                          />
                          <p className='fs-5 fnt-500 pt-2 head-text mb-1 light-dark-theme-color'>
                            <i>{chargeData?.activityStatus === 1 ? 'Active' : 'Inactive'}</i>
                          </p>
                        </div>
                      </div>

                      <div className='row me-4 ms-0 mt-5'>
                        <div className='col-10 ps-0'>
                          <p className='fs-4 fnt-700 required'> Name</p>
                          <div className='d-flex'>
                            <p className='fs-4 fnt-500 mb-0 mt-2'>
                              {' '}
                              {type == 'contract-fee'
                                ? 'Contract Fee - '
                                : type == 'admin-fee'
                                  ? 'Admin Fee - '
                                  : type == 'management-fee'
                                    ? 'Management Fee - '
                                    : type == 'maintenance-fee'
                                      ? 'Maintenance Fee - '
                                      : type == 'housekeeping-fee'
                                        ? 'Housekeeping Fee - '
                                        : type == 'booking-fee'
                                          ? 'Booking Fee - '
                                          : `${type} - `}{' '}
                            </p>
                            <div className='ms-3' style={{ width: '60%' }}>
                              <InputNotRequired
                                required={true}
                                register={register}
                                errors={errors}
                                value={chargeData?.name}
                                setValue={setValue}
                                trigger={trigger}
                                onBlur={() => { }}
                                onChange={(val: any) => {
                                  setChargeData({ ...chargeData, ['name']: val })
                                }} // Pass the callback function to handle input changes
                                placeholder='Enter a sub-name for this charge…'
                                name='templateName'
                                pattern={/^[A-Za-z0-9._ \-]*$/} // Pattern to allow letters, numbers, periods, underscores, hyphens, and spaces
                                maxLength={50} // Maximum length of 50 characters
                                className=''
                              />
                            </div>

                            {/* <h3 className='text-danger mb-0 ms-2'>
                        <b>*</b>
                      </h3> */}
                          </div>
                        </div>
                      </div>



                      <div className='position-relative'>
                        <>
                          {flg == '2' && (
                            <div
                              className='tenantOpacityBox'
                              style={{ opacity: '0.7', cursor: 'not-allowed' }}
                            ></div>
                          )}
                          <>
                            <div className='row mt-7'>
                              <div className='col-12'>
                                <h4 className='page-heading m-0 white-dark-theme-color fnt-700 required'>
                                  Amount Calculation
                                </h4>
                                <p className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>
                                  Select the relevant calculation method that will be used to calculate
                                  the charge amount:
                                </p>
                              </div>
                            </div>

                            <div className='row mt-5'>
                              <div className='col-6'>
                                <div className='d-flex align-items-start tst mb-4'>
                                  <div style={{ width: '30px', height: '30px' }}>
                                    <input
                                      type='radio'
                                      className='me-3 cursor-pointer form-check-input'
                                      {...register('amountCalculationType', {
                                        required: 'Please select an option to proceed',
                                      })}
                                      id='fixedAmount'
                                      value='fixedAmount'
                                      checked={chargeData?.amountCalculationType == 0}
                                      onChange={(e: any) => {
                                        setChargeData({ ...chargeData, ['amountCalculationType']: 0 })
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <h5 className='m-0'>
                                      <label htmlFor='fixedAmount' className='fs-5 cursor-pointer'>
                                        Fixed Fee
                                      </label>
                                    </h5>
                                    <p
                                      className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'
                                      style={{ color: '#a4a4a4' }}
                                    >
                                      This charge will be generated and calculated based on a defined
                                      fixed fee.
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className='col-6'>
                                <div className='d-flex align-items-start tst mb-4'>
                                  <div style={{ width: '30px', height: '30px' }}>
                                    <input
                                      type='radio'
                                      className='me-3 cursor-pointer form-check-input'
                                      {...register('amountCalculationType', {
                                        required: 'Please select an option to proceed',
                                      })}
                                      id='percentAmount'
                                      value='percentAmount'
                                      checked={chargeData?.amountCalculationType == 1}
                                      onChange={(e: any) => {
                                        setChargeData({ ...chargeData, ['amountCalculationType']: 1 })
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <h5 className='m-0'>
                                      <label htmlFor='percentAmount' className='fs-5 cursor-pointer'>
                                        % from the charges applied in a lease
                                      </label>
                                    </h5>
                                    <p
                                      className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'
                                      style={{ color: '#a4a4a4' }}
                                    >
                                      The charge will be generated and calculated based on selected
                                      charges that are included in a lease. When applying this method, you
                                      must also select the charges it will be calculated from.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        </>
                      </div>
                    </div>
                  </div>

                  {/* Fee Setting */}
                  {chargeData?.amountCalculationType === 0 ? (
                    <SingleCharge
                      chargeData={chargeData}
                      onUpdateChargeData={(updatedCharge: any) => {
                        setChargeData(updatedCharge)
                      }}
                    />
                  ) : (
                    <RecurringCharge
                      chrgData={chargeData}
                      setChrgData={(updatedCharge: any) => {
                        setChargeData(updatedCharge)
                        setIsChargeDataChanged(new Date())
                      }}
                    />
                  )}
                </Col>


                {/* Charges Preview */}
                <Col md={3} style={{ display: 'flex', flexDirection: 'column' }}>
                  {chargeData?.amountCalculationType == 0 ? (
                    <FixedBasedBookingFeePreview
                      chrgData={chargeData}
                      setChrgData={(updatedCharge: any) => {
                        setChargeData(updatedCharge)
                      }}
                    />
                  ) : (
                    <PercentageBasedBookingFeePreview
                      chrgData={chargeData}
                      setChrgData={(updatedCharge: any) => {
                        setChargeData(updatedCharge)
                      }}
                      isChrgDataChanged={isChargeDataChanged}
                    />
                  )}
                </Col>
              </Row>
            </form>
          </FormProvider>

          {/* Property Settings */}
          <PropertySettings
            chrgData={chargeData}
            setChrgData={(val: any) => {
              setChargeData({ ...chargeData, ['appliesToAllUnits']: val })
            }}
            setDevelopmentConfiguration={(val: any) => {
              let values = { ...chargeData }
              values.developmentConfiguration = val
              values.previewData.developmentConfiguration = val
              setChargeData(values)
            }}
          />

          {/* Confirmation Modal */}
          <ConfirmationModal
            show={askForConfirmation}
            onHide={() => {
              setAskForConfirmation(false)
            }}
            status={chargeData?.activityStatus}
            onAdd={addConfiguration}
            isDisabled={disableAddCTA}
          />
        </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{ height: '75vh' }}
        >
          <CircularProgress style={{ color: '#007a59' }} />
        </div>
      )}
    </>
  )
}

export default AddBookingFeeConfiguration
