import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import {SuccessToast, ErrorToast} from '../../../../apiCommon/helpers/Toast'
import editIcon from '../../../../img/pencil-white.png'
import unitNoIcon from '../../../../img/unitNoIcon.png'
import bedroomsIcon from '../../../../img/bedroomsIcon.png'
import bathroomsIcon from '../../../../img/bathroomsIcon.png'
import sizeIcon from '../../../../img/sizeIcon.png'
import floorsBlue from '../../../../img/floorsBlue.png'
import appartment from '../../../../img/Apartment.svg'
import townhouseIcon from '../../../../img/TownHouse.svg'
import villaIcon from '../../../../img/Villa.svg'
import villa from '../../../img/Villa.svg'
import other from '../../../../img/other.svg'
import buildings from '../../../img/buildings.svg'
import common_area from '../../../../img/common_area.svg'
import penthouse from '../../../../img/PentHouse.svg'
import commercialFloorGreenIcon from '../../../../img/commercialFloorGreenIcon.png'
import residentialGreenIcon from '../../../../img/residentialGreenIcon.png'
import LeasesOfProperty from './LeasesOfProperty'
import backArrow from '../../../../img/back-arrow.png'
import {Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import OwnerListModel from './OwnerListModel'
import {CircularProgress} from '@mui/material'
import { formatAmount } from '../../../../Utilities/utils'

const PropertyPrpfile = () => {
  const {flg, type, id, tenancyId, unitId} = useParams()

  const navigate = useNavigate()
  const location: any = useLocation()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  const [propertyData, setPropertyData] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getPropertyById = async () => {
    setIsLoading(true)
    await ApiGet(`corporate/unit/${id}`)
      .then((res: any) => {
        setPropertyData(res?.data?.data)
        setIsLoading(false)
      })
      .catch((err) => {
        ErrorToast(err.message)
        setIsLoading(false)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    getPropertyById()
  }, [])

  const handleImageClickCheques = () => {
    const groupName = `group_attachments_others`

    // chequeImages?.map((receipt: any, idx: any) => {
    //   const imgeNext = document.getElementById(`clickedAttachments${idx}`)
    //   if (imgeNext) {
    //     imgeNext.setAttribute('data-src', `${Bucket}${receipt?.URL}`)
    //     imgeNext.setAttribute('data-fancybox', groupName)

    //     // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
    //   }

    //   // Trigger the fancybox on the clicked image
    //   // if (imgeNext) {
    //   //   imgeNext.click()
    //   // }
    // })
  }

  const [selectedImage, setSelectedImage] = useState<any>('')

  useEffect(() => {
    setSelectedImage(propertyData?.images?.[0])
  }, [propertyData])

  const [ownerListeModal, setOwnerListModal] = useState<any>(false)
  const [ownerListData, setOwnerListData] = useState<any>([])
  const handleOwnerModel = (listItem: any) => {
    if (listItem?.ownerData?.length > 0) {
      setOwnerListData(listItem?.ownerData)
      setOwnerListModal(true)
    }
  }

  const goToEdit = () => {
    if (location?.state?.from === 'buildingDetail') {
      navigate(`/properties/building-details/${location?.state?.buildingId}/edit-property/${id}`, {
        state: {
          area: location?.state?.area,
          city: location?.state?.city,
          buildingId: location?.state?.buildingId,
          buildingName: location?.state?.buildingName,
          from: 'propertyProfile',
        },
      })
    } else if (location?.state?.from === 'buildingDetailFloor') {
      navigate(`/properties/building-details/${location?.state?.buildingId}/floors/${location?.state?.floorId}/edit-property/${id}`, {
        state: {
          from: 'propertyProfile',
          floorName: location?.state.floorName,
          buildingId: location?.state?.buildingId,
          buildingName: location?.state?.buildingName,
          floorId: location?.state?.floorId,
          area: location?.state?.area,
          city: location?.state?.city,
        },
      })
    } else if (location?.state?.from === 'communityDetail') {
      navigate(`/properties/community-details/${id}/edit-property/${id}`, {
        state: {
          from: 'propertyProfile',
          communityName: location?.state?.communityName,
          communityId: id,
          area: location?.state.area,
          city: location?.state.city,
          street: location?.state.street,
        },
      })
    } else if (location?.state?.from === 'unitCluster') {
      navigate(`/properties/community-details/${location?.state?.communityId}/unit-cluster/${location?.state?.clusterId}/edit-property/${id}`, {
        state: {
          from: 'propertyProfile',
          type: location?.state?.type,
          clusterName: location?.state?.clusterName,
          communityId: location?.state?.communityId,
          clusterId: location?.state?.clusterId,
          communityName: location?.state?.communityName,
          area: location?.state?.area,
          city: location?.state?.city,
          street: location?.state?.street,
        },
      })
    } else if (location?.state?.from === 'buildingCluster') {
      navigate(`/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}/edit-property/${id}`, {
        state: {
          from: 'propertyProfile',
          type: location?.state?.type,
          clusterName: location?.state?.clusterName,
          communityId: location?.state?.communityId,
          clusterId: location?.state?.clusterId,
          communityName: location?.state?.communityName,
          area: location?.state?.area,
          city: location?.state?.city,
          street: location?.state?.street,
        },
      })
    } else if (location?.state?.from === 'mixedCluster') {
      navigate(`/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/edit-property/${id}`, {
        state: {
          from: 'propertyProfile',
          type: location?.state?.type,
          clusterName: location?.state?.clusterName,
          communityId: location?.state?.communityId,
          clusterId: location?.state?.clusterId,
          communityName: location?.state?.communityName,
          area: location?.state?.area,
          city: location?.state?.city,
          street: location?.state?.street,
        },
      })
    } else if (location?.state?.from === 'buildingClusterBuilding') {
      navigate(
        `/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}/building/${location?.state?.buildingId}/edit-property/${id}`,
        {
          state: {
            from: 'propertyProfile',
            type: location?.state?.type,
            clusterName: location?.state?.clusterName,
            communityId: location?.state?.communityId,
            clusterId: location?.state?.clusterId,
            communityName: location?.state?.communityName,
            buildingName: location?.state.buildingName,
            buildingId: location?.state.buildingId,
            area: location?.state?.area,
            city: location?.state?.city,
            street: location?.state?.street,
          },
        }
      )
    } else if (location?.state?.from === 'buildingClusterBuildingFloor') {
      navigate(
        `/properties/community-details/${location?.state?.communityId}/building-cluster/${location?.state?.clusterId}/building/${location?.state?.buildingId}/floor/${location?.state?.floorId}/edit-property/${id}`,
        {
          state: {
            from: 'propertyProfile',
            type: location?.state?.type,
            clusterName: location?.state?.clusterName,
            communityId: location?.state?.communityId,
            clusterId: location?.state?.clusterId,
            communityName: location?.state?.communityName,
            buildingName: location?.state.buildingName,
            buildingId: location?.state.buildingId,
            floorName: location?.state.floorName,
            floorId: location?.state.floorId,
            area: location?.state?.area,
            city: location?.state?.city,
            street: location?.state?.street,
          },
        }
      )
    } else if (location?.state?.from === 'buildingGroupBuilding') {
      navigate(
        `/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${location?.state?.groupId}/edit-property/${id}`,
        {
          state: {
            from: 'propertyProfile',
            type: location?.state?.type,
            clusterName: location?.state?.clusterName,
            communityId: location?.state?.communityId,
            clusterId: location?.state?.clusterId,
            communityName: location?.state?.communityName,
            groupName: location?.state?.groupName,
            groupId: location?.state?.groupId,
            groupType: location?.state?.groupType,
            area: location?.state?.area,
            city: location?.state?.city,
            street: location?.state?.street,
          },
        }
      )
    } else if (location?.state?.from === 'buildingGroupBuildingFloor') {
      navigate(
        `/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${location?.state?.groupId}/floor/${location?.state?.floorId}/edit-property/${id}`,
        {
          state: {
            from: 'propertyProfile',
            type: location?.state?.type,
            clusterName: location?.state?.clusterName,
            communityId: location?.state?.communityId,
            clusterId: location?.state?.clusterId,
            communityName: location?.state?.communityName,
            groupName: location?.state?.groupName,
            groupId: location?.state?.groupId,
            groupType: location?.state?.groupType,
            floorName: location?.state?.floorName,
            floorId: location?.state?.floorId,
            area: location?.state?.area,
            city: location?.state?.city,
          },
        }
      )
    } else if (location?.state?.from === 'unitGroup') {
      navigate(
        `/properties/community-details/${location?.state?.communityId}/mixed-cluster/${location?.state?.clusterId}/group/${location?.state?.groupId}/edit-property/${id}`,
        {
          state: {
            from: 'propertyProfile',
            type: location?.state?.type,
            clusterName: location?.state?.clusterName,
            communityId: location?.state?.communityId,
            clusterId: location?.state?.clusterId,
            communityName: location?.state?.communityName,
            groupName: location?.state?.groupName,
            groupId: location?.state?.groupId,
            groupType: location?.state?.groupType,
            area: location?.state?.area,
            city: location?.state?.city,
            street: location?.state?.street,
          },
        }
      )
    }else if(location?.state?.from === 'globalProperty'){
      navigate(`/properties/edit-property/${id}`,{
        state:{
          from:'propertyProfile',
          developmentName: location?.state?.developmentName,
           area:location?.state?.area,
         city:location?.state?.city 
        }
      })
    }
  }

  return (
    <>
      {!isLoading ? (
        <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
          <div id='' className='pt-0 mt-0'>
            {' '}
            {/* Details Card */}
            <div className='card card-flush py-5 mt-4 px-7'>
              {/*  */}
              <div className='d-flex align-items-center'>
                <span className='rounded-circle cursor-pointer' onClick={() => navigate(-1)}>
                  <img src={backArrow} height='12' width='12' className='me-3' />
                </span>

                <h3 className='top ps-0 mb-0 white-dark-theme-color'>
                  <b>
                    {propertyData?.unitNo}, {propertyData?.communityId ? propertyData?.community?.[0]?.name : propertyData?.building?.[0]?.name},{' '}
                    {propertyData?.communityId ? propertyData?.community?.[0]?.street : propertyData?.building?.[0]?.street}{' '}{propertyData?.communityId ? propertyData?.community?.[0]?.area : propertyData?.building?.[0]?.area}, {propertyData?.communityId ? propertyData?.community?.[0]?.city : propertyData?.building?.[0]?.city}
                  </b>
                </h3>

                <button type='button' className='btn btn-sm fw-bold px-3 green-submit-btn  ms-auto btn-fit-content' onClick={() => goToEdit()}>
                  <img src={editIcon} height={16} width={16} className='me-3' /> Edit Property Details
                </button>
              </div>

              {/*  */}
              <div className='d-flex align-items-center mt-7'>
                <div className='d-flex align-items-center me-3'>
                  <img src={unitNoIcon} height={22} width={22} className='me-3' />
                  <p className='m-0 me-3 fs-5 fnt-500 white-dark-theme-color'>{propertyData?.unitNo ? propertyData?.unitNo : '-'}</p>
                </div>

                <div className='d-flex align-items-center me-3'>
                  <img src={bedroomsIcon} height={20} width={20} className='me-3' />
                  <p className='m-0  me-3 fs-5 fnt-500 white-dark-theme-color'>{propertyData?.bedrooms ? propertyData?.bedrooms : '-'}</p>
                </div>

                <div className='d-flex align-items-center me-3'>
                  <img src={bathroomsIcon} height={20} width={20} className='me-3' />
                  <p className='m-0  me-3 fs-5 fnt-500 white-dark-theme-color'>{propertyData?.bathrooms ? propertyData?.bathrooms : '-'}</p>
                </div>

                <div className='d-flex align-items-center me-3'>
                  <img src={sizeIcon} height={20} width={20} className='me-3' />
                  <p className='m-0  me-3 fs-5 fnt-500 white-dark-theme-color'>
                    {propertyData?.size ? `${propertyData?.size}${propertyData?.sizeType == 0 ? 'Sq ft' : 'ft'}` : '-'}
                  </p>
                </div>

                {propertyData?.floorId && (
                  <div className='d-flex align-items-center me-3'>
                    <img src={floorsBlue} height={20} width={20} className='me-3' />
                    <p className='m-0  me-3 fs-5 fnt-500 white-dark-theme-color'>{propertyData?.floorId ? propertyData?.floor?.[0]?.name : '-'}</p>
                  </div>
                )}

                <div className='d-flex align-items-center me-3 ms-auto'>
                  <img
                    src={
                      propertyData?.unitType === 0
                        ? appartment
                        : propertyData?.unitType === 1
                        ? penthouse
                        : propertyData?.unitType === 2
                        ? common_area
                        : propertyData?.unitType === 3
                        ? townhouseIcon
                        : propertyData?.unitType === 4
                        ? villaIcon
                        : propertyData?.unitType === 5
                        ? other
                        : propertyData?.unitType === 6
                        ? other
                        : propertyData?.unitType === 7
                        ? other
                        : propertyData?.unitType === 8
                        ? other
                        : propertyData?.unitType === 9
                        ? other
                        : other
                    }
                    height={20}
                    width={20}
                    className='me-3'
                  />
                  <p className='m-0  me-3 fs-5 fnt-500 white-dark-theme-color'>
                    {propertyData?.unitType === 0
                      ? 'Apartment'
                      : propertyData?.unitType === 1
                      ? 'Penthouse'
                      : propertyData?.unitType === 2
                      ? 'Common Area'
                      : propertyData?.unitType === 3
                      ? 'Townhouse'
                      : propertyData?.unitType === 4
                      ? 'Villa'
                      : propertyData?.unitType === 5
                      ? `Other - ${propertyData?.otherName}`
                      : propertyData?.unitType === 6
                      ? 'Office'
                      : propertyData?.unitType === 7
                      ? 'Restaurant'
                      : propertyData?.unitType === 8
                      ? 'Cafe'
                      : propertyData?.unitType === 9
                      ? 'Retail'
                      : '-'}
                  </p>
                </div>

                <div className='d-flex align-items-center me-3'>
                  <img
                    src={propertyData?.propertyType == 0 ? residentialGreenIcon : commercialFloorGreenIcon}
                    height={22}
                    width={22}
                    className='me-3'
                  />
                  <p className='m-0 me-3 fs-5 fnt-500 white-dark-theme-color'>{propertyData?.propertyType == 0 ? 'Residential' : 'Commercial'}</p>
                </div>
              </div>

              {/*  */}
              <div className='row mt-7'>
                {/* Left column */}
                <div className='col-5'>
                  <div className='row'>
                    <div className='col-12 text-center'>
                      {propertyData?.images?.length > 0 ? (
                        <img src={`${Bucket}${selectedImage}`} className='w-100 object-fit-cover' height={320} style={{borderRadius: '10px'}} />
                      ) : (
                        <img
                          src={
                            propertyData?.unitType === 0
                              ? appartment
                              : propertyData?.unitType === 1
                              ? penthouse
                              : propertyData?.unitType === 2
                              ? common_area
                              : propertyData?.unitType === 3
                              ? townhouseIcon
                              : propertyData?.unitType === 4
                              ? villaIcon
                              : propertyData?.unitType === 5
                              ? other
                              : propertyData?.unitType === 6
                              ? other
                              : propertyData?.unitType === 7
                              ? other
                              : propertyData?.unitType === 8
                              ? other
                              : propertyData?.unitType === 9
                              ? other
                              : other
                          }
                          className='w-50'
                          height={320}
                          style={{borderRadius: '10px'}}
                        />
                      )}
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <div className='image-container d-flex'>
                      {propertyData?.images?.map((atch: any, ind: any) => {
                        return (
                          <div className='col-3 pe-5 pb-3'>
                            <img
                              src={`${Bucket}${atch}`}
                              className='w-100 object-fit-cover cursor-pointer'
                              height={60}
                              style={{borderRadius: '5px'}}
                              onClick={() => {
                                setSelectedImage(atch)
                              }}
                            />
                            {selectedImage == atch && <div className='active-image gray-card mt-2'></div>}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                {/* Right column */}
                <div className='col-7 ps-8'>
                  <div className='row'>
                    {/* Occupancy */}
                    <div className='col-4 mb-5'>
                      <div className='my-4 align-items-center'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color'>
                            {' '}
                            <b>Occupancy</b>
                          </label>
                        </h4>

                        <h3 className='white-dark-theme-color'>
                          <b>{propertyData?.leaseMetrics?.occupancyRate ? `${formatAmount(propertyData?.leaseMetrics?.occupancyRate)}%` : '-'}</b>
                        </h3>
                      </div>
                    </div>

                    {/* Rating */}
                    <div className='col-4 mb-5'>
                      <div className='my-4 align-items-center'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color'>
                            {' '}
                            <b>Rating</b>
                          </label>
                        </h4>

                        <h3 className='white-dark-theme-color'>
                          <b>{propertyData?.leaseMetrics?.rating ? propertyData?.leaseMetrics?.rating : '-'}</b>
                        </h3>
                      </div>
                    </div>

                    <div className='col-4 mb-5'>
                      <div className='my-4 align-items-center'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color'>
                            {' '}
                            <b>Owner</b>
                          </label>
                        </h4>

                        <div className='d-flex align-items-center cursor-pointer' onClick={() => handleOwnerModel(propertyData)}>
                        <h3 className='white-dark-theme-color'>
                            <b>
                              {propertyData?.ownerData?.length > 0
                                ? `${propertyData?.ownerData?.[0]?.firstName} ${propertyData?.ownerData?.[0]?.lastName}`
                                : '-'}
                            </b>
                          </h3>
                          <span className='ms-7 labl-gry light-dark-theme-color'>
                            <b>{propertyData?.ownerData?.length > 1 ? `+${propertyData?.ownerData?.length - 1}` : ''}</b>
                          </span>
                        </div>
                      </div>
                    </div>

                    {/* Average Rent Value */}
                    <div className='col-4 mb-5'>
                      <div className='my-4 align-items-center'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color'>
                            {' '}
                            <b>Average Rent Value</b>
                          </label>
                        </h4>

                        <h3 className='white-dark-theme-color'>
                          <b>{propertyData?.leaseMetrics?.avgRent ? `AED ${formatAmount(propertyData?.leaseMetrics?.avgRent)}` : '-'}</b>
                        </h3>
                      </div>
                    </div>

                    {/* Low Range Rent */}
                    <div className='col-4 mb-5'>
                      <div className='my-4 align-items-center'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color'>
                            {' '}
                            <b>Low Range Rent</b>
                          </label>
                        </h4>

                        <h3 className='white-dark-theme-color'>
                          <b>{propertyData?.leaseMetrics?.lowRangeRent ? `AED ${formatAmount(propertyData?.leaseMetrics?.lowRangeRent)}` : '-'}</b>
                        </h3>
                      </div>
                    </div>

                    {/* High Range Rent */}
                    <div className='col-4 mb-5'>
                      <div className='my-4 align-items-center'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color'>
                            {' '}
                            <b>High Range Rent</b>
                          </label>
                        </h4>

                        <h3 className='white-dark-theme-color'>
                          <b>{propertyData?.leaseMetrics?.highRangeRent ? `AED ${formatAmount(propertyData?.leaseMetrics?.highRangeRent)}` : '-'}</b>
                        </h3>
                      </div>
                    </div>

                    {/* Total Leases*/}
                    <div className='col-4 mb-5'>
                      <div className='my-4 align-items-center'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color'>
                            {' '}
                            <b>Total Leases</b>
                          </label>
                        </h4>

                        <h3 className='white-dark-theme-color'>
                          <b>{propertyData?.leaseMetrics?.totalLeases ? formatAmount(propertyData?.leaseMetrics?.totalLeases) : '-'}</b>
                        </h3>
                      </div>
                    </div>

                    {/* Average Lease Duration */}
                    <div className='col-4 mb-5'>
                      <div className='my-4 align-items-center'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color'>
                            {' '}
                            <b>Average Lease Duration</b>
                          </label>
                        </h4>

                        <h3 className='white-dark-theme-color'>
                          <b>{propertyData?.leaseMetrics?.avgLeaseDuration ? `${propertyData?.leaseMetrics?.avgLeaseDuration} Days` : '-'}</b>
                        </h3>
                      </div>
                    </div>

                    {/* Total Tenants */}
                    <div className='col-4 mb-5'>
                      <div className='my-4 align-items-center'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color'>
                            {' '}
                            <b>Total Tenants</b>
                          </label>
                        </h4>

                        <h3 className='white-dark-theme-color'>
                          <b>{propertyData?.leaseMetrics?.totalLeases ? formatAmount(propertyData?.leaseMetrics?.totalLeases) : '-'}</b>
                        </h3>
                      </div>
                    </div>
                  </div>

                  <div className='d-flex align-items-center  mt-2 pb-5 mb-5 ' style={{borderBottom: '0.1rem solid #bec3cb'}}></div>

                  <div className='row'>
                    {/* Total Income */}
                    <div className='col-4 mb-5'>
                      <div className='my-4 align-items-center'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color'>
                            {' '}
                            <b>Total Income</b>
                          </label>
                        </h4>

                        <h3 className='white-dark-theme-color'>
                          <b>{propertyData?.leaseMetrics?.totalIncome ? `AED ${formatAmount(propertyData?.leaseMetrics?.totalIncome)}` : '-'}</b>
                        </h3>
                      </div>
                    </div>

                    {/* Total Expenses */}
                    <div className='col-4 mb-5'>
                      <div className='my-4 align-items-center'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color'>
                            {' '}
                            <b>Total Expenses</b>
                          </label>
                        </h4>

                        <h3 className='white-dark-theme-color'>
                          <b>{propertyData?.leaseMetrics?.totalExpense ? `AED ${formatAmount(propertyData?.leaseMetrics?.totalExpense)}` : '-'}</b>
                        </h3>
                      </div>
                    </div>

                    {/* Total Outstanding */}
                    <div className='col-4 mb-5'>
                      <div className='my-4 align-items-center'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label className='labl-gry light-dark-theme-color'>
                            {' '}
                            <b>Total Outstanding</b>
                          </label>
                        </h4>

                        <h3 className='white-dark-theme-color'>
                          <b>{propertyData?.leaseMetrics?.outstanding ? `AED ${formatAmount(propertyData?.leaseMetrics?.outstanding)}` : '-'}</b>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Leases */}
              {location?.state?.isCommonArea !== undefined && !location?.state?.isCommonArea && (
                <>
                  <div className='d-flex  align-items-center mt-10'>
                    <h2 className='text-decoration-underline ps-1 white-dark-theme-color fnt-700'>
                      {'Leases '}
                    </h2>
                  </div>

                  <LeasesOfProperty propData={propertyData?.leases} />
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center w-100' style={{height: '75vh'}}>
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}

      {ownerListeModal && (
        <OwnerListModel
          listData={ownerListData}
          show={ownerListeModal}
          handleClose={() => {
            setOwnerListModal(false)
          }}
        />
      )}
    </>
  )
}

export default PropertyPrpfile
