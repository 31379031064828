import React from 'react'
import {Col, Modal, Row} from 'react-bootstrap'
import {Bucket} from '../../../apiCommon/helpers/API/ApiData'
import {KTSVG} from '../../../_metronic/helpers'

interface ButtonProps {
  show: any
  handleClose: any
  showChequeData: any
}

const ChequeData = ({show, handleClose, showChequeData}: ButtonProps) => {
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-600px'
      show={show}
      onHide={handleClose}
      backdrop={true}
      size='sm'
    >
      <div className='modal-header'>
        <h2>Cheque Details</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>
      <div className='modal-body py-lg-10 px-lg-10'>
        <Row>
          <Col md={12} className=''>
            <div className='payment-receipt-item'>
              <h6 className='text-muted'>
                cheque No :
                <span className='mx-1' style={{color: 'black'}}>
                  {showChequeData?.chequeNo}
                </span>
              </h6>
            </div>
            <div className='payment-receipt-item'>
              <h6 className='text-muted'>
                Bank Name :
                <span className='mx-1' style={{color: 'black'}}>
                  {showChequeData?.bankName}
                </span>
              </h6>
            </div>
            <div className='payment-receipt-item'>
              {showChequeData?.image && (
                <a
                  className='btn btn-sm fw-bold btn-primary btn-green'
                  href={`${Bucket}${showChequeData?.image}`}
                  target='_blank'
                >
                  View Cheque image
                </a>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default ChequeData
