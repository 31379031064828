import moment from 'moment'
import React, {useEffect, useState, useRef} from 'react'
import {Col, Row} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import noData from '../../../img/NoData1.svg'
import {ApiGet, ApiPost, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import ReactPaginate from 'react-paginate'

import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import plus from '../../../img/add-white.png'
import IncomingPaymentModal from './IncomingPaymentModal'
import EditPaymentModal from './EditPaymentModal'
import open from '../../../img/payment-col-open.png'
import close from '../../../img/payment-col-close.png'
import closes from '../../../img/close.svg'
import Select from 'react-select'
import {DatePicker, Space} from 'antd'

import {DemoContainer, DemoItem} from '@mui/x-date-pickers/internals/demo'
import {LocalizationProvider} from '@mui/x-date-pickers-pro'
import {AdapterDayjs} from '@mui/x-date-pickers-pro/AdapterDayjs'
import {DateRangePicker} from '@mui/x-date-pickers-pro/DateRangePicker'
import TabsFinancials from './TabsFinancials'

import lock from '../../../img/padlock.png'
import allTen from '../../../img/all-tenc.png'
import bookTen from '../../../img/book-tenc.png'
import maintenance from '../../../img/maintenanceWhite.png'

// import { DateRangePicker } from 'rsuite';
// import "rsuite/dist/rsuite.css";

// import Datepicker from "react-tailwindcss-datepicker"

import './style.scss'
// import './style.css'

const {RangePicker} = DatePicker

function GlobalFinancials() {
  const navigate = useNavigate()

  const tableRef = useRef<HTMLTableElement | null>(null)

  const [incomeModal, setIncomeModal] = useState<any>(false)
  const [incomingPaymentId, setIncomingPaymentId] = useState<any>()
  const [paymentId, setPaymentId] = useState<any>()
  const [subItemId, setSubItemId] = useState<any>()
  const [itemId, setItemId] = useState<any>()
  const [payments, setPayments] = useState<any>()
  const [show, setShow] = useState<any>([])
  const [showFilter, setShowFilter] = useState<any>(false)

  const [pageLimit, setPageLimit] = useState<any>(1)
  const [page, setPage] = useState<any>(1)

  const [editIncomeModal, setEditIncomeModal] = useState<any>(false)
  const [dataToEdit, setDataToEdit] = useState<any>()

  const [devlopment, setdevlopment] = useState<any>([])
  const [devId, setDevId] = useState<any>()

  const [paymentStatus, setPaymentStatus] = useState<any>(null)
  const [paymentCat, setPaymentCat] = useState<any>()
  const [startDate, setStartDate] = useState<any>(new Date())
  const [endDate, setEndDate] = useState<any>(new Date())

  const [unitDetails, setUnitDetails] = useState<any>()

  const [checked, setChecked] = useState<any>(null)

  const [dateRange, setDateRange] = useState<Date[]>([])

  const [value, setValue] = useState<any>({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  })

  // const handleValueChange = (newValue: any) => {
  //   console.log('newValue:', newValue)
  //   setValue(newValue)
  // }

  // const [filterDate, setFilterDate] = useState<any>([new Date(), new Date()])

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '50%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#fff' : '#35475e',
      backgroundColor: state.isSelected ? '#35475e' : '',
      ':hover': {
        backgroundColor: '#d7dadf',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #35475e ',
      marginTop: '0px',
      borderRadius: '0px',
    }),

    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #35475e',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      ':hover': {
        borderColor: '#35475e',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#35475e !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({...defaultStyles, color: ' #5e6278', fontWeight: '600'}),
  }

  const addIncomingPaymentFunc = () => {
    ApiPost(`corporate/payment`)
      .then((res) => {
        setIncomingPaymentId(res?.data?.data._id)
        setPaymentId(res?.data?.data.id)
      })
      .catch((err) => console.log('err', err))
  }

  const addSubItemFunc = () => {
    ApiPost(`corporate/payment_sub_item`)
      .then((res) => {
        setSubItemId(res?.data?.data._id)
        setItemId(res?.data?.data.id)
        console.log(res?.data?.data.id)
        setIncomeModal(true)
      })
      .catch((err) => console.log('err', err))
  }

  // get All the development list either community or building i.e. first dropdown-list
  const callDevelopment = () => {
    ApiGet(`corporate/communities/get_development`)
      .then((res) => {
        setdevlopment(res?.data?.data)
      })
      .catch((err) => console.log('err', err))
  }

  const [expensesTotalCount, setExpensesTotalCount] = useState<any>(0)
  const [incomeTotalCount, setIncomeTotalCount] = useState<any>(0)

  const getAllPayments = () => {
    const body = {
      limit: 10,
      page: page,
    }
    ApiPost(`corporate/payment/get`, body)
      .then((res) => {
        setPayments(res?.data?.data?.payment_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
        for (let i = 0; i < res?.data?.data?.payment_data.length; i++) {
          let values = [...show]
          values[i] = false
          setShow(values)
        }
        setIncomeTotalCount(res?.data?.data?.state?.data_count)
      })
      .catch((err) => console.log('err', err))
  }

  const getAllExpenses = () => {
    const body = {
      page: 1,
      limit: 25,
      searchExpense: '',
      searchPayee: '',
      searchPaymentAccount: '',
    }

    ApiPost(`corporate/expense/get`, body)
      .then((res) => {
        setExpensesTotalCount(res?.data?.data?.state?.data_count)
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    getAllExpenses()
  }, [])

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  //
  const handleDateRangeChange = (dates: any) => {
    console.log(dates)
    setStartDate(dates?.[0].format('YYYY.MM.DD'))
    setEndDate(dates?.[1].format('YYYY.MM.DD'))
  }

  //
  const applyFilters = () => {
    let newData: any = []
    newData[newData.length] = devId

    setPage(1)
    const body = {
      limit: 10,
      page: page,
      startDate: startDate,
      endDate: endDate,
      paymentStatus: paymentStatus,
      categories: checked,
      communityIds: newData,
    }
    ApiPost(`corporate/payment/get`, body)
      .then((res) => {
        setPayments(res?.data?.data?.payment_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
        for (let i = 0; i < res?.data?.data?.payment_data.length; i++) {
          let values = [...show]
          values[i] = false
          setShow(values)
        }
      })
      .catch((err) => console.log('err', err))
  }

  const getPaymentById = async (id: any) => {
    ApiGet(`corporate/payment/${id}`)
      .then(async (res) => {
        setDataToEdit(res?.data?.data)

        await ApiGet(`corporate/unit/${res?.data?.data?.unitId}`)
          .then((res) => {
            setUnitDetails(res?.data?.data)
            setEditIncomeModal(true)
          })
          .catch((err) => console.log('err', err))
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    getAllPayments()
    callDevelopment()
    enableScrolling()
  }, [page])

  const handleDateValueChange = (dates: any) => {
    if (dates?.[0] != null) setStartDate(dates?.[0].format('YYYY.MM.DD'))
    else setStartDate(dates?.[0])

    if (dates?.[1] != null) setEndDate(dates?.[1].format('YYYY.MM.DD'))
    else setEndDate(dates?.[1])
  }

  const handleMouseWheel = (e: React.WheelEvent) => {
    if (tableRef.current) {
      if (isMouseOverTable(e, tableRef.current)) {
        e.preventDefault()
        tableRef.current.scrollLeft += e.deltaY
      }
    }
  }

  const isMouseOverTable = (e: React.WheelEvent, element: HTMLElement) => {
    // document.body.style.overflow = 'hidden';
    const rect = element.getBoundingClientRect()
    const mouseX = e.clientX - rect.left
    const mouseY = e.clientY - rect.top
    return mouseX >= 0 && mouseX <= rect.width && mouseY >= 0 && mouseY <= rect.height
  }

  const disableScrolling = () => {
    document.body.style.overflow = 'hidden'
  }

  const enableScrolling = () => {
    document.body.style.overflow = '' // Reset to default
  }

  const handleMouseEnter = (event: any) => {
    disableScrolling()
  }

  const handleMouseLeave = () => {
    enableScrolling()
  }

  const getPositive = (number: any) => {
    // if number is less than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const [tenantReqCount, setTenantReqCount] = useState<any>()

  const updateTenantReqCount = (cnt: any) => {
    setTenantReqCount(cnt)
  }

  //
  const getCRMTickets = () => {
    const body = {
      limit: 25,
      page: 1,
      buildingIds: [],
      communityIds: [],
      unitIds: [],
      tenantIds: [],
      crmTicketTypes: [],
      crmTicketStatus: [],
      crmTicketPriorities: [],
    }
    ApiPost(`corporate/crm_ticket/get`, body)
      .then((res: any) => {
        setTenantReqCount(res?.data?.data?.state?.data_count)
      })
      .catch((err) => {
        ErrorToast(err.message)
        if (err?.message === 'Your account has been suspended!') {
          window.location.pathname = '/auth'
          localStorage.clear()
        }
      })
  }

  useEffect(() => {
    getCRMTickets()
  }, [])

  return (
    <>
      <div id='' className=' pt-0 mt-0 no-print px-2'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='page-heading  m-0'>
              <b>Financials </b>
            </h1>
          </div>
        </div>
        <div className='d-flex flex-wrap mt-5 financials'>
          {/* Old Income Tab */}
          <div className='me-3 mb-5'>
            <div
              className={`d-flex align-items-center tenancy-green-card tenancy-active-blue-card h-45px`}
              onClick={() => {
                navigate(`/financials-income`)
              }}
            >
              {/* <div
                className='d-flex align-items-end'> */}
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={allTen}
                  height={22}
                  width={22}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                {/* <h2 className='mb-0 text-white'>
                  {incomeTotalCount ? incomeTotalCount?.toLocaleString('en-US', {}) : 0}
                </h2> */}
                <h4 className='mb-0 text-white'>Income-01</h4>
              </div>
              {/* </div> */}
            </div>

            <div className='blue-active-filter '></div>
          </div>

          {/* New Incomes Tab */}
          <div className='me-3 mb-5'>
            <div
              className={`d-flex align-items-center tenancy-green-card h-45px`}
              onClick={() => {
                navigate(`/finance-income`)
              }}
            >
              {/* <div
                className='d-flex align-items-end'> */}
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={allTen}
                  height={22}
                  width={22}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                {/* <h2 className='mb-0 text-white'>
                  {incomeTotalCount ? incomeTotalCount?.toLocaleString('en-US', {}) : 0}
                </h2> */}
                <h4 className='mb-0 text-white'>Income</h4>
              </div>

              {/* </div> */}
            </div>
          </div>

          {/* Expenses Tab */}
          <div className='me-3 mb-5'>
            <div
              className={`d-flex align-items-center tenancy-green-card h-45px`}
              onClick={() => {
                navigate(`/financials-expenses`)
              }}
            >
              {/* <div
                className='d-flex align-items-end'> */}
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={bookTen}
                  height={21}
                  width={21}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                {/* <h2 className='mb-0 text-white'>
                  {expensesTotalCount ? expensesTotalCount?.toLocaleString('en-US', {}) : 0}
                </h2> */}

                <h4 className='mb-0 text-white'>Expenses</h4>
              </div>
              {/* </div> */}
            </div>
          </div>

          {/* Tenant Requests*/}
          <div className='ms-auto'>
            <div
              className={'tenancy-green-card d-flex align-items-center gray-card h-45px'}
              onClick={() => {
                navigate(`/finance-crm-residents`)
              }}
            >
              {/* <div
                className='d-flex align-items-end'> */}
              <div
                className=''
                style={{
                  fontWeight: '700',
                  width: 'fit-content',
                }}
              >
                <img
                  src={maintenance}
                  height={26}
                  width={26}
                  style={{
                    marginRight: '7px',
                  }}
                  className='mx-3'
                />
              </div>
              <div>
                {/* <h2 className='mb-0 text-white'>
                  {tenantReqCount ? tenantReqCount?.toLocaleString('en-US', {}) : 0}
                </h2> */}

                <h4 className='mb-0 text-white'>Tenant Requests</h4>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
        {/* <TabsFinancials value={'financials'} /> */}

        {/* <div className='page-title d-flex flex-wrap mb-5 min-w-200px '>
          <h1 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0'>
            Financials
          </h1>
        </div> */}

        {/* Tabs */}
        {/* <div className='d-flex align-items-center gap-2 gap-lg-5 justify-content-center align-items-center mb-5'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 active me-5'
                data-bs-toggle='tab'
                onClick={() => navigate('/financials-income')}
              >
                Income
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 mx-5'
                data-bs-toggle='tab'
                onClick={() => navigate('/financials-expenses')}
              >
                Expenses
              </a>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 mx-5'
                data-bs-toggle='tab'
                onClick={() => navigate('/financials-statement-reports')}
              >
                Statement Reports
              </a>
            </li>

            <li className='nav-item'>
              <a
                className='nav-link text-active-primary pb-2 mx-5'
                data-bs-toggle='tab'
                onClick={() => navigate('/financials-payment-reminders')}
              >
                Payment Reminders
              </a>
            </li>
          </ul>
        </div> */}

        {/* Income */}
        <div className='d-flex align-items-center gap-2 my-8 gap-lg-3 '>
          <div className='d-flex align-items-center '>
            <input
              type='text'
              className='form-control form-control-settings'
              placeholder='Search invoice #'
              name='item_id'
            />
            <button
              className='btn btn-sm fw-bold btn-primary btn-green mx-3 fs-4 d-flex'
              style={{width: '130px', height: '37px'}}
              onClick={() => setShowFilter(true)}
            >
              <svg
                style={{
                  height: '18px',
                  width: '18px',
                  marginRight: '7px',
                  marginLeft: '-10px',
                  marginTop: '1px',
                }}
                viewBox='0 0 1024 1024'
                xmlns='http://www.w3.org/2000/svg'
                className='me-1'
              >
                <path
                  fill='#ffff'
                  d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                />
              </svg>
              <p style={{marginLeft: '8px'}}>Filter</p>
            </button>
          </div>
          <div className='ms-auto'>
            <button
              className='btn btn-sm fw-bold text-white '
              style={{backgroundColor: '#35475e'}}
              onClick={() => {
                setIncomeModal(true)
                // addIncomingPaymentFunc()
                // addSubItemFunc()
              }}
            >
              <img
                src={plus}
                style={{
                  height: '20px',
                  width: '20px',
                  marginRight: '7px',
                  marginLeft: '-10px',
                }}
              />
              Add Incoming Payment
            </button>
          </div>
        </div>

        <div className='card card-flush gap-5 p-5 pt-1 pe-2'>
          {payments?.length > 0 ? (
            <div
              className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'
              id='global-table'
              // onWheel={handleMouseWheel}
              // ref={tableRef}
              // onMouseEnter={handleMouseEnter}
              // onMouseLeave={handleMouseLeave}
            >
              <table
                className='table align-start table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-100px'>#</th>
                    <th className='min-w-150px '>Payment</th>
                    <th className='min-w-150px'>Amount</th>
                    <th className='min-w-150px'>Development</th>
                    <th className='min-w-150px'>Unit No.</th>
                    <th className='min-w-125px'>Date Created</th>
                    <th className='min-w-100px'>Status</th>
                    <th className='min-w-100px'>Category</th>
                    <th className='min-w-100px'>Due</th>
                    <th className='min-w-100px'>Reminder</th>
                    <th className='min-w-150px'>Amount Paid</th>
                    <th className='min-w-100px'>Date Paid</th>
                    <th className='min-w-150px'>Oustanding</th>
                    <th className='min-w-100px'>Method</th>
                    {/* <th className='min-w-100px'>Invoice</th> */}
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {payments.map((pm: any, ind: any) => {
                    let x = pm?.invoiceURL?.split('.')[1]
                    let y = pm?.invoiceURL?.split('/')[2]
                    // let inv: any = y + '.' + x
                    let inv: any = y

                    return (
                      <>
                        <tr
                          style={
                            show[ind] == true
                              ? {borderBottomStyle: 'none', height: '30px'}
                              : show[ind] == false
                              ? {borderBottomStyle: 'dashed', height: '30px'}
                              : pm?.payment_sub_item?.length == 0
                              ? {borderBottomStyle: 'dashed', height: '30px'}
                              : {height: '30px'}
                          }
                        >
                          <td>
                            <div className='d-flex align-items-center'>
                              {pm?.payment_sub_item?.length > 0 && (
                                <img
                                  src={show[ind] ? close : open}
                                  height='15'
                                  width='15'
                                  className='cursor-pointer'
                                  style={{marginLeft: '-30px', marginRight: '15px'}}
                                  onClick={() => {
                                    if (show[ind] == true) {
                                      let values = [...show]
                                      values[ind] = false
                                      setShow(values)
                                    } else {
                                      let values = [...show]

                                      values[ind] = true

                                      setShow(values)
                                    }
                                  }}
                                />
                              )}
                              <a
                                // href={`${Bucket}${pm?.invoiceURL}`}
                                // target='_blank'
                                style={{
                                  textDecoration: 'underline',
                                  color: '#007a59',
                                  marginLeft: '-3px',
                                }}
                                onClick={() => getPaymentById(pm?._id)}
                              >
                                {' '}
                                {pm.id}
                              </a>
                            </div>
                          </td>
                          <td className='max-w-150px'>
                            <div className='d-flex'>
                              {pm?.isLock && (
                                <img src={lock} height={16} width={16} className='me-2' />
                              )}
                              {pm.name}
                            </div>
                          </td>
                          <td>
                            {parseFloat(pm?.totalAmount)?.toLocaleString('en-US', {
                              minimumFractionDigits: 2,
                            })}
                          </td>
                          <td>
                            {pm?.community?.length > 0
                              ? pm?.community?.[0]?.name
                              : pm?.building?.[0]?.name}
                          </td>
                          <td>{pm?.unit?.length > 0 ? pm?.unit?.[0]?.unitNo : '-'}</td>
                          <td>
                            {pm?.createdAt ? moment(pm?.createdAt).format('DD.MM.YYYY') : '-'}
                          </td>
                          <td>
                            {pm.paymentStatus === 0
                              ? 'Unpaid'
                              : pm.paymentStatus === 1
                              ? 'Paid'
                              : pm.paymentStatus === 2
                              ? 'Partially paid'
                              : pm.paymentStatus === 3
                              ? 'Void'
                              : 'Draft'}
                          </td>
                          <td>
                            {pm.categories == 0
                              ? 'Upcoming'
                              : pm.categories == 1
                              ? 'Overdue'
                              : 'Deferred'}
                          </td>
                          <td>{pm?.dueDate ? moment(pm?.dueDate).format('DD.MM.YYYY') : '-'}</td>
                          <td>
                            {pm?.payment_reminder?.length > 0
                              ? moment(pm?.payment_reminder[0]?.reminderDate).format('DD.MM.YYYY')
                              : '-'}
                          </td>
                          <td>
                            {pm?.amountPaid?.toLocaleString('en-US', {minimumFractionDigits: 2})}
                          </td>
                          <td>{pm?.datePaid ? moment(pm?.datePaid).format('DD.MM.YYYY') : '-'}</td>
                          <td>
                            {pm?.outstandingAmount <= 0 ? '' : '-'}
                            {parseFloat(getPositive(pm?.outstandingAmount))?.toLocaleString(
                              'en-US',
                              {
                                minimumFractionDigits: 2,
                              }
                            )}
                          </td>
                          <td>
                            {pm?.paymentMethod == 0
                              ? 'Card'
                              : pm?.paymentMethod == 1
                              ? 'Cheque'
                              : pm?.paymentMethod == 2
                              ? 'Bank'
                              : 'Cash'}
                          </td>
                          {/* <td>
                            {pm?.invoiceURL ? (
                              <a
                                href={`${Bucket}${pm?.invoiceURL}`}
                                target='_blank'
                                style={{textDecoration: 'underline', color: '#007a59'}}
                              >
                                {inv}
                              </a>
                            ) : (
                              '-'
                            )}
                          </td> */}
                        </tr>

                        {pm?.payment_sub_item?.length > 0 && show[ind] == true ? (
                          pm?.payment_sub_item.map((pmSub: any, index: any) => {
                            return (
                              <>
                                <tr style={{borderBottomStyle: 'none', height: '30px'}}>
                                  <td style={{paddingLeft: '5px'}}>
                                    {pm.id}
                                    {'_'}
                                    {pmSub?.id}
                                  </td>
                                  <td>{pmSub?.name}</td>
                                  <td>
                                    {pmSub?.isLatePaymentFeesRecord
                                      ? pmSub?.amount?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })
                                      : (pmSub?.amount * pmSub?.quantity)?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>
                                    {pmSub.paymentStatus === 0
                                      ? 'Unpaid'
                                      : pmSub.paymentStatus === 1
                                      ? 'Paid'
                                      : pmSub.paymentStatus === 2
                                      ? 'Partially paid'
                                      : pmSub.paymentStatus === 3
                                      ? 'Void'
                                      : 'Draft'}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>

                                  <td>
                                    {pmSub?.amountPaid?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                  <td>
                                    {pmSub?.datePaid
                                      ? moment(pmSub?.datePaid).format('DD.MM.YYYY')
                                      : '-'}
                                  </td>
                                  <td>
                                    {pmSub?.outstandingAmount <= 0 ? '' : '-'}
                                    {parseFloat(
                                      getPositive(pmSub?.outstandingAmount)
                                    )?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                    })}
                                  </td>
                                </tr>
                                {index == pm?.payment_sub_item?.length - 1 && (
                                  <tr style={{height: '30px'}}>
                                    <td></td>
                                    <td>{' Total VAT'}</td>
                                    <td>
                                      {pm?.totalVAT?.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                  </tr>
                                )}
                              </>
                            )
                          })
                        ) : (
                          <></>
                        )}
                      </>
                    )
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div className='card-body pt-0 table-responsive mt-1 pb-4 pe-5'>
              <table
                className='table align-start table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-100px'>#</th>
                    <th className='min-w-150px '>Payment</th>
                    <th className='min-w-150px'>Amount</th>
                    <th className='min-w-150px'>Date Created</th>
                    <th className='min-w-100px'>Status</th>
                    <th className='min-w-100px'>Category</th>
                    <th className='min-w-150px'>Due</th>
                    <th className='min-w-150px'>Reminder</th>
                    <th className='min-w-150px'>Amount Paid</th>
                    <th className='min-w-100px'>Date Paid</th>
                    <th className='min-w-150px'>Oustanding</th>
                    <th className='min-w-100px'>Method</th>
                    {/* <th className='min-w-100px'>Invoice</th> */}
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  <tr>
                    <td colSpan={10} className='text-center'>
                      <img src={noData} alt='' width={350} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous disabled'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>

        {/* Filters */}
        <div
          className={showFilter == true ? 'filter-payments' : 'd-none'}
          style={{
            width: '420px',
            backgroundColor: 'white',
            zIndex: '999',
            height: 'fit-content',
            boxShadow: '0px 0px 20px 0px rgba(18, 32, 78, 0.2)',
          }}
        >
          <div
            className='card m-0 p-0'
            style={{
              overflow: 'hidden',
              borderRadius: '0px',
              boxShadow: 'none',
            }}
          >
            {/* header */}
            <h3
              className='m-0 d-flex align-items-center msg_title'
              style={{
                justifyContent: 'space-between',
                borderRadius: '0px',
                zIndex: '1',
                opacity: '0.85',
              }}
            >
              Filter
              <span className='mx-0' style={{cursor: 'pointer', marginTop: '-20px'}}>
                <img src={closes} alt='' onClick={() => setShowFilter(false)} />
              </span>
            </h3>

            <h3
              className='m-0 d-flex align-items-center '
              style={{
                justifyContent: 'flex-end',
                borderRadius: '0px',
                transform: 'translate(0px, -50px)',
                color: 'white',
                backgroundColor: '#007a59',
                paddingBottom: '5px',
              }}
            >
              <span className='mx-1' style={{cursor: 'pointer'}}>
                <svg
                  style={{
                    height: '40px',
                    width: '38px',
                    marginRight: '7px',
                    marginLeft: '-10px',
                    marginTop: '5px',
                    transform: 'translate(-30px, 0px)',
                  }}
                  viewBox='0 0 1024 1024'
                  xmlns='http://www.w3.org/2000/svg'
                  className='me-1'
                >
                  <path
                    fill='#ffff'
                    d='M384 523.392V928a32 32 0 0 0 46.336 28.608l192-96A32 32 0 0 0 640 832V523.392l280.768-343.104a32 32 0 1 0-49.536-40.576l-288 352A32 32 0 0 0 576 512v300.224l-128 64V512a32 32 0 0 0-7.232-20.288L195.52 192H704a32 32 0 1 0 0-64H128a32 32 0 0 0-24.768 52.288L384 523.392z'
                  />
                </svg>
              </span>
            </h3>
          </div>
          <div style={{height: '100%', transform: 'translate(0px, -50px)'}}>
            <div className='min-w-200px align-items-center ps-3 ms-5 mt-5 '>
              <h4 className='' style={{minWidth: '105px', color: 'black', fontWeight: '500'}}>
                <b>Property</b>
              </h4>

              <Select
                styles={customStyles}
                isSearchable={false}
                options={
                  devlopment?.length > 0 &&
                  devlopment?.map((dev: any) => {
                    return {
                      label: `${dev?.name}`,
                      value: `${dev?._id}`,
                      details: `${dev}`,
                    }
                  })
                }
                onChange={(e: any) => setDevId(e.value)}
              />
            </div>

            <div className='min-w-200px align-items-center ps-3 ms-5 mt-5 pt-3'>
              <h4 className='' style={{minWidth: '105px', color: 'black', fontWeight: '500'}}>
                <b>Status</b>
              </h4>

              <div className='row'>
                <div className='col-4 d-flex align-items-center'>
                  <input
                    className='form-check-input me-3'
                    type='radio'
                    checked={paymentStatus === 0}
                    // defaultChecked={f._id === vendorServiceId}
                    style={{cursor: 'pointer'}}
                    onChange={() => setPaymentStatus(0)}
                  />{' '}
                  Unpaid
                </div>
                <div className='col-4 d-flex align-items-center'>
                  <input
                    className='form-check-input me-3'
                    type='radio'
                    checked={paymentStatus === 2}
                    // defaultChecked={f._id === vendorServiceId}
                    style={{cursor: 'pointer'}}
                    onChange={() => setPaymentStatus(2)}
                  />{' '}
                  Partially Paid
                </div>
                <div className='col-4 d-flex align-items-center'>
                  <input
                    className='form-check-input me-3'
                    type='radio'
                    checked={paymentStatus === 1}
                    // defaultChecked={f._id === vendorServiceId}
                    style={{cursor: 'pointer'}}
                    onChange={() => setPaymentStatus(1)}
                  />{' '}
                  Paid
                </div>
              </div>
            </div>

            <div className='min-w-200px align-items-center ps-3 ms-5 mt-5 pt-3'>
              <h4 className='' style={{minWidth: '105px', color: 'black', fontWeight: '500'}}>
                <b>Category</b>
              </h4>

              <div className='row'>
                <div className='col-4 d-flex align-items-center'>
                  <input
                    className='form-check-input me-3'
                    type='radio'
                    checked={checked === 0}
                    // defaultChecked={f._id === vendorServiceId}
                    style={{cursor: 'pointer'}}
                    onChange={() => setChecked(0)}
                  />{' '}
                  Upcoming
                </div>
                <div className='col-4 d-flex align-items-center'>
                  <input
                    className='form-check-input me-3'
                    type='radio'
                    checked={checked === 1}
                    // defaultChecked={f._id === vendorServiceId}
                    style={{cursor: 'pointer'}}
                    onChange={() => setChecked(1)}
                  />{' '}
                  Overdue
                </div>
                <div className='col-4 d-flex align-items-center'>
                  <input
                    className='form-check-input me-3'
                    type='radio'
                    checked={checked === 2}
                    // defaultChecked={f._id === vendorServiceId}
                    style={{cursor: 'pointer', borderRadius: '50%'}}
                    onChange={() => setChecked(2)}
                  />{' '}
                  Deffered
                </div>
              </div>
            </div>

            <div className='min-w-200px align-items-center ps-3 ms-5 mt-5 pt-3'>
              <h4 className='' style={{minWidth: '105px', color: 'black', fontWeight: '500'}}>
                <b>Date</b>
              </h4>

              <Space direction='vertical' size={12}>
                <RangePicker onChange={handleDateRangeChange} />
              </Space>
              {/* <Datepicker
              value={value}
              onChange={handleValueChange}
               
                // onChange={handleValueChange}
            /> */}

              {/* <DateRangePicker
        // value={dateRange}
        onChange={()=>setDateRange}
        placeholder="Select Date Range"
      /> */}
            </div>

            <div className='min-w-200px align-items-center ps-3 ms-5 mt-5 pt-3'>
              <h4 className='' style={{minWidth: '105px', color: 'black', fontWeight: '500'}}>
                <b>Date</b>
              </h4>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateRangePicker
                  calendars={1}
                  className='date-filter'
                  format='YYYY-MM-DD'
                  slotProps={{fieldSeparator: {children: 'to'}}}
                  localeText={{start: '', end: ''}}
                  onChange={handleDateValueChange}
                />
              </LocalizationProvider>
            </div>

            <div className='d-flex align-items-center justify-content-center ps-3 ms-5 mt-5'>
              <button
                className='btn btn-sm fw-bold btn-primary btn-green mx-3 fs-4 d-flex mt-5'
                style={{width: '130px', height: '37px'}}
                onClick={() => applyFilters()}
              >
                <p style={{marginLeft: '27px'}}>Apply</p>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Add Payment Modal */}
      {incomeModal && (
        <IncomingPaymentModal
          show={incomeModal}
          handleClose={() => {
            setIncomeModal(false)
          }}
          incomingPaymentId={incomingPaymentId}
          id={paymentId}
          subItemId={subItemId}
          subId={itemId}
          getAllPayments={getAllPayments}
        />
      )}

      {/* Edit Payment Modal */}
      {editIncomeModal && (
        <EditPaymentModal
          show={editIncomeModal}
          handleClose={() => {
            setEditIncomeModal(false)
          }}
          incomingPaymentId={incomingPaymentId}
          id={paymentId}
          subItemId={subItemId}
          subId={itemId}
          getAllPayments={getAllPayments}
          unitDetails={unitDetails}
          detailsToEdit={dataToEdit}
          type={'financials'}
        />
      )}
    </>
  )
}

export default GlobalFinancials
