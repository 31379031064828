import React, {useEffect, useState} from 'react'
import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit} from './modules/auth'
import {Toaster} from 'react-hot-toast'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import 'react-phone-input-2/lib/style.css'
import {GoogleOAuthProvider} from '@react-oauth/google'

import {MsalProvider} from '@azure/msal-react'
import {Configuration, PublicClientApplication} from '@azure/msal-browser'
import '../_metronic/assets/sass/core/components/custom_styling.scss'
import './../styles/index.scss'

import { AppProvider } from '../context/AppContext'

const configuration: Configuration = {
  auth: {
    clientId: '88aad168-a978-4f06-a70e-bbb785f340b2',
  }
}


const pca = new PublicClientApplication(configuration)
const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <GoogleOAuthProvider clientId='359403179255-hvieh1s0k8co79tl1ppr64tvuoc17bj9.apps.googleusercontent.com'>
        <MsalProvider instance={pca}>
          <I18nProvider>
            <LayoutProvider>
              <AppProvider>
                <AuthInit>
                  <Outlet />
                  <MasterInit />
                </AuthInit>
              </AppProvider>
            </LayoutProvider>
          </I18nProvider>
        </MsalProvider>
      </GoogleOAuthProvider>
      <Toaster position='top-center' reverseOrder={false} />
    </Suspense>
  )
}

export {App}
