import ReactPaginate from 'react-paginate'
import noData from '../../../img/NoData1.svg'

interface TableRowData {
  [key: string]: any
}

interface TableProps {
  fields: {label: string; key: string; labelClasses: string; keyClasses: string}[]
  items: TableRowData[]
  checkedValues: boolean[]
  setCheckedValues: (values: boolean[]) => void
  onCellClick?: (item: TableRowData, columnKey: string, rowIndex: number) => void
  handlePageClick: (event: {selected: number}) => void
  pageLimit: number
  page: number
}

const TableCellActionWithCheck: React.FC<TableProps> = ({
  fields,
  items,
  checkedValues,
  setCheckedValues,
  onCellClick,
  handlePageClick,
  pageLimit,
  page,
}) => {
  // console.log(items)
  return (
    <div className='table-responsive mt-4'>
      <table
        className='table align-middle table-row-dashed fs-8 gy-5'
        id='kt_ecommerce_sales_table'
      >
        <thead className='fs-7'>
          <tr>
            <th className='text-start w-25px'>
              <div className='form-check form-check-custom form-check-solid form-check-sm py-0 ps-0 pe-5 test'>
                <input
                  className='form-check-input cursor-pointer'
                  type='checkbox'
                  onChange={(e) => {
                    const values = e.target.checked ? items.map(() => true) : items.map(() => false)
                    setCheckedValues(values)
                  }}
                  checked={items.length > 0 && checkedValues.every(Boolean)}
                />
              </div>
            </th>
            {fields.map((header, index) => (
              <th key={index} className={` ${header?.labelClasses}`}>
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.length > 0 ? (
            items.map((item, rowIndex) => (
              <tr key={rowIndex}>
                <td>
                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0'>
                    <input
                      className='form-check-input cursor-pointer'
                      type='checkbox'
                      checked={checkedValues[rowIndex] || false}
                      onChange={() => {
                        const values = [...checkedValues]
                        values[rowIndex] = !values[rowIndex]
                        setCheckedValues(values)
                      }}
                    />
                  </div>
                </td>
                {fields.map((columnKey: any, colIndex: any) => (
                  <td
                    key={colIndex}
                    onClick={() => onCellClick && onCellClick(item, columnKey.key, rowIndex)} // Ensure columnKey.key is passed
                    className={`cursor-pointer ${columnKey?.keyClasses}`}
                  >
                    {item[columnKey.key] || '-'}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={fields.length + 1} className='text-center'>
                <img src={noData} alt='No Data' width={350} />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div className='row mb-5'>
        <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-end mt-5'>
          <ReactPaginate
            breakLabel='...'
            nextLabel='>'
            pageClassName='paginate_button page-item'
            pageLinkClassName='page-link'
            containerClassName='pagination'
            activeClassName='active'
            previousClassName='paginate_button page-item previous'
            nextClassName='paginate_button page-item next'
            previousLinkClassName='page-link'
            nextLinkClassName='page-link'
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageLimit}
            forcePage={page - 1}
            previousLabel='<'
          />
        </div>
      </div>
    </div>
  )
}

export default TableCellActionWithCheck
