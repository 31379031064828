import React from 'react'
import FixedFee from './components/FixedFee'
import PercentBasedFee from './components/PercentBasedFee'

interface ItemData {
  amountCalculationType: number
}

interface FeeSettingsProps {
  itemData?: ItemData
  setItemData?: React.Dispatch<React.SetStateAction<ItemData>> 
}

const FeeSettings: React.FC<FeeSettingsProps> = ({itemData, setItemData}) => {
  return <div>{itemData?.amountCalculationType == 0 ? <FixedFee /> : <PercentBasedFee />}</div>
}

export default FeeSettings
