import React, {useEffect} from 'react'
import {useState, useRef} from 'react'
import {Row, Col} from 'react-bootstrap'
import {useNavigate} from 'react-router-dom'
import backArrow from '../../../img/back-arrow.png'
import addWhite from '../../../img/add-white.png'
import './style.scss'
import downGreen from '../../../img/down-arrow-green.png'
import upGreen from '../../../img/up-arrow-green.png'
import crossBtn from '../../../img/cross-button.png'
import redCross from '../../../img/remove.png'
import {Tabs, Tab, Paper} from '@mui/material'
import LeasingCommercial from './LeasingCommercial'
import LeasingResidential from './LeasingResedential'
import Tenants from './TenantsTab'
import AddFeature from './AddFeatures'
import PropertyPortfolio from './PropertyPortfolio'
import Maintenance from './Maintenance'
import Communications from './Communications'
import AddUsersModal from './AddUsersModal'
import profileWhite from '../../../img/profile-white.png'
import {ApiPost} from '../../../apiCommon/helpers/API/ApiData'
import {Controller, useForm} from 'react-hook-form'
import {SuccessToast} from '../../../apiCommon/helpers/Toast'
import HelpAndSupport from './HelpAndSupport'
import AccountManagement from './AccountManagement'
import UserGuide from './UserGuide'

const AddRole = () => {
  const {
    register,
    control,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    clearErrors,
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const navigate = useNavigate()
  const [roleData, setRoleData] = useState<any>({
    name: '',
    description: '',
    featureList: {
      leasing_residential: {
        lease_agreements: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          tenant_detail: {
            view_details: false,
            create: false,
            update: false,
          },
          contract_detail: {
            view_details: false,
            create: false,
            update: false,
          },
          isRemoved: false,
        },
        renewals: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          isRemoved: false,
        },
        terminations: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          isRemoved: false,
        },
        cancellation: {
          allow: false,
          isRemoved: false,
        },
        lease_deletion: {
          allow: false,
          isRemoved: false,
        },
        lease_financial: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          isRemoved: false,
          invoicing: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          receipts: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          refunds: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          credit_notes: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          debit_notes: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          items: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          vat: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          late_payment_fees: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          payment_method: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          payment_reminders: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          attachments: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          notes: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
        },
      },
      user_guide: {
        user_guide: true,
      },
      help_support: {
        support_ticket: true,
      },
      account_management: {
        two_step_verification: true,
        reset_password: true,
        reset_email_id: true,
        themSelection: false,
        editPersonalDetails: false,
      },
    },
    userIds: [],
  })

  const [nameError, setNameError] = useState<boolean>(false)
  const [descriptionCount, setDescriptionCount] = useState<any>(`0/50`)
  const [descriptionError, setDescriptionError] = useState<boolean>(false)
  const [wordCountError, setWordCountError] = useState<boolean>(false)
  const [roleUsers, setRoleUsers] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  var usersSelected: any = 0

  // const countWords = (text: string): number => {
  //   // Split the text by spaces and filter out empty strings
  //   const words = text.trim().split(/\s+/).filter(Boolean)
  //   return words.length
  // }

  // const wordCount = countWords(roleData.description)
  // const maxWords = 50

  const [backup, setBackup] = useState<any>({
    name: '',
    description: '',
    featureList: {
      leasing_residential: {
        lease_agreements: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          tenant_detail: {
            view_details: false,
            create: false,
            update: false,
          },
          contract_detail: {
            view_details: false,
            create: false,
            update: false,
          },
        },
        renewals: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
        },
        terminations: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
        },
        cancellation: {
          allow: false,
        },
        lease_deletion: {
          allow: false,
        },
        lease_financial: {
          view_list: false,
          view_details: false,
          create: false,
          update: false,
          delete: false,
          approve: false,
          invoicing: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          receipts: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          refunds: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          credit_notes: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          debit_notes: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          items: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          vat: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          late_payment_fees: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          payment_method: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          payment_reminders: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          attachments: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
          notes: {
            view_list: false,
            view_details: false,
            create: false,
            update: false,
            delete: false,
          },
        },
      },
      user_guide: {
        user_guide: false,
      },
      help_support: {
        support_ticket: false,
      },
      account_management: {
        two_step_verification: false,
        reset_password: false,
        reset_email_id: false,
        themSelection: false,
        editPersonalDetails: false,
      },
    },
    userIds: [],
  })

  localStorage.setItem('roleDataBackup', JSON.stringify(backup))

  const [hide, setHide] = React.useState<any>(true)
  const [tabValue, setTabValue] = useState('Account Management')
  const [tabName, setTabName] = useState('Account Management')
  const [addFeatureModal, setAddFeatureModal] = useState<any>(false)
  const contentRef = useRef<HTMLDivElement | null>(null)
  const [addUser, setAddUser] = useState<any>(false)

  const [tabs, setTabs] = useState<any>([
    {
      name: 'Account Management',
      value: 0,
    },
    {
      name: 'Help & Support',
      value: 1,
    },
    {
      name: 'User Guide',
      value: 2,
    },
  ])

  const [sortedTabs, setSortedTabs] = useState<any>([])

  const handleChange = (event: any, newValue: any) => {
    setTabValue(newValue)
    setTabName(event.target.innerText)
    if (contentRef.current) {
      contentRef.current.scrollTop = 0
    }
  }

  const addRole = () => {
    let nameErr: any = roleData?.name?.trim()?.length == 0
    // let descErr: any = roleData?.description?.trim()?.length == 0
    // let countErr: any = roleData?.description?.length < 500

    if (!nameErr) {
      setIsLoading(true)
      let userIds: any = []
      roleUsers?.map((user: any, ind: any) => {
        userIds[ind] = user?._id
      })

      roleData.features = undefined
      if (roleData?.featureList?.leasing_residential?.obj != undefined)
        roleData.featureList.leasing_residential.obj = undefined
      roleData.userIds = userIds

      const body = roleData
      ApiPost('corporate/role_permission', body)
        .then((res: any) => {
          SuccessToast(res?.data?.message)
          localStorage.removeItem('roleData')
          localStorage.removeItem('roleDataBackup')
          setIsLoading(false)

          navigate(-1)
        })
        .catch(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
      if (nameErr) setNameError(true)
      // if (descErr) setDescriptionError(true)
      // else if (countErr) setWordCountError(true)
    }
  }

  // const handleChangeRoleData = (e: any) => {
  //   setRoleData({
  //     ...roleData,
  //     [e.target.name]: e.target.value,
  //   })
  // }

  useEffect(() => {
    let values = tabs.slice().sort((a: any, b: any) => a.name.localeCompare(b.name))
    setSortedTabs(values)
  }, [tabs])
  return (
    <>
      <div id='' className='pt-0 mt-0 px-2 ps-1'>
        <form onSubmit={handleSubmit(addRole)}>
          <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'>
            <div className='d-flex align-items-center gap-3'>
              <span
                className='rounded-circle'
                style={{cursor: 'pointer'}}
                onClick={() => {
                  localStorage.removeItem('roleData')
                  localStorage.removeItem('roleDataBackup')
                  navigate('/settings/roles-and-permissions')
                }}
              >
                <img src={backArrow} height='14px' width='14px' />
              </span>
              <h2 className='page-heading m-0 head-text'>Add a Role</h2>
            </div>
            <div className='d-flex ms-auto'>
              <button
                className='btn btn-sm fw-bold  me-0 green-submit-btn ps-3 pe-10'
                type='submit'
                style={{width: 'fit-content'}}
                disabled={isLoading}
              >
                <img src={addWhite} height={17} width={17} style={{marginRight: '10px'}} /> Add Role
              </button>
            </div>
          </div>
          <div className='d-flex align-items-center gap-2 mb-5 gap-lg-3'></div>
          <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3'>
            <div className='card-body pt-0 mt-1 py-3 px-3'>
              <Row className='my-5'>
                <Col md={7} lg={7} className='px-7 mb-12 ps-4'>
                  <div className='d-flex'>
                    <h5 className='my-2' style={{color: 'black'}}>
                      <label style={{width: '100px'}} className='labl-gry required'>
                        {' '}
                        Role Name
                      </label>
                    </h5>
                    <input
                      // {...register('name', {required: true})}
                      type='text'
                      className='form-control form-control-solid mytest tenancy-control'
                      placeholder='Enter a role name...'
                      name='name'
                      value={roleData?.name}
                      onChange={(e: any) => {
                        const values = {...roleData}
                        values.name = e.target.value
                        setRoleData(values)
                        setNameError(false)
                      }}
                      // onInput={() => {
                      //   clearErrors('name');
                      // }}
                      onBlur={() => {}}
                      style={{height: '35px', width: '50%'}}
                    />{' '}
                  </div>
                  {nameError && (
                    <div className='d-flex mb-0'>
                      {' '}
                      <label style={{width: '100px'}} className='labl-gry'>
                        {' '}
                      </label>
                      <p
                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                        className='m-0 mt-1'
                      >
                        This field is required
                      </p>
                    </div>
                  )}
                  <h5 className='my-2 mt-10' style={{color: 'black'}}>
                    <label className='labl-gry'> Role Description</label>
                  </h5>
                  <textarea
                    // {...register('description', {
                    //   required: true,
                    //   // pattern: {
                    //   //   value: /^(\w+\s*){50,}$/, // Regex to match at least 50 words
                    //   //   message: 'Description must contain at least 50 words',
                    //   // },
                    // })}
                    className='form-control form-control-solid mytest'
                    placeholder='Give the role a description...'
                    name='description'
                    value={roleData?.description}
                    onChange={(e: any) => {
                      const values = {...roleData}
                      values.description = e.target.value
                      setRoleData(values)
                      setDescriptionError(false)

                      const target = e.target
                      // Get the `maxlength` attribute
                      const maxLength = target.getAttribute('maxlength')
                      // Count the current number of characters
                      const currentLength = target.value.length

                      setDescriptionCount(`${currentLength}/${maxLength}`)

                      if (currentLength < 50) setWordCountError(false)
                    }}
                    maxLength={50}
                    // onInput={() => {
                    //   clearErrors('description');
                    //   console.log(errors)
                    // }}
                    style={{height: '210px'}}
                  />{' '}
                  <div className=' d-flex'>
                    {/* {descriptionError && (
                      <p
                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                        className='m-0 mt-1'
                      >
                        This field is required
                      </p>
                    )} */}
                    {/* {wordCountError && (
                      <p
                        style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                        className='m-0 mt-1'
                      >
                        Description contain max 50 characters
                      </p>
                    )} */}
                    <p className='ms-auto light-dark-theme-color'>
                      {/* Word Count: {wordCount}/{maxWords} */}
                      {descriptionCount}
                    </p>
                  </div>
                </Col>
                <Col md={5} lg={5} className='pe-4 mb-12'>
                  <div className='d-flex mb-5 container-xxl'>
                    <h2 className='page-heading m-0 head-text pt-3'>Users</h2>
                    <div className='d-flex ms-auto'>
                      <button
                        type='button'
                        className='btn btn-sm fw-bold  me-0 select-btn ps-3 pe-10'
                        onClick={() => {
                          setAddUser(true)
                        }}
                        style={{width: 'fit-content'}}
                        disabled={isLoading}
                      >
                        <img
                          src={profileWhite}
                          height={18}
                          width={18}
                          style={{marginLeft: '3px'}}
                          className='me-4'
                        />
                        Add
                      </button>
                    </div>
                  </div>

                  {roleUsers?.map((user: any, ind: any) => {
                    if (user?.check == true) usersSelected = usersSelected + 1
                    return <></>
                  })}

                  <div
                    className={
                      hide
                        ? 'card card-flush py-5 container-xxl mt-2 user-card-collapse px-5'
                        : 'card card-flush py-5 container-xxl mt-2 min-card px-5'
                    }
                    style={{maxHeight: '310px', overflowY: 'auto'}}
                  >
                    <div className='d-flex  align-items-center mt-2' style={{height: '25px'}}>
                      <p className='user-head mb-2 white-dark-theme-color' style={{fontWeight: '500'}}>
                        <i>
                          Total Users <b>{roleUsers?.length}</b>
                        </i>
                      </p>
                      <div className='ms-auto'>
                        <p className='user-head mb-2 white-dark-theme-color' style={{fontWeight: '500'}}>
                          <i>
                            Total Selected <b>{usersSelected}</b>
                          </i>
                        </p>
                      </div>
                    </div>

                    <div className='table-responsive '>
                      <table
                        className='table align-middle table-row-dashed fs-8 gy-5 '
                        id='kt_ecommerce_sales_table'
                      >
                        <thead>
                          <th className='text-start min-w-25px test'>
                            <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='vacant0'
                                name='none'
                                checked={
                                  usersSelected == roleUsers?.length && roleUsers?.length != 0
                                    ? true
                                    : false
                                }
                                onChange={(e: any) => {
                                  let values: any = [...roleUsers]
                                  if (e.target.checked) {
                                    values?.map((user: any) => {
                                      user.check = true
                                    })
                                  } else {
                                    values?.map((user: any) => {
                                      user.check = false
                                    })
                                  }
                                  setRoleUsers(values)
                                }}
                              />
                            </div>
                          </th>

                          <th className='text-start min-w-150px'>Users</th>
                          <th className='text-end min-w-75px'>
                            {usersSelected > 0 && (
                              <a
                                className='btn btn-sm fw-bold  ms-3 remove-btn ps-2 ms-auto'
                                onClick={() => {
                                  // let values = [...roleUsers]

                                  // values?.map((user:any, ind:any)=>{
                                  //   if(user.check == true){
                                  //    values.splice(ind, 1)
                                  //   }
                                  //   console.log(values)
                                  // })
                                  // setRoleUsers(values)
                                  let values = [...roleUsers]
                                  // Filter out the users with checkboxes checked
                                  const filteredUsers = values.filter((user) => !user.check)
                                  setRoleUsers(filteredUsers)
                                }}
                              >
                                <img src={crossBtn} height={18} width={18} className='me-4' />{' '}
                                Remove
                              </a>
                            )}
                          </th>
                        </thead>
                        <tbody className='fw-semibold text-gray-600'>
                          {roleUsers?.length > 0 &&
                            roleUsers?.map((user: any, ind: any) => {
                              return (
                                <>
                                  <tr key={ind}>
                                    <td className='text-start ps-0 test'>
                                      <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                        <input
                                          className='form-check-input'
                                          type='checkbox'
                                          id='vacant0'
                                          name='none'
                                          checked={roleUsers[ind]?.check}
                                          onChange={(e: any) => {
                                            console.log(e.target.checked)
                                            const values = [...roleUsers]
                                            values[ind].check = e.target.checked
                                            setRoleUsers(values)
                                          }}
                                        />
                                      </div>
                                    </td>
                                    <td className='text-start ps-0'>
                                      <div className='d-flex head-text'>
                                        <p className='user-head pe-2 mb-2'>
                                          {' '}
                                          {user?.firstName} {user?.lastName}{' '}
                                        </p>
                                        <p className='role-text mb-2'>
                                          <i> ({user?.email})</i>
                                        </p>
                                      </div>
                                    </td>
                                    <td className='text-end ps-0'>
                                      <img
                                        src={redCross}
                                        height={16}
                                        width={16}
                                        className='me-4 cursor-pointer'
                                        onClick={() => {
                                          let values = [...roleUsers]
                                          values.splice(ind, 1)
                                          setRoleUsers(values)
                                        }}
                                      />
                                    </td>
                                  </tr>
                                </>
                              )
                            })}

                          {/* <tr>
                            <td className='text-start ps-0 test'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={true}
                                  onChange={(e: any) => {}}
                                />
                              </div>
                            </td>
                            <td className='text-start ps-0'>
                              <div className='d-flex head-text'>
                                <p className='user-head pe-2 mb-2'> Waqar Khan </p>
                                <p className='role-text mb-2'>
                                  <i>(Waqar.khan@email.com)</i>
                                </p>
                              </div>
                            </td>

                            <td className='text-end ps-0'>
                              <img src={redCross} height={18} width={18} className='me-4' />
                            </td>
                          </tr>
                          <tr>
                            <td className='text-start ps-0 test'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-5'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={true}
                                  onChange={(e: any) => {}}
                                />
                              </div>
                            </td>
                            <td className='text-start ps-0'>
                              <div className='d-flex head-text'>
                                <p className='user-head pe-2 mb-2'> Ketan Vagh </p>
                                <p className='role-text mb-2'>
                                  <i>(Ketan.vagh@email.com)</i>
                                </p>
                              </div>
                            </td>

                            <td className='text-end ps-0'>
                              <img src={redCross} height={18} width={18} className='me-4' />
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>

                    <div className='ms-auto mt-auto' style={{height: '30px'}}>
                      <div
                        className='br-arr d-flex align-items-center justify-content-center mt-3'
                        // style={{transform: 'translate(0px, -25px)'}}
                      >
                        {hide === false ? (
                          <img
                            src={upGreen}
                            height={20}
                            width={20}
                            className='cursor-pointer'
                            onClick={() => {
                              setHide(true)
                            }}
                          />
                        ) : (
                          <img
                            src={downGreen}
                            height={20}
                            width={20}
                            className='cursor-pointer'
                            onClick={() => {
                              setHide(false)
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className='my-5'>
                <Col md={12} lg={12} className='px-6'>
                  <div className='d-flex mb-5'>
                    <h2 className='page-heading m-0 head-text pt-3'>Features</h2>
                    <div className='d-flex ms-auto'>
                      <button
                        type='button'
                        className='btn btn-sm fw-bold  me-0 select-btn ps-3 pe-10'
                        onClick={() => {
                          localStorage.setItem('roleData', JSON.stringify(roleData))
                          localStorage.setItem('actveTab', tabValue)
                          setAddFeatureModal(true)
                        }}
                        style={{width: 'fit-content'}}
                        disabled={isLoading}
                      >
                        <img src={addWhite} height={17} width={17} style={{marginRight: '10px'}} />{' '}
                        Add
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className='card card-flush gap-5 p-5 pt-0 pb-0 mt-5 px-0 card-onboard-shad mx-2'>
                <div className='card-body pt-0 mt-0 py-3 px-0 pb-0'>
                  <Row>
                    <Col md={2} className='test'>
                      <Paper style={{display: 'flex', height: '100%'}}>
                        <Tabs
                          orientation='vertical'
                          // variant='scrollable'
                          value={tabValue}
                          onChange={handleChange}
                          TabIndicatorProps={{
                            style: {
                              display: 'none', // This will hide the indicator line
                            },
                          }}
                          sx={{
                            borderRight: `1px solid divider`,
                            paddingTop: '15px',
                            paddingBottom: '20px',

                            '& .Mui-selected': {
                              fontWeight: 'bold',
                              textDecoration: 'underline',
                              border: 'none',
                              color: '#000',
                            },
                          }}
                        >
                          {sortedTabs.map((tab: any, index: any) => {
                            return (
                              <Tab
                                value={tab?.name}
                                label={tab?.name}
                                sx={{
                                  fontWeight: tabValue === index ? 'bold' : 'normal',
                                  textDecoration: tabValue === index ? 'underline' : 'none',
                                  border: 'none',
                                  color: '#000',
                                }}
                              />
                            )
                          })}

                          {/* <Tab
                            label='Leasing (Residential)'
                            sx={{
                              fontWeight: tabValue === 0 ? 'bold' : 'normal',
                              textDecoration: tabValue === 0 ? 'underline' : 'none',
                              border: 'none',
                              color: '#000',
                            }}
                          />
                          <Tab
                            label='Leasing (Commercial)'
                            sx={{
                              fontWeight: tabValue === 1 ? 'bold' : 'normal',
                              textDecoration: tabValue === 1 ? 'underline' : 'none',
                              border: 'none',
                              color: '#000',
                            }}
                          /> 
                          <Tab
                            label='Tenants'
                            sx={{
                              fontWeight: tabValue === 2 ? 'bold' : 'normal',
                              textDecoration: tabValue === 2 ? 'underline' : 'none',
                              border: 'none',
                              color: '#000',
                            }}
                          />
                          <Tab
                            label='Property Portfolio'
                            sx={{
                              fontWeight: tabValue === 3 ? 'bold' : 'normal',
                              textDecoration: tabValue === 3 ? 'underline' : 'none',
                              border: 'none',
                              color: '#000',
                            }}
                          />
                          <Tab
                            label='Maintenance'
                            sx={{
                              fontWeight: tabValue === 4 ? 'bold' : 'normal',
                              textDecoration: tabValue === 4 ? 'underline' : 'none',
                              border: 'none',
                              color: '#000',
                            }}
                          />
                          <Tab
                            label='Communications'
                            sx={{
                              fontWeight: tabValue === 5 ? 'bold' : 'normal',
                              textDecoration: tabValue === 5 ? 'underline' : 'none',
                              border: 'none',
                              color: '#000',
                            }}
                          />
                          <Tab
                            label='Help & Support'
                            sx={{
                              fontWeight: tabValue === 6 ? 'bold' : 'normal',
                              textDecoration: tabValue === 6 ? 'underline' : 'none',
                              border: 'none',
                              color: '#000',
                            }}
                          />
                          <Tab
                            label='Account Management'
                            sx={{
                              fontWeight: tabValue === 7 ? 'bold' : 'normal',
                              textDecoration: tabValue === 7 ? 'underline' : 'none',
                              border: 'none',
                              color: '#000',
                            }}
                          />
                          <Tab
                            label='User Guide'
                            sx={{
                              fontWeight: tabValue === 8 ? 'bold' : 'normal',
                              textDecoration: tabValue === 8 ? 'underline' : 'none',
                              border: 'none',
                              color: '#000',
                            }}
                          />
                          <Tab
                            label='Data Export'
                            sx={{
                              fontWeight: tabValue === 9 ? 'bold' : 'normal',
                              textDecoration: tabValue === 9 ? 'underline' : 'none',
                              border: 'none',
                              color: '#000',
                            }} 
                          />*/}
                        </Tabs>
                        {/* Content for each tab */}
                      </Paper>
                    </Col>
                    <Col md={10} className='px-0'>
                      <div
                        style={{flex: 1, padding: '10px 0px'}}
                        className='feature-tab-details'
                        ref={contentRef}
                      >
                        {(tabName === 'Leasing (Residential)' && roleData) && (
                          <LeasingResidential
                            roleData={roleData}
                            from={'add-view'}
                            updateRoleDate={(val: any) => {
                              setRoleData(val)
                            }}
                          />
                        )}
                        {(tabName === 'Leasing Commercial' && roleData) && <LeasingCommercial />}
                        {tabName === 'Tenants' && <Tenants />}
                        {tabName === 'PropertyPortfolio' && <PropertyPortfolio />}
                        {tabName === 'Maintenance' && <Maintenance />}
                        {tabName === 'Communications' && <Communications />}
                        {(tabName === 'Help & Support' && roleData) && (
                          <HelpAndSupport
                            roleData={roleData}
                            from={'add-view'}
                            updateRoleDate={(val: any) => {
                              setRoleData(val)
                            }}
                          />
                        )}
                        {(tabName === 'Account Management' && roleData) && (
                          <AccountManagement
                            roleData={roleData}
                            from={'add-view'}
                            updateRoleDate={(val: any) => {
                              setRoleData(val)
                            }}
                          />
                        )}
                        {(tabName === 'User Guide' && roleData) && (
                          <UserGuide
                            roleData={roleData}
                            from={'add-view'}
                            updateRoleDate={(val: any) => {
                              setRoleData(val)
                            }}
                          />
                        )}
                        {tabName === 'Data Export' && <div>{'Data Export'}</div>}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* Add Feature Modal */}
      {(addFeatureModal && roleData) && (
        <AddFeature
          show={addFeatureModal}
          handleClose={(val: any) => { 
            let temp = localStorage.getItem('roleData')
            if (temp != null) {
              let copy = JSON.parse(temp)
              setRoleData(copy)
            }

            const values = [...tabs] 
            
            setTabValue(values[0]?.name)
            setTabName(values[0]?.name)

            setTimeout(() => {
              let active: any = localStorage?.getItem('actveTab')
              if (active != null) {
                setTabValue(active)
                setTabName(active)
              }
              setAddFeatureModal(false)
            }, 300)

            // if (val) {
            //   setTimeout(() => {
            //     setTabName('Leasing (Residential)')
            //     setAddFeatureModal(false)
            //   }, 300)
            // } else {
            //   setTabName('Help & Support')
            //   setAddFeatureModal(false)
            // }
          }}
          role={roleData}
          from={'add'}
          updateTabData={(val: any, data: any) => {
            const isInc = tabs.some((tab: any) => tab.name === 'Leasing (Residential)')
            setRoleData(data)

            const values = [...tabs]

            if (val == 'residential' && !isInc) {
              values[values.length] = {
                name: 'Leasing (Residential)',
                value: values.length,
              }
              setTabs(values)
            } else if (val == 'residential' && isInc) {
            } else if (val == '' && isInc) {
              values.splice(values.length - 1, 1)
              setTabs(values)
            }

            setTimeout(() => {
              let active: any = localStorage?.getItem('actveTab')
              if (active != null) {
                const inclTab = values.some((tab: any) => tab.name === active)
                if(inclTab){
                setTabValue(active)
                setTabName(active)
                }
                else{
                  setTabValue(values[0]?.name)
                  setTabName(values[0]?.name)
                }
              }
              setAddFeatureModal(false)
            }, 300)
          }}
        />
      )}

      {/* Add User Modal */}
      {addUser && (
        <AddUsersModal
          show={addUser}
          handleClose={() => setAddUser(false)}
          updateUsers={(data: any) => {
            console.log(data)
            const values: any = []
            let j = 0
            for (let i = 0; i < data?.length; i++) {
              if (data[i]?.check) {
                values[j] = data[i]
                values[j].check = false
                j++
              }
            }
            setRoleUsers(values)
            setAddUser(false)
          }}
          addedUsers={roleUsers}
          from={'add-role'}
          previousIds={''}
        />
      )}
    </>
  )
}

export default AddRole
