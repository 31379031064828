import {Modal} from 'react-bootstrap'

interface ShowMoreI {
  show: boolean
  onHide: any
  headingText: string
  selectedData: any
}

const ShowMore = ({show, onHide, headingText, selectedData}: ShowMoreI) => {
  return (
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-450px mx-auto'
      show={show}
      onHide={() => {
        onHide()
      }}
      backdrop={true}
    >
      <div
        className='modal-body p-0 modal-body-without-header px-7 pb-5'
        style={{minHeight: '120px'}}
      >
        <div
          className='row px-6 py-5 m-0 pb-3 pe-2'
          style={{position: 'sticky', top: 0, background: 'transparent', zIndex: 2}}
        >
          <div className={`col-12 px-0`}>
            <div className='d-flex justify-content-end'>
              <label
                className='head-text fs-5 cursor-pointer blue-dark-text fnt-700'
                onClick={() => {
                  onHide()
                }}
              >
                X
              </label>
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-start px-0'>
          <h4 className='text-dark white-dark-theme-color mb-2'>
            <b>Selected {headingText}:</b>
          </h4>
        </div>

        <div className='px-0'>
          <p className='my-3 text-start text-dark white-dark-theme-color fs-5 fnt-400 mb-4'>
            {selectedData
              ?.map((bd: any) => bd.label)
              .sort()
              .join(', ')}
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default ShowMore
