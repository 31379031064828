import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import FlexedInput from '../../../../../../components/FlexedInput';
import DatePicker from 'antd/es/date-picker';
import moment from 'moment';
import { formatAmount } from '../../../../../../../../../Utilities/utils';
import sync from '../../../../../../../../../img/refreshIcon.png';
import SecurityDepositDisplay from './common/SecurityDepositDisplay';

interface ParentData {
  chrgData: any;
  setChrgData: any;
  isChrgDataChanged?: any;
}

const FixedBasedBookingFeePreview = ({ chrgData, setChrgData, isChrgDataChanged }: ParentData) => {
  const [previewCalculations, setPreviewCalculations] = useState<any>(false);
  const [showPreviewCharges, setShowPreviewCharges] = useState<any>(false);
  const [isFieldModified, setIsFieldModified] = useState(false);

  const pickerKey = Date.now()

  const isCreditFromSecurityDeposit = chrgData.creditOptionType[0]?.value === 0;
  const isCreditFromRentCharges = chrgData.creditOptionType[0]?.value === 1;

  // basic validation for preview section visibility
  const showInputPreviewSection =
    chrgData?.amountCalculationType === 0 &&
    chrgData?.fixedAmount != null &&
    chrgData?.VATPercentage != null &&
    chrgData?.autoInvoicingEnabled?.length > 0 &&
    chrgData?.invoicingType != null &&
    chrgData?.creditOptionType?.length > 0;

  const calculatePreviewAmounts = () => {
    const updatedChrgData = { ...chrgData };

    if (!updatedChrgData.previewData.totalValue) return;

    // calculating VAT and total amount
    updatedChrgData.previewData.bookingFee = Number(updatedChrgData.fixedAmount);
    updatedChrgData.previewData.VATRate =
      (Number(updatedChrgData.fixedAmount) * Number(updatedChrgData.VATPercentage)) / 100;
    updatedChrgData.previewData.totalAmount =
      Number(updatedChrgData.previewData.bookingFee) + Number(updatedChrgData.previewData.VATRate);

    // handling credit calculations
    if (isCreditFromSecurityDeposit) {
      updatedChrgData.previewData.creditSource = 'Security Deposit';
      updatedChrgData.previewData.securityDepositAmount =
        (Number(updatedChrgData.previewData.totalValue) * Number(updatedChrgData.previewData.securityDepositValue)) / 100;

      // checking if booking fee exceeds security deposit
      if (updatedChrgData.previewData.bookingFee > updatedChrgData.previewData.securityDepositAmount) {
        // credit entire security deposit
        updatedChrgData.previewData.creditAmount = updatedChrgData.previewData.securityDepositAmount;
        updatedChrgData.previewData.outstandingAmount = 0;

        // calculate remaining amount to be credited from rent
        const remainingCredit =
          updatedChrgData.previewData.bookingFee - updatedChrgData.previewData.securityDepositAmount;

        updatedChrgData.previewData.showRentCredit = true;
        updatedChrgData.previewData.rentCreditAmount = remainingCredit;
        updatedChrgData.previewData.rentOutstanding =
          updatedChrgData.previewData.totalValue - remainingCredit;
      } else {
        // normal case where security deposit covers booking fee
        updatedChrgData.previewData.creditAmount = updatedChrgData.previewData.bookingFee;
        updatedChrgData.previewData.outstandingAmount =
          updatedChrgData.previewData.securityDepositAmount - updatedChrgData.previewData.bookingFee;
        updatedChrgData.previewData.showRentCredit = false;
      }
    } else if (isCreditFromRentCharges) {
      // direct rent charges credit
      updatedChrgData.previewData.creditSource = 'Rent Charges';

      if (updatedChrgData.previewData.noOfInstallments) {
        const installmentAmount =
          updatedChrgData.previewData.totalValue / updatedChrgData.previewData.noOfInstallments;

        let remainingCredit = updatedChrgData.previewData.bookingFee;
        const creditedInstallments = [];
        let currentInstallment = 1;

        while (remainingCredit > 0 &&
          currentInstallment <= updatedChrgData.previewData.noOfInstallments) {
          const creditForThisInstallment = Math.min(remainingCredit, installmentAmount);

          creditedInstallments.push({
            installmentNumber: currentInstallment,
            amount: installmentAmount,
            credited: creditForThisInstallment,
            outstanding: installmentAmount - creditForThisInstallment
          });

          remainingCredit -= creditForThisInstallment;
          currentInstallment++;
        }

        updatedChrgData.previewData.creditedInstallments = creditedInstallments;
      }
    }

    setChrgData(updatedChrgData);
  };

  const handleInputChange = (field: string, value: any) => {
    const updatedChrgData = { ...chrgData };
    // Update the specific field
    if (field === 'totalValue') {
      updatedChrgData.previewData.totalValue = value;
      if (updatedChrgData.previewData.securityDepositValue) {
        updatedChrgData.previewData.securityDepositAmount =
          (Number(value) * Number(updatedChrgData.previewData.securityDepositValue)) / 100;
      }
    } else if (field === 'securityDepositValue') {
      updatedChrgData.previewData.securityDepositValue = value;
      if (updatedChrgData.previewData.totalValue) {
        updatedChrgData.previewData.securityDepositAmount =
          (Number(updatedChrgData.previewData.totalValue) * Number(value)) / 100;
      }
    } else if (field === 'noOfInstallments') {
      updatedChrgData.previewData.noOfInstallments = value;
    } else if (field === 'startDate') {
      updatedChrgData.previewData.startDate = value;
    }
    setPreviewCalculations(Date.now());
    setChrgData(updatedChrgData);
    setShowPreviewCharges(false);
    setIsFieldModified(true);
  };

  const handlePreviewClick = () => {
    calculatePreviewAmounts();
    setShowPreviewCharges(true);
    setIsFieldModified(false);
  };


  // resetting preview data when charge data changes
  useEffect(() => {
    const updatedChrgData = { ...chrgData };
    updatedChrgData.previewData = {
      ...updatedChrgData.previewData,
      totalValue: null,
      securityDepositValue: null,
      securityDepositAmount: null,
      bookingFee: null,
      VATRate: null,
      totalAmount: null,
      creditAmount: null,
      creditSource: null,
      outstandingAmount: null,
      startDate: null,
      noOfInstallments: null,
      creditedInstallments: null
    };
    setChrgData(updatedChrgData);
    setShowPreviewCharges(false);
  }, [chrgData.isChrgDataChanged]);

  const resetPreview = () => {
    const updatedChrgData = { ...chrgData };
    updatedChrgData.previewData = {
      ...updatedChrgData.previewData,
      totalValue: null,
      securityDepositValue: null,
      securityDepositAmount: null,
      bookingFee: null,
      VATRate: null,
      totalAmount: null,
      creditAmount: null,
      creditSource: null,
      outstandingAmount: null,
      startDate: null,
      noOfInstallments: null,
      creditedInstallments: null,
      rentCreditAmount: null,
      rentOutstanding: null,
      showRentCredit: false
    };
    setChrgData(updatedChrgData);
    setShowPreviewCharges(false);
    setIsFieldModified(false);
  };

  useEffect(() => {
    if (previewCalculations) {
      calculatePreviewAmounts();
    }
  }, [previewCalculations]);

  return (
    <div className="card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3 card-grey h-100">
      <div className="card-body pt-0 mt-5 py-0 px-3 d-flex flex-column">
        <div className="flex-grow-1">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className="page-heading m-0 white-dark-theme-color fnt-700">Preview Charges</h3>
            <div
              className="filter-div d-flex align-items-center justify-content-center cursor-pointer ms-auto"
              onClick={resetPreview}
              style={{ zIndex: '20' }}
            >
              <img src={sync} height={18} width={18} alt="refresh" />
            </div>
          </div>
          <p className="pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color mb-10" style={{ color: '#a4a4a4' }}>
            Fill out the settings below and click preview to view how the charges will apply
          </p>

          {showInputPreviewSection ? (
            <>
              <Row className="d-block charge-preview">
                {/* Total Rent Value */}
                <Col className="pe-0" sm={12}>
                  <FlexedInput
                    isDisabled={false}
                    iconSize={30}
                    required={false}
                    setRightPosition="10px"
                    type="number"
                    name="totalRentValue"
                    value={chrgData?.previewData?.totalValue || ''}
                    label="Total Rent Value"
                    firstPlaceholder="Enter Amount"
                    secondPlaceHolder="AED"
                    onChange={(newValue: any) => handleInputChange('totalValue', newValue)}
                  />
                </Col>

                {/* Security Deposit or No. of Installments */}
                {isCreditFromSecurityDeposit ? (
                  <Col className="pe-0 mt-5" sm={12}>
                    <FlexedInput
                      isDisabled={false}
                      iconSize={30}
                      required={false}
                      setRightPosition="10px"
                      type="number"
                      name="securityDepositValue"
                      value={chrgData?.previewData?.securityDepositValue || ''}
                      label="Security Deposit"
                      isPercent={true}
                      firstPlaceholder="Enter Percentage"
                      secondPlaceHolder="%"
                      onChange={(newValue: any) => handleInputChange('securityDepositValue', newValue)}
                    />
                    <SecurityDepositDisplay chrgData={chrgData} />
                  </Col>
                ) : (
                  <Col className="pe-0 mt-5" sm={12}>
                    <FlexedInput
                      isDisabled={false}
                      iconSize={30}
                      required={false}
                      setRightPosition="10px"
                      type="number"
                      name="noOfInstallments"
                      maxValue={12}
                      value={chrgData?.previewData?.noOfInstallments || ''}
                      label="No. of Installments"
                      firstPlaceholder="Enter Installments"
                      secondPlaceHolder=""
                      onChange={(newValue: any) => handleInputChange('noOfInstallments', newValue)}
                    />
                  </Col>
                )}

                {/* Booking Date */}
                <Col className="mt-5 pe-0" sm={12}>
                  <div className="d-flex align-items-center">
                    <div className="percentage-label-container">
                      <label className="percentage-input-label white-dark-theme-color fnt-500 status-w-130">
                        Booking Date
                      </label>
                    </div>
                    <DatePicker
                      key={pickerKey}
                      className={`w-100 ${chrgData?.previewData?.startDate ? 'isFilled' : ''}`}
                      value={chrgData?.previewData?.startDate ?
                        moment(chrgData.previewData.startDate, 'YYYY-MM-DD') : undefined}
                      format="DD.MM.YYYY"
                      onChange={(date: any) => handleInputChange('startDate',
                        date ? date.format('YYYY-MM-DD') : null)}
                      placeholder="Select Date"
                      defaultPickerValue={moment()}
                    />
                  </div>
                </Col>

                {/* Booking Fee and VAT Display */}
                <Col className="mt-5 pt-10 pe-0" sm={12}>
                  <div className="d-flex align-items-center w-100">
                    <div className="percentage-label-container w-50">
                      <label className="percentage-input-label white-dark-theme-color fnt-500 status-w-130">
                        Booking Fee
                      </label>
                    </div>
                    <label className="fnt-500 light-dark-theme-color w-50 text-end">
                      {chrgData?.fixedAmount != null ?
                        formatAmount(chrgData.fixedAmount) : '-'} AED
                    </label>
                  </div>

                  {/* Show VAT when both fee and VAT percentage are available */}
                  {chrgData?.fixedAmount != null && chrgData?.VATPercentage != null && (
                    <div className="d-flex align-items-center w-100 mt-5">
                      <div className="percentage-label-container w-50">
                        <label className="percentage-input-label white-dark-theme-color fnt-500 status-w-130">
                          VAT Rate
                        </label>
                      </div>
                      <label className="fnt-500 light-dark-theme-color w-50 text-end">
                        {formatAmount((Number(chrgData.fixedAmount) * Number(chrgData.VATPercentage)) / 100)} AED
                      </label>
                    </div>
                  )}
                </Col>
              </Row>

              {/* Preview Button */}
              <button
                type="button"
                className="btn btn-sm fw-bold px-2 mx-auto justify-content-center blue-btn status-w-200 mt-10"
                onClick={() => handlePreviewClick()}
                disabled={!chrgData?.previewData?.startDate ||
                  !chrgData?.previewData?.totalValue ||
                  (isCreditFromSecurityDeposit && !chrgData?.previewData?.securityDepositValue) ||
                  (isCreditFromRentCharges && !chrgData?.previewData?.noOfInstallments) ||
                  (!isFieldModified && showPreviewCharges)}
              >
                Preview Charges
              </button>

              {/* Charges Preview Section */}
              {showPreviewCharges && (
                <>
                  {/* Charge Section */}
                  <div className="mt-8">
                    <h4 className="fnt-600 mb-4 text-decoration-underline">1. Charge</h4>
                    <div className="d-flex justify-content-between mb-2">
                      <div>
                        <div className="fnt-600">Booking Fee</div>
                        <div className="fnt-400">
                          Applied: {moment(chrgData.previewData.startDate).format('DD.MM.YYYY')}
                        </div>
                      </div>
                      <div className="text-end">
                        <div className="fnt-600">{formatAmount(chrgData.previewData.totalAmount)} AED</div>
                        <div className="fnt-400">
                          Due: {moment(chrgData.previewData.startDate).format('DD.MM.YYYY')}
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="d-flex justify-content-between">
                        <span>Sub Total:</span>
                        <span>{formatAmount(chrgData.previewData.bookingFee)} AED</span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span>VAT:</span>
                        <span>{formatAmount(chrgData.previewData.VATRate)} AED</span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <span>Total Amount:</span>
                        <span>{formatAmount(chrgData.previewData.totalAmount)} AED</span>
                      </div>
                    </div>
                  </div>

                  <Row className="separator mx-1 my-3 mb-7"></Row>

                  {/* Payment Section */}
                  <div className="mt-5">
                    <h4 className="fnt-600 mb-4 text-decoration-underline">2. Payment</h4>
                    <div className="d-flex justify-content-between">
                      <div className="fnt-500">Booking Fee</div>
                      <div>
                        <div className="fnt-600">+{formatAmount(chrgData.previewData.totalAmount)} AED</div>
                        <div className="fnt-400">
                          {moment(chrgData.previewData.startDate).format('DD.MM.YYYY')}
                        </div>
                      </div>
                    </div>
                  </div>

                  <Row className="separator mx-1 my-3 mb-7"></Row>

                  {/* Credit Section */}
                  <div className="mt-5">
                    <h4 className="fnt-600 mb-4 text-decoration-underline">3. Credit</h4>
                    {isCreditFromSecurityDeposit ? (
                      // Security Deposit Credit Display
                      <div className="mb-2">
                        <div className="d-flex justify-content-between mb-2">
                          <div className="fnt-700">Security Deposit</div>
                          <div className="fnt-600">
                            {formatAmount(chrgData.previewData.securityDepositAmount)} AED
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <div className="fnt-500">Credited Amount</div>
                          <div className="fnt-600">
                            -{formatAmount(chrgData.previewData.creditAmount)} AED
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mb-2">
                          <div className="fnt-500">Outstanding</div>
                          <div className="fnt-600">
                            {formatAmount(chrgData.previewData.outstandingAmount)} AED
                          </div>
                        </div>

                        {/* Show rent credit if booking fee exceeds security deposit */}
                        {chrgData.previewData.showRentCredit && (
                          <>
                            <div className="separator my-4"></div>
                            <div className="d-flex justify-content-between mb-2">
                              <div className="fnt-700">Total Rent Value</div>
                              <div className="fnt-600">
                                {formatAmount(chrgData.previewData.totalValue)} AED
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <div className="fnt-500">Credited Amount</div>
                              <div className="fnt-600">
                                -{formatAmount(chrgData.previewData.rentCreditAmount)} AED
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <div className="fnt-500">Outstanding</div>
                              <div className="fnt-600">
                                {formatAmount(chrgData.previewData.rentOutstanding)} AED
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    ) : (
                      // rent charges credit display - Direct installment breakdown
                      <div className="mb-2">
                        {chrgData.previewData.creditedInstallments?.map((installment: any, index: number) => (
                          <React.Fragment key={index}>
                            {index > 0 && <div className="separator my-4"></div>}
                            <div>
                              <div className="d-flex justify-content-between mb-2">
                                <div className="fnt-700">Rent Installment {installment.installmentNumber}</div>
                                <div className="fnt-600">{formatAmount(installment.amount)} AED</div>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <div className="fnt-500">Credited Amount</div>
                                <div className="fnt-600">
                                  -{formatAmount(installment.credited)} AED
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mb-2">
                                <div className="fnt-500">Outstanding</div>
                                <div className="fnt-600">
                                  {formatAmount(installment.outstanding)} AED
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    )}
                    <div className="fnt-400 mt-2">
                      Credit Issue Date: {moment(chrgData.previewData.startDate).format('DD.MM.YYYY')}
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-sm fw-bold px-2 mx-auto justify-content-center blue-btn status-w-200 mt-10"
                onClick={() => setShowPreviewCharges(true)}
                disabled={!chrgData?.previewData?.startDate ||
                  !chrgData?.previewData?.totalValue ||
                  (isCreditFromSecurityDeposit && !chrgData?.previewData?.securityDepositValue) ||
                  (isCreditFromRentCharges && !chrgData?.previewData?.noOfInstallments)}
              >
                Preview Charges
              </button>
              <p className="text-danger text-center mt-3">
                Please fill out all required field settings and then click 'Preview' to view the charges.
              </p>
            </>
          )}
        </div>

        {/* Footer Section */}
        {showPreviewCharges && (
          <div className="mt-auto border-top pt-4 mb-3">
            <p className="fs-6 mb-0">
              {chrgData.creditOptionType[0]?.value === 0 ? (
                "The booking fee amount will be credited to the selected charge. If the credited amount exceeds the selected charge total, it will automatically credit the next available charge with the remaining amount."
              ) : (
                "The booking fee amount will be credited to the selected charge. If the credited amount exceeds a single rent installment, it will automatically credit the next available rent installment with the remaining amount."
              )}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FixedBasedBookingFeePreview;