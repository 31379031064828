import React, {useEffect, useState} from 'react'
// import {Modal} from 'react-bootstrap'
import {ApiGet, ApiPut} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast} from '../../../apiCommon/helpers/Toast'
import {KTSVG} from '../../../_metronic/helpers'
import {Modal, Button, DatePicker} from 'antd'

import moment, {Moment} from 'moment'

import {DemoContainer} from '@mui/x-date-pickers/internals/demo'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {TimePicker} from '@mui/x-date-pickers/TimePicker'
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import dayjs, {Dayjs} from 'dayjs'
import {AnyARecord} from 'dns'

// import {TimePickerComponent} from '@syncfusion/ej2-react-calendars'

interface ButtonProps {
  show: any
  handleClose: any
  serviceRequestData?: any
  modalData?: any
  isKey: any
  indexToUpdate: any
  scheduleToUpdate: any
}

const Datechange = ({
  show,
  handleClose,
  serviceRequestData,
  modalData,
  isKey,
  indexToUpdate,
  scheduleToUpdate,
}: ButtonProps) => {
  //  const {show, handleClose, serviceRequestData, modalData} = props
  const format = 'HH:mm'
  // const [schedule, setSchedule] = useState<any>([
  //   {preferScheduleDate: '', preferScheduleTime: ''},
  //   {preferScheduleDate: '', preferScheduleTime: ''},
  // ])

  const currentTime = new Date();

  const [selectedDate, setSelectedDate] = useState<any>()
  // let x1:any = timeConvert(modalData?.schedule?.[0]?.startTime)
  // let x2: any = timeConvert(modalData?.schedule?.[0]?.endTime)
  // const [startTime, setStartTime] = useState<any>(x1)
  // const [endTime, setEndTime] = useState<any>(x2)

  const today = new Date()

  const [startTime, setStartTime] = useState<any>(null)
  const [endTime, setEndTime] = useState<any>(null)

  const [start, setStart] = useState<moment.Moment | null>(null)
  const [end, setEnd] = useState<moment.Moment | null>(null)

  const [isDisable, setIsDisable] = useState<boolean>(true)

  const [startValue, setStartValue] = React.useState<Dayjs | null>()
  const [endValue, setEndValue] = React.useState<Dayjs | null>()

  const [startTimeMinute, setstartTimeMinute] = useState<any>(0)
  const [endTimeMinute, setendTimeMinute] = useState<any>(0)

  const now = moment()
  const hours = now.hours()
  const minutes = now.minutes()
  const disabledHours = useState<any>(null)
  const disabledMinutes = useState<any>(null)

  // important
  const [journeyToTime, setJourneyToTime] = useState(null)
  const [journeyFromTime, setJourneyFromTime] = useState(null)

  const [journeyFromDateDisabledHours, setJourneyFromDateDisabledHours] = useState([])
  const [journeyFromDateDisabledMinutes, setJourneyFromDateDisabledMinutes] = useState([])
  const [journeyFromDateDisabledSeconds, setJourneyFromDateDisabledSeconds] = useState([])

  const [journeyToDateDisabledHours, setJourneyToDateDisabledHours] = useState([])
  const [journeyToDateDisabledMinutes, setJourneyToDateDisabledMinutes] = useState([])
  const [journeyToDateDisabledSeconds, setJourneyToDateDisabledSeconds] = useState([])

  /*const handleJourneyToTimeChange = (value: any) => {
    setJourneyToTime(value)

    if (value) {
      const journeyToDate = moment(value).add(1, 'day')
      const disabledHours: any = []
      const disabledMinutes: any = []
      const disabledSeconds: any = []

      for (let i = 0; i < value.hours(); i++) {
        disabledHours.push(i)
      }

      if (value.hours() === journeyToDate.hours()) {
        for (let i = 0; i < value.minutes(); i++) {
          disabledMinutes.push(i)
        }

        if (value.minutes() === journeyToDate.minutes()) {
          for (let i = 0; i < value.seconds(); i++) {
            disabledSeconds.push(i)
          }
        }
      }

      setJourneyFromDateDisabledHours(disabledHours)
      setJourneyFromDateDisabledMinutes(disabledMinutes)
      setJourneyFromDateDisabledSeconds(disabledSeconds)
    } else {
      setJourneyFromDateDisabledHours([])
      setJourneyFromDateDisabledMinutes([])
      setJourneyFromDateDisabledSeconds([])
    }
  }*/

  /*const handleJourneyFromTimeChange = (value: any) => {
    setJourneyFromTime(value)

    if (value) {
      const journeyToDate = moment(value).add(1, 'day')
      const disabledHours: any = []
      const disabledMinutes: any = []
      const disabledSeconds: any = []

      for (let i = 24; i > value.hours(); i--) {
        disabledHours.push(i)
      }

      if (value.hours() === journeyToDate.hours()) {
        for (let i = 60; i > value.minutes(); i--) {
          disabledMinutes.push(i)
        }

        if (value.minutes() === journeyToDate.minutes()) {
          for (let i = 0; i < value.seconds(); i++) {
            disabledSeconds.push(i)
          }
        }
      }

      setJourneyToDateDisabledHours(disabledHours)
      setJourneyToDateDisabledMinutes(disabledMinutes)
      setJourneyToDateDisabledSeconds(disabledSeconds)
    } else {
      setJourneyToDateDisabledHours([])
      setJourneyToDateDisabledMinutes([])
      setJourneyToDateDisabledSeconds([])
    }
  } */

  /*const showValue = (value: any, value2: any) => {
    setStartTime(value[0])

    setstartTimeMinute(value[0].hour() * 60 + value[0].minute())
    setEndTime(value[1])
    setendTimeMinute(value[1].hour() * 60 + value[1].minute())

    console.log('start Time ' + startTime)
    console.log('end time ' + endTime)
  }*/

  /*const disabledPastTime = () => {
    // disable hours before current hour
    for (let i = 0; i < hours; i++) {
      disabledHours.push(i)
    }

    // disable minutes before current minute
    for (let i = 0; i < minutes; i++) {
      disabledMinutes.push(i)
    }

    return {
      disabledHours: () => disabledHours,
      disabledMinutes: () => disabledMinutes,
    }
  }*/

  // const setScheduleEvent = (e: any, flg: any) => {
  //   if (flg === 'preferScheduleTime') {
  //     var hoursInMinutes = e.hours() * 60 // Convert hours to minutes
  //     const minutes = e.minutes() // Get the remaining minutes
  //     const totalMinutes = hoursInMinutes + minutes
  //     schedule[flg] = totalMinutes
  //   } else {
  //     schedule[flg] = e
  //   }
  //   console.log('\nschedule', schedule)
  //   setSchedule(schedule)
  // }

  // Function to handle changes to the start time picker
  /*const handleStartChange = (value: any) => {
    // Check if the new start time is after the current end time
    if (value && endTime && value.isAfter(end)) {
      setEnd(value)
      ErrorToast('Time (From) should not be after Time (To)..')
    }
    setStart(value)
    setstartTimeMinute(value.hour() * 60 + value.minute())
  }*/

  const handleDateChange = (date: any) => {
    if(date != null)
    setSelectedDate(date.format('YYYY-MM-DD'))
  else
  setSelectedDate(null)
  }

  function disabledDate(current: Moment) {
    // Disable dates before today's date
    return current && current < moment().startOf('day')
  }

  const handleStartValueChange = (value: any) => {
    let temp = new Date(value)
    // console.log(temp)
    
    let isoStr = temp.toISOString()
    // console.log(isoStr)

    let x = temp.toUTCString();
    // console.log(x)

    setStartValue(value)
    // console.log(startValue)
    setstartTimeMinute(isoStr)

    // setStartValue(value)
    // setstartTimeMinute(value.hour() * 60 + value.minute())
  }

  const handleEndValueChange = (value: any) => {
    let temp = new Date(value)
    // console.log(temp)
    
    let isoStr = temp.toISOString()
    // console.log(isoStr)

    let x = temp.toUTCString();
    // console.log(x)

    setEndValue(value)
    setendTimeMinute(isoStr) 

    // setEndValue(value)
    // setendTimeMinute(value.hour() * 60 + value.minute())
  }

  const handleOk = () => {
    console.log(modalData)
    let previousData = modalData?.[0]?.schedule
    
    let y = previousData

    // // to edit recors
    // if (isKey === 1) {
    //   y[indexToUpdate] = {
    //     date: selectedDate,
    //     startTime: startTimeMinute,
    //     endTime: endTimeMinute,
    //     status: 0,
    //   }
    // } 
    // // to add record
    // else {
      y[y.length] = {
        date: selectedDate,
        startTime: startTimeMinute,
        endTime: endTimeMinute,
        status: 0,
      }
    // }

    let body: any = {
      id: modalData?.[0]?._id,
      schedule: y,
    }
    ApiPut(`corporate/service_job`, body)
      .then((res) => {
        serviceRequestData()
        // setSelectedDate(null)
        setStartValue(null)
        setEndTime(null)
        setEndValue(null)
        handleClose()
      })
      .catch((err) => {
        console.log('\nerr', err)
      })
  }

  // Function to handle changes to the end time picker
  /*const handleEndChange = (value: any) => {
    // Check if the new end time is before the current start time
    if (value && start && value.isBefore(start)) {
      setStart(value)
      ErrorToast('Time (To) should not be before Time (From)..')
    }
    setEnd(value)
    setendTimeMinute(value.hour() * 60 + value.minute())
  }*/

  /*const handleStartTimeChange = (value: any) => {
    if (value && endTime && value.isAfter(endTime)) {
      setEndTime(value)
      ErrorToast('Time (From) should not be after Time (To)..')
      setIsDisable(true)
    } else {
      setIsDisable(false)
    }

    setStartTime(value)
    setstartTimeMinute(value.hour() * 60 + value.minute())
  }*/

  /*const handleEndTimeChange = (value: any) => {
    // Check if the new end time is before the current start time
    if (value && startTime && value.isBefore(startTime)) {
      setStartTime(value)
      ErrorToast('Time (To) should not be before Time (From)..')
      setIsDisable(true)
    } else {
      setIsDisable(false)
    }
    setEndTime(value)
    setendTimeMinute(value.hour() * 60 + value.minute())
  }*/

  useEffect(() => {
    if (scheduleToUpdate !== undefined) {
      // // setSelectedDate(scheduleToUpdate.date.slice(0, 10))
      // let x: any = timeConvert(scheduleToUpdate.startTime)
      // // setStartValue(x)
      // // setStartTime(x)
      // setstartTimeMinute(x)
      // let y: any = timeConvert(scheduleToUpdate.endTime)
      // // setEndValue(y)
      // // setEndTime(y)
      // setendTimeMinute(y)
    }

  }, [modalData])

  function timeConvert(n: any) {
    var num = n
    var hours = num / 60
    var rhours = Math.floor(hours)
    var fhour = '' + rhours
    var minutes = (hours - rhours) * 60
    var rminutes = Math.round(minutes)
    var fminutes = '' + rminutes
    if (rhours < 10) fhour = '0' + rhours
    if (rminutes < 10) fminutes = '0' + rminutes
    return fhour + ':' + fminutes
  }

  return (
    <>
      <Modal
        title='Select Date and Times'
        visible={show}
        onOk={handleOk}
        centered
        onCancel={() => handleClose()}
        footer={[
          <button
            type='button'
            className='btn btn-lg btn-light-primary me-3'
            onClick={() => handleClose()}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr063.svg' className='svg-icon-3 me-1 ' />{' '}
            Cancel
          </button>,

          <button
            onClick={handleOk}
            className='btn btn-lg btn-primary btn-green'
            // disabled={isDisable || selectedDate === null}
          >
            Submit
            <KTSVG path='/media/icons/duotune/arrows/arr064.svg' className='svg-icon-3 ms-2 me-0' />
          </button>,
        ]}
      >
        <h6 className='text-muted align-items-center justify-content-center'>
          Date
          <DatePicker
            // defaultValue={moment(selectedDate, 'YYYY-MM-DD')}
            disabledDate={disabledDate}
            onChange={handleDateChange}
          />
        </h6>

        <h6 className='text-muted align-items-center justify-content-center' >
        Time (From)
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
             
              <TimePicker
                ampm={false}
                value={startValue}
                onChange={(newValue) => handleStartValueChange(newValue)}
                minTime={ (new Date(selectedDate).getFullYear() ===
                  today.getFullYear() &&
                new Date(selectedDate).getMonth() ===
                  today.getMonth() &&
                new Date(selectedDate).getDate() ===
                  today.getDate()) ? dayjs(currentTime) : startValue}
                maxTime={endValue}
              />
            </DemoContainer>
          </LocalizationProvider>
        </h6>

        <h6 className='text-muted align-items-center justify-content-center'>
        Time (To) 
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['TimePicker']}>
             
              <TimePicker
                minutesStep={1}
                ampm={false}
                // defaultValue={startValue}
                value={endValue}
                // onChange={(newValue) => handleEndValueChange(newValue)}
                disablePast={ (new Date(selectedDate).getFullYear() ===
                  today.getFullYear() &&
                new Date(selectedDate).getMonth() ===
                  today.getMonth() &&
                new Date(selectedDate).getDate() ===
                  today.getDate()) ? true : false}
                minTime={startValue}
                onAccept={(newValue) => handleEndValueChange(newValue)}
                // onClose={handleClose}
              />
            </DemoContainer>
          </LocalizationProvider>
         
        </h6>

        {/* <h6 className='text-muted'>
       
          Time (From)
          <TimePicker value={start} onChange={handleStartChange}  format={format}/>
        </h6>
        <h6 className='text-muted'>
         
          Time (To)
          <TimePicker value={end} onChange={handleEndChange} format={format}/>
        </h6> */}

        {/* <h6>
        <TimePicker value={start} onChange={handleStartChange} />
        <TimePicker value={end} onChange={handleEndChange} />
        </h6> */}
        {/* <h6 className='text-muted'>
          
          Time (From)
          <TimePicker
            defaultValue={moment()}
            value={startTime}
            onChange={handleStartTimeChange}
            format={format}
          />
        </h6>
        <h6 className='text-muted'>
         
          Time (To)
          <TimePicker
            defaultValue={moment()}
            value={endTime}
            onChange={handleEndTimeChange}
            format={format}
          />
          </h6> */}
        {/* <TimePicker
           format={format} 
        value={journeyToTime}
        onChange={handleJourneyToTimeChange}
        disabledHours={() => journeyToDateDisabledHours}
        disabledMinutes={() => journeyToDateDisabledMinutes}
        disabledSeconds={() => journeyToDateDisabledSeconds}
      />
      <TimePicker
       format={format} 
        disabledHours={() => journeyFromDateDisabledHours}
        disabledMinutes={() => journeyFromDateDisabledMinutes}
        disabledSeconds={() => journeyFromDateDisabledSeconds}
        onChange={handleJourneyFromTimeChange}
      /> */}

        {/* Range picker */}
        {/* <h6 className='text-muted'>
          <span style={{paddingTop: '6px'}}>Time (From) - Time (To){' '}</span>
          <TimePicker.RangePicker
            onChange={showValue}
            value={[startTime, endTime]}
            format={format}
          />
        </h6> */}

       
      </Modal>
    </>
  )
}

export default Datechange
