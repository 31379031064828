import moment from 'moment'
import React, {useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import {ApiGet, ApiPost, ApiPut, Bucket} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import MediaComments from './MediaComments'
import MediaPopUp from './RequestLogMediaComments'
import noData from '../../../img/NoData1.svg'
import editDate from '../../../img/edit.svg'
import TenatsFilter1 from '../Tenancies/TenanciesFilter1'
import leftArrow from '../../../img/arrowleft.svg'
import submit from '../../../img/submit.png'
import Lightbox from 'yet-another-react-lightbox'
   import 'yet-another-react-lightbox/styles.css'

function RequestLogTab() {
  const navigate = useNavigate()
  const [showMedia, setShowMedia] = useState<any>(false)
  const [mediaPopup, setMediaPopup] = React.useState<any>(false)
  const [requestData, setRequestData] = React.useState<any>()
  const [serviceData, setServicedata] = React.useState<any>()
  const [approvalRequest, setApprovalRequest] = React.useState<any>()
  const [propertyList, setPropertyList] = React.useState<any>()
  const [pageLimit, setPageLimit] = React.useState<any>()
  const [page, setPage] = React.useState<any>(1)
  const [jobId, setJobId] = React.useState<any>()
  const serviceId = window.location.pathname?.split('/')[5]
  const [mediaPop, setMediaPop] = useState<any>()
  const [showCreateAppModal1, setShowCreateAppModal1] = useState<boolean>(false)
  const [unitNo, setUnitNo] = useState<any>()

  const [index, setIndex] = React.useState(-1)
  const [slides, setSlides] = React.useState<any>([])

  var today: any = new Date()
  const currentTimee = new Date()
  const currentHours = currentTimee.getHours()
  const currentMinutes = currentTimee.getMinutes()

  const getServiceData = async () => {
    await ApiGet(`corporate/service_request/${serviceId}`)
      .then((res) => {
        setRequestData(res?.data?.data[0])
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getServiceJob = async () => {
    const body = {
      serviceRequestId: serviceId,
    }
    await ApiPost(`corporate/service_job/job_list`, body)
      .then((res) => {
        setServicedata(res?.data?.data)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const getApprovalServiceRequest = async () => {
    const body = {
      serviceRequestId: serviceId,
    }
    await ApiPost(`corporate/approval_request/get`, body)
      .then((res) => {
        setApprovalRequest(res?.data?.data?.serviceApprovalRequest)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const serviceRequestData = async () => {
    const body = {
      page: page,
      limit: 10,
      serviceRequestId: serviceId,
    }
    await ApiPost(`corporate/service_job/get`, body)
      .then((res) => {
        setPropertyList(res?.data?.data?.service_job_data)
        setPageLimit(res?.data?.data?.state?.page_limit)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  // Submit to vendor
  const submitToVendor = async () => {
    const body = {
      id: serviceId,
      // requestStatus: 6,
      vendorStatus: 1
    }
    await ApiPut(`corporate/service_request`, body)
      .then((res) => {
        SuccessToast("Request has been submitted to vendor successfully!!!")
      })
      .catch((e) => {
        console.log(e)
      })
    navigate('/request-management/service')
  }

  React.useEffect(() => {
    getServiceData()
    getServiceJob()
    getApprovalServiceRequest()
    serviceRequestData()
  }, [window.location.pathname?.split('/')[4], page])

  const openModal = async (ids: any, unitN: any) => {
    setUnitNo(unitN)
   

    await ApiGet(`corporate/service_job/${ids}`)
      .then((res) => {
       
       
        setMediaPop(res.data.data[0])
        setJobId(ids)
        setMediaPopup(true)
      })
      .catch((err) => ErrorToast(err.message))
  }
  function timeConvert(n: any) {
    // console.log(n)
    let temp = new Date(n)
    let newVal: any = temp.toLocaleTimeString()
    // console.log(newVal);

    let h1: any = newVal.slice(0, 2) * 60
    let newValHours = parseInt(h1)
    //  console.log( newValHours)

    let newValMins: any = parseInt(newVal.slice(3, 5))
    //  console.log(newValMins)

    var num: number = newValHours + newValMins
    //  console.log(num)
    var hours = num / 60
    var rhours = Math.floor(hours)
    var fhour: any = '' + rhours
    var minutes = (hours - rhours) * 60
    var rminutes = Math.round(minutes) 
    var fminutes: any = '' + rminutes
    if (rhours < 10) fhour = '0' + rhours
    if (rminutes < 10) fminutes = '0' + rminutes
    let x: any = fhour + ':' + fminutes
    return x
  }

  return (
    <>
      <div className='app-main facilitie_create flex-column flex-row-fluid' id='kt_app_main'>
        <div id='' className='app-container container-xxl pt-0 mt-0'>
          <div className='d-flex align-items-center flex-wrap mr-5'>
            {/* <ul className='breadcrumb breadcrumb-transparent breadcrumb-line font-weight-bold p-0 my-2 font-size-sm'> */}
            {/* <li className='breadcrumb-item '>
              <a
                className='text-muted px-2 cursor-pointer'
                onClick={() => navigate(`/request-management/service`)}
              >
                Request
              </a>
            </li> */}
            {/* <li className='breadcrumb-item active'>
              <a className='px-2'> View Service Detail</a>
            </li> */}

            {/* </ul> */}
            <span
              className='rounded-circle'
              style={{cursor: 'pointer'}}
              onClick={() => navigate(`/request-management/service`)}
            >
              <img src={leftArrow} style={{stroke: 'red'}} className='me-2' />
            </span>
            <h3 className='page-heading green_color m-0'>View Request</h3>
          </div>

          <div className='d-flex align-items-center justify-content-center gap-2 mb-10 gap-lg-3'>
            <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(
                    `/request-management/service/create-request-service/service-detail/${serviceId}`
                  )
                }}
              >
                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                  Service
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(
                    `/request-management/service/create-request-service/payment-tab/${serviceId}`
                  )
                }}
              >
                <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                  Payment
                </a>
              </li>
              <li
                className='nav-item'
                onClick={() => {
                  navigate(
                    `/request-management/service/create-request-service/request-log-tab/${serviceId}`
                  )
                }}
              >
                <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                  Request Log
                </a>
              </li>
            </ul>
          </div>
          <div className='card p-5'>
            <Row>
              <Col md={10}>
                <h1 className='top green_color'>Approval Workflow</h1>
              </Col>
              { requestData?.vendorStatus === 0 &&
              <Col md={2} className='text-end'>
                {(requestData?.requestType === 1 && requestData?.requestStatus === 5) && (
                  <a className='btn btn-sm fw-bold text-white'  style={{backgroundColor: 'rgb(72, 71, 77)'}}
                  onClick={() =>
                    Swal.fire({
                      html: '<p class="text-center m-0" style="font-size: 16px"> You are about to submit this request to the service provider, Please ensure that all information is accurate.</p>',
                      showConfirmButton: true,
                      confirmButtonColor: ' #007a59',
                      confirmButtonText: 'Submit',
                      showCancelButton: true,
                      cancelButtonText: 'Cancel',
                    }).then((res) => {
                      if (res.isConfirmed) {
                        submitToVendor()
                      }
                    })
                  }
                  > 
                <img src={submit} style={{height: '20px', width: '20px', marginRight: '7px', marginLeft: '-10px'}} />
                  Submit To Vendor</a>
                )}
              </Col>
}
            </Row>

            <div className='pt-0 table-responsive'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='text-center'>User</th>
                    <th className='text-center min-w-100px'>Updated</th>
                    <th className='text-center min-w-100px'>Status</th>
                    <th className='text-center min-w-100px'>Signature</th>
                    <th className='text-center min-w-100px'>Comments</th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {approvalRequest?.length ? (
                    approvalRequest?.map((f: any, i: any) => (
                      <tr>
                        <td className='text-center'>
                          {/* {f?.corporate[0]?.firstName} {f?.corporate[0]?.lastName} */}
                          {f?.type == 1
                              ? `${f?.corporate[0]?.firstName || ''} ${
                                  f?.corporate[0]?.lastName || ''
                                }`
                              : `${f?.vendor[0]?.companyName || ''}`}
                        </td>
                        { f?.corporate[0] ?
                        <td className='text-center'>
                          {moment(`${f.updatedAt}`).format('DD-MM-YYYY - HH:mm')}
                        </td>
                        :
                        <td className='text-center'>-</td>
}
                        { f?.corporate[0] ?
                        <td className='text-center'>
                          {f?.approvalStatus === 0
                            ? 'Pending Approval'
                            : f?.approvalStatus === 1
                            ? 'Requested'
                            : f?.approvalStatus === 2
                            ? 'Approved'
                            : f?.approvalStatus === 3
                            ? 'Rejected'
                            : 'Send Back'}
                        </td>
                        :
                        <td className='text-center'>-</td>
                          }
                        { f?.type == 1 ?
                        <td className='text-center'>
                          {f?.eSignature ? (
                            <img
                             style={{height: '35px', width: '35px', cursor: 'pointer'}}
                              src={`${Bucket}${f?.eSignature}`}
                              onClick={() => {
                                setSlides([{
                                  src: `${Bucket}${f?.eSignature}`
                                }])
                                setIndex(0)
                              }}
                            />
                             
                          ) : f?.writtenSignature ? (
                            <a
                              className='mx-3 green_color cursor-pointer' style={{textDecoration: 'underline'}}
                              // href={`${Bucket}${f?.writtenSignature}`}
                              // target='_blank'
                              onClick={() => {
                                setSlides('')
                                setSlides([{
                                  src: `${Bucket}${f?.writtenSignature}`
                                }])
                                setIndex(0)
                              }}
                            >
                              Signed Document
                            </a>
                          ) : (
                            ''
                          )}
                        </td>
                        :
                        <td className='text-center'>-</td>
                          }
                        <td className='text-center'>{f.comment ? f.comment : '-'}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={16} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className='card  mt-5  p-5'>
          <Row>
              <Col md={12}>
              <h1 className='top green_color mb-0'>Request Summary</h1>
              </Col></Row>
            <div className="grid-out-diff-gap container-xxl px-0">
            
          
              <div className='p-2 px-0 side '>
                {/* <h1 className='top green_color'>Service Details</h1> */}
                <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 3fr'}}>
                Request ID
                  <div className='mx-1 value_black'>{requestData?.id}</div>
                </h6>

                <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 3fr'}}>
              Service
                  <div className='mx-1 value_black'> {requestData?.main_service[0]?.name} - {requestData?.sub_service[0]?.name}</div>
                </h6>

                <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 3fr'}}>
             Assigned to
                  <div className='mx-1 value_black'>  {requestData?.staff[0]?.firstName} {requestData?.staff[0]?.lastName}</div>
                </h6>

                <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 3fr'}}>
            Developments
                  <div className='mx-1 value_black'>   {serviceData?.total_building || serviceData?.total_community}</div>
                </h6>
                <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 3fr'}}>
             Total Units
                  <div className='mx-1 value_black'>   {serviceData?.total_units}</div>
                </h6>
                <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 3fr'}}>
             Total Jobs
                  <div className='mx-1 value_black'>   {serviceData?.total_units} </div>
                </h6>
                </div>
                <div className='p-2 px-0 side '>
                {/* <h1 className='top green_color'>Service Details</h1> */}
                <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 2fr'}}>
               Request Source
                  <div className='mx-1 value_black'>  {requestData?.requestType === 2 ? 'Internal' : 'External'}</div>
                </h6>

                <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 2fr'}}>
            Request Urgency
                  <div className='mx-1 value_black'>  {requestData?.requestUrgency === 0 ? 'Standard' : 'Emergency'}</div>
                </h6>

                {/* <h6 className='mx-0 text-muted' style={{gridTemplateColumns: '1fr 2fr'}}>
                Preferred Schedules for Service
                  <div className='mx-1 value_black'>   {requestData?.schedule?.[0]?.preferScheduleDate &&
                        moment(requestData?.schedule?.[0]?.preferScheduleDate).format(
                          'DD/MM/YYYY'
                        ) + ' - '}
                      {requestData?.schedule?.[0]?.preferScheduleTime &&
                        timeConvert(requestData?.schedule?.[0]?.preferScheduleTime)}{' '}</div>
                </h6> */}
                </div>
                
                </div>
                </div>


          {/* Request Details & Summary */}
          {/* <div className='card mt-5  p-5'>
           
            <h1 className='top green_color mb-0'>Request Summary</h1>

            <div className=' tops text-muted'>
              <div className='pe-5 mt-0'> */}
                  {/*<Row className='mt-3'>
          <Col md={6}>
              <h6 className=''>
              Service
                <span className='mx-1' style={{color: 'black'}}>
                {requestData?.main_service[0]?.name} - {requestData?.sub_service[0]?.name}
                </span>
              </h6>
            </Col> 
            
           <Col md={4}>
              <h6 className='text-muted'>
              Assigned To
                <span className='mx-1' style={{color: 'black'}}>
                {requestData?.staff[0]?.firstName} {requestData?.staff[0]?.lastName}
                </span>
              </h6>
            </Col>
            <Col md={4}>
              <h6 className='text-muted'>
              Total Buildings/Communities
                <span className='mx-1' style={{color: 'black'}}>
                {serviceData?.total_building || serviceData?.total_community}
                </span>
              </h6>
            </Col>
            <Col md={4}>
              <h6 className='text-muted'>
              Total Units
                <span className='mx-1' style={{color: 'black'}}>
                {serviceData?.total_units}
                </span>
              </h6>
            </Col>
            <Col md={4}>
              <h6 className='text-muted'>
              Total Jobs
                <span className='mx-1' style={{color: 'black'}}>
                
                </span>
              </h6>
            </Col>
            <Col md={4}>
              <h6 className='text-muted'>
              Request Urgency
                <span className='mx-1' style={{color: 'black'}}>
                {requestData?.requestUrgency === 0 ? 'Standard' : 'Emergency'}
                </span>
              </h6>
            </Col>
            <Col md={4}>
              <h6 className='text-muted'>
              Preferred Schedules for Service
                <span className='mx-1' style={{color: 'black'}}>
                {requestData?.schedule?.[0]?.preferScheduleDate &&
                      moment(requestData?.schedule?.[0]?.preferScheduleDate).format('DD/MM/YYYY') +
                        ' - '}
                    {requestData?.schedule?.[0]?.preferScheduleTime &&
                      timeConvert(requestData?.schedule?.[0]?.preferScheduleTime)}{' '}
                </span>
              </h6>
            </Col>
            </Row> */}

                {/* <h6 className='text-muted mx-0 my-2 align-items-center'>
                  Request ID
                  <div className='d-flex align-items-center gap-5 '>
                    <div className='mx-1 value_black'>{requestData?.id}</div>
                  </div>
                </h6>
                <h6 className='text-muted mx-0 my-2 align-items-center'>
                  Service
                  <div className='d-flex align-items-center gap-5 '>
                    <div className='mx-1 value_black'>
                      {requestData?.main_service[0]?.name} - {requestData?.sub_service[0]?.name}
                    </div>
                  </div>
                </h6> */}
                {/* <h6 className='text-muted border-outline mx-0 my-2'>
                  Assigned To
                  <div className='mx-1 value_black'>
                    {requestData?.staff[0]?.firstName} {requestData?.staff[0]?.lastName}
                  </div>
                </h6>
                <h6 className='text-muted border-outline mx-0 my-2'>
                  Total Buildings/Communities
                  <div className='mx-1 value_black'>
                    {serviceData?.total_building || serviceData?.total_community}
                  </div>
                </h6> */}
                {/* <h6 className='text-muted border-outline mx-0 my-2'>
                  Total Units
                  <div className='mx-1 value_black'>{serviceData?.total_units}</div>
                </h6>
                <h6 className='text-muted border-outline mx-0 my-2'>
                  Total Jobs
                  <div className='mx-1 value_black'></div>
                </h6>
              </div>

              <div className='ps-5 mt-0 mh-100'>
                <h6 className='text-muted mx-0 my-2 align-items-center'>
                  Request Source
                  <div className='d-flex align-items-center gap-5 '>
                    <div className='mx-1 value_black'>
                      {requestData?.requestType === 2 ? 'Internal' : 'External'}
                    </div>
                  </div>
                </h6> */}
                {/* <h6 className='text-muted mx-0 my-2 align-items-center'>
                  Request Urgency
                  <div className='d-flex align-items-center gap-5 '>
                    <div className='mx-1 value_black'>
                      {requestData?.requestUrgency === 0 ? 'Standard' : 'Emergency'}
                    </div>
                  </div>
                </h6> */}

                {/* <h6 className='text-muted mx-0 my-2 align-items-center'>
                  Preferred Schedules for Service
                  <div className='d-flex align-items-center gap-5 '>
                    <div className='mx-1 value_black'>
                      {requestData?.schedule?.[0]?.preferScheduleDate &&
                        moment(requestData?.schedule?.[0]?.preferScheduleDate).format(
                          'DD/MM/YYYY'
                        ) + ' - '}
                      {requestData?.schedule?.[0]?.preferScheduleTime &&
                        timeConvert(requestData?.schedule?.[0]?.preferScheduleTime)}{' '}
                    </div>
                  </div>
                </h6> */}
              {/* </div>
            </div>
          </div> */}

          {/* Properties */}
          <div className='card p-5 mt-5'>
            <div className='d-flex align-items-center gap-2 gap-lg-3'>
              <h1 className='top green_color'>Properties</h1>
            </div>
            <div className='table-responsive mb-5'>
              <table
                className='table align-middle table-row-dashed fs-6 gy-5'
                id='kt_ecommerce_sales_table'
              >
                <thead>
                  <tr className='text-center text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                    <th className='min-w-100px'>Job Id</th>
                    <th className='min-w-100px'>Development</th>
                    <th className='min-w-70px'>Floor/Cluster</th>
                    <th className='min-w-100px'>Unit</th>
                    <th className='min-w-100px'>Unit Type </th>
                    <th className='min-w-100px'>Bedrooms </th>
                    <th className='min-w-200px'>Job Schedule</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className='fw-semibold text-gray-600'>
                  {propertyList?.length ? (
                    propertyList?.map((f: any, i: any) => {
                      return (
                        <tr className='text-center'>
                          <td>{f?.id ?? '-'}</td>
                          <td>{f?.communityId ? 'Community' : 'Building'}</td>
                          <td>{f?.communityId ? 'Cluster' : 'Floor'}</td>
                          <td>{f?.unit[0]?.unitNo ?? '-'}</td>
                          <td>{f?.unit[0]?.unitType ?? '-'}</td>
                          <td>{f?.unit[0]?.bedrooms ?? '-'}</td>
                          <td>
                          {f?.schedule?.[0]?.date
                                          ? moment(f?.schedule?.[0]?.date).format('DD.MM.YYYY')
                                          : null}{' '}
                                        {f?.schedule?.[0]?.startTime
                                          ? timeConvert(f?.schedule?.[0]?.startTime) + '-'
                                          : null}
                                        {f?.schedule?.[0]?.endTime
                                          ? timeConvert(f?.schedule?.[0]?.endTime)
                                          : null}{' '}

{f?.schedule?.[0]?.date && (
                                        <>
                                          {/* Same Day */}
                                          {new Date(f?.schedule?.[0]?.date).getFullYear() ===
                                            today.getFullYear() &&
                                          new Date(f?.schedule?.[0]?.date).getMonth() ===
                                            today.getMonth() &&
                                          new Date(f?.schedule?.[0]?.date).getDate() ===
                                            today.getDate() ? (
                                            <>
                                              {currentHours >
                                              parseInt(
                                                timeConvert(f?.schedule?.[0]?.startTime).split(
                                                  ':'
                                                )[0]
                                              ) ? (
                                                <p style={{color: 'rgb(213, 70, 69)'}}>
                                                  {' '}
                                                  Schedule has Expired
                                                </p>
                                              ) : (
                                                <>
                                                  {parseInt(
                                                    timeConvert(f?.schedule?.[0]?.startTime).split(
                                                      ':'
                                                    )[0]
                                                  ) -
                                                    currentHours <=
                                                  6 ? (
                                                    <p style={{color: 'rgb(213, 70, 69)'}}>
                                                    {/* { parseInt(
                                                    timeConvert(f?.schedule?.[0]?.startTime).split(
                                                      ':'
                                                    )[0]
                                                  ) -
                                                    currentHours} */}
                                                      Schedule is Approaching
                                                    </p>
                                                  ) : (
                                                    <></>
                                                  )}
                                                </>
                                              )}
                                            </>
                                          ) : 
                                             // next Day
                                          new Date(f?.schedule?.[0]?.date) > today ? (
                                            <>
                                              {new Date(f?.schedule?.[0]?.date).getDate() ===
                                              today.getDate() + 1 ? (
                                                <>
                                                  {24 -
                                                    currentHours +
                                                    parseInt(
                                                      timeConvert(
                                                        f?.schedule?.[0]?.startTime
                                                      ).split(':')[0]
                                                    ) <=
                                                    6 && (
                                                    <p style={{color: 'rgb(213, 70, 69)'}}>
                                                      Schedule is Approaching
                                                    </p>
                                                  )}
                                                </>
                                              ) : (
                                                <></>
                                              )}
                                              <></>
                                            </>
                                          ) : (
                                            <p style={{color: 'rgb(213, 70, 69)'}} >
                                            Schedule has expired
                                          </p>
                                )
                                
                                }
                              </>
                                      )}

                  
                            {/* {f?.date ? moment(f?.date).format('DD/MM/YYYY') : null}
                            {f?.startTime ? timeConvert(f?.startTime) : null}
                            {f?.endTime ? timeConvert(f?.endTime) : null} */}
                            {/* <a
                            // onClick={() => {
                            //   setShowCreateAppModal1(f)
                            // }}
                            >
                               <img src={editDate} alt='' /> 
                            </a> */}
                          </td>
                          <td>
                            <button
                              className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                              onClick={() => openModal(f?._id,  f?.unit[0]?.unitNo)}
                            >
                              Media & Comments
                            </button>
                          </td>
                        </tr>
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={16} className='text-center'>
                        <img src={noData} alt='' width={350} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Lightbox
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                slides={slides}
                carousel={{ finite: slides.length <= 1 }}
                render={{
                  buttonPrev: slides.length <= 1 ? () => null : undefined,
                  buttonNext: slides.length <= 1 ? () => null : undefined,
                }}
              />

      <MediaComments
        show={showMedia}
        handleClose={() => {
          setShowMedia(false)
        }}
        propertyList={propertyList}
        // showMediaType={showMediaType}
      />
      <TenatsFilter1
        show={showCreateAppModal1}
        handleClose={() => {
          setShowCreateAppModal1(false)
        }}
        data={propertyList}
        callhandle={() => setShowCreateAppModal1(false)}
      />
      <MediaPopUp
        unitNo={unitNo}
        show={mediaPopup}
        propertyList={propertyList}
        handleClose={() => {
          setMediaPopup(false)
        }}
        id={{jobId: jobId}}
        mediaPop={mediaPop}

        // setFormData={setFormData}
      />
    </>
  )
}

export default RequestLogTab
