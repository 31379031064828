import React, {useEffect, useState} from 'react'
import {Row, Col} from 'react-bootstrap'
import FlexedInput from '../../../../../../components/FlexedInput'
import DatePicker from 'antd/es/date-picker'
import moment, {Moment} from 'moment'
import {formatAmount} from '../../../../../../../../../Utilities/utils'
import {ErrorToast} from '../../../../../../../../../apiCommon/helpers/Toast'
import CustomFrequencyInput from '../../../../../../components/CustomFrequencyInput'
import FlexedInputFrequency from '../../../../../../components/FlexedInputFrequency'
import {useFormContext} from 'react-hook-form'
import sync from '../../../../../../../../../img/refreshIcon.png'

interface ParentData {
  chrgData: any
  setChrgData: any
  isChrgDataChanged?: any
}

const SinglePreview = ({chrgData, setChrgData, isChrgDataChanged}: ParentData) => {
  const {
    formState: {errors},
  } = useFormContext()
  console.log(errors)
  const [isPreviewFieldFilled, setIsPreviewFieldsFilled] = useState<boolean>(false)
  // const [showCalculationFields, setShowCalculationFields] = useState<boolean>(false)
  const [showPreviewCharges, setShowPreviewCharges] = useState<any>(false)
  const [pickerKey, setPickerKey] = useState(Date.now())

  const calculateFeeAmount = () => {
    if (chrgData?.amountCalculationType !== null && chrgData?.amountCalculationType === 0) return
    if (errors['noOfInstallments']?.type == 'min' || errors['noOfInstallments']?.type == 'max') return
    // Check if feePercentage is not null
    const updatedChargeData = {...chrgData}
    if (chrgData?.selectLeaseChargeType?.[0]?.label?.toLowerCase() === 'total rent value') {
      if (chrgData?.previewData?.totalRentValue && chrgData?.feePercentage) {
        if (chrgData?.splitIntoInstallments?.[0]?.value === 1 || chrgData?.splitIntoInstallments?.[0]?.value === 2) {
          const newFeeAmount = (Number(chrgData.previewData.totalRentValue) * Number(chrgData.feePercentage)) / 100
          const feeAmount =
            Number(newFeeAmount) >= Number(chrgData?.minimumAmount) ? newFeeAmount : Number(chrgData?.minimumAmount)
          updatedChargeData.previewData.feeAmount = feeAmount
        } else if (chrgData?.splitIntoInstallments?.[0]?.value === 0 && chrgData?.previewData?.noOfInstallments) {
          console.log('in last if else of total rent cvalue')
          const newFeeAmount = (chrgData.previewData.totalRentValue * chrgData.feePercentage) / 100
          const feeAmount =
            Number(newFeeAmount) >= Number(chrgData?.minimumAmount) ? newFeeAmount : Number(chrgData?.minimumAmount)
          updatedChargeData.previewData.feeAmount = feeAmount
        } else {
          updatedChargeData.previewData.feeAmount = null
        }
      }

      // Update chrgData
      setChrgData(updatedChargeData)
    } else if (chrgData?.selectLeaseChargeType?.[0]?.label?.toLowerCase() === 'single rent installment') {
      if (chrgData?.previewData?.totalRentValue && chrgData?.previewData?.noOfInstallments && chrgData.feePercentage) {
        const newFeeAmount = (chrgData.previewData.singleRentInstallment * chrgData.feePercentage) / 100
        const feeAmount =
          Number(newFeeAmount) >= Number(chrgData?.minimumAmount) ? newFeeAmount : Number(chrgData?.minimumAmount)
        updatedChargeData.previewData.feeAmount = feeAmount
      } else {
        updatedChargeData.previewData.feeAmount = null
      }

      // Update chrgData
      setChrgData(updatedChargeData)
    } else if (chrgData?.splitIntoInstallments?.[0]?.value === 0) {
      console.log('in last if else')
      if (chrgData?.previewData?.totalRentValue && chrgData?.previewData?.noOfInstallments && chrgData.feePercentage) {
        const feeAmount = (chrgData.previewData.totalRentValue * chrgData.feePercentage) / 100
        updatedChargeData.previewData.feeAmount = feeAmount
      } else {
        updatedChargeData.previewData.feeAmount = null
      }
      // Update chrgData
      setChrgData(updatedChargeData)
    } else {
      // Reset feeAmount if conditions are not met
      const updatedChargeData = {...chrgData}
      updatedChargeData.previewData.feeAmount = null
      setChrgData(updatedChargeData)
    }
  }

  const calculateVATAmount = () => {
    if (chrgData?.amountCalculationType !== null && chrgData?.amountCalculationType === 0) return
    if (errors['noOfInstallments']?.type == 'min' || errors['noOfInstallments']?.type == 'max') return
    // Check if feeAmount is not null or 0
    // Check if VATPercentage is not null
    if (chrgData?.previewData.feeAmount && chrgData?.VATPercentage) {
      console.log('if run calculate VAt')
      // Calculate vatAmount
      const vatAmount = (Number(chrgData?.previewData.feeAmount) * Number(chrgData?.VATPercentage)) / 100
      console.log('vatAmount', vatAmount)
      const updatedChargeData = {...chrgData}
      // Update chrgData
      updatedChargeData.previewData.VATRate = vatAmount
      console.log(updatedChargeData)
      setChrgData(updatedChargeData)
    } else {
      console.log('else run calculate VAt')
      // Reset vatAmount if conditions are not met
      const updatedChargeData = {...chrgData}
      updatedChargeData.previewData.VATRate = null
      setChrgData(updatedChargeData)
    }
  }

  const calculateDailyRate = () => {
    if (errors['noOfInstallments']?.type == 'min' || errors['noOfInstallments']?.type == 'max') return
    // Check if feeAmount is not null or 0
    // Check if VATPercentage is not null
    if (chrgData?.previewData.startDate && chrgData?.previewData.endDate) {
      // Calculate dailyRate

      const updatedChargeData = {...chrgData}
      if (chrgData?.fixedAmount && chrgData?.amountCalculationType === 0) {
        const dailyRate = chrgData.fixedAmount / 365
        updatedChargeData.previewData.dailyRate = dailyRate
      } else if (chrgData?.previewData.feeAmount && chrgData?.amountCalculationType === 1) {
        const dailyRate = chrgData?.previewData.feeAmount / 365
        updatedChargeData.previewData.dailyRate = dailyRate
      }

      // Update chrgData
      setChrgData(updatedChargeData)
    } else {
      // Reset dailyRate if conditions are not met
      const updatedChargeData = {...chrgData}
      updatedChargeData.previewData.dailyRate = null
      setChrgData(updatedChargeData)
    }
  }

  const calculateTotalAmount = () => {
    if (errors['noOfInstallments']?.type == 'min' || errors['noOfInstallments']?.type == 'max') return
    const updatedChargeData = {...chrgData}
    if (chrgData?.previewData?.feeAmount && chrgData?.previewData?.VATRate) {
      const totalAmount = Number(chrgData?.previewData.feeAmount) + Number(chrgData?.previewData.VATRate)
      updatedChargeData.previewData.totalValue = totalAmount
    } else if (chrgData?.fixedAmount && chrgData?.previewData?.VATRate) {
      const totalAmount = Number(chrgData?.fixedAmount) + Number(chrgData?.previewData.VATRate)
      updatedChargeData.previewData.totalValue = totalAmount
    } else {
      updatedChargeData.previewData.totalValue = null
    }
    setChrgData(updatedChargeData)
  }

  const calculateSingleRentInstllment = () => {
    if (errors['noOfInstallments']?.type == 'min' || errors['noOfInstallments']?.type == 'max') return
    if (chrgData?.previewData?.totalRentValue && chrgData?.previewData?.noOfInstallments) {
      const updatedChargeData = {...chrgData}
      const singleRentInstallment = chrgData?.previewData?.totalRentValue / chrgData?.previewData?.noOfInstallments
      updatedChargeData.previewData.singleRentInstallment = singleRentInstallment
      setChrgData(updatedChargeData)
    } else {
      // Reset feeAmount if conditions are not met
      const updatedChargeData = {...chrgData}
      // updatedChargeData.previewData.noOfInstallments = null
      updatedChargeData.previewData.singleRentInstallment = null
      setChrgData(updatedChargeData)
    }
  }

  function calculateInstallmentDates(startDate: Date, endDate: Date, noOfInstallments: number): Date[] {
    const installmentDates: Date[] = []
    const timeDiff = endDate?.getTime() - startDate?.getTime()
    const interval = timeDiff / noOfInstallments

    for (let i = 0; i < noOfInstallments; i++) {
      const installmentDate = new Date(startDate?.getTime() + interval * i)
      installmentDates.push(installmentDate)
    }

    return installmentDates
  }

  const previewHandler = () => {
    const {startDate, endDate, feeAmount, VATRate} = chrgData.previewData
    const {
      customTimesApplied,
      customFrequencyType,
      daysAfterChargeIsApplied,
      startApplying,
      dueDateType,
      splitIntoInstallments,
    } = chrgData

    // Initialize the chargeSeries array
    const chargeSeries = []
    let totalAmount = 0
    if (chrgData?.amountCalculationType == 1) {
      totalAmount =
        chrgData?.splitIntoInstallments?.[0]?.value === 0
          ? (Number(feeAmount) + VATRate) / Number(chrgData?.previewData?.noOfInstallments)
          : chrgData?.splitIntoInstallments?.[0]?.value === 1
          ? (Number(feeAmount) + VATRate) / Number(customTimesApplied)
          : Number(feeAmount) + VATRate // Fee + VAT
    } else if (chrgData?.amountCalculationType === 0) {
      totalAmount =
        chrgData?.splitIntoInstallments?.[0]?.value == 0
          ? (Number(chrgData?.fixedAmount) + VATRate) / Number(chrgData?.previewData?.noOfInstallments)
          : chrgData?.splitIntoInstallments?.[0]?.value === 1
          ? (Number(chrgData?.fixedAmount) + VATRate) / Number(customTimesApplied)
          : Number(chrgData?.fixedAmount) + VATRate // Fee + VAT
    }

    let currentDate = moment(startDate, 'YYYY-MM-DD')
    let dueDate =
      dueDateType?.[0]?.value === 1 && Number(daysAfterChargeIsApplied) > 1
        ? moment(startDate, 'YYYY-MM-DD').add(Number(daysAfterChargeIsApplied), 'days')
        : dueDateType?.[0]?.value === 1 && Number(daysAfterChargeIsApplied) === 1
        ? moment(startDate, 'YYYY-MM-DD').add(Number(daysAfterChargeIsApplied), 'day')
        : moment(startDate, 'YYYY-MM-DD')

    console.log(dueDateType?.[0]?.value, daysAfterChargeIsApplied)

    // Parse the start date
    if (chrgData?.startApplying?.[0]?.value === 1 || chrgData?.startApplying?.[0]?.value === 2) {
      currentDate = moment(chrgData?.previewData?.startApplingReceivedDate, 'YYYY-MM-DD')
      dueDate =
        dueDateType?.[0]?.value === 1 && daysAfterChargeIsApplied > 1
          ? moment(chrgData?.previewData?.startApplingReceivedDate, 'YYYY-MM-DD').add(daysAfterChargeIsApplied, 'days')
          : dueDateType?.[0]?.value === 1 && daysAfterChargeIsApplied === 1
          ? moment(chrgData?.previewData?.startApplingReceivedDate, 'YYYY-MM-DD').add(daysAfterChargeIsApplied, 'day')
          : moment(chrgData?.previewData?.startApplingReceivedDate, 'YYYY-MM-DD')
    }

    if (splitIntoInstallments?.[0]?.value === 0) {
      const start: any = chrgData?.previewData?.startDate
      const startDate = new Date(start)
      const end: any = chrgData?.previewData?.endDate
      const endDate = new Date(end)
      const dates = calculateInstallmentDates(startDate, endDate, chrgData?.previewData?.noOfInstallments)
      for (let i = 0; i < Number(chrgData?.previewData?.noOfInstallments); i++) {
        chargeSeries.push({
          appliedDate: moment(dates[i]).format('DD.MM.YYYY'),
          dueDate: moment(dates[i]).format('DD.MM.YYYY'),
          chargeAmount: formatAmount(totalAmount),
        })
      }
    } else if (splitIntoInstallments?.[0]?.value === 1) {
      let i = 0
      let condition =
        chrgData?.splitIntoInstallments?.[0]?.value === 0
          ? Number(chrgData?.previewData?.noOfInstallments)
          : chrgData?.splitIntoInstallments?.[0]?.value === 1
          ? Number(customTimesApplied)
          : 0
      for (i = 0; i < condition; i++) {
        // Add a new entry in chargeSeries
        chargeSeries.push({
          appliedDate: currentDate.format('DD.MM.YYYY'),
          dueDate: dueDate.format('DD.MM.YYYY'),
          chargeAmount: formatAmount(totalAmount),
        })

        // Increment date based on frequency type
        if (customFrequencyType?.[0]?.value === 1) {
          currentDate = currentDate.add(7, 'days')
          dueDate = dueDate.add(7, 'days')
        } else if (customFrequencyType?.[0]?.value === 2) {
          currentDate = currentDate.add(1, 'month')
          dueDate = dueDate.add(1, 'month')
        } else if (customFrequencyType?.[0]?.value === 0) {
          currentDate = currentDate.add(1, 'day')
          dueDate = dueDate.add(1, 'day')
        }
      }
    } else if (splitIntoInstallments?.[0]?.value === 2) {
      chargeSeries.push({
        appliedDate: moment(currentDate).format('DD.MM.YYYY'),
        dueDate: moment(dueDate).format('DD.MM.YYYY'),
        chargeAmount: formatAmount(totalAmount),
      })
    }

    // Update chrgData with the new chargeSeries
    const updatedChargeData = {...chrgData}
    updatedChargeData.previewData.chargeSeries = chargeSeries
    setChrgData(updatedChargeData)
    setShowPreviewCharges(true)
    console.log('Charge Series Generated:', chargeSeries)
  }

  const handleDateChange = (date: any) => {
    const updateChargeData = {...chrgData}
    if (date != null) {
      updateChargeData.previewData.startDate = date.format('YYYY-MM-DD')

      // Calculate end date (365 days later)
      const endDate = date.add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD')
      updateChargeData.previewData.endDate = endDate
    } else {
      // Reset both dates if date is null
      updateChargeData.previewData.startDate = null
      updateChargeData.previewData.endDate = null
      updateChargeData.previewData.startApplingReceivedDate = null
    }
    setChrgData(updateChargeData)
  }

  const disabledDate = (currentDate: any) => {
    const startDate = chrgData?.previewData?.startDate ? moment(chrgData.previewData.startDate, 'YYYY-MM-DD') : null
    const endDate = chrgData?.previewData?.endDate ? moment(chrgData.previewData.endDate, 'YYYY-MM-DD') : null

    // Return true to disable the date, false to allow it
    return (startDate && currentDate.isBefore(startDate, 'day')) || (endDate && currentDate.isAfter(endDate, 'day'))
  }

  const showInputPreview1 =
    chrgData?.type == 0 &&
    chrgData?.amountCalculationType == 1 &&
    chrgData?.feePercentage != null &&
    chrgData?.selectLeaseChargeType?.length > 0 &&
    // chrgData?.VATPercentage != null &&
    chrgData?.minimumAmount != null &&
    chrgData?.splitIntoInstallments?.length > 0 &&
    chrgData?.dueDateType?.length > 0 &&
    chrgData?.autoInvoicingEnabled?.length > 0 &&
    chrgData?.invoicingType != null

  // Frequency ---- custom and as per rent installment
  const showInputPreview2 =
    (showInputPreview1 &&
      chrgData?.splitIntoInstallments?.[0]?.value == 1 &&
      chrgData?.customTimesApplied !== null &&
      chrgData?.startApplying?.length > 0 &&
      chrgData?.customFrequencyType?.length > 0) ||
    (showInputPreview1 && chrgData?.splitIntoInstallments?.[0]?.value == 0) ||
    (showInputPreview1 && chrgData?.splitIntoInstallments?.[0]?.value == 2)

  // based on due date
  const showInputPreview3 =
    (showInputPreview2 && chrgData?.dueDateType?.[0]?.value == 1 && chrgData?.daysAfterChargeIsApplied != null) ||
    (showInputPreview2 && chrgData?.dueDateType?.[0]?.value == 0) ||
    (showInputPreview2 && chrgData?.dueDateType?.[0]?.value == 2)

  // based on Start Appyling When % Received
  const showInputPreview4 =
    (showInputPreview3 &&
      chrgData?.startApplying?.[0]?.value == 1 &&
      chrgData?.startApplyPercentagePaymentReceived != null &&
      chrgData?.startApplySelectChargeLeaseFeeListType?.length > 0) ||
    (showInputPreview3 && chrgData?.startApplying?.[0]?.value == 0) ||
    (showInputPreview3 && chrgData?.startApplying?.[0]?.value == 2) ||
    (showInputPreview3 && chrgData?.splitIntoInstallments?.[0]?.value == 0)

  const showInputPreviewSections = showInputPreview4
  // console.log('showInputPreview1',showInputPreview1)
  // console.log('showInputPreview2',showInputPreview2)
  // console.log('showInputPreview3',showInputPreview3)

  const showInputPreviewFixed1 =
    chrgData?.type == 0 &&
    chrgData?.amountCalculationType == 0 &&
    chrgData?.fixedAmount != null &&
    // chrgData?.VATPercentage != null &&
    chrgData?.splitIntoInstallments?.length > 0 &&
    chrgData?.dueDateType?.length > 0 &&
    chrgData?.autoInvoicingEnabled?.length > 0 &&
    chrgData?.invoicingType != null

  // Frequency ---- custom and as per rent installment
  const showInputPreviewFixed2 =
    (showInputPreviewFixed1 &&
      chrgData?.splitIntoInstallments?.[0]?.value == 1 &&
      chrgData?.customTimesApplied !== null &&
      chrgData?.startApplying?.length > 0 &&
      chrgData?.customFrequencyType?.length > 0) ||
    (showInputPreviewFixed1 && chrgData?.splitIntoInstallments?.[0]?.value == 0) ||
    (showInputPreviewFixed1 && chrgData?.splitIntoInstallments?.[0]?.value == 2)

  const showInputPreviewFixed3 =
    (showInputPreviewFixed2 && chrgData?.dueDateType?.[0]?.value == 1 && chrgData?.daysAfterChargeIsApplied != null) ||
    (showInputPreviewFixed2 && chrgData?.dueDateType?.[0]?.value == 0) ||
    (showInputPreviewFixed2 && chrgData?.dueDateType?.[0]?.value == 2)

  const showInputPreviewFixed4 =
    (showInputPreviewFixed3 &&
      chrgData?.startApplying?.[0]?.value == 1 &&
      chrgData?.startApplyPercentagePaymentReceived != null &&
      chrgData?.startApplySelectChargeLeaseFeeListType?.length > 0) ||
    (showInputPreviewFixed3 && chrgData?.startApplying?.[0]?.value == 0) ||
    (showInputPreviewFixed3 && chrgData?.startApplying?.[0]?.value == 2) ||
    (showInputPreviewFixed3 && chrgData?.splitIntoInstallments?.[0]?.value == 0)

  const showInputFixedPreviewSections = showInputPreviewFixed4

  const disablePreviewCTA =
    chrgData.previewData.feeAmount == null ||
    chrgData.previewData.startDate == null ||
    ((chrgData?.selectLeaseChargeType?.[0]?.label == 'Single Rent Installment' ||
      chrgData?.splitIntoInstallments?.[0]?.value == 0) &&
      chrgData.previewData.noOfInstallments == null) ||
    (chrgData?.startApplying?.[0]?.value == 1 && chrgData?.previewData?.startApplingReceivedDate == null) ||
    (chrgData?.startApplying?.[0]?.value == 2 && chrgData?.previewData?.startApplingReceivedDate == null)

  const disablePreviewCTAFixed =
    chrgData.previewData.startDate == null ||
    (chrgData?.splitIntoInstallments?.[0]?.value == 0 && chrgData.previewData.noOfInstallments == null) ||
    (chrgData?.startApplying?.[0]?.value == 1 && chrgData?.previewData?.startApplingReceivedDate == null) ||
    (chrgData?.startApplying?.[0]?.value == 2 && chrgData?.previewData?.startApplingReceivedDate == null)

  useEffect(() => {
    calculateFeeAmount()
  }, [
    chrgData?.selectLeaseChargeType,
    chrgData?.previewData?.totalRentValue,
    chrgData?.feePercentage,
    chrgData?.previewData?.noOfInstallments,
    chrgData.previewData.singleRentInstallment,
  ])

  useEffect(() => {
    calculateVATAmount()
  }, [
    chrgData?.VATPercentage,
    chrgData?.previewData?.feeAmount,
    chrgData?.previewData?.totalRentValue,
    chrgData?.previewData?.noOfInstallments,
  ])

  useEffect(() => {
    calculateTotalAmount()
  }, [
    chrgData?.previewData?.feeAmount,
    chrgData?.fixedAmount,
    chrgData?.previewData?.VATRate,
    chrgData?.previewData?.noOfInstallments,
    chrgData?.previewData?.startDate,
    chrgData?.previewData?.endDate,
  ])

  useEffect(() => {
    calculateDailyRate()
  }, [
    chrgData?.previewData?.feeAmount,
    chrgData?.previewData?.startDate,
    chrgData?.previewData?.endDate,
    chrgData?.previewData?.noOfInstallments,
  ])

  useEffect(() => {
    calculateSingleRentInstllment()
  }, [chrgData?.previewData?.totalRentValue, chrgData?.previewData?.noOfInstallments])

  // useEffect(() => {
  //   let IsSubPctAndChrgFilled = false
  //   if (chrgData?.startApplying?.length > 0 && chrgData?.startApplying?.[0].value === 1) {
  //     if (chrgData?.startApplyPercentagePaymentReceived && chrgData?.startApplySelectChargeLeaseFeeListType?.length > 0) {
  //       IsSubPctAndChrgFilled = true
  //     } else {
  //       IsSubPctAndChrgFilled = false
  //     }
  //   } else if (chrgData?.startApplying?.length > 0 && chrgData?.startApplying?.[0].value !== 1) {
  //     IsSubPctAndChrgFilled = true
  //   }

  //   let IsDayAftrChrgApldFilled = false
  //   if (chrgData?.dueDateType?.length > 0 && chrgData?.dueDateType?.[0]?.value === 1) {
  //     if (chrgData?.daysAfterChargeIsApplied) {
  //       IsDayAftrChrgApldFilled = true
  //     } else {
  //       IsDayAftrChrgApldFilled = false
  //     }
  //   } else if (chrgData?.dueDateType?.length > 0 && chrgData?.dueDateType?.[0]?.value !== 1) {
  //     IsDayAftrChrgApldFilled = true
  //   }

  //   if (
  //     chrgData?.feePercentage &&
  //     chrgData?.selectLeaseChargeType?.length &&
  //     chrgData?.VATPercentage &&
  //     chrgData?.VATPercentage &&
  //     chrgData?.minimumAmount &&
  //     chrgData?.splitIntoInstallments?.length > 0 &&
  //     chrgData?.customTimesApplied &&
  //     chrgData?.invoicingType !== null &&
  //     chrgData?.customFrequencyType?.length > 0 &&
  //     chrgData?.autoInvoicingEnabled?.length > 0 &&
  //     IsSubPctAndChrgFilled &&
  //     IsDayAftrChrgApldFilled
  //   ) {
  //     setIsFilledAllRequiredFields(true)
  //   } else {
  //     setIsFilledAllRequiredFields(false)
  //   }
  // }, [chrgData])

  useEffect(() => {
    const updatedChargeData = {...chrgData}
    updatedChargeData.previewData.noOfInstallments = null
    updatedChargeData.previewData.startDate = null
    updatedChargeData.previewData.endDate = null
    setChrgData(updatedChargeData)
  }, [chrgData?.selectLeaseChargeType, chrgData?.splitIntoInstallments])

  useEffect(() => {
    const updatedChargeData = {...chrgData}
    updatedChargeData.previewData.startApplingReceivedDate = null
    setChrgData(updatedChargeData)
  }, [chrgData?.startApplying])

  useEffect(() => {
    const updatedChargeData = {...chrgData}
    updatedChargeData.daysAfterChargeIsApplied = null
    setChrgData(updatedChargeData)
  }, [chrgData?.dueDateType])

  useEffect(() => {
    const updatedChargeData = {...chrgData}
    updatedChargeData.previewData.singleRentInstallment = null
    setChrgData(updatedChargeData)
  }, [chrgData?.selectLeaseChargeType])

  useEffect(() => {
    const updatedChargeData = {...chrgData}
    updatedChargeData.previewData.chargeSeries = []
    setChrgData(updatedChargeData)
  }, [
    chrgData.previewData?.totalRentValue,
    chrgData.previewData?.startDate,
    chrgData.previewData?.endDate,
    chrgData.previewData?.noOfInstallments,
    chrgData?.previewData?.startApplingReceivedDate,

    // chrgData?.feePercentage,
    // chrgData?.selectLeaseChargeType,
    // chrgData?.VATPercentage,
    // chrgData?.minimumAmount,
    // chrgData?.splitIntoInstallments,
    // chrgData?.customTimesApplied,
    // chrgData?.invoicingType !== null,
    // chrgData?.customFrequencyType,
    // chrgData?.autoInvoicingEnabled,
    // chrgData?.dueDateType,
    // chrgData?.daysAfterChargeIsApplied,
    // chrgData?.startApplying,
    // chrgData?.startApplyPercentagePaymentReceived,
    // chrgData?.startApplySelectChargeLeaseFeeListType,
  ])

  useEffect(() => {
    if (errors['noOfInstallments']?.type == 'min' || errors['noOfInstallments']?.type == 'max') {
      const updatedChargeData = {...chrgData}
      updatedChargeData.previewData.feeAmount = null
      updatedChargeData.previewData.VATRate = null
      updatedChargeData.previewData.dailyRate = null
      updatedChargeData.previewData.singleRentInstallment = null
      setChrgData(updatedChargeData)
    }
  }, [chrgData])


  useEffect(() => {
    resetPreview()

    setShowPreviewCharges(false)
  }, [isChrgDataChanged])

  const resetPreview = () => {
    const updateLatePaymentFields = {...chrgData}
    updateLatePaymentFields.previewData.totalRentValue = null
    updateLatePaymentFields.previewData.totalValue = null // total amount with vat
    updateLatePaymentFields.previewData.noOfInstallments = null
    updateLatePaymentFields.previewData.startDate = null
    updateLatePaymentFields.previewData.endDate = null
    updateLatePaymentFields.previewData.startApplingReceivedDate = null
    updateLatePaymentFields.previewData.feeAmount = null
    // updateLatePaymentFields.previewData.VATRate = null
    if (chrgData?.amountCalculationType === 1) updateLatePaymentFields.previewData.VATRate = null
    updateLatePaymentFields.previewData.chargeSeries = []
    setChrgData(updateLatePaymentFields)
  }

  return (
    <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3 card-grey h-100'>
      <div className='card-body pt-0 mt-5 py-0 px-3'>
        <div className='d-flex align-items-center justify-content-between'>
          <h3 className='page-heading m-0 white-dark-theme-color fnt-700'>Preview Charges</h3>
          <div
            className='filter-div d-flex align-items-center justify-content-center cursor-pointer ms-auto'
            onClick={resetPreview}
            style={{zIndex: '20'}}
          >
            <img src={sync} height={18} width={18} />
          </div>
        </div>
        <p className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color mb-10' style={{color: '#a4a4a4'}}>
          Fill out the settings below and click preview to view how the charges will apply
        </p>

        {showInputPreviewSections &&
          errors['installmentFrequency']?.type != 'max' &&
          errors['installmentFrequency']?.type != 'min' && (
            <Row className='d-block charge-preview'>
              {/* Total Amount */}
              {chrgData?.selectLeaseChargeType?.length > 0 && (
                <Col>
                  <FlexedInput
                    isDisabled={false}
                    iconSize={30}
                    required={false}
                    setRightPosition='10px'
                    type='number'
                    name='totalRentValue'
                    value={chrgData?.previewData?.totalRentValue !== null ? chrgData?.previewData?.totalRentValue : ''}
                    label={`${
                      chrgData?.selectLeaseChargeType?.[0]?.label == 'Total Rent Value' ||
                      chrgData?.selectLeaseChargeType?.[0]?.label == 'Single Rent Installment'
                        ? 'Total Rent Value'
                        : `${chrgData?.selectLeaseChargeType?.[0]?.label}`
                    }`}
                    firstPlaceholder='Enter Amount'
                    secondPlaceHolder='AED'
                    onChange={(newValue: any) => {
                      const updateLatePaymentFields = {...chrgData}
                      if (newValue) {
                        updateLatePaymentFields.previewData.totalRentValue = newValue
                        setShowPreviewCharges(false)
                      } else {
                        updateLatePaymentFields.previewData.totalRentValue = null
                      }
                      setChrgData(updateLatePaymentFields)
                    }}
                  />
                </Col>
              )}
              {/* No Of  Installments */}
              {chrgData?.selectLeaseChargeType?.length > 0 &&
                (chrgData?.selectLeaseChargeType?.[0]?.label?.toLowerCase() === 'single rent installment' ||
                  chrgData?.splitIntoInstallments?.[0]?.value === 0) && (
                  <Col className='mt-5'>
                    {/* <FlexedInput */}
                    <FlexedInputFrequency
                      minValue={1}
                      maxValue={12}
                      isDisabled={false}
                      iconSize={30}
                      required={false}
                      setRightPosition='10px'
                      type='number'
                      name='noOfInstallments'
                      // value={chrgData?.previewData?.noOfInstallments !== null ? chrgData?.previewData?.noOfInstallments : ''}
                      value={
                        chrgData?.previewData?.noOfInstallments != null ? chrgData?.previewData?.noOfInstallments : ''
                      }
                      label={`No. of Installments`}
                      firstPlaceholder='Enter Installments'
                      secondPlaceHolder=''
                      onChange={(newValue: any) => {
                        const updateLatePaymentFields = {...chrgData}
                        if (newValue) {
                          updateLatePaymentFields.previewData.noOfInstallments = newValue
                          setShowPreviewCharges(false)
                        } else {
                          updateLatePaymentFields.previewData.noOfInstallments = null
                        }
                        setChrgData(updateLatePaymentFields)
                      }}
                    />
                  </Col>
                )}

              {/* Lease Start Date  */}
              {/* {chrgData?.startApplying?.[0]?.value == 0 || chrgData?.startApplying?.[0]?.value === 1 && ( */}
              <Col className='mt-5 lease-charges'>
                <div className={`d-flex align-items-center singleCharge`}>
                  <div className='percentage-label-container'>
                    <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                      Lease Start Date
                    </label>
                  </div>

                  <DatePicker
                    key={pickerKey}
                    className={`w-100 ${chrgData?.previewData?.startDate ? 'isFilled bg-white' : ''}`}
                    value={
                      chrgData?.previewData?.startDate
                        ? moment(chrgData.previewData.startDate, 'YYYY-MM-DD')
                        : undefined
                    }
                    format='DD.MM.YYYY'
                    // disabledDate={disabledDate}
                    onChange={(date: any) => {
                      if (date) {
                        const startDate = date.format('YYYY-MM-DD')
                        const endDate = moment(date).add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD')

                        const isAppliedStartDateInRange = moment(
                          chrgData.previewData.startApplingReceivedDate
                        ).isBetween(startDate, endDate, 'day', '[]')

                        const updatedChrgData = {
                          ...chrgData,
                          previewData: {
                            ...chrgData.previewData,
                            startDate: startDate,
                            endDate: endDate, // Set the correctly calculated endDate
                            startApplingReceivedDate: isAppliedStartDateInRange
                              ? chrgData.previewData.startApplingReceivedDate
                              : null,
                          },
                        }
                        setShowPreviewCharges(false)
                        setChrgData(updatedChrgData)
                      } else {
                        const updatedChrgData = {
                          ...chrgData,
                          previewData: {
                            ...chrgData.previewData,
                            startDate: null,
                            endDate: null, // Reset endDate when startDate is cleared
                            startApplingReceivedDate: null,
                          },
                        }

                        setChrgData(updatedChrgData)

                        setPickerKey(Date.now())
                      }

                      // setTimeout(() => {
                      //   setPreviewCalculations(Date.now())
                      // }, 200)
                    }}
                    placeholder='Select Date'
                    defaultPickerValue={moment()}
                  />
                </div>
              </Col>
              {/* )} */}

              {/* Lease End Date  */}
              {/* {chrgData?.startApplying?.[0]?.value === 0 || chrgData?.startApplying?.[0]?.value === 1  && ( */}
              <Col className='mt-7'>
                <div className={`d-flex align-items-center`}>
                  <div className=''>
                    <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                      Lease End Date
                    </label>
                  </div>
                  <label className={`white-dark-theme-color ps-3`}>
                    {chrgData?.previewData?.endDate != null
                      ? moment(chrgData?.previewData?.endDate).format('DD.MM.YYYY')
                      : '-'}
                  </label>
                </div>
              </Col>
              {/* )} */}

              {chrgData?.selectLeaseChargeType?.length > 0 &&
                chrgData?.selectLeaseChargeType?.[0]?.label?.toLowerCase() === 'single rent installment' && (
                  <Col className='mt-5'>
                    <div className={`d-flex align-items-center w-100`}>
                      <div className='percentage-label-container w-50'>
                        <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                          Rent Installment
                        </label>
                      </div>
                      <label className={`white-dark-theme-color w-50 text-start ps-3`}>
                        {`${
                          chrgData?.previewData?.singleRentInstallment
                            ? `${formatAmount(chrgData?.previewData?.singleRentInstallment)} AED`
                            : '- AED'
                        }`}
                      </label>
                    </div>
                  </Col>
                )}

              {chrgData?.selectLeaseChargeType?.length > 0 &&
                (chrgData?.splitIntoInstallments?.[0]?.value === 1 ||
                  chrgData?.splitIntoInstallments?.[0]?.value === 2) && (
                  <Col sm={12} className='mt-5'>
                    <div className={`d-flex align-items-center w-100`}>
                      <div className='percentage-label-container w-50'>
                        <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                          Split
                        </label>
                      </div>
                      {chrgData?.splitIntoInstallments?.[0]?.value === 1 ? (
                        <label className={`white-dark-theme-color w-50 text-start ps-3`}>
                          {`${chrgData?.customTimesApplied ? chrgData?.customTimesApplied : '-'} Installments`}
                        </label>
                      ) : (
                        <label className={`white-dark-theme-color w-50 text-start ps-3`}>No Split</label>
                      )}
                    </div>
                  </Col>
                )}

              {/* Charge Details */}
              {/* Fee Amount */}
            </Row>
          )}

        {showInputPreviewSections &&
          errors['installmentFrequency']?.type != 'max' &&
          errors['installmentFrequency']?.type != 'min' && (
            <Row>
              <Col sm={12} className='mt-15 pt-10'>
                <div className={`d-flex align-items-center w-100`}>
                  <div className='percentage-label-container w-50'>
                    <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                      Fee Amount
                    </label>
                  </div>
                  <label className={`white-dark-theme-color w-50 text-end pe-2`}>
                    {chrgData?.previewData?.feeAmount != null
                      ? `${formatAmount(chrgData?.previewData?.feeAmount)} AED`
                      : '- AED'}
                  </label>
                </div>
              </Col>

              {/* VAT Rate */}
              <Col sm={12} className='mt-5'>
                <div className={`d-flex align-items-center w-100`}>
                  <div className='percentage-label-container w-50'>
                    <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                      VAT Rate
                    </label>
                  </div>
                  <label className={`white-dark-theme-color w-50 text-end pe-2`}>
                    {chrgData?.previewData?.VATRate != null
                      ? `${formatAmount(chrgData?.previewData?.VATRate)} AED`
                      : '- AED'}
                  </label>
                </div>
              </Col>

              {/* Daily Rate */}
              <Col sm={12} className='mt-5'>
                <div className={`d-flex align-items-center w-100`}>
                  <div className='percentage-label-container w-50'>
                    <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                      Daily Rate
                    </label>
                  </div>
                  <label className={`white-dark-theme-color w-50 text-end pe-2`}>
                    {chrgData?.previewData?.dailyRate != null
                      ? `${formatAmount(chrgData?.previewData?.dailyRate)} AED`
                      : '- AED'}
                  </label>
                </div>
              </Col>
            </Row>
          )}

        {showInputFixedPreviewSections &&
          errors['installmentFrequency']?.type != 'max' &&
          errors['installmentFrequency']?.type != 'min' && (
            <Row className='charge-preview'>
              {/* No Of  Installments */}
              {chrgData?.splitIntoInstallments?.[0]?.value === 0 && (
                <Col sm={12} className='mt-5'>
                  {/* <FlexedInput
                      minValue={1}
                      maxValue={12}
                    isDisabled={false}
                    iconSize={30}
                    required={false}
                    setRightPosition='10px'
                    type='number'
                    name='noOfInstallments'
                    // value={chrgData?.previewData?.noOfInstallments !== null ? chrgData?.previewData?.noOfInstallments : ''}
                    value={chrgData?.previewData?.noOfInstallments}
                    label={`No. of Installments`}
                    firstPlaceholder='Enter Installments'
                    secondPlaceHolder=''
                    onChange={(newValue: any) => {
                      const updateLatePaymentFields = {...chrgData}
                      console.log(newValue)
                      if (newValue) {
                        updateLatePaymentFields.previewData.noOfInstallments = newValue
                        setShowPreviewCharges(false)
                      } else {
                        updateLatePaymentFields.previewData.noOfInstallments = null
                      }
                      setChrgData(updateLatePaymentFields)
                    }}
                  /> */}
                  <FlexedInputFrequency
                      minValue={1}
                      maxValue={12}
                      isDisabled={false}
                      iconSize={30}
                      required={false}
                      setRightPosition='10px'
                      type='number'
                      name='noOfInstallments'
                      // value={chrgData?.previewData?.noOfInstallments !== null ? chrgData?.previewData?.noOfInstallments : ''}
                      value={
                        chrgData?.previewData?.noOfInstallments != null ? chrgData?.previewData?.noOfInstallments : ''
                      }
                      label={`No. of Installments`}
                      firstPlaceholder='Enter Installments'
                      secondPlaceHolder=''
                      onChange={(newValue: any) => {
                        const updateLatePaymentFields = {...chrgData}
                        if (newValue) {
                          updateLatePaymentFields.previewData.noOfInstallments = newValue
                          setShowPreviewCharges(false)
                        } else {
                          updateLatePaymentFields.previewData.noOfInstallments = null
                        }
                        setChrgData(updateLatePaymentFields)
                      }}
                    />
                </Col>
              )}
              {/* Lease Start Date  */}
              <Col sm={12} className='mt-5 lease-charges'>
                <div className={`d-flex align-items-center singleCharge`}>
                  <div className='percentage-label-container'>
                    <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                      Lease Start Date
                    </label>
                  </div>
                  <DatePicker
                    key={pickerKey}
                    className={`w-100 ${chrgData?.previewData?.startDate ? 'isFilled bg-white' : ''}`}
                    value={
                      chrgData?.previewData?.startDate
                        ? moment(chrgData.previewData.startDate, 'YYYY-MM-DD')
                        : undefined
                    }
                    format='DD.MM.YYYY'
                    disabledDate={disabledDate}
                    onChange={(date: any) => {
                      if (date) {
                        const startDate = date.format('YYYY-MM-DD')
                        const endDate = moment(date).add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD')

                        const updatedChrgData = {
                          ...chrgData,
                          previewData: {
                            ...chrgData.previewData,
                            startDate: startDate,
                            endDate: endDate, // Set the correctly calculated endDate
                          },
                        }
                        setShowPreviewCharges(false)
                        setChrgData(updatedChrgData)
                      } else {
                        const updatedChrgData = {
                          ...chrgData,
                          previewData: {
                            ...chrgData.previewData,
                            startDate: null,
                            endDate: null, // Reset endDate when startDate is cleared,
                            startApplingReceivedDate: null,
                          },
                        }

                        setChrgData(updatedChrgData)

                        setPickerKey(Date.now())
                      }
                    }}
                    placeholder='Select Date'
                    defaultPickerValue={moment()}
                  />
                </div>
              </Col>

              {/* Lease End Date  */}
              <Col sm={12} className='mt-7'>
                <div className={`d-flex align-items-center w-100`}>
                  <div className=''>
                    <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                      Lease End Date
                    </label>
                  </div>
                  <label className={`white-dark-theme-color ps-2`}>
                    {chrgData?.previewData?.endDate != null
                      ? moment(chrgData?.previewData?.endDate).format('DD.MM.YYYY')
                      : '-'}
                  </label>
                </div>
              </Col>

              {(chrgData?.splitIntoInstallments?.[0]?.value === 0 ||
                chrgData?.splitIntoInstallments?.[0]?.value === 1 ||
                chrgData?.splitIntoInstallments?.[0]?.value === 2) && (
                <Col sm={12} className='mt-5'>
                  <div className={`d-flex align-items-center w-100`}>
                    <div className='percentage-label-container w-50'>
                      <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                        Split
                      </label>
                    </div>
                    {chrgData?.splitIntoInstallments?.[0]?.value === 0 ? (
                      <label className={`white-dark-theme-color w-50 text-start ps-2`}>
                        {`${
                          chrgData?.previewData?.noOfInstallments ? chrgData?.previewData?.noOfInstallments : '-'
                        } Installments`}
                      </label>
                    ) : chrgData?.splitIntoInstallments?.[0]?.value === 1 ? (
                      <label className={`white-dark-theme-color w-50 text-start ps-2`}>
                        {`${chrgData?.customTimesApplied ? chrgData?.customTimesApplied : '-'} Installments`}
                      </label>
                    ) : (
                      <label className={`white-dark-theme-color w-50 text-start ps-2`}>No Split</label>
                    )}
                  </div>
                </Col>
              )}
            </Row>
          )}

        {showInputFixedPreviewSections &&
          errors['installmentFrequency']?.type != 'max' &&
          errors['installmentFrequency']?.type != 'min' && (
            <>
              <Row>
                {/* Fee Amount */}
                <Col sm={12} className='mt-15 pt-10'>
                  <div className={`d-flex align-items-center w-100`}>
                    <div className='percentage-label-container w-50'>
                      <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                        Fee Amount
                      </label>
                    </div>
                    <label className={`white-dark-theme-color w-50 text-end pe-2`}>
                      {chrgData?.fixedAmount != null ? `${formatAmount(chrgData?.fixedAmount)} AED` : '- AED'}
                    </label>
                  </div>
                </Col>

                {/* VAT Rate */}
                <Col sm={12} className='mt-5'>
                  <div className={`d-flex align-items-center w-100`}>
                    <div className='percentage-label-container w-50'>
                      <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                        VAT Rate
                      </label>
                    </div>
                    <label className={`white-dark-theme-color w-50 text-end pe-2`}>
                      {chrgData?.previewData?.VATRate != null
                        ? `${formatAmount(chrgData?.previewData?.VATRate)} AED`
                        : '- AED'}
                    </label>
                  </div>
                </Col>

                {/* Daily Rate */}
                <Col sm={12} className='mt-5'>
                  <div className={`d-flex align-items-center w-100`}>
                    <div className='percentage-label-container w-50'>
                      <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                        Daily Rate
                      </label>
                    </div>
                    <label className={`white-dark-theme-color w-50 text-end pe-2`}>
                      {chrgData?.previewData?.dailyRate != null
                        ? `${formatAmount(chrgData?.previewData?.dailyRate)} AED`
                        : '- AED'}
                    </label>
                  </div>
                </Col>
              </Row>
            </>
          )}

        {(chrgData?.startApplying?.[0]?.value === 1 || chrgData?.startApplying?.[0]?.value === 2) &&
          (chrgData?.amountCalculationType === 1 ? showInputPreviewSections : showInputFixedPreviewSections) && (
            <Row className='charge-preview lease-charges'>
              <Col>
                <h3 className='page-heading m-0 white-dark-theme-color fnt-700 mt-2'>Charge Starts Applying</h3>
                {chrgData?.startApplying?.[0]?.value === 1 && (
                  <div className={`d-flex align-items-center w-100 mt-3`}>
                    <div className='percentage-label-container' style={{width: '55%'}}>
                      <label className={`percentage-input-label white-dark-theme-color fnt-500`}>
                        % of Payments Received
                      </label>
                    </div>
                    <label className={`fnt-500 light-dark-theme-color text-end`} style={{width: '45%'}}>
                      {`${
                        chrgData?.startApplyPercentagePaymentReceived
                          ? `${chrgData?.startApplyPercentagePaymentReceived}%`
                          : '- %'
                      }`}
                    </label>
                  </div>
                )}

                <div className={`d-flex align-items-center singleCharge mt-8`}>
                  <div className='percentage-label-container'>
                    <label className={`percentage-input-label white-dark-theme-color fnt-400 status-w-130`}>
                      {`${
                        chrgData?.startApplying?.[0]?.value === 1
                          ? 'Date Received'
                          : chrgData?.startApplying?.[0]?.value === 2
                          ? 'Date Applied'
                          : '-'
                      }`}
                    </label>
                  </div>
                  <DatePicker
                    key={pickerKey}
                    className={`w-100 ${chrgData?.previewData?.startApplingReceivedDate ? 'isFilled' : ''}`}
                    value={
                      chrgData?.previewData?.startApplingReceivedDate
                        ? moment(chrgData?.previewData?.startApplingReceivedDate, 'YYYY-MM-DD')
                        : undefined
                    }
                    format='DD.MM.YYYY'
                    defaultPickerValue={moment()}
                    disabledDate={disabledDate}
                    onChange={(date: any) => {
                      const updatedChrgData = {
                        ...chrgData,
                        previewData: {
                          ...chrgData.previewData,
                          startApplingReceivedDate: date ? date.format('YYYY-MM-DD') : null,
                        },
                      }
                      setShowPreviewCharges(false)
                      setChrgData(updatedChrgData)
                      setPickerKey(Date.now())
                    }}
                    placeholder='Select Date'
                    disabled={chrgData?.previewData?.startDate == null}
                  />
                </div>
              </Col>
            </Row>
          )}

        {chrgData?.amountCalculationType === 1 ? (
          <button
            type='button'
            className='btn btn-sm fw-bold px-2 mx-auto justify-content-center blue-btn status-w-200 mt-10'
            onClick={previewHandler}
            disabled={
              !showInputPreviewSections ||
              disablePreviewCTA ||
              showPreviewCharges ||
              errors['installmentFrequency']?.type == 'max' ||
              errors['installmentFrequency']?.type == 'min' ||
              errors['noOfInstallments']?.type == 'min' ||
              errors['noOfInstallments']?.type == 'max'
            }
          >
            Preview
          </button>
        ) : (
          <button
            type='button'
            className='btn btn-sm fw-bold px-2 mx-auto justify-content-center blue-btn status-w-200 mt-10'
            onClick={previewHandler}
            disabled={
              !showInputFixedPreviewSections ||
              disablePreviewCTAFixed ||
              showPreviewCharges ||
              errors['installmentFrequency']?.type == 'max' ||
              errors['installmentFrequency']?.type == 'min' ||
              errors['noOfInstallments']?.type == 'min' ||
              errors['noOfInstallments']?.type == 'max'
            }
          >
            Preview
          </button>
        )}

        <>
          {chrgData?.amountCalculationType == null && (
            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 text-center px-5'>
              Please fill out all required field settings and then click ‘Preview’ to view the charges.
            </p>
          )}
          {/* 
          {!showInputPreviewSections && chrgData?.amountCalculationType === 1 && (
            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 text-center px-5'>
              Please fill out all required field settings and then click ‘Preview’ to view the charges.
            </p>
          )} */}

          {!showInputPreviewSections &&
          chrgData?.amountCalculationType === 1 &&
          (errors['installmentFrequency']?.type != 'max' || errors['installmentFrequency']?.type != 'min') ? (
            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 text-center px-5'>
              Please fill out all required field settings and then click ‘Preview’ to view the charges.
            </p>
          ) : chrgData?.amountCalculationType === 1 && errors['installmentFrequency']?.type == 'max' ? (
            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 text-center px-5'>
              {`Installments must be less than or equal to ${
                chrgData?.customFrequencyType?.[0]?.value == 0
                  ? '365'
                  : chrgData?.customFrequencyType?.[0]?.value == 1
                  ? '52'
                  : '12'
              }.`}
            </p>
          ) : chrgData?.amountCalculationType === 1 && errors['installmentFrequency']?.type == 'min' ? (
            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 text-center px-5'>
              Installments must be greater than 1.
            </p>
          ) : (
            <></>
          )}

          {/* {errors['installmentFrequency']?.type === "max" && (
          <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 text-center px-5'>
            Installments must be less than or equal to 52.
          </p>
        )} */}

          {!showInputFixedPreviewSections &&
          chrgData?.amountCalculationType === 0 &&
          (errors['installmentFrequency']?.type != 'max' || errors['installmentFrequency']?.type != 'min') ? (
            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 text-center px-5'>
              Please fill out all required field settings and then click ‘Preview’ to view the charges.
            </p>
          ) : chrgData?.amountCalculationType === 0 && errors['installmentFrequency']?.type == 'max' ? (
            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 text-center px-5'>
              {`Installments must be less than or equal to ${
                chrgData?.customFrequencyType?.[0]?.value == 0
                  ? '365'
                  : chrgData?.customFrequencyType?.[0]?.value == 1
                  ? '52'
                  : '12'
              }.`}
            </p>
          ) : chrgData?.amountCalculationType === 0 && errors['installmentFrequency']?.type == 'min' ? (
            <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 text-center px-5'>
              Installments must be greater than 1.
            </p>
          ) : (
            <></>
          )}
        </>

        {showPreviewCharges && (
          <>
            <Row className='mt-8'>
              {chrgData?.previewData.chargeSeries?.length > 0 &&
                chrgData?.previewData.chargeSeries?.map((dateItem: any, index: number) => {
                  return index === 0 ||
                    index === 1 ||
                    index === chrgData?.previewData.chargeSeries?.length - 2 ||
                    index === chrgData?.previewData.chargeSeries?.length - 1 ? (
                    <React.Fragment key={index}>
                      <Col className='mb-1 pe-6' sm={12}>
                        <label className='percentage-input-label white-dark-theme-color fnt-600 w-50'>
                          Charge {index + 1}
                        </label>
                        <label className='percentage-input-label white-dark-theme-color fnt-600 w-50 text-end'>
                          {formatAmount(dateItem?.chargeAmount)} {' AED'}
                        </label>
                      </Col>
                      <Col className='mb-5 pe-6' sm={12}>
                        <label className='percentage-input-label white-dark-theme-color fnt-400 w-50'>
                          Applied: {`${dateItem?.appliedDate ? dateItem?.appliedDate : '-'}`}
                          {/* Applied: {moment(dateItem?.appliedDate).format('DD.MM.YYYY')} */}
                        </label>

                        <label className='percentage-input-label white-dark-theme-color fnt-400 w-50 text-end'>
                          Due: {`${dateItem?.dueDate ? dateItem?.dueDate : '-'}`}
                          {/* Due: {moment(dateItem?.dueDate).format('DD.MM.YYYY')} */}
                        </label>
                      </Col>
                    </React.Fragment>
                  ) : (
                    <></>
                  )
                })}
            </Row>
          </>
        )}

        {chrgData?.previewData.chargeSeries?.length > 0 && <Row className='separator mx-2 my-3 mt-10'></Row>}

        {chrgData?.previewData.chargeSeries?.length > 0 && (
          <Row className='mb-10'>
            <Col className='mt-5 pe-6' sm={12}>
              <div className={`d-flex align-items-center w-100`}>
                <div className='percentage-label-container w-50'>
                  <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                    Total Split
                  </label>
                </div>
                {chrgData?.splitIntoInstallments?.[0]?.value === 0 ||
                chrgData?.splitIntoInstallments?.[0]?.value === 1 ? (
                  <label className={`white-dark-theme-color w-50 text-end`}>
                    {`${
                      chrgData?.splitIntoInstallments?.[0]?.value === 0 && chrgData?.previewData?.noOfInstallments
                        ? chrgData?.previewData?.noOfInstallments
                        : chrgData?.splitIntoInstallments?.[0]?.value === 1 && chrgData?.customTimesApplied
                        ? chrgData?.customTimesApplied
                        : '-'
                    } Installments`}
                  </label>
                ) : (
                  <label className={`white-dark-theme-color w-50 text-end`}>No Split</label>
                )}
              </div>
            </Col>

            {/* Sub-Total */}
            <Col className='mt-5 pe-6' sm={12}>
              <div className={`d-flex align-items-center w-100`}>
                <div className='percentage-label-container w-50'>
                  <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                    Sub-Total
                  </label>
                </div>
                {chrgData?.amountCalculationType === 1 ? (
                  <label className={`white-dark-theme-color w-50 text-end`}>
                    {chrgData?.previewData?.feeAmount != null
                      ? `${formatAmount(chrgData?.previewData?.feeAmount)} AED`
                      : '- AED'}
                  </label>
                ) : (
                  <label className={`white-dark-theme-color w-50 text-end`}>
                    {chrgData?.fixedAmount != null ? `${formatAmount(chrgData?.fixedAmount)} AED` : '- AED'}
                  </label>
                )}
              </div>
            </Col>

            {/* VAT */}
            <Col className='mt-5 pe-6' sm={12}>
              <div className={`d-flex align-items-center w-100`}>
                <div className='percentage-label-container w-50'>
                  <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>VAT</label>
                </div>
                <label className={`white-dark-theme-color w-50 text-end`}>
                  {chrgData?.previewData?.VATRate != null
                    ? `${formatAmount(chrgData?.previewData?.VATRate)} AED`
                    : '- AED'}
                </label>
              </div>
            </Col>

            {/* VAT */}
            <Col className='mt-5 pe-6' sm={12}>
              <div className={`d-flex align-items-center w-100`}>
                <div className='percentage-label-container w-50'>
                  <label className={`percentage-input-label white-dark-theme-color fnt-500 status-w-130`}>
                    Total Amount
                  </label>
                </div>
                <label className={`light-dark-theme-color w-50 text-end`}>
                  {chrgData?.previewData?.totalValue != null
                    ? `${formatAmount(chrgData?.previewData?.totalValue)} AED`
                    : '- AED'}
                </label>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}

export default SinglePreview
