import React, {useEffect, useState} from 'react'
import moment, {Moment} from 'moment'
import addWhite from '../../../../img/add-white.png'
import {ApiPut, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import correct from '../../../../img/correct.png'
import removed from '../../../../img/remove-filled.png'
import pencil from '../../../../img/edit-pen.png'
import trashImg from '../../../../img/trash.png'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import externalTeam from '../../../../img/external-team-green.png'
import {useParams} from 'react-router-dom'
import swal from 'sweetalert2'
import userIcon from '../../../../img/green-user-filled.png'
import {CircularProgress} from '@mui/material'
import uploadWhite from '../../../../img/upload-white.png'
import ModalLoader from '../../General/ModalLoader'
import {ApiPost, Bucket} from '../../../../apiCommon/helpers/API/ApiData'
import pdfDocument from '../../../../img/pdf.svg'
import docCocument from '../../../../img/doc.svg'
import fileIcon from '../../../../img/file.svg'
import videoImg from '../../../../img/video.png'
import pngImg from '../../../../img/png.png'
import jpgImg from '../../../../img/jpg.png'
import downloadIcon from '../../../../img/download.png'
import axios from 'axios'
import attachmentImg from '../../../../img/attchement.png'
import { capitalizeFirstLetter } from '../../../../Utilities/utils'

interface ButtonProps {
  modalData?: any
  coments?: any
  updateParent?: any
}

const BusinessCommentsOfMaintenance = ({modalData, coments, updateParent}: ButtonProps) => {
  const {id} = useParams()

  const [isLoading, setIsLoading] = useState<any>(false)

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const [comment, setComment] = useState<any>('')
  const [commentToEdit, setCommentToEdit] = useState<any>('')

  const [commentAttachments, setCommentAttachments] = useState<any>([])

  const [comments, setComments] = useState<any>(coments ? coments : [])
  const [commentError, setCommentError] = useState<any>(false)

  const [selectedInd, setSelectedInd] = useState<any>(-1)

  const [showMore, setShowMore] = useState<any>([])

  const convertUTCToLocalTime = (utcTimeString: any) => {
    const localDate = moment.utc(utcTimeString).local()
    return localDate.format('DD.MM.YYYY - HH:mm')
  }

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  //
  const updateCRMTicket = (data: any) => {
    setIsLoading(true)

    let comm: any = []
    data?.map((dt: any, ind: any) => {
      comm[ind] = dt
      comm[ind].createdBy = dt?.createdByUser?._id
    })

    const body = {
      id: id,
      businessComments: comm,
    }

    ApiPut(`corporate/crm_ticket`, body)
      .then((res: any) => {
        updateParent()
        setIsLoading(false)
      })
      .catch((err: any) => {
        setIsLoading(false)
        ErrorToast(err?.message)
      })
  }

  const handleUploadAttchment = async (e: any) => {
    e.preventDefault()

    const values = [...commentAttachments]

    if (e.target.files) {
      setShowLoader(true)

      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any =
          e.target.files[i]?.name?.charAt(0).toUpperCase() + e.target.files[i]?.name?.slice(1)
        const nameParts = name.split('.')
        const extension = nameParts.pop()
        let baseName = nameParts.join('.')

        // Check for duplicate names and append a number if necessary
        let uniqueName = baseName
        let counter = 1
        while (
          values?.some((attachment: any) => attachment.fileName === `${uniqueName}.${extension}`)
        ) {
          uniqueName = `${baseName}(${counter})`
          counter++
        }
        name = `${uniqueName}.${extension}`

        await ApiPost(`upload/compress_image/crm_ticket`, formdata)
          .then((res: any) => {
            values.push({
              fileURL: res?.data?.data?.image,
              fileName: name?.charAt(0).toUpperCase() + name?.slice(1),
              fileSize: 0,
              createdAt: '',
            })

            setUploadProgress(i + 1)
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }

      setCommentAttachments(values)
      setShowLoader(false)

      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      e.target.value = null
    }
  }

  const truncateFileName = (name: any, length: any) => {
    if (name.length <= length) return name

    const parts = name.split('.')
    const extension = parts.length > 1 ? '.' + parts.pop() : ''
    const truncatedName = parts.join('.').slice(0, length - 3 - extension.length) + '...'

    return truncatedName + extension
  }

  const handleDownload = async (fileUrl: any) => {
    // setIsDisable(true)
    try {
      const response = await axios.get(fileUrl, {
        responseType: 'blob',
      })

      const contentType = response.headers['content-type']
      const blob = new Blob([response.data], {type: contentType})

      // Determine file extension based on content type
      const extensionMap: {[key: string]: string} = {
        'image/jpeg': 'jpg',
        'image/png': 'png',
        'application/pdf': 'pdf',
        // Add more mappings as needed
      }

      const extension = extensionMap[contentType] || 'file'

      // Create a link element
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `downloaded_file.${extension}`

      // Append the link to the body
      document.body.appendChild(link)

      // Trigger the click event to start the download
      link.click()

      // Remove the link from the body
      document.body.removeChild(link)
      // setIsDisable(false)
    } catch (error) {
      // setIsDisable(false)
      ErrorToast('Error downloading file')
    }
  }

  useEffect(() => {
    let values: any = []
    comments?.map((cm: any, ind: any) => {
      values?.push(false)
    })
    setShowMore(values)
  }, [comments])

  //
  const handleImageClick = (
    commentId: string,
    attachmentId: string,
    attachmentSrc: string,
    comm: any
  ) => {
    const groupName = `group_business_attachments_${commentId}`

    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(attachmentId)
    if (image) {
      image.setAttribute('data-fancybox', groupName)
      image.setAttribute('data-src', attachmentSrc)
    }

    // Add data-fancybox attribute to the related attachments
    comm?.fileList?.forEach((attach: any, idx: number) => {
      const imageNext = document.getElementById(`clickedBusinessAttachments${commentId}_${idx}`)
      if (imageNext) {
        imageNext.setAttribute('data-fancybox', groupName)
        imageNext.setAttribute('data-src', `${Bucket}${attach?.fileURL}`)
      }
    })

    // Trigger the fancybox on the clicked image
    if (image) {
      image.click()
    }
  }

  const sortedComments = comments
    ?.filter((comment: any) => comment?.createdAt) // Filter out comments without createdAt
    .slice()
    .sort((a: any, b: any) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime())

  return (
    <>
      {/* Comments */}
      <div className='card card-flush py-0 mt-5 ms-3 me-0 side mb-0'>
        <div className='row mx-0'>
          <div className='col-md-6 col-12 py-5 ps-0'>
            <div className='d-flex px-7'>
              <div className='d-block'>
                <h2 className='top ps-0 white-dark-theme-color'>
                  <b>Business Comments</b>{' '}
                </h2>
                <h3 className='top ps-0 d-block'>
                  <i className='gry-dark-text i_grey'> Include job specific comments shared with the handling team.</i>
                </h3>
              </div>

              <img src={externalTeam} height={45} width={45} className='ms-auto' />
            </div>

            <div className='d-flex mt-3 px-7'>
              <textarea
                className='form-control form-control-solid m-0 mytest'
                rows={3}
                name='comment'
                placeholder='Enter your comments here..'
                value={comment}
                onChange={(e) => {
                  let newVal:any = capitalizeFirstLetter(e.target.value)
                  setComment(newVal)}}
              ></textarea>
            </div>

            {commentError && (
              <p style={{color: 'var(--kt-danger)', gridColumn: '2/2'}} className='m-0 mt-1 px-7'>
                Please enter comment
              </p>
            )}

            <div className='d-flex justify-content-center mt-5  px-7'>
              <label
                title='Add New File'
                htmlFor='uploadPassport'
                className='btn btn-sm fw-bold  me-0 blue-btn ps-3 status-w-140'
              >
                <img src={attachmentImg} height={18} width={18} className='me-3' />
                Attach Files{' '}
              </label>
              <input
                type='file'
                hidden
                id='uploadPassport'
                className='form-control form-control-solid'
                placeholder='upload'
                name='passport'
                onChange={(e: any) => {
                  handleUploadAttchment(e)
                }}
                multiple
              />

              {/* <button
            type='button'
            className='btn btn-sm fw-bold  me-0 blue-btn ps-3 status-w-130'
            onClick={() => {}}
            style={{width: 'fit-content'}}
            disabled={isLoading}
          >
            <img src={uploadWhite} height={17} width={17} className='me-3' /> Upload File
          </button> */}
            </div>

            <div className='d-flex justify-content-center mt-2  px-7'>
              <button
                type='button'
                className='btn btn-sm fw-bold  me-0 green-submit-btn ps-3 status-w-140'
                onClick={() => {
                  if (comment?.trim()?.length == 0) {
                    setCommentError(true)
                  } else {
                    const userData: any = localStorage.getItem('userData')
                    const user = JSON.parse(userData)

                    let values = [...comments]
                    values[values?.length] = {
                      createdByUser: {
                        _id: user?._id,
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                      },
                      createdByType: 0,
                      createdBy: user?._id,
                      comment: comment,
                      createdAt: new Date().toISOString(),
                      fileList: commentAttachments,
                    }
                    setComments(values)
                    setCommentError(false)
                    setComment('')

                    setCommentAttachments([])

                    updateCRMTicket(values)
                  }
                }}
                disabled={isLoading || modalData?.status == 1}
              >
                <img src={addWhite} height={16} width={16} className='me-3' /> Add Comment
              </button>
            </div>

            {commentAttachments?.length > 0 && (
              <div className='mx-5 py-2' style={{maxHeight: '200px', overflow: 'auto'}}>
                {commentAttachments?.map((file: any, ind: any) => {
                  return (
                    <>
                      <div className='mw-450px mx-3 card card-flush mb-3 mt-2  py-2 px-3 crm-shadow'>
                        <div className='d-flex align-items-center'>
                          <img
                            src={
                              file?.fileURL?.split('.')[1] === 'pdf'
                                ? pdfDocument
                                : file?.fileURL?.split('.').pop() === 'doc' ||
                                  file?.fileURL?.split('.').pop() === 'docx'
                                ? docCocument
                                : file?.fileURL?.split('.').pop() === 'jpeg' ||
                                  file?.fileURL?.split('.').pop() === 'jpg'
                                ? jpgImg
                                : file?.fileURL?.split('.').pop() === 'png' ||
                                  file?.fileURL?.split('.').pop() === 'svg' ||
                                  file?.fileURL?.split('.').pop() === 'gif'
                                ? pngImg
                                : file?.fileURL?.split('.').pop()?.toLowerCase() === 'mp4' ||
                                  file?.fileURL?.split('.').pop()?.toLowerCase() === 'webm' ||
                                  file?.fileURL?.split('.').pop()?.toLowerCase() === 'avi' ||
                                  file?.fileURL?.split('.').pop()?.toLowerCase() === 'mkv' ||
                                  file?.fileURL?.split('.').pop()?.toLowerCase() === 'mov' ||
                                  file?.fileURL?.split('.').pop()?.toLowerCase() === 'flv' ||
                                  file?.fileURL?.split('.').pop()?.toLowerCase() === 'wmv'
                                ? videoImg
                                : fileIcon
                            }
                            // src={Bucket + file}
                            width={30}
                            height={30}
                            className='main_img cursor-pointer'
                            data-fancybox
                            data-src={`${Bucket}${file?.fileURL}`}
                          />

                          <p className='mb-0 ms-4 fnt-500 white-dark-theme-color'>
                            {truncateFileName(file?.fileName, 35)}
                          </p>

                          <img
                            src={downloadIcon}
                            height={18}
                            width={18}
                            className='ms-auto cursor-pointer '
                            onClick={() => {
                              handleDownload(`${Bucket}${file?.fileURL}`)
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )
                })}
              </div>
            )}

            <div
              className='d-flex align-items-center mt-3 pb-5 mb-5  mx-3'
              style={{borderBottom: '0.1rem solid #bec3cb'}}
            ></div>
          </div>

          <div
            className='col-md-6 col-12 py-5 px-0'
            style={{border: '0.1rem solid #bec3cb', borderRight: '0px', borderRadius: '5px'}}
          >
            {isLoading ? (
              <div
                className='d-flex justify-content-center align-items-center w-100'
                style={{height: '15vh'}}
              >
                <CircularProgress style={{color: '#007a59'}} />
              </div>
            ) : (
              <>
                {comments?.length > 0 ? (
                  <div style={{maxHeight: '400px', overflow: 'auto'}} className='px-7'>
                    {sortedComments?.map((comm: any, ind: any) => {
                      return (
                        <>
                          <div
                            className={`d-flex ${ind == 0 ? 'mt-4' : 'mt-7'} align-items-center`}
                          >
                            {comm?.createdByUser?.role_and_permission?.[0]?.name ==
                            'Maintenance Manager' ? (
                              <div className='d-flex align-items-center'>
                                <img src={userIcon} height={24} width={25} className='me-3' />
                                <h5 className='ps-1 mb-0' style={{color: '#146c6a'}}>
                                  <b>
                                    Maintenance Manager - {comm?.createdByUser?.firstName}{' '}
                                    {comm?.createdByUser?.lastName}
                                  </b>
                                </h5>
                              </div>
                            ) : (
                              <h5 className='top ps-1 mb-0 white-dark-theme-color'>
                                <b>
                                  {comm?.createdByUser?.firstName} {comm?.createdByUser?.lastName}
                                </b>
                              </h5>
                            )}

                            <h5 className='top ps-0 ms-auto mb-0 white-dark-theme-color'>
                              {comm?.createdAt ? `${convertUTCToLocalTime(comm?.createdAt)}` : ''}
                            </h5>
                          </div>
                          <div className='d-flex mt-2'>
                            <textarea
                              className={`form-control form-control-solid m-0 ${
                                selectedInd == ind
                                  ? 'jobs-comments-edit'
                                  : comm?.createdByUser?._id != user?._id
                                  ? 'green-border-box'
                                  : 'jobs-comments'
                              } `}
                              rows={3}
                              name='comment'
                              placeholder='Enter your comments here'
                              value={selectedInd != ind ? comm?.comment : commentToEdit}
                              onChange={(e) =>  {let newVal:any = capitalizeFirstLetter(e.target.value)
                                setCommentToEdit(newVal)}}
                              disabled={selectedInd != ind}
                            ></textarea>
                          </div>

                          <div className='mt-2 mb-1'>
                            {selectedInd == ind ? (
                              <div className='d-flex me-1 mb-2'>
                                <img
                                  src={removed}
                                  height={18}
                                  width={18}
                                  className='ms-auto cursor-pointer'
                                  onClick={() => {
                                    setSelectedInd(-1)
                                  }}
                                />
                                <img
                                  src={correct}
                                  height={18}
                                  width={18}
                                  className='ms-3 cursor-pointer'
                                  onClick={() => {
                                    let values = [...comments]
                                    values[ind].comment = commentToEdit
                                    setComments(values)
                                    setSelectedInd(-1)

                                    updateCRMTicket(values)
                                  }}
                                />
                              </div>
                            ) : (
                              <>
                                {comm?.createdByUser?._id == user?._id && (
                                  <div className='me-1 mb-2 text-end'>
                                    <img
                                      src={pencil}
                                      alt=''
                                      height={18}
                                      width={18}
                                      className='ms-4  cursor-pointer'
                                      onClick={() => {
                                        setSelectedInd(ind)
                                        setCommentToEdit(comm?.comment)
                                      }}
                                    />
                                    <img
                                      src={trashImg}
                                      alt=''
                                      height={18}
                                      width={18}
                                      className='ms-3 cursor-pointer'
                                      onClick={() => {
                                        swal
                                          .fire({
                                            html: ` 
                                          <div class='fs-3'> <b>Delete Comment</b></div>
                                          <div class='fs-5 mt-4'> Are you sure you want to delete this comment ? </div>`,
                                            // icon: 'warning',
                                            showConfirmButton: true,
                                            confirmButtonColor: '#35475e',
                                            confirmButtonText: 'Yes',
                                            showCancelButton: true,
                                            cancelButtonText: 'No',
                                            cancelButtonColor: '#fff',
                                            customClass: {
                                              confirmButton: 'custom-confirm-button',
                                              cancelButton: 'custom-cancel-button',
                                              popup: 'custom-popup',
                                            },
                                            reverseButtons: true,
                                          })
                                          .then((res: any) => {
                                            if (res.isConfirmed) {
                                              let values = [...comments]
                                              values.splice(ind, 1)
                                              setComments(values)

                                              updateCRMTicket(values)
                                            }
                                          })
                                      }}
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                          {comm?.fileList?.length > 0 && (
                            <div className='py-2' style={{maxHeight: '200px', overflow: 'auto'}}>
                              {comm?.fileList?.map((file: any, filesInd: any) => {
                                return (
                                  <>
                                    {(filesInd < 2 || showMore[ind] == true) && (
                                      <div className='mw-450px card card-flush mb-3 mt-0  py-2 px-3 crm-shadow ms-1'>
                                        <div className='d-flex align-items-center'>
                                          <img
                                            src={
                                              file?.fileURL?.split('.')[1] === 'pdf'
                                                ? pdfDocument
                                                : file?.fileURL?.split('.').pop() === 'doc' ||
                                                  file?.fileURL?.split('.').pop() === 'docx'
                                                ? docCocument
                                                : file?.fileURL?.split('.').pop() === 'jpeg' ||
                                                  file?.fileURL?.split('.').pop() === 'jpg'
                                                ? jpgImg
                                                : file?.fileURL?.split('.').pop() === 'png' ||
                                                  file?.fileURL?.split('.').pop() === 'svg' ||
                                                  file?.fileURL?.split('.').pop() === 'gif'
                                                ? pngImg
                                                : file?.fileURL?.split('.').pop()?.toLowerCase() ===
                                                    'mp4' ||
                                                  file?.fileURL?.split('.').pop()?.toLowerCase() ===
                                                    'webm' ||
                                                  file?.fileURL?.split('.').pop()?.toLowerCase() ===
                                                    'avi' ||
                                                  file?.fileURL?.split('.').pop()?.toLowerCase() ===
                                                    'mkv' ||
                                                  file?.fileURL?.split('.').pop()?.toLowerCase() ===
                                                    'mov' ||
                                                  file?.fileURL?.split('.').pop()?.toLowerCase() ===
                                                    'flv' ||
                                                  file?.fileURL?.split('.').pop()?.toLowerCase() ===
                                                    'wmv'
                                                ? videoImg
                                                : fileIcon
                                            }
                                            // src={Bucket + file}
                                            width={30}
                                            height={30}
                                            className='main_img cursor-pointer'
                                            id={`clickedBusinessAttachments${comm._id}_${filesInd}`}
                                            // data-fancybox
                                            // data-src={`${Bucket}${file?.fileURL}`}
                                            onClick={() =>
                                              handleImageClick(
                                                comm._id,
                                                `clickedBusinessAttachments${comm._id}_${filesInd}`,
                                                `${Bucket}${file?.fileURL}`,
                                                comm
                                              )
                                            }
                                          />

                                          <p className='mb-0 ms-4 fnt-500 white-dark-theme-color'>
                                            {truncateFileName(file?.fileName, 35)}
                                          </p>

                                          <img
                                            src={downloadIcon}
                                            height={18}
                                            width={18}
                                            className='ms-auto cursor-pointer '
                                            onClick={() => {
                                              handleDownload(`${Bucket}${file?.fileURL}`)
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </>
                                )
                              })}
                            </div>
                          )}

                          {comm?.fileList?.length > 2 && !showMore[ind] ? (
                            <>
                              <p
                                className='mb-0 cursor-pointer ps-1 i_grey'
                                style={{color: '#a1a5b7'}}
                                onClick={() => {
                                  let values: any = [...showMore]
                                  values[ind] = true
                                  setShowMore(values)
                                }}
                              >
                                +{comm?.fileList?.length - 2} Show More
                              </p>
                            </>
                          ) : comm?.fileList?.length > 2 && showMore[ind] ? (
                            <>
                              <p
                                className='mb-0 cursor-pointer ps-1 i_grey'
                                style={{color: '#a1a5b7'}}
                                onClick={() => {
                                  let values: any = [...showMore]
                                  values[ind] = false
                                  setShowMore(values)
                                }}
                              >
                                Show Less
                              </p>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )
                    })}
                  </div>
                ) : (
                  <>
                    <div
                      className='d-flex justify-content-center'
                      style={{minHeight: '250px', verticalAlign: 'middle'}}
                    >
                      <h3 className='top ps-0 d-block my-auto'>
                        <i className='gry-dark-text i_grey'>
                          {' '}
                          Request specific comments shared with the handling team will appear here.
                        </i>
                      </h3>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* <SingleFileLoader isVisible={showLoader} /> */}
      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </>
  )
}

export default BusinessCommentsOfMaintenance
