import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {ApiPost, Bucket, ApiGet} from '../../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../../apiCommon/helpers/Toast'
import {useParams} from 'react-router-dom'
import ModalLoader from '../../General/ModalLoader'
import moment from 'moment'
import {DatePicker} from 'antd'
import {Moment} from 'moment'
import trashImg from '../../../../img/trash.png'
import uploadFileIcon from '../../../../img/upload-file-icon.png'
import redCross from '../../../../img/remove.png'
import saveGreenImg from '../../../../img/save-dark-green.png'
import greenPen from '../../../../img/pen.png'
import downloadIcon from '../../../../img/download.png'
import axios from 'axios'
import swal from 'sweetalert2'
import {downloadFile, getFileIcon} from '../../../../Utilities/utils'

interface ButtonProps {
  show: any
  handleClose: any
  updateData?: any
  updateDataAfterDelete: any
  expData?: any
  mode?: any
  selectedItem?: any
  slctdDev?: any
}

const ViewEditPaymentRecord = ({
  show,
  handleClose,
  updateData,
  updateDataAfterDelete,
  expData,
  mode,
  selectedItem,
  slctdDev,
}: ButtonProps) => {
  const {flg, type, id, tenancyId, unitId} = useParams()

  // console.log('********* View Payment Record  ************')
  // console.log(expData)
  // console.log(selectedItem)

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  let [outstanding, setOutstanding] = useState<any>([])

  const [checkedValues, setCheckedValues] = useState<any>([])
  const [isEdit, setIsEdit] = useState<any>(false)

  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [totalFiles, setTotalFiles] = useState<number>(0)

  const [uploadingText, setUploadingText] = useState<any>('Uploading')
  const [percentProgress, setPercentProgress] = useState(0)

  let totalSelected: any = 0

  let totalAmountPaid: any = 0

  const [recordItems, setRecordItems] = useState<any>(selectedItem?.paymentSubItems)
  const [paymentRecord, setPaymentRecord] = useState<any>()

  useEffect(() => {
    let selectedVal: any = {...recordItems}
    selectedVal = {
      items: selectedItem?.items,
    }

    // console.log(selectedItem?.items)
    expData?.items?.map((expItm: any, expInd: any) => {
      selectedItem?.items?.map((slItm: any, slInd: any) => {
        let previousAmount: any = Number(slItm?.amount)
        let previousQty: any = Number(slItm?.quantity)
        let previousTotalAmount: any = slItm?.totalAmount

        if (
          expItm?.paymentItemListId == slItm?.paymentItemListId &&
          expItm?.frontendStorage?.itemUniqueId == slItm?.frontendStorage?.itemUniqueId
        ) {
          selectedVal.items[slInd].amount = Number(expItm?.amount)
          selectedVal.items[slInd].quantity = Number(expItm?.quantity)
          selectedVal.items[slInd].VATPercentage = Number(expItm?.VATPercentage)
          selectedVal.items[slInd].VATAmount =
            ((Number(expItm?.amount) * Number(expItm?.quantity)) / 100) *
            Number(expItm?.VATPercentage)
          selectedVal.items[slInd].totalAmount =
            Number(expItm?.amount) * Number(expItm?.quantity) + Number(expItm?.VATAmount)
          selectedVal.items[slInd].outstandingAmount =
            Number(slItm?.outstandingAmount) +
            (Number(selectedVal.items[slInd]?.totalAmount) - Number(previousTotalAmount))
        }
      })
    })

    setRecordItems(selectedVal?.items)

  }, [expData])

  // 
  const handleDateChange = (date: any) => {
    if (date != null) {
      let values = {...paymentRecord}
      values.datePaid = date.format('YYYY-MM-DD')
      setPaymentRecord(values)
    } else {
      let values = {...paymentRecord}
      values.datePaid = null
      setPaymentRecord(values)
    }

    // if (flg == '2') {
    //   setTimeout(() => {
    //     setAutoSaveData(Date.now())
    //   }, 700)
    // }
  }

  function disabledDate(current: Moment) {
    return current && current > moment().endOf('day')
  }

  const [isLoading, setIsLoading] = useState<any>(false)

  const [dragActive, setDragActive] = React.useState(false)
  const inputRef = React.useRef(null)

  // handle drag events
  const handleDrag = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      // handleFiles(e.dataTransfer.files);
    }
  }

  // 
  const handleUploadReceipt = async (e: any) => {
    e.preventDefault()

    const values = {...paymentRecord}
    const valuesReceipt = [...values.receipts]

    if (e.target.files) {
      setShowLoader(true)
      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any =
          e.target.files[i]?.name?.charAt(0).toUpperCase() + e.target.files[i]?.name?.slice(1)
        const nameParts = name.split('.')
        const extension = nameParts.pop()
        let baseName = nameParts.join('.')

        // Check for duplicate names and append a number if necessary
        let uniqueName = baseName
        let counter = 1
        while (valuesReceipt.some((receipt) => receipt.name === `${uniqueName}.${extension}`)) {
          uniqueName = `${baseName}(${counter})`
          counter++
        }
        name = `${uniqueName}.${extension}`

        await ApiPost(
          `upload/image/params?fileType=invoice&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            const name = res?.data?.data?.image.split('/')
            const fileName = name[name.length - 1]

            valuesReceipt[0] = {
              fileURL: res?.data?.data?.image,
              fileName: fileName?.charAt(0).toUpperCase() + fileName?.slice(1),
            }

            // console.log(values);
            // update the upload progress
            setUploadProgress(i + 1)
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }
      values.receipts = valuesReceipt
      setPaymentRecord(values)
      setShowLoader(false)
      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      e.target.value = null
    }
  }

  // 
  const handleUploadAttchment = async (e: any) => {
    e.preventDefault()

    const values = {...paymentRecord}
    const valuesAttachments = [...values.attachments]

    if (e.target.files) {
      setShowLoader(true)
      setTotalFiles(e.target.files.length)

      for (let i = 0; i < e.target.files.length; i++) {
        let fileURL = e.target.files[i]

        let userData: any = localStorage.getItem('userData')
        const data = JSON.parse(userData)

        var formdata = new FormData()
        formdata.append('image', fileURL)

        let name: any =
          e.target.files[i]?.name?.charAt(0).toUpperCase() + e.target.files[i]?.name?.slice(1)
        const nameParts = name.split('.')
        const extension = nameParts.pop()
        let baseName = nameParts.join('.')

        // Check for duplicate names and append a number if necessary
        let uniqueName = baseName
        let counter = 1
        while (
          valuesAttachments.some((attachment) => attachment.name === `${uniqueName}.${extension}`)
        ) {
          uniqueName = `${baseName}(${counter})`
          counter++
        }
        name = `${uniqueName}.${extension}`

        await ApiPost(
          `upload/image/params?fileType=invoice&databaseId=${data._id}&fileName=${name}`,
          formdata
        )
          .then((res) => {
            const name = res?.data?.data?.image.split('/')
            const fileName = name[name.length - 1]

            valuesAttachments[0] = {
              fileURL: res?.data?.data?.image,
              fileName: fileName?.charAt(0).toUpperCase() + fileName?.slice(1),
            }

            setUploadProgress(i + 1)
          })
          .catch((err: any) => {
            ErrorToast(err?.message)
            setShowLoader(false)
            setUploadProgress(0) // Reset upload progress
            setTotalFiles(0)
          })
      }
      values.attachments = valuesAttachments

      // console.log(values);
      setPaymentRecord(values)
      setShowLoader(false)

      setUploadProgress(0) // Reset upload progress
      setTotalFiles(0)

      e.target.value = null
    }
  }

  //  
  useEffect(() => {
    setPaymentRecord(selectedItem)
  }, selectedItem)

  // 
  const deletePaymentRecord = (val: any) => {
    const body = {
      ids: [val],
      expenseId: id,
    }
    ApiPost(`corporate/expense_payment_record/delete`, body)
      .then((res: any) => {
        SuccessToast('Payment Record has been deleted successfully')
        updateDataAfterDelete()
      })
      .catch((err: any) => {
        ErrorToast(err?.message)
      })
  }

  // 
  useEffect(() => {
    let values: any = []
    selectedItem?.items?.map((itm: any, ind: any) => {
      if (flg == '1') values[ind] = Number(itm?.outstandingAmount) + Number(itm?.totalAmountPaid)
      else values[ind] = Number(itm?.outstandingAmount) + Number(itm?.amountPaid)
    })
    
    setOutstanding(values)
  }, [selectedItem])

  // 
  const truncateFileName = (name: any, length: any) => {
    if (name?.length <= length) return name

    const parts = name?.split('.')
    const extension = parts?.length > 1 ? '.' + parts?.pop() : ''
    const truncatedName = parts?.join('.').slice(0, length - 3 - extension?.length) + '...'

    return truncatedName + extension
  }

  //
  const handleImageClick = (val: any, valSrc: any, type: any) => {
    const groupName = `group_${type}`

    // Add data-fancybox attribute to the clicked image
    const image = document.getElementById(val)
    console.log('image' , image)
    if (image) {
      image.setAttribute('data-fancybox', groupName)
      image.setAttribute('data-src', valSrc)
    }

    // Add data-fancybox attribute to the related receipts
    if (type == 'receipts') {
      paymentRecord?.receipts?.map((receipt: any, idx: any) => {
        const imgeNext = document.getElementById(`clickedReceipt${idx}`)
        console.log(receipt)
        if (imgeNext) {
          imgeNext.setAttribute('data-fancybox', groupName)
          imgeNext.setAttribute('data-src', `${Bucket}${receipt?.fileURL}`)
          // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
        }
      })
    } else {
      paymentRecord?.attachments?.map((attch: any, idx: any) => {
        const imgeNext = document.getElementById(`clickedAttachment${idx}`)
        if (imgeNext) {
          imgeNext.setAttribute('data-fancybox', groupName)
          imgeNext.setAttribute('data-src', `${Bucket}${attch?.fileURL}`)
          // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
        }
      })
    }

    // Trigger the fancybox on the clicked image
    if (image) {
      image.click()
    }
  }

  const [isAmountExceeded, setIsAmountExceeded] = useState<any>(false)

  useEffect(() => {
    let isExceeded: any = false
    expData?.items?.map((itm: any, ind: any) => {
      if (
        (flg == '1' && Number(itm?.totalAmountPaid) > Number(outstanding[ind])) ||
        (flg == '2' && Number(itm?.amountPaid) > Number(outstanding[ind]))
      )
        isExceeded = true
    })

    setIsAmountExceeded(isExceeded)
  }, [recordItems, outstanding])

  // 
  const getReceiptById = async (id: any) => {
    let url = `corporate/payment_receipt/${id}`

    await ApiGet(`${url}`)
      .then((res) => {
        setRecordItems(res?.data?.data?.paymentSubItems)

        let values: any = {
          _id: res?.data?.data?._id,
          items: res?.data?.data?.paymentSubItems,
          datePaid: res?.data?.data?.datePaid,
          totalAmountPaid: res?.data?.data?.amountPaid,
          receipts: [res?.data?.data?.receiptAttachment],
          attachments:
            res?.data?.data?.otherAttachment?.length > 0
              ? res?.data?.data?.otherAttachment
              : [
                  {
                    fileURL: '',
                    fileName: '',
                  },
                ],
          notes: res?.data?.data?.note,
        }

        console.log(values)

        setPaymentRecord(values)
      })
      .catch((err: any) => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    getReceiptById(selectedItem?._id)
  }, [selectedItem])

  return (
    <div className='schedule'>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-1000px mx-auto'
        show={show}
        onHide={() => {
          let record: any = localStorage?.getItem('ViewPaymentRecordItem')
          let recordItems: any = JSON.parse(record)
          setRecordItems(recordItems?.items)
          handleClose()
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-7 px-lg-7 pt-lg-3 modal-body-without-header'
          style={{
            minHeight: '350px',
            maxHeight: '600px',
            overflow: 'auto',
          }}
        >
          {checkedValues?.length > 0 && (
            <>
              {checkedValues?.map((ch: any, ind: any) => {
                if (ch == true) totalSelected = totalSelected + 1
                return <></>
              })}
            </>
          )}

          <div className='d-flex align-items-center'>
            <div className='d-flex align-items-center mt-3'>
              <h2 className='  mb-0'>
                <b>{isEdit ? 'Edit ' : ''} Payment Record</b>
              </h2>

              {!isEdit && (
                <img
                  src={greenPen}
                  height={18}
                  width={18}
                  className='mx-6 cursor-pointer'
                  onClick={() => setIsEdit(true)}
                />
              )}
            </div>

            <div className='d-flex align-items-center ms-auto'>
              {!isEdit && (
                <>
                  <img
                    src={trashImg}
                    height={18}
                    width={18}
                    onClick={() => {
                      swal
                        .fire({
                          html: ` 
                          <div class='fs-3'> <b>Delete Payment Record</></div>
                           <div class='fs-5 mt-4'>You are about to delete the payment record.</div>
                          <div class='fs-5 mt-4'> Are you sure you want to continue? </div>`,
                          // icon: 'warning',
                          showConfirmButton: true,
                          confirmButtonColor: '#35475e',
                          confirmButtonText: 'Yes',
                          showCancelButton: true,
                          cancelButtonText: 'No',
                          cancelButtonColor: '#fff',
                          customClass: {
                            confirmButton: 'custom-confirm-button',
                            cancelButton: 'custom-cancel-button',
                            popup: 'custom-popup',
                          },
                          reverseButtons: true, // Reverse the order of buttons reverseButtons: true,
                        })
                        .then((res: any) => {
                          if (res.isConfirmed) {
                            if (flg == '1') {
                              updateDataAfterDelete()
                            } else deletePaymentRecord(paymentRecord?._id)
                          }
                        })
                    }}
                    className='me-5 cursor-pointer'
                  />{' '}
                  {paymentRecord?.updatedAt ? (
                    <p className='mb-0 me-5 fnt-500'>
                      Last Updated{' '}
                      {`${moment
                        ?.utc(paymentRecord?.updatedAt)
                        ?.local()
                        ?.format('DD.MM.YYYY - hh:mm A')}`}
                    </p>
                  ) : (
                    <p className='mb-0 me-5 fnt-500'>
                      Created{' '}
                      {`${moment
                        ?.utc(paymentRecord?.createdAt)
                        ?.local()
                        ?.format('DD.MM.YYYY - hh:mm A')}`}
                    </p>
                  )}
                </>
              )}
              <label
                className='head-text fs-2 cursor-pointer ms-7'
                style={{fontWeight: '700'}}
                onClick={() => {
                  let record: any = localStorage?.getItem('ViewPaymentRecordItem')
                  let recordItems: any = JSON.parse(record)
                  // console.log(recordItems?.items)
                  setRecordItems(recordItems?.items)
                  handleClose()
                }}
              >
                X
              </label>
            </div>
          </div>

          {recordItems?.map((itm: any, ind: any) => {
            totalAmountPaid = Number(totalAmountPaid) + Number(itm?.amountPaid)
            return <></>
          })}

          {isEdit && (
            <div className='d-flex align-items-center mt-2 justify-content-end me-3'>
              <button
                type='button'
                className='btn btn-sm fw-bold  me-3  red-hollow-btn ps-4'
                onClick={() => {
                  let record: any = localStorage?.getItem('ViewPaymentRecordItem')
                  let recordItems: any = JSON.parse(record)
                  // console.log(recordItems?.items)
                  setRecordItems(recordItems?.items)
                  setIsEdit(false)
                }}
              >
                <img src={redCross} height={18} width={18} className='me-4' /> Cancel
              </button>

              <button
                type='button'
                className='btn btn-sm fw-bold  me-3  green-hollow-btn ps-4'
                onClick={() => {
                  let values = {...paymentRecord}
                  values.totalAmountPaid = totalAmountPaid


                  values?.receipts?.map((receipt: any, idx: any) => {
                    if(!receipt?.fileName?.includes(receipt?.fileURL?.split('.').pop())){
                      receipt.fileName = `${receipt?.fileName}.${receipt?.fileURL?.split('.').pop()}`
                    }
                  })

                  values?.attachments?.map((attch: any, idx: any) => {
                    console.log(!attch?.fileName?.includes(attch?.fileURL?.split('.').pop()))
                    if(!attch?.fileName?.includes(attch?.fileURL?.split('.').pop())){
                      attch.fileName = `${attch?.fileName}.${attch?.fileURL?.split('.').pop()}`
                    }
                  })


                  updateData(values)
                  setIsEdit(false)
                }}
                disabled={
                  totalAmountPaid == 0 ||
                  paymentRecord?.datePaid == null ||
                  isAmountExceeded == true
                    ? true
                    : false
                }
              >
                <img src={saveGreenImg} height={18} width={18} className='me-4' /> Save
              </button>
            </div>
          )}

          <div className='table-responsive mt-4' style={{maxHeight: '300px', overflow: 'auto'}}>
            <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                  <th className='text-start min-w-150px ps-0'>Item</th>
                  <th className='text-start min-w-150px ps-0'>Amount </th>
                  <th className='text-start min-w-75px ps-0'>QTY </th>
                  <th className='text-start min-w-75px ps-0'>VAT </th>
                  <th className='text-start min-w-100px ps-0'>Total Amount </th>
                  <th className='text-start min-w-100px ps-0'>Outstanding </th>
                  <th className='text-start min-w-150px ps-0'>Amount Paid </th>
                </tr>
              </thead>

              <tbody className='fw-semibold'>
                {recordItems?.map((itm: any, ind: any) => {
                  let totalAmountOfItem: any = 0
                  let vatAmnt: any =
                    ((Number(itm?.amount) * Number(itm?.quantity)) / 100) *
                    Number(itm?.VATPercentage)
                  totalAmountOfItem = Number(itm?.amount) * Number(itm?.quantity) + vatAmnt

                  let outstand: any = Number(itm?.outstandingAmount) + Number(itm?.amountPaid)
                  return (
                    <>
                      <tr className=''>
                        <td className='text-start  ps-0'>
                          {' '}
                          <div className='pt-2'>{itm?.name ? itm?.name : '-'}</div>
                        </td>
                        <td className='text-start  ps-0'>
                          <div className='pt-2'>
                            {'AED '}
                            <b>
                              {itm?.amount
                                ? `${Number(itm?.amount)?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}`
                                : '-'}
                            </b>
                          </div>
                        </td>
                        <td className='text-start  ps-0'>
                          {' '}
                          <div className='pt-2'>{itm?.quantity ? itm?.quantity : '-'}</div>
                        </td>

                        <td className='text-start  ps-0'>
                          <div className='pt-2'>
                            {' '}
                            {itm.VATPercentage ? `${itm.VATPercentage}%` : '-'}
                          </div>
                        </td>
                        <td className='text-start  ps-0'>
                          <div className='pt-2'>
                            {'AED '}
                            <b>
                              {totalAmountOfItem
                                ? ` ${totalAmountOfItem?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}`
                                : ' -'}
                            </b>
                          </div>
                        </td>
                        <td className='text-start  ps-0'>
                          <div className='pt-2'>
                            {'AED '}
                            <b>
                              {itm?.outstandingAmount != null
                                ? `${Number(outstand)?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                  })}`
                                : '-'}
                            </b>
                          </div>
                        </td>

                        {isEdit ? (
                          <td className='text-start  ps-0'>
                            <input
                              //   {...register('documentName', {required: true})}
                              type='number'
                              className={`form-control form-control-solid mytest tenancy-control   status-w-200 ${
                                flg == '1' &&
                                Number(itm?.totalAmountPaid) > Number(outstanding[ind])
                                  ? 'border-danger'
                                  : flg == '2' && Number(itm?.amountPaid) > Number(outstanding[ind])
                                  ? 'border-danger'
                                  : ''
                              }`}
                              name='documentName'
                              value={flg == '1' ? itm?.totalAmountPaid : itm?.amountPaid}
                              style={{height: '35px'}}
                              placeholder={'AED'}
                              onChange={(e: any) => {
                                let values = [...recordItems]
                                values[ind].amountPaid =
                                  e.target.value?.trim()?.length != 0 ? Number(e.target.value) : ''
                                values[ind].totalAmountPaid =
                                  e.target.value?.trim()?.length != 0 ? Number(e.target.value) : ''

                                // console.log(Number(outstanding[ind]))
                                // console.log(values[ind].totalAmountPaid)
                                // console.log(values[ind].amountPaid)

                                // values[ind].outstandingAmount = (
                                //   Number( values[ind]?.amount) * Number( values[ind]?.quantity) +
                                //   values[ind]?.VATAmount
                                // ) - Number(e.target.value)

                                setRecordItems(values)
                              }}
                              autoComplete='off'
                            />
                            {((flg == '1' &&
                              Number(itm?.totalAmountPaid) > Number(outstanding[ind])) ||
                              (flg == '2' &&
                                Number(itm?.amountPaid) > Number(outstanding[ind]))) && (
                              <p
                                className='text-danger mb-0 ms-0'
                                style={{maxWidth: '200px', wordBreak: 'break-word'}}
                              >
                                {' '}
                                Amount paid cannot exceed the outstanding amount of your expense
                                item. Edit the total amount of your expense item to be able to enter
                                a greater amount paid.
                              </p>
                            )}
                          </td>
                        ) : (
                          <td className='text-start  ps-0'>
                            {flg == '1' ? (
                              <>
                                <div className='pt-2'>
                                  {'AED '}
                                  <b>
                                    {itm?.totalAmountPaid >= 0
                                      ? `${itm?.totalAmountPaid?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}`
                                      : '-'}
                                  </b>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className='pt-2'>
                                  {'AED '}
                                  <b>
                                    {itm?.amountPaid >= 0
                                      ? `${itm?.amountPaid?.toLocaleString('en-US', {
                                          minimumFractionDigits: 2,
                                        })}`
                                      : '-'}
                                  </b>
                                </div>
                              </>
                            )}
                          </td>
                        )}
                      </tr>
                    </>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className='separator my-5 m'></div>

          <div className='d-flex align-items-center mt-10'>
            <div className='d-block'>
              <h4 className='my-2' style={{color: 'black'}}>
                <label style={{width: '130px'}} className=''>
                  {' '}
                  Date Paid
                </label>
              </h4>
              {isEdit ? (
                <div className='expense-date'>
                  {paymentRecord?.datePaid != null ? (
                    <DatePicker
                      name='deliveryDate'
                      defaultValue={moment(paymentRecord?.datePaid, 'YYYY-MM-DD')}
                      disabledDate={disabledDate}
                      onChange={handleDateChange}
                      // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                      placeholder='Select Payment Date'
                    />
                  ) : (
                    <DatePicker
                      name='deliveryDate'
                      disabledDate={disabledDate}
                      onChange={handleDateChange}
                      // defaultValue={moment(show.date).format('DD/MM/YYYY')}
                      placeholder='Select Payment Date'
                    />
                  )}
                </div>
              ) : (
                <>
                  <p className='mt-5'>
                    {' '}
                    <b>
                      {paymentRecord?.datePaid != null
                        ? `${moment?.utc(paymentRecord?.datePaid)?.local()?.format('DD.MM.YYYY')}`
                        : '-'}
                    </b>
                  </p>
                </>
              )}
            </div>
            <div className='d-block ms-auto text-end'>
              <h2 className=' pt-2 mb-0 ms-auto'>
                <b> Total Amount Paid</b>
              </h2>
              <h2 className=' pt-2 mb-0 '>
                <b>
                  {totalAmountPaid > 0
                    ? `AED ${totalAmountPaid?.toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}`
                    : 'AED -'}
                </b>
              </h2>

              <h3 className=' pt-2 mb-0'></h3>
            </div>
          </div>

          <div className='row mx-0 d-flex mt-10 payment-record'>
            <div
              className='col-12 col-md-6 ps-0 '
              style={{display: 'flex', flexDirection: 'column'}}
            >
              {isEdit ? (
                <div className='card p-5 px-9 h-100' style={{}}>
                  <div className='d-flex justify-content-center xyz mb-2 mt-8'>
                    <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                      Receipt
                    </h2>{' '}
                  </div>
                  <div className='d-flex justify-content-center xyz my-2 mt-0'>
                    <p className='page-heading d-flex text-dark fw-bold flex-column justify-content-center  mb-0'>
                      Add the receipt details for your payment:
                    </p>{' '}
                  </div>
                  <div className='d-flex justify-content-center xyz mb-3 mt-4'>
                    <input
                      //   {...register('documentName', {required: true})}
                      type='text'
                      className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                      name='documentName'
                      value={paymentRecord?.receipts[0]?.fileName}
                      style={{height: '35px', width: '300px'}}
                      placeholder={'Receipt No.'}
                      onChange={(e: any) => {
                        let values = {...paymentRecord}
                        // const ext = values.receipts[0].fileName?.split('.').pop()
                        values.receipts[0].fileName = `${e.target.value}`
                        setPaymentRecord(values)
                      }}
                    />
                  </div>

                  <div className='mt-5 '>
                    {paymentRecord?.receipts[0]?.fileURL?.trim()?.length == 0 ? (
                      <div className='d-flex align-items-start justify-content-center'>
                        <form
                          id='form-file-upload-po'
                          onDragEnter={handleDrag}
                          onSubmit={(e) => e.preventDefault()}
                          style={{height: '110px'}}
                        >
                          <label
                            id='form-file-upload-po'
                            htmlFor='form-file-upload-po-receipt'
                            className={
                              dragActive
                                ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                            }
                            style={{
                              fontSize: '16px',
                              color: 'white',
                            }}
                            ref={inputRef}
                          >
                            <div
                              className={`d-flex justify-content-center px-8 align-items-center ${
                                isLoading ? 'file-div-drag-disabled' : 'file-div-drag'
                              }`}
                            >
                              <img src={uploadFileIcon} height={60} width={45} className='me-4' />
                              <div
                                className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                                style={{minWidth: '90px'}}
                              >
                                <b>Drag & drop your file </b> <span className='mx-1 ps-1'>or </span>
                              </div>
                              <div
                                className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                                style={{minWidth: '90px'}}
                              >
                                <div
                                  className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center blue-btn btn-fit-content'
                                  style={{}}
                                >
                                  Browse Files
                                </div>
                              </div>
                            </div>
                          </label>
                          <input
                            type='file'
                            // multiple={true}
                            onChange={(e: any) => {
                              handleUploadReceipt(e)
                            }}
                            id='form-file-upload-po-receipt'
                            ref={inputRef}
                            className='file-input-drag cursor-pointer'
                            // accept='application/pdf'
                          />
                          {dragActive && (
                            <div
                              id='drag-file-element'
                              onDragEnter={handleDrag}
                              onDragLeave={handleDrag}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                            ></div>
                          )}
                        </form>
                      </div>
                    ) : (
                      <div className='d-flex justify-content-center'>
                        <img
                          src={getFileIcon(paymentRecord?.receipts[0]?.fileURL)}
                          height={150}
                          width={120}
                          className='shadow'
                        />
                        <img
                          src={trashImg}
                          height={16}
                          width={16}
                          onClick={() => {
                            let values = {...paymentRecord}
                            values.receipts[0].fileURL = ''
                            values.receipts[0].fileName = ''
                            setPaymentRecord(values)
                          }}
                          className='ms-2 cursor-pointer'
                        />{' '}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className='card p-5 px-9 h-100' style={{}}>
                  <div className='d-flex justify-content-center xyz mb-2 mt-5'>
                    <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                      Receipt
                    </h2>{' '}
                  </div>
                  {paymentRecord?.receipts?.length > 0 ? (
                    <div style={{maxHeight: '175px', overflow: 'auto'}}>
                      {paymentRecord?.receipts?.map((rs: any, ind: any) => {
                        return (
                          <div className='d-flex justify-content-start xyz mb-2 mt-5 align-items-center pe-7'>
                            <div className='bullet-item-dark-green me-3 mb-0 ms-1'></div>
                            <p className='mb-0 ms-2  me-7 fnt-500'>
                              {truncateFileName(rs?.fileName, 35)}
                            </p>
                            {/* <a href={`${Bucket}${paymentRecord?.receipt?.URL}`} target='_blank'> */}
                            <img
                              src={getFileIcon(rs?.fileURL)}
                              height={30}
                              width={30}
                              className='mx-4 ms-auto cursor-pointer'
                              // data-fancybox
                              // data-src={`${Bucket}${ rs?.fileURL}`}
                              id={`clickedReceipt${ind}`}
                              onClick={() =>
                                handleImageClick(
                                  `clickedReceipt${ind}`,
                                  `${Bucket}${rs?.fileURL}`,
                                  'receipts'
                                )
                              }
                            />
                            {/* </a> */}
                            <img
                              src={downloadIcon}
                              height={18}
                              width={18}
                              className='ms-5 cursor-pointer'
                              onClick={() => {
                                downloadFile(`${Bucket}${rs?.fileURL}`, rs?.fileName?.includes(rs?.fileURL?.split('.').pop()) ? rs?.fileName : `${rs?.fileName}.${rs?.fileURL?.split('.').pop()}`)
                              }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  ) : (
                    <div className='d-flex justify-content-center'>
                      <p>No Record</p>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div
              className='col-12 col-md-6 pe-0'
              style={{display: 'flex', flexDirection: 'column'}}
            >
              {isEdit ? (
                <div className='card p-5 px-9 h-100'>
                  <div className='d-flex justify-content-center xyz mb-2 mt-8'>
                    <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                      Other Attachments
                    </h2>{' '}
                  </div>

                  <div className='d-flex justify-content-center xyz my-2 mt-0'>
                    <p className='page-heading d-flex text-dark fw-bold flex-column justify-content-center mb-0'>
                      Attach any other document related to your payment
                    </p>{' '}
                  </div>

                  <div className='d-flex justify-content-center xyz mb-3 mt-4'>
                    <input
                      //   {...register('documentName', {required: true})}
                      type='text'
                      className='form-control form-control-solid mytest tenancy-control mb-3 bg-white'
                      name='documentName'
                      value={paymentRecord?.attachments?.[0].fileName}
                      style={{height: '35px', width: '300px'}}
                      placeholder={'Attachment Name'}
                      onChange={(e: any) => {
                        let values = {...paymentRecord}
                        values.attachments[0].fileName = e.target.value
                        setPaymentRecord(values)
                      }}
                    />
                  </div>

                  <div className='mt-5'>
                    {paymentRecord?.attachments[0]?.fileURL?.trim()?.length == 0 ? (
                      <div className='d-flex align-items-start justify-content-center'>
                        <form
                          id='form-file-upload-po'
                          onDragEnter={handleDrag}
                          onSubmit={(e) => e.preventDefault()}
                          style={{height: '110px'}}
                        >
                          <label
                            id='form-file-upload-po'
                            htmlFor='form-file-upload-poo'
                            className={
                              dragActive
                                ? 'drag-active form-label m-0 upload d-flex gap-2 text-center p-auto'
                                : 'form-label m-0 upload d-flex gap-2 text-center p-auto'
                            }
                            style={{
                              fontSize: '16px',
                              color: 'white',
                            }}
                            ref={inputRef}
                          >
                            <div
                              className={`d-flex justify-content-center px-8 align-items-center ${
                                isLoading ? 'file-div-drag-disabled' : 'file-div-drag'
                              }`}
                            >
                              <img src={uploadFileIcon} height={60} width={45} className='me-4' />
                              <div
                                className='grey-label fs-6 d-flex justify-content-center align-items-center py-2'
                                style={{minWidth: '90px'}}
                              >
                                <b>Drag & drop your file </b> <span className='mx-1 ps-1'>or </span>
                              </div>
                              <div
                                className='grey-label fs-6 d-flex justify-content-center align-items-center my-3'
                                style={{minWidth: '90px'}}
                              >
                                <div
                                  className='btn btn-sm fw-bold ms-3 px-3 form-label text-white d-flex justify-content-center align-items-center blue-btn btn-fit-content'
                                  style={{}}
                                >
                                  Browse Files
                                </div>
                              </div>
                            </div>
                          </label>
                          <input
                            type='file'
                            multiple={true}
                            onChange={(e: any) => {
                              handleUploadAttchment(e)
                            }}
                            id='form-file-upload-po'
                            ref={inputRef}
                            className='file-input-drag cursor-pointer'
                            // accept='application/pdf'
                          />
                          {dragActive && (
                            <div
                              id='drag-file-element'
                              onDragEnter={handleDrag}
                              onDragLeave={handleDrag}
                              onDragOver={handleDrag}
                              onDrop={handleDrop}
                            ></div>
                          )}
                        </form>
                      </div>
                    ) : (
                      <div className='d-flex  justify-content-center'>
                        <img
                          src={getFileIcon(paymentRecord?.attachments[0]?.fileURL)}
                          height={150}
                          width={120}
                          className='shadow'
                        />
                        <img
                          src={trashImg}
                          height={16}
                          width={16}
                          onClick={() => {
                            let values = {...paymentRecord}
                            values.attachments[0].fileURL = ''
                            values.attachments[0].fileName = ''
                            setPaymentRecord(values)
                          }}
                          className='ms-2 cursor-pointer'
                        />{' '}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className='card p-5 px-9 h-100' style={{}}>
                  <div className='d-flex justify-content-center xyz mb-2 mt-5'>
                    <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                      Other Attachments
                    </h2>{' '}
                  </div>
                  {paymentRecord?.attachments?.length > 0 &&
                  paymentRecord?.attachments?.[0]?.fileURL?.trim()?.length != 0 ? (
                    <div style={{maxHeight: '175px', overflow: 'auto'}}>
                      {paymentRecord?.attachments?.map((pAttach: any, ind: any) => {
                        return (
                          <>
                            <div className='d-flex justify-content-start xyz mb-2 mt-5 align-items-center pe-7'>
                              <div className='bullet-item-dark-green  me-3 mb-0 ms-1'></div>
                              <p className='mb-0 ms-2 me-7 fnt-500'>
                                {truncateFileName(pAttach?.fileName, 35)}
                              </p>
                              {/* <a href={`${Bucket}${paymentRecord?.attachment?.URL}`} target='_blank'> */}
                              <img
                                src={getFileIcon(pAttach?.fileURL)}
                                height={30}
                                width={30}
                                className='mx-4 ms-auto cursor-pointer'
                                // data-fancybox
                                // data-src={`${Bucket}${pAttach?.fileURL}`}

                                id={`clickedAttachment${ind}`}
                                onClick={() =>
                                  handleImageClick(
                                    `clickedAttachment${ind}`,
                                    `${Bucket}${pAttach?.fileURL}`,
                                    'attachments'
                                  )
                                }
                              />

                              {/* </a> */}
                              <img
                                src={downloadIcon}
                                height={18}
                                width={18}
                                className='ms-5 cursor-pointer'
                                onClick={() => {
                                  // downloadFile(`${Bucket}${pAttach?.fileURL}`, pAttach?.fileName)
                            downloadFile(`${Bucket}${pAttach?.fileURL}`,  pAttach?.fileName?.includes(pAttach?.fileURL?.split('.').pop()) ?  pAttach?.fileName : `${pAttach?.fileName}.${pAttach?.fileURL?.split('.').pop()}`)
                                }}
                              />
                            </div>
                          </>
                        )
                      })}
                    </div>
                  ) : (
                    <div className='d-flex justify-content-center'>
                      <p>No Record</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='row mx-0 mt-10'>
            <div className='card p-5 px-9 h-100' style={{}}>
              <div className='d-flex justify-content-center xyz mb-3 mt-5'>
                <h2 className='page-heading d-flex text-dark fw-bold flex-column justify-content-center '>
                  Notes
                </h2>{' '}
              </div>
              {!isEdit ? (
                <div className='d-flex justify-content-center xyz mb-3 mt-2'>
                  <p className=' d-flex text-dark flex-column justify-content-center '>
                    {paymentRecord?.notes ? paymentRecord?.notes : '-'}
                  </p>{' '}
                </div>
              ) : (
                <textarea
                  className='form-control form-control-solid mytest bg-white'
                  placeholder='Enter notes related to this payment'
                  name='message'
                  style={{minHeight: '100px'}}
                  value={paymentRecord?.notes}
                  onChange={(e: any) => {
                    let values = {...paymentRecord}
                    let newValue: any =
                      e.target.value != ''
                        ? e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
                        : e.target.value

                    values.notes = newValue
                    setPaymentRecord(values)
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>

      {/* <SingleFileLoader isVisible={showLoader} /> */}

      <ModalLoader
        isVisible={showLoader}
        progress={uploadProgress}
        totalFiles={totalFiles}
        uploadingText={uploadingText}
        percentageProgress={percentProgress}
      />
    </div>
  )
}

export default ViewEditPaymentRecord
