/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import chat from '../../../../../img/chat.svg'
import settingIcon from '../../../../../img/settings-white.png'
import facilityIcon from '../../../../../img/wrench.png'

const SidebarMenuMain = () => {
  const intl = useIntl()

  const userData: any = localStorage.getItem('userData')
  const user = JSON.parse(userData)

  const permission: any = localStorage.getItem('permission')
  const data = JSON.parse(permission)

  const roleAndPermissions: any = localStorage.getItem('roleAndPermissions')
  const role = JSON.parse(roleAndPermissions)

  return (
    <>
      {/* {(data?.property || data?.tenancy || data?.tenant || data?.tenancy || data?.announcement) && ( */}
      <SidebarMenuItem to='/dashboard' title='Dashboard' hasBullet={false} dashboard={true} />

     
      {/* // Property Management  */}
      {/* <SidebarMenuItemWithSub
        to={[
          '/dashboard',
          '/building-dashboard',
          '/community-dashboard',
          '/tenancies',
          '/tenants',
          '/financials-income',
          '/financials-expenses',
          '/financials-statement-reports',
          '/financials-payment-reminders',
          '/announcements',
        ]}
        title='Property Management'
        fontIcon='bi-app-indicator'
        hasProperty={true}
      >
        {data?.property === true && (
          <SidebarMenuItemWithSub
            to={['/dashboard', '/building-dashboard', '/community-dashboard']}
            title='Properties'
            // icon='/media/icons/duotune/finance/fin001.svg'
            hasBullet={true}
          >
            <SidebarMenuItem to='/dashboard' title='Overview' hasBullet={true} />
            <SidebarMenuItem to='/building-dashboard' title='Buildings' hasBullet={true} />
            <SidebarMenuItem to='/community-dashboard' title='Communities' hasBullet={true} />
          </SidebarMenuItemWithSub>
        )} */}

         {data?.property === true && (
          <SidebarMenuItemWithSub
          to={['/properties', ]}
          title='Properties'
          fontIcon='bi-app-indicator'
          hasProperty={true}

        >
          <SidebarMenuItem to='/properties' title='All Properties' hasBullet={true} />
          <SidebarMenuItem  title='All Devleopments' hasBullet={true}  />
          <SidebarMenuItem  title='Buildings' hasBullet={true} />
          <SidebarMenuItem  title='Communities' hasBullet={true} />
        </SidebarMenuItemWithSub>
        )}

        {((user?.isSubUser && role?.leasing_residential?.lease_agreements?.view_list) ||
          !user?.isSubUser) && (
          <SidebarMenuItemWithSub
          to={['/tenancies']}
          title='Leases'
          fontIcon='bi-app-indicator'
          hasTenancy={true}

        >
          <SidebarMenuItem to='/tenancies' title='All' hasBullet={true} />
          <SidebarMenuItem  title='Booked' hasBullet={true}  />
          <SidebarMenuItem  title='Active' hasBullet={true}  />
          <SidebarMenuItem  title='Expiring' hasBullet={true} />
          <SidebarMenuItem  title='Expired' hasBullet={true} />
          <SidebarMenuItem  title='Terminated' hasBullet={true} />
          <SidebarMenuItem  title='Draft' hasBullet={true} />
          <SidebarMenuItem  title='Cancelled' hasBullet={true} />
          <SidebarMenuItem  title='Requests' hasBullet={true} />
          <SidebarMenuItem  title='Deleted' hasBullet={true} />
        </SidebarMenuItemWithSub>
        )}

       {data?.tenant === true && (
          <SidebarMenuItemWithSub
          to={['/tenants']}
          title='Tenants'
          fontIcon='bi-app-indicator'
          hasTenant={true}

        >
          <SidebarMenuItem to='/tenants' title='Tenants' hasBullet={true} />
          <SidebarMenuItem  title='Move In' hasBullet={true}  />
          <SidebarMenuItem  title='Move Out' hasBullet={true} />
          <SidebarMenuItem  title='General Complaints' hasBullet={true} />
          <SidebarMenuItem  title='Other Requests' hasBullet={true} />
        </SidebarMenuItemWithSub>
        )}

      {data?.tenancy === true && (
          <SidebarMenuItemWithSub
          to={['/financials-income',
                '/financials-expenses',
                '/finance-income']}
          title='Financials'
          fontIcon='bi-app-indicator'
          hasProperty={true}

        >
          <SidebarMenuItem to='/financials-income' title='Income' hasBullet={true} />
          <SidebarMenuItem to='/financials-expenses' title='Expenses' hasBullet={true}  />
        </SidebarMenuItemWithSub>
        )}

        {data?.announcement === true && (
          <SidebarMenuItemWithSub
          to={['/announcements']}
          title='Announcements'
          fontIcon='bi-app-indicator'
          hasAnnouce={true}

        >
          <SidebarMenuItem to='/announcements' title='All' hasBullet={true} />
          <SidebarMenuItem  title='Active' hasBullet={true}  />
          <SidebarMenuItem  title='Draft' hasBullet={true}  />
          <SidebarMenuItem  title='Inactive' hasBullet={true}  />
        </SidebarMenuItemWithSub>
        )}

        {/* {data?.announcement === true && (
          <SidebarMenuItemWithSub
          to={['/vendor-management']}
          title='Vendor Management'
          fontIcon='bi-app-indicator'
          hasProperty={true}

        >
          <SidebarMenuItem to='/vendor-management' title='All' hasBullet={true} />
          <SidebarMenuItem to='/vendor-management' title='Electrician' hasBullet={true}  />
          <SidebarMenuItem to='/vendor-management' title='Mechaniser' hasBullet={true}  />
          <SidebarMenuItem to='/vendor-management' title='Plumber' hasBullet={true}  />
        </SidebarMenuItemWithSub>
        )} */}
        


        

    
      {/* )} */}

      {/* Request Management */}
      {/* {data?.externalServiceRequest === true || data?.internalServiceRequest === true ? (
        <SidebarMenuItemWithSub
          to={['/request-management/service']}
          title='Request Management'
          fontIcon='bi-app-indicator'
          hasRequest={true}
        >
          <SidebarMenuItem
            to='/request-management/service'
            title='Request Management'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>
      ) : (
        ''
      )} */}

      {/* Facility */}
      {/* {data?.service === true && (
        <SidebarMenuItemWithSub
          to={['/facility']}
          title='Facility Management'
          fontIcon='bi-app-indicator'
          facility={true}
        >
          <SidebarMenuItem to='/facility' title='Facility' hasBullet={true} />
        </SidebarMenuItemWithSub>
      )} */}

      {/* Vendor Management */}
      {data?.vendorList === true && (
        <SidebarMenuItemWithSub
          to={['/vendor-management']}
          // to={['/vendor-management/serviceproviders']}
          title='Vendor Management'
          fontIcon='bi-app-indicator'
          vendorManagement={true}
        >
          {/* <SidebarMenuItem
            to='/vendor-management'
            // to='/vendor-management/serviceproviders'
            title='Vendor Management'
            hasBullet={true}
          /> */}
            <SidebarMenuItem to='/vendor-management' title='All' hasBullet={true} />
          <SidebarMenuItem  title='Electrician' hasBullet={true}  />
          <SidebarMenuItem  title='Mechaniser' hasBullet={true}  />
          <SidebarMenuItem  title='Plumber' hasBullet={true}  />
        </SidebarMenuItemWithSub>
      )}

        {(
          <SidebarMenuItemWithSub
          to={['/maintenance']}
          title='Maintenance'
          fontIcon='bi-app-indicator'
          hasMaintenance={true}

        >
          <SidebarMenuItem to='/maintenance' title='Requests' hasBullet={true} />
          <SidebarMenuItem  title='Jobs' hasBullet={true}  />
          <SidebarMenuItem  title='Services' hasBullet={true}  />
          <SidebarMenuItem  title='Reactive' hasBullet={true}  />
          <SidebarMenuItem  title='Planned' hasBullet={true}  />
          <SidebarMenuItem  title='Preventative' hasBullet={true}  />
        </SidebarMenuItemWithSub>
        )}

      <SidebarMenuItem to='/chat' title='Message' hasBullet={false} message={true} />
      <SidebarMenuItem to='/settings' title='Settings' hasBullet={false} setting={true} />
      <SidebarMenuItem to='/feedback' title='Feedback' hasBullet={false} feedback={true} />
    </>
  )
}

export {SidebarMenuMain}
