import React, { useState, useCallback, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import backArrow from '../../../../../img/back-arrow.png'
import emailTemplateOneImg from '../../../../../img/email-template-one.png'
import emailTemplateTwoImg from '../../../../../img/email-template-two.png'
import whiteTick from '../../../../../img/onboard-tick.png'
import { SuccessToast, ErrorToast } from '../../../../../apiCommon/helpers/Toast'
import { ApiPost } from '../../../../../apiCommon/helpers/API/ApiData'

interface Template {
  src: string
  name: string
  url: string
}

const SelectTemplates: React.FC = () => {
  const navigate = useNavigate()
  const { id, type } = useParams()
  const { state } = useLocation()

  const templates: Template[] = [
    {
      src: emailTemplateOneImg,
      name: 'Template 1',
      url: `/settings/crm-residents/email-templates/select/template-01/${type}`,
    },
    {
      src: emailTemplateTwoImg,
      name: 'Template 2',
      url: `/settings/crm-residents/email-templates/select/template-02/${type}`,
    },
  ]

  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState<number | null>(null)
  const selectedTemplate = selectedTemplateIndex !== null ? templates[selectedTemplateIndex] : null

  const handleNext = () => {
    if (selectedTemplate) {
      const nextUrl = id ? `${selectedTemplate.url}/${id}` : selectedTemplate.url
      navigate(nextUrl, { state: { email: state?.email } })
    }
  }

  const [requestTableData, setRequestTableData] = useState<any>([])
  const [page, setPage] = useState<number>(1)
  const [limit] = useState<number>(25)
  const [pageLimit, setPageLimit] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [templateTypes, setTemplateTypes] = useState<any>([
    `${
      type == 'invoices'
        ? 'invoice_share_via_email'
        : type == 'receipts'
        ? 'receipt_share_via_email'
        : ''
    }`,
  ])

  const handlePageClick = (event: {selected: number}) => {
    setPage(event.selected + 1)
  }

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true)
      const body = {page, limit, templateTypes}

      const res = await ApiPost('corporate/email_template/get', body)
      setRequestTableData(res?.data?.data?.email_template || [])
      setPageLimit(res?.data?.data?.state?.page_limit || 1)
    } catch (err: any) {
      ErrorToast(err.message)
      if (err?.message === 'Your account has been suspended!') {
        localStorage.clear()
        window.location.pathname = '/auth'
      }
    } finally {
      setIsLoading(false)
    }
  }, [page, limit])

  useEffect(() => {
    // fetchData()
  }, [fetchData])

  return (
    <div className="pt-0 mt-0 px-2 ps-1">
      <div className="d-flex align-items-center">
        <span className="rounded-circle cursor-pointer" onClick={() => navigate(`/settings/communications/${type}`)}>
          <img src={backArrow} height={14} width={14} className="me-2" alt="Back" />
        </span>
        <h2 className="page-heading m-0 head-text">Email Builder</h2>
      </div>

      <div className="card card-flush gap-5 p-5 pt-1 pb-0 tst mt-8" style={{ minHeight: '75vh' }}>
        <div className="card-body pt-0 table-responsive mt-5 py-3 px-3">
          <div className="d-flex align-items-start justify-content-between">
            <div>
              <h3 className="page-heading mb-2"><b>Select Template</b></h3>
              <p className="labl-gry fs-5 mb-0">
                Select a template you would like to use for your communication.
              </p>
            </div>
            <button
              type="button"
              className="btn btn-sm fw-bold green-submit-btn px-2 status-w-180 d-flex justify-content-center"
              onClick={handleNext}
              disabled={selectedTemplateIndex === null}
            >
              Next
            </button>
          </div>

          <div className="row mt-9">
            {templates.map((template, index) => (
              <div className="col-4" key={template.name}>
                <div className="d-flex align-items-start">
                  <div
                    className={`d-flex align-items-center justify-content-center 
                    ${selectedTemplateIndex === index ? 'green-radio-filled' : 'green-radio-hollow'} 
                    h-18px w-18px me-3 cursor-pointer`}
                    onClick={() => setSelectedTemplateIndex(index)}
                  >
                    {selectedTemplateIndex === index && (
                      <img src={whiteTick} height={10} width={10} alt="Selected" />
                    )}
                  </div>
                  <div>
                    <h4 className="mb-2"><b>{template.name}</b></h4>
                    <img src={template.src} height={240} width={180} alt={template.name} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectTemplates
