import React, {useEffect, useState} from 'react'
import {ApiPost} from '../../../../../../../apiCommon/helpers/API/ApiData'
import noData from '../../../../../../img/NoData1.svg'
import moment from 'moment'
import {MultiSelect} from 'react-multi-select-component'
import {
  removeLeadingZeros,
  formatAmount,
  invertOutstandingAmount,
  preventExpAndSign,
} from '../../../../../../../Utilities/utils'
import {useParams} from 'react-router-dom'

interface ButtonProps {
  slctdInvoices?: any
  updateDetails?: any
  notePaymentData?: any
  register?: any
  errors?: any
  setValue?: any
  trigger?: any
  control?: any
  updatePaymentRecord: (val: any) => void // Function type, not void
  autoSaveParent?: any
  editMode?: any
  from?: string
}

const RecordPayment = ({
  slctdInvoices,
  updateDetails,
  notePaymentData,
  register,
  errors,
  setValue,
  trigger,
  control,
  updatePaymentRecord,
  autoSaveParent,
  editMode,
  from,
}: ButtonProps) => {
  const {flg} = useParams()

  function enforceMinMax(event: any) {
    const el = event.target
    if (el.value != '') {
      if (parseInt(el.value) < parseInt(el.min)) {
        el.value = el.min
      }

      if (parseInt(el.value) > parseInt(el.max)) {
        el.value = el.max
      }
    }
  }

  return (
    <div>
      <div className='card card-flush py-4 mt-7  px-5 mb-5' style={{maxHeight: '500px', overflow: 'auto'}}>
        <div className='d-flex align-items-top'>
          <h2 className='pt-2 mb-0 white-dark-theme-color'>
            <b>{`Set ${from == 'credit note' ? 'Credit' : 'Debit'} Amount`}</b>
          </h2>

          <div className='mt-0 ms-auto'>
            {flg != '1' && (
              <>
                {notePaymentData?.formStatusDateTime ? (
                  <div className='me-2 white-dark-theme-color'>
                    <div
                      className={
                        notePaymentData?.formStatus == 0
                          ? 'status draft-st ms-5 status-w-120'
                          : 'status create-st ms-5 status-w-120'
                      }
                    >
                      {notePaymentData?.formStatus == 0 ? 'Draft' : 'Created'}
                    </div>
                    <i style={{paddingRight: '3px', paddingLeft: '18px'}}>
                      {' '}
                      {notePaymentData?.formStatusDateTime
                        ? `${moment.utc(notePaymentData?.formStatusDateTime).local().format('DD.MM.YYYY - hh:mm A')}`
                        : ''}
                    </i>
                  </div>
                ) : (
                  <div className='status not-saved ms-5 status-w-120 white-dark-theme-color'>-</div>
                )}
              </>
            )}
          </div>
        </div>
        <div className='d-flex align-items-center'>
          <h4 className='pt-0 mb-2 head-text light-dark-theme-color'>
            {`Enter the ${
              from == 'credit note' ? 'credit' : 'debit'
            }  amount for each item under the selected invoices and ensure to select the items you would like to add to your 
          ${from == 'credit note' ? 'credit' : 'debit'} note below:`}
          </h4>
        </div>

        <>
          {slctdInvoices?.map((inv: any, indOut: any) => {
            let outstandingAmount: any = Number(inv?.totalAmount) - Number(inv?.totalPaidAmount)

            return (
              <div className='mb-10'>
                <div className='table-responsive pb-3'>
                  <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                        <th className='min-w-125px ps-0'>Invoice </th>
                        <th className='min-w-125px'>Amount</th>
                        <th className='min-w-125px ps-7 pe-5'>Due Date</th>
                        <th className='min-w-100px text-center'>Category</th>
                        <th className='min-w-100px text-center'>Status</th>
                        <th className='min-w-150px '>Payor</th>
                        <th className='min-w-100px'>Unit </th>
                        <th className='text-start min-w-150px px-3'>Contract No.</th>
                        <th className='min-w-150px'>Lease Term</th>
                      </tr>
                    </thead>

                    <tbody className='fw-semibold head-text'>
                      <>
                        <tr>
                          <td className='ps-0'>
                            <div className='d-flex text-decoration-underline'>
                              <b>{inv?.invoiceNo ? inv?.invoiceNo : '-'}</b>
                            </div>
                          </td>
                          <td>{inv?.totalAmount > 0 ? <>AED {formatAmount(inv?.totalAmount)}</> : <>AED 0</>}</td>
                          <td className='ps-7 pe-5'>
                            {inv?.dueDate ? moment(inv?.dueDate).format('DD.MM.YYYY') : '-'}
                          </td>
                          <td>
                            <div
                              className={`status status-w-100 mx-auto
                                ${
                                  inv?.categories == 0
                                    ? 'active-bar '
                                    : inv?.categories == 1
                                    ? 'expired-bar'
                                    : inv?.categories == 3
                                    ? 'complete-bar'
                                    : inv?.categories == 2
                                    ? 'req-bar'
                                    : 'no-st-bar'
                                }
                              `}
                            >
                              {inv?.categories == 0
                                ? 'Upcoming'
                                : inv?.categories == 1
                                ? 'Overdue'
                                : inv?.categories == 3
                                ? 'Complete'
                                : inv?.categories == 2
                                ? 'Deferred'
                                : '-'}
                            </div>
                          </td>

                          <td>
                            <div
                              className={`status-w-100 status mx-auto
                                ${
                                  inv?.status === 2
                                    ? 'pending-approval-st '
                                    : inv?.status === 1
                                    ? 'approve-st'
                                    : inv?.status === 0
                                    ? 'cancelled-st'
                                    : inv?.status === 3 || inv?.status === 4
                                    ? 'pend-st'
                                    : 'not-saved'
                                }`}
                            >
                              {inv?.status === 0
                                ? 'Unpaid'
                                : inv?.status === 1
                                ? 'Paid'
                                : inv?.status === 2
                                ? 'Partially paid'
                                : inv?.status === 3
                                ? 'Void'
                                : inv?.status === 4
                                ? 'Draft'
                                : '-'}
                            </div>
                          </td>

                          <td className=''>
                            {inv?.tenant?.[0]?.firstName
                              ? `${inv?.tenant?.[0]?.firstName} ${inv?.tenant?.[0]?.lastName}`
                              : '-'}
                          </td>

                          <td>{inv?.unit?.length > 0 ? inv?.unit?.[0]?.unitNo : '-'}</td>

                          <td className=''>
                            {inv?.tenancy?.[0]?.contractNo > 0 ? inv?.tenancy?.[0]?.contractNo : '-'}
                          </td>
                          <td className='text-start '>
                            {inv?.tenancy?.[0]?.duration?.start_date
                              ? `${moment.utc(inv?.tenancy?.[0]?.duration?.start_date).local().format('DD.MM.YYYY')} `
                              : '-'}
                            {'-'}
                            {inv?.tenancy?.[0]?.duration?.end_date
                              ? `${moment.utc(inv?.tenancy?.[0]?.duration?.end_date).local().format('DD.MM.YYYY')} `
                              : '-'}
                          </td>
                        </tr>
                      </>
                    </tbody>
                  </table>
                </div>

                <div className='separator my-3 mt-7'></div>

                <div
                  className='table-responsive pb-3'
                  // style={{maxHeight: '350px', overflow: 'auto'}}
                >
                  <table className='table align-start fs-6 gy-5' id='kt_ecommerce_sales_table'>
                    <thead>
                      <tr className='text-start text-gray-400 fw-bold fs-7 gs-0'>
                        <th className='min-w-25px pe-3 ps-0'>ID</th>
                        <th className='min-w-150px px-3'>Item</th>
                        <th className='min-w-100px px-3'>Rate</th>
                        <th className='min-w-75px  px-3'>QTY</th>
                        <th className='min-w-75px  px-3'>VAT</th>
                        <th className='min-w-150px  px-3'>Total Amount</th>
                        <th className='min-w-150px px-3'>Outstanding</th>
                        {from === 'credit note' ? <th className='min-w-150px px-3'>Credit</th> : <th className='min-w-150px px-3'>Debit</th>}
                        {/* <th className='min-w-150px  px-3'>Debit</th> */}
                        <th className='px-0'>
                          <tr className=''>
                            <th className='min-w-175px py-0 px-3' style={{maxWidth: '1175px'}}>
                              {from == 'credit note' ? 'Credit ' : 'Debit '} Rate Amount
                            </th>
                            <th className='min-w-100px py-0 px-3' style={{maxWidth: '100px'}}>
                              QTY
                            </th>
                            <th className='min-w-100px py-0 px-3' style={{maxWidth: '100px'}}>
                              VAT (%)
                            </th>
                          </tr>
                        </th>
                        <th className='min-w-25px px-3'>Add</th>
                      </tr>
                    </thead>
                    <tbody className='fw-semibold head-text'>
                      {inv?.paymentSubItems?.map((invItem: any, indIn: any) => {
                        let totalAmountOfItem: any = 0
                        let vatAmnt = 0
                        if (from === 'credit note' && (invItem?.addedCredit?.creditRateAmount && invItem?.addedCredit?.quantity && invItem?.addedCredit?.creditVATAmount !== '')) {
                          vatAmnt =
                            ((Number(invItem?.addedCredit?.creditRateAmount) * Number(invItem?.addedCredit?.quantity)) /
                              100) *
                            Number(invItem?.addedCredit?.creditVATAmount)
                          totalAmountOfItem =
                            Number(invItem?.addedCredit?.creditRateAmount) * Number(invItem?.addedCredit?.quantity) +
                            vatAmnt
                          invItem.addedCredit.totalCreditAmount = totalAmountOfItem
                          invItem.invoiceNo = slctdInvoices[indOut]?.invoiceNo
                          invItem.invoiceId = slctdInvoices[indOut]?._id
                          invItem.frontendStorage = {
                            ...invItem.frontendStorage,
                            invoiceDate: slctdInvoices[indOut]?.date,
                            addedAt: new Date().getTime(),
                          }
                        } else if (from === 'debit note' && (invItem?.addedDebit?.debitRateAmount && invItem?.addedDebit?.quantity && invItem?.addedDebit?.debitVATAmount !== '')) {
                          vatAmnt =
                            ((Number(invItem?.addedDebit?.debitRateAmount) * Number(invItem?.addedDebit?.quantity)) /
                              100) *
                            Number(invItem?.addedDebit?.debitVATAmount)
                          totalAmountOfItem =
                            Number(invItem?.addedDebit?.debitRateAmount) * Number(invItem?.addedDebit?.quantity) +
                            vatAmnt
                          invItem.addedDebit.totalDebitAmount = totalAmountOfItem
                          invItem.invoiceNo = slctdInvoices[indOut]?.invoiceNo
                          invItem.invoiceId = slctdInvoices[indOut]?._id
                          invItem.frontendStorage = {
                            ...invItem.frontendStorage,
                            invoiceDate: slctdInvoices[indOut]?.date,
                            addedAt: new Date().getTime(),
                          }
                        }

                        return (
                          <tr>
                            <td className='pe-3 ps-0 '>
                              <span className='mt-3 d-inline-block'>
                                {invItem?.frontendStorage?.paymentItemId
                                  ? invItem?.frontendStorage?.paymentItemId
                                  : '-'}
                              </span>
                            </td>
                            <td className='px-3'>
                              <span className='mt-3 d-inline-block'>{invItem?.name ? invItem?.name : '-'}</span>
                            </td>{' '}
                            <td className='px-3'>
                              <span className='mt-3 d-inline-block'>
                                {' '}
                                {formatAmount(Number(invItem?.amount))}
                              </span>
                            </td>{' '}
                            <td className='px-3'>
                              <span className='mt-3 d-inline-block'>{formatAmount(Number(invItem?.quantity))}</span>
                            </td>{' '}
                            <td className='px-3'>
                              <span className='mt-3 d-inline-block'>
                                {' '}
                                {`${formatAmount(Number(invItem.VATPercentage))}%`}
                              </span>
                            </td>{' '}
                            <td className='px-3'>
                              <span className='mt-3 d-inline-block'>
                                {'AED '}
                                <b className='ps-1'>
                                  {formatAmount(Number(invItem?.totalAmount))}
                                </b>
                              </span>
                            </td>{' '}
                            <td className='px-3'>
                              <span className='mt-3 d-inline-block'>
                                {'AED '}
                                <b className='ps-1'>
                                  {formatAmount(invertOutstandingAmount(Number(invItem?.outstandingAmount)))}
                                </b>
                              </span>
                            </td>{' '}
                            <td className='px-3'>
                              <span className='mt-3 d-inline-block'>
                                {'AED '}
                                    {from === 'credit note' ? <b className='ps-1'>
                                  {formatAmount(Number(invItem?.totalCreditAmount))}
                                </b> :<b className='ps-1'>
                                  {formatAmount(Number(invItem?.totalDebitAmount))}
                                </b>}
                              </span>
                            </td>{' '}
                            <td className='px-0'>
                              <tr className=''>
                                <td className='min-w-175px px-3' style={{maxWidth: '175px'}}>
                                  {editMode ? (
                                    <>
                                      <div className='currency-input-wrapper'>
                                        <span
                                          className={flg === '1' ? `${ 
                                            invItem?.addedCredit?.creditRateAmount  ||
                                            invItem?.addedDebit?.debitRateAmount
                                              ? 'currency-prefix'
                                              : 'currency-prefix currency-input-placeholder'
                                          }` : `${ 
                                            invItem?.addedCredit?.creditRateAmount >= 0  ||
                                            invItem?.addedDebit?.debitRateAmount >= 0
                                              ? 'currency-prefix'
                                              : 'currency-prefix currency-input-placeholder'
                                          }`}
                                        >
                                          AED
                                        </span>
                                        <input
                                          min={0}
                                          onKeyUp={enforceMinMax}
                                          onKeyDown={preventExpAndSign}
                                          type='number'
                                          className={flg === '1' ? `currency-input ${
                                            invItem?.addedCredit?.creditRateAmount  || invItem?.addedDebit?.debitRateAmount
                                              ? 'isFilled'
                                              : ''
                                          } ${from === 'credit note' &&
                                            totalAmountOfItem > Number(invItem?.totalAmount)
                                              ? 'border-error'
                                              : ''
                                          }
                                          ` : `currency-input ${
                                            invItem?.addedCredit?.creditRateAmount >= 0 || invItem?.addedDebit?.debitRateAmount >= 0
                                              ? 'isFilled'
                                              : ''
                                          } ${from === 'credit note' &&
                                            totalAmountOfItem > Number(invItem?.totalAmount)
                                              ? 'border-error'
                                              : ''
                                          }
                                          `}
                                          name={`${from === 'credit note' ? 'creditRateAmount' : 'debitRateAmount'}`}
                                          value={
                                            from === 'credit note'
                                              ? invItem?.addedCredit?.creditRateAmount
                                              : invItem?.addedDebit?.debitRateAmount
                                          }
                                          style={{
                                            height: '35px',
                                            backgroundColor: '#ffffff',
                                          }}
                                          placeholder={''}
                                          onChange={(e: any) => {
                                            let values = [...slctdInvoices]
                                            let valuesItems = values[indOut].paymentSubItems
                                            let newValue: any = removeLeadingZeros(e.target.value)
                                            if (from === 'credit note') {
                                              valuesItems[indIn].addedCredit.creditRateAmount = newValue
                                            } else {
                                              valuesItems[indIn].addedDebit.debitRateAmount = newValue
                                            }
                                            values[indOut].paymentSubItems = valuesItems

                                            updatePaymentRecord(values)
                                          }}
                                          autoComplete='off'
                                          onBlur={autoSaveParent}
                                        />
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      {'AED '}
                                      {from === 'credit note' ? (
                                        <>
                                          <b className='ps-1'>
                                            {invItem?.addedCredit?.creditRateAmount
                                              ? `${formatAmount(invItem?.addedCredit?.creditRateAmount)}`
                                              : ' -'}
                                          </b>
                                        </>
                                      ) : (
                                        <>
                                          <b className='ps-1'>
                                            {invItem?.addedDebit?.debitRateAmount
                                              ? `${formatAmount(invItem?.addedDebit?.debitRateAmount)}`
                                              : ' -'}
                                          </b>
                                        </>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td className='min-w-100px px-3' style={{maxWidth: '100px'}}>
                                  {editMode ? (
                                    <>
                                      <input
                                        min={0}
                                        onKeyUp={enforceMinMax}
                                        onKeyDown={preventExpAndSign}
                                        type='number'
                                        className={`form-control form-control-solid mytest b-rounded tenancy-control ${
                                          from === 'credit note' && totalAmountOfItem > Number(invItem?.totalAmount) ? 'border-error' : ''
                                        }`}
                                        name={`${from === 'credit note' ? 'creditQuantity' : 'debitQuantity'}`}
                                        value={
                                          from === 'credit note'
                                            ? invItem?.addedCredit?.quantity
                                            : invItem?.addedDebit?.quantity
                                        }
                                        style={{
                                          height: '35px',
                                          backgroundColor: '#ffffff',
                                        }}
                                        placeholder={'QTY'}
                                        onChange={(e: any) => {
                                          let values = [...slctdInvoices]
                                          let valuesItems = values[indOut].paymentSubItems
                                          let newValue: any = removeLeadingZeros(e.target.value)

                                          if (from === 'credit note') {
                                            valuesItems[indIn].addedCredit.quantity = newValue
                                          } else {
                                            valuesItems[indIn].addedDebit.quantity = newValue
                                          }

                                          values[indOut].paymentSubItems = valuesItems
                                          updatePaymentRecord(values)
                                        }}
                                        autoComplete='off'
                                        onBlur={autoSaveParent}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {'AED '}
                                      {from === 'credit note' ? (
                                        <b className='ps-1'>
                                          {invItem?.addedCredit?.quantity
                                            ? `${formatAmount(invItem?.addedCredit?.quantity)}`
                                            : ' -'}
                                        </b>
                                      ) : (
                                        <b className='ps-1'>
                                          {invItem?.addedDebit?.quantity
                                            ? `${formatAmount(invItem?.addedDebit?.quantity)}`
                                            : ' -'}
                                        </b>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td className='min-w-100px px-3' style={{maxWidth: '100px'}}>
                                  {editMode ? (
                                    <>
                                      <input
                                        min={0}
                                        max={100}
                                        onKeyUp={enforceMinMax}
                                        onKeyDown={preventExpAndSign}
                                        type='number'
                                        className={`form-control form-control-solid mytest b-rounded tenancy-control ${
                                          from === 'credit note' && totalAmountOfItem > Number(invItem?.totalAmount) && 'border-error'
                                        }`}
                                        name={`${from === 'credit note' ? 'creditVATAmount' : 'debitVATAmount'}`}
                                        value={
                                          from === 'credit note'
                                            ? invItem?.addedCredit?.creditVATAmount
                                            : invItem?.addedDebit?.debitVATAmount
                                        }
                                        style={{
                                          height: '35px',
                                          backgroundColor: '#ffffff',
                                        }}
                                        placeholder={'%'}
                                        onChange={(e: any) => {
                                          let values = [...slctdInvoices]
                                          let valuesItems = values[indOut].paymentSubItems
                                          let newValue: any = removeLeadingZeros(e.target.value)

                                          if (from === 'credit note') {
                                            valuesItems[indIn].addedCredit.creditVATAmount = newValue
                                          } else {
                                            valuesItems[indIn].addedDebit.debitVATAmount = newValue
                                          }

                                          values[indOut].paymentSubItems = valuesItems
                                          updatePaymentRecord(values)
                                        }}
                                        autoComplete='off'
                                        onBlur={autoSaveParent}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      {'AED '}
                                      {from === 'credit note' ? (
                                        <b className='ps-1'>
                                          {`${formatAmount(invItem?.addedCredit?.creditVATAmount)}`}
                                        </b>
                                      ) : (
                                        <b className='ps-1'>
                                          {`${formatAmount(invItem?.addedDebit?.debitVATAmount)}`}
                                        </b>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                              {from === 'credit note' && totalAmountOfItem > Number(invItem?.totalAmount) && (
                                <p
                                  className='text-danger  ms-0 fs-4 ps-3'
                                  style={{
                                    maxWidth: '100%',
                                    wordBreak: 'break-word',
                                  }}
                                >
                                  {' '}
                                  Total credit amount exceeds item amount.
                                </p>
                              )}
                            </td>
                            <td className='text-start px-3 test'>
                              <div className='d-flex align-items-center form-check form-check-custom form-check-solid form-check-sm mb-1 py-0 ps-0 pe-2 mt-3'>
                                <input
                                  className='form-check-input cursor-pointer'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  disabled={
                                    (from === 'debit note' &&
                                      (Number(invItem?.addedDebit?.debitRateAmount) <= 0 ||
                                        Number(invItem?.addedDebit?.quantity) <= 0 ||
                                        Number(invItem?.addedDebit?.debitVATAmount) < 0 ||
                                        invItem?.addedDebit?.debitVATAmount === null ||
                                        invItem?.addedDebit?.debitVATAmount === '')) ||
                                    (from === 'credit note' &&
                                      (Number(invItem?.addedCredit?.creditRateAmount) <= 0 ||
                                        Number(invItem?.addedCredit?.quantity) <= 0 ||
                                        Number(invItem?.addedCredit?.creditVATAmount) < 0 ||
                                        invItem?.addedCredit?.creditVATAmount === null ||
                                        invItem?.addedCredit?.creditVATAmount === ''  || 
                                        totalAmountOfItem > Number(invItem?.totalAmount)
                                      ))
                                  }
                                  checked={invItem?.isSelected == true ? true : false}
                                  onChange={(e: any) => {
                                    let values = [...slctdInvoices]
                                    let valuesItems = values[indOut].paymentSubItems

                                    valuesItems[indIn].isSelected = !valuesItems[indIn].isSelected
                                    // valuesItems[indIn].frontendStorage.addedAt = Date.now()
                                    valuesItems[indIn].frontendStorage = {
                                      ...valuesItems[indIn].frontendStorage,
                                      addedAt: new Date().getTime(),
                                    }

                                    updatePaymentRecord(values)
                                    autoSaveParent()
                                  }}
                                />
                              </div>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>

                <div className='separator my-3 mt-7'></div>
              </div>
            )
          })}
        </>
      </div>
    </div>
  )
}

export default RecordPayment
