import React, {useEffect, useState, useRef} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  ApiGet,
  ApiGetNoAuth,
  ApiPost,
  ApiPut,
  ApiUpload,
  Bucket,
  ApiDelete,
} from '../../../apiCommon/helpers/API/ApiData'
import {ErrorToast, SuccessToast} from '../../../apiCommon/helpers/Toast'
import {Col, Row, Tabs, Tab, Modal} from 'react-bootstrap'
import TenanciesFilter from './TenanciesFilter'
import {Controller, useForm} from 'react-hook-form'
import {KTSVG} from '../../../_metronic/helpers'
import {AiFillDelete} from 'react-icons/ai'
import swal from 'sweetalert2'
import {DatePicker} from 'antd'
import 'antd/dist/antd.css'
import type {RangePickerProps} from 'antd/es/date-picker'
import moment from 'moment'
import PhoneInput from 'react-phone-input-2'
import townhouse from '../../../img/TownHouse.svg'
import appartment from '../../../img/Apartment.svg'
import villa from '../../../img/Villa.svg'
import other from '../../../img/other.svg'
import common_area from '../../../img/common_area.svg'
import penthouse from '../../../img/PentHouse.svg'
import upload from '../../../img/upload.svg'
import noData from '../../../img/NoData1.svg'
import {Button, Spinner} from 'react-bootstrap'
import Label from '../../../app/modules/comman/Label'
import pen from '../../../img/pen.png'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import IncomingPaymentModal from './IncomingPaymentModal'
import ReactPaginate from 'react-paginate'
import EditPaymentModal from '../Financials/EditPaymentModal'
import backArrow from '../../../img/back-arrow.png'
import folderDocument from '../../../img/folder-document.svg'
import submitImg from '../../../img/Submit-White.png'
import EditPaymentModalDraft from './EditPaymentModal'
import AddNewTenant from './AddNewTenantModal'
import saveGreenImg from '../../../img/save-dark-green.png'
import homeImg from '../../../img/home.png'
import profileWhite from '../../../img/profile-white.png'
import file from '../../../img/invoice-white.png'
import addFolder from '../../../img/add-fold-ehite.png'
import invoiceAdd from '../../../img/invoice-new.png'
import addWhite from '../../../img/add-white.png'
import profileGreen from '../../../img/profile-green.png'
import redCross from '../../../img/remove.png'
import redCrossFilled from '../../../img/remove-filled.png'
import onboardTick from '../../../img/onboard-tick.png'
import {BsArrowRightSquareFill} from 'react-icons/bs'
import downGreen from '../../../img/down-arrow-green.png'
import upGreen from '../../../img/up-arrow-green.png'
import SelectPropertyModal from './SelectPropertyModal'
import trashImg from '../../../img/trash.png'
import EditTenant from './EditTenantModal'
import Select from 'react-select'
import TenantFolders from './TenantFolderModal'
import './style.scss'
import SingleFileModal from './SingleFileModal'
import Swal from 'sweetalert2'
import financialsIcon from '../../../img/financials.png'
import {AiOutlineExclamationCircle} from 'react-icons/ai'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import CustomDateRangePicker from './CustomDateRangePicker'
import {calculateDateStatuses} from './dateStatusUtils'
import { Fancybox as fancybox } from "@fancyapps/ui";
import '@fancyapps/ui/dist/fancybox/fancybox.css'

let arry: any = []

const RenewTenancy = () => {
  const {flg, id, tenancyId} = useParams()

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})

  const [open, setOpen] = React.useState(false)

  const [isDraft, setIsDraft] = useState<any>(false)
  const [isFormDirty, setIsFormDirty] = useState(false)

  const [selectModal, setSelectModal] = useState<any>(false)

  const sourceOptions = [
    {
      value: '0',
      label: 'Agent',
    },
    {
      value: '1',
      label: 'Direct',
    },
  ]

  var itemInd: any
  var itemIndSub: any

  const finance: any = localStorage.getItem('leaseRenewalsWithFinancials')
  let isFinance = JSON.parse(finance)

  // React Select
  const customStyles = {
    container: (defaultStyles: any) => ({
      ...defaultStyles,
      width: '100%',
    }),

    option: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      color: state.isSelected ? '#000' : '#000',
      backgroundColor: state.isSelected ? '#c2d8d9' : '',
      ':hover': {
        backgroundColor: '#e0f5f0',
        color: '#000',
      },

      fontWeight: '500',
    }),

    menu: (defaultStyles: any) => ({
      ...defaultStyles,
      border: '0.1rem solid #007a59 ',
      marginTop: '0px',
      borderRadius: '0px',
      zIndex: '2',
    }),
    menuList: (defaultStyles: any) => ({
      ...defaultStyles,
      maxHeight: '200px',
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      fontStyle: 'Italic',
      zIndex: '1',
    }),

    input: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      color: ' #b5b5c3',
      fontWeight: '500',
    }),

    control: (defaultStyles: any, state: any) => ({
      ...defaultStyles,
      backgroundColor: '#f5f8fa',
      boxShadow: 'none',
      color: 'white',
      border: '0.1rem solid #bec3cb',
      borderRadius: '2px',
      padding: '0rem',
      height: '35px',
      ':hover': {
        borderColor: '#007a59',
        backgroundColor: '#fff',
      },
      ':focus': {
        borderColor: '#007a59 !important',
        backgroundColor: '#fff',
      },
      '::placeholder': {
        fontStyle: 'Italic',
      },
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      color: '#5e6278',
      fontWeight: '600',
      zIndex: '1',
    }),
    valueContainer: (defaultStyles: any) => ({
      ...defaultStyles,
      padding: '0px 8px 2px 8px',
    }),
  }

  const [hide, setHide] = React.useState<any>(false)

  let isView: any = localStorage.getItem('isViewMode')
  const isViewMode = JSON.parse(isView)

  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const {state} = useLocation()
  const [showCheange, setShowCheange] = useState<boolean>(false)
  const [showPaid, setShowPaid] = useState<boolean>(false)
  const [showImageName, setShowImageName] = useState<boolean>(false)
  const [country, setCountry] = useState([])
  const [tableData, setTableData] = useState([])
  const [card, setCard] = useState('')
  const [cardA, setCardA] = useState<any>([])
  const [showModal, setShowModal] = useState<any>('Filter')
  const [type, setType] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [start_date, setStart] = useState<any>(null)
  const [end_date, setEnd] = useState<any>(null)
  const [totalDays, setTotalDays] = useState('0 Days')
  const [ttlDays, setTtlDays] = useState<any>('0')

  const [remainingDays, setRemainingDays] = useState('0 Days')
  const [renewalError, setRenewalError] = useState<any>(false)

  const [propertiType, setPropertiType] = useState<any>('Tenants')
  const [dateError, setDateError] = useState<any>(false)
  const [slectTenantError, setSlectTenantError] = useState<any>(false)
  const [contractNoError, setContractNoError] = useState<any>(false)

  const [payment, setPayment] = useState<number>(0)
  const [option, setOption] = useState(1)
  const [formData, setFormData] = useState<any>({
    propertyId: window.location.pathname?.split('/')[2],
    propertyAreaId: window.location.pathname?.split('/')[3],
  })
  const [create, setCreate] = useState<any>(false)
  const [status, setStatus] = useState<any>(false)
  const [phone, setPhone] = useState('')
  const [header, setHeader] = useState<any>({})
  const [unitData, setUnitData] = useState<any>([])
  const [optionArry, setOptionArry] = useState<any>({
    none: false,
    maintenanceFree: false,
    other: [],
    chillerFree: false,
  })
  const [headerB, setHeaderB] = useState<any>([])
  const [tenancyData, setTenancyData] = useState<any>({
    renewalDays: 90,
  })
  const [paymentData, setPaymentData] = useState<any>({})
  const [createpaymentData, setCreatePaymentData] = useState<any>(0)
  const [subTenant, setSubTenant] = useState<any>({})
  const [otherOptions, setOtherOptions] = useState<any>([
    // {
    //   name: '',
    // },
  ])

  const [subTenantData, setSubTenantData] = useState<any>([
    // {
    //   id: '',
    //   firstName: '',
    //   lastName: '',
    //   email: '',
    //   mobileNo: '',
    // },
  ])

  // Handle Add Sub Tenants
  const handleAddFields = () => {
    const values = [...subTenantData]
    values.push({
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      mobileNo: '',
    })
    setSubTenantData(values)
  }

  // Handle Remove Sub Tenants
  const handleRemoveFields = (index: any, item: any) => {
    const values = [...subTenantData]
    values.splice(index, 1)
    setSubTenantData(values)
  }

  // Handle Add Other Options
  const handleAddOther = () => {
    const values = [...otherOptions]
    values.push({
      value: '',
    })
    setOtherOptions(values)
  }

  // Handle Remove Other Options
  const handleRemoveOther = (index: any) => {
    const values = [...otherOptions]
    values.splice(index, 1)
    setOtherOptions(values)

    setIsFormDirty(true)
    if (flg == '2') setOpen(true)
    setTimeout(() => {
      setAutoSaveData(Date.now())
    }, 700)
  }

  let isExisted: any = false

  const [rentFin, setRentFin] = useState<any>({
    name: 'Rent',
    totalAmount: '',
    noOfPayment: '',
    paymentItemListId: '',
  })

  const [rentAmountError, setRentAmountError] = useState<any>(true)
  const [rentNoOfPaymentsError, setRentNoOfPaymentsError] = useState<any>(true)

  let totalAmountFinance: any = 0

  const [securityFin, setSecurityFin] = useState<any>({
    name: 'Security Deposit',
    totalAmount: '',
    noOfPayment: '',
    paymentItemListId: '',
  })

  const [securityAmountError, setSecurityAmountError] = useState<any>(true)
  const [securityNoOfPaymentsError, setSecurityNoOfPaymentsError] = useState<any>(true)

  const [otherFinancials, setOtherFinancials] = useState<any>([
    // {
    //   name: '',
    //   amount: 0,
    //   noOfPayments: 0
    // }
  ])

  const [otherAmountError, setOtherAmountError] = useState<any>(false)
  const [otherNoOfPaymentsError, setOtherNoOfPaymentsError] = useState<any>(false)
  const [otherItem, setOtherItem] = useState<any>({})

  // Handle Add Other Options
  const handleAddOtherFinancials = () => {
    const values = [...otherFinancials]
    values.push({
      value: '',
    })
    setOtherFinancials(values)
  }

  // Handle Remove Other Options
  const handleRemoveOtherFinancials = (index: any) => {
    const values = [...otherFinancials]
    values.splice(index, 1)
    setOtherFinancials(values)

    // validation for item selection
    const temp: any = []
    let k: any = 0
    // console.log(filteredFinancialItems)

    for (let i = 0; i < financialItems?.length; i++) {
      let isTrue = true
      let ind: any = ''
      for (let j = 0; j < values?.length; j++) {
        // console.log(financialItems[i].name)
        // console.log(values[j].name)
        if (financialItems[i].name == values[j].name) {
          isTrue = false
          ind = i
        }
      }
      if (isTrue) {
        temp[k] = financialItems[i]
        k++
      }
      // console.log(temp)
    }

    setFilteredFinancialItems(temp)
    setIsFormDirty(true)
    if (flg == '2') setOpen(true)
    setTimeout(() => {
      setAutoSaveData(Date.now())
    }, 700)
  }

  const [count, setcount] = useState<any>(0)
  const [totalamount, settotalamount] = useState<any>(0)
  const [totalpayment, settotalpayment] = useState<any>(0)
  const [createtotalpayment, setCreatetotalpayment] = useState<any>(0)
  const [modeldata, setmodeldata] = useState<any>()
  const [modelindex, setmodelindex] = useState<any>()
  const [modelData, setmodelData] = useState<any>({})
  const [popUpData, setpopUpData] = useState<any>({})
  const [modelchequeData, setmodelchequeData] = useState<any>()
  const [popUpReceiptData, setpopUpReceiptData] = useState<any>()
  const [popupindex, setpopupindex] = useState<any>()
  const [tenancyPayment, setTenancyPayment] = useState<any>([])
  const [createtenancyPayment, setCreateTenancyPayment] = useState<any>([])
  const [isLoading, setIsLoading] = useState<any>()
  const [updateIds, setUpdateIds] = useState<any>([])
  const [deletePayment, setDeletePayment] = useState<any>([])
  const [updatePayment, setUpdatePayment] = useState<any>([])
  const [newPayment, setNewPayment] = useState<any>([])
  const [tenanatData, setTenantData] = useState<any>([])

  const [subTenantIds, setSubTenantIds] = useState<any>([])

  const [imgerrors, setImgError] = useState<any>({})
  const [modalBtnIsLoading, setModalBtnIsLoading] = useState<any>(false)
  const [deleteSubtenantArr, setDeleteSubtenantArr] = useState<any>([])
  const [sendError, setSendError] = useState<any>(true)
  const [finalPaid, setFinalPaid] = useState<any>()

  const [startDatePayload, setStartDatePayload] = useState<any>(null)
  const [endDatePayload, setEndDatePayload] = useState<any>(null)

  var optionArryPayload: any

  const unitId = localStorage.getItem('UnitId')
  const navigatePage = localStorage.getItem('page')

  const unitIDfromProperties = useSelector(
    (state: any) => state.counterReducer.unitIDfromProperties
  )
  const comefrom = useSelector((state: any) => state.counterReducer.comefrom)
  const [tenancyStatus, setTenancyStatus] = useState<any>(null)

  const {RangePicker} = DatePicker
  const [isReqCheck, setisReqCheck] = useState<any>(false)
  let status_isDraft: any = localStorage.getItem('isDraft')
  const [isSkeleton, setIsSkeleton] = useState<any>(true)

  useEffect(() => {
    if (flg == '1') {
      setisReqCheck(true)
      setIsSkeleton(false)
    } else {
      setisReqCheck(false)
      setIsSkeleton(true)
    }
    enableScrolling()
  }, [])

  const requestorId = useSelector((state: any) => state.counterReducer.requestorId)
  const comefromtenant = useSelector((state: any) => state.counterReducer.comefrom)

  const dateFormat = 'DD/MM/YYYY'

  const handleChnage = (e: any) => {
    const {name, value} = e.target
    setFormData({...formData, [name]: value})
  }
  const popUpchange = (name: string, value: any) => {
    // let {name, value} = e.target
    setpopUpData({...popUpData, [name]: value})
    // setpopUpData({...popUpData, [name]: isNaN(value) ? value : +value})
  }
  const modelchange = (e: any) => {
    let {name, value} = e.target
    setmodelData({...modelData, [name]: value})
  }
  const modelSubmit = () => {
    let data: any
    if (modeldata == '1') {
      data = {
        cheque: modelData,
      }
      data.cheque.image = modelchequeData
    } else if (modeldata == '2') {
      data = {
        bank: modelData,
      }
    } else if (modeldata == '0') {
      data = {
        card: {},
      }
    }
    // else if (modeldata == '1') {
    //   data = {
    //     card: {},
    //   }
    // }
    arry = arry.map((q: any, i: any) => {
      if (i === modelindex) {
        // 👇️ change value of name property
        return {...q, ['paymentValue']: data}
      }
      return q
    })

    setcount(count + 1)
    setShowCheange(false)
  }
  const popUpSubmit = (event: any, index: any) => {
    event.preventDefault()
    let data: any
    setmodelindex(index)
    if (paymentMethod === '0') {
      data = {
        card: {},
      }
      // data.card.image = popUpReceiptData
    } else if (paymentMethod === '1') {
      data = {
        cheque: {
          chequeNo: popUpData?.chequeNo,
          bankName: popUpData?.bankName,
          image: modelchequeData,
        },
      }
      // data.cheque.receiptImage = popUpReceiptData
      // data.cheque.chequeImage = modelchequeData
    } else if (paymentMethod === '2') {
      data = {
        bank: {
          IBAN: popUpData?.IBAN,
          accountNo: popUpData?.accountNo,
          bankName: popUpData?.bankName,
        },
      }
      // data.bank.image = popUpReceiptData
    } else {
      data = {
        cash: {},
      }
      // data.cash.image = popUpReceiptData
    }
    let receiptd: any = {
      referenceNo: popUpData?.referenceNo,
      receiptDate: popUpData?.receiptDate,
      image: popUpReceiptData,
    }

    let temp: any = {...popUpData, paymentValue: data, paymentMethod}
    setFinalPaid([temp])
    console.log('\ntemp', temp)
    const new_tenancyPayment = tenancyPayment.map((el: any, i: any) => {
      if (i == popupindex) {
        return {
          ...el,
          amount: temp.amountPaid,
          amountPaid: temp.amountPaid,
          amountReceived: temp.amountReceived,
          outstandingBalance: temp.outstandingBalance,
          serviceFee: temp.serviceFee,
          paymentValue: temp.paymentValue,
          paymentMethod: temp.paymentMethod,
          receiptDate: temp.receiptDate,
          paymentDate: temp.paymentDate,
          receiptURL: popUpReceiptData,
        }
      } else {
        return el
      }
    })
    const new_createtenancyPayment = createtenancyPayment.map((el: any, i: any) => {
      if (i == popupindex) {
        return {
          ...el,
          amount: temp.amountPaid,
          amountPaid: temp.amountPaid,
          amountReceived: temp.amountReceived,
          outstandingBalance: temp.outstandingBalance,
          serviceFee: temp.serviceFee,
          paymentValue: temp.paymentValue,
          paymentMethod: temp.paymentMethod,
          receiptDate: temp.receiptDate,
          paymentDate: temp.paymentDate,
          receiptURL: popUpReceiptData,
        }
      } else {
        return el
      }
    })
    setTenancyPayment(new_tenancyPayment)
    setCreateTenancyPayment(new_createtenancyPayment)

    setpopUpData({})
    setmodelchequeData('')
    setpopUpReceiptData('')

    setcount(count + 1)
    setShowPaid(false)
  }

  const handleChangeContractNo = (e: any) => {
    const {name, value} = e.target
    setTenancyData({...tenancyData, [name]: value})
    setIsFormDirty(true)
    setContractNoError(false)
  }

  const handleChnageTenancy = (e: any) => {
    console.log(e.target.value)
    const {name, value} = e.target
    setTenancyData({...tenancyData, [name]: value})

    setTimeout(() => {
      autoSaveTenancy()
    }, 2500)
  }

  const handleChnagePayment = (e: any, i: any, v: any) => {
    let {name, value} = e.target
    if (name == 'amount' || name == 'paymentMethod' || name == 'rentType' || name == 'status') {
      value = parseInt(value)
    }
    if (flg == '1') {
      if (name == 'amount') {
        let dat = createtenancyPayment[i].amount
        if (!value) {
          value = 0
        }

        if (dat) {
          settotalamount(totalamount - dat + value)
        } else {
          settotalamount(totalamount + value)
        }
      }
      let updateTenancyPayment = createtenancyPayment.map((q: any, k: any) => {
        if (k === i) {
          if (name == 'paymentSchedule' || name == 'paymentRemainder') {
            let d = new Date(value)
            return {...q, [name]: moment(d).format('YYYY-MM-DD')}
          } else {
            return {...q, [name]: value}
          }
        }
        return q
      })
      setCreateTenancyPayment(updateTenancyPayment)
      setcount(count + 1)
      setPaymentData({...paymentData, [name]: value})
    } else {
      if (name == 'amount') {
        let dat = tenancyPayment[i].amount
        if (!value) {
          value = 0
        }

        if (dat) {
          settotalamount(totalamount - dat + value)
        } else {
          settotalamount(totalamount + value)
        }
      }
      let updateTenancyPayment = tenancyPayment.map((q: any, k: any) => {
        if (k === i) {
          if (name == 'paymentSchedule' || name == 'paymentRemainder') {
            let d = new Date(value)
            return {...q, [name]: moment(d).format('YYYY-MM-DD')}
          } else {
            return {...q, [name]: value}
          }
        }
        return q
      })
      setTenancyPayment(updateTenancyPayment)
      setcount(count + 1)
      setPaymentData({...paymentData, [name]: value})
      if (!updateIds.includes(v._id)) {
        setUpdateIds([...updateIds, v._id])
      }
    }
  }

  const callmodel = (data: any, index: any) => {
    setmodeldata(data)
    setmodelindex(index)
    setcount(count + 1)
    setShowCheange(true)
  }
  const callpopUp = (data: any, index: any) => {
    setmodelindex(index)
    setcount(count + 1)
    setShowPaid(true)
  }

  // work in progress

  const handleChnageOption = (e: any) => {
    let {name, checked} = e.target
    console.log('e.target.value', e.target.value)
    if (name == 'other') {
      setOptionArry({...optionArry, [name]: [e.target.value]})
      optionArryPayload = {...optionArry, [name]: [e.target.value]}
    } else {
      if (name == 'none' && checked == true) {
        setOption(0)
        setOptionArry({
          ...optionArry,
          ['other']: [],
          ['chillerFree']: false,
          ['maintenanceFree']: false,
          [name]: checked,
        })
        optionArryPayload = {
          ...optionArry,
          ['other']: [],
          ['chillerFree']: false,
          ['maintenanceFree']: false,
          [name]: checked,
        }
      } else {
        setOptionArry({...optionArry, [name]: checked})
        optionArryPayload = {...optionArry, [name]: checked}
      }
    }

    setcount(count + 1)
    setIsFormDirty(true)
    if (flg == '2') setOpen(true)
    setTimeout(() => {
      setAutoSaveData(Date.now())
    }, 700)
  }

  const getUbitDataById = () => {
    ApiGet(`corporate/unit/${id}`)
      .then((res) => {
        setUnitData(res?.data?.data)
        setSendError(false)
        setDisabledBtn(false)
      })
      .catch((err) => console.log('err', err))
  }

  const getUnitById = (value: any) => {
    ApiGet(`corporate/unit/${value}`)
      .then((res) => {
        setUnitData(res?.data?.data)
        setSendError(false)
        setDisabledBtn(false)
      })
      .catch((err) => console.log('err', err))
  }

  const handleSubmitId = (value: any) => {
    setIsFormDirty(true)
    getUnitById(value)
  }
  const handleChnageSubTenant = (e: any) => {
    const {name, value} = e.target
    setSubTenant({...subTenant, [name]: value})
  }

  const handleChnageTenancyDate = (dates: any) => {
    if (dates != null) {
      let body: any
      if (flg == '1') {
        body = {
          startDate: dates?.[0].format('YYYY-MM-DD'),
          endDate: dates?.[1].format('YYYY-MM-DD'),
          unitId: unitData?._id,
        }
      } else {
        body = {
          startDate: dates?.[0].format('YYYY-MM-DD'),
          endDate: dates?.[1].format('YYYY-MM-DD'),
          unitId: unitData?._id,
          tenancyId: tenancyId,
        }
      }

      ApiPost(`corporate/tenancy/term/validator`, body)
        .then((res) => {
          if (res?.data?.data?.isAlreadyExist) {
            ErrorToast(
              'This unit is currently occupied during the selected term, please select another term date'
            )
            setStart(null)
            setStartDatePayload(null)

            setEnd(null)
            setEndDatePayload(null)

            setTotalDays(0 + ' days')
            setTtlDays(0)
            setRemainingDays(0 + ' days')
            if (flg == '2') setOpen(true)
            setTimeout(() => {
              setAutoSaveData(Date.now())
            }, 700)
          } else {
            setDateError(false)
            setStart(dates?.[0].format('DD/MM/YYYY'))
            setStartDatePayload(dates?.[0].format('YYYY-MM-DD'))

            setEnd(dates?.[1].format('DD/MM/YYYY'))
            setEndDatePayload(dates?.[1].format('YYYY-MM-DD'))

            var start = moment(dates?.[0])
            var end = moment(dates?.[1])

            let today = moment(new Date())
            setTotalDays(Math.floor(end.diff(start, 'days', true)) + ' days')
            setTtlDays(Math.floor(end.diff(start, 'days', true)))
            setRemainingDays(Math.floor(end.diff(today, 'days', true)) + ' days')

            if (Math.floor(end.diff(start, 'days', true)) < tenancyData?.renewalDays)
              setRenewalError(true)
            else setRenewalError(false)

            setIsFormDirty(true)
            if (flg == '2') setOpen(true)
            setTimeout(() => {
              setAutoSaveData(Date.now())
            }, 700)
          }
        })
        .catch((err) => console.log('err', err))
    } else {
      setStart(null)
      setStartDatePayload(null)

      setEnd(null)
      setEndDatePayload(null)

      setTotalDays(0 + ' days')
      setTtlDays(0)
      setRemainingDays(0 + ' days')
      if (flg == '2') setOpen(true)
      setTimeout(() => {
        setAutoSaveData(Date.now())
      }, 700)
    }

    // console.log(dates)
    // setStart(moment(e[0]?._d).format('YYYY-MM-DD'))
    // setEnd(moment(e[1]?._d).format('YYYY-MM-DD'))
  }

  const handleChnage12 = (e: any) => {
    const {name, value} = e.target
    setPaymentMethod(e.target.value)
  }

  const deleteSubtenant = (j: any) => {
    setDeleteSubtenantArr((prev: any) => [
      ...prev,
      subTenantData.filter((el: any, i: any) => i == j),
    ])
    setSubTenantData(subTenantData.filter((el: any, i: any) => i !== j))
  }
  const addSubTenant = () => {
    setSubTenantIds([...subTenantIds, subTenantId])
    setSubTenantData([...subTenantData, subTenant])
    setCreate(false)

    let values = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      countryCode: '',
      email: '',
    }
    setSubTenant(values)
    setSubTenantId('')
  }

  const deleteRow = (i: any, amount: any, deleteData: any) => {
    if (flg == '1') {
      setCreateTenancyPayment(createtenancyPayment.filter((v: any, index: any) => index !== i))
    } else if (flg == '2' || flg == '3') {
      setTenancyPayment(tenancyPayment.filter((v: any, index: any) => index !== i))
      setDeletePayment([...deletePayment, deleteData])
      if (amount == undefined) {
        amount = 0
      }
      settotalamount(totalamount - amount)
      settotalpayment(totalpayment - 1)
      setcount(count + 1)
      if (tenancyPayment.length === 0) {
        setCard('')
      }
      let a: any = []
      tenancyPayment.map((v: any) => {
        if (v?.paymentMethod === 0) {
          a.push(v?.paymentMethod)
        }
        if (v?.paymentMethod === 1) {
          a.push(v?.paymentMethod)
        }
      })
      const ages = a
      const uniqueAges = ages.filter((x: any, i: any, a: any) => a.indexOf(x) == i)

      if (uniqueAges.length === 2) {
        setCard('2')
      }
      if (uniqueAges.length === 1) {
        if (uniqueAges[0] === 0) {
          setCard('0')
        } else {
          setCard('1')
        }
      }
      if (uniqueAges.length === 0) {
        if (a.length === 0) {
          setCard('')
        }
      }
    }
  }

  const imageCheque = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/cheque', formData)
      .then(async (res) => {
        setmodelchequeData(res?.data?.data?.image)
      })
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const imagerecipt = async (e: any) => {
    let file = e.target.files[0]
    let fileURL = URL.createObjectURL(file)
    file.fileURL = fileURL
    let formData = new FormData()
    formData.append('image', file)
    await ApiUpload('upload/image/cheque', formData)
      .then(async (res) => {
        setpopUpReceiptData(res?.data?.data?.image)
      })
      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }

  const [uploadOtherImgTenancy, setuploadOtherImgTenancy] = useState<any>([])
  const [uploadContractTenancy, setuploadContractTenancy] = useState<any>([])

  const [uploadOtherImgTenant, setuploadOtherImgTenant] = useState<any>([])
  const [uploadIdTenant, setuploadIdTenant] = useState<any>([])
  const [uploadresidencyTenant, setuploadresidencyTenant] = useState<any>([])
  const [uploadPassportTenant, setuploadPassportTenant] = useState<any>([])
  const [selectedUploadFile, setSelectedUploadFile] = useState<any>()
  const [finalFileUpload, setFinalFileUpload] = useState<any>([])
  const [fileFound, setFileFound] = useState<any>(false)
  const [newTenancyID, setNewTenancyID] = useState<any>(null)
  const [newTenancyData, setNewTenancyData] = useState<any>(state?.tenancyData)
  const [newTenantId, setNewTenantID] = useState<any>('')

  const [prevRecId, setPrevRecId] = useState<any>()
  const [prevInvId, setPrevInvId] = useState<any>()

  const [editName, setEditName] = useState(false)
  // const dropArea: any = document.querySelector('.drag-area'),
  //   dragText = dropArea.querySelector('header'),
  //   button = dropArea.querySelector('button'),
  //   input = dropArea.querySelector('input')
  // let file: any = ''
  // button.onclick = () => {
  //   input.click() //if user click on the button then the input also clicked
  // }
  // input.addEventListener('change', function (e: any) {
  //   //getting user select file and [0] this means if user select multiple files then we'll select only the first one
  //   console.log('this.files[0]', e)
  //   // file = this.files[0]
  // })

  const [payments, setPayments] = useState<any>()
  const [pageLimit, setPageLimit] = useState<any>(25)
  const [page, setPage] = useState<any>(1)
  const [unitDetails, setUnitDetails] = useState<any>()
  const [editIncomeModal, setEditIncomeModal] = useState<any>(false)
  const [editIncomeModalDraft, setEditIncomeModalDraft] = useState<any>(false)
  const [dataToEdit, setDataToEdit] = useState<any>()
  const [indexToEdit, setIndexToEdit] = useState<any>()

  const [addNewTenant, setAddNewTenant] = useState<any>(false)
  const [editTenantModal, setEditTenantModal] = useState<any>(false)
  const [tenantIdToView, setTenantIdToView] = useState<any>()
  const [tenantIdForFolder, setTenantIdForFolder] = useState<any>()

  const [tenantFolderModal, setTenantFolderModal] = useState<any>(false)
  const [tenantFileModal, setTenantFileModal] = useState<any>(false)
  const [targetFolder, setTargetFolder] = useState<any>()

  const getAllPayments = () => {
    const body = {
      limit: 25,
      page: page,
      tenancyId: tenancyId,
      paymentStatus: 4,
      includePaymentSubItem: true,
    }
    ApiPost(`corporate/payment/get/tenancy?isCloneRecord=true`, body)
      .then((res) => {
        let values: any = []

        for (let i = 0; i < res?.data?.data?.payment_data?.length; i++) {
          let temp: any = []
          values[i] = {
            payment: {
              _id: res?.data?.data?.payment_data?.[i]?._id,
              name: res?.data?.data?.payment_data?.[i]?.name,
              note: res?.data?.data?.payment_data?.[i]?.note,
              currency: 'AED',
              paymentStatus: res?.data?.data?.payment_data?.[i]?.paymentStatus,
              categories: res?.data?.data?.payment_data?.[i]?.categories,
              amountPaid: parseFloat(res?.data?.data?.payment_data?.[i]?.amountPaid),
              outstandingAmount: res?.data?.data?.payment_data?.[i]?.outstandingAmount,
              subTotalAmount: res?.data?.data?.payment_data?.[i]?.subTotalAmount,
              totalVAT: res?.data?.data?.payment_data?.[i]?.totalVAT,
              totalAmount: res?.data?.data?.payment_data?.[i]?.totalAmount,
              // paymentType: 0,
              paymentMethod: res?.data?.data?.payment_data?.[i]?.paymentMethod,
              paymentAccountId: res?.data?.data?.payment_data?.[i]?.paymentAccountId,
              chequeBankName: res?.data?.data?.payment_data?.[i]?.chequeBankName,
              isCardVerificationEnable:
                res?.data?.data?.payment_data?.[i]?.isCardVerificationEnable,
              dueDate: res?.data?.data?.payment_data?.[i]?.dueDate,
              attachments: res?.data?.data?.payment_data?.[i]?.attachments,
              chequeImages: res?.data?.data?.payment_data?.[i]?.chequeImages,
              tenancyId: tenancyId,
              unitId: unitDetails?._id,
              clusterId: unitDetails?.clusterId,
              unitGroupId: unitDetails?.unitGroupId,
              communityId: unitDetails?.communityId,
              floorId: unitDetails?.floorId,
              buildingId: unitDetails?.buildingId,
              datePaid: res?.data?.data?.payment_data?.[i]?.datePaid,
              createdAt: res?.data?.data?.payment_data?.[i]?.createdAt,

            },
            new_payment_sub_item: [],
            // new_payment_invoice: payInv,
            // new_payment_receipt: prec,
            // new_payment_reminder: remindersSched,
          }
          for (let j = 0; j < res?.data?.data?.payment_data?.[i]?.payment_sub_item?.length; j++) {
            temp[j] = {
              _id: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?._id,
              name: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?.name,
              amount: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?.amount,
              quantity: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]?.quantity,
              isLateItem: res?.data?.data?.payment_data?.[i]?.payment_sub_item?.[j]
                ?.isLatePaymentFeesRecord
                ? true
                : false,
            }
          }
          values[i].new_payment_sub_item = temp
        }

        setIncomePaymentPayload(values)
        setPageLimit(res?.data?.data?.state?.page_limit)
        for (let i = 0; i < res?.data?.data?.payment_data.length; i++) {
          let values = [...show]
          values[i] = false
          setShow(values)
        }
        setOpen(false)
      })
      .catch((err) => console.log('err', err))
  }

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  const getPaymentById = async (id: any) => {
    ApiGet(`corporate/payment/${id}`)
      .then(async (res) => {
        setDataToEdit(res?.data?.data)

        await ApiGet(`corporate/unit/${res?.data?.data?.unitId}`)
          .then((res) => {
            setUnitDetails(res?.data?.data)
            setEditIncomeModal(true)
          })
          .catch((err) => console.log('err', err))
      })
      .catch((err) => console.log('err', err))
  }

  const [randomDatabaseId, setRandomDatabaseId] = useState<any>()
  useEffect(() => {
    setRandomDatabaseId(new Date().getTime())
  }, [])

  let fileLists: any = []

  const onFilesAdded = async (event: any, type: any) => {
    setFileFound(false)
    const files = event.target.files
    fileLists = Array.from(files)
    setSelectedUploadFile({files: fileLists, type: type})
    fileLists.forEach((element: any, i: any) => {
      setFinalFileUpload((prev: any) => {
        if (prev[i]) {
          prev[i].index = i
          prev[i].fileName = type == 'tenancy' || type == 'tenant' ? '' : element.name
          prev[i].selctedfile = element
          prev[i].type = type
          return [...prev]
        } else {
          return [...prev, {index: i, fileName: '', selctedfile: element, type: type}]
        }
      })
    })
    if (type == 'tenancy' || type == 'tenant') {
      handleShow()
    } else {
      saveImageFileName()
    }
  }
  const uploadImageName = (e: any, selctedfile: any, i: any) => {
    setFinalFileUpload((prev: any) => {
      prev[i].fileName = e.target.value
      return [...prev]
    })
  }
  const getFilename = (file: any) => {
    return file.split('/').pop()
  }

  const getFilenameToSelect = (file: any, type: any) => {
    if (type) return file.split('.')[1]
    else return file.split('.')[0]
  }
  const saveImageFileName = async () => {
    setModalBtnIsLoading(true)
    setFileFound(false)
    for (let i = 0; i < finalFileUpload.length; i++) {
      let formData: any = new FormData()
      let data: any = finalFileUpload[i]
      let fileURL: any = URL.createObjectURL(data.selctedfile)
      let extensionType = data?.selctedfile?.name?.split('.')[1]
      let type: any = finalFileUpload[i].type

      data.fileURL = fileURL
      formData.append('image', data?.selctedfile)
      let fileType: any = ''

      switch (type) {
        case 'tenancy':
          fileType = 'tenancy_other'
          break
        case 'tenant':
          fileType = 'tenant_other'
          break
        default:
          break
      }

      // if (type == 'tenancy' || type == 'tenant') {
      await ApiPost(
        `upload/file_checker?fileType=${fileType}&databaseId=${randomDatabaseId}&fileName=${finalFileUpload[i]?.fileName}.${extensionType}`,
        formData
      )
        .then(async (res) => {
          if (res?.data?.data?.isFileExist) {
            setFileFound(true)
          } else {
            await ApiUpload(
              `upload/image/params?fileType=${fileType}&databaseId=${randomDatabaseId}&fileName=${finalFileUpload[i]?.fileName}.${extensionType}`,
              formData
            )
              .then((res) => {
                if (type == 'tenant') {
                  setuploadOtherImgTenant([...uploadOtherImgTenant, res?.data?.data?.image])
                } else if (type == 'tenancy') {
                  setuploadOtherImgTenancy([...uploadOtherImgTenancy, res?.data?.data?.image])
                }
                setIsFormDirty(true)
                handleClose()
              })
              .catch((err) => {
                console.log('res_blob', err)
                ErrorToast(err?.message)
              })

            if (type == 'tenancy') {
              setTimeout(() => {
                autoSaveTenancy()
              }, 2000)
            } else {
            }
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
          setModalBtnIsLoading(false)
        })
    }
  }

  let fileLists_single: any = []

  const onFilesAddedSingle = async (event: any, type: any, isTenancy: any) => {
    const files = event.target.files
    fileLists_single = Array.from(files)

    for (let i = 0; i < fileLists_single.length; i++) {
      let formData: any = new FormData()
      let data: any = fileLists_single[i]
      let fileURL: any = URL.createObjectURL(data)
      let filetypes: any = fileLists_single[i].type
      data.fileURL = fileURL
      formData.append('image', data)
      console.log('\ntype', filetypes)

      let url = ''
      if (type == 'tenant') {
        url = 'upload/image/tenant_other'
      } else if (type == 'tenancy') {
        url = 'upload/image/tenancy_other'
      } else if (type == 'uploadId') {
        url = 'upload/image/tenant_id'
      } else if (type == 'uploadResidency') {
        url = 'upload/image/tenant_residency'
      } else if (type == 'uploadPassport') {
        url = 'upload/image/tenant_passport'
      } else if (type == 'uploadContract') {
        url = 'upload/image/tenancy_contract'
      }

      await ApiUpload(`${url}`, formData)
        .then((res) => {
          if (type == 'uploadId') {
            setuploadIdTenant([res?.data?.data?.image])
          } else if (type == 'uploadResidency') {
            setuploadresidencyTenant([res?.data?.data?.image])
          } else if (type == 'uploadPassport') {
            setuploadPassportTenant([res?.data?.data?.image])
          } else if (type == 'uploadContract') {
            setuploadContractTenancy([res?.data?.data?.image])
          }

          if (isTenancy) {
            setTimeout(() => {
              autoSaveTenancy()
            }, 2500)
          } else {
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    }
  }

  const handleClose = () => {
    setModalBtnIsLoading(false)
    setShowImageName(false)
  }
  const handleShow = () => setShowImageName(true)

  const deleteUploadImg = async (e: any, type: any, ele: any) => {
    const url = ele.replace(`${Bucket}`, '')
    await ApiPost('upload/delete_file', {url})
      .then(() => {
        if (type == 'tenant') {
          setuploadOtherImgTenant(uploadOtherImgTenant.filter((flg: any, j: any) => j !== e))
        } else if (type == 'tenancy') {
          setuploadOtherImgTenancy(uploadOtherImgTenancy.filter((flg: any, j: any) => j !== e))
          setTimeout(() => {
            autoSaveTenancy()
          }, 2500)
        } else if (type == 'uploadId') {
          setuploadIdTenant(uploadIdTenant.filter((flg: any, j: any) => j !== e))
        } else if (type == 'uploadResidency') {
          setuploadresidencyTenant(uploadresidencyTenant.filter((flg: any, j: any) => j !== e))
        } else if (type == 'uploadPassport') {
          setuploadPassportTenant(uploadPassportTenant.filter((flg: any, j: any) => j !== e))
        } else if (type == 'uploadContract') {
          setuploadContractTenancy(uploadContractTenancy.filter((flg: any, j: any) => j !== e))
        }
      })

      .catch((err) => {
        console.log('res_blob', err)
        ErrorToast(err?.message)
      })
  }
  const [newName, setnewName] = useState<any>()
  const [isEditImgName, setIsEditImgName] = useState<any>(false)
  const changeFileNameOther = async (type: any, index: any) => {
    let body: any
    if (isEditImgName) {
      if (type == 'tenancy') {
        body = {
          newFileName: newName + '.' + uploadOtherImgTenancy[index].split('.').pop(),
          image: uploadOtherImgTenancy[index],
        }
      } else if (type == 'tenant') {
        body = {
          newFileName: newName + '.' + uploadOtherImgTenant[index].split('.').pop(),
          image: uploadOtherImgTenant[index],
        }
      }
      console.log('\nbody', body)

      await ApiPost('upload/rename_file', body)
        .then((res) => {
          if (type == 'tenancy') {
            setEditName(false)
            // setuploadOtherImgTenancy([...uploadOtherImgTenancy, res?.data?.data?.image])
            const remove_index = uploadOtherImgTenancy.filter((el: any, i: any) => i !== index)
            setuploadOtherImgTenancy([...remove_index, res?.data?.data?.image])
          } else if (type == 'tenant') {
            setEditName(false)
            const remove_index = uploadOtherImgTenant.filter((el: any, i: any) => i !== index)
            setuploadOtherImgTenant([...remove_index, res?.data?.data?.image])
          }
        })
        .catch((err) => {
          console.log('res_blob', err)
          ErrorToast(err?.message)
        })
    } else {
      setEditName(false)
    }
  }

  const handleNumber = (Phone: any, e: any) => {
    let CountryCode: any = e?.dialCode
    let PhoneNumber: any = Phone.split(CountryCode)[1]
    setFormData({
      ...formData,
      ['countryCode']: parseInt(CountryCode),
      ['phoneNumber']: parseInt(PhoneNumber),
    })
    setPhone(Phone)
  }

  const handleNumber1 = (Phone: any, e: any) => {
    let CountryCode: any = e.dialCode
    let PhoneNumber: any = Phone.split(CountryCode)[1]

    setSubTenant({
      ...subTenant,
      ['countryCode']: parseInt(CountryCode),
      ['phoneNumber']: parseInt(PhoneNumber),
    })
  }
  setTimeout(() => {
    setIsLoading(false)
  }, 5000)

  setTimeout(() => {
    setOpen(false)
  }, 15000)

  const varificationCodeValidation = () => {
    let errorMsg: any = {
      error: {},
      isValid: true,
    }

    if (!unitData?._id) {
      errorMsg.isValid = false
      errorMsg.error['unitdata'] = 'This field is Required'
    }
    return errorMsg
  }

  const checkIsImage = (fileUrl: any) => {
    if (fileUrl?.includes('blob')) {
      return true
    } else if (fileUrl?.match(/\.(jpg|jpeg|png|gif)$/i)) {
      return true
    }
    return false
  }

  const removeBucket = (url: any) => {
    if (typeof url === 'string') {
      return url.replaceAll(Bucket, '')
    } else {
      let urlData: any = []
      url?.forEach((element: any) => {
        urlData?.push(element?.replaceAll(Bucket, ''))
      })
      return urlData
    }
  }

  const [tenantDocuments, setTenantDocuments] = useState<any>([])
  const [tenantDeletedDocuments, setTenantDeletedDocuments] = useState<any>([])

  const [contractDocuments, setContractDocuments] = useState<any>([])
  const [contractDeletedDocuments, setContractDeletedDocuments] = useState<any>([])

  const onSaveNewFlow = (data: any, event: any, isDraft: any) => {
    // event.preventDefault()
    setIsLoading(true)

    let renewReqId: any = localStorage.getItem('renewRequestId')

    let payment: any = [...incomePaymentPayload]

    let deletedPayments: any = []
    let deleteInd: any = 0

    let discardedPayments: any = [...deletedPayments]

    if (isFinance) {
      for (let i = 0; i < incomePaymentPayload?.length; i++) {
        let newItems: any = []
        let newItemsInd: any = 0
        let updateItems: any = []
        let updateItemsInd: any = 0

        for (let j = 0; j < incomePaymentPayload[i]?.new_payment_sub_item?.length; j++) {
          payment[i].new_payment_sub_item[j] = {
            _id:
              payment[i]?.new_payment_sub_item[j]?._id?.trim()?.length == 0
                ? undefined
                : payment[i]?.new_payment_sub_item[j]?._id,
            VATAmount: payment[i]?.new_payment_sub_item[j].VATAmount,
            VATPercentage: payment[i]?.new_payment_sub_item[j].VATPercentage,
            amount: payment[i]?.new_payment_sub_item[j].amount,
            amountPaid: payment[i]?.new_payment_sub_item[j].amountPaid,
            isLatePaymentFeesRecord: payment[i]?.new_payment_sub_item[j].isLatePaymentFeesRecord,
            latePaymentFees: payment[i]?.new_payment_sub_item[j].latePaymentFees,
            latePaymentObject: payment[i]?.new_payment_sub_item[j].latePaymentObject,
            latePaymentSelectionStatus:
              payment[i]?.new_payment_sub_item[j].latePaymentSelectionStatus,
            name: payment[i]?.new_payment_sub_item[j].name,
            nextLatePaymentCycle: payment[i]?.new_payment_sub_item[j].nextLatePaymentCycle,
            oldLatePaymentCycleList: payment[i]?.new_payment_sub_item[j].oldLatePaymentCycleList,
            outstandingAmount: payment[i]?.new_payment_sub_item[j].outstandingAmount,
            paymentItemListId: payment[i]?.new_payment_sub_item[j].paymentItemListId,
            paymentSubItemStatus: payment[i]?.new_payment_sub_item[j].paymentSubItemStatus,
            quantity: payment[i]?.new_payment_sub_item[j].quantity,
            serviceJobId: payment[i]?.new_payment_sub_item[j].serviceJobId,
            serviceRequestId: payment[i]?.new_payment_sub_item[j].serviceRequestId,
            totalAmount: payment[i]?.new_payment_sub_item[j].totalAmount,
          }

          if (payment[i]?.new_payment_sub_item[j]?._id?.trim()?.length == undefined) {
            newItems[newItemsInd] = payment[i].new_payment_sub_item[j]
            newItemsInd++
          } else {
            updateItems[updateItemsInd] = payment[i].new_payment_sub_item[j]
            updateItemsInd++
          }
        }

        // Payment Invoice
        if (payment[i]?.payment?.invoiceId != undefined) {
          payment[i].update_payment_invoice = payment[i].new_payment_invoice
          // payment[i].update_payment_invoice[0]._id = payment[i]?.payment?.invoiceId

          payment[i].update_payment_invoice[0] = {
            _id: payment[i]?.payment?.invoiceId,
            // "id": 51,
            // "workspaceId": "ae7ef7b8-7fa6-4375-9f97-3705a14186a8",
            note: payment[i].new_payment_invoice?.[0]?.note,
            invoiceNo: payment[i].new_payment_invoice?.[0]?.invoiceNo,
            date: moment(new Date()).format('YYYY-MM-DD'),
            pdfURL: payment[i].new_payment_invoice?.[0]?.pdfURL,
            // invoiceLogo: "6582bac50d9a049c6882be03/logo/1703154826946.jpg",
            // "invoiceCompany": {
            //   "trn": "147852369",
            //   "name": "Muhammad",
            //   "address": "9XRH+3J7, Lund Khwar, Mardan, Khyber Pakhtunkhwa, Pakistan",
            //   "countryCode": "+92",
            //   "phoneNumber": "341-0521297",
            //   "email": "test@mailinator.com",
            //   "_id": "6584148bfe71fa5a07a69c8c"
            // },
            attachments: payment[i].new_payment_invoice?.[0]?.attachments,
            paymentSubItems: payment[i].new_payment_sub_item,
            paymentMethod: payment[i]?.payment?.paymentMethod,
            receiptAmount:
              payment[i].new_payment_receipt?.length > 0
                ? payment[i].new_payment_receipt?.[0]?.amountPaid
                : 0,
            totalRefundAmount: 0,
            refundVATAmount: 0,
            refundAmount: 0,
            totalAmount: payment[i]?.payment?.totalAmount,
            saveStatus: 1,
            creditAmount: 0,
            creditVATAmount: 0,
            totalCreditAmount: 0,
            debitAmount: 0,
            debitVATAmount: 0,
            totalDebitAmount: 0,
            totalVAT: payment[i]?.payment?.totalVAT,
            subTotalAmount: payment[i]?.payment?.subTotalAmount,
            isReceiptGenerated: payment[i].new_payment_receipt?.length > 0 ? true : false,
            isShareToTenant: payment[i].new_payment_invoice?.[0]?.isShareToTenant,
            isManualPDF: payment[i].new_payment_invoice?.[0]?.isManualPDF,
            // "isMainPDF": true,
            isActive: true,
            isLock: false,
            isBlock: false,
            isVoid: false,
            paymentAccountId: payment[i].new_payment_invoice?.[0]?.paymentAccountId,
            paymentId: payment[i]?.payment?._id,
            // "createdBy": "6582bac50d9a049c6882be03"
          }
          payment[i].new_payment_invoice = []
        }

        // Payment Receipt
        if (payment[i]?.payment?.receiptId != undefined) {
          if (payment[i].new_payment_receipt?.length == 0) {
            payment[i].delete_payment_receipt = [{_id: payment[i]?.payment?.receiptId}]
          } else {
            payment[i].update_payment_receipt = payment[i].new_payment_receipt
            payment[i].update_payment_receipt[0]._id = payment[i]?.payment?.receiptId
            payment[i].new_payment_receipt = []
          }
        }

        // Payment reminder
        let newReminder: any = []
        let updateReminder: any = []
        let newReminderInd: any = 0
        let updateReminderInd: any = 0

        if (payment[i]?.new_payment_reminder?.length > 0) {
          for (let j = 0; j < payment[i]?.new_payment_reminder?.length; j++) {
            if (
              payment[i]?.new_payment_reminder?.[j]?._id != undefined &&
              payment[i]?.new_payment_reminder?.[j]?._id?.trim()?.length > 0
            ) {
              updateReminder[updateReminderInd] = payment[i]?.new_payment_reminder?.[j]
              updateReminderInd++
            } else {
              newReminder[newReminderInd] = payment[i]?.new_payment_reminder?.[j]
              newReminder[newReminderInd]._id = undefined
              newReminderInd++
            }
          }
          payment[i].new_payment_reminder = newReminder
          payment[i].update_payment_reminder = updateReminder
        }

        payment[i].new_payment_sub_item = newItems
        payment[i].update_payment_sub_item = updateItems
        payment[i].payment.datePaid = undefined
        payment[i].isManipulated = undefined
        payment[i].payment.receiptId = undefined
        payment[i].payment.invoiceId = undefined
      }
    } else {
      discardedPayments = []
      for (let i = 0; i < incomePaymentPayload?.length; i++) {
        discardedPayments[deleteInd] = incomePaymentPayload[i]?.payment?._id
      }
    }

    //  tenant Documents
    let tentDoc: any = []
    let tentDocInd: any = 0

    for (let i = 0; i < tenantDocuments?.length; i++) {
      if (tenantDocuments[i]?.isCheck) {
        let files: any = []
        if (tenantDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < tenantDocuments[i]?.fileList?.length; j++) {
            if (tenantDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: tenantDocuments[i]?.fileList[j]?.fileName,
                fileURL: tenantDocuments[i]?.fileList[j]?.fileURL,
                _id: tenantDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        tentDoc[tentDocInd] = {
          _id: tenantDocuments[i]?._id,
          name: tenantDocuments[i]?.name,
          isSystemGenerated: tenantDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        tentDocInd = tentDocInd + 1
      }
    }

    tentDocInd = tentDoc.length

    for (let i = 0; i < tenantDeletedDocuments?.length; i++) {
      if (tenantDeletedDocuments[i]?.isCheck) {
        let files: any = []
        if (tenantDeletedDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < tenantDeletedDocuments[i]?.fileList?.length; j++) {
            if (tenantDeletedDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: tenantDeletedDocuments[i]?.fileList[j]?.fileName,
                fileURL: tenantDeletedDocuments[i]?.fileList[j]?.fileURL,
                _id: tenantDeletedDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        tentDoc[tentDocInd] = {
          _id: tenantDeletedDocuments[i]?._id,
          name: tenantDeletedDocuments[i]?.name,
          isSystemGenerated: tenantDeletedDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        tentDocInd = tentDocInd + 1
      }
    }

    // contract Documents
    let contractDoc: any = []
    let contractDocInd: any = 0

    for (let i = 0; i < contractDocuments?.length; i++) {
      if (contractDocuments[i]?.isCheck) {
        let files: any = []
        if (contractDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < contractDocuments[i]?.fileList?.length; j++) {
            if (contractDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: contractDocuments[i]?.fileList[j]?.fileName,
                fileURL: contractDocuments[i]?.fileList[j]?.fileURL,
                _id: contractDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        contractDoc[contractDocInd] = {
          _id: contractDocuments[i]?._id,
          name: contractDocuments[i]?.name,
          isSystemGenerated: contractDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        contractDocInd = contractDocInd + 1
      }
    }

    contractDocInd = contractDoc.length

    for (let i = 0; i < contractDeletedDocuments?.length; i++) {
      if (contractDeletedDocuments[i]?.isCheck) {
        let files: any = []
        if (contractDeletedDocuments[i]?.fileList?.length > 0) {
          let ind: any = 0
          for (let j = 0; j < contractDeletedDocuments[i]?.fileList?.length; j++) {
            if (contractDeletedDocuments[i]?.fileList[j].isChecked) {
              files[ind] = {
                fileName: contractDeletedDocuments[i]?.fileList[j]?.fileName,
                fileURL: contractDeletedDocuments[i]?.fileList[j]?.fileURL,
                _id: contractDeletedDocuments[i]?.fileList[j]?._id,
              }
              ind = ind + 1
            }
          }
        }
        contractDoc[contractDocInd] = {
          _id: contractDeletedDocuments[i]?._id,
          name: contractDeletedDocuments[i]?.name,
          isSystemGenerated: contractDeletedDocuments[i]?.isSystemGenerated,
          fileList: files,
          tenantId: newTenantId,
        }
        contractDocInd = contractDocInd + 1
      }
    }

    let temp: any = []
    let otherInd: any = 0
    for (let i = 0; i < otherOptions?.length; i++) {
      if (otherOptions[i]?.value?.trim().length > 0) {
        temp[otherInd] = otherOptions[i]?.value
        otherInd++
      }
    }

    let valuesBenefit = {...optionArry}
    if (optionArry?.none == false) valuesBenefit.other = temp

    setOptionArry(valuesBenefit)

    // Financial Summary
    let finance: any = []
    finance[0] = rentFin
    finance[1] = securityFin
    let finInd = 2

    for (let i = 0; i < otherFinancials?.length; i++) {
      finance[finInd] = otherFinancials[i]
      finInd++
    }

    // console.log(finance)

    let subIds: any = []
    let j: any = 0
    for (let i = 0; i < subTenantData?.length; i++) {
      if (subTenantData[i]?.id != '') {
        subIds[j] = subTenantData[i]?.id
        j++
      }
    }

    let validationCall: any = varificationCodeValidation()
    setImgError(validationCall.error)

    let benifit_optionArray: any = optionArry

    if (flg == '2' || flg == '3' || flg == '4') {
      delete valuesBenefit._id
      benifit_optionArray = optionArry
    }

    if (validationCall.isValid) {
      // Draft Request
      if (flg == '2') {
        const body = {
          unitId: unitData?._id ? unitData?._id : null,
          mainTenantId: newTenantId,
          subTenantIds: subIds,

          tenancy: {
            _id: tenancyId,
            isDraft: isDraft,
            contractNo: tenancyData?.contractNo,
            duration: {
              start_date: startDatePayload,
              end_date: endDatePayload,
              days: totalDays.split(' ')[0],
            },
            benefits: valuesBenefit,
            renewalDays: tenancyData?.renewalDays,
            tenantDocument: tentDoc,
            contractDocument: contractDoc,
            previousTenancyId: tenancyData?.previousTenancyId
              ? tenancyData?.previousTenancyId
              : undefined,
            tenantRequestId: tenancyData?.tenantRequestId,
            financialSummery: finance,
          },
          paymentList: isFinance ? payment : [],
          deletePaymentList: discardedPayments,
          // paymentList:
          //   propertiType == 'Financials' || propertiType == 'onboard' ? payment : undefined,
          // deletePaymentList:
          //   propertiType == 'Financials' || propertiType == 'onboard' ? deletedPayments : undefined,
        }
        ApiPost('corporate/tenancy/renewal_form/handler', body)
          .then((res) => {
            getDatesStatus(unitData?._id)
            setIsFormDirty(false)
            setIsLoading(false)
            setOpen(true)
            if (event == 'exit') navigate('/tenancies')
            else {
              if (flg != '2') SuccessToast('Lease has been saved as DRAFT')

              setTimeout(() => {
                refreshDocuments()
                // setIsSaveFirst(true)
                // getTenantData()
                getAllPayments()
                // getItemLevelSummary()
              }, 1000)
            }
          })
          .catch((err) => {
            setIsLoading(false)
            setOpen(false)
            ErrorToast(err.message)
          })
      } else if (flg == '4') {
        const body = {
          unitId: unitData?._id ? unitData?._id : null,
          mainTenantId: newTenantId,
          subTenantIds: subIds,
          tenancy: {
            previousTenancyId: tenancyId,
            tenantRequestId: renewReqId,
            isDraft: isDraft,
            contractNo: tenancyData?.contractNo,
            duration: {
              start_date: startDatePayload,
              end_date: endDatePayload,
              days: totalDays.split(' ')[0],
            },
            benefits: valuesBenefit,
            renewalDays: tenancyData?.renewalDays,
            tenantDocument: tentDoc,
            contractDocument: contractDoc,
            financialSummery: finance,
          },
          paymentList: payment,
          deletePaymentList: deletedPayments,
        }
        ApiPost('corporate/tenancy/renewal_form/handler', body)
          .then((res) => {
            getDatesStatus(unitData?._id)
            setIsFormDirty(false)
            setIsLoading(false)

            setOpen(true)
            if (event == 'exit') navigate('/tenancies')
            else {
              SuccessToast('Lease has been saved as DRAFT')
              navigate(`/renew-tenancy/2/${unitData?._id}/${res?.data?.data?._id}`)

              setTimeout(() => {
                setIsSaveFirst(true)
              }, 1000)
            }
          })
          .catch((err) => {
            setOpen(false)
            setIsLoading(false)
            ErrorToast(err.message)
          })
      }
    }
  }

  const onSubmitNewFlow = (data: any, event: any, isDraft: any) => {
    event.preventDefault()
    let renewReqId: any = localStorage.getItem('renewRequestId')

    setIsLoading(true)
    if (
      newTenantId &&
      start_date != null &&
      end_date != null &&
      tenancyData?.contractNo?.trim()?.length > 0 &&
      !renewalError
    ) {
      SuccessToast('No mandatory fields are pending..')
      // let payment: any = [...incomePaymentPayload]

      // let deletedPayments: any = []
      // let deleteInd: any = 0

      // let discardedPayments: any = [...deletedPayments]

      // if (isFinance) {
      //   for (let i = 0; i < incomePaymentPayload?.length; i++) {
      //     let newItems: any = []
      //     let newItemsInd: any = 0
      //     let updateItems: any = []
      //     let updateItemsInd: any = 0

      //     for (let j = 0; j < incomePaymentPayload[i]?.new_payment_sub_item?.length; j++) {
      //       payment[i].new_payment_sub_item[j] = {
      //         _id:
      //           payment[i]?.new_payment_sub_item[j]?._id?.trim()?.length == 0
      //             ? undefined
      //             : payment[i]?.new_payment_sub_item[j]?._id,
      //         VATAmount: payment[i]?.new_payment_sub_item[j].VATAmount,
      //         VATPercentage: payment[i]?.new_payment_sub_item[j].VATPercentage,
      //         amount: payment[i]?.new_payment_sub_item[j].amount,
      //         amountPaid: payment[i]?.new_payment_sub_item[j].amountPaid,
      //         isLatePaymentFeesRecord: payment[i]?.new_payment_sub_item[j].isLatePaymentFeesRecord,
      //         latePaymentFees: payment[i]?.new_payment_sub_item[j].latePaymentFees,
      //         latePaymentObject: payment[i]?.new_payment_sub_item[j].latePaymentObject,
      //         latePaymentSelectionStatus:
      //           payment[i]?.new_payment_sub_item[j].latePaymentSelectionStatus,
      //         name: payment[i]?.new_payment_sub_item[j].name,
      //         nextLatePaymentCycle: payment[i]?.new_payment_sub_item[j].nextLatePaymentCycle,
      //         oldLatePaymentCycleList: payment[i]?.new_payment_sub_item[j].oldLatePaymentCycleList,
      //         outstandingAmount: payment[i]?.new_payment_sub_item[j].outstandingAmount,
      //         paymentItemListId: payment[i]?.new_payment_sub_item[j].paymentItemListId,
      //         paymentSubItemStatus: payment[i]?.new_payment_sub_item[j].paymentSubItemStatus,
      //         quantity: payment[i]?.new_payment_sub_item[j].quantity,
      //         serviceJobId: payment[i]?.new_payment_sub_item[j].serviceJobId,
      //         serviceRequestId: payment[i]?.new_payment_sub_item[j].serviceRequestId,
      //         totalAmount: payment[i]?.new_payment_sub_item[j].totalAmount,
      //       }

      //       if (payment[i]?.new_payment_sub_item[j]?._id?.trim()?.length == undefined) {
      //         newItems[newItemsInd] = payment[i].new_payment_sub_item[j]
      //         newItemsInd++
      //       } else {
      //         updateItems[updateItemsInd] = payment[i].new_payment_sub_item[j]
      //         updateItemsInd++
      //       }
      //     }

      //     // Payment Invoice
      //     if (payment[i]?.payment?.invoiceId != undefined) {
      //       payment[i].update_payment_invoice = payment[i].new_payment_invoice
      //       // payment[i].update_payment_invoice[0]._id = payment[i]?.payment?.invoiceId

      //       payment[i].update_payment_invoice[0] = {
      //         _id: payment[i]?.payment?.invoiceId,
      //         // "id": 51,
      //         // "workspaceId": "ae7ef7b8-7fa6-4375-9f97-3705a14186a8",
      //         note: payment[i].new_payment_invoice?.[0]?.note,
      //         invoiceNo: payment[i].new_payment_invoice?.[0]?.invoiceNo,
      //         date: moment(new Date()).format('YYYY-MM-DD'),
      //         pdfURL: payment[i].new_payment_invoice?.[0]?.pdfURL,
      //         // invoiceLogo: "6582bac50d9a049c6882be03/logo/1703154826946.jpg",
      //         // "invoiceCompany": {
      //         //   "trn": "147852369",
      //         //   "name": "Muhammad",
      //         //   "address": "9XRH+3J7, Lund Khwar, Mardan, Khyber Pakhtunkhwa, Pakistan",
      //         //   "countryCode": "+92",
      //         //   "phoneNumber": "341-0521297",
      //         //   "email": "test@mailinator.com",
      //         //   "_id": "6584148bfe71fa5a07a69c8c"
      //         // },
      //         attachments: payment[i].new_payment_invoice?.[0]?.attachments,
      //         paymentSubItems: payment[i].new_payment_sub_item,
      //         paymentMethod: payment[i]?.payment?.paymentMethod,
      //         receiptAmount:
      //           payment[i].new_payment_receipt?.length > 0
      //             ? payment[i].new_payment_receipt?.[0]?.amountPaid
      //             : 0,
      //         totalRefundAmount: 0,
      //         refundVATAmount: 0,
      //         refundAmount: 0,
      //         totalAmount: payment[i]?.payment?.totalAmount,
      //         saveStatus: 1,
      //         creditAmount: 0,
      //         creditVATAmount: 0,
      //         totalCreditAmount: 0,
      //         debitAmount: 0,
      //         debitVATAmount: 0,
      //         totalDebitAmount: 0,
      //         totalVAT: payment[i]?.payment?.totalVAT,
      //         subTotalAmount: payment[i]?.payment?.subTotalAmount,
      //         isReceiptGenerated: payment[i].new_payment_receipt?.length > 0 ? true : false,
      //         isShareToTenant: payment[i].new_payment_invoice?.[0]?.isShareToTenant,
      //         isManualPDF: payment[i].new_payment_invoice?.[0]?.isManualPDF,
      //         // "isMainPDF": true,
      //         isActive: true,
      //         isLock: false,
      //         isBlock: false,
      //         isVoid: false,
      //         paymentAccountId: payment[i].new_payment_invoice?.[0]?.paymentAccountId,
      //         paymentId: payment[i]?.payment?._id,
      //         // "createdBy": "6582bac50d9a049c6882be03"
      //       }
      //       payment[i].new_payment_invoice = []
      //     }

      //     // Payment Receipt
      //     if (payment[i]?.payment?.receiptId != undefined) {
      //       if (payment[i].new_payment_receipt?.length == 0) {
      //         payment[i].delete_payment_receipt = [{_id: payment[i]?.payment?.receiptId}]
      //       } else {
      //         payment[i].update_payment_receipt = payment[i].new_payment_receipt
      //         payment[i].update_payment_receipt[0]._id = payment[i]?.payment?.receiptId
      //         payment[i].new_payment_receipt = []
      //       }
      //     }

      //     // Payment reminder
      //     let newReminder: any = []
      //     let updateReminder: any = []
      //     let newReminderInd: any = 0
      //     let updateReminderInd: any = 0

      //     if (payment[i]?.new_payment_reminder?.length > 0) {
      //       for (let j = 0; j < payment[i]?.new_payment_reminder?.length; j++) {
      //         if (
      //           payment[i]?.new_payment_reminder?.[j]?._id != undefined &&
      //           payment[i]?.new_payment_reminder?.[j]?._id?.trim()?.length > 0
      //         ) {
      //           updateReminder[updateReminderInd] = payment[i]?.new_payment_reminder?.[j]
      //           updateReminderInd++
      //         } else {
      //           newReminder[newReminderInd] = payment[i]?.new_payment_reminder?.[j]
      //           newReminder[newReminderInd]._id = undefined
      //           newReminderInd++
      //         }
      //       }
      //       payment[i].new_payment_reminder = newReminder
      //       payment[i].update_payment_reminder = updateReminder
      //     }

      //     payment[i].new_payment_sub_item = newItems
      //     payment[i].update_payment_sub_item = updateItems
      //     payment[i].payment.datePaid = undefined
      //     payment[i].isManipulated = undefined
      //     payment[i].payment.receiptId = undefined
      //     payment[i].payment.invoiceId = undefined
      //   }
      // } else {
      //   discardedPayments = []
      //   for (let i = 0; i < incomePaymentPayload?.length; i++) {
      //     discardedPayments[deleteInd] = incomePaymentPayload[i]?.payment?._id
      //   }
      // }

      // //  tenant Documents
      // let tentDoc: any = []
      // let tentDocInd: any = 0

      // for (let i = 0; i < tenantDocuments?.length; i++) {
      //   if (tenantDocuments[i]?.isCheck) {
      //     let files: any = []
      //     if (tenantDocuments[i]?.fileList?.length > 0) {
      //       let ind: any = 0
      //       for (let j = 0; j < tenantDocuments[i]?.fileList?.length; j++) {
      //         if (tenantDocuments[i]?.fileList[j].isChecked) {
      //           files[ind] = {
      //             fileName: tenantDocuments[i]?.fileList[j]?.fileName,
      //             fileURL: tenantDocuments[i]?.fileList[j]?.fileURL,
      //             _id: tenantDocuments[i]?.fileList[j]?._id,
      //           }
      //           ind = ind + 1
      //         }
      //       }
      //     }
      //     tentDoc[tentDocInd] = {
      //       _id: tenantDocuments[i]?._id,
      //       name: tenantDocuments[i]?.name,
      //       isSystemGenerated: tenantDocuments[i]?.isSystemGenerated,
      //       fileList: files,
      //       tenantId: newTenantId,
      //     }
      //     tentDocInd = tentDocInd + 1
      //   }
      // }

      // tentDocInd = tentDoc.length

      // for (let i = 0; i < tenantDeletedDocuments?.length; i++) {
      //   if (tenantDeletedDocuments[i]?.isCheck) {
      //     let files: any = []
      //     if (tenantDeletedDocuments[i]?.fileList?.length > 0) {
      //       let ind: any = 0
      //       for (let j = 0; j < tenantDeletedDocuments[i]?.fileList?.length; j++) {
      //         if (tenantDeletedDocuments[i]?.fileList[j].isChecked) {
      //           files[ind] = {
      //             fileName: tenantDeletedDocuments[i]?.fileList[j]?.fileName,
      //             fileURL: tenantDeletedDocuments[i]?.fileList[j]?.fileURL,
      //             _id: tenantDeletedDocuments[i]?.fileList[j]?._id,
      //           }
      //           ind = ind + 1
      //         }
      //       }
      //     }
      //     tentDoc[tentDocInd] = {
      //       _id: tenantDeletedDocuments[i]?._id,
      //       name: tenantDeletedDocuments[i]?.name,
      //       isSystemGenerated: tenantDeletedDocuments[i]?.isSystemGenerated,
      //       fileList: files,
      //       tenantId: newTenantId,
      //     }
      //     tentDocInd = tentDocInd + 1
      //   }
      // }

      // // contract Documents
      // let contractDoc: any = []
      // let contractDocInd: any = 0

      // for (let i = 0; i < contractDocuments?.length; i++) {
      //   if (contractDocuments[i]?.isCheck) {
      //     let files: any = []
      //     if (contractDocuments[i]?.fileList?.length > 0) {
      //       let ind: any = 0
      //       for (let j = 0; j < contractDocuments[i]?.fileList?.length; j++) {
      //         if (contractDocuments[i]?.fileList[j].isChecked) {
      //           files[ind] = {
      //             fileName: contractDocuments[i]?.fileList[j]?.fileName,
      //             fileURL: contractDocuments[i]?.fileList[j]?.fileURL,
      //             _id: contractDocuments[i]?.fileList[j]?._id,
      //           }
      //           ind = ind + 1
      //         }
      //       }
      //     }
      //     contractDoc[contractDocInd] = {
      //       _id: contractDocuments[i]?._id,
      //       name: contractDocuments[i]?.name,
      //       isSystemGenerated: contractDocuments[i]?.isSystemGenerated,
      //       fileList: files,
      //       tenantId: newTenantId,
      //     }
      //     contractDocInd = contractDocInd + 1
      //   }
      // }

      // contractDocInd = contractDoc.length

      // for (let i = 0; i < contractDeletedDocuments?.length; i++) {
      //   if (contractDeletedDocuments[i]?.isCheck) {
      //     let files: any = []
      //     if (contractDeletedDocuments[i]?.fileList?.length > 0) {
      //       let ind: any = 0
      //       for (let j = 0; j < contractDeletedDocuments[i]?.fileList?.length; j++) {
      //         if (contractDeletedDocuments[i]?.fileList[j].isChecked) {
      //           files[ind] = {
      //             fileName: contractDeletedDocuments[i]?.fileList[j]?.fileName,
      //             fileURL: contractDeletedDocuments[i]?.fileList[j]?.fileURL,
      //             _id: contractDeletedDocuments[i]?.fileList[j]?._id,
      //           }
      //           ind = ind + 1
      //         }
      //       }
      //     }
      //     contractDoc[contractDocInd] = {
      //       _id: contractDeletedDocuments[i]?._id,
      //       name: contractDeletedDocuments[i]?.name,
      //       isSystemGenerated: contractDeletedDocuments[i]?.isSystemGenerated,
      //       fileList: files,
      //       tenantId: newTenantId,
      //     }
      //     contractDocInd = contractDocInd + 1
      //   }
      // }

      // let temp: any = []
      // let otherInd: any = 0
      // for (let i = 0; i < otherOptions?.length; i++) {
      //   if (otherOptions[i]?.value?.trim().length > 0) {
      //     temp[otherInd] = otherOptions[i]?.value
      //     otherInd++
      //   }
      // }

      // let valuesBenefit = {...optionArry}
      // if (optionArry?.none == false) valuesBenefit.other = temp

      // setOptionArry(valuesBenefit)

      // // Financial Summary
      // let finance: any = []
      // finance[0] = rentFin
      // finance[1] = securityFin
      // let finInd = 2

      // for (let i = 0; i < otherFinancials?.length; i++) {
      //   finance[finInd] = otherFinancials[i]
      //   finInd++
      // }

      // // Sub tenants
      // let subIds: any = []
      // let j: any = 0
      // for (let i = 0; i < subTenantData?.length; i++) {
      //   if (subTenantData[i]?.id != '') {
      //     subIds[j] = subTenantData[i]?.id
      //     j++
      //   }
      // }

      // let validationCall: any = varificationCodeValidation()
      // setImgError(validationCall.error)

      // let benifit_optionArray: any = optionArry

      // if (flg == '2' || flg == '3' || flg == '4') {
      //   delete valuesBenefit._id
      //   benifit_optionArray = optionArry
      // }

      // if (validationCall.isValid) {
      //   // Draft Request
      //   if (flg == '2') {
      //     const body = {
      //       unitId: unitData?._id ? unitData?._id : null,
      //       mainTenantId: newTenantId,
      //       subTenantIds: subIds,
      //       tenancy: {
      //         _id: tenancyId,
      //         isDraft: isDraft,
      //         contractNo: tenancyData?.contractNo,
      //         duration: {
      //           start_date: startDatePayload,
      //           end_date: endDatePayload,
      //           days: totalDays.split(' ')[0],
      //         },
      //         benefits: valuesBenefit,
      //         renewalDays: tenancyData?.renewalDays,
      //         tenantDocument: tentDoc,
      //         contractDocument: contractDoc,
      //         previousTenancyId: tenancyData?.previousTenancyId
      //           ? tenancyData?.previousTenancyId
      //           : undefined,
      //         tenantRequestId: tenancyData?.tenantRequestId,
      //         financialSummery: finance,
      //       },
      //       paymentList: isFinance ? payment : [],
      //       deletePaymentList: discardedPayments,
      //     }
      //     ApiPost('corporate/tenancy/renewal_form/handler', body)
      //       .then((res) => {
      //         setIsFormDirty(false)
      //         setIsLoading(false)
      //         SuccessToast(res?.data?.message)
      //         setTimeout(() => {
      //           navigate(`/lease-request/${tenancyData?.tenantRequestId}/${id}/${tenancyId}/2`)

      //           // navigate(`/tenants`, {
      //           //   state: {
      //           //     requestId: flg == '2' ? tenancyData?.tenantRequestId : state?.tenantReqId,
      //           //   },
      //           // })
      //         }, 200)
      //       })
      //       .catch((err) => {
      //         setOpen(false)
      //         setIsLoading(false)
      //         ErrorToast(err.message)
      //       })
      //   } else if (flg == '4') {
      //     const body = {
      //       unitId: unitData?._id ? unitData?._id : null,
      //       mainTenantId: newTenantId,
      //       subTenantIds: subIds,
      //       tenancy: {
      //         previousTenancyId: tenancyId,
      //         tenantRequestId: renewReqId,
      //         isDraft: isDraft,
      //         contractNo: tenancyData?.contractNo,
      //         duration: {
      //           start_date: startDatePayload,
      //           end_date: endDatePayload,
      //           days: totalDays.split(' ')[0],
      //         },
      //         benefits: valuesBenefit,
      //         renewalDays: tenancyData?.renewalDays,
      //         tenantDocument: tentDoc,
      //         contractDocument: contractDoc,
      //         financialSummery: finance,
      //       },
      //       paymentList: payment,
      //       deletePaymentList: deletedPayments,
      //     }
      //     ApiPost('corporate/tenancy/renewal_form/handler', body)
      //       .then((res) => {
      //         setIsFormDirty(false)
      //         setIsLoading(false)

      //         SuccessToast(res?.data?.message)
      //         setTimeout(() => {
      //           navigate(`/lease-request/${state?.tenantReqId}/${id}/${tenancyId}/2`)
      //           // navigate(`/tenants`, {
      //           //   state: {
      //           //     requestId: state?.tenantReqId,
      //           //   },
      //           // })
      //         }, 200)
      //       })
      //       .catch((err) => {
      //         setOpen(false)
      //         setIsLoading(false)
      //         ErrorToast(err.message)
      //       })
      //   }
      // }
    } else {
      if (newTenantId?.trim()?.length == 0) {
        setSlectTenantError(true)
        setPropertiType('Tenants')
      } else if (
        tenancyData?.contractNo?.trim()?.length == 0 ||
        tenancyData?.contractNo == undefined ||
        start_date == null ||
        end_date == null
      ) {
        if (tenancyData?.contractNo?.trim()?.length == 0 || tenancyData?.contractNo == undefined) {
          setContractNoError(true)
          setPropertiType('Tenancy')
        }
        if (start_date == null || end_date == null) {
          setDateError(true)
          setPropertiType('Tenancy')
        }
      } else {
      }
    }
  }

  const getCountry = async () => {
    await ApiGetNoAuth('country')
      .then((res) => {
        setCountry(res?.data?.data)
      })
      .catch((e) => {
        console.log('e', e)
      })
  }

  const navigate = useNavigate()

  const [deletedPayments, setDeletedPayments] = useState<any>([])

  const getTenantData = () => {
    let id: any = localStorage.getItem('newTenancyID')
    if (flg === '2' || flg === '3') id = tenancyId
    if (flg == '3')
      ApiGet(`corporate/tenant?isNullFilter=true&isCloneRecord=true`)
        // ApiGet(`corporate/tenant/tenancy_record?tenancyId=${id}&isCloneRecord=true`)
        .then((res) => {
          setTenantData(res?.data?.data)
        })
        .catch((err) => console.log('err', err))
    // ApiGet(`corporate/tenant/tenancy_record?tenancyId=${id}`)
    else
      ApiGet(`corporate/tenant?isNullFilter=true`)
        .then((res) => {
          setTenantData(res?.data?.data)
        })
        .catch((err) => console.log('err', err))
  }
  const [changeid, setchangeid] = useState<any>(null)
  const [isUpdate, setIsUpdate] = useState<any>(false)
  const [subTenantId, setSubTenantId] = useState<any>('')
  const [editTenant, setEditTenant] = useState<any>(-1)
  const [tenantFolders, setTenantFolders] = useState<any>([])
  const [residencyFolder, setResidencyFolder] = useState<any>()
  const [passportFolder, setPassportFolder] = useState<any>()
  const [iDsFolder, setIDsFolder] = useState<any>()
  const [contractsFolder, setContractsFolder] = useState<any>()
  const [dataToSelectFrom, setDataToSelectFrom] = useState<any>()
  const [folderFileModal, setFolderFileModal] = useState<any>(false)
  const [fileType, setFileType] = useState<any>()
  const [showBck, setShowBck] = useState<any>()
  const [selectedFolder, setSelectedFolder] = useState<any>()
  const [selectedTenantName, setSelectedTenantName] = useState<any>()

  const updateTenantDropdown = async (id: any) => {
    setNewTenantID(id)

    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        setTenantIdForFolder(res?.data?.data?._id)
        setIsFormDirty(true)
        let temp = res?.data?.data?.firstName + res?.data?.data?.lastName
        setSelectedTenantName(temp)

        setFormData(res?.data?.data)

        let tentFold: any = []
        let t = 0

        let contFold: any = []
        let c = 0

        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          //  Documents Section
          //  Tenant Documents
          if (res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
            if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
              tentFold[t] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: true,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }
            } else {
              tentFold[t] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: false,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }
            }
            console.log(res?.data?.data?.tenant_folder?.[i]?.fileList?.length)

            for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
              if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                // tentFold[t].selected = true,
                tentFold[t].fileList[j] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                  fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                  fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                  isChecked: true,
                }
              } else {
                // tentFold[t].isCheck = false,
                tentFold[t].fileList[j] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                  fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                  fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                  isChecked: true,
                }
              }
            }
            t++
          }

          // Contract/Tenancy Documents
          if (
            res?.data?.data?.tenant_folder?.[i].name != 'Passports' &&
            res?.data?.data?.tenant_folder?.[i].name != 'Residencies' &&
            res?.data?.data?.tenant_folder?.[i].name != 'IDs'
          ) {
            if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
              contFold[c] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: true,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }
            } else {
              contFold[c] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: false,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }
            }
            console.log(res?.data?.data?.tenant_folder?.[i]?.fileList?.length)

            for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
              if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                // tentFold[t].selected = true,
                contFold[c].fileList[j] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                  fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                  fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                  isChecked: true,
                }
              } else {
                // tentFold[t].isCheck = false,
                contFold[c].fileList[j] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                  fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                  fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                  isChecked: true,
                }
              }
            }
            c++
          }
        }

        // console.log('Tenant Documents')
        // console.log(tentFold)
        // console.log('Contract Documents')
        // console.log(contFold)

        setTenantDocuments(tentFold)
        setContractDocuments(contFold)
      })
      .catch((err) => console.log('err', err))
  }

  const handleChnageTenant = async (e: any) => {
    let isSel: any = false

    for (let i = 0; i < subTenantIds?.length; i++) {
      if (e.value == subTenantIds?.[i]) {
        isSel = true
        i = subTenantIds?.length
      }
    }

    if (e.value == unitData?.tenancy?.tenantId) {
      // ErrorToast('This tenant is already occupying the same property , If you still want to continue, please either use the Renewal route or cancel the create tenancy action.')
      Swal.fire({
        text: 'This tenant is currently occupying the selected property, to create a new tenancy for the same tenant, please renew their existing tenancy.',
        icon: 'warning',
        showConfirmButton: true,
        confirmButtonColor: '#146c6a',
        confirmButtonText: 'Renew Tenancy',
        showCancelButton: true,
        cancelButtonText: 'Select Another Tenant',
        showDenyButton: false,
        denyButtonText: 'Cancel Tenancy',
        denyButtonColor: '#d546452',
      }).then((res: any) => {
        if (res.isConfirmed) {
          setNewTenantID('')
          setFormData([])
          setContractDocuments([])
          setTenantDocuments([])
          setSubTenantIds([])
          setSubTenantData([])

          setTenancyData([])
          setOtherOptions([])
          setStart(null)
          setEnd(null)
          setIsFormDirty(false)
          navigate(`/tenancy/draft/4/${unitData?._id}/${unitData?.tenancy?._id}`)
          setIncomePaymentPayload([])
          setTotalDays('0 Days')
          setRemainingDays('0 Days')
        } else if (res.isDenied) {
          if (flg == '1') {
            SuccessToast('Create tenancy action has been cancelled.')
            navigate(-1)
          } else {
            cancelTenancy()
          }
        } else {
        }
      })
    } else if (isSel) {
      ErrorToast('Tenant already Select as Sub-Tenant')
    } else {
      if (e.value == 'new') {
        // let newTenantId = localStorage.getItem('newTenantID')
        // setNewTenantID(newTenantId)
        setFormData([])
        setuploadresidencyTenant([])
        setuploadPassportTenant([])
        setuploadIdTenant([])
        setuploadOtherImgTenant([])
        setchangeid(null)

        // setTimeout(() => {
        //   getSubTenants(newTenantId)
        // }, 1500)
      } else {
        setSlectTenantError(false)
        setIsUpdate(true)
        setchangeid(e.value)
        setNewTenantID(e.value)
        if (e.value) {
          await ApiGet(`corporate/tenant/${e.value}`)
            .then((res) => {
              setTenantIdForFolder(res?.data?.data?._id)
              setIsFormDirty(true)
              let temp = res?.data?.data?.firstName + res?.data?.data?.lastName
              setSelectedTenantName(temp)

              setFormData(res?.data?.data)

              setuploadContractTenancy([])
              setuploadresidencyTenant([])
              setuploadPassportTenant([])
              setuploadIdTenant([])
              setuploadOtherImgTenant([])

              let tentFold: any = []
              let t = 0

              let contFold: any = []
              let c = 0

              for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
                // if (res?.data?.data?.tenant_folder?.[i].name == 'Passports') {
                //   setPassportFolder(res?.data?.data?.tenant_folder?.[i])
                // } else if (res?.data?.data?.tenant_folder?.[i].name == 'Contracts') {
                //   // contFold[c] = {
                //   //   data: res?.data?.data?.tenant_folder?.[i],
                //   //   isChecked: true,
                //   // }
                //   // c++
                //   setContractsFolder(res?.data?.data?.tenant_folder?.[i])
                // } else if (res?.data?.data?.tenant_folder?.[i].name == 'IDs') {
                //   setIDsFolder(res?.data?.data?.tenant_folder?.[i])
                // } else if (res?.data?.data?.tenant_folder?.[i].name == 'Residencies') {
                //   setResidencyFolder(res?.data?.data?.tenant_folder?.[i])
                // }

                //  Documents Section
                //  Tenant Documents
                if (res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
                  if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                    tentFold[t] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?._id,
                      name: res?.data?.data?.tenant_folder?.[i]?.name,
                      isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                      isCheck: true,
                      fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                    }
                  } else {
                    tentFold[t] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?._id,
                      name: res?.data?.data?.tenant_folder?.[i]?.name,
                      isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                      isCheck: false,
                      fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                    }
                  }
                  console.log(res?.data?.data?.tenant_folder?.[i]?.fileList?.length)

                  for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                    if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                      // tentFold[t].selected = true,
                      tentFold[t].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: true,
                      }
                    } else {
                      // tentFold[t].isCheck = false,
                      tentFold[t].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: true,
                      }
                    }
                  }
                  t++
                }

                // Contract/Tenancy Documents
                if (
                  res?.data?.data?.tenant_folder?.[i].name != 'Passports' &&
                  res?.data?.data?.tenant_folder?.[i].name != 'Residencies' &&
                  res?.data?.data?.tenant_folder?.[i].name != 'IDs'
                ) {
                  if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                    contFold[c] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?._id,
                      name: res?.data?.data?.tenant_folder?.[i]?.name,
                      isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                      isCheck: true,
                      fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                    }
                  } else {
                    contFold[c] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?._id,
                      name: res?.data?.data?.tenant_folder?.[i]?.name,
                      isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                      isCheck: false,
                      fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                    }
                  }
                  console.log(res?.data?.data?.tenant_folder?.[i]?.fileList?.length)

                  for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                    if (res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated == true) {
                      // tentFold[t].selected = true,
                      contFold[c].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: true,
                      }
                    } else {
                      // tentFold[t].isCheck = false,
                      contFold[c].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: true,
                      }
                    }
                  }
                  c++
                }
              }

              console.log('Tenant Documents')
              console.log(tentFold)
              console.log('Contract Documents')
              console.log(contFold)

              setTenantDocuments(tentFold)
              setContractDocuments(contFold)
            })
            .catch((err) => console.log('err', err))
        }
      }
    }
  }

  const handleSelectSubTenant = async (e: any, ind: any) => {
    if (e.value == newTenantId) {
      ErrorToast('Tenant already Selected as Main Tenant')
    } else {
      const temp = [...subTenantData]
      temp[ind].id = e.value
      // setSubTenantId(e.target.value)
      if (e.value == 'new') {
        // let id = localStorage.getItem('newSubId')
        // setSubTenantId(id)
        // let values = {
        //   firstName: '',
        //   lastName: '',
        //   phoneNumber: '',
        //   countryCode: '',
        //   email: '',
        // }
        // setSubTenant(values)
      } else {
        if (e.value) {
          await ApiGet(`corporate/tenant/${e.value}`)
            .then((res) => {
              setIsFormDirty(true)
              temp[ind] = {
                firstName: res?.data?.data?.firstName,
                lastName: res?.data?.data?.lastName,
                phoneNumber: res?.data?.data?.phoneNumber,
                countryCode: res?.data?.data?.countryCode,
                email: res?.data?.data?.email,
                passportNo: res?.data?.data?.passportNo,
                idNo: res?.data?.data?.idNo,
                id: res?.data?.data?._id,
              }
              setSubTenantData(temp)

              setSubTenantIds([...subTenantIds, res?.data?.data?._id])
              if (flg == '2') setOpen(true)
              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 700)
            })
            .catch((err) => console.log('err', err))
        }
      }
    }
  }

  const createTenant = () => {
    ApiPost('corporate/tenant')
      .then((res) => {
        localStorage.setItem('newTenantID', res?.data?.data?._id)
        setNewTenantID(res?.data?.data?._id)
      })
      .catch((err: any) => ErrorToast(err.message))
  }

  const getTenantDocuments = async (id: any, dataTenant: any, dataContract: any) => {
    if (flg != '4') {
      // setTenantDocuments([])
      await ApiGet(`corporate/tenant/${id}`)
        .then((res) => {
          setTenantIdForFolder(res?.data?.data?._id)

          let tentFold: any = []
          let t = 0

          let contFold: any = []
          let c = 0

          let deletedFolder: any = []
          let d: any = 0

          //  Documents Section
          for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
            let isInc: any = false
            let incTenant: any = false
            let ind: any = 0
            let indTen: any = 0
            let isDeleted = true

            for (let x = 0; x < dataTenant?.length; x++) {
              if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
                isDeleted = false
                x = dataTenant?.length
              }
            }

            if (!isDeleted) {
              //  Tenant Documents
              if (res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
                for (let x = 0; x < dataTenant?.length; x++) {
                  if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
                    incTenant = true
                    ind = x
                    x = dataTenant?.length
                  } else {
                    incTenant = false
                  }
                }

                if (incTenant) {
                  tentFold[t] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?._id,
                    name: res?.data?.data?.tenant_folder?.[i]?.name,
                    isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                    isCheck: true,
                    fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                  }

                  for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                    let isAvailable: any = false
                    let isAvailableTenant: any = false

                    for (let x = 0; x < dataTenant[ind]?.fileList?.length; x++) {
                      if (
                        res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                        dataTenant[ind]?.fileList?.[x]?._id
                      ) {
                        // console.log(res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id + ' ' + dataTenant[ind]?.fileList?.[x]?._id)
                        isAvailableTenant = true
                        x = dataTenant[ind]?.fileList?.length
                      } else {
                        isAvailableTenant = false
                      }
                    }

                    // console.log(isAvailableTenant)

                    if (isAvailableTenant) {
                      tentFold[t].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: true,
                      }
                    } else {
                      tentFold[t].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: false,
                      }
                    }
                  }
                  t++
                } else {
                  // console.log(res?.data?.data?.tenant_folder?.[i]?.name)
                  tentFold[t] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?._id,
                    name: res?.data?.data?.tenant_folder?.[i]?.name,
                    isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                    isCheck: false,
                    fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                  }

                  for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                    // tentFold[t].isCheck = false,
                    tentFold[t].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                  t++
                }
              }
            }
          }

          let temp: any = false
          let tempInd: any = 0
          for (let x = 0; x < dataTenant?.length; x++) {
            for (let y = 0; y < tentFold?.length; y++) {
              if (tentFold[y]._id == dataTenant[x]?._id) {
                // console.log(tentFold[y]._id + ' ' + dataTenant[x]?._id)
                temp = true
                // tempInd = x
                y = tentFold?.length
              } else {
                temp = false
                tempInd = x
              }
            }
            if (temp == false) {
              if (dataTenant?.[tempInd]?.name != 'Contracts') {
                deletedFolder[d] = {
                  _id: dataTenant?.[tempInd]?._id,
                  name: dataTenant?.[tempInd]?.name,
                  isSystemGenerated: dataTenant?.[tempInd]?.isSystemGenerated,
                  isCheck: true,
                  fileList: dataTenant?.[tempInd]?.fileList,
                }

                for (let j = 0; j < dataTenant?.[tempInd]?.fileList?.length; j++) {
                  deletedFolder[d].fileList[j] = {
                    _id: dataTenant?.[tempInd]?.fileList?.[j]?._id,
                    fileName: dataTenant?.[tempInd]?.fileList?.[j]?.fileName,
                    fileURL: dataTenant?.[tempInd]?.fileList?.[j]?.fileURL,
                    isChecked: true,
                  }
                }
              }
              d++
            }
          }

          // console.log(' Tenant Deleted Folder')
          // console.log(deletedFolder)

          // console.log('Tenant Documents')
          // console.log(tentFold)

          setTenantDocuments(tentFold)
          setTenantDeletedDocuments(deletedFolder)
        })
        .catch((err) => console.log('err', err))
    }
    // Exculde deleted folders in renewal
    else {
      await ApiGet(`corporate/tenant/${id}`)
        .then((res) => {
          setTenantIdForFolder(res?.data?.data?._id)

          let tentFold: any = []
          let t = 0

          let contFold: any = []
          let c = 0

          let deletedFolder: any = []
          let d: any = 0

          //  Documents Section
          for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
            let isInc: any = false
            let incTenant: any = false
            let ind: any = 0
            let indTen: any = 0
            let isDeleted = true

            for (let x = 0; x < dataTenant?.length; x++) {
              if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
                isDeleted = false
                x = dataTenant?.length
              }
            }

            if (!isDeleted) {
              //  Tenant Documents
              if (res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
                for (let x = 0; x < dataTenant?.length; x++) {
                  if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
                    incTenant = true
                    ind = x
                    x = dataTenant?.length
                  } else {
                    incTenant = false
                  }
                }

                if (incTenant) {
                  tentFold[t] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?._id,
                    name: res?.data?.data?.tenant_folder?.[i]?.name,
                    isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                    isCheck: true,
                    fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                  }

                  for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                    let isAvailable: any = false
                    let isAvailableTenant: any = false

                    for (let x = 0; x < dataTenant[ind]?.fileList?.length; x++) {
                      if (
                        res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                        dataTenant[ind]?.fileList?.[x]?._id
                      ) {
                        // console.log(res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id + ' ' + dataTenant[ind]?.fileList?.[x]?._id)
                        isAvailableTenant = true
                        x = dataTenant[ind]?.fileList?.length
                      } else {
                        isAvailableTenant = false
                      }
                    }

                    // console.log(isAvailableTenant)

                    if (isAvailableTenant) {
                      tentFold[t].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: true,
                      }
                    } else {
                      tentFold[t].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: false,
                      }
                    }
                  }
                  t++
                } else {
                  // console.log(res?.data?.data?.tenant_folder?.[i]?.name)
                  tentFold[t] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?._id,
                    name: res?.data?.data?.tenant_folder?.[i]?.name,
                    isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                    isCheck: false,
                    fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                  }

                  for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                    // tentFold[t].isCheck = false,
                    tentFold[t].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                  t++
                }
              }
            } else {
              if (res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
                deletedFolder[d] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?._id,
                  name: res?.data?.data?.tenant_folder?.[i]?.name,
                  isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                  isCheck: true,
                  fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                }

                for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                  deletedFolder[d].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: true,
                  }
                }
              }
              d++
            }
          }

          // console.log('deleted Folder')
          // console.log(deletedFolder)

          // console.log('Tenant Documents')
          // console.log(tentFold)

          setTenantDocuments(tentFold)
        })
        .catch((err) => console.log('err', err))
    }
  }

  const getContractDocuments = async (id: any, dataTenant: any, dataContract: any) => {
    if (flg != '4') {
      // setContractDocuments([])
      await ApiGet(`corporate/tenant/${id}`)
        .then((res) => {
          setTenantIdForFolder(res?.data?.data?._id)

          let tentFold: any = []
          let t = 0

          let contFold: any = []
          let c = 0

          let deletedFolder: any = []
          let d: any = 0

          //  Documents Section
          for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
            let isInc: any = false
            let incTenant: any = false
            let ind: any = 0
            let indTen: any = 0
            let isDeleted = true

            // Contract/Tenancy Documents
            // console.log(dataContract)
            // console.log(contractDocuments)
            for (let x = 0; x < dataContract?.length; x++) {
              if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
                isDeleted = false
                x = dataTenant?.length
              }
            }

            if (!isDeleted) {
              if (
                res?.data?.data?.tenant_folder?.[i].name != 'Passports' &&
                res?.data?.data?.tenant_folder?.[i].name != 'Residencies' &&
                res?.data?.data?.tenant_folder?.[i].name != 'IDs'
              ) {
                for (let x = 0; x < dataContract?.length; x++) {
                  if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
                    isInc = true
                    indTen = x
                    x = dataContract?.length
                  } else {
                    isInc = false
                  }
                }

                if (isInc) {
                  contFold[c] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?._id,
                    name: res?.data?.data?.tenant_folder?.[i]?.name,
                    isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                    isCheck: true,
                    fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                  }

                  for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                    let isAvailable: any = false

                    for (let x = 0; x < dataContract[indTen]?.fileList?.length; x++) {
                      if (
                        res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                        dataContract[indTen]?.fileList?.[x]?._id
                      ) {
                        isAvailable = true
                        x = dataContract[indTen]?.fileList?.length
                      } else {
                        isAvailable = false
                      }
                    }

                    if (isAvailable) {
                      contFold[c].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: true,
                      }
                    } else {
                      contFold[c].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: false,
                      }
                    }
                  }
                  c++
                } else {
                  contFold[c] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?._id,
                    name: res?.data?.data?.tenant_folder?.[i]?.name,
                    isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                    isCheck: false,
                    fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                  }

                  for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                    // tentFold[t].isCheck = false,
                    contFold[c].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                  c++
                }
              }
            }
          }

          let temp: any = false
          let tempInd: any = 0
          for (let x = 0; x < dataContract?.length; x++) {
            for (let y = 0; y < contFold?.length; y++) {
              if (contFold[y]._id == dataContract[x]?._id) {
                // console.log(contFold[y]._id + ' ' + dataContract[x]?._id)
                temp = true
                // tempInd = x
                y = contFold?.length
              } else {
                temp = false
                tempInd = x
              }
            }
            if (temp == false) {
              if (
                dataContract?.[tempInd]?.name != 'Passports' &&
                dataContract?.[tempInd]?.name != 'Residencies' &&
                dataContract?.[tempInd]?.name != 'IDs'
              ) {
                deletedFolder[d] = {
                  _id: dataContract?.[tempInd]?._id,
                  name: dataContract?.[tempInd]?.name,
                  isSystemGenerated: dataContract?.[tempInd]?.isSystemGenerated,
                  isCheck: true,
                  fileList: dataContract?.[tempInd]?.fileList,
                }

                for (let j = 0; j < dataContract?.[tempInd]?.fileList?.length; j++) {
                  deletedFolder[d].fileList[j] = {
                    _id: dataContract?.[tempInd]?.fileList?.[j]?._id,
                    fileName: dataContract?.[tempInd]?.fileList?.[j]?.fileName,
                    fileURL: dataContract?.[tempInd]?.fileList?.[j]?.fileURL,
                    isChecked: true,
                  }
                }
              }
              d++
            }
          }

          // console.log('Contract  Deleted Folder')
          // console.log(deletedFolder)

          // console.log('Contract Documents')
          // console.log(contFold)

          setContractDocuments(contFold)
          setContractDeletedDocuments(deletedFolder)
        })
        .catch((err) => console.log('err', err))
    } else {
      await ApiGet(`corporate/tenant/${id}`)
        .then((res) => {
          setTenantIdForFolder(res?.data?.data?._id)

          let tentFold: any = []
          let t = 0

          let contFold: any = []
          let c = 0

          //  Documents Section
          for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
            let isInc: any = false
            let incTenant: any = false
            let ind: any = 0
            let indTen: any = 0
            let isDeleted = true

            // Contract/Tenancy Documents
            // console.log(dataContract)
            // console.log(contractDocuments)
            for (let x = 0; x < dataContract?.length; x++) {
              if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
                isDeleted = false
                x = dataTenant?.length
              }
            }

            if (!isDeleted) {
              if (
                res?.data?.data?.tenant_folder?.[i].name != 'Passports' &&
                res?.data?.data?.tenant_folder?.[i].name != 'Residencies' &&
                res?.data?.data?.tenant_folder?.[i].name != 'IDs'
              ) {
                for (let x = 0; x < dataContract?.length; x++) {
                  if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
                    isInc = true
                    indTen = x
                    x = dataContract?.length
                  } else {
                    isInc = false
                  }
                }

                if (isInc) {
                  contFold[c] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?._id,
                    name: res?.data?.data?.tenant_folder?.[i]?.name,
                    isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                    isCheck: true,
                    fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                  }

                  for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                    let isAvailable: any = false

                    for (let x = 0; x < dataContract[indTen]?.fileList?.length; x++) {
                      if (
                        res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                        dataContract[indTen]?.fileList?.[x]?._id
                      ) {
                        isAvailable = true
                        x = dataContract[indTen]?.fileList?.length
                      } else {
                        isAvailable = false
                      }
                    }

                    if (isAvailable) {
                      contFold[c].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: true,
                      }
                    } else {
                      contFold[c].fileList[j] = {
                        _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                        fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                        fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                        isChecked: false,
                      }
                    }
                  }
                  c++
                } else {
                  contFold[c] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?._id,
                    name: res?.data?.data?.tenant_folder?.[i]?.name,
                    isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                    isCheck: false,
                    fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
                  }

                  for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                    // tentFold[t].isCheck = false,
                    contFold[c].fileList[j] = {
                      _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                      fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                      fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                      isChecked: false,
                    }
                  }
                  c++
                }
              }
            }
          }

          // console.log('Tenant Documents')
          // console.log(tentFold)
          // console.log('Contract Documents')
          // console.log(contFold)

          setContractDocuments(contFold)
        })
        .catch((err) => console.log('err', err))
    }
  }

  const getTenantById = async (id: any, dataTenant: any, dataContract: any) => {
    await ApiGet(`corporate/tenant/${id}`)
      .then((res) => {
        setTenantIdForFolder(res?.data?.data?._id)
        // setIsFormDirty(true)

        let tentFold: any = []
        let t = 0

        let contFold: any = []
        let c = 0

        // Tenant Documents
        for (let i = 0; i < res?.data?.data?.tenant_folder?.length; i++) {
          let isInc: any = false
          let ind: any = 0
          //  Documents Section
          //  Tenant Documents
          if (res?.data?.data?.tenant_folder?.[i].name != 'Contracts') {
            for (let x = 0; x < dataTenant?.length; x++) {
              if (res?.data?.data?.tenant_folder?.[i]._id == dataTenant[x]?._id) {
                isInc = true
                ind = x
                x = dataTenant?.length
              }
            }

            if (isInc) {
              tentFold[t] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: true,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }

              for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                let isAvailable: any = false

                for (let x = 0; x < dataTenant[ind]?.fileList?.length; x++) {
                  if (
                    res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                    dataTenant[ind]?.fileList?.[x]?._id
                  ) {
                    isAvailable = true

                    x = dataTenant[ind]?.fileList?.length
                  }
                }

                if (isAvailable) {
                  tentFold[t].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: true,
                  }
                } else {
                  tentFold[t].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: false,
                  }
                }
              }
              t++
            } else {
              tentFold[t] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: false,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }

              for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                // tentFold[t].isCheck = false,
                tentFold[t].fileList[j] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                  fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                  fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                  isChecked: false,
                }
              }
              t++
            }
          }
          // Contract/Tenancy Documents
          if (
            res?.data?.data?.tenant_folder?.[i].name != 'Passports' &&
            res?.data?.data?.tenant_folder?.[i].name != 'Residencies' &&
            res?.data?.data?.tenant_folder?.[i].name != 'IDs'
          ) {
            for (let x = 0; x < dataContract?.length; x++) {
              if (res?.data?.data?.tenant_folder?.[i]._id == dataContract[x]?._id) {
                isInc = true
                ind = x
                x = dataContract?.length
              }
            }

            if (isInc) {
              contFold[c] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: true,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }

              for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                let isAvailable: any = false

                for (let x = 0; x < dataContract[ind]?.fileList?.length; x++) {
                  if (
                    res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id ==
                    dataContract[ind]?.fileList?.[x]?._id
                  ) {
                    isAvailable = true

                    x = dataContract[ind]?.fileList?.length
                  }
                }

                if (isAvailable) {
                  contFold[c].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: true,
                  }
                } else {
                  contFold[c].fileList[j] = {
                    _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                    fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                    fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                    isChecked: false,
                  }
                }
              }
              c++
            } else {
              contFold[c] = {
                _id: res?.data?.data?.tenant_folder?.[i]?._id,
                name: res?.data?.data?.tenant_folder?.[i]?.name,
                isSystemGenerated: res?.data?.data?.tenant_folder?.[i]?.isSystemGenerated,
                isCheck: false,
                fileList: res?.data?.data?.tenant_folder?.[i]?.fileList,
              }

              for (let j = 0; j < res?.data?.data?.tenant_folder?.[i]?.fileList?.length; j++) {
                // tentFold[t].isCheck = false,
                contFold[c].fileList[j] = {
                  _id: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?._id,
                  fileName: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileName,
                  fileURL: res?.data?.data?.tenant_folder?.[i]?.fileList?.[j]?.fileURL,
                  isChecked: false,
                }
              }
              c++
            }
          }
        }

        // console.log('Tenant Documents')
        // console.log(tentFold)
        // console.log('Contract Documents')
        // console.log(contFold)

        setTenantDocuments(tentFold)

        setContractDocuments(contFold)
      })
      .catch((err) => console.log('err', err))
  }

  const refreshDocuments = () => {
    ApiGet(`corporate/tenancy/full_detail/${tenancyId}?isCloneRecord=true`)
      .then((res) => {
        if (res?.data?.data?.[0]?.tenant?.length > 0) {
          getTenantDocuments(
            res?.data?.data?.[0]?.tenant?.[0]?._id,
            res?.data?.data?.[0]?.tenantDocument,
            res?.data?.data?.[0]?.contractDocument
          )

          getContractDocuments(
            res?.data?.data?.[0]?.tenant?.[0]?._id,
            res?.data?.data?.[0]?.tenantDocument,
            res?.data?.data?.[0]?.contractDocument
          )
        }
      })

      .catch((err) => console.log('err', err))
  }

  const getTenancyData = (id: any) => {
    if (flg == '4') {
      ApiGet(`corporate/tenancy/full_detail/${id}`)
        .then((res) => {
          // console.log('\nres.data.data', res.data.data)

          if (res?.data?.data?.[0]?.tenant?.length > 0) {
            setNewTenantID(res?.data?.data?.[0]?.tenant?.[0]?._id)
            // console.log(res?.data?.data?.[0]?.tenant?.length)
            setFormData(res?.data?.data?.[0]?.tenant?.[0])
          }

          setNewTenancyID(res.data.data?.[0]?._id)
          setTenancyData(res?.data?.data[0])
          //   setTenancyPayment(res?.data?.data[0].tenancy_payment)

          // SubTenant Data
          let values: any = []
          for (let i = 0; i < res?.data?.data?.[0].sub_tenant?.length; i++) {
            values[i] = {
              id: res?.data?.data?.[0].sub_tenant?.[i]?._id,
              firstName: res?.data?.data?.[0].sub_tenant?.[i]?.firstName,
              lastName: res?.data?.data?.[0].sub_tenant?.[i]?.lastName,
              email: res?.data?.data?.[0].sub_tenant?.[i]?.email,
              countryCode: `${res?.data?.data?.[0].sub_tenant?.[i]?.countryCode}`,
              phoneNumber: `${res?.data?.data?.[0].sub_tenant?.[i]?.phoneNumber}`,
              passportNo: `${res?.data?.data?.[0].sub_tenant?.[i]?.passportNo}`,
              idNo: `${res?.data?.data?.[0].sub_tenant?.[i]?.idNo}`,
            }
          }
          setSubTenantData(values)

          //   if (res?.data?.data[0]?.duration?.start_date != null) {
          //     setStart(moment(res?.data?.data[0]?.duration?.start_date).format('DD/MM/YYYY'))
          //     setStartDatePayload(
          //       moment(res?.data?.data[0]?.duration?.start_date).format('YYYY-MM-DD')
          //     )
          //   }

          //   if (res?.data?.data[0]?.duration?.end_date != null) {
          //     if (state?.terminationDate) {
          //       setEnd(moment(new Date(state?.terminationDate)).format('DD/MM/YYYY'))
          //       setEndDatePayload(moment(new Date(state?.terminationDate)).format('YYYY-MM-DD'))
          //     } else {
          //       setEnd(moment(res?.data?.data[0]?.duration?.end_date).format('DD/MM/YYYY'))
          //       setEndDatePayload(moment(res?.data?.data[0]?.duration?.end_date).format('YYYY-MM-DD'))
          //     }
          //   }

          //   setTotalDays(res?.data?.data[0]?.duration?.days + ' days')
          //   setRemainingDays(res?.data?.data[0]?.remainingDays + ' days')

          setNewTenantID(res?.data?.data?.[0]?.tenantId)

          let final_obj: any = {
            none: false,
            maintenanceFree: false,
            chillerFree: false,
            other: [],
            ...res?.data?.data[0]?.benefits,
          }
          setOptionArry(final_obj)

          let temp: any = []
          for (let i = 0; i < res?.data?.data[0]?.benefits?.other?.length; i++) {
            temp[i] = {
              value: res?.data?.data[0]?.benefits?.other?.[i],
            }
          }
          setOtherOptions(temp)

          getTenantDocuments(
            res?.data?.data?.[0]?.tenant?.[0]?._id,
            res?.data?.data?.[0]?.tenantDocument,
            res?.data?.data?.[0]?.contractDocument
          )

          getContractDocuments(
            res?.data?.data?.[0]?.tenant?.[0]?._id,
            res?.data?.data?.[0]?.tenantDocument,
            res?.data?.data?.[0]?.contractDocument
          )

          setTimeout(() => {
            setIsSkeleton(false)
          }, 300)
        })

        .catch((err) => console.log('err', err))
    } else {
      ApiGet(`corporate/tenancy/full_detail/${id}?isCloneRecord=true`)
        .then((res) => {
          // console.log('\nres.data.data', res.data.data)

          if (res?.data?.data?.[0]?.tenant?.length > 0) {
            setNewTenantID(res?.data?.data?.[0]?.tenant?.[0]?._id)
            // console.log(res?.data?.data?.[0]?.tenant?.length)
            setFormData(res?.data?.data?.[0]?.tenant?.[0])
          }

          setNewTenancyID(res.data.data?.[0]?._id)
          setTenancyData(res?.data?.data[0])
          //   setTenancyPayment(res?.data?.data[0].tenancy_payment)

          // SubTenant Data
          let values: any = []
          for (let i = 0; i < res?.data?.data?.[0].sub_tenant?.length; i++) {
            values[i] = {
              id: res?.data?.data?.[0].sub_tenant?.[i]?._id,
              firstName: res?.data?.data?.[0].sub_tenant?.[i]?.firstName,
              lastName: res?.data?.data?.[0].sub_tenant?.[i]?.lastName,
              email: res?.data?.data?.[0].sub_tenant?.[i]?.email,
              countryCode: `${res?.data?.data?.[0].sub_tenant?.[i]?.countryCode}`,
              phoneNumber: `${res?.data?.data?.[0].sub_tenant?.[i]?.phoneNumber}`,
              passportNo: `${res?.data?.data?.[0].sub_tenant?.[i]?.passportNo}`,
              idNo: `${res?.data?.data?.[0].sub_tenant?.[i]?.idNo}`,
            }
          }
          setSubTenantData(values)

          if (res?.data?.data[0]?.duration?.start_date != null) {
            setStart(moment(res?.data?.data[0]?.duration?.start_date))
            setStartDatePayload(
              moment(res?.data?.data[0]?.duration?.start_date).format('YYYY-MM-DD')
            )
          }

          if (res?.data?.data[0]?.duration?.end_date != null) {
            if (state?.terminationDate) {
              setEnd(moment(new Date(state?.terminationDate)))
              setEndDatePayload(moment(new Date(state?.terminationDate)).format('YYYY-MM-DD'))
            } else {
              setEnd(moment(res?.data?.data[0]?.duration?.end_date))
              setEndDatePayload(moment(res?.data?.data[0]?.duration?.end_date).format('YYYY-MM-DD'))
            }
          }

          setTotalDays(res?.data?.data[0]?.duration?.days + ' days')
          setRemainingDays(res?.data?.data[0]?.remainingDays + ' days')

          setNewTenantID(res?.data?.data?.[0]?.tenantId)

          let final_obj: any = {
            none: false,
            maintenanceFree: false,
            chillerFree: false,
            other: [],
            ...res?.data?.data[0]?.benefits,
          }
          setOptionArry(final_obj)

          let temp: any = []
          for (let i = 0; i < res?.data?.data[0]?.benefits?.other?.length; i++) {
            temp[i] = {
              value: res?.data?.data[0]?.benefits?.other?.[i],
            }
          }
          setOtherOptions(temp)

          getTenantDocuments(
            res?.data?.data?.[0]?.tenant?.[0]?._id,
            res?.data?.data?.[0]?.tenantDocument,
            res?.data?.data?.[0]?.contractDocument
          )

          getContractDocuments(
            res?.data?.data?.[0]?.tenant?.[0]?._id,
            res?.data?.data?.[0]?.tenantDocument,
            res?.data?.data?.[0]?.contractDocument
          )

          let finance: any = res?.data?.data?.[0]?.financialSummery
          let otherInd: any = 0
          let otherValues: any = []
          for (let i = 0; i < finance?.length; i++) {
            if (finance[i]?.name == 'Rent') setRentFin(finance[i])
            else if (finance[i]?.name == 'Security Deposit') setSecurityFin(finance[i])
            else {
              otherValues[otherInd] = finance[i]
              otherInd++
            }
          }
          setOtherFinancials(otherValues)

          setTimeout(() => {
            setIsSkeleton(false)
          }, 300)
        })

        .catch((err) => console.log('err', err))
    }
  }

  useEffect(() => {
    if (flg == '2' || flg == '3' || flg == '4') {
      setIsSkeleton(true)
      setSendError(false)

      setRentAmountError(false)
      if (flg != '4') getAllPayments()

      setTimeout(() => {
        getTenancyData(tenancyId)
      }, 1000)
    }
    getDatesStatus(id)
    getTenantData()

    getUbitDataById()

    getCountry()
  }, [])

  React.useEffect(() => {
    reset(formData)
  }, [formData])

  const getImage: any = () => {
    if (unitData?.images?.length > 0 && unitData?.images[0].length > 0) {
      return <img src={`${Bucket}${unitData?.images[0]}`} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'town_house') {
      return <img src={townhouse} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'other') {
      return <img src={other} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'common_area') {
      return <img src={common_area} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'villa') {
      return <img src={villa} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'apartment') {
      return <img src={appartment} alt='' width={170} height={150} />
    } else if (unitData?.unitType === 'penthouse') {
      return <img src={penthouse} alt='' width={170} height={150} />
    }
  }

  const total: any = tenancyPayment?.reduce((acc: any, obj: any) => {
    return acc + obj.amount
  }, 0)

  const [from, setFrom] = useState<any>()
  const [subInd, setSubInd] = useState<any>()

  const [disabledBtn, setDisabledBtn] = useState<any>(true)
  const [incomeModal, setIncomeModal] = useState<any>(false)
  const [tenantDetails, setTenantDetails] = useState<any>()
  const [incomePaymentPayload, setIncomePaymentPayload] = useState<any>([])

  const [filteredPaymentItems, setFilteredPaymentItems] = useState<any>([
    {value: '0', label: 'Booking'},
    {value: '1', label: 'Maintenance'},
    {value: '2', label: 'Rent'},
  ])

  const [show, setShow] = useState<any>([])

  const [financialItems, setFinancialItems] = useState<any>([])
  const [filteredFinancialItems, setFilteredFinancialItems] = useState<any>([])

  const getPaymentItemsList = () => {
    console.log(unitData)
    if (unitData?.communityId !== undefined && unitData?.communityId !== null) {
      ApiGet(`corporate/payment_item_list/payment?communityId=` + unitData.communityId)
        .then((res) => {
          setFilteredPaymentItems(res?.data?.data)
          let values: any = []
          let x = 0
          for (let i = 0; i < res?.data?.data?.length; i++) {
            if (
              res?.data?.data?.[i]?.name != 'Rent' &&
              res?.data?.data?.[i]?.name != 'Security Deposit'
            ) {
              values[x] = res?.data?.data?.[i]
              x++
            } else {
              if (res?.data?.data?.[i]?.name == 'Rent') {
                let values = {...rentFin}
                values.paymentItemListId = res?.data?.data?.[i]?._id
                setRentFin(values)
              }
              if (res?.data?.data?.[i]?.name == 'Security Deposit') {
                let values = {...securityFin}
                values.paymentItemListId = res?.data?.data?.[i]?._id
                setSecurityFin(values)
              }
            }
          }
          setFinancialItems(values)
          setFilteredFinancialItems(values)
        })
        .catch((err) => console.log('err', err))
    } else if (unitData?.buildingId !== undefined && unitData?.buildingId !== null) {
      ApiGet(`corporate/payment_item_list/payment?buildingId=` + unitData.buildingId)
        .then((res) => {
          setFilteredPaymentItems(res?.data?.data)
          let values: any = []
          let x = 0
          for (let i = 0; i < res?.data?.data?.length; i++) {
            if (
              res?.data?.data?.[i]?.name != 'Rent' &&
              res?.data?.data?.[i]?.name != 'Security Deposit'
            ) {
              values[x] = res?.data?.data?.[i]
              x++
            } else {
              if (res?.data?.data?.[i]?.name == 'Rent') {
                let values = {...rentFin}
                values.paymentItemListId = res?.data?.data?.[i]?._id
                setRentFin(values)
              }
              if (res?.data?.data?.[i]?.name == 'Security Deposit') {
                let values = {...securityFin}
                values.paymentItemListId = res?.data?.data?.[i]?._id
                setSecurityFin(values)
              }
            }
          }
          setFinancialItems(values)
          setFilteredFinancialItems(values)
        })
        .catch((err) => console.log('err', err))
    }
  }

  const updateIncomePaymentPayload = (val: any, from: any, ind: any) => {
    const values = [...incomePaymentPayload]
    if (from == 'add') values[values.length] = val
    else if (from == 'edit') {
      values[ind] = val
      values[ind].payment.receiptId = prevRecId
      values[ind].payment.invoiceId = prevInvId
    } else {
      values.splice(ind, 1)
      setEditIncomeModalDraft(false)
      if (val != undefined) {
        if (deletedPayments?.length == 0) setDeletedPayments([val])
        else {
          let temp = [...deletedPayments]
          temp[temp?.length] = val
          setDeletedPayments(temp)
        }
      }
    }

    setIncomePaymentPayload(values)

    setIsFormDirty(true)

    for (let i = 0; i < values.length; i++) {
      let values = [...show]
      values[i] = false
      setShow(values)
    }
    if (flg == '2') setOpen(true)
    setTimeout(() => {
      setAutoSaveData(Date.now())
    }, 800)
  }

  const tableRef = useRef<HTMLTableElement | null>(null)

  const getPositive = (number: any) => {
    // if number is less than zero multiply with -1, otherwise returns as it is
    return number < 0 ? number * -1 : number
  }

  const handleMouseWheel = (e: React.WheelEvent) => {
    if (tableRef.current) {
      if (isMouseOverTable(e, tableRef.current)) {
        e.preventDefault()
        tableRef.current.scrollLeft += e.deltaY
      }
    }
  }

  const isMouseOverTable = (e: React.WheelEvent, element: HTMLElement) => {
    // document.body.style.overflow = 'hidden';
    const rect = element.getBoundingClientRect()
    const mouseX = e.clientX - rect.left
    const mouseY = e.clientY - rect.top
    return mouseX >= 0 && mouseX <= rect.width && mouseY >= 0 && mouseY <= rect.height
  }

  const disableScrolling = () => {
    document.body.style.overflow = 'hidden'
  }

  const enableScrolling = () => {
    document.body.style.overflow = '' // Reset to default
  }

  const handleMouseEnter = (event: any) => {
    disableScrolling()
  }

  const handleMouseLeave = () => {
    enableScrolling()
  }

  const autoSaveTenancy = () => {
    // console.log(uploadContractTenancy?.[0])
    let benifit_optionArray: any = optionArry

    if (flg == '2' || flg == '3') {
      delete optionArry._id
      benifit_optionArray = optionArry
      optionArryPayload = optionArry
    }

    const body = {
      id: flg == '1' ? newTenancyID : tenancyId,
      contractNo: tenancyData?.contractNo,
      duration: {
        start_date: start_date,
        end_date: end_date,
        days: totalDays?.split(' ')[0],
        _id: newTenancyData?.duration?._id,
      },
      document: {
        contract:
          uploadContractTenancy?.[0] === null
            ? null
            : uploadContractTenancy?.length > 0
            ? removeBucket(uploadContractTenancy[0])
            : uploadContractTenancy?.length === 0
            ? null
            : null,
        other: uploadOtherImgTenancy?.length > 0 ? removeBucket(uploadOtherImgTenancy) : [],
        tenantPassport:
          uploadPassportTenant?.length > 0 ? removeBucket(uploadPassportTenant[0]) : null,
        tenantResidency:
          uploadresidencyTenant?.length > 0 ? removeBucket(uploadresidencyTenant[0]) : null,
        tenantId: uploadIdTenant?.length > 0 ? removeBucket(uploadIdTenant[0]) : null,
        tenantOther: uploadOtherImgTenant?.length > 0 ? removeBucket(uploadOtherImgTenant) : null,
        _id: newTenancyData?.document?._id,
      },
      benefits: optionArryPayload,
      isDraft: true,
      tenancyStatus: tenancyData?.tenancyStatus != null ? parseInt(tenancyData?.tenancyStatus) : 0,
      unitId: unitData?._id,
      communityId: unitData?.communityId,
      tenantId: flg == '3' ? tenancyData?.tenant?.[0]?._id : newTenantId,
      buildingId: unitData?.buildingId,
      tenantSource: formData?.tenantSource != null ? parseInt(formData?.tenantSource) : 0,
      subTenantIds: subTenantIds,
    }

    // if (flg == '3')
    //   ApiPut('corporate/tenancy?isCloneRecord=true', body)
    //     .then((res) => {})
    //     .catch((err) => ErrorToast(err.message))
    // else
    //   ApiPut('corporate/tenancy', body)
    //     .then((res) => {})
    //     .catch((err) => ErrorToast(err.message))
  }

  const deleteTenant = (id: any, type: any) => {
    ApiDelete(`corporate/tenant/${id}`)
      .then((response) => {
        if (type == 0) setCreate(false)
        else {
          SuccessToast(response?.data?.message)
          getSubTenants(newTenantId)
        }
        getTenantData()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  //
  const updateSubTenant = (subTen: any) => {
    setNewTenantID(subTen?.tenantId)
    const body = {
      id: subTen?._id,
      firstName: subTen?.firstName ? subTen?.firstName : null,
      lastName: subTen?.lastName ? subTen?.lastName : null,
      phoneNumber: subTen?.phoneNumber?.toString() ? subTen?.phoneNumber?.toString() : null,
      countryCode: subTen?.countryCode?.toString() ? subTen?.countryCode?.toString() : null,
      email: subTen?.email ? subTen?.email : null,
      tenantType: subTen?.tenantType,
      tenantId: subTen?.tenantId,
    }

    if (flg == '3')
      ApiPut('corporate/tenant?isCloneRecord=true', body)
        .then((res) => {
          setTimeout(() => {
            getSubTenants(subTen?.tenantId)
            setEditTenant(-1)
          }, 1000)
        })
        .catch((err) => ErrorToast(err.message))
    else
      ApiPut('corporate/tenant', body)
        .then((res) => {
          setTimeout(() => {
            getSubTenants(subTen?.tenantId)
            setEditTenant(-1)
          }, 1000)
        })
        .catch((err) => ErrorToast(err.message))
  }

  //
  const getSubTenants = (id: any) => {
    let body: any

    if (flg == '1') {
      let newId = localStorage.getItem('newTenancyID')
      body = {
        page: page,
        limit: 10,
        mainTenantId: '',
        tenancyId: newId,
      }
    } else {
      body = {
        page: page,
        limit: 10,
        mainTenantId: '',
        tenancyId: tenancyId,
      }
    }

    if (flg == '4')
      ApiPost('corporate/tenant/sub_tenant/get', body)
        .then((res) => {
          let values: any = []
          for (let i = 0; i < subTenantData?.length; i++) {
            values[i] = {
              id: res?.data?.data?.tenant_data?.[i]?._id,
              firstName: res?.data?.data?.tenant_data?.[i]?.firstName,
              lastName: res?.data?.data?.tenant_data?.[i]?.lastName,
              email: res?.data?.data?.tenant_data?.[i]?.email,
              mobileNo: res?.data?.data?.tenant_data?.[i]?.mobileNo,
              passportNo: res?.data?.data?.tenant_data?.[i]?.passportNo,
              idNo: res?.data?.data?.tenant_data?.[i]?.idNo
            }
          }
          setSubTenantData(values)
        })
        .catch((err) => ErrorToast(err.message))
    else
      ApiPost('corporate/tenant/sub_tenant/get?isCloneRecord=true', body)
        .then((res) => {
          let values: any = []
          for (let i = 0; i < subTenantData?.length; i++) {
            values[i] = {
              id: res?.data?.data?.tenant_data?.[i]?._id,
              firstName: res?.data?.data?.tenant_data?.[i]?.firstName,
              lastName: res?.data?.data?.tenant_data?.[i]?.lastName,
              email: res?.data?.data?.tenant_data?.[i]?.email,
              mobileNo: res?.data?.data?.tenant_data?.[i]?.mobileNo,
              passportNo: res?.data?.data?.tenant_data?.[i]?.passportNo,
              idNo: res?.data?.data?.tenant_data?.[i]?.idNo
            }
          }
          setSubTenantData(values)
        })
        .catch((err) => ErrorToast(err.message))
  }

  const cancelTenancy = () => {
    ApiGet(`corporate/tenancy/cancel/${tenancyId}`)
      .then((response: any) => {
        SuccessToast(response?.data?.message)
        navigate(-1)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }

  // useEffect(() => {
  //   if(flg != '1')
  //   getAllPayments()
  // }, [page])

  const [isSaveFirst, setIsSaveFirst] = useState<any>(false)

  useEffect(() => {
    if (isSaveFirst == true) {
      // if (flg == '2' || flg == '3') {
      setSendError(false)
      getTenancyData(tenancyId)
      // }
      // getTenantData()
      // getSubTenants(tenancyId)

      ApiGet(`corporate/unit/${id}`)
        .then((res) => {
          setUnitData(res?.data?.data)
          setSendError(false)
          setDisabledBtn(false)
        })
        .catch((err) => console.log('err', err))
      // getItemLevelSummary()
      getAllPayments()

      setIsSaveFirst(false)

      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }, [isSaveFirst])

  // useEffect(() => {
  //   const handleBeforeUnload = (e: any) => {
  //     if (isFormDirty) {
  //       e.preventDefault()
  //       e.returnValue = 'You have unsaved changes. Are you sure you want to leave this page'
  //     }
  //   }

  //   window.addEventListener('beforeunload', handleBeforeUnload)

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload)
  //   }
  // }, [isFormDirty])

  const disabledDate = (current: any) => {
    // Disable dates before today
    return current ? current < moment().subtract(1, 'day') : false
  }

  useEffect(() => {
    getPaymentItemsList()
  }, [unitData])

  useEffect(() => {
    let values: any = [...otherFinancials]

    // validation for item selection
    const temp: any = []
    let k: any = 0

    // console.log(filteredFinancialItems)

    for (let i = 0; i < financialItems?.length; i++) {
      let isTrue = true
      let ind: any = ''
      for (let j = 0; j < values?.length; j++) {
        // console.log(filteredFinancialItems[i].name)
        // console.log(values[j].name)
        if (financialItems[i].name == values[j].name) {
          isTrue = false
          ind = i
        }
      }
      if (isTrue) {
        temp[k] = financialItems[i]
        k++
      }
    }

    setFilteredFinancialItems(temp)
  }, [financialItems])

  const getItemLevelSummary = () => {
    const body = {
      tenancyId: tenancyId,
    }

    ApiPost(`corporate/payment/item_level/information`, body)
      .then((res) => {
        // let values = [...incomePaymentPayload]
        // console.log("Draft test")
        // console.log(values)

        // setIncomePaymentPayload(values)

        setIsDraft(true)
      })
      .catch(() => {})
  }

  useEffect(() => {
    if (flg == '2') {
      getItemLevelSummary()
    }
  }, [])

  // Financial Summary linking Errors
  useEffect(() => {
    // if (flg == '1') {
    let income: any = [...incomePaymentPayload]
    // console.log(income)
    let totalAmountFinanceRent = 0

    let rentError: any = false
    let securityError: any = false
    let otherError: any = false

    /*************************** Total Amount Error ****************/
    //  Rent Amount
    for (let i = 0; i < income?.length; i++) {
      for (let j = 0; j < income[i]?.new_payment_sub_item?.length; j++) {
        if (income[i]?.new_payment_sub_item[j]?.name == 'Rent') {
          totalAmountFinanceRent =
            totalAmountFinanceRent +
            income[i]?.new_payment_sub_item[j]?.amount *
              income[i]?.new_payment_sub_item[j]?.quantity
          if (totalAmountFinanceRent != rentFin?.totalAmount) {
            rentError = true
            setRentAmountError(true)
          } else {
            rentError = false
            setRentAmountError(false)
          }
        }
      }
    }

    // console.log('totalAmountFinanceRent ' + totalAmountFinanceRent)

    //  Security Deposit Amount
    // if (!rentError) {
    let totalAmountFinanceSecurity = 0
    for (let i = 0; i < income?.length; i++) {
      for (let j = 0; j < income[i]?.new_payment_sub_item?.length; j++) {
        if (income[i]?.new_payment_sub_item[j]?.name == 'Security Deposit') {
          totalAmountFinanceSecurity =
            totalAmountFinanceSecurity +
            income[i]?.new_payment_sub_item[j]?.amount *
              income[i]?.new_payment_sub_item[j]?.quantity
          if (totalAmountFinanceSecurity != securityFin?.totalAmount) {
            securityError = true
            setSecurityAmountError(true)
          } else {
            securityError = false
            setSecurityAmountError(false)
          }
        }
      }
    }
    // }

    // Other Financials Amount
    // if (!rentError && !securityError) {
    let totalAmountFinanceOther = 0
    {
      otherFinancials?.length > 0 &&
        otherFinancials?.map((othFin: any, index: any) => {
          return (
            <>
              {incomePaymentPayload?.length > 0 &&
                incomePaymentPayload?.map((pm: any, index: any) => {
                  return (
                    <>
                      {pm?.new_payment_sub_item?.map((pmIt: any, ind: any) => {
                        if (pmIt?.name == othFin?.name) {
                          totalAmountFinanceOther =
                            totalAmountFinanceOther + pmIt?.amount * pmIt?.quantity
                          if (totalAmountFinanceOther != othFin?.totalAmount) {
                            setOtherItem({
                              name: othFin?.name,
                            })
                            otherError = true
                            setOtherAmountError(true)
                          } else {
                            otherError = false
                            setOtherAmountError(false)
                          }
                        }
                        return <></>
                      })}
                    </>
                  )
                })}
            </>
          )
        })
    }
    // }

    /************************* No of Payments  *********************/
    let rentCount: any = 0
    let securityCount: any = 0
    let otherCount: any = 0
    // let income: any = [...incomePaymentPayload]

    //  rent
    for (let i = 0; i < income?.length; i++) {
      for (let j = 0; j < income[i]?.new_payment_sub_item?.length; j++) {
        if (income[i]?.new_payment_sub_item[j]?.name == 'Rent') {
          rentCount = rentCount + 1
          j = income[i]?.new_payment_sub_item?.length
        }
      }
    }

    if (rentCount != rentFin?.noOfPayment) setRentNoOfPaymentsError(true)
    else setRentNoOfPaymentsError(false)

    // security deposits
    for (let i = 0; i < income?.length; i++) {
      for (let j = 0; j < income[i]?.new_payment_sub_item?.length; j++) {
        if (income[i]?.new_payment_sub_item[j]?.name == 'Security Deposit') {
          securityCount = securityCount + 1
          j = income[i]?.new_payment_sub_item?.length
        }
      }
    }

    if (securityCount != securityFin?.noOfPayment) setSecurityNoOfPaymentsError(true)
    else setSecurityNoOfPaymentsError(false)

    // Other Financials
    for (let i = 0; i < otherFinancials?.length; i++) {
      for (let j = 0; j < income?.length; j++) {
        for (let k = 0; k < income[j]?.new_payment_sub_item?.length; k++) {
          if (income[j]?.new_payment_sub_item[k]?.name == otherFinancials[i]?.name) {
            otherCount = otherCount + 1
            k = income[j]?.new_payment_sub_item?.length
          }
        }
      }
      if (otherCount != otherFinancials[i]?.noOfPayment) {
        setOtherNoOfPaymentsError(true)
        setOtherItem({
          name: otherFinancials[i]?.name,
        })
        i = otherFinancials?.length
      } else setOtherNoOfPaymentsError(false)
    }

    // console.log('Rent Count' + rentCount)
    // console.log('Security Deposit ' + securityCount)

    if (otherFinancials?.length == 0) {
      setOtherAmountError(false)
      setOtherNoOfPaymentsError(false)
    }
    // }
  }, [rentFin, securityFin, otherFinancials, isDraft, incomePaymentPayload])

  const [autoSaveData, setAutoSaveData] = useState<any>(false)

  useEffect(() => {
    if (autoSaveData != false && flg == '2') onSaveNewFlow(formData, '', true)
  }, [autoSaveData])

  const [datesToCompare, setDatesToCompare] = useState<any>()

  //
  const getDatesStatus = (value: any) => {
    ApiGet(`corporate/tenancy/get_dates/unit/${value}`)
      .then((res) => {
        setDatesToCompare(res?.data?.data)
        setTimeout(() => {
          setOpen(false)
        }, 1000)
      })
      .catch((err) => console.log('err', err))
  }

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null
    endDate: moment.Moment | null
  }) => {
    // console.log(startDate)
    // console.log(endDate)

    // console.log(startDate?.format('YYYY-MM-DD'))
    // console.log(endDate?.format('YYYY-MM-DD'))

    if (startDate == null && endDate == null) {
      setStart(null)
      setStartDatePayload(null)
      setEnd(null)
      setEndDatePayload(null)

      setTotalDays(0 + ' days')
      setTtlDays(0)
      setRemainingDays(0 + ' days')
    } else {
      setStart(startDate)
      setStartDatePayload(startDate?.format('YYYY-MM-DD'))
      setEnd(endDate)
      setEndDatePayload(endDate?.format('YYYY-MM-DD'))

      var start = moment(startDate)
      var end = moment(endDate)

      let today = moment(new Date())
      setTotalDays(Math.floor(end.diff(start, 'days', true)) + ' days')
      setTtlDays(Math.floor(end.diff(start, 'days', true)))
      setRemainingDays(Math.floor(end.diff(today, 'days', true)) + ' days')

      if (Math.floor(end.diff(start, 'days', true)) < tenancyData?.renewalDays)
        setRenewalError(true)
      else setRenewalError(false)

      setIsFormDirty(true)
      if (startDatePayload && endDatePayload) {
      }
    }
  }

  const dateStatuses = calculateDateStatuses(datesToCompare)

  const getWorkFlowListByName = () => {
    ApiGet(`corporate/sub_workflow_list/get?name=Lease Renewals`)
      .then((res: any) => {
        // setLeaseWithApproval(res?.data?.data?.isApprovalEnabled)
        // setLeaseWithFinancials(res?.data?.data?.isFinancialIncluded)
        localStorage.setItem('leaseRenewalsWithFinancials', res?.data?.data?.isFinancialIncluded)

        isFinance = res?.data?.data?.isFinancialIncluded
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    // getWorkFlowListByName()
  }, [])

  const financialsRecord: any = localStorage.getItem('isFinanceSubmitted')
  let isFinanceSubmitted = JSON?.parse(financialsRecord)


  
  const handleImageClick = (val: any, valSrc: any, type: any,docs:any,docType:any) => {
    const groupName = `group_${type}`

    if(docType === 'TenantDocs' || docType ===  'ContactDocs' || docType ===  'Onboard' || docType === 'ContactDeletedDocs' || docType === 'OnboardTenantDeletedDoc' || docType === 'TenantDeletedDocs'
      || docType === 'OnboardContactDocs'  || docType === 'OnboardContactDeletedDocs'
    ){
      docs?.map((doc: any, index: any) => {
        const imgeNext = document.getElementById(`clicked${type}${index}`)
        if (imgeNext) {
          imgeNext.setAttribute('data-fancybox', groupName)
          imgeNext.setAttribute('data-src', `${Bucket}${doc?.fileURL}`)
          // imgeNext.setAttribute('src', `${Bucket}${receipt?.URL}`)
        }
      })

    }
    
  }

  
  useEffect(() => {
    fancybox.bind('[data-fancybox]', {
      Toolbar: {
        display: {
          left: ['infobar'],
          middle: ['zoomIn', 'zoomOut', 'toggle1to1', 'rotateCCW', 'rotateCW', 'flipX', 'flipY'],
          right: ['slideshow', 'thumbs', 'close'],
        },
      },
    })

    // return () => {
    //   fancybox.destroy();
    // };
  }, []);

  return (
    <>
      {!isSkeleton ? (
        <div className='app-main flex-column flex-row-fluid px-2' id='kt_app_main'>
          <form onSubmit={handleSubmit((data: any, e: any) => onSubmitNewFlow(formData, e, false))}>
            <div id='' className='pt-0 mt-0'>
              {/* Header */}
              <div className='page-title d-flex flex-column justify-content-center flex-wrap'>
                <div className='d-flex flex-stack pt-1 mt-0 px-0'>
                  <div className=''>
                    <div className='d-flex align-items-center mb-1'>
                      <span
                        className='rounded-circle'
                        style={{cursor: 'pointer'}}
                        // onClick={() => {
                        //   if (
                        //     isFormDirty
                        //     // && !window.confirm('Changes that you made may not be saved ?')
                        //   ) {
                        //     Swal.fire({
                        //       text: 'You are about to leave this page. Do you wish to save your updates?',
                        //       icon: 'warning',
                        //       showConfirmButton: true,
                        //       confirmButtonColor: '#146c6a',
                        //       confirmButtonText: 'Yes',
                        //       showCancelButton: false,
                        //       cancelButtonText: 'Cancel',
                        //       showDenyButton: true,
                        //       denyButtonText: 'No',
                        //       denyButtonColor: '#d546452',
                        //     }).then((res: any) => {
                        //       if (res.isConfirmed) {
                        //         onSaveNewFlow(formData, 'exit', true)
                        //       } else if (res.isDenied) {
                        //         navigate(`/tenants`, {
                        //           state: {
                        //             requestId:
                        //               flg == '2' ? tenancyData?.tenantRequestId : state?.tenantReqId,
                        //           },
                        //         })
                        //       } else {
                        //       }
                        //     })
                        //   } else
                        //     navigate(`/tenants`, {
                        //       state: {
                        //         requestId:
                        //           flg == '2' ? tenancyData?.tenantRequestId : state?.tenantReqId,
                        //       },
                        //     })
                        // }}
                        onClick={() => {
                          // navigate(`/tenants`, {
                          //   state: {
                          //     requestId:
                          //       flg == '2' ? tenancyData?.tenantRequestId : state?.tenantReqId,
                          //   },
                          // })

                          if (flg == '2')
                            navigate(
                              `/lease-request/${tenancyData?.tenantRequestId}/${id}/${tenancyId}/2`
                            )
                          else navigate(`/lease-request/${state?.tenantReqId}/${id}/${tenancyId}/2`)
                        }}
                      >
                        <img src={backArrow} height='14' width='14' />
                      </span>
                      <h2 className='m-0 head-text ms-2'>
                        {flg == '1'
                          ? 'Create Tenancy'
                          : flg == '2'
                          ? 'Renewal Offer'
                          : 'Renewal Offer'}
                      </h2>
                    </div>
                  </div>

                  <div className=' d-flex'>
                    {flg == '2' && (
                      <>
                        {/* <button
                          type='button'
                          className='btn btn-sm fw-bold  ms-3 red-hollow-btn'
                          onClick={() => cancelTenancy()}
                          disabled={isLoading || isViewMode}
                        >
                          <img src={trashImg} height={20} width={20} style={{marginRight: '7px'}} />{' '}
                          Delete
                        </button> */}
                      </>
                    )}

                    {flg != '2' && (
                      <button
                        type='button'
                        className='btn btn-sm fw-bold ms-3 green-hollow-btn'
                        onClick={(e) => {
                          onSaveNewFlow(formData, e, true)
                        }}
                        disabled={isLoading || sendError || isViewMode}
                      >
                        <img
                          src={saveGreenImg}
                          height={20}
                          width={20}
                          style={{marginRight: '13px'}}
                        />{' '}
                        {'  '}Save
                      </button>
                    )}

                    {/* <button
                      type='button'
                      className='btn btn-sm fw-bold  ms-3  green-submit-btn'
                      onClick={(e: any) => {
                        onSubmitNewFlow(formData, e, false)
                      }}
                      disabled={
                        ((rentAmountError ||
                          securityAmountError ||
                          otherAmountError ||
                          rentNoOfPaymentsError ||
                          securityNoOfPaymentsError ||
                          otherNoOfPaymentsError) &&
                          isFinance) ||
                        isLoading ||
                        isViewMode
                      }
                    >
                      <img src={submitImg} height={20} width={20} style={{marginRight: '7px'}} />{' '}
                      {'  '}
                     Validate
                    </button> */}
                  </div>
                </div>
              </div>

              <div className='card card-flush py-5 mt-5'>
                <div
                  className='row mx-1'
                  style={propertiType != 'Financials' ? {height: '100px'} : {}}
                >
                  {/* Tabs */}
                  <div className='col-7 px-5'>
                    <div className='d-flex row ms-3'>
                      {/* Tenants */}
                      <div className='col-3 px-0'>
                        <div className='d-flex align-items-center'>
                          <div
                            className={
                              propertiType == 'Tenants'
                                ? 'tb-bg-active  d-flex align-items-center justify-content-center cursor-pointer'
                                : 'tb-bg d-flex align-items-center justify-content-center cursor-pointer'
                            }
                            onClick={() => setPropertiType('Tenants')}
                          >
                            <img
                              src={profileWhite}
                              height={24}
                              width={24}
                              style={{marginLeft: '3px'}}
                            />
                          </div>

                          <div className='connector'></div>
                        </div>
                      </div>

                      {/* Contract */}
                      <div className='col-3 px-0'>
                        <div className='d-flex align-items-center'>
                          <div
                            className={
                              propertiType == 'Tenancy'
                                ? 'tb-bg-active  d-flex align-items-center justify-content-center cursor-pointer'
                                : 'tb-bg d-flex align-items-center justify-content-center cursor-pointer'
                            }
                            onClick={() => {
                              if (sendError) setHide(false)
                              setPropertiType('Tenancy')
                            }}
                          >
                            <img src={file} height={24} width={20} />
                          </div>

                          <div className='connector'></div>
                        </div>
                      </div>

                      {/* Financials */}
                      {isFinance && (
                        <div className='col-3 px-0'>
                          <div
                            className='d-flex align-items-center'
                            style={
                              rentFin?.totalAmount > 0 &&
                              rentFin?.noOfPayment > 0 &&
                              securityFin?.totalAmount > 0 &&
                              securityFin?.noOfPayment > 0
                                ? {opacity: '1', cursor: 'pointer'}
                                : {opacity: '0.4'}
                            }
                          >
                            <div
                              className={
                                propertiType == 'Financials'
                                  ? 'tb-bg-active  d-flex align-items-center justify-content-center cursor-pointer'
                                  : 'tb-bg d-flex align-items-center justify-content-center cursor-pointer'
                              }
                              onClick={() => {
                                if (
                                  rentFin?.totalAmount > 0 &&
                                  rentFin?.noOfPayment > 0 &&
                                  securityFin?.totalAmount > 0 &&
                                  securityFin?.noOfPayment > 0
                                ) {
                                  if (!sendError) setHide(true)
                                  else setHide(false)

                                  // getPaymentItemsList()
                                  setPropertiType('Financials')
                                }
                              }}
                            >
                              <img src={financialsIcon} height={32} width={30} />
                            </div>

                            <div className='connector'></div>
                          </div>
                        </div>
                      )}

                      {/* Tenant Onboarding */}
                      <div className='col-3 px-0'>
                        <div className='d-flex align-items-center'>
                          <div
                            className={
                              propertiType == 'onboard'
                                ? 'tb-bg-active  d-flex align-items-center justify-content-center cursor-pointer'
                                : 'tb-bg d-flex align-items-center justify-content-center cursor-pointer'
                            }
                            onClick={() => {
                              setHide(true)
                              setPropertiType('onboard')
                            }}
                          >
                            <img src={onboardTick} height={24} width={24} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex row mt-2 ms-2'>
                      {/* Tenants */}
                      <div className='px-0 ps-1 col-3 '>
                        <h4
                          className={
                            propertiType == 'Tenants'
                              ? 'mt-1 tb-active cursor-pointer'
                              : 'head-text mt-1 cursor-pointer'
                          }
                          onClick={() => setPropertiType('Tenants')}
                        >
                          Tenants
                        </h4>
                      </div>

                      {/* Contract */}
                      <div className='px-0  col-3'>
                        <h4
                          className={
                            propertiType == 'Tenancy'
                              ? 'mt-1 tb-active cursor-pointer'
                              : 'head-text mt-1 cursor-pointer'
                          }
                          onClick={() => setPropertiType('Tenancy')}
                        >
                          Contract
                        </h4>
                      </div>

                      {/* Financials */}
                      {isFinance && (
                        <div className=' px-0  col-2'>
                          <h4
                            className={
                              propertiType == 'Financials' ? 'mt-1 tb-active' : 'head-text mt-1'
                            }
                            style={
                              rentFin?.totalAmount > 0 &&
                              rentFin?.noOfPayment > 0 &&
                              securityFin?.totalAmount > 0 &&
                              securityFin?.noOfPayment > 0
                                ? {opacity: '1', cursor: 'pointer'}
                                : {opacity: '0.4'}
                            }
                            onClick={() => {
                              if (
                                rentFin?.totalAmount > 0 &&
                                rentFin?.noOfPayment > 0 &&
                                securityFin?.totalAmount > 0 &&
                                securityFin?.noOfPayment > 0
                              ) {
                                if (!sendError) setHide(true)
                                else setHide(false)

                                // getPaymentItemsList()
                                setPropertiType('Financials')
                              }
                            }}
                          >
                            Financials
                          </h4>
                        </div>
                      )}

                      {/* Tenant Onboarding */}
                      <div className='mp-5  px-5 col-3'>
                        <h4
                          className={
                            propertiType == 'onboard'
                              ? 'mt-1 tb-active cursor-pointer'
                              : 'head-text mt-1 cursor-pointer'
                          }
                          onClick={() => {
                            setHide(true)
                            setPropertiType('onboard')
                          }}
                          style={!isFinance ? {marginLeft: '-30px'} : {}}
                        >
                          Tenant Onboarding
                        </h4>
                      </div>
                    </div>

                    {propertiType == 'Financials' && (
                      <>
                        <div
                          className='row mx-1 mt-5'
                          style={sendError ? {opacity: '0.4'} : {opacity: '1'}}
                        >
                          <div className='col-7 mt-5'>
                            <h2 className='top ps-0'>
                              <b>Financials</b>
                            </h2>
                          </div>
                        </div>

                        <div className='row mx-1 mt-3'>
                          <div className='col-7 px-3'>
                            <button
                              type='button'
                              className='btn btn-sm fw-bold green-submit-btn'
                              onClick={() => {
                                let values = {
                                  firstName: formData?.firstName,
                                  lastName: formData?.lastName,
                                  email: formData?.email,
                                  countryCode: formData?.countryCode,
                                  phoneNumber: formData?.phoneNumber,
                                }
                                setTenantDetails(values)
                                setIncomeModal(true)
                              }}
                              style={{width: '200px'}}
                              disabled={sendError || isViewMode}
                            >
                              <img
                                src={addWhite}
                                height={16}
                                width={16}
                                style={{marginRight: '10px'}}
                              />{' '}
                              Add Incoming Payment
                            </button>
                          </div>
                        </div>

                        {rentAmountError && (
                          <div className='row mx-1 mt-3'>
                            <div className='col-12 px-3'>
                              <p
                                style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                className='m-0'
                              >
                                The value of the payments added do not reflect the total values of
                                the items set (Rent).
                              </p>
                            </div>
                          </div>
                        )}

                        {!rentAmountError && securityAmountError && (
                          <div className='row mx-1 mt-3'>
                            <div className='col-12 px-3'>
                              <p
                                style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                className='m-0'
                              >
                                The value of the payments added do not reflect the total values of
                                the items set (Security Deposit).
                              </p>
                            </div>
                          </div>
                        )}

                        {!rentAmountError && !securityAmountError && otherAmountError && (
                          <div className='row mx-1 mt-3'>
                            <div className='col-12 px-3'>
                              <p
                                style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                className='m-0'
                              >
                                The value of the payments added do not reflect the total values of
                                the items set ({otherItem?.name}).
                              </p>
                            </div>
                          </div>
                        )}

                        {!rentAmountError &&
                          !securityAmountError &&
                          !otherAmountError &&
                          rentNoOfPaymentsError && (
                            <div className='row mx-1 mt-3'>
                              <div className='col-12 px-3'>
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0'
                                >
                                  The total no. of payments added do not reflect the total no. of
                                  payments set (Rent).
                                </p>
                              </div>
                            </div>
                          )}

                        {!rentAmountError &&
                          !securityAmountError &&
                          !otherAmountError &&
                          !rentNoOfPaymentsError &&
                          securityNoOfPaymentsError && (
                            <div className='row mx-1 mt-3'>
                              <div className='col-12 px-3'>
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0'
                                >
                                  The total no. of payments added do not reflect the total no. of
                                  payments set (Security Deposit).
                                </p>
                              </div>
                            </div>
                          )}

                        {!rentAmountError &&
                          !securityAmountError &&
                          !otherAmountError &&
                          !rentNoOfPaymentsError &&
                          !securityNoOfPaymentsError &&
                          otherNoOfPaymentsError && (
                            <div className='row mx-1 mt-3'>
                              <div className='col-12 px-3'>
                                <p
                                  style={{color: 'var(--kt-danger)', gridColumn: '2/2'}}
                                  className='m-0'
                                >
                                  The total no. of payments added do not reflect the total no. of
                                  payments set ({otherItem?.name}).
                                </p>
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                  {/* Property header */}
                  {propertiType !== 'onboard' && (
                    <div className='col-5'>
                      <div
                        className={
                          hide
                            ? 'card card-flush py-5 container-xxl mt-2  min-res pe-5'
                            : 'card card-flush py-5 container-xxl mt-2 min-card pe-5'
                        }
                        style={{position: 'relative', zIndex: '10'}}
                      >
                        <div className='d-flex  align-items-center'>
                          <h2 className='ms-3' style={{fontWeight: '700'}}>
                            Property
                          </h2>

                          <div className='ms-auto' style={{width: '143px'}}>
                            {tenancyData?.isDraft ? (
                              <>
                                <div
                                  className={
                                    flg == '4'
                                      ? 'status draft-st'
                                      : tenancyData?.isDraft
                                      ? 'status draft-st '
                                      : 'status create-st'
                                  }
                                >
                                  {tenancyData?.isDraft ? 'Draft' : 'Created'}
                                </div>
                                <i>
                                  {' '}
                                  {tenancyData?.draftStatusDateTime
                                    ? `${moment(tenancyData?.draftStatusDateTime).format(
                                        'DD.MM.YYYY - HH:mm A'
                                      )} `
                                    : ''}
                                </i>
                              </>
                            ) : (
                              <div className='status not-saved'>-</div>
                            )}
                          </div>
                          {/* <div className='ms-auto' style={{width: '143px'}}>
                          <div
                            className={
                              flg == '4'
                                ? 'status  no-st-bar '
                                : tenancyData?.tenancyStatus == 0
                                ? 'status booked-bar '
                                : tenancyData?.tenancyStatus == 1
                                ? 'status active-bar '
                                : tenancyData?.tenancyStatus == 2
                                ? 'status expiring-bar '
                                : tenancyData?.tenancyStatus == 3
                                ? 'status expired-bar '
                                : 'status  no-st-bar '
                            }
                          >
                            {flg == '4'
                              ? '-'
                              : tenancyData?.tenancyStatus == 0
                              ? 'Booked'
                              : tenancyData?.tenancyStatus == 1
                              ? 'Active'
                              : tenancyData?.tenancyStatus == 2
                              ? 'Expiring'
                              : tenancyData?.tenancyStatus == 3
                              ? 'Expired'
                              : '-'}
                          </div>
                          <i>
                            {' '}
                            {tenancyData?.tenancyStatusDateTime
                              ? `${moment(tenancyData?.tenancyStatusDateTime).format(
                                  'DD.MM.YYYY - HH:mm A'
                                )} `
                              : ''}
                          </i>
                        </div> */}
                        </div>
                        {/* <div className='d-flex mt-3 align-items-center'>
                        {(flg != '2' && flg == '4') && (
                          <button
                            type='button'
                            className='btn btn-sm fw-bold ms-3 ps-4 text-white select-btn'
                            onClick={(event) => {
                              setSelectModal(true)
                            }}
                          >
                            <img
                              src={homeImg}
                              height={20}
                              width={20}
                              style={{marginRight: '7px'}}
                            />{' '}
                            {'  '}
                            Select
                          </button>
                        )}
                        <div className='ms-auto' style={{width: '143px'}}>
                          {tenancyData?.isDraft ? (
                            <>
                              <div
                                className={
                                  flg == '4'
                                    ? 'status draft-st'
                                    : tenancyData?.isDraft
                                    ? 'status draft-st '
                                    : 'status create-st'
                                }
                              >
                                {tenancyData?.isDraft ? 'Draft' : 'Created'}
                              </div>
                              <i>
                                {' '}
                                {tenancyData?.draftStatusDateTime
                                  ? `${moment(tenancyData?.draftStatusDateTime).format(
                                      'DD.MM.YYYY - HH:mm A'
                                    )} `
                                  : ''}
                              </i>
                            </>
                          ) : (
                            <div className='status not-saved'>-</div>
                          )}
                        </div>
                      </div> */}

                        {unitData && (
                          <>
                            {!sendError && (
                              <>
                                <div className='row d-flex me-5 mb-5 mt-5 px-0 ms-0'>
                                  {unitData?.communityId && (
                                    <div className='col-12 px-4'>
                                      <h4 className='my-2' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Development{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.communityId
                                              ? unitData?.community?.[0]?.name
                                              : '-'}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}
                                  {unitData?.communityId == null && unitData?.buildingId && (
                                    <div className='col-12 px-4'>
                                      <h4 className='my-2' style={{color: 'black'}}>
                                        <label
                                          style={{minWidth: '100px'}}
                                          className='labl-gry me-3'
                                        >
                                          {' '}
                                          Development{' '}
                                        </label>
                                        <span className='ms-5 ps-1'>
                                          <b>
                                            {unitData?.buildingId
                                              ? unitData?.building?.[0]?.name
                                              : '-'}
                                          </b>
                                        </span>
                                      </h4>
                                    </div>
                                  )}

                                  <div className='col-12 px-4'>
                                    <h4 className='my-2' style={{color: 'black'}}>
                                      <label style={{minWidth: '100px'}} className='labl-gry me-3'>
                                        {' '}
                                        Unit No{' '}
                                      </label>
                                      <span className='ms-5 ps-1'>
                                        <b>{unitData?.unitNo ? unitData?.unitNo : '-'}</b>
                                      </span>
                                    </h4>
                                  </div>

                                  {hide !== true && (
                                    <>
                                      {unitData?.clusterId && (
                                        <div className='col-12 px-4'>
                                          <h4 className='my-2' style={{color: 'black'}}>
                                            <label
                                              style={{minWidth: '100px'}}
                                              className='labl-gry me-3'
                                            >
                                              {' '}
                                              Cluster{' '}
                                            </label>
                                            <span className='ms-5 ps-1'>
                                              <b>
                                                {unitData?.clusterId
                                                  ? unitData?.cluster?.[0]?.name
                                                  : unitData?.building?.[0]?.name}
                                              </b>
                                            </span>
                                          </h4>
                                        </div>
                                      )}

                                      {unitData?.unitGroupId && (
                                        <div className='col-12 px-4'>
                                          <h4 className='my-2' style={{color: 'black'}}>
                                            <label
                                              style={{minWidth: '100px'}}
                                              className='labl-gry me-3'
                                            >
                                              {' '}
                                              Unit No{' '}
                                            </label>
                                            <span className='ms-5 ps-1'>
                                              <b>
                                                {unitData?.unitGroupId
                                                  ? unitData?.unit_group?.[0]?.name
                                                  : ''}
                                              </b>
                                            </span>
                                          </h4>
                                        </div>
                                      )}

                                      {unitData?.floorId && (
                                        <div className='col-12 px-4'>
                                          <h4 className='my-2' style={{color: 'black'}}>
                                            <label
                                              style={{minWidth: '100px'}}
                                              className='labl-gry me-3'
                                            >
                                              {' '}
                                              Floor{' '}
                                            </label>
                                            <span className='ms-5 ps-1'>
                                              <b>
                                                {unitData?.floorId
                                                  ? unitData?.floor?.[0]?.name
                                                  : ''}
                                              </b>
                                            </span>
                                          </h4>
                                        </div>
                                      )}

                                      <div className='col-12 px-4'>
                                        <h4 className='my-2' style={{color: 'black'}}>
                                          <label
                                            style={{minWidth: '100px'}}
                                            className='labl-gry me-3'
                                          >
                                            {' '}
                                            Type{' '}
                                          </label>
                                          <span className='ms-5 ps-0'>
                                            <b>
                                              {' '}
                                              {unitData?.unitType === 'town_house'
                                                ? 'TownHouse'
                                                : unitData?.unitType === 'other'
                                                ? 'Other'
                                                : unitData?.unitType === 'common_area'
                                                ? 'Common Area'
                                                : unitData?.unitType === 'villa'
                                                ? 'Villa'
                                                : unitData?.unitType === 'apartment'
                                                ? 'Apartment'
                                                : unitData?.unitType === 'penthouse'
                                                ? 'Penthouse'
                                                : '-'}
                                            </b>
                                          </span>
                                        </h4>
                                      </div>

                                      <div className='col-12 px-4'>
                                        <h4 className='my-2' style={{color: 'black'}}>
                                          <label
                                            style={{minWidth: '100px'}}
                                            className='labl-gry me-3'
                                          >
                                            {' '}
                                            Bedroom{' '}
                                          </label>
                                          <span className='ms-5 ps-1'>
                                            <b>{unitData?.bedrooms ? unitData?.bedrooms : '-'}</b>
                                          </span>
                                        </h4>
                                      </div>

                                      <div className='col-12 px-4'>
                                        <h4 className='my-2' style={{color: 'black'}}>
                                          <label
                                            style={{minWidth: '100px'}}
                                            className='labl-gry me-3'
                                          >
                                            {' '}
                                            Size{' '}
                                          </label>
                                          <span className='ms-5 ps-1'>
                                            <b>{unitData?.size ? `${unitData?.size} SqFt` : '-'}</b>
                                          </span>
                                        </h4>
                                      </div>

                                      <div className='col-12 px-4'>
                                        <h4 className='my-2 mb-0' style={{color: 'black'}}>
                                          <label
                                            style={{minWidth: '100px'}}
                                            className='labl-gry me-3'
                                          >
                                            {' '}
                                            Property Manager{' '}
                                          </label>
                                          <span className='ms-5 ps-1'>
                                            <b>{unitData?.managerId ? unitData?.managerId : '-'}</b>
                                          </span>
                                        </h4>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )}

                        <div className='ms-auto mt-auto' style={{height: '0px'}}>
                          <div
                            className='br-arr d-flex align-items-center justify-content-center'
                            style={{transform: 'translate(0px, -25px)'}}
                          >
                            {hide === false ? (
                              <img
                                src={upGreen}
                                height={20}
                                width={20}
                                className='cursor-pointer'
                                onClick={() => {
                                  if (!sendError) setHide(true)
                                }}
                              />
                            ) : (
                              <img
                                src={downGreen}
                                height={20}
                                width={20}
                                className='cursor-pointer'
                                onClick={() => {
                                  if (!sendError) setHide(false)
                                }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Main Tenant Tab */}
                {propertiType === 'Tenants' && (
                  <>
                    <div className='row mx-1' style={sendError ? {opacity: '0.4'} : {opacity: '1'}}>
                      <div className='col-7 ps-5'>
                        <h2 className='top ps-2'>
                          <b>Main Tenant Details</b>
                        </h2>

                        {tenanatData &&
                          tenanatData.map((pI: any, index: any) => {
                            if (newTenantId == pI._id) itemInd = index
                            return <></>
                          })}

                        <div
                          className='row ms-3 mb-3 mt-3'
                          style={{justifyContent: 'space-between'}}
                        >
                          <div className='col-4 ps-0' style={{color: 'black'}}>
                            <Select
                              defaultValue={
                                formData?.tenanttSource == 0 ? sourceOptions[0] : sourceOptions[1]
                              }
                              styles={customStyles}
                              isSearchable={false}
                              options={sourceOptions}
                              name='tenanttSource'
                              onChange={(e) => {
                                //

                                let values = {formData}
                                values = {
                                  ...formData,
                                  tenanttSource: e?.value,
                                }
                                setFormData(values)
                                setIsFormDirty(true)
                              }}
                              isDisabled={isViewMode || isFinanceSubmitted ? true : false}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='row mx-3 mt-4'>
                        <div className='col-7 px-5'>
                          <div className='d-flex'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '100px'}} className='labl-gry'>
                                {' '}
                                First Name{' '}
                              </label>
                              <span className='ms-5 ps-1'>
                                <b>{formData?.firstName ? formData?.firstName : ''}</b>
                              </span>
                            </h4>
                            {newTenantId && flg != '3' && (
                              <img
                                src={profileGreen}
                                height={26}
                                width={26}
                                className='me-3 ms-auto'
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                  setFrom('main')
                                  setTenantIdToView(newTenantId)
                                  setEditTenantModal(true)
                                }}
                              />
                            )}
                          </div>
                        </div>
                        <div className='col-7 px-5'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry'>
                              {' '}
                              Last Name{' '}
                            </label>
                            <span className='ms-5 ps-1'>
                              <b>{formData?.lastName ? formData?.lastName : ''}</b>
                            </span>
                          </h4>
                        </div>
                        <div className='col-7 px-5'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry'>
                              {' '}
                              Passport No.{' '}
                            </label>
                            <span className='ms-5 ps-1'>
                              <b>{formData?.passportNo ? formData?.passportNo : ''}</b>
                            </span>
                          </h4>
                        </div>

                        <div className='col-7 px-5'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry'>
                              {' '}
                              ID No.{' '}
                            </label>
                            <span className='ms-5 ps-1'>
                              <b>{formData?.idNo ? formData?.idNo : ''}</b>
                            </span>
                          </h4>
                        </div>

                        <div className='col-7 px-5'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry'>
                              {' '}
                              DOB{' '}
                            </label>
                            <span className='ms-5 ps-1'>
                              <b>
                                {formData?.DOB
                                  ? moment(formData?.DOB && formData?.DOB.split('T')[0]).format(
                                      'DD/MM/YYYY'
                                    )
                                  : ''}
                              </b>
                            </span>
                          </h4>
                        </div>

                        <div className='col-7 px-5'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry'>
                              Nationality{' '}
                            </label>
                            <span className='ms-5 ps-1'>
                              <b>{formData?.nationality ? formData?.nationality : ''}</b>
                            </span>
                          </h4>
                        </div>

                        <div className='col-7 px-5'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry'>
                              Email{' '}
                            </label>
                            <span className='ms-5 ps-1'>
                              <b>{formData?.email ? formData?.email : ''}</b>
                            </span>
                          </h4>
                        </div>

                        <div className='col-7 px-5'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry'>
                              Mobile No.{' '}
                            </label>
                            <span className='ms-5 ps-1'>
                              <b>
                                {formData?.phoneNumber
                                  ? `${formData?.countryCode} ${formData?.phoneNumber}`
                                  : ''}
                              </b>
                            </span>
                          </h4>
                        </div>
                      </div>

                      <div className='row mx-1 mt-5 pt-3'>
                        <div className='col-7 mt-5'>
                          <div className='d-flex  align-items-center'>
                            <h3 className='ms-0' style={{fontWeight: '700'}}>
                              Documents
                            </h3>
                            <div className='ms-auto'>
                              <button
                                type='button'
                                className='btn btn-sm fw-bold  ms-3  blue-btn'
                                disabled={isViewMode || isFinanceSubmitted}
                                onClick={() => {
                                  if (newTenantId) {
                                    setFrom('tenant')

                                    let temp = [...tenantDocuments]
                                    localStorage.setItem('tenantCopy', JSON.stringify(temp))

                                    setTenantFolderModal(true)
                                  } else {
                                    ErrorToast('Please select Main Tenant..')
                                  }
                                }}
                              >
                                <img
                                  src={addFolder}
                                  height={20}
                                  width={20}
                                  style={{marginRight: '7px'}}
                                />{' '}
                                Folder
                              </button>
                            </div>
                          </div>
                        </div>

                        {tenantDocuments?.map((doc: any, index: any) => {
                          return (
                            <>
                              {doc.isCheck && (
                                <>
                                  <div className='col-7 ps-3 pe-3 mt-5 pt-2'>
                                    <div className='d-flex  align-items-center'>
                                      <h4 className='' style={{fontWeight: '700'}}>
                                        {doc?.name}
                                      </h4>
                                      <div className='ms-auto d-flex align-items-center'>
                                        <button
                                          type='button'
                                          className='btn btn-sm fw-bold  ms-3  blue-hollow-btn'
                                          disabled={sendError || isViewMode || isFinanceSubmitted}
                                          onClick={() => {
                                            setFrom('tenant')
                                            setTargetFolder(doc)
                                            setTenantFileModal(true)
                                          }}
                                        >
                                          <img
                                            src={invoiceAdd}
                                            height={20}
                                            width={16}
                                            style={{marginRight: '15px'}}
                                          />{' '}
                                          File
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-5 mt-4 ps-1 pt-3'>
                                    <>
                                      {isViewMode || isFinanceSubmitted ? (
                                        <></>
                                      ) : (
                                        <img
                                          src={redCrossFilled}
                                          height={15}
                                          width={15}
                                          className='cursor-pointer ms-0 mt-3'
                                          onClick={() => {
                                            const values = [...tenantDocuments]
                                            values[index].isCheck = false
                                            setTenantDocuments(values)
                                            if (flg == '2') setOpen(true)
                                            setTimeout(() => {
                                              setAutoSaveData(Date.now())
                                            }, 1000)
                                          }}
                                        />
                                      )}
                                    </>
                                  </div>

                                  <div className='col-12 px-2 mt-5 pt-3'>
                                    {doc?.fileList?.length > 0 ? (
                                      <div
                                        className='tops d-flex gap-5 align-items-start mx-5'
                                        style={{overflow: 'auto', overflowY: 'hidden'}}
                                      >
                                        {doc?.fileList?.map((file: any, ind: any) => {
                                          return (
                                            <>
                                              {file.isChecked && (
                                                <div className='imgs-set'>
                                                  <div className='d-flex mw-350px me-3'>
                                                    <img
                                                      src={Bucket + file?.fileURL}
                                                      width='100'
                                                      height='100'
                                                      className='main_img mb-5 cursor-pointer'
                                                      id={`clicked${doc?.name}${ind}`}
                                                      onClick={() =>
                                                        handleImageClick(
                                                          `clicked${doc?.name}${ind}`,
                                                          `${Bucket}${file?.fileURL}`,
                                                          `${doc?.name}`,
                                                          doc?.fileList,
                                                          'TenantDocs'
                                                        )
                                                      }
                                                    />
                                                    <>
                                                      {isViewMode || isFinanceSubmitted ? (
                                                        <></>
                                                      ) : (
                                                        <img
                                                          src={redCross}
                                                          height={15}
                                                          width={15}
                                                          className='cursor-pointer ms-2 mt-1'
                                                          onClick={() => {
                                                            const values = [...tenantDocuments]
                                                            console.log(values)
                                                            values[index].fileList[ind].isChecked =
                                                              false
                                                            setTenantDocuments(values)
                                                            if (flg == '2') setOpen(true)
                                                            setTimeout(() => {
                                                              setAutoSaveData(Date.now())
                                                            }, 700)
                                                          }}
                                                        />
                                                      )}
                                                    </>
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )
                                        })}
                                      </div>
                                    ) : (
                                      <div className='d-flex justify-content-center'>
                                        {' '}
                                        <div className=''>
                                          {' '}
                                          {/* <h2 className='head-text'>No Files...</h2> */}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </>
                          )
                        })}

                        {tenantDeletedDocuments?.map((doc: any, index: any) => {
                          return (
                            <>
                              {doc.isCheck && (
                                <>
                                  <div className='col-12 ps-4 pe-3 mt-4'>
                                    <div className='d-flex  align-items-center'>
                                      <h4 className='' style={{fontWeight: '700'}}>
                                        {doc?.name}
                                      </h4>
                                    </div>
                                  </div>

                                  <div className='col-12 px-4 mt-4'>
                                    {doc?.fileList?.length > 0 ? (
                                      <div
                                        className='tops d-flex gap-5 align-items-start mx-5'
                                        style={{overflow: 'auto', overflowY: 'hidden'}}
                                      >
                                        {doc?.fileList?.map((file: any, ind: any) => {
                                          return (
                                            <>
                                              {file.isChecked && (
                                                <div className='imgs-set'>
                                                  <div className='d-flex mw-350px me-3'>
                                                    <img
                                                      src={Bucket + file?.fileURL}
                                                      width='100'
                                                      height='100'
                                                      className='main_img mb-5 cursor-pointer'
                                                      id={`clicked${doc?.name}${ind}`}
                                                        onClick={() =>
                                                          handleImageClick(
                                                            `clicked${doc?.name}${ind}`,
                                                            `${Bucket}${file?.fileURL}`,
                                                            `${doc?.name}`,
                                                            doc?.fileList,
                                                            'TenantDeletedDocs'
                                                          )
                                                        }
                                                    />
                                                    {isViewMode || isFinanceSubmitted ? (
                                                      <></>
                                                    ) : (
                                                      <img
                                                        src={redCross}
                                                        height={15}
                                                        width={15}
                                                        className='cursor-pointer ms-2 mt-1'
                                                        onClick={() => {
                                                          const values = [...tenantDeletedDocuments]
                                                          values[index].fileList[ind].isChecked =
                                                            false
                                                          setTenantDeletedDocuments(values)
                                                          if (flg == '2') setOpen(true)
                                                          setTimeout(() => {
                                                            setAutoSaveData(Date.now())
                                                          }, 700)
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )
                                        })}
                                      </div>
                                    ) : (
                                      <div className='d-flex justify-content-center'>
                                        {' '}
                                        <div className=''>
                                          {' '}
                                          {/* <h2 className='head-text'>No Files...</h2> */}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </>
                          )
                        })}

                        {sendError && (
                          <>
                            <div className='col-7 ps-3 pe-3 mt-5 pt-3'>
                              <div className='d-flex  align-items-center'>
                                <h4 className='' style={{fontWeight: '700'}}>
                                  Passport
                                </h4>
                                <div className='ms-auto'>
                                  <button
                                    type='button'
                                    className='btn btn-sm fw-bold  ms-3  blue-hollow-btn'
                                    disabled={sendError}
                                  >
                                    <img
                                      src={invoiceAdd}
                                      height={20}
                                      width={16}
                                      style={{marginRight: '15px'}}
                                    />{' '}
                                    File
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className='col-7 ps-3 pe-3 mt-5 pt-3'>
                              <div className='d-flex  align-items-center'>
                                <h4 className='' style={{fontWeight: '700'}}>
                                  Residency
                                </h4>
                                <div className='ms-auto'>
                                  <button
                                    type='button'
                                    className='btn btn-sm fw-bold  ms-3  blue-hollow-btn'
                                    disabled={sendError}
                                  >
                                    <img
                                      src={invoiceAdd}
                                      height={20}
                                      width={16}
                                      style={{marginRight: '15px'}}
                                    />{' '}
                                    File
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className='col-7 ps-3 pe-3 mt-5 pt-3'>
                              <div className='d-flex  align-items-center'>
                                <h4 className='' style={{fontWeight: '700'}}>
                                  ID
                                </h4>
                                <div className='ms-auto'>
                                  <button
                                    type='button'
                                    className='btn btn-sm fw-bold  ms-3  blue-hollow-btn'
                                    disabled={sendError}
                                  >
                                    <img
                                      src={invoiceAdd}
                                      height={20}
                                      width={16}
                                      style={{marginRight: '15px'}}
                                    />{' '}
                                    File
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      {/* Sub Tenants */}
                      <div className='row mx-0 mt-5 pt-3'>
                        <div className='col-12 ps-5 pe-0 mt-4'>
                          <div className='d-flex  align-items-center'>
                            <h2 className='ms-0' style={{fontWeight: '700'}}>
                              Sub Tenants
                            </h2>

                            <div className='d-flex ms-auto'>
                              <button
                                type='button'
                                className='btn btn-sm fw-bold  me-3  select-btn'
                                onClick={() => {
                                  let values = [...subTenantData]
                                  let temp = subTenantData?.length

                                  if (values?.length == 0) handleAddFields()
                                  else {
                                    console.log(values[temp])
                                    if (values[values?.length - 1]?.id != '') handleAddFields()
                                    else
                                      ErrorToast(
                                        'The subtenant selection dropdown is already available, please select the previous dropdown to add more subtenants first.'
                                      )
                                  }
                                }}
                                disabled={isViewMode || isFinanceSubmitted}
                              >
                                <img
                                  src={addWhite}
                                  height={18}
                                  width={18}
                                  style={{marginRight: '10px'}}
                                />{' '}
                                Add
                              </button>

                              <button
                                type='button'
                                className='btn btn-sm fw-bold green-submit-btn status-w-160'
                                onClick={() => {
                                  setFrom('sub')
                                  setAddNewTenant(true)
                                }}
                                disabled={isViewMode || isFinanceSubmitted}
                              >
                                <img
                                  src={profileWhite}
                                  height={19}
                                  width={19}
                                  style={{margin: '5px'}}
                                />{' '}
                                Add New Tenant
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='row mx-0'>
                        <div className='col-12 ps-4 pe-0'>
                          <div className='table-responsive' style={{overflow: 'unset'}}>
                            <table
                              className='table align-middle  fs-6 gy-5'
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                  <th className='text-start min-w-100px'>First Name</th>
                                  <th className='text-start min-w-100px'>Last Name</th>
                                  <th className='text-start min-w-100px'>Passport No.</th>
                                  <th className='text-start min-w-100px'>ID No.</th>
                                  <th className='text-start min-w-150px'>Email </th>
                                  <th className='text-start min-w-100px'>Mobile No.</th>
                                  <th className='text-start min-w-100px'></th>
                                </tr>
                              </thead>

                              <tbody className='fw-semibold text-gray-600'>
                                {subTenantData?.length ? (
                                  subTenantData.map((sb: any, i: any) => {
                                    return (
                                      <>
                                        {tenanatData &&
                                          tenanatData.map((pI: any, index: any) => {
                                            if (sb?.id == pI._id) itemIndSub = index
                                            return <></>
                                          })}
                                        <tr className=''>
                                          <div
                                            className=''
                                            style={{color: 'black', width: '200px'}}
                                          >
                                            {sb?.id != '' ? (
                                              <Select
                                                defaultValue={{
                                                  label: `${tenanatData[itemIndSub]?.firstName} ${tenanatData[itemIndSub]?.lastName}`,
                                                  value: tenanatData[itemIndSub]?._id,
                                                }}
                                                value={{
                                                  label: `${tenanatData[itemIndSub]?.firstName} ${tenanatData[itemIndSub]?.lastName}`,
                                                  value: tenanatData[itemIndSub]?._id,
                                                }}
                                                styles={customStyles}
                                                isSearchable={true}
                                                options={
                                                  tenanatData &&
                                                  tenanatData?.map((i: any, index: any) => {
                                                    return {
                                                      label: `${i?.firstName} ${i?.lastName}`,
                                                      value: `${i?._id}`,
                                                    }
                                                  })
                                                }
                                                onChange={(e: any) => handleSelectSubTenant(e, i)}
                                                isDisabled={
                                                  isViewMode || isFinanceSubmitted ? true : false
                                                }
                                              />
                                            ) : (
                                              <Select
                                                value={{
                                                  label: `Search Tenant..`,
                                                  value: '',
                                                }}
                                                styles={customStyles}
                                                isSearchable={true}
                                                options={
                                                  tenanatData &&
                                                  tenanatData?.map((i: any, index: any) => {
                                                    return {
                                                      label: `${i?.firstName} ${i?.lastName}`,
                                                      value: `${i?._id}`,
                                                    }
                                                  })
                                                }
                                                name='tenanttSource'
                                                onChange={(e: any) => handleSelectSubTenant(e, i)}
                                                isDisabled={
                                                  isViewMode || isFinanceSubmitted ? true : false
                                                }
                                              />
                                            )}
                                          </div>
                                        </tr>
                                        {sb?.id != '' && (
                                          <tr className='mt-2'>
                                            <td className='text-start min-w-100px'>
                                              {`${sb?.firstName}` ? `${sb?.firstName}` : '-'}
                                            </td>
                                            <td className='text-start min-w-100px'>
                                              {`${sb?.lastName}` ? `${sb?.lastName}` : '-'}
                                            </td>
                                            <td className='text-start min-w-100px'>
                                        {(sb?.passportNo != 'undefined' && sb?.passportNo != undefined &&  sb?.passportNo != null && sb?.passportNo != 'null') ? sb?.passportNo : '-'} 
                                        </td>
                                        <td className='text-start min-w-100px'>
                                          {(sb?.idNo != 'undefined' && sb?.idNo != undefined &&  sb?.idNo != null) ? sb?.idNo : '-'}
                                        </td>
                                            <td className='text-start min-w-150px'>
                                              {`${sb?.email}` ? `${sb?.email}` : '-'}{' '}
                                            </td> 
                                            <td className='text-start min-w-100px'>
                                              {`${sb?.phoneNumber}}`
                                                ? `${sb?.countryCode} ${sb?.phoneNumber}`
                                                : '-'}
                                            </td>
                                            <td className='text-start min-w-100px'>
                                              <div className='d-flex align-items-center'>
                                                <img
                                                  src={profileGreen}
                                                  height={20}
                                                  width={20}
                                                  className='me-3'
                                                  style={{cursor: 'pointer'}}
                                                  onClick={() => {
                                                    setFrom('sub')
                                                    setSubInd(i)
                                                    setTenantIdToView(sb?.id)
                                                    setEditTenantModal(true)
                                                  }}
                                                />{' '}
                                                {!isViewMode && !isFinanceSubmitted && (
                                                  <img
                                                    src={redCross}
                                                    height={16}
                                                    width={16}
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => {
                                                      swal
                                                        .fire({
                                                          text: 'Are you sure you want to remove this tenant from Sub Tenants List ?',
                                                          icon: 'warning',
                                                          showConfirmButton: true,
                                                          confirmButtonColor: '#D72852',
                                                          confirmButtonText: 'Yes',
                                                          showCancelButton: true,
                                                          // cancelButtonColor: "transparent",
                                                          cancelButtonText: 'Cancel',
                                                        })
                                                        .then((res) => {
                                                          if (res.isConfirmed) {
                                                            // deleteTenant(v?._id, 1)
                                                            let temp = [...subTenantIds]
                                                            temp.splice(i, 1)
                                                            setSubTenantIds(temp)

                                                            let values = [...subTenantData]
                                                            values.splice(i, 1)
                                                            setSubTenantData(values)

                                                            setIsFormDirty(true)
                                                          }
                                                        })
                                                    }}
                                                  />
                                                )}
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </>
                                    )
                                  })
                                ) : (
                                  <>
                                    <tr>
                                      <td colSpan={10} className='text-center'>
                                        <img src={noData} alt='' width={350} />
                                      </td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {/* Contract Tab */}
                {propertiType === 'Tenancy' && (
                  <>
                    <div className='row mx-4' style={sendError ? {opacity: '0.4'} : {opacity: '1'}}>
                      <div className='col-7 px-5'>
                        <h2 className='top ps-0'>
                          <b>Contract Details</b>
                        </h2>
                      </div>

                      <div className='col-7 px-5 mt-4'>
                        <div className='d-flex '>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry required'>
                              {' '}
                              Contract No.
                            </label>
                          </h4>
                          <input
                            type='number'
                            className='form-control form-control-solid mytest tenancy-control'
                            placeholder=''
                            name='contractNo'
                            value={tenancyData?.contractNo}
                            onChange={handleChangeContractNo}
                            style={{height: '35px', width: '40%'}}
                            onBlur={() => {
                              if (flg == '2') setOpen(true)
                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 700)
                            }}
                            disabled={isViewMode || isFinanceSubmitted}
                          />
                        </div>
                        <div className='d-flex '>
                          <h4 className='' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry'>
                              {' '}
                            </label>
                          </h4>

                          {contractNoError && (
                            <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                          )}
                        </div>
                      </div>

                      <div className='col-7 px-5 mt-2 mb-2'>
                        <div className='d-flex'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry required'>
                              {' '}
                              Term{' '}
                            </label>
                          </h4>
                          <CustomDateRangePicker
                            startDate={start_date}
                            endDate={end_date}
                            dateStatuses={dateStatuses}
                            onDatesChange={handleDatesChange}
                            //  datesToCompare={datesToCompare}
                            autoSave={() => {
                              if (flg == '2') setOpen(true)
                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 500)
                            }}
                            from={'renew'}
                            disabled={isViewMode || isFinanceSubmitted}
                          />
                        </div>

                        <div className='d-flex'>
                          <h4 className='' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry'></label>
                          </h4>
                          {dateError && (
                            <p style={{color: 'var(--kt-danger)'}}>This field is required</p>
                          )}
                        </div>
                      </div>

                      <div className='col-7 px-5 mt-1 mb-2'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            {' '}
                            Duration
                          </label>
                          <span className=' ps-1'>
                            <b>{totalDays}</b>
                          </span>
                        </h4>
                      </div>

                      <div className='col-7 px-5 my-3 mb-2'>
                        <h4 className='my-2' style={{color: 'black'}}>
                          <label style={{width: '100px'}} className='labl-gry'>
                            {' '}
                            Remaining
                          </label>
                          <span className='ps-1'>
                            <b>
                              {parseFloat(remainingDays.split(' ')[0]) > 0
                                ? remainingDays
                                : '0 days'}
                            </b>
                          </span>
                        </h4>
                      </div>

                      <div className='col-7 px-5 mt-5'>
                        <div className='d-flex align-items-center'>
                          <h4 className='my-2' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry'>
                              {' '}
                              Renewal{' '}
                            </label>
                          </h4>
                          <input
                            type='number'
                            className='form-control form-control-solid mytest tenancy-control'
                            placeholder=''
                            name='renewalDays'
                            value={tenancyData?.renewalDays}
                            onChange={(e: any) => {
                              const {name, value} = e.target
                              setTenancyData({...tenancyData, [name]: value})
                              setIsFormDirty(true)

                              if (Math.floor(Number(ttlDays)) < Number(e.target.value))
                                setRenewalError(true)
                              else setRenewalError(false)
                            }}
                            onBlur={() => {
                              if (flg == '2') setOpen(true)
                              setTimeout(() => {
                                setAutoSaveData(Date.now())
                              }, 700)
                            }}
                            disabled={isViewMode || isFinanceSubmitted}
                            style={{height: '35px', width: '10%'}}
                          />{' '}
                          <span>
                            {' '}
                            <i className='mt-2 ms-2'>Days before term end</i>
                          </span>
                        </div>
                        <div className='d-flex '>
                          <h4 className='' style={{color: 'black'}}>
                            <label style={{width: '100px'}} className='labl-gry'></label>
                          </h4>
                          {renewalError && (
                            <p style={{color: 'var(--kt-danger)'}}>Cannot exceed total duration</p>
                          )}
                        </div>
                      </div>

                      <div className='col-7 ps-5 mt-5'>
                        <div className='d-flex'>
                          <div className='d-flex'>
                            <label
                              style={{width: '100px'}}
                              className='labl-gry form-label'
                              htmlFor='exampleFormControlInput1'
                            >
                              Benefits
                            </label>
                            <div className='test'>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='vacant0'
                                  name='none'
                                  checked={optionArry?.none === true ? true : false}
                                  onChange={handleChnageOption}
                                  disabled={isViewMode || isFinanceSubmitted}
                                />
                                <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                                  {' '}
                                  None
                                </p>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='occupied1'
                                  name='chillerFree'
                                  checked={optionArry?.chillerFree === true ? true : false}
                                  disabled={
                                    optionArry?.none === true || isViewMode || isFinanceSubmitted
                                      ? true
                                      : false
                                  }
                                  onChange={handleChnageOption}
                                />
                                <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                                  {' '}
                                  Chiller Free
                                </p>
                              </div>
                              <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='occupied2'
                                  name='maintenanceFree'
                                  checked={optionArry?.maintenanceFree === true ? true : false}
                                  disabled={
                                    optionArry?.none === true || isViewMode || isFinanceSubmitted
                                      ? true
                                      : false
                                  }
                                  onChange={handleChnageOption}
                                />
                                <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                                  {' '}
                                  Maintenance Free
                                </p>
                              </div>
                              {otherOptions?.length > 0 &&
                                otherOptions?.map((opt: any, ind: any) => (
                                  <div className='form-check form-check-custom form-check-solid form-check-sm mb-1'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      id='occupied2'
                                      name='maintenanceFree'
                                      checked={optionArry?.none == true ? false : true}
                                    />
                                    <input
                                      className='form-control form-control-solid mytest w-75 ms-3 tenancy-control'
                                      type='text'
                                      value={opt.value}
                                      onChange={(e: any) => {
                                        const values = [...otherOptions]
                                        values[ind] = {
                                          value: e.target.value,
                                        }
                                        setOtherOptions(values)

                                        setIsFormDirty(true)
                                      }}
                                      onBlur={() => {
                                        if (flg == '2') setOpen(true)
                                        setTimeout(() => {
                                          setAutoSaveData(Date.now())
                                        }, 700)
                                      }}
                                      style={{height: '35px'}}
                                      disabled={isViewMode || isFinanceSubmitted}
                                    />

                                    <p className='head-text ms-3 mb-0' style={{fontWeight: '600'}}>
                                      {!isViewMode && !isFinanceSubmitted && (
                                        <img
                                          src={redCross}
                                          width='16px'
                                          height='16px'
                                          className='main_img cursor-pointer ms-auto'
                                          onClick={() => handleRemoveOther(ind)}
                                        />
                                      )}
                                    </p>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className='ms-auto'>
                            <button
                              type='button'
                              className='btn btn-sm fw-bold blue-btn'
                              onClick={() => {
                                if (optionArry?.none === true || sendError == true) {
                                } else handleAddOther()
                              }}
                              disabled={
                                optionArry?.none == true || isViewMode || isFinanceSubmitted ? true : false
                              }
                            >
                              <img
                                src={addWhite}
                                height={16}
                                width={16}
                                style={{marginRight: '10px'}}
                              />{' '}
                              Other
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* {isFinance && ( */}
                      <div className='row mx-1 mt-5 pt-4'>
                        <div className='col-7 ps-0'>
                          <div className='d-flex  align-items-center'>
                            <h3 className='ms-0' style={{fontWeight: '700'}}>
                              Financial Summary
                            </h3>
                            <div className='ms-auto'>
                              {filteredFinancialItems && filteredFinancialItems?.length > 0 && (
                                <button
                                  type='button'
                                  className='btn btn-sm fw-bold blue-btn'
                                  onClick={() => {
                                    if (otherFinancials?.length > 0) {
                                      if (
                                        otherFinancials[otherFinancials?.length - 1]?.name ==
                                          undefined ||
                                        otherFinancials[otherFinancials?.length - 1]?.name == ''
                                      )
                                        ErrorToast(
                                          'Please first select the previous dropdown to add more items.'
                                        )
                                      else handleAddOtherFinancials()
                                    } else {
                                      handleAddOtherFinancials()
                                    }
                                  }}
                                  disabled={isViewMode || isFinanceSubmitted}
                                >
                                  <img
                                    src={addWhite}
                                    height={16}
                                    width={16}
                                    style={{marginRight: '10px'}}
                                  />{' '}
                                  Other
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='col-7 ps-0 ms-2'>
                          <div className='d-flex'>
                            {/* <label
                          style={{width: '120px'}}
                          className='labl-gry form-label'
                          htmlFor='exampleFormControlInput1'
                        >
                        </label> */}
                            <div className='test mt-4' style={{width: '-webkit-fill-available'}}>
                              <div className='row mb-3'>
                                <p
                                  className='mb-0 col-3 mt-3 ps-1 pe-0'
                                  style={{
                                    fontWeight: '500',
                                    // minWidth: '180px'
                                  }}
                                >
                                  {' '}
                                  Rent
                                </p>
                                <div className='col-3'>
                                  <input
                                    className='form-control form-control-solid mytest ms-3 tenancy-control financial-input'
                                    type='number'
                                    value={rentFin?.totalAmount}
                                    onChange={(e: any) => {
                                      const newValue = e.target.value.replace(/^0+/, '')

                                      let values = {...rentFin}
                                      values.totalAmount = newValue === '' ? 0 : Number(newValue)
                                      setRentFin(values)
                                      setIsFormDirty(true)
                                    }}
                                    onBlur={() => {
                                      if (flg == '2') setOpen(true)
                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 1000)
                                    }}
                                    placeholder='Enter Total Value'
                                    disabled={ isViewMode || isFinanceSubmitted}
                                  />
                                </div>
                                <p
                                  className='mb-0 col-2 mt-3 ps-5 pe-0'
                                  style={{
                                    fontWeight: '500',
                                    // minWidth: '180px'
                                  }}
                                >
                                  {' '}
                                  No. Of Payments
                                </p>
                                <div className='col-3'>
                                  <input
                                    className='form-control form-control-solid mytest ms-3 tenancy-control financial-input'
                                    type='number'
                                    value={rentFin?.noOfPayment}
                                    onChange={(e: any) => {
                                      const newValue = e.target.value.replace(/^0+/, '')

                                      let values = {...rentFin}
                                      values.noOfPayment = newValue === '' ? 0 : Number(newValue)
                                      setRentFin(values)
                                      setIsFormDirty(true)
                                    }}
                                    onBlur={() => {
                                      if (flg == '2') setOpen(true)
                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 1000)
                                    }}
                                    placeholder=''
                                    disabled={ isViewMode || isFinanceSubmitted}
                                  />
                                </div>
                              </div>
                              <div className='row mb-3'>
                                <p
                                  className='mb-0 col-3 mt-3 ps-1 pe-0'
                                  style={{
                                    fontWeight: '500',
                                    // minWidth: '180px'
                                  }}
                                >
                                  {' '}
                                  Security Deposit
                                </p>
                                <div className='col-3'>
                                  <input
                                    className='form-control form-control-solid mytest ms-3 tenancy-control financial-input'
                                    type='number'
                                    value={securityFin?.totalAmount}
                                    onChange={(e: any) => {
                                      const newValue = e.target.value.replace(/^0+/, '')

                                      let values = {...securityFin}
                                      values.totalAmount = newValue === '' ? 0 : Number(newValue)
                                      setSecurityFin(values)
                                      setIsFormDirty(true)
                                    }}
                                    onBlur={() => {
                                      if (flg == '2') setOpen(true)
                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 1000)
                                    }}
                                    placeholder='Enter Total Value'
                                    disabled={ isViewMode || isFinanceSubmitted}
                                  />
                                </div>
                                <p
                                  className='mb-0 col-2 mt-3 ps-5 pe-0'
                                  style={{
                                    fontWeight: '500',
                                    // minWidth: '180px'
                                  }}
                                >
                                  {' '}
                                  No. Of Payments
                                </p>
                                <div className='col-3'>
                                  <input
                                    className='form-control form-control-solid mytest  ms-3 tenancy-control financial-input'
                                    type='number'
                                    value={securityFin?.noOfPayment}
                                    onChange={(e: any) => {
                                      const newValue = e.target.value.replace(/^0+/, '')

                                      let values = {...securityFin}
                                      values.noOfPayment = newValue === '' ? 0 : Number(newValue)
                                      setSecurityFin(values)
                                      setIsFormDirty(true)
                                    }}
                                    onBlur={() => {
                                      if (flg == '2') setOpen(true)
                                      setTimeout(() => {
                                        setAutoSaveData(Date.now())
                                      }, 1000)
                                    }}
                                    placeholder=''
                                    disabled={ isViewMode || isFinanceSubmitted}
                                  />
                                </div>
                              </div>

                              {otherFinancials?.length > 0 &&
                                otherFinancials?.map((opt: any, ind: any) => (
                                  <>
                                    <>
                                      {financialItems &&
                                        financialItems.map((fn: any, index: any) => {
                                          // console.log(opt.name)
                                          if (opt.name == fn.name) {
                                            itemInd = index
                                          }
                                          return <></>
                                        })}
                                    </>

                                    <div className='row mb-3'>
                                      <div
                                        style={{
                                          fontWeight: '500',
                                          // minWidth: '180px'
                                        }}
                                        className='col-3 ps-1 pe-0'
                                      >
                                        {opt.name != undefined && opt.name != '' ? (
                                          <Select
                                            options={
                                              filteredFinancialItems &&
                                              filteredFinancialItems?.map(
                                                (pItem: any, index: any) => {
                                                  return {
                                                    label: `${pItem?.name}`,
                                                    value: `${pItem?._id}`,
                                                    id: pItem,
                                                  }
                                                }
                                              )
                                            }
                                            defaultValue={{
                                              label: `${financialItems[itemInd]?.name}`,
                                              value: financialItems[itemInd]?._id,
                                              id: financialItems[itemInd],
                                            }}
                                            value={{
                                              label: `${financialItems[itemInd]?.name}`,
                                              value: financialItems[itemInd]?._id,
                                              id: financialItems[itemInd],
                                            }}
                                            styles={customStyles}
                                            isSearchable={false}
                                            onChange={(event: any) => {
                                              let values: any = [...otherFinancials]
                                              values[ind].name = event.label
                                              values[ind].paymentItemListId = event.id?._id
                                              setOtherFinancials(values)
                                              setIsFormDirty(true)

                                              // setOtherAmountError(true)

                                              // validation for item selection
                                              const temp: any = []
                                              let k: any = 0

                                              // console.log(filteredFinancialItems)

                                              for (let i = 0; i < financialItems?.length; i++) {
                                                let isTrue = true
                                                let ind: any = ''
                                                for (let j = 0; j < values?.length; j++) {
                                                  // console.log(filteredFinancialItems[i].name)
                                                  // console.log(values[j].name)
                                                  if (financialItems[i].name == values[j].name) {
                                                    isTrue = false
                                                    ind = i
                                                  }
                                                }
                                                if (isTrue) {
                                                  temp[k] = financialItems[i]
                                                  k++
                                                }
                                              }

                                              setFilteredFinancialItems(temp)
                                              if (flg == '2') setOpen(true)
                                              setTimeout(() => {
                                                setAutoSaveData(Date.now())
                                              }, 700)
                                            }}
                                            isDisabled={isViewMode || isFinanceSubmitted}
                                          />
                                        ) : (
                                          <Select
                                            options={
                                              filteredFinancialItems &&
                                              filteredFinancialItems?.map(
                                                (pItem: any, index: any) => {
                                                  return {
                                                    label: `${pItem?.name}`,
                                                    value: `${pItem?._id}`,
                                                    id: pItem,
                                                  }
                                                }
                                              )
                                            }
                                            styles={customStyles}
                                            isSearchable={false}
                                            onChange={(event: any) => {
                                              let values: any = [...otherFinancials]
                                              values[ind].name = event.label
                                              values[ind].paymentItemListId = event.id?._id
                                              setOtherFinancials(values)

                                              setOtherAmountError(true)
                                              setIsFormDirty(true)

                                              // validation for item selection
                                              const temp: any = []
                                              let k: any = 0
                                              // console.log(filteredFinancialItems)

                                              for (let i = 0; i < financialItems?.length; i++) {
                                                let isTrue = true
                                                let ind: any = ''
                                                for (let j = 0; j < values?.length; j++) {
                                                  // console.log(filteredFinancialItems[i].name)
                                                  // console.log(values[j].name)
                                                  if (financialItems[i].name == values[j].name) {
                                                    isTrue = false
                                                    ind = i
                                                  }
                                                }
                                                if (isTrue) {
                                                  temp[k] = financialItems[i]
                                                  k++
                                                }
                                              }

                                              setFilteredFinancialItems(temp)
                                              if (flg == '2') setOpen(true)
                                              setTimeout(() => {
                                                setAutoSaveData(Date.now())
                                              }, 700)
                                            }}
                                            
                                            isDisabled={ isViewMode || isFinanceSubmitted}
                                            placeholder={'Payment Item'}
                                          />
                                        )}
                                      </div>
                                      <div className='col-3'>
                                        <input
                                          className='form-control form-control-solid mytest ms-3 tenancy-control financial-input'
                                          type='number'
                                          value={opt.totalAmount}
                                          onChange={(e: any) => {
                                            const newValue = e.target.value.replace(/^0+/, '')

                                            const values = [...otherFinancials]
                                            values[ind].totalAmount =
                                              newValue === '' ? 0 : Number(newValue)
                                            setOtherFinancials(values)

                                            setIsFormDirty(true)
                                          }}
                                          onBlur={() => {
                                            if (flg == '2') setOpen(true)
                                            setTimeout(() => {
                                              setAutoSaveData(Date.now())
                                            }, 1000)
                                          }}
                                          placeholder='Enter Total Value'
                                          disabled={ isViewMode || isFinanceSubmitted}
                                        />
                                      </div>
                                      <p
                                        className='mb-0 col-2 mt-3 ps-5 pe-0'
                                        style={{
                                          fontWeight: '500',
                                          // minWidth: '180px'
                                        }}
                                      >
                                        {' '}
                                        No. Of Payments
                                      </p>
                                      <div className='col-3'>
                                        <input
                                          className='form-control form-control-solid mytest ms-3 tenancy-control financial-input'
                                          type='number'
                                          value={opt.noOfPayment}
                                          onChange={(e: any) => {
                                            const newValue = e.target.value.replace(/^0+/, '')

                                            const values = [...otherFinancials]
                                            values[ind].noOfPayment =
                                              newValue === '' ? 0 : Number(newValue)
                                            setOtherFinancials(values)
                                            setIsFormDirty(true)
                                          }}
                                          onBlur={() => {
                                            if (flg == '2') setOpen(true)
                                            setTimeout(() => {
                                              setAutoSaveData(Date.now())
                                            }, 1000)
                                          }}
                                          placeholder=''
                                          disabled={ isViewMode || isFinanceSubmitted}
                                        />
                                      </div>

                                      <div className='col-1'>
                                        <p
                                          className='head-text ms-3 mb-0 mt-3'
                                          style={{fontWeight: '600'}}
                                        >
                                          {!isViewMode && !isFinanceSubmitted && (
                                            <img
                                              src={redCross}
                                              width='16px'
                                              height='16px'
                                              className='main_img cursor-pointer ms-auto'
                                              onClick={() => handleRemoveOtherFinancials(ind)}
                                            />
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* )} */}

                      <div className='row mx-1 mt-5 pt-4'>
                        <div className='col-7 ps-0'>
                          <div className='d-flex  align-items-center'>
                            <h3 className='ms-0' style={{fontWeight: '700'}}>
                              Documents
                            </h3>
                            <div className='ms-auto'>
                              <button
                                type='button'
                                className='btn btn-sm fw-bold  ms-3  blue-btn'
                                disabled={ isViewMode || isFinanceSubmitted}
                                onClick={() => {
                                  if (newTenantId) {
                                    setFrom('contract')

                                    let temp = [...contractDocuments]
                                    localStorage.setItem('contractCopy', JSON.stringify(temp))

                                    setTenantFolderModal(true)
                                  } else {
                                    ErrorToast('Please select Main Tenant..')
                                  }
                                }}
                              >
                                <img
                                  src={addFolder}
                                  height={20}
                                  width={20}
                                  style={{marginRight: '7px'}}
                                />{' '}
                                Folder
                              </button>
                            </div>
                          </div>
                        </div>

                        {contractDocuments?.map((doc: any, index: any) => {
                          return (
                            <>
                              {doc.isCheck && (
                                <>
                                  <div className='col-7 ps-0 pe-3 mt-5 pt-2'>
                                    <div className='d-flex  align-items-center'>
                                      <h4 className='' style={{fontWeight: '700'}}>
                                        {doc?.name}
                                      </h4>
                                      <div className='ms-auto'>
                                        <button
                                          type='button'
                                          className='btn btn-sm fw-bold  ms-3  blue-hollow-btn'
                                          disabled={ isViewMode || isFinanceSubmitted}
                                          onClick={() => {
                                            setFrom('contract')
                                            setTargetFolder(doc)
                                            setTenantFileModal(true)
                                          }}
                                        >
                                          <img
                                            src={invoiceAdd}
                                            height={20}
                                            width={16}
                                            style={{marginRight: '15px'}}
                                          />{' '}
                                          File
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='col-5 mt-4 ps-1 pt-3'>
                                    {!isViewMode && !isFinanceSubmitted && (
                                      <img
                                        src={redCrossFilled}
                                        height={15}
                                        width={15}
                                        className='cursor-pointer ms-0 mt-3'
                                        onClick={() => {
                                          const values = [...contractDocuments]
                                          values[index].isCheck = false
                                          setContractDocuments(values)
                                          if (flg == '2') setOpen(true)

                                          setTimeout(() => {
                                            setAutoSaveData(Date.now())
                                          }, 1000)
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className='col-12 px-3 mt-4'>
                                    {doc?.fileList?.length > 0 ? (
                                      <div
                                        className='tops d-flex gap-5 align-items-start mx-5'
                                        style={{overflow: 'auto', overflowY: 'hidden'}}
                                      >
                                        {doc?.fileList?.map((file: any, ind: any) => {
                                          return (
                                            <>
                                              {file.isChecked && (
                                                <div className='imgs-set'>
                                                  <div className='d-flex mw-350px me-3'>
                                                    <img
                                                      src={Bucket + file?.fileURL}
                                                      width='100'
                                                      height='100'
                                                      className='main_img mb-5 cursor-pointer'
                                                      id={`clicked${doc?.name}${ind}`}
                                                        onClick={() =>
                                                          handleImageClick(
                                                            `clicked${doc?.name}${ind}`,
                                                            `${Bucket}${file?.fileURL}`,
                                                            `${doc?.name}`,
                                                            doc?.fileList,
                                                            'ContactDocs'
                                                          )
                                                        }
                                                    />
                                                    {!isViewMode && !isFinanceSubmitted && (
                                                      <img
                                                        src={redCross}
                                                        height={15}
                                                        width={15}
                                                        className='cursor-pointer ms-2 mt-1'
                                                        onClick={() => {
                                                          const values = [...contractDocuments]
                                                          console.log(values)
                                                          values[index].fileList[ind].isChecked =
                                                            false
                                                          setContractDocuments(values)
                                                          if (flg == '2') setOpen(true)

                                                          setTimeout(() => {
                                                            setAutoSaveData(Date.now())
                                                          }, 1000)
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )
                                        })}
                                      </div>
                                    ) : (
                                      <div className='d-flex justify-content-center'>
                                        {' '}
                                        <div className=''>
                                          {' '}
                                          {/* <h2 className='head-text'>No Files...</h2> */}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </>
                          )
                        })}

                        {contractDeletedDocuments?.map((doc: any, index: any) => {
                          return (
                            <>
                              {doc.isCheck && (
                                <>
                                  <div className='col-7 ps-0 pe-1 mt-4'>
                                    <div className='d-flex  align-items-center'>
                                      <h4 className='' style={{fontWeight: '700'}}>
                                        {doc?.name}
                                      </h4>
                                    </div>
                                  </div>
                                  <div className='col-5 mt-4 ps-1'>
                                    {!isViewMode && !isFinanceSubmitted && (
                                      <img
                                        src={redCrossFilled}
                                        height={15}
                                        width={15}
                                        className='cursor-pointer ms-0 mt-3'
                                        onClick={() => {
                                          const values = [...contractDeletedDocuments]
                                          values[index].isCheck = false
                                          setContractDeletedDocuments(values)
                                          if (flg == '2') setOpen(true)

                                          setTimeout(() => {
                                            setAutoSaveData(Date.now())
                                          }, 1000)
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className='col-12 px-0 mt-5 pt-4'>
                                    {doc?.fileList?.length > 0 ? (
                                      <div
                                        className='tops d-flex gap-5 align-items-start mx-5'
                                        style={{overflow: 'auto', overflowY: 'hidden'}}
                                      >
                                        {doc?.fileList?.map((file: any, ind: any) => {
                                          return (
                                            <>
                                              {file.isChecked && (
                                                <div className='imgs-set'>
                                                  <div className='d-flex mw-350px me-3'>
                                                    <img
                                                      src={Bucket + file?.fileURL}
                                                      width='100'
                                                      height='100'
                                                      className='main_img mb-5 cursor-pointer'
                                                      id={`clicked${doc?.name}${ind}`}
                                                      onClick={() =>
                                                        handleImageClick(
                                                          `clicked${doc?.name}${ind}`,
                                                          `${Bucket}${file?.fileURL}`,
                                                          `${doc?.name}`,
                                                          doc?.fileList,
                                                          'ContactDeletedDocs'
                                                        )
                                                      }
                                                    />
                                                    {!isViewMode && isFinanceSubmitted && (
                                                      <img
                                                        src={redCross}
                                                        height={15}
                                                        width={15}
                                                        className='cursor-pointer ms-2 mt-1'
                                                        onClick={() => {
                                                          const values = [
                                                            ...contractDeletedDocuments,
                                                          ]
                                                          
                                                          values[index].fileList[ind].isChecked =
                                                            false
                                                          setContractDeletedDocuments(values)
                                                          if (flg == '2') setOpen(true)

                                                          setTimeout(() => {
                                                            setAutoSaveData(Date.now())
                                                          }, 1000)
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                </div>
                                              )}
                                            </>
                                          )
                                        })}
                                      </div>
                                    ) : (
                                      <div className='d-flex justify-content-center'>
                                        {' '}
                                        <div className=''>
                                          {' '}
                                          {/* <h2 className='head-text'>No Files...</h2> */}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </>
                          )
                        })}

                        {sendError && (
                          <>
                            <div className='col-7 ps-0 pe-3 mt-5 pt-3'>
                              <div className='d-flex  align-items-center'>
                                <h4 className='' style={{fontWeight: '700'}}>
                                  Contract
                                </h4>
                                <div className='ms-auto'>
                                  <button
                                    type='button'
                                    className='btn btn-sm fw-bold  ms-3  blue-hollow-btn'
                                    disabled={sendError}
                                  >
                                    <img
                                      src={invoiceAdd}
                                      height={20}
                                      width={16}
                                      style={{marginRight: '15px'}}
                                    />{' '}
                                    File
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/* Financials Tab */}
                {propertiType === 'Financials' && (
                  <>
                    <div className='row mx-4 mt-5'>
                      <div className='col-12 px-3 mt-5'>
                        {incomePaymentPayload?.length > 0 ? (
                          <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                            <table
                              className='table align-start table-row-dashed fs-6 gy-5'
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                  <th className='min-w-100px'>Payment</th>
                                  <th className='min-w-100px'>Amount</th>
                                  <th className='min-w-100px'>Status</th>
                                  <th className='min-w-100px'>Due</th>
                                  <th className='min-w-100px'>Created At</th>
                                  <th className='min-w-150px'>Amount Paid</th>
                                  <th className='min-w-150px'>Date Paid</th>
                                  <th className='min-w-100px'></th>
                                </tr>
                              </thead>
                              <tbody className='fw-semibold text-gray-600'>
                                {incomePaymentPayload?.map((pm: any, ind: any) => {
                                  isExisted = false
                                  if (rentAmountError) {
                                    for (let i = 0; i < pm?.new_payment_sub_item?.length; i++) {
                                      if (pm?.new_payment_sub_item?.[i]?.name == 'Rent') {
                                        isExisted = true
                                        i = pm?.new_payment_sub_item?.length
                                      }
                                    }
                                  } else if (!rentAmountError && securityAmountError) {
                                    for (let i = 0; i < pm?.new_payment_sub_item?.length; i++) {
                                      if (
                                        pm?.new_payment_sub_item?.[i]?.name == 'Security Deposit'
                                      ) {
                                        isExisted = true
                                        i = pm?.new_payment_sub_item?.length
                                      }
                                    }
                                  } else if (
                                    !rentAmountError &&
                                    !securityAmountError &&
                                    otherAmountError
                                  ) {
                                    for (let i = 0; i < pm?.new_payment_sub_item?.length; i++) {
                                      if (pm?.new_payment_sub_item?.[i]?.name == otherItem?.name) {
                                        isExisted = true
                                        i = pm?.new_payment_sub_item?.length
                                      }
                                    }
                                  } else if (
                                    !rentAmountError &&
                                    !securityAmountError &&
                                    !otherAmountError &&
                                    rentNoOfPaymentsError
                                  ) {
                                    for (let i = 0; i < pm?.new_payment_sub_item?.length; i++) {
                                      if (pm?.new_payment_sub_item?.[i]?.name == 'Rent') {
                                        isExisted = true
                                        i = pm?.new_payment_sub_item?.length
                                      }
                                    }
                                  } else if (
                                    !rentAmountError &&
                                    !securityAmountError &&
                                    !otherAmountError &&
                                    !rentNoOfPaymentsError &&
                                    securityNoOfPaymentsError
                                  ) {
                                    for (let i = 0; i < pm?.new_payment_sub_item?.length; i++) {
                                      if (
                                        pm?.new_payment_sub_item?.[i]?.name == 'Security Deposit'
                                      ) {
                                        isExisted = true
                                        i = pm?.new_payment_sub_item?.length
                                      }
                                    }
                                  } else if (
                                    !rentAmountError &&
                                    !securityAmountError &&
                                    !otherAmountError &&
                                    !rentNoOfPaymentsError &&
                                    !securityNoOfPaymentsError &&
                                    otherNoOfPaymentsError
                                  ) {
                                    for (let i = 0; i < pm?.new_payment_sub_item?.length; i++) {
                                      if (pm?.new_payment_sub_item?.[i]?.name == otherItem?.name) {
                                        isExisted = true
                                        i = pm?.new_payment_sub_item?.length
                                      }
                                    }
                                  } else {
                                  }
                                  return (
                                    <>
                                      <tr
                                        style={
                                          show[ind] == true
                                            ? {borderBottomStyle: 'none', height: '30px'}
                                            : show[ind] == false
                                            ? {borderBottomStyle: 'dashed', height: '30px'}
                                            : pm?.payment_sub_item?.length == 0
                                            ? {borderBottomStyle: 'dashed', height: '30px'}
                                            : {height: '30px'}
                                        }
                                        key={ind}
                                      >
                                        <td>
                                          <div className='d-flex align-items-center'>
                                            {pm?.payment?.name}{' '}
                                            {isExisted && (
                                              <AiOutlineExclamationCircle
                                                color='#d54645  '
                                                fontSize={16}
                                                fontWeight={600}
                                                className='cursor-pointer mx-3'
                                                style={{cursor: 'pointer', marginBottom: '2px'}}
                                              />
                                            )}
                                          </div>
                                        </td>

                                        <td>
                                          {parseFloat(pm?.payment?.totalAmount)?.toLocaleString(
                                            'en-US',
                                            {
                                              minimumFractionDigits: 2,
                                            }
                                          )}
                                        </td>

                                        <td>
                                          {'Draft'}
                                          {/* {pm?.payment?.paymentStatus === 0
                                        ? 'Unpaid'
                                        : pm?.payment?.paymentStatus === 1
                                        ? 'Paid'
                                        : pm?.payment?.paymentStatus === 2
                                        ? 'Partially paid'
                                        : pm?.payment?.paymentStatus === 3
                                        ? 'Void'
                                        : 'Draft'} */}
                                        </td>

                                        <td>
                                          {pm?.payment?.dueDate
                                            ? moment(pm?.payment?.dueDate).format(
                                                'DD.MM.YYYY - hh:mm A'
                                              )
                                            : '-'}
                                        </td>
                                        <td>
                                          {pm?.payment?.createdAt
                                            ? moment(pm?.payment?.createdAt).format(
                                                'DD.MM.YYYY - hh:mm A'
                                              )
                                            : '-'}
                                        </td>
                                        <td>
                                          {pm?.payment?.amountPaid?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                          })}
                                        </td>
                                        <td>
                                          {pm?.payment?._id && pm?.isManipulated == undefined ? (
                                            <>
                                              {pm?.payment?.datePaid
                                                ? moment(pm?.payment?.datePaid).format(
                                                    'DD.MM.YYYY - hh:mm A'
                                                  )
                                                : '-'}
                                            </>
                                          ) : (
                                            <>
                                              {pm?.new_payment_receipt?.[0]?.datePaid
                                                ? moment(
                                                    pm?.new_payment_receipt?.[0]?.datePaid
                                                  ).format('DD.MM.YYYY - hh:mm A')
                                                : '-'}
                                            </>
                                          )}
                                        </td>
                                        <td>
                                          <BsArrowRightSquareFill
                                            color='#35475E '
                                            fontSize={18}
                                            style={{cursor: 'pointer'}}
                                            className='mx-3 me-4'
                                            onClick={() => {
                                              let values = {
                                                firstName: formData?.firstName,
                                                lastName: formData?.lastName,
                                                email: formData?.email,
                                                countryCode: formData?.countryCode,
                                                phoneNumber: formData?.phoneNumber,
                                              }
                                              setTenantDetails(values)

                                              if (pm?.payment?._id) {
                                                if (pm?.isManipulated) {
                                                  setDataToEdit(pm)
                                                  setIndexToEdit(ind)
                                                  setEditIncomeModalDraft(true)
                                                } else {
                                                  ApiGet(
                                                    `corporate/payment/${pm?.payment?._id}?isCloneRecord=true`
                                                  )
                                                    .then(async (res) => {
                                                      setPrevRecId(
                                                        res?.data?.data?.payment_receipt?.[0]?._id
                                                      )
                                                      setPrevInvId(
                                                        res?.data?.data?.payment_invoice?.[0]?._id
                                                      )
                                                      setDataToEdit(res?.data?.data)
                                                      setIndexToEdit(ind)
                                                      setEditIncomeModalDraft(true)
                                                    })
                                                    .catch((err) => {
                                                      ErrorToast(err?.message)
                                                      console.log('err', err)
                                                    })
                                                }
                                              } else {
                                                setDataToEdit(pm)
                                                setIndexToEdit(ind)
                                                setEditIncomeModalDraft(true)
                                              }
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                            <table
                              className='table align-start table-row-dashed fs-6 gy-5'
                              id='kt_ecommerce_sales_table'
                            >
                              <thead>
                                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                  <th className='min-w-100px'>Payment</th>
                                  <th className='min-w-100px'>Amount</th>
                                  <th className='min-w-100px'>Status</th>
                                  <th className='min-w-100px'>Due</th>
                                  <th className='min-w-100px'>Created At</th>
                                  <th className='min-w-150px'>Amount Paid</th>
                                  <th className='min-w-150px'>Date Paid</th>
                                  <th className='min-w-100px'></th>
                                </tr>
                              </thead>
                              <tbody className='fw-semibold text-gray-600'>
                                <tr>
                                  <td colSpan={10} className='text-center'>
                                    <img src={noData} alt='' width={350} />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                {/* Onboarding Tab */}
                {propertiType === 'onboard' && (
                  <div className='px-1'>
                    <div
                      className='card card-flush py-5  mt-5 px-0 mx-2'
                      style={{boxShadow: 'lightgrey 0px 1px 4px 4px'}}
                    >
                      <div className='row mx-1'>
                        <div className='col-12 px-5 mb-3'>
                          <div className='d-flex'>
                            <h1 className='top ps-2'>
                              <b>Review Tenancy</b>
                            </h1>
                            <div className=' d-flex ms-auto'>
                              {/* <div className='me-5'>
                              <div
                                className={
                                  flg == '4'
                                    ? 'status  no-st-bar ms-5'
                                    : tenancyData?.tenancyStatus == 0
                                    ? 'status booked-bar ms-5'
                                    : tenancyData?.tenancyStatus == 1
                                    ? 'status active-bar ms-5'
                                    : tenancyData?.tenancyStatus == 2
                                    ? 'status expiring-bar ms-5'
                                    : tenancyData?.tenancyStatus == 3
                                    ? 'status expired-bar ms-5'
                                    : 'status  no-st-bar ms-5'
                                }
                              >
                                {flg == '4'
                                  ? '-'
                                  : tenancyData?.tenancyStatus == 0
                                  ? 'Booked'
                                  : tenancyData?.tenancyStatus == 1
                                  ? 'Active'
                                  : tenancyData?.tenancyStatus == 2
                                  ? 'Expiring'
                                  : tenancyData?.tenancyStatus == 3
                                  ? 'Expired'
                                  : '-'}
                              </div>
                              <i>
                                {' '}
                                {tenancyData?.tenancyStatusDateTime
                                  ? `${moment(tenancyData?.tenancyStatusDateTime).format(
                                      'DD.MM.YYYY - HH:mm A'
                                    )} `
                                  : ''}
                              </i>
                            </div> */}
                              <div>
                                {tenancyData?.isDraft ? (
                                  <>
                                    <div
                                      className={
                                        flg == '4'
                                          ? 'status draft-st ms-5'
                                          : tenancyData?.isDraft
                                          ? 'status draft-st ms-5'
                                          : 'status create-st ms-5'
                                      }
                                    >
                                      {tenancyData?.isDraft ? 'Draft' : 'Created'}
                                    </div>
                                    <i>
                                      {' '}
                                      {tenancyData?.draftStatusDateTime
                                        ? `${moment(tenancyData?.draftStatusDateTime).format(
                                            'DD.MM.YYYY - HH:mm A'
                                          )} `
                                        : ''}
                                    </i>
                                  </>
                                ) : (
                                  <div className='status not-saved'>-</div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Property header */}
                      <div className='row mx-1' style={{height: '0px'}}>
                        <div className='col-7 px-5'></div>
                        <div className='col-5'>
                          <div
                            className={
                              hide
                                ? 'card card-flush py-5 container-xxl mt-2  min-res pe-5'
                                : 'card card-flush py-5 container-xxl mt-2 min-card pe-5'
                            }
                            style={{position: 'relative', zIndex: '10'}}
                          >
                            <div
                              className='d-flex  align-items-center mt-2'
                              style={{height: '25px'}}
                            >
                              <h2 className='ms-3' style={{fontWeight: '700'}}>
                                Property
                              </h2>
                              <div className='ms-auto'>
                                <img
                                  src={
                                    unitData?.unitType === 'town_house'
                                      ? townhouse
                                      : unitData?.unitType === 'other'
                                      ? other
                                      : unitData?.unitType === 'common_area'
                                      ? common_area
                                      : unitData?.unitType === 'villa'
                                      ? villa
                                      : unitData?.unitType === 'apartment'
                                      ? appartment
                                      : unitData?.unitType === 'penthouse'
                                      ? penthouse
                                      : ''
                                  }
                                  height={40}
                                  className='mb-2'
                                />
                              </div>
                            </div>

                            {unitData && (
                              <>
                                {!sendError && (
                                  <>
                                    <div className='row d-flex me-5 mb-5 mt-5 px-0 ms-0'>
                                      {unitData?.communityId && (
                                        <div className='col-12 px-4'>
                                          <h4 className='my-2' style={{color: 'black'}}>
                                            <label
                                              style={{minWidth: '100px'}}
                                              className='labl-gry me-3'
                                            >
                                              {' '}
                                              Development{' '}
                                            </label>
                                            <span className='ms-5 ps-1'>
                                              <b>
                                                {unitData?.communityId
                                                  ? unitData?.community?.[0]?.name
                                                  : '-'}
                                              </b>
                                            </span>
                                          </h4>
                                        </div>
                                      )}
                                      {unitData?.communityId == null && unitData?.buildingId && (
                                        <div className='col-12 px-4'>
                                          <h4 className='my-2' style={{color: 'black'}}>
                                            <label
                                              style={{minWidth: '100px'}}
                                              className='labl-gry me-3'
                                            >
                                              {' '}
                                              Development{' '}
                                            </label>
                                            <span className='ms-5 ps-1'>
                                              <b>
                                                {unitData?.buildingId
                                                  ? unitData?.building?.[0]?.name
                                                  : '-'}
                                              </b>
                                            </span>
                                          </h4>
                                        </div>
                                      )}

                                      <div className='col-12 px-4'>
                                        <h4 className='my-2' style={{color: 'black'}}>
                                          <label
                                            style={{minWidth: '100px'}}
                                            className='labl-gry me-3'
                                          >
                                            {' '}
                                            Unit No{' '}
                                          </label>
                                          <span className='ms-5 ps-1'>
                                            <b>{unitData?.unitNo ? unitData?.unitNo : '-'}</b>
                                          </span>
                                        </h4>
                                      </div>

                                      {hide !== true && (
                                        <>
                                          {unitData?.clusterId && (
                                            <div className='col-12 px-4'>
                                              <h4 className='my-2' style={{color: 'black'}}>
                                                <label
                                                  style={{minWidth: '100px'}}
                                                  className='labl-gry me-3'
                                                >
                                                  {' '}
                                                  Cluster{' '}
                                                </label>
                                                <span className='ms-5 ps-1'>
                                                  <b>
                                                    {unitData?.clusterId
                                                      ? unitData?.cluster?.[0]?.name
                                                      : unitData?.building?.[0]?.name}
                                                  </b>
                                                </span>
                                              </h4>
                                            </div>
                                          )}

                                          {unitData?.unitGroupId && (
                                            <div className='col-12 px-4'>
                                              <h4 className='my-2' style={{color: 'black'}}>
                                                <label
                                                  style={{minWidth: '100px'}}
                                                  className='labl-gry me-3'
                                                >
                                                  {' '}
                                                  Unit No{' '}
                                                </label>
                                                <span className='ms-5 ps-1'>
                                                  <b>
                                                    {unitData?.unitGroupId
                                                      ? unitData?.unit_group?.[0]?.name
                                                      : ''}
                                                  </b>
                                                </span>
                                              </h4>
                                            </div>
                                          )}

                                          {unitData?.floorId && (
                                            <div className='col-12 px-4'>
                                              <h4 className='my-2' style={{color: 'black'}}>
                                                <label
                                                  style={{minWidth: '100px'}}
                                                  className='labl-gry me-3'
                                                >
                                                  {' '}
                                                  Floor{' '}
                                                </label>
                                                <span className='ms-5 ps-1'>
                                                  <b>
                                                    {unitData?.floorId
                                                      ? unitData?.floor?.[0]?.name
                                                      : ''}
                                                  </b>
                                                </span>
                                              </h4>
                                            </div>
                                          )}

                                          <div className='col-12 px-4'>
                                            <h4 className='my-2' style={{color: 'black'}}>
                                              <label
                                                style={{minWidth: '100px'}}
                                                className='labl-gry me-3'
                                              >
                                                {' '}
                                                Type{' '}
                                              </label>
                                              <span className='ms-5 ps-0'>
                                                <b>
                                                  {' '}
                                                  {unitData?.unitType === 'town_house'
                                                    ? 'TownHouse'
                                                    : unitData?.unitType === 'other'
                                                    ? 'Other'
                                                    : unitData?.unitType === 'common_area'
                                                    ? 'Common Area'
                                                    : unitData?.unitType === 'villa'
                                                    ? 'Villa'
                                                    : unitData?.unitType === 'apartment'
                                                    ? 'Apartment'
                                                    : unitData?.unitType === 'penthouse'
                                                    ? 'Penthouse'
                                                    : '-'}
                                                </b>
                                              </span>
                                            </h4>
                                          </div>

                                          <div className='col-12 px-4'>
                                            <h4 className='my-2' style={{color: 'black'}}>
                                              <label
                                                style={{minWidth: '100px'}}
                                                className='labl-gry me-3'
                                              >
                                                {' '}
                                                Bedroom{' '}
                                              </label>
                                              <span className='ms-5 ps-1'>
                                                <b>
                                                  {unitData?.bedrooms ? unitData?.bedrooms : '-'}
                                                </b>
                                              </span>
                                            </h4>
                                          </div>

                                          <div className='col-12 px-4'>
                                            <h4 className='my-2' style={{color: 'black'}}>
                                              <label
                                                style={{minWidth: '100px'}}
                                                className='labl-gry me-3'
                                              >
                                                {' '}
                                                Size{' '}
                                              </label>
                                              <span className='ms-5 ps-1'>
                                                <b>
                                                  {unitData?.size ? `${unitData?.size} SqFt` : '-'}
                                                </b>
                                              </span>
                                            </h4>
                                          </div>

                                          <div className='col-12 px-4'>
                                            <h4 className='my-2 mb-0' style={{color: 'black'}}>
                                              <label
                                                style={{minWidth: '100px'}}
                                                className='labl-gry me-3'
                                              >
                                                {' '}
                                                Property Manager{' '}
                                              </label>
                                              <span className='ms-5 ps-1'>
                                                <b>
                                                  {unitData?.managerId ? unitData?.managerId : '-'}
                                                </b>
                                              </span>
                                            </h4>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </>
                            )}

                            <div className='ms-auto mt-auto' style={{height: '0px'}}>
                              <div
                                className='br-arr d-flex align-items-center justify-content-center'
                                style={{transform: 'translate(0px, -25px)'}}
                              >
                                {hide === false ? (
                                  <img
                                    src={upGreen}
                                    height={20}
                                    width={20}
                                    className='cursor-pointer'
                                    onClick={() => {
                                      if (!sendError) setHide(true)
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={downGreen}
                                    height={20}
                                    width={20}
                                    className='cursor-pointer'
                                    onClick={() => {
                                      if (!sendError) setHide(false)
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Main Tenant Details */}
                      <>
                        <div
                          className='row mx-1'
                          style={sendError ? {opacity: '0.4'} : {opacity: '1'}}
                        >
                          <div className='col-7 ps-5'>
                            <h2 className='top ps-2'>
                              <b>Main Tenant Details</b>
                            </h2>
                          </div>

                          <div className='row mx-3 mt-3'>
                            <div className='col-7 px-5'>
                              <div className='d-flex'>
                                <h4 className='my-2' style={{color: 'black'}}>
                                  <label style={{width: '100px'}} className='labl-gry'>
                                    {' '}
                                    First Name{' '}
                                  </label>
                                  <span className='ms-5 ps-1'>
                                    <b>{formData?.firstName ? formData?.firstName : ''}</b>
                                  </span>
                                </h4>
                              </div>
                            </div>
                            <div className='col-7 px-5'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  Last Name{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.lastName ? formData?.lastName : ''}</b>
                                </span>
                              </h4>
                            </div>
                            <div className='col-7 px-5'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  Passport No.{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.passportNo ? formData?.passportNo : ''}</b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-7 px-5'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  ID No.{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.idNo ? formData?.idNo : ''}</b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-7 px-5'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  DOB{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>
                                    {formData?.DOB
                                      ? moment(formData?.DOB && formData?.DOB.split('T')[0]).format(
                                          'DD/MM/YYYY'
                                        )
                                      : ''}
                                  </b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-7 px-5'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  Nationality{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.nationality ? formData?.nationality : ''}</b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-7 px-5'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  Email{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>{formData?.email ? formData?.email : ''}</b>
                                </span>
                              </h4>
                            </div>

                            <div className='col-7 px-5'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  Mobile No.{' '}
                                </label>
                                <span className='ms-5 ps-1'>
                                  <b>
                                    {formData?.phoneNumber
                                      ? `${formData?.countryCode} ${formData?.phoneNumber}`
                                      : ''}
                                  </b>
                                </span>
                              </h4>
                            </div>
                          </div>

                          <div className='row mx-1 mt-5'>
                            <div className='col-7 mt-5'>
                              <div className='d-flex  align-items-center'>
                                <h3 className='ms-0' style={{fontWeight: '700'}}>
                                  Documents
                                </h3>
                              </div>
                            </div>

                            {tenantDocuments?.map((doc: any, index: any) => {
                              return (
                                <>
                                  {doc.isCheck && (
                                    <>
                                      <div className='col-7 ps-3 pe-3 mt-5 pt-3'>
                                        <div className='d-flex  align-items-center'>
                                          <h4 className='' style={{fontWeight: '700'}}>
                                            {doc?.name}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className='col-12 px-4 mt-5'>
                                        {doc?.fileList?.length > 0 ? (
                                          <div
                                            className='tops d-flex gap-5 align-items-start mx-5'
                                            style={{overflow: 'auto', overflowY: 'hidden'}}
                                          >
                                            {doc?.fileList?.map((file: any, ind: any) => {
                                              return (
                                                <>
                                                  {file.isChecked && (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='100'
                                                          height='100'
                                                          className='main_img mb-5 cursor-pointer'
                                                          id={`clicked${doc?.name}${ind}`}
                                                              onClick={() =>
                                                                handleImageClick(
                                                                  `clicked${doc?.name}${ind}`,
                                                                  `${Bucket}${file?.fileURL}`,
                                                                  `${doc?.name}`,
                                                                  doc?.fileList,
                                                                  'Onboard'
                                                                )
                                                              }
                                                        />
                                                        {/* <img
                                                        src={redCross}
                                                        height={15}
                                                        width={15}
                                                        className='cursor-pointer ms-2 mt-1'
                                                        onClick={() => {
                                                          const values = [...tenantDocuments]
                                                          console.log(values)
                                                          values[index].fileList[ind].isChecked =
                                                            false
                                                          setTenantDocuments(values)
                                                        }}
                                                      /> */}
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            })}
                                          </div>
                                        ) : (
                                          <div className='d-flex justify-content-center'>
                                            {' '}
                                            <div className=''>
                                              {' '}
                                              {/* <h2 className='head-text'>No Files...</h2> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            })}

                            {tenantDeletedDocuments?.map((doc: any, index: any) => {
                              return (
                                <>
                                  {doc.isCheck && (
                                    <>
                                      <div className='col-12 ps-4 pe-3 mt-4'>
                                        <div className='d-flex  align-items-center'>
                                          <h4 className='' style={{fontWeight: '700'}}>
                                            {doc?.name}
                                          </h4>
                                        </div>
                                      </div>

                                      <div className='col-12 px-4 mt-4'>
                                        {doc?.fileList?.length > 0 ? (
                                          <div
                                            className='tops d-flex gap-5 align-items-start mx-5'
                                            style={{overflow: 'auto', overflowY: 'hidden'}}
                                          >
                                            {doc?.fileList?.map((file: any, ind: any) => {
                                              return (
                                                <>
                                                  {file.isChecked && (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='100'
                                                          height='100'
                                                          className='main_img mb-5 cursor-pointer'
                                                          id={`clicked${doc?.name}${ind}`}
                                                              onClick={() =>
                                                                handleImageClick(
                                                                  `clicked${doc?.name}${ind}`,
                                                                  `${Bucket}${file?.fileURL}`,
                                                                  `${doc?.name}`,
                                                                  doc?.fileList,
                                                                  'OnboardTenantDeletedDoc'
                                                                )
                                                              }
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            })}
                                          </div>
                                        ) : (
                                          <div className='d-flex justify-content-center'>
                                            {' '}
                                            <div className=''>
                                              {' '}
                                              {/* <h2 className='head-text'>No Files...</h2> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            })}

                            {sendError && (
                              <>
                                <div className='col-7 ps-3 pe-3 mt-5 pt-3'>
                                  <div className='d-flex  align-items-center'>
                                    <h4 className='' style={{fontWeight: '700'}}>
                                      Passport
                                    </h4>
                                  </div>
                                </div>

                                <div className='col-7 ps-3 pe-3 mt-5 pt-3'>
                                  <div className='d-flex  align-items-center'>
                                    <h4 className='' style={{fontWeight: '700'}}>
                                      Residency
                                    </h4>
                                  </div>
                                </div>

                                <div className='col-7 ps-3 pe-3 mt-5 pt-3'>
                                  <div className='d-flex  align-items-center'>
                                    <h4 className='' style={{fontWeight: '700'}}>
                                      ID
                                    </h4>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>

                          {/* Sub Tenants */}
                          {subTenantData?.length > 0 && (
                            <div className='row mx-0 mt-5 pt-4'>
                              <div className='col-12 ps-4 pe-0 mt-3'>
                                <div className='d-flex  align-items-center'>
                                  <h2 className='ms-0' style={{fontWeight: '700'}}>
                                    Sub Tenants
                                  </h2>
                                </div>
                              </div>
                            </div>
                          )}

                          {subTenantData?.length > 0 && (
                            <div className='row mx-0'>
                              <div className='col-12 ps-5 pe-0 '>
                                <div className='table-responsive '>
                                  <table
                                    className='table align-middle  fs-6 gy-5'
                                    id='kt_ecommerce_sales_table'
                                  >
                                    <thead>
                                      <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                        <th className='text-start min-w-100px'>First Name</th>
                                        <th className='text-start min-w-100px'>Last Name</th>
                                        <th className='text-start min-w-100px'>Passport No.</th>
                                        <th className='text-start min-w-100px'>ID No.</th>
                                        <th className='text-start min-w-150px'>Email </th>
                                        <th className='text-start min-w-100px'>Mobile No.</th>
                                      </tr>
                                    </thead>

                                    <tbody className='fw-semibold text-gray-600'>
                                      {subTenantData?.length ? (
                                        subTenantData.map((sb: any, i: any) => {
                                          return (
                                            <>
                                              {sb?.id != '' && (
                                                <tr className='mt-2'>
                                                  <td className='text-start min-w-100px'>
                                                    {`${sb?.firstName}` ? `${sb?.firstName}` : '-'}
                                                  </td>
                                                  <td className='text-start min-w-100px'>
                                                    {`${sb?.lastName}` ? `${sb?.lastName}` : '-'}
                                                  </td>
                                                  <td className='text-start min-w-100px'>
                                                    {`${
                                                      sb?.passportNo != undefined &&
                                                      sb?.passportNo != null
                                                    }`
                                                      ? `${sb?.passportNo}`
                                                      : '-'}
                                                  </td>
                                                  <td className='text-start min-w-100px'>
                                                    {`${sb?.idNo != undefined && sb?.idNo != null}`
                                                      ? `${sb?.idNo}`
                                                      : '-'}
                                                  </td>
                                                  <td className='text-start min-w-150px'>
                                                    {`${sb?.email}` ? `${sb?.email}` : '-'}{' '}
                                                  </td>
                                                  <td className='text-start min-w-100px'>
                                                    {`${sb?.phoneNumber}}`
                                                      ? `${sb?.countryCode} ${sb?.phoneNumber}`
                                                      : '-'}
                                                  </td>
                                                </tr>
                                              )}
                                            </>
                                          )
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>

                      {/* Contract Details */}
                      <>
                        <div
                          className='row mx-4 mt-5 pt-4'
                          style={sendError ? {opacity: '0.4'} : {opacity: '1'}}
                        >
                          <div className='col-7 px-4 mt-4'>
                            <h2 className='top ps-0'>
                              <b>Contract Details</b>
                            </h2>
                          </div>

                          <div className='col-7 px-5 mt-4'>
                            <div className='d-flex '>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  Contract No.{' '}
                                </label>

                                <span className='ms-1'>
                                  <b>{tenancyData?.contractNo ? tenancyData?.contractNo : ''}</b>
                                </span>
                              </h4>
                            </div>
                          </div>

                          <div className='col-7 px-5 mt-4 mb-2'>
                            <div className='d-flex'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  Term{' '}
                                </label>

                                <span className=''>
                                  {start_date != null || end_date != null ? (
                                    <b>
                                      {' '}
                                      {start_date != null || end_date != null
                                        ? `${start_date} - ${end_date} `
                                        : '-'}
                                    </b>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              </h4>
                            </div>
                          </div>

                          <div className='col-7 px-5 mt-3 mb-2'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '100px'}} className='labl-gry'>
                                {' '}
                                Duration
                              </label>
                              <span className=' ps-1'>
                                <b>{totalDays}</b>
                              </span>
                            </h4>
                          </div>

                          <div className='col-7 px-5 my-3 mb-2'>
                            <h4 className='my-2' style={{color: 'black'}}>
                              <label style={{width: '100px'}} className='labl-gry'>
                                {' '}
                                Remaining
                              </label>
                              <span className='ps-1'>
                                <b>{remainingDays}</b>
                              </span>
                            </h4>
                          </div>

                          <div className='col-7 px-5 mt-5'>
                            <div className='d-flex align-items-center'>
                              <h4 className='my-2' style={{color: 'black'}}>
                                <label style={{width: '100px'}} className='labl-gry'>
                                  {' '}
                                  Renewal{' '}
                                </label>
                              </h4>
                              <span className=''>
                                <b>{tenancyData?.renewalDays ? tenancyData?.renewalDDays : '-'}</b>
                              </span>

                              <span>
                                {' '}
                                <i className='mt-2 ms-2'> Days before term end</i>
                              </span>
                            </div>
                          </div>

                          <div className='col-7 ps-5 mt-5'>
                            <div className='d-flex'>
                              <div className='d-flex'>
                                <label
                                  style={{width: '100px'}}
                                  className='labl-gry form-label'
                                  htmlFor='exampleFormControlInput1'
                                >
                                  Benefits
                                </label>
                                <div className='test ps-2'>
                                  {optionArry?.none && (
                                    <div className='d-flex align-items-center'>
                                      <p className='bullet-ic mb-0'></p>
                                      <p
                                        className='head-text ms-3 mb-0'
                                        style={{fontWeight: '600'}}
                                      >
                                        {' '}
                                        None
                                      </p>
                                    </div>
                                  )}

                                  {optionArry?.chillerFree === true && (
                                    <div className='d-flex align-items-center'>
                                      <p className='bullet-ic mb-0'></p>
                                      <p
                                        className='head-text ms-3 mb-0'
                                        style={{fontWeight: '600'}}
                                      >
                                        {' '}
                                        Chiller Free
                                      </p>
                                    </div>
                                  )}

                                  {optionArry?.maintenanceFree === true && (
                                    <div className='d-flex align-items-center'>
                                      <p className='bullet-ic mb-0'></p>
                                      <p
                                        className='head-text ms-3 mb-0'
                                        style={{fontWeight: '600'}}
                                      >
                                        {' '}
                                        Maintenance Free
                                      </p>
                                    </div>
                                  )}
                                  {otherOptions?.length > 0 &&
                                    otherOptions?.map((opt: any, ind: any) => (
                                      <div className='d-flex align-items-center'>
                                        <p className='bullet-ic mb-0'></p>
                                        <p
                                          className='head-text ms-3 mb-0'
                                          style={{fontWeight: '600'}}
                                        >
                                          {' '}
                                          {opt?.value}
                                        </p>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='row mx-1 mt-5'>
                            <div className='col-7 ps-0 mt-5'>
                              <div className='d-flex  align-items-center'>
                                <h3 className='ms-0' style={{fontWeight: '700'}}>
                                  Documents
                                </h3>
                              </div>
                            </div>

                            {contractDocuments?.map((doc: any, index: any) => {
                              return (
                                <>
                                  {doc.isCheck && (
                                    <>
                                      <div className='col-7 ps-0 pe-3 mt-5 pt-2'>
                                        <div className='d-flex  align-items-center'>
                                          <h4 className='' style={{fontWeight: '700'}}>
                                            {doc?.name}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className='col-12 px-2 mt-4'>
                                        {doc?.fileList?.length > 0 ? (
                                          <div
                                            className='tops d-flex gap-5 align-items-start mx-5'
                                            style={{overflow: 'auto', overflowY: 'hidden'}}
                                          >
                                            {doc?.fileList?.map((file: any, ind: any) => {
                                              return (
                                                <>
                                                  {file.isChecked && (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='100'
                                                          height='100'
                                                          className='main_img mb-5 cursor-pointer'
                                                          id={`clicked${doc?.name}${ind}`}
                                                          onClick={() =>
                                                            handleImageClick(
                                                              `clicked${doc?.name}${ind}`,
                                                              `${Bucket}${file?.fileURL}`,
                                                              `${doc?.name}`,
                                                              doc?.fileList,
                                                              'OnboardContactDocs'
                                                            )
                                                          }
                                                        />
                                                        {/* <img
                                                        src={redCross}
                                                        height={15}
                                                        width={15}
                                                        className='cursor-pointer ms-2 mt-1'
                                                        onClick={() => {
                                                          const values = [...contractDocuments]
                                                          console.log(values)
                                                          values[index].fileList[ind].isChecked =
                                                            false
                                                          setContractDocuments(values)
                                                        }}
                                                      /> */}
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            })}
                                          </div>
                                        ) : (
                                          <div className='d-flex justify-content-center'>
                                            {' '}
                                            <div className=''>
                                              {' '}
                                              {/* <h2 className='head-text'>No Files...</h2> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            })}
                            {contractDeletedDocuments?.map((doc: any, index: any) => {
                              return (
                                <>
                                  {doc.isCheck && (
                                    <>
                                      <div className='col-7 ps-0 pe-1 mt-4'>
                                        <div className='d-flex  align-items-center'>
                                          <h4 className='' style={{fontWeight: '700'}}>
                                            {doc?.name}
                                          </h4>
                                        </div>
                                      </div>
                                      <div className='col-5 mt-4 ps-1'></div>
                                      <div className='col-12 px-0 mt-5 pt-4'>
                                        {doc?.fileList?.length > 0 ? (
                                          <div
                                            className='tops d-flex gap-5 align-items-start mx-5'
                                            style={{overflow: 'auto', overflowY: 'hidden'}}
                                          >
                                            {doc?.fileList?.map((file: any, ind: any) => {
                                              return (
                                                <>
                                                  {file.isChecked && (
                                                    <div className='imgs-set'>
                                                      <div className='d-flex mw-350px me-3'>
                                                        <img
                                                          src={Bucket + file?.fileURL}
                                                          width='100'
                                                          height='100'
                                                          className='main_img mb-5'
                                                          id={`clicked${doc?.name}${ind}`}
                                                        onClick={() =>
                                                          handleImageClick(
                                                            `clicked${doc?.name}${ind}`,
                                                            `${Bucket}${file?.fileURL}`,
                                                            `${doc?.name}`,
                                                            doc?.fileList,
                                                            'OnboardContactDeletedDocs'
                                                          )
                                                        }
                                                        />
                                                      </div>
                                                    </div>
                                                  )}
                                                </>
                                              )
                                            })}
                                          </div>
                                        ) : (
                                          <div className='d-flex justify-content-center'>
                                            {' '}
                                            <div className=''>
                                              {' '}
                                              {/* <h2 className='head-text'>No Files...</h2> */}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </>
                              )
                            })}

                            {sendError && (
                              <>
                                <div className='col-7 ps-0 pe-3 mt-5 pt03'>
                                  <div className='d-flex  align-items-center'>
                                    <h4 className='' style={{fontWeight: '700'}}>
                                      Contract
                                    </h4>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>

                      {/* Financials Details */}
                      {isFinance && (
                        <>
                           {incomePaymentPayload?.length > 0 && (
                          <div
                            className='row mx-3 mt-5'
                            style={sendError ? {opacity: '0.4'} : {opacity: '1'}}
                          >
                            <div className='col-7 px-5 mt-5 pt-5'>
                              <h2 className='top'>
                                <b>Financials</b>
                              </h2>
                            </div>
                          </div>
                           )}
                          {incomePaymentPayload?.length > 0 && (
                            <div className='row mx-4 mt-5'>
                              <div className='col-12 px-3'>
                                {incomePaymentPayload?.length > 0 ? (
                                  <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                                    <table
                                      className='table align-start table-row-dashed fs-6 gy-5'
                                      id='kt_ecommerce_sales_table'
                                    >
                                      <thead>
                                        <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                          <th className='min-w-100px'>Payment</th>
                                          <th className='min-w-100px'>Amount</th>
                                          <th className='min-w-100px'>Status</th>
                                          <th className='min-w-100px'>Due</th>
                                          <th className='min-w-100px'>Created At</th>
                                          <th className='min-w-150px'>Amount Paid</th>
                                          <th className='min-w-150px'>Date Paid</th>
                                          <th className='min-w-100px'></th>
                                        </tr>
                                      </thead>
                                      <tbody className='fw-semibold text-gray-600'>
                                        {incomePaymentPayload?.map((pm: any, ind: any) => {
                                          return (
                                            <>
                                              <tr
                                                style={
                                                  show[ind] == true
                                                    ? {borderBottomStyle: 'none', height: '30px'}
                                                    : show[ind] == false
                                                    ? {borderBottomStyle: 'dashed', height: '30px'}
                                                    : pm?.payment_sub_item?.length == 0
                                                    ? {borderBottomStyle: 'dashed', height: '30px'}
                                                    : {height: '30px'}
                                                }
                                              >
                                                <td>
                                                  <div className='d-flex align-items-center'>
                                                    {pm?.payment?.name}{' '}
                                                    {isExisted && (
                                                      <AiOutlineExclamationCircle
                                                        color='#d54645  '
                                                        fontSize={16}
                                                        fontWeight={600}
                                                        className='cursor-pointer mx-3'
                                                        style={{
                                                          cursor: 'pointer',
                                                          marginBottom: '2px',
                                                        }}
                                                      />
                                                    )}
                                                  </div>
                                                </td>

                                                <td>
                                                  {parseFloat(
                                                    pm?.payment?.totalAmount
                                                  )?.toLocaleString('en-US', {
                                                    minimumFractionDigits: 2,
                                                  })}
                                                </td>

                                                <td>
                                                  {'Draft'}
                                                  {/* {pm?.payment?.paymentStatus === 0
                                          ? 'Unpaid'
                                          : pm?.payment?.paymentStatus === 1
                                          ? 'Paid'
                                          : pm?.payment?.paymentStatus === 2
                                          ? 'Partially paid'
                                          : pm?.payment?.paymentStatus === 3
                                          ? 'Void'
                                          : 'Draft'} */}
                                                </td>

                                                <td>
                                                  {pm?.payment?.dueDate
                                                    ? moment(pm?.payment?.dueDate).format(
                                                        'DD.MM.YYYY - hh:mm A'
                                                      )
                                                    : '-'}
                                                </td>
                                                <td>
                                               
                                                  {pm?.payment?.createdAt
                                                    ? moment(pm?.payment?.createdAt).format(
                                                        'DD.MM.YYYY - hh:mm A'
                                                      )
                                                    : '-'}
                                                </td>
                                                <td>
                                                  {pm?.payment?.amountPaid?.toLocaleString(
                                                    'en-US',
                                                    {
                                                      minimumFractionDigits: 2,
                                                    }
                                                  )}
                                                </td>
                                                <td>
                                                  {pm?.payment?._id &&
                                                  pm?.isManipulated == undefined ? (
                                                    <>
                                                      {pm?.payment?.datePaid
                                                        ? moment(pm?.payment?.datePaid).format(
                                                            'DD.MM.YYYY - hh:mm A'
                                                          )
                                                        : '-'}
                                                    </>
                                                  ) : (
                                                    <>
                                                      {pm?.new_payment_receipt?.[0]?.datePaid
                                                        ? moment(
                                                            pm?.new_payment_receipt?.[0]?.datePaid
                                                          ).format('DD.MM.YYYY - hh:mm A')
                                                        : '-'}
                                                    </>
                                                  )}
                                                </td>
                                                <td>
                                                  <BsArrowRightSquareFill
                                                    color='#35475E '
                                                    fontSize={18}
                                                    style={{cursor: 'pointer'}}
                                                    className='mx-3 me-4'
                                                    onClick={() => {
                                                      let values = {
                                                        firstName: formData?.firstName,
                                                        lastName: formData?.lastName,
                                                        email: formData?.email,
                                                        countryCode: formData?.countryCode,
                                                        phoneNumber: formData?.phoneNumber,
                                                      }
                                                      setTenantDetails(values)

                                                      if (pm?.payment?._id) {
                                                        if (pm?.isManipulated) {
                                                          setDataToEdit(pm)
                                                          setIndexToEdit(ind)
                                                          setEditIncomeModalDraft(true)
                                                        } else {
                                                          ApiGet(
                                                            `corporate/payment/${pm?.payment?._id}?isCloneRecord=true`
                                                          )
                                                            .then(async (res) => {
                                                              setPrevRecId(
                                                                res?.data?.data
                                                                  ?.payment_receipt?.[0]?._id
                                                              )
                                                              setPrevInvId(
                                                                res?.data?.data
                                                                  ?.payment_invoice?.[0]?._id
                                                              )
                                                              setDataToEdit(res?.data?.data)
                                                              setIndexToEdit(ind)
                                                              setEditIncomeModalDraft(true)
                                                            })
                                                            .catch((err) => {
                                                              ErrorToast(err?.message)
                                                              console.log('err', err)
                                                            })
                                                        }
                                                      } else {
                                                        setDataToEdit(pm)
                                                        setIndexToEdit(ind)
                                                        setEditIncomeModalDraft(true)
                                                      }
                                                    }}
                                                  />
                                                </td>
                                              </tr>
                                            </>
                                          )
                                        })}
                                      </tbody>
                                    </table>
                                  </div>
                                ) : (
                                  <div className='card-body pt-0 table-responsive mt-1 pb-4 px-0'>
                                    <table
                                      className='table align-start table-row-dashed fs-6 gy-5'
                                      id='kt_ecommerce_sales_table'
                                    >
                                      <thead>
                                        <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                                          <th className='min-w-100px'>Payment</th>
                                          <th className='min-w-100px'>Amount</th>
                                          <th className='min-w-100px'>Status</th>
                                          <th className='min-w-100px'>Due</th>
                                          <th className='min-w-100px'>Created At</th>
                                          <th className='min-w-150px'>Amount Paid</th>
                                          <th className='min-w-150px'>Date Paid</th>
                                          <th className='min-w-100px'></th>
                                        </tr>
                                      </thead>
                                      <tbody className='fw-semibold text-gray-600'>
                                        <tr>
                                          <td colSpan={10} className='text-center'>
                                            <img src={noData} alt='' width={350} />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}
      <Modal show={showImageName} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUploadFile?.files.map((el: any, i: any) => (
            <>
              <label htmlFor='exampleFormControlInput1' className='required form-label m-0'>
                Input File Name
              </label>
              <input
                type='text'
                className='form-control form-control-solid'
                onChange={(e: any) => uploadImageName(e, el, i)}
              />
              {fileFound ? (
                <p className='mt-2' style={{color: 'red'}}>
                  Filename Exist already
                </p>
              ) : null}
            </>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='primary'
            onClick={saveImageFileName}
            className='btn btn-sm fw-bold btn-primary btn-green mx-3'
            disabled={modalBtnIsLoading}
          >
            Save {''}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Property Management '})}</PageTitle>
      <DashboardPage /> */}
      <TenanciesFilter
        show={showCreateAppModal}
        handleClose={() => {
          setShowCreateAppModal(false)
        }}
        // type={type}
        showModal={showModal}
        // building={building}
        // updateDataId={updateDataId}
        // isEdit={isEdit}
        // formData={formData}
        // propertiType={propertiType}
        // sethandleSubmitIdv={sethandleSubmitIdv}
        handleSubmitId={handleSubmitId}
        // handleChnage={handleChnage}
        // imageChange={imageChange}
        unitData={unitData._id}
      />

      {incomeModal && (
        <IncomingPaymentModal
          show={incomeModal}
          handleClose={() => setIncomeModal(false)}
          paymentItems={filteredPaymentItems}
          tenantDetails={tenantDetails}
          handlePayload={updateIncomePaymentPayload}
          allPayments={() => getAllPayments()}
          unitDetails={unitData}
          tenancyId={flg == '1' ? newTenancyID : tenancyId}
          from={'renew'}
          otherFinancials={otherFinancials}
        />
      )}

      {/* Edit Payment Modal */}
      {editIncomeModal && (
        <EditPaymentModal
          show={editIncomeModal}
          handleClose={() => {
            setEditIncomeModal(false)
          }}
          incomingPaymentId={''}
          id={''}
          subItemId={''}
          subId={''}
          getAllPayments={getAllPayments}
          unitDetails={unitDetails}
          detailsToEdit={dataToEdit}
          type={'tenancy'}
        />
      )}

      {editIncomeModalDraft && (
        <EditPaymentModalDraft
          show={editIncomeModalDraft}
          handleClose={() => {
            setEditIncomeModalDraft(false)
          }}
          paymentItems={filteredPaymentItems}
          tenantDetails={tenantDetails}
          handlePayload={updateIncomePaymentPayload}
          allPayments={() => getAllPayments()}
          unitDetails={unitData}
          tenancyId={flg == '1' ? newTenancyID : tenancyId}
          detailsToEdit={dataToEdit}
          indexToUpdate={indexToEdit}
          otherFinancials={otherFinancials}
          from={'renew'}
        />
      )}

      {/* Select For Files instead of upload */}
      {/* <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered mw-900px mx-auto'
        show={folderFileModal}
        onHide={() => {
          setFolderFileModal(false)
        }}
        backdrop={true}
        size='sm'
      >
        <div
          className='modal-body py-lg-10 px-lg-6  pt-lg-3'
          style={{
            transform: 'translate(0px, -70px)',
            // border: '3px solid #384a62',
            top: '70px',
            borderRadius: '5px',
            boxShadow: '0px 0px 20px 0px #516176',
            // minHeight: '150px',
          }}
        >
          <div className='my-1 pb-1 row'>
            <div className='col-md-12 text-end'>
              <label
                className='head-text fs-5 cursor-pointer'
                style={{fontWeight: '700'}}
                onClick={() => {
                  setFolderFileModal(false)
                }}
              >
                X
              </label>
            </div>
          </div>

          {showBck && (
            <div className='d-flex align-items-center mb-3'>
              <span
                className='rounded-circle'
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setFileType('other')
                  setShowBck(false)
                }}
              >
                <img src={backArrow} style={{stroke: 'red'}} height='14' width='14' />
              </span>
              <h2 className='m-0 head-text ms-2'>{selectedFolder?.name}</h2>
            </div>
          )}

          {fileType == 'other' ? (
            <>
              {tenantFolders?.length > 0 ? (
                <>
                  <div className='d-flex'>
                    {tenantFolders?.map((folder: any) => {
                      return (
                        <a
                          onClick={() => {}}
                          className='text-gray-800 text-hover-primary d-flex flex-column mx-5 text-center'
                        >
                          <div
                            className='symbol symbol-60px mb-5'
                            onClick={() => {
                              if (folder?.name == 'Passports') {
                                setDataToSelectFrom(passportFolder)
                                setFileType('passport')
                              } else if (folder?.name == 'Contracts') {
                                setDataToSelectFrom(contractsFolder)
                                setFileType('contract')
                              } else if (folder?.name == 'IDs') {
                                setDataToSelectFrom(iDsFolder)
                                setFileType('ids')
                              } else if (folder?.name == 'Residencies') {
                                setDataToSelectFrom(residencyFolder)
                                setFileType('residency')
                              }
                              setSelectedFolder(folder)
                              setShowBck(true)
                            }}
                          >
                            <img src={folderDocument} className='theme-light-show' alt='' />
                            <img
                              src='/metronic8/demo1/assets/media/svg/files/pdf-dark.svg'
                              className='theme-dark-show'
                              alt=''
                            />
                          </div>
                          <div className='fs-5 fw-bold mb-2'>{folder?.name}</div>
                        </a>
                      )
                    })}
                  </div>
                </>
              ) : (
                <>
                  <div className='d-flex justify-content-center'>
                    {' '}
                    <div className=''>
                      {' '}
                      <h2 className='head-text'>No Files...</h2>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              {dataToSelectFrom?.fileList?.length > 0 ? (
                <div
                  className='tops  d-flex gap-5 align-items-start mx-5'
                  style={{overflow: 'scroll'}}
                >
                  {dataToSelectFrom?.fileList?.map((file: any, ind: any) => {
                    return (
                      <div className='imgs-set'>
                        <div className='d-flex mw-350px me-3'>
                          <img
                            src={Bucket + file?.fileURL}
                            width='100'
                            height='100'
                            className='main_img'
                            onClick={() => {
                              if (showBck) {
                                setuploadOtherImgTenant([...uploadOtherImgTenant, file?.fileURL])
                              } else {
                                if (fileType == 'passport') setuploadPassportTenant([file?.fileURL])
                                else if (fileType == 'contract')
                                  setuploadContractTenancy([file?.fileURL])
                                else if (fileType == 'residency')
                                  setuploadresidencyTenant([file?.fileURL])
                                else if (fileType == 'ids') setuploadIdTenant([file?.fileURL])
                              }

                              setIsFormDirty(true)

                              setFolderFileModal(false)

                              // setTimeout(() => {
                              //   autoSaveTenancy()
                              // }, 2500)
                            }}
                          />
                        </div>
                        <p className=''>
                          {getFilenameToSelect(file?.fileName, false)?.length > 6
                            ? `${file?.fileName?.slice(0, 6)}...${getFilenameToSelect(
                                file?.fileName,
                                true
                              )}`
                            : file?.fileName}
                        </p>
                      </div>
                    )
                  })}
                </div>
              ) : (
                <div className='d-flex justify-content-center'>
                  {' '}
                  <div className=''>
                    {' '}
                    <h2 className='head-text'>No Files...</h2>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Modal> */}

      {/* Add New tenant Modal */}
      {addNewTenant && (
        <AddNewTenant
          show={AddNewTenant}
          handleClose={() => setAddNewTenant(false)}
          refetchTenants={() => getTenantData()}
          onSuccess={(val: any) => {
            if (from == 'main') {
              updateTenantDropdown(val?._id)
            } else {
              let temp = [...subTenantData]
              setIsFormDirty(true)
              temp[subTenantData?.length] = {
                firstName: val?.firstName,
                lastName: val?.lastName,
                phoneNumber: val?.phoneNumber,
                countryCode: val?.countryCode,
                email: val?.email,
                passportNo: val?.passportNo,
                idNo: val?.idNo,
                id: val?._id,
              }
              setSubTenantData(temp)
              setSubTenantIds([...subTenantIds, val?._id])
              if (flg == '2') setOpen(true)
              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 1000)
            }
          }}
        />
      )}

      {/* Edit tenant Modal */}
      {editTenantModal && (
        <EditTenant
          show={AddNewTenant}
          handleClose={() => setEditTenantModal(false)}
          refetchTenants={() => getTenantData()}
          tenant={tenantIdToView}
          onSuccess={(val: any) => {
            if (from == 'main') {
              console.log('val')
              console.log(val)
              setNewTenantID(val?._id)
              setTenantIdForFolder(val?._id)
              setIsFormDirty(true)
              let temp = val?.firstName + val?.lastName
              setSelectedTenantName(temp)
              setFormData(val)

              let values = [...contractDocuments]

              //  Contract Documents
              for (let i = 0; i < val?.tenant_folder?.length; i++) {
                for (let m = 0; m < values?.length; m++) {
                  if (val?.tenant_folder[i]?._id == values[m]?._id) {
                    for (let n = 0; n < values[m]?.fileList?.length; n++) {
                      let isDel: any = true
                      let delInd: any = 0
                      for (let j = 0; j < val?.tenant_folder?.[i]?.fileList?.length; j++) {
                        if (
                          values[m]?.fileList[n]?._id == val?.tenant_folder?.[i]?.fileList[j]?._id
                        ) {
                          isDel = false
                          n = val?.tenant_folder?.[i]?.fileList?.length
                        } else {
                          isDel = true
                          delInd = n
                        }
                      }

                      if (isDel) {
                        values[m].fileList.splice(delInd, 1)
                        setContractDocuments(values)
                      }
                    }
                  }
                }
              }

              let values1 = [...tenantDocuments]

              //  Tenant Documents
              for (let i = 0; i < val?.tenant_folder?.length; i++) {
                for (let m = 0; m < values1?.length; m++) {
                  if (val?.tenant_folder[i]?._id == values1[m]?._id) {
                    for (let n = 0; n < values1[m]?.fileList?.length; n++) {
                      let isDel: any = true
                      let delInd: any = 0
                      for (let j = 0; j < val?.tenant_folder?.[i]?.fileList?.length; j++) {
                        if (
                          values1[m]?.fileList[n]?._id == val?.tenant_folder?.[i]?.fileList[j]?._id
                        ) {
                          isDel = false
                          n = val?.tenant_folder?.[i]?.fileList?.length
                        } else {
                          isDel = true
                          delInd = n
                        }
                      }

                      if (isDel) {
                        values1[m].fileList.splice(delInd, 1)
                        setTenantDocuments(values1)
                      }
                    }
                  }
                }
              }
            } else {
              let temp = [...subTenantData]
              setIsFormDirty(true)
              temp[subInd] = {
                firstName: val?.firstName,
                lastName: val?.lastName,
                phoneNumber: val?.phoneNumber,
                countryCode: val?.countryCode,
                email: val?.email,
                passportNo: val?.passportNo,
                idNo: val?.idNo,
                id: val?._id,
              }
              setSubTenantData(temp)
              setSubTenantIds([...subTenantIds, val?._id])
            }
            setEditTenantModal(false)
          }}
        />
      )}

      {/* Add Folder Modal */}
      {tenantFolderModal && (
        <TenantFolders
          show={tenantFolderModal}
          handleClose={(val: any, st: any) => {
            setTenantFolderModal(false)
            // if (flg == '1') {
            if (st) {
              if (from == 'tenant') setTenantDocuments(val)
              else setContractDocuments(val)
              if (flg == '2') setOpen(true)
              setTimeout(() => {
                setAutoSaveData(Date.now())
              }, 1000)
            } else {
              let temp = localStorage.getItem('contractCopy')
              if (temp != null) {
                let copy = JSON.parse(temp)
                setContractDocuments(copy)
              }

              let temp2 = localStorage.getItem('tenantCopy')
              if (temp2 != null) {
                let copy2 = JSON.parse(temp2)
                setTenantDocuments(copy2)
              }
            }
            // } else getTenancyData(tenancyId)
          }}
          refetchTenants={() => getTenantData()}
          tenant={tenantIdForFolder}
          tentFolder={from == 'tenant' ? tenantDocuments : contractDocuments}
          // tentFolder={tenantDocuments}
          contFolder={from == 'tenant' ? tenantDocuments : contractDocuments}
          from={from}
          onSuccess={(val: any) => {
            if (from == 'tenant') setTenantDocuments(val)
            else setContractDocuments(val)
            setTenantFolderModal(false)
            if (flg == '2') setOpen(true)
            setTimeout(() => {
              setAutoSaveData(Date.now())
            }, 1000)
          }}
        />
      )}

      {/* Add File Modal */}
      {tenantFileModal && (
        <SingleFileModal
          show={tenantFileModal}
          handleClose={(val: any, st: any) => {
            setTenantFileModal(false)
            console.log(val)
            // if (flg == '1') {
            if (st) {
              if (from == 'tenant') setTenantDocuments(val)
              else setContractDocuments(val)
            }
            if (flg == '2') setOpen(true)
            setTimeout(() => {
              setAutoSaveData(Date.now())
            }, 1000)
            // } else getTenancyData(tenancyId)
          }}
          refetchTenants={() => getTenantData()}
          tenant={tenantIdForFolder}
          tentFolder={from == 'tenant' ? tenantDocuments : contractDocuments}
          targetFolder={targetFolder}
          contFolder={contractDocuments}
          from={from}
          onSuccess={(val: any) => {
            if (from == 'tenant') setTenantDocuments(val)
            else setContractDocuments(val)
            if (flg == '2') setOpen(true)
            setTimeout(() => {
              setAutoSaveData(Date.now())
            }, 1000)
          }}
        />
      )}

      {/* Add Payment Modal */}
      {selectModal && (
        <SelectPropertyModal
          show={selectModal}
          handleClose={() => {
            setSelectModal(false)
          }}
          handleSubmitId={handleSubmitId}
        />
      )}

      <Backdrop sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}} open={open}>
        <CircularProgress style={{color: '#007a59'}} />
      </Backdrop>
    </>
  )
}

export default RenewTenancy
