import { useState } from 'react';
import PercentageInput from '../../../../../../components/PercentageInput';
import MultiSelectInput from '../../../../../../components/MultiSelectInput';

type ParentData = {
  chargeData: any;
  onUpdateChargeData: any;
}

const FixedCharge = ({ chargeData, onUpdateChargeData }: ParentData) => {
  const [invoicingOptions] = useState([
    { label: 'Enabled', value: true },
    { label: 'Disabled', value: false },
  ]);

  const [creditOptions] = useState([
    { label: 'Rent Charges', value: 1 },
    { label: 'Security Deposit', value: 0 },
  ]);

  const subLabels = {
    VATPercentage: 'Set the amount of tax you want to apply to the charge once the fee is calculated.',
    invoicingLabel: 'Auto-generate invoices for the charge whenever it is applied and charged to the tenant.',
    creditLabel: 'Select the charge you want to credit the booking fee from once it has been paid.',
  };

  const renderCreditText = () => {
    if (chargeData?.creditOptionType?.[0]?.value === 0) {
      return (
        <p className='mt-3 fs-6 fnt-500 light-dark-theme-color'>
          Once the booking fee is paid, the <u>security deposit</u> will be credited with the booking fee amount and reduce the outstanding balance of the <u>security deposit</u>.
        </p>
      );
    } else if (chargeData?.creditOptionType?.[0]?.value === 1) {
      return (
        <p className='mt-3 fs-6 fnt-500 light-dark-theme-color'>
          Once the booking fee is paid, the <u>rent charge</u> will be credited with the booking fee amount and reduce the outstanding balance of the <u>rent installment</u>. The system will auto-credit the first available rent installment.
        </p>
      );
    }
    return null;
  };

  return (
    <div className='card card-flush gap-5 p-5 pt-3 pb-5 mt-7 px-3' style={{ minHeight: '300px' }}>
      <div className='card-body pt-0 mt-5 py-0 px-3'>
        <h3 className='page-heading m-0 white-dark-theme-color fnt-700'>Fee Settings</h3>
        <p className='pe-13 fs-5 fnt-500 pt-2 labl-gry mb-1 light-dark-theme-color'>
          Set the fee structure for this charge.
        </p>

        {chargeData?.amountCalculationType != null && (
          <div className='mt-5'>
            {/* Set Fee and VAT Row */}
            <div className='row mb-8 align-items-start'>
              <div className='col-md-5'>
                <PercentageInput
                  isPercent={chargeData?.amountCalculationType === 1}
                  isDisabled={false}
                  iconSize={30}
                  required={true}
                  setRightPosition='10px'
                  type='number'
                  name={chargeData?.amountCalculationType === 1 ? 'feePercentage' : 'fixedAmount'}
                  value={chargeData?.amountCalculationType === 1 ?
                    chargeData?.feePercentage : chargeData?.fixedAmount}
                  label='Set Fee'
                  firstPlaceholder={chargeData?.amountCalculationType === 1 ?
                    'Enter Percentage' : 'Enter Amount'}
                  subLabel={chargeData?.amountCalculationType === 1 ?
                    'Set the fee %' : 'Set the fee amount'}
                  secondPlaceHolder={chargeData?.amountCalculationType === 1 ? '%' : 'AED'}
                  onChange={(newValue: any) => {
                    const updateChargeFields = { ...chargeData };
                    if (chargeData?.amountCalculationType === 1) {
                      updateChargeFields.feePercentage = newValue || null;
                    } else {
                      updateChargeFields.fixedAmount = newValue || null;
                      if (updateChargeFields.VATPercentage != null) {
                        updateChargeFields.previewData = {
                          ...updateChargeFields.previewData,
                          VATAmountForFixes: (updateChargeFields.VATPercentage / 100) * (newValue || 0)
                        };
                      }
                    }
                    updateChargeFields.isChrgDataChanged = Date.now(); 
                    onUpdateChargeData(updateChargeFields);
                  }}
                  className='width-300'
                />
              </div>

              <div className='col-md-7'>
                <div className='ps-md-12 d-flex flex-column'>
                  <div>
                    <PercentageInput
                      isPercent={true}
                      isDisabled={false}
                      iconSize={30}
                      required={true}
                      setRightPosition='10px'
                      type='number'
                      name='VATPercentage'
                      value={chargeData?.VATPercentage}
                      label='VAT'
                      firstPlaceholder='Enter Percentage'
                      subLabel={subLabels.VATPercentage}
                      secondPlaceHolder='%'
                      onChange={(newValue: any) => {
                        const updateChargeFields = { ...chargeData };
                        updateChargeFields.VATPercentage = newValue || null;
                        if (updateChargeFields.fixedAmount != null) {
                          updateChargeFields.previewData.VATAmountForFixes =
                            (updateChargeFields.VATPercentage / 100) * updateChargeFields.fixedAmount;
                        }
                        updateChargeFields.isChrgDataChanged = Date.now(); 
                        onUpdateChargeData(updateChargeFields);
                      }}
                      className='width-300'
                    />
                  </div>
                  <div className='d-flex align-items-center mt-2'>
                    <label className='percentage-input-label white-dark-theme-color status-w-150'>
                      VAT Rate
                    </label>
                    <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color'>
                      AED {chargeData?.previewData?.VATAmountForFixes || '-'}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Invoicing and Due Date Row */}
            <div className='row mb-8'>
              <div className='col-md-5'>
                <MultiSelectInput
                  name='invoicingType'
                  label='Invoicing'
                  subLabel={subLabels.invoicingLabel}
                  chargeOptions={invoicingOptions}
                  selectedCharge={chargeData?.autoInvoicingEnabled}
                  setSelectedCharge={(newVal: any) => {
                    const updateChargeFields = { ...chargeData };
                    updateChargeFields.autoInvoicingEnabled = newVal;
                    onUpdateChargeData(updateChargeFields);
                  }}
                  required={false}
                  disableSearch={true}
                  wrapperStyle='width-300'
                />
              </div>

              <div className='col-md-7'>
                <div className='ps-md-12'>
                  <div className='percentage-label-container'>
                    <label className='percentage-input-label white-dark-theme-color'>
                      Due Date
                    </label>
                    <p className='d-block fs-5 p-0 m-0 fnt-500 labl-gry mb-1 light-dark-theme-color py-2'>
                      Set the due date for when you want your tenant to <br />
                      settle the payment for this charge:
                    </p>
                  </div>
                  <div className='form-control bg-secondary bg-opacity-15 p-3 width-300'>
                    Time Charge is Applied
                  </div>
                </div>
              </div>
            </div>

            {/* Credit Row */}
            <div className='row mb-8'>
              <div className='col-md-5'>
                <MultiSelectInput
                  name='credit'
                  label='Credit'
                  subLabel={subLabels.creditLabel}
                  chargeOptions={creditOptions}
                  selectedCharge={chargeData?.creditOptionType}
                  setSelectedCharge={(newVal: any) => {
                    const updateChargeFields = { ...chargeData };
                    updateChargeFields.creditOptionType = newVal;
                    updateChargeFields.isChrgDataChanged = Date.now(); 
                    onUpdateChargeData(updateChargeFields);
                  }}
                  required={true}
                  disableSearch={true}
                  wrapperStyle='width-300'
                />
                {renderCreditText()}
              </div>
            </div>
          </div>
        )}
      </div>
    </div >
  );
};

export default FixedCharge;