import React, {useEffect, useState} from 'react'
import backArrow from '../../../../../../../../../img/back-arrow.png'
import buildingGreenIcon from '../../../../../../../../../img/allBuildingGreen.png'
import communityGreenIcon from '../../../../../../../../../img/buildingClusterGreen.png'
import mixedClusterGreenIcon from '../../../../../../../../../img/mixedClusterGreen.png'
import unitClusterGreenIcon from '../../../../../../../../../img/unitClusterGreen.png'
import {useNavigate, useParams} from 'react-router-dom'
import {Row, Col} from 'react-bootstrap'
import {ApiPost} from '../../../../../../../../../apiCommon/helpers/API/ApiData'
import { CircularProgress } from '@mui/material'

const DevelopmentCard: React.FC<{
  type: any
  developmentName: string
  stateObj: any
  navigate: any
}> = ({type, developmentName, stateObj, navigate}) => (
  <Col md={6}>
    <div
      className='card card-flush gap-5 p-3 cursor-pointerlight-gry-dark-shadow cursor-pointer lease-setting-table-card my-3'
      onClick={() => {
        if (stateObj?.path) navigate(stateObj.path,{state:{...stateObj?.stateArgs}})
      }}
    >
      <div className='card-body p-1'>
        <div className='d-flex align-items-center'>
          <p className='page-heading m-0 white-dark-theme-color fnt-600 fs-4 ps-4'>{developmentName}</p>
          <img
            className='ms-auto'
            src={
              type === 'building-development'
                ? buildingGreenIcon
                : type === 0
                ? unitClusterGreenIcon
                : type === 1
                ? communityGreenIcon
                : mixedClusterGreenIcon
            }
            alt='Icon'
            width='60px'
          />
        </div>
      </div>
    </div>
  </Col>
)

const RentFeeConfigration = () => {
  const navigate = useNavigate()
  const {id} = useParams()
  const [developmentList, setDevelopmentList] = useState<any>([])
  const [isSkeleton, setIsSkeleton] = useState<any>(false)

  const getDevelopmentList = async () => {
    const body = {
      filters: {
        propertyTypes:[0],
        leaseChargeListIds:[id],
        showDevelopments: true
      },
      
    }
    try {
      const response = await ApiPost('corporate/lease_fee_configuration/filter', body)
      //   setDevelopmentList(response?.data?.data?.developments)
      const updatedDevelopmentTypes = response?.data?.data?.developments?.map((item: any) => {
        const ids = {
          unitGroupId: item?.unitGroup?._id || '',
          buildingId: item?.building?._id || '',
          clusterId: item?.cluster?._id || '',
        };
  
        let name = item?.name || '';
        let type = '';
        const path = `/settings/residential-leasing/annual-leases/rent-fee/${id}/rent-details/${item?._id}`;
        

        const isBuilding = !!item?.isBuilding;
        const isCommunity = !!item?.isCommunity;
        const isCommunityBuilding = isCommunity && item?.building && item?.cluster?.type === 2;
        const isUnitGroupInMixedCluster = isCommunity && item?.unitGroup && item?.cluster?.type === 2;
        const isBuildingCluster = isCommunity && item?.building && item?.cluster?.type === 1;
        const isUnitGroup= isCommunity  && item?.cluster?.type === 0 ;

      if (isBuilding) {
        type = 'building-development';
      } else if (isCommunity) {
        type = item?.cluster?.type;

        if (isCommunityBuilding || isBuildingCluster) {
          name = `${item?.name} — ${item?.cluster?.name} — ${item?.building?.name}`;
        } else if (isUnitGroupInMixedCluster) {
          name = `${item?.name} — ${item?.cluster?.name} — ${item?.unitGroup?.name}`;
        } else if (isUnitGroup) {
          name = `${item?.name} — ${item?.cluster?.name}`;
        }
        // else if (isBuildingCluster) {
        //   name = `${item?.name} — ${item?.cluster?.name} - ${item?.building?.name}`;
        // }
      }

        const stateObj = {
          stateArgs: { name, isBuilding, isCommunity, isUnitGroup, isCommunityBuilding,isUnitGroupInMixedCluster , isBuildingCluster, ids },
          path,
        };


        return {
          ...item,
          name,
          stateObj,
          type,
        }
      })
      setDevelopmentList(updatedDevelopmentTypes)
    } catch (error: any) {
      console.error('Failed to fetch lease types:', error)
      if (error?.message === 'Your account has been suspended!') {
        window.location.pathname = '/auth'
        localStorage.clear()
      }
    }
  }

  useEffect(() => {
    getDevelopmentList()
  }, [])

  return (
    <>
      {!isSkeleton ? (
        <div className='pt-0 mt-0 px-2 ps-1'>
          <div className='d-flex align-items-center gap-2 mb-4 gap-lg-3'>
            <div className='d-flex align-items-start gap-3'>
              <span
                className='rounded-circle cursor-pointer'
                onClick={() => navigate('/settings/residential-leasing/annual-leases')}
              >
                <img src={backArrow} height={14} width={14} alt='Back' />
              </span>
              <div>
                <h2 className='page-heading m-0 head-text mb-1'>Rent Configurations</h2>
                <p className='labl-gry fs-4 mb-0 light-dark-theme-color'>
                  Setup you rental rates across your property portfolio. Rent configrations will apply to new leases
                  created.
                </p>
              </div>
            </div>
          </div>

          {/* card */}
          <div className='card card-flush gap-5 p-5 pt-3 pb-0 mt-5 px-3' style={{minHeight: '75vh'}}>
            <div className='card-body pt-0 mt-5 py-0 px-3'>
              <Row className='mb-5 '>
                {/* Development Card */}
                {developmentList?.length > 0 &&
                  developmentList?.map((developmentItem: any) => {
                    return (
                      <DevelopmentCard
                        type={developmentItem?.type}
                        developmentName={developmentItem?.name}
                        key={developmentItem?._id}
                        navigate={navigate}
                        stateObj={developmentItem?.stateObj}
                      />
                    )
                  })}
              </Row>
            </div>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center w-100' style={{height: '75vh'}}>
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}
    </>
  )
}

export default RentFeeConfigration
