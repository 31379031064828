import React, {useState} from 'react'
import {Controller, useForm, FormProvider} from 'react-hook-form'
import {CircularProgress} from '@mui/material'
import {useNavigate, useParams} from 'react-router-dom'
import backArrow from '../../../../../../../../img/back-arrow.png'
import {Box, Grid} from '@mui/material'
import ChargeSettings from '../common/ChargeSettings/ChargeSettings'
import Preview from '../common/Preview/Preview'
import FeeSettings from '../common/FeeSettings/FeeSettings'
import AssignCharges from './components/AssignCharges'

interface AddLatePaymentFeeProps {}

const AddLatePaymentFee: React.FC<AddLatePaymentFeeProps> = () => {
  const [isSkeleton, setIsSkeleton] = useState<boolean>(false)
  const methods = useForm<any>({mode: 'onSubmit', reValidateMode: 'onChange'})
  const {handleSubmit} = methods
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const navigate = useNavigate()
  const {flg, mode} = useParams()

  const [latePaymentFeeItemData, setLatePaymentFeeItemData] = useState<any>({
    name: '',
    type: null,
    amountCalculationType: null,
  })

  const addLatePaymentFee = () => {}

  return (
    <>
      {!isSkeleton ? (
        <div className='pt-0 mt-0 px-2 ps-1'>
          <FormProvider {...methods}>
            <form
              onSubmit={handleSubmit((data: any, e: any) => {
                addLatePaymentFee()
              })}
            >
              <div className='d-flex align-items-end gap-2 mb-3 gap-lg-3'>
                <div className='d-flex align-items-start gap-3'>
                  <span className='rounded-circle cursor-pointer' onClick={() => navigate('/settings/residential-leasing/annual-leases')} >
                    <img src={backArrow} height={14} width={14} alt='Back' />
                  </span>
                  <div>
                    <h2 className='page-heading m-0 head-text mb-1'>
                      {mode === 'edit' || isEdit || flg === '1'
                        ? 'Create a Late Payment Fee'
                        : 'View a Late Payment Fee'}
                    </h2>
                    {flg === '1' && (
                      <p className='labl-gry fs-4 mb-0 light-dark-theme-color'>
                        Late payment fees will be applied on overdue charges based on the due date
                        set for the charge and will continue to generate until the payment is fully
                        paid and settled.
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {/* LatePayment Settings,  Fee Settings & Preview  */}
              <Grid container spacing={4} className='mb-4'>
                <Grid item md={9} sx={{display: 'flex', flexDirection: 'column'}}>
                  {/* LatePayment Settings Card */}
                  <ChargeSettings
                    cardHeading={'Late Payment Fee Setting'}
                    cardSubHeading={'Set your late payment fee structure.'}
                    type={'Late Payment Fee'}
                    itemData={latePaymentFeeItemData}
                    setItemData={setLatePaymentFeeItemData}
                    namePlaceholderText={'Enter a name for this late payment fee...'}
                  />

                  {/* Fee Settings Card */}
                  <FeeSettings
                    itemData={latePaymentFeeItemData}
                    setItemData={setLatePaymentFeeItemData}
                  />
                </Grid>
                <Grid item md={3} sx={{display: 'flex', flexDirection: 'column'}}>
                  {/* Preview */}
                  <Preview />
                </Grid>
              </Grid>

              {/* Assign Charges */}
              <Grid container spacing={4} className='mb-4'>
                <Grid item xs={12}>
                  <AssignCharges />
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </div>
      ) : (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '75vh'}}
        >
          <CircularProgress style={{color: '#007a59'}} />
        </div>
      )}
    </>
  )
}

export default AddLatePaymentFee
