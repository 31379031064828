import React, {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'

function Request() {
  const [formData, setFormData] = useState<any>({})
  const [page, setPage] = useState<any>(1)
  const [pageLimit, setPageLimit] = useState<any>(1)
  const [propertiType, setPropertiType] = useState<any>('Service Providers')
  const navigate = useNavigate()
  const pathName = window.location.pathname

  const ListNameItem = new Array(10).fill(0)
  const Vendors = new Array(5).fill(0)

  const handlePageClick = (event: any) => {
    setPage(event.selected + 1)
  }

  return (
    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
      <div id='' className='app-container container-xxl pt-0 mt-0'>
        <div className='page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-5 min-w-200px'>
          <h1 className='page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0'>
            Manage Requests
          </h1>
        </div>
        <Row>
          <Col lg={6}>
            <div
              className='card-header align-items-center py-5   gap-md-2 d-flex'
              style={{justifyContent: 'space-between'}}
            >
              <div className='mb-10  min-w-200px' style={{display: 'contents'}}>
                <h5 className='gray-500'>
                  Overall Requests:{' '}
                  <span className='' style={{color: 'black'}}>
                    50
                  </span>
                </h5>
              </div>
              <div className='mb-10  min-w-200px' style={{display: 'contents'}}>
                <h5 className='gray-500'>
                  Pending Requests:{' '}
                  <span className='' style={{color: 'black'}}>
                    8
                  </span>
                </h5>
              </div>
              <div className='mb-10  min-w-200px' style={{display: 'contents'}}>
                <h5 className='gray-500'>
                  Requests Cancelled:{' '}
                  <span className='' style={{color: 'black'}}>
                    4
                  </span>
                </h5>
              </div>
            </div>
          </Col>
          <Col lg={6}></Col>
        </Row>
        <div className='d-flex align-items-center gap-2 mb-10 gap-lg-3 justify-content-center'>
          <ul className='nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-n2'>
            <li
              className='nav-item'
              onClick={() => {
                navigate(`/vendor-management/serviceproviders`)
              }}
            >
              <a className='nav-link text-active-primary pb-4' data-bs-toggle='tab'>
                All
              </a>
            </li>
            <li
              className='nav-item'
              onClick={() => {
                navigate(`/request-management/quotation`)
              }}
            >
              <a className='nav-link text-active-primary pb-4 active' data-bs-toggle='tab'>
                Quotations
              </a>
            </li>
            <li
              className='nav-item'
              onClick={() => {
                navigate(`/request-management/service`)
              }}
            >
              <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                Maintenance & Service
              </a>
            </li>
            <li
              className='nav-item'
              // onClick={() => {
              //   navigate(`/vendor-management/suppliers`)
              // }}
            >
              <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                Products & Goods
              </a>
            </li>
            <li
              className='nav-item'
              // onClick={() => {
              //   navigate(`/vendor-management/suppliers`)
              // }}
            >
              <a className='nav-link text-active-primary pb-4 ' data-bs-toggle='tab'>
                Payments
              </a>
            </li>
          </ul>
        </div>
        <div className='mb-10'>
          <Row>
            <Col lg={3} md={3} className='d-flex align-items-center'>
              <label htmlFor='exampleFormControlInput1' className='w-200px form-label m-0'>
                Quotation ID
              </label>
              <input
                type='text'
                className='form-control form-control-solid solid'
                placeholder='Quotation ID '
                name='main_service'
                value=''
                style={{backgroundColor: '#fff'}}
              />
            </Col>
            <Col lg={3} md={3} className='d-flex align-items-center'>
              <label htmlFor='exampleFormControlInput1' className='w-200px form-label m-0'>
                Status
              </label>
              <select name='Status' className='form-select form-select-solid'>
                <option selected value=''>
                  Select
                </option>
              </select>
            </Col>
            <Col lg={3} md={3} className='d-flex align-items-center'>
              <label htmlFor='exampleFormControlInput1' className='w-200px form-label m-0'>
                Portfolio
              </label>
              <select name='Portfolio' className='form-select form-select-solid'>
                <option selected value=''>
                  Select
                </option>
              </select>
            </Col>
            <Col lg={3} md={3} className='d-flex align-items-center'>
              <label htmlFor='exampleFormControlInput1' className='w-200px form-label m-0'>
                Vendor
              </label>
              <input
                type='text'
                className='form-control form-control-solid solid'
                placeholder='Vendor'
                name='Vendor'
                value=''
                style={{backgroundColor: '#fff'}}
              />
            </Col>
          </Row>
        </div>

        <div className='mb-8 min-w-375px d-flex gap-4 w-auto justify-content-end'>
          <div
            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
            onClick={() => navigate(`/vendor-management/management-list`)}
          >
            Request Service Quotation
          </div>
          <div
            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
            onClick={() => navigate(`/vendor-management/management-list`)}
          >
            Request P&G Quotation
          </div>
        </div>
        <div className='card card-flush'>
          <div className='card-body pt-0 table-responsive mt-5'>
            <table
              className='table align-middle table-row-dashed fs-6 gy-5'
              id='kt_ecommerce_sales_table'
            >
              <thead>
                <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                  <th className='text-center min-w-100px'>Quotation Id</th>
                  <th className='text-center min-w-100px'>Type</th>
                  <th className='text-center min-w-100px'>Status</th>
                  <th className='text-center min-w-100px'>Request Name</th>
                  <th className='text-center min-w-70px'>Portfolio</th>
                  <th className='text-center min-w-100px'>Vendors Selected</th>
                  <th className='text-center min-w-100px'>Quotation Deadline </th>
                  <th className='text-center min-w-100px'>Quotations Received </th>
                  <th className='text-center min-w-100px'>Quotation Selected</th>
                  <th className='text-center min-w-100px'>Selected Status</th>
                  <th className='text-center min-w-100px'>Requestor</th>
                  <th className='text-center min-w-100px'>Pending With</th>
                  <th className='text-center min-w-100px'>Completed By</th>
                  <th className='text-center min-w-100px'></th>
                </tr>
              </thead>
              <tbody className='fw-semibold text-gray-600'>
                {Vendors.map((f, i) => {
                  return (
                    <tr>
                      <td>###</td>
                      <td>service</td>
                      <td>Lorem Ipsum</td>
                      <td>Service Provider</td>
                      <td>5427850</td>
                      <td>1209817</td>
                      <td>Dubai</td>
                      <td>1</td>
                      <td>Electricians</td>
                      <td>+9710000000</td>
                      <td>eee@ggg.com</td>
                      <td>4.5</td>
                      <td>5</td>
                      <td>
                        <div className='menu-item px-3'>
                          <div
                            onClick={() => navigate(`/edit-facility/${1}`)}
                            className='btn btn-sm fw-bold btn-primary btn-green hover-none'
                          >
                            View
                          </div>
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          <div className='row mb-5'>
            <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
              <div
                className='dataTables_paginate paging_simple_numbers'
                id='kt_ecommerce_sales_table_paginate'
              >
                <ReactPaginate
                  breakLabel='...'
                  nextLabel='>'
                  pageClassName='paginate_button page-item +'
                  pageLinkClassName='page-link'
                  containerClassName='pagination'
                  activeClassName='active'
                  previousClassName='paginate_button page-item previous disabled'
                  nextClassName='paginate_button page-item next'
                  previousLinkClassName='page-link'
                  nextLinkClassName='page-link'
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={5}
                  pageCount={pageLimit}
                  previousLabel='<'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Request
